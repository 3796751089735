import type { StoreInputEmails } from '@app/stores/helpers/StoreInputEmails'
import type { InputRef } from 'antd'
import { action } from 'mobx'

export const handleOnEmailTagClose =
  (storeInputEmails: StoreInputEmails) =>
  (onValidate: (error?: string) => void) =>
  (removedEmail: string) =>
  () => {
    storeInputEmails.removeEmail(removedEmail)
    onValidate(storeInputEmails.emailError)
  }

export const handleTagOnClick = (storeInputEmails: StoreInputEmails) => () => {
  storeInputEmails.setInputVisible(true)
}

export const handleTagOnKeydown =
  (storeInputEmails: StoreInputEmails) =>
  (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      storeInputEmails.setInputVisible(true)
    }
  }

export const handleInputChange =
  (storeInputEmails: StoreInputEmails) =>
  (e: React.ChangeEvent<HTMLInputElement>) => {
    storeInputEmails.setInputValue(e.target.value)
  }

export const handleInputConfirm =
  (storeInputEmails: StoreInputEmails) =>
  (onValidate: (error?: string) => void) =>
  () => {
    storeInputEmails.addEmails()
    onValidate(storeInputEmails.emailError)
  }
export const handleEditInputChange =
  (storeInputEmails: StoreInputEmails) =>
  (e: React.ChangeEvent<HTMLInputElement>) => {
    storeInputEmails.setEditInputValue(e.target.value)
  }

export const handleEditInputConfirm =
  (storeInputEmails: StoreInputEmails) =>
  (onValidate: (error?: string) => void) =>
  (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLInputElement, MouseEvent>
      | React.FocusEvent<HTMLInputElement, Element>
  ) => {
    storeInputEmails.updateEmailAtIndex()
    onValidate(storeInputEmails.emailError)
    e.preventDefault()
  }

export const handleEmailTagOnEdit =
  (storeInputEmails: StoreInputEmails) =>
  (editInputRef: React.RefObject<InputRef>) =>
  (index: number, email: string) =>
    action(
      (
        e:
          | React.MouseEvent<HTMLSpanElement, MouseEvent>
          | React.KeyboardEvent<HTMLSpanElement>
      ) => {
        if (
          e instanceof KeyboardEvent &&
          !['Enter', 'Space'].includes((e as KeyboardEvent).key)
        ) {
          return
        }
        storeInputEmails.setEditInputValue(email)
        storeInputEmails.setEditInputIndex(index)
        // get the focus after the ref is set
        setTimeout(() => {
          editInputRef.current?.focus()
        })
      }
    )
