import type { StorePreferences } from '@app/stores'

export const onPreferencesApiKeyLoad =
  (storePreferences: StorePreferences) => () => {
    storePreferences.fetchApiKey()
  }

export const onApiKeyRefreshSubmit =
  (storePreferences: StorePreferences) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    storePreferences.storeModalConfirmApiKeyRefresh.show()
  }

export const onApiKeyRefreshModalConfirmClick =
  (storePreferences: StorePreferences) => () => {
    storePreferences.refreshApiKey()
    storePreferences.storeModalConfirmApiKeyRefresh.hide()
  }
