import { AppMenu } from '@app/components-legacy/Menu'
import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import { useAppTranslator, useStores } from '@app/hooks'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { RbacEntityItemType } from '@libs/rbac/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IRoleEditEntitiesMenuProps {}

const RoleEditMenu: React.FC<IRoleEditEntitiesMenuProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Roles.Permissions']
  })

  const { storeManagementRbacRoles } = useStores()

  useStoreMenuInit<IMenuEntry<RbacEntityItemType>>({
    storeMenu: storeManagementRbacRoles.storeMenu,
    menuEntries: [
      {
        key: RbacEntityItemType.data,
        label: translate('Data entities'),
        labelledBy: RbacEntityItemType.data
      },
      {
        key: RbacEntityItemType.userPersonal,
        label: translate('Self-user entities'),
        labelledBy: RbacEntityItemType.userPersonal
      },
      {
        key: RbacEntityItemType.singleton,
        label: translate('Singleton entities'),
        labelledBy: RbacEntityItemType.singleton
      },
      {
        key: RbacEntityItemType.ui,
        label: translate('UI entities'),
        labelledBy: RbacEntityItemType.ui
      }
    ],
    defaultSelectedMenuKey: RbacEntityItemType.data
  })

  return <AppMenu storeMenu={storeManagementRbacRoles.storeMenu} />
}

export default observer(RoleEditMenu)
