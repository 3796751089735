import type { Maybe } from '@@types/helpers'
import type { CheckerOptionCodename } from '@app/entities/EntityGenericCheckerOption/types'
import { CheckerOptionCodenameEnum } from '@app/entities/EntityGenericCheckerOption/types'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { ValueType } from '@libs/valueTypeParser/types'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type {
  ICheckerOptionArraySelectValue,
  ICheckerOptionFieldMeta,
  ICheckerOptionOMCGMAWConfigurationValues,
  ICheckerOptionOWCTConfigurationValues
} from '../types'

/**
 * Encode each field value dependending of its value type and from
 * form or dedicated stores.
 */
export const encodeFieldValue =
  (storeForm: StoreForm<any>) =>
  (
    optionCodename: CheckerOptionCodename,
    optionOMCGMAWConfigurationValues?: ICheckerOptionOMCGMAWConfigurationValues[],
    optionOWCTConfigurationValues?: ICheckerOptionOWCTConfigurationValues[]
  ): Maybe<string> => {
    const logger = storeForm.storeRoot.logger

    const field = storeForm.field(optionCodename)
    const fieldMeta = field.meta.get<ICheckerOptionFieldMeta>('meta')

    if (!fieldMeta) {
      logger.warn(`[encodeFieldValue] Field ${field.name}: No meta found`)
      return null
    }

    switch (fieldMeta.valueType) {
      case ValueType.string:
      case ValueType.sddl:
      case ValueType.regex: {
        // not change required, return the field value as string
        return JSON.stringify(field.asString)
      }

      case ValueType.boolean: {
        return String(Boolean(field.value === 'true' || field.value === true))
      }

      case ValueType.integer: {
        return field.asString
      }

      case ValueType.float: {
        return String(Number(field.asString.replace(/,/, '.')))
      }

      case ValueType.arrayRegex:
      case ValueType.arrayInteger: {
        // extract multi-values as an array for multi-values fields
        return JSON.stringify(
          storeForm.getMultiValuesFieldValuesAsArray(optionCodename)
        )
      }

      case ValueType.arrayBoolean: {
        return JSON.stringify(
          storeForm.getMultiValuesFields(optionCodename).map(f => {
            return f.value === 'true' || f.value === true
          })
        )
      }

      case ValueType.arrayString:
      case ValueType.arrayCron: {
        return JSON.stringify(
          storeForm.getMultiValuesFields(optionCodename).map(f => f.asString)
        )
      }

      case ValueType.arraySelect: {
        // return a stringified IValueTypeArraySelectDecodedValue object
        if (!fieldMeta.arraySelectSelection) {
          logger.warn(
            `[encodeFieldValue] Field ${field.name}: Missing select selection data`
          )
          return null
        }

        const selectValue: ICheckerOptionArraySelectValue = {
          chosen: String(field.value),
          choices: fieldMeta.arraySelectSelection.value.choices
        }

        return JSON.stringify(selectValue)
      }

      case ValueType.arrayObject: {
        // for complex value types that need a specific store, save the store value
        // into the form and return a stringified specific object according to
        // the option codename
        switch (optionCodename) {
          case CheckerOptionCodenameEnum.O_MAX_CUSTOM_GROUPS_MEMBERS_AND_WHITELIST: {
            if (!optionOMCGMAWConfigurationValues) {
              logger.warn(
                `[encodeFieldValue] Field ${field.name}: Missing values for ${optionCodename} option`
              )
              return null
            }

            const configurationValues = JSON.stringify(
              optionOMCGMAWConfigurationValues
            )

            field.setValue(configurationValues)

            return configurationValues
          }

          case CheckerOptionCodenameEnum.O_WHITELIST_CERTIFICATE_TEMPLATES: {
            if (!optionOWCTConfigurationValues) {
              logger.warn(
                `[encodeFieldValue] Field ${field.name}: Missing values for ${optionCodename} option`
              )
              return null
            }

            const configurationValues = JSON.stringify(
              optionOWCTConfigurationValues
            )

            field.setValue(configurationValues)

            return configurationValues
          }

          default: {
            logger.warn(
              `[encodeFieldValue] Field ${field.name}: Option ${optionCodename} not yet supported`
            )
            return null
          }
        }
      }

      case ValueType.date: {
        return field.asString
      }

      case ValueType.object:
        logger.warn(
          `[encodeFieldValue] Field ${field.name}: Value type ${fieldMeta.valueType} not yet supported`
        )
        return null

      default:
        assertUnreachableCase(fieldMeta.valueType)
        return null
    }
  }
