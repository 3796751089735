import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'
import { Tooltip } from 'antd'
import type { TooltipProps } from 'antd/lib/tooltip'
import * as React from 'react'
import styled from 'styled-components'
import type { IContainerSimpleProps } from '../Container/ContainerSimple'
import ContainerSimple from '../Container/ContainerSimple'

export interface IIconPNGProps extends IContainerSimpleProps {
  iconName: string
  width?: number
  height?: number
  size?: number
  onClick?: () => void
  tooltip?: TooltipProps
  labelledBy: string
}

/**
 * Wrapper around an icon as PNG to add an optional tooltip since there is
 * some issues to make the tooltip working with a SVG...
 */
const IconPNG: React.FC<IIconPNGProps> = props => {
  // Don't import appRouter here since <IconPng /> could be used as a decoration icon
  // in Antd messages where stores are not available (because not wrapped by the Context)
  const fullSrc = `/w/assets/images/${props.iconName}`

  const extraDivProps = filterEmptyProps({
    onClick: props.onClick
  })

  const renderIcon = () => {
    return (
      <ContainerSimple
        name={IconPNG.name}
        ariaRoles="img"
        labelledBy={props.labelledBy}
        className={props.className}
        {...extraDivProps}
      >
        <img src={`${fullSrc}.png`} />
      </ContainerSimple>
    )
  }

  if (!props.tooltip) {
    return renderIcon()
  }

  return <Tooltip {...props.tooltip}>{renderIcon()}</Tooltip>
}

const defaultSize = 18

export default styled(IconPNG)`
  img {
    width: ${props => props.width || props.size || defaultSize}px;
    height: ${props => props.height || props.size || defaultSize}px;
  }
`
