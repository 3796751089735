import { consts } from '@app/styles'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled from 'styled-components'

interface IInputExpressionContainerProps {
  className?: string
  isFocus: boolean
  isDisabled: boolean
  children?: React.ReactNode
}

const InputExpressionContainer: React.FC<
  IInputExpressionContainerProps
> = props => {
  return <div className={props.className}>{props.children}</div>
}

export default styled(InputExpressionContainer)`
  background-color: ${props =>
    props.isDisabled
      ? props.theme.tokens['input/backgroundColor/disabled']
      : props.theme.tokens['inputExpression/backgroundColor/default']};
  position: relative;
  padding: 0 ${consts.paddingXXXL} 0 ${consts.paddingXXL};
  height: 32px;
  text-overflow: ellipsis;
  box-shadow: ${props =>
    isThemeLegacy(props.theme) && props.isFocus && !props.isDisabled
      ? consts.shadowBlueAntd
      : ''};
  transition: all 0.3s;
  overflow: hidden;
  width: 100%;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'default')};
  border-radius: ${props =>
    props.theme.tokens['inputExpression/borderRadius/default']};
  border: 1px solid
    ${props =>
      props.isFocus && !props.isDisabled
        ? props.theme.tokens['inputExpression/borderColor/hover']
        : props.theme.tokens['inputExpression/borderColor/default']};

  &:hover {
    border-color: ${props =>
      !props.isDisabled
        ? props.theme.tokens['inputExpression/borderColor/hover']
        : 'inherit'};
  }

  input {
    padding: 7px 0;
  }
`
