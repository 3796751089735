import { useTestAttribute } from '@app/hooks/useTestAttribute'
import type { Maybe } from '@server/graphql/typeDefs/types'
import { CheckerType } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'

interface IBadgeAlertsProps {
  className?: string
  type: CheckerType
  count: Maybe<number>
  labelledBy?: string
  isTulEnabled?: boolean
}

const AlertsBadge: React.FC<IBadgeAlertsProps> = props => {
  const count = props.count
  const { testAttributeProps } = useTestAttribute('contentinfo')

  const getCountValue = () => {
    if (!count) {
      return 0
    }

    if (count > 99) {
      return '99+'
    }

    return count
  }
  return (
    <div
      data-name="AlertsBadge"
      className={props.className}
      {...testAttributeProps()(props.labelledBy)}
    >
      {getCountValue()}
    </div>
  )
}

export default styled(AlertsBadge)`
  position: absolute;
  top: ${props => (props.type === CheckerType.Exposure ? '10px' : '31px')};
  left: ${props => (props.isTulEnabled ? '-15px' : '-10px')};

  width: 25px;

  opacity: ${props => (props.count ? 1 : 0)};
  transform: scale(${props => (props.count ? 1 : 0.3)});
  transition: all 0.3s ease-in-out;

  background-color: ${props =>
    props.type === CheckerType.Exposure ? '#4575f9' : 'red'};
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  border-radius: 5px;
  user-select: none;
`
