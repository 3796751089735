import type { Perhaps } from '@@types/helpers'
import { isDefined } from '@libs/isDefined'
import { DescriptionTemplateReplacementsInnerValueTypeEnum } from '@libs/openapi/service-identity-core'
import { ValueType } from '@libs/valueTypeParser/types'
import { assertUnreachableCase } from '@productive-codebases/toolbox'

/**
 * Format an incriminating attribute value according to the valueType.
 */
export function formatAttributeValueToMarkdown(
  value: Perhaps<string>,
  valueType: ValueType | DescriptionTemplateReplacementsInnerValueTypeEnum
): string {
  switch (valueType) {
    case ValueType.string:
    case ValueType.regex:
    case ValueType.float:
    case ValueType.integer:
    case ValueType.boolean:
    case ValueType.object:
    case ValueType.arrayString:
    case ValueType.arrayCron:
    case ValueType.arrayRegex:
    case ValueType.arrayInteger:
    case ValueType.arrayBoolean:
    case ValueType.arraySelect:
    case ValueType.arrayObject:
    case DescriptionTemplateReplacementsInnerValueTypeEnum.String:
    case DescriptionTemplateReplacementsInnerValueTypeEnum.Regex:
    case DescriptionTemplateReplacementsInnerValueTypeEnum.Float:
    case DescriptionTemplateReplacementsInnerValueTypeEnum.Integer:
    case DescriptionTemplateReplacementsInnerValueTypeEnum.Boolean:
    case DescriptionTemplateReplacementsInnerValueTypeEnum.Object:
    case DescriptionTemplateReplacementsInnerValueTypeEnum.Arraystring:
    case DescriptionTemplateReplacementsInnerValueTypeEnum.Arrayregex:
    case DescriptionTemplateReplacementsInnerValueTypeEnum.Arrayinteger:
    case DescriptionTemplateReplacementsInnerValueTypeEnum.Arrayboolean:
    case DescriptionTemplateReplacementsInnerValueTypeEnum.Arrayselect:
    case DescriptionTemplateReplacementsInnerValueTypeEnum.Arrayobject:
    case DescriptionTemplateReplacementsInnerValueTypeEnum.Arraycron: {
      // if the value is null or undefined, return a fallback
      if (!isDefined(value)) {
        return `\`?\``
      }

      // if the value is an empty string, return an empty string as well but
      // without backticks to avoid any formatting
      if (value.trim() === '') {
        return ''
      }

      // otherwise, return the value surrounded by backticks with $ escaped
      return `\`${value.replaceAll(/\$/gm, '$$$')}\``
    }

    case ValueType.date:
    case DescriptionTemplateReplacementsInnerValueTypeEnum.Date: {
      return `[tenable_ad_date]${value}[/tenable_ad_date]`
    }

    case ValueType.sddl:
    case DescriptionTemplateReplacementsInnerValueTypeEnum.Sddl: {
      return `[tenable_ad_sddl]${value}[/tenable_ad_sddl]`
    }

    default:
      assertUnreachableCase(valueType)
  }
}
