import { consts } from '@app/styles'
import { computeLabelColor } from '@app/styles/colors/getLabelColor'
import * as React from 'react'
import styled from 'styled-components'

interface IContainerTriangleProps {
  className?: string
  name?: string
  backgroundColor: string
  children?: React.ReactNode
}

/**
 * Render a triangle used for decoration in Incriminated Attributes
 * or Alerts cards.
 */
const CardDecorationTriangle: React.FC<IContainerTriangleProps> = props => {
  return (
    <div
      data-name={props.name ?? 'CardDecorationTriangle'}
      className={props.className}
    >
      {props.children}
    </div>
  )
}

export default styled(CardDecorationTriangle)`
  position: absolute;
  left: -20px;
  top: 20px;
  padding-top: 10px;
  width: 57px;
  text-align: center;
  font-size: ${consts.textSizeSmall};
  font-weight: ${consts.fontWeightBold};
  text-transform: uppercase;
  color: ${props => computeLabelColor(props.backgroundColor)};
  background-color: ${props => props.backgroundColor};
  transform: rotate(-45deg);
  transform-origin: top left;
`
