import { ContainerFlex } from '@app/components/Container'
import { FormWrapperSelect } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks'
import type StoreActivityLogsFilters from '@app/stores/ActivityLogs/StoreActivityLogsFilters'
import { isDefined } from '@libs/isDefined'
import { observer } from 'mobx-react-lite'
import React from 'react'
import FilterLabelWithSelectedCount from './FilterLabelWithSelectedCount'

interface IFilterField {
  storeActivityLogsFilters: StoreActivityLogsFilters
}

const FilterUserField: React.FC<IFilterField> = props => {
  const translate = useAppTranslator({
    namespaces: ['ActivityLogs.Filters']
  })

  const selectOptionsList = props.storeActivityLogsFilters.allUserEmails.map(
    user => {
      return {
        label: user,
        value: user,
        labelledBy: user
      }
    }
  )

  const selectedList: string[] = Array.from(
    props.storeActivityLogsFilters.storeDrawerFilters
      .getDataValue('selectedUserEmails')
      ?.values() ?? []
  )

  const onSelect = (values: string[]) => {
    props.storeActivityLogsFilters.storeDrawerFilters.setData({
      selectedUserEmails: new Set(values)
    })
  }

  if (selectOptionsList.length < 2) {
    // Hide the form select if there is no need to filter (aka no or only one result).
    return null
  }

  return (
    <ContainerFlex
      name="DrawerFiltersFields"
      direction="column"
      items={[
        <FilterLabelWithSelectedCount
          label={translate('User(s)')}
          selectedList={selectedList}
          labelledBy="filteredUsersCount"
        />,
        <FormWrapperSelect<any>
          labelledBy="FilteredUsers"
          height="auto"
          selectProps={{
            placeholder: translate('Select user(s)'),
            mode: 'multiple',
            ...(isDefined(selectedList) &&
              selectedList.length && { value: selectedList }),
            onChange: onSelect
          }}
          selectOptions={selectOptionsList}
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(FilterUserField)
