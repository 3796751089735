import type { StoreRoot } from '@app/stores'
import StoreBase from '@app/stores/StoreBase'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { escapeRegExp } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

export enum InputSearchTransformMethod {
  default = 'default',
  greedy = 'greedy'
}

interface IStoreInputSearchOptions {
  transformMethod?: InputSearchTransformMethod
}

export class StoreInputSearch extends StoreBase<IStoreInputSearchOptions> {
  private $searchValue = observable.box<string>('')
  private $focus = observable.box<boolean>(false)
  private $isDirty = observable.box<boolean>(false)

  constructor(
    storeRoot: StoreRoot,
    options = {
      transformMethod: InputSearchTransformMethod.greedy
    }
  ) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Transform the value according to the transformMethod.
   */
  private transformSearchValue(searchValue: string): string {
    switch (this.options.transformMethod) {
      case InputSearchTransformMethod.default:
        return searchValue

      // if greedy, insert '.*' between each char to match non consecutive chars
      case InputSearchTransformMethod.greedy:
        return searchValue
          .split('')
          .reduce<string[]>(
            (acc, char) => acc.concat([escapeRegExp(char), '.*']),
            []
          )
          .join('')

      default:
        assertUnreachableCase(this.options.transformMethod)
    }
  }

  /* Action */

  @action
  reset(): this {
    this.$searchValue.set('')
    this.setIsDirty(false)
    return this
  }

  @action
  setSearchValue(value: string): this {
    this.$searchValue.set(value)
    this.setIsDirty(true)
    return this
  }

  @action
  setFocus(value: boolean): this {
    this.$focus.set(value)
    return this
  }

  @action
  setIsDirty(value: boolean): this {
    this.$isDirty.set(value)
    return this
  }

  @action
  submit(): this {
    this.setIsDirty(false)
    return this
  }

  /* Computed */

  /**
   * Return true if a search value has been filled.
   */
  @computed
  get hasSearchValue(): boolean {
    return this.$searchValue.get() !== ''
  }

  /**
   * Return the search value.
   */
  @computed
  get searchValue(): string {
    return this.$searchValue.get()
  }

  /**
   * Return the transformed search values as a string.
   */
  @computed
  get transformedSearchValue(): string {
    return this.transformSearchValue(this.$searchValue.get())
  }

  /**
   * Return the transformed search values as a regexp.
   */
  @computed
  get transformedSearchValueAsRegexp(): RegExp {
    return new RegExp(this.transformedSearchValue, 'i')
  }

  /**
   * Return the focus value.
   */
  @computed
  get focus(): boolean {
    return this.$focus.get()
  }

  /**
   * Return the isDirty value.
   */
  @computed
  get isDirty(): boolean {
    return this.$isDirty.get()
  }
}
