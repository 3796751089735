import { BookmarkCategoryEnum } from '@app/stores/TrailFlow/StoreSearchBookmarks'
import type { TranslateFn } from '@libs/i18n'

export const getCategoryTranslation = (
  category: string,
  translate: TranslateFn
): string => {
  switch (category) {
    case BookmarkCategoryEnum.All:
      return translate(BookmarkCategoryEnum.All)

    case BookmarkCategoryEnum.Uncategorized:
      return translate(BookmarkCategoryEnum.Uncategorized)

    default:
      return category
  }
}
