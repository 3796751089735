import { FormWrapperSelect } from '@app/components-legacy/Form'
import { useAppTranslator, useStores } from '@app/hooks'
import { InputCrontabUnit } from '@app/stores/helpers/StoreInputCrontab/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { UnitPrefix } from '../types'
import CronfieldControlContainer from './CronfieldControlContainer'

export interface ISelectDaysOfTheWeekProps {
  days: string[]
  onChange: (days: string[]) => void
}

function SelectDaysOfTheWeek(props: ISelectDaysOfTheWeekProps) {
  const { storeManagementReportingCenter } = useStores()
  const storeInputCrontab = storeManagementReportingCenter.storeInputCrontab

  const translate = useAppTranslator({
    namespaces: ['Components.InputCrontab']
  })

  const selectOptions = [
    {
      label: 'Monday',
      value: '1'
    },
    {
      label: 'Tuesday',
      value: '2'
    },
    {
      label: 'Wednesday',
      value: '3'
    },
    {
      label: 'Thursday',
      value: '4'
    },
    {
      label: 'Friday',
      value: '5'
    },
    {
      label: 'Saturday',
      value: '6'
    },
    {
      label: 'Sunday',
      value: '7'
    }
  ]

  const emptySelection = props.days.length === 0
  const daysOfTheMonth = JSON.parse(
    storeInputCrontab.storeForm.getFieldValueAsString(
      InputCrontabUnit.daysOfTheMonth
    )
  ) as string[]
  const emptyDaysOfTheMonthSelection = daysOfTheMonth.length === 0

  return (
    <CronfieldControlContainer
      name="SelectDaysOfTheMonth"
      unitPrefix={emptyDaysOfTheMonthSelection ? UnitPrefix.On : UnitPrefix.And}
      hidePrefix={emptySelection}
    >
      <FormWrapperSelect<string[]>
        labelledBy="format"
        width={emptySelection ? 'large' : 'medium'}
        selectProps={{
          placeholder: translate('Every day of the week'),
          allowClear: true,
          value: props.days,
          onChange: props.onChange,
          mode: 'multiple'
        }}
        selectOptions={selectOptions.map(option => {
          return { ...option, label: translate(option.label) }
        })}
      />
    </CronfieldControlContainer>
  )
}

export default observer(SelectDaysOfTheWeek)
