import type { Maybe } from '@@types/helpers'
import { replacer, reviver } from '@libs/json/utils'
import { getLogger } from '@libs/logger'

const logger = getLogger()

/**
 * Very simple memoization tool to cache data.
 */
type SerializedKey = string
type SerializedValue = string

interface IKeepSakeStats {
  cacheHits: Map<SerializedKey, number>
}

export default class Keepsake {
  private _store: Map<SerializedKey, SerializedValue> = new Map()
  private _stats: IKeepSakeStats = {
    cacheHits: new Map()
  }

  /**
   * Memoize the results of `fn` according to `args`.
   */
  memoFunction<A, V>(fn: (...args: any[]) => V) {
    return {
      width: (...args: any[]): V => {
        const results = this._getFromStore<A, V>(args)

        if (results) {
          const [serializedKey, memoValue] = results

          if (memoValue !== null) {
            this._addCacheHit(serializedKey)

            return memoValue
          }
        }

        const value = fn(...args)

        this._saveInStore(args, value)

        return value
      }
    }
  }

  /**
   * Dump the internal stores and statistics.
   */
  inspectStore(): void {
    logger.info('Store:', JSON.stringify(this._store, replacer, 2))
    logger.info('Stats:', JSON.stringify(this._stats, replacer, 2))
  }

  /* Private */

  /**
   * Retrieve a value from the store.
   */
  private _getFromStore<A, V>(key: A[]): Maybe<[SerializedKey, V]> {
    const serializedKey = this._serializeKey(key)
    const serializedValue = this._store.get(serializedKey)

    if (serializedValue === undefined) {
      return null
    }

    const value = this._unserialize<V>(serializedValue)

    if (!value) {
      return null
    }

    return [serializedKey, value]
  }

  /**
   * Save a value in the store.
   */
  private _saveInStore<A, V>(key: A[], value: V): this {
    const serializedKey = this._serializeKey(key)
    const serializedValue = this._serializeValue(value)

    this._store.set(serializedKey, serializedValue)

    return this
  }

  /**
   * Serialize the arguments to compute a key used in the store.
   */
  private _serializeKey<A>(...args: A[]): SerializedKey {
    return JSON.stringify(args, replacer)
  }

  /**
   * Serialize the value to store in the store.
   */
  private _serializeValue<V>(value: V): SerializedValue {
    return JSON.stringify(value, replacer)
  }

  /**
   * Unserialize the key or the value from the store.
   */
  private _unserialize<V>(value: SerializedKey | SerializedValue): Maybe<V> {
    try {
      return JSON.parse(value, reviver)
    } catch (err) {
      logger.error('[Keepsake]', 'Error when decoding value')
      return null
    }
  }

  /**
   * Increment the cacheHits counts when the value has been memoized.
   */
  private _addCacheHit(serializedKey: SerializedKey): this {
    this._stats.cacheHits.set(
      serializedKey,
      (this._stats.cacheHits.get(serializedKey) ?? 0) + 1
    )

    return this
  }
}
