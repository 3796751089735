import type { Perhaps } from '@@types/helpers'
import type { StoreInputHealthChecks } from '@app/stores'
import type { HealthCheckCodenameKey } from '@app/stores/StoreInputHealthChecks/types'
import { If } from '@libs/fp-helpers/If'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import type { TreeProps } from 'antd/lib/tree'

/**
 * Open the drawer of the healthchecknames selection.
 */
export const handleInputHealthCheckNamesOnLoad =
  (storeInputHealthChecks: StoreInputHealthChecks) =>
  (loadAtMount: Perhaps<boolean>) =>
  () => {
    if (!loadAtMount) {
      return
    }

    If(!storeInputHealthChecks.healthChecksTemplatesNames.size).fetch(() =>
      storeInputHealthChecks.fetchHealthChecksTemplatesNames()
    )
  }

/**
 * Open the drawer of the healthchecknames selection.
 */
export const handleInputHealthChecksOnClick =
  (storeInputHealthCheckNames: StoreInputHealthChecks) => () => {
    storeInputHealthCheckNames.storeDrawer.openDrawer()
  }

/**
 * When searching a HealthCheckName and unselect other healthchecknames.
 *
 * The user have to select again once it has finished his search.
 */
export const handleHealthChecksSearch =
  (storeInputHealthChecks: StoreInputHealthChecks) => () => {
    storeInputHealthChecks.expandAllHealthCheckTypes()
    storeInputHealthChecks.unselectAllHealthChecksTemplatesNames()
  }

/**
 * Select or unselect all healthchecknames.
 */
export const handleHealthChecksSelectAllOnClick =
  (storeInputHealthChecks: StoreInputHealthChecks, withExpand?: boolean) =>
  (e: CheckboxChangeEvent) => {
    e.target.checked
      ? storeInputHealthChecks.selectAllHealthChecksTemplatesNames(withExpand)
      : storeInputHealthChecks.unselectAllHealthChecksTemplatesNames()
  }

/**
 * Expand or collapse all health check types.
 */
export const handleExpandAllHealthCheckTypesOnClick =
  (storeInputHealthChecks: StoreInputHealthChecks) =>
  (e: CheckboxChangeEvent) => {
    e.target.checked
      ? storeInputHealthChecks.expandAllHealthCheckTypes()
      : storeInputHealthChecks.collapseAllHealthCheckTypes()
  }

/**
 * Expand of collapse the health check types tree.
 */
export const handleHealthCheckTypeExpandOnClick =
  (storeInputHealthChecks: StoreInputHealthChecks) =>
  (expandedKeys: TreeProps['expandedKeys']) => {
    if (!Array.isArray(expandedKeys)) {
      return
    }
    storeInputHealthChecks.toggleExpandHealthCheckType(
      expandedKeys.map(k => String(k) as HealthCheckCodenameKey)
    )
  }

/**
 * Toggle selection of a health check type.
 */
export const handleHealthCheckTypeSelection =
  (storeInputHealthChecks: StoreInputHealthChecks) =>
  (healthCheckCodenameKey: HealthCheckCodenameKey) =>
  () => {
    storeInputHealthChecks.toggleSelectHealthCheckType(healthCheckCodenameKey)
  }

/**
 * Toggle selection of a HealthCheckName.
 */
export const handleHealthCheckSelection =
  (storeInputHealthChecks: StoreInputHealthChecks) =>
  (healthCheckId: string) =>
  () => {
    storeInputHealthChecks.toggleSelectHealthChecksTemplatesName(healthCheckId)
  }

/**
 * Validate the HealthCheckName selection.
 */
export const handleHealthChecksDrawerOnSubmit =
  (
    storePendingHealthChecks: StoreInputHealthChecks,
    storeInputHealthChecks: StoreInputHealthChecks
  ) =>
  (onValidate?: () => void) =>
  () => {
    storeInputHealthChecks.replaceSelectedHealthChecksTemplatesNames(
      storePendingHealthChecks.selectedHealthChecksTemplatesNames
    )
    storeInputHealthChecks.storeDrawer.closeDrawer()

    if (onValidate) {
      onValidate()
    }
  }
