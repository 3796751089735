import * as React from 'react'
import styled from 'styled-components'

export interface ICellDateProps {
  className?: string
  children: string
}

const CellDate: React.FC<ICellDateProps> = props => {
  return <div className={props.className}>{props.children}</div>
}

export default styled(CellDate)`
  width: 250px !important;
`
