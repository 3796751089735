import { ContainerFlex } from '@app/components/Container'
import FormWrapperButtonFancy from '@app/components-legacy/Form/Wrappers/ButtonFancy'
import { useAppRouter, useAppTranslator } from '@app/hooks'
import { AppRouteName } from '@app/routes'
import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  display: flex;

  :hover {
    text-decoration: none;
  }
`

interface IAttackPathTier0ButtonsProps {
  className?: string
}

const AttackPathTier0Buttons: React.FC<
  IAttackPathTier0ButtonsProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.Tier0.Buttons']
  })

  const appRouter = useAppRouter()

  const attackPathTier0AssetsUrl = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.AttackPath_Tier0_Assets,
    parameters: {}
  })

  const attackPathTier0AccountsUrl = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.AttackPath_Tier0_Accounts,
    parameters: {}
  })

  return (
    <ContainerFlex
      className={props.className}
      name="AttackPathTier0"
      flexWrap="wrap"
      justifyContent="center"
      items={[
        <StyledLink to={attackPathTier0AssetsUrl}>
          <FormWrapperButtonFancy
            labelledBy="attack-path-tier0-assets"
            label={translate('What are my privileged assets')}
            description={translate('Identification of Tier 0 assets')}
          />
        </StyledLink>,
        <StyledLink to={attackPathTier0AccountsUrl}>
          <FormWrapperButtonFancy
            labelledBy="attack-path-tier0-accounts"
            label={translate('Who has control over my privileged assets')}
            description={translate(
              'A lot of user and computer accounts can become privileged'
            )}
          />
        </StyledLink>
      ]}
      flexGap="large"
    />
  )
}

export default styled(AttackPathTier0Buttons)`
  padding: ${({ theme }) => theme.tokens['padding/extraLarge']} 0;
`
