import {
  canAccessToIoABoard,
  canAccessToIoAExport
} from '@app/pages/IoA/IoABoardPage/permissions'
import { canAccessToTrailFlow } from '@app/pages/TrailFlow/permissions'
import {
  rbacCapabilityCheckAllOf,
  rbacCapabilityCheckAnyOf
} from '@libs/rbac/functions'
import {
  readUiIoaAttackDescription,
  readUiIoaAttackYaraRules,
  readUiIoaCloseAttack,
  readUiIoaRemoveAttack
} from '@libs/rbac/permissions'

export const canAccessToAttackDescription = rbacCapabilityCheckAllOf(
  canAccessToIoABoard,
  readUiIoaAttackDescription()
)

export const canAccessToAttackYaraRules = rbacCapabilityCheckAllOf(
  canAccessToIoABoard,
  readUiIoaAttackYaraRules()
)

export const canAccessToAttackTrailflow = rbacCapabilityCheckAllOf(
  canAccessToIoABoard,
  canAccessToTrailFlow
)

export const canAccessToAttackDetails = rbacCapabilityCheckAnyOf(
  canAccessToAttackDescription,
  canAccessToAttackYaraRules
  // TODO: add canAccessToIoATrailflow when the trailflow tab is implemented
)

export const canAccessToAttackClose = rbacCapabilityCheckAllOf(
  canAccessToIoABoard,
  readUiIoaCloseAttack()
)

export const canAccessToAttackRemove = rbacCapabilityCheckAllOf(
  canAccessToIoABoard,
  readUiIoaRemoveAttack()
)

export const canAccessToAttackActions = rbacCapabilityCheckAnyOf(
  canAccessToAttackClose,
  canAccessToAttackRemove,
  canAccessToIoAExport
)

export const canAccessToAttacksExport =
  rbacCapabilityCheckAllOf(canAccessToIoABoard)
