import { ContainerFlex } from '@app/components-legacy/Container'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import * as React from 'react'
import styled from 'styled-components'
import { setTableColumnsStyled } from '../IoAAttacksTable/styles'

interface IIoAAttacksTableHeadProps {
  className?: string
}

const IoAAttacksTableHead: React.FC<IIoAAttacksTableHeadProps> = props => {
  const translate = useAppTranslator({ namespaces: ['IoA.Attacks'] })

  return (
    <ContainerFlex
      name="IoAAttacksTableHead"
      className={props.className}
      itemsFlexGrow={[0, 1]}
      items={[
        <div className="attackDate">{translate('Date')}</div>,

        <ContainerFlex
          name="IoAAttacksTableHeadColumns"
          className="attackColumns"
          items={[
            <div className="attackSource">{translate('Source')}</div>,

            <div className="attackVector">{translate('Attack Vector')}</div>,

            <div className="attackDestination">{translate('Destination')}</div>,

            <div className="attackName">{translate('Attack Name')}</div>,

            <div className="attackDirectory">{translate('Domain')}</div>,

            <div className="attackExpandButton"></div>,

            <div className="attackCheckbox"></div>
          ]}
        />
      ]}
    />
  )
}

const StyledIoAAttacksTableHead = setTableColumnsStyled(IoAAttacksTableHead, {
  header: true
})

export default styled(StyledIoAAttacksTableHead)`
  text-align: center;
  font-weight: 600;
`
