import type {
  PatternsMap,
  SddlAceFlags,
  SddlAceIntRights,
  SddlAceRights,
  SddlAceTypes,
  SddlDaclFlags,
  SddlSids,
  WellKnownSids,
  WellKnownGuids,
  WellKnownExtendedGuids
} from './types'
import {
  SddlAceFlagsEnum,
  SddlAceRightsEnum,
  SddlAceTypeEnum,
  SddlDaclFlagsEnum,
  SddlSidsEnum,
  WellKnownSidsEnum,
  WellKnownGuidsEnum,
  WellKnownExtendedGuidsEnum
} from './types'

export const sddlAceTypes: SddlAceTypes = new Map([
  [SddlAceTypeEnum.A, 'ACCESS_ALLOWED_ACE_TYPE'],
  [SddlAceTypeEnum.D, 'ACCESS_DENIED_ACE_TYPE'],
  [SddlAceTypeEnum.OA, 'ACCESS_ALLOWED_OBJECT_ACE_TYPE'],
  [SddlAceTypeEnum.OD, 'ACCESS_DENIED_OBJECT_ACE_TYPE'],
  [SddlAceTypeEnum.AU, 'SYSTEM_AUDIT_ACE_TYPE'],
  [SddlAceTypeEnum.AL, 'SYSTEM_ALARM_ACE_TYPE'],
  [SddlAceTypeEnum.OU, 'SYSTEM_AUDIT_OBJECT_ACE_TYPE'],
  [SddlAceTypeEnum.OL, 'SYSTEM_ALARM_OBJECT_ACE_TYPE'],
  [SddlAceTypeEnum.ML, 'SYSTEM_MANDATORY_LABEL_ACE_TYPE'],
  [SddlAceTypeEnum.XA, 'ACCESS_ALLOWED_CALLBACK_ACE_TYPE'],
  [SddlAceTypeEnum.XD, 'ACCESS_DENIED_CALLBACK_ACE_TYPE'],
  [SddlAceTypeEnum.RA, 'SYSTEM_RESOURCE_ATTRIBUTE_ACE_TYPE'],
  [SddlAceTypeEnum.SP, 'SYSTEM_SCOPED_POLICY_ID_ACE_TYPE'],
  [SddlAceTypeEnum.XU, 'SYSTEM_AUDIT_CALLBACK_ACE_TYPE'],
  [SddlAceTypeEnum.ZA, 'ACCESS_ALLOWED_CALLBACK_ACE_TYPE']
])

export const sddlAceFlags: SddlAceFlags = new Map([
  [SddlAceFlagsEnum.CI, 'CONTAINER_INHERIT_ACE'],
  [SddlAceFlagsEnum.OI, 'OBJECT_INHERIT_ACE'],
  [SddlAceFlagsEnum.NP, 'NO_PROPAGATE_INHERIT_ACE'],
  [SddlAceFlagsEnum.IO, 'INHERIT_ONLY_ACE'],
  [SddlAceFlagsEnum.ID, 'INHERITED_ACE'],
  [SddlAceFlagsEnum.SA, 'SUCCESSFUL_ACCESS_ACE_FLAG'],
  [SddlAceFlagsEnum.FA, 'FAILED_ACCESS_ACE_FLAG']
])

export const sddlAceRights: SddlAceRights = new Map([
  // Generic access rights
  [SddlAceRightsEnum.GA, 'GENERIC_ALL'],
  [SddlAceRightsEnum.GR, 'GENERIC_READ'],
  [SddlAceRightsEnum.GW, 'GENERIC_WRITE'],
  [SddlAceRightsEnum.GX, 'GENERIC_EXECUTE'],

  // Standard access rights
  [SddlAceRightsEnum.RC, 'READ_CONTROL'],
  [SddlAceRightsEnum.SD, 'DELETE'],
  [SddlAceRightsEnum.WD, 'WRITE_DAC'],
  [SddlAceRightsEnum.WO, 'WRITE_OWNER'],

  // Directory service object access rights
  [SddlAceRightsEnum.RP, 'ADS_RIGHT_DS_READ_PROP'],
  [SddlAceRightsEnum.WP, 'ADS_RIGHT_DS_WRITE_PROP'],
  [SddlAceRightsEnum.CC, 'ADS_RIGHT_DS_CREATE_CHILD'],
  [SddlAceRightsEnum.DC, 'ADS_RIGHT_DS_DELETE_CHILD'],
  [SddlAceRightsEnum.LC, 'ADS_RIGHT_ACTRL_DS_LIST'],
  [SddlAceRightsEnum.SW, 'ADS_RIGHT_DS_SELF'],
  [SddlAceRightsEnum.LO, 'ADS_RIGHT_DS_LIST_OBJECT'],
  [SddlAceRightsEnum.DT, 'ADS_RIGHT_DS_DELETE_TREE'],
  [SddlAceRightsEnum.CR, 'ADS_RIGHT_DS_CONTROL_ACCESS'],

  // File access rights
  [SddlAceRightsEnum.FA, 'FILE_ALL_ACCESS'],
  [SddlAceRightsEnum.FR, 'FILE_GENERIC_READ'],
  [SddlAceRightsEnum.FW, 'FILE_GENERIC_WRITE'],
  [SddlAceRightsEnum.FX, 'FILE_GENERIC_EXECUTE'],

  // Registry key access rights
  [SddlAceRightsEnum.KA, 'KEY_ALL_ACCESS'],
  [SddlAceRightsEnum.KR, 'KEY_READ'],
  [SddlAceRightsEnum.KW, 'KEY_WRITE'],
  [SddlAceRightsEnum.KX, 'KEY_EXECUTE'],

  // Mandatory label rights
  [SddlAceRightsEnum.NR, 'SYSTEM_MANDATORY_LABEL_NO_READ_UP'],
  [SddlAceRightsEnum.NW, 'SYSTEM_MANDATORY_LABEL_NO_WRITE_UP'],
  [SddlAceRightsEnum.NX, 'SYSTEM_MANDATORY_LABEL_NO_EXECUTE']
])

export const sddlAceIntRights: SddlAceIntRights = [
  // Generic access rights
  [0x10000000, 'GENERIC_ALL'],
  [0x80000000, 'GENERIC_READ'],
  [0x40000000, 'GENERIC_WRITE'],
  [0x20000000, 'GENERIC_EXECUTE'],

  // File access rights
  [0x1f01ff, 'FILE_ALL_ACCESS'],
  [0x120089, 'FILE_GENERIC_READ'],
  [0x120116, 'FILE_GENERIC_WRITE'],
  [0x1200a0, 'FILE_GENERIC_EXECUTE'],

  // Standard access rights
  [0x20000, 'READ_CONTROL'],
  [0x00010000, 'DELETE'],
  [0x40000, 'WRITE_DAC'],
  [0x80000, 'WRITE_OWNER'],

  // Registry key access rights
  [0xf003f, 'KEY_ALL_ACCESS'],
  [0x20019, 'KEY_READ'],
  [0x20006, 'KEY_WRITE'],
  [0x20019, 'KEY_EXECUTE'],

  // Directory service object access rights
  [0x10, 'ADS_RIGHT_DS_READ_PROP'],
  [0x20, 'ADS_RIGHT_DS_WRITE_PROP'],
  [0x1, 'ADS_RIGHT_DS_CREATE_CHILD'],
  [0x2, 'ADS_RIGHT_DS_DELETE_CHILD'],
  [0x4, 'ADS_RIGHT_ACTRL_DS_LIST'],
  [0x8, 'ADS_RIGHT_DS_SELF'],
  [0x80, 'ADS_RIGHT_DS_LIST_OBJECT'],
  [0x40, 'ADS_RIGHT_DS_DELETE_TREE'],
  [0x100, 'ADS_RIGHT_DS_CONTROL_ACCESS'],

  // Mandatory label rights
  [0x2, 'SYSTEM_MANDATORY_LABEL_NO_READ_UP'],
  [0x1, 'SYSTEM_MANDATORY_LABEL_NO_WRITE_UP'],
  [0x4, 'SYSTEM_MANDATORY_LABEL_NO_EXECUTE']
]

export const sddlDaclFlags: SddlDaclFlags = new Map([
  [SddlDaclFlagsEnum.P, 'SE_DACL_PROTECTED'],
  [SddlDaclFlagsEnum.AR, 'SE_DACL_AUTO_INHERIT_REQ'],
  [SddlDaclFlagsEnum.AI, 'SE_DACL_AUTO_INHERITED'],
  [SddlDaclFlagsEnum.NO_ACCESS_CONTROL, 'NULL_ACL']
])

export const sddlSids: SddlSids = new Map([
  [SddlSidsEnum.AA, 'Access Control Assistance Operators'],
  [SddlSidsEnum.AC, 'All App Packages'],
  [SddlSidsEnum.AN, 'Anonymous'],
  [SddlSidsEnum.AO, 'Account Operators'],
  [SddlSidsEnum.AP, 'Protected Users'],
  [SddlSidsEnum.AS, 'Authentication Authority Asserted Identity'],
  [SddlSidsEnum.AU, 'Authenticated Users'],
  [SddlSidsEnum.BA, 'Administrators'],
  [SddlSidsEnum.BG, 'Guests'],
  [SddlSidsEnum.BO, 'Backup Operators'],
  [SddlSidsEnum.BU, 'Users'],
  [SddlSidsEnum.CA, 'Cert Publishers'],
  [SddlSidsEnum.CD, 'Certificate Service DCOM Access'],
  [SddlSidsEnum.CG, 'Creator Group'],
  [SddlSidsEnum.CN, 'Cloneable Domain Controllers'],
  [SddlSidsEnum.CO, 'Creator Owner'],
  [SddlSidsEnum.CY, 'Cryptographic Operators'],
  [SddlSidsEnum.DA, 'Domain Admins'],
  [SddlSidsEnum.DC, 'Domain Computers'],
  [SddlSidsEnum.DD, 'Domain Controllers'],
  [SddlSidsEnum.DG, 'Domain Guests'],
  [SddlSidsEnum.DU, 'Domain Users'],
  [SddlSidsEnum.EA, 'Enterprise Admins'],
  [SddlSidsEnum.ED, 'Enterprise Domain Controllers'],
  [SddlSidsEnum.EK, 'Enterprise Key Admins'],
  [SddlSidsEnum.ER, 'Event Log Readers'],
  [SddlSidsEnum.ES, 'RDS Endpoint Servers'],
  [SddlSidsEnum.HA, 'Hyper-V Administrators'],
  [SddlSidsEnum.HI, 'High Mandatory Level'],
  [SddlSidsEnum.IS, 'IIS_IUSRS'],
  [SddlSidsEnum.IU, 'Interactive'],
  [SddlSidsEnum.KA, 'Key Admins'],
  [SddlSidsEnum.LA, 'Administrator'],
  [SddlSidsEnum.LG, 'Guest'],
  [SddlSidsEnum.LS, 'Local Service'],
  [SddlSidsEnum.LU, 'Performance Log Users'],
  [SddlSidsEnum.LW, 'Low Mandatory Level'],
  [SddlSidsEnum.ME, 'Medium Mandatory Level'],
  [SddlSidsEnum.MP, 'Medium Plus Mandatory Level'],
  [SddlSidsEnum.MS, 'RDS Management Servers'],
  [SddlSidsEnum.MU, 'Performance Monitor Users'],
  [SddlSidsEnum.NO, 'Network Configuration Operators'],
  [SddlSidsEnum.NS, 'Network Service'],
  [SddlSidsEnum.NU, 'Network'],
  [SddlSidsEnum.OW, 'Owner Rights'],
  [SddlSidsEnum.PA, 'Group Policy Creator Owners'],
  [SddlSidsEnum.PO, 'Print Operators'],
  [SddlSidsEnum.PS, 'Principal Self'],
  [SddlSidsEnum.PU, 'Power Users'],
  [SddlSidsEnum.RA, 'RDS Remote Access Servers'],
  [SddlSidsEnum.RC, 'Restricted Code'],
  [SddlSidsEnum.RD, 'Remote Desktop Users'],
  [SddlSidsEnum.RE, 'Replicators'],
  [SddlSidsEnum.RM, 'Remote Management Users'],
  [SddlSidsEnum.RO, 'Enterprise Read-only Domain Controllers'],
  [SddlSidsEnum.RS, 'RAS and IAS Servers'],
  [SddlSidsEnum.RU, 'Pre-Windows 2000 Compatible Access'],
  [SddlSidsEnum.SA, 'Schema Admins'],
  [SddlSidsEnum.SI, 'System Mandatory Level'],
  [SddlSidsEnum.SO, 'Server Operators'],
  [SddlSidsEnum.SS, 'Service Asserted Identity'],
  [SddlSidsEnum.SU, 'Service'],
  [SddlSidsEnum.SY, 'Local System'],
  [SddlSidsEnum.UD, 'User-Mode Driver Process'],
  [SddlSidsEnum.WD, 'Everyone'],
  [SddlSidsEnum.WR, 'Write Restricted Code']
])

// prettier-ignore
export const wellKnownSids: WellKnownSids = new Map([
  [WellKnownSidsEnum['S-1-0-0'], 'Nobody'],
  [WellKnownSidsEnum['S-1-0'], 'Null Authority'],
  [WellKnownSidsEnum['S-1-1-0'], 'Everyone'],
  [WellKnownSidsEnum['S-1-1'], 'World Authority'],
  [WellKnownSidsEnum['S-1-2-0'], 'Local'],
  [WellKnownSidsEnum['S-1-2-1'], 'Console Logon'],
  [WellKnownSidsEnum['S-1-2'], 'Local Authority'],
  [WellKnownSidsEnum['S-1-3-0'], 'Creator Owner'],
  [WellKnownSidsEnum['S-1-3-1'], 'Creator Group'],
  [WellKnownSidsEnum['S-1-3-2'], 'Creator Owner Server'],
  [WellKnownSidsEnum['S-1-3-3'], 'Creator Group Server'],
  [WellKnownSidsEnum['S-1-3-4'], 'Owner Rights'],
  [WellKnownSidsEnum['S-1-3'], 'Creator Authority'],
  [WellKnownSidsEnum['S-1-4'], 'Non-unique Authority'],
  [WellKnownSidsEnum['S-1-5-1'], 'Dialup'],
  [WellKnownSidsEnum['S-1-5-2'], 'Network'],
  [WellKnownSidsEnum['S-1-5-3'], 'Batch'],
  [WellKnownSidsEnum['S-1-5-4'], 'Interactive'],
  [WellKnownSidsEnum['S-1-5-6'], 'Service'],
  [WellKnownSidsEnum['S-1-5-7'], 'Anonymous Logon'],
  [WellKnownSidsEnum['S-1-5-8'], 'Proxy'],
  [WellKnownSidsEnum['S-1-5-10'], 'Principal Self'],
  [WellKnownSidsEnum['S-1-5-11'], 'Authenticated Users'],
  [WellKnownSidsEnum['S-1-5-12'], 'Restricted Code'],
  [WellKnownSidsEnum['S-1-5-13'], 'Terminal Server User'],
  [WellKnownSidsEnum['S-1-5-14'], 'Remote Interactive Logon'],
  [WellKnownSidsEnum['S-1-5-15'], 'This Organization'],
  [WellKnownSidsEnum['S-1-5-17'], 'IUSR'],
  [WellKnownSidsEnum['S-1-5-18'], 'Local System'],
  [WellKnownSidsEnum['S-1-5-19'], 'Local Service'],
  [WellKnownSidsEnum['S-1-5-20'], 'Network Service'],
  [WellKnownSidsEnum['S-1-5-32-545'], 'Users'],
  [WellKnownSidsEnum['S-1-5-32-546'], 'Guests'],
  [WellKnownSidsEnum['S-1-5-32-547'], 'Power Users'],
  [WellKnownSidsEnum['S-1-5-32-553'], 'BUILTIN\\RAS and IAS Servers'],
  [WellKnownSidsEnum['S-1-5-32-554'], 'Pre-Windows 2000 Compatible Access'],
  [WellKnownSidsEnum['S-1-5-32-555'], 'Remote Desktop Users'],
  [WellKnownSidsEnum['S-1-5-32-556'], 'Network Configuration Operators'],
  [WellKnownSidsEnum['S-1-5-32-557'], 'Incoming Forest Trust Builders'],
  [WellKnownSidsEnum['S-1-5-32-558'], 'Performance Monitor Users'],
  [WellKnownSidsEnum['S-1-5-32-559'], 'Performance Log Users'],
  [WellKnownSidsEnum['S-1-5-32-560'], 'Windows Authorization Access Group'],
  [WellKnownSidsEnum['S-1-5-32-561'], 'Terminal Server License Servers'],
  [WellKnownSidsEnum['S-1-5-32-562'], 'Distributed COM Users'],
  [WellKnownSidsEnum['S-1-5-32-568'], 'IIS_IUSRS'],
  [WellKnownSidsEnum['S-1-5-32-569'], 'Cryptographic Operators'],
  [WellKnownSidsEnum['S-1-5-32-571'], 'Cacheable Principals'],
  [WellKnownSidsEnum['S-1-5-32-572'], 'Non-Cacheable Principals'],
  [WellKnownSidsEnum['S-1-5-32-573'], 'Event Log Readers'],
  [WellKnownSidsEnum['S-1-5-32-574'], 'Certificate Service DCOM Access'],
  [WellKnownSidsEnum['S-1-5-32-575'], 'RDS Remote Access Servers'],
  [WellKnownSidsEnum['S-1-5-32-576'], 'RDS Endpoint Servers'],
  [WellKnownSidsEnum['S-1-5-32-577'], 'RDS Management Servers'],
  [WellKnownSidsEnum['S-1-5-32-578'], 'Hyper-V Administrators'],
  [WellKnownSidsEnum['S-1-5-32-579'], 'Access Control Assistance Operators'],
  [WellKnownSidsEnum['S-1-5-32-580'], 'Remote Management Users'],
  [WellKnownSidsEnum['S-1-5-32-581'], 'System Managed Accounts Group'],
  [WellKnownSidsEnum['S-1-5-32-582'], 'Storage Replica Administrators'],
  [WellKnownSidsEnum['S-1-5-32-583'], 'Device Owners'],
  [WellKnownSidsEnum['S-1-5-33'], 'Write Restricted Code'],
  [WellKnownSidsEnum['S-1-5-64-10'], 'NTLM Authentication'],
  [WellKnownSidsEnum['S-1-5-64-14'], 'SChannel Authentication'],
  [WellKnownSidsEnum['S-1-5-64-21'], 'Digest Authentication'],
  [WellKnownSidsEnum['S-1-5-80-0'], 'NT SERVICES\\ALL SERVICES'],
  [WellKnownSidsEnum['S-1-5-80-956008885-3418522649-1831038044-1853292631-2271478464'], 'Trusted Installer'],
  [WellKnownSidsEnum['S-1-5-83-0'], 'NT VIRTUAL MACHINE\\Virtual Machines'],
  [WellKnownSidsEnum['S-1-5-84-0-0-0-0-0'], 'User-Mode Driver Process'],
  [WellKnownSidsEnum['S-1-5-113'], 'Local account'],
  [WellKnownSidsEnum['S-1-5-114'], 'Local account and member of Administrators group'],
  [WellKnownSidsEnum['S-1-5-1000'], 'Other Organization'],
  [WellKnownSidsEnum['S-1-5'], 'NT Authority'],
  [WellKnownSidsEnum['S-1-15-2-1'], 'All App Packages'],
  [WellKnownSidsEnum['S-1-16-0'], 'Untrusted Mandatory Level'],
  [WellKnownSidsEnum['S-1-16-4096'], 'Low Mandatory Level'],
  [WellKnownSidsEnum['S-1-16-8192'], 'Medium Mandatory Level'],
  [WellKnownSidsEnum['S-1-16-8448'], 'Medium Plus Mandatory Level'],
  [WellKnownSidsEnum['S-1-16-12288'], 'High Mandatory Level'],
  [WellKnownSidsEnum['S-1-16-16384'], 'System Mandatory Level'],
  [WellKnownSidsEnum['S-1-16-20480'], 'Protected Process Mandatory Level'],
  [WellKnownSidsEnum['S-1-16-28672'], 'Secure Process Mandatory Level'],
  [WellKnownSidsEnum['S-1-18-1'], 'Authentication Authority Asserted Identity'],
  [WellKnownSidsEnum['S-1-18-2'], 'Service Asserted Identity'],
  [WellKnownSidsEnum['S-1-18-3'], 'Fresh Public Key Identity'],
  [WellKnownSidsEnum['S-1-18-4'], 'Key Trust'],
  [WellKnownSidsEnum['S-1-18-5'], 'MFA Key Property'],
  [WellKnownSidsEnum['S-1-18-6'], 'Attested Key Property']
])

// Separate Well Known Sids with a matching pattern that will not be used
// in the parser. Only used to guess the user-friendly definition label.
export const wellKnownSidsPatterns: WellKnownSids = new Map([
  [WellKnownSidsEnum['S-1-5-21-.*-500'], 'Administrator'],
  [WellKnownSidsEnum['S-1-5-21-.*-501'], 'Guest'],
  [WellKnownSidsEnum['S-1-5-21-.*-502'], 'krbtgt'],
  [WellKnownSidsEnum['S-1-5-21-.*-513'], 'Domain Users'],
  [WellKnownSidsEnum['S-1-5-21-.*-514'], 'Domain Guests'],
  [WellKnownSidsEnum['S-1-5-21-.*-515'], 'Domain Computers'],
  [WellKnownSidsEnum['S-1-5-21-.*-522'], 'Cloneable Domain Controllers'],
  [WellKnownSidsEnum['S-1-5-21-.*-525'], 'Protected Users'],
  [WellKnownSidsEnum['S-1-5-21-.*-526'], 'Key Admins'],
  [WellKnownSidsEnum['S-1-5-21-.*-553'], 'RAS and IAS Servers'],
  [WellKnownSidsEnum['S-1-5-21-.*-571'], 'Allowed RODC Password Replication Group'],
  [WellKnownSidsEnum['S-1-5-21-.*-572'], 'Denied RODC Password Replication Group']
])

export const wellKnownSidsWithAce: WellKnownSids = new Map([
  [WellKnownSidsEnum['S-1-5-9'], 'Enterprise Domain Controllers'],
  [WellKnownSidsEnum['S-1-5-18'], 'Local System'],
  [WellKnownSidsEnum['S-1-5-32-544'], 'Administrators'],
  [WellKnownSidsEnum['S-1-5-32-548'], 'Account Operators'],
  [WellKnownSidsEnum['S-1-5-32-549'], 'Server Operators'],
  [WellKnownSidsEnum['S-1-5-32-550'], 'Print Operators'],
  [WellKnownSidsEnum['S-1-5-32-551'], 'Backup Operators'],
  [WellKnownSidsEnum['S-1-5-32-552'], 'Replicators']
])

export const wellKnownFilteredTrusteeSids = new Map([
  [WellKnownSidsEnum['S-1-3-0'], 'Creator Owner'],
  [WellKnownSidsEnum['S-1-5-10'], 'Principal Self']
])

// Separate Well Known Sids with a matching pattern that will not be used
// in the parser. Only used to guess the user-friendly definition label.
export const wellKnownSidsWithAcePatterns: WellKnownSids = new Map([
  [WellKnownSidsEnum['S-1-5-21-.*-498'], 'Enterprise Read-only Domain Controllers'],
  [WellKnownSidsEnum['S-1-5-21-.*-512'], 'Domain Admins'],
  [WellKnownSidsEnum['S-1-5-21-.*-516'], 'Domain Controllers'],
  [WellKnownSidsEnum['S-1-5-21-.*-517'], 'Cert Publishers'],
  [WellKnownSidsEnum['S-1-5-21-.*-518'], 'Schema Admins'],
  [WellKnownSidsEnum['S-1-5-21-.*-519'], 'Enterprise Admins'],
  [WellKnownSidsEnum['S-1-5-21-.*-520'], 'Group Policy Creator Owners'],
  [WellKnownSidsEnum['S-1-5-21-.*-521'], 'Read-only Domain Controllers'],
  [WellKnownSidsEnum['S-1-5-21-.*-526'], 'Key Admins'],
  [WellKnownSidsEnum['S-1-5-21-.*-527'], 'Enterprise Key Admins']
])

/* Used for permissions in control ACEs */

export const wellKnownGuidsWithSelfWrite: WellKnownGuids = new Map([
  [WellKnownGuidsEnum['bf9679c0-0de6-11d0-a285-00aa003049e2'], 'Self-Membership'],
  [WellKnownGuidsEnum['72e39547-7b18-11d1-adef-00c04fd8d5cd'], 'Validated-DNS-Host-Name'],
  [
    WellKnownGuidsEnum['80863791-dbe9-4eb8-837e-7f0ab55d9ac7'],
    'Validated-MS-DS-Additional-DNS-Host-Name'
  ],
  [WellKnownGuidsEnum['d31a8757-2447-4545-8081-3bb610cacbf2'], 'Validated-MS-DS-Behavior-Version'],
  [WellKnownGuidsEnum['f3a64788-5306-11d1-a9c5-0000f80367c1'], 'Validated-SPN'],
  [WellKnownGuidsEnum['9b026da6-0d3c-465c-8bee-5199d7165cba'], 'DS-Validated-Write-Computer']
])

export const wellKnownGuidsWithWriteProperties: WellKnownGuids = new Map([
  [WellKnownGuidsEnum['00000000-0000-0000-0000-000000000000'], 'empty'],
  [WellKnownGuidsEnum['f30e3bbe-9ff0-11d1-b603-0000f80367c1'], 'gPLink'],
  [WellKnownGuidsEnum['f30e3bc1-9ff0-11d1-b603-0000f80367c1'], 'gPCFileSysPath'],
  [WellKnownGuidsEnum['bc0ac240-79a9-11d0-9020-00c04fc2d4cf'], 'groupMembership'],
  [WellKnownGuidsEnum['00fbf30c-91fe-11d1-aebc-0000f80367c1'], 'altSecurityIdentities'],
  [WellKnownGuidsEnum['e48d0154-bcf8-11d1-8702-00c04fb96050'], 'publicInformation'],
  [WellKnownGuidsEnum['5b47d60f-6090-40b2-9f37-2a4de88f3063'], 'msDS-KeyCredentialLink'],
  [
    WellKnownGuidsEnum['3f78c3e5-f79a-46bd-a0b8-9d18116ddc79'],
    'msDS-AllowedToActOnBehalfOfOtherIdentity'
  ],
  // Present in both lists in order to make sure that we don't consider a Validated Write the same way as a Write Property
  [WellKnownGuidsEnum['bf9679c0-0de6-11d0-a285-00aa003049e2'], 'member'],
  [WellKnownGuidsEnum['72e39547-7b18-11d1-adef-00c04fd8d5cd'], 'dNSHostName'],
  [WellKnownGuidsEnum['80863791-dbe9-4eb8-837e-7f0ab55d9ac7'], 'msDS-AdditionalDnsHostName'],
  [WellKnownGuidsEnum['d31a8757-2447-4545-8081-3bb610cacbf2'], 'msDS-Behavior-Version'],
  [WellKnownGuidsEnum['f3a64788-5306-11d1-a9c5-0000f80367c1'], 'servicePrincipalName'],
  [WellKnownGuidsEnum['9b026da6-0d3c-465c-8bee-5199d7165cba'], 'computer']
])

// prettier-ignore
export const wellKnownGuidsWithControlAccess: WellKnownExtendedGuids = new Map([
  [WellKnownExtendedGuidsEnum['00000000-0000-0000-0000-000000000000'], 'Empty'],
  [WellKnownExtendedGuidsEnum['00299570-246d-11d0-a768-00aa006e0529'], 'User-Force-Change-Password'],
  [WellKnownExtendedGuidsEnum['1131f6ad-9c07-11d1-f79f-00c04fc2dcd2'], 'DS-Replication-Get-Changes-All'],
  [WellKnownExtendedGuidsEnum['9923a32a-3607-11d2-b9be-0000f87a36b2'], 'DS-Install-Replica'],
  [WellKnownExtendedGuidsEnum['1131f6ac-9c07-11d1-f79f-00c04fc2dcd2'], 'DS-Replication-Manage-Topology'],
  [WellKnownExtendedGuidsEnum['1131f6ab-9c07-11d1-f79f-00c04fc2dcd2'], 'DS-Replication-Synchronize'],
])

// prettier-ignore
export const wellKnownExtendedGuids: WellKnownExtendedGuids = new Map([
  [WellKnownExtendedGuidsEnum['ee914b82-0a98-11d1-adbb-00c04fd8d5cd'], 'Abandon-Replication'],
  [WellKnownExtendedGuidsEnum['440820ad-65b4-11d1-a3da-0000f875ae0d'], 'Add-GUID'],
  [WellKnownExtendedGuidsEnum['1abd7cf8-0a99-11d1-adbb-00c04fd8d5cd'], 'Allocate-Rids'],
  [WellKnownExtendedGuidsEnum['68b1d179-0d15-4d4f-ab71-46152e79a7bc'], 'Allowed-To-Authenticate'],
  [WellKnownExtendedGuidsEnum['edacfd8f-ffb3-11d1-b41d-00a0c968f939'], 'Apply-Group-Policy'],
  [WellKnownExtendedGuidsEnum['0e10c968-78fb-11d2-90d4-00c04f79dc55'], 'Certificate-Enrollment'],
  [WellKnownExtendedGuidsEnum['a05b8cc2-17bc-4802-a710-e7c15ab866a2'], 'Certificate-AutoEnrollment'],
  [WellKnownExtendedGuidsEnum['014bf69c-7b3b-11d1-85f6-08002be74fab'], 'Change-Domain-Master'],
  [WellKnownExtendedGuidsEnum['cc17b1fb-33d9-11d2-97d4-00c04fd8d5cd'], 'Change-Infrastructure-Master'],
  [WellKnownExtendedGuidsEnum['bae50096-4752-11d1-9052-00c04fc2d4cf'], 'Change-PDC'],
  [WellKnownExtendedGuidsEnum['d58d5f36-0a98-11d1-adbb-00c04fd8d5cd'], 'Change-Rid-Master'],
  [WellKnownExtendedGuidsEnum['e12b56b6-0a95-11d1-adbb-00c04fd8d5cd'], 'Change-Schema-Master'],
  [WellKnownExtendedGuidsEnum['e2a36dc9-ae17-47c3-b58b-be34c55ba633'], 'Create-Inbound-Forest-Trust'],
  [WellKnownExtendedGuidsEnum['fec364e0-0a98-11d1-adbb-00c04fd8d5cd'], 'Do-Garbage-Collection'],
  [WellKnownExtendedGuidsEnum['ab721a52-1e2f-11d0-9819-00aa0040529b'], 'Domain-Administer-Server'],
  [WellKnownExtendedGuidsEnum['69ae6200-7f46-11d2-b9ad-00c04f79f805'], 'DS-Check-Stale-Phantoms'],
  [WellKnownExtendedGuidsEnum['2f16c4a5-b98e-432c-952a-cb388ba33f2e'], 'DS-Execute-Intentions-Script'],
  [WellKnownExtendedGuidsEnum['4ecc03fe-ffc0-4947-b630-eb672a8a9dbc'], 'DS-Query-Self-Quota'],
  [WellKnownExtendedGuidsEnum['1131f6aa-9c07-11d1-f79f-00c04fc2dcd2'], 'DS-Replication-Get-Changes'],
  [WellKnownExtendedGuidsEnum['89e95b76-444d-4c62-991a-0facbeda640c'], 'DS-Replication-Get-Changes-In-Filtered-Set'],
  [WellKnownExtendedGuidsEnum['f98340fb-7c5b-4cdb-a00b-2ebdfa115a96'], 'DS-Replication-Monitor-Topology'],
  [WellKnownExtendedGuidsEnum['05c74c5e-4deb-43b4-bd9f-86664c2a7fd5'], 'Enable-Per-User-Reversibly-Encrypted-Password'],
  [WellKnownExtendedGuidsEnum['b7b1b3de-ab09-4242-9e30-9980e5d322f7'], 'Generate-RSoP-Logging'],
  [WellKnownExtendedGuidsEnum['b7b1b3dd-ab09-4242-9e30-9980e5d322f7'], 'Generate-RSoP-Planning'],
  [WellKnownExtendedGuidsEnum['7c0e2a7c-a419-48e4-a995-10180aad54dd'], 'Manage-Optional-Features'],
  [WellKnownExtendedGuidsEnum['ba33815a-4f93-4c76-87f3-57574bff8109'], 'Migrate-SID-History'],
  [WellKnownExtendedGuidsEnum['b4e60130-df3f-11d1-9c86-006008764d0e'], 'msmq-Open-Connector'],
  [WellKnownExtendedGuidsEnum['06bd3201-df3e-11d1-9c86-006008764d0e'], 'msmq-Peek'],
  [WellKnownExtendedGuidsEnum['4b6e08c3-df3c-11d1-9c86-006008764d0e'], 'msmq-Peek-computer-Journal'],
  [WellKnownExtendedGuidsEnum['4b6e08c1-df3c-11d1-9c86-006008764d0e'], 'msmq-Peek-Dead-Letter'],
  [WellKnownExtendedGuidsEnum['06bd3200-df3e-11d1-9c86-006008764d0e'], 'msmq-Receive'],
  [WellKnownExtendedGuidsEnum['4b6e08c2-df3c-11d1-9c86-006008764d0e'], 'msmq-Receive-computer-Journal'],
  [WellKnownExtendedGuidsEnum['4b6e08c0-df3c-11d1-9c86-006008764d0e'], 'msmq-Receive-Dead-Letter'],
  [WellKnownExtendedGuidsEnum['06bd3203-df3e-11d1-9c86-006008764d0e'], 'msmq-Receive-journal'],
  [WellKnownExtendedGuidsEnum['06bd3202-df3e-11d1-9c86-006008764d0e'], 'msmq-Send'],
  [WellKnownExtendedGuidsEnum['a1990816-4298-11d1-ade2-00c04fd8d5cd'], 'Open-Address-Book'],
  [WellKnownExtendedGuidsEnum['1131f6ae-9c07-11d1-f79f-00c04fc2dcd2'], 'Read-Only-Replication-Secret-Synchronization'],
  [WellKnownExtendedGuidsEnum['45ec5156-db7e-47bb-b53f-dbeb2d03c40f'], 'Reanimate-Tombstones'],
  [WellKnownExtendedGuidsEnum['0bc1554e-0a99-11d1-adbb-00c04fd8d5cd'], 'Recalculate-Hierarchy'],
  [WellKnownExtendedGuidsEnum['62dd28a8-7f46-11d2-b9ad-00c04f79f805'], 'Recalculate-Security-Inheritance'],
  [WellKnownExtendedGuidsEnum['ab721a56-1e2f-11d0-9819-00aa0040529b'], 'Receive-As'],
  [WellKnownExtendedGuidsEnum['9432c620-033c-4db7-8b58-14ef6d0bf477'], 'Refresh-Group-Cache'],
  [WellKnownExtendedGuidsEnum['1a60ea8d-58a6-4b20-bcdc-fb71eb8a9ff8'], 'Reload-SSL-Certificate'],
  [WellKnownExtendedGuidsEnum['7726b9d5-a4b4-4288-a6b2-dce952e80a7f'], 'Run-Protect_Admin_Groups-Task'],
  [WellKnownExtendedGuidsEnum['91d67418-0135-4acc-8d79-c08e857cfbec'], 'SAM-Enumerate-Entire-Domain'],
  [WellKnownExtendedGuidsEnum['ab721a54-1e2f-11d0-9819-00aa0040529b'], 'Send-As'],
  [WellKnownExtendedGuidsEnum['ab721a55-1e2f-11d0-9819-00aa0040529b'], 'Send-To'],
  [WellKnownExtendedGuidsEnum['ccc2dc7d-a6ad-4a7a-8846-c04e3cc53501'], 'Unexpire-Password'],
  [WellKnownExtendedGuidsEnum['280f369c-67c7-438e-ae98-1d46f3c6f541'], 'Update-Password-Not-Required-Bit'],
  [WellKnownExtendedGuidsEnum['be2bb760-7f46-11d2-b9ad-00c04f79f805'], 'Update-Schema-Cache'],
  [WellKnownExtendedGuidsEnum['ab721a53-1e2f-11d0-9819-00aa0040529b'], 'User-Change-Password'],
  [WellKnownExtendedGuidsEnum['3e0f7e18-2c7a-4c10-ba82-4d926db99a3e'], 'DS-Clone-Domain-Controller'],
  [WellKnownExtendedGuidsEnum['084c93a2-620d-4879-a836-f0ae47de0e89'], 'DS-Read-Partition-Secrets'],
  [WellKnownExtendedGuidsEnum['94825a8d-b171-4116-8146-1e34d8f54401'], 'DS-Write-Partition-Secrets'],
  [WellKnownExtendedGuidsEnum['4125c71f-7fac-4ff0-bcb7-f09a41325286'], 'DS-Set-Owner'],
  [WellKnownExtendedGuidsEnum['88a9933e-e5c8-4f2a-9dd7-2527416b8092'], 'DS-Bypass-Quota']
])

// build regexps only once
const mapsMatch: Array<Map<string, string>> = [
  wellKnownSids,
  wellKnownSidsWithAce,
  wellKnownSidsWithAcePatterns,
  wellKnownSidsPatterns,
  wellKnownGuidsWithSelfWrite,
  wellKnownGuidsWithWriteProperties,
  wellKnownGuidsWithControlAccess,
  wellKnownExtendedGuids
]

export const allSidsPatterns = mapsMatch.reduce<PatternsMap>((acc, map) => {
  for (const [regexpStr, definition] of map.entries()) {
    const regexp = new RegExp(`^${regexpStr}$`)
    acc.set(regexp, definition)
  }

  return acc
}, new Map())
