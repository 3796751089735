import { Blade } from '@app/components-legacy/Blade'
import { BladeContentVariant } from '@app/components-legacy/Blade/types'
import { CardMainContent } from '@app/components-legacy/Card/CardMainContent/CardMainContent'
import ContainerSections from '@app/components-legacy/Container/ContainerSections'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import DrawerSearchBookmarks from '../DrawerSearchBookmarks'
import DrawerSearchHistoryTimeline from '../DrawerSearchHistoryTimeline'
import EventDetailsPage from '../EventDetailsPage'
import { canAccessToEventDetails, canAccessToTrailFlow } from '../permissions'
import TrailFlowHistoryDrawer from '../TrailFlowHistoryDrawer'
import { onTrailFlowLoad, onTrailFlowUnload } from './handlers'
import Table from './Table'
import TableFooter from './Table/TableFooter'
import TrailFlowPageAutomaticPauseModal from './TrailFlowPageAutomaticPauseModal'
import TrailFlowPageHeader from './TrailFlowPageHeader'

const StyledContainerSections = styled(ContainerSections)`
  // Fix spinner position
  .simplebar-content {
    height: 100%;
  }
`

interface ITrailFlowPageProps {}

const TrailFlowPage: React.FC<ITrailFlowPageProps> = () => {
  const { storeInfrastructures, storeTrailFlow } = useStores()

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  return (
    <>
      <Blade
        root={true}
        routeDefinition={{
          routeName: AppRouteName.TrailFlow,
          parameters: {}
        }}
        onLoad={onTrailFlowLoad(storeTrailFlow)}
        onUnload={onTrailFlowUnload(storeTrailFlow)}
        flags={[
          storeTrailFlow.storeFlagsTrailFlow.flags,
          storeInfrastructures.storeFlagsFetchInfrastructures.flags
        ]}
        layout={{
          name: 'default',
          header: themeIsLegacy ? <TrailFlowPageHeader /> : undefined,
          content: (
            <>
              {themeIsLegacy ? (
                <Table />
              ) : (
                <CardMainContent fullHeight>
                  <StyledContainerSections
                    header={<TrailFlowPageHeader />}
                    content={<Table />}
                    footer={<TableFooter />}
                    gap={theme.tokens['margin/small']}
                    contentContainerProps={{
                      style: { margin: `0 -${theme.tokens['margin/default']}` }
                    }}
                    withSimpleBar
                  />
                </CardMainContent>
              )}

              <TrailFlowHistoryDrawer />

              <Portal name={PlaceHolderName.genericDrawer}>
                <DrawerSearchHistoryTimeline />
                <DrawerSearchBookmarks />
              </Portal>

              <TrailFlowPageAutomaticPauseModal />
            </>
          ),
          footer: themeIsLegacy ? <TableFooter /> : undefined
        }}
        contentProps={{
          variant: BladeContentVariant.fullScreen
        }}
        rbacCapabilityCheck={canAccessToTrailFlow}
      />

      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName: AppRouteName.TrailFlow_EventDetails,
              parameters: {
                infrastructureId: Number(),
                directoryId: Number(),
                eventId: String()
              }
            },
            component: EventDetailsPage,
            rbacPermissionsCheck: () => canAccessToEventDetails
          }
        ]}
      />
    </>
  )
}

export default observer(TrailFlowPage)
