import { Features } from '@alsid/common'
import type StoreAttacks from '@app/stores/IoA/StoreAttacks'
import { isDefined } from '@libs/isDefined'
import type {
  CloseAttacksBulkMutationArgs,
  CloseAttacksMutationArgs,
  InputCloseAttack
} from '@server/graphql/typeDefs/types'

/**
 * Close selected attacks.
 */
export const closeOrReopenSelectedAttacks =
  (storeAttacks: StoreAttacks) => (parameters: { isClosed: boolean }) => {
    const { storeAuthentication, storeRbac } = storeAttacks.storeRoot.stores

    const featureSelectAllAttacks = storeRbac.isUserGrantedAccordingFeatureFlag(
      Features.CLOSE_REOPEN_ALL_ATTACKS
    )

    const selectedRows =
      !featureSelectAllAttacks &&
      storeAttacks.storeWidgetListAttacks.isAllRowsSelected
        ? storeAttacks.storeWidgetListAttacks.allEntitiesAsArray // true only when all data has been loaded
        : storeAttacks.storeWidgetListAttacks.selectedRowsAsArray

    const attacks = selectedRows
      .map(attack => {
        if (!attack.id) {
          return
        }

        const inputCloseAttack: InputCloseAttack = {
          attackId: attack.id,
          isClosed: parameters.isClosed
        }

        return inputCloseAttack
      })
      .filter(isDefined)

    const profileId = storeAuthentication.currentProfileId

    const args: CloseAttacksMutationArgs = {
      attacks,
      profileId
    }

    return storeAttacks
      .closeOrReopenAttacks(args, parameters)
      .then(() => {
        storeAttacks.storeModalConfirmCloseAction.hide()
        storeAttacks.storeWidgetListAttacks.resetSelection()
      })
      .then(() => storeAttacks.reloadAttacks())
  }

/**
 * Close or reopen all attacks in bulk.
 */
export const closeOrReopenAttacksBulk =
  (storeAttacks: StoreAttacks) => (parameters: { isClosed: boolean }) => {
    const { storeRbac } = storeAttacks.storeRoot.stores

    const featureSelectAllAttacks = storeRbac.isUserGrantedAccordingFeatureFlag(
      Features.CLOSE_REOPEN_ALL_ATTACKS
    )

    if (!featureSelectAllAttacks) {
      return closeOrReopenSelectedAttacks(storeAttacks)(parameters)
    }

    const profileId =
      storeAttacks.storeRoot.stores.storeAuthentication.currentProfileId

    const filtersArgs = storeAttacks.computedArgsFromFilters

    if (!filtersArgs) {
      return
    }

    const args: CloseAttacksBulkMutationArgs = {
      attacks: {
        attacksFilters: filtersArgs.attacksFilters,
        isClosed: parameters.isClosed
      },
      profileId
    }

    return storeAttacks
      .closeOrReopenAttacksBulk(args, parameters)
      .then(() => {
        storeAttacks.storeModalConfirmCloseAction.hide()
        storeAttacks.storeWidgetListAttacks.resetSelection()
      })
      .then(() => storeAttacks.reloadAttacks())
  }
