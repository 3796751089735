import { ContainerFlex } from '@app/components/Container'
import { IconCount, IconLineChart } from '@app/components/Icon'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { HorizontalLine } from '@app/components/Separator'
import { CardSimple } from '@app/components-legacy/Card/CardSimple/CardSimple'
import { useAppTranslator } from '@app/hooks'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import InformationLine from './InformationLine'
import TemplateWrapper from './TemplateWrapper'
import { DashboardTemplate } from './types'

interface IUserMonitoringProps {
  children?: React.ReactNode
}

const StyledHorizontalLine = styled(HorizontalLine)`
  margin-top: ${consts.paddingLarge};
  margin-bottom: ${consts.paddingMedium};
`

const UserMonitoring: React.FC<IUserMonitoringProps> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Dashboard.AddDashboardDrawer',
      'Dashboard.Common',
      'Components.LabelChecker.ComplexityLevel'
    ]
  })

  return (
    <TemplateWrapper
      title={translate('User Monitoring')}
      templateType={DashboardTemplate.userMonitoring}
    >
      <ContainerFlex
        name="UserMonitoring"
        flexGap="verySmall"
        direction="column"
        items={[
          <CardSimple>
            <ContainerFlex
              name="AdUserEvolution"
              flexGap="medium"
              items={[
                <IconLineChart />,
                <ContainerFlex
                  name="AdUserEvolutionContent"
                  flexGap="verySmall"
                  direction="column"
                  items={[
                    <Label variant={LabelVariant.p_bold}>
                      {translate('AD User Evolution')}
                    </Label>,
                    <Label variant={LabelVariant.description}>
                      {translate(
                        'Track the additions and removals of your Active Directory users'
                      )}
                    </Label>
                  ]}
                />
              ]}
              itemsFlexShrink={[0, 1]}
            />
          </CardSimple>,

          <CardSimple>
            <ContainerFlex
              name="UserCategoriesCount"
              flexGap="medium"
              items={[
                <IconCount />,
                <ContainerFlex
                  name="UserCategoriesCountContent"
                  flexGap="verySmall"
                  direction="column"
                  items={[
                    <Label variant={LabelVariant.p_bold}>
                      {translate('User Categories Count')}
                    </Label>,
                    <Label variant={LabelVariant.description}>
                      {translate(
                        'Monitor the current real-time status of your AD users'
                      )}
                    </Label>
                  ]}
                />
              ]}
              itemsFlexShrink={[0, 1]}
            />

            <StyledHorizontalLine color={consts.CTAColorV2.disable} />

            <ContainerFlex
              name="CountList"
              flexGap="verySmall"
              direction="column"
              items={[
                <InformationLine title={translate('All users')} />,
                <InformationLine title={translate('Disabled users')} />,
                <InformationLine title={translate('Active users')} />,
                <InformationLine title={translate('Dormant accounts')} />,
                <InformationLine
                  title={translate('Disabled accounts in privileged groups')}
                />
              ]}
            />
          </CardSimple>,

          <CardSimple>
            <ContainerFlex
              name="DormantAccountEvolution"
              flexGap="medium"
              items={[
                <IconLineChart />,
                <ContainerFlex
                  name="DormantAccountEvolutionContent"
                  flexGap="verySmall"
                  direction="column"
                  items={[
                    <Label variant={LabelVariant.p_bold}>
                      {translate('Dormant Account Evolution')}
                    </Label>,
                    <Label variant={LabelVariant.description}>
                      {translate(
                        'Keep track of the usage of your registered AD users'
                      )}
                    </Label>
                  ]}
                />
              ]}
              itemsFlexShrink={[0, 1]}
            />
          </CardSimple>
        ]}
      />
    </TemplateWrapper>
  )
}

export default UserMonitoring
