import { BladeContentLayoutDefault as BladeContentLayoutDefaultDeprecated } from '@app/components-legacy/Blade/BladeContent'
import BladeContentLayoutDefault from '@app/components-legacy/Blade/BladeContent/BladeContentLayoutDefault'
import {
  Drawer as DrawerDeprecated,
  DrawerContext as DrawerContextDeprecated,
  DrawerHorizontalContainer as DrawerHorizontalContainerDeprecated,
  DrawerTitle as DrawerTitleDeprecated
} from '@app/components-legacy/Drawer'
import { DrawerContext } from '@app/components-legacy/Drawer/context'
import Drawer from '@app/components-legacy/Drawer/Drawer'
import DrawerHorizontalContainer from '@app/components-legacy/Drawer/DrawerHorizontalContainer'
import DrawerTitle from '@app/components-legacy/Drawer/DrawerTitle'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditDashbard } from '../../permissions'
import DrawerEditDashboardForm from './DrawerEditDashboardForm'

interface IDrawerEditDashboardProps {}

const DrawerEditDashboard: React.FC<IDrawerEditDashboardProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Dashboard.Common']
  })

  const { storeDashboards } = useStores()

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const {
    storeDashboardsManagement: { storeDrawerEditDashboard }
  } = storeDashboards

  const dashboardId = storeDashboards.currentDashboardId

  return themeIsLegacy ? (
    <DrawerContextDeprecated.Provider
      value={{ storeDrawer: storeDrawerEditDashboard }}
    >
      <DrawerDeprecated>
        <DrawerHorizontalContainerDeprecated labelledBy="configureDashboard">
          <BladeContentLayoutDefaultDeprecated
            layout={{
              name: 'default',
              title: (
                <DrawerTitleDeprecated>
                  {translate('Configure the dashboard')}
                </DrawerTitleDeprecated>
              ),
              content: <DrawerEditDashboardForm />,
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canEditDashbard(dashboardId)}
          />
        </DrawerHorizontalContainerDeprecated>
      </DrawerDeprecated>
    </DrawerContextDeprecated.Provider>
  ) : (
    <DrawerContext.Provider value={{ storeDrawer: storeDrawerEditDashboard }}>
      <Drawer>
        <DrawerHorizontalContainer labelledBy="configureDashboard">
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: (
                <DrawerTitle>
                  {translate('Configure the dashboard')}
                </DrawerTitle>
              ),
              content: <DrawerEditDashboardForm />,
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canEditDashbard(dashboardId)}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerEditDashboard)
