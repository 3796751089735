import {
  ContainerFooter,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import {
  IconLockOutlined,
  IconUserOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import { AuthProviderName } from '@server/graphql/typeDefs/types'
import { Input } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onLoginViaTenableProviderSubmit } from './handlers'
import { TenableInputName } from './types'

export interface ILoginTenableFormProps {}

const LoginTenableForm: React.FC<ILoginTenableFormProps> = props => {
  const { testAttributeProps } = useTestAttribute('form')

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Login', 'Login.Labels', 'Login.Errors']
  })

  const { storeAuthentication } = useStores()

  const storeForm = storeAuthentication.storeForms[AuthProviderName.Tenable]

  const fields: IField[] = [
    {
      name: TenableInputName.email,
      label: translate('Email address'),
      errors: storeForm.field(TenableInputName.email).errors,
      control: (
        <Input
          data-name="EmailInput"
          prefix={<IconUserOutlined />}
          name={TenableInputName.email}
          value={storeForm.getFieldValueAsString(TenableInputName.email)}
          onChange={onInputChange(storeForm)(TenableInputName.email)}
          placeholder={translate('Email address')}
          autoComplete="off"
          tabIndex={1}
          disabled={storeAuthentication.storeFlagsProcessLogin.isLoading}
          {...testAttributeProps('textbox')('email')}
        />
      )
    },
    {
      name: TenableInputName.password,
      label: translate('Password'),
      errors: storeForm.field(TenableInputName.password).errors,
      control: (
        <Input.Password
          data-name="PasswordInput"
          prefix={<IconLockOutlined />}
          name={TenableInputName.password}
          value={storeForm.getFieldValueAsString(TenableInputName.password)}
          onChange={onInputChange(storeForm)(TenableInputName.password)}
          placeholder={translate('Password')}
          autoComplete="off"
          visibilityToggle
          tabIndex={2}
          disabled={storeAuthentication.storeFlagsProcessLogin.isLoading}
          {...testAttributeProps('textbox')('password')}
        />
      )
    }
  ]

  return (
    <FormWrapper
      name="tenableLoginForm"
      onSubmit={onLoginViaTenableProviderSubmit(storeAuthentication)}
    >
      <ContainerForm fields={fields} />

      <Portal name={PlaceHolderName.authFooter}>
        <ContainerFooter>
          <FormWrapperButtonSubmit
            type="primary"
            htmlType="submit"
            loading={storeAuthentication.storeFlagsProcessLogin.isLoading}
            {...testAttributeProps('button')('login')}
          >
            {translate('Login')}
          </FormWrapperButtonSubmit>
        </ContainerFooter>
      </Portal>
    </FormWrapper>
  )
}

export default observer(LoginTenableForm)
