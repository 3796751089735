import ContainerHeader from '@app/components-legacy/Container/ContainerHeader'
import * as React from 'react'
import type { BladeLayout } from '../types'

interface IBladeContentHeaderProps {
  style?: React.CSSProperties
  isLoading: boolean
  isGranted: boolean
  isError: boolean
  layout: BladeLayout
}

const BladeContentHeader: React.FC<IBladeContentHeaderProps> = props => {
  if (
    !props.layout.header ||
    props.isLoading ||
    !props.isGranted ||
    props.isError
  ) {
    return null
  }

  return (
    <ContainerHeader style={props.style}>{props.layout.header}</ContainerHeader>
  )
}

export default BladeContentHeader
