import {
  ContainerContent,
  ContainerFlex,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import { TFlexValuePreset } from '@app/components-legacy/Container/ContainerFlex/types'
import { LabelDirectoriesTree } from '@app/components-legacy/Label'
import { TypographySubTitle } from '@app/components-legacy/Typography'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import HoneyAccountScript from './HoneyAccountScript'
import { replaceDistinguishedName } from './utils'

interface IHoneyAccountCommonLayoutProps {
  directoryId: number
  children?: React.ReactNode
}

const HoneyAccountCommonLayout: React.FC<
  IHoneyAccountCommonLayoutProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Directories']
  })

  const { storeInfrastructures, storeManagementDirectories } = useStores()

  const directoryEntity = storeManagementDirectories.directories.get(
    props.directoryId
  )

  const configurationScript =
    storeManagementDirectories.honeyAccountConfigurationScript.get(
      props.directoryId
    )

  const searchValue = storeManagementDirectories.storeInputSearch.searchValue
  const isFormValid =
    Boolean(searchValue) &&
    storeManagementDirectories.storeFormHoneyAccount.validate()

  const scriptValue =
    configurationScript && isFormValid
      ? replaceDistinguishedName(configurationScript, searchValue)
      : translate('Honey Account Configuration Script Placeholder', {
          interpolations: {
            name: String(directoryEntity?.name)
          }
        })

  return (
    <ContainerContent>
      <ContainerFlex
        name="HoneyAccountCreatePage"
        items={[
          <ContainerFlex
            name="HoneyAccountCreatePageLeftColumn"
            direction="column"
            flexValuePreset={TFlexValuePreset.mainColumns}
            items={[
              <TypographySubTitle>
                {translate('Honey Account Title')}
              </TypographySubTitle>,

              <ContainerHTMLContent padded>
                {translate('Honey Account Description', {
                  transformMarkdown: true
                })}
              </ContainerHTMLContent>,

              props.children,

              <ContainerFlex
                name="HoneyAccountCreateDeployment"
                direction="column"
                items={[
                  <TypographySubTitle>
                    {translate('Honey Account Deployment')}
                  </TypographySubTitle>,

                  <ContainerHTMLContent padded>
                    {translate('Honey Account Deployment Description', {
                      transformMarkdown: true
                    })}
                  </ContainerHTMLContent>,

                  <HoneyAccountScript
                    isDisabled={!isFormValid}
                    value={scriptValue}
                  />
                ]}
                wrapItems
                spaced
                spaceWidth="default"
                borderPadding="default"
              />
            ]}
            wrapItems
            spaced
            spaceWidth="default"
            borderPadding="default"
          />,

          <ContainerFlex
            name="HoneyAccountCreatePageRightColumn"
            direction="column"
            flexValuePreset={TFlexValuePreset.summaryColumns}
            items={[
              <TypographySubTitle>
                {translate('Honey Account Concerned Domain')}
              </TypographySubTitle>,

              <LabelDirectoriesTree
                direction="row"
                directoryIds={[props.directoryId]}
                storeInfrastructures={storeInfrastructures}
              />
            ]}
            wrapItems
            spaced
            spaceWidth="default"
            borderPadding="default"
          />
        ]}
        spaced
      />
    </ContainerContent>
  )
}

export default observer(HoneyAccountCommonLayout)
