import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { LabelNonBreakable } from '@app/components-legacy/Label'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'

export interface ITableContentHeadCellProps {
  className?: string
  children?: React.ReactNode
}

const TableContentHeadCell: React.FC<ITableContentHeadCellProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  if (themeIsLegacy) {
    return (
      <LabelNonBreakable className={props.className}>
        {props.children}
      </LabelNonBreakable>
    )
  }

  return (
    <Label variant={LabelVariant.h3} className={props.className}>
      <LabelNonBreakable>{props.children}</LabelNonBreakable>
    </Label>
  )
}

export default TableContentHeadCell
