import * as React from 'react'

export interface IGaugeMediumProps {}

export default (props: IGaugeMediumProps) => (
  <svg width={24} height={24}>
    <defs>
      <path
        d="M10.055.062a9.846 9.846 0 0 0-8.153 15.366l1.452-1.145a8 8 0 1 1 13.403 0l1.452 1.145A9.846 9.846 0 0 0 10.055.062z"
        id="gauge-medium-prefix__a"
      />
      <path
        d="M.215 9.96a9.782 9.782 0 0 0 1.72 5.51l2.908-2.292a6.154 6.154 0 0 1 5.19-9.43L10.016.055a9.846 9.846 0 0 0-9.8 9.905zm9.813-6.206L8.83 9.884a1.23 1.23 0 1 0 2.461 0L10.031 3.76l-.003-.006z"
        id="gauge-medium-prefix__c"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(2 4)">
        <mask id="gauge-medium-prefix__b" fill="#fff">
          <use xlinkHref="#gauge-medium-prefix__a" />
        </mask>
        <use
          fill="#000"
          fillRule="nonzero"
          xlinkHref="#gauge-medium-prefix__a"
        />
        <g mask="url(#gauge-medium-prefix__b)" fill="#9DA5B3">
          <path d="M-2-4h24v24H-2z" />
        </g>
      </g>
      <g transform="translate(2 4)">
        <mask id="gauge-medium-prefix__d" fill="#fff">
          <use xlinkHref="#gauge-medium-prefix__c" />
        </mask>
        <use
          fill="#000"
          fillRule="nonzero"
          xlinkHref="#gauge-medium-prefix__c"
        />
        <g mask="url(#gauge-medium-prefix__d)" fill="#606B80">
          <path d="M-2-4h24v24H-2z" />
        </g>
      </g>
    </g>
  </svg>
)
