import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import EntityHealthCheckDomainStatusReason from '@app/entities/EntityHealthCheckDomainStatusReason'
import { createEntities } from '@app/entities/index'
import type {
  HealthCheckDomainStatus,
  HealthCheckDomainStatusDetails,
  HealthCheckDomainStatusReason,
  HealthCheckStatus,
  Maybe
} from '@server/graphql/typeDefs/types'

export default class EntityHealthCheckDomainStatusDetails
  extends EntityBase
  implements PropertiesNullable<HealthCheckDomainStatusDetails>
{
  healthCheckName: Maybe<string> = null
  directoryId: Maybe<number> = null
  status: Maybe<HealthCheckStatus> = null
  lastCheckDate: Maybe<string> = null
  statusSince: Maybe<string> = null
  reasons: Maybe<HealthCheckDomainStatusReason[]> = null

  constructor(data: Partial<HealthCheckDomainStatus>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return the reasons entities.
   */
  getReasons(): Maybe<EntityHealthCheckDomainStatusReason[]> {
    if (!this.reasons) {
      return null
    }

    return createEntities<
      HealthCheckDomainStatusReason,
      EntityHealthCheckDomainStatusReason
    >(EntityHealthCheckDomainStatusReason, this.reasons)
  }
}
