import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperCheckbox } from '@app/components-legacy/Form'
import { useAppTranslator, useStores } from '@app/hooks'
import { LockoutPolicyFormFieldName } from '@app/stores/Management/StoreLockoutPolicy/types'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditLockoutPolicy } from '../permissions'
import {
  LOCKOUT_POLICY_DURATION_MAX,
  LOCKOUT_POLICY_DURATION_MIN,
  LOCKOUT_POLICY_DURATION_STEP
} from './consts'
import { handleLockoutPolicyFieldsOnChange } from './handlers'
import { convertToMinutes } from './helpers'
import StyledFormWrapperInput from './StyledFormWrapperInput'
import StyledSlider from './StyledSlider'

interface ILockoutDurationFieldProps {}

const LockoutDurationField: React.FC<ILockoutDurationFieldProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.LockoutPolicy']
  })

  const { storeLockoutPolicy, storeRbac } = useStores()

  const { storeFormLockoutPolicyConfiguration } = storeLockoutPolicy

  const lockoutDurationValue =
    storeFormLockoutPolicyConfiguration.getFieldValueAsNumber(
      LockoutPolicyFormFieldName.lockoutDuration
    )

  const isLockoutDurationValueInfinite =
    storeFormLockoutPolicyConfiguration.getFieldValueAsBoolean(
      LockoutPolicyFormFieldName.lockoutDurationInfinite
    )

  const handleLockoutDurationOnChangeFn = handleLockoutPolicyFieldsOnChange(
    storeFormLockoutPolicyConfiguration
  )(
    LockoutPolicyFormFieldName.lockoutDuration,
    LockoutPolicyFormFieldName.lockoutDurationInfinite
  )

  const canEditLockoutPolicyValue =
    storeRbac.isUserGrantedTo(canEditLockoutPolicy)

  const Slider = (
    <StyledSlider
      min={LOCKOUT_POLICY_DURATION_MIN}
      max={LOCKOUT_POLICY_DURATION_MAX}
      step={LOCKOUT_POLICY_DURATION_STEP}
      value={lockoutDurationValue}
      onChange={handleLockoutDurationOnChangeFn}
      disabled={isLockoutDurationValueInfinite || !canEditLockoutPolicyValue}
      tooltip={{ open: false }}
    />
  )

  const getInputValue = () => {
    const minutes = convertToMinutes(lockoutDurationValue)

    const tooltipContent =
      !isLockoutDurationValueInfinite && minutes
        ? translate('About X minutes', {
            interpolations: {
              number: minutes
            }
          })
        : null

    return (
      <Tooltip title={tooltipContent}>
        <div>
          <StyledFormWrapperInput
            labelledBy="lockoutDurationValue"
            inputProps={{
              onChange: e => {
                handleLockoutDurationOnChangeFn(Number(e.target.value))
              },
              value: isLockoutDurationValueInfinite
                ? '♾️'
                : lockoutDurationValue,
              disabled: !canEditLockoutPolicyValue
            }}
          />
        </div>
      </Tooltip>
    )
  }

  const InfiniteSwitch = (
    <FormWrapperCheckbox
      labelledBy="LockoutInfiniteDurationCheckbox"
      checkboxProps={{
        onChange: () => handleLockoutDurationOnChangeFn(null),
        checked: isLockoutDurationValueInfinite,
        disabled: !canEditLockoutPolicyValue
      }}
    >
      {translate('Infinity')}
    </FormWrapperCheckbox>
  )

  return (
    <ContainerFlex
      name={LockoutDurationField.name}
      alignItems="center"
      items={[Slider, getInputValue(), InfiniteSwitch]}
      fullWidth
      spaced
    />
  )
}

export default observer(LockoutDurationField)
