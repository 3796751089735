import { Features } from '@alsid/common'
import { ModalChangesNotSaved } from '@app/components-legacy/Modal'
import ModalFeatureFlagRestart from '@app/components-legacy/Modal/ModalFeatureFlagRestart'
import ModalFeatureFlagUpdate from '@app/components-legacy/Modal/ModalFeatureFlagUpdate'
import {
  PlaceHolderAlertsIoA,
  PlaceHolderAlertsIoE,
  PlaceHolderDrawers
} from '@app/components-legacy/PlaceHolder'
import {
  useAlertsObserver,
  useAlertsQueryStringNotifier
} from '@app/components-legacy/PlaceHolder/hooks'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useStores } from '@app/hooks'
import WidgetAlerts from '@app/pages/HeaderLegacy/Widgets/WidgetAlerts'
import DrawerAlerts from '@app/pages/HeaderLegacy/Widgets/WidgetAlerts/DrawerAlerts'
import WidgetHelp from '@app/pages/HeaderLegacy/Widgets/WidgetHelp'
import WidgetSettings from '@app/pages/HeaderLegacy/Widgets/WidgetSettings'
import WidgetUser from '@app/pages/HeaderLegacy/Widgets/WidgetUser'
import HealthCheckHeaderWidget from '@app/pages/HealthCheck/HealthCheckHeaderWidget'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { buildVariants } from '@design-system/libs/buildVariants'
import { DSThemeName } from '@design-system/styles/themes/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import { tulOverrides } from '../createApp/tulOverrides'
import {
  useActivityLogs,
  useKeyBindings,
  usePostLogin,
  useRouterInit,
  useTelemetry,
  useTitleBindingsOnHistory
} from './hooks'
import Routes from './Routes'

interface IAppContainerProps {}

interface IDivStyleProps {
  isTulDisplayed: boolean
  isTulV6Enabled: boolean
}

const Div = styled.div<IDivStyleProps>(props => {
  const tulHeight =
    props.theme.tokens[`layout/tul${props.isTulV6Enabled ? '' : '-v5'}/height`]

  return buildVariants(props)
    .css({
      position: 'absolute',
      left: 0,
      width: '100%',
      height: '100%',

      '@media print': {
        overflow: 'inherit',
        position: 'initial'
      }
    })

    .variant('isTulDisplayed', props.isTulDisplayed, {
      true: {
        // Let some place for TUL
        top: tulHeight,
        height: `calc(100% - ${tulHeight}px)`
      },

      false: {
        height: '100%'
      }
    })

    .end()
})

function App(props: IAppContainerProps) {
  useRouterInit()
  useTitleBindingsOnHistory()
  useKeyBindings()
  usePostLogin()
  useTelemetry()
  useActivityLogs()

  useAlertsObserver()
  useAlertsQueryStringNotifier()

  const { storeLayout, storeRoot, storeAuthentication } = useStores()

  const ref = useRef()

  const isTulV6Enabled = storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
    Features.KAPTEYN_TUL_V6
  )

  const featureKapteynLeftNavigationUi =
    storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
      Features.KAPTEYN_LEFT_NAVIGATION_UI
    )

  useLayoutEffect(() => {
    if (!ref || !ref.current) {
      return
    }

    if (isTulV6Enabled) {
      storeRoot.tulApi?._storeRoot.setTulRoot(ref.current)

      setTimeout(() => {
        tulOverrides()
      })
    }
  }, [ref])

  return (
    <>
      {storeAuthentication.isAuthenticated && isTulV6Enabled && (
        <DSThemeProvider themeName={DSThemeName.default}>
          <tenable-global-header ref={ref}>
            <div slot="custom-help-action">
              <WidgetHelp />
            </div>
            <div slot="custom-settings-action">
              <WidgetSettings />
            </div>
            <div slot="custom-notifications-action">
              <div
                style={{
                  paddingLeft: '8px'
                }}
              >
                <WidgetAlerts />
              </div>
            </div>
            <div slot="custom-user-action">
              <WidgetUser />
            </div>
            <div slot="custom-app-specific-actions">
              <div
                style={{
                  paddingRight: '8px'
                }}
              >
                {featureKapteynLeftNavigationUi && <HealthCheckHeaderWidget />}
              </div>
            </div>
          </tenable-global-header>
        </DSThemeProvider>
      )}

      <Div
        data-name="App"
        isTulDisplayed={storeLayout.isTulDisplayed()}
        isTulV6Enabled={isTulV6Enabled}
      >
        <Routes />

        <ModalChangesNotSaved />

        <ModalFeatureFlagUpdate />
        <ModalFeatureFlagRestart />

        {/* Placeholder for security alerts  */}
        <PlaceHolderAlertsIoE alertsLifeTime={5} />
        <PlaceHolderAlertsIoA alertsLifeTime={5} />

        {/* Placeholders for the different drawers. /!\ Order (z-index) is important! */}

        {/* Placeholder for generic drawers */}
        <PlaceHolderDrawers name={PlaceHolderName.genericDrawer} />

        {/* Placeholder for checkers selection drawers */}
        <PlaceHolderDrawers name={PlaceHolderName.selectorDrawer} />

        {/* Placeholder for alerts drawers (need to be after all others) */}
        <PlaceHolderDrawers name={PlaceHolderName.alertsDrawer} />

        {/* Placeholder for drawers overlay, that needs to be placed at the top level */}
        <PlaceHolderDrawers name={PlaceHolderName.drawerOverlay} />

        {/* Put the portal here so that we use only one portal for alerts drawer and not a lot according to the various feature-flags used at the moment... */}
        <Portal name={PlaceHolderName.alertsDrawer}>
          <DrawerAlerts />
        </Portal>
      </Div>
    </>
  )
}

export default observer(App)
