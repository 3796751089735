import { ContainerFlex } from '@app/components-legacy/Container'
import { useDeviceViewPort } from '@app/hooks/useDeviceViewPort'
import { DeviceViewPortWidth } from '@app/hooks/useDeviceViewPort/types'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import WidgetAlerts from './WidgetAlerts'
import WidgetHelp from './WidgetHelp'
import WidgetRibbon from './WidgetRibbon'
import WidgetUser from './WidgetUser'

interface IWidgetsProps {
  className?: string
  style?: React.CSSProperties
}

const Widgets: React.FC<IWidgetsProps> = props => {
  const { deviceViewPort } = useDeviceViewPort()

  return (
    <ContainerFlex
      name="Widgets"
      style={props.style}
      className={props.className}
      alignItems="center"
      items={[
        <ContainerFlex
          name="WidgetsIcons"
          alignItems="center"
          items={[
            <WidgetHelp />,
            <WidgetAlerts />,
            <WidgetUser />,

            deviceViewPort.isLessThan(DeviceViewPortWidth.large) && (
              <WidgetRibbon />
            )
          ]}
          spaced
          spaceWidth="small"
        />
      ]}
      spaced
    />
  )
}

export default styled(Widgets)`
  height: ${consts.headerHeight};
  margin-right: ${consts.marginExtraSmall};
`
