import type { TFlexGrow } from '@app/components-legacy/Container/ContainerFlex/types'
import { useAppTranslator } from '@app/hooks'
import { buildVariants } from '@design-system/libs/buildVariants'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from 'tenable-design-system-alt'
import type { UnitPrefix } from '../types'

interface ICronfieldControlContainerProps {
  name: string
  unitPrefix: UnitPrefix
  hidePrefix: boolean
  children: React.ReactNode
  flexGrow?: TFlexGrow
}

interface IUnitPrefixDivProps {
  hide?: boolean
}

const UnitPrefixDiv = styled.div<IUnitPrefixDivProps>(props => {
  return buildVariants(props)
    .css({
      display: props.hide ? 'none' : 'block'
    })
    .end()
})

export default function CronfieldControlContainer(
  props: ICronfieldControlContainerProps
) {
  const translate = useAppTranslator({
    namespaces: ['Components.InputCrontab']
  })

  return (
    <ContainerFlex
      name={props.name}
      flexGap="spacingM"
      flexJustifyContent="space-between"
      flexAlignItems="baseline"
      flexGrow={props.flexGrow}
    >
      <UnitPrefixDiv hide={props.hidePrefix}>
        {translate(props.unitPrefix)}
      </UnitPrefixDiv>
      {props.children}
    </ContainerFlex>
  )
}
