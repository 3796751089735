import { consts } from '@app/styles'
import styled from 'styled-components'

const CardCheckboxContainer = styled.div<{ checked?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  background-color: ${props =>
    props.checked ? consts.colorBlue002 : consts.colorBlueGray030};
`

export default CardCheckboxContainer
