import { ContainerFlex } from '@app/components/Container'
import { FormWrapperSelect } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks'
import type StoreActivityLogsFilters from '@app/stores/ActivityLogs/StoreActivityLogsFilters'
import { isDefined } from '@libs/isDefined'
import { observer } from 'mobx-react-lite'
import React from 'react'
import FilterLabelWithSelectedCount from './FilterLabelWithSelectedCount'

interface IFilterField {
  storeActivityLogsFilters: StoreActivityLogsFilters
}

const FilterIPField: React.FC<IFilterField> = props => {
  const translate = useAppTranslator({
    namespaces: ['ActivityLogs.Filters']
  })

  const selectOptionsList = props.storeActivityLogsFilters.allIPs.map(ip => {
    return {
      label: ip,
      value: ip,
      labelledBy: ip
    }
  })

  const selectedList: string[] = Array.from(
    props.storeActivityLogsFilters.storeDrawerFilters
      .getDataValue('selectedIPs')
      ?.values() ?? []
  )

  const onSelect = (values: string[]) => {
    props.storeActivityLogsFilters.storeDrawerFilters.setData({
      selectedIPs: new Set(values)
    })
  }

  return (
    <ContainerFlex
      name="DrawerFiltersFields"
      direction="column"
      items={[
        <FilterLabelWithSelectedCount
          label={translate('IP Address(es)')}
          selectedList={selectedList}
          labelledBy="filteredIpsCount"
        />,
        <FormWrapperSelect<any>
          labelledBy="FilteredIps"
          height="auto"
          selectProps={{
            placeholder: translate('Select IP Address(es)'),
            mode: 'multiple',
            ...(isDefined(selectedList) &&
              selectedList.length && { value: selectedList }),
            onChange: onSelect
          }}
          selectOptions={selectOptionsList}
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(FilterIPField)
