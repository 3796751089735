import type { IDataRowRbacEntityItem } from '@app/entities/EntityRbacEntityItem'
import type { StoreManagementRbacRoles } from '@app/stores'
import { filterStoreWidgetLists } from '@app/stores/Management/Rbac/functions'
import type { TranslateFn } from '@libs/i18n'
import {
  buildRbacPermissionKey,
  isRbacPermissionInvalid,
  rbacCapability
} from '@libs/rbac/functions'
import type { IRbacBulkPermissionAction, RbacEntityId } from '@libs/rbac/types'
import { RbacEntityItemType, RbacPermissionType } from '@libs/rbac/types'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type { Maybe } from '@server/graphql/typeDefs/types'
import { RbacAction, RbacEntityName } from '@server/graphql/typeDefs/types'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { flatMap } from 'lodash'
import { explodeBulkPermissionOptionValue } from './utils'

/**
 * Apply set of permissions on Rbac entity items.
 */
const applyPermissionsOnRbacEntityItems =
  (
    storeManagementRbacRoles: StoreManagementRbacRoles,
    translate: TranslateFn
  ) =>
  (rbacActions: RbacAction[]) =>
  (
    bulkRbacEntityItemSelection: Array<{
      rbacEntityItemRows: IDataRowRbacEntityItem[]
      forAllEntities: boolean
    }>
  ) =>
  (rbacPermissionType: RbacPermissionType): void => {
    const { logger } = storeManagementRbacRoles.storeRoot

    const logRbacActions = rbacActions.join(', ')

    const countImpactedEntities = bulkRbacEntityItemSelection.reduce(
      (acc, s) =>
        acc + countImpactedEntitiesAccordingEntityType(s.rbacEntityItemRows),
      0
    )

    logger.debug(
      `[applyPermissionsOnRbacEntityItems] Applying: ${rbacPermissionType} - ${logRbacActions} - ${countImpactedEntities} entities`
    )

    const isRbacPermissionInvalidFn = isRbacPermissionInvalid()

    bulkRbacEntityItemSelection.forEach(bulkRbacEntityItem => {
      if (!bulkRbacEntityItem.rbacEntityItemRows.length) {
        return
      }

      // set the store function to add or remove permissions
      const setPendingRbacPermissionFn =
        rbacPermissionType === RbacPermissionType.grant
          ? storeManagementRbacRoles.addPendingRbacPermissions.bind(
              storeManagementRbacRoles
            )
          : storeManagementRbacRoles.removePendingRbacPermissions.bind(
              storeManagementRbacRoles
            )

      rbacActions.forEach(rbacAction => {
        bulkRbacEntityItem.rbacEntityItemRows.forEach(rbacEntityItemRow => {
          const { rbacEntityName, rbacEntityItemType } = rbacEntityItemRow

          // retrieve either entityName or entityType according to the item type
          const isDataOrUserOrSingleton =
            rbacEntityItemType === RbacEntityItemType.data ||
            rbacEntityItemType === RbacEntityItemType.userPersonal ||
            rbacEntityItemType === RbacEntityItemType.singleton

          const rbacEntityIdentifier = isDataOrUserOrSingleton
            ? rbacEntityName
            : rbacEntityItemType

          // don't save or remove invalid permission
          if (
            rbacEntityIdentifier &&
            isRbacPermissionInvalidFn(rbacEntityIdentifier, rbacAction)
          ) {
            return
          }

          // apply permissions on entity id if...
          const applyOnId =
            // entity data
            rbacEntityItemType === RbacEntityItemType.data &&
            // not all permissions OR
            (!bulkRbacEntityItem.forAllEntities ||
              // for unauthorizing
              rbacPermissionType === RbacPermissionType.unauthorize)

          // apply permission on specific item
          if (applyOnId) {
            setPendingRbacPermissionFn(
              rbacEntityName,
              rbacAction,
              rbacEntityItemRow.id
            )

            logger.info(
              `[applyPermissionsOnRbacEntityItems] ${rbacPermissionType}: ${buildRbacPermissionKey(
                rbacEntityName,
                rbacAction,
                rbacEntityItemRow.id ? [rbacEntityItemRow.id] : null
              )}`
            )

            return
          }

          // apply on all items
          setPendingRbacPermissionFn(
            rbacEntityItemRow.rbacEntityName,
            rbacAction
          )
        })
      })
    })

    storeManagementRbacRoles.storeRoot.stores.storeMessages.info(
      translate('Items have been changed', {
        interpolations: {
          count: countImpactedEntities
        }
      }),
      {
        labelledBy: 'itemsChanged'
      }
    )
  }

/**
 * Toggle between grant all / unauthorize in pending permissions for a defined
 * entity + action.
 */
export const onSetAllPermissionsCheckboxClick =
  (storeManagementRbacRoles: StoreManagementRbacRoles) =>
  (rbacEntityName: RbacEntityName, rbacAction: RbacAction) =>
  (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      storeManagementRbacRoles.addPendingRbacPermissions(
        rbacEntityName,
        rbacAction
      )
      return
    }

    storeManagementRbacRoles.removePendingRbacPermissions(
      rbacEntityName,
      rbacAction
    )
  }

/**
 * Toggle the visibility of a list of Rbac entity items.
 */
export const onToggleVisibilityClick =
  (storeManagementRbacRoles: StoreManagementRbacRoles) =>
  (rbacEntityName: RbacEntityName) =>
  () => {
    const isVisible =
      storeManagementRbacRoles.rbacEntityItemsListVisibility.get(
        rbacEntityName
      ) || false

    storeManagementRbacRoles.setRbacEntityItemListVisibility(
      rbacEntityName,
      !isVisible
    )
  }

/**
 * Collapse all Rbac entity items lists.
 */
export const onCollapsedItemListsButtonClick =
  (storeManagementRbacRoles: StoreManagementRbacRoles) =>
  (visible: boolean) =>
  () => {
    storeManagementRbacRoles.setRbacEntityItemListsVisibility(visible)
  }

/**
 * Collapse all Rbac entity items lists.
 */
export const onOnlyGrantedCheck =
  (storeManagementRbacRoles: StoreManagementRbacRoles) =>
  (checked: boolean) => {
    storeManagementRbacRoles.setRbacEntityItemListsVisibility(true)
    storeManagementRbacRoles.setOnlyGrantedCheckboxValue(checked)
  }

/**
 * Toggle the permission of one of several Rbac entity items.
 */
export const onTagTogglePermissionClick =
  (storeManagementRbacRoles: StoreManagementRbacRoles) =>
  (
    rbacEntityName: RbacEntityName,
    rbacAction: RbacAction,
    rbacEntityId?: Maybe<RbacEntityId>
  ) =>
  (permissionAction: RbacPermissionType) =>
  () => {
    const { logger } = storeManagementRbacRoles.storeRoot

    if (
      displayRbacModals(storeManagementRbacRoles)(
        rbacEntityName,
        rbacAction,
        rbacEntityId
      )(permissionAction)
    ) {
      return
    }

    // don't save or remove invalid permission
    if (isRbacPermissionInvalid()(rbacEntityName, rbacAction)) {
      const permissionKey = buildRbacPermissionKey
      logger.warn(
        `[onTagTogglePermissionClick] Removing the invalid permission "${permissionKey}"`
      )
      return
    }

    if (permissionAction === RbacPermissionType.grant) {
      storeManagementRbacRoles.addPendingRbacPermissions(
        rbacEntityName,
        rbacAction,
        rbacEntityId
      )
      return
    }

    storeManagementRbacRoles.removePendingRbacPermissions(
      rbacEntityName,
      rbacAction,
      rbacEntityId
    )
  }

/**
 * Toggle selections of Rbac entity items for bulk actions.
 */
export const onToggleSelectAllEntitiesClick =
  (storeManagementRbacRoles: StoreManagementRbacRoles) =>
  (rbacEntityItemType: Maybe<RbacEntityItemType>) =>
  () => {
    if (!rbacEntityItemType) {
      return
    }

    storeManagementRbacRoles.toggleSelectAllEntityItems(rbacEntityItemType)
  }

/**
 * Grant or unthorized selected Rbac entity items.
 */
export const onBulkActionSubmit =
  (
    storeManagementRbacRoles: StoreManagementRbacRoles,
    translate: TranslateFn
  ) =>
  (rbacEntityItemType: Maybe<RbacEntityItemType>) =>
  (bulkRbacPermissionAction: Maybe<IRbacBulkPermissionAction>) =>
  () => {
    if (!rbacEntityItemType) {
      return
    }

    if (!bulkRbacPermissionAction) {
      return
    }

    const selectedRbacEntityItems = flatMap(
      filterStoreWidgetLists(
        storeManagementRbacRoles.storesWidgetListEntityItems
      )(rbacEntityItemType).map(storeWidgetList => {
        return {
          rbacEntityItemRows: storeWidgetList.selectedRowsAsArray,
          forAllEntities: bulkRbacPermissionAction.entities === 'all'
        }
      })
    )

    const grantOrUnauthorizedFn = applyPermissionsOnRbacEntityItems(
      storeManagementRbacRoles,
      translate
    )(bulkRbacPermissionAction.actions)(selectedRbacEntityItems)

    switch (bulkRbacPermissionAction.type) {
      case RbacPermissionType.grant:
        grantOrUnauthorizedFn(RbacPermissionType.grant)
        break

      case RbacPermissionType.unauthorize:
        grantOrUnauthorizedFn(RbacPermissionType.unauthorize)
        break

      default:
        assertUnreachableCase(bulkRbacPermissionAction.type)
    }
  }

/**
 * According to some context, display a modal box for user informations.
 */
export const displayRbacModals =
  (storeManagementRbacRoles: StoreManagementRbacRoles) =>
  (
    rbacEntityName: RbacEntityName,
    rbacAction: RbacAction,
    rbacEntityId?: Maybe<RbacEntityId>
  ) =>
  (permissionAction: RbacPermissionType): boolean => {
    // case where the edit:user:* permissions is granted and if the user wants to
    // unauthorize the edit:selfUser:* permissions
    const isGrantedToEditAllUsers = rbacCapability(
      storeManagementRbacRoles.pendingRbacPermissions
    )(RbacEntityName.User, RbacAction.Edit, null, {
      strict: true
    }).isGranted

    if (
      isGrantedToEditAllUsers &&
      rbacEntityName === RbacEntityName.SelfUser &&
      permissionAction === RbacPermissionType.unauthorize
    ) {
      storeManagementRbacRoles.storeModalEditSelfUser.show()
      return true
    }

    return false
  }

/**
 * Count the number of entities impacted to permissions, according to the
 * type of entity.
 */
export const countImpactedEntitiesAccordingEntityType = (
  rbacEntityItemRows: IDataRowRbacEntityItem[]
): number => {
  const counts: Map<string, number> = new Map()

  rbacEntityItemRows.forEach(rbacEntityItem => {
    const index = [
      rbacEntityItem.rbacEntityItemType,
      // use the name of the entity if data, otherwise, use the rbacEntityName
      rbacEntityItem.rbacEntityItemType === RbacEntityItemType.data
        ? rbacEntityItem.name
        : rbacEntityItem.rbacEntityName
    ].join(':')

    if (counts.has(index)) {
      return
    }

    counts.set(index, 1)
  })

  return Array.from(counts.values()).reduce((acc, count) => acc + count, 0)
}

/**
 * Select the correct option and validate.
 */
export const onBulkActionsSelectorChange =
  (
    storeManagementRbacRoles: StoreManagementRbacRoles,
    translate: TranslateFn
  ) =>
  (
    selectPermissionOption: React.Dispatch<
      React.SetStateAction<Maybe<IRbacBulkPermissionAction>>
    >
  ) =>
  (selectedPermissionOptionString: string) => {
    const selectedPermission = explodeBulkPermissionOptionValue(
      selectedPermissionOptionString
    )

    // update the state
    selectPermissionOption(selectedPermission)

    // configure the validate function
    const validatePermissionsFn = onBulkActionSubmit(
      storeManagementRbacRoles,
      translate
    )(storeManagementRbacRoles.storeMenu.selectedMenuKey)

    // validate now to avoid to click again on the OK button
    validatePermissionsFn(selectedPermission)()
  }

/**
 * Validate the selected bulk action.
 */
export const onBulkActionsSelectorSubmit =
  (
    storeManagementRbacRoles: StoreManagementRbacRoles,
    translate: TranslateFn
  ) =>
  (selectedPermissionOptionString: string) =>
  () => {
    const selectedPermission = explodeBulkPermissionOptionValue(
      selectedPermissionOptionString
    )

    // configure the validate function
    const validatePermissionsFn = onBulkActionSubmit(
      storeManagementRbacRoles,
      translate
    )(storeManagementRbacRoles.storeMenu.selectedMenuKey)

    validatePermissionsFn(selectedPermission)()
  }
