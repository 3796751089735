import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type { Maybe } from '@server/graphql/typeDefs/types'
import { ResourceType } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import { ContainerFlex } from '../Container'
import {
  IconFileOutlined,
  IconFilePdfOutlined,
  IconPlayCircleOutlined
} from '../Icon/IconAntd'

export interface ILabelDocumentsProps {
  name: React.ReactNode
  type: Maybe<ResourceType>
  url?: Maybe<string>
}

const LabelDocuments: React.FC<ILabelDocumentsProps> = props => {
  const getIcon = () => {
    switch (props.type) {
      case ResourceType.Pdf:
        return <IconFilePdfOutlined />

      case ResourceType.Mp4:
        return <IconPlayCircleOutlined />

      case ResourceType.File:
      case ResourceType.Hyperlink:
        return <IconFileOutlined />

      default:
        assertUnreachableCase(props.type)
    }
  }

  const getName = () => {
    if (props.url) {
      return (
        <a target="_blank" rel="noopener noreferrer" href={props.url}>
          {props.name}
        </a>
      )
    }

    return <div>{props.name}</div>
  }

  return (
    <ContainerFlex
      name="LabelDocuments"
      alignItems="center"
      items={[getIcon(), getName()]}
      spaced
      spaceWidth="small"
      labelledBy={`document-${props.name}`}
    />
  )
}

export default LabelDocuments
