import type {
  EntityTopologyDirectory,
  EntityTopologyTrust
} from '@app/entities'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { isDefined } from '@libs/isDefined'
import type { Maybe } from '@server/graphql/typeDefs/types'
import { TrustHazardLevel } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onTrustClick, onTrustMouseOut, onTrustMouseOver } from './handlers'
import type SceneObject from './SceneObject'
import TrustArrow from './TrustArrow'
import TrustAttributes from './TrustAttributes'
import TrustPath from './TrustPath'

interface ITrustProps {
  className?: string
  uid: string
  drawString: string
  hazardLevel: TrustHazardLevel
  isInternal: boolean
  attributes: string[]
  isSvgDominantBaselineSupported: boolean
  correlatedTrust: Maybe<EntityTopologyTrust>
  directorySceneObjects: {
    from: SceneObject<EntityTopologyDirectory>
    to: SceneObject<EntityTopologyDirectory>
  }
}

const Trust: React.FC<ITrustProps> = props => {
  const appRouter = useAppRouter()

  const { storeRbac, storeTopology } = useStores()

  const { from, to } = props.directorySceneObjects

  if (!from.object.uidInfrastructure || !to.object.uidInfrastructure) {
    return null
  }

  if (!from.object.uid || !to.object.uid) {
    return null
  }

  // this trust is highlighted if its from or to directory is part of a current searched infrastructure
  const isHighlightedFromInfra = storeTopology.isTrustHighlightedFromInfra(
    from.object,
    to.object
  )

  const isHighlightedFromDirectory =
    storeTopology.isTrustHighlightedFromDirectory(from.object, to.object)

  const isSameInfrastructuretUid =
    storeTopology.infrastructureUid === from.object.uidInfrastructure ||
    storeTopology.infrastructureUid === to.object.uidInfrastructure

  // this trust is active if its from or to directory is part of a current hovered infrastructure
  const isActive = storeTopology.infrastructureUid && isSameInfrastructuretUid

  const isHighlighted =
    isActive || isHighlightedFromInfra || isHighlightedFromDirectory

  const isHovered = storeTopology.isSameUuidAsTooltip(props.uid)

  const isBidirectional = isDefined(props.correlatedTrust)

  const isZoomed = storeTopology.chartZoomData.k > 1

  const fromDirectoryName = from.object.name || ''
  const toDirectoryName = to.object.name || ''

  const hazardLevelColor =
    (props.hazardLevel === TrustHazardLevel.Dangerous && consts.colorRed001) ||
    (props.hazardLevel === TrustHazardLevel.Unknown && consts.colorOrange001) ||
    consts.colorBlue010

  return (
    <g data-name="Trust" className={props.className}>
      <g
        onClick={onTrustClick(storeRbac, appRouter)(
          props.hazardLevel,
          props.isInternal
        )}
        onMouseOver={onTrustMouseOver(storeTopology)(
          <TrustAttributes
            attributes={props.attributes}
            correlatedTrust={props.correlatedTrust}
            fromDirectoryName={fromDirectoryName}
            toDirectoryName={toDirectoryName}
            hazardLevelColor={hazardLevelColor}
            isSvgDominantBaselineSupported={
              props.isSvgDominantBaselineSupported
            }
          />,
          props.uid
        )}
        onMouseOut={onTrustMouseOut(storeTopology)}
      >
        <TrustPath
          uid={props.uid}
          drawString={props.drawString}
          hazardLevel={props.hazardLevel}
          isHovered={isHovered}
          isHighlighted={isHighlighted}
          isZoomed={isZoomed}
        />

        {!props.isInternal && props.isSvgDominantBaselineSupported && (
          <TrustArrow
            uid={props.uid}
            isBidirectional={isBidirectional}
            hazardLevel={props.hazardLevel}
            isHovered={isHovered}
            isHighlighted={isHighlighted}
          />
        )}
      </g>
    </g>
  )
}

export default observer(Trust)
