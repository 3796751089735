import type { PropertiesNullable } from '@@types/helpers'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import type { AdObject } from '@server/graphql/typeDefs/types'
import EntityAdObject from '.'
import type { IEntityListable, IEntityOmitKeysParameter } from './../types'
import type { IDataRowAdObjectAsDeviantObject } from './types'

export default class EntityAdObjectAsDeviantObject
  extends EntityAdObject
  implements
    PropertiesNullable<AdObject>,
    IEntityListable<IDataRowAdObjectAsDeviantObject>
{
  /* Implements IEntityListable */

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowAdObjectAsDeviantObject> = []
  ): Array<IWidgetListColumns<IDataRowAdObjectAsDeviantObject>> {
    const columns: Array<IWidgetListColumns<IDataRowAdObjectAsDeviantObject>> =
      [
        {
          label: 'ID',
          key: 'id'
        },
        {
          label: 'Type',
          key: 'type'
        },
        {
          label: 'Object',
          key: 'class'
        },
        {
          label: 'Path',
          key: 'dn'
        },
        {
          label: 'Domain',
          key: 'directoryId'
        },
        {
          label: 'Reasons',
          key: 'reasonIds'
        }
      ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  /**
   * Return the entity as a row for widget lists.
   */
  asDataRow(): IDataRowAdObjectAsDeviantObject {
    return {
      id: this.getPropertyAsNumber('id'),
      type: this.getPropertyAsString('type'),
      class: this.getClassOrFile(),
      dn: this.getDNOrGlobalPath(),
      directoryId: this.getPropertyAsNumber('directoryId'),
      reasonIds: this.getPropertyAsArrayOf<number>('reasons')
    }
  }
}
