import { LabelDate } from '@app/components-legacy/Label'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { DateFormat } from '@libs/dates/formatDate'
import { Language } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'

interface IContainerHTMLContentExtendedDateProps {
  className?: string
  date: string
  key?: number
}

const ContainerHTMLContentExtendedDate: React.FC<
  IContainerHTMLContentExtendedDateProps
> = props => {
  const { storeRoot } = useStores()

  const format =
    storeRoot.appTranslator.language === Language.FrFr
      ? DateFormat.frenchFullDate
      : DateFormat.englishFullDate

  return (
    <code className={props.className} key={props.key}>
      <LabelDate date={props.date} inline formatDateOptions={{ format }} />
    </code>
  )
}

export default styled(ContainerHTMLContentExtendedDate)`
  background-color: ${consts.colorBlueGray030};
  padding: 1px 3px;
`
