import { useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { HealthCheckDetailsContext } from './context'
import HealthCheckDetailsInformation from './HealthCheckDetailsInformation'

interface IHealthCheckDomainStatusDetailsInformationPageProps {}

const HealthCheckDomainStatusDetailsInformationPage: React.FC<
  IHealthCheckDomainStatusDetailsInformationPageProps
> = props => {
  const { storeHealthCheck } = useStores()

  const parameters = React.useContext(HealthCheckDetailsContext)

  if (!parameters || !storeHealthCheck.healthCheckDomainStatusDetails) {
    return null
  }

  return (
    <HealthCheckDetailsInformation
      healthCheckDetails={storeHealthCheck.healthCheckDomainStatusDetails}
    />
  )
}

export default observer(HealthCheckDomainStatusDetailsInformationPage)
