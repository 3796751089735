import { ContainerFlex } from '@app/components/Container'
import { IconCheckCircle } from '@app/components/Icon'
import IconDirection, { Direction } from '@app/components/Icon/IconDirection'
import { LabelSliced } from '@app/components/Labels'
import FormWrapperButtonSimple from '@app/components-legacy/Form/Wrappers/ButtonSimple'
import FormWrapperCheckboxSimple from '@app/components-legacy/Form/Wrappers/FormWrapperCheckboxSimple'
import type { EntityInfrastructure } from '@app/entities'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import Cell from './Cell'
import {
  handleInfrastructureCollapseOrExpand,
  handleInfrastructureForAllAttacksOnChange
} from './handlers'

interface IInfrastructureCellProps {
  className?: string
  isCheckable: boolean
  infrastructure: EntityInfrastructure
}

const Div = styled.div`
  margin-bottom: ${consts.marginSmall};
`

const StyledContainerFlexCellHeader = styled(ContainerFlex)`
  margin-left: ${consts.marginMedium};
`

const CellInfrastructure: React.FC<IInfrastructureCellProps> = props => {
  const { storeManagementAttackTypeConfiguration } = useStores()

  const CheckboxElt = props.isCheckable && (
    <FormWrapperCheckboxSimple
      labelledBy={props.infrastructure.getPropertyAsString('name')}
      checked={storeManagementAttackTypeConfiguration.isInfrastructureForAllAttacksSelected(
        props.infrastructure.getPropertyAsNumber('id')
      )}
      indeterminate={storeManagementAttackTypeConfiguration.isInfrastructureForAllAttacksPartiallySelected(
        props.infrastructure.getPropertyAsNumber('id')
      )}
      onChange={handleInfrastructureForAllAttacksOnChange(
        storeManagementAttackTypeConfiguration
      )(props.infrastructure.getPropertyAsNumber('id'))}
    />
  )

  const isInfrastructureCollapsed =
    storeManagementAttackTypeConfiguration.infrastructuresCollapsed.get(
      props.infrastructure.getPropertyAsNumber('id')
    )

  return (
    <Cell
      key={`infrastructure-${props.infrastructure.id}`}
      className={props.className}
      direction="column"
      isInfrastructure
    >
      <StyledContainerFlexCellHeader
        name="CellInfrastructureHeader"
        justifyContent="flex-end"
        alignItems="center"
        spaced
        spaceWidth="default"
        items={[
          <IconCheckCircle />,
          <FormWrapperButtonSimple
            labelledBy="CollapseOrExpandInfrastructure"
            onClick={handleInfrastructureCollapseOrExpand(
              storeManagementAttackTypeConfiguration
            )(props.infrastructure.getPropertyAsNumber('id'))}
          >
            <IconDirection
              color={consts.FontColorV2.primary}
              direction={
                isInfrastructureCollapsed ? Direction.right : Direction.left
              }
            />
          </FormWrapperButtonSimple>
        ]}
      />

      <Div>
        <LabelSliced
          maxLength={15}
          value={props.infrastructure.getPropertyAsString('name')}
          tooltipProps={{
            placement: 'bottom'
          }}
        />
      </Div>

      {CheckboxElt}
    </Cell>
  )
}

export default observer(CellInfrastructure)
