import type { IIconProps } from '@app/components/Icon/types'
import { FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconRightArrowThinProps extends IIconProps {}

const IconRightArrowThin: React.FC<IIconRightArrowThinProps> = props => {
  const fill = props.color ?? FontColorV2.secondary
  const width = props.size ?? 15
  const height = width / 1.5

  return (
    <svg width={width} height={height} viewBox="0 0 15 10" fill="none">
      <path
        d="M9.80617 8.99383L10.2123 9.4L14.9123 4.7L10.2123 0L9.80617 0.406173L13.8099 4.40988H0V4.99012H13.8099L9.80617 8.99383Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconRightArrowThin
