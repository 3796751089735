import { ContainerFlex } from '@app/components-legacy/Container'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { TIMELINE_HEIGHT } from './constant'

interface IFiltersTimelineNavigationProps {
  className?: string
  icon: React.ReactNode
  isLoading: boolean
  onClick: () => void
}

const FiltersTimelineNavigation: React.FC<
  IFiltersTimelineNavigationProps
> = props => {
  return (
    <ContainerFlex
      name={FiltersTimelineNavigation.name}
      className={props.className}
      alignItems="center"
      justifyContent="center"
      onClick={props.onClick}
      items={[props.icon]}
    />
  )
}

export default styled(FiltersTimelineNavigation)`
  width: 32px;
  min-width: 32px;
  height: ${TIMELINE_HEIGHT};
  background-color: ${consts.colorGrey010};
  color: ${consts.colorBlueGray001};
  cursor: ${props => (props.isLoading ? 'default' : 'pointer')};
  transition: ${consts.transitionBackground};

  &:hover {
    background-color: ${props =>
      props.isLoading ? consts.colorGrey010 : consts.colorBlueGray030};
  }

  @media print {
    display: none;
  }
`
