import { useStores } from '@app/hooks'
import {
  canAccessToSecurityEngineMilestone2,
  canAccessToSecurityEngineMilestone3,
  canAccessToSecurityEngineMilestone4,
  canAccessToSecurityEngineMilestone5,
  canAccessToSecurityEngineMilestone6
} from '@app/pages/SecurityEngine/WeaknessInstancesPage/permissions'
import styled from '@emotion/styled'
import type { ISecurityEngineAppConfiguration } from '@libs/IframeCommunicationBus/types'
import React from 'react'
import { buildVariants } from 'tenable-design-system-alt'
import { SecurityEngineApp } from './SecurityEngineApp'

const Div = styled.div(props => {
  return buildVariants(props)
    .css({
      height: '100%'
    })
    .end()
})

export default function SecurityEngineContainer() {
  const { storeRoot, storeRbac } = useStores()

  const securityEngineRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!securityEngineRef.current) {
      return
    }

    const isProduction = storeRoot.environment.config.production
      ? 'true'
      : 'false'

    const tenablePlatformOrigin = storeRoot.environment.tenablePlatformOrigin()

    const securityEngineSdkConfiguration: ISecurityEngineAppConfiguration = {
      // endpoint: 'http://localhost:4010',
      endpoint: '/w/tenable-services-proxy/security-engine-ui/index.html',
      /* Uncomment to target a specific page for testing purposes */
      // initialPage:
      //   '/assets/bccedc86-16a3-4d1e-b2a6-3875316c847b/identity/accounts',
      preferences: {
        featureFlags: {
          milestone2: storeRbac.isUserGrantedTo(
            canAccessToSecurityEngineMilestone2
          )
            ? 'true'
            : 'false',

          milestone3: storeRbac.isUserGrantedTo(
            canAccessToSecurityEngineMilestone3
          )
            ? 'true'
            : 'false',
          milestone4: storeRbac.isUserGrantedTo(
            canAccessToSecurityEngineMilestone4
          )
            ? 'true'
            : 'false',
          milestone5: storeRbac.isUserGrantedTo(
            canAccessToSecurityEngineMilestone5
          )
            ? 'true'
            : 'false',
          milestone6: storeRbac.isUserGrantedTo(
            canAccessToSecurityEngineMilestone6
          )
            ? 'true'
            : 'false'
        },
        language: 'en',
        // We can't pass the whole session because that will be larger than the allowed request header size
        session: {
          // FIXME
          containerUuid: 'FIXME',
          uuid: 'FIXME'
        },
        // Example
        layout: {
          backToAssetsButtonUrl: {
            type: 'internal',
            routeName: 'WeaknessInstances'
          }
        },
        environment: {
          origin: '',
          tenablePlatformOrigin,
          production: isProduction,
          servicesPrefixPath:
            '/w/tenable-services-proxy/service-security-engine-core'
        }
      },
      iframeMessageHandlers: {
        onExternalLinkClick: (_, iframeMessage) => {
          if (iframeMessage.selectedApplication) {
            window.localStorage.setItem(
              'selectedApplication',
              iframeMessage.selectedApplication
            )
          }

          switch (iframeMessage.target) {
            // consider using the "parent"'s internal router to handle link with _parent target
            case '_parent': {
              storeRoot.appRouter.history.push(iframeMessage.url)
              break
            }

            // use `_blank` to open the url in a new tab
            default: {
              window.open(iframeMessage.url, iframeMessage.target)
              break
            }
          }
        }
        /*
        trackTelemetry: (_, iframeMessage) => {
          storeRoot.stores.storeLayout.tulApi?.trackTelemetry(
            iframeMessage.data.eventName,
            iframeMessage.data.eventProperties
          )
        }
        */
      }
    }

    const securityEngine = new SecurityEngineApp(
      securityEngineSdkConfiguration
    ).mountInto('#SecurityEngineContainer')

    return () => securityEngine.unmount()
  }, [securityEngineRef])

  return <Div ref={securityEngineRef} id="SecurityEngineContainer" />
}
