import { isDefined } from '@libs/isDefined'
import type {
  ConfigurationParameters,
  HTTPHeaders
} from '@libs/openapi/service-identity-core'
import {
  CheckersApi,
  Configuration as ServiceIdentityCoreResourceConfiguration,
  HealthApi,
  SettingsApi,
  TenantsApi
} from '@libs/openapi/service-identity-core'
import { IdentitiesApiExtended } from '@libs/openapi-clients/extendedClients/IdentitiesApiExtended'
import type { Maybe } from '@productive-codebases/toolbox'
import * as fetch from 'isomorphic-fetch'
import type { IOpenApiClients, IServiceIdentityCore } from './types'
import { OpenApiClientEndPoint } from './types'

export class OpenApiClients implements IOpenApiClients {
  serviceIdentityCore!: IServiceIdentityCore

  // Needed server side
  private _origin?: Maybe<string>

  // Optional headers (authentication, language, etc)
  private _headers?: HTTPHeaders

  private _defaultConfiguration: ConfigurationParameters = {
    fetchApi: fetch
  }

  /**
   * OpenAPI clients needs absolute urls server side.
   * Client side, only the pathname given by OpenApiClientEndPoint is required.
   */
  setOrigin(origin: string): this {
    this._origin = origin
    return this
  }

  /**
   * Set the authentication token, needed server side.
   * Client queries will use `tenableServicesProxyRouter` middleware that ensures authentication.
   */
  setHeaders(headers: HTTPHeaders): this {
    this._headers = headers
    return this
  }

  /**
   * Instanciate all OpenApi clients.
   */
  createClients(): this {
    const serviceIdentityCoreConfig = this._mergeConfiguration(
      OpenApiClientEndPoint.serviceIdentityCore
    )

    // service-identity-core
    this.serviceIdentityCore = {
      checkers: new CheckersApi(
        new ServiceIdentityCoreResourceConfiguration(serviceIdentityCoreConfig)
      ),
      health: new HealthApi(
        new ServiceIdentityCoreResourceConfiguration(serviceIdentityCoreConfig)
      ),
      identities: new IdentitiesApiExtended(
        new ServiceIdentityCoreResourceConfiguration(serviceIdentityCoreConfig)
      ),
      settings: new SettingsApi(
        new ServiceIdentityCoreResourceConfiguration(serviceIdentityCoreConfig)
      ),
      tenants: new TenantsApi(
        new ServiceIdentityCoreResourceConfiguration(serviceIdentityCoreConfig)
      )
    }

    return this
  }

  /**
   * Private
   */

  private _getBasePath(openApiClientEndPoint: OpenApiClientEndPoint) {
    return [this._origin, openApiClientEndPoint].filter(isDefined).join('')
  }

  /**
   * Merge configurations and headers.
   */
  private _mergeConfiguration(
    openApiClientEndPoint: OpenApiClientEndPoint
  ): ConfigurationParameters {
    return {
      ...this._defaultConfiguration,
      basePath: this._getBasePath(openApiClientEndPoint),
      headers: this._headers
    }
  }
}
