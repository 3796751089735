import type { Maybe } from '@@types/helpers'
import type { StoreRoot } from '@app/stores'
import StoreBase from '@app/stores/StoreBase'
import { ensureArray } from '@libs/ensureArray'
import { isDefined } from '@libs/isDefined'
import { action, computed, makeObservable, observable } from 'mobx'
import Field from './Field'
import { trim } from './sanitizors'
import type {
  FieldError,
  FieldName,
  FieldValue,
  IFieldError,
  IFieldMultiValue,
  IFieldValue,
  InputType,
  IStoreFormSetup
} from './types'

const getMultiFieldRegExp = (fieldName: FieldName): RegExp => {
  return new RegExp(`^${fieldName}\\.\\d+$`)
}

export interface IStoreFormOptions<TFieldEnum extends string> {
  setup?: IStoreFormSetup<TFieldEnum>
}

export default class StoreForm<TFieldEnum extends string> extends StoreBase<
  IStoreFormOptions<TFieldEnum>
> {
  /**
   * Observable
   */
  private $isValid = observable.box<boolean>(false)

  /**
   * Private
   */

  private _fields: Map<FieldName, Field> = new Map()
  // final StoreForm setup
  private _setup: Maybe<IStoreFormSetup<TFieldEnum>> = null

  private translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Errors.Form']
  })

  /**
   * Optional fieldNames to declare available field names in the form.
   */
  constructor(
    storeRoot: StoreRoot,
    options: IStoreFormOptions<TFieldEnum> = {}
  ) {
    super(storeRoot, options)

    const setup = options.setup

    if (!setup) {
      return
    }

    // add custom translate function
    if (setup?.translate) {
      this.translate = setup.translate
    }

    const fieldNames = Object.keys(setup.fields) as TFieldEnum[]

    // setup fields according to the setup passed as parameter
    fieldNames.forEach(fieldName => {
      const field = setup.fields[fieldName]

      if (!field) {
        return
      }

      // it will create the field
      this.field(fieldName)

      if (field.label) {
        this.field(fieldName).addLabel(field.label)
      }

      if (field.labelAlignItem) {
        this.field(fieldName).addLabelAlignItem(field.labelAlignItem)
      }

      if (field.description) {
        this.field(fieldName).addDescription(field.description)
      }

      if (field.inputType) {
        this.field(fieldName).addInputType(field.inputType)
      }

      ensureArray(field.sanitizors).forEach(sanitizor => {
        this.field(fieldName).addSanitizor(sanitizor)
      })

      ensureArray(field.validators).forEach(validator => {
        this.field(fieldName).addValidator(validator)
      })
    })

    this._setup = setup

    makeObservable(this)
  }

  /**
   * Return a field value as a string.
   */
  getFieldValueAsString<T = string>(fieldName: FieldName, fallback?: T): T {
    this.assertFieldExists(fieldName)

    const field = this.field(fieldName)

    if (!field.hasValue) {
      return (fallback || '') as T
    }

    return field.asString as unknown as T
  }

  /**
   * Return a field value as a string or null.
   */

  getFieldValueAsStringOrNull(
    fieldName: FieldName,
    fallback?: string
  ): Maybe<string> {
    this.assertFieldExists(fieldName)

    const field = this.field(fieldName)

    if (!field.hasValue) {
      return fallback || null
    }

    return field.asString as unknown as string
  }

  /**
   * Return a field value as a boolean.
   */
  getFieldValueAsBoolean(
    fieldName: FieldName,
    fallback: boolean = false
  ): boolean {
    this.assertFieldExists(fieldName)

    const field = this.field(fieldName)

    if (!field.hasValue) {
      return fallback
    }

    return field.asBoolean
  }

  /**
   * Return a field value as a number.
   */
  getFieldValueAsNumber(fieldName: FieldName, fallback: number = 0): number {
    this.assertFieldExists(fieldName)

    const field = this.field(fieldName)

    if (!field.hasValue) {
      return fallback
    }

    return field.asNumber
  }

  /**
   * Return a field value for a <Select mode="multiple" />
   */
  getFieldValueForSelectMultiple(fieldName: FieldName): string[] {
    this.assertFieldExists(fieldName)

    if (!this.field(fieldName).hasValue) {
      return []
    }

    return this.getFieldValueAsString(fieldName).split(',').filter(isDefined)
  }

  /**
   * Return the fields objects matching a multi-values field.
   */
  getMultiValuesFields(
    fieldName: FieldName,
    options = { removeEmptyFields: true }
  ): Field[] {
    this.assertFieldExists(fieldName)

    const regexp = getMultiFieldRegExp(fieldName)

    const fields = Array.from(this._fields.values()).filter(field =>
      regexp.test(field.name)
    )

    if (options.removeEmptyFields) {
      return fields.filter(field => field.value !== '')
    }

    return fields
  }

  /**
   * Return a set of field values, merged to an array according to a defined prefix.
   * Useful for multi-fields input.
   *
   * Example:
   *
   * allowedGroups.1
   * allowedGroups.2
   * allowedGroups.3
   *
   * => {'allowedGroups': [1, 2, 3]}
   */
  getMultiValuesFieldValuesAsArray(
    fieldName: FieldName,
    options = { removeEmptyFields: true }
  ): FieldValue[] {
    this.assertFieldExists(fieldName)

    return this.getMultiValuesFields(fieldName, options)
      .map(field => field.value)
      .filter(isDefined)
  }

  /**
   * Return all fields.
   * (Remove the multi-fields name that should be considered like only one field).
   */
  getFields(): Field[] {
    return Array.from(this._fields.entries())
      .filter(([name]) => !/\.\d+$/.test(name))
      .map(([, field]) => field)
  }

  /**
   * Return the name of all fields.
   * (Remove the multi-fields name that should be considered like only one field).
   */
  getFieldNames(): FieldName[] {
    return Array.from(this._fields.keys()).filter(name => !/\.\d+$/.test(name))
  }

  /**
   * Return the input type of the field.
   */
  getFieldInputType(fieldName: FieldName): InputType {
    this.assertFieldExists(fieldName)

    const field = this.field(fieldName)

    return field.inputType
  }

  /**
   * Return true if the fieldName is a multi values one.
   */
  isMultiValuesField(fieldName: FieldName): boolean {
    this.assertFieldExists(fieldName)

    const regexp = getMultiFieldRegExp(fieldName)

    return (
      Array.from(this._fields.values()).filter(field => regexp.test(field.name))
        .length > 0
    )
  }

  /**
   * Return true if the field is required.
   */
  isFieldRequired(fieldName: FieldName): boolean {
    this.assertFieldExists(fieldName)
    const field = this.field(fieldName)
    return field.required
  }

  /**
   * Set default values for each fields.
   */
  setDefaultFieldsValues(defaultFieldsValues: IFieldValue[]): this {
    defaultFieldsValues.forEach(field => {
      try {
        this.assertFieldExists(field.key)

        this.field(field.key).setDefaultValue(String(field.value))
      } catch (err) {
        this.storeRoot.logger.warn(
          `[StoreForm] The field ${field.key} doesnt exist`
        )
      }
    })

    return this
  }

  /**
   * Set default values for each fields from an object.
   */
  setDefaultFieldsValuesFromObject<T extends object>(
    obj: T,
    selectKeys?:
      | {
          action: 'pick'
          keys: Array<keyof T>
        }
      | {
          action: 'omit'
          keys: Array<keyof T>
        }
  ): this {
    const keys = Object.keys(obj) as Array<keyof T>

    const remainingKeys = isDefined(selectKeys)
      ? selectKeys.action === 'pick'
        ? keys.filter(k => selectKeys.keys.includes(k))
        : keys.filter(k => !selectKeys.keys.includes(k))
      : keys

    const defaultValues = remainingKeys.reduce<IFieldValue[]>((acc, key) => {
      try {
        this.assertFieldExists(String(key))

        const value = obj[key]

        if (!isDefined(value)) {
          return acc
        }

        // if the value is an array, loop over values and set a field entry for each.
        if (Array.isArray(value)) {
          value.forEach((val, i) => {
            const fieldKey = `${String(key)}.${i}`
            acc.push({ key: fieldKey, value: String(val) })
          })

          return acc
        }

        return acc.concat({
          key: key as FieldName,
          value: value as unknown as FieldValue
        })
      } catch (err) {
        this.storeRoot.logger.warn(
          `[StoreForm] The field ${String(key)} doesnt exist`
        )

        return acc
      }
    }, [])

    this.setDefaultFieldsValues(defaultValues)

    return this
  }

  /**
   * Set the current values as default values.
   * Useful after a submission to set new default values and handle correctly
   * the dirty state of fields.
   */
  setCurrentFieldsValuesAsDefault(): this {
    Array.from(this._fields.values()).forEach(field => {
      try {
        this.assertFieldExists(field.name)

        if (!field.value) {
          return
        }

        field.setDefaultValue(field.value)
      } catch (err) {
        this.storeRoot.logger.warn(
          `[StoreForm] The field ${field.name} doesnt exist`
        )
      }
    })

    return this
  }

  /**
   * Set default values for each multi values fields.
   */
  setDefaultFieldsMultiValues(
    defaultFieldsMultiValues: IFieldMultiValue[]
  ): this {
    defaultFieldsMultiValues.forEach(field => {
      try {
        this.assertFieldExists(field.key)

        // delete fields to set defaults from scratch
        this.getMultiValuesFields(field.key, {
          removeEmptyFields: false
        }).forEach(multiValueField => {
          this.deleteField(multiValueField.name)
        })

        field.values.forEach((value, i) => {
          const fieldKey = `${field.key}.${i}`
          this.field(fieldKey).setDefaultValue(value)
        })
      } catch (err) {
        this.storeRoot.logger.warn(
          `[StoreForm] The field ${field.key} doesnt exist`
        )
      }
    })

    return this
  }

  /**
   * Return true if a value has been defined for a field.
   */
  hasFieldValueDefined(fieldName: FieldName) {
    this.assertFieldExists(fieldName)

    return this.field(fieldName).hasValue
  }

  /**
   * Return a new Field object.
   * (one instance by field)
   */
  field(fieldName: FieldName, defaultBehavior = true): Field {
    this.assertFieldExists(fieldName)

    if (this._fields.has(fieldName)) {
      return this._fields.get(fieldName)!
    }

    const field = new Field(fieldName, this.translate)

    if (defaultBehavior) {
      // add the trim sanitizor by default
      field.addSanitizor(trim())
    }

    this._fields.set(fieldName, field)

    return field
  }

  /**
   * Delete a field.
   */
  deleteField(fieldName: FieldName): this {
    this.assertFieldExists(fieldName)

    this._fields.delete(fieldName)
    return this
  }

  /**
   * Delete all fields.
   */
  deleteAllFields(): this {
    this._fields.clear()
    return this
  }

  /**
   * Set values for each field.
   */
  setFieldsErrors(errors: IFieldError[]): this {
    errors.forEach(({ key, error }) => {
      try {
        this.assertFieldExists(key)

        this.setFieldError(key, error)
      } catch (err) {
        this.storeRoot.logger.warn(`[StoreForm] The field ${key} doesnt exist`)
      }
    })

    return this
  }

  /**
   * Set value for one field.
   */
  setFieldValue(fieldName: FieldName, fieldValue: FieldValue): this {
    this.assertFieldExists(fieldName)

    this.field(fieldName).setValue(fieldValue)

    return this
  }

  /**
   * Set error for one field.
   */
  setFieldError(fieldName: FieldName, fieldError: FieldError): this {
    this.assertFieldExists(fieldName)

    this.field(fieldName).setError(fieldError)
    return this
  }

  /**
   * Return true if at least one field is dirty.
   */
  isDirty(): boolean {
    return Array.from(this._fields.values()).some(field => field.isDirty())
  }

  /**
   * Return true if the field is dirty.
   * Instead of the `isDirty` method of the Field class, this method handles
   * multi-values fields.
   */
  isFieldIsDirty(fieldName: FieldName): boolean {
    this.assertFieldExists(fieldName)

    const isMultiValuesField = this.isMultiValuesField(fieldName)

    if (!isMultiValuesField) {
      return this.field(fieldName).isDirty()
    }

    return this.getMultiValuesFields(fieldName, {
      removeEmptyFields: false
    }).some(field => {
      return field.isDirty()
    })
  }

  /**
   * Sanitize fields entries.
   */
  sanitize(): this {
    Array.from(this._fields.values()).forEach(field => field.sanitize())

    return this
  }

  /**
   * Validate the form.
   * Return true if the form is valid.
   */
  validate(options: { skipFields?: FieldName[] } = {}): boolean {
    const isValid = Array.from(this._fields.entries())
      .filter(([fieldName]) => {
        const intoSkippedField =
          ensureArray(options.skipFields).indexOf(fieldName) !== -1
        return !intoSkippedField
      })
      .map(([, field]) => field.validate())
      .every(b => b === true)

    this.setValidation(isValid)

    return isValid
  }

  /**
   * Reset all fields.
   */
  reset(): this {
    this._fields.forEach(field => {
      field.reset()
    })

    this.setValidation(false)

    return this
  }

  resetFieldErrors(fieldName: FieldName): this {
    this.assertFieldExists(fieldName)

    this.field(fieldName).clearErrors()
    return this
  }

  /**
   * Reset all fields and default values.
   */
  hardReset(): this {
    this._fields.forEach(field => {
      field.hardReset()
    })

    this.setValidation(false)

    return this
  }

  /**
   * Get the field values.
   * Useful for debugging.
   */
  get debugFieldsValues(): { [key: string]: string } {
    return Array.from(this._fields.values()).reduce<{
      [key: string]: string
    }>((acc, field) => {
      return {
        ...acc,
        [field.name]: field.asString
      }
    }, {})
  }

  /**
   * Get the field errors.
   * Useful for debugging.
   */
  get debugFieldsErrors(): { [key: string]: string[] } {
    return Array.from(this._fields.values()).reduce<{
      [key: string]: string[]
    }>((acc, field) => {
      return {
        ...acc,
        [field.name]: (acc[field.name] || []).concat(field.errors)
      }
    }, {})
  }

  /**
   * Get the field successes.
   * Useful for debugging.
   */
  get debugFieldsSuccesses(): { [key: string]: string[] } {
    return Array.from(this._fields.values()).reduce<{
      [key: string]: string[]
    }>((acc, field) => {
      return {
        ...acc,
        [field.name]: (acc[field.name] || []).concat(field.successes)
      }
    }, {})
  }

  /**
   * Assert that the field exists.
   */
  private assertFieldExists(fieldName: FieldName): void {
    if (!this._setup) {
      return
    }

    if (!this._setup.fields) {
      return
    }

    if (this._setup.assertFieldExists === false) {
      return
    }

    const fieldNames = Object.keys(this._setup.fields)

    if (fieldNames.indexOf(fieldName) !== -1) {
      return
    }

    this.storeRoot.logger.debug(
      `[storeForm] The field ${fieldName} doesn't exist`
    )

    throw new Error(`The field ${fieldName} doesn't exist`)
  }

  /* Actions */

  @action
  setValidation(isValid: boolean): this {
    this.$isValid.set(isValid)
    return this
  }

  /* Computed */

  @computed
  get isValid(): boolean {
    return this.$isValid.get()
  }
}
