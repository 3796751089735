import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import TenantCommonForm from '../TenantsCreatePage/TenantCommonForm'
import { handleTenantEditionOnSubmit } from './handlers'

interface ITenantEditFormProps {
  tenantId: string
}

const TenantEditForm: React.FC<ITenantEditFormProps> = props => {
  const { storeManagementTenants } = useStores()

  return (
    <TenantCommonForm
      storeForm={storeManagementTenants.storeFormEdition}
      version="edition"
      onSubmit={handleTenantEditionOnSubmit(storeManagementTenants)(
        props.tenantId
      )}
    />
  )
}

export default TenantEditForm
