import type { Maybe, Perhaps } from '@@types/helpers'
import { routesMatcher } from '@app/components-legacy/Router/utils'
import type { AppRouteName } from '@app/routes'
import type { StoreRoot } from '@app/stores'
import StoreBase from '@app/stores/StoreBase'
import { ensureArray } from '@libs/ensureArray'
import type { IObservableValue } from 'mobx'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import type { IMenuEntry } from './types'

export interface IStoreMenuOptions<ME extends IMenuEntry<any>> {
  menuEntries?: Perhaps<ME[]>
  defaultSelectedMenuKey?: ME['key']
}

/**
 * Store to use with the Menu component.
 */
export default class StoreMenu<ME extends IMenuEntry<any>> extends StoreBase<
  IStoreMenuOptions<ME>
> {
  private _routeMatcher = routesMatcher(this.storeRoot.appRouter)

  /**
   * Observable
   */

  private $menuEntries = observable.array<ME>(
    ensureArray(this.options.menuEntries)
  )

  private $selectedMenuKey = observable.box<Maybe<ME['key']>>(
    this.options.defaultSelectedMenuKey || null
  )

  private $expanded = observable.box<boolean>(false)

  constructor(storeRoot: StoreRoot, options: IStoreMenuOptions<ME> = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Check that an entry menu is selected according to the current route name.
   */

  /**
   * Return the menu entries recursively that matches the current route name.
   */
  routeMatchingMenuEntries(currentRouteName: AppRouteName): ME[] {
    const filteredMenuEntries: ME[] = []

    const browseMenuEntries = (menuEntries: ME[]): void => {
      menuEntries
        .filter(menuEntry => {
          return (
            menuEntry.routeDefinition &&
            this._routeMatcher(
              menuEntry.routeDefinition.routeName,
              currentRouteName
            )
          )
        })
        .forEach(menuEntry => {
          filteredMenuEntries.push(menuEntry)

          if (menuEntry.menuEntries) {
            browseMenuEntries(menuEntry.menuEntries as ME[])
          }
        })
    }

    browseMenuEntries(this.menuEntries)

    return filteredMenuEntries
  }

  /**
   * Return the menuEntry object of a menu entry.
   */
  getMenuEntry(key: string): Maybe<ME> {
    return this.$menuEntries.find(menuEntry => menuEntry.key === key) || null
  }

  /* Actions */

  /**
   * Select the default menu.
   */
  @action
  reset(): this {
    if (this.options.defaultSelectedMenuKey) {
      this.selectEntry(this.options.defaultSelectedMenuKey)
    }

    return this
  }

  /**
   * Set the menu entries.
   */
  @action
  setMenuEntries(menuEntries: ME[]): this {
    this.$menuEntries.replace([...menuEntries])
    return this
  }

  /**
   * Set the default selected menu key (when store has already been instanciated).
   */
  @action
  setDefaultSelectedMenuKey(menuKey: ME['key']): this {
    this.options.defaultSelectedMenuKey = menuKey

    if (this.selectedMenuKey === null) {
      this.selectEntry(menuKey)
    }

    return this
  }

  /**
   * Add a new menu entry.
   */
  @action
  addMenuEntry(menuEntry: ME): this {
    this.$menuEntries.push(menuEntry)
    return this
  }

  /**
   * Delete a menu entry via its index.
   */
  @action
  deleteMenuEntryIndex(index: number): this {
    const isSelected = this.selectedEntryIndex === index

    this.$menuEntries.splice(index, 1)

    if (isSelected) {
      this.selectEntryIndex(index)
    }

    return this
  }

  /**
   * Select first menu entry.
   */
  @action
  selectFirstEntry(): this {
    if (!this.$menuEntries.length) {
      return this
    }
    return this.selectEntry(this.$menuEntries[0].key)
  }

  /**
   * Select last menu entry.
   */
  @action
  selectLastEntry(): this {
    if (!this.$menuEntries.length) {
      return this
    }
    return this.selectEntryIndex(this.$menuEntries.length - 1)
  }

  /**
   * Select previous menu entry.
   */
  @action
  selectPreviousEntry(): this {
    if (!this.$menuEntries.length || this.selectedEntryIndex <= 0) {
      return this
    }
    return this.selectEntryIndex(this.selectedEntryIndex - 1)
  }

  /**
   * Select next menu entry.
   */
  @action
  selectNextEntry(): this {
    if (
      !this.$menuEntries.length ||
      this.selectedEntryIndex >= this.$menuEntries.length - 1
    ) {
      return this
    }
    return this.selectEntryIndex(this.selectedEntryIndex + 1)
  }

  /**
   * Select a menu entry.
   */
  @action
  selectEntry(menuKey: Maybe<ME['key']>): this {
    this.$selectedMenuKey.set(menuKey)
    return this
  }

  /**
   * Select a menu entry by its index.
   */
  @action
  selectEntryIndex(index: number): this {
    const safeIndex = Math.max(0, Math.min(this.$menuEntries.length - 1, index))
    const entry = this.$menuEntries[safeIndex]

    this.$selectedMenuKey.set(entry.key)

    return this
  }

  /**
   * Expand menu or not.
   */
  @action
  setExpanded(isExpanded: boolean): this {
    this.$expanded.set(isExpanded)
    return this
  }

  /* Computed */

  /**
   * Return menu entries.
   */
  @computed
  get menuEntries(): ME[] {
    return toJS(this.$menuEntries)
  }

  /**
   * Return the selected menu entry key.
   */
  @computed
  get selectedMenuKey(): Maybe<ME['key']> {
    return this.$selectedMenuKey.get()
  }

  /**
   * Return the menu key as an observable value to listen changes.
   */
  @computed
  get selectedObservedMenuKey(): IObservableValue<Maybe<ME['key']>> {
    return this.$selectedMenuKey
  }

  /**
   * Return the index of the selected entry.
   */
  @computed
  get selectedEntryIndex(): number {
    const selectedMenuKey = this.$selectedMenuKey.get()

    if (!selectedMenuKey) {
      return -1
    }

    return this.$menuEntries.findIndex(entry => entry.key === selectedMenuKey)
  }

  /**
   * Return true if the menu is expanded.
   */
  @computed
  get expanded(): boolean {
    return this.$expanded.get()
  }
}
