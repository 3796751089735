import { ContainerFooter } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { Modal, ModalCancelButton } from '@app/components-legacy/Modal'
import { useAppTranslator } from '@app/hooks'
import { AttackPathSearchTabs } from '@app/pages/AttackPath/types'
import type StoreModal from '@app/stores/helpers/StoreModal'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import * as React from 'react'

interface IModalLongRenderProps {
  nodes: number
  edges: number
  activeTab: AttackPathSearchTabs
  onSubmit: () => void
  storeModal: StoreModal<any>
}

const ModalLongRender: React.FC<IModalLongRenderProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.ModalLongRender']
  })

  function getFirstSentenceKey(): string {
    switch (props.activeTab) {
      case AttackPathSearchTabs.attackPath:
        return 'The attack path graph that you are about to display'
      case AttackPathSearchTabs.blastRadius:
        return 'The blast radius graph that you are about to display'
      case AttackPathSearchTabs.assetExposure:
        return 'The asset exposure graph that you are about to display'
      default:
        assertUnreachableCase(props.activeTab)
    }
  }

  return (
    <Modal
      modalProps={{
        title: translate('Performance warning'),
        footer: (
          <ContainerFooter variant="modal">
            <ModalCancelButton storeModal={props.storeModal} />

            <FormWrapperButton
              labelledBy="submit"
              variant={ButtonVariant.primary}
              size={ButtonSize.large}
              buttonProps={{
                onClick: props.onSubmit
              }}
            >
              {translate('Go ahead')}
            </FormWrapperButton>
          </ContainerFooter>
        )
      }}
      storeModal={props.storeModal}
    >
      <Label>
        {translate(getFirstSentenceKey(), {
          interpolations: {
            nodesNumber: props.nodes,
            relationshipsNumber: props.edges
          }
        })}
      </Label>
      <br />
      <br />
      <Label>
        {translate('This high number of nodes will impact the performance')}
      </Label>
    </Modal>
  )
}

export default ModalLongRender
