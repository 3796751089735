import { Card } from '@app/components-legacy/Card'
import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { getCriticityColor } from '@libs/criticity'
import { Criticity } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'
import AlertCheckbox from './AlertCheckbox'
import AlertCheckerIcon from './AlertCheckerIcon'
import AlertDescription from './AlertDescription'
import AlertFooter from './AlertFooter'
import AlertHeader from './AlertHeader'
import { initAlertLoad, onAlertSelectionChange } from './handlers'
import type { ILabelAlertProps } from './types'
import { LabelAlertSize } from './types'

const LabelAlert: React.FC<ILabelAlertProps> = props => {
  // return a memoized version of handlers that wrap a timer to remove the alert
  const alertHandlers = React.useMemo(() => {
    return initAlertLoad(props.storeGenericAlerts)(props.alertLifeTime)
  }, [props.checkerType])

  const hasDescription = props.description !== undefined
  const checked = props.checked || false

  return (
    <ContainerContent
      onLoad={alertHandlers.onAlertLoad}
      labelledBy={`alert-${props.label}`}
    >
      <Card active={checked}>
        <ContainerFlex
          name="LabelAlert"
          className={props.className}
          itemsFlexGrow={[0, 1, 0]}
          items={[
            <AlertCheckerIcon
              checkerType={props.checkerType}
              criticity={props.criticity || Criticity.Unknown}
            />,

            <ContainerFlex
              name="LabelAlertCenter"
              direction="column"
              itemsFlexGrow={[1]}
              items={[
                <AlertHeader
                  label={props.label}
                  date={props.date}
                  read={props.read}
                  onClose={
                    props.isClosable ? alertHandlers.onAlertCloseClick : null
                  }
                />,

                hasDescription && (
                  <AlertDescription>{props.description}</AlertDescription>
                ),

                <AlertFooter
                  directories={props.directories}
                  actions={props.actions}
                  archived={props.archived}
                  onAction={props.onAction}
                />
              ]}
            />,

            props.isCheckable === true && (
              <AlertCheckbox
                alertId={props.id}
                checked={checked}
                onChange={onAlertSelectionChange(props.onSelections)}
              />
            )
          ]}
        />
      </Card>
    </ContainerContent>
  )
}

export default styled(LabelAlert)`
  position: relative;

  min-width: ${props => (props.size ? props.size : LabelAlertSize.normal)};
  max-width: ${props => (props.size ? props.size : LabelAlertSize.normal)};

  opacity: ${props => (props.archived ? 0.5 : 1)};

  border-left: 4px solid
    ${props => getCriticityColor(props.criticity || Criticity.Unknown)};
`
