import { Features } from '@alsid/common'
import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { useHistoryChange } from '@app/components-legacy/Router/hooks'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useStores } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import AttackPathSceneFilters from './Bar'
import ZoomSlider from './Bar/ZoomSlider'
import IconPinPath from './Components/IconPinPath'
import ModalCrawling from './Components/ModalCrawling'
import ModalLongRender from './Components/ModalLongRender'
import {
  handleAttackPathHistoryOnChange,
  handleLoadHeavyGraphOnClick
} from './handlers'
import AttackPathNodeFinder from './NodeFinder'
import AttackPathScene from './Scene'

interface IAttackPathSceneContentProps {}

const StyledContainer = styled(ContainerFlex)`
  padding: ${consts.paddingMedium} ${consts.paddingLarge};
`

const StyledZoomSlider = styled(ZoomSlider)`
  position: absolute;
  bottom: 20px;
  right: 30px;
  width: 210px;
  z-index: 1;
`

const AttackPathSceneContent: React.FC<
  IAttackPathSceneContentProps
> = props => {
  const { storeAttackPath } = useStores()

  const featurePinHighlightedPath = useFeatureFlag({
    featureFlagName: Features.ATTACK_PATH_PIN_HIGHLIGHTED_PATH
  })

  useHistoryChange({
    onHistoryChange: handleAttackPathHistoryOnChange(storeAttackPath)
  })

  const { searchIsDone } = storeAttackPath
  const { nodes, edges } = storeAttackPath.awaitingGraphToDisplay

  return (
    <>
      <ModalCrawling
        storeModal={storeAttackPath.storeModalServiceUnavailable}
      />
      <ModalLongRender
        nodes={nodes}
        edges={edges}
        activeTab={storeAttackPath.activeTab}
        onSubmit={handleLoadHeavyGraphOnClick(storeAttackPath)}
        storeModal={storeAttackPath.storeModalLongRender}
      />

      {searchIsDone && <AttackPathSceneFilters />}

      {searchIsDone && featurePinHighlightedPath && <IconPinPath />}

      {searchIsDone && <StyledZoomSlider />}

      <ContainerContent
        spinner={<SpinnerInline />}
        flags={[storeAttackPath.storeFlagsFetchNodes.flags]}
        style={{
          alignItems: 'center',
          justifyContent: 'center'
        }}
        customErrorMessage={storeAttackPath.customErrorMessage}
      >
        {searchIsDone ? (
          <ContainerFlex
            name="AttackPathSceneContentContent"
            labelledBy="AttackPath"
            direction="column"
            items={[<AttackPathScene />]}
            fullHeight
          />
        ) : (
          <StyledContainer
            name="AttackPathSceneContentContent"
            labelledBy="AttackPath"
            direction="column"
            items={[<AttackPathNodeFinder />]}
            fullHeight
          />
        )}
      </ContainerContent>
    </>
  )
}

export default observer(AttackPathSceneContent)
