import { AssetImg } from '@app/components/Asset/AssetImg'
import * as React from 'react'

interface IIconSVGProps {
  className?: string
  labelledBy?: string
  text?: React.ReactNode | string
  size?: number
  iconName: string
}

const IconSVG: React.FC<IIconSVGProps> = props => {
  return (
    <AssetImg
      className={props.className}
      labelledBy={props.labelledBy}
      imageName={props.iconName}
      fileType="svg"
      width={props.size ?? 40}
    />
  )
}

export default IconSVG
