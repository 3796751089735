import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreInputReasons } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerContent from './DrawerContent'
import DrawerFooter from './DrawerFooter'
import DrawerHeader from './DrawerHeader'
import { useDrawerInputReasonsInit } from './hooks'
import { canSeeInputReasonsDrawer } from './permissions'

interface IDrawerInputReasonsProps {
  onValidate?: () => void
  storeInputReasons: StoreInputReasons
}

const DrawerInputReasons: React.FC<IDrawerInputReasonsProps> = props => {
  const storePendingInputReasons = useDrawerInputReasonsInit(
    props.storeInputReasons
  )

  const translate = useAppTranslator({
    namespaces: ['Components.InputReasons']
  })

  return (
    <DrawerHorizontalContainer
      labelledBy="inputReasons"
      flags={storePendingInputReasons.storeFlagsFetchReasons.flags}
    >
      <BladeContentLayoutDefault
        layout={{
          name: 'default',
          title: <DrawerTitle>{translate('Reasons')}</DrawerTitle>,
          header: <DrawerHeader storeInputReasons={storePendingInputReasons} />,
          content: (
            <DrawerContent storeInputReasons={storePendingInputReasons} />
          ),
          footer: (
            <DrawerFooter
              onValidate={props.onValidate}
              storeInputReasons={props.storeInputReasons}
              storePendingReasons={storePendingInputReasons}
            />
          )
        }}
        rbacCapabilityCheck={canSeeInputReasonsDrawer}
      />
    </DrawerHorizontalContainer>
  )
}

export default observer(DrawerInputReasons)
