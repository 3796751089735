import { useStores } from '@app/hooks/useStores'
import { SCENE_ZOOM_INITIAL_VALUE } from '@app/stores/Topology/consts'
import * as React from 'react'

/**
 * Init zoom slider value with computed chart minimal zoom value.
 */
export function useSceneInitZoomSliderValue() {
  const { storeTopology } = useStores()

  React.useEffect(() => {
    storeTopology.setZoomSliderValue(SCENE_ZOOM_INITIAL_VALUE)
  }, [])
}
