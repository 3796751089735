import { useAppRouter, useStores } from '@app/hooks'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { AppRouteName } from '@app/routes'
import { NODE_WIDTH } from '@app/stores/AttackPath/consts'
import { consts } from '@app/styles'
import { isDefined } from '@libs/isDefined'
import { AttackPathAdObjectType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  handleAdObjectOnClick,
  handleAdObjectOnMouseOut,
  handleAdObjectOnMouseOver,
  handleUnexpandNodeOnClick,
  handleUnexpandNodeOnMouseOut,
  handleUnexpandNodeOnMouseOver
} from '../handlers'
import SVGTooltip from './SVGTooltip'

export interface IImageNodeProps extends React.SVGProps<SVGImageElement> {
  nodetype: AttackPathAdObjectType
  width: number
  height: number
  adObjectId: number
  nodeId: string
  name: string
  isImportantNode: boolean
  directoryId: number
  childrenCount: number
  isHiddenNode: boolean
  depth: number
  featurePinHighlightedPath: boolean
}

const StyledG = styled.g<{
  isHighlighted: boolean
  isPartOfCurrentPath: boolean
}>`
  circle:not(.count) {
    opacity: ${props =>
      props.isPartOfCurrentPath || props.isHighlighted ? 0.2 : 0};
    transition: transform 200ms ease;
    transform: scale(${props => (props.isHighlighted ? 1.8 : 1)});
    cursor: pointer;
  }

  image:not(.importantNode) {
    opacity: ${props =>
      props.isPartOfCurrentPath || props.isHighlighted ? 1 : 0.3};
    transition: opacity 200ms ease;
  }
`

const CircleWithShadow = styled.circle<{
  isHighlighted: boolean
}>`
  filter: drop-shadow(0 -2px 7px #ccccdd);
  transition: all 200ms ease;
  transform: scale(${props => (props.isHighlighted ? 1.3 : 1)});
`

const TextWithNoFocus = styled.text`
  font-size: 18px;
  user-select: none;
`

const ImageNode: React.FC<IImageNodeProps> = props => {
  const appRouter = useAppRouter()

  const { storeAttackPath } = useStores()

  const { testAttributeProps } = useTestAttribute('contentinfo')

  const { k } = storeAttackPath.chartZoomData

  const filePath = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.MiddlewareAssets_Images,
    parameters: {
      image: `attackpath/object-${props.nodetype}.svg`
    }
  })

  const isHighlighted =
    storeAttackPath.adObjectId === props.adObjectId ||
    storeAttackPath.currentPinnedNodeUid === props.nodeId

  const isHoveringNode = isDefined(storeAttackPath.currentNodeId)

  const isPinnedNode = isDefined(storeAttackPath.currentPinnedNodeUid)

  const isPartOfCurrentPath = () => {
    if (storeAttackPath.isAllGraphHighlighted) {
      return true
    }

    // If I am the target node
    if (props.adObjectId === storeAttackPath.targetNode?.id) {
      if (isPinnedNode) {
        if (
          storeAttackPath.currentPinnedNodeId &&
          storeAttackPath.targetNodeLinkedIds?.has(
            storeAttackPath.currentPinnedNodeId
          )
        ) {
          return true
        }
      }

      if (isHoveringNode) {
        if (props.adObjectId === storeAttackPath.targetNode?.id) {
          if (
            storeAttackPath.adObjectId &&
            storeAttackPath.targetNodeLinkedIds?.has(storeAttackPath.adObjectId)
          ) {
            return true
          }
        }
      }
    }

    if (isPinnedNode && isHoveringNode) {
      return (
        storeAttackPath.currentPath.has(props.nodeId) ||
        storeAttackPath.currentPinnedPath.has(props.nodeId)
      )
    }

    if (isPinnedNode) {
      return storeAttackPath.currentPinnedPath.has(props.nodeId)
    }

    if (isHoveringNode) {
      return storeAttackPath.currentPath.has(props.nodeId)
    }

    return true
  }

  const circleColor = (nodetype: AttackPathAdObjectType): string => {
    switch (nodetype) {
      case AttackPathAdObjectType.Device:
        return consts.colorAttackPathDevice
      case AttackPathAdObjectType.Domain:
        return consts.colorAttackPathDomain
      case AttackPathAdObjectType.DomainController:
        return consts.colorAttackPathDomainController
      case AttackPathAdObjectType.EnterpriseNtAuthStore:
        return consts.colorAttackPathEnas
      case AttackPathAdObjectType.GpoFile:
        return consts.colorAttackPathGPOFile
      case AttackPathAdObjectType.GpoFolder:
        return consts.colorAttackPathGPOFolder
      case AttackPathAdObjectType.Group:
        return consts.colorAttackPathGroup
      case AttackPathAdObjectType.GroupManagedServiceAccount:
        return consts.colorAttackPathGmsa
      case AttackPathAdObjectType.GroupPolicy:
        return consts.colorAttackPathGroupPolicy
      case AttackPathAdObjectType.OrganizationalUnit:
        return consts.colorAttackPathOrganizationalUnit
      case AttackPathAdObjectType.PkiCertificateTemplate:
        return consts.colorAttackPathPkiCertificate
      case AttackPathAdObjectType.ReadOnlyDomainController:
        return consts.colorAttackPathReadOnlyDomainController
      case AttackPathAdObjectType.SpecialIdentity:
        return consts.colorAttackPathSpecialIdentity
      case AttackPathAdObjectType.UnresolvedSecurityPrincipal:
        return consts.colorAttackPathUsp
      case AttackPathAdObjectType.User:
        return consts.colorAttackPathUser
      default:
        return consts.colorAttackPathOther
    }
  }

  const isExpanded =
    storeAttackPath.expandedNodeUids.includes(props.nodeId) &&
    !storeAttackPath.storeFlagsFetchExpandedNodes.flags.isLoading

  const isUnexpandedHighlighted =
    storeAttackPath.hoveredUnexpandNodeUid === props.nodeId

  if (props.isHiddenNode) {
    return <></>
  }

  return (
    <g {...testAttributeProps()(`node-${props.name}`)}>
      <StyledG
        isHighlighted={isHighlighted}
        isPartOfCurrentPath={isPartOfCurrentPath()}
        k={k}
      >
        <SVGTooltip
          width={props.width}
          height={props.height}
          nodeId={props.nodeId}
          adObjectId={props.adObjectId}
          name={props.name}
          isImportantNode={props.isImportantNode}
          directoryId={props.directoryId}
          isHoveringNode={isHoveringNode}
          isPinnedNode={isPinnedNode}
          isHighlighted={isHighlighted}
          isPartOfCurrentPath={isPartOfCurrentPath()}
        />

        <g
          onClick={handleAdObjectOnClick(storeAttackPath)(
            props.nodeId,
            props.depth,
            props.adObjectId,
            props.featurePinHighlightedPath
          )}
          onMouseOver={handleAdObjectOnMouseOver(storeAttackPath)(
            props.adObjectId,
            props.nodeId
          )}
          onMouseOut={handleAdObjectOnMouseOut(storeAttackPath)}
          style={{
            cursor: props.featurePinHighlightedPath ? 'pointer' : 'auto'
          }}
        >
          <circle
            x={-props.width / 2}
            y={-props.height / 2}
            r={props.isImportantNode ? 23 : 15}
            fill={circleColor(props.nodetype)}
          />
          <image
            className={`${props.isImportantNode ? 'importantNode' : ''}`}
            href={filePath}
            x={-props.width / 2}
            y={-props.height / 2}
            height={props.height}
            width={props.width}
          />
        </g>
      </StyledG>

      {isExpanded && (
        <g
          onMouseOver={handleUnexpandNodeOnMouseOver(storeAttackPath)(
            props.nodeId
          )}
          onMouseOut={handleUnexpandNodeOnMouseOut(storeAttackPath)}
          onClick={handleUnexpandNodeOnClick(storeAttackPath)(props.nodeId)}
          transform={`translate(-${NODE_WIDTH * 0.7}, -${NODE_WIDTH * 0.7})`}
        >
          <CircleWithShadow
            isHighlighted={isUnexpandedHighlighted}
            r={9}
            fill={
              isUnexpandedHighlighted ? consts.colorAttackPathArrow : '#fff'
            }
          />
          <TextWithNoFocus
            fill={
              isUnexpandedHighlighted ? '#fff' : consts.colorAttackPathArrow
            }
            x={-4}
            y={6}
          >
            -
          </TextWithNoFocus>
        </g>
      )}
    </g>
  )
}

export default observer(ImageNode)
