import { AppRouteName } from '@app/routes'
import type { StoreEula, StoreLicense } from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'

/**
 * Logout if the user doesn't valid the License.
 */
export const handleLicenseCancelOnClick =
  (
    appRouter: AppRouterClient,
    storeEula: StoreEula,
    storeLicense: StoreLicense
  ) =>
  async () => {
    // We need to clear the new uploaded license as we cancelled it.
    if (storeLicense.licenseUploaded) {
      await storeEula.clearEula()
    }

    appRouter.hardRedirect({
      routeName: AppRouteName.MiddlewareAuth_Logout,
      parameters: {}
    })
  }

/**
 * Redirect the user to the home page if the uploaded license is valid.
 * Do a hard-redirect to fetch everything again and set the profile correctly.
 */
export const handleLicenseOKOnClick = (appRouter: AppRouterClient) => () => {
  // redirect to the root page (/) because profileName is not set yet.
  appRouter.hardRedirect({
    routeName: AppRouteName.Root,
    parameters: {}
  })
}
