import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { LabelAlt, LabelSliced } from '@app/components-legacy/Label'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { Tooltip } from 'antd'
import * as React from 'react'
import BulletTriangle from '../Bullet/BulletTriangle'
import { ContainerFlex } from '../Container'

export interface ILabelDirectoryProps {
  className?: string
  style?: React.CSSProperties
  color: string
  label: string
  maxLength?: number
  maxWidth?: number
  tooltipLabel?: React.ReactNode | string
  labelledBy?: string
}

const LabelDirectory: React.FC<ILabelDirectoryProps> = props => {
  const theme = useDSTheme()

  const renderLabel = () => {
    const maxLength = props.maxLength ?? 25

    const label = isThemeLegacy(theme) ? (
      <LabelSliced
        maxLength={maxLength}
        maxWidth={props.maxWidth}
        value={props.label}
      />
    ) : (
      <Label variant={LabelVariant.p}>
        <LabelSliced
          maxLength={maxLength}
          maxWidth={props.maxWidth}
          value={props.label}
        />
      </Label>
    )

    if (props.tooltipLabel) {
      return (
        <Tooltip title={props.tooltipLabel}>
          <div>{label}</div>
        </Tooltip>
      )
    }

    return label
  }

  const label = <LabelAlt>{renderLabel()}</LabelAlt>

  return (
    <ContainerFlex
      name="LabelDirectory"
      labelledBy="directory"
      className={props.className}
      style={props.style}
      items={[<BulletTriangle color={props.color} />, label]}
      spaced
      spaceWidth="verySmall"
    />
  )
}

export default LabelDirectory
