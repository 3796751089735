import {
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { ButtonSize } from '@app/components-legacy/Form/Wrappers/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreInputExpression from '@app/stores/helpers/StoreInputExpression'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onDrawerWizardReset, onDrawerWizardSubmit } from './handlers'

interface IFormButtonsProps {
  onValidate?: () => void
  storeInputExpression: StoreInputExpression
}

const FormButtons: React.FC<IFormButtonsProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.Wizard']
  })

  const { storeWizard } = props.storeInputExpression

  return (
    <ContainerFooter>
      <DrawerCancelButton
        storeDrawer={props.storeInputExpression.storeDrawer}
      />

      <ContainerFlex
        name="FormValidationButtons"
        alignItems="center"
        justifyContent="flex-end"
        items={[
          <FormWrapperButton
            labelledBy="reset"
            size={ButtonSize.large}
            buttonProps={{
              htmlType: 'button',
              disabled: storeWizard.isSubmitting,
              onClick: onDrawerWizardReset(storeWizard)
            }}
          >
            {translate('Reset')}
          </FormWrapperButton>,

          <FormWrapperButton
            labelledBy="validate"
            size={ButtonSize.large}
            buttonProps={{
              type: 'primary',
              htmlType: 'button',
              disabled: storeWizard.isSubmitting,
              loading: storeWizard.isSubmitting,
              onClick: onDrawerWizardSubmit(
                props.storeInputExpression,
                props.onValidate
              )
            }}
          >
            {translate('Validate')}
          </FormWrapperButton>
        ]}
        spaced
        spaceWidth="small"
      />
    </ContainerFooter>
  )
}

export default observer(FormButtons)
