import type {
  EntityTopologyDirectory,
  EntityTopologyInfrastructure
} from '@app/entities'
import { consts } from '@app/styles'
import { ensureArray } from '@libs/ensureArray'
import { renderSvgLabelFilter } from '@libs/svg/helpers'
import * as React from 'react'
import Directory from './Directory'
import Infrastructure from './Infrastructure'
import type SceneObject from './SceneObject'

interface IInfrastructuresProps {
  infrastructureSceneObjects: Array<SceneObject<EntityTopologyInfrastructure>>
  directorySceneObjects: Map<
    SceneObject<EntityTopologyInfrastructure>,
    Array<SceneObject<EntityTopologyDirectory>>
  >
}

const Infrastructures: React.FC<IInfrastructuresProps> = props => {
  return (
    <g data-name="Infrastructures">
      <defs>
        {renderSvgLabelFilter('highlight', consts.colorBlue010)}
        {renderSvgLabelFilter('highlight-unknown', consts.colorGrey010)}
      </defs>

      {props.infrastructureSceneObjects.map(sceneObject => {
        const directorySceneObjects =
          props.directorySceneObjects.get(sceneObject)

        return (
          <Infrastructure
            key={`inf-key-${sceneObject.object.uid}`}
            infrastructureSceneObject={sceneObject}
          >
            {ensureArray(directorySceneObjects).map(
              (directorySceneObject, index) => {
                const key = `dir-key-${
                  directorySceneObject.object.uid || 'unknown-' + index
                }`

                return (
                  <Directory
                    key={key}
                    directorySceneObject={directorySceneObject}
                  />
                )
              }
            )}
          </Infrastructure>
        )
      })}
    </g>
  )
}

export default Infrastructures
