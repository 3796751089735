import type { Maybe } from '@@types/helpers'
import { Features } from '@alsid/common'
import { canDisplayAzureAdBecauseEnabled } from '@app/pages/permissions'
import {
  rbacCapabilityCheckAllOf,
  rbacCapabilityCheckAnyOf
} from '@libs/rbac/functions'
import {
  accessByFeatureFlag,
  accessByFeatureFlagDisabled,
  createTenant,
  deleteTenant,
  editTenant,
  readTenants
} from '@libs/rbac/permissions'
import type { RbacEntityId } from '@libs/rbac/types'
import {
  canEditAzureAdSupport,
  canReadAzureAdSupport
} from '../../ConfigurationPage/ConfigurationTenableCloudPage/permissions'
import { canAccessToSystem } from '../../permissions'

export const canReadTenants = rbacCapabilityCheckAnyOf(
  rbacCapabilityCheckAllOf(
    accessByFeatureFlagDisabled(Features.RBAC_ENTRAID_TENANT)(),
    canReadAzureAdSupport
  ),
  rbacCapabilityCheckAllOf(
    accessByFeatureFlag(Features.RBAC_ENTRAID_TENANT)(),
    readTenants()
  )
)

/** Used in pages that needs to list Tenants out of the context of management (IOE > Tenants drawer for example) */
export const canAccessToTenantsInReadOnly = rbacCapabilityCheckAllOf(
  canDisplayAzureAdBecauseEnabled,
  canReadTenants
)

export const canAccessToTenantsManagement = rbacCapabilityCheckAllOf(
  canDisplayAzureAdBecauseEnabled,
  canAccessToSystem,
  canReadTenants
)

export const canDeleteTenants = rbacCapabilityCheckAllOf(
  canAccessToTenantsManagement,
  rbacCapabilityCheckAnyOf(
    rbacCapabilityCheckAllOf(
      accessByFeatureFlagDisabled(Features.RBAC_ENTRAID_TENANT)(),
      canEditAzureAdSupport
    ),
    rbacCapabilityCheckAllOf(
      accessByFeatureFlag(Features.RBAC_ENTRAID_TENANT)(),
      deleteTenant()
    )
  )
)

export const canCreateTenants = rbacCapabilityCheckAllOf(
  canAccessToTenantsManagement,
  rbacCapabilityCheckAnyOf(
    rbacCapabilityCheckAllOf(
      accessByFeatureFlagDisabled(Features.RBAC_ENTRAID_TENANT)(),
      canEditAzureAdSupport
    ),
    rbacCapabilityCheckAllOf(
      accessByFeatureFlag(Features.RBAC_ENTRAID_TENANT)(),
      createTenant()
    )
  )
)

export const canEditTenant = (tenantId: Maybe<RbacEntityId>) =>
  rbacCapabilityCheckAllOf(
    canAccessToTenantsManagement,
    rbacCapabilityCheckAnyOf(
      rbacCapabilityCheckAllOf(
        accessByFeatureFlagDisabled(Features.RBAC_ENTRAID_TENANT)(),
        canEditAzureAdSupport
      ),
      rbacCapabilityCheckAllOf(
        accessByFeatureFlag(Features.RBAC_ENTRAID_TENANT)(),
        editTenant(tenantId)
      )
    )
  )

export const canEditTenants = canEditTenant(null)
