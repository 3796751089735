import { FormWrapperButton } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks'
import type StoreAttacks from '@app/stores/IoA/StoreAttacks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IIoAAttacksLoadPreviousButtonProps {
  className?: string
  storeAttacks: StoreAttacks
}

const IoAAttacksLoadPreviousButton: React.FC<
  IIoAAttacksLoadPreviousButtonProps
> = props => {
  const translate = useAppTranslator({ namespaces: ['IoA.Attacks'] })

  if (!props.storeAttacks.hasOlderResults) {
    return null
  }

  return (
    <FormWrapperButton
      labelledBy="loadPreviousAttacks"
      buttonProps={{
        className: props.className,
        onClick: () => props.storeAttacks.fetchOlderAttacks(),
        loading: props.storeAttacks.storeFlagsFetchOlderAttacks.isLoading
      }}
    >
      {translate('Load previous incidents')}
    </FormWrapperButton>
  )
}

export default observer(IoAAttacksLoadPreviousButton)
