import IconDown from '@app/components/Icon/IconDown'
import IconUp from '@app/components/Icon/IconUp'
import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form/Wrappers'
import ButtonSimple from '@app/components-legacy/Form/Wrappers/ButtonSimple'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { IconTriangle } from '@app/components-legacy/Icon'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { SpinnerInlineSize } from '@app/components-legacy/Spinner/SpinnerInline'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled, { css } from 'styled-components'

const StyledContainerFlex = styled(ContainerFlex)`
  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      min-height: 24px;
    `}
`

export interface ITableControlProps {
  className?: string
  style?: React.CSSProperties
  labelledBy: string
  direction?: 'up' | 'down'
  label: string
  isLoading: boolean
  onClick: () => void
}

const TableControl: React.FC<ITableControlProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  function getItems() {
    if (props.isLoading) {
      return [<SpinnerInline size={SpinnerInlineSize.small} />]
    }

    if (themeIsLegacy) {
      return [
        <ContainerFlex
          name="TableControlIcon"
          justifyContent="center"
          items={[
            <IconTriangle
              degres={props.direction === 'up' ? 0 : 180}
              color={consts.colorGreen030}
            />,

            <a onClick={props.onClick}>
              <ButtonSimple labelledBy={props.labelledBy}>
                {props.label}
              </ButtonSimple>
            </a>
          ]}
          spaced
          spaceWidth="verySmall"
        />
      ]
    }

    return [
      <FormWrapperButton
        labelledBy={props.labelledBy}
        variant={ButtonVariant.secondary}
        size={ButtonSize.verySmall}
        icon={props.direction === 'up' ? IconUp : IconDown}
        iconAfterLabel
        buttonProps={{
          onClick: props.onClick
        }}
      >
        {props.label}
      </FormWrapperButton>
    ]
  }

  return (
    <StyledContainerFlex
      name="TableControl"
      className={props.className}
      style={props.style}
      alignItems="center"
      justifyContent="center"
      items={getItems()}
    />
  )
}

export default TableControl
