import type { StoreRoot } from '@app/stores'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { action, computed, makeObservable, observable } from 'mobx'
import type { CreateGroupFn } from './types'

/**
 * Handle groups of T.
 * Useful for multi-configuration fieldsets in forms.
 */
export default class StoreFormGroups<G> extends StoreBase {
  private $groups = observable.map<number, G>()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /* Actions */

  @action
  reset(): this {
    this.$groups.clear()
    return this
  }

  @action
  addGroup(createGroupFn: CreateGroupFn<G>): this {
    const nextIndex =
      this.$groups.size === 0
        ? 0
        : Math.max(...Array.from(this.$groups.keys())) + 1

    this.$groups.set(nextIndex, createGroupFn(nextIndex))
    return this
  }

  @action
  removeGroup(index: number): this {
    this.$groups.delete(index)
    return this
  }

  /* Computed */

  @computed
  get groups(): Map<number, G> {
    const groups = new Map<number, G>()

    Array.from(this.$groups.keys()).forEach(key => {
      const group = this.$groups.get(key)
      if (group) {
        groups.set(key, group)
      }
    })

    return groups
  }

  @computed
  get groupsAsArray(): G[] {
    return Array.from(this.$groups.values())
  }
}
