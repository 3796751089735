import { AppRouteName } from '@app/routes'
import type { StoreDashboards } from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'
import type { DeleteDashboardMutationArgs } from '@server/graphql/typeDefs/types'
import { first } from 'lodash'

/**
 * Open the drawer to delete a dashboard.
 */
export const onDeleteDashboardButtonClick =
  (appRouter: AppRouterClient, storeDashboards: StoreDashboards) =>
  (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()

    const params = appRouter.getRouteParameters({
      routeName: AppRouteName.Dashboard_Grid,
      parameters: {
        instanceName: String(),
        dashboardId: Number()
      }
    })

    if (!params) {
      return
    }

    storeDashboards.storeDashboardsManagement.storeDrawerDeleteDashboard
      .setData(params)
      .openDrawer()
  }

/**
 * Delete a dashboard,
 * Then redirect to the first dashboard or on the CTA component.
 */
export const onDeleteDashboardValidate =
  (appRouter: AppRouterClient, storeDashboards: StoreDashboards) =>
  (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault()
    }

    const { storeDashboardsManagement } = storeDashboards
    const { storeDrawerDeleteDashboard } = storeDashboardsManagement

    const parameters = appRouter.getRouteParameters({
      routeName: AppRouteName.Dashboard_Grid,
      parameters: {
        instanceName: String(),
        dashboardId: Number()
      }
    })

    if (!parameters) {
      return
    }

    const { instanceName, dashboardId } = parameters

    const args: DeleteDashboardMutationArgs = {
      dashboard: {
        instanceName,
        dashboardId
      }
    }

    storeDashboardsManagement
      .deleteDashboard(args)
      .then(() => storeDrawerDeleteDashboard.closeDrawer())
      .then(() => {
        let url: string

        const firstDashboard = first(storeDashboards.sortedDashboards)

        if (!firstDashboard) {
          url = appRouter.makeRouteInfosPathname({
            routeName: AppRouteName.Dashboard,
            parameters: {}
          })
        } else {
          const dashboardKey = firstDashboard.getDashboardKey()

          if (dashboardKey) {
            storeDashboards.selectDashboard(dashboardKey)
          }

          url = appRouter.makeRouteInfosPathname({
            routeName: AppRouteName.Dashboard_Grid,
            parameters: {
              instanceName: firstDashboard.getPropertyAsString('instanceName'),
              dashboardId: firstDashboard.getPropertyAsNumber('id')
            }
          })
        }

        appRouter.history.push(url)
      })
      .then(() => {
        return storeDashboards.fetchDashboards()
      })
      .catch(() => {
        /* Already handled in the store */
      })
  }
