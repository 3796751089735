import { ButtonShowPicker } from '@app/components-legacy/Button'
import { Drawer, DrawerContext } from '@app/components-legacy/Drawer'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import type { StoreInputTenants } from '@app/stores'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import type { BaseButtonProps } from 'antd/lib/button/button'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerInputTenants from './DrawerInputTenants'
import TenantsSelectionCounter from './DrawerInputTenants/TenantsSelectionCounter'
import { handleInputTenantsOnClick } from './handlers'

interface IInputTenantsButtonProps {
  size?: BaseButtonProps['size']
  onSubmit?: () => void
  storeInputTenants: StoreInputTenants
}

const InputTenantsButton: React.FC<IInputTenantsButtonProps> = props => {
  return (
    <>
      <ButtonShowPicker
        labelledBy="showPickerTenants"
        buttonsProps={{
          size: props.size,
          onClick: handleInputTenantsOnClick(props.storeInputTenants)
        }}
        storeDrawer={props.storeInputTenants.storeDrawer}
      >
        <TenantsSelectionCounter storeInputTenants={props.storeInputTenants} />
      </ButtonShowPicker>

      <Portal name={PlaceHolderName.selectorDrawer}>
        <DSThemeProvider themeName={DSThemeName.legacy}>
          <DrawerContext.Provider
            value={{ storeDrawer: props.storeInputTenants.storeDrawer }}
          >
            <Drawer>
              <DrawerInputTenants
                onSubmit={props.onSubmit}
                storeInputTenants={props.storeInputTenants}
              />
            </Drawer>
          </DrawerContext.Provider>
        </DSThemeProvider>
      </Portal>
    </>
  )
}

export default observer(InputTenantsButton)
