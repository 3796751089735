import type { PropertiesNullable } from '@@types/helpers'
import EntityAdObjectAsAttributes from '@app/entities/EntityAdObject/EntityAdObjectAsAttribute'
import { isGrantedEntity } from '@libs/rbac/functions'
import type {
  AdObject,
  Deviance,
  Directory,
  Event,
  Maybe,
  MaybeGrantedDeviances,
  MaybeGrantedReasons
} from '@server/graphql/typeDefs/types'
import { createEntities, createEntity } from '.'
import EntityBase from './EntityBase'
import EntityDeviance from './EntityDeviance'
import EntityDirectory from './EntityDirectory'

export type EventId = string

export default class EntityEvent
  extends EntityBase
  implements PropertiesNullable<Event>
{
  id: Maybe<EventId> = null
  date: Maybe<string> = null
  adObjectId: Maybe<number> = null
  directoryId: Maybe<number> = null
  type: Maybe<string> = null

  adObject: Maybe<AdObject> = null
  directory: Maybe<Directory> = null
  rbacDeviances: Maybe<MaybeGrantedDeviances> = null
  rbacReasons: Maybe<MaybeGrantedReasons> = null

  constructor(data: Partial<Event>) {
    super()
    Object.assign(this, data)
  }

  getAdObject(): Maybe<EntityAdObjectAsAttributes> {
    if (!this.adObject) {
      return null
    }

    return createEntity<AdObject, EntityAdObjectAsAttributes>(
      EntityAdObjectAsAttributes,
      this.adObject
    )
  }

  getDirectory(): Maybe<EntityDirectory> {
    if (!this.directory) {
      return null
    }

    return createEntity<Directory, EntityDirectory>(
      EntityDirectory,
      this.directory
    )
  }

  /**
   * Return granted deviances related to this event.
   */
  getDeviances(): EntityDeviance[] {
    if (!this.rbacDeviances) {
      return []
    }

    if (!isGrantedEntity(this.rbacDeviances)) {
      return []
    }

    return createEntities<Deviance, EntityDeviance>(
      EntityDeviance,
      this.rbacDeviances.node
    )
  }
}
