import { ContainerContent } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditCredentials } from '../permissions'
import ChangePasswordForm from './ChangePasswordForm'
import { onPreferencesSecurityLoad } from './handlers'

interface IPreferencesSecurityProps {}

const PreferencesSecurity: React.FC<IPreferencesSecurityProps> = () => {
  const { storePreferences, storeAbout } = useStores()

  return (
    <ContainerContent
      onLoad={onPreferencesSecurityLoad(storePreferences)}
      rbacCapabilityCheck={canEditCredentials(storeAbout)}
    >
      <ChangePasswordForm />
    </ContainerContent>
  )
}

export default observer(PreferencesSecurity)
