import type { IDataRowRelay } from '@app/entities/EntityRelay'
import type { StoreManagementRelays } from '@app/stores'

/**
 * Delete the relay, close the drawer and reload relays.
 */
export const handleRelayDeleteOnSubmit =
  (storeManagementRelays: StoreManagementRelays) =>
  (dataRow: IDataRowRelay) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    storeManagementRelays
      .deleteRelay(dataRow.id, dataRow.name)
      .then(() => {
        storeManagementRelays.storeDeleteDrawer.closeDrawer()

        // reload relays
        return storeManagementRelays.fetchRelays()
      })
      .catch(() => {
        // already catched in the store
      })
  }
