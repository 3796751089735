import type { PropertiesNullable } from '@@types/helpers'
import type { Eula, Language, Maybe } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityEula
  extends EntityBase
  implements PropertiesNullable<Eula>
{
  language: Maybe<Language> = null
  version: Maybe<number> = null
  license: Maybe<string> = null

  constructor(data: Partial<Eula>) {
    super()
    Object.assign(this, data)
  }

  getLicenseContent() {
    return this.getPropertyAsString('license').replace(/<br>/g, ' ')
  }
}
