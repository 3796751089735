import { Features } from '@alsid/common'
import { emptyServerProperties } from '@app/_init/createApp/initializeTul/emptyResponses'
import type { StoreRoot } from '@app/stores'
import { isDefined } from '@libs/isDefined'
import type { ITulConfig } from 'tenable-universal-layout'
import { initTul, TenableProductLogo } from 'tenable-universal-layout'

export async function initializeTul(storeRoot: StoreRoot) {
  const { storePendo } = storeRoot.stores

  const featureWorkspaceAppswitcherInTad =
    storeRoot.stores.storeRbac.isUserGrantedAccordingFeatureFlag(
      Features.WORKSPACE_APPSWITCHER_IN_TAD
    )

  /**
   * Retrieve server properties from Kapteyn server middleware
   * (which queries Eridanis directly).
   */
  const serverProperties =
    featureWorkspaceAppswitcherInTad &&
    storeRoot.stores.storeAbout.productAssociation?.isAssociatedToTenableOne()
      ? await storeRoot.stores.storeAuthentication.fetchServerProperties()
      : null

  const cloudStatistics =
    await storeRoot.stores.storeAuthentication.fetchCloudStatistics()

  const pendoParameters = cloudStatistics?.getPendoParameters()

  const tulConfig: ITulConfig = {
    i18n: {
      language: 'en' // TODO: update this part to take care of the user session language
    },
    services: {
      session: {
        enabled: true,

        sessionDataGetter: () => {
          return Promise.resolve(
            storeRoot.stores.storeAuthentication.tulSession
          )
        },

        parameters: {
          // don't use Irontoken, only used by apps living on the Tenable workspace,
          // which is not the case of T.AD
          disableIronToken: true
        },
        renderComponents: {
          accountPanel: () =>
            `<div slot="user-action"><slot name="custom-user-action"></slot></div>`
        }
      },

      serverProperties: {
        enabled: featureWorkspaceAppswitcherInTad,

        serverPropertiesDataGetter: () => {
          return Promise.resolve(serverProperties || emptyServerProperties)
        },

        renderComponents: {
          // if no serverProperties, don't render appsPanel
          appsPanel: isDefined(serverProperties)
        },

        parameters: {
          workspaceDomain: storeRoot.environment.config.tul?.workspacedomain
        }
      },

      settings: {
        enabled: true,
        renderComponents: {
          settingsPanel: () =>
            `<div slot="settings-action"><slot name="custom-settings-action"></slot></div>`
        }
      },

      alerts: {
        renderComponents: {
          alertsPanel: () =>
            `<div slot="notifications-action"><slot name="custom-notifications-action"></slot></div>`
        }
      },

      telemetry: {
        enabled: false
      },

      chats: {
        enabled: false
      },

      pendo: {
        enabled: storePendo.isPendoEnabled,

        pendoDataGetter: () => {
          const tulPendoData = pendoParameters?.tulPendoData

          if (!tulPendoData) {
            throw new Error(
              'Cant initialize Pendo, check CloudStatistics parameters.'
            )
          }

          return Promise.resolve(tulPendoData)
        },

        parameters: {
          apikey:
            storeRoot.environment.config.tul?.services?.pendo?.parameters
              ?.apikey ?? ''
        },

        metadata: {
          visitor: [
            {
              name: 'adUiLanguage',
              value: storeRoot.appTranslator.language
            },
            {
              name: 'username',
              value:
                storeRoot.stores.storeAuthentication.cloudStatistics
                  ?.userUuid ?? ''
            },
            {
              name: 'email',
              value: storeRoot.stores.storeAuthentication.whoAmI?.email ?? ''
            }
          ],
          account: [
            {
              name: 'adSite',
              value:
                storeRoot.stores.storeAuthentication.cloudStatistics?.siteId ??
                ''
            },
            {
              name: 'adEnvironmentType',
              value:
                storeRoot.stores.storeAuthentication.cloudStatistics
                  ?.environmentType ?? ''
            },
            {
              name: 'adCustomerId',
              value:
                storeRoot.stores.storeAuthentication.cloudStatistics
                  ?.customerName ?? ''
            }
          ]
        }
      },

      streamio: {
        enabled: false
      },

      appSpecific: {
        enabled: true,
        renderComponents: {
          appSpecificPanel: () => `
            <div slot="app-specific-actions">
              <slot name="custom-app-specific-actions"></slot>
            </div>
            ${
              !storePendo.isPendoEnabled
                ? `
                  <div slot="help-action">
                    <slot name="custom-help-action" /></slot>
                  </div>
                `
                : ''
            }
          `
        }
      }
    },

    app: {
      id: 'ad',
      production: storeRoot.environment.config.production,

      // useDefaultCSS: false,

      logo: {
        name: TenableProductLogo['IE-FullColor-RGB-logo'],
        directory: '/w/assets/images/logos'
      },

      handlers: {
        onTulNavigate: ({ url }) => {
          // Handle redirection of the AppsSwitcher
          document.location.href = url
        },

        /**
         * Set the expanded flag when clicking on a TUL's hamburger icon.
         */
        onTulHamburgerClick: ({ tulEvent }) => {
          const { storeMenuMainNavigation } = storeRoot.stores.storeLayout
          storeMenuMainNavigation.setExpanded(tulEvent.detail.navigationOpened)
        }
      }
    }
  }

  return initTul(null, tulConfig)
}
