import {
  IconActivity,
  IconFootPrint,
  IconForestManagement,
  IconLicense,
  IconSliders,
  IconUser,
  IconUserGroup
} from '@app/components/Icon'
import IconDomainManagement from '@app/components/Icon/IconDomainManagement'
import { Popover } from '@app/components/Popover'
import type { IPopoverRef } from '@app/components/Popover/Popover'
import { useAppTranslator, useStores } from '@app/hooks'
import type { IMainMenuEntry } from '@app/pages/MainMenu/types'
import { MainMenuKey } from '@app/pages/MainMenu/types'
import { canAccessToUserActivityLogs } from '@app/pages/Management/AccountsPage/ActivityLogs/permissions'
import { canAccessToProfiles } from '@app/pages/Management/AccountsPage/Profiles/ProfilesPage/permissions'
import { canAccessToRoles } from '@app/pages/Management/AccountsPage/Roles/permissions'
import { canAccessToAccountUsers } from '@app/pages/Management/AccountsPage/Users/UsersPage/permissions'
import { canAccessToAbout } from '@app/pages/Management/SystemPage/AboutPage/permissions'
import { canAccessToSMTPConfiguration } from '@app/pages/Management/SystemPage/ConfigurationPage/permissions'
import { canAccessToDirectories } from '@app/pages/Management/SystemPage/Directories/permissions'
import { canAccessToInfrastructures } from '@app/pages/Management/SystemPage/Infrastructures/permissions'
import { AppRouteName } from '@app/routes'
import { MenuEntryType } from '@app/stores/helpers/StoreMenu/types'
import { filterFalsies } from '@libs/filterFalsies'
import * as React from 'react'
import WidgetSettingsPopover from './WidgetSettingsPopover'
import WidgetSettingsPopoverContainer from './WidgetSettingsPopoverContainer'

interface IWidgetSettingsProps {
  className?: string
}

const WidgetSettings: React.FC<IWidgetSettingsProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Layout.SettingsMenu'] })

  const [popoverVisible, setPopoverVisible] = React.useState<boolean>(false)

  const { storeRbac, storeAuthentication } = useStores()

  const settingsSubNavigationEntries: IMainMenuEntry[] =
    filterFalsies<IMainMenuEntry>([
      storeRbac.isUserGrantedTo(canAccessToSMTPConfiguration) && {
        key: MainMenuKey.systemConfiguration,
        type: MenuEntryType.link,
        label: translate('System Configuration'),
        labelledBy: 'systemConfiguration',
        routeDefinition: {
          routeName: AppRouteName.Management_System_Configuration_SMTP,
          parameters: {}
        },
        icon: IconSliders
      },
      storeRbac.isUserGrantedTo(canAccessToInfrastructures) && {
        key: MainMenuKey.forestManagement,
        type: MenuEntryType.link,
        label: translate('Forest management'),
        labelledBy: 'forestManagement',
        routeDefinition: {
          routeName: AppRouteName.Management_System_Infrastructures,
          parameters: {}
        },
        icon: IconForestManagement
      },
      storeRbac.isUserGrantedTo(canAccessToDirectories) && {
        key: MainMenuKey.domainManagement,
        type: MenuEntryType.link,
        label: translate('Domain management'),
        labelledBy: 'domainManagement',
        routeDefinition: {
          routeName: AppRouteName.Management_System_Directories,
          parameters: {}
        },
        icon: IconDomainManagement
      },
      storeRbac.isUserGrantedTo(canAccessToAbout) && {
        key: MainMenuKey.licenseInfo,
        type: MenuEntryType.link,
        label: translate('License Info'),
        labelledBy: 'licenseInfo',
        routeDefinition: {
          routeName: AppRouteName.Management_System_About,
          parameters: {}
        },
        icon: IconLicense
      },
      storeRbac.isUserGrantedTo(canAccessToAccountUsers(storeRbac)) && {
        key: MainMenuKey.userManagement,
        type: MenuEntryType.link,
        label: translate('User Management'),
        labelledBy: 'userManagement',
        routeDefinition: {
          routeName: AppRouteName.Management_Accounts_Users,
          parameters: {}
        },
        icon: IconUserGroup
      },
      storeRbac.isUserGrantedTo(canAccessToRoles) && {
        key: MainMenuKey.roleManagement,
        type: MenuEntryType.link,
        label: translate('Role Management'),
        labelledBy: 'roleManagement',
        routeDefinition: {
          routeName: AppRouteName.Management_Accounts_Roles,
          parameters: {}
        },
        icon: IconUser
      },
      storeRbac.isUserGrantedTo(canAccessToProfiles) && {
        key: MainMenuKey.profilesConfiguration,
        type: MenuEntryType.link,
        label: translate('Profiles Configuration'),
        labelledBy: 'profilesConfiguration',
        routeDefinition: {
          routeName: AppRouteName.Management_Accounts_Profiles,
          parameters: {}
        },
        icon: IconFootPrint
      },
      storeRbac.isUserGrantedTo(
        canAccessToUserActivityLogs(storeAuthentication)
      ) && {
        key: MainMenuKey.activityLogs,
        type: MenuEntryType.link,
        label: translate('Activity Logs'),
        labelledBy: 'ActivityLogs',
        routeDefinition: {
          routeName: AppRouteName.ActivityLogs,
          parameters: {}
        },
        icon: IconActivity
      }
    ])

  const popoverRef = React.useRef<IPopoverRef>(null)
  const triggerRef = React.useRef<HTMLDivElement>(null)

  function hidePopover(): void {
    popoverRef.current?.hidePopover()
  }

  if (!settingsSubNavigationEntries.length) {
    return null
  }

  return (
    <Popover
      ref={popoverRef}
      triggerRef={triggerRef}
      popoverProps={{
        content: (
          <WidgetSettingsPopover
            hidePopover={hidePopover}
            settingsSubNavigationEntries={settingsSubNavigationEntries}
          />
        ),
        placement: 'bottomRight',
        trigger: 'click',
        onOpenChange: setPopoverVisible
      }}
      tenableTheme
    >
      <WidgetSettingsPopoverContainer
        ref={triggerRef}
        activated={popoverVisible}
      />
    </Popover>
  )
}

export default WidgetSettings
