import { ContainerFlex, ContainerRbac } from '@app/components-legacy/Container'
import type EntityAttack from '@app/entities/EntityAttack'
import { useStores } from '@app/hooks'
import { canAccessToAttackDetails } from '@app/pages/IoA/IoAAttacksPage/permissions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import IoAAttacksTableBodyDataRowAttackDirectory from './IoAAttacksTableBodyDataRowAttackDirectory'
import IoAAttacksTableBodyDataRowAttackName from './IoAAttacksTableBodyDataRowAttackName'
import IoAAttacksTableBodyDataRowAttackVector from './IoAAttacksTableBodyDataRowAttackVector'
import IoAAttacksTableBodyDataRowDestination from './IoAAttacksTableBodyDataRowDestination'
import IoAAttacksTableBodyDataRowExpandButton from './IoAAttacksTableBodyDataRowExpandButton'
import IoAAttacksTableBodyDataRowSource from './IoAAttacksTableBodyDataRowSource'

interface IIoAAttacksTableBodyDataRowProps {
  className?: string
  attackEntity: EntityAttack
}

const IoAAttacksTableRow: React.FC<
  IIoAAttacksTableBodyDataRowProps
> = props => {
  const { storeIoA } = useStores()

  return (
    <ContainerFlex
      name="IoAAttacksTableBodyRowContainer"
      className="attackColumns"
      alignItems="center"
      items={[
        <div className="attackSource">
          <IoAAttacksTableBodyDataRowSource
            attackSourceEntity={props.attackEntity.getSource()}
          />
        </div>,

        <div className="attackVector">
          <IoAAttacksTableBodyDataRowAttackVector
            attackTypeEntity={props.attackEntity.getAttackType(
              storeIoA.attackTypes
            )}
            attackVectorEntity={props.attackEntity.getVector()}
          />
        </div>,

        <div className="attackDestination">
          <IoAAttacksTableBodyDataRowDestination
            attackDestinationEntity={props.attackEntity.getDestination()}
          />
        </div>,

        <div className="attackName">
          <IoAAttacksTableBodyDataRowAttackName
            attackTypeEntity={props.attackEntity.getAttackType(
              storeIoA.attackTypes
            )}
          />
        </div>,

        <div className="attackDirectory">
          <IoAAttacksTableBodyDataRowAttackDirectory
            attackEntity={props.attackEntity}
          />
        </div>,

        <div className="attackExpandButton">
          <ContainerRbac rbacCapabilityCheck={canAccessToAttackDetails}>
            <IoAAttacksTableBodyDataRowExpandButton
              attackEntity={props.attackEntity}
            />
          </ContainerRbac>
        </div>
      ]}
    />
  )
}

export default observer(IoAAttacksTableRow)
