import { FormWrapperSelect } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import {
  CRITICITY_LEVEL_CRITICAL,
  CRITICITY_LEVEL_HIGH,
  CRITICITY_LEVEL_LOW,
  CRITICITY_LEVEL_MEDIUM,
  getCriticityString,
  getCriticityValue
} from '@libs/criticity'
import type { SelectProps, SelectValue } from 'antd/lib/select'
import * as React from 'react'

interface IInputCriticityProps {
  criticityValue: number
  id?: string
  onChange: (value: SelectValue) => void
  selectProps?: SelectProps<any>
}

/**
 * Render a Select to chose a criticity level.
 */
const InputCriticity: React.FC<IInputCriticityProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.LabelChecker.ComplexityLevel']
  })

  // from a custom value, retrieve a valid "tick"
  const criticity = getCriticityString(props.criticityValue)
  const criticityTick = getCriticityValue(criticity)

  return (
    <FormWrapperSelect
      labelledBy="criticity"
      selectProps={{
        id: props.id,
        value: criticityTick,
        onChange: props.onChange
      }}
      selectOptions={[
        {
          label: translate('critical'),
          value: CRITICITY_LEVEL_CRITICAL,
          labelledBy: 'critical'
        },
        {
          label: translate('high'),
          value: CRITICITY_LEVEL_HIGH,
          labelledBy: 'high'
        },
        {
          label: translate('medium'),
          value: CRITICITY_LEVEL_MEDIUM,
          labelledBy: 'medium'
        },
        {
          label: translate('low'),
          value: CRITICITY_LEVEL_LOW,
          labelledBy: 'low'
        }
      ]}
    />
  )
}

export default InputCriticity
