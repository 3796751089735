import { Features } from '@alsid/common'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  accessByFeatureFlag,
  grant,
  readWeaknessOverview
} from '@libs/rbac/permissions'
import type { RbacCapabilityCheck } from '@libs/rbac/types'

/**
 * Allow access to GlobalInventory pages.
 */
export const canAccessToWeaknesses: RbacCapabilityCheck = (
  rbacPermissions,
  meta
) => {
  const isDebugEnabledForGlobalInventory =
    meta.storeRbac.storeRoot.environment.config.globalinventory?.debug
      ?.enabled === true

  // bypass all Rbac conditions if debug
  if (isDebugEnabledForGlobalInventory) {
    return grant()(rbacPermissions, meta)
  }

  return rbacCapabilityCheckAllOf(
    readWeaknessOverview(),
    accessByFeatureFlag(Features.GLOBAL_INVENTORY_IDENTITIES)()
  )(rbacPermissions, meta)
}
