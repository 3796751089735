import type { Mutation } from '../typeDefs/types'

export type MutationUpdatePreferences = Pick<Mutation, 'updatePreferences'>

export const mutationUpdatePreferences = `
  mutation mutationUpdatePreferences($preferences: InputUpdatePreferences!) {
    updatePreferences(
      preferences: $preferences
    ) {
      language
      preferredProfileId
    }
  }
`

export type MutationChangeUserPassword = Pick<Mutation, 'changeUserPassword'>

export const mutationChangeUserPassword = `
  mutation mutationChangeUserPassword($password: InputChangeUserPassword!) {
    changeUserPassword(
      password: $password
    ) {
      message
      error
    }
  }
`
