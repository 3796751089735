import { AssetImg } from '@app/components/Asset/AssetImg'
import { StepPoints } from '@app/components/Bullet'
import { ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useAppTranslator } from '@app/hooks'
import AuthPlaceHolders from '@app/pages/Auth/AuthPlaceHolders'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import AuthContainerPadded from '../Common/AuthContainerPadded'
import FirstLoginPasswordForm from './FirstLoginPasswordForm'

const StyledAuthPlaceHolders = styled(AuthPlaceHolders)`
  width: 456px;
`

const StyledLabel = styled(Label)`
  text-align: center;
`

const StyledContainerContent = styled(ContainerContent)`
  padding: 0 30px;
`

interface IFirstLoginChangePasswordPageProps {}

const FirstLoginChangePasswordPage: React.FC<
  IFirstLoginChangePasswordPageProps
> = () => {
  const translate = useAppTranslator({
    namespaces: ['Login.Labels', 'Login.FirstLogin']
  })

  return (
    <DSThemeProvider themeName={DSThemeName.default}>
      <StyledAuthPlaceHolders
        layout={{
          header: (
            <ContainerFlex
              name="FirstLoginChangePasswordHeader"
              alignItems="center"
              justifyContent="center"
              style={{
                height: '150px'
              }}
              items={[
                <AssetImg
                  imageName="logo-tenable-ad"
                  width={280}
                  fileType="svg"
                  labelledBy="tenableLogo"
                />
              ]}
            />
          ),
          content: (
            <AuthContainerPadded>
              <StyledContainerContent
                spinner={<SpinnerInline />}
                spaceWidth="large"
              >
                <ContainerFlex
                  name="FirstLoginChangePasswordContent"
                  alignItems="center"
                  justifyContent="center"
                  items={[
                    <AssetImg
                      imageName="secure-account"
                      width={116}
                      fileType="svg"
                      labelledBy="secureAccount"
                    />
                  ]}
                />

                <ContainerFlex
                  name="FirstLoginChangePasswordText"
                  direction="column"
                  alignItems="center"
                  items={[
                    <StyledLabel variant={LabelVariant.p_bold}>
                      {translate('Secure your account')}
                    </StyledLabel>,

                    <StyledLabel variant={LabelVariant.p}>
                      {translate(
                        'In order to continue to provide you with a safe experience, please change your password'
                      )}
                    </StyledLabel>
                  ]}
                  flexGap="smaller"
                />

                <FirstLoginPasswordForm />

                <StepPoints nbPoints={3} selectedPoint={1} />
              </StyledContainerContent>
            </AuthContainerPadded>
          ),
          footer: (
            <PortalPlaceHolder
              name={PlaceHolderName.firstLoginPasswordChangeFooter}
            />
          )
        }}
      />
    </DSThemeProvider>
  )
}

export default observer(FirstLoginChangePasswordPage)
