import type {
  StoreManagementLDAPConfiguration,
  StoreManagementRelays,
  StoreRbac
} from '@app/stores'
import { LdapConfigurationFieldName } from '@app/stores/Management/StoreLDAPConfiguration/types'
import { SamlConfigurationGroupFieldName } from '@app/stores/Management/StoreSAMLConfiguration/types'
import { isNotFalsy } from '@libs/isDefined'
import type { InputLdapConfiguration } from '@server/graphql/typeDefs/types'
import { canAccessToRelays } from '../../Relays/permissions'

export const onLoadLdapConfiguration =
  (
    storeManagementLDAPConfiguration: StoreManagementLDAPConfiguration,
    storeManagementRelays: StoreManagementRelays,
    storeRbac: StoreRbac
  ) =>
  () => {
    storeManagementLDAPConfiguration.fetchLDAPConfiguration()
    if (storeRbac.isUserGrantedTo(canAccessToRelays)) {
      storeManagementRelays.fetchRelays()
    }
  }

export const onUnLoadLdapConfiguration =
  (storeManagementLDAPConfiguration: StoreManagementLDAPConfiguration) =>
  () => {
    storeManagementLDAPConfiguration.reset()
  }

export const onLDAPConfigurationSubmit =
  (storeManagementLDAPConfiguration: StoreManagementLDAPConfiguration) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { storeRoot, storeForm } = storeManagementLDAPConfiguration

    const translate = storeRoot.appTranslator.bindOptions({
      namespaces: ['Management.System.Configuration.LDAP']
    })

    const isLdapConfigurationEnable = storeForm.getFieldValueAsBoolean(
      LdapConfigurationFieldName.enabled
    )

    // validate and check groups only if enabling the LDAP configuration
    if (isLdapConfigurationEnable) {
      // validate main form
      if (!storeForm.validate()) {
        storeRoot.stores.storeMessages.validationError()
        return
      }

      const ldapGroups =
        storeManagementLDAPConfiguration.storeFormAllowedGroups.groupsAsArray

      if (!ldapGroups.length) {
        storeRoot.stores.storeMessages.error(
          translate('You have to configure at least one allowed group'),
          {
            labelledBy: 'ldapConfigGroupError'
          }
        )
        return
      }

      // validate groups
      for (const storeFormLdapGroup of ldapGroups) {
        if (!storeFormLdapGroup.validate()) {
          storeRoot.stores.storeMessages.validationError()
          return
        }
      }
    }

    const ldapConfiguration: InputLdapConfiguration = {
      enabled: isLdapConfigurationEnable,
      url: storeForm.getFieldValueAsString(LdapConfigurationFieldName.url),
      searchUserDN: storeForm.getFieldValueAsString(
        LdapConfigurationFieldName.searchUserDN
      ),
      searchUserPassword: storeForm.getFieldValueAsStringOrNull(
        LdapConfigurationFieldName.searchUserPassword
      ),
      userSearchBase: storeForm.getFieldValueAsString(
        LdapConfigurationFieldName.userSearchBase
      ),
      userSearchFilter: storeForm.getFieldValueAsString(
        LdapConfigurationFieldName.userSearchFilter
      ),
      allowedGroups: isLdapConfigurationEnable
        ? storeManagementLDAPConfiguration.storeFormAllowedGroups.groupsAsArray
            .filter(storeFormGroup => {
              return isNotFalsy(
                storeFormGroup.getFieldValueAsString(
                  SamlConfigurationGroupFieldName.groupName
                )
              )
            })
            .map(storeFormGroup => {
              return {
                name: storeFormGroup.getFieldValueAsString(
                  SamlConfigurationGroupFieldName.groupName
                ),
                defaultProfileId: storeFormGroup.getFieldValueAsNumber(
                  SamlConfigurationGroupFieldName.defaultProfileId
                ),
                defaultRoleIds: storeFormGroup
                  .getFieldValueForSelectMultiple(
                    SamlConfigurationGroupFieldName.defaultRoleIds
                  )
                  .map(roleId => Number(roleId))
              }
            })
        : null,
      enableSaslBinding: storeForm.getFieldValueAsBoolean(
        LdapConfigurationFieldName.enableSaslBinding
      ),
      relayId:
        storeForm.getFieldValueAsNumber(
          LdapConfigurationFieldName.relayId,
          0
        ) || null
    }

    storeManagementLDAPConfiguration
      .updateLDAPConfiguration(ldapConfiguration)
      .catch(() => {
        // already catched in the store
      })
  }
