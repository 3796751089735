import { LabelNonBreakable } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import {
  onWidgetListSelectAllSelectAllPageClick,
  onWidgetListSelectAllSelectCurrentPageClick,
  onWidgetListSelectAllUnselectAllClick
} from './handlers'

interface IWidgetListSelectAllCheckboxPopoverContentProps {
  className?: string
  onChange: (state: boolean) => void
  hasInfiniteScroll?: boolean
  disableSelectAllPages?: boolean
  storeWidgetList: StoreWidgetList<any, any>
}

const WidgetListSelectAllCheckboxPopoverContent: React.FC<
  IWidgetListSelectAllCheckboxPopoverContentProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Components.WidgetList'],
    storeOptionsI18n: props.storeWidgetList.options
  })

  return (
    <div
      data-name="WidgetListSelectAllCheckboxPopoverContent"
      className={props.className}
    >
      <ul>
        {props.storeWidgetList.hasPagination && (
          <li
            onClick={onWidgetListSelectAllSelectCurrentPageClick(
              props.storeWidgetList,
              props.onChange
            )}
          >
            <LabelNonBreakable labelledBy="selectCurrentPage">
              {props.hasInfiniteScroll
                ? translate('Select displayed objects')
                : translate('Select current page')}
            </LabelNonBreakable>
          </li>
        )}

        {props.storeWidgetList.hasPagination &&
          !props.disableSelectAllPages && (
            <li
              onClick={onWidgetListSelectAllSelectAllPageClick(
                props.storeWidgetList,
                props.onChange
              )}
            >
              <LabelNonBreakable labelledBy="selectAllPages">
                {props.hasInfiniteScroll
                  ? translate('Select all')
                  : translate('Select all pages')}
              </LabelNonBreakable>
            </li>
          )}

        <li
          onClick={onWidgetListSelectAllUnselectAllClick(
            props.storeWidgetList,
            props.onChange
          )}
        >
          <LabelNonBreakable labelledBy="unselectAllPages">
            {translate('Unselect all pages')}
          </LabelNonBreakable>
        </li>
      </ul>
    </div>
  )
}

export default styled(WidgetListSelectAllCheckboxPopoverContent)`
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    padding: ${consts.paddingSmall};
    cursor: pointer;

    &:hover {
      background: ${consts.colorBlue002};
    }
  }
`
