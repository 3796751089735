import { ContainerFlex } from '@app/components/Container'
import { Label, LabelSliced } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { FormWrapperSelect } from '@app/components-legacy/Form'
import InputInfrastructures from '@app/components-legacy/Input/InputInfrastructures'
import InputSearch from '@app/components-legacy/Input/InputSearch'
import { LabelDirectoriesTree } from '@app/components-legacy/Label'
import LabelDirectoryTag from '@app/components-legacy/Label/LabelDirectoryTag'
import { useAppTranslator, useStores } from '@app/hooks'
import NodeSearchIcon from '@app/pages/AttackPath/Components/NodeSearchIcon'
import {
  handleAccountsWithTier0AttackPathAssetChange,
  handleAccountsWithTier0AttackPathDirectoryChange,
  handleAccountsWithTier0AttackPathSearchChange
} from '@app/pages/AttackPath/SceneBlade/NodeFinder/Tier0/handlers'
import { consts } from '@app/styles'
import { isDefined } from '@libs/isDefined'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

const StyledContainerFlex = styled(ContainerFlex)`
  margin: 0 ${consts.marginDefault};
`

interface IAttackPathTier0AccountsFiltersProps {
  className?: string
}

const AttackPathTier0AccountsFilters: React.FC<
  IAttackPathTier0AccountsFiltersProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.Tier0']
  })

  const { storeAttackPath, storeInfrastructures } = useStores()

  const { storeAttackPathTier0 } = storeAttackPath

  return (
    <StyledContainerFlex
      name="AttackPathTier0AccountsFilters"
      itemsFlexGrow={[1, 0, 0]}
      items={[
        <InputSearch
          name="accountsWithTier0AttackPathSearchInput"
          placeholder={translate('Search for an object')}
          storeInputSearch={
            storeAttackPathTier0.storeInputSearchAccountsWithTier0AttackPath
          }
          onChange={handleAccountsWithTier0AttackPathSearchChange(
            storeAttackPathTier0
          )}
          onReset={handleAccountsWithTier0AttackPathSearchChange(
            storeAttackPathTier0
          )}
        />,
        <FormWrapperSelect
          selectProps={{
            placeholder: translate('Asset'),
            value: storeAttackPathTier0.accountsWithTier0AttackPathAssetFilter,
            allowClear: true,
            showSearch: true,
            filterOption: (input: string, option: any) =>
              option?.key?.toLowerCase().includes(input.toLowerCase()),
            onChange:
              handleAccountsWithTier0AttackPathAssetChange(storeAttackPathTier0)
          }}
          labelledBy="accountsWithTier0AttackPathObjectFilter"
          width="extraLarge"
          selectOptions={
            storeAttackPathTier0.attackPathAllTier0ObjectsSelectOptions
          }
          renderOption={option => {
            const optionObject =
              storeAttackPathTier0.attackPathAllTier0Objects?.find(
                object => object.id === option.value
              )

            const directory =
              optionObject?.directoryId &&
              storeInfrastructures.getDirectoryFromId(optionObject.directoryId)

            return (
              <ContainerFlex
                name="AccountsWithTier0AttackPathObjectFilterOption"
                alignItems="center"
                justifyContent="space-between"
                items={[
                  <ContainerFlex
                    name="AccountsWithTier0AttackPathObjectFilterOption"
                    alignItems="center"
                    items={[
                      <NodeSearchIcon
                        adObjectType={optionObject?.adObjectType}
                        size={25}
                      />,
                      <Label variant={LabelVariant.p}>
                        <LabelSliced
                          value={option.label}
                          maxWidth={180}
                          allowHTML
                        />
                      </Label>
                    ]}
                    spaced
                    spaceWidth="small"
                  />,
                  directory && isDefined(directory.id) && directory.name && (
                    <LabelDirectoryTag
                      color={directory.color}
                      label={directory.name}
                      tooltipLabel={
                        <LabelDirectoriesTree
                          storeInfrastructures={storeInfrastructures}
                          directoryIds={[directory.id]}
                        />
                      }
                      maxWidth={130}
                    />
                  )
                ]}
                spaced
                spaceWidth="small"
              />
            )
          }}
        />,
        <InputInfrastructures
          storeInfrastructures={storeAttackPathTier0.storeInfrastructures}
          onValidate={handleAccountsWithTier0AttackPathDirectoryChange(
            storeAttackPathTier0
          )}
        />
      ]}
      spaced
    />
  )
}

export default observer(AttackPathTier0AccountsFilters)
