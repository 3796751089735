import type { IDataRowEmail } from '@app/entities/EntityEmail'
import type { StoreManagementEmails } from '@app/stores'

/**
 * Delete the email, close the drawer and reload emails.
 */
export const onEmailDeleteSubmit =
  (storeManagementEmails: StoreManagementEmails) =>
  (dataRow: IDataRowEmail) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    storeManagementEmails
      .deleteEmail(dataRow.id)
      .then(() => {
        storeManagementEmails.storeDeleteDrawer.closeDrawer()
        return storeManagementEmails.fetchEmails()
      })
      .catch(() => {
        // already catched in the store
      })
  }
