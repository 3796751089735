import type { IIconProps } from '@app/components/Icon/types'
import { BackgroundColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconCheckCircleProps extends IIconProps {}

const IconCheckCircle: React.FC<IIconCheckCircleProps> = props => {
  const fill = props.color ?? BackgroundColorV2.green
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="8" cy="8" r="8" fill={fill} />
      <path
        d="M5.2137 11.8765L5.94138 12.6429L6.51976 11.7587C6.92024 11.1473 7.35516 10.5488 7.81011 9.97889C9.21959 8.21919 10.896 6.66203 12.7935 5.34999L13.4825 4.87503L12.5324 3.5L11.8449 3.97506C9.81393 5.37885 8.01692 7.04664 6.50548 8.93408C6.23416 9.27279 5.96865 9.6216 5.71465 9.97753C5.11904 9.35888 4.61374 8.85509 3.75831 8.04131L3.15258 7.46432L2 8.67716L2.60573 9.25415C3.74539 10.3378 4.2506 10.8603 5.21365 11.8764L5.2137 11.8765Z"
        fill="white"
      />
    </svg>
  )
}

export default IconCheckCircle
