import { ContainerContent } from '@app/components/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { EntityAlertIoE } from '@app/entities'
import type StoreGenericAlerts from '@app/stores/StoreAlerts/StoreGenericAlerts'
import { isDefined } from '@libs/isDefined'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import AlertsContentItemIoE from './AlertsContentItemIoE'
import AlertContentTimeline from './AlertsContentTimeline'
import AlertsFooter from './AlertsFooter'
import { onAlertsDrawerMenuLoad } from './handlers'

interface IAlertsContentIoEProps {
  storeGenericAlerts: StoreGenericAlerts<any>
}

const AlertsContentIoE: React.FC<IAlertsContentIoEProps> = props => {
  const alertItems = props.storeGenericAlerts.alerts
    .map(alert => {
      if (alert instanceof EntityAlertIoE) {
        return (
          <AlertsContentItemIoE
            alert={alert}
            storeGenericAlerts={props.storeGenericAlerts}
          />
        )
      }

      return null
    })
    .filter(isDefined)

  return (
    <ContainerContent
      onLoad={onAlertsDrawerMenuLoad(props.storeGenericAlerts)}
      flags={[
        props.storeGenericAlerts.storeFlags.flags,
        props.storeGenericAlerts.storeActionFlags.flags,
        props.storeGenericAlerts.storeSwitchFlags.flags
      ]}
      spinner={<SpinnerInline />}
    >
      <AlertContentTimeline
        alertItems={alertItems}
        storeGenericAlerts={props.storeGenericAlerts}
      />

      <Portal name={PlaceHolderName.drawerFooter}>
        {alertItems.length > 0 && (
          <AlertsFooter storeGenericAlerts={props.storeGenericAlerts} />
        )}
      </Portal>
    </ContainerContent>
  )
}

export default observer(AlertsContentIoE)
