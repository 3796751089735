import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter, useStores } from '@app/hooks'
import { AppRouteName } from '@app/routes'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canReadProfile } from '../permissions'
import ConfigurationContent from './ConfigurationContent'
import ConfigurationHeader from './ConfigurationHeader'
import ConfigurationMenu from './ConfigurationMenu'
import {
  onProfileCheckerConfigurationLoad,
  onProfileCheckerConfigurationUnload
} from './handlers'

interface IProfilesCheckerConfigurationProps {}

const ProfilesCheckerConfiguration: React.FC<
  IProfilesCheckerConfigurationProps
> = props => {
  const { storeManagementProfiles } = useStores()
  const { storeInputGenericCheckers } =
    storeManagementProfiles.storeProfileGenericCheckersSelected

  const appRouter = useAppRouter()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_Accounts_Profiles_Edit_Checker,
    parameters: {
      profileId: Number(),
      checkerId: Number(),
      checkerCodename: String()
    }
  })

  if (!parameters) {
    return null
  }

  const profileEntity = storeManagementProfiles.currentProfileEntity
  const checkerEntity = storeInputGenericCheckers.checkersByCodename.get(
    parameters.checkerCodename
  )

  if (!checkerEntity) {
    return null
  }

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_Accounts_Profiles_Edit_Checker,
        parameters
      }}
      onLoad={onProfileCheckerConfigurationLoad(
        storeManagementProfiles.storeProfileGenericCheckersSelected
      )(parameters.checkerCodename)}
      onUnload={onProfileCheckerConfigurationUnload(
        storeManagementProfiles.storeProfileGenericCheckersSelected
      )(parameters.checkerCodename)}
      flags={[
        storeManagementProfiles.storeProfileGenericCheckersSelected
          .storeFlagsFetchCheckerOptions.flags
      ]}
      layout={{
        name: 'default',
        header: <ConfigurationHeader checkerEntity={checkerEntity} />,
        menu: (
          <DSThemeProvider themeName={DSThemeName.default}>
            <ConfigurationMenu
              checkerCodename={parameters.checkerCodename}
              storeProfileGenericCheckers={
                storeManagementProfiles.storeProfileGenericCheckersSelected
              }
            />
          </DSThemeProvider>
        ),
        content: (
          <ConfigurationContent
            profileEntity={profileEntity}
            checkerEntity={checkerEntity}
          />
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
      }}
      rbacCapabilityCheck={canReadProfile(
        profileEntity?.getPropertyAsNumber('id') || null
      )}
    />
  )
}

export default observer(ProfilesCheckerConfiguration)
