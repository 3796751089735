import {
  ContainerContent,
  ContainerFlex,
  ContainerHTMLContent
} from '@app/components/Container'
import type EntityAttack from '@app/entities/EntityAttack'
import { useAppTranslator } from '@app/hooks'
import { mdToHTML } from '@libs/mdToHTML'
import * as React from 'react'
import styled from 'styled-components'

interface IIoAAttacksDescriptionTabDescriptionProps {
  attackEntity: EntityAttack
}

const DescriptionsColumn = styled.div`
  flex: 50%;
`

const IoAAttacksDescriptionTabDescription: React.FC<
  IIoAAttacksDescriptionTabDescriptionProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['IoA.Attacks']
  })

  const attackVectorEntity = props.attackEntity.getVector()
  const attackVectorDescription = attackVectorEntity?.buildDescription(
    props.attackEntity.getPropertyAsString('attackTypeVectorTemplate')
  )

  return (
    <DescriptionsColumn>
      <ContainerFlex
        name="IoAAttacksDescriptionTabLeft"
        direction="column"
        items={[
          <ContainerContent title={translate('Incident description')}>
            <ContainerHTMLContent>
              {mdToHTML(
                props.attackEntity.getPropertyAsString('attackTypeDescription')
              )}
            </ContainerHTMLContent>

            {attackVectorDescription && (
              <ContainerHTMLContent>
                {mdToHTML(attackVectorDescription)}
              </ContainerHTMLContent>
            )}
          </ContainerContent>,

          <ContainerContent title={translate('MITRE ATT&CK® info')}>
            <ContainerHTMLContent>
              {mdToHTML(
                props.attackEntity.getPropertyAsString(
                  'attackTypeMitreAttackDescription'
                )
              )}
            </ContainerHTMLContent>
          </ContainerContent>
        ]}
        spaced
      />
    </DescriptionsColumn>
  )
}

export default IoAAttacksDescriptionTabDescription
