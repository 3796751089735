import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface ICardRankTitleProps {
  className?: string
}

const CardRankTitle: React.FC<ICardRankTitleProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Buttons', 'IoA.Board'] })

  return <div className={props.className}>{translate('Top 3 attacks')}</div>
}

export default styled(CardRankTitle)`
  background-color: ${consts.colorBlueGray035};
  padding: ${consts.marginVerySmall} ${consts.paddingMedium};
  font-weight: ${consts.fontWeightBold};
  margin-right: 2px;
`
