import type { Maybe } from '@@types/helpers'
import {
  LabelDirectoriesTree,
  LabelDirectory
} from '@app/components-legacy/Label'
import { useAppTranslator, useStores } from '@app/hooks'
import { CSSColors } from '@app/styles/colors/types'
import { intersection } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export interface ILabelDirectoriesProps {
  // is null, it means that it's for all directories
  directoryIds: Maybe<number[]>
  maxWidth?: number
}

const LabelDirectories: React.FC<ILabelDirectoriesProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Components.LabelDirectories']
  })

  const { storeInfrastructures } = useStores()

  /**
   * If all directories belong to the same infrastructure,
   * return the color of the first directory.
   *
   * Otherwise, return black.
   */
  const getColor = (): string => {
    if (props.directoryIds === null) {
      return CSSColors.Black
    }

    return props.directoryIds.length === 1
      ? storeInfrastructures.getDirectoryColor(props.directoryIds[0])
      : CSSColors.Black
  }

  /**
   * If there is only one directory, return the name and the color of the directory.
   * Otherwise, return the number of directories.
   */
  const getLabel = (): string => {
    if (props.directoryIds === null) {
      return translate('All domains')
    }

    if (props.directoryIds.length === 1) {
      const directoryId = props.directoryIds[0]
      const directory = storeInfrastructures.directories.get(directoryId)

      const fallbackLabel = translate('Not available')

      if (!directory) {
        return fallbackLabel
      }

      return directory.name || fallbackLabel
    }

    return translate('nbDomains', {
      interpolations: {
        count: props.directoryIds.length
      }
    })
  }

  /**
   * Return the title of the tooltip.
   *
   * In some cases, some directory could be set as props but could not be
   * present in the store (case of Widgets on the dashboards).
   *
   * In this case, we add a custom message to explain why.
   */
  const renderTooltipTitle = (): Maybe<React.ReactElement> => {
    if (props.directoryIds === null) {
      return null
    }

    if (!props.directoryIds.length) {
      return null
    }

    const allDirectoryIds = storeInfrastructures.directoryIds

    const isDirectoryDoesNotMatch =
      intersection(props.directoryIds, allDirectoryIds).length === 0

    if (isDirectoryDoesNotMatch) {
      return <div>{translate('Domains not available')}</div>
    }

    return (
      <LabelDirectoriesTree
        storeInfrastructures={storeInfrastructures}
        directoryIds={props.directoryIds}
      />
    )
  }

  return (
    <div data-name="LabelDirectories">
      <LabelDirectory
        color={getColor()}
        label={getLabel()}
        tooltipLabel={renderTooltipTitle()}
        maxWidth={props.maxWidth}
      />
    </div>
  )
}

export default observer(LabelDirectories)
