import type { AppRouteDefinition } from '@app/routes'
import type StoreAbout from '@app/stores/StoreAbout'
import type StoreLicense from '@app/stores/StoreLicense'
import type { License } from '@server/graphql/typeDefs/types'
import { RequestErrorCode } from '@server/graphql/typeDefs/types'
import type { UploadChangeParam } from 'antd/lib/upload'

export const handleAboutOnLoad =
  (storeAbout: StoreAbout, storeLicense: StoreLicense) => (): void => {
    storeAbout.fetchAbout()
    storeLicense.fetchLicense()
  }

export const handleLicenseOnUpload =
  (storeLicense: StoreLicense) =>
  (options?: { redirectRouteDefinition?: AppRouteDefinition }) =>
  (info: UploadChangeParam): void => {
    if (info.file.status === 'done') {
      const { appRouter } = storeLicense.storeRoot

      storeLicense.setLicenseUploaded(true)

      storeLicense.updateLicense(info.file.response as License)

      // reload the app to reinitalize everything according to the license features
      if (options?.redirectRouteDefinition) {
        appRouter.hardRedirect(options?.redirectRouteDefinition)
      }
    }

    if (info.file.status === 'error') {
      if (
        info.file.response?.error?.code ===
        RequestErrorCode.LicenseDowngradeNotAllowed
      ) {
        return storeLicense.uploadLicenseFailed(
          'License downgrade is not allowed',
          'licenseDowngradeNotAllowed'
        )
      }

      if (
        info.file.response?.error?.code === RequestErrorCode.InvalidLinkingKey
      ) {
        return storeLicense.uploadLicenseFailed(
          'This license may already have been used',
          'invalidLinkingKey'
        )
      }

      storeLicense.uploadLicenseFailed(null)
    }
  }
