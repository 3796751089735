import { AppRouteName } from '@app/routes'
import type { StoreManagementSAMLConfiguration } from '@app/stores'
import {
  SamlConfigurationFieldName,
  SamlConfigurationGroupFieldName
} from '@app/stores/Management/StoreSAMLConfiguration/types'
import { isNotFalsy } from '@libs/isDefined'
import type { AppRouterClient } from '@libs/Router/types'
import type { InputSamlConfiguration } from '@server/graphql/typeDefs/types'

export const onLoadSAMLConfiguration =
  (storeManagementSAMLConfiguration: StoreManagementSAMLConfiguration) =>
  () => {
    storeManagementSAMLConfiguration.fetchSAMLConfiguration()
  }

export const onUnLoadSAMLConfiguration =
  (storeManagementSAMLConfiguration: StoreManagementSAMLConfiguration) =>
  () => {
    storeManagementSAMLConfiguration.reset()
  }

/**
 * Open a new window on the certificate download url that
 * shoud initiate a new file download.
 */
export const onSAMLMetadataGenerateClick =
  (appRouter: AppRouterClient) => () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareSAML_GenerateCertificate,
      parameters: {}
    })

    window.open(url)
  }

/**
 * Open a new window on the metadata download url that
 * shoud initiate a new file download.
 */
export const onSAMLMetadataExportClick = (appRouter: AppRouterClient) => () => {
  const url = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.MiddlewareAuth_SAML_Metadata,
    parameters: {}
  })

  window.open(url)
}

/**
 * Validate the SAML configuration.
 */
export const onSAMLConfigurationSubmit =
  (storeManagementSAMLConfiguration: StoreManagementSAMLConfiguration) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeRoot, storeForm } = storeManagementSAMLConfiguration

    const translate = storeRoot.appTranslator.bindOptions({
      namespaces: ['Management.System.Configuration.SAML']
    })

    const isSamlConfigurationEnable = storeForm.getFieldValueAsBoolean(
      SamlConfigurationFieldName.enabled
    )

    // validate and check groups only if enabling the SAML configuration
    if (isSamlConfigurationEnable) {
      // validate main form
      if (!storeForm.validate()) {
        storeRoot.stores.storeMessages.validationError()
        return
      }

      const samlGroups =
        storeManagementSAMLConfiguration.storeFormAllowedGroups.groupsAsArray

      if (!samlGroups.length) {
        storeRoot.stores.storeMessages.error(
          translate('You have to configure at least one allowed group'),
          {
            labelledBy: 'samlConfigGroupError'
          }
        )
        return
      }

      // validate groups
      for (const storeFormGroup of samlGroups) {
        if (!storeFormGroup.validate()) {
          storeRoot.stores.storeMessages.validationError()
          return
        }
      }
    }

    const samlConfiguration: InputSamlConfiguration = {
      enabled: isSamlConfigurationEnable,
      providerLoginUrl: storeForm.getFieldValueAsString(
        SamlConfigurationFieldName.providerLoginUrl
      ),
      signatureCertificate: storeForm.getFieldValueAsString(
        SamlConfigurationFieldName.signatureCertificate
      ),
      activateCreatedUsers: storeForm.getFieldValueAsBoolean(
        SamlConfigurationFieldName.activateCreatedUsers
      ),
      allowedGroups: isSamlConfigurationEnable
        ? storeManagementSAMLConfiguration.storeFormAllowedGroups.groupsAsArray
            .filter(storeFormGroup => {
              return isNotFalsy(
                storeFormGroup.getFieldValueAsString(
                  SamlConfigurationGroupFieldName.groupName
                )
              )
            })
            .map(storeFormGroup => {
              return {
                name: storeFormGroup.getFieldValueAsString(
                  SamlConfigurationGroupFieldName.groupName
                ),
                defaultProfileId: storeFormGroup.getFieldValueAsNumber(
                  SamlConfigurationGroupFieldName.defaultProfileId
                ),
                defaultRoleIds: storeFormGroup
                  .getFieldValueForSelectMultiple(
                    SamlConfigurationGroupFieldName.defaultRoleIds
                  )
                  .map(roleId => Number(roleId))
              }
            })
        : null
    }

    storeManagementSAMLConfiguration
      .updateSAMLConfiguration(samlConfiguration)
      .catch(() => {
        // already catched in the store
      })
  }
