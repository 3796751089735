import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconLicenseProps extends IIconProps {}

const IconLicense: React.FC<IIconLicenseProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 20

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.375 10.625C15.7557 10.625 16.875 9.50571 16.875 8.125C16.875 6.74429 15.7557 5.625 14.375 5.625C12.9943 5.625 11.875 6.74429 11.875 8.125C11.875 9.50571 12.9943 10.625 14.375 10.625Z"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.125 3.1236C13.125 2.43402 12.566 1.875 11.8764 1.875H3.12207C2.43333 1.875 1.875 2.43333 1.875 3.12207V16.8779C1.875 17.5667 2.43333 18.125 3.12207 18.125H11.8764C12.566 18.125 13.125 17.566 13.125 16.8764"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.625 15V14.3758C10.625 13.685 11.185 13.125 11.8758 13.125H16.8742C17.565 13.125 18.125 13.685 18.125 14.3758V15"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 5.625H9.375"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 8.125H9.375"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 10.625H9.375"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 13.125H8.125"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 15.625H8.125"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconLicense
