import { LabelSliced } from '@app/components-legacy/Label'
import type { EntityEvent } from '@app/entities'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import * as React from 'react'
import TableContentBodyCell from '../TableContentBodyCell'
import CellEmpty from './CellEmpty'

interface ICellClassFileProps {
  event?: EntityEvent
}

const CellClassFile: React.FC<ICellClassFileProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['TrailFlow.EventDetails.Attributes']
  })

  const getCellContent = () => {
    if (!props.event) {
      return <CellEmpty />
    }

    const adObject = props.event.getAdObject()

    if (!adObject) {
      return <CellEmpty />
    }

    const classOrFile = adObject.getClassOrFile()

    return (
      <LabelSliced
        popoverType="none"
        value={classOrFile === null ? translate('Folder') : classOrFile}
        maxLength={30}
        labelledBy="object"
      />
    )
  }

  return (
    <div className="eventClassFile">
      <TableContentBodyCell>{getCellContent()}</TableContentBodyCell>
    </div>
  )
}

export default CellClassFile
