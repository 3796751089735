import type { EntityUserLog } from '@app/entities'
import { consts } from '@app/styles'
import { Collapse } from 'antd'
import React from 'react'
import styled from 'styled-components'

export interface IUserLogCollapseRowProps {
  className?: string
  children?: React.ReactNode
  title: React.ReactNode
  log: EntityUserLog
}

const StyledCollapse = styled(Collapse)`
  margin-top: ${consts.marginExtraSmall};
  margin-bottom: ${consts.marginExtraSmall};
  background-color: transparent;

  > .ant-collapse-item > .ant-collapse-header {
    padding: 0px !important;
    width: 100%;
  }
  > .ant-collapse-item > .ant-collapse-header > .ant-collapse-arrow {
    top: 0px !important;
    right: ${consts.marginSmall} !important;
  }

  > .ant-collapse-item {
    border: 0px !important;
  }
`

const StyledCollapsePanel = styled(Collapse.Panel)`
  background-color: transparent;

  & .ant-collapse-content-box {
    margin-top: ${consts.marginExtraSmall};
    margin-bottom: ${consts.marginExtraSmall};
    margin-left: ${consts.marginSmall};
    margin-right: ${consts.marginSmall};
    border-radius: ${consts.borderRadiusRedesign};
    background-color: ${consts.colorWhite};
    padding: ${consts.paddingVeryVerySmall} ${consts.paddingDefault} !important;
  }
`

const UserLogCollapseRow: React.FC<IUserLogCollapseRowProps> = props => {
  const { log } = props

  return (
    <StyledCollapse
      bordered={false}
      expandIconPosition="end"
      className={props.className}
    >
      <StyledCollapsePanel header={props.title} key={String(log.id)}>
        {props.children}
      </StyledCollapsePanel>
    </StyledCollapse>
  )
}

export default UserLogCollapseRow
