/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CreateTenantSetting
 */
export interface CreateTenantSetting {
  /**
   *
   * @type {string}
   * @memberof CreateTenantSetting
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreateTenantSetting
   */
  credentialsId: string
}

/**
 * Check if a given object implements the CreateTenantSetting interface.
 */
export function instanceOfCreateTenantSetting(value: object): boolean {
  if (!('name' in value)) return false
  if (!('credentialsId' in value)) return false
  return true
}

export function CreateTenantSettingFromJSON(json: any): CreateTenantSetting {
  return CreateTenantSettingFromJSONTyped(json, false)
}

export function CreateTenantSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CreateTenantSetting {
  if (json == null) {
    return json
  }
  return {
    name: json['name'],
    credentialsId: json['credentials_id']
  }
}

export function CreateTenantSettingToJSON(
  value?: CreateTenantSetting | null
): any {
  if (value == null) {
    return value
  }
  return {
    name: value['name'],
    credentials_id: value['credentialsId']
  }
}
