import type { Maybe } from '@@types/helpers'
import { LabelValue } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

const ToolIcon = styled.div`
  font-size: 2em;
  line-height: 45px;
  width: 50px;
  height: 50px;
  background: ${consts.colorBlueGray020};
  text-align: center;
  border: 1px solid ${consts.colorBlueGray015};
`

export interface ILabelResourceProps {
  name?: string
  url?: Maybe<string>
}

const LabelResource: React.FC<ILabelResourceProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.LabelResource']
  })

  const getName = () => {
    if (props.url) {
      return <a href={props.url}>{props.name}</a>
    }

    return <span>{props.name}</span>
  }

  const getItems = () => {
    const items = [
      <LabelValue fallbackValue={translate('Unnamed resource')}>
        {getName()}
      </LabelValue>
    ]

    return items
  }

  return (
    <ul>
      {getItems().map((item, key) => {
        return <li key={key}>{item}</li>
      })}
    </ul>
  )
}

export default LabelResource
