/* eslint-disable max-classes-per-file */
import type { ParseResultFailure } from '@libs/Expression/parser/ParseResult'
import { ParseResult } from '@libs/Expression/parser/ParseResult'
import { immutableAppend } from '@libs/immutableAppend'
import { trimLeft } from '@libs/trim'

interface IParserInput {
  parsersHistory: string[]
  text: string
  parserName: string
}

type ParserThunk<T> = () => Parser<T>
type ParserApplyFn<T> = (input: IParserInput) => ParseResult<T>

function computeRemaining(input: string, currentPos: number) {
  return input.substring(currentPos + 1)
}

export abstract class Parser<T> {
  protected $parentName: string | null = null

  protected constructor(protected $name: string) {}

  get name(): string {
    return this.$name
  }

  isStrict(): this is StrictParser<T> {
    return !this.isLazy()
  }

  /**
   * Returns a new parser that, once called with an input, will first
   * apply this parser and :
   *  - On Succeed, will apply the function passed as parameter to the result
   *
   * Example :
   *  const nameParser : Parser<string> = stringEqualsTo("Jean-Michel")
   *  const parser : Parser<string> = nameParser.flatMap(ignoredName => stringEqualsTo("12345"))
   *  - parser.parse("Jean-Michel12345") will
   *      Successfully returns "12345" as result and "" as remaining input.
   *  - parser.parse("KEY12345") will
   *      Failed and the function won't be called.
   *
   * @param fn The function to apply on the result of this parser if success
   */
  flatMap<U>(fn: (t: T) => Parser<U>): Parser<U> {
    return Parser.strict<U>('FlatMap', input => {
      const parseResult: ParseResult<T> = this.apply(input)

      if (parseResult.isSuccess()) {
        const parsedValue = parseResult.parsedValue
        return fn(parsedValue).apply({ ...input, text: parseResult.remaining })
      }

      return parseResult as any as ParseResultFailure<U>
    })
  }

  /**
   * Returns a new parser that, once called with an input, will first
   * apply this parser and then, on success, will apply the parser passed as
   * parameter on the remaining input of the first one.
   * If the 2 parser applies successfully, the 2 results of both parsers
   * will be returned as the result.
   *
   * Example :
   *  const keyParser : Parser<string> = stringEqualsTo("key")
   *  const parser : Parser<[string, string]> = keyParser.followedBy(stringEqualsTo("123"))
   *  parser.parse("key12345") will
   *    - Successfully returns ["key", "123"] as parsed value and "45" as remaining input.
   *
   * @param parser the Parser that should be applied after this parser succeed
   * @param trimmingLeft:string If true the input will be trimmed after the
   *                            first parser succeed before passing it's remaining
   *                            to the second one.
   */
  followedBy<U>(
    parser: Parser<U>,
    trimmingLeft: boolean = true
  ): Parser<[T, U]> {
    return Parser.strict<[T, U]>(`${this.name} ~ ${parser.name}`, input => {
      const thisParseResult = this.apply(input)

      if (thisParseResult.isSuccess()) {
        const newText = trimmingLeft
          ? trimLeft(thisParseResult.remaining)
          : thisParseResult.remaining
        const otherParseResult = parser.apply({
          ...input,
          text: newText,
          parsersHistory: thisParseResult.parsersHistory
        })

        if (otherParseResult.isSuccess()) {
          return ParseResult.success<[T, U]>({
            parsersHistory: otherParseResult.parsersHistory,
            parsedValue: [
              thisParseResult.parsedValue,
              otherParseResult.parsedValue
            ],
            parserName: input.parserName,
            remaining: otherParseResult.remaining
          })
        }

        return otherParseResult as any as ParseResultFailure<[T, U]>
      }

      return thisParseResult as any as ParseResultFailure<[T, U]>
    })
  }

  /**
   * Returns a new parser that, once called with an input, will first
   * apply this parser and then, on success, will apply the parser passed as
   * parameter on the remaining input of the first one.
   * If the 2 parser applies successfully, the result of the first one only
   * will be kept and returned as the result.
   *
   * Example :
   *  const keyParser : Parser<string> = stringEqualsTo("key")
   *  const parser : Parser<string> = keyParser.followedByKeepLeft(stringEqualsTo("123"))
   *  parser.parse("key12345") will
   *    - Successfully returns "key" as parsed value and "45" as remaining input
   *        discarding the value "123" parsed by the parser on the right and thus
   *        keeping only the result of the parser on the left.
   *
   * @param parser
   */
  followedByKeepLeft(parser: Parser<any>): Parser<T> {
    return this.followedBy(parser)
      .map(([left, right]) => left)
      .named(`${this.name} <~ ${parser.name}`)
  }

  /**
   * Returns a new parser that, once called with an input, will first
   * apply this parser and then, on success, will apply the parser passed as
   * parameter on the remaining input of the first one.
   * If the 2 parser applies successfully, the result of the second one only
   * will be kept and returned as the result.
   *
   * Example :
   *  const keyParser : Parser<string> = stringEqualsTo("key")
   *  const parser : Parser<string> = keyParser.followedByKeepRight(stringEqualsTo("123"))
   *  parser.parse("key12345") will
   *    - Successfully returns "123" as parsed value and "45" as remaining input
   *        discarding the value "key" parsed by the parser on the left and thus
   *        keeping only the result of the parser on the right.
   *
   * @param parser
   */
  followedByKeepRight<U>(parser: Parser<U>): Parser<U> {
    return this.followedBy(parser)
      .map(([left, right]) => right)
      .named(`${this.name} ~> ${parser.name}`)
  }

  abstract isLazy(): this is LazyParser<T>

  /**
   * Returns a new parser that, once called with an input, will first
   * apply this parser and :
   *  - On Succeed, will apply the function passed as parameter to the result
   *
   * Example :
   *  const nameParser : Parser<string> = stringEqualsTo("Jean-Michel")
   *  const parser : Parser<User> = nameParser.map(name => new User(name))
   *  - parser.parse("Jean-Michel12345") will
   *      Successfully returns an instance of User as parsed value and "12345"
   *      as remaining input.
   *  - parser.parse("KEY12345") will
   *      Failed and the function won't be called.
   *
   * @param fn The function to apply on the result of this parser if success
   */
  map<U>(fn: (t: T) => U): Parser<U> {
    return Parser.strict<U>(this.name, input => this.apply(input).map(fn))
  }

  /**
   * Returns a new parser that, once called with an input, will first
   * apply this parser and :
   *  - On failure, will apply otherParser on the same input as the first one
   *  - On Succeed, won't apply the otherParser passed as parameter.
   *
   * Example :
   *  const parser : Parser<string> = stringEqualsTo("key").orElse(stringEqualsTo("KEY"))
   *  - parser.parse("key12345") will
   *      Successfully returns "key" as parsed value and "12345" as remaining input.
   *  - parser.parse("KEY12345") will
   *      Successfully returns "KEY" as parsed value and "12345" as remaining input.
   *
   * @param otherParser the Parser that should be applied after this parser failed
   */
  orElse<U>(otherParser: Parser<U>): Parser<T | U> {
    return Parser.strict<T | U>(`${this.name} | ${otherParser.name}`, input => {
      const parseResult = this.apply(input)
      return parseResult.isSuccess() ? parseResult : otherParser.apply(input)
    })
  }

  parse(text: string): ParseResult<T> {
    return this.apply({ text, parserName: this.name, parsersHistory: [] })
  }

  /**
   * Return a new parser that, once called with an input, will apply this parser
   * and, if success, will consume n chars and return the result parsed by this parser.
   *
   * Example :
   *  const keyParser = stringEqualsTo("key")
   *  const parser = keyParser.skipping(3)
   *  parser.parse("key12345") will
   *    - Successfully returns "key" as parsed value and "45" as remaining input
   *
   * @param nbChars : Number of chars to skip on the input
   */
  skippingNext(nbChars: number = 1): Parser<T> {
    const name = `SkippingNext(${nbChars})`

    if (nbChars < 1) {
      return this.named(name)
    }

    return Parser.strict(name, input => {
      return this.apply(input).flatMap((parsedValue, parseResult) =>
        ParseResult.success({
          parsersHistory: immutableAppend(parseResult.parsersHistory, name),
          parsedValue: parseResult.parsedValue,
          parserName: input.parserName,
          remaining: computeRemaining(parseResult.remaining, nbChars - 1)
        })
      )
    })
  }

  abstract apply(input: IParserInput): ParseResult<T>

  abstract named(name: string): Parser<T>

  /* Protected methods */

  protected eval(
    input: IParserInput,
    fn: (input: IParserInput) => ParseResult<T>
  ): ParseResult<T> {
    const result = fn(input)
    if (result.isSuccess()) {
      result.parsersHistory.push(
        `${this.name}   ParsedValue: '${result.parsedValue}'    Remaining: '${result.remaining}'`
      )
    }
    return result
  }

  /* Static methods */

  /**
   * Returns a new parser that, once called with an input, will successfully
   * return the value submitted as parameter without touching the input.
   *
   * Example :
   *  const parser = Parser.success<string>("key")
   *  parser.parse("12345") will
   *    - Successfully returns "key" as parsed value and "12345"
   *
   * @param value : The value to be return as successfull result when
   *                parser will be called on a given input
   */
  static success<T>(value: T): Parser<T> {
    return Parser.strict(`Success`, ({ text, parserName, parsersHistory }) =>
      ParseResult.success({
        parsersHistory,
        parsedValue: value,
        parserName,
        remaining: text
      })
    )
  }

  /**
   * Returns a new parser that, once called with an input, will failed
   * with the errorMsg submitted as parameter without touching the input.
   *
   * Example :
   *  const keyParser = Parser.failure<string>("An error occurred ")
   *  parser.parse("12345") will
   *  Fails wit this message "An error occurred" and "12345" as remaining input
   *
   *
   * @param errorMsg : error message to be displayed on failure result when
   *                parser will be called on any given input
   */
  static failure<T>(errorMsg: string): Parser<T> {
    return Parser.strict(`Failure`, ({ text, parserName, parsersHistory }) =>
      ParseResult.failure({
        parsersHistory,
        errorMsg,
        parserName,
        remaining: text
      })
    )
  }

  static lazy<T>(parserName: string, parserThunk: ParserThunk<T>): Parser<T> {
    return new LazyParser(parserThunk, null, parserName)
  }

  static computeRemaining(text: string, currentPos: number) {
    return computeRemaining(text, currentPos)
  }

  static strict<T>(
    parserName: string,
    parserApplyFn: ParserApplyFn<T>
  ): Parser<T> {
    return new StrictParser(parserApplyFn, parserName)
  }
}

class LazyParser<T> extends Parser<T> {
  constructor(
    private $parserThunk: ParserThunk<T>,
    private $parser: null | Parser<T>,
    $name: string = 'UnNamedLazyParser'
  ) {
    super($name)
  }

  apply(input: IParserInput): ParseResult<T> {
    return this.eval(input, arg => this.evalParserThunk().apply(arg))
  }

  isLazy(): this is LazyParser<T> {
    return true
  }

  named(name: string): LazyParser<T> {
    return new LazyParser<T>(this.$parserThunk, this.$parser, name)
  }

  /* Private methods */

  private evalParserThunk(): Parser<T> {
    if (this.$parser !== null) {
      return this.$parser
    }

    this.$parser = this.$parserThunk()

    while (this.$parser.isLazy()) {
      this.$parser = this.$parser.$parserThunk()
    }

    return this.$parser
  }
}

class StrictParser<T> extends Parser<T> {
  constructor(
    private $parserApplyFn: ParserApplyFn<T>,
    $name: string = 'UnNamedStrictParser'
  ) {
    super($name)
  }

  apply(input: IParserInput): ParseResult<T> {
    return this.eval(input, arg => this.$parserApplyFn(arg))
  }

  isLazy(): this is LazyParser<T> {
    return false
  }

  named(name: string): StrictParser<T> {
    return new StrictParser<T>(this.$parserApplyFn, name)
  }
}
