import {
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { ButtonSize } from '@app/components-legacy/Form/Wrappers/types'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { WidgetListPagination } from '@app/components-legacy/Widgets/WidgetList'
import { useAppTranslator, useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  onSearchHistoryPageChange,
  onTrailFlowSearchHistoryTimelineClear
} from './handlers'
import SearchHistoryConfirmDeleteModal from './SearchHistoryConfirmDeleteModal'

export interface IDrawerFooterProps {
  className?: string
}

const DrawerFooter: React.FC<IDrawerFooterProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'TrailFlow.SearchHistory']
  })

  const { storeTrailFlow } = useStores()

  const { storeSearchHistory } = storeTrailFlow
  const { storeInputSearch, storeDatePicker } = storeSearchHistory

  const isDeletingWithFilters =
    (storeInputSearch.hasSearchValue && !storeInputSearch.isDirty) ||
    storeDatePicker.datePickerRangeValueAsIsoStrings

  return (
    <Portal name={PlaceHolderName.drawerFooter}>
      <ContainerFooter>
        <DrawerCancelButton />

        <ContainerFlex
          name="DrawerFooterRight"
          alignItems="center"
          items={[
            <WidgetListPagination
              storeWidgetList={storeSearchHistory.storeWidgetList}
              onChange={onSearchHistoryPageChange(storeSearchHistory)}
            />,

            <FormWrapperButton
              labelledBy="clear"
              size={ButtonSize.large}
              buttonProps={{
                type: 'primary',
                onClick:
                  onTrailFlowSearchHistoryTimelineClear(storeSearchHistory),
                disabled: !storeSearchHistory.searchHistoryEntries.size
              }}
            >
              {isDeletingWithFilters
                ? translate('Clear selection')
                : translate('Clear search history')}
            </FormWrapperButton>
          ]}
          spaced
        />
      </ContainerFooter>

      <SearchHistoryConfirmDeleteModal />
    </Portal>
  )
}

export default observer(DrawerFooter)
