import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'

interface IIconIoEProps extends IIconProps {}

const IconIoE: React.FC<IIconIoEProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5126 14.3851H17.5126C17.9126 13.4226 18.1376 12.3601 18.1376 11.2601C18.1376 6.76006 14.5001 3.12256 10 3.12256C5.50005 3.12256 1.86255 6.76006 1.86255 11.2601C1.86255 12.3601 2.08755 13.4226 2.48755 14.3851H7.50005M7.50005 14.3851C7.50005 15.7702 8.62296 16.8931 10.0081 16.8931C11.3933 16.8931 12.5162 15.7702 12.5162 14.3851C12.5162 12.9999 11.3933 11.877 10.0081 11.877C8.62296 11.877 7.50005 12.9999 7.50005 14.3851ZM13.1125 8.14756L11.125 12.1476"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default IconIoE
