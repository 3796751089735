import { consts } from '@app/styles'
import { buildVariants } from '@design-system/libs/buildVariants'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '../Container'
import IconWarningTriangle from '../Icon/IconWarningTriangle'

export interface ILabelWarningProps {
  variant?: 'dark' | 'light'
  children: React.ReactNode
}

const StyledContainerFlex = styled(ContainerFlex)<
  Omit<ILabelWarningProps, 'children'>
>(props => {
  return buildVariants(props)
    .css({
      padding: consts.marginSmall,
      borderRadius: consts.borderRadiusRedesign
    })

    .variant('variant', props.variant ?? 'dark', {
      dark: {
        background: consts.colorBlueGray002,
        color: consts.colorWhite
      },

      light: { background: consts.colorBlueGray030 }
    })

    .end()
})

/**
 * Container with grey background + white color for important / warning message.
 */
export default function LabelWarning(props: ILabelWarningProps) {
  return (
    <StyledContainerFlex
      name="LabelWarning"
      alignItems="center"
      flexGap="small"
      variant={props.variant}
      items={[<IconWarningTriangle />, <div>{props.children}</div>]}
    />
  )
}
