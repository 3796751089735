import type { MaybeUndef } from '@@types/helpers'
import type { StoreIoE } from '@app/stores'

/**
 * When entering, load findings.
 */
export const handleFindingsOnLoad =
  (storeIoE: StoreIoE) => (checkerId: string) => async () => {
    const { storeIndicatorFindings } = storeIoE.storeIndicator

    // don't reload findings if already loaded
    if (storeIndicatorFindings.storeWidgetListFindings.entitiesAsArray.length) {
      return
    }

    storeIndicatorFindings.storeInputTenants.fetchTenants().then(() => {
      storeIndicatorFindings.storeInputTenants.selectAllTenants()
      return storeIndicatorFindings.fetchFindingsByCheckerId(checkerId, {
        page: 1
      })
    })
  }

/**
 * When validating the search, reload findings.
 */
export const handleFindingsOnInputSearchSubmit =
  (storeIoE: StoreIoE) => (checkerId: string) => () => {
    const { storeIndicatorFindings } = storeIoE.storeIndicator

    storeIndicatorFindings.storeWidgetListFindings.resetPagination()
    storeIndicatorFindings.fetchFindingsByCheckerId(
      checkerId,
      { page: 1 },
      storeIndicatorFindings.storeFlagsReloadFindings
    )
  }

/**
 * When changing the page, fetch findings of the new page.
 */
export const handleFindingsOnPageChange =
  (storeIoE: StoreIoE) =>
  (checkerId: string) =>
  (page: number, perPage: MaybeUndef<number>) => {
    const { storeIndicatorFindings } = storeIoE.storeIndicator

    storeIndicatorFindings.fetchFindingsByCheckerId(
      checkerId,
      {
        page,
        perPage
      },
      storeIndicatorFindings.storeFlagsReloadFindings
    )
  }
