import type { Maybe } from '@@types/helpers'
import type { RGBColor } from './types'

function componentToHex(numColor: number) {
  const hex = numColor.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

export function rgbToHex(r: number, g: number, b: number): string {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export function hexToRgb(hexColor: string): Maybe<RGBColor> {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor)

  if (!result) {
    return null
  }

  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ]
}
