import { Blade } from '@app/components-legacy/Blade'
import { BladeContext } from '@app/components-legacy/Blade/context'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import ConfigurationHealthCheckPage from '@app/pages/Management/SystemPage/ConfigurationPage/ConfigurationHealthCheckPage/ConfigurationHealthCheckPage'
import MenuSystem from '@app/pages/Management/SystemPage/MenuSystem'
import { AppRouteName } from '@app/routes'
import * as React from 'react'
import ConfigurationActivityLogsPage from './ConfigurationActivityLogsPage'
import ConfigurationAttackTypePage from './ConfigurationAttackTypePage'
import ConfigurationEmailsPage from './ConfigurationEmailsPage'
import ConfigurationLDAPPage from './ConfigurationLDAPPage'
import ConfigurationNavigation from './ConfigurationNavigation'
import ConfigurationRelayPage from './ConfigurationRelayPage'
import ConfigurationReportingCenterPage from './ConfigurationReportingCenterPage'
import ConfigurationSAMLPage from './ConfigurationSAMLPage'
import ConfigurationSecurityPage from './ConfigurationSecurityPage'
import ConfigurationSMTPPage from './ConfigurationSMTPPage'
import ConfigurationSyslogsPage from './ConfigurationSyslogsPage'
import ConfigurationTelemetryPage from './ConfigurationTelemetryPage'
import ConfigurationTenableAccountPage from './ConfigurationTenableAccountPage'
import ConfigurationTenableCloudPage from './ConfigurationTenableCloudPage'
import {
  canAccessToActivityLogsConfiguration,
  canAccessToDataCollectionConfiguration,
  canAccessToEmailAlertsConfiguration,
  canAccessToHealthCheckConfiguration,
  canAccessToIoAConfiguration,
  canAccessToLDAPProviderConfiguration,
  canAccessToRelayConfiguration,
  canAccessToReportingCenter,
  canAccessToSAMLProviderConfiguration,
  canAccessToSecurityConfiguration,
  canAccessToSMTPConfiguration,
  canAccessToSyslogAlertsConfiguration,
  canAccessToSystemConfiguration,
  canAccessToTelemetryConfiguration,
  canAccessToTenableProviderConfiguration
} from './permissions'

interface IConfigurationPageProps {}

const ConfigurationPage: React.FC<IConfigurationPageProps> = () => {
  return (
    <Blade
      root={true}
      routeDefinition={{
        routeName: AppRouteName.Management_System_Configuration,
        parameters: {}
      }}
      layoutContentGreyBackground
      layout={{
        name: 'twoColumns',
        responsive: true,
        menu: <MenuSystem />,
        leftColumn: <ConfigurationNavigation />,
        content: (
          // Reset context layoutContentGreyBackground value to not have a grey background in the drawers
          <BladeContext.Consumer>
            {bladeContext => (
              <BladeContext.Provider
                value={
                  bladeContext
                    ? {
                        entityBlade: bladeContext.entityBlade,
                        bladeContentProps: {
                          ...bladeContext.bladeContentProps,
                          layoutContentGreyBackground: undefined
                        }
                      }
                    : null
                }
              >
                <RbacRoutes
                  routes={[
                    {
                      routeDefinition: {
                        routeName:
                          AppRouteName.Management_System_Configuration_SMTP,
                        parameters: {}
                      },
                      component: ConfigurationSMTPPage,
                      rbacPermissionsCheck: () => canAccessToSMTPConfiguration
                    },
                    {
                      routeDefinition: {
                        routeName:
                          AppRouteName.Management_System_Configuration_ActivityLogs,
                        parameters: {}
                      },
                      component: ConfigurationActivityLogsPage,
                      rbacPermissionsCheck: () =>
                        canAccessToActivityLogsConfiguration
                    },
                    {
                      routeDefinition: {
                        routeName:
                          AppRouteName.Management_System_Configuration_Telemetry,
                        parameters: {}
                      },
                      component: ConfigurationTelemetryPage,
                      rbacPermissionsCheck: () =>
                        canAccessToTelemetryConfiguration
                    },
                    {
                      routeDefinition: {
                        routeName:
                          AppRouteName.Management_System_Configuration_ReportingCenter,
                        parameters: {}
                      },
                      component: ConfigurationReportingCenterPage,
                      rbacPermissionsCheck: () => canAccessToReportingCenter
                    },
                    {
                      routeDefinition: {
                        routeName:
                          AppRouteName.Management_System_Configuration_Security,
                        parameters: {}
                      },
                      component: ConfigurationSecurityPage,
                      rbacPermissionsCheck: () =>
                        canAccessToSecurityConfiguration
                    },
                    {
                      routeDefinition: {
                        routeName:
                          AppRouteName.Management_System_Configuration_TenableCloud,
                        parameters: {}
                      },
                      component: ConfigurationTenableCloudPage,
                      rbacPermissionsCheck: () =>
                        canAccessToDataCollectionConfiguration
                    },
                    {
                      routeDefinition: {
                        routeName:
                          AppRouteName.Management_System_Configuration_Relay,
                        parameters: {}
                      },
                      component: ConfigurationRelayPage,
                      rbacPermissionsCheck: () => canAccessToRelayConfiguration
                    },
                    {
                      routeDefinition: {
                        routeName:
                          AppRouteName.Management_System_Configuration_HealthCheck,
                        parameters: {}
                      },
                      component: ConfigurationHealthCheckPage,
                      rbacPermissionsCheck: () =>
                        canAccessToHealthCheckConfiguration
                    },
                    {
                      routeDefinition: {
                        routeName:
                          AppRouteName.Management_System_Configuration_IoA,
                        parameters: {}
                      },
                      component: ConfigurationAttackTypePage,
                      rbacPermissionsCheck: () => canAccessToIoAConfiguration
                    },
                    {
                      routeDefinition: {
                        routeName:
                          AppRouteName.Management_System_Configuration_Syslogs,
                        parameters: {}
                      },
                      component: ConfigurationSyslogsPage,
                      rbacPermissionsCheck: () =>
                        canAccessToSyslogAlertsConfiguration
                    },
                    {
                      routeDefinition: {
                        routeName:
                          AppRouteName.Management_System_Configuration_Emails,
                        parameters: {}
                      },
                      component: ConfigurationEmailsPage,
                      rbacPermissionsCheck: () =>
                        canAccessToEmailAlertsConfiguration
                    },
                    {
                      routeDefinition: {
                        routeName:
                          AppRouteName.Management_System_Configuration_TenableAccountConfiguration,
                        parameters: {}
                      },
                      component: ConfigurationTenableAccountPage,
                      rbacPermissionsCheck: () =>
                        canAccessToTenableProviderConfiguration
                    },
                    {
                      routeDefinition: {
                        routeName:
                          AppRouteName.Management_System_Configuration_LDAPConfiguration,
                        parameters: {}
                      },
                      component: ConfigurationLDAPPage,
                      rbacPermissionsCheck: () =>
                        canAccessToLDAPProviderConfiguration()
                    },
                    {
                      routeDefinition: {
                        routeName:
                          AppRouteName.Management_System_Configuration_SAMLConfiguration,
                        parameters: {}
                      },
                      component: ConfigurationSAMLPage,
                      rbacPermissionsCheck: () =>
                        canAccessToSAMLProviderConfiguration()
                    }
                  ]}
                  redirect
                />
              </BladeContext.Provider>
            )}
          </BladeContext.Consumer>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canAccessToSystemConfiguration}
    />
  )
}

export default ConfigurationPage
