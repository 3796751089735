import type { EntityDirectory } from '@app/entities'
import { createEntities, EntityInfrastructure } from '@app/entities'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import { getInfrastructuresColorScheme } from '@app/styles/colors/schemes'
import { CSSColors } from '@app/styles/colors/types'
import { ensureArray } from '@libs/ensureArray'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import { checkRbac } from '@libs/rbac/functions'
import { addSetValueToMap } from '@libs/setValueToMap'
import { indexEntitiesToMap } from '@productive-codebases/toolbox'
import type { QueryRbacInfrastructures } from '@server/graphql/queries/infrastructure'
import { queryRbacInfrastructures } from '@server/graphql/queries/infrastructure'
import type { Infrastructure, Maybe } from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import StoreFlags from './helpers/StoreFlags'
import StoreBase from './StoreBase'
import type StoreRoot from './StoreRoot'
import type {
  IStoreMultiInstanceOptions,
  WithOptionalInstanceName
} from './types'

export interface IStoreInfrastructuresOptions
  extends IStoreMultiInstanceOptions {
  emptySelectionAllowed?: boolean
}

// Treeview internal ID representation (infrastructureID|directoryID)
export type TreeId = string

export default class StoreInfrastructures extends StoreBase<IStoreInfrastructuresOptions> {
  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  public storeFlagsFetchInfrastructures = new StoreFlags(this.storeRoot)

  public storeDrawer = new StoreDrawer(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Components.InputInfrastructures']
  })

  private _queue: Set<{ resolve: () => void; reject: () => void }> = new Set()

  /* Observable */

  private $infrastructures = observable.map<number, EntityInfrastructure>()
  private $directories = observable.map<number, EntityDirectory>()
  private $selectedDirectories = observable.map<TreeId, boolean>()

  // ID of expanded infrastructures (Tree)
  private $expandedInfrastructures = observable.set<number>()

  constructor(
    storeRoot: StoreRoot,
    options: WithOptionalInstanceName<IStoreInfrastructuresOptions> = {}
  ) {
    super(storeRoot, {
      instanceName:
        options.instanceName ?? storeRoot.environment.getFirstInstanceName(),
      ...options
    })

    makeObservable(this)
  }

  /**
   * Fetch infrastructures.
   */
  fetchInfrastructures(): Promise<void> {
    if (this.storeFlagsFetchInfrastructures.isLoading) {
      // If this method is called more than one time, catch it and store a promise
      // resolve / reject in a queue so that we can resolve when the first fetch is over
      this.storeRoot.logger.debug(
        '[StoreInfrastructures] Infrastructures are already fetching, adding to the queue.'
      )
      return new Promise<void>((resolve, reject) => {
        this._queue.add({ resolve, reject })
      })
    }

    this.storeFlagsFetchInfrastructures.loading()

    return this.storeRoot
      .getGQLRequestor(this.options.instanceName)
      .query<QueryRbacInfrastructures>(queryRbacInfrastructures)
      .then(data => data.rbacInfrastructures)
      .then(infrastructures => {
        if (
          !checkRbac(
            this.storeRoot,
            this.storeFlagsFetchInfrastructures
          )(infrastructures)
        ) {
          throw new ForbiddenAccessError()
        }

        const infrastructureEntities = createEntities<
          Infrastructure,
          EntityInfrastructure
        >(EntityInfrastructure, infrastructures.node.node)

        // generate a color scheme for all infrastructures
        const colors = getInfrastructuresColorScheme(
          infrastructures.node.node.length
        )

        // set colors
        infrastructureEntities.forEach((infra, i) => {
          infra.setColor(colors[i])
        })

        this.setInfrastructures(infrastructureEntities)
        this.setDirectories()

        if (this._queue.size > 0) {
          // Resolve all queued call
          this._queue.forEach(item => {
            item.resolve()
          })
          // Empty the queue
          this._queue.clear()
        }

        this.storeFlagsFetchInfrastructures.success()
      })
      .catch((err: Error) => {
        handleStoreError(
          this.storeRoot,
          this.storeFlagsFetchInfrastructures
        )(err)

        if (this._queue.size > 0) {
          // Resolve all queued call
          this._queue.forEach(item => {
            item.reject()
          })

          // Empty the queue
          this._queue.clear()
        }
      })
  }

  /**
   * Return the color of a directory.
   */
  getInfrastructureColor(infrastuctureId: number): string {
    const infrastucture = this.$infrastructures.get(infrastuctureId)

    if (!infrastucture) {
      return CSSColors.Black
    }

    return infrastucture.color
  }

  /**
   * Return the color of a directory.
   */
  getDirectoryColor(directoryId: number): string {
    const directory = this.$directories.get(directoryId)

    if (!directory) {
      return CSSColors.Black
    }

    return directory.color
  }

  /**
   * Return the infrastructure from an ID.
   */
  getInfrastructureFromId(id: number): Maybe<EntityInfrastructure> {
    return this.$infrastructures.get(id) ?? null
  }

  /**
   * Return the infrastructure from a directory ID.
   */
  getInfrastructureFromDirectoryId(
    directoryId: number
  ): Maybe<EntityInfrastructure> {
    return (
      Array.from(this.$selectedDirectories.keys())
        .map(treeId => this.explodeTreeKey(treeId))
        .filter(
          ({ directoryId: currentDirectoryId }) =>
            currentDirectoryId === directoryId
        )
        .map(({ infrastructureId: currentInfrastructureId }) =>
          this.$infrastructures.get(currentInfrastructureId)
        )
        .pop() || null
    )
  }

  /**
   * Return the infrastructure ID from a directory ID.
   */
  getInfrastructureIdFromDirectoryId(directoryId: number): Maybe<number> {
    return (
      this.getInfrastructureFromDirectoryId(directoryId)?.getPropertyAsNumber(
        'id'
      ) || null
    )
  }

  /**
   * Return the directory from an ID.
   */
  getDirectoryFromId(id: number): Maybe<EntityDirectory> {
    return this.$directories.get(id) ?? null
  }

  /**
   * Return treeId selected for an infrastructure or for all infrastructures.
   */
  getSelectedDomainsTreeIds(infrastructureId?: number): string[] {
    return (
      Array.from(this.$selectedDirectories.entries())
        // filter on an infrastructureId or not
        .filter(
          ([treeId]) =>
            !isDefined(infrastructureId) ||
            this.explodeTreeKey(treeId).infrastructureId === infrastructureId
        )
        // filter on selected directories
        .filter(([, selected]) => selected === true)
        .map(([treeId]) => treeId)
    )
  }

  /**
   * Return treeId selected for an infrastructure or for all infrastructures,
   * filtered according to the search filter value.
   */
  getSearchedSelectedDomainsTreeIds(infrastructureId?: number): string[] {
    return (
      Array.from(this.$selectedDirectories.entries())
        // filter on an infrastructureId or not
        .filter(
          ([treeId]) =>
            !infrastructureId ||
            this.explodeTreeKey(treeId).infrastructureId === infrastructureId
        )
        // filter on selected directories
        .filter(([, selected]) => selected === true)
        .map(([treeId]) => treeId)
        // filter on the directory name
        .filter(treeId => {
          const { directoryId } = this.explodeTreeKey(treeId)
          const directory = this.$directories.get(directoryId)

          if (!directory) {
            return false
          }

          return this.storeInputSearch.transformedSearchValueAsRegexp.test(
            directory.getPropertyAsString('name')
          )
        })
    )
  }

  /**
   * Return all directoryIds for an infrastructure or for all infrastructures.
   */
  getDirectoryIds(infrastructureId?: number): number[] {
    return this.getDomainsTreeIds(infrastructureId)
      .map(treeId => this.explodeTreeKey(treeId).directoryId)
      .filter(isDefined)
  }

  /**
   * Return selected directoryIds for an infrastructure or for all infrastructures.
   */
  getSelectedDirectoryIds(infrastructureId?: number): number[] {
    return this.getSelectedDomainsTreeIds(infrastructureId)
      .map(treeId => this.explodeTreeKey(treeId).directoryId)
      .filter(isDefined)
  }

  /**
   * Return the checked status of an infrastructure.
   */
  isInfrastructureSelected(infrastructureId: number): Maybe<boolean> {
    const directoryIds = Array.from(this.$selectedDirectories.entries()).filter(
      ([treeId]) =>
        this.explodeTreeKey(treeId).infrastructureId === infrastructureId
    )

    const allSelected = directoryIds.every(([, selected]) => selected === true)

    if (allSelected === true) {
      return true
    }

    const noneSelected = directoryIds.every(
      ([, selected]) => selected === false
    )

    if (noneSelected === true) {
      return false
    }

    // indeterminate status
    return null
  }

  /**
   * Build the internal key used in the tree structure.
   */
  buildTreeKey(infrastructureId: number, directoryId: number): string {
    return [String(infrastructureId), String(directoryId)].join('|')
  }

  /**
   * Explode the internal key of the tree structure.
   */
  explodeTreeKey(treeId: string): {
    infrastructureId: number
    directoryId: number
  } {
    const [infrastructureId, directoryId] = treeId.split('|')

    return {
      infrastructureId: Number(infrastructureId),
      directoryId: Number(directoryId)
    }
  }

  /**
   * Return selected directories for an infrastructure or for all infrastructures.
   */
  selectedDirectories(infrastructureId?: number): EntityDirectory[] {
    return Array.from(this.$selectedDirectories.entries())
      .filter(([, selected]) => selected === true)
      .map(([key]) =>
        this.$directories.get(this.explodeTreeKey(key).directoryId)
      )
      .filter(isDefined)
      .filter(directory => {
        return infrastructureId
          ? directory?.infrastructureId === infrastructureId
          : true
      })
  }

  /**
   * Return domains indexed by infrastructures.
   */
  getDirectoriesByInfractructure(
    options = { onlySelected: false }
  ): Map<EntityInfrastructure, Set<EntityDirectory>> {
    const directoriesByInfrastructure: Map<
      EntityInfrastructure,
      Set<EntityDirectory>
    > = new Map()

    Array.from(this.infrastructures.values()).forEach(infrastructure => {
      infrastructure.getDirectories().forEach(directory => {
        // if options.onlySelected, don't include not selected directory
        if (
          options.onlySelected &&
          directory.id &&
          !this.selectedDirectoryIds.includes(directory.id)
        ) {
          return
        }

        addSetValueToMap(directoriesByInfrastructure, infrastructure, directory)
      })
    })

    return directoriesByInfrastructure
  }

  /* Private */

  /**
   * Return treeId for an infrastructure.
   */
  private getDomainsTreeIds(infrastructureId?: number): string[] {
    return (
      Array.from(this.$selectedDirectories.entries())
        // filter on an infrastructureId or not
        .filter(([treeId]) => {
          if (!infrastructureId) {
            return true
          }
          return (
            this.explodeTreeKey(treeId).infrastructureId === infrastructureId
          )
        })
        .map(([treeId]) => treeId)
    )
  }

  /* Actions */

  /**
   * Reset the store.
   */
  @action
  reset(): this {
    this.$infrastructures.clear()

    this.storeInputSearch.reset()
    this.storeFlagsFetchInfrastructures.reset()
    this.storeDrawer.reset()

    return this
  }

  /**
   * Set infrastructures entities.
   */
  @action
  setInfrastructures(infrastructures: EntityInfrastructure[]): this {
    this.$infrastructures.clear()

    // remove the current selection of directories
    this.$selectedDirectories.clear()

    const allTreeIds: TreeId[] = []

    infrastructures.forEach(infrastructure => {
      const infrastructureId = infrastructure.getPropertyAsNumber('id')

      this.$infrastructures.set(infrastructureId, infrastructure)

      infrastructure.getDirectories().forEach(directory => {
        const treeId = this.buildTreeKey(
          infrastructure.getPropertyAsNumber('id'),
          directory.getPropertyAsNumber('id')
        )

        allTreeIds.push(treeId)

        // retrieve the current selected status or select it by default
        const isSelectedValue = this.$selectedDirectories.get(treeId)

        const isSelectedBoolean = isDefined(isSelectedValue)
          ? isSelectedValue
          : true

        this.$selectedDirectories.set(treeId, isSelectedBoolean)
      })
    })

    // remove all selection that can be "deprecated" after an infra/dir deletion
    Array.from(this.$selectedDirectories.keys()).forEach(treeId => {
      if (allTreeIds.indexOf(treeId) === -1) {
        this.$selectedDirectories.delete(treeId)
      }
    })

    // don't forget to reactualize the directories from the new infrastructures
    this.setDirectories()

    return this
  }

  /**
   * Set directories entities.
   */
  @action
  setDirectories(): this {
    this.$directories.clear()

    this.infrastructures.forEach(insfrastructure => {
      insfrastructure.getDirectories().forEach(directory => {
        if (!directory.id) {
          return
        }

        this.$directories.set(directory.id, directory)
      })
    })

    return this
  }

  /**
   * Select or unselect the directories of an infrastructure.
   */
  @action
  selectInfrastructure(infrastructureId: number, selected: boolean): this {
    const infrastructure = this.$infrastructures.get(infrastructureId)

    if (!infrastructure) {
      return this
    }

    infrastructure
      .getDirectories()
      .map(directory =>
        this.buildTreeKey(
          infrastructure.getPropertyAsNumber('id'),
          directory.getPropertyAsNumber('id')
        )
      )
      .forEach(treeId => this.$selectedDirectories.set(treeId, selected))

    return this
  }

  /**
   * Reverse the selection of the directories of an infrastructure.
   */
  @action
  toggleSelectInfrastructure(infrastructureId: number): this {
    const selectedDirectoryIds =
      this.getSelectedDomainsTreeIds(infrastructureId)
    const infrastructureDirectoryIds = this.getDirectoryIds(infrastructureId)

    // unselect if the infrastructure is already selected
    const select =
      selectedDirectoryIds.length !== infrastructureDirectoryIds.length

    return this.selectInfrastructure(infrastructureId, select)
  }

  /**
   * Select or unselect the directories of an infrastructure from treeIds.
   */
  @action
  toggleSelectInfrastructureFromTreeIds(
    infrastructureId: number,
    treeIds: TreeId[]
  ): this {
    Array.from(this.$selectedDirectories.entries())
      // filter on an infrastructureId or not
      .filter(
        ([treeId]) =>
          !infrastructureId ||
          this.explodeTreeKey(treeId).infrastructureId === infrastructureId
      )
      .forEach(([treeId]) => {
        const selected = treeIds.indexOf(treeId) !== -1
        this.$selectedDirectories.set(treeId, selected)
      })

    return this
  }

  /**
   * Replace all the directories selection.
   */
  @action
  replaceSelectedDirectories(selectedDirectories: Map<TreeId, boolean>): this {
    Array.from(this.$selectedDirectories.keys()).forEach(key => {
      const selected = selectedDirectories.get(key)

      // if undefined, keep the existing value
      if (!isDefined(selected)) {
        return
      }

      this.$selectedDirectories.set(key, selected)
    })

    return this
  }

  /**
   * Select all directories or only the directories that
   * match if the search value is defined.
   */
  @action
  selectAllDirectories(): this {
    if (this.storeInputSearch.hasSearchValue) {
      this.selectDirectories(this.searchedDirectoryIds)
      return this
    }

    this.selectDirectories(this.directoryIds)

    return this
  }

  /**
   * Unselect all directories.
   */
  @action
  unselectAllDirectories(): this {
    Array.from(this.$selectedDirectories.keys()).forEach(key => {
      this.$selectedDirectories.set(key, false)
    })

    return this
  }

  /**
   * Select or unselect some directories.
   */
  @action
  selectDirectories(directoryIds: number[]): this {
    Array.from(this.$selectedDirectories.keys())
      .map(treeId => this.explodeTreeKey(treeId))
      .forEach(({ directoryId, infrastructureId }) => {
        const treeId = this.buildTreeKey(infrastructureId, directoryId)
        const isSelected = directoryIds.indexOf(directoryId) !== -1

        this.$selectedDirectories.set(treeId, isSelected)
      })

    return this
  }

  /**
   * Toggle the selection of one directory.
   */
  @action
  toggleSelectDirectory(directoryId: number): this {
    const infrastructure = this.getInfrastructureFromDirectoryId(directoryId)

    if (!infrastructure) {
      return this
    }

    const treeId = this.buildTreeKey(
      infrastructure.getPropertyAsNumber('id'),
      directoryId
    )

    this.$selectedDirectories.set(
      treeId,
      !this.$selectedDirectories.get(treeId)
    )

    return this
  }

  /**
   * Expand or collapsed an infrastructure.
   */
  @action
  toggleExpandInfrastructure(infrastructureIds: number[]): this {
    this.$expandedInfrastructures.replace(infrastructureIds)

    return this
  }

  /**
   * Expand or collapsed all infrastructures.
   */
  @action
  expandAllInfrastructures(): this {
    const infrastructureIds = Array.from(this.$infrastructures.keys())
    this.$expandedInfrastructures.replace(infrastructureIds)

    return this
  }

  /**
   * Collapse all infrastructures.
   */
  @action
  collapseAllInfrastructures(): this {
    this.$expandedInfrastructures.clear()

    return this
  }

  /* Computed */

  /**
   * Return true if submit is allowed on empty selection
   */
  @computed
  get isDrawerSelectionSubmitEnabled(): boolean {
    return (
      this.selectedDirectoryIds.length > 0 ||
      (this.options.emptySelectionAllowed ?? false)
    )
  }

  /**
   * Return all infrastructures as a map.
   */
  @computed
  get infrastructures(): Map<number, EntityInfrastructure> {
    return toJS(this.$infrastructures)
  }

  /**
   * Return all directories as a map.
   */
  @computed
  get directories(): Map<number, EntityDirectory> {
    return toJS(this.$directories)
  }

  /**
   * Return all directories as a map.
   */
  @computed
  get directoriesByUuid(): Map<string, EntityDirectory> {
    return indexEntitiesToMap<EntityDirectory, string>(
      Array.from(this.directories.values()),
      'directoryUuid'
    )
  }

  /**
   * Return selected directories as map.
   */
  @computed
  get selectedDirectoriesMap(): Map<TreeId, boolean> {
    return toJS(this.$selectedDirectories)
  }

  /**
   * Return selected directoryIds.
   */
  @computed
  get selectedDirectoryIds(): number[] {
    return Array.from(this.$selectedDirectories.entries())
      .filter(([, selected]) => selected === true)
      .map(([key]) => this.explodeTreeKey(key).directoryId)
  }

  /**
   * Return selected directoryUuids.
   */
  @computed
  get selectedDirectoryUuids(): string[] {
    return this.selectedDirectories()
      .map(directory => directory.directoryUuid)
      .filter(isDefined)
  }

  /**
   * Return true if there is at least one directory.
   */
  @computed
  get hasDirectories(): boolean {
    return (
      Array.from(this.$infrastructures.values()).filter(
        infrastructure => ensureArray(infrastructure.directories).length
      ).length > 0
    )
  }

  /**
   * Return all the directory ids.
   */
  @computed
  get directoryIds(): number[] {
    return Array.from(this.$directories.keys())
  }

  /**
   * Return all the directory ids that match the current search value.
   */
  @computed
  get searchedDirectoryIds(): number[] {
    return Array.from(this.$directories.entries())
      .map(([directoryId, directory]) => {
        return {
          infrastructure: this.getInfrastructureFromDirectoryId(directoryId),
          directory
        }
      })
      .filter(({ infrastructure, directory }) => {
        if (!infrastructure) {
          return false
        }

        const regexp = this.storeInputSearch.transformedSearchValueAsRegexp

        return (
          regexp.test(infrastructure.getPropertyAsString('name')) ||
          regexp.test(directory.getPropertyAsString('name'))
        )
      })
      .map(({ directory }) => directory.getPropertyAsNumber('id'))
  }

  /**
   * Return the expanded infrastructures (of the tree).
   */
  @computed
  get expandedInfrastructures(): Set<number> {
    return toJS(this.$expandedInfrastructures)
  }

  /**
   * Return true if some infrastructures are expanded.
   */
  @computed
  get isSomeInfrastructuresExpanded(): boolean {
    if (this.isAllInfrastructuresExpanded) {
      return false
    }

    return this.$expandedInfrastructures.size > 0
  }

  /**
   * Return true if all infrastructures are expanded.
   */
  @computed
  get isAllInfrastructuresExpanded(): boolean {
    return this.$infrastructures.size === this.$expandedInfrastructures.size
  }

  /**
   * Return true if some domains are selected.
   */
  @computed
  get isSomeDomainsSelected(): boolean {
    if (this.isAllDomainsSelected) {
      return false
    }

    return Array.from(this.$selectedDirectories.values()).some(
      selected => selected === true
    )
  }

  /**
   * Return true if all or searches domains are selected.
   */
  @computed
  get isAllDomainsSelected(): boolean {
    if (this.storeInputSearch.hasSearchValue) {
      return Array.from(this.selectedDirectoriesMap.entries())
        .filter(([key]) => {
          return (
            this.searchedDirectoryIds.indexOf(
              this.explodeTreeKey(key).directoryId
            ) !== -1
          )
        })
        .every(([, selected]) => selected === true)
    }

    return Array.from(this.$selectedDirectories.values()).every(
      selected => selected === true
    )
  }
}
