import type { MaybeUndef } from '@@types/helpers'
import type { StoreHealthCheck } from '@app/stores'
import { InputHealthChecksMode } from '@app/stores/StoreInputHealthChecks/types'

function fetchHealthChecksPlatformStatusWithCurrentFilters(
  storeHealthCheck: StoreHealthCheck
) {
  storeHealthCheck.fetchHealthChecksPlatformStatus({
    healthCheckNames:
      storeHealthCheck.storeInputHealthChecks
        .selectedHealthChecksTemplatesNamesIds,
    showHealthy: storeHealthCheck.showHealthy,
    healthChecksPage: 1,
    healthChecksPerPage: 20
  })

  storeHealthCheck.storeRoot.stores.storeHealthCheckGlobalStatus.refreshGlobalStatusManually()
}

export const handleHealthChecksPlatformStatusOnLoad =
  (storeHealthCheck: StoreHealthCheck) => async (): Promise<void> => {
    storeHealthCheck.storeInputHealthChecks.setMode(
      InputHealthChecksMode.platformStatus
    )

    storeHealthCheck.storeInputHealthChecks.selectAllHealthChecksTemplatesNames()

    fetchHealthChecksPlatformStatusWithCurrentFilters(storeHealthCheck)
  }

export const handleHealthChecksPlatformStatusPaginationChange =
  (storeHealthCheck: StoreHealthCheck) =>
  (healthChecksPage: number, healthChecksPerPage: MaybeUndef<number>): void => {
    storeHealthCheck.fetchHealthChecksPlatformStatus({
      healthCheckNames:
        storeHealthCheck.storeInputHealthChecks
          .selectedHealthChecksTemplatesNamesIds,
      showHealthy: storeHealthCheck.showHealthy,
      healthChecksPage,
      healthChecksPerPage
    })
  }

export const handleHealthChecksPlatformStatusOnUnload =
  (storeHealthCheck: StoreHealthCheck) => (): void => {
    storeHealthCheck.resetHealthCheckPlatformStatus()
  }

export const handleHealthChecksPlatformStatusShowHealthyChange =
  (storeHealthCheck: StoreHealthCheck) => (showHealthy: boolean) => {
    storeHealthCheck.setShowHealthy(showHealthy)
    fetchHealthChecksPlatformStatusWithCurrentFilters(storeHealthCheck)
  }

export const handleHealthChecksPlatformStatusChecksChange =
  (storeHealthCheck: StoreHealthCheck) => () => {
    fetchHealthChecksPlatformStatusWithCurrentFilters(storeHealthCheck)
  }

export const handleHealthChecksPlatformStatusRefresh =
  (storeHealthCheck: StoreHealthCheck) => () => {
    storeHealthCheck.fetchHealthChecksPlatformStatus({
      healthCheckNames:
        storeHealthCheck.storeInputHealthChecks
          .selectedHealthChecksTemplatesNamesIds,
      showHealthy: storeHealthCheck.showHealthy,
      healthChecksPage:
        storeHealthCheck.storeWidgetListHealthChecksPlatformStatus
          .paginationPage,
      healthChecksPerPage:
        storeHealthCheck.storeWidgetListHealthChecksPlatformStatus.rowsPerPage
    })

    storeHealthCheck.storeRoot.stores.storeHealthCheckGlobalStatus.refreshGlobalStatusManually()
  }

export const handleHealthCheckPlatformStatusDetailsOnLoad =
  (storeHealthCheck: StoreHealthCheck, healthCheckName: string) =>
  async (): Promise<void> => {
    await storeHealthCheck.storeRoot.stores.storeInfrastructures.fetchInfrastructures()

    storeHealthCheck.fetchHealthChecksPlatformStatusDetails({
      healthCheckName
    })
  }

export const handleHealthCheckPlatformStatusDetailsOnUnload =
  (storeHealthCheck: StoreHealthCheck) => (): void => {
    storeHealthCheck.resetPlatformStatusDetails()
  }
