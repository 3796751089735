import { ContainerFlex } from '@app/components/Container'
import { Card } from '@app/components-legacy/Card'
import type EntityHealthCheckDomainStatusReason from '@app/entities/EntityHealthCheckDomainStatusReason'
import { HealthCheckReasonBody } from '@app/pages/HealthCheck/HealthCheckDetails/Reason/HealthCheckReasonBody'
import { HealthCheckReasonHeader } from '@app/pages/HealthCheck/HealthCheckDetails/Reason/HealthCheckReasonHeader'
import { consts } from '@app/styles'
import { HealthCheckStatus } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  padding: ${consts.paddingDefault} ${consts.paddingMedium};
  flex-grow: 1;
`

interface IHealthCheckReasonProps {
  healthCheckName: string
  reason: EntityHealthCheckDomainStatusReason
}

export function HealthCheckReason(props: IHealthCheckReasonProps) {
  const [expanded, setExpanded] = React.useState(
    props.reason.status === HealthCheckStatus.Warning ||
      props.reason.status === HealthCheckStatus.Failure
  )

  return (
    <StyledCard>
      <ContainerFlex
        name="HealthCheckReason"
        direction="column"
        items={[
          <HealthCheckReasonHeader
            healthCheckName={props.healthCheckName}
            reason={props.reason}
            expanded={expanded}
            onExpand={() => setExpanded(!expanded)}
          />,

          expanded && (
            <HealthCheckReasonBody
              healthCheckName={props.healthCheckName}
              reason={props.reason}
            />
          )
        ]}
        flexGap="small"
      />
    </StyledCard>
  )
}
