import { ContainerFlex } from '@app/components-legacy/Container'
import { AppMenu } from '@app/components-legacy/Menu'
import type EntityAttack from '@app/entities/EntityAttack'
import type StoreMenu from '@app/stores/helpers/StoreMenu'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import IoAAttacksDescriptionTabContent from './IoAAttacksDescriptionTabContent'
import IoAAttacksDescriptionTabDescription from './IoAAttacksDescriptionTabDescription'
import IoAAttacksDescriptionTabResources from './IoAAttacksDescriptionTabResources'
import IoAAttacksYaraRulesTab from './IoAAttacksYaraRulesTab'
import { AttacksDescriptionTabMenuKey } from './types'

interface IIoAAttacksDescriptionTabProps {
  className?: string
  attackEntity: EntityAttack
  storeMenu: StoreMenu<IMenuEntry<AttacksDescriptionTabMenuKey>>
}

const IoAAttacksDescriptionTab: React.FC<
  IIoAAttacksDescriptionTabProps
> = props => {
  const showDescriptionTab =
    props.storeMenu.selectedMenuKey ===
    AttacksDescriptionTabMenuKey.MenuAttackDescription

  const getContent = () => {
    if (showDescriptionTab) {
      return (
        <IoAAttacksDescriptionTabContent
          showDescriptionTab={showDescriptionTab}
        >
          <ContainerFlex
            name="IoAAttacksDescriptionTabContent"
            items={[
              <IoAAttacksDescriptionTabDescription
                attackEntity={props.attackEntity}
              />,

              <IoAAttacksDescriptionTabResources
                attackEntity={props.attackEntity}
              />
            ]}
            spaced
          />
        </IoAAttacksDescriptionTabContent>
      )
    }

    return (
      <IoAAttacksDescriptionTabContent>
        <ContainerFlex
          name="IoAAttacksDescriptionTabContent"
          itemsFlexGrow={[1]}
          items={<IoAAttacksYaraRulesTab attackEntity={props.attackEntity} />}
        />
      </IoAAttacksDescriptionTabContent>
    )
  }

  return (
    <ContainerFlex
      name="IoAAttacksDescriptionTab"
      direction="column"
      items={[<AppMenu storeMenu={props.storeMenu} />, getContent()]}
      spaced
    />
  )
}

export default observer(IoAAttacksDescriptionTab)
