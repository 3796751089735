import {
  ContainerDividerFilter,
  ContainerFlex
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import {
  InputExpression,
  InputInfrastructures
} from '@app/components-legacy/Input'
import InputDatePickerRange from '@app/components-legacy/Input/InputDatePickerRange'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import {
  onTrailFlowExpressionCloseIconClick,
  onTrailFlowExpressionSubmit,
  onTrailFlowInputInfrastructuresChange,
  onTrailFlowSearchFocus,
  onTrailFlowSearchSubmit
} from './handlers'
import TableHeaderLiveButton from './TableHeaderLiveButton'

export interface ITableFiltersProps {
  className?: string
}

const TableFilters: React.FC<ITableFiltersProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Buttons'] })

  const { storeInfrastructures, storeTrailFlow } = useStores()

  const {
    storeInputExpression,
    storeEvents: { storeDatePicker }
  } = storeTrailFlow

  return (
    <ContainerFlex
      name="TableFilters"
      className={props.className}
      direction="column"
      items={[
        <ContainerFlex
          name="TableFiltersFormControls"
          alignItems="center"
          justifyContent="space-between"
          itemsFlexShrink={[1, 0, 0, 0, 0, 0]}
          items={[
            <InputExpression
              onValidate={onTrailFlowExpressionSubmit(storeTrailFlow)}
              onFocus={onTrailFlowSearchFocus(
                storeTrailFlow.storeSearchHistory,
                storeTrailFlow.storeSearchBookmarks
              )}
              onClearIconClick={onTrailFlowExpressionCloseIconClick(
                storeTrailFlow
              )}
              storeInputExpression={storeInputExpression}
              labelledBy="trailflow-search-input"
            />,

            <InputDatePickerRange
              storeDatePicker={storeDatePicker}
              labelledBy="main-trailflow-datePicker"
              unitOfTime="seconds"
              reducedFontSize
              rangePickerProps={{
                showTime: true
              }}
            />,

            <InputInfrastructures
              storeInfrastructures={storeInfrastructures}
              onValidate={onTrailFlowInputInfrastructuresChange(storeTrailFlow)}
            />,

            <FormWrapperButton
              labelledBy="search"
              buttonProps={{
                type: 'primary',
                onClick: onTrailFlowSearchSubmit(storeTrailFlow)
              }}
            >
              {translate('Search')}
            </FormWrapperButton>,

            <ContainerDividerFilter />,

            <TableHeaderLiveButton />
          ]}
          spaced
          spaceWidth="small"
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default TableFilters
