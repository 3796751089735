import {
  ContainerFlex,
  ContainerIcon,
  ContainerRbac
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import {
  IconCheckOutlined,
  IconDeleteOutlined,
  IconEditOutlined,
  IconEyeOutlined,
  IconUndoOutlined
} from '@app/components-legacy/Icon/IconAntd'
import IconBlank from '@app/components-legacy/Icon/IconBlank'
import { LabelAlt } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import {
  WidgetListActionButtons,
  WidgetListActionsHeader,
  WidgetListCountItems,
  WidgetListTable,
  WidgetListTableActionIcons
} from '@app/components-legacy/Widgets/WidgetList'
import type { EntityProfile } from '@app/entities'
import type { IDataRowProfile } from '@app/entities/EntityProfile'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { Alert } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  canCreateProfile,
  canDeleteProfile,
  canEditProfile,
  canReadProfile
} from '../permissions'
import {
  handleProfileCommitOnClick,
  handleProfileCreateButtonOnClick,
  handleProfileDeleteIconOnClick,
  handleProfileEditIconOnClick,
  handleProfileUnstageOnClick
} from './handlers'
import ModalMaxProfiles from './ModalMaxProfiles'
import ModalProfileBeforeCommit from './ModalProfileBeforeCommit'

interface IProfilesListProps {}

const ProfilesList: React.FC<IProfilesListProps> = () => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Generic',
      'Login.ProviderTypes',
      'Management.Accounts.Profiles.List'
    ]
  })

  const { storeRbac, storeAuthentication, storeManagementProfiles } =
    useStores()

  /**
   * Return the icons if each row.
   */
  function getIcons(profileEntity: EntityProfile): React.ReactNodeArray {
    const profileId = profileEntity.getPropertyAsNumber('id')
    const isDirty = profileEntity.getPropertyAsBoolean('dirty')

    const nodes = []

    if (profileEntity.isReadableOnly(storeRbac)) {
      nodes.push(
        <ContainerRbac
          rbacCapabilityCheck={canReadProfile(profileId)}
          render={({ isGranted }) => {
            return isGranted ? (
              <ContainerIcon
                labelledBy="seeProfile"
                iconComponent={IconEyeOutlined}
                iconProps={{
                  type: 'eye',
                  onClick: handleProfileEditIconOnClick(
                    storeManagementProfiles
                  )(profileId)
                }}
                tooltipProps={{
                  title: translate('See profile')
                }}
              />
            ) : (
              <IconBlank />
            )
          }}
        />
      )

      return nodes
    }

    if (isDirty) {
      nodes.push(
        ...[
          <ContainerRbac
            rbacCapabilityCheck={canEditProfile(profileId)}
            render={({ isGranted }) => {
              return isGranted ? (
                <ContainerIcon
                  labelledBy={`revertProfile-${profileId}`}
                  iconComponent={IconUndoOutlined}
                  iconProps={{
                    onClick: handleProfileUnstageOnClick(
                      storeManagementProfiles
                    )(profileEntity)
                  }}
                  tooltipProps={{
                    title: translate('Revert changes')
                  }}
                />
              ) : (
                <IconBlank />
              )
            }}
          />,

          <ContainerRbac
            rbacCapabilityCheck={canEditProfile(profileId)}
            render={({ isGranted }) => {
              return isGranted ? (
                <ContainerIcon
                  labelledBy={`commitProfile-${profileId}`}
                  iconComponent={IconCheckOutlined}
                  iconProps={{
                    type: 'check',
                    onClick: handleProfileCommitOnClick(
                      storeManagementProfiles
                    )(
                      profileEntity.getPropertyAsNumber('id'),
                      profileEntity.getPropertyAsString('name')
                    )
                  }}
                  tooltipProps={{
                    title: translate('Commit changes')
                  }}
                />
              ) : (
                <IconBlank />
              )
            }}
          />
        ]
      )
    }

    const isCurrentProfile = storeAuthentication.currentProfileId === profileId

    nodes.push(
      ...[
        <ContainerRbac
          rbacCapabilityCheck={canEditProfile(profileId)}
          render={({ isGranted }) => {
            return isGranted ? (
              <ContainerIcon
                labelledBy={`customizeProfile-${profileId}`}
                iconComponent={IconEditOutlined}
                iconProps={{
                  type: 'edit',
                  onClick: handleProfileEditIconOnClick(
                    storeManagementProfiles
                  )(profileId)
                }}
                tooltipProps={{
                  title: translate('Customize profile')
                }}
              />
            ) : (
              <IconBlank />
            )
          }}
        />,

        <ContainerRbac
          rbacCapabilityCheck={canDeleteProfile(profileId)}
          render={({ isGranted }) => {
            return isGranted ? (
              <ContainerIcon
                labelledBy={`deleteProfile-${profileId}`}
                iconComponent={IconDeleteOutlined}
                iconProps={{
                  disabled: isCurrentProfile,
                  onClick: handleProfileDeleteIconOnClick(
                    storeManagementProfiles
                  )(profileId, isCurrentProfile)
                }}
                tooltipProps={{
                  title: isCurrentProfile
                    ? translate('You cant delete the current profile')
                    : null,
                  placement: 'left'
                }}
              />
            ) : (
              <IconBlank />
            )
          }}
        />
      ]
    )

    return nodes
  }

  const hasAnyProfileDirty = Array.from(
    storeManagementProfiles.profiles.values()
  ).some(profile => profile.getPropertyAsBoolean('dirty'))

  return (
    <>
      <ModalMaxProfiles />
      <ModalProfileBeforeCommit />

      {hasAnyProfileDirty && (
        <Alert
          type="warning"
          message={translate('Some profiles have some pending configurations')}
          showIcon
        />
      )}

      <ContainerFlex
        name={ProfilesList.name}
        direction="column"
        items={[
          <WidgetListActionsHeader>
            <WidgetListCountItems
              storeWidgetList={storeManagementProfiles.storeWidgetList}
            />

            <WidgetListActionButtons>
              <ContainerRbac rbacCapabilityCheck={canCreateProfile}>
                <FormWrapperButton
                  labelledBy="createProfile"
                  buttonProps={{
                    type: 'primary',
                    onClick: handleProfileCreateButtonOnClick(
                      storeManagementProfiles
                    )
                  }}
                >
                  {translate('Create a profile')}
                </FormWrapperButton>
              </ContainerRbac>
            </WidgetListActionButtons>
          </WidgetListActionsHeader>,

          <WidgetListTable<EntityProfile, IDataRowProfile>
            translate={translate}
            hiddenColumnsKeys={['id']}
            storeWidgetList={storeManagementProfiles.storeWidgetList}
            cellsRenderFn={{
              dirty: isDirty => {
                const labelledBy = 'cellCustomization'
                return isDirty ? (
                  <LabelAlt
                    labelledBy={labelledBy}
                    variant={LabelAltVariant.Warning}
                  >
                    {translate('Pending')}
                  </LabelAlt>
                ) : (
                  <LabelAlt labelledBy={labelledBy}>
                    {translate('Done')}
                  </LabelAlt>
                )
              },
              isSecurityAnalysisStarted: isSecurityAnalysisStarted => {
                const labelledBy = 'cellSecurityAnalysis'
                return isSecurityAnalysisStarted ? (
                  <LabelAlt labelledBy={labelledBy}>
                    {translate('Started')}
                  </LabelAlt>
                ) : (
                  <LabelAlt
                    labelledBy={labelledBy}
                    variant={LabelAltVariant.Warning}
                  >
                    {translate('Waiting')}
                  </LabelAlt>
                )
              }
            }}
            actionsButtonsRenderFn={profileId => {
              const profile = storeManagementProfiles.profiles.get(
                Number(profileId)
              )

              if (!profile) {
                return null
              }

              return <WidgetListTableActionIcons icons={getIcons(profile)} />
            }}
          />
        ]}
        spaced
      />
    </>
  )
}

export default observer(ProfilesList)
