import { forwardProps } from '@design-system/libs/forwardProps'
import type {
  IForwardedProps,
  ReactHTMLAttributes
} from '@design-system/libs/forwardProps/types'
import * as React from 'react'
import type { IIconProps } from './types'

export interface IIconExternalLinkProps
  extends IIconProps,
    IForwardedProps<ReactHTMLAttributes<SVGSVGElement>> {
  size?: number
  height?: 'icon'
}

export default function IconExternalLink(props: IIconExternalLinkProps) {
  const size = props.size ?? '1em'

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -256 1850 1850"
      width={size}
      height={size}
      {...forwardProps(props)}
    >
      <path
        d="M1408 608V288q0-119-84.5-203.5T1120 0H288Q169 0 84.5 84.5T0 288v832q0 119 84.5 203.5T288 1408h704q14 0 23-9t9-23v-64q0-14-9-23t-23-9H288q-66 0-113-47t-47-113V288q0-66 47-113t113-47h832q66 0 113 47t47 113v320q0 14 9 23t23 9h64q14 0 23-9t9-23zm384 864V960q0-26-19-45t-45-19q-26 0-45 19l-176 176-652-652q-10-10-23-10t-23 10L695 553q-10 10-10 23t10 23l652 652-176 176q-19 19-19 45t19 45q19 19 45 19h512q26 0 45-19t19-45z"
        style={{
          fill: 'currentColor'
        }}
        transform="matrix(1 0 0 -1 30.373 1426.95)"
      />
    </svg>
  )
}
