import { updateIoABoardUrlWithFilters } from '@app/pages/IoA/IoABoardPage/functions'
import type { StoreIoA } from '@app/stores'
import type StoreBoard from '@app/stores/IoA/StoreBoard'
import type { AttacksSummaryPeriod } from '@server/graphql/typeDefs/types'
import type { RadioChangeEvent } from 'antd/lib/radio'
import * as moment from 'moment'
import type { PickerPanelSharedProps } from 'rc-picker/lib/PickerPanel'

/**
 * When selecting a date via the DatePicker,
 * set the date and reload attacks summary.
 */
export const handleTimelineDatePickerSelection = (storeIoA: StoreIoA) => {
  return (date: PickerPanelSharedProps<any>['value']) => {
    const { storeTimeline } = storeIoA.storeBoard

    storeTimeline.setDateStart(date)

    updateIoABoardUrlWithFilters(storeIoA)

    storeIoA.storeBoard.reloadAttacksSummary()
  }
}

/**
 * On change date format type, reset date range.
 */
export const handleTimelinePeriodSelection = (storeIoA: StoreIoA) => {
  return (e: RadioChangeEvent) => {
    const { storeBoard } = storeIoA
    const { storeTimeline } = storeBoard

    const period = e.target.value as AttacksSummaryPeriod

    storeTimeline.setPeriod(period)

    updateIoABoardUrlWithFilters(storeIoA)

    storeBoard.reloadAttacksSummary()
  }
}

/**
 * Set the date start at the current date.
 */
export const handleTimelineNowButton = (storeIoA: StoreIoA) => () => {
  storeIoA.storeBoard.storeTimeline.setDateStart(moment.utc().toISOString())

  updateIoABoardUrlWithFilters(storeIoA)
  storeIoA.storeBoard.reloadAttacksSummary()
}

/**
 * Reload attacks summary.
 */
export const handleTimelineRefreshButton = (storeBoard: StoreBoard) => {
  return () => {
    storeBoard.reloadAttacksSummary()
  }
}
