import {
  ContainerFooter,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import {
  IconLockOutlined,
  IconUserOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import { AuthProviderName } from '@server/graphql/typeDefs/types'
import { Input } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onLoginLDAPSubmit } from './handlers'
import { LDAPInputName } from './types'

export interface ILoginLDAPFormProps {}

const LoginLDAPForm: React.FC<ILoginLDAPFormProps> = props => {
  const { testAttributeProps } = useTestAttribute('form')

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Login', 'Login.Labels', 'Login.Errors']
  })

  const { storeAuthentication } = useStores()

  const storeForm = storeAuthentication.storeForms[AuthProviderName.Ldap]

  const fields: IField[] = [
    {
      name: LDAPInputName.account,
      label: translate('LDAP Account'),
      errors: storeForm.field(LDAPInputName.account).errors,
      control: (
        <Input
          data-name="AccountInput"
          prefix={<IconUserOutlined />}
          name={LDAPInputName.account}
          value={storeForm.getFieldValueAsString(LDAPInputName.account)}
          onChange={onInputChange(storeForm)(LDAPInputName.account)}
          placeholder={translate('LDAP Account')}
          autoComplete="off"
          tabIndex={10}
          disabled={storeAuthentication.storeFlagsProcessLogin.isLoading}
          {...testAttributeProps('textbox')('account')}
        />
      )
    },
    {
      name: LDAPInputName.password,
      label: translate('LDAP Password'),
      errors: storeForm.field(LDAPInputName.password).errors,
      control: (
        <Input.Password
          data-name="PasswordInput"
          prefix={<IconLockOutlined />}
          name={LDAPInputName.password}
          value={storeForm.getFieldValueAsString(LDAPInputName.password)}
          onChange={onInputChange(storeForm)(LDAPInputName.password)}
          placeholder={translate('LDAP Password')}
          autoComplete="off"
          visibilityToggle
          tabIndex={20}
          disabled={storeAuthentication.storeFlagsProcessLogin.isLoading}
          {...testAttributeProps('textbox')('password')}
        />
      )
    }
  ]

  return (
    <FormWrapper
      name="ldapConfiguration"
      onSubmit={onLoginLDAPSubmit(storeAuthentication)}
    >
      <ContainerForm fields={fields} />

      <Portal name={PlaceHolderName.authFooter}>
        <ContainerFooter>
          <FormWrapperButtonSubmit
            type="primary"
            htmlType="submit"
            loading={storeAuthentication.storeFlagsProcessLogin.isLoading}
            {...testAttributeProps('button')('submit')}
          >
            {translate('Login')}
          </FormWrapperButtonSubmit>
        </ContainerFooter>
      </Portal>
    </FormWrapper>
  )
}

export default observer(LoginLDAPForm)
