import { consts } from '@app/styles'
import { buildVariants } from '@design-system/libs/buildVariants'
import { forwardProps } from '@design-system/libs/forwardProps'
import * as React from 'react'
import styled from 'styled-components'

export interface IInputTextLikePasswordProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

interface IInputTextLikePasswordVariants {
  isFilled: boolean
  disabled?: boolean
}

const StyledInput = styled.input<IInputTextLikePasswordVariants>(props => {
  return (
    buildVariants(props)
      .css({
        /** Antd computed styles copied from the browser */
        overflow: 'visible',
        boxSizing: 'border-box',
        margin: '0',
        fontVariant: 'tabular-nums',
        listStyle: 'none',
        fontFeatureSettings: "'tnum'",
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        minWidth: '0',
        padding: '4.8px 11px',
        color: '#1c2b45',
        fontSize: '13px',
        lineHeight: '1.5715',
        backgroundColor: '#fff',
        backgroundImage: 'none',
        border: '1px solid #d9d9d9',
        borderRadius: '2px',
        transition: 'all 0.3s',
        WebkitAppearance: 'none',
        touchAction: 'manipulation',

        '&:focus': {
          outline: 'none'
        }
      })

      .if(!props.disabled, builder_ => {
        return builder_
          .css({
            '&:hover': {
              borderColor: '#2f5469',
              borderRightWidth: '1px'
            }
          })
          .end()
      })

      /**
       * Replace the font only when filled to keep placeholder with the default font
       */
      .variant('isFilled', props.isFilled, {
        true: {
          // Use home-made Circle font to replace characters by circles
          fontFamily: 'Circle',
          letterSpacing: '-5px'
        },

        false: {}
      })

      .variant('disabled', props.disabled, {
        true: {
          color: consts.colorGrey030,
          background: consts.colorInputDisabled
        },

        false: {}
      })

      .end()
  )
})

/**
 * Input text with type="password" behavior by using a home-made font which represents
 * all charts with circles, allowing to avoid passwords autofills/suggestion by browsers.
 */
export default function InputTextLikePassword(
  props: IInputTextLikePasswordProps
) {
  const valueLength = (props.value ?? '').toString().length

  return (
    <StyledInput
      isFilled={valueLength > 0}
      autoComplete="off"
      {...props}
      {...forwardProps(props)}
    />
  )
}
