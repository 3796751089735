import { ContainerFlex } from '@app/components/Container'
import type { IIconProps } from '@app/components/Icon/types'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import * as React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  border-radius: ${({ theme }) => theme.tokens['card/borderRadius/default']};
  padding: ${({ theme }) => theme.tokens['card/padding/mainContent']};
  background-color: ${({ theme }) =>
    theme.tokens['cardInfo/backgroundColor/default']};
`

interface ICardInfoProps {
  className?: string
  style?: React.CSSProperties
  title: string
  icon: React.FC<IIconProps>
  children?: React.ReactNode
}

export function CardInfo(props: ICardInfoProps) {
  const theme = useDSTheme()

  const Icon = props.icon

  return (
    <StyledDiv className={props.className} style={props.style}>
      <ContainerFlex
        name="CardInfoIconAndContent"
        items={[
          <Icon color={theme.tokens['cardInfo/color/icon']} size={20} />,
          <ContainerFlex
            name="CardInfoContent"
            direction="column"
            items={[
              <Label variant={LabelVariant.p_bold}>{props.title}</Label>,
              <div>{props.children}</div>
            ]}
            flexGap="small"
          />
        ]}
        flexGap="small"
      />
    </StyledDiv>
  )
}
