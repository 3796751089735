import type { Maybe } from '@@types/helpers'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  createRole,
  deleteRole,
  editRole,
  readRole,
  readRoles,
  readUIAccountsRoles
} from '@libs/rbac/permissions'

export const canAccessToRoles = rbacCapabilityCheckAllOf(
  readUIAccountsRoles(),
  readRoles()
)

export const canReadRole = (roleId: number) =>
  rbacCapabilityCheckAllOf(canAccessToRoles, readRole(roleId))

export const canCreateRoles = rbacCapabilityCheckAllOf(
  canAccessToRoles,
  createRole()
)

export const canDeleteRole = (roleId: Maybe<number>) =>
  rbacCapabilityCheckAllOf(canAccessToRoles, deleteRole(roleId))

export const canEditRole = (roleId: number) =>
  rbacCapabilityCheckAllOf(canAccessToRoles, editRole(roleId))
