import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks/useStores'
import MenuAccounts from '@app/pages/Management/AccountsPage/MenuAccounts'
import { AppRouteName } from '@app/routes'
import type { IManagementAccountsProfilesEditRouteDefinition } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import ProfilesCreatePage from '../ProfilesCreatePage'
import ProfilesEditPage from '../ProfilesEditPage'
import DrawerDeleteProfile from './DrawerDeleteProfile'
import { handleProfilesOnLoad } from './handlers'
import {
  canAccessToProfiles,
  canCreateProfiles,
  canEditProfiles
} from './permissions'
import ProfilesList from './ProfilesList'

interface IProfilesPageProps {}

const ProfilesPage: React.FC<IProfilesPageProps> = () => {
  const { storeManagementProfiles } = useStores()

  return (
    <>
      <Blade
        root={true}
        routeDefinition={{
          routeName: AppRouteName.Management_Accounts_Profiles,
          parameters: {}
        }}
        onLoad={handleProfilesOnLoad(storeManagementProfiles)}
        flags={storeManagementProfiles.storeFlagsFetchProfiles.flags}
        layout={{
          name: 'default',
          menu: <MenuAccounts />,
          content: (
            <ContainerContent>
              <ProfilesList />
            </ContainerContent>
          ),
          footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
        }}
        rbacCapabilityCheck={canAccessToProfiles}
      />

      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName: AppRouteName.Management_Accounts_Profiles_Create,
              parameters: {}
            },
            component: ProfilesCreatePage,
            rbacPermissionsCheck: () => canCreateProfiles
          },
          {
            routeDefinition: {
              routeName: AppRouteName.Management_Accounts_Profiles_Edit,
              parameters: {
                profileId: Number()
              }
            },
            component: ProfilesEditPage,
            rbacPermissionsCheck: (
              parameters: IManagementAccountsProfilesEditRouteDefinition['parameters']
            ) => {
              const profile = storeManagementProfiles.profiles.get(
                parameters.profileId
              )
              const isTenableProfile = Boolean(
                profile && profile.isTenableProfile()
              )

              return canEditProfiles(isTenableProfile, parameters.profileId)
            }
          }
        ]}
      />

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerDeleteProfile />
      </Portal>
    </>
  )
}

export default observer(ProfilesPage)
