import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import EntityHealthCheckPlatformStatusReason from '@app/entities/EntityHealthCheckPlatformStatusReason'
import { createEntities } from '@app/entities/index'
import type {
  HealthCheckPlatformStatus,
  HealthCheckPlatformStatusDetails,
  HealthCheckPlatformStatusReason,
  HealthCheckStatus,
  Maybe
} from '@server/graphql/typeDefs/types'

export default class EntityHealthCheckPlatformStatusDetails
  extends EntityBase
  implements PropertiesNullable<HealthCheckPlatformStatusDetails>
{
  healthCheckName: Maybe<string> = null
  status: Maybe<HealthCheckStatus> = null
  lastCheckDate: Maybe<string> = null
  statusSince: Maybe<string> = null
  reasons: Maybe<HealthCheckPlatformStatusReason[]> = null

  constructor(data: Partial<HealthCheckPlatformStatus>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return the reasons entities.
   */
  getReasons(): Maybe<EntityHealthCheckPlatformStatusReason[]> {
    if (!this.reasons) {
      return null
    }

    return createEntities<
      HealthCheckPlatformStatusReason,
      EntityHealthCheckPlatformStatusReason
    >(EntityHealthCheckPlatformStatusReason, this.reasons)
  }
}
