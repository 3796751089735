import type { MaybeUndef } from '@@types/helpers'
import {
  EntityAttackPathEdge,
  EntityAttackPathNode,
  EntityAttackPathNodeDrawer,
  EntityPagination
} from '@app/entities'
import { AttackPathSearchTabs } from '@app/pages/AttackPath/types'
import {
  findInDepthLinkedEntities,
  generateIndexedEdges,
  isEntityAttackPathNode,
  rotateCoords,
  sortNodesWithEdges
} from '@app/stores/AttackPath/utils'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import type { IDataRowGeneric } from '@app/stores/helpers/StoreWidgetList/types'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { ErrorName } from '@libs/errors/types'
import { filterFalsies } from '@libs/filterFalsies'
import { GQLRequestor } from '@libs/graphQL'
import { filterNullOrUndefinedValues } from '@libs/helpers/objects/filterNullOrUndefinedValues'
import { isDefined } from '@libs/isDefined'
import { checkRbac } from '@libs/rbac/functions'
import type {
  QueryRbacAttackPath,
  QueryRbacAttackPathHealthCheck,
  QueryRbacAttackPathSearch,
  QueryRbacAttackPathTwoNodes
} from '@server/graphql/queries/attack-path'
import {
  queryRbacAttackPath,
  queryRbacAttackPathHealthCheck,
  queryRbacAttackPathSearch,
  queryRbacAttackPathTwoNodes
} from '@server/graphql/queries/attack-path'
import type {
  AttackPath,
  AttackPathNode,
  Maybe,
  MaybeGrantedAttackPath,
  RbacAttackPathQueryArgs,
  RbacAttackPathSearchQueryArgs,
  RbacAttackPathTwoNodesQueryArgs
} from '@server/graphql/typeDefs/types'
import { AttackPathDirection } from '@server/graphql/typeDefs/types'
import type { Selection, ZoomBehavior } from 'd3'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import { v4 as v4uuid } from 'uuid'
import { StoreAttackPathTier0 } from '..'
import type { StoreRoot } from '..'
import StoreFlags from '../helpers/StoreFlags'
import {
  InputSearchTransformMethod,
  StoreInputSearch
} from '../helpers/StoreInputSearch'
import StoreModal from '../helpers/StoreModal'
import StoreBase from '../StoreBase'
import type { IChartZoomData, ISceneDimensions } from '../Topology/types'
import type { IStoreOptions } from '../types'
import {
  CELL_SIZE,
  MAX_NODES_DEPTH,
  MAX_NODES_TO_DISPLAY_BEFORE_DISPLAYING_ALERT
} from './consts'
import { placeNodesForPathBetweenTwoNodes } from './helpers/GraphPlacement'
import {
  generateExpandNodesPlacement,
  placeNodesForOneNodeSearch
} from './helpers/OnionPlacement'
import type {
  AttackPathQueryStringParameters,
  AttackPathStats,
  EntityAttackPathNodeAny,
  IAttackPathEdgeExtended,
  IDrawerAttackPathData,
  INodeWithEdge,
  PlacementAlgorithmData
} from './types'
import { combineRelationsTypesInEdges, groupNodes } from './utils'

export default class StoreAttackPath extends StoreBase {
  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['AttackPath.Chart']
  })

  public storeAttackPathTier0 = new StoreAttackPathTier0(this.storeRoot)

  /* Flags */
  public storeFlagsHealthCheck = new StoreFlags(this.storeRoot)
  public storeFlagsSearchSourceNodes = new StoreFlags(this.storeRoot)
  public storeFlagsSearchTargetNodes = new StoreFlags(this.storeRoot)
  public storeFlagsFetchNodes = new StoreFlags(this.storeRoot)
  public storeFlagsFetchExpandedNodes = new StoreFlags(this.storeRoot)
  public storeFlagsFetchDrawerNodes = new StoreFlags(this.storeRoot)

  public storeInputSearchSource = new StoreInputSearch(this.storeRoot)
  public storeInputSearchTarget = new StoreInputSearch(this.storeRoot)
  public storeInputSearchDrawer = new StoreInputSearch(this.storeRoot, {
    transformMethod: InputSearchTransformMethod.greedy
  })

  /* Drawers */

  public storeDrawer = new StoreDrawer<IDrawerAttackPathData>(this.storeRoot)

  /* Modals */

  public storeModalLongRender = new StoreModal(this.storeRoot)
  public storeModalServiceUnavailable = new StoreModal(this.storeRoot, {
    isUnclosable: true
  })

  /* Lists */

  public storeWidgetListDrawerNodeRelationships = new StoreWidgetList<
    EntityAttackPathNode,
    IDataRowGeneric
  >(this.storeRoot, {
    offline: true
  })

  /* Private */

  // ref to the DOM element of the chart
  private _chartSvgRef: Maybe<SVGSVGElement> = null
  private _handleZoom: Maybe<ZoomBehavior<SVGSVGElement, any>> = null

  private _zoomSelection: Maybe<
    Selection<SVGSVGElement, unknown, null, undefined>
  > = null

  private _maxOnionRingIndex: number = 0

  // used to be able to only take into account the last performed request
  private _requestUuid: Maybe<string> = null

  /* Observables */

  private $sceneDimensions = observable.box<Maybe<ISceneDimensions>>(null)
  private $sceneIsReady = observable.box<boolean>(true)
  private $chartZoomData = observable.box<Maybe<IChartZoomData>>(null)
  private $direction = observable.box<AttackPathDirection>(
    AttackPathDirection.From
  )
  private $zoomSliderValue = observable.box<number>(1)
  private $zoomSliderMinValue = observable.box<number>(1)
  private $zoomSliderMaxValue = observable.box<number>(4)
  private $targetNodeDisplay = observable.box<boolean>(false)
  private $isShowingAllTooltips = observable.box<boolean>(false)
  private $isPopoverVisible = observable.box<boolean>(false)

  private $activeTab = observable.box<AttackPathSearchTabs>(
    AttackPathSearchTabs.blastRadius
  )

  // current hovered adObject
  private $adObjectId = observable.box<Maybe<number>>(null)
  private $currentNodeId = observable.box<Maybe<string>>(null)

  // current hovered edge
  private $currentEdgeId = observable.box<Maybe<string>>(null)

  // current pinned node
  private $currentPinnedNodeUid = observable.box<Maybe<string>>(null)
  private $currentPinnedNodeId = observable.box<Maybe<number>>(null)

  // nodes and edges stores waiting for their display
  private $placementResult = observable.box<Maybe<PlacementAlgorithmData>>(null)

  // current hovered expand node
  private $hoveredExpandNodeUid = observable.box<Maybe<string>>(null)
  // current hovered unexpand node
  private $hoveredUnexpandNodeUid = observable.box<Maybe<string>>(null)
  // current expanded node
  private $expandedNodeUids = observable.array<string>([])

  private $linkedNodes = observable.map<string, EntityAttackPathNode>([])
  private $linkedEdges = observable.array<EntityAttackPathEdge>([])

  private $nodes = observable.map<string, EntityAttackPathNodeAny>([])
  private $edges = observable.map<string, EntityAttackPathEdge>([])

  private $rootNodeEntity = observable.box<Maybe<EntityAttackPathNode>>(null)

  private $searchSourceSuggestions = observable.array<AttackPathNode>([])

  private $searchTargetSuggestions = observable.array<AttackPathNode>([])

  private $selectedSearchedSourceNode =
    observable.box<Maybe<AttackPathNode>>(null)

  private $selectedSearchedTargetNode =
    observable.box<Maybe<AttackPathNode>>(null)

  private $sourceNode = observable.box<Maybe<AttackPathNode>>(null)

  private $targetNode = observable.box<Maybe<AttackPathNode>>(null)

  private $isTruncated = observable.box<Maybe<boolean>>(null)
  private $truncationType =
    observable.box<Maybe<AttackPath['truncationType']>>(null)
  private $displayIsTruncatedAlert = observable.box<Maybe<boolean>>(null)

  private $noAttackPathTwoNodesResult = observable.box<boolean>(false)

  private $drawerChildrenNodesWithEdge = observable.array<INodeWithEdge>([])

  private $drawerAdObjectTypeFilter = observable.array<string>([])

  private $customErrorMessage = observable.box<Maybe<string>>(null)

  private handledErrorsTranslations = new Map([
    [
      ErrorName.AttackPathGraphTooBig,
      () => this.translate('Attack path graph too big error')
    ],
    [
      ErrorName.AttackPathSearchTimeout,
      () => this.translate('Attack path search error')
    ]
  ])

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch the attack path health check
   */
  public fetchHealthCheck(): Promise<void> {
    this.storeModalServiceUnavailable.hide()

    this.storeFlagsHealthCheck.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryRbacAttackPathHealthCheck>(
            queryRbacAttackPathHealthCheck,
            null,
            { keepGraphQLError: true }
          )
      })
      .then(({ rbacAttackPathHealthCheck }) => {
        if (!rbacAttackPathHealthCheck) {
          throw new Error()
        }
        this.storeFlagsHealthCheck.success()
      })
      .catch(err => {
        if (err?.response?.errors[0]?.statusCode === 503) {
          this.storeModalServiceUnavailable.show()
          this.storeFlagsHealthCheck.success()
          return
        }
        handleStoreError(
          this.storeRoot,
          this.storeFlagsHealthCheck
        )(GQLRequestor.transformError(err))
      })
  }

  /**
   * Handle the loading of primary nodes.
   * Displaying can be delayed to user choice depending on the amount of nodes.
   */
  fetchNodes(storeFlags = this.storeFlagsFetchNodes): Promise<void> {
    const currentRequestUuid = v4uuid()
    this.setCurrentRequestUuid(currentRequestUuid)

    storeFlags.loading()
    this.setNoAttackPathTwoNodesResult(false)
    this.setCustomErrorMessage(null)
    // reset expanded node uids
    this.setExpandedNodeUids([])

    const depth = MAX_NODES_DEPTH

    return Promise.resolve()
      .then(() => {
        if (!this.sourceNode || (this.targetNodeDisplay && !this.targetNode)) {
          throw new Error('An error occurred with selected node')
        }

        if (this.targetNodeDisplay && this.targetNode) {
          const twoNodesArgs: RbacAttackPathTwoNodesQueryArgs = {
            sourceAdObjectId: this.sourceNode.id,
            targetAdObjectId: this.targetNode.id
          }

          return this.fetchAttackPathTwoNodes(twoNodesArgs)
        }

        const args: RbacAttackPathQueryArgs = {
          direction: this.direction,
          adObjectId: this.sourceNode.id,
          depth
        }

        return this.fetchAttackPathOneNode(args)
      })
      .then(rbacAttackPath => {
        if (
          !checkRbac(this.storeRoot, this.storeFlagsFetchNodes)(rbacAttackPath)
        ) {
          throw new ForbiddenAccessError()
        }

        if (this._requestUuid !== currentRequestUuid) {
          return
        }

        const { nodes, edges, isTruncated, truncationType } =
          rbacAttackPath.node

        if (!nodes || !edges || !nodes.length) {
          // If no result, clean graph and display a message on attack path mode
          this.cleanGraph()

          if (this.targetNode) {
            this.setNoAttackPathTwoNodesResult(true)
          }

          storeFlags.success()
          return
        }

        // Update searched source node to fill search inputs
        const searchedSourceId = this.sourceNode?.id
        if (isDefined(searchedSourceId) && !this.sourceNode?.name) {
          // Find node
          const sourceNode = nodes.find(node => node.id === searchedSourceId)

          if (sourceNode && sourceNode.name) {
            this.setSourceNode(sourceNode)
            this.setSelectedSearchedSourceNode(sourceNode)
            this.storeInputSearchSource.setSearchValue(sourceNode.name)
          }
        }

        // Update searched target node to fill search inputs
        const searchedTargetId = this.targetNode?.id
        if (isDefined(searchedTargetId) && !this.targetNode?.name) {
          // Find node
          const targetNode = nodes.find(node => node.id === searchedTargetId)

          if (targetNode && targetNode.name) {
            this.setTargetNode(targetNode)
            this.setSelectedSearchedTargetNode(targetNode)
            this.storeInputSearchTarget.setSearchValue(targetNode.name)
          }
        }

        const direction: AttackPathDirection = this.targetNode
          ? AttackPathDirection.From
          : this.direction

        const depthToProcess = this.targetNode ? edges.length : MAX_NODES_DEPTH

        const rootNode = nodes.find(node => node.id === searchedSourceId)

        // Generate root node entity (the one that is searched)
        this.setRootNodeEntity(
          new EntityAttackPathNode({
            ...rootNode,
            uid: v4uuid(),
            depth: 0,
            isImportantNode: true
          })
        )

        if (!this.rootNodeEntity) {
          return
        }

        // Filter edges for hasControlRight relation types
        const filteredEdges: IAttackPathEdgeExtended[] =
          combineRelationsTypesInEdges(edges)

        // Search in depth all related entities (nodes and edges)
        const { nodes: linkedNodes, edges: linkedEdges } =
          findInDepthLinkedEntities(
            depthToProcess,
            direction,
            this.rootNodeEntity,
            nodes,
            filteredEdges
          )

        this.setLinkedNodes(linkedNodes)
        this.setLinkedEdges(linkedEdges)

        const linkedNodesArr = Array.from(linkedNodes.values())

        const placementResult: PlacementAlgorithmData = this.targetNode
          ? placeNodesForPathBetweenTwoNodes(
              this.rootNodeEntity,
              this.targetNode.id,
              linkedNodesArr,
              linkedEdges
            )
          : placeNodesForOneNodeSearch(
              linkedNodesArr,
              linkedEdges,
              this.rootNodeEntity,
              direction
            )

        this.setPlacementResult(placementResult)
        this.setIsTruncated(isTruncated, truncationType)
        this.setDisplayIsTruncatedAlert(null)

        const canDisplay =
          placementResult.nodes.length <
          MAX_NODES_TO_DISPLAY_BEFORE_DISPLAYING_ALERT

        // Protect Kapteyn from loading too many nodes.
        if (!canDisplay) {
          this.cleanGraph()
          storeFlags.success()
          // Remove current nodes
          this.storeModalLongRender.show()
          return
        }

        this.displayGraph()
      })
      .catch(err => {
        if (err?.response?.errors[0]?.statusCode === 404) {
          this.setCustomErrorMessage(
            this.translate('No node found with the given id')
          )
          storeFlags.success()
        }

        const errorMessage = err?.response?.errors[0]?.message
        const isErrorHandled = this.handledErrorsTranslations.has(errorMessage)
        const errorTranslator = this.handledErrorsTranslations.get(errorMessage)

        if (isErrorHandled && errorTranslator) {
          this.cleanGraph()
          this.setCustomErrorMessage(errorTranslator())
          storeFlags.success()
          handleStoreError(this.storeRoot, storeFlags, {
            errorMessageTranslationFn: _ => errorTranslator()
          })(GQLRequestor.transformError(err))
          return
        }

        handleStoreError(
          this.storeRoot,
          storeFlags
        )(GQLRequestor.transformError(err))
      })
  }

  /**
   * Get edge by uid.
   */
  getEdgeByUid(uid: string): Maybe<EntityAttackPathEdge> {
    const edge = this.$edges.get(uid)

    if (!edge) {
      return null
    }

    return edge
  }

  /**
   * Fetch the attack path from a node or to a node.
   */
  private fetchAttackPathOneNode(
    args: RbacAttackPathQueryArgs
  ): Promise<MaybeGrantedAttackPath> {
    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryRbacAttackPath>(queryRbacAttackPath, args, {
            keepGraphQLError: true
          })
      })
      .then(({ rbacAttackPath }) => rbacAttackPath)
  }

  /**
   * Fetch the attack path between two nodes.
   */
  private fetchAttackPathTwoNodes(
    args: RbacAttackPathTwoNodesQueryArgs
  ): Promise<MaybeGrantedAttackPath> {
    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryRbacAttackPathTwoNodes>(
            queryRbacAttackPathTwoNodes,
            args,
            { keepGraphQLError: true }
          )
      })
      .then(({ rbacAttackPathTwoNodes }) => rbacAttackPathTwoNodes)
  }

  /**
   * This method is responsible of computing nodes position.
   */
  displayGraph(storeFlags = this.storeFlagsFetchNodes): void {
    const placementResult = this.$placementResult.get()

    if (placementResult) {
      this.setNodes(placementResult.nodes)

      if (!this.targetNode) {
        this.setMaxOnionRingIndex(placementResult.maxIndex ?? 0)
      }

      this.setEdges(placementResult.edges)
    }

    this.setDisplayIsTruncatedAlert(this.isTruncated)

    // Remove flag
    storeFlags.success()
  }

  /**
   *  Handle the loading of expanded nodes.
   */
  fetchExpandNodes(
    linkedEntity: EntityAttackPathNode,
    storeFlags = this.storeFlagsFetchExpandedNodes
  ): Promise<void> {
    storeFlags.loading()
    const depth = linkedEntity.depth + 1

    return Promise.resolve()
      .then(() => {
        const args: RbacAttackPathQueryArgs = {
          direction: this.direction,
          adObjectId: linkedEntity.getPropertyAsNumber('id'),
          depth: 1
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryRbacAttackPath>(queryRbacAttackPath, args)
      })
      .then(({ rbacAttackPath }) => {
        if (
          !checkRbac(
            this.storeRoot,
            this.storeFlagsFetchExpandedNodes
          )(rbacAttackPath)
        ) {
          throw new ForbiddenAccessError()
        }

        const { nodes, edges } = rbacAttackPath.node

        const searchedAdObjectId = linkedEntity.getPropertyAsNumber('id')

        if (!nodes || !edges || !searchedAdObjectId) {
          return
        }

        // Generate root node entity (the one that is searched)
        const rootNodeEntity = linkedEntity

        // Filter edges for hasControlRight relation types
        const filteredEdges: IAttackPathEdgeExtended[] =
          combineRelationsTypesInEdges(edges)

        const direction: AttackPathDirection = this.targetNode
          ? AttackPathDirection.From
          : this.direction

        // Search in depth all related entities (nodes and edges)
        const { nodes: linkedNodes, edges: linkedEdges } =
          findInDepthLinkedEntities(
            depth,
            direction,
            rootNodeEntity,
            nodes,
            filteredEdges
          )

        const previousLinkedNodes = this.linkedNodes

        linkedNodes.forEach(node => previousLinkedNodes.set(node.uid, node))

        this.setLinkedNodes(previousLinkedNodes)
        this.setLinkedEdges([...this.linkedEdges, ...linkedEdges])

        const centeredNode = this.nodes.find(node => {
          return node.depth === 0
        })

        if (!centeredNode) {
          return
        }

        const linkedNodesArr = Array.from(linkedNodes.values())

        // Filter all children that are linked to a node that exceed the threshold
        // No expand for path between 2 nodes
        const groupedNodes = groupNodes(linkedNodesArr, linkedEdges, direction)

        // Add entities placed with the onion method
        const placement = generateExpandNodesPlacement(
          groupedNodes,
          linkedEntity,
          centeredNode,
          this.maxOnionRingIndex
        )

        const nodesWithFurtherEdges = placement.nodes.filter(node => !node.id)

        const directionFrom = direction === AttackPathDirection.From

        const edgesFromFurtherEdges = nodesWithFurtherEdges
          .map(expand => {
            if (!expand.linkedNodeEntity) {
              return
            }
            const edgeEntity = new EntityAttackPathEdge({
              id: v4uuid(),
              sourceUid: directionFrom
                ? expand.linkedNodeEntity.uid
                : expand.uid, // adding uid for easy finding later
              targetUid: directionFrom
                ? expand.uid
                : expand.linkedNodeEntity.uid // adding uid for easy finding later
            })
            return edgeEntity
          })
          .filter(isDefined)

        // Add onions nodes
        this.setNodes([
          ...this.nodes.map(node => {
            node.isSourceOfExpansion = false
            return node
          }),
          ...placement.nodes
            .filter(node => node.uid !== linkedEntity.uid)
            .map(node => {
              node.isPartOfExpansion = true
              return node
            })
        ])

        rootNodeEntity.isSourceOfExpansion = true

        const remaningNodeUids = new Set(placement.nodes.map(node => node.uid))

        const cleanedEdges = linkedEdges.filter(
          edge =>
            edge.sourceUid === rootNodeEntity.uid ||
            edge.targetUid === rootNodeEntity.uid ||
            (edge.sourceUid &&
              edge.targetUid &&
              remaningNodeUids.has(edge.sourceUid) &&
              remaningNodeUids.has(edge.targetUid))
        )

        // Attach source and target entities to edges
        this.setEdges([
          ...this.edges,
          ...cleanedEdges.concat(edgesFromFurtherEdges).map(edge => {
            if (!edge.sourceUid || !edge.targetUid) {
              return edge
            }

            // Attach entities to the edge.
            // It's done here because we need to have fully qualified entites (with onion placement data)
            const source = this.$nodes.get(edge.sourceUid)
            const target = this.$nodes.get(edge.targetUid)

            if (!source || !target) {
              return edge
            }

            edge._setSource(source)
            edge._setTarget(target)

            edge.isPartOfExpansion = true

            // Launch computation of graphics properties
            edge.computeSvgProps()

            return edge
          })
        ])

        // Remove hovered
        this.removeHoveredExpandNodeUid()
        this.removeHoveredUnexpandNodeUid()
        this.computeZoomValues(true)

        // Remove flag
        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   *  Handle the display of expanded nodes.
   */
  displayExpandNodesFromDrawer(
    linkedEntity: EntityAttackPathNode,
    nodes: EntityAttackPathNode[],
    edges: EntityAttackPathEdge[]
  ): void {
    const searchedAdObjectId = linkedEntity.getPropertyAsNumber('id')

    if (!searchedAdObjectId) {
      return
    }

    // Generate root node entity (the one that is searched)
    const rootNodeEntity = linkedEntity

    const direction: AttackPathDirection = this.targetNode
      ? AttackPathDirection.From
      : this.direction

    const centeredNode = this.nodes.find(node => {
      return node.depth === 0
    })

    if (!centeredNode) {
      return
    }

    // Add entities placed with the onion method
    const placement = generateExpandNodesPlacement(
      nodes,
      linkedEntity,
      centeredNode,
      this.maxOnionRingIndex
    )

    const nodesWithFurtherEdges = placement.nodes.filter(node => !node.id)

    const directionFrom = direction === AttackPathDirection.From

    const edgesFromFurtherEdges = nodesWithFurtherEdges
      .map(expand => {
        if (!expand.linkedNodeEntity) {
          return
        }
        const edgeEntity = new EntityAttackPathEdge({
          id: v4uuid(),
          sourceUid: directionFrom ? expand.linkedNodeEntity.uid : expand.uid, // adding uid for easy finding later
          targetUid: directionFrom ? expand.uid : expand.linkedNodeEntity.uid // adding uid for easy finding later
        })
        return edgeEntity
      })
      .filter(isDefined)

    this.moveDrawerNodeFromParentNode(linkedEntity)

    // Add onions nodes
    this.setNodes([
      ...this.nodes.map(node => {
        node.isSourceOfExpansion = false
        return node
      }),
      ...placement.nodes
        .filter(node => node.uid !== linkedEntity.uid)
        .map(node => {
          node.isPartOfExpansion = true
          return node
        })
    ])

    rootNodeEntity.isSourceOfExpansion = true

    const remaningNodeUids = new Set(placement.nodes.map(node => node.uid))

    const cleanedEdges = edges.filter(
      edge =>
        ((edge.sourceUid === rootNodeEntity.uid ||
          edge.targetUid === rootNodeEntity.uid) &&
          remaningNodeUids.has(edge.sourceUid as string)) ||
        remaningNodeUids.has(edge.targetUid as string)
    )

    // Attach source and target entities to edges
    this.setEdges([
      ...this.edges,
      ...cleanedEdges.concat(edgesFromFurtherEdges).map(edge => {
        if (!edge.sourceUid || !edge.targetUid) {
          return edge
        }

        // Attach entities to the edge.
        // It's done here because we need to have fully qualified entites (with onion placement data)
        const source = this.$nodes.get(edge.sourceUid)
        const target = this.$nodes.get(edge.targetUid)

        if (!source || !target) {
          return edge
        }

        edge._setSource(source)
        edge._setTarget(target)

        edge.isPartOfExpansion = true

        // Launch computation of graphics properties
        edge.computeSvgProps()

        return edge
      })
    ])

    this.computeZoomValues(true)

    // Remove hovered
    this.removeHoveredExpandNodeUid()
    this.removeHoveredUnexpandNodeUid()
  }

  /**
   *  Handle the loading of drawer nodes.
   */
  fetchDrawerNodes(
    linkedEntity: EntityAttackPathNode,
    storeFlags = this.storeFlagsFetchDrawerNodes
  ): Promise<void> {
    storeFlags.loading()
    return Promise.resolve()
      .then(() => {
        const args: RbacAttackPathQueryArgs = {
          direction: this.direction,
          adObjectId: linkedEntity.getPropertyAsNumber('id'),
          depth: 1
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryRbacAttackPath>(queryRbacAttackPath, args)
      })
      .then(({ rbacAttackPath }) => {
        if (
          !checkRbac(
            this.storeRoot,
            this.storeFlagsFetchExpandedNodes
          )(rbacAttackPath)
        ) {
          throw new ForbiddenAccessError()
        }

        const { nodes, edges } = rbacAttackPath.node

        if (!nodes || !edges) {
          throw new Error('No data for drawer')
        }

        // Filter edges for hasControlRight relation types
        const filteredEdges: IAttackPathEdgeExtended[] =
          combineRelationsTypesInEdges(edges)

        const childrenNodesWithEdge = filterFalsies(
          filteredEdges.map(edge => {
            const childNode =
              this.direction === AttackPathDirection.To
                ? nodes.find(node => node.id === edge.sourceId)
                : nodes.find(node => node.id === edge.targetId)

            if (!childNode) {
              return null
            }

            const nodeEntity = new EntityAttackPathNode({
              ...childNode,
              uid: v4uuid(),
              depth: linkedEntity.depth + 1,
              linkedNodeEntity: linkedEntity
            })

            const edgeEntity = new EntityAttackPathEdge({
              ...edge,
              id: v4uuid(),
              sourceUid:
                this.direction === AttackPathDirection.To
                  ? nodeEntity.uid
                  : linkedEntity.uid, // adding uid for easy finding later
              targetUid:
                this.direction === AttackPathDirection.To
                  ? linkedEntity.uid
                  : nodeEntity.uid // adding uid for easy finding later
            })

            return {
              edge: edgeEntity,
              node: nodeEntity
            }
          })
        )

        this.setDrawerChildrenNodesWithEdge(childrenNodesWithEdge)

        const previousLinkedNodes = this.linkedNodes
        const linkedEdges: EntityAttackPathEdge[] = []

        childrenNodesWithEdge.map(({ node, edge }) => {
          previousLinkedNodes.set(node.uid, node)
          linkedEdges.push(edge)
        })

        this.setLinkedNodes(previousLinkedNodes)
        this.setLinkedEdges([...this.linkedEdges, ...linkedEdges])

        // Remove flag
        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  computeZoomValues(sameChart: boolean = false) {
    const rootNode = this.nodes.find(node => node.depth === 0)
    if (!rootNode) {
      return
    }

    const sceneDimensions = this.sceneDimensions

    if (this.targetNode) {
      const { minX, minY, maxX, maxY } = (
        this.nodes as EntityAttackPathNode[]
      ).reduce(
        (acc, node) => {
          if (acc.minX > node.x) {
            acc.minX = node.x
          }
          if (acc.minY > node.y) {
            acc.minY = node.y
          }

          if (acc.maxX < node.x) {
            acc.maxX = node.x
          }
          if (acc.maxY < node.y) {
            acc.maxY = node.y
          }

          return acc
        },
        {
          minX: Number.MAX_VALUE,
          minY: Number.MAX_VALUE,
          maxX: 0,
          maxY: 0
        }
      )

      if (this.nodes.length > 4) {
        const zoomMinForPathBetween2Nodes = Math.min(
          sceneDimensions.width / (maxX - minX),
          sceneDimensions.height / (maxY - minY)
        )

        // Force the min value to be 1, so that we got at least icons on their
        // natural size.
        this.setZoomSliderMinValue(
          Math.min(1, zoomMinForPathBetween2Nodes * 0.8)
        )
      }

      const zoomMaxForPathBetween2Nodes = Math.max(
        1.5,
        Math.min(sceneDimensions.width, sceneDimensions.height) /
          (CELL_SIZE * 2)
      )
      this.setZoomSliderMaxValue(zoomMaxForPathBetween2Nodes)

      return
    }

    // Calculate values for zoom min value depending on nodes. Add one more for expand buttons
    const expandSpacing =
      this.expandedNodeUids.length > 0 ? this.expandedNodeUids.length : 1

    const maxCellDistanceFromRootNode =
      (this.maxOnionRingIndex + 1 + expandSpacing) * CELL_SIZE

    const maxCellDistanceFromRootNodeRotated =
      maxCellDistanceFromRootNode / Math.cos((45 * Math.PI) / 180)

    const maxDistanceBetweenTwoOppositeCells =
      maxCellDistanceFromRootNodeRotated * 2

    const zoomMin =
      Math.min(sceneDimensions.width, sceneDimensions.height) /
      maxDistanceBetweenTwoOppositeCells

    // If we stay on the same chart, we want to apply the new zoom min value only if it increases the ability to unzoom
    if (sameChart) {
      if (this.zoomSliderMinValue > zoomMin) {
        this.setZoomSliderMinValue(zoomMin)
      }
    } else {
      this.setZoomSliderMinValue(zoomMin)
    }

    // Calculate values for zoom max value depending on nodes
    const firstRingDistanceRotated =
      (CELL_SIZE * 2) / Math.cos((45 * Math.PI) / 180)
    const zoomMax = Math.max(
      1.5,
      Math.min(sceneDimensions.width, sceneDimensions.height) /
        firstRingDistanceRotated
    )
    this.setZoomSliderMaxValue(zoomMax)
  }

  /**
   * Return attackPathNode entity.
   */
  getNode(nodeUid: string): MaybeUndef<EntityAttackPathNode> {
    return this.linkedNodes.get(nodeUid)
  }

  /**
   * Return the nodes and edges of the children of a node.
   */
  getChildrenNodesWithEdge(nodeUid: string): INodeWithEdge[] {
    const filteredEdges = this.linkedEdges.filter(edge =>
      this.direction === AttackPathDirection.From
        ? edge.sourceUid === nodeUid
        : edge.targetUid === nodeUid
    )

    const indexedEdges = generateIndexedEdges(filteredEdges, this.direction)

    const childrenUids = Array.from(indexedEdges.get(nodeUid) || [])

    return filterFalsies(
      childrenUids.map(uid => {
        const childEdge =
          this.direction === AttackPathDirection.To
            ? filteredEdges.find(edge => edge.sourceUid === uid)
            : filteredEdges.find(edge => edge.targetUid === uid)
        const childNode = this.getNode(uid)

        if (!childEdge || !childNode) {
          return null
        }

        return { edge: childEdge, node: childNode }
      })
    )
  }

  /**
   * Get the drawer node linked to a node.
   */
  getDrawerNode(uid: string): Maybe<EntityAttackPathNodeDrawer> {
    const drawerNode = this.nodes.find(node => {
      return (
        node instanceof EntityAttackPathNodeDrawer &&
        node.linkedNodeEntity &&
        node.linkedNodeEntity.uid === uid
      )
    })

    if (drawerNode instanceof EntityAttackPathNodeDrawer) {
      return drawerNode
    }

    return null
  }

  /**
   * Get the edge linked to a child node.
   */
  getEdgeFromChildNode(uid: string): MaybeUndef<EntityAttackPathEdge> {
    return this.edges.find(edge => {
      const childNode =
        this.direction === AttackPathDirection.To ? edge.source : edge.target

      return childNode?.uid === uid
    })
  }

  /**
   * Search for nodes suggestions in the source input and update them.
   */
  async searchSourceNodesAndSetSuggestions(
    storeFlags = this.storeFlagsSearchSourceNodes
  ): Promise<void> {
    const attackPathNodes = await this.searchNodes(
      this.storeInputSearchSource.searchValue,
      storeFlags
    )

    if (attackPathNodes) {
      this.setSearchSourceSuggestions(attackPathNodes)
    }
  }

  /**
   * Search for nodes suggestions in the target input and update them.
   */
  async searchTargetNodesAndSetSuggestions(
    storeFlags = this.storeFlagsSearchTargetNodes
  ): Promise<void> {
    const attackPathNodes = await this.searchNodes(
      this.storeInputSearchTarget.searchValue,
      storeFlags
    )

    if (attackPathNodes) {
      this.setSearchTargetSuggestions(attackPathNodes)
    }
  }

  /**
   * Handle the search for nodes in the node finder.
   * It uses the text typed in the search input then display the nodes as suggestions.
   */
  private searchNodes(
    searchValue: string,
    storeFlags: StoreFlags
  ): Promise<AttackPathNode[] | void> {
    if (searchValue.length < 3) {
      return Promise.resolve()
    }

    storeFlags.loading()
    return Promise.resolve()
      .then(() => {
        const args: RbacAttackPathSearchQueryArgs = {
          text: searchValue
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryRbacAttackPathSearch>(queryRbacAttackPathSearch, args)
      })
      .then(({ rbacAttackPathSearch }) => {
        if (!checkRbac(this.storeRoot, storeFlags)(rbacAttackPathSearch)) {
          throw new ForbiddenAccessError()
        }

        if (!rbacAttackPathSearch.node) {
          throw new Error('No data while getting nodes suggestions')
        }

        return rbacAttackPathSearch.node
      })
      .then(attackPathNodes => {
        storeFlags.success()
        return attackPathNodes
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Save current request.
   */
  setCurrentRequestUuid(uuid: Maybe<string>): this {
    this._requestUuid = uuid

    return this
  }

  /* Actions */

  /**
   * Save scene dimensions.
   */
  @action
  setSceneDimensions(sceneDimensions: ISceneDimensions): this {
    this.$sceneDimensions.set(sceneDimensions)
    return this
  }

  /**
   * Save AttackPath svg ref.
   */
  @action
  setChartSvgRef(ref: SVGSVGElement): this {
    this._chartSvgRef = ref
    return this
  }

  /**
   * Save linked nodes entities.
   */
  @action
  setLinkedNodes(linkedNodes: Map<string, EntityAttackPathNode>): this {
    this.$linkedNodes.replace(linkedNodes)

    return this
  }

  /**
   * Save linked edges entities.
   */
  @action
  setLinkedEdges(linkedEdges: EntityAttackPathEdge[]): this {
    this.$linkedEdges.replace(linkedEdges)

    return this
  }

  /**
   * Save nodes entities.
   */
  @action
  setNodes(nodes: EntityAttackPathNodeAny[]): this {
    this.$nodes.clear()

    nodes.forEach(node => {
      if (!node.uid) {
        return
      }

      this.$nodes.set(node.uid, node)
    })

    return this
  }

  @action
  setMaxOnionRingIndex(index: number): this {
    this._maxOnionRingIndex = index
    return this
  }

  @action
  setEdges(edges: EntityAttackPathEdge[]): this {
    this.$edges.clear()

    edges.forEach(edge => {
      if (!edge.id) {
        return
      }

      this.$edges.set(edge.id, edge)
    })

    return this
  }

  /**
   * Save current adObjectId hovered to highlight adObject(s) that share this id.
   */
  @action
  setAdObjectId(id: number): this {
    this.$adObjectId.set(id)
    return this
  }

  /**
   * Remove current adObjectId hovered.
   */
  @action
  removeAdObjectId(): this {
    this.$adObjectId.set(null)
    return this
  }

  /**
   * Save current expand node uid hovered to highlight adObject(s) that share this id.
   */
  @action
  setHoveredExpandNodeUid(uid: string): this {
    this.$hoveredExpandNodeUid.set(uid)
    return this
  }

  /**
   * Remove current expand node uid hovered to highlight adObject(s) that share this id.
   */
  @action
  removeHoveredExpandNodeUid(): this {
    this.$hoveredExpandNodeUid.set(null)
    return this
  }

  /**
   * Save current unexpand node uid hovered.
   */
  @action
  setHoveredUnexpandNodeUid(uid: string): this {
    this.$hoveredUnexpandNodeUid.set(uid)
    return this
  }

  /**
   * Remove current expand node uid hovered.
   */
  @action
  removeHoveredUnexpandNodeUid(): this {
    this.$hoveredUnexpandNodeUid.set(null)
    return this
  }

  /**
   * Save current expanded node uid.
   */
  @action
  setExpandedNodeUids(uids: string[]): this {
    this.$expandedNodeUids.replace(uids)

    return this
  }

  /**
   * Remove current expanded node uid.
   */
  @action
  removeExpandedNodeUids(uid: string): this {
    const drawerNode = this.getDrawerNode(uid)

    if (drawerNode) {
      this.moveBackDrawerNode(drawerNode)
    }

    const indexOfUid = this.expandedNodeUids.indexOf(uid)

    this.setExpandedNodeUids(
      this.expandedNodeUids.filter((expandUid, index) => index < indexOfUid)
    )

    const linkedEntity = this.$nodes.get(
      this.expandedNodeUids[this.expandedNodeUids.length - 1]
    )

    if (!linkedEntity) {
      const nodes = this.nodes
        .filter(node => !node.isPartOfExpansion)
        .map(node => {
          node.isSourceOfExpansion = false
          return node
        })

      this.setNodes(nodes)

      this.setEdges(this.edges.filter(edge => !edge.isPartOfExpansion))
    }

    if (linkedEntity && isEntityAttackPathNode(linkedEntity)) {
      const nodes = this.nodes.filter(node =>
        isEntityAttackPathNode(node)
          ? node.depth <= linkedEntity.depth + 1
          : node.depth <= linkedEntity.depth + 2
      )

      this.setNodes(nodes)

      const edges = this.edges.filter(edge =>
        this.direction === AttackPathDirection.From
          ? (edge.source && edge.source.depth <= linkedEntity.depth) ||
            (edge.target &&
              !isEntityAttackPathNode(edge.target) &&
              edge.target.depth <= linkedEntity.depth + 2)
          : (edge.target && edge.target.depth <= linkedEntity.depth) ||
            (edge.source &&
              !isEntityAttackPathNode(edge.source) &&
              edge.source.depth <= linkedEntity.depth + 2)
      )

      this.setEdges(edges)
    }

    this.removeHoveredUnexpandNodeUid()

    return this
  }

  /**
   * Move the drawer node by -90° from the parent node.
   */
  @action
  moveDrawerNodeFromParentNode(node: EntityAttackPathNode): this {
    const drawerNode = this.getDrawerNode(node.uid)

    if (!drawerNode) {
      return this
    }

    drawerNode.baseX = drawerNode.x
    drawerNode.baseY = drawerNode.y

    const [x, y] = rotateCoords(node.x, node.y, drawerNode.x, drawerNode.y, -90)

    drawerNode.x = x
    drawerNode.y = y

    drawerNode.isExpanded = true

    const drawerEdge = this.getEdgeFromChildNode(drawerNode.uid)

    if (drawerEdge) {
      drawerEdge.computeSvgProps()
    }

    return this
  }

  /**
   * Move back the drawer node to its original position.
   */
  @action
  moveBackDrawerNode(drawerNode: EntityAttackPathNodeDrawer): this {
    drawerNode.x = drawerNode.baseX
    drawerNode.y = drawerNode.baseY

    drawerNode.isExpanded = false

    const drawerEdge = this.getEdgeFromChildNode(drawerNode.uid)

    if (drawerEdge) {
      drawerEdge.computeSvgProps()
    }

    return this
  }

  /**
   * Save current adObjectId hovered to highlight adObject(s) that share this id.
   */
  @action
  setCurrentNodeId(id: string): this {
    this.$currentNodeId.set(id)
    return this
  }

  /**
   * Remove current adObjectId hovered.
   */
  @action
  removeCurrentNodeId(): this {
    this.$currentNodeId.set(null)
    return this
  }

  /**
   * Save current hovered Edge id to highlight fake edges that are related.
   */
  @action
  setCurrentEdgeId(id: string): this {
    this.$currentEdgeId.set(id)
    return this
  }

  /**
   * Remove current hovered Edge id.
   */
  @action
  removeCurrentEdgeId(): this {
    this.$currentEdgeId.set(null)
    return this
  }

  /**
   * Pin node uid clicked to highlight adObject(s) are related.
   */
  @action
  setCurrentPinnedNodeUid(id: string): this {
    this.$currentPinnedNodeUid.set(id)
    return this
  }

  /**
   * Remove current node uid hovered.
   */
  @action
  removeCurrentPinnedNodeUid(): this {
    this.$currentPinnedNodeUid.set(null)
    return this
  }

  /**
   * Pin adObject Id clicked to highlight adObject(s) are related.
   */
  @action
  setCurrentPinnedNodeId(id: number): this {
    this.$currentPinnedNodeId.set(id)
    return this
  }

  /**
   * Remove current adObjectId hovered.
   */
  @action
  removeCurrentPinnedNodeId(): this {
    this.$currentPinnedNodeId.set(null)
    return this
  }

  /**
   * Save chart zoom data (on zoom event).
   */
  @action
  setChartZoomData(chartZoomData: IChartZoomData): this {
    this.$chartZoomData.set(chartZoomData)
    return this
  }

  /**
   * Save handleZoom behavior information.
   */
  @action
  setHandleZoom(handleZoom: ZoomBehavior<SVGSVGElement, any>): this {
    this._handleZoom = handleZoom
    return this
  }

  /**
   * Save handleZoom behavior information.
   */
  @action
  setZoomSelection(
    zoomSelection: Selection<SVGSVGElement, unknown, null, undefined>
  ): this {
    this._zoomSelection = zoomSelection
    return this
  }

  /**
   * Save the new direction and fetch nodes
   */
  @action
  setDirection(direction: AttackPathDirection): this {
    this.$direction.set(direction)

    return this
  }

  /**
   * Save zoomSlider value (on zoomSlider event).
   */
  @action
  setZoomSliderValue(k: number): this {
    this.$zoomSliderValue.set(k)

    if (this._handleZoom && this._zoomSelection) {
      this._handleZoom.scaleTo(this._zoomSelection, k)
    }
    return this
  }

  /**
   * Save zoomSlider min value.
   */
  @action
  setZoomSliderMinValue(value: number): this {
    this.$zoomSliderMinValue.set(value)

    return this
  }

  /**
   * Save zoomSlider max value.
   */
  @action
  setZoomSliderMaxValue(value: number): this {
    this.$zoomSliderMaxValue.set(value)

    return this
  }

  /**
   * Save target node display.
   */
  @action
  setTargetNodeDisplay(value: boolean): this {
    this.$targetNodeDisplay.set(value)

    return this
  }

  /**
   * Save isShowingAllTooltips value.
   */
  @action
  setIsShowingAllTooltips(value: boolean): this {
    this.$isShowingAllTooltips.set(value)

    return this
  }

  /**
   * Init the store with some query string parameters
   */
  @action
  initStoreWithQueryStringParameters(
    queryStringParams?: AttackPathQueryStringParameters
  ): this {
    if (!queryStringParams) {
      return this
    }

    // Ensure that we only fetch nodes if required
    let fetchNodesRequired = false

    if (
      queryStringParams.direction &&
      queryStringParams.direction !== this.direction
    ) {
      this.setDirection(queryStringParams.direction)
      if (queryStringParams.direction === AttackPathDirection.From) {
        this.setActiveTab(AttackPathSearchTabs.blastRadius)
      }
      if (queryStringParams.direction === AttackPathDirection.To) {
        this.setActiveTab(AttackPathSearchTabs.assetExposure)
      }
      fetchNodesRequired = true
    }

    if (queryStringParams.sourceNodeId) {
      const sourceNodeId = Number(queryStringParams.sourceNodeId)

      if (!this.sourceNode || this.sourceNode.id !== sourceNodeId) {
        // Assign partial source node. Missing data will be defined after fetching data
        this.setSourceNode({
          id: Number(sourceNodeId)
        })
        fetchNodesRequired = true
      }
    }

    if (!queryStringParams.targetNodeId && this.targetNode) {
      this.setTargetNodeDisplay(false)
      this.removeTargetNode()
      fetchNodesRequired = true
    }

    if (queryStringParams.targetNodeId) {
      const targetNodeId = Number(queryStringParams.targetNodeId)

      if (!this.targetNode || this.targetNode.id !== targetNodeId) {
        // Assign partial target node. Missing data will be defined after fetching data
        this.setTargetNode({
          id: targetNodeId
        })
        this.setTargetNodeDisplay(true)
        fetchNodesRequired = true
      }

      this.setActiveTab(AttackPathSearchTabs.attackPath)
    }

    if (fetchNodesRequired) {
      this.fetchNodes()
    }

    return this
  }

  /**
   * Store attack path placement algorithm result
   */
  @action
  setPlacementResult(placementResult: PlacementAlgorithmData): this {
    this.$placementResult.set(placementResult)

    return this
  }

  /**
   * Store root node
   */
  @action
  setRootNodeEntity(rootNodeEntity?: EntityAttackPathNode): this {
    this.$rootNodeEntity.set(rootNodeEntity ?? null)

    return this
  }

  /**
   * Set source search suggestions
   */
  @action
  setSearchSourceSuggestions(searchSourceSuggestions: AttackPathNode[]): this {
    this.$searchSourceSuggestions.replace(searchSourceSuggestions)

    return this
  }

  /**
   * Remove source search suggestions
   */
  @action
  removeSearchSourceSuggestions(): this {
    this.$searchSourceSuggestions.replace([])

    return this
  }

  /**
   * Set target search suggestions
   */
  @action
  setSearchTargetSuggestions(searchTargetSuggestions: AttackPathNode[]): this {
    this.$searchTargetSuggestions.replace(searchTargetSuggestions)

    return this
  }

  /**
   * Remove target search suggestions
   */
  @action
  removeSearchTargetSuggestions(): this {
    this.$searchTargetSuggestions.replace([])

    return this
  }

  /**
   * Store selected source node
   */
  @action
  setSelectedSearchedSourceNode(
    selectedSearchedSourceNode: Maybe<AttackPathNode>
  ): this {
    this.$selectedSearchedSourceNode.set(selectedSearchedSourceNode)

    return this
  }

  /**
   * Remove selected source node
   */
  @action
  removeSelectedSearchedSourceNode(): this {
    this.$selectedSearchedSourceNode.set(null)

    return this
  }

  /**
   * Store selected target node
   */
  @action
  setSelectedSearchedTargetNode(
    selectedSearchedTargetNode: Maybe<AttackPathNode>
  ): this {
    this.$selectedSearchedTargetNode.set(selectedSearchedTargetNode)

    return this
  }

  /**
   * Remove selected target node
   */
  @action
  removeSelectedSearchedTargetNode(): this {
    this.$selectedSearchedTargetNode.set(null)

    return this
  }

  /**
   * Store source node
   */
  @action
  setSourceNode(sourceNode: AttackPathNode): this {
    this.$sourceNode.set(sourceNode)

    return this
  }

  /**
   * Remove source node
   */
  @action
  removeSourceNode(): this {
    this.$sourceNode.set(null)

    return this
  }

  /**
   * Store target node
   */
  @action
  setTargetNode(targetNode: Maybe<AttackPathNode>): this {
    this.$targetNode.set(targetNode)

    return this
  }

  /**
   * Remove target node
   */
  @action
  removeTargetNode(): this {
    this.$targetNode.set(null)

    return this
  }

  /**
   * Store is truncated
   */
  @action
  setIsTruncated(
    isTruncated: Maybe<boolean>,
    truncationType: AttackPath['truncationType']
  ): this {
    this.$isTruncated.set(isTruncated)
    this.$truncationType.set(truncationType)

    return this
  }

  /**
   * Store display is truncated alert
   */
  @action
  setDisplayIsTruncatedAlert(displayIsTruncatedAlert: Maybe<boolean>): this {
    this.$displayIsTruncatedAlert.set(displayIsTruncatedAlert)

    return this
  }

  /**
   * Store no attack path two nodes result
   */
  @action
  setNoAttackPathTwoNodesResult(noAttackPathResult: boolean): this {
    this.$noAttackPathTwoNodesResult.set(noAttackPathResult)

    return this
  }

  @action
  setPopoverVisible(isVisible: boolean): this {
    this.$isPopoverVisible.set(isVisible)
    return this
  }

  /**
   * Store drawer children nodes with edge
   */
  @action
  setDrawerChildrenNodesWithEdge(childrenNodesWithEdge: INodeWithEdge[]): this {
    const orderedChildrenNodesWithEdge = sortNodesWithEdges(
      childrenNodesWithEdge,
      this.storeRoot.stores.storeInfrastructures
    )

    this.$drawerChildrenNodesWithEdge.replace(orderedChildrenNodesWithEdge)

    this.storeWidgetListDrawerNodeRelationships.setPagination(
      new EntityPagination({
        page: 0,
        perPage: 20,
        totalCount: orderedChildrenNodesWithEdge.length
      })
    )

    this.storeWidgetListDrawerNodeRelationships.setEntities(
      orderedChildrenNodesWithEdge.map(({ node }) => node)
    )

    return this
  }

  /**
   * Store drawer ad object type filter
   */
  @action
  setDrawerAdObjectTypeFilter(drawerAdObjectTypeFilter: string[]): this {
    this.$drawerAdObjectTypeFilter.replace(drawerAdObjectTypeFilter)

    return this
  }

  /**
   * Empty graph from all nodes and edges
   */
  @action
  cleanGraph(): this {
    this.setNoAttackPathTwoNodesResult(false)

    this.$edges.clear()
    this.$nodes.clear()

    return this
  }

  /**
   * Swap source and target input values on node finder.
   */
  @action
  swapSourceAndTargetInputValues(): this {
    const sourceSearchValue = this.storeInputSearchSource.searchValue
    const targetSearchValue = this.storeInputSearchTarget.searchValue

    const selectedSearchedSourceNode = this.selectedSearchedSourceNode
    const selectedSearchedTargetNode = this.selectedSearchedTargetNode

    const searchSourceSuggestions = this.searchSourceSuggestions
    const searchTargetSuggestions = this.searchTargetSuggestions

    this.storeInputSearchSource.setSearchValue(targetSearchValue)
    this.storeInputSearchTarget.setSearchValue(sourceSearchValue)

    this.setSelectedSearchedSourceNode(selectedSearchedTargetNode)
    this.setSelectedSearchedTargetNode(selectedSearchedSourceNode)

    this.setSearchSourceSuggestions(searchTargetSuggestions)
    this.setSearchTargetSuggestions(searchSourceSuggestions)

    return this
  }

  @action
  setActiveTab(tab: AttackPathSearchTabs): this {
    this.$activeTab.set(tab)
    return this
  }

  @action
  setCustomErrorMessage(message: Maybe<string>): this {
    this.$customErrorMessage.set(message)
    return this
  }

  /**
   * Reset store
   */
  @action
  reset(): this {
    this.storeFlagsSearchSourceNodes.reset()
    this.storeFlagsFetchNodes.reset()
    this.storeFlagsFetchExpandedNodes.reset()

    this.storeInputSearchSource.reset()
    this.storeInputSearchTarget.reset()

    this._chartSvgRef = null
    this._handleZoom = null
    this._zoomSelection = null
    this._maxOnionRingIndex = 0
    this._requestUuid = null

    this.$sceneDimensions.set(null)
    this.$sceneIsReady.set(true)
    this.$chartZoomData.set(null)
    this.$direction.set(AttackPathDirection.From)
    this.$zoomSliderValue.set(1)
    this.$zoomSliderMinValue.set(1)
    this.$zoomSliderMaxValue.set(4)
    this.$targetNodeDisplay.set(false)
    this.$isShowingAllTooltips.set(false)

    this.$activeTab.set(AttackPathSearchTabs.blastRadius)

    this.$adObjectId.set(null)
    this.$currentNodeId.set(null)
    this.$currentEdgeId.set(null)
    this.$currentPinnedNodeUid.set(null)
    this.$currentPinnedNodeId.set(null)

    this.$hoveredExpandNodeUid.set(null)
    this.$hoveredUnexpandNodeUid.set(null)
    this.$expandedNodeUids.clear()

    this.$nodes.clear()
    this.$edges.clear()

    this.$placementResult.set(null)
    this.$rootNodeEntity.set(null)

    this.$searchSourceSuggestions.clear()
    this.$searchTargetSuggestions.clear()

    this.$selectedSearchedSourceNode.set(null)
    this.$selectedSearchedTargetNode.set(null)

    this.$sourceNode.set(null)
    this.$targetNode.set(null)

    this.$isTruncated.set(null)
    this.$displayIsTruncatedAlert.set(null)

    this.$customErrorMessage.set(null)

    return this
  }

  /* Computed */

  /**
   * Return the dimension (width, height) of the scene (the main div container).
   * If not defined, throw an exception to avoid to handle nullable values.
   */
  @computed
  get sceneDimensions(): ISceneDimensions {
    const sceneDimensions = this.$sceneDimensions.get()

    if (!sceneDimensions) {
      throw new Error('Scene dimensions are not defined')
    }

    return sceneDimensions
  }

  /**
   * Return the chart SVG DOM node.
   */
  @computed
  get chartSvgRef(): Maybe<SVGSVGElement> {
    return this._chartSvgRef
  }

  @computed
  get direction(): AttackPathDirection {
    return this.$direction.get()
  }

  /**
   * Return true if the scene is ready to be rendered.
   */
  @computed
  get sceneIsReady(): boolean {
    const sceneDimensions = this.$sceneDimensions.get()

    if (!sceneDimensions) {
      this.storeRoot.logger.debug(
        'The scene as ready, missing some initialization data'
      )
      return false
    }

    return this.$sceneIsReady.get()
  }

  /**
   * Return linked nodes.
   */
  @computed
  get linkedNodes(): Map<string, EntityAttackPathNode> {
    return toJS(this.$linkedNodes)
  }

  /**
   * Return linked edges.
   */
  @computed
  get linkedEdges(): EntityAttackPathEdge[] {
    return toJS(this.$linkedEdges)
  }

  /**
   * Return attackPathNodes entities.
   */
  @computed
  get nodes(): EntityAttackPathNodeAny[] {
    return Array.from(this.$nodes.values())
  }

  /**
   * Return edges between each node.
   */
  @computed
  get edges(): EntityAttackPathEdge[] {
    return Array.from(this.$edges.values())
  }

  /**
   * Return the current hovered adObject id.
   */
  @computed
  get adObjectId(): Maybe<number> {
    return this.$adObjectId.get()
  }

  /**
   * Return the current hovered node id.
   */
  @computed
  get currentNodeId(): Maybe<string> {
    return this.$currentNodeId.get()
  }

  /**
   * Return the current hovered Edge id.
   */
  @computed
  get currentEdgeId(): Maybe<string> {
    return this.$currentEdgeId.get()
  }

  /**
   * Return the current pinned node uid.
   */
  @computed
  get currentPinnedNodeUid(): Maybe<string> {
    return this.$currentPinnedNodeUid.get()
  }

  /**
   * Return the current pinned node id.
   */
  @computed
  get currentPinnedNodeId(): Maybe<number> {
    return this.$currentPinnedNodeId.get()
  }

  /**
   * Return the current hovered expand node uid.
   */
  @computed
  get hoveredExpandNodeUid(): Maybe<string> {
    return this.$hoveredExpandNodeUid.get()
  }

  /**
   * Return the current hovered unexpand node uid.
   */
  @computed
  get hoveredUnexpandNodeUid(): Maybe<string> {
    return this.$hoveredUnexpandNodeUid.get()
  }

  /**
   * Return current nodes uid that have generated the expansion set of nodes
   */
  @computed
  get expandedNodeUids(): string[] {
    return toJS(this.$expandedNodeUids)
  }

  /**
   * Return the zoom and position of the chart.
   */
  @computed
  get chartZoomData(): IChartZoomData {
    const defaultChartZoomData: IChartZoomData = {
      x: 0,
      y: 0,
      k: 1
    }
    return this.$chartZoomData.get() || defaultChartZoomData
  }

  /**
   * Return the handleZoom behavior.
   */
  @computed
  get handleZoom(): Maybe<ZoomBehavior<SVGSVGElement, any>> {
    return this._handleZoom
  }

  /**
   * Return the zoomSelction behavior.
   */
  @computed
  get zoomSelection(): Maybe<
    Selection<SVGSVGElement, unknown, null, undefined>
  > {
    return this._zoomSelection
  }

  /**
   * Return current node ids that are travelled to get to the center.
   */
  @computed
  get currentPath(): Set<string> {
    const idsTravelled = new Set<string>()

    if (!this.currentNodeId) {
      return idsTravelled
    }

    const getNextNodeId = (node: EntityAttackPathNodeAny) => {
      idsTravelled.add(node.getPropertyAsString('uid'))

      if (!node.linkedNodeEntity) {
        return
      }

      // we dont need the centered node with depth 0
      if (this.direction === AttackPathDirection.To || node.depth > 1) {
        getNextNodeId(node.linkedNodeEntity)
      }
    }

    const currentNodeEntity = this.$nodes.get(this.currentNodeId)

    if (!currentNodeEntity || currentNodeEntity.depth === 0) {
      return idsTravelled
    }

    getNextNodeId(currentNodeEntity)

    return idsTravelled
  }

  /**
   * Return current node ids that are travelled to get to the center.
   */
  @computed
  get currentPinnedPath(): Set<string> {
    const idsTravelled = new Set<string>()

    if (!this.currentPinnedNodeUid) {
      return idsTravelled
    }

    const getNextNodeId = (node: EntityAttackPathNodeAny) => {
      idsTravelled.add(node.getPropertyAsString('uid'))

      if (!node.linkedNodeEntity) {
        return
      }

      // we dont need the centered node with depth 0
      if (this.direction === AttackPathDirection.To || node.depth > 1) {
        getNextNodeId(node.linkedNodeEntity)
      }
    }

    const currentNodeEntity = this.$nodes.get(this.currentPinnedNodeUid)

    if (!currentNodeEntity || currentNodeEntity.depth === 0) {
      return idsTravelled
    }

    getNextNodeId(currentNodeEntity)

    return idsTravelled
  }

  /**
   * Return the zoom slider value.
   */
  @computed
  get zoomSliderValue(): Maybe<number> {
    return this.$zoomSliderValue.get()
  }

  /**
   * Return the zoom slider min value.
   */
  @computed
  get zoomSliderMinValue(): number {
    return this.$zoomSliderMinValue.get()
  }

  /**
   * Return the zoom slider max value.
   */
  @computed
  get zoomSliderMaxValue(): number {
    return this.$zoomSliderMaxValue.get()
  }

  /**
   * Return the maximum onion ring index
   */
  @computed
  get maxOnionRingIndex(): number {
    return this._maxOnionRingIndex
  }

  /**
   * Return the querystring filters according to the current state of local
   * stores.
   */
  @computed
  get computedQueryString(): AttackPathQueryStringParameters {
    return filterNullOrUndefinedValues({
      sourceNodeId: this.sourceNode?.id
        ? String(this.sourceNode.id)
        : undefined,
      targetNodeId: this.targetNode?.id
        ? String(this.targetNode.id)
        : undefined,
      direction: this.targetNode ? undefined : this.direction
    })
  }

  @computed
  get awaitingGraphToDisplay(): AttackPathStats {
    const placementResult = this.$placementResult.get()

    if (!placementResult) {
      return { nodes: 0, edges: 0 }
    }

    const edges = placementResult.edges.length ?? 0
    const nodes = placementResult.nodes.length ?? 0

    return {
      nodes,
      edges
    }
  }

  @computed
  get rootNodeEntity(): Maybe<EntityAttackPathNode> {
    return this.$rootNodeEntity.get()
  }

  /**
   * Return true if the graph is displayed.
   */
  @computed
  get searchIsDone(): boolean {
    return isDefined(this.sourceNode)
  }

  /**
   * Return true if a search can be done
   */
  @computed
  get canValidate(): boolean {
    if (!this.targetNodeDisplay) {
      return isDefined(this.selectedSearchedSourceNode)
    }

    return (
      isDefined(this.selectedSearchedSourceNode) &&
      isDefined(this.selectedSearchedTargetNode)
    )
  }

  /**
   * Return targetNodeDisplay value.
   */
  @computed
  get targetNodeDisplay(): boolean {
    return this.$targetNodeDisplay.get()
  }

  /**
   * Return searchSourceSuggestions value
   */
  @computed
  get searchSourceSuggestions(): AttackPathNode[] {
    return toJS(this.$searchSourceSuggestions)
  }

  /**
   * Return searchTargetSuggestions value
   */
  @computed
  get searchTargetSuggestions(): AttackPathNode[] {
    return toJS(this.$searchTargetSuggestions)
  }

  /**
   * Return selectedSearchedSourceNode value
   */
  @computed
  get selectedSearchedSourceNode(): Maybe<AttackPathNode> {
    return this.$selectedSearchedSourceNode.get()
  }

  /**
   * Return selectedSearchedTargetNode value
   */
  @computed
  get selectedSearchedTargetNode(): Maybe<AttackPathNode> {
    return this.$selectedSearchedTargetNode.get()
  }

  /**
   * Return sourceNode value
   */
  @computed
  get sourceNode(): Maybe<AttackPathNode> {
    return this.$sourceNode.get()
  }

  /**
   * Return targetNode value
   */
  @computed
  get targetNode(): Maybe<AttackPathNode> {
    return this.$targetNode.get()
  }

  /**
   * Return isTruncated value
   */
  @computed
  get isTruncated(): Maybe<boolean> {
    return this.$isTruncated.get()
  }

  /**
   * Return isTruncated value
   */
  @computed
  get truncationType(): Maybe<AttackPath['truncationType']> {
    return this.$truncationType.get()
  }

  /**
   * Return displayIsTruncatedAlert value
   */
  @computed
  get displayIsTruncatedAlert(): Maybe<boolean> {
    return this.$displayIsTruncatedAlert.get()
  }

  /**
   * Return noAttackPathTwoNodesResult value
   */
  @computed
  get noAttackPathTwoNodesResult(): boolean {
    return this.$noAttackPathTwoNodesResult.get()
  }

  /**
   * Return isShowingAllTooltips value.
   */
  @computed
  get isShowingAllTooltips(): boolean {
    return this.$isShowingAllTooltips.get()
  }

  @computed
  get isPopoverVisible(): boolean {
    return this.$isPopoverVisible.get()
  }

  /**
   * Return the current active Tab
   */
  @computed
  get activeTab(): AttackPathSearchTabs {
    return this.$activeTab.get()
  }

  /**
   * Return drawerChildrenNodesWithEdge value
   */
  @computed
  get drawerChildrenNodesWithEdge(): INodeWithEdge[] {
    return toJS(this.$drawerChildrenNodesWithEdge)
  }

  /**
   * Return drawerAdObjectTypeFilter value
   */
  @computed
  get drawerAdObjectTypeFilter(): string[] {
    return toJS(this.$drawerAdObjectTypeFilter)
  }

  /**
   * Return filteredByNameDrawerChildrenNodesWithEdge value
   */
  @computed
  get filteredByNameDrawerChildrenNodesWithEdge(): INodeWithEdge[] {
    return this.drawerChildrenNodesWithEdge.filter(({ node }) =>
      node.name?.match(
        this.storeInputSearchDrawer.transformedSearchValueAsRegexp
      )
    )
  }

  /**
   * Return filteredDrawerChildrenNodesWithEdge value
   */
  @computed
  get filteredDrawerChildrenNodesWithEdge(): INodeWithEdge[] {
    return this.filteredByNameDrawerChildrenNodesWithEdge.filter(({ node }) => {
      if (this.drawerAdObjectTypeFilter.length === 0) {
        return true
      }

      if (!node.adObjectType) {
        return false
      }

      return this.drawerAdObjectTypeFilter.includes(node.adObjectType)
    })
  }

  /**
   * Return paginated drawerChildrenNodesWithEdge value
   */
  @computed
  get paginatedDrawerChildrenNodesWithEdge(): INodeWithEdge[] {
    const { paginationPage, rowsPerPage: paginationPerPage } =
      this.storeWidgetListDrawerNodeRelationships

    return this.filteredDrawerChildrenNodesWithEdge.slice(
      (paginationPage - 1) * paginationPerPage,
      paginationPage * paginationPerPage
    )
  }

  /**
   * Return true if all parts of the graph must be highlighted
   */
  @computed
  get isAllGraphHighlighted(): boolean {
    const currentPinnedNodeid = this.currentPinnedNodeUid
      ? this.$nodes.get(this.currentPinnedNodeUid)?.getPropertyAsNumber('id')
      : undefined

    return (
      (this.sourceNode &&
        this.targetNode &&
        (this.targetNode.id === this.adObjectId ||
          this.targetNode.id === currentPinnedNodeid)) ||
      false
    )
  }

  @computed
  get customErrorMessage(): Maybe<string> {
    return this.$customErrorMessage.get()
  }

  /**
   * On a between two nodes path, return all node ids that are linked to the target node
   */
  get targetNodeLinkedIds(): Maybe<Set<number>> {
    if (!this.targetNode) {
      return null
    }

    const targetNodesLinkedIds = new Set<number>()
    const targetNodes = this.nodes.filter(
      node => this.targetNode && node.id === this.targetNode.id
    )

    targetNodes.forEach(
      node =>
        node.linkedNodeEntity &&
        targetNodesLinkedIds.add(
          node.linkedNodeEntity.getPropertyAsNumber('id')
        )
    )

    return targetNodesLinkedIds
  }
}
