import { Features } from '@alsid/common'
import { useHistoryChange } from '@app/components-legacy/Router/hooks'
import { useAppRouter, useStores, useAppTranslator } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import {
  getActivityLogsParentsFromPathname,
  isActivityLogsAvailableOnRoute
} from '@libs/activityLogs/utils'
import { Language } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import type { IPageVisited } from '../entities/EntityUserLog'

/**
 * Handle activity logs when a page loads.
 */
export function usePageLifeCycleActivityLogs() {
  const featureActivityLogs = useFeatureFlag({
    featureFlagName: Features.TRACEABILITY_DASHBOARD
  })

  const appRouter = useAppRouter()
  const { storeActivityLogs, storeAuthentication } = useStores()

  // Use a forced language as the feature is not translated
  const translate = useAppTranslator({
    namespaces: ['RouteNames.Pages'],
    language: Language.EnUs
  })

  const { currentRouteInfo } = useHistoryChange()

  const currentRouteName = currentRouteInfo?.routeName || null
  const isActivityLogsAvailableOnRouteFn =
    isActivityLogsAvailableOnRoute(appRouter)

  // Handles activity logs when the page loads for the first time of a session
  const onRouteEnterActivityLogs = React.useCallback(() => {
    if (!featureActivityLogs) {
      return
    }

    if (!currentRouteName) {
      return
    }

    // Do not track page visited when not already logged
    if (!storeAuthentication.whoAmI) {
      return
    }

    if (!isActivityLogsAvailableOnRouteFn(currentRouteName)) {
      return
    }
    const pathName = appRouter.getRoutePathname(currentRouteName)
    const parents = getActivityLogsParentsFromPathname(pathName).map(parent =>
      translate(parent)
    )

    const params: IPageVisited = {
      routeName: translate(currentRouteName),
      routePath: appRouter.history.location()?.pathname,
      parents: parents.length > 0 ? parents : undefined
    }

    storeActivityLogs.savePageVisited(params)
  }, [currentRouteName])

  /**
   * Log pageVisited events when a page loads.
   */
  React.useEffect(() => {
    onRouteEnterActivityLogs()
  }, [currentRouteName])
}
