import { Features } from '@alsid/common'
import { emptySession } from '@app/_init/createApp/initializeTul/emptyResponses'
import {
  checkPostLoginData,
  fetchPostLoginData,
  fetchUserData
} from '@app/_init/utils/fetchData'
import {
  createEntities,
  createEntity,
  EntityAuthProvider,
  EntityCloudStatistics,
  EntityPreferences,
  EntityProfile,
  EntityWhoAmI
} from '@app/entities'
import {
  LDAPInputName,
  TenableInputName
} from '@app/pages/Auth/LoginPage/types'
import { AppRouteName } from '@app/routes'
import { getRainbowColorScheme } from '@app/styles/colors/schemes'
import { assertResponseStatus } from '@libs/assertions/assertResponseStatus'
import type { HardReloadError } from '@libs/errors'
import {
  PasswordChangeNeededError,
  UnauthorizedAccessError
} from '@libs/errors'
import { isErrorOfType } from '@libs/errors/functions'
import { handleStoreError, isRequestError } from '@libs/errors/handleStoreError'
import { ErrorName } from '@libs/errors/types'
import { isDefined } from '@libs/isDefined'
import type { ICloudStatistics } from '@libs/Pendo/types'
import type { RbacPermissionKey, RbacPermissions } from '@libs/rbac/types'
import { sanitize } from '@libs/sanitize'
import type {
  AuthProvider,
  Maybe,
  Preferences,
  Profile,
  WhoAmI
} from '@server/graphql/typeDefs/types'
import {
  AuthProviderName,
  RequestErrorCode
} from '@server/graphql/typeDefs/types'
import { uniq } from 'lodash'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import type { ServerProperties } from 'tenable-universal-layout'
import {
  isValidServerProperties,
  snakeCaseToCamelCaseJson
} from 'tenable-universal-layout'
import type { Session } from 'tenable-universal-layout/dist/libs/openapi/session/sessionResource/models/Session'
import type { ServerProperties as ServerPropertiesLegacy } from 'tenable-universal-layout-v5'
import {
  isValidServerProperties as isValidServerPropertiesLegacy,
  snakeCaseToCamelCaseJson as snakeCaseToCamelCaseJsonLegacy
} from 'tenable-universal-layout-v5'
import type { StoreRoot } from '..'
import StoreFlags from '../helpers/StoreFlags'
import StoreForm from '../helpers/StoreForm'
import {
  equalTo,
  mandatory,
  userPasswordFormat
} from '../helpers/StoreForm/validators'
import StoreMenu from '../helpers/StoreMenu'
import type { IMenuEntry } from '../helpers/StoreMenu/types'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'
import type { AuthenticationCredentials, ILoginResponse } from './types'

export enum FirstLoginPasswordFormFieldName {
  newPassword = 'newPassword',
  newPasswordConfirmation = 'newPasswordConfirmation'
}

export default class StoreAuthentication extends StoreBase {
  public storeMenu = new StoreMenu<IMenuEntry<AuthProviderName>>(
    this.storeRoot,
    {
      defaultSelectedMenuKey: AuthProviderName.Tenable
    }
  )

  public storeFlagsFetchProviders = new StoreFlags(this.storeRoot)
  public storeFlagsProcessLogin = new StoreFlags(this.storeRoot)
  public storeFlagsFetchServerProperties = new StoreFlags(this.storeRoot)
  public storeFlagsFirstLoginPassword = new StoreFlags(this.storeRoot)
  public storeFlagsFetchCloudStatistics = new StoreFlags(this.storeRoot)

  public storeForms: Record<AuthProviderName, StoreForm<any>> = {
    [AuthProviderName.Tenable]: new StoreForm<TenableInputName>(
      this.storeRoot,
      {
        setup: {
          fields: {
            [TenableInputName.email]: {
              label: 'Email address',
              validators: [
                mandatory({
                  onError: () =>
                    this.translate(
                      'Please provide an email address of a registered account'
                    )
                })
              ]
            },
            [TenableInputName.password]: {
              label: 'Password',
              validators: [
                mandatory({
                  onError: () =>
                    this.translate(
                      'Please provide the password of your account'
                    )
                })
              ]
            }
          }
        }
      }
    ),

    [AuthProviderName.Ldap]: new StoreForm<LDAPInputName>(this.storeRoot, {
      setup: {
        fields: {
          [LDAPInputName.account]: {
            label: 'LDAP Account',
            validators: [
              mandatory({
                onError: () =>
                  this.translate('Please provide a valid LDAP account')
              })
            ]
          },
          [LDAPInputName.password]: {
            label: 'LDAP Password',
            validators: [
              mandatory({
                onError: () =>
                  this.translate(
                    'Please provide the password of your LDAP account'
                  )
              })
            ]
          }
        }
      }
    }),
    // declare a form even if there is no form for SAML, to avoid undefined values
    [AuthProviderName.Saml]: new StoreForm(this.storeRoot),
    [AuthProviderName.Tone]: new StoreForm(this.storeRoot)
  }

  public storeFormFirstLoginPassword: StoreForm<FirstLoginPasswordFormFieldName> =
    new StoreForm<FirstLoginPasswordFormFieldName>(this.storeRoot, {
      setup: {
        fields: {
          newPassword: {
            label: 'New password',
            validators: [mandatory(), userPasswordFormat()]
          },
          newPasswordConfirmation: {
            label: 'Confirmation of the new password',
            validators: [
              mandatory(),
              equalTo(() =>
                this.storeFormFirstLoginPassword.getFieldValueAsString(
                  FirstLoginPasswordFormFieldName.newPassword
                )
              )({
                onError: () =>
                  this.translate('The password confirmation is incorrect')
              })
            ]
          }
        }
      }
    })

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: [
      'Buttons',
      'Login',
      'Login.Labels',
      'Login.Errors',
      'Login.FirstLogin',
      'Errors.Form'
    ]
  })

  /* Observable */

  // current logged user
  private $whoAmI = observable.box<Maybe<EntityWhoAmI>>(null)

  // Rbac permissions of the logged user
  private $rbacPermissions = observable.box<Maybe<RbacPermissions>>(null)

  // current profile
  private $currentProfile = observable.box<Maybe<EntityProfile>>(null)

  // profiles of the current user - Keys are profileId
  private $profiles = observable.map<number, EntityProfile>()

  // available providers
  private $providers = observable.map<AuthProviderName, EntityAuthProvider>()

  // Reset password nonce
  private $resetPasswordNonce = observable.box<Maybe<string>>(null)

  private $cloudStatistics = observable.box<Maybe<EntityCloudStatistics>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Call the auth API and if the login response is valid,
   * retrieve initialization data (preferences, settings, etc),
   * and connect to the websocket.
   */
  login(authenticationCredentials: AuthenticationCredentials): Promise<void> {
    this.storeFlagsProcessLogin.loading()

    return (
      Promise.resolve()
        /**
         * Try to login.
         */
        .then(() => this.tryToLogin(authenticationCredentials))

        /**
         * Handle login errors or return the auth user.
         */
        .then(async response => {
          if (response.status === 401) {
            const requestError = await response.json()

            if (isRequestError(requestError)) {
              switch (requestError.error.code) {
                case RequestErrorCode.AccountLockedOut:
                  throw new UnauthorizedAccessError('Your account is locked')

                default:
                  throw new UnauthorizedAccessError(
                    'Login or password is incorrect'
                  )
              }
            }

            throw new UnauthorizedAccessError('Login or password is incorrect')
          }

          if (response.status === 403) {
            throw new UnauthorizedAccessError('Login or password is incorrect')
          }

          if (response.status !== 200) {
            throw new UnauthorizedAccessError(
              'An error occurred during the initialization of the authentication process'
            )
          }

          return response.json() as Promise<ILoginResponse>
        })

        /**
         * Check and verify if the user needs password reset.
         */
        .then(async response => {
          if (response.needPasswordReset && response.resetPasswordNonce) {
            this.setResetPasswordNonce(response.resetPasswordNonce)

            const url = this.storeRoot.appRouter.makeRouteInfosPathname({
              routeName: AppRouteName.Auth_FirstLoginPassword,
              parameters: {
                resetPasswordNonce: response.resetPasswordNonce
              }
            })

            this.storeRoot.appRouter.history.push(url)

            this.storeFlagsProcessLogin.success()

            throw new PasswordChangeNeededError(null)
          }

          return response
        })

        /**
         * Check and verify the license.
         */
        .then(async response => {
          await this.storeRoot.stores.storeLicense.fetchLicense()

          return response
        })

        /**
         * Fetch all related user data.
         */
        .then(() =>
          fetchUserData(this.storeRoot)({
            profileName: null,
            user: null
          })
        )

        /**
         * Final steps.
         */
        .then(() => {
          const userName = this.whoAmI?.name

          if (!userName) {
            throw new UnauthorizedAccessError(
              'An error occurred during the initialization of the authentication process'
            )
          }

          // remove credentials from the stores
          Object.values(this.storeForms).forEach(storeForm => {
            storeForm.reset()
          })
        })

        /**
         * Check post login data.
         */
        .then(() => {
          const profileName =
            this.storeRoot.stores.storeAuthentication.currentProfile?.name ||
            null

          return checkPostLoginData(this.storeRoot)(profileName)
        })

        /**
         * Fetch post login data.
         * - infrastructures
         * - checkers...
         */
        .then(() => {
          return fetchPostLoginData(this.storeRoot)
        })

        .then(() => {
          this.storeFlagsProcessLogin.success()
        })

        /**
         * Redirection will be done automatically in the App component,
         * via the usePostLogin hook.
         */
        .then(() => {
          // nothing to do for redirection.
        })

        /**
         * Handle errors.
         *
         * If an HardReloadError exception is throwed, just hard-redirect.
         * Otherwise, display the translated err.message
         */
        .catch(err => {
          // We don't want to display any error alert in this case, just going to the right view, which is already handled
          if (
            isErrorOfType<PasswordChangeNeededError>(
              err,
              ErrorName.PasswordChangeNeededError
            )
          ) {
            this.storeRoot.logger.info('You have to change your password')
            return
          }

          const handler = handleStoreError(
            this.storeRoot,
            this.storeFlagsProcessLogin,
            {
              errorMessageTranslationFn: _err => {
                if (
                  isErrorOfType<HardReloadError>(
                    _err,
                    ErrorName.HardReloadError
                  )
                ) {
                  this.storeRoot.appRouter.hardRedirect(_err.getMessage())
                  return false
                }

                return isErrorOfType(_err, ErrorName.UnauthorizedAccessError)
                  ? this.translate(_err.message, { transformMarkdown: true })
                  : null
              }
            },
            {
              ariaRoles: ['alert'],
              labelledBy: err.name
            }
          )

          handler(err)
        })
    )
  }

  /**
   * Try to login with the passed credentials.
   */
  tryToLogin(
    authenticationCredentials: AuthenticationCredentials
  ): Promise<Response> {
    const url = this.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareAuth_Login,
      parameters: {
        provider: authenticationCredentials.provider
      }
    })

    return this.storeRoot.fetchClient.post(
      url,
      authenticationCredentials.credentials
    )
  }

  tryToResetPassword(newPassword: string): void {
    if (!this.resetPasswordNonce) {
      return
    }

    this.storeFlagsFirstLoginPassword.loading()

    this.resetPassword(this.resetPasswordNonce, newPassword)
      .then(response => {
        if (response.status !== 200) {
          throw new Error('An error has occurred when resetting the password')
        }

        this.storeRoot.stores.storeMessages.success(
          this.translate('Your password has been edited'),
          {
            labelledBy: 'passwordEdited'
          }
        )

        this.storeFlagsFirstLoginPassword.success()
      })
      .then(() => {
        const url = this.storeRoot.appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.HomePage,
          parameters: {}
        })

        this.storeRoot.appRouter.hardRedirect(url)
      })
  }

  /**
   * Reset password.
   */
  resetPassword(
    resetPasswordNonce: string,
    newPassword: string
  ): Promise<Response> {
    const url = this.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareAuth_ResetPassword,
      parameters: {}
    })

    return this.storeRoot.fetchClient.post(url, {
      resetPasswordNonce,
      newPassword
    })
  }

  /**
   * Connect to the WSClient according to the configuration status.
   */
  connectToWsClient(): this {
    if (
      this.storeRoot.environment.config.app.development.live.enabled === false
    ) {
      return this
    }

    if (this.storeRoot.wsClient.isConnected()) {
      this.storeRoot.wsClient.close()
    }

    this.storeRoot.wsClient.connect()

    return this
  }

  /**
   * Fetch available providers for the login process.
   */
  fetchProviders(): Promise<void> {
    this.storeFlagsFetchProviders.loading()

    const url = this.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareAuth_Providers,
      parameters: {}
    })

    return this.storeRoot.fetchClient
      .get(url)
      .then(response => {
        assertResponseStatus(response)
        return response.json() as Promise<AuthProvider[]>
      })
      .then(providers => {
        if (!Array.isArray(providers)) {
          throw new Error('Cant retrieve authentication providers.')
        }

        this.setProviders(providers)
        this.storeFlagsFetchProviders.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsFetchProviders, {
          errorMessageTranslationFn: () =>
            this.translate(
              'An error occurred during the initialization of the authentication process'
            )
        })
      )
  }

  /**
   * Fetch serverProperties info.
   */
  fetchServerProperties(): Promise<void | ServerProperties> {
    this.storeFlagsFetchServerProperties.loading()

    const url = this.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareServerProperties,
      parameters: {}
    })

    return this.storeRoot.fetchClient
      .get(url)
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Unable to get the server properties')
        }

        return response.json()
      })
      .then(data => {
        this.storeFlagsFetchServerProperties.success()

        const camelCasedData = snakeCaseToCamelCaseJson(data)

        if (!camelCasedData) {
          return
        }

        if (!isValidServerProperties(camelCasedData)) {
          this.storeRoot.logger.warn(
            `Server properties data are invalid, disabling TUL's related widgets`
          )
          throw new Error('Invalid server properties')
        }

        return camelCasedData
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsFetchServerProperties)
      )
  }

  fetchServerPropertiesLegacy(): Promise<void | ServerPropertiesLegacy> {
    this.storeFlagsFetchServerProperties.loading()

    const url = this.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareServerProperties,
      parameters: {}
    })

    return this.storeRoot.fetchClient
      .get(url)
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Unable to get the server properties')
        }

        return response.json()
      })
      .then(data => {
        this.storeFlagsFetchServerProperties.success()

        const camelCasedData = snakeCaseToCamelCaseJsonLegacy(data)

        if (!camelCasedData) {
          return
        }

        if (!isValidServerPropertiesLegacy(camelCasedData)) {
          this.storeRoot.logger.warn(
            `Server properties data are invalid, disabling TUL's related widgets`
          )
          throw new Error('Invalid server properties')
        }

        return camelCasedData
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsFetchServerProperties)
      )
  }

  /**
   * Fetch cloud statistics (Pendo and more).
   */
  fetchCloudStatistics(): Promise<void | EntityCloudStatistics> {
    this.storeFlagsFetchCloudStatistics.loading()

    const url = this.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareCloudStatistics,
      parameters: {}
    })

    return this.storeRoot.fetchClient
      .get(url)
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Unable to get pendo init data')
        }

        return response.json()
      })
      .then((data: ICloudStatistics) => {
        this.storeFlagsFetchCloudStatistics.success()

        const entityCloudStatistics = createEntity<
          ICloudStatistics,
          EntityCloudStatistics
        >(EntityCloudStatistics, data)

        this.setCloudStatistics(entityCloudStatistics)

        return entityCloudStatistics
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsFetchCloudStatistics, {
          errorMessageTranslationFn: () => false
        })
      )
  }

  /**
   * Set the current profile from the user's preferences.
   */
  setCurrentProfileFromPreferredProfile(): boolean {
    const preferences = this.storeRoot.stores.storePreferences.preferences

    if (!preferences) {
      return false
    }

    const preferredProfileId = preferences.preferredProfileId

    if (!preferredProfileId) {
      return false
    }

    return this._saveCurrentProfile(preferredProfileId)
  }

  /**
   * Return true if the user is a Tone user.
   */
  isToneUser(): boolean {
    const { storeRbac, storeAbout } = this.storeRoot.stores

    const featureWorkspaceAppswitcherInTad =
      storeRbac.isUserGrantedAccordingFeatureFlag(
        Features.WORKSPACE_APPSWITCHER_IN_TAD
      )

    const isToneUser =
      (featureWorkspaceAppswitcherInTad &&
        storeAbout.productAssociation?.isAssociatedToTenableOne()) === true

    return isToneUser
  }

  /* Private */

  /**
   * Set the current profile from a profileName.
   */
  private _setCurrentProfileFromProfileName(
    profileName?: Maybe<string>
  ): boolean {
    if (!profileName) {
      return false
    }

    const foundProfile = Array.from(this.$profiles.entries())
      .filter(
        ([, profile]) =>
          sanitize(profile.getPropertyAsString('name')) ===
          sanitize(profileName)
      )
      .pop()

    const profileId = foundProfile && foundProfile[0] && Number(foundProfile[0])

    if (!profileId) {
      return false
    }

    return this._saveCurrentProfile(profileId)
  }

  /* Actions */

  @action
  setProviders(providers: AuthProvider[]): void {
    createEntities<AuthProvider, EntityAuthProvider>(
      EntityAuthProvider,
      providers
    ).forEach(provider => {
      if (!provider.protocol) {
        return
      }

      this.$providers.set(provider.protocol, provider)
    })
  }

  @action
  setPreferences(preferences: Preferences): void {
    this.storeRoot.stores.storePreferences.setPreferences(
      createEntity<Preferences, EntityPreferences>(
        EntityPreferences,
        preferences
      )
    )
  }

  @action
  setCloudStatistics(cloudStatistics: EntityCloudStatistics): this {
    this.$cloudStatistics.set(cloudStatistics)
    return this
  }

  /**
   * Set profiles.
   *
   * If not granted to IOE via the license features, keep only the Tenable profile.
   * (No profile management for IOA only)
   */
  @action
  setProfiles(profiles: Profile[]): void {
    if (!profiles.length) {
      return
    }

    this.$profiles.clear()

    const entities = createEntities<Profile, EntityProfile>(
      EntityProfile,
      profiles
    )

    this.$profiles.clear()

    // reverse colors to avoid to have the same colors that infrastructures
    const colors = getRainbowColorScheme(profiles.length).reverse()

    entities.forEach((profile, i) => {
      profile.setColor(colors[i])

      if (!profile.id) {
        return
      }

      this.$profiles.set(profile.id, profile)
    })
  }

  /**
   * Set the current profile after the app initialization or after a profile
   * selection in the header of the app.
   */
  @action
  setCurrentProfile(profileName?: Maybe<string>): boolean {
    if (profileName && this._setCurrentProfileFromProfileName(profileName)) {
      return true
    }

    // if no profile is set, set the profile from preferences (preferredProfileId)
    if (this.setCurrentProfileFromPreferredProfile()) {
      return true
    }

    return false
  }

  @action
  setWhoAmI(whoAmI: WhoAmI): void {
    const entity = createEntity<WhoAmI, EntityWhoAmI>(EntityWhoAmI, whoAmI)
    this.$whoAmI.set(entity)
  }

  /**
   * Set the permissions of the authed user.
   */
  @action
  setUserRbacPermissions(rbacPermissions: RbacPermissions): this {
    this.$rbacPermissions.set(rbacPermissions)
    return this
  }

  /**
   * Update the Eula version of the current user.
   */
  @action
  updateEulaVersion(eulaVersion: number): void {
    const whoAmI = this.$whoAmI.get()

    if (!whoAmI) {
      return
    }

    // create a new object to trigger the observability
    const newWhoAmI = new EntityWhoAmI({
      ...whoAmI,
      eulaVersion
    })

    this.$whoAmI.set(newWhoAmI)
  }

  @action
  setResetPasswordNonce(resetPasswordNonce: string): this {
    this.$resetPasswordNonce.set(resetPasswordNonce)
    return this
  }

  /* Private actions */

  /**
   * Save the profile for the current user.
   */
  @action
  private _saveCurrentProfile(profileId: number): boolean {
    const foundProfile = this.$profiles.get(profileId)

    if (foundProfile && foundProfile.id) {
      this.$currentProfile.set(foundProfile)
      return true
    }

    // the profile has still not been defined, fallback on the first fetched
    const firstFetchedProfile = Array.from(this.$profiles.values())[0]

    if (!firstFetchedProfile) {
      return false
    }

    this.$currentProfile.set(firstFetchedProfile)

    return true
  }

  /* Computed */

  @computed
  get providers(): Map<AuthProviderName, EntityAuthProvider> {
    return toJS(this.$providers)
  }

  @computed
  get whoAmI(): Maybe<EntityWhoAmI> {
    return this.$whoAmI.get()
  }

  @computed
  get cloudStatistics(): Maybe<EntityCloudStatistics> {
    return this.$cloudStatistics.get()
  }

  /**
   * Return the permission of the authed user.
   */
  @computed
  get rbacPermissions(): RbacPermissions {
    return this.$rbacPermissions.get() || new Map()
  }

  /**
   * Return the Tenable profile.
   * Suppose it is always the first!
   */
  @computed
  get tenableProfile(): Maybe<EntityProfile> {
    const firstEntry = Array.from(this.$profiles.entries()).shift()

    if (firstEntry) {
      return firstEntry[1]
    }

    return null
  }

  @computed
  get currentProfile(): Maybe<EntityProfile> {
    return this.$currentProfile.get()
  }

  /**
   * Return the sanitized name of the profile (for the URL).
   */
  @computed
  get currentSanitizedProfileName(): Maybe<string> {
    const currentProfile = this.$currentProfile.get()
    const currentProfileName =
      currentProfile && currentProfile.getPropertyAsString('name')

    if (!currentProfileName) {
      return null
    }

    return sanitize(currentProfileName)
  }

  /**
   * Return the current profile ID
   * (fallback on the Tenable profile if not found but it should never be the case).
   */
  @computed
  get currentProfileId(): number {
    const currentProfile = this.$currentProfile.get()

    const currentProfileId =
      currentProfile && currentProfile.getPropertyAsNumber('id')

    return currentProfileId || 1
  }

  /**
   * Return the Tenable profile + the current profile Id.
   */
  @computed
  get profileIds(): number[] {
    return uniq(
      [this.tenableProfile, this.currentProfile]
        .filter(isDefined)
        .map(p => p.id)
        .filter(isDefined)
    )
  }

  @computed
  get profiles(): Map<number, EntityProfile> {
    return toJS(this.$profiles)
  }

  /**
   * Used for debugging.
   */
  @computed
  get rbacPermissionKeys(): RbacPermissionKey[] {
    return Array.from(this.rbacPermissions.keys()).sort()
  }

  /**
   * Return true when the user is authenticated, meaning that both
   * whoAmI and rbacPermissions have been set.
   *
   * A React hook observes this property and redirects after the login
   * process when `isAuthenticated` returns true.
   */
  @computed
  get isAuthenticated(): boolean {
    return (
      // if user has been set
      isDefined(this.$whoAmI.get()) &&
      // if user's permissions have been set
      isDefined(this.$rbacPermissions.get())
    )
  }

  /**
   * Return true if the user is authenticated and active.
   */
  @computed
  get isUserActive(): boolean {
    return this.isAuthenticated && this.$whoAmI.get()?.active === true
  }

  /**
   * Return selectionnable profiles (security analysis has been started).
   */
  @computed
  get selectionnableProfiles(): EntityProfile[] {
    return Array.from(this.$profiles.values()).filter(profile => {
      return profile.isSecurityAnalysisStarted()
    })
  }

  @computed
  get resetPasswordNonce(): Maybe<string> {
    return this.$resetPasswordNonce.get()
  }

  @computed
  get tulSession(): Session {
    return { ...emptySession, name: this.currentProfile?.name ?? undefined }
  }
}
