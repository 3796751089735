import type { Maybe } from '@@types/helpers'
import type StoreTrailFlow from '@app/stores/TrailFlow/StoreTrailFlow'

export const onTrailFlowLoad = (storeTrailFlow: StoreTrailFlow) => () => {
  storeTrailFlow.fetchInitialData()
}

export const onTrailFlowUnload = (storeTrailFlow: StoreTrailFlow) => () => {
  storeTrailFlow.unregisterWS()
}

/**
 * When pausing the trail flow, unregistered events websocket.
 */
export const onPauseTrailFlow = (storeTrailFlow: StoreTrailFlow) => () => {
  storeTrailFlow.pauseTrailFlow()
}

/**
 * When restarting the trail flow, fetch events and registers websockets,
 * then scroll to the top.
 */
export const onRestartTrailFlow =
  (storeTrailFlow: StoreTrailFlow) =>
  (tableHTMLElement: Maybe<HTMLDivElement>) =>
  () => {
    storeTrailFlow.restartTrailFlow().then(() => {
      if (!tableHTMLElement) {
        return
      }

      tableHTMLElement.scrollTo({ top: 0 })
    })
  }
