import { LabelSliced } from '@app/components/Labels'
import { CheckboxTree } from '@app/components-legacy/Checkbox'
import StyledTree from '@app/components-legacy/Input/StyledTree'
import { LabelSliced as LabelSlicedDeprecated } from '@app/components-legacy/Label'
import type { EntityHealthCheckName } from '@app/entities'
import { useAppTranslator } from '@app/hooks'
import type StoreInputHealthChecks from '@app/stores/StoreInputHealthChecks'
import type { HealthCheckCodenameKey } from '@app/stores/StoreInputHealthChecks/types'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { ensureArray } from '@libs/ensureArray'
import type { TreeProps } from 'antd/lib/tree'
import Tree from 'antd/lib/tree'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  handleHealthCheckSelection,
  handleHealthCheckTypeExpandOnClick,
  handleHealthCheckTypeSelection
} from '../handlers'

export interface IHealthCheckNamesTreeProps {
  className?: string
  style?: React.CSSProperties
  withExpand?: boolean
  storeInputHealthCheckNames: StoreInputHealthChecks
}

const HealthCheckNamesTree: React.FC<IHealthCheckNamesTreeProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['HealthCheck.Filter']
  })

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const LocalLabelSliced = themeIsLegacy ? LabelSlicedDeprecated : LabelSliced

  const getTreeProps = (): TreeProps => {
    const treeProps: TreeProps = {}

    const { searchedHealthChecksTemplatesNamesIdsWithExpand } =
      props.storeInputHealthCheckNames

    const healthCheckCodenameKeys =
      props.storeInputHealthCheckNames.healthCheckCodenameKeys
        .filter(healthCheckCodenameKey => {
          return searchedHealthChecksTemplatesNamesIdsWithExpand.some(
            healthChecksTemplatesNameId =>
              props.storeInputHealthCheckNames.isHealthCheckTemplateNameIdInType(
                healthChecksTemplatesNameId,
                healthCheckCodenameKey
              )
          )
        })
        .filter(healthCheckCodenameKey => {
          const healthChecksTemplatesNames = ensureArray(
            props.storeInputHealthCheckNames.healthChecksTemplatesNamesByType.get(
              healthCheckCodenameKey
            )
          )

          return healthChecksTemplatesNames.length > 0
        })

    treeProps.expandedKeys = healthCheckCodenameKeys.filter(
      healthCheckCodenameKey =>
        props.storeInputHealthCheckNames.expandedHealthCheckTypes.has(
          healthCheckCodenameKey
        )
    )

    function getChild(
      entityHealthCheckName: EntityHealthCheckName,
      healthCheckCodenameKey?: HealthCheckCodenameKey
    ) {
      return {
        title: (
          <span
            onClick={handleHealthCheckSelection(
              props.storeInputHealthCheckNames
            )(entityHealthCheckName.getPropertyAsString('id'))}
          >
            <LocalLabelSliced
              maxLength={50}
              value={entityHealthCheckName.getPropertyAsString(
                'healthCheckLocalizedName'
              )}
            />
          </span>
        ),
        key: healthCheckCodenameKey
          ? props.storeInputHealthCheckNames.buildTreeKey(
              healthCheckCodenameKey,
              entityHealthCheckName.getPropertyAsString('id')
            )
          : `${entityHealthCheckName.getPropertyAsString('id')}`,
        icon: (
          <CheckboxTree
            onClick={handleHealthCheckSelection(
              props.storeInputHealthCheckNames
            )(entityHealthCheckName.getPropertyAsString('id'))}
            checked={
              props.storeInputHealthCheckNames.isHealthCheckNameSelected(
                entityHealthCheckName.getPropertyAsString('id')
              ) || false
            }
            color={
              healthCheckCodenameKey
                ? props.storeInputHealthCheckNames.healthCheckTypesColorScheme.get(
                    healthCheckCodenameKey
                  ) ?? ''
                : entityHealthCheckName.color
            }
          />
        )
      }
    }

    treeProps.treeData = props.withExpand
      ? healthCheckCodenameKeys.map(healthCheckCodenameKey => ({
          title: (
            <span
              onClick={handleHealthCheckTypeSelection(
                props.storeInputHealthCheckNames
              )(healthCheckCodenameKey)}
            >
              <LabelSliced
                maxLength={50}
                value={translate(healthCheckCodenameKey)}
                labelledBy={`healthCheckType-filter-${healthCheckCodenameKey}`}
              />
            </span>
          ),
          key: healthCheckCodenameKey,
          icon: (
            <CheckboxTree
              onClick={handleHealthCheckTypeSelection(
                props.storeInputHealthCheckNames
              )(healthCheckCodenameKey)}
              checked={
                props.storeInputHealthCheckNames.isHealthCheckTypeSelected(
                  healthCheckCodenameKey
                ) || false
              }
              indeterminate={
                props.storeInputHealthCheckNames.isHealthCheckTypeSelected(
                  healthCheckCodenameKey
                ) === null
              }
              color={
                props.storeInputHealthCheckNames.healthCheckTypesColorScheme.get(
                  healthCheckCodenameKey
                ) ?? ''
              }
            />
          ),
          children:
            props.storeInputHealthCheckNames.searchedHealthChecksTemplatesNamesWithExpand
              .filter(healthCheckTemplateName =>
                props.storeInputHealthCheckNames.isHealthCheckTemplateNameIdInType(
                  healthCheckTemplateName.getPropertyAsString('id'),
                  healthCheckCodenameKey
                )
              )
              .map(entityHealthCheckName => {
                return getChild(entityHealthCheckName, healthCheckCodenameKey)
              })
        }))
      : props.storeInputHealthCheckNames.searchedHealthChecksTemplatesNames.map(
          entityHealthCheckName => {
            return getChild(entityHealthCheckName)
          }
        )

    return treeProps
  }

  const LocalTree = themeIsLegacy ? Tree : StyledTree

  return (
    <LocalTree
      onExpand={handleHealthCheckTypeExpandOnClick(
        props.storeInputHealthCheckNames
      )}
      showIcon
      {...getTreeProps()}
    />
  )
}

export default observer(HealthCheckNamesTree)
