import { InputSwitch } from '@app/components-legacy/Input'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { AttacksPageContext } from '../context'
import { onAttacksFilterCloseChange } from './handlers'

interface IIoAIncidentsFilterClosedProps {}

const IoAIncidentsFilterClosed: React.FC<
  IIoAIncidentsFilterClosedProps
> = props => {
  const attacksPageContext = React.useContext(AttacksPageContext)

  const translate = useAppTranslator({
    namespaces: ['IoA.Attacks']
  })

  if (!attacksPageContext) {
    return null
  }

  return (
    <InputSwitch
      label={translate('Closed incidents')}
      labelledBy="closedAttacks"
      switchProps={{
        checked: attacksPageContext.storeAttacks.filterIsClosed,
        onChange: onAttacksFilterCloseChange(attacksPageContext.storeAttacks)
      }}
    />
  )
}

export default observer(IoAIncidentsFilterClosed)
