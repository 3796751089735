import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import type { EntityProfile } from '@app/entities'
import type { EntityGenericChecker } from '@app/entities/EntityGenericChecker/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import ProfileAlertReadOnlyProfile from '../ProfilesEditPage/ProfileAlertReadOnlyProfile'
import ConfigurationContentSerie from './ConfigurationContentSerie'
import ConfigurationFooter from './ConfigurationFooter'

interface IConfigurationContentProps {
  profileEntity: Maybe<EntityProfile>
  checkerEntity: Maybe<EntityGenericChecker>
}

const ConfigurationContent: React.FC<IConfigurationContentProps> = props => {
  if (!props.profileEntity || !props.checkerEntity) {
    return null
  }

  const checkerCodename = props.checkerEntity.genericCodename

  return (
    <>
      <ContainerFlex
        name={ConfigurationContent.name}
        direction="column"
        items={[
          <ProfileAlertReadOnlyProfile />,
          <ConfigurationContentSerie checkerCodename={checkerCodename} />
        ]}
        spaced
      />

      <Portal name={PlaceHolderName.drawerFooter}>
        <ConfigurationFooter
          profileId={props.profileEntity.getPropertyAsNumber('id')}
          checkerId={props.checkerEntity.getPropertyAsNumber('id')}
          checkerCodename={props.checkerEntity.genericCodename}
        />
      </Portal>
    </>
  )
}

export default observer(ConfigurationContent)
