import { ErrorBoundary, ErrorMessage } from '@app/components/Error'
import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { InputSearch } from '@app/components-legacy/Input'
import { LabelAltError } from '@app/components-legacy/Label'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import { useAppTranslator } from '@app/hooks'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import ConfigurationSerieForm from './ConfigurationSerieForm'

interface IConfigurationContentSerieProps {
  checkerCodename: GenericCheckerCodename
}

const ConfigurationContentSerie: React.FC<
  IConfigurationContentSerieProps
> = props => {
  const { storeManagementProfiles } = useStores()

  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Profiles.Configuration']
  })

  const { storeProfileGenericCheckersSelected } = storeManagementProfiles

  const storeProfileCheckerSerie =
    storeProfileGenericCheckersSelected.getSelectedStoreProfileCheckerSerie(
      props.checkerCodename
    )

  const getContent = () => {
    if (!storeProfileCheckerSerie) {
      return (
        <LabelAltError labelledBy="noCheckerConfiguration">
          {translate('No configuration is available for this checker')}
        </LabelAltError>
      )
    }

    const { storeInputSearchOptions } = storeProfileGenericCheckersSelected

    return (
      <ErrorBoundary errorComponent={<ErrorMessage />}>
        <ContainerFlex
          name={ConfigurationContentSerie.name}
          direction="column"
          items={[
            <InputSearch
              placeholder={translate('Search an option')}
              storeInputSearch={storeInputSearchOptions}
            />,

            <ConfigurationSerieForm
              checkerCodename={props.checkerCodename}
              storeProfileCheckerSerie={storeProfileCheckerSerie}
            />
          ]}
          spaced
        />
      </ErrorBoundary>
    )
  }

  return (
    <ContainerContent
      flags={[
        storeManagementProfiles.storeProfileGenericCheckersSelected
          .storeFlagsFetchCheckers.flags
      ]}
    >
      {getContent()}
    </ContainerContent>
  )
}

export default observer(ConfigurationContentSerie)
