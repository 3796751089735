import type { PropertiesNullable } from '@@types/helpers'
import type { ProductAssociation, Maybe } from '@server/graphql/typeDefs/types'

export default class EntityProductAssociation
  implements PropertiesNullable<ProductAssociation>
{
  productAssociation: Maybe<string> = null

  constructor(data: Partial<ProductAssociation>) {
    Object.assign(this, data)
  }

  /**
   * Return true if productAssociation is t.one or t.ep
   */
  isAssociatedToTenableOne(): boolean {
    return ['t.one', 't.ep'].includes(
      this.productAssociation?.toLowerCase() ?? ''
    )
  }
}
