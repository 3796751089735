import type { StoreInputCrontab } from '@app/stores/helpers/StoreInputCrontab'
import { InputCrontabUnit } from '@app/stores/helpers/StoreInputCrontab/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ContainerFlex } from 'tenable-design-system-alt'
import SelectDaysOfTheMonth from '../Controls/SelectDaysOfTheMonth'
import { handleDaysOfTheMonthOnChange } from '../handlers'
import ConfigurationByWeek from './ConfigurationByWeek'

export interface IConfigurationByMonthProps {
  storeInputCrontab: StoreInputCrontab
}

function ConfigurationByMonth(props: IConfigurationByMonthProps) {
  const daysOfTheMonth = JSON.parse(
    props.storeInputCrontab.storeForm.getFieldValueAsString(
      InputCrontabUnit.daysOfTheMonth
    )
  )

  return (
    <ContainerFlex
      name="ConfigurationByMonth"
      flexDirection="column"
      flexGap="spacingM"
    >
      <SelectDaysOfTheMonth
        days={daysOfTheMonth}
        onChange={handleDaysOfTheMonthOnChange(props.storeInputCrontab)}
      />
      <ConfigurationByWeek storeInputCrontab={props.storeInputCrontab} />
    </ContainerFlex>
  )
}

export default observer(ConfigurationByMonth)
