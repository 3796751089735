import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import * as React from 'react'

interface IIconSkullHeadProps extends IIconProps {}

const IconSkullHead: React.FC<IIconSkullHeadProps> = props => {
  const size = props.size ?? 23
  const fill = props.color ?? consts.FontColorV2.secondary

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox={`0 0 18 17`}
      fill="none"
    >
      <path
        d="M8.99998 0.676758C4.51858 0.676758 0.876953 4.3187 0.876953 8.79979C0.876953 13.2809 4.5189 16.9228 8.99998 16.9228C13.4745 16.9228 17.123 13.2809 17.123 8.79979C17.123 4.3187 13.4745 0.676758 8.99998 0.676758ZM4.45793 9.28033C4.19398 9.01638 4.19398 8.58312 4.45793 8.31906L5.33117 7.44581L4.45793 6.57257C4.19398 6.30862 4.19398 5.87536 4.45793 5.6113C4.72188 5.34735 5.14851 5.34735 5.41244 5.6113L6.29244 6.49142L7.16556 5.61142C7.42951 5.34747 7.85614 5.34747 8.12008 5.61142C8.38403 5.87537 8.38403 6.30863 8.12008 6.5727L7.24683 7.44594L8.12008 8.31918C8.38403 8.58313 8.38403 9.01639 8.12008 9.28046C7.86668 9.53385 7.43478 9.54969 7.16556 9.28046L6.28556 8.40046L5.41232 9.28046C5.14284 9.54981 4.71108 9.53336 4.45793 9.28034V9.28033ZM12.3845 12.8613H5.61544C5.23635 12.8613 4.9385 12.5567 4.9385 12.1844C4.9385 11.8121 5.23634 11.5074 5.61544 11.5074H12.3845C12.7568 11.5074 13.0615 11.8121 13.0615 12.1844C13.0615 12.5567 12.7568 12.8613 12.3845 12.8613ZM13.5352 8.31925C13.7992 8.5832 13.7992 9.01646 13.5352 9.28052C13.2818 9.53391 12.8499 9.54975 12.5807 9.28052L11.7007 8.40052L10.8275 9.28052C10.558 9.55012 10.1262 9.53367 9.87294 9.28052C9.60899 9.01657 9.60899 8.58331 9.87294 8.31925L10.7462 7.446L9.87294 6.57276C9.60899 6.30881 9.60899 5.87555 9.87294 5.61149C10.1369 5.34754 10.5635 5.34754 10.8275 5.61149L11.7076 6.49161L12.5807 5.61161C12.8447 5.34766 13.2713 5.34766 13.5352 5.61161C13.7992 5.87556 13.7992 6.30882 13.5352 6.57289L12.662 7.44613L13.5352 8.31925Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconSkullHead
