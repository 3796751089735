import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  DrawerTitle,
  DrawerHorizontalContainer
} from '@app/components-legacy/Drawer'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreInputExpression from '@app/stores/helpers/StoreInputExpression'
import { grant } from '@libs/rbac/permissions'
import * as React from 'react'
import FormButtons from './FormButtons'
import FormContent from './FormContent'
import FormPreviewer from './FormPreviewer'

export interface IDrawerWizardProps {
  onValidate?: () => void
  storeInputExpression: StoreInputExpression
}

const DrawerWizard: React.FC<IDrawerWizardProps> = props => {
  const { storeWizard } = props.storeInputExpression

  const translate = useAppTranslator({
    namespaces: ['Components.Wizard']
  })

  return (
    <DrawerHorizontalContainer labelledBy="inputExpression" variant="large">
      <BladeContentLayoutDefault
        layout={{
          name: 'default',
          title: (
            <DrawerTitle>{translate('Edit query expression')}</DrawerTitle>
          ),
          header: <FormPreviewer storeWizard={storeWizard} />,
          content: (
            <FormContent storeInputExpression={props.storeInputExpression} />
          ),
          footer: (
            <FormButtons
              storeInputExpression={props.storeInputExpression}
              onValidate={props.onValidate}
            />
          )
        }}
        // no specific permissions needed for now
        rbacCapabilityCheck={grant()}
      />
    </DrawerHorizontalContainer>
  )
}

export default DrawerWizard
