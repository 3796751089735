export enum StatusCodeDefinition {
  'Continue' = 'Continue',
  'Switching_Protocols' = 'Switching Protocols',
  'Processing' = 'Processing',
  'OK' = 'OK',
  'Created' = 'Created',
  'Accepted' = 'Accepted',
  'Non_Authoritative_Information' = 'Non-Authoritative Information',
  'No_Content' = 'No Content',
  'Reset_Content' = 'Reset Content',
  'Partial_Content' = 'Partial Content',
  'Multi_Status' = 'Multi-Status',
  'Multiple_Choices' = 'Multiple Choices',
  'Moved_Permanently' = 'Moved Permanently',
  'Moved_Temporarily' = 'Moved Temporarily',
  'See_Other' = 'See Other',
  'Not_Modified' = 'Not Modified',
  'Use_Proxy' = 'Use Proxy',
  'Temporary_Redirect' = 'Temporary Redirect',
  'Bad_Request' = 'Bad Request',
  'Unauthorized' = 'Unauthorized',
  'Payment_Required' = 'Payment Required',
  'Forbidden' = 'Forbidden',
  'Not_Found' = 'Not Found',
  'Method_Not_Allowed' = 'Method Not Allowed',
  'Not_Acceptable' = 'Not Acceptable',
  'Proxy_Authentication_Required' = 'Proxy Authentication Required',
  'Request_Time_out' = 'Request Time-out',
  'Conflict' = 'Conflict',
  'Gone' = 'Gone',
  'Length_Required' = 'Length Required',
  'Precondition_Failed' = 'Precondition Failed',
  'Request_Entity_Too_Large' = 'Request Entity Too Large',
  'Request_URI_Too_Large' = 'Request-URI Too Large',
  'Unsupported_Media_Type' = 'Unsupported Media Type',
  'Requested_Range_Not_Satisfiable' = 'Requested Range Not Satisfiable',
  'Expectation_Failed' = 'Expectation Failed',
  'I_m_a_teapot' = "I'm a teapot",
  'Unprocessable_Entity' = 'Unprocessable Entity',
  'Locked' = 'Locked',
  'Failed_Dependency' = 'Failed Dependency',
  'Unordered_Collection' = 'Unordered Collection',
  'Upgrade_Required' = 'Upgrade Required',
  'Precondition_Required' = 'Precondition Required',
  'Too_Many_Requests' = 'Too Many Requests',
  'Request_Header_Fields_Too_Large' = 'Request Header Fields Too Large',
  'Unavailable_For_Legal_Reasons' = 'Unavailable For Legal Reasons',
  'Internal_Server_Error' = 'Internal Server Error',
  'Not_Implemented' = 'Not Implemented',
  'Bad_Gateway' = 'Bad Gateway',
  'Service_Unavailable' = 'Service Unavailable',
  'Gateway_Time_out' = 'Gateway Time-out',
  'HTTP_Version_Not_Supported' = 'HTTP Version Not Supported',
  'Variant_Also_Negotiates' = 'Variant Also Negotiates',
  'Insufficient_Storage' = 'Insufficient Storage',
  'Bandwidth_Limit_Exceeded' = 'Bandwidth Limit Exceeded',
  'Not_Extended' = 'Not Extended',
  'Network_Authentication_Required' = 'Network Authentication Required',
  // 4xx
  'Application_Error' = 'Application Error',
  // 5xx
  'Server_Error' = 'Server Error'
}

export const statusCodes = new Map<number, string>([
  [100, StatusCodeDefinition.Continue],
  [101, StatusCodeDefinition.Switching_Protocols],
  [102, StatusCodeDefinition.Processing],
  [200, StatusCodeDefinition.OK],
  [201, StatusCodeDefinition.Created],
  [202, StatusCodeDefinition.Accepted],
  [203, StatusCodeDefinition.Non_Authoritative_Information],
  [204, StatusCodeDefinition.No_Content],
  [205, StatusCodeDefinition.Reset_Content],
  [206, StatusCodeDefinition.Partial_Content],
  [207, StatusCodeDefinition.Multi_Status],
  [300, StatusCodeDefinition.Multiple_Choices],
  [301, StatusCodeDefinition.Moved_Permanently],
  [302, StatusCodeDefinition.Moved_Temporarily],
  [303, StatusCodeDefinition.See_Other],
  [304, StatusCodeDefinition.Not_Modified],
  [305, StatusCodeDefinition.Use_Proxy],
  [307, StatusCodeDefinition.Temporary_Redirect],
  [400, StatusCodeDefinition.Bad_Request],
  [401, StatusCodeDefinition.Unauthorized],
  [402, StatusCodeDefinition.Payment_Required],
  [403, StatusCodeDefinition.Forbidden],
  [404, StatusCodeDefinition.Not_Found],
  [405, StatusCodeDefinition.Method_Not_Allowed],
  [406, StatusCodeDefinition.Not_Acceptable],
  [407, StatusCodeDefinition.Proxy_Authentication_Required],
  [408, StatusCodeDefinition.Request_Time_out],
  [409, StatusCodeDefinition.Conflict],
  [410, StatusCodeDefinition.Gone],
  [411, StatusCodeDefinition.Length_Required],
  [412, StatusCodeDefinition.Precondition_Failed],
  [413, StatusCodeDefinition.Request_Entity_Too_Large],
  [414, StatusCodeDefinition.Request_URI_Too_Large],
  [415, StatusCodeDefinition.Unsupported_Media_Type],
  [416, StatusCodeDefinition.Requested_Range_Not_Satisfiable],
  [417, StatusCodeDefinition.Expectation_Failed],
  [418, StatusCodeDefinition.I_m_a_teapot],
  [422, StatusCodeDefinition.Unprocessable_Entity],
  [423, StatusCodeDefinition.Locked],
  [424, StatusCodeDefinition.Failed_Dependency],
  [425, StatusCodeDefinition.Unordered_Collection],
  [426, StatusCodeDefinition.Upgrade_Required],
  [428, StatusCodeDefinition.Precondition_Required],
  [429, StatusCodeDefinition.Too_Many_Requests],
  [431, StatusCodeDefinition.Request_Header_Fields_Too_Large],
  [451, StatusCodeDefinition.Unavailable_For_Legal_Reasons],
  [500, StatusCodeDefinition.Internal_Server_Error],
  [501, StatusCodeDefinition.Not_Implemented],
  [502, StatusCodeDefinition.Bad_Gateway],
  [503, StatusCodeDefinition.Service_Unavailable],
  [504, StatusCodeDefinition.Gateway_Time_out],
  [505, StatusCodeDefinition.HTTP_Version_Not_Supported],
  [506, StatusCodeDefinition.Variant_Also_Negotiates],
  [507, StatusCodeDefinition.Insufficient_Storage],
  [509, StatusCodeDefinition.Bandwidth_Limit_Exceeded],
  [510, StatusCodeDefinition.Not_Extended],
  [511, StatusCodeDefinition.Network_Authentication_Required]
])

/**
 * Return true if the statusCode is a 2xx.
 */
export function is2xx(statusCode: string | number) {
  return /^2/.test(String(statusCode))
}
