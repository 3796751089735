import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { ContainerHTMLContent } from '@app/components-legacy/Container'
import { Modal } from '@app/components-legacy/Modal'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export interface ITrailFlowPageAutomaticPauseModalProps {}

const TrailFlowPageAutomaticPauseModal: React.FC<
  ITrailFlowPageAutomaticPauseModalProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Titles', 'Management.Accounts.Users']
  })

  const { storeManagementUsers } = useStores()

  return (
    <Modal
      modalProps={{
        title: translate('Create a user'),
        closable: true,
        maskClosable: true
      }}
      storeModal={storeManagementUsers.storeModalToneUserCreation}
    >
      <Label variant={LabelVariant.p}>
        <ContainerHTMLContent padded>
          {translate(
            'Tenable.ad operates as part of the Tenable.One platform. Click HERE to access user account settings'
          )}
        </ContainerHTMLContent>
      </Label>
    </Modal>
  )
}

export default observer(TrailFlowPageAutomaticPauseModal)
