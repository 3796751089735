import { ContainerFlex } from '@app/components-legacy/Container'
import type { IContainerSimpleProps } from '@app/components-legacy/Container/ContainerSimple'
import type { EntityUserLog } from '@app/entities'
import type {
  IDirectoryConfigurationChanged,
  IInfrastructureConfigurationChanged,
  IPageVisited,
  IProfileCreationOrDeletionOrUnstaging,
  IReportConfigurationCreationOrDeletion,
  IRoleCreationOrDeletion,
  IUserChanged
} from '@app/entities/EntityUserLog'
import { consts } from '@app/styles'
import { marginSmall, marginSmaller } from '@app/styles/consts'
import { UserTraceLogType } from '@server/graphql/typeDefs/types'
import React from 'react'
import styled from 'styled-components'
import UserLogAction from '../../Components/UserLogAction'
import UserLogDirectory from '../../Components/UserLogDirectory'
import { UserLogIcon } from '../../Components/UserLogIcon'
import UserLogInfrastructure from '../../Components/UserLogInfrastructure'
import { UserLogIpAddress } from '../../Components/UserLogIpAddress'
import { UserLogPageVisited } from '../../Components/UserLogPageVisited'
import UserLogProfile from '../../Components/UserLogProfile'
import UserLogReport from '../../Components/UserLogReport'
import UserLogRole from '../../Components/UserLogRole'
import UserLogTime from '../../Components/UserLogTime'
import { UserLogUser } from '../../Components/UserLogUser'
import { UserLogUsername } from '../../Components/UserLogUserName'

interface IUserLogRowProps extends IContainerSimpleProps {
  log: EntityUserLog
}

const enhanceTraceLogTitle = (log: EntityUserLog): JSX.Element | undefined => {
  if (!log.logType) {
    return
  }

  switch (log.logType) {
    case UserTraceLogType.DirectoryCreated:
    case UserTraceLogType.DirectoryDeleted: {
      const directoryConfigurationChanges =
        log.decodedLogAttributes as IDirectoryConfigurationChanged
      if (!directoryConfigurationChanges) {
        return
      }

      return <UserLogDirectory {...directoryConfigurationChanges} />
    }
    case UserTraceLogType.InfrastructureCreated:
    case UserTraceLogType.InfrastructureDeleted: {
      const infrastructureConfigurationChanges =
        log.decodedLogAttributes as IInfrastructureConfigurationChanged
      if (!infrastructureConfigurationChanges) {
        return
      }

      return <UserLogInfrastructure {...infrastructureConfigurationChanges} />
    }
    case UserTraceLogType.RoleCreated:
    case UserTraceLogType.RoleDeleted: {
      const roleConfiguration =
        log.decodedLogAttributes as IRoleCreationOrDeletion
      return <UserLogRole {...roleConfiguration} />
    }
    case UserTraceLogType.ProfileCreated:
    case UserTraceLogType.ProfileDeleted:
    case UserTraceLogType.ProfileCommited:
    case UserTraceLogType.ProfileUnstaged: {
      const profileCreatedOrDeletedOrCommitedOrUnstaged =
        log.decodedLogAttributes as IProfileCreationOrDeletionOrUnstaging
      return <UserLogProfile {...profileCreatedOrDeletedOrCommitedOrUnstaged} />
    }
    case UserTraceLogType.UserCreated:
    case UserTraceLogType.UserDeleted: {
      const userChanged = log.decodedLogAttributes as IUserChanged
      if (!userChanged) {
        return
      }

      return (
        <UserLogUsername
          userName={userChanged.userName}
          userSurname={userChanged.userSurname}
        />
      )
    }
    case UserTraceLogType.PageVisited: {
      const pageVisited = log.decodedLogAttributes as IPageVisited
      if (!pageVisited) {
        return
      }

      return <UserLogPageVisited {...pageVisited} />
    }
    case UserTraceLogType.ReportConfigurationCreated: {
      const ReportConfiguration =
        log.decodedLogAttributes as IReportConfigurationCreationOrDeletion
      return <UserLogReport {...ReportConfiguration} />
    }
  }

  return
}

const UserLogSimpleRow: React.FC<IUserLogRowProps> = ({ log, className }) => (
  <ContainerFlex
    name={UserLogSimpleRow.name}
    className={className}
    ariaRoles={['list', 'row']}
    flexWrap="wrap"
    labelledBy={String(log.logType)}
    alignItems="center"
    items={[
      <UserLogIcon trace={log} />,
      <UserLogUser user={log.userEmail} />,
      <UserLogIpAddress ipAddress={log.userIp} />,
      <UserLogAction log={log} />,
      enhanceTraceLogTitle(log),
      <UserLogTime log={log} />
    ]}
  />
)

export default styled(UserLogSimpleRow)`
  border-radius: ${consts.borderRadiusRedesign};
  background-color: ${consts.colorWhite};
  padding-top: ${consts.paddingSmall};
  padding-bottom: ${consts.paddingSmall};
  padding-right: ${consts.paddingExtraLarge};
  padding-left: ${consts.paddingLarge};
  margin-top: ${consts.marginExtraSmall};

  .traceUser {
    text-align: left;
    margin-right: ${marginSmaller};
  }

  .traceIpAddress {
    text-align: left;
    margin-right: ${marginSmall};
  }

  .traceAction {
    text-align: left;
    margin-right: ${consts.marginVeryVerySmall};
  }

  .traceTime {
    text-align: right;
    flex: 1;
    width: 100%;
  }
`
