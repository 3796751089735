import { Drawer, DrawerContext } from '@app/components-legacy/Drawer'
import { useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import EditBookmarkCategory from './EditBookmarkCategory'

interface IDrawerEditBookmarkCategory {}

const DrawerEditBookmarkCategory: React.FC<
  IDrawerEditBookmarkCategory
> = () => {
  const { storeTrailFlow } = useStores()

  return (
    <DrawerContext.Provider
      value={{
        storeDrawer:
          storeTrailFlow.storeSearchBookmarks.storeDrawerEditBookmarkCategory
      }}
    >
      <Drawer>
        <EditBookmarkCategory />
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerEditBookmarkCategory)
