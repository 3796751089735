import type { IGraphQLQuery } from '@libs/graphQL/types'
import type {
  Query,
  RbacAttackPathAccountsWithTier0AttackPathQueryArgs,
  RbacAttackPathAllTier0ObjectsQueryArgs,
  RbacAttackPathQueryArgs,
  RbacAttackPathSearchQueryArgs,
  RbacAttackPathTier0ObjectsQueryArgs,
  RbacAttackPathTwoNodesQueryArgs
} from '../typeDefs/types'

/**
 * Retrieve attack path nodes
 */
export type QueryRbacAttackPathHealthCheck = IGraphQLQuery<
  null,
  Pick<Query, 'rbacAttackPathHealthCheck'>
>

export const queryRbacAttackPathHealthCheck = `
  query queryRbacAttackPathHealthCheck {
    rbacAttackPathHealthCheck
  }
`

/**
 * Retrieve attack path nodes
 */
export type QueryRbacAttackPath = IGraphQLQuery<
  RbacAttackPathQueryArgs,
  Pick<Query, 'rbacAttackPath'>
>

export const queryRbacAttackPath = `
  query queryRbacAttackPath(
    $adObjectId: Int!
    $direction: AttackPathDirection!
    $depth: Int!
  ) {
    rbacAttackPath(
      adObjectId: $adObjectId
      direction: $direction
      depth: $depth
    ) {
      node {
        nodes {
          id
          name
          adObjectType
          nextDepthNodeCount
          directoryId
        }
        edges {
          relationType
          sourceId
          targetId
        }
        isTruncated,
        truncationType
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Retrieve attack path nodes between two nodes
 */
export type QueryRbacAttackPathTwoNodes = IGraphQLQuery<
  RbacAttackPathTwoNodesQueryArgs,
  Pick<Query, 'rbacAttackPathTwoNodes'>
>

export const queryRbacAttackPathTwoNodes = `
  query queryRbacAttackPathTwoNodes(
    $sourceAdObjectId: Int!
    $targetAdObjectId: Int!
  ) {
    rbacAttackPathTwoNodes(
      sourceAdObjectId: $sourceAdObjectId
      targetAdObjectId: $targetAdObjectId
    ) {
      node {
        nodes {
          id
          name
          adObjectType
          nextDepthNodeCount
          directoryId
        }
        edges {
          relationType
          sourceId
          targetId
        }
        isTruncated,
        truncationType
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Retrieve attack path nodes with text
 */
export type QueryRbacAttackPathSearch = IGraphQLQuery<
  RbacAttackPathSearchQueryArgs,
  Pick<Query, 'rbacAttackPathSearch'>
>

export const queryRbacAttackPathSearch = `
  query queryRbacAttackPathSearch(
    $text: String!
  ) {
    rbacAttackPathSearch(
      text: $text
    ) {
      node {
        id
        name
        adObjectType
        nextDepthNodeCount
        directoryId
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Retrieve attack path tier 0 objects
 */
export type QueryRbacAttackPathTier0Objects = IGraphQLQuery<
  RbacAttackPathTier0ObjectsQueryArgs,
  Pick<Query, 'rbacAttackPathTier0Objects'>
>

export const queryRbacAttackPathTier0Objects = `
  query queryRbacAttackPathTier0Objects(
    $directoryIds: [Int!]!
    $page: Int!
    $perPage: Int!
  ) {
    rbacAttackPathTier0Objects(
      directoryIds: $directoryIds
      page: $page
      perPage: $perPage
    ) {
      node {
        node {
          id
          name
          adObjectType
          directoryId
          accountTotal
          accountWithTier0Access
        }
        pagination {
          page
          perPage
          totalCount
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Retrieve all attack path tier 0 objects
 */
export type QueryRbacAttackPathAllTier0Objects = IGraphQLQuery<
  RbacAttackPathAllTier0ObjectsQueryArgs,
  Pick<Query, 'rbacAttackPathAllTier0Objects'>
>

export const queryRbacAttackPathAllTier0Objects = `
  query queryRbacAttackPathAllTier0Objects(
    $directoryIds: [Int!]!
  ) {
    rbacAttackPathAllTier0Objects(
      directoryIds: $directoryIds
    ) {
      node {
        id
        name
        adObjectType
        directoryId
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Retrieve accounts with attack path to tier 0 objects
 */
export type QueryRbacAttackPathAccountsWithTier0AttackPath = IGraphQLQuery<
  RbacAttackPathAccountsWithTier0AttackPathQueryArgs,
  Pick<Query, 'rbacAttackPathAccountsWithTier0AttackPath'>
>

export const queryRbacAttackPathAccountsWithTier0AttackPath = `
  query queryRbacAttackPathAccountsWithTier0AttackPath(
    $accountSearchText: String
    $directoryIds: [Int!]!
    $tier0AssetId: Int
    $page: Int!
    $perPage: Int!
  ) {
    rbacAttackPathAccountsWithTier0AttackPath(
      accountSearchText: $accountSearchText
      directoryIds: $directoryIds
      tier0AssetId: $tier0AssetId
      page: $page
      perPage: $perPage
    ) {
      node {
        node {
          id
          name
          adObjectType
          directoryId
          location
        }
        pagination {
          page
          perPage
          totalCount
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
