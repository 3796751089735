import type { Maybe } from '@@types/helpers'
import { Features } from '@alsid/common'
import { StaticError } from '@app/pages/Error'
import { StoreRoot } from '@app/stores'
import type { IStores } from '@app/stores/types'
import type { ApplicationError, HardReloadError } from '@libs/errors'
import { isErrorOfType } from '@libs/errors/functions'
import { ErrorName } from '@libs/errors/types'
import { logException } from '@libs/logExceptions'
import { getLogger } from '@libs/logger'
import * as React from 'react'
import Root from '../components/Root'
import { extractProfileNameFromUrl, setProfileNameInRouter } from '../utils'
import { setupEnvironment } from '../utils/setupEnvironment'
import { initializeApp } from './initializeApp'
import { initializeTul } from './initializeTul'
import { initializeTulLegacy } from './initializeTulLegacy'

interface IExtendedWindow extends Window {
  ROOT_STORE: StoreRoot
}

type ExtendedWindow = IExtendedWindow & typeof globalThis

const extendedWindow: ExtendedWindow = window as ExtendedWindow

const logger = getLogger()

/**
 * Create the app and return the Root component or a StaticError component error.
 */
export function createApp(parameters: {
  pathname: string
  csrfToken: Maybe<string>
}): Promise<Maybe<JSX.Element>> {
  const _logException = logException(logger)

  return (
    Promise.resolve()
      /**
       * Setup environment.
       */
      .then(() => setupEnvironment(parameters))

      /**
       * Expose the storeRoot into the DOM (for debugging purposes).
       */
      .then(environment => {
        // retrieve the root store from window first (hot reloading)
        const storeRoot =
          extendedWindow.ROOT_STORE ||
          new StoreRoot(environment).instanciateGraphQLRequestors()

        extendedWindow.ROOT_STORE = storeRoot

        return storeRoot
      })

      /**
       * Initialize the app.
       */
      .then(storeRoot => {
        const profileName = extractProfileNameFromUrl(parameters.pathname)

        return initializeApp(storeRoot)({
          pathname: parameters.pathname,
          profileName
        }).then(() => storeRoot)
      })

      /**
       * Initialize default parameters of the router.
       */
      .then(storeRoot => {
        const { storeAuthentication } = storeRoot.stores

        // set the profileName as default parameter into the router
        setProfileNameInRouter(storeAuthentication)

        return storeRoot
      })

      /**
       * Init TUL
       */
      .then(storeRoot => {
        const isTulEnabled =
          storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
            Features.TENABLE_UNIVERSAL_LAYOUT
          )

        const isTenableIdentityExposureVisuals =
          storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
            Features.TENABLE_IDENTITY_EXPOSURE_VISUALS
          )

        if (isTulEnabled) {
          const isTulV6Enabled =
            storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
              Features.KAPTEYN_TUL_V6
            )

          if (!isTulV6Enabled) {
            // TUL legacy should be displayed
            return initializeTulLegacy(storeRoot)
              .then(tulApi => {
                // Use tulApi that exposes methods useful to manipulate TUL
                if (tulApi) {
                  tulApi.setViewTitle('Active Directory')

                  if (!isTenableIdentityExposureVisuals) {
                    // continue to set T.AD logo if not ff
                    tulApi.setLogoPath('/w/assets/images/logo-tenable-ad.svg')
                  }
                }
              })
              .then(() => storeRoot)
          }

          return initializeTul(storeRoot)
            .then(tulApi => {
              storeRoot.setTulApi(tulApi)
            })
            .then(() => storeRoot)
        }

        return storeRoot
      })

      .then(storeRoot => {
        const isTulV6Enabled =
          storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
            Features.KAPTEYN_TUL_V6
          )

        if (!isTulV6Enabled) {
          return storeRoot
        }

        return storeRoot.stores.storeAlerts.storeAlertsIoA
          .fetchAlerts(undefined, {
            forceCount: true
          })
          .then(() => storeRoot)
      })

      /**
       * Render the app by passing all instantiated stores for the MobX provider.
       */
      .then(storeRoot => {
        const stores: IStores & { storeRoot: StoreRoot } = {
          ...storeRoot.stores,
          storeRoot
        }

        return <Root stores={stores} />
      })

      /**
       * Handle errors and redirections.
       */
      .catch(err => {
        // handle hard redirections
        if (isErrorOfType<HardReloadError>(err, ErrorName.HardReloadError)) {
          const url = err.getMessage()

          const logMessage = url ? `Redirect to ${url}` : 'Refresh the page'
          logger.info(`[AFAD] ${logMessage}.`)
          logger.debug(err)

          if (!url) {
            document.location.reload()
          } else {
            document.location.href = url
          }

          // return null to avoid to render the App
          return Promise.resolve(null)
        }

        // handle application errors
        if (isErrorOfType<ApplicationError>(err, ErrorName.ApplicationError)) {
          _logException(
            err,
            'An error has occurred during the rendering of the page'
          )

          const message = `An error has occurred during the initialization of the user. If the problem persists, try to logout and login again.`

          return <StaticError reason={message} />
        }

        _logException(
          err,
          'An error has occurred during the rendering of the page'
        )

        return (
          <StaticError reason="An error has occurred during the initialization of the application." />
        )
      })
  )
}
