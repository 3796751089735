import { LabelSliced } from '@app/components/Labels'
import { ContainerFlex } from '@app/components-legacy/Container'
import type { EntityInfrastructure } from '@app/entities'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import Cell from './Cell'
import CellDirectory from './CellDirectory'
import CellInfrastructure from './CellInfrastructure'
import CellWrapper from './CellWrapper'
import { SETUP_TABLE_ATTACK_TYPES_WIDTH_REDESIGN } from './consts'

interface ITheadCellsProps {
  className?: string
  refs: {
    thead: React.RefObject<HTMLDivElement>
  }
  isCheckable: boolean
  infrastructures: EntityInfrastructure[]
}

const ScrollableDiv = styled.div`
  overflow-x: hidden;
`

const CellInfrastructureRedesign = styled(CellInfrastructure)`
  height: 110px;
  max-height: 110px;
  border-radius: ${consts.containerSmallBorderRadius};
`

const CellDirectoryRedesign = styled(CellDirectory)`
  height: 110px;
  max-height: 110px;
  border-radius: ${consts.containerSmallBorderRadius};
`

const CellRedesign = styled(Cell)`
  height: 110px;
  max-height: 110px;
  border-radius: ${consts.containerSmallBorderRadius};
`

const CellWrapperHeadTitle = styled(CellWrapper)`
  margin-right: ${consts.marginVerySmall};
  border-top-left-radius: ${consts.borderRadiusRedesign};
  border-top-right-radius: ${consts.borderRadiusRedesign};
`

const CellWrapperHeadDirectory = styled(CellWrapper)`
  padding: ${consts.paddingDefault} ${consts.paddingVerySmall}
    ${consts.paddingVerySmall} 0;
`

const CellWrapperInfrastructure = styled(CellWrapper)<{
  isInfraCollapsed: boolean
}>`
  padding-right: ${props =>
    props.isInfraCollapsed ? consts.paddingDefault : consts.paddingVerySmall};
  border-top-left-radius: ${consts.borderRadiusRedesign};
  border-top-right-radius: ${props =>
    props.isInfraCollapsed ? consts.borderRadiusRedesign : '0'};
`

const ContainerInfraAndDirectories = styled(ContainerFlex)`
  margin-right: ${consts.marginVerySmall};

  ${CellWrapperHeadDirectory}:last-child {
    padding-right: ${consts.paddingDefault};
    border-top-right-radius: ${consts.borderRadiusRedesign};
  }
`

const CellsThead: React.FC<ITheadCellsProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.IoA']
  })

  const { storeManagementAttackTypeConfiguration } = useStores()
  const { storeInfrastructures } = storeManagementAttackTypeConfiguration

  const getDirectoriesCells = (infrastructure: EntityInfrastructure) => {
    const directories = infrastructure
      .getDirectories()
      .filter(
        directory =>
          directory.id &&
          storeInfrastructures.selectedDirectoryIds.includes(directory.id)
      )

    return directories.map(directory => {
      return (
        <CellWrapperHeadDirectory isHeadCell={true}>
          <CellDirectoryRedesign
            key={`dir-${directory.id}`}
            isCheckable={props.isCheckable}
            directory={directory}
            infrastructure={infrastructure}
          />
        </CellWrapperHeadDirectory>
      )
    })
  }

  const infrastructureCells = Array.from(
    storeInfrastructures.infrastructures.values()
  ).map(infrastructure => {
    const directories = getDirectoriesCells(infrastructure)

    if (!directories.length) {
      return null
    }

    const isInfrastructureCollapsed =
      storeManagementAttackTypeConfiguration.infrastructuresCollapsed.get(
        infrastructure.getPropertyAsNumber('id')
      ) || false

    return (
      <ContainerInfraAndDirectories
        key={`infra-${infrastructure.id}`}
        name="DirectoriesCell"
        items={
          isInfrastructureCollapsed
            ? [
                <CellWrapperInfrastructure
                  isHeadCell={true}
                  isInfraCollapsed={isInfrastructureCollapsed}
                >
                  <CellInfrastructureRedesign
                    isCheckable={props.isCheckable}
                    infrastructure={infrastructure}
                  />
                </CellWrapperInfrastructure>
              ]
            : [
                <CellWrapperInfrastructure
                  isHeadCell={true}
                  isInfraCollapsed={isInfrastructureCollapsed}
                >
                  <CellInfrastructureRedesign
                    isCheckable={props.isCheckable}
                    infrastructure={infrastructure}
                  />
                </CellWrapperInfrastructure>,
                ...directories
              ]
        }
      />
    )
  })

  return (
    <ContainerFlex
      name={CellsThead.name}
      className={props.className}
      items={[
        <CellWrapperHeadTitle isHeadCell={true}>
          <CellRedesign width={SETUP_TABLE_ATTACK_TYPES_WIDTH_REDESIGN}>
            <LabelSliced value={translate('Attack name')} />
          </CellRedesign>
        </CellWrapperHeadTitle>,

        <ScrollableDiv ref={props.refs.thead}>
          <ContainerFlex name="CellsThead" items={infrastructureCells} />
        </ScrollableDiv>
      ]}
    />
  )
}

export default observer(CellsThead)
