import { useStores } from '@app/hooks'
import { AttacksSummaryPeriod } from '@server/graphql/typeDefs/types'
import { DatePicker } from 'antd'
import { observer } from 'mobx-react-lite'
import * as moment from 'moment'
import * as React from 'react'
import styled from 'styled-components'
import { handleTimelineDatePickerSelection } from './handlers'

interface IFiltersDatePickerProps {
  className?: string
}

const FiltersDatePicker: React.FC<IFiltersDatePickerProps> = props => {
  const { storeIoA } = useStores()

  const { storeTimeline } = storeIoA.storeBoard

  return (
    <div data-name="FiltersDatePicker">
      <DatePicker
        className={props.className}
        onChange={handleTimelineDatePickerSelection(storeIoA)}
        picker={storeTimeline.datePickerValue}
        format={storeTimeline.datePickerFormat}
        value={moment(storeIoA.storeBoard.storeTimeline.dateStart)}
        allowClear={false}
        showTime={
          storeTimeline.attacksSummaryPeriod === AttacksSummaryPeriod.Hour
        }
        showNow
      />
    </div>
  )
}

const ObservedFiltersDatePicker = observer(FiltersDatePicker)

export default styled(ObservedFiltersDatePicker)`
  width: 200px;
`
