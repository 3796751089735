import type StoreForm from '@app/stores/helpers/StoreForm'
import type { InputMultiValuesOnChange } from '@app/stores/helpers/StoreForm/types'
import { makeFieldName } from './tools'

export const onMultiInputChange =
  (name: string, nbInput: number) =>
  (onChange: InputMultiValuesOnChange) =>
  (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = makeFieldName(name, nbInput)
    onChange(fieldName, e.target.value)
  }

export const onMultiInputAddIconClick =
  (
    numberOfInputs: number,
    setNumberOfInputs: React.Dispatch<React.SetStateAction<number>>
  ) =>
  () => {
    setNumberOfInputs(numberOfInputs + 1)
  }

export const onMultiInputRemoveIconClick =
  (storeForm: StoreForm<any>) =>
  (
    name: string,
    nbInput: number,
    numberOfInputs: number,
    setNumberOfInputs: React.Dispatch<React.SetStateAction<number>>,
    onChange: InputMultiValuesOnChange
  ) =>
  () => {
    const newNumberOfInput = numberOfInputs - 1

    setNumberOfInputs(newNumberOfInput)

    // shift all values of inputs after the removed one
    for (let index = nbInput; index < newNumberOfInput; index++) {
      const nextValue = storeForm.getFieldValueAsString(
        makeFieldName(name, index + 1)
      )

      onChange(makeFieldName(name, index), nextValue)
    }

    // set the deleted field as empty
    // (do not delete it in order to be able to retrieve its dirty (modified) state)
    // Note: Empty field are removed when being retrieved via the StoreForm.
    const fieldName = makeFieldName(name, newNumberOfInput)
    storeForm.field(fieldName).setValue('')
  }
