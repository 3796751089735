export enum CronstrueLocale {
  Af = 'af',
  Ar = 'ar',
  Be = 'be',
  Ca = 'ca',
  Cs = 'cs',
  Da = 'da',
  De = 'de',
  En = 'en',
  Es = 'es',
  Fa = 'fa',
  Fi = 'fi',
  Fr = 'fr',
  He = 'he',
  Hu = 'hu',
  Id = 'id',
  It = 'it',
  Ja = 'ja',
  Ko = 'ko',
  Nb = 'nb',
  Nl = 'nl',
  Pl = 'pl',
  PtBr = 'pt_BR',
  PtPt = 'pt_PT',
  Ro = 'ro',
  Ru = 'ru',
  Sk = 'sk',
  Sl = 'sl',
  Sv = 'sv',
  Sw = 'sw',
  Th = 'th',
  Tr = 'tr',
  Uk = 'uk',
  ZhCn = 'zh_CN',
  ZhTw = 'zh_TW'
}
