import type { IBladeHeaderInfo } from '@app/components-legacy/Blade/BladeHeader'
import BladeHeader from '@app/components-legacy/Blade/BladeHeader'
import { useAppTranslator, useStores } from '@app/hooks'
import { AttackResourceType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { AttacksPageContext } from '../context'

interface IIoAAttacksHeaderProps {}

const IoAAttacksHeader: React.FC<IIoAAttacksHeaderProps> = props => {
  const attacksPageContext = React.useContext(AttacksPageContext)

  const translate = useAppTranslator({ namespaces: ['IoA.Attacks'] })

  const { storeInfrastructures } = useStores()

  if (!attacksPageContext) {
    return null
  }

  const { queryStringParameters } = attacksPageContext.storeAttacks

  const getValue = (): string => {
    if (
      !queryStringParameters.resourceValue ||
      !queryStringParameters.resourceType
    ) {
      return '-'
    }

    switch (queryStringParameters.resourceType) {
      case AttackResourceType.Infrastructure: {
        const infrastructure = storeInfrastructures.getInfrastructureFromId(
          Number(queryStringParameters.resourceValue)
        )
        return infrastructure?.getPropertyAsString('name') || '-'
      }
      case AttackResourceType.Directory: {
        const directory = storeInfrastructures.getDirectoryFromId(
          Number(queryStringParameters.resourceValue)
        )
        return directory?.getPropertyAsString('name') || '-'
      }
      case AttackResourceType.Hostname:
      case AttackResourceType.Ip:
        return queryStringParameters.resourceValue
    }
  }

  const getType = (): string => {
    if (!queryStringParameters.resourceType) {
      return '-'
    }

    switch (queryStringParameters.resourceType) {
      case AttackResourceType.Infrastructure:
        return 'Incidents related to the forest'

      case AttackResourceType.Directory:
        return 'Incidents related to the domain'

      case AttackResourceType.Hostname:
        return 'Incidents related to the hostname'

      case AttackResourceType.Ip:
        return 'Incidents related to the IP'
    }
  }

  const getInfos = (): IBladeHeaderInfo[] => {
    const rawType = getType()

    return [
      {
        rawType,
        type: translate(rawType),
        value: getValue()
      }
    ]
  }

  return <BladeHeader icon="ioa" infos={getInfos()} />
}

export default observer(IoAAttacksHeader)
