import {
  ContainerContent,
  ContainerFlex,
  ContainerTimeline
} from '@app/components-legacy/Container'
import { LabelChecker } from '@app/components-legacy/Label'
import LabelCheckerIdentity from '@app/components-legacy/Label/LabelCheckerIdentity'
import type { EntityChecker } from '@app/entities'
import type EntityCheckerIdentity from '@app/entities/EntityGenericChecker/EntityCheckerIdentity'
import { useStores } from '@app/hooks'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreIoE } from '@app/stores'
import { consts } from '@app/styles'
import { ensureArray } from '@libs/ensureArray'
import { isDefined } from '@libs/isDefined'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { CheckerType, Criticity } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { handleCheckerIdentityOnClick, handleCheckerOnClick } from './handlers'

export interface IIoEBoardCardsProps {
  // passed as props because used in IoE and Topology interfaces.
  storeIoE: StoreIoE
  directoryIds: number[]
  tenantIds?: string[]
}

const StyledLabelChecker = styled(LabelChecker)`
  margin-bottom: ${consts.paddingMedium};
`

const StyledLabelCheckerIdentity = styled(LabelCheckerIdentity)`
  margin-bottom: ${consts.paddingMedium};
`

const IoEBoardCards: React.FC<IIoEBoardCardsProps> = props => {
  const { storeManagementAzureAD } = useStores()

  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'IoE.Board',
      'Components.LabelChecker.ComplexityLevel'
    ]
  })

  const appRouter = useAppRouter()

  if (
    props.storeIoE.isIdentityCheckersMenuEntrySelected &&
    !storeManagementAzureAD.isTenableCloudApiTokensWorking
  ) {
    return null
  }

  function getCheckersByCriticity(criticityLevel: Criticity) {
    const checkers = props.storeIoE
      .filterAndSortCheckersForBoard(
        props.storeIoE.checkersForBoard,
        criticityLevel
      )
      .map(renderChecker(criticityLevel))
      .filter(isDefined)

    if (!checkers.length) {
      return <div>{translate('No indicators found with deviant objects')}</div>
    }

    return (
      <ContainerFlex
        name="Checkers"
        items={checkers}
        flexWrap="wrap"
        spaced
        spaceWidth="default"
        wrapItems
      />
    )
  }

  function renderChecker(criticityLevel: Criticity) {
    return (checker: EntityChecker | EntityCheckerIdentity) => {
      if (!checker.codename) {
        return null
      }

      switch (checker.type) {
        case CheckerType.Exposure: {
          if (!checker.directoryIds) {
            return null
          }

          const directoryIds = checker.directoryIds.filter(directoryId => {
            return props.directoryIds.includes(directoryId)
          })

          if (!directoryIds.length && !props.storeIoE.showAllIndicators) {
            return null
          }

          return (
            <StyledLabelChecker
              criticity={criticityLevel}
              codename={checker.codename}
              enabled={checker.enabled}
              name={checker.getPropertyAsString('name')}
              description={checker.getPropertyAsString('description')}
              complexityValue={checker.remediationCost || 0}
              directoryIds={directoryIds}
              onClick={handleCheckerOnClick(appRouter)(checker)}
            />
          )
        }

        case 'identity': {
          if (!checker.tenants) {
            return null
          }

          const tenantsIds = checker.getTenantsIds().filter(tenantId => {
            return ensureArray(props.tenantIds).includes(tenantId)
          })

          if (!tenantsIds.length && !props.storeIoE.showAllIndicators) {
            return null
          }

          return (
            <StyledLabelCheckerIdentity
              criticity={criticityLevel}
              codename={checker.codename}
              name={checker.getPropertyAsString('name')}
              summary={checker.getPropertyAsString('summary')}
              description={checker.getPropertyAsString('description')}
              complexityValue={checker.remediationCost}
              tenantIds={tenantsIds}
              onClick={handleCheckerIdentityOnClick(appRouter)(checker)}
            />
          )
        }

        case CheckerType.Attack: {
          return null
        }

        default: {
          assertUnreachableCase(checker)
        }
      }
    }
  }

  function renderContent() {
    return (
      <ContainerTimeline
        itemLabels={[
          translate(Criticity.Critical),
          translate(Criticity.High),
          translate(Criticity.Medium),
          translate(Criticity.Low)
        ]}
        itemColors={[
          consts.colorRed001,
          consts.colorOrange001,
          consts.colorYellow001,
          consts.colorBlue005
        ]}
        items={[
          getCheckersByCriticity(Criticity.Critical),
          getCheckersByCriticity(Criticity.High),
          getCheckersByCriticity(Criticity.Medium),
          getCheckersByCriticity(Criticity.Low)
        ]}
        reversedBullets={false}
        timelineToBottom
      />
    )
  }

  return (
    <ContainerContent
      flags={[
        props.storeIoE.storeFlagsReloadCheckersExposure.flags,
        props.storeIoE.storeFlagsReloadCheckersIdentity.flags
      ]}
    >
      {renderContent()}
    </ContainerContent>
  )
}

export default observer(IoEBoardCards)
