import { useAppRouter, useLogger, useStores } from '@app/hooks'
import type { AppRouteDefinition, AppRouteName } from '@app/routes'
import { first } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { RouteContext } from './context'
import { useHistoryChange } from './hooks'
import type { IRbacRoute } from './types'
import { routesMatcher } from './utils'

interface IRbacRoutesProps {
  routes: Array<IRbacRoute<AppRouteName, AppRouteDefinition>>
  redirect?: boolean
}

const RbacRoutes: React.FC<IRbacRoutesProps> = props => {
  const appRouter = useAppRouter()

  const { storeEula, storeAuthentication, storeLicense, storeRbac } =
    useStores()

  const logger = useLogger()

  const { history, currentRouteInfo } = useHistoryChange()

  // verify EULA and product license
  const isProductContextValid =
    storeEula.hasApprovedEula && storeLicense.isLicenseStatusAuthorizeAccess

  const currentRouteName = currentRouteInfo?.routeName || null

  // save granted routes when the user is set and for the current routeName
  const memoGrantedRoutes = React.useMemo(() => {
    return props.routes.filter(route => {
      // if not auth but if the route is not flagued as anonymous, don't grant it
      if (!storeAuthentication.isAuthenticated && route.isAnonymous !== true) {
        return false
      }

      // unless `dontVerifyProductContext` is true, don't allow access to the route
      // if the product context is unvalid
      if (!isProductContextValid && route.dontVerifyLicensesContext !== true) {
        return false
      }

      // fallback to {} to avoid to have to test nullable parameters
      // in route's rbacPermissionsCheck declaration
      const routeParameters =
        appRouter.getRouteParameters(route.routeDefinition) || {}

      return storeRbac.isUserGrantedTo(
        route.rbacPermissionsCheck(routeParameters)
      )
    })
  }, [storeAuthentication.isAuthenticated, currentRouteName])

  const memoRouteMatcher = React.useMemo(
    () => routesMatcher(appRouter),
    [currentRouteName]
  )

  // save if the currentRouteName is present in the grantedRoutes
  const memoIsGrantedRoutePresent = React.useMemo(() => {
    return (
      memoGrantedRoutes.filter(route => {
        return memoRouteMatcher(
          route.routeDefinition.routeName,
          currentRouteName
        )
      }).length > 0
    )
  }, [currentRouteName])

  // On each currentRouteName change, check if present in isGrantedRoutePresent,
  // if not, perform a direction to the first firstGrantedRoute
  React.useEffect(() => {
    if (!props.redirect || !history || !currentRouteName) {
      return
    }

    const firstGrantedRoute = first(memoGrantedRoutes)

    if (firstGrantedRoute && !memoIsGrantedRoutePresent) {
      const path = appRouter.makeRouteInfosPathname(
        firstGrantedRoute.routeDefinition
      )

      logger.debug('[RbacRoutes] Redirect to:', path)

      history.replace(path)
    }
  }, [currentRouteName])

  // render the component of the first granted route
  for (const route of memoGrantedRoutes) {
    if (memoRouteMatcher(route.routeDefinition.routeName, currentRouteName)) {
      if (currentRouteName) {
        logger.debug(
          '[RbacRoutes] Render route:',
          route.routeDefinition,
          '; Current routename:',
          appRouter.getRoutePathname(currentRouteName),
          '; All granted routes:',
          memoGrantedRoutes
        )
      }

      const routerContext = {
        ...route.routeContext,
        routeName: route.routeDefinition.routeName
      }

      return (
        <RouteContext.Provider value={routerContext}>
          <route.component />
        </RouteContext.Provider>
      )
    }
  }

  return null
}

export default observer(RbacRoutes)
