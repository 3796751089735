import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface IDrawerAttributeValueContentValueProps {
  className?: string
  labelledBy?: string
  children?: React.ReactNode
}

const DrawerAttributeValueContentValue: React.FC<
  IDrawerAttributeValueContentValueProps
> = props => {
  const { testAttributeProps } = useTestAttribute('contentinfo')
  return (
    <div
      className={props.className}
      {...testAttributeProps()(props.labelledBy)}
    >
      {props.children}
    </div>
  )
}

export default styled(DrawerAttributeValueContentValue)`
  background-color: ${consts.colorBlueGray035};
  border: 1px solid ${consts.colorBlueGray030};
  ${consts.fontTextAreaDefault};
`
