import { BladesContainer } from '@app/components-legacy/Blade'
import { LayoutDefault } from '@app/components-legacy/Layout'
import { useStores } from '@app/hooks'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import * as React from 'react'
import DrawerNodeRelationships from './DrawerNodeRelationships'
import AttackPathBlade from './SceneBlade'

interface IAttackPathPageProps {}

const AttackPathPage: React.FC<IAttackPathPageProps> = props => {
  const { storeAttackPath } = useStores()

  return (
    <DSThemeProvider themeName={DSThemeName.default}>
      <LayoutDefault
        content={
          <BladesContainer>
            <AttackPathBlade />
          </BladesContainer>
        }
      />
      <DrawerNodeRelationships storeDrawer={storeAttackPath.storeDrawer} />
    </DSThemeProvider>
  )
}

export default AttackPathPage
