import type { IUseClipboardOptions } from '@app/components/Button/ButtonCopyToClipboard/hooks'
import { ButtonCopyToClipboard } from '@app/components-legacy/Button'
import {
  ContainerFooter,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import {
  DrawerContext,
  useDrawerContext
} from '@app/components-legacy/Drawer/context'
import { handleDrawerOnClose } from '@app/components-legacy/Drawer/handlers'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { LabelMissingData } from '@app/components-legacy/Label'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { mdToHTML } from '@libs/mdToHTML'
import * as React from 'react'

interface IDrawerAttackDetailsContentProps extends IUseClipboardOptions {
  className?: string
}

const DrawerAttackDetailsContent: React.FC<
  IDrawerAttackDetailsContentProps
> = () => {
  const contextValue = useDrawerContext<{
    attackVectorDescription: string
  }>(DrawerContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons']
  })

  if (!contextValue) {
    return <LabelMissingData />
  }

  const attackVectorDescription = contextValue.storeDrawer.getDataValue(
    'attackVectorDescription'
  )

  return (
    <>
      <ContainerHTMLContent>
        {mdToHTML(attackVectorDescription)}
      </ContainerHTMLContent>

      <Portal name={PlaceHolderName.drawerFooter}>
        <ContainerFooter>
          <FormWrapperButton
            labelledBy="close"
            buttonProps={{
              type: 'default',
              onClick: handleDrawerOnClose(contextValue.storeDrawer)
            }}
          >
            {translate('Close')}
          </FormWrapperButton>

          <ButtonCopyToClipboard
            variant="large"
            textToCopy={attackVectorDescription}
            buttonProps={{
              type: 'primary'
            }}
          />
        </ContainerFooter>
      </Portal>
    </>
  )
}

export default DrawerAttackDetailsContent
