import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

export interface ITableHeadCheckboxProps {
  className?: string
}

const TableHeadCheckbox: React.FC<ITableHeadCheckboxProps> = props => {
  return <th className={props.className} />
}

export default styled(TableHeadCheckbox)`
  padding-right: ${consts.paddingDefault};
  width: 30px;
`
