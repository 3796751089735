import type { IIconProps } from '@app/components/Icon/types'
import { FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconDotsProps extends IIconProps {}

const IconDots: React.FC<IIconDotsProps> = props => {
  const fill = props.color ?? FontColorV2.white
  const size = props.size ?? 9

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 9 2"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2C1.55228 2 2 1.55228 2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2Z"
        fill={fill}
      />
      <path
        d="M4.33301 2C4.88529 2 5.33301 1.55228 5.33301 1C5.33301 0.447715 4.88529 0 4.33301 0C3.78072 0 3.33301 0.447715 3.33301 1C3.33301 1.55228 3.78072 2 4.33301 2Z"
        fill={fill}
      />
      <path
        d="M7.66699 2C8.21928 2 8.66699 1.55228 8.66699 1C8.66699 0.447715 8.21928 0 7.66699 0C7.11471 0 6.66699 0.447715 6.66699 1C6.66699 1.55228 7.11471 2 7.66699 2Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconDots
