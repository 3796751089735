import IconPauseCircleOutlinedAntd from '@app/components/Icon/IconPauseCircleOutlinedAntd'
import IconPlayCircleOutlinedAntd from '@app/components/Icon/IconPlayCircleOutlinedAntd'
import { FormWrapperButton } from '@app/components-legacy/Form'
import {
  IconPauseCircleOutlined,
  IconPlayCircleOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  onTrailFlowPauseButtonClick,
  onTrailFlowStartButtonClick
} from './handlers'

export interface ITableHeaderLiveButtonProps {
  className?: string
  style?: React.CSSProperties
}

const TableHeaderLiveButton: React.FC<ITableHeaderLiveButtonProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const translate = useAppTranslator({ namespaces: ['TrailFlow.Table'] })

  const { storeWSIndicator, storeTrailFlow } = useStores()

  const isDisabled = !storeWSIndicator.isConnected

  if (isDisabled) {
    return (
      <Tooltip title={translate('The Trail Flow is unavailable')}>
        <FormWrapperButton
          className={props.className}
          style={props.style}
          labelledBy="start-the-trail-flow"
          icon={!themeIsLegacy ? IconPlayCircleOutlinedAntd : undefined}
          buttonProps={filterEmptyProps({
            disabled: true,
            icon: themeIsLegacy ? <IconPlayCircleOutlined /> : undefined
          })}
        />
      </Tooltip>
    )
  }

  if (storeTrailFlow.isLive) {
    return (
      <Tooltip
        title={translate('Pause the Trail Flow')}
        placement="topRight"
        arrowPointAtCenter
      >
        <FormWrapperButton
          className={props.className}
          style={props.style}
          labelledBy="pause-the-trail-flow"
          icon={!themeIsLegacy ? IconPauseCircleOutlinedAntd : undefined}
          buttonProps={filterEmptyProps({
            icon: themeIsLegacy ? <IconPauseCircleOutlined /> : undefined,
            onClick: onTrailFlowStartButtonClick(storeTrailFlow)
          })}
        />
      </Tooltip>
    )
  }

  return (
    <Tooltip
      title={translate('Restart the Trail Flow')}
      placement="topRight"
      arrowPointAtCenter
    >
      <FormWrapperButton
        className={props.className}
        style={props.style}
        labelledBy="restart-the-trail-flow"
        icon={!themeIsLegacy ? IconPlayCircleOutlinedAntd : undefined}
        buttonProps={filterEmptyProps({
          icon: themeIsLegacy ? <IconPlayCircleOutlined /> : undefined,
          onClick: onTrailFlowPauseButtonClick(storeTrailFlow)
        })}
      />
    </Tooltip>
  )
}

export default observer(TableHeaderLiveButton)
