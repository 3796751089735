import { LabelColorTag } from '@app/components/Labels'
import type { IConfigurationChangedItemField } from '@app/entities/EntityUserLog'
import { ConfigurationType } from '@app/entities/EntityUserLog'
import { BackgroundColor } from '@app/styles/consts'
import { isBoolean } from 'lodash'
import React from 'react'
import {
  colorUserLogActivatedRedesign,
  colorUserLogDeactivatedRedesign,
  colorUserLogPreviousValueRedesign
} from '../consts'

export interface IUserLogFieldProps {
  colorMode?: 'before' | 'after' | 'neutral'
  value: string | boolean
  field: IConfigurationChangedItemField
  fieldType?: ConfigurationType
  labelledBy?: string
}

const transformValue = (
  value: string | boolean,
  field: IConfigurationChangedItemField,
  fieldType?: ConfigurationType
): string | boolean => {
  if (fieldType === ConfigurationType.userRole) {
    return String(field)
  }

  switch (field) {
    case 'password':
      return '**************'
    default:
      return isBoolean(value) || String(value).length > 0 ? value : 'N/A'
  }
}

const getColorsFromMode = (
  mode: IUserLogFieldProps['colorMode']
): { text?: string; background?: string } => {
  switch (mode) {
    case 'before':
      return colorUserLogPreviousValueRedesign
    case 'after':
      return colorUserLogActivatedRedesign

    default:
      return {
        background: BackgroundColor.secondary
      }
  }
}

const UserLogField = (props: IUserLogFieldProps) => {
  const { value, field, fieldType, colorMode } = props

  if (isBoolean(value) && fieldType !== ConfigurationType.userRole) {
    return (
      <LabelColorTag
        color={
          value
            ? colorUserLogActivatedRedesign.background
            : colorUserLogDeactivatedRedesign.background
        }
        labelProperties={{
          color: value
            ? colorUserLogActivatedRedesign.text
            : colorUserLogDeactivatedRedesign.text,
          labelledBy: props.labelledBy
        }}
      >
        {value === true ? 'Activated' : 'Deactivated'}
      </LabelColorTag>
    )
  }

  const colors = getColorsFromMode(colorMode)

  return (
    <LabelColorTag
      color={colors.background}
      labelProperties={{
        color: colors.text,
        labelledBy: props.labelledBy
      }}
    >
      {transformValue(value, field, fieldType)}
    </LabelColorTag>
  )
}

export default UserLogField
