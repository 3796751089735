import LabelWarning from '@app/components/Labels/LabelWarning'
import { useAppTranslator } from '@app/hooks'
import * as React from 'react'

export interface ILabelTidAddOnErrorProps {
  children?: React.ReactNode
}

export default function LabelTidAddOnError(props: ILabelTidAddOnErrorProps) {
  const translate = useAppTranslator({
    namespaces: ['Components.LabelTidAddOnError']
  })

  return (
    <LabelWarning>
      {props.children ?? translate('Impossible to retrieve Azure AD data')}
    </LabelWarning>
  )
}
