import * as React from 'react'
import TableRows from './TableRows'

export interface ITableProps {}

const Table: React.FC<ITableProps> = props => {
  return <TableRows />
}

export default Table
