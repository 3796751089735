import { Features } from '@alsid/common'
import {
  ContainerContent,
  ContainerFlex,
  ContainerFooter,
  ContainerIcon,
  ContainerRbac
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import {
  IconDeleteOutlined,
  IconEditOutlined
} from '@app/components-legacy/Icon/IconAntd'
import IconBlank from '@app/components-legacy/Icon/IconBlank'
import { InputSearch } from '@app/components-legacy/Input'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { SpinnerInlineSize } from '@app/components-legacy/Spinner/SpinnerInline'
import {
  WidgetListActionButtons,
  WidgetListActionsHeader,
  WidgetListCountItems,
  WidgetListPagination,
  WidgetListTable,
  WidgetListTableActionIcons
} from '@app/components-legacy/Widgets/WidgetList'
import type { EntityInfrastructure } from '@app/entities'
import type { IDataRowInfrastructure } from '@app/entities/EntityInfrastructure'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canCreateDirectories } from '../../Directories/permissions'
import {
  canCreateInfrastructures,
  canDeleteInfrastructures,
  canEditInfrastructures
} from '../permissions'
import {
  handleSearchInfrastructureOnChange,
  handleSearchInfrastructureOnReset,
  onAddDirectoryClick,
  onAddInfrastructureClick,
  onDeleteInfrastructureIconClick,
  onEditInfrastructureIconClick,
  onPageChange
} from './handlers'

export interface IInfrastructuresListProps {}

const InfrastructuresList: React.FC<IInfrastructuresListProps> = props => {
  const featureForestAndDomainSearch = useFeatureFlag({
    featureFlagName: Features.FOREST_AND_DOMAIN_SEARCH
  })

  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Infrastructures',
      'Management.System.Directories'
    ]
  })

  const appRouter = useAppRouter()

  const { storeManagementInfrastructures } = useStores()

  const hasInfrastructures =
    storeManagementInfrastructures.infrastructures.size > 0

  return (
    <>
      <ContainerFlex
        name="InfrastructuresList"
        direction="column"
        items={[
          featureForestAndDomainSearch ? (
            <InputSearch
              placeholder={translate('Search for a forest')}
              storeInputSearch={storeManagementInfrastructures.storeInputSearch}
              onReset={handleSearchInfrastructureOnReset(
                storeManagementInfrastructures
              )}
              onChange={handleSearchInfrastructureOnChange(
                storeManagementInfrastructures
              )}
            />
          ) : null,
          <WidgetListActionsHeader>
            <WidgetListCountItems
              storeWidgetList={storeManagementInfrastructures.storeWidgetList}
            />

            <WidgetListActionButtons>
              <ContainerRbac rbacCapabilityCheck={canCreateInfrastructures}>
                <FormWrapperButton
                  labelledBy="addForest"
                  buttonProps={{
                    type: 'primary',
                    onClick: onAddInfrastructureClick(appRouter)
                  }}
                >
                  {translate('Add a forest')}
                </FormWrapperButton>
              </ContainerRbac>

              {hasInfrastructures && (
                <ContainerRbac rbacCapabilityCheck={canCreateDirectories}>
                  <FormWrapperButton
                    labelledBy="addDirectory"
                    buttonProps={{
                      type: 'primary',
                      onClick: onAddDirectoryClick(appRouter)
                    }}
                  >
                    {translate('Add a domain')}
                  </FormWrapperButton>
                </ContainerRbac>
              )}
            </WidgetListActionButtons>
          </WidgetListActionsHeader>,

          <ContainerContent
            flags={storeManagementInfrastructures.storeSearchFlags.flags}
            spinner={<SpinnerInline size={SpinnerInlineSize.small} />}
          >
            <WidgetListTable<EntityInfrastructure, IDataRowInfrastructure>
              translate={translate}
              hiddenColumnsKeys={['id']}
              storeWidgetList={storeManagementInfrastructures.storeWidgetList}
              actionsButtonsRenderFn={infrastructureId => {
                return (
                  <WidgetListTableActionIcons
                    icons={[
                      <ContainerRbac
                        rbacCapabilityCheck={canEditInfrastructures(
                          Number(infrastructureId)
                        )}
                        render={({ isGranted }) => {
                          return isGranted ? (
                            <ContainerIcon
                              labelledBy={`editInfrastructure-${infrastructureId}`}
                              iconComponent={IconEditOutlined}
                              iconProps={{
                                type: 'edit',
                                onClick: onEditInfrastructureIconClick(
                                  appRouter
                                )(Number(infrastructureId))
                              }}
                            />
                          ) : (
                            <IconBlank />
                          )
                        }}
                      />,

                      <ContainerRbac
                        rbacCapabilityCheck={canDeleteInfrastructures(
                          Number(infrastructureId)
                        )}
                        render={({ isGranted }) => {
                          return isGranted ? (
                            <ContainerIcon
                              labelledBy={`deleteInfrastructure-${infrastructureId}`}
                              iconComponent={IconDeleteOutlined}
                              iconProps={{
                                onClick: onDeleteInfrastructureIconClick(
                                  storeManagementInfrastructures
                                )(Number(infrastructureId))
                              }}
                            />
                          ) : (
                            <IconBlank />
                          )
                        }}
                      />
                    ]}
                  />
                )
              }}
            />
          </ContainerContent>
        ]}
        spaced
      />

      <Portal name={PlaceHolderName.bladeFooter}>
        <ContainerFooter>
          <ContainerFlex
            name="Pagination"
            justifyContent="flex-end"
            items={[
              <WidgetListPagination
                storeWidgetList={storeManagementInfrastructures.storeWidgetList}
                onChange={onPageChange(storeManagementInfrastructures)}
              />
            ]}
          />
        </ContainerFooter>
      </Portal>
    </>
  )
}

export default observer(InfrastructuresList)
