import type { StoreManagementReportingCenter } from '@app/stores'
import { action } from 'mobx'

/**
 * Open the drawer of the report edition.
 */
export const handleReportEditionOnClick =
  (storeManagementReportingCenter: StoreManagementReportingCenter) =>
  (reportId: number) =>
    action(() => {
      const reportEntity = storeManagementReportingCenter.reports.get(reportId)

      if (reportEntity) {
        storeManagementReportingCenter.storeDrawerReportAction
          .setData({ reportDataRow: reportEntity.asDataRow() })
          .openDrawer()
      }
    })

/**
 * Open the drawer of the report access token refresh.
 */
export const handleReportAccessTokenRefreshOnClick = (
  storeManagementReportingCenter: StoreManagementReportingCenter
) =>
  action(() => {
    storeManagementReportingCenter.storeModalConfirmReportAccessTokenRefresh.show()
  })
