import type { IIconProps } from '@app/components/Icon/types'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { LinkButton } from '@app/components-legacy/Link'
import { consts } from '@app/styles'
import { BackgroundColorV2, FontColorV2 } from '@app/styles/consts'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { Dropdown, Menu } from 'antd'
import type { ItemType } from 'antd/es/menu/hooks/useItems'
import * as React from 'react'
import styled from 'styled-components'

interface IDropdownMenuProps {
  label?: string
  labelledBy: string
  items: ItemType[]
  icon: React.FC<IIconProps>
}

const StyledMenu = styled(Menu)`
  background: ${BackgroundColorV2.tooltips};
  padding: ${consts.paddingVerySmall};
  margin: 0;
  border-radius: ${consts.borderRadiusRedesign};
  margin-top: ${consts.marginVerySmall};

  .ant-dropdown-menu-item {
    color: ${FontColorV2.white};
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    background: ${BackgroundColorV2.tooltips};
    padding-bottom: ${consts.paddingDefault};
    padding-top: ${consts.paddingDefault};
  }

  .ant-dropdown-menu-item:last-child {
    border-bottom: 0;
  }
`

const DropdownMenu: React.FC<IDropdownMenuProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const renderMenu = (): JSX.Element => {
    return themeIsLegacy ? (
      <Menu items={props.items} />
    ) : (
      <StyledMenu items={props.items} />
    )
  }

  return themeIsLegacy ? (
    <Dropdown overlay={renderMenu()} trigger={['click']}>
      <LinkButton
        iconComponent={props.icon}
        label={props.label ?? ''}
        labelledBy={props.labelledBy}
      />
    </Dropdown>
  ) : (
    <Dropdown overlay={renderMenu()} trigger={['click']}>
      <div>
        <FormWrapperButton
          icon={props.icon}
          variant={ButtonVariant.secondary}
          size={ButtonSize.small}
          labelledBy={props.labelledBy}
        />
      </div>
    </Dropdown>
  )
}

export default DropdownMenu
