import type { Mutation } from '../typeDefs/types'

/**
 * Mutation of IoE alerts.
 */

export type MutationEditAlertsIoE = Pick<Mutation, 'editAlertsIoE'>

export const mutationEditAlertsIoE = `
  mutation mutationEditAlertsIoE($alerts: [InputEditAlertIoE!]!) {
    editAlertsIoE(
      alerts: $alerts
    ) {
      node {
        id
        archived
        read
      }
      err
      status
    }
  }
`

export type MutationEditBulkAlertsIoE = Pick<Mutation, 'editBulkAlertsIoE'>

export const mutationEditBulkAlertsIoE = `
  mutation mutationEditBulkAlertsIoE($alertBulkPayload: InputEditAlertIoEBulkPayload!) {
    editBulkAlertsIoE(
      alertBulkPayload: $alertBulkPayload
    )
  }
`

/**
 * Mutation of IoA alerts.
 */

export type MutationEditAlertsIoA = Pick<Mutation, 'editAlertsIoA'>

export const mutationEditAlertsIoA = `
  mutation mutationEditAlertsIoA($alerts: [InputEditAlertIoA!]!) {
    editAlertsIoA(
      alerts: $alerts
    )
  }
`

export type MutationEditBulkAlertsIoA = Pick<Mutation, 'editBulkAlertsIoA'>

export const mutationEditBulkAlertsIoA = `
  mutation mutationEditBulkAlertsIoA($alertBulkPayload: InputEditAlertIoABulkPayload!) {
    editBulkAlertsIoA(
      alertBulkPayload: $alertBulkPayload
    )
  }
`
