import { createEntity, EntityEvent } from '@app/entities'
import type { EventId } from '@app/entities/EntityEvent'
import type { StoreRoot } from '@app/stores'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { checkRbac } from '@libs/rbac/functions'
import type { QueryEventDetails } from '@server/graphql/queries/event'
import { queryEventDetails } from '@server/graphql/queries/event'
import type {
  Event,
  EventByIdQueryArgs,
  Maybe,
  RbacDirectoriesQueryArgs,
  RbacInfrastructuresQueryArgs,
  RbacReasonsEventArgs
} from '@server/graphql/typeDefs/types'
import { first } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import StoreEventDetailsAttributes from './StoreEventDetailsAttributes'
import StoreEventDetailsDeviances from './StoreEventDetailsDeviances'

export default class StoreEventDetails extends StoreBase {
  public storeFlagsEventDetails = new StoreFlags(this.storeRoot)

  public storeEventDetailsAttributes = new StoreEventDetailsAttributes(
    this.storeRoot
  )

  public storeEventDetailsDeviances = new StoreEventDetailsDeviances(
    this.storeRoot
  )

  /* Observables */

  private $event = observable.box<Maybe<EntityEvent>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  fetchEventDetails(
    infrastructureId: number,
    directoryId: number,
    eventId: EventId
  ): Promise<void> {
    this.storeFlagsEventDetails.loading()

    const profileId = this.storeRoot.stores.storeAuthentication.currentProfileId

    const args: RbacInfrastructuresQueryArgs &
      RbacDirectoriesQueryArgs &
      EventByIdQueryArgs &
      RbacReasonsEventArgs = {
      profileId,
      infrastructureId,
      directoryId,
      eventId
    }

    return this.storeRoot
      .getGQLRequestor()
      .query<QueryEventDetails>(queryEventDetails, args)
      .then(({ rbacInfrastructures, rbacDirectories }) => {
        if (
          !checkRbac(
            this.storeRoot,
            this.storeFlagsEventDetails
          )(rbacInfrastructures)
        ) {
          throw new ForbiddenAccessError()
        }

        if (
          !checkRbac(
            this.storeRoot,
            this.storeFlagsEventDetails
          )(rbacDirectories)
        ) {
          throw new ForbiddenAccessError()
        }

        return {
          directoryId: first(rbacInfrastructures.node.node)?.id,
          event: first(rbacDirectories.node.node)?.event
        }
      })
      .then(data => {
        if (!data.directoryId || !data.event) {
          throw new Error('Event is not defined')
        }

        this.setEventEntity(data.directoryId, data.event)

        this.storeFlagsEventDetails.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsEventDetails))
  }

  reset(): this {
    this.storeEventDetailsAttributes.reset()
    this.storeEventDetailsDeviances.reset()

    return this
  }

  /* Action */

  @action
  setEventEntity(directoryId: number, event: Event): this {
    this.$event.set(
      createEntity<Event, EntityEvent>(EntityEvent, event, directoryId)
    )

    return this
  }

  /* Computed */

  @computed
  get event(): Maybe<EntityEvent> {
    return this.$event.get()
  }

  @computed
  get hasDeviances(): boolean {
    if (!this.event) {
      return false
    }
    return this.event.getDeviances().length > 0
  }
}
