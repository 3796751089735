import { extractLanguageSubtagFromLocale } from '@app/stores/StoreApplicationConfiguration/utils'
import type { Translator } from '@libs/i18n'
import { Language } from '@server/graphql/typeDefs/types'
import * as moment from 'moment'
import type { ITranslateOptions } from './types'

import 'moment/min/locales'

/**
 * Extends moment formats for calendar.
 */
export function setupMomentCalendarFormat(translator: Translator): void {
  const preferedLanguage = translator.getFirstPreferredLanguage()

  const translateOptions: ITranslateOptions = {
    namespaces: ['Date']
  }

  const momentCalendarConfig: moment.LocaleSpecification['calendar'] = {
    sameDay: `[${translator.translate('Today', translateOptions)}]`,
    nextDay: `[${translator.translate('Tomorrow', translateOptions)}]`,
    nextWeek: 'dddd',
    lastDay: `[${translator.translate('Yesterday', translateOptions)}]`,
    lastWeek: 'dddd',
    sameElse: 'YYYY-MM-DD'
  }

  // Handle particular cases
  if (preferedLanguage === Language.FrFr) {
    momentCalendarConfig.sameElse = 'DD/MM/YYYY'
  }

  moment.updateLocale(extractLanguageSubtagFromLocale(preferedLanguage), {
    calendar: momentCalendarConfig
  })
}

/**
 * Setup the moment locale globally.
 */
export function setMomentLocale(locale: Language): void {
  moment.locale(extractLanguageSubtagFromLocale(locale))
}

/**
 * Return the main moment module (localized).
 */
export function getMoment(): typeof moment {
  return moment
}
