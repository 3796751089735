import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import {
  LabelAttackerKnownTool,
  LabelMissingData
} from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import { CheckerDetailsPageContext } from '../context'

interface IAttackerKnownToolsProps {}

const AttackerKnownTools: React.FC<IAttackerKnownToolsProps> = () => {
  const parameters = React.useContext(CheckerDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoE.Details.Information']
  })

  const { storeIoE } = useStores()

  if (!parameters) {
    return null
  }

  const renderContent = () => {
    const checker = storeIoE.checkers.get(parameters.checkerCodename)

    if (!checker) {
      return <LabelMissingData />
    }

    const items = checker.getAttackerKnownTools().map(tool => {
      if (!tool.name) {
        return null
      }

      return (
        <LabelAttackerKnownTool
          name={tool.name}
          url={tool.url}
          author={tool.author}
        />
      )
    })

    if (!items.length) {
      return <div>{translate('No tools listed for this indicator')}</div>
    }

    return (
      <ContainerFlex
        name="AttackerKnownTools"
        direction="column"
        items={items}
        spaced
        spaceWidth="small"
      />
    )
  }

  return (
    <ContainerContent
      labelledBy="attackerKnownTools"
      title={translate('Attacker known tools')}
    >
      {renderContent()}
    </ContainerContent>
  )
}

export default AttackerKnownTools
