import { useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export interface IHealthCheckGlobalStatusConditioningProps {
  children: React.ReactNode
}

const HealthCheckGlobalStatusConditioning: React.FC<
  IHealthCheckGlobalStatusConditioningProps
> = props => {
  const { storeManagementApplicationSettings } = useStores()

  if (
    !storeManagementApplicationSettings.applicationSettings
      ?.healthCheckGlobalStatusDisplayEnabled
  ) {
    return null
  }

  return <>{props.children}</>
}

export default observer(HealthCheckGlobalStatusConditioning)
