import { AppRouteName } from '@app/routes'
import type { StoreIoA } from '@app/stores'
import type StoreBoard from '@app/stores/IoA/StoreBoard'
import type { IoABoardQueryStringParameters } from '@app/stores/IoA/StoreBoard/types'
import type { EditAttacksSummaryLayoutMutationArgs } from '@server/graphql/typeDefs/types'

/**
 * Reinitialize the board view the querystring in parameters.
 */
export async function updateIoABoardFiltersFromQueryParams(
  storeBoard: StoreBoard,
  boardFilters?: IoABoardQueryStringParameters
): Promise<void> {
  if (boardFilters && boardFilters.layout) {
    const profileId =
      storeBoard.storeRoot.stores.storeAuthentication.currentProfileId

    const args: EditAttacksSummaryLayoutMutationArgs = {
      layout: {
        layout: boardFilters.layout
      },
      profileId
    }

    await storeBoard.editSummaryLayout(args)
  }

  storeBoard.updateFiltersStoresFromQueryString(boardFilters)
}

/**
 * Save the board filters in querystring.
 */
export function updateIoABoardUrlWithFilters(storeIoA: StoreIoA) {
  const appRouter = storeIoA.storeRoot.appRouter

  const boardFilters = storeIoA.computeBoardQueryParameters()

  const url = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.IoA_Board,
    parameters: {},
    queryStringParameters: {
      boardFilters
    }
  })

  storeIoA.storeRoot.stores.storeBlades.updateLastBladeUrl(url)

  appRouter.history.replace(url)
}
