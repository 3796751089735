import type { TContainerFlexAlignItems } from '@app/components/Container/ContainerFlex/types'
import antdMessage from 'antd/lib/message'
import type { ARIARole } from 'aria-query'
import type { ObservableMap } from 'mobx'
import { action, computed, makeObservable, observable } from 'mobx'
import * as uuid from 'uuid'
import type { StoreRoot } from '.'
import StoreBase from './StoreBase'
import type { IStoreOptions } from './types'

export enum MessageType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error'
}

export interface IMessageOptions {
  type?: MessageType
  html?: boolean
  duration?: number
  customIcon?: 'success' | 'error' // extends these union if needed
  closable?: boolean
  ariaRoles?: ARIARole | ARIARole[]
  labelledBy: string
  alignItems?: TContainerFlexAlignItems
}

export interface IMessage {
  key: string
  message: string
  date: Date
  options: IMessageOptions
}

// in seconds
export const MESSAGES_DEFAULT_DURATION = 6

export default class StoreMessages extends StoreBase {
  // map of messages
  private $messages = observable.map<string, IMessage>()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  success(message: string, options: IMessageOptions): string {
    return this.pushAlert(message, {
      type: MessageType.success,
      ariaRoles: ['alert'],
      ...options
    })
  }

  info(message: string, options: IMessageOptions): string {
    return this.pushAlert(message, {
      type: MessageType.info,
      ariaRoles: ['alert'],
      ...options
    })
  }

  warning(message: string, options: IMessageOptions): string {
    return this.pushAlert(message, {
      type: MessageType.warning,
      ariaRoles: ['alert'],
      ...options
    })
  }

  error(message: string, options: IMessageOptions): string {
    return this.pushAlert(message, {
      type: MessageType.error,
      ariaRoles: ['alert'],
      ...options
    })
  }

  /**
   * Push a standardized error message when something bad happens during the
   * data fetching.
   */
  genericError() {
    const translate = this.storeRoot.appTranslator.bindOptions({
      namespaces: ['Errors']
    })
    this.error(translate('An error has occurred'), {
      labelledBy: 'genericError'
    })
  }

  /**
   * Push a standardized error message when a validation has failed.
   */
  validationError() {
    const translate = this.storeRoot.appTranslator.bindOptions({
      namespaces: ['Errors.Form']
    })

    this.error(translate('Validation failed. Please correct the errors'), {
      labelledBy: 'validationError'
    })
  }

  /**
   * Remove a displayed alert by its key.
   */
  removeAlert(key: string): this {
    antdMessage.destroy(key)

    return this
  }

  /* Actions */

  @action
  private pushAlert(message: string, options: IMessageOptions): string {
    const messageKey = uuid.v4()

    const messageObj: IMessage = {
      key: messageKey,
      message,
      date: new Date(),
      options
    }

    const now = new Date()
    const delay = MESSAGES_DEFAULT_DURATION * 1000

    // remove messages older than MESSAGES_DELAY
    this.$messages.forEach((m, key) => {
      if (now.getTime() - m.date.getTime() > delay) {
        this.$messages.delete(key)
      }
    })

    // had only the message is not already present
    if (!this.$messages.has(message)) {
      this.$messages.set(message, messageObj)
    }

    return messageKey
  }

  /* Computed */

  /**
   * Return the observable to push messages via AntD.
   */
  @computed
  get messages(): ObservableMap<string, IMessage> {
    return this.$messages
  }
}
