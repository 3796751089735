import type { Mutation } from '@server/graphql/typeDefs/types'

export type MutationCreateSearchBookmarkEntry = Pick<
  Mutation,
  'createSearchBookmarkEntry'
>

export const mutationCreateSearchBookmarkEntry = `
  mutation mutationCreateSearchBookmarkEntry($searchBookmarkEntry: InputCreateSearchBookmarkEntry!) {
    createSearchBookmarkEntry(
      searchBookmarkEntry: $searchBookmarkEntry
    ) {
      id
      expression
      name
      category
    }
  }
`

export type MutationDeleteSearchBookmarkEntry = Pick<
  Mutation,
  'deleteSearchBookmarkEntry'
>

export const mutationDeleteSearchBookmarkEntry = `
  mutation mutationDeleteSearchBookmarkEntry($searchBookmarkEntry: InputDeleteSearchBookmarkEntry!) {
    deleteSearchBookmarkEntry(
      searchBookmarkEntry: $searchBookmarkEntry
    )
  }
`

export type MutationEditSearchBookmarkCategory = Pick<
  Mutation,
  'editSearchBookmarkCategory'
>

export const mutationEditSearchBookmarkCategory = `
  mutation mutationEditSearchBookmarkCategory($searchBookmarkCategory: InputEditSearchBookmarkCategory!) {
    editSearchBookmarkCategory(
      searchBookmarkCategory: $searchBookmarkCategory
    ) 
  }
`

export type MutationDeleteSearchBookmarkCategory = Pick<
  Mutation,
  'deleteSearchBookmarkCategory'
>

export const mutationDeleteSearchBookmarkCategory = `
  mutation mutationDeleteSearchBookmarkCategory($searchBookmarkCategory: InputDeleteSearchBookmarkCategory!) {
    deleteSearchBookmarkCategory(
      searchBookmarkCategory: $searchBookmarkCategory
    )
  }
`

export type MutationEditSearchBookmarkEntry = Pick<
  Mutation,
  'editSearchBookmarkEntry'
>

export const mutationEditSearchBookmarkEntry = `
  mutation mutationEditSearchBookmarkEntry($searchBookmarkEntry: InputEditSearchBookmarkEntry!) {
    editSearchBookmarkEntry(
      searchBookmarkEntry: $searchBookmarkEntry
    ) {
      id
      expression
      name
      category
    }
  }
`
