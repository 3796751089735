import { Label, LabelColorTag } from '@app/components/Labels'
import { ContainerFlex } from '@app/components-legacy/Container'
import type { IContainerSimpleProps } from '@app/components-legacy/Container/ContainerSimple'
import type {
  IConfigurationChanged,
  IConfigurationChangedItem,
  IRoleFieldChanged
} from '@app/entities/EntityUserLog'
import type EntityUserLog from '@app/entities/EntityUserLog'
import { ConfigurationType } from '@app/entities/EntityUserLog'
import { consts } from '@app/styles'
import {
  BackgroundColor,
  CTAColorV2,
  marginSmall,
  marginSmaller
} from '@app/styles/consts'
import { isDefined } from '@libs/isDefined'
import React from 'react'
import styled from 'styled-components'
import UserLogCollapseRow from '../../Components/UserLogCollapseRow'
import UserLogDirectory from '../../Components/UserLogDirectory'
import { UserLogIcon } from '../../Components/UserLogIcon'
import UserLogInfrastructure from '../../Components/UserLogInfrastructure'
import { UserLogIpAddress } from '../../Components/UserLogIpAddress'
import UserLogTime from '../../Components/UserLogTime'
import { UserLogUser } from '../../Components/UserLogUser'
import { colorUserLogRoleNameRedesign } from '../../consts'
import UserTraceConfigurationChangeItem from './UserTraceConfigurationChangeItem'

interface IUserLogRowProps extends IContainerSimpleProps {
  log: EntityUserLog
}

interface ISharedInfrastructure {
  infrastructureId: number
  infrastructureName: string
}

interface ISharedDirectory {
  directoryId: number
  directoryName: string
}

interface ISharedProfile {
  profileId: number
  profileName: string
}

interface ISharedChecker {
  checkerId: number
  checkerName: string
}

interface ISharedAttackType {
  attackTypeId: number
  attackTypeName: string
}

const StyledLabel = styled(Label)`
  margin-right: ${consts.marginVeryVerySmall};
`

const getSharedInfrastructure = (
  items: IConfigurationChangedItem[]
): ISharedInfrastructure | null => {
  if (items.length === 0) {
    return null
  }

  const infrastructureIds = items.map(x => x.infrastructureId)
  const doItemsSharedTheSameInfrastructure = infrastructureIds.every(
    x => x === infrastructureIds[0]
  )

  if (!doItemsSharedTheSameInfrastructure) {
    return null
  }

  const sharedInfrastructureId = items[0].infrastructureId
  const sharedInfrastructureName = items[0].infrastructureName

  if (
    sharedInfrastructureId === undefined ||
    sharedInfrastructureName === undefined
  ) {
    return null
  }

  const sharedInfrastructure: ISharedInfrastructure = {
    infrastructureId: sharedInfrastructureId,
    infrastructureName: sharedInfrastructureName
  }

  return sharedInfrastructure
}

const getSharedDirectory = (
  items: IConfigurationChangedItem[]
): ISharedDirectory | null => {
  if (items.length === 0) {
    return null
  }

  const directoryIds = items.map(x => x.directoryId)
  const doItemsSharedTheSameDirectory = directoryIds.every(
    x => x === directoryIds[0]
  )

  if (!doItemsSharedTheSameDirectory) {
    return null
  }

  const sharedDirectoryId = items[0].directoryId
  const sharedDirectoryName = items[0].directoryName

  if (sharedDirectoryId === undefined || sharedDirectoryName === undefined) {
    return null
  }

  const sharedDirectory: ISharedDirectory = {
    directoryId: sharedDirectoryId,
    directoryName: sharedDirectoryName
  }

  return sharedDirectory
}

const getSharedProfile = (
  items: IConfigurationChangedItem[]
): ISharedProfile | null => {
  if (items.length === 0) {
    return null
  }

  const profileIds = items.map(x => x.profileId)
  const doItemsSharedTheSameProfile = profileIds.every(x => x === profileIds[0])

  if (!doItemsSharedTheSameProfile) {
    return null
  }

  const sharedProfileId = items[0].profileId
  const sharedProfileName = items[0].profileName

  if (sharedProfileId === undefined || sharedProfileName === undefined) {
    return null
  }

  const sharedProfile: ISharedProfile = {
    profileId: sharedProfileId,
    profileName: sharedProfileName
  }

  return sharedProfile
}

const getSharedChecker = (
  items: IConfigurationChangedItem[]
): ISharedChecker | null => {
  if (items.length === 0) {
    return null
  }

  const checkerIds = items.map(x => x.checkerId)
  const doItemsSharedTheSameChecker = checkerIds.every(x => x === checkerIds[0])

  if (!doItemsSharedTheSameChecker) {
    return null
  }

  const sharedCheckerId = items[0].checkerId
  const sharedCheckerName = items[0].checkerName

  if (sharedCheckerId === undefined || sharedCheckerName === undefined) {
    return null
  }

  const sharedChecker: ISharedChecker = {
    checkerId: sharedCheckerId,
    checkerName: sharedCheckerName
  }

  return sharedChecker
}

const getSharedAttackType = (
  items: IConfigurationChangedItem[]
): ISharedAttackType | null => {
  if (items.length === 0) {
    return null
  }

  const attackTypeIds = items.map(x => x.attackTypeId)
  const doItemsSharedTheSameAttackType = attackTypeIds.every(
    x => x === attackTypeIds[0]
  )

  if (!doItemsSharedTheSameAttackType) {
    return null
  }

  const sharedAttackTypeId = items[0].attackTypeId
  const sharedAttackTypeName = items[0].attackTypeName

  if (sharedAttackTypeId === undefined || sharedAttackTypeName === undefined) {
    return null
  }

  const sharedAttackType: ISharedAttackType = {
    attackTypeId: sharedAttackTypeId,
    attackTypeName: sharedAttackTypeName
  }

  return sharedAttackType
}

const UserLogConfigurationChangeRow: React.FC<IUserLogRowProps> = ({
  log: trace,
  className
}) => {
  if (!trace.decodedLogAttributes) {
    return null
  }

  const configurationChanges =
    trace.decodedLogAttributes as IConfigurationChanged

  const sharedInfrastructure = getSharedInfrastructure(
    configurationChanges.items
  )

  const sharedDirectory = sharedInfrastructure
    ? getSharedDirectory(configurationChanges.items)
    : null

  const sharedProfile = getSharedProfile(configurationChanges.items)
  const sharedChecker = getSharedChecker(configurationChanges.items)
  const sharedAttackType = getSharedAttackType(configurationChanges.items)

  let sortItemsFunction
  let logTypeField: JSX.Element[] = []

  const defaultLogTypeField = [
    <LabelColorTag
      color={CTAColorV2.secondary}
      labelProperties={{
        color: CTAColorV2.primary,
        labelledBy: configurationChanges.configurationType
      }}
    >
      {configurationChanges.configurationType}
    </LabelColorTag>
  ]

  switch (configurationChanges.configurationType) {
    case ConfigurationType.role: {
      if (configurationChanges.items.length) {
        const firstRoleConfField = configurationChanges.items[0]
          .field as IRoleFieldChanged
        const roleName = firstRoleConfField.roleName

        logTypeField = [
          <LabelColorTag
            color={colorUserLogRoleNameRedesign.background}
            labelProperties={{
              color: colorUserLogRoleNameRedesign.text,
              labelledBy: 'role-name'
            }}
          >
            {roleName}
          </LabelColorTag>,
          <LabelColorTag
            color={BackgroundColor.secondary}
            labelProperties={{
              labelledBy: configurationChanges.configurationType
            }}
          >
            {configurationChanges.configurationType}
          </LabelColorTag>
        ]
        break
      }
      logTypeField = defaultLogTypeField
      break
    }
    case ConfigurationType.checkerOption: {
      logTypeField = [
        <LabelColorTag
          color={BackgroundColor.secondary}
          labelProperties={{
            labelledBy: configurationChanges.configurationType
          }}
        >
          {configurationChanges.configurationType}
        </LabelColorTag>
      ]

      const sortByFieldName = (
        a: IConfigurationChangedItem,
        b: IConfigurationChangedItem
      ): number => {
        if (!a.field || !b.field) {
          // Can't do a comparaison, return 0
          return 0
        }

        return a.field < b.field ? -1 : 1
      }

      sortItemsFunction = (
        a: IConfigurationChangedItem,
        b: IConfigurationChangedItem
      ): number => {
        // No directoryNames, move first as it is the global conf
        if (!isDefined(a.directoryNames) || a.directoryNames.length === 0) {
          if (!isDefined(b.directoryNames) || b.directoryNames.length === 0) {
            return sortByFieldName(a, b)
          }
          return -1
        }

        // If b has also a global configuration, move after
        if (!isDefined(b.directoryNames) || b.directoryNames.length === 0) {
          return 1
        }

        if (a.directoryNames.length === b.directoryNames.length) {
          // Order by checker name
          return sortByFieldName(a, b)
        }

        // Reverse order to have configuration with higher first
        return a.directoryNames.length > b.directoryNames.length ? -1 : 1
      }
      break
    }
    case ConfigurationType.ioa:
    default:
      logTypeField = defaultLogTypeField
      break
  }

  const rowItems = [
    <UserLogIcon trace={trace} />,
    <UserLogUser user={trace.userEmail} />,
    <UserLogIpAddress ipAddress={trace.userIp} />,
    <StyledLabel>Has edited</StyledLabel>,
    <LabelColorTag
      color={BackgroundColor.secondary}
      labelProperties={{
        labelledBy: 'amountOfModification'
      }}
    >
      {configurationChanges.items.length}
    </LabelColorTag>,
    <StyledLabel>
      value{configurationChanges.items.length > 1 ? 's' : ''} of the
    </StyledLabel>,
    ...logTypeField,
    <StyledLabel>configuration</StyledLabel>,

    sharedDirectory && <StyledLabel>for the domain </StyledLabel>,
    sharedDirectory && (
      <UserLogDirectory {...sharedDirectory}></UserLogDirectory>
    ),
    sharedInfrastructure && <StyledLabel>for the forest </StyledLabel>,
    sharedInfrastructure && (
      <UserLogInfrastructure {...sharedInfrastructure}></UserLogInfrastructure>
    ),
    sharedProfile && (
      <StyledLabel>
        for the security profile name{' '}
        <LabelColorTag>{sharedProfile.profileName}</LabelColorTag>
      </StyledLabel>
    ),
    sharedChecker && (
      <StyledLabel>
        for the checker name{' '}
        <LabelColorTag>{sharedChecker.checkerName}</LabelColorTag>
      </StyledLabel>
    ),
    sharedAttackType && (
      <StyledLabel>
        for the attack-type name{' '}
        <LabelColorTag>{sharedAttackType.attackTypeName}</LabelColorTag>
      </StyledLabel>
    ),
    <UserLogTime log={trace} />
  ]

  const title = (
    <ContainerFlex
      name={UserLogConfigurationChangeRow.name}
      className={className}
      ariaRoles={['list', 'row']}
      flexWrap="wrap"
      labelledBy={UserLogConfigurationChangeRow.name}
      alignItems="center"
      items={rowItems}
    />
  )

  return (
    <UserLogCollapseRow title={title} log={trace}>
      {configurationChanges.items.sort(sortItemsFunction).map(item => (
        <div key={`${trace.id}${item.field}${item.before}${item.after}`}>
          <UserTraceConfigurationChangeItem
            changedItem={item}
            configurationType={configurationChanges.configurationType}
          />
        </div>
      ))}
    </UserLogCollapseRow>
  )
}

export default styled(UserLogConfigurationChangeRow)`
  border-radius: ${consts.borderRadiusRedesign};
  background-color: ${consts.colorWhite};
  padding-right: ${consts.paddingExtraLarge};
  padding-left: ${consts.paddingLarge};
  padding-top: ${consts.paddingSmall};
  padding-bottom: ${consts.paddingSmall};

  .traceUser {
    text-align: left;
    margin-right: ${marginSmaller};
  }

  .traceIpAddress {
    text-align: left;
    margin-right: ${marginSmall};
  }

  .traceAction {
    text-align: left;
  }

  .traceTime {
    text-align: right;
    flex: 1;
    width: 100%;
  }
`
