export enum SmtpEncryption {
  none = 'none',
  tls = 'tls',
  startTls = 'startTls'
}

// SMTP configuration
export enum SMTPServerFormFieldName {
  smtpServerAddress = 'smtpServerAddress',
  smtpServerPort = 'smtpServerPort',
  smtpAccount = 'smtpAccount',
  smtpAccountPassword = 'smtpAccountPassword',
  smtpEncryption = 'smtpEncryption',
  emailSender = 'emailSender',
  smtpRelayId = 'smtpRelayId'
}

// Security configuration
export enum SecurityConfigurationFormFieldName {
  internalCertificate = 'internalCertificate'
}

// Activity logs configuration
export enum ActivityLogsConfigurationFormFieldName {
  isActive = 'isActive',
  retentionDurationInMonth = 'retentionDurationInMonth'
}

// Tenable account configuration
export enum TenableAccountFormFieldName {
  defaultProfileId = 'defaultProfileId',
  defaultRoleIds = 'defaultRoleIds'
}

export enum TelemetryFormFieldName {
  telemetryEnabled = 'telemetryEnabled'
}

export enum HealthCheckFormFieldName {
  globalStatusDisplayEnabled = 'globalStatusDisplayEnabled '
}
