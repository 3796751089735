import type { Maybe } from '@@types/helpers'
import type { FieldError } from '@app/stores/helpers/StoreForm/types'
import type { TContainerFlexAlignItems } from '../ContainerFlex/types'

/**
 * @deprecated
 */
export interface IField {
  name: string
  version?: string
  label?: string
  required?: boolean
  description?: string
  highlight?: boolean
  errors?: FieldError[]
  // if true, don't show description or error
  hideDecoration?: boolean
  labelAlignItem?: TContainerFlexAlignItems
  control: Maybe<React.ReactNode>
  inputId?: string
  // free structure to pass data (unsafe)
  meta?: object
}

export enum ContainerFormVariant {
  // default size
  default = 'default',
  // inside a drawer
  drawer = 'drawer',
  // try to fill the entire space
  fill = 'fill',
  // let it take the space it needs
  natural = 'natural'
}
