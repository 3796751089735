import type { Query } from '../typeDefs/types'

export type QueryApiKey = Pick<Query, 'rbacApiKey'>

export const queryApiKey = `
  query queryApiKey {
    rbacApiKey {
      node {
        key
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
