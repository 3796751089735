import type { DomWindow } from '@@types/dom'
import type { EventType, IEvents } from '@alsid/common/libs/telemetry'
import type { LocalStorageSelectedApplication } from '@alsid/common/types/environment'
import type { SecurityEngineRouteName } from '@alsid/common/types/routes'
import type { Maybe } from '@productive-codebases/toolbox'

/**
 * Parameters passed to the iframe as querystring parameters.
 */
export interface ISecurityEngineParameters {
  /**
   * Specify the target origin allowing postMessage between different origins.
   * Used in dev when the iframe is served directly via the Vite server.
   */
  targetOrigin: string
  preferences: ISecurityEngineIframePreferences
}

/**
 * The url could be internal or external:
 *   "internal" value will navigate inside GI without passing to the parent (parent URL will still be updated)
 *   "external" value will send the url to the parent through the iFrameBus
 */
export type BackToButtonUrl =
  | { type: 'internal'; routeName: keyof typeof SecurityEngineRouteName }
  | { type: 'external'; url: string }

/**
 * Security Engine preferences encoded in the querystring of the iframe url.
 * Note that all scalar (boolean, number, etc) will be parsed as string.
 */
export interface ISecurityEngineIframePreferences {
  /** Language - Add more when translations are available */
  language: 'en' | 'fr'
  /** User's session */
  session: { uuid: string; containerUuid: string }
  /** SecurityEngine layout parameters */
  layout: {
    /**
     * Url of the "Back to Asset Inventory" button displayed on the details page.
     */
    backToAssetsButtonUrl: BackToButtonUrl
  }
  /** Environment: used for redirects */
  environment: {
    /** Origin (baseurl) of the current app */
    origin: string
    /** Origin of the Tenable platform (required for external links when GI is hosted on a different hostname) */
    tenablePlatformOrigin?: string
    /** True when being in a production context - Stringified as passed as querystring */
    production: 'true' | 'false'
    /** Optional prefix used on all XHR queries to target services  */
    servicesPrefixPath?: string
  }
  /** Feature flags set from the parent-app. */
  featureFlags?: ISecurityEngineFeatureFlags
}

/**
 * Security Engine injected feature flags
 */

export interface ISecurityEngineFeatureFlags {
  milestone2?: string
  milestone3?: string
  milestone4?: string
  milestone5?: string
  milestone6?: string
}

export type PartialSecurityEngineFeatureFlags = {
  [K in keyof ISecurityEngineFeatureFlags]?: ISecurityEngineFeatureFlags[K]
}

/**
 * SecurityEngine configuration passed via the SDK.
 */
export interface ISecurityEngineAppConfiguration {
  /** Endpoint of SecurityEngine entry page (index.html) */
  endpoint: string
  /** Optional initial page of SecurityEngine (Default: "/" for the assets list) */
  initialPage?: string
  /** Iframe optional styles for better in-app integration */
  iframeStyle?: object
  /** SecurityEngine preferences (passed as querystring parameters via the iframe) */
  preferences: ISecurityEngineIframePreferences
  /** Custom handlers to handle iframe messages */
  iframeMessageHandlers?: Partial<IIframeCommunicationBusHandlers>
}

/**
 * SecurityEngine/SDK messages definitions.
 */

/** Enum of iframe message actions */
export enum IframeActionEnum {
  dummyAction = 'dummyAction',
  getAppIronToken = 'getAppIronToken',
  returnIronTokenValue = 'returnIronTokenValue',
  onLocationChange = 'onLocationChange',
  onExternalLinkClick = 'onExternalLinkClick',
  trackTelemetry = 'trackTelemetry',
  getAppPathName = 'getAppPathName',
  returnAppPathName = 'returnAppPathName'
}

/** Dummy message for testing purposes */
export interface IIframeMessageDummyAction {
  action: IframeActionEnum.dummyAction
}

/** Message asking the app's ironToken */
export interface IIframeMessageGetAppIronToken {
  action: IframeActionEnum.getAppIronToken
}

/** Message returning the app's ironToken */
export interface IIframeMessageReturnIronTokenValue {
  action: IframeActionEnum.returnIronTokenValue
  ironToken: string
}

/** Message containing the link to open */
export interface IIframeMessageOpenLink {
  action: IframeActionEnum.onExternalLinkClick
  url: string
  target: '_blank' | '_self' | '_parent' | '_top' | 'framename'
  selectedApplication?: LocalStorageSelectedApplication
}

/** Message containing the iframe location pathname */
export interface IIframeMessageOnLocationChange {
  action: IframeActionEnum.onLocationChange
  pathname: Maybe<string>
  options?: {
    replace?: boolean
  }
}

/** Message containing the telemetry data for user's tracking */
export interface IIframeTrackTelemetry {
  action: IframeActionEnum.trackTelemetry
  data: {
    eventName: EventType
    eventProperties: IEvents[EventType]
  }
}

/** Message asking for the App pathname */
export interface IIframeMessageGetAppPathName {
  action: IframeActionEnum.getAppPathName
}

/** Message returning the App pathname */
export interface IIframeMessageReturnAppPathName {
  action: IframeActionEnum.returnAppPathName
  pathname: string
}

/** Union of messages between the app and the iframe */
export type IIframeMessageUnion =
  | IIframeMessageDummyAction
  | IIframeMessageGetAppIronToken
  | IIframeMessageReturnIronTokenValue
  | IIframeMessageOnLocationChange
  | IIframeMessageOpenLink
  | IIframeTrackTelemetry
  | IIframeMessageGetAppPathName
  | IIframeMessageReturnAppPathName

/**
 * IFrameCommunicationBus types.
 */

/** Configuration passed to IframeCommunicationBus constructor. */
export interface IIframeCommunicationBusConfiguration {
  /**
   * Specify the target origin allowing postMessage between differents origins.
   * Used in dev when the iframe is served directly via the Vite server.
   */
  targetOrigin: string
  /** Handlers to execute according to the received message */
  handlers: IIframeCommunicationBusHandlers
  /** Window object of the frame running the app */
  windowObject: DomWindow
}

/** Handler executed by IframeCommunicationBus when receiving a message */
export type IframeCommunicationBusHandler<
  TIframeMessage extends IIframeMessageUnion
> = (
  eventSource: { postMessage: (message: IIframeMessageUnion) => void },
  receivedMessage: TIframeMessage
) => void

/** Record of handlers passed to IframeCommunicationBus */
export interface IIframeCommunicationBusHandlers {
  [IframeActionEnum.dummyAction]: IframeCommunicationBusHandler<IIframeMessageDummyAction>
  [IframeActionEnum.getAppIronToken]: IframeCommunicationBusHandler<IIframeMessageGetAppIronToken>
  [IframeActionEnum.returnIronTokenValue]: IframeCommunicationBusHandler<IIframeMessageReturnIronTokenValue>
  [IframeActionEnum.onLocationChange]: IframeCommunicationBusHandler<IIframeMessageOnLocationChange>
  [IframeActionEnum.onExternalLinkClick]: IframeCommunicationBusHandler<IIframeMessageOpenLink>
  [IframeActionEnum.trackTelemetry]: IframeCommunicationBusHandler<IIframeTrackTelemetry>
  [IframeActionEnum.getAppPathName]: IframeCommunicationBusHandler<IIframeMessageGetAppPathName>
  [IframeActionEnum.returnAppPathName]: IframeCommunicationBusHandler<IIframeMessageReturnAppPathName>
}
