import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'

interface IIconIdentitiesProps extends IIconProps {}

export default function IconWeaknesses(props: IIconIdentitiesProps) {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.125 3.125L10 1.25L16.875 3.125V11.9115C16.875 15.625 13.2076 17.772 10.128 19.0729C10.0461 19.1075 9.95392 19.1075 9.872 19.0729C6.79224 17.7719 3.125 15.625 3.125 11.9143V3.125Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
