import { AppRouteName } from '@app/routes'
import type { StoreBlades, StoreManagementTenants } from '@app/stores'
import { TenantsFormFieldName } from '@app/stores/Management/StoreTenants'
import type { UpdateTenantSetting } from '@libs/openapi/service-identity-core'

/**
 * Initialize the form according to the selected tenant.
 */
export const handleTenantEditionPageOnLoad =
  (storeManagementTenants: StoreManagementTenants) =>
  (tenantId: string) =>
  async () => {
    storeManagementTenants.storeFormEdition.reset()

    return Promise.resolve()
      .then(() => {
        if (storeManagementTenants.tenants.size) {
          return
        }

        return storeManagementTenants.fetchTenants()
      })
      .then(() => storeManagementTenants.fetchCredentials())
      .then(() => {
        const entity = storeManagementTenants.tenants.get(tenantId)

        if (!entity) {
          return
        }

        storeManagementTenants.storeFormEdition
          .setDefaultFieldsValuesFromObject({
            [TenantsFormFieldName.name]: entity.getPropertyAsString('name'),
            [TenantsFormFieldName.credentialUUID]:
              entity.getPropertyAsString('credentialsId')
          })
          .reset()
      })
  }

/**
 * Handle the click on the cancel button
 */
export const handleTenantEditionCancelOnClick =
  (storeBlades: StoreBlades) => () => {
    const url = storeBlades.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Tenants,
      parameters: {}
    })

    storeBlades.goToBladeUrl(url)
  }

/**
 * Handle sending edited values for this tenant
 */
export const handleTenantEditionOnSubmit =
  (storeManagementTenants: StoreManagementTenants) =>
  (tenantSettingId: string) =>
  (e: React.FormEvent<any>) => {
    const { storeFormEdition, storeRoot } = storeManagementTenants
    const { appRouter } = storeManagementTenants.storeRoot

    e.preventDefault()

    if (!storeFormEdition.validate()) {
      return
    }

    const tenant: UpdateTenantSetting = {
      name: storeFormEdition.getFieldValueAsString(TenantsFormFieldName.name),
      credentialsId: storeFormEdition.getFieldValueAsString(
        TenantsFormFieldName.credentialUUID
      )
    }

    storeManagementTenants
      .editTenant(tenantSettingId, tenant)
      .then(() => {
        storeManagementTenants.fetchTenants()

        storeRoot.stores.storeMessages.success(
          storeManagementTenants.translate('Tenant X updated', {
            interpolations: {
              tenantName: tenant.name
            }
          }),
          {
            labelledBy: 'tenantUpdated'
          }
        )

        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Management_System_Tenants,
          parameters: {}
        })

        appRouter.history.push(url)
      })
      .catch(() => {
        // Avoid to redirect to the list of tenants if an error occurred during
        // the Tenant edition.
      })
  }
