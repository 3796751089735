import { FormWrapperSelect } from '@app/components-legacy/Form'
import type { IFormWrapperSelectGroupOptionProps } from '@app/components-legacy/Form/Wrappers/Select'
import type { EntityProfile } from '@app/entities'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { isDefined } from '@libs/isDefined'
import type { SelectValue } from 'antd/lib/select'
import * as React from 'react'

interface IInputSelectProfilesProps {
  className?: string
  labelledBy?: string
  onProfileChange: (profileId: SelectValue) => void
  defaultProfileName?: string
  profiles: EntityProfile[]
  disabled?: boolean
}

const InputSelectProfiles: React.FC<IInputSelectProfilesProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.InputSelectProfiles']
  })

  const options: IFormWrapperSelectGroupOptionProps[] = [
    {
      groupLabel: props.profiles.length
        ? translate('Available profiles')
        : translate('No other profile available'),
      options: props.profiles
        .map(profile => {
          const profileName = profile.getPropertyAsString('name')

          return {
            labelledBy: profileName,
            label: profileName,
            value: profileName
          }
        })
        .filter(isDefined)
    }
  ]

  return (
    <FormWrapperSelect<any>
      className={props.className}
      labelledBy={props.labelledBy ?? 'Profiles'}
      selectProps={{
        value: props.defaultProfileName,
        disabled: props.disabled,
        onChange: props.onProfileChange
      }}
      selectOptions={options}
    />
  )
}

export default InputSelectProfiles
