import type { Mutation } from '@server/graphql/typeDefs/types'

export type MutationCreateEmail = Pick<Mutation, 'createEmail'>

export const mutationCreateEmail = `
  mutation mutationCreateEmail($email: InputCreateEmail!) {
    createEmail(
      email: $email
    ) {
      address
      criticityThreshold
      directories {
        id
      }
      description
      shouldNotifyOnInitialFullSecurityCheck
      profiles
      checkers
      healthCheckNames
    }
  }
`

export type MutationEditEmail = Pick<Mutation, 'editEmail'>

export const mutationEditEmail = `
  mutation mutationEditEmail($email: InputEditEmail!) {
    editEmail(
      email: $email
    ) {
      id
      address
      criticityThreshold
      directories {
        id
      }
      description
      shouldNotifyOnInitialFullSecurityCheck
      profiles
      checkers
      healthCheckNames
    }
  }
`

export type MutationDeleteEmail = Pick<Mutation, 'deleteEmail'>

export const mutationDeleteEmail = `
  mutation mutationDeleteEmail($email: InputDeleteEmail!) {
    deleteEmail(
      email: $email
    )
  }
`

export type MutationTestNewEmail = Pick<Mutation, 'testNewEmail'>

export const mutationTestNewEmail = `
  mutation mutationTestNewEmail($email: InputTestNewEmail!) {
    testNewEmail(
      email: $email
    )
  }
`

export type MutationTestExistingEmail = Pick<Mutation, 'testExistingEmail'>

export const mutationTestExistingEmail = `
  mutation mutationTestExistingEmail($email: InputTestExistingEmail!) {
    testExistingEmail(
      email: $email
    )
  }
`
