/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { Score } from './Score'
import { ScoreFromJSON, ScoreFromJSONTyped, ScoreToJSON } from './Score'
import type { Tenant } from './Tenant'
import { TenantFromJSON, TenantFromJSONTyped, TenantToJSON } from './Tenant'

/**
 *
 * @export
 * @interface Identity
 */
export interface Identity {
  /**
   *
   * @type {string}
   * @memberof Identity
   */
  uuid: string
  /**
   *
   * @type {string}
   * @memberof Identity
   */
  name: string
  /**
   *
   * @type {Array<Tenant>}
   * @memberof Identity
   */
  tenants: Array<Tenant>
  /**
   *
   * @type {Score}
   * @memberof Identity
   */
  exposureScore: Score
  /**
   *
   * @type {number}
   * @memberof Identity
   */
  openedRisksCount: number
  /**
   *
   * @type {number}
   * @memberof Identity
   */
  totalAccessibleResources: number
}

/**
 * Check if a given object implements the Identity interface.
 */
export function instanceOfIdentity(value: object): boolean {
  if (!('uuid' in value)) return false
  if (!('name' in value)) return false
  if (!('tenants' in value)) return false
  if (!('exposureScore' in value)) return false
  if (!('openedRisksCount' in value)) return false
  if (!('totalAccessibleResources' in value)) return false
  return true
}

export function IdentityFromJSON(json: any): Identity {
  return IdentityFromJSONTyped(json, false)
}

export function IdentityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Identity {
  if (json == null) {
    return json
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
    tenants: (json['tenants'] as Array<any>).map(TenantFromJSON),
    exposureScore: ScoreFromJSON(json['exposure_score']),
    openedRisksCount: json['opened_risks_count'],
    totalAccessibleResources: json['total_accessible_resources']
  }
}

export function IdentityToJSON(value?: Identity | null): any {
  if (value == null) {
    return value
  }
  return {
    uuid: value['uuid'],
    name: value['name'],
    tenants: (value['tenants'] as Array<any>).map(TenantToJSON),
    exposure_score: ScoreToJSON(value['exposureScore']),
    opened_risks_count: value['openedRisksCount'],
    total_accessible_resources: value['totalAccessibleResources']
  }
}
