import { PAGINATION_PERPAGE_DEFAULT } from '@app/entities/EntityPagination'
import {
  AttacksSummaryChartCardType,
  AttacksSummaryLayout
} from '@server/graphql/typeDefs/types'

export const ATTACKS_DEFAULT_SUMMARY_LAYOUT = AttacksSummaryLayout.Criticity
export const ATTACKS_DEFAULT_CARD_CHART_TYPE =
  AttacksSummaryChartCardType.LineChart

export const ATTACKS_LIMIT = PAGINATION_PERPAGE_DEFAULT
export const ATTACKS_MAX_PAGES = 3
export const ATTACKS_MAX_BLADES = 5
