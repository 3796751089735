import type { IUseHistoryChangeOptions } from '@app/components-legacy/Router/hooks'
import { AppRouteName } from '@app/routes'
import type StoreActivityLogs from '@app/stores/ActivityLogs/StoreActivityLogs'
import { If } from '@libs/fp-helpers/If'
import { isDefined } from '@libs/isDefined'
import type { IUsersTracesRouteDefinition } from '@server/routes/api'
import moment from 'moment'

/**
 * Load activity logs data and more on scene loading.
 */
export const handleActivityLogsOnSceneLoad =
  (storeActivityLogs: StoreActivityLogs) => (): void => {
    const { appRouter } = storeActivityLogs.storeRoot
    const { storeInfrastructures } = storeActivityLogs.storeRoot.stores

    // We load the infrastructures so that we can use it inside traces
    If(!storeInfrastructures.infrastructures.size).fetch(() =>
      storeInfrastructures.fetchInfrastructures()
    )

    const qs =
      appRouter.getCurrentRouteQueryStringParameters<
        IUsersTracesRouteDefinition['queryStringParameters']
      >()

    if (qs && (isDefined(qs.dateStart) || isDefined(qs.dateEnd))) {
      const dateStart = isDefined(qs.dateStart) ? moment(qs.dateStart) : null
      const dateEnd = isDefined(qs.dateEnd) ? moment(qs.dateEnd) : null

      storeActivityLogs.storeDatePicker.setDatePickerRangeValue(
        [dateStart, dateEnd],
        'minutes'
      )
    }

    if (qs && isDefined(qs.ips)) {
      storeActivityLogs.storeActivityLogsFilters.setSelectedIPs(qs.ips)
    }

    if (qs && isDefined(qs.userEmails)) {
      storeActivityLogs.storeActivityLogsFilters.setSelectedUserEmails(
        qs.userEmails
      )
    }

    storeActivityLogs.fetchLogs({ replaceExistingLogs: true })
    storeActivityLogs.storeActivityLogsFilters.fetchFilterData()
  }

/**
 * Reset store activity logs on scene unloading.
 */
export const handleActivityLogsOnSceneUnload =
  (storeActivityLogs: StoreActivityLogs) => (): void => {
    storeActivityLogs.reset()
  }

/**
 * Handler that listens the browser history and update StoreActivityLogs
 * according to the current parameters.
 */
export const handleActivityLogsHistoryOnChange =
  (
    storeActivityLogs: StoreActivityLogs
  ): IUseHistoryChangeOptions['onHistoryChange'] =>
  (currentRouteInfo): void => {
    if (!currentRouteInfo) {
      return
    }
    if (currentRouteInfo.routeName !== AppRouteName.ActivityLogs) {
      return
    }

    storeActivityLogs.reset()
    storeActivityLogs.fetchLogs({ replaceExistingLogs: true })
  }
