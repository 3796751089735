import { AppRouteName } from '@app/routes'
import type { StoreManagementAttackTypeConfiguration } from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'

/**
 * Show a modal to confirm the save of the configuration.
 */
export const handleSaveConfigurationButtonClick =
  (
    storeManagementAttackTypeConfiguration: StoreManagementAttackTypeConfiguration
  ) =>
  (isGrantedAndOverQuota: boolean) =>
  () => {
    if (isGrantedAndOverQuota) {
      storeManagementAttackTypeConfiguration.storeModalEditAutoQuotaLimit.show()
      return
    }

    storeManagementAttackTypeConfiguration.storeModalSaveConfiguration.show()
  }

/**
 * Save the configuration and display a toast notification if we are on Automatic mode,
 * otherwise if we are on manual mode, open the see procedure modal
 */
export const handleSaveIoAConfigurationButtonClick =
  (
    storeManagementAttackTypeConfiguration: StoreManagementAttackTypeConfiguration
  ) =>
  (customPastDelaySeconds: number, isIoAAutomaticConfiguration: boolean) =>
  () => {
    const options = {
      forwardException: isIoAAutomaticConfiguration ? false : true
    }

    storeManagementAttackTypeConfiguration.setIsConfigurationSavedOnManualMode(
      false
    )

    return (
      Promise.resolve()
        .then(() => {
          return (
            storeManagementAttackTypeConfiguration
              // update IoA configuration first
              .updateIoAConfiguration({
                customPastDelaySeconds
              })
          )
        })
        // then update IoA indicators configuration
        .then(() => {
          return storeManagementAttackTypeConfiguration
            .updateAttackTypeConfiguration(
              storeManagementAttackTypeConfiguration.inputAttackTypeConfiguration,
              options
            )
            .then(() =>
              storeManagementAttackTypeConfiguration.reloadAttackTypeConfiguration()
            )
            .then(() => {
              if (!isIoAAutomaticConfiguration) {
                storeManagementAttackTypeConfiguration.setIsConfigurationSavedOnManualMode(
                  true
                )
                storeManagementAttackTypeConfiguration.storeModalProcedure.show()
              }
            })
        })
    )
  }

/**
 * Open a new page to download the installation script.
 */
export const handleDownloadScriptButtonClick =
  (appRouter: AppRouterClient) => () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareIoAConfiguration_GenerateScript,
      parameters: {}
    })

    // Open the url to trigger the download of the installation script.
    window.open(url)
  }

/**
 * Open a new page to download the IoA config.
 */
export const handleDownloadIoAConfigButtonClick =
  (appRouter: AppRouterClient) => () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareIoAConfiguration_IoAConfig,
      parameters: {}
    })

    // Open the url to trigger the download of the installation script.
    window.open(url)
  }

/**
 * Cancel all the changes made in the configuration.
 */
export const handleCancelEditsButtonClick =
  (
    storeManagementAttackTypeConfiguration: StoreManagementAttackTypeConfiguration
  ) =>
  () => {
    storeManagementAttackTypeConfiguration.cancelEdits()
  }

/**
 * Cancel the save of the configuration.
 */
export const handleModalSaveConfigurationCancelButton =
  (
    storeManagementAttackTypeConfiguration: StoreManagementAttackTypeConfiguration
  ) =>
  () => {
    storeManagementAttackTypeConfiguration.storeModalSaveConfiguration.hide()
  }

/**
 * Confirm the save of the configuration and reload the table (to reset activity status).
 */
export const handleModalSaveConfigurationConfirmButton =
  (
    storeManagementAttackTypeConfiguration: StoreManagementAttackTypeConfiguration
  ) =>
  () => {
    storeManagementAttackTypeConfiguration
      .updateAttackTypeConfiguration(
        storeManagementAttackTypeConfiguration.inputAttackTypeConfiguration
      )
      .then(() =>
        storeManagementAttackTypeConfiguration.reloadAttackTypeConfiguration()
      )
      .then(() =>
        storeManagementAttackTypeConfiguration.storeModalSaveConfiguration.hide()
      )
  }

/**
 * Select or unselect all the configuration entries.
 */
export const handleSelectAllEntriesOnChange =
  (
    storeManagementAttackTypeConfiguration: StoreManagementAttackTypeConfiguration
  ) =>
  () => {
    storeManagementAttackTypeConfiguration.toggleAllAttackTypesForAllDirectoriesSelection()
  }
