import type { EntityBlade } from '@app/entities'

export interface IBladeLayoutBase {
  title?: React.ReactNode
  header?: React.ReactNode
  menu?: React.ReactNode
  content: React.ReactNode
  footer?: React.ReactNode
}

export interface IBladeLayoutDefault extends IBladeLayoutBase {
  name: 'default'
}

export interface IBladeLayoutTwoColumns extends IBladeLayoutBase {
  name: 'twoColumns'
  leftColumn: React.ReactNode
  /** false by default (will hide the leftColumn) */
  responsive?: boolean
}

export type BladeLayout = IBladeLayoutDefault | IBladeLayoutTwoColumns

export enum BladeContentVariant {
  normal = 'normal',
  fullScreen = 'fullScreen'
}

export interface IBladeContext {
  entityBlade: EntityBlade
  bladeContentProps?: {
    variant?: BladeContentVariant
    handleScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void
    layoutContentGreyBackground?: boolean
  }
}
