import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperCheckbox } from '@app/components-legacy/Form'
import { InputSearch } from '@app/components-legacy/Input'
import {
  handleInputCheckersOnSearch,
  handleInputCheckersSelectAllOnClick
} from '@app/components-legacy/Input/InputCheckers/handlers'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IDrawerHeaderProps<GC extends TGenericChecker> {
  className?: string
  storePendingInputCheckers: StoreInputGenericCheckers<GC>
}

function DrawerHeader<GC extends TGenericChecker>(
  props: IDrawerHeaderProps<GC>
): React.ReactElement {
  const translate = useAppTranslator({
    namespaces: ['Components.InputCheckers']
  })

  return (
    <ContainerFlex
      name="DrawerHeader"
      direction="column"
      items={[
        <InputSearch
          placeholder={translate('Search a checker')}
          onChange={handleInputCheckersOnSearch(
            props.storePendingInputCheckers
          )}
          storeInputSearch={props.storePendingInputCheckers.storeInputSearch}
        />,

        <ContainerFlex
          name="CheckerFilters"
          className={props.className}
          items={[
            <FormWrapperCheckbox
              labelledBy="selectCheckers"
              checkboxProps={{
                name: 'LabelCheckersChecker',
                onChange: handleInputCheckersSelectAllOnClick(
                  props.storePendingInputCheckers
                ),
                indeterminate:
                  props.storePendingInputCheckers
                    .hasSomeSearchedCheckersSelected,
                checked:
                  props.storePendingInputCheckers.isAllSearchedCheckersSelected
              }}
            >
              {props.storePendingInputCheckers.storeInputSearch.hasSearchValue
                ? translate('Select found checkers')
                : translate('Select all checkers')}
            </FormWrapperCheckbox>
          ]}
          spaced
        />
      ]}
      spaced
    />
  )
}

export default observer(DrawerHeader)
