import type {
  CheckersApi,
  HealthApi,
  SettingsApi,
  TenantsApi
} from '@libs/openapi/service-identity-core'
import type { IdentitiesApiExtended } from '@libs/openapi-clients/extendedClients/IdentitiesApiExtended'

export enum OpenApiClientEndPoint {
  serviceIdentityCore = '/w/tenable-services-proxy/service-identity-core'
}

export interface IServiceIdentityCore {
  checkers: CheckersApi
  health: HealthApi
  identities: IdentitiesApiExtended
  settings: SettingsApi
  tenants: TenantsApi
}

export interface IOpenApiClients {
  // service-identity-core API
  serviceIdentityCore: IServiceIdentityCore

  createClients(): IOpenApiClients
}

export type OpenApiClientStubOnCallFn<TRequest> = (parameters: {
  request: TRequest
}) => void

export interface IOpenApiClientStubMockResponses<TFailed, TSuccess, TRequest> {
  responses: [TFailed, TSuccess]
  onCall?: OpenApiClientStubOnCallFn<TRequest>
}
