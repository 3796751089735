/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Score
 */
export interface Score {
  /**
   *
   * @type {number}
   * @memberof Score
   */
  value: number
  /**
   *
   * @type {string}
   * @memberof Score
   */
  severity: ScoreSeverityEnum
}

/**
 * @export
 * @enum {string}
 */
export enum ScoreSeverityEnum {
  Unavailable = 'UNAVAILABLE',
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
  Critical = 'CRITICAL'
}

/**
 * Check if a given object implements the Score interface.
 */
export function instanceOfScore(value: object): boolean {
  if (!('value' in value)) return false
  if (!('severity' in value)) return false
  return true
}

export function ScoreFromJSON(json: any): Score {
  return ScoreFromJSONTyped(json, false)
}

export function ScoreFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Score {
  if (json == null) {
    return json
  }
  return {
    value: json['value'],
    severity: json['severity']
  }
}

export function ScoreToJSON(value?: Score | null): any {
  if (value == null) {
    return value
  }
  return {
    value: value['value'],
    severity: value['severity']
  }
}
