import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface ITrustsSwitchLabelProps {}

const TrustsSwitchLabel: React.FC<ITrustsSwitchLabelProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['Topology.Chart']
  })

  return (
    <span data-name="TrustsSwitchLabel">
      {translate('Show internal relationships')}
    </span>
  )
}

export default observer(TrustsSwitchLabel)
