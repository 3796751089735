import * as React from 'react'
import type { IIconProps } from '../types'

export default function IconAzureAd(props: IIconProps) {
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 6.78003V9.78003L8 12.9684L14.595 8.72836L12 7.28003L7.5 6.78003Z"
        fill="#53B1E0"
      />
      <path
        d="M16 10.3733L8 15.5617L7.5 14.78L8 13.905L15.1117 9.33333L16 10.3733ZM8 1L7.5 4.28L8 7.18667L14.595 8.72833L8 1Z"
        fill="url(#paint0_linear_3791_141950)"
      />
      <path
        d="M8.00003 7.18665L4.00003 7.27998L1.40503 8.72831L8.00003 12.9683V7.18665Z"
        fill="#9CEBFF"
      />
      <path
        d="M0.888333 9.33333L8 13.905V15.5617L0 10.3733L0.888333 9.33333ZM1.405 8.72833L8 7.18667V1L1.405 8.72833Z"
        fill="#50E6FF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3791_141950"
          x1="5.45"
          y1="2.81333"
          x2="11.9167"
          y2="12.8467"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54AEF0" />
          <stop offset="1" stopColor="#3499E4" />
        </linearGradient>
      </defs>
    </svg>
  )
}
