import { ContainerSimple } from '@app/components-legacy/Container'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { consts } from '@app/styles'
import { isRbacPermissionInvalid } from '@libs/rbac/functions'
import type { RbacEntityId } from '@libs/rbac/types'
import { RbacEntityItemType, RbacPermissionType } from '@libs/rbac/types'
import type { Maybe, RbacEntityName } from '@server/graphql/typeDefs/types'
import { RbacAction } from '@server/graphql/typeDefs/types'
import { Tag } from 'antd'
import type { TagProps } from 'antd/lib/tag'
import * as React from 'react'
import styled from 'styled-components'

interface ITagTogglePermissionProps {
  className?: string
  type: RbacPermissionType
  rbacEntityType: RbacEntityItemType
  rbacEntityName: RbacEntityName
  rbacAction: RbacAction
  rbacEntityId?: Maybe<RbacEntityId>
  forAllEntities?: boolean
  builtIn?: boolean
  disabled: boolean
  onClick?: Maybe<() => void>
}

const NonApplicable = styled(ContainerSimple)`
  font-family: 10px;
  color: ${consts.colorBlueGray015};
`

const TagTogglePermission: React.FC<ITagTogglePermissionProps> = props => {
  const { testAttributeProps } = useTestAttribute('button')

  const isInvalid = React.useRef(isRbacPermissionInvalid())

  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Roles.Permissions']
  })

  /**
   * Return the color of the Tag.
   */
  const getColor = () => {
    if (props.disabled) {
      return consts.colorGrey001
    }

    return props.type === RbacPermissionType.grant
      ? consts.colorBlue010
      : undefined
  }

  /**
   * Return the translation of the tag.
   */
  const getTranslation = () => {
    const permTranslation = props.builtIn
      ? translate('Permission required')
      : translate(
          props.type === RbacPermissionType.grant ? 'Granted' : 'Unauthorized'
        )

    if (
      props.forAllEntities &&
      props.rbacEntityType === RbacEntityItemType.data
    ) {
      return `${permTranslation} (${translate('By def')})`
    }

    return permTranslation
  }

  const tagProps: TagProps = {
    className: props.className,
    color: getColor()
  }

  if (!props.disabled && props.onClick) {
    tagProps.onClick = props.onClick
  }

  // don't display Tag for entites data on create permission to avoid confusion since it's
  // granted for all or none, and not by ID.
  if (
    props.rbacEntityType === RbacEntityItemType.data &&
    props.rbacAction === RbacAction.Create
  ) {
    return null
  }

  // if the permission is invalid, do not display the tag
  if (
    isInvalid.current(props.rbacEntityType, props.rbacAction) ||
    isInvalid.current(props.rbacEntityName, props.rbacAction)
  ) {
    return <NonApplicable labelledBy="nonApplicable">N/A</NonApplicable>
  }

  return (
    <Tag
      {...tagProps}
      {...testAttributeProps('status')(
        [props.rbacAction, props.rbacEntityName, props.rbacEntityId].join('-')
      )}
    >
      {getTranslation()}
    </Tag>
  )
}

export default styled(TagTogglePermission)`
  font-size: 10px;
  width: 80px;
  padding: 0 2px;
  text-align: center;
  cursor: ${props =>
    props.disabled ? 'no-drop' : props.onClick ? 'pointer' : 'default'};
  color: ${props => (props.disabled ? consts.colorBlack010 : '')};
  border-color: ${props => (props.disabled ? consts.colorGrey010 : '')};
`
