import type {
  CheckerRemediationCostLevel,
  CheckerType,
  Criticity
} from '@server/graphql/typeDefs/types'
import type { EntityAttackType, EntityChecker } from '..'
import type EntityCheckerIdentity from './EntityCheckerIdentity'

export type CheckerTypeUnion =
  | CheckerType.Exposure
  | CheckerType.Attack
  // Checkers coming from service-identity-core
  | 'identity'

/**
 * Describe a generic checker.
 */
export interface IGenericChecker {
  readonly type: CheckerTypeUnion
  readonly genericId: string
  readonly genericName: string
  readonly genericCodename: GenericCheckerCodename
  readonly genericCriticity: Criticity
  readonly genericRemediationCostLevel: CheckerRemediationCostLevel
}

export type EntityGenericChecker =
  | EntityChecker
  | EntityAttackType
  | EntityCheckerIdentity

/**
 * Hardcode the checkerID for the deviant objects interface on Topology,
 * that lists only deviances of the C_DANGEROUS_TRUST_RELATIONSHIP checker.
 */
export enum CheckerCodeNameId {
  'C_DANGEROUS_TRUST_RELATIONSHIP' = 4
}

/**
 * List of checkers that need some specific code in Kapteyn.
 */
export enum CheckerCodeNameEnum {
  'C_DANGEROUS_TRUST_RELATIONSHIP' = 'C-DANGEROUS-TRUST-RELATIONSHIP'
}

export type GenericCheckerCodename = CheckerCodeNameEnum | string

export type AttackTypeName = string
