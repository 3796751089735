import type { Maybe } from '@@types/helpers'
import { createEntities, EntitySearchBookmarkEntry } from '@app/entities'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import StoreBase from '@app/stores/StoreBase'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { Expression } from '@libs/Expression'
import { isDefinedAndNotEmptyString } from '@libs/isDefined'
import type {
  MutationCreateSearchBookmarkEntry,
  MutationDeleteSearchBookmarkCategory,
  MutationDeleteSearchBookmarkEntry,
  MutationEditSearchBookmarkCategory,
  MutationEditSearchBookmarkEntry
} from '@server/graphql/mutations/searchBookmarks'
import {
  mutationCreateSearchBookmarkEntry,
  mutationDeleteSearchBookmarkCategory,
  mutationDeleteSearchBookmarkEntry,
  mutationEditSearchBookmarkCategory,
  mutationEditSearchBookmarkEntry
} from '@server/graphql/mutations/searchBookmarks'
import type { QuerySearchBookmarksEntries } from '@server/graphql/queries/search-bookmarks'
import { querySearchBookmarksEntries } from '@server/graphql/queries/search-bookmarks'
import type {
  CreateSearchBookmarkEntryMutationArgs,
  DeleteSearchBookmarkCategoryMutationArgs,
  DeleteSearchBookmarkEntryMutationArgs,
  EditSearchBookmarkCategoryMutationArgs,
  EditSearchBookmarkEntryMutationArgs,
  InputCreateSearchBookmarkEntry,
  InputEditSearchBookmarkCategory,
  InputEditSearchBookmarkEntry,
  SearchBookmarkEntry
} from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import type { StoreRoot } from '..'
import StoreForm from '../helpers/StoreForm'
import { InputType } from '../helpers/StoreForm/types'
import type { IStoreOptions } from '../types'

export enum BookmarkFormFieldName {
  existingCategory = 'existingCategory',
  newCategory = 'newCategory',
  name = 'name'
}

export enum BookmarkCategoryEditFormFieldName {
  existingCategory = 'existingCategory',
  newCategory = 'newCategory'
}

export enum BookmarkEditFormFieldName {
  existingName = 'existingName',
  newName = 'newName'
}

export enum BookmarkCategoryEnum {
  All = 'All',
  Uncategorized = 'Uncategorized'
}

export type BookmarkCategory = BookmarkCategoryEnum | string

export default class StoreSearchBookmarks extends StoreBase {
  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['TrailFlow.SearchBookmarks']
  })

  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  /* Drawers */
  public storeSearchBookmarksDrawer = new StoreDrawer(this.storeRoot)
  public storeDrawerEditBookmarkCategory = new StoreDrawer(this.storeRoot)
  public storeDrawerEditBookmark = new StoreDrawer<EntitySearchBookmarkEntry>(
    this.storeRoot
  )

  /* Flags */
  public storeFlagsSearchBookmarks = new StoreFlags(this.storeRoot)
  public storeFlagsCreateSearchBookmarksEntry = new StoreFlags(this.storeRoot)
  public storeFlagsEditSearchBookmarksEntry = new StoreFlags(this.storeRoot)
  public storeFlagsDeleteSearchBookmarksEntry = new StoreFlags(this.storeRoot)
  public storeFlagsEditBookmarkCategory = new StoreFlags(this.storeRoot)
  public storeFlagsEditBookmark = new StoreFlags(this.storeRoot)

  /* Forms */
  public storeFormCreateBookmark: StoreForm<BookmarkFormFieldName> =
    new StoreForm<BookmarkFormFieldName>(this.storeRoot, {
      setup: {
        fields: {
          existingCategory: {
            label: 'Choose a folder',
            validators: [],
            inputType: InputType.select
          },
          newCategory: {
            label: 'Folder',
            validators: [],
            inputType: InputType.input
          },
          name: {
            label: 'Name',
            validators: [],
            inputType: InputType.input
          }
        }
      }
    })

  public storeFormEditBookmarkCategory: StoreForm<BookmarkCategoryEditFormFieldName> =
    new StoreForm<BookmarkCategoryEditFormFieldName>(this.storeRoot, {
      setup: {
        fields: {
          existingCategory: {
            label: 'Existing folder',
            validators: [],
            inputType: InputType.input
          },
          newCategory: {
            label: 'Folder',
            validators: [],
            inputType: InputType.input
          }
        }
      }
    })

  public storeFormEditBookmark: StoreForm<BookmarkEditFormFieldName> =
    new StoreForm<BookmarkEditFormFieldName>(this.storeRoot, {
      setup: {
        fields: {
          existingName: {
            label: 'Existing name',
            validators: [],
            inputType: InputType.input
          },
          newName: {
            label: 'Name',
            validators: [],
            inputType: InputType.input
          }
        }
      }
    })

  /* Observable */

  private $searchBookmarks = observable.map<number, EntitySearchBookmarkEntry>()
  private $isCreatingNewFolderForBookmarks = observable.box<boolean>(false)
  private $isPopoverVisible = observable.box<boolean>(false)
  private $categorySelectorValue = observable.box<BookmarkCategory>(
    BookmarkCategoryEnum.All
  )

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch search bookmarks entries and save entities.
   */
  fetchSearchBookmarksEntries(): Promise<any> {
    this.storeFlagsSearchBookmarks.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QuerySearchBookmarksEntries>(querySearchBookmarksEntries)
      })
      .then(data => data.searchBookmarks)
      .then(searchBookmarksEntries => {
        if (!searchBookmarksEntries) {
          throw new Error('SearchBookmarks is not defined.')
        }

        this.setSearchBookmarksEntries(searchBookmarksEntries)

        this.storeFlagsSearchBookmarks.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsSearchBookmarks))
  }

  /**
   * Create a bookmark entry
   */
  createSearchBookmarksEntry(bookmarkEntry: InputCreateSearchBookmarkEntry) {
    this.storeFlagsCreateSearchBookmarksEntry.loading()

    return Promise.resolve()
      .then(() => {
        const args: CreateSearchBookmarkEntryMutationArgs = {
          searchBookmarkEntry: {
            expression: bookmarkEntry.expression,
            name: bookmarkEntry.name,
            category: bookmarkEntry.category
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationCreateSearchBookmarkEntry>(
            mutationCreateSearchBookmarkEntry,
            args
          )
      })
      .then(() => this.fetchSearchBookmarksEntries())
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Bookmark added'),
          {
            labelledBy: 'bookmarkAdded'
          }
        )

        this.storeFlagsCreateSearchBookmarksEntry.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsCreateSearchBookmarksEntry
        )
      )
  }

  /**
   * Delete a bookmark entry
   */
  deleteSearchBookmarksEntry(bookmarkEntryId: number) {
    this.storeFlagsDeleteSearchBookmarksEntry.loading()

    return Promise.resolve()
      .then(() => {
        const args: DeleteSearchBookmarkEntryMutationArgs = {
          searchBookmarkEntry: {
            id: bookmarkEntryId
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationDeleteSearchBookmarkEntry>(
            mutationDeleteSearchBookmarkEntry,
            args
          )
      })
      .then(() => this.fetchSearchBookmarksEntries())
      .then(() => {
        this.storeFlagsDeleteSearchBookmarksEntry.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsDeleteSearchBookmarksEntry
        )
      )
  }

  /**
   * Edit a bookmark category
   */
  editBookmarkCategory(editBookmarkCategory: InputEditSearchBookmarkCategory) {
    this.storeFlagsEditBookmarkCategory.loading()

    return Promise.resolve()
      .then(() => {
        const args: EditSearchBookmarkCategoryMutationArgs = {
          searchBookmarkCategory: {
            existingCategory: editBookmarkCategory.existingCategory,
            newCategory: editBookmarkCategory.newCategory
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationEditSearchBookmarkCategory>(
            mutationEditSearchBookmarkCategory,
            args
          )
      })
      .then(() => this.fetchSearchBookmarksEntries())
      .then(() => {
        this.storeFlagsEditBookmarkCategory.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsEditBookmarkCategory)
      )
  }

  /**
   * Delete a bookmark category
   */
  deleteSearchBookmarksCategory(bookmarkCategory: string) {
    this.storeFlagsDeleteSearchBookmarksEntry.loading()

    return Promise.resolve()
      .then(() => {
        const args: DeleteSearchBookmarkCategoryMutationArgs = {
          searchBookmarkCategory: {
            category: bookmarkCategory
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationDeleteSearchBookmarkCategory>(
            mutationDeleteSearchBookmarkCategory,
            args
          )
      })
      .then(() => this.fetchSearchBookmarksEntries())
      .then(() => {
        this.storeFlagsDeleteSearchBookmarksEntry.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsDeleteSearchBookmarksEntry
        )
      )
  }

  /**
   * Edit a bookmark entry
   */
  editSearchBookmarksEntry(bookmarkEntry: InputEditSearchBookmarkEntry) {
    this.storeFlagsEditSearchBookmarksEntry.loading()

    return Promise.resolve()
      .then(() => {
        const args: EditSearchBookmarkEntryMutationArgs = {
          searchBookmarkEntry: {
            id: bookmarkEntry.id,
            name: bookmarkEntry.name,
            category: bookmarkEntry.category
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationEditSearchBookmarkEntry>(
            mutationEditSearchBookmarkEntry,
            args
          )
      })
      .then(() => this.fetchSearchBookmarksEntries())
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Bookmark edited'),
          {
            labelledBy: 'bookmarkEdited'
          }
        )
        this.storeFlagsEditSearchBookmarksEntry.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsEditSearchBookmarksEntry
        )
      )
  }

  /* Actions */

  /**
   * Save search bookmarks
   */
  @action
  setSearchBookmarksEntries(
    searchBookmarksEntries: SearchBookmarkEntry[]
  ): this {
    this.$searchBookmarks.clear()

    const searchBookmarksEntriesEntities = createEntities<
      SearchBookmarkEntry,
      EntitySearchBookmarkEntry
    >(EntitySearchBookmarkEntry, searchBookmarksEntries)

    searchBookmarksEntriesEntities.forEach(searchBookmarkEntry => {
      if (searchBookmarkEntry.id && searchBookmarkEntry.expression) {
        searchBookmarkEntry.expressionObject = new Expression().fromString(
          searchBookmarkEntry.expression
        )

        this.$searchBookmarks.set(searchBookmarkEntry.id, searchBookmarkEntry)
      }
    })

    return this
  }

  @action
  setCreatingNewFolderForBookmarks(
    isCreatingNewFolderForBookmarks: boolean
  ): this {
    this.$isCreatingNewFolderForBookmarks.set(isCreatingNewFolderForBookmarks)
    return this
  }

  @action
  setPopoverVisible(isVisible: boolean): this {
    this.$isPopoverVisible.set(isVisible)
    return this
  }

  @action
  setCategorySelectorValue(value: string): this {
    this.$categorySelectorValue.set(value)
    return this
  }

  /**
   * Reset
   */
  @action
  reset(): this {
    this.storeInputSearch.reset()
    this.setCategorySelectorValue(BookmarkCategoryEnum.All)

    return this
  }

  /* Computed values */

  @computed
  get searchBookmarksEntries(): Map<number, EntitySearchBookmarkEntry> {
    return toJS(this.$searchBookmarks)
  }

  @computed
  get searchBookmarksEntriesFiltered(): EntitySearchBookmarkEntry[] {
    return Array.from(this.searchBookmarksEntries.values()).filter(
      entitySearchBookmark => {
        return this.storeRoot.stores.storeTrailFlow.storeInputExpression.inputValueAsRegExp.test(
          entitySearchBookmark.getPropertyAsString('expression').trim()
        )
      }
    )
  }

  @computed
  get searchBookmarksEntriesFromSearch(): EntitySearchBookmarkEntry[] {
    return Array.from(this.searchBookmarksEntries.values()).filter(
      entitySearchBookmark => {
        const matchedExpression =
          this.storeInputSearch.transformedSearchValueAsRegexp.test(
            entitySearchBookmark.getPropertyAsString('expression')
          ) ||
          this.storeInputSearch.transformedSearchValueAsRegexp.test(
            entitySearchBookmark.getPropertyAsString('name')
          )

        if (this.categorySelectorValue === BookmarkCategoryEnum.All) {
          return matchedExpression
        }

        if (this.categorySelectorValue === BookmarkCategoryEnum.Uncategorized) {
          return matchedExpression && !entitySearchBookmark.category
        }

        return (
          matchedExpression &&
          entitySearchBookmark.category === this.categorySelectorValue
        )
      }
    )
  }

  @computed
  get searchBookmarksCategories(): BookmarkCategory[] {
    const allCategories = Array.from(this.searchBookmarksEntries.values())
      .map(entry => entry.category as BookmarkCategory)
      .filter(isDefinedAndNotEmptyString)

    return Array.from(new Set(allCategories).values())
  }

  @computed
  get isCreatingNewFolderForBookmarks(): boolean {
    return this.$isCreatingNewFolderForBookmarks.get()
  }

  @computed
  get isPopoverVisible(): boolean {
    return this.$isPopoverVisible.get()
  }

  @computed
  get categorySelectorValue(): string {
    return this.$categorySelectorValue.get()
  }

  @computed
  get searchBookmarksSortByCategories(): Map<
    string,
    EntitySearchBookmarkEntry[]
  > {
    const isBookmarksWithoutCat =
      this.searchBookmarksEntriesFromSearch.filter(
        bookmark => !bookmark.category
      ).length > 0

    const localeSortBy = (
      bookmarksEntries: EntitySearchBookmarkEntry[],
      predicate: (entry: EntitySearchBookmarkEntry) => Maybe<string>
    ) => {
      return bookmarksEntries.sort((a, b) => {
        const aString = String(predicate(a))
        const bString = String(predicate(b))

        // Empty strings will be on top
        return new Intl.Collator().compare(aString, bString)
      })
    }

    const alphabetizeCategories = (
      bookmarksEntries: EntitySearchBookmarkEntry[]
    ): EntitySearchBookmarkEntry[] => {
      return localeSortBy(bookmarksEntries, entry => entry.category)
    }

    const alphabetizeBookmarkEntries = (
      bookmarksEntries: EntitySearchBookmarkEntry[]
    ): EntitySearchBookmarkEntry[] => {
      const entriesWithoutLabel = bookmarksEntries.filter(entry => !entry.name)

      const entriesWithLabel = localeSortBy(
        bookmarksEntries.filter(entry => entry.name),
        entry => entry.name
      )

      return [...entriesWithLabel, ...entriesWithoutLabel]
    }

    return alphabetizeCategories(this.searchBookmarksEntriesFromSearch).reduce(
      (acc, entitySearchBookmark) => {
        const uncategorized = BookmarkCategoryEnum.Uncategorized

        if (isBookmarksWithoutCat && !acc.size) {
          // If we have bookmarks without category, we want uncategorized bookmarks to be displayed at first position.
          acc.set(uncategorized, [])
        }

        if (!entitySearchBookmark.category) {
          const currentBookmarksUncategorized = acc.get(uncategorized)

          if (currentBookmarksUncategorized) {
            acc.set(
              uncategorized,
              alphabetizeBookmarkEntries(
                currentBookmarksUncategorized.concat([entitySearchBookmark])
              )
            )

            return acc
          }

          acc.set(uncategorized, [entitySearchBookmark])

          return acc
        }

        if (acc.has(entitySearchBookmark.category)) {
          const currentBookmarksEntries = acc.get(entitySearchBookmark.category)

          if (currentBookmarksEntries) {
            acc.set(
              entitySearchBookmark.category,
              alphabetizeBookmarkEntries(
                currentBookmarksEntries.concat([entitySearchBookmark])
              )
            )
          }

          return acc
        }

        acc.set(entitySearchBookmark.category, [entitySearchBookmark])

        return acc
      },
      new Map<string, EntitySearchBookmarkEntry[]>()
    )
  }
}
