import type { Maybe } from '@@types/helpers'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  createInfrastructure,
  deleteInfrastructure,
  editInfrastructure,
  readInfrastructures,
  readUISystemInfrastructures
} from '@libs/rbac/permissions'
import { canAccessToSystem } from '../permissions'

export const canAccessToInfrastructures = rbacCapabilityCheckAllOf(
  canAccessToSystem,
  readUISystemInfrastructures(),
  readInfrastructures()
)

export const canCreateInfrastructures = rbacCapabilityCheckAllOf(
  canAccessToInfrastructures,
  createInfrastructure()
)

export const canEditInfrastructures = (infrastructureId: number) =>
  rbacCapabilityCheckAllOf(
    canAccessToInfrastructures,
    editInfrastructure(infrastructureId)
  )

export const canDeleteInfrastructures = (infrastructureId: Maybe<number>) =>
  rbacCapabilityCheckAllOf(
    canAccessToInfrastructures,
    deleteInfrastructure(infrastructureId)
  )
