import { Features } from '@alsid/common'
import {
  ButtonExportAll,
  ButtonShowPicker
} from '@app/components-legacy/Button'
import {
  ContainerFlex,
  ContainerFooter,
  ContainerRbac
} from '@app/components-legacy/Container'
import { handleDrawerOnOpen } from '@app/components-legacy/Drawer/handlers'
import {
  WidgetListBulkActions,
  WidgetListCountItems,
  WidgetListSelectAllCheckbox
} from '@app/components-legacy/Widgets/WidgetList'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import {
  canAccessToAttackClose,
  canAccessToAttackRemove
} from '@app/pages/IoA/IoAAttacksPage/permissions'
import { filterFalsies } from '@libs/filterFalsies'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { AttacksPageContext } from '../context'
import IoAAttacksModalConfirmClose from '../IoAAttacksModalConfirmClose'
import {
  onAttacksBulkActionSelection as onAttacksBulkActionChange,
  onAttacksShowRemoveFiltersClick
} from './handlers'
import { AttackBulkActionValue } from './types'

const ContainerFlexStyled = styled(ContainerFlex)`
  @media only screen and (min-width: 1590px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

interface IIoAAttacksFooterActionsProps {
  className?: string
}

const IoAAttacksFooterActions: React.FC<IIoAAttacksFooterActionsProps> = () => {
  const attacksPageContext = React.useContext(AttacksPageContext)

  const { storeRbac } = useStores()

  const translate = useAppTranslator({
    namespaces: ['IoA.Attacks']
  })

  const featureSelectAllAttacks = useFeatureFlag({
    featureFlagName: Features.CLOSE_REOPEN_ALL_ATTACKS
  })

  if (!attacksPageContext) {
    return null
  }

  const { storeAttacks } = attacksPageContext
  const { storeWidgetListAttacks } = storeAttacks

  return (
    <>
      <ContainerFooter>
        <ContainerFlexStyled
          name={IoAAttacksFooterActions.name}
          direction="column"
          items={[
            <ContainerFlex
              name="IoAAttacksFooterActionsFirst"
              alignItems="center"
              items={[
                <ContainerFlex
                  name="IoAAttacksFooterActionsSelector"
                  alignItems="center"
                  flexWrap="wrap"
                  items={[
                    <WidgetListSelectAllCheckbox
                      disableSelectAllPages={!featureSelectAllAttacks}
                      hasInfiniteScroll
                      storeWidgetList={storeWidgetListAttacks}
                    />,

                    <WidgetListCountItems
                      label={translate('Number of selected incidents', {
                        interpolations: {
                          count: storeWidgetListAttacks.countSelectedRows,
                          totalCount:
                            storeWidgetListAttacks.paginationTotalCount
                        }
                      })}
                      storeWidgetList={storeWidgetListAttacks}
                    />,

                    <WidgetListBulkActions
                      options={filterFalsies([
                        storeRbac.isUserGrantedTo(canAccessToAttackClose) && {
                          label: translate('Close selected incidents'),
                          value: AttackBulkActionValue.close
                        },
                        storeRbac.isUserGrantedTo(canAccessToAttackClose) && {
                          label: translate('Reopen selected incidents'),
                          value: AttackBulkActionValue.reopen
                        },
                        storeRbac.isUserGrantedTo(canAccessToAttackRemove) && {
                          label: translate('Remove selected incidents'),
                          value: AttackBulkActionValue.remove
                        }
                      ])}
                      onChange={onAttacksBulkActionChange(storeAttacks)}
                      flags={storeAttacks.storeFlagsCloseAttacks.flags}
                      width="large"
                      storeWidgetList={storeWidgetListAttacks}
                    />
                  ]}
                  spaced
                />
              ]}
              spaced
              spaceWidth="small"
            />,

            <ContainerFlex
              name="IoAAttacksFooterActionsButtons"
              justifyContent="flex-end"
              items={[
                <ContainerRbac rbacCapabilityCheck={canAccessToAttackRemove}>
                  <ButtonShowPicker
                    labelledBy="showPickerIoaIncidentsDeletionFilters"
                    buttonsProps={{
                      onClick: onAttacksShowRemoveFiltersClick(storeAttacks)
                    }}
                    storeDrawer={storeAttacks.storeDrawerAttacksFiltersAdd}
                  >
                    <div>{translate('Modify incidents deletion filters')}</div>
                  </ButtonShowPicker>
                </ContainerRbac>,

                <ButtonExportAll
                  onClick={handleDrawerOnOpen(
                    storeAttacks.storeDrawerExportAttacks
                  )}
                />
              ]}
              spaced
              spaceWidth="small"
            />
          ]}
          spaced
          spaceWidth="small"
          fullWidth
        />
      </ContainerFooter>

      <IoAAttacksModalConfirmClose />
    </>
  )
}

export default observer(IoAAttacksFooterActions)
