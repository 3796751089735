import { ContainerHTMLContent } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

export interface IEulaContentLicenseProps {}

const ContainerHTMLContentStyled = styled(ContainerHTMLContent)`
  h1 {
    text-align: center;
  }

  max-height: 500px;
  overflow-y: scroll;
  text-align: justify;
  padding: ${consts.paddingLarge};

  ol {
    padding-inline-start: 12px;
  }
`

const EulaContentLicense: React.FC<IEulaContentLicenseProps> = props => {
  const { storeEula } = useStores()

  return (
    <ContainerHTMLContentStyled padded>
      {storeEula.eula?.getLicenseContent() || 'License not available'}
    </ContainerHTMLContentStyled>
  )
}

export default observer(EulaContentLicense)
