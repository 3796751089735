import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppTranslator, useStores } from '@app/hooks'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canReadAlerts } from '../../permissions'
import AlertsMenu from '../AlertsMenu'
import AlertsContentIoA from './AlertsContentIoA'
import AlertsContentIoE from './AlertsContentIoE'
import { onAlertsDrawerLoad, onAlertsDrawerUnload } from './handlers'

export interface IAlertsProps {
  className?: string
}

const Alerts: React.FC<IAlertsProps> = () => {
  const translate = useAppTranslator({ namespaces: ['Drawers.Alerts'] })

  const { storeCheckers, storeAlerts } = useStores()

  const getContent = () => {
    if (storeAlerts.storeMenu.selectedMenuKey === CheckerType.Exposure) {
      return (
        <AlertsContentIoE storeGenericAlerts={storeAlerts.storeAlertsIoE} />
      )
    }

    if (storeAlerts.storeMenu.selectedMenuKey === CheckerType.Attack) {
      return (
        <AlertsContentIoA storeGenericAlerts={storeAlerts.storeAlertsIoA} />
      )
    }

    return null
  }

  return (
    <DrawerHorizontalContainer
      labelledBy="alerts"
      onLoad={onAlertsDrawerLoad(storeAlerts)}
      onUnload={onAlertsDrawerUnload(storeAlerts)}
      flags={[
        storeCheckers.storeInputCheckersExposure.storeFlagsFetchCheckers.flags,
        storeCheckers.storeInputCheckersAttacks.storeFlagsFetchCheckers.flags
      ]}
    >
      <BladeContentLayoutDefault
        layout={{
          name: 'default',
          title: <DrawerTitle>{translate('Alerts')}</DrawerTitle>,
          menu: <AlertsMenu />,
          content: getContent(),
          footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
        }}
        rbacCapabilityCheck={canReadAlerts}
      />
    </DrawerHorizontalContainer>
  )
}

export default observer(Alerts)
