import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import {
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { ButtonSize } from '@app/components-legacy/Form/Wrappers/types'
import { handleDirectoriesSelectionOnSubmit } from '@app/components-legacy/Input/InputInfrastructures/handlers'
import { LabelAlt } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreInfrastructures } from '@app/stores'
import type StoreDrawer from '@app/stores/helpers/StoreDrawer'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DomainsSelectionCounter from './DomainsSelectionCounter'

interface IDrawerFooterProps {
  onSubmit?: () => void
  storeInfrastructures: StoreInfrastructures
  storePendingInfrastructures: StoreInfrastructures
  storeDrawer: StoreDrawer<any>
}

const DrawerFooter: React.FC<IDrawerFooterProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Components.InputInfrastructures']
  })

  const enableSubmit =
    props.storePendingInfrastructures.isDrawerSelectionSubmitEnabled

  return (
    <ContainerFooter>
      <DrawerCancelButton storeDrawer={props.storeDrawer} />

      <ContainerFlex
        name="DrawerFooterRight"
        alignItems="center"
        items={[
          themeIsLegacy ? (
            <LabelAlt variant={LabelAltVariant.Minor}>
              <DomainsSelectionCounter
                storeInfrastructures={props.storePendingInfrastructures}
              />
            </LabelAlt>
          ) : (
            <Label
              variant={LabelVariant.p}
              color={theme.tokens['drawer/color/selectionCounter']}
            >
              <DomainsSelectionCounter
                storeInfrastructures={props.storePendingInfrastructures}
              />
            </Label>
          ),

          <FormWrapperButton
            labelledBy="submit"
            pendoSuffix="drawer"
            size={ButtonSize.large}
            buttonProps={{
              type: 'primary',
              disabled: !enableSubmit,
              onClick: handleDirectoriesSelectionOnSubmit(
                props.storePendingInfrastructures,
                props.storeInfrastructures
              )(props.onSubmit)
            }}
          >
            {translate('Submit selection')}
          </FormWrapperButton>
        ]}
        spaced
        spaceWidth="small"
      />
    </ContainerFooter>
  )
}

export default observer(DrawerFooter)
