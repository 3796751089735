import type { IGraphQLQuery } from '@libs/graphQL/types'
import type { Query, RbacReportsQueryArgs } from '../typeDefs/types'

/**
 * Retrieve the Reports.
 */

export type QueryReports = IGraphQLQuery<
  RbacReportsQueryArgs,
  Pick<Query, 'rbacReports'>
>

export const queryRbacReports = `
 query queryRbacReports {
    rbacReports {
      node {
        node {
          id
          type
          name
          directoryIds
          attackTypeIds
          profileId
          checkerIds
          recipientEmails
          format
          dataTimeframe
          recurrence
          timeZone
        }
        pagination {
          page
          perPage
          totalCount
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
   }
 }
`
