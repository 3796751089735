import { ContainerFlex } from '@app/components/Container'
import { IconBarChart, IconCount, IconLineChart } from '@app/components/Icon'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { CardSimple } from '@app/components-legacy/Card/CardSimple/CardSimple'
import { useAppTranslator } from '@app/hooks'
import * as React from 'react'
import TemplateWrapper from './TemplateWrapper'
import { DashboardTemplate } from './types'

interface IAdComplianceAndTopRisksProps {
  children?: React.ReactNode
}

const AdComplianceAndTopRisks: React.FC<
  IAdComplianceAndTopRisksProps
> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Dashboard.AddDashboardDrawer',
      'Dashboard.Common',
      'Components.LabelChecker.ComplexityLevel'
    ]
  })

  return (
    <TemplateWrapper
      title={translate('AD Compliance and Top Risks')}
      templateType={DashboardTemplate.adComplianceAndTopRisks}
    >
      <ContainerFlex
        name="AdComplianceAndTopRisks"
        flexGap="verySmall"
        direction="column"
        items={[
          <CardSimple>
            <ContainerFlex
              name="Score"
              flexGap="medium"
              items={[
                <IconCount />,
                <ContainerFlex
                  name="ScoreContent"
                  flexGap="verySmall"
                  direction="column"
                  items={[
                    <Label variant={LabelVariant.p_bold}>
                      {translate('Score')}
                    </Label>,
                    <Label variant={LabelVariant.description}>
                      {translate(
                        'Understand your global compliance score and learn how to improve it'
                      )}
                    </Label>
                  ]}
                />
              ]}
              itemsFlexShrink={[0, 1]}
            />
          </CardSimple>,

          <CardSimple>
            <ContainerFlex
              name="Evolution"
              flexGap="medium"
              items={[
                <IconLineChart />,
                <ContainerFlex
                  name="EvolutionContent"
                  flexGap="verySmall"
                  direction="column"
                  items={[
                    <Label variant={LabelVariant.p_bold}>
                      {translate('Evolution')}
                    </Label>,
                    <Label variant={LabelVariant.description}>
                      {translate(
                        'Monitor the evolution of your global compliance score'
                      )}
                    </Label>
                  ]}
                />
              ]}
              itemsFlexShrink={[0, 1]}
            />
          </CardSimple>,

          <CardSimple>
            <ContainerFlex
              name="RiskCriticalityCompliance"
              flexGap="medium"
              items={[
                <IconBarChart />,
                <ContainerFlex
                  name="RiskCriticalityComplianceContent"
                  flexGap="verySmall"
                  direction="column"
                  items={[
                    <Label variant={LabelVariant.p_bold}>
                      {translate('Risk Criticality Compliance')}
                    </Label>,
                    <Label variant={LabelVariant.description}>
                      {translate(
                        'Specify your priorities for each Indicator of Exposure criticality level'
                      )}
                    </Label>
                  ]}
                />
              ]}
              itemsFlexShrink={[0, 1]}
            />
          </CardSimple>
        ]}
      />
    </TemplateWrapper>
  )
}

export default AdComplianceAndTopRisks
