import type { Maybe } from '@@types/helpers'
import type { AppRouteName } from '@app/routes'
import type { AppRouterClient } from '@libs/Router/types'

/**
 * Allow to compare 2 route names by checking if the second starts with the first.
 * Used for menu highlights and this kind of things.
 */
export function routesMatcher(appRouter: AppRouterClient) {
  return (routeName1: Maybe<AppRouteName>, routeName2: Maybe<AppRouteName>) => {
    if (!routeName1 || !routeName2) {
      return false
    }

    const routeName1Pathname = appRouter.getRoutePathname(routeName1)
    const routeName2Pathname = appRouter.getRoutePathname(routeName2)

    return routeName2Pathname.startsWith(routeName1Pathname)
  }
}
