import type { Query } from '@server/graphql/typeDefs/types'

/**
 * Search events.
 */

export type QueryEvents = Pick<Query, 'events'>

export const queryEvents = `
  query queryEvents (
    $profileId: Int!
    $directoryIds: [Int!]!
    $dateStart: String!
    $dateEnd: String!
    $eventsDirection: String!
    $eventsPerPage: Int!
    $eventsExpression: String!
  ) {
    events(
      profileId: $profileId
      directoryIds: $directoryIds
      dateStart: $dateStart
      dateEnd: $dateEnd
      eventsDirection: $eventsDirection
      eventsPerPage: $eventsPerPage
      eventsExpression: $eventsExpression
    ) {
      id
      type
      date
      adObject {
        id
        type
        objectAttributes {
          name
          value
          valueType
        }
      }
      directory {
        id
        name
      }
      directoryId
      adObjectId
    }
  }
`

/**
 * Fetch only one event.
 */

export type QueryEventById = Pick<Query, 'eventById'>

export const queryEventById = `
  query queryEventById (
    $directoryId: Int!
    $eventId: String!
  ) {
    eventById(
      directoryId: $directoryId
      eventId: $eventId
    ) {
      id
      type
      date
      directory {
        id
        name
      }
      adObject {
        id
        type
        objectAttributes {
          name
          value
          valueType
        }
      }
    }
  }
`

/**
 * Fetch (granted) deviances for a list of events and for a list of profiles.
 *
 * Note that the isDeviant check is done in the resolver to return only a list
 * of granted deviances.
 */

export type QueryEventsDeviances = Pick<Query, 'rbacDeviances'>

export const queryEventsDeviances = `
  query queryRbacEventsDeviances (
    $profileIds: [Int!]!
    $events: [InputDirectoryIdEventId!]!
  ) {
    rbacDeviances(
      profileIds: $profileIds
      events: $events
    ) {
      node {
        id
        createdEventId
        resolvedEventId
        devianceProviderId
        resolvedAt
        profileId
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Fetch incriminating attributes for an event.
 */
export type QueryEventIncriminatingAttributes = Pick<
  Query,
  'rbacEventIncriminatingAttributes'
>

export const queryEventIncriminatingAttributes = `
  query queryEventIncriminatingAttributes (
    $profileId: Int!
    $infrastructureId: Int!
    $directoryId: Int!
    $eventId: String!
    $checkerIds: [Int!]!
    $reasonIds: [Int!]!
    $incriminatingAttributesExpression: String
    $incriminatingAttributesPage: Int
    $incriminatingAttributesPerPage: Int
  ) {
    rbacEventIncriminatingAttributes(
      profileId: $profileId
      infrastructureId: $infrastructureId
      directoryId: $directoryId
      eventId: $eventId
      checkerIds: $checkerIds
      reasonIds: $reasonIds
      incriminatingAttributesExpression: $incriminatingAttributesExpression
      incriminatingAttributesPage: $incriminatingAttributesPage
      incriminatingAttributesPerPage: $incriminatingAttributesPerPage
    ) {
      node {
        node {
          id
          eventDate
          createdEventId
          resolvedEventId
          devianceProviderId
          ignoreUntil
          resolvedAt
          attributes {
            name
            value
          }
          description {
            template
            replacements {
              name
              valueType
            }
          }
          checkerId
          directoryId
          profileId
          adObjectId
          reasonId
        }
        pagination {
          page
          perPage
          totalCount
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Get details of an event.
 */
export type QueryEventDetails = Pick<
  Query,
  'rbacInfrastructures' | 'rbacDirectories'
>

export const queryEventDetails = `
  query queryEventDetails(
    $profileId: Int!
    $infrastructureId: Int!
    $directoryId: Int!
    $eventId: String!
  ) {
    rbacInfrastructures(infrastructureId: $infrastructureId) {
      node {
        node {
          id
          name
        }
        pagination {
          page
          perPage
          totalCount
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
    rbacDirectories(directoryId: $directoryId) {
      node {
        node {
          id
          name
          event(eventId: $eventId) {
            id
            type
            date
            rbacReasons(profileId: $profileId) {
              node {
                id
                codename
              }
              rbacCapability {
                isGranted
                isFound
                message
                rbacEntityName
              }
            }
            rbacDeviances(profileId: $profileId) {
              node {
                id
                resolvedAt
                checkerId
                reason {
                  codename
                  name
                  description
                }
              }
              rbacCapability {
                isGranted
                isFound
                message
                rbacEntityName
              }
            }
            adObject {
              id
              type
              objectAttributes {
                name
                value
                valueType
              }
              current {
                objectAttributes {
                  name
                  value
                  valueType
                }
              }
              changes {
                attributeName
                valueType
                values {
                  before
                  after
                  current
                }
              }
            }
          }
        }
        pagination {
          page
          perPage
          totalCount
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
