import { ContainerFlex } from '@app/components-legacy/Container'
import { TypographySubTitle } from '@app/components-legacy/Typography'
import { useAppTranslator } from '@app/hooks'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { DateFormat, formatDate } from '@libs/dates/formatDate'
import { Language } from '@server/graphql/typeDefs/types'
import { Table } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

interface IIoABoardAuthorPDFProps {}

const StyledTable = styled(Table)`
  table {
    page-break-after: initial;
    page-break-inside: initial;

    tr th,
    tr td {
      padding: ${consts.paddingDefault};
    }
  }
`

const IoABoardAuthorPDF: React.FC<IIoABoardAuthorPDFProps> = () => {
  const { storePreferences, storeAuthentication } = useStores()

  const translate = useAppTranslator({
    namespaces: ['IoA.BoardPDF']
  })

  const dataSource = [
    {
      key: '1',
      author: storeAuthentication.currentProfile?.getPropertyAsString('name'),
      qualification: '',
      contactAddress: '',
      version: '1.0',
      date: formatDate(new Date(), {
        utc: true,
        format:
          storePreferences.language === Language.FrFr
            ? DateFormat.frenchDateOfTheDay
            : DateFormat.englishDateOfTheDay
      }),
      comments: undefined
    }
  ]

  const contributorsColumns = [
    { title: translate('Author'), dataIndex: 'author' },
    { title: translate('Qualification'), dataIndex: 'qualification' },
    { title: translate('Contact address'), dataIndex: 'contactAddress' }
  ]

  const historyColumns = [
    { title: translate('Version'), dataIndex: 'version' },
    { title: translate('Date'), dataIndex: 'date' },
    { title: translate('Author'), dataIndex: 'author' },
    { title: translate('Comments'), dataIndex: 'comments' }
  ]

  return (
    <ContainerFlex
      name="IoABoardAuthorPDFTable"
      direction="column"
      items={[
        <ContainerFlex
          name="IoABoardAuthorPDFTableContributors"
          direction="column"
          items={[
            <TypographySubTitle>
              {translate('Document contributors')}
            </TypographySubTitle>,

            <StyledTable
              columns={contributorsColumns}
              dataSource={dataSource}
              pagination={false}
              bordered
              size="middle"
            />
          ]}
          spaced
          paddingV="small"
        />,

        <ContainerFlex
          name="IoABoardAuthorPDFTableHistory"
          direction="column"
          items={[
            <TypographySubTitle>
              {translate('Document history')}
            </TypographySubTitle>,

            <StyledTable
              columns={historyColumns}
              dataSource={dataSource}
              pagination={false}
              bordered
              size="middle"
            />
          ]}
          spaced
          paddingV="small"
        />
      ]}
      spaced
      paddingV="default"
      paddingH="default"
    />
  )
}

export default observer(IoABoardAuthorPDF)
