import type {
  StoreManagementApplicationSettings,
  StoreManagementAttackTypeConfiguration,
  StoreManagementDirectories
} from '@app/stores'
import type StoreModal from '@app/stores/helpers/StoreModal'
import type StoreApplicationSettings from '@app/stores/Management/StoreApplicationSettings'
import type { InputUpdateApplicationSettings } from '@server/graphql/typeDefs/types'

/**
 * Load everything to be able to configure IoA.
 */
export const handleAttackTypeConfigurationLoad =
  (
    storeManagementAttackTypeConfiguration: StoreManagementAttackTypeConfiguration,
    storeManagementApplicationSettings: StoreManagementApplicationSettings,
    storeManagementDirectories: StoreManagementDirectories
  ) =>
  () => {
    // wait loading of infra/dirs and attackCheckers before loading the attackTypeConfiguration
    // add fetchApplicationSettings call to get ioaAutomaticConfiguration value
    Promise.all([
      storeManagementAttackTypeConfiguration.storeInfrastructures.fetchInfrastructures(),
      storeManagementAttackTypeConfiguration.storeInputCheckersAttacks.fetchAttackCheckers(),
      storeManagementApplicationSettings.fetchApplicationSettings(),
      storeManagementDirectories.fetchDirectories()
    ]).then(() => {
      storeManagementAttackTypeConfiguration.storeInputCheckersAttacks
        .showAllCheckers()
        .selectAllCheckers()

      storeManagementAttackTypeConfiguration.fetchAttackTypeConfiguration()
      storeManagementAttackTypeConfiguration.fetchIoaConfiguration()
    })
  }

export const handleAttackTypeConfigurationUnload =
  (
    storeManagementAttackTypeConfiguration: StoreManagementAttackTypeConfiguration
  ) =>
  () => {
    storeManagementAttackTypeConfiguration.reset()
  }

/**
 * Show the procedure modal.
 */
export const handleSeeProcedureOnClick =
  (storeModal: StoreModal<any>) => () => {
    storeModal.show()
  }

export const handleAutomaticConfigurationOnChange =
  (storeManagementApplicationSettings: StoreApplicationSettings) =>
  (isAutomaticConfigurationEnabled: boolean): void => {
    const currentApplicationSettings =
      storeManagementApplicationSettings.applicationSettings

    if (!currentApplicationSettings) {
      return
    }

    const applicationSettings: InputUpdateApplicationSettings = {
      ioaAutomaticConfiguration: isAutomaticConfigurationEnabled
    }

    storeManagementApplicationSettings.updateApplicationSettings(
      applicationSettings
    )
  }

/**
 * Show the errors modal.
 */
export const handleArrowErrorsModalOnClick =
  (storeModal: StoreModal<any>) => () => {
    storeModal.show()
  }
