/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ScanInfo
 */
export interface ScanInfo {
  /**
   *
   * @type {string}
   * @memberof ScanInfo
   */
  description: string
  /**
   *
   * @type {string}
   * @memberof ScanInfo
   */
  scanStatus: ScanInfoScanStatusEnum
  /**
   *
   * @type {Date}
   * @memberof ScanInfo
   */
  scanRequestedAt?: Date
  /**
   *
   * @type {Date}
   * @memberof ScanInfo
   */
  scanUpdatedAt?: Date
  /**
   *
   * @type {boolean}
   * @memberof ScanInfo
   */
  dryRun?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum ScanInfoScanStatusEnum {
  Queued = 'QUEUED',
  Requested = 'REQUESTED',
  Started = 'STARTED',
  InProgress = 'IN_PROGRESS',
  EndedInError = 'ENDED_IN_ERROR',
  EndedSuccessfully = 'ENDED_SUCCESSFULLY',
  Dropped = 'DROPPED'
}

/**
 * Check if a given object implements the ScanInfo interface.
 */
export function instanceOfScanInfo(value: object): boolean {
  if (!('description' in value)) return false
  if (!('scanStatus' in value)) return false
  return true
}

export function ScanInfoFromJSON(json: any): ScanInfo {
  return ScanInfoFromJSONTyped(json, false)
}

export function ScanInfoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ScanInfo {
  if (json == null) {
    return json
  }
  return {
    description: json['description'],
    scanStatus: json['scan_status'],
    scanRequestedAt:
      json['scan_requested_at'] == null
        ? undefined
        : new Date(json['scan_requested_at']),
    scanUpdatedAt:
      json['scan_updated_at'] == null
        ? undefined
        : new Date(json['scan_updated_at']),
    dryRun: json['dry_run'] == null ? undefined : json['dry_run']
  }
}

export function ScanInfoToJSON(value?: ScanInfo | null): any {
  if (value == null) {
    return value
  }
  return {
    description: value['description'],
    scan_status: value['scanStatus'],
    scan_requested_at:
      value['scanRequestedAt'] == null
        ? undefined
        : value['scanRequestedAt'].toISOString(),
    scan_updated_at:
      value['scanUpdatedAt'] == null
        ? undefined
        : value['scanUpdatedAt'].toISOString(),
    dry_run: value['dryRun']
  }
}
