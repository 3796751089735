import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import { IconAttackVectorArrow } from '@app/components-legacy/Icon'
import { LabelAlt, LabelSliced } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import type { EntityAttackType } from '@app/entities'
import type EntityAttackVector from '@app/entities/EntityAttackVector'
import * as React from 'react'
import styled from 'styled-components'
import { AttacksPageContext } from '../../context'
import IoAAttacksTableBodyShowAttackDetailsDecoration from './IoAAttacksTableBodyShowAttackDetailsDecoration'

interface IIoAAttacksTableBodyDataRowAttackVectorProps {
  className?: string
  attackTypeEntity: Maybe<EntityAttackType>
  attackVectorEntity: Maybe<EntityAttackVector>
}

const popoverMaxLength = 1024

const LabelAltCentered = styled(LabelAlt)`
  text-align: center;
`

const IoAAttacksTableBodyDataRowAttackVector: React.FC<
  IIoAAttacksTableBodyDataRowAttackVectorProps
> = props => {
  const attacksPageContext = React.useContext(AttacksPageContext)

  if (!attacksPageContext) {
    return null
  }

  const description =
    props.attackTypeEntity &&
    props.attackVectorEntity &&
    props.attackVectorEntity.buildDescription(
      props.attackTypeEntity.getPropertyAsString('vectorTemplate')
    )

  return (
    <ContainerFlex
      name="IoAAttacksTableBodyDataRowAttackVector"
      className="attackVector"
      alignItems="center"
      justifyContent="center"
      direction="column"
      items={[
        <LabelAltCentered
          variant={LabelAltVariant.Small | LabelAltVariant.Minor}
        >
          <LabelSliced
            breakString
            maxLength={75}
            allowHTML
            popoverMaxLength={popoverMaxLength}
            popoverType="popover"
            value={description ?? '?'}
            decoration={
              <IoAAttacksTableBodyShowAttackDetailsDecoration
                popoverMaxLength={popoverMaxLength}
                attackVector={props.attackVectorEntity}
                attackVectorDescription={description}
              />
            }
          />
        </LabelAltCentered>,

        <IconAttackVectorArrow />
      ]}
    />
  )
}

export default IoAAttacksTableBodyDataRowAttackVector
