import type { IBladeHeaderInfo } from '@app/components-legacy/Blade/BladeHeader'
import BladeHeader from '@app/components-legacy/Blade/BladeHeader'
import { LabelDate, LabelSliced } from '@app/components-legacy/Label'
import { AdObjectType } from '@app/entities/EntityAdObject/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { filterFalsies } from '@libs/filterFalsies'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import EventDetailsHeaderImpactedDomains from './EventDetailsHeaderImpactedDomains'

interface IEventDetailsHeaderProps {}

const EventDetailsHeader: React.FC<IEventDetailsHeaderProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['TrailFlow.EventDetails.Attributes']
  })

  const {
    storeTrailFlow: { storeEventDetails }
  } = useStores()

  const getInfos = (): IBladeHeaderInfo[] => {
    const event = storeEventDetails.event

    if (!event) {
      return []
    }

    const adObject = event.getAdObject()

    if (!adObject) {
      return []
    }

    if (!adObject.type) {
      return []
    }

    const classOrFile = adObject.getClassOrFile()

    return filterFalsies([
      {
        rawType: 'Source',
        type: translate('Source'),
        value: adObject.getPropertyAsString('type', '-')
      },
      {
        rawType: 'Type',
        type: translate('Type'),
        value: event.getPropertyAsString('type', '-')
      },
      {
        rawType: adObject.type === AdObjectType.LDAP ? 'Class' : 'File',
        type:
          adObject.type === AdObjectType.LDAP
            ? translate('Class')
            : translate('File'),
        value: (
          <LabelSliced
            maxLength={35}
            value={classOrFile === null ? translate('Folder') : classOrFile}
          />
        )
      },
      {
        rawType: adObject.type === AdObjectType.LDAP ? 'DN' : 'Globalpath',
        type:
          adObject.type === AdObjectType.LDAP
            ? translate('DN')
            : translate('Globalpath'),
        value: (
          <LabelSliced maxLength={35} value={adObject.getDNOrGlobalPath()} />
        )
      },
      // display only if deviances has been found for this event
      storeEventDetails.hasDeviances && {
        rawType: 'Impacted domains',
        type: translate('Impacted domains'),
        value: <EventDetailsHeaderImpactedDomains />
      },
      {
        rawType: 'Event date',
        type: translate('Event date'),
        value: <LabelDate date={event.date} />
      }
    ])
  }

  const infosData = getInfos()

  return <BladeHeader icon="box" infos={infosData} />
}

export default observer(EventDetailsHeader)
