import type { PropertiesNullable } from '@@types/helpers'
import { interpolateTemplate } from '@alsid/common/helpers/template/interpolateTemplate'
import { ensureArray } from '@libs/ensureArray'
import { formatAttributeValueToMarkdown } from '@libs/incriminatingAttributes/formatAttributeValueToMarkdown'
import { ValueType } from '@libs/valueTypeParser/types'
import type {
  IncriminatingAttribute,
  IncriminatingAttributeAttribute,
  IncriminatingAttributeDescription,
  Maybe
} from '@server/graphql/typeDefs/types'
import * as moment from 'moment'
import EntityBase from '../EntityBase'

export default class EntityIncriminatingAttribute
  extends EntityBase
  implements PropertiesNullable<IncriminatingAttribute>
{
  id: Maybe<number> = null
  createdEventId: Maybe<string> = null
  resolvedEventId: Maybe<string> = null
  devianceProviderId: Maybe<string> = null
  resolvedAt: Maybe<string> = null
  eventDate: Maybe<string> = null
  attributes: Maybe<IncriminatingAttributeAttribute[]> = null
  description: Maybe<IncriminatingAttributeDescription> = null
  ignoreUntil: Maybe<string> = null
  checkerId: Maybe<number> = null
  directoryId: Maybe<number> = null
  profileId: Maybe<number> = null
  adObjectId: Maybe<number> = null
  reasonId: Maybe<number> = null

  constructor(data: Partial<EntityIncriminatingAttribute>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return true if the current date is before than the "ignoreUntil" property value.
   */
  isIgnored(): boolean {
    if (!this.ignoreUntil) {
      return false
    }

    return moment().utc().isBefore(moment.utc(this.ignoreUntil))
  }

  /**
   * Build the description sentence to a markdown string.
   */
  buildDescriptionMarkdown(): Maybe<string> {
    if (
      // should never happen, but it case of
      !this.description ||
      // on deviances emitted before v2.7 (deviances before incriminating attributes),
      // devianceProviderId is missing and attributes for translations are not available
      !this.devianceProviderId
    ) {
      return null
    }

    const indexedAttributes = ensureArray(this.attributes).reduce(
      (acc, attr) => {
        acc.set(attr.name, attr.value)
        return acc
      },
      new Map<string, string>()
    )

    const variables = this.description.replacements.reduce(
      (acc, replacement) => {
        const replacementValue = formatAttributeValueToMarkdown(
          indexedAttributes.get(replacement.name),
          replacement.name === 'DangerousAceList'
            ? ValueType.sddl
            : (replacement.valueType as ValueType)
        )

        return {
          ...acc,
          [replacement.name]: replacementValue
        }
      },
      {}
    )

    try {
      const description = interpolateTemplate(
        this.description.template,
        variables,
        null
      )

      // avoid duplicate spaces, after empty replacements
      return description.replace(/\s+/g, ' ')
    } catch (err) {
      return null
    }
  }
}
