import { Blade } from '@app/components-legacy/Blade'
import { BladeContentVariant } from '@app/components-legacy/Blade/types'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import type { ITopologyDomainDetailsRouteDefinition } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DomainDetailsBlade from '../DomainDetailsBlade'
import { canAccessToDomainDetails } from '../DomainDetailsBlade/permissions'
import { canAccessToTopology } from '../permissions'
import TrustsDeviantObjectsBlade from '../TrustsDeviantObjectsBlade'
import { canSeeDangerousTrustRelationships } from '../TrustsDeviantObjectsBlade/permissions'
import { onTopologySceneLoad } from './handlers'
import Scene from './Scene'

interface IBladeSceneProps {}

const SceneBlade: React.FC<IBladeSceneProps> = props => {
  const { storeTopology, storeAuthentication, storeRbac } = useStores()

  return (
    <>
      <Blade
        root={true}
        routeDefinition={{
          routeName: AppRouteName.Topology,
          parameters: {}
        }}
        onLoad={onTopologySceneLoad(storeTopology)(
          storeAuthentication.currentProfileId
        )}
        flags={[
          storeTopology.storeFlags.flags,
          storeTopology.storeInfrastructures.storeFlagsFetchInfrastructures
            .flags
        ]}
        contentProps={{
          variant: storeRbac.isUserGrantedTo(canAccessToTopology)
            ? BladeContentVariant.fullScreen
            : BladeContentVariant.normal
        }}
        layout={{
          name: 'default',
          content: <Scene />
        }}
        rbacCapabilityCheck={canAccessToTopology}
      />

      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName: AppRouteName.Topology_DeviantObjects,
              parameters: {
                checkerId: Number(),
                checkerCodename: String()
              }
            },
            component: TrustsDeviantObjectsBlade,
            rbacPermissionsCheck: () => canSeeDangerousTrustRelationships
          },
          {
            routeDefinition: {
              routeName: AppRouteName.Topology_DomainDetails,
              parameters: {
                directoryId: Number()
              }
            },
            component: DomainDetailsBlade,
            rbacPermissionsCheck: (
              parameters: ITopologyDomainDetailsRouteDefinition['parameters']
            ) => canAccessToDomainDetails(parameters.directoryId)
          }
        ]}
      />
    </>
  )
}

export default observer(SceneBlade)
