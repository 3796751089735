import { ErrorRbacDenyMessage } from '@app/components/Error'
import {
  ContainerFlex,
  ContainerHTMLContent,
  ContainerHVCentered,
  ContainerRbac
} from '@app/components-legacy/Container'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { IconNoData } from '@app/components-legacy/Icon'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { filterFalsies } from '@libs/filterFalsies'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { canCreateDashboardWidget } from '../permissions'

export interface IGridAddFirstWidgetProps {
  onAddFirstWidgetButton: () => void
}

const ContainerHTMLContentCustom = styled(ContainerHTMLContent)`
  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      margin-top: 30px;
      text-align: center;
    `}
`

const GridAddFirstWidget: React.FC<IGridAddFirstWidgetProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Dashboard.Common'] })

  const { storeRbac } = useStores()

  const theme = useDSTheme()

  const canCreateWidget = storeRbac.isUserGrantedTo(canCreateDashboardWidget)

  const themeIsLegacy = isThemeLegacy(theme)

  const getItems = (): React.ReactNodeArray => {
    return filterFalsies([
      canCreateWidget && (
        <IconNoData
          text={
            <ContainerHTMLContentCustom>
              {translate(
                themeIsLegacy
                  ? 'There is no widget on this dashboard yet'
                  : 'Currently, there is no data available. Start adding widgets now!',
                {
                  transformMarkdown: true
                }
              )}
            </ContainerHTMLContentCustom>
          }
          imageName="widgets-placeholder"
        />
      ),

      !canCreateWidget && (
        <ErrorRbacDenyMessage
          message={translate(
            'You do not have the rights to create a new widget'
          )}
        />
      ),

      <ContainerRbac rbacCapabilityCheck={canCreateDashboardWidget}>
        <FormWrapperButton
          labelledBy="addNewWidget"
          variant={ButtonVariant.primary}
          size={ButtonSize.large}
          buttonProps={{
            onClick: props.onAddFirstWidgetButton
          }}
        >
          {themeIsLegacy
            ? translate('Add a new widget on this dashboard')
            : translate('Add a widget')}
        </FormWrapperButton>
      </ContainerRbac>
    ])
  }

  return (
    <ContainerHVCentered>
      <ContainerFlex
        name="AddWidgetButtonItems"
        direction="column"
        alignItems="center"
        items={getItems()}
        spaced
        spaceWidth="large"
      />
    </ContainerHVCentered>
  )
}

export default GridAddFirstWidget
