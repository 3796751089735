import { Features } from '@alsid/common'
import { isDefined } from '@productive-codebases/toolbox'
import { computed, makeObservable } from 'mobx'
import StoreBase from '../StoreBase'
import type StoreRoot from '../StoreRoot'
import type { IStoreOptions } from '../types'

export default class StorePendo extends StoreBase {
  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)

    makeObservable(this)
  }

  /**
   * Return true if Pendo should be enabled.
   */
  @computed
  get isPendoEnabled(): boolean {
    const { storeAuthentication, storeRbac } = this.storeRoot.stores

    const tul = this.storeRoot.environment.config.tul

    return (
      // if enabled in Kapteyn configuration
      tul?.services?.pendo?.enabled === true &&
      // if Pendo apikey has been defined
      (tul?.services?.pendo?.parameters?.apikey ?? '').length > 0 &&
      // if FF Pendo enabled
      storeRbac.isUserGrantedAccordingFeatureFlag(
        Features.PENDO_INTEGRATION
      ) === true &&
      // if CloudStatistics.isEnabled
      storeAuthentication.cloudStatistics?.isEnabled === true &&
      // if Pendo parameters has been computed correctly
      isDefined(storeAuthentication.cloudStatistics?.getPendoParameters())
    )
  }
}
