import type { Maybe } from '@@types/helpers'
import { Features } from '@alsid/common'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  accessByFeatureFlag,
  createDirectory,
  deleteDirectory,
  editDirectory,
  readDataCollection,
  readDirectories,
  readDirectoryRecrawl,
  readUISystemDirectories
} from '@libs/rbac/permissions'
import { canAccessToSystem } from '../permissions'

export const canAccessToDirectories = rbacCapabilityCheckAllOf(
  canAccessToSystem,
  readUISystemDirectories(),
  readDirectories()
)

export const canCreateDirectories = rbacCapabilityCheckAllOf(
  canAccessToDirectories,
  createDirectory()
)

export const canEditDirectory = (directoryId: number) =>
  rbacCapabilityCheckAllOf(canAccessToDirectories, editDirectory(directoryId))

export const canDeleteDirectory = (directoryId: Maybe<number>) =>
  rbacCapabilityCheckAllOf(canAccessToDirectories, deleteDirectory(directoryId))

export const canRecrawlDirectory = (directoryId: Maybe<number>) =>
  rbacCapabilityCheckAllOf(
    canAccessToDirectories,
    readDirectoryRecrawl(directoryId)
  )

export const canAccessToHoneyAccount = rbacCapabilityCheckAllOf(
  canAccessToDirectories
)

export const canCreateHoneyAccount = (directoryId: number) =>
  rbacCapabilityCheckAllOf(
    canAccessToHoneyAccount,
    canEditDirectory(directoryId)
  )

export const canEditHoneyAccount = (directoryId: number) =>
  rbacCapabilityCheckAllOf(
    canAccessToHoneyAccount,
    canEditDirectory(directoryId)
  )

export const canAccessTenableCloudSensitiveData = rbacCapabilityCheckAllOf(
  accessByFeatureFlag(Features.TENABLE_CLOUD_SENSITIVE_DATA)(),
  readDataCollection()
)
