import {
  IconDcTriangle,
  IconLaptop,
  IconServer
} from '@app/components-legacy/Icon'
import { IconUserOutlined } from '@app/components-legacy/Icon/IconAntd'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type { Maybe } from '@server/graphql/typeDefs/types'
import {
  AttackDestinationType,
  AttackSourceType
} from '@server/graphql/typeDefs/types'
import * as React from 'react'

interface IIoAAttacksTargetIconProps {
  source?: Maybe<AttackSourceType>
  destination?: Maybe<AttackDestinationType>
}

const IoAAttacksTargetIcon: React.FC<IIoAAttacksTargetIconProps> = props => {
  if (props.source) {
    switch (props.source) {
      case AttackSourceType.Dc:
        return <IconDcTriangle />

      case AttackSourceType.Server:
        return <IconServer />

      case AttackSourceType.Computer:
        return <IconLaptop />

      case AttackSourceType.User:
        return <IconUserOutlined /> // FIXME: change the user icon

      default:
        assertUnreachableCase(props.source)
    }
  }

  if (props.destination) {
    switch (props.destination) {
      case AttackDestinationType.Dc:
        return <IconDcTriangle />

      case AttackDestinationType.Server:
        return <IconServer />

      case AttackDestinationType.Computer:
        return <IconLaptop />

      case AttackDestinationType.User:
        return <IconUserOutlined /> // FIXME: change the user icon

      default:
        assertUnreachableCase(props.destination)
    }
  }

  return null
}

export default IoAAttacksTargetIcon
