import {
  ContainerContent,
  ContainerFlex,
  ContainerHTMLContent
} from '@app/components/Container'
import BladeContentLayoutDefault from '@app/components-legacy/Blade/BladeContent/BladeContentLayoutDefault'
import { DrawerContext } from '@app/components-legacy/Drawer/context'
import Drawer from '@app/components-legacy/Drawer/Drawer'
import DrawerHorizontalContainer from '@app/components-legacy/Drawer/DrawerHorizontalContainer'
import DrawerTitle from '@app/components-legacy/Drawer/DrawerTitle'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { buildVariants } from '@design-system/libs/buildVariants'
import { grant } from '@libs/rbac/permissions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { ReportAction } from '../types'
import {
  handleReportCreationOnLoad,
  handleReportCreationOnSubmit,
  handleReportCreationOnUnload,
  handleReportEditionOnLoad,
  handleReportEditionOnSubmit,
  handleReportEditionOnUnload
} from './handlers'
import ReportCommonForm from './ReportCommonForm'

export interface IDrawerReportActionProps {}

const Subtitle = styled(ContainerHTMLContent)(props => {
  return buildVariants(props)
    .css({
      color: props.theme.tokens['drawer/color/subtitle'],
      marginBottom: props.theme.tokens['margin/default'],
      marginTop: `-${props.theme.tokens['margin/default']}`
    })
    .end()
})

const DrawerReportAction: React.FC<IDrawerReportActionProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.ReportingCenter']
  })

  const { storeManagementReportingCenter } = useStores()
  const { storeDrawerReportAction: storeReportActionDrawer } =
    storeManagementReportingCenter

  const reportDataRow = storeReportActionDrawer.getDataValue('reportDataRow')

  const theme = useDSTheme()

  const renderContent = () => {
    if (!reportDataRow) {
      return (
        <ContainerContent
          onLoad={handleReportCreationOnLoad(storeManagementReportingCenter)}
          onUnload={handleReportCreationOnUnload(
            storeManagementReportingCenter
          )}
        >
          <ReportCommonForm
            version={ReportAction.Creation}
            storeManagementReportingCenter={storeManagementReportingCenter}
            onSubmit={handleReportCreationOnSubmit(
              storeManagementReportingCenter
            )}
          />
        </ContainerContent>
      )
    }

    return (
      <ContainerContent
        onLoad={handleReportEditionOnLoad(storeManagementReportingCenter)(
          reportDataRow
        )}
        onUnload={handleReportEditionOnUnload(storeManagementReportingCenter)}
      >
        <ReportCommonForm
          version={ReportAction.Edition}
          storeManagementReportingCenter={storeManagementReportingCenter}
          onSubmit={handleReportEditionOnSubmit(storeManagementReportingCenter)(
            reportDataRow.id
          )}
        />
      </ContainerContent>
    )
  }

  return (
    <DrawerContext.Provider
      value={{
        storeDrawer: storeManagementReportingCenter.storeDrawerReportAction
      }}
    >
      <Drawer>
        <DrawerHorizontalContainer variant="large">
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: (
                <DrawerTitle>{translate('Report configuration')}</DrawerTitle>
              ),
              content: (
                <ContainerFlex
                  name="DrawerCreateReportDrawerContent"
                  direction="column"
                  items={[
                    <Subtitle>
                      {translate('Set up your regular reports here', {
                        transformMarkdown: true
                      })}
                    </Subtitle>,
                    renderContent()
                  ]}
                />
              ),
              footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
            }}
            rbacCapabilityCheck={grant()}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerReportAction)
