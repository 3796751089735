import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { FormWrapper } from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DirectoriesCreateFirst from '../../Directories/DirectoriesPage/DirectoriesCreateFirst'
import EmailCommonFormAlertFields from './EmailCommonFormAlertFields'
import EmailCommonFormButtons from './EmailCommonFormButtons'
import EmailCommonFormMainFields from './EmailCommonFormMainFields'

export interface IEmailCommonFormProps {
  version: 'creation' | 'edition'
  onSubmit: (e: React.FormEvent<any>) => void
}

const EmailCommonForm: React.FC<IEmailCommonFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Titles', 'Components.InputInfrastructures']
  })

  const { storeManagementEmails } = useStores()

  const renderCreateDirectoryFirst = () => {
    return (
      <ContainerContent title={translate('Main information')}>
        <DirectoriesCreateFirst />
      </ContainerContent>
    )
  }

  const renderForm = () => {
    return (
      <FormWrapper name="emailConfiguration" onSubmit={props.onSubmit}>
        <>
          <ContainerFlex
            name="EmailsCreateForm"
            direction="column"
            items={[
              <EmailCommonFormMainFields />,
              <EmailCommonFormAlertFields {...props} />
            ]}
            spaced
          />

          <Portal name={PlaceHolderName.bladeFooter}>
            <EmailCommonFormButtons
              version={props.version}
              onSubmit={props.onSubmit}
            />
          </Portal>
        </>
      </FormWrapper>
    )
  }

  if (!storeManagementEmails.storeInfrastructures.getDirectoryIds().length) {
    return renderCreateDirectoryFirst()
  }

  return renderForm()
}

export default observer(EmailCommonForm)
