import { LayoutAuth } from '@app/components-legacy/Layout'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { AppRouteName } from '@app/routes'
import { grant, unAuthenticated } from '@libs/rbac/permissions'
import * as React from 'react'
import EulaPage from './EulaPage'
import FirstLoginChangePasswordPage from './FirstLoginChangePasswordPage'
import LicensePage from './LicensePage'
import LoginPage from './LoginPage'
import LoginPendingActivationPage from './LoginPendingActivationPage'
import { canAccessToLicensePage, canValidateEULA } from './permissions'

interface IAuthPageProps {}

const AuthPage: React.FC<IAuthPageProps> = () => {
  return (
    <LayoutAuth
      content={
        <RbacRoutes
          routes={[
            {
              routeDefinition: {
                routeName: AppRouteName.Auth_Login,
                parameters: {}
              },
              component: LoginPage,
              rbacPermissionsCheck: unAuthenticated,
              // can access to this route being unauthenticated
              isAnonymous: true,
              // no license or EULA available yet
              dontVerifyLicensesContext: true
            },
            {
              routeDefinition: {
                routeName: AppRouteName.Auth_Eula,
                parameters: {}
              },
              component: EulaPage,
              rbacPermissionsCheck: () => canValidateEULA,
              // can access to the route without having approve EULA
              dontVerifyLicensesContext: true
            },
            {
              routeDefinition: {
                routeName: AppRouteName.Auth_License,
                parameters: {}
              },
              component: LicensePage,
              rbacPermissionsCheck: () => canAccessToLicensePage,
              // can access to this route with a unvalid license
              dontVerifyLicensesContext: true
            },
            {
              routeDefinition: {
                routeName: AppRouteName.Auth_PendingActivation,
                parameters: {}
              },
              component: LoginPendingActivationPage,
              rbacPermissionsCheck: grant,
              // can access to this route being unauthenticated
              isAnonymous: true,
              // no license or EULA available yet
              dontVerifyLicensesContext: true
            },
            {
              routeDefinition: {
                routeName: AppRouteName.Auth_FirstLoginPassword,
                parameters: {}
              },
              component: FirstLoginChangePasswordPage,
              rbacPermissionsCheck: grant,
              // can access to this route being unauthenticated
              isAnonymous: true,
              // no license or EULA available yet
              dontVerifyLicensesContext: true
            }
          ]}
          redirect
        />
      }
    />
  )
}

export default AuthPage
