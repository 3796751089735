import { LabelNonBreakable } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreInputTenants } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface ITenantsSelectionCounterProps {
  storeInputTenants: StoreInputTenants
}

const TenantsSelectionCounter: React.FC<
  ITenantsSelectionCounterProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.InputTenants']
  })

  return (
    <LabelNonBreakable>
      {translate('Tenants selected', {
        interpolations: {
          count: props.storeInputTenants.selectedTenantIds.length,
          allCount: props.storeInputTenants.tenantIds.length
        }
      })}
    </LabelNonBreakable>
  )
}

export default observer(TenantsSelectionCounter)
