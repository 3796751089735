import * as React from 'react'
import styled from 'styled-components'

interface ILabelReportTitleProps {
  className?: string
  size: 'default' | 'title'
  outline?: boolean
  children?: React.ReactNode
}

const LabelReportTitle: React.FC<ILabelReportTitleProps> = props => {
  return <div className={props.className}>{props.children}</div>
}

function getFontFamily(props: ILabelReportTitleProps): string {
  return props.outline ? 'Akira-Outline' : 'Akira'
}

function getFontSize(props: ILabelReportTitleProps): string {
  return props.size === 'default' ? 'inherits' : '75px'
}

export default styled(LabelReportTitle)`
  font-family: ${getFontFamily};
  font-size: ${getFontSize};
  text-transform: capitalize;
`
