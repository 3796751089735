import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { classes, consts } from '@app/styles'
import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'
import * as React from 'react'
import styled from 'styled-components'
import { ITableRowVariantFlags } from './types'

export interface ITableRowProps {
  id?: string
  className?: string
  isOdd?: boolean
  hasActionIcons?: boolean
  variant?: ITableRowVariantFlags
  onClick?: () => void
  children: React.ReactNode
}

const TableRow: React.FC<ITableRowProps> = props => {
  const { testAttributeProps } = useTestAttribute('table')

  const rowProps = filterEmptyProps({
    id: props.id,
    className: props.className,
    onClick: props.onClick
  })

  return (
    <tr {...rowProps} {...testAttributeProps('row')(`id-${props.id}`)}>
      {props.children}
    </tr>
  )
}

export default styled(TableRow)`
  background-color: ${props =>
    props.isOdd === true
      ? props.theme.tokens['table/backgroundColor/oddRow']
      : props.theme.tokens['table/backgroundColor/evenRow']};
  transition: ${consts.transitionOpacity};
  border-bottom: ${props => props.theme.tokens['table/borderBottom/row']};
  padding: ${props => props.theme.tokens['table/padding/row']};

  &:hover {
    background-color: ${props =>
      props.theme.tokens['table/backgroundColor/rowHover']};

    ${props => {
      if (!props.hasActionIcons) {
        return ''
      }

      return `
        td:last-child {
          span.anticon, .iconDisplayedOnHover {
            opacity: 1;
          }
        }
      `
    }}
  }

  ${props => {
    /* Clickable variant: Add a cursor on the row */
    const shouldHavePointer =
      (props.variant || ITableRowVariantFlags.Normal) &
      (ITableRowVariantFlags.Clickable | ITableRowVariantFlags.Expanded)

    if (!shouldHavePointer) {
      return ''
    }

    return `
      &:hover {
        cursor: pointer;
      }
    `
  }};

  ${props => {
    /* Toggleable variant: Extra row displayed under the toggleable row */
    const shouldHaveBackgroundOnHover =
      (props.variant || ITableRowVariantFlags.Normal) &
      ITableRowVariantFlags.Toggleable

    if (!shouldHaveBackgroundOnHover) {
      return ''
    }

    return `
      &:hover {
        background-color: ${
          props.isOdd === true ? consts.colorWhiteAlt : consts.colorWhite
        };
      }
    `
  }};

  ${props => {
    /* Expanded variant: Add a selected background color */
    const shouldHaveBackground =
      (props.variant || ITableRowVariantFlags.Normal) &
      ITableRowVariantFlags.Expanded

    if (!shouldHaveBackground) {
      return ''
    }

    return `
      & {
        border-bottom: none;
      }
    `
  }};

  td {
    &:not(.toggleable) {
      padding-right: ${consts.paddingVerySmall};
    }

    ${props => {
      if (!props.hasActionIcons) {
        return ''
      }

      return `
        &:last-child {
          span.anticon.${classes.tableIconDontHide}, .iconDisplayedOnHover {
            opacity: 1;
          }

          span.anticon, .iconDisplayedOnHover {
            transition: ${consts.transitionOpacity};
            opacity: 0;
          }
        }
      `
    }}
  }
`
