import type { DeviceViewPort } from './DeviceViewPort'

export enum DeviceViewPortWidth {
  verySmall = 'verySmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge'
}

export interface IUseDeviceViewPort {
  deviceViewPort: DeviceViewPort
}
