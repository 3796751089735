import type { EntityTopologyDirectory } from '@app/entities'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { isDefined } from '@libs/isDefined'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToDomainDetails } from '../../DomainDetailsBlade/permissions'
import DirectoryLabel from './DirectoryLabel'
import DirectoryShape from './DirectoryShape'
import { onDomainClick } from './handlers'
import type SceneObject from './SceneObject'

interface IDirectoryProps {
  directorySceneObject: SceneObject<EntityTopologyDirectory>
}

const Directory: React.FC<IDirectoryProps> = props => {
  const appRouter = useAppRouter()

  const { storeRbac, storeTopology } = useStores()

  const { uid, id: directoryId } = props.directorySceneObject.object

  if (!uid) {
    return null
  }

  const color = props.directorySceneObject.object.known
    ? consts.colorBlack001
    : consts.colorOrange001

  const isSearching = storeTopology.storeInputSearch.searchValue.length >= 3

  const isDirectoryHighlighted =
    storeTopology.searchedDirectoriesUids.indexOf(uid) !== -1 ||
    storeTopology.searchedActiveDirectoriesUids.indexOf(uid) !== -1

  const isDirectoryTransparent = isSearching && !isDirectoryHighlighted

  const isKnown =
    isDefined(directoryId) &&
    storeRbac.isUserGrantedTo(canAccessToDomainDetails(directoryId))

  return (
    <g
      data-name="Directory"
      onClick={onDomainClick(storeRbac, appRouter)(directoryId)}
    >
      <DirectoryShape
        directorySceneObject={props.directorySceneObject}
        color={color}
        isTransparent={isDirectoryTransparent}
        isKnown={isKnown}
      />

      <DirectoryLabel
        directorySceneObject={props.directorySceneObject.addOffset(
          {
            x: 25,
            y: 18
          },
          { clone: true }
        )}
        isHighlighted={isDirectoryHighlighted}
        isTransparent={isDirectoryTransparent}
        isKnown={isKnown}
      />
    </g>
  )
}

export default observer(Directory)
