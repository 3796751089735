/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { DescriptionTemplate } from './DescriptionTemplate'
import {
  DescriptionTemplateFromJSON,
  DescriptionTemplateFromJSONTyped,
  DescriptionTemplateToJSON
} from './DescriptionTemplate'
import type { Tenant } from './Tenant'
import { TenantFromJSON, TenantFromJSONTyped, TenantToJSON } from './Tenant'

/**
 *
 * @export
 * @interface Finding
 */
export interface Finding {
  /**
   *
   * @type {string}
   * @memberof Finding
   */
  uuid: string
  /**
   *
   * @type {string}
   * @memberof Finding
   */
  checkerId: string
  /**
   *
   * @type {string}
   * @memberof Finding
   */
  type: string
  /**
   *
   * @type {string}
   * @memberof Finding
   */
  object: string
  /**
   *
   * @type {Date}
   * @memberof Finding
   */
  detectionDate: Date
  /**
   *
   * @type {DescriptionTemplate}
   * @memberof Finding
   */
  description: DescriptionTemplate
  /**
   *
   * @type {string}
   * @memberof Finding
   */
  path: string
  /**
   *
   * @type {Tenant}
   * @memberof Finding
   */
  tenant: Tenant
}

/**
 * Check if a given object implements the Finding interface.
 */
export function instanceOfFinding(value: object): boolean {
  if (!('uuid' in value)) return false
  if (!('checkerId' in value)) return false
  if (!('type' in value)) return false
  if (!('object' in value)) return false
  if (!('detectionDate' in value)) return false
  if (!('description' in value)) return false
  if (!('path' in value)) return false
  if (!('tenant' in value)) return false
  return true
}

export function FindingFromJSON(json: any): Finding {
  return FindingFromJSONTyped(json, false)
}

export function FindingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Finding {
  if (json == null) {
    return json
  }
  return {
    uuid: json['uuid'],
    checkerId: json['checker_id'],
    type: json['type'],
    object: json['object'],
    detectionDate: new Date(json['detection_date']),
    description: DescriptionTemplateFromJSON(json['description']),
    path: json['path'],
    tenant: TenantFromJSON(json['tenant'])
  }
}

export function FindingToJSON(value?: Finding | null): any {
  if (value == null) {
    return value
  }
  return {
    uuid: value['uuid'],
    checker_id: value['checkerId'],
    type: value['type'],
    object: value['object'],
    detection_date: value['detectionDate'].toISOString(),
    description: DescriptionTemplateToJSON(value['description']),
    path: value['path'],
    tenant: TenantToJSON(value['tenant'])
  }
}
