import { IconPin, IconUnPin } from '@app/components/Icon'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { paddingDefault, paddingSmall } from '@app/styles/consts'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { handleAttackPathIconPinOnClick } from '../handlers'

export interface IIconPinPathProps {
  className?: string
}

const StyledDiv = styled.div`
  position: absolute;
  top: 70px;
  right: ${consts.marginMedium};
  pointer-events: auto;
  z-index: 1;
`

const IconPinPath: React.FC<IIconPinPathProps> = props => {
  const { storeAttackPath } = useStores()

  const translate = useAppTranslator({
    namespaces: ['AttackPath.Chart']
  })

  return (
    <Tooltip
      title={
        storeAttackPath.currentPinnedNodeUid
          ? translate('Unpin highlighted path')
          : translate(
              'You can pin a highlighted path by clicking on any of its nodes'
            )
      }
      placement="bottomLeft"
      color={consts.colorAttackPathSecondary}
      overlayInnerStyle={{
        borderRadius: consts.borderRadiusRedesign,
        userSelect: 'none',
        boxShadow: 'none',
        padding: `${paddingSmall} ${paddingDefault} ${paddingSmall} ${paddingSmall}`
      }}
    >
      <StyledDiv>
        <FormWrapperButton
          labelledBy="icon-pin-highlighted-path"
          variant={ButtonVariant.secondary}
          size={ButtonSize.small}
          icon={!storeAttackPath.currentPinnedNodeUid ? IconPin : IconUnPin}
          customDisabled={!storeAttackPath.currentPinnedNodeUid}
          buttonProps={{
            type: 'primary',
            onClick: handleAttackPathIconPinOnClick(storeAttackPath)
          }}
        />
      </StyledDiv>
    </Tooltip>
  )
}

export default observer(IconPinPath)
