import { FormWrapperSelect } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks'
import { range } from 'lodash'
import * as React from 'react'
import { UnitPrefix } from '../types'
import CronfieldControlContainer from './CronfieldControlContainer'

export interface ISelectHoursProps {
  hours: string[]
  onChange: (hours: string[]) => void
}

export default function SelectHours(props: ISelectHoursProps) {
  const translate = useAppTranslator({
    namespaces: ['Components.InputCrontab']
  })

  const selectOptions = range(0, 24).map(hour => {
    return {
      label: hour.toString().padStart(2, '0'),
      value: String(hour)
    }
  })

  return (
    <CronfieldControlContainer
      name="SelectHours"
      unitPrefix={UnitPrefix.At}
      hidePrefix={false}
      flexGrow="1"
    >
      <FormWrapperSelect<string[]>
        labelledBy="format"
        width="smallNormal"
        selectProps={{
          placeholder: translate('Every hour'),
          allowClear: true,
          value: props.hours,
          onChange: props.onChange,
          mode: 'multiple'
        }}
        selectOptions={selectOptions}
      />
    </CronfieldControlContainer>
  )
}
