import { Features } from '@alsid/common'
import { IconHelpOutlined, IconInfoTul } from '@app/components/Icon'
import { useAppTranslator } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import * as React from 'react'
import WidgetContainer from '../WidgetContainer'
import { onHelpClick } from './handlers'

export interface IWidgetHelpProps {}

const WidgetHelp: React.FC<IWidgetHelpProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Components.Header.Help'] })

  const isTulEnabled = useFeatureFlag({
    featureFlagName: Features.TENABLE_UNIVERSAL_LAYOUT
  })

  const tabTitle = translate('Tenable.ad documentation')

  return (
    <WidgetContainer
      name="WidgetHelp"
      title={tabTitle}
      ariaRoles={['menu', 'figure']}
      labelledBy="documentation"
      onClick={onHelpClick(tabTitle)}
      isBackgroundIcon={true}
    >
      {isTulEnabled ? <IconInfoTul /> : <IconHelpOutlined />}
    </WidgetContainer>
  )
}

export default WidgetHelp
