import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { onDirectoriesCreatePageUnload } from '@app/pages/Management/SystemPage/Directories/DirectoriesCreatePage/handlers'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditDirectory } from '../permissions'
import DirectoryEditForm from './DirectoryEditForm'
import { onDirectoryEditLoad } from './handlers'

interface IDirectoriesEditPageProps {}

const DirectoriesEditPage: React.FC<IDirectoriesEditPageProps> = props => {
  const appRouter = useAppRouter()
  const {
    storeManagementDirectories,
    storeManagementDataCollection,
    storeManagementRelays,
    storeRbac
  } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_System_Directories_Edit,
    parameters: {
      directoryId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_System_Directories_Edit,
        parameters: {
          directoryId: parameters.directoryId
        }
      }}
      onLoad={onDirectoryEditLoad(
        storeManagementDirectories,
        storeManagementDataCollection,
        storeManagementRelays,
        storeRbac
      )(parameters.directoryId)}
      onUnload={onDirectoriesCreatePageUnload(
        storeManagementDirectories,
        storeManagementRelays
      )}
      flags={storeManagementDirectories.storeFlags.flags}
      layout={{
        name: 'default',
        content: (
          <ContainerContent>
            <DirectoryEditForm directoryId={parameters.directoryId} />
          </ContainerContent>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canEditDirectory(parameters.directoryId)}
    />
  )
}

export default observer(DirectoriesEditPage)
