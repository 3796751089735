import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelAlt } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { TooltipDate } from '@app/components-legacy/Tooltip'
import { DateFormat, formatDate } from '@libs/dates/formatDate'
import * as React from 'react'
import styled from 'styled-components'

interface IIoAAttacksTableBodyDataRowDateTextProps {
  className?: string
  date: string
}

const IoAAttacksTableBodyDataRowDateText: React.FC<
  IIoAAttacksTableBodyDataRowDateTextProps
> = props => {
  return (
    <div data-name="IoAAttacksTableBodyDataRowDateText">
      <TooltipDate date={props.date}>
        <div>
          <ContainerFlex
            className={props.className}
            name="IoAAttacksTableBodyDataRowDateText"
            direction="column"
            justifyContent="center"
            items={[
              <LabelAlt variant={LabelAltVariant.Small | LabelAltVariant.Minor}>
                {formatDate(props.date, {
                  format: DateFormat.englishDateOfTheDay
                })}
              </LabelAlt>,

              <LabelAlt variant={LabelAltVariant.Small | LabelAltVariant.Minor}>
                {formatDate(props.date, {
                  format: DateFormat.hoursMinutesSeconds
                })}
              </LabelAlt>
            ]}
          />
        </div>
      </TooltipDate>
    </div>
  )
}

export default styled(IoAAttacksTableBodyDataRowDateText)`
  margin-top: 20px;
  width: 60px;
`
