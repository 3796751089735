import type { StoreActivityLogs } from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'
import { updateActivityLogsUrlWithAllFilters } from '../../functions'

/**
 * Open the blade to add filters.
 */
export const onAddFiltersButtonClick =
  (storeActivityLogs: StoreActivityLogs) =>
  (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()

    // Initialize drawer data with content
    const { storeActivityLogsFilters } = storeActivityLogs
    storeActivityLogsFilters.storeDrawerFilters
      .setData({
        selectedIPs: new Set(storeActivityLogsFilters.selectedIPs),
        selectedUserEmails: new Set(
          storeActivityLogsFilters.selectedUserEmails
        ),
        selectedLogTypes: new Set(storeActivityLogsFilters.selectedLogTypes)
      })
      .openDrawer()
  }

export const onFiltersFormValidate =
  (appRouter: AppRouterClient, storeActivityLogs: StoreActivityLogs) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // Apply all drawer's modification to the filters store
    storeActivityLogs.storeActivityLogsFilters.applyDrawerFormFilters()

    // Update the url
    updateActivityLogsUrlWithAllFilters(storeActivityLogs)

    // Close the drawer
    storeActivityLogs.storeActivityLogsFilters.storeDrawerFilters.closeDrawer()

    // Reload logs with new filters
    storeActivityLogs.reloadLogs()
  }

/**
 * Reset all filters
 */
export const onAddFiltersResetButtonClick =
  (storeActivityLogs: StoreActivityLogs) =>
  (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    // Reset all drawer filters
    storeActivityLogs.storeActivityLogsFilters.storeDrawerFilters.clearData()

    // Apply the reseted filters to the filters store
    storeActivityLogs.storeActivityLogsFilters.applyDrawerFormFilters()

    // Update the url
    updateActivityLogsUrlWithAllFilters(storeActivityLogs)

    // Close the drawer
    storeActivityLogs.storeActivityLogsFilters.storeDrawerFilters.closeDrawer()

    // Reload logs with no filter
    storeActivityLogs.reloadLogs()
  }
