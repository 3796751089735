import { ButtonExportAll } from '@app/components-legacy/Button'
import {
  ContainerFooter,
  ContainerRbac
} from '@app/components-legacy/Container'
import { handleDrawerOnOpen } from '@app/components-legacy/Drawer/handlers'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canExportIoEIdentityFindings } from '../permissions'

interface IFindingsFooterProps {
  className?: string
  checkerId: string
  checkerCodename: GenericCheckerCodename
}

const FindingsFooter: React.FC<IFindingsFooterProps> = props => {
  const {
    storeIoE: {
      storeIndicator: { storeIndicatorFindings }
    }
  } = useStores()

  return (
    <ContainerFooter>
      <ContainerRbac rbacCapabilityCheck={canExportIoEIdentityFindings}>
        <ButtonExportAll
          onClick={handleDrawerOnOpen(
            storeIndicatorFindings.storeDrawerExportFindings
          )}
        />
      </ContainerRbac>
    </ContainerFooter>
  )
}

export default observer(FindingsFooter)
