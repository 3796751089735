import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import * as React from 'react'

interface IIconBellProps extends IIconProps {}

const IconBell: React.FC<IIconBellProps> = props => {
  const size = props.size ?? 20
  const fill = props.color ?? consts.FontColorV2.secondary

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox={`0 0 15 18`}
      fill="none"
    >
      <path
        d="M7.98102 14.8978C7.66108 15.5556 6.98549 16 6.20324 16C5.42098 16 4.7454 15.5556 4.42546 14.8978C4.95883 14.9334 5.47432 14.9511 5.93663 14.9511H6.43441C6.93219 14.9689 7.44768 14.9511 7.98105 14.8978H7.98102ZM11.7855 9.92003C10.5055 8.39114 10.6654 6.77333 10.5588 5.68892C10.4165 4.05336 10.1676 1.77781 7.10987 1.42225V0.888889C7.10987 0.408857 6.70102 0 6.20324 0C5.70546 0 5.31435 0.408857 5.31435 0.906635V1.44C2.23883 1.8134 2.00768 4.08892 1.86546 5.74222C1.75881 6.77333 1.90105 8.42673 0.638762 9.95555C-0.356761 11.1289 -0.090158 12.4267 0.763267 12.9777C1.97209 13.7244 4.0166 14.0266 6.22104 14.0089C8.42552 14.0266 10.4699 13.7422 11.6788 12.9777C12.5142 12.4088 12.7631 11.0933 11.7853 9.91997L11.7855 9.92003Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconBell
