import type { Maybe } from '@@types/helpers'
import type {
  CheckerOptionCodename,
  EntityGenericCheckerOption
} from '@app/entities/EntityGenericCheckerOption/types'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import type { ValueType } from '@libs/valueTypeParser/types'

/* Stores options */

export type TIStoreProfileCheckerSerieOptions =
  IStoreProfileCheckerSerieOptions<TGenericChecker, EntityGenericCheckerOption>

/**
 * Options of the StoreProfileCheckerSerieOptions store.
 */
export interface IStoreProfileCheckerSerieOptions<
  GC extends TGenericChecker,
  E extends EntityGenericCheckerOption
> {
  isProfileDirty: boolean

  /* Serie informations */

  // the current checker being modified
  checker: GC['entityType']
  // the directories associated to the serie
  directoryIds: Array<Maybe<number>>
  // all options (commit and staged for all configurations)
  checkerOptions: E[]
  // all options available for this checker
  allDirectoriesCheckerOptions: Map<CheckerOptionCodename, E>
  // options available for the serie
  serieCheckerOptions: Map<CheckerOptionCodename, E>
}

/**
 * Modals.
 */

export interface IModalProfileData {
  profileId: number
  profileName: string
}

/**
 * Forms Interfaces & Types.
 */

export interface ICheckerOptionFieldMeta {
  checkerId: number
  valueType: ValueType
  arraySelectSelection?: {
    value: ICheckerOptionArraySelectValue
    translations: string[]
  }
  staged: boolean
}

/**
 * Describe the value structure of array/select option.
 */
export interface ICheckerOptionArraySelectValue {
  choices: string[]
  chosen: string
}

/**
 * Field names to handle the O-MAX-CUSTOM-GROUPS-MEMBERS-AND-WHITELIST option.
 */
export enum CheckerOptionOMCGMAWConfigurationFieldName {
  distinguishedName = 'distinguishedName',
  nbUsersMax = 'nbUsersMax',
  usersWhiteList = 'usersWhiteList'
}

/**
 * Field names to handle the O-WHITELIST-CERTIFICATE-TEMPLATES option.
 */
export enum CheckerOptionOWCTConfigurationFieldName {
  displayName = 'displayName',
  trusteeId = 'trusteeId'
}

/**
 * Definition of fields for the O-MAX-CUSTOM-GROUPS-MEMBERS-AND-WHITELIST (OMCGMAW) checker option.
 * (Use in a context of ValueType.arrayObject)
 */
export interface ICheckerOptionOMCGMAWConfigurationValues {
  distinguishedName: string
  nbUsersMax: number
  usersWhiteList: string[]
}

/**
 * Definition of fields for the O-WHITELIST-CERTIFICATE-TEMPLATES (OWCTC) checker option.
 * (Use in a context of ValueType.arrayObject)
 */
export interface ICheckerOptionOWCTConfigurationValues {
  displayName: string
  trusteeId: string
}
