import { consts } from '@app/styles'
import * as React from 'react'
import { BulletCircle } from '.'

interface IBulletStatusDisabledProps {}

const BulletStatusDisabled: React.FC<IBulletStatusDisabledProps> = props => {
  return <BulletCircle color={consts.colorBlueGray015} />
}

export default BulletStatusDisabled
