import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '../Container'
import HeaderBreadcrumbCrumb from './HeaderBreadcrumbCrumb'

interface IHeaderBreadcrumbProps {
  className?: string
}

const StyledContainerFlex = styled(ContainerFlex)`
  width: 100%;
`

const HeaderBreadcrumb: React.FC<IHeaderBreadcrumbProps> = props => {
  const { storeBlades } = useStores()

  return (
    <StyledContainerFlex
      name="HeaderBreadcrumb"
      className={props.className}
      items={[
        <StyledContainerFlex
          name="HeaderBreadcrumbCrumbsBlades"
          items={storeBlades.bladesAsArray.map(blade => {
            const lastBlade = storeBlades.lastBlade
            const isLastBlade = lastBlade?.uuid === blade.uuid

            return (
              <HeaderBreadcrumbCrumb
                key={blade.uuid}
                selected={isLastBlade}
                blade={blade}
              />
            )
          })}
        />
      ]}
    />
  )
}

export default observer(HeaderBreadcrumb)
