import type { MaybeUndef } from '@@types/helpers'
import { interpolateTemplate } from '@alsid/common/helpers/template/interpolateTemplate'
import { ensureArray } from '@libs/ensureArray'
import { isDefined } from '@libs/isDefined'
import type { ILogger } from '@libs/logger'
import { Language } from '@server/graphql/typeDefs/types'
import { flatten } from 'flat'
import { castArray, unescape } from 'lodash'
import type {
  ITranslateKey,
  ITranslateNamespace,
  ITranslateOptions,
  ITranslations
} from './types'

const defaultLogger = console

const DELIMITER = '::'
const DEFAULT_LANGUAGE = Language.EnUs

export default class Translator {
  private defaultTranslateOptions: ITranslateOptions = {}
  private translations = new Map<string, string>()
  private preferredLanguages: Language[] = [DEFAULT_LANGUAGE]
  private preferredNamespaces: ITranslateNamespace = ['']
  private logger: ILogger = defaultLogger

  constructor(defaultTranslateOptions?: ITranslateOptions) {
    this.defaultTranslateOptions = {
      ...this.defaultTranslateOptions,
      ...defaultTranslateOptions
    }
  }

  getPreferredLanguages(): string[] {
    return this.preferredLanguages
  }

  getFirstPreferredLanguage(): Language {
    return this.preferredLanguages[0] || DEFAULT_LANGUAGE
  }

  setLogger(logger: ILogger): this {
    this.logger = logger
    return this
  }

  /**
   * Set preferred languages.
   */
  setPreferredLanguages(languages: Language | Language[]): this {
    this.preferredLanguages = castArray(languages)
    return this
  }

  /**
   * Set preferred namespaces.
   */
  setPreferredNamespaces(namespaces: ITranslateNamespace): this {
    this.preferredNamespaces = namespaces
    return this
  }

  /**
   * Add translations into the map.
   */
  addTranslations(language: Language, translations: ITranslations): this {
    // add the language is not already present
    if (this.preferredLanguages.indexOf(language) === -1) {
      this.preferredLanguages.push(language)
    }

    const flattenTranslations: { [key: string]: string } = flatten(
      translations,
      {
        delimiter: DELIMITER
      }
    )

    Object.keys(flattenTranslations).forEach(key => {
      const tKey = [language, key].join(DELIMITER)
      this.translations.set(tKey, flattenTranslations[key])
    })

    return this
  }

  /**
   * Translate a key for the current language.
   */
  translate(key: ITranslateKey, options?: ITranslateOptions): string {
    const finalOptions = { ...this.defaultTranslateOptions, ...options }

    let translation = this.findTranslation(key, finalOptions)

    // if no translation found, fallback to the last part of the key
    if (translation === undefined) {
      const stringifiedKey = castArray(key).join(DELIMITER)
      translation = stringifiedKey.split(DELIMITER).pop() || stringifiedKey
    }

    // interpolate if needed
    if (finalOptions && finalOptions.interpolations) {
      translation = this.interpolateTranslation(translation, finalOptions)
    }

    return translation
  }

  /**
   * Return a valid namespace path to be used as ITranslateOptions.namespace.
   */
  makeNamespace(namespaces: ITranslateNamespace): string {
    return namespaces.join(DELIMITER)
  }

  /**
   * Return true if some translations have been already saved.
   */
  isInitialized(): boolean {
    return this.translations.size > 0
  }

  /* Private */

  /**
   * Find the preferred translation according to the key,
   * preferred languages and preferred namespaces.
   */
  private findTranslation(
    key: ITranslateKey,
    options?: ITranslateOptions
  ): MaybeUndef<string> {
    const isDebug = options && options.debug

    // only for debug
    const tKeysLookedAt = []

    // try to find the preferred translation
    let result: MaybeUndef<string>
    const namespaces = this.getNamespaces(options)

    const languages = ensureArray(options?.language || this.preferredLanguages)

    for (const language of languages) {
      for (const namespace of namespaces) {
        const tKeys = this.computeTKeys(language, namespace, key, options)

        if (isDebug) {
          tKeysLookedAt.push(tKeys)
        }

        for (const tKey of tKeys) {
          result = this.translations.get(tKey)

          if (result !== undefined) {
            return result
          }
        }
      }
    }

    if (isDebug && tKeysLookedAt.length) {
      this.logger.warn(
        `[TranslatorStore] Missing key: "${tKeysLookedAt.join(', ')}"`
      )
    }

    return result
  }

  /**
   * Return all namespaces, preferred and passed ones.
   */
  public getNamespaces(options?: ITranslateOptions): string[] {
    const namespaces = this.preferredNamespaces

    const nestedNamespaces = this.nestedNamespace(namespaces)

    // if some namespaces have been passed, merge them into each preferred namespace
    if (options && options.namespaces) {
      const nestedPassedNamespaces = this.nestedNamespace(options.namespaces)
      const allNestedNamespaces: string[] = []

      for (const nestedNamespace of nestedNamespaces) {
        for (const nestedPassedNamespace of nestedPassedNamespaces) {
          const joinedNamespace = [nestedNamespace, nestedPassedNamespace]
            // filter empty namespaces
            .filter(v => !!v)
            .join(DELIMITER)

          allNestedNamespaces.push(joinedNamespace)
        }
      }

      return allNestedNamespaces
    }

    return nestedNamespaces
  }

  /**
   * Return nested namespaces.
   * e.g:
   * ['foo'] => ['foo']
   * ['foo.bar'] => ['foo${DELIMITER}bar']
   * ['foo.bar', 'baz'] => ['foo${DELIMITER}bar', 'baz']
   * ['foo.bar', ['baz.qux'] => ['foo${DELIMITER}bar', 'baz${DELIMITER}qux']
   */
  private nestedNamespace(namespaces: ITranslateNamespace): string[] {
    const allNamespaces = namespaces.reduce<string[]>((acc, namespace) => {
      const nestedNamespaces = namespace.split('.').join(DELIMITER)
      return acc.concat(nestedNamespaces)
    }, [])

    return allNamespaces
  }

  /**
   * Compute all tKeys in preferred orders, according to plurals.
   */
  private computeTKeys(
    language: Language,
    namespace: string,
    key: ITranslateKey,
    options?: ITranslateOptions
  ): string[] {
    if (!isDefined(key)) {
      return []
    }

    const tKeys = []

    const tKeyParts = [String(language)]

    if (namespace) {
      tKeyParts.push(namespace)
    }

    tKeyParts.push(...key.split('.'))

    // add tKey without quantity
    tKeys.unshift(tKeyParts.join(DELIMITER))

    if (
      options &&
      options.interpolations &&
      options.interpolations.count !== undefined
    ) {
      const count = Number(options.interpolations.count)

      // unshift tKey with plural quantity
      if (count > 1) {
        const tKeyPluralParts = tKeyParts.slice(0).concat('plural')
        tKeys.unshift(tKeyPluralParts.join(DELIMITER))
      }

      // unshift tKey with defined quantity
      tKeyParts.push(String(count))
      tKeys.unshift(tKeyParts.join(DELIMITER))
    }

    return tKeys
  }

  /**
   * Replace part of the translation with interpolations.
   */
  private interpolateTranslation(
    translation: string,
    options: ITranslateOptions
  ): string {
    // try/catch to avoid to raise an exception if some interpolations are undefined
    try {
      return unescape(
        interpolateTemplate(
          translation,
          options.interpolations ?? {},
          options.fallback
        )
      )
    } catch (err) {
      if (options.debug) {
        this.logger.warn(
          `[TranslatorStore] Missing interpolation in "${translation}". Received: ${JSON.stringify(
            options.interpolations,
            null,
            2
          )}`
        )
      }

      return translation
    }
  }
}
