import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import type { IManagementSystemonfigurationEmailsEditRouteDefinition } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToEmailAlertsConfiguration } from '../permissions'
import DrawerDeleteEmail from './DrawerDeleteEmail'
import ConfigurationEmailsCreatePage from './EmailCreatePage'
import ConfigurationEmailsEditPage from './EmailEditPage'
import EmailsList from './EmailsList'
import { onEmailsListLoad } from './handlers'
import { canCreateEmailAlerts, canEditEmailAlert } from './permissions'

interface IConfigurationEmailsPageProps {}

const ConfigurationEmailsPage: React.FC<
  IConfigurationEmailsPageProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.System.Configuration.Navigation']
  })

  const { storeManagementEmails } = useStores()

  return (
    <>
      <ContainerContent
        title={translate('Email alerts')}
        onLoad={onEmailsListLoad(storeManagementEmails)}
        flags={storeManagementEmails.storeFlags.flags}
        rbacCapabilityCheck={canAccessToEmailAlertsConfiguration}
      >
        <EmailsList />

        <Portal name={PlaceHolderName.genericDrawer}>
          <DrawerDeleteEmail />
        </Portal>
      </ContainerContent>

      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName:
                AppRouteName.Management_System_Configuration_Emails_Create,
              parameters: {}
            },
            component: ConfigurationEmailsCreatePage,
            rbacPermissionsCheck: () => canCreateEmailAlerts
          },
          {
            routeDefinition: {
              routeName:
                AppRouteName.Management_System_Configuration_Emails_Edit,
              parameters: {
                emailId: Number()
              }
            },
            component: ConfigurationEmailsEditPage,
            rbacPermissionsCheck: (
              params: IManagementSystemonfigurationEmailsEditRouteDefinition['parameters']
            ) => canEditEmailAlert(Number(params.emailId))
          }
        ]}
      />
    </>
  )
}

export default observer(ConfigurationEmailsPage)
