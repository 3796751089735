import * as React from 'react'
import styled from 'styled-components'

export interface ICellBodyContentProps {
  className?: string
  children: string
}

const CellBodyContent: React.FC<ICellBodyContentProps> = props => {
  return (
    <div className={props.className}>
      <pre>{props.children}</pre>
    </div>
  )
}

export default styled(CellBodyContent)`
  max-width: 500px;
  max-height: 500px;
  overflow: auto;
`
