import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperCheckbox } from '@app/components-legacy/Form'
import { InputSearch } from '@app/components-legacy/Input'
import {
  onReasonsSearch,
  onReasonsSelectAllClick
} from '@app/components-legacy/Input/InputReasons/handlers'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreInputReasons } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IDrawerHeaderProps {
  className?: string
  storeInputReasons: StoreInputReasons
}

const DrawerHeader: React.FC<IDrawerHeaderProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.InputReasons']
  })

  return (
    <ContainerFlex
      name="DrawerHeader"
      direction="column"
      items={[
        <InputSearch
          placeholder={translate('Search a reason')}
          onChange={onReasonsSearch(props.storeInputReasons)}
          storeInputSearch={props.storeInputReasons.storeInputSearch}
        />,

        <ContainerFlex
          name="ReasonFilters"
          className={props.className}
          items={[
            <FormWrapperCheckbox
              labelledBy="selectReasons"
              checkboxProps={{
                name: 'LabelReasonsChecker',
                onChange: onReasonsSelectAllClick(props.storeInputReasons),
                indeterminate: props.storeInputReasons.isSomeReasonsSelected,
                checked: props.storeInputReasons.isAllReasonsSelected
              }}
            >
              {props.storeInputReasons.storeInputSearch.hasSearchValue
                ? translate('Select found reasons')
                : translate('Select all reasons')}
            </FormWrapperCheckbox>
          ]}
          spaced
        />
      ]}
      spaced
    />
  )
}

export default observer(DrawerHeader)
