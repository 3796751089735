import * as React from 'react'
import styled from 'styled-components'

interface ITableProps {
  className?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  children?: React.ReactNode
}

const Table: React.FC<ITableProps> = props => {
  return (
    <div
      className={props.className}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <table>{props.children}</table>
    </div>
  )
}

export default styled(Table)`
  position: relative;

  table {
    width: 100%;
  }
`
