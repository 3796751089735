import type { IMainMenuEntry } from '@app/pages/MainMenu/types'
import * as React from 'react'
import styled from 'styled-components'
import WidgetMenuLink from '../WidgetMenuLink'
import WidgetSettingsHeader from './WidgetSettingsHeader'

const MenuItem = styled.div``

interface IWidgetSettingsPopoverProps {
  className?: string
  hidePopover: () => void
  settingsSubNavigationEntries: IMainMenuEntry[]
}

const WidgetSettingsPopover: React.FC<IWidgetSettingsPopoverProps> = props => {
  return (
    <div className={props.className}>
      <MenuItem>
        <WidgetSettingsHeader hidePopover={props.hidePopover} />
      </MenuItem>
      {props.settingsSubNavigationEntries.map(navigationEntry => (
        <MenuItem key={navigationEntry.key}>
          <WidgetMenuLink
            hidePopover={props.hidePopover}
            navigationEntry={navigationEntry}
          />
        </MenuItem>
      ))}
    </div>
  )
}

export default styled(WidgetSettingsPopover)`
  width: 282px;
`
