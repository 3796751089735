import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import type EntityAttack from '@app/entities/EntityAttack'
import { useAppTranslator, useStores } from '@app/hooks'
import {
  canAccessToAttackDescription,
  canAccessToAttackYaraRules
} from '@app/pages/IoA/IoAAttacksPage/permissions'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { filterFalsies } from '@libs/filterFalsies'
import * as React from 'react'
import IoAAttacksDescriptionTab from './IoAAttacksDescriptionTab'
import { AttacksDescriptionTabMenuKey } from './types'

interface IIoAAttacksTableBodyRowExpansionProps {
  attackEntity: EntityAttack
}

const IoAAttacksTableRowDetails: React.FC<
  IIoAAttacksTableBodyRowExpansionProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['IoA.Attacks']
  })

  const { storeRbac } = useStores()

  const storeMenu = useStoreMenuInit<IMenuEntry<AttacksDescriptionTabMenuKey>>({
    menuEntries: filterFalsies([
      storeRbac.isUserGrantedTo(canAccessToAttackDescription) && {
        key: AttacksDescriptionTabMenuKey.MenuAttackDescription,
        label: translate('Description'),
        labelledBy: 'Description'
      },

      storeRbac.isUserGrantedTo(canAccessToAttackYaraRules) && {
        key: AttacksDescriptionTabMenuKey.MenuAttackYaraRules,
        label: translate('YARA Detection Rules'),
        labelledBy: 'YARA Detection Rules'
      }
    ]),
    defaultSelectedMenuKey: storeRbac.isUserGrantedTo(
      canAccessToAttackDescription
    )
      ? AttacksDescriptionTabMenuKey.MenuAttackDescription
      : AttacksDescriptionTabMenuKey.MenuAttackYaraRules
  })

  return (
    <IoAAttacksDescriptionTab
      attackEntity={props.attackEntity}
      storeMenu={storeMenu}
    />
  )
}

export default IoAAttacksTableRowDetails
