import {
  ContainerFooter,
  ContainerRbac
} from '@app/components-legacy/Container'
import { handleDrawerOnOpen } from '@app/components-legacy/Drawer/handlers'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { IconDownloadOutlined } from '@app/components-legacy/Icon/IconAntd'
import { useAppTranslator } from '@app/hooks'
import { canAccessToIoAExport } from '@app/pages/IoA/IoABoardPage/permissions'
import * as React from 'react'
import { IoABoardPageContext } from '../context'

interface IIoABoardFooterProps {}

const IoABoardFooter: React.FC<IIoABoardFooterProps> = props => {
  const ioaBoardPageContext = React.useContext(IoABoardPageContext)

  const translate = useAppTranslator({ namespaces: ['IoA.Board'] })

  if (!ioaBoardPageContext) {
    return null
  }

  const { storeBoard } = ioaBoardPageContext

  return (
    <ContainerRbac rbacCapabilityCheck={canAccessToIoAExport}>
      <ContainerFooter>
        <FormWrapperButton
          labelledBy="export"
          buttonProps={{
            type: 'primary',
            icon: <IconDownloadOutlined />,
            onClick: handleDrawerOnOpen(storeBoard.storeDrawerExportCards)
          }}
        >
          {translate('Export')}
        </FormWrapperButton>
      </ContainerFooter>
    </ContainerRbac>
  )
}

export default IoABoardFooter
