import { Features } from '@alsid/common'
import { useHistoryChange } from '@app/components-legacy/Router/hooks'
import { useAppRouter, useStores } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { isTelemetryDisableOnRoute } from '@libs/telemetry/utils'
import * as React from 'react'

/**
 * Handle telemetry when the page is not visible anymore
 * (user changed tab, user closed the browser, user switched to another app).
 */
export function useVisibilityChangeTelemetry() {
  const featureTelemetry = useFeatureFlag({
    featureFlagName: Features.TELEMETRY
  })

  const appRouter = useAppRouter()
  const { storeTelemetry } = useStores()
  const { currentRouteInfo } = useHistoryChange()

  const currentRouteName = currentRouteInfo?.routeName || null
  const isTelemetryDisableOnRouteFn = isTelemetryDisableOnRoute(appRouter)

  // Handles telemetry for `visibilitychange` event
  const onHandlerVisibilityChange = React.useCallback(() => {
    if (!featureTelemetry) {
      return
    }

    if (!currentRouteName) {
      return
    }

    if (isTelemetryDisableOnRouteFn(currentRouteName)) {
      return
    }

    if (document.visibilityState === 'visible') {
      storeTelemetry.logPageVisited(currentRouteName)
      return
    }
    storeTelemetry.logPageLeft(currentRouteName)
  }, [currentRouteName])

  React.useEffect(() => {
    document.addEventListener('visibilitychange', onHandlerVisibilityChange)

    return () => {
      document.removeEventListener(
        'visibilitychange',
        onHandlerVisibilityChange
      )
    }
  }, [currentRouteName])
}
