import CardDecorationTriangle from '@app/components-legacy/Card/CardDecorationTriangle'
import * as React from 'react'

interface IAlertTriangleIconProps {
  color: string
  children?: React.ReactNode
}

const AlertTriangleIcon: React.FC<IAlertTriangleIconProps> = props => {
  return (
    <CardDecorationTriangle
      name="AlertTriangleIcon"
      backgroundColor={props.color}
    >
      {props.children}
    </CardDecorationTriangle>
  )
}

export default AlertTriangleIcon
