import Blade from '@app/components-legacy/Blade/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canCreateDashboardWidget } from '../permissions'
import { onWidgetAddPageLoad, onWidgetAddPageUnload } from './handlers'
import WidgetAddForm from './WidgetAddForm'

interface IWidgetAddPageProps {}

const WidgetAddPage: React.FC<IWidgetAddPageProps> = props => {
  const appRouter = useAppRouter()

  const { storeDashboards } = useStores()

  const storeDashboard = storeDashboards.currentStoreDashboard

  if (!storeDashboard) {
    return null
  }

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Dashboard_Grid,
    parameters: {
      instanceName: String(),
      dashboardId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Dashboard_Grid_WidgetAdd,
        parameters: {
          instanceName: parameters.instanceName,
          dashboardId: parameters.dashboardId
        }
      }}
      onLoad={onWidgetAddPageLoad(storeDashboards)(parameters.dashboardId)}
      onUnload={onWidgetAddPageUnload(storeDashboard)}
      flags={storeDashboard.storeWidgetsManagement.storeFlags.flags}
      layout={{
        name: 'default',
        content: (
          <ContainerContent>
            <WidgetAddForm storeDashboard={storeDashboard} />
          </ContainerContent>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canCreateDashboardWidget}
    />
  )
}

export default observer(WidgetAddPage)
