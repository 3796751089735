import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelAlt, LabelGauge } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { isDefined } from '@libs/isDefined'
import { Tooltip } from 'antd'
import * as React from 'react'
import type { ILabelCheckerProps } from './types'

interface ILabelCheckerGaugeProps<GC extends TGenericChecker>
  extends ILabelCheckerProps<GC> {}

function LabelCheckerGauge<GC extends TGenericChecker>(
  props: ILabelCheckerGaugeProps<GC>
): Maybe<React.ReactElement> {
  const translate = useAppTranslator({
    namespaces: ['Components.LabelChecker']
  })

  if (!isDefined(props.complexityValue)) {
    return null
  }

  return (
    <Tooltip title={translate('Difficulty to remediate the deviant IoE')}>
      <div>
        <ContainerFlex
          name="LabelCheckerGauge"
          alignItems="center"
          items={[
            <LabelAlt>{translate('Complexity')}</LabelAlt>,
            <LabelGauge remediationCost={props.complexityValue} />
          ]}
          spaced
          spaceWidth="verySmall"
        />
      </div>
    </Tooltip>
  )
}

export default LabelCheckerGauge
