import type { IFieldSanitizor } from '@app/stores/helpers/StoreForm/types'

/**
 * Trim the field value.
 *
 * Usage:
 * field('firstName').addSanitizor(trim())
 */
export const trim = (sanitizor?: Partial<IFieldSanitizor>): IFieldSanitizor => {
  const defaultSanitizor: IFieldSanitizor = {
    name: 'trim',
    sanitize: value => String(value || '').trim()
  }

  return {
    ...defaultSanitizor,
    ...sanitizor
  }
}

/**
 * Transform slashes to pipes.
 *
 * Usage:
 * field('profileName').addSanitizor(transformSlashesToPipes())
 */
export const transformSlashesToPipes = (
  sanitizor?: Partial<IFieldSanitizor>
): IFieldSanitizor => {
  const defaultSanitizor: IFieldSanitizor = {
    name: 'transformSlashesToPipes',
    sanitize: value => String(value || '').replace(/\//g, '|')
  }

  return {
    ...defaultSanitizor,
    ...sanitizor
  }
}
