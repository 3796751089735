import { Features } from '@alsid/common'
import { ForbiddenAccessError } from '@libs/errors'
import { isDefined } from '@libs/isDefined'
import { checkRbac } from '@libs/rbac/functions'
import type { QueryTracesFiltersData } from '@server/graphql/queries/tracesFiltersData'
import { queryTracesFiltersData } from '@server/graphql/queries/tracesFiltersData'
import type { UserTraceLogType } from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '..'
import StoreDrawer from '../helpers/StoreDrawer'
import StoreFlags from '../helpers/StoreFlags'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'

/**
 * Store in charge of configuring the ip/user/action filters
 */
export default class StoreActivityLogsFilters extends StoreBase {
  /* Flags */
  public storeFlagsFetchIPs = new StoreFlags(this.storeRoot)
  public storeDrawerFilters = new StoreDrawer<{
    selectedIPs: Set<string>
    selectedUserEmails: Set<string>
    selectedLogTypes: Set<UserTraceLogType>
  }>(this.storeRoot)
  public storeFlagsFetchUserEmails = new StoreFlags(this.storeRoot)

  /* Observables */
  private $allIps = observable.array<string>()
  private $selectedIps = observable.array<string>()

  private $selectedLogTypes = observable.array<UserTraceLogType>()

  private $allUserEmails = observable.array<string>()
  private $selectedUserEmails = observable.array<string>()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch the all the IPs and user emails
   */
  public fetchFilterData(): Promise<void> {
    this.storeFlagsFetchIPs.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryTracesFiltersData>(queryTracesFiltersData, null, {
            keepGraphQLError: true
          })
      })
      .then(({ tracesFiltersData }) => {
        if (
          !checkRbac(this.storeRoot, this.storeFlagsFetchIPs)(tracesFiltersData)
        ) {
          throw new ForbiddenAccessError()
        }

        if (!tracesFiltersData) {
          throw new Error()
        }
        const { node } = tracesFiltersData
        const { allUserIps, allUserEmails } = node
        if (isDefined(allUserIps)) {
          this.setAllIPs(allUserIps)
        }
        if (isDefined(allUserEmails)) {
          this.setAllUserEmails(allUserEmails)
        }
      })
      .then(() => {
        this.storeFlagsFetchIPs.success()
      })
  }

  /* Actions */

  @action
  setAllIPs(allIPs: string[]): this {
    this.$allIps.replace(allIPs)
    return this
  }

  @action
  setSelectedIPs(ips: string[]): this {
    this.$selectedIps.replace(ips)
    return this
  }

  @action
  setAllUserEmails(allUserEmails: string[]): this {
    this.$allUserEmails.replace(allUserEmails)
    return this
  }

  @action
  setSelectedUserEmails(userEmails: string[]): this {
    this.$selectedUserEmails.replace(userEmails)
    return this
  }

  @action
  setSelectedLogTypes(logTypes: UserTraceLogType[]): this {
    this.$selectedLogTypes.replace(logTypes)
    return this
  }

  @action
  applyDrawerFormFilters(): this {
    const data = this.storeDrawerFilters.data

    this.setSelectedIPs(Array.from(data?.selectedIPs?.values() ?? []))
    this.setSelectedUserEmails(
      Array.from(data?.selectedUserEmails?.values() ?? [])
    )
    this.setSelectedLogTypes(Array.from(data?.selectedLogTypes?.values() ?? []))

    return this
  }

  /**
   * Reset store
   */
  @action
  reset(): this {
    this.$allIps.clear()
    this.$selectedIps.clear()
    this.$allUserEmails.clear()
    this.$selectedUserEmails.clear()
    this.$selectedLogTypes.clear()
    return this
  }

  /* Computed */

  /**
   * Return all the user IPs.
   */

  @computed
  get allIPs(): string[] {
    return Array.from(this.$allIps)
  }

  /**
   * Return all the user emails.
   */

  @computed
  get allUserEmails(): string[] {
    return Array.from(this.$allUserEmails)
  }

  /**
   * Return all the selected IPs.
   */

  @computed
  get selectedIPs(): string[] {
    return Array.from(this.$selectedIps)
  }

  /**
   * Return all the selected Users.
   */

  @computed
  get selectedUserEmails(): string[] {
    return Array.from(this.$selectedUserEmails)
  }

  /**
   * Return all the selected logTypes
   */

  @computed
  get selectedLogTypes(): UserTraceLogType[] {
    return Array.from(this.$selectedLogTypes)
  }

  /**
   * Return the number of selected filters
   */
  @computed
  get selectedFiltersCount(): number {
    return (
      this.selectedIPs.length +
      this.selectedUserEmails.length +
      this.selectedLogTypes.length
    )
  }

  @computed
  get canShowByUserFilter(): boolean {
    return (
      this.$allUserEmails.length >= 2 &&
      this.storeRoot.stores.storeRbac.isUserGrantedAccordingFeatureFlag(
        Features.ACTIVITY_LOGS_USERS_FILTERING
      )
    )
  }
}
