import {
  ContainerContent,
  ContainerFlex,
  ContainerRbac
} from '@app/components-legacy/Container'
import { TFlexValuePreset } from '@app/components-legacy/Container/ContainerFlex/types'
import { useStores } from '@app/hooks/useStores'
import AttackerKnownTools from '@app/pages/IoE/IoECheckerDetailsPage/DetailsInformationsPage/AttackerKnownTools'
import ExecutiveSummary from '@app/pages/IoE/IoECheckerDetailsPage/DetailsInformationsPage/ExecutiveSummary'
import { ensureArray } from '@libs/ensureArray'
import * as React from 'react'
import { CheckerDetailsPageContext } from '../context'
import {
  canAccessToIoEDeviantObjects,
  canReadIoEInformation
} from '../permissions'
import Documents from './Documents'
import ImpactedDomains from './ImpactedDomains'

interface IDetailsInformationsPageProps {}

const DetailsInformationsPage: React.FC<
  IDetailsInformationsPageProps
> = props => {
  const parameters = React.useContext(CheckerDetailsPageContext)

  const { storeIoE } = useStores()

  if (!parameters) {
    return null
  }

  const directoryIds = ensureArray(
    storeIoE.storeIndicator.checkerExposure?.getDeviancesDirectoryIds()
  )

  return (
    <ContainerContent
      rbacCapabilityCheck={canReadIoEInformation(parameters.checkerId)}
    >
      <ContainerFlex
        name="DetailsInformationsPage"
        items={[
          <ContainerFlex
            name="DetailsInformationPageLeftColumn"
            direction="column"
            flexValuePreset={TFlexValuePreset.twoColumns}
            items={[
              <ExecutiveSummary />,
              <Documents />,
              <AttackerKnownTools />
            ]}
            wrapItems
            spaced
            spaceWidth="default"
            borderPadding="default"
          />,

          <ContainerFlex
            name="DetailsInformationPageRightColumn"
            direction="column"
            flexValuePreset={TFlexValuePreset.twoColumns}
            items={[
              directoryIds.length && (
                <ContainerRbac
                  rbacCapabilityCheck={canAccessToIoEDeviantObjects(
                    parameters.checkerId
                  )}
                >
                  <ImpactedDomains directoryIds={directoryIds} />
                </ContainerRbac>
              )
            ]}
            wrapItems
            spaced
            spaceWidth="default"
            borderPadding="default"
          />
        ]}
        spaced
      />
    </ContainerContent>
  )
}

export default DetailsInformationsPage
