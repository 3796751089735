import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useStores } from '@app/hooks'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import ModalRefreshReportAccessToken from 'app/pages/Management/SystemPage/ConfigurationPage/ConfigurationReportingCenterPage/ModalRefreshReportAccessToken'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToReportingCenter } from '../permissions'
import ConfigurationReportsFirstReport from './ConfigurationReportsFirstReport'
import ConfigurationReportsList from './ConfigurationReportsList'
import DrawerDeleteReport from './DrawerDeleteReport'
import DrawerReportAction from './DrawerReportAction'
import { handleConfigurationReportingOnLoad } from './handlers'

export interface IConfigurationReportingCenterPageProps {}

function ConfigurationReportingCenterPage(
  props: IConfigurationReportingCenterPageProps
) {
  const { storeManagementReportingCenter, storeManagementProfiles } =
    useStores()

  function renderContent() {
    if (
      !storeManagementReportingCenter.storeWidgetList.entitiesAsArray.length
    ) {
      return <ConfigurationReportsFirstReport />
    }

    return <ConfigurationReportsList />
  }

  return (
    <DSThemeProvider themeName={DSThemeName.default}>
      <ContainerContent
        onLoad={handleConfigurationReportingOnLoad(
          storeManagementReportingCenter
        )(storeManagementProfiles)}
        flags={[
          storeManagementReportingCenter.storeInputCheckersExposure
            .storeFlagsFetchCheckers.flags,
          storeManagementReportingCenter.storeInputCheckersAttacks
            .storeFlagsFetchCheckers.flags,
          storeManagementReportingCenter.storeInfrastructures
            .storeFlagsFetchInfrastructures.flags,
          storeManagementReportingCenter.storeFlagsFetchReport.flags,
          storeManagementReportingCenter.storeFlagsFetchReportAccessToken.flags,
          storeManagementProfiles.storeFlagsFetchProfiles.flags
        ]}
        rbacCapabilityCheck={canAccessToReportingCenter}
      >
        {renderContent()}
      </ContainerContent>

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerReportAction />
      </Portal>

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerDeleteReport />
      </Portal>

      <ModalRefreshReportAccessToken />
    </DSThemeProvider>
  )
}

export default observer(ConfigurationReportingCenterPage)
