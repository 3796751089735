import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperCheckbox } from '@app/components-legacy/Form'
import { InputSearch } from '@app/components-legacy/Input'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreInputTenants } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleTenantsSearch, handleTenantsSelectAllOnClick } from '../handlers'

interface IDrawerHeaderProps {
  className?: string
  storeInputTenants: StoreInputTenants
}

const DrawerHeader: React.FC<IDrawerHeaderProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.InputTenants']
  })

  return (
    <ContainerFlex
      name="DrawerHeader"
      direction="column"
      items={[
        <InputSearch
          placeholder={translate('Search a tenant')}
          onChange={handleTenantsSearch(props.storeInputTenants)}
          storeInputSearch={props.storeInputTenants.storeInputSearch}
        />,

        <ContainerFlex
          name="TenantFilters"
          className={props.className}
          items={[
            <FormWrapperCheckbox
              labelledBy="selectTenants"
              checkboxProps={{
                name: 'LabelTenantsChecker',
                onChange: handleTenantsSelectAllOnClick(
                  props.storeInputTenants
                ),
                indeterminate: props.storeInputTenants.isSomeTenantsSelected,
                checked: props.storeInputTenants.isAllTenantsSelected
              }}
            >
              {props.storeInputTenants.storeInputSearch.hasSearchValue
                ? translate('Select found tenants')
                : translate('Select all tenants')}
            </FormWrapperCheckbox>
          ]}
          spaced
        />
      ]}
      spaced
    />
  )
}

export default observer(DrawerHeader)
