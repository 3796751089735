import type { Maybe } from '@@types/helpers'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  createProfile,
  deleteProfile,
  editProfile,
  readLicenseFeatureIoA,
  readLicenseFeatureIoE,
  readProfiles,
  readUIAccountsProfiles
} from '@libs/rbac/permissions'
import { canAccessToAccounts } from '../permissions'

export const canAccessToProfiles = rbacCapabilityCheckAllOf(
  canAccessToAccounts,
  readUIAccountsProfiles()
)

export const canReadProfile = (profileId: Maybe<number>) =>
  rbacCapabilityCheckAllOf(canAccessToProfiles, readProfiles(profileId))

export const canCreateProfile = rbacCapabilityCheckAllOf(
  canAccessToProfiles,
  createProfile()
)

export const canEditProfile = (profileId: number) =>
  rbacCapabilityCheckAllOf(canAccessToProfiles, editProfile(profileId))

export const canEditProfileIoE = (profileId: number) =>
  rbacCapabilityCheckAllOf(canEditProfile(profileId), readLicenseFeatureIoE())

export const canEditProfileIoA = (profileId: number) =>
  rbacCapabilityCheckAllOf(canEditProfile(profileId), readLicenseFeatureIoA())

export const canDeleteProfile = (profileId: Maybe<number>) =>
  rbacCapabilityCheckAllOf(canAccessToProfiles, deleteProfile(profileId))
