import ContainerHeader from '@app/components-legacy/Container/ContainerHeader'
import * as React from 'react'
import { AttacksPageContext } from '../context'
import IoAAttacksFilters from './IoAAttacksFilters'

interface IIoAAttacksTableSubHeaderProps {}

const IoAAttacksHeaderSub: React.FC<IIoAAttacksTableSubHeaderProps> = props => {
  const attacksPageContext = React.useContext(AttacksPageContext)

  if (!attacksPageContext) {
    return null
  }

  return (
    <ContainerHeader>
      <IoAAttacksFilters />
    </ContainerHeader>
  )
}

export default IoAAttacksHeaderSub
