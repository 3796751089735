import { useAppTranslator } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

export interface IInputTextProps {
  className?: string
  inError?: boolean
  disabled?: boolean
  value: string
  onChange?: (value: string) => void
  onBlur?: () => void
  onFocus?: () => void
  onSubmit?: (force?: boolean) => void
}

const InputText: React.FC<IInputTextProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.InputExpression']
  })

  /**
   * When pressing "back keys" and if the value is empty,
   * act as a change to be able to edit the expression.
   */
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const backKeys = ['Backspace', 'ArrowLeft']
    const isBackKey = backKeys.indexOf(e.key) !== -1
    const isEmpty = props.value === ''

    if (props.onChange && isBackKey && isEmpty) {
      props.onChange('')
    }

    if (!isEmpty && props.onSubmit && e.key === 'Enter') {
      // Avoid whole form submission behavior
      e.preventDefault()

      props.onSubmit(true)
    }
  }

  /**
   * Handle each change when typing some text.
   */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      const value = e.target.value
      props.onChange(value)
    }
  }

  /**
   * When leaving the field, act as a validation.
   */
  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (props.onBlur) {
      props.onBlur()
    }
  }

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (props.onFocus) {
      props.onFocus()
    }
  }

  const renderInput = () => {
    if (props.disabled === true) {
      return null
    }

    return (
      <input
        type="text"
        name="expression"
        placeholder={translate('Type an expression')}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onBlur={onBlur}
        value={props.value}
        autoComplete="off"
      />
    )
  }

  return <div className={props.className}>{renderInput()}</div>
}

const ObservedInputText = observer(InputText)

export default styled(ObservedInputText)`
  width: 100%;

  input {
    font-family: 'Source Code Pro';
    font-size: 0.9em;
    background: transparent;
    width: 100%;
    border: none;
    color: ${props =>
      props.inError
        ? props.theme.tokens['input/color/textError']
        : props.disabled
          ? props.theme.tokens['input/color/textDisabled']
          : props.theme.tokens['input/color/text']};

    &::placeholder {
      color: ${props => props.theme.tokens['input/color/placeholder']};
    }

    &:focus {
      outline: none;
    }
  }
`
