import {
  rbacCapabilityCheckAllOf,
  rbacCapabilityCheckAnyOf
} from '@libs/rbac/functions'
import {
  readAttackTypes,
  readCheckers,
  readLicenseFeatureIoA,
  readLicenseFeatureIoE,
  readUIAlerts,
  readUIAlertsArchive
} from '@libs/rbac/permissions'

export const canReadAlertsIoE = rbacCapabilityCheckAllOf(
  readLicenseFeatureIoE(),
  readUIAlerts(),
  readCheckers(null)
)

export const canReadAlertsIoA = rbacCapabilityCheckAllOf(
  readLicenseFeatureIoA(),
  readUIAlerts(),
  readAttackTypes(null)
)

export const canReadAlerts = rbacCapabilityCheckAnyOf(
  canReadAlertsIoE,
  canReadAlertsIoA
)

export const canReadArchivedAlerts = rbacCapabilityCheckAllOf(
  canReadAlerts,
  readUIAlertsArchive()
)
