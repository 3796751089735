import * as React from 'react'
import { ContainerDivider } from '..'

interface IContainerDividerFilterProps {}

const ContainerDividerFilter: React.FC<
  IContainerDividerFilterProps
> = props => {
  return <ContainerDivider type={{ name: 'vertical', height: 25 }} />
}

export default ContainerDividerFilter
