import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import * as React from 'react'
import { canCreateAccountUsers } from '../UsersPage/permissions'
import {
  handleUserCreationCancelOnClick,
  handleUserCreationOnLoad,
  handleUserCreationOnSubmit
} from './handlers'
import UserCommonForm from './UserCommonForm'

interface IUserCreateProps {}

const UserCreate: React.FC<IUserCreateProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.Accounts.Users']
  })

  const appRouter = useAppRouter()

  const {
    storeMessages,
    storeBlades,
    storeManagementUsers,
    storeRbac,
    storeAbout
  } = useStores()

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_Accounts_Users_Create,
        parameters: {}
      }}
      onLoad={handleUserCreationOnLoad(storeManagementUsers)}
      flags={[
        storeManagementUsers.storeFetchUsersFlags.flags,
        storeManagementUsers.storeCreateUserFlags.flags,
        storeManagementUsers.storeSetRolesFlags.flags
      ]}
      layout={{
        name: 'default',
        content: (
          <UserCommonForm
            storeForm={storeManagementUsers.storeForm}
            version="creation"
            onCancelButtonClick={handleUserCreationCancelOnClick(storeBlades)}
            onSubmit={handleUserCreationOnSubmit(
              storeMessages,
              storeRbac,
              storeManagementUsers
            )(translate, appRouter)}
          />
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canCreateAccountUsers(storeRbac)}
    />
  )
}

export default UserCreate
