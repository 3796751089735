import { LabelSliced } from '@app/components-legacy/Label'
import * as React from 'react'
import styled from 'styled-components'

export interface ICellUrlProps {
  className?: string
  children: string
}

const CellUrl: React.FC<ICellUrlProps> = props => {
  return (
    <div className={props.className}>
      <LabelSliced
        maxLength={80}
        value={props.children}
        popoverType="popover"
      />
    </div>
  )
}

export default styled(CellUrl)`
  width: 500px !important;
`
