import type { EntityTopologyInfrastructure } from '@app/entities'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { SCENE_TOOLIP_DEBOUNCE_DELAY } from '@app/stores/Topology/consts'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import type SceneObject from './SceneObject'

interface IInfrastructureLabelProps {
  className?: string
  infrastructureSceneObject: SceneObject<EntityTopologyInfrastructure>
  isHighlighted?: boolean
  isTransparent?: boolean
}

const InfrastructureLabel: React.FC<IInfrastructureLabelProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Topology.Chart']
  })

  const { storeTopology } = useStores()
  const isZoomed = storeTopology.chartZoomData.k > 0.8
  const isZoomedOut = storeTopology.chartZoomData.k < 0.5

  const { x, y, radius } = props.infrastructureSceneObject.coordinates
  const { name, known } = props.infrastructureSceneObject.object

  const className = `${props.className}
  ${props.isHighlighted ? 'isHighlighted' : ''}
  ${isZoomed ? 'zoomed' : ''}
  ${isZoomedOut ? 'zoomedOut' : ''}`

  return (
    <>
      {!known && (
        <text className={`${props.className} isUnknown`} x={x} y={y + 15}>
          ?
        </text>
      )}
      <text
        filter={known ? 'url(#highlight)' : 'url(#highlight-unknown)'}
        className={className}
        x={x}
        y={y + ((radius || 0) + 25)}
      >
        {name || translate('Unknown')}
      </text>
      {/* duplicate text without filter to avoid blurry effect */}
      <text className={className} x={x} y={y + ((radius || 0) + 25)}>
        {name || translate('Unknown')}
      </text>
    </>
  )
}

const ObservedInfrastructureLabel = observer(InfrastructureLabel)

export default styled(ObservedInfrastructureLabel)`
  text-anchor: middle;
  font-size: 16px;
  fill: ${consts.colorWhite};
  transition: all ${SCENE_TOOLIP_DEBOUNCE_DELAY}ms linear;

  &.isHighlighted {
    font-size: 18px;
  }

  &.zoomed {
    font-size: 14px;
    &.isHighlighted {
      font-size: 16px;
    }
  }

  &.zoomedOut {
    font-size: 20px;
    &.isHighlighted {
      font-size: 22px;
    }
  }

  &.isUnknown {
    fill: ${consts.colorBlueGray015};
    font-size: 30px;
  }

  opacity: ${props => (props.isTransparent ? '0.2' : '1')};
`
