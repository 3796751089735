import { BladesContainer } from '@app/components-legacy/Blade'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LayoutDefault } from '@app/components-legacy/Layout'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import * as React from 'react'
import HealthCheckBlade from './HealthCheckBlade'

interface IHealthCheckProps {}

const HealthCheck: React.FC<IHealthCheckProps> = props => {
  return (
    <DSThemeProvider themeName={DSThemeName.default}>
      <LayoutDefault
        content={
          <ContainerFlex
            name="HealthCheck"
            items={[
              <BladesContainer>
                <HealthCheckBlade />
              </BladesContainer>
            ]}
          />
        }
      />
    </DSThemeProvider>
  )
}

export default HealthCheck
