import type { ReactComponent } from '@@types/helpers'
import type { IconComponentProps } from '@ant-design/icons/lib/components/AntdIcon'
import { ContainerFlex } from '@app/components/Container'
import type { TContainerFlexSpaceWidth } from '@app/components/Container/ContainerFlex/types'
import * as React from 'react'

export interface ILabelIconProps {
  iconComponent: ReactComponent
  iconProps?: Omit<IconComponentProps, 'icon'>
  spaceWidth?: TContainerFlexSpaceWidth
  children?: React.ReactNode
}

const LabelIcon: React.FC<ILabelIconProps> = props => {
  return (
    <ContainerFlex
      name="LabelIcon"
      alignItems="center"
      items={[
        <props.iconComponent {...props.iconProps} />,
        <div>{props.children}</div>
      ]}
      flexGap={props.spaceWidth ?? 'verySmall'}
    />
  )
}

export default LabelIcon
