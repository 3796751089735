import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import DirectoryCommonForm from '../DirectoriesCreatePage/DirectoryCommonForm'
import {
  onDirectoriesEditCancelClick,
  onDirectoriesEditSubmit
} from './handlers'

interface IDirectoryEditFormProps {
  directoryId: number
}

const DirectoryEditForm: React.FC<IDirectoryEditFormProps> = props => {
  const appRouter = useAppRouter()

  const { storeManagementDirectories, storeBlades } = useStores()

  return (
    <DirectoryCommonForm
      version="edition"
      onCancelButtonClick={onDirectoriesEditCancelClick(storeBlades)}
      onSubmit={onDirectoriesEditSubmit(
        appRouter,
        storeManagementDirectories
      )(props.directoryId)}
    />
  )
}

export default DirectoryEditForm
