import Blade from '@app/components-legacy/Blade/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  handleHealthCheckPlatformStatusDetailsOnLoad,
  handleHealthCheckPlatformStatusDetailsOnUnload
} from '../HealthCheckPlatformStatus/handlers'
import { canAccessToHealthCheck } from '../permissions'
import { HealthCheckDetailsContext } from './context'
import HealthCheckDetailsHeader from './HealthCheckDetailsHeader'
import HealthCheckPlatformStatusDetailsInformationPage from './HealthCheckPlatformStatusDetailsInformationPage'

interface IHealthCheckPlatformStatusDetailsPageProps {}

const HealthCheckPlatformStatusDetailsPage: React.FC<
  IHealthCheckPlatformStatusDetailsPageProps
> = () => {
  const appRouter = useAppRouter()
  const { storeHealthCheck, storeInfrastructures } = useStores()

  const informationParameters = appRouter.getRouteParameters({
    routeName: AppRouteName.HealthCheck_PlatformStatus_Details_Information,
    parameters: {
      healthCheckName: String()
    }
  })

  const parameters = informationParameters

  if (!parameters) {
    return null
  }

  return (
    <HealthCheckDetailsContext.Provider
      value={{
        healthCheckName: parameters.healthCheckName
      }}
    >
      <Blade
        routeDefinition={{
          routeName: AppRouteName.HealthCheck_PlatformStatus_Details,
          parameters: {
            healthCheckName: parameters.healthCheckName
          }
        }}
        onLoad={handleHealthCheckPlatformStatusDetailsOnLoad(
          storeHealthCheck,
          parameters.healthCheckName
        )}
        onUnload={handleHealthCheckPlatformStatusDetailsOnUnload(
          storeHealthCheck
        )}
        flags={[
          storeInfrastructures.storeFlagsFetchInfrastructures.flags,
          storeHealthCheck.storeFlagsFetchPlatformStatusDetails.flags
        ]}
        layout={{
          name: 'default',
          header: (
            <HealthCheckDetailsHeader
              healthCheckDetails={
                storeHealthCheck.healthCheckPlatformStatusDetails
              }
            />
          ),
          content: (
            <RbacRoutes
              routes={[
                {
                  routeDefinition: {
                    routeName:
                      AppRouteName.HealthCheck_PlatformStatus_Details_Information,
                    parameters: {
                      healthCheckName: String()
                    }
                  },
                  component: HealthCheckPlatformStatusDetailsInformationPage,
                  rbacPermissionsCheck: () => canAccessToHealthCheck
                }
              ]}
              redirect
            />
          ),
          footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
        }}
        rbacCapabilityCheck={canAccessToHealthCheck}
      />
    </HealthCheckDetailsContext.Provider>
  )
}

export default observer(HealthCheckPlatformStatusDetailsPage)
