export enum ReportsFieldName {
  uuid = 'uuid',
  accesstoken = 'accesstoken'
}

export interface IReportInputParameters {
  uuid: string
  accesstoken: string
  reportName: string
}
