import { useTestAttribute } from '@app/hooks/useTestAttribute'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import type { FieldName } from '@app/stores/helpers/StoreForm/types'
import { consts } from '@app/styles'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { Input } from 'antd'
import type { InputProps } from 'antd/es/input'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled, { css } from 'styled-components'

interface IWidgetFormInputProps {
  /** Field name */
  fieldName: FieldName
  /** Input id */
  inputId: string
  /** Props that will be passed to the rendered component */
  inputProps?: InputProps
  /** StoreForm to get data */
  storeForm: StoreForm<any>
}

const StyledInput = styled(Input)`
  ${props => {
    return (
      !isThemeLegacy(props.theme) &&
      css`
        border: 0;
        border-radius: ${consts.borderRadiusSmallRedesign};
        background-color: ${props.theme.tokens[
          'input/backgroundColor/default'
        ]};
        color: ${props.theme.tokens['input/color/text']};
        height: ${props.theme.tokens['input/height/default']};
        padding: ${props.theme.tokens['input/padding/default']};

        &::placeholder {
          color: ${props.theme.tokens['input/color/placeholder']};
        }

        &.ant-input:focus,
        &.ant-input-focused,
        &.ant-input-affix-wrapper:focus,
        &.ant-input-affix-wrapper-focused {
          border: none;
          box-shadow: none;
        }
      `
    )
  }}
`

/**
 * @deprecated
 */
const WidgetFormInput: React.FC<IWidgetFormInputProps> = props => {
  const { testAttributeProps } = useTestAttribute('form')

  return (
    <StyledInput
      type="text"
      id={props.inputId}
      name={props.fieldName}
      value={props.storeForm.getFieldValueAsString(props.fieldName)}
      autoComplete="off"
      onChange={onInputChange(props.storeForm)(props.fieldName)}
      {...props.inputProps}
      {...testAttributeProps('textbox')(props.fieldName)}
    />
  )
}

export default observer(WidgetFormInput)
