import { ContainerFlex } from '@app/components/Container'
import { LabelNonBreakable } from '@app/components/Labels'
import LabelEmail from '@app/components-legacy/Label/LabelEmail'
import { useAppTranslator } from '@app/hooks'
import { buildVariants } from '@design-system/libs/buildVariants'
import { filterFalsies } from '@libs/filterFalsies'
import * as React from 'react'
import styled from 'styled-components'

export interface IEmailsTagsProps {
  emails: string[]
}

const StyledContainerFlex = styled(ContainerFlex)(props => {
  return buildVariants(props)
    .css({
      // force emails to be on 2 lines if more than 2
      width:
        Array.isArray(props.items) && filterFalsies(props.items).length > 1
          ? '300px'
          : undefined
    })
    .end()
})

const StyledLabelEmail = styled(LabelEmail)(props => {
  return buildVariants(props)
    .css({
      margin: '2px'
    })
    .end()
})

export default function EmailsTags(props: IEmailsTagsProps) {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.ReportingCenter']
  })

  const emailsCopy = props.emails.slice(0)
  // X emails displayed, the rest displayed as "X more"
  const displayedEmails = emailsCopy.splice(0, 3)

  return (
    <StyledContainerFlex
      name="emails"
      alignItems="center"
      flexWrap="wrap"
      items={[
        ...displayedEmails.map(recipientEmails => {
          return (
            <StyledLabelEmail key={recipientEmails}>
              {recipientEmails}
            </StyledLabelEmail>
          )
        }),

        emailsCopy.length > 0 && (
          <LabelNonBreakable>
            {translate('X more', {
              interpolations: { count: emailsCopy.length }
            })}
          </LabelNonBreakable>
        )
      ]}
    />
  )
}
