import type { Mutation } from '@server/graphql/typeDefs/types'

/* Edit attacks summary infrastructure */

export type MutationEditAttacksSummaryDirectory = Pick<
  Mutation,
  'editAttacksSummaryDirectory'
>

export const mutationEditAttacksSummaryDirectory = `
  mutation mutationEditAttacksSummaryDirectory(
    $profileId: Int!
    $directory: InputIoAAttacksSummaryDirectory!
  ) {
    editAttacksSummaryDirectory(
      profileId: $profileId
      directory: $directory
    )
  }
`

/* Edit attacks summary layout */

export type MutationEditAttacksSummaryLayout = Pick<
  Mutation,
  'editAttacksSummaryLayout'
>

export const mutationEditAttacksSummaryLayout = `
  mutation mutationEditAttacksSummaryLayout(
    $profileId: Int!
    $layout: InputIoAAttacksSummaryLayout!
  ) {
    editAttacksSummaryLayout(
      profileId: $profileId
      layout: $layout
    )
  }
`

/* Close selected attacks */

export type MutationCloseAttacks = Pick<Mutation, 'closeAttacks'>

export const mutationCloseAttacks = `
  mutation mutationCloseAttacks(
    $profileId: Int!
    $attacks: [InputCloseAttack!]!
  ) {
    closeAttacks(
      profileId: $profileId
      attacks: $attacks
    ) {
      err
      status
    }
  }
`

/* Close all attacks in bulk */

export type MutationCloseAttacksBulk = Pick<Mutation, 'closeAttacksBulk'>

export const mutationCloseAttacksBulk = `
  mutation mutationCloseAttacksBulk(
    $profileId: Int!
    $attacks: InputCloseAttacksBulk!
  ) {
    closeAttacksBulk(
      profileId: $profileId
      attacks: $attacks
    )
  }
`

/* Remove attacks (by adding remove-filters) */

export type MutationRemoveAttacks = Pick<Mutation, 'removeAttacks'>

export const mutationRemoveAttacks = `
  mutation mutationRemoveAttacks(
    $profileId: Int!
    $attackFilters: [InputAttackFilter!]!
  ) {
    removeAttacks(
      profileId: $profileId
      attackFilters: $attackFilters
    )
  }
`

/* Unremove attacks (by removing remove-filters) */

export type MutationUnremoveAttacks = Pick<Mutation, 'unremoveAttacks'>

export const mutationUnremoveAttacks = `
  mutation mutationUnremoveAttacks(
    $profileId: Int!
    $filterId: Int!
  ) {
    unremoveAttacks(
      profileId: $profileId
      filterId: $filterId
    )
  }
`

/* Unremove all attacks (by removing all remove-filters) */

export type MutationUnremoveAllAttacks = Pick<Mutation, 'unremoveAllAttacks'>

export const mutationUnremoveAllAttacks = `
  mutation mutationUnremoveAllAttacks(
    $profileId: Int!
  ) {
    unremoveAllAttacks(
      profileId: $profileId
    )
  }
`
