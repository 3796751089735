import { Card } from '@app/components-legacy/Card'
import type EntityAttack from '@app/entities/EntityAttack'
import { useStores } from '@app/hooks'
import { canAccessToAttackActions } from '@app/pages/IoA/IoAAttacksPage/permissions'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { AttacksPageContext } from '../context'
import IoAAttacksTableRow from './IoAAttacksTableRow'
import {
  onAttackCheckboxClick,
  onAttackExpandButtonClick
} from './IoAAttacksTableRow/handlers'

interface IIoAAttacksTableBodyRowCardContentProps {
  className?: string
  attackEntity: EntityAttack
  expanded: boolean
  closed: boolean
}

const CardAttack = styled(Card)`
  min-height: 70px;
  min-width: 1000px;
  margin: 0 ${consts.marginDefault} ${consts.marginSmall} 0;
`

const IoAAttacksTableBodyRowCardContent: React.FC<
  IIoAAttacksTableBodyRowCardContentProps
> = props => {
  const { storeRbac } = useStores()

  const attacksPageContext = React.useContext(AttacksPageContext)

  if (!attacksPageContext) {
    return null
  }

  const isActive =
    attacksPageContext.storeAttacks.storeWidgetListAttacks.isSelected(
      props.attackEntity
    )

  return (
    <CardAttack
      name="IoAAttacksTableBodyRowCardContent"
      className={props.className}
      active={isActive}
      checkable={storeRbac.isUserGrantedTo(canAccessToAttackActions)}
      onClick={onAttackExpandButtonClick(attacksPageContext.storeAttacks)(
        props.attackEntity
      )}
      onSelect={onAttackCheckboxClick(attacksPageContext.storeAttacks)(
        props.attackEntity
      )}
    >
      <IoAAttacksTableRow attackEntity={props.attackEntity} />
    </CardAttack>
  )
}

const ObservedIoAAttacksTableBodyRowCardContent = observer(
  IoAAttacksTableBodyRowCardContent
)

export default styled(ObservedIoAAttacksTableBodyRowCardContent)`
  opacity: ${props => (props.closed ? 0.5 : 1)};
`
