import { mandatory } from '@app/stores/helpers/StoreForm/validators'
import { Combinator } from '@libs/Expression/types'
import type { IStoreFormSetup } from '../StoreForm/types'
import type {
  DefinitionDateValue,
  DefinitionRule,
  DefinitionRuleGroup
} from './types'
import { WizardRuleFieldName, WizardRuleGroupFieldName } from './types'

export const DEFAULT_FIELD = 'CN'
export const DEFAULT_COMBINATOR = Combinator.AND
export const DEFAULT_RULEGROUP_PATH = [0]

export const DEFAULT_DATES: DefinitionDateValue = [
  ['>=', ''],
  ['<=', '']
]

export const DEFAULT_RULEGROUP_PARAMS: DefinitionRuleGroup = {
  combinator: DEFAULT_COMBINATOR,
  rules: []
}

export const DEFAULT_RULE_PARAMS: DefinitionRule = {
  field: DEFAULT_FIELD,
  value: ''
}

export const DEFAULT_RULEGROUP_FIELDS: IStoreFormSetup<WizardRuleGroupFieldName> =
  {
    fields: {
      [WizardRuleGroupFieldName.combinator]: {
        label: 'Rulegroup combinator',
        validators: [mandatory()]
      }
    }
  }

export const DEFAULT_RULE_FIELDS: IStoreFormSetup<WizardRuleFieldName> = {
  fields: {
    [WizardRuleFieldName.field]: {
      label: 'Rule field',
      validators: [mandatory()]
    },
    [WizardRuleFieldName.value]: {
      label: 'Rule value',
      validators: [mandatory()]
    }
  }
}
