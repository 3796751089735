import { Features } from '@alsid/common'
import { WrapperAlert } from '@app/components/Wrapper'
import { AlertSize } from '@app/components/Wrapper/types'
import {
  ContainerFlex,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import { useAppTranslator, useStores } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { AttackPathSearchTabs } from '../../types'
import AttackPathInputsWrapper from './AttackPathInputsWrapper'
import AttackPathTabs from './AttackPathTabs'
import AttackPathTier0Buttons from './Tier0/AttackPathTier0Buttons'

interface IAttackPathNodeFinderProps {}

const StyledContainer = styled(ContainerFlex)`
  background: linear-gradient(134.06deg, #b9dcff 0%, #026edd 101.7%);
  position: relative;
  border-radius: 10px;
  height: 320px;
`

const StyledH1 = styled.h1`
  color: ${consts.colorWhite};
  font-size: 17px;
  font-weight: 800;
  padding-top: 30px;
  z-index: 1;
`

const StyledSpan = styled.span`
  color: ${consts.colorWhite};
  font-size: 17px;
  padding-top: 10px;
  z-index: 1;
`

const StyledAlert = styled(WrapperAlert)`
  margin-top: 10px;

  .ant-alert {
    padding: 15px 20px;
  }
`

const Illustration = styled.div`
  width: 100%;
  height: 253px;
  background: url('/w/assets/images/attackpath/illustration.svg') no-repeat
    center;
  background-size: contain;
  position: absolute;
  top: 67px;
  left: 0;
  right: 0;
  margin: auto;
`

const AttackPathNodeFinder: React.FC<IAttackPathNodeFinderProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.NodeFinder']
  })

  const { storeAttackPath } = useStores()

  const featureAttackPathTier0 = useFeatureFlag({
    featureFlagName: Features.ATTACK_PATH_TIER0
  })

  const { activeTab } = storeAttackPath

  const getSearchExplanationText = () => {
    switch (activeTab) {
      case AttackPathSearchTabs.attackPath:
        return translate(
          'Attack Path helps to anticipate actions that an attacker will do to reach a business asset from a specific entry point',
          { transformMarkdown: true }
        )
      case AttackPathSearchTabs.blastRadius:
        return translate(
          'Blast Radius helps to evaluate lateral movements that are possible from a potentially exposed asset',
          { transformMarkdown: true }
        )
      case AttackPathSearchTabs.assetExposure:
        return translate(
          'Asset Exposure helps to identify all paths allowing to take control of an object in order to assess their legitimacy',
          { transformMarkdown: true }
        )
      default:
        return translate(
          'Blast Radius helps to evaluate lateral movements that are possible from a potentially exposed asset',
          { transformMarkdown: true }
        )
    }
  }

  return (
    <StyledContainer
      name="AttackPathNodeFinder"
      direction="column"
      alignItems="center"
      items={[
        <Illustration />,
        <StyledH1>{translate('Explore AD security relationships')}</StyledH1>,
        <StyledSpan>{translate('What are you looking for?')}</StyledSpan>,
        <AttackPathTabs />,
        <StyledAlert
          name="AttackPathAlertExplanation"
          labelledBy="attackPathAlertExplanation"
          alertProps={{
            message: (
              <ContainerHTMLContent>
                {getSearchExplanationText()}
              </ContainerHTMLContent>
            )
          }}
          size={AlertSize.large}
        />,
        <AttackPathInputsWrapper />,
        featureAttackPathTier0 && <AttackPathTier0Buttons />
      ]}
      spaced
      spaceWidth="default"
    />
  )
}

export default observer(AttackPathNodeFinder)
