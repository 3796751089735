import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import { createReport, deleteReport, editReport } from '@libs/rbac/permissions'
import type { Maybe } from '@productive-codebases/toolbox'
import { canAccessToReportingCenter } from '../permissions'

export const canReadReport = (reportId: number) => canAccessToReportingCenter

export const canCreateReport = rbacCapabilityCheckAllOf(
  canAccessToReportingCenter,
  createReport()
)

export const canEditReport = (reportId: number) =>
  rbacCapabilityCheckAllOf(canAccessToReportingCenter, editReport(reportId))

export const canDeleteReport = (reportId: Maybe<number>) =>
  rbacCapabilityCheckAllOf(canAccessToReportingCenter, deleteReport(reportId))
