import { BladeCancelButton } from '@app/components-legacy/Blade'
import {
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import {
  FormWrapperButton,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onSyslogTestButtonClick } from './SyslogsCreatePage/handlers'

export interface ISyslogCommonFormButtonsProps {
  version: 'creation' | 'edition'
  onSubmit: (e: React.FormEvent<any>) => void
}

const SyslogCommonFormButtons: React.FC<
  ISyslogCommonFormButtonsProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.System.Configuration.SyslogAlerts']
  })

  const { storeMessages, storeManagementSyslogs } = useStores()

  const { storeSubmitFlags } = storeManagementSyslogs

  return (
    <ContainerFooter>
      <BladeCancelButton />

      <ContainerFlex
        name="SyslogsCreateFormButtons"
        items={[
          <FormWrapperButton
            labelledBy="testConfiguration"
            buttonProps={{
              type: 'default',
              onClick: onSyslogTestButtonClick(
                storeMessages,
                storeManagementSyslogs
              )
            }}
          >
            {translate('Test the configuration')}
          </FormWrapperButton>,

          <FormWrapperButtonSubmit
            type="primary"
            loading={storeSubmitFlags.isLoading}
          >
            {translate(props.version === 'creation' ? 'Add' : 'Edit')}
          </FormWrapperButtonSubmit>
        ]}
        spaced
      />
    </ContainerFooter>
  )
}

export default observer(SyslogCommonFormButtons)
