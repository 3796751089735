import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import type {
  HealthCheckDetailedGlobalStatus,
  HealthCheckDetailedStatus,
  HealthCheckStatus,
  Maybe
} from '@server/graphql/typeDefs/types'

export default class EntityHealthCheckDetailedGlobalStatus
  extends EntityBase
  implements PropertiesNullable<HealthCheckDetailedGlobalStatus>
{
  status: Maybe<HealthCheckStatus> = null
  domainStats: Maybe<HealthCheckDetailedStatus> = null
  platformStats: Maybe<HealthCheckDetailedStatus> = null

  constructor(data: Partial<HealthCheckDetailedGlobalStatus>) {
    super()
    Object.assign(this, data)
  }
}
