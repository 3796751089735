import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelSliced } from '@app/components-legacy/Label'
import { consts } from '@app/styles'
import { isMaybeAnSDDL } from '@libs/sddl/isMaybeAnSDDL'
import { Typography } from 'antd'
import classnames from 'classnames'
import * as React from 'react'
import styled from 'styled-components'
import SDDLPanelHeaderDefinition from './SDDLPanelHeaderDefinition'

export interface ISDDLPanelStaticProps {
  className?: string
  title: Maybe<string>
  value: Maybe<string>
  definition: Maybe<string>
}

/**
 * Reuse the same classes than ant-collapse to keep styles.
 */
const SDDLPanelStatic: React.FC<ISDDLPanelStaticProps> = props => {
  const classNames = classnames({
    'ant-collapse': true,
    [props.className || '']: true
  })

  const showDefinition = props.definition !== props.value
  // don't show value if a definition has been found or if it seems to be an SDDL
  const showValue = !showDefinition && isMaybeAnSDDL(props.value)

  return (
    <div className={classNames}>
      <div className="ant-collapse-item">
        <div className="ant-collapse-header custom-header-styles">
          <ContainerFlex
            name="SDDLPanelHeader"
            items={[
              props.title && (
                <Typography.Text strong>{props.title}</Typography.Text>
              ),

              showDefinition && (
                <SDDLPanelHeaderDefinition definition={props.definition} />
              ),

              showValue && (
                <LabelSliced
                  data-name="SDDLPanelHeader"
                  popoverType="popover"
                  popoverProps={{
                    placement: 'left',
                    mouseEnterDelay: 1
                  }}
                  value={props.value || '-'}
                  maxLength={70}
                  popoverMaxLength={1000}
                />
              )
            ]}
            spaced
          />
        </div>
      </div>
    </div>
  )
}

export default styled(SDDLPanelStatic)`
  margin: ${consts.marginVeryVerySmall} 0;

  /* override the cursor behavior */
  .custom-header-styles {
    padding: 12px 16px !important;
    cursor: default !important;
  }
`
