import type StoreModal from '@app/stores/helpers/StoreModal'
import * as React from 'react'
import { FormWrapperButtonCancel } from '../Form/Wrappers'

interface IModalCancelButtonProps {
  storeModal: StoreModal<any>
}

/**
 * Render a Cancel button for modals.
 *
 * Usage:
 *
 * <ModalCancelButton storeModal={...} />
 */
const ModalCancelButton: React.FC<IModalCancelButtonProps> = props => {
  const handleOnClick = () => {
    props.storeModal.hide()
  }

  return <FormWrapperButtonCancel onClick={handleOnClick} />
}

export default ModalCancelButton
