import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface IContainerFiltersProps {
  className?: string
  children?: React.ReactNode
}

/**
 * Could be useful to wrap filters when used in the `menu` placeholder of layouts.
 */
const ContainerFilters: React.FC<IContainerFiltersProps> = props => {
  return (
    <div data-name="ContainerFilters" className={props.className}>
      {props.children}
    </div>
  )
}

export default styled(ContainerFilters)`
  padding: ${consts.paddingDefault};
  border-bottom: 1px solid ${consts.colorBlueGray030};
`
