import { AppMenu } from '@app/components-legacy/Menu'
import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { canDisplayActivityLogs } from '@app/pages/Management/AccountsPage/ActivityLogs/permissions'
import { AppRouteName } from '@app/routes'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { filterFalsies } from '@libs/filterFalsies'
import * as React from 'react'
import { canAccessToProfiles } from './Profiles/ProfilesPage/permissions'
import { canAccessToRoles } from './Roles/permissions'
import { canAccessToAccountUsers } from './Users/UsersPage/permissions'

export enum MenuAccountsKey {
  Users = 'Users',
  Roles = 'Roles',
  Profiles = 'Profiles',
  ActivityLogs = 'ActivityLogs'
}

export interface IMenuAccountsProps {}

const MenuAccounts: React.FC<IMenuAccountsProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.Accounts']
  })

  const { storeRbac } = useStores()

  const storeMenu = useStoreMenuInit<IMenuEntry<MenuAccountsKey>>({
    menuEntries: filterFalsies([
      storeRbac.isUserGrantedTo(canAccessToAccountUsers(storeRbac)) && {
        key: MenuAccountsKey.Users,
        label: translate('Users management'),
        labelledBy: 'Users management',
        routeDefinition: {
          routeName: AppRouteName.Management_Accounts_Users,
          parameters: {}
        }
      },
      storeRbac.isUserGrantedTo(canAccessToRoles) && {
        key: MenuAccountsKey.Roles,
        label: translate('Roles management'),
        labelledBy: 'Roles management',
        routeDefinition: {
          routeName: AppRouteName.Management_Accounts_Roles,
          parameters: {}
        }
      },
      storeRbac.isUserGrantedTo(canAccessToProfiles) && {
        key: MenuAccountsKey.Profiles,
        label: translate('Profiles management'),
        labelledBy: 'Profiles management',
        routeDefinition: {
          routeName: AppRouteName.Management_Accounts_Profiles,
          parameters: {}
        }
      },
      storeRbac.isUserGrantedTo(canDisplayActivityLogs) && {
        key: MenuAccountsKey.ActivityLogs,
        label: translate('Activity Logs'),
        labelledBy: 'ActivityLogs',
        routeDefinition: {
          routeName: AppRouteName.ActivityLogs,
          parameters: {}
        }
      }
    ])
  })

  return <AppMenu storeMenu={storeMenu} />
}

export default MenuAccounts
