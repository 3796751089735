import {
  ContainerContent,
  ContainerFlex,
  ContainerFooter,
  ContainerIcon,
  ContainerRbac
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import {
  IconDeleteOutlined,
  IconEditOutlined,
  IconInfoCircleOutlined,
  IconMailOutlined
} from '@app/components-legacy/Icon/IconAntd'
import IconBlank from '@app/components-legacy/Icon/IconBlank'
import { LabelDirectories } from '@app/components-legacy/Label'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import {
  WidgetListActionButtons,
  WidgetListActionsHeader,
  WidgetListCountItems,
  WidgetListPagination,
  WidgetListTable,
  WidgetListTableActionIcons
} from '@app/components-legacy/Widgets/WidgetList'
import type { EntityEmail } from '@app/entities'
import type { IDataRowEmail } from '@app/entities/EntityEmail'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { getCriticityString } from '@libs/criticity'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Link } from 'react-router-dom'
import {
  onEmailCreateButtonClick,
  onEmailDeleteIconClick,
  onEmailEditIconClick,
  onEmailsListPageChange,
  onEmailTestIconClick
} from './handlers'
import {
  canCreateEmailAlerts,
  canDeleteEmailAlert,
  canEditEmailAlert,
  canReadEmailAlert
} from './permissions'

interface IEmailsListProps {}

const EmailsList: React.FC<IEmailsListProps> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Configuration.EmailAlerts',
      'Components.LabelChecker.ComplexityLevel'
    ]
  })

  const appRouter = useAppRouter()

  const { storeManagementEmails, storeManagementApplicationSettings } =
    useStores()

  const { applicationSettings } = storeManagementApplicationSettings

  // Check that SMTP server is configured before allowing adding email alerts
  if (!applicationSettings?.smtpServerAddress) {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Configuration,
      parameters: {}
    })

    return (
      <ContainerContent>
        <ContainerFlex
          name="SMTPServerNotConfigured"
          direction="column"
          items={[
            <div>{translate('No SMTP configured yet')}</div>,
            <Link to={url}>{translate('Configure the SMTP Server')}</Link>
          ]}
          spaced
          spaceWidth="verySmall"
        />
      </ContainerContent>
    )
  }

  return (
    <>
      <ContainerFlex
        name="EmailsList"
        direction="column"
        items={[
          <WidgetListActionsHeader>
            <WidgetListCountItems
              storeWidgetList={storeManagementEmails.storeWidgetList}
            />

            <WidgetListActionButtons>
              <ContainerRbac rbacCapabilityCheck={canCreateEmailAlerts}>
                <FormWrapperButton
                  labelledBy="addEmailAlert"
                  buttonProps={{
                    type: 'primary',
                    onClick: onEmailCreateButtonClick(appRouter)
                  }}
                >
                  {translate('Add an email alert')}
                </FormWrapperButton>
              </ContainerRbac>
            </WidgetListActionButtons>
          </WidgetListActionsHeader>,

          <WidgetListTable<EntityEmail, IDataRowEmail>
            translate={translate}
            columnWidths={{
              id: 'ID'
            }}
            storeWidgetList={storeManagementEmails.storeWidgetList}
            cellsRenderFn={{
              criticityThreshold: value => {
                return <div>{translate(getCriticityString(value))}</div>
              },
              description: value => {
                // if no descriptions, no tooltip
                if (!value) {
                  return null
                }

                return (
                  <Tooltip title={value}>
                    <IconInfoCircleOutlined />
                  </Tooltip>
                )
              },
              directories: directoryIds => {
                return <LabelDirectories directoryIds={directoryIds} />
              }
            }}
            actionsButtonsRenderFn={emailId => {
              return (
                <WidgetListTableActionIcons
                  icons={[
                    <ContainerRbac
                      rbacCapabilityCheck={canReadEmailAlert(Number(emailId))}
                      render={({ isGranted }) => {
                        return isGranted ? (
                          <ContainerIcon
                            labelledBy="testEmail"
                            iconComponent={IconMailOutlined}
                            iconProps={{
                              type: 'mail',
                              onClick: onEmailTestIconClick(
                                storeManagementEmails
                              )(Number(emailId))
                            }}
                          />
                        ) : (
                          <IconBlank />
                        )
                      }}
                    />,

                    <ContainerRbac
                      rbacCapabilityCheck={canEditEmailAlert(Number(emailId))}
                      render={({ isGranted }) => {
                        return isGranted ? (
                          <ContainerIcon
                            labelledBy="editEmail"
                            iconComponent={IconEditOutlined}
                            iconProps={{
                              type: 'edit',
                              onClick: onEmailEditIconClick(appRouter)(
                                Number(emailId)
                              )
                            }}
                          />
                        ) : (
                          <IconBlank />
                        )
                      }}
                    />,

                    <ContainerRbac
                      rbacCapabilityCheck={canDeleteEmailAlert(Number(emailId))}
                      render={({ isGranted }) => {
                        return isGranted ? (
                          <ContainerIcon
                            labelledBy="deleteEmail"
                            iconComponent={IconDeleteOutlined}
                            iconProps={{
                              type: 'delete',
                              onClick: onEmailDeleteIconClick(
                                storeManagementEmails
                              )(Number(emailId))
                            }}
                          />
                        ) : (
                          <IconBlank />
                        )
                      }}
                    />
                  ]}
                />
              )
            }}
          />
        ]}
        spaced
      />

      <Portal name={PlaceHolderName.bladeFooter}>
        <ContainerFooter>
          <ContainerFlex
            name="Pagination"
            justifyContent="flex-end"
            items={[
              <WidgetListPagination
                storeWidgetList={storeManagementEmails.storeWidgetList}
                onChange={onEmailsListPageChange(storeManagementEmails)}
              />
            ]}
          />
        </ContainerFooter>
      </Portal>
    </>
  )
}

export default observer(EmailsList)
