import { BladesContainer } from '@app/components-legacy/Blade'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LayoutDefault } from '@app/components-legacy/Layout'
import SceneBlade from '@app/pages/Topology/SceneBlade'
import * as React from 'react'

interface ITopologyProps {}

const Topology: React.FC<ITopologyProps> = props => {
  return (
    <LayoutDefault
      content={
        <ContainerFlex
          name="Topology"
          items={[
            <BladesContainer>
              <SceneBlade />
            </BladesContainer>
          ]}
        />
      }
    />
  )
}

export default Topology
