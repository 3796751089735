import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks/useStores'
import MenuSystem from '@app/pages/Management/SystemPage/MenuSystem'
import type {
  IManagementSystemDirectoriesEditRouteDefinition,
  IManagementSystemDirectoriesHoneyAccountCreateRouteDefinition,
  IManagementSystemDirectoriesHoneyAccountEditRouteDefinition
} from '@app/routes'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DirectoriesCreatePage from '../DirectoriesCreatePage'
import DirectoriesEditPage from '../DirectoriesEditPage'
import HoneyAccountCreatePage from '../HoneyAccountCreatePage'
import HoneyAccountEditPage from '../HoneyAccountEditPage'
import {
  canAccessToDirectories,
  canCreateDirectories,
  canCreateHoneyAccount,
  canEditDirectory,
  canEditHoneyAccount
} from '../permissions'
import DirectoriesList from './DirectoriesList'
import DrawerActionDeleteDirectory from './DrawerActionDeleteDirectory'
import DrawerActionRecrawlDirectory from './DrawerActionRecrawlDirectory'
import { handleDirectoriesOnLoad, handleDirectoriesOnUnload } from './handlers'

interface IDirectoriesPageProps {}

const DirectoriesPage: React.FC<IDirectoriesPageProps> = props => {
  const { storeInfrastructures, storeManagementDirectories } = useStores()

  return (
    <>
      <Blade
        root={true}
        routeDefinition={{
          routeName: AppRouteName.Management_System_Directories,
          parameters: {}
        }}
        onLoad={handleDirectoriesOnLoad(
          storeInfrastructures,
          storeManagementDirectories
        )}
        onUnload={handleDirectoriesOnUnload(storeManagementDirectories)}
        flags={storeManagementDirectories.storeFlags.flags}
        layout={{
          name: 'default',
          menu: <MenuSystem />,
          content: (
            <ContainerContent>
              <DirectoriesList />

              <Portal name={PlaceHolderName.genericDrawer}>
                <DrawerActionDeleteDirectory />
                <DrawerActionRecrawlDirectory />
              </Portal>
            </ContainerContent>
          ),
          footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
        }}
        rbacCapabilityCheck={canAccessToDirectories}
      />

      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName: AppRouteName.Management_System_Directories_Create,
              parameters: {}
            },
            component: DirectoriesCreatePage,
            rbacPermissionsCheck: () => canCreateDirectories
          },
          {
            routeDefinition: {
              routeName: AppRouteName.Management_System_Directories_Edit,
              parameters: {
                directoryId: Number()
              }
            },
            component: DirectoriesEditPage,
            rbacPermissionsCheck: (
              parameters: IManagementSystemDirectoriesEditRouteDefinition['parameters']
            ) => canEditDirectory(parameters.directoryId)
          },
          {
            routeDefinition: {
              routeName:
                AppRouteName.Management_System_Directories_Honey_Account_Create,
              parameters: {
                directoryId: Number()
              }
            },
            component: HoneyAccountCreatePage,
            rbacPermissionsCheck: (
              parameters: IManagementSystemDirectoriesHoneyAccountCreateRouteDefinition['parameters']
            ) => canCreateHoneyAccount(parameters.directoryId)
          },
          {
            routeDefinition: {
              routeName:
                AppRouteName.Management_System_Directories_Honey_Account_Edit,
              parameters: {
                directoryId: Number()
              }
            },
            component: HoneyAccountEditPage,
            rbacPermissionsCheck: (
              parameters: IManagementSystemDirectoriesHoneyAccountEditRouteDefinition['parameters']
            ) => canEditHoneyAccount(parameters.directoryId)
          }
        ]}
      />
    </>
  )
}

export default observer(DirectoriesPage)
