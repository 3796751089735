import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface ILayoutPDFProps {
  className?: string
  content: React.ReactNode
}

const LayoutPDF: React.FC<ILayoutPDFProps> = props => {
  return <div className={props.className}>{props.content}</div>
}

export default styled(LayoutPDF)`
  padding: ${consts.paddingDefault};
`
