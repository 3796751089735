import {
  rbacCapabilityCheckAllOf,
  rbacCapabilityCheckAnyOf
} from '@libs/rbac/functions'
import {
  readLicenseFeatureIoA,
  readLicenseFeatureIoE,
  readUITrailFlow
} from '@libs/rbac/permissions'

export const canAccessToTrailFlow = rbacCapabilityCheckAllOf(
  readUITrailFlow(),
  rbacCapabilityCheckAnyOf(readLicenseFeatureIoE(), readLicenseFeatureIoA())
)

export const canSeeDeviances = rbacCapabilityCheckAllOf(readLicenseFeatureIoE())

export const canAccessToEventDetails = canAccessToTrailFlow
