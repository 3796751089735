import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelSliced } from '@app/components-legacy/Label'
import { isMaybeAnSDDL } from '@libs/sddl/isMaybeAnSDDL'
import { Typography } from 'antd'
import * as React from 'react'

export interface ISDDLPanelHeaderProps {
  title: Maybe<string>
  value: Maybe<string>
}

const SDDLPanelHeader: React.FC<ISDDLPanelHeaderProps> = props => {
  // don't show value when it's only "letters"
  const showValue = isMaybeAnSDDL(props.value)

  return (
    <ContainerFlex
      name="SDDLPanelHeader"
      items={[
        props.title && <Typography.Text strong>{props.title}</Typography.Text>,

        showValue && (
          <LabelSliced
            data-name="SDDLPanelHeader"
            popoverType="popover"
            popoverProps={{
              placement: 'left',
              mouseEnterDelay: 1
            }}
            value={props.value || '-'}
            maxLength={70}
            popoverMaxLength={1000}
          />
        )
      ]}
      spaced
    />
  )
}

export default SDDLPanelHeader
