import { FormWrapperSelect } from '@app/components-legacy/Form'
import type { IFormWrapperSelectProps } from '@app/components-legacy/Form/Wrappers/Select'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import {
  ProfileCreateFieldValue,
  ProfileFieldName
} from '@app/pages/Management/AccountsPage/Profiles/ProfilesCreatePage/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onProfileCreateCopyFromSelection } from './handlers'

interface IProfileCreateFormSelectProps {}

const ProfileCreateFormSelect: React.FC<IProfileCreateFormSelectProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Profiles.Creation']
  })

  const { storeManagementProfiles } = useStores()

  const options: IFormWrapperSelectProps<any>['selectOptions'] = [
    {
      label: translate('Create a new profile'),
      value: ProfileCreateFieldValue.createProfile
    },
    {
      groupLabel: translate('Or copy a profile'),
      options: Array.from(storeManagementProfiles.profiles.values()).map(
        profile => {
          return {
            label: translate('Copy the profile X', {
              interpolations: {
                profileName: profile.getPropertyAsString('name')
              }
            }),
            value: profile.getPropertyAsString('id'),
            labelledBy: `copy-the-profile-${profile.getPropertyAsString('id')}`
          }
        }
      )
    }
  ]

  return (
    <FormWrapperSelect
      labelledBy="createProfile"
      selectProps={{
        onChange: onProfileCreateCopyFromSelection(storeManagementProfiles),
        value: storeManagementProfiles.storeFormCreation.getFieldValueAsString(
          ProfileFieldName.fromProfileSelection
        )
      }}
      selectOptions={options}
    />
  )
}

export default observer(ProfileCreateFormSelect)
