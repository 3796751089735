import { FormWrapperSelect } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import {
  getDurationsValues,
  WIDGET_DEFAULT_DURATION
} from '@app/stores/Dashboard/consts'
import type StoreWidgetsManagement from '@app/stores/Dashboard/StoreWidgetsManagement'
import { onSelectChange } from '@app/stores/helpers/StoreForm/handlers'
import type StoreRoot from '@app/stores/StoreRoot'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { WidgetCommonFormFieldName } from '../types'

export interface IWidgetInputDurationProps {
  storeWidgetsManagement: StoreWidgetsManagement
  storeRoot?: StoreRoot
}

const WidgetInputDuration: React.FC<IWidgetInputDurationProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Dashboard.WidgetCommonForm']
  })

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  return (
    <FormWrapperSelect
      labelledBy="inputDuration"
      width={themeIsLegacy ? undefined : 'large'}
      selectProps={{
        value: props.storeWidgetsManagement.storeForm.getFieldValueAsString(
          WidgetCommonFormFieldName.dataOptionsDuration,
          String(WIDGET_DEFAULT_DURATION)
        ),
        onChange: onSelectChange(props.storeWidgetsManagement.storeForm)(
          WidgetCommonFormFieldName.dataOptionsDuration
        )
      }}
      selectOptions={Array.from(getDurationsValues(translate).entries()).map(
        ([duration, translation]) => {
          return {
            label: translation,
            value: String(duration)
          }
        }
      )}
    />
  )
}

export default observer(WidgetInputDuration)
