import { BladeCancelButton } from '@app/components-legacy/Blade'
import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  ContainerContent,
  ContainerFooter
} from '@app/components-legacy/Container'
import {
  Drawer,
  DrawerContext,
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckerDetailsPageContext } from '../../context'
import { canSetIgnoreDateOnDeviantObjects } from '../../permissions'
import { onDeviantObjectsUnsetIgnoreDateSubmit } from '../DrawerIgnoreDeviantObjects/handlers'

interface IDrawerUnignoreDeviantObjectsProps {}

const DrawerUnignoreDeviantObjects: React.FC<
  IDrawerUnignoreDeviantObjectsProps
> = () => {
  const parameters = React.useContext(CheckerDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoE.Details.DeviantObjects']
  })

  const {
    storeAuthentication,
    storeIoE: { storeIndicator }
  } = useStores()

  if (!parameters) {
    return null
  }

  const { storeDrawerUnignoreObjets, storeFlagsEditDeviances } =
    storeIndicator.storeIndicatorDeviantObjects

  const isDeviantObject =
    storeDrawerUnignoreObjets.getDataValue('objectType') === 'object'

  return (
    <DrawerContext.Provider value={{ storeDrawer: storeDrawerUnignoreObjets }}>
      <Drawer>
        <DrawerHorizontalContainer>
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: (
                <DrawerTitle>
                  {translate(
                    isDeviantObject
                      ? 'Stop ignoring selected objects'
                      : 'Stop ignoring selected deviances'
                  )}
                </DrawerTitle>
              ),
              content: (
                <ContainerContent
                  rbacCapabilityCheck={canSetIgnoreDateOnDeviantObjects}
                >
                  <p>
                    {translate(
                      isDeviantObject
                        ? 'Tenable.ad will stop ignoring the selected objects'
                        : 'Tenable.ad will stop ignoring the selected deviances'
                    )}
                  </p>
                </ContainerContent>
              ),
              footer: (
                <ContainerFooter>
                  <BladeCancelButton />

                  <FormWrapperButton
                    labelledBy="submit"
                    buttonProps={{
                      type: 'primary',
                      onClick: onDeviantObjectsUnsetIgnoreDateSubmit(
                        storeAuthentication,
                        storeIndicator.storeIndicatorDeviantObjects
                      ),
                      loading: storeFlagsEditDeviances.flags.isLoading
                    }}
                  >
                    {translate('OK')}
                  </FormWrapperButton>
                </ContainerFooter>
              )
            }}
            rbacCapabilityCheck={canSetIgnoreDateOnDeviantObjects}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerUnignoreDeviantObjects)
