import { AssetImg } from '@app/components/Asset/AssetImg'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useAppRouter, useAppTranslator } from '@app/hooks'
import { useStores } from '@app/hooks/useStores'
import { canUpdateLicenseDuringLogin } from '@app/pages/Auth/permissions'
import type { AppRouteDefinition } from '@app/routes'
import { AppRouteName } from '@app/routes'
import { Upload } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import { handleLicenseOnUpload } from './handlers'

interface IProductLicenseUploadButtonProps {
  redirectRouteDefinition?: AppRouteDefinition
}

const StyledAssetImg = styled(AssetImg)`
  padding: 0 10px 5px 0;
`
const ProductLicenseUploadButton: React.FC<
  IProductLicenseUploadButtonProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.About.License']
  })

  const appRouter = useAppRouter()

  const { storeLicense, storeRbac } = useStores()

  const url = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.MiddlewareLicense,
    parameters: {}
  })

  const disabled = !storeRbac.isUserGrantedTo(canUpdateLicenseDuringLogin)
  const license = storeLicense.license
  return (
    <Upload
      name="licenseFile"
      disabled={disabled}
      action={url}
      showUploadList={false}
      onChange={handleLicenseOnUpload(storeLicense)({
        redirectRouteDefinition: props.redirectRouteDefinition
      })}
    >
      <FormWrapperButton
        labelledBy="uploadValidLicense"
        variant={ButtonVariant.secondary}
        size={ButtonSize.large}
        buttonProps={{
          disabled,
          title: disabled
            ? translate('You are not allowed to upload the license')
            : '',
          icon: license ? (
            <StyledAssetImg
              imageName="edit-license"
              fileType="svg"
              labelledBy="editLicense"
            />
          ) : (
            <StyledAssetImg
              imageName="upload-license"
              fileType="svg"
              labelledBy="uploadLicense"
            />
          )
        }}
      >
        {translate(
          license ? 'Edit the license file' : 'Upload the license file'
        )}
      </FormWrapperButton>
    </Upload>
  )
}

export default ProductLicenseUploadButton
