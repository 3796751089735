import type { Perhaps } from '@@types/helpers'
import type { IEntityListable } from '@app/entities/types'
import { ensureArray } from '@libs/ensureArray'
import { isDefined } from '@libs/isDefined'
import type {
  IDataRowGeneric,
  IWidgetListDataSet,
  WidgetListGetterId
} from './types'

/**
 * Default getter for ID.
 */
export function defaultGetIdFn<D extends IDataRowGeneric>(
  row: D
): WidgetListGetterId {
  // Fix error: Property 'id' does not exist on type 'D'.
  return `${(row as any).id}`
}

/**
 * Default filter function for entities
 */
export function defaultFilterEntitiesFn<E>(entities: E[]): E[] {
  return entities
}

/**
 * Default builder function of a dataSet.
 */
export function buildDataSetDefaultFn<
  E extends IEntityListable<IDataRowGeneric>,
  D extends IDataRowGeneric
>(entities: E[]): IWidgetListDataSet<D> {
  if (!entities.length) {
    return {
      columns: [],
      rows: []
    }
  }

  const columns = entities[0].getColumns()
  const rows = entities
    .map(e => {
      if (e.asCustomDataRow) {
        return e.asCustomDataRow()
      }

      if (e.asDataRow) {
        return e.asDataRow()
      }

      return
    })
    .filter(isDefined) as D[]

  return { columns, rows }
}

/**
 * Filter columns by removing optionnal hidden keys.
 */
export function filterColumn<D>(
  key: keyof D,
  hiddenKeys?: Array<keyof D>
): boolean {
  if (!hiddenKeys) {
    return true
  }
  return hiddenKeys.indexOf(key) === -1
}

/**
 * Return an empty data source.
 */
export function getEmptyDataSet<D>(): IWidgetListDataSet<D> {
  return {
    columns: [],
    rows: []
  }
}

/**
 * Return true if the list of ids of entities is equal to -1.
 * "Empty entities" are used to create empty rows that sometimes are necessary to
 * keep headers/selectors functional.
 */
export function isListConsideredAsEmpty(
  rbacEntityIds: Perhaps<Array<Perhaps<string | number>> | string | number>
): boolean {
  return ensureArray(rbacEntityIds).some(id => Number(id) === -1)
}
