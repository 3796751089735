import * as React from 'react'
import styled from 'styled-components'

interface IContainerDividerProps {
  className?: string
  type:
    | {
        name: 'vertical'
        height: number
      }
    | {
        name: 'horizontal'
        width: number
      }
}

/**
 * Custom divider to replace the one of AntDesign, too lighter,
 * and to have the control over the height/width of the divider.
 */
const ContainerDivider: React.FC<IContainerDividerProps> = props => {
  return <div className={props.className}></div>
}

function getDividerSize(dividerType: IContainerDividerProps['type']): string {
  switch (dividerType.name) {
    case 'horizontal':
      return `width: ${dividerType.width}px;`

    default:
      return `height: ${dividerType.height}px;`
  }
}

export default styled(ContainerDivider)`
  ${props => getDividerSize(props.type)}
  border-right: 1px solid ${props =>
    props.theme.tokens['containerDivider/color/default']};
`
