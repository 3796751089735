import StoreBase from './StoreBase'
import type StoreRoot from './StoreRoot'
import type { IStoreOptions } from './types'

export enum DebugFlags {
  Dev = 'tad:dev',
  NoRbac = 'tad:noRbac',
  Automation = 'tad:automation'
}

export default class StoreDebug extends StoreBase {
  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
  }

  /**
   * Return true if the dev flag is set in local storage.
   * Used to enable "at run time" some features, for demos or tests purpose.
   */
  isDevFlag(): boolean {
    if (!this.storeRoot.environment.localStorage) {
      return false
    }

    return (
      this.storeRoot.environment.localStorage.getItem(DebugFlags.Dev) === 'true'
    )
  }

  /**
   * Return true if the no-Rbac flag is set in local storage.
   * Used to bypass Rbac "at run time" to debugging purposes.
   * (Note that Rbac will still be checked by the API)
   */
  isNoRbacFlag(): boolean {
    if (!this.storeRoot.environment.localStorage) {
      return false
    }

    return (
      this.storeRoot.environment.localStorage.getItem(DebugFlags.NoRbac) ===
      'true'
    )
  }

  /**
   * Return true if the automation flag is set in local storage.
   * Used to enable "at run time" some feature to help QA people.
   */
  isAutomationFlag(): boolean {
    if (!this.storeRoot.environment.localStorage) {
      return false
    }

    return (
      this.storeRoot.environment.localStorage.getItem(DebugFlags.Automation) ===
      'true'
    )
  }
}
