import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import LabelSliced from '../LabelSliced'
import type { ILabelCheckerProps } from './types'

interface ILabelCheckerDescriptionProps<GC extends TGenericChecker>
  extends ILabelCheckerProps<GC> {}

function LabelCheckerDescription<GC extends TGenericChecker>(
  props: ILabelCheckerDescriptionProps<GC>
): React.ReactElement {
  return (
    <div data-name="LabelCheckerDescription" className={props.className}>
      <LabelSliced
        maxLength={250}
        value={props.description || '-'}
        popoverType="tooltip"
        breakString
      />
    </div>
  )
}

export default styled(LabelCheckerDescription)`
  padding: 0 ${consts.paddingSmall};
`
