export enum ErrorName {
  // 400
  BadRequestError = 'BadRequestError',
  // 401
  UnauthorizedAccessError = 'UnauthorizedAccessError',
  LockedOutAccountAccessError = 'LockedOutAccountAccessError',
  // 403
  ForbiddenAccessError = 'ForbiddenAccessError',
  // 404
  NotFoundError = 'NotFoundError',
  // Application error (specified with a ApplicationErrorValue message)
  ApplicationError = 'ApplicationError',
  // Application related to RequestError (from API)
  ApplicationRequestError = 'ApplicationRequestError',
  // GraphQL related exceptions
  GraphQLServerError = 'GraphQLServerError',
  // Server (Kapteyn server) related exceptions
  ServerError = 'ServerError',
  // Exception that leads to a hard redirect (message is an url)
  HardReloadError = 'HardReloadError',
  // Used for specific use-case where Rbac is not (yet?) supported
  RbacNotSupportedError = 'RbacNotSupportedError',
  // Too specific error that could be handled in a not-generic way
  CustomError = 'CustomError',
  // Used for a refused license upgrade when the version of the new one is older that existing one
  LicenseDowngradeNotAllowed = 'LicenseDowngradeNotAllowed',
  // Used for a refused license upgrade when the license uploaded may have already been used
  InvalidLinkingKey = 'InvalidLinkingKey',
  // Used when reseting password
  InvalidPassword = 'InvalidPassword',
  // Exception that leads to a hard redirect (message is an url)
  PasswordChangeNeededError = 'PasswordChangeNeededError',
  // Exception when the path returned by attack path would be too big
  AttackPathGraphTooBig = 'AttackPathGraphTooBig',
  // Exception when attack path search exceeded timeout
  AttackPathSearchTimeout = 'AttackPathSearchTimeout'
}

export enum ApplicationErrorValue {
  HookError = 'HookError',
  FetchError = 'FetchError',
  TranslatorError = 'TranslatorError',
  ValidationError = 'ValidationError',
  DevianceNotFound = 'DevianceNotFound',
  LicenseNotFound = 'LicenseNotFound',
  ApiError = 'ApiError'
}

/**
 * Interface used when checking a IMaybeRequestErrorResults<T>
 * with the handleRequestError typeguard function.
 * Define a structure with a defined node without requestError.
 */
export interface IRequestResults<T> {
  node: T
  requestError: null
}
