import { ContainerFlex } from '@app/components/Container'
import IconReload from '@app/components/Icon/IconReload'
import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import { FormWrapperSwitch } from '@app/components-legacy/Form/Wrappers'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import InputHealthChecks from '@app/components-legacy/Input/InputHealthChecks'
import { useAppTranslator, useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  handleHealthChecksPlatformStatusChecksChange,
  handleHealthChecksPlatformStatusRefresh,
  handleHealthChecksPlatformStatusShowHealthyChange
} from './handlers'

export interface IHealthCheckPlatformStatusFiltersProps {}

function HealthCheckPlatformStatusFilters(
  props: IHealthCheckPlatformStatusFiltersProps
) {
  const { storeHealthCheck } = useStores()

  const translate = useAppTranslator({
    namespaces: ['HealthCheck.Global', 'HealthCheck.Domains']
  })

  return (
    <ContainerFlex
      name="HealthCheckPlatformStatusFilters"
      justifyContent="flex-end"
      alignItems="center"
      items={[
        <label htmlFor="heathCheckShowHealthy">
          <Label variant={LabelVariant.p}>{translate('Show healthy')}</Label>
        </label>,
        <FormWrapperSwitch
          labelledBy="healthCheckShowHealthy"
          switchProps={{
            id: 'heathCheckShowHealthy',
            checked: storeHealthCheck.showHealthy,
            onChange:
              handleHealthChecksPlatformStatusShowHealthyChange(
                storeHealthCheck
              )
          }}
        />,
        <InputHealthChecks
          storeInputHealthChecks={storeHealthCheck.storeInputHealthChecks}
          onSubmit={handleHealthChecksPlatformStatusChecksChange(
            storeHealthCheck
          )}
        />,
        <FormWrapperButton
          variant={ButtonVariant.secondary}
          size={ButtonSize.small}
          labelledBy="healthCheckRefreshButton"
          icon={IconReload}
          buttonProps={{
            onClick: handleHealthChecksPlatformStatusRefresh(storeHealthCheck)
          }}
        />
      ]}
      flexGap="small"
    />
  )
}

export default observer(HealthCheckPlatformStatusFilters)
