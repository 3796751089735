import TableBodyNoResult from '@app/components-legacy/Table/TableBodyNoResult'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import TableContentBodyRowMemo from './TableContentBodyRowMemo'

interface ITableContentBodyRowsProps {}

const TableContentBodyRows: React.FC<ITableContentBodyRowsProps> = () => {
  const { storeTrailFlow } = useStores()

  const renderRows = React.useMemo(() => {
    const { events } = storeTrailFlow.storeEvents

    if (!events.length) {
      return <TableBodyNoResult />
    }

    return events.map(event => {
      return (
        <TableContentBodyRowMemo
          key={`event-${event.id}`}
          event={event}
          isLive={storeTrailFlow.isLive}
        />
      )
    })
  }, [storeTrailFlow.storeEvents.eventsActivity])

  return <>{renderRows}</>
}

export default observer(TableContentBodyRows)
