import {
  ContainerContent,
  ContainerFooter
} from '@app/components-legacy/Container'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToTenableProviderConfiguration } from '../permissions'
import {
  handleTenableAccountConfigurationOnLoad,
  handleTenableAccountConfigurationOnSubmit,
  handleTenableAccountConfigurationOnUnLoad
} from './handlers'
import LockoutPolicyForm from './LockoutPolicyForm'
import { canSubmitTenableAccountForm } from './permissions'
import TenableAdForm from './TenableAdForm'

interface IConfigurationTenableAccountPageProps {}

const ConfigurationTenableAccountPage: React.FC<
  IConfigurationTenableAccountPageProps
> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Configuration.Navigation',
      'Management.System.Configuration.TenableAccount'
    ]
  })

  const {
    storeRbac,
    storeAuthentication,
    storeManagementRbacRoles,
    storeManagementApplicationSettings,
    storeLockoutPolicy
  } = useStores()

  return (
    <ContainerContent
      onLoad={handleTenableAccountConfigurationOnLoad(
        storeManagementRbacRoles,
        storeManagementApplicationSettings
      )}
      onUnload={handleTenableAccountConfigurationOnUnLoad(
        storeManagementApplicationSettings
      )}
      flags={[
        storeManagementRbacRoles.storeFetchRbacRolesFlags.flags,
        storeManagementApplicationSettings.storeFlagsAppSettingsFetch.flags
      ]}
      rbacCapabilityCheck={canAccessToTenableProviderConfiguration}
    >
      <FormWrapper
        name="tenableAccountConfiguration"
        onSubmit={handleTenableAccountConfigurationOnSubmit(
          storeManagementApplicationSettings,
          storeLockoutPolicy
        )}
      >
        <>
          <ContainerContent name={ConfigurationTenableAccountPage.name}>
            <TenableAdForm />
            {!storeAuthentication.isToneUser() && <LockoutPolicyForm />}
          </ContainerContent>

          <Portal name={PlaceHolderName.bladeFooter}>
            <ContainerFooter>
              <FormWrapperButtonSubmit
                loading={
                  storeManagementApplicationSettings.storeFlagsAppSettingsFetch
                    .flags.isLoading
                }
                disabled={
                  !storeRbac.isUserGrantedTo(canSubmitTenableAccountForm)
                }
              >
                {translate('Save')}
              </FormWrapperButtonSubmit>
            </ContainerFooter>
          </Portal>
        </>
      </FormWrapper>
    </ContainerContent>
  )
}

export default observer(ConfigurationTenableAccountPage)
