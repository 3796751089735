import type { IIconProps } from '@app/components/Icon/types'
import { useTestAttribute } from '@app/hooks'
import { TenableColor } from '@app/styles/consts'
import * as React from 'react'

interface IIconBurgerCloseProps extends IIconProps {
  labelledBy?: string
}

const IconBurgerClose: React.FC<IIconBurgerCloseProps> = props => {
  const { testAttributeProps } = useTestAttribute('contentinfo')

  const stroke = props.color ?? TenableColor.brand_d_blue
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      {...testAttributeProps()(props.labelledBy)}
    >
      <path
        d="M3.125 3.125L16.875 16.875"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.125 16.875L16.875 3.125"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconBurgerClose
