import Leaf from '@libs/Expression/Leaf'
import Node from '@libs/Expression/Node'
import { cloneDeep } from 'lodash'
import type { NodeOrLeaf, ValueOpOrNull } from './types'
import { Combinator } from './types'

function flattenChildren(parent: Node, children: NodeOrLeaf[]): NodeOrLeaf[] {
  function flattenChildrenRec(
    parentNode: Node,
    remaining: NodeOrLeaf[],
    result: NodeOrLeaf[]
  ): NodeOrLeaf[] {
    if (!remaining.length) {
      return result
    }

    const currentChild = remaining[0]

    // remove the first item from remaining meaning we have done with it for this table
    remaining.shift()

    if (currentChild.isLeaf()) {
      // Append this currentChild which is a leaf to the result array
      result.push(currentChild)
      return flattenChildrenRec(parentNode, remaining, result)
    }

    if (currentChild.hasSameCombinator(parentNode)) {
      // The child has same operator than the parent so there is a useless parenthesis.
      // Prepend all children for the next iteration
      remaining.unshift(...currentChild.values)
      return flattenChildrenRec(parentNode, remaining, result)
    }

    // The child has higher precedence than the parent so everything
    // is normal and we add the child to the result after flattened it.
    result.push(flatten(currentChild))
    return flattenChildrenRec(parentNode, remaining, result)
  }

  const remainingChildren = cloneDeep(children)

  return flattenChildrenRec(parent, remainingChildren, [])
}

function createNode(
  op: Combinator,
  first: NodeOrLeaf,
  rest: NodeOrLeaf[]
): NodeOrLeaf {
  if (rest.length === 0) {
    return first
  }

  return new Node().setCombinator(op).setValues([first!, ...rest!])
}

/**
 * Flatten a node or a leaf.
 *
 * Note that if a leaf is passed as param, then it is already flattened.
 * Transform (keeping orders) a node like this example:
 *
 *          AND                                    AND
 *       AND   e4                    ->     e1   e2   e3   e4
 *    AND   e3
 *  e1   e2
 *  ((e1 AND e2) AND e3 ) AND e4     ->     e1 AND e2 AND e3 AND e4
 *
 *      AND                                        AND
 *   e1    AND                       ->     e1   e2   e3   e4
 *        e2   AND
 *            e3   e4
 *  e1 AND (e2 AND (e3 AND e4))      ->     e1 AND e2 AND e3 AND e4
 *
 *      AND                                        AND
 *   e1    AND                       ->     e1   e2      OR
 *        e2   OR                                     e3    e4
 *            e3   e4
 *  e1 AND (e2 AND (e3 OR e4))      ->     e1 AND e2 AND (e3 OR e4)
 *
 * @param el the element (Node or Leaf) to be flattened
 * @return a new element (Node or Leaf) that have been flattened
 */
export function flatten(el: NodeOrLeaf): NodeOrLeaf {
  if (!el.isNode()) {
    return el
  }

  // If at least one child is a node
  if (el.values.some(child => child.isNode())) {
    const newValues = flattenChildren(el, el.values)
    return new Node().setCombinator(el.combinator).setValues(newValues)
  }

  // Every child is a leaf so the parent is already flattened
  return el
}

export function Or(first: NodeOrLeaf, rest: NodeOrLeaf[]): NodeOrLeaf {
  return createNode(Combinator.OR, first, rest)
}

export function And(first: NodeOrLeaf, rest: NodeOrLeaf[]): NodeOrLeaf {
  return createNode(Combinator.AND, first, rest)
}

export function Constraint(key: string, value: [ValueOpOrNull, string]): Leaf {
  const [op, val] = value
  return Leaf.unsafeCreate(key, val).setOperator(op)
}
