import type {
  IFieldValidator,
  IFieldValidatorDef
} from '@app/stores/helpers/StoreForm/types'
import type { TranslateFn } from '@libs/i18n'
import { isDefined, isDefinedAndNotEmptyString } from '@libs/isDefined'
import { flatMap } from 'lodash'

// Export regex if needed on back-end side
export const profileNameRegexp = /^[_A-z0-9]*$/

export const profileNameTenableRegexp =
  /^(?!([tT][eE][nN][aA][bB][lL][eE])$).*$/

// Password regex to synchronize with API validation
const passwordRegex = new RegExp(
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{12,}$/
)

export const emailRegex = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

const legacyDefaultPassword = 'verysecure'

/**
 * Validate at least one of the passed validators.
 */
export const anyOfValidators = (
  ...validators: IFieldValidator[]
): IFieldValidator => {
  const validatorName = validators.map(validator => validator.name).join(', ')

  return {
    name: validatorName,
    handler: translate => {
      const validateValue: IFieldValidatorDef['isValid'] = value =>
        validators.some(v => v.handler(translate).isValid(value))

      return {
        isValid: value => {
          return validateValue(value)
        },
        onError: value => {
          return flatMap(
            validators
              .map(validator => {
                return validateValue(value)
                  ? null
                  : validator.handler(translate).onError(value)
              })
              .filter(isDefined)
          )
        },
        onSuccess: value => {
          return flatMap(
            validators
              .map(validator => {
                return validateValue(value)
                  ? validator.handler(translate).onSuccess(value)
                  : null
              })
              .filter(isDefined)
          )
        }
      }
    }
  }
}

/**
 * Validate that a field is defined and not empty.
 *
 * Usage:
 * field('firstName').addValidator(mandatory())
 */
export const mandatory = (
  validator?: Partial<IFieldValidatorDef>
): IFieldValidator => {
  return {
    name: 'mandatory',
    handler: (translate: TranslateFn) => {
      const defaultValidator: IFieldValidatorDef = {
        isValid: isDefinedAndNotEmptyString,
        onSuccess: () => null,
        onError: () => translate('Enter a value')
      }

      return {
        ...defaultValidator,
        ...validator
      }
    }
  }
}

/**
 * Validate an IP address.
 *
 * Usage:
 * field('ip').addValidator(ipFormat())
 */
export const ipFormat = (
  validator?: Partial<IFieldValidatorDef>
): IFieldValidator => {
  return {
    name: 'ipFormat',
    handler: (translate: TranslateFn) => {
      const regex = new RegExp(
        /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
      )

      const defaultValidator: IFieldValidatorDef = {
        isValid: value =>
          !isDefinedAndNotEmptyString(value) || regex.test(value),
        onSuccess: () => null,
        onError: () => translate('Invalid IP format')
      }

      return {
        ...defaultValidator,
        ...validator
      }
    }
  }
}

/**
 * Validate a hostname format.
 *
 * Usage:
 * field('hostname').addValidator(hostnameFormat())
 */
export const hostnameFormat = (
  validator?: Partial<IFieldValidatorDef>
): IFieldValidator => {
  return {
    name: 'hostnameFormat',
    handler: (translate: TranslateFn) => {
      const ipv4BasicRegexp = new RegExp(/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/)
      const dnsAddressRegex = new RegExp(/^[a-z0-9]+([-_.][a-z0-9]+)*$/i)

      const defaultValidator: IFieldValidatorDef = {
        isValid: value => {
          if (!isDefinedAndNotEmptyString(value)) {
            return true
          }

          // if it's seems to be an IP v4 => validation fails
          if (ipv4BasicRegexp.test(value)) {
            return false
          }

          return dnsAddressRegex.test(value)
        },
        onSuccess: () => null,
        onError: () => translate('Invalid hostname format')
      }

      return {
        ...defaultValidator,
        ...validator
      }
    }
  }
}

/**
 * Validate PORT format
 *
 * Usage:
 * field('port').addValidator(portFormat())
 */
export const portFormat = (
  validator?: Partial<IFieldValidatorDef>
): IFieldValidator => {
  return {
    name: 'portFormat',
    handler: (translate: TranslateFn) => {
      const defaultValidator: IFieldValidatorDef = {
        isValid: value => {
          return (
            !isDefinedAndNotEmptyString(value) ||
            (/^[0-9]+$/.test(value) &&
              Number(value) > 0 &&
              Number(value) <= 65535)
          )
        },
        onSuccess: () => null,
        onError: () => translate('Invalid port format')
      }

      return {
        ...defaultValidator,
        ...validator
      }
    }
  }
}

/**
 * Validate LDAP url
 *
 * Usage:
 * field('url').addValidator(ldapUrlFormat())
 */
export const ldapUrlFormat = (
  validator?: Partial<IFieldValidatorDef>
): IFieldValidator => {
  return {
    name: 'ldapUrlFormat',
    handler: (translate: TranslateFn) => {
      const regex = new RegExp(/^ldaps?:\/\//)

      const defaultValidator: IFieldValidatorDef = {
        isValid: value =>
          !isDefinedAndNotEmptyString(value) || regex.test(value),
        onSuccess: () => null,
        onError: () =>
          translate('LDAP urls should start by ldap:// or ldaps://')
      }

      return {
        ...defaultValidator,
        ...validator
      }
    }
  }
}

/**
 * Validate email format
 *
 * Usage:
 * field('email').addValidator(emailFormat())
 */
export const emailFormat = (
  validator?: Partial<IFieldValidatorDef>
): IFieldValidator => {
  return {
    name: 'emailFormat',
    handler: (translate: TranslateFn) => {
      const defaultValidator: IFieldValidatorDef = {
        isValid: value =>
          !isDefinedAndNotEmptyString(value) || emailRegex.test(value),
        onSuccess: () => null,
        onError: () => translate('Invalid email format')
      }

      return {
        ...defaultValidator,
        ...validator
      }
    }
  }
}

/**
 * Validate email list format
 *
 * Usage:
 * field('emails').addValidator(emailListFormat())
 */
export const emailListFormat = (
  validator?: Partial<IFieldValidatorDef>
): IFieldValidator => {
  return {
    name: 'emailListFormat',
    handler: (translate: TranslateFn) => {
      const defaultValidator: IFieldValidatorDef = {
        // value should be a non empty array of valid email addresses
        isValid: value => {
          if (isDefinedAndNotEmptyString(value)) {
            const emails = JSON.parse(value) as string[]

            return !emails.some(email => !emailRegex.test(email))
          }
          // we have the mandatory() validator before and we don't want both
          return true
        },
        onSuccess: () => null,
        onError: () => translate('Correct invalid email address')
      }

      return {
        ...defaultValidator,
        ...validator
      }
    }
  }
}

/**
 * Validate that the value is equal to an another value.
 *
 * Usage:
 * field('email').addValidator(equalTo(getValue())())
 */
export const equalTo =
  (getValue: () => string) =>
  (validator?: Partial<IFieldValidatorDef>): IFieldValidator => {
    return {
      name: 'equalTo',
      handler: (translate: TranslateFn) => {
        const defaultValidator: IFieldValidatorDef = {
          isValid: value => value === getValue(),
          onSuccess: () => null,
          onError: () => translate('Invalid value')
        }

        return {
          ...defaultValidator,
          ...validator
        }
      }
    }
  }

/**
 * Validate that the value is at least of `length` chars long.
 *
 * Usage:
 * field('email').addValidator(minLength(8)())
 */
export const minLength =
  (length: number) =>
  (validator?: Partial<IFieldValidatorDef>): IFieldValidator => {
    return {
      name: 'minLength',
      handler: (translate: TranslateFn) => {
        const defaultValidator: IFieldValidatorDef = {
          isValid: value => typeof value === 'string' && value.length >= length,
          onSuccess: () => null,
          onError: () =>
            translate('Value must be at least X characters', {
              interpolations: {
                length
              }
            })
        }

        return {
          ...defaultValidator,
          ...validator
        }
      }
    }
  }

/**
 * Validate that the value is not more than `length` chars long.
 *
 * Usage:
 * field('email').addValidator(maxLength(8)())
 */
export const maxLength =
  (length: number) =>
  (validator?: Partial<IFieldValidatorDef>): IFieldValidator => {
    return {
      name: 'minLength',
      handler: (translate: TranslateFn) => {
        const defaultValidator: IFieldValidatorDef = {
          isValid: value => typeof value === 'string' && value.length <= length,
          onSuccess: () => null,
          onError: () =>
            translate('Value must not exceed X characters', {
              interpolations: {
                length
              }
            })
        }

        return {
          ...defaultValidator,
          ...validator
        }
      }
    }
  }

/**
 * Validate that profile name doesn't contain special characters
 *
 * Usage:
 * field('profileName').addValidator(profileNameFormat())
 */
export const profileNameFormat = (
  validator?: Partial<IFieldValidatorDef>
): IFieldValidator => {
  return {
    name: 'profileNameFormat',
    handler: (translate: TranslateFn) => {
      const regex = new RegExp(profileNameRegexp)

      const defaultValidator: IFieldValidatorDef = {
        isValid: value =>
          !isDefinedAndNotEmptyString(value) || regex.test(value),
        onSuccess: () => null,
        onError: () =>
          translate('Use only alphanumerical characters and underscores')
      }

      return {
        ...defaultValidator,
        ...validator
      }
    }
  }
}

/**
 * Validate that profile name is not equals to Tenable
 *
 * Usage:
 * field('profileName').addValidator(profileNameIsNotEqualsToTenableFormat())
 */
export const profileNameIsNotEqualsToTenableFormat = (
  validator?: Partial<IFieldValidatorDef>
): IFieldValidator => {
  return {
    name: 'profileNameIsNotEqualsToTenableFormat',
    handler: (translate: TranslateFn) => {
      const regex = new RegExp(profileNameTenableRegexp)

      const defaultValidator: IFieldValidatorDef = {
        isValid: value =>
          !isDefinedAndNotEmptyString(value) || regex.test(value),
        onSuccess: () => null,
        onError: () => translate('Profile name must be different than Tenable')
      }

      return {
        ...defaultValidator,
        ...validator
      }
    }
  }
}

/**
 * Validate that the field value is greater than a value.
 *
 * Usage:
 * field('nbUsers').addValidator(minValue(0)())
 */
export const minValue =
  (min: number) =>
  (validator?: Partial<IFieldValidatorDef>): IFieldValidator => {
    return {
      name: 'minValue',
      handler: (translate: TranslateFn) => {
        const defaultValidator: IFieldValidatorDef = {
          isValid: value => Number(value) >= min,
          onSuccess: () => null,
          onError: () =>
            translate('Value must be greater than or equal to X', {
              interpolations: {
                min
              }
            })
        }

        return {
          ...defaultValidator,
          ...validator
        }
      }
    }
  }

/**
 * Validate that an IP address is unique.
 *
 * Usage:
 * field('ip').addValidator(uniqueIp(getValue())())
 */
export const uniqueIp =
  (getValue: () => Set<string>) =>
  (validator?: Partial<IFieldValidatorDef>): IFieldValidator => {
    return {
      name: 'uniqueIp',
      handler: (translate: TranslateFn) => {
        const defaultValidator: IFieldValidatorDef = {
          isValid: value => !getValue().has(String(value)),
          onSuccess: () => null,
          onError: () => translate('This address IP is already used')
        }

        return {
          ...defaultValidator,
          ...validator
        }
      }
    }
  }

/**
 * Validate that an address DNS is unique.
 *
 * Usage:
 * field('dns').addValidator(uniqueDns(getValue())())
 */
export const uniqueDns =
  (getValue: () => Set<string>) =>
  (validator?: Partial<IFieldValidatorDef>): IFieldValidator => {
    return {
      name: 'uniqueDns',
      handler: (translate: TranslateFn) => {
        const defaultValidator: IFieldValidatorDef = {
          isValid: value => !getValue().has(String(value)),
          onSuccess: () => null,
          onError: () => translate('This DNS is already used')
        }

        return {
          ...defaultValidator,
          ...validator
        }
      }
    }
  }

/**
 * Validate that distinguished name is valid.
 *
 * Usage:
 * field('dn').addValidator(dnFormat())
 */
export const dnFormat = (
  validator?: Partial<IFieldValidatorDef>
): IFieldValidator => {
  return {
    name: 'dnFormat',
    handler: (translate: TranslateFn) => {
      const formatRule = (entry: string) => {
        const entries = entry.split('=')

        if (entries.length !== 2) {
          return false
        }

        const [prop, value] = entries
        return prop?.trim().length && value?.trim().length
      }

      const defaultValidator: IFieldValidatorDef = {
        isValid: value => String(value).split(',').every(formatRule),
        onSuccess: () => null,
        onError: () => translate('This DN is not valid')
      }

      return {
        ...defaultValidator,
        ...validator
      }
    }
  }
}

/**
 * Validate that the value doesn't contain a specific word.
 *
 * Usage:
 * field('password').addValidator(doesntContain('test')())
 */
export const doesntContain =
  (word: string) =>
  (validator?: Partial<IFieldValidatorDef>): IFieldValidator => {
    return {
      name: 'doesntContain',
      handler: (translate: TranslateFn) => {
        const defaultValidator: IFieldValidatorDef = {
          isValid: value =>
            typeof value === 'string' &&
            !value.toLowerCase().includes(word.toLowerCase()),
          onSuccess: () => null,
          onError: () =>
            translate('Value must not contain X', {
              interpolations: {
                word
              }
            })
        }

        return {
          ...defaultValidator,
          ...validator
        }
      }
    }
  }

export const userPasswordFormat = (
  validator?: Partial<IFieldValidatorDef>
): IFieldValidator => {
  return {
    name: 'userPasswordFormat',
    handler: (translate: TranslateFn) => {
      const defaultValidator: IFieldValidatorDef = {
        isValid: value =>
          (!isDefinedAndNotEmptyString(value) || passwordRegex.test(value)) &&
          typeof value === 'string' &&
          !value.toLowerCase().includes(legacyDefaultPassword),
        onSuccess: () => null,
        onError: () => translate('Invalid user password format')
      }

      return {
        ...defaultValidator,
        ...validator
      }
    }
  }
}
