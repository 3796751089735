import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { InputSwitch } from '@app/components-legacy/Input'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import type { EntitySearchBookmarkEntry } from '@app/entities'
import { useAppTranslator, useStores } from '@app/hooks'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import {
  BookmarkCategoryEnum,
  BookmarkFormFieldName
} from '@app/stores/TrailFlow/StoreSearchBookmarks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  onBookmarkCreate,
  onBookmarkEdit,
  onCreateFolderForBookmarksToggle,
  onSearchBookmarkEntryFromEditDelete
} from './handlers'

interface IBookmarkFormProps {
  className?: string
  bookmark?: EntitySearchBookmarkEntry
}

const BookmarkForm: React.FC<IBookmarkFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['TrailFlow.SearchBookmarks']
  })

  const { storeTrailFlow } = useStores()

  const { storeFormCreateBookmark } = storeTrailFlow.storeSearchBookmarks

  const options = [
    {
      value: '',
      label: translate(BookmarkCategoryEnum.Uncategorized)
    }
  ].concat(
    storeTrailFlow.storeSearchBookmarks.searchBookmarksCategories.map(
      category => ({
        value: category,
        label: category
      })
    )
  )

  const hiddenFields = storeTrailFlow.storeSearchBookmarks
    .isCreatingNewFolderForBookmarks
    ? [BookmarkFormFieldName.existingCategory]
    : [BookmarkFormFieldName.newCategory]

  return (
    <ContainerFlex
      name="SaveBookmark"
      alignItems="center"
      className={props.className}
      direction="column"
      items={[
        <ContainerContent
          title={
            props.bookmark
              ? translate('Edit bookmark')
              : translate('Add to your bookmarks')
          }
        >
          <WidgetForm<BookmarkFormFieldName>
            translate={translate}
            storeForm={storeFormCreateBookmark}
            hiddenFields={hiddenFields}
            fieldsParams={{
              [BookmarkFormFieldName.existingCategory]: {
                inputType: InputType.select,
                inputProps: {
                  labelledBy: 'existingCategory',
                  selectProps: {},
                  selectOptions: options
                },
                extendedParams: {
                  labelAlignItem: 'center'
                }
              },
              [BookmarkFormFieldName.newCategory]: {
                inputType: InputType.input,
                inputProps: {
                  placeholder: translate('Placeholder name')
                }
              },
              [BookmarkFormFieldName.name]: {
                inputType: InputType.input,
                inputProps: {
                  placeholder: translate('Placeholder name')
                }
              }
            }}
            variant={ContainerFormVariant.drawer}
          />

          <ContainerFlex
            name="SaveBookmarkFooter"
            alignItems="center"
            justifyContent="space-between"
            items={[
              <InputSwitch
                label={translate('Create new folder')}
                labelledBy="newFolder"
                switchProps={{
                  onChange: onCreateFolderForBookmarksToggle(
                    storeTrailFlow.storeSearchBookmarks
                  ),
                  checked:
                    storeTrailFlow.storeSearchBookmarks
                      .isCreatingNewFolderForBookmarks
                }}
              />,

              <ContainerFlex
                name="SaveBookmarkFooterActions"
                items={[
                  props.bookmark && (
                    <FormWrapperButton
                      labelledBy="delete"
                      buttonProps={{
                        onClick: onSearchBookmarkEntryFromEditDelete(
                          storeTrailFlow.storeSearchBookmarks
                        )(props.bookmark.getPropertyAsNumber('id'))
                      }}
                    >
                      {translate('Delete')}
                    </FormWrapperButton>
                  ),

                  <FormWrapperButton
                    labelledBy={props.bookmark ? 'edit' : 'add'}
                    buttonProps={{
                      type: 'primary',
                      onClick: props.bookmark
                        ? onBookmarkEdit(storeTrailFlow.storeSearchBookmarks)(
                            props.bookmark.getPropertyAsNumber('id')
                          )
                        : onBookmarkCreate(storeTrailFlow)
                    }}
                  >
                    {props.bookmark ? translate('Edit') : translate('Add')}
                  </FormWrapperButton>
                ]}
                spaced
                spaceWidth="verySmall"
              />
            ]}
          />
        </ContainerContent>
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(BookmarkForm)
