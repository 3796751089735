import {
  ContainerFlex,
  ContainerTimeline
} from '@app/components-legacy/Container'
import {
  LabelAlt,
  LabelBreakable,
  LabelDiff
} from '@app/components-legacy/Label'
import { LabelDiffLegend } from '@app/components-legacy/Label/LabelDiff'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type * as diff from 'diff'
import * as React from 'react'
import styled from 'styled-components'

interface IEventDetailsAttributesTableChangesProps {
  className?: string
  diffs: diff.Change[]
  attributeBeforeValue: string
}

const EventDetailsAttributesTableChanges: React.FC<
  IEventDetailsAttributesTableChangesProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['TrailFlow.EventDetails.Attributes']
  })

  return (
    <ContainerFlex
      name="TimelineChangesContainer"
      className={props.className}
      direction="column"
      items={[
        <ContainerTimeline
          className="timelineChanges"
          items={[
            <div>
              <LabelAlt>{translate('Value before event')}</LabelAlt>
              <LabelBreakable labelledBy="value-before-event">
                {props.attributeBeforeValue}
              </LabelBreakable>
            </div>,

            <div>
              <LabelAlt>{translate('Value at event')}</LabelAlt>
              <LabelDiff labelledBy="value-at-event" diffs={props.diffs} />
            </div>
          ]}
        />,
        <LabelDiffLegend
          labelledBy="diffLegend"
          translate={translate}
          className="diffLegend"
        />
      ]}
      spaced
    />
  )
}

export default styled(EventDetailsAttributesTableChanges)`
  min-width: 500px;
  max-width: 500px;

  .diffLegend {
    align-self: flex-end;
  }
`
