import type { MaybeUndef, PropertiesNullable } from '@@types/helpers'
import { createEntity } from '@app/entities'
import { WIDGET_DEFAULT_LAYOUT } from '@app/stores/Dashboard/consts'
import { createDashboardKey, createWidgetKey } from '@libs/dashboards/keys'
import type { DashboardKey, WidgetKey } from '@libs/dashboards/types'
import { isDefined } from '@libs/isDefined'
import { getLogger } from '@libs/logger'
import type {
  DashboardWidget,
  DashboardWidgetOptions,
  Maybe
} from '@server/graphql/typeDefs/types'
import type { Layout } from 'react-grid-layout'
import EntityBase from './EntityBase'
import type EntityDashboard from './EntityDashboard'
import EntityDashboardWidgetOptions from './EntityDashboardWidgetOptions'

const logger = getLogger('EntityDashboardWidget')

export default class EntityDashboardWidget
  extends EntityBase
  implements PropertiesNullable<DashboardWidget>
{
  id: Maybe<number> = null
  title: Maybe<string> = null
  posX: Maybe<number> = null
  posY: Maybe<number> = null
  width: Maybe<number> = null
  height: Maybe<number> = null
  options: Maybe<DashboardWidgetOptions> = null

  constructor(
    data: Partial<DashboardWidget>,
    public readonly parent?: EntityDashboard
  ) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return the dashboardId passed as parent.
   */
  getDashboardKey(): MaybeUndef<DashboardKey> {
    if (!isDefined(this.parent)) {
      logger.debug('Missing dashboard parent entity')
      return
    }

    if (!isDefined(this.parent.instanceName)) {
      logger.debug('Missing instanceName')
      return
    }

    if (!isDefined(this.parent.id)) {
      logger.debug('Missing dashboardId')
      return
    }

    return createDashboardKey(this.parent.instanceName, this.parent.id)
  }

  /**
   * Return a key used in UI to index widgets in the layout.
   * The format is dashboardId:widgetId.
   */
  getWidgetKey(): MaybeUndef<WidgetKey> {
    const dashboardKey = this.getDashboardKey()

    if (!isDefined(dashboardKey)) {
      logger.debug('Missing dashboardKey')
      return
    }

    if (!isDefined(this.id)) {
      logger.debug('Missing id')
      return
    }

    return createWidgetKey(dashboardKey, this.id)
  }

  /**
   * Return widget options entity (singular).
   *
   * Note that since options are of different types (data and display) and because
   * options are wrapped into a Serie object, options are handled by one entity.
   *
   * So the `createEntity` (singular) here is not a mistake.
   */
  getOptions(): Maybe<EntityDashboardWidgetOptions> {
    if (!this.options) {
      return null
    }

    return createEntity<DashboardWidgetOptions, EntityDashboardWidgetOptions>(
      EntityDashboardWidgetOptions,
      this.options
    )
  }

  /**
   * Return the layout of the widget.
   */
  getLayout(): Maybe<Layout> {
    const widgetKey = this.getWidgetKey()

    if (!widgetKey) {
      return null
    }

    return {
      i: widgetKey, // Do not forget this one!
      x: this.posX || 0,
      y: this.posY || 0,
      w: Math.max(
        WIDGET_DEFAULT_LAYOUT.minW,
        this.width || WIDGET_DEFAULT_LAYOUT.minW
      ),
      h: Math.max(
        WIDGET_DEFAULT_LAYOUT.minH,
        this.height || WIDGET_DEFAULT_LAYOUT.minH
      )
    }
  }
}
