/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Pagination
 */
export interface Pagination {
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  offset: number
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  limit: number
  /**
   *
   * @type {number}
   * @memberof Pagination
   */
  total?: number
  /**
   *
   * @type {string}
   * @memberof Pagination
   */
  previous?: string
  /**
   *
   * @type {string}
   * @memberof Pagination
   */
  next?: string
  /**
   *
   * @type {string}
   * @memberof Pagination
   */
  sortBy?: PaginationSortByEnum
  /**
   *
   * @type {string}
   * @memberof Pagination
   */
  order?: PaginationOrderEnum
}

/**
 * @export
 * @enum {string}
 */
export enum PaginationSortByEnum {
  Name = 'name',
  ExposureScore = 'exposure_score',
  OpenedRisksCount = 'opened_risks_count',
  TotalAccessibleResources = 'total_accessible_resources'
}
/**
 * @export
 * @enum {string}
 */
export enum PaginationOrderEnum {
  Asc = 'asc',
  Desc = 'desc'
}

/**
 * Check if a given object implements the Pagination interface.
 */
export function instanceOfPagination(value: object): boolean {
  if (!('offset' in value)) return false
  if (!('limit' in value)) return false
  return true
}

export function PaginationFromJSON(json: any): Pagination {
  return PaginationFromJSONTyped(json, false)
}

export function PaginationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Pagination {
  if (json == null) {
    return json
  }
  return {
    offset: json['offset'],
    limit: json['limit'],
    total: json['total'] == null ? undefined : json['total'],
    previous: json['previous'] == null ? undefined : json['previous'],
    next: json['next'] == null ? undefined : json['next'],
    sortBy: json['sort_by'] == null ? undefined : json['sort_by'],
    order: json['order'] == null ? undefined : json['order']
  }
}

export function PaginationToJSON(value?: Pagination | null): any {
  if (value == null) {
    return value
  }
  return {
    offset: value['offset'],
    limit: value['limit'],
    total: value['total'],
    previous: value['previous'],
    next: value['next'],
    sort_by: value['sortBy'],
    order: value['order']
  }
}
