import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconDomainManagementProps extends IIconProps {}

const IconDomainManagement: React.FC<IIconDomainManagementProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 20

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.12586 19L9.99998 2.17934L17.8741 19H2.12586Z" stroke={fill} />
    </svg>
  )
}

export default IconDomainManagement
