import type { EntityInfrastructure } from '@app/entities'
import type StoreBoard from '@app/stores/IoA/StoreBoard'
import { consts } from '@app/styles'
import { CriticityValuesOrdered, getCriticityColor } from '@libs/criticity'
import { formatDate } from '@libs/dates/formatDate'
import { convertTimestampToIsoString } from '@libs/dates/helpers'
import type { TranslateFn } from '@libs/i18n'
import { sanitizeFilename } from '@libs/sanitize'
import type { Criticity } from '@server/graphql/typeDefs/types'
import moment from 'moment'
import PptxGenJS from 'pptxgenjs'
import {
  GLOBAL_PADDING,
  LAYOUT_HEIGHT,
  LAYOUT_WIDTH,
  PPTX_HEADER_BG_SIZE,
  PPTX_MAIN_BOTTOM_BG_SIZE,
  PPTX_COLUMN_MAX_CARDS_PER_SLIDE,
  PPTX_ROW_MAX_CARDS_PER_SLIDE,
  PPTX_TENABLE_LOGO_SIZE,
  PPTX_MAX_DOMAINS_PER_COLUMN
} from './constants'
import { IoABoardCardPPTX } from './IoABoardCardPPTX'
import type { IPPTXCoords } from './types'
import {
  addBulletText,
  addText,
  cleanColor,
  cmToInches,
  defaultTextOptions,
  heightTopBackgroundImage,
  percentHeightToInches,
  percentToInches,
  percentWidthToInches,
  pixelToInches,
  subTitleTextOptions,
  titleTextOptions
} from './utils'

const WHITE = cleanColor(consts.colorWhite)
const LIGHT_GREY = cleanColor(consts.colorGrey005)
const DEFAULT_TEXT_COLOR = cleanColor(consts.colorPPTXDefaultText)

export const exportToPPTX = (
  storeBoard: StoreBoard,
  translate: TranslateFn,
  useUtc: boolean
) => {
  const { storeSummaryCards, storeTimeline, storeInfrastructures } = storeBoard
  const { storeLayout } = storeBoard.storeRoot.stores

  /**
   * Prepare the data
   */
  const directoriesByInfractructure =
    storeInfrastructures.getDirectoriesByInfractructure({ onlySelected: true })

  const summaryCardsBlocks =
    storeSummaryCards.sortedFilteredStoresSummaryCardBlocks

  const summaryLayout = storeSummaryCards.summaryLayout

  const timelineAttackPoints = Array.from(storeTimeline.attackPoints.entries())

  // Create the presentation
  const pptx = new PptxGenJS()

  /**
   * Configuration of the document
   */
  pptx.author = storeLayout.getProductName()
  pptx.subject = `${translate('Consolidated report')}`
  pptx.title = `${translate('Consolidated report')}`
  // Define new layout for the Presentation
  pptx.defineLayout({
    name: 'A3 landscape',
    width: LAYOUT_WIDTH,
    height: LAYOUT_HEIGHT
  })

  // Set presentation to use new layout
  pptx.layout = 'A3 landscape'

  /**
   *  DEFINE SLIDE MASTERS
   * Templates in PowerPoint
   */
  const introSlideBgWidth = percentWidthToInches(80)
  const introSlideBgHeight =
    (introSlideBgWidth * PPTX_HEADER_BG_SIZE.h) / PPTX_HEADER_BG_SIZE.w

  const introSlideTenableLogoWidth = percentWidthToInches(15)
  const introSlideTenableLogoHeight =
    (introSlideTenableLogoWidth * PPTX_TENABLE_LOGO_SIZE.h) /
    PPTX_TENABLE_LOGO_SIZE.w

  pptx.defineSlideMaster({
    title: 'INTRO_SLIDE',
    background: { fill: 'FFFFFF' },
    objects: [
      {
        image: {
          path: '/w/assets/images/pptx-header-bg.png',
          w: introSlideBgWidth,
          h: introSlideBgHeight,
          x: (LAYOUT_WIDTH - introSlideBgWidth) / 2,
          y: cmToInches(2)
        }
      },
      {
        image: {
          path: '/w/assets/images/pptx-logo-tenable.png',
          w: introSlideTenableLogoWidth,
          h: introSlideTenableLogoHeight,
          x: (LAYOUT_WIDTH - introSlideTenableLogoWidth) / 2,
          y: cmToInches(2)
        }
      }
    ]
  })

  const bottomBgHeight =
    (LAYOUT_WIDTH * PPTX_MAIN_BOTTOM_BG_SIZE.h) / PPTX_MAIN_BOTTOM_BG_SIZE.w

  pptx.defineSlideMaster({
    title: 'MAIN_SLIDE',
    background: { fill: 'FFFFFF' },
    objects: [
      {
        image: {
          path: '/w/assets/images/pptx-top-bg.png',
          w: LAYOUT_WIDTH,
          h: heightTopBackgroundImage,
          x: 0,
          y: 0
        }
      },
      {
        image: {
          path: '/w/assets/images/pptx-bot-bg.png',
          w: LAYOUT_WIDTH,
          h: bottomBgHeight,
          x: 0,
          y: percentHeightToInches(100) - bottomBgHeight
        }
      }
    ]
  })

  /**
   * Generate pages
   */

  /**
   * #############################
   * FIRST PAGE - HEADER, TIMELINE
   * #############################
   *
   */
  const summarySlide = pptx.addSlide({
    masterName: 'INTRO_SLIDE'
  })

  const headerTitleSize = {
    w: percentWidthToInches(90),
    h: percentToInches(60, introSlideBgHeight)
  }

  const headerTitleCoords = {
    x: (LAYOUT_WIDTH - headerTitleSize.w) / 2,
    y: (introSlideBgHeight - headerTitleSize.h) / 2 + cmToInches(2)
  }

  addText(
    summarySlide,
    [
      {
        text: translate('Consolidated report').toUpperCase(),
        options: {
          breakLine: true,
          fontSize: 16,
          fontFace: 'Arial',
          charSpacing: 12,
          bold: true,
          paraSpaceAfter: 10
        }
      },
      {
        text: translate('Indicators of').toUpperCase(),
        options: {
          breakLine: true,
          outline: {
            color: DEFAULT_TEXT_COLOR,
            size: 1
          },
          color: WHITE,
          fontFace: 'Arial Black',
          fontSize: 54,
          charSpacing: 6
        }
      },
      {
        text: translate('Attack').toUpperCase(),
        options: {
          breakLine: true,
          fontFace: 'Arial Black',
          fontSize: 54,
          charSpacing: 6
        }
      }
    ],
    {
      ...headerTitleSize,
      ...headerTitleCoords,
      align: 'center',
      valign: 'middle'
    }
  )

  summarySlide.addText(
    [
      {
        text: `${translate('Period of time')} (${translate(
          storeTimeline.attacksSummaryPeriod
        )}${useUtc ? ' UTC' : ''})`.toUpperCase(),
        options: {
          ...defaultTextOptions,
          breakLine: true,
          bold: true
        }
      },
      {
        text: storeTimeline.formatAttacksSummaryPeriodWithTimezone(useUtc),
        options: {
          ...defaultTextOptions,
          breakLine: true,
          paraSpaceAfter: 20
        }
      },
      {
        text: translate('Timeline of attacks for the period').toUpperCase(),
        options: {
          ...defaultTextOptions,
          breakLine: true,
          bold: true
        }
      }
    ],
    {
      align: 'center',
      w: '100%',
      fit: 'resize',
      y: percentHeightToInches(65),
      h: pixelToInches(100)
    }
  )

  /**
   * Generate pills diagram
   */

  /**
   *  Compute data by criticity.
   *  Each row represent a bar for one criticity containing all labels and values
   *  for a timestamp.
   */

  const reversedCriticityOrdered = [...CriticityValuesOrdered].reverse()

  const data: Array<{
    name: Criticity | string
    labels: string[]
    values: number[]
  }> = reversedCriticityOrdered.map(criticity => {
    const { labels, values } = timelineAttackPoints.reduce(
      (acc, [timestamp, points]) => {
        acc.labels.push(
          formatDate(convertTimestampToIsoString(timestamp), {
            utc: useUtc,
            format: storeBoard.storeTimeline.tickDateFormat
          })
        )
        acc.values.push(
          Array.from(points.values()).find(
            point => point.criticity === criticity
          )?.count || 0
        )

        return acc
      },
      { labels: [] as string[], values: [] as number[] }
    )

    return {
      name: criticity,
      labels,
      values
    }
  })

  /**
   * Generate blank data for faking the chart
   * The idea is to add some data below the others in white color,
   * so that every column seems aligned by the middle
   * The max count for one timestamp is determined as a base, every other
   * timestamp will have a value which is the difference between the base and
   * its own count divided by 2 to get white below and above.
   */
  const timestampArray = Array.from(Array(data[0].labels.length))

  // Get max count from all timestamps
  const maxCount = timestampArray
    .map((_, index) => {
      return data.reduce((acc, criticity) => {
        return acc + criticity.values[index]
      }, 0)
    })
    .reduce((max, count) => {
      if (count > max) {
        return count
      }
      return max
    }, Number.MIN_VALUE)

  // Add fake datas
  data.unshift({
    name: `STYLE DATA / DONT USE IT`,
    labels: [...data[0].labels],
    values: timestampArray.map((_, index) => {
      // Sum values from each criticity
      const countValuesForTimestamp = data.reduce((acc, criticity) => {
        return acc + criticity.values[index]
      }, 0)
      // Return the delta divided by two for having space below and above
      return (maxCount - countValuesForTimestamp) / 2
    })
  })

  const heightTimelineChart = pixelToInches(70)

  const barSize = {
    w: (LAYOUT_WIDTH * 60) / 100,
    h: heightTimelineChart
  }

  const barCoords = {
    x: (LAYOUT_WIDTH - barSize.w) / 2,
    y: percentHeightToInches(65) + pixelToInches(100) + pixelToInches(20)
  }

  const chartColors = [WHITE].concat(
    reversedCriticityOrdered.map(criticity => getCriticityColor(criticity))
  )

  /**
   * The graph group each identical label in a stack
   */
  summarySlide.addChart('bar', data, {
    ...barCoords,
    ...barSize,
    chartColors,
    layout: { x: 0, y: 0, w: 1, h: 1 },
    catAxisLabelFontSize: 6,
    showLabel: false,
    showLegend: false,
    showPercent: false,
    showValue: false,
    barGapWidthPct: 240,
    catAxisLabelRotate: 0,
    lineSize: 0,
    valGridLine: {
      style: 'solid',
      size: 1,
      color: LIGHT_GREY
    },
    barGrouping: 'stacked',
    valAxisHidden: true,
    valAxisLineShow: false,
    catAxisMajorTickMark: 'none',
    catAxisMinorTickMark: 'none',
    catAxisLineColor: WHITE,
    border: {
      color: WHITE,
      pt: 0,
      type: 'none'
    },
    catAxisLabelColor: DEFAULT_TEXT_COLOR
  })

  // Border are hard to configured for this barchart.
  // Easier to add a rect just front of it
  summarySlide.addShape('rect', {
    ...barCoords,
    ...barSize,
    line: {
      color: LIGHT_GREY
    },
    lineDash: 'sysDot'
  })

  /**
   * ################################
   * SECOND PAGE - SELECTED PERIMETER
   * ################################
   *
   */

  let currentPerimeterSlide: PptxGenJS.Slide

  const defaultInfrastructureTitlePosition: IPPTXCoords = {
    x: percentWidthToInches(10),
    y: cmToInches(6)
  }

  const infraBulletSize = pixelToInches(15)
  const domainBulletSize = pixelToInches(11)
  const infrastructuresGlobalSize = percentWidthToInches(80)
  const infrastructureWidth = infrastructuresGlobalSize / 3

  let currentInfrastructureTitlePosition: IPPTXCoords = {
    ...defaultInfrastructureTitlePosition
  }

  /**
   * Add an infrastructure bullet text
   */
  const addInfrastructurePerimeterTitle = (
    infrastructure: EntityInfrastructure
  ): void => {
    addBulletText(currentPerimeterSlide, {
      bullet: {
        color: infrastructure.color,
        size: infraBulletSize
      },
      text: {
        value: infrastructure.getPropertyAsString('name'),
        options: {
          align: 'left',
          fontSize: 15
        }
      },
      position: currentInfrastructureTitlePosition,
      size: {
        w: infrastructureWidth,
        h: pixelToInches(15)
      }
    })

    // Add background rect below list of directories
    const topMargin = pixelToInches(30)
    currentPerimeterSlide.addShape('rect', {
      x: currentInfrastructureTitlePosition.x,
      y: currentInfrastructureTitlePosition.y + topMargin,
      w: infrastructureWidth - pixelToInches(20),
      h:
        LAYOUT_HEIGHT -
        topMargin -
        currentInfrastructureTitlePosition.y -
        pixelToInches(40),
      fill: {
        color: LIGHT_GREY,
        transparency: 80
      }
    })
  }

  /**
   * Generate a new perimeter slide (using the MAIN_SLIDE master)
   * Add the title and the current infrastructure bullet
   */
  const generatePerimeterSlide = (
    infrastructure: EntityInfrastructure
  ): void => {
    // Reset current position
    currentInfrastructureTitlePosition = {
      ...defaultInfrastructureTitlePosition
    }

    // Create the new slide
    currentPerimeterSlide = pptx.addSlide({
      masterName: 'MAIN_SLIDE'
    })

    // Slide title
    addText(
      currentPerimeterSlide,
      translate('Perimeter selected').toUpperCase(),
      {
        ...titleTextOptions
      }
    )

    // Add bullet perimeter title
    addInfrastructurePerimeterTitle(infrastructure)
  }

  /**
   * Move the position cursor to the next column
   */
  const moveToNextColumn = (): void => {
    currentInfrastructureTitlePosition.x += infrastructureWidth
  }
  /**
   * Return if the current position is out the allowed zone
   */
  const outOfHorizontalLimit = (): boolean => {
    return currentInfrastructureTitlePosition.x >= infrastructuresGlobalSize
  }

  Array.from(directoriesByInfractructure.entries()).forEach(
    ([infrastructure, directories], infrastructureIndex) => {
      // Add a new column every time we change to another infrastructure
      // except for the first one
      if (infrastructureIndex > 0) {
        moveToNextColumn()
      }

      // If no current slide or the next infrastructure bullet text
      // will be over the allowed size for infrastructures / directories space,
      // then add new slide page
      if (!currentPerimeterSlide || outOfHorizontalLimit()) {
        // Create a new slide (infrastructure bullet text is included)
        generatePerimeterSlide(infrastructure)
      } else {
        // Else add the infratructure bullet text
        addInfrastructurePerimeterTitle(infrastructure)
      }

      Array.from(directories.values()).forEach((directory, directoryIndex) => {
        // Determine if the next directory reach the max number allowed in
        // a column
        if (
          directoryIndex > 0 &&
          directoryIndex % PPTX_MAX_DOMAINS_PER_COLUMN === 0
        ) {
          moveToNextColumn()
        }

        // If no current slide or the next bullet text will be over the allowed
        // size for infrastructures / directories space, then add new slide page
        if (!currentPerimeterSlide || outOfHorizontalLimit()) {
          generatePerimeterSlide(infrastructure)
        }

        // Add the directory bullet text
        addBulletText(currentPerimeterSlide, {
          bullet: {
            color: infrastructure.color,
            size: domainBulletSize
          },
          text: {
            value: directory.getPropertyAsString('name'),
            options: {
              align: 'left',
              fontSize: 11
            }
          },
          position: {
            x: currentInfrastructureTitlePosition.x + pixelToInches(11),
            y:
              currentInfrastructureTitlePosition.y +
              pixelToInches(40) + // Margin bottom
              pixelToInches(20) * (directoryIndex % PPTX_MAX_DOMAINS_PER_COLUMN)
          },
          size: {
            w: infrastructureWidth,
            h: pixelToInches(15)
          }
        })
      })
    }
  )

  /**
   * #########################
   * NEXT PAGES - CARDS SLIDES
   * #########################
   *
   */

  const xMargin = 6 * GLOBAL_PADDING
  const yMargin = 2 * GLOBAL_PADDING

  const widthCard =
    percentWidthToInches(100 / PPTX_COLUMN_MAX_CARDS_PER_SLIDE) - xMargin
  const heightCard = pixelToInches(330) - yMargin

  /**
   * Generate at least one page for each sort blocks
   * There can be more than one page for each block, depending on the cards number
   * Cards are displayed in a configurable grid
   */
  summaryCardsBlocks.forEach(block => {
    const cardsGroups = storeSummaryCards.getGroupedStoreSummaryCardByRows(
      PPTX_COLUMN_MAX_CARDS_PER_SLIDE,
      block.storesSummaryCard
    )

    let boardSlide: PptxGenJS.Slide
    cardsGroups.forEach((group, groupIndex) => {
      const rowIndex = groupIndex % PPTX_ROW_MAX_CARDS_PER_SLIDE

      // No slide has been created or
      // the first row will be displayed
      if (!boardSlide || rowIndex === 0) {
        boardSlide = pptx.addSlide({
          masterName: 'MAIN_SLIDE'
        })

        // Slide title
        addText(
          boardSlide,
          [
            {
              text: `${translate('Domains impacted')}, ${translate(
                'Sorted by'
              )} ${translate(summaryLayout)}`.toUpperCase()
            },
            {
              text: block.blockName.toUpperCase(),
              options: subTitleTextOptions
            }
          ],
          titleTextOptions
        )
      }

      group.forEach((card, colIndex) => {
        new IoABoardCardPPTX(
          boardSlide,
          translate,
          card,
          {
            x: xMargin / 2 + (widthCard + xMargin) * colIndex,
            y:
              heightTopBackgroundImage -
              2 * yMargin +
              (heightCard + yMargin) * rowIndex
          },
          {
            w: widthCard,
            h: heightCard
          },
          useUtc
        ).draw()
      })
    })
  })

  const fileName = sanitizeFilename(
    `${translate('Consolidated report')}-${translate(
      'Indicators of'
    )} ${translate('Attack')}-${moment(
      storeBoard.storeTimeline.dateStart
    ).toISOString()}-${moment(storeBoard.storeTimeline.dateEnd).toISOString()}${
      useUtc ? '-UTC' : ''
    }.pptx`
  )

  pptx.writeFile({
    fileName
  })
}
