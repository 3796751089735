import { ContainerForm } from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { useAppTranslator } from '@app/hooks'
import type StoreWidgetSerie from '@app/stores/Dashboard/StoreWidgetSerie'
import * as React from 'react'
import WidgetInputLabel from '../WidgetInput/WidgetInputLabel'

export interface IWidgetInputsDisplayOptionsSwitchProps {
  storeSerie: StoreWidgetSerie
}

const WidgetInputsDisplayOptionsSwitch: React.FC<
  IWidgetInputsDisplayOptionsSwitchProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Dashboard.WidgetCommonForm']
  })

  const fields: IField[] = [
    {
      name: 'datasetName',
      label: translate('Name of the dataset'),
      description: translate(
        'You can give a custom name to this dataset to use in the legend caption'
      ),
      control: (
        // for now, label is a field available for all dashboard type
        <WidgetInputLabel storeForm={props.storeSerie.storeForm} />
      )
    }
  ]

  return <ContainerForm fields={fields} />
}

export default WidgetInputsDisplayOptionsSwitch
