import { AppMenu } from '@app/components-legacy/Menu'
import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { filterFalsies } from '@libs/filterFalsies'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canReadAlertsIoA, canReadAlertsIoE } from '../permissions'

export interface IMenuAlertsProps {}

const AlertsMenu: React.FC<IMenuAlertsProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['Drawers.Alerts']
  })

  const { storeRbac, storeAlerts } = useStores()

  useStoreMenuInit<IMenuEntry<CheckerType>>({
    storeMenu: storeAlerts.storeMenu,
    menuEntries: filterFalsies([
      storeRbac.isUserGrantedTo(canReadAlertsIoE) && {
        key: CheckerType.Exposure,
        label: translate('Exposure alerts'),
        labelledBy: 'Exposure alerts'
      },

      storeRbac.isUserGrantedTo(canReadAlertsIoA) && {
        key: CheckerType.Attack,
        label: translate('Attack alerts'),
        labelledBy: 'Attack alerts'
      }
    ]),
    defaultSelectedMenuKey: storeRbac.isUserGrantedTo(canReadAlertsIoE)
      ? CheckerType.Exposure
      : CheckerType.Attack
  })

  const showMenu = storeAlerts.storeMenu.menuEntries.length > 1

  if (!showMenu) {
    return null
  }

  return <AppMenu storeMenu={storeAlerts.storeMenu} />
}

export default observer(AlertsMenu)
