import * as React from 'react'
import { ContainerFlex } from '../Container'
import IconExternalLink from '../Icon/IconExternalLink'

export interface ILabelExternalLinkProps {
  children: React.ReactNode
}

/**
 * Display a simple label with the ExternalLink icon before.
 */
export default function LabelExternalLink(props: ILabelExternalLinkProps) {
  return (
    <ContainerFlex
      name="Label"
      alignItems="center"
      flexGap="small"
      items={[<IconExternalLink />, <div>{props.children}</div>]}
    />
  )
}
