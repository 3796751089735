import type { StorePreferences, StoreRoot } from '@app/stores'
import type { Language } from '@server/graphql/typeDefs/types'

/**
 * Initialize the form.
 */
export const onPreferencesInternationalizationLoad =
  (storePreferences: StorePreferences) => () => {
    const language = storePreferences.language

    if (!language) {
      return
    }

    storePreferences.storeFormI18n
      .setDefaultFieldsValues([
        {
          key: 'language',
          value: language
        }
      ])
      .reset()
  }

/**
 * Save the new language and reload the app.
 */
export const onPreferencesInternationalizationSubmit =
  (storeRoot: StoreRoot, storePreferences: StorePreferences) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const preferences = {
      language:
        storePreferences.storeFormI18n.getFieldValueAsString<Language>(
          'language'
        )
    }

    storePreferences
      .updatePreferences(preferences)
      .then(() => {
        // save the language in localStorage
        storeRoot.appTranslator.saveLanguage(preferences.language)

        // reload the page to apply the selected language
        document.location.reload()
      })
      .catch(() => {
        // already catched in the store
      })
  }
