import { SpanP } from '@app/components/Labels/Label/SpanP'
import {
  ContainerFlex,
  ContainerFooter,
  ContainerPopoverContent
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { sliceString } from '@libs/sliceString'
import { Popover } from 'antd'
import * as React from 'react'
import { onAttributeShowSDDLButtonClick } from './handlers'

interface IEventDetailsAttributesPopoverSddlProps {
  maxLength: number
  value: string
}

const POPOVER_NTSECURITY_DESCRIPTOR_MAX_LENGTH = 500

const EventDetailsAttributesPopoverSddl: React.FC<
  IEventDetailsAttributesPopoverSddlProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['TrailFlow.EventDetails.SDDLParser']
  })

  const {
    storeTrailFlow: { storeEventDetails }
  } = useStores()

  const { storeEventDetailsAttributes } = storeEventDetails

  return (
    <Popover
      content={
        <ContainerFlex
          name="Popover"
          direction="column"
          justifyContent="space-between"
          items={[
            <ContainerPopoverContent>
              {sliceString(props.value, {
                maxLength: POPOVER_NTSECURITY_DESCRIPTOR_MAX_LENGTH
              })}
            </ContainerPopoverContent>,

            <ContainerFooter variant="popover">
              <FormWrapperButton
                labelledBy="seeSDDLDescription"
                buttonProps={{
                  type: 'primary',
                  onClick: onAttributeShowSDDLButtonClick(
                    storeEventDetailsAttributes
                  )(props.value)
                }}
              >
                {translate('See SDDL description')}
              </FormWrapperButton>
            </ContainerFooter>
          ]}
          spaced
        />
      }
    >
      <SpanP>{sliceString(props.value, { maxLength: props.maxLength })}</SpanP>
    </Popover>
  )
}

export default EventDetailsAttributesPopoverSddl
