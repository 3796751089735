import type { EntityDirectory } from '@app/entities'
import type { StoreInfrastructures } from '@app/stores'
import type StoreGenericAlerts from '@app/stores/StoreAlerts/StoreGenericAlerts'
import type {
  CheckerType,
  Criticity,
  Maybe
} from '@server/graphql/typeDefs/types'

export enum LabelAlertSize {
  normal = '500px',
  fullWidth = '100%'
}

export interface ILabelAlertAction {
  label: string
  key: string
}

export interface ILabelAlertProps {
  className?: string
  id: number

  /* Data */

  checkerType: CheckerType
  label: string
  date: Maybe<string>
  description?: string
  criticity?: Criticity
  directories?: EntityDirectory[]

  /* Behaviors */

  size?: LabelAlertSize
  checked?: boolean
  read?: boolean
  archived?: boolean
  isCheckable?: boolean
  isClosable?: boolean
  actions?: ILabelAlertAction[]

  // time before removing the alert (in seconds)
  alertLifeTime?: number

  /* Handlers */

  onAction?: (actionKey: string) => void
  onSelections?: {
    onSelection: () => void
    onUnselection: () => void
  }

  /* Stores */

  storeInfrastructures: StoreInfrastructures
  storeGenericAlerts: StoreGenericAlerts<any>
}
