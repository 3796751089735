import {
  ContainerContent,
  ContainerFooter
} from '@app/components-legacy/Container'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import { FormWrapper } from '@app/components-legacy/Form/Wrappers'
import FormWrapperButtonSubmit from '@app/components-legacy/Form/Wrappers/ButtonSubmit'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { onDeleteDashboardValidate } from './handlers'

export interface IDrawerDeleteDashboardFormProps {}

const P = styled.p`
  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      color: ${consts.FontColorV2.primary};
    `}
`

const DrawerDeleteDashboardForm: React.FC<
  IDrawerDeleteDashboardFormProps
> = () => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Components.LabelConfirm',
      'Dashboard.Common',
      'Dashboard.DeleteDashboardDrawer'
    ]
  })

  const appRouter = useAppRouter()

  const { storeDashboards } = useStores()

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const dashboardStore = storeDashboards.currentStoreDashboard
  const dashboardName =
    dashboardStore?.dashboardEntity.getPropertyAsString('name')

  if (!dashboardName) {
    return null
  }

  return (
    <FormWrapper
      name="dashboardDeletion"
      onSubmit={onDeleteDashboardValidate(appRouter, storeDashboards)}
    >
      <ContainerContent>
        <P>
          {translate('You will remove this dashboard', {
            interpolations: { dashboardName }
          })}
        </P>

        <P>{translate('Are you sure')}</P>

        <Portal name={PlaceHolderName.drawerFooter}>
          <ContainerFooter>
            <DrawerCancelButton />

            <FormWrapperButtonSubmit
              type="primary"
              htmlType="submit"
              danger={themeIsLegacy}
              loading={
                storeDashboards.storeDashboardsManagement
                  .storeFlagsDeleteDashboard.flags.isLoading
              }
            >
              {translate('Delete')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </ContainerContent>
    </FormWrapper>
  )
}

export default observer(DrawerDeleteDashboardForm)
