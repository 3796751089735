import type { StoreAuthentication, StoreInfrastructures } from '@app/stores'
import type StoreIndicator from '@app/stores/IoE/StoreIndicator'
import { If } from '@libs/fp-helpers/If'

/**
 * Fetch deviant elements to retrieve the checker compliance status.
 */
export const handleCheckerDetailsOnLoad =
  (
    storeAuthentication: StoreAuthentication,
    storeInfrastructures: StoreInfrastructures,
    storeIndicator: StoreIndicator
  ) =>
  (checkerId: number) =>
  () => {
    const profileId = storeAuthentication.currentProfileId

    return If(!storeInfrastructures.infrastructures.size)
      .fetch(
        storeInfrastructures.fetchInfrastructures.bind(storeInfrastructures)
      )
      .then(() => {
        const directoryIds = storeInfrastructures.selectedDirectoryIds

        storeIndicator.fetchCheckerExposureComplianceStatus(
          profileId,
          checkerId,
          directoryIds
        )
      })
  }
