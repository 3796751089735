import type { DeepPartial } from '@alsid/common'
import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import PageGlobalInventory from '@app/pages/GlobalInventory'
import { AppRouteName } from '@app/routes'
import * as React from 'react'
import type { IGlobalInventoryAppConfiguration } from 'tenable-cyber-asset-management/dist/common/src/libs/IframeCommunicationBus/types'
import { canAccessToWeaknesses } from '../permissions'

interface IWeaknessesBladeProps {}

const WeaknessesBlade: React.FC<IWeaknessesBladeProps> = props => {

  const configuration: DeepPartial<IGlobalInventoryAppConfiguration> = {
    initialPage: '/weaknesses',
    preferences: {
      layout: {
        backToAssetsButtonUrl: {
          type: 'external',
          shouldGoToPreviousPage: true
        }
      }
    }
  }

  return (
    <Blade
      root={true}
      routeDefinition={{
        routeName: AppRouteName.Gi_Weaknesses,
        parameters: {}
      }}
      flags={[]}
      layout={{
        name: 'default',
        content: (
          <ContainerContent>
            <PageGlobalInventory configuration={configuration} />
          </ContainerContent>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      paddingH={0}
      paddingV={0}
      scrollChildrenContainerOverflow="hidden"
      rbacCapabilityCheck={canAccessToWeaknesses}
    />
  )
}

export default WeaknessesBlade
