import type { IUseClipboardOptions } from '@app/components/Button/ButtonCopyToClipboard/hooks'
import { useClipboard } from '@app/components/Button/ButtonCopyToClipboard/hooks'
import IconCheck from '@app/components/Icon/IconCheck'
import IconCopyToClipBoard from '@app/components/Icon/IconCopyToClipBoard'
import { FormWrapperButton } from '@app/components-legacy/Form'
import type { ButtonVariant } from '@app/components-legacy/Form/Wrappers/types'
import { ButtonSize } from '@app/components-legacy/Form/Wrappers/types'
import {
  IconCheckOutlined,
  IconCopyOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { Tooltip } from 'antd'
import type { ButtonProps } from 'antd/lib/button'
import * as React from 'react'
import styled from 'styled-components'

interface IButtonCopyToClipboardProps extends IUseClipboardOptions {
  className?: string
  variant?: 'small' | 'large'
  position?: {
    top?: number
    bottom?: number
    left?: number
    right?: number
  }
  buttonVariant?: ButtonVariant
  buttonProps?: ButtonProps
}

/**
 * Component used to copy a predefined text or a text in an input to the clipboard
 * It renders a button with a copy icon.
 */
const ButtonCopyToClipboard: React.FC<IButtonCopyToClipboardProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const { translate, copied, unsetCopied, copyToClipboard } =
    useClipboard(props)

  const delayedUnsetCopied = () => {
    // delay the change to prevent a visual bug with Ant Design tooltip
    setTimeout(unsetCopied, 300)
  }

  const variant = props.variant || 'small'

  switch (variant) {
    case 'small':
      return (
        <Tooltip
          placement="top"
          title={copied ? translate('Copied') : translate('Copy to clipboard')}
        >
          <FormWrapperButton
            labelledBy="copyToClipboard"
            icon={
              !themeIsLegacy
                ? copied
                  ? IconCheck
                  : IconCopyToClipBoard
                : undefined
            }
            variant={props.buttonVariant}
            buttonProps={{
              className: props.className,
              onClick: copyToClipboard,
              onMouseLeave: delayedUnsetCopied,
              ...props.buttonProps
            }}
          >
            {themeIsLegacy ? (
              copied ? (
                <IconCheckOutlined />
              ) : (
                <IconCopyOutlined />
              )
            ) : undefined}
          </FormWrapperButton>
        </Tooltip>
      )

    case 'large':
      return (
        <FormWrapperButton
          labelledBy="copyToClipboard"
          size={ButtonSize.large}
          variant={props.buttonVariant}
          buttonProps={{
            className: props.className,
            onClick: copyToClipboard,
            onMouseLeave: delayedUnsetCopied,
            ...props.buttonProps
          }}
        >
          <span>{translate('Copy to clipboard')}</span>
          {copied ? <IconCheckOutlined /> : <IconCopyOutlined />}
        </FormWrapperButton>
      )

    default:
      assertUnreachableCase(variant)
  }
}

/**
 * Define the absolute position if a position object is defined.
 */
function getPosition(position: IButtonCopyToClipboardProps['position']) {
  if (!position) {
    return ''
  }

  const vPos = 'bottom' in position ? 'bottom' : 'top'
  const hPos = 'left' in position ? 'left' : 'right'

  const pos = `position: absolute; ${vPos}: ${position[vPos]}px; ${hPos}: ${position[hPos]}px;}`

  return pos
}

export default styled(ButtonCopyToClipboard)`
  ${props => {
    // remove the padding when the component is used as a link.
    if (props.buttonProps && props.buttonProps.type === 'link') {
      return 'padding: 0;'
    }
    return
  }}

  // define the color on hover if used as link
  ${props => {
    if (props.buttonProps?.type === 'link') {
      return `
        color: ${consts.colorBlue015};

        &:hover,
        &:focus {
          color: ${consts.colorBlue010};
        }
      `
    }
    return ''
  }}
  
  // define the absolute position if a position object is defined
  ${props => {
    return getPosition(props.position)
  }}
`
