import { ContainerFlex } from '@app/components-legacy/Container'
import { InputSearch } from '@app/components-legacy/Input'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'

interface IIoEDomainFiltersProps {}

const IoEDomainFilters: React.FC<IIoEDomainFiltersProps> = props => {
  const translate = useAppTranslator({ namespaces: ['IoE.Board'] })

  const { storeTopology } = useStores()

  return (
    <ContainerFlex
      name="IoEDomainFilters"
      itemsFlexGrow={[1]}
      alignItems="center"
      items={[
        <InputSearch
          placeholder={translate('Search for an indicator')}
          storeInputSearch={
            storeTopology.storeTopologyDomainDetails.storeIoE.storeInputSearch
          }
        />
      ]}
    />
  )
}

export default IoEDomainFilters
