import StoreForm from '@app/stores/helpers/StoreForm'
import {
  DEFAULT_DATES,
  DEFAULT_RULEGROUP_PATH
} from '@app/stores/helpers/StoreWizard/constants'
import {
  WizardRuleFieldName,
  WizardRuleGroupFieldName
} from '@app/stores/helpers/StoreWizard/types'
import {
  isDefinitionRule,
  isWizardRuleGroup
} from '@app/stores/helpers/StoreWizard/utils'
import StoreBase from '@app/stores/StoreBase'
import type StoreRoot from '@app/stores/StoreRoot'
import type { IStoreOptions } from '@app/stores/types'
import { Combinator } from '@libs/Expression/types'
import type { IObservableArray } from 'mobx'
import { action, computed, makeObservable, observable } from 'mobx'
import {
  DEFAULT_FIELD,
  DEFAULT_RULE_FIELDS,
  DEFAULT_RULEGROUP_FIELDS
} from './constants'
import type {
  DefinitionDateValue,
  DefinitionRule,
  DefinitionRuleGroup,
  DefinitionRuleOrRuleGroup,
  WizardRule,
  WizardRuleGroup,
  WizardRuleOrRuleGroup
} from './types'
import {
  createRuleDefinition,
  createRuleGroupDefinition,
  findRuleGroup,
  findRuleOrRuleGroup,
  isDefinitionRuleGroup
} from './utils'

/**
 * # StoreWizard
 * - isDeviant is a single observable in wizard store
 * - dateRule is a single observable in wizard store
 * - values is a single non observable ruleGroup containing multiple observable rule and ruleGroup
 *
 * Example of values:
 * ```
 * ruleGroup(StoreForm, rules: [
 *   rule(StoreForm),
 *   ruleGroup(StoreForm, rules: [
 *     rule(StoreForm),
 *     rule(StoreForm),
 *     ...
 *   ]),
 *   ...
 * ])
 * ```
 */
export default class StoreWizard extends StoreBase {
  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Components.Wizard']
  })

  // StoreWizard state
  private $isSubmitting = observable.box<boolean>(false)

  private $isDeviant = observable.box<boolean>(false)
  private $deviantCombinator = observable.box<Combinator>(Combinator.AND)
  private $dateRule = observable.box<DefinitionDateValue>(DEFAULT_DATES)

  /** First ruleGroup can't be removed. */
  private $values: WizardRuleGroup = this.createWizardRuleGroup()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)

    this.resetValues()

    makeObservable(this)
  }

  private createWizardRuleGroup(
    combinator: Combinator = Combinator.AND,
    rules?: IObservableArray<WizardRuleOrRuleGroup>
  ): WizardRuleGroup {
    const newRule: WizardRule = {
      storeForm: new StoreForm(this.storeRoot, { setup: DEFAULT_RULE_FIELDS })
    }

    newRule.storeForm.setFieldValue(WizardRuleFieldName.field, DEFAULT_FIELD)

    const ruleGroup: WizardRuleGroup = {
      storeForm: new StoreForm(this.storeRoot, {
        setup: DEFAULT_RULEGROUP_FIELDS
      }),
      rules: rules ? rules : observable.array([newRule])
    }

    ruleGroup.storeForm.setFieldValue(
      WizardRuleGroupFieldName.combinator,
      combinator
    )

    return ruleGroup
  }

  private createWizardRule(
    field: string = DEFAULT_FIELD,
    value?: string | number | boolean
  ): WizardRule {
    const rule: WizardRule = {
      storeForm: new StoreForm(this.storeRoot, { setup: DEFAULT_RULE_FIELDS })
    }

    rule.storeForm.setFieldValue(WizardRuleFieldName.field, field)

    if (value !== undefined) {
      rule.storeForm.setFieldValue(WizardRuleFieldName.value, value)
    }

    return rule
  }

  /**
   * Insert new RuleOrRuleGroup in RuleGroup rules at path x.
   */
  private insertRuleOrRuleGroup(
    index: number,
    ruleGroupPath: number[],
    newRuleOrRuleGroup: WizardRuleOrRuleGroup
  ): this {
    const ruleGroup = this.getRuleGroup(ruleGroupPath)

    ruleGroup.rules.splice(index, 0, newRuleOrRuleGroup)

    return this
  }

  /**
   * Push new RuleOrRuleGroup in RuleGroup rules at path x.
   */
  private pushRuleOrRuleGroup(
    ruleGroupPath: number[],
    newRuleOrRuleGroup: WizardRuleOrRuleGroup
  ): this {
    const ruleGroup = this.getRuleGroup(ruleGroupPath)

    ruleGroup.rules.push(newRuleOrRuleGroup)

    return this
  }

  public getRuleOrRuleGroup(
    indexInRuleGroup: number,
    ruleGroupPath: number[]
  ): WizardRuleOrRuleGroup {
    return findRuleOrRuleGroup({
      indexInRuleGroup,
      ruleGroupPath,
      ruleGroup: this.$values
    })
  }

  public getRuleGroup(ruleGroupPath: number[]): WizardRuleGroup {
    return findRuleGroup({
      ruleGroupPath,
      ruleGroup: this.$values
    })
  }

  /* Actions */

  /**
   * Reset the store.
   */
  @action
  public reset(): this {
    this.resetSubmitting()
    this.resetValues()

    return this
  }

  /**
   * Reset and initialize values.
   */
  @action
  public setValues(values: DefinitionRuleGroup): this {
    this.$values.rules.clear()
    this.$values.storeForm.setFieldValue(
      WizardRuleGroupFieldName.combinator,
      values.combinator
    )

    const rootIndex = DEFAULT_RULEGROUP_PATH

    const deepPushRuleOrRuleGroup = (
      rules: DefinitionRuleOrRuleGroup[],
      ruleGroupPath: number[]
    ) => {
      rules.forEach((ruleOrRuleGroup, i) => {
        const index = ruleGroupPath.concat(i)

        if (isDefinitionRuleGroup(ruleOrRuleGroup)) {
          this.pushRuleGroup(
            ruleGroupPath,
            ruleOrRuleGroup.combinator,
            observable.array()
          )

          if (ruleOrRuleGroup.rules.length) {
            deepPushRuleOrRuleGroup(ruleOrRuleGroup.rules, index)
          }
        }

        if (isDefinitionRule(ruleOrRuleGroup)) {
          this.pushRule(
            ruleGroupPath,
            ruleOrRuleGroup.field,
            ruleOrRuleGroup.value
          )
        }
      })
    }

    if (values.rules.length) {
      deepPushRuleOrRuleGroup(values.rules, rootIndex)
    }

    return this
  }

  @action
  public resetValues(): this {
    this.resetSubmitting()
    this.$values.rules.clear()

    this.$values.storeForm.reset()
    this.$values.storeForm.setFieldValue(
      WizardRuleGroupFieldName.combinator,
      Combinator.AND
    )
    this.pushRule([0])

    this.setDeviant(false)
    this.setDateRule(DEFAULT_DATES)

    return this
  }

  @action
  public resetSubmitting(): this {
    this.setSubmitting(false)
    return this
  }

  @action
  public setSubmitting(submitting: boolean): this {
    this.$isSubmitting.set(submitting)
    return this
  }

  @action
  public setDeviant(isDeviant: boolean): this {
    this.$isDeviant.set(isDeviant)
    return this
  }

  @action
  public setDeviantCombinator(combinator: Combinator): this {
    this.$deviantCombinator.set(combinator)
    return this
  }

  @action
  public setDateRule(dateRule: DefinitionDateValue): this {
    this.$dateRule.set(dateRule)
    return this
  }

  /**
   * Insert new RuleGroup at path x.
   */
  @action
  public insertRuleGroup(
    indexToInsert: number,
    ruleGroupPath: number[],
    combinator: Combinator = Combinator.AND,
    rules?: IObservableArray<WizardRuleOrRuleGroup>
  ): this {
    const ruleGroup = this.createWizardRuleGroup(combinator, rules)

    return this.insertRuleOrRuleGroup(indexToInsert, ruleGroupPath, ruleGroup)
  }

  /**
   * Push new RuleGroup at the end of RuleGroup rules.
   */
  @action
  public pushRuleGroup(
    ruleGroupPath: number[],
    combinator: Combinator = Combinator.AND,
    rules?: IObservableArray<WizardRuleOrRuleGroup>
  ): this {
    const ruleGroup = this.createWizardRuleGroup(combinator, rules)

    return this.pushRuleOrRuleGroup(ruleGroupPath, ruleGroup)
  }

  /**
   * Insert new Rule at path x.
   */
  @action
  public insertRule(
    indexToInsert: number,
    ruleGroupPath: number[],
    field: string = DEFAULT_FIELD,
    value?: string | number | boolean
  ): this {
    const rule = this.createWizardRule(field, value)

    return this.insertRuleOrRuleGroup(indexToInsert, ruleGroupPath, rule)
  }

  /**
   * Push new Rule at the end of RuleGroup rules.
   */
  @action
  public pushRule(
    ruleGroupPath: number[],
    field: string = DEFAULT_FIELD,
    value?: string | number | boolean
  ): this {
    const rule = this.createWizardRule(field, value)

    return this.pushRuleOrRuleGroup(ruleGroupPath, rule)
  }

  /**
   * Remove Rule or RuleGroup at path x.
   */
  @action
  public removeRuleOrRuleGroup(
    indexToRemove: number,
    ruleGroupPath: number[]
  ): this {
    const parentRuleGroup = findRuleGroup({
      ruleGroupPath,
      ruleGroup: this.$values
    })

    parentRuleGroup.rules.splice(indexToRemove, 1)

    return this
  }

  /* Computed */

  @computed
  get isSubmitting(): boolean {
    return this.$isSubmitting.get()
  }

  @computed
  get isDeviant(): boolean {
    return this.$isDeviant.get()
  }

  @computed
  get deviantCombinator(): Combinator {
    return this.$deviantCombinator.get()
  }

  @computed
  get dateRule(): DefinitionDateValue {
    return this.$dateRule.get()
  }

  @computed
  get values(): DefinitionRuleGroup {
    const defaultRuleGroup = (
      ruleGroup: WizardRuleGroup
    ): DefinitionRuleGroup => {
      return createRuleGroupDefinition({
        combinator: ruleGroup.storeForm.getFieldValueAsString(
          WizardRuleGroupFieldName.combinator
        ),
        rules: deepTransform(ruleGroup)
      })
    }

    const defaultRule = (rule: WizardRule): DefinitionRule => {
      return createRuleDefinition({
        field: rule.storeForm.getFieldValueAsString(WizardRuleFieldName.field),
        value: rule.storeForm.getFieldValueAsString(WizardRuleFieldName.value)
      })
    }

    function deepTransform(
      ruleGroup: WizardRuleGroup
    ): DefinitionRuleOrRuleGroup[] {
      if (!ruleGroup.rules.length) {
        return []
      }

      return ruleGroup.rules.map(ruleOrRuleGroup => {
        const defaultValue = isWizardRuleGroup(ruleOrRuleGroup)
          ? defaultRuleGroup(ruleOrRuleGroup)
          : defaultRule(ruleOrRuleGroup)

        return ruleOrRuleGroup.storeForm
          .getFieldNames()
          .reduce((acc, fieldName) => {
            return {
              ...acc,
              [fieldName]:
                ruleOrRuleGroup.storeForm.getFieldValueAsString(fieldName)
            }
          }, defaultValue)
      })
    }

    return this.$values.storeForm.getFieldNames().reduce((acc, fieldName) => {
      return {
        ...acc,
        [fieldName]: this.$values.storeForm.getFieldValueAsString(fieldName)
      }
    }, defaultRuleGroup(this.$values))
  }
}
