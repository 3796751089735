import type { IIconProps } from '@app/components/Icon/types'
import { FontColorV2, colorWhite } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

interface IIconIdentitiesProps extends IIconProps {}

const Color1Path = styled.path`
  fill: ${FontColorV2.primary};
  stroke: ${FontColorV2.primary};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${colorWhite};
    stroke: ${colorWhite};
  }
`

const IconIdentitiesLegacy: React.FC<IIconIdentitiesProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Color1Path
        d="M4.375 15.625C4.375 13.5539 6.05393 11.875 8.125 11.875H11.875C13.9461 11.875 15.625 13.5539 15.625 15.625M13.125 6.25C13.125 7.97589 11.7259 9.375 10 9.375C8.27411 9.375 6.875 7.97589 6.875 6.25C6.875 4.52411 8.27411 3.125 10 3.125C11.7259 3.125 13.125 4.52411 13.125 6.25Z"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconIdentitiesLegacy
