import type { PropertiesNullable } from '@@types/helpers'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import { getLogger } from '@libs/logger'
import type {
  Maybe,
  UserTrace,
  UserTraceLogSource,
  UserTraceLogType
} from '@server/graphql/typeDefs/types'
import { sanitizeCsvValue } from '../../libs/csv-protector'
import EntityBase from './EntityBase'
import type {
  IEntityExportable,
  IEntityExportableAsCsvColumnsParameters,
  IEntityExportableAsCsvRowParameters
} from './types'

const logger = getLogger('EntityUserLog')

export interface IRoleFieldChanged {
  roleName: string
  entity: string
  field: string | 0 | null | undefined
  action: string
}

export type IConfigurationChangedItemField = string | IRoleFieldChanged

export interface IConfigurationChangedItem {
  before: string | boolean | string[]
  after: string | boolean | string[]
  field: IConfigurationChangedItemField
  directoryId?: number
  directoryName?: string
  infrastructureName?: string
  infrastructureId?: number
  profileId?: number
  profileName?: string
  attackTypeId?: number
  attackTypeName?: string
  checkerId?: number
  checkerName?: string
  fieldType?: ConfigurationType
  directoryIds?: number[]
  directoryNames?: string[]
}

export enum ConfigurationType {
  ioa = 'ioa',
  role = 'role',
  applicationSetting = 'application-setting',
  attackTypeOption = 'attack-type-option',
  infrastructure = 'infrastructure',
  user = 'user',
  lockoutPolicy = 'lockout-policy',
  userRole = 'userRole',
  ldapConfiguration = 'ldap-configuration',
  samlConfiguration = 'saml-configuration',
  directory = 'directory',
  checkerOption = 'checker-option'
}

export interface IConfigurationChanged {
  configurationType: ConfigurationType
  items: IConfigurationChangedItem[]
}

export interface IDirectoryConfigurationChanged {
  directoryName?: string
  directoryId?: number
}

export interface IInfrastructureConfigurationChanged {
  infrastructureName?: string
  infrastructureId?: number
}

export interface IRoleCreationOrDeletion {
  roleName: string
  roleDescription: string
}

export interface IReportConfigurationCreationOrDeletion {
  reportName: string
}

export interface IProfileCreationOrDeletionOrUnstaging {
  profileId: number
  profileName: string
}

export interface IUserChanged {
  userName?: string
  userSurname?: string
  items?: IConfigurationChangedItem[]
  configurationType?: ConfigurationType
}

export enum IApiCallRestVerb {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}
export interface IApiCall {
  verb: IApiCallRestVerb
  route: string
  payload?: string
  status: number
}
export interface IPageVisited {
  routeName: string
  routePath?: string
  parents?: string[]
}

export default class EntityUserLog
  extends EntityBase
  implements PropertiesNullable<UserTrace>, IEntityExportable<UserTrace>
{
  id: Maybe<number> = null
  createdAt: Maybe<string> = null
  userId: Maybe<number> = null
  userEmail: Maybe<string> = null
  userIp: Maybe<string> = null
  logTitle: Maybe<string> = null
  logSource: Maybe<UserTraceLogSource> = null
  logType: Maybe<UserTraceLogType> = null
  logAttributes: Maybe<string> = null

  decodedLogAttributes?:
    | IConfigurationChanged
    | IDirectoryConfigurationChanged
    | IInfrastructureConfigurationChanged
    | IRoleCreationOrDeletion
    | IUserChanged
    | IApiCall
    | IPageVisited

  constructor(data: Partial<UserTrace>) {
    super()
    Object.assign(this, data)

    if (this.logAttributes) {
      try {
        this.decodedLogAttributes = JSON.parse(this.logAttributes)
      } catch (e) {
        logger.error('[ActivityLogs] Invalid JSON for trace', this.id)
      }
    }
  }

  /** CSV export functions */

  /**
   * Return the column labels for CSV exports.
   */

  getCSVColumns(
    parameters: IEntityExportableAsCsvColumnsParameters<UserTrace>
  ): Array<IWidgetListColumns<UserTrace>> {
    const columns: Array<IWidgetListColumns<UserTrace>> = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Date',
        key: 'createdAt'
      },
      {
        label: 'User ID',
        key: 'userId'
      },
      {
        label: 'User Email',
        key: 'userEmail'
      },
      {
        label: 'User IP',
        key: 'userIp'
      },
      {
        label: 'Log Title',
        key: 'logTitle'
      },
      {
        label: 'Log Source',
        key: 'logSource'
      },
      {
        label: 'Log Type',
        key: 'logType'
      },
      {
        label: 'Log Attributes',
        key: 'logAttributes'
      }
    ]

    return columns.filter(column => !parameters.omitKeys.includes(column.key))
  }

  /**
   * Return the entity as a raw for CSV exports.
   */
  asCSVRow(
    parameters: IEntityExportableAsCsvRowParameters<UserTrace>
  ): Maybe<string> {
    const csvRowValues: Record<keyof UserTrace, Maybe<string | number>> = {
      id: this.id,
      createdAt: this.createdAt,
      userId: this.userId,
      userEmail: this.userEmail,
      userIp: this.userIp,
      logTitle: this.logTitle,
      logSource: this.logSource,
      logType: this.logType,
      logAttributes: this.logAttributes
    }

    const columnsKeys = this.getCSVColumns(parameters).map(column => column.key)

    return Object.entries(csvRowValues)
      .filter(([key]) => columnsKeys.includes(key as keyof UserTrace))
      .map(([_, value]) => value)
      .map(value => sanitizeCsvValue(value))
      .join(parameters.separator)
  }
}
