import type { Mutation } from '../typeDefs/types'

export type MutationRefreshApiKey = Pick<Mutation, 'refreshApiKey'>

export const mutationRefreshApiKey = `
  mutation mutationRefreshApiKey {
    refreshApiKey {
      key
    }
  }
`
