import type { MaybeUndef } from '@@types/helpers'
import WidgetListPaginationNew from '@app/components/Widgets/WidgetList/WidgetListPagination'
import {
  ContainerFlex,
  ContainerSimple
} from '@app/components-legacy/Container'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { Pagination } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export interface IWidgetListPaginationProps {
  className?: string
  style?: React.CSSProperties
  onChange: (page: number, perPage: MaybeUndef<number>) => void
  storeWidgetList: StoreWidgetList<any, any>
}

/**
 * @deprecated
 */
const WidgetListPagination: React.FC<IWidgetListPaginationProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  if (!themeIsLegacy) {
    return (
      <WidgetListPaginationNew
        className={props.className}
        style={props.style}
        onChange={props.onChange}
        storeWidgetList={props.storeWidgetList}
      />
    )
  }

  if (!props.storeWidgetList.paginationTotalCount) {
    return null
  }

  // in case page number * perPage number is greater than the total count
  // we have to display the last page
  if (
    (props.storeWidgetList.paginationPage! - 1) *
      props.storeWidgetList.rowsPerPage! >=
    props.storeWidgetList.paginationTotalCount!
  ) {
    const lastPage = Math.floor(
      props.storeWidgetList.paginationTotalCount! /
        props.storeWidgetList.rowsPerPage!
    ) // euclidian division to get the last page
    props.storeWidgetList.updatePagination({ page: lastPage })
    // we need to load the elements of the new page
    props.onChange(lastPage, props.storeWidgetList.rowsPerPage)
  }

  return (
    <ContainerFlex
      name={WidgetListPagination.name}
      justifyContent="flex-end"
      className={props.className}
      style={props.style}
      items={[
        <Pagination
          size="small"
          defaultCurrent={1}
          current={props.storeWidgetList.paginationPage}
          pageSize={props.storeWidgetList.rowsPerPage}
          total={props.storeWidgetList.paginationTotalCount}
          onChange={props.onChange}
          itemRender={(page, type, originalElement) => {
            return (
              <ContainerSimple
                labelledBy={type === 'page' ? `page-${page}` : type}
              >
                {originalElement}
              </ContainerSimple>
            )
          }}
          // TODO https://github.com/AlsidOfficial/AlsidForAD-Cassiopeia/issues/4072
          showSizeChanger={false}
        />
      ]}
    />
  )
}

/**
 * @deprecated
 */
export default observer(WidgetListPagination)
