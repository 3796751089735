import { Features } from '@alsid/common'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  accessByFeatureFlag,
  editDataCollection,
  editTenableCloudApiToken,
  readTenableCloudApiToken
} from '@libs/rbac/permissions'
import { canAccessToDataCollectionConfiguration } from '../../permissions'

export const canEditDataCollectionConfiguration = rbacCapabilityCheckAllOf(
  canAccessToDataCollectionConfiguration,
  editDataCollection()
)

/* AzureAD support configuration supposes having access to the TenableCloudApiToken entity. */

export const canReadAzureAdSupport = rbacCapabilityCheckAllOf(
  // Check FF
  accessByFeatureFlag(Features.AZURE_AD_ADDON)(),
  readTenableCloudApiToken()
)

export const canEditAzureAdSupport = rbacCapabilityCheckAllOf(
  canReadAzureAdSupport,
  editTenableCloudApiToken()
)
