import { useStores } from '@app/hooks'
import { NB_MAX_ROWS } from '@app/stores/APIInspector'
import { range } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import CellDate from './CellDate'
import CellJSON from './CellJSON'
import CellMethod from './CellMethod'
import CellStatusCode from './CellStatusCode'
import CellUrl from './CellUrl'
import TableRow from './TableRow'

export interface ITableRowsProps {}

const TableRows: React.FC<ITableRowsProps> = props => {
  const { storeAPIInspector } = useStores()

  const isNoEvent = storeAPIInspector.events.length === 0

  if (isNoEvent) {
    return <div>No events yet</div>
  }

  const toIndex = Math.min(storeAPIInspector.events.length, NB_MAX_ROWS)

  return (
    <>
      {range(0, toIndex).map(i => {
        const event = storeAPIInspector.events[i]

        if (!event) {
          return null
        }

        return (
          <TableRow key={event.uuid}>
            <CellDate>{event.date}</CellDate>
            <CellMethod>{event.method || '-'}</CellMethod>
            <CellUrl>{event.url || '-'}</CellUrl>
            <CellStatusCode>{event.statusCode}</CellStatusCode>
            <CellJSON payload={event.bodyPayload} />
            <CellJSON payload={event.responsePayload} />
          </TableRow>
        )
      })}
    </>
  )
}

export default observer(TableRows)
