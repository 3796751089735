import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import * as React from 'react'

interface IIconCloseProps extends IIconProps {}

const IconClose: React.FC<IIconCloseProps> = props => {
  const fill = props.color ?? consts.colorWhite
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-1154.000000, -1047.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g transform="translate(1162.000200, 1055.000000) rotate(-360.000000) translate(-1162.000200, -1055.000000) translate(1154.000000, 1047.000000)">
            <polygon points="16.0004 1.02522563 14.9751744 0 8.00020001 6.97477437 1.02502563 0 0 1.02522563 6.97517438 8 0 14.9747744 1.02502563 16 8.00020001 9.02522563 14.9751744 16 16.0004 14.9747744 9.02562564 8" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconClose
