import { BladeCancelButton } from '@app/components-legacy/Blade'
import { ContainerFooter } from '@app/components-legacy/Container'
import { FormWrapperButtonSubmit } from '@app/components-legacy/Form/Wrappers'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreFlags from '@app/stores/helpers/StoreFlags'
import * as React from 'react'
import type { UserFormVersion } from './UserCommonForm'

interface IUserCreateEditBladeFooterProps {
  version: UserFormVersion
  storeFlags: StoreFlags
  onCancelClick: () => void
  canValidate: boolean
}

const UserCreateEditBladeFooter: React.FC<
  IUserCreateEditBladeFooterProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.Accounts.Users']
  })

  return (
    <ContainerFooter>
      <BladeCancelButton />

      <FormWrapperButtonSubmit
        loading={props.storeFlags.isLoading}
        disabled={!props.canValidate}
      >
        {props.version === 'creation' ? translate('Create') : translate('Edit')}
      </FormWrapperButtonSubmit>
    </ContainerFooter>
  )
}

export default UserCreateEditBladeFooter
