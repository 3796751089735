import type { IIconProps } from '@app/components/Icon/types'
import { TenableColor } from '@app/styles/consts'
import * as React from 'react'

interface IIconHelpOutlinedProps extends IIconProps {}

const IconHelpOutlined: React.FC<IIconHelpOutlinedProps> = props => {
  const stroke = props.color ?? TenableColor.brand_d_blue
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M10 18.125C14.4873 18.125 18.125 14.4873 18.125 10C18.125 5.51269 14.4873 1.875 10 1.875C5.51269 1.875 1.875 5.51269 1.875 10C1.875 14.4873 5.51269 18.125 10 18.125Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0125 13.75L10 13.7625"
        stroke={stroke}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99999 10.85V10.625C9.99999 9.07499 12.2875 9.29999 12.2875 7.49999C12.2875 6.37499 10.775 4.89999 8.34357 6.61157"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default IconHelpOutlined
