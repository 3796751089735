import { ContainerHTMLContent } from '@app/components-legacy/Container'
import Modal from '@app/components-legacy/Modal/Modal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  handleProfileCancelCommitOnClick,
  handleProfileCommitOnSubmit
} from './handlers'

interface IModalProfileBeforeCommitProps {}

const ModalProfileBeforeCommit: React.FC<
  IModalProfileBeforeCommitProps
> = () => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Profiles.Modal']
  })

  const { storeManagementProfiles } = useStores()

  const profileData = storeManagementProfiles.storeModalProfileBeforeCommit.data

  if (!profileData) {
    return null
  }

  return (
    <Modal
      modalProps={{
        title: translate('Important information'),
        onCancel: handleProfileCancelCommitOnClick(storeManagementProfiles),
        onOk: handleProfileCommitOnSubmit(storeManagementProfiles)(profileData),
        okButtonProps: {
          danger: true,
          loading:
            storeManagementProfiles.storeFlagsCommitProfile.flags.isLoading
        }
      }}
      storeModal={storeManagementProfiles.storeModalProfileBeforeCommit}
    >
      <ContainerHTMLContent padded>
        {translate('You will validate the customization for this profile', {
          transformMarkdown: true
        })}
      </ContainerHTMLContent>
    </Modal>
  )
}

export default observer(ModalProfileBeforeCommit)
