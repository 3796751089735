import type { Maybe } from '@@types/helpers'
import type { IUseClipboardOptions } from '@app/components/Button/ButtonCopyToClipboard/hooks'
import { ErrorBoundary, ErrorMessage } from '@app/components/Error'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { ButtonCopyToClipboard } from '@app/components-legacy/Button'
import {
  ContainerFlex,
  ContainerFooter,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import {
  DrawerContext,
  useDrawerContext
} from '@app/components-legacy/Drawer/context'
import { LabelMissingData } from '@app/components-legacy/Label'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import type { IAdObjetAttribute } from '@app/entities/EntityAdObject/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { ValueType } from '@libs/valueTypeParser/types'
import * as React from 'react'
import DrawerAttributeValueContentPre from './DrawerAttributeValueContentPre'
import DrawerAttributeValueContentValue from './DrawerAttributeValueContentValue'

interface IDrawerAttributeValueContentProps extends IUseClipboardOptions {
  className?: string
  attribute: Maybe<IAdObjetAttribute>
}

const DrawerAttributeValueContent: React.FC<
  IDrawerAttributeValueContentProps
> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const contextValue = useDrawerContext<{
    attribute: IAdObjetAttribute
  }>(DrawerContext)

  const translate = useAppTranslator({
    namespaces: ['TrailFlow.EventDetails.DrawerAttributeValue']
  })

  const renderAttributeValue = (attribute: IAdObjetAttribute) => {
    switch (attribute.valueType) {
      case ValueType.object:
      case ValueType.arrayObject:
        return (
          <DrawerAttributeValueContentValue labelledBy="drawer-content-value">
            <DrawerAttributeValueContentPre>
              {JSON.stringify(JSON.parse(attribute.value), null, 2)}
            </DrawerAttributeValueContentPre>
          </DrawerAttributeValueContentValue>
        )

      default:
        return (
          <DrawerAttributeValueContentValue labelledBy="drawer-content-value">
            {attribute.value}
          </DrawerAttributeValueContentValue>
        )
    }
  }

  if (!props.attribute || !contextValue) {
    return <LabelMissingData />
  }

  return (
    <>
      <ContainerFlex
        name="DrawerAttributeValueContent"
        direction="column"
        items={[
          themeIsLegacy ? (
            <ContainerHTMLContent>
              {translate('Value of attribute X', {
                interpolations: {
                  attributeName: props.attribute.attributeName
                },
                transformMarkdown: true
              })}
            </ContainerHTMLContent>
          ) : (
            <Label variant={LabelVariant.p}>
              <ContainerHTMLContent>
                {translate('Value of attribute X', {
                  interpolations: {
                    attributeName: props.attribute.attributeName
                  },
                  transformMarkdown: true
                })}
              </ContainerHTMLContent>
            </Label>
          ),

          /* Wrap to protect against JSON.parse exceptions */
          <ErrorBoundary errorComponent={<ErrorMessage />}>
            {renderAttributeValue(props.attribute)}
          </ErrorBoundary>
        ]}
        spaced
      />

      <Portal name={PlaceHolderName.drawerFooter}>
        <ContainerFooter>
          <DrawerCancelButton />

          <ButtonCopyToClipboard
            variant="large"
            textToCopy={
              contextValue.storeDrawer.getDataValue('attribute')?.value
            }
            buttonProps={{
              type: 'primary'
            }}
          />
        </ContainerFooter>
      </Portal>
    </>
  )
}

export default DrawerAttributeValueContent
