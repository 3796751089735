import type { StoreLicense } from '@app/stores'

/**
 * Toggle ribbon visibility.
 */
export const handleWidgetRibbonVisibilityOnClick =
  (storeLicense: StoreLicense, isVisible: boolean) => () => {
    storeLicense.setRibbonVisibility(isVisible)
  }

/**
 * Show the hidden ribbon every x times.
 */
export const handleAppRibbonTimer =
  (storeLicense: StoreLicense, ribbonTimer: number) => () => {
    if (storeLicense.isRibbonVisible) {
      return
    }

    const timeoutId = setTimeout(
      () => storeLicense.setRibbonVisibility(true),
      ribbonTimer
    )

    return () => clearTimeout(timeoutId)
  }
