import { LabelDate, LabelNonBreakable } from '@app/components-legacy/Label'
import type { EntityEvent } from '@app/entities'
import * as React from 'react'
import TableContentBodyCell from '../TableContentBodyCell'
import CellEmpty from './CellEmpty'

export interface ICellDateProps {
  event?: EntityEvent
  isLive: boolean
}

/**
 * Use native Date object in live to improve perfs.
 */
function format(date: string) {
  if (date === '-') {
    return '-'
  }

  const d = new Date(date)

  return [
    [d.getHours(), d.getMinutes(), d.getSeconds()]
      .map(v => String(v).padStart(2, '0'))
      .join(':'),
    [d.getFullYear(), d.getMonth() + 1, d.getDate()]
      .map(v => String(v).padStart(2, '0'))
      .join('-')
  ].join(', ')
}

const CellDate: React.FC<ICellDateProps> = props => {
  const getCellContent = () => {
    if (!props.event) {
      return <CellEmpty />
    }

    if (props.isLive) {
      return (
        <LabelNonBreakable labelledBy="date">
          {format(props.event.getPropertyAsString('date', '-'))}
        </LabelNonBreakable>
      )
    }

    return (
      <LabelDate
        labelledBy="date"
        date={props.event.getPropertyAsString('date', '-')}
      />
    )
  }

  return (
    <div className="eventDate">
      <TableContentBodyCell>{getCellContent()}</TableContentBodyCell>
    </div>
  )
}

export default CellDate
