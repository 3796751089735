import type { IIconProps } from '@app/components/Icon/types'
import { FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconInfoProps extends IIconProps {}

const IconInfo: React.FC<IIconInfoProps> = props => {
  const stroke = props.color ?? FontColorV2.white
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      style={props.style}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.01 5L8 5.01"
        stroke={stroke}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7.45001V11.45"
        stroke={stroke}
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default IconInfo
