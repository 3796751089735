/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ErrorResponse, IdentitiesResponse } from '../models/index'
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  IdentitiesResponseFromJSON,
  IdentitiesResponseToJSON
} from '../models/index'

export interface GetIdentitiesRequest {
  tenantIds: Array<string>
  textQuery?: string
  offset?: number
  limit?: number
  sortBy?: GetIdentitiesSortByEnum
  order?: GetIdentitiesOrderEnum
}

/**
 *
 */
export class IdentitiesApi extends runtime.BaseAPI {
  /**
   * Get all identities
   */
  async getIdentitiesRaw(
    requestParameters: GetIdentitiesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IdentitiesResponse>> {
    if (requestParameters['tenantIds'] == null) {
      throw new runtime.RequiredError(
        'tenantIds',
        'Required parameter "tenantIds" was null or undefined when calling getIdentities().'
      )
    }

    const queryParameters: any = {}

    if (requestParameters['textQuery'] != null) {
      queryParameters['text_query'] = requestParameters['textQuery']
    }

    if (requestParameters['tenantIds'] != null) {
      queryParameters['tenant_ids[]'] = requestParameters['tenantIds']
    }

    if (requestParameters['offset'] != null) {
      queryParameters['offset'] = requestParameters['offset']
    }

    if (requestParameters['limit'] != null) {
      queryParameters['limit'] = requestParameters['limit']
    }

    if (requestParameters['sortBy'] != null) {
      queryParameters['sort_by'] = requestParameters['sortBy']
    }

    if (requestParameters['order'] != null) {
      queryParameters['order'] = requestParameters['order']
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/core/ad/identities`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      IdentitiesResponseFromJSON(jsonValue)
    )
  }

  /**
   * Get all identities
   */
  async getIdentities(
    requestParameters: GetIdentitiesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IdentitiesResponse> {
    const response = await this.getIdentitiesRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetIdentitiesSortByEnum {
  Name = 'name',
  ExposureScore = 'exposure_score',
  OpenedRisksCount = 'opened_risks_count',
  TotalAccessibleResources = 'total_accessible_resources'
}
/**
 * @export
 * @enum {string}
 */
export enum GetIdentitiesOrderEnum {
  Asc = 'asc',
  Desc = 'desc'
}
