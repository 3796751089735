import type {
  AttacksSummaryPeriod,
  Criticity
} from '@server/graphql/typeDefs/types'

export interface IStoreTimelineOptions {
  attacksSummaryPeriod: AttacksSummaryPeriod
}

export enum TimelineInterval {
  Every5Minutes = '5m',
  EveryHour = '1h',
  EveryDay = '1d',
  EveryMonth = '1M'
}

// All points of the timeline (key is a timestamp as string)
export type TimelineAttackPoints = Map<string, Set<ITimelineAttackPoint>>

// one point of the timeline
export interface ITimelineAttackPoint {
  criticity: Criticity
  count: number
}
