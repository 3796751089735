import { ContainerHTMLContent } from '@app/components/Container'
import { ContainerFlex } from '@app/components/Container/ContainerFlex'
import { IconVector } from '@app/components/Icon'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import LinkSimple from '@app/components/LinkSimple/LinkSimple'
import { FormWrapperSwitch } from '@app/components-legacy/Form/Wrappers'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import Modal from '@app/components-legacy/Modal/Modal'
import { useAppRouter, useAppTranslator, useStores } from '@app/hooks'
import type StoreModal from '@app/stores/helpers/StoreModal'
import { consts } from '@app/styles'
import { IOA_CONFIGURATION_FILENAME } from '@libs/common/consts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import AlertIoaAutomaticConfigurationSuccess from './AlertIoaAutomaticConfigurationSuccess'
import AlertIoaManualConfiguration from './AlertIoaManualConfiguration'
import {
  handleDownloadIoAConfigButtonClick,
  handleDownloadScriptButtonClick
} from './ConfigurationAttackTypeFooter/handlers'
import ContainerScriptCommands from './ContainerScriptCommands'
import { handleAutomaticConfigurationOnChange } from './handlers'

interface IModalProcedureProps {
  storeModal: StoreModal<any>
}

const MessageContainerFlex = styled(ContainerFlex)<{
  isIoAAutomaticConfiguration: boolean
}>`
  padding: 15px 20px;
  margin-bottom: ${props =>
    props.isIoAAutomaticConfiguration
      ? consts.marginSmall
      : consts.marginDefault};
  border-radius: ${consts.borderRadiusSmallRedesign};
  background-color: ${consts.BackgroundColorV2.lightGray};
`

const CustomIconVector = styled(IconVector)`
  flex-shrink: 0;
  align-self: flex-start;
`

const CustomContainerFlex = styled(ContainerFlex)`
  border-bottom: 1px solid ${consts.BrandColorV2.lightGray};
  padding-bottom: ${consts.paddingLarge};
  margin-bottom: ${consts.marginDefault};
`

const ModalProcedure: React.FC<IModalProcedureProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.IoA']
  })

  const {
    storeManagementApplicationSettings,
    storeManagementAttackTypeConfiguration
  } = useStores()

  const appRouter = useAppRouter()

  const isIoAAutomaticConfiguration =
    storeManagementApplicationSettings.applicationSettings
      ?.ioaAutomaticConfiguration || false

  return (
    <Modal
      modalProps={{
        title: translate('Procedure'),
        footer: null,
        width: 800
      }}
      storeModal={props.storeModal}
    >
      {!isIoAAutomaticConfiguration &&
        storeManagementAttackTypeConfiguration.isConfigurationSavedOnManualMode && (
          <AlertIoaManualConfiguration />
        )}

      <MessageContainerFlex
        name="DomainsProcedure"
        labelledBy="modalDomainsProcedure"
        alignItems="center"
        isIoAAutomaticConfiguration={isIoAAutomaticConfiguration}
        items={[
          <CustomIconVector size={18} />,

          <ContainerFlex
            name="FutureAutomaticUpdates"
            labelledBy="futureAutomaticUpdates"
            direction="column"
            justifyContent="center"
            items={[
              <Label variant={LabelVariant.p}>
                <ContainerHTMLContent>
                  {translate('Future automatic updates', {
                    transformMarkdown: true
                  })}
                </ContainerHTMLContent>
              </Label>,
              <Label variant={LabelVariant.p}>
                {translate(
                  'To avoid having to reconfigure manually your domains with each future modification, we recommend that you enable automatic updates'
                )}{' '}
                <LinkSimple href="https://docs.tenable.com/tenablead/3_x/Content/Admin/09a-IoAs/ioa-deployment/InstallIoAModule.htm">
                  {translate('Learn more')}
                </LinkSimple>
              </Label>
            ]}
            spaced
            spaceWidth="verySmall"
          />,

          <FormWrapperSwitch
            labelledBy="allowAutomaticUpdate"
            switchProps={{
              checked: isIoAAutomaticConfiguration,
              onChange: handleAutomaticConfigurationOnChange(
                storeManagementApplicationSettings
              ),
              disabled:
                storeManagementAttackTypeConfiguration.forcedManualIoaConfiguration
            }}
          />
        ]}
        spaced
        spaceWidth="verySmall"
      />

      {isIoAAutomaticConfiguration && <AlertIoaAutomaticConfigurationSuccess />}

      <ContainerFlex
        name="DomainsConfigurationThreeSteps"
        labelledBy="domainsConfigurationThreeSteps"
        direction="column"
        items={[
          <CustomContainerFlex
            name="DomainsConfigurationDownloadStep"
            labelledBy="domainsConfigurationDownloadStep"
            justifyContent="space-between"
            alignItems="center"
            items={[
              <Label variant={LabelVariant.p}>
                <b>1. </b>
                {translate('Download the file "Register-TenableIOA.ps1"')}
              </Label>,

              <FormWrapperButton
                labelledBy="downloadInstallationScript"
                variant={ButtonVariant.primary}
                size={ButtonSize.large}
                buttonProps={{
                  onClick: handleDownloadScriptButtonClick(appRouter)
                }}
              >
                {translate('Download')}
              </FormWrapperButton>
            ]}
            spaced
            spaceWidth="extraLarge"
          />,

          <CustomContainerFlex
            name="DomainsConfigurationDownloadStep"
            labelledBy="domainsConfigurationDownloadStep"
            justifyContent="space-between"
            alignItems="center"
            items={[
              <Label variant={LabelVariant.p}>
                <b>2. </b>
                {translate(
                  'Download the IoA configuration file for all domains',
                  {
                    interpolations: {
                      filename: IOA_CONFIGURATION_FILENAME
                    }
                  }
                )}
              </Label>,

              <FormWrapperButton
                labelledBy="downloadInstallationScript"
                variant={ButtonVariant.primary}
                size={ButtonSize.large}
                buttonProps={{
                  onClick: handleDownloadIoAConfigButtonClick(appRouter)
                }}
              >
                {translate('Download')}
              </FormWrapperButton>
            ]}
            spaced
            spaceWidth="extraLarge"
          />,

          <ContainerFlex
            name="DomainsConfigurationRunStep"
            labelledBy="domainsConfigurationRunStep"
            direction="column"
            items={[
              <Label variant={LabelVariant.p}>
                <b>3. </b>
                {translate(
                  'Run the following PowerShell commands to configure your domains'
                )}
              </Label>,

              <ContainerScriptCommands />
            ]}
            spaced
            spaceWidth="default"
          />
        ]}
      />
    </Modal>
  )
}

export default observer(ModalProcedure)
