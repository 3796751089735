import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import MenuSystem from '@app/pages/Management/SystemPage/MenuSystem'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleAboutOnLoad } from './handlers'
import { canAccessToAbout } from './permissions'
import ProductLicense from './ProductLicense'
import ProductVersion from './ProductVersion'

interface IAboutPageProps {}

const AboutPage: React.FC<IAboutPageProps> = () => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Navigation',
      'Management.System.About.License'
    ]
  })

  const { storeAbout, storeLicense } = useStores()

  return (
    <Blade
      root={true}
      routeDefinition={{
        routeName: AppRouteName.Management_System_About,
        parameters: {}
      }}
      onLoad={handleAboutOnLoad(storeAbout, storeLicense)}
      flags={[
        storeAbout.storeFlags.flags,
        storeLicense.storeFlagsFetchLicense.flags
      ]}
      layout={{
        name: 'twoColumns',
        menu: <MenuSystem />,
        leftColumn: (
          <ContainerContent title={translate('About')}>
            <ProductVersion />
          </ContainerContent>
        ),
        content: <ProductLicense />,
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canAccessToAbout}
    />
  )
}

export default observer(AboutPage)
