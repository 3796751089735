import type { IEventDeviance } from '@app/stores/TrailFlow/types'
import { addSetValueToMap } from '@libs/setValueToMap'

/**
 * Return true if all deviances for a defined profile are resolved.
 */
export function isAllDeviancesResolvedForProfileId(
  allDeviances: Map<number, IEventDeviance>
) {
  const deviancesStatusByProfileId: Map<number, Set<IEventDeviance>> = new Map()

  allDeviances.forEach(deviance => {
    addSetValueToMap(deviancesStatusByProfileId, deviance.profileId, deviance)
  })

  return (profileId: number) => {
    const profileDeviances = deviancesStatusByProfileId.get(profileId)

    if (!profileDeviances) {
      return true
    }

    return Array.from(profileDeviances.values()).every(
      deviance => deviance.isResolved
    )
  }
}
