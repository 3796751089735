import type { StoreBlades } from '@app/stores'

/**
 * Return to the blade that matchs the clicked overlay.
 * Note that the blade where we want to go back is the previous one relative to
 * the clicked overlay.
 */
export const onBladeOverlayClick =
  (storeBlades: StoreBlades) => (uuid: string) => () => {
    const url = storeBlades.getPreviousBladeFrom(uuid)?.url

    if (url) {
      storeBlades.goToBladeUrl(url)
    }
  }
