import { ContainerContent } from '@app/components-legacy/Container'
import { LabelDirectoriesTree } from '@app/components-legacy/Label'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import { EventDetailsPageContext } from '../contexts'

interface IEventDetailsHeaderImpactedDomainsProps {}

const EventDetailsHeaderImpactedDomains: React.FC<
  IEventDetailsHeaderImpactedDomainsProps
> = () => {
  const { storeInfrastructures, storeTrailFlow } = useStores()

  const parameters = React.useContext(EventDetailsPageContext)

  if (!parameters) {
    return null
  }

  // show impacted domains only if there is a deviance
  const event = storeTrailFlow.storeEventDetails.event

  if (event && !event.getDeviances().length) {
    return null
  }

  const infrastructure = storeInfrastructures.getInfrastructureFromDirectoryId(
    parameters.directoryId
  )

  if (!infrastructure) {
    return null
  }

  if (!infrastructure.name) {
    return null
  }

  return (
    <ContainerContent>
      <LabelDirectoriesTree
        storeInfrastructures={storeInfrastructures}
        directoryIds={[parameters.directoryId]}
      />
    </ContainerContent>
  )
}

export default EventDetailsHeaderImpactedDomains
