import type { Maybe } from '@@types/helpers'
import { ensureArray } from '@libs/ensureArray'
import { trimEnd } from 'lodash'
import { marked } from 'marked'

/**
 * Slice a string or an array of strings until `maxLength` value.
 */
export function sliceString(
  str: string | string[],
  _options: {
    maxLength?: number
    delimiter?: string
    ellipsis?: string
  }
): string {
  const options = {
    delimiter: ', ',
    ellipsis: '…',
    ..._options
  }

  let finalString = ensureArray(str).join(options.delimiter)

  if (options.maxLength === undefined) {
    return finalString
  }

  if (finalString.length > options.maxLength) {
    finalString =
      trimEnd(finalString.slice(0, options.maxLength)) + options.ellipsis
  }

  return finalString
}

/**
 * Slice a markdown string until `maxLength` value.
 */
export function sliceMarkdownString(
  str: string | string[],
  _options: {
    maxLength?: number
    delimiter?: string
    ellipsis?: string
  }
): string {
  const options = {
    delimiter: ', ',
    ellipsis: '…',
    ..._options
  }

  const finalString = ensureArray(str).join(options.delimiter)

  const tokens = marked.lexer(finalString)
  sliceTokenizedMarkdownString(tokens, options)

  return trimEnd(marked.parser(tokens))
}

/**
 * Slice a markdown string until `maxLength` by parsing Marked tokens.
 * It mutates `tokens` by altering the tokens text and/or removing some tokens.
 */
export function sliceTokenizedMarkdownString(
  tokens: marked.TokensList,
  _options: {
    maxLength?: number
    ellipsis?: string
  }
): void {
  const options = {
    ellipsis: '…',
    ..._options
  }

  if (options.maxLength === undefined) {
    return
  }

  const optionsMaxLength = options.maxLength

  let depth = -1
  let lastWord: Maybe<string> = null
  let wordsTotalLength = 0
  let maxLengthReached = false

  function browseTokens(_tokens: marked.TokensList | any) {
    depth++

    for (let i = 0; i < ensureArray<marked.Token>(_tokens).length; i++) {
      const _token = _tokens[i] as marked.Token

      // if the limit has been reached, remove the token
      if (maxLengthReached) {
        _tokens.splice(i, 1)

        // fix the iteration index since we have removed an item from the list
        i--

        continue
      }

      // if there is children tokens, recall the fonction recursively
      if ('tokens' in _token) {
        browseTokens(_token.tokens)
      }

      // don't count the first depth where the whole markdown string is present
      if (depth === 0) {
        continue
      }

      // skip if no text is present
      if (!('text' in _token)) {
        continue
      }

      // avoid words duplications
      if (lastWord === _token.text) {
        continue
      }

      // push the word and compute the total length
      lastWord = _token.text
      wordsTotalLength += _token.text.length

      // if the added words length have reached the limit, slice the token text
      // until the limit
      if (wordsTotalLength > optionsMaxLength) {
        const tokenTextLength =
          _token.text.length - (wordsTotalLength - optionsMaxLength)
        _token.text = _token.text.slice(0, tokenTextLength)

        maxLengthReached = true
      }
    }

    // if the limit has been reached, add an ellipsis at the end of the string
    if (depth === 1 && maxLengthReached) {
      _tokens.push({
        type: 'text',
        raw: options.ellipsis,
        text: options.ellipsis
      })
    }

    depth--
  }

  browseTokens(tokens)
}
