import { ContainerFlex } from '@app/components/Container'
import LabelTidAddOnError from '@app/components/Labels/LabelTidAddOnError'
import { WidgetListPagination } from '@app/components/Widgets/WidgetList'
import { ButtonExportAll } from '@app/components-legacy/Button'
import {
  ContainerFooter,
  ContainerHVCentered,
  ContainerRbac
} from '@app/components-legacy/Container'
import { handleDrawerOnOpen } from '@app/components-legacy/Drawer/handlers'
import { IconNoData } from '@app/components-legacy/Icon'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator, useStores } from '@app/hooks'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerExportIdentities from '../DrawerExportIdentities'
import { canExportIdentities } from '../permissions'
import { handleIdentityExplorerPaginationChange } from './handlers'
import Table from './Table'

export interface IIdentityExplorerListProps {}

const IdentityExplorerList: React.FC<IIdentityExplorerListProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['IdentityExplorer.Main', 'IdentityExplorer.Table']
  })

  const { storeIdentityExplorer, storeManagementAzureAD } = useStores()

  const { storeWidgetListIdentities } = storeIdentityExplorer

  if (!storeManagementAzureAD.isTenableCloudApiTokensWorking) {
    return <LabelTidAddOnError />
  }

  if (!storeWidgetListIdentities.allEntitiesAsArray.length) {
    return (
      <ContainerHVCentered>
        <IconNoData text={translate('No identity available')} />
      </ContainerHVCentered>
    )
  }

  return (
    <>
      <Table
        identitiesEntities={storeWidgetListIdentities.allEntitiesAsArray}
      />

      <Portal name={PlaceHolderName.bladeFooter}>
        <ContainerFooter>
          <ContainerFlex
            name="IdentityExplorerFooter"
            flexGap="default"
            items={[
              <DSThemeProvider themeName={DSThemeName.default}>
                <WidgetListPagination
                  onChange={handleIdentityExplorerPaginationChange(
                    storeIdentityExplorer
                  )}
                  storeWidgetList={
                    storeIdentityExplorer.storeWidgetListIdentities
                  }
                />
              </DSThemeProvider>,

              <ContainerRbac rbacCapabilityCheck={canExportIdentities}>
                <ButtonExportAll
                  onClick={handleDrawerOnOpen(
                    storeIdentityExplorer.storeDrawerExportIdentities
                  )}
                />
              </ContainerRbac>
            ]}
          />
        </ContainerFooter>
      </Portal>

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerExportIdentities />
      </Portal>
    </>
  )
}

export default observer(IdentityExplorerList)
