import { FormWrapperCheckbox } from '@app/components-legacy/Form'
import { SyslogFormFieldName } from '@app/pages/Management/SystemPage/ConfigurationPage/ConfigurationSyslogsPage/types'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { onCheckboxChange } from '@app/stores/helpers/StoreForm/handlers'
import type { FieldName } from '@app/stores/helpers/StoreForm/types'
import type { CheckboxProps } from 'antd/lib/checkbox/Checkbox'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IWidgetFormCheckboxProps {
  /** Field name */
  fieldName: FieldName
  /** Input id */
  inputId: string
  /** Props that will be passed to the rendered component */
  inputProps?: CheckboxProps
  /** StoreForm to get data */
  storeForm: StoreForm<any>
}

/**
 * @deprecated
 */
const WidgetFormCheckbox: React.FC<IWidgetFormCheckboxProps> = props => {
  return (
    <FormWrapperCheckbox
      labelledBy={props.fieldName}
      checkboxProps={{
        id: props.inputId,
        name: SyslogFormFieldName.tls,
        checked: props.storeForm.getFieldValueAsBoolean(props.fieldName),
        onChange: onCheckboxChange(props.storeForm)(props.fieldName),
        ...props.inputProps
      }}
    />
  )
}

export default observer(WidgetFormCheckbox)
