import type { EntityAttackType } from '@app/entities'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import Cell from './Cell'
import CellAttackType from './CellAttackType'
import { SETUP_TABLE_ATTACK_TYPES_WIDTH_REDESIGN } from './consts'
import { handleAttackTypeForAllDirectoriesOnChange } from './handlers'

interface IAttackTypesCellsProps {
  isCheckable: boolean
  attackTypes: EntityAttackType[]
}

const Div = styled.div`
  padding-bottom: ${consts.paddingDefault};
  padding-top: ${consts.paddingDefault};
  background: ${consts.BackgroundColorV2.white};
  border-bottom-left-radius: ${consts.borderRadiusRedesign};
  border-bottom-right-radius: ${consts.borderRadiusRedesign};
`

const CellsAttackTypes: React.FC<IAttackTypesCellsProps> = props => {
  const { storeManagementAttackTypeConfiguration } = useStores()

  return (
    <Div>
      {props.attackTypes.map(attackType => {
        return (
          <Cell
            key={`attackType-${attackType.id}`}
            width={SETUP_TABLE_ATTACK_TYPES_WIDTH_REDESIGN + 20}
            isAttackType={true}
          >
            <CellAttackType
              name={attackType.genericName}
              codename={attackType.genericCodename}
              criticity={attackType.genericCriticity}
              onChange={handleAttackTypeForAllDirectoriesOnChange(
                storeManagementAttackTypeConfiguration
              )(attackType.getPropertyAsNumber('id'))}
              checked={storeManagementAttackTypeConfiguration.isAttackTypeForAllDirectoriesSelected(
                attackType.getPropertyAsNumber('id')
              )}
              indeterminate={storeManagementAttackTypeConfiguration.isAttackTypeForAllDirectoriesPartiallySelected(
                attackType.getPropertyAsNumber('id')
              )}
            />
          </Cell>
        )
      })}
    </Div>
  )
}

export default observer(CellsAttackTypes)
