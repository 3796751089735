import { useTestAttribute } from '@app/hooks/useTestAttribute'
import * as React from 'react'
import styled from 'styled-components'

interface ILabelBreakableProps {
  className?: string
  children: React.ReactNode
  labelledBy?: string
}

/**
 * @deprecated
 */
const LabelBreakable: React.FC<ILabelBreakableProps> = props => {
  const { testAttributeProps } = useTestAttribute('contentinfo')
  return (
    <div
      data-name="LabelBreakable"
      className={props.className}
      {...testAttributeProps()(props.labelledBy)}
    >
      {props.children}
    </div>
  )
}

/**
 * @deprecated
 */
export default styled(LabelBreakable)`
  word-wrap: break-word;
  word-break: break-word;
`
