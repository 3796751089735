import { ContainerContent } from '@app/components-legacy/Container'
import { LabelSliced } from '@app/components-legacy/Label'
import { WidgetListTable } from '@app/components-legacy/Widgets/WidgetList'
import type { EntityRbacEntityItem } from '@app/entities'
import type { IDataRowRbacEntityItem } from '@app/entities/EntityRbacEntityItem'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import { translateRbacEntity } from '@app/stores/Management/Rbac/functions'
import { rbacCapability, rbacCapabilityAnyOf } from '@libs/rbac/functions'
import { RbacPermissionType, RbacEntityItemType } from '@libs/rbac/types'
import { RbacAction, RbacEntityName } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import { onTagTogglePermissionClick } from './handlers'
import TagTogglePermission from './TagTogglePermission'

interface IListEntityItemsTypeSingletonProps {
  storeWidgetList: StoreWidgetList<EntityRbacEntityItem, IDataRowRbacEntityItem>
  rbacEntityItemType: RbacEntityItemType
  disabled: boolean
}

function getHiddenColumnsKeys(
  rbacEntityItemType: RbacEntityItemType
): Array<keyof IDataRowRbacEntityItem> {
  // only read:* permissions for UI entities
  if (rbacEntityItemType === RbacEntityItemType.ui) {
    return [
      'id',
      'rbacEntityItemType',
      'rbacReadIdAction',
      'rbacEditIdAction',
      'rbacEditAllAction',
      'rbacCreateAction'
    ]
  }

  // only edit:*, create:* permissions for self-user entities
  if (rbacEntityItemType === RbacEntityItemType.userPersonal) {
    return [
      'id',
      'rbacEntityItemType',
      'rbacReadIdAction',
      'rbacReadAllAction',
      'rbacEditIdAction'
    ]
  }

  // only read:*, edit:* permissions for singleton entities
  return [
    'id',
    'rbacEntityItemType',
    'rbacReadIdAction',
    'rbacEditIdAction',
    'rbacCreateAction'
  ]
}

const ListEntityItemsTypeSingleton: React.FC<
  IListEntityItemsTypeSingletonProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.Accounts.Roles.Permissions']
  })

  const { storeManagementRbacRoles } = useStores()

  /**
   * Specific cases for the edition tag.
   */
  const isRbacEditAllActionGranted = (
    rbacEntityName: RbacEntityName
  ): boolean => {
    switch (rbacEntityName) {
      case RbacEntityName.SelfUser: {
        // granted if at least one of those permissions is granted
        return rbacCapabilityAnyOf(
          rbacCapability(storeManagementRbacRoles.pendingRbacPermissions)(
            rbacEntityName,
            RbacAction.Edit
          ),
          rbacCapability(storeManagementRbacRoles.pendingRbacPermissions)(
            RbacEntityName.User,
            RbacAction.Edit,
            null,
            { strict: true }
          )
        ).isGranted
      }

      default: {
        return rbacCapability(storeManagementRbacRoles.pendingRbacPermissions)(
          rbacEntityName,
          RbacAction.Edit
        ).isGranted
      }
    }
  }

  return (
    <ContainerContent>
      <WidgetListTable<EntityRbacEntityItem, IDataRowRbacEntityItem>
        translate={translate}
        hiddenColumnsKeys={getHiddenColumnsKeys(props.rbacEntityItemType)}
        columnWidths={{
          rbacReadIdAction: 15,
          rbacReadAllAction: 15,
          rbacEditIdAction: 15,
          rbacEditAllAction: 15,
          rbacCreateAction: 15
        }}
        storeWidgetList={props.storeWidgetList}
        headsRenderFn={{
          rbacReadAllAction: () => {
            return props.rbacEntityItemType === RbacEntityItemType.ui
              ? translate('Access')
              : translate('Read')
          }
        }}
        cellsRenderFn={{
          name: value => {
            return (
              <LabelSliced
                maxLength={100}
                value={translateRbacEntity(translate)(value)}
              />
            )
          },

          rbacReadAllAction: (canBeChanged, entityItemRow) => {
            const { isGranted } = rbacCapability(
              storeManagementRbacRoles.pendingRbacPermissions
            )(entityItemRow.rbacEntityName, RbacAction.Read)

            if (canBeChanged === false) {
              return (
                <TagTogglePermission
                  type={RbacPermissionType.grant}
                  rbacEntityType={props.rbacEntityItemType}
                  rbacEntityName={entityItemRow.rbacEntityName}
                  disabled={props.disabled}
                  rbacAction={RbacAction.Read}
                  builtIn
                  forAllEntities={isGranted}
                />
              )
            }

            return (
              <TagTogglePermission
                type={
                  isGranted
                    ? RbacPermissionType.grant
                    : RbacPermissionType.unauthorize
                }
                rbacEntityType={props.rbacEntityItemType}
                rbacEntityName={entityItemRow.rbacEntityName}
                disabled={props.disabled}
                rbacAction={RbacAction.Read}
                onClick={
                  !props.disabled
                    ? onTagTogglePermissionClick(storeManagementRbacRoles)(
                        entityItemRow.rbacEntityName,
                        RbacAction.Read
                      )(
                        isGranted
                          ? RbacPermissionType.unauthorize
                          : RbacPermissionType.grant
                      )
                    : null
                }
                forAllEntities={isGranted}
              />
            )
          },

          rbacEditAllAction: (canBeChanged, entityItemRow) => {
            const isGranted = isRbacEditAllActionGranted(
              entityItemRow.rbacEntityName
            )

            if (canBeChanged === false) {
              return (
                <TagTogglePermission
                  type={RbacPermissionType.grant}
                  rbacEntityType={props.rbacEntityItemType}
                  rbacEntityName={entityItemRow.rbacEntityName}
                  disabled={props.disabled}
                  rbacAction={RbacAction.Edit}
                  builtIn
                  forAllEntities={isGranted}
                />
              )
            }

            return (
              <TagTogglePermission
                type={
                  isGranted
                    ? RbacPermissionType.grant
                    : RbacPermissionType.unauthorize
                }
                rbacEntityType={props.rbacEntityItemType}
                rbacEntityName={entityItemRow.rbacEntityName}
                disabled={props.disabled}
                rbacAction={RbacAction.Edit}
                onClick={
                  !props.disabled
                    ? onTagTogglePermissionClick(storeManagementRbacRoles)(
                        entityItemRow.rbacEntityName,
                        RbacAction.Edit
                      )(
                        isGranted
                          ? RbacPermissionType.unauthorize
                          : RbacPermissionType.grant
                      )
                    : null
                }
                forAllEntities={isGranted}
              />
            )
          },

          rbacCreateAction: (canBeChanged, entityItemRow) => {
            const { isGranted } = rbacCapability(
              storeManagementRbacRoles.pendingRbacPermissions
            )(entityItemRow.rbacEntityName, RbacAction.Create)

            if (canBeChanged === false) {
              return (
                <TagTogglePermission
                  type={RbacPermissionType.grant}
                  rbacEntityType={props.rbacEntityItemType}
                  rbacEntityName={entityItemRow.rbacEntityName}
                  disabled={props.disabled}
                  rbacAction={RbacAction.Create}
                  builtIn
                  forAllEntities={isGranted}
                />
              )
            }

            return (
              <TagTogglePermission
                type={
                  isGranted
                    ? RbacPermissionType.grant
                    : RbacPermissionType.unauthorize
                }
                rbacEntityType={props.rbacEntityItemType}
                rbacEntityName={entityItemRow.rbacEntityName}
                disabled={props.disabled}
                rbacAction={RbacAction.Create}
                onClick={
                  !props.disabled
                    ? onTagTogglePermissionClick(storeManagementRbacRoles)(
                        entityItemRow.rbacEntityName,
                        RbacAction.Create
                      )(
                        isGranted
                          ? RbacPermissionType.unauthorize
                          : RbacPermissionType.grant
                      )
                    : null
                }
                forAllEntities={isGranted}
              />
            )
          }
        }}
      />
    </ContainerContent>
  )
}

export default ListEntityItemsTypeSingleton
