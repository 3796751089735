import type { StoreLicense } from '@app/stores'
import { handleConfigurationAzureADSupportOnLoad } from './ConfigurationAzureADSupport/handlers'
import { handleDataCollectionStatusOnLoad } from './ConfigurationDataCollection/handlers'

/**
 * Call sub pages handlers if the license allows enabling Tenable Cloud services
 * and/or AzureAd support.
 */
export const handleTenableCloudOnLoad =
  (storeLicense: StoreLicense) => async () => {
    const { storeManagementDataCollection, storeManagementAzureAD } =
      storeLicense.storeRoot.stores

    await storeLicense.fetchLicense()

    if (!storeLicense.isLicenseUpToDate) {
      return
    }

    handleConfigurationAzureADSupportOnLoad(storeManagementAzureAD)()
    handleDataCollectionStatusOnLoad(storeManagementDataCollection)()
  }
