export const mutationUpdateLDAPConfiguration = `
  mutation mutationUpdateLDAPConfiguration($ldapConfiguration: InputLDAPConfiguration!) {
    updateLDAPConfiguration(
      ldapConfiguration: $ldapConfiguration
    ) {
      enabled
      url
      searchUserDN
      searchUserPassword
      userSearchBase
      userSearchFilter
      enableSaslBinding
      relayId
    }
  }
`
