import { createEntity, EntityLockoutPolicy } from '@app/entities'
import type { StoreRoot } from '@app/stores'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreForm from '@app/stores/helpers/StoreForm'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { checkRbac } from '@libs/rbac/functions'
import type { MutationUpdateLockoutPolicy } from '@server/graphql/mutations/lockoutPolicy'
import { mutationUpdateLockoutPolicy } from '@server/graphql/mutations/lockoutPolicy'
import type { QueryLockoutPolicy } from '@server/graphql/queries/lockoutPolicy'
import { queryLockoutPolicy } from '@server/graphql/queries/lockoutPolicy'
import type {
  InputUpdateLockoutPolicy,
  LockoutPolicy
} from '@server/graphql/typeDefs/types'
import type Maybe from 'graphql/tsutils/Maybe'
import { action, computed, makeObservable, observable } from 'mobx'
import { LockoutPolicyFormFieldName } from './types'

export const DEFAULT_SMTP_PORT = 25

export default class StoreLockoutPolicy extends StoreBase {
  public storeFlagsFetchLockoutPolicy = new StoreFlags(this.storeRoot)
  public storeFlagsSetLockoutPolicy = new StoreFlags(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Buttons', 'Management.System.Configuration.LockoutPolicy']
  })

  public storeFormLockoutPolicyConfiguration =
    new StoreForm<LockoutPolicyFormFieldName>(this.storeRoot, {
      setup: {
        fields: {
          [LockoutPolicyFormFieldName.enabled]: {
            label: 'Lockout policy enabled'
          },
          [LockoutPolicyFormFieldName.lockoutDuration]: {
            label: 'Lockout duration',
            description: 'Period during the user can no longer log in'
          },
          [LockoutPolicyFormFieldName.lockoutDurationInfinite]: {
            label: 'Infinite lockout duration'
          },
          [LockoutPolicyFormFieldName.failedAttemptThreshold]: {
            label: 'Number of attempts before lockout'
          },
          [LockoutPolicyFormFieldName.failedAttemptPeriod]: {
            label: 'Redemption period',
            description: 'Period during which unsuccessful attempts are counted'
          },
          [LockoutPolicyFormFieldName.failedAttemptPeriodInfinite]: {
            label: 'Infinite redemption period'
          }
        }
      }
    })

  /* Observable */

  private $lockoutPolicy = observable.box<Maybe<EntityLockoutPolicy>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch Lockout Policy parameters.
   */
  fetchLockoutPolicy(): Promise<void> {
    this.storeFlagsFetchLockoutPolicy.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryLockoutPolicy>(queryLockoutPolicy)
      })
      .then(data => data.rbacLockoutPolicy)
      .then(rbacLockoutPolicy => {
        if (
          !checkRbac(
            this.storeRoot,
            this.storeFlagsFetchLockoutPolicy
          )(rbacLockoutPolicy)
        ) {
          // Just ignore and hide the Lockout Policy on the interface
          return
        }

        const lockoutPolicyEntity = createEntity<
          LockoutPolicy,
          EntityLockoutPolicy
        >(EntityLockoutPolicy, rbacLockoutPolicy.node)

        this.setLockoutPolicy(lockoutPolicyEntity)

        this.storeFlagsFetchLockoutPolicy.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsFetchLockoutPolicy)
      )
  }

  /**
   * Edit Lockout Policy.
   */
  editLockoutPolicy(
    lockoutPolicy: InputUpdateLockoutPolicy,
    options = {
      // if set to true, do not push a success message and forward the error exception
      forwardException: false
    }
  ): Promise<void> {
    this.storeFlagsSetLockoutPolicy.loading()

    return Promise.resolve()
      .then(() => {
        const args: MutationUpdateLockoutPolicy['args'] = {
          lockoutPolicy
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationUpdateLockoutPolicy>(
            mutationUpdateLockoutPolicy,
            args
          )
      })
      .then(({ updateLockoutPolicy }) => {
        if (!updateLockoutPolicy) {
          throw new Error()
        }

        if (!options.forwardException) {
          this.storeRoot.stores.storeMessages.success(
            this.translate('Lockout policy parameters updated'),
            {
              labelledBy: 'lockoutPolicyParametersUpdated'
            }
          )
        }

        // since the API route doesn't return the entity, retrieve values from the form
        const lockoutPolicyEntity = createEntity<
          LockoutPolicy,
          EntityLockoutPolicy
        >(EntityLockoutPolicy, {
          enabled:
            this.storeFormLockoutPolicyConfiguration.getFieldValueAsBoolean(
              LockoutPolicyFormFieldName.enabled
            ),
          lockoutDuration:
            this.storeFormLockoutPolicyConfiguration.getFieldValueAsNumber(
              LockoutPolicyFormFieldName.lockoutDuration
            ),
          failedAttemptThreshold:
            this.storeFormLockoutPolicyConfiguration.getFieldValueAsNumber(
              LockoutPolicyFormFieldName.failedAttemptThreshold
            ),
          failedAttemptPeriod:
            this.storeFormLockoutPolicyConfiguration.getFieldValueAsNumber(
              LockoutPolicyFormFieldName.failedAttemptPeriod
            )
        })

        this.setLockoutPolicy(lockoutPolicyEntity)

        this.storeFlagsSetLockoutPolicy.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsSetLockoutPolicy, {
          forwardExceptionFn: () => options.forwardException
        })
      )
  }

  /* Action */

  /**
   * Save Lockout Policy parameters.
   */
  @action
  setLockoutPolicy(lockoutPolicyEntity: EntityLockoutPolicy): this {
    this.$lockoutPolicy.set(lockoutPolicyEntity)
    return this
  }

  /* Computed */

  @computed
  get lockoutPolicy(): Maybe<EntityLockoutPolicy> {
    return this.$lockoutPolicy.get()
  }
}
