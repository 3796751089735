import type { IGraphQLQuery } from '@libs/graphQL/types'
import type {
  Mutation,
  UpdateApplicationSettingsMutationArgs
} from '../typeDefs/types'

export type MutationUpdateApplicationSettings = IGraphQLQuery<
  UpdateApplicationSettingsMutationArgs,
  Pick<Mutation, 'updateApplicationSettings'>
>

export const mutationUpdateApplicationSettings = `
  mutation mutationUpdateApplicationSettings(
    $applicationSettings: InputUpdateApplicationSettings!
  ) {
    updateApplicationSettings(
      applicationSettings: $applicationSettings
    ) {
      userRegistration
      activityLogsIsActive
      activityLogsRetentionDurationInMonth
      smtpServerAddress
      smtpServerPort
      smtpAccount
      smtpAccountPassword
      tls
      emailSender
      defaultProfileId
      defaultRoleIds
      telemetryEnabled
      ioaAutomaticConfiguration
      smtpRelayId
      cloudStatisticsIsActive
      healthCheckGlobalStatusDisplayEnabled
    }
  }
`
