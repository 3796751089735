import type { Perhaps } from '@@types/helpers'
import { ContainerContent, ContainerFlex } from '@app/components/Container'
import { IconHealthCheck } from '@app/components/Icon/MainMenu'
import { useAppRouter, useAppTranslator, useStores } from '@app/hooks'
import { canAccessToHealthCheck } from '@app/pages/HealthCheck/permissions'
import { AppRouteName } from '@app/routes'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import type { HealthCheckDetailedStatus } from '@server/graphql/typeDefs/types'
import { HealthCheckStatus } from '@server/graphql/typeDefs/types'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Link } from 'react-router-dom'

function HealthCheckHeaderWidget() {
  const appRouter = useAppRouter()
  const theme = useDSTheme()
  const { storeHealthCheckGlobalStatus } = useStores()
  const { healthCheckGlobalStatus } = storeHealthCheckGlobalStatus
  const translateGlobalStatus = useAppTranslator({
    namespaces: ['HealthCheck.GlobalStatus']
  })
  const translateDomains = useAppTranslator({
    namespaces: ['HealthCheck.Domains']
  })

  const getStatusColor = () => {
    if (healthCheckGlobalStatus?.status === HealthCheckStatus.Success) {
      return theme.tokens['healthCheck/color/success']
    }
    if (healthCheckGlobalStatus?.status === HealthCheckStatus.Warning) {
      return theme.tokens['healthCheck/color/warning']
    }
    if (healthCheckGlobalStatus?.status === HealthCheckStatus.Failure) {
      return theme.tokens['healthCheck/color/failure']
    }

    return theme.tokens['healthCheck/color/unknown']
  }

  const issuesCountSummary = (
    issuesCount: Perhaps<HealthCheckDetailedStatus>
  ) => {
    return `${translateGlobalStatus('issues', {
      interpolations: {
        count: issuesCount?.numberOfIssues ?? 0
      }
    })}, ${translateGlobalStatus('warnings', {
      interpolations: {
        count: issuesCount?.numberOfWarnings ?? 0
      }
    })}, ${translateGlobalStatus('unknowns', {
      interpolations: {
        count: issuesCount?.numberOfUnknowns ?? 0
      }
    })}`
  }

  const popupText = () => {
    return (
      <ContainerFlex
        name="HealtCheckTooltip"
        direction={'column'}
        items={[
          <ContainerFlex
            name="HealthCheckTooltipTitle"
            justifyContent={'center'}
            items={<>{translateDomains('Health check')}</>}
          />,
          <ContainerFlex
            name="HealthCheckTooltipBody"
            items={
              <>
                {translateGlobalStatus('Domain')}:{' '}
                {issuesCountSummary(healthCheckGlobalStatus?.domainStats)}
                <br />
                {translateGlobalStatus('Platform')}:{' '}
                {issuesCountSummary(healthCheckGlobalStatus?.platformStats)}
              </>
            }
          />
        ]}
      />
    )
  }

  return (
    <ContainerContent
      fade={false}
      rbacCapabilityCheck={canAccessToHealthCheck}
      // if not granted, don't display the RBAC error in the header
      onDeny={() => <></>}
    >
      <Tooltip
        title={popupText()}
        placement="bottom"
        overlayStyle={{ maxWidth: 350 }}
      >
        <Link
          to={appRouter.makeRouteInfosPathname({
            routeName: AppRouteName.HealthCheck,
            parameters: {}
          })}
        >
          <tenable-floating-button>
            <IconHealthCheck size={24} statusColor={getStatusColor()} />
          </tenable-floating-button>
        </Link>
      </Tooltip>
    </ContainerContent>
  )
}

export default observer(HealthCheckHeaderWidget)
