import IconPlus from '@app/components/Icon/IconPlus'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useAppTranslator, useStores } from '@app/hooks'
import { handleCreateReportOnClick } from '@app/pages/Management/SystemPage/ConfigurationPage/ConfigurationReportingCenterPage/handlers'
import { createReport } from '@libs/rbac/permissions'
import React from 'react'

interface ICreateReportButton {}

const CreateReportButton: React.FC<ICreateReportButton> = () => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.ReportingCenter']
  })

  const { storeManagementReportingCenter, storeRbac } = useStores()

  const canCreateReport = storeRbac.isUserGrantedTo(createReport())

  return (
    <FormWrapperButton
      variant={ButtonVariant.primary}
      size={ButtonSize.large}
      labelledBy="reports/create"
      icon={IconPlus}
      buttonProps={{
        disabled: !canCreateReport,
        onClick: handleCreateReportOnClick(storeManagementReportingCenter)
      }}
    >
      {translate('Create report')}
    </FormWrapperButton>
  )
}

export default CreateReportButton
