import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'

interface IIconIoAProps extends IIconProps {}

const IconIoA: React.FC<IIconIoAProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.875 11.875L14.375 9.375L11.875 8.15L14.3375 5.6625L11.875 4.375C11.875 4.375 13.2639 2.98607 13.9285 2.32141C12.6619 1.97599 10 1.25 10 1.25L3.125 3.125V11.9143C3.125 15.625 6.79224 17.7719 9.872 19.0729C9.95392 19.1075 10.0461 19.1075 10.128 19.0729C13.2076 17.772 16.875 15.625 16.875 11.9115V3.125"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconIoA
