import Modal from '@app/components-legacy/Modal/Modal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import type { StoreBlades, StoreLayout } from '@app/stores'
import * as React from 'react'
import { ContainerFooter } from '../Container'
import { FormWrapperButton } from '../Form'

interface IModalChangesNotSavedProps {}

const onGoBackClick = (storeLayout: StoreLayout) => () => {
  storeLayout.storeModalChangesNotSaved.hide()
}

const onConfirmClick =
  (storeLayout: StoreLayout, storeBlades: StoreBlades) => () => {
    storeBlades.closeLastBlade()
    storeLayout.storeModalChangesNotSaved.hide()
  }

const ModalChangesNotSaved: React.FC<IModalChangesNotSavedProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Titles', 'Components.ModalChangesNotSaved']
  })
  const { storeLayout, storeBlades } = useStores()

  return (
    <Modal
      modalProps={{
        title: translate('Confirmation'),
        footer: (
          <ContainerFooter variant="modal">
            <FormWrapperButton
              labelledBy="goBack"
              buttonProps={{
                onClick: onGoBackClick(storeLayout)
              }}
            >
              {translate('Go back on the page')}
            </FormWrapperButton>

            <FormWrapperButton
              labelledBy="confirm"
              buttonProps={{
                onClick: onConfirmClick(storeLayout, storeBlades)
              }}
            >
              {translate('Confirm')}
            </FormWrapperButton>
          </ContainerFooter>
        )
      }}
      storeModal={storeLayout.storeModalChangesNotSaved}
    >
      <div>{translate('You did not save your changes')}</div>
    </Modal>
  )
}

export default ModalChangesNotSaved
