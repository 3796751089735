import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canCreateTenants } from '../permissions'
import { handleTenantCreationPageLoad } from './handlers'
import TenantCreateForm from './TenantCreateForm'

interface ITenantsCreatePageProps {}

const TenantsCreatePage: React.FC<ITenantsCreatePageProps> = () => {
  const { storeManagementTenants } = useStores()

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_System_Tenants_Create,
        parameters: {}
      }}
      flags={[
        storeManagementTenants.storeFlagsFetchTenants.flags,
        storeManagementTenants.storeFlagsFetchCredentials.flags
      ]}
      layout={{
        name: 'default',
        content: (
          <ContainerContent>
            <TenantCreateForm />
          </ContainerContent>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      onLoad={handleTenantCreationPageLoad(storeManagementTenants)}
      rbacCapabilityCheck={canCreateTenants}
    />
  )
}

export default observer(TenantsCreatePage)
