import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  ContainerContent,
  ContainerFooter,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import {
  Drawer,
  DrawerCancelButton,
  DrawerContext,
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { LabelConfirm } from '@app/components-legacy/Label'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canDeleteSyslogAlert } from '../permissions'
import { onSyslogDeleteSubmit } from './handlers'

export interface IDrawerDeleteSyslogProps {}

const DrawerDeleteSyslog: React.FC<IDrawerDeleteSyslogProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.System.Configuration.SyslogAlerts']
  })

  const { storeManagementSyslogs } = useStores()
  const { storeDeleteDrawer } = storeManagementSyslogs

  const syslogDataRow = storeDeleteDrawer.getDataValue('syslogDataRow')

  const renderContent = () => {
    if (!syslogDataRow) {
      return null
    }

    const isLoading = storeManagementSyslogs.storeDeletionFlags.flags.isLoading

    return (
      <FormWrapper
        name="syslogDeletion"
        onSubmit={onSyslogDeleteSubmit(storeManagementSyslogs)(syslogDataRow)}
      >
        <ContainerContent
          flags={storeManagementSyslogs.storeFlags.flags}
          spinner={<SpinnerInline />}
        >
          <ContainerHTMLContent>
            {translate('You will delete the selected SYSLOG alert', {
              transformMarkdown: true
            })}
          </ContainerHTMLContent>

          <LabelConfirm />
        </ContainerContent>

        <Portal name={PlaceHolderName.drawerFooter}>
          <ContainerFooter>
            <DrawerCancelButton />

            <FormWrapperButtonSubmit loading={isLoading}>
              {translate('Delete')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </FormWrapper>
    )
  }

  return (
    <DrawerContext.Provider value={{ storeDrawer: storeDeleteDrawer }}>
      <Drawer>
        <DrawerHorizontalContainer>
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: (
                <DrawerTitle>{translate('Delete a SYSLOG alert')}</DrawerTitle>
              ),
              content: renderContent(),
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canDeleteSyslogAlert(
              syslogDataRow?.id || null
            )}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerDeleteSyslog)
