import { useHistoryChange } from '@app/components-legacy/Router/hooks'
import { useAppTranslator, useLogger } from '@app/hooks'
import {
  useFormSubmitTelemetry,
  usePageHideTelemetry,
  usePageLifeCycleTelemetry,
  useVisibilityChangeTelemetry
} from '@app/hooks/telemetry'
import { useSetupTelemetry } from '@app/hooks/telemetry/useSetupTelemetry'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { usePageLifeCycleActivityLogs } from '@app/hooks/usePageLifeCycleActivityLogs'
import { useStores } from '@app/hooks/useStores'
import { isNotFalsy } from '@libs/isDefined'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { registerEscapeHandler, setProfileNameInRouter } from '../utils'
import { performRedirectsPostLogin } from '../utils/redirections'

/**
 * Set the history object into the router.
 */
export function useRouterInit(): void {
  const history = useHistory()
  const appRouter = useAppRouter()

  appRouter.setHistory(history)
}

/**
 * At mounting, bind some keys.
 */
export function useKeyBindings(): void {
  const { storeRoot } = useStores()

  React.useEffect(() => {
    // bind ESC key
    registerEscapeHandler(storeRoot)
  }, [])
}

/**
 * When being authenticated, setup the profileName and perform redirections.
 * At logout, the app is fully reloaded, so no need to handle it here.
 */
export function usePostLogin(): void {
  const { storeAuthentication, storeEula, storeLicense, storeAbout } =
    useStores()

  const logger = useLogger()

  React.useEffect(() => {
    setProfileNameInRouter(storeAuthentication)
  }, [
    // trigger when being authenticated
    storeAuthentication.isAuthenticated
  ])

  React.useEffect(() => {
    logger.debug('[performRedirectsPostLogin]')
    performRedirectsPostLogin(
      storeAuthentication,
      storeEula,
      storeLicense,
      storeAbout,
      document.location.pathname
    )
  }, [
    // trigger when being authenticated
    storeAuthentication.isAuthenticated,
    // or when EULA is approved,
    storeEula.hasApprovedEula
  ])
}

/**
 * Handle telemetry event logging.
 */
export function useTelemetry() {
  useSetupTelemetry()
  // Commented for now, as we'll try to use the auto-track method of Tenable telemetry lib
  // Set the document & window listeners for telemetry
  usePageLifeCycleTelemetry()
  useVisibilityChangeTelemetry()
  usePageHideTelemetry()
  useFormSubmitTelemetry()
}

/**
 * Handle activity logs page visited logging.
 */
export function useActivityLogs() {
  usePageLifeCycleActivityLogs()
}

/***
 * Update title at each history change.
 */
export function useTitleBindingsOnHistory() {
  const { storeLayout } = useStores()

  const router = useAppRouter()
  const translate = useAppTranslator({ namespaces: ['RouteNames.Pages'] })

  function updateDocumentTitle() {
    const routeName = router.getCurrentRouteName()

    if (!routeName) {
      return
    }

    const routeNameTranslated = translate(routeName)
    const title = [storeLayout.getProductName(), routeNameTranslated]
      .filter(isNotFalsy)
      .join(' - ')

    document.title = title
  }

  useHistoryChange({
    onHistoryChange: updateDocumentTitle
  })

  // update title at first load
  updateDocumentTitle()
}
