import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { IconCheckCircleOutlined, IconCloseSquareOutlined } from './IconAntd'

export interface IIconCheckProps {
  status?: boolean
}

const Cross = styled(IconCloseSquareOutlined)`
  color: ${consts.colorRed001};
`

const IconCheck: React.FC<IIconCheckProps> = props => {
  const status = props.status === undefined ? true : props.status
  return status ? <IconCheckCircleOutlined /> : <Cross />
}

export default IconCheck
