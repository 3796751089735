import * as React from 'react'
import { ContainerFlex } from 'tenable-design-system-alt'
import ConfigurationAttackTypeDomainsStep1 from './ConfigurationAttackTypeDomainsStep1'
import ConfigurationAttackTypeDomainsStep2 from './ConfigurationAttackTypeDomainsStep2'

interface IConfigurationAttackTypeDomainsProps {}

function ConfigurationAttackTypeDomains(
  props: IConfigurationAttackTypeDomainsProps
) {
  return (
    <ContainerFlex
      name="ConfigurationAttackTypeDomains"
      flexDirection="column"
      flexGap="spacingM"
      flexGrow={1}
    >
      <ConfigurationAttackTypeDomainsStep1 />
      <ConfigurationAttackTypeDomainsStep2 />
    </ContainerFlex>
  )
}

export default ConfigurationAttackTypeDomains
