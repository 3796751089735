import type { IAttackPathCoords } from '@app/pages/AttackPath/types'

/**
 * Retuns the angle from 2 coords
 */
export default function getArrowCoordsForAngle(
  origin: IAttackPathCoords,
  desiredArrowAngle: number,
  lineAngle: number,
  distanceFromOrigin: number
) {
  return {
    x:
      origin.x +
      distanceFromOrigin *
        Math.cos(desiredArrowAngle) *
        (lineAngle > Math.PI ? 1 : -1),
    y:
      origin.y +
      distanceFromOrigin *
        Math.sin(desiredArrowAngle) *
        (lineAngle > Math.PI ? 1 : -1)
  }
}
