import type { MaybeUndef } from '@@types/helpers'
import { DEFAULT_INSTANCE_NAME } from '@libs/common/consts'
import type { ConfigClient } from '@libs/config/types'
import type { IFetchClient } from '@libs/FetchClient/types'
import type Translator from '@libs/i18n/Translator'
import type { ILogger } from '@libs/logger'
import type { IOpenApiClients } from '@libs/openapi-clients/types'
import type { TWSClient } from '@libs/WSClient/types'
import type { GraphQLClient } from 'graphql-request'
import type { ISessionParams } from './types'

export default class Environment<
  TFetchClient extends IFetchClient = IFetchClient,
  TOpenApiClients extends IOpenApiClients = IOpenApiClients
> {
  constructor(
    readonly config: ConfigClient,
    readonly sessionParams: ISessionParams,
    readonly logger: ILogger,
    readonly fetchClient: TFetchClient,
    // one instance by Tenable.ad instance
    readonly graphQLClients: Map<string, GraphQLClient>,
    readonly wsClient: TWSClient,
    readonly translator: Translator,
    readonly openApiClients: TOpenApiClients,
    readonly localStorage: MaybeUndef<Storage>
  ) {}

  /**
   * Return the first instance name of the configuration.
   */
  getFirstInstanceName(): string {
    const instanceNames = this.config.instances.map(instance => instance.name)

    // this is safe since the config is validated with at least one instance
    return instanceNames[0] ?? DEFAULT_INSTANCE_NAME
  }

  /**
   * Return true if the configuration defined more than one Tenable.ad instances.
   */
  hasMultipleInstances(): boolean {
    return this.config.instances.length > 1
  }

  tenablePlatformOrigin(): string {
    // FIXME - Should be better to have a dedicated entry or using app.tul.workspacedomain (when ARGS have been set in the Dockerfile)
    return new URL(this.config.app.settings.tonelogin.url).origin
  }
}
