import type StoreTrailFlow from '@app/stores/TrailFlow/StoreTrailFlow'

/**
 * Fetch newer events and add it to the table, then scroll.
 */
export const fetchNewerEvents = (storeTrailFlow: StoreTrailFlow) => {
  const { storeEvents, storeDeviances } = storeTrailFlow

  // if a query is being processed, skip this one
  if (storeEvents.storeFlagsFetchNewerEvents.flags.isLoading) {
    return
  }

  storeEvents.fetchNewerEvents().then(() => {
    storeDeviances.fetchDeviances()
  })
}

/**
 * Fetch older events, add them to the table, then scroll.
 */
export const fetchOlderEvents = (storeTrailFlow: StoreTrailFlow) => {
  const { storeEvents, storeDeviances } = storeTrailFlow

  // if a query is being processed, skip this one
  if (storeEvents.storeFlagsFetchOlderEvents.flags.isLoading) {
    return
  }

  storeEvents.fetchOlderEvents().then(() => {
    storeDeviances.fetchDeviances()
  })
}
