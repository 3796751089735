import { ContainerContent, ContainerFlex } from '@app/components/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import SpinnerInline from '@app/components-legacy/Spinner/SpinnerInline'
import { useAppTranslator, useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckerIdentityDetailsPageContext } from '../context'
import { canAccessToIoEIdentityFindings } from '../permissions'
import FindingsFilters from './FindingsFilters'
import FindingsFooter from './FindingsFooter'
import FindingsTable from './FindingsTable'
import { handleFindingsOnLoad } from './handlers'

interface IDetailsFindingsPageProps {}

const DetailsFindingsPage: React.FC<IDetailsFindingsPageProps> = () => {
  const parameters = React.useContext(CheckerIdentityDetailsPageContext)

  const translate = useAppTranslator({ namespaces: ['IoE.Details.Menu'] })

  const { storeIoE } = useStores()

  if (!parameters) {
    return null
  }

  return (
    <ContainerContent
      title={translate('Findings')}
      flags={[
        storeIoE.storeFlagsFetchCheckersIdentity.flags,
        storeIoE.storeIndicator.storeIndicatorFindings.storeFlagsFetchFindings
          .flags,
        storeIoE.storeIndicator.storeIndicatorFindings.storeInputTenants
          .storeFlagsFetchTenants.flags
      ]}
      onLoad={handleFindingsOnLoad(storeIoE)(parameters.checkerId)}
      rbacCapabilityCheck={canAccessToIoEIdentityFindings}
    >
      <>
        <ContainerFlex
          name="Findings"
          direction="column"
          items={[
            <FindingsFilters />,

            <ContainerContent
              spinner={<SpinnerInline />}
              flags={[
                storeIoE.storeIndicator.storeIndicatorFindings
                  .storeFlagsReloadFindings.flags
              ]}
            >
              <FindingsTable />
            </ContainerContent>
          ]}
          spaced
        />

        <Portal name={PlaceHolderName.bladeFooter}>
          <FindingsFooter
            checkerId={parameters.checkerId}
            checkerCodename={parameters.checkerCodename}
          />
        </Portal>
      </>
    </ContainerContent>
  )
}

export default observer(DetailsFindingsPage)
