import type { IContainerInfiniteScrollHandlerOptions } from './types'

export const SCROLL_INFINITE_LIMIT = 300

/**
 * Call a function when reatching the scroll value limit.
 */
export const handleContainerInfiniteScroll =
  (options: IContainerInfiniteScrollHandlerOptions) =>
  (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (options.preventFn && options.preventFn()) {
      return
    }

    const targetElement = e.target as HTMLDivElement

    const scrollDiff =
      targetElement.scrollHeight -
      (targetElement.scrollTop + targetElement.clientHeight)

    const limit = options.limit ?? SCROLL_INFINITE_LIMIT

    if (scrollDiff < limit) {
      options.onScrollFn()
    }
  }
