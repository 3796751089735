import type StoreSummaryCard from '@app/stores/IoA/StoreBoard/StoreSummaryCard'

/**
 * Fetch attacksStats for number of attacks in time.
 */
export const handleCardOnLoad = (storeSummaryCard: StoreSummaryCard) => () => {
  const args = storeSummaryCard.computeArgs()

  if (!args) {
    return
  }

  storeSummaryCard.fetchAttacksStats(args)
}
