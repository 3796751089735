import type { Maybe, PropertiesNullable } from '@@types/helpers'
import type { ReportAccessToken } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityReportAccessToken
  extends EntityBase
  implements PropertiesNullable<ReportAccessToken>
{
  reportAccessToken: Maybe<string> = null

  constructor(data: Partial<EntityReportAccessToken>) {
    super()
    Object.assign(this, data)
  }
}
