import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperCheckbox } from '@app/components-legacy/Form'
import { IconArrow } from '@app/components-legacy/Icon'
import { LabelAlt, LabelNonBreakable } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import { Popover } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  onWidgetListSelectAllCheckboxChange,
  onWidgetListSelectAllLabelClick
} from './handlers'
import WidgetListSelectAllCheckboxPopoverContent from './WidgetListSelectAllCheckboxPopoverContent'

export interface IWidgetListSelectAllCheckboxProps {
  style?: React.CSSProperties
  hideLabel?: boolean
  hasInfiniteScroll?: boolean
  disableSelectAllPages?: boolean
  storeWidgetList: StoreWidgetList<any, any>
}

const WidgetListSelectAllCheckbox: React.FC<
  IWidgetListSelectAllCheckboxProps
> = props => {
  const [showPopover, setShowPopover] = React.useState(false)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Components.WidgetList'],
    storeOptionsI18n: props.storeWidgetList.options
  })

  const onWidgetListSelectArrayClick = () => {
    setShowPopover(!showPopover)
  }

  const renderLabel = () => {
    if (!props.storeWidgetList.hasPagination) {
      return translate('Select all')
    }

    if (
      !props.disableSelectAllPages &&
      props.storeWidgetList.isPageRowsSelected &&
      !props.storeWidgetList.isAllRowsSelected
    ) {
      return props.hasInfiniteScroll
        ? translate('Select all')
        : translate('Select all pages')
    }

    if (
      props.storeWidgetList.isAllRowsSelected ||
      (props.disableSelectAllPages && props.storeWidgetList.isPageRowsSelected)
    ) {
      return translate('Unselect all pages')
    }

    return props.hasInfiniteScroll
      ? translate('Select displayed objects')
      : translate('Select current page')
  }

  const hideLabel = props.hideLabel === true

  return (
    <ContainerFlex
      name={WidgetListSelectAllCheckbox.name}
      alignItems="center"
      items={[
        <ContainerFlex
          name="WidgetListSelectAllCheckboxItems"
          alignItems="center"
          items={[
            <Popover
              placement="top"
              open={showPopover}
              content={
                <WidgetListSelectAllCheckboxPopoverContent
                  storeWidgetList={props.storeWidgetList}
                  onChange={setShowPopover}
                  hasInfiniteScroll={props.hasInfiniteScroll}
                  disableSelectAllPages={props.disableSelectAllPages}
                />
              }
            >
              <FormWrapperCheckbox
                labelledBy="selectAll"
                checkboxProps={{
                  checked:
                    props.storeWidgetList.isPageRowsSelected &&
                    !props.storeWidgetList.isEmpty,
                  indeterminate:
                    props.storeWidgetList.isAllRowsPartiallySelected,
                  disabled: props.storeWidgetList.isEmpty,
                  onChange: onWidgetListSelectAllCheckboxChange(
                    props.storeWidgetList,
                    setShowPopover,
                    props.disableSelectAllPages
                  )
                }}
              />
            </Popover>,

            !props.storeWidgetList.isEmpty && (
              <IconArrow
                labelledBy="togglePageSelector"
                direction="down"
                rotation={180}
                expanded={showPopover}
                onClick={onWidgetListSelectArrayClick}
              />
            )
          ]}
          spaced
          spaceWidth="verySmall"
        />,

        !hideLabel && (
          <LabelNonBreakable>
            <LabelAlt
              labelledBy="selectAllLabels"
              variant={LabelAltVariant.Pointer}
              containerProps={{
                onClick: onWidgetListSelectAllLabelClick(props.storeWidgetList)
              }}
            >
              {renderLabel()}
            </LabelAlt>
          </LabelNonBreakable>
        )
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(WidgetListSelectAllCheckbox)
