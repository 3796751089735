import type { IGraphQLQuery } from '@libs/graphQL/types'
import type { UpdateDataCollectionStatusMutationArgs } from '@server/graphql/typeDefs/types'
import type { Mutation } from '../typeDefs/types'

/**
 * Data collection status.
 */

/* Edit data collection status */

export type MutationUpdateDataCollectionStatus = IGraphQLQuery<
  UpdateDataCollectionStatusMutationArgs,
  Pick<Mutation, 'updateDataCollectionStatus'>
>

export const mutationUpdateDataCollectionStatus = `
  mutation mutationUpdateDataCollectionStatus(
    $dataCollectionStatus: InputUpdateDataCollectionStatus!
  ) {
    updateDataCollectionStatus(
      dataCollectionStatus: $dataCollectionStatus
    ) {
      isEnabled
      notWorkingBecauseOf
      sensitiveDataEnabled
    }
  }
`
