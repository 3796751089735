import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import HoneyAccountCommonForm from '../HoneyAccountCreatePage/HoneyAccountCommonForm'
import {
  onHoneyAccountEditCancelClick,
  onHoneyAccountEditSubmit,
  onHoneyAccountDeleteSubmit
} from './handlers'

interface IHoneyAccountEditFormProps {
  directoryId: number
}

const HoneyAccountEditForm: React.FC<IHoneyAccountEditFormProps> = props => {
  const appRouter = useAppRouter()

  const { storeManagementDirectories, storeBlades } = useStores()

  return (
    <HoneyAccountCommonForm
      version="edition"
      directoryId={props.directoryId}
      onCancelButtonClick={onHoneyAccountEditCancelClick(storeBlades)}
      onSubmit={onHoneyAccountEditSubmit(
        appRouter,
        storeManagementDirectories
      )(props.directoryId)}
      onDelete={onHoneyAccountDeleteSubmit(
        appRouter,
        storeManagementDirectories
      )(props.directoryId)}
    />
  )
}

export default HoneyAccountEditForm
