import { ContainerFlex } from '@app/components/Container'
import { IconCount } from '@app/components/Icon'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { HorizontalLine } from '@app/components/Separator'
import { CardSimple } from '@app/components-legacy/Card/CardSimple/CardSimple'
import { useAppTranslator } from '@app/hooks'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import InformationLine from './InformationLine'
import TemplateWrapper from './TemplateWrapper'
import { DashboardTemplate } from './types'

interface INativeAdminMonitoringProps {
  children?: React.ReactNode
}

const StyledHorizontalLine = styled(HorizontalLine)`
  margin-top: ${consts.paddingLarge};
  margin-bottom: ${consts.paddingMedium};
`

const NativeAdminMonitoring: React.FC<INativeAdminMonitoringProps> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Dashboard.AddDashboardDrawer',
      'Dashboard.Common',
      'Components.LabelChecker.ComplexityLevel'
    ]
  })

  return (
    <TemplateWrapper
      title={translate('Native Admin Monitoring')}
      templateType={DashboardTemplate.nativeAdminMonitoring}
    >
      <ContainerFlex
        name="NativeAdminMonitoring"
        flexGap="verySmall"
        direction="column"
        items={[
          <CardSimple>
            <ContainerFlex
              name="NativeAdminMonitoringContent"
              flexGap="medium"
              items={[
                <IconCount />,
                <ContainerFlex
                  name="AdministrativeAccountsMetrics"
                  flexGap="verySmall"
                  direction="column"
                  items={[
                    <Label variant={LabelVariant.p_bold}>
                      {translate('Administrative Accounts Metrics')}
                    </Label>,
                    <Label variant={LabelVariant.description}>
                      {translate(
                        'Monitor current users in natively-privileged groups'
                      )}
                    </Label>
                  ]}
                />
              ]}
              itemsFlexShrink={[0, 1]}
            />

            <StyledHorizontalLine color={consts.CTAColorV2.disable} />

            <ContainerFlex
              name="CountList"
              flexGap="verySmall"
              direction="column"
              items={[
                <InformationLine title={translate('Total native accounts')} />,
                <InformationLine title={translate('Missing LAPS config')} />,
                <InformationLine
                  title={translate('Protected user group not in use')}
                />,
                <InformationLine
                  title={translate('Admin count attribute - deviance')}
                />,
                <InformationLine
                  title={translate('Use of default admin ID')}
                />,
                <InformationLine
                  title={translate('Local admin account management')}
                />
              ]}
            />
          </CardSimple>
        ]}
      />
    </TemplateWrapper>
  )
}

export default NativeAdminMonitoring
