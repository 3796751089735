import { AssetImg } from '@app/components/Asset/AssetImg'
import { StepPoints } from '@app/components/Bullet'
import { ContainerFooter } from '@app/components/Container'
import {
  ContainerContent,
  ContainerFlex,
  ContainerRbac
} from '@app/components-legacy/Container'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { LabelAlt, LabelAltError } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useAppRouter, useAppTranslator, useStores } from '@app/hooks'
import ProductLicenseDetails from '@app/pages/Management/SystemPage/AboutPage/ProductLicenseDetails'
import ProductLicenseUploadButton from '@app/pages/Management/SystemPage/AboutPage/ProductLicenseUploadButton'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import AuthPlaceHolders from '../AuthPlaceHolders'
import AuthContainerPadded from '../Common/AuthContainerPadded'
import { canReadLicenseDuringLogin } from '../permissions'
import { handleLicenseCancelOnClick, handleLicenseOKOnClick } from './handlers'

const StyledAuthPlaceHolders = styled(AuthPlaceHolders)`
  min-height: auto;
`

const StyledContainerFooter = styled(ContainerFooter)`
  padding: 0 50px 30px;
`
const StyledLabelAlt = styled(LabelAlt)`
  padding: 0 30px;
  text-align: center;
`

interface ILicensePageProps {}

const LicensePage: React.FC<ILicensePageProps> = () => {
  const { storeLicense, storeEula } = useStores()

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.System.About.License']
  })

  const license = storeLicense.license

  const appRouter = useAppRouter()

  return (
    <DSThemeProvider themeName={DSThemeName.default}>
      <StyledAuthPlaceHolders
        layout={{
          header: (
            <ContainerFlex
              name="UpdateLicenseHeader"
              alignItems="center"
              justifyContent="center"
              style={{
                height: '150px'
              }}
              items={[
                <AssetImg
                  imageName="logo-tenable-ad"
                  width={280}
                  fileType="svg"
                  labelledBy="tenableLogo"
                />
              ]}
            />
          ),
          content: (
            <AuthContainerPadded>
              <ContainerContent
                flags={[
                  storeLicense.storeFlagsFetchLicense.flags,
                  {
                    ...storeLicense.storeFlagsUpdateLicense.flags,
                    // don't show error message in the container even if the license
                    // upload has failed (if the license is not valid, a message will pop)
                    isError: false
                  }
                ]}
                spinner={<SpinnerInline />}
              >
                {!license && (
                  <>
                    <ContainerFlex
                      name="LicensePageUploadContent"
                      alignItems="center"
                      justifyContent="center"
                      items={[
                        <AssetImg
                          imageName="validate-license"
                          width={116}
                          fileType="svg"
                          labelledBy="validateLicense"
                        />
                      ]}
                    />
                    <StyledLabelAlt variant={LabelAltVariant.Bolded}>
                      {translate('Product License')}
                    </StyledLabelAlt>
                  </>
                )}

                <ContainerRbac
                  rbacCapabilityCheck={canReadLicenseDuringLogin}
                  render={({ isGranted }) => {
                    if (!isGranted) {
                      return (
                        <LabelAltError labelledBy="notAllowedToReadLicense">
                          {translate('You are not allowed to read the license')}
                        </LabelAltError>
                      )
                    }

                    return (
                      <ContainerFlex
                        name="LicenseDetails"
                        direction="column"
                        justifyContent="space-between"
                        paddingV={license ? 'default' : 'large'}
                        items={[
                          <>
                            {license && (
                              <LabelAlt variant={LabelAltVariant.Bolded}>
                                {translate('License informations')}
                              </LabelAlt>
                            )}
                            <ProductLicenseDetails />
                          </>,

                          <ContainerFlex
                            name="LicenseDetails"
                            justifyContent="center"
                            items={[<ProductLicenseUploadButton />]}
                          />
                        ]}
                        spaced
                      />
                    )
                  }}
                />
                <StepPoints nbPoints={3} selectedPoint={license ? 3 : 2} />
              </ContainerContent>
            </AuthContainerPadded>
          ),
          footer: (
            <StyledContainerFooter>
              <FormWrapperButton
                labelledBy="cancel"
                variant={ButtonVariant.secondary}
                size={ButtonSize.large}
                buttonProps={{
                  onClick: handleLicenseCancelOnClick(
                    appRouter,
                    storeEula,
                    storeLicense
                  )
                }}
              >
                {translate('Cancel')}
              </FormWrapperButton>

              <FormWrapperButton
                labelledBy="continue"
                variant={ButtonVariant.secondary}
                size={ButtonSize.large}
                buttonProps={{
                  type: 'primary',
                  disabled: !storeLicense.isLicenseStatusAuthorizeAccess,
                  onClick: handleLicenseOKOnClick(appRouter)
                }}
              >
                {translate('Continue')}
              </FormWrapperButton>
            </StyledContainerFooter>
          )
        }}
      />
    </DSThemeProvider>
  )
}

export default observer(LicensePage)
