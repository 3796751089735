import { ContainerFooter } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { Modal, ModalCancelButton } from '@app/components-legacy/Modal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleAccessTokenRefreshOnSubmit } from './handlers'

export interface IModalRefreshReportAccessTokenProps {}

const ModalRefreshReportAccessToken: React.FC<
  IModalRefreshReportAccessTokenProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.System.Configuration.ReportingCenter']
  })

  const { storeManagementReportingCenter } = useStores()
  const { storeModalConfirmReportAccessTokenRefresh } =
    storeManagementReportingCenter

  const isLoading =
    storeManagementReportingCenter.storeFlagsRefreshReportAccessToken.flags
      .isLoading

  return (
    <Modal
      modalProps={{
        title: translate('Generate a new access key'),
        footer: (
          <ContainerFooter variant="modal">
            <ModalCancelButton
              storeModal={storeModalConfirmReportAccessTokenRefresh}
            />

            <FormWrapperButton
              labelledBy="submit"
              variant={ButtonVariant.primary}
              size={ButtonSize.large}
              buttonProps={{
                onClick: handleAccessTokenRefreshOnSubmit(
                  storeManagementReportingCenter
                ),
                loading: isLoading
              }}
            >
              {translate('Generate')}
            </FormWrapperButton>
          </ContainerFooter>
        )
      }}
      storeModal={storeModalConfirmReportAccessTokenRefresh}
    >
      <Label variant={LabelVariant.p}>
        {translate('You are generating a new access key')}
      </Label>
    </Modal>
  )
}

export default observer(ModalRefreshReportAccessToken)
