import type { IIconProps } from '@app/components/Icon/types'
import { IconRightOutlined } from '@app/components-legacy/Icon/IconAntd'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

interface IIconRightAntdProps extends IIconProps {}

const StyledIconRightOutlined = styled(IconRightOutlined)`
  svg {
    fill: ${props => props.color ?? CTAColorV2.primary};
    width: ${props => props.size ?? 11}px;
    height: ${props => props.size ?? 11}px;
  }
`

const IconRightAntd: React.FC<IIconRightAntdProps> = props => (
  <StyledIconRightOutlined {...props} />
)

export default IconRightAntd
