import type { PropertiesNullable } from '@@types/helpers'
import type { ApiKey, Maybe } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityApiKey
  extends EntityBase
  implements PropertiesNullable<ApiKey>
{
  key: Maybe<string> = null

  constructor(data: Partial<ApiKey>) {
    super()
    Object.assign(this, data)
  }
}
