import { FormWrapperCheckbox } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { Criticity } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'

interface ILabelCheckerGroupProps {
  criticity: Criticity
  checkable: boolean
  checked: boolean
  disabled: boolean
  indeterminate: boolean
  onLabelClick: () => void
  onCheckboxClick: () => void
}

const Link = styled.div`
  cursor: pointer;
  user-select: none;
`

const LabelCheckerGroup: React.FC<ILabelCheckerGroupProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.LabelChecker.ComplexityLevel']
  })

  if (!props.checkable) {
    return (
      <Link onClick={props.onLabelClick}>{translate(props.criticity)}</Link>
    )
  }

  return (
    <FormWrapperCheckbox
      labelledBy={`select-checkers-${props.criticity}`}
      checkboxProps={{
        onChange: props.onCheckboxClick,
        checked: props.checked,
        disabled: props.disabled,
        indeterminate: props.indeterminate
      }}
    >
      {translate(props.criticity)}
    </FormWrapperCheckbox>
  )
}

export default LabelCheckerGroup
