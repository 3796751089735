import type { IIconProps } from '@app/components/Icon/types'
import { IconPlayCircleOutlined } from '@app/components-legacy/Icon/IconAntd'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface IIconPlayCircleOutlinedAntdProps extends IIconProps {}

const StyledIconPlayCircleOutlined = styled(IconPlayCircleOutlined)`
  svg {
    width: ${props => props.size ?? 15}px;
    height: ${props => props.size ?? 15}px;
  }

  svg * {
    color: ${props => props.color ?? consts.colorBlue015};
  }
`

const IconPlayCircleOutlinedAntd: React.FC<
  IIconPlayCircleOutlinedAntdProps
> = props => <StyledIconPlayCircleOutlined {...props} />

export default IconPlayCircleOutlinedAntd
