import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperSelect } from '@app/components-legacy/Form'
import { InputSearch } from '@app/components-legacy/Input'
import { useAppTranslator, useStores } from '@app/hooks'
import type { BookmarkCategory } from '@app/stores/TrailFlow/StoreSearchBookmarks'
import { BookmarkCategoryEnum } from '@app/stores/TrailFlow/StoreSearchBookmarks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onCategorySelectorChange } from './handlers'
import { getCategoryTranslation } from './helper'

export interface IDrawerHeaderProps {}

const DrawerHeader: React.FC<IDrawerHeaderProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['TrailFlow.SearchBookmarks']
  })

  const { storeTrailFlow } = useStores()

  const categories: BookmarkCategory[] = [
    BookmarkCategoryEnum.All,
    BookmarkCategoryEnum.Uncategorized,
    ...storeTrailFlow.storeSearchBookmarks.searchBookmarksCategories
  ]

  return (
    <ContainerFlex
      name="SearchBookmarksHeader"
      itemsFlexGrow={[1, 0]}
      alignItems="center"
      items={[
        <InputSearch
          placeholder={translate('Search a bookmark')}
          storeInputSearch={
            storeTrailFlow.storeSearchBookmarks.storeInputSearch
          }
        />,

        <FormWrapperSelect
          labelledBy="categories"
          width="normal"
          selectProps={{
            value: storeTrailFlow.storeSearchBookmarks.categorySelectorValue,
            onChange: onCategorySelectorChange(
              storeTrailFlow.storeSearchBookmarks
            )
          }}
          selectOptions={categories.map(category => {
            return {
              label: getCategoryTranslation(category, translate),
              value: category
            }
          })}
        />
      ]}
      spaced
    />
  )
}

export default observer(DrawerHeader)
