import { ContainerFlex } from '@app/components/Container'
import { Label, LabelColorTag } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { consts } from '@app/styles'
import React from 'react'
import styled from 'styled-components'

export interface IFilterLabelWithSelectedCountProps {
  label: string
  selectedList: string[]
  labelledBy?: string
}

const StyledLabelColorTag = styled(LabelColorTag)`
  border-radius: ${consts.borderRadiusLargeRedesign};
  padding: 0px ${consts.paddingDefault};
  margin: 0px;
`

const FilterLabelWithSelectedCount: React.FC<
  IFilterLabelWithSelectedCountProps
> = props => {
  return (
    <ContainerFlex
      name=""
      items={[
        <Label label={props.label} />,
        props.selectedList.length > 0 && (
          <StyledLabelColorTag
            label={`${props.selectedList.length}`}
            color={consts.CTAColorV2.primary}
            labelProperties={{
              color: consts.colorWhite,
              variant: LabelVariant.caption,
              labelledBy: props.labelledBy
            }}
          />
        )
      ]}
      itemsFlexGrow={[1, 0]}
    />
  )
}

export default FilterLabelWithSelectedCount
