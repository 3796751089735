import {
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import LayoutFatalError from '@app/components-legacy/Layout/LayoutFatalError'
import { TypographySubTitle } from '@app/components-legacy/Typography'
import * as React from 'react'
import AuthPlaceHolders from '../Auth/AuthPlaceHolders'
import AuthTenableLogo from '../Auth/AuthTenableLogo'
import AuthContainerPadded from '../Auth/Common/AuthContainerPadded'

interface IStaticErrorProps {
  className?: string
  style?: React.CSSProperties
  reason: string
  children?: React.ReactNode
}

const StaticError: React.FC<IStaticErrorProps> = props => {
  // don't use stores here, this component is used as a fallback when the app
  // fails to initialize and stores will not be available.

  const reloadPage = () => {
    document.location.reload()
  }

  const renderContent = () => {
    const defaultReason =
      'An error has occurred during the loading of this page.'

    return (
      <AuthPlaceHolders
        layout={{
          header: <AuthTenableLogo />,
          content: (
            <AuthContainerPadded>
              <ContainerFlex
                name="StaticErrorContent"
                className="staticErrorContent"
                direction="column"
                items={[
                  <TypographySubTitle>
                    <div>Sorry, an error has occurred 🙁</div>
                  </TypographySubTitle>,

                  <p>{props.reason || defaultReason}</p>
                ]}
                spaced
                spaceWidth="default"
              />
            </AuthContainerPadded>
          ),
          footer: (
            <ContainerFooter>
              <ContainerFlex
                name="ButtonsLeft"
                items={[
                  // Hardcore the url here, stores could be unavailable
                  <FormWrapperButton
                    labelledBy="logout"
                    buttonProps={{
                      href: '/w/auth/logout'
                    }}
                  >
                    Logout (if connected)
                  </FormWrapperButton>,

                  <FormWrapperButton
                    labelledBy="backToHP"
                    buttonProps={{
                      href: '/'
                    }}
                  >
                    Back to the HP
                  </FormWrapperButton>
                ]}
                spaced
                spaceWidth="small"
              />

              <FormWrapperButton
                labelledBy="reloadCurrentPage"
                buttonProps={{
                  type: 'primary',
                  onClick: reloadPage
                }}
              >
                Reload the current page
              </FormWrapperButton>
            </ContainerFooter>
          )
        }}
      />
    )
  }

  return <LayoutFatalError content={renderContent()} />
}

export default StaticError
