import { AppRouteName } from '@app/routes'
import type { StoreActivityLogs } from '@app/stores'

/**
 * Save the filters (date range, IPs etc...) in querystring.
 */
export function updateActivityLogsUrlWithAllFilters(
  storeActivityLogs: StoreActivityLogs
) {
  const appRouter = storeActivityLogs.storeRoot.appRouter

  const url = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.ActivityLogs,
    parameters: {},
    queryStringParameters: storeActivityLogs.computeBoardQueryParameters()
  })

  storeActivityLogs.storeRoot.stores.storeBlades.updateLastBladeUrl(url)

  appRouter.history.replace(url)
}
