import type { MaybeUndef } from '@@types/helpers'
import { AppRouteName } from '@app/routes'
import type { StoreManagementSyslogs } from '@app/stores'
import { onSelectChange } from '@app/stores/helpers/StoreForm/handlers'
import type { AppRouterClient } from '@libs/Router/types'
import { SyslogInputType } from '@server/graphql/typeDefs/types'
import type { SelectValue } from 'antd/lib/select'
import { SyslogFormFieldName } from './types'

export const onSyslogListLoad =
  (storeManagementSyslogs: StoreManagementSyslogs) => () => {
    storeManagementSyslogs.fetchSyslogs({
      syslogsPage: 1,
      syslogsPerPage: storeManagementSyslogs.storeWidgetList.rowsPerPage
    })
  }

export const onSyslogCreateButtonClick =
  (appRouter: AppRouterClient) =>
  (storeManagementSyslogs: StoreManagementSyslogs) =>
  () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Configuration_Syslogs_Create,
      parameters: {}
    })

    appRouter.history.push(url)

    // cleanup
    storeManagementSyslogs.storeInputHealthChecks.reset()
  }

export const onSyslogTestIconClick =
  (storeManagementSyslogs: StoreManagementSyslogs) =>
  (syslogId: number) =>
  () => {
    storeManagementSyslogs.testExistingSyslog(syslogId)
  }

export const onSyslogEditIconClick =
  (appRouter: AppRouterClient) => (syslogId: number) => () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Configuration_Syslogs_Edit,
      parameters: {
        syslogId
      }
    })
    appRouter.history.push(url)
  }

export const onSyslogsListPageChange =
  (storeManagementSyslogs: StoreManagementSyslogs) =>
  (page: number, pageSize: MaybeUndef<number>) => {
    storeManagementSyslogs.fetchSyslogs({
      syslogsPage: page,
      syslogsPerPage: pageSize
    })
  }

export const onDirectoriesChange =
  (storeManagementSyslogs: StoreManagementSyslogs) => () => {
    const { storeForm, storeInfrastructures } = storeManagementSyslogs

    storeForm.setFieldValue(
      SyslogFormFieldName.directories,
      storeInfrastructures.getSelectedDirectoryIds().join(',')
    )
  }

export const onHealthCheckNamesChange =
  (storeManagementSyslogs: StoreManagementSyslogs) => () => {
    const { storeForm, storeInputHealthChecks } = storeManagementSyslogs

    storeForm.setFieldValue(
      SyslogFormFieldName.healthCheckNames,
      storeInputHealthChecks.selectedHealthChecksTemplatesNamesIds.join(',')
    )
  }

export const onProfilesChanges =
  (storeManagementSyslogs: StoreManagementSyslogs) =>
  (values: string[]): void => {
    storeManagementSyslogs.storeForm.setFieldValue(
      SyslogFormFieldName.profiles,
      values.join(',')
    )
  }

/**
 * Auto unselect checkers on increase criticity threshold
 */
export const onSyslogCriticityThresholdChanges =
  (storeManagementSyslogs: StoreManagementSyslogs) => (value: SelectValue) => {
    const { storeForm, storeInputCheckersExposure } = storeManagementSyslogs

    storeInputCheckersExposure.unselectCheckersByThreshold(Number(value))

    onSelectChange(storeForm)(SyslogFormFieldName.criticityThreshold)(value)
  }

export const onSyslogTriggerChanges =
  (storeManagementSyslogs: StoreManagementSyslogs) => (value: SelectValue) => {
    const { storeForm } = storeManagementSyslogs

    if (value === SyslogInputType.AdObjectChanges) {
      storeManagementSyslogs.storeForm.setFieldValue(
        SyslogFormFieldName.criticityThreshold,
        0
      )
    }

    onSelectChange(storeForm)(SyslogFormFieldName.inputType)(value)
  }
