import type { Maybe } from '@@types/helpers'
import type StoreGenericAlerts from '@app/stores/StoreAlerts/StoreGenericAlerts'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import type { MenuProps } from 'rc-menu/lib/Menu'
import type { ILabelAlertProps } from './types'

/**
 * Return handlers to "load" and remove an alert by wrapping a timer used
 * to remove the alert after `alertLifeTime` seconds.
 */
export const initAlertLoad =
  (storeGenericAlerts: StoreGenericAlerts<any>) => (alertLifeTime?: number) => {
    let alertTimer: Maybe<NodeJS.Timeout> = null

    function removeLiveAlert() {
      if (alertTimer) {
        clearTimeout(alertTimer)
      }
      storeGenericAlerts.removeLiveAlert()
    }

    return {
      /**
       * Start a timer to remove the alert after `alertLifeTime` seconds.
       */
      onAlertLoad: () => {
        if (!alertLifeTime) {
          return
        }

        alertTimer = setTimeout(() => {
          removeLiveAlert()
        }, alertLifeTime * 1000)
      },

      /**
       * Clear the timer if defined and remove the alert.
       */
      onAlertCloseClick: () => {
        removeLiveAlert()
      }
    }
  }

/**
 * If onAction handler is passed, call it when the menu is clicked.
 */
export const onAlertMenuClick =
  (onAction?: ILabelAlertProps['onAction']): MenuProps['onClick'] =>
  clickParam => {
    if (onAction) {
      onAction(String(clickParam.key))
    }
  }

/**
 * If onSelections handlers are passed, call them on checkbox changes.
 */
export const onAlertSelectionChange =
  (onSelections?: ILabelAlertProps['onSelections']) =>
  (e: CheckboxChangeEvent) => {
    if (onSelections) {
      e.target.checked
        ? onSelections.onSelection()
        : onSelections.onUnselection()
    }
  }
