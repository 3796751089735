import type { Maybe } from '@@types/helpers'
import CardSelectable from '@app/components-legacy/Card/CardSelectable'
import { ContainerFlex } from '@app/components-legacy/Container'
import LabelDevianceContent from '@app/components-legacy/Label/LabelDeviance/LabelDevianceContent'
import LabelDevianceReasonInitials from '@app/components-legacy/Label/LabelDeviance/LabelDevianceReasonInitials'
import type { EntityChecker } from '@app/entities'
import type EntityAdObjectAsAttributes from '@app/entities/EntityAdObject/EntityAdObjectAsAttribute'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import * as React from 'react'
import styled from 'styled-components'

interface ILabelDevianceProps {
  adObject?: Maybe<EntityAdObjectAsAttributes>
  checked: boolean
  className?: string
  description: Maybe<string>
  eventDate: string
  eventUrl?: Maybe<string>
  entityChecker: Maybe<EntityChecker>
  onCheck?: (e: CheckboxChangeEvent) => void
  reasonColor: string
  reasonName: string
  ignoreUntil: Maybe<string>
  resolvedAt: Maybe<string>
  selectable?: boolean
}

const LabelDeviance: React.FC<ILabelDevianceProps> = props => {
  const [expanded, setExpanded] = React.useState<boolean>(true)

  const toggleExpand = React.useCallback(() => {
    setExpanded(!expanded)
  }, [setExpanded, expanded])

  return (
    <CardSelectable
      selectable={props.selectable}
      checked={props.checked}
      onCheck={props.onCheck}
    >
      <ContainerFlex
        className={props.className}
        name="LabelDeviance"
        itemsFlexGrow={[0, 1]}
        items={[
          <LabelDevianceReasonInitials
            reasonColor={props.reasonColor}
            reasonName={props.reasonName}
          />,

          <LabelDevianceContent
            adObject={props.adObject}
            description={props.description}
            eventDate={props.eventDate}
            eventUrl={props.eventUrl}
            entityChecker={props.entityChecker}
            reasonName={props.reasonName}
            ignoreUntil={props.ignoreUntil}
            resolvedAt={props.resolvedAt}
            expanded={expanded}
            toggleExpand={toggleExpand}
          />
        ]}
      />
    </CardSelectable>
  )
}

export default styled(LabelDeviance)`
  width: 100%;
`
