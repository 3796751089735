import {
  ContainerContent,
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { WidgetListPagination } from '@app/components-legacy/Widgets/WidgetList'
import { useStores } from '@app/hooks/useStores'
import { EventDetailsPageContext } from '@app/pages/TrailFlow/contexts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import EventDetailDeviancesCardsContent from './EventDetailDeviancesCardsContent'
import EventDetailDeviancesTableHeader from './EventDetailDeviancesTableHeader'
import { onDeviancePageChange } from './handlers'

interface IEventDetailDeviancesTableProps {}

const EventDetailDeviancesTable: React.FC<
  IEventDetailDeviancesTableProps
> = () => {
  const { storeInfrastructures, storeTrailFlow } = useStores()
  const { storeEventDetailsDeviances } = storeTrailFlow.storeEventDetails

  const parameters = React.useContext(EventDetailsPageContext)

  if (!parameters) {
    return null
  }

  const adObject = storeTrailFlow.storeEventDetails.event?.getAdObject()

  const flags =
    storeEventDetailsDeviances.storeReloadIncriminatingAttributesFlags.flags

  return (
    <ContainerFlex
      name="EventDetailDeviancesTable"
      direction="column"
      items={[
        <EventDetailDeviancesTableHeader />,

        <ContainerContent flags={flags}>
          <>
            <ContainerFlex
              name="IncriminatingAttributesTable"
              direction="column"
              items={[
                <EventDetailDeviancesCardsContent
                  adObject={adObject}
                  storeInfrastructures={storeInfrastructures}
                  storeInputReasons={
                    storeEventDetailsDeviances.storeInputReasons
                  }
                  storeInputCheckersExposure={
                    storeEventDetailsDeviances.storeInputCheckersExposure
                  }
                  storeWidgetList={storeEventDetailsDeviances.storeWidgetList}
                />
              ]}
              spaced
            />

            <Portal name={PlaceHolderName.bladeFooter}>
              <ContainerFooter>
                <ContainerFlex
                  name="WidgetListPagination"
                  justifyContent="flex-end"
                  items={[
                    <WidgetListPagination
                      storeWidgetList={
                        storeEventDetailsDeviances.storeWidgetList
                      }
                      onChange={onDeviancePageChange(storeTrailFlow)(
                        parameters.infrastructureId,
                        parameters.directoryId,
                        parameters.eventId
                      )}
                    />
                  ]}
                />
              </ContainerFooter>
            </Portal>
          </>
        </ContainerContent>
      ]}
      spaced
    />
  )
}

export default observer(EventDetailDeviancesTable)
