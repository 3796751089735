import EntityAttackPathBaseNode from './EntityAttackPathBaseNode'
import type EntityAttackPathNode from './EntityAttackPathNode'

export default class EntityAttackPathNodeDrawer extends EntityAttackPathBaseNode {
  // Coordinates of the node before being moved after an expansion
  baseX: number = 0
  baseY: number = 0

  isExpanded: boolean = false

  constructor(data: Partial<EntityAttackPathNode>) {
    super()
    Object.assign(this, data)
  }
}
