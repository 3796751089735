/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  CreateTenantSetting,
  Credential,
  ErrorResponse,
  TenantSetting,
  UpdateTenantSetting
} from '../models/index'
import {
  CreateTenantSettingFromJSON,
  CreateTenantSettingToJSON,
  CredentialFromJSON,
  CredentialToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  TenantSettingFromJSON,
  TenantSettingToJSON,
  UpdateTenantSettingFromJSON,
  UpdateTenantSettingToJSON
} from '../models/index'

export interface CreateTenantSettingRequest {
  createTenantSetting: CreateTenantSetting
}

export interface DeleteTenantSettingRequest {
  tenantSettingId: string
}

export interface UpdateTenantSettingRequest {
  tenantSettingId: string
  updateTenantSetting: UpdateTenantSetting
}

/**
 *
 */
export class SettingsApi extends runtime.BaseAPI {
  /**
   * Create new tenant settings
   */
  async createTenantSettingRaw(
    requestParameters: CreateTenantSettingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TenantSetting>> {
    if (requestParameters['createTenantSetting'] == null) {
      throw new runtime.RequiredError(
        'createTenantSetting',
        'Required parameter "createTenantSetting" was null or undefined when calling createTenantSetting().'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/core/ad/settings/tenants`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateTenantSettingToJSON(
          requestParameters['createTenantSetting']
        )
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      TenantSettingFromJSON(jsonValue)
    )
  }

  /**
   * Create new tenant settings
   */
  async createTenantSetting(
    requestParameters: CreateTenantSettingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TenantSetting> {
    const response = await this.createTenantSettingRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }

  /**
   * Delete a tenant setting
   */
  async deleteTenantSettingRaw(
    requestParameters: DeleteTenantSettingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['tenantSettingId'] == null) {
      throw new runtime.RequiredError(
        'tenantSettingId',
        'Required parameter "tenantSettingId" was null or undefined when calling deleteTenantSetting().'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/core/ad/settings/tenants/{tenantSettingId}`.replace(
          `{${'tenantSettingId'}}`,
          encodeURIComponent(String(requestParameters['tenantSettingId']))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Delete a tenant setting
   */
  async deleteTenantSetting(
    requestParameters: DeleteTenantSettingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.deleteTenantSettingRaw(requestParameters, initOverrides)
  }

  /**
   * Retrieves all credentials
   */
  async listCredentialsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<Credential>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/core/ad/settings/credentials`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(CredentialFromJSON)
    )
  }

  /**
   * Retrieves all credentials
   */
  async listCredentials(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<Credential>> {
    const response = await this.listCredentialsRaw(initOverrides)
    return await response.value()
  }

  /**
   * Retrieve all tenant settings
   */
  async listTenantSettingsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<TenantSetting>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/core/ad/settings/tenants`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TenantSettingFromJSON)
    )
  }

  /**
   * Retrieve all tenant settings
   */
  async listTenantSettings(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<TenantSetting>> {
    const response = await this.listTenantSettingsRaw(initOverrides)
    return await response.value()
  }

  /**
   * Update tenant settings
   */
  async updateTenantSettingRaw(
    requestParameters: UpdateTenantSettingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TenantSetting>> {
    if (requestParameters['tenantSettingId'] == null) {
      throw new runtime.RequiredError(
        'tenantSettingId',
        'Required parameter "tenantSettingId" was null or undefined when calling updateTenantSetting().'
      )
    }

    if (requestParameters['updateTenantSetting'] == null) {
      throw new runtime.RequiredError(
        'updateTenantSetting',
        'Required parameter "updateTenantSetting" was null or undefined when calling updateTenantSetting().'
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/core/ad/settings/tenants/{tenantSettingId}`.replace(
          `{${'tenantSettingId'}}`,
          encodeURIComponent(String(requestParameters['tenantSettingId']))
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateTenantSettingToJSON(
          requestParameters['updateTenantSetting']
        )
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      TenantSettingFromJSON(jsonValue)
    )
  }

  /**
   * Update tenant settings
   */
  async updateTenantSetting(
    requestParameters: UpdateTenantSettingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TenantSetting> {
    const response = await this.updateTenantSettingRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }
}
