import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { consts } from '@app/styles'
import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'
import classNames from 'classnames'
import * as React from 'react'
import styled from 'styled-components'
import {
  IconDownOutlined,
  IconLeftOutlined,
  IconRightOutlined,
  IconUpOutlined
} from './IconAntd'

interface IIconArrowProps {
  className?: string
  direction: 'up' | 'right' | 'down' | 'left'
  rotation?: 90 | 180
  expanded?: boolean
  onClick?: () => void
  labelledBy: string
}

const IconArrow: React.FC<IIconArrowProps> = props => {
  const { testAttributeProps } = useTestAttribute('link')

  const getIconComponent = () => {
    switch (props.direction) {
      case 'up':
        return IconUpOutlined

      case 'right':
        return IconRightOutlined

      case 'down':
        return IconDownOutlined

      case 'left':
        return IconLeftOutlined
    }
  }

  const IconComponent = getIconComponent()

  const classes = classNames({
    ...(props.className && { [props.className]: true }),
    expanded: props.expanded === true
  })

  const iconProps = filterEmptyProps({
    onClick: props.onClick
  })

  return (
    <IconComponent
      className={classes}
      {...iconProps}
      {...testAttributeProps('img')(props.labelledBy)}
    />
  )
}

function getRotationValue(rotation: IIconArrowProps['rotation']): string {
  switch (rotation) {
    case 90:
      return '90deg'

    default:
      return '180deg'
  }
}

export default styled(IconArrow)`
  /* Force override of Antd default transition for icons */
  transition: transform 0.1s ease-out !important;

  ${props => {
    if (!props.rotation) {
      return ''
    }

    return `
      &.expanded {
        transform: rotate(${getRotationValue(props.rotation)});
      }
    `
  }}
  &:hover,
  &:focus {
    color: ${consts.colorBlue010};
  }
`
