import type StoreMenu from '@app/stores/helpers/StoreMenu'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import type { AppRouterClient } from '@libs/Router/types'
import type { MenuItemProps } from 'antd/lib/menu/MenuItem'

/**
 * Select the clicked menu.
 */
export const onMenuEntryClick =
  (storeMenu: StoreMenu<any>) =>
  (menuEntry: IMenuEntry<string>): MenuItemProps['onClick'] =>
  menuInfo => {
    if (menuEntry.onClick) {
      menuEntry.onClick()
    }

    storeMenu.selectEntry(String(menuInfo.key))
  }

/**
 * Push to the history when clicking on the menu link, when a route definition
 * has been defined.
 */
export const onMenuEntryLinkClick =
  (appRouter: AppRouterClient, storeMenu: StoreMenu<any>) =>
  (menuEntry: IMenuEntry<string>) =>
  (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    storeMenu.selectEntry(menuEntry.key)

    // handle ctrl+click to open the link in a new window
    if (!e.ctrlKey) {
      e.preventDefault()
    }

    if (menuEntry.onClick) {
      menuEntry.onClick()
    }

    if (!menuEntry.routeDefinition) {
      return
    }

    const pathname = appRouter.makeRouteInfosPathname(menuEntry.routeDefinition)
    appRouter.history.push(pathname)
  }
