import type { PropertiesNullable } from '@@types/helpers'
import { ValueType } from '@libs/valueTypeParser/types'
import type {
  Change,
  ChangeValues,
  Maybe
} from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityChange
  extends EntityBase
  implements PropertiesNullable<Change>
{
  attributeName: Maybe<string> = null
  valueType: Maybe<ValueType> = null

  values: Maybe<ChangeValues> = null

  constructor(data: Partial<Change>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return the valueType of the change or fallback on string.
   */
  getValueType(): ValueType {
    return this.valueType || ValueType.string
  }
}
