import * as React from 'react'
import styled from 'styled-components'

export enum SpinnerInlineSize {
  normal = '18',
  small = '12'
}

export interface ISpinnerInlineProps {
  size?: SpinnerInlineSize
  style?: React.CSSProperties
  className?: string
}

const SpinnerInline: React.FC<ISpinnerInlineProps> = props => {
  return (
    <div
      data-name="SpinnerInline"
      className={props.className}
      style={props.style}
    >
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  )
}

export default styled(SpinnerInline)`
  > div {
    width: ${props => (props.size ? props.size : SpinnerInlineSize.normal)}px;
    height: ${props => (props.size ? props.size : SpinnerInlineSize.normal)}px;
    margin-right: 5px;
    background-color: ${({ theme }) => theme.tokens['spinner/color/default']};

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`
