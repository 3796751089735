import { ContainerContent } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToSMTPConfiguration } from '../permissions'
import ConfigurationSMTPForm from './ConfigurationSMTPForm'
import { onSMTPConfigurationLoad, onSMTPConfigurationUnload } from './handlers'

interface IConfigurationSMTPPageProps {}

const ConfigurationSMTPPage: React.FC<IConfigurationSMTPPageProps> = () => {
  const { storeManagementApplicationSettings } = useStores()

  return (
    <ContainerContent
      onLoad={onSMTPConfigurationLoad(storeManagementApplicationSettings)}
      onUnload={onSMTPConfigurationUnload(storeManagementApplicationSettings)}
      flags={
        storeManagementApplicationSettings.storeFlagsAppSettingsFetch.flags
      }
      rbacCapabilityCheck={canAccessToSMTPConfiguration}
    >
      <ConfigurationSMTPForm />
    </ContainerContent>
  )
}

export default observer(ConfigurationSMTPPage)
