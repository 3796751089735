import type {
  EntityAttacksSummaryCard,
  EntityAttackType,
  EntityDirectory
} from '@app/entities'
import { createEntities, EntityAttacksStat } from '@app/entities'
import type {
  IAttackChartData,
  IAttackSortData
} from '@app/pages/IoA/IoABoardPage/IoABoardCards/CardChart/types'
import {
  getNumberOfAttacksByCriticity,
  getNumberOfAttacksInTime,
  getRateOfAttacksByCriticity
} from '@app/pages/IoA/IoABoardPage/IoABoardCards/CardChart/utils'
import type { StoreRoot } from '@app/stores'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import { getTop3AttackTypes } from '@app/stores/IoA/StoreBoard/StoreSummaryCard/utils'
import StoreBase from '@app/stores/StoreBase'
import { ensureArray } from '@libs/ensureArray'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import type { QueryAttacksStats } from '@server/graphql/queries/ioa'
import { queryAttacksStats } from '@server/graphql/queries/ioa'
import type {
  AttacksStatsQueryArgs,
  Criticity,
  DashboardWidgetOptionsSerieStatAttack,
  DashboardWidgetOptionsSerieStatDataPointCount,
  Maybe
} from '@server/graphql/typeDefs/types'
import { AttacksSummaryChartCardType } from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable } from 'mobx'

interface IStoreSummaryCardOptions {
  attacksSummaryCardEntity: EntityAttacksSummaryCard
}

/**
 * Store stats for one card.
 */
export default class StoreSummaryCard extends StoreBase<IStoreSummaryCardOptions> {
  public storeFlagsFetchAttackStats = new StoreFlags(this.storeRoot)

  private $attacksStats = observable.box<Maybe<EntityAttacksStat[]>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreSummaryCardOptions) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch attacks when loading the interface for the first time.
   */
  fetchAttacksStats = (_args: AttacksStatsQueryArgs): Promise<void> => {
    this.storeFlagsFetchAttackStats.loading()

    const args: AttacksStatsQueryArgs = {
      ..._args,
      profileId: this.storeRoot.stores.storeAuthentication.currentProfileId
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryAttacksStats>(queryAttacksStats, args)
      })
      .then(({ attacksStats }) => {
        this.setAttacksStats(attacksStats)
        this.storeFlagsFetchAttackStats.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsFetchAttackStats))
  }

  /**
   * Compute args to fetch card statistics.
   */
  computeArgs(): Maybe<AttacksStatsQueryArgs> {
    const { storeAuthentication, storeIoA } = this.storeRoot.stores
    const { storeTimeline } = storeIoA.storeBoard

    const { attacksSummaryCardEntity } = this.options

    const ioaSeries = storeIoA.storeBoard.ioaSeriesBySelectedCriticity
    const directoryId =
      attacksSummaryCardEntity.getPropertyAsNumber('directoryId')

    // False if you want only open attacks.
    // True for closed attacks.
    // If not specified, won't filter anything.
    const isClosed = storeIoA.storeBoard.storeSummaryCards
      .displayOnlyAttackedDomainsSwitch
      ? false
      : undefined

    const args: AttacksStatsQueryArgs = {
      profileId: storeAuthentication.currentProfileId,
      dateStart: storeTimeline.dateStart,
      dateEnd: storeTimeline.dateEnd,
      interval: storeTimeline.interval,
      directoryIds: [directoryId],
      ioaSeries,
      isClosed
    }

    return args
  }

  /**
   * Return the rate attached to the criticity
   */
  getRateForCriticity(criticity: Criticity): number {
    const foundCriticity = this.rateOfAttacksByCriticity.find(
      x => x.criticity === criticity
    )

    if (!foundCriticity) {
      return 0
    }

    return foundCriticity.rate
  }

  /* Actions */

  @action
  setAttacksStats(attacksStats: DashboardWidgetOptionsSerieStatAttack[]): this {
    const attacksStatEntities = createEntities<
      DashboardWidgetOptionsSerieStatAttack,
      EntityAttacksStat
    >(EntityAttacksStat, attacksStats)

    this.$attacksStats.set(attacksStatEntities)
    return this
  }

  /* Computed */

  @computed
  get attacksStats(): EntityAttacksStat[] {
    return ensureArray(this.$attacksStats.get())
  }

  @computed
  get directoryEntity(): Maybe<EntityDirectory> {
    const directoryId = this.options.attacksSummaryCardEntity.directoryId

    if (!directoryId) {
      return null
    }

    const { storeBoard } = this.storeRoot.stores.storeIoA

    return storeBoard.storeInfrastructures.directories.get(directoryId) || null
  }

  /**
   * Return the top 3 of attacks types related to the card.
   */
  @computed
  get attackTypesTop3(): Array<{
    attackType: EntityAttackType
    count: number
  }> {
    const { storeBoard } = this.storeRoot.stores.storeIoA

    const counts = ensureArray(
      this.options.attacksSummaryCardEntity.statsCountEntities
    )

    const attackTypesNames = storeBoard.attackTypeEntities.reduce(
      (acc, entry) => {
        acc.set(
          entry.getPropertyAsNumber('id'),
          entry.getPropertyAsString('name')
        )
        return acc
      },
      new Map<number, string>()
    )

    return getTop3AttackTypes(counts, attackTypesNames)
      .map(dataPoint => {
        const attackType = storeBoard.getAttackTypeById(dataPoint.attackTypeId)

        if (!attackType) {
          return
        }

        return {
          attackType,
          count: dataPoint.count
        }
      })
      .filter(isDefined)
  }

  /**
   * Return data for charts of the rate of attacks by criticity.
   */
  @computed
  get rateOfAttacksByCriticity(): IAttackChartData[] {
    return getRateOfAttacksByCriticity(this.options.attacksSummaryCardEntity)
  }

  /**
   * Return data for charts of the number of attacks by criticity.
   */
  @computed
  get numberOfAttacksByCriticity(): IAttackSortData[] {
    return getNumberOfAttacksByCriticity(this.options.attacksSummaryCardEntity)
  }

  /**
   * Return data for the charts of number of attacks in time.
   */
  @computed
  get numberOfAttacksInTime(): DashboardWidgetOptionsSerieStatDataPointCount[] {
    return getNumberOfAttacksInTime(this.attacksStats)
  }

  /**
   * Return true if there is some attacks for this card.
   */
  @computed
  get hasAttacksDetected(): boolean {
    switch (this.options.attacksSummaryCardEntity.chartType) {
      case AttacksSummaryChartCardType.Donut: {
        return this.rateOfAttacksByCriticity.some(counts => {
          return counts.rate !== 0
        })
      }

      case AttacksSummaryChartCardType.LineChart: {
        return ensureArray(this.$attacksStats.get()).some(stat => {
          return ensureArray(stat.data).some(point => point.count > 0)
        })
      }
    }
  }
}
