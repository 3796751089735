import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import HoneyAccountCommonLayout from '../HoneyAccountCreatePage/HoneyAccountCommonLayout'
import { canEditHoneyAccount } from '../permissions'
import { onHoneyAccountEditLoad } from './handlers'
import HoneyAccountEditForm from './HoneyAccountEditForm'

interface IHoneyAccountEditPageProps {}

const HoneyAccountEditPage: React.FC<IHoneyAccountEditPageProps> = props => {
  const appRouter = useAppRouter()
  const { storeManagementDirectories } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_System_Directories_Honey_Account_Edit,
    parameters: {
      directoryId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <Blade
      routeDefinition={{
        routeName:
          AppRouteName.Management_System_Directories_Honey_Account_Edit,
        parameters: {
          directoryId: parameters.directoryId
        }
      }}
      onLoad={onHoneyAccountEditLoad(storeManagementDirectories)(
        parameters.directoryId
      )}
      flags={[
        storeManagementDirectories.storeFlags.flags,
        storeManagementDirectories.storeHoneyAccountConfigurationScriptFlags
          .flags
      ]}
      layout={{
        name: 'default',
        content: (
          <HoneyAccountCommonLayout directoryId={parameters.directoryId}>
            <HoneyAccountEditForm directoryId={parameters.directoryId} />
          </HoneyAccountCommonLayout>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canEditHoneyAccount(parameters.directoryId)}
    />
  )
}

export default observer(HoneyAccountEditPage)
