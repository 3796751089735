import { castArray } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'

interface IContainerPDFPageProps {
  headerItem: React.ReactNode
  footerItem: React.ReactNode
  bodyItems: React.ReactNode | React.ReactNodeArray
}

const StyledWrapper = styled.div`
  table {
    page-break-after: always;
    page-break-inside: auto;

    width: 100%;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;

    tr {
      height: 200px;
    }

    .sticky-footer {
      position: fixed;
      bottom: 0;

      height: 200px;
      width: 100%;
    }
  }
`

const ContainerPDFPage: React.FC<IContainerPDFPageProps> = props => {
  const renderItems = (item: React.ReactNode, index: number) => {
    return (
      <tr key={`td-${index}`}>
        <td>{item}</td>
      </tr>
    )
  }

  return (
    <StyledWrapper>
      <table>
        <thead>
          <tr>
            <th>{props.headerItem}</th>
          </tr>
        </thead>
        <tbody>{castArray(props.bodyItems).map(renderItems)}</tbody>
        <tfoot>
          <tr>
            <td>
              <div className="sticky-footer">{props.footerItem}</div>
            </td>
          </tr>
        </tfoot>
      </table>
    </StyledWrapper>
  )
}

export default ContainerPDFPage
