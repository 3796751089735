import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import * as React from 'react'
import { ContainerFlex } from 'tenable-design-system-alt'
import BulletPoint from './BulletPoint'

export interface IContainerStepTitleProps {
  stepNumber: number
  title: string
}

export default function ContainerStepTitle(props: IContainerStepTitleProps) {
  return (
    <div>
      <ContainerFlex
        name="DomainsConfigurationTitle"
        data-test="domainsConfigurationTitle"
        flexAlignItems="center"
        flexGap="spacingM"
      >
        <BulletPoint>{props.stepNumber}</BulletPoint>
        <Label variant={LabelVariant.h2}>{props.title}</Label>
      </ContainerFlex>
    </div>
  )
}
