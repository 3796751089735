import type { Maybe } from '@@types/helpers'
import { EntityUser } from '@app/entities'
import { canAccessToHealthCheck } from '@app/pages/HealthCheck/permissions'
import { canAccessToAzureAdData } from '@app/pages/permissions'
import { AppRouteName } from '@app/routes'
import type { StoreRoot } from '@app/stores'
import { HardReloadError, UnauthorizedAccessError } from '@libs/errors'
import { If } from '@libs/fp-helpers/If'
import { getFirstLanguageAvailable } from '@libs/i18n/initTranslator'
import { isDefined } from '@libs/isDefined'
import { isGrantedEntity } from '@libs/rbac/functions'
import { sanitize } from '@libs/sanitize'
import type { QueryInitUserData } from '@server/graphql/queries/init'
import { queryInitUserData } from '@server/graphql/queries/init'
import type { User } from '@server/graphql/typeDefs/types'
import { omit } from 'lodash'
import { setupUserRbacPermission } from '.'

/**
 * Fetch data that doesn't need to be authed.
 */
export function fetchAnonymousData(storeRoot: StoreRoot): Promise<void> {
  /**
   * Retrieve the version of the product
   */
  return (
    Promise.resolve()
      /**
       * Fetch the version of the product.
       */
      .then(() => storeRoot.stores.storeAbout.fetchAbout())

      /**
       * Fetch the association of the product.
       */
      .then(() => storeRoot.stores.storeAbout.fetchProductAssociation())

      /**
       * Retrieve the EULA.
       */
      .then(() => storeRoot.stores.storeEula.fetchEula())

      /**
       * Connect to the WS server.
       */
      .then(() => storeRoot.stores.storeAuthentication.connectToWsClient())

      /**
       * Retrieve the feature flags.
       */
      .then(() => storeRoot.stores.storeFeatureFlags.fetchFeatureFlags())

      /**
       * On error, forward exception to the handler in the caller.
       */
      .catch(err => {
        throw err
      })
  )
}

/**
 * Fetch data related to a user.
 */
export function fetchUserData(storeRoot: StoreRoot) {
  return (parameters: {
    profileName: Maybe<string>
    user: Maybe<User>
  }): Promise<QueryInitUserData['success']> => {
    const { storeAuthentication, storePreferences } = storeRoot.stores

    return (
      storeRoot
        .getGQLRequestor()
        .makeQuery<QueryInitUserData>(queryInitUserData)

        /**
         * Set preferences.
         *
         * Set them first in order to retrieve the profileName for the
         * `preferredProfileId` preference (if the profile is not present in the URL).
         */
        .then(data => {
          storePreferences.setPreferences(data.preferences)
          return data
        })

        /**
         * Set the authed user.
         *
         * (the one passed as parameter when login or the one fetched just before
         * when reloading the app)
         *
         * /!\ Keep this action *AFTER* the currentProfile assignation to be able
         * to build the url with the profileName inside when performing the
         * redirection.
         */
        .then(data => {
          const user =
            parameters.user || new EntityUser(omit(data.whoAmI, 'roles'))

          storeAuthentication.setWhoAmI({
            ...user,
            ...data.whoAmI
          })

          return data
        })

        /**
         * Fetch the license of the product.
         */
        .then(async data => {
          await If(!storeRoot.stores.storeLicense.license).fetch(() =>
            storeRoot.stores.storeLicense.fetchLicense()
          )

          return data
        })

        /**
         * Setup the application-configuration.
         */
        .then(data => {
          storeRoot.stores.storeApplicationConfiguration.setApplicationConfiguration(
            data.applicationConfiguration
          )

          return data
        })

        /**
         * Set RBAC permissions of the auth user.
         */
        .then(data => {
          setupUserRbacPermission(storeRoot, data.whoAmI.roles)

          return data
        })

        /**
         * Set Profile.
         *
         * /!\
         *
         * Profile needs to be set after:
         *   - The user: To be able to set the profileName in the url
         *   - The user's permissions: To be able to known if the user is granted
         *     to customized profiles (Only Tenable profile if not granted to IOE)
         */
        .then(data => {
          // the user is authenticated, the license has been set, but the profile is not granted. Logout.
          if (
            !isDefined(parameters.profileName) &&
            storeRoot.stores.storeAuthentication.isAuthenticated &&
            storeRoot.stores.storeLicense.isLicenseStatusAuthorizeAccess &&
            !isGrantedEntity(data.rbacProfiles)
          ) {
            const logoutUrl = storeRoot.appRouter.makeRouteInfosPathname({
              routeName: AppRouteName.MiddlewareAuth_Logout,
              parameters: {},
              queryStringParameters: {
                error:
                  'You do not have the required permissions to perform this action'
              }
            })

            throw new HardReloadError(logoutUrl)
          }

          // profiles could be not granted when the license is not set
          if (!isGrantedEntity(data.rbacProfiles)) {
            return data
          }

          storeAuthentication.setProfiles(data.rbacProfiles.node)

          // if the profile has not been set, there is a problem. Logout.
          if (!storeAuthentication.setCurrentProfile(parameters.profileName)) {
            throw new UnauthorizedAccessError()
          }

          return data
        })

        /**
         * Fetch the health check global status.
         */
        .then(async data => {
          if (
            storeRoot.stores.storeRbac.isUserGrantedTo(canAccessToHealthCheck)
          ) {
            await storeRoot.stores.storeHealthCheckGlobalStatus.fetchHealthChecksGlobalStatus()

            storeRoot.stores.storeHealthCheckGlobalStatus.startPollingGlobalStatus()
          }
          return data
        })

        /**
         * On error, forward exception to the handler in the caller.
         */
        .catch(err => {
          throw err
        })
    )
  }
}

/**
 * Do checks for translators, profiles, etc.
 */
export function checkPostLoginData(storeRoot: StoreRoot) {
  return (profileName: Maybe<string>): Promise<any> => {
    return (
      Promise.resolve()
        /**
         * Initialize the translator of the application.
         */
        .then(() => {
          // get the language from the preferences but avoid to return a language
          // that is not supported in the configuration
          const preferenceLanguage = getFirstLanguageAvailable(
            [storeRoot.stores.storePreferences.language],
            storeRoot.environment.config.i18n.locales.available,
            storeRoot.environment.config.i18n.locales.fallback
          )

          const firstPreferredLanguage =
            storeRoot.environment.translator.getFirstPreferredLanguage()

          // check that the current language is the one defined in preferences,
          // if not, reload the page with the correct language
          if (firstPreferredLanguage !== preferenceLanguage) {
            const preferredLanguage =
              storeRoot.stores.storePreferences.language ||
              storeRoot.environment.config.i18n.locales.fallback

            storeRoot.appTranslator.saveLanguage(preferredLanguage)

            throw new HardReloadError(null)
          }
        })

        /**
         * Verify profile name if defined
         * (could be undefined if the license if not set yet).
         *
         * If the profile set in the store is not the one set in the URL,
         * reload the app with the correct profileName.
         */
        .then(() => {
          if (!isDefined(profileName)) {
            return
          }

          const { currentSanitizedProfileName } =
            storeRoot.stores.storeAuthentication

          const sameProfile =
            currentSanitizedProfileName &&
            profileName &&
            sanitize(profileName) === currentSanitizedProfileName

          if (!sameProfile) {
            throw new HardReloadError(
              storeRoot.appRouter.makeRouteInfosPathname({
                routeName: AppRouteName.Profile,
                parameters: {
                  profileName: currentSanitizedProfileName
                }
              })
            )
          }
        })

        /**
         * On error, forward exception to the handler in the caller.
         */
        .catch(err => {
          throw err
        })
    )
  }
}

/**
 * Fetch post login data.
 *
 * If the profile is not defined (could be the case if the license is not set yet),
 * fetch anything.
 *
 * The app will be fully reloaded after that the license has been uploaded.
 */
export function fetchPostLoginData(storeRoot: StoreRoot): Promise<any> {
  if (!storeRoot.stores.storeLicense.isLicenseStatusAuthorizeAccess) {
    return Promise.resolve()
  }

  return (
    Promise.resolve()
      /**
       * Unregister to the feature flags.
       */
      .then(() => {
        return storeRoot.stores.storeFeatureFlags.unregisterEventWS()
      })

      /**
       * Reconnect to the WS server if the user is authenticated.
       */
      .then(() => {
        return storeRoot.stores.storeAuthentication.connectToWsClient()
      })

      /**
       * Retrieve the feature flags.
       */
      .then(() => {
        return storeRoot.stores.storeFeatureFlags.fetchFeatureFlags()
      })

      /**
       * Fetch infrastructures to be sure to be able to retrieve infras/dirs
       * of some entities (like live alerts).
       */
      .then(() => {
        return storeRoot.stores.storeInfrastructures.fetchInfrastructures()
      })

      /**
       * Fetch checkers and options once.
       */
      .then(() => {
        return storeRoot.stores.storeCheckers.fetchCheckers()
      })

      /**
       * Fetch application settings.
       */
      .then(() => {
        return storeRoot.stores.storeManagementApplicationSettings.fetchApplicationSettings()
      })

      /**
       * Retrieve the Microsoft Entra ID support to condition the display of the TID add-on
       * and check Tenable Cloud ApiTokens status.
       */
      .then(() => {
        const { storeRbac, storeManagementAzureAD } = storeRoot.stores

        return storeManagementAzureAD
          .fetchAzureADSupportConfiguration()
          .then(() => {
            const checkTenableApiKeysStatus =
              // check that the user is granted to access Tenable keys (access key + secret key)
              storeRbac.isUserGrantedTo(canAccessToAzureAdData) &&
              storeManagementAzureAD.isAzureAdSupportEnabled

            if (checkTenableApiKeysStatus) {
              return storeManagementAzureAD.fetchTenableCloudApiKeysPingStatus()
            }

            return
          })
      })

      /**
       * On error, forward exception to the handler in the caller.
       */
      .catch(err => {
        throw err
      })
  )
}
