import { AppRouteName } from '@app/routes'
import type { StoreManagementEmails, StoreMessages } from '@app/stores'
import { CRITICITY_LEVEL_MEDIUM } from '@libs/criticity'
import { EmailInputType } from '@server/graphql/typeDefs/types'
import { canSelectTriggerOnDeviances } from '../../permissions'
import { EmailFormFieldName } from '../types'

export const onEmailCreateLoad =
  (storeManagementEmails: StoreManagementEmails) => () => {
    const { storeRbac, storeAuthentication } =
      storeManagementEmails.storeRoot.stores

    const {
      storeForm,
      storeInfrastructures,
      storeInputCheckersExposure,
      storeInputCheckersAttacks
    } = storeManagementEmails

    return Promise.resolve()
      .then(() => {
        return Promise.all([
          storeInputCheckersExposure.fetchCheckers(),
          storeInputCheckersAttacks.fetchCheckers()
        ])
      })
      .then(() => {
        // fetch infras and save them all in the form values
        storeInfrastructures
          .fetchInfrastructures()
          .then(() => {
            storeForm
              .hardReset()
              .setDefaultFieldsValues([
                {
                  key: EmailFormFieldName.shouldNotifyOnInitialFullSecurityCheck,
                  value: true
                },
                {
                  key: EmailFormFieldName.shouldNotifyOnInitialFullSecurityCheck,
                  value: false
                },
                {
                  key: EmailFormFieldName.inputType,
                  value: storeRbac.isUserGrantedTo(canSelectTriggerOnDeviances)
                    ? EmailInputType.Deviances
                    : EmailInputType.Attacks
                },
                {
                  key: EmailFormFieldName.criticityThreshold,
                  value: CRITICITY_LEVEL_MEDIUM
                },
                {
                  key: EmailFormFieldName.profiles,
                  value: storeAuthentication.currentProfileId
                }
              ])
              .reset()

            storeForm.setFieldValue(
              EmailFormFieldName.directories,
              storeInfrastructures.getSelectedDirectoryIds().join(',')
            )
          })
          .catch(() => {
            // Already handled in the store
          })
      })
  }

export const onEmailCreateUnload =
  (storeManagementEmails: StoreManagementEmails) => () => {
    storeManagementEmails.reset()
  }

export const onEmailCreateSubmit =
  (storeManagementEmails: StoreManagementEmails) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeForm } = storeManagementEmails

    if (!storeForm.validate()) {
      return
    }

    const appRouter = storeManagementEmails.storeRoot.appRouter

    storeManagementEmails
      .createEmail(storeManagementEmails.getArgsFromStores())
      .then(() => {
        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Management_System_Configuration_Emails,
          parameters: {}
        })
        appRouter.history.push(url)
      })
      .catch(() => {
        // already catched in the store
      })
  }

/**
 * Send an alert with the current Email alert configuration.
 */
export const onEmailTestButtonClick =
  (
    storeMessages: StoreMessages,
    storeManagementEmails: StoreManagementEmails
  ) =>
  () => {
    const { storeForm } = storeManagementEmails

    const isFormValid = storeForm.validate()

    if (!isFormValid) {
      storeMessages.validationError()
      return
    }

    storeManagementEmails.testNewEmail(
      storeManagementEmails.getArgsFromStores()
    )
  }

export const onEmailCancelButtonClick =
  (storeManagementEmails: StoreManagementEmails) => () => {
    storeManagementEmails.reset()

    const { storeBlades } = storeManagementEmails.storeRoot.stores

    const url = storeBlades.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Configuration_Emails,
      parameters: {}
    })

    storeBlades.goToBladeUrl(url)
  }
