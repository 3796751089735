import { BladesContainer } from '@app/components-legacy/Blade'
import { LayoutDefault } from '@app/components-legacy/Layout'
import * as React from 'react'
import APIInspectorBlade from './APIInspectorBlade'

interface IAPIInspectorProps {}

const APIInspector: React.FC<IAPIInspectorProps> = props => {
  return (
    <LayoutDefault
      content={
        <BladesContainer>
          <APIInspectorBlade />
        </BladesContainer>
      }
    />
  )
}

export default APIInspector
