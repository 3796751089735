import { IconMagicWand } from '@app/components-legacy/Icon'
import * as React from 'react'
import styled from 'styled-components'

interface IIconWidget {
  className?: string
  isDisable: boolean
  onClick: () => void
}

const IconWidget: React.FC<IIconWidget> = props => {
  return (
    <div
      className={props.className}
      onClick={props.onClick}
      data-name="WandIconWidget"
      data-test="WandIconWidget"
    >
      <IconMagicWand />
    </div>
  )
}

export default styled(IconWidget)(props => {
  const cursor = props.isDisable ? 'not-allowed' : 'pointer'

  const backgroundColor = props.isDisable
    ? props.theme.tokens['inputExpression/backgroundColor/widgetButtonDisabled']
    : props.theme.tokens['inputExpression/backgroundColor/widgetButton']

  return `
    cursor: ${cursor};
    background-color: ${backgroundColor};
    position: absolute;
    top: 0;
    left: 0;
    padding: 2px 5px 0 5px;
    border-right: 1px solid ${
      props.theme.tokens['inputExpression/borderColor/default']
    };
    transition: color 0.3s, background-color 0.3s, border-color 0.3s;
    color: ${props.theme.tokens['inputExpression/color/widgetButtonIcon']};

    ${
      !props.isDisable &&
      `
        &:hover {
          background-color: ${props.theme.tokens['inputExpression/backgroundColor/widgetButtonHover']};
          border-color: ${props.theme.tokens['inputExpression/borderColor/hover']};
          color: ${props.theme.tokens['inputExpression/color/widgetButtonIconHover']};
        }
        `
    }
  `
})
