import type { IGraphQLQuery } from '@libs/graphQL/types'
import type {
  Query,
  SearchHoneyAccountCandidatesQueryArgs,
  HoneyAccountConfigurationScriptQueryArgs
} from '../typeDefs/types'

/**
 * Fetch infrastructures.
 */
export type QueryRbacInfrastructures = Pick<Query, 'rbacInfrastructures'>

export const queryRbacInfrastructures = `
  query queryRbacInfrastructures(
    $infrastructuresPage: Int
    $infrastructuresPerPage: Int
    $infrastructureId: Int
    $infrastructureName: String
  ) {
    rbacInfrastructures(
      infrastructuresPage: $infrastructuresPage
      infrastructuresPerPage: $infrastructuresPerPage
      infrastructureId: $infrastructureId
      infrastructureName: $infrastructureName
    ) {
      node {
        node {
          id
          name
          login
          directories {
            id
            name
            ip
            infrastructureId
            ioaProcedureInstalled
            ioaProcedureFailureReason
            ioaProcedureFailureDescription
            directoryUuid
          }
        }
        pagination {
          page
          perPage
          totalCount
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Fetch directories.
 */

export type QueryRbacDirectories = Pick<Query, 'rbacDirectories'>

export const queryRbacDirectories = `
  query queryRbacDirectories(
    $directoriesPage: Int
    $directoriesPerPage: Int
    $directoryId: Int
    $directoriesSearch: String
  ) {
    rbacDirectories(
      directoriesPage: $directoriesPage
      directoriesPerPage: $directoriesPerPage
      directoryId: $directoryId
      directoriesSearch: $directoriesSearch
    ) {
      node {
        node {
          id
          name
          ip
          type
          ldapPort
          globalCatalogPort
          smbPort
          dns
          ldapCrawlingStatus
          sysvolCrawlingStatus
          honeyAccountAdObjectId
          honeyAccountDistinguishedName
          honeyAccountConfigurationStatus
          infrastructureId
          relayId
          infrastructure {
            name
          }
          sensitiveDataCollectionIsEnabled
          sensitiveDataCollectionStatus
          ioaProcedureInstalled
        }
        pagination {
          page
          perPage
          totalCount
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

export type QuerySearchHoneyAccountCandidates = IGraphQLQuery<
  SearchHoneyAccountCandidatesQueryArgs,
  Pick<Query, 'searchHoneyAccountCandidates'>
>

export const querySearchHoneyAccountCandidates = `
  query querySearchHoneyAccountCandidates($honeyAccount: InputSearchHoneyAccountCandidates!) {
    searchHoneyAccountCandidates(
      honeyAccount: $honeyAccount
    ) {
      name
    }
  }
`

export type QueryHoneyAccountConfigurationScript = IGraphQLQuery<
  HoneyAccountConfigurationScriptQueryArgs,
  Pick<Query, 'honeyAccountConfigurationScript'>
>

export const queryHoneyAccountConfigurationScript = `
  query queryHoneyAccountConfigurationScript($honeyAccount: InputHoneyAccountConfigurationScript!) {
    honeyAccountConfigurationScript(
      honeyAccount: $honeyAccount
    ) {
      script
    }
  }
`
