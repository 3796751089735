import { ContainerContent } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToSecurityConfiguration } from '../permissions'
import ConfigurationSecurityForm from './ConfigurationSecurityForm'
import {
  onSecurityConfigurationLoad,
  onSecurityConfigurationUnload
} from './handlers'

interface IConfigurationSecurityPageProps {}

const ConfigurationSecurityPage: React.FC<
  IConfigurationSecurityPageProps
> = () => {
  const { storeManagementApplicationSettings } = useStores()

  return (
    <ContainerContent
      onLoad={onSecurityConfigurationLoad(storeManagementApplicationSettings)}
      onUnload={onSecurityConfigurationUnload(
        storeManagementApplicationSettings
      )}
      flags={
        storeManagementApplicationSettings.storeFlagsAppSettingsFetch.flags
      }
      rbacCapabilityCheck={canAccessToSecurityConfiguration}
    >
      <ConfigurationSecurityForm />
    </ContainerContent>
  )
}

export default observer(ConfigurationSecurityPage)
