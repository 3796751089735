import { BrandColorV2 } from '@app/styles/consts'
import { isDefined } from '@libs/isDefined'
import styled from 'styled-components'

interface IHorizontalLineProps {
  color?: string
  height?: number
  width?: number
}

const HorizontalLine = styled.hr<IHorizontalLineProps>`
  height: 0;
  width: ${({ width }) => (isDefined(width) ? `${width}px` : '100%')};
  border-top: ${({ height }) => height ?? 1}px solid
    ${({ color }) => color ?? BrandColorV2.decorativeGray};
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  margin: 0;
`

export default HorizontalLine
