import { Label } from '@app/components/Labels'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelDirectoriesTree, LabelSliced } from '@app/components-legacy/Label'
import LabelDirectoryTag from '@app/components-legacy/Label/LabelDirectoryTag'
import type EntityAttackPathNode from '@app/entities/EntityAttackPathNode'
import { useStores } from '@app/hooks'
import { isDefined } from '@libs/isDefined'
import * as React from 'react'

export interface IDrawerNodeRelationshipNameDirectoryProps {
  className?: string
  style?: React.CSSProperties
  entityAttackPathNode: EntityAttackPathNode
  maxWidth: number
}

const DrawerNodeRelationshipNameDirectory: React.FC<
  IDrawerNodeRelationshipNameDirectoryProps
> = props => {
  const { storeInfrastructures } = useStores()

  const availableWidth = props.maxWidth - 10 // margin between the two elements

  const nameWidth = availableWidth * 0.7
  const directoryNameWidth = availableWidth * 0.3

  const directory =
    props.entityAttackPathNode.directoryId &&
    storeInfrastructures.getDirectoryFromId(
      props.entityAttackPathNode.directoryId
    )

  return (
    <ContainerFlex
      className={props.className}
      style={props.style}
      name="DrawerNodeRelationshipNameDirectory"
      justifyContent="space-between"
      items={[
        <Label>
          <LabelSliced
            value={props.entityAttackPathNode.name || ''}
            maxWidth={nameWidth}
          />
        </Label>,

        directory && isDefined(directory.id) && directory.name && (
          <LabelDirectoryTag
            color={directory.color}
            label={directory.name}
            tooltipLabel={
              <LabelDirectoriesTree
                storeInfrastructures={storeInfrastructures}
                directoryIds={[directory.id]}
              />
            }
            maxWidth={directoryNameWidth}
          />
        )
      ]}
    />
  )
}

export default DrawerNodeRelationshipNameDirectory
