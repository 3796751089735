import IconEdit from '@app/components/Icon/IconEdit'
import IconTrash from '@app/components/Icon/IconTrash'
import { ContainerFlex, ContainerIcon } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form/Wrappers'
import ButtonSimple from '@app/components-legacy/Form/Wrappers/ButtonSimple'
import { ButtonVariant } from '@app/components-legacy/Form/Wrappers/types'
import {
  IconDeleteOutlined,
  IconEditOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { TagExpression } from '@app/components-legacy/Tag'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import type { Expression } from '@libs/Expression'
import * as React from 'react'
import styled from 'styled-components'
import TagBookmarkName from './TagBookmarkName'

const StyledTagContainer = styled.div`
  overflow: hidden;
`

interface ITrailFlowSearchEntryProps {
  className?: string
  name?: string | null
  expressionObject: Expression
  onClick: () => void
  onEdit?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onDelete?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  labelledBy?: string
}

const TrailFlowSearchEntry: React.FC<ITrailFlowSearchEntryProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  if (!props.expressionObject) {
    return null
  }

  return (
    <ContainerFlex
      name="TrailFlowSearchEntry"
      alignItems="center"
      className={props.className}
      itemsFlexGrow={[1]}
      labelledBy={props.labelledBy}
      items={[
        !props.name ? (
          <StyledTagContainer>
            <TagExpression
              id="expression"
              expression={props.expressionObject}
              onClick={props.onClick}
            />
          </StyledTagContainer>
        ) : (
          <TagBookmarkName
            name={props.name}
            onClick={props.onClick}
            expressionAsString={props.expressionObject.expressionAsString}
          />
        ),

        props.onEdit && (
          <div>
            {themeIsLegacy ? (
              <ButtonSimple
                labelledBy="editTrailFlowEntry"
                onClick={
                  props.onEdit as (e: Event | React.SyntheticEvent) => void
                }
              >
                <ContainerIcon
                  labelledBy="editTrailFlowEntry"
                  iconComponent={IconEditOutlined}
                />
              </ButtonSimple>
            ) : (
              <FormWrapperButton
                labelledBy="editTrailFlowEntry"
                variant={ButtonVariant.transparent}
                icon={IconEdit}
                buttonProps={{
                  onClick: props.onEdit
                }}
              />
            )}
          </div>
        ),

        props.onDelete && (
          <div>
            {themeIsLegacy ? (
              <ButtonSimple
                labelledBy="deleteTrailFlowEntry"
                onClick={
                  props.onDelete as (e: Event | React.SyntheticEvent) => void
                }
              >
                <ContainerIcon
                  labelledBy="deleteTrailFlowEntry"
                  iconComponent={IconDeleteOutlined}
                />
              </ButtonSimple>
            ) : (
              <FormWrapperButton
                labelledBy="deleteTrailFlowEntry"
                variant={ButtonVariant.transparent}
                icon={IconTrash}
                buttonProps={{
                  onClick: props.onDelete
                }}
              />
            )}
          </div>
        )
      ]}
      spaced
      spaceWidth="small"
      fullWidth
    />
  )
}

export default styled(TrailFlowSearchEntry)`
  background-color: ${props =>
    props.theme.tokens['trailFlowSearchEntry/backgroundColor/default']};
  padding: 2px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: ${props =>
      props.theme.tokens['trailFlowSearchEntry/backgroundColor/hover']};
  }

  &:hover:before {
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;

    box-shadow: ${props =>
      props.theme.tokens['trailFlowSearchEntry/boxShadow/hover']};
    opacity: 0;
    transition: ${consts.transitionOpacity};
  }
`
