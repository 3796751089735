import type { PropertiesNullable } from '@@types/helpers'
import { createEntities, EntityTopologyDirectory } from '@app/entities'
import { ensureArray } from '@libs/ensureArray'
import type {
  Maybe,
  TopologyDirectory,
  TopologyInfrastructure
} from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityTopologyInfrastructure
  extends EntityBase
  implements PropertiesNullable<TopologyInfrastructure>
{
  uid: Maybe<string> = null
  name: Maybe<string> = null
  directories: Maybe<TopologyDirectory[]> = null
  known: Maybe<boolean> = null

  // computed radius according to the number of directories
  radius: Maybe<number> = null

  constructor(data: Partial<TopologyInfrastructure>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return TopologyDirectory entities.
   */
  getDirectories(): EntityTopologyDirectory[] {
    return createEntities<TopologyDirectory, EntityTopologyDirectory>(
      EntityTopologyDirectory,
      // add the infrastructure uid in directory entity
      ensureArray(this.directories).map(directory => {
        return {
          ...directory,
          uidInfrastructure: this.uid
        }
      })
    )
  }
}
