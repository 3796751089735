import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'

interface IIconHealthCheckProps extends IIconProps {
  statusColor: string
}

const IconHealthCheck: React.FC<IIconHealthCheckProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 -1 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.375 10.625H11.875L10.625 8.125L9.375 11.875L6.875 5.625L5.625 10.625C5.625 10.625 4.1993 10.625 3.10606 10.625C2.35667 9.41071 1.875 8.21932 1.875 7.1875C1.875 4.59866 3.83375 2.5 6.25 2.5C7.84336 2.5 9.23777 3.41261 10.0027 4.77659C10.7629 3.41217 12.157 2.5 13.75 2.5C16.1662 2.5 18.125 4.59866 18.125 7.1875C18.125 10.625 12.7827 15.8293 10.0021 17.7485C8.63535 16.8074 6.64917 15.0709 4.98246 13.1401"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="17" cy="4" r="3" fill={props.statusColor} />
    </svg>
  )
}

export default IconHealthCheck
