import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import Navigation from '@app/components-legacy/Navigation'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { MenuEntryType } from '@app/stores/helpers/StoreMenu/types'
import { filterFalsies } from '@libs/filterFalsies'
import * as React from 'react'
import {
  canAccessToPreferences,
  canEditCredentials,
  canConfigureApiKey
} from '../permissions'

enum PreferencesNavigationKeys {
  Internationalization = 'Internationalization',
  Profiles = 'Profiles',
  Credentials = 'Credentials',
  API = 'API'
}

interface IPreferencesNavigationProps {}

const PreferencesNavigation: React.FC<IPreferencesNavigationProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['Preferences.PersonalSettings.Navigation']
  })

  const { storeRbac, storeAbout } = useStores()

  const storeMenu = useStoreMenuInit<IMenuEntry<PreferencesNavigationKeys>>({
    menuEntries: filterFalsies([
      storeRbac.isUserGrantedTo(canAccessToPreferences) && {
        key: PreferencesNavigationKeys.Internationalization,
        label: translate('Internationalization'),
        labelledBy: 'Internationalization',
        routeDefinition: {
          routeName: AppRouteName.Preferences_Internationalization,
          parameters: {}
        },
        type: MenuEntryType.link
      },
      storeRbac.isUserGrantedTo(canAccessToPreferences) && {
        key: PreferencesNavigationKeys.Profiles,
        label: translate('Profiles'),
        labelledBy: 'Profiles',
        routeDefinition: {
          routeName: AppRouteName.Preferences_Profiles,
          parameters: {}
        },
        type: MenuEntryType.link
      },
      storeRbac.isUserGrantedTo(canEditCredentials(storeAbout)) && {
        key: PreferencesNavigationKeys.Credentials,
        label: translate('Credentials'),
        labelledBy: 'Credentials',
        routeDefinition: {
          routeName: AppRouteName.Preferences_Security,
          parameters: {}
        },
        type: MenuEntryType.link
      },
      storeRbac.isUserGrantedTo(canConfigureApiKey) && {
        key: PreferencesNavigationKeys.API,
        label: translate('API key'),
        labelledBy: 'API key',
        routeDefinition: {
          routeName: AppRouteName.Preferences_ApiKey,
          parameters: {}
        },
        type: MenuEntryType.link
      }
    ])
  })

  return <Navigation storeMenu={storeMenu} />
}

export default PreferencesNavigation
