import { ContainerContent } from '@app/components-legacy/Container'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { EmailFormFieldName } from './types'

export interface IEmailCommonFormMainFieldsProps {}

const EmailCommonFormMainFields: React.FC<
  IEmailCommonFormMainFieldsProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Titles', 'Management.System.Configuration.EmailAlerts']
  })

  const { storeManagementEmails } = useStores()

  return (
    <ContainerContent title={translate('Main information')}>
      <WidgetForm<EmailFormFieldName>
        translate={translate}
        storeForm={storeManagementEmails.storeForm}
        displayedFields={[
          EmailFormFieldName.address,
          EmailFormFieldName.description
        ]}
        fieldsParams={{
          [EmailFormFieldName.address]: {
            inputType: InputType.input
          },
          [EmailFormFieldName.description]: {
            inputType: InputType.input
          }
        }}
      />
    </ContainerContent>
  )
}

export default observer(EmailCommonFormMainFields)
