import { AppRouteName } from '@app/routes'
import type { StoreReports } from '@app/stores'
import type { IReportInputParameters } from '@app/stores/StoreReports/types'
import { ReportsFieldName } from '@app/stores/StoreReports/types'
import type { ISubmitFormParameters } from '@libs/submitForm'
import { submitForm } from '@libs/submitForm'
import type * as React from 'react'

/**
 * Query Kapteyn server reports middleware to download the report from the backend.
 */
export function handleDownloadOnSubmit(storeReports: StoreReports) {
  return (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault()

    const { storeRoot } = storeReports
    const { storeMessages } = storeRoot.stores
    const { appRouter, appTranslator } = storeRoot

    const translate = appTranslator.bindOptions({
      namespaces: ['Reports.Download']
    })

    const pathname = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareReportingCenterReports,
      parameters: {}
    })

    const uuid = storeReports.storeForm.field(ReportsFieldName.uuid).asString
    const accesstoken = storeReports.storeForm.field(
      ReportsFieldName.accesstoken
    ).asString

    // just stop, the button is not clickable if no accesstoken is set
    if (!accesstoken) {
      return
    }

    // check report metadata first to know if the report exists
    storeReports
      .fetchReportMetadata({ uuid, accesstoken })
      .then(metadata => {
        if (!metadata) {
          throw new Error()
        }

        const reportName = `${[
          'report',
          metadata.type,
          metadata.startDate,
          metadata.endDate
        ].join('-')}.csv`

        const reportsParameters: IReportInputParameters = {
          uuid,
          accesstoken,
          reportName
        }

        const parameters: ISubmitFormParameters<IReportInputParameters> = {
          parameters: reportsParameters,
          language: storeRoot.appTranslator.language,
          'csrf-token': storeRoot.environment.sessionParams.csrfToken
        }

        // submit a form to the middleware to start the download
        submitForm(pathname, parameters, 'POST')
      })
      .catch(() => {
        storeMessages.error(translate('Report not found'), {
          labelledBy: 'error'
        })
      })
  }
}
