import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'

/**
 * When submitting the drawer, replace selection of checkers by the ones of the
 * drawer.
 */
export const onDrawerInputCheckersValidation =
  (
    storeInputCheckers: StoreInputGenericCheckers<any>,
    storePendingInputCheckers: StoreInputGenericCheckers<any>
  ) =>
  (onValidate?: () => void) =>
  () => {
    storeInputCheckers.replaceSelectedCheckers(
      storePendingInputCheckers.selectedCheckers
    )

    // custom another validation function passed in the footer
    if (onValidate) {
      onValidate()
    }
  }
