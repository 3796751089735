import { AppMenu } from '@app/components-legacy/Menu'
import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { HistoryMenuKey } from '@app/stores/TrailFlow/StoreSearchHistory'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export interface IHistoryDrawerProps {}

const HistoryDrawerMenu: React.FC<IHistoryDrawerProps> = () => {
  const { storeTrailFlow } = useStores()

  const translate = useAppTranslator({
    namespaces: ['TrailFlow.SearchHistory']
  })

  const storeMenu = useStoreMenuInit<IMenuEntry<HistoryMenuKey>>({
    storeMenu: storeTrailFlow.storeMenuHistory,
    menuEntries: [
      {
        key: HistoryMenuKey.History,
        label: translate('History'),
        labelledBy: 'History',
        onClick: () =>
          storeTrailFlow.storeSearchHistory.fetchSearchHistoryEntries()
      },
      {
        key: HistoryMenuKey.Bookmarks,
        label: translate('Bookmarks'),
        labelledBy: 'Bookmarks',
        onClick: () =>
          storeTrailFlow.storeSearchBookmarks.fetchSearchBookmarksEntries()
      }
    ],
    defaultSelectedMenuKey: HistoryMenuKey.History
  })

  return <AppMenu storeMenu={storeMenu} />
}

export default observer(HistoryDrawerMenu)
