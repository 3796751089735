import LabelTenants from '@app/components/Labels/LabelTenants'
import { ContainerFlex } from '@app/components-legacy/Container'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { consts } from '@app/styles'
import { isDefined } from '@libs/isDefined'
import { omit } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import LabelCheckerGauge from './LabelCheckerGauge'
import type { ILabelCheckerIdentityProps } from './types'

interface ILabelCheckerFooterProps<GC extends TGenericChecker>
  extends ILabelCheckerIdentityProps<GC> {
  isOvered: boolean
}

function LabelCheckerFooter<GC extends TGenericChecker>(
  props: ILabelCheckerFooterProps<GC>
): React.ReactElement {
  const showTenants = isDefined(props.tenantIds)
  const showGauge = isDefined(props.complexityValue)
  const hasTenants = props.tenantIds && props.tenantIds.length > 0

  const renderTenants = () => {
    if (!props.tenantIds) {
      return null
    }

    return <LabelTenants tenantIds={props.tenantIds} />
  }

  return (
    <ContainerFlex
      name="LabelCheckerFooter"
      className={props.className}
      itemsFlexGrow={[1, 0]}
      alignItems="center"
      justifyContent={hasTenants ? 'flex-start' : 'flex-end'}
      items={[
        showTenants && renderTenants(),
        showGauge && <LabelCheckerGauge {...omit(props, 'className')} />
      ]}
      wrapItems
    />
  )
}

export default styled(LabelCheckerFooter)`
  transition: ${consts.transitionOpacity};
  padding: ${consts.paddingVeryVerySmall} ${consts.paddingSmall};

  /* Allow to show the Tooltip over the &:hover::after element */
  z-index: 2;

  background-color: ${props =>
    props.isOvered ? consts.colorSelected02 : consts.colorWhiteAlt};
`
