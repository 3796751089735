import type { Maybe, ReactComponent } from '@@types/helpers'
import { BulletStatusKO, BulletStatusOK } from '@app/components-legacy/Bullet'
import { ContainerFlex, ContainerIcon } from '@app/components-legacy/Container'
import {
  IconClockCircleFilled,
  IconSyncOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { useAppTranslator } from '@app/hooks'
import { consts } from '@app/styles'
import type { TranslateFn } from '@libs/i18n'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { CrawlingStatus } from '@server/graphql/typeDefs/types'
import * as React from 'react'

interface IDirectoryIconCrawlingStatus {
  source: 'LDAP' | 'SYSVOL' | 'Sensitive data collection'
  status: CrawlingStatus
}

const DirectoryIconCrawlingStatus: React.FC<
  IDirectoryIconCrawlingStatus
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.System.Directories']
  })

  const color =
    props.status === CrawlingStatus.None ? consts.colorBlueGray015 : undefined

  const labelledBy = ['directory-sync', props.source, props.status]
    .map(x => x.toLowerCase())
    .join('-')

  const tooltipProps = getCrawlingStatusTooltip(
    props.status,
    props.source,
    translate
  )

  return (
    <ContainerFlex
      name={labelledBy}
      direction="column"
      alignItems="center"
      items={[
        <ContainerIcon
          labelledBy={labelledBy}
          iconComponent={getIcon(props.status)}
          iconProps={{
            style: { color },
            spin: props.status === CrawlingStatus.Ongoing
          }}
          tooltipProps={tooltipProps}
        />
      ]}
      wrapItems
    />
  )
}

export default DirectoryIconCrawlingStatus

function getIcon(status: CrawlingStatus): ReactComponent {
  switch (status) {
    case CrawlingStatus.None:
      return IconClockCircleFilled

    case CrawlingStatus.Ongoing:
      return IconSyncOutlined

    case CrawlingStatus.Success:
      return BulletStatusOK

    case CrawlingStatus.Failure:
      return BulletStatusKO

    default:
      assertUnreachableCase(status)
  }
}

function getCrawlingStatusTooltip(
  status: IDirectoryIconCrawlingStatus['status'],
  source: IDirectoryIconCrawlingStatus['source'],
  translate: TranslateFn
) {
  const honeyAccountTooltip = getCrawlingStatusLocale(status)

  if (!honeyAccountTooltip) {
    return
  }

  return {
    title: translate(honeyAccountTooltip, { interpolations: { source } })
  }
}

function getCrawlingStatusLocale(status: CrawlingStatus): Maybe<string> {
  switch (status) {
    case CrawlingStatus.Success:
      return 'Source found'

    case CrawlingStatus.Failure:
      return 'Source not found'

    default:
      return null
  }
}
