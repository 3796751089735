import { ButtonCopyToClipboard } from '@app/components-legacy/Button'
import Code from '@app/components-legacy/Code'
import { ContainerContent } from '@app/components-legacy/Container'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

interface IHoneyAccountScriptProps {
  value: string
  isDisabled: boolean
}

const StyledCode = styled(Code)`
  code {
    white-space: break-spaces !important;
  }
`

const HoneyAccountScript: React.FC<IHoneyAccountScriptProps> = props => {
  return (
    <ContainerContent name="HoneyAccountScript" labelledBy="HoneyAccountScript">
      <StyledCode>{props.value}</StyledCode>

      <ButtonCopyToClipboard
        textToCopy={props.value}
        buttonProps={{ disabled: props.isDisabled }}
      />
    </ContainerContent>
  )
}

export default observer(HoneyAccountScript)
