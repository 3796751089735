import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { useBladeRouteContext } from '@app/components-legacy/Blade/hooks'
import FormWrapperButtonSimple from '@app/components-legacy/Form/Wrappers/ButtonSimple'
import {
  onHeaderCrumbClick,
  onHeaderCrumbCloseIconClick,
  onHeaderCrumbExportIconClick
} from '@app/components-legacy/HeadBar/handlers'
import { LabelNonBreakable } from '@app/components-legacy/Label'
import type { EntityBlade } from '@app/entities'
import { useAppRouter, useAppTranslator, useTestAttribute } from '@app/hooks'
import { useStores } from '@app/hooks/useStores'
import { consts, helpers } from '@app/styles'
import { CTAColorV2 } from '@app/styles/consts'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { ensureArray } from '@libs/ensureArray'
import type { IRouteDefinition } from '@libs/Router/types'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled, { css } from 'styled-components'
import IconCloseSmall from '../../components/Icon/IconCloseSmall'
import { ContainerFlex, ContainerIcon } from '../Container'
import { IconCloseOutlined, IconExportOutlined } from '../Icon/IconAntd'

export interface IHeaderBreadcrumbCrumbProps {
  className?: string
  selected: boolean
  blade: EntityBlade
}

const HeaderBreadcrumbCrumb: React.FC<IHeaderBreadcrumbCrumbProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.HeaderBreadcrumbCrumb']
  })

  const { storeRoot, storeBlades } = useStores()

  const routeContext = useBladeRouteContext(props.blade)

  const appRouter = useAppRouter()

  const { pendoAttributeProps } = useTestAttribute('heading')

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const pendoAttribute = React.useMemo(() => {
    return { ...pendoAttributeProps()() }
  }, [props.blade.title])

  const queryStringParameters =
    appRouter.getCurrentRouteQueryStringParameters<
      IRouteDefinition<any>['queryStringParameters']
    >()

  const renderIcons = () => {
    const exportPathnames = ensureArray(
      queryStringParameters &&
        routeContext?.exportPathnames?.(queryStringParameters)
    )

    const isExportable = exportPathnames.length > 0

    return (
      <ContainerFlex
        name="HeaderBreadcrumbCrumbIcons"
        alignItems="center"
        justifyContent={themeIsLegacy ? 'center' : undefined}
        items={[
          isExportable && (
            <ContainerIcon
              labelledBy="exportBlade"
              iconComponent={IconExportOutlined}
              tooltipProps={{
                title: translate('Export'),
                mouseEnterDelay: 1
              }}
              iconProps={{
                onClick:
                  onHeaderCrumbExportIconClick(storeRoot)(exportPathnames)
              }}
            />
          ),

          props.blade.level > 0 &&
            (themeIsLegacy ? (
              <ContainerIcon
                labelledBy="closeBlade"
                iconComponent={IconCloseOutlined}
                tooltipProps={{
                  title: translate('Close'),
                  mouseEnterDelay: 1
                }}
                iconProps={{
                  onClick: onHeaderCrumbCloseIconClick(storeBlades)
                }}
              />
            ) : (
              <Tooltip title={translate('Close')} mouseEnterDelay={1}>
                <div>
                  <FormWrapperButtonSimple
                    labelledBy="closeBlade"
                    onClick={onHeaderCrumbCloseIconClick(storeBlades)}
                  >
                    <IconCloseSmall color={CTAColorV2.primary} size={14} />
                  </FormWrapperButtonSimple>
                </div>
              </Tooltip>
            ))
        ]}
        spaced
        spaceWidth="verySmall"
      />
    )
  }

  return (
    <ContainerFlex
      name="HeaderBreadcrumbCrumb"
      alignItems="center"
      justifyContent="space-between"
      onClick={onHeaderCrumbClick(storeBlades)(props.blade)}
      className={props.className}
      items={[
        <div {...pendoAttribute}>
          {themeIsLegacy ? (
            <LabelNonBreakable ellipsis>{props.blade.title}</LabelNonBreakable>
          ) : (
            <Label variant={LabelVariant.p}>
              <LabelNonBreakable ellipsis>
                {props.blade.title}
              </LabelNonBreakable>
            </Label>
          )}
        </div>,
        renderIcons()
      ]}
      spaced
    />
  )
}

const ObservedHeaderBreadcrumbCrumb = observer(HeaderBreadcrumbCrumb)

export default styled(ObservedHeaderBreadcrumbCrumb)`
  background-color: ${props =>
    props.selected
      ? props.blade.level > 0
        ? props.theme.tokens['headBar/color/bodyBackground0']
        : props.theme.tokens['headBar/color/bodyBackgroundN']
      : 'transparent'};
  box-shadow: ${({ theme }) => theme.tokens['headBar/boxShadow/bladeTab']};
  transition: ${consts.transitionOpacity};
  padding: 0 ${consts.paddingDefault};
  min-width: ${helpers.add(consts.bladesLeftOffset, 20)};
  cursor: pointer;

  ${({ theme }) =>
    !isThemeLegacy(theme) &&
    css`
      border-radius: ${consts.borderRadiusSmallRedesign}
        ${consts.borderRadiusSmallRedesign} 0 0;
      z-index: ${consts.zIndexHeaderBreadcrumbCrumb};
    `}
  &:hover {
    background-color: ${props =>
      props.blade.level > 0
        ? props.theme.tokens['headBar/color/bodyBackground0']
        : props.theme.tokens['headBar/color/bodyBackgroundN']};
  }
`
