import type { IIconProps } from '@app/components/Icon/types'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import type { IoEBoardMenuItemKey } from '../IoE/IoEBoardPage/types'

export enum MainMenuKey {
  generalTitle = 'generalTitle',
  dashboard = 'dashboard',

  identity360Title = 'identity360Title',
  activeDirectoryTitle = 'activeDirectoryTitle',
  entraIdTitle = 'entraIdTitle',

  securityTitle = 'securityTitle',
  trailflow = 'trailflow',
  ioe = 'ioe',
  ioa = 'ioa',
  ioeWhite = 'ioe-white',
  ioaWhite = 'ioa-white',
  topology = 'topology',
  attackPath = 'attackpath',
  attackPathAnalysis = 'attackpathAnalysis',

  managementTitle = 'managementTitle',
  accounts = 'accounts',
  system = 'system',
  healthCheck = 'healthCheck',
  identityExplorer = 'identityExplorer',
  identities = 'identities',
  weaknesses = 'weaknesses',
  securityEngine = 'securityEngine',
  activityLogs = 'activityLogs',

  settingsTitle = 'settingsTitle',
  user = 'user',
  preferences = 'preferences',
  logout = 'logout',
  persona = 'persona',

  whatsNew = 'whatsNew',

  debugTitle = 'debugTitle',
  apiInspector = 'apiInspector',

  systemConfiguration = 'systemConfiguration',
  forestManagement = 'forestManagement',
  domainManagement = 'domainManagement',
  licenseInfo = 'licenseInfo',
  userManagement = 'userManagement',
  roleManagement = 'roleManagement',
  profilesConfiguration = 'profilesConfiguration'
}

export interface IMainMenuEntry
  extends IMenuEntry<MainMenuKey | IoEBoardMenuItemKey> {
  // "hard redirect"
  href?: string
  label2?: string | React.FC
  icon?: React.FC<IIconProps>
  icon2?: React.FC<IIconProps>
  conditioningIcon2Component?: React.FC<{ children: React.ReactNode }>
}
