import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperCheckbox } from '@app/components-legacy/Form'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { consts } from '@app/styles'
import { omit } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import { LabelSliced } from '..'
import LabelCheckerDateResolution from './LabelCheckerDateResolution'
import type { ILabelCheckerIdentityProps } from './types'

interface ILabelCheckerTitleProps<GC extends TGenericChecker>
  extends ILabelCheckerIdentityProps<GC> {}

function LabelCheckerTitle<GC extends TGenericChecker>(
  props: ILabelCheckerTitleProps<GC>
): React.ReactElement {
  const isSmall = props.small === true

  const showCheckboxSelection = props.checkable
  const showDateResolution = !isSmall && props.resolvedAt

  return (
    <ContainerFlex
      name="LabelCheckerTitle"
      className={props.className}
      justifyContent="space-between"
      alignItems="center"
      items={[
        showCheckboxSelection && (
          <FormWrapperCheckbox
            labelledBy={`checker/${props.codename || props.name}`}
            checkboxProps={{
              checked: props.selected,
              indeterminate: props.indeterminate
            }}
          />
        ),

        <ContainerFlex
          name="LabelCheckerTitleTexts"
          className="checkerTitleTexts"
          justifyContent="space-between"
          items={[
            <div className="checkerLabel">
              <LabelSliced
                maxLength={props.maxLength || 150}
                breakString
                value={props.name}
              />
            </div>,

            showDateResolution && (
              <LabelCheckerDateResolution {...omit(props, 'className')} />
            )
          ]}
        />
      ]}
    />
  )
}

export default styled(LabelCheckerTitle)`
  padding: ${props => (props.checkable ? `0 ${consts.paddingDefault}` : 0)};

  .checkerTitleTexts {
    text-align: left;
    width: 100%;

    .checkerLabel {
      font-weight: ${consts.fontWeightBold};
      padding: ${consts.paddingSmall};
    }
  }
`
