import { AssetImg } from '@app/components/Asset/AssetImg'
import * as React from 'react'
import { ContainerFlex } from '../Container'

interface IStepPointsProps {
  nbPoints: number
  selectedPoint: number
}

const StepPoints = (props: IStepPointsProps) => {
  const dots: JSX.Element[] = []
  for (let i = 1; i <= props.nbPoints; i++) {
    if (props.selectedPoint === i) {
      dots.push(
        <AssetImg imageName="dot-dark" fileType="svg" labelledBy="dot-dark" />
      )
    } else {
      dots.push(
        <AssetImg
          imageName="dot-light"
          fileType="svg"
          labelledBy={`dot-light-${i}`}
        />
      )
    }
  }
  return (
    <ContainerFlex
      name="step-points"
      alignItems="center"
      spaced
      justifyContent="center"
      spaceWidth="verySmall"
      items={dots}
    />
  )
}

export default StepPoints
