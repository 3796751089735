import type { MaybeUndef } from '@@types/helpers'
import { createEntities, createEntity, EntityEvent } from '@app/entities'
import {
  AdObjectCommonAttribute,
  AdObjectCommonObjectClass
} from '@app/entities/EntityAdObject/types'
import type { EventId } from '@app/entities/EntityEvent'
import StoreDatePicker from '@app/stores/helpers/StoreDatePicker'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreBase from '@app/stores/StoreBase'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { Expression } from '@libs/Expression'
import { isDefined, isDefinedAndNotEmptyString } from '@libs/isDefined'
import type { QueryEventById, QueryEvents } from '@server/graphql/queries/event'
import { queryEventById, queryEvents } from '@server/graphql/queries/event'
import type {
  Event,
  EventByIdQueryArgs,
  EventsQueryArgs,
  Maybe
} from '@server/graphql/typeDefs/types'
import type {
  IWSEventMessage,
  IWSRegistration
} from '@server/routers/WSProxyRouter/types'
import { WSEntityName } from '@server/routers/WSProxyRouter/types'
import { action, computed, makeObservable, observable } from 'mobx'
import * as moment from 'moment'
import type { StoreRoot } from '..'
import type { IStoreOptions } from '../types'
import type { IFetchEventsOptions } from './types'
import { EventsTableStatus } from './types'

export const NB_EVENTS_PER_PAGE = 50
export const NB_PAGES = 3

export enum DateRange {
  firstMonth = 'firstMonth',
  secondMonth = 'secondMonth',
  thirdMonth = 'thirdMonth'
}

export default class StoreEvents extends StoreBase {
  public storeFlagsFetchNewerEvents = new StoreFlags(this.storeRoot)
  public storeFlagsFetchOlderEvents = new StoreFlags(this.storeRoot)

  public storeDatePicker = new StoreDatePicker(this.storeRoot, {
    // set a default range of one week
    defaultDateStart: moment().subtract(1, 'week'),
    defaultDateEnd: moment()
  })

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Errors', 'TrailFlow.Table']
  })

  private _eventWSRegistration:
    | IWSRegistration<IWSEventMessage['extendedPayload']>
    | undefined

  // list of events
  private _events: EntityEvent[] = []

  /* Observables */

  // toggle between 0 and 1
  private $eventsActivity = observable.box<number>(0)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch events newer than the first one of the map.
   * Used when scrolling to the top.
   */
  fetchNewerEvents(fetchOptions: IFetchEventsOptions = {}): Promise<void> {
    this.storeFlagsFetchNewerEvents.loading()

    const finalFetchOptions = this._getFetchEventsOptions({
      appendDirection: 'newer',
      clearEvents: false,
      ...fetchOptions
    })

    return this._fetchEventsToDirection(this._events[0], finalFetchOptions)
      .then(entityEvents => {
        this.storeFlagsFetchNewerEvents.success()
        return entityEvents
      })
      .then(entityEvents => {
        // if there are no newer events, push a message
        if (Array.isArray(entityEvents) && !entityEvents.length) {
          this.storeRoot.stores.storeMessages.info(
            this.translate('There is no newer event'),
            {
              labelledBy: 'noNewerEvent'
            }
          )
        }
      })
  }

  /**
   * Fetch events older than the latest one of the map.
   * Used when scrolling to the bottom.
   */
  fetchOlderEvents(fetchOptions: IFetchEventsOptions = {}): Promise<void> {
    this.storeFlagsFetchOlderEvents.loading()

    const finalFetchOptions = this._getFetchEventsOptions({
      appendDirection: 'older',
      clearEvents: false,
      ...fetchOptions
    })

    return this._fetchEventsToDirection(
      this._events[this._events.length - 1],
      finalFetchOptions
    ).then(() => {
      const wasLive = this.storeRoot.stores.storeTrailFlow.isLive

      // disable the live mode when navigating to the past
      this.unregisterEventWS()

      // notify the user that the live mode has been disabled
      if (wasLive) {
        this.storeRoot.stores.storeMessages.info(
          this.translate('The Trail Flow has paused'),
          {
            labelledBy: 'trailflowPaused'
          }
        )
      }

      this.storeFlagsFetchOlderEvents.success()
    })
  }

  /**
   * Fetch events.
   */
  fetchEvents(
    _fetchOptions: IFetchEventsOptions = {}
  ): Promise<EntityEvent[] | void> {
    const { storeTrailFlow, storeInfrastructures } = this.storeRoot.stores

    const selectedDirectoryIds = storeInfrastructures.getSelectedDirectoryIds()

    // if no directory selected (or not yet added), return an empty list
    // (since the API requires at least on directory)
    if (!selectedDirectoryIds.length) {
      return Promise.resolve([])
    }

    const fetchOptions = this._getFetchEventsOptions(_fetchOptions)

    const expression = this._mergeExpressions(
      fetchOptions.expression,
      storeTrailFlow.storeInputExpression.expression
    )

    const dates = this.storeDatePicker.datePickerRangeValueAsMoments

    if (!dates || !dates.dateStart || !dates.dateEnd) {
      return Promise.resolve()
    }

    const { dateStart, dateEnd } = dates

    const args: EventsQueryArgs = {
      profileId: this.storeRoot.stores.storeAuthentication.currentProfileId,
      directoryIds: selectedDirectoryIds,
      eventsDirection:
        fetchOptions.appendDirection === 'newer' ? 'asc' : 'desc',
      dateStart: dateStart.toISOString(),
      dateEnd: dateEnd.toISOString(),
      eventsPerPage: fetchOptions.nbEventsPerPage || NB_EVENTS_PER_PAGE,
      eventsExpression: JSON.stringify(expression.expressionAsObject)
    }

    return this.storeRoot
      .getGQLRequestor()
      .query<QueryEvents>(queryEvents, args)
      .then(data => data.events)
      .then(events => {
        if (!events) {
          throw new Error('Events are not defined')
        }

        const entities = createEntities<Event, EntityEvent>(EntityEvent, events)

        this._setEvents(entities, fetchOptions)

        return entities
      })
      .catch(handleStoreError(this.storeRoot, null))
  }

  /**
   * Fetch only one event.
   * It doesn't update observables or flags, it just returns a promise with the data.
   */
  retrieveOneEvent(
    directoryId: number,
    eventId: EventId
  ): Promise<Event | void> {
    return Promise.resolve()
      .then(() => {
        const args: EventByIdQueryArgs = {
          directoryId,
          eventId
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<QueryEventById>(queryEventById, args)
      })
      .then(data => data.eventById)
      .then(event => {
        if (!event) {
          throw new Error('Event has not been found')
        }
        return event
      })
      .catch(handleStoreError(this.storeRoot, null))
  }

  /**
   * Add an even from a payload received via websocket.
   * New events are added to the map in the natural order, thus displayed first.
   */
  addEvent(payload: IWSEventMessage['extendedPayload']): void {
    if (!payload) {
      return
    }

    const objectClassAttribute = payload.adObject.objectAttributes.find(
      attr => attr.name === AdObjectCommonAttribute.objectclass
    )
    if (
      objectClassAttribute &&
      (objectClassAttribute.value ===
        AdObjectCommonObjectClass.passwordHashScan ||
        objectClassAttribute.value ===
          AdObjectCommonObjectClass.passwordHashReuse)
    ) {
      return
    }

    const eventEntity = createEntity<Event, EntityEvent>(EntityEvent, payload)

    this._setEvents([eventEntity], {
      appendDirection: 'newer',
      clearEvents: false
    })
  }

  /**
   * Return events.
   */
  get events(): EntityEvent[] {
    return this._events
  }

  /* Private */

  /**
   * Merge two expressions (the user's one and the optional pagination one).
   *
   * Unfortunately, the `expression.merge()` method fails currently to
   * parse and merge two expressions if the first one is used with
   * an eventId + operator. The value of the eventId is considered as a number
   * and the operator is lost.
   * It's a lot of (re)work to make it work since a lot of assumptions in the
   * parser is done on number like values, whereas eventId is handled as strings
   * to handle ~bigint values.
   *
   * By joining manually the expressions (with quotes around the eventId), it works.
   *
   * Example of a merged expression:
   *
   * "expression": {
   *   "AND": [
   *     {
   *       "eventId": [
   *         "<",
   *         "1081"
   *       ]
   *     },
   *     {
   *       "distinguishedName": "CN=Configuration,DC=acme,DC=corp"
   *     }
   *   ]
   * },
   */
  private _mergeExpressions(
    expression1: MaybeUndef<Expression>,
    expression2: MaybeUndef<Expression>
  ): Expression {
    const finalExpressionString = [expression1, expression2]
      .filter(isDefined)
      .map(exp => exp.expressionAsString)
      .filter(isDefinedAndNotEmptyString)
      .join(' AND ')

    return new Expression().fromString(finalExpressionString)
  }

  /**
   * Fetch news or older events relative to the passed event.
   */
  private _fetchEventsToDirection(
    fromEvent: Maybe<EntityEvent>,
    fetchOptions: IFetchEventsOptions = {}
  ): Promise<EntityEvent[] | void> {
    const finalFetchOptions = this._getFetchEventsOptions(fetchOptions)

    if (!fromEvent) {
      return this.fetchEvents(finalFetchOptions)
    }

    const symbol = finalFetchOptions.appendDirection === 'newer' ? '>' : '<'

    finalFetchOptions.expression = new Expression().fromString(
      `eventId: ${symbol}"${fromEvent.id}"`
    )

    return this.fetchEvents(finalFetchOptions)
  }

  /**
   * Return default options merged with the ones passed as parameters.
   */
  private _getFetchEventsOptions(
    fetchOptions: IFetchEventsOptions = {}
  ): IFetchEventsOptions {
    return {
      expression: new Expression(),
      appendDirection: 'older',
      clearEvents: true,
      nbEventsPerPage: NB_EVENTS_PER_PAGE,
      nbPages: NB_PAGES,
      ...fetchOptions
    }
  }

  /**
   * Set events.
   * @private
   */
  _setEvents(
    events: EntityEvent[],
    fetchOptions: IFetchEventsOptions = {}
  ): this {
    if (fetchOptions.clearEvents) {
      this.reset()
    }

    const eventIds = this.eventIds

    // Keep events not already in the array of events
    const newEvents = events.filter((event: EntityEvent) => {
      return event.id ? eventIds.indexOf(event.id) === -1 : true
    })

    const nbEventsPerPage = fetchOptions.nbEventsPerPage || NB_EVENTS_PER_PAGE
    const nbEventsMax = this.storeRoot.stores.storeTrailFlow.isLive
      ? // live mode: only one page of events
        nbEventsPerPage
      : // search mode: NB_PAGES pages
        nbEventsPerPage * (fetchOptions.nbPages || NB_PAGES)

    const removedEventEntities = this._pushOrUnshiftEvents(
      fetchOptions,
      nbEventsMax,
      newEvents
    )

    // purge deviances of removed events
    this.storeRoot.stores.storeTrailFlow.storeDeviances.purgeDeviances(
      removedEventEntities
    )

    return this.updateEventsActivity()
  }

  /**
   * Push or unshift events into the events array and splice the array to
   * nbEventsMax items.
   *
   * Return the removed events.
   */
  private _pushOrUnshiftEvents(
    fetchOptions: IFetchEventsOptions,
    nbEventsMax: number,
    events: EntityEvent[]
  ): EntityEvent[] {
    // if direction => newer, add events at the beginning of the array of events
    // and limit the number of events to nbEventPerPage.
    if (fetchOptions.appendDirection === 'newer') {
      this._events.unshift(...events)

      return this._events.splice(Math.min(this._events.length, nbEventsMax))
    }

    // if direction => older, add events at the end of the array of events
    // and limit the number of events to nbEventPerPage
    this._events.push(...events)

    return this._events.splice(
      0,
      Math.max(0, this._events.length - nbEventsMax)
    )
  }

  /* Actions */

  /**
   * Reset events.
   */
  @action
  reset(): this {
    this._events = []
    return this
  }

  /**
   * Toggle the observable to trigger a refresh since events array is not observed
   * for performances reasons.
   */
  @action
  updateEventsActivity(): this {
    this.$eventsActivity.set(this.$eventsActivity.get() ^ 1)
    return this
  }

  /**
   * Subscribe to events.
   */
  @action
  registerEventWS() {
    const { storeInfrastructures, storeAuthentication } = this.storeRoot.stores

    // don't register several times
    if (this._eventWSRegistration) {
      return
    }

    const { storeInputExpression, storeEvents } =
      this.storeRoot.stores.storeTrailFlow

    this.storeRoot.stores.storeTrailFlow.setTableStatus(
      EventsTableStatus.isLive
    )

    // don't register the events channel when filetering on deviant changes only
    // (events are added after deviances reception)
    if (this.storeRoot.stores.storeTrailFlow.isDeviantFilterEnabled) {
      return
    }

    const selectedDirectoryIds = storeInfrastructures.getSelectedDirectoryIds()
    const currentProfile = storeAuthentication.currentProfile
    const profileId = currentProfile && currentProfile.id

    if (!profileId) {
      return
    }

    this._eventWSRegistration = this.storeRoot.wsClient.addRegistration<
      IWSEventMessage['extendedPayload']
    >(
      'Event',
      {
        name: WSEntityName.event,
        payload: {
          profileId,
          directories: selectedDirectoryIds,
          expression: storeInputExpression.expression.expressionAsObject
        }
      },
      storeEvents.addEvent.bind(this)
    )
  }

  /**
   * Unsubscribe events.
   */
  @action
  unregisterEventWS() {
    this.storeRoot.stores.storeTrailFlow.unsetTableStatus(
      EventsTableStatus.isLive
    )

    if (this._eventWSRegistration) {
      this.storeRoot.wsClient.removeRegistration(this._eventWSRegistration)
      this._eventWSRegistration = undefined
    }
  }

  /* Computed */

  @computed
  get eventsActivity(): number {
    return this.$eventsActivity.get()
  }

  /**
   * Return IDs of events.
   */
  @computed
  get eventIds(): EventId[] {
    return this._events.map(e => e.id).filter(isDefined)
  }
}
