import { ContainerHTMLContent } from '@app/components/Container'
import { IconCheckCircle } from '@app/components/Icon'
import { WrapperAlert } from '@app/components/Wrapper'
import { AlertSize, AlertVariant } from '@app/components/Wrapper/types'
import { useAppTranslator } from '@app/hooks'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface IAlertIoaManualConfigurationProps {}

const AlertSuccess = styled(WrapperAlert)`
  width: 100%;
  margin-bottom: 20px;

  .ant-alert {
    text-align: left;
  }

  strong {
    font-weight: ${consts.fontWeightBold};
  }
`

const AlertIoaManualConfiguration: React.FC<
  IAlertIoaManualConfigurationProps
> = () => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.IoA']
  })

  return (
    <AlertSuccess
      name="AlertIoaManualConfiguration"
      labelledBy="alertIoaManualConfiguration"
      alertProps={{
        closable: false,
        showIcon: true,
        icon: <IconCheckCircle />,
        message: (
          <ContainerHTMLContent>
            {translate(
              'Configuration saved! Follow the procedure below to configure your domains',
              {
                transformMarkdown: true
              }
            )}
          </ContainerHTMLContent>
        )
      }}
      size={AlertSize.large}
      variant={AlertVariant.success}
    />
  )
}

export default AlertIoaManualConfiguration
