import type { Maybe } from '@@types/helpers'
import type { AppRouteName } from '@app/routes'
import { appRoutes } from '@app/routes'
import { isDefined } from '@libs/isDefined'
import type { AppRouterClient } from '@libs/Router/types'

/**
 * Return true if the activity logs is disable on `routeName`.
 */
export function isActivityLogsAvailableOnRoute(appRouter: AppRouterClient) {
  return (routeName: Maybe<AppRouteName>) => {
    return (
      isDefined(routeName) &&
      !isDefined(appRouter.getRouteSpecs(routeName).abstract)
    )
  }
}

/**
 * List of all routes except abstract one.
 */
export const indexedRoutesForActivityLogs = Object.values(appRoutes)
  .filter(route => !isDefined(route.abstract))
  .reduce<Map<string, string>>((acc, route) => {
    acc.set(route.pathname, route.routeName)
    return acc
  }, new Map()) // Map<pathname, routeName>

/**
 * Find parent pathname from child one.
 */
const getParentPathName = (pathname: string): string => {
  const split = pathname.split('/')
  // Remove last one
  split.pop()

  return split.join('/')
}

/**
 * Recursive method to find every parents route name.
 */
export const getActivityLogsParentsFromPathname = (
  pathname: string
): string[] => {
  const routes: string[] = []

  const parentPathname = getParentPathName(pathname)

  if (parentPathname.length > 0) {
    // Iterate through all parents
    routes.push(...getActivityLogsParentsFromPathname(parentPathname))

    // Add current parent pathname
    const parentRoute = indexedRoutesForActivityLogs.get(parentPathname)
    if (parentRoute) {
      routes.push(parentRoute)
    }
  }

  // Return all routes
  return routes
}
