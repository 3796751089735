import { ContainerContent, ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { FormWrapperSwitch } from '@app/components-legacy/Form/Wrappers'
import type { EntityCloudStatistics } from '@app/entities'
import { useAppTranslator, useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import ConfigurationPendoCard from './ConfigurationPendoCard'
import { updateCloudStatisticsIsActive } from './handler'

export interface IConfigurationPendoProps {
  className?: string
  cloudStatistics: EntityCloudStatistics
}

export function ConfigurationPendo(props: IConfigurationPendoProps) {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.Pendo']
  })

  const { storeAuthentication, storeManagementApplicationSettings } =
    useStores()
  const cloudStatistics = props.cloudStatistics
  const isEnabled = cloudStatistics.isEnabled ?? false

  return (
    <ContainerContent name="ConfigurationPendoPage" className={props.className}>
      <ContainerFlex
        name="card"
        direction="column"
        flexGap="default"
        items={[
          <ContainerFlex
            name="form"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            items={[
              <Label variant={LabelVariant.h2}>
                {translate(`Enable Cloud statistics`)}
              </Label>,

              <FormWrapperSwitch
                labelledBy="pendo"
                switchProps={{
                  checked: isEnabled,
                  loading:
                    storeAuthentication.storeFlagsFetchCloudStatistics
                      .isLoading,
                  onChange: updateCloudStatisticsIsActive(
                    storeManagementApplicationSettings,
                    storeAuthentication
                  )
                }}
              />
            ]}
            spaced
            spaceWidth="small"
          />,

          <ConfigurationPendoCard />
        ]}
      />
    </ContainerContent>
  )
}

export default observer(ConfigurationPendo)
