import { Features } from '@alsid/common'
import { useAppRouter, useStores } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import type { IMainMenuEntry } from '@app/pages/MainMenu/types'
import { AppRouteName } from '@app/routes'
import { MenuEntryType } from '@app/stores/helpers/StoreMenu/types'
import {
  BackgroundColorV2,
  CTAColorV2,
  TenableBackgroundsColor
} from '@app/styles/consts'
import * as React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import WidgetMenuLinkContent from './WidgetMenuLinkContent'

const MenuItemContent = styled.div`
  padding: 18px 15px;
  border-left: 2px solid ${BackgroundColorV2.white};
`

const linkCss = css`
  display: flex;

  :focus,
  :hover {
    text-decoration: none;
    background-color: ${TenableBackgroundsColor.backgroundHover};

    ${MenuItemContent} {
      border-left-color: ${TenableBackgroundsColor.backgroundHover};
    }
  }

  :active,
  &.activated {
    background-color: ${TenableBackgroundsColor.backgroundSelectedAlt};

    ${MenuItemContent} {
      border-left-color: ${CTAColorV2.primary};
    }
  }
`

const StyledA = styled.a`
  ${linkCss}
`

const StyledLink = styled(Link)`
  ${linkCss}
`

interface IWidgetMenuLinkProps {
  className?: string
  hidePopover: () => void
  navigationEntry: IMainMenuEntry
}

const WidgetMenuLink: React.FC<IWidgetMenuLinkProps> = props => {
  const featureKapteynTulV6 = useFeatureFlag({
    featureFlagName: Features.KAPTEYN_TUL_V6
  })

  const { storeAlerts } = useStores()

  function handleMenuItemClick() {
    props.hidePopover()
    storeAlerts.storeDrawer.closeDrawer()
  }

  const appRouter = useAppRouter()

  const currentPage = appRouter.getCurrentRouteName()

  const { testAttributeProps } = useTestAttribute('menu')

  const content = (
    <MenuItemContent>
      <WidgetMenuLinkContent
        icon={props.navigationEntry.icon}
        label={props.navigationEntry.label}
      />
    </MenuItemContent>
  )

  if (props.navigationEntry.routeDefinition) {
    const path = appRouter.makeRouteInfosPathname(
      props.navigationEntry.routeDefinition
    )

    const linkProps = {
      className:
        props.navigationEntry.routeDefinition.routeName ===
          AppRouteName.Preferences &&
        (currentPage === AppRouteName.Preferences_ApiKey ||
          currentPage === AppRouteName.Preferences_Internationalization ||
          currentPage === AppRouteName.Preferences_Profiles ||
          currentPage === AppRouteName.Preferences_Security)
          ? 'activated'
          : props.navigationEntry.routeDefinition.routeName === currentPage
            ? 'activated'
            : '',
      onClick: handleMenuItemClick,
      ...testAttributeProps('menuitem')(props.navigationEntry.labelledBy)
    }

    if (featureKapteynTulV6) {
      return (
        <StyledLink to={path} {...linkProps}>
          {content}
        </StyledLink>
      )
    }

    return (
      <StyledA href={path} {...linkProps}>
        {content}
      </StyledA>
    )
  }

  if (props.navigationEntry.href) {
    return (
      <StyledA
        href={props.navigationEntry.href}
        {...(props.navigationEntry.type === MenuEntryType.externalLink && {
          target: '_blank',
          rel: 'noopener noreferrer'
        })}
        onClick={handleMenuItemClick}
        {...testAttributeProps('menuitem')(props.navigationEntry.labelledBy)}
      >
        {content}
      </StyledA>
    )
  }

  return null
}

export default WidgetMenuLink
