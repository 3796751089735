import type { EntityEvent } from '@app/entities'
import type { EventId } from '@app/entities/EntityEvent'
import { useStores } from '@app/hooks'
import * as React from 'react'
import { canSeeDeviances } from '../../permissions'
import CellClassFile from './Cells/CellClassFile'
import CellDate from './Cells/CellDate'
import CellDeviance from './Cells/CellDeviance'
import CellDirectory from './Cells/CellDirectory'
import CellDN from './Cells/CellDN'
import CellSource from './Cells/CellSource'
import CellType from './Cells/CellType'
import TableContentBodyRow from './TableContentBodyRow'

interface ITableContentBodyRowMemoProps {
  event: EntityEvent
  isLive: boolean
}

const TableContentBodyRowMemo: React.FC<
  ITableContentBodyRowMemoProps
> = props => {
  const { storeRbac } = useStores()

  /**
   * Memoize all the row for an eventId.
   * It avoids to compute styles and cells for previous events already rendered once.
   */
  const TableContentBodyRowMemoized = React.useMemo(() => {
    return (
      <TableContentBodyRow event={props.event} isLive={props.isLive}>
        {/* don't display the deviances icon column if not granted */}
        {storeRbac.isUserGrantedTo(canSeeDeviances) && (
          <CellDeviance eventId={props.event.getPropertyAsT<EventId>('id')} />
        )}
        <CellSource event={props.event} />
        <CellType event={props.event} />
        <CellClassFile event={props.event} />
        <CellDN event={props.event} />
        <CellDirectory event={props.event} />
        <CellDate event={props.event} isLive={props.isLive} />
      </TableContentBodyRow>
    )
  }, [
    // memoization by eventId
    props.event.id,
    // and invalid it when toggling the live mode (in order to recompute styles)
    props.isLive
  ])

  return <>{TableContentBodyRowMemoized}</>
}

export default TableContentBodyRowMemo
