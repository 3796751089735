import type { MaybeUndef } from '@@types/helpers'
import { consts } from '@app/styles'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled from 'styled-components'

/**
 * @deprecated
 */
export interface ILabelInputProps {
  className?: string
  style?: React.CSSProperties
  htmlFor?: string
  required?: MaybeUndef<boolean>
  highlight?: MaybeUndef<boolean>
  children?: React.ReactNode
}

/**
 * @deprecated
 */
const LabelInput: React.FC<ILabelInputProps> = props => {
  return (
    <label
      data-name="LabelInput"
      className={props.className}
      htmlFor={props.htmlFor}
      style={props.style}
    >
      {props.children}
    </label>
  )
}

export default styled(LabelInput)`
  width: 200px;
  margin-right: ${consts.marginDefault};

  &::first-letter {
    text-transform: uppercase;
  }

  ${props => {
    if (props.highlight !== true) {
      return ''
    }

    return `
      color: ${consts.colorBlue005};
      font-weight: ${consts.fontWeightBold};
    `
  }};

  ${props => {
    if (props.required !== true) {
      return ''
    }

    return `
      ::after {
        content: '*';
        color: red;
      }
    `
  }};

  ${props => {
    if (isThemeLegacy(props.theme)) {
      return ''
    }

    return `
      color: ${consts.FontColorV2.primary};
    `
  }};
`
