import { ContainerFlex } from '@app/components/Container'
import { ContainerRbac } from '@app/components-legacy/Container'
import { InputInfrastructures, InputSearch } from '@app/components-legacy/Input'
import InputTenants from '@app/components-legacy/Input/InputTenants'
import { useAppTranslator, useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleInfrastructureOnChange, handleTenantOnChange } from './handlers'
import IoEBoardMenuSwitch from './IoEBoardMenuSwitch'
import { canAccessToIdentityIoEDetails } from './permissions'

export interface IIoEBoardFiltersProps {}

function IoEBoardFilters(props: IIoEBoardFiltersProps) {
  const translate = useAppTranslator({ namespaces: ['IoE.Board'] })

  const { storeInfrastructures, storeIoE, storeManagementAzureAD } = useStores()

  if (
    storeIoE.isIdentityCheckersMenuEntrySelected &&
    !storeManagementAzureAD.isTenableCloudApiTokensWorking
  ) {
    return null
  }

  const showTenantsInput =
    storeIoE.isAllOrIdentityCheckersMenuEntrySelected &&
    storeManagementAzureAD.isTenableCloudApiTokensWorking

  const showInfrastructuresInput = storeIoE.isMenuSelectedToShowAdCheckers

  return (
    <ContainerFlex
      name="IoEBoardMenu"
      itemsFlexGrow={[1, 0, 0]}
      alignItems="center"
      flexGap="default"
      items={[
        <InputSearch
          placeholder={translate('Search for an indicator')}
          storeInputSearch={storeIoE.storeInputSearch}
        />,

        <IoEBoardMenuSwitch />,

        showTenantsInput && (
          <ContainerRbac rbacCapabilityCheck={canAccessToIdentityIoEDetails}>
            <InputTenants
              storeInputTenants={storeIoE.storeInputTenants}
              onSubmit={handleTenantOnChange(storeIoE)}
            />
          </ContainerRbac>
        ),

        showInfrastructuresInput && (
          <InputInfrastructures
            storeInfrastructures={storeInfrastructures}
            onValidate={handleInfrastructureOnChange(storeIoE)}
          />
        )
      ]}
    />
  )
}

export default observer(IoEBoardFilters)
