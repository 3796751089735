import type { IGraphQLQuery } from '@libs/graphQL/types'
import type { AddPageVisitedMutationArgs, Mutation } from '../typeDefs/types'

export type MutationAddPageVisited = IGraphQLQuery<
  AddPageVisitedMutationArgs,
  Pick<Mutation, 'addPageVisited'>
>

export const mutationAddPageVisited = `
  mutation mutationAddPageVisited($pageVisited: InputAddPageVisited!) {
    addPageVisited(
      pageVisited: $pageVisited
    )
  }
`

export type MutationDeleteAllUserTraces = IGraphQLQuery<
  AddPageVisitedMutationArgs,
  Pick<Mutation, 'deleteAllUserTraces'>
>

export const mutationDeleteAllUserTraces = `
  mutation mutationDeleteAllUserTraces {
    deleteAllUserTraces
  }
`
