import {
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { ButtonSize } from '@app/components-legacy/Form/Wrappers/types'
import { LabelAlt } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreInputTenants } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleTenantsDrawerOnSubmit } from '../handlers'
import TenantsSelectionCounter from './TenantsSelectionCounter'

interface IDrawerFooterProps {
  onSubmit?: () => void
  storeInputTenants: StoreInputTenants
  storePendingTenants: StoreInputTenants
}

const DrawerFooter: React.FC<IDrawerFooterProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Components.InputTenants']
  })

  const enableSubmit = props.storePendingTenants.isDrawerSelectionSubmitEnabled

  return (
    <ContainerFooter>
      <DrawerCancelButton storeDrawer={props.storeInputTenants.storeDrawer} />

      <ContainerFlex
        name="DrawerFooterRight"
        alignItems="center"
        items={[
          <LabelAlt variant={LabelAltVariant.Minor}>
            <TenantsSelectionCounter
              storeInputTenants={props.storePendingTenants}
            />
          </LabelAlt>,

          <FormWrapperButton
            labelledBy="submit"
            pendoSuffix="drawer"
            size={ButtonSize.large}
            buttonProps={{
              type: 'primary',
              disabled: !enableSubmit,
              onClick: handleTenantsDrawerOnSubmit(
                props.storePendingTenants,
                props.storeInputTenants
              )(props.onSubmit)
            }}
          >
            {translate('Submit selection')}
          </FormWrapperButton>
        ]}
        spaced
        spaceWidth="small"
      />
    </ContainerFooter>
  )
}

export default observer(DrawerFooter)
