import {
  ContainerContent,
  ContainerRbac
} from '@app/components-legacy/Container'
import { useAppTranslator } from '@app/hooks'
import * as React from 'react'
import {
  canReadLicense,
  canReadOrUpdateLicense,
  canUpdateLicense
} from './permissions'
import ProductLicenseDetails from './ProductLicenseDetails'
import ProductLicenseUpdate from './ProductLicenseUpdate'

interface IProductLicenseProps {}

const ProductLicense: React.FC<IProductLicenseProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.About.License']
  })

  return (
    <ContainerRbac rbacCapabilityCheck={canReadOrUpdateLicense}>
      <ContainerContent title={translate('License')}>
        <ContainerRbac rbacCapabilityCheck={canReadLicense}>
          <ProductLicenseDetails />
        </ContainerRbac>

        <ContainerRbac rbacCapabilityCheck={canUpdateLicense}>
          <ProductLicenseUpdate />
        </ContainerRbac>
      </ContainerContent>
    </ContainerRbac>
  )
}

export default ProductLicense
