import { getCriticityColor } from '@libs/criticity'
import type { Criticity } from '@server/graphql/typeDefs/types'
import * as React from 'react'

interface IFiltersTimelinePointAttacksPillProps {
  height: number
  criticity: Criticity
}

const FiltersTimelinePointAttacksPill: React.FC<
  IFiltersTimelinePointAttacksPillProps
> = props => {
  // use inline-style to boost performances since this component can be instantiated
  // hundred of times.
  const style: React.CSSProperties = {
    backgroundColor: getCriticityColor(props.criticity),
    height: `${props.height}px`,
    width: '7px',
    cursor: 'pointer'
  }

  return <div style={style} />
}

export default FiltersTimelinePointAttacksPill
