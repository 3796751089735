import {
  ContainerContent,
  ContainerFlex,
  ContainerFlexVertical
} from '@app/components-legacy/Container'
import { LabelSliced } from '@app/components-legacy/Label'
import {
  WidgetListCountItems,
  WidgetListSelectAllCheckbox,
  WidgetListTable
} from '@app/components-legacy/Widgets/WidgetList'
import WidgetListCountItemsWithFilter from '@app/components-legacy/Widgets/WidgetList/WidgetListCountItemsWithFilter'
import type { EntityRbacEntityItem } from '@app/entities'
import type { IDataRowRbacEntityItem } from '@app/entities/EntityRbacEntityItem'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import { isListConsideredAsEmpty } from '@app/stores/helpers/StoreWidgetList/utils'
import { isRbacPermissionInvalid, rbacCapability } from '@libs/rbac/functions'
import { RbacEntityItemType, RbacPermissionType } from '@libs/rbac/types'
import type { RbacEntityName } from '@server/graphql/typeDefs/types'
import { RbacAction } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import CheckboxByDefault from './CheckboxByDefault'
import CheckboxGrantCreation from './CheckboxGrantCreation'
import { onTagTogglePermissionClick, onToggleVisibilityClick } from './handlers'
import ListEntityItemsTitle from './ListEntityItemsTitle'
import ListEntityItemsTypeDataNoResult from './ListEntityItemsTypeDataNoResult'
import TagTogglePermission from './TagTogglePermission'

interface IListEntityItemsTypeDataProps {
  rbacEntityName: RbacEntityName
  disabled: boolean
  storeWidgetList: StoreWidgetList<EntityRbacEntityItem, IDataRowRbacEntityItem>
}

const ListEntityItemsTypeData: React.FC<
  IListEntityItemsTypeDataProps
> = props => {
  const isInvalid = React.useRef(isRbacPermissionInvalid())

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.Accounts.Roles.Permissions']
  })

  const { storeManagementRbacRoles } = useStores()
  const { storeInputSearchRbacEntityItems } = storeManagementRbacRoles

  const isListItemsVisible =
    storeManagementRbacRoles.rbacEntityItemsListVisibility.get(
      props.rbacEntityName
    ) || false

  return (
    <ContainerContent labelledBy={props.rbacEntityName}>
      <ContainerFlexVertical
        items={[
          <ContainerFlex
            name="ListEntityItemsHeaderLeft"
            items={[
              <ContainerFlex
                name="ListEntityItemsHeaderSelector"
                alignItems="center"
                items={[
                  !props.disabled && (
                    <WidgetListSelectAllCheckbox
                      storeWidgetList={props.storeWidgetList}
                      hideLabel
                    />
                  ),

                  <ListEntityItemsTitle
                    rbacEntityName={props.rbacEntityName}
                    isVisible={isListItemsVisible}
                    onVisibilityIconClick={onToggleVisibilityClick(
                      storeManagementRbacRoles
                    )(props.rbacEntityName)}
                  />
                ]}
                spaced
                spaceWidth="small"
              />,

              <WidgetListCountItems storeWidgetList={props.storeWidgetList} />
            ]}
            spaced
          />,

          storeInputSearchRbacEntityItems.hasSearchValue && (
            <ContainerFlex
              name="ListEntityItemsHeaderFilter"
              items={[
                <WidgetListCountItemsWithFilter
                  storeWidgetList={props.storeWidgetList}
                />
              ]}
              spaced
            />
          )
        ]}
        spaced
      />

      {isListItemsVisible && (
        <WidgetListTable<EntityRbacEntityItem, IDataRowRbacEntityItem>
          translate={translate}
          storeWidgetList={props.storeWidgetList}
          hiddenColumnsKeys={[
            'id',
            'rbacEntityItemType',
            'rbacReadAllAction',
            'rbacEditAllAction'
          ]}
          columnWidths={{
            rbacReadIdAction: 15,
            rbacReadAllAction: 15,
            rbacEditIdAction: 15,
            rbacEditAllAction: 15,
            rbacCreateAction: 15
          }}
          headsRenderFn={{
            rbacReadIdAction: title => {
              return (
                <ContainerFlex
                  name="ReadHead"
                  alignItems="center"
                  items={[
                    <div>{translate(title)}</div>,

                    !isInvalid.current(
                      props.rbacEntityName,
                      RbacAction.Read
                    ) && (
                      <CheckboxByDefault
                        rbacEntityName={props.rbacEntityName}
                        disabled={props.disabled}
                        rbacAction={RbacAction.Read}
                        checked={
                          rbacCapability(
                            storeManagementRbacRoles.pendingRbacPermissions
                          )(props.rbacEntityName, RbacAction.Read, null, {
                            strict: true
                          }).isGranted
                        }
                      />
                    )
                  ]}
                  spaced
                  spaceWidth="small"
                />
              )
            },
            rbacEditIdAction: title => {
              return (
                <ContainerFlex
                  name="EditHead"
                  alignItems="center"
                  items={[
                    <div>{translate(title)}</div>,

                    !isInvalid.current(
                      props.rbacEntityName,
                      RbacAction.Edit
                    ) && (
                      <CheckboxByDefault
                        rbacEntityName={props.rbacEntityName}
                        disabled={props.disabled}
                        rbacAction={RbacAction.Edit}
                        checked={
                          rbacCapability(
                            storeManagementRbacRoles.pendingRbacPermissions
                          )(props.rbacEntityName, RbacAction.Edit, null, {
                            strict: true
                          }).isGranted
                        }
                      />
                    )
                  ]}
                  spaced
                  spaceWidth="small"
                />
              )
            },
            rbacCreateAction: title => {
              if (isInvalid.current(props.rbacEntityName, RbacAction.Create)) {
                return null
              }

              return (
                <ContainerFlex
                  name="CheckboxGrantCreation"
                  justifyContent="flex-end"
                  items={[
                    <CheckboxGrantCreation
                      rbacEntityName={props.rbacEntityName}
                      disabled={props.disabled}
                      rbacAction={RbacAction.Create}
                      checked={
                        rbacCapability(
                          storeManagementRbacRoles.pendingRbacPermissions
                        )(props.rbacEntityName, RbacAction.Create, null, {
                          strict: true
                        }).isGranted
                      }
                    />
                  ]}
                />
              )
            }
          }}
          cellsRenderFn={{
            id: id => {
              return <div>foo</div>
            },

            name: (name, item) => {
              if (isListConsideredAsEmpty(item.id)) {
                return <div>{translate('No data')}</div>
              }

              return <LabelSliced maxLength={100} value={name} />
            },

            rbacReadIdAction: (canBeChanged, entityItemRow) => {
              if (props.storeWidgetList.isEmpty) {
                return null
              }

              if (canBeChanged === false) {
                return (
                  <TagTogglePermission
                    type={RbacPermissionType.grant}
                    rbacEntityType={RbacEntityItemType.data}
                    rbacEntityName={entityItemRow.rbacEntityName}
                    disabled={props.disabled}
                    rbacAction={RbacAction.Read}
                    forAllEntities
                    builtIn
                  />
                )
              }

              const { isGranted } = rbacCapability(
                storeManagementRbacRoles.pendingRbacPermissions
              )(entityItemRow.rbacEntityName, RbacAction.Read, entityItemRow.id)

              const isForAllEntities =
                storeManagementRbacRoles.hasAllEntitiesPendingRbacPermission(
                  props.rbacEntityName,
                  RbacAction.Read
                )

              return (
                <TagTogglePermission
                  type={
                    isGranted
                      ? RbacPermissionType.grant
                      : RbacPermissionType.unauthorize
                  }
                  rbacEntityType={RbacEntityItemType.data}
                  rbacEntityName={entityItemRow.rbacEntityName}
                  disabled={props.disabled}
                  rbacAction={RbacAction.Read}
                  rbacEntityId={entityItemRow.id}
                  forAllEntities={isForAllEntities}
                  onClick={
                    !props.disabled
                      ? onTagTogglePermissionClick(storeManagementRbacRoles)(
                          entityItemRow.rbacEntityName,
                          RbacAction.Read,
                          entityItemRow.id
                        )(
                          isGranted
                            ? RbacPermissionType.unauthorize
                            : RbacPermissionType.grant
                        )
                      : null
                  }
                />
              )
            },

            rbacEditIdAction: (canBeChanged, entityItemRow) => {
              if (props.storeWidgetList.isEmpty) {
                return null
              }

              if (canBeChanged === false) {
                return (
                  <TagTogglePermission
                    type={RbacPermissionType.grant}
                    rbacEntityType={RbacEntityItemType.data}
                    rbacEntityName={entityItemRow.rbacEntityName}
                    disabled={props.disabled}
                    rbacAction={RbacAction.Edit}
                    forAllEntities
                    builtIn
                  />
                )
              }

              const { isGranted } = rbacCapability(
                storeManagementRbacRoles.pendingRbacPermissions
              )(entityItemRow.rbacEntityName, RbacAction.Edit, entityItemRow.id)

              const isForAllEntities =
                storeManagementRbacRoles.hasAllEntitiesPendingRbacPermission(
                  props.rbacEntityName,
                  RbacAction.Edit
                )

              return (
                <TagTogglePermission
                  type={
                    isGranted
                      ? RbacPermissionType.grant
                      : RbacPermissionType.unauthorize
                  }
                  rbacEntityType={RbacEntityItemType.data}
                  rbacEntityName={entityItemRow.rbacEntityName}
                  disabled={props.disabled}
                  rbacAction={RbacAction.Edit}
                  rbacEntityId={entityItemRow.id}
                  forAllEntities={isForAllEntities}
                  onClick={
                    !props.disabled
                      ? onTagTogglePermissionClick(storeManagementRbacRoles)(
                          entityItemRow.rbacEntityName,
                          RbacAction.Edit,
                          entityItemRow.id
                        )(
                          isGranted
                            ? RbacPermissionType.unauthorize
                            : RbacPermissionType.grant
                        )
                      : null
                  }
                />
              )
            },

            rbacCreateAction: (canBeChanged, entityItemRow) => {
              if (props.storeWidgetList.isEmpty) {
                return null
              }

              if (canBeChanged === false) {
                return (
                  <TagTogglePermission
                    type={RbacPermissionType.grant}
                    rbacEntityType={RbacEntityItemType.data}
                    rbacEntityName={entityItemRow.rbacEntityName}
                    disabled={props.disabled}
                    rbacAction={RbacAction.Create}
                    forAllEntities
                    builtIn
                  />
                )
              }

              const { isGranted } = rbacCapability(
                storeManagementRbacRoles.pendingRbacPermissions
              )(entityItemRow.rbacEntityName, RbacAction.Create)

              const isForAllEntities =
                storeManagementRbacRoles.hasAllEntitiesPendingRbacPermission(
                  props.rbacEntityName,
                  RbacAction.Create
                )

              return (
                <TagTogglePermission
                  type={
                    isGranted
                      ? RbacPermissionType.grant
                      : RbacPermissionType.unauthorize
                  }
                  rbacEntityType={RbacEntityItemType.data}
                  rbacEntityName={props.rbacEntityName}
                  disabled={props.disabled}
                  rbacAction={RbacAction.Create}
                  onClick={
                    !props.disabled
                      ? onTagTogglePermissionClick(storeManagementRbacRoles)(
                          props.rbacEntityName,
                          RbacAction.Create
                        )(
                          isGranted
                            ? RbacPermissionType.unauthorize
                            : RbacPermissionType.grant
                        )
                      : null
                  }
                  forAllEntities={isForAllEntities}
                />
              )
            }
          }}
          noResultsComponent={
            <ListEntityItemsTypeDataNoResult
              rbacEntityName={props.rbacEntityName}
              disabled={props.disabled}
              storeManagementRbacRoles={storeManagementRbacRoles}
            />
          }
        />
      )}
    </ContainerContent>
  )
}

export default observer(ListEntityItemsTypeData)
