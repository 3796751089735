import type { MaybeUndef } from '@@types/helpers'

/**
 * Combine two event handlers by calling them one after the other.
 */
export function combineEventHandlers<T>(
  func1?: (e: T) => void,
  func2?: (e: T) => void
): MaybeUndef<(e: T) => void> {
  if (func1 && func2) {
    return (e: T) => {
      func1(e)
      func2(e)
    }
  }
  return func1 ?? func2
}
