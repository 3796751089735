import { ContainerContent } from '@app/components-legacy/Container'
import { LabelDocuments } from '@app/components-legacy/Label'
import type EntityAttack from '@app/entities/EntityAttack'
import { useAppTranslator } from '@app/hooks'
import { ensureArray } from '@libs/ensureArray'
import * as React from 'react'
import styled from 'styled-components'

interface IIoAAttacksDescriptionTabResourcesProps {
  attackEntity: EntityAttack
}

const ResourcesColumn = styled.div`
  flex: 50%;
`

const IoAAttacksDescriptionTabResources: React.FC<
  IIoAAttacksDescriptionTabResourcesProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['IoA.Attacks']
  })

  const renderResources = () => {
    const resources = ensureArray(props.attackEntity.attackTypeResources)

    const hasDocuments = resources.length > 0

    if (!hasDocuments) {
      return translate('No resource available')
    }

    return resources.map(resource => (
      <LabelDocuments
        key={resource.name}
        name={resource.name}
        url={resource.url}
        type={resource.type}
      />
    ))
  }

  return (
    <ResourcesColumn>
      <ContainerContent title={translate('Additional resources')}>
        <div>{renderResources()}</div>
      </ContainerContent>
    </ResourcesColumn>
  )
}

export default IoAAttacksDescriptionTabResources
