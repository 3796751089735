import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import type { IManagementSystemConfigurationSyslogsEditRouteDefinition } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToSyslogAlertsConfiguration } from '../permissions'
import DrawerDeleteSyslog from './DrawerDeleteSyslog'
import { onSyslogListLoad } from './handlers'
import { canEditSyslogAlert, canListSyslogAlerts } from './permissions'
import ConfigurationSyslogsCreatePage from './SyslogsCreatePage'
import ConfigurationSyslogsEditPage from './SyslogsEditPage'
import SyslogsList from './SyslogsList'

interface IConfigurationSyslogsPageProps {}

const ConfigurationSyslogsPage: React.FC<
  IConfigurationSyslogsPageProps
> = () => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.System.Configuration.Navigation']
  })

  const { storeManagementSyslogs } = useStores()

  return (
    <>
      <ContainerContent
        title={translate('SYSLOG alerts')}
        onLoad={onSyslogListLoad(storeManagementSyslogs)}
        flags={storeManagementSyslogs.storeFlags.flags}
        rbacCapabilityCheck={canAccessToSyslogAlertsConfiguration}
      >
        <SyslogsList />

        <Portal name={PlaceHolderName.genericDrawer}>
          <DrawerDeleteSyslog />
        </Portal>
      </ContainerContent>

      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName:
                AppRouteName.Management_System_Configuration_Syslogs_Create,
              parameters: {}
            },
            component: ConfigurationSyslogsCreatePage,
            rbacPermissionsCheck: () => canListSyslogAlerts
          },
          {
            routeDefinition: {
              routeName:
                AppRouteName.Management_System_Configuration_Syslogs_Edit,
              parameters: {
                syslogId: Number()
              }
            },
            component: ConfigurationSyslogsEditPage,
            rbacPermissionsCheck: (
              parameters: IManagementSystemConfigurationSyslogsEditRouteDefinition['parameters']
            ) => canEditSyslogAlert(parameters.syslogId)
          }
        ]}
      />
    </>
  )
}

export default observer(ConfigurationSyslogsPage)
