import { canAccessToAccounts } from '@app/pages/Management/AccountsPage/permissions'
import type { StoreRbac } from '@app/stores'
import {
  rbacCapabilityCheckAllOf,
  rbacCapabilityCheckAnyOf
} from '@libs/rbac/functions'
import {
  createUser,
  editUser,
  readUIAccountsUsers,
  readUsers
} from '@libs/rbac/permissions'

export const canAccessToAccountUsers = (storeRbac: StoreRbac) =>
  rbacCapabilityCheckAllOf(
    canAccessToAccounts,
    readUIAccountsUsers(),
    rbacCapabilityCheckAnyOf(storeRbac.editUserHimself(), readUsers())
  )

export const canCreateAccountUsers = (storeRbac: StoreRbac) =>
  rbacCapabilityCheckAllOf(canAccessToAccountUsers(storeRbac), createUser())

export const canEditAccountUsers = (storeRbac: StoreRbac) => (userId: number) =>
  rbacCapabilityCheckAllOf(canAccessToAccountUsers(storeRbac), editUser(userId))
