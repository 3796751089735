import { safeStringify } from '@libs/json/safeStringify'
import type { Logger } from '@productive-codebases/toolbox'
import { isObject } from 'lodash'
import type {
  IIframeCommunicationBusConfiguration,
  IIframeMessageUnion
} from './types'

/**
 * Define a communication bus between the app and the iframe.
 * It works in both "directions", just pass the relevant window object to target
 * the app or the iframe.
 * When receiving an event, if valid, the matched handler function will be executed.
 */
export class IframeCommunicationBus {
  private readonly _listener: (this: Window, ev: MessageEvent<any>) => any

  constructor(
    private _configuration: IIframeCommunicationBusConfiguration,
    private _logger: Logger
  ) {
    _logger('debug')(
      'Instantiate new IframeCommunicationBus: %o',
      this._configuration
    )

    // Store the bound listener function as bind is returning a new function at each call
    this._listener = this._handlePostMessage.bind(this)

    this._bindPostMessage()
  }

  /**
   * Send a message from the iframe to the app.
   */
  postMessage(message: IIframeMessageUnion) {
    // When being into the iframe: Use parent's window to send the postMessage to the parent app,
    // When being into the app: window.parent === window, so it works too.
    this._configuration.windowObject.parent.postMessage(
      message,
      this._configuration.targetOrigin
    )
  }

  /**
   * Unbind the internal handler from the "message" event.
   */
  unbind() {
    this._logger('debug')('Unbind "message" event')

    this._configuration.windowObject.removeEventListener(
      'message',
      this._listener
    )
  }

  /**
   * Private
   */

  /**
   * Bind the "message" event to the internal handler.
   */
  private _bindPostMessage(): this {
    this._logger('debug')('Bind "message" event')

    this._configuration.windowObject.addEventListener('message', this._listener)

    return this
  }

  /**
   * Handle the posted message from the iframe.
   */
  private _handlePostMessage(event: MessageEvent): this {
    const iframeMessage = event.data

    if (/react-devtools/.test(iframeMessage.source)) {
      return this
    }

    if (/mobx-devtools/.test(iframeMessage.source)) {
      return this
    }

    if (!this._isValidIframeMessage(iframeMessage)) {
      this._logger('error')(
        `Skipping unhandled iframe message: ${
          iframeMessage ? safeStringify(iframeMessage) : ''
        }`
      )
      return this
    }

    const eventSource = {
      /** typed postMessage function bounded to the eventSource's postMessage */
      postMessage: (message: IIframeMessageUnion) => {
        // FIXME
        event.source?.postMessage(message, { targetOrigin: '*' })
      }
    }

    // run the handler matching the message action
    const handler = this._configuration.handlers[iframeMessage.action]

    if (handler) {
      handler(
        eventSource,
        // FIXME
        iframeMessage as any
      )
    }

    return this
  }

  /**
   * Validate the iframe postMessage.
   */
  private _isValidIframeMessage(message: any): message is IIframeMessageUnion {
    if (!isObject(message)) {
      return false
    }

    if (!('action' in message)) {
      return false
    }

    return true
  }
}
