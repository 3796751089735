import type { MaybeUndef } from '@@types/helpers'
import { IconCalendar, IconRightArrowThin } from '@app/components/Icon'
import { useAppTranslator, useTestAttribute } from '@app/hooks'
import type StoreDatePicker from '@app/stores/helpers/StoreDatePicker'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { isDefined } from '@libs/isDefined'
import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'
import { DatePicker } from 'antd'
import type {
  PickerPanelBaseProps,
  PickerPanelTimeProps
} from 'antd/lib/calendar/generateCalendar'
import type { RangePickerProps } from 'antd/lib/date-picker'
import type { RangePickerDateProps } from 'antd/lib/date-picker/generatePicker'
import { observer } from 'mobx-react-lite'
import type { Moment } from 'moment'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { onInputDatePickerRangeChange } from './handlers'

interface IInputDatePickerRangeProps {
  className?: string
  picker?:
    | PickerPanelBaseProps<any>['picker']
    | PickerPanelTimeProps<any>['picker']
  /** Default is true */
  allowClear?: boolean
  allowEmpty?: [boolean, boolean]
  format?: string
  storeDatePicker: StoreDatePicker
  onChange?: () => void
  labelledBy?: string
  unitOfTime?: moment.unitOfTime.StartOf
  reducedFontSize?: boolean
  rangePickerProps?: RangePickerProps
}

/**
 * Wrap a DatePicker.RangePicker component to be used with a StoreDatePicker store.
 */
const InputDatePickerRange: React.FC<IInputDatePickerRangeProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.DatePicker']
  })

  const { testAttributeProps } = useTestAttribute('form')

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  // save previous dates selection
  const dateRangeRef = React.useRef(
    props.storeDatePicker.datePickerRangeValueAsIsoStrings
  )

  const allowClear = isDefined(props.allowClear) ? props.allowClear : true

  /**
   * Call the `onChange` handler only when closing the panel and if
   * dates selection have changed.
   */
  const onOpenChange = (open: boolean) => {
    if (!props.onChange) {
      return
    }

    // do anything when opening the DatePicker
    if (open) {
      return
    }

    const newDates = props.storeDatePicker.datePickerRangeValueAsIsoStrings

    const isNewDateSelection =
      newDates?.dateStart !== dateRangeRef.current?.dateStart ||
      newDates?.dateEnd !== dateRangeRef.current?.dateEnd

    // when closing, if the value is different, call the handler
    if (isNewDateSelection) {
      dateRangeRef.current = newDates

      if (props.onChange) {
        props.onChange()
      }
    }
  }

  const renderDatesWithAttrTests = (current: Moment) => {
    // add a data-test for start date and end date
    let attrTest = null
    if (
      isDefined(dateRangeRef) &&
      isDefined(props.storeDatePicker.datePickerRangeValue)
    ) {
      const [dateStart, dateEnd] = props.storeDatePicker.datePickerRangeValue
      if (current.isSame(dateStart, 'day')) {
        attrTest = 'form/InputDatePickerRange/dateStart'
      }
      if (current.isSame(dateEnd, 'day')) {
        attrTest = 'form/InputDatePickerRange/dateEnd'
      }
    }
    return (
      <div
        className="ant-picker-cell-inner"
        {...filterEmptyProps({ ['data-test']: attrTest })}
      >
        {current.date()}
      </div>
    )
  }

  return (
    <div
      data-name="InputDatePickerRange"
      {...testAttributeProps('time')(props.labelledBy || 'datepicker')}
      className={props.className}
    >
      <DatePicker.RangePicker
        onChange={onInputDatePickerRangeChange(
          props.storeDatePicker,
          props.unitOfTime
        )(props.onChange)}
        onOpenChange={onOpenChange}
        value={props.storeDatePicker.datePickerRangeValue}
        picker={props.picker}
        format={props.format}
        allowClear={allowClear}
        allowEmpty={props.allowEmpty}
        placeholder={[translate('Start date'), translate('End date')]}
        dateRender={renderDatesWithAttrTests}
        {...(!themeIsLegacy && {
          separator: <IconRightArrowThin />,
          suffixIcon: <IconCalendar />
        })}
        {...props.rangePickerProps}
      />
    </div>
  )
}

const ObservedInputDatePickerRange = observer(InputDatePickerRange)

export default styled(ObservedInputDatePickerRange)`
  min-width: ${props =>
    (props.rangePickerProps as MaybeUndef<RangePickerDateProps<Moment>>)
      ?.showTime
      ? '298'
      : '230'}px;

  .ant-picker-input > input {
    font-size: ${props =>
      props.reducedFontSize
        ? props.theme.tokens['inputDatePickerRange/fontSize/reduced']
        : props.theme.tokens['inputDatePickerRange/fontSize/default']};
  }

  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      .ant-picker {
        background-color: ${props.theme.tokens[
          'input/backgroundColor/default'
        ]};
        border: none;
      }

      .ant-picker-focused {
        box-shadow: none;
      }

      .ant-picker-range .ant-picker-active-bar {
        display: none;
      }

      .ant-picker-range-separator {
        display: flex; // Center the arrow separator
      }

      .ant-picker-input > input {
        color: ${props.theme.tokens['input/color/text']};
      }

      .ant-picker-input > input::placeholder {
        color: ${props.theme.tokens['input/color/placeholder']};
      }

      .ant-picker-clear {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
      }
    `}
`
