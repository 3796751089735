import { consts } from '@app/styles'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerContent, ContainerFlex } from '../Container'
import { extractContainerLoaderProps } from '../Container/ContainerContent/helpers'
import { SpinnerInline } from '../Spinner'
import type { IComponentLoaderProps } from '../types'
import { DrawerContext, useDrawerContext } from './context'

export interface IDrawerHorizontalContainerProps
  extends Omit<IComponentLoaderProps, 'rbacCapabilityCheck'> {
  className?: string
  variant?: 'normal' | 'large' | 'small'
  children?: React.ReactNode
}

const DrawerVerticalContainer: React.FC<
  IDrawerHorizontalContainerProps
> = props => {
  const contextValue = useDrawerContext(DrawerContext)

  if (!contextValue) {
    return null
  }

  const getContent = () => {
    // Keep an empty div for the drawer animation
    if (!contextValue.storeDrawer.isDrawerOpened) {
      return [<div></div>]
    }

    const containerLoaderProps = extractContainerLoaderProps(props)

    return (
      <ContainerContent spinner={<SpinnerInline />} {...containerLoaderProps}>
        {props.children}
      </ContainerContent>
    )
  }

  // use inline style because styled is not reactive on contextValue.storeDrawer changes
  const style: React.CSSProperties = {
    height: contextValue.storeDrawer.isDrawerOpened
      ? `${getDrawerHeight(props.variant)}`
      : 0
  }

  return (
    <ContainerFlex
      name="DrawerVerticalContainer"
      className={props.className}
      style={style}
      itemsFlexGrow={[1]}
      direction="column"
      items={getContent()}
    />
  )
}

function getDrawerHeight(
  variant?: IDrawerHorizontalContainerProps['variant']
): string {
  if (!variant) {
    return '500px'
  }

  switch (variant) {
    case 'large':
      return '850px'

    case 'normal':
      return '500px'

    case 'small':
      return '380px'

    default:
      assertUnreachableCase(variant)
  }
}

const ObservedDrawerHorizontalContainer = observer(DrawerVerticalContainer)

export default styled(ObservedDrawerHorizontalContainer)`
  position: absolute;
  top: 0;
  z-index: ${consts.zIndexDrawerVerticalContainer};

  background-color: white;
  box-shadow: ${props =>
    props.theme.tokens['drawerVerticalContainer/boxShadow/default']};

  width: 100%;
  height: ${props => getDrawerHeight(props.variant)};

  transition: height 0.3s ${consts.smoothAnimation};
`
