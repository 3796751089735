import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperCheckbox } from '@app/components-legacy/Form'
import { InputSearch } from '@app/components-legacy/Input'
import {
  handleExpandAllInfrastructuresOnClick,
  handleInfrastructureOrDomainOnSearch,
  handleSelectAllDirectoriesOnClick
} from '@app/components-legacy/Input/InputInfrastructures/handlers'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreInfrastructures } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IDrawerHeaderProps {
  className?: string
  storeInfrastructures: StoreInfrastructures
}

const DrawerHeader: React.FC<IDrawerHeaderProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.InputInfrastructures']
  })

  return (
    <ContainerFlex
      name="DrawerHeader"
      direction="column"
      items={[
        <InputSearch
          placeholder={translate('Search a forest or domain')}
          onChange={handleInfrastructureOrDomainOnSearch(
            props.storeInfrastructures
          )}
          storeInputSearch={props.storeInfrastructures.storeInputSearch}
        />,

        <ContainerFlex
          name="InfrastructureFilters"
          className={props.className}
          items={[
            <FormWrapperCheckbox
              labelledBy="expandAll"
              checkboxProps={{
                name: 'LabelExpandChecker',
                onChange: handleExpandAllInfrastructuresOnClick(
                  props.storeInfrastructures
                ),
                indeterminate:
                  props.storeInfrastructures.isSomeInfrastructuresExpanded,
                checked: props.storeInfrastructures.isAllInfrastructuresExpanded
              }}
            >
              {translate('Expand all')}
            </FormWrapperCheckbox>,

            <FormWrapperCheckbox
              name="LabelDirectoriesChecker"
              labelledBy="selectDomains"
              checkboxProps={{
                onChange: handleSelectAllDirectoriesOnClick(
                  props.storeInfrastructures
                ),
                indeterminate: props.storeInfrastructures.isSomeDomainsSelected,
                checked: props.storeInfrastructures.isAllDomainsSelected
              }}
            >
              {props.storeInfrastructures.storeInputSearch.hasSearchValue
                ? translate('Select found domains')
                : translate('Select all domains')}
            </FormWrapperCheckbox>
          ]}
          spaced
        />
      ]}
      spaced
    />
  )
}

export default observer(DrawerHeader)
