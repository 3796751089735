import { ContainerFlex } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks/useStores'
import { notApplicableRbacEntityNames } from '@libs/rbac/consts'
import { RbacEntityItemType } from '@libs/rbac/types'
import type { RbacEntityName } from '@server/graphql/typeDefs/types'
import { Divider } from 'antd'
import * as React from 'react'
import ListEntityItemsTypeData from './ListEntityItems/ListEntityItemsTypeData'

export interface IRoleEditEntitiesListsDataProps {
  disabled: boolean
}

const RoleEditEntitiesListsData: React.FC<
  IRoleEditEntitiesListsDataProps
> = props => {
  const { storeManagementRbacRoles } = useStores()

  const getWidgetListItems = () => {
    const storeWidgetLists = Array.from(
      storeManagementRbacRoles.storesWidgetListEntityItems.entries()
    ).filter(([rbacEntityName]) => {
      return (
        rbacEntityName !== RbacEntityItemType.userPersonal &&
        rbacEntityName !== RbacEntityItemType.singleton &&
        rbacEntityName !== RbacEntityItemType.ui &&
        !notApplicableRbacEntityNames.includes(rbacEntityName as RbacEntityName)
      )
    })

    return storeWidgetLists.map(([rbacEntityName, storeWidgetList], i) => {
      return (
        <>
          <ListEntityItemsTypeData
            rbacEntityName={rbacEntityName as RbacEntityName}
            disabled={props.disabled}
            storeWidgetList={storeWidgetList}
          />

          {i < storeWidgetLists.length - 1 && <Divider />}
        </>
      )
    })
  }

  return (
    <ContainerFlex
      name="RoleEditEntitiesDataLists"
      direction="column"
      items={getWidgetListItems()}
    />
  )
}

export default RoleEditEntitiesListsData
