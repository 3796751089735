import { ContainerContent } from '@app/components/Container'
import { ContainerFlex } from '@app/components-legacy/Container'
import SpinnerInline, {
  SpinnerInlineSize
} from '@app/components-legacy/Spinner/SpinnerInline'
import type { EntityAttackPathEdge, EntityAttackPathNode } from '@app/entities'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import SimpleBar from 'simplebar-react'
import styled from 'styled-components'
import DrawerNodeRelationshipsNode from './DrawerNodeRelationshipsNode'

const StyledSimpleBar = styled(SimpleBar)`
  flex-grow: 1;
  padding: ${consts.paddingVerySmall} ${consts.paddingLarge}
    ${consts.paddingVerySmall} 0;
`

export interface IDrawerNodeRelationshipsNodesProps {
  className?: string
  style?: React.CSSProperties
  childrenNodesWithEdge: Array<{
    edge: EntityAttackPathEdge
    node: EntityAttackPathNode
  }>
  hasArrow: boolean
}

const DrawerNodeRelationshipsNodes: React.FC<
  IDrawerNodeRelationshipsNodesProps
> = props => {
  const { storeAttackPath } = useStores()

  const maxRelationTypes = props.childrenNodesWithEdge.reduce(
    (max, { edge }) => {
      const length = edge.relationTypes?.length ?? 0
      return length > max ? length : max
    },
    0
  )

  return (
    <ContainerFlex
      name="DrawerNodeRelationshipsNodes"
      className={props.className}
      style={props.style}
      items={[
        <StyledSimpleBar autoHide={false}>
          <ContainerContent
            flags={storeAttackPath.storeFlagsFetchDrawerNodes.flags}
            spinner={<SpinnerInline size={SpinnerInlineSize.small} />}
          >
            <ContainerFlex
              name="DrawerNodeRelationshipsNodesInScroll"
              direction="column"
              items={props.childrenNodesWithEdge.map(({ node, edge }) => (
                <DrawerNodeRelationshipsNode
                  node={node}
                  edge={edge}
                  maxRelationTypes={maxRelationTypes}
                  hasArrow={props.hasArrow}
                />
              ))}
              spaced
            />
          </ContainerContent>
        </StyledSimpleBar>
      ]}
    />
  )
}

const ObservedDrawerNodeRelationshipsNodes = observer(
  DrawerNodeRelationshipsNodes
)

export default styled(ObservedDrawerNodeRelationshipsNodes)`
  margin-top: ${consts.marginSmaller};
  overflow: hidden;
`
