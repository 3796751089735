import { ContextDSTheme } from '@design-system/contexts/ContextDSTheme'
import { dsThemes } from '@design-system/styles/themes'
import type { DSThemeName } from '@design-system/styles/themes/types'
import * as React from 'react'
import { ThemeProvider as ThemeProviderSC } from 'styled-components'

interface IDSThemeProviderProps {
  themeName: DSThemeName
  children: React.ReactNode
}

export function DSThemeProvider(props: IDSThemeProviderProps) {
  return (
    <ContextDSTheme.Provider value={{ themeName: props.themeName }}>
      <ThemeProviderSC
        theme={{
          dsTheme: props.themeName,
          ...dsThemes[props.themeName]
        }}
      >
        {props.children}
      </ThemeProviderSC>
    </ContextDSTheme.Provider>
  )
}
