import { AppMenu } from '@app/components-legacy/Menu'
import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { filterFalsies } from '@libs/filterFalsies'
import * as React from 'react'
import { CheckerDetailsPageContext } from './context'
import {
  canAccessToIoEDeviantObjects,
  canReadIoEInformation,
  canReadIoERecommandations,
  canReadIoEVulnerabilityDetails
} from './permissions'

enum DetailsBladeMenuKey {
  Information = 'Information',
  Vulnerability = 'Vulnerability',
  Deviant = 'Deviant',
  Recommendations = 'Recommendations'
}

interface IDetailsBladeMenuProps {}

const DetailsBladeMenu: React.FC<IDetailsBladeMenuProps> = () => {
  const parameters = React.useContext(CheckerDetailsPageContext)

  const translate = useAppTranslator({ namespaces: ['IoE.Details.Menu'] })

  const { storeRbac } = useStores()

  const storeMenu = useStoreMenuInit<IMenuEntry<DetailsBladeMenuKey>>({
    menuEntries:
      parameters &&
      filterFalsies([
        storeRbac.isUserGrantedTo(
          canReadIoEInformation(parameters.checkerId)
        ) && {
          key: DetailsBladeMenuKey.Information,
          label: translate('Information'),
          labelledBy: 'Information',
          routeDefinition: {
            routeName: AppRouteName.IoE_Details_Information,
            parameters
          }
        },
        storeRbac.isUserGrantedTo(
          canReadIoEVulnerabilityDetails(parameters.checkerId)
        ) && {
          key: DetailsBladeMenuKey.Vulnerability,
          label: translate('Vulnerability details'),
          labelledBy: 'Vulnerability details',
          routeDefinition: {
            routeName: AppRouteName.IoE_Details_VulnerabilityDetails,
            parameters
          }
        },
        storeRbac.isUserGrantedTo(
          canAccessToIoEDeviantObjects(parameters.checkerId)
        ) && {
          key: DetailsBladeMenuKey.Deviant,
          label: translate('Deviant objects'),
          labelledBy: 'Deviant objects',
          routeDefinition: {
            routeName: AppRouteName.IoE_Details_DeviantObjects,
            parameters
          }
        },
        storeRbac.isUserGrantedTo(
          canReadIoERecommandations(parameters.checkerId)
        ) && {
          key: DetailsBladeMenuKey.Recommendations,
          label: translate('Recommendations'),
          labelledBy: 'Recommendations',
          routeDefinition: {
            routeName: AppRouteName.IoE_Details_Recommendations,
            parameters
          }
        }
      ]),
    defaultSelectedMenuKey: DetailsBladeMenuKey.Information
  })

  return <AppMenu storeMenu={storeMenu} />
}

export default DetailsBladeMenu
