import { LabelSliced } from '@app/components-legacy/Label'
import type { EntityEvent } from '@app/entities'
import * as React from 'react'
import TableContentBodyCell from '../TableContentBodyCell'
import CellEmpty from './CellEmpty'

export interface ICellTypeProps {
  event?: EntityEvent
}

const CellType: React.FC<ICellTypeProps> = props => {
  const getCellContent = () => {
    if (!props.event) {
      return <CellEmpty />
    }

    return (
      <LabelSliced
        popoverType="none"
        value={props.event.getPropertyAsString('type', '-')}
        labelledBy="Type"
        maxLength={30}
      />
    )
  }

  return (
    <div className="eventType">
      <TableContentBodyCell>{getCellContent()}</TableContentBodyCell>
    </div>
  )
}

export default CellType
