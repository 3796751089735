export enum AlertSize {
  small = 'small',
  large = 'large'
}

export enum AlertVariant {
  default = 'default',
  success = 'success',
  error = 'error',
  dark = 'dark'
}
