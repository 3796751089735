import {
  LabelAlt,
  LabelAltError,
  LabelLicense
} from '@app/components-legacy/Label'
import { routesMatcher } from '@app/components-legacy/Router/utils'
import { useAppRouter, useAppTranslator, useStores } from '@app/hooks'
import { AppRouteName } from '@app/routes'
import type {
  LicenseFeature,
  LicenseType
} from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

export interface IProductLicenseDetailsProps {}
const StyledLabelAlt = styled(LabelAlt)`
  padding: 0 0 30px;
  text-align: center;
`

const ProductLicenseDetails: React.FC<IProductLicenseDetailsProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.About.License']
  })

  const { storeLicense } = useStores()

  const appRouter = useAppRouter()

  const license = storeLicense.license

  const currentRoute = appRouter.getCurrentRouteName()

  if (!license) {
    if (routesMatcher(appRouter)(currentRoute, AppRouteName.Auth_License)) {
      return (
        <StyledLabelAlt>
          {translate(
            'Please provide a valid license in order to use Tenable.ad'
          )}
        </StyledLabelAlt>
      )
    }

    return (
      <LabelAltError labelledBy="unableRetrieveLicense">
        {translate('Unable to retrieve the license')}
      </LabelAltError>
    )
  }

  return (
    <LabelLicense
      customerName={license.getPropertyAsString('customerName')}
      type={license.getPropertyAsT<LicenseType>('type')}
      features={license.getPropertyAsT<LicenseFeature[]>('features')}
      currentActiveUserCount={license.currentActiveUserCount}
      maxActiveUserCount={license.getPropertyAsNumber('maxActiveUserCount')}
      expirationDateUTC={license.getPropertyAsString('expirationDateUTC')}
      isExpired={storeLicense.isLicenseExpired}
      activationCode={license.activationCode}
      containerUuid={license.containerUuid}
      productAssociation={license.productAssociation}
    />
  )
}

export default observer(ProductLicenseDetails)
