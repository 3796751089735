import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

const StyledPath = styled.path`
  stroke: ${consts.colorBlueGray020};
  stroke-linecap: square;
`

const StyledPath2 = styled.path`
  fill: ${consts.colorBlueGray020};
  clip-rule: evenodd;
  fill-rule: evenodd;
`

const StyledRect = styled.rect`
  width: 72px;
  height: 1px;
  fill: ${consts.colorBlueGray020};
`

const StyledRect2 = styled.rect`
  width: 83px;
  height: 1px;
  fill: ${consts.colorBlueGray020};
`

interface IIconAttackVectorArrowProps {
  className?: string
}

const IconAttackVectorArrow: React.FC<IIconAttackVectorArrowProps> = props => {
  return (
    <svg
      className={props.className}
      viewBox="0 0 196 11"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPath d="M42 5.5H82L86 2.5L94 8.5L98 5.5H138" />
      <StyledPath2 d="M196 5.5L189 11V0L196 5.5Z" />
      <StyledRect y="5" />
      <StyledRect2 x="109" y="5" />
    </svg>
  )
}

export default styled(IconAttackVectorArrow)`
  width: 196px;
  height: 11px;
  fill: none;
`
