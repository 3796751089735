import type { IIconProps } from '@app/components/Icon/types'
import { FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconFilterProps extends IIconProps {}

const IconFilter: React.FC<IIconFilterProps> = props => {
  const fill = props.color ?? FontColorV2.white
  const size = props.size ?? 10

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="none"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-290.000000, -622.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <path
            d="M293.866889,631.984915 L295.774506,631.263114 C295.843197,631.245972 295.877602,631.177161 295.877602,631.10847 L295.877602,627.705694 C295.877602,627.447957 295.963555,627.190091 296.118196,626.983892 L299.486601,622.274999 C299.572555,622.154641 299.486601,622 299.3491,622 L290.171915,622 C290.034413,622 289.948463,622.154644 290.034413,622.274999 L293.402818,626.983892 C293.557463,627.190085 293.643413,627.447938 293.643413,627.705694 L293.643413,631.813086 C293.643413,631.950587 293.763652,632.036417 293.866744,631.984872 L293.866889,631.984915 Z"
            transform="translate(294.760508, 627.000000) translate(-294.760508, -627.000000)"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default IconFilter
