import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import type {
  AttackResourceType,
  AttacksRemoveFilter,
  Maybe
} from '@server/graphql/typeDefs/types'
import type { IEntityListable, IEntityOmitKeysParameter } from './types'

export interface IDataRowAttackRemoveFilter {
  id: number
  resourceType: AttackResourceType
  resourceValue: string
  attackTypeId: number
}

export default class EntityAttackRemoveFilter
  extends EntityBase
  implements
    PropertiesNullable<AttacksRemoveFilter>,
    IEntityListable<IDataRowAttackRemoveFilter>
{
  id: Maybe<number> = null
  resourceType: Maybe<AttackResourceType> = null
  resourceValue: Maybe<string> = null
  attackTypeId: Maybe<number> = null

  constructor(data: Partial<EntityAttackRemoveFilter>) {
    super()
    Object.assign(this, data)
  }

  /* Implements IEntityListable */

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowAttackRemoveFilter> = []
  ): Array<IWidgetListColumns<IDataRowAttackRemoveFilter>> {
    const columns: Array<IWidgetListColumns<IDataRowAttackRemoveFilter>> = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Resource type',
        key: 'resourceType'
      },
      {
        label: 'Resource value',
        key: 'resourceValue'
      },
      {
        label: 'Attack type',
        key: 'attackTypeId'
      }
    ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  asDataRow(): IDataRowAttackRemoveFilter {
    return {
      id: this.getPropertyAsNumber('id'),
      resourceType: this.getPropertyAsT('resourceType'),
      resourceValue: this.getPropertyAsString('resourceValue'),
      attackTypeId: this.getPropertyAsNumber('attackTypeId')
    }
  }
}
