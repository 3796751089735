import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

export interface ILinkSimpleProps {
  className?: string
  children: React.ReactNode
  href: string
}

const LinkSimple: React.FC<ILinkSimpleProps> = props => {
  return (
    <a
      className={props.className}
      target="_blank"
      rel="noopener noreferrer"
      href={props.href}
    >
      {props.children}
    </a>
  )
}

export default styled(LinkSimple)`
  font-weight: ${consts.fontWeightBold};
  color: ${consts.FontColorV2.link};

  &:hover {
    color: ${consts.FontColorV2.link};
  }
`
