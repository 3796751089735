import type { IIconProps } from '@app/components/Icon/types'
import { FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconRightArrowProps extends IIconProps {}

const IconRightArrow: React.FC<IIconRightArrowProps> = props => {
  const stroke = props.color ?? FontColorV2.white
  const size = props.size ?? 12

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M7.08199 10.1015L6.71585 9.74418L10.1853 6.35802L10.5519 6.0002L10.1853 5.64237L6.71585 2.25621L7.08198 1.89887L11.2841 6.00021L7.08199 10.1015Z"
        stroke={stroke}
      />
      <path
        d="M9.89981 5.7002H0.299805V6.3002H9.89981V5.7002Z"
        stroke={stroke}
      />
    </svg>
  )
}

export default IconRightArrow
