import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { consts } from '@app/styles'
import { ensureArray } from '@libs/ensureArray'
import { isDefined } from '@libs/isDefined'
import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { castArray } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import { getWidthValue } from './functions'
import ItemWrapper from './ItemWrapper'
import type { IContainerFlexProps } from './types'
import { TFlexValuePreset } from './types'

const ContainerFlex: React.FC<IContainerFlexProps> = props => {
  const ariaRoles = ensureArray(props.ariaRoles)

  const [mainRole, ...otherRoles] = (ariaRoles.length && ariaRoles) || [
    'contentinfo'
  ]

  const { testAttribute } = useTestAttribute(mainRole)

  const renderItem = (item: React.ReactNode, i: number) => {
    if (typeof item === 'boolean') {
      return null
    }

    if (!item) {
      return null
    }

    const key = `item-${i}`

    // FIXME: use item props style to avoid overriding
    // const props = (item as JSX.Element).props
    // const style = props ? props.style : {}

    const newProps: Partial<IContainerFlexProps & { key: string }> = {
      key,
      style: {}
    }

    if (props.itemsFlexGrow) {
      newProps.style = {
        ...newProps.style,
        flexGrow: props.itemsFlexGrow[i] || 0
      }
    }

    if (props.itemsFlexShrink) {
      newProps.style = {
        ...newProps.style,
        flexShrink: props.itemsFlexShrink[i] || 0
      }
    }

    if (props.wrapItems) {
      newProps.style = {
        ...newProps.style,
        flexWrap: props.flexWrap
      }

      return <ItemWrapper {...newProps}>{item}</ItemWrapper>
    }

    return React.cloneElement(item as JSX.Element, newProps)
  }

  if (!props.items) {
    return null
  }

  if (Array.isArray(props.items) && !props.items.length) {
    return null
  }

  const finalProps = filterEmptyProps({
    'data-name': props.name,
    ['data-test']:
      props.labelledBy && testAttribute(otherRoles)(props.labelledBy),
    style: props.style,
    className: props.className,
    onClick: props.onClick,
    onScroll: props.onScroll
  })

  return <div {...finalProps}>{castArray(props.items).map(renderItem)}</div>
}

function getLeftRightPadding(props: IContainerFlexProps): string {
  if (!isDefined(props.paddingH)) {
    return ''
  }

  if (typeof props.paddingH === 'number') {
    return `padding-left: ${props.paddingH}px; padding-right: ${props.paddingH}px`
  }

  switch (props.paddingH) {
    case 'none':
      return `padding-left: ${0}; padding-right: ${0}`

    case 'verySmall':
      return `padding-left: ${consts.paddingVerySmall}; padding-right: ${consts.paddingVerySmall}`

    case 'small':
      return `padding-left: ${consts.paddingSmall}; padding-right: ${consts.paddingSmall}`

    case 'default':
      return `padding-left: ${consts.paddingDefault}; padding-right: ${consts.paddingDefault}`

    case 'medium':
      return `padding-left: ${consts.paddingMedium}; padding-right: ${consts.paddingMedium}`

    case 'large':
      return `padding-left: ${consts.paddingLarge}; padding-right: ${consts.paddingLarge}`

    default:
      assertUnreachableCase(props.paddingH)
  }
}

function getTopBottomPadding(props: IContainerFlexProps): string {
  if (!isDefined(props.paddingV)) {
    return ''
  }

  if (typeof props.paddingV === 'number') {
    return `padding-top: ${props.paddingV}px; padding-bottom: ${props.paddingV}px`
  }

  switch (props.paddingV) {
    case 'none':
      return `padding-top: ${0}; padding-bottom: ${0}`

    case 'verySmall':
      return `padding-top: ${consts.paddingVerySmall}; padding-bottom: ${consts.paddingVerySmall}`

    case 'small':
      return `padding-top: ${consts.paddingSmall}; padding-bottom: ${consts.paddingSmall}`

    case 'default':
      return `padding-top: ${consts.paddingDefault}; padding-bottom: ${consts.paddingDefault}`

    case 'medium':
      return `padding-top: ${consts.paddingMedium}; padding-bottom: ${consts.paddingMedium}`

    case 'large':
      return `padding-top: ${consts.paddingLarge}; padding-bottom: ${consts.paddingLarge}`

    default:
      assertUnreachableCase(props.paddingV)
  }
}

export default styled(ContainerFlex)`
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};

  flex: ${props =>
    props.flexValuePreset ? props.flexValuePreset : TFlexValuePreset.initial};
  flex-wrap: ${props => props.flexWrap || 'nowrap'};
  flex-direction: ${props => props.direction || 'row'};
  flex-grow: ${props => props.flexGrow || 'initial'};

  justify-content: ${props => props.justifyContent || 'flex-start'};
  align-items: ${props => (props.alignItems ? props.alignItems : 'normal')};

  ${getLeftRightPadding};
  ${getTopBottomPadding};

  /* Force the width according to the flexValue preset (2 columns for full width) */
  ${props => {
    if (props.flexValuePreset) {
      switch (props.flexValuePreset) {
        case TFlexValuePreset.twoColumns:
          return 'width: 50%;'

        case TFlexValuePreset.mainColumns:
          return 'width: 75%;'

        case TFlexValuePreset.summaryColumns:
          return 'width: 25%;'

        default:
          return 'width: 100%;'
      }
    }

    return ''
  }}

  /*
   * Because className can't be injected in the renderItem methods,
   * (classNames injected by others styled HoC would be overwritten)
   * the solution is to inject all styles of spaced items in a single function.
  */
  ${props => {
    if (!props.spaced) {
      return ''
    }

    const direction = props.direction || ''

    return `
      > ${
        props.spaced === 'allChildren'
          ? '*'
          : `*:not(:${/reverse/.test(direction) ? 'first' : 'last'}-child)`
      } {
        ${
          /column/.test(direction)
            ? `margin-bottom: ${getWidthValue(props.spaceWidth || 'default')};`
            : `margin-right: ${getWidthValue(props.spaceWidth || 'default')};`
        }
      };
    `
  }}

  /* Set full height */
  ${props => {
    if (!props.fullHeight) {
      return ''
    }

    return 'height: 100%;'
  }}

  /* Set full width */
  ${props => {
    if (!props.fullWidth) {
      return ''
    }

    return 'min-width: 100%;'
  }}
`
