import type {
  EntitySearchBookmarkEntry,
  EntitySearchHistoryEntry
} from '@app/entities'
import type { StoreTrailFlow } from '@app/stores'
import type StoreSearchHistory from '@app/stores/TrailFlow/StoreSearchHistory'

/**
 * When clicking on an expression from the search history, select this expression and reload trailFlow.
 */
export const onTrailFlowSearchHistorySelection =
  (storeTrailFlow: StoreTrailFlow) => (value: string) => () => {
    storeTrailFlow.storeInputExpression
      .clearEntry()
      .setEntry(value)
      .validateEntry()

    storeTrailFlow.storeDrawerHistory.closeDrawer()
    storeTrailFlow.reloadData()
  }

/**
 * Delete search history entry.
 */
export const onSearchHistoryEntryDelete =
  (storeSearchHistory: StoreSearchHistory) =>
  (searchHistoryEntry: EntitySearchHistoryEntry | EntitySearchBookmarkEntry) =>
  (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // We need to stop propagation to prevent the history entry from being selected, we only want to delete it here.
    event.stopPropagation()

    if (!searchHistoryEntry.id) {
      return
    }

    storeSearchHistory.deleteSearchHistoryEntry(searchHistoryEntry.id)
  }

/**
 * Load new newer/older events when scrolling at the top/bottom.
 * Not used anymore for now.
 * TODO Remove it, for now leave it there.
 */
// export const onTrailFlowScroll = (storeTrailFlow: StoreTrailFlow) => (
//   tableHTMLElement: Maybe<HTMLDivElement>,
//   isLive: boolean
// ) => (): void => {
//   if (!tableHTMLElement) {
//     return
//   }

//   const isScrolledToBoom =
//     tableHTMLElement.scrollHeight - tableHTMLElement.scrollTop ===
//     tableHTMLElement.clientHeight

//   const isScrolledToTop = tableHTMLElement.scrollTop === 0

//   // fetch newer events only if we are not in live mode
//   // (no need to scroll newer events since we are in live mode)
//   if (isScrolledToTop && !isLive) {
//     fetchNewerEvents(storeTrailFlow)
//   }

//   if (isScrolledToBoom) {
//     fetchOlderEvents(storeTrailFlow)
//   }
// }
