import type { PropertiesNullable } from '@@types/helpers'
import type { EntityTopologyDirectory } from '@app/entities'
import {
  createEntities,
  EntityTopologyInfrastructure,
  EntityTopologyTrust
} from '@app/entities'
import SceneObject from '@app/pages/Topology/SceneBlade/Scene/SceneObject'
import type {
  IInfrastructureHierarchyNode,
  IPackedInfrastructureHierarchyNode,
  SceneCoordinates
} from '@app/pages/Topology/SceneBlade/Scene/types'
import {
  SCENE_DIRECTORY_WIDTH,
  SCENE_DIRECTORY_OFFSET_Y,
  SCENE_INFRASTRUCTURE_SPACING,
  SCENE_INFRASTRUCTURE_MAX_SIZE
} from '@app/stores/Topology/consts'
import { ensureArray } from '@libs/ensureArray'
import type {
  Maybe,
  Topology,
  TopologyInfrastructure,
  TopologyTrust
} from '@server/graphql/typeDefs/types'
import type { HierarchyNode } from 'd3'
import { hierarchy, pack } from 'd3'
import { get } from 'lodash'
import EntityBase from './EntityBase'

export default class EntityTopology
  extends EntityBase
  implements PropertiesNullable<Topology>
{
  infrastructures: Maybe<TopologyInfrastructure[]> = null
  trusts: Maybe<TopologyTrust[]> = null
  packCoordinates: Maybe<SceneCoordinates> = null

  constructor(data: Partial<Topology>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return infrastructure entities.
   */
  getInfrastructures(): EntityTopologyInfrastructure[] {
    return createEntities<TopologyInfrastructure, EntityTopologyInfrastructure>(
      EntityTopologyInfrastructure,
      this.infrastructures
    )
  }

  /**
   * Return all directory entities of all infrastructures.
   */
  getAllDirectories(): Map<string, EntityTopologyDirectory[]> {
    return this.getInfrastructures().reduce((acc, infrastructure) => {
      acc.set(
        infrastructure.getPropertyAsString('uid'),
        infrastructure.getDirectories()
      )
      return acc
    }, new Map<string, EntityTopologyDirectory[]>())
  }

  /**
   * Return all trust entities.
   */
  getTrusts(): EntityTopologyTrust[] {
    return createEntities<TopologyTrust, EntityTopologyTrust>(
      EntityTopologyTrust,
      this.trusts
    )
  }

  /**
   * Return filtered trust entities by removing reciprocal trust.
   */
  getUniqTrusts(): EntityTopologyTrust[] {
    return this.getTrusts().reduce<EntityTopologyTrust[]>((acc, trust) => {
      const findBidirectional = acc.find(
        t =>
          t.from === trust.to &&
          t.to === trust.from &&
          trust.hazardLevel === t.hazardLevel
      )
      if (findBidirectional) {
        return acc
      }

      acc.push(trust)
      return acc
    }, [])
  }

  /**
   * Compute the radius (size) of the infrastructure.
   * The more infrastructure has directories, the bigger it will be, in a logarithmic scale,
   * without exceeding SCENE_INFRASTRUCTURE_MAX_SIZE.
   */
  computeInfrastructureRadius(
    nbDirectories: number,
    nbMaxOfDirectories: number
  ): number {
    const radius =
      // multiply the number of dirs to improve the difference between 1 or more directories
      // https://www.desmos.com/calculator/qbiguljt8x
      (SCENE_INFRASTRUCTURE_MAX_SIZE * Math.log(nbDirectories * 2)) /
      Math.log(nbMaxOfDirectories + 1)

    return radius
  }

  computeInfrastructureSceneObjects(
    chartScaleWidth: number,
    chartScaleHeight: number
  ): Array<SceneObject<EntityTopologyInfrastructure>> {
    const infrastructures = this.getInfrastructures()
    const allDirectories = this.getAllDirectories()

    // get the max number of directories for each infrastructure
    const nbMaxOfDirectories = Math.max(
      ...Array.from(allDirectories.values()).map(
        directories => directories.length
      )
    )

    const infrastructureHierarchyNodes =
      infrastructures.map<IInfrastructureHierarchyNode>(infrastructure => {
        const directories = ensureArray(
          allDirectories.get(infrastructure.getPropertyAsString('uid'))
        )

        // compute the radious according to the number of directories
        const radius = this.computeInfrastructureRadius(
          directories.length,
          nbMaxOfDirectories
        )

        return {
          radius,
          infrastructure
        }
      })

    const infrastructureRootNode = hierarchy({
      children: infrastructureHierarchyNodes
    })

    // Mutate data by computing the sum of children nodes
    infrastructureRootNode.sum(d => Number(get(d, 'radius')))

    // Mutate data by computing the x, y, r (radius) of infrastructure circles
    pack()
      .size([chartScaleWidth, chartScaleHeight])
      // radius getter to take into account the radius computed by computeInfrastructureRadius
      .radius(children => Number(get(children, 'data.radius')))
      .padding(SCENE_INFRASTRUCTURE_SPACING)(
      infrastructureRootNode as HierarchyNode<unknown>
    )

    if (!infrastructureRootNode.children) {
      return []
    }

    // FIX one day: hack type to handler easier data
    const nodeParent = infrastructureRootNode as unknown as SceneCoordinates
    this.packCoordinates = {
      x: nodeParent.x,
      y: nodeParent.y
    }

    // FIX one day: hack type to handler easier data
    const nodes =
      infrastructureRootNode.children as unknown as IPackedInfrastructureHierarchyNode[]
    return nodes.map(node => {
      return new SceneObject(
        {
          x: node.x,
          y: node.y,
          radius: node.r
        },
        node.data.infrastructure
      )
    })
  }

  computeDirectorySceneObjects(
    infrastructureSceneObjects: Array<SceneObject<EntityTopologyInfrastructure>>
  ): Map<
    SceneObject<EntityTopologyInfrastructure>,
    Array<SceneObject<EntityTopologyDirectory>>
  > {
    const map: Map<
      SceneObject<EntityTopologyInfrastructure>,
      Array<SceneObject<EntityTopologyDirectory>>
    > = new Map()

    infrastructureSceneObjects.forEach(sceneObject => {
      const infrastructure = sceneObject.object

      const directories = infrastructure.getDirectories()
      const step = 360 / directories.length

      const directorySceneObjects = directories.map((directory, index) => {
        const angle = index * step
        const angleRadians = (angle * Math.PI) / 180

        const directoryX =
          sceneObject.coordinates.x +
          (sceneObject.coordinates.radius || 0) * Math.sin(angleRadians)

        const directoryY =
          sceneObject.coordinates.y -
          (sceneObject.coordinates.radius || 0) * Math.cos(angleRadians)

        const directorySceneObject = new SceneObject(
          {
            x: directoryX - SCENE_DIRECTORY_WIDTH / 2,
            y: directoryY - SCENE_DIRECTORY_WIDTH / 2 - SCENE_DIRECTORY_OFFSET_Y
          },
          directory
        )

        return directorySceneObject
      })

      map.set(sceneObject, directorySceneObjects)
    })

    return map
  }
}
