import {
  DIRECTORY_DEFAULT_GLOBAL_CATALOG_PORT,
  DIRECTORY_DEFAULT_LDAP_PORT,
  DIRECTORY_DEFAULT_SMB_PORT
} from '@app/entities/EntityDirectory'
import { AppRouteName } from '@app/routes'
import type {
  StoreBlades,
  StoreManagementDirectories,
  StoreManagementRelays,
  StoreRbac
} from '@app/stores'
import type StoreDataCollection from '@app/stores/Management/StoreDataCollection'
import type { AppRouterClient } from '@libs/Router/types'
import type { InputEditDirectory } from '@server/graphql/typeDefs/types'
import { DirectoryFormFieldName } from '../DirectoriesCreatePage/types'
import { canAccessTenableCloudSensitiveData } from '../permissions'
import { canAccessToRelays } from './../../Relays/permissions'

/**
 * Initialize the form of the edition of a directory.
 */
export const onDirectoryEditLoad =
  (
    storeManagementDirectories: StoreManagementDirectories,
    storeManagementDataCollection: StoreDataCollection,
    storeManagementRelays: StoreManagementRelays,
    storeRbac: StoreRbac
  ) =>
  (directoryId: number) =>
  () => {
    Promise.resolve()
      .then(() => {
        return !storeManagementDirectories.directories.size
          ? storeManagementDirectories.fetchDirectories()
          : null
      })
      .then(() => {
        return storeRbac.isUserGrantedTo(canAccessToRelays)
          ? storeManagementRelays.fetchRelays()
          : null
      })
      .then(() => {
        const entity = storeManagementDirectories.directories.get(directoryId)

        if (!entity) {
          return
        }

        storeManagementDirectories.storeFormDomain
          .setDefaultFieldsValuesFromObject({
            [DirectoryFormFieldName.name]: entity.getPropertyAsString('name'),
            [DirectoryFormFieldName.dns]: entity.getPropertyAsString('dns'),
            [DirectoryFormFieldName.ip]: entity.getPropertyAsString('ip'),
            [DirectoryFormFieldName.ldapPort]:
              entity.getPropertyAsString('ldapPort'),
            [DirectoryFormFieldName.globalCatalogPort]:
              entity.getPropertyAsString('globalCatalogPort'),
            [DirectoryFormFieldName.smbPort]:
              entity.getPropertyAsString('smbPort'),
            [DirectoryFormFieldName.infrastructureId]:
              entity.getPropertyAsNumber('infrastructureId'),
            [DirectoryFormFieldName.relayId]:
              entity.getPropertyAsNumber('relayId') !== 0
                ? entity.getPropertyAsNumber('relayId')
                : null,
            [DirectoryFormFieldName.sensitiveDataCollectionIsEnabled]:
              entity.getPropertyAsBoolean('sensitiveDataCollectionIsEnabled')
          })
          .reset()
      })
    if (storeRbac.isUserGrantedTo(canAccessTenableCloudSensitiveData)) {
      storeManagementDataCollection.fetchDataCollectionStatus()
    }
  }

/**
 * Edit the directory,
 * Fetch directories,
 * And go back to the list.
 */
export const onDirectoriesEditSubmit =
  (
    appRouter: AppRouterClient,
    storeManagementDirectories: StoreManagementDirectories
  ) =>
  (directoryId: number) =>
  (e: React.FormEvent<any>) => {
    const { storeFormDomain } = storeManagementDirectories!

    e.preventDefault()

    if (!storeFormDomain.validate()) {
      storeFormDomain.storeRoot.stores.storeMessages.validationError()
      return
    }

    const directory: InputEditDirectory = {
      id: directoryId,
      name: storeFormDomain.getFieldValueAsString(DirectoryFormFieldName.name),
      ip: storeFormDomain.getFieldValueAsString(DirectoryFormFieldName.ip),
      // used only for the url ; a directory can't change its infrastructure once created
      infrastructureId: storeFormDomain.getFieldValueAsNumber(
        DirectoryFormFieldName.infrastructureId
      ),
      sensitiveDataCollectionIsEnabled: storeFormDomain.getFieldValueAsBoolean(
        DirectoryFormFieldName.sensitiveDataCollectionIsEnabled
      ),
      relayId: storeFormDomain.getFieldValueAsNumber(
        DirectoryFormFieldName.relayId
      ),
      dns: storeFormDomain.getFieldValueAsString(DirectoryFormFieldName.dns),
      ldapPort: storeFormDomain.getFieldValueAsNumber(
        DirectoryFormFieldName.ldapPort,
        DIRECTORY_DEFAULT_LDAP_PORT
      ),
      globalCatalogPort: storeFormDomain.getFieldValueAsNumber(
        DirectoryFormFieldName.globalCatalogPort,
        DIRECTORY_DEFAULT_GLOBAL_CATALOG_PORT
      ),
      smbPort: storeFormDomain.getFieldValueAsNumber(
        DirectoryFormFieldName.smbPort,
        DIRECTORY_DEFAULT_SMB_PORT
      )
    }

    storeManagementDirectories
      .editDirectory(directory)
      .then(() => {
        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Management_System_Directories,
          parameters: {}
        })
        appRouter.history.push(url)
      })
      .catch(() => {
        // already catched in the store
      })
  }

export const onDirectoriesEditCancelClick =
  (storeBlades: StoreBlades) => () => {
    const url = storeBlades.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Directories,
      parameters: {}
    })

    storeBlades.goToBladeUrl(url)
  }
