import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import {
  FormWrapperButton,
  FormWrapperSelect
} from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import type { IRbacBulkPermissionAction } from '@libs/rbac/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  onBulkActionsSelectorChange,
  onBulkActionsSelectorSubmit
} from './handlers'
import { buildPermissionOptionValue, getBulkPermissionOptions } from './utils'

interface IListEntityItemsBulkActionsSelectorProps {}

const ListEntityItemsBulkActionsSelector: React.FC<
  IListEntityItemsBulkActionsSelectorProps
> = () => {
  const [selectedPermissionOption, selectPermissionOption] =
    React.useState<Maybe<IRbacBulkPermissionAction>>(null)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.Accounts.Roles.Permissions']
  })

  const { storeManagementRbacRoles } = useStores()

  const getSelectValue = (): string => {
    const availablePermissionOptionValues = getBulkPermissionOptions(
      storeManagementRbacRoles,
      translate
    ).map(bulkOption => bulkOption.value)

    let selectValue =
      buildPermissionOptionValue(selectedPermissionOption) ||
      // fallback on the first option available if no selection have been done yet
      availablePermissionOptionValues[0]

    // if the selected option if not in available options, fallback on the first available
    if (availablePermissionOptionValues.indexOf(selectValue) === -1) {
      selectValue = availablePermissionOptionValues[0]
    }

    return selectValue
  }

  const isSomeRowsSelected =
    storeManagementRbacRoles.isSomeRbacEntityItemsSelected(
      storeManagementRbacRoles.storeMenu.selectedMenuKey
    )

  const selectedPermissionOptionString = getSelectValue()

  return (
    <ContainerFlex
      name="BulkActionsSelector"
      alignItems="center"
      items={[
        <FormWrapperSelect
          labelledBy="listAction"
          width="large"
          selectProps={{
            size: 'small',
            placeholder: translate('Action'),
            value: selectedPermissionOptionString,
            onChange: onBulkActionsSelectorChange(
              storeManagementRbacRoles,
              translate
            )(selectPermissionOption),
            disabled: !isSomeRowsSelected
          }}
          selectOptions={getBulkPermissionOptions(
            storeManagementRbacRoles,
            translate
          ).map(option => {
            return {
              label: option.label,
              value: option.value,
              style: { backgroundColor: option.backgroundColor }
            }
          })}
        />,

        <FormWrapperButton
          labelledBy="submit"
          buttonProps={{
            size: 'small',
            onClick: onBulkActionsSelectorSubmit(
              storeManagementRbacRoles,
              translate
            )(selectedPermissionOptionString),
            disabled: !isSomeRowsSelected
          }}
        >
          {translate('OK')}
        </FormWrapperButton>
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(ListEntityItemsBulkActionsSelector)
