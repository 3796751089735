import { ContainerHTMLContent } from '@app/components/Container'
import { Label, LabelSliced } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import type { TranslateFn } from '@libs/i18n'
import * as React from 'react'
import styled from 'styled-components'
import type { IHealthCheckBaseLabelProps } from './types'

const StyledContainerHTMLContent = styled(ContainerHTMLContent)`
  ul {
    margin: 0;
  }
`

const StyledLabel = styled(Label)`
  min-width: 0;
`

export interface IHealthCheckLabelProps extends IHealthCheckBaseLabelProps {
  translate: TranslateFn
}

const HealthCheckLabel: React.FC<IHealthCheckLabelProps> = props => {
  const value = props.translate(props.locale, {
    transformMarkdown: props.transformMarkdown,
    interpolations: props.interpolations
  })

  if (props.pureText) {
    return <>{value}</>
  }

  function renderValue(): React.ReactNode {
    if (props.transformMarkdown) {
      return <StyledContainerHTMLContent>{value}</StyledContainerHTMLContent>
    }

    if (props.maxWidth) {
      return <LabelSliced value={value} maxWidth={props.maxWidth} />
    }

    if (props.maxWidthAuto) {
      return <LabelSliced value={value} maxWidthAuto />
    }

    return value
  }

  return (
    <StyledLabel variant={LabelVariant.p} {...props.labelProps}>
      {renderValue()}
    </StyledLabel>
  )
}

export default HealthCheckLabel
