import { LabelSliced } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { LabelSliced as LabelSlicedDeprecated } from '@app/components-legacy/Label'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled from 'styled-components'

interface ITypographyWidgetTitleProps {
  className?: string
  children: string
}

const TypographyWidgetTitle: React.FC<ITypographyWidgetTitleProps> = props => {
  const theme = useDSTheme()
  return (
    <h3 data-name="TypographyWidgetTitle" className={props.className}>
      {isThemeLegacy(theme) ? (
        <LabelSlicedDeprecated maxLength={35} value={String(props.children)} />
      ) : (
        <LabelSliced
          maxWidthAuto
          value={props.children}
          labelProperties={{
            variant: LabelVariant.p,
            color: theme.tokens['dashboardWidget/color/title'],
            style: {
              textTransform: 'uppercase'
            }
          }}
        />
      )}
    </h3>
  )
}

export default styled(TypographyWidgetTitle)`
  text-transform: uppercase;
  font-weight: ${consts.fontWeightBold};
  font-size: ${consts.textSizeNormal};
  color: ${({ theme }) => theme.tokens['dashboardWidget/color/title']};
  margin: 0;
  min-width: 0;
`
