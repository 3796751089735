import { ContainerFlex } from '@app/components-legacy/Container'
import {
  LabelAlt,
  LabelAltVariant
} from '@app/components-legacy/Label/LabelAlt'
import { useAppTranslator } from '@app/hooks'
import type StoreSummaryCard from '@app/stores/IoA/StoreBoard/StoreSummaryCard'
import { consts } from '@app/styles'
import { getCriticityColor } from '@libs/criticity'
import { isDefined } from '@libs/isDefined'
import * as React from 'react'
import styled from 'styled-components'
import CardRankAttack from './CardRankAttack'
import CardRankTitle from './CardRankTitle'

interface ICardRankProps {
  className?: string
  storeSummaryCard: StoreSummaryCard
}

const StyledNoTop3Attacks = styled(LabelAlt)`
  color: ${consts.colorGrey010};
  margin-left: ${consts.marginSmall};
`

const CardRank: React.FC<ICardRankProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Buttons', 'IoA.Board'] })

  const getItems = () => {
    if (!props.storeSummaryCard.hasAttacksDetected) {
      return [
        <StyledNoTop3Attacks
          variant={LabelAltVariant.Centered}
          className="top3NoAttacks"
        >
          {translate('No attack detected during this period')}
        </StyledNoTop3Attacks>,
        <LabelAlt>&nbsp;</LabelAlt>, // Add some blank to simulate the 2 other lines
        <LabelAlt>&nbsp;</LabelAlt>
      ]
    }

    const rankedItems = props.storeSummaryCard.attackTypesTop3
      .map(dataPoint => {
        return (
          <CardRankAttack
            name={dataPoint.attackType.genericName}
            levelColor={getCriticityColor(
              dataPoint.attackType.genericCriticity
            )}
            nbAttacks={dataPoint.count}
          />
        )
      })
      .filter(isDefined)

    if (rankedItems.length === 1) {
      rankedItems.push(<CardRankAttack />)
    }

    if (rankedItems.length === 2) {
      rankedItems.push(<CardRankAttack />)
    }

    return rankedItems
  }

  return (
    <ContainerFlex
      name={CardRank.name}
      direction="column"
      items={[<CardRankTitle />, ...getItems()]}
      spaced="allChildren"
      spaceWidth="small"
    />
  )
}

export default CardRank
