import type { IGraphQLQuery } from '@libs/graphQL/types'
import type {
  HealthChecksDomainStatusDetailsQueryArgs,
  HealthChecksDomainStatusQueryArgs,
  HealthChecksPlatformStatusDetailsQueryArgs,
  HealthChecksPlatformStatusQueryArgs,
  Query
} from '../typeDefs/types'

/**
 * HealthCheck
 */

export type QueryHealthChecksDomainStatus = IGraphQLQuery<
  HealthChecksDomainStatusQueryArgs,
  Pick<Query, 'healthChecksDomainStatus'>
>

export const queryHealthChecksDomainStatus = `
    query queryHealthChecksDomainStatus(
      $directoryIds: [Int!]!
      $healthCheckNames: [String!]!
      $showHealthy: Boolean
      $healthChecksPage: Int
      $healthChecksPerPage: Int
    ) {
        healthChecksDomainStatus(
          directoryIds: $directoryIds
          healthCheckNames: $healthCheckNames
          showHealthy: $showHealthy
          healthChecksPage: $healthChecksPage
          healthChecksPerPage: $healthChecksPerPage
        ) {
            node {
              healthCheckName
              directoryId
              status
              lastCheckDate
              statusSince
            }
            pagination {
              page
              perPage
              totalCount
            }
        }
    }
`

export type QueryHealthChecksDomainStatusDetails = IGraphQLQuery<
  HealthChecksDomainStatusDetailsQueryArgs,
  Pick<Query, 'healthChecksDomainStatusDetails'>
>

export const queryHealthChecksDomainStatusDetails = `
  query queryHealthChecksDomainStatusDetails (
    $healthCheckName: String!
    $directoryId: Int!
  ) {
    healthChecksDomainStatusDetails(
      healthCheckName: $healthCheckName
      directoryId: $directoryId
    ) {
      healthCheckName
      directoryId
      status
      lastCheckDate
      statusSince
      reasons {
        name
        status
        statusSince
        lastCheckDate
        attributes {
          name
          value
          valueType
        }
      }
    }
  }
`

export type QueryHealthChecksPlatformStatus = IGraphQLQuery<
  HealthChecksPlatformStatusQueryArgs,
  Pick<Query, 'healthChecksPlatformStatus'>
>

export const queryHealthChecksPlatformStatus = `
    query queryHealthChecksPlatformStatus(
      $healthCheckNames: [String!]!
      $showHealthy: Boolean
      $healthChecksPage: Int
      $healthChecksPerPage: Int
    ) {
        healthChecksPlatformStatus(
          healthCheckNames: $healthCheckNames
          showHealthy: $showHealthy
          healthChecksPage: $healthChecksPage
          healthChecksPerPage: $healthChecksPerPage
        ) {
            node {
              healthCheckName
              status
              lastCheckDate
              statusSince
            }
            pagination {
              page
              perPage
              totalCount
            }
        }
    }
`

export type QueryHealthChecksPlatformStatusDetails = IGraphQLQuery<
  HealthChecksPlatformStatusDetailsQueryArgs,
  Pick<Query, 'healthChecksPlatformStatusDetails'>
>

export const queryHealthChecksPlatformStatusDetails = `
  query queryHealthChecksPlatformStatusDetails (
    $healthCheckName: String!
  ) {
    healthChecksPlatformStatusDetails(
      healthCheckName: $healthCheckName
    ) {
      healthCheckName
      status
      lastCheckDate
      statusSince
      reasons {
        name
        status
        statusSince
        lastCheckDate
        attributes {
          name
          value
          valueType
        }
      }
    }
  }
`

export type QueryHealthChecksGlobalStatus = IGraphQLQuery<
  null,
  Pick<Query, 'healthChecksGlobalStatus'>
>

export const queryHealthChecksGlobalStatus = `
  query queryHealthChecksGlobalStatus {
    healthChecksGlobalStatus {
      status
      issues
      warnings
    }
  }
`
export type QueryHealthChecksTemplatesNames = IGraphQLQuery<
  null,
  Pick<Query, 'healthChecksTemplatesNames'>
>

export const queryHealthChecksTemplatesNames = `
  query queryHealthChecksTemplatesNames {
    healthChecksTemplatesNames {
      codename
      alert
    }
  }
`

export type QueryHealthChecksDetailedGlobalStatus = IGraphQLQuery<
  null,
  Pick<Query, 'healthChecksDetailedGlobalStatus'>
>

export const queryHealthChecksDetailedGlobalStatus = `
  query queryHealthChecksDetailedGlobalStatus {
    healthChecksDetailedGlobalStatus {
      status
      domainStats {
        numberOfIssues
        numberOfWarnings
        numberOfUnknowns
      }
      platformStats {
        numberOfIssues
        numberOfWarnings
        numberOfUnknowns
      }
    }
  }
`
