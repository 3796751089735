import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { isRbacAdminRole } from '@app/stores/Management/Rbac/functions'
import type { RbacRoleFormFieldName } from '@libs/rbac/types'
import { Alert } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export interface IRoleCommonFormMainInformationProps {}

const RoleCommonFormMainInformation: React.FC<
  IRoleCommonFormMainInformationProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Titles', 'Buttons', 'Management.Accounts.Roles']
  })

  const { storeManagementRbacRoles } = useStores()

  const role = storeManagementRbacRoles.currentRbacRole
  const isAdminRole = isRbacAdminRole(role)

  return (
    <ContainerFlex
      name="RoleCommonFormMainInformation"
      direction="column"
      items={[
        isAdminRole && (
          <Alert
            type="info"
            message={translate(
              'This is the Admin role and its permissions are not editable'
            )}
            showIcon
          />
        ),

        <ContainerContent title={translate('Main information')}>
          <WidgetForm<RbacRoleFormFieldName>
            translate={translate}
            storeForm={storeManagementRbacRoles.storeForm}
          />
        </ContainerContent>
      ]}
      spaced
    />
  )
}

export default observer(RoleCommonFormMainInformation)
