import { Features } from '@alsid/common'
import type { IIconProps } from '@app/components/Icon/types'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import * as React from 'react'

interface IIconSettingProps extends IIconProps {}

const IconSetting: React.FC<IIconSettingProps> = props => {
  const { storeRoot } = useStores()

  const fill = props.color ?? consts.colorBlue015
  const size = props.size ?? 24

  const isTulV6Enabled = storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
    Features.KAPTEYN_TUL_V6
  )

  if (isTulV6Enabled) {
    return (
      <tenable-floating-button
        id="settings-menu-button"
        aria-label="Settings"
        aria-haspopup="true"
      >
        <tenable-settings-app color="brand" size="medium" />
      </tenable-floating-button>
    )
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="3"></circle>
      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
    </svg>
  )
}

export default IconSetting
