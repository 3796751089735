import type { Maybe } from '@@types/helpers'
import type { StoreRoot } from '@app/stores'
import type StoreFlags from '@app/stores/helpers/StoreFlags'
import type { IMessageOptions } from '@app/stores/StoreMessages'
import { ensureArray } from '@libs/ensureArray'
import { ApplicationError, ApplicationRequestError } from '@libs/errors'
import { isErrorOfType } from '@libs/errors/functions'
import type { IRequestResults } from '@libs/errors/types'
import { ApplicationErrorValue, ErrorName } from '@libs/errors/types'
import type { TranslateFn } from '@libs/i18n'
import { isDefined } from '@libs/isDefined'
import type { ILogger } from '@libs/logger'
import type { RequestError } from '@server/graphql/typeDefs/types'
import { RequestErrorCode } from '@server/graphql/typeDefs/types'
import type { IMaybeRequestErrorResults } from '@server/graphql/types'
import { validate } from 'jsonschema'
import { values } from 'lodash'
import type { ForbiddenAccessError } from './index'

export interface IStoreErrorHandlerOptions {
  /**
   * Custom translation for error message push in the UI
   *
   * @returns false: No message; null: default message; string: custom message
   */
  errorMessageTranslationFn?: (err: Error) => false | Maybe<string>

  /**
   * Throw a new exception in order to forward the error in the UI.
   *
   * @returns
   *  - true: Forward with the original exception
   *  - false: Do not forward the exception
   *  - string: Forward with custom message
   */
  forwardExceptionFn?: (err: Error) => boolean | string
}

/**
 * Check the potential requestErrorResults.requestError by throwing the suitable
 * exception or return true to validate that requestErrorResults is a IRequestResults
 * interface, with a defined node and a nullable requestError value.
 *
 * Triggered exception can be handled by handleStoreError function.
 *
 * Usage:
 *
 * .then(() => {
 *     # ...
 *
 *     return this.storeRoot
 *       .getGQLRequestor()
 *       .query<IMutationEditRbacRole>(mutationEditRbacRole, args)
 *   })
 *   .then(response => handleMaybeRequestErrorResults(response.editRbacRole))
 *   .then((node) => {
 *     # node is defined
 *   })
 *   .catch(handleStoreError(...))
 */
export function handleMaybeRequestErrorResults<T>(message: string) {
  return (
    requestErrorResults: IMaybeRequestErrorResults<T>
  ): requestErrorResults is IRequestResults<T> => {
    if (requestErrorResults.requestError?.error) {
      const { title, code } = requestErrorResults.requestError.error

      if (title && code) {
        throw new ApplicationRequestError({
          errorValue: ApplicationErrorValue.ApiError,
          message: {
            message,
            technicalError: title
          }
        })
      }

      throw new ApplicationError({
        errorValue: ApplicationErrorValue.ApiError
      })
    }

    if (!isDefined(requestErrorResults.node)) {
      throw new ApplicationError({
        errorValue: ApplicationErrorValue.ApiError
      })
    }

    return true
  }
}

/**
 * Generic error handler for stores.
 */
export function handleStoreError(
  storeRoot: StoreRoot,
  storeFlags: Maybe<StoreFlags | StoreFlags[]>,
  options: IStoreErrorHandlerOptions = {},
  _messageOptions?: IMessageOptions
) {
  const { logger, appTranslator } = storeRoot

  const translate = appTranslator.bindOptions({
    namespaces: ['Errors', 'Rbac']
  })

  const messageOptions: IMessageOptions = {
    html: true,
    customIcon: 'error',
    labelledBy: 'error',
    ..._messageOptions
  }

  return (err: Error): void => {
    if (
      isErrorOfType<ForbiddenAccessError>(err, ErrorName.ForbiddenAccessError)
    ) {
      return _handleStoreErrorForbiddenAccessErrorException(
        storeRoot,
        storeFlags,
        options,
        messageOptions
      )(
        logger,
        translate
      )(err)
    }

    if (
      isErrorOfType<ApplicationRequestError>(
        err,
        ErrorName.ApplicationRequestError
      )
    ) {
      return _handleStoreErrorApplicationRequestErrorException(
        storeRoot,
        storeFlags,
        options,
        messageOptions
      )(
        logger,
        translate
      )(err)
    }

    storeRoot.logException(err)

    ensureArray(storeFlags).forEach(storeFlag => storeFlag.fail())

    _pushErrorMessage(storeRoot, options, messageOptions)(err)

    if (options.forwardExceptionFn) {
      _forwardException(options)(err)
    }
  }
}

/**
 * Handle store errors of type ForbiddenAccessError.
 */
function _handleStoreErrorForbiddenAccessErrorException(
  storeRoot: StoreRoot,
  storeFlags: Maybe<StoreFlags | StoreFlags[]>,
  options: IStoreErrorHandlerOptions = {},
  messageOptions: IMessageOptions
) {
  return (logger: ILogger, translate: TranslateFn) => {
    return (err: ForbiddenAccessError): void => {
      logger.info(
        `[StoreError] Unauthorized access: ${err.message || 'No message set'}`
      )

      ensureArray(storeFlags).forEach(storeFlag => storeFlag.forbidden())

      _pushErrorMessage(
        storeRoot,
        {
          errorMessageTranslationFn: rbacErr => {
            // handle custom message if defined
            if (options.errorMessageTranslationFn) {
              return options.errorMessageTranslationFn(rbacErr)
            }

            // Uggly extraction of the route name.
            // FIXME? encode a JSON struct as the exception message instead?
            const matches = rbacErr.message.match(/[A-Z]+ \/(.*);/)
            const routePath = matches && Array.from(matches)[1]

            return routePath
              ? translate(`You do not have access to this page (X)`, {
                  interpolations: { entityName: routePath }
                })
              : translate(`You do not have access to this page`)
          }
        },
        messageOptions
      )(err)

      if (options.forwardExceptionFn) {
        _forwardException(options)(err)
      }

      return
    }
  }
}

/**
 * Handle store errors of type ApplicationRequestError.
 */
function _handleStoreErrorApplicationRequestErrorException(
  storeRoot: StoreRoot,
  storeFlags: Maybe<StoreFlags | StoreFlags[]>,
  options: IStoreErrorHandlerOptions = {},
  messageOptions: IMessageOptions
) {
  return (logger: ILogger, translate: TranslateFn) => {
    return (err: ApplicationRequestError): void => {
      logger.info(
        `[StoreError] Application error: ${err.message || 'No message set'}`
      )

      ensureArray(storeFlags).forEach(storeFlag => storeFlag.fail())

      const storeHandlerOptions: IStoreErrorHandlerOptions = {
        errorMessageTranslationFn: () => {
          const exceptionMessage = err.getMessage()

          return translate('An application error has occurred', {
            interpolations: {
              technicalError: translate(
                exceptionMessage.message?.technicalError || 'Not available'
              )
            },
            transformMarkdown: true
          })
        }
      }

      _pushErrorMessage(storeRoot, storeHandlerOptions, messageOptions)(err)

      if (options.forwardExceptionFn) {
        _forwardException(options)(err)
      }

      return
    }
  }
}

/**
 * Push custom or generic error message.
 */
function _pushErrorMessage(
  storeRoot: StoreRoot,
  options: IStoreErrorHandlerOptions,
  messageOptions?: IMessageOptions
) {
  return (err: Error): void => {
    const customErrorMessage =
      options.errorMessageTranslationFn &&
      options.errorMessageTranslationFn(err)

    if (customErrorMessage === false) {
      return
    }

    if (customErrorMessage) {
      storeRoot.stores.storeMessages.error(customErrorMessage, {
        labelledBy: 'genericError',
        ...messageOptions
      })
      return
    }

    storeRoot.stores.storeMessages.genericError()
  }
}

/**
 * Optionally, rethrow the exception to bubble up the exception to above layers.
 */
function _forwardException(options: IStoreErrorHandlerOptions) {
  return (err: Error): void => {
    if (!options.forwardExceptionFn) {
      return
    }

    const customMessageOrBool = options.forwardExceptionFn(err)

    if (customMessageOrBool === false) {
      return
    }

    if (customMessageOrBool === 'string') {
      err.message = customMessageOrBool
    }

    // rethrow the same exception
    throw err
  }
}

/**
 * Typeguard to validate that input is a `RequestError` object.
 */
export function isRequestError(input: any): input is RequestError {
  if (!input) {
    return false
  }

  const schema = {
    type: 'object',
    required: ['error'],
    additionalProperties: false,
    properties: {
      message: { type: 'string' },
      error: {
        type: 'object',
        required: ['code', 'title', 'resource'],
        properties: {
          code: {
            enum: values(RequestErrorCode)
          },
          title: {
            type: 'string'
          },
          resource: {
            type: 'string'
          }
        }
      }
    }
  }

  return validate(input, schema).valid
}
