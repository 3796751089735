import type { PropertiesNullable } from '@@types/helpers'
import { createEntities, EntityRbacRole } from '@app/entities'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import { isDefined } from '@libs/isDefined'
import type {
  Maybe,
  MaybeGrantedRbacRoles,
  RbacRole,
  User
} from '@server/graphql/typeDefs/types'
import { AuthProviderName } from '@server/graphql/typeDefs/types'
import { flatMap } from 'lodash'
import EntityBase from './EntityBase'
import type { IEntityListable, IEntityOmitKeysParameter } from './types'

export interface IDataRowUser {
  id: number
  surname: string
  name: string
  email: string
  department: string
  provider: AuthProviderName
  rbacRoles: MaybeGrantedRbacRoles[]
  active: boolean
  lockedOut: boolean
}

export default class EntityUser
  extends EntityBase
  implements PropertiesNullable<User>, IEntityListable<IDataRowUser>
{
  id: Maybe<number> = null
  provider: Maybe<AuthProviderName> = null
  identifier: Maybe<string> = null
  surname: Maybe<string> = null
  name: Maybe<string> = null
  email: Maybe<string> = null
  eulaVersion: Maybe<number> = null
  department?: Maybe<string> = null
  active: Maybe<boolean> = null
  rbacRoles: Maybe<MaybeGrantedRbacRoles[]> = null
  lockedOut: Maybe<boolean> = null

  constructor(data: Partial<EntityUser>) {
    super()
    Object.assign(this, data)
  }

  getRoles(): EntityRbacRole[] {
    const roles = flatMap(
      this.rbacRoles?.map(role => role.node).filter(isDefined)
    )

    return createEntities<RbacRole, EntityRbacRole>(EntityRbacRole, roles)
  }

  /**
   * Return true if the user has been logged via the Tenable provider
   * (not LDAP neither SAML).
   */
  isUserLoggedViaTenableProvider(): boolean {
    return this.provider === AuthProviderName.Tenable
  }

  /* Implements IEntityListable */

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowUser> = []
  ): Array<IWidgetListColumns<IDataRowUser>> {
    const columns: Array<IWidgetListColumns<IDataRowUser>> = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Name',
        key: 'name'
      },
      {
        label: 'Surname',
        key: 'surname'
      },
      {
        label: 'Email',
        key: 'email'
      },
      {
        label: 'Department',
        key: 'department'
      },
      {
        label: 'Authentication type',
        key: 'provider'
      },
      {
        label: 'Roles',
        key: 'rbacRoles'
      },
      {
        label: 'Active',
        key: 'active'
      },
      {
        label: 'LockedOut',
        key: 'lockedOut'
      }
    ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  asDataRow(): IDataRowUser {
    return {
      id: this.getPropertyAsNumber('id'),
      surname: this.getPropertyAsString('surname'),
      name: this.getPropertyAsString('name'),
      email: this.getPropertyAsString('email'),
      department: this.getPropertyAsString('department'),
      provider: this.getPropertyAsString('provider') as AuthProviderName,
      rbacRoles: this.getPropertyAsArrayOf<MaybeGrantedRbacRoles>('rbacRoles'),
      active: this.getPropertyAsBoolean('active'),
      lockedOut: this.getPropertyAsBoolean('lockedOut')
    }
  }
}
