import Tabs from '@app/components-legacy/Tabs/Tabs'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import { useAppTranslator } from '@app/hooks'
import type { TStoreProfileGenericCheckers } from '@app/stores/Management/StoreProfiles/StoreProfileGenericCheckers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleRefineConfigurationClick } from './handlers'

interface IConfigurationMenuProps {
  className?: string
  checkerCodename: GenericCheckerCodename
  storeProfileGenericCheckers: TStoreProfileGenericCheckers
}

const ConfigurationMenu: React.FC<IConfigurationMenuProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Profiles.Configuration']
  })

  const storeMenu =
    props.storeProfileGenericCheckers.getOrSetStoresMenuCheckerSerie(
      props.checkerCodename
    )

  const stores = props.storeProfileGenericCheckers.getStoresProfileCheckerSerie(
    props.checkerCodename
  )

  if (!stores.length) {
    return null
  }

  return (
    <Tabs
      storeMenu={storeMenu}
      previousTabLabel={translate('Previous configuration')}
      nextTabLabel={translate('Next configuration')}
      listAllTabsLabel={translate('List all configurations')}
      newTabLabel={translate('Refine the configuration')}
      onNewTab={handleRefineConfigurationClick(
        props.storeProfileGenericCheckers
      )(props.checkerCodename)}
    />
  )
}

export default observer(ConfigurationMenu)
