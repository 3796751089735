import type { StoreRoot } from '@app/stores'
import StoreBase from '@app/stores/StoreBase'
import { action, computed, makeObservable, observable, toJS } from 'mobx'

interface IStoreInputEmailsOptions {}

/**
 * Store for managing the InputEmails component state
 */
export class StoreInputEmails extends StoreBase<IStoreInputEmailsOptions> {
  private $inputVisible = observable.box<boolean>(false)
  private $inputValue = observable.box<string>('')
  private $editInputIndex = observable.box<number>(-1)
  private $editInputValue = observable.box<string>('')
  private $error = observable.box<string>('')

  private $emails = observable.array<string>([])

  constructor(storeRoot: StoreRoot, options = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /* Action */

  /**
   * split emails string with separators: blank space , ; and new line
   * a la google:
   *    - duplicates are removed without warning
   *    - multiple entries: incorrect emails can be entered and displayed
   *      but will fail global validation if not corrected
   */
  @action
  addEmails(): this {
    this.$error.set('')
    const newEmails = this.inputValue.split(/[,;\s]+/)

    newEmails.forEach(email => {
      if (email && !this.emails.includes(email)) {
        this.setEmails([email, ...this.emails])
      }
    })

    this.setInputVisible(false)
    this.setInputValue('')

    return this
  }

  @action
  editEmail(index: number, email: string): this {
    this.setEditInputIndex(index)
    this.setEditInputValue(email)
    return this
  }

  @action
  removeEmail(removedEmail: string): this {
    this.setEmails(this.emails.filter(email => email !== removedEmail))
    return this
  }

  @action
  updateEmailAtIndex(): this {
    const newEmails = [...this.emails]
    newEmails[this.editInputIndex] = this.editInputValue
    this.setEmails(newEmails)
    this.resetEditEmail()
    return this
  }

  @action
  resetEditEmail(): this {
    this.setEditInputIndex(-1)
    this.setInputValue('')
    return this
  }

  @action
  reset(): this {
    this.$emails.clear()
    this.$inputValue.set('')
    this.$error.set('')
    this.setInputVisible(false)
    return this
  }

  @action
  setInputVisible(value: boolean): this {
    this.$inputVisible.set(value)
    return this
  }

  @action
  setInputValue(value: string): this {
    this.$inputValue.set(value)
    return this
  }

  @action
  setEditInputIndex(value: number): this {
    this.$editInputIndex.set(value)
    return this
  }

  @action
  setEditInputValue(value: string): this {
    this.$editInputValue.set(value)
    return this
  }

  @computed
  get emails(): string[] {
    return toJS(this.$emails)
  }

  @action
  setEmails(emails: string[]): this {
    this.$emails.replace(emails)
    return this
  }

  /* Computed */

  @computed
  get inputVisible(): boolean {
    return this.$inputVisible.get()
  }

  @computed
  get inputValue(): string {
    return this.$inputValue.get()
  }

  @computed
  get editInputIndex(): number {
    return this.$editInputIndex.get()
  }

  @computed
  get editInputValue(): string {
    return this.$editInputValue.get()
  }

  @computed
  get emailError(): string {
    return this.$error.get()
  }
}
