import type EntityAttackSource from '@app/entities/EntityAttackSource'
import type { AttackSourceType, Maybe } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import IoAAttacksTableBodyDataRowSourceDestValue from './IoAAttacksTableBodyDataRowSourceDestValue'
import IoAAttacksTargetIcon from './IoAAttacksTargetIcon'

interface IIoAAttacksTableBodyDataRowSourceProps {
  className?: string
  attackSourceEntity: Maybe<EntityAttackSource>
}

const IoAAttacksTableBodyDataRowSource: React.FC<
  IIoAAttacksTableBodyDataRowSourceProps
> = props => {
  if (!props.attackSourceEntity) {
    return <div />
  }

  const sourceType =
    props.attackSourceEntity.getPropertyAsT<Maybe<AttackSourceType>>('type')

  const sourceHostname =
    props.attackSourceEntity.getPropertyAsString('hostname')

  const sourceIp = props.attackSourceEntity.getPropertyAsString('ip')

  return (
    <IoAAttacksTableBodyDataRowSourceDestValue
      justifyContent="flex-start"
      type="source"
      hostname={sourceHostname}
      ip={sourceIp}
      icon={<IoAAttacksTargetIcon source={sourceType} />}
    />
  )
}

export default IoAAttacksTableBodyDataRowSource
