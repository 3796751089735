import * as React from 'react'
import { ContainerFlex } from '../Container'
import TagValue from './TagValue'
import { TagFlags } from './types'

interface ITagProps {
  label?: string
  value?: string | boolean
  variant?: TagFlags
  labelledBy?: string
}

const Tag: React.FC<ITagProps> = props => {
  const renderItems = () => {
    const items = []

    if (props.label !== undefined) {
      const label = props.value !== undefined ? `${props.label}:` : props.label

      items.push(<TagValue variant={props.variant}>{label}</TagValue>)
    }

    if (props.value !== undefined) {
      const variant = props.variant || TagFlags.None

      items.push(
        <TagValue variant={variant | TagFlags.White}>
          {String(props.value)}
        </TagValue>
      )
    }

    return items
  }

  return (
    <TagValue variant={props.variant}>
      <ContainerFlex
        name="Tag"
        items={renderItems()}
        labelledBy={props.labelledBy}
      />
    </TagValue>
  )
}

export default Tag
