import { ContainerSimple } from '@app/components-legacy/Container'
import type { RadioProps } from 'antd'
import { Radio } from 'antd'
import * as React from 'react'
import type { IFormWrapperControl } from './types'

interface IFormWrapperRadioProps extends IFormWrapperControl {
  checkboxProps: RadioProps
}

const FormWrapperRadio: React.FC<IFormWrapperRadioProps> = props => {
  return (
    <ContainerSimple
      name={`${FormWrapperRadio.name}-${props.labelledBy}`}
      ariaRoles={props.ariaRoles || ['form', 'radio']}
      labelledBy={props.labelledBy}
    >
      <Radio {...props.checkboxProps}>{props.children}</Radio>
    </ContainerSimple>
  )
}

export default FormWrapperRadio
