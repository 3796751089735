import { ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { HorizontalLine } from '@app/components/Separator'
import { consts } from '@app/styles'
import { FontColorV2 } from '@app/styles/consts'
import { filterFalsies } from '@libs/filterFalsies'
import * as React from 'react'
import styled from 'styled-components'
import { usePaladinTheme } from 'tenable-design-system-alt'
import { LabelVariant } from '../Labels/types'
import type { MenuVariant } from '../types'

const StyledHorizontalLine = styled(HorizontalLine)<{ expanded?: boolean }>`
  min-width: ${props =>
    props.expanded
      ? 0
      : props.theme.tokens['mainMenu/menuEntry/width/default']};
  width: initial;
  transition: width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
`

interface IMenuTitleProps {
  className?: string
  label: string
  expanded?: boolean
  variant?: MenuVariant
}

const MenuTitle: React.FC<IMenuTitleProps> = props => {
  const theme = usePaladinTheme()

  const shouldDisplayLine = !(props.expanded && props.variant === 'current')
  const color =
    props.variant === 'current'
      ? theme.color.colorBorderPrimary
      : FontColorV2.secondary

  return (
    <ContainerFlex
      name="MenuTitle"
      className={props.className}
      alignItems="center"
      itemsFlexShrink={[0, 1]}
      itemsFlexGrow={[1, 0]}
      direction="row-reverse"
      items={filterFalsies([
        shouldDisplayLine && (
          <StyledHorizontalLine color={color} expanded={props.expanded} />
        ),
        <Label
          variant={
            props.variant === 'current'
              ? LabelVariant.menuTitle
              : LabelVariant.legacyMenuTitle
          }
        >
          {props.label}
        </Label>
      ])}
      spaced
      spaceWidth="small"
    />
  )
}

export default styled(MenuTitle)`
  display: flex;
  width: ${props =>
    props.expanded
      ? props.theme.tokens['mainMenu/menuEntry/width/expanded']
      : props.theme.tokens['mainMenu/menuEntry/width/default']};
  margin-top: ${consts.marginVerySmall};
  overflow: hidden;
  white-space: nowrap;
  transition: width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
`
