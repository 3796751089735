import type StoreFormGroups from '@app/stores/helpers/StoreFormGroups.ts'
import type { CreateGroupFn } from '@app/stores/helpers/StoreFormGroups.ts/types'

export const onFormGroupsAddClick =
  <G>(storeFormGroups: StoreFormGroups<G>) =>
  (createGroupFn: CreateGroupFn<G>) =>
  () => {
    storeFormGroups.addGroup(createGroupFn)
  }

export const onFormGroupsRemoveClick =
  <G>(storeFormGroups: StoreFormGroups<G>) =>
  (index: number) =>
  () => {
    storeFormGroups.removeGroup(index)
  }
