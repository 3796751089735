import type { MaybeUndef } from '@@types/helpers'
import type { IAttackPathTier0AccountsRouteDefinition } from '@app/routes'
import { AppRouteName } from '@app/routes'
import type { StoreAttackPathTier0 } from '@app/stores'
import { isDefined } from '@libs/isDefined'
import { debounce } from 'lodash'

// Tier0

export const handleAttackPathTier0Load =
  (storeAttackPathTier0: StoreAttackPathTier0) => () => {
    storeAttackPathTier0.storeInfrastructures.fetchInfrastructures()
  }

export const handleAttackPathTier0Unload =
  (storeAttackPathTier0: StoreAttackPathTier0) => () => {
    storeAttackPathTier0.reset()
  }

// Tier 0 assets

export const handleAttackPathTier0AssetsLoad =
  (storeAttackPathTier0: StoreAttackPathTier0) => () => {
    storeAttackPathTier0.fetchTier0Objects(
      storeAttackPathTier0.storeInfrastructures.selectedDirectoryIds,
      1
    )
  }

export const handleAttackPathTier0AssetsUnload =
  (storeAttackPathTier0: StoreAttackPathTier0) => () => {
    storeAttackPathTier0.resetTier0Assets()
  }

export const handleAttackPathTier0AssetsDirectoryChange =
  (storeAttackPathTier0: StoreAttackPathTier0) => () => {
    storeAttackPathTier0.fetchTier0Objects(
      storeAttackPathTier0.storeInfrastructures.selectedDirectoryIds,
      1
    )
  }

export const handleAttackPathTier0AssetsPaginationChange =
  (storeAttackPathTier0: StoreAttackPathTier0) =>
  (page: number, perPage: MaybeUndef<number>) => {
    storeAttackPathTier0.fetchTier0Objects(
      storeAttackPathTier0.storeInfrastructures.selectedDirectoryIds,
      page,
      perPage
    )
  }

// Accounts with tier 0 attack path

export const handleAccountsWithTier0AttackPathLoad =
  (storeAttackPathTier0: StoreAttackPathTier0) => () => {
    const { appRouter } = storeAttackPathTier0.storeRoot

    const queryStringParameters =
      appRouter.getCurrentRouteQueryStringParameters<
        IAttackPathTier0AccountsRouteDefinition['queryStringParameters']
      >()

    let assetFilter =
      storeAttackPathTier0.accountsWithTier0AttackPathAssetFilter

    if (
      queryStringParameters &&
      isDefined(queryStringParameters.tier0AssetId)
    ) {
      assetFilter = Number(queryStringParameters.tier0AssetId)

      storeAttackPathTier0.setAccountsWithTier0AttackPathAssetFilter(
        assetFilter
      )

      appRouter.history.replace(
        appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.AttackPath_Tier0_Accounts,
          parameters: {}
        })
      )
    }

    storeAttackPathTier0.fetchAccountsWithTier0AttackPath(
      storeAttackPathTier0.storeInputSearchAccountsWithTier0AttackPath
        .searchValue,
      storeAttackPathTier0.storeInfrastructures.selectedDirectoryIds,
      assetFilter,
      1
    )

    storeAttackPathTier0.fetchAllTier0Objects(
      storeAttackPathTier0.storeInfrastructures.selectedDirectoryIds
    )
  }

export const handleAccountsWithTier0AttackPathUnload =
  (storeAttackPathTier0: StoreAttackPathTier0) => () => {
    storeAttackPathTier0.resetAccountsWithTier0AttackPath()
  }

export const handleAccountsWithTier0AttackPathFilterChange =
  (storeAttackPathTier0: StoreAttackPathTier0) => () => {
    storeAttackPathTier0.fetchAccountsWithTier0AttackPath(
      storeAttackPathTier0.storeInputSearchAccountsWithTier0AttackPath
        .searchValue,
      storeAttackPathTier0.storeInfrastructures.selectedDirectoryIds,
      storeAttackPathTier0.accountsWithTier0AttackPathAssetFilter,
      1
    )
  }

export const handleAccountsWithTier0AttackPathDirectoryChange =
  (storeAttackPathTier0: StoreAttackPathTier0) => () => {
    handleAccountsWithTier0AttackPathFilterChange(storeAttackPathTier0)()

    storeAttackPathTier0.fetchAllTier0Objects(
      storeAttackPathTier0.storeInfrastructures.selectedDirectoryIds
    )
  }

export const handleAccountsWithTier0AttackPathSearchChange = (
  storeAttackPathTier0: StoreAttackPathTier0
) =>
  debounce(
    handleAccountsWithTier0AttackPathFilterChange(storeAttackPathTier0),
    500
  )

export const handleAccountsWithTier0AttackPathAssetChange =
  (storeAttackPathTier0: StoreAttackPathTier0) =>
  (objectId: number): void => {
    storeAttackPathTier0.setAccountsWithTier0AttackPathAssetFilter(objectId)
    handleAccountsWithTier0AttackPathFilterChange(storeAttackPathTier0)()
  }

export const handleAccountsWithTier0AttackPathPaginationChange =
  (storeAttackPathTier0: StoreAttackPathTier0) =>
  (page: number, perPage: MaybeUndef<number>) => {
    storeAttackPathTier0.fetchAccountsWithTier0AttackPath(
      storeAttackPathTier0.storeInputSearchAccountsWithTier0AttackPath
        .searchValue,
      storeAttackPathTier0.storeInfrastructures.selectedDirectoryIds,
      storeAttackPathTier0.accountsWithTier0AttackPathAssetFilter,
      page,
      perPage
    )
  }
