import type { Maybe } from '@@types/helpers'
import { LabelColorTag } from '@app/components-legacy/Label/index'
import { useAppTranslator } from '@app/hooks'
import { consts } from '@app/styles'
import * as React from 'react'

export interface ILabelComplianceProps {
  enabled: Maybe<boolean>
  compliant: Maybe<boolean>
}

const LabelCompliance: React.FC<ILabelComplianceProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Components.LabelCompliance']
  })

  const getColor = () => {
    if (props.enabled === false) {
      return consts.colorBlueGray010
    }

    if (props.compliant === undefined) {
      return consts.colorBlueGray005
    }

    return [consts.colorRed001, consts.colorGreen020][Number(props.compliant)]
  }

  const getLabel = () => {
    if (props.enabled === false) {
      return translate('Inactive')
    }

    if (props.compliant === undefined) {
      return translate('Unknown')
    }

    return [translate('Not compliant'), translate('Compliant')][
      Number(props.compliant)
    ]
  }

  return <LabelColorTag color={getColor()} label={getLabel()} />
}

export default LabelCompliance
