import { ContainerFlex } from '@app/components/Container'
import { AppMenu } from '@app/components-legacy/Menu'
import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import { useAppTranslator, useStores } from '@app/hooks'
import {
  canAccessToHealthCheckDomainStatus,
  canAccessToHealthCheckPlatformStatus
} from '@app/pages/HealthCheck/permissions'
import { AppRouteName } from '@app/routes'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { filterFalsies } from '@libs/filterFalsies'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import HealthCheckMenuButtons from './HealthCheckMenuButtons'

export enum MenuHealthCheckKey {
  DomainStatus = 'DomainStatus',
  PlatformStatus = 'PlatformStatus'
}

export interface IHealthCheckMenuProps {
  className?: string
  style?: React.CSSProperties
}

function HealthCheckMenu(props: IHealthCheckMenuProps) {
  const { storeRbac } = useStores()

  const translate = useAppTranslator({
    namespaces: ['HealthCheck.Menu']
  })

  const storeMenu = useStoreMenuInit<IMenuEntry<MenuHealthCheckKey>>({
    menuEntries: filterFalsies([
      storeRbac.isUserGrantedTo(canAccessToHealthCheckDomainStatus) && {
        key: MenuHealthCheckKey.DomainStatus,
        label: translate('Domain status'),
        labelledBy: 'Domain status',
        routeDefinition: {
          routeName: AppRouteName.HealthCheck_DomainStatus,
          parameters: {}
        }
      },
      storeRbac.isUserGrantedTo(canAccessToHealthCheckPlatformStatus) && {
        key: MenuHealthCheckKey.PlatformStatus,
        label: translate('Platform status'),
        labelledBy: 'Platform status',
        routeDefinition: {
          routeName: AppRouteName.HealthCheck_PlatformStatus,
          parameters: {}
        }
      }
    ])
  })

  return (
    <ContainerFlex
      name="HealthCheckMenu"
      itemsFlexGrow={[1, 0]}
      alignItems="stretch"
      justifyContent="space-between"
      items={[
        <AppMenu
          className={props.className}
          style={props.style}
          storeMenu={storeMenu}
        />,
        <HealthCheckMenuButtons />
      ]}
    />
  )
}

export default observer(HealthCheckMenu)
