import type { Query } from '../typeDefs/types'

/**
 * Retrieve SYSLOG alerts
 */
export type QuerySyslogs = Pick<Query, 'rbacSyslogs'>

export const querySyslogs = `
  query querySyslogs(
    $syslogsPage: Int!
    $syslogsPerPage: Int!
  ) {
    rbacSyslogs(
      syslogsPage: $syslogsPage
      syslogsPerPage: $syslogsPerPage
    ) {
      node {
        node {
          id
          ip
          port
          protocol
          tls
          criticityThreshold
          directories {
            id
          }
          filterExpression
          description
          shouldNotifyOnInitialFullSecurityCheck
          inputType
          profiles
          checkers
          attackTypes
          relayId
          healthCheckNames
        }
        pagination {
          page
          perPage
          totalCount
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Retrieve Email alerts
 */
export type QueryEmails = Pick<Query, 'rbacEmails'>

export const queryEmails = `
  query queryEmails(
    $emailsPage: Int!
    $emailsPerPage: Int!
  ) {
    rbacEmails(
      emailsPage: $emailsPage
      emailsPerPage: $emailsPerPage
    ) {
      node {
        node {
          id
          address
          criticityThreshold
          directories {
            id
          }
          description
          shouldNotifyOnInitialFullSecurityCheck
          profiles
          inputType
          checkers
          attackTypes
          healthCheckNames
        }
        pagination {
          page
          perPage
          totalCount
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
