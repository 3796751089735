import { LabelColorTag } from '@app/components/Labels'
import React from 'react'
import { colorLogUserCreationDeletionRedesign } from '../consts'

export const UserLogUsername = (props: {
  userName?: string
  userSurname?: string
}) => {
  return (
    <LabelColorTag
      className="traceUserNameSurname"
      color={colorLogUserCreationDeletionRedesign.background}
      labelProperties={{
        color: colorLogUserCreationDeletionRedesign.text,
        labelledBy: 'nameAndSurname'
      }}
    >
      {props.userName} {props.userSurname}
    </LabelColorTag>
  )
}
