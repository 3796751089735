import type { MaybeUndef } from '@@types/helpers'
import { useEventListener } from '@app/hooks/useEventListener'
import type { KeyboardKey } from '@libs/KeyboardBindingsManager/types'
import React from 'react'

/**
 * Call the handler function when a key is pressed while the focus is on the
 * referenced dom element.
 * The hook registers a keypress event listener on the ref element.
 */
export function useKey<T extends HTMLElement>(
  key: KeyboardKey | KeyboardKey[],
  handler: MaybeUndef<(e: Event | React.SyntheticEvent) => void>
): {
  ref?: React.RefCallback<T>
} {
  const handleKeyPress = React.useCallback<(e: KeyboardEvent) => void>(e => {
    if (!handler) {
      return
    }
    if (Array.isArray(key)) {
      if (key.find(k => k === e.code)) {
        handler(e)
      }
      return
    }
    if (e.code === key) {
      handler(e)
    }
  }, [])

  return useEventListener('keypress', handleKeyPress)
}
