import type { MaybeUndef } from '@@types/helpers'
import { ContainerFlex } from '@app/components/Container'
import { IconLeftAntd, IconRightAntd } from '@app/components/Icon'
import WidgetListPaginationJumpNextButton from '@app/components/Widgets/WidgetList/WidgetListPaginationJumpNextButton'
import WidgetListPaginationJumpPreviousButton from '@app/components/Widgets/WidgetList/WidgetListPaginationJumpPreviousButton'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import { consts } from '@app/styles'
import { Pagination } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

export interface IWidgetListPaginationProps {
  className?: string
  style?: React.CSSProperties
  itemRender?: (
    page: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    originalElement: React.ReactElement<HTMLElement>
  ) => React.ReactNode
  onChange: (page: number, perPage: MaybeUndef<number>) => void
  storeWidgetList: StoreWidgetList<any, any>
}

const WidgetListPagination: React.FC<IWidgetListPaginationProps> = props => {
  if (!props.storeWidgetList.paginationTotalCount) {
    return null
  }

  const {
    paginationPage,
    rowsPerPage: paginationPerPage,
    paginationTotalCount
  } = props.storeWidgetList

  return (
    <ContainerFlex
      name={WidgetListPagination.name}
      justifyContent="flex-end"
      className={props.className}
      style={props.style}
      items={[
        <Pagination
          defaultCurrent={1}
          current={paginationPage}
          pageSize={paginationPerPage}
          total={paginationTotalCount}
          onChange={props.onChange}
          itemRender={(page, type, originalElement) => {
            switch (type) {
              case 'jump-prev':
                return <WidgetListPaginationJumpPreviousButton />

              case 'jump-next':
                return <WidgetListPaginationJumpNextButton />

              case 'prev':
                return (
                  <FormWrapperButton
                    labelledBy={`page-previous`}
                    variant={ButtonVariant.colored}
                    size={ButtonSize.small}
                    square
                    icon={IconLeftAntd}
                    buttonProps={{
                      disabled: paginationPage === 1
                    }}
                  />
                )

              case 'next':
                return (
                  <FormWrapperButton
                    labelledBy={`page-next`}
                    variant={ButtonVariant.colored}
                    size={ButtonSize.small}
                    square
                    icon={IconRightAntd}
                    buttonProps={{
                      disabled:
                        paginationPage * paginationPerPage >=
                        paginationTotalCount
                    }}
                  />
                )

              case 'page':
                return (
                  <FormWrapperButton
                    labelledBy={`page-${page}`}
                    variant={
                      page === paginationPage
                        ? ButtonVariant.primary
                        : ButtonVariant.colored
                    }
                    size={ButtonSize.small}
                    square
                  >
                    {page}
                  </FormWrapperButton>
                )

              default:
                return originalElement
            }
          }}
          showSizeChanger={false}
        />
      ]}
    />
  )
}

const ObservedWidgetListPagination = observer(WidgetListPagination)

export default styled(ObservedWidgetListPagination)`
  .ant-pagination-item {
    background-color: transparent;
  }

  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    margin-right: ${consts.marginVerySmall};
  }

  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    height: 30px;
    min-height: 30px;
    width: 30px;
    min-width: 30px;
    line-height: 30px;
    border: 0;
  }

  .ant-pagination-item-active,
  .ant-pagination-item-active:hover {
    border: 0;
  }
`
