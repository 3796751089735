import type { Maybe } from '@@types/helpers'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import * as React from 'react'
import { ContainerSimple } from '../Container'

export interface ILabelAboutProps {
  className?: string
  version: Maybe<string>
  showPrefix?: boolean
}

const LabelAbout: React.FC<ILabelAboutProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Components.LabelAbout'] })

  if (props.showPrefix === true) {
    return (
      <ContainerSimple name={LabelAbout.name} className={props.className}>
        <span>
          {translate('Version')}
          {': '}
        </span>

        <ContainerSimple labelledBy="productVersion" inline>
          {props.version || translate('Unknown version')}
        </ContainerSimple>
      </ContainerSimple>
    )
  }

  return (
    <ContainerSimple
      name={LabelAbout.name}
      className={props.className}
      labelledBy="productVersion"
    >
      {props.version || translate('Unknown version')}
    </ContainerSimple>
  )
}

export default LabelAbout
