import type { Perhaps } from '@@types/helpers'
import type { StoreInfrastructures } from '@app/stores'
import { If } from '@libs/fp-helpers/If'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import type { TreeProps } from 'antd/lib/tree'

/**
 * Load infrastructures at mount or not.
 */
export const handleInputInfrastructuresOnLoad =
  (storeInfrastructures: StoreInfrastructures) =>
  (loadAtMount: Perhaps<boolean>) =>
  () => {
    if (!loadAtMount) {
      return
    }

    If(!storeInfrastructures.infrastructures.size).fetch(() =>
      storeInfrastructures.fetchInfrastructures()
    )
  }

/**
 * Open the drawer of the directories selection.
 */
export const handleSelectDirectoriesButtonOnClick =
  (storeInfrastructures: StoreInfrastructures) => () => {
    storeInfrastructures.storeDrawer.openDrawer()
  }

/**
 * When searching an infrastructore or a domain, expand all infrastructures
 * and unselect directories.
 *
 * The user have to select again once it has finished his search.
 */
export const handleInfrastructureOrDomainOnSearch =
  (storeInfrastructures: StoreInfrastructures) => () => {
    storeInfrastructures.expandAllInfrastructures()
    storeInfrastructures.unselectAllDirectories()
  }

/**
 * Select or unselect all the directories of all infrastructures.
 */
export const handleSelectAllDirectoriesOnClick =
  (storeInfrastructures: StoreInfrastructures) => (e: CheckboxChangeEvent) => {
    e.target.checked
      ? storeInfrastructures.selectAllDirectories()
      : storeInfrastructures.unselectAllDirectories()
  }

/**
 * Expand or collapse all infrastructures.
 */
export const handleExpandAllInfrastructuresOnClick =
  (storeInfrastructures: StoreInfrastructures) => (e: CheckboxChangeEvent) => {
    e.target.checked
      ? storeInfrastructures.expandAllInfrastructures()
      : storeInfrastructures.collapseAllInfrastructures()
  }

/**
 * Expand of collapse the infrastructures tree.
 */
export const handleInfrastructureExpandOnClick =
  (storeInfrastructures: StoreInfrastructures) =>
  (expandedKeys: TreeProps['expandedKeys']) => {
    if (!Array.isArray(expandedKeys)) {
      return
    }
    storeInfrastructures.toggleExpandInfrastructure(
      expandedKeys.map(k => Number(k))
    )
  }

/**
 * Select or unselect an infrastructure when clicking on the checkbox or the label.
 */
export const handleInfrastructureOnSelect =
  (storeInfrastructures: StoreInfrastructures) =>
  (infrastructureId: number) =>
  () => {
    storeInfrastructures.toggleSelectInfrastructure(infrastructureId)
  }

/**
 * Select or unselect a directory when clicking on the checkbox or the label.
 */
export const handleDirectoryOnSelect =
  (storeInfrastructures: StoreInfrastructures) =>
  (directoryId: number) =>
  () => {
    storeInfrastructures.toggleSelectDirectory(directoryId)
  }

/**
 * Validate the directories selection.
 */
export const handleDirectoriesSelectionOnSubmit =
  (
    storePendingInfrastructures: StoreInfrastructures,
    storeInfrastructures: StoreInfrastructures
  ) =>
  (onChange?: () => void) =>
  () => {
    storeInfrastructures.replaceSelectedDirectories(
      storePendingInfrastructures.selectedDirectoriesMap
    )
    storeInfrastructures.storeDrawer.closeDrawer()

    if (onChange) {
      onChange()
    }
  }
