import type { Maybe } from '@@types/helpers'
import type { TIStoreProfileCheckerSerieOptions } from '../types'

/**
 * Return true if the serie is for all directoryIds.
 */
export function isSerieForAllDirectories(
  options: Maybe<TIStoreProfileCheckerSerieOptions>
): boolean {
  if (!options) {
    return false
  }

  return options.directoryIds.every(d => d === null)
}
