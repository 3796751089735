import { IconPNG } from '@app/components-legacy/Icon'
import { MainMenuKey } from '@app/pages/MainMenu/types'
import { getCriticityColor } from '@libs/criticity'
import { CheckerType, Criticity } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'
import AlertTriangleIcon from './AlertTriangleIcon'

interface IAlertCheckerIconProps {
  checkerType: CheckerType
  criticity: Criticity
}

const IconPNGCheckerType = styled(IconPNG)`
  position: absolute;
  top: 5px;
  left: 17px;
  transform: rotate(45deg);
`

const AlertCheckerIcon: React.FC<IAlertCheckerIconProps> = props => {
  const color = getCriticityColor(props.criticity || Criticity.Unknown)

  const renderCheckerIcon = () => {
    const icon =
      props.checkerType === CheckerType.Exposure
        ? MainMenuKey.ioeWhite
        : MainMenuKey.ioaWhite

    return (
      <div style={{ color }}>
        <div>C</div>
        <IconPNGCheckerType
          iconName={`icons/navigation/${icon}`}
          labelledBy="alert"
        />
      </div>
    )
  }

  return (
    <AlertTriangleIcon color={color}>{renderCheckerIcon()}</AlertTriangleIcon>
  )
}

export default AlertCheckerIcon
