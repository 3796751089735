import { ContainerFlex } from '@app/components-legacy/Container'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import AlertActionsButton from './AlertActionsButton'
import AlertDirectories from './AlertDirectories'
import type { ILabelAlertProps } from './types'

interface IAlertFooterProps {
  className?: string
  directories: ILabelAlertProps['directories']
  actions: ILabelAlertProps['actions']
  archived: ILabelAlertProps['archived']
  onAction: ILabelAlertProps['onAction']
}

const AlertFooter: React.FC<IAlertFooterProps> = props => {
  return (
    <ContainerFlex
      name="AlertFooter"
      className={props.className}
      justifyContent="space-between"
      alignItems="center"
      items={[
        <AlertDirectories directories={props.directories} />,

        <AlertActionsButton
          actions={props.actions}
          archived={props.archived}
          onAction={props.onAction}
        />
      ]}
    />
  )
}

export default styled(AlertFooter)`
  background-color: ${consts.colorWhiteAlt};
  padding: ${consts.paddingSmall};
`
