import type { InstanceName } from '@libs/Environment/types'
import { CheckerType } from '@server/graphql/typeDefs/types'
import StoreForm from '../helpers/StoreForm'
import StoreInputGenericCheckers from '../helpers/StoreInputGenericCheckers'
import StoreBase from '../StoreBase'
import StoreInfrastructures from '../StoreInfrastructures'
import type { IStoreMultiInstanceOptions } from '../types'

export interface IStoreWidgetSerieOptions extends IStoreMultiInstanceOptions {}

export default class StoreWidgetSerie extends StoreBase<IStoreWidgetSerieOptions> {
  public storeForm = new StoreForm(this.storeRoot)

  public storeInputCheckersExposure = new StoreInputGenericCheckers<any>(
    this.storeRoot,
    {
      instanceName: this.options.instanceName,
      checkerType: CheckerType.Exposure,
      selectable: true
    }
  )

  public storeInfrastructures = new StoreInfrastructures(
    this.storeRoot,
    this.options
  )

  /**
   * Set the instanceName used for this store and composed ones.
   */
  setInstanceName(instanceName: InstanceName): void {
    this.setOptions({
      instanceName
    })

    this.storeInputCheckersExposure.setOptions({
      instanceName
    })

    this.storeInfrastructures.setOptions({
      instanceName
    })
  }
}
