import type StoreInputExpression from '@app/stores/helpers/StoreInputExpression'

export const onInputExpressionChange =
  (storeInputExpression: StoreInputExpression) => (value: string) => {
    storeInputExpression.setEntry(value)
  }

export const onInputExpressionClearIconClick =
  (storeInputExpression: StoreInputExpression) =>
  (onClearIconClick?: () => void) =>
  () => {
    storeInputExpression.clearEntry()

    if (onClearIconClick) {
      onClearIconClick()
    }
  }

export const onInputExpressionSubmit =
  (storeInputExpression: StoreInputExpression) =>
  (onValidate?: () => void) =>
  () => {
    // if the validation has failed, don't call the handler
    if (!storeInputExpression.validateEntry()) {
      return
    }

    if (onValidate) {
      onValidate()
    }
  }

export const onInputExpressionBlur =
  (storeInputExpression: StoreInputExpression) => () =>
    storeInputExpression.setFocus(false)

export const onInputExpressionFocus =
  (storeInputExpression: StoreInputExpression) =>
  (onFocus?: () => void) =>
  () => {
    storeInputExpression.setFocus(true)

    if (onFocus) {
      onFocus()
    }
  }

export const onInputExpressionWizardIconClick =
  (storeInputExpression: StoreInputExpression) =>
  (isDisabled: boolean) =>
  () => {
    if (!isDisabled) {
      storeInputExpression.storeDrawer.openDrawer()
    }
  }

/**
 * Switch to edition when clicking on a tag in the expression.
 */
export const onTagExpressionClick =
  (storeInputExpression: StoreInputExpression) =>
  (onFocus?: () => void) =>
  () => {
    onInputExpressionFocus(storeInputExpression)(onFocus)()

    storeInputExpression.setEntry('')
  }
