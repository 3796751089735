import type { Maybe } from '@@types/helpers'
import type { TContainerFlexJustifyContent } from '@app/components/Container/ContainerFlex/types'
import type { IDENTIFIER_COLUMN_NAME } from '@app/components-legacy/TableNew/consts'
import type * as React from 'react'

type WithIdentifier<T> = T & {
  [IDENTIFIER_COLUMN_NAME]: number | string
}

export type TableInterfaceType = {
  [key: string]: any | React.ReactNode
}

interface IBaseTableColumn<T extends TableInterfaceType, P extends keyof T> {
  name?: string
  isBeta?: boolean
  hidden?: boolean
  sorter?: Maybe<ITableColumnSorter>
  justifyContent?: TContainerFlexJustifyContent
  headerCentered?: boolean
}

interface IBaseTableColumnWithFixedWidth<
  T extends TableInterfaceType,
  P extends keyof T
> extends IBaseTableColumn<T, P> {
  fixedWidth: number
  relativeWidth?: undefined
}

interface IBaseTableColumnWithRelativeWidth<
  T extends TableInterfaceType,
  P extends keyof T
> extends IBaseTableColumn<T, P> {
  fixedWidth?: undefined
  relativeWidth?: number
}

export type TableColumnProperties<
  T extends TableInterfaceType,
  P extends keyof T
> = (
  | IBaseTableColumnWithFixedWidth<T, P>
  | IBaseTableColumnWithRelativeWidth<T, P>
) &
  (T[P] extends React.ReactNode
    ? { render?: (value: T[P], availableWidth: number) => React.ReactNode }
    : { render: (value: T[P], availableWidth: number) => React.ReactNode })

export type TableColumnsProperties<T extends TableInterfaceType> = {
  [P in keyof Required<T>]: TableColumnProperties<T, P>
}

export type TableRowData<T extends TableInterfaceType> = {
  [P in keyof Required<WithIdentifier<T>>]: WithIdentifier<T>[P]
}

export interface ITableColumnSorter {
  sortingOrder?: `${TableColumnOrder}` // use enum values as type to allow plain text strings
  sorterFn: () => void
}

export enum TableColumnOrder {
  Asc = 'asc',
  Desc = 'desc'
}
