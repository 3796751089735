import type { Maybe } from '@@types/helpers'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  readAttackTypes,
  readLicenseFeatureIoA,
  readUIIoA,
  readUiIoaEditCard,
  readUiIoaExport,
  readUIIoATimeline
} from '@libs/rbac/permissions'

export const canAccessToIoABoard = rbacCapabilityCheckAllOf(
  readUIIoA(),
  readLicenseFeatureIoA()
)

export const canAccessToIoATimeline = rbacCapabilityCheckAllOf(
  canAccessToIoABoard,
  readUIIoATimeline()
)

export const canAccessToIoAExport = rbacCapabilityCheckAllOf(
  canAccessToIoABoard,
  readUiIoaExport()
)

export const canAccessToIoAEditCard = rbacCapabilityCheckAllOf(
  canAccessToIoABoard,
  readUiIoaEditCard()
)

export const canAccessToIoADetails = (attackTypeId: Maybe<number>) => {
  return rbacCapabilityCheckAllOf(
    canAccessToIoABoard,
    readAttackTypes(attackTypeId)
  )
}
