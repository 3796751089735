import * as React from 'react'

/**
 * Used to create a colored background behind a svg text.
 */
export const renderSvgLabelFilter = (id: string, color: string) => (
  <filter id={id} x="-10%" width="120%" y="-20%" height="140%">
    <feFlood floodColor={color} />
    <feGaussianBlur stdDeviation="4" />
    <feComponentTransfer>
      <feFuncA type="table" tableValues="0 0 0 1" />
    </feComponentTransfer>

    <feComponentTransfer>
      <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1" />
    </feComponentTransfer>
    <feComposite operator="over" in="SourceGraphic" />
  </filter>
)
