import type { Maybe, MaybeUndef } from '@@types/helpers'
import type { ConfigServer } from '@libs/config/types'
import type { IExpressionDefinition } from '@libs/Expression/types'
import type {
  AlertIoE,
  Attack,
  CrawlingStatus,
  DataCollectionNotWorkingReason,
  Deviance,
  Event,
  HoneyAccountStatus,
  Language
} from '@server/graphql/typeDefs/types'
import type { IWSFeatureFlagRegistration } from '@server/routers/WSToggleRouter/types'

export type ServiceLiveConfig = ConfigServer['instances'][0]['services']['live']

// list of all available entities
export enum WSEntityName {
  alertIoE = 'alertIoE',
  alertIoA = 'alertIoA',
  event = 'event',
  deviance = 'deviance',
  attack = 'attack',
  apiInspector = 'apiInspector',
  featureFlags = 'featureFlags',
  directory = 'directory',
  directoryConnectivityStatus = 'directory-connectivity-status',
  dataCollectionStatus = 'data-collection-status'
}

// command name for the subscription / unsubscription
export enum WSCommandName {
  register = 'register',
  unregister = 'unregister'
}

// friendly name for the registration
export type WSRegistrationName = string

// identifier of the "communication" channel
export type WSChannelUuid = string

// registration that "links" a message, a channel and a handler to execute
export interface IWSRegistration<P> {
  name: WSRegistrationName
  channelUuid: WSChannelUuid
  entity: WSEntityRegistration
  handler: (payload: P) => void
}

// command (command name + registration) to prepare the message to send to the server
export interface IWSCommand extends IWSRegistration<any> {
  command: WSCommandName
}

// message received from the server
export interface IWSMessageData<P = WSEntityMessage['payload']> {
  entity: WSEntityName
  payload: P
  // payload extended server side by the WS proxy middleware
  extendedPayload?: WSEntityMessage['extendedPayload']
  channelUuid: WSChannelUuid
}

export interface IWSMessageError {
  error: string
}

export interface IWSMessageStatus {
  message: string
}

// message send to the server
export interface IWSMessage {
  command: IWSCommand['command']
  channelUuid: IWSCommand['channelUuid']
  entity: IWSCommand['entity']['name']
  payload: WSEntityRegistration['payload']
}

/* # List of entities */

// union of all payloads send to the server
export type WSEntityRegistration =
  | IWSAlertIoERegistration
  | IWSAlertIoARegistration
  | IWSEventRegistration
  | IWSDevianceRegistration
  | IWSAttackRegistration
  | IWSAPIInspectorRegistration
  | IWSFeatureFlagRegistration
  | IWSDirectoryRegistration
  | IWSDirectoryConnectivityRegistration
  | IWSDataCollectionStatusRegistration

// union of all payloads received from the server
export type WSEntityMessage =
  | IWSAlertIoEMessage
  | IWSAlertIoAMessage
  | IWSEventMessage
  | IWSDevianceMessage
  | IWSAttackMessage
  | IWSAPIInspectorMessage
  | IWSDirectoryMessage
  | IWSDirectoryConnectivityMessage
  | IWSDataCollectionStatusMessage

/* ## AlertIoE entity */

export interface IWSAlertIoERegistration {
  name: WSEntityName.alertIoE
  payload: {
    profileId: number
  }
}

export interface IWSAlertIoEMessage {
  name: WSEntityName.alertIoE
  payload: {
    id: number
    profileId: number
    infrastructureId: number
    directoryId: number
    devianceId: number
    archived: boolean
    read: boolean
    date: string
  }
  // extended payload by the WS proxy middleware
  extendedPayload?: AlertIoE
}

/* ## AlertIoA entity */

export interface IWSAlertIoARegistration {
  name: WSEntityName.alertIoA
  payload: {
    profileId: number
    language: Language
  }
}

export interface IWSAlertIoAMessage {
  name: WSEntityName.alertIoA
  payload: {
    id: number
    profileId: number
    date: string
    directoryId: number
    attackTypeId: number
    archived: boolean
    read: boolean
  }
  // extended payload by the WS proxy middleware
  extendedPayload?: null
}

/* ## Event entity */

export interface IWSEventRegistration {
  name: WSEntityName.event
  payload: {
    profileId: number
    directories: number[]
    expression: IExpressionDefinition
  }
}

export interface IWSEventMessage {
  name: WSEntityName.event
  payload: {
    id: number
    directoryId: number
    adObjectId: number
    date: string
  }
  // extended payload by the WS proxy middleware
  extendedPayload?: Event
}

/* ## Deviance entity */

export interface IWSDevianceRegistration {
  name: WSEntityName.deviance
  payload: {
    profileId: number
    directories: number[]
    expression: IExpressionDefinition
  }
}

export interface IWSDevianceMessage {
  name: WSEntityName.deviance
  payload: {
    id: number
    directoryId: number
    checkerId: number
    profileId: number
    adObjectId: number
    reasonId: number
    createdEventId: number
    resolvedEventId: MaybeUndef<number>
    eventDate: string
    resolvedAt: MaybeUndef<string>
    ignoreUntil: MaybeUndef<string>
  }
  // extended payload by the WS proxy middleware
  extendedPayload?: Deviance
}

/* ## Attack entity */

export interface IWSAttackRegistration {
  name: WSEntityName.attack
  payload: {
    language: Language
  }
}

export interface IWSAttackMessage {
  name: WSEntityName.attack
  payload: {
    action: 'add' | 'update'
    attack: Attack
  }
  // payload extended server side by the WS proxy middleware
  extendedPayload?: null
}

/* ## APIInspector entity */

export interface IWSAPIInspectorRegistration {
  name: WSEntityName.apiInspector
  payload: {}
}

export interface IWSAPIInspectorMessage {
  name: WSEntityName.apiInspector
  payload: {
    id: number
    date: string
    url: string
    statusCode: number
    method?: string
    bodyPayload?: BodyInit | null
    responsePayload?: any
  }
  extendedPayload: {}
}

/* ## Directory entity */

export interface IWSDirectoryRegistration {
  name: WSEntityName.directory
  payload: {}
}

export interface IWSDirectoryMessage {
  name: WSEntityName.directory
  payload: {
    id: number
    name: string
    ip: string
    type: string
    ldapPort: number
    globalCatalogPort: number
    smbPort: number
    dns?: Maybe<string>
    ldapCrawlingStatus: CrawlingStatus
    sysvolCrawlingStatus: CrawlingStatus
    sensitiveDataCollectionStatus: CrawlingStatus
    honeyAccountAdObjectId?: Maybe<number>
    honeyAccountDistinguishedName?: Maybe<string>
    honeyAccountConfigurationStatus?: Maybe<HoneyAccountStatus>
    infrastructureId: number
  }
  extendedPayload: {}
}

export interface IWSDirectoryConnectivityRegistration {
  name: WSEntityName.directoryConnectivityStatus
  payload: {}
}

export interface IWSDirectoryConnectivityMessage {
  name: WSEntityName.directoryConnectivityStatus
  payload: {
    success: boolean
    messages: string[]
  }
  extendedPayload: {}
}

export interface IWSDataCollectionStatusRegistration {
  name: WSEntityName.dataCollectionStatus
  payload: {}
}

export interface IWSDataCollectionStatusMessage {
  name: WSEntityName.dataCollectionStatus
  payload: {
    notWorkingBecauseOf: DataCollectionNotWorkingReason[]
  }
  extendedPayload: {}
}
