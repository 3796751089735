import { ContainerSimple } from '@app/components/Container'
import type { IFormWrapperControl } from '@app/components-legacy/Form/Wrappers/types'
import * as React from 'react'

interface IWrapperProps extends IFormWrapperControl {}

const ErrorWrapper: React.FC<IWrapperProps> = props => {
  return (
    <ContainerSimple
      name={`${ErrorWrapper.name}-${props.labelledBy}`}
      ariaRoles={'alert'}
      inline
      {...props}
    >
      {props.children}
    </ContainerSimple>
  )
}

export default ErrorWrapper
