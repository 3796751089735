import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelAbout } from '@app/components-legacy/Label'
import { TypographyFieldsetTitle } from '@app/components-legacy/Typography'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

export interface IProductVersionProps {
  className?: string
}

const ProductVersion: React.FC<IProductVersionProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.About']
  })

  const { storeAbout } = useStores()

  return (
    <ContainerFlex
      className={props.className}
      name="ProductVersion"
      direction="column"
      items={[
        <TypographyFieldsetTitle>
          {translate('Product version')}
        </TypographyFieldsetTitle>,

        <LabelAbout version={storeAbout.productVersion} />
      ]}
      spaced
    />
  )
}

const ObserverProductVersion = observer(ProductVersion)

export default styled(ObserverProductVersion)`
  width: ${consts.productVersionWidth};
`
