import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { useAppRouter, useAppTranslator } from '@app/hooks'
import { AppRouteName } from '@app/routes'
import * as React from 'react'
import { Link } from 'react-router-dom'

interface IDirectoriesCreateFirstProps {}

const DirectoriesCreateFirst: React.FC<
  IDirectoriesCreateFirstProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.InputInfrastructures']
  })

  const appRouter = useAppRouter()

  const url = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.Management_System_Directories_Create,
    parameters: {}
  })

  return (
    <ContainerContent title={translate('Main information')}>
      <ContainerFlex
        name="CreateDirectory"
        items={[
          <div>{translate('No domain found')}</div>,
          <Link to={url}>{translate('Add a domain')}</Link>
        ]}
        spaced
        spaceWidth="verySmall"
      />
    </ContainerContent>
  )
}

export default DirectoriesCreateFirst
