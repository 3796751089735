import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  ContainerContent,
  ContainerFooter,
  ContainerForm
} from '@app/components-legacy/Container'
import { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import {
  Drawer,
  DrawerCancelButton,
  DrawerContext,
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckerDetailsPageContext } from '../../context'
import { canSetIgnoreDateOnDeviantObjects } from '../../permissions'
import {
  onDeviantObjectsSetIgnoreDateSubmit,
  onIgnoreUntilDateChange
} from './handlers'
import IgnoreUntilDatePicker from './IgnoreUntilDatePicker'

interface IDrawerIgnoreDeviantObjectsProps {}

const DrawerIgnoreDeviantObjects: React.FC<
  IDrawerIgnoreDeviantObjectsProps
> = () => {
  const parameters = React.useContext(CheckerDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoE.Details.DeviantObjects']
  })

  const {
    storeAuthentication,
    storeIoE: { storeIndicator }
  } = useStores()

  if (!parameters) {
    return null
  }

  const {
    storeDrawerIgnoreObjets,
    storeFormIgnoreObjects,
    storeFlagsEditDeviances
  } = storeIndicator.storeIndicatorDeviantObjects

  const isDeviantObject =
    storeDrawerIgnoreObjets.getDataValue('objectType') === 'object'

  const ignoreUntilValue =
    storeFormIgnoreObjects.getFieldValueAsString('ignoreUntil')

  return (
    <DrawerContext.Provider value={{ storeDrawer: storeDrawerIgnoreObjets }}>
      <Drawer>
        <DrawerHorizontalContainer>
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: (
                <DrawerTitle>
                  {translate(
                    isDeviantObject
                      ? 'Ignore selected objects'
                      : 'Ignore selected deviances'
                  )}
                </DrawerTitle>
              ),
              content: (
                <ContainerContent>
                  <p>
                    {translate(
                      isDeviantObject
                        ? 'Select the date until which you want to ignore the selected objects'
                        : 'Select the date until which you want to ignore the selected deviances'
                    )}
                  </p>

                  <ContainerForm
                    variant={ContainerFormVariant.drawer}
                    fields={[
                      {
                        name: 'ignoreUntil',
                        label: translate('Ignore until'),
                        control: (
                          <IgnoreUntilDatePicker
                            selectedIgnoreUntilDate={ignoreUntilValue}
                            onChange={onIgnoreUntilDateChange(
                              storeFormIgnoreObjects
                            )}
                          />
                        )
                      }
                    ]}
                  />
                </ContainerContent>
              ),
              footer: (
                <ContainerFooter>
                  <DrawerCancelButton />

                  <FormWrapperButton
                    labelledBy="createFirstDashboard"
                    buttonProps={{
                      type: 'primary',
                      onClick: onDeviantObjectsSetIgnoreDateSubmit(
                        storeAuthentication,
                        storeIndicator.storeIndicatorDeviantObjects
                      )(ignoreUntilValue),
                      disabled: ignoreUntilValue === '',
                      loading: storeFlagsEditDeviances.flags.isLoading
                    }}
                  >
                    {translate('OK')}
                  </FormWrapperButton>
                </ContainerFooter>
              )
            }}
            rbacCapabilityCheck={canSetIgnoreDateOnDeviantObjects}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerIgnoreDeviantObjects)
