import { AppRouteName } from '@app/routes'
import type { StoreManagementSyslogs, StoreMessages } from '@app/stores'
import { CRITICITY_LEVEL_MEDIUM } from '@libs/criticity'
import { SyslogInputType } from '@server/graphql/typeDefs/types'
import { canAccessToRelays } from '../../../Relays/permissions'
import { ProtocolInputName, SyslogFormFieldName } from '../types'

export const onSyslogCreateLoad =
  (storeManagementSyslogs: StoreManagementSyslogs) => () => {
    const { storeAuthentication, storeManagementRelays, storeRbac } =
      storeManagementSyslogs.storeRoot.stores
    const {
      storeForm,
      storeInputExpression,
      storeInfrastructures,
      storeInputCheckersExposure,
      storeInputCheckersAttacks
    } = storeManagementSyslogs

    return Promise.resolve()
      .then(() => {
        return Promise.all([
          storeInputCheckersExposure.fetchCheckers(),
          storeInputCheckersAttacks.fetchCheckers()
        ])
      })
      .then(() => {
        if (storeRbac.isUserGrantedTo(canAccessToRelays)) {
          storeManagementRelays.fetchRelays()
        }
      })
      .then(() => {
        // fetch infras and save them all in the form values
        storeInfrastructures
          .fetchInfrastructures()
          .then(() => {
            storeForm
              .hardReset()
              .setDefaultFieldsValues([
                {
                  key: SyslogFormFieldName.protocol,
                  value: ProtocolInputName.TCP
                },
                {
                  key: SyslogFormFieldName.tls,
                  value: true
                },
                {
                  key: SyslogFormFieldName.shouldNotifyOnInitialFullSecurityCheck,
                  value: false
                },
                {
                  key: SyslogFormFieldName.inputType,
                  value: SyslogInputType.AdObjectChanges
                },
                {
                  key: SyslogFormFieldName.criticityThreshold,
                  value: CRITICITY_LEVEL_MEDIUM
                },
                {
                  key: SyslogFormFieldName.profiles,
                  value: storeAuthentication.currentProfileId
                }
              ])
              .reset()

            storeInputExpression.clearEntry()

            storeForm.setFieldValue(
              SyslogFormFieldName.directories,
              storeInfrastructures.getSelectedDirectoryIds().join(',')
            )
          })
          .catch(() => {
            // Already handled in the store
          })
      })
  }

export const onSyslogCreateUnload =
  (storeManagementSyslogs: StoreManagementSyslogs) => () => {
    storeManagementSyslogs.reset()
  }

export const onSyslogCreateSubmit =
  (storeManagementSyslogs: StoreManagementSyslogs) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeForm } = storeManagementSyslogs

    if (!storeForm.validate()) {
      return
    }

    const appRouter = storeManagementSyslogs.storeRoot.appRouter

    storeManagementSyslogs
      .createSyslog(storeManagementSyslogs.getArgsFromStores())
      .then(() => {
        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Management_System_Configuration_Syslogs,
          parameters: {}
        })
        appRouter.history.push(url)
      })
      .catch(() => {
        // already catched in the store
      })
  }

/**
 * Send an alert with the current Syslog alert configuration.
 */
export const onSyslogTestButtonClick =
  (
    storeMessages: StoreMessages,
    storeManagementSyslogs: StoreManagementSyslogs
  ) =>
  () => {
    const { storeForm, storeInputExpression } = storeManagementSyslogs

    // Enable user to not validate input expression before submitting form
    const isInputExpressionValid = storeInputExpression
      .setEntry(storeInputExpression.inputValue)
      .validateEntry()

    const isFormValid = storeForm.validate()

    if (!isInputExpressionValid || !isFormValid) {
      storeMessages.validationError()
      return
    }

    storeManagementSyslogs.testNewSyslog(
      storeManagementSyslogs.getArgsFromStores()
    )
  }
