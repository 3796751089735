import type { ISddlTree, WellKnownSidsEnum } from '@libs/sddl/types'
import { ACETitle } from '@libs/sddl/types'

export function isAce(sddl: ISddlTree): boolean {
  return sddl.title === ACETitle.ACE
}

export function matchEnumOrLabel(
  sidAccount: string,
  sidLabel: string | undefined
) {
  return ([wellKnownSidsEnum, label]: [WellKnownSidsEnum, string]): boolean => {
    return label === sidLabel || wellKnownSidsEnum === sidAccount
  }
}

export function matchEnumPatternOrLabel(
  sidAccount: string,
  sidLabel: string | undefined
) {
  return ([wellKnownSidsEnum, label]: [WellKnownSidsEnum, string]): boolean => {
    const regexp = new RegExp(`^${wellKnownSidsEnum}$`)
    return label === sidLabel || regexp.test(sidAccount)
  }
}
