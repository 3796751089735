import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditRelays } from '../permissions'
import { handleRelaysEditPageOnLoad } from './handlers'
import RelayEditForm from './RelayEditForm'

interface IRelayEditPageProps {}

const RelaysEditPage: React.FC<IRelayEditPageProps> = () => {
  const appRouter = useAppRouter()

  const { storeManagementRelays } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_System_Relays_Edit,
    parameters: {
      relayId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_System_Relays_Edit,
        parameters: {
          relayId: parameters.relayId
        }
      }}
      onLoad={handleRelaysEditPageOnLoad(storeManagementRelays)(
        parameters.relayId
      )}
      flags={storeManagementRelays.storeFlagsRelaysFetch.flags}
      layout={{
        name: 'default',
        content: (
          <ContainerContent>
            <RelayEditForm relayId={parameters.relayId} />
          </ContainerContent>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canEditRelays(parameters.relayId)}
    />
  )
}

export default observer(RelaysEditPage)
