import type { IIconProps } from '@app/components/Icon/types'
import { FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconDownloadProps extends IIconProps {}

const IconDownload: React.FC<IIconDownloadProps> = props => {
  const fill = props.color ?? FontColorV2.white
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 11 17"
      fill="none"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-289.000000, -654.000000)" fill={fill}>
          <g transform="translate(294.500000, 662.718370) rotate(-270.000000) translate(-294.500000, -662.718370) translate(286.781630, 658.218370)">
            <g id="noun-arrow-2841741" fillRule="nonzero" stroke={fill}>
              <polygon points="9.00497048 4.16558979 0.0204877241 4.104126 -2.34276141e-14 5.02740186 8.79982759 5.02740186 5.55796552 8.26926393 6.27613448 8.98730876 10.7696172 4.51430876 10.7491295 4.4937 10.0514743 3.79613793 6.27612952 0 5.55796055 0.718168966"></polygon>
            </g>
            <rect x="13.4367391" y="0" width="2" height="9"></rect>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconDownload
