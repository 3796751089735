import { ContainerFlex } from '@app/components-legacy/Container'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { consts } from '@app/styles'
import { getCriticityColor } from '@libs/criticity'
import { ensureArray } from '@libs/ensureArray'
import { isDefined } from '@libs/isDefined'
import { Criticity } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'
import { onLabelCheckerClick } from './handlers'
import LabelCheckerDescription from './LabelCheckerDescription'
import LabelCheckerFooter from './LabelCheckerFooter'
import LabelCheckerTitle from './LabelCheckerTitle'
import type { ILabelCheckerIdentityProps } from './types'
import { LabelCheckerHeight, LabelCheckerSize } from './types'

function LabelCheckerIdentity<GC extends TGenericChecker>(
  props: ILabelCheckerIdentityProps<GC>
): React.ReactElement {
  const [isOvered, setOverStatus] = React.useState<boolean>(false)

  /**
   * Needed to pass an over status to the footer, in order to change
   * the background color.
   */
  const onMouseEnterHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation()
    setOverStatus(true)
  }

  const onMouseOverLeave = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation()
    setOverStatus(false)
  }

  const hasComplexity = isDefined(props.complexityValue)
  const hasTenants = ensureArray(props.tenantIds).length > 0
  const hasDescription = isDefined(props.description)
  const showFooter = hasComplexity || hasTenants

  const { className, ...restProps } = props

  return (
    <div
      data-name="LabelChecker"
      onClick={onLabelCheckerClick(props)}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseOverLeave}
    >
      <ContainerFlex
        name="LabelCheckerContent"
        labelledBy={restProps.name}
        className={className}
        direction="column"
        justifyContent="space-between"
        items={[
          <ContainerFlex
            name="LabelCheckerContentPart"
            direction="column"
            items={[
              <LabelCheckerTitle {...restProps} />,

              hasDescription && <LabelCheckerDescription {...restProps} />
            ]}
          />,

          showFooter && (
            <LabelCheckerFooter {...restProps} isOvered={isOvered} />
          )
        ]}
      />
    </div>
  )
}

function showBackgroundColor(props: ILabelCheckerIdentityProps<any>): string {
  if (props.selectedBackground === false) {
    return consts.colorWhite
  }

  if (
    (props.selected || props.indeterminate) &&
    (props.checkable || props.selectable)
  ) {
    return consts.colorSelected01
  }

  return consts.colorWhite
}

export default styled(LabelCheckerIdentity)`
  position: relative;

  min-width: ${props => (props.size ? props.size : LabelCheckerSize.normal)};
  max-width: ${props => (props.size ? props.size : LabelCheckerSize.normal)};
  min-height: ${props => (props.small ? 'auto' : LabelCheckerHeight.normal)};
  max-height: ${props => (props.small ? 'auto' : LabelCheckerHeight.normal)};

  color: ${consts.colorBlue015};
  background-color: ${showBackgroundColor};
  opacity: ${props => (props.resolved ? 0.5 : 1)};

  border: 1px solid ${consts.colorBlueGray025};
  border-radius: 4px;
  border-left: 4px solid
    ${props => getCriticityColor(props.criticity || Criticity.Unknown)};

  cursor: ${props => (props.onClick ? 'pointer' : 'normal')};

  :hover {
    background-color: ${props =>
      !isDefined(props.hoverable) || props.hoverable === true
        ? consts.colorSelected01
        : consts.colorWhite};
  }
`
