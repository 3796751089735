import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import type { Criticity } from '@server/graphql/typeDefs/types'

/**
 * Un/select a group of checkers.
 */
export function onCheckersGroupTitleChange<GC extends TGenericChecker>(
  storeInputGenericCheckers: StoreInputGenericCheckers<GC>
) {
  return (criticity: Criticity, checked: boolean) => () => {
    checked
      ? storeInputGenericCheckers.unselectCheckersByCriticity(criticity)
      : storeInputGenericCheckers.selectCheckersByCriticity(criticity)
  }
}

/**
 * Toggle the checkers group.
 */
export function onCheckerGroupTitleClick<GC extends TGenericChecker>(
  storeInputGenericCheckers: StoreInputGenericCheckers<GC>
) {
  return (criticity: Criticity) => () => {
    storeInputGenericCheckers.toggleCheckersVisibility(criticity)
  }
}

/**
 * Un/select a checker.
 */
export function onCheckerSelection<GC extends TGenericChecker>(
  storeInputGenericCheckers: StoreInputGenericCheckers<GC>
) {
  return (
      checkerCodename: GenericCheckerCodename,
      onSelection?: (checkerCodename: GenericCheckerCodename) => void
    ) =>
    () => {
      if (storeInputGenericCheckers.options.selectable === true) {
        storeInputGenericCheckers.toggleSelectChecker(checkerCodename)
      }

      if (onSelection) {
        onSelection(checkerCodename)
      }
    }
}
