import { BulletCircle } from '@app/components/Bullet'
import { BulletCircleSize } from '@app/components/Bullet/BulletCircle'
import { LabelSliced, LabelColorTag } from '@app/components/Labels'
import type { ILabelProps } from '@app/components/Labels/Label'
import type { LabelVariant } from '@app/components/Labels/types'
import { consts } from '@app/styles'
import { Tooltip } from 'antd'
import * as React from 'react'
import styled from 'styled-components'

export interface ILabelInfrastructureProps {
  className?: string
  style?: React.CSSProperties
  color: string
  labelTagColor?: string
  label: string
  maxLength?: number
  tooltipLabel?: React.ReactNode | string
  labelVariant?: LabelVariant
  labelProperties?: ILabelProps
}

const StyledBulletCircle = styled(BulletCircle)`
  margin-right: ${consts.marginVerySmall};
`

const LabelInfrastructure: React.FC<ILabelInfrastructureProps> = props => {
  const renderLabel = () => {
    const maxLength = props.maxLength ?? 25

    if (props.tooltipLabel) {
      return (
        <Tooltip title={props.tooltipLabel}>
          <div>
            <LabelSliced maxLength={maxLength} value={props.label} {...props} />
          </div>
        </Tooltip>
      )
    }

    return (
      <LabelSliced
        maxLength={maxLength}
        value={props.label}
        {...props}
        popoverType="tooltip"
      />
    )
  }

  return (
    <LabelColorTag
      className={props.className}
      style={props.style}
      color={props.labelTagColor}
      icon={
        <StyledBulletCircle
          color={props.color}
          size={BulletCircleSize.medium}
        />
      }
      labelProperties={{
        labelledBy: 'LabelInfrastructure',
        ...props.labelProperties
      }}
    >
      {renderLabel()}
    </LabelColorTag>
  )
}

export default LabelInfrastructure
