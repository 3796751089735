import type { ILogger } from '@libs/logger'
import { useStores } from './useStores'

/**
 * Return the logger instance from the root store.
 */
export function useLogger(): ILogger {
  const { storeRoot } = useStores()

  return storeRoot.logger
}
