import type { Maybe } from '@@types/helpers'
import { ContainerContent, ContainerFlex } from '@app/components/Container'
import { IconAttackPath } from '@app/components/Icon'
import { Label, LabelSliced } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { WidgetListPagination } from '@app/components/Widgets/WidgetList'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { LabelDirectoriesTree } from '@app/components-legacy/Label'
import LabelDirectoryTag from '@app/components-legacy/Label/LabelDirectoryTag'
import { IDENTIFIER_COLUMN_NAME } from '@app/components-legacy/TableNew/consts'
import { Table } from '@app/components-legacy/TableNew/Table'
import type {
  TableColumnsProperties,
  TableRowData
} from '@app/components-legacy/TableNew/types'
import { useAppRouter, useAppTranslator, useStores } from '@app/hooks'
import NodeSearchIcon from '@app/pages/AttackPath/Components/NodeSearchIcon'
import { handleAccountsWithTier0AttackPathPaginationChange } from '@app/pages/AttackPath/SceneBlade/NodeFinder/Tier0/handlers'
import { AppRouteName } from '@app/routes'
import { consts } from '@app/styles'
import { isDefined } from '@libs/isDefined'
import type { AttackPathAdObjectType } from '@server/graphql/typeDefs/types'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

const StyledWidgetListPagination = styled(WidgetListPagination)`
  margin: 0 ${consts.marginDefault};
`

interface IAttackPathTier0AccountsColumns {
  name: {
    name: string
    adObjectType: Maybe<AttackPathAdObjectType>
  }
  directoryId: Maybe<number>
  location: string
  attackPathLink: Maybe<number>
}

interface IAttackPathTier0AccountsTableProps {
  className?: string
}

const AttackPathTier0AccountsTable: React.FC<
  IAttackPathTier0AccountsTableProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.Tier0', 'AttackPath.Tier0.TableColumns']
  })

  const { storeAttackPath, storeInfrastructures } = useStores()

  const { storeAttackPathTier0 } = storeAttackPath

  const appRouter = useAppRouter()

  const columns: TableColumnsProperties<IAttackPathTier0AccountsColumns> = {
    name: {
      name: translate('Common name'),
      relativeWidth: 35,
      render: ({ name, adObjectType }, availableWidth) => (
        <ContainerFlex
          name="AttackPathTier0AssetName"
          alignItems="center"
          items={[
            <NodeSearchIcon adObjectType={adObjectType} size={30} />,
            <Label variant={LabelVariant.p}>
              <LabelSliced value={name} maxWidth={availableWidth - (30 + 10)} />
            </Label>
          ]}
          spaced
          spaceWidth="small"
        />
      )
    },
    directoryId: {
      name: translate('Domain'),
      relativeWidth: 25,
      render: (directoryId, availableWidth) => {
        const directory =
          directoryId && storeInfrastructures.getDirectoryFromId(directoryId)

        return (
          directory &&
          isDefined(directory.id) &&
          directory.name && (
            <LabelDirectoryTag
              color={directory.color}
              label={directory.name}
              tooltipLabel={
                <LabelDirectoriesTree
                  storeInfrastructures={storeInfrastructures}
                  directoryIds={[directory.id]}
                />
              }
              maxWidth={availableWidth}
            />
          )
        )
      }
    },
    location: {
      name: translate('Location'),
      relativeWidth: 40
    },
    attackPathLink: {
      fixedWidth: 30,
      render: id => {
        if (!isDefined(id) || id < 0) {
          return null
        }

        const link = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.AttackPath,
          parameters: {},
          queryStringParameters: {
            sourceNodeId: String(id),
            targetNodeId: '0'
          }
        })

        return (
          <Tooltip title={translate('Explore attack path')}>
            <a href={link}>
              <FormWrapperButton
                labelledBy={`attack-path-link-${id}}`}
                variant={ButtonVariant.tertiary}
                size={ButtonSize.small}
                icon={IconAttackPath}
              />
            </a>
          </Tooltip>
        )
      }
    }
  }

  const data: Array<TableRowData<IAttackPathTier0AccountsColumns>> =
    storeAttackPathTier0.accountsWithTier0AttackPath?.map(object => ({
      [IDENTIFIER_COLUMN_NAME]: object.getPropertyAsNumber('id'),
      name: {
        name: object.getPropertyAsString('name'),
        adObjectType: object.adObjectType
      },
      directoryId: object.directoryId,
      location: object.getPropertyAsString('location'),
      attackPathLink: object.id
    })) || []

  return (
    <ContainerContent
      flags={
        storeAttackPathTier0.storeFlagsFetchAccountsWithTier0AttackPath.flags
      }
    >
      <Table
        labelledBy="attackPathTier0Accounts"
        columns={columns}
        data={data}
      />
      <StyledWidgetListPagination
        onChange={handleAccountsWithTier0AttackPathPaginationChange(
          storeAttackPathTier0
        )}
        storeWidgetList={
          storeAttackPathTier0.storeWidgetListAccountsWithTier0AttackPath
        }
      />
    </ContainerContent>
  )
}

export default observer(AttackPathTier0AccountsTable)
