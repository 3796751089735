import {
  createEntities,
  createEntity,
  EntityProfile,
  EntityRbacRole
} from '@app/entities'
import EntitySAMLConfiguration from '@app/entities/EntitySAMLConfiguration'
import type { StoreRoot } from '@app/stores'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreForm from '@app/stores/helpers/StoreForm'
import type { IFieldValue } from '@app/stores/helpers/StoreForm/types'
import { mandatory } from '@app/stores/helpers/StoreForm/validators'
import StoreFormGroups from '@app/stores/helpers/StoreFormGroups.ts'
import type { CreateGroupFn } from '@app/stores/helpers/StoreFormGroups.ts/types'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { ensureArray } from '@libs/ensureArray'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import { checkRbac } from '@libs/rbac/functions'
import { mutationUpdateSAMLConfiguration } from '@server/graphql/mutations/samlConfiguration'
import type { QuerySAMLConfiguration } from '@server/graphql/queries/management'
import { querySAMLConfiguration } from '@server/graphql/queries/management'
import type {
  InputSamlConfiguration,
  Maybe,
  Profile,
  RbacRole,
  SamlConfiguration,
  UpdateSamlConfigurationMutationArgs
} from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable } from 'mobx'
import {
  SamlConfigurationFieldName,
  SamlConfigurationGroupFieldName
} from './types'

export default class StoreSAMLConfiguration extends StoreBase {
  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Errors.Form', 'Management.System.Configuration.SAML']
  })

  public storeForm = new StoreForm<SamlConfigurationFieldName>(this.storeRoot, {
    setup: {
      fields: {
        [SamlConfigurationFieldName.enabled]: {
          label: 'Enable SAML authentication'
        },
        [SamlConfigurationFieldName.providerLoginUrl]: {
          label: 'URL of the SAML server',
          validators: [mandatory()]
        },
        [SamlConfigurationFieldName.signatureCertificate]: {
          label: 'SAML server certificate',
          validators: [mandatory()]
        },
        [SamlConfigurationFieldName.activateCreatedUsers]: {
          label: "Activate new user's account automatically"
        },
        [SamlConfigurationFieldName.serviceProviderUrl]: {
          label: 'URL of the Tenable.ad service provider'
        },
        [SamlConfigurationFieldName.assertEndpoint]: {
          label: 'Assert endpoint of the Tenable.ad service provider'
        }
      },
      translate: this.translate
    }
  })

  public storeFlags = new StoreFlags(this.storeRoot)
  public storeUpdateFlags = new StoreFlags(this.storeRoot)

  public storeFormAllowedGroups = new StoreFormGroups<
    StoreForm<SamlConfigurationGroupFieldName>
  >(this.storeRoot)

  /* Observable */

  private $samlConfiguration =
    observable.box<Maybe<EntitySAMLConfiguration>>(null)
  private $rbacRoles = observable.box<Maybe<EntityRbacRole[]>>(null)
  private $profiles = observable.box<Maybe<EntityProfile[]>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  fetchSAMLConfiguration() {
    this.storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QuerySAMLConfiguration>(querySAMLConfiguration)
      })
      .then(({ rbacSAMLConfiguration, rbacProfiles, rbacRoles }) => {
        if (
          !checkRbac(this.storeRoot, this.storeFlags)(rbacSAMLConfiguration)
        ) {
          throw new ForbiddenAccessError()
        }

        const samlConfigurationEntity = createEntity<
          SamlConfiguration,
          EntitySAMLConfiguration
        >(EntitySAMLConfiguration, rbacSAMLConfiguration.node)

        const rbacProfilesEntities = createEntities<Profile, EntityProfile>(
          EntityProfile,
          ensureArray(rbacProfiles.node)
        )

        const rbacRolesEntities = createEntities<RbacRole, EntityRbacRole>(
          EntityRbacRole,
          ensureArray(rbacRoles.node)
        )

        this.setEntities(
          samlConfigurationEntity,
          rbacProfilesEntities,
          rbacRolesEntities
        )

        this.setFormDefaultValues()
        this.setFormGroupsForAllowedGroups(samlConfigurationEntity)

        this.storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlags))
  }

  /**
   * Update SAML configuration.
   */
  updateSAMLConfiguration(samlConfiguration: InputSamlConfiguration) {
    this.storeUpdateFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: UpdateSamlConfigurationMutationArgs = {
          samlConfiguration
        }

        return this.storeRoot
          .getGQLRequestor()
          .query(mutationUpdateSAMLConfiguration, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('SAML configuration updated'),
          {
            labelledBy: 'samlConfigurationUpdated'
          }
        )

        this.storeUpdateFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeUpdateFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when editing the SAML configuration'
        })
      )
  }

  /* Actions */

  @action
  reset(): this {
    this.storeFormAllowedGroups.reset()

    this.storeForm.reset()

    this.storeFlags.reset()
    this.storeUpdateFlags.reset()

    this.$samlConfiguration.set(null)
    this.$rbacRoles.set(null)
    this.$profiles.set(null)

    return this
  }

  /**
   * Save SAML configuration.
   */
  @action
  setEntities(
    samlConfigurationEntity: EntitySAMLConfiguration,
    profilesEntities: Maybe<EntityProfile[]>,
    rbacRolesEntities: Maybe<EntityRbacRole[]>
  ): this {
    this.$samlConfiguration.set(samlConfigurationEntity)
    this.$profiles.set(profilesEntities)
    this.$rbacRoles.set(rbacRolesEntities)

    return this
  }

  /**
   * Reset the form first and set fields values.
   */
  @action
  setFormDefaultValues(): this {
    const samlConfiguration = this.samlConfiguration

    if (!samlConfiguration) {
      return this
    }

    this.storeForm
      .setDefaultFieldsValuesFromObject(samlConfiguration, {
        action: 'omit',
        keys: ['encryptionCertificate', 'allowedGroups']
      })
      .reset()

    return this
  }

  /**
   * Set the allowed groups (groups of StoreForm in storeFormAllowedGroups).
   */
  setFormGroupsForAllowedGroups(
    samlConfigurationEntity: EntitySAMLConfiguration
  ): void {
    if (!samlConfigurationEntity.allowedGroups) {
      return
    }

    this.storeFormAllowedGroups.reset()

    samlConfigurationEntity.allowedGroups.forEach(() => {
      this.storeFormAllowedGroups.addGroup(this.createAllowedGroups)
    })
  }

  /**
   * When adding a group fieldset configuration (for default profile and defaultRoles),
   * instanciate a new StoreForm instance and setup default fields according to
   * the current SAML configuration.
   */
  @action
  createAllowedGroups: CreateGroupFn<
    StoreForm<SamlConfigurationGroupFieldName>
  > = index => {
    const storeForm = new StoreForm<SamlConfigurationGroupFieldName>(
      this.storeRoot,
      {
        setup: {
          fields: {
            [SamlConfigurationGroupFieldName.groupName]: {
              label: 'Group name',
              validators: [mandatory()]
            },
            [SamlConfigurationGroupFieldName.defaultProfileId]: {
              label: 'Default profile id',
              validators: [mandatory()]
            },
            [SamlConfigurationGroupFieldName.defaultRoleIds]: {
              label: 'Default role ids',
              validators: [mandatory()]
            }
          }
        }
      }
    )

    if (!this.samlConfiguration) {
      return storeForm
    }

    const defaultValues: IFieldValue[] = []

    const allowedGroups = ensureArray(this.samlConfiguration.allowedGroups)[
      index
    ]

    const groupName = allowedGroups && allowedGroups.name

    if (groupName) {
      defaultValues.push({
        key: SamlConfigurationGroupFieldName.groupName,
        value: groupName
      })
    }

    const defaultProfileId = allowedGroups && allowedGroups.defaultProfileId

    if (defaultProfileId) {
      defaultValues.push({
        key: SamlConfigurationGroupFieldName.defaultProfileId,
        value: defaultProfileId
      })
    }

    const defaultRoleIds = allowedGroups && allowedGroups.defaultRoleIds

    if (defaultRoleIds) {
      defaultValues.push({
        key: SamlConfigurationGroupFieldName.defaultRoleIds,
        value: defaultRoleIds.join(',')
      })
    }

    storeForm.setDefaultFieldsValues(defaultValues).reset()

    return storeForm
  }

  /* Computed */

  @computed
  get samlConfiguration(): Maybe<EntitySAMLConfiguration> {
    return this.$samlConfiguration.get()
  }

  @computed
  get rbacRoles(): EntityRbacRole[] {
    return ensureArray(this.$rbacRoles.get())
  }

  @computed
  get rbacRoleNames(): string[] {
    return ensureArray(this.$rbacRoles.get())
      .map(rbacRole => rbacRole.name)
      .filter(isDefined)
  }

  @computed
  get profiles(): EntityProfile[] {
    return ensureArray(this.$profiles.get())
  }
}
