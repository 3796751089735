import type { PropertiesNullable } from '@@types/helpers'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import { ensureArray } from '@libs/ensureArray'
import type {
  Directory,
  Email,
  EmailInputType,
  Maybe
} from '@server/graphql/typeDefs/types'
import { createEntities, EntityDirectory } from './'
import EntityBase from './EntityBase'
import type { IEntityListable, IEntityOmitKeysParameter } from './types'

export interface IDataRowEmail {
  id: number
  address: string
  criticityThreshold: number
  description: string
  shouldNotifyOnInitialFullSecurityCheck: boolean
  inputType: EmailInputType
  directories: number[]
  profiles: number[]
  checkers: number[]
  attackTypes: number[]
  healthCheckNames: string[]
}

export default class EntityEmail
  extends EntityBase
  implements PropertiesNullable<Email>, IEntityListable<IDataRowEmail>
{
  id: Maybe<number> = null
  address: Maybe<string> = null
  criticityThreshold: Maybe<number> = null
  description: Maybe<string> = null
  shouldNotifyOnInitialFullSecurityCheck: Maybe<boolean> = null
  directories: Maybe<Directory[]> = null
  profiles: Maybe<number[]> = null
  inputType: Maybe<EmailInputType> = null
  checkers: Maybe<number[]> = null
  attackTypes: Maybe<number[]> = null
  healthCheckNames: Maybe<string[]> = null

  constructor(data: Partial<Email>) {
    super()
    Object.assign(this, data)
  }

  getDirectories(): EntityDirectory[] {
    return createEntities<Directory, EntityDirectory>(
      EntityDirectory,
      this.directories
    )
  }

  /* Implements IEntityListable */

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowEmail> = []
  ): Array<IWidgetListColumns<IDataRowEmail>> {
    const columns: Array<IWidgetListColumns<IDataRowEmail>> = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Address',
        key: 'address'
      },
      {
        label: 'Criticity threshold',
        key: 'criticityThreshold'
      },
      {
        label: 'Directories',
        key: 'directories'
      },
      {
        label: 'Description',
        key: 'description'
      }
    ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  asDataRow(): IDataRowEmail {
    return {
      id: this.getPropertyAsNumber('id'),
      address: this.getPropertyAsString('address'),
      criticityThreshold: this.getPropertyAsNumber('criticityThreshold'),
      description: this.getPropertyAsString('description'),
      shouldNotifyOnInitialFullSecurityCheck: this.getPropertyAsBoolean(
        'shouldNotifyOnInitialFullSecurityCheck'
      ),
      inputType: this.getPropertyAsT<EmailInputType>('inputType'),
      directories: ensureArray(this.directories).map(d => d.id),
      profiles: ensureArray(this.profiles),
      checkers: ensureArray(this.checkers),
      attackTypes: ensureArray(this.attackTypes),
      healthCheckNames: ensureArray(this.healthCheckNames)
    }
  }
}
