import { Features } from '@alsid/common'
import {
  rbacCapability,
  rbacCapabilityAllOf,
  rbacCapabilityAnyOf
} from '@libs/rbac/functions'
import type { RbacPermission, RbacPermissions } from '@libs/rbac/types'
import { RbacAction, RbacEntityName } from '@server/graphql/typeDefs/types'
import {
  denyCapability,
  grantCapability,
  toggleCapability
} from './capabilities'

// Grant generic permission
export const grant: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  () => {
    return {
      isGranted: true,
      message: (rbacPermissionOption && rbacPermissionOption.message) || null
    }
  }

// Deny generic permission
export const deny: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  () => {
    return {
      isGranted: false,
      message: (rbacPermissionOption && rbacPermissionOption.message) || null
    }
  }

/* Related to authentication */

export const authenticated: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions, { storeRbac }) => {
    const { storeAuthentication } = storeRbac.storeRoot.stores

    return {
      isGranted: storeAuthentication.isAuthenticated,
      message: (rbacPermissionOption && rbacPermissionOption.message) || null
    }
  }

export const unAuthenticated: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions, { storeRbac }) => {
    const { storeAuthentication } = storeRbac.storeRoot.stores

    return {
      isGranted: !storeAuthentication.isAuthenticated,
      message: (rbacPermissionOption && rbacPermissionOption.message) || null
    }
  }

/* Related to license */

export const validLicense: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions, { storeRbac }) => {
    const { storeLicense } = storeRbac.storeRoot.stores

    return {
      isGranted: storeLicense.isLicenseStatusAuthorizeAccess,
      message: (rbacPermissionOption && rbacPermissionOption.message) || null
    }
  }

export const unvalidLicense: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions, { storeRbac }) => {
    const { storeLicense } = storeRbac.storeRoot.stores

    return {
      isGranted: !storeLicense.isLicenseStatusAuthorizeAccess,
      message: (rbacPermissionOption && rbacPermissionOption.message) || null
    }
  }

export const hasNotApprovedEula: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions, { storeRbac }) => {
    const { storeEula } = storeRbac.storeRoot.stores

    return {
      isGranted: !storeEula.hasApprovedEula,
      message: (rbacPermissionOption && rbacPermissionOption.message) || null
    }
  }

/* Related to toggles */

export const accessByFeatureFlag =
  (featureFlagName: Features): RbacPermission =>
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions, { storeRbac }) => {
    return storeRbac.isUserGrantedAccordingFeatureFlag(featureFlagName)
      ? grantCapability()
      : denyCapability()
  }

export const accessByFeatureFlagDisabled =
  (featureFlagName: Features): RbacPermission =>
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions, { storeRbac }) => {
    return storeRbac.isUserGrantedAccordingFeatureFlag(featureFlagName)
      ? denyCapability()
      : grantCapability()
  }

/* Alerts */

export const readUIAlerts: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiAlerts,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUIAlertsArchive: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiAlertsArchiveAlert,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Dashboards */

export const readUIDashboard: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiDashboard,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const configureDashboards: RbacPermission =
  (dashboardId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions, meta) => {
    return rbacCapabilityAnyOf(
      editDashboard(dashboardId, rbacPermissionOption)(rbacPermissions, meta),
      deleteDashboard(dashboardId, rbacPermissionOption)(rbacPermissions, meta)
    )
  }

export const addDashboardOrWidgets: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions, meta) => {
    return rbacCapabilityAnyOf(
      createDashboard(rbacPermissionId, rbacPermissionOption)(
        rbacPermissions,
        meta
      ),
      createDashboardWidget(rbacPermissionId, rbacPermissionOption)(
        rbacPermissions,
        meta
      )
    )
  }

export const readDashboards: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Dashboard,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const createDashboard: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Dashboard,
      RbacAction.Create,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editDashboard: RbacPermission =
  (dashboardId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Dashboard,
      RbacAction.Edit,
      dashboardId,
      rbacPermissionOption
    )
  }

export const deleteDashboard: RbacPermission =
  (dashboardId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Dashboard,
      RbacAction.Create,
      dashboardId,
      rbacPermissionOption
    )
  }

export const createDashboardWidget: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Widget,
      RbacAction.Create,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editDashboardWidget: RbacPermission =
  (dashboardWidgetId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Widget,
      RbacAction.Edit,
      dashboardWidgetId,
      rbacPermissionOption
    )
  }

export const deleteDashboardWidget: RbacPermission =
  (dashboardWidgetId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Widget,
      RbacAction.Create,
      dashboardWidgetId,
      rbacPermissionOption
    )
  }

/* TrailFlow */

export const readUITrailFlow: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiTrailFlow,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Identities Explorer */

export const readIdentityExplorer: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIdentityExplorer,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Asset overview */

export const readAssetOverview: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiAssetOverview,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Weakness overview */

export const readWeaknessOverview: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiWeaknessOverview,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* IoE */

export const readUIIoE: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIoe,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUIIoEInformations: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIoeInformations,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUIIoEVulnerabilityDetails: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIoeVulnerabilityDetails,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUIIoEDeviances: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIoeDeviantElements,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUIIoEDeviancesExport: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIoeDeviantElementsExport,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUIIoEDeviancesSetIgnoreDate: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIoeDeviantElementsSetIgnoreDate,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUIIoERecommandations: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIoeRecommandations,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readCheckers: RbacPermission =
  (checkerId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Checker,
      RbacAction.Read,
      checkerId,
      rbacPermissionOption
    )
  }

/* IoE Identity */

export const readUIIoEIdentity: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIoeIdentity,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* IoA */

export const readUIIoA: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIoa,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUIIoATimeline: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIoaTimeline,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUiIoaExport: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIoaExport,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUiIoaEditCard: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIoaEditCard,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUiIoaAttackDescription: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIoaAttackDescription,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUiIoaAttackYaraRules: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIoaAttackYaraRules,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUiIoaCloseAttack: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiIoaCloseAttack,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUiIoaRemoveAttack: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions, { storeRbac }) => {
    return rbacCapabilityAllOf(
      // deny if disabled by the toggle
      toggleCapability(storeRbac)(Features.REMOVE_ATTACKS)(),
      rbacCapability(rbacPermissions)(
        RbacEntityName.UiIoaRemoveAttack,
        RbacAction.Read,
        rbacPermissionId,
        rbacPermissionOption
      )
    )
  }

export const readAttackTypes: RbacPermission =
  (attackTypeId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.AttackType,
      RbacAction.Read,
      attackTypeId,
      rbacPermissionOption
    )
  }

/* Topology */

export const readUITopology: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiTopology,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readTopology: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Topology,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Accounts */

export const readUIAccounts: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiAccounts,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Users */

export const readUIAccountsUsers: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiAccountsUsers,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUsers: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.User,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const createUser: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.User,
      RbacAction.Create,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editUser: RbacPermission =
  (userId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.User,
      RbacAction.Edit,
      userId,
      rbacPermissionOption
    )
  }

export const deleteUser: RbacPermission =
  (userId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.User,
      RbacAction.Create,
      userId,
      rbacPermissionOption
    )
  }

/* Roles */

export const readUIAccountsRoles: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiAccountsRoles,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readRoles: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Role,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readRole: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Role,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const createRole: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Role,
      RbacAction.Create,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editRole: RbacPermission =
  (roleId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Role,
      RbacAction.Edit,
      roleId,
      rbacPermissionOption
    )
  }

export const deleteRole: RbacPermission =
  (roleId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Role,
      RbacAction.Create,
      roleId,
      rbacPermissionOption
    )
  }

/* Profiles */

export const readUIAccountsProfiles: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions, { storeRbac }) => {
    const ioaFeatureCapability = readLicenseFeatureIoA(
      rbacPermissionId,
      rbacPermissionOption
    )(rbacPermissions, { storeRbac })

    const ioeFeatureCapability = readLicenseFeatureIoE(
      rbacPermissionId,
      rbacPermissionOption
    )(rbacPermissions, { storeRbac })

    if (ioaFeatureCapability.isGranted && !ioeFeatureCapability.isGranted) {
      return rbacCapabilityAllOf(
        rbacCapability(rbacPermissions)(
          RbacEntityName.UiAccountsProfiles,
          RbacAction.Read,
          rbacPermissionId,
          rbacPermissionOption
        )
      )
    }

    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiAccountsProfiles,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readProfiles: RbacPermission =
  (profileId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Profile,
      RbacAction.Read,
      profileId,
      rbacPermissionOption
    )
  }

export const createProfile: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Profile,
      RbacAction.Create,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editProfile: RbacPermission =
  (profileId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Profile,
      RbacAction.Edit,
      profileId,
      rbacPermissionOption
    )
  }

export const deleteProfile: RbacPermission =
  (profileId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Profile,
      RbacAction.Create,
      profileId,
      rbacPermissionOption
    )
  }

/* System */

export const readUISystem: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystem,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Infrastructures */

export const readUISystemInfrastructures: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemInfrastructures,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readInfrastructures: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Infrastructure,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const createInfrastructure: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Infrastructure,
      RbacAction.Create,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editInfrastructure: RbacPermission =
  (infrastructureId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Infrastructure,
      RbacAction.Edit,
      infrastructureId,
      rbacPermissionOption
    )
  }

export const deleteInfrastructure: RbacPermission =
  (infrastructureId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Infrastructure,
      RbacAction.Create,
      infrastructureId,
      rbacPermissionOption
    )
  }

/* Directories */

export const readUISystemDirectories: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemDirectories,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readDirectories: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Directory,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const createDirectory: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Directory,
      RbacAction.Create,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editDirectory: RbacPermission =
  (directoryId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Directory,
      RbacAction.Edit,
      directoryId,
      rbacPermissionOption
    )
  }

export const deleteDirectory: RbacPermission =
  (directoryId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Directory,
      RbacAction.Create,
      directoryId,
      rbacPermissionOption
    )
  }

export const readDirectoryRecrawl: RbacPermission =
  (directoryId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.DirectoryRecrawl,
      RbacAction.Read,
      directoryId,
      rbacPermissionOption
    )
  }

/* Configuration */

export const readUISystemConfiguration: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemConfiguration,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUISystemConfigurationSMTPServer: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemConfigurationSmtpServer,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUISystemConfigurationLogs: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemConfigurationLogs,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUISystemConfigurationTelemetry: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiTelemetry,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUISystemConfigurationSecurity: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemConfigurationSecurity,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUISystemConfigurationAttackType: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemConfigurationIoa,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUiSystemConfigurationIoaDownloadGpo: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemConfigurationIoaDownloadGpo,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* About */

export const readUISystemAbout: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemAbout,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Application Setting */

export const readApplicationSetting: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.ApplicationSetting,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editApplicationSetting: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.ApplicationSetting,
      RbacAction.Edit,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* System alerts */

export const accessToSystemAlerts: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions, meta) => {
    return rbacCapabilityAnyOf(
      readSyslogAlerts(rbacPermissionId, rbacPermissionOption)(
        rbacPermissions,
        meta
      ),
      readEmailAlerts(rbacPermissionId, rbacPermissionOption)(
        rbacPermissions,
        meta
      )
    )
    // TODO for the PLUGIN FEATURE : add the readPlugins to this list when rbac will be implemented for the feature
  }

/* Syslog alerts */

export const readUISystemConfigurationAlertsSyslog: RbacPermission =
  (syslogId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemConfigurationAlertsSyslog,
      RbacAction.Read,
      syslogId,
      rbacPermissionOption
    )
  }

export const readSyslogAlerts: RbacPermission =
  (syslogId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Syslog,
      RbacAction.Read,
      syslogId,
      rbacPermissionOption
    )
  }

export const createSyslogAlert: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Syslog,
      RbacAction.Create,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editSyslogAlert: RbacPermission =
  (syslogId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Syslog,
      RbacAction.Edit,
      syslogId,
      rbacPermissionOption
    )
  }

export const deleteSyslogAlert: RbacPermission =
  (syslogId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Syslog,
      RbacAction.Create,
      syslogId,
      rbacPermissionOption
    )
  }

/* Email alerts */

export const readUISystemConfigurationAlertsEmail: RbacPermission =
  (syslogId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemConfigurationAlertsEmail,
      RbacAction.Read,
      syslogId,
      rbacPermissionOption
    )
  }

export const readEmailAlerts: RbacPermission =
  (emailId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.EmailNotifier,
      RbacAction.Read,
      emailId,
      rbacPermissionOption
    )
  }

export const createEmailAlert: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.EmailNotifier,
      RbacAction.Create,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editEmailAlert: RbacPermission =
  (emailId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.EmailNotifier,
      RbacAction.Edit,
      emailId,
      rbacPermissionOption
    )
  }

export const deleteEmailAlert: RbacPermission =
  (emailId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.EmailNotifier,
      RbacAction.Create,
      emailId,
      rbacPermissionOption
    )
  }

/* Plugins */

export const readUISystemConfigurationPlugins: RbacPermission =
  (pluginId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemConfigurationPlugin,
      RbacAction.Read,
      pluginId,
      rbacPermissionOption
    )
  }

export const readPlugins: RbacPermission =
  (pluginId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Plugin,
      RbacAction.Read,
      pluginId,
      rbacPermissionOption
    )
  }

/* Tenable account Configuration */

export const readUISystemConfigurationTenableAccount: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemConfigurationTenableAccount,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Lockout Policy */

export const readLockoutPolicy: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.LockoutPolicy,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editLockoutPolicy: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.LockoutPolicy,
      RbacAction.Edit,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Ldap Configuration */

export const readUISystemConfigurationLdap: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemConfigurationLdap,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readToLdapConfiguration: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.LdapConfiguration,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editToLdapConfiguration: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.LdapConfiguration,
      RbacAction.Edit,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Saml Configuration */

export const readUISystemConfigurationSaml: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemConfigurationSaml,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readToSamlConfiguration: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.SamlConfiguration,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editToSamlConfiguration: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.SamlConfiguration,
      RbacAction.Edit,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Preferences */

export const readUIPreferences: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiPreferences,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editPreferences: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Preference,
      RbacAction.Edit,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* License */

export const updateLicense: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions, meta) => {
    return rbacCapabilityAllOf(
      readLicense(rbacPermissionId, rbacPermissionOption)(
        rbacPermissions,
        meta
      ),
      editLicense(rbacPermissionId, rbacPermissionOption)(rbacPermissions, meta)
    )
  }

export const readLicense: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.License,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editLicense: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.License,
      RbacAction.Edit,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editApiKey: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.ApiKey,
      RbacAction.Edit,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/**
 * Permissions based on the license features.
 */

export const readLicenseFeatureIoE: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.LicenseFeatureIoe,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readLicenseFeatureIoA: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.LicenseFeatureIoa,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/**
 * AttackPath.
 */

export const readUIAttackPath: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiAttackPath,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* ActivityLogs */

export const readActivityLogs: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.ActivityLogs,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editActivityLogs: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.ActivityLogs,
      RbacAction.Edit,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Telemetry */

export const readUITelemetry: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiTelemetry,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Data Collection */

export const readDataCollection: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.DataCollectionConfiguration,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editDataCollection: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.DataCollectionConfiguration,
      RbacAction.Edit,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Tenable Cloud Api Token (Microsoft Entra ID support) */

export const readTenableCloudApiToken: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.TenableCloudApiToken,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editTenableCloudApiToken: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.TenableCloudApiToken,
      RbacAction.Edit,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Relay */

export const readUISystemRelay: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemRelay,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const readUIRelayLinkingKey: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemConfigurationAppServicesLinkingKey,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

export const editRelay: RbacPermission =
  (relayId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions, { storeRbac }) => {
    if (!storeRbac.storeRoot.environment.config.app.securerelay.customerside) {
      return denyCapability()
    }

    return rbacCapability(rbacPermissions)(
      RbacEntityName.Relay,
      RbacAction.Edit,
      relayId,
      rbacPermissionOption
    )
  }

export const createRelay: RbacPermission =
  (relayId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Relay,
      RbacAction.Create,
      relayId,
      rbacPermissionOption
    )
  }

export const readRelay: RbacPermission =
  (relayId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions, { storeRbac }) => {
    if (!storeRbac.storeRoot.environment.config.app.securerelay.customerside) {
      return denyCapability()
    }

    return rbacCapability(rbacPermissions)(
      RbacEntityName.Relay,
      RbacAction.Read,
      relayId,
      rbacPermissionOption
    )
  }

/* Health Check */

export const readHealthCheck: RbacPermission =
  (rbacPermissionId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.HealthCheck,
      RbacAction.Read,
      rbacPermissionId,
      rbacPermissionOption
    )
  }

/* Reporting center */

export const readUiSystemConfigurationReportingCenter: RbacPermission =
  (reportId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.UiSystemConfigurationReportingCenter,
      RbacAction.Read,
      reportId,
      rbacPermissionOption
    )
  }

export const readReport: RbacPermission =
  (reportId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Report,
      RbacAction.Read,
      reportId,
      rbacPermissionOption
    )
  }

export const createReport: RbacPermission =
  (reportId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Report,
      RbacAction.Create,
      reportId,
      rbacPermissionOption
    )
  }

export const editReport: RbacPermission =
  (reportId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Report,
      RbacAction.Edit,
      reportId,
      rbacPermissionOption
    )
  }

export const deleteReport: RbacPermission =
  (reportId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Report,
      RbacAction.Create,
      reportId,
      rbacPermissionOption
    )
  }

export const readTenants: RbacPermission =
  (tenantId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Tenant,
      RbacAction.Read,
      tenantId,
      rbacPermissionOption
    )
  }

export const createTenant: RbacPermission =
  (tenantId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Tenant,
      RbacAction.Create,
      tenantId,
      rbacPermissionOption
    )
  }

export const editTenant: RbacPermission =
  (tenantId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Tenant,
      RbacAction.Edit,
      tenantId,
      rbacPermissionOption
    )
  }

export const deleteTenant: RbacPermission =
  (tenantId = null, rbacPermissionOption = null) =>
  (rbacPermissions: RbacPermissions) => {
    return rbacCapability(rbacPermissions)(
      RbacEntityName.Tenant,
      RbacAction.Create,
      tenantId,
      rbacPermissionOption
    )
  }
