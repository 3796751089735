/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { ProviderType } from './ProviderType'
import {
  ProviderTypeFromJSON,
  ProviderTypeFromJSONTyped,
  ProviderTypeToJSON
} from './ProviderType'

/**
 *
 * @export
 * @interface Credential
 */
export interface Credential {
  /**
   *
   * @type {string}
   * @memberof Credential
   */
  uuid: string
  /**
   *
   * @type {ProviderType}
   * @memberof Credential
   */
  providerType: ProviderType
  /**
   *
   * @type {string}
   * @memberof Credential
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof Credential
   */
  tenantUuid?: string
}

/**
 * Check if a given object implements the Credential interface.
 */
export function instanceOfCredential(value: object): boolean {
  if (!('uuid' in value)) return false
  if (!('providerType' in value)) return false
  if (!('name' in value)) return false
  return true
}

export function CredentialFromJSON(json: any): Credential {
  return CredentialFromJSONTyped(json, false)
}

export function CredentialFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Credential {
  if (json == null) {
    return json
  }
  return {
    uuid: json['uuid'],
    providerType: ProviderTypeFromJSON(json['provider_type']),
    name: json['name'],
    tenantUuid: json['tenant_uuid'] == null ? undefined : json['tenant_uuid']
  }
}

export function CredentialToJSON(value?: Credential | null): any {
  if (value == null) {
    return value
  }
  return {
    uuid: value['uuid'],
    provider_type: ProviderTypeToJSON(value['providerType']),
    name: value['name'],
    tenant_uuid: value['tenantUuid']
  }
}
