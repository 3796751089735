import type { EntityAttackTypeOption, EntityCheckerOption } from '@app/entities'
import { createEntities, EntityProfile } from '@app/entities'
import type { IDataRowProfile } from '@app/entities/EntityProfile'
import { ProfileFieldName } from '@app/pages/Management/AccountsPage/Profiles/ProfilesCreatePage/types'
import type { StoreRoot } from '@app/stores'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreForm from '@app/stores/helpers/StoreForm'
import { transformSlashesToPipes } from '@app/stores/helpers/StoreForm/sanitizors'
import {
  mandatory,
  profileNameFormat,
  profileNameIsNotEqualsToTenableFormat
} from '@app/stores/helpers/StoreForm/validators'
import type {
  ICheckerAttack,
  ICheckerExposure
} from '@app/stores/helpers/StoreInputGenericCheckers/types'
import StoreMenu from '@app/stores/helpers/StoreMenu'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import StoreModal from '@app/stores/helpers/StoreModal'
import StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { handleStoreError } from '@libs/errors/handleStoreError'
import type {
  MutationCopyProfile,
  MutationCreateProfile
} from '@server/graphql/mutations/profile'
import {
  mutationCommitProfile,
  mutationCopyProfile,
  mutationCreateProfile,
  mutationDeleteProfile,
  mutationEditProfile,
  mutationUnstageProfile
} from '@server/graphql/mutations/profile'
import type { QueryProfiles } from '@server/graphql/queries/profiles'
import { queryProfiles } from '@server/graphql/queries/profiles'
import type {
  CommitProfileMutationArgs,
  CopyProfileMutationArgs,
  CreateProfileMutationArgs,
  DeleteProfileMutationArgs,
  EditProfileMutationArgs,
  InputCopyProfile,
  InputCreateProfile,
  InputEditProfile,
  Maybe,
  Profile,
  UnstageProfileMutationArgs
} from '@server/graphql/typeDefs/types'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import type { TStoreProfileGenericCheckers } from './StoreProfileGenericCheckers'
import StoreProfileGenericCheckers from './StoreProfileGenericCheckers'
import type { IModalProfileData } from './types'

/**
 * Root store for profiles management.
 *
 * Stores composition (non exhaustive)
 * -----------------------------------
 *
 * StoreProfiles
 *
 *  - StoreWidgetList                                             // List of profiles
 *  - StoreForm*                                                  // Creation/edition form (main fields)
 *  - StoreFlags*                                                 // Profiles loading
 *
 *  - StoreProfileGenericCheckers<ICheckerExposure> /
 *    StoreProfileGenericCheckers<ICheckerAttack>                 // Generic checkers options configuration
 *    - StoreFlags*                                               // Generic checkers options loading
 *    - StoreInputGenericCheckers<any>                            // Generic checkers selection
 *
 *    - Map<CheckerCodename, Array<StoreProfileCheckerSerie<GC>>  // Mapping generic checkers <-> StoreProfileCheckerSerie[]
 *      - StoreInputInfrastructure                                // Domains selections
 *      - StoreForm                                               // Generic checkers options form
 */
export default class StoreProfiles extends StoreBase {
  // list of profiles
  public storeWidgetList = new StoreWidgetList<EntityProfile, IDataRowProfile>(
    this.storeRoot,
    {
      selectable: false
    }
  )

  // main fields
  public storeFormCreation = new StoreForm(this.storeRoot, {
    setup: {
      fields: {
        [ProfileFieldName.name]: {
          label: 'Name',
          sanitizors: [transformSlashesToPipes()],
          validators: [
            mandatory(),
            profileNameFormat(),
            profileNameIsNotEqualsToTenableFormat()
          ]
        },
        [ProfileFieldName.fromProfileSelection]: {
          label: 'Copy from'
        }
      }
    }
  })

  public storeFormEdition = new StoreForm(this.storeRoot, {
    setup: {
      fields: {
        [ProfileFieldName.name]: {
          label: 'Name',
          sanitizors: [transformSlashesToPipes()],
          validators: [
            mandatory(),
            profileNameFormat(),
            profileNameIsNotEqualsToTenableFormat()
          ]
        }
      }
    }
  })

  // flags
  public storeFlagsFetchProfiles = new StoreFlags(this.storeRoot)
  public storeFlagsReloadProfiles = new StoreFlags(this.storeRoot)
  public storeFlagsCreateProfile = new StoreFlags(this.storeRoot)
  public storeFlagsEditProfile = new StoreFlags(this.storeRoot)
  public storeFlagsUnstageProfile = new StoreFlags(this.storeRoot)
  public storeFlagsCommitProfile = new StoreFlags(this.storeRoot)
  public storeFlagsDeleteProfile = new StoreFlags(this.storeRoot)

  // translator
  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: [
      'Layout.MainMenu',
      'Errors',
      'Management.Accounts.Profiles.List',
      'Management.Accounts.Profiles.Creation',
      'Management.Accounts.Profiles.Edition',
      'Management.Accounts.Profiles.Configuration'
    ]
  })

  // menu
  public storeMenu = new StoreMenu<IMenuEntry<CheckerType>>(this.storeRoot)

  // Stores for checkers / attackTypes options configuration
  public storeProfileCheckersExposure = new StoreProfileGenericCheckers<
    ICheckerExposure,
    EntityCheckerOption
  >(this.storeRoot, {
    checkerType: CheckerType.Exposure
  })

  public storeProfileCheckersAttack = new StoreProfileGenericCheckers<
    ICheckerAttack,
    EntityAttackTypeOption
  >(this.storeRoot, {
    checkerType: CheckerType.Attack
  })

  // modals
  public storeModalProfileMax = new StoreModal(this.storeRoot)
  public storeModalProfileBeforeCommit = new StoreModal<IModalProfileData>(
    this.storeRoot
  )

  // drawers
  public storeDrawerDeleteProfile = new StoreDrawer<{
    profileDataRow: IDataRowProfile
  }>(this.storeRoot)

  /* Observables */

  // current profileId that being edited
  private $currentProfileId = observable.box<Maybe<number>>(null)

  // keys are profileId
  private $profiles = observable.map<number, EntityProfile>()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch profiles for listing.
   */
  fetchProfiles(storeFlags = this.storeFlagsFetchProfiles): Promise<any> {
    storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryProfiles>(queryProfiles)
      })
      .then(data => data.profiles)
      .then(profiles => {
        if (!profiles) {
          throw new Error('Profiles are not defined.')
        }

        // update profiles in the storeAuthentication that keep profiles entities globally
        this.storeRoot.stores.storeAuthentication.setProfiles(profiles)

        const profilesEntities = createEntities<Profile, EntityProfile>(
          EntityProfile,
          profiles
        )

        this.setProfiles(profilesEntities)

        this.storeWidgetList.setEntities(profilesEntities)
        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Reload the profiles with a different StoreFlags instance.
   */
  reloadProfiles(): Promise<any> {
    return this.fetchProfiles(this.storeFlagsReloadProfiles)
  }

  /**
   * Create a profile
   * and refresh profiles.
   */
  createProfile(profile: InputCreateProfile): Promise<any> {
    this.storeFlagsCreateProfile.loading()

    return Promise.resolve()
      .then(() => {
        const args: CreateProfileMutationArgs = { profile }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationCreateProfile>(mutationCreateProfile, args)
      })
      .then(response => {
        if (response.createProfile) {
          this.setCurrentProfile(response.createProfile.id)
        }

        return this.fetchProfiles()
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Profile X created', {
            interpolations: {
              profileName: profile.name
            }
          }),
          {
            labelledBy: 'profileCreated'
          }
        )

        this.storeFlagsCreateProfile.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsCreateProfile, {
          forwardExceptionFn: () =>
            'An error has occurred when creating the profile'
        })
      )
  }

  /**
   * Copy a profile from an another profile
   * and refresh profiles.
   */
  copyProfile(profile: InputCopyProfile): Promise<any> {
    this.storeFlagsCreateProfile.loading()

    return Promise.resolve()
      .then(() => {
        const args: CopyProfileMutationArgs = { profile }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationCopyProfile>(mutationCopyProfile, args)
      })
      .then(response => {
        if (response.copyProfile) {
          this.setCurrentProfile(response.copyProfile.id)
        }

        return this.fetchProfiles()
      })
      .then(() => {
        const lastProfile = Array.from(this.profiles.values()).pop()

        if (!lastProfile) {
          return
        }

        this.storeRoot.stores.storeMessages.success(
          this.translate('Profile X created', {
            interpolations: {
              profileName: lastProfile.getPropertyAsString('name')
            }
          }),
          {
            labelledBy: 'copyProfileCreated'
          }
        )

        this.storeFlagsCreateProfile.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsCreateProfile, {
          forwardExceptionFn: () =>
            'An error has occurred when copying the profile'
        })
      )
  }

  /**
   * Edit an profile.
   */
  editProfile(profile: InputEditProfile) {
    this.storeFlagsEditProfile.loading()

    return Promise.resolve()
      .then(() => {
        const args: EditProfileMutationArgs = {
          profile
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<QueryProfiles>(mutationEditProfile, args)
      })
      .then(() => this.fetchProfiles())
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Profile X edited', {
            interpolations: {
              profileName: profile.name
            }
          }),
          {
            labelledBy: 'profileEdited'
          }
        )

        this.storeFlagsEditProfile.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsEditProfile, {
          forwardExceptionFn: () =>
            'An error has occurred when editing the profile'
        })
      )
  }

  /**
   * Unstage a profile.
   */
  unstageProfile(profileId: number, options: { dontNotify?: boolean } = {}) {
    this.storeFlagsUnstageProfile.loading()

    const profileEntity = this.$profiles.get(profileId)
    const profileName =
      (profileEntity && profileEntity.getPropertyAsString('name')) ||
      this.translate('Unknown profile')

    return Promise.resolve()
      .then(() => {
        const args: UnstageProfileMutationArgs = {
          profile: {
            id: profileId
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<QueryProfiles>(mutationUnstageProfile, args)
      })
      .then(() => {
        if (!options.dontNotify) {
          this.storeRoot.stores.storeMessages.success(
            this.translate('Profile X unstaged', {
              interpolations: {
                profileName
              }
            }),
            {
              labelledBy: 'profileUnstaged'
            }
          )
        }

        this.storeFlagsUnstageProfile.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsUnstageProfile, {
          forwardExceptionFn: () =>
            'An error has occurred when unstaging the profile'
        })
      )
  }

  /**
   * Commit a profile.
   */
  commitProfile(profileId: number, profileName: string) {
    this.storeFlagsCommitProfile.loading()

    return Promise.resolve()
      .then(() => {
        const args: CommitProfileMutationArgs = {
          profile: {
            id: profileId
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<QueryProfiles>(mutationCommitProfile, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Profile X commit', {
            interpolations: {
              profileName
            }
          }),
          {
            labelledBy: 'profileCommit'
          }
        )

        this.storeFlagsCommitProfile.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsCommitProfile, {
          forwardExceptionFn: () =>
            'An error has occurred when committing the profile'
        })
      )
  }

  /**
   * Delete a profile.
   */
  deleteProfile(profileId: number, profileName: string) {
    this.storeFlagsDeleteProfile.loading()

    return Promise.resolve()
      .then(() => {
        const args: DeleteProfileMutationArgs = {
          profile: {
            id: profileId
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<QueryProfiles>(mutationDeleteProfile, args)
      })
      .then(() => this.fetchProfiles())
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Profile X deleted', {
            interpolations: {
              profileName
            }
          }),
          {
            labelledBy: 'profileDeleted'
          }
        )

        this.storeFlagsDeleteProfile.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsDeleteProfile, {
          forwardExceptionFn: () =>
            'An error has occurred when deleting the profile'
        })
      )
  }

  /**
   * Return true if the maximum number of profiles is reached.
   */
  hasReachNumberOfMaximalProfiles(): boolean {
    return (
      // substract the Tenable profile
      this.profiles.size - 1 >=
      this.storeRoot.environment.config.app.settings.nbmaxprofiles
    )
  }

  /* Actions */

  /**
   * Reset everything.
   */
  @action
  reset(): void {
    this.storeFormCreation.reset()
    this.storeFormEdition.reset()

    this.storeMenu.reset()

    this.storeProfileCheckersExposure.reset()
    this.storeProfileCheckersAttack.reset()
  }

  @action
  setProfiles(profiles: EntityProfile[]): void {
    this.$profiles.clear()

    profiles.forEach(entity => {
      if (entity.id) {
        this.$profiles.set(entity.id, entity)
      }
    })
  }

  @action
  setCurrentProfile(profileId: number): void {
    this.$currentProfileId.set(profileId)
  }

  /* Computed */

  /**
   * Return the store StoreProfileGenericCheckers depending of the current selected
   * checker type.
   */
  @computed
  get storeProfileGenericCheckersSelected(): TStoreProfileGenericCheckers {
    const instance =
      this.storeMenu.selectedMenuKey === CheckerType.Exposure
        ? this.storeProfileCheckersExposure
        : this.storeProfileCheckersAttack

    return instance as TStoreProfileGenericCheckers
  }

  /**
   * Return the map of profiles.
   */
  @computed
  get profiles(): Map<number, EntityProfile> {
    return toJS(this.$profiles)
  }

  /**
   * Return true if the current profile is readonly:
   * The Tenable profile by default, or whatever profile that has only a
   * read permission.
   */
  @computed
  get isCurrentProfileReadOnly(): boolean {
    const { storeRbac } = this.storeRoot.stores

    const currentProfileEntity = this.currentProfileEntity

    if (!currentProfileEntity) {
      return true
    }

    return currentProfileEntity.isReadableOnly(storeRbac)
  }

  /**
   * Return true if the current profile is dirty (has staged checker-options).
   */
  @computed
  get isCurrentProfileDirty(): boolean {
    const currentProfileEntity = this.currentProfileEntity

    if (!currentProfileEntity) {
      return false
    }

    return currentProfileEntity.getPropertyAsBoolean('dirty') === true
  }

  @computed
  get currentProfileId(): Maybe<number> {
    return this.$currentProfileId.get()
  }

  @computed
  get currentProfileEntity(): Maybe<EntityProfile> {
    const profileId = this.$currentProfileId.get()

    if (!profileId) {
      return null
    }

    return this.$profiles.get(profileId) || null
  }
}
