import { LabelInfrastructure } from '@app/components/Labels'
import type { IInfrastructureConfigurationChanged } from '@app/entities/EntityUserLog'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import React from 'react'

const UserLogInfrastructure = (props: IInfrastructureConfigurationChanged) => {
  const { storeActivityLogs } = useStores()
  const { infrastructureId, infrastructureName } = props

  if (!infrastructureId || !infrastructureName) {
    return null
  }

  const color = storeActivityLogs.infrastructureColors.get(infrastructureId)

  return (
    <LabelInfrastructure
      label={infrastructureName}
      color={consts.colorWhite}
      labelTagColor={color}
      className="UserTraceInfrastructure"
      labelProperties={{ color: consts.colorWhite }}
    />
  )
}

export default UserLogInfrastructure
