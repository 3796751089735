import Blade from '@app/components-legacy/Blade/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditDashboardOrWidget } from '../permissions'
import { onWidgetEditPageLoad, onWidgetEditPageUnload } from './handlers'
import WidgetEditForm from './WidgetEditForm'

interface IWidgetEditPageProps {}

const WidgetEditPage: React.FC<IWidgetEditPageProps> = props => {
  const appRouter = useAppRouter()

  const { storeDashboards, storeAuthentication } = useStores()

  const storeDashboard = storeDashboards.currentStoreDashboard

  if (!storeDashboard) {
    return null
  }

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Dashboard_Grid_WidgetEdit,
    parameters: {
      instanceName: String(),
      dashboardId: Number(),
      dashboardWidgetId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Dashboard_Grid_WidgetEdit,
        parameters: {
          instanceName: parameters.instanceName,
          dashboardId: parameters.dashboardId,
          dashboardWidgetId: parameters.dashboardWidgetId
        }
      }}
      onLoad={onWidgetEditPageLoad(storeAuthentication, storeDashboard)(
        parameters.dashboardId,
        parameters.dashboardWidgetId
      )}
      onUnload={onWidgetEditPageUnload(storeDashboard)}
      flags={storeDashboard.storeWidgetsManagement.storeFlags.flags}
      layout={{
        name: 'default',
        content: (
          <ContainerContent>
            <WidgetEditForm storeDashboard={storeDashboard} />
          </ContainerContent>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canEditDashboardOrWidget(
        parameters.dashboardWidgetId
      )}
    />
  )
}

export default observer(WidgetEditPage)
