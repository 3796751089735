import { ContainerFlex, ContainerForm } from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import type StoreProfileCheckerOptionArrayObject from '@app/stores/Management/StoreProfiles/StoreProfileCheckerOptionOWCT'
import { CheckerOptionOWCTConfigurationFieldName as FieldName } from '@app/stores/Management/StoreProfiles/types'
import { Input } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import GroupConfigurationTitle from './GroupConfigurationTitle'

interface IGroupConfigurationProps {
  index: number
  highlight: boolean
  readonly: boolean
  storeFormConfiguration: StoreForm<FieldName>
  storeProfileCheckerOptionArrayObject: StoreProfileCheckerOptionArrayObject
}

const GroupConfiguration: React.FC<IGroupConfigurationProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Profiles.Configuration']
  })

  /**
   * Return the fields of the option configuration according to the StoreForm
   * instance passed in props.
   */
  const serieFields: IField[] = [
    {
      name: FieldName.displayName,
      label: translate(FieldName.displayName),
      errors: props.storeFormConfiguration.field(FieldName.displayName).errors,
      highlight: props.highlight,
      control: (
        <Input
          data-name="string"
          name={FieldName.displayName}
          value={props.storeFormConfiguration.getFieldValueAsString(
            FieldName.displayName
          )}
          disabled={props.readonly}
          onChange={onInputChange(props.storeFormConfiguration)(
            FieldName.displayName
          )}
        />
      )
    },
    {
      name: FieldName.trusteeId,
      label: translate(FieldName.trusteeId),
      errors: props.storeFormConfiguration.field(FieldName.trusteeId).errors,
      highlight: props.highlight,
      control: (
        <Input
          data-name="string"
          name={FieldName.trusteeId}
          value={props.storeFormConfiguration.getFieldValueAsString(
            FieldName.trusteeId
          )}
          disabled={props.readonly}
          onChange={onInputChange(props.storeFormConfiguration)(
            FieldName.trusteeId
          )}
        />
      )
    }
  ]

  return (
    <ContainerFlex
      name={GroupConfiguration.name}
      direction="column"
      items={[
        <GroupConfigurationTitle
          index={props.index}
          storeProfileCheckerOptionArrayObject={
            props.storeProfileCheckerOptionArrayObject
          }
        />,

        <ContainerForm
          fields={serieFields}
          variant={ContainerFormVariant.fill}
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(GroupConfiguration)
