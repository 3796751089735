import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  ContainerContent,
  ContainerFooter,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import {
  Drawer,
  DrawerCancelButton,
  DrawerContext,
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { LabelConfirm } from '@app/components-legacy/Label'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canDeleteDirectory } from '../../permissions'
import { onDirectoryDeleteValidate } from './handlers'

export interface IDrawerActionDeleteDirectoryProps {}

const DrawerActionDeleteDirectory: React.FC<
  IDrawerActionDeleteDirectoryProps
> = () => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.System.Directories']
  })

  const { storeManagementDirectories } = useStores()
  const { storeDeleteDrawer } = storeManagementDirectories

  const directoryDataRow = storeDeleteDrawer.getDataValue('directoryDataRow')

  const renderContent = () => {
    if (!directoryDataRow) {
      return null
    }

    const isLoading =
      storeManagementDirectories.storeDeletionFlags.flags.isLoading

    return (
      <FormWrapper
        name="directoryDeletion"
        onSubmit={onDirectoryDeleteValidate(storeManagementDirectories)(
          directoryDataRow
        )}
      >
        <ContainerContent
          flags={storeManagementDirectories.storeFlags.flags}
          spinner={<SpinnerInline />}
        >
          <ContainerHTMLContent>
            {translate('You will delete the domain X', {
              transformMarkdown: true,
              interpolations: {
                name: directoryDataRow.name
              }
            })}
          </ContainerHTMLContent>

          <LabelConfirm />
        </ContainerContent>

        <Portal name={PlaceHolderName.drawerFooter}>
          <ContainerFooter>
            <DrawerCancelButton />

            <FormWrapperButtonSubmit loading={isLoading} danger>
              {translate('Delete')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </FormWrapper>
    )
  }

  return (
    <DrawerContext.Provider value={{ storeDrawer: storeDeleteDrawer }}>
      <Drawer>
        <DrawerHorizontalContainer>
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: <DrawerTitle>{translate('Delete a domain')}</DrawerTitle>,
              content: <ContainerContent>{renderContent()}</ContainerContent>,
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canDeleteDirectory(
              directoryDataRow?.id || null
            )}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerActionDeleteDirectory)
