import type { Maybe } from '@@types/helpers'
import type { EntityProfile } from '@app/entities'
import type { IDataRowProfile } from '@app/entities/EntityProfile'
import { AppRouteName } from '@app/routes'
import type { StoreManagementProfiles } from '@app/stores'
import type { IModalProfileData } from '@app/stores/Management/StoreProfiles/types'

/**
 * Load the profiles.
 */
export const handleProfilesOnLoad =
  (storeManagementProfiles: StoreManagementProfiles) => (): void => {
    storeManagementProfiles.fetchProfiles()
  }

/**
 * Check that there is enough slots to create a profile,
 * If yes, open the creation profile blade.
 */
export const handleProfileCreateButtonOnClick =
  (storeManagementProfiles: StoreManagementProfiles) => (): void => {
    if (storeManagementProfiles.hasReachNumberOfMaximalProfiles()) {
      storeManagementProfiles.storeModalProfileMax.show()
      return
    }

    const { appRouter } = storeManagementProfiles.storeRoot

    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_Accounts_Profiles_Create,
      parameters: {}
    })

    appRouter.history.push(url)
  }

/**
 * Open the profile edition blade.
 */
export const handleProfileEditIconOnClick =
  (storeManagementProfiles: StoreManagementProfiles) =>
  (profileId: number) =>
  (): void => {
    const { appRouter } = storeManagementProfiles.storeRoot

    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_Accounts_Profiles_Edit,
      parameters: {
        profileId
      }
    })

    appRouter.history.push(url)
  }

/**
 * Open the profile deletion drawer.
 */
export const handleProfileDeleteIconOnClick =
  (storeManagementProfiles: StoreManagementProfiles) =>
  (profileId: number, isCurrentProfile: boolean) =>
  (): void => {
    if (isCurrentProfile) {
      return
    }

    const profileEntity = storeManagementProfiles.profiles.get(profileId)

    if (!profileEntity) {
      return
    }

    storeManagementProfiles.storeDrawerDeleteProfile
      .setData({ profileDataRow: profileEntity.asDataRow() })
      .openDrawer()
  }

/**
 * Delete the profile, close the drawer and reload profiles.
 */
export const handleProfileDeleteOnSubmit =
  (storeManagementProfiles: StoreManagementProfiles) =>
  (dataRow: IDataRowProfile) =>
  (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    storeManagementProfiles
      .deleteProfile(dataRow.id, dataRow.name)
      .then(() => {
        storeManagementProfiles.storeDrawerDeleteProfile.closeDrawer()
        return storeManagementProfiles.fetchProfiles()
      })
      .catch(() => {
        // already catched in the store
      })
  }

/**
 * Unstage the profile (revert all checker-options modifications),
 * Redirect to the list
 * And reload profiles.
 */
export const handleProfileUnstageOnClick =
  (storeManagementProfiles: StoreManagementProfiles) =>
  (profileEntity: Maybe<EntityProfile>) =>
  (): Promise<void> => {
    const { storeRoot } = storeManagementProfiles
    const { storeMessages } = storeRoot.stores
    const { appRouter, logger } = storeRoot

    if (!profileEntity) {
      storeMessages.error('Unable to unstage the profile', {
        labelledBy: 'unableUnstageProfile'
      })
      logger.debug('The current profile entity is undefined.')
      return Promise.resolve()
    }

    return storeManagementProfiles
      .unstageProfile(profileEntity.getPropertyAsNumber('id'))
      .then(() => {
        const destRouteName = AppRouteName.Management_Accounts_Profiles
        const currentRouteName = appRouter.getCurrentRouteName()

        if (currentRouteName !== destRouteName) {
          // redirect to the list
          const url = appRouter.makeRouteInfosPathname({
            routeName: destRouteName,
            parameters: {}
          })
          appRouter.history.push(url)
        }
      })
      .then(() => storeManagementProfiles.fetchProfiles())
      .catch(() => {
        /* Already catched in the store */
      })
  }

/**
 * Show the modalbox just before to commit.
 */
export const handleProfileCommitOnClick =
  (storeManagementProfiles: StoreManagementProfiles) =>
  (profileId: number, profileName: string) =>
  (): void => {
    storeManagementProfiles.storeModalProfileBeforeCommit
      .setData({ profileId, profileName })
      .show()
  }

/**
 * Hide the modalbox to abort the commit process.
 */
export const handleProfileCancelCommitOnClick =
  (storeManagementProfiles: StoreManagementProfiles) => (): void => {
    storeManagementProfiles.storeModalProfileBeforeCommit.hide()
  }

/**
 * Commit the profile (apply staged checker-options),
 * Redirect to the list
 * And reload profiles.
 */
export const handleProfileCommitOnSubmit =
  (storeManagementProfiles: StoreManagementProfiles) =>
  (profileData: Maybe<IModalProfileData>) =>
  (): Promise<void> => {
    const { storeRoot } = storeManagementProfiles
    const { storeMessages } = storeRoot.stores
    const { appRouter, logger } = storeRoot

    if (!profileData) {
      storeMessages.error('Unable to commit the profile', {
        labelledBy: 'unableCommitProfile'
      })

      logger.debug('The current profile entity is undefined.')
      return Promise.resolve()
    }

    return storeManagementProfiles
      .commitProfile(profileData.profileId, profileData.profileName)
      .then(() => {
        const destRouteName = AppRouteName.Management_Accounts_Profiles
        const currentRouteName = appRouter.getCurrentRouteName()

        if (currentRouteName !== destRouteName) {
          // redirect to the list
          const url = appRouter.makeRouteInfosPathname({
            routeName: destRouteName,
            parameters: {}
          })
          appRouter.history.push(url)
        }
      })
      .then(() => storeManagementProfiles.storeModalProfileBeforeCommit.hide())
      .then(() => storeManagementProfiles.fetchProfiles())
      .catch(() => {
        /* Already catched in the store */
      })
  }
