import { createEntities, EntityPagination } from '@app/entities'
import type { IDataRowFinding } from '@app/entities/EntityGenericChecker/EntityFinding'
import EntityFinding from '@app/entities/EntityGenericChecker/EntityFinding'
import type { StoreRoot } from '@app/stores'
import { StoreInputTenants } from '@app/stores'
import StoreDatePicker from '@app/stores/helpers/StoreDatePicker'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import type { IPartialPagination } from '@app/stores/Identity/types'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { filterNullOrUndefinedValues } from '@libs/helpers/objects/filterNullOrUndefinedValues'
import { isDefined } from '@libs/isDefined'
import type {
  Finding,
  GetFindingsByCheckerIdRequest
} from '@libs/openapi/service-identity-core'
import { action, makeObservable } from 'mobx'

export default class StoreIndicatorFindings extends StoreBase {
  public storeFlagsFetchFindings = new StoreFlags(this.storeRoot)
  public storeFlagsReloadFindings = new StoreFlags(this.storeRoot)

  public storeDatePicker = new StoreDatePicker(this.storeRoot, {
    defaultDateStart: null,
    defaultDateEnd: null
  })

  public storeInputTenants = new StoreInputTenants(this.storeRoot)

  public storeInputSearchFinding = new StoreInputSearch(this.storeRoot)

  public storeWidgetListFindings = new StoreWidgetList<
    EntityFinding,
    IDataRowFinding
  >(this.storeRoot, {
    selectable: false,
    // build dataSets with IDataRowDeviantAdObject rows
    buildDataSetFn: entities => {
      if (!entities.length) {
        return {
          columns: [],
          rows: []
        }
      }

      const columns = entities[0].getColumns()
      const rows = entities.map(entity => entity.asDataRow()).filter(isDefined)

      return { columns, rows }
    }
  })

  /* Drawers */

  public storeDrawerExportFindings = new StoreDrawer(this.storeRoot)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch findings by checker id from service-identity-core.
   */
  fetchFindingsByCheckerId(
    checkerId: string,
    pagination: IPartialPagination,
    storeFlags = this.storeFlagsFetchFindings
  ): Promise<void> {
    storeFlags.loading()

    return this.storeRoot.environment.openApiClients.serviceIdentityCore.checkers
      .getFindingsByCheckerId(
        this.findingsParametersFromFilters(
          checkerId,
          EntityPagination.fromDefaultAttributes(pagination)
        )
      )
      .then(findings => {
        const { items, pagination } = findings

        const entities = createEntities<Finding, EntityFinding>(
          EntityFinding,
          items
        )

        this.storeWidgetListFindings.setEntities(entities)

        const entityPagination =
          EntityPagination.fromPaginationOffset(pagination)

        this.storeWidgetListFindings.setPagination(entityPagination)

        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Return request parameters for filters / pagination.
   */
  findingsParametersFromFilters(
    checkerId: string,
    pagination: EntityPagination
  ): GetFindingsByCheckerIdRequest {
    return filterNullOrUndefinedValues({
      checkerId,
      textQuery:
        this.storeInputSearchFinding.searchValue !== ''
          ? this.storeInputSearchFinding.searchValue
          : undefined,
      startDetectionDate:
        this.storeDatePicker.datePickerRangeValueAsDate?.dateStart ?? undefined,
      endDetectionDate:
        this.storeDatePicker.datePickerRangeValueAsDate?.dateEnd ?? undefined,
      // FIXME API Core side
      tenantIds: this.storeInputTenants.selectedTenantIds.length
        ? this.storeInputTenants.selectedTenantIds
        : undefined,
      ...pagination.computePaginationOffset()
    })
  }

  /**
   * Actions
   */

  @action
  reset(): this {
    this.storeFlagsFetchFindings.reset()
    this.storeFlagsReloadFindings.reset()
    this.storeDatePicker.reset()
    this.storeInputSearchFinding.reset()
    this.storeWidgetListFindings.reset()

    return this
  }
}
