import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import SyslogCommonForm from '../SyslogCommonForm'
import { onSyslogCreateSubmit } from './handlers'

interface ISyslogCreateFormProps {}

const SyslogCreateForm: React.FC<ISyslogCreateFormProps> = () => {
  const { storeManagementSyslogs } = useStores()

  return (
    <SyslogCommonForm
      version="creation"
      onSubmit={onSyslogCreateSubmit(storeManagementSyslogs)}
    />
  )
}

export default SyslogCreateForm
