import type { EntityPagination } from '@app/entities'
import {
  createEntities,
  createEntity,
  EntityInfrastructure
} from '@app/entities'
import type { IDataRowInfrastructure } from '@app/entities/EntityInfrastructure'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { mandatory } from '@app/stores/helpers/StoreForm/validators'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { checkRbac } from '@libs/rbac/functions'
import type {
  MutationCreateInfrastructure,
  MutationDeleteInfrastructure,
  MutationEditInfrastructure
} from '@server/graphql/mutations/infrastructure'
import {
  mutationCreateInfrastructure,
  mutationDeleteInfrastructure,
  mutationEditInfrastructure
} from '@server/graphql/mutations/infrastructure'
import type { QueryRbacInfrastructures } from '@server/graphql/queries/infrastructure'
import { queryRbacInfrastructures } from '@server/graphql/queries/infrastructure'
import type {
  CreateInfrastructureMutationArgs,
  DeleteInfrastructureMutationArgs,
  EditInfrastructureMutationArgs,
  Infrastructure,
  InputCreateInfrastructure,
  InputEditInfrastructure,
  Pagination,
  RbacInfrastructuresQueryArgs
} from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '..'
import StoreDrawer from '../helpers/StoreDrawer'
import StoreFlags from '../helpers/StoreFlags'
import StoreForm from '../helpers/StoreForm'
import { StoreInputSearch } from '../helpers/StoreInputSearch'
import StoreWidgetList from '../helpers/StoreWidgetList'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'

export enum InfrastructuresFormFieldName {
  name = 'name',
  login = 'login',
  password = 'password'
}

export default class StoreInfrastructures extends StoreBase {
  public storeWidgetList = new StoreWidgetList<
    EntityInfrastructure,
    IDataRowInfrastructure
  >(this.storeRoot, {
    selectable: false
  })

  public storeFormCreation = new StoreForm<InfrastructuresFormFieldName>(
    this.storeRoot,
    {
      setup: {
        fields: {
          [InfrastructuresFormFieldName.name]: {
            label: 'Name',
            description: 'Name of the forest',
            validators: [mandatory()]
          },
          [InfrastructuresFormFieldName.login]: {
            label: 'Login',
            description: 'Login of the account used by Tenable.ad',
            validators: [mandatory()]
          },
          [InfrastructuresFormFieldName.password]: {
            label: 'Password',
            description: 'Password of the account used by Tenable.ad',
            validators: [mandatory()],
            inputType: InputType.inputPassword
          }
        }
      }
    }
  )

  public storeFormEdition = new StoreForm<InfrastructuresFormFieldName>(
    this.storeRoot,
    {
      setup: {
        fields: {
          [InfrastructuresFormFieldName.name]: {
            label: 'Name',
            description: 'Name of the forest',
            validators: [mandatory()]
          },
          [InfrastructuresFormFieldName.login]: {
            label: 'Login',
            description: 'Login of the account used by Tenable.ad',
            validators: [mandatory()]
          },
          [InfrastructuresFormFieldName.password]: {
            label: 'Password',
            description: 'Enter a new password only if you want to change it',
            inputType: InputType.inputPassword
          }
        }
      }
    }
  )

  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  public storeDeleteDrawer = new StoreDrawer<{
    infrastructureDataRow: IDataRowInfrastructure
  }>(this.storeRoot)
  public storeFlags = new StoreFlags(this.storeRoot)
  public storeCreationFlags = new StoreFlags(this.storeRoot)
  public storeDeletionFlags = new StoreFlags(this.storeRoot)
  public storeSearchFlags = new StoreFlags(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Errors', 'Management.System.Infrastructures']
  })

  /* Observables */

  // keys are infrastructure id
  private $infrastructures = observable.map<number, EntityInfrastructure>()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  fetchInfrastructures(
    _args?: RbacInfrastructuresQueryArgs,
    storeFlags = this.storeFlags
  ): Promise<void> {
    const args: RbacInfrastructuresQueryArgs = {
      infrastructuresPage: this.storeWidgetList.paginationPage,
      infrastructuresPerPage: this.storeWidgetList.rowsPerPage,
      ..._args
    }

    storeFlags.loading()

    return (
      Promise.resolve()
        .then(() => {
          return this.storeRoot
            .getGQLRequestor()
            .query<QueryRbacInfrastructures>(queryRbacInfrastructures, args)
        })
        .then(data => data.rbacInfrastructures)
        .then(infrastructures => {
          if (!checkRbac(this.storeRoot, storeFlags)(infrastructures)) {
            throw new ForbiddenAccessError()
          }

          const infrastructuresEntities = createEntities<
            Infrastructure,
            EntityInfrastructure
          >(EntityInfrastructure, infrastructures.node.node)

          const infrastructuresPagination = createEntity<
            Pagination,
            EntityPagination
          >(EntityInfrastructure, infrastructures.node.pagination)

          this.setInfrastructures(infrastructuresEntities)

          this.storeWidgetList.setEntities(infrastructuresEntities)
          this.storeWidgetList.setPagination(infrastructuresPagination)
        })
        // resync the infras <-> dirs mapping
        .then(() =>
          this.storeRoot.stores.storeInfrastructures.fetchInfrastructures()
        )
        .then(() => {
          storeFlags.success()
        })
        .catch(handleStoreError(this.storeRoot, storeFlags))
    )
  }

  fetchInfrastructuresWithName(
    _args?: RbacInfrastructuresQueryArgs
  ): Promise<void> {
    const args: RbacInfrastructuresQueryArgs = {
      infrastructuresPage: 1,
      infrastructureName: this.storeInputSearch.searchValue,
      ..._args
    }

    return this.fetchInfrastructures(args, this.storeSearchFlags)
  }

  /**
   * Create an infrastructure,
   * Refetch infrastructures.
   */
  createInfrastructure(infrastructure: InputCreateInfrastructure) {
    this.storeCreationFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: CreateInfrastructureMutationArgs = {
          infrastructure
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationCreateInfrastructure>(
            mutationCreateInfrastructure,
            args
          )
      })
      .then(() => {
        this.storeFormCreation.reset()
        this.storeFormEdition.reset()
        return this.fetchInfrastructures()
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Forest X created', {
            interpolations: {
              infrastructureName: infrastructure.name
            }
          }),
          {
            labelledBy: 'infrastructureCreated'
          }
        )

        this.storeCreationFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeCreationFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when creating the infrastructure'
        })
      )
  }

  /**
   * Edit an infrastructure.
   */
  editInfrastructure(infrastructure: InputEditInfrastructure) {
    this.storeCreationFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: EditInfrastructureMutationArgs = {
          infrastructure
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationEditInfrastructure>(mutationEditInfrastructure, args)
      })
      .then(() => {
        // reload infrastructures (and remake the mapping between infras and dirs)
        return this.fetchInfrastructures()
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Forest X updated', {
            interpolations: {
              infrastructureName: infrastructure.name
            }
          }),
          {
            labelledBy: 'infrastructureUpdated'
          }
        )

        this.storeCreationFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeCreationFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when editing the infrastructure'
        })
      )
  }

  /**
   * Delete an infrastructure.
   */
  deleteInfrastructure(infrastructureId: number, infrastructureName: string) {
    this.storeDeletionFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: DeleteInfrastructureMutationArgs = {
          infrastructure: {
            id: infrastructureId
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationDeleteInfrastructure>(
            mutationDeleteInfrastructure,
            args
          )
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Forest X deleted', {
            interpolations: {
              infrastructureName
            }
          }),
          {
            labelledBy: 'infrastructureDeleted'
          }
        )

        this.storeDeletionFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeDeletionFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when deleting the infrastructure'
        })
      )
  }

  @action
  setInfrastructures(infrastructures: EntityInfrastructure[]) {
    this.$infrastructures.clear()

    infrastructures.forEach(entity => {
      if (entity.id) {
        this.$infrastructures.set(entity.id, entity)
      }
    })
  }

  /* Actions */

  @action
  reset(): this {
    this.storeSearchFlags.reset()
    this.storeInputSearch.reset()

    return this
  }

  /* Computed */

  @computed
  get infrastructures() {
    return this.$infrastructures
  }
}
