import StoreInputReasons from '@app/stores/StoreInputReasons'
import * as React from 'react'

/**
 * Initialize the drawer reasons.
 */
export function useDrawerInputReasonsInit(
  storeInputReasons: StoreInputReasons
) {
  // create a new memoized storeInfrastructure for pending selection
  const storePendingReasons = React.useMemo(() => {
    return new StoreInputReasons(
      storeInputReasons.storeRoot,
      storeInputReasons.options
    )
  }, [])

  // when the drawer is opened, fetch the reasons
  // and select the same infras that the storeReasons passed as param.
  React.useEffect(() => {
    if (!storeInputReasons.storeDrawer.isDrawerOpened) {
      return
    }

    // if there are already reasons, return
    if (storePendingReasons.reasons.size > 0) {
      return
    }

    storePendingReasons.fetchReasons().then(() => {
      storePendingReasons.selectReasons(storeInputReasons.selectedReasonIds)
    })
  }, [storeInputReasons.storeDrawer.isDrawerOpened])

  return storePendingReasons
}
