import { ContainerFlex } from '@app/components/Container'
import { LabelSliced } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import FormWrapperCheckboxSimple from '@app/components-legacy/Form/Wrappers/FormWrapperCheckboxSimple'
import { consts } from '@app/styles'
import { getCriticityColor } from '@libs/criticity'
import { Criticity } from '@server/graphql/typeDefs/types'
import type Maybe from 'graphql/tsutils/Maybe'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

interface IAttackTypeCellProps {
  criticity: Maybe<Criticity>
  checked: boolean
  indeterminate: boolean
  name: string
  codename?: string
  onChange?: (checked: boolean) => void
}

const HrCustom = styled.hr`
  width: 2px;
  height: 16px;
  border: 0;
  border-radius: 22px;
  margin: 0 ${consts.marginSmaller} 0 ${consts.marginDefault};
  flex-shrink: 0;
`

const CellAttackType: React.FC<IAttackTypeCellProps> = props => {
  return (
    <ContainerFlex
      name="LabelCheckerTitle"
      justifyContent="space-between"
      alignItems="center"
      items={[
        <FormWrapperCheckboxSimple
          labelledBy={`checker/${props.codename || props.name}`}
          checked={props.checked}
          indeterminate={props.indeterminate}
          onChange={props.onChange}
        />,

        <HrCustom
          color={getCriticityColor(props.criticity || Criticity.Unknown)}
        />,

        <div className="checkerLabel">
          <LabelSliced
            breakString
            value={props.name}
            maxLength={40}
            labelProperties={{
              variant: LabelVariant.p
            }}
          />
        </div>
      ]}
    />
  )
}

export default observer(CellAttackType)
