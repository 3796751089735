import IconDown from '@app/components/Icon/IconDown'
import { IconDownOutlined } from '@app/components-legacy/Icon/IconAntd'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  position: relative;
`

const StyledIconDown = styled(IconDown)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  pointer-events: none;
`

const StyledIconDownOutlined = styled(IconDownOutlined)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 12px;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.25);
`

const StyledSelect = styled.select`
  width: 100%;
  height: 30px;
  cursor: pointer;
  color: ${props => props.theme.tokens['select/color/textSelected']};
  background-color: ${props =>
    props.theme.tokens['select/backgroundColor/default']};
  border: ${props => props.theme.tokens['select/border/default']};
  border-radius: ${props => props.theme.tokens['select/borderRadius/default']};
  padding-left: 11px;
  padding-right: ${props => (isThemeLegacy(props.theme) ? '28px' : '32px')};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  outline: 0;

  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  &:hover {
    border-color: ${consts.colorBlue010};
  }

  &:focus {
    border-color: ${consts.colorBlue010};
    box-shadow: ${props => props.theme.tokens['select/boxShadow/focus']};
  }
`

interface IInputSelectNative
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  labelledBy: string
}

/**
 * Native select stylised like the Antd one to use instead of the
 * Antd's one that is suffering from various bug with large set of values.
 */
const InputSelectNative: React.FC<IInputSelectNative> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const { testAttributeProps } = useTestAttribute('form')

  return (
    <StyledDiv>
      <StyledSelect
        {...props}
        {...testAttributeProps('listbox')(props.labelledBy)}
      >
        {props.children}
      </StyledSelect>
      {themeIsLegacy ? <StyledIconDownOutlined /> : <StyledIconDown />}
    </StyledDiv>
  )
}

export default InputSelectNative
