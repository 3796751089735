import { StoreInputHealthChecks } from '@app/stores'
import * as React from 'react'

/**
 * Initialize the drawer healthchecknames.
 */
export function useDrawerInputHealthChecksInit(
  storeInputHealthChecks: StoreInputHealthChecks
) {
  // create a new memoized storeInputHealthCheckNames for pending selection
  const storePendingHealthChecks = React.useMemo(() => {
    return new StoreInputHealthChecks(
      storeInputHealthChecks.storeRoot,
      storeInputHealthChecks.options
    )
  }, [])

  // when the drawer is opened, fetch the healthchecknames
  // and select the same healthchecknames that the storeHealthCheckNames passed as param.
  React.useEffect(() => {
    if (!storeInputHealthChecks.storeDrawer.isDrawerOpened) {
      return
    }

    storePendingHealthChecks.setMode(storeInputHealthChecks.mode)

    Promise.resolve()
      .then(() => {
        // fetch only if no healthchecknames
        if (storePendingHealthChecks.healthChecksTemplatesNames.size) {
          return Promise.resolve()
        }
        return storePendingHealthChecks.fetchHealthChecksTemplatesNames()
      })
      .then(() => {
        if (!storeInputHealthChecks.selectedHealthChecksTemplatesNames.size) {
          return
        }

        storePendingHealthChecks.selectHealthChecksTemplatesNames(
          storeInputHealthChecks.selectedHealthChecksTemplatesNamesIds
        )
      })
  }, [storeInputHealthChecks.storeDrawer.isDrawerOpened])

  return storePendingHealthChecks
}
