import type { Maybe } from '@server/graphql/typeDefs/types'
import * as React from 'react'

const EventDetailsPageContext = React.createContext<
  Maybe<{
    infrastructureId: number
    directoryId: number
    eventId: string
  }>
>(null)

export { EventDetailsPageContext }
