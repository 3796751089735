import type { Maybe } from '@@types/helpers'
import type { EntityEvent } from '@app/entities'
import type { Expression } from '@libs/Expression'

export type SetEventDirection = 'newer' | 'older'

export enum EventsTableStatus {
  // new idling
  isStatic = 0,
  // when receiving new events
  isLive = 1 << 0
}

/* Deviances types */

// Simpler structure to represent a deviance in the TrailFlow table.
export interface IEventDeviance {
  devianceId: number
  isTenableProfile: boolean
  profileId: number
  isResolved: boolean
}

export interface IEventDevianceUpdate {
  createdEventId: Maybe<number>
  resolvedEventId: Maybe<number>
  profileId: number
  resolvedAt: Maybe<string>
}

export interface IFetchEventsOptions {
  // expression (filter object) send to the API
  expression?: Expression
  // newer or older events (used when scrolling in the table) (Default: older)
  appendDirection?: SetEventDirection
  // number max of events per page
  nbEventsPerPage?: number
  // number max of pages
  nbPages?: number
  // clear existing events before fetching (Default: true)
  clearEvents?: boolean
}

export type DisplayedEvents = Array<[string, EntityEvent]>
