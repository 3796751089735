import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperSelect } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onPageSelectorChange } from './handlers'
import { WidgetListPageSelectorValue } from './types'

export interface IWidgetListSelectPageSelectProps {
  style?: React.CSSProperties
  className?: string
  storeWidgetList: StoreWidgetList<any, any>
}

const WidgetListSelectPageSelect: React.FC<
  IWidgetListSelectPageSelectProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.WidgetList'],
    storeOptionsI18n: props.storeWidgetList.options
  })

  return (
    <ContainerFlex
      name="WidgetListSelectPageSelect"
      className={props.className}
      style={props.style}
      alignItems="center"
      items={[
        <div>
          <FormWrapperSelect<WidgetListPageSelectorValue>
            labelledBy="pages"
            width="normal"
            selectProps={{
              size: 'small',
              defaultValue: WidgetListPageSelectorValue.unselectAllPages,
              onChange: onPageSelectorChange(props.storeWidgetList),
              onSelect: onPageSelectorChange(props.storeWidgetList)
            }}
            selectOptions={[
              {
                label: translate('Unselect all pages'),
                value: WidgetListPageSelectorValue.unselectAllPages
              },
              {
                label: translate('Select current page'),
                value: WidgetListPageSelectorValue.selectCurrentPage
              },
              {
                label: translate('Select all pages'),
                value: WidgetListPageSelectorValue.selectAllPages
              }
            ]}
          />
        </div>
      ]}
      spaceWidth="small"
      spaced
    />
  )
}

export default observer(WidgetListSelectPageSelect)
