import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { Tooltip } from 'antd'
import type { ButtonProps } from 'antd/lib/button'
import * as React from 'react'
import { IconCheck, IconCopyToClipBoard } from '../../Icon'
import type { IUseClipboardOptions } from './hooks'
import { useClipboard } from './hooks'

interface IButtonCopyToClipboardProps extends IUseClipboardOptions {
  className?: string
  variant?: ButtonVariant
  size?: ButtonSize
  buttonProps?: ButtonProps
}

/**
 * Component used to copy a predefined text or a text in an input to the clipboard
 * It renders a button with a copy icon.
 */
const ButtonCopyToClipboard: React.FC<IButtonCopyToClipboardProps> = props => {
  const { translate, copied, unsetCopied, copyToClipboard } =
    useClipboard(props)

  const delayedUnsetCopied = () => {
    // delay the change to prevent a visual bug with Ant Design tooltip
    setTimeout(unsetCopied, 300)
  }

  return (
    <Tooltip
      placement="top"
      title={copied ? translate('Copied') : translate('Copy to clipboard')}
    >
      <FormWrapperButton
        labelledBy="copyToClipboard"
        variant={props.variant ?? ButtonVariant.secondary}
        buttonProps={{
          className: props.className,
          onClick: copyToClipboard,
          onMouseLeave: delayedUnsetCopied,
          'aria-label': translate('Copy to clipboard'),
          ...props.buttonProps
        }}
        icon={copied ? IconCheck : IconCopyToClipBoard}
        size={props.size ?? ButtonSize.large}
      />
    </Tooltip>
  )
}

export default ButtonCopyToClipboard
