import type { ReactNode } from 'react'

export interface ISceneDimensions {
  width: number
  height: number
}

export interface IChartZoomData {
  // translateX
  x: number
  // translateY
  y: number
  // zoom
  k: number
}

export interface ITooltip {
  content: ReactNode
  uid: string
}

export enum TrustAttributesName {
  NON_TRANSITIVE = 'NON_TRANSITIVE',
  UPLEVEL_ONLY = 'UPLEVEL_ONLY',
  QUARANTINED_DOMAIN = 'QUARANTINED_DOMAIN',
  FOREST_TRANSITIVE = 'FOREST_TRANSITIVE',
  CROSS_ORGANIZATION_OR_SELECTIVE_AUTH = 'CROSS_ORGANIZATION_OR_SELECTIVE_AUTH',
  WITHIN_FOREST = 'WITHIN_FOREST',
  TREAT_AS_EXTERNAL = 'TREAT_AS_EXTERNAL',
  RC4_ENCRYPTION = 'RC4_ENCRYPTION',
  AES_KEYS = 'AES_KEYS',
  CROSS_ORGANIZATION_NO_TGT_DELEGATION = 'CROSS_ORGANIZATION_NO_TGT_DELEGATION',
  PIM_TRUST = 'PIM_TRUST',
  CROSS_ORGANIZATION_ENABLE_TGT_DELEGATION = 'CROSS_ORGANIZATION_ENABLE_TGT_DELEGATION',
  TREE_PARENT = 'TREE_PARENT',
  TREE_ROOT = 'TREE_ROOT',
  NO_ATTRIBUTE = 'NO_ATTRIBUTE'
}
