import type { IGraphQLQuery } from '@libs/graphQL/types'
import type { Query } from '../typeDefs/types'

/**
 * Retrieve the Relay's linking key.
 */
export type QueryRbacRelayLinkingKey = IGraphQLQuery<
  null,
  Pick<Query, 'rbacRelayLinkingKey'>
>

export const queryRbacRelayLinkingKey = `
  query queryRbacRelayLinkingKey {
    rbacRelayLinkingKey {
      node {
        linkingKey
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Retrieve the Relays.
 */
export type QueryRbacRelays = IGraphQLQuery<null, Pick<Query, 'rbacRelays'>>

export const queryRbacRelays = `
 query queryRbacRelays {
    rbacRelays {
      node {
        id
        name
        directoryIds
        status
        alertCount
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
   }
 }
`
