import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { IconNoData } from '@app/components-legacy/Icon'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import IoAAttacksLoadPreviousButton from '@app/pages/IoA/IoAAttacksPage/IoAAttacksTable/IoAAttacksLoadPreviousButton'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { AttacksPageContext } from '../context'
import IoAAttacksTableBodyRow from './IoAAttacksTableBodyRow'
import IoAAttacksTableHead from './IoAAttacksTableHead'

interface IIoAAttacksTableBodyProps {
  className?: string
}

const IoAAttacksTable: React.FC<IIoAAttacksTableBodyProps> = props => {
  const attacksPageContext = React.useContext(AttacksPageContext)

  const translate = useAppTranslator({ namespaces: ['IoA.Attacks'] })

  if (!attacksPageContext) {
    return null
  }

  const { attacks } = attacksPageContext.storeAttacks

  const renderContent = () => {
    if (!attacks.length) {
      return (
        <ContainerFlex
          className={props.className}
          name="IoAAttacksTableContent"
          direction="column"
          justifyContent="center"
          items={[
            <IconNoData
              text={translate(
                'Tenable.ad has not yet identified a security incident'
              )}
            />
          ]}
          fullHeight
        />
      )
    }

    return (
      <ContainerFlex
        className={props.className}
        name="Rows"
        direction="column"
        justifyContent="center"
        items={[
          <div>
            {attacks.map((attackEntity, i) => (
              <IoAAttacksTableBodyRow
                key={attackEntity.getPropertyAsString('id')}
                index={i}
                attackEntity={attackEntity}
              />
            ))}
          </div>,

          <IoAAttacksLoadPreviousButton
            storeAttacks={attacksPageContext.storeAttacks}
          />
        ]}
      />
    )
  }

  return (
    <ContainerContent
      name={IoAAttacksTable.name}
      flags={[
        attacksPageContext.storeAttacks.storeInputCheckersAttacks
          .storeFlagsFetchCheckers.flags,
        attacksPageContext.storeAttacks.storeFlagsFetchAttacks.flags,
        attacksPageContext.storeAttacks.storeFlagsReloadAttacks.flags
      ]}
      spinner={<SpinnerInline />}
    >
      <ContainerFlex
        name="Content"
        direction="column"
        items={[<IoAAttacksTableHead />, renderContent()]}
        spaced
        spaceWidth="small"
      />
    </ContainerContent>
  )
}

export default observer(IoAAttacksTable)
