import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { getColor } from './utils'

interface IDomainDetailsGaugeProps {
  className?: string
  score: Maybe<number>
  width: number
  height: number
}

const DomainDetailsGauge: React.FC<IDomainDetailsGaugeProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Topology.DomainBlade'] })

  if (!props.score) {
    return null
  }

  return (
    <section className={props.className}>
      <div className="gauge">
        <div className="semi-circle">
          <ContainerFlex
            name="ComplianceScore"
            className="score"
            direction="column"
            items={[
              <span className="score__label">{translate('Score')}</span>,
              <span className="score__value">{props.score}%</span>
            ]}
          />
        </div>
        <div className="semi-circle--mask"></div>
      </div>
    </section>
  )
}

export default styled(DomainDetailsGauge)`
  .gauge {
    position: relative;
    overflow: hidden;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
  }

  .semi-circle {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    background: ${props => {
      if (!props.score) {
        return consts.colorRed001
      }
      return getColor(props.score)
    }};
    border-radius: 50% 50% 50% 50% / 100% 100% 0% 0%;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: 2;
      display: block;
      width: 70%;
      height: 70%;
      margin-left: -35%;
      background: ${consts.colorWhite};
      border-radius: 50% 50% 50% 50% / 100% 100% 0% 0%;
    }
  }

  .semi-circle--mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    background: transparent;
    transform: rotate(
      ${props => (props.score ? (props.score * 180) / 100 : 0)}deg
    );

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      width: 101%;
      height: 51%;
      margin: -1px 0 0 -1px;
      background: ${consts.colorBlueGray020};
      border-radius: 50% 50% 50% 50% / 100% 100% 0% 0%;
    }
  }

  .score {
    position: absolute;
    z-index: 5;
    bottom: 0;
    left: 0;
    right: 0;
    line-height: 16px;

    &__value {
      font-weight: 600;
      color: ${props => {
        if (!props.score) {
          return consts.colorRed001
        }
        return getColor(props.score)
      }};
    }
  }
`
