import type { Maybe } from '@@types/helpers'
import type { ValueType } from '@libs/valueTypeParser/types'
import type * as diff from 'diff'

export enum AdObjectType {
  LDAP = 'LDAP',
  SYSVOL = 'SYSVOL'
}

export enum AdObjectCommonAttribute {
  type = 'type',
  objectclass = 'objectclass',
  distinguishedname = 'distinguishedname',
  globalpath = 'globalpath',
  ntsecuritydescriptor = 'ntsecuritydescriptor'
}

export enum AdObjectCommonObjectClass {
  passwordHashReuse = '["passwordHashReuse"]',
  passwordHashScan = '["passwordHashScan"]'
}

export type AdObjectId = number

/**
 * Used for event details attributes list.
 */
export interface IDataRowAdObject {
  id: number
  attributes: IDataRowAdObjectAsAttribute[]
  changes: IDataRowAdObjectAsAttribute[]
}

export interface IAdObjetAttribute {
  attributeName: string
  value: string
  valueType: ValueType
}

export interface IDataRowAdObjectAsAttribute {
  id: AdObjectId
  isChange: boolean
  attributeName: string
  attributeValueAtEvent: IAdObjetAttribute
  attributeCurrentValue: IAdObjetAttribute
  attributeDiffsBetweenBeforeAndAtEvent?: {
    diffs: diff.Change[]
    attributeBeforeValue: IAdObjetAttribute
  }
}

/**
 * Used for deviant objects list.
 */
export interface IDataRowAdObjectAsDeviantObject {
  id: AdObjectId
  type: string
  class: Maybe<string>
  dn: string
  directoryId: number
  reasonIds: number[]
}
