import { ContainerFlex } from '@app/components-legacy/Container'
import { InputSwitch } from '@app/components-legacy/Input'
import { useAppTranslator, useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleShowAllIndicatorsOnClick } from './handlers'

export interface IIoEBoardMenuSwitchProps {}

const IoEBoardMenuSwitch: React.FC<IIoEBoardMenuSwitchProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Buttons', 'IoE.Board'] })

  const { storeIoE } = useStores()

  return (
    <ContainerFlex
      name="ShowDeviantSwitch"
      items={[
        <InputSwitch
          label={translate('Show all indicators')}
          labelledBy="showAllIndicators"
          switchProps={{
            onChange: handleShowAllIndicatorsOnClick(storeIoE),
            defaultChecked: storeIoE.showAllIndicators === true
          }}
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(IoEBoardMenuSwitch)
