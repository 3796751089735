import { TextAreaCertificate } from '@app/components-legacy/TextArea'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import type { FieldName } from '@app/stores/helpers/StoreForm/types'
import type { TextAreaProps } from 'antd/lib/input/TextArea'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IWidgetFormTextareaCertificateProps {
  /** Field name */
  fieldName: FieldName
  /** Input id */
  inputId: string
  /** Props that will be passed to the rendered component */
  inputProps?: TextAreaProps
  /** StoreForm to get data */
  storeForm: StoreForm<any>
}

/**
 * @deprecated
 */
const WidgetFormTextareaCertificate: React.FC<
  IWidgetFormTextareaCertificateProps
> = props => {
  const { testAttributeProps } = useTestAttribute('form')

  const handleClearOnClick = (): void => {
    props.storeForm.hardReset()
  }

  return (
    <TextAreaCertificate
      textAreaProps={{
        id: props.inputId,
        name: props.fieldName,
        value: props.storeForm.getFieldValueAsString(props.fieldName),
        autoComplete: 'off',
        onChange: onInputChange(props.storeForm)(props.fieldName),
        ...props.inputProps,
        ...testAttributeProps('textbox')(props.fieldName)
      }}
      onClear={handleClearOnClick}
    />
  )
}

export default observer(WidgetFormTextareaCertificate)
