import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import { buildRbacPermissionKey } from '@libs/rbac/functions'
import type {
  RbacEntityIds,
  RbacPermissionKey,
  RbacEntityId
} from '@libs/rbac/types'
import type {
  Maybe,
  RbacAction,
  RbacEntityName,
  RbacPermission
} from '@server/graphql/typeDefs/types'

export default class EntityRbacPermission
  extends EntityBase
  implements PropertiesNullable<RbacPermission>
{
  entityName: Maybe<RbacEntityName> = null
  action: Maybe<RbacAction> = null
  entityIds: RbacEntityIds = null

  constructor(data: Partial<RbacPermission>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return the permission key of the entity.
   * /!\ Warning, the permission key could be invalid if some property are null.
   */
  buildRbacPermissionKey(): RbacPermissionKey {
    return buildRbacPermissionKey(
      this.getPropertyAsT<RbacEntityName>('entityName'),
      this.getPropertyAsT<RbacAction>('action'),
      this.entityIds ? this.entityIds : null
    )
  }

  /**
   * Explode the entity to retrieve the different parts of the permission.
   */
  explode(): Array<{
    rbacEntityName: Maybe<RbacEntityName>
    rbacAction: Maybe<RbacAction>
    rbacEntityId: Maybe<RbacEntityId>
  }> {
    if (!Array.isArray(this.entityIds)) {
      return [
        {
          rbacEntityName: this.getPropertyAsT<RbacEntityName>('entityName'),
          rbacAction: this.getPropertyAsT<RbacAction>('action'),
          rbacEntityId: null
        }
      ]
    }

    return this.entityIds.map(rbacEntityId => {
      return {
        rbacEntityName: this.entityName,
        rbacAction: this.action,
        rbacEntityId
      }
    })
  }
}
