import { ContainerFooter } from '@app/components-legacy/Container'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export interface IDrawerFooterProps {}

const DrawerFooter: React.FC<IDrawerFooterProps> = () => {
  return (
    <Portal name={PlaceHolderName.drawerFooter}>
      <ContainerFooter justify="left">
        <DrawerCancelButton />
      </ContainerFooter>
    </Portal>
  )
}

export default observer(DrawerFooter)
