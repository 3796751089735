import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import type {
  IEntityExportable,
  IEntityExportableAsCsvColumnsParameters,
  IEntityExportableAsCsvRowParameters
} from '@app/entities/types'
import type {
  IAttackChartData,
  IAttackSortData
} from '@app/pages/IoA/IoABoardPage/IoABoardCards/CardChart/types'
import {
  getNumberOfAttacksByCriticity,
  getRateOfAttacksByCriticity
} from '@app/pages/IoA/IoABoardPage/IoABoardCards/CardChart/utils'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import { ATTACKS_DEFAULT_CARD_CHART_TYPE } from '@app/stores/IoA/consts'
import { getTop3AttackTypes } from '@app/stores/IoA/StoreBoard/StoreSummaryCard/utils'
import { ensureArray } from '@libs/ensureArray'
import type {
  AttacksSummaryCard,
  AttacksSummaryChartCardType,
  AttackType,
  DashboardWidgetOptionsSerieStatAttackCounts,
  Maybe
} from '@server/graphql/typeDefs/types'
import { Criticity } from '@server/graphql/typeDefs/types'
import { get } from 'lodash'
import {
  createEntities,
  EntityDashboardWidgetOptionsSerieStatAttackCounts
} from '.'
import { sanitizeCsvValue } from '../../libs/csv-protector'

/**
 * Used for attacks summary card CSV exports.
 */
export interface IDataRowAttacksSummaryCardExportable {
  infrastructureId: number
  infrastructureName: string
  directoryId: number
  directory: string
  criticityCriticalPercent: number
  criticityHighPercent: number
  criticityMediumPercent: number
  criticityLowPercent: number
  attackNameTop1: string
  attackCountTop1: number
  attackNameTop2: string
  attackCountTop2: number
  attackNameTop3: string
  attackCountTop3: number
}

export default class EntityAttacksSummaryCard
  extends EntityBase
  implements
    PropertiesNullable<AttacksSummaryCard>,
    IEntityExportable<IDataRowAttacksSummaryCardExportable>
{
  directoryId: Maybe<number> = null
  chartType: AttacksSummaryChartCardType = ATTACKS_DEFAULT_CARD_CHART_TYPE
  statsCounts: Maybe<DashboardWidgetOptionsSerieStatAttackCounts[]> = null

  // used for csv export
  directoryName: Maybe<string> = null
  infrastructureId: Maybe<number> = null
  infrastructureName: Maybe<string> = null
  attackTypes: AttackType[] = []

  constructor(data: PropertiesNullable<AttacksSummaryCard>) {
    super()
    Object.assign(this, data)
  }

  setDirectoryName(directoryName: string): this {
    this.directoryName = directoryName
    return this
  }

  setInfrastructureId(infrastructureId: number): this {
    this.infrastructureId = infrastructureId
    return this
  }

  setInfrastructureName(infrastructureName: string): this {
    this.infrastructureName = infrastructureName
    return this
  }

  setAttackTypes(attackTypes: AttackType[]): this {
    this.attackTypes = attackTypes
    return this
  }

  get statsCountEntities(): EntityDashboardWidgetOptionsSerieStatAttackCounts[] {
    return createEntities<
      DashboardWidgetOptionsSerieStatAttackCounts,
      EntityDashboardWidgetOptionsSerieStatAttackCounts
    >(
      EntityDashboardWidgetOptionsSerieStatAttackCounts,
      ensureArray(this.statsCounts)
    )
  }

  get attacksCount(): number {
    return this.statsCountEntities.reduce((acc, curr) => {
      return acc + curr.dataCount
    }, 0)
  }

  get rateOfAttacksByCriticity(): IAttackChartData[] {
    return getRateOfAttacksByCriticity(this)
  }

  get numberOfAttacksByCriticity(): IAttackSortData[] {
    return getNumberOfAttacksByCriticity(this)
  }

  /** Implements IEntityExportable */

  getCSVColumns(
    parameters: IEntityExportableAsCsvColumnsParameters<IDataRowAttacksSummaryCardExportable>
  ): Array<IWidgetListColumns<IDataRowAttacksSummaryCardExportable>> {
    const columns: Array<
      IWidgetListColumns<IDataRowAttacksSummaryCardExportable>
    > = [
      {
        label: 'Forest ID',
        key: 'infrastructureId'
      },
      {
        label: 'Forest',
        key: 'infrastructureName'
      },
      {
        label: 'Domain ID',
        key: 'directoryId'
      },
      {
        label: 'Domain',
        key: 'directory'
      },
      {
        label: '% of critical attacks',
        key: 'criticityCriticalPercent'
      },
      {
        label: '% of high attacks',
        key: 'criticityHighPercent'
      },
      {
        label: '% of medium attacks',
        key: 'criticityMediumPercent'
      },
      {
        label: '% of low attacks',
        key: 'criticityLowPercent'
      },
      {
        label: 'Top1 attack',
        key: 'attackNameTop1'
      },
      {
        label: 'Counter',
        key: 'attackCountTop1'
      },
      {
        label: 'Top2 attack',
        key: 'attackNameTop2'
      },
      {
        label: 'Counter',
        key: 'attackCountTop2'
      },
      {
        label: 'Top3 attack',
        key: 'attackNameTop3'
      },
      {
        label: 'Counter',
        key: 'attackCountTop3'
      }
    ]

    return columns.filter(column => !parameters.omitKeys.includes(column.key))
  }

  /**
   * Return the entity as a row for widget lists.
   */
  asDataRow(): IDataRowAttacksSummaryCardExportable {
    const getCriticityPercent = (criticity: Criticity): number => {
      return (
        this.rateOfAttacksByCriticity.find(
          numberOfAttacks => numberOfAttacks.criticity === criticity
        )?.rate ?? 0
      )
    }

    const attackTypesNames = this.attackTypes.reduce((acc, entry) => {
      acc.set(entry.id, entry.name)
      return acc
    }, new Map<number, string>())

    const numberOfAttacksTop3 = getTop3AttackTypes(
      this.statsCountEntities,
      attackTypesNames
    )

    const getAttackNameTopX = (top: number) => {
      const topX = numberOfAttacksTop3[top]

      if (!topX) {
        return null
      }

      return {
        attackName: topX.name || null,
        count: topX.count
      }
    }

    return {
      infrastructureName: this.getPropertyAsString('infrastructureName'),
      infrastructureId: this.getPropertyAsNumber('infrastructureId'),

      directory: this.getPropertyAsString('directoryName'),
      directoryId: this.getPropertyAsNumber('directoryId'),

      criticityCriticalPercent: getCriticityPercent(Criticity.Critical),
      criticityHighPercent: getCriticityPercent(Criticity.High),
      criticityMediumPercent: getCriticityPercent(Criticity.Medium),
      criticityLowPercent: getCriticityPercent(Criticity.Low),

      attackNameTop1: getAttackNameTopX(0)?.attackName || '',
      attackCountTop1: getAttackNameTopX(0)?.count || 0,

      attackNameTop2: getAttackNameTopX(1)?.attackName || '',
      attackCountTop2: getAttackNameTopX(1)?.count || 0,

      attackNameTop3: getAttackNameTopX(2)?.attackName || '',
      attackCountTop3: getAttackNameTopX(2)?.count || 0
    }
  }

  /* Implements IEntityExportable */

  /**
   * Return the entity as a string for CSV exports.
   */
  asCSVRow(
    parameters: IEntityExportableAsCsvRowParameters<IDataRowAttacksSummaryCardExportable>
  ): string {
    const dataRow = this.asDataRow()
    const columns = this.getCSVColumns(parameters)

    const csvRowColumns = columns.map(column => {
      // wrap values in quotes to protect the separator
      return sanitizeCsvValue(get(dataRow, column.key, ''))
    })

    return csvRowColumns.join(parameters.separator)
  }
}
