import { LabelNonBreakable } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreInfrastructures } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IDomainsSelectionCounterProps {
  storeInfrastructures: StoreInfrastructures
}

const DomainsSelectionCounter: React.FC<
  IDomainsSelectionCounterProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.InputInfrastructures']
  })

  const directoryIds = props.storeInfrastructures.getDirectoryIds()
  const selectedDirectoryIds =
    props.storeInfrastructures.getSelectedDirectoryIds()

  return (
    <LabelNonBreakable>
      {translate('Domains selected', {
        interpolations: {
          count: selectedDirectoryIds.length,
          allCount: directoryIds.length
        }
      })}
    </LabelNonBreakable>
  )
}

export default observer(DomainsSelectionCounter)
