import { IconNoData } from '@app/components-legacy/Icon'
import { Table, TableBody, TableHead } from '@app/components-legacy/Table'
import type { IEntityListable } from '@app/entities/types'
import type { IDataRowGeneric } from '@app/stores/helpers/StoreWidgetList/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { getDataSet } from './functions'
import type { IWidgetListTableCommonProps } from './types'
import WidgetTableTBody from './WidgetTableTBody'
import WidgetTableTHead from './WidgetTableTHead'

interface IWidgetTableProps<
  E extends IEntityListable<IDataRowGeneric>,
  D extends IDataRowGeneric
> extends IWidgetListTableCommonProps<E, D> {}

/**
 * Render the table.
 */
export function WidgetTable<E extends IEntityListable<IDataRowGeneric>, D>(
  props: IWidgetTableProps<E, D>
): React.ReactElement {
  if (!getDataSet(props.storeWidgetList).rows.length) {
    return <div>{props.noResultsComponent || <IconNoData />}</div>
  }

  return (
    <Table>
      <TableHead>
        <WidgetTableTHead<E, D> {...props} />
      </TableHead>
      <TableBody>
        <WidgetTableTBody<E, D> {...props} />
      </TableBody>
    </Table>
  )
}

export default observer(WidgetTable)
