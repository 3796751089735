import type { Maybe } from '@@types/helpers'
import { LabelDeviance } from '@app/components-legacy/Label'
import type EntityAdObjectAsAttributes from '@app/entities/EntityAdObject/EntityAdObjectAsAttribute'
import type EntityIncriminatingAttributeListable from '@app/entities/EntityIncriminatingAttribute/EntityIncriminatingAttributeListable'
import type { IDataRowIncriminatingAttributeListable } from '@app/entities/EntityIncriminatingAttribute/types'
import type { StoreInfrastructures, StoreInputReasons } from '@app/stores'
import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { ICheckerExposure } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import { isDefined } from '@libs/isDefined'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IEventDetailDevianceCardProps {
  adObject?: Maybe<EntityAdObjectAsAttributes>
  selectable?: boolean
  incriminatingAttributeDataRow: IDataRowIncriminatingAttributeListable
  storeInfrastructures: StoreInfrastructures
  storeInputCheckersExposure?: StoreInputGenericCheckers<ICheckerExposure>
  storeInputReasons: StoreInputReasons
  storeWidgetList: StoreWidgetList<
    EntityIncriminatingAttributeListable,
    IDataRowIncriminatingAttributeListable
  >
}

const EventDetailDevianceCard: React.FC<
  IEventDetailDevianceCardProps
> = props => {
  const { incriminatingAttributeDataRow } = props
  const {
    checkerId,
    description,
    reasonId,
    ignoreUntil,
    eventDate,
    resolvedAt
  } = incriminatingAttributeDataRow

  const devianceReasons = props.storeInputReasons.getDeviancesReasons(
    [reasonId].filter(isDefined)
  )

  const devianceReason = devianceReasons[0]

  if (!devianceReason || !eventDate) {
    return null
  }

  const checked = props.storeWidgetList.isSelected(
    incriminatingAttributeDataRow
  )

  const entityChecker =
    checkerId && props.storeInputCheckersExposure
      ? props.storeInputCheckersExposure.checkersById.get(checkerId) || null
      : null

  return (
    <LabelDeviance
      adObject={props.adObject}
      checked={checked}
      eventDate={eventDate}
      description={description}
      entityChecker={entityChecker}
      reasonColor={devianceReason.getPropertyAsString('color')}
      reasonName={devianceReason.getPropertyAsString('name')}
      ignoreUntil={ignoreUntil}
      resolvedAt={resolvedAt}
      selectable={props.selectable}
    />
  )
}

export default observer(EventDetailDevianceCard)
