import { EntityAttackPathEdge, EntityAttackPathNode } from '@app/entities'
import { AttackPathRelationType } from '@server/graphql/typeDefs/types'
import { v4 as v4uuid } from 'uuid'
import { CELL_SIZE } from '../consts'
import type { EntityAttackPathNodeAny, PlacementAlgorithmData } from '../types'
import { computeEdgesProperties, groupNodesByParent } from '../utils'

/**
 * Place a node according to its depth and an optional column
 * Proceed to the x,y coords computation.
 */
export const placeNode = (
  node: EntityAttackPathNode,
  row: number,
  column?: number
): void => {
  node.setCell({
    row,
    column: column ?? node.depth
  })

  // Affect rotated coords
  node.x = Number(node.cell?.column) * CELL_SIZE
  node.y = Number(node.cell?.row) * CELL_SIZE * 0.5

  node.placed = true
}

/**
 * Place all parents of a node.
 * Parents are placed at the middle of their children.
 */
export const placeParent = (
  node: EntityAttackPathNode,
  nodesByParent: Map<string, EntityAttackPathNodeAny[]>
): void => {
  const parent = node.linkedNodeEntity

  if (parent && parent.depth !== 0) {
    // Parent has not been placed
    const parentNodes = Array.from(nodesByParent.get(parent.uid) ?? [])
    if (!parentNodes.length) {
      return
    }

    if (parentNodes.length === 1) {
      placeNode(parent, parentNodes[0].cell?.row ?? 0)
    }

    const { min, max } = parentNodes.reduce(
      (acc, value) => {
        const cell = value.cell
        if (!cell) {
          return acc
        }

        if (acc.min > cell.row) {
          acc.min = cell.row
        }
        if (acc.max < cell.row) {
          acc.max = cell.row
        }

        return acc
      },
      { min: Number.MAX_VALUE, max: 0 }
    )

    placeNode(parent, Math.floor(min + (max - min) / 2))

    placeParent(parent, nodesByParent)
  }
}

/**
 *  Place all nodes in case of getting paths between two nodes.
 */
export const placeNodesForPathBetweenTwoNodes = (
  sourceNode: EntityAttackPathNode,
  targetNodeId: number,
  linkedNodes: EntityAttackPathNode[],
  linkedEdges: EntityAttackPathEdge[]
): PlacementAlgorithmData => {
  const indexedEdgesByTargetUid = linkedEdges.reduce((acc, edge) => {
    if (edge.targetUid) {
      acc.set(edge.targetUid, edge)
    }

    return acc
  }, new Map<string, EntityAttackPathEdge>())

  const fakeNodes: EntityAttackPathNode[] = []
  const fakeEdges: EntityAttackPathEdge[] = []

  // Search for all final nodes
  const finalNodes = linkedNodes.filter(node => node.id === targetNodeId)

  // Determine the max depth of those final nodes
  const maxDepth = finalNodes.reduce((max, node) => {
    if (node.depth > max) {
      return node.depth
    }

    return max
  }, 0)

  // Create a new final node to which all paths will be linked, if the final node is not alone.
  const finalNode =
    finalNodes.length > 1
      ? new EntityAttackPathNode({
          ...finalNodes[0],
          uid: v4uuid(),
          isImportantNode: true
        })
      : finalNodes[0]

  if (finalNodes.length > 1) {
    // We will hide last final nodes as they are not really final,
    // but one part of the edge
    finalNodes.forEach((node, index) => {
      node.isHiddenNode = true

      // Place this node
      placeNode(node, index)

      // Find the edge attached to this node
      const edge = indexedEdgesByTargetUid.get(node.uid)

      if (!edge) {
        return
      }

      let maxDepthNode = node

      // Add a new hidden node at the max depth if it's not already at the max
      // It will be placed at the same row, so that we have an horizontal line
      // from the formal node to the fake new one
      if (node.depth !== maxDepth) {
        const fakeNode = new EntityAttackPathNode({
          ...node,
          uid: v4uuid()
        })

        placeNode(fakeNode, index, maxDepth)

        fakeNodes.push(fakeNode)

        // Create the edge to connect the formal final edge to this new fake one
        const fakeEdgeToFinalDepth = new EntityAttackPathEdge({
          id: v4uuid(),
          sourceId: node.id,
          sourceUid: node.uid,
          targetId: fakeNode.id,
          targetUid: fakeNode.uid,
          relationTypes: [AttackPathRelationType.Fake],
          uuidLinked: edge.id,
          isFake: true
        })

        fakeEdges.push(fakeEdgeToFinalDepth)

        maxDepthNode = fakeNode
      }

      // Create the edge from the previous fake or formal final node to
      // the real new final node
      const fakeEdgeToFinalNode = new EntityAttackPathEdge({
        id: v4uuid(),
        sourceId: maxDepthNode.id,
        sourceUid: maxDepthNode.uid,
        targetId: finalNode.id,
        targetUid: finalNode.uid,
        relationTypes: [AttackPathRelationType.Fake],
        uuidLinked: edge.id,
        isFake: true
      })

      fakeEdges.push(fakeEdgeToFinalNode)
    })
  }

  // Calculate the median row for both start and end nodes
  const maxPaths = finalNodes.length
  const medianPathRow = Math.floor(maxPaths / 2)
  // Assign this new position
  placeNode(sourceNode, medianPathRow, 0)
  placeNode(finalNode, medianPathRow, maxDepth + 1)

  // Create a new array containing all nodes (initial, fakes and source)
  const allLinkedNodes = linkedNodes.concat(
    finalNodes.length > 1 ? fakeNodes : finalNode,
    sourceNode
  )

  // Generate an indexed array of nodes by their parent
  const nodesByParent = groupNodesByParent(allLinkedNodes)

  // Place all formal final nodes
  finalNodes.forEach(node => {
    placeParent(node, nodesByParent)
  })

  // Add finalNode after placing others formal final nodes to not get it into account
  // when calculating parent's position
  // Related to https://jira.eng.tenable.com/browse/AD-4164
  allLinkedNodes.push(finalNode)

  // Remove nodes that have not been placed in the graph
  const placedLinkedNodes = allLinkedNodes.filter(node => node.placed)

  // Compute properties for all edges
  const edges = computeEdgesProperties(
    placedLinkedNodes,
    linkedEdges.concat(fakeEdges)
  )

  return {
    nodes: placedLinkedNodes,
    maxIndex: finalNode.depth,
    edges
  }
}
