import type { Maybe } from '@@types/helpers'
import { consts } from '@app/styles'
import { isDefined } from '@libs/isDefined'
import { sanitize } from '@libs/sanitize'
import { getUserFriendlyDefinition } from '@libs/sddl/getUserFriendlyDefinition'
import type { Detail, ISddlTree } from '@libs/sddl/types'
import { Collapse } from 'antd'
import type { CollapseProps } from 'antd/lib/collapse'
import { get, isString, uniqueId } from 'lodash'
import { lighten } from 'polished'
import * as React from 'react'
import { getAceColor } from './sddlChecker'
import SDDLPanelHeader from './SDDLPanelHeader'
import SDDLPanelStatic from './SDDLPanelStatic'
import { ACEColorEnum } from './types'

const LIGHTEN_AMOUNT = 0.13

const aceColors = {
  [ACEColorEnum.RED]: lighten(
    LIGHTEN_AMOUNT,
    consts.vulnerabilityCriticityHigh
  ),
  [ACEColorEnum.ORANGE]: lighten(
    LIGHTEN_AMOUNT,
    consts.vulnerabilityCriticityMedium
  ),
  [ACEColorEnum.GREEN]: lighten(
    LIGHTEN_AMOUNT,
    consts.vulnerabilityCriticityNone
  )
}

interface ISDDLDetailProps {
  sddlTree: ISddlTree
}

const panelStyles: React.CSSProperties = {
  marginBottom: '2px'
}

/**
 * Complicated function to return an unique index/key for React.
 * Could be better.
 */
function generateKey(detail: Detail): string {
  if (!detail) {
    return ''
  }

  const value = isString(detail)
    ? sanitize(detail)
    : sanitize(detail.title || detail.value)

  return uniqueId(value)
}

/**
 * If all details are only strings, consider that the current detail is a "leaf"
 * and display all details into static panel.
 */
function renderIfDetailIsLeaf(sddlTree: ISddlTree): Maybe<React.ReactElement> {
  const isLeaf = sddlTree.details.filter(isDefined).every(isString)

  if (!isLeaf) {
    return null
  }

  const key = generateKey(sddlTree)

  return (
    <>
      {sddlTree.details
        .map((detail, _i) => {
          if (!detail) {
            return null
          }

          // `detail` should always be a string here
          const definition = isString(detail)
            ? getUserFriendlyDefinition(sddlTree.title, detail)
            : ''

          return (
            <SDDLPanelStatic
              key={key}
              title={sddlTree.title}
              value={sddlTree.value}
              definition={definition}
            />
          )
        })
        .filter(isDefined)}
    </>
  )
}

/**
 * If the details and sub details are an array of 1 item, display the detail
 * into a static panel.
 */
function renderIfDetailAndSubDetailIsASimpleString(
  sddlTree: ISddlTree
): Maybe<React.ReactElement> {
  const key = generateKey(sddlTree)

  const isOnlyOneDetail: boolean = get(sddlTree, 'details', []).length === 1
  const isOnlyOneDetailInChild: boolean =
    get(sddlTree, 'details.0.details', []).length === 1

  if (!(isOnlyOneDetail && isOnlyOneDetailInChild)) {
    return null
  }

  const detail: Maybe<string> = get(sddlTree, 'details.0.details.0', null)

  if (!(isString(detail) && detail)) {
    return null
  }

  const definition = getUserFriendlyDefinition(sddlTree.title, detail)

  return (
    <SDDLPanelStatic
      key={key}
      title={sddlTree.title}
      value={sddlTree.value}
      definition={definition}
    />
  )
}

/**
 * If the sub details is an array of nulls, use the value as the fallback.
 */
function renderIfSubDetailIsNull(
  sddlTree: ISddlTree
): Maybe<React.ReactElement> {
  const key = generateKey(sddlTree)
  const isOnlyOneDetail: boolean = get(sddlTree, 'details', []).length === 1

  const isOnlyOneDetailInChild: boolean =
    get(sddlTree, 'details.0.details', []).length === 1

  const isSubDetailsIsNull: boolean =
    isOnlyOneDetailInChild &&
    get(sddlTree, 'details.0.details', []).every((d: any) => d === null)

  if (!(isOnlyOneDetail && isOnlyOneDetailInChild && isSubDetailsIsNull)) {
    return null
  }

  const sddl: Maybe<ISddlTree | string> = get(sddlTree, 'details.0', null)

  if (typeof sddl === 'string') {
    return null
  }

  const definition = sddl
    ? getUserFriendlyDefinition(sddl.title, sddl.value)
    : ''

  return (
    <SDDLPanelStatic
      key={key}
      title={sddlTree.title}
      value={sddl?.value || null}
      definition={definition}
    />
  )
}

function renderTogglePanel(sddlTree: ISddlTree): React.ReactElement {
  const key = generateKey(sddlTree)

  const collapseProps: CollapseProps = {
    destroyInactivePanel: true
  }

  const aceColor = getAceColor(sddlTree)
  const backgroundColor = aceColor && aceColors[aceColor]

  return (
    <Collapse
      data-name="SDDLDetail"
      style={{ backgroundColor }}
      {...collapseProps}
    >
      <Collapse.Panel
        key={key}
        header={
          <SDDLPanelHeader title={sddlTree.title} value={sddlTree.value} />
        }
        children={
          <>
            {sddlTree.details.map((detail, i) => {
              if (!detail) {
                return null
              }

              // should never happen, this case if handled by (1)
              if (isString(detail)) {
                return (
                  <SDDLPanelStatic
                    title={sddlTree.title}
                    value={detail}
                    definition="Unknown"
                  />
                )
              }

              return (
                <SDDLDetail key={`${generateKey(detail)}`} sddlTree={detail} />
              )
            })}
          </>
        }
        style={panelStyles}
        collapsible="header"
      />
    </Collapse>
  )
}

/**
 * Functional component in charge to display recursively SDDL "blocks".
 */
const SDDLDetail: React.FC<ISDDLDetailProps> = props => {
  return (
    renderIfDetailIsLeaf(props.sddlTree) ||
    renderIfDetailAndSubDetailIsASimpleString(props.sddlTree) ||
    renderIfSubDetailIsNull(props.sddlTree) ||
    renderTogglePanel(props.sddlTree)
  )
}

export default SDDLDetail
