import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useAppTranslator } from '@app/hooks'
import type { ButtonProps } from 'antd/lib/button'
import * as React from 'react'
import { FormWrapperButton } from '..'

interface IFormWrapperButtonCancelProps {
  onClick?: () => void
  buttonProps?: ButtonProps
}

/**
 * Render a Cancel button with the correct labelledBy for automation tests.
 *
 * Usage:
 *
 * <FormWrapperButtonCancel onClick?={...} buttonProps?={{...}} />
 */
const FormWrapperButtonCancel: React.FC<
  IFormWrapperButtonCancelProps
> = props => {
  const translate = useAppTranslator({ namespaces: ['Buttons'] })

  return (
    <FormWrapperButton
      labelledBy="cancel"
      variant={ButtonVariant.tertiary}
      size={ButtonSize.large}
      buttonProps={{
        onClick: props.onClick,
        ...props.buttonProps
      }}
    >
      {translate('Cancel')}
    </FormWrapperButton>
  )
}

export default FormWrapperButtonCancel
