import { ContainerHTMLContent } from '@app/components-legacy/Container'
import ContainerContent from '@app/components-legacy/Container/ContainerContent'
import { useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { onEulaPageLoad } from './handlers'

interface ILegalEulaProps {}

const StyledContainerHTMLContent = styled(ContainerHTMLContent)`
  h1 {
    text-align: center;
  }

  ol {
    padding-inline-start: 12px;
  }
`

const LegalEula: React.FC<ILegalEulaProps> = props => {
  const { storeEula } = useStores()

  return (
    <ContainerContent
      onLoad={onEulaPageLoad(storeEula)}
      flags={storeEula.storeFlags.flags}
    >
      <StyledContainerHTMLContent padded>
        {storeEula.eula?.getLicenseContent() || 'License not available'}
      </StyledContainerHTMLContent>
    </ContainerContent>
  )
}

export default observer(LegalEula)
