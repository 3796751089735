import type { EntityUserLog } from '@app/entities'
import type { IApiCall } from '@app/entities/EntityUserLog'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { consts } from '@app/styles'
import { isDefined } from '@libs/isDefined'
import * as React from 'react'
import styled from 'styled-components'
import { INDENT_LENGTH_JSON_FORMAT } from '../../consts'

interface ITracePayload {
  log: EntityUserLog
}

const PayloadStyled = styled('pre')`
  margin: ${consts.marginSmall};
  white-space: pre-wrap;
  font-family: inherit;
`

const ActivityLogsLogPayload: React.FC<ITracePayload> = props => {
  const { testAttributeProps } = useTestAttribute('contentinfo')
  const { log } = props
  const decodedAttributes = log.decodedLogAttributes as IApiCall
  let renderedPayload = null
  if (isDefined(decodedAttributes.payload)) {
    try {
      const payload = JSON.parse(decodedAttributes.payload)
      renderedPayload = JSON.stringify(payload, null, INDENT_LENGTH_JSON_FORMAT)
    } catch (error) {
      // if error, this is not a json, we display the raw value
      renderedPayload = decodedAttributes.payload
    }
  }
  return (
    <div {...testAttributeProps()('payloadCall')}>
      <PayloadStyled>{renderedPayload}</PayloadStyled>
    </div>
  )
}

export default ActivityLogsLogPayload
