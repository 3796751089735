import { CheckboxTree } from '@app/components-legacy/Checkbox'
import { LabelSliced } from '@app/components-legacy/Label'
import type EntityTenant from '@app/entities/EntityTenant'
import type StoreInputTenants from '@app/stores/StoreInputTenants'
import type { TreeProps } from 'antd/lib/tree'
import Tree from 'antd/lib/tree'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleTenantSelection } from '../handlers'

export interface ITenantsTreeProps {
  className?: string
  style?: React.CSSProperties
  entitiesTenant: EntityTenant[]
  storeInputTenants: StoreInputTenants
}

const TenantsTree: React.FC<ITenantsTreeProps> = props => {
  const getTreeProps = (): TreeProps => {
    const treeProps: TreeProps = {}

    treeProps.treeData = props.entitiesTenant.map(entityTenant => {
      return {
        title: (
          <span
            onClick={handleTenantSelection(props.storeInputTenants)(
              entityTenant.getPropertyAsString('id')
            )}
          >
            <LabelSliced
              maxLength={50}
              value={entityTenant.getPropertyAsString('name')}
            />
          </span>
        ),
        key: `${entityTenant.getPropertyAsString('id')}`,
        icon: (
          <CheckboxTree
            onClick={handleTenantSelection(props.storeInputTenants)(
              entityTenant.getPropertyAsString('id')
            )}
            checked={
              props.storeInputTenants.isTenantSelected(
                entityTenant.getPropertyAsString('id')
              ) || false
            }
            color={entityTenant.color}
          />
        )
      }
    })

    return treeProps
  }

  return <Tree showIcon {...getTreeProps()} />
}

export default observer(TenantsTree)
