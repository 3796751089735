import { consts } from '@app/styles'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import type { DSThemeDefinition } from '@design-system/styles/themes/types'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { ContainerFlex } from '.'
import type { TContainerFlexJustifyContent } from './ContainerFlex/types'

interface IContainerFooterProps {
  className?: string
  variant?: 'normal' | 'popover' | 'modal'
  justify?: 'default' | 'left' | 'center' | 'right'
  children?: React.ReactNode
  withBoxShadow?: boolean
}

/**
 * @deprecated
 */
const ContainerFooter: React.FC<IContainerFooterProps> = props => {
  const childrens = React.Children.toArray(props.children)

  const getJustifyContent = (): TContainerFlexJustifyContent => {
    if (!props.justify) {
      return childrens.length === 1 ? 'flex-end' : 'space-between'
    }

    switch (props.justify) {
      case 'default':
        return 'space-between'

      case 'left':
        return 'flex-start'

      case 'center':
        return 'center'

      case 'right':
        return 'flex-end'

      default:
        assertUnreachableCase(props.justify)
    }
  }

  return (
    <ContainerFlex
      name="ContainerFooter"
      labelledBy="load-previous-events"
      justifyContent={getJustifyContent()}
      alignItems="center"
      className={props.className}
      items={childrens}
      spaced
      spaceWidth="small"
    />
  )
}

function getPadding(props: IContainerFooterProps): string {
  if (!props.variant) {
    return `${consts.paddingDefault} ${consts.paddingLarge}`
  }

  switch (props.variant) {
    case 'popover':
      return `${consts.paddingDefault} 0 0 0`

    case 'modal':
      return '0'

    case 'normal':
      return `${consts.paddingDefault} ${consts.paddingLarge}`
  }
}

function getBorder(
  props: IContainerFooterProps,
  theme: DSThemeDefinition
): string {
  const borderValue = `1px solid ${theme.tokens['containerFooter/color/separator']}`

  if (!props.variant) {
    return borderValue
  }

  switch (props.variant) {
    case 'modal':
      return 'none'

    case 'normal':
    case 'popover':
      return borderValue
  }
}

/**
 * @deprecated
 */
export default styled(ContainerFooter)`
  width: 100%;
  padding: ${getPadding};
  border-top: ${props => getBorder(props, props.theme)};
  min-height: ${consts.containerFooterHeight};

  ${props =>
    !isThemeLegacy(props.theme) &&
    props.variant !== 'popover' &&
    props.withBoxShadow !== false &&
    css`
      border-top: 0;
      box-shadow: ${consts.drawerFooterBoxShadow};
    `}
`
