import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import WrapperAlert from '@app/components-legacy/Wrapper/WrapperAlert'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckerDetailsPageContext } from '../../context'
import DeviantObjectsFilters from './DeviantObjectsFilters'
import DeviantObjectsTable from './DeviantObjectsTable'

interface IDeviantObjectsTabProps {}

const DeviantObjectsTab: React.FC<IDeviantObjectsTabProps> = props => {
  const parameters = React.useContext(CheckerDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoE.Details.DeviantObjects']
  })

  const { storeIoE } = useStores()

  if (!parameters) {
    return null
  }

  const renderAlert = () => {
    const checker = storeIoE.checkers.get(parameters.checkerCodename)

    const isCheckerEnabled = checker && checker.enabled

    if (isCheckerEnabled) {
      return
    }

    return (
      <WrapperAlert
        labelledBy="deviant-objects-before-deactivation"
        alertProps={{
          type: 'warning',
          message: translate('Tenable.ad detected these objects as deviant'),
          showIcon: true
        }}
      />
    )
  }

  const { storeIndicatorDeviantObjects } = storeIoE.storeIndicator

  return (
    <ContainerFlex
      name="DeviantObjects"
      direction="column"
      items={[
        renderAlert(),

        <DeviantObjectsFilters />,

        <ContainerContent
          spinner={<SpinnerInline />}
          flags={[
            storeIndicatorDeviantObjects.storeFlagsReLoadDeviantObjects.flags,
            storeIndicatorDeviantObjects.storeFlagsLoadCheckerReasons.flags
          ]}
        >
          <DeviantObjectsTable />
        </ContainerContent>
      ]}
      spaced
    />
  )
}

export default observer(DeviantObjectsTab)
