import type { EntityPagination } from '@app/entities'
import {
  createEntities,
  createEntity,
  EntitySearchHistoryEntry
} from '@app/entities'
import type { IDataRowSearchHistoryEntry } from '@app/entities/EntitySearchHistoryEntry'
import StoreDatePicker from '@app/stores/helpers/StoreDatePicker'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import StoreBase from '@app/stores/StoreBase'
import { DateFormat, formatDate } from '@libs/dates/formatDate'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { Expression } from '@libs/Expression'
import { addSetValueToMap } from '@libs/setValueToMap'
import type {
  MutationCreateSearchHistoryEntry,
  MutationDeleteSearchHistoryEntries,
  MutationDeleteSearchHistoryEntriesByQuery,
  MutationDeleteSearchHistoryEntry
} from '@server/graphql/mutations/searchHistory'
import {
  mutationCreateSearchHistoryEntry,
  mutationDeleteSearchHistoryEntries,
  mutationDeleteSearchHistoryEntriesByQuery,
  mutationDeleteSearchHistoryEntry
} from '@server/graphql/mutations/searchHistory'
import type { QuerySearchHistoryEntries } from '@server/graphql/queries/search-history'
import { querySearchHistoryEntries } from '@server/graphql/queries/search-history'
import type {
  CreateSearchHistoryEntryMutationArgs,
  DeleteSearchHistoryEntriesByQueryMutationArgs,
  DeleteSearchHistoryEntryMutationArgs,
  InputDeleteSearchHistoryEntriesByQuery,
  Pagination,
  SearchHistoryEntry,
  SearchHistoryQueryArgs
} from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import type { StoreRoot } from '..'
import StoreModal from '../helpers/StoreModal'
import StoreWidgetList from '../helpers/StoreWidgetList'
import type { IStoreOptions } from '../types'

export enum HistoryMenuKey {
  History = 'History',
  Bookmarks = 'Bookmarks'
}

export default class StoreSearchHistory extends StoreBase {
  public storeInputSearch = new StoreInputSearch(this.storeRoot)
  public storeDatePicker = new StoreDatePicker(this.storeRoot, {
    defaultDateStart: null,
    defaultDateEnd: null
  })

  public storeSearchHistoryTimelineDrawer = new StoreDrawer(this.storeRoot)

  public storeModalConfirmDeleteHistory = new StoreModal(this.storeRoot)

  public storeFlagsSearchHistory = new StoreFlags(this.storeRoot)
  public storeFlagsCreateSearchHistoryEntry = new StoreFlags(this.storeRoot)
  public storeFlagsDeleteSearchHistoryEntry = new StoreFlags(this.storeRoot)

  public storeWidgetList = new StoreWidgetList<
    EntitySearchHistoryEntry,
    IDataRowSearchHistoryEntry
  >(this.storeRoot, {
    selectable: false
  })

  /* Observable */

  private $searchHistory = observable.map<number, EntitySearchHistoryEntry>()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch search history entries and save entities.
   */
  fetchSearchHistoryEntries(_args?: SearchHistoryQueryArgs): Promise<void> {
    const args: SearchHistoryQueryArgs = {
      searchHistoryEntriesPage: this.storeWidgetList.paginationPage,
      searchHistoryEntriesPerPage: this.storeWidgetList.rowsPerPage,
      ..._args
    }

    this.storeFlagsSearchHistory.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QuerySearchHistoryEntries>(querySearchHistoryEntries, args)
      })
      .then(data => data.searchHistory)
      .then(searchHistoryEntries => {
        if (!searchHistoryEntries) {
          throw new Error('SearchHistory is not defined.')
        }

        const searchHistoryEntriesEntities = createEntities<
          SearchHistoryEntry,
          EntitySearchHistoryEntry
        >(EntitySearchHistoryEntry, searchHistoryEntries.node)

        const searchHistoryEntriesPagination = createEntity<
          Pagination,
          EntityPagination
        >(EntitySearchHistoryEntry, searchHistoryEntries.pagination)

        this.setSearchHistoryEntries(searchHistoryEntriesEntities)

        this.storeWidgetList.setEntities(searchHistoryEntriesEntities)
        this.storeWidgetList.setPagination(searchHistoryEntriesPagination)

        this.storeFlagsSearchHistory.success()
        this.storeInputSearch.submit()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsSearchHistory))
  }

  /**
   * Create a history entry
   */
  createSearchHistoryEntry(expression: string) {
    this.storeFlagsCreateSearchHistoryEntry.loading()

    return Promise.resolve()
      .then(() => {
        const args: CreateSearchHistoryEntryMutationArgs = {
          searchHistoryEntry: {
            expression
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationCreateSearchHistoryEntry>(
            mutationCreateSearchHistoryEntry,
            args
          )
      })
      .then(() => this.fetchSearchHistoryEntries())
      .then(() => {
        this.storeFlagsCreateSearchHistoryEntry.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsCreateSearchHistoryEntry
        )
      )
  }

  /**
   * Delete a history entry
   */
  deleteSearchHistoryEntry(searchHistoryEntryId: number) {
    this.storeFlagsDeleteSearchHistoryEntry.loading()

    return Promise.resolve()
      .then(() => {
        const args: DeleteSearchHistoryEntryMutationArgs = {
          searchHistoryEntry: {
            id: searchHistoryEntryId
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationDeleteSearchHistoryEntry>(
            mutationDeleteSearchHistoryEntry,
            args
          )
      })
      .then(() => this.fetchSearchHistoryEntries())
      .then(() => {
        this.storeFlagsDeleteSearchHistoryEntry.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsDeleteSearchHistoryEntry
        )
      )
  }

  /**
   * Delete all history entries that match the query.
   */
  deleteSearchHistoryEntriesByQuery(
    query: InputDeleteSearchHistoryEntriesByQuery
  ) {
    this.storeFlagsDeleteSearchHistoryEntry.loading()

    return Promise.resolve()
      .then(() => {
        const args: DeleteSearchHistoryEntriesByQueryMutationArgs = {
          searchHistoryQuery: {
            expressionSearch: query.expressionSearch,
            dateStart: query.dateStart,
            dateEnd: query.dateEnd
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationDeleteSearchHistoryEntriesByQuery>(
            mutationDeleteSearchHistoryEntriesByQuery,
            args
          )
      })
      .then(() => this.fetchSearchHistoryEntries())
      .then(() => {
        this.storeFlagsDeleteSearchHistoryEntry.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsDeleteSearchHistoryEntry
        )
      )
  }

  /**
   * Delete all search history
   */
  deleteSearchHistoryEntries() {
    this.storeFlagsDeleteSearchHistoryEntry.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<MutationDeleteSearchHistoryEntries>(
            mutationDeleteSearchHistoryEntries
          )
      })
      .then(() => this.fetchSearchHistoryEntries())
      .then(() => {
        this.storeFlagsDeleteSearchHistoryEntry.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsDeleteSearchHistoryEntry
        )
      )
  }

  /* Actions */

  /**
   * Save search history
   */
  @action
  setSearchHistoryEntries(
    searchHistoryEntries: EntitySearchHistoryEntry[]
  ): this {
    this.$searchHistory.clear()

    searchHistoryEntries.forEach(searchHistoryEntry => {
      if (searchHistoryEntry.id && searchHistoryEntry.expression) {
        searchHistoryEntry.expressionObject = new Expression().fromString(
          searchHistoryEntry.expression
        )

        this.$searchHistory.set(searchHistoryEntry.id, searchHistoryEntry)
      }
    })

    return this
  }

  /**
   * Reset
   */
  @action
  reset(): this {
    this.storeInputSearch.reset()
    this.storeDatePicker.reset()

    return this
  }

  /* Computed values */

  @computed
  get searchHistoryEntries(): Map<number, EntitySearchHistoryEntry> {
    return toJS(this.$searchHistory)
  }

  @computed
  get searchHistoryEntriesFiltered(): EntitySearchHistoryEntry[] {
    return Array.from(this.searchHistoryEntries.values()).filter(
      entitySearchHistory => {
        return this.storeRoot.stores.storeTrailFlow.storeInputExpression.inputValueAsRegExp.test(
          entitySearchHistory.getPropertyAsString('expression').trim()
        )
      }
    )
  }

  @computed
  get searchHistoryEntriesSortedByDate(): Map<
    string,
    Set<EntitySearchHistoryEntry>
  > {
    const map: Map<string, Set<EntitySearchHistoryEntry>> = new Map()

    Array.from(this.searchHistoryEntries.values()).forEach(
      searchHistoryEntry => {
        if (!searchHistoryEntry.date) {
          return
        }

        const formatedDate = formatDate(searchHistoryEntry.date, {
          utc: false,
          format: DateFormat.englishDateOfTheDay
        })

        addSetValueToMap(map, formatedDate, searchHistoryEntry)
      }
    )

    return map
  }
}
