import type { TContainerFlexJustifyContent } from '@app/components/Container/ContainerFlex/types'
import { useTestAttribute } from '@app/hooks'
import { isDefined } from '@libs/isDefined'
import * as React from 'react'
import styled from 'styled-components'

interface ITableCellProps {
  className?: string
  style?: React.CSSProperties
  labelledBy: string
  fixedWidth?: number
  relativeWidth?: number
  justifyContent?: TContainerFlexJustifyContent
  height: number
  children: React.ReactNode
  cursor?: string
  handleOnClick?: () => void
}

function TableCell(props: ITableCellProps) {
  const { testAttributeProps } = useTestAttribute('table')

  const { labelledBy } = props

  return (
    <div
      className={props.className}
      onClick={props.handleOnClick}
      style={props.style}
      {...testAttributeProps('cell')(labelledBy)}
    >
      {props.children}
    </div>
  )
}

export default styled(TableCell)`
  flex-basis: ${props =>
    isDefined(props.fixedWidth)
      ? `${props.fixedWidth}px`
      : isDefined(props.relativeWidth)
        ? `${props.relativeWidth * 100}%`
        : ''};
  height: ${props => props.height}px;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-shrink: ${props => (isDefined(props.fixedWidth) ? 0 : 1)};
  justify-content: ${props => props.justifyContent ?? 'flex-start'};
  ${props => (props.cursor ? `cursor: ${props.cursor};` : '')}
`
