import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface IIconPlayRawProps {
  className?: string
  onClick?: () => void
}

const IconPlayRaw: React.FC<IIconPlayRawProps> = props => {
  return <div className={props.className} onClick={props.onClick} />
}

export default styled(IconPlayRaw)`
  box-sizing: content-box;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-left: 12px solid ${consts.colorBlueGray005};
  border-bottom: 8px solid transparent;
  cursor: pointer;
`
