import { ContainerFlex } from '@app/components/Container'
import { IconEdit, IconRecast, IconTrash } from '@app/components/Icon'
import { ContainerIcon } from '@app/components-legacy/Container'
import FormWrapperButtonSimple from '@app/components-legacy/Form/Wrappers/ButtonSimple'
import {
  IconDeleteOutlined,
  IconEditOutlined,
  IconSyncOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { explodeWidgetKey } from '@libs/dashboards/keys'
import type { WidgetKey } from '@libs/dashboards/types'
import { Tooltip } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import {
  canDeleteWidget,
  canEditWidget,
  canRefreshWidget
} from '../permissions'

interface IWidgetHeaderTitleIconsProps {
  className?: string
  widgetKey?: WidgetKey
  onEditIconClick?: () => void
  onDeleteIconClick?: () => void
  onRefreshIconClick?: () => void
}

const CustomFormWrapperButtonSimple = styled(FormWrapperButtonSimple)`
  &:hover svg path,
  &:focus:not([data-focus-method='mouse']) svg path {
    stroke: ${consts.CTAColorV2.primaryHover};
  }
`

const WidgetHeaderTitleIcons: React.FC<
  IWidgetHeaderTitleIconsProps
> = props => {
  const translate = useAppTranslator({ namespaces: ['Dashboard.Common'] })

  const { storeRbac } = useStores()

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const onEditIconClick = () => {
    if (props.onEditIconClick) {
      props.onEditIconClick()
    }
  }

  const onDeleteIconClick = () => {
    if (props.onDeleteIconClick) {
      props.onDeleteIconClick()
    }
  }

  const onRefreshIconClick = () => {
    if (props.onRefreshIconClick) {
      props.onRefreshIconClick()
    }
  }

  const dashboardWidgetId =
    (props.widgetKey && explodeWidgetKey(props.widgetKey)?.dashboardWidgetId) ||
    ''

  return (
    <ContainerFlex
      name="WidgetHeaderTitleIcons"
      className={props.className}
      items={[
        canRefreshWidget(storeRbac)(props.widgetKey) &&
          (themeIsLegacy ? (
            <ContainerIcon
              labelledBy={`refreshWidget-id-${dashboardWidgetId}`}
              key="sync"
              iconComponent={IconSyncOutlined}
              iconProps={{
                onClick: onRefreshIconClick
              }}
              tooltipProps={{ title: translate('Refresh') }}
            />
          ) : (
            <Tooltip title={translate('Refresh')} mouseEnterDelay={0.5}>
              <div>
                <CustomFormWrapperButtonSimple
                  labelledBy={`refreshWidget-id-${dashboardWidgetId}`}
                  onClick={onRefreshIconClick}
                >
                  <IconRecast />
                </CustomFormWrapperButtonSimple>
              </div>
            </Tooltip>
          )),
        canEditWidget(storeRbac)(props.widgetKey) &&
          (themeIsLegacy ? (
            <ContainerIcon
              labelledBy={`modifyWidget-id-${dashboardWidgetId}`}
              key="edit"
              iconComponent={IconEditOutlined}
              iconProps={{
                onClick: onEditIconClick
              }}
              tooltipProps={{ title: translate('Modify') }}
            />
          ) : (
            <Tooltip title={translate('Modify')} mouseEnterDelay={0.5}>
              <div>
                <CustomFormWrapperButtonSimple
                  labelledBy={`modifyWidget-id-${dashboardWidgetId}`}
                  onClick={onEditIconClick}
                >
                  <IconEdit />
                </CustomFormWrapperButtonSimple>
              </div>
            </Tooltip>
          )),
        canDeleteWidget(storeRbac)(props.widgetKey) &&
          (themeIsLegacy ? (
            <ContainerIcon
              labelledBy={`deleteWidget-id-${dashboardWidgetId}`}
              key="delete"
              iconComponent={IconDeleteOutlined}
              iconProps={{
                onClick: onDeleteIconClick
              }}
              tooltipProps={{ title: translate('Delete') }}
            />
          ) : (
            <Tooltip title={translate('Delete')} mouseEnterDelay={0.5}>
              <div>
                <CustomFormWrapperButtonSimple
                  labelledBy={`deleteWidget-id-${dashboardWidgetId}`}
                  onClick={onDeleteIconClick}
                >
                  <IconTrash />
                </CustomFormWrapperButtonSimple>
              </div>
            </Tooltip>
          ))
      ]}
      flexGap={themeIsLegacy ? 'none' : 'verySmall'}
    />
  )
}

export default styled(WidgetHeaderTitleIcons)`
  margin: ${consts.marginVeryVerySmall} 0;
`
