import { Blade } from '@app/components-legacy/Blade'
import { ContainerFilters } from '@app/components-legacy/Container'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import IoEBoardCards from '@app/pages/IoE/IoEBoardPage/IoEBoardCards'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DomainDetailsBladeHeader from './DomainDetailsBladeHeader'
import { onDomainDetailsLoad } from './handlers'
import IoEDomainFilters from './IoEDomainFilters'
import { canAccessToDomainDetails } from './permissions'

interface IBladeDomainDetailsProps {}

const DomainDetailsBlade: React.FC<IBladeDomainDetailsProps> = () => {
  const { storeTopology } = useStores()

  const appRouter = useAppRouter()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Topology_DomainDetails,
    parameters: {
      directoryId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Topology_DomainDetails,
        parameters
      }}
      onLoad={onDomainDetailsLoad(storeTopology)(parameters.directoryId)}
      flags={[
        storeTopology.storeTopologyDomainDetails.storeIoE
          .storeFlagsFetchCheckersExposure.flags,
        storeTopology.storeTopologyDomainDetails.storeFlagsFetchDomainScore
          .flags
      ]}
      layout={{
        name: 'default',
        header: <DomainDetailsBladeHeader />,
        menu: (
          <ContainerFilters>
            <IoEDomainFilters />
          </ContainerFilters>
        ),
        content: (
          <IoEBoardCards
            storeIoE={storeTopology.storeTopologyDomainDetails.storeIoE}
            directoryIds={[parameters.directoryId]}
          />
        )
      }}
      rbacCapabilityCheck={canAccessToDomainDetails(parameters.directoryId)}
    />
  )
}

export default observer(DomainDetailsBlade)
