export const NODE_WIDTH = 35
export const MAIN_NODE_WIDTH = 65
export const EXPAND_NODE_WIDTH = 25
export const LINK_ARROW_SIZE = 5
export const LINK_ICON_WIDTH = 20
export const CELL_SIZE = 150

export const MAX_NODES_DEPTH = 2

export const MAX_NODES_TO_DISPLAY_BEFORE_DISPLAYING_ALERT = 300
export const MAX_CHILDREN_BEFORE_HIDING_THEM = 20
export const MAX_DRAWER_NODE_SELECTION = 20
