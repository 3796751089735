import { consts } from '@app/styles'
import { range } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'

const Pre = styled.pre`
  position: relative;
  padding: ${consts.paddingVerySmall} ${consts.paddingVerySmall}
    ${consts.paddingVerySmall} 3.8em;
  border: 1px solid ${consts.colorBlueGray025};
  border-radius: 4px;
  background-color: ${consts.colorWhite};
`

const CodeWrapper = styled.code`
  position: relative;
  white-space: inherit;
  color: ${consts.colorBlueGray005};
`

const LinesNumbers = styled.span`
  position: absolute;
  pointer-events: none;
  top: -2px;
  font-size: 100%;
  left: -3.8em;
  width: 3em;
  user-select: none;
`

const LineNumber = styled.span`
  pointer-events: none;
  display: block;
  color: ${consts.colorBlueGray015};
  padding-right: 0.8em;
  text-align: right;
`

interface ICodeProps {
  children: string
  className?: string
}

const Code: React.FC<ICodeProps> = props => {
  const linesNumber = (props.children.match(/\n/g) || '').length + 1

  return (
    <Pre className={props.className}>
      <CodeWrapper>
        {props.children}
        <LinesNumbers aria-hidden={true}>
          {range(1, linesNumber + 1).map(lineNumber => (
            <LineNumber key={lineNumber}>{lineNumber}</LineNumber>
          ))}
        </LinesNumbers>
      </CodeWrapper>
    </Pre>
  )
}

export default Code
