import { SCENE_TOOLIP_DEBOUNCE_DELAY } from '@app/stores/Topology/consts'
import { consts } from '@app/styles'
import { TrustHazardLevel } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'

interface ITrustArrowProps {
  className?: string
  uid: string
  isBidirectional: boolean
  hazardLevel: TrustHazardLevel
  isHovered: boolean
  isHighlighted: boolean
}

const TrustArrow: React.FC<ITrustArrowProps> = props => {
  const arrowContent = props.isBidirectional ? '< >' : '>'

  return (
    <text className={props.className}>
      <textPath href={`#${props.uid}`} startOffset="55%">
        {arrowContent}
      </textPath>
    </text>
  )
}

export default styled(TrustArrow)`
  font-size: ${props => (props.isHovered ? '40px' : '20px')};
  fill: ${props =>
    props.hazardLevel === TrustHazardLevel.Dangerous
      ? consts.colorRed001
      : props.hazardLevel === TrustHazardLevel.Unknown
        ? consts.colorOrange001
        : consts.colorBlue010};
  font-family: 'Rubik';
  dominant-baseline: central;
  opacity: ${props => (props.isHighlighted || props.isHovered ? 1 : 0.2)};
  transition: all ${SCENE_TOOLIP_DEBOUNCE_DELAY}ms linear;
  pointer-events: none;
`
