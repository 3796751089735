import { ContainerFlex } from '@app/components/Container'
import { LabelColorTag } from '@app/components/Labels'
import type { IContainerSimpleProps } from '@app/components-legacy/Container/ContainerSimple'
import type { IApiCall } from '@app/entities/EntityUserLog'
import type EntityUserLog from '@app/entities/EntityUserLog'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { marginSmall, marginSmaller } from '@app/styles/consts'
import { filterFalsies } from '@libs/filterFalsies'
import { isDefined } from '@libs/isDefined'
import React from 'react'
import styled from 'styled-components'
import ActivityLogsLogPayload from '../../Components/DrawerActivityLogPayload/ActivityLogsLogPayload'
import { onShowPayloadButtonClick } from '../../Components/DrawerActivityLogPayload/handlers'
import UserLogCollapseRow from '../../Components/UserLogCollapseRow'
import { UserLogIcon } from '../../Components/UserLogIcon'
import { UserLogIpAddress } from '../../Components/UserLogIpAddress'
import UserLogTime from '../../Components/UserLogTime'
import { UserLogUser } from '../../Components/UserLogUser'
import { colorUserDisplayPayloadButton, PAYLOAD_MAX_LENGTH } from '../../consts'
import { UserLogAPICallAction } from './UserLogAPICallAction'

interface IUserLogAPICallRowProps extends IContainerSimpleProps {
  log: EntityUserLog
}

const UserLogAPICallRow: React.FC<IUserLogAPICallRowProps> = ({
  log,
  className
}) => {
  const { storeActivityLogs } = useStores()

  if (!log.decodedLogAttributes) {
    return null
  }

  const rowItems = filterFalsies([
    <UserLogIcon trace={log} />,
    <UserLogUser user={log.userEmail} />,
    <UserLogIpAddress ipAddress={log.userIp} />,
    <div>
      <UserLogAPICallAction log={log} />
    </div>,
    <UserLogTime log={log} />
  ])

  const title = (
    <ContainerFlex
      name={UserLogAPICallRow.name}
      className={className}
      ariaRoles={['list', 'row']}
      flexWrap="wrap"
      labelledBy={UserLogAPICallRow.name}
      alignItems="center"
      items={rowItems}
      itemsFlexGrow={[0, 0, 0, 1, 0]}
    />
  )

  const decodedAttributes = log.decodedLogAttributes as IApiCall

  if (!isDefined(decodedAttributes.payload)) {
    return title
  }

  try {
    const exceedsLength: boolean =
      decodedAttributes.payload.length > PAYLOAD_MAX_LENGTH

    if (!exceedsLength) {
      return (
        <UserLogCollapseRow title={title} log={log}>
          <ActivityLogsLogPayload log={log} />
        </UserLogCollapseRow>
      )
    }

    const displayButton = (
      <LabelColorTag
        color={colorUserDisplayPayloadButton.background}
        labelProperties={{
          color: colorUserDisplayPayloadButton.text,
          labelledBy: 'displayPayload',
          style: {
            cursor: 'pointer',
            margin: consts.marginVerySmall
          }
        }}
      >
        <div onClick={onShowPayloadButtonClick(storeActivityLogs, log)}>
          Display
        </div>
      </LabelColorTag>
    )

    return (
      <UserLogCollapseRow title={title} log={log}>
        <ContainerFlex
          name="traceContent"
          labelledBy="payloadTooLarge"
          flexWrap="wrap"
          itemsFlexGrow={[1, 0]}
          items={[
            <div>The volume of information is too large to display here</div>,
            displayButton
          ]}
        />
      </UserLogCollapseRow>
    )
  } catch (e) {
    return null
  }
}

export default styled(UserLogAPICallRow)`
  border-radius: ${consts.borderRadiusRedesign};
  background-color: ${consts.colorWhite};
  padding-top: ${consts.paddingSmall};
  padding-bottom: ${consts.paddingSmall};
  padding-right: ${consts.paddingExtraLarge};
  padding-left: ${consts.paddingLarge};
  margin-top: ${consts.marginExtraSmall};

  .traceUser {
    text-align: left;
    margin-right: ${marginSmaller};
  }

  .traceIpAddress {
    text-align: left;
    margin-right: ${marginSmall};
  }

  .traceAction {
    text-align: left;
    margin-right: ${marginSmall};
  }

  .traceTime {
    text-align: right;
  }
`
