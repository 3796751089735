import { ContainerContent } from '@app/components-legacy/Container'
import { handleInputCheckersOnLoad } from '@app/components-legacy/Input/InputCheckers/handlers'
import SpinnerInline, {
  SpinnerInlineSize
} from '@app/components-legacy/Spinner/SpinnerInline'
import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import type { BaseButtonProps } from 'antd/lib/button/button'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import InputCheckersButton from './InputCheckersButton'

interface IInputCheckersProps<GC extends TGenericChecker> {
  className?: string
  style?: React.CSSProperties
  size?: BaseButtonProps['size']
  onValidate?: () => void
  loadAtMount?: boolean
  storeInputGenericCheckers: StoreInputGenericCheckers<GC>
}

/**
 * @deprecated
 */
export function InputCheckers<GC extends TGenericChecker>(
  props: IInputCheckersProps<GC>
): React.ReactElement {
  return (
    <ContainerContent
      onLoad={handleInputCheckersOnLoad(props.storeInputGenericCheckers)(
        props.loadAtMount
      )}
      flags={props.storeInputGenericCheckers.storeFlagsFetchCheckers.flags}
      spinner={<SpinnerInline size={SpinnerInlineSize.small} />}
    >
      {/* Wrap in a div to avoid to have a full width button */}
      <div>
        <InputCheckersButton
          size={props.size}
          onValidate={props.onValidate}
          storeInputGenericCheckers={props.storeInputGenericCheckers}
        />
      </div>
    </ContainerContent>
  )
}

export default observer(InputCheckers)
