import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreForm from '@app/stores/helpers/StoreForm'
import type StoreProfileCheckerOptionOWCT from '@app/stores/Management/StoreProfiles/StoreProfileCheckerOptionOWCT'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import GroupConfiguration from './GroupConfiguration'
import { onAddNewConfigurationClick } from './handlers'

interface IConfigurationConfigurationSerieFormOWCTProps {
  readonly: boolean
  highlight: boolean
  storeForm: StoreForm<any>
  storeProfileCheckerOptionArrayObject: StoreProfileCheckerOptionOWCT
}

const ConfigurationConfigurationSerieFormOWCT: React.FC<
  IConfigurationConfigurationSerieFormOWCTProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Profiles.Configuration']
  })

  const { storeFormConfigurations } = props.storeProfileCheckerOptionArrayObject

  if (!storeFormConfigurations.length) {
    return (
      <FormWrapperButton
        labelledBy="addConfiguration"
        buttonProps={{
          onClick: onAddNewConfigurationClick(
            props.storeProfileCheckerOptionArrayObject
          )
        }}
      >
        {translate('Add a configuration')}
      </FormWrapperButton>
    )
  }

  const items = storeFormConfigurations.map((storeFormConfiguration, index) => {
    return (
      <GroupConfiguration
        index={index}
        readonly={props.readonly}
        highlight={props.highlight}
        storeFormConfiguration={storeFormConfiguration}
        storeProfileCheckerOptionArrayObject={
          props.storeProfileCheckerOptionArrayObject
        }
      />
    )
  })

  return (
    <ContainerFlex
      name={ConfigurationConfigurationSerieFormOWCT.name}
      direction="column"
      items={items}
      spaced
    />
  )
}

export default observer(ConfigurationConfigurationSerieFormOWCT)
