import type { PropertiesNullable } from '@@types/helpers'
import type { Pagination as PaginationOffset } from '@libs/openapi/service-identity-core'
import type { Maybe, Pagination } from '@server/graphql/typeDefs/types'

export const PAGINATION_PERPAGE_DEFAULT = 20

export default class EntityPagination
  implements PropertiesNullable<Pagination>
{
  page: Maybe<number> = null
  perPage: Maybe<number> = null
  totalCount: Maybe<number> = null

  constructor(pagination: Partial<Pagination>) {
    this.setPagination(pagination)
  }

  setPagination(pagination?: Partial<Pagination>): this {
    if (!pagination) {
      return this
    }

    Object.assign(this, pagination)
    return this
  }

  /**
   * Return pagination offset from page / perPage.
   */
  computePaginationOffset(): {
    offset: number
    limit: number
  } {
    const page = Math.max(1, this.page || 1)
    const perPage = this.perPage || PAGINATION_PERPAGE_DEFAULT

    const offset = Math.max(page - 1, 0) * perPage
    const limit = perPage

    return {
      offset,
      limit
    }
  }

  /**
   * Static
   */

  /**
   * Instanciate a new EntityPagination from PaginationOffset.
   */
  static fromPaginationOffset(
    paginationOffset: PaginationOffset
  ): EntityPagination {
    const page = Math.max(
      1,
      Math.ceil((paginationOffset.offset + 1) / paginationOffset.limit)
    )
    const perPage = paginationOffset.limit || PAGINATION_PERPAGE_DEFAULT
    const totalCount = paginationOffset.total

    return new EntityPagination({ page, perPage, totalCount })
  }

  /**
   * Instanciate an EntityPagination object from default or custom pagination parameters.
   */
  static fromDefaultAttributes(pagination?: Partial<Pagination>) {
    return new EntityPagination({
      page: 1,
      perPage: PAGINATION_PERPAGE_DEFAULT,
      totalCount: -1,
      ...pagination
    })
  }
}
