import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { mergeFlags } from '@app/stores/helpers/StoreFlags/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditSyslogAlert } from '../permissions'
import { onSyslogEditLoad, onSyslogEditUnload } from './handlers'
import SyslogEditForm from './SyslogEditForm'

interface IConfigurationSyslogsEditPageProps {}

const ConfigurationSyslogsEditPage: React.FC<
  IConfigurationSyslogsEditPageProps
> = () => {
  const appRouter = useAppRouter()

  const { storeManagementSyslogs } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_System_Configuration_Syslogs_Edit,
    parameters: {
      syslogId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  const flags = mergeFlags(
    // wait for infras
    storeManagementSyslogs.storeInfrastructures.storeFlagsFetchInfrastructures
      .flags,
    // wait IoE loaded in cards
    storeManagementSyslogs.storeInputCheckersExposure.storeFlagsFetchCheckers
      .flags,
    // wait IoA loaded in cards
    storeManagementSyslogs.storeInputCheckersAttacks.storeFlagsFetchCheckers
      .flags,
    // wait for the current syslog to be loaded,
    storeManagementSyslogs.storeFlags.flags
  )

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_System_Configuration_Syslogs_Edit,
        parameters: {
          syslogId: parameters.syslogId
        }
      }}
      onLoad={onSyslogEditLoad(storeManagementSyslogs)(parameters.syslogId)}
      onUnload={onSyslogEditUnload(storeManagementSyslogs)}
      flags={flags}
      layout={{
        name: 'default',
        content: (
          <ContainerContent>
            <SyslogEditForm syslogId={parameters.syslogId} />
          </ContainerContent>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canEditSyslogAlert(parameters.syslogId)}
    />
  )
}

export default observer(ConfigurationSyslogsEditPage)
