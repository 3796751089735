import { IconEdit, IconTrash } from '@app/components/Icon'
import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import {
  ContainerContent,
  ContainerFlex,
  ContainerIcon,
  ContainerTimeline
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form/Wrappers'
import ButtonSimple from '@app/components-legacy/Form/Wrappers/ButtonSimple'
import { ButtonVariant } from '@app/components-legacy/Form/Wrappers/types'
import {
  IconDeleteOutlined,
  IconEditOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { LabelNonBreakable } from '@app/components-legacy/Label'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useAppTranslator, useStores } from '@app/hooks'
import { BookmarkCategoryEnum } from '@app/stores/TrailFlow/StoreSearchBookmarks'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { ensureArray } from '@libs/ensureArray'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onSearchBookmarkEntryDelete } from '../../TrailFlowHistoryDrawer/DrawerBookmarks/handlers'
import TrailFlowSearchEntry from '../../TrailFlowSearchEntry'
import { handleEditBookmarkLoad } from '../DrawerEditBookmark/handlers'
import { handleEditBookmarkCategoryLoad } from '../DrawerEditBookmarkCategory/handlers'
import DrawerFooter from './DrawerFooter'
import {
  onDeleteSearchBookmarkCategory,
  onTrailFlowSearchBookmarksSelection
} from './handlers'
import { getCategoryTranslation } from './helper'

export interface IDrawerContentProps {}

const DrawerContent: React.FC<IDrawerContentProps> = () => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'TrailFlow.SearchBookmarks']
  })

  const { storeTrailFlow } = useStores()

  const bookmarksCategories = Array.from(
    storeTrailFlow.storeSearchBookmarks.searchBookmarksSortByCategories.keys()
  )

  const getCategoryLabel = (category: string) => {
    const isUncategorized = category === BookmarkCategoryEnum.Uncategorized

    const labelItems = [
      themeIsLegacy ? (
        <LabelNonBreakable ellipsis>
          {getCategoryTranslation(category, translate)}
        </LabelNonBreakable>
      ) : (
        <Label variant={LabelVariant.p}>
          <LabelNonBreakable ellipsis>
            {getCategoryTranslation(category, translate)}
          </LabelNonBreakable>
        </Label>
      )
    ]

    if (!isUncategorized) {
      labelItems.push(
        themeIsLegacy ? (
          <ButtonSimple
            labelledBy="editBookmarksCategory"
            onClick={handleEditBookmarkCategoryLoad(
              storeTrailFlow.storeSearchBookmarks
            )(category)}
          >
            <ContainerIcon
              labelledBy="editBookmarksCategory"
              iconComponent={IconEditOutlined}
            />
          </ButtonSimple>
        ) : (
          <FormWrapperButton
            labelledBy="editBookmarksCategory"
            variant={ButtonVariant.colored}
            icon={IconEdit}
            buttonProps={{
              onClick: handleEditBookmarkCategoryLoad(
                storeTrailFlow.storeSearchBookmarks
              )(category)
            }}
          />
        )
      )

      labelItems.push(
        themeIsLegacy ? (
          <ButtonSimple
            labelledBy="deleteBookmarksCategory"
            onClick={onDeleteSearchBookmarkCategory(
              storeTrailFlow.storeSearchBookmarks
            )(category)}
          >
            <ContainerIcon
              labelledBy="deleteBookmarksCategory"
              iconComponent={IconDeleteOutlined}
            />
          </ButtonSimple>
        ) : (
          <FormWrapperButton
            labelledBy="deleteBookmarksCategory"
            variant={ButtonVariant.colored}
            icon={IconTrash}
            buttonProps={{
              onClick: onDeleteSearchBookmarkCategory(
                storeTrailFlow.storeSearchBookmarks
              )(category)
            }}
          />
        )
      )
    }

    return (
      <ContainerFlex
        name="CategoryBookmarkLabel"
        alignItems="center"
        items={labelItems}
        spaced
        spaceWidth="small"
      />
    )
  }

  const getItemsSearchBookmarks = () =>
    bookmarksCategories.map(key => (
      <ContainerFlex
        name="SearchBookmarksPerCat"
        direction="column"
        items={ensureArray(
          storeTrailFlow.storeSearchBookmarks.searchBookmarksSortByCategories.get(
            key
          )
        ).map(entitySearchBookmark => {
          if (
            !entitySearchBookmark.expressionObject ||
            !entitySearchBookmark.expression
          ) {
            return
          }

          return (
            <TrailFlowSearchEntry
              key={entitySearchBookmark.id}
              expressionObject={entitySearchBookmark.expressionObject}
              name={entitySearchBookmark.name}
              onClick={onTrailFlowSearchBookmarksSelection(storeTrailFlow)(
                entitySearchBookmark.expression
              )}
              onEdit={handleEditBookmarkLoad(
                storeTrailFlow.storeSearchBookmarks
              )(entitySearchBookmark)}
              onDelete={onSearchBookmarkEntryDelete(
                storeTrailFlow.storeSearchBookmarks
              )(entitySearchBookmark)}
            />
          )
        })}
        spaced
        spaceWidth="veryVerySmall"
      />
    ))

  if (
    !storeTrailFlow.storeSearchBookmarks.searchBookmarksEntriesFromSearch.length
  ) {
    return (
      <>
        {themeIsLegacy ? (
          translate('No bookmark found')
        ) : (
          <Label variant={LabelVariant.p}>
            {translate('No bookmark found')}
          </Label>
        )}

        <DrawerFooter />
      </>
    )
  }

  return (
    <ContainerContent
      flags={[
        storeTrailFlow.storeSearchBookmarks.storeFlagsSearchBookmarks.flags,
        storeTrailFlow.storeSearchBookmarks.storeFlagsDeleteSearchBookmarksEntry
          .flags
      ]}
      spinner={<SpinnerInline />}
    >
      <ContainerTimeline
        itemLabels={bookmarksCategories.map(category =>
          getCategoryLabel(category)
        )}
        items={getItemsSearchBookmarks()}
      />

      <DrawerFooter />
    </ContainerContent>
  )
}

export default observer(DrawerContent)
