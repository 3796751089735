import {
  ContainerContent,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import {
  FormWrapperCheckbox,
  FormWrapperSelect
} from '@app/components-legacy/Form'
import { InputInfrastructures } from '@app/components-legacy/Input'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { onCheckboxChange } from '@app/stores/helpers/StoreForm/handlers'
import { filterFalsies } from '@libs/filterFalsies'
import { isDefinedAndNotEmptyString } from '@libs/isDefined'
import type { Maybe } from '@server/graphql/typeDefs/types'
import { SyslogInputType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  canSelectTriggerOnAttacks,
  canSelectTriggerOnDeviances,
  canSelectTriggerOnHealthCheckStatusChange
} from '../permissions'
import getFieldsForInputType from './getFieldsForInputType'
import {
  onDirectoriesChange,
  onProfilesChanges,
  onSyslogTriggerChanges
} from './handlers'
import { canAccessToIoASecurityCheck } from './permissions'
import { SyslogFormFieldName } from './types'

export interface ISyslogCommonFormAlertFieldsProps {
  version: 'creation' | 'edition'
  onSubmit: (e: React.FormEvent<any>) => void
}

const SyslogCommonFormAlertFields: React.FC<
  ISyslogCommonFormAlertFieldsProps
> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Titles',
      'Errors',
      'Errors.Form',
      'HealthCheck',
      'Components.InputInfrastructures',
      'Components.InputHealthChecks',
      'Management.System.Configuration.SyslogAlerts',
      'Components.LabelChecker.ComplexityLevel'
    ]
  })

  const {
    storeRbac,
    storeManagementSyslogs,
    storeManagementDirectories,
    storeAuthentication
  } = useStores()

  const getProfileController = () => {
    const selectedProfiles = storeManagementSyslogs.storeForm
      .getFieldValueAsString(SyslogFormFieldName.profiles)
      .split(',')
      .filter(isDefinedAndNotEmptyString)

    const profiles = Array.from(storeAuthentication.profiles.values())

    return (
      <FormWrapperSelect
        labelledBy="syslog-alert-profiles"
        width="normal"
        selectProps={{
          mode: 'multiple',
          value: selectedProfiles,
          onChange: onProfilesChanges(storeManagementSyslogs)
        }}
        selectOptions={profiles.map(profile => {
          return {
            label: profile.getPropertyAsString('name'),
            value: profile.getPropertyAsString('id')
          }
        })}
      />
    )
  }

  const getSecondaryFields = (): IField[] => {
    const { storeForm, storeInfrastructures } = storeManagementSyslogs

    const inputTypeName = storeForm.getFieldValueAsString<
      Maybe<SyslogInputType>
    >(SyslogFormFieldName.inputType)

    return filterFalsies<IField>([
      {
        name: SyslogFormFieldName.inputType,
        label: translate('Alert trigger'),
        required: true,
        control: (
          <FormWrapperSelect
            labelledBy="alertTrigger"
            selectProps={{
              value: storeForm.getFieldValueAsString(
                SyslogFormFieldName.inputType
              ),
              onChange: onSyslogTriggerChanges(storeManagementSyslogs)
            }}
            selectOptions={filterFalsies([
              {
                label: translate('On changes'),
                value: SyslogInputType.AdObjectChanges
              },
              storeRbac.isUserGrantedTo(canSelectTriggerOnDeviances) && {
                label: translate('On each deviance'),
                value: SyslogInputType.Deviances
              },
              storeRbac.isUserGrantedTo(canSelectTriggerOnAttacks) && {
                label: translate('On each attack'),
                value: SyslogInputType.Attacks
              },
              storeRbac.isUserGrantedTo(
                canSelectTriggerOnHealthCheckStatusChange
              ) && {
                label: translate('On health check status change'),
                value: SyslogInputType.HealthChecks
              }
            ])}
          />
        )
      },
      inputTypeName !== SyslogInputType.HealthChecks && {
        name: SyslogFormFieldName.profiles,
        errors: storeForm.field(SyslogFormFieldName.profiles).errors,
        label: translate('Profiles'),
        required: true,
        control: getProfileController()
      },
      inputTypeName !== SyslogInputType.HealthChecks &&
        storeRbac.isUserGrantedTo(canAccessToIoASecurityCheck) && {
          name: SyslogFormFieldName.shouldNotifyOnInitialFullSecurityCheck,
          label: translate(
            'Send alerts when deviances are detected during the initial analysis phase'
          ),
          required: true,
          control: (
            <FormWrapperCheckbox
              labelledBy="send-deviances-during-initial-phase"
              checkboxProps={{
                name: SyslogFormFieldName.shouldNotifyOnInitialFullSecurityCheck,
                checked: storeForm.getFieldValueAsBoolean(
                  SyslogFormFieldName.shouldNotifyOnInitialFullSecurityCheck
                ),
                onChange: onCheckboxChange(storeForm)(
                  SyslogFormFieldName.shouldNotifyOnInitialFullSecurityCheck
                )
              }}
            />
          )
        },
      ...getFieldsForInputType({
        storeManagementSyslogs,
        translate
      }),
      {
        name: 'directories',
        label: translate('Directories'),
        description: translate('Directories affected by this alert'),
        required: true,
        labelAlignItem: 'center',
        hideDecoration: storeManagementDirectories.directories.size === 0,
        control: (
          <InputInfrastructures
            storeInfrastructures={storeInfrastructures}
            onValidate={onDirectoriesChange(storeManagementSyslogs)}
          />
        )
      }
    ])
  }

  return (
    <ContainerContent title={translate('Alert parameters')}>
      <ContainerForm version={props.version} fields={getSecondaryFields()} />
    </ContainerContent>
  )
}

export default observer(SyslogCommonFormAlertFields)
