import type { Maybe } from '@@types/helpers'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { getDataSet } from './functions'

/**
 * Select or unselect the row.
 */
export const onRowSelection =
  (storeWidgetList: Maybe<StoreWidgetList<any, any>>) =>
  (row: any) =>
  (e: CheckboxChangeEvent): void => {
    if (!storeWidgetList) {
      return
    }

    const dataSet = getDataSet(storeWidgetList)

    e.target.checked
      ? storeWidgetList.selectRow(row)
      : storeWidgetList.unselectRow(row, dataSet.rows)
  }

/**
 * When clicking on a row, open or close the related toggleable row.
 */
export const onToggleableRowClick =
  (storeWidgetList: StoreWidgetList<any, any>) => (row: any) => () => {
    storeWidgetList.toggleToggleableRow(row)
  }
