import type { IAttackPathCoords } from '@app/pages/AttackPath/types'
import { getPositionBeforeTheEnd } from '.'

/**
 * Retuns the coords of a vector with margins
 */
export default function getVectorPositionWithMargins(
  point1: IAttackPathCoords,
  point2: IAttackPathCoords,
  marginStart: number,
  marginEnd: number
) {
  const endPoint = getPositionBeforeTheEnd(point1, point2, marginEnd)
  const startPoint = getPositionBeforeTheEnd(point2, point1, marginStart)

  return {
    x1: startPoint.x,
    y1: startPoint.y,
    x2: endPoint.x,
    y2: endPoint.y
  }
}
