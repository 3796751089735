import { BulletCircle } from '@app/components-legacy/Bullet'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelAlt, LabelDate, LabelSliced } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import type { EntityDashboardWidgetOptionsSerieStatAttackCounts } from '@app/entities'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { TimelineInterval } from '@app/stores/IoA/StoreBoard/StoreTimeline/types'
import { consts } from '@app/styles'
import { CriticityValuesOrdered, getCriticityColor } from '@libs/criticity'
import { timestampToDate } from '@libs/debug/timestampToDate'
import { isDefined } from '@libs/isDefined'
import { first } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { getTimelinePillSortedStatsCounts } from './utils'

interface IFiltersTimelineTooltipProps {
  className?: string
  attacksStatCountsEntities: EntityDashboardWidgetOptionsSerieStatAttackCounts[]
}

const FiltersTimelineTooltip: React.FC<
  IFiltersTimelineTooltipProps
> = props => {
  const translate = useAppTranslator({ namespaces: ['IoA.Board'] })

  const { storeIoA } = useStores()

  const getRanksByCriticities = () => {
    return props.attacksStatCountsEntities
      .map((statCount, index) => {
        const criticity = CriticityValuesOrdered[index]

        // avoid to display a block with an empty list
        if (!statCount.dataEntities.length) {
          return
        }

        const attackCounts = getTimelinePillSortedStatsCounts(
          statCount.dataEntities
        )

        const hasAttacks = attackCounts.some(counts => counts.count !== 0)

        // avoid to display blocks with attacks
        if (!hasAttacks) {
          return null
        }

        const attackNames = attackCounts.map(data => {
          const attackTypeId = data.getPropertyAsNumber('attackTypeId')
          const attackType = storeIoA.storeBoard.getAttackTypeById(attackTypeId)
          const attackName = attackType?.getPropertyAsString('name')

          if (!attackName) {
            return null
          }

          // don't display attacks with a count to 0
          if (!data.count) {
            return null
          }

          return (
            <li key={attackTypeId}>
              <ContainerFlex
                name="FiltersTimelineTooltipAttacks"
                className="tooltipAttack"
                justifyContent="space-between"
                items={[
                  <LabelSliced maxLength={25} value={attackName} />,
                  <span>({data.count})</span>
                ]}
                spaced
                spaceWidth="verySmall"
              />
            </li>
          )
        })

        if (!attackNames.length) {
          return null
        }

        return (
          <div key={criticity}>
            <ContainerFlex
              name="FiltersTimelineTooltipTitle"
              className="tooltipTitle"
              justifyContent="space-between"
              alignItems="center"
              items={[
                <div>{translate(`Top 3 ${criticity}`)}</div>,

                <BulletCircle color={getCriticityColor(criticity)} />
              ]}
              spaced
              spaceWidth="small"
            />

            <ul className="tooltipList">{attackNames}</ul>
          </div>
        )
      })
      .filter(isDefined)
  }

  const criticityBlocks = React.Children.toArray(getRanksByCriticities())

  if (!criticityBlocks.length) {
    return <div>{translate('No attack detected during this period')}</div>
  }

  const getDate = () => {
    const timestampStart = first(
      props.attacksStatCountsEntities
    )?.timestampStart

    if (!timestampStart) {
      return null
    }

    const date = timestampToDate(timestampStart)

    // Only show utc datetime when hour / day filter is selected
    const needUTCDisplay =
      storeIoA.storeBoard.storeTimeline.interval ===
        TimelineInterval.Every5Minutes ||
      storeIoA.storeBoard.storeTimeline.interval === TimelineInterval.EveryHour

    return (
      <LabelAlt variant={LabelAltVariant.Bolded}>
        <LabelDate
          date={date}
          formatDateOptions={{
            utc: needUTCDisplay,
            format: storeIoA.storeBoard.storeTimeline.tooltipDateFormat
          }}
          inline
          noTooltip
        />
        {needUTCDisplay && <span> (UTC)</span>}
      </LabelAlt>
    )
  }

  return (
    <div data-name={FiltersTimelineTooltip.name} className={props.className}>
      {getDate()}
      {criticityBlocks}
    </div>
  )
}

const ObservedFiltersTimelineTooltip = observer(FiltersTimelineTooltip)

export default styled(ObservedFiltersTimelineTooltip)`
  .tooltipTitle {
    margin-top: 10px;
    border-bottom: 1px solid white;
    margin-bottom: 5px;
    padding-bottom: 5px;
    font-weight: ${consts.fontWeightBold};
  }

  .tooltipList {
    padding-left: 15px;
  }

  .tooltipAttack {
    display: inline-flex;
  }
`
