import { ContainerFlex } from '@app/components/Container'
import { WidgetListPagination } from '@app/components/Widgets/WidgetList'
import { ContainerContent } from '@app/components-legacy/Container'
import ContainerSections from '@app/components-legacy/Container/ContainerSections'
import { useStores } from '@app/hooks'
import HealthCheckDomainStatusFilters from '@app/pages/HealthCheck/HealthCheckDomainStatus/HealthCheckDomainStatusFilters'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  handleHealthChecksDomainStatusOnLoad,
  handleHealthChecksDomainStatusOnUnload,
  handleHealthChecksDomainStatusPaginationChange
} from './handlers'
import HealthCheckTable from './HealthCheckDomainStatusTable'

const StyledContainerFlex = styled(ContainerFlex)`
  height: 100%;
`

const StyledHealthCheckTable = styled(HealthCheckTable)`
  height: 100%;
`

export interface IHealthCheckDomainStatusProps {}

function HealthCheckDomainStatus(props: IHealthCheckDomainStatusProps) {
  const { storeHealthCheck } = useStores()

  const { storeWidgetListHealthChecksDomainStatus } = storeHealthCheck

  const healthChecksDomainStatusEntities =
    storeWidgetListHealthChecksDomainStatus.allEntitiesAsArray

  return (
    <ContainerContent
      onLoad={handleHealthChecksDomainStatusOnLoad(storeHealthCheck)}
      onUnload={handleHealthChecksDomainStatusOnUnload(storeHealthCheck)}
    >
      <StyledContainerFlex
        name="HealthCheckContent"
        items={[
          <ContainerSections
            contentContainerProps={{
              style: { margin: `0 -${consts.marginDefault}` }
            }}
            header={<HealthCheckDomainStatusFilters />}
            content={
              <StyledHealthCheckTable
                healthCheckEntities={healthChecksDomainStatusEntities}
                flags={[
                  storeHealthCheck.storeInfrastructures
                    .storeFlagsFetchInfrastructures.flags,
                  storeHealthCheck.storeFlagsFetchDomainStatus.flags
                ]}
              />
            }
            footer={
              <WidgetListPagination
                onChange={handleHealthChecksDomainStatusPaginationChange(
                  storeHealthCheck
                )}
                storeWidgetList={
                  storeHealthCheck.storeWidgetListHealthChecksDomainStatus
                }
              />
            }
          />
        ]}
      />
    </ContainerContent>
  )
}

export default observer(HealthCheckDomainStatus)
