import type { Maybe } from '@@types/helpers'
import { LabelAlt } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { useAppTranslator } from '@app/hooks'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { onAttackLinkClick } from './handlers'

interface IIoAIncidentLabelValueProps {
  url?: Maybe<string>
  variant?: LabelAltVariant
  children: Maybe<React.ReactNode>
}

/**
 * Display a label or fallback to an 'Unknown' translation.
 */
const IoAIncidentLabelValue: React.FC<IIoAIncidentLabelValueProps> = props => {
  const translate = useAppTranslator({ namespaces: ['IoA.Attacks'] })

  const variant = props.variant || LabelAltVariant.Normal

  if (!props.children) {
    return (
      <LabelAlt variant={variant | LabelAltVariant.Minor}>
        {translate('Unknown')}
      </LabelAlt>
    )
  }

  if (props.url) {
    return (
      <Link to={props.url} onClick={onAttackLinkClick}>
        {<LabelAlt variant={variant}>{props.children}</LabelAlt>}
      </Link>
    )
  }

  return <LabelAlt variant={variant}>{props.children}</LabelAlt>
}

export default IoAIncidentLabelValue
