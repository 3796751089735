export enum PlaceHolderName {
  drawerOverlay = 'drawerOverlay',

  // generic placeholders for drawers

  genericDrawer = 'genericDrawer',
  alertsDrawer = 'alertsDrawer',
  selectorDrawer = 'selectorDrawer',

  // placeholders for vertical drawers

  verticalDrawer = 'verticalDrawer',

  // placeholders for blades' layout

  // /!\ Important: Name the placeholder `blade.*` to let the portal uid logic
  // generates portail name relative to a blade uuid
  // (related to `getPlaceHolderName` function in src/app/components/Portal/usePortal.tsx)

  bladeHeader = 'bladeHeader',
  bladeContent = 'bladeContent',
  bladeFooter = 'bladeFooter',

  // placeholders for drawers' layout

  authFooter = 'authFooter',
  drawerFooter = 'drawerFooter',
  firstLoginPasswordChangeFooter = 'firstLoginPasswordChangeFooter'
}
