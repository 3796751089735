export class SecurityEngineError extends Error {
  extra: string

  constructor(message?: string, extra?: string) {
    super(message) // Pass the message to the Error constructor

    // Ensure the name of this error is the same as the class name
    this.name = this.constructor.name

    // This would be a custom property you could add.
    // It's not needed for the error itself, but could be helpful for debugging or handling the error.
    this.extra = extra || ''

    // This line is needed to ensure the `CustomError` constructor is in the stack trace, not the base `Error`
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message || '').stack
    }
  }
}
