import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import HeadBarContent from './HeadBarContent'

interface IHeadBarProps {}

const HeadBar: React.FC<IHeadBarProps> = props => {
  const { storeBlades } = useStores()

  const hasBlades = storeBlades.bladesAsArray.length > 0

  return <HeadBarContent hasBlades={hasBlades} />
}

export default observer(HeadBar)
