import { ContainerFlex } from '@app/components/Container'
import { Label, LabelColorTag } from '@app/components/Labels'
import type { IContainerSimpleProps } from '@app/components-legacy/Container/ContainerSimple'
import { IconSVG } from '@app/components-legacy/Icon'
import type { IConfigurationChangedItem } from '@app/entities/EntityUserLog'
import { ConfigurationType } from '@app/entities/EntityUserLog'
import { consts } from '@app/styles'
import React from 'react'
import styled from 'styled-components'
import {
  colorActivityLogsLogAlternateBackgroundRedesign,
  colorUserLogActivatedRedesign,
  colorUserLogDeactivatedRedesign,
  colorUserLogIoAAttackTypeRedesign
} from '../../consts'
import { renderMultipleValues } from '../../utils'

interface IUserLogUserCreatedItem extends IContainerSimpleProps {
  changedItem: IConfigurationChangedItem
}

const StyledAction = styled(ContainerFlex)`
  border-radius: ${consts.borderRadiusRedesign};
  background-color: ${colorActivityLogsLogAlternateBackgroundRedesign};
  padding: ${consts.paddingVerySmall} ${consts.paddingDefault};
`

const StyledIcon = styled(IconSVG)`
  transform: rotateY(180deg);
  margin-right: ${consts.marginSmall};
`

const StyledSpacedLabel = styled(Label)`
  margin-right: ${consts.marginVerySmall};
`

const UserTraceUserUpdatedItem = (props: IUserLogUserCreatedItem) => {
  const { changedItem } = props

  const getPreviousNext = (): JSX.Element[] => {
    // If the field is a role, we have a different output
    if (
      changedItem.fieldType === ConfigurationType.userRole &&
      changedItem.before === changedItem.after
    ) {
      return []
    }

    return [
      ...renderMultipleValues(
        'before',
        String(changedItem.field),
        changedItem.before,
        changedItem.fieldType
      ),
      <StyledSpacedLabel label="to" />,
      ...renderMultipleValues(
        'after',
        String(changedItem.field),
        changedItem.after,
        changedItem.fieldType
      )
    ]
  }

  const getItems = (): JSX.Element[] => {
    if (changedItem.fieldType === ConfigurationType.userRole) {
      const { field, after } = changedItem

      if (typeof field !== 'string') {
        return []
      }

      return [
        <LabelColorTag
          color={
            after
              ? colorUserLogActivatedRedesign.background
              : colorUserLogDeactivatedRedesign.background
          }
          labelProperties={{
            color: after
              ? colorUserLogActivatedRedesign.text
              : colorUserLogDeactivatedRedesign.text,
            labelledBy: after === true ? 'Activated' : 'Deactivated'
          }}
        >
          {after === true ? 'Activated' : 'Deactivated'}
        </LabelColorTag>,

        <LabelColorTag
          color={colorUserLogIoAAttackTypeRedesign.background}
          labelProperties={{
            color: colorUserLogIoAAttackTypeRedesign.text,
            labelledBy: 'field'
          }}
        >
          {field}
        </LabelColorTag>
      ]
    }

    if (typeof changedItem.field !== 'string') {
      return []
    }

    return [
      <LabelColorTag
        color={colorUserLogIoAAttackTypeRedesign.background}
        labelProperties={{
          color: colorUserLogIoAAttackTypeRedesign.text
        }}
      >
        {changedItem.field}
      </LabelColorTag>,
      ...getPreviousNext()
    ]
  }

  return (
    <ContainerFlex
      name={UserTraceUserUpdatedItem.name}
      className={props.className}
      ariaRoles={['list', 'row']}
      labelledBy="updatedUserSubLine"
      alignItems="center"
      items={[
        <StyledIcon iconName="activityLogs/backline" size={20} />,
        <StyledAction
          name="ChangedItemAction"
          flexWrap="wrap"
          items={getItems()}
        />
      ]}
    />
  )
}

export default styled(UserTraceUserUpdatedItem)`
  margin-top: 1px;
  margin-bottom: 1px;
`
