export enum WidgetCommonFormFieldName {
  instanceName = 'instanceName',

  dashboardId = 'dashboardId',
  dashboardWidgetId = 'dashboardWidgetId',

  type = 'type',
  title = 'title',

  // dataOptions
  dataOptionsType = 'dataOptionsType',
  dataOptionsDirectoryIds = 'dataOptionsDirectoryIds',
  dataOptionsDuration = 'dataOptionsDuration',
  dataOptionsInterval = 'dataOptionsInterval',
  dataOptionsUserStatus = 'dataOptionsUserStatus',
  dataOptionsCheckers = 'dataOptionsCheckers',

  // displayOptions
  displayOptionsLabel = 'displayOptionsLabel'
}

export enum WidgetCommonFormUserStatus {
  active = 'active',
  inactive = 'inactive',
  all = 'all'
}
