import { AssetImg } from '@app/components/Asset/AssetImg'
import { ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { LabelAlt } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled from 'styled-components'
import { LabelAltVariant } from '../Label/LabelAlt'

interface IIconNoDataProps {
  className?: string
  text?: React.ReactNode | string
  size?: number
  imageName?:
    | 'dashboard-placeholder'
    | 'healthCheck/all-passed'
    | 'placeholder-no-data'
    | 'reports-placeholder'
    | 'widgets-placeholder'
}

const IconNoData: React.FC<IIconNoDataProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Components.IconNoData'] })

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  return (
    <ContainerFlex
      name="IconNoData"
      className={props.className}
      direction="column"
      alignItems="center"
      justifyContent="center"
      flexGap="default"
      items={[
        <AssetImg
          imageName={
            themeIsLegacy || !props.imageName
              ? 'placeholder-no-data'
              : props.imageName
          }
          fileType="svg"
          width={props.size ?? themeIsLegacy ? 350 : 400}
        />,

        themeIsLegacy ? (
          <LabelAlt variant={LabelAltVariant.Minor}>
            <div>{props.text ?? translate('No data')}</div>
          </LabelAlt>
        ) : (
          <Label variant={LabelVariant.p}>
            <div>{props.text ?? translate('No data')}</div>
          </Label>
        )
      ]}
    />
  )
}

export default styled(IconNoData)`
  margin-bottom: 50px;
`
