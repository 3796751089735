import type { IIconProps } from '@app/components/Icon/types'
import { TenableButtonsColor } from '@app/styles/consts'
import * as React from 'react'

interface IIconBackgroundCircleProps extends IIconProps {}

const IconBackgroundCircle: React.FC<IIconBackgroundCircleProps> = props => {
  const fill = props.color ?? TenableButtonsColor.backgroundButtonFloatingHover
  const size = props.size ?? 40

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
    >
      <circle cx="16" cy="16" r="16" fill={fill} />
    </svg>
  )
}

export default IconBackgroundCircle
