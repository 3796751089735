import type { Maybe } from '@@types/helpers'
import { useStores } from '@app/hooks'
import type { IWindowSize } from '@app/hooks/useWindowSize'
import { consts } from '@app/styles'
import { multiply } from '@app/styles/helpers'
import { select, zoom } from 'd3'
import * as React from 'react'

/**
 * When the div ref is set, set the scene dimensions into the store.
 * It will trigger a component render with the `sceneIsReady` flag set to true,
 * allowing to render the scene.
 */
export function useSceneDimensions(
  windowSize: Maybe<IWindowSize>
): React.RefObject<HTMLDivElement> {
  const sceneDivRef = React.useRef<HTMLDivElement>(null)

  const { storeAttackPath } = useStores()

  /**
   * When the div ref is set, set the scene dimensions into the store.
   * It will trigger a component render with the `sceneIsReady` flag set to true,
   * allowing to render the scene.
   */
  React.useEffect(() => {
    if (!sceneDivRef.current) {
      return
    }

    const widthPadding = Number(multiply(consts.paddingLarge, 2, false))
    const heightPadding = Number(multiply(consts.paddingMedium, 2, false))

    storeAttackPath.setSceneDimensions({
      width:
        sceneDivRef.current.offsetWidth +
        (isNaN(widthPadding) ? 0 : widthPadding),
      height:
        sceneDivRef.current.offsetHeight +
        (isNaN(heightPadding) ? 0 : heightPadding)
    })
  }, [sceneDivRef, windowSize])

  return sceneDivRef
}

/**
 * Save the SVG ref at mount.
 */
export function useSceneSvgRefInit(): React.RefObject<SVGSVGElement> {
  const chartSVGRef = React.useRef<SVGSVGElement>(null)

  const { storeAttackPath } = useStores()

  React.useEffect(() => {
    if (!chartSVGRef.current) {
      return
    }

    storeAttackPath.setChartSvgRef(chartSVGRef.current)
  }, [])

  return chartSVGRef
}

/**
 * Add a listener on the zoom event to update text svg size.
 */
export function useSceneZoomBinding(
  chartSVGRef: React.RefObject<SVGSVGElement>
): void {
  const { storeAttackPath } = useStores()

  React.useEffect(() => {
    if (!chartSVGRef.current) {
      return
    }

    storeAttackPath.computeZoomValues()

    const zoomMin = storeAttackPath.zoomSliderMinValue
    const zoomMax = storeAttackPath.zoomSliderMaxValue
    const rootNode = storeAttackPath.rootNodeEntity
    const targetNodeEntity = storeAttackPath.nodes.find(
      node =>
        node.getPropertyAsNumber('id') === storeAttackPath.targetNode?.id &&
        node.getPropertyAsBoolean('isImportantNode') === true
    )

    if (!rootNode) {
      return
    }

    const selection = select(chartSVGRef.current)

    // on zoom event, save x, y, k
    const handleZoom = zoom<SVGSVGElement, any>()
      .on('zoom', event => {
        storeAttackPath.setChartZoomData(event.transform)
      })
      .scaleExtent([zoomMin, zoomMax])

    storeAttackPath.setHandleZoom(handleZoom)
    storeAttackPath.setZoomSelection(selection)
    selection.call(handleZoom)

    if (targetNodeEntity && targetNodeEntity.cell) {
      // If we are in the attack-path mode, then center the view at the middle
      // of the rendered graph

      handleZoom.translateTo(
        selection,
        rootNode.x + (targetNodeEntity.x - rootNode.x) / 2,
        rootNode.y + (targetNodeEntity.y - rootNode.y) / 2
      )

      // Force zoomSliderValue to the minimal zoomsliderMinValue to display full graph
      storeAttackPath.setZoomSliderValue(
        Math.min(storeAttackPath.zoomSliderMinValue, 1)
      )
    } else {
      if (storeAttackPath.expandedNodeUids.length === 0) {
        handleZoom.translateTo(selection, rootNode.x, rootNode.y)
        storeAttackPath.setZoomSliderValue(1)
      }
    }

    return () => {
      selection.on('zoom', null)
    }
  }, [
    chartSVGRef,
    storeAttackPath.rootNodeEntity,
    storeAttackPath.storeModalLongRender.isVisible,
    storeAttackPath.zoomSliderMinValue
  ])
}
