import { ContainerFlex } from '@app/components/Container'
import type { TContainerFlexSpaceWidth } from '@app/components/Container/ContainerFlex/types'
import type { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import * as React from 'react'
import ContainerFormField from './ContainerFormField'
import type { IField } from './types'

export interface IContainerFormProps {
  className?: string
  fields?: IField[]
  spaceWidth?: TContainerFlexSpaceWidth
  // if a version is defined, sort on field version
  version?: string
  variant?: ContainerFormVariant
}

const ContainerForm: React.FC<IContainerFormProps> = props => {
  const renderFields = () => {
    if (!props.fields) {
      return null
    }

    return props.fields
      .filter(field => {
        if (!props.version || !field.version) {
          return true
        }
        return props.version === field.version
      })
      .map(field => {
        return (
          <ContainerFormField
            key={field.label}
            variant={props.variant}
            field={field}
          />
        )
      })
  }

  const spaceWidth = props.spaceWidth || 'small'

  return (
    <ContainerFlex
      name="ContainerForm"
      className={props.className}
      direction="column"
      items={renderFields()}
      spaced
      spaceWidth={spaceWidth}
    />
  )
}

export default ContainerForm
