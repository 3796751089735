import { createEntities } from '@app/entities'
import EntityDashboard from '@app/entities/EntityDashboard'
import { AppRouteName } from '@app/routes'
import { createDashboardKey, explodeDashboardKey } from '@libs/dashboards/keys'
import type { DashboardKey } from '@libs/dashboards/types'
import type { InstanceName } from '@libs/Environment/types'
import { ForbiddenAccessError } from '@libs/errors'
import { isErrorOfType } from '@libs/errors/functions'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { ErrorName } from '@libs/errors/types'
import { If } from '@libs/fp-helpers/If'
import { isDefined } from '@libs/isDefined'
import { checkRbac } from '@libs/rbac/functions'
import type { QueryRbacDashboards } from '@server/graphql/queries/dashboard'
import { queryRbacDashboards } from '@server/graphql/queries/dashboard'
import type {
  Dashboard,
  Maybe,
  RbacDashboardsQueryArgs
} from '@server/graphql/typeDefs/types'
import { first, last } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '..'
import StoreFlags from '../helpers/StoreFlags'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'
import StoreDashboard from './StoreDashboard'
import StoreDashboardsManagement from './StoreDashboardsManagement'
import StoreWidget from './StoreWidget'

/**
 * Root store of the dashboards interface.
 *
 * Stores composition (non exhaustive)
 * ------------------
 *
 * - storeDashboards
 *
 *   - storeFlags
 *
 *   - storeDashboardsManagement
 *
 *     - storeFlags
 *     - storeDrawer
 *     - storeForm
 *
 *   - storeDashboard[]      // scoped by instanceName
 *
 *     - storeWidget[]
 *
 *       - storeFlags
 *       - storeInfrastructure
 *
 *   - storeWidgetsManagement
 *
 *     - storeFlags
 *     - storeDrawer
 *     - storeForm
 *     - storeSerie[]
 *
 *       - storeInputCheckersExposure
 *       - storeInfrastructures
 */
export default class StoreDashboards extends StoreBase {
  public storeFlagsAllDashboards = new StoreFlags(this.storeRoot)
  public storeFlagsOneDashboard = new StoreFlags(this.storeRoot)

  // store in charge of the creation/edition/deletion of dashboards
  public storeDashboardsManagement = new StoreDashboardsManagement(
    this.storeRoot,
    {
      instanceName: this.storeRoot.environment.getFirstInstanceName()
    }
  )

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Rbac', 'Dashboard.Common', 'Dashboard.AddDashboardDrawer']
  })

  /* Observables */

  // stores of dashboards (one store for each dashboard)
  private $storesDashboards = observable.map<DashboardKey, StoreDashboard>()

  // DashboardKey of the current selected dashboard
  private $selectedDashboard = observable.box<Maybe<DashboardKey>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch all dashboards (of all instances).
   */
  fetchDashboards(): Promise<void> {
    this.storeFlagsAllDashboards.loading()

    return Promise.resolve()
      .then(() => {
        const instanceNames = this.storeRoot.environment.config.instances.map(
          instance => instance.name
        )

        const profileId =
          this.storeRoot.stores.storeAuthentication.currentProfileId

        const args: RbacDashboardsQueryArgs = {
          profileId
        }

        return {
          instanceNames,
          args
        }
      })
      .then(({ instanceNames, args }) => {
        return this._fetchDashboardsForInstances(instanceNames, args)
      })
      .then(() => {
        // set the instance name of the first instances (for dashboard creation)
        this.storeDashboardsManagement.setOptions({
          instanceName: this.storeRoot.environment.getFirstInstanceName()
        })

        this.storeFlagsAllDashboards.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsAllDashboards))
  }

  /**
   * Fetch a dashboard.
   */
  fetchOneDashboard(
    instanceName: InstanceName,
    dashboardId: number
  ): Promise<void> {
    this.storeFlagsOneDashboard.loading()

    return Promise.resolve()
      .then(() => {
        const profileId =
          this.storeRoot.stores.storeAuthentication.currentProfileId

        const args: RbacDashboardsQueryArgs = {
          profileId,
          dashboardId
        }

        return {
          instanceNames: [instanceName],
          args
        }
      })
      .then(({ instanceNames, args }) => {
        return this._fetchDashboardsForInstances(instanceNames, args)
      })
      .then(() => {
        // set the instance name for dashboard creation
        this.storeDashboardsManagement.setOptions({
          instanceName
        })

        this.storeFlagsOneDashboard.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsOneDashboard))
  }

  /**
   * Handle the redirection to dashboards
   * (create first Or first dashboard Or current dashboard)
   *
   * Redirection is handled by the store itself in order to be reusable
   * in different contexts on the UI:
   *  - on the navigation menu when clicking on the Dashboard entry
   *  - when reloading the page with an url already on the dashboard
   *    (onLoad handler of the blade)
   */
  redirectToDashboards(): Promise<void> {
    this.reset()

    const { appRouter } = this.storeRoot
    const { storeBlades } = this.storeRoot.stores

    const parameters = appRouter.getRouteParameters({
      routeName: AppRouteName.Dashboard_Grid,
      parameters: {
        instanceName: String(),
        dashboardId: Number()
      }
    })

    if (parameters && isDefined(this.$selectedDashboard.get())) {
      // When coming back to /profile/tenable/dashboard/xx,yy (history back for example),
      // we are in a case where parameters are defined and dashboards stores have been cleared.
      // So fetching them again here if not already saved.
      return If(!this.$storesDashboards.size).fetch(() => {
        return this.fetchDashboards()
      })
    }

    return this.fetchDashboards().then(() => {
      // if a dashboardId has been found in the url, just select the dashboard
      // without any redirection
      if (parameters) {
        const dashboardKey = createDashboardKey(
          parameters.instanceName,
          parameters.dashboardId
        )

        this.selectDashboard(dashboardKey)

        const dashboardGridUrl = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Dashboard_Grid,
          parameters: {
            instanceName: parameters.instanceName,
            dashboardId: parameters.dashboardId
          }
        })

        storeBlades.updateLastBladeUrl(dashboardGridUrl)

        return
      }

      // if not, retrieve the first dashboardId and redirect to the first dashboard
      const firstDashboard =
        this.sortedDashboards.length > 0 && first(this.sortedDashboards)

      if (firstDashboard) {
        const dashboardKey = createDashboardKey(
          firstDashboard.getPropertyAsString('instanceName'),
          firstDashboard.getPropertyAsNumber('id')
        )

        this.selectDashboard(dashboardKey)

        const dashboardGridUrl = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Dashboard_Grid,
          parameters: {
            instanceName: firstDashboard.getPropertyAsString('instanceName'),
            dashboardId: firstDashboard.getPropertyAsNumber('id')
          }
        })

        storeBlades.updateLastBladeUrl(dashboardGridUrl)

        appRouter.history.replace(dashboardGridUrl)
      }
    })
  }

  /**
   * Return the last dashboard of the instanceName.
   */
  getLastDashboardOfInstance(
    instanceName: InstanceName
  ): Maybe<EntityDashboard> {
    return (
      last(
        this.sortedDashboards.filter(dashboard => {
          return dashboard.getPropertyAsString('instanceName') === instanceName
        })
      ) || null
    )
  }

  /* Private */

  /**
   * Fetch the dashboards,
   * Create and set widgets stores,
   * Create and set dashboards stores.
   */
  _fetchDashboardsForInstances(
    instanceNames: string[],
    args: RbacDashboardsQueryArgs
  ): Promise<void[]> {
    return Promise.all(
      instanceNames.map(instanceName => {
        return Promise.resolve()
          .then(() => {
            return this.storeRoot
              .getGQLRequestor(instanceName)
              .query<QueryRbacDashboards>(queryRbacDashboards, args)
          })
          .then(data => data.rbacDashboards)
          .then(dashboards => {
            if (!checkRbac(this.storeRoot)(dashboards)) {
              throw new ForbiddenAccessError()
            }

            // create stores for each widget and for each dashboard

            const dashboardEntities = createEntities<
              Dashboard,
              EntityDashboard
            >(EntityDashboard, dashboards.node)

            dashboardEntities.forEach(dashboardEntity => {
              const dashboardStore = new StoreDashboard(this.storeRoot, {
                instanceName,
                dashboardEntity
              })

              dashboardEntity.getWidgets().forEach(widgetEntity => {
                const widgetKey = widgetEntity.getWidgetKey()
                const widgetStore = new StoreWidget(this.storeRoot, {
                  instanceName,
                  widgetEntity
                })

                if (widgetKey) {
                  dashboardStore.setWidgetStore(widgetKey, widgetStore)

                  const widgetLayout = widgetEntity.getLayout()

                  if (!widgetLayout) {
                    this.storeRoot.logger.warn(
                      `No widgetLayout found for the widget ${widgetKey}`
                    )
                    return
                  }

                  dashboardStore.setWidgetLayout(widgetKey, widgetLayout)
                }
              })

              const dashboardKey = dashboardEntity.getDashboardKey()

              if (dashboardKey) {
                this.setDashboardStore(dashboardKey, dashboardStore)
              }
            })
          })
          .catch(
            handleStoreError(this.storeRoot, null, {
              errorMessageTranslationFn: err => {
                if (
                  isErrorOfType<ForbiddenAccessError>(
                    err,
                    ErrorName.ForbiddenAccessError
                  )
                ) {
                  return this.translate('You do not have access to this page')
                }

                return this.translate(
                  'An error occurred while loading the dashboards from the instance',
                  {
                    interpolations: {
                      instanceName
                    }
                  }
                )
              }
            })
          )
      })
    )
  }

  /* Actions */

  /**
   * Reset stores.
   */
  @action
  reset() {
    this.storeFlagsAllDashboards.reset()
    this.$storesDashboards.clear()
  }

  /**
   * Set a store for each dashboard.
   */
  @action
  setDashboardStore(
    dashboardKey: DashboardKey,
    dashboardStore: StoreDashboard
  ): void {
    this.$storesDashboards.set(dashboardKey, dashboardStore)
  }

  /**
   * Delete a dashboard store (when deleting a dashboard for example).
   */
  @action
  deleteDashboardStore(dashboardKey: DashboardKey): void {
    this.$storesDashboards.delete(dashboardKey)
  }

  /**
   * Set the currently selected dashboard.
   */
  @action
  selectDashboard(dashboardKey: DashboardKey): void {
    this.$selectedDashboard.set(dashboardKey)
  }

  /* Computed */

  /**
   * Return sorted dashboard's entities.
   */
  @computed
  get sortedDashboards(): EntityDashboard[] {
    const instanceNames = this.storeRoot.environment.config.instances.map(
      i => i.name
    )

    return Array.from(this.$storesDashboards.entries())
      .map(([, storeDashboard]) => storeDashboard.dashboardEntity)
      .sort((a, b) => {
        return (
          // order first according `instanceNames`
          instanceNames.indexOf(a.instanceName || '') -
            instanceNames.indexOf(b.instanceName || '') ||
          // then order by `order` property
          ((a.order || 1) < (b.order || 1) ? -1 : 1)
        )
      })
  }

  /**
   * Return the store of the current selected dashboard.
   */
  @computed
  get currentStoreDashboard(): Maybe<StoreDashboard> {
    const dashboardKey = this.$selectedDashboard.get()

    if (!dashboardKey) {
      return null
    }

    return this.$storesDashboards.get(dashboardKey) || null
  }

  /**
   * Return the current DashboardKey.
   */
  @computed
  get currentDashboardKey(): Maybe<DashboardKey> {
    const storeDashboard = this.currentStoreDashboard

    if (!storeDashboard) {
      return null
    }

    const dashboardEntity = storeDashboard.dashboardEntity
    return (dashboardEntity && dashboardEntity.getDashboardKey()) || null
  }

  /**
   * Return the current dashboardId.
   */
  @computed
  get currentDashboardId(): Maybe<number> {
    const dashboardKey = this.currentDashboardKey

    if (!dashboardKey) {
      return null
    }

    const dashboardKeyDetails = explodeDashboardKey(dashboardKey)

    if (!dashboardKeyDetails) {
      return null
    }

    return dashboardKeyDetails.dashboardId
  }
}
