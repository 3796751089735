/**
 * This method will replace in the template arg every occurence defined in the
 * values arg.
 *
 * Usable tags are <%= %> or <%- %>.
 *
 * If no interpolation value is sent, returning the template.
 */
export function interpolateTemplate(
  template: string,
  interpolationValues: object,
  // if null, a missing interpolation will raise an exception
  fallbackIsInterpolationMissing: string | null | undefined = '?'
): string {
  if (!interpolationValues) {
    return template
  }

  // Use of a Set to be TS compliant while handling unknown object
  const set = new Map<string, string>(Object.entries(interpolationValues))

  // Detect either <%= property %> and <%- property %>
  const regex = /<%[=-](.*?)%>/gm
  let result = template
  let regexResult

  while ((regexResult = regex.exec(template)) !== null) {
    const tag = regexResult[0]

    // Cleaning property as there will be often something like <%= property %>
    const property = regexResult[1].trim()
    const value = set.get(property)

    if (
      (fallbackIsInterpolationMissing === undefined ||
        fallbackIsInterpolationMissing === null) &&
      (value === undefined || value === null)
    ) {
      throw new Error(`Missing property ${property} when interpolating`)
    }

    // Replacing value or fallback to the property name
    result = result.replace(tag, value ?? fallbackIsInterpolationMissing ?? '')
  }

  return result
}
