import { LabelNonBreakable } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreInputReasons } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IReasonsSelectionCounterProps {
  storeInputReasons: StoreInputReasons
}

const ReasonsSelectionCounter: React.FC<
  IReasonsSelectionCounterProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.InputReasons']
  })

  return (
    <LabelNonBreakable>
      {translate('Reasons selected', {
        interpolations: {
          count: props.storeInputReasons.selectedReasonIds.length,
          allCount: props.storeInputReasons.reasonIds.length
        }
      })}
    </LabelNonBreakable>
  )
}

export default observer(ReasonsSelectionCounter)
