import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import {
  FormWrapperButton,
  FormWrapperSelect
} from '@app/components-legacy/Form'
import type { IFormWrapperSelectProps } from '@app/components-legacy/Form/Wrappers/Select'
import type { IComponentLoaderProps } from '@app/components-legacy/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { mergeFlags } from '@app/stores/helpers/StoreFlags/helpers'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import { ensureArray } from '@libs/ensureArray'
import { isDefined } from '@libs/isDefined'
import type { SelectValue } from 'antd/lib/select'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import type { IWidgetListBulkActionOption } from './types'

export interface IWidgetListBulkActionsProps {
  className?: string
  options: IWidgetListBulkActionOption[]
  flags?: IComponentLoaderProps['flags']
  onChange: (option: IWidgetListBulkActionOption) => void
  width?: IFormWrapperSelectProps<any>['width']
  storeWidgetList: StoreWidgetList<any, any>
}

const WidgetListBulkActions: React.FC<IWidgetListBulkActionsProps> = props => {
  const [selectedValue, setSelectValue] =
    React.useState<Maybe<SelectValue>>(null)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Components.WidgetList']
  })

  const onChange = (value: SelectValue) => {
    setSelectValue(value)
  }

  const onValidate = () => {
    const options = props.options.filter(o => o.value === selectedValue)

    if (options.length) {
      props.onChange(options[0])
    }
  }

  if (!props.options.length) {
    return null
  }

  const flags = mergeFlags(...ensureArray(props.flags))

  const isSelectDisabled = !props.storeWidgetList.isSomeRowsSelected
  const isSubmitDisabled = isSelectDisabled || !isDefined(selectedValue)

  return (
    <ContainerFlex
      name="WidgetListBulkActions"
      className={props.className}
      alignItems="center"
      items={[
        <FormWrapperSelect
          labelledBy="bulkActions"
          width={props.width || 'normal'}
          selectProps={{
            size: 'small',
            placeholder: translate('Action'),
            onChange,
            disabled: isSelectDisabled
          }}
          selectOptions={props.options}
        />,

        <FormWrapperButton
          labelledBy="submit"
          buttonProps={{
            size: 'small',
            onClick: onValidate,
            disabled: isSubmitDisabled,
            loading: flags.isLoading
          }}
        >
          {translate('OK')}
        </FormWrapperButton>
      ]}
      spaced
      spaceWidth="verySmall"
    />
  )
}

export default observer(WidgetListBulkActions)
