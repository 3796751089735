import { consts } from '@app/styles'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { Criticity } from '@server/graphql/typeDefs/types'
import { values } from 'lodash'
import { lighten } from 'polished'
import type { EntityGenericChecker } from '../app/entities/EntityGenericChecker/types'

export const CRITICITY_LEVEL_LOW = 0
export const CRITICITY_LEVEL_MEDIUM = 26
export const CRITICITY_LEVEL_HIGH = 51
export const CRITICITY_LEVEL_CRITICAL = 76

/**
 * Export order of criticities.
 */
export const CriticityValuesOrdered = [
  Criticity.Critical,
  Criticity.High,
  Criticity.Medium,
  Criticity.Low
]

export const getOrderedCriticityValuesStartingAt = (criticity: Criticity) => {
  return CriticityValuesOrdered.slice(CriticityValuesOrdered.indexOf(criticity))
}

/**
 * Return the criticity number value from the enum.
 */
export function getCriticityValue(criticity: Criticity): number {
  switch (criticity) {
    case Criticity.Medium:
      return CRITICITY_LEVEL_MEDIUM

    case Criticity.High:
      return CRITICITY_LEVEL_HIGH

    case Criticity.Critical:
      return CRITICITY_LEVEL_CRITICAL

    case Criticity.Low:
      return CRITICITY_LEVEL_LOW

    case Criticity.Unknown:
      return CRITICITY_LEVEL_LOW

    default:
      assertUnreachableCase(criticity)
  }
}

/**
 * Return the criticity string value from a number value.
 */
export function getCriticityString(criticityValue: number): Criticity {
  return criticityValue < CRITICITY_LEVEL_MEDIUM
    ? Criticity.Low
    : criticityValue >= CRITICITY_LEVEL_MEDIUM &&
        criticityValue <= CRITICITY_LEVEL_HIGH - 1
      ? Criticity.Medium
      : criticityValue >= CRITICITY_LEVEL_HIGH &&
          criticityValue <= CRITICITY_LEVEL_CRITICAL - 1
        ? Criticity.High
        : Criticity.Critical
}

/**
 * Return the color string value matching the criticity string.
 */
export function getCriticityColor(criticity: Criticity): string {
  switch (criticity) {
    case Criticity.Critical:
      return lighten(0.1, consts.colorRed001)

    case Criticity.High:
      return lighten(0.1, consts.colorOrange001)

    case Criticity.Medium:
      return lighten(0.1, consts.colorYellow001)

    case Criticity.Low:
      return lighten(0.1, consts.colorBlue005)

    case Criticity.Unknown:
      return lighten(0.1, consts.colorBlueGray005)

    default:
      assertUnreachableCase(criticity)
  }
}

/**
 * Sort generic checkers by criticities.
 * This function mutates checkerEntities.
 */
export function sortGenericCheckersByCriticityAndByName(
  checkerEntities: EntityGenericChecker[]
): void {
  const criticities = values(CriticityValuesOrdered)

  checkerEntities.sort((a, b) => {
    if (a.genericCriticity !== b.genericCriticity) {
      return (
        criticities.indexOf(a.genericCriticity) -
        criticities.indexOf(b.genericCriticity)
      )
    }

    return a.genericName.localeCompare(b.genericName)
  })
}
