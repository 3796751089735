import { CheckerCodeNameId } from '@app/entities/EntityGenericChecker/types'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  readCheckers,
  readLicenseFeatureIoE,
  readUIIoE
} from '@libs/rbac/permissions'

export const canSeeDangerousTrustRelationships = rbacCapabilityCheckAllOf(
  readUIIoE(),
  readLicenseFeatureIoE(),
  readCheckers(Number(CheckerCodeNameId.C_DANGEROUS_TRUST_RELATIONSHIP))
)
