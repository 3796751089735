import {
  LabelAlt,
  LabelAltError,
  LabelInput
} from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { consts } from '@app/styles'
import { ensureArray } from '@libs/ensureArray'
import { mdToHTML } from '@libs/mdToHTML'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex, ContainerHTMLContent } from '..'
import ContainerSimple from '../ContainerSimple'
import { formatErrorMessage } from './functions'
import type { IField } from './types'
import { ContainerFormVariant } from './types'

interface IContainerFormFieldProps {
  className?: string
  variant?: ContainerFormVariant
  field: IField
}

/**
 * @deprecated
 */
const ContainerFormField: React.FC<IContainerFormFieldProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.ContainerFormField']
  })

  const getFieldContent = () => {
    const items = [
      <ContainerSimple
        className="controlContainer"
        labelledBy={props.field.name}
      >
        {props.field.control}
      </ContainerSimple>
    ]

    const hideDecoration = props.field.hideDecoration === true

    if (hideDecoration) {
      return items
    }

    if (props.field.description) {
      items.push(
        <LabelAlt
          variant={LabelAltVariant.Minor}
          className="fieldSubLabel"
          labelledBy={`description-${props.field.name}`}
        >
          <ContainerHTMLContent>
            {mdToHTML(props.field.description)}
          </ContainerHTMLContent>
        </LabelAlt>
      )
    }

    if (props.field.errors) {
      const errorMessage = formatErrorMessage(translate)(props.field.errors)

      if (errorMessage) {
        items.push(
          <LabelAltError
            className="fieldSubLabel"
            labelledBy={`error-${props.field.name}`}
          >
            {errorMessage}
          </LabelAltError>
        )
      }
    }

    return items
  }

  const alignItems = props.field.labelAlignItem || 'baseline'

  return (
    <ContainerSimple ariaRoles={['form', 'row']} labelledBy={props.field.name}>
      <ContainerFlex
        key={props.field.label}
        name="Field"
        className={props.className}
        alignItems={alignItems}
        items={[
          props.field.label !== undefined && (
            <LabelInput
              highlight={props.field.highlight}
              required={props.field.required}
              htmlFor={props.field.inputId}
            >
              {props.field.label}
            </LabelInput>
          ),

          <ContainerFlex
            name="FieldContent"
            direction="column"
            items={getFieldContent()}
          />
        ]}
      />
    </ContainerSimple>
  )
}

function getControlWith(variant?: ContainerFormVariant) {
  switch (variant) {
    case ContainerFormVariant.drawer:
      return '300px'

    case ContainerFormVariant.fill:
      return '100%'

    case ContainerFormVariant.natural:
      return ''

    default:
      return '450px'
  }
}

function getInputWith(variant?: ContainerFormVariant) {
  return getControlWith(variant)
}

export default styled(ContainerFormField)`
  input {
    border-color: ${props =>
      ensureArray(props.field.errors).length ? consts.colorRed001 : null};
  }

  input[type='text'],
  input[type='number'],
  input[type='password'],
  .ant-select {
    width: ${props => getInputWith(props.variant)};
  }

  .controlContainer {
    width: ${props => getControlWith(props.variant)};
  }

  .fieldSubLabel {
    padding: ${consts.paddingVerySmall} 0;
    width: ${props => getInputWith(props.variant)};
  }
`
