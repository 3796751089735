import type { Mutation } from '../typeDefs/types'

/**
 * Create a role.
 */

export type MutationCreateRbacRole = Pick<Mutation, 'createRbacRole'>

export const mutationCreateRbacRole = `
  mutation mutationCreateRbacRole($role: InputCreateRbacRole!) {
    createRbacRole(
      role: $role
    ) {
      id
      name
      description
    }
  }
`

/**
 * Edit a role.
 */

export type MutationEditRbacRole = Pick<Mutation, 'editRbacRole'>

export const mutationEditRbacRole = `
  mutation mutationEditRbacRole($role: InputEditRbacRole!) {
    editRbacRole(
      role: $role
    ) {
      node {
        id
        name
        description
      }
      requestError {
        error {
          code
          title
          resource
          details {
            __typename
            ... on RequestErrorDetailsRbacMissingRelation {
              type
              permissions
            }
            ... on RequestErrorDetailsRbacMissingPermission{
              type
              relatedEntities
            }
          }
        }
        message
      }
    }
  }
`

/**
 * Delete a role.
 */

export type MutationDeleteRbacRole = Pick<Mutation, 'deleteRbacRole'>

export const mutationDeleteRbacRole = `
  mutation mutationDeleteRbacRole($role: InputDeleteRbacRole!) {
    deleteRbacRole(
      role: $role
    )
  }
`
