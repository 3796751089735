import type { StoreInputCrontab } from '@app/stores/helpers/StoreInputCrontab'
import { buildVariants } from '@design-system/libs/buildVariants'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from 'tenable-design-system-alt'
import Configuration from './Configuration'
import HumanReadableCron from './HumanReadableCron'
import PeriodSelector from './PeriodSelector'

export interface IInputCrontabProps {
  storeInputCrontab: StoreInputCrontab
}

interface IStyledContainerFlexProps {}

const StyledContainerFlex = styled(ContainerFlex)<IStyledContainerFlexProps>(
  props => {
    return buildVariants(props)
      .css({
        color: props.theme.tokens['layout/color']
      })
      .end()
  }
)

function InputCrontab(props: IInputCrontabProps) {
  return (
    <StyledContainerFlex
      name="InputCrontab"
      flexDirection="column"
      flexGap="spacingM"
    >
      <PeriodSelector storeInputCrontab={props.storeInputCrontab} />
      <Configuration storeInputCrontab={props.storeInputCrontab} />
      <HumanReadableCron
        crontab={props.storeInputCrontab.crontab}
        type={'cronSummary'}
      />
    </StyledContainerFlex>
  )
}

export default observer(InputCrontab)
