import type { IBladeHeaderInfo } from '@app/components-legacy/Blade/BladeHeader'
import BladeHeader from '@app/components-legacy/Blade/BladeHeader'
import {
  LabelCompliance,
  LabelCriticity,
  LabelDate
} from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { filterFalsies } from '@libs/filterFalsies'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckerDetailsPageContext } from './context'
import { canReadIoEComplianceStatus } from './permissions'

export interface IDetailsBladeHeaderProps {}

const DetailsBladeHeader: React.FC<IDetailsBladeHeaderProps> = () => {
  const parameters = React.useContext(CheckerDetailsPageContext)

  const translate = useAppTranslator({ namespaces: ['Buttons', 'IoE.Details'] })

  const { storeRbac, storeIoE } = useStores()

  if (!parameters) {
    return null
  }

  const getInfos = (): IBladeHeaderInfo[] => {
    const checker = storeIoE.storeIndicator.checkerExposure

    if (!checker) {
      return []
    }

    const latestUpdateDate = checker.getLatestUpdateDate() ? (
      <LabelDate date={checker.getLatestUpdateDate()} />
    ) : (
      translate('No latest detection')
    )

    return filterFalsies([
      {
        rawType: 'Name',
        type: translate('Name'),
        value: checker.name
      },
      {
        rawType: 'Criticity',
        type: translate('Criticity'),
        value: <LabelCriticity criticity={checker.genericCriticity} />
      },
      storeRbac.isUserGrantedTo(canReadIoEComplianceStatus) && {
        rawType: 'Status',
        type: translate('Status'),
        value: (
          <LabelCompliance
            enabled={checker.enabled}
            compliant={checker.getComplianceStatus()}
          />
        )
      },
      {
        rawType: 'Latest detection',
        type: translate('Latest detection'),
        value: latestUpdateDate
      }
    ])
  }

  return <BladeHeader icon="ioe" infos={getInfos()} />
}

export default observer(DetailsBladeHeader)
