import { Blade } from '@app/components-legacy/Blade'
import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { AppRouteName } from '@app/routes'
import { grant } from '@libs/rbac/permissions'
import * as React from 'react'
import Header from './Header'
import Register from './Register'
import Table from './Table'

interface IBladeProps {}

const APIInspectorBlade: React.FC<IBladeProps> = props => {
  return (
    <Blade
      root={true}
      routeDefinition={{
        routeName: AppRouteName.Debug_APIInspector,
        parameters: {}
      }}
      layout={{
        name: 'default',
        content: (
          <ContainerContent title="List of events">
            <ContainerFlex
              name="MainContainer"
              direction="column"
              itemsFlexGrow={[0, 1]}
              items={[
                <Header />,
                <Register>
                  <Table />
                </Register>
              ]}
            />
          </ContainerContent>
        )
      }}
      rbacCapabilityCheck={grant()}
    />
  )
}

export default APIInspectorBlade
