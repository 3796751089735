import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { ensureArray } from '@libs/ensureArray'
import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'
import type { ARIARole } from 'aria-query'
import * as React from 'react'

export interface IContainerSimpleProps {
  name?: string
  className?: string
  style?: React.CSSProperties
  ariaRoles?: ARIARole | ARIARole[]
  labelledBy?: string
  inline?: boolean
  containerProps?: React.HTMLAttributes<HTMLSpanElement | HTMLDivElement>
  children?: React.ReactNode
}

/**
 * Simple container that exposes the basics.
 */
const ContainerSimple: React.FC<IContainerSimpleProps> = props => {
  const ariaRoles = ensureArray(props.ariaRoles)

  const [mainRole, ...otherRoles] = (ariaRoles.length && ariaRoles) || [
    'contentinfo'
  ]

  const { testAttribute } = useTestAttribute(mainRole)

  const intrinsicProps = filterEmptyProps(
    {
      ['data-name']: props.name,
      ['data-test']:
        props.labelledBy && testAttribute(otherRoles)(props.labelledBy),
      ...props
    },
    {
      pickProps: ['data-name', 'data-test', 'className', 'style']
    }
  )

  if (props.inline) {
    return (
      <span {...intrinsicProps} {...props.containerProps}>
        {props.children}
      </span>
    )
  }

  return (
    <div {...intrinsicProps} {...props.containerProps}>
      {props.children}
    </div>
  )
}

export default ContainerSimple
