import { StoreInputTenants } from '@app/stores'
import * as React from 'react'

/**
 * Initialize the drawer tenants.
 */
export function useDrawerInputTenantsInit(
  storeInputTenants: StoreInputTenants
) {
  // create a new memoized storeInputTenants for pending selection
  const storePendingTenants = React.useMemo(() => {
    return new StoreInputTenants(
      storeInputTenants.storeRoot,
      storeInputTenants.options
    )
  }, [])

  // when the drawer is opened, fetch the tenants
  // and select the same tenants that the storeTenants passed as param.
  React.useEffect(() => {
    if (!storeInputTenants.storeDrawer.isDrawerOpened) {
      return
    }

    Promise.resolve()
      .then(() => {
        // fetch only if no tenants
        if (storePendingTenants.tenants.size) {
          return Promise.resolve()
        }
        return storePendingTenants.fetchTenants()
      })
      .then(() => {
        if (!storeInputTenants.selectedTenants.size) {
          storePendingTenants.selectAllTenants()
          return
        }
        storePendingTenants.selectTenants(storeInputTenants.selectedTenantIds)
      })
  }, [storeInputTenants.storeDrawer.isDrawerOpened])

  return storePendingTenants
}
