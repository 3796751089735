import type { PropertiesNullable } from '@@types/helpers'
import type { IEntityListable } from '@app/entities/types'
import { MAX_CHILDREN_BEFORE_HIDING_THEM } from '@app/stores/AttackPath/consts'
import type {
  IDataRowGeneric,
  IWidgetListColumns
} from '@app/stores/helpers/StoreWidgetList/types'
import type {
  AttackPathAdObjectType,
  AttackPathNode,
  Maybe
} from '@server/graphql/typeDefs/types'
import EntityAttackPathBaseNode from './EntityAttackPathBaseNode'

export default class EntityAttackPathNode
  extends EntityAttackPathBaseNode
  implements
    PropertiesNullable<AttackPathNode>,
    IEntityListable<IDataRowGeneric>
{
  id: Maybe<number> = null
  name: Maybe<string> = null
  adObjectType: Maybe<AttackPathAdObjectType> = null
  nextDepthNodeCount?: Maybe<number>
  directoryId: Maybe<number> = null

  childrenCount: number = 0

  constructor(data: Partial<EntityAttackPathNode>) {
    super()
    Object.assign(this, data)
  }

  setChildrenCount(count: number): this {
    this.childrenCount = count
    return this
  }

  get hasTooManyChildren(): boolean {
    return this.childrenCount > MAX_CHILDREN_BEFORE_HIDING_THEM
  }

  hasNodeInHierarchy(nodeUID: string): boolean {
    if (!this.linkedNodeEntity) {
      return false
    }

    if (this.linkedNodeEntity.uid === nodeUID) {
      return true
    }

    return this.linkedNodeEntity.hasNodeInHierarchy(nodeUID)
  }

  /**
   * Not used, columns are hardcoded in the view with a custom render.
   */
  getColumns(): Array<IWidgetListColumns<IDataRowGeneric>> {
    return []
  }
}
