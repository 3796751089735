import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import {
  canSelectIoA,
  canSelectIoE
} from '@app/components-legacy/Input/InputCheckers/permissions'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerContent from './DrawerContent'
import DrawerHeader from './DrawerHeader'
import { onDrawerInputCheckersValidation } from './handlers'
import { useDrawerInputCheckersInit } from './hooks'

interface IInputCheckersDrawerProps<GC extends TGenericChecker> {
  onValidate?: () => void
  storeInputGenericCheckers: StoreInputGenericCheckers<GC>
}

function DrawerInputCheckers<GC extends TGenericChecker>(
  props: IInputCheckersDrawerProps<GC>
): React.ReactElement {
  const storePendingInputCheckers = useDrawerInputCheckersInit(
    props.storeInputGenericCheckers
  )

  const translate = useAppTranslator({
    namespaces: ['Components.InputCheckers']
  })

  const isIoE =
    props.storeInputGenericCheckers.options.checkerType === CheckerType.Exposure

  const title = isIoE
    ? translate('Indicators of Exposure')
    : translate('Indicators of Attack')

  const rbacCheck = isIoE ? canSelectIoE : canSelectIoA

  return (
    <DrawerHorizontalContainer
      labelledBy="inputCheckers"
      flags={storePendingInputCheckers.storeFlagsFetchCheckers.flags}
    >
      <BladeContentLayoutDefault
        layout={{
          name: 'default',
          title: <DrawerTitle>{title}</DrawerTitle>,
          header: (
            <DrawerHeader
              storePendingInputCheckers={storePendingInputCheckers}
            />
          ),
          content: (
            <DrawerContent
              onValidate={onDrawerInputCheckersValidation(
                props.storeInputGenericCheckers,
                storePendingInputCheckers
              )(props.onValidate)}
              storeInputGenericCheckers={props.storeInputGenericCheckers}
              storePendingInputGenericCheckers={storePendingInputCheckers}
            />
          ),
          footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
        }}
        rbacCapabilityCheck={rbacCheck}
      />
    </DrawerHorizontalContainer>
  )
}

export default observer(DrawerInputCheckers)
