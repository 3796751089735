import type { StorePreferences, StoreAuthentication } from '@app/stores'
import type { SelectValue } from 'antd/lib/select'
import { FieldName } from './types'

/**
 * Save the new preferences.
 */
export const onProfileSubmit =
  (
    storePreferences: StorePreferences,
    storeAuthentication: StoreAuthentication
  ) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const preferredProfileName =
      storePreferences.storeFormPreferredProfile.getFieldValueAsString(
        FieldName.preferredProfileName
      )

    const profileFound = Array.from(storeAuthentication.profiles.values())
      .filter(profile => {
        return profile.getPropertyAsString('name') === preferredProfileName
      })
      .pop()

    if (!profileFound) {
      return
    }

    const preferences = {
      preferredProfileId: profileFound.getPropertyAsNumber('id')
    }

    storePreferences
      .updatePreferences(preferences)
      .then(() => storePreferences.fetchPreferences())
      .catch(() => {
        // already catched in the store
      })
  }

/**
 * Set the preferences selection.
 */
export const onProfileChange =
  (storePreferences: StorePreferences) => (value: SelectValue) => {
    storePreferences.storeFormPreferredProfile.setFieldValue(
      FieldName.preferredProfileName,
      String(value)
    )
  }
