import type { Maybe } from '@@types/helpers'
import type { WidgetListColumnSize } from '@app/components/Widgets/WidgetList/WidgetListTable/types'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

export interface ITableHeadCellProps {
  className?: string
  style?: React.CSSProperties
  width?: Maybe<number | string | WidgetListColumnSize>
  children?: React.ReactNode
}

const TableHeadCell: React.FC<ITableHeadCellProps> = props => {
  return (
    <th className={props.className} style={props.style}>
      {props.children}
    </th>
  )
}

function getWidth(width: ITableHeadCellProps['width']): string {
  if (!width) {
    return 'auto'
  }

  switch (width) {
    case 'ID':
      return `70px`
  }

  if (typeof width === 'number') {
    return `${width}%`
  }

  return width
}

export default styled(TableHeadCell)`
  font-weight: normal;
  padding-right: ${consts.paddingLarge};
  width: ${props => getWidth(props.width)};
  text-align: left;
  color: ${consts.colorWhite};
  background-color: ${consts.BackgroundColor.more2};
  border-top: 2px solid ${consts.BackgroundColor.more2};
  border-bottom: 2px solid ${consts.BackgroundColor.more2};
  padding-top: 6px;
  padding-bottom: 6px;

  &:first-child {
    border-left: 10px solid ${consts.BackgroundColor.more2};
    padding-left: ${consts.paddingLarge};
  }

  &:last-child {
    border-right: 10px solid ${consts.BackgroundColor.more2};
  }
`
