import { LabelSliced } from '@app/components-legacy/Label'
import type { EntityEvent } from '@app/entities'
import * as React from 'react'
import TableContentBodyCell from '../TableContentBodyCell'
import CellEmpty from './CellEmpty'

export interface ICellSourceProps {
  event?: EntityEvent
}

const CellSource: React.FC<ICellSourceProps> = props => {
  const getCellContent = () => {
    if (!props.event) {
      return <CellEmpty />
    }

    const adObject = props.event.getAdObject()

    if (!adObject) {
      return <CellEmpty />
    }

    return (
      <LabelSliced
        popoverType="none"
        value={adObject.getPropertyAsString('type', '-')}
        labelledBy="Source"
        maxLength={30}
      />
    )
  }

  return (
    <div className="eventSource">
      <TableContentBodyCell>{getCellContent()}</TableContentBodyCell>
    </div>
  )
}

export default CellSource
