import type { MaybeUndef } from '@@types/helpers'
import { useStores } from '@app/hooks/useStores'
import { Language } from '@server/graphql/typeDefs/types'
import { ConfigProvider } from 'antd'
import type { Locale } from 'antd/lib/locale-provider'
import ar_EG from 'antd/lib/locale-provider/ar_EG'
import az_AZ from 'antd/lib/locale-provider/az_AZ'
import bg_BG from 'antd/lib/locale-provider/bg_BG'
import ca_ES from 'antd/lib/locale-provider/ca_ES'
import cs_CZ from 'antd/lib/locale-provider/cs_CZ'
import da_DK from 'antd/lib/locale-provider/da_DK'
import de_DE from 'antd/lib/locale-provider/de_DE'
import el_GR from 'antd/lib/locale-provider/el_GR'
import en_GB from 'antd/lib/locale-provider/en_GB'
import en_US from 'antd/lib/locale-provider/en_US'
import es_ES from 'antd/lib/locale-provider/es_ES'
import et_EE from 'antd/lib/locale-provider/et_EE'
import fa_IR from 'antd/lib/locale-provider/fa_IR'
import fi_FI from 'antd/lib/locale-provider/fi_FI'
import fr_FR from 'antd/lib/locale-provider/fr_FR'
import ga_IE from 'antd/lib/locale-provider/ga_IE'
import gl_ES from 'antd/lib/locale-provider/gl_ES'
import he_IL from 'antd/lib/locale-provider/he_IL'
import hi_IN from 'antd/lib/locale-provider/hi_IN'
import hr_HR from 'antd/lib/locale-provider/hr_HR'
import hu_HU from 'antd/lib/locale-provider/hu_HU'
import hy_AM from 'antd/lib/locale-provider/hy_AM'
import id_ID from 'antd/lib/locale-provider/id_ID'
import is_IS from 'antd/lib/locale-provider/is_IS'
import it_IT from 'antd/lib/locale-provider/it_IT'
import ja_JP from 'antd/lib/locale-provider/ja_JP'
import kk_KZ from 'antd/lib/locale-provider/kk_KZ'
import kmr_IQ from 'antd/lib/locale-provider/kmr_IQ'
import kn_IN from 'antd/lib/locale-provider/kn_IN'
import ko_KR from 'antd/lib/locale-provider/ko_KR'
import ku_IQ from 'antd/lib/locale-provider/ku_IQ'
import lt_LT from 'antd/lib/locale-provider/lt_LT'
import lv_LV from 'antd/lib/locale-provider/lv_LV'
import mk_MK from 'antd/lib/locale-provider/mk_MK'
import mn_MN from 'antd/lib/locale-provider/mn_MN'
import ms_MY from 'antd/lib/locale-provider/ms_MY'
import nb_NO from 'antd/lib/locale-provider/nb_NO'
import ne_NP from 'antd/lib/locale-provider/ne_NP'
import nl_BE from 'antd/lib/locale-provider/nl_BE'
import nl_NL from 'antd/lib/locale-provider/nl_NL'
import pl_PL from 'antd/lib/locale-provider/pl_PL'
import pt_BR from 'antd/lib/locale-provider/pt_BR'
import pt_PT from 'antd/lib/locale-provider/pt_PT'
import ro_RO from 'antd/lib/locale-provider/ro_RO'
import ru_RU from 'antd/lib/locale-provider/ru_RU'
import sk_SK from 'antd/lib/locale-provider/sk_SK'
import sl_SI from 'antd/lib/locale-provider/sl_SI'
import sr_RS from 'antd/lib/locale-provider/sr_RS'
import sv_SE from 'antd/lib/locale-provider/sv_SE'
import ta_IN from 'antd/lib/locale-provider/ta_IN'
import th_TH from 'antd/lib/locale-provider/th_TH'
import tr_TR from 'antd/lib/locale-provider/tr_TR'
import uk_UA from 'antd/lib/locale-provider/uk_UA'
import vi_VN from 'antd/lib/locale-provider/vi_VN'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import zh_TW from 'antd/lib/locale-provider/zh_TW'
import * as React from 'react'

interface IAntdConfigProviderProps {
  children?: React.ReactNode
}

export function getAntdLocale(locale: Language): MaybeUndef<Locale> {
  const mapping: Map<Language, Locale> = new Map([
    [Language.ArEg, ar_EG],
    [Language.AzAzCyrl, az_AZ],
    [Language.AzAzLatn, az_AZ],
    [Language.BgBg, bg_BG],
    [Language.CaEs, ca_ES],
    [Language.CsCz, cs_CZ],
    [Language.DaDk, da_DK],
    [Language.DeDe, de_DE],
    [Language.ElGr, el_GR],
    [Language.EnUs, en_US],
    [Language.EnJm, en_US],
    [Language.EnGb, en_GB],
    [Language.EsEs, es_ES],
    [Language.EtEe, et_EE],
    [Language.FaIr, fa_IR],
    [Language.FiFi, fi_FI],
    [Language.FrFr, fr_FR],
    [Language.GaIe, ga_IE],
    [Language.GlEs, gl_ES],
    [Language.HeIl, he_IL],
    [Language.HiIn, hi_IN],
    [Language.HrHr, hr_HR],
    [Language.HuHu, hu_HU],
    [Language.HyAm, hy_AM],
    [Language.IdId, id_ID],
    [Language.IsIs, is_IS],
    [Language.ItIt, it_IT],
    [Language.JaJp, ja_JP],
    [Language.KkKz, kk_KZ],
    [Language.Kmr, kmr_IQ],
    [Language.KnIn, kn_IN],
    [Language.KoKr, ko_KR],
    [Language.KuIq, ku_IQ],
    [Language.LtLt, lt_LT],
    [Language.LvLv, lv_LV],
    [Language.MkMk, mk_MK],
    [Language.MnMn, mn_MN],
    [Language.MsMy, ms_MY],
    [Language.NbNo, nb_NO],
    [Language.NeNp, ne_NP],
    [Language.NlBe, nl_BE],
    [Language.NlNl, nl_NL],
    [Language.PlPl, pl_PL],
    [Language.PtBr, pt_BR],
    [Language.PtPt, pt_PT],
    [Language.RoRo, ro_RO],
    [Language.RuRu, ru_RU],
    [Language.SkSk, sk_SK],
    [Language.SlSi, sl_SI],
    [Language.SrRsCyrl, sr_RS],
    [Language.SrRsLatn, sr_RS],
    [Language.SvSe, sv_SE],
    [Language.TaIn, ta_IN],
    [Language.ThTh, th_TH],
    [Language.TrTr, tr_TR],
    [Language.UkUa, uk_UA],
    [Language.ViVn, vi_VN],
    [Language.ZhCn, zh_CN],
    [Language.Tw, zh_TW]
  ])

  return mapping.get(locale) ?? en_US
}

const AntdConfigProvider: React.FC<IAntdConfigProviderProps> = props => {
  const { storeRoot } = useStores()

  const antdLocale = getAntdLocale(
    storeRoot.environment.translator.getFirstPreferredLanguage()
  )

  return <ConfigProvider locale={antdLocale}>{props.children}</ConfigProvider>
}

export default AntdConfigProvider
