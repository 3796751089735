import type { MaybeUndef } from '@@types/helpers'
import type { StoreTrailFlow } from '@app/stores'
import type StoreSearchHistory from '@app/stores/TrailFlow/StoreSearchHistory'
import type { SearchHistoryQueryArgs } from '@server/graphql/typeDefs/types'

/**
 * When clicking on an expression from the search history, select this expression.
 */
export const onTrailFlowSearchHistoryTimelineSelection =
  (storeTrailFlow: StoreTrailFlow) => (value: string) => () => {
    storeTrailFlow.storeInputExpression
      .clearEntry()
      .setEntry(value)
      .validateEntry()
    storeTrailFlow.storeSearchHistory.storeSearchHistoryTimelineDrawer.closeDrawer()
    storeTrailFlow.reloadData()
  }

/**
 * Reset storeSearchHistory when leaving the drawer.
 */
export const onTrailFlowSearchHistoryTimelineUnload =
  (storeSearchHistory: StoreSearchHistory) => () => {
    storeSearchHistory.reset()
  }

/**
 * Clear search history.
 */
export const onTrailFlowSearchHistoryTimelineClear =
  (storeSearchHistory: StoreSearchHistory) => () => {
    storeSearchHistory.storeModalConfirmDeleteHistory.show()
  }

/**
 * Close search history timeline drawer.
 */
export const onTrailFlowSearchHistoryTimelineCancel =
  (storeSearchHistory: StoreSearchHistory) => () => {
    storeSearchHistory.storeSearchHistoryTimelineDrawer.closeDrawer()
  }

/**
 * On date change, fetch history with date range, without forgetting expressionSearch param (if filled).
 */
export const onInputDatePickerChange =
  (storeSearchHistory: StoreSearchHistory) => () => {
    const dateRange =
      storeSearchHistory.storeDatePicker.datePickerRangeValueAsIsoStrings

    const customArgs = storeSearchHistory.storeInputSearch.hasSearchValue
      ? {
          expressionSearch: storeSearchHistory.storeInputSearch.searchValue,
          ...dateRange
        }
      : dateRange

    storeSearchHistory.fetchSearchHistoryEntries({
      ...customArgs
    })
  }

/**
 * Fetch new page with all necessary params.
 */
export const onSearchHistoryPageChange =
  (storeSearchHistory: StoreSearchHistory) =>
  (page: number, pageSize: MaybeUndef<number>) => {
    const dateRange =
      storeSearchHistory.storeDatePicker.datePickerRangeValueAsIsoStrings

    const customArgs = storeSearchHistory.storeInputSearch.hasSearchValue
      ? {
          expressionSearch: storeSearchHistory.storeInputSearch.searchValue,
          ...dateRange
        }
      : {
          ...dateRange
        }

    storeSearchHistory.fetchSearchHistoryEntries({
      searchHistoryEntriesPage: page,
      searchHistoryEntriesPerPage: pageSize,
      ...customArgs
    })
  }

/**
 * On input search submit click, fetch history with the value provided by the user.
 */
export const onInputSearchSubmit =
  (storeSearchHistory: StoreSearchHistory) => () => {
    const dateRange =
      storeSearchHistory.storeDatePicker.datePickerRangeValueAsIsoStrings

    const customArgs: SearchHistoryQueryArgs = {
      expressionSearch: storeSearchHistory.storeInputSearch.searchValue,
      ...dateRange
    }

    storeSearchHistory.fetchSearchHistoryEntries(customArgs)
  }

/**
 * On input search reset, fetch history.
 */
export const onInputSearchReset =
  (storeSearchHistory: StoreSearchHistory) => () => {
    const dateRange =
      storeSearchHistory.storeDatePicker.datePickerRangeValueAsIsoStrings

    storeSearchHistory.fetchSearchHistoryEntries({
      ...dateRange
    })
  }

/**
 * Confirm the delete of search history.
 */
export const onDeleteModalConfirmClick =
  (storeSearchHistory: StoreSearchHistory) => () => {
    const hasSearchValue =
      storeSearchHistory.storeInputSearch.hasSearchValue &&
      !storeSearchHistory.storeInputSearch.isDirty

    const dateRange =
      storeSearchHistory.storeDatePicker.datePickerRangeValueAsIsoStrings

    if (hasSearchValue || dateRange) {
      const customArgs = hasSearchValue
        ? {
            expressionSearch: storeSearchHistory.storeInputSearch.searchValue,
            ...dateRange
          }
        : {
            ...dateRange
          }

      storeSearchHistory.deleteSearchHistoryEntriesByQuery(customArgs)

      storeSearchHistory.storeModalConfirmDeleteHistory.hide()
      return
    }

    storeSearchHistory.deleteSearchHistoryEntries()
    storeSearchHistory.storeModalConfirmDeleteHistory.hide()
  }

/**
 * Cancel the delete and close the modal.
 */
export const onCancelModalConfirmClick =
  (storeSearchHistory: StoreSearchHistory) => (): void => {
    storeSearchHistory.storeModalConfirmDeleteHistory.hide()
  }
