import { useStores } from '@app/hooks'
import {
  GRID_COLS,
  GRID_ROW_HEIGHT,
  GRID_WIDTH
} from '@app/stores/Dashboard/consts'
import type StoreDashboard from '@app/stores/Dashboard/StoreDashboard'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { isDefined } from '@libs/isDefined'
import * as React from 'react'
import * as GridLayout from 'react-grid-layout'
import styled, { css } from 'styled-components'
import { canEditDashboardWidget } from '../permissions'
import GridWidget from './GridWidget'
import {
  onDashboardGridDragStop,
  onDashboardGridLayoutChange,
  onDashboardGridResizeStop
} from './handlers'

interface IGridProps {
  storeDashboard: StoreDashboard
}

const Div = styled.div`
  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      .react-resizable-handle {
        background-image: none;
      }

      &.react-grid-item > .react-resizable-handle::after {
        right: 10px;
        bottom: 10px;
        width: 9px;
        height: 9px;
        border-right: 1px solid
          ${({ theme }) => theme.tokens['dashboardWidget/color/resizeArrow']};
        border-bottom: 1px solid
          ${({ theme }) => theme.tokens['dashboardWidget/color/resizeArrow']};
      }
    `}
`

const Grid: React.FC<IGridProps> = props => {
  const { storeDashboards, storeRbac } = useStores()

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  // if not granted on the widget edition, prevent the resize and the move of widgets
  const canModifyWidgetPosition = storeRbac.isUserGrantedTo(
    canEditDashboardWidget
  )

  return (
    <GridLayout
      className="layout"
      containerPadding={themeIsLegacy ? [0, 0] : [0, 15]}
      margin={[20, 20]}
      cols={GRID_COLS}
      width={GRID_WIDTH}
      rowHeight={GRID_ROW_HEIGHT}
      layout={props.storeDashboard.widgetLayoutsList}
      isDraggable={canModifyWidgetPosition}
      isResizable={canModifyWidgetPosition}
      onDragStop={onDashboardGridDragStop(props.storeDashboard)}
      onResizeStop={onDashboardGridResizeStop(props.storeDashboard)}
      onLayoutChange={onDashboardGridLayoutChange(storeDashboards)}
      compactType={null}
    >
      {props.storeDashboard.storeWidgetsList
        .map(widgetStore => {
          const widgetEntity = widgetStore.widgetEntity

          if (!widgetEntity) {
            return
          }

          const widgetKey = widgetEntity.getWidgetKey()

          if (!widgetKey) {
            return
          }

          const widgetTitle = widgetEntity.title
          const widgetOptions = widgetEntity.getOptions()

          if (!widgetTitle || !widgetOptions) {
            // Return an "empty" node to enter in onLayoutChange handler,
            // that will set bounds to widget, triggering an another render
            // with the widgets correctly positioned.
            return <div key={widgetKey} />
          }

          const dashboardId = props.storeDashboard.dashboardEntity.id
          const dashboardWidget = widgetEntity.parent

          const preLoad = Boolean(
            dashboardWidget &&
              dashboardWidget.getPropertyAsNumber('id') === dashboardId
          )

          return (
            <Div
              data-name="GridWidget"
              data-widget-key={widgetKey}
              key={widgetKey}
            >
              <GridWidget
                preLoad={preLoad}
                widgetKey={widgetKey}
                title={widgetTitle}
                options={widgetOptions}
                storeDashboard={props.storeDashboard}
                storeWidget={widgetStore}
              />
            </Div>
          )
        })
        .filter(isDefined)}
    </GridLayout>
  )
}

export default Grid
