import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface ITableProps {
  className?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  children?: React.ReactNode
}

/**
 * @deprecated This is only used in the abandonned plugin feature
 * and should be deleted.
 * @wip This component is currently migrating from component-legacy.
 */
const Table: React.FC<ITableProps> = props => {
  return (
    <div
      className={props.className}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <table>{props.children}</table>
    </div>
  )
}

export default styled(Table)`
  position: relative;

  table {
    width: 100%;
    background-color: ${consts.BackgroundColor.secondary};
    border-radius: 5px;
    overflow: hidden;
  }
`
