import type { Detail, ISddlTree } from './types'

export function isSDDLTree(arg: Detail): arg is ISddlTree {
  if (!arg) {
    return false
  }

  if (typeof arg === 'string') {
    return false
  }

  return typeof arg.details !== 'string'
}
