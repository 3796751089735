import {
  TableHeadCell,
  TableHeadCheckbox,
  TableRow
} from '@app/components-legacy/Table'
import type { IEntityListable } from '@app/entities/types'
import type { IDataRowGeneric } from '@app/stores/helpers/StoreWidgetList/types'
import { filterColumn } from '@app/stores/helpers/StoreWidgetList/utils'
import { isDefined } from '@libs/isDefined'
import * as React from 'react'
import { getDataSet } from './functions'
import type { IWidgetListTableCommonProps } from './types'
import WidgetTableTHeadCell from './WidgetTableTHeadCell'

interface IWidgetTableTHeadProps<
  E extends IEntityListable<IDataRowGeneric>,
  D extends IDataRowGeneric
> extends IWidgetListTableCommonProps<E, D> {}

/**
 * Render the row of the THead.
 */
export function WidgetTableTHead<E extends IEntityListable<IDataRowGeneric>, D>(
  props: IWidgetTableTHeadProps<E, D>
): React.ReactElement {
  const cells = []

  if (props.storeWidgetList && props.storeWidgetList.options.selectable) {
    cells.push(<TableHeadCheckbox key="checkbox" />)
  }

  const dataSet = getDataSet(props.storeWidgetList)

  cells.push(
    ...dataSet.columns
      .filter(column => filterColumn<D>(column.key, props.hiddenColumnsKeys))
      // retrieve the width of the column if defined
      .map(column => {
        if (!props.columnWidths || !(column.key in props.columnWidths)) {
          return { column, width: null }
        }

        const width = props.columnWidths[column.key]

        return { column, width }
      })
      .map(({ column, width }) => {
        return (
          <TableHeadCell key={String(column.key)} width={width}>
            <WidgetTableTHeadCell<E, D>
              column={column}
              rows={dataSet.rows}
              {...props}
            />
          </TableHeadCell>
        )
      })
  )

  if (props.actionsButtonsRenderFn) {
    cells.push(
      <TableHeadCell key="actionButton">
        <div />
      </TableHeadCell>
    )
  }

  return (
    <TableRow hasActionIcons={isDefined(props.actionsButtonsRenderFn)}>
      {cells}
    </TableRow>
  )
}

export default WidgetTableTHead
