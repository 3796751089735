import * as React from 'react'
import styled from 'styled-components'

export enum IconTriangleSize {
  small = 4
}

interface IIconTriangleProps {
  className?: string
  color: string
  degres?: number
  size?: IconTriangleSize
}

const IconTriangleRaw = (props: IIconTriangleProps) => {
  return <div className={props.className} />
}

export default styled(IconTriangleRaw)`
  width: 0;
  height: 0;
  margin-top: 6px;
  border-left: ${props =>
    `${props.size || IconTriangleSize.small}px solid transparent`};
  border-right: ${props =>
    `${props.size || IconTriangleSize.small}px solid transparent`};
  border-bottom: ${props =>
    `${props.size || IconTriangleSize.small * 2}px solid ${props.color}`};
  transform: rotate(${props => props.degres || 0}deg);
`
