import {
  useDataFocusMethodAttribute,
  useKey,
  useTestAttribute
} from '@app/hooks'
import { ensureArray } from '@libs/ensureArray'
import { KeyboardKey } from '@libs/KeyboardBindingsManager/types'
import * as React from 'react'
import styled from 'styled-components'
import type { IFormWrapperControl } from './types'

export interface IFormWrapperButtonSimpleProps extends IFormWrapperControl {
  children?: React.ReactNode
  disabled?: boolean
  onClick?: (e: Event | React.SyntheticEvent) => void
  elementProps?: React.HTMLAttributes<HTMLDivElement>
}

/**
 * A div which has the role 'button' and is focusable, without any formatting.
 */
const FormWrapperButtonSimple = React.forwardRef<
  HTMLDivElement,
  IFormWrapperButtonSimpleProps
>((props, forwardedRef) => {
  const { ariaRoles, labelledBy, elementProps, ...rest } = props

  const ariaRolesArr = ensureArray(ariaRoles)

  const [mainRole, ...otherRoles] = (ariaRolesArr.length && ariaRolesArr) || [
    'form',
    'button'
  ]

  const { testAttributeProps } = useTestAttribute(mainRole)

  const { ref: dataFocusMethodAttributeRef, ...dataFocusMethodAttributeProps } =
    useDataFocusMethodAttribute<HTMLDivElement>()

  const { ref: keyRef } = useKey(
    [KeyboardKey.Enter, KeyboardKey.Space],
    props.onClick
  )

  const name = `FormWrapperButtonSimple-${labelledBy}`

  const ref = React.useCallback<React.RefCallback<HTMLDivElement>>(node => {
    if (typeof forwardedRef === 'function') {
      forwardedRef(node)
    }
    if (dataFocusMethodAttributeRef) {
      dataFocusMethodAttributeRef.current = node
    }
    keyRef?.(node)
  }, [])

  const customElementProps: React.HTMLAttributes<HTMLDivElement> = {
    ...(props.disabled && { 'aria-disabled': true }),
    ...elementProps
  }

  return (
    <div
      ref={ref}
      data-name={name}
      role="button"
      tabIndex={0}
      {...rest}
      {...customElementProps}
      {...testAttributeProps(otherRoles)(labelledBy)}
      {...dataFocusMethodAttributeProps}
    />
  )
})

export default styled(FormWrapperButtonSimple)`
  display: flex;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  :focus {
    outline: none;
  }
`
