import { ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

const StyledContainerFlex = styled(ContainerFlex)`
  width: 100%;
  border-radius: ${consts.borderRadiusSmallRedesign};
  padding: ${consts.paddingVerySmall} ${consts.paddingDefault}
    ${consts.paddingSmall};
  background-color: ${consts.BackgroundColorV2.lightGray};
`

interface IInformationLineProps {
  title: string
  className?: string
}

const InformationLine: React.FC<IInformationLineProps> = props => {
  return (
    <StyledContainerFlex
      name="AddDashboardInformationLine"
      className={props.className}
      items={[<Label variant={LabelVariant.p_bold}>{props.title}</Label>]}
    />
  )
}

export default InformationLine
