import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { InputCheckers, InputSearch } from '@app/components-legacy/Input'
import InputDatePickerRange from '@app/components-legacy/Input/InputDatePickerRange'
import { useAppTranslator } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { AttacksPageContext } from '../context'
import { onAttacksSearchSubmit } from './handlers'
import IoAAttacksFilterClosed from './IoAAttacksFilterClosed'

interface IIoAAttacksFiltersProps {}

const IoAAttacksFilters: React.FC<IIoAAttacksFiltersProps> = props => {
  const attacksPageContext = React.useContext(AttacksPageContext)

  const translate = useAppTranslator({ namespaces: ['Buttons', 'IoA.Attacks'] })

  if (!attacksPageContext) {
    return null
  }

  return (
    <ContainerFlex
      name="IoAAttacksFilters"
      alignItems="center"
      justifyContent="space-between"
      itemsFlexGrow={[1, 0]}
      items={[
        <InputSearch
          placeholder={translate('Search for a source or a destination')}
          storeInputSearch={attacksPageContext.storeAttacks.storeInputSearch}
          onValidate={onAttacksSearchSubmit(attacksPageContext.storeAttacks)}
          onReset={onAttacksSearchSubmit(attacksPageContext.storeAttacks)}
        />,

        <ContainerFlex
          name="IoAAttacksFiltersRight"
          alignItems="center"
          flexWrap="wrap"
          items={[
            <InputDatePickerRange
              allowEmpty={[true, true]}
              onChange={onAttacksSearchSubmit(attacksPageContext.storeAttacks)}
              storeDatePicker={attacksPageContext.storeAttacks.storeDatePicker}
            />,

            <InputCheckers
              storeInputGenericCheckers={
                attacksPageContext.storeAttacks.storeInputCheckersAttacks
              }
              onValidate={onAttacksSearchSubmit(
                attacksPageContext.storeAttacks
              )}
            />,

            <IoAAttacksFilterClosed />,

            <FormWrapperButton
              labelledBy="refresh"
              buttonProps={{
                type: 'primary',
                onClick: onAttacksSearchSubmit(attacksPageContext.storeAttacks)
              }}
            >
              {translate('Refresh')}
            </FormWrapperButton>

            // TODO: Display live attacks button when the functionality is implemented
            // <ContainerDividerFilter />,
            // <IoAAttacksLiveButton />
          ]}
          spaced
          spaceWidth="small"
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(IoAAttacksFilters)
