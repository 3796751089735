import type { Maybe } from '@@types/helpers'
import * as React from 'react'

/**
 * Return props to add data-focus-method attribute to an element to style it
 * only on focus through mouse or key.
 */
export function useDataFocusMethodAttribute<T extends HTMLElement>(): {
  ref: React.MutableRefObject<Maybe<T>>
  onMouseDown: () => void
  onBlur: () => void
}
export function useDataFocusMethodAttribute<T extends HTMLElement>(
  ref: React.RefObject<T>
): {
  onMouseDown: () => void
  onBlur: () => void
}
export function useDataFocusMethodAttribute<T extends HTMLElement>(
  ref?: React.RefObject<T>
): {
  ref?: React.MutableRefObject<Maybe<T>>
  onMouseDown: () => void
  onBlur: () => void
} {
  const localRef = React.useRef<Maybe<T>>(null)

  const elementRef = ref || localRef

  const setDataFocusMethod = () => {
    if (elementRef.current) {
      elementRef.current.setAttribute('data-focus-method', 'mouse')
    }
  }

  const removeDataFocusMethod = () => {
    if (elementRef.current) {
      elementRef.current.removeAttribute('data-focus-method')
    }
  }

  return {
    ...(!ref && { ref: localRef }),
    onMouseDown: setDataFocusMethod,
    onBlur: removeDataFocusMethod
  }
}
