import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface ITableHeadProps {
  className?: string
  children: React.ReactNode
}

const TableHead: React.FC<ITableHeadProps> = props => {
  return <thead className={props.className}>{props.children}</thead>
}

export default styled(TableHead)`
  background-color: ${consts.colorBlueGray035};
`
