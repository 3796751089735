import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useAppTranslator } from '@app/hooks'
import { useStores } from '@app/hooks/useStores'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import TrailFlowSearchEntry from '../../TrailFlowSearchEntry'
import { onTrailFlowSearchHistorySelection } from './handlers'

export interface IDrawerHistoryProps {
  className?: string
}

const DrawerHistory: React.FC<IDrawerHistoryProps> = () => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const translate = useAppTranslator({
    namespaces: ['TrailFlow.SearchHistory']
  })

  const { storeTrailFlow } = useStores()

  const getSearchHistoryEntries = (): React.ReactNodeArray => {
    return storeTrailFlow.storeSearchHistory.searchHistoryEntriesFiltered.map(
      entitySearchHistory => {
        if (
          !entitySearchHistory.expression ||
          !entitySearchHistory.id ||
          !entitySearchHistory.expressionObject
        ) {
          return
        }

        return (
          <TrailFlowSearchEntry
            key={entitySearchHistory.id}
            expressionObject={entitySearchHistory.expressionObject}
            onClick={onTrailFlowSearchHistorySelection(storeTrailFlow)(
              entitySearchHistory.expression
            )}
            labelledBy={String(entitySearchHistory.id)}
          />
        )
      }
    )
  }

  const getContent = (): React.ReactNode => {
    if (
      !storeTrailFlow.storeSearchHistory.searchHistoryEntriesFiltered.length
    ) {
      return themeIsLegacy ? (
        <>{translate('No history entry found')}</>
      ) : (
        <Label variant={LabelVariant.p}>
          {translate('No history entry found')}
        </Label>
      )
    }

    return (
      <ContainerFlex
        name="DrawerHistory"
        labelledBy="DrawerHistory"
        direction="column"
        items={getSearchHistoryEntries()}
        spaced
        spaceWidth="veryVerySmall"
      />
    )
  }

  return (
    <ContainerContent
      flags={[
        storeTrailFlow.storeSearchHistory.storeFlagsSearchHistory.flags,
        storeTrailFlow.storeSearchHistory.storeFlagsDeleteSearchHistoryEntry
          .flags
      ]}
      spinner={<SpinnerInline />}
    >
      {getContent()}
    </ContainerContent>
  )
}

export default observer(DrawerHistory)
