import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  ContainerContent,
  ContainerFooter,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import {
  Drawer,
  DrawerCancelButton,
  DrawerContext,
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { FormWrapperSelect } from '@app/components-legacy/Form'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canExportIoEIdentityFindings } from '../../permissions'
import { handleExportFindingsOnSubmit } from './handlers'

export interface IDrawerExportFindingsProps {}

const DrawerExportFindings: React.FC<IDrawerExportFindingsProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoE.Details.Findings']
  })

  const {
    storeIoE: { storeIndicator }
  } = useStores()

  const renderContent = () => {
    const fields: IField[] = [
      {
        name: 'exportFormat',
        label: translate('Export format'),
        control: (
          <FormWrapperSelect
            labelledBy="exportFormat"
            selectProps={{
              defaultValue: 'csv'
            }}
            selectOptions={[
              {
                label: 'CSV',
                value: 'csv'
              }
            ]}
          />
        )
      }
    ]

    return (
      <FormWrapper
        name="findingsExport"
        onSubmit={handleExportFindingsOnSubmit(storeIndicator)}
      >
        <ContainerContent>
          <p>
            {translate(
              'You will export the detections displayed in the interface'
            )}
          </p>

          <ContainerContent>
            <ContainerForm
              variant={ContainerFormVariant.drawer}
              fields={fields}
            />
          </ContainerContent>
        </ContainerContent>

        <Portal name={PlaceHolderName.drawerFooter}>
          <ContainerFooter>
            <DrawerCancelButton />

            <FormWrapperButtonSubmit>
              {translate('Export all')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </FormWrapper>
    )
  }

  return (
    <DrawerContext.Provider
      value={{
        storeDrawer:
          storeIndicator.storeIndicatorFindings.storeDrawerExportFindings
      }}
    >
      <Drawer>
        <DrawerHorizontalContainer>
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: <DrawerTitle>{translate('Export findings')}</DrawerTitle>,
              content: renderContent(),
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canExportIoEIdentityFindings}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerExportFindings)
