import styled from 'styled-components'

export default styled.div`
  background: white url('/w/assets/images/pdf-footer-page.svg') no-repeat left
    top;
  background-size: 592px 262px;
  background-position: center bottom;
  width: 100%;
  height: 150px;
`
