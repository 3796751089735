import { ContainerFlex } from '@app/components/Container'
import { AppMenu } from '@app/components-legacy/Menu'
import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useAppTranslator } from '@app/hooks'
import { canAccessToAttackPathTier0 } from '@app/pages/AttackPath/permissions'
import { AppRouteName } from '@app/routes'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { consts } from '@app/styles'
import { BackgroundColorV2 } from '@app/styles/consts'
import { filterFalsies } from '@libs/filterFalsies'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import AttackPathTier0Accounts from './AttackPathTier0Accounts'
import AttackPathTier0Assets from './AttackPathTier0Assets'

const StyledAppMenu = styled(AppMenu)`
  margin: 0 ${consts.marginDefault};
`

enum Tier0MenuKey {
  Tier_0_Assets = 'Tier_0_Assets',
  Accounts_With_Tier_0_Attack_Path = 'Accounts_With_Tier_0_Attack_Path',
  Recent_Tier_0_Attack_Paths = 'Recent_Tier_0_Attack_Paths'
}

interface IAttackPathTier0ContentProps {
  className?: string
}

const AttackPathTier0Content: React.FC<
  IAttackPathTier0ContentProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.Tier0.Menu']
  })

  const storeMenu = useStoreMenuInit<IMenuEntry<Tier0MenuKey>>({
    menuEntries: filterFalsies([
      {
        key: Tier0MenuKey.Tier_0_Assets,
        label: translate('Tier 0 assets'),
        labelledBy: 'Tier_0_Assets',
        routeDefinition: {
          routeName: AppRouteName.AttackPath_Tier0_Assets,
          parameters: {}
        }
      },

      {
        key: Tier0MenuKey.Accounts_With_Tier_0_Attack_Path,
        label: translate('Accounts with Tier 0 Attack Path'),
        labelledBy: 'Accounts_With_Tier_0_Attack_Path',
        routeDefinition: {
          routeName: AppRouteName.AttackPath_Tier0_Accounts,
          parameters: {}
        }
      }
    ]),
    defaultSelectedMenuKey: Tier0MenuKey.Tier_0_Assets
  })

  return (
    <ContainerFlex
      className={props.className}
      name="AttackPathTier0ContentContent"
      direction="column"
      items={[
        <StyledAppMenu storeMenu={storeMenu} />,

        <div>
          <RbacRoutes
            routes={[
              {
                routeDefinition: {
                  routeName: AppRouteName.AttackPath_Tier0_Assets,
                  parameters: {}
                },
                component: AttackPathTier0Assets,
                rbacPermissionsCheck: () => canAccessToAttackPathTier0
              },
              {
                routeDefinition: {
                  routeName: AppRouteName.AttackPath_Tier0_Accounts,
                  parameters: {}
                },
                component: AttackPathTier0Accounts,
                rbacPermissionsCheck: () => canAccessToAttackPathTier0
              }
            ]}
          />
        </div>
      ]}
      spaced
    />
  )
}

const ObservedAttackPathTier0Content = observer(AttackPathTier0Content)

export default styled(ObservedAttackPathTier0Content)`
  background-color: ${BackgroundColorV2.white};
  padding: 0 0 ${consts.paddingLarge};
  box-shadow: ${consts.boxShadowRedesignLight};
  border-radius: ${consts.borderRadiusRedesign};
`
