import type { StoreManagementAzureAD } from '@app/stores'
import { AzureADSupportConfigurationFormFieldName } from '@app/stores/Management/StoreAzureADConfiguration/types'
import type { InputUpdateTenableCloudApiToken } from '@server/graphql/typeDefs/types'
import { handleDataCollectionStatusOnUpdate } from '../ConfigurationDataCollection/handlers'
import { canReadAzureAdSupport } from '../permissions'

export const handleConfigurationAzureADSupportOnLoad =
  (storeManagementAzureAD: StoreManagementAzureAD) => () => {
    const { storeRbac } = storeManagementAzureAD.storeRoot.stores

    if (!storeRbac.isUserGrantedTo(canReadAzureAdSupport)) {
      return
    }

    storeManagementAzureAD.fetchTenableCloudApiKeysStatus()

    storeManagementAzureAD.fetchAzureADSupportConfiguration().then(() => {
      if (storeManagementAzureAD.isAzureAdSupportEnabled) {
        storeManagementAzureAD.fetchTenableCloudApiKeysPingStatus()
      }
    })
  }

export const handleConfigurationAzureADSupportOnUnLoad =
  (storeManagementAzureAD: StoreManagementAzureAD) => () => {
    const { storeRbac } = storeManagementAzureAD.storeRoot.stores

    if (!storeRbac.isUserGrantedTo(canReadAzureAdSupport)) {
      return
    }

    storeManagementAzureAD.reset()
  }

/**
 * Enable or disabled the AzureAD support.
 */
export const handleConfigurationAzureADSupportOnChange =
  (storeManagementAzureAD: StoreManagementAzureAD, incitationPopup: boolean) =>
  (azureADSupportEnabled: boolean) => {
    const { storeManagementDataCollection } =
      storeManagementAzureAD.storeRoot.stores

    const showIncitationPopup =
      azureADSupportEnabled &&
      !storeManagementDataCollection.dataCollectionStatusEntity?.isEnabled &&
      incitationPopup

    if (showIncitationPopup) {
      // prompt modale
      storeManagementAzureAD.storeModalPromptUseOfTenableCloud.show()
      return
    }
    updateAzureADSupportConfiguration(
      storeManagementAzureAD,
      azureADSupportEnabled
    )
  }

export const promptUseOfTenableCloudOnCancel =
  (storeManagementAzureAD: StoreManagementAzureAD) => (e: React.MouseEvent) => {
    storeManagementAzureAD.storeModalPromptUseOfTenableCloud.hide()
    if (e.currentTarget.tagName.toLowerCase() === 'button') {
      updateAzureADSupportConfiguration(storeManagementAzureAD, true)
    }
  }

export const promptUseOfTenableCloudOnOk =
  (storeManagementAzureAD: StoreManagementAzureAD) => () => {
    const { storeManagementDataCollection } =
      storeManagementAzureAD.storeRoot.stores
    storeManagementAzureAD.storeModalPromptUseOfTenableCloud.hide()
    handleDataCollectionStatusOnUpdate(storeManagementDataCollection)(true)
    updateAzureADSupportConfiguration(storeManagementAzureAD, true)
  }

export const updateAzureADSupportConfiguration = (
  storeManagementAzureAD: StoreManagementAzureAD,
  azureADSupportEnabled: boolean
) => {
  storeManagementAzureAD
    .updateAzureADSupportConfiguration({
      azureADSupportEnabled
    })
    .then(() => {
      // hard reload to refresh all permissions related to that settings
      storeManagementAzureAD.storeRoot.appRouter.hardRedirect(null)
    })
}

/**
 * Submit new ApiKeys.
 */
export const handleTenableCloudApiKeysOnSubmit =
  (storeManagementAzureAD: StoreManagementAzureAD) =>
  (e: React.FormEvent<unknown>): Promise<any> => {
    e.preventDefault()

    const { storeFormAzureADSupportConfiguration } = storeManagementAzureAD

    if (!storeFormAzureADSupportConfiguration.validate()) {
      storeFormAzureADSupportConfiguration.storeRoot.stores.storeMessages.validationError()
      return Promise.reject()
    }

    const tenableCloudApiToken: InputUpdateTenableCloudApiToken = {
      accessKey: storeFormAzureADSupportConfiguration.getFieldValueAsString(
        AzureADSupportConfigurationFormFieldName.accessKey
      ),
      secretKey: storeFormAzureADSupportConfiguration.getFieldValueAsString(
        AzureADSupportConfigurationFormFieldName.secretKey
      )
    }

    return storeManagementAzureAD
      .updateTenableCloudApiToken(tenableCloudApiToken)
      .then(() => {
        storeManagementAzureAD.fetchTenableCloudApiKeysPingStatus()
        storeManagementAzureAD.setApiKeysStatus('set')
      })
  }
