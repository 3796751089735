import type { IComponentLoaderProps } from '@app/components-legacy/types'
import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'

/**
 * Return only props of IComponentLoaderProps.
 */
export function extractContainerLoaderProps<P extends IComponentLoaderProps>(
  props: P
): IComponentLoaderProps {
  return filterEmptyProps<P>(props, {
    pickProps: [
      'onLoad',
      'onUnload',
      'flags',
      'spinner',
      'fade',
      'rbacCapabilityCheck'
    ]
  })
}
