import { ContainerContent } from '@app/components-legacy/Container'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import { useStores } from '@app/hooks/useStores'
import type StoreIndicatorDeviantObjectDeviances from '@app/stores/IoE/StoreIndicator/StoreIndicatorDeviantObjectDeviances'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import DeviancesCardsContent from './DeviancesCardsContent'

interface IDeviancesCardsProps {
  className?: string
  storeIndicatorDeviantObjectDeviances: StoreIndicatorDeviantObjectDeviances
  checkerCodename: GenericCheckerCodename
}

const DeviancesCards: React.FC<IDeviancesCardsProps> = props => {
  const {
    storeIoE: {
      storeIndicator: { storeIndicatorDeviantObjects }
    }
  } = useStores()

  return (
    <ContainerContent
      data-name="DeviancesCards"
      className={props.className}
      spaceWidth="small"
    >
      <DeviancesCardsContent
        selectable={true}
        storeInfrastructures={storeIndicatorDeviantObjects.storeInfrastructures}
        storeInputReasons={storeIndicatorDeviantObjects.storeInputReasons}
        storeWidgetList={
          props.storeIndicatorDeviantObjectDeviances.storeWidgetListDeviances
        }
      />
    </ContainerContent>
  )
}

export default styled(DeviancesCards)`
  padding: ${consts.paddingDefault};
`
