import { SpinnerLogo } from '@app/components-legacy/Spinner'
import type EntityAttackPathNode from '@app/entities/EntityAttackPathNode'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  handleExpandNodeOnClick,
  handleExpandNodeOnMouseOut,
  handleExpandNodeOnMouseOver
} from '../handlers'

export interface IExpandNodeProps extends React.SVGProps<SVGImageElement> {
  uid: string
  linkedEntity: EntityAttackPathNode
}

const CircleWithShadow = styled.circle<{
  isHighlighted: boolean
}>`
  filter: drop-shadow(0 -2px 7px #ccccdd);
  transition: all 200ms ease;
  transform: scale(${props => (props.isHighlighted ? 1.3 : 1)});
`

const TextWithNoFocus = styled.text`
  font-size: 18px;
  user-select: none;
`

const StyledSpinnerLogo = styled(SpinnerLogo)`
  line-height: 0px;
`

const ExpandNode: React.FC<IExpandNodeProps> = props => {
  const { storeAttackPath } = useStores()

  const isHighlighted = storeAttackPath.hoveredExpandNodeUid === props.uid

  const hasBeenExpanded = storeAttackPath.expandedNodeUids.includes(
    props.linkedEntity.getPropertyAsString('uid')
  )

  const isFetchingNodes =
    storeAttackPath.expandedNodeUids.indexOf(
      props.linkedEntity.getPropertyAsString('uid')
    ) ===
      storeAttackPath.expandedNodeUids.length - 1 &&
    storeAttackPath.storeFlagsFetchExpandedNodes.flags.isLoading

  return (
    <g>
      {!hasBeenExpanded && (
        <g
          onMouseOver={handleExpandNodeOnMouseOver(storeAttackPath)(props.uid)}
          onMouseOut={handleExpandNodeOnMouseOut(storeAttackPath)}
          onClick={handleExpandNodeOnClick(storeAttackPath)(
            props.uid,
            props.linkedEntity
          )}
        >
          <CircleWithShadow
            isHighlighted={isHighlighted}
            r={12.5}
            fill={isHighlighted ? consts.colorAttackPathArrow : '#fff'}
          />

          <TextWithNoFocus
            fill={isHighlighted ? '#fff' : consts.colorAttackPathArrow}
            x={-5}
            y={7}
          >
            +
          </TextWithNoFocus>
        </g>
      )}

      {isFetchingNodes && (
        <g
          onMouseOver={handleExpandNodeOnMouseOver(storeAttackPath)(props.uid)}
          onMouseOut={handleExpandNodeOnMouseOut(storeAttackPath)}
          onClick={handleExpandNodeOnClick(storeAttackPath)(
            props.uid,
            props.linkedEntity
          )}
        >
          <CircleWithShadow
            isHighlighted={isHighlighted}
            r={12.5}
            fill={isHighlighted ? consts.colorAttackPathArrow : '#fff'}
          />
          <foreignObject width="16" height="16" x="-8" y="-8">
            <StyledSpinnerLogo width={15} />
          </foreignObject>
        </g>
      )}
    </g>
  )
}

export default observer(ExpandNode)
