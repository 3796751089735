import { createEntities, EntityChecker } from '@app/entities'
import EntityCheckerIdentity from '@app/entities/EntityGenericChecker/EntityCheckerIdentity'
import {
  isEntityCheckerExposure,
  isEntityCheckerIdentity
} from '@app/entities/EntityGenericChecker/helpers'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import { CheckerOptionCodenameEnum } from '@app/entities/EntityGenericCheckerOption/types'
import { canAccessToIdentityIoE } from '@app/pages/IoE/IoEBoardPage/permissions'
import { IoEBoardMenuItemKey } from '@app/pages/IoE/IoEBoardPage/types'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { appendEntitiesToMap } from '@libs/indexEntitiesToMap'
import type { Checker as CheckerIdentity } from '@libs/openapi/service-identity-core'
import { checkRbac } from '@libs/rbac/functions'
import type { QueryRbacCheckers } from '@server/graphql/queries/checker'
import { queryRbacCheckers } from '@server/graphql/queries/checker'
import type {
  Checker,
  Criticity,
  RbacCheckersQueryArgs
} from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import { StoreInputTenants } from '..'
import type { StoreRoot } from '..'
import StoreFlags from '../helpers/StoreFlags'
import StoreMenu from '../helpers/StoreMenu'
import type { IMenuEntry } from '../helpers/StoreMenu/types'
import StoreBase from '../StoreBase'
import StoreIndicator from './StoreIndicator'

interface IStoreIoEOptions {
  // set to true when loading IoE into the Topology view
  topologyContext: boolean
}

export default class StoreIoE extends StoreBase<IStoreIoEOptions> {
  public storeIndicator = new StoreIndicator(this.storeRoot)

  public storeFlagsFetchCheckersExposure = new StoreFlags(this.storeRoot)
  public storeFlagsReloadCheckersExposure = new StoreFlags(this.storeRoot)

  public storeFlagsFetchCheckersIdentity = new StoreFlags(this.storeRoot)
  public storeFlagsReloadCheckersIdentity = new StoreFlags(this.storeRoot)

  public storeInputSearch = new StoreInputSearch(this.storeRoot)
  public storeInputTenants = new StoreInputTenants(this.storeRoot)

  public storeMenuIndicatorsFilter = new StoreMenu<
    IMenuEntry<IoEBoardMenuItemKey>
  >(this.storeRoot)

  /* Observables */

  private $showAllIndicators = observable.box<boolean>(false)
  private $checkers = observable.map<GenericCheckerCodename, EntityChecker>()
  private $checkersIdentity = observable.map<
    GenericCheckerCodename,
    EntityCheckerIdentity
  >()

  constructor(storeRoot: StoreRoot, options: IStoreIoEOptions) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch checkers when loading the interface for the first time.
   */
  fetchCheckersExposure(
    storeFlags = this.storeFlagsFetchCheckersExposure
  ): Promise<void> {
    storeFlags.loading()

    return this._fetchCheckersExposure()
      .then(() => {
        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Reload checkers when updating a filter option.
   */
  reloadCheckersExposure(): Promise<void> {
    return this.fetchCheckersExposure(this.storeFlagsReloadCheckersExposure)
  }

  /**
   * Fetch identity checkers from service-identity-core.
   * Flags need to be handled in handlers.
   */
  fetchCheckersIdentity(
    storeFlags = this.storeFlagsFetchCheckersIdentity
  ): Promise<void> {
    if (
      !this.storeRoot.stores.storeManagementAzureAD
        .isTenableCloudApiTokensWorking
    ) {
      return Promise.resolve()
    }

    storeFlags.loading()

    return this.storeRoot.environment.openApiClients.serviceIdentityCore.checkers
      .getCheckers()
      .then(checkers => {
        const entities = createEntities<CheckerIdentity, EntityCheckerIdentity>(
          EntityCheckerIdentity,
          checkers
        )

        this.setEntityCheckersIdentity(entities)

        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Reload checkers when updating a filter option.
   */
  reloadCheckersIdentity(): Promise<void> {
    return this.fetchCheckersIdentity(this.storeFlagsReloadCheckersIdentity)
  }

  /**
   * Return the checkers (AD or Identities) for a defined criticity.
   */
  filterAndSortCheckersForBoard(
    checkers: Map<string, EntityChecker | EntityCheckerIdentity>,
    criticity: Criticity
  ): Array<EntityChecker | EntityCheckerIdentity> {
    const checkersByCriticity = appendEntitiesToMap(
      Array.from(checkers.values()),
      'genericCriticity'
    )

    return (
      Array.from((checkersByCriticity.get(criticity) || new Set()).values())
        .filter(checker => {
          const searchRegexp =
            this.storeInputSearch.transformedSearchValueAsRegexp

          return (
            searchRegexp.test(checker.getPropertyAsString('name')) ||
            searchRegexp.test(checker.getPropertyAsString('codename'))
          )
        })
        // filter on showAllIndicators / deviance status / findings
        .filter(checker => {
          if (this.showAllIndicators) {
            return true
          }

          if (isEntityCheckerExposure(checker)) {
            return checker.isDeviant === true
          }

          if (isEntityCheckerIdentity(checker)) {
            return checker.hasFindings === true
          }

          return false
        })
        // horizontally, sort by remediation cost
        .sort((a, b) => {
          return (a.remediationCost || 0) > (b.remediationCost || 0) ? 1 : -1
        })
    )
  }

  /**
   * Return all the checkers for a defined criticity, without any other filter.
   */
  getCheckersPerCriticity(
    checkers: Map<string, EntityChecker | EntityCheckerIdentity>,
    criticity: Criticity
  ): Array<EntityChecker | EntityCheckerIdentity> {
    const checkersByCriticity = appendEntitiesToMap(
      Array.from(checkers.values()),
      'genericCriticity'
    )

    return Array.from(
      (checkersByCriticity.get(criticity) || new Set()).values()
    )
  }

  /**
   * Private
   */

  /**
   * Fetch checkers.
   */
  private _fetchCheckersExposure(): Promise<void> {
    return Promise.resolve()
      .then(() => {
        const profileId =
          this.storeRoot.stores.storeAuthentication.currentProfileId

        const args: RbacCheckersQueryArgs = {
          profileId,
          optionsCodenames: [
            CheckerOptionCodenameEnum.O_CRITICITY,
            CheckerOptionCodenameEnum.O_ENABLED
          ]
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<QueryRbacCheckers>(queryRbacCheckers, args)
      })
      .then(data => data.rbacCheckers)
      .then(rbacCheckers => {
        if (!checkRbac(this.storeRoot)(rbacCheckers)) {
          throw new ForbiddenAccessError()
        }

        const entities = createEntities<Checker, EntityChecker>(
          EntityChecker,
          rbacCheckers.node
        )

        this.setEntityCheckers(entities)
      })
  }

  /* Actions */

  @action
  setShowAllIndicators(value: boolean): this {
    this.$showAllIndicators.set(value)
    return this
  }

  @action
  setEntityCheckers(entities: EntityChecker[]): this {
    entities.forEach(entity => {
      if (entity.codename) {
        this.$checkers.set(entity.codename, entity)
      }
    })

    return this
  }

  @action
  setEntityCheckersIdentity(entities: EntityCheckerIdentity[]): this {
    entities.forEach(entity => {
      if (entity.codename) {
        this.$checkersIdentity.set(entity.codename, entity)
      }
    })

    return this
  }

  /* Computed */

  /**
   * Return AD checkers.
   */
  @computed
  get checkers(): Map<string, EntityChecker> {
    return toJS(this.$checkers)
  }

  /**
   * Return Identity checkers.
   */
  @computed
  get checkersIdentity(): Map<GenericCheckerCodename, EntityCheckerIdentity> {
    return toJS(this.$checkersIdentity)
  }

  /**
   * Return checkers to be display on the board page, according to the state
   * of the context of the page (menu, filters, etc).
   */
  @computed
  get checkersForBoard(): Map<string, EntityChecker | EntityCheckerIdentity> {
    const boardCheckers = new Map<
      string,
      EntityChecker | EntityCheckerIdentity
    >()

    if (this.isMenuSelectedToShowAdCheckers) {
      this.$checkers.forEach((checker, codename) => {
        boardCheckers.set(codename, checker)
      })
    }

    // get identity checkers if not topology context (from the Topologie view) and
    // if service-identity-core is reachable
    if (
      !this.options.topologyContext &&
      this.storeRoot.stores.storeManagementAzureAD
        .isTenableCloudApiTokensWorking &&
      this.isAllOrIdentityCheckersMenuEntrySelected
    ) {
      this.$checkersIdentity.forEach((checker, codename) => {
        boardCheckers.set(codename, checker)
      })
    }

    return boardCheckers
  }

  @computed
  get showAllIndicators(): boolean {
    return this.$showAllIndicators.get()
  }

  /**
   * Return true/false if the menu allows to show or not identity checkers.
   */
  @computed
  get isMenuSelectedToShowAdCheckers(): boolean {
    if (!this.storeMenuIndicatorsFilter.selectedMenuKey) {
      return true
    }

    return [
      IoEBoardMenuItemKey.All,
      IoEBoardMenuItemKey.ActiveDirectory
    ].includes(this.storeMenuIndicatorsFilter.selectedMenuKey)
  }

  /**
   * Return true if the menu is selected on All or Microsoft Entra ID entries.
   */
  @computed
  get isAllOrIdentityCheckersMenuEntrySelected(): boolean {
    if (!this.storeMenuIndicatorsFilter.selectedMenuKey) {
      return true
    }

    return [IoEBoardMenuItemKey.All, IoEBoardMenuItemKey.AzureAD].includes(
      this.storeMenuIndicatorsFilter.selectedMenuKey
    )
  }

  /**
   * Return true if the menu is selected on Microsoft Entra ID entry.
   */
  @computed
  get isIdentityCheckersMenuEntrySelected(): boolean {
    return (
      this.storeMenuIndicatorsFilter.selectedMenuKey ===
      IoEBoardMenuItemKey.AzureAD
    )
  }

  /**
   * Return true if conditions are met to show the error on the UI.
   */
  @computed
  get showIoEIdentitiesConnectionError(): boolean {
    if (
      !this.storeRoot.stores.storeRbac.isUserGrantedTo(canAccessToIdentityIoE)
    ) {
      return false
    }

    return (
      !this.storeRoot.stores.storeManagementAzureAD
        .isTenableCloudApiTokensWorking &&
      this.isAllOrIdentityCheckersMenuEntrySelected
    )
  }
}
