import { colorTokens, defaultTheme } from '@design-system/styles/themes/default'
import type { DSThemeDefinition } from '@design-system/styles/themes/types'

const tokens: Partial<DSThemeDefinition['tokens']> = {
  /**
   * Button
   */

  'button/background/select': colorTokens['color/background/white'],

  /**
   * Input
   */

  'input/backgroundColor/default': colorTokens['color/background/white'],
  'input/color/text': colorTokens['color/font/primary'],
  'input/color/placeholder': colorTokens['color/font/secondary'],

  /**
   * InputSearch
   */

  'inputSearch/backgroundColor/default': colorTokens['color/background/white'],

  /**
   * Select
   */

  'select/backgroundColor/default': colorTokens['color/background/white']
}

export const greyThemeTokens: DSThemeDefinition = {
  ...defaultTheme,
  tokens: { ...defaultTheme.tokens, ...tokens }
}
