import {
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import FormWrapperButtonSubmit from '@app/components-legacy/Form/Wrappers/ButtonSubmit'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ReportAction } from '../types'

export interface IReportCommonFormButtonsProps {
  version: ReportAction
}

const ReportCommonFormButtons: React.FC<
  IReportCommonFormButtonsProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.System.Configuration.ReportingCenter']
  })

  const { storeManagementReportingCenter } = useStores()

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const { storeFlagsCreateReport: storeSubmitFlags } =
    storeManagementReportingCenter

  const buttonLabel = translate(
    props.version === ReportAction.Creation ? 'Create report' : 'Save'
  )

  return (
    <ContainerFooter>
      <DrawerCancelButton
        storeDrawer={storeManagementReportingCenter.storeDrawerDeleteReport}
      />

      <ContainerFlex
        name="ReportCreateFormButtons"
        items={[
          !themeIsLegacy ? (
            <FormWrapperButtonSubmit loading={storeSubmitFlags.isLoading}>
              {buttonLabel}
            </FormWrapperButtonSubmit>
          ) : (
            <FormWrapperButtonSubmit
              type="primary"
              loading={storeSubmitFlags.isLoading}
            >
              {buttonLabel}
            </FormWrapperButtonSubmit>
          )
        ]}
        spaced
      />
    </ContainerFooter>
  )
}

export default observer(ReportCommonFormButtons)
