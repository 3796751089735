import { ContainerFlex } from '@app/components-legacy/Container'
import type { StoreInputReasons } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import ReasonsTree from './ReasonsTree'

export interface IInputReasonsDrawerProps {
  className?: string
  storeInputReasons: StoreInputReasons
}

function getReasonsTreeItems(props: IInputReasonsDrawerProps): React.ReactNode {
  const { storeInputReasons } = props

  const entitiesReason = Array.from(storeInputReasons.reasons.values()).filter(
    entityReason => {
      const searchRegexp =
        storeInputReasons.storeInputSearch.transformedSearchValueAsRegexp

      return searchRegexp.test(entityReason.getPropertyAsString('name'))
    }
  )

  return (
    <ReasonsTree
      storeInputReasons={storeInputReasons}
      entitiesReason={entitiesReason}
    />
  )
}

const DrawerContent: React.FC<IInputReasonsDrawerProps> = props => {
  return (
    <ContainerFlex
      name="InputReasonsDrawerContent"
      className={props.className}
      items={getReasonsTreeItems(props)}
      direction="column"
    />
  )
}

export default observer(DrawerContent)
