import { ContainerSimple } from '@app/components/Container'
import { IconCheck } from '@app/components/Icon'
import { Label } from '@app/components/Labels'
import type { ILabelProps } from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import type { IFormWrapperControl } from '@app/components-legacy/Form/Wrappers/types'
import { useDataFocusMethodAttribute } from '@app/hooks'
import { consts } from '@app/styles'
import { FontColorV2 } from '@app/styles/consts'
import { isDefined } from '@libs/isDefined'
import * as React from 'react'
import styled from 'styled-components'

export const CheckboxDiv = styled.div<{
  checked?: boolean
  disabled?: boolean
  indeterminate?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  position: relative;
  border-radius: ${props =>
    props.theme.tokens['checkbox/borderRadius/default']};
  background-color: ${props =>
    props.disabled
      ? props.theme.tokens['checkbox/backgroundColor/disabled']
      : props.checked
        ? props.theme.tokens['checkbox/backgroundColor/checked']
        : props.theme.tokens['checkbox/backgroundColor/default']};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  border: ${props =>
    props.disabled
      ? 'none'
      : `1px solid ${
          props.checked
            ? props.theme.tokens['checkbox/borderColor/checked']
            : props.theme.tokens['checkbox/borderColor/default']
        }`};

  &:focus-within,
  &:hover {
    ${props =>
      !props.disabled &&
      (props.checked
        ? `background-color: ${props.theme.tokens['checkbox/backgroundColor/hover']}; border-color: ${props.theme.tokens['checkbox/backgroundColor/hover']};`
        : `border-color: ${props.theme.tokens['checkbox/backgroundColor/checked']};`)}
    outline: none;
  }

  &[data-focus-method='mouse']:not(:hover):focus-within {
    border: ${props =>
      props.disabled
        ? 'none'
        : `1px solid ${
            props.checked
              ? props.theme.tokens['checkbox/backgroundColor/checked']
              : props.theme.tokens['checkbox/borderColor/default']
          }`};
    ${props =>
      !props.disabled &&
      props.checked &&
      `background-color: ${props.theme.tokens['checkbox/backgroundColor/checked']};`}
  }
`

const StyledHtmlInput = styled.input`
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
`

const StyledHtmlLabel = styled.label`
  display: flex;
  ${Label} {
    padding: 0 ${consts.paddingDefault};
  }
`

export const IndeterminateDiv = styled.div<{ disabled?: boolean }>`
  width: 12px;
  height: 12px;
  background-color: ${props =>
    props.disabled
      ? props.theme.tokens['checkbox/backgroundColor/indeterminate']
      : props.theme.tokens['checkbox/backgroundColor/checked']};
  border-radius: 2px;
`

interface IFormWrapperCheckboxSimpleProps extends IFormWrapperControl {
  checked?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  indeterminate?: boolean
  labelProps?: ILabelProps
  onChange?: (checked: boolean) => void
}

const FormWrapperCheckboxSimple: React.FC<
  IFormWrapperCheckboxSimpleProps
> = props => {
  const ref = React.useRef<HTMLDivElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const dataFocusMethodAttributeProps = useDataFocusMethodAttribute(ref)

  const [checked, setChecked] = React.useState(props.defaultChecked || false)

  React.useEffect(() => {
    if (isDefined(props.checked)) {
      setChecked(props.checked)
    }
  }, [props.checked])

  React.useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.indeterminate = Boolean(props.indeterminate)
    }
  }, [props.indeterminate])

  const handleCheck = () => {
    if (props.disabled) {
      return
    }

    if (!isDefined(props.checked)) {
      setChecked(!checked)
    }

    if (props.onChange) {
      props.onChange(!checked)
    }
  }

  const checkbox = (
    <ContainerSimple
      name={`FormWrapperCheckbox-${props.labelledBy}`}
      ariaRoles={props.ariaRoles || ['form', 'checkbox']}
      labelledBy={props.labelledBy}
    >
      <CheckboxDiv
        ref={ref}
        className={props.className}
        style={props.style}
        checked={checked}
        indeterminate={props.indeterminate}
        disabled={props.disabled}
      >
        <StyledHtmlInput
          ref={inputRef}
          type="checkbox"
          onChange={handleCheck}
          disabled={props.disabled}
          checked={checked}
          aria-checked={props.indeterminate ? 'mixed' : checked}
          aria-disabled={props.disabled}
          {...dataFocusMethodAttributeProps}
        />
        {!props.indeterminate && checked && (
          <IconCheck
            color={props.disabled ? FontColorV2.secondary : consts.colorWhite}
          />
        )}

        {props.indeterminate && <IndeterminateDiv disabled={props.disabled} />}
      </CheckboxDiv>
    </ContainerSimple>
  )

  return props.children ? (
    <StyledHtmlLabel>
      {checkbox}
      <Label
        variant={LabelVariant.p}
        {...props.labelProps}
        {...(props.disabled && { color: FontColorV2.secondary })}
      >
        {props.children}
      </Label>
    </StyledHtmlLabel>
  ) : (
    checkbox
  )
}

export default FormWrapperCheckboxSimple
