import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import type {
  DashboardWidgetOptionsSerieStatDataPointAttackCount,
  Maybe
} from '@server/graphql/typeDefs/types'

export default class EntityDashboardWidgetOptionsSerieStatDataPointAttackCount
  extends EntityBase
  implements
    PropertiesNullable<DashboardWidgetOptionsSerieStatDataPointAttackCount>
{
  attackTypeId: Maybe<number> = null
  count: Maybe<number> = null

  constructor(
    data: Partial<DashboardWidgetOptionsSerieStatDataPointAttackCount>
  ) {
    super()
    Object.assign(this, data)
  }
}
