import { isDefined } from '@libs/isDefined'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import type { InputNumberProps } from 'antd/lib/input-number'
import type { SelectValue } from 'antd/lib/select'
import { isValidCron } from 'cron-validator'
import { isBoolean } from 'lodash'
import type StoreForm from '.'
import type { FieldName, InputMultiValuesOnChange } from './types'

/**
 * Generic handlers to be used in forms with an instance of StoreForm.
 */

/**
 * Generic handler to set a field value.
 *
 * Note: use explicit fieldName to match the same signature that other handlers where
 *       sometimes the name if not passed in the called function.
 *
 * Usage:
 *
 * <Input
 *   ...
 *   onChange={onInputChange(storeForm)}
 * />
 */
export const onInputChange =
  (storeForm: StoreForm<any>) =>
  (fieldName: FieldName) =>
  (event: React.ChangeEvent<any> | React.FormEvent<any>): void => {
    storeForm.setFieldValue(fieldName, event.currentTarget.value)
  }

/**
 * Generic handler to set a field value on a Switch component (from Antd).
 *
 * Usage:
 *
 * <Switch
 *   ...
 *   onChange={onSwitchChange(storeForm)(fieldName)}
 * />
 */
export const onSwitchChange =
  (storeForm: StoreForm<any>) =>
  (fieldName: FieldName) =>
  (checked: boolean): void => {
    storeForm.setFieldValue(fieldName, checked)
  }

/**
 * Generic handler to set a field value on a Checkbox component (from Antd).
 *
 * Usage:
 *
 * <FormWrapperCheckbox
 *   checkboxProps={{
 *     onChange: onCheckboxChange(storeForm)(fieldName),
 *     ...
 *   }}
 * />
 */
export const onCheckboxChange =
  (storeForm: StoreForm<any>) =>
  (fieldName: FieldName) =>
  (checkedOrEvent: boolean | CheckboxChangeEvent): void => {
    storeForm.setFieldValue(
      fieldName,
      isBoolean(checkedOrEvent) ? checkedOrEvent : checkedOrEvent.target.checked
    )
  }

/**
 * Generic handler to set a field value on a Input number component.
 *
 * Usage:
 *
 * <InputNumber
 *   ...
 *   onChange={onInputNumberChange(storeForm)(fieldName)}
 * />
 */
export const onInputNumberChange =
  (storeForm: StoreForm<any>) =>
  (fieldName: FieldName): InputNumberProps['onChange'] =>
  (value): void => {
    if (!isDefined(value)) {
      return
    }

    storeForm.setFieldValue(fieldName, value)
  }

/**
 * Generic handler to set a value on a Select component.
 *
 * Usage:
 *
 * <Select
 *   ...
 *   onChange={onInputNumberChange(storeForm)(fieldName)}
 * />
 */
export const onSelectChange =
  (storeForm: StoreForm<any>) =>
  (fieldName: FieldName) =>
  (value: SelectValue): void => {
    storeForm.setFieldValue(fieldName, String(value))
  }

/**
 * Generic handler to set a value on a native select component.
 *
 * Usage:
 *
 * <select
 *   ...
 *   onChange={onNativeSelectChange(storeForm)(fieldName)}
 * />
 */
export const onNativeSelectChange =
  (storeForm: StoreForm<any>) =>
  (fieldName: FieldName) =>
  (event: React.ChangeEvent<HTMLSelectElement>): void => {
    storeForm.setFieldValue(fieldName, event.currentTarget.value)
  }

/**
 * Generic handler to set a value on a InputMultiValues component.
 *
 * Usage:
 *
 * <InputMultiValues
 *   ...
 *   onChange={onMultiValuesChange(storeForm)(fieldName)}
 * />
 */
export const onMultiValuesChange =
  (storeForm: StoreForm<any>): InputMultiValuesOnChange =>
  (fieldName, value): void => {
    storeForm.setFieldValue(fieldName, String(value))
  }

/**
 * Handler to set a value on a InputMultiValues component taking a Cron as input.
 *
 * Usage:
 *
 * <InputMultiValues
 *   ...
 *   onChange={onMultiValuesCronInputChange(storeForm)(fieldName)}
 * />
 */
export const onMultiValuesCronInputChange =
  (storeForm: StoreForm<any>): InputMultiValuesOnChange =>
  (fieldName, value): void => {
    const stringValue = String(value)
    const isCronValid = isValidCron(stringValue, {
      seconds: false,
      alias: true,
      allowBlankDay: true
    })
    storeForm.setFieldValue(fieldName, stringValue)
    if (!isCronValid && stringValue.length) {
      storeForm.setFieldError(fieldName, 'The cron is not valid')
      return
    }

    storeForm.resetFieldErrors(fieldName)
  }

/**
 * Generic handler validate a field on blur.
 *
 * Note: use explicit fieldName to match the same signature that other handlers where
 *       sometimes the name if not passed in the called function.
 *
 * Usage:
 *
 * <Input
 *   ...
 *   onBlur={onInputBlur(storeForm)(fieldName)}
 * />
 */
export const onInputBlur =
  (storeForm: StoreForm<any>) => (fieldName: FieldName) => (): void => {
    const field = storeForm.getFields().find(field => field.name === fieldName)

    if (!field) {
      return
    }

    field.validate()
  }
