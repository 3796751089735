import { IconSearch } from '@app/components/Icon'
import { ContainerFlex, ContainerIcon } from '@app/components-legacy/Container'
import {
  IconCloseOutlined,
  IconSearchOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import type { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { combineEventHandlers } from '@libs/combineEventHandlers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled, { css } from 'styled-components'
import {
  onInputKeyPress,
  onInputSearchBlur,
  onInputSearchFocus,
  onInputSearchValueChange,
  onInputSearchValueReset
} from './handlers'

interface IInputSearchProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string
  style?: React.CSSProperties
  name?: string
  placeholder?: string
  // optional handler to call when the input search value is changed
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onReset?: () => void
  onValidate?: (searchValue: string) => void
  storeInputSearch: StoreInputSearch
  leftIcon?: React.ReactNode
  labelledByReset?: string
  suffix?: React.ReactNode
}

const InputSearch: React.FC<IInputSearchProps> = React.forwardRef<
  HTMLInputElement,
  IInputSearchProps
>((props, ref) => {
  const { testAttributeProps } = useTestAttribute('form')

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const {
    className,
    style,
    name,
    placeholder,
    onChange,
    onReset,
    onValidate,
    storeInputSearch,
    leftIcon,
    labelledByReset,
    suffix,
    ...rest // needed to support props injection by AutoComplete component
  } = props

  return (
    <ContainerFlex
      name="InputSearch"
      className={className}
      style={style}
      itemsFlexGrow={[0, 1, 0, 0]}
      itemsFlexShrink={[0, 1, 0, 0]}
      alignItems="center"
      items={[
        leftIcon ??
          (themeIsLegacy ? (
            <ContainerIcon
              labelledBy="searchValue"
              iconComponent={IconSearchOutlined}
            />
          ) : (
            <IconSearch size={20} />
          )),

        <input
          {...rest}
          ref={ref}
          type="text"
          name={name ?? 'searchText'}
          value={storeInputSearch.searchValue}
          placeholder={placeholder || 'Search something'}
          autoComplete="off"
          onChange={combineEventHandlers(
            onInputSearchValueChange(storeInputSearch),
            onChange
          )}
          onFocus={onInputSearchFocus(storeInputSearch)}
          onBlur={onInputSearchBlur(storeInputSearch)}
          onKeyPress={onInputKeyPress(storeInputSearch)(onValidate)}
          {...testAttributeProps('textbox')(name ?? 'search')}
        />,

        props.suffix,

        storeInputSearch.searchValue && (
          <ContainerIcon
            labelledBy={labelledByReset || 'resetSearch'}
            iconComponent={IconCloseOutlined}
            iconProps={{
              onClick: onInputSearchValueReset(storeInputSearch)(onReset),
              // Prevents to open AutoComplete when clearing the input
              onMouseDown: e => e.stopPropagation()
            }}
          />
        )
      ]}
    />
  )
})

const ObservedInputSearch = observer(InputSearch)

export default styled(ObservedInputSearch)`
  background-color: ${props =>
    props.theme.tokens['inputSearch/backgroundColor/default']};
  border-radius: ${props => props.theme.tokens['input/borderRadius/default']};
  min-width: 200px;
  height: ${props => props.theme.tokens['input/height/default']};
  padding: ${props => props.theme.tokens['inputSearch/padding/default']};

  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      border: none;
    `}
  input {
    min-width: 1px; // Fixes delete icon on small width. 0 doesn't work on IE.
    border: none;
    background-color: transparent;
    margin-left: 5px;
    color: ${props => props.theme.tokens['input/color/text']};

    &::placeholder {
      color: ${props => props.theme.tokens['input/color/placeholder']};
    }

    &:focus {
      outline: none;
    }
  }

  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      .anticon-close svg {
        background-color: ${props.theme.tokens['inputSearch/color/closeIcon']};
        border-radius: 50%;
        fill: white;
        padding: 2px;
      }
    `}
`
