import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import { ContainerFlex } from '@app/components-legacy/Container'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { Tooltip } from 'antd'
import * as React from 'react'
import styled from 'styled-components'

interface ITagBookmarkNameProps {
  className?: string
  name: string
  onClick: () => void
  expressionAsString?: string
}

const StyledTooltip = styled(Tooltip)`
  overflow: hidden;
  text-overflow: ellipsis;
`

const TagBookmarkName: React.FC<ITagBookmarkNameProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  return (
    <div
      data-name="TagBookmarkName"
      className={props.className}
      onClick={props.onClick}
    >
      <ContainerFlex
        name="TagBookmarkNameContent"
        alignItems="center"
        items={
          <StyledTooltip title={props.expressionAsString} placement="right">
            <div>
              {themeIsLegacy ? (
                props.name
              ) : (
                <Label variant={LabelVariant.p}>{props.name}</Label>
              )}
            </div>
          </StyledTooltip>
        }
      />
    </div>
  )
}

export default styled(TagBookmarkName)`
  width: 100%;
  padding: 5px 8px;
  cursor: pointer;
  overflow: hidden;
`
