import { createEntities, EntityAttackType } from '@app/entities'
import { CheckerOptionCodenameEnum } from '@app/entities/EntityGenericCheckerOption/types'
import type { IoABoardQueryStringParameters } from '@app/stores/IoA/StoreBoard/types'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { indexEntitiesToMap } from '@libs/indexEntitiesToMap'
import { isDefined } from '@libs/isDefined'
import { checkRbac } from '@libs/rbac/functions'
import type { QueryAttacksTypes } from '@server/graphql/queries/ioa'
import { queryAttacksTypes } from '@server/graphql/queries/ioa'
import type {
  AttackType,
  Maybe,
  RbacAttackTypesQueryArgs
} from '@server/graphql/typeDefs/types'
import { last } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '..'
import StoreFlags from '../helpers/StoreFlags'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'
import type StoreAttacks from './StoreAttacks'
import type { AttacksQueryStringParameters } from './StoreAttacks/types'
import StoreBoard from './StoreBoard'

export default class StoreIoA extends StoreBase {
  public storeBoard = new StoreBoard(this.storeRoot)

  public storeFlagsFetchAttackTypes = new StoreFlags(this.storeRoot)

  /* Observable */

  private $storesAttacks = observable.array<StoreAttacks>()

  /* Private */

  private _attackTypes = new Map<number, EntityAttackType>()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch attackTypes when loading the interface for the first time.
   */
  public fetchAttackTypes(): Promise<void> {
    this.storeFlagsFetchAttackTypes.loading()

    return Promise.resolve()
      .then(() => {
        const args: RbacAttackTypesQueryArgs = {
          profileId: this.storeRoot.stores.storeAuthentication.currentProfileId,
          optionsCodenames: [
            CheckerOptionCodenameEnum.O_ENABLED,
            CheckerOptionCodenameEnum.O_CRITICITY
          ]
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<QueryAttacksTypes>(queryAttacksTypes, args)
      })
      .then(({ rbacAttackTypes }) => {
        if (
          !checkRbac(
            this.storeRoot,
            this.storeFlagsFetchAttackTypes
          )(rbacAttackTypes)
        ) {
          throw new ForbiddenAccessError()
        }

        this.setAttackTypes(rbacAttackTypes.node)

        this.storeFlagsFetchAttackTypes.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsFetchAttackTypes))
  }

  /**
   * Save attackTypes entities.
   */
  setAttackTypes(attackTypes: AttackType[]): this {
    const attackTypeEntities = createEntities<AttackType, EntityAttackType>(
      EntityAttackType,
      attackTypes
    )

    const attackTypeEntitiesAsMap = indexEntitiesToMap<
      EntityAttackType,
      number
    >(attackTypeEntities, 'id')

    this._attackTypes = attackTypeEntitiesAsMap

    return this
  }

  /**
   * Return the attack types.
   */
  get attackTypes(): Map<number, EntityAttackType> {
    return this._attackTypes
  }

  /**
   * Return the attack types as an array.
   */
  get attackTypesAsArray(): EntityAttackType[] {
    return Array.from(this._attackTypes.values())
  }

  /**
   * Return all querystring parameters matching filters of all StoreAttacks.
   */
  computeBoardQueryParameters(): IoABoardQueryStringParameters {
    return this.storeBoard.computedQueryStringFilters
  }

  /**
   * Return all querystring parameters matching filters of all StoreAttacks.
   */
  computeAttacksAllQueryParameters(): {
    boardFilters: IoABoardQueryStringParameters
    attacksFilters: AttacksQueryStringParameters[]
  } {
    const attacksFilters = this.storesAttacks
      .map(storeAttack => storeAttack.computedQueryStringFilters)
      .filter(isDefined)

    const boardFilters = this.computeBoardQueryParameters()

    return { boardFilters, attacksFilters }
  }

  /* Actions */

  /**
   * Reset stores.
   */
  @action
  reset(): this {
    this.storeFlagsFetchAttackTypes.reset()
    return this
  }

  /**
   * Add a new StoreAttacks instance for each investigation view.
   */
  @action
  addNewStoreAttacks(storeAttacks: StoreAttacks): this {
    this.$storesAttacks.push(storeAttacks)
    return this
  }

  /**
   * Remove some StoreAttacks instances.
   */
  @action
  removeStoreAttacksFromIndex(nbStoreAttacks: number): this {
    this.$storesAttacks.splice(nbStoreAttacks)
    return this
  }

  /**
   * Remove all StoreAttacks instances.
   */
  @action
  clearStoresAttack(): this {
    this.$storesAttacks.clear()
    return this
  }

  /* Computed */

  @computed
  get storesAttacks(): StoreAttacks[] {
    return this.$storesAttacks
  }

  /**
   * Return the latest store attack, of the latest attack blade.
   */
  @computed
  get latestStoreAttacks(): Maybe<StoreAttacks> {
    return last(this.storesAttacks) || null
  }

  /**
   * Return the previous store attack from the latest attack blade.
   */
  @computed
  get previousStoreAttacks(): Maybe<StoreAttacks> {
    return this.storesAttacks[this.storesAttacks.length - 2] || null
  }
}
