import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface IIconPauseRawProps {
  className?: string
  onClick?: () => void
  color?: string
}

const IconPauseRaw: React.FC<IIconPauseRawProps> = props => {
  return <div className={props.className} onClick={props.onClick} />
}

export default styled(IconPauseRaw)`
  box-sizing: content-box;
  width: 4px;
  height: 16px;
  border-right: 4px solid ${props => props.color || consts.colorBlueGray005};
  border-left: 4px solid ${props => props.color || consts.colorBlueGray005};
  cursor: pointer;
`
