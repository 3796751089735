import { ContainerFlex } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks'
import type { ITimelineAttackPoint } from '@app/stores/IoA/StoreBoard/StoreTimeline/types'
import { consts } from '@app/styles'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { DEFAULT_MAX_HEIGHT_PILL } from './constant'
import FiltersTimelinePointAttacksPill from './FiltersTimelinePointAttacksPill'
import FiltersTimelineTooltip from './FiltersTimelineTooltip'
import { handleTimelinePillClick } from './handlers'
import { getTimelinePillDetails } from './utils'

interface IFiltersTimelinePointAttacksProps {
  className?: string
  timestamp: string
  attacks: Set<ITimelineAttackPoint>
}

const EmptyTick = styled.div`
  height: 10px;
  border-left: 1px solid ${consts.colorGrey010};
`

const FiltersTimelinePointAttacks: React.FC<
  IFiltersTimelinePointAttacksProps
> = props => {
  const { storeIoA } = useStores()
  const { storeTimeline } = storeIoA.storeBoard

  const timelinePillDetails = getTimelinePillDetails(
    Array.from(props.attacks.values()),
    DEFAULT_MAX_HEIGHT_PILL,
    storeTimeline.pillBiggestAttackCountSum
  )

  const getPillsItems = () => {
    if (!timelinePillDetails.length) {
      return <EmptyTick />
    }

    return timelinePillDetails.map(timelinePillDetail => {
      return (
        <Tooltip
          trigger="click"
          placement="bottom"
          title={
            !storeTimeline.storeFlagsTooltip.isLoading && (
              <FiltersTimelineTooltip
                attacksStatCountsEntities={
                  storeTimeline.attacksStatsCountsForTooltip
                }
              />
            )
          }
          overlayStyle={{ marginTop: '50px' }}
          onOpenChange={handleTimelinePillClick(storeIoA.storeBoard)(
            props.timestamp
          )}
        >
          <div>
            <FiltersTimelinePointAttacksPill
              height={timelinePillDetail.height}
              criticity={timelinePillDetail.attack.criticity}
            />
          </div>
        </Tooltip>
      )
    })
  }

  return (
    <ContainerFlex
      name={`display-${FiltersTimelinePointAttacks.name}`}
      alignItems="center"
      justifyContent="center"
      items={[
        <ContainerFlex
          name={FiltersTimelinePointAttacks.name}
          className={props.className}
          direction="column"
          alignItems="center"
          items={getPillsItems()}
        />
      ]}
      fullHeight
    />
  )
}

const ObservedFiltersTimelinePointAttacks = observer(
  FiltersTimelinePointAttacks
)

export default styled(ObservedFiltersTimelinePointAttacks)`
  width: 7px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  align-self: center;
`
