import type { PropertiesNullable } from '@@types/helpers'
import type {
  ApplicationSettings,
  LockoutPolicy,
  Maybe
} from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityLockoutPolicy
  extends EntityBase
  implements PropertiesNullable<LockoutPolicy>
{
  enabled: Maybe<boolean> = null
  lockoutDuration: Maybe<number> = null
  failedAttemptThreshold: Maybe<number> = null
  failedAttemptPeriod: Maybe<number> = null

  constructor(data: Partial<ApplicationSettings>) {
    super()
    Object.assign(this, data)
  }
}
