import type { Maybe } from '@@types/helpers'
import {
  createEntities,
  createEntity,
  EntityPagination,
  EntityReport
} from '@app/entities'
import type { IDataRowReport } from '@app/entities/EntityReport'
import EntityReportAccessToken from '@app/entities/EntityReportAccessToken'
import { ReportCommonFormFieldName } from '@app/pages/Management/SystemPage/ConfigurationPage/ConfigurationReportingCenterPage/DrawerReportAction/types'
import { ReportAction } from '@app/pages/Management/SystemPage/ConfigurationPage/ConfigurationReportingCenterPage/types'
import type { StoreRoot } from '@app/stores'
import { StoreInfrastructures } from '@app/stores'
import StoreModal from '@app/stores/helpers/StoreModal'
import type { IStoreMultiInstanceOptions } from '@app/stores/types'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { checkRbac } from '@libs/rbac/functions'
import type {
  MutationCreateReport,
  MutationDeleteReport,
  MutationEditReport
} from '@server/graphql/mutations/report'
import {
  mutationCreateReport,
  mutationDeleteReport,
  mutationEditReport
} from '@server/graphql/mutations/report'
import type { MutationRefreshReportAccessToken } from '@server/graphql/mutations/reportAccessToken'
import { mutationRefreshReportAccessToken } from '@server/graphql/mutations/reportAccessToken'
import type { QueryReports } from '@server/graphql/queries/report'
import { queryRbacReports } from '@server/graphql/queries/report'
import type { QueryReportAccessToken } from '@server/graphql/queries/reportAccessToken'
import { queryRbacReportAccessToken } from '@server/graphql/queries/reportAccessToken'
import type {
  CreateReportMutationArgs,
  DeleteReportMutationArgs,
  EditReportMutationArgs,
  InputCreateReport,
  InputEditReport,
  Pagination,
  Report,
  ReportAccessToken
} from '@server/graphql/typeDefs/types'
import { CheckerType, ReportType } from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import StoreDrawer from '../helpers/StoreDrawer'
import StoreFlags from '../helpers/StoreFlags'
import StoreForm from '../helpers/StoreForm'
import { emailListFormat, mandatory } from '../helpers/StoreForm/validators'
import { StoreInputCrontab } from '../helpers/StoreInputCrontab'
import { StoreInputEmails } from '../helpers/StoreInputEmails'
import StoreInputGenericCheckers from '../helpers/StoreInputGenericCheckers'
import type {
  ICheckerAttack,
  ICheckerExposure
} from '../helpers/StoreInputGenericCheckers/types'
import StoreWidgetList from '../helpers/StoreWidgetList'
import StoreBase from '../StoreBase'

export const DEFAULT_CRONTAB = '0 9 * * 1'

export interface IStoreReportingCenterOptions
  extends IStoreMultiInstanceOptions {}

export default class StoreReportingCenter extends StoreBase<IStoreReportingCenterOptions> {
  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Errors', 'Management.System.Configuration.ReportingCenter']
  })

  public storeFlagsFetchReport = new StoreFlags(this.storeRoot)
  public storeFlagsCreateReport = new StoreFlags(this.storeRoot)
  public storeFlagsEditReport = new StoreFlags(this.storeRoot)
  public storeFlagsDeleteReport = new StoreFlags(this.storeRoot)
  public storeDeletionFlags = new StoreFlags(this.storeRoot)
  public storeFlags = new StoreFlags(this.storeRoot)
  public storeFlagsFetchReportAccessToken = new StoreFlags(this.storeRoot)
  public storeFlagsRefreshReportAccessToken = new StoreFlags(this.storeRoot)

  public storeWidgetList = new StoreWidgetList<EntityReport, IDataRowReport>(
    this.storeRoot,
    {
      selectable: false
    }
  )

  public storeInputEmails = new StoreInputEmails(this.storeRoot)

  public storeDrawerReportAction = new StoreDrawer<{
    reportDataRow: IDataRowReport
  }>(this.storeRoot)

  public storeDrawerDeleteReport = new StoreDrawer<{
    reportDataRow: IDataRowReport
  }>(this.storeRoot)

  public storeModalConfirmReportAccessTokenRefresh = new StoreModal(
    this.storeRoot
  )

  public storeInputCheckersExposure =
    new StoreInputGenericCheckers<ICheckerExposure>(this.storeRoot, {
      instanceName: this.options.instanceName,
      checkerType: CheckerType.Exposure,
      selectable: true
    })

  public storeInputCheckersAttacks =
    new StoreInputGenericCheckers<ICheckerAttack>(this.storeRoot, {
      instanceName: this.options.instanceName,
      checkerType: CheckerType.Attack,
      selectable: true
    })

  public storeInfrastructures = new StoreInfrastructures(
    this.storeRoot,
    this.options
  )

  public storeForm = new StoreForm<ReportCommonFormFieldName>(this.storeRoot, {
    setup: {
      fields: {
        [ReportCommonFormFieldName.reportName]: {
          label: ReportCommonFormFieldName.reportName,
          validators: [mandatory()]
        },
        [ReportCommonFormFieldName.reportType]: {
          label: ReportCommonFormFieldName.reportType,
          validators: [mandatory()]
        },
        [ReportCommonFormFieldName.checkerIds]: {
          label: ReportCommonFormFieldName.checkerIds,
          validators: [mandatory()]
        },
        [ReportCommonFormFieldName.directoryIds]: {
          label: ReportCommonFormFieldName.directoryIds
        },
        [ReportCommonFormFieldName.attackTypeIds]: {
          label: ReportCommonFormFieldName.attackTypeIds
        },
        [ReportCommonFormFieldName.profileId]: {
          label: ReportCommonFormFieldName.profileId,
          validators: [mandatory()]
        },
        [ReportCommonFormFieldName.format]: {
          label: ReportCommonFormFieldName.format,
          validators: [mandatory()]
        },
        [ReportCommonFormFieldName.dataTimeframe]: {
          label: ReportCommonFormFieldName.dataTimeframe,
          validators: [mandatory()]
        },
        [ReportCommonFormFieldName.timeZone]: {
          label: ReportCommonFormFieldName.timeZone,
          validators: [mandatory()]
        },
        [ReportCommonFormFieldName.recurrence]: {
          label: ReportCommonFormFieldName.recurrence
        },
        [ReportCommonFormFieldName.recipients]: {
          label: ReportCommonFormFieldName.recipients,
          validators: [mandatory(), emailListFormat()]
        }
      }
    }
  })

  public storeInputCrontab = new StoreInputCrontab(this.storeRoot, {})

  // keys are report id
  private $reports = observable.map<number, EntityReport>()

  private $currentReportAction = observable.box<ReportAction>(
    ReportAction.Creation
  )

  private $reportAccessToken =
    observable.box<Maybe<EntityReportAccessToken>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreReportingCenterOptions) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch reports.
   */
  fetchReports(args_?: QueryReports['args']): Promise<void> {
    const args: QueryReports['args'] = {
      reportsPage: this.storeWidgetList.paginationPage,
      reportsPerPage: this.storeWidgetList.rowsPerPage,
      ...args_
    }

    this.storeFlagsFetchReport.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryReports>(queryRbacReports, args)
      })
      .then(data => data.rbacReports)
      .then(reports => {
        if (!checkRbac(this.storeRoot, this.storeFlagsFetchReport)(reports)) {
          throw new ForbiddenAccessError()
        }

        const reportsEntities = createEntities<Report, EntityReport>(
          EntityReport,
          reports.node.node
        )

        const reportsPagination = createEntity<Pagination, EntityPagination>(
          EntityPagination,
          reports.node.pagination
        )

        this.setReports(reportsEntities)

        this.storeWidgetList.setEntities(reportsEntities)
        this.storeWidgetList.setPagination(reportsPagination)

        this.storeFlagsFetchReport.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsFetchReport))
  }

  /**
   * Create a report.
   */
  createReport(reportArgs: InputCreateReport): Promise<void> {
    this.storeFlagsCreateReport.loading()

    return Promise.resolve()
      .then(() => {
        const args: CreateReportMutationArgs = {
          report: reportArgs
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationCreateReport>(mutationCreateReport, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Report created'),
          {
            labelledBy: 'ReportCreated'
          }
        )

        this.storeFlagsCreateReport.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsCreateReport, {
          // forward error to prevent the drawer closing
          forwardExceptionFn: () => true
        })
      )
  }

  /**
   * Edit a report
   */
  editReport(report: InputEditReport) {
    this.storeFlagsEditReport.loading()

    return Promise.resolve()
      .then(() => {
        const args: EditReportMutationArgs = {
          report
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationEditReport>(mutationEditReport, args)
      })
      .then(() => {
        // reload reports (and remake the mapping between infras and dirs)
        return this.fetchReports()
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Report updated'),
          {
            labelledBy: 'reportUpdated'
          }
        )

        this.storeFlagsEditReport.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsEditReport, {
          forwardExceptionFn: () =>
            'An error has occurred when saving the report'
        })
      )
  }

  /**
   * Delete a report.
   */
  deleteReport(reportId: number) {
    this.storeFlagsDeleteReport.loading()

    return Promise.resolve()
      .then(() => {
        const args: DeleteReportMutationArgs = {
          report: {
            id: reportId
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationDeleteReport>(mutationDeleteReport, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Report deleted'),
          {
            labelledBy: 'reportDeleted'
          }
        )

        this.storeFlagsDeleteReport.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsDeleteReport, {
          forwardExceptionFn: () =>
            'An error has occurred when deleting the report'
        })
      )
  }

  /**
   * Fetch report access token.
   */
  fetchReportAccessToken(storeFlags = this.storeFlagsFetchReportAccessToken) {
    storeFlags.loading()
    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryReportAccessToken>(queryRbacReportAccessToken)
      })
      .then(({ rbacReportAccessToken }) => {
        if (!checkRbac(this.storeRoot, storeFlags)(rbacReportAccessToken)) {
          throw new ForbiddenAccessError()
        }

        const reportAccessTokenEntity = createEntity<
          ReportAccessToken,
          EntityReportAccessToken
        >(EntityReportAccessToken, rbacReportAccessToken.node)

        this.setReportAccessToken(reportAccessTokenEntity)

        storeFlags.success()
      })
      .catch(err => {
        this.$reportAccessToken.set(null)

        storeFlags.fail()

        this.storeRoot.logException(err)
      })
  }

  /**
   * Refresh report access token.
   */
  refreshReportAccessToken(
    storeFlags = this.storeFlagsRefreshReportAccessToken
  ) {
    storeFlags.loading()
    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationRefreshReportAccessToken>(
            mutationRefreshReportAccessToken
          )
      })
      .then(({ refreshReportAccessToken }) => {
        const reportAccessTokenEntity = createEntity<
          ReportAccessToken,
          EntityReportAccessToken
        >(EntityReportAccessToken, refreshReportAccessToken)

        this.setReportAccessToken(reportAccessTokenEntity)

        this.storeRoot.stores.storeMessages.success(
          this.translate('Reports access key generated'),
          {
            labelledBy: 'ReportAccessTokenRefreshed'
          }
        )

        storeFlags.success()
      })
      .catch(err => {
        this.$reportAccessToken.set(null)

        storeFlags.fail()

        this.storeRoot.logException(err)
      })
  }

  /**
   * Compile variables for report creation and edition.
   */
  getArgsFromStores(): InputCreateReport {
    const type = this.storeForm.getFieldValueAsString<ReportType>(
      ReportCommonFormFieldName.reportType
    )

    const args: InputCreateReport = {
      type,
      name: this.storeForm.getFieldValueAsString(
        ReportCommonFormFieldName.reportName
      ),
      profileId: this.storeForm.getFieldValueAsNumber(
        ReportCommonFormFieldName.profileId
      ),
      recipientEmails: this.storeInputEmails.emails,
      directoryIds: this.storeInfrastructures.getSelectedDirectoryIds(),
      checkerIds:
        type === ReportType.Deviances
          ? this.storeInputCheckersExposure.selectedCheckerIds
          : undefined,
      attackTypeIds:
        type === ReportType.Attacks
          ? this.storeInputCheckersAttacks.selectedCheckerIds
          : undefined,
      format: this.storeForm.getFieldValueAsString(
        ReportCommonFormFieldName.format
      ),
      dataTimeframe: this.storeForm.getFieldValueAsString(
        ReportCommonFormFieldName.dataTimeframe
      ),
      recurrence: this.storeInputCrontab.crontab,
      timeZone: this.storeForm.getFieldValueAsString(
        ReportCommonFormFieldName.timeZone
      )
    }

    return args
  }

  /**
   * Actions
   */

  @action
  reset() {
    this.storeInputCheckersExposure.reset()
    this.storeInputCheckersAttacks.reset()
    this.storeInfrastructures.reset()
    this.$reportAccessToken.set(null)
    this.storeFlagsFetchReportAccessToken.reset()

    this.resetForm()

    return this
  }

  @action
  resetForm() {
    this.storeForm.reset()
    this.storeInputEmails.reset()
    this.storeInputCrontab.reset()

    return this
  }

  @action
  setReports(reports: EntityReport[]) {
    this.$reports.clear()

    reports.forEach(entity => {
      if (entity.id) {
        this.$reports.set(entity.id, entity)
      }
    })
  }

  /**
   * Set current report action.
   */
  @action
  setCurrentReportAction(action: ReportAction): this {
    this.$currentReportAction.set(action)
    return this
  }

  /**
   * Set report access token.
   */
  @action
  setReportAccessToken(reportAccessTokenEntity: EntityReportAccessToken): void {
    this.$reportAccessToken.set(reportAccessTokenEntity)
  }

  /* Computed */

  @computed
  get reports(): Map<number, EntityReport> {
    return toJS(this.$reports)
  }

  @computed
  get currentReportAction(): ReportAction {
    return this.$currentReportAction.get()
  }

  @computed
  get reportAccessToken(): Maybe<EntityReportAccessToken> {
    return this.$reportAccessToken.get()
  }
}
