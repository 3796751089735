import * as React from 'react'
import WidgetContainer from './WidgetContainer'
import WidgetHeaderTitle from './WidgetHeaderTitle'

export interface IWidgetErrorProps {
  title: string
}

const WidgetError: React.FC<IWidgetErrorProps> = props => {
  return (
    <WidgetContainer
      data-name="WidgetError"
      flags={{
        flags: true,
        isLoading: false,
        isError: true,
        isForbidden: false
      }}
      header={<WidgetHeaderTitle title={props.title} />}
      footer={null}
    >
      {null}
    </WidgetContainer>
  )
}

export default WidgetError
