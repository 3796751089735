import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks/useStores'
import MenuSystem from '@app/pages/Management/SystemPage/MenuSystem'
import type { IManagementSystemTenantsEditRouteDefinition } from '@app/routes'
import { AppRouteName } from '@app/routes'
import { mergeStoreFlags } from '@app/stores/helpers/StoreFlags/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  canAccessToTenantsManagement,
  canCreateTenants,
  canEditTenant
} from '../permissions'
import TenantsCreatePage from '../TenantsCreatePage'
import TenantsEditPage from '../TenantsEditPage'
import DrawerActionDeleteTenant from './DrawerActionDeleteTenant'
import { handleTenantsOnLoad } from './handlers'
import TenantsList from './TenantsList'

interface ITenantsPageProps {}

const TenantsPage: React.FC<ITenantsPageProps> = props => {
  const { storeManagementTenants } = useStores()

  return (
    <>
      <Blade
        root={true}
        routeDefinition={{
          routeName: AppRouteName.Management_System_Tenants,
          parameters: {}
        }}
        onLoad={handleTenantsOnLoad(storeManagementTenants)}
        layout={{
          name: 'default',
          menu: <MenuSystem />,
          content: (
            <ContainerContent
              flags={mergeStoreFlags(
                [
                  storeManagementTenants.storeFlagsFetchTenants,
                  storeManagementTenants.storeFlagsToggleTenantStatus
                ],
                {
                  // custom errors on TenantsList
                  isError: false
                }
              )}
            >
              <TenantsList />
            </ContainerContent>
          ),
          footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
        }}
        rbacCapabilityCheck={canAccessToTenantsManagement}
      />

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerActionDeleteTenant />
      </Portal>

      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName: AppRouteName.Management_System_Tenants_Create,
              parameters: {}
            },
            component: TenantsCreatePage,
            rbacPermissionsCheck: () => canCreateTenants
          },
          {
            routeDefinition: {
              routeName: AppRouteName.Management_System_Tenants_Edit,
              parameters: {
                tenantId: String()
              }
            },
            component: TenantsEditPage,
            rbacPermissionsCheck: (
              parameters: IManagementSystemTenantsEditRouteDefinition['parameters']
            ) => canEditTenant(parameters.tenantId)
          }
        ]}
      />
    </>
  )
}

export default observer(TenantsPage)
