import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { consts } from '@app/styles'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled, { useTheme } from 'styled-components'

const StyledDiv = styled.div`
  text-transform: uppercase;
  font-weight: ${consts.fontWeightBold};
`

interface ICardTitleProps {
  style?: React.CSSProperties
  className?: string
  children?: React.ReactNode
}

export function CardTitle(props: ICardTitleProps) {
  const theme = useTheme()

  if (isThemeLegacy(theme)) {
    return (
      <StyledDiv className={props.className} style={props.style}>
        {props.children}
      </StyledDiv>
    )
  }

  return (
    <Label
      className={props.className}
      style={props.style}
      variant={LabelVariant.p_bold}
    >
      {props.children}
    </Label>
  )
}
