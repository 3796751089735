import type { Features } from '@alsid/common'
import { rbacCapability } from '@libs/rbac/functions'
import { deny, grant } from '@libs/rbac/permissions'
import type { IRbacCapability, RbacCapabilityCheck } from '@libs/rbac/types'
import { RbacAction, RbacEntityName } from '@server/graphql/typeDefs/types'
import StoreBase from './StoreBase'
import type StoreRoot from './StoreRoot'
import type { IStoreOptions } from './types'

export default class StoreRbac extends StoreBase {
  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
  }

  /**
   * Return true if the user is granted to a permission.
   *
   * Usage:
   * isUserGrantedTo(readInfrastructures(infrastructureId))
   */
  isUserGrantedTo(
    rbacCapabilityCheck: RbacCapabilityCheck,
    options?: {
      // allow to display the missing permissions
      showLogMessage?: boolean
    }
  ): boolean {
    const capability = this.checkIfUserIsAbleTo(rbacCapabilityCheck)

    if (!capability.isGranted && options?.showLogMessage) {
      this.storeRoot.logger.info(
        '[isUserGrantedTo]',
        capability.logMessage ?? '-'
      )
    }

    return capability.isGranted
  }

  /**
   * Return true if the user is granted according to a feature flag.
   *
   * Usage:
   * isUserGrantedAccordingFeatureFlag(Features.FEATURE_FLAG_XXX)
   */
  isUserGrantedAccordingFeatureFlag(featureFlagName: Features): boolean {
    const rbacCapabilityCheck =
      this.grantedAccordingFeatureFlag(featureFlagName)
    return this.isUserGrantedTo(rbacCapabilityCheck)
  }

  /**
   * Return an IRbacCapabilityCheck according to a boolean.
   *
   * Usage:
   * grantedAccordingTo(profile.isReadOnly())
   */
  grantedAccordingTo = (isGranted: boolean): RbacCapabilityCheck => {
    return (/* storeAuthentication.rbacPermissions */): IRbacCapability => {
      return {
        isGranted,
        message: null
      }
    }
  }

  /**
   * Return an IRbacCapabilityCheck according to a feature flag name.
   *
   * Usage:
   * grantedAccordingFeatureFlag(Features.FEATURE_FLAG_XXX)
   */
  grantedAccordingFeatureFlag = (
    featureFlagName: Features
  ): RbacCapabilityCheck => {
    const isGranted =
      this.storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
        featureFlagName
      )

    return (/* storeAuthentication.rbacPermissions */): IRbacCapability => {
      return {
        isGranted,
        message: null
      }
    }
  }

  /**
   * Return a IRbacCapabilityCheck that checks the granted permission of the edition
   * of the current user.
   *
   * This permissions should be granted by the custom permission `edit:self-user:*`.
   * This permission can be set in the permissions management but is *not*
   * returned by the /user/whoami API route.
   *
   * Usage:
   *
   * <BladeLoaderContainer rbacCapabilityCheck={editUserHimself()}>
   *    ...
   * </BladeLoaderContainer>
   */
  editUserHimself(): RbacCapabilityCheck {
    const { storeAuthentication } = this.storeRoot.stores
    const { whoAmI } = storeAuthentication

    if (!whoAmI) {
      return deny()
    }

    return (/* storeAuthentication.rbacPermissions */): IRbacCapability => {
      return rbacCapability(storeAuthentication.rbacPermissions, {
        storeRbac: this
      })(RbacEntityName.User, RbacAction.Edit, whoAmI.id)
    }
  }

  /**
   * Perform a Rbac check and return a IRbacCapabilityCheck object.
   *
   * Note that this function authorize a bypass of the Rbac check if a flag
   * is set in localstorage.
   *
   * Usage:
   * checkIfUserIsAbleTo(readInfrastructures(infrastructureId))
   */
  checkIfUserIsAbleTo(
    rbacCapabilityCheck: RbacCapabilityCheck
  ): IRbacCapability {
    const { storeAuthentication } = this.storeRoot.stores

    // bypass Rbac if the flag rbac is set to false
    // (Note: used for dev/debug only and API Rbac checks are still there obviously)
    if (this.storeRoot.stores.storeDebug.isNoRbacFlag()) {
      return grant()(storeAuthentication.rbacPermissions, { storeRbac: this })
    }

    return rbacCapabilityCheck(storeAuthentication.rbacPermissions, {
      storeRbac: this
    })
  }
}
