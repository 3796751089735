import { createEntities, EntityAttackRemoveFilter } from '@app/entities'
import type { IDataRowAttackRemoveFilter } from '@app/entities/EntityAttackRemoveFilter'
import type { StoreRoot } from '@app/stores'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { ICheckerAttack } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { handleStoreError } from '@libs/errors/handleStoreError'
import type { QueryAttacksRemoveFilters } from '@server/graphql/queries/ioa'
import { queryAttacksRemoveFilters } from '@server/graphql/queries/ioa'
import type {
  AttacksRemoveFilter,
  AttacksRemoveFiltersQueryArgs
} from '@server/graphql/typeDefs/types'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { sortBy, startCase } from 'lodash'
import { action, makeObservable } from 'mobx'

/**
 * "Remove-filters" are the filters that allow to remove (= ignore) attacks.
 */
export default class StoreAttackRemoveFilters extends StoreBase {
  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['IoA.Attacks.DrawerRemoveFilters']
  })

  // keep a reference to the "parent" storeIoA
  public storeIoA = this.storeRoot.stores.storeIoA

  public storeFlagsFetch = new StoreFlags(this.storeRoot)

  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  public storeDrawer = new StoreDrawer(this.storeRoot)

  public storeInputCheckersAttacks =
    new StoreInputGenericCheckers<ICheckerAttack>(this.storeRoot, {
      checkerType: CheckerType.Attack,
      selectable: true
    })

  public storeWidgetList = new StoreWidgetList<
    EntityAttackRemoveFilter,
    IDataRowAttackRemoveFilter
  >(this.storeRoot, {
    selectable: false,
    filterEntitiesFn: entityRemoveFilters => {
      // filter according storeInputSearch
      const filterRegexp = this.storeInputSearch.transformedSearchValueAsRegexp

      const filteredEntityRemoveFilters = entityRemoveFilters.filter(filter => {
        const entityChecker =
          filter.attackTypeId &&
          this.storeInputCheckersAttacks.checkersById.get(filter.attackTypeId)

        const resourceType = this.translate(
          startCase(filter.getPropertyAsString('resourceType'))
        )

        const resourceValue = filter.getPropertyAsString('resourceValue')

        const match1 =
          filterRegexp.test(resourceType) || filterRegexp.test(resourceValue)

        if (!entityChecker) {
          return match1
        }

        return (
          match1 || filterRegexp.test(entityChecker.getPropertyAsString('name'))
        )
      })

      // sort results
      const keys: Array<keyof EntityAttackRemoveFilter> = [
        'resourceType',
        'resourceValue',
        'attackTypeId'
      ]

      return sortBy(filteredEntityRemoveFilters, keys)
    }
  })

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch attack filters (to remove attacks).
   */
  fetchAttacksRemoveFilters(
    _args: AttacksRemoveFiltersQueryArgs
  ): Promise<void> {
    this.storeFlagsFetch.loading()

    const args: AttacksRemoveFiltersQueryArgs = {
      ..._args,
      profileId: this.storeRoot.stores.storeAuthentication.currentProfileId
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryAttacksRemoveFilters>(queryAttacksRemoveFilters, args)
      })
      .then(({ attacksRemoveFilters }) => {
        this.setAttacksRemoveFilters(attacksRemoveFilters)
        this.storeFlagsFetch.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsFetch))
  }

  /* Actions */

  /**
   * Save attack filters entities.
   */
  @action
  setAttacksRemoveFilters(attacksRemoveFilters: AttacksRemoveFilter[]): this {
    const attacksRemoveFiltersEntities = createEntities<
      AttacksRemoveFilter,
      EntityAttackRemoveFilter
    >(EntityAttackRemoveFilter, attacksRemoveFilters)

    this.storeWidgetList.setEntities(attacksRemoveFiltersEntities)

    return this
  }
}
