import { LabelColorTag } from '@app/components/Labels'
import { useAppTranslator } from '@app/hooks'
import { BackgroundColorV2, FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IConfigurationDataCollectionSuccessLabelProps {
  isWorking: boolean
}

const ConfigurationDataCollectionSuccessLabel: React.FC<
  IConfigurationDataCollectionSuccessLabelProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.DataCollection']
  })

  return props.isWorking ? (
    <LabelColorTag
      color={BackgroundColorV2.greenLight}
      labelProperties={{
        color: FontColorV2.green,
        labelledBy: 'tenable-cloud-working'
      }}
    >
      {translate('Working')}
    </LabelColorTag>
  ) : (
    <LabelColorTag
      color={BackgroundColorV2.redLight}
      labelProperties={{
        color: FontColorV2.red,
        labelledBy: 'tenable-cloud-not-working'
      }}
    >
      {translate('Not working')}
    </LabelColorTag>
  )
}

export default ConfigurationDataCollectionSuccessLabel
