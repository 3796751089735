import { ContainerFlex } from '@app/components/Container'
import { Label, LabelColorTag } from '@app/components/Labels'
import type { IContainerSimpleProps } from '@app/components-legacy/Container/ContainerSimple'
import { IconSVG } from '@app/components-legacy/Icon'
import type {
  IConfigurationChangedItem,
  IRoleFieldChanged
} from '@app/entities/EntityUserLog'
import { ConfigurationType } from '@app/entities/EntityUserLog'
import { consts } from '@app/styles'
import { filterFalsies } from '@libs/filterFalsies'
import { isDefined } from '@libs/isDefined'
import { isBoolean } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import UserLogDirectory from '../../Components/UserLogDirectory'
import {
  colorActivityLogsLogAlternateBackgroundRedesign,
  colorUserLogActivatedRedesign,
  colorUserLogDeactivatedRedesign,
  colorUserLogIoAAttackTypeRedesign
} from '../../consts'
import { renderMultipleValues } from '../../utils'

interface IUserTraceConfigurationChangeItem extends IContainerSimpleProps {
  changedItem: IConfigurationChangedItem
  configurationType: ConfigurationType
}

const StyledAction = styled(ContainerFlex)`
  border-radius: ${consts.borderRadiusRedesign};
  background-color: ${colorActivityLogsLogAlternateBackgroundRedesign};
  padding: ${consts.paddingVerySmall} ${consts.paddingDefault};
`

const StyledIcon = styled(IconSVG)`
  transform: rotateY(180deg);
  margin-right: ${consts.marginSmall};
`

const StyledSpacedLabel = styled(Label)`
  margin-right: ${consts.marginVerySmall};
`

const renderField = (
  field: string | IRoleFieldChanged,
  configurationType: ConfigurationType
): JSX.Element => {
  let renderedField
  switch (configurationType) {
    case ConfigurationType.role: {
      const roleField = field as IRoleFieldChanged
      const fieldArray = []
      if (isDefined(roleField.entity)) {
        fieldArray.push(roleField.entity)
      }
      if (isDefined(roleField.field)) {
        fieldArray.push(roleField.field)
      }
      if (isDefined(roleField.action)) {
        fieldArray.push(roleField.action)
      }

      renderedField = filterFalsies(fieldArray).join(' -> ')
      break
    }
    case ConfigurationType.ioa:
    default: {
      renderedField = field as string
      break
    }
  }

  return (
    <LabelColorTag
      color={colorUserLogIoAAttackTypeRedesign.background}
      labelProperties={{
        color: colorUserLogIoAAttackTypeRedesign.text,
        labelledBy: 'field'
      }}
    >
      {renderedField}
    </LabelColorTag>
  )
}

const UserTraceConfigurationChangeItem = (
  props: IUserTraceConfigurationChangeItem
) => {
  const { changedItem, configurationType } = props
  const { field, before, after, directoryId, directoryIds, directoryNames } =
    changedItem

  const hasDirectory = isDefined(directoryId)
  const shouldMaskBeforeAndAfterValues =
    typeof field === 'string'
      ? [
          'searchUserPassword',
          'internalCertificate',
          'smtpAccountPassword'
        ].includes(field as string)
      : false
  const maskedValue = '**************'

  const shouldSpecifyDirectoryNames =
    directoryNames !== undefined &&
    directoryIds !== undefined &&
    directoryNames.length > 0

  const getDirectoryNamesDisplay = (): JSX.Element[] => {
    if (!shouldSpecifyDirectoryNames) {
      return []
    }
    return directoryNames.map((directoryName, index) => (
      <UserLogDirectory
        directoryId={directoryIds[index]}
        directoryName={directoryName}
      />
    ))
  }

  const getActions = (): JSX.Element[] => {
    if (isBoolean(after)) {
      return filterFalsies([
        <LabelColorTag
          color={
            after
              ? colorUserLogActivatedRedesign.background
              : colorUserLogDeactivatedRedesign.background
          }
          labelProperties={{
            color: after
              ? colorUserLogActivatedRedesign.text
              : colorUserLogDeactivatedRedesign.text,
            labelledBy: after === true ? 'Activated' : 'Deactivated'
          }}
        >
          {after === true ? 'Activated' : 'Deactivated'}
        </LabelColorTag>,
        renderField(field, configurationType),
        hasDirectory && <StyledSpacedLabel label="on" />,
        hasDirectory && (
          <UserLogDirectory
            directoryId={directoryId}
            directoryName={changedItem.directoryName}
          />
        )
      ])
    }

    return filterFalsies([
      renderField(field, configurationType),

      ...renderMultipleValues(
        'before',
        String(field),
        shouldMaskBeforeAndAfterValues ? maskedValue : before,
        undefined,
        'before'
      ),
      <StyledSpacedLabel label="to" />,
      ...renderMultipleValues(
        'after',
        String(field),
        shouldMaskBeforeAndAfterValues ? maskedValue : after,
        undefined,
        'after'
      ),
      shouldSpecifyDirectoryNames && (
        <StyledSpacedLabel
          label={`for ${
            directoryNames.length > 1 ? 'directories' : 'directory'
          }`}
        />
      ),
      ...getDirectoryNamesDisplay()
    ])
  }

  return (
    <ContainerFlex
      name={UserTraceConfigurationChangeItem.name}
      className={props.className}
      ariaRoles={['list', 'row']}
      labelledBy={UserTraceConfigurationChangeItem.name}
      alignItems="center"
      items={[
        <StyledIcon iconName="activityLogs/backline" size={20} />,
        <StyledAction
          name="ChangedItemAction"
          flexWrap="wrap"
          items={getActions()}
        />
      ]}
    />
  )
}

export default styled(UserTraceConfigurationChangeItem)`
  margin-top: 1px;
  margin-bottom: 1px;
`
