import { ensureArray } from '@libs/ensureArray'
import {
  ForbiddenAccessError,
  ServerError,
  UnauthorizedAccessError
} from '@libs/errors'
import { StatusCodeDefinition } from '@libs/FetchClient/statusCodes'
import type { ILogger } from '@libs/logger'
import { getLogger } from '@libs/logger'
import type { ClientError, GraphQLClient, Variables } from 'graphql-request'
import type { RequestInit } from 'graphql-request/src/types.dom'
import { isGraphQLServerError } from '../functions'
import type { AnyGraphQLQuery } from '../types'
import type { IGQLRequestor, IQueryOptions } from './types'

export default class GQLRequestor implements IGQLRequestor {
  private _logger: ILogger
  private _graphQLClient: GraphQLClient

  constructor(graphQLClient: GraphQLClient) {
    this._logger = getLogger('GQLRequestor')
    this._graphQLClient = graphQLClient
  }

  /**
   * Do a GraphQL query.
   */
  makeQuery<Q extends AnyGraphQLQuery>(
    query: string,
    variables: Q['args'] = {},
    options: IQueryOptions = {}
  ): Promise<Q['success']> {
    if (options.log === true) {
      // replace parameters
      const finalGraphQLQuery = this._replaceParamsInQuery(query, variables)
      this._logger.debug('Querying', finalGraphQLQuery)
    }

    return this._graphQLClient
      .request({
        document: query,
        variables,
        requestHeaders: options.requestHeaders,
        // Casting needed because of https://github.com/jasonkuhrt/graphql-request/issues/356
        signal: options.signal as RequestInit['signal']
      })
      .catch((err: ClientError) => {
        if (options.keepGraphQLError) {
          throw err
        }

        throw GQLRequestor.transformError(err)
      })
  }

  /**
   * Do a GraphQL query.
   * @deprecated
   */
  query<T>(
    query: string,
    args: Variables = {},
    options: IQueryOptions = {}
  ): Promise<T> {
    return this.makeQuery(query, args, options)
  }

  get graphQLClient() {
    return this._graphQLClient
  }

  /**
   * Replace parameters of the query.
   */
  private _replaceParamsInQuery(query: string, args: Variables) {
    return query.replace(/\$(\w+)/g, paramName => {
      // remove '$' at beginning
      const value = args[paramName.slice(1)]

      let stringifiedValue: string
      try {
        stringifiedValue = JSON.stringify(value)
      } catch (err) {
        stringifiedValue = value
      }

      return String(stringifiedValue || paramName)
    })
  }

  static transformError(err: ClientError): Error {
    if (!err.request || !err.response) {
      return new ServerError(
        'Error when querying the GraphQL server - GraphQL server down?'
      )
    }

    if (err.response.status === 401) {
      return new UnauthorizedAccessError()
    }

    if (!err.response.errors) {
      return new ServerError('Error when querying the GraphQL server')
    }

    // get the last error
    const responseError = ensureArray(err.response.errors).slice(0).pop()

    // The GraphQLRouter middleware should always returns IGraphQLServerErrorMessage object
    // as errors
    if (!isGraphQLServerError(responseError)) {
      return new ServerError('Invalid GraphQL error response')
    }

    if (
      responseError.statusCodeDefinition === StatusCodeDefinition.Unauthorized
    ) {
      return new UnauthorizedAccessError(responseError.message)
    }

    if (responseError.statusCodeDefinition === StatusCodeDefinition.Forbidden) {
      return new ForbiddenAccessError(responseError.message)
    }

    return new ServerError(responseError.message)
  }
}
