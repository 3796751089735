import { ContainerFlex } from '@app/components/Container'
import { VerticalLine } from '@app/components/Separator'
import { consts } from '@app/styles'
import { TenableBordersColor, TenableColor } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'
import BurgerMenu from './BurgerMenu'
import HeaderLogo from './HeaderLogo'
import Widgets from './Widgets'

interface IHeaderProps {
  className?: string
}

const StyledVerticalLine = styled(VerticalLine)`
  margin: 0 16px;
`

const ActiveDirectory = styled.div`
  font-family: 'Barlow';
  font-weight: 300;
  font-stretch: semi-condensed;
  font-size: 22px;
  color: rgb(51, 54, 63);
`

/**
 * Old header when TUL is disabled.
 */
const HeaderLegacy: React.FC<IHeaderProps> = props => {
  // wrapping by a div is necessary to fix the height
  return (
    <div>
      <ContainerFlex
        name="Header"
        className={props.className}
        direction="row"
        itemsFlexGrow={[0, 1]}
        alignItems="center"
        items={[
          <BurgerMenu />,
          <ContainerFlex
            name="HeaderLogo"
            alignItems="center"
            items={[
              <HeaderLogo />,
              <StyledVerticalLine
                color={TenableColor.brand_d_blue}
                height={28}
              />,
              <ActiveDirectory>Active Directory</ActiveDirectory>
            ]}
          />,
          <ContainerFlex
            name="WidgetsBar"
            justifyContent="flex-end"
            items={[<Widgets />]}
          />
        ]}
      />
    </div>
  )
}

export default styled(HeaderLegacy)`
  position: relative;
  top: 0;
  z-index: ${consts.zIndexHeadBar};
  background: ${consts.BackgroundColorV2.white};
  border-bottom: 1px solid ${TenableBordersColor.borderBase};
`
