/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { Identity } from './Identity'
import {
  IdentityFromJSON,
  IdentityFromJSONTyped,
  IdentityToJSON
} from './Identity'
import type { Pagination } from './Pagination'
import {
  PaginationFromJSON,
  PaginationFromJSONTyped,
  PaginationToJSON
} from './Pagination'

/**
 *
 * @export
 * @interface IdentitiesResponse
 */
export interface IdentitiesResponse {
  /**
   *
   * @type {Array<Identity>}
   * @memberof IdentitiesResponse
   */
  items: Array<Identity>
  /**
   *
   * @type {Pagination}
   * @memberof IdentitiesResponse
   */
  pagination: Pagination
}

/**
 * Check if a given object implements the IdentitiesResponse interface.
 */
export function instanceOfIdentitiesResponse(value: object): boolean {
  if (!('items' in value)) return false
  if (!('pagination' in value)) return false
  return true
}

export function IdentitiesResponseFromJSON(json: any): IdentitiesResponse {
  return IdentitiesResponseFromJSONTyped(json, false)
}

export function IdentitiesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IdentitiesResponse {
  if (json == null) {
    return json
  }
  return {
    items: (json['items'] as Array<any>).map(IdentityFromJSON),
    pagination: PaginationFromJSON(json['pagination'])
  }
}

export function IdentitiesResponseToJSON(
  value?: IdentitiesResponse | null
): any {
  if (value == null) {
    return value
  }
  return {
    items: (value['items'] as Array<any>).map(IdentityToJSON),
    pagination: PaginationToJSON(value['pagination'])
  }
}
