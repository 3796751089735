import type { CheckerOptionCodename } from '@app/entities/EntityGenericCheckerOption/types'
import { CheckerOptionCodenameEnum } from '@app/entities/EntityGenericCheckerOption/types'

export function sortCheckerOptions<
  T extends { codename: CheckerOptionCodename }
>(checkerOptions: T[]): void {
  const codenamesOrder: CheckerOptionCodename[] = [
    CheckerOptionCodenameEnum.O_CRITICITY,
    CheckerOptionCodenameEnum.O_ENABLED
  ]

  checkerOptions.sort((a, b) => {
    if (!a.codename || !b.codename) {
      return 1
    }

    if (
      codenamesOrder.indexOf(a.codename) !== -1 ||
      codenamesOrder.indexOf(b.codename) !== -1
    ) {
      return (
        codenamesOrder.indexOf(b.codename) - codenamesOrder.indexOf(a.codename)
      )
    }

    return String(a.codename) > String(b.codename) ? 1 : -1
  })
}
