import { ContainerFlex } from '@app/components-legacy/Container'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreManagementRbacRoles } from '@app/stores'
import { consts } from '@app/styles'
import { isRbacPermissionInvalid, rbacCapability } from '@libs/rbac/functions'
import type { RbacEntityName } from '@server/graphql/typeDefs/types'
import { RbacAction } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import CheckboxByDefault from './CheckboxByDefault'
import CheckboxGrantCreation from './CheckboxGrantCreation'

interface IListEntityItemsTypeDataNoResultProps {
  className?: string
  rbacEntityName: RbacEntityName
  disabled: boolean
  storeManagementRbacRoles: StoreManagementRbacRoles
}

const ListEntityItemsTypeDataNoResult: React.FC<
  IListEntityItemsTypeDataNoResultProps
> = props => {
  const isInvalid = React.useRef(isRbacPermissionInvalid())

  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Roles.Permissions']
  })

  const { storeManagementRbacRoles } = props

  return (
    <ContainerFlex
      name="CheckboxGrantCreation"
      className={props.className}
      justifyContent="flex-end"
      spaced
      items={[
        !isInvalid.current(props.rbacEntityName, RbacAction.Read) && (
          <ContainerFlex
            name="ReadHead"
            alignItems="center"
            items={[
              <div>{translate('Read')}</div>,
              <CheckboxByDefault
                rbacEntityName={props.rbacEntityName}
                disabled={props.disabled}
                rbacAction={RbacAction.Read}
                checked={
                  rbacCapability(
                    storeManagementRbacRoles.pendingRbacPermissions
                  )(props.rbacEntityName, RbacAction.Read, null, {
                    strict: true
                  }).isGranted
                }
              />
            ]}
            spaced
            spaceWidth="small"
          />
        ),
        !isInvalid.current(props.rbacEntityName, RbacAction.Edit) && (
          <ContainerFlex
            name="EditHead"
            alignItems="center"
            items={[
              <div>{translate('Edit')}</div>,
              <CheckboxByDefault
                rbacEntityName={props.rbacEntityName}
                disabled={props.disabled}
                rbacAction={RbacAction.Edit}
                checked={
                  rbacCapability(
                    storeManagementRbacRoles.pendingRbacPermissions
                  )(props.rbacEntityName, RbacAction.Edit, null, {
                    strict: true
                  }).isGranted
                }
              />
            ]}
            spaced
            spaceWidth="small"
          />
        ),
        !isInvalid.current(props.rbacEntityName, RbacAction.Create) && (
          <CheckboxGrantCreation
            rbacEntityName={props.rbacEntityName}
            disabled={props.disabled}
            rbacAction={RbacAction.Create}
            checked={
              rbacCapability(storeManagementRbacRoles.pendingRbacPermissions)(
                props.rbacEntityName,
                RbacAction.Create,
                null,
                {
                  strict: true
                }
              ).isGranted
            }
          />
        )
      ]}
    />
  )
}

const ObservedListEntityItemsTypeDataNoResult = observer(
  ListEntityItemsTypeDataNoResult
)

// Container to fix the position like others checkboxes present in tables
export default styled(ObservedListEntityItemsTypeDataNoResult)`
  font-weight: ${consts.fontWeightBold};
  font-size: 13px;
  padding-right: ${consts.paddingDefault};
`
