export type Maybe<T> = T | null

export interface InputSearchHoneyAccountCandidates {
  directoryId: number

  name: string
}

export interface InputHoneyAccountConfigurationScript {
  directoryId: number
}

export interface InputDirectoryIdEventId {
  directoryId: number

  eventId: string
}

export interface InputAttacksFilters {
  profileId: number

  dateStart?: Maybe<string>

  dateEnd?: Maybe<string>

  resourceType: AttackResourceType

  resourceValue: string

  attackTypeIds?: Maybe<number[]>

  includeClosed?: Maybe<boolean>

  search?: Maybe<string>
}

export interface InputEditAlertIoE {
  alertId: number

  read?: Maybe<boolean>

  archived?: Maybe<boolean>
}

export interface InputEditAlertIoEBulkPayload {
  profileId: number

  read?: Maybe<boolean>

  archived?: Maybe<boolean>
}

export interface InputEditAlertIoA {
  alertId: number

  read?: Maybe<boolean>

  archived?: Maybe<boolean>
}

export interface InputEditAlertIoABulkPayload {
  profileId: number

  read?: Maybe<boolean>

  archived?: Maybe<boolean>
}

export interface InputCreateInfrastructure {
  name: string

  login: string

  password: string
}

export interface InputEditInfrastructure {
  id: number

  name: string

  login: string

  password?: Maybe<string>
}

export interface InputDeleteInfrastructure {
  id: number
}

export interface InputCreateDirectory {
  name: string

  ip: string

  type?: Maybe<string>

  ldapPort?: Maybe<number>

  globalCatalogPort?: Maybe<number>

  smbPort?: Maybe<number>

  dns: string

  infrastructureId: number

  relayId?: Maybe<number>

  sensitiveDataCollectionIsEnabled: boolean
}

export interface InputEditDirectory {
  id: number

  name: string

  ip: string

  ldapPort?: Maybe<number>

  globalCatalogPort?: Maybe<number>

  smbPort?: Maybe<number>

  dns: string

  infrastructureId: number

  relayId?: Maybe<number>

  sensitiveDataCollectionIsEnabled: boolean
}

export interface InputDeleteDirectory {
  infrastructureId: number

  id: number
}

export interface InputRecrawlDirectory {
  infrastructureId: number

  id: number
}

export interface InputRequestDirectoryConnectivity {
  name: string

  ip: string

  ldapPort?: Maybe<number>

  globalCatalogPort?: Maybe<number>

  smbPort?: Maybe<number>

  dns: string

  infrastructureId: number

  relayId?: Maybe<number>

  sensitiveDataCollectionIsEnabled?: Maybe<boolean>
}

export interface InputUpdateHoneyAccount {
  directoryId: number

  honeyAccountDistinguishedName?: Maybe<string>
}

export interface InputEditRelay {
  id: number

  name: string
}

export interface InputDeleteRelay {
  id: number
}

export interface InputRenewRelayApiKey {
  id: number
}

export interface InputEditDeviance {
  directoryId: number

  devianceId: number

  ignoreUntil?: Maybe<string>
}

export interface InputEditAdObjectDeviances {
  profileId: number

  checkerId: number

  adObjectId: number

  reasonIds?: Maybe<number[]>

  dateStart?: Maybe<string>

  dateEnd?: Maybe<string>

  ignoreUntil?: Maybe<string>
}

export interface InputEditCheckerDeviances {
  profileId: number

  checkerId: number

  reasonIds?: Maybe<number[]>

  directoryIds?: Maybe<number[]>

  adObjectExpression?: Maybe<string>

  dateStart?: Maybe<string>

  dateEnd?: Maybe<string>

  ignoreUntil?: Maybe<string>
}

export interface InputCreateDashboard {
  instanceName: string

  name: string

  order: number
}

export interface InputEditDashboard {
  instanceName: string

  dashboardId: number

  name: string

  order?: Maybe<number>
}

export interface InputDeleteDashboard {
  instanceName: string

  dashboardId: number
}

export interface InputCreateDashboardWidget {
  posX: number

  posY: number

  width: number

  height: number

  title: string

  instanceName: string

  dashboardId: number
}

export interface InputEditDashboardWidget {
  id: number

  posX?: Maybe<number>

  posY?: Maybe<number>

  width?: Maybe<number>

  height?: Maybe<number>

  title?: Maybe<string>

  instanceName: string

  dashboardId: number
}

export interface InputDeleteDashboardWidget {
  instanceName: string

  dashboardId: number

  dashboardWidgetId: number
}

export interface InputSetDashboardWidgetOptions {
  instanceName: string

  dashboardId: number

  dashboardWidgetId: number

  type: DashboardWidgetType

  series: InputDashboardWidgetSerie[]
}

export interface InputDashboardWidgetSerie {
  dataOptionsUser?: Maybe<InputDashboardWidgetSerieDataOptionsUser>

  dataOptionsDeviance?: Maybe<InputDashboardWidgetSerieDataOptionsDeviance>

  dataOptionsComplianceScore?: Maybe<InputDashboardWidgetSerieDataOptionsComplianceScore>

  displayOptions?: Maybe<InputDashboardWidgetSerieDisplayOptions>
}

export interface InputDashboardWidgetSerieDataOptionsUser {
  type: DashboardWidgetDataOptionType

  directoryIds: number[]

  active?: Maybe<boolean>

  interval?: Maybe<string>

  duration: number
}

export interface InputDashboardWidgetSerieDataOptionsDeviance {
  type: DashboardWidgetDataOptionType

  checkerIds: number[]

  directoryIds: number[]

  reasonIds: number[]

  profileId?: Maybe<number>

  interval?: Maybe<string>

  duration: number
}

export interface InputDashboardWidgetSerieDataOptionsComplianceScore {
  type: DashboardWidgetDataOptionType

  checkerIds: number[]

  directoryIds: number[]

  reasonIds: number[]

  profileId?: Maybe<number>

  interval?: Maybe<string>

  duration: number
}

export interface InputDashboardWidgetSerieDisplayOptions {
  type: DashboardWidgetType

  label?: Maybe<string>
}

export interface InputUpdateApplicationSettings {
  userRegistration?: Maybe<boolean>

  activityLogsIsActive?: Maybe<boolean>

  activityLogsRetentionDurationInMonth?: Maybe<number>

  smtpServerAddress?: Maybe<string>

  smtpServerPort?: Maybe<number>

  smtpAccount?: Maybe<string>

  smtpAccountPassword?: Maybe<string>

  smtpUseStartTLS?: Maybe<boolean>

  tls?: Maybe<boolean>

  emailSender?: Maybe<string>

  defaultProfileId?: Maybe<number>

  defaultRoleIds?: Maybe<number[]>

  internalCertificate?: Maybe<string>

  telemetryEnabled?: Maybe<boolean>

  ioaAutomaticConfiguration?: Maybe<boolean>

  smtpRelayId?: Maybe<number>

  cloudStatisticsIsActive?: Maybe<boolean>

  healthCheckGlobalStatusDisplayEnabled?: Maybe<boolean>
}

export interface InputUpdateDataCollectionStatus {
  isEnabled: boolean

  notWorkingBecauseOf?: Maybe<DataCollectionNotWorkingReason[]>

  sensitiveDataEnabled: boolean
}

export interface InputAzureAdSupportConfiguration {
  azureADSupportEnabled: boolean
}

export interface InputUpdatePreferences {
  language?: Maybe<Language>

  preferredProfileId?: Maybe<number>
}

export interface InputUpdateLockoutPolicy {
  enabled?: Maybe<boolean>

  lockoutDuration?: Maybe<number>

  failedAttemptThreshold?: Maybe<number>

  failedAttemptPeriod?: Maybe<number>
}

export interface InputLdapConfiguration {
  enabled: boolean

  url?: Maybe<string>

  searchUserDN?: Maybe<string>

  searchUserPassword?: Maybe<string>

  userSearchBase?: Maybe<string>

  userSearchFilter?: Maybe<string>

  allowedGroups?: Maybe<InputAuthConfigurationAllowedGroups[]>

  enableSaslBinding?: Maybe<boolean>

  relayId?: Maybe<number>
}

export interface InputAuthConfigurationAllowedGroups {
  name: string

  defaultProfileId: number

  defaultRoleIds: number[]
}

export interface InputSamlConfiguration {
  enabled: boolean

  providerLoginUrl?: Maybe<string>

  signatureCertificate?: Maybe<string>

  activateCreatedUsers?: Maybe<boolean>

  allowedGroups?: Maybe<InputAuthConfigurationAllowedGroups[]>
}

export interface InputAttackTypeConfiguration {
  configuration?: Maybe<AttackTypeConfigurationConfiguration[]>
}

export interface AttackTypeConfigurationConfiguration {
  attackTypeId: number

  directoryId: number
}

export interface InputUpdateTenableCloudApiToken {
  accessKey: string

  secretKey: string
}

export interface InputIoaConfiguration {
  customPastDelaySeconds: number
}

export interface InputIoAAttacksSummaryLayout {
  layout: AttacksSummaryLayout
}

export interface InputIoAAttacksSummaryDirectory {
  directoryId: number

  chartType: AttacksSummaryChartCardType
}

export interface InputCloseAttack {
  attackId: number

  isClosed: boolean
}

export interface InputCloseAttacksBulk {
  attacksFilters: InputAttacksFilters

  isClosed: boolean
}

export interface InputAttackFilter {
  resourceType: AttackResourceType

  resourceValue: string

  attackTypeId: number
}

export interface InputCreateSyslog {
  ip: string

  port: number

  protocol: string

  tls: boolean

  criticityThreshold: number

  directories: number[]

  healthCheckNames?: Maybe<string[]>

  description?: Maybe<string>

  shouldNotifyOnInitialFullSecurityCheck: boolean

  filterExpression?: Maybe<string>

  inputType: SyslogInputType

  profiles?: Maybe<number[]>

  checkers?: Maybe<number[]>

  attackTypes?: Maybe<number[]>

  relayId?: Maybe<number>
}

export interface InputEditSyslog {
  id: number

  ip: string

  port: number

  protocol: string

  tls: boolean

  criticityThreshold: number

  directories: number[]

  healthCheckNames?: Maybe<string[]>

  description?: Maybe<string>

  shouldNotifyOnInitialFullSecurityCheck: boolean

  filterExpression?: Maybe<string>

  inputType: SyslogInputType

  profiles?: Maybe<number[]>

  checkers?: Maybe<number[]>

  attackTypes?: Maybe<number[]>

  relayId?: Maybe<number>
}

export interface InputTestNewSyslog {
  ip: string

  port: number

  protocol: string

  tls: boolean

  criticityThreshold: number

  directories: number[]

  healthCheckNames?: Maybe<string[]>

  description?: Maybe<string>

  shouldNotifyOnInitialFullSecurityCheck: boolean

  filterExpression?: Maybe<string>

  inputType: SyslogInputType

  profiles?: Maybe<number[]>

  checkers?: Maybe<number[]>

  attackTypes?: Maybe<number[]>

  relayId?: Maybe<number>
}

export interface InputTestExistingSyslog {
  id: number
}

export interface InputDeleteSyslog {
  id: number
}

export interface InputCreateEmail {
  address: string

  criticityThreshold: number

  directories: number[]

  description?: Maybe<string>

  shouldNotifyOnInitialFullSecurityCheck: boolean

  inputType: EmailInputType

  profiles?: Maybe<number[]>

  checkers?: Maybe<number[]>

  attackTypes?: Maybe<number[]>

  healthCheckNames?: Maybe<string[]>
}

export interface InputEditEmail {
  id: number

  address: string

  criticityThreshold: number

  directories: number[]

  description?: Maybe<string>

  shouldNotifyOnInitialFullSecurityCheck: boolean

  inputType: EmailInputType

  profiles?: Maybe<number[]>

  checkers?: Maybe<number[]>

  attackTypes?: Maybe<number[]>

  healthCheckNames?: Maybe<string[]>
}

export interface InputTestNewEmail {
  address: string

  criticityThreshold: number

  directories: number[]

  description: string

  shouldNotifyOnInitialFullSecurityCheck: boolean

  inputType: EmailInputType

  profiles?: Maybe<number[]>

  checkers?: Maybe<number[]>

  attackTypes?: Maybe<number[]>

  healthCheckNames?: Maybe<string[]>
}

export interface InputTestExistingEmail {
  id: number
}

export interface InputDeleteEmail {
  id: number
}

export interface InputCreateReport {
  name: string

  type: ReportType

  checkerIds?: Maybe<number[]>

  attackTypeIds?: Maybe<number[]>

  directoryIds: number[]

  profileId: number

  recipientEmails: string[]

  format: string

  dataTimeframe: DataTimeframe

  recurrence: string

  timeZone: string
}

export interface InputEditReport {
  id: number

  name: string

  type: ReportType

  checkerIds?: Maybe<number[]>

  attackTypeIds?: Maybe<number[]>

  directoryIds: number[]

  profileId: number

  recipientEmails: string[]

  format: string

  dataTimeframe: DataTimeframe

  recurrence: string

  timeZone: string
}

export interface InputDeleteReport {
  id: number
}

export interface InputCreatePlugin {
  name: string

  parameters: InputPluginParameter[]
}

export interface InputPluginParameter {
  name: string

  valueType: string
}

export interface InputEditPlugin {
  id: number

  name: string

  parameters: InputPluginParameter[]
}

export interface InputDeletePlugin {
  id: number
}

export interface InputCreatePluginConfiguration {
  parametersValues: InputPluginConfigurationParameters[]
}

export interface InputPluginConfigurationParameters {
  name: string

  value: string
}

export interface InputEditPluginConfiguration {
  id: number

  parametersValues: InputPluginConfigurationParameters[]
}

export interface InputDeletePluginConfiguration {
  id: number
}

export interface InputCreateUser {
  surname?: Maybe<string>

  name: string

  email: string

  password: string

  department?: Maybe<string>

  biography?: Maybe<string>

  active: boolean

  provider?: Maybe<AuthProviderName>
}

export interface InputEditUser {
  id: number

  surname?: Maybe<string>

  name?: Maybe<string>

  email?: Maybe<string>

  password?: Maybe<string>

  department?: Maybe<string>

  biography?: Maybe<string>

  active?: Maybe<boolean>

  lockedOut?: Maybe<boolean>
}

export interface InputDeleteUser {
  id: number
}

export interface InputEditUserRoles {
  userId: number

  roles: number[]
}

export interface InputChangeUserPassword {
  oldPassword: string

  newPassword: string
}

export interface InputUserEula {
  eulaVersion: number
}

export interface InputCreateRbacRole {
  name: string

  description: string
}

export interface InputEditRbacRole {
  id: number

  name?: Maybe<string>

  description?: Maybe<string>

  permissions?: Maybe<InputRbacPermission[]>
}

export interface InputRbacPermission {
  entityName: RbacEntityName

  action: RbacAction

  entityIds?: Maybe<string[]>

  dynamidId?: Maybe<string>
}

export interface InputDeleteRbacRole {
  id: number
}

export interface InputCreateProfile {
  name: string

  directories: number[]
}

export interface InputCopyProfile {
  id: number

  name: string

  directories: number[]
}

export interface InputEditProfile {
  id: number

  name: string
}

export interface InputUnstageProfile {
  id: number
}

export interface InputCommitProfile {
  id: number
}

export interface InputDeleteProfile {
  id: number
}

export interface InputSetProfileCheckerOption {
  checkerId: number

  codename: string

  profileId: number

  directoryId?: Maybe<number>

  value: string

  valueType: string
}

export interface InputCreateSearchHistoryEntry {
  expression: string
}

export interface InputDeleteSearchHistoryEntry {
  id: number
}

export interface InputDeleteSearchHistoryEntriesByQuery {
  expressionSearch?: Maybe<string>

  dateStart?: Maybe<string>

  dateEnd?: Maybe<string>
}

export interface InputCreateSearchBookmarkEntry {
  expression: string

  name?: Maybe<string>

  category?: Maybe<string>
}

export interface InputEditSearchBookmarkEntry {
  id: number

  name?: Maybe<string>

  category?: Maybe<string>
}

export interface InputDeleteSearchBookmarkEntry {
  id: number
}

export interface InputEditSearchBookmarkCategory {
  existingCategory: string

  newCategory?: Maybe<string>
}

export interface InputDeleteSearchBookmarkCategory {
  category: string
}

export interface InputAddPageVisited {
  routeName: string

  routePath?: Maybe<string>

  parents?: Maybe<string[]>
}

export interface InputSetProfileAttackTypeOption {
  checkerId: number

  codename: string

  profileId: number

  directoryId?: Maybe<number>

  value: string

  valueType: string
}

export interface InputCreateCheckerOption {
  codename: string

  directoryId: number

  value: string

  valueType: string
}

export interface InputCreateAttackTypeOption {
  codename: string

  directoryId: number

  value: string

  valueType: string
}

export enum AuthProviderName {
  Tenable = 'tenable',
  Ldap = 'ldap',
  Saml = 'saml',
  Tone = 'tone'
}

export enum RbacEntityName {
  Infrastructure = 'infrastructure',
  Directory = 'directory',
  DirectoryRecrawl = 'directoryRecrawl',
  AdObject = 'adObject',
  AdObjectDevianceHistory = 'adObjectDevianceHistory',
  Deviance = 'deviance',
  Event = 'event',
  Checker = 'checker',
  CheckerOption = 'checkerOption',
  Profile = 'profile',
  User = 'user',
  Role = 'role',
  EmailNotifier = 'emailNotifier',
  Plugin = 'plugin',
  PluginConfiguration = 'pluginConfiguration',
  Syslog = 'syslog',
  Alert = 'alert',
  AttackAlert = 'attackAlert',
  Topology = 'topology',
  Score = 'score',
  Attack = 'attack',
  AttackType = 'attackType',
  AttackTypeOption = 'attackTypeOption',
  Dashboard = 'dashboard',
  Widget = 'widget',
  Preference = 'preference',
  SelfUser = 'selfUser',
  ApiKey = 'apiKey',
  ApplicationSetting = 'applicationSetting',
  LockoutPolicy = 'lockoutPolicy',
  LdapConfiguration = 'ldapConfiguration',
  SamlConfiguration = 'samlConfiguration',
  DataCollectionConfiguration = 'dataCollectionConfiguration',
  TenableCloudApiToken = 'tenableCloudApiToken',
  License = 'license',
  AttackPath = 'attackPath',
  ActivityLogs = 'activityLogs',
  SelfActivityLogs = 'selfActivityLogs',
  Relay = 'relay',
  Report = 'report',
  HealthCheck = 'healthCheck',
  Tenant = 'tenant',
  UiAccounts = 'uiAccounts',
  UiAccountsProfiles = 'uiAccountsProfiles',
  UiAccountsRoles = 'uiAccountsRoles',
  UiAccountsUsers = 'uiAccountsUsers',
  UiAlerts = 'uiAlerts',
  UiAlertsArchiveAlert = 'uiAlertsArchiveAlert',
  UiAssetOverview = 'uiAssetOverview',
  UiAttackPath = 'uiAttackPath',
  UiDashboard = 'uiDashboard',
  UiIdentityExplorer = 'uiIdentityExplorer',
  UiIoa = 'uiIoa',
  UiIoaAttackDescription = 'uiIoaAttackDescription',
  UiIoaAttackYaraRules = 'uiIoaAttackYaraRules',
  UiIoaCloseAttack = 'uiIoaCloseAttack',
  UiIoaEditCard = 'uiIoaEditCard',
  UiIoaExport = 'uiIoaExport',
  UiIoaRemoveAttack = 'uiIoaRemoveAttack',
  UiIoaTimeline = 'uiIoaTimeline',
  UiIoe = 'uiIoe',
  UiIoeDeviantElements = 'uiIoeDeviantElements',
  UiIoeDeviantElementsExport = 'uiIoeDeviantElementsExport',
  UiIoeDeviantElementsSetIgnoreDate = 'uiIoeDeviantElementsSetIgnoreDate',
  UiIoeIdentity = 'uiIoeIdentity',
  UiIoeInformations = 'uiIoeInformations',
  UiIoeRecommandations = 'uiIoeRecommandations',
  UiIoeVulnerabilityDetails = 'uiIoeVulnerabilityDetails',
  UiPreferences = 'uiPreferences',
  UiSystem = 'uiSystem',
  UiSystemAbout = 'uiSystemAbout',
  UiSystemConfiguration = 'uiSystemConfiguration',
  UiSystemConfigurationAlertsEmail = 'uiSystemConfigurationAlertsEmail',
  UiSystemConfigurationAlertsSyslog = 'uiSystemConfigurationAlertsSyslog',
  UiSystemConfigurationAppServicesLinkingKey = 'uiSystemConfigurationAppServicesLinkingKey',
  UiSystemConfigurationIoa = 'uiSystemConfigurationIoa',
  UiSystemConfigurationIoaDownloadGpo = 'uiSystemConfigurationIoaDownloadGpo',
  UiSystemConfigurationLdap = 'uiSystemConfigurationLdap',
  UiSystemConfigurationLogs = 'uiSystemConfigurationLogs',
  UiSystemConfigurationPlugin = 'uiSystemConfigurationPlugin',
  UiSystemConfigurationPluginConfiguration = 'uiSystemConfigurationPluginConfiguration',
  UiSystemConfigurationReportingCenter = 'uiSystemConfigurationReportingCenter',
  UiSystemConfigurationSaml = 'uiSystemConfigurationSaml',
  UiSystemConfigurationSecurity = 'uiSystemConfigurationSecurity',
  UiSystemConfigurationSmtpServer = 'uiSystemConfigurationSmtpServer',
  UiSystemConfigurationTenableAccount = 'uiSystemConfigurationTenableAccount',
  UiSystemDirectories = 'uiSystemDirectories',
  UiSystemInfrastructures = 'uiSystemInfrastructures',
  UiSystemRelay = 'uiSystemRelay',
  UiTelemetry = 'uiTelemetry',
  UiTopology = 'uiTopology',
  UiTrailFlow = 'uiTrailFlow',
  UiWeaknessOverview = 'uiWeaknessOverview',
  LicenseFeatureIoe = 'licenseFeatureIoe',
  LicenseFeatureIoa = 'licenseFeatureIoa',
  Unknown = 'unknown'
}

export enum RbacAction {
  Read = 'read',
  Create = 'create',
  Edit = 'edit'
}

export enum CrawlingStatus {
  None = 'none',
  Ongoing = 'ongoing',
  Success = 'success',
  Failure = 'failure'
}

export enum HoneyAccountStatus {
  None = 'none',
  Pending = 'pending',
  Missing = 'missing',
  Misconfigured = 'misconfigured',
  Configured = 'configured'
}

export enum FailureReason {
  Unknown = 'unknown',
  GpoNotFound = 'gpo_not_found',
  DirectoryMissing = 'directory_missing',
  FileNotWritable = 'file_not_writable',
  InsufficientRight = 'insufficient_right',
  ConfigurationNotSync = 'configuration_not_sync',
  EvtListenerNotInstalled = 'evt_listener_not_installed',
  EvtListenerBlockingErrors = 'evt_listener_blocking_errors'
}

export enum ResourceType {
  File = 'file',
  Pdf = 'pdf',
  Mp4 = 'mp4',
  Hyperlink = 'hyperlink'
}

export enum DashboardWidgetType {
  PieChart = 'PieChart',
  LineChart = 'LineChart',
  BarChart = 'BarChart',
  BigNumber = 'BigNumber',
  SecurityCompliance = 'SecurityCompliance'
}

export enum DashboardWidgetDataOptionType {
  User = 'User',
  Deviance = 'Deviance',
  Resolution = 'Resolution',
  Event = 'Event',
  ComplianceScore = 'ComplianceScore',
  Attack = 'Attack'
}

export enum TrustHazardLevel {
  Dangerous = 'dangerous',
  Unknown = 'unknown',
  Regular = 'regular'
}

export enum AttacksSummaryLayout {
  Infrastructures = 'infrastructures',
  Alphabetical = 'alphabetical',
  Criticity = 'criticity'
}

export enum AttacksSummaryChartCardType {
  Donut = 'donut',
  LineChart = 'lineChart'
}

export enum AttackResourceType {
  Infrastructure = 'infrastructure',
  Directory = 'directory',
  Hostname = 'hostname',
  Ip = 'ip'
}

export enum OrderBy {
  Asc = 'asc',
  Desc = 'desc'
}

export enum AttackSourceType {
  Computer = 'computer',
  Dc = 'dc',
  Server = 'server',
  User = 'user'
}

export enum AttackDestinationType {
  Computer = 'computer',
  Dc = 'dc',
  Server = 'server',
  User = 'user'
}

export enum Language {
  Ab = 'ab',
  AaEt = 'aa_ET',
  Ak = 'ak',
  AfZa = 'af_ZA',
  SqAl = 'sq_AL',
  AmEt = 'am_ET',
  AmEr = 'am_ER',
  ArAa = 'ar_AA',
  ArAe = 'ar_AE',
  ArBh = 'ar_BH',
  ArDz = 'ar_DZ',
  ArEg = 'ar_EG',
  ArEh = 'ar_EH',
  ArIq = 'ar_IQ',
  ArJo = 'ar_JO',
  ArKw = 'ar_KW',
  ArLb = 'ar_LB',
  ArLy = 'ar_LY',
  ArMa = 'ar_MA',
  ArMr = 'ar_MR',
  ArOm = 'ar_OM',
  ArPs = 'ar_PS',
  ArQa = 'ar_QA',
  ArSa = 'ar_SA',
  ArSd = 'ar_SD',
  ArSy = 'ar_SY',
  ArTd = 'ar_TD',
  ArTn = 'ar_TN',
  ArYe = 'ar_YE',
  Anu = 'anu',
  HyAm = 'hy_AM',
  HyAmArevela = 'hy_AM_arevela',
  HyAmArevmda = 'hy_AM_arevmda',
  AsIn = 'as_IN',
  AstEs = 'ast_ES',
  AyBo = 'ay_BO',
  AzAzCyrl = 'az_AZ_Cyrl',
  AzAzLatn = 'az_AZ_Latn',
  BaRu = 'ba_RU',
  BalIr = 'bal_IR',
  BhIn = 'bh_IN',
  BiVu = 'bi_VU',
  Bam = 'bam',
  BsBaCyrl = 'bs_BA_Cyrl',
  BsBaLatn = 'bs_BA_Latn',
  BrFr = 'br_FR',
  Bsk = 'bsk',
  MyMm = 'my_MM',
  BeBy = 'be_BY',
  Cal = 'cal',
  CaEs = 'ca_ES',
  Ceb = 'ceb',
  Cha = 'cha',
  Cja = 'cja',
  Cjm = 'cjm',
  Ctd = 'ctd',
  NyMw = 'ny_MW',
  ZhCn = 'zh_CN',
  ZhTw = 'zh_TW',
  ZhHk = 'zh_HK',
  ZhSg = 'zh_SG',
  ZhHkHans = 'zh_HK_Hans',
  Chk = 'chk',
  Cpe = 'cpe',
  CoFr = 'co_FR',
  HrHr = 'hr_HR',
  HrBa = 'hr_BA',
  CsCz = 'cs_CZ',
  EuEs = 'eu_ES',
  BnIn = 'bn_IN',
  BnBd = 'bn_BD',
  BgBg = 'bg_BG',
  CaAd = 'ca_AD',
  Dnj = 'dnj',
  DaDk = 'da_DK',
  PrsAf = 'prs_AF',
  DvIn = 'dv_IN',
  Mis = 'mis',
  NlNl = 'nl_NL',
  EnUs = 'en_US',
  EnGb = 'en_GB',
  En_142 = 'en_142',
  EnCa = 'en_CA',
  EnAu = 'en_AU',
  EnNz = 'en_NZ',
  EnZa = 'en_ZA',
  EnCh = 'en_CH',
  EnHk = 'en_HK',
  EnIn = 'en_IN',
  EnIe = 'en_IE',
  EnSg = 'en_SG',
  EnAe = 'en_AE',
  EnDe = 'en_DE',
  EnNl = 'en_NL',
  EnAt = 'en_AT',
  EnNt = 'en_NT',
  EnCy = 'en_CY',
  EnKe = 'en_KE',
  EnBs = 'en_BS',
  EnMy = 'en_MY',
  EnPk = 'en_PK',
  EnPh = 'en_PH',
  EnLu = 'en_LU',
  EnNg = 'en_NG',
  EnJp = 'en_JP',
  EnTt = 'en_TT',
  EnAw = 'en_AW',
  EnBh = 'en_BH',
  EnEg = 'en_EG',
  EnEs = 'en_ES',
  EnId = 'en_ID',
  EnJo = 'en_JO',
  EnKr = 'en_KR',
  EnKw = 'en_KW',
  EnTh = 'en_TH',
  EnJm = 'en_JM',
  Eo = 'eo',
  EtEe = 'et_EE',
  EeGh = 'ee_GH',
  Eky = 'eky',
  Kyu = 'kyu',
  FoFo = 'fo_FO',
  FjFj = 'fj_FJ',
  FilPh = 'fil_PH',
  FiFi = 'fi_FI',
  NlBe = 'nl_BE',
  FrFr = 'fr_FR',
  FrCa = 'fr_CA',
  FrCh = 'fr_CH',
  FrBe = 'fr_BE',
  FrLu = 'fr_LU',
  FrMa = 'fr_MA',
  FrSn = 'fr_SN',
  FrCm = 'fr_CM',
  Fy = 'fy',
  Fu = 'fu',
  Fat = 'fat',
  GlEs = 'gl_ES',
  KaGe = 'ka_GE',
  Kar = 'kar',
  DeDe = 'de_DE',
  DeAt = 'de_AT',
  DeBe = 'de_BE',
  DeCh = 'de_CH',
  DeLu = 'de_LU',
  DeNl = 'de_NL',
  LbLu = 'lb_LU',
  KlGl = 'kl_GL',
  ElGr = 'el_GR',
  ElCy = 'el_CY',
  GrcGr = 'grc_GR',
  Gil = 'gil',
  Grn = 'grn',
  GuIn = 'gu_IN',
  HtHt = 'ht_HT',
  Cnh = 'cnh',
  HaNg = 'ha_NG',
  HeIl = 'he_IL',
  HiIn = 'hi_IN',
  Hil = 'hil',
  Hmn = 'hmn',
  HmnUs = 'hmn_US',
  HuHu = 'hu_HU',
  Haw = 'haw',
  IsIs = 'is_IS',
  Ig = 'ig',
  Ilo = 'ilo',
  IdId = 'id_ID',
  Ia = 'ia',
  Ie = 'ie',
  Iu = 'iu',
  Ium = 'ium',
  Ik = 'ik',
  Ish = 'ish',
  GaIe = 'ga_IE',
  ItIt = 'it_IT',
  ItCh = 'it_CH',
  JaJp = 'ja_JP',
  JvId = 'jv_ID',
  Ks = 'ks',
  KkKz = 'kk_KZ',
  KgCg = 'kg_CG',
  Kik = 'kik',
  Kig = 'kig',
  RwRw = 'rw_RW',
  Ky = 'ky',
  Rn = 'rn',
  SwKe = 'sw_KE',
  KmKh = 'km_KH',
  KnIn = 'kn_IN',
  KokIn = 'kok_IN',
  Tlh = 'tlh',
  KoKr = 'ko_KR',
  Kos = 'kos',
  KuTr = 'ku_TR',
  Kmr = 'kmr',
  Ckb = 'ckb',
  KuIq = 'ku_IQ',
  LoLa = 'lo_LA',
  La = 'la',
  LvLv = 'lv_LV',
  LnCg = 'ln_CG',
  LtLt = 'lt_LT',
  MfeMu = 'mfe_MU',
  MkMk = 'mk_MK',
  MgMg = 'mg_MG',
  MsMy = 'ms_MY',
  MsSg = 'ms_SG',
  MlIn = 'ml_IN',
  MtMt = 'mt_MT',
  MiNz = 'mi_NZ',
  MrIn = 'mr_IN',
  Mah = 'mah',
  MnMn = 'mn_MN',
  SlaMe = 'sla_ME',
  MoMd = 'mo_MD',
  NaNr = 'na_NR',
  Nv = 'nv',
  NdZw = 'nd_ZW',
  NeNp = 'ne_NP',
  NoNo = 'no_NO',
  NbNo = 'nb_NO',
  Niu = 'niu',
  NnNo = 'nn_NO',
  NsoZa = 'nso_ZA',
  Nus = 'nus',
  OcFr = 'oc_FR',
  OrIn = 'or_IN',
  OmEt = 'om_ET',
  Ota = 'ota',
  Pag = 'pag',
  Pam = 'pam',
  Pau = 'pau',
  Ps = 'ps',
  PsPk = 'ps_PK',
  FaIr = 'fa_IR',
  Pon = 'pon',
  PlPl = 'pl_PL',
  PtPt = 'pt_PT',
  PtBr = 'pt_BR',
  PtMz = 'pt_MZ',
  PtAo = 'pt_AO',
  PaPa = 'pa_PA',
  PaIn = 'pa_IN',
  PaPk = 'pa_PK',
  QuPe = 'qu_PE',
  Qya = 'qya',
  XrMm = 'xr_MM',
  Rar = 'rar',
  RmCh = 'rm_CH',
  RoRo = 'ro_RO',
  RoMd = 'ro_MD',
  RuRu = 'ru_RU',
  RuAm = 'ru_AM',
  RuAz = 'ru_AZ',
  RuGe = 'ru_GE',
  RuMd = 'ru_MD',
  RuUa = 'ru_UA',
  SmWs = 'sm_WS',
  Sg = 'sg',
  SaIn = 'sa_IN',
  ScIt = 'sc_IT',
  GdGb = 'gd_GB',
  St = 'st',
  TnZa = 'tn_ZA',
  SrYu = 'sr_YU',
  SrRsCyrl = 'sr_RS_Cyrl',
  SrMeCyrl = 'sr_ME_Cyrl',
  SrMeLatn = 'sr_ME_Latn',
  SrRsLatn = 'sr_RS_Latn',
  Sn = 'sn',
  Sjn = 'sjn',
  SdPk = 'sd_PK',
  SiLk = 'si_LK',
  Ss = 'ss',
  SkSk = 'sk_SK',
  SlSi = 'sl_SI',
  Snk = 'snk',
  SoSo = 'so_SO',
  DsbDe = 'dsb_DE',
  HsbDe = 'hsb_DE',
  EsEs = 'es_ES',
  EsAr = 'es_AR',
  EsBo = 'es_BO',
  EsCl = 'es_CL',
  EsCo = 'es_CO',
  EsCr = 'es_CR',
  EsCu = 'es_CU',
  EsDo = 'es_DO',
  EsEc = 'es_EC',
  EsSv = 'es_SV',
  EsGt = 'es_GT',
  EsHn = 'es_HN',
  Es_419 = 'es_419',
  EsMx = 'es_MX',
  EsNi = 'es_NI',
  EsPa = 'es_PA',
  EsPy = 'es_PY',
  EsPe = 'es_PE',
  EsPr = 'es_PR',
  EsUy = 'es_UY',
  EsUs = 'es_US',
  EsVe = 'es_VE',
  Es_001 = 'es_001',
  EsNt = 'es_NT',
  Swa = 'swa',
  SwSo = 'sw_SO',
  SwTz = 'sw_TZ',
  SwUg = 'sw_UG',
  SvSe = 'sv_SE',
  SvFi = 'sv_FI',
  ApdSd = 'apd_SD',
  ApdSdLatn = 'apd_SD_Latn',
  Sun = 'sun',
  SyrTr = 'syr_TR',
  TlPh = 'tl_PH',
  TgTj = 'tg_TJ',
  TaIn = 'ta_IN',
  TaSg = 'ta_SG',
  TaLk = 'ta_LK',
  TtRu = 'tt_RU',
  TeIn = 'te_IN',
  TetId = 'tet_ID',
  TetTl = 'tet_TL',
  ThTh = 'th_TH',
  Bo = 'bo',
  Ti = 'ti',
  TirEr = 'tir_ER',
  TirEt = 'tir_ET',
  ToTo = 'to_TO',
  TsZa = 'ts_ZA',
  TnBw = 'tn_BW',
  Tpi = 'tpi',
  Tvl = 'tvl',
  TrTr = 'tr_TR',
  TkTm = 'tk_TM',
  Tkl = 'tkl',
  Tw = 'tw',
  Ty = 'ty',
  UkUa = 'uk_UA',
  UrIn = 'ur_IN',
  UrPk = 'ur_PK',
  UgCn = 'ug_CN',
  UzUzCyrl = 'uz_UZ_Cyrl',
  UzUzLatn = 'uz_UZ_Latn',
  UzAf = 'uz_AF',
  CyGb = 'cy_GB',
  ViVn = 'vi_VN',
  Vo = 'vo',
  Wo = 'wo',
  War = 'war',
  XhZa = 'xh_ZA',
  XzAf = 'xz_AF',
  Yao = 'yao',
  Yap = 'yap',
  Yi = 'yi',
  YiIl = 'yi_IL',
  YiUs = 'yi_US',
  YoNg = 'yo_NG',
  Czt = 'czt',
  Zom = 'zom',
  ZuZa = 'zu_ZA',
  Kun = 'kun',
  Lua = 'lua',
  ScoGb = 'sco_GB',
  ScoIe = 'sco_IE',
  FrCg = 'fr_CG',
  ZhYue = 'zh_YUE',
  Lug = 'lug',
  Ogo = 'ogo',
  Bbc = 'bbc',
  Ksw = 'ksw',
  Cfm = 'cfm',
  Cmn = 'cmn',
  Goyu = 'goyu',
  Aii = 'aii',
  Cld = 'cld',
  Pdc = 'pdc',
  Ziw = 'ziw',
  Pap = 'pap',
  EnCn = 'en_CN',
  Din = 'din',
  FrTn = 'fr_TN',
  Fur = 'fur',
  EnEu = 'en_EU',
  Mas = 'mas',
  EnIl = 'en_IL',
  EnQa = 'en_QA',
  Pis = 'pis',
  Lus = 'lus',
  Hif = 'hif',
  Zyp = 'zyp',
  Sez = 'sez',
  Clt = 'clt',
  Mwq = 'mwq',
  Mrh = 'mrh',
  Sdh = 'sdh',
  Tcz = 'tcz',
  Rtm = 'rtm',
  Bfa = 'bfa',
  Shn = 'shn',
  Mnw = 'mnw',
  Toq = 'toq',
  Ach = 'ach',
  Jam = 'jam',
  Rom = 'rom',
  Chr = 'chr',
  Bsq = 'bsq',
  Cpf = 'cpf',
  KgpBr = 'kgp_BR',
  YrlBr = 'yrl_BR',
  Bem = 'bem',
  TsZaChangana = 'ts_ZA_changana',
  Luo = 'luo',
  Xsm = 'xsm',
  Mni = 'mni',
  Nag = 'nag',
  Quc = 'quc',
  Ton = 'ton',
  Bdx = 'bdx',
  Doi = 'doi',
  Mai = 'mai',
  Sat = 'sat',
  Daw = 'daw',
  Krj = 'krj',
  Fr_001 = 'fr_001',
  Pt_001 = 'pt_001',
  EnSa = 'en_SA',
  Mnk = 'mnk',
  Kjb = 'kjb',
  Keo = 'keo',
  Kjg = 'kjg',
  Wbh = 'wbh',
  EnMa = 'en_MA',
  EnBe = 'en_BE',
  EnCz = 'en_CZ',
  EnHu = 'en_HU',
  EnSk = 'en_SK',
  EnFi = 'en_FI',
  EnHr = 'en_HR',
  EnRo = 'en_RO',
  EnSi = 'en_SI',
  EnRs = 'en_RS',
  EnUa = 'en_UA',
  EnPt = 'en_PT',
  EnDk = 'en_DK',
  EnFr = 'en_FR',
  EnIt = 'en_IT',
  EnMx = 'en_MX',
  EnNo = 'en_NO',
  EnPl = 'en_PL',
  EnRu = 'en_RU',
  EnSe = 'en_SE',
  EnOm = 'en_OM',
  MsAr = 'ms_AR',
  RuIl = 'ru_IL',
  Ber = 'ber',
  HiLatn = 'hi_Latn'
}

export enum DataCollectionNotWorkingReason {
  ObsoleteLicense = 'OBSOLETE_LICENSE',
  DatacollectionapitokenRetrievalFailure = 'DATACOLLECTIONAPITOKEN_RETRIEVAL_FAILURE',
  AuthenticationFailure = 'AUTHENTICATION_FAILURE'
}

export enum AttackPathDirection {
  From = 'from',
  To = 'to'
}

export enum AttackPathAdObjectType {
  Device = 'Device',
  Domain = 'Domain',
  DomainController = 'DomainController',
  EnterpriseNtAuthStore = 'EnterpriseNtAuthStore',
  GpoFile = 'GPOFile',
  GpoFolder = 'GPOFolder',
  Group = 'Group',
  GroupManagedServiceAccount = 'GroupManagedServiceAccount',
  GroupPolicy = 'GroupPolicy',
  OrganizationalUnit = 'OrganizationalUnit',
  Other = 'Other',
  PkiCertificateTemplate = 'PkiCertificateTemplate',
  ReadOnlyDomainController = 'ReadOnlyDomainController',
  SpecialIdentity = 'SpecialIdentity',
  UnresolvedSecurityPrincipal = 'UnresolvedSecurityPrincipal',
  User = 'User'
}

export enum AttackPathRelationType {
  HasControlRight = 'HasControlRight',
  AddAuthCa = 'AddAuthCA',
  AddKeyCredentials = 'AddKeyCredentials',
  AddMember = 'AddMember',
  AddRcm = 'AddRCM',
  AddUserScript = 'AddUserScript',
  AddAltSecurityIdentities = 'AddAltSecurityIdentities',
  AdminTo = 'AdminTo',
  AllowedToAct = 'AllowedToAct',
  AllowedToDelegate = 'AllowedToDelegate',
  BelongsToGpo = 'BelongsToGpo',
  CanLinkGpo = 'CanLinkGPO',
  DcSync = 'DCSync',
  EditCertTemplate = 'EditCertTemplate',
  GpoControl = 'GPOControl',
  GrantAllowedToAct = 'GrantAllowedToAct',
  GrantAllowedToDelegate = 'GrantAllowedToDelegate',
  GrantReadGmsaPassword = 'GrantReadGMSAPassword',
  GrantRodcManage = 'GrantRODCManage',
  HasSession = 'HasSession',
  HasSidHistory = 'HasSIDHistory',
  ImplicitTakeover = 'ImplicitTakeover',
  InheritGpo = 'InheritGPO',
  LinkedGpo = 'LinkedGPO',
  MemberOf = 'MemberOf',
  Owns = 'Owns',
  ReadGmsaPassword = 'ReadGMSAPassword',
  ReadLapsPassword = 'ReadLAPSPassword',
  ResetPassword = 'ResetPassword',
  RodcManage = 'RODCManage',
  WriteDacl = 'WriteDACL',
  WriteOwner = 'WriteOwner',
  Fake = 'Fake'
}

export enum AttackPathTruncationType {
  Cluster = 'Cluster',
  Omit = 'Omit'
}

export enum EmailInputType {
  Deviances = 'deviances',
  Attacks = 'attacks',
  HealthChecks = 'healthChecks'
}

export enum ReportType {
  Deviances = 'deviances',
  Attacks = 'attacks'
}

export enum DataTimeframe {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter'
}

export enum SyslogInputType {
  AdObjectChanges = 'adObjectChanges',
  Deviances = 'deviances',
  Attacks = 'attacks',
  HealthChecks = 'healthChecks'
}

export enum LicenseType {
  Poc = 'POC',
  Prod = 'PROD',
  Internal = 'INTERNAL',
  Nfr = 'NFR'
}

export enum LicenseFeature {
  Ioe = 'ioe',
  Ioa = 'ioa',
  IoaPreview = 'ioaPreview'
}

export enum UserTraceLogSource {
  Gui = 'gui',
  Api = 'api',
  Other = 'other'
}

export enum UserTraceLogType {
  LoginSucceeded = 'loginSucceeded',
  LoginFailed = 'loginFailed',
  Logout = 'logout',
  InfrastructureCreated = 'infrastructureCreated',
  InfrastructureDeleted = 'infrastructureDeleted',
  InfrastructureModified = 'infrastructureModified',
  DirectoryCreated = 'directoryCreated',
  DirectoryDeleted = 'directoryDeleted',
  ConfigurationChanged = 'configurationChanged',
  ReportConfigurationCreated = 'reportConfigurationCreated',
  RoleCreated = 'roleCreated',
  RoleDeleted = 'roleDeleted',
  UserCreated = 'userCreated',
  UserDeleted = 'userDeleted',
  ApiCall = 'apiCall',
  UnauthenticatedApiCall = 'unauthenticatedApiCall',
  PageVisited = 'pageVisited',
  ProfileCreated = 'profileCreated',
  ProfileDeleted = 'profileDeleted',
  ProfileCommited = 'profileCommited',
  ProfileUnstaged = 'profileUnstaged',
  DeleteAllTraces = 'deleteAllTraces'
}

export enum HealthCheckStatus {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Unknown = 'UNKNOWN',
  Warning = 'WARNING'
}

export enum ReflectedPromiseStatus {
  Fulfilled = 'fulfilled',
  Rejected = 'rejected'
}

export enum RequestErrorCode {
  AccountLockedOut = 'ACCOUNT_LOCKED_OUT',
  InvalidCredentials = 'INVALID_CREDENTIALS',
  InvalidRbacRules = 'INVALID_RBAC_RULES',
  LicenseDowngradeNotAllowed = 'LICENSE_DOWNGRADE_NOT_ALLOWED',
  InvalidLinkingKey = 'INVALID_LINKING_KEY',
  InvalidSearchNodeIdBecauseResponseWouldBeTooBig = 'INVALID_SEARCH_NODE_ID_BECAUSE_RESPONSE_WOULD_BE_TOO_BIG',
  InvalidSearchBecauseResponseTakesTooMuchTime = 'INVALID_SEARCH_BECAUSE_RESPONSE_TAKES_TOO_MUCH_TIME',
  RbacRestriction = 'RBAC_RESTRICTION',
  LastAdminRemoval = 'LAST_ADMIN_REMOVAL',
  ImmutableTenableProfile = 'IMMUTABLE_TENABLE_PROFILE',
  ImmutableAdminRole = 'IMMUTABLE_ADMIN_ROLE',
  NonModifiableExternalAccountRoles = 'NON_MODIFIABLE_EXTERNAL_ACCOUNT_ROLES',
  InsufficientLicenseUsage = 'INSUFFICIENT_LICENSE_USAGE',
  Unknown = 'UNKNOWN',
  NotFound = 'NOT_FOUND'
}

export enum RequestErrorDetailsType {
  MissingRelation = 'MISSING_RELATION',
  MissingPermission = 'MISSING_PERMISSION'
}

export enum CheckerType {
  Exposure = 'exposure',
  Attack = 'attack'
}

export enum Criticity {
  Critical = 'critical',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Unknown = 'unknown'
}

export enum CheckerRemediationCostLevel {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Unknown = 'unknown'
}

export enum AttacksSummaryPeriod {
  Hour = 'hour',
  Day = 'day',
  Month = 'month',
  Year = 'year'
}

// ====================================================
// Types
// ====================================================

export interface Query {
  whoAmI: WhoAmI

  users: PaginatedUsers

  profiles: Profile[]

  rbacProfiles: MaybeGrantedProfiles

  rbacApiKey: MaybeGrantedApiKey

  rbacInfrastructures: MaybeGrantedPaginatedInfrastructures

  rbacDirectories: MaybeGrantedPaginatedDirectories

  searchHoneyAccountCandidates: SearchHoneyAccountCandidates[]

  honeyAccountConfigurationScript: HoneyAccountConfigurationScript

  rbacCheckers: MaybeGrantedCheckers

  checkerOptions: CheckerOption[]

  rbacReasons: MaybeGrantedReasons

  categories: Category

  events: Event[]

  eventById: Event

  searchHistory: PaginatedSearchHistoryEntries

  searchBookmarks: SearchBookmarkEntry[]

  rbacDeviantAdObjects: MaybeGrantedPaginatedAdObject

  rbacAdObjectIncriminatingAttributes: MaybeGrantedPaginatedIncriminatingAttributes

  rbacEventIncriminatingAttributes: MaybeGrantedPaginatedIncriminatingAttributes

  rbacDeviances: MaybeGrantedDeviances[]

  rbacDashboards: MaybeGrantedDashboards

  rbacTopology: MaybeGrantedTopology

  rbacTopologyDomainDetails: MaybeGrantedTopologyDomainDetails

  rbacAttackTypes: MaybeGrantedAttackTypes

  rbacAttacksSummary: MaybeGrantedAttacksSummary

  attacksStats: DashboardWidgetOptionsSerieStatAttack[]

  attacksStatsCounts: DashboardWidgetOptionsSerieStatAttackCounts[]

  attackTypeOptions: AttackTypeOption[]

  rbacAttacks: MaybeGrantedAttacks

  rbacAttacksCount: MaybeGrantedAttacksCount

  attacksRemoveFilters: AttacksRemoveFilter[]

  attackTypeConfiguration: AttackTypeConfiguration

  ioaConfiguration: IoaConfiguration

  rbacApplicationSettings: MaybeGrantedApplicationSettings

  applicationConfiguration: ApplicationConfiguration

  rbacDataCollectionStatus: MaybeGrantedDataCollectionStatus

  rbacLockoutPolicy: MaybeGrantedLockoutPolicy

  rbacLDAPConfiguration: MaybeGrantedLdapConfiguration

  rbacSAMLConfiguration: MaybeGrantedSamlConfiguration

  rbacRelayLinkingKey: MaybeGrantedRelayLinkingKey

  rbacRelays: MaybeGrantedRelays

  rbacAttackPathHealthCheck: boolean

  rbacAttackPath: MaybeGrantedAttackPath

  rbacAttackPathTwoNodes: MaybeGrantedAttackPath

  rbacAttackPathSearch: MaybeGrantedAttackPathNode

  rbacAttackPathTier0Objects: MaybeGrantedPaginatedAttackPathTier0Objects

  rbacAttackPathAllTier0Objects: MaybeGrantedAttackPathTier0Objects

  rbacAttackPathAccountsWithTier0AttackPath: MaybeGrantedPaginatedAttackPathAccountsWithTier0AttackPath

  rbacRoles: MaybeGrantedRbacRoles

  rbacUserCreationDefaultsRbac: MaybeGrantedRbacRoles

  rbacPermissions: RbacPermission[]

  entitiesRbac: RbacEntity[]

  rbacActions: RbacAction[]

  rbacSyslogs: MaybeGrantedPaginatedSyslogs

  rbacEmails: MaybeGrantedPaginatedEmails

  rbacReports: MaybeGrantedPaginatedReports

  rbacReportAccessToken: MaybeGrantedReportAccessToken

  rbacPlugins: MaybeGrantedPlugins

  rbacPluginConfigurations: MaybeGrantedPluginConfigurations

  rbacAlertsIoE: MaybeGrantedPaginatedAlertsIoE

  rbacAlertsIoA: MaybeGrantedPaginatedAlertsIoA

  rbacAlertsIoACount: MaybeGrantedAlertsIoACount

  preferences: Preferences

  rbacLicense: MaybeGrantedLicense

  rbacUsersTraces: MaybeGrantedPaginatedUserTrace

  tracesFiltersData: TracesFiltersData

  healthChecksDomainStatus: PaginatedHealthChecksDomainStatus

  healthChecksDomainStatusDetails: HealthCheckDomainStatusDetails

  healthChecksPlatformStatus: PaginatedHealthChecksPlatformStatus

  healthChecksPlatformStatusDetails: HealthCheckPlatformStatusDetails

  healthChecksGlobalStatus: HealthCheckGlobalStatus

  healthChecksDetailedGlobalStatus: HealthCheckDetailedGlobalStatus

  healthChecksTemplatesNames: HealthChecksTemplatesName[]

  azureADSupportConfiguration: AzureAdSupportConfiguration
}

export interface WhoAmI {
  id: number

  surname?: Maybe<string>

  identifier: string

  provider: AuthProviderName

  name: string

  email?: Maybe<string>

  department?: Maybe<string>

  eulaVersion: number

  biography?: Maybe<string>

  roles: RbacRole[]
}

export interface RbacRole {
  id: number

  name: string

  description: string

  permissions: RbacPermission[]
}

export interface RbacPermission {
  entityName: RbacEntityName

  action: RbacAction

  entityIds?: Maybe<string[]>

  dynamicId?: Maybe<string>
}

export interface PaginatedUsers {
  node: User[]

  pagination: Pagination
}

export interface User {
  id: number

  surname?: Maybe<string>

  identifier: string

  provider: AuthProviderName

  name: string

  email: string

  department?: Maybe<string>

  eulaVersion: number

  biography?: Maybe<string>

  active: boolean

  roles?: Maybe<number[]>

  lockedOut?: Maybe<boolean>

  rbacRoles?: Maybe<MaybeGrantedRbacRoles[]>
}

export interface MaybeGrantedRbacRoles {
  node?: Maybe<RbacRole[]>

  rbacCapability: RbacCapability
}

export interface RbacCapability {
  isGranted?: Maybe<boolean>

  isFound?: Maybe<boolean>

  message?: Maybe<string>

  rbacEntityName?: Maybe<RbacEntityName>
}

export interface Pagination {
  page?: Maybe<number>

  perPage?: Maybe<number>

  totalCount?: Maybe<number>
}

export interface Profile {
  id: number

  name: string

  deleted: boolean

  dirty: boolean

  hasEverBeenCommitted: boolean
}

export interface MaybeGrantedProfiles {
  node?: Maybe<Profile[]>

  rbacCapability: RbacCapability
}

export interface MaybeGrantedApiKey {
  node?: Maybe<ApiKey>

  rbacCapability: RbacCapability
}

export interface ApiKey {
  key: string
}

export interface MaybeGrantedPaginatedInfrastructures {
  node?: Maybe<PaginatedInfrastructures>

  rbacCapability: RbacCapability
}

export interface PaginatedInfrastructures {
  node: Infrastructure[]

  pagination: Pagination
}

export interface Infrastructure {
  id: number

  name: string

  login: string

  directories: Directory[]
}

export interface Directory {
  id: number

  name: string

  ip: string

  type: string

  ldapPort: number

  globalCatalogPort: number

  smbPort: number

  dns?: Maybe<string>

  ldapCrawlingStatus: CrawlingStatus

  sysvolCrawlingStatus: CrawlingStatus

  honeyAccountAdObjectId?: Maybe<number>

  honeyAccountDistinguishedName?: Maybe<string>

  honeyAccountConfigurationStatus?: Maybe<HoneyAccountStatus>

  infrastructureId: number

  infrastructure: Infrastructure

  relayId?: Maybe<number>

  sensitiveDataCollectionIsEnabled: boolean

  sensitiveDataCollectionStatus: CrawlingStatus

  ioaProcedureInstalled: boolean

  ioaProcedureFailureReason?: Maybe<FailureReason>

  ioaProcedureFailureDescription?: Maybe<string>

  directoryUuid?: Maybe<string>

  event: Event
}

export interface Event {
  id: string

  date: string

  type?: Maybe<string>

  adObjectId: number

  directoryId: number

  adObject: AdObject

  directory: Directory

  rbacDeviances: MaybeGrantedDeviances

  rbacReasons: MaybeGrantedReasons
}

export interface AdObject {
  id: number

  type: string

  objectId: string

  directoryId: number

  reasons: number[]

  objectAttributes: ObjectAttribute[]

  changes: Change[]

  current?: Maybe<AdObject>
}

export interface ObjectAttribute {
  name: string

  value: string

  valueType: string

  translations?: Maybe<string[]>
}

export interface Change {
  attributeName: string

  valueType: string

  values: ChangeValues
}

export interface ChangeValues {
  before?: Maybe<string>

  after?: Maybe<string>

  current?: Maybe<string>
}

export interface MaybeGrantedDeviances {
  node?: Maybe<Deviance[]>

  rbacCapability: RbacCapability
}

export interface Deviance {
  id: number

  eventDate: string

  createdEventId: string

  resolvedEventId?: Maybe<string>

  devianceProviderId?: Maybe<string>

  ignoreUntil?: Maybe<string>

  resolvedAt?: Maybe<string>

  checkerId: number

  directoryId: number

  profileId: number

  adObjectId: number

  reasonId: number

  event: Event

  profile: Profile

  adObject: AdObject

  reason: Reason
}

export interface Reason {
  id: number

  codename: string

  name?: Maybe<string>

  description?: Maybe<string>
}

export interface MaybeGrantedReasons {
  node?: Maybe<Reason[]>

  rbacCapability: RbacCapability
}

export interface MaybeGrantedPaginatedDirectories {
  node?: Maybe<PaginatedDirectories>

  rbacCapability: RbacCapability
}

export interface PaginatedDirectories {
  node: Directory[]

  pagination: Pagination
}

export interface SearchHoneyAccountCandidates {
  name: string
}

export interface HoneyAccountConfigurationScript {
  script: string
}

export interface MaybeGrantedCheckers {
  node?: Maybe<Checker[]>

  rbacCapability: RbacCapability
}

export interface Checker {
  id: number

  codename: string

  remediationCost: number

  name: string

  description?: Maybe<string>

  execSummary?: Maybe<string>

  vulnerabilityDetail: VulnerabilityDetail

  resources: Resource[]

  attackerKnownTools: AttackerKnownTool[]

  recommendation: Recommendation

  category: Category

  options: CheckerOption[]

  isDeviant: boolean

  directoryIds: number[]

  rbacReasons: MaybeGrantedReasons

  rbacDeviances: MaybeGrantedDeviances[]
}

export interface VulnerabilityDetail {
  detail?: Maybe<string>
}

export interface Resource {
  name: string

  url: string

  type: ResourceType
}

export interface AttackerKnownTool {
  name: string

  url?: Maybe<string>

  author?: Maybe<string>
}

export interface Recommendation {
  name?: Maybe<string>

  description?: Maybe<string>

  execSummary?: Maybe<string>

  detail?: Maybe<string>

  resources?: Maybe<Resource[]>
}

export interface Category {
  id: number

  name: string
}

export interface CheckerOption {
  id: number

  name: string

  description?: Maybe<string>

  codename: string

  profileId: number

  checkerId: number

  directoryId?: Maybe<number>

  value: string

  valueType: string

  translations?: Maybe<string[]>

  staged: boolean

  checker: Checker
}

export interface PaginatedSearchHistoryEntries {
  node: SearchHistoryEntry[]

  pagination: Pagination
}

export interface SearchHistoryEntry {
  id: number

  expression: string

  date: string
}

export interface SearchBookmarkEntry {
  id: number

  expression: string

  name?: Maybe<string>

  category?: Maybe<string>
}

export interface MaybeGrantedPaginatedAdObject {
  node?: Maybe<PaginatedAdObject>

  rbacCapability: RbacCapability
}

export interface PaginatedAdObject {
  node: AdObject[]

  pagination: Pagination
}

export interface MaybeGrantedPaginatedIncriminatingAttributes {
  node?: Maybe<PaginatedIncriminatingAttributes>

  rbacCapability: RbacCapability
}

export interface PaginatedIncriminatingAttributes {
  node: IncriminatingAttribute[]

  pagination: Pagination
}

export interface IncriminatingAttribute {
  id: number

  eventDate: string

  createdEventId: string

  resolvedEventId?: Maybe<string>

  devianceProviderId?: Maybe<string>

  ignoreUntil?: Maybe<string>

  resolvedAt?: Maybe<string>

  attributes: IncriminatingAttributeAttribute[]

  description: IncriminatingAttributeDescription

  checkerId: number

  directoryId: number

  profileId: number

  adObjectId: number

  reasonId: number
}

export interface IncriminatingAttributeAttribute {
  name: string

  value: string
}

export interface IncriminatingAttributeDescription {
  template: string

  replacements: IncriminatingAttributeDescriptionReplacement[]
}

export interface IncriminatingAttributeDescriptionReplacement {
  name: string

  valueType: string
}

export interface MaybeGrantedDashboards {
  node?: Maybe<Dashboard[]>

  rbacCapability: RbacCapability
}

export interface Dashboard {
  instanceName: string

  id: number

  name: string

  order: number

  rbacWidgets: MaybeGrantedWidgets
}

export interface MaybeGrantedWidgets {
  node?: Maybe<DashboardWidget[]>

  rbacCapability: RbacCapability
}

export interface DashboardWidget {
  id: number

  posX: number

  posY: number

  width: number

  height: number

  title: string

  options: DashboardWidgetOptions
}

export interface DashboardWidgetOptions {
  type: DashboardWidgetType

  series: DashboardWidgetOptionsSerie[]
}

export interface DashboardWidgetOptionsSerie {
  dataOptions: DashboardWidgetOptionsSerieDataOptions

  displayOptions: DashboardWidgetOptionsSerieDisplayOptions

  stats: DashboardWidgetOptionsSerieStat
}

export interface DashboardWidgetOptionsSerieDataOptionsUser {
  type: DashboardWidgetDataOptionType

  directoryIds: number[]

  profileId?: Maybe<number>

  active?: Maybe<boolean>

  duration: number

  interval?: Maybe<string>
}

export interface DashboardWidgetOptionsSerieDataOptionsDeviance {
  type: DashboardWidgetDataOptionType

  directoryIds: number[]

  checkerIds: number[]

  reasonIds: number[]

  profileId?: Maybe<number>

  duration: number

  interval?: Maybe<string>
}

export interface DashboardWidgetOptionsSerieDataOptionsResolution {
  type: DashboardWidgetDataOptionType

  directoryIds: number[]

  checkerIds: number[]

  reasonIds: number[]

  profileId?: Maybe<number>

  duration: number

  interval?: Maybe<string>
}

export interface DashboardWidgetOptionsSerieDataOptionsEvent {
  type: DashboardWidgetDataOptionType

  directoryIds: number[]

  eventType: string

  duration: number

  interval?: Maybe<string>
}

export interface DashboardWidgetOptionsSerieDataOptionsComplianceScore {
  type: DashboardWidgetDataOptionType

  directoryIds: number[]

  checkerIds: number[]

  reasonIds: number[]

  profileId?: Maybe<number>

  duration: number

  interval?: Maybe<string>
}

export interface DashboardWidgetOptionsSerieDisplayOptionsBarChart {
  type: DashboardWidgetType

  label?: Maybe<string>
}

export interface DashboardWidgetOptionsSerieDisplayOptionsLineChart {
  type: DashboardWidgetType

  label?: Maybe<string>
}

export interface DashboardWidgetOptionsSerieDisplayOptionsPieChart {
  type: DashboardWidgetType

  label?: Maybe<string>
}

export interface DashboardWidgetOptionsSerieDisplayOptionsBigNumber {
  type: DashboardWidgetType

  label?: Maybe<string>
}

export interface DashboardWidgetOptionsSerieDisplayOptionsSecurityCompliance {
  type: DashboardWidgetType

  label?: Maybe<string>
}

export interface DashboardWidgetOptionsSerieStatUser {
  type: DashboardWidgetDataOptionType

  timestampStart: string

  timestampEnd: string

  interval: string

  directoryIds: number[]

  profileId?: Maybe<number>

  active?: Maybe<boolean>

  label: string

  data: DashboardWidgetOptionsSerieStatDataPointCount[]
}

export interface DashboardWidgetOptionsSerieStatDataPointCount {
  timestamp: string

  count: number
}

export interface DashboardWidgetOptionsSerieStatDeviance {
  type: DashboardWidgetDataOptionType

  timestampStart: string

  timestampEnd: string

  interval: string

  directoryIds: number[]

  checkerIds: number[]

  reasonIds: number[]

  profileId?: Maybe<number>

  label: string

  data: DashboardWidgetOptionsSerieStatDataPointCount[]
}

export interface DashboardWidgetOptionsSerieStatResolution {
  type: DashboardWidgetDataOptionType

  timestampStart: string

  timestampEnd: string

  interval: string

  directoryIds: number[]

  checkerIds: number[]

  reasonIds: number[]

  profileId?: Maybe<number>

  label: string

  data: DashboardWidgetOptionsSerieStatDataPointCount[]
}

export interface DashboardWidgetOptionsSerieStatEvent {
  type: DashboardWidgetDataOptionType

  timestampStart: string

  timestampEnd: string

  interval: string

  directoryIds: number[]

  eventType: string

  label: string

  data: DashboardWidgetOptionsSerieStatDataPointCount[]
}

export interface DashboardWidgetOptionsSerieStatComplianceScore {
  type: DashboardWidgetDataOptionType

  timestampStart: string

  timestampEnd: string

  interval: string

  directoryIds: number[]

  checkerIds: number[]

  reasonIds: number[]

  profileId?: Maybe<number>

  label: string

  data: DashboardWidgetOptionsSerieStatDataPointValue[]
}

export interface DashboardWidgetOptionsSerieStatDataPointValue {
  timestamp: string

  value: number
}

export interface DashboardWidgetOptionsSerieStatAttack {
  type: DashboardWidgetDataOptionType

  timestampStart: string

  timestampEnd: string

  interval: string

  directoryIds: number[]

  attackTypeIds: number[]

  profileId?: Maybe<number>

  label: string

  data: DashboardWidgetOptionsSerieStatDataPointCount[]

  isClosed?: Maybe<boolean>
}

export interface DashboardWidgetOptionsSerieStatAttackCounts {
  type: DashboardWidgetDataOptionType

  timestampStart: string

  timestampEnd: string

  directoryIds: number[]

  attackTypeIds: number[]

  profileId?: Maybe<number>

  label: string

  data: DashboardWidgetOptionsSerieStatDataPointAttackCount[]

  isClosed?: Maybe<boolean>
}

export interface DashboardWidgetOptionsSerieStatDataPointAttackCount {
  attackTypeId: number

  count: number
}

export interface MaybeGrantedTopology {
  node?: Maybe<Topology>

  rbacCapability: RbacCapability
}

export interface Topology {
  infrastructures: TopologyInfrastructure[]

  trusts: TopologyTrust[]
}

export interface TopologyInfrastructure {
  uid: string

  name: string

  known: boolean

  directories: TopologyDirectory[]
}

export interface TopologyDirectory {
  id?: Maybe<number>

  uid: string

  name: string

  known: boolean
}

export interface TopologyTrust {
  from: string

  to: string

  hazardLevel: TrustHazardLevel

  attributes: string[]
}

export interface MaybeGrantedTopologyDomainDetails {
  node?: Maybe<DashboardWidgetOptionsSerieStatComplianceScore>

  rbacCapability: RbacCapability
}

export interface MaybeGrantedAttackTypes {
  node?: Maybe<AttackType[]>

  rbacCapability: RbacCapability
}

export interface AttackType {
  id: number

  name: string

  description: string

  mitreAttackDescription: string

  resources: Resource[]

  yaraRules: string

  vectorTemplate: string

  vectorTemplateReplacements: Maybe<AttackTypeVectorTemplateReplacements>[]

  options: AttackTypeOption[]
}

export interface AttackTypeVectorTemplateReplacements {
  name: string

  valueType: string
}

export interface AttackTypeOption {
  id: number

  name: string

  description?: Maybe<string>

  codename: string

  profileId: number

  attackTypeId: number

  directoryId?: Maybe<number>

  value: string

  valueType: string

  translations?: Maybe<string[]>

  staged: boolean

  attackType: AttackType
}

export interface MaybeGrantedAttacksSummary {
  node?: Maybe<AttacksSummary>

  rbacCapability: RbacCapability
}

export interface AttacksSummary {
  layout?: Maybe<AttacksSummaryLayout>

  cards: AttacksSummaryCard[]
}

export interface AttacksSummaryCard {
  directoryId: number

  chartType?: Maybe<AttacksSummaryChartCardType>

  statsCounts: DashboardWidgetOptionsSerieStatAttackCounts[]
}

export interface MaybeGrantedAttacks {
  node?: Maybe<Attack[]>

  rbacCapability: RbacCapability
}

export interface Attack {
  id: number

  date: string

  source: AttackSource

  destination: AttackDestination

  vector: AttackVector

  attackTypeId: number

  directoryId: number

  isClosed?: Maybe<boolean>
}

export interface AttackSource {
  hostname?: Maybe<string>

  ip?: Maybe<string>

  type?: Maybe<AttackSourceType>
}

export interface AttackDestination {
  hostname?: Maybe<string>

  ip?: Maybe<string>

  type?: Maybe<AttackDestinationType>
}

export interface AttackVector {
  attributes: AttackVectorAttribute[]
}

export interface AttackVectorAttribute {
  name: string

  value: string

  valueType: string
}

export interface MaybeGrantedAttacksCount {
  node?: Maybe<AttacksCount>

  rbacCapability: RbacCapability
}

export interface AttacksCount {
  count: number
}

export interface AttacksRemoveFilter {
  id: number

  resourceType: AttackResourceType

  resourceValue: string

  attackTypeId: number
}

export interface AttackTypeConfiguration {
  configuration: AttackTypeConfigurationConfigurationEntry[]

  permissions: Permissions
}

export interface AttackTypeConfigurationConfigurationEntry {
  attackTypeId: number

  directoryId: number

  detectedEvents: boolean

  autoDisabledForPerfAt?: Maybe<string>
}

export interface Permissions {
  canEdit: boolean
}

export interface IoaConfiguration {
  scriptDownloadDate?: Maybe<string>

  forcedManualIoaConfiguration?: Maybe<boolean>

  customPastDelaySeconds?: Maybe<number>
}

export interface MaybeGrantedApplicationSettings {
  node?: Maybe<ApplicationSettings>

  rbacCapability: RbacCapability
}

export interface ApplicationSettings {
  userRegistration: boolean

  activityLogsIsActive: boolean

  activityLogsRetentionDurationInMonth: number

  smtpServerAddress?: Maybe<string>

  smtpServerPort?: Maybe<number>

  smtpAccount?: Maybe<string>

  smtpAccountPassword?: Maybe<string>

  smtpUseStartTLS?: Maybe<boolean>

  tls: boolean

  emailSender: string

  defaultProfileId: number

  defaultRoleIds: number[]

  internalCertificate?: Maybe<string>

  telemetryEnabled?: Maybe<boolean>

  ioaAutomaticConfiguration?: Maybe<boolean>

  smtpRelayId?: Maybe<number>

  cloudStatisticsIsActive?: Maybe<boolean>

  healthCheckGlobalStatusDisplayEnabled?: Maybe<boolean>
}

export interface ApplicationConfiguration {
  availableLanguages: AvailableLanguage[]
}

export interface AvailableLanguage {
  language: Language

  isBeta: boolean
}

export interface MaybeGrantedDataCollectionStatus {
  node?: Maybe<DataCollectionStatus>

  rbacCapability: RbacCapability
}

export interface DataCollectionStatus {
  isEnabled: boolean

  notWorkingBecauseOf: DataCollectionNotWorkingReason[]

  sensitiveDataEnabled: boolean
}

export interface MaybeGrantedLockoutPolicy {
  node?: Maybe<LockoutPolicy>

  rbacCapability: RbacCapability
}

export interface LockoutPolicy {
  enabled: boolean

  lockoutDuration: number

  failedAttemptThreshold: number

  failedAttemptPeriod: number
}

export interface MaybeGrantedLdapConfiguration {
  node?: Maybe<LdapConfiguration>

  rbacCapability: RbacCapability
}

export interface LdapConfiguration {
  enabled: boolean

  url?: Maybe<string>

  searchUserDN?: Maybe<string>

  searchUserPassword?: Maybe<string>

  userSearchBase?: Maybe<string>

  userSearchFilter?: Maybe<string>

  allowedGroups?: Maybe<AuthConfigurationAllowedGroups[]>

  enableSaslBinding?: Maybe<boolean>

  relayId?: Maybe<number>
}

export interface AuthConfigurationAllowedGroups {
  name: string

  defaultProfileId?: Maybe<number>

  defaultRoleIds?: Maybe<number[]>
}

export interface MaybeGrantedSamlConfiguration {
  node?: Maybe<SamlConfiguration>

  rbacCapability: RbacCapability
}

export interface SamlConfiguration {
  enabled: boolean

  providerLoginUrl?: Maybe<string>

  signatureCertificate?: Maybe<string>

  serviceProviderUrl?: Maybe<string>

  assertEndpoint?: Maybe<string>

  activateCreatedUsers?: Maybe<boolean>

  allowedGroups?: Maybe<AuthConfigurationAllowedGroups[]>
}

export interface MaybeGrantedRelayLinkingKey {
  node?: Maybe<RelayLinkingKey>

  rbacCapability: RbacCapability
}

export interface RelayLinkingKey {
  linkingKey: string
}

export interface MaybeGrantedRelays {
  node?: Maybe<Relay[]>

  rbacCapability: RbacCapability
}

export interface Relay {
  id: number

  name: string

  relayUuid: string

  status: string

  directoryIds?: Maybe<number[]>

  apiKey: string

  newApiKey?: Maybe<string>

  alertCount: number
}

export interface MaybeGrantedAttackPath {
  node?: Maybe<AttackPath>

  rbacCapability: RbacCapability
}

export interface AttackPath {
  nodes?: Maybe<AttackPathNode[]>

  edges?: Maybe<AttackPathEdge[]>

  isTruncated?: Maybe<boolean>

  truncationType?: Maybe<AttackPathTruncationType>
}

export interface AttackPathNode {
  id: number

  name?: Maybe<string>

  adObjectType?: Maybe<AttackPathAdObjectType>

  nextDepthNodeCount?: Maybe<number>

  directoryId?: Maybe<number>
}

export interface AttackPathEdge {
  relationType: AttackPathRelationType

  sourceId: number

  targetId: number
}

export interface MaybeGrantedAttackPathNode {
  node?: Maybe<AttackPathNode[]>

  rbacCapability: RbacCapability
}

export interface MaybeGrantedPaginatedAttackPathTier0Objects {
  node?: Maybe<PaginatedAttackPathTier0Objects>

  rbacCapability: RbacCapability
}

export interface PaginatedAttackPathTier0Objects {
  node: AttackPathTier0Object[]

  pagination: Pagination
}

export interface AttackPathTier0Object {
  id: number

  name?: Maybe<string>

  adObjectType?: Maybe<AttackPathAdObjectType>

  directoryId?: Maybe<number>

  accountTotal?: Maybe<number>

  accountWithTier0Access?: Maybe<number>
}

export interface MaybeGrantedAttackPathTier0Objects {
  node: AttackPathTier0Object[]

  rbacCapability: RbacCapability
}

export interface MaybeGrantedPaginatedAttackPathAccountsWithTier0AttackPath {
  node?: Maybe<PaginatedAttackPathAccountsWithTier0AttackPath>

  rbacCapability: RbacCapability
}

export interface PaginatedAttackPathAccountsWithTier0AttackPath {
  node: AttackPathAccountWithTier0AttackPath[]

  pagination: Pagination
}

export interface AttackPathAccountWithTier0AttackPath {
  id: number

  name?: Maybe<string>

  adObjectType?: Maybe<AttackPathAdObjectType>

  directoryId?: Maybe<number>

  location?: Maybe<string>
}

export interface RbacEntity {
  entityName: RbacEntityName

  getAllUrl?: Maybe<string>

  actions: RbacEntityAction

  items: RbacEntityItem[]
}

export interface RbacEntityAction {
  read: RbacEntityActionReadFlags

  edit: RbacEntityActionEditFlags

  create: boolean
}

export interface RbacEntityActionReadFlags {
  all: boolean

  id: boolean
}

export interface RbacEntityActionEditFlags {
  all: boolean

  id: boolean
}

export interface Email {
  id: number

  address: string

  criticityThreshold: number

  directories: Directory[]

  description?: Maybe<string>

  shouldNotifyOnInitialFullSecurityCheck: boolean

  inputType: EmailInputType

  profiles?: Maybe<number[]>

  checkers?: Maybe<number[]>

  attackTypes?: Maybe<number[]>

  healthCheckNames?: Maybe<string[]>
}

export interface Plugin {
  id: number

  name: string

  parameters: PluginParameter[]
}

export interface PluginParameter {
  name: string

  valueType: string
}

export interface PluginConfiguration {
  id: number

  pluginId: number

  parametersValues: PluginConfigurationParameter[]
}

export interface PluginConfigurationParameter {
  name: string

  value: string
}

export interface Preferences {
  language: Language

  preferredProfileId: number
}

export interface RbacEntityUiItem {
  name: string
}

export interface Report {
  id: number

  name: string

  type: ReportType

  checkerIds?: Maybe<number[]>

  attackTypeIds?: Maybe<number[]>

  directoryIds: number[]

  profileId: number

  recipientEmails: string[]

  format: string

  dataTimeframe: DataTimeframe

  recurrence: string

  timeZone: string
}

export interface Syslog {
  id: number

  ip: string

  port: number

  protocol: string

  tls: boolean

  criticityThreshold: number

  directories: Directory[]

  healthCheckNames?: Maybe<string[]>

  shouldNotifyOnInitialFullSecurityCheck: boolean

  description?: Maybe<string>

  filterExpression?: Maybe<string>

  inputType: SyslogInputType

  profiles?: Maybe<number[]>

  checkers?: Maybe<number[]>

  attackTypes?: Maybe<number[]>

  relayId?: Maybe<number>
}

export interface Tenant {
  uuid: string

  name: string

  providerType: string
}

export interface MaybeGrantedPaginatedSyslogs {
  node?: Maybe<PaginatedSyslogs>

  rbacCapability: RbacCapability
}

export interface PaginatedSyslogs {
  node: Syslog[]

  pagination: Pagination
}

export interface MaybeGrantedPaginatedEmails {
  node?: Maybe<PaginatedEmails>

  rbacCapability: RbacCapability
}

export interface PaginatedEmails {
  node: Email[]

  pagination: Pagination
}

export interface MaybeGrantedPaginatedReports {
  node?: Maybe<PaginatedReports>

  rbacCapability: RbacCapability
}

export interface PaginatedReports {
  node: Report[]

  pagination: Pagination
}

export interface MaybeGrantedReportAccessToken {
  node: ReportAccessToken

  rbacCapability: RbacCapability
}

export interface ReportAccessToken {
  reportAccessToken: string
}

export interface MaybeGrantedPlugins {
  node?: Maybe<Plugin[]>

  rbacCapability: RbacCapability
}

export interface MaybeGrantedPluginConfigurations {
  node?: Maybe<PluginConfiguration[]>

  rbacCapability: RbacCapability
}

export interface MaybeGrantedPaginatedAlertsIoE {
  node?: Maybe<PaginatedAlertsIoE>

  rbacCapability: RbacCapability
}

export interface PaginatedAlertsIoE {
  node: AlertIoE[]

  pagination: Pagination
}

export interface AlertIoE {
  id: number

  infrastructureId: number

  directoryId: number

  devianceId: number

  archived: boolean

  read: boolean

  date: string

  rbacDeviance: MaybeGrantedDeviance
}

export interface MaybeGrantedDeviance {
  node?: Maybe<Deviance>

  rbacCapability: RbacCapability
}

export interface MaybeGrantedPaginatedAlertsIoA {
  node?: Maybe<PaginatedAlertsIoA>

  rbacCapability: RbacCapability
}

export interface PaginatedAlertsIoA {
  node: AlertIoA[]

  pagination: Pagination
}

export interface AlertIoA {
  id: number

  date: string

  directoryId: number

  attackTypeId: number

  archived: boolean

  read: boolean
}

export interface MaybeGrantedAlertsIoACount {
  node?: Maybe<AlertIoACount>

  rbacCapability: RbacCapability
}

export interface AlertIoACount {
  count: number
}

export interface MaybeGrantedLicense {
  node?: Maybe<License>

  rbacCapability: RbacCapability
}

export interface License {
  customerName: string

  maxActiveUserCount: number

  currentActiveUserCount?: Maybe<number>

  type: LicenseType

  features: LicenseFeature[]

  expirationDateUTC: string

  inAppEula: boolean

  activationCode?: Maybe<string>

  containerUuid?: Maybe<string>

  productAssociation?: Maybe<string>
}

export interface MaybeGrantedPaginatedUserTrace {
  node?: Maybe<PaginatedUserTrace>

  rbacCapability: RbacCapability
}

export interface PaginatedUserTrace {
  node: UserTrace[]

  pagination: PaginationHal
}

export interface UserTrace {
  id: number

  createdAt: string

  userId?: Maybe<number>

  userEmail?: Maybe<string>

  userIp?: Maybe<string>

  logTitle?: Maybe<string>

  logSource: UserTraceLogSource

  logType: UserTraceLogType

  logAttributes?: Maybe<string>
}

export interface PaginationHal {
  nextLink?: Maybe<string>
}

export interface TracesFiltersData {
  node?: Maybe<FiltersData>

  rbacCapability: RbacCapability
}

export interface FiltersData {
  allUserIps: string[]

  allUserEmails: string[]
}

export interface PaginatedHealthChecksDomainStatus {
  node: HealthCheckDomainStatus[]

  pagination: Pagination
}

export interface HealthCheckDomainStatus {
  healthCheckName: string

  directoryId: number

  status: HealthCheckStatus

  lastCheckDate: string

  statusSince: string
}

export interface HealthCheckDomainStatusDetails {
  healthCheckName: string

  directoryId: number

  status: HealthCheckStatus

  lastCheckDate: string

  statusSince: string

  reasons?: Maybe<HealthCheckDomainStatusReason[]>
}

export interface HealthCheckDomainStatusReason {
  name: string

  status: HealthCheckStatus

  statusSince: string

  lastCheckDate: string

  attributes?: Maybe<HealthCheckAttribute[]>
}

export interface HealthCheckAttribute {
  name?: Maybe<string>

  value?: Maybe<string>

  valueType?: Maybe<string>
}

export interface PaginatedHealthChecksPlatformStatus {
  node: HealthCheckPlatformStatus[]

  pagination: Pagination
}

export interface HealthCheckPlatformStatus {
  healthCheckName: string

  status: HealthCheckStatus

  lastCheckDate: string

  statusSince: string
}

export interface HealthCheckPlatformStatusDetails {
  healthCheckName: string

  status: HealthCheckStatus

  lastCheckDate: string

  statusSince: string

  reasons?: Maybe<HealthCheckDomainStatusReason[]>
}

export interface HealthCheckGlobalStatus {
  status: HealthCheckStatus

  issues?: Maybe<number>

  warnings?: Maybe<number>
}

export interface HealthCheckDetailedGlobalStatus {
  status: HealthCheckStatus

  domainStats: HealthCheckDetailedStatus

  platformStats: HealthCheckDetailedStatus
}

export interface HealthCheckDetailedStatus {
  numberOfIssues: number

  numberOfWarnings: number

  numberOfUnknowns: number
}

export interface HealthChecksTemplatesName {
  codename: string

  alert: boolean
}

export interface AzureAdSupportConfiguration {
  azureADSupportEnabled: boolean
}

export interface Mutation {
  editAlertsIoE: ReflectedAlertsIoE[]

  editBulkAlertsIoE: boolean

  editAlertsIoA: boolean

  editBulkAlertsIoA: boolean

  createInfrastructure: Infrastructure

  editInfrastructure: Infrastructure

  deleteInfrastructure?: Maybe<boolean>

  createDirectory: Directory

  editDirectory: Directory

  deleteDirectory?: Maybe<boolean>

  recrawlDirectory: Directory

  requestDirectoryConnectivity: boolean

  updateHoneyAccount: Directory

  editRelay: Relay

  deleteRelay: boolean

  renewRelayApiKey: boolean

  editDeviances: ReflectedDeviance[]

  editAdObjectDeviances: ReflectedDeviance[]

  editCheckerDeviances: boolean

  createDashboard: Dashboard

  editDashboard: Dashboard

  deleteDashboard: boolean

  createDashboardWidget: DashboardWidget

  editDashboardWidget: DashboardWidget

  deleteDashboardWidget: boolean

  setDashboardWidgetOptions: boolean

  updateApplicationSettings: ApplicationSettings

  updateDataCollectionStatus: DataCollectionStatus

  updateAzureADSupportConfiguration: AzureAdSupportConfiguration

  updatePreferences: Preferences

  updateLockoutPolicy: boolean

  updateLDAPConfiguration: LdapConfiguration

  updateSAMLConfiguration: SamlConfiguration

  updateAttackTypeConfiguration: boolean

  updateTenableCloudApiToken: boolean

  updateIoAConfiguration: boolean

  editAttacksSummaryLayout: boolean

  editAttacksSummaryDirectory: boolean

  closeAttacks: ReflectedAttack[]

  closeAttacksBulk: boolean

  removeAttacks: boolean

  unremoveAllAttacks: boolean

  unremoveAttacks: boolean

  createSyslog: Syslog

  editSyslog: Syslog

  testNewSyslog: boolean

  testExistingSyslog: boolean

  deleteSyslog: boolean

  createEmail: Email

  editEmail: Email

  testNewEmail: boolean

  testExistingEmail: boolean

  deleteEmail: boolean

  createReport: Report

  editReport: Report

  deleteReport: boolean

  refreshReportAccessToken: ReportAccessToken

  createPlugin: Plugin

  editPlugin: Plugin

  deletePlugin: boolean

  createPluginConfiguration: PluginConfiguration

  editPluginConfiguration: PluginConfiguration

  deletePluginConfiguration: boolean

  createUser: User

  editUser: User

  deleteUser: boolean

  editUserRoles: boolean

  changeUserPassword: CatchedQuery

  updateUserEula: User

  refreshApiKey: ApiKey

  createRbacRole: RbacRole

  editRbacRole: CatchRequestErrorRbacRole

  deleteRbacRole: boolean

  createProfile: Profile

  copyProfile: Profile

  editProfile: Profile

  unstageProfile: boolean

  commitProfile: boolean

  deleteProfile: boolean

  setProfileCheckerOptions: boolean

  createSearchHistoryEntry: SearchHistoryEntry

  deleteSearchHistoryEntry: boolean

  deleteSearchHistoryEntries: boolean

  deleteSearchHistoryEntriesByQuery: boolean

  createSearchBookmarkEntry: SearchBookmarkEntry

  editSearchBookmarkEntry: SearchBookmarkEntry

  deleteSearchBookmarkEntry: boolean

  editSearchBookmarkCategory: boolean

  deleteSearchBookmarkCategory: boolean

  addPageVisited: boolean

  deleteAllUserTraces: boolean
}

export interface ReflectedAlertsIoE {
  node?: Maybe<AlertIoE>

  err?: Maybe<string>

  status: ReflectedPromiseStatus
}

export interface ReflectedDeviance {
  node?: Maybe<Deviance>

  err?: Maybe<string>

  status: ReflectedPromiseStatus
}

export interface ReflectedAttack {
  err?: Maybe<string>

  status: ReflectedPromiseStatus
}

export interface CatchedQuery {
  message?: Maybe<string>

  error?: Maybe<string>
}

export interface CatchRequestErrorRbacRole {
  node?: Maybe<RbacRole>

  requestError?: Maybe<RequestError>
}

export interface RequestError {
  error: RequestErrorError

  message?: Maybe<string>
}

export interface RequestErrorError {
  code?: Maybe<RequestErrorCode>

  title?: Maybe<string>

  resource?: Maybe<string>

  details?: Maybe<RequestErrorDetails>
}

export interface RequestErrorDetailsRbacMissingRelation {
  type?: Maybe<RequestErrorDetailsType>

  permissions?: Maybe<string[]>

  relatedEntities?: Maybe<string[]>
}

export interface RequestErrorDetailsRbacMissingPermission {
  type?: Maybe<RequestErrorDetailsType>

  permissions?: Maybe<string[]>

  relatedEntities?: Maybe<string[]>
}

export interface ReflectedAlertsIoA {
  node?: Maybe<AlertIoA>

  err?: Maybe<string>

  status: ReflectedPromiseStatus
}

export interface PaginatedDeviances {
  node: Deviance[]

  pagination: Pagination
}

export interface AttackTypeConfigurationScript {
  script: string
}

export interface AttackTypeConfigurationDirectory {
  id: number

  name: string
}

export interface AttackTypeConfigurationInfrastructure {
  id: number

  name: string

  directories: AttackTypeConfigurationDirectory[]
}

export interface PaginatedAttackTypeConfigurationInfrastructures {
  node: AttackTypeConfigurationInfrastructure[]

  pagination: Pagination
}

export interface MaybeGrantedPaginatedAttackTypeConfigurationInfrastructures {
  node?: Maybe<PaginatedAttackTypeConfigurationInfrastructures>

  rbacCapability: RbacCapability
}

export interface AuthProvider {
  protocol: AuthProviderName

  type: string

  url: string
}

export interface Eula {
  language: Language

  version: number

  license: string
}

export interface SamlEncryptionCertificate {
  encryptionCertificate: string
}

export interface ReportMetadata {
  type: ReportType

  startDate: string

  endDate: string
}

export interface About {
  version: string

  hostname: string

  isSaaSDeployment: boolean
}

export interface HealthCheckPlatformStatusReason {
  name: string

  status: HealthCheckStatus

  statusSince: string

  lastCheckDate: string

  attributes?: Maybe<HealthCheckAttribute[]>
}

export interface ProductAssociation {
  productAssociation: string
}

export interface TenableCloudApiToken {
  accessKey: string

  secretKey: string
}

// ====================================================
// Arguments
// ====================================================

export interface UsersQueryArgs {
  usersPage?: Maybe<number>

  usersPerPage?: Maybe<number>
}
export interface ProfilesQueryArgs {
  profileId?: Maybe<number>
}
export interface RbacProfilesQueryArgs {
  profileId?: Maybe<number>
}
export interface RbacInfrastructuresQueryArgs {
  infrastructureId?: Maybe<number>

  infrastructuresPage?: Maybe<number>

  infrastructuresPerPage?: Maybe<number>

  infrastructureName?: Maybe<string>
}
export interface RbacDirectoriesQueryArgs {
  directoryId?: Maybe<number>

  directoriesPage?: Maybe<number>

  directoriesPerPage?: Maybe<number>

  directoriesSearch?: Maybe<string>
}
export interface SearchHoneyAccountCandidatesQueryArgs {
  honeyAccount: InputSearchHoneyAccountCandidates
}
export interface HoneyAccountConfigurationScriptQueryArgs {
  honeyAccount: InputHoneyAccountConfigurationScript
}
export interface RbacCheckersQueryArgs {
  profileId: number

  checkerId?: Maybe<number>

  optionsCodenames: string[]
}
export interface CheckerOptionsQueryArgs {
  profileId: number

  checkerId: number
}
export interface CategoriesQueryArgs {
  categoryId?: Maybe<number>
}
export interface EventsQueryArgs {
  profileId: number

  directoryIds: number[]

  dateStart: string

  dateEnd: string

  eventsDirection: string

  eventsExpression: string

  eventsPerPage: number
}
export interface EventByIdQueryArgs {
  directoryId: number

  eventId: string
}
export interface SearchHistoryQueryArgs {
  searchHistoryEntriesPage?: Maybe<number>

  searchHistoryEntriesPerPage?: Maybe<number>

  dateStart?: Maybe<string>

  dateEnd?: Maybe<string>

  expressionSearch?: Maybe<string>
}
export interface RbacDeviantAdObjectsQueryArgs {
  profileId: number

  checkerId: number

  directoryIds: number[]

  reasonIds: number[]

  dateStart?: Maybe<string>

  dateEnd?: Maybe<string>

  showIgnored: boolean

  adObjectsExpression: string

  adObjectsPage?: Maybe<number>

  adObjectsPerPage?: Maybe<number>
}
export interface RbacAdObjectIncriminatingAttributesQueryArgs {
  profileId: number

  checkerId: number

  adObjectId: number

  showIgnored: boolean

  dateStart?: Maybe<string>

  dateEnd?: Maybe<string>

  incriminatingAttributesPage?: Maybe<number>

  incriminatingAttributesPerPage?: Maybe<number>
}
export interface RbacEventIncriminatingAttributesQueryArgs {
  profileId: number

  infrastructureId: number

  directoryId: number

  eventId: string

  checkerIds: number[]

  reasonIds: number[]

  incriminatingAttributesExpression?: Maybe<string>

  incriminatingAttributesPage?: Maybe<number>

  incriminatingAttributesPerPage?: Maybe<number>
}
export interface RbacDeviancesQueryArgs {
  profileIds: number[]

  events: InputDirectoryIdEventId[]
}
export interface RbacDashboardsQueryArgs {
  profileId: number

  dashboardId?: Maybe<number>

  dashboardWidgetId?: Maybe<number>
}
export interface RbacTopologyQueryArgs {
  profileId: number
}
export interface RbacTopologyDomainDetailsQueryArgs {
  profileId: number

  directoryIds: number[]

  checkerIds: number[]

  reasonIds: number[]

  timestampStart: string

  timestampEnd: string
}
export interface RbacAttackTypesQueryArgs {
  profileId: number

  optionsCodenames: string[]
}
export interface RbacAttacksSummaryQueryArgs {
  profileId: number

  dateStart: string

  dateEnd: string

  interval: string

  directoryIds: number[]

  layout: AttacksSummaryLayout
}
export interface AttacksStatsQueryArgs {
  profileId: number

  dateStart: string

  dateEnd: string

  interval: string

  directoryIds: number[]

  ioaSeries: number[][]

  isClosed?: Maybe<boolean>
}
export interface AttacksStatsCountsQueryArgs {
  profileId: number

  dateStart: string

  dateEnd: string

  directoryIds: number[]

  ioaSeries: number[][]

  isClosed?: Maybe<boolean>
}
export interface AttackTypeOptionsQueryArgs {
  profileId: number

  attackTypeId: number
}
export interface RbacAttacksQueryArgs {
  attacksFilters: InputAttacksFilters

  orderBy: OrderBy

  limit?: Maybe<number>
}
export interface RbacAttacksCountQueryArgs {
  attacksFilters: InputAttacksFilters
}
export interface AttacksRemoveFiltersQueryArgs {
  profileId: number
}
export interface RbacAttackPathQueryArgs {
  adObjectId: number

  direction: AttackPathDirection

  depth: number
}
export interface RbacAttackPathTwoNodesQueryArgs {
  sourceAdObjectId: number

  targetAdObjectId: number
}
export interface RbacAttackPathSearchQueryArgs {
  text: string
}
export interface RbacAttackPathTier0ObjectsQueryArgs {
  directoryIds: number[]

  page: number

  perPage: number
}
export interface RbacAttackPathAllTier0ObjectsQueryArgs {
  directoryIds: number[]
}
export interface RbacAttackPathAccountsWithTier0AttackPathQueryArgs {
  accountSearchText?: Maybe<string>

  directoryIds: number[]

  tier0AssetId?: Maybe<number>

  page: number

  perPage: number
}
export interface RbacRolesQueryArgs {
  roleId?: Maybe<number>
}
export interface RbacPermissionsQueryArgs {
  roleId: number
}
export interface RbacSyslogsQueryArgs {
  syslogsPage?: Maybe<number>

  syslogsPerPage?: Maybe<number>
}
export interface RbacEmailsQueryArgs {
  emailsPage?: Maybe<number>

  emailsPerPage?: Maybe<number>
}
export interface RbacReportsQueryArgs {
  reportsPage?: Maybe<number>

  reportsPerPage?: Maybe<number>
}
export interface RbacPluginConfigurationsQueryArgs {
  pluginId: number
}
export interface RbacAlertsIoEQueryArgs {
  profileId: number

  alertId?: Maybe<number>

  showRead: boolean

  showArchived: boolean

  alertsPage?: Maybe<number>

  alertsPerPage?: Maybe<number>
}
export interface RbacAlertsIoAQueryArgs {
  profileId: number

  showArchived: boolean

  alertsPage?: Maybe<number>

  alertsPerPage?: Maybe<number>
}
export interface RbacAlertsIoACountQueryArgs {
  profileId: number

  showRead: boolean

  showArchived: boolean
}
export interface RbacUsersTracesQueryArgs {
  dateStart?: Maybe<string>

  dateEnd?: Maybe<string>

  nextLink?: Maybe<string>

  ips?: Maybe<string[]>

  userEmails?: Maybe<string[]>

  logTypes?: Maybe<string[]>
}
export interface HealthChecksDomainStatusQueryArgs {
  directoryIds: number[]

  healthCheckNames: string[]

  showHealthy?: Maybe<boolean>

  healthChecksPage?: Maybe<number>

  healthChecksPerPage?: Maybe<number>
}
export interface HealthChecksDomainStatusDetailsQueryArgs {
  healthCheckName: string

  directoryId: number
}
export interface HealthChecksPlatformStatusQueryArgs {
  healthCheckNames: string[]

  showHealthy?: Maybe<boolean>

  healthChecksPage?: Maybe<number>

  healthChecksPerPage?: Maybe<number>
}
export interface HealthChecksPlatformStatusDetailsQueryArgs {
  healthCheckName: string
}
export interface DirectoriesInfrastructureArgs {
  directoryId?: Maybe<number>
}
export interface EventDirectoryArgs {
  eventId: string
}
export interface RbacDeviancesEventArgs {
  profileId: number
}
export interface RbacReasonsEventArgs {
  profileId: number
}
export interface RbacReasonsCheckerArgs {
  checkerId: number
}
export interface RbacDeviancesCheckerArgs {
  directoryIds: number[]

  deviancesPerPage?: Maybe<number>
}
export interface RbacWidgetsDashboardArgs {
  dashboardWidgetId?: Maybe<number>
}
export interface StatsDashboardWidgetOptionsSerieArgs {
  profileId: number
}
export interface StatsCountsAttacksSummaryCardArgs {
  profileId: number

  dateStart: string

  dateEnd: string

  directoryIds?: Maybe<number[]>

  ioaSeries: number[][]

  isClosed?: Maybe<boolean>
}
export interface EditAlertsIoEMutationArgs {
  alerts: InputEditAlertIoE[]
}
export interface EditBulkAlertsIoEMutationArgs {
  alertBulkPayload: InputEditAlertIoEBulkPayload
}
export interface EditAlertsIoAMutationArgs {
  alerts: InputEditAlertIoA[]
}
export interface EditBulkAlertsIoAMutationArgs {
  alertBulkPayload: InputEditAlertIoABulkPayload
}
export interface CreateInfrastructureMutationArgs {
  infrastructure: InputCreateInfrastructure
}
export interface EditInfrastructureMutationArgs {
  infrastructure: InputEditInfrastructure
}
export interface DeleteInfrastructureMutationArgs {
  infrastructure: InputDeleteInfrastructure
}
export interface CreateDirectoryMutationArgs {
  directory: InputCreateDirectory
}
export interface EditDirectoryMutationArgs {
  directory: InputEditDirectory
}
export interface DeleteDirectoryMutationArgs {
  directory: InputDeleteDirectory
}
export interface RecrawlDirectoryMutationArgs {
  directory: InputRecrawlDirectory
}
export interface RequestDirectoryConnectivityMutationArgs {
  id: string

  directory: InputRequestDirectoryConnectivity
}
export interface UpdateHoneyAccountMutationArgs {
  honeyAccount: InputUpdateHoneyAccount
}
export interface EditRelayMutationArgs {
  relay: InputEditRelay
}
export interface DeleteRelayMutationArgs {
  relay: InputDeleteRelay
}
export interface RenewRelayApiKeyMutationArgs {
  relay: InputRenewRelayApiKey
}
export interface EditDeviancesMutationArgs {
  deviances: InputEditDeviance[]
}
export interface EditAdObjectDeviancesMutationArgs {
  deviances: InputEditAdObjectDeviances[]
}
export interface EditCheckerDeviancesMutationArgs {
  deviances: InputEditCheckerDeviances
}
export interface CreateDashboardMutationArgs {
  dashboard: InputCreateDashboard
}
export interface EditDashboardMutationArgs {
  dashboard: InputEditDashboard
}
export interface DeleteDashboardMutationArgs {
  dashboard: InputDeleteDashboard
}
export interface CreateDashboardWidgetMutationArgs {
  dashboardWidget: InputCreateDashboardWidget
}
export interface EditDashboardWidgetMutationArgs {
  dashboardWidget: InputEditDashboardWidget
}
export interface DeleteDashboardWidgetMutationArgs {
  dashboardWidget: InputDeleteDashboardWidget
}
export interface SetDashboardWidgetOptionsMutationArgs {
  dashboardWidgetOptions: InputSetDashboardWidgetOptions
}
export interface UpdateApplicationSettingsMutationArgs {
  applicationSettings: InputUpdateApplicationSettings
}
export interface UpdateDataCollectionStatusMutationArgs {
  dataCollectionStatus: InputUpdateDataCollectionStatus
}
export interface UpdateAzureAdSupportConfigurationMutationArgs {
  azureADSupportConfiguration: InputAzureAdSupportConfiguration
}
export interface UpdatePreferencesMutationArgs {
  preferences: InputUpdatePreferences
}
export interface UpdateLockoutPolicyMutationArgs {
  lockoutPolicy: InputUpdateLockoutPolicy
}
export interface UpdateLdapConfigurationMutationArgs {
  ldapConfiguration: InputLdapConfiguration
}
export interface UpdateSamlConfigurationMutationArgs {
  samlConfiguration: InputSamlConfiguration
}
export interface UpdateAttackTypeConfigurationMutationArgs {
  attackTypeConfiguration: InputAttackTypeConfiguration
}
export interface UpdateTenableCloudApiTokenMutationArgs {
  tenableCloudApiToken: InputUpdateTenableCloudApiToken
}
export interface UpdateIoAConfigurationMutationArgs {
  ioaConfiguration: InputIoaConfiguration
}
export interface EditAttacksSummaryLayoutMutationArgs {
  profileId: number

  layout: InputIoAAttacksSummaryLayout
}
export interface EditAttacksSummaryDirectoryMutationArgs {
  profileId: number

  directory: InputIoAAttacksSummaryDirectory
}
export interface CloseAttacksMutationArgs {
  profileId: number

  attacks: InputCloseAttack[]
}
export interface CloseAttacksBulkMutationArgs {
  profileId: number

  attacks: InputCloseAttacksBulk
}
export interface RemoveAttacksMutationArgs {
  profileId: number

  attackFilters: InputAttackFilter[]
}
export interface UnremoveAllAttacksMutationArgs {
  profileId: number
}
export interface UnremoveAttacksMutationArgs {
  profileId: number

  filterId: number
}
export interface CreateSyslogMutationArgs {
  syslog: InputCreateSyslog
}
export interface EditSyslogMutationArgs {
  syslog: InputEditSyslog
}
export interface TestNewSyslogMutationArgs {
  syslog: InputTestNewSyslog
}
export interface TestExistingSyslogMutationArgs {
  syslog: InputTestExistingSyslog
}
export interface DeleteSyslogMutationArgs {
  syslog: InputDeleteSyslog
}
export interface CreateEmailMutationArgs {
  email: InputCreateEmail
}
export interface EditEmailMutationArgs {
  email: InputEditEmail
}
export interface TestNewEmailMutationArgs {
  email: InputTestNewEmail
}
export interface TestExistingEmailMutationArgs {
  email: InputTestExistingEmail
}
export interface DeleteEmailMutationArgs {
  email: InputDeleteEmail
}
export interface CreateReportMutationArgs {
  report: InputCreateReport
}
export interface EditReportMutationArgs {
  report: InputEditReport
}
export interface DeleteReportMutationArgs {
  report: InputDeleteReport
}
export interface CreatePluginMutationArgs {
  plugin: InputCreatePlugin
}
export interface EditPluginMutationArgs {
  plugin: InputEditPlugin
}
export interface DeletePluginMutationArgs {
  plugin: InputDeletePlugin
}
export interface CreatePluginConfigurationMutationArgs {
  pluginId: number

  pluginConfiguration: InputCreatePluginConfiguration
}
export interface EditPluginConfigurationMutationArgs {
  pluginId: number

  pluginConfiguration: InputEditPluginConfiguration
}
export interface DeletePluginConfigurationMutationArgs {
  pluginId: number

  pluginConfiguration: InputDeletePluginConfiguration
}
export interface CreateUserMutationArgs {
  user: InputCreateUser
}
export interface EditUserMutationArgs {
  user: InputEditUser
}
export interface DeleteUserMutationArgs {
  user: InputDeleteUser
}
export interface EditUserRolesMutationArgs {
  roles: InputEditUserRoles
}
export interface ChangeUserPasswordMutationArgs {
  password: InputChangeUserPassword
}
export interface UpdateUserEulaMutationArgs {
  userEula: InputUserEula
}
export interface CreateRbacRoleMutationArgs {
  role: InputCreateRbacRole
}
export interface EditRbacRoleMutationArgs {
  role: InputEditRbacRole
}
export interface DeleteRbacRoleMutationArgs {
  role: InputDeleteRbacRole
}
export interface CreateProfileMutationArgs {
  profile: InputCreateProfile
}
export interface CopyProfileMutationArgs {
  profile: InputCopyProfile
}
export interface EditProfileMutationArgs {
  profile: InputEditProfile
}
export interface UnstageProfileMutationArgs {
  profile: InputUnstageProfile
}
export interface CommitProfileMutationArgs {
  profile: InputCommitProfile
}
export interface DeleteProfileMutationArgs {
  profile: InputDeleteProfile
}
export interface SetProfileCheckerOptionsMutationArgs {
  profileId: number

  checkerType: CheckerType

  checkerId: number

  checkerOptions: InputSetProfileCheckerOption[]
}
export interface CreateSearchHistoryEntryMutationArgs {
  searchHistoryEntry: InputCreateSearchHistoryEntry
}
export interface DeleteSearchHistoryEntryMutationArgs {
  searchHistoryEntry: InputDeleteSearchHistoryEntry
}
export interface DeleteSearchHistoryEntriesByQueryMutationArgs {
  searchHistoryQuery: InputDeleteSearchHistoryEntriesByQuery
}
export interface CreateSearchBookmarkEntryMutationArgs {
  searchBookmarkEntry: InputCreateSearchBookmarkEntry
}
export interface EditSearchBookmarkEntryMutationArgs {
  searchBookmarkEntry: InputEditSearchBookmarkEntry
}
export interface DeleteSearchBookmarkEntryMutationArgs {
  searchBookmarkEntry: InputDeleteSearchBookmarkEntry
}
export interface EditSearchBookmarkCategoryMutationArgs {
  searchBookmarkCategory: InputEditSearchBookmarkCategory
}
export interface DeleteSearchBookmarkCategoryMutationArgs {
  searchBookmarkCategory: InputDeleteSearchBookmarkCategory
}
export interface AddPageVisitedMutationArgs {
  pageVisited: InputAddPageVisited
}

// ====================================================
// Unions
// ====================================================

export type DashboardWidgetOptionsSerieDataOptions =
  | DashboardWidgetOptionsSerieDataOptionsUser
  | DashboardWidgetOptionsSerieDataOptionsDeviance
  | DashboardWidgetOptionsSerieDataOptionsResolution
  | DashboardWidgetOptionsSerieDataOptionsEvent
  | DashboardWidgetOptionsSerieDataOptionsComplianceScore

export type DashboardWidgetOptionsSerieDisplayOptions =
  | DashboardWidgetOptionsSerieDisplayOptionsBarChart
  | DashboardWidgetOptionsSerieDisplayOptionsLineChart
  | DashboardWidgetOptionsSerieDisplayOptionsPieChart
  | DashboardWidgetOptionsSerieDisplayOptionsBigNumber
  | DashboardWidgetOptionsSerieDisplayOptionsSecurityCompliance

export type DashboardWidgetOptionsSerieStat =
  | DashboardWidgetOptionsSerieStatUser
  | DashboardWidgetOptionsSerieStatDeviance
  | DashboardWidgetOptionsSerieStatResolution
  | DashboardWidgetOptionsSerieStatEvent
  | DashboardWidgetOptionsSerieStatComplianceScore
  | DashboardWidgetOptionsSerieStatAttack
  | DashboardWidgetOptionsSerieStatAttackCounts

export type RbacEntityItem =
  | ApplicationSettings
  | AttackPath
  | AttackType
  | Checker
  | Dashboard
  | DashboardWidget
  | Directory
  | Email
  | Infrastructure
  | LdapConfiguration
  | Plugin
  | PluginConfiguration
  | Preferences
  | Profile
  | RbacEntityUiItem
  | RbacRole
  | Relay
  | Report
  | SamlConfiguration
  | Syslog
  | Tenant
  | User

export type RequestErrorDetails =
  | RequestErrorDetailsRbacMissingRelation
  | RequestErrorDetailsRbacMissingPermission

export type DashboardWidgetOptionsSerieStatDataPoint =
  | DashboardWidgetOptionsSerieStatDataPointCount
  | DashboardWidgetOptionsSerieStatDataPointValue
  | DashboardWidgetOptionsSerieStatDataPointAttackCount
