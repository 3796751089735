import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import { BrandColorV2, FontColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

const Color1Path = styled.path`
  fill: ${FontColorV2.primary};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${consts.colorWhite};
  }
`

const Color2Path = styled.path`
  stroke: ${BrandColorV2.decorativeGray};
  stroke-width: 1.5;

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    stroke: ${consts.colorFadedBlueNewDesign};
  }
`

interface IIconIoAProps extends IIconProps {}

const IconIoALegacy: React.FC<IIconIoAProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Color2Path d="M9.935 16.87C13.7651 16.87 16.87 13.7651 16.87 9.935C16.87 6.10491 13.7651 3 9.935 3C6.1049 3 3 6.10491 3 9.935C3 13.7651 6.1049 16.87 9.935 16.87Z" />
      <Color1Path
        d="M13.7838 1.02658H8.43301L5.02661 11.2428H9.40521L6.48594 20L15.2431 8.32321H10.8658L13.7838 1.02658Z"
        fill="#676EA1"
      />
    </svg>
  )
}

export default IconIoALegacy
