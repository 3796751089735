import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelAlt, LabelDate } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import * as React from 'react'
import { LabelAltVariant } from '../LabelAlt'

interface ILabelDevianceResolvedAtProps {
  className?: string
  resolvedAt: Maybe<string>
}

const LabelDevianceResolvedAt: React.FC<
  ILabelDevianceResolvedAtProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['IoE.Details.DeviantObjects']
  })

  return (
    <ContainerFlex
      className={props.className}
      name="LabelDevianceResolvedAt"
      alignItems="center"
      items={[
        <LabelAlt variant={LabelAltVariant.Solved}>
          <span>{translate('Resolved at')}</span>
        </LabelAlt>,
        <LabelAlt variant={LabelAltVariant.Solved}>
          <LabelDate date={props.resolvedAt} />
        </LabelAlt>
      ]}
      spaced
      spaceWidth="verySmall"
    />
  )
}

export default LabelDevianceResolvedAt
