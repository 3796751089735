import type { Query } from '../typeDefs/types'

/**
 * Topology
 */

export type QueryRbacTopology = Pick<Query, 'rbacTopology'>

export const queryRbacTopology = `
  query queryRbacTopology (
    $profileId: Int!
  ) {
    rbacTopology(
      profileId: $profileId
    ) {
      node {
        infrastructures {
          uid
          name
          known
          directories {
            id
            uid
            name
            known
          }
        },
        trusts {
          from
          to
          hazardLevel
          attributes
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

export type QueryRbacTopologyDomainDetails = Pick<
  Query,
  'rbacTopologyDomainDetails'
>

export const queryRbacTopologyDomainDetails = `
  query queryRbacTopologyDomainDetails (
    $profileId: Int!
    $directoryIds: [Int!]!
    $checkerIds: [Int!]!
    $reasonIds: [Int!]!
    $timestampStart: String!
    $timestampEnd: String!
  ) {
    rbacTopologyDomainDetails(
      profileId: $profileId
      directoryIds: $directoryIds
      checkerIds: $checkerIds
      reasonIds: $reasonIds
      timestampStart: $timestampStart
      timestampEnd: $timestampEnd
    ) {
      node {
        timestampStart
        timestampEnd
        profileId
        directoryIds
        checkerIds
        reasonIds
        label
        data {
          timestamp
          value
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
