import {
  ContainerContent,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import { LabelMissingData } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import { CheckerDetailsPageContext } from '../context'
import { canReadIoEVulnerabilityDetails } from '../permissions'

interface IDetailsVulnerabilityDetailsPageProps {}

const DetailsVulnerabilityDetailsPage: React.FC<
  IDetailsVulnerabilityDetailsPageProps
> = () => {
  const parameters = React.useContext(CheckerDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: ['IoE.Details.Menu', 'IoE.Details.VulnerabilityDetails']
  })

  const { storeIoE } = useStores()

  if (!parameters) {
    return null
  }

  const checker = storeIoE.checkers.get(parameters.checkerCodename)

  if (!checker) {
    return <LabelMissingData />
  }

  const vulnerabilityDetail = checker.getVulnerabilityDetail()

  if (!vulnerabilityDetail) {
    return <LabelMissingData />
  }

  return (
    <ContainerContent
      title={translate('Vulnerability details')}
      rbacCapabilityCheck={canReadIoEVulnerabilityDetails(parameters.checkerId)}
    >
      <ContainerHTMLContent labelledBy="vulnerabilityDetails">
        {vulnerabilityDetail.getPropertyAsString('detail', '-')}
      </ContainerHTMLContent>
    </ContainerContent>
  )
}

export default DetailsVulnerabilityDetailsPage
