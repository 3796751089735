import type { Mutation } from './../typeDefs/types'

export type MutationCreateInfrastructure = Pick<
  Mutation,
  'createInfrastructure'
>

export const mutationCreateInfrastructure = `
  mutation mutationCreateInfrastructure($infrastructure: InputCreateInfrastructure!) {
    createInfrastructure(
      infrastructure: $infrastructure
    ) {
      id
      name
      login
    }
  }
`

export type MutationEditInfrastructure = Pick<Mutation, 'editInfrastructure'>

export const mutationEditInfrastructure = `
  mutation mutationEditInfrastructure($infrastructure: InputEditInfrastructure!) {
    editInfrastructure(
      infrastructure: $infrastructure
    ) {
      id
      name
      login
    }
  }
`

export type MutationDeleteInfrastructure = Pick<
  Mutation,
  'deleteInfrastructure'
>

export const mutationDeleteInfrastructure = `
  mutation mutationDeleteInfrastructure($infrastructure: InputDeleteInfrastructure!) {
    deleteInfrastructure(
      infrastructure: $infrastructure
    )
  }
`
