import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconUserProps extends IIconProps {}

const IconUser: React.FC<IIconUserProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 20

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 9.375C11.7259 9.375 13.125 7.97589 13.125 6.25C13.125 4.52411 11.7259 3.125 10 3.125C8.27411 3.125 6.875 4.52411 6.875 6.25C6.875 7.97589 8.27411 9.375 10 9.375Z"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 15.625V15.625C4.375 13.5539 6.05393 11.875 8.125 11.875H11.875C13.9461 11.875 15.625 13.5539 15.625 15.625V15.625"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconUser
