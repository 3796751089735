import { Features } from '@alsid/common'
import { AppRouteName } from '@app/routes'
import type StoreAttacks from '@app/stores/IoA/StoreAttacks'
import { filterNullOrUndefinedValues } from '@libs/helpers/objects/filterNullOrUndefinedValues'
import type { ISubmitFormParameters } from '@libs/submitForm'
import { submitForm } from '@libs/submitForm'
import type { IExportParametersAttacks } from '@server/routers/exportCSVRouter/types'
import type { IAttacksRouteDefinition } from '@server/routes/api'

/**
 * Do a POST query to the export middleware in order to initiate the download
 * of the CSV export.
 */
export const handleExportAttacksOnSubmit =
  (storeAttacks: StoreAttacks) => (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeRoot } = storeAttacks
    const { appRouter } = storeRoot
    const {
      storeMessages,
      storeIoA: { storeBoard },
      storeFeatureFlags
    } = storeAttacks.storeRoot.stores

    const featureIoAExportSpeeding = storeFeatureFlags.getFeatureFlagValue(
      Features.IOA_EXPORT_SPEEDING
    )

    const args = storeAttacks.computedArgsFromFilters

    if (!args) {
      storeMessages.genericError()
      return
    }

    if (featureIoAExportSpeeding) {
      const profileId =
        storeMessages.storeRoot.stores.storeAuthentication.currentProfileId

      const queryArgs: IAttacksRouteDefinition['queryStringParameters'] =
        filterNullOrUndefinedValues({
          dateStart: args.attacksFilters.dateStart,
          dateEnd: args.attacksFilters.dateEnd,
          resourceType: args.attacksFilters.resourceType,
          resourceValue: args.attacksFilters.resourceValue,
          attackTypeIds: args.attacksFilters.attackTypeIds?.map(id =>
            String(id)
          ),
          includeClosed:
            args.attacksFilters.includeClosed === true ? 'true' : 'false',
          search: args.attacksFilters.search,
          order: args.orderBy
        })

      const pathname = appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.MiddlewareAPI_Profiles_Attacks_Export,
        parameters: {
          profileId
        },
        queryStringParameters: queryArgs
      })

      window.open(pathname, '_blank')?.focus()

      return
    }

    // create a mapping between infrastructureId/infrastructure to be able to
    // get the directory name in the report filename
    const infrastructures = Array.from(
      storeBoard.storeInfrastructures.infrastructures.entries()
    ).reduce(
      (acc, [infrastructureId, infrastructure]) => {
        return {
          ...acc,
          [infrastructureId]: infrastructure.getPropertyAsString('name')
        }
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      },
      {} satisfies Record<number, string>
    )

    // create a mapping between directoryId/directoryName to be able to
    // get the directory name in the report filename
    const directories = Array.from(
      storeBoard.storeInfrastructures.directories.entries()
    ).reduce(
      (acc, [directoryId, directory]) => {
        return {
          ...acc,
          [directoryId]: directory.getPropertyAsString('name')
        }
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      },
      {} satisfies Record<number, string>
    )

    const pathname = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareExportCSV,
      parameters: {}
    })

    const parameters: ISubmitFormParameters<IExportParametersAttacks> = {
      parameters: {
        routine: 'Attacks',
        queryArgs: args,
        contextData: {
          infrastructures,
          directories
        }
      },
      language: storeAttacks.storeRoot.appTranslator.language,
      'csrf-token': storeRoot.environment.sessionParams.csrfToken
    }

    submitForm(pathname, parameters, 'POST')
  }
