import type { Maybe } from '@@types/helpers'
import type { StoreRoot } from '@app/stores'
import StoreBase from '@app/stores/StoreBase'
import { action, computed, makeObservable, observable } from 'mobx'
import * as moment from 'moment'
import type { RangePickerSharedProps } from 'rc-picker/lib/RangePicker'
import type { IStoreDatePickerOptions } from './types'

/**
 * Store to be used with InputDate* components.
 */
export default class StoreDatePicker extends StoreBase<IStoreDatePickerOptions> {
  private $datePickerRangeValue =
    observable.box<Maybe<[Maybe<moment.Moment>, Maybe<moment.Moment>]>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreDatePickerOptions) {
    super(storeRoot, options)

    if (!options.defaultDateStart || !options.defaultDateEnd) {
      return
    }

    this.setDatePickerRangeValue([
      options.defaultDateStart,
      options.defaultDateEnd
    ])

    makeObservable(this)
  }

  /* Actions */

  @action
  reset(): this {
    this.$datePickerRangeValue.set(null)
    return this
  }

  @action
  setDatePickerRangeValue(
    datesRange: Array<Maybe<moment.Moment>>,
    unitOfTime: moment.unitOfTime.StartOf = 'day'
  ): this {
    const [dateStartRange, dateEndRange] = datesRange

    if (dateStartRange || dateEndRange) {
      const dateStart = dateStartRange
        ? dateStartRange.clone().utc().startOf(unitOfTime)
        : null
      const dateEnd = dateEndRange
        ? dateEndRange.clone().utc().endOf(unitOfTime)
        : null

      this.$datePickerRangeValue.set([dateStart, dateEnd])
    }

    return this
  }

  /* Computed */

  /**
   * Return date picker ranges with defaults if defined.
   */
  @computed
  get datePickerRangeValue(): RangePickerSharedProps<any>['value'] {
    const [from, to] = this.$datePickerRangeValue.get() ?? []

    if (!from && !to) {
      return !this.options
        ? [undefined, undefined]
        : [this.options.defaultDateStart, this.options.defaultDateEnd]
    }

    if (!from) {
      return !this.options
        ? [undefined, moment(to)]
        : [this.options.defaultDateStart, moment(to)]
    }

    if (!to) {
      return !this.options
        ? [moment(from), undefined]
        : [moment(from), this.options.defaultDateEnd]
    }

    return [moment(from), moment(to)]
  }

  /**
   * Return date picker ranges as moment objects.
   */
  @computed
  get datePickerRangeValueAsMoments(): Maybe<{
    dateStart: Maybe<moment.Moment>
    dateEnd: Maybe<moment.Moment>
  }> {
    const dates = this.datePickerRangeValue

    if (!dates) {
      return null
    }

    const [dateStart, dateEnd] = dates

    if (!(dateStart || dateEnd)) {
      return null
    }

    return { dateStart, dateEnd }
  }

  /**
   * Return date picker ranges as ISO strings.
   */
  @computed
  get datePickerRangeValueAsIsoStrings(): Maybe<{
    dateStart: Maybe<string>
    dateEnd: Maybe<string>
  }> {
    const dates = this.datePickerRangeValueAsMoments

    if (!dates) {
      return null
    }

    return {
      dateStart: dates.dateStart ? dates.dateStart.toISOString() : null,
      dateEnd: dates.dateEnd ? dates.dateEnd.toISOString() : null
    }
  }

  /**
   * Return date picker ranges as Date.
   */
  @computed
  get datePickerRangeValueAsDate(): Maybe<{
    dateStart: Maybe<Date>
    dateEnd: Maybe<Date>
  }> {
    const dates = this.datePickerRangeValueAsMoments

    if (!dates) {
      return null
    }

    return {
      dateStart: dates.dateStart ? dates.dateStart.toDate() : null,
      dateEnd: dates.dateEnd ? dates.dateEnd.toDate() : null
    }
  }
}
