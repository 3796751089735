import { isExpressionContainsIsDeviantTrue } from '@app/components-legacy/Input/InputExpression/Wizard/utils'
import { canSeeDeviances } from '@app/pages/TrailFlow/permissions'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreInputExpression from '@app/stores/helpers/StoreInputExpression'
import StoreBase from '@app/stores/StoreBase'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import type { StoreRoot } from '..'
import StoreDrawer from '../helpers/StoreDrawer'
import StoreMenu from '../helpers/StoreMenu'
import type { IMenuEntry } from '../helpers/StoreMenu/types'
import StoreModal from '../helpers/StoreModal'
import type { IStoreOptions } from '../types'
import StoreDeviances from './StoreDeviances'
import StoreEventDetails from './StoreEventDetails'
import StoreEvents from './StoreEvents'
import StoreSearchBookmarks from './StoreSearchBookmarks'
import StoreSearchHistory, { HistoryMenuKey } from './StoreSearchHistory'
import { EventsTableStatus } from './types'

export default class StoreTrailFlow extends StoreBase {
  /**
   * TrailFlow table
   */
  public storeEvents = new StoreEvents(this.storeRoot)
  public storeDeviances = new StoreDeviances(this.storeRoot)

  public storeInputExpression = new StoreInputExpression(this.storeRoot, {
    enableBookmarks: true,
    enableIsDeviantSwitch: true
  })

  /* Flags */
  public storeFlagsTrailFlow = new StoreFlags(this.storeRoot)
  public storeFlagsReloadTrailFlow = new StoreFlags(this.storeRoot)

  /**
   * Event details
   */

  public storeEventDetails = new StoreEventDetails(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['TrailFlow.Table']
  })

  /**
   * History & Bookmarks
   */
  public storeDrawerHistory = new StoreDrawer(this.storeRoot)
  public storeMenuHistory = new StoreMenu<IMenuEntry<HistoryMenuKey>>(
    this.storeRoot,
    {
      defaultSelectedMenuKey: HistoryMenuKey.History
    }
  )
  public storeSearchHistory = new StoreSearchHistory(this.storeRoot)
  public storeSearchBookmarks = new StoreSearchBookmarks(this.storeRoot)

  public timeoutStopTrailflow: NodeJS.Timeout | null = null

  public storeModalTrailFlowAutomaticPause = new StoreModal(this.storeRoot)

  /**
   * For tests only
   */

  public __hackedComputedForTestsOnly: {
    isLive?: boolean
    isDeviantFilterEnabled?: boolean
  } = {}

  /**
   * Private
   */

  @observable
  private _tableStatus = EventsTableStatus.isStatic

  /* Observable */

  private $isDeviantFilterEnabled = observable.box<boolean>(false)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Load initial data when opening the Trail flow.
   */
  fetchInitialData(): Promise<void> {
    this.storeFlagsTrailFlow.loading()

    return this.storeRoot.stores.storeInfrastructures
      .fetchInfrastructures()
      .then(() => this.storeEvents.fetchEvents())
      .then(() => this.storeDeviances.fetchDeviances())
      .then(() => {
        this._setIsDeviantFilterFromExpression()
        this._registerWS()
        this.storeFlagsTrailFlow.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsTrailFlow, {
          errorMessageTranslationFn: () => false
        })
      )
  }

  /**
   * Reload events after filtering.
   */
  reloadData(): Promise<void> {
    this.storeFlagsReloadTrailFlow.loading()

    this._setIsDeviantFilterFromExpression()

    const wasLive = this.isLive

    // unregister websockets before making the search
    if (wasLive) {
      this.unregisterWS()
    }

    const { storeDatePicker } = this.storeEvents

    return Promise.resolve()
      .then(() => this.storeEvents.fetchEvents())
      .then(() => this.storeDeviances.fetchDeviances())
      .then(() => {
        // because Electra will always send messages of the current time,
        // register the WS again only if the current date is between the selected date range
        // const [dateStart, dateEnd] = storeDatePicker.datePickerRangeValue
        const values = storeDatePicker.datePickerRangeValue

        if (values) {
          const [dateStart, dateEnd] = values

          const isTodayBetweenDateRange =
            !dateStart || !dateEnd || moment().isBetween(dateStart, dateEnd)

          if (wasLive && isTodayBetweenDateRange) {
            this._registerWS()
          }
        }

        this.storeFlagsReloadTrailFlow.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsTrailFlow, {
          errorMessageTranslationFn: () => false
        })
      )
  }

  /**
   * Pause the trail flow (stop live).
   */
  pauseTrailFlow(showMessage: boolean = true): void {
    if (this.isLive && showMessage) {
      this.storeRoot.stores.storeMessages.info(
        this.translate('The Trail Flow has paused'),
        {
          labelledBy: 'trailflowPaused'
        }
      )
    }

    this.storeEvents.unregisterEventWS()

    // stop also the deviances if isDeviant filter enable
    if (this.isDeviantFilterEnabled) {
      this.storeRoot.stores.storeTrailFlow.storeDeviances.unregisterDevianceWS()
    }
  }

  /**
   * Restart the trail flow (restart live).
   */
  restartTrailFlow(): Promise<void> {
    // notify the user
    this.storeRoot.stores.storeMessages.info(
      this.translate('The Trail Flow is restarting'),
      {
        labelledBy: 'trailflowRestarting'
      }
    )

    return Promise.resolve()
      .then(() => this.storeEvents.fetchEvents())
      .then(() => this.storeDeviances.fetchDeviances())
      .then(() => this._registerWS())
  }

  /**
   * Unregister websockets.
   */
  unregisterWS(): void {
    this.storeEvents.unregisterEventWS()
    this.storeDeviances.unregisterDevianceWS()
    this.clearLiveModeTimeout()
  }

  /**
   * Reset data when leaving the trail flow.
   */
  reset(): void {
    this.storeEvents.reset()
    this.storeDeviances.reset()
  }

  setLiveModeTimeout() {
    if (this.timeoutStopTrailflow) {
      clearTimeout(this.timeoutStopTrailflow)
    }

    this.timeoutStopTrailflow = setTimeout(() => {
      this.clearLiveModeTimeout()
      this.pauseTrailFlow(false)
      this.storeModalTrailFlowAutomaticPause.show()
    }, this.storeRoot.environment.config.app.trailflow.inactivityperiodinseconds * 1000)
  }

  clearLiveModeTimeout() {
    if (this.timeoutStopTrailflow) {
      clearTimeout(this.timeoutStopTrailflow)
    }
  }

  @action
  setTableStatus(status: EventsTableStatus) {
    this._tableStatus |= status
  }

  @action
  unsetTableStatus(status: EventsTableStatus) {
    this._tableStatus &= ~status
  }

  /**
   * Private
   */

  /**
   * Register websockets.
   */
  private _registerWS(): void {
    this.storeEvents.registerEventWS()
    this.storeDeviances.registerDevianceWS()
    this.setLiveModeTimeout()
  }

  /**
   * Refresh the isDeviantFilter flag from the current expression.
   */
  private _setIsDeviantFilterFromExpression() {
    const isDeviant = isExpressionContainsIsDeviantTrue(
      this.storeInputExpression.expression
    )

    this._setIsDeviantFilter(isDeviant)
  }

  /* Actions */

  /**
   * Save the isDeviant status to avoid to parse the expression at each deviance.
   * Check the RBAC permissions before applying the filter.
   */
  @action
  private _setIsDeviantFilter(isDeviant: boolean): void {
    if (!this.storeRoot.stores.storeRbac.isUserGrantedTo(canSeeDeviances)) {
      // log for optional debug or reminder
      this.storeRoot.logger.info('You are not authorized to see deviances')
      return
    }

    this.$isDeviantFilterEnabled.set(isDeviant)
  }

  /* Computed values */

  /**
   * Return true if the table is live
   * (and if the websocket connection is alive).
   */
  @computed
  get isLive(): boolean {
    // use for tests only
    if (isDefined(this.__hackedComputedForTestsOnly.isLive)) {
      return this.__hackedComputedForTestsOnly.isLive
    }

    return Boolean(
      this.storeRoot.stores.storeWSIndicator.isConnected &&
        this._tableStatus & EventsTableStatus.isLive
    )
  }

  @computed
  get isDeviantFilterEnabled(): boolean {
    // use for tests only
    if (isDefined(this.__hackedComputedForTestsOnly.isDeviantFilterEnabled)) {
      return this.__hackedComputedForTestsOnly.isDeviantFilterEnabled
    }

    return this.$isDeviantFilterEnabled.get()
  }
}
