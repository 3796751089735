import {
  ContainerContent,
  ContainerFlex,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import { LabelMissingData } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { ensureArray } from '@libs/ensureArray'
import { mdToHTML } from '@libs/mdToHTML'
import * as React from 'react'
import { CheckerIdentityDetailsPageContext } from '../context'
import Resources from '../DetailsInformationsPage/Resources'
import { canReadIoEIdentityRecommandations } from '../permissions'

interface IDetailsRecommendationsPageProps {}

const DetailsRecommendationsPage: React.FC<
  IDetailsRecommendationsPageProps
> = props => {
  const parameters = React.useContext(CheckerIdentityDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: ['IoE.Details.Recommendations']
  })

  const { storeIoE } = useStores()

  if (!parameters) {
    return null
  }

  const checker = storeIoE.checkersIdentity.get(parameters.checkerCodename)

  if (!checker) {
    return <LabelMissingData />
  }

  if (!checker.recommendation) {
    return <LabelMissingData />
  }

  return (
    <ContainerContent rbacCapabilityCheck={canReadIoEIdentityRecommandations}>
      <ContainerFlex
        name="DetailsRecommendationsPage"
        direction="column"
        items={[
          <ContainerContent title={translate('Details')}>
            <ContainerHTMLContent labelledBy="details" padded>
              {mdToHTML(checker.recommendation.detail)}
            </ContainerHTMLContent>
          </ContainerContent>,

          <Resources
            resources={ensureArray(checker.recommendation.resources)}
          />
        ]}
        spaced
        wrapItems
        borderPadding="default"
      />
    </ContainerContent>
  )
}

export default DetailsRecommendationsPage
