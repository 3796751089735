import type { PropertiesNullable } from '@@types/helpers'
import { ensureArray } from '@libs/ensureArray'
import { getFile } from '@libs/getFile'
import { decodeValue } from '@libs/valueTypeParser/decodeValue'
import type { IDecodeOptions, ValueType } from '@libs/valueTypeParser/types'
import type {
  AdObject,
  Change,
  Maybe,
  ObjectAttribute
} from '@server/graphql/typeDefs/types'
import * as diff from 'diff'
import { createEntities, EntityChange, EntityObjectAttribute } from '..'
import EntityBase from '../EntityBase'
import { AdObjectCommonAttribute, AdObjectType } from './types'

export default class EntityAdObject
  extends EntityBase
  implements PropertiesNullable<AdObject>
{
  id: Maybe<number> = null
  type: Maybe<string> = null
  directoryId: Maybe<number> = null
  objectId: Maybe<string> = null
  reasons: Maybe<number[]> = null

  objectAttributes: Maybe<ObjectAttribute[]> = null
  current: Maybe<AdObject> = null
  changes: Maybe<Change[]> = null

  constructor(data: Partial<AdObject>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return changes of the adObject.
   */
  getChanges(): EntityChange[] {
    return createEntities<Change, EntityChange>(EntityChange, this.changes)
  }

  /**
   * Return attributes of the adObject.
   */
  getAttributes(): EntityObjectAttribute[] {
    return createEntities<ObjectAttribute, EntityObjectAttribute>(
      EntityObjectAttribute,
      this.objectAttributes
    )
  }

  /**
   * Return the decoded value of an attribute at the current state.
   */
  getAttributeCurrentValue(
    attributeName: string,
    decodeOptions: IDecodeOptions = {}
  ): string | string[] {
    if (!this.current) {
      return decodeOptions.fallback || ''
    }

    const objectAttribute = this.current.objectAttributes
      .filter(a => a.name === attributeName)
      .pop()

    if (!objectAttribute) {
      return decodeOptions.fallback || ''
    }

    return decodeValue(
      objectAttribute.value,
      objectAttribute.valueType as ValueType,
      decodeOptions
    )
  }

  /**
   * Return the decoded value of an attribute before the change.
   */
  getAttributeBeforeValue(
    attributeName: string,
    decodeOptions: IDecodeOptions = {}
  ): string | string[] {
    const change = ensureArray(this.changes)
      .filter(c => c.attributeName === attributeName)
      .pop()

    if (!change) {
      return decodeOptions.fallback || ''
    }

    return decodeValue(
      change.values.before,
      change.valueType as ValueType.string,
      decodeOptions
    )
  }

  /**
   * Return the decoded value of an attribute after the change.
   * In fact, this is an alias of getAttributeValueAtEvent.
   */
  getAttributeAfterValue(
    attributeName: string,
    decodeOptions: IDecodeOptions = {}
  ): string | string[] {
    return this.getAttributeValueAtEvent(attributeName, decodeOptions)
  }

  /**
   * Return the decoded value of an attribute for the current event.
   */
  getAttributeValueAtEvent(
    attributeName: string,
    decodeOptions: IDecodeOptions = {}
  ): string | string[] {
    const objectAttribute = ensureArray(this.objectAttributes)
      .filter(a => a.name === attributeName)
      .pop()

    if (!objectAttribute) {
      return decodeOptions.fallback || ''
    }

    return decodeValue(
      objectAttribute.value,
      objectAttribute.valueType as ValueType.string,
      decodeOptions
    )
  }

  /**
   * Return the diff before and after the change of the decoded value of an attribute.
   */
  getDiffBetweenBeforeAndAtEvent(
    attributeName: string,
    decodeOptions: IDecodeOptions = {}
  ): diff.Change[] {
    const beforeValue = this.getAttributeBeforeValue(
      attributeName,
      decodeOptions
    )
    const valueAtEvent = this.getAttributeValueAtEvent(
      attributeName,
      decodeOptions
    )
    return diff.diffWords(String(beforeValue), String(valueAtEvent))
  }

  /**
   * Return the class or the extension of the path depending of the object type.
   */
  getClassOrFile(): Maybe<string> {
    const decodeOptions: IDecodeOptions = {
      lastValueOnly: true
    }

    if (this.type === AdObjectType.LDAP) {
      return ensureArray(
        this.getAttributeValueAtEvent(
          AdObjectCommonAttribute.objectclass,
          decodeOptions
        )
      ).join(', ')
    }

    const value = this.getAttributeValueAtEvent(
      AdObjectCommonAttribute.globalpath,
      decodeOptions
    )

    return getFile(String(value))
  }

  /**
   * Return the id
   */
  getId(): Maybe<number> {
    return this.id
  }

  /**
   * If type is LDAP,
   *  return the decoded globalpath (JSON => string)
   *
   * return the decoded distinguishedname
   */
  getDNOrGlobalPath(): string {
    const value =
      this.type === AdObjectType.LDAP
        ? this.getAttributeValueAtEvent(
            AdObjectCommonAttribute.distinguishedname
          )
        : this.getAttributeValueAtEvent(AdObjectCommonAttribute.globalpath)

    return String(value)
  }
}
