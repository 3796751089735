import { deepStrictEqual } from 'assert'
import type { EntityGenericCheckerOption } from '@app/entities/EntityGenericCheckerOption/types'
import { isNotFalsy } from '@libs/isDefined'
import { ValueType } from '@libs/valueTypeParser/types'

/**
 * Return true if both options are equal, the check depends of their valueType.
 */
export function isOptionsValueEqual(
  commitOption: EntityGenericCheckerOption,
  stagedOption: EntityGenericCheckerOption
): boolean {
  // catch assert.deepStrictEqual
  try {
    switch (commitOption.valueType) {
      case ValueType.object:
      case ValueType.arrayString:
      case ValueType.arrayCron:
      case ValueType.arrayRegex:
      case ValueType.arrayInteger:
      case ValueType.arrayBoolean:
      case ValueType.arraySelect:
      case ValueType.arrayObject: {
        let value1 = JSON.parse(commitOption.getPropertyAsString('value'))
        let value2 = JSON.parse(stagedOption.getPropertyAsString('value'))

        if (Array.isArray(value1)) {
          value1 = value1.filter(isNotFalsy)
        }

        if (Array.isArray(value2)) {
          value2 = value2.filter(isNotFalsy)
        }

        deepStrictEqual(value1, value2)

        // if no assertion exception, both value are equal
        return true
      }

      default:
        return commitOption.value === stagedOption.value
    }
  } catch (err) {
    return false
  }
}
