// Define the scale of the Scene:
// The greater the value, the larger will be the infrastructure sizes.
export const SCENE_SCALE_DIVIDER = 10

export const SCENE_INFRASTRUCTURE_SPACING = 200
export const SCENE_INFRASTRUCTURE_MAX_SIZE = 100

export const SCENE_DIRECTORY_WIDTH = 20
export const SCENE_DIRECTORY_OFFSET_Y = 6

export const SCENE_DOMAIN_TRIANGLE_LONG = 20
export const SCENE_DOMAIN_TRIANGLE_HEIGHT = 15

export const SCENE_TOOLIP_DEBOUNCE_DELAY = 200

export const SCENE_ZOOM_MAX_VALUE = 5
export const SCENE_ZOOM_INITIAL_VALUE = 0.75
