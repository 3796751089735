import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface IInputTextEndingProps {
  className?: string
}

const InputTextBlurEnding: React.FC<IInputTextEndingProps> = props => {
  return (
    <div data-name={InputTextBlurEnding.name} className={props.className} />
  )
}

export default styled(InputTextBlurEnding)`
  position: absolute;
  top: 0;
  left: -5px;
  width: 5px;
  height: 30px;
  box-shadow: 0px 0px 5px 5px ${consts.colorWhiteAlt};
  background-color: ${consts.colorWhiteAlt};
`
