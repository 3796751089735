import { ContainerHTMLContent } from '@app/components/Container'
import { IconWarningCircle } from '@app/components/Icon'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { WrapperAlert } from '@app/components/Wrapper'
import { AlertSize, AlertVariant } from '@app/components/Wrapper/types'
import { useAppTranslator, useStores } from '@app/hooks'
import { FontColorV2 } from '@app/styles/consts'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'

const StyledAlert = styled(WrapperAlert)`
  width: 100%;

  .ant-alert {
    text-align: left;
  }
`

interface IAlertAutomaticUpdateDisabledProps {}

const AlertAutomaticUpdateDisabled: React.FC<
  IAlertAutomaticUpdateDisabledProps
> = () => {
  const { storeManagementApplicationSettings } = useStores()

  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.IoA']
  })

  if (
    storeManagementApplicationSettings.applicationSettings?.getPropertyAsBoolean(
      'ioaAutomaticConfiguration'
    )
  ) {
    return null
  }

  return (
    <StyledAlert
      name="IoaAlertAutomaticUpdateDisabled"
      labelledBy="ioaAlertAutomaticUpdateDisabled"
      variant={AlertVariant.error}
      alertProps={{
        showIcon: true,
        icon: <IconWarningCircle />,
        message: (
          <Label variant={LabelVariant.p} color={FontColorV2.red}>
            <ContainerHTMLContent>
              {translate(
                'Disabled automatic update of Indicators of Attack configuration',
                { transformMarkdown: true }
              )}
            </ContainerHTMLContent>
          </Label>
        )
      }}
      size={AlertSize.large}
    />
  )
}
export default observer(AlertAutomaticUpdateDisabled)
