import type { Query } from '../typeDefs/types'

/**
 * Retrieve the list of bookmarks.
 */
export type QuerySearchBookmarksEntries = Pick<Query, 'searchBookmarks'>

export const querySearchBookmarksEntries = `
  query querySearchBookmarks {
    searchBookmarks {
      id
      expression
      name
      category
    }
  }
`
