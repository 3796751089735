import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { EventDetailsPageContext } from '../contexts'
import EventDetailsAttributesPage from '../EventDetailsAttributesPage'
import DrawerAttributeValue from '../EventDetailsAttributesPage/DrawerAttributeValue'
import DrawerSDDLDescription from '../EventDetailsAttributesPage/DrawerSDDLDescription'
import EventDetailsDeviancesPage from '../EventDetailsDeviancesPage'
import { canAccessToEventDetails, canSeeDeviances } from '../permissions'
import EventDetailsHeader from './EventDetailsHeader'
import EventDetailsMenu from './EventDetailsMenu'
import { onEventDetailsLoad, onEventDetailsUnload } from './handlers'

interface IEventDetailsPageProps {}

const EventDetailsPage: React.FC<IEventDetailsPageProps> = () => {
  const appRouter = useAppRouter()

  const { storeTrailFlow } = useStores()

  const { storeEventDetailsAttributes } = storeTrailFlow.storeEventDetails

  const attributesParameters = appRouter.getRouteParameters({
    routeName: AppRouteName.TrailFlow_EventDetails_Attributes,
    parameters: {
      infrastructureId: Number(),
      directoryId: Number(),
      eventId: String()
    }
  })

  const deviancesParameters = appRouter.getRouteParameters({
    routeName: AppRouteName.TrailFlow_EventDetails_Deviances,
    parameters: {
      infrastructureId: Number(),
      directoryId: Number(),
      eventId: String()
    }
  })

  const parameters = attributesParameters || deviancesParameters

  if (!parameters) {
    return null
  }

  return (
    <EventDetailsPageContext.Provider value={parameters}>
      <Blade
        routeDefinition={{
          routeName: AppRouteName.TrailFlow_EventDetails,
          parameters: {
            infrastructureId: parameters.infrastructureId,
            directoryId: parameters.directoryId,
            eventId: parameters.eventId
          }
        }}
        onLoad={onEventDetailsLoad(storeTrailFlow)(
          parameters.infrastructureId,
          parameters.directoryId,
          parameters.eventId
        )}
        onUnload={onEventDetailsUnload(storeTrailFlow)}
        flags={[
          storeTrailFlow.storeFlagsTrailFlow.flags,
          storeTrailFlow.storeEventDetails.storeFlagsEventDetails.flags
        ]}
        layout={{
          name: 'default',
          header: <EventDetailsHeader />,
          menu: <EventDetailsMenu />,
          content: (
            <>
              <RbacRoutes
                routes={[
                  {
                    routeDefinition: {
                      routeName: AppRouteName.TrailFlow_EventDetails_Attributes,
                      parameters: {
                        infrastructureId: Number(),
                        directoryId: Number(),
                        eventId: String()
                      }
                    },
                    component: EventDetailsAttributesPage,
                    rbacPermissionsCheck: () => canAccessToEventDetails
                  },
                  {
                    routeDefinition: {
                      routeName: AppRouteName.TrailFlow_EventDetails_Deviances,
                      parameters: {
                        infrastructureId: Number(),
                        directoryId: Number(),
                        eventId: String()
                      }
                    },
                    component: EventDetailsDeviancesPage,
                    rbacPermissionsCheck: () => canSeeDeviances
                  }
                ]}
                redirect
              />

              <Portal name={PlaceHolderName.genericDrawer}>
                <DrawerSDDLDescription
                  storeDrawer={
                    storeEventDetailsAttributes.storeDrawerSDDLDescription
                  }
                />
              </Portal>

              <Portal name={PlaceHolderName.genericDrawer}>
                <DrawerAttributeValue
                  storeDrawer={
                    storeEventDetailsAttributes.storeDrawerAttributeValue
                  }
                />
              </Portal>
            </>
          ),
          footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
        }}
        rbacCapabilityCheck={canAccessToEventDetails}
      />
    </EventDetailsPageContext.Provider>
  )
}

export default observer(EventDetailsPage)
