import { Features } from '@alsid/common'
import {
  createEntities,
  createEntity,
  EntityRbacEntity,
  EntityRbacEntityItem,
  EntityRbacPermission,
  EntityRbacRole
} from '@app/entities'
import type { IDataRowRbacEntityItem } from '@app/entities/EntityRbacEntityItem'
import type { IDataRowRbacRole } from '@app/entities/EntityRbacRole'
import type { StoreRoot } from '@app/stores'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreForm from '@app/stores/helpers/StoreForm'
import { mandatory } from '@app/stores/helpers/StoreForm/validators'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import StoreMenu from '@app/stores/helpers/StoreMenu'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import StoreModal from '@app/stores/helpers/StoreModal'
import StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import type { IWidgetListDataSet } from '@app/stores/helpers/StoreWidgetList/types'
import {
  isRbacAdminRole,
  isRoleReadOnly
} from '@app/stores/Management/Rbac/functions'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { ensureArray } from '@libs/ensureArray'
import { ForbiddenAccessError } from '@libs/errors'
import {
  handleMaybeRequestErrorResults,
  handleStoreError
} from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import {
  buildRbacPermissionKey,
  checkRbac,
  getRbacSideEffectPermissions,
  isRbacSelfUserEntity,
  isRbacSingletonEntity,
  isRbacUIEntity,
  mergeRbacCheckerOptionsPermissions
} from '@libs/rbac/functions'
import type {
  RbacEntityId,
  RbacPermissionKey,
  RbacPermissions
} from '@libs/rbac/types'
import {
  RbacEntityItemType,
  RbacPermissionType,
  RbacRoleFormFieldName
} from '@libs/rbac/types'
import type {
  MutationCreateRbacRole,
  MutationDeleteRbacRole,
  MutationEditRbacRole
} from '@server/graphql/mutations/rbac'
import {
  mutationCreateRbacRole,
  mutationDeleteRbacRole,
  mutationEditRbacRole
} from '@server/graphql/mutations/rbac'
import type {
  QueryEntitiesRbac,
  QueryRbacRoles
} from '@server/graphql/queries/rbac'
import { queryRbacEntities, queryRbacRoles } from '@server/graphql/queries/rbac'
import type {
  CreateRbacRoleMutationArgs,
  DeleteRbacRoleMutationArgs,
  EditRbacRoleMutationArgs,
  InputCreateRbacRole,
  InputEditRbacRole,
  Maybe,
  RbacEntity,
  RbacEntityItem,
  RbacRole
} from '@server/graphql/typeDefs/types'
import { RbacAction, RbacEntityName } from '@server/graphql/typeDefs/types'
import { remove } from 'lodash'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import { filterRbacEntityItem, filterStoreWidgetLists } from './functions'
import { StoreWidgetListEntitiesMeta } from './types'

export default class StoreRbacRoles extends StoreBase {
  // store of the entities input search
  public storeInputSearchRbacEntityItems = new StoreInputSearch(this.storeRoot)

  // store of the search filter input
  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  // list of roles
  public storeWidgetListRbacRoles = new StoreWidgetList<
    EntityRbacRole,
    IDataRowRbacRole
  >(this.storeRoot, {
    selectable: false,
    buildDataSetFn: roles => {
      if (!roles.length) {
        return {
          columns: [],
          rows: []
        }
      }

      const columns = roles[0].getColumns()
      const rows = roles
        .filter(role => {
          return (
            this.storeInputSearch.transformedSearchValueAsRegexp.test(
              role.name ?? ''
            ) ||
            this.storeInputSearch.transformedSearchValueAsRegexp.test(
              role.description ?? ''
            )
          )
        })
        .map(entity => entity.asDataRow())
        .filter(isDefined)

      return { columns, rows }
    }
  })

  // lists of rbacEntities (one by entityName)
  public storesWidgetListEntityItems: Map<
    RbacEntityName | RbacEntityItemType,
    StoreWidgetList<EntityRbacEntityItem, IDataRowRbacEntityItem>
  > = new Map()

  // forms
  public storeForm = new StoreForm<RbacRoleFormFieldName>(this.storeRoot, {
    setup: {
      fields: {
        [RbacRoleFormFieldName.name]: {
          label: 'Name',
          validators: [mandatory()]
        },
        [RbacRoleFormFieldName.description]: {
          label: 'Description',
          validators: [mandatory()]
        }
      }
    }
  })

  // flags
  public storeFetchRbacEntitiesFlags = new StoreFlags(this.storeRoot)
  public storeFetchRbacRolesFlags = new StoreFlags(this.storeRoot)
  public storeRefreshRbacRolesFlags = new StoreFlags(this.storeRoot)
  public storeCreateRbacRoleFlags = new StoreFlags(this.storeRoot)
  public storeApplyRbacRoleFlags = new StoreFlags(this.storeRoot)
  public storeEditRbacRoleFlags = new StoreFlags(this.storeRoot)
  public storeDeletionRbacRoleFlags = new StoreFlags(this.storeRoot)

  // drawers
  public storeDrawerDeleteRole = new StoreDrawer<{
    rbacRoleDataRow: IDataRowRbacRole
  }>(this.storeRoot)

  // modals
  public storeModalEditSelfUser = new StoreModal(this.storeRoot)

  // current selected tab of entities item type (Data, Singleton, UI)
  public storeMenu = new StoreMenu<IMenuEntry<RbacEntityItemType>>(
    this.storeRoot,
    {
      defaultSelectedMenuKey: RbacEntityItemType.data
    }
  )

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: [
      'Errors',
      'Management.Accounts.Roles',
      'Management.Accounts.Roles.Permissions'
    ]
  })

  /* Observables */

  // permissions changed that need to be saved
  private $pendingRbacPermissions = observable.map<
    RbacPermissionKey,
    EntityRbacPermission
  >()

  // keys are role id
  private $rbacRoles = observable.map<number, EntityRbacRole>()

  // current RoleId that being edited
  private $currentRbacRoleId = observable.box<Maybe<number>>(null)

  // state of the "only granted" checkbox
  private $onlyGrantedCheckboxValue = observable.box<boolean>(false)

  // visibility state for each entity item lists (only for data items)
  private $rbacEntityItemListsVisibility = observable.map<
    RbacEntityName,
    boolean
  >()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch Rbac entities with associated items.
   */
  fetchRbacEntities(): Promise<any> {
    this.storeFetchRbacEntitiesFlags.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryEntitiesRbac>(queryRbacEntities)
      })
      .then(data => data.entitiesRbac)
      .then(rbacEntities => {
        if (!rbacEntities) {
          throw new Error('Rbac entities are not defined')
        }

        this.saveRbacEntityItems(rbacEntities)

        this.storeFetchRbacEntitiesFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFetchRbacEntitiesFlags))
  }

  /**
   * Fetch roles.
   */
  fetchRbacRoles(options?: { refresh: boolean }): Promise<void> {
    const storeFlags =
      options && options.refresh
        ? this.storeRefreshRbacRolesFlags
        : this.storeFetchRbacRolesFlags

    storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryRbacRoles>(queryRbacRoles)
      })
      .then(data => data.rbacRoles)
      .then(rbacRoles => {
        if (!checkRbac(this.storeRoot, storeFlags)(rbacRoles)) {
          throw new ForbiddenAccessError()
        }

        const rbacRolesEntities = createEntities<RbacRole, EntityRbacRole>(
          EntityRbacRole,
          ensureArray(rbacRoles.node)
        )

        this.setRbacRoles(rbacRolesEntities)
        this.storeWidgetListRbacRoles.setEntities(rbacRolesEntities)

        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Create a role.
   */
  createRole(role: InputCreateRbacRole) {
    this.storeCreateRbacRoleFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: CreateRbacRoleMutationArgs = {
          role
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationCreateRbacRole>(mutationCreateRbacRole, args)
      })
      .then(response => {
        this.setCurrentRole(response.createRbacRole.id)
        return this.fetchRbacRoles()
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Role X created', {
            interpolations: {
              roleName: role.name
            }
          }),
          {
            labelledBy: 'roleCreated'
          }
        )

        this.storeCreateRbacRoleFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeCreateRbacRoleFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when creating the role'
        })
      )
  }

  /**
   * Edit an role.
   */
  editRole(
    role: InputEditRbacRole,
    options?: {
      applyOnly: boolean
    }
  ) {
    const storeFlag =
      options && options.applyOnly
        ? this.storeApplyRbacRoleFlags
        : this.storeEditRbacRoleFlags

    storeFlag.loading()

    return Promise.resolve()
      .then(() => {
        const args: EditRbacRoleMutationArgs = {
          role
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationEditRbacRole>(mutationEditRbacRole, args)
      })
      .then(response =>
        handleMaybeRequestErrorResults(
          'An error has occurred when editing the role'
        )(response.editRbacRole)
      )
      .then(() => {
        const refresh = Boolean(options && options.applyOnly)
        return this.fetchRbacRoles({ refresh })
      })
      .then(() => {
        const entityRole = this.$rbacRoles.get(role.id)

        // role.name can be undefined if not modified
        const roleName =
          role.name || (entityRole && entityRole.getPropertyAsString('name'))

        if (roleName) {
          this.storeRoot.stores.storeMessages.success(
            this.translate('Role X updated', {
              interpolations: { roleName }
            }),
            {
              labelledBy: 'roleUpdated'
            }
          )
        }

        storeFlag.success()
      })
      .catch(
        handleStoreError(this.storeRoot, storeFlag, {
          forwardExceptionFn: () =>
            'An error has occurred when editing the role'
        })
      )
  }

  /**
   * Delete a role.
   */
  deleteRole(roleId: number, roleName: string) {
    this.storeDeletionRbacRoleFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: DeleteRbacRoleMutationArgs = {
          role: {
            id: roleId
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationDeleteRbacRole>(mutationDeleteRbacRole, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Role X deleted', {
            interpolations: {
              roleName
            }
          }),
          {
            labelledBy: 'roleDeleted'
          }
        )

        this.storeDeletionRbacRoleFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeDeletionRbacRoleFlags, {
          errorMessageTranslationFn: () => {
            return 'An error has occurred when deleting the role'
          }
        })
      )
  }

  /**
   * Return true if there is at least one permission for all entities for
   * the passed rbacEntityName and rbacAction.
   */
  hasAllEntitiesPendingRbacPermission(
    rbacEntityName: RbacEntityName,
    rbacAction: RbacAction
  ) {
    return Array.from(this.$pendingRbacPermissions.values())
      .filter(pendingRbacPermission => {
        return (
          pendingRbacPermission.entityName === rbacEntityName &&
          pendingRbacPermission.action === rbacAction
        )
      })
      .some(
        pendingRbacPermission => !isDefined(pendingRbacPermission.entityIds)
      )
  }

  /**
   * Return true is some Rbac entity items of type rbacEntityItemType are selected.
   */
  isSomeRbacEntityItemsSelected(
    rbacEntityItemType: Maybe<RbacEntityItemType>
  ): boolean {
    if (!rbacEntityItemType) {
      return false
    }

    return filterStoreWidgetLists(this.storesWidgetListEntityItems)(
      rbacEntityItemType
    ).some(storeWidgetList => storeWidgetList.isSomeRowsSelected)
  }

  /**
   * Return true is all Rbac entity items of type rbacEntityItemType are selected.
   */
  isAllRbacEntityItemsSelected(
    rbacEntityItemType: Maybe<RbacEntityItemType>
  ): boolean {
    if (!rbacEntityItemType) {
      return false
    }

    const storeWildgetLists = filterStoreWidgetLists(
      this.storesWidgetListEntityItems
    )(rbacEntityItemType)

    if (!storeWildgetLists.length) {
      return false
    }

    return storeWildgetLists.every(
      storeWidgetList => storeWidgetList.isAllRowsSelected
    )
  }

  /**
   * Select all Rbac entity items of type rbacEntityItemType.
   */
  toggleSelectAllEntityItems(rbacEntityItemType: RbacEntityItemType): void {
    const selectAction = this.isAllRbacEntityItemsSelected(rbacEntityItemType)
      ? 'unselect'
      : 'select'

    return filterStoreWidgetLists(this.storesWidgetListEntityItems)(
      rbacEntityItemType
    ).forEach(storeWidgetList => {
      selectAction === 'select'
        ? storeWidgetList.selectAllRows()
        : storeWidgetList.unselectAllRows()
    })
  }

  /**
   * Private
   */

  /**
   * Save Rbac entities items in each widgetList stores (one by entityName)
   */
  private saveRbacEntityItems(allRbacEntities: RbacEntity[]): void {
    const remainingRbacEntities = allRbacEntities.slice(0)

    const isRbacSingletonEntityFn = isRbacSingletonEntity(remainingRbacEntities)

    const rbacEntitiesUI = remove(remainingRbacEntities, rbacEntity =>
      isRbacUIEntity(rbacEntity.entityName)
    )

    this.saveRbacEntityItemsUI(rbacEntitiesUI)

    const rbacEntitiesSelfUser = remove(remainingRbacEntities, rbacEntity =>
      isRbacSelfUserEntity(rbacEntity.entityName)
    )

    this.saveRbacEntityItemsSelfUser(rbacEntitiesSelfUser)

    const rbacEntitiesSingleton = remove(remainingRbacEntities, rbacEntity =>
      isRbacSingletonEntityFn(rbacEntity.entityName)
    )

    this.saveRbacEntityItemsSingleton(rbacEntitiesSingleton)

    this.saveRbacEntityItemsData(remainingRbacEntities)
  }

  /**
   * Save Rbac entities items of type "data" in widgetList store.
   */
  private saveRbacEntityItemsData(rbacEntities: RbacEntity[]): void {
    const { storeRbac } = this.storeRoot.stores

    rbacEntities.forEach(rbacEntity => {
      const rbacEntityName = rbacEntity.entityName

      const storeWidgetList = new StoreWidgetList<
        EntityRbacEntityItem,
        IDataRowRbacEntityItem
      >(this.storeRoot, {
        filterEntitiesFn: rbacEntityItems => {
          // don't use function ref directly to not break reactivity (dunno why)
          return filterRbacEntityItem(
            this.pendingRbacPermissions,
            this.translate
          )(
            this.onlyGrantedCheckboxValue,
            this.storeInputSearchRbacEntityItems.transformedSearchValueAsRegexp
          )(RbacEntityItemType.data, rbacEntityItems)
        },
        // don't allow to select entities for the Admin role, or if the user can only read the role
        selectable:
          !isRbacAdminRole(this.currentRbacRole) &&
          !isRoleReadOnly(this.currentRbacRole, storeRbac),
        // add extra namespaces to override page selector translations
        translatorNamespaces: ['Management.Accounts.Roles.Permissions']
      })

      const rbacEntityEntity = createEntity<RbacEntity, EntityRbacEntity>(
        EntityRbacEntity,
        rbacEntity
      )

      const items = rbacEntity.items.map(item => {
        return {
          ...item,
          type: RbacEntityItemType.data
        }
      })

      const rbacEntityItemEntities = items.length
        ? createEntities<
            RbacEntityItem,
            EntityRbacEntityItem,
            EntityRbacEntity
          >(EntityRbacEntityItem, items, rbacEntityEntity)
        : // if there is no items, create an "empty item" with an id equal to -1
          // to make the default permissions (read, edit, create) editable
          // by the bulk selector.
          // "Empty item" permissions are hidden React side and filtered when
          // permissions are applyed.
          [
            new EntityRbacEntityItem(
              {
                id: -1,
                type: RbacEntityItemType.data
              },
              rbacEntityEntity
            )
          ]

      storeWidgetList.setEntities(rbacEntityItemEntities)

      // set the type of item entities to the meta of the storeWidgetList
      // useful to know which kind of entities we have when there is no rows
      storeWidgetList.meta.set({
        key: StoreWidgetListEntitiesMeta.rbacEntityName,
        value: rbacEntityName
      })

      this.storesWidgetListEntityItems.set(rbacEntityName, storeWidgetList)
      this.setRbacEntityItemListVisibility(rbacEntityName, true)
    })
  }

  /**
   * Save Rbac entities items of type "self-user" in widgetList store.
   */
  private saveRbacEntityItemsSelfUser(rbacEntities: RbacEntity[]): void {
    const { storeRbac } = this.storeRoot.stores

    const storeWidgetList = new StoreWidgetList<
      EntityRbacEntityItem,
      IDataRowRbacEntityItem
    >(this.storeRoot, {
      // because no ID is available for self-user items
      getIdFn: row => row.name,
      filterEntitiesFn: rbacEntityItems => {
        // don't use function ref directly to not break reactivity (dunno why)
        return filterRbacEntityItem(
          this.pendingRbacPermissions,
          this.translate
        )(
          this.onlyGrantedCheckboxValue,
          this.storeInputSearchRbacEntityItems.transformedSearchValueAsRegexp
        )(RbacEntityItemType.userPersonal, rbacEntityItems)
      },
      // don't allow to select entities for the Admin role, or if the user can only read the role
      selectable:
        !isRbacAdminRole(this.currentRbacRole) &&
        !isRoleReadOnly(this.currentRbacRole, storeRbac)
    })

    const rbacEntityItemEntities = rbacEntities.map(rbacEntity => {
      const rbacEntityEntity = createEntity<RbacEntity, EntityRbacEntity>(
        EntityRbacEntity,
        rbacEntity
      )

      const item = {
        name: rbacEntity.entityName,
        type: RbacEntityItemType.userPersonal
      }

      return createEntity<
        RbacEntityItem,
        EntityRbacEntityItem,
        EntityRbacEntity
      >(EntityRbacEntityItem, item, rbacEntityEntity)
    })

    storeWidgetList.setEntities(rbacEntityItemEntities)

    this.storesWidgetListEntityItems.set(
      RbacEntityItemType.userPersonal,
      storeWidgetList
    )
  }

  /**
   * Save Rbac entities items of type "singleton" in widgetList store.
   */
  private saveRbacEntityItemsSingleton(rbacEntities: RbacEntity[]): void {
    const { storeRbac } = this.storeRoot.stores

    const storeWidgetList = new StoreWidgetList<
      EntityRbacEntityItem,
      IDataRowRbacEntityItem
    >(this.storeRoot, {
      // because no ID is available for singleton items
      getIdFn: row => row.name,
      filterEntitiesFn: rbacEntityItems => {
        // don't use function ref directly to not break reactivity (dunno why)
        return filterRbacEntityItem(
          this.pendingRbacPermissions,
          this.translate
        )(
          this.onlyGrantedCheckboxValue,
          this.storeInputSearchRbacEntityItems.transformedSearchValueAsRegexp
        )(RbacEntityItemType.singleton, rbacEntityItems)
      },
      // don't allow to select entities for the Admin role, or if the user can only read the role
      selectable:
        !isRbacAdminRole(this.currentRbacRole) &&
        !isRoleReadOnly(this.currentRbacRole, storeRbac)
    })

    const rbacEntityItemEntities = rbacEntities.map(rbacEntity => {
      const rbacEntityEntity = createEntity<RbacEntity, EntityRbacEntity>(
        EntityRbacEntity,
        rbacEntity
      )

      const item = {
        name: rbacEntity.entityName,
        type: RbacEntityItemType.singleton
      }

      return createEntity<
        RbacEntityItem,
        EntityRbacEntityItem,
        EntityRbacEntity
      >(EntityRbacEntityItem, item, rbacEntityEntity)
    })

    storeWidgetList.setEntities(rbacEntityItemEntities)

    this.storesWidgetListEntityItems.set(
      RbacEntityItemType.singleton,
      storeWidgetList
    )
  }

  /**
   * Save Rbac entities items of type "ui" in widgetList store.
   */
  private saveRbacEntityItemsUI(rbacEntities: RbacEntity[]): void {
    const { storeRbac } = this.storeRoot.stores

    const storeWidgetList = new StoreWidgetList<
      EntityRbacEntityItem,
      IDataRowRbacEntityItem
    >(this.storeRoot, {
      // because no ID is available for UI items
      getIdFn: row => row.name,
      filterEntitiesFn: rbacEntityItems => {
        // don't use function ref directly to not break reactivity (dunno why)
        return filterRbacEntityItem(
          this.pendingRbacPermissions,
          this.translate
        )(
          this.onlyGrantedCheckboxValue,
          this.storeInputSearchRbacEntityItems.transformedSearchValueAsRegexp
        )(RbacEntityItemType.ui, rbacEntityItems)
      },
      // define a custom buildDataSet function to force alpha-order
      buildDataSetFn: (
        entities
      ): IWidgetListDataSet<IDataRowRbacEntityItem> => {
        if (!entities.length) {
          return {
            columns: [],
            rows: []
          }
        }

        const filterToggledRbacEntities =
          (featureFlagName: Features, rbacEntityName: RbacEntityName) =>
          (dataRowRbacEntityItem: IDataRowRbacEntityItem): boolean => {
            return (
              storeRbac.isUserGrantedAccordingFeatureFlag(featureFlagName) ||
              dataRowRbacEntityItem.name !== rbacEntityName
            )
          }

        const columns = entities[0].getColumns()
        const rows = entities
          .map(entity => entity.asDataRow())
          .filter(isDefined)
          .filter(
            filterToggledRbacEntities(
              Features.REMOVE_ATTACKS,
              RbacEntityName.UiIoaRemoveAttack
            )
          )
          .sort((a, b) => (a.name > b.name ? 1 : -1))

        return { columns, rows }
      },
      // don't allow to select entities for the Admin role, or if the user can only read the role
      selectable:
        !isRbacAdminRole(this.currentRbacRole) &&
        !isRoleReadOnly(this.currentRbacRole, storeRbac)
    })

    const rbacEntityItemEntities = rbacEntities.map(rbacEntity => {
      const rbacEntityEntity = createEntity<RbacEntity, EntityRbacEntity>(
        EntityRbacEntity,
        rbacEntity
      )

      const item = { name: rbacEntity.entityName, type: RbacEntityItemType.ui }

      return createEntity<
        RbacEntityItem,
        EntityRbacEntityItem,
        EntityRbacEntity
      >(EntityRbacEntityItem, item, rbacEntityEntity)
    })

    storeWidgetList.setEntities(rbacEntityItemEntities)

    this.storesWidgetListEntityItems.set(RbacEntityItemType.ui, storeWidgetList)
  }

  /**
   * Actions
   */

  /**
   * Reset the store.
   */
  @action
  reset(): this {
    this.storeWidgetListRbacRoles.resetSelection()
    this.resetPendingRbaPermissions()

    return this
  }

  /**
   * Reset the store when leaving role edition.
   */
  @action
  resetRoleEdition(): this {
    // list
    Array.from(this.storesWidgetListEntityItems.values()).forEach(store =>
      store.reset()
    )

    // current rbac role id
    this.$currentRbacRoleId.set(null)

    // search
    this.storeInputSearchRbacEntityItems.reset()

    // pending permissions
    this.resetPendingRbaPermissions()

    // current selected entity items
    this.storeMenu.reset()

    // flags
    this.storeEditRbacRoleFlags.reset()

    return this
  }

  /**
   * Set roles that have been fetched.
   */
  @action
  setRbacRoles(rbacRoles: EntityRbacRole[]): this {
    this.$rbacRoles.clear()

    rbacRoles.forEach(entity => {
      if (entity.id) {
        this.$rbacRoles.set(entity.id, entity)
      }
    })

    return this
  }

  /**
   * Set the current roleId that is being edited.
   */
  @action
  setCurrentRole(roleId: number): this {
    this.$currentRbacRoleId.set(roleId)
    return this
  }

  @action
  resetPendingRbaPermissions() {
    this.$pendingRbacPermissions.clear()
  }

  /**
   * When opening the permissions edition,
   * copy all user's permissions into pending permissions to allow edition.
   *
   * Once edition is done, save pending permissions to replace the existing ones.
   */
  @action
  initPendingRbacPermissions(permissions: RbacPermissions): this {
    this.$pendingRbacPermissions.clear()

    Array.from(permissions.values()).forEach(entityRbacPermission => {
      const rbacPermissions = entityRbacPermission.explode()

      rbacPermissions.forEach(rbacPermission => {
        if (
          isDefined(rbacPermission.rbacEntityName) &&
          isDefined(rbacPermission.rbacAction)
        ) {
          this.addPendingRbacPermissions(
            rbacPermission.rbacEntityName,
            rbacPermission.rbacAction,
            rbacPermission.rbacEntityId
          )
        }
      })
    })

    return this
  }

  /**
   * Add pending permissions.
   */
  @action
  addPendingRbacPermissions(
    rbacEntityName: RbacEntityName,
    rbacAction: RbacAction,
    rbacEntityId?: Maybe<RbacEntityId>
  ): this {
    // Don't add invalid ids
    if (rbacEntityId === '-1') {
      return this
    }

    const rbacPermissionToAdd = new EntityRbacPermission({
      entityName: rbacEntityName,
      action: rbacAction,
      entityIds: rbacEntityId ? [rbacEntityId] : null
    })

    let shouldAddPermission = true

    // do no set id permission if there is already a all permission
    if (rbacEntityId) {
      const hasAllPermission = Array.from(
        this.pendingRbacPermissions.values()
      ).some(rbacPermission => {
        return (
          rbacPermission.entityName === rbacEntityName &&
          rbacPermission.action === rbacAction &&
          !rbacPermission.entityIds
        )
      })

      if (hasAllPermission) {
        shouldAddPermission = false
      }
    }

    if (shouldAddPermission) {
      this.$pendingRbacPermissions.set(
        rbacPermissionToAdd.buildRbacPermissionKey(),
        rbacPermissionToAdd
      )
    }

    // side-effect permissions
    const rbacSideEffectPermissions = getRbacSideEffectPermissions()(
      RbacPermissionType.grant,
      rbacEntityName,
      rbacAction
    )

    ensureArray(
      rbacSideEffectPermissions.get(RbacPermissionType.grant)
    ).forEach(rbacSideEffectGrantPermission => {
      this.addPendingRbacPermissions(
        rbacSideEffectGrantPermission.rbacEntityName,
        rbacSideEffectGrantPermission.rbacAction,
        rbacEntityId
      )
    })

    ensureArray(
      rbacSideEffectPermissions.get(RbacPermissionType.unauthorize)
    ).forEach(rbacSideEffectUnauthorizePermission => {
      this.removePendingRbacPermissions(
        rbacSideEffectUnauthorizePermission.rbacEntityName,
        rbacSideEffectUnauthorizePermission.rbacAction,
        rbacEntityId
      )
    })

    // custom specific side-effet permissions
    mergeRbacCheckerOptionsPermissions(this.$pendingRbacPermissions)

    // if adding an all permission, remove id permissions
    if (!rbacEntityId) {
      Array.from(this.pendingRbacPermissions.values())
        .filter(rbacPermission => {
          return (
            rbacPermission.entityName === rbacEntityName &&
            rbacPermission.action === rbacAction
          )
        })
        .forEach(rbacPermission => {
          if (!rbacPermission.entityIds) {
            return
          }

          this.$pendingRbacPermissions.delete(
            rbacPermission.buildRbacPermissionKey()
          )
        })
    }

    return this
  }

  /**
   * Remove pending permissions.
   */
  @action
  removePendingRbacPermissions(
    rbacEntityName: RbacEntityName,
    rbacAction: RbacAction,
    rbacEntityId?: Maybe<RbacEntityId>
  ): this {
    const hasAllPendingPermission = this.$pendingRbacPermissions.has(
      buildRbacPermissionKey(rbacEntityName, rbacAction)
    )

    const rbacPermissionToRemove = new EntityRbacPermission({
      entityName: rbacEntityName,
      action: rbacAction,
      entityIds: rbacEntityId ? [rbacEntityId] : null
    })

    this.$pendingRbacPermissions.delete(
      rbacPermissionToRemove.buildRbacPermissionKey()
    )

    // side-effect permissions
    const rbacSideEffectPermissions = getRbacSideEffectPermissions()(
      RbacPermissionType.unauthorize,
      rbacEntityName,
      rbacAction
    )

    ensureArray(
      rbacSideEffectPermissions.get(RbacPermissionType.grant)
    ).forEach(rbacSideEffectGrantPermission => {
      this.addPendingRbacPermissions(
        rbacSideEffectGrantPermission.rbacEntityName,
        rbacSideEffectGrantPermission.rbacAction,
        rbacEntityId
      )
    })

    ensureArray(
      rbacSideEffectPermissions.get(RbacPermissionType.unauthorize)
    ).forEach(rbacSideEffectUnauthorizePermission => {
      this.removePendingRbacPermissions(
        rbacSideEffectUnauthorizePermission.rbacEntityName,
        rbacSideEffectUnauthorizePermission.rbacAction,
        rbacEntityId
      )
    })

    // custom specific side-effet permissions
    mergeRbacCheckerOptionsPermissions(this.$pendingRbacPermissions)

    // if an all permission is set, when removing an id permission,
    // remove the all permission and add id permissions on other entities
    if (hasAllPendingPermission && rbacEntityId) {
      // remove the all permission
      this.$pendingRbacPermissions.delete(
        buildRbacPermissionKey(rbacEntityName, rbacAction)
      )

      const storeWidgetList =
        this.storesWidgetListEntityItems.get(rbacEntityName)

      // add all other id permissions on read/edit
      if (storeWidgetList && rbacAction !== RbacAction.Create) {
        storeWidgetList.allEntitiesAsArray.forEach(otherRbacEntity => {
          const row = otherRbacEntity.asDataRow()

          if (row?.id === rbacEntityId) {
            return
          }

          this.addPendingRbacPermissions(
            rbacEntityName,
            rbacAction,
            String(row?.id)
          )
        })
      }
    }

    // if an all permission is set and when removing it,
    // remove the all permission and add id permissions on other entities
    if (hasAllPendingPermission && !rbacEntityId) {
      // remove the all permission
      this.$pendingRbacPermissions.delete(
        buildRbacPermissionKey(rbacEntityName, rbacAction)
      )

      const storeWidgetList =
        this.storesWidgetListEntityItems.get(rbacEntityName)

      // add all other id permissions
      if (rbacAction !== RbacAction.Create && storeWidgetList) {
        storeWidgetList.entitiesAsArray.forEach(otherRbacEntity => {
          const row = otherRbacEntity.asDataRow()

          this.addPendingRbacPermissions(rbacEntityName, rbacAction, row?.id)
        })
      }
    }

    return this
  }

  /**
   * Update the visibility of one list of items.
   */
  @action
  setRbacEntityItemListVisibility(
    rbacEntityName: RbacEntityName,
    visible: boolean
  ): void {
    this.$rbacEntityItemListsVisibility.set(rbacEntityName, visible)
  }

  /**
   * Update the visibility of lists of items.
   */
  @action
  setRbacEntityItemListsVisibility(visible: boolean): void {
    Array.from(this.storesWidgetListEntityItems.entries()).forEach(
      ([, storeWidgetList]) => {
        const rbacEntityName = storeWidgetList.meta.get<RbacEntityName>(
          StoreWidgetListEntitiesMeta.rbacEntityName
        )

        if (!rbacEntityName) {
          return
        }

        this.$rbacEntityItemListsVisibility.set(rbacEntityName, visible)
      }
    )
  }

  @action
  setOnlyGrantedCheckboxValue(bool: boolean): this {
    this.$onlyGrantedCheckboxValue.set(bool)
    return this
  }

  /**
   * Computed
   */

  /**
   * Return roles.
   */
  @computed
  get rbacRoles(): Map<number, EntityRbacRole> {
    return toJS(this.$rbacRoles)
  }

  /**
   * Return the current roleId.
   */
  @computed
  get currentRbacRoleId(): Maybe<number> {
    return this.$currentRbacRoleId.get()
  }

  /**
   * Return the current role entity.
   */
  @computed
  get currentRbacRole(): Maybe<EntityRbacRole> {
    if (!this.currentRbacRoleId) {
      return null
    }

    return this.$rbacRoles.get(this.currentRbacRoleId) || null
  }

  /**
   * Return the pending permissions to be saved after the role edition.
   */
  @computed
  get pendingRbacPermissions(): RbacPermissions {
    return toJS(this.$pendingRbacPermissions)
  }

  @computed
  get pendingRbacPermissionKeys(): RbacPermissionKey[] {
    return Array.from(this.$pendingRbacPermissions.keys()).sort()
  }

  @computed
  get rbacEntityItemsListVisibility(): Map<RbacEntityName, boolean> {
    return toJS(this.$rbacEntityItemListsVisibility)
  }

  @computed
  get onlyGrantedCheckboxValue(): boolean {
    return this.$onlyGrantedCheckboxValue.get()
  }
}
