import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import { AppRouteName } from '@app/routes'
import type { StoreIoE, StoreMessages } from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'
import type { ISubmitFormParameters } from '@libs/submitForm'
import { submitForm } from '@libs/submitForm'
import type { RbacDeviantAdObjectsQueryArgs } from '@server/graphql/typeDefs/types'

/**
 * Export deviant objects to an attached file.
 */

export const handleExportDeviantObjectsOnSubmit =
  (storeMessages: StoreMessages, storeIoE: StoreIoE) =>
  (appRouter: AppRouterClient) =>
  (checkerId: number, checkerCodename: GenericCheckerCodename) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeRoot } = storeIoE

    const args =
      storeIoE.storeIndicator.storeIndicatorDeviantObjects.computeFetchDeviantObjectsArgs(
        checkerCodename,
        {
          adObjectsPage: 1,
          adObjectsPerPage: 10
        }
      )

    if (!args) {
      storeMessages.genericError()
      return
    }

    const expression = JSON.parse(args.adObjectsExpression)
    const dateStart = args.dateStart ? new Date(args.dateStart) : undefined
    if (dateStart) {
      const dateStartExp = {
        date: ['>', dateStart.toISOString()]
      }
      if (expression.AND) {
        expression.AND.push(dateStartExp)
      } else {
        expression.AND = [dateStartExp]
      }
    }

    const dateEnd = args.dateEnd ? new Date(args.dateEnd) : undefined
    if (dateEnd) {
      const dateEndExp = {
        date: ['<', dateEnd.toISOString()]
      }
      if (expression.AND) {
        expression.AND.push(dateEndExp)
      } else {
        expression.AND = [dateEndExp]
      }
    }
    const showIgnored = args.showIgnored
    if (showIgnored) {
      const showIgnoredExp = {
        showIgnored: showIgnored.toString()
      }
      if (expression.AND) {
        expression.AND.push(showIgnoredExp)
      } else {
        expression.AND = [showIgnoredExp]
      }
    }
    args.adObjectsExpression = JSON.stringify(expression)

    const profileId =
      storeMessages.storeRoot.stores.storeAuthentication.currentProfileId

    const pathname = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareAPI_Export_Profiles_Checkers,
      parameters: {
        profileId,
        checkerId
      }
    })

    const parameters: ISubmitFormParameters<RbacDeviantAdObjectsQueryArgs> = {
      parameters: args,
      language: storeMessages.storeRoot.appTranslator.language,
      'csrf-token': storeRoot.environment.sessionParams.csrfToken
    }

    submitForm(pathname, parameters, 'GET')
  }
