import type { EntityBlade } from '@app/entities'
import type { StoreBlades, StoreRoot } from '@app/stores'
import { sendPageToPDFMiddleware } from './helpers'

/**
 * Close the latest blade.
 */
export const onHeaderCrumbCloseIconClick =
  (storeBlades: StoreBlades) => (e: React.SyntheticEvent | Event) => {
    e.stopPropagation()
    storeBlades.closeLastBlade()
  }

/**
 * Export some pages to PDF.
 */
export const onHeaderCrumbExportIconClick =
  (storeRoot: StoreRoot) =>
  (pathnames: string[]) =>
  (e: React.MouseEvent<any, MouseEvent>) => {
    e.stopPropagation()

    sendPageToPDFMiddleware(storeRoot, pathnames)
  }

/**
 * Go to the clicked blade.
 */
export const onHeaderCrumbClick =
  (storeBlades: StoreBlades) => (entityBlade: EntityBlade) => () => {
    storeBlades.goToBladeUrl(entityBlade.url)
  }
