import { IconCheckCircle } from '@app/components/Icon'
import { WrapperAlert } from '@app/components/Wrapper'
import { AlertSize, AlertVariant } from '@app/components/Wrapper/types'
import { useAppTranslator } from '@app/hooks'
import * as React from 'react'
import styled from 'styled-components'

interface IAlertIoaAutomaticConfigurationSuccessProps {}

const AlertSuccess = styled(WrapperAlert)`
  width: 100%;
  margin-bottom: 20px;

  .ant-alert {
    text-align: left;
  }
`

const AlertIoaAutomaticConfigurationSuccess: React.FC<
  IAlertIoaAutomaticConfigurationSuccessProps
> = () => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.IoA']
  })

  return (
    <AlertSuccess
      name="AlertIoaAutomaticConfigurationSuccess"
      labelledBy="alertIoaAutomaticConfigurationSuccess"
      alertProps={{
        closable: false,
        showIcon: true,
        icon: <IconCheckCircle />,
        message: (
          <>
            <div>
              {translate(
                'Tenable.ad will apply future configuration changes automatically'
              )}
            </div>
            <div>
              <b>
                {translate(
                  'Follow the procedure below to configure your domains for automatic updates'
                )}
              </b>
            </div>
          </>
        )
      }}
      size={AlertSize.large}
      variant={AlertVariant.success}
    />
  )
}

export default AlertIoaAutomaticConfigurationSuccess
