import { ContainerContent } from '@app/components/Container'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToTelemetryConfiguration } from '../permissions'
import ConfigurationTelemetryForm from './ConfigurationTelemetryForm'
import {
  onTelemetryConfigurationLoad,
  onTelemetryConfigurationUnload
} from './handlers'

interface IConfigurationTelemetryProps {}

const ConfigurationTelemetryPage: React.FC<
  IConfigurationTelemetryProps
> = () => {
  const { storeManagementApplicationSettings } = useStores()

  return (
    <ContainerContent
      onLoad={onTelemetryConfigurationLoad(storeManagementApplicationSettings)}
      onUnload={onTelemetryConfigurationUnload(
        storeManagementApplicationSettings
      )}
      flags={
        storeManagementApplicationSettings.storeFlagsAppSettingsFetch.flags
      }
      rbacCapabilityCheck={canAccessToTelemetryConfiguration}
    >
      <ConfigurationTelemetryForm />
    </ContainerContent>
  )
}

export default observer(ConfigurationTelemetryPage)
