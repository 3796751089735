import type { MaybeUndef, PropertiesNullable } from '@@types/helpers'
import { getCriticityColor, getCriticityString } from '@libs/criticity'
import { isDefined } from '@libs/isDefined'
import { isGrantedEntity } from '@libs/rbac/functions'
import type {
  AttackerKnownTool,
  Category,
  Checker,
  CheckerOption,
  Deviance,
  Maybe,
  MaybeGrantedDeviances,
  MaybeGrantedReasons,
  Recommendation,
  Resource,
  VulnerabilityDetail
} from '@server/graphql/typeDefs/types'
import {
  CheckerRemediationCostLevel,
  CheckerType,
  Criticity
} from '@server/graphql/typeDefs/types'
import { flatMap, last } from 'lodash'
import {
  createEntities,
  createEntity,
  EntityCheckerOption,
  EntityRecommendation
} from '..'
import AttackerKnownToolEntity from '../EntityAttackerKnownTool'
import EntityBase from '../EntityBase'
import EntityDeviance from '../EntityDeviance'
import { CheckerOptionCodenameEnum } from '../EntityGenericCheckerOption/types'
import EntityResource from '../EntityResource'
import EntityVulnerabilityDetail from '../EntityVulnerabilityDetail'
import type { GenericCheckerCodename, IGenericChecker } from './types'

export default class EntityChecker
  extends EntityBase
  implements PropertiesNullable<Checker>, IGenericChecker
{
  id: Maybe<number> = null
  codename: Maybe<GenericCheckerCodename> = null
  remediationCost: Maybe<number> = null
  name: Maybe<string> = null
  description: Maybe<string> = null
  execSummary: Maybe<string> = null

  vulnerabilityDetail: Maybe<VulnerabilityDetail> = null
  resources: Maybe<Resource[]> = null
  attackerKnownTools: Maybe<AttackerKnownTool[]> = null
  recommendation: Maybe<Recommendation> = null
  category: Maybe<Category> = null
  options: Maybe<CheckerOption[]> = null

  rbacReasons: Maybe<MaybeGrantedReasons> = null
  rbacDeviances: Maybe<MaybeGrantedDeviances[]> = null

  // isDeviant and directoryIds are returned by the resolver.
  isDeviant: Maybe<boolean> = null
  directoryIds: Maybe<number[]> = null

  /**
   * Used to differentiate checkerType that implements IGenericChecker.
   */
  type = CheckerType.Exposure

  constructor(data: Partial<Checker>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return options entities.
   */
  getOptions(): EntityCheckerOption[] {
    return createEntities<CheckerOption, EntityCheckerOption>(
      EntityCheckerOption,
      this.options
    )
  }

  /**
   * Return granted deviances entities.
   */
  getDeviances(): EntityDeviance[] {
    if (!this.rbacDeviances) {
      return []
    }

    const deviances = flatMap(
      this.rbacDeviances
        .filter(rbacDeviance => isGrantedEntity(rbacDeviance))
        .map(rbacDeviance => rbacDeviance.node)
        .filter(isDefined)
    )

    return createEntities<Deviance, EntityDeviance>(EntityDeviance, deviances)
  }

  /**
   * Return the directoryIds of all deviances.
   */
  getDeviancesDirectoryIds(): number[] {
    return (
      this.directoryIds ??
      this.getDeviances()
        .map(deviance => deviance.directoryId)
        .filter(isDefined)
    )
  }

  /**
   * Return the criticity option if found.
   */
  getCriticityOption(): MaybeUndef<EntityCheckerOption> {
    return this.getOptions()
      .filter(
        option => option.codename === CheckerOptionCodenameEnum.O_CRITICITY
      )
      .pop()
  }

  /**
   * Return the criticity color.
   */
  getCriticityColor(): string {
    const criticity = this.getCriticityOption()

    if (!criticity) {
      return getCriticityColor(Criticity.Low)
    }

    const criticityValue = Number(criticity.getValue())
    return getCriticityColor(getCriticityString(criticityValue))
  }

  /**
   * Return the date of the latest update,
   * in fact, the date of the event matching the latest related deviance.
   */
  getLatestUpdateDate(): Maybe<string> {
    const latestDeviances = last(this.getDeviances())

    if (!latestDeviances) {
      return null
    }

    // could be null, just not wrapped into an entity here to avoid
    // useless instanciations.
    return latestDeviances.eventDate || null
  }

  /**
   * Return resource entities
   */
  getResources(): EntityResource[] {
    return createEntities<Resource, EntityResource>(
      EntityResource,
      this.resources
    )
  }

  /**
   * Return attacker known tools entities
   */
  getAttackerKnownTools(): AttackerKnownToolEntity[] {
    return createEntities<AttackerKnownTool, AttackerKnownToolEntity>(
      AttackerKnownToolEntity,
      this.attackerKnownTools
    )
  }

  /**
   * Return the vulnerabilityDetail entity.
   */
  getVulnerabilityDetail(): Maybe<EntityVulnerabilityDetail> {
    if (!this.vulnerabilityDetail) {
      return null
    }

    return createEntity<VulnerabilityDetail, EntityVulnerabilityDetail>(
      EntityVulnerabilityDetail,
      this.vulnerabilityDetail
    )
  }

  /**
   * Return the recommendation entity.
   */
  getRecommendation(): Maybe<EntityRecommendation> {
    if (!this.recommendation) {
      return null
    }

    return createEntity<VulnerabilityDetail, EntityRecommendation>(
      EntityRecommendation,
      this.recommendation
    )
  }

  /**
   * Return true if the checker is compliant (no deviances).
   */
  getComplianceStatus(): boolean {
    return !this.isDeviant ?? this.getDeviances().length === 0
  }

  /**
   * Check in checker options if O-ENABLED value is true
   */
  get enabled(): Maybe<boolean> {
    const enabledOption = this.getOptions()
      .filter(option => option.codename === CheckerOptionCodenameEnum.O_ENABLED)
      .pop()

    if (!enabledOption) {
      return null
    }

    return enabledOption.value === 'true'
  }

  /* Static methods */

  static getRemediationCostLevel(
    remediationCost: number | null
  ): CheckerRemediationCostLevel {
    if (remediationCost === null) {
      return CheckerRemediationCostLevel.Unknown
    }

    const remediationCostValue = Number(remediationCost)

    return remediationCostValue <= 33
      ? CheckerRemediationCostLevel.Low
      : remediationCostValue >= 34 && remediationCostValue <= 66
        ? CheckerRemediationCostLevel.Medium
        : CheckerRemediationCostLevel.High
  }

  /** Implements IGenericChecker */

  get genericId() {
    return this.getPropertyAsString('id')
  }

  get genericName() {
    return this.getPropertyAsString('name')
  }

  get genericCodename(): GenericCheckerCodename {
    return this.getPropertyAsString('codename')
  }

  /**
   * Return the criticity level from the criticity option value.
   */
  get genericCriticity(): Criticity {
    const criticity = this.getCriticityOption()

    if (!criticity) {
      return Criticity.Low
    }

    const criticityValue = Number(criticity.getValue())
    return getCriticityString(criticityValue)
  }

  /**
   * Return the remediation cost level.
   */
  get genericRemediationCostLevel(): CheckerRemediationCostLevel {
    return EntityChecker.getRemediationCostLevel(this.remediationCost)
  }
}
