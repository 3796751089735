import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import { useTheme } from 'styled-components'
import { TypographySubTitle } from '../Typography'

interface IContainerTitleProps {
  children?: React.ReactNode
}

/**
 * Used to display title in all containers.
 */
function ContainerTitle(props: IContainerTitleProps) {
  const theme = useTheme()

  if (isThemeLegacy(theme)) {
    return <TypographySubTitle>{props.children}</TypographySubTitle>
  }

  return <Label variant={LabelVariant.h2}>{props.children}</Label>
}

export default ContainerTitle
