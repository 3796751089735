import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import { BrandColorV2, FontColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

const Color1Path = styled.path`
  fill: ${FontColorV2.primary};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${consts.colorWhite};
  }
`

const Color2PathFill = styled.path`
  fill: ${BrandColorV2.decorativeGray};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${consts.colorFadedBlueNewDesign};
  }
`

const Color2PathStroke = styled.path`
  stroke: ${BrandColorV2.decorativeGray};
  stroke-width: 1.3;
  stroke-linecap: square;

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    stroke: ${consts.colorFadedBlueNewDesign};
  }
`

interface IIconAttackPathProps extends IIconProps {}

const IconAttackPathLegacy: React.FC<IIconAttackPathProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Color2PathStroke d="M10.1122 0.913971L10.0909 18.1531" />
      <Color2PathStroke d="M0.96167 9.99706H19.0495" />
      <Color1Path d="M10 13.6364C12.0083 13.6364 13.6364 12.0083 13.6364 10C13.6364 7.99169 12.0083 6.36363 10 6.36363C7.9917 6.36363 6.36365 7.99169 6.36365 10C6.36365 12.0083 7.9917 13.6364 10 13.6364Z" />
      <Color2PathFill d="M16.6364 12.078C16.6354 12.34 16.7926 12.5767 17.0345 12.6775C17.2764 12.7783 17.5551 12.7232 17.7405 12.538L19.8045 10.4718C19.9265 10.3498 19.9946 10.1842 19.9937 10.0117C19.9937 10.0042 19.9937 9.99732 19.9937 9.99042C20 9.81166 19.9317 9.63833 19.8051 9.51195L17.7399 7.45086C17.4855 7.20894 17.0846 7.21405 16.8365 7.46236C16.5883 7.71067 16.5835 8.11157 16.8256 8.36581L18.453 9.9933L16.8256 11.6208C16.7043 11.742 16.6362 11.9065 16.6364 12.078Z" />
      <Color2PathFill d="M12.2114 3.35774C12.4734 3.35867 12.7101 3.20147 12.8109 2.9596C12.9117 2.71774 12.8566 2.43897 12.6714 2.25358L10.6052 0.189607C10.4832 0.0676382 10.3176 -0.000489235 10.1451 0.000405073C10.1376 0.000405073 10.1307 0.000405073 10.1238 0.000405073C9.94507 -0.0058856 9.77174 0.0624449 9.64536 0.189031L7.58427 2.25415C7.34235 2.50855 7.34745 2.90945 7.59576 3.1576C7.84408 3.40576 8.24498 3.41061 8.49922 3.16853L10.1267 1.54105L11.7542 3.16853C11.8754 3.28979 12.0399 3.35786 12.2114 3.35774Z" />
      <Color2PathFill d="M12.2114 16.6364C12.4734 16.6354 12.7101 16.7926 12.8109 17.0345C12.9117 17.2764 12.8566 17.5551 12.6714 17.7405L10.6052 19.8045C10.4832 19.9265 10.3176 19.9946 10.1451 19.9937C10.1376 19.9937 10.1307 19.9937 10.1238 19.9937C9.94507 20 9.77174 19.9317 9.64536 19.8051L7.58427 17.7399C7.34235 17.4855 7.34745 17.0846 7.59576 16.8365C7.84408 16.5883 8.24498 16.5835 8.49922 16.8256L10.1267 18.453L11.7542 16.8256C11.8754 16.7043 12.0399 16.6362 12.2114 16.6364Z" />
      <Color2PathFill d="M3.35778 12.078C3.35872 12.34 3.20152 12.5767 2.95965 12.6775C2.71778 12.7783 2.43901 12.7232 2.25362 12.538L0.189653 10.4718C0.0676837 10.3498 -0.000443697 10.1842 0.000450611 10.0117C0.000450611 10.0042 0.000450611 9.99732 0.000450611 9.99042C-0.00584006 9.81166 0.0624909 9.63833 0.189077 9.51195L2.2542 7.45086C2.50859 7.20894 2.90949 7.21405 3.15765 7.46236C3.4058 7.71067 3.41065 8.11157 3.16858 8.36581L1.5411 9.9933L3.16858 11.6208C3.28984 11.742 3.35791 11.9065 3.35778 12.078Z" />
    </svg>
  )
}

export default IconAttackPathLegacy
