import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter, useStores } from '@app/hooks'
import { EventDetailsPageContext } from '@app/pages/TrailFlow/contexts'
import EventDetailsAttributesPage from '@app/pages/TrailFlow/EventDetailsAttributesPage'
import DrawerAttributeValue from '@app/pages/TrailFlow/EventDetailsAttributesPage/DrawerAttributeValue'
import DrawerSDDLDescription from '@app/pages/TrailFlow/EventDetailsAttributesPage/DrawerSDDLDescription'
import EventDetailsHeader from '@app/pages/TrailFlow/EventDetailsPage/EventDetailsHeader'
import {
  onEventDetailsLoad,
  onEventDetailsUnload
} from '@app/pages/TrailFlow/EventDetailsPage/handlers'
import { canAccessToEventDetails } from '@app/pages/TrailFlow/permissions'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckerDetailsPageContext } from '../context'

interface IDetailsDeviantObjectsAttributesPageProps {}

const DetailsDeviantObjectsAttributesPage: React.FC<
  IDetailsDeviantObjectsAttributesPageProps
> = props => {
  const appRouter = useAppRouter()

  const { storeTrailFlow } = useStores()

  const { storeEventDetailsAttributes } = storeTrailFlow.storeEventDetails

  const routeName = appRouter.getCurrentRouteName()

  if (
    routeName !==
      AppRouteName.IoE_Details_DeviantObjects_EventDetails_Attributes &&
    routeName !== AppRouteName.Topology_DeviantObjects_EventDetails_Attributes
  ) {
    return null
  }

  const parameters = appRouter.getRouteParameters({
    routeName,
    parameters: {
      checkerId: Number(),
      checkerCodename: String(),
      infrastructureId: Number(),
      directoryId: Number(),
      eventId: String()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <CheckerDetailsPageContext.Provider
      value={{
        checkerId: parameters.checkerId,
        checkerCodename: parameters.checkerCodename
      }}
    >
      <EventDetailsPageContext.Provider value={parameters}>
        <Blade
          routeDefinition={{
            routeName,
            parameters: {
              checkerId: parameters.checkerId,
              checkerCodename: parameters.checkerCodename,
              infrastructureId: parameters.infrastructureId,
              directoryId: parameters.directoryId,
              eventId: parameters.eventId
            }
          }}
          onLoad={onEventDetailsLoad(storeTrailFlow)(
            parameters.infrastructureId,
            parameters.directoryId,
            parameters.eventId
          )}
          onUnload={onEventDetailsUnload(storeTrailFlow)}
          flags={[
            storeTrailFlow.storeFlagsTrailFlow.flags,
            storeTrailFlow.storeEventDetails.storeFlagsEventDetails.flags
          ]}
          layout={{
            name: 'default',
            header: <EventDetailsHeader />,
            content: (
              <>
                <EventDetailsAttributesPage />

                <Portal name={PlaceHolderName.genericDrawer}>
                  <DrawerSDDLDescription
                    storeDrawer={
                      storeEventDetailsAttributes.storeDrawerSDDLDescription
                    }
                  />
                </Portal>

                <Portal name={PlaceHolderName.genericDrawer}>
                  <DrawerAttributeValue
                    storeDrawer={
                      storeEventDetailsAttributes.storeDrawerAttributeValue
                    }
                  />
                </Portal>
              </>
            ),
            footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
          }}
          rbacCapabilityCheck={canAccessToEventDetails}
        />
      </EventDetailsPageContext.Provider>
    </CheckerDetailsPageContext.Provider>
  )
}

export default observer(DetailsDeviantObjectsAttributesPage)
