import type { PropertiesNullable } from '@@types/helpers'
import { ensureArray } from '@libs/ensureArray'
import type {
  Checker,
  Recommendation,
  Resource,
  Tenant
} from '@libs/openapi/service-identity-core'
import {
  CheckerSeverityEnum,
  RecommendationCostEnum
} from '@libs/openapi/service-identity-core'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type { Maybe } from '@server/graphql/typeDefs/types'
import {
  CheckerRemediationCostLevel,
  Criticity
} from '@server/graphql/typeDefs/types'
import EntityBase from '../EntityBase'
import type { GenericCheckerCodename, IGenericChecker } from './types'

export default class EntityCheckerIdentity
  extends EntityBase
  implements PropertiesNullable<Checker>, IGenericChecker
{
  id: Maybe<string> = null
  codename: Maybe<string> = null
  name: Maybe<string> = null
  hasFindings: Maybe<boolean> = null
  latestDetectionDate?: Maybe<Date> = null
  summary: Maybe<string> = null
  description: Maybe<string> = null
  tenants: Maybe<Tenant[]> = null
  severity: Maybe<CheckerSeverityEnum> = null
  vulnerabilityDetail: Maybe<string> = null
  recommendation: Maybe<Recommendation> = null
  resources: Maybe<Resource[]> = null

  /**
   * Used to differentiate checkerType that implements IGenericChecker.
   */
  type = 'identity' as const

  constructor(data: Partial<Checker>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return IDs of tenants.
   */
  getTenantsIds(): string[] {
    return ensureArray(this.tenants).map(tenant => tenant.id)
  }

  /** Implements IGenericChecker */

  get genericId() {
    return this.getPropertyAsString('id')
  }

  get genericName() {
    return this.getPropertyAsString('name')
  }

  get genericCodename(): GenericCheckerCodename {
    return this.getPropertyAsString('codename')
  }

  /**
   * Return the criticity level from the severity...
   */
  get genericCriticity(): Criticity {
    if (!this.severity) {
      return Criticity.Low
    }

    return EntityCheckerIdentity.getSeverityCriticityValue(this.severity)
  }

  /**
   * Return the remediation cost level.
   * Proceed to a simple mapping as the cost level is already returned by
   * the T.id backend
   */
  get genericRemediationCostLevel(): CheckerRemediationCostLevel {
    if (!this.recommendation) {
      return CheckerRemediationCostLevel.Unknown
    }

    switch (this.recommendation.cost) {
      case RecommendationCostEnum.Low:
        return CheckerRemediationCostLevel.Low

      case RecommendationCostEnum.Medium:
        return CheckerRemediationCostLevel.Medium

      case RecommendationCostEnum.High:
        return CheckerRemediationCostLevel.High

      default:
        assertUnreachableCase(this.recommendation.cost)
    }
  }

  get remediationCost(): number {
    if (!this.recommendation) {
      return 33
    }

    switch (this.recommendation.cost) {
      case RecommendationCostEnum.Low:
        return 33

      case RecommendationCostEnum.Medium:
        return 66

      case RecommendationCostEnum.High:
        return 100

      default:
        assertUnreachableCase(this.recommendation.cost)
    }
  }

  /**
   * Static
   */

  /**
   * Return a GraphQL Criticity object from an OpenApi CheckerSeverityEnum object.
   */
  static getSeverityCriticityValue(severity: CheckerSeverityEnum): Criticity {
    switch (severity) {
      case CheckerSeverityEnum.Critical:
        return Criticity.Critical

      case CheckerSeverityEnum.High:
        return Criticity.High

      case CheckerSeverityEnum.Medium:
        return Criticity.Medium

      case CheckerSeverityEnum.Low:
        return Criticity.Low

      default:
        assertUnreachableCase(severity)
    }
  }
}
