import type { IGraphQLQuery } from '@libs/graphQL/types'
import type { Query } from '../typeDefs/types'

/**
 * Traces Filters data (all IPs, all users...)
 */

export type QueryTracesFiltersData = IGraphQLQuery<
  null,
  Pick<Query, 'tracesFiltersData'>
>

export const queryTracesFiltersData = `
  query queryTracesFiltersData {
    tracesFiltersData {
      node {
        allUserIps
        allUserEmails
      }

      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
