import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  Drawer,
  DrawerContext,
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppTranslator, useStores } from '@app/hooks'
import type StoreDrawer from '@app/stores/helpers/StoreDrawer'
import { grant } from '@libs/rbac/permissions'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { onDrawerAttacksFiltersLoad } from './handlers'
import IoADrawerAttacksRemoveFiltersListContent from './IoADrawerAttacksRemoveFiltersListContent'

interface IIoADrawerAttacksFiltersRemoveProps {
  storeDrawer: StoreDrawer<any>
}

const IoADrawerAttacksRemoveFiltersList: React.FC<
  IIoADrawerAttacksFiltersRemoveProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['IoA.Attacks.DrawerRemoveFilters']
  })

  const { storeIoA } = useStores()

  const storeAttacks = storeIoA.latestStoreAttacks

  if (!storeAttacks) {
    return null
  }

  return (
    <DrawerContext.Provider value={{ storeDrawer: props.storeDrawer }}>
      <Drawer>
        <DrawerHorizontalContainer
          variant="large"
          onLoad={onDrawerAttacksFiltersLoad(storeAttacks)}
          flags={[
            storeAttacks.storeAttackRemoveFilters.storeFlagsFetch.flags,
            storeAttacks.storeAttackRemoveFilters.storeInputCheckersAttacks
              .storeFlagsFetchCheckers.flags
          ]}
        >
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: (
                <DrawerTitle>
                  {translate('Remove incidents deletion filters')}
                </DrawerTitle>
              ),
              content: (
                <IoADrawerAttacksRemoveFiltersListContent
                  storeAttacks={storeAttacks}
                />
              ),
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={grant()}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(IoADrawerAttacksRemoveFiltersList)
