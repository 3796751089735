import type StoreWidgetsManagement from '@app/stores/Dashboard/StoreWidgetsManagement'
import { onSelectChange } from '@app/stores/helpers/StoreForm/handlers'
import type { SelectValue } from 'antd/lib/select'
import { WidgetCommonFormFieldName } from '../types'

/**
 * When selecting the dataOptions type, set the value and add a new serie,
 * ready to be configurable.
 */
export const onWidgetInputDataOptionsTypeSelect =
  (storeWidgetsManagement: StoreWidgetsManagement) => (value: SelectValue) => {
    onSelectChange(storeWidgetsManagement.storeForm)(
      WidgetCommonFormFieldName.dataOptionsType
    )(value)

    storeWidgetsManagement.addFirstSerie()
  }
