import type { CheckerOptionCodename } from '@app/entities/EntityGenericCheckerOption/types'
import type {
  Maybe,
  SetProfileCheckerOptionsMutationArgs
} from '@server/graphql/typeDefs/types'

/**
 * Return the codename of the option that is duplicated for a same directory.
 * Otherwise, return null.
 */
export function getDuplicatedCheckerOptionsForASameDirectory(
  checkerOptions: SetProfileCheckerOptionsMutationArgs['checkerOptions']
): Maybe<CheckerOptionCodename> {
  const optionOccurrences = new Map<
    string,
    { codename: CheckerOptionCodename; nbOccurrences: number }
  >()

  checkerOptions
    .filter(checkerOption => checkerOption.directoryId)
    .forEach(checkerOption => {
      const key = `${checkerOption.codename}-${checkerOption.directoryId}`

      const currentValue = optionOccurrences.get(key)
      const nbOccurrences = !currentValue ? 1 : currentValue.nbOccurrences + 1

      optionOccurrences.set(key, {
        codename: checkerOption.codename as CheckerOptionCodename,
        nbOccurrences
      })
    })

  for (const { codename, nbOccurrences } of optionOccurrences.values()) {
    if (nbOccurrences > 1) {
      return codename
    }
  }

  return null
}
