import type {
  EntityRbacEntityItem,
  EntityRbacPermission,
  EntityRbacRole
} from '@app/entities'
import type { IDataRowRbacEntityItem } from '@app/entities/EntityRbacEntityItem'
import type { IDataRowRbacRole } from '@app/entities/EntityRbacRole'
import {
  canEditRole,
  canReadRole
} from '@app/pages/Management/AccountsPage/Roles/permissions'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import type StoreRbac from '@app/stores/StoreRbac'
import type { TranslateFn } from '@libs/i18n'
import { RbacEntityItemType } from '@libs/rbac/types'
import type { Maybe, RbacEntityName } from '@server/graphql/typeDefs/types'

/**
 * Filter on items that grant an access with the passed permissions.
 */
export const filterRbacEntityItem =
  (permissions: Map<string, EntityRbacPermission>, translate: TranslateFn) =>
  (onlyGrantedCheckboxValue: boolean, searchRegExp: RegExp) =>
  (
    rbacEntityItemType: RbacEntityItemType,
    rbacEntityItems: EntityRbacEntityItem[]
  ) => {
    return rbacEntityItems
      .filter(rbacEntityItem => {
        const row = rbacEntityItem.asDataRow()

        if (!row) {
          return false
        }

        const translatedRowName =
          rbacEntityItemType === RbacEntityItemType.data
            ? row.name
            : translateRbacEntity(translate)(row.name)

        return searchRegExp.test(translatedRowName)
      })
      .filter(rbacEntityItem => {
        if (onlyGrantedCheckboxValue === false) {
          return rbacEntityItem
        }

        const availableRbacActions = rbacEntityItem.getAvailableRbacAction()
        const isGrantedFn = rbacEntityItem.isGranted(permissions)
        return availableRbacActions.some(rbacAction => isGrantedFn(rbacAction))
      })
  }

/**
 * Return only storeWigets of Rbac entities items of type rbacEntityItemType.
 */
export const filterStoreWidgetLists =
  (
    storesWidgetListEntityItems: Map<
      RbacEntityName | RbacEntityItemType,
      StoreWidgetList<EntityRbacEntityItem, IDataRowRbacEntityItem>
    >
  ) =>
  (rbacEntityItemType: RbacEntityItemType) => {
    return Array.from(storesWidgetListEntityItems.values()).filter(
      storeWidgetList => {
        const firstItem = storeWidgetList.entitiesAsArray[0]

        if (!firstItem) {
          return false
        }

        return firstItem.type === rbacEntityItemType
      }
    )
  }

/**
 * Return true if the role is the Admin one (supposed to be always the first)
 */
export function isRbacAdminRole(
  role: Maybe<EntityRbacRole | IDataRowRbacRole> | number
) {
  if (!role) {
    return false
  }

  if (typeof role === 'number') {
    return role === 1
  }

  return role.id === 1
}

/**
 * Translate the name (not the RbacEntityName) of an Rbac entity.
 */
export function translateRbacEntity(translate: TranslateFn) {
  return (rbacEntityRowName: string): string => {
    return translate(`${rbacEntityRowName} entity`)
  }
}

/**
 * Return true if the user can read the role but not edit it
 */
export function isRoleReadOnly(
  role: Maybe<EntityRbacRole> | number,
  storeRbac: StoreRbac
) {
  if (!role) {
    return false
  }
  let roleId: number
  if (typeof role !== 'number') {
    if (!role.id) {
      return false
    }
    roleId = role.id
  } else {
    roleId = role
  }
  return (
    storeRbac.isUserGrantedTo(canReadRole(roleId)) &&
    !storeRbac.isUserGrantedTo(canEditRole(roleId))
  )
}
