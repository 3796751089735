import type { ILabelProps } from '@app/components/Labels/Label'
import type { ITranslateOptionsInterpolations } from '@libs/i18n'

export enum HealthCheckNameLocale {
  name = 'name',
  description = 'description'
}

export enum HealthCheckReasonLocale {
  name = 'name',
  description = 'description',
  details = 'details',
  successDetails = 'successDetails',
  remediation = 'remediation'
}

export interface IHealthCheckBaseLabelProps {
  locale: HealthCheckNameLocale | HealthCheckReasonLocale
  labelProps?: ILabelProps
  maxWidth?: number
  maxWidthAuto?: boolean
  transformMarkdown?: boolean
  pureText?: boolean
  interpolations?: ITranslateOptionsInterpolations
}
