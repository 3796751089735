import { ContainerFooter } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { Modal, ModalCancelButton } from '@app/components-legacy/Modal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { onApiKeyRefreshModalConfirmClick } from '@app/pages/Preferences/PreferencesApiKey/handlers'
import * as React from 'react'

export interface IApiKeyConfirmModalProps {}

const ApiKeyConfirmModal: React.FC<IApiKeyConfirmModalProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Titles', 'Buttons', 'Preferences.PersonalSettings.ApiKey']
  })

  const { storePreferences } = useStores()

  return (
    <Modal
      modalProps={{
        title: translate('Confirmation'),
        footer: (
          <ContainerFooter variant="modal">
            <ModalCancelButton
              storeModal={storePreferences.storeModalConfirmApiKeyRefresh}
            />

            <FormWrapperButton
              labelledBy="submit"
              buttonProps={{
                type: 'primary',
                onClick: onApiKeyRefreshModalConfirmClick(storePreferences)
              }}
            >
              {translate('Confirm')}
            </FormWrapperButton>
          </ContainerFooter>
        )
      }}
      storeModal={storePreferences.storeModalConfirmApiKeyRefresh}
    >
      <div>
        {translate(
          'Refreshing the API key causes Tenable.ad to deactivate the current token'
        )}
      </div>
    </Modal>
  )
}

export default ApiKeyConfirmModal
