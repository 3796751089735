import Tree from 'antd/lib/tree'
import styled from 'styled-components'

const StyledTree = styled(Tree)`
  &.ant-tree .ant-tree-node-content-wrapper:hover {
    background-color: ${props => props.theme.tokens['tree/color/hover']};
  }

  &.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
    width: 30px;
  }
` as typeof Tree

export default StyledTree
