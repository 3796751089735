import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditInfrastructures } from '../permissions'
import { onInfrastructuresEditPageLoad } from './handlers'
import InfrastructureEditForm from './InfrastructureEditForm'

interface IInfrastructuresEditPageProps {}

const InfrastructuresEditPage: React.FC<IInfrastructuresEditPageProps> = () => {
  const appRouter = useAppRouter()

  const { storeManagementInfrastructures } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_System_Infrastructures_Edit,
    parameters: {
      infrastructureId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_System_Infrastructures_Edit,
        parameters: {
          infrastructureId: parameters.infrastructureId
        }
      }}
      onLoad={onInfrastructuresEditPageLoad(storeManagementInfrastructures)(
        parameters.infrastructureId
      )}
      flags={storeManagementInfrastructures.storeFlags.flags}
      layout={{
        name: 'default',
        content: (
          <ContainerContent>
            <InfrastructureEditForm
              infrastructureId={parameters.infrastructureId}
            />
          </ContainerContent>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canEditInfrastructures(parameters.infrastructureId)}
    />
  )
}

export default observer(InfrastructuresEditPage)
