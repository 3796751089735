import type { Maybe } from '@@types/helpers'
import type AppRouter from './AppRouter'
import type { IRouteDefinition } from './types'

/**
 * Remove prefix from the endpoint.
 */
export function removePrefixFromPathname<
  R extends string,
  RD extends IRouteDefinition<R>
>(
  appRouter: AppRouter<R, RD>,
  prefixRouteName: IRouteDefinition<R>['routeName']
) {
  return (routeName: IRouteDefinition<R>['routeName']): string => {
    const prefixEndpoint = appRouter.getRoutePathname(prefixRouteName)
    const endpoint = appRouter.getRoutePathname(routeName)
    return endpoint.replace(prefixEndpoint, '').replace(/\/+/g, '/')
  }
}

/**
 * Append prefix to the endpoint.
 */
export function appendPrefixToPathname<
  R extends string,
  RD extends IRouteDefinition<R>
>(
  appRouter: AppRouter<R, RD>,
  prefixRouteName: IRouteDefinition<R>['routeName']
) {
  return (routeName: Maybe<IRouteDefinition<R>['routeName']>): string => {
    const prefixEndpoint = appRouter.getRoutePathname(prefixRouteName)

    if (!routeName) {
      return prefixEndpoint
    }

    const endpoint = appRouter.getRoutePathname(routeName)
    return [prefixEndpoint, endpoint].join('/').replace(/\/+/g, '/')
  }
}
