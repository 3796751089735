import { ContainerFlex, ContainerHTMLContent } from '@app/components/Container'
import LabelExternalLink from '@app/components/Labels/LabelExternalLink'
import { BladeCancelButton } from '@app/components-legacy/Blade'
import {
  ContainerContent,
  ContainerFooter
} from '@app/components-legacy/Container'
import {
  FormWrapper,
  FormWrapperButton,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { IconReloadOutlined } from '@app/components-legacy/Icon/IconAntd'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import type { FieldParams } from '@app/components-legacy/Widgets/WidgetForm/types'
import WidgetFormSelect from '@app/components-legacy/Widgets/WidgetForm/WidgetFormSelect'
import { useAppTranslator, useStores } from '@app/hooks'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { TenantsFormFieldName } from '@app/stores/Management/StoreTenants'
import { isDefined } from '@productive-codebases/toolbox'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  handleAddCredentialOnClick,
  handleTenantRefreshCredentialsOnClick
} from './handlers'

export interface ITenantCommonFormProps {
  version: 'creation' | 'edition'
  onSubmit: (e: React.FormEvent<any>) => void
  storeForm: StoreForm<any>
}

const TenantCommonForm: React.FC<ITenantCommonFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Titles', 'Management.System.Tenants']
  })
  const { storeManagementTenants } = useStores()

  const options = Array.from(storeManagementTenants.credentials.values())
    .map(credential => {
      if (!credential.uuid || !credential.name) {
        return null
      }
      return {
        value: credential.uuid,
        label: credential.name,
        disabled: isDefined(credential.tenantUuid)
      }
    })
    .filter(isDefined)

  return (
    <FormWrapper name="tenantConfiguration" onSubmit={props.onSubmit}>
      <>
        <ContainerFlex
          name="TenantsCreateForm"
          direction="column"
          items={[
            <ContainerContent title={translate('Main information')}>
              <WidgetForm<TenantsFormFieldName>
                translate={translate}
                storeForm={props.storeForm}
                renderFields={{
                  [TenantsFormFieldName.credentialUUID]: (
                    storeForm,
                    field,
                    inputId
                  ) => {
                    const fieldParams: FieldParams = {
                      inputType: storeForm.getFieldInputType(field.fieldName)
                    }

                    return (
                      <ContainerFlex
                        name="CredentialsContent"
                        direction="column"
                        flexGap="default"
                        items={[
                          <ContainerFlex
                            name="CredentialsContent"
                            flexGap="default"
                            items={[
                              <WidgetFormSelect
                                fieldName={field.fieldName}
                                inputId={inputId}
                                inputProps={{
                                  ...fieldParams.inputProps,
                                  labelledBy: 'existingCredential',
                                  selectProps: {},
                                  selectOptions: options
                                }}
                                storeForm={storeForm}
                              />,
                              <FormWrapperButton
                                labelledBy="RefreshCredentials"
                                buttonProps={{
                                  loading:
                                    storeManagementTenants
                                      .storeFlagsReloadCredentials.isLoading,
                                  onClick:
                                    handleTenantRefreshCredentialsOnClick(
                                      storeManagementTenants
                                    ),
                                  icon: <IconReloadOutlined />
                                }}
                              >
                                {translate('Refresh')}
                              </FormWrapperButton>
                            ]}
                          />,

                          <ContainerFlex
                            name="CredentialsHelper"
                            direction="column"
                            items={[
                              props.version === 'edition' && (
                                <ContainerHTMLContent padded>
                                  {translate(
                                    'Warning, be sure to select a credential for the same tenant',
                                    {
                                      transformMarkdown: true
                                    }
                                  )}
                                </ContainerHTMLContent>
                              ),

                              <ContainerHTMLContent padded>
                                {translate(
                                  'If your tenant credential does not appear in the drop-down list above',
                                  {
                                    transformMarkdown: true
                                  }
                                )}
                              </ContainerHTMLContent>,

                              <ContainerFlex
                                name="AddCredentialsButton"
                                justifyContent="flex-end"
                                items={[
                                  <FormWrapperButton
                                    labelledBy="AddCredentials"
                                    buttonProps={{
                                      onClick: handleAddCredentialOnClick(
                                        storeManagementTenants
                                      )
                                    }}
                                  >
                                    <LabelExternalLink>
                                      {translate('Add new credential')}
                                    </LabelExternalLink>
                                  </FormWrapperButton>
                                ]}
                              />
                            ]}
                          />
                        ]}
                      />
                    )
                  }
                }}
              />
            </ContainerContent>
          ]}
          spaced
        />

        <Portal name={PlaceHolderName.bladeFooter}>
          <ContainerFooter>
            <BladeCancelButton />

            <FormWrapperButtonSubmit
              type="primary"
              loading={
                props.version === 'creation'
                  ? storeManagementTenants.storeFlagsCreateTenant.isLoading
                  : storeManagementTenants.storeFlagsEditTenant.isLoading
              }
            >
              {translate(props.version === 'creation' ? 'Add' : 'Edit')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </>
    </FormWrapper>
  )
}

export default observer(TenantCommonForm)
