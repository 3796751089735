import * as React from 'react'
import type { BladeLayout } from '../types'

interface IBladeContentFooterProps {
  isLoading: boolean
  isGranted: boolean
  isError: boolean
  layout: BladeLayout
}

/**
 * Render the footer of the Blade.
 *
 * Note that the content is not wrapped by `ContainerFooter` to avoid "empty" footer.
 * Indeed, it's not easy to test that the component render null when footer
 * is used with a `RbacRoutes` component for example.
 *
 * That's why components used as BladeFooter has to use `ContainerFooter` since not
 * wrapped here.
 */
const BladeContentFooter: React.FC<IBladeContentFooterProps> = props => {
  if (
    !props.layout.footer ||
    props.isLoading ||
    !props.isGranted ||
    props.isError
  ) {
    return null
  }

  return <>{props.layout.footer}</>
}

export default BladeContentFooter
