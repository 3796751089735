import { ContainerFlex } from '@app/components/Container'
import * as React from 'react'
import CreateReportButton from './CreateReportButton'

interface IConfigurationReportsActionsProps {}

const ConfigurationReportsActions: React.FC<
  IConfigurationReportsActionsProps
> = props => {
  return (
    <ContainerFlex
      name="ConfigurationReportsNoData"
      alignItems="center"
      justifyContent="flex-end"
      items={[<CreateReportButton />]}
    />
  )
}

export default ConfigurationReportsActions
