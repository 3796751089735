import { consts } from '@app/styles'
import type { DSTheme } from '@design-system/styles/themes/types'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerSimple } from '../Container'
import type { IContainerSimpleProps } from '../Container/ContainerSimple'

export enum LabelAltVariant {
  Normal = 0,

  // font-style

  Bolded = 1 << 0,
  Warning = 1 << 1,
  Error = 1 << 2,
  Disabled = 1 << 3,
  Minor = 1 << 4,
  Solved = 1 << 5,
  Valid = 1 << 6,

  // text-decoration

  LineThrough = 1 << 7,

  // sizes
  Small = 1 << 8,

  // line-height
  Tight = 1 << 9,

  // alignment

  Left = 1 << 10,
  Centered = 1 << 11,
  Right = 1 << 12,

  // cursor

  Pointer = 1 << 13
}

export interface ILabelAltProps extends IContainerSimpleProps {
  variant?: LabelAltVariant
}

export const LabelAlt: React.FC<ILabelAltProps> = props => {
  return <ContainerSimple {...props}>{props.children}</ContainerSimple>
}

function getFontColor(
  variant: LabelAltVariant = LabelAltVariant.Normal,
  theme: DSTheme
) {
  if (variant & LabelAltVariant.Disabled) {
    return consts.colorBlueGray015
  }

  if (variant & LabelAltVariant.Minor) {
    return consts.colorBlueGray015
  }

  if (variant & LabelAltVariant.Warning) {
    return consts.colorOrange001
  }

  if (variant & LabelAltVariant.Error) {
    return theme.tokens['label/color/textError']
  }

  if (variant & LabelAltVariant.Bolded) {
    return 'inherit'
  }

  if (variant & LabelAltVariant.Solved) {
    return theme.tokens['label/color/textSuccess']
  }

  if (variant & LabelAltVariant.Valid) {
    return consts.colorBlue005
  }

  return 'inherit'
}

function getFontWeight(
  variant: LabelAltVariant = LabelAltVariant.Normal
): string {
  if (
    variant & LabelAltVariant.Bolded ||
    variant & LabelAltVariant.Valid ||
    variant & LabelAltVariant.Warning ||
    variant & LabelAltVariant.Error
  ) {
    return consts.fontWeightBold
  }

  return 'normal'
}

function getFontSize(variant: LabelAltVariant = LabelAltVariant.Normal) {
  if (variant & LabelAltVariant.Small) {
    return consts.textSizeVerySmall
  }

  return consts.textSizeNormal
}

function getTextDecoration(
  variant: LabelAltVariant = LabelAltVariant.Normal
): string {
  if (variant & LabelAltVariant.LineThrough) {
    return 'line-through'
  }

  return 'none'
}

function getTextAlignment(
  variant: LabelAltVariant = LabelAltVariant.Normal
): string {
  if (variant & LabelAltVariant.Left) {
    return 'left'
  }

  if (variant & LabelAltVariant.Centered) {
    return 'center'
  }

  if (variant & LabelAltVariant.Right) {
    return 'right'
  }

  return 'left'
}

function getCursor(variant: LabelAltVariant = LabelAltVariant.Normal): string {
  if (variant & LabelAltVariant.Pointer) {
    return 'pointer'
  }

  return 'normal'
}

function getLineHeight(
  variant: LabelAltVariant = LabelAltVariant.Normal
): string {
  if (variant & LabelAltVariant.Tight) {
    return '14px'
  }

  return 'inherit'
}

export default styled(LabelAlt)`
  color: ${props => getFontColor(props.variant, props.theme)};
  font-weight: ${props => getFontWeight(props.variant)};
  font-size: ${props => getFontSize(props.variant)};
  display: ${props => (props.inline === true ? 'inline' : 'block')};
  text-align: ${props => getTextAlignment(props.variant)};
  text-decoration: ${props => getTextDecoration(props.variant)};
  cursor: ${props => getCursor(props.variant)};
  line-height: ${props => getLineHeight(props.variant)};
`
