import { IconDoubleLeft, IconEllipsis } from '@app/components/Icon'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import * as React from 'react'
import { useHover } from 'react-use'
import styled from 'styled-components'

export interface IWidgetListPaginationJumpPreviousButtonProps {}

const WidgetListPaginationJumpPreviousButton: React.FC<
  IWidgetListPaginationJumpPreviousButtonProps
> = () => {
  const button = (hovered: boolean) => (
    <FormWrapperButton
      labelledBy={`page-jump-previous`}
      variant={ButtonVariant.colored}
      size={ButtonSize.small}
      icon={hovered ? IconDoubleLeft : IconEllipsis}
    />
  )

  const [hoverable] = useHover(button)

  return hoverable
}

export default styled(WidgetListPaginationJumpPreviousButton)``
