import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconRecastProps extends IIconProps {}

const IconRecast: React.FC<IIconRecastProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.5 6.5L13.5 8.5L15.5 6.5"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.395 12.4273C10.4545 13.1496 9.2774 13.5789 8 13.5789C4.91881 13.5789 2.42102 11.0811 2.42102 7.99988C2.42102 4.91869 4.91881 2.4209 8 2.4209C11.0812 2.4209 13.579 4.91869 13.579 7.99988"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconRecast
