import { ContainerFlex } from '@app/components/Container'
import { ContainerIcon } from '@app/components-legacy/Container'
import { IllustrationWarningOutlined } from '@app/components-legacy/Icon/IconAntd'
import { TypographyWidgetTitle } from '@app/components-legacy/Typography'
import { useAppTranslator } from '@app/hooks'
import type { WidgetKey } from '@libs/dashboards/types'
import { ensureArray } from '@libs/ensureArray'
import * as React from 'react'
import styled from 'styled-components'
import WidgetHeaderTitleIcons from './WidgetHeaderTitleIcons'

const StyledContainerFlex = styled(ContainerFlex)`
  > div {
    min-width: 0;
  }
`

interface IWidgetHeaderTitleProps {
  widgetKey?: WidgetKey
  title: string
  // optional list of warnings for that widget saying that something is weird
  warnings?: string[]
  onEditIconClick?: () => void
  onDeleteIconClick?: () => void
  onRefreshIconClick?: () => void
}

const WidgetHeaderTitle: React.FC<IWidgetHeaderTitleProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Dashboard.Common'] })

  const onEditIconClick = () => {
    if (props.onEditIconClick) {
      props.onEditIconClick()
    }
  }

  const onDeleteIconClick = () => {
    if (props.onDeleteIconClick) {
      props.onDeleteIconClick()
    }
  }

  const onRefreshIconClick = () => {
    if (props.onRefreshIconClick) {
      props.onRefreshIconClick()
    }
  }

  const renderWarningIcon = () => {
    const warnings = ensureArray(props.warnings)

    if (!warnings.length) {
      return null
    }

    const warningsList = (
      <>
        <p>{translate('This widget has some configuration problems')}</p>
        <ul style={{ margin: 0 }}>
          {warnings.map((warning, i) => {
            return <li key={i}>{warning}</li>
          })}
        </ul>
      </>
    )

    return (
      <ContainerIcon
        labelledBy="widgetWarning"
        iconComponent={IllustrationWarningOutlined}
        tooltipProps={{ title: warningsList }}
        variant="warning"
      />
    )
  }

  return (
    <StyledContainerFlex
      name="WidgetHeaderTitle"
      itemsFlexGrow={[1, 0]}
      itemsFlexShrink={[1, 0]}
      alignItems="center"
      items={[
        <ContainerFlex
          name="WidgetHeaderTitleItems"
          alignItems="center"
          items={[
            renderWarningIcon(),
            <TypographyWidgetTitle>{props.title}</TypographyWidgetTitle>
          ]}
          spaced
          spaceWidth="verySmall"
        />,

        <WidgetHeaderTitleIcons
          widgetKey={props.widgetKey}
          onEditIconClick={onEditIconClick}
          onDeleteIconClick={onDeleteIconClick}
          onRefreshIconClick={onRefreshIconClick}
        />
      ]}
      wrapItems
    />
  )
}

export default WidgetHeaderTitle
