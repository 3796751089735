import type { EntityChecker } from '@app/entities'
import type { IAdObjetAttribute } from '@app/entities/EntityAdObject/types'
import { AppRouteName } from '@app/routes'
import type { StoreTrailFlow } from '@app/stores'
import type StoreEventDetailsAttributes from '@app/stores/TrailFlow/StoreEventDetails/StoreEventDetailsAttributes'

/**
 * When clicking on a checker label, redirect to the IoE checker page.
 */
export const onLabelCheckerClick =
  (storeTrailFlow: StoreTrailFlow) => (checker: EntityChecker) => () => {
    const checkerId = checker.id
    const checkerCodename = checker.codename

    const appRouter = storeTrailFlow.storeRoot.appRouter

    if (checkerId && checkerCodename) {
      const url = appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.IoE_Details_Information,
        parameters: {
          checkerId,
          checkerCodename
        }
      })

      appRouter.history.push(url)
    }
  }

/**
 * When clicking on the show SDDL button, open a drawer with the parsed SDDL.
 */
export const onAttributeShowSDDLButtonClick =
  (storeEventDetailsAttributes: StoreEventDetailsAttributes) =>
  (sddlValue: string) =>
  () => {
    storeEventDetailsAttributes.storeDrawerSDDLDescription
      .setData({ sddl: sddlValue })
      .openDrawer()
  }

/**
 * When clicking on the show attribute value button, open a drawer with the
 * full length of the attribute.
 */
export const onAttributeShowValueButtonClick =
  (storeEventDetailsAttributes: StoreEventDetailsAttributes) =>
  (attribute: IAdObjetAttribute) =>
  () => {
    storeEventDetailsAttributes.storeDrawerAttributeValue
      .setData({ attribute })
      .openDrawer()
  }
