import { AppRouteName } from '@app/routes'
import type { StoreManagementRbacRoles } from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'

export const handleRolesPageOnLoad =
  (storeManagementRbacRoles: StoreManagementRbacRoles) => (): Promise<any> => {
    return storeManagementRbacRoles.fetchRbacRoles()
  }

export const handleRolesPageOnUnload =
  (storeManagementRbacRoles: StoreManagementRbacRoles) => (): void => {
    storeManagementRbacRoles.reset()
  }

export const handleCreateRoleButtonOnClick =
  (appRouter: AppRouterClient) => () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_Accounts_Roles_Create,
      parameters: {}
    })
    appRouter.history.push(url)
  }

export const handleEditRoleIconOnClick =
  (appRouter: AppRouterClient) => (roleId: number) => () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_Accounts_Roles_Edit,
      parameters: {
        roleId
      }
    })
    appRouter.history.push(url)
  }

export const handleDeleteRoleIconOnClick =
  (storeManagementRbacRoles: StoreManagementRbacRoles) =>
  (roleId: number) =>
  () => {
    const roleEntity = storeManagementRbacRoles.rbacRoles.get(roleId)

    if (roleEntity) {
      storeManagementRbacRoles.storeDrawerDeleteRole
        .setData({
          rbacRoleDataRow: roleEntity.asDataRow()
        })
        .openDrawer()
    }
  }
