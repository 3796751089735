import { createEntity, EntityApplicationConfiguration } from '@app/entities'
import type {
  ApplicationConfiguration,
  AvailableLanguage,
  Language,
  Maybe
} from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '..'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'
import { extractLanguageSubtagFromLocale, getLabelFromLocale } from './utils'

export interface ILanguage {
  locale: string
  label: string
}

export default class StoreApplicationConfiguration extends StoreBase {
  private $applicationConfiguration =
    observable.box<Maybe<EntityApplicationConfiguration>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /* Actions */

  /**
   * Save application configuration.
   */
  @action
  setApplicationConfiguration(
    applicationConfiguration: ApplicationConfiguration
  ): this {
    const entities = createEntity<
      ApplicationConfiguration,
      EntityApplicationConfiguration
    >(EntityApplicationConfiguration, applicationConfiguration)

    this.$applicationConfiguration.set(entities)

    return this
  }

  /* Computed */

  @computed
  get applicationConfiguration(): Maybe<EntityApplicationConfiguration> {
    return this.$applicationConfiguration.get()
  }

  @computed
  get availableLanguages(): ILanguage[] {
    if (!this.applicationConfiguration) {
      return []
    }

    const locales: AvailableLanguage[] =
      this.applicationConfiguration.availableLanguages || []

    if (!Array.isArray(locales)) {
      return []
    }

    // Extract language codes and count occurences
    const languageOccurences = locales.reduce((count, locale) => {
      const languageSubtag = extractLanguageSubtagFromLocale(locale.language)

      count.set(languageSubtag, (count.get(languageSubtag) ?? 0) + 1)

      return count
    }, new Map<string, number>())

    return locales
      .map<ILanguage>(availableLanguage => {
        return {
          locale: availableLanguage.language,
          label: getLabelFromLocale(availableLanguage, languageOccurences)
        }
      })
      .filter(locale => {
        // Ensure that backend locales are available locally
        return this.storeRoot.environment.config.i18n.locales.available.includes(
          locale.locale as Language
        )
      })
  }
}
