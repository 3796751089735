import { ContainerFlex } from '@app/components/Container'
import { IconAlert, IconDots, IconSetting } from '@app/components/Icon'
import ContainerRbac from '@app/components-legacy/Container/ContainerRbac'
import DropdownMenu from '@app/components-legacy/DropdownMenu/DropdownMenu'
import { LabelIcon } from '@app/components-legacy/Label'
import { useAppRouter, useAppTranslator, useStores } from '@app/hooks'
import {
  canAccessToHealthCheckConfiguration,
  canAccessToSyslogAlertsConfiguration
} from '@app/pages/Management/SystemPage/ConfigurationPage/permissions'
import { AppRouteName } from '@app/routes'
import { FontColorV2 } from '@app/styles/consts'
import { filterFalsies } from '@libs/filterFalsies'
import { rbacCapabilityCheckAnyOf } from '@libs/rbac/functions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledContainerFlex = styled(ContainerFlex)`
  padding: 0 ${({ theme }) => theme.tokens['padding/default']};
  border-bottom: ${({ theme }) => theme.tokens['menu/style/border']};
`

export interface IHealthCheckMenuButtonsProps {}

function HealthCheckMenuButtons(props: IHealthCheckMenuButtonsProps) {
  const translate = useAppTranslator({
    namespaces: ['HealthCheck.Global']
  })

  const appRouter = useAppRouter()

  const { storeRbac } = useStores()

  return (
    <ContainerRbac
      rbacCapabilityCheck={rbacCapabilityCheckAnyOf(
        canAccessToHealthCheckConfiguration,
        canAccessToSyslogAlertsConfiguration
      )}
    >
      <StyledContainerFlex
        name="HealthCheckMainPageSettingsMenu"
        alignItems="center"
        items={[
          <DropdownMenu
            labelledBy="healthCheckSettings"
            items={filterFalsies([
              storeRbac.isUserGrantedTo(
                canAccessToHealthCheckConfiguration
              ) && {
                key: 'configuration',
                label: (
                  <Link
                    to={appRouter.makeRouteInfosPathname({
                      routeName:
                        AppRouteName.Management_System_Configuration_HealthCheck,
                      parameters: {}
                    })}
                  >
                    <LabelIcon
                      iconComponent={IconSetting}
                      iconProps={{ color: FontColorV2.white, size: 16 }}
                      spaceWidth="small"
                    >
                      {translate('Configuration')}
                    </LabelIcon>
                  </Link>
                )
              },
              storeRbac.isUserGrantedTo(
                canAccessToSyslogAlertsConfiguration
              ) && {
                key: 'syslogs-alerts',
                label: (
                  <Link
                    to={appRouter.makeRouteInfosPathname({
                      routeName:
                        AppRouteName.Management_System_Configuration_Syslogs,
                      parameters: {}
                    })}
                  >
                    <LabelIcon
                      iconComponent={IconAlert}
                      iconProps={{ color: FontColorV2.white, size: 16 }}
                      spaceWidth="small"
                    >
                      {translate('Alerts')}
                    </LabelIcon>
                  </Link>
                )
              }
            ])}
            icon={IconDots}
          />
        ]}
        flexGap="small"
      />
    </ContainerRbac>
  )
}

export default observer(HealthCheckMenuButtons)
