import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import UserCommonForm from '../UsersCreatePage/UserCommonForm'
import { canEditAccountUsers } from '../UsersPage/permissions'
import {
  onCancelButtonClick,
  onUserEditLoad,
  onUserEditSubmit,
  onUserEditUnLoad
} from './handlers'

interface IUserEditProps {}

const UserEdit: React.FC<IUserEditProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.Accounts.Users']
  })

  const appRouter = useAppRouter()

  const { storeBlades, storeMessages, storeManagementUsers, storeRbac } =
    useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_Accounts_Users_Edit,
    parameters: {
      userId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_Accounts_Users_Edit,
        parameters: {
          userId: parameters.userId
        }
      }}
      flags={[
        storeManagementUsers.storeFetchUsersFlags.flags,
        storeManagementUsers.storeEditUserFlags.flags,
        storeManagementUsers.storeSetRolesFlags.flags
      ]}
      onLoad={onUserEditLoad(storeManagementUsers)(parameters.userId)}
      onUnload={onUserEditUnLoad(storeManagementUsers)}
      layout={{
        name: 'default',
        content: (
          <UserCommonForm
            storeForm={storeManagementUsers.storeForm}
            version="edition"
            userId={parameters.userId}
            onCancelButtonClick={onCancelButtonClick(storeBlades)}
            onSubmit={onUserEditSubmit(
              storeMessages,
              storeRbac,
              storeManagementUsers
            )(
              translate,
              appRouter
            )(parameters.userId)}
          />
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canEditAccountUsers(storeRbac)(parameters.userId)}
    />
  )
}

export default observer(UserEdit)
