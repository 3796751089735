import type { Maybe } from '@@types/helpers'
import { createEntities, EntityRbacRole } from '@app/entities'
import type { StoreAuthentication, StoreRoot } from '@app/stores'
import { ensureArray } from '@libs/ensureArray'
import { EscapeHandler } from '@libs/KeyboardBindingsManager/EscapeHandler'
import { KeyboardKey } from '@libs/KeyboardBindingsManager/types'
import { computeRbacPermissions } from '@libs/rbac/functions'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type { RbacRole } from '@server/graphql/typeDefs/types'
import {
  LicenseFeature,
  RbacAction,
  RbacEntityName
} from '@server/graphql/typeDefs/types'

/**
 * Extract the profileName from the URL.
 *
 * Because we are not inside components, we can't retrieve easily matched
 * parameters of the url by the router, so we do it here in an uggly way.
 */
export function extractProfileNameFromUrl(pathname: string): Maybe<string> {
  const parts = pathname.match(new RegExp('/profile/([^/]+)'))
  const profileName = parts ? decodeURI(parts[1]) : null

  // could be the case when profile is not initialized properly and leads to
  // infinite loops
  if (profileName === 'null') {
    return null
  }

  return profileName
}

/**
 * Register the Escape key to close IStoreEscapable stores.
 */
export function registerEscapeHandler(storeRoot: StoreRoot): void {
  storeRoot.keyboardBindingsManager.addBinding(
    KeyboardKey.Escape,
    new EscapeHandler()
  )
}

/**
 * Setup RBAC permissions for the user from user's role and license features.
 */
export function setupUserRbacPermission(
  storeRoot: StoreRoot,
  roles: RbacRole[]
): void {
  const getRbacEntityNameFromLicenseFeature = (
    feature: LicenseFeature
  ): RbacEntityName => {
    switch (feature) {
      case LicenseFeature.Ioe:
        return RbacEntityName.LicenseFeatureIoe

      case LicenseFeature.Ioa:
      case LicenseFeature.IoaPreview:
        return RbacEntityName.LicenseFeatureIoa

      default:
        assertUnreachableCase(feature)
    }
  }

  // add a fake role and permissions according to the features of the license
  const licenseFeaturesRbacRoleEntity = new EntityRbacRole({
    name: 'License features',
    description: 'Permissions granted by the license',
    permissions: ensureArray(
      storeRoot.stores.storeLicense.license?.features
    ).map(feature => {
      return {
        entityName: getRbacEntityNameFromLicenseFeature(feature),
        action: RbacAction.Read,
        entityIds: null
      }
    })
  })

  const rbacRoleEntities = createEntities<RbacRole, EntityRbacRole>(
    EntityRbacRole,
    roles
  )

  const allRbacRoleEntities = rbacRoleEntities.concat(
    licenseFeaturesRbacRoleEntity
  )

  storeRoot.stores.storeAuthentication.setUserRbacPermissions(
    computeRbacPermissions(allRbacRoleEntities)
  )
}

/**
 * Set the profileName as default parameter into the router.
 *
 * Note:
 * Already set in initialize.tsx but when performing logging,
 * the app is not reinitialized again after the redirection
 * and the profileName is not yet.
 * So we set it again.
 */
export function setProfileNameInRouter(
  storeAuthentication: StoreAuthentication
): void {
  const { appRouter } = storeAuthentication.storeRoot

  const { currentSanitizedProfileName } = storeAuthentication

  if (currentSanitizedProfileName) {
    appRouter.setRouterDefaultRouteParameters({
      profileName: currentSanitizedProfileName
    })
  }
}
