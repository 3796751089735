import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelAlt } from '@app/components-legacy/Label'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { createDashboardKey } from '@libs/dashboards/keys'
import type { DashboardKey } from '@libs/dashboards/types'
import { isDefined } from '@libs/isDefined'
import { Menu } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled, { css } from 'styled-components'
import GridMenuButtons from './GridMenuButtons'
import { onMenuEntryClick } from './handlers'

export interface IGridMenuEntry {
  key: DashboardKey
  label: string
}

interface IGridMenuProps {
  className?: string
}

const GridMenu: React.FC<IGridMenuProps> = props => {
  const appRouter = useAppRouter()

  const { storeDashboards, storeBlades } = useStores()

  if (storeDashboards.storeFlagsAllDashboards.isLoading) {
    return null
  }

  // don't display the navigation if there is no dashboard
  if (!storeDashboards.sortedDashboards.length) {
    return null
  }

  const menuEntries: IGridMenuEntry[] = storeDashboards.sortedDashboards.map(
    dashboard => {
      const dashboardKey = createDashboardKey(
        dashboard.getPropertyAsString('instanceName'),
        dashboard.getPropertyAsNumber('id')
      )

      return {
        key: dashboardKey,
        label: dashboard.getPropertyAsString('name')
      }
    }
  )

  const selectedKeys = [storeDashboards.currentDashboardKey].filter(isDefined)

  return (
    <ContainerFlex
      name="GridMenu"
      className={props.className}
      itemsFlexGrow={[1, 0]}
      items={[
        <Menu
          onClick={onMenuEntryClick(storeDashboards, storeBlades, appRouter)}
          selectedKeys={selectedKeys}
          mode="horizontal"
          items={menuEntries.map(menuEntry => ({
            key: menuEntry.key,
            label: (
              <LabelAlt labelledBy={`dashboard-${menuEntry.key}`}>
                {menuEntry.label}
              </LabelAlt>
            )
          }))}
        />,

        <GridMenuButtons />
      ]}
    />
  )
}

const ObservedGridMenu = observer(GridMenu)

export default styled(ObservedGridMenu)`
  /* "Move" the border-bottom on the parent (instead of ul) in order to have the
     border on the full width
  */
  border-bottom: ${({ theme }) => theme.tokens['menu/style/border']};

  > ul {
    border-bottom: none !important;
    /* Limit the width of the Navigation to avoid that buttons are over items */
    width: 80%;
    background-color: ${({ theme }) =>
      theme.tokens['blade/backgroundColor/default']};
  }

  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      .ant-menu-horizontal > .ant-menu-item::after {
        bottom: -1px;
      }

      .ant-menu-item {
        color: ${consts.FontColorV2.primary} !important;
      }
    `}
`
