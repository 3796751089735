import { useStores } from '@app/hooks/useStores'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface ISceneTooltipProps {}

const SceneTooltip: React.FC<ISceneTooltipProps> = props => {
  const { storeTopology } = useStores()

  if (!storeTopology.tooltip) {
    return null
  }

  return (
    <div
      style={{
        position: 'absolute',
        left: storeTopology.tooltip.coordinates.x,
        top: storeTopology.tooltip.coordinates.y
      }}
    >
      <Tooltip
        title={storeTopology.tooltip.object.content}
        arrowPointAtCenter
        defaultVisible
        placement="top"
        overlayStyle={{
          maxWidth: 'none'
        }}
      />
    </div>
  )
}

export default observer(SceneTooltip)
