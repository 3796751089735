import type { IUseHistoryChangeOptions } from '@app/components-legacy/Router/hooks'
import { EntityAttackPathNodeDrawer } from '@app/entities'
import type EntityAttackPathNode from '@app/entities/EntityAttackPathNode'
import type { IAttackPathRouteDefinition } from '@app/routes'
import { AppRouteName } from '@app/routes'
import type { StoreAttackPath } from '@app/stores'
import type { EntityAttackPathNodeAny } from '@app/stores/AttackPath/types'
import { If } from '@libs/fp-helpers/If'
import type { Maybe } from '@server/graphql/typeDefs/types'
import type { SliderSingleProps } from 'antd/lib/slider'

/**
 * Load attack path data and more on scene loading.
 */
export const handleAttackPathOnSceneLoad =
  (storeAttackPath: StoreAttackPath) => (): void => {
    // Restore store from query string
    const { appRouter } = storeAttackPath.storeRoot

    const { storeInfrastructures } = storeAttackPath.storeRoot.stores

    If(!storeInfrastructures.infrastructures.size).fetch(() =>
      storeInfrastructures.fetchInfrastructures()
    )

    storeAttackPath.fetchHealthCheck()

    const qs =
      appRouter.getCurrentRouteQueryStringParameters<
        IAttackPathRouteDefinition['queryStringParameters']
      >()

    if (!qs) {
      return
    }

    // If url have incoherent parameters, redirect to base url without parameters
    if (!qs.sourceNodeId || (!qs.targetNodeId && !qs.direction)) {
      const attackPathUrl = appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.AttackPath,
        parameters: {}
      })

      appRouter.history.replace(attackPathUrl)

      return
    }

    storeAttackPath.initStoreWithQueryStringParameters(qs)
  }

/**
 * Reset store attack path on scene unloading.
 */
export const handleAttackPathOnSceneUnload =
  (storeAttackPath: StoreAttackPath) => (): void => {
    storeAttackPath.reset()
  }

export const updateAttackPathHistory = (storeAttackPath: StoreAttackPath) => {
  const appRouter = storeAttackPath.storeRoot.appRouter
  const queryStringParameters = storeAttackPath.computedQueryString

  if (
    !queryStringParameters.sourceNodeId ||
    (!queryStringParameters.targetNodeId && !queryStringParameters.direction)
  ) {
    return
  }

  if (
    appRouter.getCurrentRouteQueryStringParameters() !== queryStringParameters
  ) {
    const attackPathUrl = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.AttackPath,
      parameters: {},
      queryStringParameters
    })

    // Change history
    appRouter.history.push(attackPathUrl)
  }
}

/**
 * When clicking on an adObject, pin the highlighted path or unpin it if already pinned.
 */
export const handleAdObjectOnClick =
  (storeAttackPath: StoreAttackPath) =>
  (
    currentId: Maybe<string>,
    depth: number,
    adObjectId: number,
    featureFlag: boolean
  ) =>
  (): void => {
    if (!currentId || depth === 0) {
      return
    }

    if (featureFlag) {
      if (storeAttackPath.currentPinnedNodeUid === currentId) {
        storeAttackPath.removeCurrentPinnedNodeUid().removeCurrentPinnedNodeId()
        return
      }

      storeAttackPath
        .setCurrentPinnedNodeUid(currentId)
        .setCurrentPinnedNodeId(adObjectId)
    }
  }

/**
 * When clicking on the count of adObject's children, display a drawer with all the nodes.
 */
export const handleAdObjectChildrenOnClick =
  (storeAttackPath: StoreAttackPath) =>
  (
    adObjectId: Maybe<number>,
    currentId: Maybe<string>,
    depth: number,
    displayedNodes: EntityAttackPathNodeAny[]
  ) =>
  (): void => {
    if (!adObjectId || !currentId) {
      return
    }

    const node = storeAttackPath.getNode(currentId)

    if (!node) {
      return
    }

    if (displayedNodes.length > 0) {
      displayedNodes.forEach(displayedNode =>
        storeAttackPath.storeWidgetListDrawerNodeRelationships.selectRow(
          displayedNode
        )
      )
    }

    if (!node.hasTooManyChildren) {
      storeAttackPath.fetchDrawerNodes(node)
    }

    storeAttackPath.storeDrawer
      .setData({ adObjectId, currentId, depth })
      .openDrawer()
  }

/**
 * When hovering an adObject, keep its id in the store to highlight adObject that shares this adObjectId, on the graph.
 */
export const handleAdObjectOnMouseOver =
  (storeAttackPath: StoreAttackPath) =>
  (adObjectId: Maybe<number>, currentId: Maybe<string>) =>
  (): void => {
    if (!adObjectId || !currentId) {
      return
    }

    storeAttackPath.setAdObjectId(adObjectId).setCurrentNodeId(currentId)
  }

/**
 * When leaving an adObject, unset current adObjectId.
 */
export const handleAdObjectOnMouseOut =
  (storeAttackPath: StoreAttackPath) => () => {
    storeAttackPath.removeAdObjectId().removeCurrentNodeId()
  }

/**
 * When hovering an Edge, save its id to highlight edges that are related.
 */
export const handleEdgeOnMouseOver =
  (storeAttackPath: StoreAttackPath) => (id: Maybe<string>) => (): void => {
    if (!id) {
      return
    }

    storeAttackPath.setCurrentEdgeId(id)
  }

/**
 * When leaving an Edge, unset current edge hovered.
 */
export const handleEdgeOnMouseOut =
  (storeAttackPath: StoreAttackPath) => () => {
    storeAttackPath.removeCurrentEdgeId()
  }

/**
 * When hovering an expand node, keep its uid in the store to highlight it.
 */
export const handleExpandNodeOnMouseOver =
  (storeAttackPath: StoreAttackPath) =>
  (expandNodeUid: Maybe<string>) =>
  (): void => {
    if (!expandNodeUid) {
      return
    }

    storeAttackPath.setHoveredExpandNodeUid(expandNodeUid)
  }

/**
 * When leaving an expanded node, unset current uid.
 */
export const handleExpandNodeOnMouseOut =
  (storeAttackPath: StoreAttackPath) => () => {
    storeAttackPath.removeHoveredExpandNodeUid()
  }

/**
 * When hovering an expand node, keep its uid in the store to highlight it.
 */
export const handleUnexpandNodeOnMouseOver =
  (storeAttackPath: StoreAttackPath) =>
  (expandNodeUid: Maybe<string>) =>
  (): void => {
    if (!expandNodeUid) {
      return
    }

    storeAttackPath.setHoveredUnexpandNodeUid(expandNodeUid)
  }

/**
 * When leaving an expanded node, unset current uid.
 */
export const handleUnexpandNodeOnMouseOut =
  (storeAttackPath: StoreAttackPath) => () => {
    storeAttackPath.removeHoveredUnexpandNodeUid()
  }

/**
 * On click
 */
export const handleExpandNodeOnClick =
  (storeAttackPath: StoreAttackPath) =>
  (expandNodeUid: Maybe<string>, linkedEntity: Maybe<EntityAttackPathNode>) =>
  () => {
    if (!linkedEntity) {
      return
    }

    if (!expandNodeUid) {
      return
    }

    // Start
    if (storeAttackPath.expandedNodeUids.length === 0) {
      storeAttackPath.setExpandedNodeUids([linkedEntity.uid])

      storeAttackPath.fetchExpandNodes(linkedEntity)

      return
    }

    // Case when the user expand another branch of expands
    if (!linkedEntity.isPartOfExpansion) {
      const nodes = storeAttackPath.nodes
        .filter(node => !node.isPartOfExpansion)
        .map(node => {
          node.isSourceOfExpansion = false

          if (node instanceof EntityAttackPathNodeDrawer && node.isExpanded) {
            storeAttackPath.moveBackDrawerNode(node)
          }

          return node
        })

      storeAttackPath.setNodes(nodes)

      storeAttackPath.setEdges(
        storeAttackPath.edges.filter(edge => !edge.isPartOfExpansion)
      )

      storeAttackPath
        .setExpandedNodeUids([linkedEntity.uid])
        .removeCurrentPinnedNodeId()
        .removeCurrentPinnedNodeUid()

      storeAttackPath.fetchExpandNodes(linkedEntity)

      return
    }

    if (
      linkedEntity.linkedNodeEntity &&
      storeAttackPath.expandedNodeUids.includes(
        linkedEntity.linkedNodeEntity?.uid
      ) &&
      storeAttackPath.expandedNodeUids.indexOf(
        linkedEntity.linkedNodeEntity?.uid
      ) !==
        storeAttackPath.expandedNodeUids.length - 1
    ) {
      const indexFound = storeAttackPath.expandedNodeUids.indexOf(
        linkedEntity.linkedNodeEntity?.uid
      )

      storeAttackPath
        .removeExpandedNodeUids(
          storeAttackPath.expandedNodeUids[indexFound + 1]
        )
        .removeCurrentPinnedNodeId()
        .removeCurrentPinnedNodeUid()
    }

    storeAttackPath.setExpandedNodeUids(
      storeAttackPath.expandedNodeUids.concat(linkedEntity.uid)
    )

    // Fetch nodes for the linked entity
    storeAttackPath.fetchExpandNodes(linkedEntity)
  }

export const handleUnexpandNodeOnClick =
  (storeAttackPath: StoreAttackPath) => (expandNodeUid: string) => () => {
    // Change current expanded node
    storeAttackPath
      .removeExpandedNodeUids(expandNodeUid)
      .removeCurrentPinnedNodeId()
      .removeCurrentPinnedNodeUid()
  }

export const handleAdObjectOnSearch =
  (storeAttackPath: StoreAttackPath) => () => {
    if (
      !storeAttackPath.selectedSearchedSourceNode ||
      (storeAttackPath.targetNodeDisplay &&
        !storeAttackPath.selectedSearchedTargetNode)
    ) {
      return
    }

    storeAttackPath.setSourceNode(storeAttackPath.selectedSearchedSourceNode)
    storeAttackPath.setTargetNode(
      storeAttackPath.targetNodeDisplay
        ? storeAttackPath.selectedSearchedTargetNode
        : null
    )

    storeAttackPath.fetchNodes()

    storeAttackPath.removeCurrentPinnedNodeUid().removeCurrentPinnedNodeId()

    updateAttackPathHistory(storeAttackPath)
  }

export const handleAttackPathDirectionChange =
  (storeAttackPath: StoreAttackPath) => () => {
    const { sourceNode } = storeAttackPath

    if (!sourceNode || !sourceNode.name) {
      return
    }

    storeAttackPath.storeInputSearchSource.setSearchValue(sourceNode.name)
    storeAttackPath.setSelectedSearchedSourceNode(sourceNode)
    storeAttackPath.setTargetNodeDisplay(false)

    storeAttackPath.fetchNodes()

    updateAttackPathHistory(storeAttackPath)
  }

/**
 * When ZoomSlider value change, save this value to the store for synchronization with d3 zoom.
 */
export const handleZoomSliderOnChange =
  (storeAttackPath: StoreAttackPath): SliderSingleProps['onChange'] =>
  value => {
    if (!value) {
      return
    }
    const k = (value as number) / 1000
    storeAttackPath.setZoomSliderValue(k)
  }

/**
 * Handler that listens the browser history and update StoreAttackPath
 * according to the current parameters.
 */
export const handleAttackPathHistoryOnChange =
  (
    storeAttackPath: StoreAttackPath
  ): IUseHistoryChangeOptions['onHistoryChange'] =>
  (currentRouteInfo): void => {
    if (!currentRouteInfo) {
      return
    }

    const queryStringParameters =
      currentRouteInfo.queryStringParameters as IAttackPathRouteDefinition['queryStringParameters']

    if (
      !queryStringParameters ||
      !queryStringParameters.sourceNodeId ||
      (!queryStringParameters.targetNodeId && !queryStringParameters.direction)
    ) {
      storeAttackPath.reset()
      return
    }

    storeAttackPath.initStoreWithQueryStringParameters(queryStringParameters)
  }

/**
 * Handler that will force load of an heavy graph
 */
export const handleLoadHeavyGraphOnClick =
  (storeAttackPath: StoreAttackPath) => (): void => {
    storeAttackPath.displayGraph()
    storeAttackPath.storeModalLongRender.hide()
  }

export const handleShowAllTooltipsOnChange =
  (storeAttackPath: StoreAttackPath) => (): void => {
    storeAttackPath.setIsShowingAllTooltips(
      !storeAttackPath.isShowingAllTooltips
    )
  }

/**
 * Handle popover visible changes.
 */
export const onGraphToolsPopoverVisibleChange =
  (storeAttackPath: StoreAttackPath) => (visible: boolean) => {
    storeAttackPath.setPopoverVisible(visible)
  }

/**
 * Handle attack path tab on click to reload or clean the graph
 */
export const handleAttackPathTabOnClick =
  (storeAttackPath: StoreAttackPath) => (): void => {
    // reset expanded node uids
    storeAttackPath.setExpandedNodeUids([])
    // unpin highlighted path
    storeAttackPath.removeCurrentPinnedNodeUid().removeCurrentPinnedNodeId()

    if (storeAttackPath.canValidate) {
      handleAdObjectOnSearch(storeAttackPath)()
      return
    }

    storeAttackPath.setCurrentRequestUuid(null)
    storeAttackPath.cleanGraph()
    // needed to stop the loader when changing tab while a request is still ongoing
    storeAttackPath.storeFlagsFetchNodes.success()
  }

/**
 * When a user click on the icon pin, unpin the current highlighted path.
 */
export const handleAttackPathIconPinOnClick =
  (storeAttackPath: StoreAttackPath) => (): void => {
    if (!storeAttackPath.currentPinnedPath) {
      return
    }

    storeAttackPath.removeCurrentPinnedNodeUid().removeCurrentPinnedNodeId()
  }

/**
 * When a user click on the Zoom reset button, reset zoom to its initial value.
 */
export const handleAttackPathResetZoomOnClick =
  (storeAttackPath: StoreAttackPath) => (): void => {
    if (!storeAttackPath.targetNode) {
      if (storeAttackPath.zoomSelection && storeAttackPath.rootNodeEntity) {
        storeAttackPath.handleZoom?.translateTo(
          storeAttackPath.zoomSelection,
          storeAttackPath.rootNodeEntity?.x,
          storeAttackPath.rootNodeEntity?.y
        )
      }

      storeAttackPath.setZoomSliderValue(1)

      return
    }

    const targetNodeEntity = storeAttackPath.nodes.find(
      node =>
        node.getPropertyAsNumber('id') === storeAttackPath.targetNode?.id &&
        node.getPropertyAsBoolean('isImportantNode') === true
    )

    if (
      storeAttackPath.zoomSelection &&
      storeAttackPath.rootNodeEntity &&
      targetNodeEntity
    ) {
      storeAttackPath.handleZoom?.translateTo(
        storeAttackPath.zoomSelection,
        storeAttackPath.rootNodeEntity.x +
          (targetNodeEntity.x - storeAttackPath.rootNodeEntity.x) / 2,
        storeAttackPath.rootNodeEntity.y +
          (targetNodeEntity.y - storeAttackPath.rootNodeEntity.y) / 2
      )
    }

    // Force zoomSliderValue to the minimal zoomsliderMinValue to display full graph
    storeAttackPath.setZoomSliderValue(
      Math.min(storeAttackPath.zoomSliderMinValue, 1)
    )
  }
