import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperSelect } from '@app/components-legacy/Form'
import { InputSearch, InputSwitch } from '@app/components-legacy/Input'
import { useAppTranslator, useStores } from '@app/hooks'
import { AttacksSummaryLayout } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  handleSummaryCardsSearch,
  handleSummaryLayoutSelection,
  handleSummaryOnlyUnderAttackSwitch
} from './handlers'

interface IIoABoardSearchProps {}

const IoABoardSearch: React.FC<IIoABoardSearchProps> = () => {
  const translate = useAppTranslator({ namespaces: ['IoA.Board'] })

  const { storeIoA } = useStores()
  const { storeSummaryCards } = storeIoA.storeBoard

  return (
    <ContainerFlex
      name={IoABoardSearch.name}
      alignItems="center"
      itemsFlexGrow={[0, 0, 1, 0, 0]}
      items={[
        <div>{translate('Sort by')}</div>,

        <FormWrapperSelect
          labelledBy="sortBy"
          width="normal"
          selectProps={{
            value: storeSummaryCards.summaryLayout,
            onChange: handleSummaryLayoutSelection(storeIoA)
          }}
          selectOptions={[
            {
              label: translate('Domain'),
              value: AttacksSummaryLayout.Alphabetical
            },
            {
              label: translate(AttacksSummaryLayout.Criticity),
              value: AttacksSummaryLayout.Criticity
            },
            {
              label: translate(AttacksSummaryLayout.Infrastructures),
              value: AttacksSummaryLayout.Infrastructures
            }
          ]}
        />,

        <InputSearch
          placeholder={translate('Search a domain or an attack')}
          storeInputSearch={storeSummaryCards.storeInputSearch}
          onChange={handleSummaryCardsSearch(storeIoA)}
        />,

        <InputSwitch
          label={translate('Show only domains under attack')}
          labelledBy="domainsUnderAttack"
          switchProps={{
            defaultChecked:
              storeIoA.storeBoard.storeSummaryCards
                .displayOnlyAttackedDomainsSwitch,
            onChange: handleSummaryOnlyUnderAttackSwitch(storeIoA)
          }}
        />
      ]}
      spaced
    />
  )
}

export default observer(IoABoardSearch)
