import { consts } from '@app/styles'
import { isDefined } from '@libs/isDefined'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '../Container'
import CardCheckbox from './CardCheckbox'

interface ICardProps {
  className?: string
  name?: string
  style?: React.CSSProperties
  active?: boolean
  checkable?: boolean
  onClick?: () => void
  onSelect?: (isActive: boolean) => void
  children?: React.ReactNode
}

const Card: React.FC<ICardProps> = props => {
  const onChange = () => {
    if (props.onSelect) {
      props.onSelect(props.active ?? false)
    }
  }

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()

    if (props.onClick) {
      props.onClick()
    }
  }

  return (
    <ContainerFlex
      name={props.name ?? 'Card'}
      alignItems="center"
      className={props.className}
      style={props.style}
      onClick={onClick}
      itemsFlexGrow={[1]}
      items={[
        <div>{props.children}</div>,

        props.checkable && (
          <CardCheckbox checked={props.active} onClick={onChange} />
        )
      ]}
    />
  )
}

export default styled(Card)`
  background-color: ${consts.colorWhite};

  position: relative;
  overflow: hidden;

  border: 1px solid
    ${({ active, theme }) =>
      active ? consts.colorBlue005 : theme.tokens['card/color/border/default']};
  border-radius: ${consts.borderRadiusSmallRedesign};

  ${props => {
    if (isDefined(props.onClick) || isDefined(props.checkable)) {
      return `
        cursor: pointer;

        transition: ${consts.transitionBackground};

        &:hover {
          background: ${consts.colorBlue002};
        }
      `
    }

    return ''
  }}
`
