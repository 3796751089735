import { ContainerFlex } from '@app/components/Container'
import { Blade, BladesContainer } from '@app/components-legacy/Blade'
import ContainerSections from '@app/components-legacy/Container/ContainerSections'
import { LayoutDefault } from '@app/components-legacy/Layout'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useStores } from '@app/hooks'
import { canAccessToSecurityEngine } from '@app/pages/SecurityEngine/WeaknessInstancesPage/permissions'
import SecurityEngineContainer from '@app/pages/SecurityEngine/WeaknessInstancesPage/SecurityEngineContainer'
import { AppRouteName } from '@app/routes'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { DSThemeName } from '@design-system/styles/themes/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

interface ISecurityEnginePageProps {}

const StyledContainerSections = styled(ContainerSections)`
  // Fix spinner position
  .simplebar-content {
    height: 100%;
  }
`
const WeaknessInstancesPage: React.FC<ISecurityEnginePageProps> = props => {
  const { storeRoot } = useStores()
  const theme = useDSTheme()

  return (
    <DSThemeProvider themeName={DSThemeName.paladin}>
      <LayoutDefault
        content={
          <ContainerFlex
            name="SecurityEnginePage"
            items={[
              <BladesContainer>
                <Blade
                  root={true}
                  routeDefinition={{
                    routeName: AppRouteName.SecurityEngine,
                    parameters: {}
                  }}
                  flags={[]}
                  layout={{
                    name: 'default',
                    content: (
                      <SecurityEngineContainer
                        key={storeRoot.securityEngineKey}
                      />
                    ),
                    footer: (
                      <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
                    )
                  }}
                  paddingH={0}
                  paddingV={0}
                  scrollChildrenContainerOverflow="hidden"
                  rbacCapabilityCheck={canAccessToSecurityEngine}
                />
              </BladesContainer>
            ]}
          />
        }
      />
    </DSThemeProvider>
  )
}

export default observer(WeaknessInstancesPage)
