import { AssetImg } from '@app/components/Asset/AssetImg'
import { ContainerSimple } from '@app/components/Container'
import type { IContainerSimpleProps } from '@app/components-legacy/Container/ContainerSimple'
import { useAppRouter, useTestAttribute } from '@app/hooks'
import { AppRouteName } from '@app/routes'
import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  display: flex;
  width: 159px;
`

interface IHeaderLogoProps extends IContainerSimpleProps {}

const HeaderLogo: React.FC<IHeaderLogoProps> = props => {
  const { testAttributeProps } = useTestAttribute('menu')

  const appRouter = useAppRouter()

  const url = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.HomePage,
    parameters: {}
  })

  return (
    <ContainerSimple name="HeaderLogo" labelledBy="headerLogo" {...props}>
      <StyledLink to={url} {...testAttributeProps('link')('headerLogo')}>
        <AssetImg imageName="logo-tenable-ad" width={159} fileType="svg" />
      </StyledLink>
    </ContainerSimple>
  )
}

export default styled(HeaderLogo)`
  padding-left: 19px;
  user-select: none;
`
