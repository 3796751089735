import type { Maybe } from '@@types/helpers'
import { createEntities, createEntity, EntityUserLog } from '@app/entities'
import EntityPaginationHal from '@app/entities/EntityPaginationHal'
import type {
  IConfigurationChanged,
  IDirectoryConfigurationChanged
} from '@app/entities/EntityUserLog'
import StoreDatePicker from '@app/stores/helpers/StoreDatePicker'
import StoreBase from '@app/stores/StoreBase'
import { getInfrastructuresColorScheme } from '@app/styles/colors'
import { CSSColors } from '@app/styles/colors/types'
import { DateFormat, formatDate } from '@libs/dates/formatDate'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { GQLRequestor } from '@libs/graphQL'
import { filterNullOrUndefinedValues } from '@libs/helpers/objects/filterNullOrUndefinedValues'
import { getLogger } from '@libs/logger'
import { checkRbac } from '@libs/rbac/functions'
import { addSetValueToMap } from '@libs/setValueToMap'
import type {
  MutationAddPageVisited,
  MutationDeleteAllUserTraces
} from '@server/graphql/mutations/userTrace'
import {
  mutationAddPageVisited,
  mutationDeleteAllUserTraces
} from '@server/graphql/mutations/userTrace'
import type { QueryRbacUsersTraces } from '@server/graphql/queries/usersTraces'
import { queryRbacUsersTraces } from '@server/graphql/queries/usersTraces'
import type {
  InputAddPageVisited,
  PaginationHal,
  RbacUsersTracesQueryArgs,
  UserTrace
} from '@server/graphql/typeDefs/types'
import { UserTraceLogType } from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import type { StoreRoot } from '..'
import StoreFlags from '../helpers/StoreFlags'
import StoreModal from '../helpers/StoreModal'
import type { IStoreOptions } from '../types'
import StoreActivityLogPayload from './StoreActivityLogPayload'
import StoreActivityLogsFilters from './StoreActivityLogsFilters'
import type { ActivityLogsQueryStringParameters } from './types'

export interface IFetchLogsParams {
  replaceExistingLogs: boolean
}

export default class StoreActivityLogs extends StoreBase {
  /* Modals */
  public storeModalConfirmDeleteTraces = new StoreModal(this.storeRoot)

  /* DatePickers */
  public storeDatePicker = new StoreDatePicker(this.storeRoot, {
    defaultDateStart: null,
    defaultDateEnd: null
  })

  /* Flags */
  public storeFlagsFetchLogs = new StoreFlags(this.storeRoot)
  public storeFlagsFetchMoreLogs = new StoreFlags(this.storeRoot)

  public storeActivityLogsFilters = new StoreActivityLogsFilters(
    this.storeRoot,
    {}
  )
  public storeActivityLogPayload = new StoreActivityLogPayload(
    this.storeRoot,
    {}
  )

  /* Private */
  private flatLogs: EntityUserLog[] = []
  private _logger = getLogger('UserTrace')

  /* Observables */
  private $logsByDay = observable.map<string, Set<EntityUserLog>>([])
  private $nextLink = observable.box<Maybe<string>>(null)
  private $hasMoreTracesAvailable = observable.box<Maybe<boolean>>(null)

  private $infrastructureColors = observable.map<number, string>([])
  private $directoryColors = observable.map<number, string>([])

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch the users traces
   */
  public fetchLogs(fetchLogsParams: IFetchLogsParams): Promise<void> {
    const storeFlags = fetchLogsParams.replaceExistingLogs
      ? this.storeFlagsFetchLogs
      : this.storeFlagsFetchMoreLogs
    storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args = this._buildQueryArgs()
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryRbacUsersTraces>(queryRbacUsersTraces, args, {
            keepGraphQLError: true
          })
      })
      .then(({ rbacUsersTraces }) => {
        if (!checkRbac(this.storeRoot, storeFlags)(rbacUsersTraces)) {
          throw new ForbiddenAccessError()
        }

        if (!rbacUsersTraces) {
          throw new Error()
        }

        const usersTraces = rbacUsersTraces.node.node
        const pagination = rbacUsersTraces.node.pagination

        const usersTracesEntities = createEntities<UserTrace, EntityUserLog>(
          EntityUserLog,
          usersTraces
        )

        const usersTracesPagination = createEntity<
          PaginationHal,
          EntityPaginationHal
        >(EntityPaginationHal, pagination)

        // Generate an ordered list of logs by day
        const logsByDate = usersTracesEntities.reduce(
          (acc, userTraceEntity) => {
            if (!userTraceEntity.createdAt) {
              return acc
            }

            const day = formatDate(userTraceEntity.createdAt, {
              format: `${DateFormat.month} ${DateFormat.dayNumber},${DateFormat.year}`
            })

            addSetValueToMap(acc, day, userTraceEntity)

            return acc
          },
          new Map<string, Set<EntityUserLog>>()
        )

        if (fetchLogsParams.replaceExistingLogs) {
          this.flatLogs = []
        }

        this.flatLogs.push(...usersTracesEntities)

        this.generateInfrastructureColors()
        this.generateDirectoryColors()

        this.setLogsByDay(logsByDate, fetchLogsParams.replaceExistingLogs)
        this.setNextLink(usersTracesPagination.nextLink)
        // More traces are available if the nextLink is non-null
        this.setHasMoreTracesAvailable(usersTracesPagination.nextLink !== null)
        storeFlags.success()
      })
      .catch(err => {
        if (err?.response?.errors[0]?.statusCode === 503) {
          storeFlags.success()
          return
        }
        handleStoreError(
          this.storeRoot,
          storeFlags
        )(GQLRequestor.transformError(err))
      })
  }

  public savePageVisited(pageVisited: InputAddPageVisited): Promise<void> {
    return Promise.resolve()
      .then(() => {
        const args: MutationAddPageVisited['args'] = {
          pageVisited
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationAddPageVisited>(mutationAddPageVisited, args)
      })
      .then(() => {
        this._logger.debug('👁 Page visited has been inserted 👁')
      })
      .catch(handleStoreError(this.storeRoot, null))
  }

  public deleteAllUserTraces(): Promise<void> {
    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationDeleteAllUserTraces>(mutationDeleteAllUserTraces)
      })
      .then(() => {
        this._logger.debug('👁 Traces has been deleted 👁')
      })
      .catch(handleStoreError(this.storeRoot, null))
  }

  /**
   * Reload the users traces
   */

  public reloadLogs = (): Promise<void> => {
    // Reset next link
    this.$nextLink.set(null)

    return this.fetchLogs({ replaceExistingLogs: true })
  }

  /**
   * Return all querystring parameters matching filters of the logs.
   */
  public computeBoardQueryParameters(): ActivityLogsQueryStringParameters {
    return this.computedQueryStringFilters
  }

  /**
   * Private
   */

  /**
   * Build the query args
   * if args are provided in the url, will set the default values to this
   */
  private _buildQueryArgs(): RbacUsersTracesQueryArgs {
    const range = this.storeDatePicker.datePickerRangeValueAsIsoStrings

    const dateStart = range?.dateStart
    const dateEnd = range?.dateEnd
    const ips = this.storeActivityLogsFilters.selectedIPs
    const userEmails = this.storeActivityLogsFilters.selectedUserEmails
    const logTypes = this.storeActivityLogsFilters.selectedLogTypes
    const nextLink = this.$nextLink.get()

    return filterNullOrUndefinedValues({
      dateStart,
      dateEnd,
      ips,
      userEmails,
      logTypes,
      nextLink
    })
  }

  /* Actions */

  @action
  setLogsByDay(
    logsByDay: Map<string, Set<EntityUserLog>>,
    replaceExistingLogs: boolean
  ): this {
    if (replaceExistingLogs) {
      this.$logsByDay.replace(logsByDay)

      return this
    }

    const mergedLogs = toJS(this.$logsByDay)

    logsByDay.forEach((logs: Set<EntityUserLog>, day: string) => {
      logs.forEach(log => {
        addSetValueToMap(mergedLogs, day, log)
      })
    })

    this.$logsByDay.replace(mergedLogs)

    return this
  }

  @action
  setNextLink(nextLink: Maybe<string>): this {
    this.$nextLink.set(nextLink)

    return this
  }

  @action
  setHasMoreTracesAvailable(val: boolean): this {
    this.$hasMoreTracesAvailable.set(val)

    return this
  }

  @action
  generateInfrastructureColors(): this {
    const uniqueInfrastructures = this.flatLogs
      .filter(
        log =>
          log.logType === UserTraceLogType.InfrastructureCreated ||
          log.logType === UserTraceLogType.InfrastructureDeleted ||
          log.logType === UserTraceLogType.ConfigurationChanged
      )
      .reduce((acc, log) => {
        if (log.logType === UserTraceLogType.ConfigurationChanged) {
          const configurationChanges =
            log.decodedLogAttributes as IConfigurationChanged

          if (!configurationChanges) {
            return acc
          }

          configurationChanges.items.forEach(item => {
            if (item.directoryId) {
              if (!acc.has(item.directoryId)) {
                acc.add(item.directoryId)
              }
            }
          })

          return acc
        }
        if (!log.logAttributes) {
          return acc
        }
        const logAttributes = JSON.parse(log.logAttributes)
        if (!acc.has(logAttributes.infrastructureId)) {
          acc.add(logAttributes.infrastructureId)
        }
        return acc
      }, new Set<number>())

    const scheme = getInfrastructuresColorScheme(uniqueInfrastructures.size)

    this.$infrastructureColors.replace(
      Array.from(uniqueInfrastructures.values()).reduce(
        (acc, infrastructureId, currentIndex) => {
          // Find if there is an existing color for this infrastructure.
          const existingColor =
            this.storeRoot.stores.storeInfrastructures.getInfrastructureColor(
              infrastructureId
            )

          // If not, using one from the scheme.
          acc.set(
            infrastructureId,
            existingColor === CSSColors.Black
              ? scheme[currentIndex]
              : existingColor
          )
          return acc
        },
        new Map<number, string>()
      )
    )

    return this
  }

  @action
  generateDirectoryColors(): this {
    const uniqueDirectories = this.flatLogs
      .filter(
        log =>
          log.logType === UserTraceLogType.DirectoryCreated ||
          log.logType === UserTraceLogType.DirectoryDeleted ||
          log.logType === UserTraceLogType.ConfigurationChanged
      )
      .reduce((acc, log) => {
        if (log.logType === UserTraceLogType.ConfigurationChanged) {
          const configurationChanges =
            log.decodedLogAttributes as IConfigurationChanged

          if (!configurationChanges) {
            return acc
          }

          configurationChanges.items.forEach(item => {
            if (item.directoryId) {
              if (!acc.has(item.directoryId)) {
                acc.add(item.directoryId)
              }
            }
            if (item.directoryIds) {
              item.directoryIds.forEach(directoryId => {
                if (!acc.has(directoryId)) {
                  acc.add(directoryId)
                }
              })
            }
          })

          return acc
        }

        const directoryConfigurationChanges =
          log.decodedLogAttributes as IDirectoryConfigurationChanged

        if (
          !directoryConfigurationChanges ||
          !directoryConfigurationChanges.directoryId
        ) {
          return acc
        }
        if (!acc.has(directoryConfigurationChanges.directoryId)) {
          acc.add(directoryConfigurationChanges.directoryId)
        }
        return acc
      }, new Set<number>())

    const scheme = getInfrastructuresColorScheme(uniqueDirectories.size)

    this.$directoryColors.replace(
      Array.from(uniqueDirectories.values()).reduce(
        (acc, directoryId, currentIndex) => {
          // Find if there is an existing color for this directory.
          const existingColor =
            this.storeRoot.stores.storeInfrastructures.getDirectoryColor(
              directoryId
            )

          // If not, using one from the scheme.
          acc.set(
            directoryId,
            existingColor === CSSColors.Black
              ? scheme[currentIndex]
              : existingColor
          )
          return acc
        },
        new Map<number, string>()
      )
    )

    return this
  }

  /**
   * Reset store
   */
  @action
  reset(): this {
    this.storeFlagsFetchLogs.reset()
    this.storeFlagsFetchMoreLogs.reset()

    this.$logsByDay.clear()
    this.$nextLink.set(null)

    this.flatLogs = []
    this.$directoryColors.clear()
    this.$infrastructureColors.clear()

    return this
  }

  /* Computed */

  /**
   * Return usertrace entities.
   */
  @computed
  get logsByDay(): Map<string, Set<EntityUserLog>> {
    return toJS(this.$logsByDay)
  }

  /**
   * Return the querystring filters according to the current state of local
   * stores.
   */
  @computed
  get computedQueryStringFilters(): ActivityLogsQueryStringParameters {
    const range = this.storeDatePicker.datePickerRangeValueAsIsoStrings

    const dateStart = range?.dateStart
    const dateEnd = range?.dateEnd
    const ips = this.storeActivityLogsFilters.selectedIPs
    const userEmails = this.storeActivityLogsFilters.selectedUserEmails
    const logTypes = this.storeActivityLogsFilters.selectedLogTypes

    return filterNullOrUndefinedValues({
      dateStart,
      dateEnd,
      ips,
      userEmails,
      logTypes
    })
  }

  /**
   * Return next link
   */
  @computed
  get nextLink(): Maybe<string> {
    return this.$nextLink.get()
  }

  /**
   * Return hasMoreTracesAvailable.
   */
  @computed
  get hasMoreTracesAvailable(): Maybe<boolean> {
    return this.$hasMoreTracesAvailable.get()
  }

  /**
   * Return all log infrastructure's colors
   */
  @computed
  get infrastructureColors(): Map<number, string> {
    return toJS(this.$infrastructureColors)
  }

  /**
   * Return all log directory's colors
   */
  @computed
  get directoryColors(): Map<number, string> {
    return toJS(this.$directoryColors)
  }
}
