import { canAccessToAccounts } from '@app/pages/Management/AccountsPage/permissions'
import {
  rbacCapabilityCheckAllOf,
  rbacCapabilityCheckAnyOf
} from '@libs/rbac/functions'
import {
  createProfile,
  editProfile,
  readProfiles,
  readUIAccountsProfiles
} from '@libs/rbac/permissions'

export const canAccessToProfiles = rbacCapabilityCheckAllOf(
  canAccessToAccounts,
  readUIAccountsProfiles(),
  readProfiles()
)

export const canCreateProfiles = rbacCapabilityCheckAllOf(
  canAccessToProfiles,
  createProfile()
)

export const canEditProfiles = (
  isTenableProfile: boolean,
  profileId: number
) => {
  return rbacCapabilityCheckAllOf(
    canAccessToProfiles,
    isTenableProfile
      ? // if Tenable profile, require a read permission
        readProfiles(Number(profileId))
      : // if not Tenable profile, require a read OR an edit permission
        rbacCapabilityCheckAnyOf(
          readProfiles(Number(profileId)),
          editProfile(Number(profileId))
        )
  )
}
