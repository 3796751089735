export enum EventType {
  PageClosed = 'page closed',
  ButtonClicked = 'button clicked',
  FormSent = 'form sent'
}

export type ParamsPageVisited = {
  name: string
  href: string
}

export type ParamsPageLeft = {
  pageName: string
  href: string
  timeSpent: number
}

export type ParamsFormSubmitted = {
  formName: string
}

export type ParamsButtonClick = {
  name: string
  href: string
}

/**
 * Telemetry framework type definitions.
 */
export interface ITelemetryEnableMetadata {
  dataSourceKey: string
  development: boolean
  debug: boolean
  containerId: string
}

export interface ITelemetryIdentifyMetadata {
  userUid: string
}

// https://github.eng.tenable.com/Product/framework-browser-telemetry
export enum XHR_VERBOSITY {
  NONE = 0,
  ERROR = 1,
  ERROR_ABORT = 2,
  ALL = 3
}
export interface ITelemetryExternalScriptAPI {
  enable: (
    dataSourceKey: string,
    options?: {
      development?: boolean
      autotrack?: boolean
      autotrack_options?: {
        blur?: boolean
        click?: boolean
        focus?: boolean
        page?: boolean
      }
      debug?: boolean
      exclude?: string[]
      xhr_verbosity?: XHR_VERBOSITY
    }
  ) => void

  disable: () => void

  identify: (
    userUuid: string,
    options?: {
      container_id: string
    }
  ) => void

  event: (name: string, properties?: object) => void

  page: (options: { href?: string; name?: string }) => void
}
