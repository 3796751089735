import { IconDirection } from '@app/components/Icon'
import type { IIconDirectionProps } from '@app/components/Icon/IconDirection'
import { Direction } from '@app/components/Icon/IconDirection'
import { ContainerFlex } from '@app/components-legacy/Container'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import type StoreDrawer from '@app/stores/helpers/StoreDrawer'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import type { ButtonProps } from 'antd/lib/button'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { IconArrow } from '../Icon'

interface IButtonShowPickerProps {
  buttonsProps: ButtonProps
  storeDrawer: StoreDrawer<any>
  children?: React.ReactNode
  labelledBy?: string
}

/**
 * Component used to open/close a drawer for items picking (directories, reasons...)
 * It render a button with a left arrow on the right.
 */
const ButtonShowPicker: React.FC<IButtonShowPickerProps> = props => {
  const theme = useDSTheme()

  if (isThemeLegacy(theme)) {
    return (
      <FormWrapperButton
        labelledBy={props.labelledBy ?? 'showPicker'}
        buttonProps={props.buttonsProps}
      >
        <ContainerFlex
          name="ButtonShowPicker"
          alignItems="center"
          items={[
            // children is the label of the button
            props.children,

            <IconArrow
              labelledBy="showPicker"
              direction="right"
              rotation={180}
              expanded={props.storeDrawer.isDrawerOpened}
            />
          ]}
          spaced
          spaceWidth="verySmall"
        />
      </FormWrapperButton>
    )
  }

  return (
    <FormWrapperButton<IIconDirectionProps>
      labelledBy={props.labelledBy ?? 'showPicker'}
      buttonProps={props.buttonsProps}
      variant={ButtonVariant.select}
      size={ButtonSize.small}
      icon={IconDirection}
      iconProps={{
        direction: props.storeDrawer.isDrawerOpened
          ? Direction.left
          : Direction.right
      }}
      iconAfterLabel
      wrapWithLabel
    >
      {props.children}
    </FormWrapperButton>
  )
}

export default observer(ButtonShowPicker)
