import type { MaybeUndef } from '@@types/helpers'
import { ContainerFlex } from '@app/components/Container'
import { useMenuEntriesSelection } from '@app/components-legacy/Menu/hooks'
import type StoreMenu from '@app/stores/helpers/StoreMenu'
import { MenuEntryType } from '@app/stores/helpers/StoreMenu/types'
import { BrandColorV2 } from '@app/styles/consts'
import { buildVariants } from '@design-system/libs/buildVariants'
import * as React from 'react'
import styled from 'styled-components'
import MenuEntry from './MenuEntry'
import type { IMainMenuEntry } from './types'

interface IMenuContainerProps {
  variant?: 'current' | 'legacy' // To remove when the toggle kapteyn-left-navigation-ui is deleted
}

interface IMenuEntriesProps extends IMenuContainerProps {
  style?: React.CSSProperties
  className?: string
  storeMenu: StoreMenu<IMainMenuEntry>
  menuEntries: IMainMenuEntry[]
}

const MenuContainer = styled(ContainerFlex)<IMenuContainerProps>(props => {
  return buildVariants(props)
    .css({})
    .variant('variant', props.variant ?? 'legacy', {
      legacy: {},
      current: {
        backgroundColor: props.theme.tokens['mainMenu/group/color/background'],
        borderRadius: props.theme.tokens['mainMenu/group/border-radius/default']
      }
    })
    .end()
})

export default function MenuEntries(props: IMenuEntriesProps) {
  useMenuEntriesSelection(props.storeMenu, true)

  const colorGradients = [
    BrandColorV2.blue1,
    BrandColorV2.blue2,
    BrandColorV2.blue3,
    BrandColorV2.blue4,
    BrandColorV2.blue5,
    BrandColorV2.blue6,
    BrandColorV2.blue7,
    BrandColorV2.blue8,
    BrandColorV2.blue9,
    BrandColorV2.blue9,
    BrandColorV2.blue9
  ]

  function entriesToCategoryMap(
    entries: IMainMenuEntry[]
  ): Map<IMainMenuEntry, IMainMenuEntry[]> {
    const entriesMap = new Map<IMainMenuEntry, IMainMenuEntry[]>()
    let currentCategory: IMainMenuEntry | null = null

    entries.forEach(entry => {
      if (entry.type === MenuEntryType.category) {
        currentCategory = entry
        entriesMap.set(entry, [])
      } else if (currentCategory) {
        entriesMap.get(currentCategory)!.push(entry)
      }
    })

    return entriesMap
  }

  const menuEntriesMain = props.menuEntries.filter(entry => !entry.bottom)
  const menuEntriesBottom = props.menuEntries.filter(entry => entry.bottom)

  function getGradientColor(entry: IMainMenuEntry): MaybeUndef<BrandColorV2> {
    if (entry.type === 'link' || entry.type === 'externalLink') {
      return colorGradients.shift()
    }
    return
  }
  const renderMenuContainer = () => {
    // need to remap the menu items with their category for the grouping styles.
    const menuEntriesMap = entriesToCategoryMap(menuEntriesMain)

    if (props.variant === 'current') {
      return (
        <>
          {Array.from(menuEntriesMap.entries()).map(([category, entries]) => (
            <MenuContainer
              key={category.key}
              name="MenuEntries"
              style={props.style}
              className={props.className}
              direction="column"
              justifyContent="space-between"
              flexGap="small"
              // 'legacy' here means no background color
              variant="legacy"
              items={[
                <MenuEntry
                  entry={category}
                  gradientColor={getGradientColor(category)}
                  storeMenu={props.storeMenu}
                />,
                <MenuContainer
                  name="SubMenuEntries"
                  style={props.style}
                  className={props.className}
                  direction="column"
                  justifyContent="space-between"
                  variant="current"
                  items={[
                    <ContainerFlex
                      name="MenuEntriesMain"
                      style={props.style}
                      className={props.className}
                      direction="column"
                      items={[
                        ...entries.map(entry => (
                          <MenuEntry
                            entry={entry}
                            gradientColor={getGradientColor(entry)}
                            storeMenu={props.storeMenu}
                          />
                        ))
                      ]}
                      spaced
                      spaceWidth="veryVerySmall"
                    />
                  ]}
                />
              ]}
            />
          ))}
        </>
      )
    }

    // legacy rendering. Could be optimized by merging with 'current' rendering
    // but: >tricky< and should be removed soon normally.
    return (
      <MenuContainer
        name="MenuEntries"
        style={props.style}
        className={props.className}
        direction="column"
        justifyContent="space-between"
        variant="legacy"
        items={[
          <ContainerFlex
            name="MenuEntriesMain"
            style={props.style}
            className={props.className}
            direction="column"
            items={[
              ...menuEntriesMain.map(entry => (
                <MenuEntry
                  entry={entry}
                  gradientColor={getGradientColor(entry)}
                  storeMenu={props.storeMenu}
                />
              ))
            ]}
            spaced
            spaceWidth="small"
          />,
          <ContainerFlex
            name="MenuEntriesBottom"
            style={props.style}
            className={props.className}
            direction="column"
            items={[
              ...menuEntriesBottom.map(entry => (
                <MenuEntry
                  entry={entry}
                  gradientColor={getGradientColor(entry)}
                  storeMenu={props.storeMenu}
                />
              ))
            ]}
            spaced
            spaceWidth="small"
          />
        ]}
        spaced
        spaceWidth="small"
      />
    )
  }

  return renderMenuContainer()
}
