import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks/useStores'
import MenuSystem from '@app/pages/Management/SystemPage/MenuSystem'
import type { IManagementSystemRelaysEditRouteDefinition } from '@app/routes'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  handleRelayOnLoad,
  handleRelayOnUnload
} from '../../ConfigurationPage/ConfigurationRelayPage/handlers'
import {
  canAccessToRelays,
  canEditRelays,
  canRenewRelaysApiKeys
} from '../permissions'
import RelaysEditPage from '../RelaysEditPage'
import DrawerActionDeleteRelay from './DrawerActionDeleteRelay'
import RelaysFooter from './RelaysFooter'
import RelaysList from './RelaysList'

interface IRelaysPageProps {}

const RelaysPage: React.FC<IRelaysPageProps> = props => {
  const { storeRbac, storeManagementRelays } = useStores()

  if (storeRbac.isUserGrantedTo(canRenewRelaysApiKeys())) {
    storeManagementRelays.storeWidgetList.options.selectable = true
  }

  return (
    <>
      <Blade
        root={true}
        routeDefinition={{
          routeName: AppRouteName.Management_System_Relays,
          parameters: {}
        }}
        onLoad={handleRelayOnLoad(storeManagementRelays)}
        onUnload={handleRelayOnUnload(storeManagementRelays)}
        flags={storeManagementRelays.storeFlagsRelaysFetch.flags}
        layout={{
          name: 'default',
          menu: <MenuSystem />,
          content: (
            <>
              <ContainerContent>
                <RelaysList />
              </ContainerContent>

              <Portal name={PlaceHolderName.genericDrawer}>
                <DrawerActionDeleteRelay />
              </Portal>
            </>
          ),
          footer: <RelaysFooter />
        }}
        rbacCapabilityCheck={canAccessToRelays}
      />

      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName: AppRouteName.Management_System_Relays_Edit,
              parameters: {
                relayId: Number()
              }
            },
            component: RelaysEditPage,
            rbacPermissionsCheck: (
              parameters: IManagementSystemRelaysEditRouteDefinition['parameters']
            ) => canEditRelays(parameters.relayId)
          }
        ]}
      />
    </>
  )
}

export default observer(RelaysPage)
