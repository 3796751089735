import {
  rbacCapabilityCheckAllOf,
  rbacCapabilityCheckAnyOf
} from '@libs/rbac/functions'
import {
  readLicenseFeatureIoA,
  readLicenseFeatureIoE,
  readUIAccounts
} from '@libs/rbac/permissions'
import { canDisplayActivityLogs } from './ActivityLogs/permissions'

export const canAccessToAccounts = rbacCapabilityCheckAnyOf(
  canDisplayActivityLogs,
  rbacCapabilityCheckAllOf(
    readUIAccounts(),
    rbacCapabilityCheckAnyOf(readLicenseFeatureIoE(), readLicenseFeatureIoA())
  )
)
