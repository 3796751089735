import type { EntityTopologyDirectory } from '@app/entities'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { checkRbac } from '@libs/rbac/functions'
import type { QueryRbacTopologyDomainDetails } from '@server/graphql/queries/topology'
import { queryRbacTopologyDomainDetails } from '@server/graphql/queries/topology'
import type {
  Maybe,
  RbacTopologyDomainDetailsQueryArgs
} from '@server/graphql/typeDefs/types'
import { RbacEntityName } from '@server/graphql/typeDefs/types'
import { first, flatMap } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { StoreIoE } from '..'
import type { StoreRoot } from '..'
import StoreFlags from '../helpers/StoreFlags'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'

export default class StoreTopologyDomainDetails extends StoreBase {
  public storeIoE = new StoreIoE(this.storeRoot, { topologyContext: true })
  public storeFlagsFetchDomainScore = new StoreFlags(this.storeRoot)

  /* Observables */

  private $domainScore = observable.box<Maybe<number>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Find and return directory object from its id.
   */
  getDirectoryFromId(directoryId: number): Maybe<EntityTopologyDirectory> {
    const allDirectories =
      this.storeRoot.stores.storeTopology.topologyEntity.getAllDirectories()

    const directoryFound = flatMap(Array.from(allDirectories.values())).find(
      directory => directory.id === directoryId
    )

    if (!directoryFound) {
      return null
    }

    return directoryFound
  }

  /**
   * Fetch Domain score
   */
  fetchDomainScore(args: RbacTopologyDomainDetailsQueryArgs): Promise<void> {
    this.storeFlagsFetchDomainScore.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryRbacTopologyDomainDetails>(
            queryRbacTopologyDomainDetails,
            args
          )
      })
      .then(({ rbacTopologyDomainDetails }) => {
        if (!checkRbac(this.storeRoot)(rbacTopologyDomainDetails)) {
          throw new ForbiddenAccessError(RbacEntityName.Deviance)
        }

        const latestData = first(rbacTopologyDomainDetails.node.data)

        if (latestData) {
          this.setDomainScore(latestData.value)
        }

        this.storeFlagsFetchDomainScore.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsFetchDomainScore))
  }

  /* Actions */

  /**
   * Save domain score.
   */
  @action
  setDomainScore(score: number): this {
    this.$domainScore.set(score)
    return this
  }

  /* Computed */

  /**
   * Return the topology entity.
   */
  @computed
  get domainScore(): Maybe<number> {
    return this.$domainScore.get()
  }
}
