import type EntityAdObjectAsAttribute from '@app/entities/EntityAdObject/EntityAdObjectAsAttribute'
import type {
  IAdObjetAttribute,
  IDataRowAdObjectAsAttribute
} from '@app/entities/EntityAdObject/types'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import type { IWidgetListDataSet } from '@app/stores/helpers/StoreWidgetList/types'
import StoreBase from '@app/stores/StoreBase'
import type StoreRoot from '@app/stores/StoreRoot'
import type { IStoreOptions } from '@app/stores/types'
import { sortBy } from 'lodash'

export default class StoreEventDetailsAttributes extends StoreBase {
  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  public storeWidgetListAttributes = new StoreWidgetList<
    EntityAdObjectAsAttribute,
    IDataRowAdObjectAsAttribute
  >(this.storeRoot, {
    selectable: false,
    buildDataSetFn: this.attributesAsDataSet.bind(this)
  })

  public storeDrawerSDDLDescription = new StoreDrawer<{
    sddl: string
  }>(this.storeRoot)

  public storeDrawerAttributeValue = new StoreDrawer<{
    attribute: IAdObjetAttribute
  }>(this.storeRoot)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
  }

  /**
   * Return the attributes as a dataSet.
   */
  attributesAsDataSet(): IWidgetListDataSet<IDataRowAdObjectAsAttribute> {
    const event = this.storeRoot.stores.storeTrailFlow.storeEventDetails.event
    const adObject = event && event.getAdObject()

    if (!adObject) {
      return {
        columns: [],
        rows: []
      }
    }

    const columns = adObject.getColumns()
    const dataRow = adObject.asCustomDataRow(
      this.storeInputSearch.transformedSearchValueAsRegexp
    )
    const sortedChanges = sortBy(dataRow.changes, ['attributeName'])
    const sortedAttributes = sortBy(dataRow.attributes, ['attributeName'])
    const rows = [...sortedChanges, ...sortedAttributes]

    return { columns, rows }
  }

  reset() {
    this.storeDrawerSDDLDescription.closeDrawer()
    this.storeInputSearch.reset()
  }
}
