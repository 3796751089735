import type { Maybe } from '@@types/helpers'
import type { TContainerFlexAlignItems } from '@app/components-legacy/Container/ContainerFlex/types'
import type { TranslateFn } from '@libs/i18n'

export type FieldName = string
export type Label = string
export type FieldValue = string | number | boolean
export type FieldError = string
export type FieldSuccess = string

export enum InputType {
  checkbox = 'checkbox',
  input = 'input',
  inputPassword = 'inputPassword',
  inputAutoComplete = 'inputAutoComplete',
  textAreaCertificate = 'textAreaCertificate',
  select = 'select',
  switch = 'switch',
  // no displayed by WidgetForm
  hidden = 'hidden'
}

export interface IStoreFormFieldConfiguration {
  label: string
  labelAlignItem?: TContainerFlexAlignItems
  description?: string
  sanitizors?: IFieldSanitizor[]
  validators?: IFieldValidator[]
  inputType?: InputType
}

export type IStoreFormFieldsConfiguration<F extends string> = Record<
  F,
  IStoreFormFieldConfiguration
>

export interface IStoreFormSetup<TFieldEnum extends string> {
  fields: IStoreFormFieldsConfiguration<TFieldEnum>
  assertFieldExists?: boolean
  translate?: TranslateFn
}

export interface IFieldValue {
  key: FieldName
  value: FieldValue
}

export interface IFieldMultiValue {
  key: FieldName
  values: FieldValue[]
}

export interface IFieldError {
  key: FieldName
  error: FieldError
}

type OnFieldValidatorStateFn<R> = (value: Maybe<string>) => R

export type DefaultValidator = 'mandatory'

export interface IFieldValidatorDef {
  isValid: (value: Maybe<string>) => boolean
  onSuccess: OnFieldValidatorStateFn<Maybe<FieldSuccess | FieldSuccess[]>>
  onError: OnFieldValidatorStateFn<Maybe<FieldError | FieldError[]>>
}

export interface IFieldValidator {
  name: string | DefaultValidator
  handler: (translate: TranslateFn) => IFieldValidatorDef
}

export type DefaultSanitizor = 'trim'

export interface IFieldSanitizor {
  name: string | DefaultSanitizor
  sanitize: (value: Maybe<FieldValue>) => Maybe<FieldValue>
}

export interface IFieldMeta<T> {
  key: string
  value: T
}

export type InputMultiValuesOnChange = (
  fieldName: FieldName,
  value: FieldValue
) => void
