import type { EntityRbacPermission } from '@app/entities'
import type { StoreRbac } from '@app/stores'
import type {
  Maybe,
  RbacAction,
  RbacCapability,
  RbacEntityName
} from '@server/graphql/typeDefs/types'

// e.g.: edit:user:create
export type RbacPermissionKey = string

// e.g.: grant:edit:user:create
export type RbacSideEffectPermissionKey = string

export type RbacEntityId = string

export type RbacEntityIds = Maybe<RbacEntityId[]>

export enum RbacDynamicId {
  selfUserId = 'self-user-id'
}

export type RbacRoleFormVersion = 'creation' | 'edition'

export type RbacPermissionEntitiesSelector = 'all' | 'some' | 'none'

export enum RbacRoleFormFieldName {
  name = 'name',
  description = 'description'
}

export enum RbacEntityItemType {
  data = 'data',
  userPersonal = 'userPersonal',
  singleton = 'singleton',
  ui = 'ui'
}

export interface IRbacPermissionOption {
  message?: Maybe<string>
  /** If true, search the exact permission (avoid the match check) */
  strict?: boolean
}

// RbacCapability <- RbacCapabilityCheck <- RbacPermission

export interface IRbacCapability {
  isGranted: boolean
  message: Maybe<string>
  // information about rbacCapabilityCheck (missing permissions)
  logMessage?: Maybe<string>
}

export interface IRbacCapabilityCheckMeta {
  storeRbac: StoreRbac
}

export type RbacPermissions = Map<RbacPermissionKey, EntityRbacPermission>
export type RbacPermissionsWithEntityNumber = Map<
  RbacPermissionKey,
  Omit<EntityRbacPermission, 'entityIds'> & {
    entityIds: Maybe<Array<string | number>>
  }
>

export type RbacCapabilityCheck = (
  rbacPermissions: RbacPermissions,
  meta: IRbacCapabilityCheckMeta
) => IRbacCapability

export type RbacPermission = (
  rbacEntityId?: Maybe<RbacEntityId | number>,
  rbacPermissionOption?: Maybe<IRbacPermissionOption>
) => RbacCapabilityCheck

export enum RbacPermissionType {
  grant = 'grant',
  unauthorize = 'unauthorize'
}

export interface IRbacBulkPermissionAction {
  type: RbacPermissionType
  actions: RbacAction[]
  entities: 'id' | 'all'
}

export type RbacSideEffectPermissions = Map<
  RbacPermissionType,
  Array<{
    rbacEntityName: RbacEntityName
    rbacAction: RbacAction
  }>
>

export interface IMaybeGrantedEntity<T> {
  node?: Maybe<T>
  rbacCapability?: RbacCapability
}

export interface IGrantedEntity<T> {
  node: Required<T>
  rbacCapability: RbacCapability
}
