import {
  ContainerFlex,
  ContainerIcon,
  ContainerSimple
} from '@app/components-legacy/Container'
import {
  IconDownOutlined,
  IconUpOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { TypographyFieldsetTitle } from '@app/components-legacy/Typography'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { RbacEntityName } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'

export interface IListEntityItemsTitleProps {
  className?: string
  rbacEntityName: RbacEntityName
  isVisible: boolean
  onVisibilityIconClick: () => void
}

const ClickableContainerSimple = styled(ContainerSimple)`
  cursor: pointer;
`

export function ListEntityItemsTitle(props: IListEntityItemsTitleProps) {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.Accounts.Roles.Permissions']
  })

  return (
    <TypographyFieldsetTitle className={props.className}>
      <ContainerFlex
        name={ListEntityItemsTitle.name}
        alignItems="center"
        items={[
          <ClickableContainerSimple
            labelledBy={`entity-${props.rbacEntityName}`}
            containerProps={{ onClick: props.onVisibilityIconClick }}
          >
            {translate(`Entity ${props.rbacEntityName}`)}
          </ClickableContainerSimple>,

          <ContainerIcon
            labelledBy="toggleEntitiesVisibility"
            iconComponent={props.isVisible ? IconUpOutlined : IconDownOutlined}
            iconProps={{
              onClick: props.onVisibilityIconClick
            }}
          />
        ]}
        spaced
        spaceWidth="small"
      />
    </TypographyFieldsetTitle>
  )
}

export default styled(ListEntityItemsTitle)`
  /* Override h3 default margin */
  margin: 0;
`
