import type { PropertiesNullable } from '@@types/helpers'
import type { About, Maybe } from '@server/graphql/typeDefs/types'

export default class EntityAbout implements PropertiesNullable<About> {
  version: Maybe<string> = null
  hostname: Maybe<string> = null
  isSaaSDeployment: Maybe<boolean> = null

  constructor(data: Partial<About>) {
    Object.assign(this, data)
  }
}
