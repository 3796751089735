import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canCreateRoles } from '../permissions'
import { onCreateRoleLoad } from './handlers'
import RoleCreateForm from './RoleCreateForm'

interface IRolesCreateBladeProps {}

const RolesCreateBlade: React.FC<IRolesCreateBladeProps> = props => {
  const { storeManagementRbacRoles } = useStores()

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_Accounts_Roles_Create,
        parameters: {}
      }}
      onLoad={onCreateRoleLoad(storeManagementRbacRoles)}
      flags={storeManagementRbacRoles.storeFetchRbacRolesFlags.flags}
      layout={{
        name: 'default',
        content: <RoleCreateForm />,
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canCreateRoles}
    />
  )
}

export default observer(RolesCreateBlade)
