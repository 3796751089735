import { AppMenu } from '@app/components-legacy/Menu'
import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import { useStores } from '@app/hooks'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { AuthProviderName } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IAuthMenuProps {}

const AuthMenu: React.FC<IAuthMenuProps> = () => {
  const { storeLayout, storeAuthentication } = useStores()

  useStoreMenuInit<IMenuEntry<AuthProviderName>>({
    storeMenu: storeAuthentication.storeMenu,
    menuEntries: [
      {
        key: AuthProviderName.Tenable,
        label: storeLayout.getProductName(),
        labelledBy: AuthProviderName.Tenable
      },
      {
        key: AuthProviderName.Ldap,
        label: 'LDAP',
        labelledBy: AuthProviderName.Ldap
      },
      {
        key: AuthProviderName.Saml,
        label: 'SAML',
        labelledBy: AuthProviderName.Saml
      }
    ],
    defaultSelectedMenuKey: AuthProviderName.Tenable
  })

  const { isLoading, isError } =
    storeAuthentication.storeFlagsFetchProviders.flags

  if (isLoading || isError) {
    return null
  }

  const disabledEntries = [
    AuthProviderName.Tenable,
    AuthProviderName.Ldap,
    AuthProviderName.Saml
  ].filter(provider => !storeAuthentication.providers.has(provider))

  return (
    <AppMenu
      disabledEntries={disabledEntries}
      storeMenu={storeAuthentication.storeMenu}
    />
  )
}

export default observer(AuthMenu)
