import { Features } from '@alsid/common'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import type { PlaceHolderName } from '../PlaceHolder/types'
import { Portal } from '../Portal'
import { DrawerContext, useDrawerContext } from './context'
import { handleDrawerOnClose } from './handlers'

const StyledDiv = styled.div<{
  isDrawerVertical?: boolean
  featureKapteynLeftNavigationUi: boolean
}>`
  .drawerOverlay {
    position: fixed;
    z-index: ${props =>
      props.isDrawerVertical
        ? consts.zIndexDrawerVerticalContainerOverlay
        : consts.zIndexDrawerContainerOverlay};
    top: ${props => (props.isDrawerVertical ? '100px' : 0)};
    left: ${props =>
      props.isDrawerVertical
        ? props.featureKapteynLeftNavigationUi
          ? '56px'
          : props.theme.tokens['mainMenu/width/default']
        : 0};
    height: 100%;
    width: 100%;
  }

  .drawerOverlay-enter {
    opacity: 0.01;
  }

  .drawerOverlay-enter-active {
    opacity: 1;
    transition: all 300ms ease-out;
  }

  .drawerOverlay-exit {
    opacity: 1;
  }

  .drawerOverlay-exit-active {
    opacity: 0.01;
    transition: all 300ms ease-out;
  }
`

interface IDrawerOverlayProps {
  className?: string
  placeHolderName?: PlaceHolderName
  overlayBackgroundColor?: string
  isDrawerVertical?: boolean
}

const DrawerOverlay: React.FC<IDrawerOverlayProps> = props => {
  const featureKapteynLeftNavigationUi = useFeatureFlag({
    featureFlagName: Features.KAPTEYN_LEFT_NAVIGATION_UI
  })

  const contextValue = useDrawerContext(DrawerContext)
  const theme = useDSTheme()

  const isTulEnabled = useFeatureFlag({
    featureFlagName: Features.TENABLE_UNIVERSAL_LAYOUT
  })
  const isTulV6Enabled = useFeatureFlag({
    featureFlagName: Features.KAPTEYN_TUL_V6
  })

  if (!contextValue) {
    return null
  }

  const style: React.CSSProperties = {
    backgroundColor: props.overlayBackgroundColor
      ? props.overlayBackgroundColor
      : theme.tokens['drawer/color/overlay'],
    marginTop: isTulEnabled
      ? theme.tokens[`layout/tul${isTulV6Enabled ? '' : '-v5'}/height`]
      : consts.headerHeight
  }

  const renderOverlay = () => {
    return (
      <StyledDiv
        data-name="DrawerOverlay"
        className={props.className}
        isDrawerVertical={props.isDrawerVertical}
        featureKapteynLeftNavigationUi={featureKapteynLeftNavigationUi}
        onClick={handleDrawerOnClose(contextValue.storeDrawer)}
      >
        <CSSTransition
          in={contextValue.storeDrawer.isDrawerOpened}
          timeout={300}
          classNames="drawerOverlay"
          style={style}
          unmountOnExit
        >
          <div className="drawerOverlay" />
        </CSSTransition>
      </StyledDiv>
    )
  }

  if (props.placeHolderName) {
    return <Portal name={props.placeHolderName}>{renderOverlay()}</Portal>
  }

  return renderOverlay()
}

const ObservedDrawerOverlay = observer(DrawerOverlay)

export default ObservedDrawerOverlay
