import { FormWrapperCheckbox } from '@app/components-legacy/Form'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { onReadConfigurationCheckboxClick } from './handlers'

export interface IEulaContentCheckboxProps {
  className?: string
}

const EulaContentCheckbox: React.FC<IEulaContentCheckboxProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Login.EULA']
  })

  const { storeEula } = useStores()

  return (
    <FormWrapperCheckbox
      name="EulaContentCheckbox"
      labelledBy="accept"
      className={props.className}
      checkboxProps={{
        checked: storeEula.hasRead,
        onChange: onReadConfigurationCheckboxClick(storeEula)
      }}
    >
      {translate('I have read and accepted the EULA')}
    </FormWrapperCheckbox>
  )
}

const ObservedContentCheckbox = observer(EulaContentCheckbox)

export default styled(ObservedContentCheckbox)`
  padding: ${consts.paddingDefault} ${consts.paddingLarge};
`
