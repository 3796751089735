import { ContainerForm } from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { InputInfrastructures } from '@app/components-legacy/Input'
import { useAppTranslator } from '@app/hooks'
import type StoreWidgetSerie from '@app/stores/Dashboard/StoreWidgetSerie'
import * as React from 'react'
import WidgetInputUserStatus from '../WidgetInput/WidgetInputUserStatus'

export interface IWidgetInputsDataOptionsForUserProps {
  storeSerie: StoreWidgetSerie
}

const WidgetInputsDataOptionsForUser: React.FC<
  IWidgetInputsDataOptionsForUserProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Dashboard.WidgetCommonForm']
  })

  const fields: IField[] = [
    {
      name: 'status',
      label: translate('Status'),
      control: <WidgetInputUserStatus storeForm={props.storeSerie.storeForm} />
    },
    {
      name: 'directories',
      label: translate('Directories'),
      labelAlignItem: 'center',
      control: (
        <InputInfrastructures
          loadAtMount
          storeInfrastructures={props.storeSerie.storeInfrastructures}
        />
      )
    }
  ]

  return <ContainerForm fields={fields} />
}

export default WidgetInputsDataOptionsForUser
