import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import {
  onMenuEntryClick,
  onMenuEntryLinkClick
} from '@app/components-legacy/Menu/handlers'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import type StoreMenu from '@app/stores/helpers/StoreMenu'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { MenuEntryType } from '@app/stores/helpers/StoreMenu/types'
import { FontColorV2 } from '@app/styles/consts'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { ensureArray } from '@libs/ensureArray'
import { Menu as AntdMenu } from 'antd'
import type { ItemType } from 'antd/es/menu/hooks/useItems'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '../Container'
import { IconArrow } from '../Icon'
import { LabelAlt } from '../Label'
import { TypographyFieldsetTitle } from '../Typography'
import { useMenuEntriesSelection } from './hooks'

export interface INavigationMenuProps<K extends string> {
  className?: string
  style?: React.CSSProperties
  storeMenu: StoreMenu<IMenuEntry<K>>
  disabledEntries?: K[]
}

const StyledIconArrow = styled(IconArrow)`
  // override default Antd style
  margin-right: 0 !important;
`

function Menu<MenuKey extends string>(
  props: INavigationMenuProps<MenuKey>
): React.ReactElement {
  const appRouter = useAppRouter()

  const [openedMenuEntryKeys, setOpenedMenuEntryKeys] = React.useState<
    Set<React.Key>
  >(new Set())

  const { testAttributeProps } = useTestAttribute('menu')

  const theme = useDSTheme()

  useMenuEntriesSelection(props.storeMenu)

  const renderMenuItem = (menuEntry: IMenuEntry<MenuKey>) => {
    switch (menuEntry.type) {
      case MenuEntryType.category:
        return (
          <TypographyFieldsetTitle>{menuEntry.label}</TypographyFieldsetTitle>
        )

      default:
        return <LabelAlt labelledBy="test">{menuEntry.label}</LabelAlt>
    }
  }

  const renderMenu = (menuEntry: IMenuEntry<MenuKey>): ItemType => {
    if (menuEntry.menuEntries) {
      return {
        key: menuEntry.label,
        label: (
          <ContainerFlex
            name="SubMenuTitle"
            alignItems="center"
            items={[
              <span>{menuEntry.label}</span>,

              <StyledIconArrow
                labelledBy="toggleMenu"
                direction="down"
                rotation={180}
                expanded={openedMenuEntryKeys.has(menuEntry.key)}
              />
            ]}
            spaced
            spaceWidth="small"
          />
        ),
        children: menuEntry.menuEntries.map(subMenuEntry => {
          return renderMenu(subMenuEntry)
        })
      }
    }

    const testAttr = testAttributeProps('menuitem')(menuEntry.key)

    if (!menuEntry.routeDefinition) {
      return {
        key: menuEntry.key,
        disabled: ensureArray(props.disabledEntries).includes(menuEntry.key),
        onClick: onMenuEntryClick(props.storeMenu)(menuEntry),
        label: <div {...testAttr}>{renderMenuItem(menuEntry)}</div>
      }
    }

    const url = appRouter.makeRouteInfosPathname(menuEntry.routeDefinition)

    return {
      key: menuEntry.key,
      disabled: ensureArray(props.disabledEntries).includes(menuEntry.key),
      label: (
        <a
          href={url}
          onClick={onMenuEntryLinkClick(appRouter, props.storeMenu)(menuEntry)}
          {...testAttr}
        >
          {isThemeLegacy(theme) ? (
            menuEntry.label
          ) : (
            <Label variant={LabelVariant.p} color={FontColorV2.primary}>
              {menuEntry.label}
            </Label>
          )}
        </a>
      )
    }
  }

  return (
    <AntdMenu
      data-name="Menu"
      className={props.className}
      style={props.style}
      selectedKeys={ensureArray(props.storeMenu.selectedMenuKey)}
      subMenuCloseDelay={0.3}
      onOpenChange={openKeys => setOpenedMenuEntryKeys(new Set(openKeys))}
      mode="horizontal"
      items={props.storeMenu.menuEntries.map(menuEntry => {
        return renderMenu(menuEntry)
      })}
    />
  )
}

const ObservedMenu = observer(Menu)

export default styled(ObservedMenu)`
  a {
    text-decoration: none;
  }
`
