import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canCreateProfiles } from '../ProfilesPage/permissions'
import { onProfileCreateLoad } from './handlers'
import ProfileCreateForm from './ProfileCreateForm'

interface IProfileCreateBladeProps {}

const ProfileCreateBlade: React.FC<IProfileCreateBladeProps> = props => {
  const appRouter = useAppRouter()

  const { storeManagementProfiles } = useStores()

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_Accounts_Profiles_Create,
        parameters: {}
      }}
      onLoad={onProfileCreateLoad(appRouter, storeManagementProfiles)}
      flags={storeManagementProfiles.storeFlagsFetchProfiles.flags}
      layout={{
        name: 'default',
        content: <ProfileCreateForm />,
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canCreateProfiles}
    />
  )
}

export default observer(ProfileCreateBlade)
