import type { TranslateFn } from '@libs/i18n'
import { DashboardWidgetDataOptionType } from '@server/graphql/typeDefs/types'
import type { IPartialLayout, IWidgetBounds } from './types'
import { DashboardWidgetSerieInterval } from './types'

// Grid specs
export const GRID_COLS = 16
export const GRID_WIDTH = 3200
export const GRID_ROW_HEIGHT = 150

// Widget bounds offset
export const WIDGET_WIDTH_OFFSET = 60
export const WIDGET_HEIGHT_OFFSET = 30

// Widget other consts
export const WIDGET_DISPLAY_OPTIONS_LABEL_MAX_LENGTH = 50
export const WIDGET_TITLE_MAX_LENGTH = 100

export const WIDGET_DURATION_1_HOUR = 1 * 1 * 3600
export const WIDGET_DURATION_1_DAY = 1 * 24 * 3600
export const WIDGET_DURATION_7_DAYS = 7 * 24 * 3600
export const WIDGET_DURATION_15_DAYS = 15 * 24 * 3600
export const WIDGET_DURATION_1_MONTH = 30 * 24 * 3600
export const WIDGET_DURATION_6_MONTH = 6 * 30 * 24 * 3600
export const WIDGET_DURATION_1_YEAR = 365 * 24 * 3600
export const WIDGET_DEFAULT_DURATION = WIDGET_DURATION_15_DAYS
export const WIDGET_DEFAULT_INTERVAL = DashboardWidgetSerieInterval.day

export const WIDGET_DEFAULT_LAYOUT: IPartialLayout = {
  posX: 0,
  posY: 0,

  // minimum width
  minW: 2,
  // minimum height
  minH: 2,

  // maximum width
  maxW: 5,
  // maximum height
  maxH: 5
}

export const WIDGET_DEFAULT_BOUNDS: IWidgetBounds = {
  width: WIDGET_DEFAULT_LAYOUT.minW * GRID_WIDTH - WIDGET_WIDTH_OFFSET,
  height: WIDGET_DEFAULT_LAYOUT.minH * GRID_ROW_HEIGHT - WIDGET_HEIGHT_OFFSET
}

// refresh delay according to dataOption type
export const WIDGET_DELAY_MAPPING: Record<
  DashboardWidgetDataOptionType,
  number
> = {
  [DashboardWidgetDataOptionType.User]: 1 * 60 * 1000,
  [DashboardWidgetDataOptionType.Deviance]: 1 * 60 * 1000,
  [DashboardWidgetDataOptionType.Resolution]: 1 * 60 * 1000,
  [DashboardWidgetDataOptionType.Event]: 1 * 60 * 1000,
  [DashboardWidgetDataOptionType.ComplianceScore]: 1 * 60 * 1000,
  [DashboardWidgetDataOptionType.Attack]: 1 * 60 * 1000
}

export const getDurationsValues = (
  translate: TranslateFn
): Map<number, string> =>
  new Map([
    [
      WIDGET_DURATION_1_HOUR,
      translate('X hour', { interpolations: { count: 1 } })
    ],
    [
      WIDGET_DURATION_1_DAY,
      translate('X day', { interpolations: { count: 1 } })
    ],
    [
      WIDGET_DURATION_7_DAYS,
      translate('X day', { interpolations: { count: 7 } })
    ],
    [
      WIDGET_DURATION_15_DAYS,
      translate('X day', { interpolations: { count: 15 } })
    ],
    [
      WIDGET_DURATION_1_MONTH,
      translate('X month', { interpolations: { count: 1 } })
    ],
    [
      WIDGET_DURATION_6_MONTH,
      translate('X month', { interpolations: { count: 6 } })
    ],
    [
      WIDGET_DURATION_1_YEAR,
      translate('X year', { interpolations: { count: 1 } })
    ]
  ])

// Dashboard consts
export const DASHBOARD_NAME_MAX_LENGTH = 60
