import { ContainerIcon } from '@app/components-legacy/Container'
import {
  IconStarFilled,
  IconStarOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { useStores } from '@app/hooks'
import BookmarkForm from '@app/pages/TrailFlow/TrailFlowHistoryDrawer/DrawerBookmarks/BookmarkForm'
import { onBookmarkPopoverVisibleChange } from '@app/pages/TrailFlow/TrailFlowHistoryDrawer/DrawerBookmarks/handlers'
import type { Expression } from '@libs/Expression'
import { Popover } from 'antd'
import { noop } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IIconAddBookmarkProps {
  className?: string
  expression: Expression
}

const IconAddBookmark: React.FC<IIconAddBookmarkProps> = props => {
  const { storeTrailFlow } = useStores()

  const { storeSearchBookmarks } = storeTrailFlow

  if (!props.expression.expressionAsString) {
    return null
  }

  const existingBookmark = Array.from(
    storeSearchBookmarks.searchBookmarksEntries.values()
  ).find(searchBookmarkEntry => {
    return (
      searchBookmarkEntry.expression === props.expression.expressionAsString
    )
  })

  return (
    <Popover
      placement="bottom"
      content={<BookmarkForm bookmark={existingBookmark} />}
      arrowPointAtCenter
      trigger="click"
      open={storeSearchBookmarks.isPopoverVisible}
      onOpenChange={onBookmarkPopoverVisibleChange(storeSearchBookmarks)(
        existingBookmark
      )}
    >
      <div>
        <ContainerIcon
          labelledBy="addBookmark"
          // Use onClick prop to display cursor pointer style
          iconProps={{ onClick: noop }}
          iconComponent={existingBookmark ? IconStarFilled : IconStarOutlined}
        />
      </div>
    </Popover>
  )
}

export default observer(IconAddBookmark)
