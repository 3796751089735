import Input from '@app/components-legacy/Form/Wrappers/Input'
import { useAppTranslator } from '@app/hooks'
import { WIDGET_DISPLAY_OPTIONS_LABEL_MAX_LENGTH } from '@app/stores/Dashboard/consts'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { WidgetCommonFormFieldName } from '../types'

export interface IWidgetInputLabelProps {
  storeForm: StoreForm<any>
}

const WidgetInputLabel: React.FC<IWidgetInputLabelProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Dashboard.WidgetCommonForm']
  })

  return (
    <Input
      name={WidgetCommonFormFieldName.displayOptionsLabel}
      labelledBy="widgetDisplayOptionsLabel"
      inputProps={{
        value: props.storeForm.getFieldValueAsString(
          WidgetCommonFormFieldName.displayOptionsLabel
        ),
        placeholder: translate('Active users on domain XXX'),
        onChange: onInputChange(props.storeForm)(
          WidgetCommonFormFieldName.displayOptionsLabel
        ),
        autoComplete: 'off',
        maxLength: WIDGET_DISPLAY_OPTIONS_LABEL_MAX_LENGTH
      }}
    />
  )
}

export default observer(WidgetInputLabel)
