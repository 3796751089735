import type { PropertiesNullable, PublicPart } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import type {
  AuthProvider,
  AuthProviderName,
  Maybe
} from '@server/graphql/typeDefs/types'

/**
 * Describe a provider (third service) for the login process.
 */
export default class EntityAuthProvider
  extends EntityBase
  implements PropertiesNullable<AuthProvider>
{
  protocol: Maybe<AuthProviderName> = null
  type: Maybe<string> = null
  url: Maybe<string> = null

  constructor(data: Partial<PublicPart<EntityAuthProvider>>) {
    super()
    Object.assign(this, data)
  }
}
