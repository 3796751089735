import { ContainerFlex } from '@app/components-legacy/Container'
import { useAppRouter } from '@app/hooks'
import { useStores } from '@app/hooks/useStores'
import type { IIoABoardPDFRouteDefinition } from '@app/routes'
import type { ITimelineAttackPoint } from '@app/stores/IoA/StoreBoard/StoreTimeline/types'
import { consts } from '@app/styles'
import { breakpointLargeViewPort } from '@app/styles/consts'
import { formatDate } from '@libs/dates/formatDate'
import { convertTimestampToIsoString } from '@libs/dates/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { TIMELINE_HEIGHT } from './constant'
import FiltersTimelinePointAttacks from './FiltersTimelinePointAttacks'

interface IFiltersTimelinePointProps {
  className?: string
  timestamp: string
  points: Set<ITimelineAttackPoint>
  hideLabelWhenScreenTooSmall: boolean
}

const StyledFiltersTimelinePointDate = styled('span')`
  font-size: ${consts.textSizeVerySmall};
  white-space: nowrap;
  height: 20px;
`

const FiltersTimelinePoint: React.FC<IFiltersTimelinePointProps> = props => {
  const { storeIoA } = useStores()
  const { storeTimeline } = storeIoA.storeBoard

  const pointDateClassName = props.hideLabelWhenScreenTooSmall
    ? 'minWidthDisplay'
    : ''

  const appRouter = useAppRouter()

  const queryStringParameters =
    appRouter.getCurrentRouteQueryStringParameters<
      IIoABoardPDFRouteDefinition['queryStringParameters']
    >()

  return (
    <ContainerFlex
      className={props.className}
      name={FiltersTimelinePoint.name}
      direction="column"
      items={
        <ContainerFlex
          name="FiltersTimelinePointTooltipContent"
          className="FiltersTimelinePointTooltipContent"
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          items={[
            <FiltersTimelinePointAttacks
              timestamp={props.timestamp}
              attacks={props.points}
            />,

            <StyledFiltersTimelinePointDate className={pointDateClassName}>
              {formatDate(convertTimestampToIsoString(props.timestamp), {
                timezone: queryStringParameters?.timezone,
                utc: queryStringParameters?.useUtc === 'true',
                format: storeTimeline.tickDateFormat
              })}
            </StyledFiltersTimelinePointDate>
          ]}
        />
      }
    />
  )
}

const ObservedFiltersTimelinePoint = observer(FiltersTimelinePoint)

export default styled(ObservedFiltersTimelinePoint)`
  margin-right: 1px !important;

  .FiltersTimelinePointTooltipContent {
    position: relative;
    height: ${TIMELINE_HEIGHT};
    padding: 20px 0px 5px 0px;
  }
  .minWidthDisplay {
    @media only screen and (max-width: ${breakpointLargeViewPort}px) {
      width: 0px;
      max-width: 0px;
      color: ${consts.colorWhite};
    }
  }
`
