import type { StoreTopology } from '@app/stores'

/**
 * Fetch compliance score and checkers data.
 */
export const onDomainDetailsLoad =
  (storeTopology: StoreTopology) => (directoryId: number) => () => {
    const profileId =
      storeTopology.storeRoot.stores.storeAuthentication.currentProfileId

    const currentTimestamp = String(new Date().getTime())

    storeTopology.storeTopologyDomainDetails.storeIoE.fetchCheckersExposure()

    storeTopology.storeTopologyDomainDetails.fetchDomainScore({
      profileId,
      directoryIds: [directoryId],
      checkerIds: [],
      reasonIds: [],
      timestampStart: currentTimestamp,
      timestampEnd: currentTimestamp
    })
  }
