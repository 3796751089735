import { useAppRouter } from '@app/hooks/useAppRouter'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { MenuEntryType } from '@app/stores/helpers/StoreMenu/types'
import { consts } from '@app/styles'
import classnames from 'classnames'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '../Container'
import { IconRightOutlined } from '../Icon/IconAntd'
import { onNavigationEntryClick } from './handlers'
import NavigationTitle from './NavigationTitle'

interface INavigationEntryProps {
  className?: string
  menuEntry: IMenuEntry<any>
  isSelected?: boolean
}

const NavigationEntry: React.FC<INavigationEntryProps> = props => {
  const appRouter = useAppRouter()

  const { testAttributeProps } = useTestAttribute('menu')

  if (props.menuEntry.type === MenuEntryType.category) {
    return <NavigationTitle label={props.menuEntry.label} />
  }

  if (!props.menuEntry.routeDefinition) {
    return null
  }

  const classNames = classnames({
    [props.className || '']: true,
    entrySelected: props.isSelected
  })

  return (
    <a
      href={appRouter.makeRouteInfosPathname(props.menuEntry.routeDefinition)}
      onClick={onNavigationEntryClick(appRouter)(props.menuEntry)}
      className={classNames}
      {...testAttributeProps('menuitem')(props.menuEntry.key)}
    >
      <ContainerFlex
        name="NavigationEntry"
        alignItems="center"
        items={[<IconRightOutlined />, <div>{props.menuEntry.label}</div>]}
        spaced
        spaceWidth="verySmall"
      />
    </a>
  )
}

const pS = consts.paddingSmall
const pM = consts.paddingMedium

export default styled(NavigationEntry)`
  padding: ${pS} ${pM} ${pS} ${pS};
  transition: ${consts.transitionOpacity};
  color: ${consts.textDefaultColor};
  white-space: nowrap;
  border-bottom: 1px solid ${consts.colorBlueGray025};

  &:hover,
  &.entrySelected {
    color: ${consts.textDefaultColor};
    font-weight: ${consts.fontWeightBold};
    text-decoration: none;
  }

  &.entrySelected {
    background-color: ${consts.colorBlueGray030};
  }
`
