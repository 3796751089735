import { useStores } from '@app/hooks'
import type StoreWidgetSerie from '@app/stores/Dashboard/StoreWidgetSerie'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { DashboardWidgetDataOptionType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canSelectDashboardWidgetsRelatedToDeviances } from '../../permissions'
import { WidgetCommonFormFieldName } from '../types'
import WidgetInputsDataOptionsForComplianceScore from './WidgetInputsDataOptionsForComplianceScore'
import WidgetInputsDataOptionsForDeviance from './WidgetInputsDataOptionsForDeviance'
import WidgetInputsDataOptionsForUser from './WidgetInputsDataOptionsForUser'

export interface IWidgetInputsDataOptionsSwitchProps {
  storeSerie: StoreWidgetSerie
  storeForm: StoreForm<any>
}

const WidgetInputsDataOptionsSwitch: React.FC<
  IWidgetInputsDataOptionsSwitchProps
> = props => {
  const { storeRbac } = useStores()

  const dataOptionsType =
    props.storeForm.getFieldValueAsString<DashboardWidgetDataOptionType>(
      WidgetCommonFormFieldName.dataOptionsType
    )

  switch (dataOptionsType) {
    case DashboardWidgetDataOptionType.User:
      return <WidgetInputsDataOptionsForUser storeSerie={props.storeSerie} />

    case DashboardWidgetDataOptionType.Deviance:
      if (
        !storeRbac.isUserGrantedTo(canSelectDashboardWidgetsRelatedToDeviances)
      ) {
        return null
      }

      return (
        <WidgetInputsDataOptionsForDeviance storeSerie={props.storeSerie} />
      )

    case DashboardWidgetDataOptionType.ComplianceScore:
      return (
        <WidgetInputsDataOptionsForComplianceScore
          storeSerie={props.storeSerie}
        />
      )

    case DashboardWidgetDataOptionType.Resolution:
    case DashboardWidgetDataOptionType.Event:
    case DashboardWidgetDataOptionType.Attack: {
      return <div>Not yet available</div>
    }

    default:
      assertUnreachableCase(dataOptionsType)
  }
}

export default observer(WidgetInputsDataOptionsSwitch)
