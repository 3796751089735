import { Features } from '@alsid/common'
import LogoTenableExposureView from '@app/components-legacy/Logo/LogoTenableExposureView'
import { useStores } from '@app/hooks'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import styled from '@emotion/styled'
import * as React from 'react'
import { buildVariants, ContainerFlex } from 'tenable-design-system-alt'
import type { IContainerFlexProps } from 'tenable-design-system-alt/dist/src/components/tooling/ContainerFlex/types'

export interface IHeaderProps {}

interface IContainerProps extends IContainerFlexProps {
  isTulV6Enabled: boolean
}

const StyledContainerFlex = styled(ContainerFlex)<IContainerProps>(props => {
  const theme = useDSTheme()

  return buildVariants(props)
    .css({
      background: theme.tokens['layout/header/background'],
      borderBottom: `1px solid ${theme.tokens['headBar/border/color']}`
    })
    .variant('isTulV6Enabled', props.isTulV6Enabled, {
      true: {
        height: theme.tokens['layout/tul/height']
      },
      false: {
        height: theme.tokens['layout/tul-v5/height']
      }
    })
    .end()
})

const StyledLogoTenableExposureView = styled(LogoTenableExposureView)(props => {
  return buildVariants(props)
    .css({
      margin: '0 20px',
      width: '350px'
    })
    .end()
})

/**
 * Public header with only the logo for public pages that don't require
 * to be authenticated.
 */
export default function Header(props: IHeaderProps) {
  const { storeFeatureFlags } = useStores()

  const isTulV6Enabled = storeFeatureFlags.getFeatureFlagValue(
    Features.KAPTEYN_TUL_V6
  )

  return (
    <StyledContainerFlex
      name="Header"
      flexAlignItems="center"
      fullWidth
      isTulV6Enabled={isTulV6Enabled}
    >
      <StyledLogoTenableExposureView />
    </StyledContainerFlex>
  )
}
