import {
  ContainerContent,
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import { FormWrapper } from '@app/components-legacy/Form/Wrappers'
import FormWrapperButtonSubmit from '@app/components-legacy/Form/Wrappers/ButtonSubmit'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { TypographyFieldsetTitle } from '@app/components-legacy/Typography'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { UserFormFieldName } from '@app/stores/Dashboard/types'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onEditDashboardLoad, onEditDashboardValidate } from './handlers'

interface IDrawerEditDashboardFormProps {}

const DrawerEditDashboardForm: React.FC<
  IDrawerEditDashboardFormProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Dashboard.AddDashboardDrawer', 'Dashboard.Common']
  })

  const appRouter = useAppRouter()

  const { storeRoot, storeDashboards } = useStores()

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const {
    storeDashboardsManagement: {
      storeFormEditDashboard,
      storeFlagsEditDashboard
    }
  } = storeDashboards

  const instances = storeRoot.environment.config.instances
  const hasMultiInstances = instances.length > 1

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Dashboard_Grid,
    parameters: {
      instanceName: String(),
      dashboardId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  const fieldsItems = [
    <WidgetForm<UserFormFieldName>
      variant={ContainerFormVariant.drawer}
      translate={translate}
      storeForm={storeFormEditDashboard}
      displayedFields={[UserFormFieldName.name]}
    />
  ]

  if (hasMultiInstances) {
    fieldsItems.push(
      ...[
        <TypographyFieldsetTitle>
          {translate('Consolidation platform')}
        </TypographyFieldsetTitle>,

        <WidgetForm<UserFormFieldName>
          variant={ContainerFormVariant.drawer}
          translate={translate}
          storeForm={storeFormEditDashboard}
          displayedFields={[UserFormFieldName.instanceName]}
          renderFields={{
            [UserFormFieldName.instanceName]: () => (
              <div>
                {storeFormEditDashboard.getFieldValueAsString('instanceName')}
              </div>
            )
          }}
        />
      ]
    )
  }

  return (
    <FormWrapper
      name="dashboardEdition"
      onSubmit={onEditDashboardValidate(storeDashboards, appRouter)}
    >
      <ContainerContent onLoad={onEditDashboardLoad(storeDashboards)}>
        <ContainerFlex
          name="DrawerEditDashboardFields"
          direction="column"
          items={fieldsItems}
          spaced
        />

        <Portal name={PlaceHolderName.drawerFooter}>
          <ContainerFooter>
            <DrawerCancelButton
              storeDrawer={
                storeDashboards.storeDashboardsManagement
                  .storeDrawerEditDashboard
              }
            />
            <FormWrapperButtonSubmit
              loading={storeFlagsEditDashboard.flags.isLoading}
              disabled={!themeIsLegacy && !storeFormEditDashboard.isDirty()}
            >
              {themeIsLegacy ? translate('OK') : translate('Edit')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </ContainerContent>
    </FormWrapper>
  )
}

export default observer(DrawerEditDashboardForm)
