import type { MaybeUndef } from '@@types/helpers'
import { DEFAULT_RULEGROUP_PATH } from '@app/stores/helpers/StoreWizard/constants'
import type { DefinitionDateValue } from '@app/stores/helpers/StoreWizard/types'
import { containsStrings } from '@libs/containsStrings'
import Expression from '@libs/Expression/Expression'
import type { Combinator } from '@libs/Expression/types'

/**
 * Expression can't be parsed correctly by the wizard
 * - isDeviant is not handled in the middle of a complex expression
 */
export function isUnsupportedByWizard(value: string): boolean {
  const unsupportedVariants = [
    'OR isDeviant:',
    'OR isDeviant:',
    'OR (isDeviant:',
    'AND isDeviant:',
    'AND isDeviant:',
    'AND (isDeviant:'
  ]

  return containsStrings(unsupportedVariants, value)
}

/**
 * Return true if the expression or the string contains the isDeviant:true filter.
 */
export function isExpressionContainsIsDeviantTrue(
  value: string | Expression
): boolean {
  const isDeviantTrueVariants = ['isDeviant:true', 'isDeviant:"true"']

  if (value instanceof Expression) {
    if (!value.expressionAsString) {
      return false
    }

    return containsStrings(isDeviantTrueVariants, value.expressionAsString)
  }

  return containsStrings(isDeviantTrueVariants, value)
}

export function isExpressionContainsOrDeviantCombinator(
  value: string | Expression
): boolean {
  const deviantCombinatorVariants = ['isDeviant:true OR', 'isDeviant:"true" OR']

  if (value instanceof Expression) {
    if (!value.expressionAsString) {
      return false
    }

    return containsStrings(deviantCombinatorVariants, value.expressionAsString)
  }

  return containsStrings(deviantCombinatorVariants, value)
}

export function isFirstRuleGroupLevel(ruleGroupPath: number[]): boolean {
  return (
    JSON.stringify(ruleGroupPath) === JSON.stringify(DEFAULT_RULEGROUP_PATH)
  )
}

export function getButtonType(newCombinator: Combinator) {
  return (oldCombinator: Combinator): 'primary' | 'default' => {
    return oldCombinator === newCombinator ? 'primary' : 'default'
  }
}

export function getDateRuleFromExpression(
  expression: Expression
): MaybeUndef<DefinitionDateValue> {
  const dateRegex = new RegExp(/\{"date":(.*)"]}/gi)

  try {
    const matchResults =
      expression.expressionAsStringyfiedObject.match(dateRegex)

    if (!matchResults) {
      throw new Error('Value dont match a date filter')
    }

    const [dateRuleMatch] = matchResults

    const [from, to] = JSON.parse(`[${dateRuleMatch}]`)

    return [from.date, to.date]
  } catch (error) {
    return
  }
}

export function getDefinedDateRule(
  dateRule: DefinitionDateValue
): MaybeUndef<DefinitionDateValue> {
  const [from, to] = dateRule

  const hasFrom = (from[1]?.length ?? 0) > 0
  const hasTo = (to[1]?.length ?? 0) > 0
  const hasDate = hasFrom || hasTo

  if (!hasDate) {
    return undefined
  }

  return dateRule
}
