import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelDirectory, LabelSliced } from '@app/components-legacy/Label'
import {
  WidgetListPagination,
  WidgetListTable,
  WidgetListTableActionIcons,
  WidgetListTableActionIconsToggle
} from '@app/components-legacy/Widgets/WidgetList'
import type EntityAdObjectAsDeviantObject from '@app/entities/EntityAdObject/EntityAdObjectAsDeviantObject'
import type { IDataRowAdObjectAsDeviantObject } from '@app/entities/EntityAdObject/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import DeviancesList from '@app/pages/IoE/IoECheckerDetailsPage/DetailsDeviantObjectsPage/DeviancesList'
import DeviancesReasonTags from '@app/pages/IoE/IoECheckerDetailsPage/DetailsDeviantObjectsPage/DeviancesList/DeviancesReasonTags'
import { intersection } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckerDetailsPageContext } from '../../context'
import { onDeviantObjectsPageChange } from '../handlers'

interface IDeviantObjectsTableProps {}

const DeviantObjectsTable: React.FC<IDeviantObjectsTableProps> = () => {
  const parameters = React.useContext(CheckerDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'TrailFlow.Table',
      'TrailFlow.EventDetails.Attributes',
      'IoE.Details.DeviantObjects'
    ]
  })

  const { storeIoE } = useStores()

  const { storeInfrastructures, storeInputReasons } =
    storeIoE.storeIndicator.storeIndicatorDeviantObjects

  if (!parameters) {
    return null
  }

  return (
    <ContainerFlex
      name="Table"
      labelledBy="devianceObjects"
      direction="column"
      items={[
        <WidgetListTable<
          EntityAdObjectAsDeviantObject,
          IDataRowAdObjectAsDeviantObject
        >
          translate={translate}
          storeWidgetList={
            storeIoE.storeIndicator.storeIndicatorDeviantObjects
              .storeWidgetListDeviantObjects
          }
          columnWidths={{
            type: 6,
            class: 15,
            directoryId: 10
          }}
          hiddenColumnsKeys={['id']}
          cellsRenderFn={{
            class: value => {
              if (!value) {
                return <div></div>
              }
              return <LabelSliced value={value} maxLength={30} />
            },

            dn: value => {
              return <LabelSliced value={value} maxLength={60} />
            },

            directoryId: directoryId => {
              const entityDirectory =
                storeInfrastructures.directories.get(directoryId)

              if (!entityDirectory) {
                return <div></div>
              }

              return (
                <LabelDirectory
                  label={entityDirectory.getPropertyAsString('name')}
                  color={storeInfrastructures.getDirectoryColor(directoryId)}
                />
              )
            },

            reasonIds: reasonIds => {
              return (
                <DeviancesReasonTags
                  reasonIds={intersection(
                    reasonIds,
                    storeInputReasons.reasonIds
                  )}
                  storeInputReasons={storeInputReasons}
                />
              )
            }
          }}
          actionsButtonsRenderFn={(deviantObjectId, row) => {
            return (
              <WidgetListTableActionIcons
                icons={[
                  <WidgetListTableActionIconsToggle
                    storeWidgetList={
                      storeIoE.storeIndicator.storeIndicatorDeviantObjects
                        .storeWidgetListDeviantObjects
                    }
                    row={row}
                  />
                ]}
              />
            )
          }}
          toggleableRowRenderFn={(deviantObjectId, row) => (
            <DeviancesList
              checkerId={parameters.checkerId}
              checkerCodename={parameters.checkerCodename}
              adObjectId={Number(deviantObjectId)}
              adObjectRow={row}
            />
          )}
        />,

        <WidgetListPagination
          storeWidgetList={
            storeIoE.storeIndicator.storeIndicatorDeviantObjects
              .storeWidgetListDeviantObjects
          }
          onChange={onDeviantObjectsPageChange(storeIoE)(
            parameters.checkerCodename
          )}
        />
      ]}
      spaced
    />
  )
}

export default observer(DeviantObjectsTable)
