import { AssetImg } from '@app/components/Asset/AssetImg'
import { ContainerHTMLContent } from '@app/components-legacy/Container'
import LayoutPublic from '@app/components-legacy/Layout/LayoutPublic'
import ProviderTdsaDefaultTheme from '@app/components-legacy/Provider/ProviderTdsaDefaultTheme'
import { useAppRouter, useAppTranslator, useStores } from '@app/hooks'
import type { IReportsRouteDefinition } from '@app/routes'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import { ReportsFieldName } from '@app/stores/StoreReports/types'
import { buildVariants } from '@design-system/libs/buildVariants'
import { Input } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { Button, ContainerFlex, Typo } from 'tenable-design-system-alt'
import { handleDownloadOnSubmit } from './handlers'

export interface IReportsPageProps {}

const TypoCentered = styled(Typo)(props => {
  return buildVariants(props)
    .css({
      textAlign: 'center'
    })
    .end()
})

function ReportsPage(props: IReportsPageProps) {
  const translate = useAppTranslator({ namespaces: ['Reports.Download'] })

  const appRouter = useAppRouter()
  const { storeReports } = useStores()

  const parameters =
    appRouter.getCurrentRouteQueryStringParameters<
      IReportsRouteDefinition['queryStringParameters']
    >()

  // save uuid + password in a storeForm to retrieve them in the handler
  storeReports.storeForm
    .field(ReportsFieldName.uuid)
    .setValue(parameters?.uuid ?? null)

  const passwordValue =
    storeReports.storeForm.field(ReportsFieldName.accesstoken).valueAsString ??
    ''

  const disabled = !(passwordValue.length > 0)
  const submitButtonVariant = disabled ? 'disabled' : 'primary'

  return (
    <ProviderTdsaDefaultTheme>
      <LayoutPublic
        content={
          <ContainerFlex
            name="ReportsPageContent"
            flexDirection="column"
            flexGap="__deprecated_veryVeryLarge"
          >
            <ContainerFlex
              name="Reports1"
              flexDirection="column"
              flexAlignItems="center"
              flexGap="__deprecated_veryLarge"
            >
              <AssetImg imageName="reports-placeholder-lock" fileType="svg" />

              <Typo variant={'h1'}>
                {translate('Type the access key to access the report')}
              </Typo>

              <Typo>
                {translate(
                  "You can find the access key in Tenable Identity Exposure's Reporting Center"
                )}
              </Typo>
            </ContainerFlex>

            <form onSubmit={handleDownloadOnSubmit(storeReports)}>
              <ContainerFlex
                name="Reports2"
                flexDirection="column"
                flexAlignItems="center"
                flexGap="__deprecated_veryLarge"
              >
                <Input.Password
                  placeholder={translate('Password')}
                  value={passwordValue}
                  onChange={onInputChange(storeReports.storeForm)(
                    ReportsFieldName.accesstoken
                  )}
                  style={{
                    width: '300px'
                  }}
                />

                <Button
                  variant={submitButtonVariant}
                  type="submit"
                  innerAttributes={{ disabled }}
                >
                  {translate('Download')}
                </Button>
              </ContainerFlex>
            </form>

            <TypoCentered>
              <ContainerHTMLContent>
                {translate(
                  'Tenable is not liable for the secret management associated to this report',
                  {
                    transformMarkdown: true
                  }
                )}
              </ContainerHTMLContent>
            </TypoCentered>
          </ContainerFlex>
        }
      />
    </ProviderTdsaDefaultTheme>
  )
}

export default observer(ReportsPage)
