import {
  ContainerContent,
  ContainerFooter
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { LabelAlt } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useAppRouter, useAppTranslator } from '@app/hooks'
import AuthPlaceHolders from '@app/pages/Auth/AuthPlaceHolders'
import AuthTenableLogo from '@app/pages/Auth/AuthTenableLogo'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import AuthContainerPadded from '../Common/AuthContainerPadded'

interface ILoginPendingActivationPageProps {}

const LoginPendingActivationPage: React.FC<
  ILoginPendingActivationPageProps
> = () => {
  const translate = useAppTranslator({ namespaces: ['Login.Labels'] })

  const appRouter = useAppRouter()

  return (
    <AuthPlaceHolders
      layout={{
        header: <AuthTenableLogo />,
        content: (
          <AuthContainerPadded>
            <ContainerContent spinner={<SpinnerInline />}>
              <LabelAlt variant={LabelAltVariant.Bolded}>
                {translate(
                  'Tenable.ad created your account but it requires an administrator to activate it'
                )}
              </LabelAlt>
            </ContainerContent>
          </AuthContainerPadded>
        ),
        footer: (
          <ContainerFooter>
            <FormWrapperButton
              labelledBy="submit"
              buttonProps={{
                type: 'primary',
                href: appRouter.makeRouteInfosPathname({
                  routeName: AppRouteName.MiddlewareAuth_Logout,
                  parameters: {}
                })
              }}
            >
              {translate('Sign in with another account')}
            </FormWrapperButton>
          </ContainerFooter>
        )
      }}
    />
  )
}

export default observer(LoginPendingActivationPage)
