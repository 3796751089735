import type { PropertiesNullable } from '@@types/helpers'
import type { Maybe, RelayLinkingKey } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityRelayLinkingKey
  extends EntityBase
  implements PropertiesNullable<RelayLinkingKey>
{
  linkingKey: Maybe<string> = null

  constructor(data: Partial<RelayLinkingKey>) {
    super()
    Object.assign(this, data)
  }
}
