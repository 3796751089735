import { LabelAlt, LabelDate } from '@app/components-legacy/Label'
import { useAppTranslator, useStores } from '@app/hooks'
import { DateFormat } from '@libs/dates/formatDate'
import { filterFalsies } from '@libs/filterFalsies'
import { isDefined } from '@libs/isDefined'
import { formatNumber } from '@libs/numbers/helpers'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type { Maybe } from '@server/graphql/typeDefs/types'
import { LicenseFeature, LicenseType } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerForm } from '../Container'
import type { IField } from '../Container/ContainerForm/types'
import { ContainerFormVariant } from '../Container/ContainerForm/types'
import { LabelAltVariant } from './LabelAlt'

export interface ILabelLicenseProps {
  className?: string
  customerName: string
  type: LicenseType
  features: LicenseFeature[]
  currentActiveUserCount: Maybe<number>
  maxActiveUserCount: number
  expirationDateUTC: string
  isExpired: boolean
  activationCode: Maybe<string>
  containerUuid: Maybe<string>
  productAssociation: Maybe<string>
}

const DivLicenseFeatures = styled.div`
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

const LabelLicense: React.FC<ILabelLicenseProps> = props => {
  const { storeLayout } = useStores()

  const translate = useAppTranslator({
    namespaces: ['Components.LabelLicense', 'Management.System.About.License']
  })

  const validProductAssociations = new Map<string, string>([
    ['t.ad', storeLayout.getProductName()],
    ['t.ep', 'Tenable One'],
    ['t.one', 'Tenable One']
  ])

  const productAssociationToDisplay = isDefined(props.productAssociation)
    ? validProductAssociations.get(props.productAssociation.toLowerCase())
    : null

  const getLicenseLabelTranslation = (): string => {
    switch (props.type) {
      case LicenseType.Poc:
        return translate('license-poc')

      case LicenseType.Prod:
        return translate('license-prod')

      case LicenseType.Internal:
        return translate('license-internal')

      case LicenseType.Nfr:
        return translate('license-nfr')

      default:
        assertUnreachableCase(props.type)
    }
  }

  const getLicenseFeaturesTranslation = (): string[] => {
    return props.features.map(feature => {
      switch (feature) {
        case LicenseFeature.Ioe:
          return translate('license-feature-ioe')

        case LicenseFeature.Ioa:
          return translate('license-feature-ioa')

        case LicenseFeature.IoaPreview:
          return translate('license-feature-ioaPreview')

        default:
          assertUnreachableCase(feature)
      }
    })
  }

  const fields = filterFalsies<IField>([
    {
      name: 'customerName',
      label: translate('Customer name'),
      control: <div>{props.customerName}</div>
    },
    {
      name: 'licenseType',
      label: translate('License type'),
      control: <div>{getLicenseLabelTranslation()}</div>
    },
    {
      name: 'licenseFeatures',
      label: translate('License features'),
      control: (
        <DivLicenseFeatures>
          <ul>
            {getLicenseFeaturesTranslation().map(featureStr => {
              return <li key={featureStr}>- {featureStr}</li>
            })}
          </ul>
        </DivLicenseFeatures>
      )
    },
    {
      name: 'currentActiveUsers',
      label: translate('Current active users'),
      control: (function _() {
        if (!isDefined(props.currentActiveUserCount)) {
          return (
            <LabelAlt variant={LabelAltVariant.Warning}>
              {translate('Not available yet')}
            </LabelAlt>
          )
        }

        return (
          <LabelAlt
            variant={
              props.currentActiveUserCount > props.maxActiveUserCount
                ? LabelAltVariant.Error
                : LabelAltVariant.Normal
            }
          >
            <div>{formatNumber(props.currentActiveUserCount)}</div>
          </LabelAlt>
        )
      })()
    },
    {
      name: 'maximumActiveUsers',
      label: translate('Maximum active users'),
      control: <div>{formatNumber(props.maxActiveUserCount)}</div>
    },
    {
      name: 'expirationDate',
      label: translate('Expiration date'),
      control: (
        <LabelAlt
          variant={
            props.isExpired ? LabelAltVariant.Error : LabelAltVariant.Normal
          }
        >
          <LabelDate
            date={props.expirationDateUTC}
            formatDateOptions={{ format: DateFormat.short }}
          />
        </LabelAlt>
      )
    },
    isDefined(productAssociationToDisplay) && {
      name: 'productAssociation',
      label: translate('Product Association'),
      control: <div>{productAssociationToDisplay}</div>
    },
    isDefined(props.activationCode) && {
      name: 'activationCode',
      label: translate('Activation code'),
      control: <div>{props.activationCode}</div>
    },
    isDefined(props.containerUuid) && {
      name: 'containerUuid',
      label: translate('ContainerId'),
      control: <div>{props.containerUuid.toLowerCase()}</div>
    }
  ])

  return <ContainerForm variant={ContainerFormVariant.drawer} fields={fields} />
}

export default LabelLicense
