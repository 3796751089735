import EntityEula from '@app/entities/EntityEula'
import { AppRouteName } from '@app/routes'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import type { MutationUpdateUserEula } from '@server/graphql/mutations/user'
import { mutationUpdateUserEula } from '@server/graphql/mutations/user'
import type { Eula, Maybe } from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '.'
import StoreFlags from './helpers/StoreFlags'
import StoreBase from './StoreBase'
import type { IStoreOptions } from './types'

export default class StoreEula extends StoreBase {
  public storeFlags = new StoreFlags(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Buttons', 'Login.EULA']
  })

  /* Observable */

  private $eula = observable.box<Maybe<EntityEula>>(null)
  private $hasRead = observable.box<boolean>(false)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch Eula info.
   */
  fetchEula(): Promise<void> {
    this.storeFlags.loading()

    const url = this.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareEula_License,
      parameters: {}
    })

    return this.storeRoot.fetchClient
      .get(url)
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Unable to get the User Agreement License')
        }

        return response.json() as Promise<Eula>
      })
      .then(data => {
        this.storeFlags.success()

        if (data) {
          this.setEula(data)
        }

        this.setReadStatus(false)
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlags))
  }

  /**
   * Clear Eula data (ie: delete the license).
   */
  clearEula(): Promise<void> {
    this.storeFlags.loading()

    const url = this.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareEula_License,
      parameters: {}
    })

    return this.storeRoot.fetchClient
      .delete(url, {})
      .then(response => {
        if (response.status !== 204) {
          throw new Error('Unable to delete the license')
        }
      })
      .then(() => {
        this.storeFlags.success()
        this.$eula.set(null)
        this.setReadStatus(false)
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlags))
  }

  /**
   * Approve Eula by updating the user eulaVersion property.
   */
  approveEula(): Promise<void> {
    this.storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        const whoAmI = this.storeRoot.stores.storeAuthentication.whoAmI

        if (!whoAmI) {
          throw new Error('User is not defined')
        }

        const eulaVersion = this.eula && this.eula.version

        if (!eulaVersion) {
          throw new Error('Eula version is unknown')
        }

        const args: MutationUpdateUserEula['args'] = {
          userEula: {
            eulaVersion
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationUpdateUserEula>(mutationUpdateUserEula, args)
      })
      .then(({ updateUserEula }) => {
        this.storeRoot.stores.storeAuthentication.updateEulaVersion(
          updateUserEula.eulaVersion
        )

        this.storeRoot.stores.storeMessages.success(
          this.translate('The End User License Agreement is accepted'),
          {
            labelledBy: 'licenseAgreementApproved'
          }
        )

        this.storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlags))
  }

  /* Actions */

  @action
  setEula(eula: Eula): this {
    const eulaEntity = new EntityEula(eula)
    this.$eula.set(eulaEntity)

    return this
  }

  @action
  setReadStatus(read: boolean): this {
    this.$hasRead.set(read)

    return this
  }

  /* Computed */

  @computed
  get eula(): Maybe<EntityEula> {
    return this.$eula.get()
  }

  @computed
  get hasRead(): boolean {
    return Boolean(this.eula && this.$hasRead.get())
  }

  /**
   * Return true if the current user has approved the user agreement license.
   * Note: Use `isDefined` to distinguish undefined/null and 0 values.
   */
  @computed
  get hasApprovedEula(): boolean {
    const whoAmI = this.storeRoot.stores.storeAuthentication.whoAmI

    if (!isDefined(whoAmI)) {
      return false
    }

    if (!isDefined(whoAmI.eulaVersion)) {
      return false
    }

    if (whoAmI.eulaVersion === -1) {
      return true
    }

    if (!isDefined(this.eula)) {
      return false
    }

    if (!isDefined(this.eula.version)) {
      return false
    }

    return whoAmI.eulaVersion >= this.eula.version
  }
}
