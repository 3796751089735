import { buildVariants } from '@design-system/libs/buildVariants'
import * as React from 'react'
import styled from 'styled-components'
import {
  Provider,
  buildTadTheme,
  createConfiguration
} from 'tenable-design-system-alt'

export interface IProviderTdsaDefaultThemeProps {
  children: React.ReactNode
}

/**
 * Because TDSA is added an extra div for now to apply default style, we have
 * to tweak them a bit to apply 100% height.
 *
 * In next version, TDSA will not provide such wrapping, only body styles applied
 * or not according to `options.applyResetCss` value.
 *
 * In short, FIXME.
 */
const Div = styled.div(props => {
  return buildVariants(props)
    .css({
      height: '100%',

      '> div': {
        height: '100%'
      }
    })
    .end()
})

export default function ProviderTdsaDefaultTheme(
  props: IProviderTdsaDefaultThemeProps
) {
  const paladinConfiguration = createConfiguration({
    themeMode: 'light',
    language: 'en',
    tdsaTheme: buildTadTheme()
  })

  return (
    <Div>
      <Provider configuration={paladinConfiguration}>{props.children}</Provider>
    </Div>
  )
}
