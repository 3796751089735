import type { PropertiesNullable } from '@@types/helpers'
import type { IEntityListable } from '@app/entities/types'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import type { Expression } from '@libs/Expression'
import type { Maybe, SearchHistoryEntry } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export interface IDataRowSearchHistoryEntry {
  id: number
  label?: string
}

export default class EntitySearchHistoryEntry
  extends EntityBase
  implements
    PropertiesNullable<SearchHistoryEntry>,
    IEntityListable<IDataRowSearchHistoryEntry>
{
  id: Maybe<number> = null
  expression: Maybe<string> = null
  expressionObject: Maybe<Expression> = null
  date: Maybe<string> = null

  constructor(data: Partial<SearchHistoryEntry>) {
    super()
    Object.assign(this, data)
  }

  /* Implements IEntityListable */

  getColumns(): Array<IWidgetListColumns<IDataRowSearchHistoryEntry>> {
    // Not used as a list for now
    return []
  }

  asDataRow(): IDataRowSearchHistoryEntry {
    return {
      id: this.getPropertyAsNumber('id')
    }
  }
}
