import type { Maybe } from '@@types/helpers'
import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import { ButtonCopyToClipboard } from '@app/components-legacy/Button'
import {
  ContainerContent,
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import {
  Drawer,
  DrawerCancelButton,
  DrawerContext,
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { LabelAltError, LabelMissingData } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreDrawer from '@app/stores/helpers/StoreDrawer'
import { grant } from '@libs/rbac/permissions'
import { sddlParseAny } from '@libs/sddl/sddl'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import SDDLDetail from './SDDLDetail'

export interface IDrawerSDDLDescriptionProps {
  storeDrawer: StoreDrawer<{ sddl: string }>
}

const DrawerSDDLDescription: React.FC<IDrawerSDDLDescriptionProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'TrailFlow.EventDetails.SDDLParser']
  })

  const renderSDDLToggles = (): Maybe<React.ReactNode> => {
    const sddl = props.storeDrawer.getDataValue('sddl')

    if (!sddl) {
      return null
    }

    const sddlData = props.storeDrawer.getDataValue('sddl')
    const sddlTree = sddlData && sddlParseAny(sddlData)

    if (!sddlTree) {
      return <LabelMissingData defaultMessageType="verbose" />
    }

    return <SDDLDetail sddlTree={sddlTree} />
  }

  return (
    <DrawerContext.Provider value={{ storeDrawer: props.storeDrawer }}>
      <Drawer>
        <DrawerHorizontalContainer variant="large">
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: <DrawerTitle>{translate('SDDL description')}</DrawerTitle>,
              content: (
                <ContainerFlex
                  name="DrawerSDDLDescription"
                  direction="column"
                  items={[
                    <ContainerContent>
                      {renderSDDLToggles() || (
                        <LabelAltError labelledBy="invalidSDDL">
                          SDDL is not defined.
                        </LabelAltError>
                      )}
                    </ContainerContent>
                  ]}
                  spaced
                />
              ),
              footer: (
                <ContainerFooter>
                  <DrawerCancelButton />

                  <ButtonCopyToClipboard
                    variant="large"
                    textToCopy={props.storeDrawer.getDataValue('sddl')}
                    buttonProps={{
                      type: 'primary'
                    }}
                  />
                </ContainerFooter>
              )
            }}
            rbacCapabilityCheck={grant()}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerSDDLDescription)
