import { getLogger } from '@libs/logger'
import type { Maybe } from '@productive-codebases/toolbox'
import type { Language } from '@server/graphql/typeDefs/types'
import { get } from 'lodash'
import type { IGraphQLClientHeaders } from './graphQL/types'

export interface ISubmitFormParameters<TParameters> {
  parameters: TParameters
  language: Language
  [IGraphQLClientHeaders.csrfToken]: Maybe<string>
}

const bodyParametersName = 'parameters'

/**
 * Create a form at runtime and submit it.
 * It is used to create a form query in a new window (for exports for example).
 */
export function submitForm(
  url: string,
  parameters: ISubmitFormParameters<any>,
  method: 'GET' | 'POST'
) {
  const form = document.createElement('form')

  form.setAttribute('method', method)
  form.setAttribute('action', url)
  form.setAttribute('target', 'blank')

  const parametersField = document.createElement('input')
  parametersField.setAttribute('type', 'hidden')
  parametersField.setAttribute('name', bodyParametersName)
  parametersField.setAttribute('value', JSON.stringify(parameters))
  form.appendChild(parametersField)

  document.body.appendChild(form)

  form.submit()

  document.body.removeChild(form)
}

/**
 * Parse submitForm parameters.
 */
export function parseSubmitFormParameters<P>(
  bodyParameters: object
): ISubmitFormParameters<P> {
  try {
    const postFormParameters = JSON.parse(
      get(bodyParameters, bodyParametersName, '{}')
    )
    return postFormParameters as ISubmitFormParameters<P>
  } catch (err) {
    const logger = getLogger()
    logger.error('Unable to parse the postForm parameters')

    throw err
  }
}
