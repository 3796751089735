import { Drawer, DrawerContext } from '@app/components-legacy/Drawer'
import { useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import EditBookmark from './EditBookmark'

interface IDrawerEditBookmark {}

const DrawerEditBookmark: React.FC<IDrawerEditBookmark> = () => {
  const { storeTrailFlow } = useStores()

  return (
    <DrawerContext.Provider
      value={{
        storeDrawer: storeTrailFlow.storeSearchBookmarks.storeDrawerEditBookmark
      }}
    >
      <Drawer>
        <EditBookmark />
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerEditBookmark)
