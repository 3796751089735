import { Features } from '@alsid/common'
import type { StoreAuthentication } from '@app/stores'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  accessByFeatureFlag,
  readActivityLogs,
  readInfrastructures,
  deny
} from '@libs/rbac/permissions'

const haveNeededAccessForActivityLogs = rbacCapabilityCheckAllOf(
  accessByFeatureFlag(Features.TRACEABILITY_DASHBOARD)(),
  readInfrastructures()
)

export const canAccessToActivityLogs = rbacCapabilityCheckAllOf(
  haveNeededAccessForActivityLogs,
  readActivityLogs()
)

export const canDisplayActivityLogs = rbacCapabilityCheckAllOf(
  haveNeededAccessForActivityLogs,
  readActivityLogs(null, {
    strict: true
  })
)

export const canAccessToUserActivityLogs = (
  storeAuthentication: StoreAuthentication
) => {
  if (!storeAuthentication.whoAmI?.id) {
    return deny()
  }

  return rbacCapabilityCheckAllOf(
    haveNeededAccessForActivityLogs,
    readActivityLogs(storeAuthentication.whoAmI.id, {
      strict: true
    })
  )
}
