import * as React from 'react'

interface IIconAlertProps {}

const IconAlert: React.FC<IIconAlertProps> = props => {
  return (
    <svg width={24} height={24}>
      <defs>
        <path
          d="M12.235 14.118h-8.47V8A4.218 4.218 0 0 1 8 3.765 4.218 4.218 0 0 1 12.235 8v6.118zm1.883-.942V8c0-2.89-2.014-5.308-4.706-5.948v-.64a1.412 1.412 0 1 0-2.824 0v.64A6.115 6.115 0 0 0 1.882 8v5.176L0 15.06V16h16v-.941l-1.882-1.883zM8 18.824c1.04 0 1.882-.843 1.882-1.883H6.118c0 1.04.842 1.883 1.882 1.883z"
          id="icon-alert-prefix__a"
        />
      </defs>
      <g transform="translate(4 2)" fill="none" fillRule="evenodd">
        <mask id="icon-alert-prefix__b" fill="#fff">
          <use xlinkHref="#icon-alert-prefix__a" />
        </mask>
        <use fill="#fff" fillRule="nonzero" xlinkHref="#icon-alert-prefix__a" />
        <g mask="url(#icon-alert-prefix__b)" fill="#fff">
          <path d="M-4-2h24v24H-4z" />
        </g>
      </g>
    </svg>
  )
}

export default IconAlert
