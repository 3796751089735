import type { Maybe } from '@@types/helpers'

/**
 * Return the filename and extension of a path like:
 * \\domain.com\sysvol\path\to\file.com
 *
 * If not found, return null and consider that the path targets a folder like:
 * \\domain.com\sysvol\path\to\folder
 */
export function getFile(str: string): Maybe<string> {
  const matches = str.match(/[^\\/]+\.[^\\/]+$/)

  if (!matches) {
    return null
  }

  const [file] = Array.from(matches)

  return file || null
}
