import type { PropertiesNullable } from '@@types/helpers'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import { ensureArray } from '@libs/ensureArray'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type {
  DataTimeframe,
  Maybe,
  Report
} from '@server/graphql/typeDefs/types'
import { ReportType } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'
import type { IEntityListable, IEntityOmitKeysParameter } from './types'

export interface IDataRowReport {
  id: number
  name: string
  type: ReportType
  indicator: { type: ReportType; ids: number[] }
  directoryIds: number[]
  profileId: number
  recipientEmails: string[]
  format: string
  dataTimeframe: DataTimeframe
  recurrence: string
  timeZone: string
}

export default class EntityReport
  extends EntityBase
  implements PropertiesNullable<Report>, IEntityListable<IDataRowReport>
{
  id: Maybe<number> = null
  name: Maybe<string> = null
  type: Maybe<ReportType> = null
  checkerIds: Maybe<number[]> = null
  attackTypeIds: Maybe<number[]> = null
  directoryIds: Maybe<number[]> = null
  profileId: Maybe<number> = null
  recipientEmails: Maybe<string[]> = null
  format: Maybe<string> = null
  dataTimeframe: Maybe<DataTimeframe> = null
  recurrence: Maybe<string> = null
  timeZone: Maybe<string> = null

  constructor(data: Partial<Report>) {
    super()
    Object.assign(this, data)
  }

  /* Implements IEntityListable */

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowReport> = []
  ): Array<IWidgetListColumns<IDataRowReport>> {
    const columns: Array<IWidgetListColumns<IDataRowReport>> = [
      { label: 'ID', key: 'id' },
      { label: 'Report name', key: 'name' },
      { label: 'Report type', key: 'type' },
      { label: 'Indicators', key: 'indicator' },
      { label: 'Domains', key: 'directoryIds' },
      { label: 'Profile', key: 'profileId' },
      { label: 'Data timeframe', key: 'dataTimeframe' },
      { label: 'Recurrence', key: 'recurrence' },
      { label: 'Time zone', key: 'timeZone' },
      { label: 'Recipients', key: 'recipientEmails' }
    ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  asDataRow(): IDataRowReport {
    const type = this.getPropertyAsT<ReportType>('type')

    const getIndicatorIds = (): number[] => {
      switch (type) {
        case ReportType.Deviances: {
          return ensureArray(this.checkerIds)
        }

        case ReportType.Attacks: {
          return ensureArray(this.attackTypeIds)
        }

        default:
          assertUnreachableCase(type)
      }
    }

    return {
      id: this.getPropertyAsNumber('id'),
      name: this.getPropertyAsString('name'),
      type,
      indicator: {
        type,
        ids: getIndicatorIds()
      },
      directoryIds: ensureArray(this.directoryIds),
      profileId: this.getPropertyAsNumber('profileId'),
      format: this.getPropertyAsString('format'),
      dataTimeframe: this.getPropertyAsT<DataTimeframe>('dataTimeframe'),
      recurrence: this.getPropertyAsString('recurrence'),
      timeZone: this.getPropertyAsString('timeZone'),
      recipientEmails: ensureArray(this.recipientEmails)
    }
  }
}
