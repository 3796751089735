import type { ConfigurationType } from '@app/entities/EntityUserLog'
import { isArray } from 'lodash'
import React from 'react'
import type { IUserLogFieldProps } from './Components/UserLogField'
import UserLogField from './Components/UserLogField'

export const renderMultipleValues = (
  colorMode: IUserLogFieldProps['colorMode'],
  field: string,
  value: string | string[] | boolean,
  fieldType?: ConfigurationType,
  labelledBy?: string
): JSX.Element[] => {
  if (isArray(value)) {
    return value.map(item => (
      <UserLogField
        colorMode={colorMode}
        field={String(field)}
        value={item}
        fieldType={fieldType}
        labelledBy={labelledBy}
      />
    ))
  }

  return [
    <UserLogField
      colorMode={colorMode}
      field={String(field)}
      value={value}
      fieldType={fieldType}
      labelledBy={labelledBy}
    />
  ]
}
