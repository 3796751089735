import type { Maybe } from '@@types/helpers'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { IFormatDateOptions } from '@libs/dates/formatDate'
import { formatDate } from '@libs/dates/formatDate'
import { Tooltip } from 'antd'
import { upperFirst } from 'lodash'
import * as React from 'react'

export interface ITooltipDateProps {
  children: React.ReactElement
  date: Maybe<Date | string>
  formatDateOptions?: IFormatDateOptions
}

const TooltipDate: React.FC<ITooltipDateProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Date'] })

  if (!props.date) {
    return null
  }

  const getDate = (): string => {
    if (!props.date) {
      return ''
    }

    return upperFirst(
      formatDate(props.date, {
        ...props.formatDateOptions,
        utc: true
      })
    )
  }

  return (
    <Tooltip
      title={translate('UTC Date', {
        interpolations: {
          utcDate: getDate()
        }
      })}
    >
      {props.children}
    </Tooltip>
  )
}

export default TooltipDate
