import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import { IconSVG } from '@app/components-legacy/Icon'
import { useAppTranslator } from '@app/hooks'
import { compareStringsAlphabetically } from '@libs/compareStringsAlphabetically'
import type { AttackPathRelationType } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'

export interface IRelationsTooltipRelationsProps {
  className?: string
  relationType: AttackPathRelationType
  controlRightRelations?: Maybe<AttackPathRelationType[]>
}

const DotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
`

const Dot = styled.span`
  height: 5px;
  width: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
`

const RelationsTooltipRelations: React.FC<
  IRelationsTooltipRelationsProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.Chart']
  })

  return (
    <ContainerFlex
      name="RelationsTooltipRelation"
      spaceWidth="veryVerySmall"
      direction="column"
      items={[
        <ContainerFlex
          name="RelationsTooltipRelationTitle"
          spaceWidth="small"
          items={[
            <IconSVG
              iconName={`attackpath/${props.relationType}-hover`}
              size={20}
            />,
            <b>
              {props.controlRightRelations
                ? translate(props.relationType, {
                    interpolations: {
                      count: props.controlRightRelations.length
                    }
                  })
                : translate(props.relationType)}
            </b>
          ]}
          spaced
        />,
        ...(props.controlRightRelations
          ?.sort(compareStringsAlphabetically)
          .map(controlRightRelation => (
            <ContainerFlex
              name="RelationsTooltipRelationControlRight"
              spaceWidth="small"
              items={[
                <DotContainer>
                  <Dot />
                </DotContainer>,
                <span>{translate(controlRightRelation)}</span>
              ]}
              spaced
            />
          )) || [])
      ]}
      spaced
    />
  )
}

export default RelationsTooltipRelations
