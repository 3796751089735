export enum InputHealthChecksMode {
  alerts = 'alerts',
  domainStatus = 'domainStatus',
  platformStatus = 'platformStatus'
}

export enum HealthCheckCodenameKey {
  domainStatus = 'HC-DOMAIN',
  platformStatus = 'HC-PLATFORM'
}
