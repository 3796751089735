import { Features } from '@alsid/common'
import { ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { useStores } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface IWidgetUserIconProps {
  className?: string
}

const LabelUserInitials = styled(Label)<{ isTulEnabled: boolean }>`
  font-family: 'Barlow';
  font-size: ${props => (props.isTulEnabled ? '13px' : '18px')};
  font-weight: ${props => (props.isTulEnabled ? '500' : '400')};
  font-stretch: ${props => (props.isTulEnabled ? 'normal' : 'condensed')};
  line-height: 19px;
`

const CustomContainer = styled(ContainerFlex)<{ isTulEnabled: boolean }>`
  height: ${props => (props.isTulEnabled ? '40px' : '32px')};
  width: ${props => (props.isTulEnabled ? '40px' : '32px')};
  background-color: ${consts.CTAColorV2.primary};
  border-radius: 100%;
  text-transform: uppercase;
  user-select: none;
`

const WidgetUserIcon: React.FC<IWidgetUserIconProps> = props => {
  const { storeAuthentication, storeRoot } = useStores()

  const isTulEnabled = useFeatureFlag({
    featureFlagName: Features.TENABLE_UNIVERSAL_LAYOUT
  })

  const isTulV6Enabled = storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
    Features.KAPTEYN_TUL_V6
  )

  let userInitials = 'U' // for Unknown user

  if (storeAuthentication.whoAmI) {
    userInitials = storeAuthentication.whoAmI.surname
      ? `${storeAuthentication.whoAmI
          .getPropertyAsString('name')
          .substring(0, 1)}${storeAuthentication.whoAmI
          .getPropertyAsString('surname')
          .substring(0, 1)}`
      : `${storeAuthentication.whoAmI
          .getPropertyAsString('name')
          .substring(0, 2)}`
  }

  if (isTulV6Enabled) {
    return (
      <tenable-avatar
        id="profile-menu-button"
        slot="anchor"
        part="trigger"
        aria-label="Avatar"
        aria-haspopup="true"
      >
        {userInitials}
      </tenable-avatar>
    )
  }

  return (
    <CustomContainer
      name="WidgetUserIcon"
      className={props.className}
      alignItems="center"
      justifyContent="center"
      isTulEnabled={isTulEnabled}
      items={[
        <LabelUserInitials
          color={consts.colorWhite}
          isTulEnabled={isTulEnabled}
        >
          {userInitials}
        </LabelUserInitials>
      ]}
    />
  )
}

export default WidgetUserIcon
