import { AppRouteName } from '@app/routes'
import type { StoreManagementSyslogs } from '@app/stores'
import { getCriticityString, getCriticityValue } from '@libs/criticity'
import { If } from '@libs/fp-helpers/If'
import type { InputEditSyslog } from '@server/graphql/typeDefs/types'
import { SyslogInputType } from '@server/graphql/typeDefs/types'
import { canAccessToRelays } from '../../../Relays/permissions'
import { canSelectTriggerOnDeviances } from '../../permissions'
import { SyslogFormFieldName } from '../types'

export const onSyslogEditLoad =
  (storeManagementSyslogs: StoreManagementSyslogs) =>
  (syslogId: number) =>
  () => {
    const { storeRbac, storeManagementRelays } =
      storeManagementSyslogs.storeRoot.stores

    const {
      storeInfrastructures,
      storeForm,
      storeInputExpression,
      storeInputCheckersAttacks,
      storeInputCheckersExposure,
      storeInputHealthChecks
    } = storeManagementSyslogs

    return Promise.all([
      If(!storeManagementSyslogs.syslogs.size).fetch(() =>
        storeManagementSyslogs.fetchSyslogs()
      ),
      // fetch everything before being able to select default values in the form
      storeInfrastructures.fetchInfrastructures(),
      storeInputCheckersAttacks.fetchAttackCheckers(),
      storeInputCheckersExposure.fetchExposureCheckers(),
      storeInputHealthChecks.fetchHealthChecksTemplatesNames()
    ])
      .then(() => {
        if (storeRbac.isUserGrantedTo(canAccessToRelays)) {
          storeManagementRelays.fetchRelays()
        }
      })
      .then(() => {
        const syslogEntity = storeManagementSyslogs.syslogs.get(syslogId)
        const syslogRow = syslogEntity && syslogEntity.asDataRow()

        if (!syslogRow) {
          return
        }

        storeInputExpression
          .clearEntry()
          .setEntry(syslogRow.filterExpression.expressionAsString)
          .validateEntry()

        // retrieve the inputType according to the type of license
        const inputType =
          // if "on deviances" selected and the user is not allowed to see deviances, select "on attacks"
          syslogRow.inputType === SyslogInputType.Deviances &&
          !storeRbac.isUserGrantedTo(canSelectTriggerOnDeviances)
            ? SyslogInputType.Attacks
            : // otherwise, keep the selection
              syslogRow.inputType

        storeForm
          .hardReset()
          .setDefaultFieldsValuesFromObject({
            [SyslogFormFieldName.ip]: syslogRow.ip,
            [SyslogFormFieldName.port]: syslogRow.port,
            [SyslogFormFieldName.protocol]: syslogRow.protocol,
            [SyslogFormFieldName.tls]: syslogRow.tls,
            [SyslogFormFieldName.description]: syslogRow.description,
            [SyslogFormFieldName.shouldNotifyOnInitialFullSecurityCheck]:
              syslogRow.shouldNotifyOnInitialFullSecurityCheck,
            // convert the list of profiles to a string
            [SyslogFormFieldName.profiles]: syslogRow.profiles.join(','),
            [SyslogFormFieldName.inputType]: inputType,
            // get a valid CriticityThreshold value from a number between 0 and 100
            [SyslogFormFieldName.criticityThreshold]: getCriticityValue(
              getCriticityString(syslogRow.criticityThreshold)
            ),
            [SyslogFormFieldName.checkers]: syslogRow.checkers,
            [SyslogFormFieldName.attackTypes]: syslogRow.attackTypes,
            [SyslogFormFieldName.filterExpression]: syslogRow.filterExpression,
            // convert the list of directories to a string
            [SyslogFormFieldName.directories]: syslogRow.directories.join(','),
            [SyslogFormFieldName.healthCheckNames]:
              syslogRow.healthCheckNames.join(','),
            [SyslogFormFieldName.relayId]:
              syslogRow.relayId !== 0 ? syslogRow.relayId : null
          })
          .reset()

        storeInfrastructures.selectDirectories(syslogRow.directories)
        storeInputCheckersExposure.selectCheckersFromIds(syslogRow.checkers)
        storeInputCheckersAttacks.selectCheckersFromIds(syslogRow.attackTypes)
        storeInputHealthChecks.selectHealthChecksTemplatesNames(
          syslogRow.healthCheckNames
        )
      })
      .catch(() => {
        // already catched in the store
      })
  }

export const onSyslogEditUnload =
  (storeManagementSyslogs: StoreManagementSyslogs) => () => {
    storeManagementSyslogs.reset()
  }

/**
 * Edit the entity.
 */
export const onSyslogsEditSubmit =
  (storeManagementSyslogs: StoreManagementSyslogs) =>
  (syslogId: number) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeForm } = storeManagementSyslogs

    if (!storeForm.validate()) {
      return
    }

    const syslog: InputEditSyslog = {
      id: syslogId,
      ...storeManagementSyslogs.getArgsFromStores()
    }

    const appRouter = storeManagementSyslogs.storeRoot.appRouter

    storeManagementSyslogs
      .editSyslog(syslog)
      .then(() => {
        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Management_System_Configuration_Syslogs,
          parameters: {}
        })
        appRouter.history.push(url)
      })
      .catch(() => {
        // already catched in the store
      })
  }
