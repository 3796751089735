import { Features } from '@alsid/common'
import { createEntity, EntityLicense } from '@app/entities'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { checkRbac } from '@libs/rbac/functions'
import { assertUnreachableCase, isDefined } from '@productive-codebases/toolbox'
import type { QueryLicenseData } from '@server/graphql/queries/about'
import { queryLicense } from '@server/graphql/queries/about'
import type { License, Maybe } from '@server/graphql/typeDefs/types'
import { LicenseType, RbacEntityName } from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '..'
import StoreFlags from '../helpers/StoreFlags'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'
import { LicenseStatus } from './types'

export default class StoreLicense extends StoreBase {
  public storeFlagsFetchLicense = new StoreFlags(this.storeRoot)
  public storeFlagsUpdateLicense = new StoreFlags(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Management.System.About.License']
  })

  /* Observables */

  private $license = observable.box<Maybe<EntityLicense>>(null)
  private $licenseStatus = observable.box<LicenseStatus>(LicenseStatus.unset)
  private $isRibbonVisible = observable.box(true)
  private $licenseUploaded = observable.box(false)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch license.
   */
  fetchLicense() {
    this.storeFlagsFetchLicense.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryLicenseData>(queryLicense)
      })
      .then(data => data.rbacLicense)
      .then(rbacLicense => {
        // if not found, it surely means that no license is defined yet, but
        // we should not consider it as an error, just let the store without license
        // to display the license upload page.
        if (!rbacLicense.rbacCapability.isFound) {
          this.storeFlagsFetchLicense.success()
          return
        }

        if (!checkRbac(this.storeRoot)(rbacLicense)) {
          throw new ForbiddenAccessError(RbacEntityName.License)
        }

        const licenseEntity = createEntity<License, EntityLicense>(
          EntityLicense,
          rbacLicense.node
        )

        this.setLicense(licenseEntity)

        this.storeFlagsFetchLicense.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsFetchLicense))
  }

  /**
   * Update license.
   *
   * The Ajax call is done in React side, fully handled by an Upload component
   * from Antd.
   */
  updateLicense(license: License) {
    this.storeFlagsUpdateLicense.loading()

    this.storeRoot.stores.storeMessages.success(
      this.translate('License updated'),
      {
        labelledBy: 'licenseUpdated'
      }
    )

    const licenseEntity = createEntity<License, EntityLicense>(
      EntityLicense,
      license
    )

    this.setLicense(licenseEntity)

    this.storeFlagsUpdateLicense.success()
  }

  /**
   * handle license upload failed.
   */
  uploadLicenseFailed(reason: Maybe<string>, labelledBy = 'invalidLicense') {
    this.storeFlagsUpdateLicense.loading()

    this.storeRoot.stores.storeMessages.error(
      this.translate(reason ?? 'Invalid license', {
        transformMarkdown: true
      }),
      {
        labelledBy,
        html: true,
        customIcon: 'error',
        alignItems: 'flex-start'
      }
    )

    this.storeFlagsUpdateLicense.fail()
  }

  /* Actions */

  /**
   * Set license.
   */
  @action
  setLicense(licenseEntity: EntityLicense): this {
    this.$license.set(licenseEntity)
    const status = licenseEntity.getStatus()

    if (
      !this.storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
        Features.ENFORCE_LICENSE_VIOLATION_ON_PREM
      ) &&
      status === LicenseStatus.nearViolated
    ) {
      this.$licenseStatus.set(LicenseStatus.valid)
    } else {
      this.$licenseStatus.set(status)
    }

    return this
  }

  /**
   * Set the ribbon visibility.
   */
  @action
  setRibbonVisibility(isVisible: boolean): this {
    this.$isRibbonVisible.set(isVisible)
    return this
  }

  /**
   * Set the license upload indicator.
   */
  @action
  setLicenseUploaded(hasBeenUploaded: boolean): this {
    this.$licenseUploaded.set(hasBeenUploaded)

    return this
  }

  /* Computed */

  @computed
  get license(): Maybe<EntityLicense> {
    return this.$license.get() || null
  }

  /**
   * Return the license type.
   * If unset, return Poc type by default.
   */
  @computed
  get licenseType(): LicenseType {
    if (!this.license) {
      return LicenseType.Poc
    }

    if (!this.license.type) {
      return LicenseType.Poc
    }

    return this.license.type
  }

  /**
   * Return the license status.
   */
  @computed
  get licenseStatus(): LicenseStatus {
    return this.$licenseStatus.get()
  }

  /**
   * Consider the license up-to-date if having a containerUuid linked.
   */
  @computed
  get isLicenseUpToDate(): boolean {
    return isDefined(this.license) && isDefined(this.license.containerUuid)
  }

  /**
   * Return true if the license is expired.
   */
  @computed
  get isLicenseExpired(): boolean {
    if (!this.licenseStatus) {
      return true
    }

    if (this.licenseStatus === LicenseStatus.unset) {
      return true
    }

    return this.licenseStatus === LicenseStatus.expired
  }

  /**
   * Return true if the current license status allows to access to the auth pages.
   */
  @computed
  get isLicenseStatusAuthorizeAccess(): boolean {
    const hasPocBehavior = () =>
      [LicenseType.Poc, LicenseType.Internal, LicenseType.Nfr].includes(
        this.licenseType
      )

    const hasProdBehavior = () => [LicenseType.Prod].includes(this.licenseType)

    switch (this.licenseStatus) {
      case LicenseStatus.unset: {
        return false
      }

      case LicenseStatus.overViolated:
      case LicenseStatus.violated: {
        if (hasPocBehavior()) {
          return false
        }

        return true
      }

      case LicenseStatus.expired: {
        if (hasPocBehavior()) {
          return false
        }
        /*
          Starting May 2023, both PROD and POC licenses should
          immediately block a customer platform when the license
          is expired
        */
        const { storeRbac } = this.storeRoot.stores
        const shouldLimitAccessToUnexpiredProdLicense =
          storeRbac.isUserGrantedAccordingFeatureFlag(
            Features.LICENSE_EXPIRED_PROD_RESTRICT
          ) === true
        if (shouldLimitAccessToUnexpiredProdLicense && hasProdBehavior()) {
          return false
        }

        return true
      }

      case LicenseStatus.nearViolated:
      case LicenseStatus.valid: {
        return true
      }

      default:
        assertUnreachableCase(this.licenseStatus)
    }
  }

  /**
   * Return true if a Ribbon should be displayed.
   */
  @computed
  get isRibbonDisplayed(): boolean {
    // If there is an issue with the license
    if (
      this.licenseStatus !== LicenseStatus.unset &&
      this.licenseStatus !== LicenseStatus.valid
    ) {
      return true
    }

    // If there is a ribbon message set in the configuration
    if (this.storeRoot.environment.config.app.settings.ribbon) {
      return true
    }

    return false
  }

  /**
   * Return true if the Ribbon should be visible.
   */
  @computed
  get isRibbonVisible(): boolean {
    return this.$isRibbonVisible.get()
  }

  /**
   * Return true if a license has been uploaded during this session.
   */
  @computed
  get licenseUploaded(): boolean {
    return this.$licenseUploaded.get()
  }
}
