import type { StoreIoE } from '@app/stores'

/**
 * Fetch checker compliance status at loading to display the blade header.
 */
export const handleTrustDeviantObjectsOnLoad =
  (storeIoE: StoreIoE) => (checkerId: number) => () => {
    const { storeAuthentication } = storeIoE.storeRoot.stores
    const currentProfileId = storeAuthentication.currentProfileId

    storeIoE.storeIndicator.fetchCheckerExposureComplianceStatus(
      currentProfileId,
      checkerId,
      // don't filter by directoryIds here, suppose that the checker is not compliant
      // since the blade is loaded from a dangerous trust (which is a deviance).
      []
    )
  }

/**
 * Reset everything when leaving the page.
 */
export const handleTrustDeviantObjectsOnUnload = (storeIoE: StoreIoE) => () => {
  const { storeIndicatorDeviantObjects } = storeIoE.storeIndicator
  storeIndicatorDeviantObjects.reset()
}
