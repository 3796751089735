import type { MaybeUndef } from '@@types/helpers'
import { AppRouteName } from '@app/routes'
import type {
  StoreInfrastructures,
  StoreManagementDirectories
} from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'
import { debounce } from 'lodash'

export const handleDirectoriesOnLoad =
  (
    storeInfrastructures: StoreInfrastructures,
    storeManagementDirectories: StoreManagementDirectories
  ) =>
  () => {
    storeManagementDirectories.registerDirectoryWS()
    storeInfrastructures.fetchInfrastructures()

    storeManagementDirectories.fetchDirectories({
      directoriesPage: 1,
      directoriesPerPage: storeManagementDirectories.storeWidgetList.rowsPerPage
    })
  }

export const handleDirectoriesOnUnload =
  (storeManagementDirectories: StoreManagementDirectories) => () => {
    storeManagementDirectories.unregisterDirectoryWS()
    storeManagementDirectories.reset()
  }

export const handleCreateDirectoryButtonOnClick =
  (appRouter: AppRouterClient) => () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Directories_Create,
      parameters: {}
    })
    appRouter.history.push(url)
  }

export const handleSearchDirectoryOnChange = (
  storeManagementDirectories: StoreManagementDirectories
) =>
  debounce(() => {
    storeManagementDirectories.fetchDirectoriesWithSearch({
      directoriesPage: 1
    })
  }, 500)

export const handleSearchDirectoryOnReset =
  (storeManagementDirectories: StoreManagementDirectories) => () => {
    storeManagementDirectories.storeInputSearch.reset()
    storeManagementDirectories.fetchDirectories({
      directoriesPage: 1
    })
  }

export const handleRecrawlDirectoryIconOnClick =
  (storeManagementDirectories: StoreManagementDirectories) =>
  (directoryId: number) =>
  () => {
    const directoryEntity =
      storeManagementDirectories.directories.get(directoryId)

    if (directoryEntity) {
      storeManagementDirectories.storeRecrawlDrawer
        .setData({ directoryDataRow: directoryEntity.asDataRow() })
        .openDrawer()
    }
  }

export const handleEditDirectoryIconOnClick =
  (appRouter: AppRouterClient) => (directoryId: number) => () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Directories_Edit,
      parameters: {
        directoryId
      }
    })
    appRouter.history.push(url)
  }

export const handleDeleteDirectoryIconOnClick =
  (storeManagementDirectories: StoreManagementDirectories) =>
  (directoryId: number) =>
  () => {
    const directoryEntity =
      storeManagementDirectories.directories.get(directoryId)

    if (directoryEntity) {
      storeManagementDirectories.storeDeleteDrawer
        .setData({ directoryDataRow: directoryEntity.asDataRow() })
        .openDrawer()
    }
  }

export const handleDirectoryPageOnChange =
  (storeManagementDirectories: StoreManagementDirectories) =>
  (page: number, pageSize: MaybeUndef<number>) => {
    storeManagementDirectories.fetchDirectories({
      directoriesPage: page,
      directoriesPerPage: pageSize,
      directoriesSearch: storeManagementDirectories.storeInputSearch.searchValue
    })
  }

export const handleCreateHoneyAccountDirectoryIconOnClick =
  (appRouter: AppRouterClient) => (directoryId: number) => () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName:
        AppRouteName.Management_System_Directories_Honey_Account_Create,
      parameters: {
        directoryId
      }
    })
    appRouter.history.push(url)
  }

export const handleEditHoneyAccountDirectoryIconOnClick =
  (appRouter: AppRouterClient) => (directoryId: number) => () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Directories_Honey_Account_Edit,
      parameters: {
        directoryId
      }
    })
    appRouter.history.push(url)
  }
