import { Features } from '@alsid/common'
import { ContainerContent } from '@app/components/Container'
import { IconAlert, IconAlertTul } from '@app/components/Icon'
import { ContainerRbac } from '@app/components-legacy/Container'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { useStores } from '@app/hooks/useStores'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import WidgetContainer from '../WidgetContainer'
import AlertsBadge from './AlertsBadge'
import {
  onDrawerAlertsClick,
  onDrawerAlertsLoad,
  onDrawerAlertsUnload
} from './handlers'
import {
  canReadAlerts,
  canReadAlertsIoA,
  canReadAlertsIoE
} from './permissions'

interface IWidgetAlertsProps {
  className?: string
}

const WidgetAlerts: React.FC<IWidgetAlertsProps> = props => {
  const { storeAlerts } = useStores()

  const isTulEnabled = useFeatureFlag({
    featureFlagName: Features.TENABLE_UNIVERSAL_LAYOUT
  })

  return (
    <ContainerContent
      onLoad={onDrawerAlertsLoad(storeAlerts)}
      onUnload={onDrawerAlertsUnload(storeAlerts)}
      fade={false}
      rbacCapabilityCheck={canReadAlerts}
      // if not granted, don't display the RBAC error in the header
      onDeny={() => <></>}
    >
      <WidgetContainer
        name="AlertsIndicator"
        ariaRoles={['menu', 'figure']}
        labelledBy="alerts"
        className={props.className}
        activated={storeAlerts.storeDrawer.isDrawerOpened}
        onClick={onDrawerAlertsClick(storeAlerts)}
        isBackgroundIcon={true}
      >
        {isTulEnabled ? <IconAlertTul /> : <IconAlert />}

        <ContainerRbac rbacCapabilityCheck={canReadAlertsIoE}>
          <AlertsBadge
            labelledBy="ioe-alerts-count"
            type={CheckerType.Exposure}
            count={storeAlerts.storeAlertsIoE.alertsCount}
            isTulEnabled={isTulEnabled}
          />
        </ContainerRbac>

        <ContainerRbac rbacCapabilityCheck={canReadAlertsIoA}>
          <AlertsBadge
            labelledBy="ioa-alerts-count"
            type={CheckerType.Attack}
            count={storeAlerts.storeAlertsIoA.alertsCount}
            isTulEnabled={isTulEnabled}
          />
        </ContainerRbac>
      </WidgetContainer>
    </ContainerContent>
  )
}

export default observer(WidgetAlerts)
