import {
  rbacCapabilityCheckAllOf,
  rbacCapabilityCheckAnyOf
} from '@libs/rbac/functions'
import {
  readLicenseFeatureIoA,
  readLicenseFeatureIoE,
  readUISystem
} from '@libs/rbac/permissions'

export const canAccessToSystem = rbacCapabilityCheckAllOf(
  readUISystem(),
  rbacCapabilityCheckAnyOf(readLicenseFeatureIoE(), readLicenseFeatureIoA())
)
