import { ContainerFlex } from '@app/components-legacy/Container'
import { Drawer, DrawerContext } from '@app/components-legacy/Drawer'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { TagExpression } from '@app/components-legacy/Tag'
import type StoreInputExpression from '@app/stores/helpers/StoreInputExpression'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  onInputExpressionBlur,
  onInputExpressionChange,
  onInputExpressionClearIconClick,
  onInputExpressionFocus,
  onInputExpressionSubmit,
  onInputExpressionWizardIconClick,
  onTagExpressionClick
} from './handlers'
import IconAddBookmark from './IconAddBookmark'
import IconClose from './IconClose'
import IconWidget from './IconWidget'
import InputExpressionContainer from './InputExpressionContainer'
import InputText from './InputText'
import InputTextBlurEnding from './InputTextBlurEnding'
import Wizard from './Wizard'

export interface IInputExpressionProps {
  className?: string
  style?: React.CSSProperties
  onClearIconClick?: () => void
  onValidate?: () => void
  onFocus?: () => void
  storeInputExpression: StoreInputExpression
  labelledBy?: string
}

const StyledContainerFlexIcons = styled(ContainerFlex)`
  position: absolute;
  top: 0;
  right: 0;
  height: 30px;
  background-color: ${props =>
    props.theme.tokens['inputExpression/backgroundColor/default']};
`

const InputExpression: React.FC<IInputExpressionProps> = props => {
  const { expression, inputValue, isInError, isDisabled, isFocus } =
    props.storeInputExpression

  const enableBookmarkIcon =
    props.storeInputExpression.options.enableBookmarks && expression.isDefined

  const enableClearIcon =
    !isDisabled && (expression.isDefined || inputValue !== '')

  return (
    <InputExpressionContainer isFocus={isFocus} isDisabled={isDisabled}>
      <ContainerFlex
        name="InputExpression"
        style={props.style}
        className={props.className}
        itemsFlexGrow={[0, 1]}
        labelledBy={props.labelledBy}
        items={[
          <IconWidget
            isDisable={isDisabled}
            onClick={onInputExpressionWizardIconClick(
              props.storeInputExpression
            )(isDisabled)}
          />,

          <TagExpression
            id="expression"
            expression={expression}
            onClick={onTagExpressionClick(props.storeInputExpression)(
              props.onFocus
            )}
          />,

          <InputText
            inError={isInError}
            onChange={onInputExpressionChange(props.storeInputExpression)}
            onSubmit={onInputExpressionSubmit(props.storeInputExpression)(
              props.onValidate
            )}
            onFocus={onInputExpressionFocus(props.storeInputExpression)(
              props.onFocus
            )}
            onBlur={onInputExpressionBlur(props.storeInputExpression)}
            value={inputValue}
            disabled={isDisabled}
          />,

          <StyledContainerFlexIcons
            name="InputExpressionIcons"
            alignItems="center"
            items={[
              <InputTextBlurEnding />,

              enableBookmarkIcon && <IconAddBookmark expression={expression} />,

              enableClearIcon && props.onClearIconClick && (
                <IconClose
                  onClick={onInputExpressionClearIconClick(
                    props.storeInputExpression
                  )(props.onClearIconClick)}
                />
              )
            ]}
            spaced
            spaceWidth="verySmall"
            paddingH="small"
          />
        ]}
        spaced
        spaceWidth="verySmall"
      />

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerContext.Provider
          value={{ storeDrawer: props.storeInputExpression.storeDrawer }}
        >
          <Drawer>
            <Wizard
              onValidate={props.onValidate}
              storeInputExpression={props.storeInputExpression}
            />
          </Drawer>
        </DrawerContext.Provider>
      </Portal>
    </InputExpressionContainer>
  )
}

export default observer(InputExpression)
