import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import InputCheckers from '@app/components-legacy/Input/InputCheckers'
import InputInfrastructures from '@app/components-legacy/Input/InputInfrastructures'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { Alert } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import SelectAllEntriesSelector from '../ConfigurationAttackTypeFooter/SelectAllEntriesSelector'
import ContainerStepTitle from '../ContainerStepTitle'
import IoaDeactivatedInformationPanel from '../IoaDeactivatedInformationPanel'
import { canAccessToIoASetupDownloadGpo } from '../permissions'
import SetupTable from './SetupTable'

interface IConfigurationAttackTypeSetupTableProps {
  className?: string
}

const ConfigurationAttackTypeSetupTable: React.FC<
  IConfigurationAttackTypeSetupTableProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.IoA']
  })

  const { storeManagementAttackTypeConfiguration, storeRbac } = useStores()

  const isSetupTableCheckable = storeRbac.isUserGrantedTo(
    canAccessToIoASetupDownloadGpo
  )

  return (
    <ContainerContent
      title={
        <ContainerFlex
          name="IoASetup"
          alignItems="center"
          justifyContent="space-between"
          flexGrow="1"
          items={[
            <ContainerStepTitle
              stepNumber={3}
              title={translate('IoA setup')}
            ></ContainerStepTitle>,

            <ContainerFlex
              name="IoASetupFilters"
              items={[
                <InputInfrastructures
                  storeInfrastructures={
                    storeManagementAttackTypeConfiguration.storeInfrastructures
                  }
                />,
                <InputCheckers
                  storeInputGenericCheckers={
                    storeManagementAttackTypeConfiguration.storeInputCheckersAttacks
                  }
                />
              ]}
              spaced
              spaceWidth="smaller"
            />
          ]}
          style={{
            paddingLeft: '20px'
          }}
        />
      }
      fullHeight={false}
    >
      {!storeManagementAttackTypeConfiguration.canEditConfiguration && (
        <Alert
          type="warning"
          message={translate(
            'You do not have the required permissions to edit the configuration'
          )}
          showIcon
        />
      )}

      {storeManagementAttackTypeConfiguration.isSomeIoaDeactivated() && (
        <IoaDeactivatedInformationPanel />
      )}

      <ContainerFlex
        name="TableHeader"
        justifyContent="space-between"
        items={[isSetupTableCheckable && <SelectAllEntriesSelector />]}
      />

      <SetupTable
        isCheckable={isSetupTableCheckable}
        isLoading={
          storeManagementAttackTypeConfiguration
            .storeFlagsReloadAttackTypeConfiguration.isLoading
        }
      />
    </ContainerContent>
  )
}

export default observer(ConfigurationAttackTypeSetupTable)
