import {
  canReadAlertsIoA,
  canReadAlertsIoE
} from '@app/pages/HeaderLegacy/Widgets/WidgetAlerts/permissions'
import { If } from '@libs/fp-helpers/If'
import { CheckerType } from '@server/graphql/typeDefs/types'
import StoreInputGenericCheckers from './helpers/StoreInputGenericCheckers'
import type {
  ICheckerAttack,
  ICheckerExposure
} from './helpers/StoreInputGenericCheckers/types'
import StoreBase from './StoreBase'
import type StoreRoot from './StoreRoot'
import type { IStoreOptions } from './types'

export default class StoreCheckers extends StoreBase {
  // use this store to retrieve exposure checkers and options to get criticities
  public storeInputCheckersExposure =
    new StoreInputGenericCheckers<ICheckerExposure>(this.storeRoot, {
      checkerType: CheckerType.Exposure
    })

  // use this store to retrieve attackTypes and options to get criticities
  public storeInputCheckersAttacks =
    new StoreInputGenericCheckers<ICheckerAttack>(this.storeRoot, {
      checkerType: CheckerType.Attack
    })

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
  }

  /**
   * Fetch all checkers once to retrieve checkers informations + options
   * to get criticities.
   */
  fetchCheckers(): Promise<any> {
    const { storeRbac } = this.storeRoot.stores

    return Promise.all([
      If(
        storeRbac.isUserGrantedTo(canReadAlertsIoE) &&
          !this.storeInputCheckersExposure.checkersById.size
      ).fetch(() => this.storeInputCheckersExposure.fetchCheckers()),

      If(
        storeRbac.isUserGrantedTo(canReadAlertsIoA) &&
          !this.storeInputCheckersAttacks.checkersById.size
      ).fetch(() => this.storeInputCheckersAttacks.fetchCheckers())
    ])
  }
}
