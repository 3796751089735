import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'

interface IIconCountProps extends IIconProps {}

const IconCount: React.FC<IIconCountProps> = props => {
  const size = props.size ?? 60

  return (
    <svg
      className={props.className}
      style={props.style}
      width={size}
      height={size}
      viewBox="0 0 60 60"
      fill="none"
    >
      <circle cx="30" cy="30" r="30" fill="#5FA6EF" />
      <mask
        id="mask0_236_3495"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="60"
        height="60"
      >
        <circle cx="30" cy="30" r="30" fill="#8BC1F9" />
      </mask>
      <g mask="url(#mask0_236_3495)">
        <path d="M30 30V0L46.5 -6L54 10.5L30 30Z" fill="#2182E4" />
        <path d="M54 10.5L30 30L60 24L61.5 21L54 10.5Z" fill="#055CB7" />
      </g>
      <circle cx="30" cy="30" r="18" fill="#F7F9FC" />
    </svg>
  )
}

export default IconCount
