import { BladesContainer } from '@app/components-legacy/Blade'
import { LayoutDefault } from '@app/components-legacy/Layout'
import * as React from 'react'
import IoABoardBlade from './IoABoardBlade'

interface IIoABoardPageProps {}

const IoABoardPage: React.FC<IIoABoardPageProps> = () => {
  return (
    <LayoutDefault
      content={
        <BladesContainer>
          <IoABoardBlade />
        </BladesContainer>
      }
    />
  )
}

export default IoABoardPage
