import {
  ContainerContent,
  ContainerFooter,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { InputWithClipboardCopy } from '@app/components-legacy/Input'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { canConfigureApiKey } from '@app/pages/Preferences/permissions'
import ApiKeyConfirmModal from '@app/pages/Preferences/PreferencesApiKey/ApiKeyConfirmModal'
import { onApiKeyRefreshSubmit } from '@app/pages/Preferences/PreferencesApiKey/handlers'
import * as React from 'react'

export interface IApiKeyFormProps {}

const ApiKeyForm: React.FC<IApiKeyFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Preferences.PersonalSettings.ApiKey',
      'Preferences.PersonalSettings.Navigation'
    ]
  })

  const { storePreferences, storeRbac } = useStores()

  const fields: IField[] = [
    {
      name: 'apiKey',
      label: translate('Current API key'),
      control: (
        <InputWithClipboardCopy
          name="apiKey"
          value={storePreferences.apiKey?.getPropertyAsString('key')}
          autoComplete="off"
        />
      )
    }
  ]

  return (
    <>
      <ApiKeyConfirmModal />

      <FormWrapper
        name="apiKeyConfiguration"
        onSubmit={onApiKeyRefreshSubmit(storePreferences)}
      >
        <>
          <ContainerContent title={translate('API key')}>
            <ContainerForm fields={fields} />
          </ContainerContent>

          <Portal name={PlaceHolderName.bladeFooter}>
            <ContainerFooter>
              <FormWrapperButtonSubmit
                loading={storePreferences.storeFlagsApiKey.flags.isLoading}
                disabled={!storeRbac.isUserGrantedTo(canConfigureApiKey)}
              >
                {translate('Refresh API key')}
              </FormWrapperButtonSubmit>
            </ContainerFooter>
          </Portal>
        </>
      </FormWrapper>
    </>
  )
}

export default ApiKeyForm
