import {
  ContainerContent,
  ContainerRbac
} from '@app/components-legacy/Container'
import { useAppTranslator, useStores } from '@app/hooks'
import { AuthProviderName } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import ConfigurationGroupsForm from '../ConfigurationAuthCommon/ConfigurationGroupsForm'
import { canAccessToTenableProviderDefaultRolesAndProfiles } from '../permissions'

interface ITenableAdFormProps {}

const TenableAdForm: React.FC<ITenableAdFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.TenableAccount']
  })

  const {
    storeAuthentication,
    storeManagementRbacRoles,
    storeManagementApplicationSettings,
    storeLayout
  } = useStores()

  const { storeFormTenableAccountConfiguration } =
    storeManagementApplicationSettings

  return (
    <ContainerRbac
      rbacCapabilityCheck={canAccessToTenableProviderDefaultRolesAndProfiles}
    >
      <ContainerContent
        name={TenableAdForm.name}
        title={storeLayout.getAuthenticationProductName()}
      >
        <p>
          {translate(
            'You must configure the default profile and roles when a new user is created'
          )}
        </p>

        <ConfigurationGroupsForm
          userProvider={AuthProviderName.Tenable}
          profiles={Array.from(storeAuthentication.profiles.values())}
          rbacRoles={Array.from(storeManagementRbacRoles.rbacRoles.values())}
          storeForm={storeFormTenableAccountConfiguration}
        />

        {storeAuthentication.isToneUser() && (
          <p>
            {translate(
              'You can find all settings in Tenable One which manages the complete authentication process'
            )}
          </p>
        )}
      </ContainerContent>
    </ContainerRbac>
  )
}

export default TenableAdForm
