import { consts } from '@app/styles'

export const getColor = (score: number) => {
  if (score > 75) {
    return consts.colorGreen020
  }
  if (score > 50) {
    return consts.colorBlue010
  }
  if (score > 25) {
    return consts.colorOrange001
  }
  return consts.colorRed001
}
