export const LAYOUT_WIDTH = 11.7 // in inches = 297 mm
export const LAYOUT_HEIGHT = 8.3 // in inches = 210 mm

export const PPTX_TENABLE_LOGO_SIZE = { w: 1500, h: 280 }

export const PPTX_HEADER_BG_SIZE = { w: 1282, h: 597 }
export const PPTX_MAIN_TOP_BG_SIZE = { w: 1554, h: 243 }
export const PPTX_MAIN_BOTTOM_BG_SIZE = { w: 1558, h: 324 }
export const PPTX_SHIELD_SIZE = { w: 22, h: 22 }

export const PPTX_RIBBON_WIDTH = 0.208333334

export const PPTX_COLUMN_MAX_CARDS_PER_SLIDE = 3
export const PPTX_ROW_MAX_CARDS_PER_SLIDE = 2

export const GLOBAL_PADDING = 0.104166667

export const PPTX_MAX_DOMAINS_PER_COLUMN = 24
