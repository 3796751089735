import { values } from 'lodash'
import { DeviceViewPortWidth } from './types'

const deviceViewPortIndexes = values(DeviceViewPortWidth).reduce(
  (acc, value, index) => {
    return acc.set(value, index)
  },
  new Map<DeviceViewPortWidth, number>()
)

/**
 * Class that facilitates widths comparisons between a defined values and defined
 * breakpoints.
 */
export class DeviceViewPort {
  constructor(private _deviceViewPortWidth: DeviceViewPortWidth) {}

  isLessThan(viewportWidth: DeviceViewPortWidth): boolean {
    return this._currentIndex < this._index(viewportWidth)
  }

  isMoreThan(viewportWidth: DeviceViewPortWidth): boolean {
    return this._currentIndex > this._index(viewportWidth)
  }

  isBetweenThan(
    viewportWidthMin: DeviceViewPortWidth,
    viewportWidthMax: DeviceViewPortWidth
  ): boolean {
    return (
      this.isMoreThan(viewportWidthMin) && this.isLessThan(viewportWidthMax)
    )
  }

  private get _currentIndex(): number {
    return this._index(this._deviceViewPortWidth)
  }

  private _index(viewportWidth: DeviceViewPortWidth): number {
    return deviceViewPortIndexes.get(viewportWidth) ?? 0
  }
}
