import type { PropertiesNullable } from '@@types/helpers'
import type {
  Maybe,
  Resource,
  ResourceType
} from '@server/graphql/typeDefs/types'

export default class EntityResource implements PropertiesNullable<Resource> {
  name: Maybe<string> = null
  url: Maybe<string> = null
  type: Maybe<ResourceType> = null

  constructor(data: Partial<Resource>) {
    Object.assign(this, data)
  }
}
