import type { MaybeUndef } from '@@types/helpers'
import type { StoreHealthCheck } from '@app/stores'
import { InputHealthChecksMode } from '@app/stores/StoreInputHealthChecks/types'

function fetchHealthChecksDomainStatusWithCurrentFilters(
  storeHealthCheck: StoreHealthCheck
) {
  storeHealthCheck.fetchHealthChecksDomainStatus({
    directoryIds: storeHealthCheck.storeInfrastructures.selectedDirectoryIds,
    healthCheckNames:
      storeHealthCheck.storeInputHealthChecks
        .selectedHealthChecksTemplatesNamesIds,
    showHealthy: storeHealthCheck.showHealthy,
    healthChecksPage: 1,
    healthChecksPerPage: 20
  })

  storeHealthCheck.storeRoot.stores.storeHealthCheckGlobalStatus.refreshGlobalStatusManually()
}

export const handleHealthChecksDomainStatusOnLoad =
  (storeHealthCheck: StoreHealthCheck) => () => {
    storeHealthCheck.storeInputHealthChecks.setMode(
      InputHealthChecksMode.domainStatus
    )

    storeHealthCheck.storeInputHealthChecks.selectAllHealthChecksTemplatesNames()

    fetchHealthChecksDomainStatusWithCurrentFilters(storeHealthCheck)
  }

export const handleHealthChecksDomainStatusOnUnload =
  (storeHealthCheck: StoreHealthCheck) => (): void => {
    storeHealthCheck.resetHealthCheckDomainStatus()
  }

export const handleHealthChecksDomainStatusPaginationChange =
  (storeHealthCheck: StoreHealthCheck) =>
  (healthChecksPage: number, healthChecksPerPage: MaybeUndef<number>): void => {
    storeHealthCheck.fetchHealthChecksDomainStatus({
      directoryIds: storeHealthCheck.storeInfrastructures.selectedDirectoryIds,
      healthCheckNames:
        storeHealthCheck.storeInputHealthChecks
          .selectedHealthChecksTemplatesNamesIds,
      showHealthy: storeHealthCheck.showHealthy,
      healthChecksPage,
      healthChecksPerPage
    })
  }

export const handleHealthChecksDomainStatusShowHealthyChange =
  (storeHealthCheck: StoreHealthCheck) => (showHealthy: boolean) => {
    storeHealthCheck.setShowHealthy(showHealthy)
    fetchHealthChecksDomainStatusWithCurrentFilters(storeHealthCheck)
  }

export const handleHealthChecksDomainStatusDirectoryChange =
  (storeHealthCheck: StoreHealthCheck) => () => {
    fetchHealthChecksDomainStatusWithCurrentFilters(storeHealthCheck)
  }

export const handleHealthChecksDomainStatusChecksChange =
  (storeHealthCheck: StoreHealthCheck) => () => {
    fetchHealthChecksDomainStatusWithCurrentFilters(storeHealthCheck)
  }

export const handleHealthChecksDomainStatusRefresh =
  (storeHealthCheck: StoreHealthCheck) => () => {
    storeHealthCheck.fetchHealthChecksDomainStatus({
      directoryIds: storeHealthCheck.storeInfrastructures.selectedDirectoryIds,
      healthCheckNames:
        storeHealthCheck.storeInputHealthChecks
          .selectedHealthChecksTemplatesNamesIds,
      showHealthy: storeHealthCheck.showHealthy,
      healthChecksPage:
        storeHealthCheck.storeWidgetListHealthChecksDomainStatus.paginationPage,
      healthChecksPerPage:
        storeHealthCheck.storeWidgetListHealthChecksDomainStatus.rowsPerPage
    })

    storeHealthCheck.storeRoot.stores.storeHealthCheckGlobalStatus.refreshGlobalStatusManually()
  }

export const handleHealthCheckDomainStatusDetailsOnLoad =
  (
    storeHealthCheck: StoreHealthCheck,
    healthCheckName: string,
    directoryId: number
  ) =>
  async (): Promise<void> => {
    await storeHealthCheck.storeRoot.stores.storeInfrastructures.fetchInfrastructures()

    storeHealthCheck.fetchHealthChecksDomainsStatusDetails({
      healthCheckName,
      directoryId
    })
  }

export const handleHealthCheckDomainStatusDetailsOnUnload =
  (storeHealthCheck: StoreHealthCheck) => (): void => {
    storeHealthCheck.resetDomainStatusDetails()
  }
