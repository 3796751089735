import Blade from '@app/components-legacy/Blade/Blade'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks'
import { AppRouteName } from '@app/routes'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleHealthCheckOnLoad, handleHealthCheckOnUnLoad } from './handlers'
import HealthCheckDomainStatusDetailsPage from './HealthCheckDetails/HealthCheckDomainStatusDetailsPage'
import HealthCheckPlatformStatusDetailsPage from './HealthCheckDetails/HealthCheckPlatformStatusDetailsPage'
import HealthCheckMainPage from './HealthCheckMainPage'
import HealthCheckMenu from './HealthCheckMenu'
import {
  canAccessToHealthCheck,
  canAccessToHealthCheckDomainStatus,
  canAccessToHealthCheckPlatformStatus
} from './permissions'

interface IHealthCheckDomainStatusPageProps {}

const HealthCheckBlade: React.FC<IHealthCheckDomainStatusPageProps> = () => {
  const { storeHealthCheck } = useStores()

  return (
    <>
      <Blade
        root={true}
        routeDefinition={{
          routeName: AppRouteName.HealthCheck_DomainStatus,
          parameters: {}
        }}
        onLoad={handleHealthCheckOnLoad(storeHealthCheck)}
        onUnload={handleHealthCheckOnUnLoad(storeHealthCheck)}
        layout={{
          name: 'default',
          menu: <HealthCheckMenu />,
          content: <HealthCheckMainPage />
        }}
        flags={[
          storeHealthCheck.storeInfrastructures.storeFlagsFetchInfrastructures
            .flags,
          storeHealthCheck.storeInputHealthChecks
            .storeFlagsFetchHealthChecksTemplatesNames.flags
        ]}
        rbacCapabilityCheck={canAccessToHealthCheck}
        scrollChildrenContainerOverflow="auto"
        scrollChildrenContainerBoxShadow={consts.boxShadowRedesignLight}
      />
      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName: AppRouteName.HealthCheck_DomainStatus_Details,
              parameters: {
                healthCheckName: String(),
                directoryId: Number()
              }
            },
            component: HealthCheckDomainStatusDetailsPage,
            rbacPermissionsCheck: () => canAccessToHealthCheckDomainStatus
          },
          {
            routeDefinition: {
              routeName: AppRouteName.HealthCheck_PlatformStatus_Details,
              parameters: {
                healthCheckName: String()
              }
            },
            component: HealthCheckPlatformStatusDetailsPage,
            rbacPermissionsCheck: () => canAccessToHealthCheckPlatformStatus
          }
        ]}
      />
    </>
  )
}

export default observer(HealthCheckBlade)
