import type { ReactComponent } from '@@types/helpers'
import type { IconComponentProps } from '@ant-design/icons/lib/components/AntdIcon'
import { consts } from '@app/styles'
import type { DSTheme } from '@design-system/styles/themes/types'
import { isDefined } from '@libs/isDefined'
import { Tooltip } from 'antd'
import type { TooltipProps } from 'antd/lib/tooltip'
import type { ARIARole } from 'aria-query'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from './ContainerFlex'
import type { IContainerSimpleProps } from './ContainerSimple'
import ContainerSimple from './ContainerSimple'

export interface IContainerIconProps extends IContainerSimpleProps {
  size?: 'default' | 'small'
  variant?: 'normal' | 'warning'
  label?: string
  iconComponent?: ReactComponent
  iconProps?: Omit<IconComponentProps, 'icon'>
  tooltipProps?: TooltipProps
  labelledBy: string
}

/**
 * Component that wraps the Icon component of Antd in order to fix
 * consistent widths and easier decoration like Popovers.
 */
const ContainerIcon = (props: IContainerIconProps) => {
  const ariaRoles: ARIARole[] = ['link', 'img']

  const IconComponent = props.iconComponent ? (
    <ContainerSimple ariaRoles={ariaRoles} labelledBy={props.labelledBy}>
      <props.iconComponent className={props.className} {...props.iconProps} />
    </ContainerSimple>
  ) : (
    // case of blank icon (<IconBlank />)
    <ContainerSimple ariaRoles={ariaRoles} {...props} />
  )

  const content = props.label ? (
    <ContainerFlex
      name="ContainerIconLabel"
      items={[<div>{IconComponent}</div>, <div>{props.label}</div>]}
      spaced
      spaceWidth="small"
    />
  ) : (
    // wrap the IconComponent with a div to let the Tooltip component
    // to apply its props correctly
    <div>{IconComponent}</div>
  )

  if (!props.tooltipProps) {
    return content
  }

  const tooltipProps: TooltipProps = {
    mouseEnterDelay: 0.5,
    ...props.tooltipProps
  }

  return <Tooltip {...tooltipProps}>{content}</Tooltip>
}

function getIconFontSize(size: IContainerIconProps['size']): string {
  if (!isDefined(size) || size === 'small') {
    return consts.iconSmallSize
  }

  return consts.iconDefaultSize
}

function getIconWidth(size: IContainerIconProps['size']): string {
  switch (size) {
    case 'small':
      return '10px'

    default:
      return '20px'
  }
}

function getIconColor(
  theme: DSTheme,
  props: IContainerIconProps,
  options = { isHover: false }
): string {
  if (props.iconProps?.disabled) {
    return theme.tokens['containerIcon/color/disabled']
  }

  if (options.isHover && props.iconProps?.onClick) {
    return theme.tokens['containerIcon/color/hover']
  }

  switch (props.variant) {
    case 'warning':
      return consts.colorOrange001

    default:
      return (
        props.iconProps?.color ?? theme.tokens['containerIcon/color/default']
      )
  }
}

export default styled(ContainerIcon)`
  font-size: ${props => getIconFontSize(props.size)};
  width: ${props => getIconWidth(props.size)};
  color: ${props => getIconColor(props.theme, props)};
  cursor: ${props => (props.iconProps?.onClick ? 'pointer' : 'normal')};
  transition: color 0.3s;

  :hover {
    color: ${props =>
      props.iconProps?.onClick
        ? props.theme.tokens['containerIcon/color/hover']
        : getIconColor(props.theme, props)};
  }
`
