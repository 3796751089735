import type { FieldError } from '@app/stores/helpers/StoreForm/types'
import { ensureArray } from '@libs/ensureArray'
import type { TranslateFn } from '@libs/i18n'
import { isNotFalsy } from '@libs/isDefined'
import { upperFirst } from 'lodash'

const removeFinalDot = (message: string): string => {
  return message.replace(/\.$/, '')
}

const addFinalDot = (message: string): string => {
  if (!message) {
    return ''
  }

  if (message[message.length - 1] === '.') {
    return message
  }

  return `${message}.`
}

/**
 * Concatenate errors messages by removing dots or capitalized letter.
 */
export const formatErrorMessage =
  (translate: TranslateFn) =>
  (fieldErrors: FieldError[]): string => {
    const allMessages = ensureArray(fieldErrors).filter(isNotFalsy)

    return addFinalDot(
      upperFirst(
        allMessages
          .map((error, i) => {
            // don't modify the error is there is only one
            if (allMessages.length === 1) {
              return error
            }

            // remove the final dot of message expect the latest
            if (i !== allMessages.length - 1) {
              error = removeFinalDot(error)
            }

            // remove the first capitalized letter for other messages
            return `${error[0].toLowerCase()}${error.slice(1)}`
          })
          .join(` ${translate('or')} `)
      )
    )
  }
