import type { IIconProps } from '@app/components/Icon/types'
import { IconPauseCircleOutlined } from '@app/components-legacy/Icon/IconAntd'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface IIconPauseCircleOutlinedAntdProps extends IIconProps {}

const StyledIconPauseCircleOutlined = styled(IconPauseCircleOutlined)`
  svg {
    width: ${props => props.size ?? 15}px;
    height: ${props => props.size ?? 15}px;
  }

  svg * {
    color: ${props => props.color ?? consts.colorBlue015};
  }
`

const IconPauseCircleOutlinedAntd: React.FC<
  IIconPauseCircleOutlinedAntdProps
> = props => <StyledIconPauseCircleOutlined {...props} />

export default IconPauseCircleOutlinedAntd
