import { ErrorMessage } from '@app/components/Error'
import IconTrash from '@app/components/Icon/IconTrash'
import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { IconDeleteOutlined } from '@app/components-legacy/Icon/IconAntd'
import type StoreWizard from '@app/stores/helpers/StoreWizard'
import type { WizardRule } from '@app/stores/helpers/StoreWizard/types'
import { isWizardRule } from '@app/stores/helpers/StoreWizard/utils'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import type { Combinator } from '@libs/Expression/types'
import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import CombinatorFieldRule from './CombinatorFieldRule'
import InputFieldRule from './formElements/InputFieldRule'
import SelectFieldRule from './formElements/SelectFieldRule'
import { onRemoveRuleOrRuleGroup } from './handlers'

interface IRuleFieldsetProps {
  className?: string
  index: number
  ruleGroupPath: number[]
  parentCombinator: Combinator
  rule: WizardRule
  storeWizard: StoreWizard
}

const RuleFieldset: React.FC<IRuleFieldsetProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  if (!isWizardRule(props.rule)) {
    return <ErrorMessage />
  }

  return (
    <div className={props.className} data-name="RuleFieldset">
      <ContainerFlex
        name="FieldOrDateBlock"
        alignItems="center"
        justifyContent="space-between"
        items={[
          <ContainerFlex
            name="FieldOrDateInfoBlock"
            alignItems="center"
            items={[
              <CombinatorFieldRule parentCombinator={props.parentCombinator} />,

              <SelectFieldRule
                index={props.index}
                ruleGroupPath={props.ruleGroupPath}
                storeWizard={props.storeWizard}
              />,

              themeIsLegacy ? (
                <div>:</div>
              ) : (
                <Label variant={LabelVariant.p}>:</Label>
              ),

              <InputFieldRule
                index={props.index}
                ruleGroupPath={props.ruleGroupPath}
                storeWizard={props.storeWizard}
                labelledBy="wizard-input"
              />
            ]}
            spaced
            spaceWidth="small"
          />,

          <FormWrapperButton
            labelledBy="removeRule"
            icon={!themeIsLegacy ? IconTrash : undefined}
            buttonProps={filterEmptyProps({
              icon: themeIsLegacy ? <IconDeleteOutlined /> : undefined,
              size: 'small',
              onClick: onRemoveRuleOrRuleGroup(
                props.index,
                props.ruleGroupPath,
                props.storeWizard
              )
            })}
          />
        ]}
        spaced
        spaceWidth="small"
      />
    </div>
  )
}

const observedRuleFieldset = observer(RuleFieldset)

export default styled(observedRuleFieldset)`
  margin-bottom: 10px;
`
