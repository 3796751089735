import { ContainerContent, ContainerFlex } from '@app/components/Container'
import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useHistoryChange } from '@app/components-legacy/Router/hooks'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { BackgroundColorV2 } from '@app/styles/consts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import MenuAccounts from '../MenuAccounts'
import ActivityLogsMenu from './ActivityLogsMenu'
import DrawerAddFilters from './ActivityLogsMenu/AdditionnalFilters/DrawerAdditionnalFilters'
import SeeMoreLogs from './ActivityLogsSeeMoreLogs'
import DrawerTracePayload from './Components/DrawerActivityLogPayload'
import {
  handleActivityLogsHistoryOnChange,
  handleActivityLogsOnSceneLoad,
  handleActivityLogsOnSceneUnload
} from './handlers'
import { canAccessToActivityLogs } from './permissions'
import UserActivityLogs from './UserActivityLogs'

const StyledBlade = styled(Blade)`
  background-color: ${BackgroundColorV2.lightGray};
`

interface IActivityLogsProps {
  className?: string
  labelledBy?: string
}
const ActivityLogsPage: React.FC<IActivityLogsProps> = props => {
  const { storeActivityLogs } = useStores()

  useHistoryChange({
    onHistoryChange: handleActivityLogsHistoryOnChange(storeActivityLogs)
  })

  return (
    <>
      <StyledBlade
        className={props.className}
        routeDefinition={{
          routeName: AppRouteName.ActivityLogs,
          parameters: {}
        }}
        onLoad={handleActivityLogsOnSceneLoad(storeActivityLogs)}
        onUnload={handleActivityLogsOnSceneUnload(storeActivityLogs)}
        layout={{
          name: 'default',
          menu: <MenuAccounts />,
          content: (
            <ContainerContent labelledBy="blade">
              <ContainerFlex
                name="ActivityLogsBladeContent"
                className={props.className}
                direction="column"
                alignItems="flex-end"
                items={[
                  <ActivityLogsMenu />,
                  <UserActivityLogs />,
                  <SeeMoreLogs />
                ]}
                fullWidth
              />
            </ContainerContent>
          )
        }}
        rbacCapabilityCheck={canAccessToActivityLogs}
      />

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerAddFilters />
        <DrawerTracePayload />
      </Portal>
    </>
  )
}

export default observer(ActivityLogsPage)
