import type { IIconProps } from '@app/components/Icon/types'
import { FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconCalendarProps extends IIconProps {}

const IconCalendar: React.FC<IIconCalendarProps> = props => {
  const fill = props.color ?? FontColorV2.secondary
  const size = props.size ?? 20

  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2575 3.82878H17.543C18.0481 3.82878 18.4572 4.23788 18.4572 4.74298L18.4569 17.0858C18.4569 17.5909 18.0478 18 17.5427 18H2.9142C2.40912 18 2 17.5909 2 17.0858V4.74298C2 4.2379 2.4091 3.82878 2.9142 3.82878H5.19991V2.45727C5.19991 2.20472 5.40495 2 5.65718 2C5.90973 2 6.11445 2.20504 6.11445 2.45727V3.82878H14.343V2.45727C14.343 2.20472 14.5474 2 14.8003 2C15.0531 2 15.2575 2.20504 15.2575 2.45727V3.82878ZM3.37173 16.6287H17.086V9.31464H3.37173V16.6287Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconCalendar
