import { IconInfo } from '@app/components/Icon'
import { Tooltip } from 'antd'
import type { TooltipProps } from 'antd/lib/tooltip'
import * as React from 'react'
import styled, { useTheme } from 'styled-components'

const StyledDiv = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
`

export interface ITooltipInfoProps {
  title: string
  tooltipProps?: TooltipProps
}

function TooltipInfo(props: ITooltipInfoProps) {
  const theme = useTheme()

  if (!props.title) {
    return null
  }

  return (
    <Tooltip title={props.title} {...props.tooltipProps}>
      <StyledDiv>
        <IconInfo color={theme.tokens['tooltipInfo/color/icon']} size={16} />
      </StyledDiv>
    </Tooltip>
  )
}

export default TooltipInfo
