import type { EntityRbacEntityItem } from '@app/entities'
import type { IDataRowRbacEntityItem } from '@app/entities/EntityRbacEntityItem'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import { RbacEntityItemType } from '@libs/rbac/types'
import * as React from 'react'
import ListEntityItemsTypeSingleton from './ListEntityItemsTypeSingleton'

interface IListEntityItemsTypeUIProps {
  disabled: boolean
  storeWidgetList: StoreWidgetList<EntityRbacEntityItem, IDataRowRbacEntityItem>
}

const ListEntityItemsTypeUI: React.FC<IListEntityItemsTypeUIProps> = props => {
  return (
    <ListEntityItemsTypeSingleton
      rbacEntityItemType={RbacEntityItemType.ui}
      disabled={props.disabled}
      storeWidgetList={props.storeWidgetList}
    />
  )
}

export default ListEntityItemsTypeUI
