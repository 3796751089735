import type { Maybe } from '@@types/helpers'
import type { AppRouteDefinition } from '@app/routes'

export enum MenuEntryType {
  category = 'category',
  link = 'link',
  externalLink = 'externalLink',
  footer = 'footer'
}

export interface IMenuEntry<TMenuKey extends string> {
  key: TMenuKey
  label: string
  labelledBy: string
  onClick?: () => void
  routeDefinition?: AppRouteDefinition
  type?: MenuEntryType
  menuEntries?: Maybe<Array<IMenuEntry<TMenuKey>>>
  bottom?: true
}
