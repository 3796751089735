import { ContextDSTheme } from '@design-system/contexts/ContextDSTheme'
import { dsThemes } from '@design-system/styles/themes'
import type { DSTheme } from '@design-system/styles/themes/types'
import * as react from 'react'

/**
 * Retrieve the theme tokens from the context.
 */
export function useDSTheme(): DSTheme {
  const { themeName } = react.useContext(ContextDSTheme)

  return {
    dsTheme: themeName,
    ...dsThemes[themeName]
  }
}
