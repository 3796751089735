import { Features } from '@alsid/common'
import type { StoreRoot } from '@app/stores'

/**
 * Initialize telemetry tracking in Amplitude.
 */
export function initializeTelemetry(storeRoot: StoreRoot): Promise<void> {
  return (
    Promise.resolve()
      /**
       * Initialize telemetry with user details.
       */
      .then(() => {
        /**
         * Enable telemetry tracking for authenticated and non-authenticated users.
         */
        if (
          !storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
            Features.TELEMETRY
          )
        ) {
          return
        }

        const {
          dataSourceKey,
          development,
          debug,
          containerid: containerId
        } = storeRoot.environment.config.app.telemetry

        storeRoot.stores.storeTelemetry.enableTelemetry({
          dataSourceKey,
          development,
          debug,
          containerId
        })
      })

      /**
       * On error, forward exception to the handler in the caller.
       */
      .catch(err => {
        throw err
      })
  )
}
