import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import type {
  AdObjectId,
  IDataRowAdObjectAsDeviantObject
} from '@app/entities/EntityAdObject/types'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DeviancesCards from './DeviancesCards'
import DeviancesCardsFooter from './DeviancesCardsFooter'
import { useDeviancesFactory } from './hooks'

interface IDeviancesListProps {
  className?: string
  checkerId: number
  checkerCodename: GenericCheckerCodename
  adObjectId: AdObjectId
  adObjectRow: IDataRowAdObjectAsDeviantObject
}

const DeviancesList: React.FC<IDeviancesListProps> = props => {
  const storeIndicatorDeviantObjectDeviances = useDeviancesFactory(
    props.checkerCodename,
    props.adObjectRow
  )

  return (
    <ContainerContent
      data-name="DeviancesList"
      flags={
        storeIndicatorDeviantObjectDeviances.storeFetchDeviancesFlags.flags
      }
      spinner={<SpinnerInline />}
    >
      <ContainerFlex
        name="DeviancesListContainer"
        className={props.className}
        direction="column"
        items={[
          <DeviancesCards
            storeIndicatorDeviantObjectDeviances={
              storeIndicatorDeviantObjectDeviances
            }
            checkerCodename={props.checkerCodename}
          />,

          !storeIndicatorDeviantObjectDeviances.storeWidgetListDeviances
            .isEmpty && (
            <DeviancesCardsFooter
              checkerId={props.checkerId}
              checkerCodename={props.checkerCodename}
              adObjectId={props.adObjectId}
              storeIndicatorDeviantObjectDeviances={
                storeIndicatorDeviantObjectDeviances
              }
            />
          )
        ]}
      />
    </ContainerContent>
  )
}

export default observer(DeviancesList)
