import type { StoreManagementApplicationSettings } from '@app/stores'
import { SecurityConfigurationFormFieldName } from '@app/stores/Management/StoreApplicationSettings/types'
import type { InputUpdateApplicationSettings } from '@server/graphql/typeDefs/types'

export const onSecurityConfigurationLoad =
  (storeManagementApplicationSettings: StoreManagementApplicationSettings) =>
  () => {
    storeManagementApplicationSettings.fetchApplicationSettings().then(() => {
      const applicationSettings =
        storeManagementApplicationSettings.applicationSettings

      if (!applicationSettings) {
        return
      }

      storeManagementApplicationSettings.storeFormSecurityConfiguration
        .setDefaultFieldsValues([
          {
            key: SecurityConfigurationFormFieldName.internalCertificate,
            value: applicationSettings.getPropertyAsString(
              'internalCertificate'
            )
          }
        ])
        .reset()
    })
  }

export const onSecurityConfigurationUnload =
  (storeManagementApplicationSettings: StoreManagementApplicationSettings) =>
  () => {
    storeManagementApplicationSettings.storeFormSecurityConfiguration.reset()
  }

/**
 * Save Security configuration.
 */
export const onSecurityConfigurationSubmit =
  (storeManagementApplicationSettings: StoreManagementApplicationSettings) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeFormSecurityConfiguration } =
      storeManagementApplicationSettings

    if (!storeFormSecurityConfiguration.validate()) {
      storeFormSecurityConfiguration.storeRoot.stores.storeMessages.validationError()
      return
    }

    const currentApplicationSettings =
      storeManagementApplicationSettings.applicationSettings

    if (!currentApplicationSettings) {
      return
    }

    const applicationSettings: InputUpdateApplicationSettings = {
      internalCertificate:
        storeFormSecurityConfiguration.getFieldValueAsStringOrNull(
          SecurityConfigurationFormFieldName.internalCertificate
        )
    }

    storeManagementApplicationSettings
      .updateApplicationSettings(applicationSettings)
      .catch(() => {
        // already catched in the store
      })
  }
