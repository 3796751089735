import { ContainerContent } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import TableContentBodyRows from './TableContentBodyRows'

interface ITableContentBodyProps {}

const TableContentBody: React.FC<ITableContentBodyProps> = () => {
  const { storeTrailFlow } = useStores()

  return (
    <ContainerContent
      flags={[
        storeTrailFlow.storeFlagsTrailFlow.flags,
        storeTrailFlow.storeFlagsReloadTrailFlow.flags
      ]}
      spaceWidth="none"
    >
      <TableContentBodyRows />
    </ContainerContent>
  )
}

export default observer(TableContentBody)
