import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled from 'styled-components'
import { BulletCircle } from '../Bullet'
import { ContainerFlex } from '../Container'

interface IBlockLabelRawProps {
  className?: string
  children: React.ReactNode
}

const BlockLabelRaw: React.FC<IBlockLabelRawProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  if (themeIsLegacy) {
    return <div className={props.className}>{props.children}</div>
  }

  return (
    <div className={props.className}>
      <Label variant={LabelVariant.p}>{props.children}</Label>
    </div>
  )
}

const BlockLabel = styled(BlockLabelRaw)`
  margin-bottom: ${consts.marginSmall};

  &::first-letter {
    text-transform: uppercase;
  }
`

/* Block */

interface IBlockRawProps {
  className?: string
  label?: React.ReactNode
  color?: string
  reversedBullet?: boolean
  timelineToBottom?: boolean
  children?: React.ReactNode
}

const BlockRaw: React.FC<IBlockRawProps> = props => {
  const theme = useDSTheme()

  return (
    <div className={props.className}>
      <BulletCircle
        className="bulletCircle"
        color={
          props.color || theme.tokens['containerTimeline/color/bulletCircle']
        }
        reversed={props.reversedBullet}
      />

      <ContainerFlex
        name="BlockText"
        className="blockText"
        direction="column"
        items={[
          Boolean(props.label) && <BlockLabel>{props.label}</BlockLabel>,
          props.children
        ]}
      />
    </div>
  )
}

const Block = styled(BlockRaw)`
  position: relative;
  padding-left: ${consts.paddingLarge};
  border-left: 1px solid white;

  ${props => {
    if (props.timelineToBottom) {
      return `
        border-left: 1px solid ${props.theme.tokens['containerTimeline/borderLeftColor/default']};
      `
    }
    return `
      :not(:last-child) {
        border-left: 1px solid ${props.theme.tokens['containerTimeline/borderLeftColor/default']};
      }
    `
  }};

  :not(:last-child) {
    padding-bottom: ${consts.marginDefault};
    border-left: 1px solid
      ${props =>
        props.theme.tokens['containerTimeline/borderLeftColor/default']};
  }

  .bulletCircle {
    position: absolute;
    top: 0;
    left: -4px;
  }

  .blockText {
    margin-top: -5px;
  }
`

/* ContainerTimeline */

export interface IContainerTimelineProps {
  className?: string
  itemColors?: string[]
  reversedBullets?: boolean
  itemLabels?: React.ReactNode
  timelineToBottom?: boolean
  items: React.ReactNodeArray
}

const ContainerTimeline: React.FC<IContainerTimelineProps> = props => {
  const reversedBullet =
    props.reversedBullets === undefined ? true : props.reversedBullets

  const timelineToBottom =
    props.timelineToBottom === undefined ? false : props.timelineToBottom

  return (
    <ContainerFlex
      name="ContainerTimeline"
      className={props.className}
      direction="column"
      items={props.items.map((item, i) => {
        const label = Array.isArray(props.itemLabels)
          ? props.itemLabels[i]
          : undefined

        const color = props.itemColors ? props.itemColors[i] : undefined

        return (
          <Block
            label={label}
            color={color}
            reversedBullet={reversedBullet}
            timelineToBottom={timelineToBottom}
          >
            {item}
          </Block>
        )
      })}
    />
  )
}

export default styled(ContainerTimeline)`
  /* padding: 10px 0; */
  /* Vertically center timeline in grid design */
  margin-left: 7px;
`
