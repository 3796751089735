import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  canAccessToPreferences,
  canEditCredentials,
  canConfigureApiKey
} from '../permissions'
import PreferencesApiKey from '../PreferencesApiKey'
import PreferencesInternationalization from '../PreferencesInternationalization'
import PreferencesProfiles from '../PreferencesProfiles'
import PreferencesSecurity from '../PreferencesSecurity'
import { onLoadPreferences } from './handlers'
import PreferencesNavigation from './PreferencesNavigation'

interface IPreferencesPageProps {}

const PreferencesPage: React.FC<IPreferencesPageProps> = () => {
  const { storePreferences, storeAbout } = useStores()

  return (
    <Blade
      root={true}
      routeDefinition={{
        routeName: AppRouteName.Preferences,
        parameters: {}
      }}
      onLoad={onLoadPreferences(storePreferences)}
      flags={storePreferences.storeFlags.flags}
      layout={{
        name: 'twoColumns',
        leftColumn: <PreferencesNavigation />,
        content: (
          <RbacRoutes
            routes={[
              {
                routeDefinition: {
                  routeName: AppRouteName.Preferences_Internationalization,
                  parameters: {}
                },
                component: PreferencesInternationalization,
                rbacPermissionsCheck: () => canAccessToPreferences
              },
              {
                routeDefinition: {
                  routeName: AppRouteName.Preferences_Profiles,
                  parameters: {}
                },
                component: PreferencesProfiles,
                rbacPermissionsCheck: () => canAccessToPreferences
              },
              {
                routeDefinition: {
                  routeName: AppRouteName.Preferences_Security,
                  parameters: {}
                },
                component: PreferencesSecurity,
                rbacPermissionsCheck: () => canEditCredentials(storeAbout)
              },
              {
                routeDefinition: {
                  routeName: AppRouteName.Preferences_ApiKey,
                  parameters: {}
                },
                component: PreferencesApiKey,
                rbacPermissionsCheck: () => canConfigureApiKey
              }
            ]}
            redirect
          />
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canAccessToPreferences}
    />
  )
}

export default observer(PreferencesPage)
