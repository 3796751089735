import { ContainerFlex } from '@app/components-legacy/Container/ContainerFlex'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { consts } from '@app/styles'
import { paddingDefault, paddingSmall } from '@app/styles/consts'
import { compareStringsAlphabetically } from '@libs/compareStringsAlphabetically'
import type { Maybe } from '@server/graphql/typeDefs/types'
import { AttackPathRelationType } from '@server/graphql/typeDefs/types'
import { Tooltip } from 'antd'
import * as React from 'react'
import RelationsTooltipRelation from './RelationsTooltipRelation'

export interface IRelationTooltipProps {
  className?: string
  relationTypes: AttackPathRelationType[]
  controlRightRelations?: Maybe<AttackPathRelationType[]>
  children: React.ReactNode
  sourceId?: Maybe<string | number>
  targetId?: Maybe<string | number>
}

const RelationsTooltipGraph: React.FC<IRelationTooltipProps> = props => {
  const { testAttributeProps } = useTestAttribute('contentinfo')

  const attrProps = (relationType: AttackPathRelationType) => {
    if (!props.sourceId || !props.targetId) {
      return
    }

    return testAttributeProps()(
      `tooltip-relation-${relationType}-source-${props.sourceId}-target-${props.targetId}`
    )
  }

  return (
    <Tooltip
      title={
        <div>
          <ContainerFlex
            name="RelationsTooltip"
            direction="column"
            spaceWidth="small"
            items={props.relationTypes
              .sort(compareStringsAlphabetically)
              .map(relationType => (
                <div {...attrProps(relationType)}>
                  <RelationsTooltipRelation
                    relationType={relationType}
                    controlRightRelations={
                      relationType === AttackPathRelationType.HasControlRight
                        ? props.controlRightRelations
                        : null
                    }
                  />
                </div>
              ))}
            spaced
          />
        </div>
      }
      color={consts.colorAttackPathSecondary}
      overlayClassName="noArrowTooltip smallPaddingTopTooltip"
      overlayInnerStyle={{
        borderRadius: consts.borderRadiusRedesign,
        userSelect: 'none',
        boxShadow: 'none',
        padding: `${paddingSmall} ${paddingDefault} ${paddingSmall} ${paddingSmall}`
      }}
      children={props.children}
    />
  )
}

export default RelationsTooltipGraph
