import type StoreSummaryCards from '@app/stores/IoA/StoreBoard/StoreSummaryCards'
import type { AttacksSummaryChartCardType } from '@server/graphql/typeDefs/types'
import { FieldName } from '../types'

/**
 * Submit form to update the card.
 */
export const handlerEditCardFormSubmit =
  (storeSummaryCards: StoreSummaryCards) =>
  (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault()
    }

    const { storeFormEditCard, storeDrawerEditCard } = storeSummaryCards

    const isValid = storeFormEditCard.validate()

    if (!isValid) {
      return
    }

    const directoryId = storeDrawerEditCard.data?.directoryId
    const chartType = storeFormEditCard.field(FieldName.chartType)
      .asString as AttacksSummaryChartCardType

    if (!directoryId) {
      return
    }

    return storeSummaryCards
      .editAttacksSummaryDirectory(directoryId, chartType)
      .then(handlerEditCardDrawerClose(storeSummaryCards))
  }

/**
 * Update input illustration changes in storeForm.
 */
export const handlerEditCardFormChartTypeSelection =
  (storeSummaryCards: StoreSummaryCards) =>
  (chartType: AttacksSummaryChartCardType): void => {
    storeSummaryCards.storeFormEditCard.setFieldValue(
      FieldName.chartType,
      chartType
    )
  }

/**
 * Close drawer
 */
export const handlerEditCardDrawerClose =
  (storeSummaryCards: StoreSummaryCards) => () => {
    storeSummaryCards.storeDrawerEditCard.reset()
    storeSummaryCards.storeFormEditCard.reset()
  }
