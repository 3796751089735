import { ContainerFlex } from '@app/components/Container'
import IconIdentityProvider from '@app/components/Icon/IconIdentityProvider'
import { ContainerContent } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { consts } from '@app/styles'
import { BackgroundColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'
import { CheckerIdentityDetailsPageContext } from '../context'

export interface IImpactedTenantsProps {}

const StyledTenantContainer = styled(ContainerFlex)`
  border-radius: ${consts.borderRadiusRedesign};
  background-color: ${BackgroundColorV2.lightGray};
`

const ImpactedTenants: React.FC<IImpactedTenantsProps> = props => {
  const parameters = React.useContext(CheckerIdentityDetailsPageContext)

  const { storeIoE } = useStores()

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoE.Details.Information']
  })

  if (!parameters) {
    return null
  }

  const checker = storeIoE.checkersIdentity.get(parameters.checkerCodename)

  if (!checker || !checker.tenants) {
    return null
  }

  return (
    <ContainerContent title={translate('Impacted Tenants')}>
      <ContainerFlex
        name="ImpartedTenantsContent"
        direction="column"
        flexGap="small"
        items={checker.tenants.map(tenant => (
          <StyledTenantContainer
            name="ImpactedTenant"
            key={tenant.id}
            paddingH="default"
            paddingV="default"
            flexGap="small"
            items={[
              tenant.providerType && (
                <IconIdentityProvider providerType={tenant.providerType} />
              ),
              <span>{tenant.name}</span>
            ]}
          />
        ))}
      />
    </ContainerContent>
  )
}

export default ImpactedTenants
