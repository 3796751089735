import Blade from '@app/components-legacy/Blade/Blade'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks'
import AttackPathSceneContent from '@app/pages/AttackPath/SceneBlade/AttackPathSceneContent'
import { AppRouteName } from '@app/routes'
import { BackgroundColorV2 } from '@app/styles/consts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  canAccessToAttackPath,
  canAccessToAttackPathTier0
} from '../permissions'
import {
  handleAttackPathOnSceneLoad,
  handleAttackPathOnSceneUnload
} from './handlers'
import AttackPathTier0Page from './NodeFinder/Tier0/AttackPathTier0Page'

interface IAttackPathBladeProps {}

const StyledBlade = styled(Blade)`
  background-color: ${BackgroundColorV2.lightGray};
`

const AttackPathBlade: React.FC<IAttackPathBladeProps> = props => {
  const { storeAttackPath } = useStores()

  return (
    <>
      <StyledBlade
        root={true}
        routeDefinition={{
          routeName: AppRouteName.AttackPath,
          parameters: {},
          queryStringParameters: storeAttackPath.computedQueryString
        }}
        onLoad={handleAttackPathOnSceneLoad(storeAttackPath)}
        onUnload={handleAttackPathOnSceneUnload(storeAttackPath)}
        flags={[storeAttackPath.storeFlagsHealthCheck.flags]}
        layout={{
          name: 'default',
          content: (
            <RbacRoutes
              routes={[
                {
                  routeDefinition: {
                    routeName: AppRouteName.AttackPath_Tier0,
                    parameters: {}
                  },
                  component: AttackPathTier0Page,
                  rbacPermissionsCheck: () => canAccessToAttackPathTier0
                },
                {
                  routeDefinition: {
                    routeName: AppRouteName.AttackPath,
                    parameters: {}
                  },
                  component: AttackPathSceneContent,
                  rbacPermissionsCheck: () => canAccessToAttackPath
                }
              ]}
            />
          )
        }}
        rbacCapabilityCheck={canAccessToAttackPath}
      />
    </>
  )
}

export default observer(AttackPathBlade)
