import type { Maybe, MaybeUndef } from '@@types/helpers'
import type { EntityPagination } from '@app/entities'
import { createEntities, createEntity, EntityDirectory } from '@app/entities'
import type { IDataRowDirectory } from '@app/entities/EntityDirectory'
import { DirectoryFormFieldName } from '@app/pages/Management/SystemPage/Directories/DirectoriesCreatePage/types'
import type { HoneyAccountFormFieldName } from '@app/pages/Management/SystemPage/Directories/HoneyAccountCreatePage/types'
import { AppRouteName } from '@app/routes'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import { MessageType } from '@app/stores/StoreMessages'
import { ensureArray } from '@libs/ensureArray'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import { checkRbac } from '@libs/rbac/functions'
import type {
  MutationCreateDirectory,
  MutationDeleteDirectory,
  MutationEditDirectory,
  MutationRecrawlDirectory,
  MutationRequestDirectoryConnectivity,
  MutationUpdateHoneyAccount
} from '@server/graphql/mutations/directory'
import {
  mutationCreateDirectory,
  mutationDeleteDirectory,
  mutationEditDirectory,
  mutationRecrawlDirectory,
  mutationRequestDirectoryConnectivity,
  mutationUpdateHoneyAccount
} from '@server/graphql/mutations/directory'
import type {
  QueryHoneyAccountConfigurationScript,
  QueryRbacDirectories,
  QuerySearchHoneyAccountCandidates
} from '@server/graphql/queries/infrastructure'
import {
  queryHoneyAccountConfigurationScript,
  queryRbacDirectories,
  querySearchHoneyAccountCandidates
} from '@server/graphql/queries/infrastructure'
import type {
  Directory,
  HoneyAccountConfigurationScript,
  InputCreateDirectory,
  InputEditDirectory,
  InputHoneyAccountConfigurationScript,
  InputSearchHoneyAccountCandidates,
  InputUpdateHoneyAccount,
  Pagination,
  RbacDirectoriesQueryArgs,
  SearchHoneyAccountCandidates
} from '@server/graphql/typeDefs/types'
import {
  CrawlingStatus,
  HoneyAccountStatus
} from '@server/graphql/typeDefs/types'
import type {
  IWSDirectoryConnectivityMessage,
  IWSDirectoryMessage,
  IWSRegistration
} from '@server/routers/WSProxyRouter/types'
import { WSEntityName } from '@server/routers/WSProxyRouter/types'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import type { StoreRoot } from '..'
import StoreDrawer from '../helpers/StoreDrawer'
import StoreFlags from '../helpers/StoreFlags'
import StoreForm from '../helpers/StoreForm'
import {
  anyOfValidators,
  dnFormat,
  hostnameFormat,
  ipFormat,
  mandatory,
  portFormat,
  uniqueDns,
  uniqueIp
} from '../helpers/StoreForm/validators'
import StoreWidgetList from '../helpers/StoreWidgetList'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'

export default class StoreDirectories extends StoreBase {
  public storeWidgetList = new StoreWidgetList<
    EntityDirectory,
    IDataRowDirectory
  >(this.storeRoot, {
    selectable: false
  })

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Errors', 'Errors.Form', 'Management.System.Directories']
  })

  private _directoryWSRegistration: MaybeUndef<
    IWSRegistration<IWSDirectoryMessage['payload']>
  >

  public storeFormDomain = new StoreForm<DirectoryFormFieldName>(
    this.storeRoot,
    {
      setup: {
        fields: {
          [DirectoryFormFieldName.name]: {
            label: 'Name',
            description: 'Domain name',
            validators: [mandatory()]
          },
          [DirectoryFormFieldName.dns]: {
            label: 'Domain FQDN',
            description: 'Domain FQDN example',
            validators: [
              mandatory(),
              hostnameFormat(),
              uniqueDns(() => this.getUsedDNS())()
            ]
          },
          [DirectoryFormFieldName.infrastructureId]: {
            label: 'Forest',
            description: 'Forest to which this domain belongs to',
            validators: [mandatory()],
            inputType: InputType.select
          },
          [DirectoryFormFieldName.relayId]: {
            label: 'Relay',
            description: 'Relay to which this domain belongs to',
            inputType: InputType.select,
            validators: this.storeRoot.environment.config.app.securerelay
              .customerside
              ? [mandatory()]
              : []
          },
          [DirectoryFormFieldName.sensitiveDataCollectionIsEnabled]: {
            label: 'Privileged analysis',
            inputType: InputType.switch
          },
          [DirectoryFormFieldName.tenableCloudSensitiveData]: {
            label: 'Privileged analysis transfer',
            inputType: InputType.switch
          },
          [DirectoryFormFieldName.ip]: {
            label: 'IP address or hostname',
            description:
              'IP address or hostname of the primary domain controller',
            validators: [
              mandatory(),
              anyOfValidators(ipFormat(), hostnameFormat()),
              uniqueIp(() => this.getUsedIps())()
            ]
          },
          [DirectoryFormFieldName.ldapPort]: {
            label: 'LDAP port',
            description: 'LDAP port of the primary domain controller',
            validators: [portFormat()]
          },
          [DirectoryFormFieldName.globalCatalogPort]: {
            label: 'Global Catalog port',
            description: 'Global Catalog port of the primary domain controller',
            validators: [portFormat()]
          },
          [DirectoryFormFieldName.smbPort]: {
            label: 'SMB port',
            description: 'SMB port of the primary domain controller',
            validators: [portFormat()]
          },
          [DirectoryFormFieldName.type]: {
            label: 'Type'
          }
        },
        assertFieldExists: false,
        translate: this.translate
      }
    }
  )

  public storeFormHoneyAccount = new StoreForm<HoneyAccountFormFieldName>(
    this.storeRoot,
    {
      setup: {
        fields: {
          [DirectoryFormFieldName.name]: {
            label: 'Name',
            description: 'Honey Account name',
            validators: [mandatory(), dnFormat()],
            inputType: InputType.inputAutoComplete
          }
        },
        assertFieldExists: false,
        translate: this.translate
      }
    }
  )

  public storeDeleteDrawer = new StoreDrawer<{
    directoryDataRow: IDataRowDirectory
  }>(this.storeRoot)
  public storeRecrawlDrawer = new StoreDrawer<{
    directoryDataRow: IDataRowDirectory
  }>(this.storeRoot)

  public storeFlags = new StoreFlags(this.storeRoot)
  public storeSearchFlags = new StoreFlags(this.storeRoot)
  public storeSubmitFlags = new StoreFlags(this.storeRoot)
  public storeDirectoryConnectivityTestFlags = new StoreFlags(this.storeRoot)
  public storeDeletionFlags = new StoreFlags(this.storeRoot)
  public storeRecrawlFlags = new StoreFlags(this.storeRoot)
  public storeUpdateHoneyAccountFlags = new StoreFlags(this.storeRoot)
  public storeSearchHoneyAccountsFlags = new StoreFlags(this.storeRoot)
  public storeHoneyAccountConfigurationScriptFlags = new StoreFlags(
    this.storeRoot
  )

  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  private directoryConnectivityTestWSRegistration: Maybe<
    IWSRegistration<IWSDirectoryConnectivityMessage['payload']>
  > = null
  private directoryConnectivityTestTimeout: Maybe<NodeJS.Timeout> = null
  private _directoryConnectivityTestSuccess = false
  private directoryConnectivityTestMessageKey: Maybe<string> = null

  /* Observables */

  // keys are directory id
  private $directories = observable.map<number, EntityDirectory>()
  private $honeyAccountCandidates = observable.map<
    number,
    SearchHoneyAccountCandidates[]
  >()
  private $honeyAccountConfigurationScript = observable.map<
    number,
    HoneyAccountConfigurationScript['script']
  >()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  fetchDirectories(
    _args?: RbacDirectoriesQueryArgs,
    storeFlags = this.storeFlags
  ): Promise<void> {
    const args: RbacDirectoriesQueryArgs = {
      directoriesPage: this.storeWidgetList.paginationPage,
      directoriesPerPage: this.storeWidgetList.rowsPerPage,
      ..._args
    }

    storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryRbacDirectories>(queryRbacDirectories, args)
      })
      .then(data => {
        return data.rbacDirectories
      })
      .then(directories => {
        if (!checkRbac(this.storeRoot, storeFlags)(directories)) {
          throw new ForbiddenAccessError()
        }

        const directoriesEntities = createEntities<Directory, EntityDirectory>(
          EntityDirectory,
          directories.node.node
        )

        const directoriesPagination = createEntity<
          Pagination,
          EntityPagination
        >(EntityDirectory, directories.node.pagination)

        this.setDirectories(directoriesEntities)

        this.storeWidgetList.setEntities(directoriesEntities)
        this.storeWidgetList.setPagination(directoriesPagination)

        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Fetch directories by passing the search value.
   */
  fetchDirectoriesWithSearch(_args?: RbacDirectoriesQueryArgs): Promise<void> {
    const args: RbacDirectoriesQueryArgs = {
      directoriesPage: this.storeWidgetList.paginationPage,
      directoriesPerPage: this.storeWidgetList.rowsPerPage,
      directoriesSearch: this.storeInputSearch.searchValue,
      ..._args
    }

    return this.fetchDirectories(args, this.storeSearchFlags)
  }

  /**
   * Create an directory,
   * Refetch directories.
   */
  createDirectory(directory: InputCreateDirectory) {
    this.storeSubmitFlags.loading()

    return (
      Promise.resolve()
        .then(() => {
          const args: MutationCreateDirectory['args'] = {
            directory
          }

          return this.storeRoot
            .getGQLRequestor()
            .makeQuery<MutationCreateDirectory>(mutationCreateDirectory, args)
        })
        // resync the infras <-> dirs mapping
        .then(() =>
          this.storeRoot.stores.storeInfrastructures.fetchInfrastructures()
        )
        .then(() => this.fetchDirectories())
        .then(() => {
          this.storeFormDomain.reset()

          this.storeRoot.stores.storeMessages.success(
            this.translate('Domain X created', {
              interpolations: {
                directoryName: directory.name
              }
            }),
            {
              labelledBy: 'directoryCreated'
            }
          )

          this.storeSubmitFlags.success()
        })
        .catch(
          handleStoreError(this.storeRoot, this.storeSubmitFlags, {
            forwardExceptionFn: () => {
              return 'An error has occurred when creating the directory'
            }
          })
        )
    )
  }

  /**
   * Edit an directory.
   */
  editDirectory(directory: InputEditDirectory) {
    this.storeSubmitFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: MutationEditDirectory['args'] = {
          directory
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationEditDirectory>(mutationEditDirectory, args)
      })
      .then(() => this.fetchDirectories())
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Domain X updated', {
            interpolations: {
              directoryName: directory.name
            }
          }),
          {
            labelledBy: 'directoryUpdated'
          }
        )

        this.storeSubmitFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeSubmitFlags, {
          forwardExceptionFn: () => {
            return 'An error has occurred when editing the directory'
          }
        })
      )
  }

  /**
   * Delete a directory.
   */
  deleteDirectory(directoryId: number, directoryName: string) {
    this.storeDeletionFlags.loading()

    return (
      Promise.resolve()
        .then(() => {
          const infrastructure =
            this.storeRoot.stores.storeInfrastructures.getInfrastructureFromDirectoryId(
              directoryId
            )

          if (!infrastructure) {
            throw new Error(
              'Infrastructure of the directory has not been found.'
            )
          }

          return infrastructure
        })
        .then(infrastructure => {
          const args: MutationDeleteDirectory['args'] = {
            directory: {
              infrastructureId: infrastructure.getPropertyAsNumber('id'),
              id: directoryId
            }
          }

          return this.storeRoot
            .getGQLRequestor()
            .makeQuery<MutationDeleteDirectory>(mutationDeleteDirectory, args)
        })
        // resync the infras <-> dirs mapping
        .then(() =>
          this.storeRoot.stores.storeInfrastructures.fetchInfrastructures()
        )
        .then(() => {
          this.storeRoot.stores.storeMessages.success(
            this.translate('Domain X deleted', {
              interpolations: {
                directoryName
              }
            }),
            {
              labelledBy: 'directoryDeleted'
            }
          )

          this.storeDeletionFlags.success()
        })
        .catch(
          handleStoreError(this.storeRoot, this.storeDeletionFlags, {
            forwardExceptionFn: () => {
              return 'An error has occurred when deleting the directory'
            }
          })
        )
    )
  }

  /**
   * Recrawl a directory.
   */
  recrawlDirectory(directoryId: number): Promise<void> {
    this.storeRecrawlFlags.loading()

    const directoryEntity = this.directories.get(directoryId)

    if (!directoryEntity) {
      throw new Error('Directory has not been found.')
    }

    return Promise.resolve()
      .then(() => {
        const args: MutationRecrawlDirectory['args'] = {
          directory: {
            id: directoryEntity.getPropertyAsNumber('id'),
            infrastructureId:
              directoryEntity.getPropertyAsNumber('infrastructureId')
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationRecrawlDirectory>(mutationRecrawlDirectory, args)
      })
      .then(({ recrawlDirectory }) => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Domain X recrawled', {
            interpolations: {
              directoryName: directoryEntity.getPropertyAsString('name')
            }
          }),
          {
            labelledBy: 'directoryRecrawled'
          }
        )

        this.updateDirectoryEntityStatus(directoryEntity, recrawlDirectory)

        this.storeRecrawlFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeRecrawlFlags))
  }

  /**
   * Update honey account.
   */
  updateHoneyAccount(honeyAccount: InputUpdateHoneyAccount): Promise<void> {
    this.storeUpdateHoneyAccountFlags.loading()

    const directoryEntity = this.directories.get(honeyAccount.directoryId)

    if (!directoryEntity) {
      throw new Error('Directory has not been found.')
    }

    return (
      Promise.resolve()
        .then(() => {
          const args: MutationUpdateHoneyAccount['args'] = {
            honeyAccount
          }

          return this.storeRoot
            .getGQLRequestor()
            .makeQuery<MutationUpdateHoneyAccount>(
              mutationUpdateHoneyAccount,
              args
            )
        })
        .then(() => this.fetchDirectories())
        // Response is not used because we fetch updated directories
        .then(() => {
          this.storeRoot.stores.storeMessages.success(
            this.translate('Honey Account for domain X updated', {
              interpolations: {
                directoryName: directoryEntity.getPropertyAsString('name')
              }
            }),
            {
              labelledBy: 'honeyAccountUpdated'
            }
          )

          this.storeUpdateHoneyAccountFlags.success()
        })
        .catch(
          handleStoreError(this.storeRoot, this.storeUpdateHoneyAccountFlags)
        )
    )
  }

  /**
   * Delete honey account.
   */
  deleteHoneyAccount(directoryId: number): Promise<void> {
    this.storeUpdateHoneyAccountFlags.loading()

    const directoryEntity = this.directories.get(directoryId)

    if (!directoryEntity) {
      throw new Error('Directory has not been found.')
    }

    return Promise.resolve()
      .then(() => {
        const args: MutationUpdateHoneyAccount['args'] = {
          honeyAccount: {
            directoryId,
            honeyAccountDistinguishedName: null
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationUpdateHoneyAccount>(
            mutationUpdateHoneyAccount,
            args
          )
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Honey Account for domain X deleted', {
            interpolations: {
              directoryName: directoryEntity.getPropertyAsString('name')
            }
          }),
          {
            labelledBy: 'honeyAccountDeleted'
          }
        )

        this.storeUpdateHoneyAccountFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeUpdateHoneyAccountFlags)
      )
  }

  /**
   * Search honey account candidates.
   */
  searchHoneyAccountCandidates = (
    honeyAccount: InputSearchHoneyAccountCandidates
  ): Promise<void> => {
    this.storeSearchHoneyAccountsFlags.loading()

    const directoryEntity = this.directories.get(honeyAccount.directoryId)

    if (!directoryEntity) {
      throw new Error('Directory has not been found.')
    }

    return Promise.resolve()
      .then(() => {
        const args: QuerySearchHoneyAccountCandidates['args'] = {
          honeyAccount
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QuerySearchHoneyAccountCandidates>(
            querySearchHoneyAccountCandidates,
            args
          )
      })
      .then(data => {
        this.setHoneyAccountCandidates(
          honeyAccount.directoryId,
          data.searchHoneyAccountCandidates
        )

        this.storeSearchHoneyAccountsFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeSearchHoneyAccountsFlags)
      )
  }

  /**
   * Update directory status.
   */
  updateDirectoryEntityStatus(
    directoryEntity: EntityDirectory,
    status: Pick<
      IWSDirectoryMessage['payload'],
      | 'ldapCrawlingStatus'
      | 'sysvolCrawlingStatus'
      | 'honeyAccountConfigurationStatus'
      | 'sensitiveDataCollectionStatus'
    >
  ): EntityDirectory {
    directoryEntity.setLdapCrawlingStatus(status.ldapCrawlingStatus)
    directoryEntity.setSysvolCrawlingStatus(status.sysvolCrawlingStatus)
    directoryEntity.setSensitiveDataCollectionStatus(
      status.sensitiveDataCollectionStatus
    )

    // TODO: remove statement once HA feature is GA
    if (status.honeyAccountConfigurationStatus) {
      directoryEntity.setHoneyAccountConfigurationStatus(
        status.honeyAccountConfigurationStatus
      )
    }

    this.storeWidgetList.updateOneEntity(
      directoryEntity,
      entity => entity.id === directoryEntity.id
    )

    return directoryEntity
  }

  /**
   * Get honey account configuration script.
   */
  getHoneyAccountConfigurationScript = (
    honeyAccount: InputHoneyAccountConfigurationScript
  ): Promise<void> => {
    this.storeHoneyAccountConfigurationScriptFlags.loading()

    const directoryEntity = this.directories.get(honeyAccount.directoryId)

    if (!directoryEntity) {
      throw new Error('Directory has not been found.')
    }

    return Promise.resolve()
      .then(() => {
        const args: QueryHoneyAccountConfigurationScript['args'] = {
          honeyAccount
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryHoneyAccountConfigurationScript>(
            queryHoneyAccountConfigurationScript,
            args
          )
      })
      .then(data => {
        this.setHoneyAccountConfigurationScript(
          directoryEntity.getPropertyAsNumber('id'),
          data.honeyAccountConfigurationScript.script
        )

        this.storeHoneyAccountConfigurationScriptFlags.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeHoneyAccountConfigurationScriptFlags
        )
      )
  }

  /**
   * Cancel the test directory connectivity registration.
   */
  private cancelTestDirectoryConnectivityRegistration(): this {
    if (this.directoryConnectivityTestWSRegistration) {
      this.storeRoot.wsClient.removeRegistration(
        this.directoryConnectivityTestWSRegistration
      )
      this.directoryConnectivityTestWSRegistration = null
    }

    return this
  }

  /**
   * Cancel the test directory connectivity timeout.
   */
  private cancelTestDirectoryConnectivityTimeout(): this {
    if (this.directoryConnectivityTestTimeout) {
      clearTimeout(this.directoryConnectivityTestTimeout)
      this.directoryConnectivityTestTimeout = null
    }

    return this
  }

  /**
   * Cancel the test directory connectivity.
   */
  private cancelTestDirectoryConnectivity(): this {
    this.cancelTestDirectoryConnectivityRegistration()
    this.cancelTestDirectoryConnectivityTimeout()

    return this
  }

  /**
   * Close the directory connectivity error message.
   */
  private closeDirectoryConnectivityMessage(): this {
    if (this.directoryConnectivityTestMessageKey) {
      this.storeRoot.stores.storeMessages.removeAlert(
        this.directoryConnectivityTestMessageKey
      )
      this.directoryConnectivityTestMessageKey = null
    }

    return this
  }

  /**
   * Test directory connectivity.
   */
  testDirectoryConnectivity(directory: InputCreateDirectory): Promise<void> {
    this.closeDirectoryConnectivityMessage()
    this.storeDirectoryConnectivityTestFlags.loading()
    this.directoryConnectivityTestSuccess = false

    const registration = this.storeRoot.wsClient.addRegistration<
      IWSDirectoryConnectivityMessage['payload']
    >(
      'DirectoryConnectivity',
      {
        name: WSEntityName.directoryConnectivityStatus,
        payload: {}
      },
      payload => this.handleDirectoryConnectivityResponse(payload, directory)
    )

    this.directoryConnectivityTestWSRegistration = registration

    this.directoryConnectivityTestTimeout = setTimeout(() => {
      this.directoryConnectivityTestTimeout = null

      this.cancelTestDirectoryConnectivityRegistration()

      this.storeDirectoryConnectivityTestFlags.fail()
      this.directoryConnectivityTestMessageKey =
        this.storeRoot.stores.storeMessages.error(
          this.translate('The connectivity test timed out'),
          {
            type: MessageType.error,
            labelledBy: 'directoryConnectivityTestErrorTimeout'
          }
        )
    }, this.storeRoot.environment.config.app.system.directoryconnectivitytimeout)

    return Promise.resolve()
      .then(() => {
        const args: MutationRequestDirectoryConnectivity['args'] = {
          id: registration.channelUuid,
          directory: {
            name: directory.name,
            ip: directory.ip,
            ldapPort: directory.ldapPort,
            globalCatalogPort: directory.globalCatalogPort,
            smbPort: directory.smbPort,
            dns: directory.dns,
            infrastructureId: directory.infrastructureId,
            relayId: directory.relayId,
            sensitiveDataCollectionIsEnabled:
              directory.sensitiveDataCollectionIsEnabled
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationRequestDirectoryConnectivity>(
            mutationRequestDirectoryConnectivity,
            args
          )
      })
      .then(() => {
        // Nothing to do, waiting for the response through WebSocket.
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeDirectoryConnectivityTestFlags,
          {
            forwardExceptionFn: () => {
              return 'An error has occurred while testing the directory connectivity'
            }
          }
        )
      )
  }

  /**
   * Handle directory connectivity response.
   */
  handleDirectoryConnectivityResponse(
    payload: IWSDirectoryConnectivityMessage['payload'],
    directory: InputCreateDirectory
  ) {
    this.cancelTestDirectoryConnectivity()

    if (!payload.success) {
      this.storeDirectoryConnectivityTestFlags.fail()

      this.directoryConnectivityTestMessageKey =
        this.storeRoot.stores.storeMessages.error(
          `
            ${this.translate('The connectivity test failed')}
            <ul style="padding-left: 15px">
              ${payload.messages
                .map(
                  message =>
                    `<li>${this.translate(message, {
                      interpolations: {
                        ip: directory.ip,
                        port: (message.startsWith('LDAP')
                          ? directory.ldapPort
                          : message.startsWith('SMB')
                            ? directory.smbPort
                            : directory.globalCatalogPort)!.toString()
                      }
                    })}</li>`
                )
                .join('')}
            </ul>
          `,
          {
            type: MessageType.error,
            labelledBy: 'directoryConnectivityTestError',
            html: true,
            customIcon: 'error',
            duration: 0,
            closable: true
          }
        )
      return
    }

    this.directoryConnectivityTestSuccess = true
    this.storeDirectoryConnectivityTestFlags.success()
    this.directoryConnectivityTestMessageKey =
      this.storeRoot.stores.storeMessages.success(
        this.translate('The connectivity test was successful'),
        {
          type: MessageType.success,
          labelledBy: 'directoryConnectivityTestSuccess'
        }
      )
  }

  get directoryConnectivityTestSuccess(): boolean {
    return this._directoryConnectivityTestSuccess
  }

  private set directoryConnectivityTestSuccess(
    directoryConnectivityTestSuccess
  ) {
    this._directoryConnectivityTestSuccess = directoryConnectivityTestSuccess
  }

  /**
   * Enable to recrawl a directory if status are not pending.
   */
  isDirectoryReadyToRecrawl(directoryId: number): boolean {
    const entityDirectory = this.directories.get(directoryId)

    if (!entityDirectory) {
      return false
    }

    const ldapCrawlingStatus =
      entityDirectory.getPropertyAsT('ldapCrawlingStatus')
    const sysvolCrawlingStatus = entityDirectory.getPropertyAsT(
      'sysvolCrawlingStatus'
    )

    return (
      (ldapCrawlingStatus === CrawlingStatus.Success ||
        ldapCrawlingStatus === CrawlingStatus.Failure) &&
      (sysvolCrawlingStatus === CrawlingStatus.Success ||
        sysvolCrawlingStatus === CrawlingStatus.Failure)
    )
  }

  /**
   * Return true if other status are in success.
   */
  isHoneyAccountConfigurable(directoryId: number): boolean {
    const entityDirectory = this.directories.get(directoryId)

    if (!entityDirectory) {
      return false
    }

    return (
      entityDirectory.ldapCrawlingStatus === CrawlingStatus.Success &&
      entityDirectory.sysvolCrawlingStatus === CrawlingStatus.Success
    )
  }

  /**
   * Return true if a Honey Account exists.
   */
  isHoneyAccountEditable(directoryId: number): boolean {
    const entityDirectory = this.directories.get(directoryId)

    if (!entityDirectory) {
      return false
    }

    return (
      entityDirectory.honeyAccountConfigurationStatus !==
      HoneyAccountStatus.None
    )
  }

  /**
   * Return all directories IP used, except the one of the current directory edited.
   */
  getUsedIps(): Set<string> {
    const parameters = this.storeRoot.appRouter.getRouteParameters({
      routeName: AppRouteName.Management_System_Directories_Edit,
      parameters: {
        directoryId: Number()
      }
    })

    return new Set(
      Array.from(this.directories.values())
        .filter(directory => {
          return parameters?.directoryId
            ? // don't return the directory if it matches the current edited directory
              directory.id !== parameters.directoryId
            : true
        })
        .map(entityDirectory => entityDirectory.ip)
        .filter(isDefined)
    )
  }

  /**
   * Return all directories DNS used.
   */
  getUsedDNS(): Set<string> {
    const parameters = this.storeRoot.appRouter.getRouteParameters({
      routeName: AppRouteName.Management_System_Directories_Edit,
      parameters: {
        directoryId: Number()
      }
    })

    return new Set(
      Array.from(this.directories.values())
        .filter(directory => {
          return parameters?.directoryId
            ? // don't return the directory if it matches the current edited directory
              directory.id !== parameters.directoryId
            : true
        })
        .map(entityDirectory => entityDirectory.dns)
        .filter(isDefined)
    )
  }

  /**
   * Return the formated candidates as object format
   */
  getHoneyAccountCandidatesAsOptions(directoryId: number) {
    const maybeHoneyAccountCandidates =
      this.honeyAccountCandidates.get(directoryId)

    if (!maybeHoneyAccountCandidates) {
      return []
    }

    return ensureArray(maybeHoneyAccountCandidates).map(candidate => {
      return {
        value: candidate.name,
        text: candidate.name
      }
    })
  }

  /**
   * Return all directories where the script were not installed
   */
  getNotInstalledScriptDirectories(): Set<EntityDirectory> {
    return new Set(
      Array.from(this.directories.values()).filter(directory => {
        return !directory.ioaProcedureInstalled
      })
    )
  }

  /* Actions */

  @action
  reset(): this {
    this.storeSearchFlags.reset()
    this.storeInputSearch.reset()

    return this
  }

  @action
  resetCreationPage(): this {
    this.directoryConnectivityTestSuccess = false

    this.storeDirectoryConnectivityTestFlags.reset()

    this.cancelTestDirectoryConnectivity()

    this.closeDirectoryConnectivityMessage()

    return this
  }

  @action
  setDirectories(directories: EntityDirectory[]): this {
    this.$directories.clear()

    directories.forEach(entity => {
      this.setDirectory(entity)
    })

    return this
  }

  @action
  setDirectory(directoryEntity: EntityDirectory): this {
    this.$directories.set(
      directoryEntity.getPropertyAsNumber('id'),
      directoryEntity
    )

    return this
  }

  @action
  setHoneyAccountCandidates(
    directoryId: number,
    honeyAccountCandidates: SearchHoneyAccountCandidates[]
  ): this {
    this.$honeyAccountCandidates.set(directoryId, honeyAccountCandidates)

    return this
  }

  @action
  setHoneyAccountConfigurationScript(
    directoryId: number,
    script: HoneyAccountConfigurationScript['script']
  ): this {
    this.$honeyAccountConfigurationScript.set(directoryId, script)

    return this
  }

  @action
  removeHoneyAccountCandidates(directoryId: number): this {
    this.$honeyAccountCandidates.delete(directoryId)

    return this
  }

  @action
  clearHoneyAccountCandidates(): this {
    this.$honeyAccountCandidates.clear()

    return this
  }

  /**
   * Add an even from a payload received via websocket.
   * New directory is updated in the map.
   */
  onWSMessage(payload: IWSDirectoryMessage['payload']): void {
    if (!payload) {
      return
    }

    const directoryEntity = this.directories.get(payload.id)

    if (!directoryEntity) {
      return
    }

    this.updateDirectoryEntityStatus(directoryEntity, payload)
    this.setDirectory(directoryEntity)
  }

  /**
   * Subscribe to directory.
   */
  @action
  registerDirectoryWS() {
    // don't register several times
    if (this._directoryWSRegistration) {
      return
    }

    const { storeManagementDirectories } = this.storeRoot.stores

    this._directoryWSRegistration = this.storeRoot.wsClient.addRegistration<
      IWSDirectoryMessage['payload']
    >(
      'Directory',
      {
        name: WSEntityName.directory,
        payload: {}
      },
      storeManagementDirectories.onWSMessage.bind(this)
    )
  }

  /**
   * Unsubscribe directory.
   */
  @action
  unregisterDirectoryWS() {
    if (this._directoryWSRegistration) {
      this.storeRoot.wsClient.removeRegistration(this._directoryWSRegistration)
      this._directoryWSRegistration = undefined
    }
  }

  /* Computed methods */

  @computed
  get directories(): Map<number, EntityDirectory> {
    return toJS(this.$directories)
  }

  @computed
  get honeyAccountCandidates(): Map<number, SearchHoneyAccountCandidates[]> {
    return toJS(this.$honeyAccountCandidates)
  }

  @computed
  get honeyAccountConfigurationScript(): Map<
    number,
    HoneyAccountConfigurationScript['script']
  > {
    return toJS(this.$honeyAccountConfigurationScript)
  }
}
