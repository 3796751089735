import type { Maybe } from '@@types/helpers'
import { canAccessToAccounts } from '@app/pages/Management/AccountsPage/permissions'
import {
  rbacCapabilityCheckAllOf,
  rbacCapabilityCheckAnyOf
} from '@libs/rbac/functions'
import {
  createUser,
  deleteUser,
  editLockoutPolicy,
  editRole,
  editUser,
  readRoles,
  readUIAccountsUsers,
  readUsers
} from '@libs/rbac/permissions'

export const canAccessToUsers = rbacCapabilityCheckAllOf(
  canAccessToAccounts,
  readUIAccountsUsers(),
  (permissions, meta) => {
    return rbacCapabilityCheckAnyOf(
      meta.storeRbac.editUserHimself(),
      readUsers()
    )(permissions, meta)
  }
)

export const canCreateUser = rbacCapabilityCheckAllOf(
  canAccessToUsers,
  createUser()
)

export const canEditUser = (userId: number) =>
  rbacCapabilityCheckAllOf(canAccessToUsers, editUser(userId))

export const canUnlockOutUser = (userId: number) =>
  rbacCapabilityCheckAllOf(editLockoutPolicy(), canEditUser(userId))

export const canDeleteUser = (userId: Maybe<number>) =>
  rbacCapabilityCheckAllOf(canAccessToUsers, deleteUser(userId))

export const canSelectUserRoles = rbacCapabilityCheckAllOf(
  canAccessToUsers,
  rbacCapabilityCheckAnyOf(readRoles(), editRole())
)
