import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components/Container'
import { LabelAltError } from '@app/components/Labels'
import type { IContainerSimpleProps } from '@app/components-legacy/Container/ContainerSimple'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import * as React from 'react'

interface IErrorMessageProps extends IContainerSimpleProps {
  message?: Maybe<string>
}

const ErrorMessage: React.FC<IErrorMessageProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Errors'] })

  const message = props.message || translate('An error has occurred')
  const labelledBy = props.labelledBy || 'genericError'

  return (
    <ContainerFlex
      name="ErrorMessage"
      className={props.className}
      direction="column"
      justifyContent="center"
      items={[
        <LabelAltError labelledBy={labelledBy} {...props}>
          {message}
        </LabelAltError>
      ]}
    />
  )
}

export default ErrorMessage
