import type { PropertiesNullable } from '@@types/helpers'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import type {
  Directory,
  Infrastructure,
  Maybe
} from '@server/graphql/typeDefs/types'
import { createEntities } from '.'
import EntityBase from './EntityBase'
import EntityDirectory from './EntityDirectory'
import type { IEntityListable, IEntityOmitKeysParameter } from './types'

export interface IDataRowInfrastructure {
  id: number
  name: string
}

export default class EntityInfrastructure
  extends EntityBase
  implements
    PropertiesNullable<Infrastructure>,
    IEntityListable<IDataRowInfrastructure>
{
  id: Maybe<number> = null
  name: Maybe<string> = null
  login: Maybe<string> = null
  directories: Maybe<Directory[]> = null

  // save one color per infrastructure
  color: string = '#000000'

  constructor(data: Partial<EntityInfrastructure>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return directories as entities.
   * Generate a variant color for each directory from the infrastructure color.
   */
  getDirectories(): EntityDirectory[] {
    const directories = createEntities<Directory, EntityDirectory>(
      EntityDirectory,
      this.directories
    )

    directories.forEach((dir, i) => {
      dir.setColor(this.color)
    })

    return directories
  }

  setColor(color: string): void {
    this.color = color
  }

  /* Implements IEntityListable */

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowInfrastructure> = []
  ): Array<IWidgetListColumns<IDataRowInfrastructure>> {
    const columns: Array<IWidgetListColumns<IDataRowInfrastructure>> = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Name',
        key: 'name'
      }
    ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  asDataRow(): IDataRowInfrastructure {
    return {
      id: this.getPropertyAsNumber('id'),
      name: this.getPropertyAsString('name')
    }
  }
}
