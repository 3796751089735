import { AppRouteName } from '@app/routes'
import type { StoreBlades, StoreManagementRbacRoles } from '@app/stores'
import type { RbacRoleFormVersion } from '@libs/rbac/types'
import { RbacRoleFormFieldName } from '@libs/rbac/types'
import type { AppRouterClient } from '@libs/Router/types'
import type { InputCreateRbacRole } from '@server/graphql/typeDefs/types'

/**
 * Create role initialization.
 */
export const onCreateRoleLoad =
  (storeManagementRbacRoles: StoreManagementRbacRoles) => () => {
    // init form
    storeManagementRbacRoles.storeForm
      .setDefaultFieldsValues([
        {
          key: RbacRoleFormFieldName.name,
          value: ''
        },
        {
          key: RbacRoleFormFieldName.description,
          value: ''
        }
      ])
      .reset()
  }

/**
 * Create a role.
 */
export const onCreateRoleSubmit =
  (
    storeBlades: StoreBlades,
    storeManagementRbacRoles: StoreManagementRbacRoles,
    appRouter: AppRouterClient
  ) =>
  (version: RbacRoleFormVersion) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeForm } = storeManagementRbacRoles

    if (!storeForm.validate()) {
      return
    }

    const role: InputCreateRbacRole = {
      name: storeForm.getFieldValueAsString(RbacRoleFormFieldName.name),
      description: storeForm.getFieldValueAsString(
        RbacRoleFormFieldName.description
      )
    }

    storeManagementRbacRoles
      .createRole(role)
      .then(() => storeBlades.removeLastBlade())
      .then(() => {
        const roleId = storeManagementRbacRoles.currentRbacRoleId

        if (!roleId) {
          return
        }

        // redirect
        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Management_Accounts_Roles_Edit,
          parameters: {
            roleId
          }
        })

        appRouter.history.push(url)
      })
      .catch(() => {
        // already catched in the store
      })
  }

/**
 * Close the creation role blade.
 */
export const onCancelClick = (storeBlades: StoreBlades) => () => {
  storeBlades.closeLastBlade()
}
