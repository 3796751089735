import type { IGraphQLQuery } from '@libs/graphQL/types'
import type { Query } from '../typeDefs/types'

/**
 * Retrieve the data collection status.
 */
export type QueryRbacDataCollectionStatus = IGraphQLQuery<
  null,
  Pick<Query, 'rbacDataCollectionStatus'>
>

export const queryRbacDataCollectionStatus = `
  query queryRbacDataCollectionStatus {
    rbacDataCollectionStatus {
      node {
        isEnabled
        notWorkingBecauseOf
        sensitiveDataEnabled
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
