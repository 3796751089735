import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import LabelDevianceDescription from '@app/components-legacy/Label/LabelDeviance/LabelDevianceDescription'
import LabelDevianceLabelChecker from '@app/components-legacy/Label/LabelDeviance/LabelDevianceLabelChecker'
import LabelDevianceTitle from '@app/components-legacy/Label/LabelDeviance/LabelDevianceTitle'
import type { EntityChecker } from '@app/entities'
import type EntityAdObjectAsAttributes from '@app/entities/EntityAdObject/EntityAdObjectAsAttribute'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface ILabelDevianceLeftProps {
  adObject?: Maybe<EntityAdObjectAsAttributes>
  className?: string
  description: Maybe<string>
  eventDate: string
  entityChecker: Maybe<EntityChecker>
  eventUrl?: Maybe<string>
  reasonName: string
  ignoreUntil: Maybe<string>
  resolvedAt: Maybe<string>
  expanded: boolean
  toggleExpand: () => void
}

const LabelDevianceContent: React.FC<ILabelDevianceLeftProps> = props => {
  return (
    <ContainerFlex
      className={props.className}
      name="LabelDevianceContent"
      direction="column"
      items={[
        <LabelDevianceTitle
          description={props.description}
          eventDate={props.eventDate}
          eventUrl={props.eventUrl}
          expanded={props.expanded}
          reasonName={props.reasonName}
          ignoreUntil={props.ignoreUntil}
          resolvedAt={props.resolvedAt}
          toggleExpand={props.toggleExpand}
        />,

        props.expanded && (
          <LabelDevianceDescription description={props.description} />
        ),

        props.expanded && props.entityChecker && (
          <LabelDevianceLabelChecker
            adObject={props.adObject}
            entityChecker={props.entityChecker}
          />
        )
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

const mS = consts.marginSmall
const mSer = consts.marginSmaller
const mM = consts.marginMedium
const mD = consts.marginDefault

export default styled(LabelDevianceContent)`
  width: 100%;
  padding: ${props => {
    return props.expanded
      ? `${mS} ${mSer} ${mD} ${mM}`
      : `${mS} ${mSer} ${mS} ${mM}`
  }};
`
