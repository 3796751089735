import type { Maybe } from '@@types/helpers'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import type { Input } from 'antd'
import * as React from 'react'

export interface IUseClipboardOptions {
  refObject?: React.RefObject<typeof Input> | React.RefObject<HTMLDivElement>
  textToCopy?: Maybe<string>
}

/**
 * Hook used to copy some value in the clipboard.
 */
export function useClipboard(options: IUseClipboardOptions) {
  const { storeMessages } = useStores()

  const translate = useAppTranslator({
    namespaces: ['Components.ButtonCopyToClipboard']
  })

  const [copied, setCopied] = React.useState<boolean>(false)

  /**
   * Allow to discard the actuel copied text to be able to copy again.
   */
  const unsetCopied = React.useCallback(() => setCopied(false), [setCopied])

  /**
   * Return a memoized function that allows to copy content from an Input,
   * a HTMLDivElement or the passed `textToCopy` string.
   */
  const copyToClipboard = React.useCallback(() => {
    if (options.textToCopy) {
      copyText(options.textToCopy).catch(() => {
        /* Already handled in copyText */
      })

      return
    }

    const node = options.refObject?.current

    if (!node) {
      return
    }

    if (
      node instanceof HTMLInputElement ||
      (node as any).input instanceof HTMLInputElement // for antd Input ref
    ) {
      copyInputContent(node)
      return
    }

    if (node instanceof HTMLDivElement) {
      copyDivContent(node)
      return
    }
  }, [options.refObject, options.textToCopy, setCopied])

  /**
   * Copy input content into the clipboard.
   */
  const copyInputContent = (inputNode: any & { select: () => void }): void => {
    inputNode.select()

    const success = document.execCommand('copy')

    if (success) {
      setCopied(true)
      return
    }

    storeMessages.error(translate('Cannot copy'), {
      labelledBy: 'couldNotCopy'
    })
  }

  /**
   * Copy div content into the clipboard.
   */
  const copyDivContent = (divNode: HTMLDivElement): void => {
    const selection = window.getSelection && window.getSelection()

    if (!selection) {
      return pushClipboardError()
    }

    const range = document.createRange()
    range.selectNodeContents(divNode)

    // select text
    selection.removeAllRanges()
    selection.addRange(range)

    copyText(selection.toString())
      // unselect text
      .then(() => selection.removeAllRanges())
      .catch(() => {
        /* Already handled in copyText */
      })

    return
  }

  /**
   * Copy text into the clipboard.
   */
  const copyText = (text: string): Promise<void> => {
    // window.clipboardData is IE-specific
    const ieClipboard = (window as any).clipboardData

    if (ieClipboard) {
      const success = ieClipboard.setData('Text', text)

      if (success) {
        setCopied(true)
        return Promise.resolve()
      }

      pushClipboardError()
      return Promise.reject()
    }

    // navigator.clipboard can be undefined according to the security context is
    // (http and not localhost for example)
    const standardClipboard = navigator.clipboard

    if (standardClipboard && window.isSecureContext) {
      return standardClipboard
        .writeText(text)
        .then(() => setCopied(true))
        .catch(() => pushClipboardError())
    }

    if (!window.isSecureContext) {
      const textArea = document.createElement('textarea')
      textArea.value = text
      // make the textarea out of viewport
      textArea.style.left = '-999999px'
      textArea.style.top = '-999999px'
      textArea.style.position = 'absolute'
      textArea.style.opacity = '0'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      return new Promise((resolve, reject) => {
        if (document.execCommand('copy')) {
          setCopied(true)
          resolve()
        } else {
          pushClipboardError()
          reject()
        }

        textArea.remove()
      })
    }

    pushClipboardError()
    return Promise.reject('')
  }

  /**
   * Push an error for the clipbard.
   */
  const pushClipboardError = (): void => {
    storeMessages.error(translate('Cannot copy'), {
      labelledBy: 'couldNotCopy'
    })
  }

  return { translate, copied, unsetCopied, copyToClipboard }
}
