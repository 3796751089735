import type { Maybe } from '@@types/helpers'

/**
 * Convert a number of seconds to a rounded number of minutes.
 */
export function convertToMinutes(seconds: number): Maybe<number> {
  if (seconds < 60) {
    return null
  }

  return Math.round(seconds / 60)
}
