import { ContainerFlex } from '@app/components/Container'
import IconReload from '@app/components/Icon/IconReload'
import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import { FormWrapperSwitch } from '@app/components-legacy/Form/Wrappers'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import InputHealthChecks from '@app/components-legacy/Input/InputHealthChecks'
import InputInfrastructures from '@app/components-legacy/Input/InputInfrastructures'
import { useAppTranslator, useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  handleHealthChecksDomainStatusChecksChange,
  handleHealthChecksDomainStatusDirectoryChange,
  handleHealthChecksDomainStatusRefresh,
  handleHealthChecksDomainStatusShowHealthyChange
} from './handlers'

export interface IHealthCheckDomainStatusFiltersProps {}

function HealthCheckDomainStatusFilters(
  props: IHealthCheckDomainStatusFiltersProps
) {
  const { storeHealthCheck } = useStores()

  const translate = useAppTranslator({
    namespaces: ['HealthCheck.Global', 'HealthCheck.Domains']
  })

  return (
    <ContainerFlex
      name="HealthCheckDomainStatusFilters"
      justifyContent="flex-end"
      alignItems="center"
      items={[
        <label htmlFor="heathCheckShowHealthy">
          <Label variant={LabelVariant.p}>{translate('Show healthy')}</Label>
        </label>,
        <FormWrapperSwitch
          labelledBy="healthCheckShowHealthy"
          switchProps={{
            id: 'heathCheckShowHealthy',
            checked: storeHealthCheck.showHealthy,
            onChange:
              handleHealthChecksDomainStatusShowHealthyChange(storeHealthCheck)
          }}
        />,
        <InputHealthChecks
          storeInputHealthChecks={storeHealthCheck.storeInputHealthChecks}
          onSubmit={handleHealthChecksDomainStatusChecksChange(
            storeHealthCheck
          )}
        />,
        <InputInfrastructures
          storeInfrastructures={storeHealthCheck.storeInfrastructures}
          onValidate={handleHealthChecksDomainStatusDirectoryChange(
            storeHealthCheck
          )}
        />,
        <FormWrapperButton
          labelledBy="healthCheckRefreshButton"
          variant={ButtonVariant.secondary}
          size={ButtonSize.small}
          icon={IconReload}
          buttonProps={{
            onClick: handleHealthChecksDomainStatusRefresh(storeHealthCheck)
          }}
        />
      ]}
      flexGap="small"
    />
  )
}

export default observer(HealthCheckDomainStatusFilters)
