import type { StoreManagementReportingCenter } from '@app/stores'
import { action } from 'mobx'

/**
 * Refresh the report access token and close the drawer.
 */
export const handleAccessTokenRefreshOnSubmit = (
  storeManagementReports: StoreManagementReportingCenter
) =>
  action(() => {
    storeManagementReports
      .refreshReportAccessToken()
      .then(() =>
        storeManagementReports.storeModalConfirmReportAccessTokenRefresh.hide()
      )
      .catch(() => {
        // already caught in the store
      })
  })
