import {
  ContainerFlex,
  ContainerHTMLContent,
  ContainerHVCentered
} from '@app/components-legacy/Container'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { IconNoData } from '@app/components-legacy/Icon'
import { useAppTranslator } from '@app/hooks'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled, { css } from 'styled-components'

export interface IDashboardCreateFirstProps {
  onButtonClick: () => void
}

const ContainerHTMLContentCustom = styled(ContainerHTMLContent)`
  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      margin-top: 30px;
      text-align: center;
    `}
`

const DashboardCreateFirst: React.FC<IDashboardCreateFirstProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Dashboard.Common'] })

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  return (
    <ContainerHVCentered>
      <ContainerFlex
        name="DashboardCreateFirst"
        direction="column"
        alignItems="center"
        items={[
          <IconNoData
            text={
              <ContainerHTMLContentCustom>
                {translate(
                  themeIsLegacy
                    ? 'There is no dashboard configured yet'
                    : "First, let's add dashboards. We have prepared a set of templates for you",
                  {
                    transformMarkdown: true
                  }
                )}
              </ContainerHTMLContentCustom>
            }
            imageName="dashboard-placeholder"
          />,

          <FormWrapperButton
            labelledBy="createFirstDashboard"
            variant={ButtonVariant.primary}
            size={ButtonSize.large}
            buttonProps={{
              onClick: props.onButtonClick
            }}
          >
            {themeIsLegacy
              ? translate('Create your first dashboard')
              : translate('Add dashboards')}
          </FormWrapperButton>
        ]}
        spaced
        spaceWidth="large"
      />
    </ContainerHVCentered>
  )
}

export default DashboardCreateFirst
