import { ProviderType } from '@libs/openapi/service-identity-core'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import * as React from 'react'
import styled from 'styled-components'
import IconAd from './providers/IconAd'
import IconAzureAd from './providers/IconAzureAd'
import IconGCP from './providers/IconGCP'
import IconGithub from './providers/IconGithub'
import IconOkta from './providers/IconOkta'

export interface IIconIdentityProviderProps {
  providerType: ProviderType
  color?: string
  size?: number
  showProviderName?: boolean
}

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
`

/**
 * Display the icon related to a tenant.
 */
export default function IconIdentityProvider(
  props: IIconIdentityProviderProps
) {
  function getIcon() {
    switch (props.providerType) {
      case ProviderType.AzureActiveDirectory:
        return <IconAzureAd color={props.color} size={props.size} />

      case ProviderType.ActiveDirectory:
        return <IconAd color={props.color} size={props.size} />

      case ProviderType.Okta:
        return <IconOkta color={props.color} size={props.size} />

      case ProviderType.GoogleCloudPlatform:
        return <IconGCP color={props.color} size={props.size} />

      case ProviderType.Github:
        return <IconGithub color={props.color} size={props.size} />

      default:
        return assertUnreachableCase(
          props.providerType,
          'ProviderType must be defined in IconIdentityProvider'
        )
    }
  }

  return <StyledIcon data-name="IconIdentityProvider">{getIcon()}</StyledIcon>
}
