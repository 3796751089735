import type { PropertiesNullable } from '@@types/helpers'
import { interpolateTemplate } from '@alsid/common/helpers/template/interpolateTemplate'
import EntityBase from '@app/entities/EntityBase'
import { ensureArray } from '@libs/ensureArray'
import { formatAttributeValueToMarkdown } from '@libs/incriminatingAttributes/formatAttributeValueToMarkdown'
import type { ValueType } from '@libs/valueTypeParser/types'
import type {
  AttackVector,
  AttackVectorAttribute,
  Maybe
} from '@server/graphql/typeDefs/types'

export default class EntityAttackVector
  extends EntityBase
  implements PropertiesNullable<AttackVector>
{
  attributes: Maybe<AttackVectorAttribute[]> = null

  constructor(data: Partial<AttackVector>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Build the vector attack description.
   */
  buildDescription(vectorTemplate: string): Maybe<string> {
    const indexedAttributes = ensureArray(this.attributes).reduce(
      (acc, attr) => {
        acc.set(attr.name, { value: attr.value, valueType: attr.valueType })
        return acc
      },
      new Map<string, { value: string; valueType: string }>()
    )

    const variables = Array.from(indexedAttributes.entries()).reduce(
      (acc, [attrName, attrInfo]) => {
        const valueType = attrInfo.valueType as ValueType

        const replacementValue = formatAttributeValueToMarkdown(
          attrInfo.value,
          valueType
        )

        return {
          ...acc,
          [attrName]: replacementValue
        }
      },
      {}
    )

    try {
      const description = interpolateTemplate(vectorTemplate, variables, null)

      // avoid duplicate spaces, after empty replacements
      return description.replace(/\s+/g, ' ')
    } catch (err) {
      return null
    }
  }
}
