import type { Maybe } from '@@types/helpers'
import { AppRouteName } from '@app/routes'
import type { StoreIoA } from '@app/stores'
import { ATTACKS_MAX_BLADES } from '@app/stores/IoA/consts'
import StoreAttacks from '@app/stores/IoA/StoreAttacks'
import type { AttacksQueryStringParameters } from '@app/stores/IoA/StoreAttacks/types'
import { handleAttacksBladeOnLoad } from './handlers'

/**
 * Add or remove store attacks instances according to attacks filters.
 */
export const createStoreAttacksAccordingFilters = (
  storeIoA: StoreIoA,
  filtersQueryParams: Maybe<AttacksQueryStringParameters[]>
) => {
  if (!Array.isArray(filtersQueryParams)) {
    return
  }

  // create StoreAttacks instances for each filters object
  filtersQueryParams.forEach((filters, index) => {
    const existingStoreAttacks = storeIoA.storesAttacks[index]

    if (existingStoreAttacks && index === ATTACKS_MAX_BLADES - 1) {
      handleBladesLimit(existingStoreAttacks, filters)
    }

    // don't create the store instance if already exists and update query string value in store
    if (existingStoreAttacks) {
      existingStoreAttacks.setQueryStringParameters(filters)
      return
    }

    const storeAttacks = new StoreAttacks(storeIoA.storeRoot)
    storeAttacks.initFiltersStoresFromQueryStringOrPreviousBladeState(filters)

    storeIoA.addNewStoreAttacks(storeAttacks)
  })

  // update the last blade url only when a filter is changed
  if (
    filtersQueryParams.length ===
      storeIoA.storeRoot.stores.storeBlades.bladesAsArray.length - 1 &&
    filtersQueryParams.length === storeIoA.storesAttacks.length
  ) {
    const boardFilters = storeIoA.computeBoardQueryParameters()

    const url = storeIoA.storeRoot.appRouter.makeRouteInfosPathname(
      {
        routeName: AppRouteName.IoA_Attacks,
        parameters: {},
        queryStringParameters: {
          boardFilters,
          attacksFilters: filtersQueryParams
        }
      },
      { arrayFormat: 'indices' }
    )

    storeIoA.storeRoot.stores.storeBlades.updateLastBladeUrl(url)
  }

  // when closing some blades, it will remove some filters in the querytring,
  // then StoreAttacks have to be removed too
  storeIoA.removeStoreAttacksFromIndex(filtersQueryParams.length)
}

function handleBladesLimit(
  storeAttacks: StoreAttacks,
  filters: AttacksQueryStringParameters
) {
  const existingQs = storeAttacks.queryStringParameters

  // replace the blade content if the maximum blades number is reached and the resource has changed
  if (
    filters.resourceType !== existingQs.resourceType ||
    filters.resourceValue !== existingQs.resourceValue
  ) {
    storeAttacks.initFiltersStoresFromQueryStringOrPreviousBladeState(filters)

    handleAttacksBladeOnLoad(storeAttacks)()

    return
  }

  // refresh the view if the user clicks on the same resource than the current one
  if (JSON.stringify(existingQs) === JSON.stringify(filters)) {
    storeAttacks.reloadAttacks()
  }

  return
}
