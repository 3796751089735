import {
  ContainerContent,
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useAppRouter, useAppTranslator, useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import AuthPlaceHolders from '../AuthPlaceHolders'
import EulaContentCheckbox from './EulaContentCheckbox'
import EulaContentLicense from './EulaContentLicense'
import { onApproveEulaClick, onCancelEulaClick } from './handlers'

interface IEulaPageProps {}

const EulaPage: React.FC<IEulaPageProps> = () => {
  const translate = useAppTranslator({ namespaces: ['Buttons', 'Login.EULA'] })

  const appRouter = useAppRouter()

  const { storeAuthentication, storeEula } = useStores()

  return (
    <AuthPlaceHolders
      layout={{
        content: (
          <ContainerContent
            flags={storeAuthentication.storeFlagsProcessLogin.flags}
            spinner={<SpinnerInline />}
          >
            <EulaContentLicense />
          </ContainerContent>
        ),
        footer: (
          <ContainerFooter>
            <FormWrapperButton
              labelledBy="refuse"
              buttonProps={{
                onClick: onCancelEulaClick(appRouter)
              }}
            >
              {translate('Refuse')}
            </FormWrapperButton>

            <ContainerFlex
              name="EulaPageFooterSubmit"
              alignItems="center"
              flexWrap="nowrap"
              items={[
                <EulaContentCheckbox />,

                <FormWrapperButton
                  labelledBy="approve"
                  buttonProps={{
                    type: 'primary',
                    disabled: !storeEula.hasRead,
                    onClick: onApproveEulaClick(storeEula)
                  }}
                >
                  {translate('Approve')}
                </FormWrapperButton>
              ]}
            />
          </ContainerFooter>
        )
      }}
    />
  )
}

export default observer(EulaPage)
