// Tenable Cloud API Key configuration
export enum AzureADSupportConfigurationFormFieldName {
  accessKey = 'accessKey',
  secretKey = 'secretKey'
}

export enum ApiTokensStatus {
  working = 'working',
  notWorking = 'notWorking',
  invalidCredentials = 'invalidCredentials',
  notAdmin = 'notAdmin'
}
