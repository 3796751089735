import { ContainerFlex } from '@app/components/Container'
import type { IIconDirectionProps } from '@app/components/Icon/IconDirection'
import IconDirection, { Direction } from '@app/components/Icon/IconDirection'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { CardTitleWithIcons } from '@app/components-legacy/Card/CardTitleWithIcons'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import LabelDate from '@app/components-legacy/Label/LabelDate'
import type EntityHealthCheckDomainStatusReason from '@app/entities/EntityHealthCheckDomainStatusReason'
import { useAppTranslator } from '@app/hooks'
import { HealthCheckStatusIcon } from '@app/pages/HealthCheck/HealthCheckStatusIcon'
import HealthCheckReasonHeaderLabel from '@app/pages/HealthCheck/Labels/HealthCheckReasonLabel'
import { HealthCheckReasonLocale } from '@app/pages/HealthCheck/Labels/types'
import { Tooltip } from 'antd'
import * as React from 'react'
import { formatHealthCheckAttributes } from '../helpers'

interface IHealthCheckReasonHeaderProps {
  healthCheckName: string
  reason: EntityHealthCheckDomainStatusReason
  expanded: boolean
  onExpand: () => void
}

export function HealthCheckReasonHeader(props: IHealthCheckReasonHeaderProps) {
  const translate = useAppTranslator({
    namespaces: ['HealthCheck.Domains', 'HealthCheck.Details']
  })

  const reasonName = props.reason.getPropertyAsString('name')

  const interpolations = formatHealthCheckAttributes(
    props.reason.getAttributes()
  )

  const iconProps: IIconDirectionProps = {
    direction: props.expanded ? Direction.down : Direction.right
  }

  return (
    <CardTitleWithIcons
      title={
        <ContainerFlex
          name="HealthCheckReasonHeaderTitle"
          alignItems="center"
          itemsFlexShrink={[0, 1]}
          items={[
            <HealthCheckStatusIcon status={props.reason.status} withTooltip />,
            <HealthCheckReasonHeaderLabel
              locale={HealthCheckReasonLocale.name}
              healthCheckName={props.healthCheckName}
              reasonName={reasonName || ''}
              interpolations={interpolations}
              pureText
            />
          ]}
          flexGap="small"
        />
      }
      preIconsElements={[
        <ContainerFlex
          name="HealthCheckReasonHeaderLastCheckDate"
          alignItems="center"
          items={[
            <Label variant={LabelVariant.h3}>{translate('Last check')}</Label>,
            <Label variant={LabelVariant.p}>
              <LabelDate date={props.reason.lastCheckDate} />
            </Label>
          ]}
          flexGap="small"
        />,
        <ContainerFlex
          name="HealthCheckReasonHeaderStatusSince"
          alignItems="center"
          items={[
            <Label variant={LabelVariant.h3}>
              {translate('Status since')}
            </Label>,
            <Label variant={LabelVariant.p}>
              <LabelDate date={props.reason.statusSince} />
            </Label>
          ]}
          flexGap="small"
        />
      ]}
      icons={[
        <Tooltip
          title={translate(props.expanded ? 'Hide details' : 'Show details')}
          mouseEnterDelay={0.5}
        >
          <FormWrapperButton
            labelledBy="HealthCheckExpandButton"
            variant={ButtonVariant.colored}
            size={ButtonSize.small}
            icon={IconDirection}
            iconProps={iconProps}
            buttonProps={{ onClick: props.onExpand }}
          />
        </Tooltip>
      ]}
    />
  )
}
