import { useAppTranslator } from '@app/hooks'
import * as React from 'react'
import HealthCheckLabel from './HealthCheckLabel'
import type {
  HealthCheckReasonLocale,
  IHealthCheckBaseLabelProps
} from './types'

export interface IHealthCheckReasonLabelProps
  extends IHealthCheckBaseLabelProps {
  locale: HealthCheckReasonLocale
  healthCheckName: string
  reasonName: string
}

const HealthCheckReasonLabel: React.FC<
  IHealthCheckReasonLabelProps
> = props => {
  const translate = useAppTranslator({
    namespaces: [
      `HealthCheck.HealthChecks.${props.healthCheckName}.reasons.${props.reasonName}`
    ],
    fallback: '**?**'
  })

  return (
    <HealthCheckLabel
      locale={props.locale}
      maxWidth={props.maxWidth}
      maxWidthAuto={props.maxWidthAuto}
      labelProps={props.labelProps}
      translate={translate}
      transformMarkdown={props.transformMarkdown}
      pureText={props.pureText}
      interpolations={props.interpolations}
    />
  )
}

export default HealthCheckReasonLabel
