import { BladesContainer } from '@app/components-legacy/Blade'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LayoutDefault } from '@app/components-legacy/Layout'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import * as React from 'react'
import ActivityLogsPage from './ActivityLogs/ActivityLogsPage'
import { canAccessToActivityLogs } from './ActivityLogs/permissions'
import ProfilesPage from './Profiles/ProfilesPage'
import { canAccessToProfiles } from './Profiles/ProfilesPage/permissions'
import { canAccessToRoles } from './Roles/permissions'
import RolesPage from './Roles/RolesPage'
import UsersPage from './Users/UsersPage'
import { canAccessToAccountUsers } from './Users/UsersPage/permissions'

interface IAccountsPageProps {}

const AccountsPage: React.FC<IAccountsPageProps> = () => {
  const { storeRbac } = useStores()

  return (
    <LayoutDefault
      content={
        <ContainerFlex
          name="Accounts"
          direction="column"
          items={[
            <BladesContainer>
              <RbacRoutes
                routes={[
                  {
                    routeDefinition: {
                      routeName: AppRouteName.Management_Accounts_Users,
                      parameters: {}
                    },
                    component: UsersPage,
                    rbacPermissionsCheck: () =>
                      canAccessToAccountUsers(storeRbac)
                  },
                  {
                    routeDefinition: {
                      routeName: AppRouteName.Management_Accounts_Roles,
                      parameters: {}
                    },
                    component: RolesPage,
                    rbacPermissionsCheck: () => canAccessToRoles
                  },
                  {
                    routeDefinition: {
                      routeName: AppRouteName.Management_Accounts_Profiles,
                      parameters: {}
                    },
                    component: ProfilesPage,
                    rbacPermissionsCheck: () => canAccessToProfiles
                  },
                  {
                    routeDefinition: {
                      routeName: AppRouteName.ActivityLogs,
                      parameters: {}
                    },
                    component: ActivityLogsPage,
                    rbacPermissionsCheck: () => canAccessToActivityLogs
                  }
                ]}
                redirect
              />
            </BladesContainer>
          ]}
        />
      }
    />
  )
}

export default AccountsPage
