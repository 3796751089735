export function thisStringRepresentsANumber(str: string): boolean {
  return /^[-+]?[0-9]*[.,]?[0-9]+([eE][-+]?[0-9]+)?$/.test(str)
}

export function toNumber(str: string): number | null {
  if (thisStringRepresentsANumber(str)) {
    return Number(str.replace(',', '.'))
  } else {
    return null
  }
}
