import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import { ContainerFooter } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { ButtonSize } from '@app/components-legacy/Form/Wrappers/types'
import { Modal, ModalCancelButton } from '@app/components-legacy/Modal'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onDeleteModalConfirmClick } from './handlers'

export interface ISearchHistoryConfirmDeleteModalProps {}

const SearchHistoryConfirmDeleteModal: React.FC<
  ISearchHistoryConfirmDeleteModalProps
> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const translate = useAppTranslator({
    namespaces: ['Titles', 'Buttons', 'TrailFlow.SearchHistory']
  })

  const { storeTrailFlow } = useStores()

  const { storeSearchHistory } = storeTrailFlow
  const { storeInputSearch, storeDatePicker } = storeSearchHistory

  const isDeletingWithFilters =
    (storeInputSearch.hasSearchValue && !storeInputSearch.isDirty) ||
    storeDatePicker.datePickerRangeValueAsIsoStrings

  const confirmSentence = isDeletingWithFilters
    ? translate(
        'Are you sure you want to delete this selection of your search history'
      )
    : translate('Are you sure you want to delete your entire search history')

  return (
    <Modal
      modalProps={{
        title: translate('Confirmation'),
        footer: (
          <ContainerFooter variant="modal" withBoxShadow={false}>
            <ModalCancelButton
              storeModal={
                storeTrailFlow.storeSearchHistory.storeModalConfirmDeleteHistory
              }
            />

            <FormWrapperButton
              labelledBy="submit"
              size={ButtonSize.large}
              buttonProps={{
                type: 'primary',
                onClick: onDeleteModalConfirmClick(
                  storeTrailFlow.storeSearchHistory
                )
              }}
            >
              {translate('Confirm')}
            </FormWrapperButton>
          </ContainerFooter>
        )
      }}
      storeModal={
        storeTrailFlow.storeSearchHistory.storeModalConfirmDeleteHistory
      }
    >
      {themeIsLegacy ? (
        <p>{confirmSentence}</p>
      ) : (
        <Label variant={LabelVariant.p}>{confirmSentence}</Label>
      )}
    </Modal>
  )
}

export default observer(SearchHistoryConfirmDeleteModal)
