import {
  ContainerContent,
  ContainerFooter,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import {
  DrawerContext,
  useDrawerContext
} from '@app/components-legacy/Drawer/context'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { InputCheckers } from '@app/components-legacy/Input'
import { LabelMissingData } from '@app/components-legacy/Label'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreAttacks from '@app/stores/IoA/StoreAttacks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onDrawerAttacksRemoveFiltersAddValidate } from './handlers'
import { useStoreInputGenericCheckersSetup } from './hooks'

interface IIoADrawerAttacksRemoveFiltersAddContentProps {
  className?: string
  storeAttacks: StoreAttacks
}

const IoADrawerAttacksRemoveFiltersAddContent: React.FC<
  IIoADrawerAttacksRemoveFiltersAddContentProps
> = props => {
  const contextValue = useDrawerContext(DrawerContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoA.Attacks']
  })

  const { storeInputGenericCheckers } = useStoreInputGenericCheckersSetup()

  if (!contextValue) {
    return <LabelMissingData />
  }

  return (
    <FormWrapper
      name="attackFiltersDeletion"
      onSubmit={onDrawerAttacksRemoveFiltersAddValidate(
        props.storeAttacks,
        storeInputGenericCheckers
      )}
    >
      <ContainerContent>
        <ContainerHTMLContent padded>
          {translate('When removing a specific attack', {
            transformMarkdown: true,
            interpolations: {
              resourceType: props.storeAttacks.resourceTypeTranslation,
              resourceValue: props.storeAttacks.resourceValueTranslation
            }
          })}
        </ContainerHTMLContent>

        <InputCheckers
          storeInputGenericCheckers={storeInputGenericCheckers}
          loadAtMount
        />
      </ContainerContent>

      <Portal name={PlaceHolderName.drawerFooter}>
        <ContainerFooter>
          <DrawerCancelButton />

          <FormWrapperButtonSubmit
            loading={props.storeAttacks.storeFlagsRemoveAttacks.flags.isLoading}
          >
            {translate('Remove incidents')}
          </FormWrapperButtonSubmit>
        </ContainerFooter>
      </Portal>
    </FormWrapper>
  )
}

export default observer(IoADrawerAttacksRemoveFiltersAddContent)
