import {
  createEntities,
  createEntity,
  EntityPagination,
  EntityRbacRole,
  EntityUser
} from '@app/entities'
import type { IDataRowRbacRole } from '@app/entities/EntityRbacRole'
import type { IDataRowUser } from '@app/entities/EntityUser'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import { isErrorOfType } from '@libs/errors/functions'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { ErrorName } from '@libs/errors/types'
import { isDefinedAndNotEmptyString } from '@libs/isDefined'
import { checkRbac } from '@libs/rbac/functions'
import type {
  MutationCreateUser,
  MutationDeleteUser,
  MutationEditUserRoles,
  MutationUpdateUser
} from '@server/graphql/mutations/user'
import {
  mutationCreateUser,
  mutationDeleteUser,
  mutationEditUser,
  mutationEditUserRoles
} from '@server/graphql/mutations/user'
import type { QueryUsers } from '@server/graphql/queries/management'
import { queryUsers } from '@server/graphql/queries/management'
import type {
  InputCreateUser,
  InputEditUser,
  Maybe,
  Pagination,
  RbacRole,
  User
} from '@server/graphql/typeDefs/types'
import { RequestErrorCode } from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import type { StoreRoot } from '..'
import StoreDrawer from '../helpers/StoreDrawer'
import StoreFlags from '../helpers/StoreFlags'
import StoreForm from '../helpers/StoreForm'
import {
  emailFormat,
  equalTo,
  mandatory,
  userPasswordFormat
} from '../helpers/StoreForm/validators'
import StoreModal from '../helpers/StoreModal'
import StoreWidgetList from '../helpers/StoreWidgetList'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'

export enum UserFormFieldName {
  formVersion = 'formVersion',
  provider = 'provider',
  surname = 'surname',
  name = 'name',
  email = 'email',
  password = 'password',
  passwordConfirmation = 'passwordConfirmation',
  department = 'department',
  biography = 'biography',
  active = 'active',
  lockedOut = 'lockedOut'
}

export default class StoreUsers extends StoreBase {
  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Errors', 'Errors.Form', 'Management.Accounts.Users']
  })

  public storeInputSearchRbacRoles = new StoreInputSearch(this.storeRoot)

  public storeWidgetUsersList = new StoreWidgetList<EntityUser, IDataRowUser>(
    this.storeRoot,
    {
      selectable: false
    }
  )

  public storeWidgetRbacRolesList = new StoreWidgetList<
    EntityRbacRole,
    IDataRowRbacRole
  >(this.storeRoot, {
    selectable: true,
    filterEntitiesFn: this.filterRbacRolesEntities.bind(this)
  })

  public storeForm: StoreForm<UserFormFieldName> =
    new StoreForm<UserFormFieldName>(this.storeRoot, {
      setup: {
        fields: {
          // hidden field
          [UserFormFieldName.formVersion]: {
            label: 'Form version',
            inputType: InputType.hidden
          },
          [UserFormFieldName.provider]: {
            label: 'Authentication type',
            validators: [mandatory()]
          },
          [UserFormFieldName.name]: {
            label: 'Name',
            validators: [mandatory()]
          },
          [UserFormFieldName.surname]: {
            label: 'Surname',
            validators: [mandatory()]
          },
          [UserFormFieldName.email]: {
            label: 'Email',
            validators: [mandatory(), emailFormat()]
          },
          [UserFormFieldName.password]: {
            label: 'Password',
            validators: [
              mandatory({
                isValid: value => {
                  const isFormEdition =
                    this.storeForm.getFieldValueAsString(
                      UserFormFieldName.formVersion
                    ) !== 'creation'

                  // not mandatory when editing a user
                  if (isFormEdition) {
                    return true
                  }

                  return isDefinedAndNotEmptyString(value)
                }
              }),
              userPasswordFormat()
            ]
          },
          [UserFormFieldName.passwordConfirmation]: {
            label: 'Password confirmation',
            validators: [
              mandatory({
                isValid: value => {
                  const isFormEdition =
                    this.storeForm.getFieldValueAsString(
                      UserFormFieldName.formVersion
                    ) !== 'creation'
                  // not mandatory when editing a user
                  if (isFormEdition) {
                    return true
                  }
                  return isDefinedAndNotEmptyString(value)
                }
              }),
              equalTo(() =>
                this.storeForm.getFieldValueAsString(UserFormFieldName.password)
              )({
                onError: () =>
                  this.translate('The password confirmation is incorrect')
              })
            ]
          },
          [UserFormFieldName.department]: {
            label: 'Department'
          },
          [UserFormFieldName.biography]: {
            label: 'Biography'
          },
          [UserFormFieldName.active]: {
            label: 'Active',
            inputType: InputType.switch
          },
          [UserFormFieldName.lockedOut]: {
            label: 'LockedOut',
            inputType: InputType.switch
          }
        }
      }
    })

  public storeFetchUsersFlags = new StoreFlags(this.storeRoot)
  public storeCreateUserFlags = new StoreFlags(this.storeRoot)
  public storeEditUserFlags = new StoreFlags(this.storeRoot)
  public storeSetRolesFlags = new StoreFlags(this.storeRoot)
  public storeDeletionFlags = new StoreFlags(this.storeRoot)

  public storeDeleteDrawer = new StoreDrawer<{ userDataRow: IDataRowUser }>(
    this.storeRoot
  )

  public storeModalToneUserCreation = new StoreModal(this.storeRoot)

  /* Observables */

  private $users = observable.map<number, EntityUser>()
  private $rbacRoles = observable.map<number, EntityRbacRole>()
  private $rbacUserCreationDefaultRoles = observable.box<EntityRbacRole[]>([])

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch users.
   */
  fetchUsers(_args?: QueryUsers['args']): Promise<void> {
    const args: QueryUsers['args'] = {
      usersPage: this.storeWidgetUsersList.paginationPage,
      usersPerPage: this.storeWidgetUsersList.rowsPerPage,
      ..._args
    }

    this.storeFetchUsersFlags.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryUsers>(queryUsers, args)
      })
      .then(({ users, rbacRoles, rbacUserCreationDefaultsRbac }) => {
        if (!users) {
          throw new Error('Users are not defined')
        }

        /* Set users */

        const usersEntities = createEntities<User, EntityUser>(
          EntityUser,
          users.node
        )

        const usersPagination = createEntity<Pagination, EntityPagination>(
          EntityPagination,
          users.pagination
        )

        this.setUsers(usersEntities)

        this.storeWidgetUsersList.setEntities(usersEntities)
        this.storeWidgetUsersList.setPagination(usersPagination)

        /* Set roles */

        if (checkRbac(this.storeRoot)(rbacRoles)) {
          const rbacRolesEntities = createEntities<RbacRole, EntityRbacRole>(
            EntityRbacRole,
            rbacRoles.node
          )

          this.setRbacRoles(rbacRolesEntities)

          // set entities for the list
          this.storeWidgetRbacRolesList.setEntities(
            Array.from(this.$rbacRoles.values())
          )

          // set user default creation roles
          // (allow to select the roles by default when creating a new user)
          if (checkRbac(this.storeRoot)(rbacUserCreationDefaultsRbac)) {
            const rbacUserCreationDefaultRoles = createEntities<
              RbacRole,
              EntityRbacRole
            >(EntityRbacRole, rbacUserCreationDefaultsRbac.node)

            this.$rbacUserCreationDefaultRoles.set(rbacUserCreationDefaultRoles)
          }
        }

        this.storeFetchUsersFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFetchUsersFlags))
  }

  /**
   * Create an user,
   * Refetch users.
   */
  createUser(user: InputCreateUser): Promise<Maybe<number> | void> {
    let userId: Maybe<number> = null

    this.storeCreateUserFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: MutationCreateUser['args'] = {
          user
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationCreateUser>(mutationCreateUser, args)
      })
      .then(results => {
        // set the userId here to be able to return it at the end of promises chain
        userId = results.createUser.id
        return this.fetchUsers()
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('User X created', {
            interpolations: {
              userName: user.name
            }
          }),
          {
            labelledBy: 'userCreated'
          }
        )

        this.storeCreateUserFlags.success()

        return userId
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeCreateUserFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when creating the user'
        })
      )
  }

  /**
   * Edit an user.
   */
  editUser(user: InputEditUser): Promise<any> {
    this.storeEditUserFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: MutationUpdateUser['args'] = {
          user
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationUpdateUser>(mutationEditUser, args)
      })
      .then(() => {
        return this.fetchUsers()
      })
      .then(() => {
        if (user.name) {
          this.storeRoot.stores.storeMessages.success(
            this.translate('User X updated', {
              interpolations: {
                userName: user.name
              }
            }),
            {
              labelledBy: 'userUpdated'
            }
          )
        }

        this.storeEditUserFlags.success()
      })
      .catch(err => {
        // if the user is deactivated whereas it's the latest having the
        // Administrator Global role, Eridanis will return a 403
        if (isErrorOfType(err, ErrorName.ForbiddenAccessError)) {
          this.storeEditUserFlags.reset()

          this.storeRoot.stores.storeMessages.error(
            this.translate(
              `You can't remove the Administrator role of this user since you would loose all administration rights`
            ),
            {
              labelledBy: 'removeAdminRoleError'
            }
          )
          throw err
        }

        handleStoreError(this.storeRoot, this.storeEditUserFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when editing the user'
        })(err)
      })
  }

  /**
   * Remove locked out status.
   */
  removeLockedOutStatus(userId: number): Promise<any> {
    this.storeEditUserFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: MutationUpdateUser['args'] = {
          user: {
            id: userId,
            lockedOut: false
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationUpdateUser>(mutationEditUser, args)
      })
      .then(() => {
        return this.fetchUsers()
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('The account has been unlocked'),
          {
            labelledBy: 'userLockedOutStatusRemoved'
          }
        )

        this.storeEditUserFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeEditUserFlags))
  }

  /**
   * Delete a user.
   */
  deleteUser(userId: number, userName: string): Promise<any> {
    this.storeDeletionFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: MutationDeleteUser['args'] = {
          user: {
            id: userId
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationDeleteUser>(mutationDeleteUser, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('User X deleted', {
            interpolations: {
              userName
            }
          }),
          {
            labelledBy: 'userDeleted'
          }
        )

        this.storeDeletionFlags.success()
      })
      .catch((err: Error) => {
        // if the user is deleted whereas it's the latest having the
        // Administrator Global role, Eridanis will return a 403
        if (
          isErrorOfType(err, ErrorName.ForbiddenAccessError) &&
          err.message === RequestErrorCode.LastAdminRemoval
        ) {
          this.storeDeletionFlags.reset()

          this.storeRoot.stores.storeMessages.error(
            this.translate(
              `You can't remove the Administrator role of this user since you would loose all administration rights`
            ),
            {
              labelledBy: 'removeAdminError'
            }
          )
          throw err
        }

        handleStoreError(this.storeRoot, this.storeDeletionFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when deleting the user'
        })
      })
  }

  /**
   * Edit the roles of a user.
   */
  setUserRoles(args: MutationEditUserRoles['args']): Promise<any> {
    this.storeSetRolesFlags.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationEditUserRoles>(mutationEditUserRoles, args)
      })
      .then(() => this.fetchUsers())
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Roles assigned to the user'),
          {
            labelledBy: 'rolesAssigned'
          }
        )

        this.storeSetRolesFlags.success()
      })
      .catch(err => {
        // if the Global Administrator role is removed on the latest user having it,
        // Eridanis will return a 403
        if (isErrorOfType(err, ErrorName.ForbiddenAccessError)) {
          this.storeSetRolesFlags.reset()

          this.storeRoot.stores.storeMessages.error(
            this.translate(
              `You can't remove the Administrator role of this user since you would loose all administration rights`
            ),
            {
              labelledBy: 'removeAdminRoleError'
            }
          )
          throw err
        }

        handleStoreError(this.storeRoot, this.storeSetRolesFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when setting roles on a user'
        })(err)
      })
  }

  /**
   * Return Rbac roles entities filtered according to the InputSearch value.
   */
  filterRbacRolesEntities(entities: EntityRbacRole[]): EntityRbacRole[] {
    return entities.filter(entity => {
      const search =
        this.storeInputSearchRbacRoles.transformedSearchValueAsRegexp

      if (!search) {
        return true
      }

      return (
        search.test(entity.getPropertyAsString('id')) ||
        search.test(entity.getPropertyAsString('name')) ||
        search.test(entity.getPropertyAsString('description'))
      )
    })
  }

  /**
   * Return true if the userId if the one actually logged in.
   */
  isCurrentUserLoggedIn(userId: Maybe<number>): boolean {
    return this.storeRoot.stores.storeAuthentication.whoAmI?.id === userId
  }

  /**
   * Return true if the userId is the Admin user.
   */
  isAdminUser(userId: number): boolean {
    return userId === 1
  }

  /**
   * Actions
   */

  @action
  reset(): this {
    this.storeForm.hardReset()

    this.storeFetchUsersFlags.reset()
    this.storeCreateUserFlags.reset()
    this.storeEditUserFlags.reset()
    this.storeSetRolesFlags.reset()
    this.storeDeletionFlags.reset()

    return this
  }

  @action
  setUsers(users: EntityUser[]): this {
    this.$users.clear()

    users.forEach(entity => {
      if (entity.id) {
        this.$users.set(entity.id, entity)
      }
    })

    return this
  }

  @action
  setRbacRoles(rbacRoles: EntityRbacRole[]): this {
    this.$rbacRoles.clear()

    rbacRoles.forEach(entity => {
      if (entity.id) {
        this.$rbacRoles.set(entity.id, entity)
      }
    })

    return this
  }

  /**
   * Computed
   */

  @computed
  get users(): Map<number, EntityUser> {
    return toJS(this.$users)
  }

  @computed
  get rbacRoles(): Map<number, EntityRbacRole> {
    return toJS(this.$rbacRoles)
  }

  @computed
  get rbacUserCreationDefaultRoles(): EntityRbacRole[] {
    return this.$rbacUserCreationDefaultRoles.get()
  }
}
