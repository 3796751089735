import { assertUnreachableCase } from '@productive-codebases/toolbox'
import * as React from 'react'
import LabelSlicedContainer from './LabelSlicedContainer'
import LabelSlicedPopoverContent from './LabelSlicedPopoverContent'
import LabelSlicedTooltipContent from './LabelSlicedTooltipContent'
import type { ILabelSlicedProps } from './types'

const LabelSliced: React.FC<ILabelSlicedProps> = _props => {
  const props: ILabelSlicedProps = {
    popoverType: 'tooltip',
    ..._props
  }

  const isValueSliced =
    props.maxWidthAuto ||
    props.maxWidth ||
    !props.maxLength ||
    (props.maxLength && props.value.length > props.maxLength)

  const popoverType = !isValueSliced
    ? // do not render popover if the value has not been sliced
      'none'
    : props.popoverType

  switch (popoverType) {
    case 'none':
      return <LabelSlicedContainer {...props} />

    case 'popover':
      return <LabelSlicedPopoverContent {...props} />

    case 'tooltip':
      return <LabelSlicedTooltipContent {...props} />

    default:
      assertUnreachableCase(popoverType)
  }
}

export default LabelSliced
