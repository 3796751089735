import * as React from 'react'
import * as uuid from 'uuid'

interface IOptionAttr {
  label?: string
  value: string
}

interface IOptOptionAttr {
  label?: string
  value: string
  children: IOptionAttr[]
}

export function getOptionAttributes(): React.ReactNode[] {
  return attributes.map(optGroup => {
    const options = optGroup.children.map(option => {
      return (
        <option
          key={`${optGroup.value}-${option.value}-${uuid.v4()}`}
          value={option.value}
        >
          {option.label || option.value}
        </option>
      )
    })

    return (
      <optgroup
        key={`${optGroup.value}-${uuid.v4()}`}
        label={optGroup.label || optGroup.value}
      >
        {options}
      </optgroup>
    )
  })
}

const attributes: IOptOptionAttr[] = [
  {
    value: 'AdminSDHolder',
    children: []
  },
  {
    value: 'attributeSchema',
    children: [
      {
        value: 'adminDescription'
      },
      {
        value: 'adminDisplayName'
      },
      {
        value: 'attributeSecurityGUID'
      },
      {
        value: 'attributeSyntax'
      },
      {
        value: 'isDefunct'
      },
      {
        value: 'isMemberOfPartialAttributeSet'
      },
      {
        value: 'isSingleValued'
      },
      {
        value: 'lDAPDisplayName'
      },
      {
        value: 'schemaFlagsEx'
      },
      {
        value: 'schemaIDGUID'
      },
      {
        value: 'searchFlags'
      },
      {
        value: 'systemFlags'
      },
      {
        value: 'systemOnly'
      }
    ]
  },
  {
    value: 'certificationAuthority',
    children: [
      {
        value: 'cACertificate'
      },
      {
        value: 'cACertificateDN'
      },
      {
        value: 'cAConnect'
      },
      {
        value: 'cAUsages'
      },
      {
        value: 'cAWEBURL'
      },
      {
        value: 'cRLObject'
      },
      {
        value: 'currentParentCA'
      },
      {
        value: 'dNSHostName'
      },
      {
        value: 'domainID'
      },
      {
        value: 'domainPolicyObject'
      },
      {
        value: 'enrollmentProviders'
      },
      {
        value: 'parentCA'
      },
      {
        value: 'parentCACertificateChain'
      },
      {
        value: 'pendingCACertificates'
      },
      {
        value: 'pendingParentCA'
      },
      {
        value: 'previousCACertificates'
      },
      {
        value: 'previousParentCA'
      },
      {
        value: 'signatureAlgorithms'
      },
      {
        value: 'supportedApplicationContext'
      }
    ]
  },
  {
    value: 'classSchema',
    children: [
      {
        value: 'adminDescription'
      },
      {
        value: 'adminDisplayName'
      },
      {
        value: 'auxiliaryClass'
      },
      {
        value: 'classDisplayName'
      },
      {
        value: 'defaultObjectCategory'
      },
      {
        value: 'defaultSecurityDescriptor'
      },
      {
        value: 'isDefunct'
      },
      {
        value: 'lDAPDisplayName'
      },
      {
        value: 'mayContain'
      },
      {
        value: 'msDs-Schema-Extensions'
      },
      {
        value: 'mustContain'
      },
      {
        value: 'objectClassCategory'
      },
      {
        value: 'possSuperiors'
      },
      {
        value: 'subClassOf'
      },
      {
        value: 'systemAuxiliaryClass'
      },
      {
        value: 'systemFlags'
      },
      {
        value: 'systemMayContain'
      },
      {
        value: 'systemMustContain'
      },
      {
        value: 'schemaIDGUID'
      },
      {
        value: 'systemOnly'
      },
      {
        value: 'systemPossSuperiors'
      }
    ]
  },
  {
    value: 'computer',
    children: [
      {
        value: 'dNSHostName'
      },
      {
        value: 'location'
      },
      {
        value: 'managedBy'
      },
      {
        value: 'msDS-AdditionalDnsHostName'
      },
      {
        value: 'msDS-AdditionalSamAccountName'
      },
      {
        value: 'msDS-AssignedAuthNPolicy'
      },
      {
        value: 'msDS-AssignedAuthNPolicySilo'
      },
      {
        value: 'msDS-HostServiceAccount'
      },
      {
        value: 'msDS-KrbTgtLink'
      },
      {
        value: 'msDS-NeverRevealGroup'
      },
      {
        value: 'msDS-RevealOnDemandGroup'
      },
      {
        value: 'msTPM-TpmInformationForComputer'
      },
      {
        value: 'operatingSystem'
      },
      {
        value: 'operatingSystemHotfix'
      },
      {
        value: 'operatingSystemServicePack'
      },
      {
        value: 'operatingSystemVersion'
      },
      {
        value: 'policyReplicationFlags'
      }
    ]
  },
  {
    value: 'configuration',
    children: [
      {
        value: 'gPLink'
      },
      {
        value: 'gPOptions'
      }
    ]
  },
  {
    value: 'container',
    children: []
  },
  {
    value: 'controlAccessRight',
    children: [
      {
        value: 'appliesTo'
      },
      {
        value: 'rightsGuid'
      },
      {
        value: 'validAccesses'
      },
      {
        value: 'localizationDisplayId'
      }
    ]
  },
  {
    value: 'cRLDistributionPoint',
    children: [
      {
        value: 'name'
      },
      {
        value: 'certificateRevocationList'
      },
      {
        value: 'authorityRevocationList'
      },
      {
        value: 'deltaRevocationList'
      }
    ]
  },
  {
    value: 'crossRef',
    children: [
      {
        value: 'dnsRoot'
      },
      {
        value: 'msDS-Behavior-Version'
      },
      {
        value: 'name'
      },
      {
        value: 'nCName'
      },
      {
        value: 'nETBIOSName'
      },
      {
        value: 'nTMixedDomain'
      },
      {
        value: 'rootTrust'
      },
      {
        value: 'trustParent'
      }
    ]
  },
  {
    value: 'crossRefContainer',
    children: [
      {
        value: 'msDS-Behavior-Version'
      },
      {
        value: 'msDS-EnabledFeature'
      },
      {
        value: 'name'
      }
    ]
  },
  {
    value: 'dHCPClass',
    children: [
      {
        value: 'name'
      }
    ]
  },
  {
    value: 'dMD',
    children: [
      {
        value: 'dmdName'
      },
      {
        value: 'msDs-Schema-Extensions'
      },
      {
        value: 'objectVersion'
      },
      {
        value: 'schemaInfo'
      }
    ]
  },
  {
    value: 'dnsNode',
    children: [
      {
        value: 'dc'
      },
      {
        value: 'dnsRecord'
      },
      {
        value: 'dNSTombstoned'
      },
      {
        value: 'name'
      }
    ]
  },
  {
    value: 'dnsZone',
    children: [
      {
        value: 'dNSProperty'
      }
    ]
  },
  {
    value: 'domainDNS',
    children: [
      {
        value: 'auditingPolicy'
      },
      {
        value: 'builtinCreationTime'
      },
      {
        value: 'cACertificate'
      },
      {
        value: 'controlAccessRights'
      },
      {
        value: 'creationTime'
      },
      {
        value: 'defaultLocalPolicyObject'
      },
      {
        value: 'domainPolicyObject'
      },
      {
        value: 'domainReplica'
      },
      {
        value: 'eFSPolicy'
      },
      {
        value: 'forceLogoff'
      },
      {
        value: 'gPLink'
      },
      {
        value: 'gPOptions'
      },
      {
        value: 'lockoutDuration'
      },
      {
        value: 'lockOutObservationWindow'
      },
      {
        value: 'lockoutThreshold'
      },
      {
        value: 'managedBy'
      },
      {
        value: 'maxPwdAge'
      },
      {
        value: 'minPwdAge'
      },
      {
        value: 'minPwdLength'
      },
      {
        value: 'ms-DS-MachineAccountQuota'
      },
      {
        value: 'msDS-AllowedDNSSuffixes'
      },
      {
        value: 'msDS-AllUsersTrustQuota'
      },
      {
        value: 'msDS-Behavior-Version'
      },
      {
        value: 'msDS-EnabledFeature'
      },
      {
        value: 'msDS-PerUserTrustQuota'
      },
      {
        value: 'msDS-PerUserTrustTombstonesQuota'
      },
      {
        value: 'nETBIOSName'
      },
      {
        value: 'nTMixedDomain'
      },
      {
        value: 'pekKeyChangeInterval'
      },
      {
        value: 'pwdHistoryLength'
      },
      {
        value: 'pwdProperties'
      },
      {
        value: 'rIDManagerReference'
      },
      {
        value: 'serverRole'
      },
      {
        value: 'treeName'
      }
    ]
  },
  {
    value: 'foreignSecurityPrincipal',
    children: []
  },
  {
    value: 'group',
    children: [
      {
        value: 'adminCount'
      },
      {
        value: 'altSecurityIdentities'
      },
      {
        value: 'controlAccessRights'
      },
      {
        value: 'gidNumber'
      },
      {
        value: 'groupType'
      },
      {
        value: 'managedBy'
      },
      {
        value: 'member'
      },
      {
        value: 'memberUid'
      },
      {
        value: 'msExchDelegateListLink'
      },
      {
        value: 'sIDHistory'
      }
    ]
  },
  {
    value: 'groupPolicyContainer',
    children: [
      {
        value: 'flags'
      },
      {
        value: 'gPCFileSysPath'
      },
      {
        value: 'gPCFunctionalityVersion'
      },
      {
        value: 'gPCMachineExtensionNames'
      },
      {
        value: 'gPCUserExtensionNames'
      },
      {
        value: 'gPCWQLFilter'
      },
      {
        value: 'name'
      },
      {
        value: 'versionNumber'
      }
    ]
  },
  {
    value: 'msDFSR-ContentSet',
    children: [
      {
        value: 'msDFSR-DirectoryFilter'
      },
      {
        value: 'msDFSR-FileFilter'
      }
    ]
  },
  {
    value: 'msDFSR-GlobalSettings',
    children: [
      {
        value: 'msDFSR-Flags'
      }
    ]
  },
  {
    value: 'msDFSR-Member',
    children: [
      {
        value: 'msDFSR-ComputerReference'
      },
      {
        value: 'serverReference'
      }
    ]
  },
  {
    value: 'msDS-AuthNPolicy',
    children: [
      {
        value: 'msDS-AuthNPolicyEnforced'
      },
      {
        value: 'msDS-StrongNTLMPolicy'
      },
      {
        value: 'msDS-ServiceAllowedNTLMNetworkAuthentication'
      },
      {
        value: 'msDS-UserAllowedNTLMNetworkAuthentication'
      },
      {
        value: 'msDS-ComputerTGTLifetime'
      },
      {
        value: 'msDS-ServiceTGTLifetime'
      },
      {
        value: 'msDS-UserTGTLifetime'
      },
      {
        value: 'msDS-UserAllowedToAuthenticateFrom'
      },
      {
        value: 'msDS-ServiceAllowedToAuthenticateFrom'
      },
      {
        value: 'msDS-UserAllowedToAuthenticateTo'
      },
      {
        value: 'msDS-ServiceAllowedToAuthenticateTo'
      },
      {
        value: 'msDS-ComputerAllowedToAuthenticateTo'
      }
    ]
  },
  {
    value: 'msDS-AuthNPolicySilo',
    children: [
      {
        value: 'msDS-AuthNPolicySiloEnforced'
      },
      {
        value: 'msDS-AuthNPolicySiloMembers'
      },
      {
        value: 'msDS-ComputerAuthNPolicy'
      },
      {
        value: 'msDS-ServiceAuthNPolicy'
      },
      {
        value: 'msDS-UserAuthNPolicy'
      }
    ]
  },
  {
    value: 'msDS-ClaimType',
    children: [
      {
        value: 'displayName'
      },
      {
        value: 'Enabled'
      },
      {
        value: 'msDS-ClaimIsSingleValued'
      },
      {
        value: 'msDS-ClaimIsValueSpaceRestricted'
      },
      {
        value: 'msDS-ClaimSourceType'
      },
      {
        value: 'msDS-ClaimTypeAppliesToClass'
      },
      {
        value: 'msDS-ClaimValueType'
      },
      {
        value: 'msDS-ClaimAttributeSource'
      },
      {
        value: 'msDS-ClaimSource'
      },
      {
        value: 'msDS-ClaimSharesPossibleValuesWith'
      },
      {
        value: 'msDS-ClaimPossibleValues'
      }
    ]
  },
  {
    value: 'msDS-Device',
    children: [
      {
        value: 'altSecurityIdentities'
      },
      {
        value: 'msDS-CloudAnchor'
      },
      {
        value: 'msDS-DeviceID'
      },
      {
        value: 'msDS-DeviceObjectVersion'
      },
      {
        value: 'msDS-DevicePhysicalIDs'
      },
      {
        value: 'msDS-DeviceOSType'
      },
      {
        value: 'msDS-DeviceOSVersion'
      },
      {
        value: 'msDS-IsEnabled'
      },
      {
        value: 'msDS-IsManaged'
      },
      {
        value: 'msDS-KeyCredentialLink'
      }
    ]
  },
  {
    value: 'msDS-GroupManagedServiceAccount',
    children: [
      {
        value: 'accountExpires'
      },
      {
        value: 'adminCount'
      },
      {
        value: 'altSecurityIdentities'
      },
      {
        value: 'badPwdCount'
      },
      {
        value: 'badPasswordTime'
      },
      {
        value: 'dNSHostName'
      },
      {
        value: 'lastLogonTimestamp'
      },
      {
        value: 'lockoutTime'
      },
      {
        value: 'logonCount'
      },
      {
        value: 'logonHours'
      },
      {
        value: 'msDS-AssignedAuthNPolicy'
      },
      {
        value: 'msDS-AssignedAuthNPolicySilo'
      },
      {
        value: 'msDS-GroupMSAMembership'
      },
      {
        value: 'msDS-ManagedPasswordInterval'
      },
      {
        value: 'msDS-SupportedEncryptionTypes'
      },
      {
        value: 'primaryGroupID'
      },
      {
        value: 'pwdLastSet'
      },
      {
        value: 'servicePrincipalName'
      },
      {
        value: 'sIDHistory'
      },
      {
        value: 'userAccountControl'
      },
      {
        value: 'userPrincipalName'
      },
      {
        value: 'userWorkstations'
      }
    ]
  },
  {
    value: 'msDS-ManagedServiceAccount',
    children: [
      {
        value: 'accountExpires'
      },
      {
        value: 'adminCount'
      },
      {
        value: 'altSecurityIdentities'
      },
      {
        value: 'badPwdCount'
      },
      {
        value: 'badPasswordTime'
      },
      {
        value: 'lastLogonTimestamp'
      },
      {
        value: 'lockoutTime'
      },
      {
        value: 'logonCount'
      },
      {
        value: 'logonHours'
      },
      {
        value: 'msDS-AssignedAuthNPolicy'
      },
      {
        value: 'msDS-AssignedAuthNPolicySilo'
      },
      {
        value: 'msDS-SupportedEncryptionTypes'
      },
      {
        value: 'primaryGroupID'
      },
      {
        value: 'pwdLastSet'
      },
      {
        value: 'servicePrincipalName'
      },
      {
        value: 'sIDHistory'
      },
      {
        value: 'userAccountControl'
      },
      {
        value: 'userPrincipalName'
      },
      {
        value: 'userWorkstations'
      }
    ]
  },
  {
    value: 'msDS-PasswordSettings',
    children: [
      {
        value: 'msDS-LockoutDuration'
      },
      {
        value: 'msDS-LockoutObservationWindow'
      },
      {
        value: 'msDS-LockoutThreshold'
      },
      {
        value: 'msDS-MaximumPasswordAge'
      },
      {
        value: 'msDS-MinimumPasswordAge'
      },
      {
        value: 'msDS-MinimumPasswordLength'
      },
      {
        value: 'msDS-PasswordComplexityEnabled'
      },
      {
        value: 'msDS-PasswordHistoryLength'
      },
      {
        value: 'msDS-PasswordReversibleEncryptionEnabled'
      },
      {
        value: 'msDS-PasswordSettingsPrecedence'
      },
      {
        value: 'msDS-PSOAppliesTo'
      }
    ]
  },
  {
    value: 'msKds-ProvRootKey',
    children: [
      {
        value: 'msKds-CreateTime'
      },
      {
        value: 'msKds-DomainID'
      },
      {
        value: 'msKds-KDFAlgorithmID'
      },
      {
        value: 'msKds-PrivateKeyLength'
      },
      {
        value: 'msKds-PublicKeyLength'
      },
      {
        value: 'msKds-SecretAgreementAlgorithmID'
      },
      {
        value: 'msKds-UseStartTime'
      },
      {
        value: 'msKds-Version'
      }
    ]
  },
  {
    value: 'msWMI-Som',
    children: [
      {
        value: 'msWMI-Author'
      },
      {
        value: 'msWMI-ChangeDate'
      },
      {
        value: 'msWMI-CreationDate'
      },
      {
        value: 'msWMI-ID'
      },
      {
        value: 'msWMI-intFlags1'
      },
      {
        value: 'msWMI-intFlags2'
      },
      {
        value: 'msWMI-intFlags3'
      },
      {
        value: 'msWMI-intFlags4'
      },
      {
        value: 'msWMI-Name'
      },
      {
        value: 'msWMI-Parm1'
      },
      {
        value: 'msWMI-Parm2'
      },
      {
        value: 'msWMI-Parm3'
      },
      {
        value: 'msWMI-Parm4'
      }
    ]
  },
  {
    value: 'nTDSDSA',
    children: [
      {
        value: 'dMDLocation'
      },
      {
        value: 'fRSRootPath'
      },
      {
        value: 'hasMasterNCs'
      },
      {
        value: 'hasPartialReplicaNCs'
      },
      {
        value: 'invocationId'
      },
      {
        value: 'lastBackupRestorationTime'
      },
      {
        value: 'managedBy'
      },
      {
        value: 'msDS-Behavior-Version'
      },
      {
        value: 'msDS-EnabledFeature'
      },
      {
        value: 'msDS-HasDomainNCs'
      },
      {
        value: 'msDS-hasFullReplicaNCs'
      },
      {
        value: 'msDS-HasInstantiatedNCs'
      },
      {
        value: 'msDS-hasMasterNCs'
      },
      {
        value: 'msDS-NeverRevealGroup'
      },
      {
        value: 'msDS-RevealOnDemandGroup'
      },
      {
        value: 'msDS-SiteName'
      },
      {
        value: 'networkAddress'
      },
      {
        value: 'options'
      },
      {
        value: 'queryPolicyObject'
      },
      {
        value: 'retiredReplDSASignatures'
      }
    ]
  },
  {
    value: 'nTDSService',
    children: [
      {
        value: 'dSHeuristics'
      },
      {
        value: 'garbageCollPeriod'
      },
      {
        value: 'msDS-DeletedObjectLifetime'
      },
      {
        value: 'msDS-Other-Settings'
      },
      {
        value: 'sPNMappings'
      },
      {
        value: 'tombstoneLifetime'
      }
    ]
  },
  {
    value: 'organizationalPerson',
    children: [
      {
        value: 'msDS-AllowedToActOnBehalfOfOtherIdentity'
      },
      {
        value: 'msDS-AllowedToDelegateTo'
      }
    ]
  },
  {
    value: 'organizationalUnit',
    children: [
      {
        value: 'defaultGroup'
      },
      {
        value: 'gPLink'
      },
      {
        value: 'gPOptions'
      },
      {
        value: 'managedBy'
      },
      {
        value: 'ou'
      },
      {
        value: 'userPassword'
      }
    ]
  },
  {
    value: 'passwordHashReuse',
    children: [
      {
        value: 'objectClass'
      },
      {
        value: 'prefix'
      },
      {
        value: 'reusedWithinDomain'
      }
    ]
  },
  {
    value: 'passwordHashScan',
    children: [
      {
        value: 'distinguishedName'
      },
      {
        value: 'objectGUID'
      },
      {
        value: 'objectClass'
      },
      {
        value: 'retrievalState'
      },
      {
        value: 'isLmBlank'
      },
      {
        value: 'isNtBlank'
      },
      {
        value: 'isBreached'
      },
      {
        value: 'isWeak'
      }
    ]
  },
  {
    value: 'person',
    children: [
      {
        value: 'telephoneNumber'
      },
      {
        value: 'userPassword'
      }
    ]
  },
  {
    value: 'pKICertificateTemplate',
    children: [
      {
        value: 'msPKI-Cert-Template-OID'
      },
      {
        value: 'msPKI-Certificate-Application-Policy'
      },
      {
        value: 'msPKI-Certificate-Name-Flag'
      },
      {
        value: 'msPKI-Enrollment-Flag'
      },
      {
        value: 'msPKI-Minimal-Key-Size'
      },
      {
        value: 'msPKI-Private-Key-Flag'
      },
      {
        value: 'msPKI-RA-Application-Policies'
      },
      {
        value: 'msPKI-RA-Signature'
      },
      {
        value: 'msPKI-Supersede-Templates'
      },
      {
        value: 'msPKI-Template-Minor-Revision'
      },
      {
        value: 'msPKI-Template-Schema-Version'
      },
      {
        value: 'pKICriticalExtensions'
      },
      {
        value: 'pKIDefaultCSPs'
      },
      {
        value: 'pKIDefaultKeySpec'
      },
      {
        value: 'pKIExpirationPeriod'
      },
      {
        value: 'pKIExtendedKeyUsage'
      },
      {
        value: 'pKIKeyUsage'
      },
      {
        value: 'pKIMaxIssuingDepth'
      },
      {
        value: 'pKIOverlapPeriod'
      },
      {
        value: 'revision'
      }
    ]
  },
  {
    value: 'pKIEnrollmentService',
    children: [
      {
        value: 'cACertificate'
      },
      {
        value: 'cACertificateDN'
      },
      {
        value: 'certificateTemplates'
      },
      {
        value: 'dNSHostName'
      },
      {
        value: 'msPKI-Enrollment-Servers'
      },
      {
        value: 'enrollmentProviders'
      },
      {
        value: 'msPKI-Site-Name'
      },
      {
        value: 'signatureAlgorithms'
      }
    ]
  },
  {
    value: 'rIDSet',
    children: [
      {
        value: 'rIDAllocationPool'
      },
      {
        value: 'rIDNextRID'
      },
      {
        value: 'rIDPreviousAllocationPool'
      },
      {
        value: 'rIDUsedPool'
      }
    ]
  },
  {
    value: 'server',
    children: [
      {
        value: 'dNSHostName'
      },
      {
        value: 'managedBy'
      },
      {
        value: 'serverReference'
      }
    ]
  },
  {
    value: 'site',
    children: [
      {
        value: 'gPLink'
      },
      {
        value: 'gPOptions'
      },
      {
        value: 'location'
      },
      {
        value: 'managedBy'
      }
    ]
  },
  {
    value: 'subnet',
    children: [
      {
        value: 'location'
      },
      {
        value: 'siteObject'
      }
    ]
  },
  {
    value: 'top',
    children: [
      {
        value: 'cn'
      },
      {
        value: 'description'
      },
      {
        value: 'displayName'
      },
      {
        value: 'distinguishedName'
      },
      {
        value: 'fSMORoleOwner'
      },
      {
        value: 'isDeleted'
      },
      {
        value: 'mS-DS-ConsistencyGuid'
      },
      {
        value: 'nTSecurityDescriptor'
      },
      {
        value: 'objectCategory'
      },
      {
        value: 'objectClass'
      },
      {
        value: 'objectGUID'
      },
      {
        value: 'objectSid'
      },
      {
        value: 'sAMAccountName'
      },
      {
        value: 'sAMAccountType'
      },
      {
        value: 'securityIdentifier'
      },
      {
        value: 'sIDHistory'
      },
      {
        value: 'unixUserPassword'
      },
      {
        value: 'userPassword'
      },
      {
        value: 'uSNChanged'
      },
      {
        value: 'uSNCreated'
      },
      {
        value: 'whenChanged'
      },
      {
        value: 'whenCreated'
      }
    ]
  },
  {
    value: 'trustedDomain',
    children: [
      {
        value: 'domainIdentifier'
      },
      {
        value: 'flatName'
      },
      {
        value: 'msDS-EgressClaimsTransformationPolicy'
      },
      {
        value: 'msDS-IngressClaimsTransformationPolicy'
      },
      {
        value: 'msDS-SupportedEncryptionTypes'
      },
      {
        value: 'name'
      },
      {
        value: 'trustAttributes'
      },
      {
        value: 'trustDirection'
      },
      {
        value: 'trustPartner'
      },
      {
        value: 'trustPosixOffset'
      },
      {
        value: 'trustType'
      }
    ]
  },
  {
    value: 'user',
    children: [
      {
        value: 'accountExpires'
      },
      {
        value: 'adminCount'
      },
      {
        value: 'altSecurityIdentities'
      },
      {
        value: 'badPwdCount'
      },
      {
        value: 'badPasswordTime'
      },
      {
        value: 'department'
      },
      {
        value: 'gidNumber'
      },
      {
        value: 'homeDirectory'
      },
      {
        value: 'homeDrive'
      },
      {
        value: 'info'
      },
      {
        value: 'lastLogonTimestamp'
      },
      {
        value: 'lockoutTime'
      },
      {
        value: 'logonHours'
      },
      {
        value: 'mail'
      },
      {
        value: 'manager'
      },
      {
        value: 'mS-DS-CreatorSID'
      },
      {
        value: 'msDS-AssignedAuthNPolicy'
      },
      {
        value: 'msDS-AssignedAuthNPolicySilo'
      },
      {
        value: 'msDS-KeyCredentialLink'
      },
      {
        value: 'msDS-LastSuccessfulInteractiveLogonTime'
      },
      {
        value: 'msDS-PSOApplied'
      },
      {
        value: 'msDS-SupportedEncryptionTypes'
      },
      {
        value: 'msExchDelegateListLink'
      },
      {
        value: 'ms-Mcs-AdmPwdExpirationTime'
      },
      {
        value: 'msLAPS-PasswordExpirationTime'
      },
      {
        value: 'networkAddress'
      },
      {
        value: 'primaryGroupID'
      },
      {
        value: 'profilePath'
      },
      {
        value: 'pwdLastSet'
      },
      {
        value: 'scriptPath'
      },
      {
        value: 'servicePrincipalName'
      },
      {
        value: 'sIDHistory'
      },
      {
        value: 'title'
      },
      {
        value: 'uid'
      },
      {
        value: 'uidNumber'
      },
      {
        value: 'unicodePwd'
      },
      {
        value: 'unixUserPassword'
      },
      {
        value: 'userAccountControl'
      },
      {
        value: 'userPrincipalName'
      },
      {
        value: 'userWorkstations'
      }
    ]
  }
]
