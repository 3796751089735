import { LabelBreakable } from '@app/components-legacy/Label'
import { consts } from '@app/styles'
import styled from 'styled-components'

/**
 * Container of Popover content that set a minimal width/height and wrap content
 * by default.
 */
export default styled(LabelBreakable)`
  width: ${consts.tooltipDefaultWidth};
  min-height: 100px;
`
