import { IconEyeInvisibleOutlined } from '@app/components-legacy/Icon/IconAntd'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { DateFormat, formatDate } from '@libs/dates/formatDate'
import { Tooltip } from 'antd'
import { upperFirst } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'

const StyledIconEyeInvisibleOutlined = styled(IconEyeInvisibleOutlined)`
  color: ${props => props.theme.tokens['label/color/text']};
`

interface ILabelDevianceIgnoreUntilProps {
  className?: string
  ignoreUntil: string
}

const LabelDevianceIgnoreUntil: React.FC<
  ILabelDevianceIgnoreUntilProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['IoE.Details.DeviantObjects']
  })

  const ignoreUntil: string = translate(
    'Tenable.ad ignores this deviance until X',
    {
      interpolations: {
        date: upperFirst(
          formatDate(props.ignoreUntil, {
            utc: true,
            format: DateFormat.verbose
          })
        )
      }
    }
  )

  return (
    <Tooltip className={props.className} placement="top" title={ignoreUntil}>
      <StyledIconEyeInvisibleOutlined />
    </Tooltip>
  )
}

export default LabelDevianceIgnoreUntil
