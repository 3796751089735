import { BulletDiamond } from '@app/components-legacy/Bullet'
import { ContainerFlexVertical } from '@app/components-legacy/Container'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import TableContentBodyCell from '../TableContentBodyCell'
import { isAllDeviancesResolvedForProfileId } from './helpers'

export interface ICellDevianceProps {
  eventId: string
}

const CellDeviance: React.FC<ICellDevianceProps> = props => {
  const translate = useAppTranslator({ namespaces: ['TrailFlow.Table'] })

  const { storeAuthentication, storeTrailFlow } = useStores()

  const CellContent = React.useMemo(() => {
    const getCellContent = () => {
      const eventDeviances = storeTrailFlow.storeDeviances.deviances.get(
        props.eventId
      )

      if (!eventDeviances?.size) {
        return null
      }

      // display for the Tenable profile if all deviances are for the Tenable profile
      const isTenableProfile = Array.from(eventDeviances.values()).every(
        deviance => deviance.isTenableProfile
      )

      const profileId = isTenableProfile
        ? storeAuthentication.tenableProfile?.getPropertyAsNumber('id')
        : storeAuthentication.currentProfileId

      const isResolved = isAllDeviancesResolvedForProfileId(eventDeviances)(
        profileId || storeAuthentication.currentProfileId
      )

      const tooltip = isResolved
        ? translate('Some modifications resolved one or several deviances')
        : translate('Some modifications caused one or several deviances')

      const attrTestLabel = () => {
        if (isTenableProfile) {
          return isResolved
            ? 'tenableProfileResolvedIcon'
            : 'tenableProfileDeviantIcon'
        }
        return isResolved
          ? 'customProfileResolvedIcon'
          : 'customProfileDeviantIcon'
      }

      return (
        <ContainerFlexVertical
          items={[
            <BulletDiamond
              tooltip={tooltip}
              reversed={isTenableProfile}
              resolved={isResolved}
              labelledBy={attrTestLabel()}
            />
          ]}
        />
      )
    }

    return (
      <div className="eventDeviance">
        <TableContentBodyCell>{getCellContent()}</TableContentBodyCell>
      </div>
    )
  }, [storeTrailFlow.storeDeviances.deviancesActivity])

  return <>{CellContent}</>
}

// Observe new deviances received via dedicated websocket channel
export default observer(CellDeviance)
