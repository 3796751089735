import { AppRouteName } from '@app/routes'
import type { StoreBlades, StoreDashboards } from '@app/stores'
import type StoreDashboard from '@app/stores/Dashboard/StoreDashboard'
import type { InstanceName } from '@libs/Environment/types'
import type { AppRouterClient } from '@libs/Router/types'

/**
 * Initialize the form with default values.
 */
export const onWidgetAddPageLoad =
  (storeDashboards: StoreDashboards) => (dashboardId: number) => () => {
    const storeDashboard = storeDashboards.currentStoreDashboard

    if (!storeDashboard) {
      return
    }

    storeDashboard.storeWidgetsManagement.initializeStoresForWidgetCreation(
      dashboardId
    )
  }

/**
 * Reset data on unload
 */
export const onWidgetAddPageUnload = (storeDashboard: StoreDashboard) => () => {
  const { storeWidgetsManagement } = storeDashboard
  storeWidgetsManagement.reset()
}

/**
 * Add a widget.
 */
export const onWidgetAddSubmit =
  (
    appRouter: AppRouterClient,
    storeDashboards: StoreDashboards,
    storeDashboard: StoreDashboard
  ) =>
  (dashboardId: number) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    if (!storeDashboard.storeWidgetsManagement.storeForm.validate()) {
      return
    }

    const dashboardEntity = storeDashboard.dashboardEntity

    const instanceName = dashboardEntity.getPropertyAsString('instanceName')

    storeDashboard.storeWidgetsManagement
      .createWidget(Number(dashboardId))
      .then(() => {
        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Dashboard_Grid,
          parameters: {
            instanceName,
            dashboardId
          }
        })

        appRouter.history.push(url)

        // reload the dashboard to get the new created widget
        return storeDashboards.fetchOneDashboard(
          instanceName,
          Number(dashboardId)
        )
      })
      .catch(() => {
        // Avoid the redirection if something is wrong when adding the widget
      })
  }

export const onCancelButtonClick =
  (storeBlades: StoreBlades) =>
  (instanceName: InstanceName, dashboardId: number) =>
  () => {
    const url = storeBlades.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Dashboard_Grid,
      parameters: {
        instanceName,
        dashboardId
      }
    })

    storeBlades.goToBladeUrl(url)
  }
