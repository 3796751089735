import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToIdentityIoEDetails } from '../IoEBoardPage/permissions'
import { CheckerIdentityDetailsPageContext } from './context'
import DetailsBladeHeader from './DetailsBladeHeader'
import DetailsBladeMenu from './DetailsBladeMenu'
import DetailsFindingsPage from './DetailsFindingsPage'
import DetailsInformationsPage from './DetailsInformationsPage'
import DetailsRecommendationsPage from './DetailsRecommendationsPage'
import {
  handleCheckerIdentityDetailsOnLoad,
  handleCheckerIdentityDetailsOnUnLoad
} from './handlers'
import {
  canAccessToIoEIdentityFindings,
  canReadIoEIdentityInformation,
  canReadIoEIdentityRecommandations
} from './permissions'

interface IIoECheckerIdentityDetailsPageProps {}

const IoECheckerIdentityDetailsPage: React.FC<
  IIoECheckerIdentityDetailsPageProps
> = () => {
  const appRouter = useAppRouter()

  const { storeIoE } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.IoE_Identity_Details,
    parameters: {
      checkerId: String(),
      checkerCodename: String()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <CheckerIdentityDetailsPageContext.Provider
      value={{
        checkerId: parameters.checkerId,
        checkerCodename: parameters.checkerCodename
      }}
    >
      <Blade
        routeDefinition={{
          routeName: AppRouteName.IoE_Identity_Details,
          parameters: {
            checkerId: parameters.checkerId,
            checkerCodename: parameters.checkerCodename
          }
        }}
        onLoad={handleCheckerIdentityDetailsOnLoad(storeIoE)(
          parameters.checkerCodename
        )}
        onUnload={handleCheckerIdentityDetailsOnUnLoad(storeIoE)}
        flags={[
          storeIoE.storeIndicator.storeFlagsFetchCheckerIdentityComplianceStatus
            .flags
        ]}
        layout={{
          name: 'default',
          header: <DetailsBladeHeader />,
          menu: <DetailsBladeMenu />,
          content: (
            <RbacRoutes
              routes={[
                {
                  routeDefinition: {
                    routeName: AppRouteName.IoE_Identity_Details_Information,
                    parameters: {
                      checkerId: parameters.checkerId,
                      checkerCodename: parameters.checkerCodename
                    }
                  },
                  component: DetailsInformationsPage,
                  rbacPermissionsCheck: () => canReadIoEIdentityInformation
                },
                {
                  routeDefinition: {
                    routeName: AppRouteName.IoE_Identity_Details_Findings,
                    parameters: {
                      checkerId: parameters.checkerId,
                      checkerCodename: parameters.checkerCodename
                    }
                  },
                  component: DetailsFindingsPage,
                  rbacPermissionsCheck: () => canAccessToIoEIdentityFindings
                },
                {
                  routeDefinition: {
                    routeName:
                      AppRouteName.IoE_Identity_Details_Recommendations,
                    parameters: {
                      checkerId: parameters.checkerId,
                      checkerCodename: parameters.checkerCodename
                    }
                  },
                  component: DetailsRecommendationsPage,
                  rbacPermissionsCheck: () => canReadIoEIdentityRecommandations
                }
              ]}
              redirect
            />
          ),
          footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
        }}
        rbacCapabilityCheck={canAccessToIdentityIoEDetails}
      />
    </CheckerIdentityDetailsPageContext.Provider>
  )
}

export default observer(IoECheckerIdentityDetailsPage)
