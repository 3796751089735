import type { StoreRoot } from '@app/stores'
import { DEFAULT_CRONTAB } from '@app/stores/Management/StoreReportingCenter'
import StoreBase from '@app/stores/StoreBase'
import { action, computed, makeObservable, observable } from 'mobx'
import StoreForm from '../StoreForm'
import { InputCrontabPeriod, InputCrontabUnit } from './types'

interface IStoreInputCrontabOptions {}
export class StoreInputCrontab extends StoreBase<IStoreInputCrontabOptions> {
  public storeForm = new StoreForm<InputCrontabUnit>(this.storeRoot, {
    setup: {
      fields: {
        [InputCrontabUnit.months]: {
          label: 'Months',
          description: 'Months',
          validators: []
        },
        [InputCrontabUnit.daysOfTheMonth]: {
          label: 'DaysOfTheMonth',
          description: 'DaysOfTheMonth',
          validators: []
        },
        [InputCrontabUnit.daysOfTheWeek]: {
          label: 'DaysOfTheWeek',
          description: 'DaysOfTheWeek',
          validators: []
        },
        [InputCrontabUnit.hours]: {
          label: 'Hours',
          description: 'Hours',
          validators: []
        },
        [InputCrontabUnit.minute]: {
          label: 'Minute',
          description: 'Minute',
          validators: []
        }
      }
    }
  })
    .setDefaultFieldsValues([
      {
        key: InputCrontabUnit.months,
        value: JSON.stringify([])
      },
      {
        key: InputCrontabUnit.daysOfTheMonth,
        value: JSON.stringify([])
      },
      {
        key: InputCrontabUnit.daysOfTheWeek,
        value: JSON.stringify([])
      },
      {
        key: InputCrontabUnit.hours,
        value: JSON.stringify([])
      },
      {
        key: InputCrontabUnit.minute,
        value: JSON.stringify(['0'])
      }
    ])
    .reset()

  /**
   * Observable
   */
  private $period = observable.box<InputCrontabPeriod>(
    InputCrontabPeriod.byYear
  )

  constructor(storeRoot: StoreRoot, options = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Actions
   */

  @action
  setPeriod(period: InputCrontabPeriod): this {
    this.$period.set(period)

    // reinit other selectors accordingly
    switch (period) {
      case InputCrontabPeriod.byMonth:
        this.storeForm.setFieldValue(
          InputCrontabUnit.months,
          JSON.stringify([])
        )
        break

      case InputCrontabPeriod.byWeek:
        this.storeForm.setFieldValue(
          InputCrontabUnit.months,
          JSON.stringify([])
        )
        this.storeForm.setFieldValue(
          InputCrontabUnit.daysOfTheMonth,
          JSON.stringify([])
        )
        break

      case InputCrontabPeriod.byDay:
        this.storeForm.setFieldValue(
          InputCrontabUnit.months,
          JSON.stringify([])
        )
        this.storeForm.setFieldValue(
          InputCrontabUnit.daysOfTheMonth,
          JSON.stringify([])
        )
        this.storeForm.setFieldValue(
          InputCrontabUnit.daysOfTheWeek,
          JSON.stringify([])
        )
        break

      default:
        break
    }
    return this
  }

  @action
  setDefaultCrontab(): this {
    this.setCrontab(DEFAULT_CRONTAB)
    return this
  }

  @action
  setCrontab(crontab: string): this {
    // split crontab and initialize all selectors accordingly
    // [minute] [hours] [days of the month] [months] [days of the week]
    const cronArray = crontab.split(' ')

    // multiple minutes not allowed
    // keep it detailed here because we may need to process other cron formats with ranges: 1-5 MON-WED etc.
    const minute =
      cronArray[0] === '*' ? [] : cronArray[0].split(',').slice(0, 1)
    const hours = cronArray[1] === '*' ? [] : cronArray[1].split(',')
    const daysOfTheMonth = cronArray[2] === '*' ? [] : cronArray[2].split(',')
    const months = cronArray[3] === '*' ? [] : cronArray[3].split(',')
    const daysOfTheWeek = cronArray[4] === '*' ? [] : cronArray[4].split(',')

    if (daysOfTheWeek.length) {
      this.setPeriod(InputCrontabPeriod.byWeek)
    } else {
      this.setPeriod(InputCrontabPeriod.byDay)
    }
    if (daysOfTheMonth.length) {
      this.setPeriod(InputCrontabPeriod.byMonth)
    }
    if (months.length) {
      this.setPeriod(InputCrontabPeriod.byYear)
    }

    this.storeForm
      .setDefaultFieldsValues([
        {
          key: InputCrontabUnit.months,
          value: JSON.stringify(months)
        },
        {
          key: InputCrontabUnit.daysOfTheMonth,
          value: JSON.stringify(daysOfTheMonth)
        },
        {
          key: InputCrontabUnit.daysOfTheWeek,
          value: JSON.stringify(daysOfTheWeek)
        },
        {
          key: InputCrontabUnit.hours,
          value: JSON.stringify(hours)
        },
        {
          key: InputCrontabUnit.minute,
          value: JSON.stringify(minute)
        }
      ])
      .reset()

    return this
  }

  @action
  reset(): this {
    this.$period.set(InputCrontabPeriod.byYear)
    this.storeForm.reset()
    return this
  }

  /**
   * Computed
   */

  @computed
  get period(): InputCrontabPeriod {
    return this.$period.get()
  }

  /**
   * Format example:
   *
   * 1,15,20 * * * *
   */
  @computed
  get crontab() {
    const fieldValues = this.storeForm.debugFieldsValues

    // Order matters in InputCrontabUnit
    // minute
    // hour
    // days of the month
    // months
    // days of the week
    const crontabValues = Object.values(InputCrontabUnit).map(unit => {
      const fieldValue = fieldValues[unit]

      if (!fieldValue) {
        return null
      }

      const values = JSON.parse(fieldValues[unit]) as string[]

      if (!values.length) {
        return '*'
      }

      return values.join(',')
    })

    return crontabValues.join(' ')
  }
}
