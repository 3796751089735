import type { DeepPartial } from '@alsid/common'
import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import PageGlobalInventory from '@app/pages/GlobalInventory'
import { canAccessToIdentities } from '@app/pages/Identities/permissions'
import { AppRouteName } from '@app/routes'
import * as React from 'react'
import type { IGlobalInventoryAppConfiguration } from 'tenable-cyber-asset-management/dist/common/src/libs/IframeCommunicationBus/types'

const KonsoleIdentitiesBlade = () => {
  const configuration: DeepPartial<IGlobalInventoryAppConfiguration> = {
    initialPage: '/identities',
    preferences: {
      layout: {
        backToAssetsButtonUrl: {
          type: 'internal',
          routeName: 'Identities'
        }
      },
      applicationContext: {
        assetTypes: ['identity']
      }
    }
  }

  // Render the blade
  return (
    <Blade
      root={true}
      routeDefinition={{
        routeName: AppRouteName.Gi_Identities,
        parameters: {}
      }}
      flags={[]}
      layout={{
        name: 'default',
        content: (
          <ContainerContent>
            <PageGlobalInventory configuration={configuration} />
          </ContainerContent>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      paddingH={0}
      paddingV={0}
      scrollChildrenContainerOverflow="hidden"
      rbacCapabilityCheck={canAccessToIdentities}
    />
  )
}

export default KonsoleIdentitiesBlade
