import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

const StyledPath = styled.path`
  fill: ${consts.colorBlue005};
  clip-rule: evenodd;
  fill-rule: evenodd;
`

interface IIconLaptopProps {
  className?: string
}

const IconLaptop: React.FC<IIconLaptopProps> = props => {
  return (
    <svg
      className={props.className}
      viewBox="0 0 22 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPath
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5 0C19.3284 0 20 0.671573 20 1.5V12H22V13.5C22 14.3284 21.3284 15 20.5 15H1.5C0.671573 15 0 14.3284 0 13.5V12H2V1.5C2 0.671573 2.67157 0 3.5 0H18.5ZM18.5 1H3.5C3.25454 1 3.05039 1.17688 3.00806 1.41012L3 1.5V12H19V1.5C19 1.25454 18.8231 1.05039 18.5899 1.00806L18.5 1ZM19 13H14L13 13.5H9L8 13H3H1V13.5C1 13.7455 1.17688 13.9496 1.41012 13.9919L1.5 14H20.5C20.7455 14 20.9496 13.8231 20.9919 13.5899L21 13.5V13H19Z"
      />
    </svg>
  )
}

export default styled(IconLaptop)`
  width: 22px;
  height: 15px;
  fill: none;
`
