import type { MaybeUndef } from '@alsid/common'
import { appRoutes } from '@app/routes'
import * as stores from '@app/stores'
import { DEFAULT_INSTANCE_NAME } from '@libs/common/consts'
import type Environment from '@libs/Environment/Environment'
import type { IFetchClient } from '@libs/FetchClient/types'
import GQLRequestor from '@libs/graphQL/GQLRequestor'
import type { IGQLRequestor } from '@libs/graphQL/GQLRequestor/types'
import AppTranslator from '@libs/i18n/AppTranslator'
import { KeyboardBindingsManager } from '@libs/KeyboardBindingsManager'
import type { ILogger } from '@libs/logger'
import { getLogger } from '@libs/logger'
import AppRouter from '@libs/Router/AppRouter'
import type { AppRouterClient } from '@libs/Router/types'
import type { TWSClient } from '@libs/WSClient/types'
import { get } from 'lodash'
import { action, computed, observable } from 'mobx'
import type { ITulApi } from 'tenable-universal-layout'
import type { IStores } from './types'

export default class StoreRoot<
  TGraphqlRequestor extends IGQLRequestor = IGQLRequestor,
  F extends IFetchClient = IFetchClient
> {
  private _environment: Environment<F>
  private _appTranslator: AppTranslator
  private _appRouter: AppRouterClient
  private _keyboardBindingsManager = new KeyboardBindingsManager()
  // one instance by Tenable.ad instance
  private _gqlRequestors: Map<string, TGraphqlRequestor> = new Map()
  private _stores!: IStores
  private _tulApi?: ITulApi
  private $securityEngineKey = observable.box<number>(0)

  constructor(environment: Environment<F>) {
    this._environment = environment

    this._appTranslator = new AppTranslator(
      this._environment.translator,
      this._environment.localStorage
    )

    this._appRouter = new AppRouter(appRoutes)

    this._instanciateStores()
  }

  /**
   * Instanciate GraphQL requestors, used to make all queries/mutations.
   */
  instanciateGraphQLRequestors(): this {
    this._gqlRequestors = new Map()

    this._environment.graphQLClients.forEach((graphQLClient, instanceName) => {
      const gqlRequestor = new GQLRequestor(graphQLClient)
      // hack :(
      this._gqlRequestors.set(
        instanceName,
        gqlRequestor as unknown as TGraphqlRequestor
      )
    })

    return this
  }

  /**
   * Set a graphQLRequestor manually.
   * Can be used in unit tests to stub graphQLRequestor responses.
   */
  setGraphQLRequestor(
    graphQLRequestor: TGraphqlRequestor,
    instanceName = DEFAULT_INSTANCE_NAME
  ): this {
    this._gqlRequestors.set(instanceName, graphQLRequestor)
    return this
  }

  /**
   * Return the GQLRequestor instance for the passed `instanceName` or of
   * the first one if not defined.
   */
  getGQLRequestor(instanceName?: string): TGraphqlRequestor {
    const allRequestors = Array.from(this._gqlRequestors.values())

    // if instanceName is not defined, return the default requestor
    const requestor = !instanceName
      ? allRequestors[0]
      : this._gqlRequestors.get(instanceName) || allRequestors[0]

    if (!requestor) {
      throw new Error('No GQLRequestor instance found')
    }

    return requestor
  }

  /**
   * Log exceptions and show stacktrace in development.
   */
  logException(err: Error): void {
    const exceptionMessage = get(err, 'message', String(err))
    this.logger.error('An error has occurred:', exceptionMessage)
  }

  /**
   * Instance a new logger with a defined namespace.
   */
  newLogger(namespace: string): ILogger {
    return getLogger(namespace)
  }

  setTulApi(tulApi: ITulApi): this {
    this._tulApi = tulApi

    return this
  }

  /**
   * Accessors and shortcuts.
   */

  get environment(): Environment {
    return this._environment
  }

  get appTranslator(): AppTranslator {
    return this._appTranslator
  }

  get appRouter(): AppRouterClient {
    return this._appRouter
  }

  get keyboardBindingsManager(): KeyboardBindingsManager {
    return this._keyboardBindingsManager
  }

  get stores(): IStores {
    return this._stores
  }

  get logger(): ILogger {
    return this._environment.logger
  }

  get fetchClient(): F {
    return this._environment.fetchClient
  }

  get wsClient(): TWSClient {
    return this._environment.wsClient
  }

  get tulApi(): MaybeUndef<ITulApi> {
    return this._tulApi
  }

  /* Private */

  private _instanciateStores() {
    /**
     * Stores are plain classes that handle all the business logic of the
     * application.
     * Stores are then injected in the UI layer to provide data to views.
     */
    // prettier-ignore
    this._stores = {
      // Common
      storeDebug: new stores.StoreDebug(this),
      storeLayout: new stores.StoreLayout(this),
      storeRbac: new stores.StoreRbac(this),

      // Layouts
      storeWSIndicator: new stores.StoreWSIndicator(this),
      storeBlades: new stores.StoreBlades(this),

      // Reports
      storeReports: new stores.StoreReports(this, {}),

      // Infra / Tenants
      storeInfrastructures: new stores.StoreInfrastructures(this, {}),

      // Checkers
      storeCheckers: new stores.StoreCheckers(this),

      // Messages / Alerts
      storeMessages: new stores.StoreMessages(this),
      storeAlerts: new stores.StoreAlerts(this),

      // Authentication
      storeAuthentication: new stores.StoreAuthentication(this),
      storeEula: new stores.StoreEula(this),

      // Dashboard
      storeDashboards: new stores.StoreDashboards(this),

      // TrailFlow
      storeTrailFlow: new stores.StoreTrailFlow(this),

      // IoE
      storeIoE: new stores.StoreIoE(this, { topologyContext: false }),

      // IoA
      storeIoA: new stores.StoreIoA(this),

      // Topology
      storeTopology: new stores.StoreTopology(this),

      // Management
      storeManagementUsers: new stores.StoreManagementUsers(this),
      storeManagementProfiles: new stores.StoreManagementProfiles(this),
      storeManagementRelays: new stores.StoreManagementRelays(this),
      storeManagementInfrastructures: new stores.StoreManagementInfrastructures(this),
      storeManagementDirectories: new stores.StoreManagementDirectories(this),
      storeManagementApplicationSettings: new stores.StoreManagementApplicationSettings(this),
      storeLockoutPolicy: new stores.StoreLockoutPolicy(this),
      storeManagementSyslogs: new stores.StoreManagementSyslogs(this),
      storeManagementEmails: new stores.StoreManagementEmails(this),
      storeManagementLDAPConfiguration: new stores.StoreManagementLDAPConfiguration(this),
      storeManagementSAMLConfiguration: new stores.StoreManagementSAMLConfiguration(this),
      storeManagementRbacRoles: new stores.StoreManagementRbacRoles(this),
      storeManagementAttackTypeConfiguration: new stores.StoreManagementAttackTypeConfiguration(this),
      storeManagementDataCollection: new stores.StoreManagementDataCollection(this),
      storeManagementAzureAD: new stores.StoreManagementAzureAD(this),
      storeManagementTenants: new stores.StoreManagementTenants(this),
      storeManagementReportingCenter: new stores.StoreManagementReportingCenter(this, { instanceName: 'reporting' }),

      // Preferences
      storePreferences: new stores.StorePreferences(this),
      storeApplicationConfiguration: new stores.StoreApplicationConfiguration(this),

      // About
      storeAbout: new stores.StoreAbout(this),

      // License
      storeLicense: new stores.StoreLicense(this),

      // Telemetry / Pendo
      storeTelemetry: new stores.StoreTelemetry(this),
      storePendo: new stores.StorePendo(this),

      // Debug
      storeAPIInspector: new stores.StoreAPIInspector(this),

      // AttackPath
      storeAttackPath: new stores.StoreAttackPath(this),

      // FeatureToggle
      storeFeatureFlags: new stores.StoreFeatureFlags(this),

      // ActivityLogs
      storeActivityLogs: new stores.StoreActivityLogs(this),

      // HealthCheck
      storeHealthCheck: new stores.StoreHealthCheck(this),
      storeHealthCheckGlobalStatus: new stores.StoreHealthCheckGlobalStatus(this),

      // AzureAD IdentityExplorer
      storeIdentityExplorer: new stores.StoreIdentityExplorer(this)
    }
  }

  /** action */
  @action
  incrementSecurityEngineKey(): void {
    this.$securityEngineKey.set(this.$securityEngineKey.get() + 1)
  }

  /** computed */
  @computed
  get securityEngineKey(): number {
    return this.$securityEngineKey.get()
  }
}
