import { createEntity } from '@app/entities'
import EntityDashboard from '@app/entities/EntityDashboard'
import EntityDashboardWidget from '@app/entities/EntityDashboardWidget'
import type EntityDashboardWidgetOptionsSerie from '@app/entities/EntityDashboardWidgetOptionsSerie'
import type { StoreRoot } from '@app/stores'
import type { WidgetKey } from '@libs/dashboards/types'
import type { InstanceName } from '@libs/Environment/types'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import type { MutationEditDashboardWidget } from '@server/graphql/mutations/dashboard'
import { mutationEditDashboardWidget } from '@server/graphql/mutations/dashboard'
import type {
  Dashboard,
  DashboardWidget,
  EditDashboardWidgetMutationArgs,
  Maybe
} from '@server/graphql/typeDefs/types'
import type { IObservableValue, ObservableMap } from 'mobx'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import type { Layout } from 'react-grid-layout'
import StoreFlags from '../helpers/StoreFlags'
import StoreBase from '../StoreBase'
import type { IStoreMultiInstanceOptions } from '../types'
import {
  GRID_COLS,
  GRID_ROW_HEIGHT,
  GRID_WIDTH,
  WIDGET_DEFAULT_LAYOUT,
  WIDGET_DELAY_MAPPING,
  WIDGET_HEIGHT_OFFSET,
  WIDGET_WIDTH_OFFSET
} from './consts'
import type StoreWidget from './StoreWidget'
import StoreWidgetsManagement from './StoreWidgetsManagement'
import type { IWidgetBounds } from './types'

export interface IStoreDashboardOptions extends IStoreMultiInstanceOptions {
  dashboardEntity: EntityDashboard
}

/**
 * Store in charge of the state of one dashboard.
 */
export default class StoreDashboard extends StoreBase<IStoreDashboardOptions> {
  storeFlags = new StoreFlags(this.storeRoot)

  // store in charge of the creation/edition/deletion of widgets
  storeWidgetsManagement: StoreWidgetsManagement

  /* Observables */

  // entity of the dashboard
  private $dashboardEntity: IObservableValue<EntityDashboard>

  // stores of widgets (one store for each widget)
  private $widgetStores = observable.map<WidgetKey, StoreWidget>()

  // layout of widgets
  private $widgetLayouts = observable.map<WidgetKey, Layout>()

  // timeouts for automatic reload
  private $timeoutWidgets: Map<WidgetKey, Maybe<NodeJS.Timeout>> = new Map()

  constructor(storeRoot: StoreRoot, options: IStoreDashboardOptions) {
    super(storeRoot, options)

    this.storeWidgetsManagement = new StoreWidgetsManagement(storeRoot, {
      instanceName: this.options.instanceName,
      storeDashboard: this
    })

    this.$dashboardEntity = observable.box(this.options.dashboardEntity)

    makeObservable(this)
  }

  /**
   * Set the instanceName used for this store.
   */
  setInstanceName(instanceName: InstanceName): this {
    this.setOptions({
      instanceName
    })

    this.storeWidgetsManagement.setOptions({
      instanceName
    })

    return this
  }

  /**
   * Set the refresh timeout according to the dataOption type.
   */
  setWidgetRefreshTimeout(
    widgetKey: WidgetKey,
    serieEntity: EntityDashboardWidgetOptionsSerie
  ): void {
    if (this.$timeoutWidgets.has(widgetKey)) {
      return
    }

    const dataOptionType = serieEntity.dataOptions?.type

    if (!dataOptionType) {
      return
    }

    const delay = WIDGET_DELAY_MAPPING[dataOptionType]

    if (!isDefined(delay)) {
      this.storeRoot.environment.logger.debug(
        `No refresh delay is stiget for dataOption type ${dataOptionType}`
      )
    }

    if (delay) {
      // use recursive setTimeout to order to consider potential long response delays
      const timeout = setTimeout(() => {
        const storeWidget = this.$widgetStores.get(widgetKey)

        if (!storeWidget) {
          return
        }

        storeWidget
          .fetchData(widgetKey)
          .then(() => {
            this.setWidgetRefreshTimeout(widgetKey, serieEntity)
          })
          .catch(() => {
            // if an error occurred during the refresh of the widget, clear the timeout
            this.clearWidgetRefreshTimeout(widgetKey)
          })
      }, delay)

      this.$timeoutWidgets.set(widgetKey, timeout)
    }
  }

  /**
   * Clear the refresh timeout for all widgets or one widget.
   */
  clearWidgetRefreshTimeout(widgetKey?: WidgetKey): void {
    const clearTimer = (timeoutWidget: NodeJS.Timeout) => {
      this.storeRoot.logger.debug(
        widgetKey
          ? `[dashboard] Clear widget refresh timer for ${widgetKey}`
          : `[dashboard] Clear all widget refresh timers`
      )
      clearTimeout(timeoutWidget)
    }

    // clear timers of all widgets
    if (!widgetKey) {
      this.$timeoutWidgets.forEach(timeoutWidget => {
        if (timeoutWidget) {
          clearTimer(timeoutWidget)
        }
      })

      this.$timeoutWidgets.clear()

      return
    }

    // clear the timer of one widget
    const timeout = this.$timeoutWidgets.get(widgetKey)
    if (timeout) {
      clearTimer(timeout)
    }
  }

  /* Actions */

  /**
   * Edit the layout of a widget on this dashboard.
   */
  editWidgetLayout(args: EditDashboardWidgetMutationArgs) {
    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor(args.dashboardWidget.instanceName)
          .query<MutationEditDashboardWidget>(mutationEditDashboardWidget, args)
      })
      .then(data => data.editDashboardWidget)
      .then(widget => {
        if (!widget) {
          throw new Error('Widget is not defined')
        }

        const dashboardEntity = createEntity<
          Partial<Dashboard>,
          EntityDashboard
        >(EntityDashboard, {
          id: args.dashboardWidget.dashboardId,
          instanceName: args.dashboardWidget.instanceName
        })

        const widgetEntity = createEntity<
          DashboardWidget,
          EntityDashboardWidget
        >(EntityDashboardWidget, widget, dashboardEntity)

        const widgetKey = widgetEntity.getWidgetKey()

        if (widgetKey) {
          const widgetLayout = widgetEntity.getLayout()

          if (!widgetLayout) {
            this.storeRoot.logger.warn(
              `No layout found for the widget ${widgetEntity.getDashboardKey()}`
            )
            return
          }

          this.setWidgetLayout(widgetKey, widgetLayout)
        }
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlags, {
          errorMessageTranslationFn: () => {
            return 'An error occurred while saving the new widget layout'
          }
        })
      )
  }

  /**
   * Set a store for each widget.
   */
  @action
  setWidgetStore(widgetKey: WidgetKey, widgetStore: StoreWidget): void {
    this.$widgetStores.set(widgetKey, widgetStore)
  }

  /**
   * Set the layout of the widget.
   */
  @action
  setWidgetLayout(widgetKey: WidgetKey, layout: Layout): void {
    this.$widgetLayouts.set(widgetKey, layout)
  }

  /* Computed */

  /**
   * Return the dashboard entity.
   */
  @computed
  get dashboardEntity(): EntityDashboard {
    return this.$dashboardEntity.get()
  }

  /**
   * Return widget stores.
   */
  @computed
  get storeWidgets(): ObservableMap<WidgetKey, StoreWidget> {
    return this.$widgetStores
  }

  /**
   * Return a list of widget stores.
   */
  @computed
  get storeWidgetsList(): StoreWidget[] {
    return Array.from(this.$widgetStores.values())
  }

  /**
   * Return all widgets layouts.
   */
  @computed
  get widgetLayouts(): Map<WidgetKey, Layout> {
    return toJS(this.$widgetLayouts)
  }

  /**
   * Return all widgets layouts as a list.
   */
  @computed
  get widgetLayoutsList(): Layout[] {
    return Array.from(this.$widgetLayouts.values()).map(layout => {
      return {
        // default layout will force minimal widget size, etc
        ...WIDGET_DEFAULT_LAYOUT,
        ...layout
      }
    })
  }

  /**
   * Return widget bounds.
   */
  @computed
  get widgetBounds(): Map<WidgetKey, IWidgetBounds> {
    const widgetsBounds = new Map()

    this.widgetLayoutsList.forEach(layout => {
      const theoreticalWidth = (GRID_WIDTH / GRID_COLS) * layout.w
      const theoreticalHeight = GRID_ROW_HEIGHT * layout.h

      // perform some adjustements
      const width = theoreticalWidth - WIDGET_WIDTH_OFFSET
      const height = theoreticalHeight - WIDGET_HEIGHT_OFFSET

      const bounds: IWidgetBounds = {
        width,
        height
      }

      widgetsBounds.set(layout.i, bounds)
    })

    return widgetsBounds
  }
}
