import { ContainerFlex } from '@app/components-legacy/Container'
import type StoreInputTenants from '@app/stores/StoreInputTenants'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import TenantsTree from './TenantsTree'

export interface IInputTenantsDrawerProps {
  className?: string
  storeInputTenants: StoreInputTenants
}

function getTenantsTreeItems(props: IInputTenantsDrawerProps): React.ReactNode {
  const { storeInputTenants } = props

  const entitiesTenant = Array.from(storeInputTenants.tenants.values()).filter(
    entityTenant => {
      const searchRegexp =
        storeInputTenants.storeInputSearch.transformedSearchValueAsRegexp

      return searchRegexp.test(entityTenant.getPropertyAsString('name'))
    }
  )

  return (
    <TenantsTree
      storeInputTenants={storeInputTenants}
      entitiesTenant={entitiesTenant}
    />
  )
}

const DrawerContent: React.FC<IInputTenantsDrawerProps> = props => {
  return (
    <ContainerFlex
      name="InputTenantsDrawerContent"
      className={props.className}
      items={getTenantsTreeItems(props)}
      direction="column"
    />
  )
}

export default observer(DrawerContent)
