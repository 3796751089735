import type { IEntityListable } from '@app/entities/types'
import type {
  IDataRowGeneric,
  IWidgetListColumns
} from '@app/stores/helpers/StoreWidgetList/types'
import * as React from 'react'
import type { IWidgetListTableCommonProps } from './types'

interface IWidgetTableTHeadContentProps<
  E extends IEntityListable<IDataRowGeneric>,
  D extends IDataRowGeneric
> extends IWidgetListTableCommonProps<E, D> {
  column: IWidgetListColumns<D>
  rows: D[]
}

/**
 * Render a cell of the THead.
 */
export function WidgetTableTHeadCell<
  E extends IEntityListable<IDataRowGeneric>,
  D
>(props: IWidgetTableTHeadContentProps<E, D>): React.ReactElement {
  const getContent = () => {
    if (props.headsRenderFn) {
      const renderFn = props.headsRenderFn[props.column.key]

      if (renderFn) {
        return renderFn(props.column.label, props.rows)
      }
    }

    return props.translate
      ? props.translate(props.column.label)
      : props.column.label
  }

  return <div>{getContent()}</div>
}

export default WidgetTableTHeadCell
