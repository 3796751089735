import type { Maybe } from '@@types/helpers'
import type { AppRouteName } from '@app/routes'
import { isDefined } from '@libs/isDefined'
import type { AppRouterClient } from '@libs/Router/types'

/**
 * Return true if the telemetry is disable on `routeName`.
 */
export function isTelemetryDisableOnRoute(appRouter: AppRouterClient) {
  return (routeName: Maybe<AppRouteName>) => {
    return (
      isDefined(routeName) &&
      appRouter.getRouteSpecs(routeName).noTelemetry === true
    )
  }
}

/**
 * Return true if the browser is Safari
 */
export function isSafariBrowser() {
  return (
    /constructor/i.test((window as any).HTMLElement) ||
    navigator.userAgent.indexOf('Safari') !== -1
  )
}
