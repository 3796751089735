import { Features } from '@alsid/common'
import { useStores } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { isDefined, isDefinedAndNotEmptyString } from '@libs/isDefined'
import * as React from 'react'

/**
 * Handle telemetry when the `submit` event is triggered.
 */
export function useFormSubmitTelemetry() {
  const featureTelemetry = useFeatureFlag({
    featureFlagName: Features.TELEMETRY
  })

  const { storeTelemetry } = useStores()

  const onFormSubmitTelemetry = React.useCallback((evt: Event) => {
    if (!featureTelemetry) {
      return
    }

    const target = evt.target
    if (!isDefined(target)) {
      return
    }

    const form = target as HTMLFormElement

    // If the 'submit' event does not come from a form
    if (form.nodeName !== 'FORM') {
      return
    }

    const formName = form.getAttribute('name')

    if (!isDefinedAndNotEmptyString(formName)) {
      return
    }

    storeTelemetry.logFormSubmitted(formName)
  }, [])

  React.useEffect(() => {
    document.addEventListener('submit', onFormSubmitTelemetry)

    return () => {
      document.removeEventListener('submit', onFormSubmitTelemetry)
    }
  }, [])
}
