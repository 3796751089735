import { ContainerFlex } from '@app/components/Container'
import { IconCheck } from '@app/components/Icon'
import InputTextLikePassword from '@app/components/Input/InputTextLikePassword'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useAppTranslator } from '@app/hooks'
import { useStores } from '@app/hooks/useStores'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import { AzureADSupportConfigurationFormFieldName } from '@app/stores/Management/StoreAzureADConfiguration/types'
import { Button } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleTenableCloudApiKeysOnSubmit } from './handlers'

interface IConfigurationAzureADSupportFormProps {}

const ConfigurationAzureADSupportForm: React.FC<
  IConfigurationAzureADSupportFormProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.AzureADSupport']
  })

  const { storeManagementAzureAD } = useStores()

  const [reinitKeys, setReinitKeys] = React.useState(false)

  if (!storeManagementAzureAD.isAzureAdSupportEnabled) {
    return null
  }

  const { storeFormAzureADSupportConfiguration: storeForm } =
    storeManagementAzureAD

  const isSubmitEnabled =
    storeForm.field(AzureADSupportConfigurationFormFieldName.accessKey).value &&
    storeForm.field(AzureADSupportConfigurationFormFieldName.secretKey).value

  const isFormDisabled =
    !reinitKeys &&
    storeManagementAzureAD.tenableCloudApiStatusSet &&
    storeManagementAzureAD.isTenableCloudApiTokensWorking

  function resetForm() {
    setReinitKeys(true)
    storeForm.reset()
  }

  function renderSubmit() {
    if (isFormDisabled) {
      return (
        <Button type="primary" onClick={() => resetForm()}>
          {translate('Edit keys')}
        </Button>
      )
    }

    return (
      <FormWrapperButton
        labelledBy="submit"
        variant={ButtonVariant.primary}
        size={ButtonSize.small}
        buttonProps={{
          size: ButtonSize.small,
          disabled: !isSubmitEnabled,
          loading:
            storeManagementAzureAD.storeFlagsAzureADConfigurationSubmit
              .isLoading,
          icon: <IconCheck color={'#FFFFFF'} />,
          onClick: e => {
            handleTenableCloudApiKeysOnSubmit(storeManagementAzureAD)(e).then(
              () => setReinitKeys(false)
            )
          }
        }}
      />
    )
  }

  return (
    <ContainerFlex
      name="ConfigurationAzureADSupportForm"
      alignItems="center"
      flexGap="default"
      items={[
        <InputTextLikePassword
          id="WidgetFormInputPassword"
          name={AzureADSupportConfigurationFormFieldName.accessKey}
          placeholder="AccessKey"
          value={
            isFormDisabled
              ? '******'
              : storeForm.getFieldValueAsString(
                  AzureADSupportConfigurationFormFieldName.accessKey
                )
          }
          onChange={onInputChange(storeForm)(
            AzureADSupportConfigurationFormFieldName.accessKey
          )}
          disabled={isFormDisabled}
        />,

        <InputTextLikePassword
          id="WidgetFormInputPassword"
          name={AzureADSupportConfigurationFormFieldName.secretKey}
          placeholder="SecretKey"
          value={
            isFormDisabled
              ? '******'
              : storeForm.getFieldValueAsString(
                  AzureADSupportConfigurationFormFieldName.secretKey
                )
          }
          onChange={onInputChange(storeForm)(
            AzureADSupportConfigurationFormFieldName.secretKey
          )}
          disabled={isFormDisabled}
        />,

        <div>{renderSubmit()}</div>
      ]}
    />
  )
}

export default observer(ConfigurationAzureADSupportForm)
