import { WrapperAlert } from '@app/components/Wrapper'
import { AlertSize } from '@app/components/Wrapper/types'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { AttackPathTruncationType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

const Message = styled.div`
  line-height: 22.5px;
`

export interface IAlertTruncatedProps {
  className?: string
}

const truncationTranslationMessages: {
  [key in AttackPathTruncationType]: string
} = {
  [AttackPathTruncationType.Cluster]:
    'For the sake of clarity and speed, Tenable.ad grouped them by node type',
  [AttackPathTruncationType.Omit]:
    'For the sake of clarity and speed, Tenable.ad omitted them'
}

const AlertTruncated: React.FC<IAlertTruncatedProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.Alert']
  })

  const { storeAttackPath } = useStores()
  const truncationType = storeAttackPath.truncationType

  if (!storeAttackPath.displayIsTruncatedAlert || !truncationType) {
    return null
  }

  return (
    <WrapperAlert
      className={props.className}
      name="AlertTruncated"
      labelledBy="alertTruncated"
      alertProps={{
        closable: true,
        message: (
          <>
            <Message>
              <b>{translate('Tenable.ad found other paths')}</b>
            </Message>
            <Message>
              {translate(truncationTranslationMessages[truncationType])}
            </Message>
          </>
        )
      }}
      size={AlertSize.large}
    />
  )
}

const ObservedAlertTruncated = observer(AlertTruncated)

export default styled(ObservedAlertTruncated)`
  margin-bottom: ${consts.marginSmall};
  pointer-events: auto;
`
