import { IllustrationWarningOutlined } from '@app/components-legacy/Icon/IconAntd'
import { handleWidgetRibbonVisibilityOnClick } from '@app/components-legacy/Label/LabelAppRibbon/handlers'
import { useStores } from '@app/hooks'
import { LicenseStatus } from '@app/stores/StoreLicense/types'
import { consts } from '@app/styles'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import WidgetContainer from '../WidgetContainer'

export interface IWidgetLicenseProps {}

enum RibbonVariant {
  version = 'version',
  license = 'license'
}

const WidgetRibbon: React.FC<IWidgetLicenseProps> = props => {
  const { storeLicense } = useStores()

  if (!storeLicense.isRibbonDisplayed) {
    return null
  }

  const variant = getVariant(storeLicense.licenseStatus)
  const color = variant ? getRibbonColor(variant) : undefined

  return (
    <div style={{ backgroundColor: color }}>
      <WidgetContainer
        name="WidgetRibbon"
        ariaRoles={['menu', 'figure']}
        labelledBy="license"
        onClick={handleWidgetRibbonVisibilityOnClick(
          storeLicense,
          !storeLicense.isRibbonVisible
        )}
      >
        <IllustrationWarningOutlined style={{ color }} />
      </WidgetContainer>
    </div>
  )
}

function getRibbonColor(color: RibbonVariant): string {
  switch (color) {
    case RibbonVariant.license:
      return consts.colorRed001

    default:
      return consts.colorPurple010
  }
}

function getVariant(licenseStatus: LicenseStatus): RibbonVariant {
  switch (licenseStatus) {
    case LicenseStatus.violated:
    case LicenseStatus.expired:
    case LicenseStatus.nearViolated:
    case LicenseStatus.overViolated:
      return RibbonVariant.license

    case LicenseStatus.valid:
    case LicenseStatus.unset:
      return RibbonVariant.version

    default:
      assertUnreachableCase(licenseStatus)
  }
}

export default observer(WidgetRibbon)
