/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Resource
 */
export interface Resource {
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof Resource
   */
  url: string
}

/**
 * Check if a given object implements the Resource interface.
 */
export function instanceOfResource(value: object): boolean {
  if (!('name' in value)) return false
  if (!('url' in value)) return false
  return true
}

export function ResourceFromJSON(json: any): Resource {
  return ResourceFromJSONTyped(json, false)
}

export function ResourceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Resource {
  if (json == null) {
    return json
  }
  return {
    name: json['name'],
    url: json['url']
  }
}

export function ResourceToJSON(value?: Resource | null): any {
  if (value == null) {
    return value
  }
  return {
    name: value['name'],
    url: value['url']
  }
}
