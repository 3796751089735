import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import { handleRelaysEditOnSubmit } from './handlers'
import RelayCommonForm from './RelayCommonForm'

interface IRelayEditFormProps {
  relayId: number
}

const RelayEditForm: React.FC<IRelayEditFormProps> = props => {
  const appRouter = useAppRouter()

  const { storeManagementRelays } = useStores()

  return (
    <RelayCommonForm
      storeForm={storeManagementRelays.storeFormEdition}
      version="edition"
      onSubmit={handleRelaysEditOnSubmit(
        appRouter,
        storeManagementRelays
      )(props.relayId)}
    />
  )
}

export default RelayEditForm
