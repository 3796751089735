import {
  ContainerContent,
  ContainerFooter,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import { AuthProviderName } from '@server/graphql/typeDefs/types'
import { Input } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditCredentials } from '../permissions'
import { onChangePasswordSubmit } from './handlers'

export interface IChangePasswordFormProps {}

const ChangePasswordForm: React.FC<IChangePasswordFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.Accounts.Users',
      'Preferences.PersonalSettings.Navigation',
      'Preferences.PersonalSettings.Security'
    ]
  })

  const { storeRbac, storeAuthentication, storePreferences, storeAbout } =
    useStores()

  const { storeFormSecurity } = storePreferences

  const fields: IField[] = [
    {
      name: 'oldPassword',
      label: translate('Old password'),
      required: true,
      errors: storeFormSecurity.field('oldPassword').errors,
      control: (
        <Input.Password
          name="oldPassword"
          onChange={onInputChange(storeFormSecurity)('oldPassword')}
          value={storeFormSecurity.getFieldValueAsString('oldPassword')}
          autoComplete="new-password"
        />
      )
    },
    {
      name: 'newPassword',
      label: translate('New password'),
      required: true,
      errors: storeFormSecurity.field('newPassword').errors,
      control: (
        <Input.Password
          name="newPassword"
          onChange={onInputChange(storeFormSecurity)('newPassword')}
          value={storeFormSecurity.getFieldValueAsString('newPassword')}
          autoComplete="new-password"
        />
      )
    },
    {
      name: 'newPasswordConfirmation',
      label: translate('Confirmation of the new password'),
      required: true,
      errors: storeFormSecurity.field('newPasswordConfirmation').errors,
      control: (
        <Input.Password
          name="newPasswordConfirmation"
          onChange={onInputChange(storeFormSecurity)('newPasswordConfirmation')}
          value={storeFormSecurity.getFieldValueAsString(
            'newPasswordConfirmation'
          )}
          autoComplete="new-password"
        />
      )
    }
  ]

  const whoAmI = storeAuthentication.whoAmI
  const provider = whoAmI && whoAmI.provider

  if (provider !== AuthProviderName.Tenable) {
    return (
      <ContainerContent title={translate('Credentials')}>
        <p>
          {translate(
            'You cannot change a password for accounts connected through external providers in Tenable.ad'
          )}
        </p>
      </ContainerContent>
    )
  }

  return (
    <FormWrapper
      name="passwordChange"
      onSubmit={onChangePasswordSubmit(storeFormSecurity, storePreferences)}
    >
      <>
        <ContainerContent title={translate('Credentials')}>
          <ContainerForm fields={fields} />
        </ContainerContent>

        <Portal name={PlaceHolderName.bladeFooter}>
          <ContainerFooter>
            <FormWrapperButtonSubmit
              loading={storePreferences.storeFlagsSecurity.flags.isLoading}
              disabled={
                !storeRbac.isUserGrantedTo(
                  rbacCapabilityCheckAllOf(canEditCredentials(storeAbout))
                )
              }
            >
              {translate('Save')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </>
    </FormWrapper>
  )
}

export default observer(ChangePasswordForm)
