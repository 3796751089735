import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import { IconClose } from '@app/components-legacy/Icon'
import { consts } from '@app/styles'
import { isDefined } from '@libs/isDefined'
import * as React from 'react'
import styled from 'styled-components'
import { LabelAlt, LabelDate } from '..'
import { LabelAltVariant } from '../LabelAlt'
import LabelSliced from '../LabelSliced'
import type { ILabelAlertProps } from './types'

interface IAlertHeaderProps {
  className?: string
  label: ILabelAlertProps['label']
  date: ILabelAlertProps['date']
  read?: boolean
  onClose?: Maybe<() => void>
}

const Title = styled.div`
  padding: ${consts.paddingSmall};
  // let the space for the triangle decoration
  padding-left: ${consts.paddingExtraLarge};
`

const AlertHeader: React.FC<IAlertHeaderProps> = props => {
  const renderLabel = () => {
    const variant = props.read ? LabelAltVariant.Normal : LabelAltVariant.Bolded

    return (
      <LabelAlt variant={variant}>
        <Title>
          <LabelSliced maxWidth={170} value={props.label} />
        </Title>
      </LabelAlt>
    )
  }

  return (
    <ContainerFlex
      name="AlertHeader"
      className={props.className}
      alignItems="center"
      justifyContent="space-between"
      items={[
        renderLabel(),

        <ContainerFlex
          name="AlertHeaderRight"
          alignItems="center"
          items={[
            isDefined(props.date) && (
              <LabelAlt>
                <LabelDate date={props.date} />
              </LabelAlt>
            ),

            props.onClose && <IconClose onClick={props.onClose} />
          ]}
        />
      ]}
    />
  )
}

export default styled(AlertHeader)`
  padding: 0 ${consts.paddingSmall};
`
