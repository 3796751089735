import { useAppTranslator, useStores } from '@app/hooks'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { consts } from '@app/styles'
import { computeLabelColor } from '@app/styles/colors/getLabelColor'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

export interface ISVGTooltipProps extends React.SVGProps<SVGImageElement> {
  width: number
  height: number
  nodeId: string
  adObjectId: number
  name: string
  isImportantNode: boolean
  directoryId: number
  isHighlighted: boolean
  isPartOfCurrentPath: boolean
  isHoveringNode: boolean
  isPinnedNode: boolean
}

const StyledG = styled.g<{
  isHighlighted: boolean
  isPartOfCurrentPath: boolean
  isHoveringNode: boolean
  isPinnedNode: boolean
  isShowingAllTooltips: boolean
  k: number
}>`
  text {
    text-anchor: middle;
    user-select: none;
    padding: ${props =>
      props.k < 0.5
        ? '15px 15px'
        : props.k < 0.8
          ? '10px 15px'
          : props.k > 1.3
            ? '2px 12px'
            : '5px 12px'};
    border-radius: 12px;
    font-size: ${props => {
      if (props.k < 0.5) {
        return consts.textSVGSizeExtraLarge
      }
      if (props.k < 0.8) {
        return consts.textSVGSizeLarge
      }
      if (props.k > 1.8) {
        return consts.textSVGSizeExtraSmall
      }
      if (props.k > 1.3) {
        return consts.textSVGSizeSmall
      }

      return consts.textSVGSizeNormal
    }};
    opacity: ${props =>
      props.isHighlighted ||
      ((props.isHoveringNode || props.isPinnedNode) &&
        props.isPartOfCurrentPath) ||
      props.isShowingAllTooltips
        ? 1
        : 0};
    transition:
      opacity 200ms ease,
      transform 200ms ease;
    transform: translate(
      ${props =>
        props.isHighlighted ||
        ((props.isHoveringNode || props.isPinnedNode) &&
          props.isPartOfCurrentPath) ||
        props.isShowingAllTooltips
          ? '0, 0'
          : '0px, 15px'}
    );
  }

  rect,
  polygon {
    opacity: ${props =>
      props.isHighlighted ||
      ((props.isHoveringNode || props.isPinnedNode) &&
        props.isPartOfCurrentPath) ||
      props.isShowingAllTooltips
        ? 1
        : 0};
    transition:
      opacity 200ms ease,
      transform 200ms ease;
    transform: translate(
      ${props =>
        props.isHighlighted ||
        ((props.isHoveringNode || props.isPinnedNode) &&
          props.isPartOfCurrentPath) ||
        props.isShowingAllTooltips
          ? '0, 0'
          : '0px, 15px'}
    );
  }
`

const SVGTooltip: React.FC<ISVGTooltipProps> = props => {
  const { storeAttackPath, storeInfrastructures } = useStores()

  const translate = useAppTranslator({
    namespaces: ['AttackPath.Chart']
  })

  const { testAttributeProps } = useTestAttribute('contentinfo')

  const directory = storeInfrastructures.getDirectoryFromId(props.directoryId)

  const { k } = storeAttackPath.chartZoomData
  const { isShowingAllTooltips } = storeAttackPath

  const isImportantNode = props.isImportantNode

  // SVG id should not contain any special characters nor spaces
  const cleanedName = props.name.replace(/[^a-z0-9]/gim, '')

  const textId = `node-${cleanedName}-${props.nodeId.split('-')[0]}`
  const textSvg = document.querySelector<SVGGraphicsElement>(`#${textId}`)

  const box = textSvg?.getBoundingClientRect()
  const boxSvg = textSvg?.getBBox?.()

  const style = textSvg ? getComputedStyle(textSvg) : null

  const paddingTop = style ? parseInt(style.paddingTop, undefined) : 0
  const paddingLeft = style ? parseInt(style.paddingLeft, undefined) : 0
  const paddingRight = style ? parseInt(style.paddingRight, undefined) : 0
  const paddingBottom = style ? parseInt(style.paddingBottom, undefined) : 0
  const borderRadius = style ? parseInt(style.borderRadius, undefined) : 0

  const endX = boxSvg ? boxSvg.width + paddingLeft + paddingRight : 0
  const endY = box ? box.height + paddingTop + paddingBottom : 0

  // Build svg id from directory ID
  const directoryId = `directory-${props.directoryId}`
  const directoryNameSvg = document.querySelector<SVGGraphicsElement>(
    `#${directoryId}`
  )

  const boxDirectorySvg = directoryNameSvg?.getBBox?.()

  // Compute points for the triangle in the tooltip
  const pointRightX =
    boxSvg && boxDirectorySvg
      ? boxSvg.x + boxSvg.width - boxDirectorySvg.width - (k > 0.8 ? 2 : 1)
      : 0
  const pointLeftX = pointRightX - (k > 0.8 ? 8 : k > 0.5 ? 9 : 11)
  const pointCrossX = pointLeftX + (pointRightX - pointLeftX) / 2

  const pointY =
    k > 0.5
      ? isImportantNode
        ? '-57'
        : '-42'
      : isImportantNode
        ? '-58'
        : '-43'
  const pointCrossY =
    k > 1.3
      ? isImportantNode
        ? '-64'
        : '-49'
      : k > 0.8
        ? isImportantNode
          ? '-66'
          : '-51'
        : k > 0.5
          ? isImportantNode
            ? '-68'
            : '-53'
          : isImportantNode
            ? '-72'
            : '-57'

  const paddingDirectoryName = 8
  const triangleSpace = k > 0.5 ? 12 : 14

  const mainNodeSpace = isImportantNode ? 15 : 0

  const color = (directory && directory.color) || consts.colorBlueGray020

  return (
    <StyledG
      isHighlighted={props.isHighlighted}
      isPartOfCurrentPath={
        props.isPartOfCurrentPath || storeAttackPath.isAllGraphHighlighted
      }
      isHoveringNode={props.isHoveringNode}
      isPinnedNode={props.isPinnedNode}
      isShowingAllTooltips={isShowingAllTooltips}
      k={k}
      pointerEvents="none"
    >
      {/*Background SVG for the tooltip*/}
      <rect
        x={-props.width / 2 + 35 / 2 - endX / 2 + mainNodeSpace}
        y={-props.height / 2 - 25 - endY * 0.65}
        width={endX + paddingDirectoryName / 2}
        height={endY}
        rx={borderRadius}
        fill={consts.colorAttackPathSecondary}
        {...testAttributeProps()(`tooltip-node-${props.adObjectId}`)}
      />

      {/*Background SVG for the directory name*/}
      <rect
        x={
          boxSvg && boxDirectorySvg
            ? boxSvg.x +
              boxSvg.width -
              boxDirectorySvg.width -
              (k > 0.5 ? 14 : 16)
            : 0
        }
        y={-props.height / 2 - 25 - endY * 0.65 + 1}
        width={
          boxDirectorySvg
            ? boxDirectorySvg.width + (paddingDirectoryName + triangleSpace)
            : 0
        }
        height={endY > 0 ? endY - 2 : endY}
        rx={2}
        fill={color}
      />

      <polygon
        points={`${pointCrossX} ${pointCrossY}, ${pointLeftX} ${pointY}, ${pointRightX} ${pointY}`}
        fill={computeLabelColor(color)}
      />

      <text
        id={textId}
        x={-props.width / 2 + 35 / 2 + mainNodeSpace}
        y={-props.height / 2 - 25}
        fill={consts.colorWhite}
      >
        {props.name.toUpperCase()}
        <tspan
          id={directoryId}
          className="directoryName"
          dx="20"
          fill={computeLabelColor(color)}
        >
          {directory && directory.name
            ? directory.name.toUpperCase()
            : translate('Unknown domain').toUpperCase()}
        </tspan>
      </text>
    </StyledG>
  )
}

export default observer(SVGTooltip)
