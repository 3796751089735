import { updateIoABoardUrlWithFilters } from '@app/pages/IoA/IoABoardPage/functions'
import type { StoreIoA } from '@app/stores'

/**
 * Update the url on search submit and fetch attacks summary.
 */
export const handleAttackSearchSubmit = (storeIoA: StoreIoA) => (): void => {
  updateIoABoardUrlWithFilters(storeIoA)

  storeIoA.storeBoard.reloadAttacksSummary()
}
