import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '../Container'

interface ILayoutFatalErrorProps {
  className?: string
  content: React.ReactNode
}

const LayoutFatalError: React.FC<ILayoutFatalErrorProps> = props => {
  return (
    <ContainerFlex
      name="LayoutFatalError"
      className={props.className}
      justifyContent="center"
      items={[
        <ContainerFlex
          name="LayoutFatalErrorVerticalAlign"
          direction="column"
          justifyContent="center"
          items={[props.content]}
        />
      ]}
      fullHeight
    />
  )
}

export default styled(LayoutFatalError)`
  background-color: ${consts.colorLoginLayoutBackground};
  position: absolute;
  height: 100%;
  width: 100%;
`
