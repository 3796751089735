export enum IoEBoardMenuItemKey {
  All = 'All',
  // Active Directory (Active Directory Domain Services)
  ActiveDirectory = 'ADDS',
  // Microsoft Entra ID
  AzureAD = 'AAD'
}

/**
 * Value used as an url params that select a specific tab on the IOE page.
 */
export enum IndicatorUrlParameter {
  'all' = 'all',
  'ad' = 'ad',
  'meid' = 'meid'
}
