import { Blade } from '@app/components-legacy/Blade'
import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { Drawer } from '@app/components-legacy/Drawer'
import { DrawerContext } from '@app/components-legacy/Drawer/context'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useHistoryChange } from '@app/components-legacy/Router/hooks'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import IoAAttacksPage from '../IoAAttacksPage'
import { handleAttacksHistoryOnChange } from '../IoAAttacksPage/handlers'
import { IoABoardPageContext } from './context'
import { handleIoABoardLoad, handleIoABoardUnload } from './handlers'
import IoABoardCardDrawer from './IoABoardCardDrawer'
import IoABoardCards from './IoABoardCards'
import IoABoardDrawerExport from './IoABoardDrawerExport'
import IoABoardFilters from './IoABoardFilters'
import IoABoardFooter from './IoABoardFooter'
import IoABoardSearch from './IoABoardSearch'
import { canAccessToIoABoard } from './permissions'

interface IIoABoardBladeProps {}

const IoABoardBlade: React.FC<IIoABoardBladeProps> = () => {
  const { storeIoA } = useStores()
  const { storeBoard } = storeIoA
  const { storeDrawerEditCard } = storeBoard.storeSummaryCards

  const boardFilters = storeIoA.computeBoardQueryParameters()

  // listen on history changes to create/remove blades to attacks investigation
  // according to the filters in the querystring
  useHistoryChange({
    onHistoryChange: handleAttacksHistoryOnChange(storeIoA)
  })

  return (
    <IoABoardPageContext.Provider value={{ storeBoard }}>
      <Blade
        root={true}
        routeDefinition={{
          routeName: AppRouteName.IoA_Board,
          parameters: {},
          queryStringParameters: {
            boardFilters
          }
        }}
        onLoad={handleIoABoardLoad(storeBoard)}
        onUnload={handleIoABoardUnload(storeBoard)}
        flags={[
          storeBoard.storeInfrastructures.storeFlagsFetchInfrastructures.flags,
          storeBoard.storeInputCheckersAttacks.storeFlagsFetchCheckers.flags,
          storeBoard.storeFlagsFetchAttacksSummary.flags
        ]}
        layout={{
          name: 'default',
          header: <IoABoardFilters />,
          footer: <IoABoardFooter />,
          content: (
            <ContainerContent
              spinner={<SpinnerInline />}
              flags={[storeBoard.storeFlagsFetchAttacksSummary.flags]}
            >
              <ContainerFlex
                name="IoABoardBladeContent"
                direction="column"
                items={[<IoABoardSearch />, <IoABoardCards />]}
                spaced
                fullHeight
              />
            </ContainerContent>
          )
        }}
        rbacCapabilityCheck={canAccessToIoABoard}
      />

      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName: AppRouteName.IoA_Attacks,
              parameters: {}
            },
            component: IoAAttacksPage,
            rbacPermissionsCheck: () => canAccessToIoABoard
          }
        ]}
      />

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerContext.Provider value={{ storeDrawer: storeDrawerEditCard }}>
          <Drawer>
            <IoABoardCardDrawer />
          </Drawer>
        </DrawerContext.Provider>
      </Portal>

      <Portal name={PlaceHolderName.genericDrawer}>
        <IoABoardDrawerExport />
      </Portal>
    </IoABoardPageContext.Provider>
  )
}

export default observer(IoABoardBlade)
