import { useAppRouter, useAppTranslator, useStores } from '@app/hooks'
import type { IIoABoardPDFRouteDefinition } from '@app/routes'
import { barChartTooltipDateByAttacksSummaryPeriod } from '@app/stores/IoA/StoreBoard/StoreSummaryCard/utils'
import { formatDate } from '@libs/dates/formatDate'
import { convertTimestampToIsoString, addDuration } from '@libs/dates/helpers'
import type {
  AttacksSummaryPeriod,
  DashboardWidgetOptionsSerieStatDataPointCount,
  Language
} from '@server/graphql/typeDefs/types'
import * as React from 'react'
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts'
import type { AxisDomain, Margin } from 'recharts/types/util/types'

interface ICardBarChartProps {
  width: number
  height: number
  margin: Margin
  language: Language
  directoryColor: string
  attacksSummaryPeriod: AttacksSummaryPeriod
  data: DashboardWidgetOptionsSerieStatDataPointCount[]
  yDomain?: AxisDomain
}

const CardBarChart: React.FC<ICardBarChartProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['IoA.Board']
  })

  const { storeIoA } = useStores()

  const appRouter = useAppRouter()

  const queryStringParameters =
    appRouter.getCurrentRouteQueryStringParameters<
      IIoABoardPDFRouteDefinition['queryStringParameters']
    >()

  return (
    <BarChart
      width={props.width}
      height={props.height}
      data={props.data}
      margin={props.margin}
    >
      <CartesianGrid strokeDasharray="1 1" />

      <YAxis
        tick={{ fontSize: 10 }}
        tickCount={1}
        dataKey="count"
        type="number"
        domain={props.yDomain}
      />

      <XAxis
        tick={{ fontSize: 10 }}
        tickFormatter={timestamp =>
          formatDate(convertTimestampToIsoString(timestamp), {
            utc: queryStringParameters?.useUtc === 'true',
            timezone: queryStringParameters?.timezone,
            format: storeIoA.storeBoard.storeTimeline.tickDateFormat
          })
        }
        dataKey="timestamp"
      />

      <Tooltip
        labelFormatter={timestamp => {
          const dateStart = convertTimestampToIsoString(timestamp)
          const dateEnd = addDuration(
            dateStart,
            storeIoA.storeBoard.storeTimeline.interval
          )
          if (!dateEnd) {
            return ''
          }
          return barChartTooltipDateByAttacksSummaryPeriod(
            dateStart,
            dateEnd,
            storeIoA.storeBoard.storeTimeline.attacksSummaryPeriod,
            storeIoA.storeRoot.appTranslator.language
          )
        }}
        formatter={(value: string) => {
          return [value, translate('Count')]
        }}
      />

      <Bar dataKey="count" fill={props.directoryColor} barSize={30} />
    </BarChart>
  )
}

export default CardBarChart
