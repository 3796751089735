import type { PropertiesNullable } from '@@types/helpers'
import type {
  Category,
  Maybe,
  Recommendation,
  Resource
} from '@server/graphql/typeDefs/types'
import { createEntities, EntityResource } from '.'
import EntityBase from './EntityBase'

export default class EntityRecommendation
  extends EntityBase
  implements PropertiesNullable<Recommendation>
{
  name: Maybe<string> = null
  description: Maybe<string> = null
  execSummary: Maybe<string> = null
  detail: Maybe<string> = null

  resources: Maybe<Resource[]> = null

  constructor(data: Partial<Category>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return resource entities
   */
  getResources(): EntityResource[] {
    return createEntities<Resource, EntityResource>(
      EntityResource,
      this.resources
    )
  }
}
