import { ErrorBoundary, ErrorMessage } from '@app/components/Error'
import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { IconNoData } from '@app/components-legacy/Icon'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { useWindowSize } from '@app/hooks/useWindowSize'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { onAddAForestButtonClick } from './handlers'
import { useArrayRedrawOnResize, useSceneDimensions } from './hooks'
import SceneChart from './SceneChart'
import SceneHeader from './SceneHeader'
import SceneLegend from './SceneLegend'
import SceneTooltip from './SceneTooltip'

interface ISceneProps {
  className?: string
}

const Scene: React.FC<ISceneProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Components.InputInfrastructures']
  })

  const appRouter = useAppRouter()
  const windowSize = useWindowSize()
  const sceneDivRef = useSceneDimensions(windowSize)
  useArrayRedrawOnResize(windowSize)

  const { storeTopology } = useStores()

  if (storeTopology.topologyEntity.getInfrastructures().length === 0) {
    return (
      <ContainerFlex
        name="ContainerNoForestFound"
        className={props.className}
        alignItems="center"
        justifyContent="center"
        items={[
          <IconNoData text={translate('No forest found')} />,

          <FormWrapperButton
            labelledBy="addForest"
            buttonProps={{
              type: 'primary',
              onClick: onAddAForestButtonClick(appRouter)
            }}
          >
            {translate('Add a forest')}
          </FormWrapperButton>
        ]}
        direction="column"
        spaced
        spaceWidth="large"
      />
    )
  }

  return (
    <ErrorBoundary errorComponent={<ErrorMessage />}>
      <div data-name="Scene" ref={sceneDivRef} className={props.className}>
        {storeTopology.sceneIsReady && (
          <>
            <SceneTooltip />
            <SceneChart />
            <SceneHeader />
            <SceneLegend />
          </>
        )}
      </div>
    </ErrorBoundary>
  )
}

const ObservedScene = observer(Scene)

// force container to be full-sized to get correct size dimensions
export default styled(ObservedScene)`
  width: 100%;
  height: 100%;

  .empty {
    font-size: 16px;
    text-align: center;

    a {
      display: block;
      padding: 10px 20px;
      background-color: ${consts.colorBlue010};
      color: white;
      margin-top: 50px;
    }
  }
`
