import type { Maybe } from '@@types/helpers'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import * as React from 'react'

const CheckerIdentityDetailsPageContext = React.createContext<
  Maybe<{
    // checkerId are uuid for identity checkers
    checkerId: string
    checkerCodename: GenericCheckerCodename
  }>
>(null)

export { CheckerIdentityDetailsPageContext }
