import { ContainerContent } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks/useStores'
import { grant } from '@libs/rbac/permissions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import EventDetailsAttributesTable from './EventDetailsAttributesTable'

interface IEventDetailsAttributesPageProps {
  className?: string
}

const EventDetailsAttributesPage: React.FC<
  IEventDetailsAttributesPageProps
> = props => {
  const {
    storeTrailFlow: { storeEventDetails }
  } = useStores()

  return (
    <ContainerContent
      flags={storeEventDetails.storeFlagsEventDetails.flags}
      rbacCapabilityCheck={grant()}
    >
      <EventDetailsAttributesTable />
    </ContainerContent>
  )
}

export default observer(EventDetailsAttributesPage)
