/**
 * Generate a unique hash from a string.
 * Do not use this to hash sensible information, it's easily reversible.
 */
export function generateHashFromString(s: string): string {
  // Code adapted from https://stackoverflow.com/a/7616484
  let hash = 0

  if (s.length === 0) {
    return String(hash)
  }

  for (let i = 0; i < s.length; i++) {
    const charCode = s.charCodeAt(i)
    hash = (hash << 5) - hash + charCode
    hash |= 0 // Convert to 32bit integer
  }

  return String(hash)
}
