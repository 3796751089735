import { ContainerFlex } from '@app/components/Container'
import { IconCloseSmall } from '@app/components/Icon'
import { Label, LabelSliced } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useStores } from '@app/hooks'
import { TenableBordersColor, TenableTextColor } from '@app/styles/consts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

const StyledFormWrapperButton = styled(FormWrapperButton)`
  margin-right: -8px;
  margin-top: -2px;
`

const StyledContainerUserHeader = styled(ContainerFlex)`
  padding: 15px;
`

interface IWidgetUserHeaderProps {
  className?: string
  hidePopover: () => void
}

const WidgetUserHeader: React.FC<IWidgetUserHeaderProps> = props => {
  const { storeAuthentication } = useStores()

  return (
    <ContainerFlex
      name="WidgetUserHeader"
      className={props.className}
      direction="column"
      items={[
        <StyledContainerUserHeader
          name="WidgetUserHeaderTop"
          justifyContent="space-between"
          items={[
            <div>
              <Label variant={LabelVariant.tenableH3} labelledBy="username">
                <LabelSliced
                  value={
                    (storeAuthentication.whoAmI &&
                      `${storeAuthentication.whoAmI.getPropertyAsString(
                        'name'
                      )} ${storeAuthentication.whoAmI.getPropertyAsString(
                        'surname'
                      )}`) ||
                    'Unknown user'
                  }
                  maxWidth={200}
                />
              </Label>
              <br />
              <Label
                variant={LabelVariant.tenableSmallText}
                color={TenableTextColor.textAlt}
              >
                <LabelSliced
                  value={
                    (storeAuthentication.whoAmI &&
                      storeAuthentication.whoAmI.getPropertyAsString(
                        'email'
                      )) ||
                    'Unknown email'
                  }
                  labelledBy="widgetUserHeaderEmail"
                  maxWidth={200}
                />
              </Label>
            </div>,
            <StyledFormWrapperButton
              labelledBy="close"
              variant={ButtonVariant.colored}
              size={ButtonSize.small}
              icon={IconCloseSmall}
              iconSize={16}
              buttonProps={{
                onClick: props.hidePopover
              }}
            />
          ]}
        ></StyledContainerUserHeader>
      ]}
      spaced
      spaceWidth="smaller"
    />
  )
}

const ObservedWidgetUserHeader = observer(WidgetUserHeader)

export default styled(ObservedWidgetUserHeader)`
  border-bottom: 1px solid ${TenableBordersColor.borderBase};
`
