import { ContainerContent, ContainerFlex } from '@app/components/Container'
import SpinnerInline, {
  SpinnerInlineSize
} from '@app/components-legacy/Spinner/SpinnerInline'
import type { IFlags } from '@app/stores/helpers/StoreFlags/types'
import { isDefined } from '@libs/isDefined'
import * as React from 'react'
import { useMeasure } from 'react-use'
import SimpleBar from 'simplebar-react'
import styled from 'styled-components'
import { TableBody } from './TableBody'
import { TableHeader } from './TableHeader'
import type {
  TableColumnProperties,
  TableColumnsProperties,
  TableInterfaceType,
  TableRowData
} from './types'

const StyledDiv = styled.div`
  width: 100%;
`

const SimpleBarContainer = styled.div`
  height: 100%;
  overflow: hidden;
`

const StyledSimpleBar = styled(SimpleBar)`
  height: 100%;
`

const StyledContainerFlex = styled(ContainerFlex)`
  height: 100%;
`

interface ITableProps<T extends TableInterfaceType> {
  className?: string
  style?: React.CSSProperties
  labelledBy: string
  columns: TableColumnsProperties<T>
  data: Array<TableRowData<T>>
  rowLinkTo?: (rowData: TableRowData<T>) => string
  flags?: IFlags | IFlags[]
  renderNoData?: () => React.ReactNode
  hideHeaderIfNoData?: boolean
}

export function Table<T extends TableInterfaceType>(props: ITableProps<T>) {
  const [ref, { width: tableWidth }] = useMeasure<HTMLDivElement>()

  const columnsValues: Array<TableColumnProperties<T, any>> = Object.values(
    props.columns
  ).filter(column => !column.hidden)

  // Total width minus margins and fixed width
  const availableWidth =
    tableWidth -
    // padding
    2 * 20 -
    // margins between columns
    (columnsValues.length - 1) * 20 -
    // fixed width columns
    columnsValues
      .map(columnValue => {
        return columnValue.fixedWidth || 0
      })
      .reduce((acc, width) => {
        return acc + width
      }, 0)

  const totalRelativeWidth = columnsValues
    .map(columnValue => {
      if (isDefined(columnValue.fixedWidth)) {
        return 0
      }
      return isDefined(columnValue.relativeWidth)
        ? columnValue.relativeWidth
        : 1
    })
    .reduce((acc, relativeWidth) => acc + relativeWidth, 0)

  return (
    <StyledDiv ref={ref} className={props.className}>
      <StyledContainerFlex
        name="Table"
        direction="column"
        items={[
          (props.data.length > 0 || !props.hideHeaderIfNoData) && (
            <TableHeader
              labelledBy={props.labelledBy}
              columns={props.columns}
              availableWidth={availableWidth}
              totalRelativeWidth={totalRelativeWidth}
            />
          ),
          <SimpleBarContainer>
            <ContainerContent
              flags={props.flags}
              spinner={<SpinnerInline size={SpinnerInlineSize.small} />}
            >
              {props.data.length === 0 && props.renderNoData ? (
                <StyledContainerFlex
                  name="TableBodyNoResult"
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                  items={[props.renderNoData()]}
                />
              ) : (
                <StyledSimpleBar autoHide={false}>
                  <TableBody
                    labelledBy={props.labelledBy}
                    columns={props.columns}
                    data={props.data}
                    availableWidth={availableWidth}
                    totalRelativeWidth={totalRelativeWidth}
                    rowLinkTo={props.rowLinkTo}
                  />
                </StyledSimpleBar>
              )}
            </ContainerContent>
          </SimpleBarContainer>
        ]}
        spaced
        spaceWidth="verySmall"
      />
    </StyledDiv>
  )
}
