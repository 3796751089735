import type { Maybe } from '@@types/helpers'
import type { StoreRoot } from '@app/stores'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreEscapable, IStoreOptions } from '@app/stores/types'
import type { EscapeHandler } from '@libs/KeyboardBindingsManager/EscapeHandler'
import { KeyboardKey } from '@libs/KeyboardBindingsManager/types'
import { action, computed, makeObservable, observable } from 'mobx'

export enum DrawerState {
  isClosed = 0,
  isOpened = 1 << 0
}

/**
 * Simple store to store a drawer status.
 */
export default class StoreDrawer<TMetadata extends object>
  extends StoreBase
  implements IStoreEscapable
{
  /**
   * Observables
   */

  // optional data for the drawer
  private $data = observable.box<Maybe<Partial<TMetadata>>>(null)

  @observable
  private $drawerState = DrawerState.isClosed

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Retrieve data.
   */
  getDataValue<K extends keyof TMetadata>(key: K): Maybe<TMetadata[K]> {
    const data = this.$data.get()

    if (!data) {
      return null
    }

    return (data[key] as TMetadata[K]) || null
  }

  /**
   * Reset the store.
   */
  reset(): this {
    this.closeDrawer()
    this.clearData()

    return this
  }

  /* Actions */

  /**
   * Clear data.
   */
  @action
  clearData(): this {
    this.$data.set(null)

    return this
  }

  /**
   * Set data.
   */
  @action
  setData(partialData: Maybe<Partial<TMetadata>>): this {
    this.$data.set({ ...this.$data.get(), ...partialData })

    return this
  }

  /**
   * Open the drawer.
   */
  @action
  openDrawer(): this {
    this.registerIntoKeyboardHandler()
    this.$drawerState |= DrawerState.isOpened

    return this
  }

  /**
   * Close the drawer and delete the data.
   */
  @action
  closeDrawer(): this {
    this.unregisterIntoKeyboardHandler()

    this.$drawerState &= ~DrawerState.isOpened
    this.$data.set(null)

    return this
  }

  /* Computed */

  /**
   * Return true if the drawer is opened.
   */
  @computed
  get isDrawerOpened(): boolean {
    return Boolean(this.$drawerState & DrawerState.isOpened)
  }

  /**
   * Retrieve all data.
   */
  @computed
  get data(): Maybe<Partial<TMetadata>> {
    return this.$data.get()
  }

  /* Implements IStoreEscapable */

  /**
   * Register the store instance into KeyboardBindingsManager when opening the drawer.
   */
  registerIntoKeyboardHandler(): this {
    const handler =
      this.storeRoot.keyboardBindingsManager.getHandler<EscapeHandler>(
        KeyboardKey.Escape
      )

    if (!handler) {
      return this
    }

    handler.addStore(this)

    return this
  }

  /**
   * Remove the store instance from KeyboardBindingsManager when closing the drawer.
   */
  unregisterIntoKeyboardHandler(): this {
    const handler =
      this.storeRoot.keyboardBindingsManager.getHandler<EscapeHandler>(
        KeyboardKey.Escape
      )

    if (!handler) {
      return this
    }

    handler.deleteStore(this)

    return this
  }

  /**
   * Return true if the drawer is opened.
   */
  get isEscapable(): boolean {
    return this.isDrawerOpened
  }

  /**
   * Close the drawer.
   */
  escape(): Promise<boolean> {
    this.closeDrawer()
    return Promise.resolve(true)
  }
}
