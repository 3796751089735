import type { IDataRowSyslog } from '@app/entities/EntitySyslog'
import type { StoreManagementSyslogs } from '@app/stores'

export const onSyslogDeleteIconClick =
  (storeManagementSyslogs: StoreManagementSyslogs) =>
  (syslogId: number) =>
  () => {
    const syslogEntity = storeManagementSyslogs.syslogs.get(syslogId)

    if (syslogEntity) {
      storeManagementSyslogs.storeDeleteDrawer
        .setData({ syslogDataRow: syslogEntity.asDataRow() })
        .openDrawer()
    }
  }

/**
 * Delete the syslog, close the drawer and reload syslogs.
 */
export const onSyslogDeleteSubmit =
  (storeManagementSyslogs: StoreManagementSyslogs) =>
  (dataRow: IDataRowSyslog) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    storeManagementSyslogs
      .deleteSyslog(dataRow.id)
      .then(() => {
        storeManagementSyslogs.storeDeleteDrawer.closeDrawer()
        return storeManagementSyslogs.fetchSyslogs()
      })
      .catch(() => {
        // already catched in the store
      })
  }
