import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  Drawer,
  DrawerContext,
  DrawerVerticalContainer
} from '@app/components-legacy/Drawer'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useStores } from '@app/hooks'
import { HistoryMenuKey } from '@app/stores/TrailFlow/StoreSearchHistory'
import { grant } from '@libs/rbac/permissions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerBookmarks from './DrawerBookmarks'
import DrawerHistory from './DrawerHistory'
import HistoryDrawerFooter from './HistoryDrawerFooter'
import HistoryDrawerMenu from './HistoryDrawerMenu'

interface ITrailFlowHistoryDrawerProps {}

const TrailFlowHistoryDrawer: React.FC<
  ITrailFlowHistoryDrawerProps
> = props => {
  const { storeTrailFlow } = useStores()

  const isHistorySelected =
    storeTrailFlow.storeMenuHistory.selectedMenuKey === HistoryMenuKey.History

  const HistoryDrawerContent = isHistorySelected
    ? DrawerHistory
    : DrawerBookmarks

  return (
    <Portal name={PlaceHolderName.verticalDrawer}>
      <DrawerContext.Provider
        value={{
          storeDrawer: storeTrailFlow.storeDrawerHistory
        }}
      >
        <Drawer
          placeHolderName={PlaceHolderName.verticalDrawer}
          overlayBackgroundColor="transparent"
          isDrawerVertical
        >
          <DrawerVerticalContainer variant="small">
            <BladeContentLayoutDefault
              layout={{
                name: 'default',
                menu: <HistoryDrawerMenu />,
                content: <HistoryDrawerContent />,
                footer: <HistoryDrawerFooter />
              }}
              rbacCapabilityCheck={grant()}
            />
          </DrawerVerticalContainer>
        </Drawer>
      </DrawerContext.Provider>
    </Portal>
  )
}

export default observer(TrailFlowHistoryDrawer)
