import { DashboardTemplate } from '@app/pages/Dashboard/GridPage/DrawerAddDashboard/types'
import {
  WidgetCommonFormFieldName,
  WidgetCommonFormUserStatus
} from '@app/pages/Dashboard/WidgetAddPage/types'
import type { StoreRoot } from '@app/stores'
import { StoreInfrastructures } from '@app/stores'
import { createWidgetKey } from '@libs/dashboards/keys'
import type { WidgetKey } from '@libs/dashboards/types'
import type { InstanceName } from '@libs/Environment/types'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type {
  MutationCreateDashboardWidget,
  MutationDeleteDashboardWidget,
  MutationEditDashboardWidget,
  MutationSetDashboardWidgetOptions
} from '@server/graphql/mutations/dashboard'
import {
  mutationCreateDashboardWidget,
  mutationDeleteDashboardWidget,
  mutationEditDashboardWidget,
  mutationSetDashboardWidgetOptions
} from '@server/graphql/mutations/dashboard'
import type { QueryRbacDashboardsWidgets } from '@server/graphql/queries/dashboard'
import { queryRbacDashboardsWidgets } from '@server/graphql/queries/dashboard'
import type {
  CreateDashboardWidgetMutationArgs,
  DashboardWidget,
  DashboardWidgetOptionsSerie,
  DashboardWidgetOptionsSerieDataOptionsUser,
  DeleteDashboardWidgetMutationArgs,
  EditDashboardWidgetMutationArgs,
  InputCreateDashboard,
  InputCreateDashboardWidget,
  InputDashboardWidgetSerieDataOptionsComplianceScore,
  InputDashboardWidgetSerieDataOptionsDeviance,
  InputDashboardWidgetSerieDataOptionsUser,
  InputDashboardWidgetSerieDisplayOptions,
  InputSetDashboardWidgetOptions,
  Maybe,
  RbacDashboardsQueryArgs,
  SetDashboardWidgetOptionsMutationArgs
} from '@server/graphql/typeDefs/types'
import {
  DashboardWidgetDataOptionType,
  DashboardWidgetType
} from '@server/graphql/typeDefs/types'
import { sortBy } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import type { Layout } from 'react-grid-layout'
import StoreDrawer from '../helpers/StoreDrawer'
import StoreFlags from '../helpers/StoreFlags'
import StoreForm from '../helpers/StoreForm'
import type { IFieldValue } from '../helpers/StoreForm/types'
import { mandatory, maxLength } from '../helpers/StoreForm/validators'
import StoreBase from '../StoreBase'
import type { IStoreMultiInstanceOptions } from '../types'
import {
  WIDGET_DEFAULT_DURATION,
  WIDGET_DEFAULT_INTERVAL,
  WIDGET_TITLE_MAX_LENGTH
} from './consts'
import {
  getComplianceBarChartWidget,
  getComplianceLineChartWidget,
  getComplianceTotalScoreNumberWidget,
  getDevianceNumberWidgetFromChecker,
  getDeviancesCounterPerCriticity,
  getDeviancesCounterPerRisk,
  getPasswordCounterWidgets,
  getPasswordDeviancesOnUserAccounts,
  getUserEvolutionLineChartWidget,
  getUsersScoreNumberWidget
} from './dashboardTemplates'
import { getFirstDashboardWidget } from './functions'
import type StoreDashboard from './StoreDashboard'
import StoreWidgetSerie from './StoreWidgetSerie'
import type { IWidgetCoordinates } from './types'

export interface IStoreWidgetsManagementOptions
  extends IStoreMultiInstanceOptions {
  storeDashboard: StoreDashboard
}

export default class StoreWidgetsManagement extends StoreBase<IStoreWidgetsManagementOptions> {
  public storeInfrastructures: StoreInfrastructures

  public storeFlags = new StoreFlags(this.storeRoot)
  public storeDrawer = new StoreDrawer<{ widgetKey: WidgetKey }>(this.storeRoot)

  public storeForm = new StoreForm<WidgetCommonFormFieldName>(this.storeRoot, {
    setup: {
      fields: {
        [WidgetCommonFormFieldName.instanceName]: {
          label: 'instanceName'
        },
        [WidgetCommonFormFieldName.dashboardId]: {
          label: 'dashboardId'
        },
        [WidgetCommonFormFieldName.dashboardWidgetId]: {
          label: 'dashboardWidgetId'
        },
        [WidgetCommonFormFieldName.type]: {
          label: 'type'
        },
        [WidgetCommonFormFieldName.title]: {
          label: 'Collector IP address or hostname',
          validators: [mandatory(), maxLength(WIDGET_TITLE_MAX_LENGTH)()]
        },
        [WidgetCommonFormFieldName.dataOptionsType]: {
          label: 'dataOptionsType'
        },
        [WidgetCommonFormFieldName.dataOptionsDirectoryIds]: {
          label: 'dataOptionsDirectoryIds'
        },
        [WidgetCommonFormFieldName.dataOptionsDuration]: {
          label: 'dataOptionsDuration'
        },
        [WidgetCommonFormFieldName.dataOptionsInterval]: {
          label: 'dataOptionsInterval'
        },
        [WidgetCommonFormFieldName.dataOptionsUserStatus]: {
          label: 'dataOptionsUserStatus'
        },
        [WidgetCommonFormFieldName.dataOptionsCheckers]: {
          label: 'dataOptionsCheckers'
        },
        [WidgetCommonFormFieldName.displayOptionsLabel]: {
          label: 'displayOptionsLabel'
        }
      },
      assertFieldExists: false
    }
  })

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: [
      'Dashboard.Common',
      'Dashboard.AddDashboardDrawer',
      'Components.LabelChecker'
    ]
  })

  // create one store by serie
  private $storesSeries: Map<number, StoreWidgetSerie> = new Map()

  /* Observables */

  private $nbSeries = observable.box<number>(1)

  constructor(storeRoot: StoreRoot, options: IStoreWidgetsManagementOptions) {
    super(storeRoot, options)

    this.storeInfrastructures = new StoreInfrastructures(
      storeRoot,
      this.options
    )

    makeObservable(this)
  }

  /**
   * Set the instanceName used for this store.
   */
  setInstanceName(instanceName: InstanceName): this {
    this.setOptions({
      instanceName
    })

    this.storeInfrastructures.setOptions({
      instanceName
    })

    return this
  }

  /**
   * Create a widget.
   *
   * First, create the widget,
   * Second, set the widget options.
   */
  createWidget(dashboardId: number): Promise<void> {
    this.storeFlags.loading()

    return (
      Promise.resolve()
        // create the widget
        .then(() => {
          const widgetCoordinates = this.computeAddedWidgetPosition(
            Array.from(this.options.storeDashboard.widgetLayouts.values())
          )

          const dashboardWidget =
            this.getDashboardWidgetVariablesForCreation(widgetCoordinates)

          if (!dashboardWidget) {
            throw new Error('Missing widget parameters')
          }

          const args: CreateDashboardWidgetMutationArgs = {
            dashboardWidget
          }

          return this.storeRoot
            .getGQLRequestor(this.options.instanceName)
            .query<MutationCreateDashboardWidget>(
              mutationCreateDashboardWidget,
              args
            )
        })
        // set options for the created widget
        .then(dataDashboardWidget => {
          const { id: dashboardWidgetId } =
            dataDashboardWidget.createDashboardWidget

          const dashboardWidgetOptions =
            this.getDashboardWidgetOptionsVariables(
              dashboardId,
              dashboardWidgetId
            )

          if (!dashboardWidgetOptions) {
            throw new Error('Missing widget options')
          }

          const args: SetDashboardWidgetOptionsMutationArgs = {
            dashboardWidgetOptions
          }

          return this.storeRoot
            .getGQLRequestor(this.options.instanceName)
            .query<MutationSetDashboardWidgetOptions>(
              mutationSetDashboardWidgetOptions,
              args
            )
        })
        .then(results => {
          if (!results.setDashboardWidgetOptions) {
            throw new Error('An error has occurred when creating the widget')
          }

          this.reset()

          this.storeRoot.stores.storeMessages.success(
            this.translate('Widget added'),
            {
              labelledBy: 'widgetAdded'
            }
          )

          this.storeFlags.success()
        })
        .catch(
          handleStoreError(this.storeRoot, this.storeFlags, {
            errorMessageTranslationFn: () => {
              return 'An error has occurred when creating the widget'
            }
          })
        )
    )
  }

  /**
   * Create predefined widgets.
   *
   * First, create the widget,
   * Second, set the widget options.
   */
  createPredefinedWidgets(
    dashboardId: number,
    template: DashboardTemplate,
    dashboard: InputCreateDashboard
  ): Promise<void | void[]> {
    this.storeFlags.loading()

    return (
      Promise.resolve()
        // create the widget
        .then(() => {
          const widgetCoordinates = this.computeAddedWidgetPosition(
            Array.from(this.options.storeDashboard.widgetLayouts.values())
          )

          switch (template) {
            case DashboardTemplate.passwordManagementRisk: {
              const argsComplianceLineChart: CreateDashboardWidgetMutationArgs =
                {
                  dashboardWidget:
                    this.getDashboardWidgetVariablesForPredefinedCreation(
                      {
                        posX: 0,
                        posY: 0
                      },
                      this.translate('Password Deviances on User accounts'),
                      dashboard.instanceName,
                      dashboardId,
                      8
                    )
                }

              const complianceLineChartWidget =
                getPasswordDeviancesOnUserAccounts(
                  this.storeRoot,
                  this,
                  dashboardId,
                  argsComplianceLineChart
                )

              const widgetRisks = getPasswordCounterWidgets(
                this.storeRoot,
                this,
                dashboardId,
                widgetCoordinates,
                dashboard
              )

              return Promise.all([complianceLineChartWidget, ...widgetRisks])
            }

            case DashboardTemplate.adRisk360:
            case DashboardTemplate.nativeAdminMonitoring:
            default: {
              const widgetsCounterRisks = getDeviancesCounterPerRisk(
                this.storeRoot,
                this,
                dashboardId,
                widgetCoordinates,
                dashboard,
                template
              )

              return Promise.all(widgetsCounterRisks)
            }

            case DashboardTemplate.adComplianceAndTopRisks: {
              const argsComplianceBarChart: CreateDashboardWidgetMutationArgs =
                {
                  dashboardWidget:
                    this.getDashboardWidgetVariablesForPredefinedCreation(
                      {
                        posX: 4,
                        posY: 0
                      },
                      this.translate('Global compliance score'),
                      dashboard.instanceName,
                      dashboardId,
                      4
                    )
                }

              const complianceBarChartWidget = getComplianceBarChartWidget(
                this.storeRoot,
                this,
                argsComplianceBarChart,
                dashboardId
              )

              const argsComplianceLineChart: CreateDashboardWidgetMutationArgs =
                {
                  dashboardWidget:
                    this.getDashboardWidgetVariablesForPredefinedCreation(
                      {
                        posX: 4,
                        posY: 2
                      },
                      this.translate('Compliance score evolution'),
                      dashboard.instanceName,
                      dashboardId,
                      4
                    )
                }

              const complianceLineChartWidget = getComplianceLineChartWidget(
                this.storeRoot,
                this,
                argsComplianceLineChart,
                dashboardId
              )

              const argsComplianceTotalScore: CreateDashboardWidgetMutationArgs =
                {
                  dashboardWidget:
                    this.getDashboardWidgetVariablesForPredefinedCreation(
                      {
                        posX: 0,
                        posY: 0
                      },
                      this.translate('Compliance score total'),
                      dashboard.instanceName,
                      dashboardId,
                      4,
                      4
                    )
                }

              const complianceTotalScoreNumberWidget =
                getComplianceTotalScoreNumberWidget(
                  this.storeRoot,
                  this,
                  argsComplianceTotalScore,
                  dashboardId
                )

              const widgetCounterCriticities = getDeviancesCounterPerCriticity(
                this.storeRoot,
                this,
                dashboardId,
                widgetCoordinates,
                dashboard
              )

              return Promise.all([
                complianceTotalScoreNumberWidget,
                complianceBarChartWidget,
                complianceLineChartWidget,
                ...widgetCounterCriticities
              ])
            }

            case DashboardTemplate.userMonitoring: {
              const argsTotalUsers: CreateDashboardWidgetMutationArgs = {
                dashboardWidget:
                  this.getDashboardWidgetVariablesForPredefinedCreation(
                    {
                      posX: 0,
                      posY: 0
                    },
                    this.translate('All users'),
                    dashboard.instanceName,
                    dashboardId
                  )
              }

              const totalUsersNumberWidget = getUsersScoreNumberWidget(
                this.storeRoot,
                this,
                argsTotalUsers,
                dashboardId,
                true
              )

              const argsActiveUsers: CreateDashboardWidgetMutationArgs = {
                dashboardWidget:
                  this.getDashboardWidgetVariablesForPredefinedCreation(
                    {
                      posX: 0,
                      posY: 2
                    },
                    this.translate('Active users'),
                    dashboard.instanceName,
                    dashboardId
                  )
              }

              const activeUsersNumberWidget = getUsersScoreNumberWidget(
                this.storeRoot,
                this,
                argsActiveUsers,
                dashboardId,
                false,
                true
              )

              const argsDisabledUsers: CreateDashboardWidgetMutationArgs = {
                dashboardWidget:
                  this.getDashboardWidgetVariablesForPredefinedCreation(
                    {
                      posX: 2,
                      posY: 2
                    },
                    this.translate('Disabled users'),
                    dashboard.instanceName,
                    dashboardId
                  )
              }

              const disabledUsersNumberWidget = getUsersScoreNumberWidget(
                this.storeRoot,
                this,
                argsDisabledUsers,
                dashboardId,
                false,
                false
              )

              const argsDisabledPrivilegedUsers: CreateDashboardWidgetMutationArgs =
                {
                  dashboardWidget:
                    this.getDashboardWidgetVariablesForPredefinedCreation(
                      {
                        posX: 4,
                        posY: 2
                      },
                      this.translate('Disabled accounts in privileged groups'),
                      dashboard.instanceName,
                      dashboardId
                    )
                }

              const disabledPrivilegedUsersNumberWidget =
                getDevianceNumberWidgetFromChecker(
                  this.storeRoot,
                  this,
                  argsDisabledPrivilegedUsers,
                  dashboardId,
                  'C-DISABLED-ACCOUNTS-PRIV-GROUPS'
                )

              const argsSleepingUsers: CreateDashboardWidgetMutationArgs = {
                dashboardWidget:
                  this.getDashboardWidgetVariablesForPredefinedCreation(
                    {
                      posX: 0,
                      posY: 4
                    },
                    this.translate('Dormant accounts'),
                    dashboard.instanceName,
                    dashboardId
                  )
              }

              const sleepingUsersNumberWidget =
                getDevianceNumberWidgetFromChecker(
                  this.storeRoot,
                  this,
                  argsSleepingUsers,
                  dashboardId,
                  'C-SLEEPING-ACCOUNTS'
                )

              const argsUserEvolutionLineChart: CreateDashboardWidgetMutationArgs =
                {
                  dashboardWidget:
                    this.getDashboardWidgetVariablesForPredefinedCreation(
                      {
                        posX: 2,
                        posY: 0
                      },
                      this.translate('AD User Evolution'),
                      dashboard.instanceName,
                      dashboardId,
                      4
                    )
                }

              const userEvolutionLineChartWidget =
                getUserEvolutionLineChartWidget(
                  this.storeRoot,
                  this,
                  argsUserEvolutionLineChart,
                  dashboardId
                )

              const argsSleepingAccountsEvolutionLineChart: CreateDashboardWidgetMutationArgs =
                {
                  dashboardWidget:
                    this.getDashboardWidgetVariablesForPredefinedCreation(
                      {
                        posX: 2,
                        posY: 4
                      },
                      this.translate('Dormant Account Evolution'),
                      dashboard.instanceName,
                      dashboardId,
                      4
                    )
                }

              const sleepingAccountsEvolutionLineChartWidget =
                getUserEvolutionLineChartWidget(
                  this.storeRoot,
                  this,
                  argsSleepingAccountsEvolutionLineChart,
                  dashboardId,
                  true
                )

              return Promise.all([
                userEvolutionLineChartWidget,
                sleepingAccountsEvolutionLineChartWidget,
                totalUsersNumberWidget,
                activeUsersNumberWidget,
                disabledUsersNumberWidget,
                disabledPrivilegedUsersNumberWidget,
                sleepingUsersNumberWidget
              ])
            }
          }
        })
        .catch(
          handleStoreError(this.storeRoot, this.storeFlags, {
            errorMessageTranslationFn: () => {
              return 'An error has occurred when creating the widget'
            }
          })
        )
    )
  }

  /**
   * Edit a widget.
   */
  editWidget(dashboardId: number, dashboardWidgetId: number): Promise<void> {
    this.storeFlags.loading()

    return (
      Promise.resolve()
        .then(() => {
          const title = this.storeForm.getFieldValueAsString(
            WidgetCommonFormFieldName.title
          )

          if (!title) {
            throw new Error('Missing widget parameters')
          }

          const args: EditDashboardWidgetMutationArgs = {
            dashboardWidget: {
              dashboardId,
              instanceName: this.instanceName,
              id: dashboardWidgetId,
              title
            }
          }

          return this.storeRoot
            .getGQLRequestor(this.options.instanceName)
            .query<MutationEditDashboardWidget>(
              mutationEditDashboardWidget,
              args
            )
        })
        // set options
        .then(() => {
          const dashboardWidgetOptions =
            this.getDashboardWidgetOptionsVariables(
              dashboardId,
              dashboardWidgetId
            )

          if (!dashboardWidgetOptions) {
            throw new Error('Missing widget options')
          }

          const args: SetDashboardWidgetOptionsMutationArgs = {
            dashboardWidgetOptions
          }

          return this.storeRoot
            .getGQLRequestor(this.options.instanceName)
            .query<MutationSetDashboardWidgetOptions>(
              mutationSetDashboardWidgetOptions,
              args
            )
        })
        .then(results => {
          if (!results.setDashboardWidgetOptions) {
            throw new Error('An error has occurred when updating the widget')
          }

          this.reset()

          this.storeRoot.stores.storeMessages.success(
            this.translate('Widget updated'),
            {
              labelledBy: 'widgetUpdated'
            }
          )

          this.storeFlags.success()
        })
        .catch(
          handleStoreError(this.storeRoot, this.storeFlags, {
            errorMessageTranslationFn: () => {
              return 'An error has occurred when updating the widget'
            }
          })
        )
    )
  }

  /**
   * Delete a widget.
   */
  deleteDashboardWidget(dashboardId: number, dashboardWidgetId: number) {
    this.storeFlags.loading()

    const args: DeleteDashboardWidgetMutationArgs = {
      dashboardWidget: {
        instanceName: this.instanceName,
        dashboardId,
        dashboardWidgetId
      }
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor(this.options.instanceName)
          .query<MutationDeleteDashboardWidget>(
            mutationDeleteDashboardWidget,
            args
          )
      })
      .then(() => {
        const widgetKey = createWidgetKey(
          String(dashboardId),
          dashboardWidgetId
        )

        // remove the widget from the store
        if (widgetKey) {
          this.removeWidget(widgetKey)
        }

        this.storeRoot.stores.storeMessages.success(
          this.translate('Widget deleted'),
          {
            labelledBy: 'widgetDeleted'
          }
        )

        this.storeFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlags, {
          errorMessageTranslationFn: () => {
            return 'An error has occurred when deleting the widget'
          }
        })
      )
  }

  get isWidgetTypeSelected(): boolean {
    return !!this.storeForm.getFieldValueAsString(
      WidgetCommonFormFieldName.type
    )
  }

  get isWidgetDataOptionsTypeSelected(): boolean {
    return !!this.storeForm.getFieldValueAsString(
      WidgetCommonFormFieldName.dataOptionsType
    )
  }

  /**
   * Return the store of the serie of create one on the fly
   * if none are found.
   */
  getStoreSerie(serieIndex: number): Maybe<StoreWidgetSerie> {
    return this.$storesSeries.get(serieIndex) || null
  }

  /**
   * Return the variables for the widget creation.
   */
  getDashboardWidgetVariablesForCreation(
    widgetCoordinates: IWidgetCoordinates
  ): InputCreateDashboardWidget {
    return {
      posX: widgetCoordinates.posX,
      posY: widgetCoordinates.posY,
      width: 2,
      height: 2,
      title: this.storeForm.getFieldValueAsString(
        WidgetCommonFormFieldName.title
      ),
      instanceName: this.storeForm.getFieldValueAsString(
        WidgetCommonFormFieldName.instanceName
      ),
      dashboardId: this.storeForm.getFieldValueAsNumber(
        WidgetCommonFormFieldName.dashboardId
      )
    }
  }

  /**
   * Return the variables for the widget creation.
   */
  getDashboardWidgetVariablesForPredefinedCreation(
    widgetCoordinates: IWidgetCoordinates,
    title: string,
    instanceName: string,
    dashboardId: number,
    width?: number,
    height?: number
  ): InputCreateDashboardWidget {
    return {
      posX: widgetCoordinates.posX,
      posY: widgetCoordinates.posY,
      width: width || 2,
      height: height || 2,
      title: title,
      instanceName: instanceName,
      dashboardId: dashboardId
    }
  }

  /**
   * Return the options for the widget creation, according to the configuration
   * of the widget on the UI.
   */
  getDashboardWidgetOptionsVariables(
    dashboardId: number,
    dashboardWidgetId: number
  ): Maybe<InputSetDashboardWidgetOptions> {
    const dashboardWidgetType = this.storeForm.getFieldValueAsString(
      WidgetCommonFormFieldName.type
    ) as DashboardWidgetType

    // protect again JSON.parse
    try {
      const dashboardWidgetOptions: InputSetDashboardWidgetOptions = {
        instanceName: this.instanceName,
        dashboardId,
        dashboardWidgetId,
        type: dashboardWidgetType,
        series: this.series.map((storeSerie, i) => {
          const {
            storeForm: serieStoreForm,
            storeInfrastructures: serieStoreInfrastructures,
            storeInputCheckersExposure: serieStoreLabelInputCheckers
          } = storeSerie

          const dataOptionsType = this.storeForm.getFieldValueAsString(
            WidgetCommonFormFieldName.dataOptionsType
          )

          // same displayOptions for all dataOptionsType
          const defaultSerieLabel = `Serie-${i + 1}`

          const displayOptions: InputDashboardWidgetSerieDisplayOptions = {
            type: dashboardWidgetType,
            label:
              serieStoreForm.getFieldValueAsString(
                WidgetCommonFormFieldName.displayOptionsLabel
              ) || defaultSerieLabel
          }

          switch (dataOptionsType) {
            // Compile dataOptions for user
            case DashboardWidgetDataOptionType.User: {
              const dataOptionsUser: InputDashboardWidgetSerieDataOptionsUser =
                {
                  type: dataOptionsType,
                  directoryIds:
                    serieStoreInfrastructures.getSelectedDirectoryIds(),
                  // same duration for all series
                  duration: this.storeForm.getFieldValueAsNumber(
                    WidgetCommonFormFieldName.dataOptionsDuration
                  )
                  // don't set interval, let's the API decides the most relevant one
                }

              // convert the active field that saves the field value as a WidgetCommonFormUserStatus
              // to a boolean (null if WidgetCommonFormUserStatus.all)
              const activeFieldValue = serieStoreForm.getFieldValueAsString(
                WidgetCommonFormFieldName.dataOptionsUserStatus
              )
              if (activeFieldValue !== WidgetCommonFormUserStatus.all) {
                dataOptionsUser.active =
                  activeFieldValue === WidgetCommonFormUserStatus.active
              }

              return {
                dataOptionsUser,
                displayOptions
              }
            }
            // Compile dataOptions for deviance
            case DashboardWidgetDataOptionType.Deviance: {
              const dataOptionsDeviance: InputDashboardWidgetSerieDataOptionsDeviance =
                {
                  type: dataOptionsType,
                  checkerIds: serieStoreLabelInputCheckers.selectedCheckerIds,
                  directoryIds:
                    serieStoreInfrastructures.getSelectedDirectoryIds(),
                  // not yet implemented in API
                  reasonIds: [],
                  // FIXME Use real profileId when supported in API
                  // https://github.com/AlsidOfficial/DSC-Cassiopeia/issues/2337
                  profileId: 1,
                  // same duration for all series
                  duration: this.storeForm.getFieldValueAsNumber(
                    WidgetCommonFormFieldName.dataOptionsDuration
                  )
                  // don't set interval, let's the API decides the most relevant one
                }

              return {
                dataOptionsDeviance,
                displayOptions
              }
            }
            // Compile dataOptions for compliance score
            case DashboardWidgetDataOptionType.ComplianceScore: {
              const dataOptionsComplianceScore: InputDashboardWidgetSerieDataOptionsComplianceScore =
                {
                  type: dataOptionsType,
                  checkerIds: serieStoreLabelInputCheckers.selectedCheckerIds,
                  directoryIds:
                    serieStoreInfrastructures.getSelectedDirectoryIds(),
                  // not yet implemented in API
                  reasonIds: [],
                  // FIXME Use real profileId when supported in API
                  // https://github.com/AlsidOfficial/DSC-Cassiopeia/issues/2337
                  profileId: 1,
                  // same duration for all series
                  duration: this.storeForm.getFieldValueAsNumber(
                    WidgetCommonFormFieldName.dataOptionsDuration
                  )
                  // don't set interval, let's the API decides the most relevant one
                }

              return {
                dataOptionsComplianceScore,
                displayOptions
              }
            }
            default:
              this.storeRoot.stores.storeMessages.error('Not yet available.', {
                labelledBy: 'notYetAvailable'
              })
              throw new Error('Not yet implemented')
          }
        })
      }
      return dashboardWidgetOptions
    } catch (err) {
      this.storeRoot.logException(err)
      this.storeRoot.stores.storeMessages.error(
        this.translate('An error has occurred when creating the widget'),
        {
          labelledBy: 'createWidgetError'
        }
      )

      return null
    }
  }

  /**
   * Compute the x and y coordinates in order to place a new widget
   * at the bottom, on the right of existing widgets.
   * Layouts are the list of layouts of current widgets of the dashboard.
   */
  computeAddedWidgetPosition(layouts: Layout[]): IWidgetCoordinates {
    // retrieve the lowest layout (bottom)
    const lowestLayout = sortBy(layouts, 'y').pop()

    if (!lowestLayout) {
      return { posX: 0, posY: 0 }
    }

    // retrieve the rightmost layout
    const allLowestLayouts = layouts.filter(l => l.y === lowestLayout.y)
    const rightmostBottomLayout = sortBy(allLowestLayouts, 'x').pop()

    if (!rightmostBottomLayout) {
      return { posX: 0, posY: 0 }
    }

    // place at the right from the lowest row
    return {
      posX: rightmostBottomLayout.x + rightmostBottomLayout.w,
      posY: rightmostBottomLayout.y
    }
  }

  /**
   * Fetch widget options and return the widget data.
   *
   * Note that unlike other fetch* methods of other stores, no observable will
   * be populated in this function, the data is directly returned and used
   * to populate stores of the edition interface.
   */
  private _fetchDashboardWidget(
    args: RbacDashboardsQueryArgs
  ): Promise<DashboardWidget | void> {
    const { dashboardId, dashboardWidgetId } = args

    if (!dashboardId || !dashboardWidgetId) {
      throw new Error('Missing widget ids')
    }

    this.storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor(this.options.instanceName)
          .query<QueryRbacDashboardsWidgets>(queryRbacDashboardsWidgets, args)
      })
      .then(data => data.rbacDashboards)
      .then(getFirstDashboardWidget(this.storeRoot, this.storeFlags))
      .catch(handleStoreError(this.storeRoot, this.storeFlags))
  }

  /**
   * Return the default fields for a serie.
   */
  private _getDefaultSerieFieldsValues(
    serie: DashboardWidgetOptionsSerie
  ): IFieldValue[] {
    const defaultSerieFieldsValues: IFieldValue[] = []

    // displayOptions
    if (serie.displayOptions.label) {
      defaultSerieFieldsValues.push({
        key: WidgetCommonFormFieldName.displayOptionsLabel,
        value: serie.displayOptions.label
      })
    }

    // dataOptions
    switch (serie.dataOptions.type) {
      case DashboardWidgetDataOptionType.User: {
        const dataOptionsUser =
          serie.dataOptions as DashboardWidgetOptionsSerieDataOptionsUser

        return defaultSerieFieldsValues.concat({
          key: WidgetCommonFormFieldName.dataOptionsUserStatus,
          // if dataOptionsUser.active undef => all, if true => active, if false => inactive
          value: !isDefined(dataOptionsUser.active)
            ? WidgetCommonFormUserStatus.all
            : dataOptionsUser.active
              ? WidgetCommonFormUserStatus.active
              : WidgetCommonFormUserStatus.inactive
        })
      }
      case DashboardWidgetDataOptionType.ComplianceScore:
      case DashboardWidgetDataOptionType.Deviance:
        return defaultSerieFieldsValues

      case DashboardWidgetDataOptionType.Resolution:
        // TODO
        return defaultSerieFieldsValues

      case DashboardWidgetDataOptionType.Event:
        // TODO
        return defaultSerieFieldsValues

      case DashboardWidgetDataOptionType.Attack:
        // TODO
        return defaultSerieFieldsValues

      default:
        assertUnreachableCase(serie.dataOptions.type)
    }
  }

  get instanceName(): InstanceName {
    return (
      this.options.instanceName ??
      this.storeRoot.environment.getFirstInstanceName()
    )
  }

  /* Actions */

  /**
   * Reset forms and widget stores.
   */
  @action
  reset(): void {
    this.$nbSeries.set(0)

    this.storeForm.hardReset()
    this.storeFlags.reset()
    this.$storesSeries.clear()
  }

  /**
   * Add a serie by creating a new dedicated store.
   */
  @action
  addSerie(): StoreWidgetSerie {
    const index = this.$nbSeries.get() + 1

    this.$nbSeries.set(index)

    const storeSerie = new StoreWidgetSerie(this.storeRoot, {
      instanceName: this.instanceName
    })

    storeSerie.setInstanceName(this.instanceName)

    this.$storesSeries.set(index, storeSerie)

    return storeSerie
  }

  /**
   * Add a new serie with fields initialization.
   */
  @action
  addNewSerie(): Maybe<StoreWidgetSerie> {
    // only add one serie for BigNumber
    if (this.isBigNumber && this.nbSeries > 0) {
      return null
    }

    const storeSerie = this.addSerie()

    storeSerie.storeForm
      .setDefaultFieldsValues([
        // User dataOption
        {
          key: WidgetCommonFormFieldName.dataOptionsUserStatus,
          value: WidgetCommonFormUserStatus.active
        }
      ])
      .reset()

    return storeSerie
  }

  /**
   * Add a new serie only if there is no added yet.
   */
  @action
  addFirstSerie(): Maybe<StoreWidgetSerie> {
    if (this.$nbSeries.get() > 0) {
      return null
    }

    return this.addNewSerie()
  }

  /**
   * Remove the serie number `serieNumber`.
   */
  @action
  removeSerie(serieNumber: number): void {
    const newMap: Map<number, StoreWidgetSerie> = new Map()

    // recreate a new map
    let i = 0
    this.$storesSeries.forEach((storeSerie, serieNumberLoop) => {
      if (serieNumberLoop !== serieNumber) {
        newMap.set(++i, storeSerie)
      }
    })

    // override the existing map
    this.$storesSeries = newMap

    // set the number of series
    this.$nbSeries.set(this.$storesSeries.size)
  }

  @action
  removeWidget(widgetKey: WidgetKey): void {
    this.options.storeDashboard.storeWidgets.delete(widgetKey)
  }

  /**
   * Initialize different stores for the widget creation.
   */
  @action
  initializeStoresForWidgetCreation(dashboardId: number): void {
    this.reset()

    this.storeForm
      .setDefaultFieldsValues([
        {
          key: WidgetCommonFormFieldName.instanceName,
          value: this.instanceName
        },
        {
          key: WidgetCommonFormFieldName.dashboardId,
          value: dashboardId
        },
        {
          key: WidgetCommonFormFieldName.dataOptionsDuration,
          value: WIDGET_DEFAULT_DURATION
        },
        {
          key: WidgetCommonFormFieldName.dataOptionsInterval,
          value: WIDGET_DEFAULT_INTERVAL
        }
      ])
      .reset()
  }

  /**
   * Initialize different stores for the widget edition after fetching
   * the widget options.
   */
  @action
  initializeStoresForWidgetEdition(
    args: RbacDashboardsQueryArgs
  ): Promise<void> {
    const { dashboardId, dashboardWidgetId } = args

    if (!dashboardId || !dashboardWidgetId) {
      throw new Error('Missing widget ids')
    }

    this.storeFlags.loading()

    // init general fields
    const defaultFieldValues: IFieldValue[] = [
      {
        key: WidgetCommonFormFieldName.instanceName,
        value: this.instanceName
      }
    ]

    return this._fetchDashboardWidget(args)
      .then(widget => {
        if (!widget) {
          return
        }

        this.reset()

        // init general fields
        defaultFieldValues.push(
          ...[
            {
              key: WidgetCommonFormFieldName.dashboardId,
              value: Number(args.dashboardId)
            },
            {
              key: WidgetCommonFormFieldName.dashboardWidgetId,
              value: Number(args.dashboardWidgetId)
            },
            {
              key: WidgetCommonFormFieldName.type,
              value: widget.options.type
            },
            {
              key: WidgetCommonFormFieldName.title,
              value: widget.title
            }
          ]
        )

        // init common fields for series
        if (widget.options.series.length) {
          defaultFieldValues.push(
            ...[
              {
                key: WidgetCommonFormFieldName.dataOptionsDuration,
                value: widget.options.series[0].dataOptions.duration
              },
              {
                key: WidgetCommonFormFieldName.dataOptionsType,
                value: widget.options.series[0].dataOptions.type
              }
            ]
          )
        }

        this.storeForm.setDefaultFieldsValues(defaultFieldValues).reset()

        // init each series
        widget.options.series.forEach(serie => {
          const storeSerie = this.addSerie()

          // init directories selection (common for all dataOptions)
          if ('directoryIds' in serie.dataOptions) {
            storeSerie.storeInfrastructures.fetchInfrastructures().then(() => {
              // if this serie has no directory and to avoid to have an error message
              // saying that at least one directory has to be checked,
              // check all directories (from the global directories list)
              if (!serie.dataOptions.directoryIds.length) {
                serie.dataOptions.directoryIds =
                  this.storeRoot.stores.storeInfrastructures.directoryIds
              }

              storeSerie.storeInfrastructures.selectDirectories(
                serie.dataOptions.directoryIds
              )
            })
          }

          // init checkers selection
          if ('checkerIds' in serie.dataOptions) {
            storeSerie.storeInputCheckersExposure.setDefaultSelectedCheckerIds(
              serie.dataOptions.checkerIds
            )
          }

          const defaultSerieFieldsValues =
            this._getDefaultSerieFieldsValues(serie)

          storeSerie.storeForm
            .setDefaultFieldsValues(defaultSerieFieldsValues)
            .reset()
        })

        this.storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlags))
  }

  /* Computed */

  @computed
  get nbSeries(): number {
    return this.$nbSeries.get()
  }

  @computed
  get series(): StoreWidgetSerie[] {
    const allStores = Array.from(this.$storesSeries.values())

    // return only the first serie for BigNumber (that can't have more than 1)
    if (this.isBigNumber) {
      return allStores.slice(0, 1)
    }

    return allStores
  }

  /**
   * Return true if all conditions are required.
   */
  @computed
  get isAdditionOrEditionFormSubmittable(): boolean {
    const s = this.storeForm

    const mustSelectCheckers =
      this.widgetType === DashboardWidgetDataOptionType.Deviance ||
      this.widgetType === DashboardWidgetDataOptionType.ComplianceScore

    if (mustSelectCheckers) {
      const checkerSelectedInAllDataset = Array.from(
        this.$storesSeries.values()
      )
        .map(serie => serie.storeInputCheckersExposure.hasSelectedCheckers)
        .every(t => t === true)

      if (!checkerSelectedInAllDataset) {
        return false
      }
    }

    return [
      s.getFieldValueAsString(WidgetCommonFormFieldName.type) !== '',
      s.getFieldValueAsString(WidgetCommonFormFieldName.dataOptionsType) !== '',
      this.$nbSeries.get() >= 1
    ].every(t => t === true)
  }

  /**
   * Return true if the widget is a BigNumber type.
   */
  @computed
  get isBigNumber(): boolean {
    const dashboardType = this.storeForm.getFieldValueAsString(
      WidgetCommonFormFieldName.type
    )

    return dashboardType === DashboardWidgetType.BigNumber
  }

  @computed
  get widgetType(): DashboardWidgetDataOptionType {
    return this.storeForm.getFieldValueAsString(
      WidgetCommonFormFieldName.dataOptionsType
    )
  }
}
