import { Features } from '@alsid/common'
import type { IIconProps } from '@app/components/Icon/types'
import { useStores } from '@app/hooks'
import { usePendoCustomStyles } from '@app/hooks/usePendoCustomStyles'
import { consts } from '@app/styles'
import * as React from 'react'

interface IIconInfoTulProps extends IIconProps {}

const IconInfoTul: React.FC<IIconInfoTulProps> = props => {
  const { storeRoot } = useStores()

  const fill = props.color ?? consts.colorBlue015
  const size = props.size ?? 24

  const isTulV6Enabled = storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
    Features.KAPTEYN_TUL_V6
  )

  usePendoCustomStyles()

  if (isTulV6Enabled) {
    return (
      <tenable-floating-button
        id="help-menu-button"
        aria-haspopup="true"
        aria-label="Resource Center"
      >
        <tenable-help-icon color="brand" size="medium" />
      </tenable-floating-button>
    )
  }

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10"></circle>
      <line x1="12" y1="16" x2="12" y2="12"></line>
      <line x1="12" y1="8" x2="12.01" y2="8"></line>
    </svg>
  )
}

export default IconInfoTul
