import { createEntities, EntityPagination } from '@app/entities'
import EntityIdentity from '@app/entities/EntityIdentity'
import StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import type { IDataRowGeneric } from '@app/stores/helpers/StoreWidgetList/types'
import { handleStoreError } from '@libs/errors/handleStoreError'
import type {
  GetIdentitiesSortByEnum,
  Identity
} from '@libs/openapi/service-identity-core'
import type { GetIdentitiesRequestExtended } from '@libs/openapi-clients/extendedClients/IdentitiesApiExtended'
import type { Maybe } from '@productive-codebases/toolbox'
import { action, makeObservable } from 'mobx'
import type { StoreRoot } from '..'
import { StoreInfrastructures, StoreInputTenants } from '..'
import StoreDrawer from '../helpers/StoreDrawer'
import StoreFlags from '../helpers/StoreFlags'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'
import { StoreInputSearch } from './../helpers/StoreInputSearch/index'
import type { IdentityColumnSorterState, IPartialPagination } from './types'

export default class StoreIdentityExplorer extends StoreBase {
  /* Flags */

  public storeFlagsFetchIdentities = new StoreFlags(this.storeRoot)
  public storeFlagsReloadIdentities = new StoreFlags(this.storeRoot)

  /* Input */

  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  /* Lists */

  public storeWidgetListIdentities = new StoreWidgetList<
    EntityIdentity,
    IDataRowGeneric
  >(this.storeRoot)

  /* Drawers */

  public storeDrawerExportIdentities = new StoreDrawer(this.storeRoot)

  /* Filters */
  public storeInputTenants = new StoreInputTenants(this.storeRoot, {
    emptySelectionAllowed: true
  })
  public storeInfrastructures = new StoreInfrastructures(this.storeRoot, {
    emptySelectionAllowed: true
  })

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch identities.
   */

  fetchIdentities(
    pagination: IPartialPagination,
    storeFlags = this.storeFlagsFetchIdentities,
    sortColumnId?: GetIdentitiesSortByEnum
  ) {
    if (
      !this.storeRoot.stores.storeManagementAzureAD
        .isTenableCloudApiTokensWorking
    ) {
      return
    }

    storeFlags.loading()

    const columnSorter =
      sortColumnId !== undefined
        ? this.storeWidgetListIdentities.nextColumnSorterState(sortColumnId)
        : this.storeWidgetListIdentities.columnSorterState

    this.storeRoot.logger.debug(
      `Next sorter is ${JSON.stringify(columnSorter)}`
    )

    return this.storeRoot.environment.openApiClients.serviceIdentityCore.identities
      .getIdentities(
        this.identitiesParametersFromFilters(
          columnSorter,
          EntityPagination.fromDefaultAttributes(pagination)
        )
      )
      .then(identities => {
        if (!identities) {
          this.storeRoot.logger.error('Unable to retrieve identities')
        }

        const { items, pagination } = identities

        const identitiesEntities = createEntities<Identity, EntityIdentity>(
          EntityIdentity,
          items
        )

        const entityPagination =
          EntityPagination.fromPaginationOffset(pagination)

        this.storeWidgetListIdentities.setPagination(entityPagination)

        this.storeWidgetListIdentities.setEntities(identitiesEntities)

        this.storeWidgetListIdentities.setColumnSorterState(columnSorter)

        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Reload filtered identities
   */

  reloadIdentities(
    pagination: IPartialPagination,
    sortColumnId?: GetIdentitiesSortByEnum
  ) {
    return this.fetchIdentities(
      pagination,
      this.storeFlagsReloadIdentities,
      sortColumnId
    )
  }

  /**
   * Return request parameters for filters / pagination.
   */
  identitiesParametersFromFilters(
    columnSorter: Maybe<IdentityColumnSorterState>,
    pagination?: EntityPagination
  ): GetIdentitiesRequestExtended {
    return {
      textQuery: this.storeInputSearch.hasSearchValue
        ? this.storeInputSearch.searchValue
        : undefined,
      ...pagination?.computePaginationOffset(),
      tenantIds: this.storeInputTenants.selectedTenantIds,
      directoryUuids:
        this.storeRoot.stores.storeInfrastructures.selectedDirectoryUuids,
      sortBy: columnSorter?.columnId,
      order: columnSorter?.sortingOrder
    }
  }

  /* Actions */

  @action
  reset(): this {
    this.storeFlagsFetchIdentities.reset()
    this.storeFlagsReloadIdentities.reset()

    this.storeInputSearch.reset()
    this.storeWidgetListIdentities.reset()

    this.storeInputTenants.reset()
    this.storeInfrastructures.reset()

    return this
  }
}
