import { ContainerSimple } from '@app/components/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { IconDownOutlined } from '@app/components-legacy/Icon/IconAntd'
import { useAppTranslator } from '@app/hooks'
import { Dropdown, Menu } from 'antd'
import * as React from 'react'
import { onAlertMenuClick } from './handlers'
import type { ILabelAlertProps } from './types'

interface IAlertActionsButtonProps {
  actions: ILabelAlertProps['actions']
  archived: ILabelAlertProps['archived']
  onAction: ILabelAlertProps['onAction']
}

const AlertActionsButton: React.FC<IAlertActionsButtonProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Drawers.Alerts'] })

  if (!props.actions || props.archived === true) {
    return null
  }

  const menu = (
    <Menu
      onClick={onAlertMenuClick(props.onAction)}
      items={props.actions.map(action => ({
        key: action.key,
        label: (
          <ContainerSimple inline labelledBy={action.key}>
            {action.label}
          </ContainerSimple>
        )
      }))}
    />
  )

  return (
    <Dropdown overlay={menu}>
      <div>
        <FormWrapperButton
          labelledBy="submit"
          buttonProps={{
            type: 'primary',
            size: 'small'
          }}
        >
          {translate('Actions')} <IconDownOutlined />
        </FormWrapperButton>
      </div>
    </Dropdown>
  )
}

export default AlertActionsButton
