import type { Maybe } from '@@types/helpers'
import type { ARIARole } from 'aria-query'

export type TContainerFlexDirection =
  | 'row'
  | 'row-reverse'
  | 'column'
  | 'column-reverse'

export type TContainerFlexJustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'

export type TContainerFlexAlignItems =
  | 'normal'
  | 'baseline'
  | 'flex-start'
  | 'flex-end'
  | 'end'
  | 'center'
  | 'stretch'

export type TContainerFlexSpaceWidth =
  | 'none'
  | 'veryVerySmall'
  | 'verySmall'
  | 'small'
  | 'smaller'
  | 'default'
  | 'medium'
  | 'large'

export type TContainerFlexSpaced = boolean | 'allChildren'

export type TContainerFlexOverflow =
  | 'visible'
  | 'hidden'
  | 'scroll'
  | 'inherit'
  | 'initial'
  | 'auto'

export type TFlexGrow = 'initial' | 'revert' | 'unset' | '1'

export type TFlexWrap = 'wrap' | 'nowrap'

export enum TFlexValuePreset {
  'initial' = 'initial',
  'twoColumns' = '0 50%',
  'mainColumns' = '0 75%',
  'summaryColumns' = '0 25%'
}

export interface IContainerFlexProps {
  // data-name for easier readability when looking at DOM
  name: string
  // data-test attributes
  labelledBy?: string
  ariaRoles?: ARIARole | ARIARole[]
  // className needed for styled-component
  className?: string
  // custom styles (needed for a ContainerFlex parent that will set flex-grow in styles)
  style?: React.CSSProperties
  // if true, add the default margin between items
  spaced?: TContainerFlexSpaced
  // width of the spacer
  spaceWidth?: TContainerFlexSpaceWidth
  // if set, specify the padding between the content and of the bottom border
  borderPadding?: TContainerFlexSpaceWidth
  // flex-direction
  direction?: TContainerFlexDirection
  // alignment to set on the flex container
  justifyContent?: TContainerFlexJustifyContent
  // alignment of items
  alignItems?: TContainerFlexAlignItems
  // allow to grow
  flexGrow?: TFlexGrow
  // allow to wrap items
  flexWrap?: TFlexWrap
  // https://css-tricks.com/almanac/properties/f/flex/
  flexValuePreset?: TFlexValuePreset
  // inline-flex
  inline?: boolean
  // flex items (children)
  items: React.ReactNode | React.ReactNodeArray
  // set flex-grow on items
  itemsFlexGrow?: number[]
  // set flex-shrink on items
  itemsFlexShrink?: number[]
  // wrap items to be able to apply a margin or a border on them.
  wrapItems?: boolean
  // optional left/right padding
  paddingH?: Maybe<
    'none' | 'verySmall' | 'small' | 'default' | 'medium' | 'large' | number
  >
  // optional top/boom padding
  paddingV?: Maybe<
    'none' | 'verySmall' | 'small' | 'default' | 'medium' | 'large' | number
  >
  // if true, set height: 100%
  fullHeight?: boolean
  // if true, set min-width: 100%
  fullWidth?: boolean
  // optional onClick handler
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  // optional onScroll handler
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void
}
