import type { Maybe } from '@@types/helpers'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import type { IWidgetListDataSet } from '@app/stores/helpers/StoreWidgetList/types'
import { getEmptyDataSet } from '@app/stores/helpers/StoreWidgetList/utils'

/**
 * Return entities as a dataSet.
 */
export function getDataSet<D>(
  storeWidgetList: Maybe<StoreWidgetList<any, D>>
): IWidgetListDataSet<D> {
  if (storeWidgetList) {
    return storeWidgetList.entitiesAsDataSet
  }

  return getEmptyDataSet<D>()
}
