import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

export interface ICellStatusCodeProps {
  className?: string
  children: number
}

const CellStatusCode: React.FC<ICellStatusCodeProps> = props => {
  return <div className={props.className}>{props.children}</div>
}

function getColor(statusCode: number): string {
  switch (statusCode) {
    case 400:
    case 404:
    case 500:
      return consts.colorRed001

    default:
      return consts.colorGreen005
  }
}

export default styled(CellStatusCode)`
  width: 150px !important;
  font-weight: ${consts.fontWeightBold};
  color: ${props => getColor(props.children)};
`
