import { ContainerFooter } from '@app/components-legacy/Container'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onTrailFlowFetchOlderEventsClick } from './handlers'
import TableControl from './TableControl'

export interface ITableFooterProps {
  className?: string
}

const TableFooter: React.FC<ITableFooterProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const translate = useAppTranslator({ namespaces: ['TrailFlow.Table'] })

  const { storeTrailFlow } = useStores()

  const isLoading =
    storeTrailFlow.storeEvents.storeFlagsFetchOlderEvents.isLoading

  const content = (
    <TableControl
      labelledBy="load-previous-events"
      direction="down"
      label={translate('Load previous events')}
      isLoading={isLoading}
      onClick={onTrailFlowFetchOlderEventsClick(storeTrailFlow)}
    />
  )

  if (!themeIsLegacy) {
    return content
  }

  return (
    <ContainerFooter justify="center" withBoxShadow={false}>
      {content}
    </ContainerFooter>
  )
}

export default observer(TableFooter)
