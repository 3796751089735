import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppTranslator, useStores } from '@app/hooks'
import { grant } from '@libs/rbac/permissions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import EditBookmarkForm from './EditBookmarkForm'
import { handleEditBookmarkUnload } from './handlers'

export interface IEditBookmarkProps {
  className?: string
}

const EditBookmark: React.FC<IEditBookmarkProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['TrailFlow.SearchBookmarks']
  })

  const { storeTrailFlow } = useStores()

  return (
    <DrawerHorizontalContainer
      variant="large"
      onUnload={handleEditBookmarkUnload(storeTrailFlow.storeSearchBookmarks)}
    >
      <BladeContentLayoutDefault
        layout={{
          name: 'default',
          title: <DrawerTitle>{translate('Edit bookmark')}</DrawerTitle>,
          content: <EditBookmarkForm />,
          footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
        }}
        rbacCapabilityCheck={grant()}
      />
    </DrawerHorizontalContainer>
  )
}

export default observer(EditBookmark)
