import type { EntityDashboardWidgetOptionsSerieStatDataPointAttackCount } from '@app/entities'
import type { ITimelineAttackPoint } from '@app/stores/IoA/StoreBoard/StoreTimeline/types'
import { roundNumber } from '@libs/numbers/helpers'
import type { ITimelinePillDetail } from './types'

/**
 * Return attack and pill max height (in pixel).
 */
export function getTimelinePillDetails(
  attacks: ITimelineAttackPoint[],
  maxHeight: number,
  biggestAttackCountSum: number
): ITimelinePillDetail[] {
  // Sum of attack.count
  const countSum = attacks.reduce((acc, curr) => acc + curr.count, 0)

  if (countSum === 0) {
    return []
  }

  return attacks.map(attack => {
    // Rate of current attack.count depending on count sum
    const countRate = (100 * attack.count) / countSum
    // Height (in pixel) depending on pill max height
    const currentHeight = (maxHeight * countRate) / 100

    // Rate of current count sum depending of the biggest attack count sum
    const countSumRate = (100 * countSum) / biggestAttackCountSum
    // Height (in pixel) depending on current pill height
    const finalHeight = (currentHeight * countSumRate) / 100

    return { attack, height: roundNumber(finalHeight) }
  })
}

/**
 * Compute attackCounts into top 3 of sorted statsCounts by higher count.
 */
export function getTimelinePillSortedStatsCounts(
  dataEntities: EntityDashboardWidgetOptionsSerieStatDataPointAttackCount[]
): EntityDashboardWidgetOptionsSerieStatDataPointAttackCount[] {
  return dataEntities
    .sort(
      (a, b) => b.getPropertyAsNumber('count') - a.getPropertyAsNumber('count')
    )
    .slice(0, 3)
}
