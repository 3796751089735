import { ContainerFlex, ContainerHTMLContent } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { HorizontalLine } from '@app/components/Separator'
import { ContainerRbac } from '@app/components-legacy/Container'
import SpinnerInline, {
  SpinnerInlineSize
} from '@app/components-legacy/Spinner/SpinnerInline'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import {
  BackgroundColorV2,
  BrandColorV2,
  FontColorV2
} from '@app/styles/consts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { canEditAzureAdSupport } from '../permissions'
import ConfigurationAzureADSupportForm from './ConfigurationAzureADSupportForm'
import ConfigurationAzureADSupportStatus from './ConfigurationAzureADSupportStatus'

interface IConfigurationAzureADSupportCardProps {
  className?: string
}

const StyledHorizontalLine = styled(HorizontalLine)`
  margin: ${consts.marginVerySmall} 0;
`

const ConfigurationAzureADSupportCard: React.FC<
  IConfigurationAzureADSupportCardProps
> = props => {
  const { storeRoot, storeManagementAzureAD } = useStores()

  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Configuration.Navigation',
      'Management.System.Configuration.AzureADSupport'
    ]
  })

  function renderFormAndStatus(): React.ReactNode[] {
    if (storeManagementAzureAD.storeFlagsTenableCloudPingStatus.isLoading) {
      return [<SpinnerInline size={SpinnerInlineSize.small} />]
    }

    return [
      <ContainerRbac rbacCapabilityCheck={canEditAzureAdSupport}>
        <ConfigurationAzureADSupportForm />
      </ContainerRbac>,

      storeManagementAzureAD.showADSupportStatus && (
        <>
          <StyledHorizontalLine color={BrandColorV2.lightGray} />
          <ConfigurationAzureADSupportStatus />
        </>
      )
    ]
  }

  return (
    <ContainerFlex
      name="formGroup"
      className={props.className}
      direction="column"
      flexGap="small"
      items={[
        <Label>
          <ContainerHTMLContent padded>
            {translate(
              `Tenable.ad uses Tenable Cloud Service to identify Azure AD tenant security risks`,
              {
                interpolations: {
                  tenableApiKeysUrl:
                    storeRoot.environment.config.app.settings.tioapikeys.url
                },
                transformMarkdown: true
              }
            )}
          </ContainerHTMLContent>
        </Label>,

        ...renderFormAndStatus()
      ]}
    />
  )
}

const ObservedConfigurationAzureADSupportCard = observer(
  ConfigurationAzureADSupportCard
)

export default styled(ObservedConfigurationAzureADSupportCard)`
  border-radius: ${consts.borderRadiusLargeRedesign};
  padding: ${consts.paddingLarge};
  background-color: ${BackgroundColorV2.lightGray};

  a {
    color: ${FontColorV2.link};
    font-weight: ${consts.fontWeightBold};
    text-decoration: underline;
  }
`
