import type { Maybe } from '@@types/helpers'
import * as React from 'react'
import type { IDrawerContext } from './types'

export const DrawerContext =
  React.createContext<Maybe<IDrawerContext<any>>>(null)

/**
 * Return the context by typing correctly the IDrawerContext context.
 */
export function useDrawerContext<D extends object>(
  context: React.Context<Maybe<IDrawerContext<D>>>
): Maybe<IDrawerContext<D>> {
  return React.useContext(context)
}
