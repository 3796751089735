import { AppRouteName } from '@app/routes'
import type { StoreEula } from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'

/**
 * Save the read status in the store.
 */
export const onReadConfigurationCheckboxClick =
  (storeEula: StoreEula) => (e: CheckboxChangeEvent) => {
    storeEula.setReadStatus(e.target.checked)
  }

/**
 * Redirect to the HP after having approved the Eula.
 */
export const onApproveEulaClick = (storeEula: StoreEula) => () => {
  storeEula.approveEula()
}

/**
 * Logout if the user doesn't valid the Eula.
 */
export const onCancelEulaClick = (appRouter: AppRouterClient) => () => {
  appRouter.hardRedirect({
    routeName: AppRouteName.MiddlewareAuth_Logout,
    parameters: {}
  })
}
