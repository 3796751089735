import { UserTraceLogType } from '@server/graphql/typeDefs/types'

export const TraceTypeLabelMap = new Map<UserTraceLogType, string>([
  [UserTraceLogType.LoginSucceeded, 'Has logged in'],
  [UserTraceLogType.Logout, 'Has disconnected'],
  [UserTraceLogType.InfrastructureCreated, 'Has created the forest'],
  [UserTraceLogType.InfrastructureDeleted, 'Has removed the forest'],
  [UserTraceLogType.DirectoryCreated, 'Has created the domain'],
  [UserTraceLogType.DirectoryDeleted, 'Has removed the domain'],
  [UserTraceLogType.RoleCreated, 'Has created the role'],
  [UserTraceLogType.RoleDeleted, 'Has removed the role'],
  [UserTraceLogType.UserCreated, 'Has created the user'],
  [UserTraceLogType.UserDeleted, 'Has removed the user'],
  [UserTraceLogType.PageVisited, 'Has visited'],
  [UserTraceLogType.ProfileCreated, 'Has created a security profile'],
  [UserTraceLogType.ProfileDeleted, 'Has deleted a security profile'],
  [UserTraceLogType.ProfileCommited, 'Has committed a security profile'],
  [
    UserTraceLogType.ProfileUnstaged,
    'Has reverted pending changes for a security profile'
  ],
  [UserTraceLogType.LoginFailed, 'Has failed to log in'],
  [UserTraceLogType.DeleteAllTraces, 'Has cleared all traces'],
  [
    UserTraceLogType.ReportConfigurationCreated,
    'Has created the report configuration'
  ]
])
