import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconMegaphoneProps extends IIconProps {}

const IconMegaphone: React.FC<IIconMegaphoneProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 20

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9625 6.12421V2.50019C15.9625 2.32685 15.8727 2.1662 15.7262 2.07489C15.5777 1.9831 15.3951 1.97578 15.2399 2.05243L7.30964 6.00019C6.21388 6.00019 4.04811 6.00019 3.31245 6.00019C1.56252 6.00019 1.56252 12.0002 3.31245 12.0002C3.4701 12.0002 3.69856 12.0002 3.96333 12.0002L4.89851 16.6066C4.94587 16.8391 5.15095 17.007 5.38874 17.007H7.50788C7.65828 17.007 7.80036 16.9397 7.89558 16.8225C7.99031 16.7063 8.0279 16.5529 7.99763 16.4064L7.08882 12.0002C7.16798 12.0002 7.23976 12.0002 7.31062 12.0002L15.2399 15.9475C15.3102 15.9826 15.3863 16.0002 15.4625 16.0002C15.5543 16.0002 15.6451 15.9748 15.7262 15.925C15.8727 15.8342 15.9625 15.6731 15.9625 15.5002V11.8759C16.465 11.7296 16.9033 11.4536 17.2408 11.0314C17.6744 10.4895 17.9127 9.76825 17.9127 9.00019C17.9127 7.75062 17.2407 6.50001 15.9625 6.12421ZM2.99995 9.00019C2.99995 7.91004 3.25019 7.2038 3.41243 7.00019H6.96247V11.0002H3.41243C3.25019 10.7965 2.99995 10.0903 2.99995 9.00019ZM6.89412 16.007H5.79744L4.9842 12.0002C5.3412 12.0002 5.71162 12.0002 6.06782 12.0002L6.89412 16.007ZM14.9625 14.6926L7.96247 11.2085V6.79236L14.9625 3.3078V14.6926ZM15.9625 10.817V7.18336C16.6124 7.51703 16.9127 8.2602 16.9127 9.00019C16.9127 9.74018 16.6124 10.4833 15.9625 10.817Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconMegaphone
