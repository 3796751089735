import type { StoreDashboards } from '@app/stores'

/**
 * Empty function, the redirect to the first dashboards and the initial loading
 * is done in the src/app/pages/Dashboard/index.tsx component, that observes
 * the url parameters.
 */
export const onDashboardsLoad = (storeDashboards: StoreDashboards) => () => {
  // do nothing here
}

/**
 * Clear everything when leaving the dashboard.
 */
export const onDashboardsUnload = (storeDashboards: StoreDashboards) => () => {
  storeDashboards.reset()
}

export const onDashboardCreateFirstButtonClick =
  (storeDashboards: StoreDashboards) => () => {
    storeDashboards.storeDashboardsManagement.storeDrawerAddDashboard.openDrawer()
  }
