import type { PropertiesNullable } from '@@types/helpers'
import type { EntityChecker, EntityDirectory } from '@app/entities'
import { createEntity, EntityDeviance } from '@app/entities'
import EntityBase from '@app/entities/EntityBase'
import type { IEntityListable } from '@app/entities/types'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import type {
  AlertIoE,
  Deviance,
  Maybe,
  MaybeGrantedDeviance
} from '@server/graphql/typeDefs/types'

export interface IDataRowAlertIoE {
  id: number
  label?: string
}

export default class EntityAlertIoE
  extends EntityBase
  implements PropertiesNullable<AlertIoE>, IEntityListable<IDataRowAlertIoE>
{
  id: Maybe<number> = null
  infrastructureId: Maybe<number> = null
  directoryId: Maybe<number> = null
  devianceId: Maybe<number> = null
  archived: Maybe<boolean> = null
  read: Maybe<boolean> = null
  date: Maybe<string> = null
  rbacDeviance: Maybe<MaybeGrantedDeviance> = null

  constructor(data: Partial<AlertIoE>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return the directory impacted by the alert.
   */
  getDirectory(
    allDirectories: Map<number, EntityDirectory>
  ): Maybe<EntityDirectory> {
    return (this.directoryId && allDirectories.get(this.directoryId)) || null
  }

  /**
   * Return the deviance associated to the alert.
   */
  getDeviance(): Maybe<EntityDeviance> {
    if (!this.rbacDeviance) {
      return null
    }

    if (
      !this.rbacDeviance.rbacCapability.isGranted ||
      !this.rbacDeviance.rbacCapability.isFound ||
      !this.rbacDeviance.node
    ) {
      return null
    }

    return createEntity<Deviance, EntityDeviance>(
      EntityDeviance,
      this.rbacDeviance.node
    )
  }

  /**
   * Return the checker at the origin of the alert.
   */
  getChecker(allCheckers: Map<number, EntityChecker>): Maybe<EntityChecker> {
    const checkerId = this.getDeviance()?.checkerId

    if (!checkerId) {
      return null
    }

    return allCheckers.get(checkerId) || null
  }

  /**
   * Return the label of the alert.
   */
  getLabel(): Maybe<string> {
    const deviance = this.getDeviance()

    if (!deviance) {
      return null
    }

    const reason = deviance.getReason()

    if (!deviance || !reason) {
      return null
    }

    return reason.name
  }

  /* Implements IEntityListable */

  getColumns(): Array<IWidgetListColumns<IDataRowAlertIoE>> {
    // Not used as a list for now
    return []
  }

  asDataRow(): IDataRowAlertIoE {
    return {
      id: this.getPropertyAsNumber('id')
    }
  }
}
