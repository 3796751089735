import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import type { AttackPathQueryStringParameters } from '@app/stores/AttackPath/types'
import type { AttacksQueryStringParameters } from '@app/stores/IoA/StoreAttacks/types'
import type { IoABoardQueryStringParameters } from '@app/stores/IoA/StoreBoard/types'
import type { InstanceName } from '@libs/Environment/types'
import AppRouter from '@libs/Router/AppRouter'
import { appendPrefixToPathname } from '@libs/Router/functions'
import type { IRouteDefinition, Routes } from '@libs/Router/types'
import type { ServerRoute } from '@server/routes/server'
import { ServerRouteName, serverRoutes } from '@server/routes/server'

export enum AppRouteName {
  'Root' = 'Root',
  'Profile' = 'Profile',
  'HomePage' = 'HomePage',

  /**
   * Unauthenticated report pages
   */
  'Reports' = 'Reports',

  /* Authentication */

  'Auth' = 'Auth',
  'Auth_Login' = 'Auth_Login',
  'Auth_PendingActivation' = 'Auth_PendingActivation',
  'Auth_License' = 'Auth_License',
  'Auth_Eula' = 'Auth_Eula',
  'Auth_FirstLoginPassword' = 'Auth_FirstLoginPassword',

  /* TrailFlow */

  'TrailFlow' = 'TrailFlow',
  'TrailFlow_EventDetails' = 'TrailFlow_EventDetails',
  'TrailFlow_EventDetails_Attributes' = 'TrailFlow_EventDetails_Attributes',
  'TrailFlow_EventDetails_Deviances' = 'TrailFlow_EventDetails_Deviances',

  /* Indicators of Exposure */

  'IoE_Board' = 'IoE_Board',
  'IoE_Board_AD' = 'IoE_Board_AD',
  'IoE_Board_MEID' = 'IoE_Board_MEID',
  'IoE_Details' = 'IoE_Details',
  'IoE_Details_Information' = 'IoE_Details_Information',
  'IoE_Details_VulnerabilityDetails' = 'IoE_Details_VulnerabilityDetails',
  'IoE_Details_DeviantObjects' = 'IoE_Details_DeviantObjects',
  'IoE_Details_DeviantObjects_EventDetails_Attributes' = 'IoE_Details_DeviantObjects_EventDetails_Attributes',
  'IoE_Details_Recommendations' = 'IoE_Details_Recommendations',

  'IoE_Identity_Details' = 'IoE_Identity_Details',
  'IoE_Identity_Details_Information' = 'IoE_Identity_Details_Information',
  'IoE_Identity_Details_Findings' = 'IoE_Identity_Details_Findings',
  'IoE_Identity_Details_Recommendations' = 'IoE_Identity_Details_Recommendations',

  /* Indicators of attack */

  'IoA_Board' = 'IoA_Board',
  'IoA_Board_PDF' = 'IoA_Board_PDF',
  'IoA_Attacks' = 'IoA_Attacks',

  /* Dashboards */

  'Dashboard' = 'Dashboard',
  'Dashboard_PDF' = 'Dashboard_PDF',
  'Dashboard_Grid' = 'Dashboard_Grid',
  'Dashboard_Grid_WidgetAdd' = 'Dashboard_Grid_WidgetAdd',
  'Dashboard_Grid_WidgetEdit' = 'Dashboard_Grid_WidgetEdit',

  /* Topology */

  'Topology' = 'Topology',
  'Topology_DomainDetails' = 'Topology_DomainDetails',
  'Topology_DeviantObjects' = 'Topology_DeviantObjects',
  'Topology_DeviantObjects_EventDetails_Attributes' = 'Topology_DeviantObjects_EventDetails_Attributes',

  /* Management accounts */

  'Management_Accounts' = 'Management_Accounts',

  /* Management accounts - Users */

  'Management_Accounts_Users' = 'Management_Accounts_Users',
  'Management_Accounts_Users_Create' = 'Management_Accounts_Users_Create',
  'Management_Accounts_Users_Edit' = 'Management_Accounts_Users_Edit',

  /* Management accounts - Roles */

  'Management_Accounts_Roles' = 'Management_Accounts_Roles',
  'Management_Accounts_Roles_Create' = 'Management_Accounts_Roles_Create',
  'Management_Accounts_Roles_Edit' = 'Management_Accounts_Roles_Edit',

  /* Management accounts - Profiles */

  'Management_Accounts_Profiles' = 'Management_Accounts_Profiles',
  'Management_Accounts_Profiles_Create' = 'Management_Accounts_Profiles_Create',
  'Management_Accounts_Profiles_Edit' = 'Management_Accounts_Profiles_Edit',
  'Management_Accounts_Profiles_Edit_Checker' = 'Management_Accounts_Profiles_Edit_Checker',

  /* Management System */

  'Management_System' = 'Management_System',

  /* Management System - Relays */

  'Management_System_Relays' = 'Management_System_Relays',
  'Management_System_Relays_Edit' = 'Management_System_Relays_Edit',

  /* Management System - Infrastructures */

  'Management_System_Infrastructures' = 'Management_System_Infrastructures',
  'Management_System_Infrastructures_Create' = 'Management_System_Infrastructures_Create',
  'Management_System_Infrastructures_Edit' = 'Management_System_Infrastructures_Edit',

  /* Management System - Directories */

  'Management_System_Directories' = 'Management_System_Directories',
  'Management_System_Directories_Create' = 'Management_System_Directories_Create',
  'Management_System_Directories_Edit' = 'Management_System_Directories_Edit',
  'Management_System_Directories_Honey_Account_Create' = 'Management_System_Directories_Honey_Account_Create',
  'Management_System_Directories_Honey_Account_Edit' = 'Management_System_Directories_Honey_Account_Edit',

  /* Management System - Tenants */

  'Management_System_Tenants' = 'Management_System_Tenants',
  'Management_System_Tenants_Create' = 'Management_System_Tenants_Create',
  'Management_System_Tenants_Edit' = 'Management_System_Tenants_Edit',

  /* Management System - Configuration */

  'Management_System_Configuration' = 'Management_System_Configuration',
  'Management_System_Configuration_SMTP' = 'Management_System_Configuration_SMTP',
  'Management_System_Configuration_ActivityLogs' = 'Management_System_Configuration_ActivityLogs',
  'Management_System_Configuration_Security' = 'Management_System_Configuration_Security',
  'Management_System_Configuration_IoA' = 'Management_System_Configuration_IoA',
  'Management_System_Configuration_TenableCloud' = 'Management_System_Configuration_TenableCloud',
  'Management_System_Configuration_Relay' = 'Management_System_Configuration_Relay',
  'Management_System_Configuration_HealthCheck' = 'Management_System_Configuration_HealthCheck',
  'Management_System_Configuration_Telemetry' = 'Management_System_Configuration_Telemetry',
  'Management_System_Configuration_ReportingCenter' = 'Management_System_Configuration_ReportingCenter',

  /* Management System - Configuration - Syslogs */

  'Management_System_Configuration_Syslogs' = 'Management_System_Configuration_Syslogs',
  'Management_System_Configuration_Syslogs_Create' = 'Management_System_Configuration_Syslogs_Create',
  'Management_System_Configuration_Syslogs_Edit' = 'Management_System_Configuration_Syslogs_Edit',

  /* Management System - Configuration - Emails */

  'Management_System_Configuration_Emails' = 'Management_System_Configuration_Emails',
  'Management_System_Configuration_Emails_Create' = 'Management_System_Configuration_Emails_Create',
  'Management_System_Configuration_Emails_Edit' = 'Management_System_Configuration_Emails_Edit',

  /* Management System - Configuration - Configuration */

  'Management_System_Configuration_TenableAccountConfiguration' = 'Management_System_Configuration_TenableAccountConfiguration',
  'Management_System_Configuration_LDAPConfiguration' = 'Management_System_Configuration_LDAPConfiguration',
  'Management_System_Configuration_SAMLConfiguration' = 'Management_System_Configuration_SAMLConfiguration',

  /* Management System - About */

  'Management_System_About' = 'Management_System_About',
  'Management_System_Legal' = 'Management_System_Legal',
  'Management_System_Legal_Eula' = 'Management_System_Legal_Eula',
  'Management_System_Legal_MitreAttack' = 'Management_System_Legal_MitreAttack',
  'Management_System_Legal_Envoy' = 'Management_System_Legal_Envoy',

  /* Preferences */

  'Preferences' = 'Preferences',
  'Preferences_Internationalization' = 'Preferences_Internationalization',
  'Preferences_Profiles' = 'Preferences_Profiles',
  'Preferences_Security' = 'Preferences_Security',
  'Preferences_ApiKey' = 'Preferences_ApiKey',

  /* Debug client */

  'Debug_APIInspector' = 'Debug_APIInspector',

  /* Attack Path */
  'AttackPath' = 'AttackPath',
  'AttackPath_Tier0' = 'AttackPath_Tier0',
  'AttackPath_Tier0_Assets' = 'AttackPath_Tier0_Assets',
  'AttackPath_Tier0_Accounts' = 'AttackPath_Tier0_Accounts',
  'AttackPath_Tier0_Recent' = 'AttackPath_Tier0_Recent',

  /* Middlewares */

  'MiddlewareAssets_Images' = 'MiddlewareAssets_Images',
  'MiddlewareAssets_Icons' = 'MiddlewareAssets_Icons',
  'MiddlewareAssets_Videos' = 'MiddlewareAssets_Videos',
  'MiddlewareAssets_Fonts' = 'MiddlewareAssets_Fonts',

  'MiddlewareAuth_SAML_Redirect' = 'MiddlewareAuth_SAML_Redirect',
  'MiddlewareAuth_SAML_Login' = 'MiddlewareAuth_SAML_Login',
  'MiddlewareAuth_SAML_Metadata' = 'MiddlewareAuth_SAML_Metadata',
  'MiddlewareAuth_Login' = 'MiddlewareAuth_Login',
  'MiddlewareAuth_Logout' = 'MiddlewareAuth_Logout',
  'MiddlewareAuth_Providers' = 'MiddlewareAuth_Providers',
  'MiddlewareAuth_ResetPassword' = 'MiddlewareAuth_ResetPassword',

  'MiddlewareLicense' = 'MiddlewareLicense',

  'MiddlewareSAML_GenerateCertificate' = 'MiddlewareSAML_GenerateCertificate',

  'MiddlewareExportCSV' = 'MiddlewareExportCSV',

  'MiddlewareEula_License' = 'MiddlewareEula_License',

  'MiddlewareAPI_About' = 'MiddlewareAPI_About',

  'MiddlewareReportingCenterReports' = 'MiddlewareReportingCenterReports',
  'MiddlewareReportingCenterReports_Metadata' = 'MiddlewareReportingCenterReports_Metadata',

  'MiddlewareAPI_Export_Profiles_Checkers' = 'MiddlewareAPI_Export_Profiles_Checkers',

  'MiddlewareAPI_Profiles_Attacks_Export' = 'MiddlewareAPI_Profiles_Attacks_Export',

  'MiddlewareIoAConfiguration_GenerateScript' = 'MiddlewareIoAConfiguration_GenerateScript',

  'MiddlewareIoAConfiguration_IoAConfig' = 'MiddlewareIoAConfiguration_IoAConfig',

  'MiddlewarePDF' = 'MiddlewarePDF',

  'MiddlewareFeatureFlags' = 'MiddlewareFeatureFlags',

  'MiddlewareTenableServicesProxy_ApiKeys' = 'MiddlewareTenableServicesProxy_ApiKeys',

  /* ActivityLogs */

  'ActivityLogs' = 'ActivityLogs',

  /* Health check */
  'HealthCheck' = 'HealthCheck',
  'HealthCheck_DomainStatus' = 'HealthCheck_DomainStatus',
  'HealthCheck_DomainStatus_Details' = 'HealthCheck_DomainStatus_Details',
  'HealthCheck_DomainStatus_Details_Information' = 'HealthCheck_DomainStatus_Details_Information',
  'HealthCheck_PlatformStatus' = 'HealthCheck_PlatformStatus',
  'HealthCheck_PlatformStatus_Details' = 'HealthCheck_PlatformStatus_Details',
  'HealthCheck_PlatformStatus_Details_Information' = 'HealthCheck_PlatformStatus_Details_Information',

  /* Server properties (Workspace appSwitcher) */
  'MiddlewareServerProperties' = 'MiddlewareServerProperties',

  /* Identity Explorer */
  'IdentityExplorer' = 'IdentityExplorer',

  /* Global Inventory */
  'Gi' = 'Gi',
  'Gi_Identities' = 'Gi_Identities',
  'Gi_Weaknesses' = 'Gi_Weaknesses',
  'Identities' = 'Identities',

  /* Security Engine */
  'SecurityEngine' = 'SecurityEngine',

  /* Pendo */
  'MiddlewareCloudStatistics' = 'MiddlewareCloudStatistics',

  /* Product association */
  'MiddlewareProductAssociation' = 'MiddlewareProductAssociation'
}

export interface IRootRouteDefinition extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Root
}

export interface IProfileRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Profile
  parameters: {}
}

export interface IHomePageRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.HomePage
  parameters: {}
}

export interface IReportsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Reports
  queryStringParameters: {
    uuid: string
  }
}

export interface IAuthRouteDefinition extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Auth
}

export interface IAuthLoginRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Auth_Login
  parameters: {}
}

export interface IAuthPendingActivationRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Auth_PendingActivation
  parameters: {}
}

export interface IAuthLicenseRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Auth_License
  parameters: {}
}

export interface IAuthEulaRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Auth_Eula
  parameters: {}
}

export interface IAuthFirstLoginPasswordRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Auth_FirstLoginPassword
  parameters: {
    resetPasswordNonce?: string
  }
}

export interface ITrailFlowRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.TrailFlow
  parameters: {}
}

export interface ITrailFlowEventDetailsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.TrailFlow_EventDetails
  parameters: {
    infrastructureId: number
    directoryId: number
    eventId: string
  }
}

export interface ITrailFlowEventDetailsAttributesRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.TrailFlow_EventDetails_Attributes
  parameters: {
    infrastructureId: number
    directoryId: number
    eventId: string
  }
}

export interface ITrailFlowEventDetailsDeviancesRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.TrailFlow_EventDetails_Deviances
  parameters: {
    infrastructureId: number
    directoryId: number
    eventId: string
  }
}

export interface IIoEBoardRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoE_Board
  parameters: {}
}

export interface IIoEBoardAdRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoE_Board_AD
  parameters: {}
}

export interface IIoEBoardMeidRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoE_Board_MEID
  parameters: {}
}

export interface IIoEDetailsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoE_Details
  parameters: {
    checkerId: number
    checkerCodename: GenericCheckerCodename
  }
}

export interface IIoEDetailsInformationRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoE_Details_Information
  parameters: {
    checkerId: number
    checkerCodename: GenericCheckerCodename
  }
}

export interface IIoEDetailsVulnerabilityDetailsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoE_Details_VulnerabilityDetails
  parameters: {
    checkerId: number
    checkerCodename: GenericCheckerCodename
  }
}

export interface IIoEDetailsDeviantObjectsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoE_Details_DeviantObjects
  parameters: {
    checkerId: number
    checkerCodename: GenericCheckerCodename
  }
}

export interface IIoEDetailsDeviantObjectsEventDetailsAttributesRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoE_Details_DeviantObjects_EventDetails_Attributes
  parameters: {
    checkerId: number
    checkerCodename: GenericCheckerCodename
    infrastructureId: number
    directoryId: number
    eventId: string
  }
}

export interface IIoEDetailsRecommendationsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoE_Details_Recommendations
  parameters: {
    checkerId: number
    checkerCodename: GenericCheckerCodename
  }
}

export interface IIoEIdentityDetailsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoE_Identity_Details
  parameters: {
    // CheckerId are uuid for identity checkers
    checkerId: string
    checkerCodename: GenericCheckerCodename
  }
}

export interface IIoEIdentityDetailsInformationRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoE_Identity_Details_Information
  parameters: {
    checkerId: string
    checkerCodename: GenericCheckerCodename
  }
}

export interface IIoEIdentityDetailsFindingsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoE_Identity_Details_Findings
  parameters: {
    checkerId: string
    checkerCodename: GenericCheckerCodename
  }
}

export interface IIoEIdentityDetailsRecommendationsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoE_Identity_Details_Recommendations
  parameters: {
    checkerId: string
    checkerCodename: GenericCheckerCodename
  }
}

export interface IIoABoardRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoA_Board
  parameters: {}
  queryStringParameters?: {
    boardFilters?: IoABoardQueryStringParameters
  }
}

export interface IIoABoardPDFRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoA_Board_PDF
  parameters: {}
  queryStringParameters?: {
    boardFilters?: IoABoardQueryStringParameters
    useUtc?: string
    timezone?: string
  }
}

export interface IIoAAttacksRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IoA_Attacks
  parameters: {}
  queryStringParameters?: {
    boardFilters?: IoABoardQueryStringParameters
    attacksFilters?: AttacksQueryStringParameters[]
  }
}

export interface IDashboardRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Dashboard
  parameters: {}
}

export interface IDashboardExportPDFRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeDefinition?: {}
  routeName: AppRouteName.Dashboard_PDF
  parameters: {}
}

export interface IDashboardGridRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Dashboard_Grid
  parameters: {
    instanceName: InstanceName
    dashboardId: number
  }
}

export interface IDashboardGridWidgetAddRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Dashboard_Grid_WidgetAdd
  parameters: {
    instanceName: InstanceName
    dashboardId: number
  }
}

export interface IDashboardGridWidgetEditRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Dashboard_Grid_WidgetEdit
  parameters: {
    instanceName: InstanceName
    dashboardId: number
    dashboardWidgetId: number
  }
}

export interface ITopologyRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Topology
  parameters: {}
}

export interface ITopologyDomainDetailsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Topology_DomainDetails
  parameters: {
    directoryId: number
  }
}

export interface ITopologyDeviantObjectsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Topology_DeviantObjects
  parameters: {
    checkerId: number
    checkerCodename: GenericCheckerCodename
  }
}

export interface ITopologyDeviantObjectsEventDetailsAttributesRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Topology_DeviantObjects_EventDetails_Attributes
  parameters: {
    checkerId: number
    checkerCodename: GenericCheckerCodename
    infrastructureId: number
    directoryId: number
    eventId: string
  }
}

export interface IManagementAccountsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_Accounts
  parameters: {}
}

export interface IManagementAccountsUsersRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_Accounts_Users
  parameters: {}
}

export interface IManagementAccountsUsersCreateRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_Accounts_Users_Create
  parameters: {}
}

export interface IManagementAccountsUsersEditRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_Accounts_Users_Edit
  parameters: {
    userId: number
  }
}

export interface IManagementAccountsRolesRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_Accounts_Roles
  parameters: {}
}

export interface IManagementAccountsRolesCreateRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_Accounts_Roles_Create
  parameters: {}
}

export interface IManagementAccountsRolesEditRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_Accounts_Roles_Edit
  parameters: {
    roleId: number
  }
}

export interface IManagementAccountsProfilesRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_Accounts_Profiles
  parameters: {}
}

export interface IManagementAccountsProfilesCreateRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_Accounts_Profiles_Create
  parameters: {}
}

export interface IManagementAccountsProfilesEditRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_Accounts_Profiles_Edit
  parameters: {
    profileId: number
  }
}

export interface IManagementAccountsProfilesEditCheckerRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_Accounts_Profiles_Edit_Checker
  parameters: {
    profileId: number
    checkerId: number
    checkerCodename: GenericCheckerCodename
  }
}

export interface IManagementSystemRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System
  parameters: {}
}

export interface IManagementSystemRelaysRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Relays
  parameters: {}
}

export interface IManagementSystemRelaysEditRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Relays_Edit
  parameters: {
    relayId: number
  }
}

export interface IManagementSystemInfrastructuresRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Infrastructures
  parameters: {}
}

export interface IManagementSystemInfrastructuresCreateRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Infrastructures_Create
  parameters: {}
}

export interface IManagementSystemInfrastructuresEditRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Infrastructures_Edit
  parameters: {
    infrastructureId: number
  }
}

export interface IManagementSystemDirectoriesRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Directories
  parameters: {}
}

export interface IManagementSystemDirectoriesCreateRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Directories_Create
  parameters: {}
}

export interface IManagementSystemDirectoriesEditRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Directories_Edit
  parameters: {
    directoryId: number
  }
}

export interface IManagementSystemDirectoriesHoneyAccountCreateRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Directories_Honey_Account_Create
  parameters: {
    directoryId: number
  }
}

export interface IManagementSystemDirectoriesHoneyAccountEditRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Directories_Honey_Account_Edit
  parameters: {
    directoryId: number
  }
}

export interface IManagementSystemTenantsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Tenants
  parameters: {}
}

export interface IManagementSystemTenantsCreateRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Tenants_Create
  parameters: {}
}

export interface IManagementSystemTenantsEditRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Tenants_Edit
  parameters: {
    tenantId: string
  }
}

export interface IManagementSystemConfigurationRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration
  parameters: {}
}

export interface IManagementSystemConfigurationSMTPRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_SMTP
  parameters: {}
}

export interface IManagementSystemConfigurationActivityLogsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_ActivityLogs
  parameters: {}
}

export interface IManagementSystemConfigurationTelemetryRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_Telemetry
  parameters: {}
}

export interface IManagementSystemConfigurationReportingRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_ReportingCenter
  parameters: {}
}

export interface IManagementSystemConfigurationSecurityRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_Security
  parameters: {}
}

export interface IManagementSystemConfigurationDataCollectionTypeRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_TenableCloud
  parameters: {}
}

export interface IManagementSystemConfigurationRelayTypeRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_Relay
  parameters: {}
}

export interface IManagementSystemConfigurationHealthCheckTypeRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_HealthCheck
  parameters: {}
}

export interface IManagementSystemConfigurationAttackTypeRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_IoA
  parameters: {}
}

export interface IManagementSystemConfigurationSyslogsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_Syslogs
  parameters: {}
}

export interface IManagementSystemConfigurationSyslogsCreateRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_Syslogs_Create
  parameters: {}
}

export interface IManagementSystemConfigurationSyslogsEditRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_Syslogs_Edit
  parameters: {
    syslogId: number
  }
}

export interface IManagementSystemConfigurationEmailsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_Emails
  parameters: {}
}

export interface IManagementSystemConfigurationEmailsCreateRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_Emails_Create
  parameters: {}
}

export interface IManagementSystemonfigurationEmailsEditRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_Emails_Edit
  parameters: {
    emailId: number
  }
}

export interface IManagementSystemConfigurationProfileAccountConfigurationRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_TenableAccountConfiguration
  parameters: {}
}

export interface IManagementSystemConfigurationLDAPConfigurationRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_LDAPConfiguration
  parameters: {}
}

export interface IManagementSystemConfigurationSAMLConfigurationRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Configuration_SAMLConfiguration
  parameters: {}
}

export interface IManagementSystemAboutRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_About
  parameters: {}
}

export interface IManagementSystemLegalRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Legal
  parameters: {}
}

export interface IManagementSystemLegalEulaRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Legal_Eula
  parameters: {}
}

export interface IManagementSystemLegalMitreAttackRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Legal_MitreAttack
  parameters: {}
}

export interface IManagementSystemLegalEnvoyRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Management_System_Legal_Envoy
  parameters: {}
}

export interface IPreferencesRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Preferences
  parameters: {}
}

export interface IPreferencesInternationalizationRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Preferences_Internationalization
  parameters: {}
}

export interface IPreferencesProfilesRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Preferences_Profiles
  parameters: {}
}

export interface IPreferencesSecurityRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Preferences_Security
  parameters: {}
}

export interface IPreferencesApiKeyRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Preferences_ApiKey
  parameters: {}
}

export interface IDebugAPIInspectorRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Debug_APIInspector
  parameters: {}
}

export interface IAttackPathRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.AttackPath
  parameters: {}
  queryStringParameters?: AttackPathQueryStringParameters
}

export interface IAttackPathTier0RouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.AttackPath_Tier0
  parameters: {}
}

export interface IAttackPathTier0AssetsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.AttackPath_Tier0_Assets
  parameters: {}
}

export interface IAttackPathTier0AccountsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.AttackPath_Tier0_Accounts
  parameters: {}
  queryStringParameters?: {
    tier0AssetId?: string
  }
}

export interface IAttackPathTier0RecentRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.AttackPath_Tier0_Recent
  parameters: {}
}

export interface IMiddlewareAssetsImagesRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareAssets_Images
  parameters: {
    image: string
  }
}

export interface IMiddlewareAssetsIconsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareAssets_Icons
  parameters: {
    icon: string
  }
}

export interface IMiddlewareAssetsVideosRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareAssets_Videos
  parameters: {
    video: string
  }
}

export interface IMiddlewareAssetsFontsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareAssets_Fonts
  parameters: {
    font: string
  }
}

export interface IMiddlewareAuthSAMLRedirectRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareAuth_SAML_Redirect
  parameters: {}
}

export interface IMiddlewareAuthSAMLLoginRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareAuth_SAML_Login
  parameters: {}
}

export interface IMiddlewareAuthSAMLMetadataRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareAuth_SAML_Metadata
  parameters: {}
}

export interface IMiddlewareAuthLoginRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareAuth_Login
  parameters: {}
}

export interface IMiddlewareAuthLogoutRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareAuth_Logout
  parameters: {}
}

export interface IMiddlewareAuthProvidersRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareAuth_Providers
  parameters: {}
}

export interface IMiddlewareAuthResetPasswordRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareAuth_ResetPassword
  parameters: {}
}

export interface IMiddlewareUploadLicenseRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareLicense
  parameters: {}
}

export interface IMiddlewareSAMLGenerateCertificateRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareSAML_GenerateCertificate
  parameters: {}
}

export interface IMiddlewareExportCSVRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareExportCSV
}

export interface IMiddlewareEulaLicenseRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareEula_License
  parameters: {}
}

export interface IMiddlewareAPIAboutRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareAPI_About
  parameters: {}
}

export interface IMiddlewareReportingCenterReportsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareReportingCenterReports
  parameters: {}
}

export interface IMiddlewareReportingCenterReportsMetadataRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareReportingCenterReports_Metadata
  parameters: {}
  queryStringParameters: {
    uuid: string
    accesstoken: string
  }
}

export interface IMiddlewareAPIExportProfilesCheckersDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareAPI_Export_Profiles_Checkers
  parameters: {
    profileId: number
    checkerId: number
  }
}

export interface IMiddlewareAPIProfilesAttacksExportDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareAPI_Profiles_Attacks_Export
  parameters: {
    profileId: number
  }
}

export interface IMiddlewareIoAConfigurationGenerateScriptRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareIoAConfiguration_GenerateScript
  parameters: {}
}

export interface IMiddlewareIoAConfigurationIoAConfigRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareIoAConfiguration_IoAConfig
  parameters: {}
}

export interface IMiddlewarePdfRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewarePDF
  parameters: {}
}

export interface IMiddlewareFeatureFlagsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareFeatureFlags
  parameters: {}
}

export interface IMiddlewareTenableServicesProxyApiKeysRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareTenableServicesProxy_ApiKeys
  parameters: {}
}

export interface IActivityLogsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.ActivityLogs
  parameters: {}
  queryStringParameters?: {
    dateStart?: string
    dateEnd?: string
    ips?: string[]
    userEmails?: string[]
    selectedLogTypes?: string[]
  }
}

export interface IHealthCheckRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.HealthCheck
  parameters: {}
}

export interface IHealthCheckDomainStatusRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.HealthCheck_DomainStatus
  parameters: {}
}

export interface IHealthCheckDomainStatusDetailsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.HealthCheck_DomainStatus_Details
  parameters: {
    healthCheckName: string
    directoryId: number
  }
}

export interface IHealthCheckDomainStatusDetailsInformationRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.HealthCheck_DomainStatus_Details_Information
  parameters: {
    healthCheckName: string
    directoryId: number
  }
}

export interface IHealthCheckPlatformStatusRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.HealthCheck_PlatformStatus
  parameters: {}
}

export interface IHealthCheckPlatformStatusDetailsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.HealthCheck_PlatformStatus_Details
  parameters: {
    healthCheckName: string
  }
}

export interface IHealthCheckPlatformStatusDetailsInformationRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.HealthCheck_PlatformStatus_Details_Information
  parameters: {
    healthCheckName: string
  }
}

export interface IIdentityExplorerRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.IdentityExplorer
  parameters: {}
}

export interface IGiRouteDefinition extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Gi
  parameters: {}
}

export interface IGiIdentitiesRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Gi_Identities
  parameters: {}
}

export interface IGiKonsoleAssetsInventoryDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Identities
  parameters: {}
}

export interface IGiWeaknessesRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.Gi_Weaknesses
  parameters: {}
}

export interface ISecurityEngineRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.SecurityEngine
  parameters: {}
}

export interface IMiddlewareServerPropertiesRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareServerProperties
  parameters: {}
}

export interface IMiddlewareCloudStatisticsRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareCloudStatistics
  parameters: {}
}

export interface IMiddlewareProductAssociationRouteDefinition
  extends IRouteDefinition<AppRouteName> {
  routeName: AppRouteName.MiddlewareProductAssociation
  parameters: {}
}

export type AppRouteDefinition =
  | IRootRouteDefinition
  | IProfileRouteDefinition
  | IHomePageRouteDefinition
  | IReportsRouteDefinition
  | IAuthRouteDefinition
  | IAuthLoginRouteDefinition
  | IAuthPendingActivationRouteDefinition
  | IAuthEulaRouteDefinition
  | IAuthLicenseRouteDefinition
  | IAuthFirstLoginPasswordRouteDefinition
  | ITrailFlowRouteDefinition
  | ITrailFlowEventDetailsRouteDefinition
  | ITrailFlowEventDetailsAttributesRouteDefinition
  | ITrailFlowEventDetailsDeviancesRouteDefinition
  | IIoEBoardRouteDefinition
  | IIoEBoardAdRouteDefinition
  | IIoEBoardMeidRouteDefinition
  | IIoEDetailsRouteDefinition
  | IIoEDetailsInformationRouteDefinition
  | IIoEDetailsVulnerabilityDetailsRouteDefinition
  | IIoEDetailsDeviantObjectsRouteDefinition
  | IIoEDetailsDeviantObjectsEventDetailsAttributesRouteDefinition
  | IIoEDetailsRecommendationsRouteDefinition
  | IIoEIdentityDetailsRouteDefinition
  | IIoEIdentityDetailsInformationRouteDefinition
  | IIoEIdentityDetailsFindingsRouteDefinition
  | IIoEIdentityDetailsRecommendationsRouteDefinition
  | IIoABoardRouteDefinition
  | IIoABoardPDFRouteDefinition
  | IIoAAttacksRouteDefinition
  | IDashboardRouteDefinition
  | IDashboardExportPDFRouteDefinition
  | IDashboardGridRouteDefinition
  | IDashboardGridWidgetAddRouteDefinition
  | IDashboardGridWidgetEditRouteDefinition
  | ITopologyRouteDefinition
  | ITopologyDomainDetailsRouteDefinition
  | ITopologyDeviantObjectsRouteDefinition
  | ITopologyDeviantObjectsEventDetailsAttributesRouteDefinition
  | IManagementAccountsRouteDefinition
  | IManagementAccountsUsersRouteDefinition
  | IManagementAccountsUsersCreateRouteDefinition
  | IManagementAccountsUsersEditRouteDefinition
  | IManagementAccountsRolesRouteDefinition
  | IManagementAccountsRolesCreateRouteDefinition
  | IManagementAccountsRolesEditRouteDefinition
  | IManagementAccountsProfilesRouteDefinition
  | IManagementAccountsProfilesCreateRouteDefinition
  | IManagementAccountsProfilesEditRouteDefinition
  | IManagementAccountsProfilesEditCheckerRouteDefinition
  | IManagementSystemRouteDefinition
  | IManagementSystemRelaysRouteDefinition
  | IManagementSystemRelaysEditRouteDefinition
  | IManagementSystemInfrastructuresRouteDefinition
  | IManagementSystemInfrastructuresCreateRouteDefinition
  | IManagementSystemInfrastructuresEditRouteDefinition
  | IManagementSystemDirectoriesRouteDefinition
  | IManagementSystemDirectoriesCreateRouteDefinition
  | IManagementSystemDirectoriesEditRouteDefinition
  | IManagementSystemDirectoriesHoneyAccountCreateRouteDefinition
  | IManagementSystemDirectoriesHoneyAccountEditRouteDefinition
  | IManagementSystemTenantsRouteDefinition
  | IManagementSystemTenantsCreateRouteDefinition
  | IManagementSystemTenantsEditRouteDefinition
  | IManagementSystemConfigurationRouteDefinition
  | IManagementSystemConfigurationSMTPRouteDefinition
  | IManagementSystemConfigurationActivityLogsRouteDefinition
  | IManagementSystemConfigurationTelemetryRouteDefinition
  | IManagementSystemConfigurationSecurityRouteDefinition
  | IManagementSystemConfigurationDataCollectionTypeRouteDefinition
  | IManagementSystemConfigurationRelayTypeRouteDefinition
  | IManagementSystemConfigurationHealthCheckTypeRouteDefinition
  | IManagementSystemConfigurationAttackTypeRouteDefinition
  | IManagementSystemConfigurationReportingRouteDefinition
  | IManagementSystemConfigurationSyslogsRouteDefinition
  | IManagementSystemConfigurationSyslogsCreateRouteDefinition
  | IManagementSystemConfigurationSyslogsEditRouteDefinition
  | IManagementSystemConfigurationEmailsRouteDefinition
  | IManagementSystemConfigurationEmailsCreateRouteDefinition
  | IManagementSystemonfigurationEmailsEditRouteDefinition
  | IManagementSystemConfigurationProfileAccountConfigurationRouteDefinition
  | IManagementSystemConfigurationLDAPConfigurationRouteDefinition
  | IManagementSystemConfigurationSAMLConfigurationRouteDefinition
  | IManagementSystemAboutRouteDefinition
  | IManagementSystemLegalRouteDefinition
  | IManagementSystemLegalEulaRouteDefinition
  | IManagementSystemLegalMitreAttackRouteDefinition
  | IManagementSystemLegalEnvoyRouteDefinition
  | IPreferencesRouteDefinition
  | IPreferencesInternationalizationRouteDefinition
  | IPreferencesProfilesRouteDefinition
  | IPreferencesSecurityRouteDefinition
  | IPreferencesApiKeyRouteDefinition
  | IDebugAPIInspectorRouteDefinition
  | IAttackPathRouteDefinition
  | IAttackPathTier0RouteDefinition
  | IAttackPathTier0AssetsRouteDefinition
  | IAttackPathTier0AccountsRouteDefinition
  | IAttackPathTier0RecentRouteDefinition
  | IMiddlewareAssetsImagesRouteDefinition
  | IMiddlewareAssetsIconsRouteDefinition
  | IMiddlewareAssetsVideosRouteDefinition
  | IMiddlewareAssetsFontsRouteDefinition
  | IMiddlewareAuthSAMLRedirectRouteDefinition
  | IMiddlewareAuthSAMLLoginRouteDefinition
  | IMiddlewareAuthSAMLMetadataRouteDefinition
  | IMiddlewareAuthLoginRouteDefinition
  | IMiddlewareAuthLogoutRouteDefinition
  | IMiddlewareAuthProvidersRouteDefinition
  | IMiddlewareAuthResetPasswordRouteDefinition
  | IMiddlewareUploadLicenseRouteDefinition
  | IMiddlewareSAMLGenerateCertificateRouteDefinition
  | IMiddlewareExportCSVRouteDefinition
  | IMiddlewareEulaLicenseRouteDefinition
  | IMiddlewareAPIAboutRouteDefinition
  | IMiddlewareReportingCenterReportsRouteDefinition
  | IMiddlewareReportingCenterReportsMetadataRouteDefinition
  | IMiddlewareAPIExportProfilesCheckersDefinition
  | IMiddlewareAPIProfilesAttacksExportDefinition
  | IMiddlewareIoAConfigurationGenerateScriptRouteDefinition
  | IMiddlewareIoAConfigurationIoAConfigRouteDefinition
  | IMiddlewarePdfRouteDefinition
  | IMiddlewareFeatureFlagsRouteDefinition
  | IActivityLogsRouteDefinition
  | IHealthCheckRouteDefinition
  | IHealthCheckDomainStatusRouteDefinition
  | IHealthCheckDomainStatusDetailsRouteDefinition
  | IHealthCheckDomainStatusDetailsInformationRouteDefinition
  | IHealthCheckPlatformStatusRouteDefinition
  | IHealthCheckPlatformStatusDetailsRouteDefinition
  | IHealthCheckPlatformStatusDetailsInformationRouteDefinition
  | IIdentityExplorerRouteDefinition
  | IGiRouteDefinition
  | IGiIdentitiesRouteDefinition
  | IGiKonsoleAssetsInventoryDefinition
  | IGiWeaknessesRouteDefinition
  | ISecurityEngineRouteDefinition
  | IMiddlewareServerPropertiesRouteDefinition
  | IMiddlewareCloudStatisticsRouteDefinition
  | IMiddlewareProductAssociationRouteDefinition
  | IMiddlewareTenableServicesProxyApiKeysRouteDefinition

const prefixWithAssetsMiddleware = appendPrefixToPathname<
  ServerRouteName,
  ServerRoute
>(new AppRouter(serverRoutes), ServerRouteName.MiddlewareAssets)

const prefixWithAuthMiddleware = appendPrefixToPathname<
  ServerRouteName,
  ServerRoute
>(new AppRouter(serverRoutes), ServerRouteName.MiddlewareAuth)

const prefixWithSAMLMiddleware = appendPrefixToPathname<
  ServerRouteName,
  ServerRoute
>(new AppRouter(serverRoutes), ServerRouteName.MiddlewareSAML)

const prefixWithExportMiddleware = appendPrefixToPathname<
  ServerRouteName,
  ServerRoute
>(new AppRouter(serverRoutes), ServerRouteName.MiddlewareExportCSV)

const prefixWithReportingCenterReportsMiddleware = appendPrefixToPathname<
  ServerRouteName,
  ServerRoute
>(new AppRouter(serverRoutes), ServerRouteName.MiddlewareReportingCenterReports)

const prefixWithUploadMiddleware = appendPrefixToPathname<
  ServerRouteName,
  ServerRoute
>(new AppRouter(serverRoutes), ServerRouteName.MiddlewareUpload)

const prefixWithEulaMiddleware = appendPrefixToPathname<
  ServerRouteName,
  ServerRoute
>(new AppRouter(serverRoutes), ServerRouteName.MiddlewareEula)

const prefixWithAPIMiddleware = appendPrefixToPathname<
  ServerRouteName,
  ServerRoute
>(new AppRouter(serverRoutes), ServerRouteName.MiddlewareAPI)

const prefixWithIOAMiddleware = appendPrefixToPathname<
  ServerRouteName,
  ServerRoute
>(new AppRouter(serverRoutes), ServerRouteName.MiddlewareIoA)

const prefixWithTenableServicesProxyMiddleware = appendPrefixToPathname<
  ServerRouteName,
  ServerRoute
>(new AppRouter(serverRoutes), ServerRouteName.MiddlewareTenableServicesProxy)

export const appRoutes: Routes<AppRouteName> = {
  [AppRouteName.Root]: {
    routeName: AppRouteName.Root,
    abstract: true,
    pathname: '/',
    noTelemetry: true
  },

  [AppRouteName.Profile]: {
    routeName: AppRouteName.Profile,
    abstract: true,
    pathname: '/profile/:profileName',
    noTelemetry: true
  },

  [AppRouteName.HomePage]: {
    routeName: AppRouteName.HomePage,
    pathname: '/profile/:profileName/dashboard',
    noTelemetry: true
  },

  [AppRouteName.Reports]: {
    routeName: AppRouteName.Reports,
    pathname: '/reports',
    noTelemetry: true
  },

  [AppRouteName.Auth]: {
    routeName: AppRouteName.Auth,
    pathname: '/auth',
    noTelemetry: true
  },

  [AppRouteName.Auth_Login]: {
    routeName: AppRouteName.Auth_Login,
    pathname: '/auth/login'
  },

  [AppRouteName.Auth_PendingActivation]: {
    routeName: AppRouteName.Auth_PendingActivation,
    pathname: '/auth/pending-activation'
  },

  [AppRouteName.Auth_License]: {
    routeName: AppRouteName.Auth_License,
    pathname: '/auth/license'
  },

  [AppRouteName.Auth_Eula]: {
    routeName: AppRouteName.Auth_Eula,
    pathname: '/auth/eula'
  },

  [AppRouteName.Auth_FirstLoginPassword]: {
    routeName: AppRouteName.Auth_FirstLoginPassword,
    pathname: '/auth/first-login-password'
  },

  [AppRouteName.TrailFlow]: {
    routeName: AppRouteName.TrailFlow,
    pathname: '/profile/:profileName/trail-flow'
  },

  [AppRouteName.TrailFlow_EventDetails]: {
    routeName: AppRouteName.TrailFlow_EventDetails,
    pathname:
      '/profile/:profileName/trail-flow/events/:infrastructureId,:directoryId,:eventId',
    noTelemetry: true
  },

  [AppRouteName.TrailFlow_EventDetails_Attributes]: {
    routeName: AppRouteName.TrailFlow_EventDetails_Attributes,
    pathname:
      '/profile/:profileName/trail-flow/events/:infrastructureId,:directoryId,:eventId/attributes'
  },

  [AppRouteName.TrailFlow_EventDetails_Deviances]: {
    routeName: AppRouteName.TrailFlow_EventDetails_Deviances,
    pathname:
      '/profile/:profileName/trail-flow/events/:infrastructureId,:directoryId,:eventId/deviances'
  },

  [AppRouteName.IoE_Board]: {
    routeName: AppRouteName.IoE_Board,
    pathname: '/profile/:profileName/indicators-of-exposure'
  },

  [AppRouteName.IoE_Board_AD]: {
    routeName: AppRouteName.IoE_Board_AD,
    pathname: '/profile/:profileName/indicators-of-exposure/ad'
  },

  [AppRouteName.IoE_Board_MEID]: {
    routeName: AppRouteName.IoE_Board_MEID,
    pathname: '/profile/:profileName/indicators-of-exposure/meid'
  },

  [AppRouteName.IoE_Details]: {
    routeName: AppRouteName.IoE_Details,
    pathname:
      '/profile/:profileName/indicators-of-exposure/ad/details/:checkerId-:checkerCodename',
    noTelemetry: true
  },

  [AppRouteName.IoE_Details_Information]: {
    routeName: AppRouteName.IoE_Details_Information,
    pathname:
      '/profile/:profileName/indicators-of-exposure/ad/details/:checkerId-:checkerCodename/information'
  },

  [AppRouteName.IoE_Details_Recommendations]: {
    routeName: AppRouteName.IoE_Details_Recommendations,
    pathname:
      '/profile/:profileName/indicators-of-exposure/ad/details/:checkerId-:checkerCodename/recommendations'
  },

  [AppRouteName.IoE_Details_VulnerabilityDetails]: {
    routeName: AppRouteName.IoE_Details_VulnerabilityDetails,
    pathname:
      '/profile/:profileName/indicators-of-exposure/ad/details/:checkerId-:checkerCodename/vulnerability-details'
  },

  [AppRouteName.IoE_Details_DeviantObjects]: {
    routeName: AppRouteName.IoE_Details_DeviantObjects,
    pathname:
      '/profile/:profileName/indicators-of-exposure/ad/details/:checkerId-:checkerCodename/deviant-objects'
  },

  // Event details on Deviant objects

  [AppRouteName.IoE_Details_DeviantObjects_EventDetails_Attributes]: {
    routeName: AppRouteName.IoE_Details_DeviantObjects_EventDetails_Attributes,
    pathname:
      '/profile/:profileName/indicators-of-exposure/ad/details/:checkerId-:checkerCodename/deviant-objects/events/:infrastructureId,:directoryId,:eventId/attributes'
  },

  [AppRouteName.IoE_Identity_Details]: {
    routeName: AppRouteName.IoE_Identity_Details,
    pathname:
      '/profile/:profileName/indicators-of-exposure/meid/details/:checkerId/:checkerCodename',
    noTelemetry: true
  },

  [AppRouteName.IoE_Identity_Details_Information]: {
    routeName: AppRouteName.IoE_Identity_Details_Information,
    pathname:
      '/profile/:profileName/indicators-of-exposure/meid/details/:checkerId/:checkerCodename/information'
  },

  [AppRouteName.IoE_Identity_Details_Findings]: {
    routeName: AppRouteName.IoE_Identity_Details_Findings,
    pathname:
      '/profile/:profileName/indicators-of-exposure/meid/details/:checkerId/:checkerCodename/findings'
  },

  [AppRouteName.IoE_Identity_Details_Recommendations]: {
    routeName: AppRouteName.IoE_Identity_Details_Recommendations,
    pathname:
      '/profile/:profileName/indicators-of-exposure/meid/details/:checkerId/:checkerCodename/recommendations'
  },

  // End of Event details on Deviant objects

  [AppRouteName.IoA_Board]: {
    routeName: AppRouteName.IoA_Board,
    pathname: '/profile/:profileName/indicators-of-attacks'
  },

  [AppRouteName.IoA_Board_PDF]: {
    routeName: AppRouteName.IoA_Board_PDF,
    pathname: '/profile/:profileName/pdf/indicators-of-attacks'
  },

  [AppRouteName.IoA_Attacks]: {
    routeName: AppRouteName.IoA_Attacks,
    pathname: '/profile/:profileName/indicators-of-attacks/incidents'
  },

  [AppRouteName.Dashboard]: {
    routeName: AppRouteName.Dashboard,
    pathname: '/profile/:profileName/dashboard',
    noTelemetry: true
  },

  [AppRouteName.Dashboard_PDF]: {
    routeName: AppRouteName.Dashboard_PDF,
    pathname: '/profile/:profileName/pdf/dashboard'
  },

  [AppRouteName.Dashboard_Grid]: {
    routeName: AppRouteName.Dashboard_Grid,
    pathname: '/profile/:profileName/dashboard/:instanceName,:dashboardId'
  },

  [AppRouteName.Dashboard_Grid_WidgetAdd]: {
    routeName: AppRouteName.Dashboard_Grid_WidgetAdd,
    pathname:
      '/profile/:profileName/dashboard/:instanceName,:dashboardId/widget/add'
  },

  [AppRouteName.Dashboard_Grid_WidgetEdit]: {
    routeName: AppRouteName.Dashboard_Grid_WidgetEdit,
    pathname:
      '/profile/:profileName/dashboard/:instanceName,:dashboardId/widget/:dashboardWidgetId/edit'
  },

  [AppRouteName.Topology]: {
    routeName: AppRouteName.Topology,
    pathname: '/profile/:profileName/topology'
  },

  [AppRouteName.Topology_DomainDetails]: {
    routeName: AppRouteName.Topology_DomainDetails,
    pathname: '/profile/:profileName/topology/domains/:directoryId'
  },

  [AppRouteName.Topology_DeviantObjects]: {
    routeName: AppRouteName.Topology_DeviantObjects,
    pathname:
      '/profile/:profileName/topology/checkers/:checkerId-:checkerCodename/deviant-objects'
  },

  // Event details on Deviant objects

  [AppRouteName.Topology_DeviantObjects_EventDetails_Attributes]: {
    routeName: AppRouteName.Topology_DeviantObjects_EventDetails_Attributes,
    pathname:
      '/profile/:profileName/topology/checkers/:checkerId-:checkerCodename/deviant-objects/events/:infrastructureId,:directoryId,:eventId/attributes'
  },

  [AppRouteName.Management_Accounts]: {
    routeName: AppRouteName.Management_Accounts,
    abstract: true,
    pathname: '/profile/:profileName/management/accounts',
    noTelemetry: true
  },

  [AppRouteName.Management_Accounts_Users]: {
    routeName: AppRouteName.Management_Accounts_Users,
    pathname: '/profile/:profileName/management/accounts/users'
  },

  [AppRouteName.Management_Accounts_Users_Create]: {
    routeName: AppRouteName.Management_Accounts_Users_Create,
    pathname: '/profile/:profileName/management/accounts/users/create'
  },

  [AppRouteName.Management_Accounts_Users_Edit]: {
    routeName: AppRouteName.Management_Accounts_Users_Edit,
    pathname: '/profile/:profileName/management/accounts/users/edit/:userId'
  },

  [AppRouteName.Management_Accounts_Roles]: {
    routeName: AppRouteName.Management_Accounts_Roles,
    pathname: '/profile/:profileName/management/accounts/roles'
  },

  [AppRouteName.Management_Accounts_Roles_Create]: {
    routeName: AppRouteName.Management_Accounts_Roles_Create,
    pathname: '/profile/:profileName/management/accounts/roles/create'
  },

  [AppRouteName.Management_Accounts_Roles_Edit]: {
    routeName: AppRouteName.Management_Accounts_Roles_Edit,
    pathname: '/profile/:profileName/management/accounts/roles/edit/:roleId'
  },

  [AppRouteName.Management_Accounts_Profiles]: {
    routeName: AppRouteName.Management_Accounts_Profiles,
    pathname: '/profile/:profileName/management/accounts/profiles'
  },

  [AppRouteName.Management_Accounts_Profiles_Create]: {
    routeName: AppRouteName.Management_Accounts_Profiles_Create,
    pathname: '/profile/:profileName/management/accounts/profiles/create'
  },

  [AppRouteName.Management_Accounts_Profiles_Edit]: {
    routeName: AppRouteName.Management_Accounts_Profiles_Edit,
    pathname:
      '/profile/:profileName/management/accounts/profiles/edit/:profileId'
  },

  [AppRouteName.Management_Accounts_Profiles_Edit_Checker]: {
    routeName: AppRouteName.Management_Accounts_Profiles_Edit_Checker,
    pathname:
      '/profile/:profileName/management/accounts/profiles/edit/:profileId/checker/:checkerId-:checkerCodename'
  },

  [AppRouteName.Management_System]: {
    routeName: AppRouteName.Management_System,
    pathname: '/profile/:profileName/management/system',
    noTelemetry: true
  },

  [AppRouteName.Management_System_Relays]: {
    routeName: AppRouteName.Management_System_Relays,
    pathname: '/profile/:profileName/management/system/relays'
  },

  [AppRouteName.Management_System_Relays_Edit]: {
    routeName: AppRouteName.Management_System_Relays_Edit,
    pathname: '/profile/:profileName/management/system/relays/edit/:relayId'
  },

  [AppRouteName.Management_System_Infrastructures]: {
    routeName: AppRouteName.Management_System_Infrastructures,
    pathname: '/profile/:profileName/management/system/infrastructures'
  },

  [AppRouteName.Management_System_Infrastructures_Create]: {
    routeName: AppRouteName.Management_System_Infrastructures_Create,
    pathname: '/profile/:profileName/management/system/infrastructures/create'
  },

  [AppRouteName.Management_System_Infrastructures_Edit]: {
    routeName: AppRouteName.Management_System_Infrastructures_Edit,
    pathname:
      '/profile/:profileName/management/system/infrastructures/edit/:infrastructureId'
  },

  [AppRouteName.Management_System_Directories]: {
    routeName: AppRouteName.Management_System_Directories,
    pathname: '/profile/:profileName/management/system/directories'
  },

  [AppRouteName.Management_System_Directories_Create]: {
    routeName: AppRouteName.Management_System_Directories_Create,
    pathname: '/profile/:profileName/management/system/directories/create'
  },

  [AppRouteName.Management_System_Directories_Edit]: {
    routeName: AppRouteName.Management_System_Directories_Edit,
    pathname:
      '/profile/:profileName/management/system/directories/edit/:directoryId'
  },

  [AppRouteName.Management_System_Directories_Honey_Account_Create]: {
    routeName: AppRouteName.Management_System_Directories_Honey_Account_Create,
    pathname:
      '/profile/:profileName/management/system/directories/:directoryId/honey-account/create'
  },

  [AppRouteName.Management_System_Directories_Honey_Account_Edit]: {
    routeName: AppRouteName.Management_System_Directories_Honey_Account_Edit,
    pathname:
      '/profile/:profileName/management/system/directories/:directoryId/honey-account/edit'
  },

  [AppRouteName.Management_System_Tenants]: {
    routeName: AppRouteName.Management_System_Tenants,
    pathname: '/profile/:profileName/management/system/tenants'
  },

  [AppRouteName.Management_System_Tenants_Create]: {
    routeName: AppRouteName.Management_System_Tenants_Create,
    pathname: '/profile/:profileName/management/system/tenants/create'
  },

  [AppRouteName.Management_System_Tenants_Edit]: {
    routeName: AppRouteName.Management_System_Tenants_Edit,
    pathname: '/profile/:profileName/management/system/tenants/edit/:tenantId'
  },

  [AppRouteName.Management_System_Configuration]: {
    routeName: AppRouteName.Management_System_Configuration,
    pathname: '/profile/:profileName/management/system/configuration',
    noTelemetry: true
  },

  [AppRouteName.Management_System_Configuration_SMTP]: {
    routeName: AppRouteName.Management_System_Configuration_SMTP,
    pathname: '/profile/:profileName/management/system/configuration/smtp'
  },

  [AppRouteName.Management_System_Configuration_ActivityLogs]: {
    routeName: AppRouteName.Management_System_Configuration_ActivityLogs,
    pathname:
      '/profile/:profileName/management/system/configuration/activity-logs'
  },

  [AppRouteName.Management_System_Configuration_Telemetry]: {
    routeName: AppRouteName.Management_System_Configuration_Telemetry,
    pathname: '/profile/:profileName/management/system/configuration/telemetry'
  },

  [AppRouteName.Management_System_Configuration_ReportingCenter]: {
    routeName: AppRouteName.Management_System_Configuration_ReportingCenter,
    pathname:
      '/profile/:profileName/management/system/configuration/reporting-center'
  },

  [AppRouteName.Management_System_Configuration_Security]: {
    routeName: AppRouteName.Management_System_Configuration_Security,
    pathname: '/profile/:profileName/management/system/configuration/security'
  },

  [AppRouteName.Management_System_Configuration_TenableCloud]: {
    routeName: AppRouteName.Management_System_Configuration_TenableCloud,
    pathname:
      '/profile/:profileName/management/system/configuration/tenable-cloud'
  },

  [AppRouteName.Management_System_Configuration_Relay]: {
    routeName: AppRouteName.Management_System_Configuration_Relay,
    pathname: '/profile/:profileName/management/system/configuration/relay'
  },

  [AppRouteName.Management_System_Configuration_HealthCheck]: {
    routeName: AppRouteName.Management_System_Configuration_HealthCheck,
    pathname:
      '/profile/:profileName/management/system/configuration/health-check'
  },

  [AppRouteName.Management_System_Configuration_IoA]: {
    routeName: AppRouteName.Management_System_Configuration_IoA,
    pathname: '/profile/:profileName/management/system/configuration/ioa'
  },

  [AppRouteName.Management_System_Configuration_Syslogs]: {
    routeName: AppRouteName.Management_System_Configuration_Syslogs,
    pathname: '/profile/:profileName/management/system/configuration/syslogs'
  },

  [AppRouteName.Management_System_Configuration_Syslogs_Create]: {
    routeName: AppRouteName.Management_System_Configuration_Syslogs_Create,
    pathname:
      '/profile/:profileName/management/system/configuration/syslogs/create'
  },

  [AppRouteName.Management_System_Configuration_Syslogs_Edit]: {
    routeName: AppRouteName.Management_System_Configuration_Syslogs_Edit,
    pathname:
      '/profile/:profileName/management/system/configuration/syslogs/edit/:syslogId'
  },

  [AppRouteName.Management_System_Configuration_Emails]: {
    routeName: AppRouteName.Management_System_Configuration_Emails,
    pathname: '/profile/:profileName/management/system/configuration/emails'
  },

  [AppRouteName.Management_System_Configuration_Emails_Create]: {
    routeName: AppRouteName.Management_System_Configuration_Emails_Create,
    pathname:
      '/profile/:profileName/management/system/configuration/emails/create'
  },

  [AppRouteName.Management_System_Configuration_Emails_Edit]: {
    routeName: AppRouteName.Management_System_Configuration_Emails_Edit,
    pathname:
      '/profile/:profileName/management/system/configuration/emails/edit/:emailId'
  },

  [AppRouteName.Management_System_Configuration_TenableAccountConfiguration]: {
    routeName:
      AppRouteName.Management_System_Configuration_TenableAccountConfiguration,
    pathname:
      '/profile/:profileName/management/system/configuration/tenable-account-configuration'
  },

  [AppRouteName.Management_System_Configuration_LDAPConfiguration]: {
    routeName: AppRouteName.Management_System_Configuration_LDAPConfiguration,
    pathname:
      '/profile/:profileName/management/system/configuration/ldap-configuration'
  },

  [AppRouteName.Management_System_Configuration_SAMLConfiguration]: {
    routeName: AppRouteName.Management_System_Configuration_SAMLConfiguration,
    pathname:
      '/profile/:profileName/management/system/configuration/saml-configuration'
  },

  [AppRouteName.Management_System_About]: {
    routeName: AppRouteName.Management_System_About,
    pathname: '/profile/:profileName/management/system/about'
  },

  [AppRouteName.Management_System_Legal]: {
    routeName: AppRouteName.Management_System_Legal,
    pathname: '/profile/:profileName/management/system/legal',
    noTelemetry: true
  },

  [AppRouteName.Management_System_Legal_Eula]: {
    routeName: AppRouteName.Management_System_Legal_Eula,
    pathname: '/profile/:profileName/management/system/legal/eula'
  },

  [AppRouteName.Management_System_Legal_MitreAttack]: {
    routeName: AppRouteName.Management_System_Legal_MitreAttack,
    pathname: '/profile/:profileName/management/system/legal/mitre-attack'
  },

  [AppRouteName.Management_System_Legal_Envoy]: {
    routeName: AppRouteName.Management_System_Legal_Envoy,
    pathname: '/profile/:profileName/management/system/legal/envoy'
  },

  [AppRouteName.Preferences]: {
    routeName: AppRouteName.Preferences,
    pathname: '/profile/:profileName/preferences',
    noTelemetry: true
  },

  [AppRouteName.Preferences_Internationalization]: {
    routeName: AppRouteName.Preferences_Internationalization,
    pathname: '/profile/:profileName/preferences/internationalization'
  },

  [AppRouteName.Preferences_Profiles]: {
    routeName: AppRouteName.Preferences_Profiles,
    pathname: '/profile/:profileName/preferences/profiles'
  },

  [AppRouteName.Preferences_Security]: {
    routeName: AppRouteName.Preferences_Security,
    pathname: '/profile/:profileName/preferences/security'
  },

  [AppRouteName.Preferences_ApiKey]: {
    routeName: AppRouteName.Preferences_ApiKey,
    pathname: '/profile/:profileName/preferences/api-key'
  },

  [AppRouteName.Debug_APIInspector]: {
    routeName: AppRouteName.Debug_APIInspector,
    pathname: '/debug/api-inspector'
  },

  [AppRouteName.AttackPath]: {
    routeName: AppRouteName.AttackPath,
    pathname: '/profile/:profileName/attack-path'
  },

  [AppRouteName.AttackPath_Tier0]: {
    routeName: AppRouteName.AttackPath_Tier0,
    pathname: '/profile/:profileName/attack-path/tier0'
  },

  [AppRouteName.AttackPath_Tier0_Assets]: {
    routeName: AppRouteName.AttackPath_Tier0_Assets,
    pathname: '/profile/:profileName/attack-path/tier0/assets'
  },

  [AppRouteName.AttackPath_Tier0_Accounts]: {
    routeName: AppRouteName.AttackPath_Tier0_Accounts,
    pathname: '/profile/:profileName/attack-path/tier0/accounts'
  },

  [AppRouteName.AttackPath_Tier0_Recent]: {
    routeName: AppRouteName.AttackPath_Tier0_Recent,
    pathname: '/profile/:profileName/attack-path/tier0/recent'
  },

  [AppRouteName.ActivityLogs]: {
    routeName: AppRouteName.ActivityLogs,
    pathname: '/activity-logs'
  },

  [AppRouteName.HealthCheck]: {
    routeName: AppRouteName.HealthCheck,
    pathname: '/health-check'
  },

  [AppRouteName.HealthCheck_DomainStatus]: {
    routeName: AppRouteName.HealthCheck_DomainStatus,
    pathname: '/health-check/domain-status'
  },

  [AppRouteName.HealthCheck_DomainStatus_Details]: {
    routeName: AppRouteName.HealthCheck_DomainStatus_Details,
    pathname:
      '/health-check/domain-status/details/:healthCheckName/:directoryId'
  },

  [AppRouteName.HealthCheck_DomainStatus_Details_Information]: {
    routeName: AppRouteName.HealthCheck_DomainStatus_Details_Information,
    pathname:
      '/health-check/domain-status/details/:healthCheckName/:directoryId/information'
  },

  [AppRouteName.HealthCheck_PlatformStatus]: {
    routeName: AppRouteName.HealthCheck_PlatformStatus,
    pathname: '/health-check/platform-status'
  },

  [AppRouteName.HealthCheck_PlatformStatus_Details]: {
    routeName: AppRouteName.HealthCheck_PlatformStatus_Details,
    pathname: '/health-check/platform-status/details/:healthCheckName'
  },

  [AppRouteName.HealthCheck_PlatformStatus_Details_Information]: {
    routeName: AppRouteName.HealthCheck_PlatformStatus_Details_Information,
    pathname:
      '/health-check/platform-status/details/:healthCheckName/information'
  },

  [AppRouteName.IdentityExplorer]: {
    routeName: AppRouteName.IdentityExplorer,
    pathname: '/identity-explorer'
  },

  [AppRouteName.Gi]: {
    routeName: AppRouteName.Gi,
    pathname: '/gi'
  },

  [AppRouteName.Gi_Identities]: {
    routeName: AppRouteName.Gi_Identities,
    pathname: '/gi/identities'
  },

  [AppRouteName.Identities]: {
    routeName: AppRouteName.Identities,
    pathname: '/identities'
  },


  [AppRouteName.Gi_Weaknesses]: {
    routeName: AppRouteName.Gi_Weaknesses,
    pathname: '/gi/weaknesses'
  },

  [AppRouteName.SecurityEngine]: {
    routeName: AppRouteName.SecurityEngine,
    pathname: '/security-engine'
  },

  [AppRouteName.MiddlewareServerProperties]: {
    routeName: AppRouteName.MiddlewareServerProperties,
    pathname: '/w/server-properties'
  },

  [AppRouteName.MiddlewareCloudStatistics]: {
    routeName: AppRouteName.MiddlewareCloudStatistics,
    pathname: '/w/cloud-statistics'
  },

  [AppRouteName.MiddlewareAssets_Images]: {
    routeName: AppRouteName.MiddlewareAssets_Images,
    pathname: prefixWithAssetsMiddleware(
      ServerRouteName.MiddlewareAssets_Images
    )
  },

  [AppRouteName.MiddlewareAssets_Icons]: {
    routeName: AppRouteName.MiddlewareAssets_Icons,
    pathname: prefixWithAssetsMiddleware(ServerRouteName.MiddlewareAssets_Icons)
  },

  [AppRouteName.MiddlewareAssets_Videos]: {
    routeName: AppRouteName.MiddlewareAssets_Videos,
    pathname: prefixWithAssetsMiddleware(
      ServerRouteName.MiddlewareAssets_Videos
    )
  },

  [AppRouteName.MiddlewareAssets_Fonts]: {
    routeName: AppRouteName.MiddlewareAssets_Fonts,
    pathname: prefixWithAssetsMiddleware(ServerRouteName.MiddlewareAssets_Fonts)
  },

  [AppRouteName.MiddlewareAuth_SAML_Redirect]: {
    routeName: AppRouteName.MiddlewareAuth_SAML_Redirect,
    pathname: prefixWithAuthMiddleware(
      ServerRouteName.MiddlewareAuth_SAML_Redirect
    )
  },

  [AppRouteName.MiddlewareAuth_SAML_Login]: {
    routeName: AppRouteName.MiddlewareAuth_SAML_Login,
    pathname: prefixWithAuthMiddleware(
      ServerRouteName.MiddlewareAuth_SAML_Login
    )
  },

  [AppRouteName.MiddlewareAuth_SAML_Metadata]: {
    routeName: AppRouteName.MiddlewareAuth_SAML_Metadata,
    pathname: prefixWithAuthMiddleware(
      ServerRouteName.MiddlewareAuth_SAML_ExportMetadata
    )
  },

  [AppRouteName.MiddlewareAuth_Login]: {
    routeName: AppRouteName.MiddlewareAuth_Login,
    pathname: prefixWithAuthMiddleware(ServerRouteName.MiddlewareAuth_Login)
  },

  [AppRouteName.MiddlewareAuth_Logout]: {
    routeName: AppRouteName.MiddlewareAuth_Logout,
    pathname: prefixWithAuthMiddleware(ServerRouteName.MiddlewareAuth_Logout)
  },

  [AppRouteName.MiddlewareAuth_Providers]: {
    routeName: AppRouteName.MiddlewareAuth_Providers,
    pathname: prefixWithAuthMiddleware(ServerRouteName.MiddlewareAuth_Providers)
  },

  [AppRouteName.MiddlewareAuth_ResetPassword]: {
    routeName: AppRouteName.MiddlewareAuth_ResetPassword,
    pathname: prefixWithAuthMiddleware(
      ServerRouteName.MiddlewareAuth_ResetPassword
    )
  },

  [AppRouteName.MiddlewareSAML_GenerateCertificate]: {
    routeName: AppRouteName.MiddlewareSAML_GenerateCertificate,
    pathname: prefixWithSAMLMiddleware(
      ServerRouteName.MiddlewareSAML_GenerateCertificate
    )
  },

  [AppRouteName.MiddlewareExportCSV]: {
    routeName: AppRouteName.MiddlewareExportCSV,
    pathname: prefixWithExportMiddleware(null)
  },

  [AppRouteName.MiddlewareLicense]: {
    routeName: AppRouteName.MiddlewareLicense,
    pathname: prefixWithUploadMiddleware(ServerRouteName.MiddlewareEula_License)
  },

  [AppRouteName.MiddlewareEula_License]: {
    routeName: AppRouteName.MiddlewareEula_License,
    pathname: prefixWithEulaMiddleware(ServerRouteName.MiddlewareEula_License)
  },

  [AppRouteName.MiddlewareAPI_About]: {
    routeName: AppRouteName.MiddlewareAPI_About,
    pathname: prefixWithAPIMiddleware(ServerRouteName.MiddlewareAPI_About)
  },

  [AppRouteName.MiddlewareProductAssociation]: {
    routeName: AppRouteName.MiddlewareProductAssociation,
    pathname: prefixWithAPIMiddleware(
      ServerRouteName.MiddlewareAPI_License_ProductAssociation
    )
  },

  [AppRouteName.MiddlewareReportingCenterReports]: {
    routeName: AppRouteName.MiddlewareReportingCenterReports,
    pathname: prefixWithReportingCenterReportsMiddleware(null)
  },

  [AppRouteName.MiddlewareReportingCenterReports_Metadata]: {
    routeName: AppRouteName.MiddlewareReportingCenterReports_Metadata,
    pathname: prefixWithReportingCenterReportsMiddleware(
      ServerRouteName.MiddlewareReportingCenterReports_Metadata
    )
  },

  [AppRouteName.MiddlewareAPI_Export_Profiles_Checkers]: {
    routeName: AppRouteName.MiddlewareAPI_Export_Profiles_Checkers,
    pathname: prefixWithAPIMiddleware(
      ServerRouteName.MiddlewareAPI_Export_Profiles_Checkers
    )
  },

  [AppRouteName.MiddlewareAPI_Profiles_Attacks_Export]: {
    routeName: AppRouteName.MiddlewareAPI_Profiles_Attacks_Export,
    pathname: prefixWithAPIMiddleware(
      ServerRouteName.MiddlewareAPI_Profiles_Attacks_Export
    )
  },

  [AppRouteName.MiddlewareIoAConfiguration_GenerateScript]: {
    routeName: AppRouteName.MiddlewareIoAConfiguration_GenerateScript,
    pathname: prefixWithIOAMiddleware(ServerRouteName.MiddlewareIoA_Script)
  },

  [AppRouteName.MiddlewareIoAConfiguration_IoAConfig]: {
    routeName: AppRouteName.MiddlewareIoAConfiguration_IoAConfig,
    pathname: prefixWithIOAMiddleware(
      ServerRouteName.MiddlewareIoA_Configuration
    )
  },

  [AppRouteName.MiddlewareTenableServicesProxy_ApiKeys]: {
    routeName: AppRouteName.MiddlewareTenableServicesProxy_ApiKeys,
    pathname: prefixWithTenableServicesProxyMiddleware(
      ServerRouteName.MiddlewareTenableServicesProxy_ApiKeys
    )
  },

  [AppRouteName.MiddlewarePDF]: {
    routeName: AppRouteName.MiddlewarePDF,
    pathname: new AppRouter(serverRoutes).getRoutePathname(
      ServerRouteName.MiddlewarePDF
    )
  },

  [AppRouteName.MiddlewareFeatureFlags]: {
    routeName: AppRouteName.MiddlewareFeatureFlags,
    pathname: new AppRouter(serverRoutes).getRoutePathname(
      ServerRouteName.MiddlewareFeatureToggles
    )
  }
}

// npm run show:routes:client
if (typeof require !== 'undefined' && require.main === module) {
  const appRouter = new AppRouter(appRoutes)
  appRouter.dumpRoutes()
}
