import { ContainerFooter } from '@app/components/Container'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { Modal, ModalCancelButton } from '@app/components-legacy/Modal'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onDeleteModalConfirmClick } from './handlers'

export interface IUserTracesConfirmDeleteModalProps {}

const UserTracesConfirmDeleteModal: React.FC<
  IUserTracesConfirmDeleteModalProps
> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Titles',
      'Buttons',
      'Management.System.Configuration.ActivityLogs'
    ]
  })

  const { storeActivityLogs, storeMessages } = useStores()

  return (
    <Modal
      modalProps={{
        title: translate('Confirmation'),
        footer: (
          <ContainerFooter variant="modal">
            <ModalCancelButton
              storeModal={storeActivityLogs.storeModalConfirmDeleteTraces}
            />

            <FormWrapperButton
              labelledBy="submit"
              size={ButtonSize.large}
              variant={ButtonVariant.primary}
              buttonProps={{
                onClick: onDeleteModalConfirmClick(
                  storeActivityLogs,
                  storeMessages
                )(translate)
              }}
            >
              {translate('Confirm')}
            </FormWrapperButton>
          </ContainerFooter>
        )
      }}
      storeModal={storeActivityLogs.storeModalConfirmDeleteTraces}
    >
      <p>{translate('Are you sure you want to clear all logs')}</p>
    </Modal>
  )
}

export default observer(UserTracesConfirmDeleteModal)
