import type { EntityTopologyInfrastructure } from '@app/entities'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onInfrastructureMouseOut, onInfrastructureMouseOver } from './handlers'
import InfrastructureLabel from './InfrastructureLabel'
import InfrastructureShape from './InfrastructureShape'
import type SceneObject from './SceneObject'

interface IInfrastructureProps {
  infrastructureSceneObject: SceneObject<EntityTopologyInfrastructure>
  children?: React.ReactNode
}

const Infrastructure: React.FC<IInfrastructureProps> = props => {
  const { storeTopology } = useStores()

  const { uid } = props.infrastructureSceneObject.object

  if (!uid) {
    return null
  }

  const isSearching = storeTopology.storeInputSearch.searchValue.length >= 3

  const isInfrastructureHighlighted =
    storeTopology.searchedInfrastructureUids.indexOf(uid) !== -1

  const isInfrastructureTransparent =
    isSearching && !isInfrastructureHighlighted

  return (
    <g
      data-name="Infrastructure"
      onMouseOver={onInfrastructureMouseOver(storeTopology)(uid)}
      onMouseOut={onInfrastructureMouseOut(storeTopology)}
    >
      <InfrastructureShape
        infrastructureSceneObject={props.infrastructureSceneObject}
        isHighlighted={isInfrastructureHighlighted}
        isTransparent={isInfrastructureTransparent}
      />

      <InfrastructureLabel
        infrastructureSceneObject={props.infrastructureSceneObject}
        isHighlighted={isInfrastructureHighlighted}
        isTransparent={isInfrastructureTransparent}
      />

      {props.children}
    </g>
  )
}

export default observer(Infrastructure)
