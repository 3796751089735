import type Leaf from './Leaf'
import type { IExpressionDefinition, ISerializable, NodeOrLeaf } from './types'
import { Combinator } from './types'

/**
 * Represent a node of a graph, that could have:
 * - one combinator (AND, OR...)
 * - leaf (key, value pair)
 * - children nodes
 *
 * Each node can be serialized to a string, allowing to construct the final query in the UI,
 * or can be converted to an IExpressionDefinition structure, used in Eridanis to describe filters.
 */
export default class Node implements ISerializable {
  private $combinator: Combinator = Combinator.AND
  private $values: NodeOrLeaf[] = []

  get combinator() {
    return this.$combinator
  }

  get values(): NodeOrLeaf[] {
    return this.$values
  }

  add(leafOrNode: NodeOrLeaf): this {
    this.$values.push(leafOrNode)
    return this
  }

  setCombinator(combinator: Combinator): this {
    this.$combinator = combinator
    return this
  }

  setValues(values: NodeOrLeaf[]): this {
    this.$values = values
    return this
  }

  /**
   * A node combinator has higher precedence if it combinator is AND and the other is not.
   */
  hasHigherPrecedenceThan(node: Node): boolean {
    return (
      this.$combinator === Combinator.AND &&
      node.isNode() &&
      node.combinator !== Combinator.AND
    )
  }

  hasSameCombinator(n: Node): boolean {
    return this.$combinator === n.combinator
  }

  isNode(): this is Node {
    return true
  }

  isLeaf(): this is Leaf {
    return false
  }

  /**
   * Convert this node to its Minimal string representation in term of
   * parenthesis and whitespaces.
   * For example : If a node were representing the expression ((A AND B)), then
   *               the toString() would have returned "A AND B"
   * Some examples :
   *   (A AND    A)   -> "A AND A"
   *   (A AND B) OR C -> "A AND B OR C"  // AND has higher priority, no need parents
   *
   *
   * @Note : The Minimisation is only in "term of parenthesis and whitespaces"
   *         meaning that (A AND A) will not become just A but will become
   *         "A AND A"
   */
  toString(): string {
    const str: string = this.$values
      .map(child =>
        child.isNode() && this.hasHigherPrecedenceThan(child)
          ? `(${child.toString()})`
          : child.toString()
      )
      .join(` ${this.$combinator} `)

    return str
  }

  /**
   * Convert the node to a valid JS expression.
   */
  toJs(): string {
    const op = this.$combinator === Combinator.AND ? '&&' : '||'
    const str: string = this.$values
      .map(child =>
        child.isNode() && this.hasHigherPrecedenceThan(child)
          ? `(${child.toJs()})`
          : child.toJs()
      )
      .join(` ${op} `)

    return str
  }

  /**
   * Convert the node to an IExpressionDefinition object.
   */
  toExpression(): IExpressionDefinition {
    if (this.values.length === 0) {
      return {}
    }

    return {
      [this.combinator]: this.values.map(child => {
        if (child.isLeaf()) {
          return child.expressionInner()
        }
        return (child as Node).toExpression()
      })
    }
  }
}
