import { newLogger } from '@libs/new-logger'

const logger = newLogger('common')('helpers')

/**
 * Pass anything to this function, it will return a stringified JSON object or specified fallback value
 * @example
 * safeStringify({ foo: 'bar' }) // '{"foo":"bar"}'
 * safeStringify({ foo: 'bar' }, false) // false
 * safeStringify({ foo: 'bar' }, null) // null
 */
export function safeStringify<T, K>(
  value: any,
  fallback: any = null,
  logError = false
): string | K | null {
  try {
    return JSON.stringify(value)
  } catch (e) {
    if (logError) {
      logger('debug')('JSON.parse error details: %O', e)
    }

    return fallback
  }
}
