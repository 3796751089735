import type { Maybe, MaybeUndef } from '@@types/helpers'
import {
  isExpressionContainsIsDeviantTrue,
  isUnsupportedByWizard
} from '@app/components-legacy/Input/InputExpression/Wizard/utils'
import type { StoreRoot } from '@app/stores'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreWizard from '@app/stores/helpers/StoreWizard'
import StoreBase from '@app/stores/StoreBase'
import { ExpressionParser } from '@libs/Expression'
import Expression from '@libs/Expression/Expression'
import { VALUE_MIN_LENGTH } from '@libs/Expression/ExpressionParser'
import type { NodeOrLeaf } from '@libs/Expression/types'
import { ExpressionParserError } from '@libs/Expression/types'
import { action, computed, makeObservable, observable } from 'mobx'
import type { IStoreInputExpressionOptions } from './types'

export default class StoreInputExpression extends StoreBase<IStoreInputExpressionOptions> {
  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Components.InputExpression, TrailFlow.Filters']
  })

  public storeDrawer = new StoreDrawer(this.storeRoot)
  public storeWizard = new StoreWizard(this.storeRoot)

  private _expressionParser = new ExpressionParser()
  private _hasExpressionAlreadyBeenDefined = this.expression.isDefined

  /* Observable */

  @observable
  private $inputValue = ''

  private $nodeOrLeaf = observable.box<MaybeUndef<NodeOrLeaf>>(undefined)

  private $inError = observable.box<boolean>(false)
  private $isDisabled = observable.box<boolean>(false)
  private $isFocus = observable.box<boolean>(false)

  constructor(
    storeRoot: StoreRoot,
    options: IStoreInputExpressionOptions = {}
  ) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /* Actions */

  @action
  reset(): this {
    this.clearEntry()

    this.storeDrawer.reset()
    this.storeWizard.reset()

    return this
  }

  @action
  setEntry(inputValue: MaybeUndef<string>): this {
    // could happen when initialized from a Expression object
    if (inputValue === undefined) {
      return this
    }

    // when reinitialize the input with an empty string, remove the potential
    // error state
    if (inputValue === '') {
      this.$inError.set(false)
    }

    const currentExpression = this.nodeOrLeaf
      ? this.nodeOrLeaf.toString()
      : undefined

    if (this.expression.isDefined) {
      this._hasExpressionAlreadyBeenDefined = true
    }

    const isExpressionCleared =
      inputValue === '' &&
      this.inputValue !== '' &&
      this._hasExpressionAlreadyBeenDefined

    if (isExpressionCleared) {
      this._hasExpressionAlreadyBeenDefined = false
    }

    // if an expression is defined, concat the input value to this expression
    // to be able to continue an existing expression
    if (currentExpression) {
      inputValue = currentExpression + ' ' + inputValue

      // remove the expression before the input
      this.setNodeOrLeaf(undefined)
    }

    // set the new input value
    this.$inputValue = inputValue

    return this
  }

  @action
  setDisabled(disabled: boolean): this {
    this.$isDisabled.set(disabled)
    return this
  }

  @action
  setFocus(focus: boolean): this {
    this.$isFocus.set(focus)
    return this
  }

  @action
  clearEntry(): this {
    this.$inputValue = ''
    this.setNodeOrLeaf(undefined)

    this.$inError.set(false)
    this.$isDisabled.set(false)

    return this
  }

  @action
  validateEntry(): boolean {
    // if there is nothing to validate
    if (!this.$inputValue) {
      return true
    }

    let nodeOrLeaf: MaybeUndef<NodeOrLeaf>
    let errorMessage: Maybe<string> = null

    const validate = () => {
      const inputValue = this.$inputValue.trim()

      // nothing to parse, OK
      if (!inputValue) {
        return true
      }

      nodeOrLeaf = this._expressionParser.parse(inputValue)

      // the parsing has failed, KO
      if (!nodeOrLeaf) {
        return false
      }

      const containsIsDeviant = isExpressionContainsIsDeviantTrue(
        nodeOrLeaf.toString()
      )

      // Only authorize isDeviant flag for power users if enabled in UI
      if (!this.options.enableIsDeviantSwitch && containsIsDeviant) {
        errorMessage = 'You cannot apply a deviant filter on this list'
        return false
      }

      // Only authorize supported format by wizard
      if (isUnsupportedByWizard(nodeOrLeaf.toString())) {
        errorMessage =
          'The wizard does not support the structure of the expression'
        return false
      }

      const parserError = this._expressionParser.getError()

      // the parsing has set errors
      if (parserError) {
        switch (parserError) {
          case ExpressionParserError.InvalidValue:
            errorMessage = `Search criteria must have at least ${VALUE_MIN_LENGTH} characters`
            break

          default:
            errorMessage = 'The search expression seems invalid'
            break
        }

        return false
      }

      // the parsing has failed, KO
      if (nodeOrLeaf && nodeOrLeaf.toString() === undefined) {
        return false
      }

      return true
    }

    const isValidated = validate()
    this.$inError.set(!isValidated)

    if (errorMessage) {
      const tErrorMessage = this.translate(errorMessage)
      this.storeRoot.stores.storeMessages.error(tErrorMessage, {
        labelledBy: 'inputExpressionError'
      })
    }

    // save the node and clean the input value
    if (isValidated) {
      this.setNodeOrLeaf(nodeOrLeaf)
      this.$inputValue = ''
    }

    return isValidated
  }

  /* Computed */

  @computed
  get inputValue(): string {
    return this.$inputValue
  }

  @computed
  get isInError(): boolean {
    return this.$inError.get()
  }

  @computed
  get isDisabled(): boolean {
    return this.$isDisabled.get()
  }

  @computed
  get isFocus(): boolean {
    return this.$isFocus.get()
  }

  private setNodeOrLeaf(nodeOrLeaf: MaybeUndef<NodeOrLeaf>) {
    this.$nodeOrLeaf.set(nodeOrLeaf)
  }

  @computed
  private get nodeOrLeaf(): MaybeUndef<NodeOrLeaf> {
    return this.$nodeOrLeaf?.get()
  }

  /**
   * Reinstanciate a new expression.
   */
  @computed
  get expression(): Expression {
    return new Expression().setNode(this.nodeOrLeaf)
  }

  /**
   * Reinstanciate a new expression.
   */
  @computed
  get inputValueAsRegExp(): RegExp {
    try {
      return new RegExp(this.inputValue.trim() || '', 'i')
    } catch (err) {
      this.storeRoot.logger.warn('Error when creating the expression regexp')
      return new RegExp('')
    }
  }
}
