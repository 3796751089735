import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlexVertical } from '../Container'
import { FormWrapperCheckbox } from '../Form'

interface ICardCheckboxProps {
  className?: string
  checked?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const CardCheckbox: React.FC<ICardCheckboxProps> = props => {
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()

    if (props.onClick) {
      props.onClick(e)
    }
  }

  return (
    <ContainerFlexVertical
      className={props.className}
      onClick={onClick}
      items={[
        <FormWrapperCheckbox
          labelledBy="selectCard"
          checkboxProps={{ checked: props.checked ?? false }}
        />
      ]}
    />
  )
}

export default styled(CardCheckbox)`
  background-color: ${props =>
    props.checked ? consts.colorBlue002 : consts.colorBlueGray030};
  width: 50px;
  text-align: center;
`
