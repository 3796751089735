import type EntityAttack from '@app/entities/EntityAttack'
import type StoreAttacks from '@app/stores/IoA/StoreAttacks'

/**
 * When clicking on an attack link, prevent details from opening.
 */
export const onAttackLinkClick = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => {
  // stop propagation to avoid to open the attack details
  e.stopPropagation()
}

/**
 * Set data in the drawer and open it.
 */
export const onAttackShowAttackDetailsClick =
  (storeAttacks: StoreAttacks) => (attackVectorDescription: string) => () => {
    storeAttacks.storeDrawerAttackDetails
      .setData({ attackVectorDescription })
      .openDrawer()
  }

/**
 * Expand the row when clicking on the Expand button.
 */
export const onAttackExpandButtonClick =
  (storeAttacks: StoreAttacks) =>
  (attackEntity: EntityAttack) =>
  (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (event) {
      // needed to not select/unselect at the same time since a click on the entire
      // row show details too
      event.stopPropagation()
    }

    storeAttacks.storeWidgetListAttacks.toggleToggleableRow(attackEntity)
  }

/**
 * Select the attack.
 */
export const onAttackCheckboxClick =
  (storeAttacks: StoreAttacks) => (attackEntity: EntityAttack) => () => {
    storeAttacks.storeWidgetListAttacks.toggleRowSelection(attackEntity)
  }
