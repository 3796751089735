import type { TranslateFn } from '@libs/i18n'

export function getConfigurationMenuKey(index: number) {
  return `Configuration ${index}`
}

export function getConfigurationMenuTranslation(
  translate: TranslateFn,
  index: number
) {
  return index === 0
    ? translate('Global configuration')
    : translate('Configuration set number X', {
        interpolations: { count: index }
      })
}
