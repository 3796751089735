import type { Maybe } from '@@types/helpers'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  readUIIoEDeviances,
  readUIIoEDeviancesExport,
  readUIIoEDeviancesSetIgnoreDate,
  readUIIoEInformations,
  readUIIoERecommandations,
  readUIIoEVulnerabilityDetails
} from '@libs/rbac/permissions'
import { canAccessToAdIoEDetails } from '../IoEBoardPage/permissions'

export const canReadIoEInformation = (checkerId: number) =>
  rbacCapabilityCheckAllOf(
    canAccessToAdIoEDetails(checkerId),
    readUIIoEInformations()
  )

export const canReadIoEVulnerabilityDetails = (checkerId: number) =>
  rbacCapabilityCheckAllOf(
    canAccessToAdIoEDetails(checkerId),
    readUIIoEVulnerabilityDetails()
  )

export const canReadIoERecommandations = (checkerId: number) =>
  rbacCapabilityCheckAllOf(
    canAccessToAdIoEDetails(checkerId),
    readUIIoERecommandations()
  )

export const canAccessToIoEDeviantObjects = (checkerId: Maybe<number>) =>
  rbacCapabilityCheckAllOf(
    canAccessToAdIoEDetails(checkerId),
    readUIIoEDeviances()
  )

export const canReadIoEComplianceStatus = readUIIoEDeviances()

export const canSetIgnoreDateOnDeviantObjects =
  readUIIoEDeviancesSetIgnoreDate()

export const canExportDeviantObjects = readUIIoEDeviancesExport()
