import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import type { IEntityListable } from '@app/entities/types'
import type {
  IDataRowGeneric,
  IWidgetListColumns
} from '@app/stores/helpers/StoreWidgetList/types'
import type {
  AttackPathAdObjectType,
  AttackPathTier0Object,
  Maybe
} from '@server/graphql/typeDefs/types'

export default class EntityAttackPathTier0Object
  extends EntityBase
  implements
    PropertiesNullable<AttackPathTier0Object>,
    IEntityListable<IDataRowGeneric>
{
  id: Maybe<number> = null
  name: Maybe<string> = null
  adObjectType: Maybe<AttackPathAdObjectType> = null
  directoryId: Maybe<number> = null
  accountTotal: Maybe<number> = null
  accountWithTier0Access: Maybe<number> = null

  constructor(data: Partial<EntityAttackPathTier0Object>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Not used, columns are hardcoded in the view with a custom render.
   */
  getColumns(): Array<IWidgetListColumns<IDataRowGeneric>> {
    return []
  }
}
