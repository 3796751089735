import type { PropertiesNullable } from '@@types/helpers'
import type { ValueType } from '@libs/valueTypeParser/types'
import type {
  AttackType,
  AttackTypeOption,
  Maybe
} from '@server/graphql/typeDefs/types'
import { CheckerType } from '@server/graphql/typeDefs/types'
import EntityBase from '../EntityBase'
import type { CheckerOptionCodename } from './types'

export default class EntityAttackTypeOption
  extends EntityBase
  implements PropertiesNullable<AttackTypeOption>
{
  id: Maybe<number> = null
  codename: Maybe<CheckerOptionCodename> = null
  name: Maybe<string> = null
  description: Maybe<string> = null
  value: Maybe<string> = null
  valueType: Maybe<ValueType> = null
  translations: Maybe<string[]> = null
  profileId: Maybe<number> = null
  attackTypeId: Maybe<number> = null
  directoryId: Maybe<number> = null
  staged: Maybe<boolean> = null

  // related attack type
  attackType: Maybe<AttackType> = null

  checkerType = CheckerType.Attack

  constructor(data: Partial<EntityAttackTypeOption>) {
    super()
    Object.assign(this, data)
  }

  getValue(): string {
    if (!this.value) {
      return ''
    }

    try {
      return String(JSON.parse(this.value))
    } catch (err) {
      return this.value || ''
    }
  }

  /**
   * Remove the stage status of this option.
   * Useful when cloning option for a new refine configuration.
   */
  unstage(): this {
    this.staged = false
    return this
  }
}
