import {
  createEntities,
  createEntity,
  EntityEmail,
  EntityPagination
} from '@app/entities'
import type { IDataRowEmail } from '@app/entities/EntityEmail'
import { EmailFormFieldName } from '@app/pages/Management/SystemPage/ConfigurationPage/ConfigurationEmailsPage/types'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import {
  emailFormat,
  mandatory
} from '@app/stores/helpers/StoreForm/validators'
import { CRITICITY_LEVEL_LOW } from '@libs/criticity'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefinedAndNotEmptyString } from '@libs/isDefined'
import { checkRbac } from '@libs/rbac/functions'
import type {
  MutationDeleteEmail,
  MutationTestExistingEmail,
  MutationTestNewEmail
} from '@server/graphql/mutations/email'
import {
  mutationCreateEmail,
  mutationDeleteEmail,
  mutationEditEmail,
  mutationTestExistingEmail,
  mutationTestNewEmail
} from '@server/graphql/mutations/email'
import type { QueryEmails } from '@server/graphql/queries/notification'
import { queryEmails } from '@server/graphql/queries/notification'
import type {
  CreateEmailMutationArgs,
  DeleteEmailMutationArgs,
  EditEmailMutationArgs,
  Email,
  InputCreateEmail,
  InputEditEmail,
  InputTestNewEmail,
  Pagination,
  RbacEmailsQueryArgs,
  TestExistingEmailMutationArgs,
  TestNewEmailMutationArgs
} from '@server/graphql/typeDefs/types'
import { CheckerType, EmailInputType } from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import type { StoreRoot } from '..'
import { StoreInputHealthChecks } from '..'
import StoreDrawer from '../helpers/StoreDrawer'
import StoreFlags from '../helpers/StoreFlags'
import StoreForm from '../helpers/StoreForm'
import StoreInputGenericCheckers from '../helpers/StoreInputGenericCheckers'
import type {
  ICheckerAttack,
  ICheckerExposure
} from '../helpers/StoreInputGenericCheckers/types'
import StoreWidgetList from '../helpers/StoreWidgetList'
import StoreBase from '../StoreBase'
import StoreInfrastructures from '../StoreInfrastructures'
import type { IStoreOptions } from '../types'

export default class StoreEmails extends StoreBase {
  public storeInfrastructures = new StoreInfrastructures(this.storeRoot)

  public storeFlags = new StoreFlags(this.storeRoot)
  public storeTestConnectivityFlags = new StoreFlags(this.storeRoot)
  public storeSubmitFlags = new StoreFlags(this.storeRoot)
  public storeDeletionFlags = new StoreFlags(this.storeRoot)

  public storeForm = new StoreForm<EmailFormFieldName>(this.storeRoot, {
    setup: {
      fields: {
        [EmailFormFieldName.id]: {
          label: 'Id'
        },
        [EmailFormFieldName.address]: {
          label: 'Email address',
          validators: [mandatory(), emailFormat()]
        },
        [EmailFormFieldName.description]: {
          label: 'Description'
        },
        [EmailFormFieldName.profiles]: {
          label: 'Profile',
          validators: [
            mandatory({
              isValid: value => {
                const inputType =
                  this.storeForm.getFieldValueAsString<EmailInputType>(
                    EmailFormFieldName.inputType
                  )

                // don't check for attacks
                if (inputType === EmailInputType.Attacks) {
                  return true
                }

                // don't check for health checks
                if (inputType === EmailInputType.HealthChecks) {
                  return true
                }

                return isDefinedAndNotEmptyString(value)
              }
            })
          ]
        },
        [EmailFormFieldName.shouldNotifyOnInitialFullSecurityCheck]: {
          label:
            'Send alerts when deviances are detected during the initial analysis phase',
          inputType: InputType.checkbox
        },
        [EmailFormFieldName.inputType]: {
          label: 'Filter trigger'
        },
        [EmailFormFieldName.criticityThreshold]: {
          label: 'Criticity threshold',
          description:
            'Threshold of severity from which the alerts of the indicators will be sent',
          validators: [mandatory()],
          inputType: InputType.select
        },
        [EmailFormFieldName.checkers]: {
          label: 'Indicators of Exposure',
          validators: [
            mandatory({
              isValid: () => {
                const inputType =
                  this.storeForm.getFieldValueAsString<EmailInputType>(
                    EmailFormFieldName.inputType
                  )

                // don't check this field if it's for attacks
                if (inputType === EmailInputType.Attacks) {
                  return true
                }

                // don't check for health checks
                if (inputType === EmailInputType.HealthChecks) {
                  return true
                }

                return this.storeInputCheckersExposure.hasSelectedCheckers
              },
              onError: () =>
                this.translate('You have to select at least one indicator')
            })
          ]
        },
        [EmailFormFieldName.attackTypes]: {
          label: 'Indicators of Attack',
          validators: [
            mandatory({
              isValid: () => {
                const inputType =
                  this.storeForm.getFieldValueAsString<EmailInputType>(
                    EmailFormFieldName.inputType
                  )

                // don't check this field if it's for deviances
                if (inputType === EmailInputType.Deviances) {
                  return true
                }

                // don't check for health checks
                if (inputType === EmailInputType.HealthChecks) {
                  return true
                }

                return this.storeInputCheckersAttacks.hasSelectedCheckers
              },
              onError: () =>
                this.translate('You have to select at least one indicator')
            })
          ]
        },
        [EmailFormFieldName.directories]: {
          label: 'Directories'
        },
        [EmailFormFieldName.healthCheckNames]: {
          label: 'Health checks',
          validators: [
            mandatory({
              isValid: value => {
                const inputType =
                  this.storeForm.getFieldValueAsString<EmailInputType>(
                    EmailFormFieldName.inputType
                  )

                // don't check this field if it's for attacks
                if (inputType === EmailInputType.Attacks) {
                  return true
                }

                // don't check for deviances
                if (inputType === EmailInputType.Deviances) {
                  return true
                }

                return isDefinedAndNotEmptyString(value)
              },
              onError: () => {
                return this.translate(
                  'You must select at least one health check'
                )
              }
            })
          ]
        }
      }
    }
  })

  public storeInputCheckersExposure =
    new StoreInputGenericCheckers<ICheckerExposure>(this.storeRoot, {
      checkerType: CheckerType.Exposure,
      selectable: true
    })

  public storeInputCheckersAttacks =
    new StoreInputGenericCheckers<ICheckerAttack>(this.storeRoot, {
      checkerType: CheckerType.Attack,
      selectable: true
    })

  public storeDeleteDrawer = new StoreDrawer<{ emailDataRow: IDataRowEmail }>(
    this.storeRoot
  )
  public storeWidgetList = new StoreWidgetList<EntityEmail, IDataRowEmail>(
    this.storeRoot,
    {
      selectable: false
    }
  )

  public storeInputHealthChecks = new StoreInputHealthChecks(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: [
      'Errors',
      'Management.System.Configuration.EmailAlerts',
      'Components.InputHealthChecks'
    ]
  })

  // keys are email id
  private $emails = observable.map<number, EntityEmail>()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  fetchEmails(_args?: RbacEmailsQueryArgs): Promise<void> {
    const args: RbacEmailsQueryArgs = {
      emailsPage: this.storeWidgetList.paginationPage,
      emailsPerPage: this.storeWidgetList.rowsPerPage,
      ..._args
    }

    this.storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryEmails>(queryEmails, args)
      })
      .then(data => data.rbacEmails)
      .then(emails => {
        if (!checkRbac(this.storeRoot, this.storeFlags)(emails)) {
          throw new ForbiddenAccessError()
        }

        const emailsEntities = createEntities<Email, EntityEmail>(
          EntityEmail,
          emails.node.node
        )

        const emailsPagination = createEntity<Pagination, EntityPagination>(
          EntityPagination,
          emails.node.pagination
        )

        this.setEmails(emailsEntities)

        this.storeWidgetList.setEntities(emailsEntities)
        this.storeWidgetList.setPagination(emailsPagination)

        this.storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlags))
  }

  /**
   * Create a email alert.
   */
  createEmail(email: InputCreateEmail) {
    this.storeSubmitFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: CreateEmailMutationArgs = {
          email
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<QueryEmails>(mutationCreateEmail, args)
      })
      .then(() => this.fetchEmails())
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Email alert created'),
          {
            labelledBy: 'emailAlertCreated'
          }
        )

        this.storeSubmitFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeSubmitFlags, {
          forwardExceptionFn: () => {
            return 'An error has occurred when creating the email alert'
          }
        })
      )
  }

  /**
   * Edit an email.
   */
  editEmail(email: InputEditEmail) {
    this.storeSubmitFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: EditEmailMutationArgs = {
          email
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<QueryEmails>(mutationEditEmail, args)
      })
      .then(() => {
        // reload emails (and remake the mapping between infras and dirs)
        return this.fetchEmails()
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Email alert updated'),
          {
            labelledBy: 'emailAlertUpdated'
          }
        )

        this.storeSubmitFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeSubmitFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when editing the email alert'
        })
      )
  }

  /**
   * Delete a email.
   */
  deleteEmail(emailId: number) {
    this.storeDeletionFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: DeleteEmailMutationArgs = {
          email: {
            id: emailId
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationDeleteEmail>(mutationDeleteEmail, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Email alert deleted'),
          {
            labelledBy: 'emailAlertDeleted'
          }
        )

        this.storeDeletionFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeDeletionFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when deleting the email'
        })
      )
  }

  /**
   * Test the connectivity of a new Email alert.
   */
  testNewEmail(_args: InputTestNewEmail): Promise<void> {
    this.storeTestConnectivityFlags.loading()

    const args: TestNewEmailMutationArgs = {
      email: _args
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<MutationTestNewEmail>(mutationTestNewEmail, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('An Email alert has been sent to the server'),
          {
            labelledBy: 'newEmailAlertSent'
          }
        )

        this.storeTestConnectivityFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeTestConnectivityFlags))
  }

  /**
   * Test the connectivity of an existing Email alert.
   */
  testExistingEmail(emailId: number): Promise<void> {
    this.storeTestConnectivityFlags.loading()

    const args: TestExistingEmailMutationArgs = {
      email: {
        id: emailId
      }
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<MutationTestExistingEmail>(mutationTestExistingEmail, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('An Email alert has been sent to the server'),
          {
            labelledBy: 'existingEmailAlertSent'
          }
        )

        this.storeTestConnectivityFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeTestConnectivityFlags))
  }

  getArgsFromStores(): InputTestNewEmail {
    const inputType = this.storeForm.getFieldValueAsString<EmailInputType>(
      EmailFormFieldName.inputType
    )

    const args: InputTestNewEmail = {
      address: this.storeForm.getFieldValueAsString(EmailFormFieldName.address),

      description: this.storeForm.getFieldValueAsString(
        EmailFormFieldName.description
      ),

      // false if Health check context
      shouldNotifyOnInitialFullSecurityCheck:
        inputType === EmailInputType.HealthChecks
          ? false
          : this.storeForm.getFieldValueAsBoolean(
              EmailFormFieldName.shouldNotifyOnInitialFullSecurityCheck
            ),

      // no profile if Health check context
      profiles:
        inputType === EmailInputType.HealthChecks
          ? null
          : this.storeForm
              .getFieldValueAsString(EmailFormFieldName.profiles)
              .split(',')
              .map(id => Number(id)),

      inputType: this.storeForm.getFieldValueAsString(
        EmailFormFieldName.inputType
      ),

      // Low if Health check context
      criticityThreshold:
        inputType === EmailInputType.HealthChecks
          ? CRITICITY_LEVEL_LOW
          : this.storeForm.getFieldValueAsNumber(
              EmailFormFieldName.criticityThreshold,
              CRITICITY_LEVEL_LOW
            ),

      // no checker if IoA or Health check context
      checkers:
        inputType === EmailInputType.Attacks ||
        inputType === EmailInputType.HealthChecks
          ? null
          : this.storeInputCheckersExposure.selectedCheckerIds,

      // no attackTypes if IoE or Health check context
      attackTypes:
        inputType === EmailInputType.Attacks ||
        inputType === EmailInputType.HealthChecks
          ? this.storeInputCheckersAttacks.selectedCheckerIds
          : null,

      directories: this.storeForm
        .getFieldValueAsString(EmailFormFieldName.directories)
        .split(',')
        .map(id => Number(id)),

      healthCheckNames: this.storeForm
        .getFieldValueAsString(EmailFormFieldName.healthCheckNames)
        .split(',')
        .map(id => String(id))
    }

    return args
  }

  /* Actions */

  @action
  reset(): this {
    this.storeForm.reset()

    this.storeInputCheckersExposure.reset()
    this.storeInputCheckersAttacks.reset()
    this.storeInputHealthChecks.reset()

    return this
  }

  @action
  setEmails(emails: EntityEmail[]) {
    this.$emails.clear()

    emails.forEach(entity => {
      if (entity.id) {
        this.$emails.set(entity.id, entity)
      }
    })
  }

  /* Computed */

  @computed
  get emails(): Map<number, EntityEmail> {
    return toJS(this.$emails)
  }
}
