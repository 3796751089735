import type { Maybe } from '@@types/helpers'
import type { EntityProductAssociation } from '@app/entities'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import { deny, grant } from '@libs/rbac/permissions'
import { canAccessToIdentityExplorer } from '../permissions'

export const canSeeIdentityExposureScore = (
  productAssociation: Maybe<EntityProductAssociation>
) => {
  return rbacCapabilityCheckAllOf(
    canAccessToIdentityExplorer,
    productAssociation?.isAssociatedToTenableOne() ? grant() : deny()
  )
}

export const canExportIdentities = rbacCapabilityCheckAllOf(
  canAccessToIdentityExplorer
)
