import { ErrorMessage } from '@app/components/Error'
import SpinnerInline, {
  SpinnerInlineSize
} from '@app/components-legacy/Spinner/SpinnerInline'
import type StoreInputReasons from '@app/stores/StoreInputReasons'
import type { BaseButtonProps } from 'antd/lib/button/button'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import InputReasonsButton from './InputReasonsButton'

interface IInputReasonsProps {
  className?: string
  style?: React.CSSProperties
  size?: BaseButtonProps['size']
  onValidate?: () => void
  loadAtMount?: boolean
  storeInputReasons: StoreInputReasons
}

const InputReasons: React.FC<IInputReasonsProps> = props => {
  React.useEffect(() => {
    if (props.loadAtMount) {
      props.storeInputReasons.fetchReasons()
    }
  }, [])

  if (props.storeInputReasons.storeFlagsFetchReasons.isLoading) {
    return <SpinnerInline size={SpinnerInlineSize.small} />
  }

  if (props.storeInputReasons.storeFlagsFetchReasons.isError) {
    return <ErrorMessage />
  }

  return (
    <InputReasonsButton
      size={props.size}
      onValidate={props.onValidate}
      storeInputReasons={props.storeInputReasons}
    />
  )
}

export default observer(InputReasons)
