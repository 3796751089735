import { Blade } from '@app/components-legacy/Blade'
import { useHistoryChange } from '@app/components-legacy/Router/hooks'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks/useStores'
import IoECheckerIdentityDetailsPage from '@app/pages/IoE/IoECheckerIdentityDetailsPage'
import type { IIoEDetailsRouteDefinition } from '@app/routes'
import { AppRouteName } from '@app/routes'
import { mergeStoreFlags } from '@app/stores/helpers/StoreFlags/helpers'
import type { IFlags } from '@app/stores/helpers/StoreFlags/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import IoECheckerDetailsPage from '../IoECheckerDetailsPage'
import { handleIoEBoardPageOnLoad } from './handlers'
import IoEBoardContent from './IoEBoardContent'
import IoEBoardMenu from './IoEBoardMenu'
import {
  canAccessToAdIoEDetails,
  canAccessToIdentityIoEDetails,
  canAccessToIoE
} from './permissions'

interface IIoEBoardBladeProps {}

const IoEBoardBlade: React.FC<IIoEBoardBladeProps> = () => {
  const { storeRbac, storeInfrastructures, storeIoE } = useStores()

  // Observe history to trigger the useEffect and rerender the checkers when clicking on the main navigation
  const { currentRouteInfo } = useHistoryChange()
  const currentRouteName = currentRouteInfo?.routeName

  // Load data when the history changes
  React.useEffect(() => {
    if (!currentRouteName) {
      return
    }

    handleIoEBoardPageOnLoad(storeIoE)(currentRouteName)()
  }, [currentRouteName])

  /**
   * Return flags according to what is loaded.
   */
  function getFlags(): IFlags[] {
    if (!currentRouteName) {
      return []
    }

    const flags: IFlags[] = []

    if (
      [AppRouteName.IoE_Board, AppRouteName.IoE_Board_AD].includes(
        currentRouteName
      )
    ) {
      // Checkers Exposure flags
      flags.push(
        storeInfrastructures.storeFlagsFetchInfrastructures.flags,
        storeIoE.storeFlagsFetchCheckersExposure.flags
      )
    }

    // If All or EntraID indicators
    if (
      [AppRouteName.IoE_Board, AppRouteName.IoE_Board_MEID].includes(
        currentRouteName
      )
    ) {
      flags.push(
        mergeStoreFlags(
          [
            storeIoE.storeInputTenants.storeFlagsFetchTenants,
            storeIoE.storeFlagsFetchCheckersIdentity
          ],
          {
            // specific error message if something is wrong for identities checkers
            isError: false
          }
        )
      )
    }

    return flags
  }

  return (
    <>
      <Blade
        root={true}
        routeDefinition={{
          routeName: AppRouteName.IoE_Board,
          parameters: {}
        }}
        flags={getFlags()}
        layout={{
          name: 'default',
          menu: <IoEBoardMenu />,
          content: <IoEBoardContent />
        }}
        rbacCapabilityCheck={canAccessToIoE}
      />

      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName: AppRouteName.IoE_Details,
              parameters: {
                checkerId: Number(),
                checkerCodename: String()
              }
            },
            component: IoECheckerDetailsPage,
            rbacPermissionsCheck: (
              params: IIoEDetailsRouteDefinition['parameters']
            ) => canAccessToAdIoEDetails(params.checkerId)
          },
          {
            routeDefinition: {
              routeName: AppRouteName.IoE_Identity_Details,
              parameters: {
                checkerId: String(),
                checkerCodename: String()
              }
            },
            component: IoECheckerIdentityDetailsPage,
            rbacPermissionsCheck: () => canAccessToIdentityIoEDetails
          }
        ]}
      />
    </>
  )
}

export default observer(IoEBoardBlade)
