import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import { DrawerHorizontalContainer } from '@app/components-legacy/Drawer'
import DrawerTitle from '@app/components-legacy/Drawer/DrawerTitle'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { grant } from '@libs/rbac/permissions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import IoABoardCardForm from './IoABoardCardForm'

interface IIoABoardCardDrawerProps {}

const IoABoardCardDrawer: React.FC<IIoABoardCardDrawerProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['IoA.Board']
  })

  return (
    <DrawerHorizontalContainer>
      <BladeContentLayoutDefault
        layout={{
          name: 'default',
          title: <DrawerTitle>{translate('Edit card')}</DrawerTitle>,
          content: <IoABoardCardForm />,
          footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
        }}
        // no specific permissions needed for now
        rbacCapabilityCheck={grant()}
      />
    </DrawerHorizontalContainer>
  )
}

export default observer(IoABoardCardDrawer)
