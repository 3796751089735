import IconDown from '@app/components/Icon/IconDown'
import { LabelSliced } from '@app/components/Labels'
import { CheckboxTree } from '@app/components-legacy/Checkbox'
import {
  handleDirectoryOnSelect,
  handleInfrastructureExpandOnClick,
  handleInfrastructureOnSelect
} from '@app/components-legacy/Input/InputInfrastructures/handlers'
import StyledTree from '@app/components-legacy/Input/StyledTree'
import { LabelSliced as LabelSlicedDeprecated } from '@app/components-legacy/Label'
import type { EntityInfrastructure } from '@app/entities'
import type StoreInfrastructures from '@app/stores/StoreInfrastructures'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { isDefined } from '@libs/isDefined'
import type { TreeProps } from 'antd/lib/tree'
import Tree from 'antd/lib/tree'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

const StyledIconDown = styled(IconDown)`
  .ant-tree .ant-tree-switcher_close &.ant-tree-switcher-icon {
    transform: rotate(90deg);
  }
`

export interface IInfrastructuresTreeProps {
  className?: string
  style?: React.CSSProperties
  infrastructures: EntityInfrastructure[]
  storeInfrastructures: StoreInfrastructures
  labelledBy?: string
}

const InfrastructuresTree: React.FC<IInfrastructuresTreeProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const LocalLabelSliced = themeIsLegacy ? LabelSlicedDeprecated : LabelSliced

  /**
   * Return the props of the Tree components.
   */
  const getTreeProps = (): TreeProps => {
    const treeProps: TreeProps = {}

    treeProps.expandedKeys = props.infrastructures
      .map(infra => {
        const isInfrastructureExpanded =
          props.storeInfrastructures.expandedInfrastructures.has(
            infra.getPropertyAsNumber('id')
          )
        return isInfrastructureExpanded ? infra.getPropertyAsString('id') : null
      })
      .filter(isDefined)

    treeProps.treeData = props.infrastructures.map(infra => ({
      title: (
        <span
          onClick={handleInfrastructureOnSelect(props.storeInfrastructures)(
            infra.getPropertyAsNumber('id')
          )}
        >
          <LocalLabelSliced
            maxLength={50}
            value={infra.getPropertyAsString('name')}
            labelledBy={`${
              props.labelledBy ? `${props.labelledBy}/` : ''
            }forest-filter-${infra.getPropertyAsString('name')}`}
          />
        </span>
      ),
      key: `${infra.getPropertyAsNumber('id')}`,
      icon: (
        <CheckboxTree
          onClick={handleInfrastructureOnSelect(props.storeInfrastructures)(
            infra.getPropertyAsNumber('id')
          )}
          checked={
            props.storeInfrastructures.isInfrastructureSelected(
              infra.getPropertyAsNumber('id')
            ) || false
          }
          indeterminate={
            props.storeInfrastructures.isInfrastructureSelected(
              infra.getPropertyAsNumber('id')
            ) === null
          }
          color={infra.color}
        />
      ),
      children: infra
        .getDirectories()
        .filter(directoryEntity => {
          const regexp =
            props.storeInfrastructures.storeInputSearch
              .transformedSearchValueAsRegexp

          return (
            regexp.test(infra.getPropertyAsString('name')) ||
            regexp.test(directoryEntity.getPropertyAsString('name'))
          )
        })
        .map(directoryEntity => {
          const directoryKey = props.storeInfrastructures.buildTreeKey(
            infra.getPropertyAsNumber('id'),
            directoryEntity.getPropertyAsNumber('id')
          )
          return {
            title: (
              <span
                onClick={handleDirectoryOnSelect(props.storeInfrastructures)(
                  directoryEntity.getPropertyAsNumber('id')
                )}
              >
                <LabelSliced
                  maxLength={50}
                  value={`${directoryEntity.name}`}
                  labelledBy={`${
                    props.labelledBy ? `${props.labelledBy}/` : ''
                  }domain-filter-${directoryEntity.name}`}
                />
              </span>
            ),
            key: directoryKey,
            icon: (
              <CheckboxTree
                onClick={handleDirectoryOnSelect(props.storeInfrastructures)(
                  directoryEntity.getPropertyAsNumber('id')
                )}
                checked={
                  props.storeInfrastructures.selectedDirectoriesMap.get(
                    directoryKey
                  ) || false
                }
                color={infra.color}
              />
            )
          }
        })
    }))

    return treeProps
  }

  const LocalTree = themeIsLegacy ? Tree : StyledTree

  return (
    <LocalTree
      onExpand={handleInfrastructureExpandOnClick(props.storeInfrastructures)}
      showIcon
      {...(!themeIsLegacy && { switcherIcon: <StyledIconDown /> })}
      {...getTreeProps()}
    />
  )
}

export default observer(InfrastructuresTree)
