import { ContainerFlex, ContainerRbac } from '@app/components-legacy/Container'
import type EntityAttack from '@app/entities/EntityAttack'
import { canAccessToAttackDetails } from '@app/pages/IoA/IoAAttacksPage/permissions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { AttacksPageContext } from '../context'
import IoAAttacksTableBodyRowCardContent from './IoAAttacksTableBodyRowCardContent'
import IoAAttacksTableRowDetails from './IoAAttacksTableRowDetails'
import { setTableColumnsStyled } from './styles'

interface IIoAAttacksTableBodyRowCardContainerProps {
  className?: string
  style?: React.CSSProperties
  attackEntity: EntityAttack
  expanded: boolean
}

const IoAAttacksTableBodyRowCardContainer: React.FC<
  IIoAAttacksTableBodyRowCardContainerProps
> = props => {
  const attacksPageContext = React.useContext(AttacksPageContext)

  if (!attacksPageContext) {
    return null
  }

  const expanded =
    attacksPageContext.storeAttacks.storeWidgetListAttacks.isToggleableRowOpened(
      props.attackEntity
    )

  const closed = props.attackEntity.getPropertyAsBoolean('isClosed')

  return (
    <ContainerFlex
      name="IoAAttacksTableBodyRowCardContainer"
      className={props.className}
      style={props.style}
      direction="column"
      items={[
        <IoAAttacksTableBodyRowCardContent
          attackEntity={props.attackEntity}
          expanded={expanded}
          closed={closed}
        />,

        expanded && (
          <ContainerRbac rbacCapabilityCheck={canAccessToAttackDetails}>
            <IoAAttacksTableRowDetails attackEntity={props.attackEntity} />
          </ContainerRbac>
        )
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

const ObservedIoAAttacksTableBodyRowCardContainer = observer(
  IoAAttacksTableBodyRowCardContainer
)

export default setTableColumnsStyled(
  ObservedIoAAttacksTableBodyRowCardContainer,
  { header: false }
)
