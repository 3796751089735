import type { WSEntityName } from '@server/routers/WSProxyRouter/types'

export enum WSFeatureFlagPayloadMessages {
  server_up = 'server up',
  update_received = 'update received',
  restarting_soon = 'restarting soon',
  restart = 'restart'
}

export interface IWSFeatureFlagRegistration {
  name: WSEntityName.featureFlags
  payload: {}
}

export interface IWSFeatureFlagMessage {
  name: WSEntityName.featureFlags
  payload: {
    message: WSFeatureFlagPayloadMessages
    timeoutValue?: number
  }
}
