import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'

export const onWidgetListSelectAllCheckboxChange =
  (
    storeWidgetList: StoreWidgetList<any, any>,
    showPopover: (state: boolean) => void,
    disableSelectAllPages?: boolean
  ) =>
  (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      showPopover(false)
      return storeWidgetList.selectCurrentPageRows()
    }

    if (
      !disableSelectAllPages &&
      storeWidgetList.isPageRowsSelected &&
      !storeWidgetList.isAllRowsSelected
    ) {
      showPopover(false)
      return storeWidgetList.selectAllRows()
    }

    showPopover(false)
    return storeWidgetList.unselectAllRows()
  }

export const onWidgetListSelectAllLabelClick =
  (storeWidgetList: StoreWidgetList<any, any>) => () => {
    if (storeWidgetList.isAllRowsSelected) {
      storeWidgetList.unselectAllRows()
      return
    }

    if (storeWidgetList.isPageRowsSelected) {
      storeWidgetList.selectAllRows()
      return
    }

    return storeWidgetList.selectCurrentPageRows()
  }

export const onWidgetListSelectAllSelectCurrentPageClick =
  (
    storeWidgetList: StoreWidgetList<any, any>,
    showPopover: (state: boolean) => void
  ) =>
  () => {
    showPopover(false)
    storeWidgetList.selectCurrentPageRows()
  }

export const onWidgetListSelectAllSelectAllPageClick =
  (
    storeWidgetList: StoreWidgetList<any, any>,
    showPopover: (state: boolean) => void
  ) =>
  () => {
    showPopover(false)
    storeWidgetList.selectAllRows()
  }

export const onWidgetListSelectAllUnselectAllClick =
  (
    storeWidgetList: StoreWidgetList<any, any>,
    showPopover: (state: boolean) => void
  ) =>
  () => {
    showPopover(false)
    storeWidgetList.unselectAllRows()
  }
