import { sendPageToPDFMiddleware } from '@app/components-legacy/HeadBar/helpers'
import { AppRouteName } from '@app/routes'
import type StoreBoard from '@app/stores/IoA/StoreBoard'
import { filterFalsies } from '@libs/filterFalsies'
import type { TranslateFn } from '@libs/i18n'
import { sanitizeFilename } from '@libs/sanitize'
import type { ISubmitFormParameters } from '@libs/submitForm'
import { submitForm } from '@libs/submitForm'
import type { IExportParametersIoACards } from '@server/routers/exportCSVRouter/types'
import * as moment from 'moment-timezone'
import { exportToPPTX } from '../ExportPPTX'
import { ExportFormat, ExportFormFieldName } from '../types'

/**
 * Export some pages to PDF.
 */
export const handleExportIoABoardOnSubmit =
  (translate: TranslateFn) =>
  (storeBoard: StoreBoard) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    if (!storeBoard.storeFormExport.validate()) {
      return
    }

    const exportFormat =
      storeBoard.storeFormExport.getFieldValueAsString<ExportFormat>(
        ExportFormFieldName.exportFormat
      )

    const useUtc = storeBoard.storeFormExport.getFieldValueAsBoolean(
      ExportFormFieldName.exportUtc
    )
    storeBoard.storeDrawerExportCards.closeDrawer()

    switch (exportFormat) {
      case ExportFormat.pdf:
        return exportToPDF(translate, storeBoard, useUtc)

      case ExportFormat.csv:
        return exportToCSV(storeBoard)

      case ExportFormat.pptx:
        return exportToPowerPoint(translate, storeBoard, useUtc)
    }
  }

/**
 * Export to PDF.
 */
function exportToPDF(
  translate: TranslateFn,
  storeBoard: StoreBoard,
  useUtc: boolean
) {
  const { storeRoot } = storeBoard
  const { appRouter } = storeBoard.storeRoot

  const queryStringParameters =
    appRouter.getCurrentRouteQueryStringParameters<object>()

  const exportPathnames = filterFalsies([
    appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.IoA_Board_PDF,
      parameters: {},
      queryStringParameters: {
        ...queryStringParameters,
        useUtc: useUtc ? 'true' : 'false',
        timezone: moment.tz.guess()
      }
    })
  ])

  const filename = sanitizeFilename(
    `${translate('Consolidated report')}-${translate(
      'Indicators of'
    )} ${translate('Attack')}-${moment(
      storeBoard.storeTimeline.dateStart
    ).toISOString()}-${moment(storeBoard.storeTimeline.dateEnd).toISOString()}${
      useUtc ? '-UTC' : ''
    }.pdf`
  )

  sendPageToPDFMiddleware(storeRoot, exportPathnames, filename)
}

/**
 * Export to CSV.
 */
function exportToCSV(storeBoard: StoreBoard) {
  const { storeRoot } = storeBoard
  const { appRouter, appTranslator } = storeBoard.storeRoot
  const { storeAuthentication, storeMessages } = storeBoard.storeRoot.stores

  const args = storeBoard.getAttackSummaryArgs()

  if (!args) {
    storeMessages.genericError()
    return
  }

  // create a mapping between infrastructureId/infrastructure
  const infrastructures = Array.from(
    storeBoard.storeInfrastructures.infrastructures.entries()
  ).reduce(
    (acc, [infrastructureId, infrastructure]) => {
      return {
        ...acc,
        [infrastructureId]: infrastructure.getPropertyAsString('name')
      }
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    },
    {} satisfies Record<number, string>
  )

  // create a mapping between directoryId and directoryName/infrastructureId
  const directories = Array.from(
    storeBoard.storeInfrastructures.directories.entries()
  ).reduce(
    (acc, [directoryId, directory]) => {
      return {
        ...acc,
        [directoryId]: {
          name: directory.getPropertyAsString('name'),
          infrastructureId: directory.getPropertyAsNumber('infrastructureId')
        }
      }
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    },
    {} satisfies Record<number, { name: string; infrastructureId: number }>
  )

  const pathname = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.MiddlewareExportCSV,
    parameters: {}
  })

  const parameters: ISubmitFormParameters<IExportParametersIoACards> = {
    parameters: {
      routine: 'IoACards',
      queryArgs: args,
      contextData: {
        language: appTranslator.language,
        profileId: storeAuthentication.currentProfileId,
        displayOnlyAttackedDomainsSwitch:
          storeBoard.storeSummaryCards.displayOnlyAttackedDomainsSwitch,
        infrastructures,
        directories
      }
    },
    language: appTranslator.language,
    'csrf-token': storeRoot.environment.sessionParams.csrfToken
  }

  submitForm(pathname, parameters, 'POST')
}

/**
 * Export to PPTX.
 */
export function exportToPowerPoint(
  translate: TranslateFn,
  storeBoard: StoreBoard,
  useUtc: boolean
) {
  storeBoard.storeFlagsExport.loading()

  exportToPPTX(storeBoard, translate, useUtc)

  storeBoard.storeFlagsExport.success()
}
