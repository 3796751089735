import { ContainerFlex } from '@app/components/Container'
import { IconCheckCircle } from '@app/components/Icon'
import IconSkullHead from '@app/components/Icon/IconSkullHead'
import { LabelSliced } from '@app/components/Labels'
import FormWrapperCheckboxSimple from '@app/components-legacy/Form/Wrappers/FormWrapperCheckboxSimple'
import type { EntityDirectory, EntityInfrastructure } from '@app/entities'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import Cell from './Cell'
import { handleDirectoryForAllAttacksOnChange } from './handlers'

interface IDirectoryCellProps {
  isCheckable: boolean
  directory: EntityDirectory
  infrastructure: EntityInfrastructure
  className?: string
}

const Div = styled.div`
  margin-bottom: ${consts.marginSmall};
`

const CellDirectory: React.FC<IDirectoryCellProps> = props => {
  const { storeManagementAttackTypeConfiguration } = useStores()

  const hasOneAttackDisabled =
    storeManagementAttackTypeConfiguration.isDirectoryForAllAttacksPartialyDisabledForPerf(
      props.directory.getPropertyAsNumber('id')
    )

  const CheckboxElt = props.isCheckable && (
    <FormWrapperCheckboxSimple
      labelledBy={props.directory.getPropertyAsString('name')}
      checked={storeManagementAttackTypeConfiguration.isDirectoryForAllAttacksSelected(
        props.directory.getPropertyAsNumber('id')
      )}
      indeterminate={storeManagementAttackTypeConfiguration.isDirectoryForAllAttacksPartiallySelected(
        props.directory.getPropertyAsNumber('id')
      )}
      onChange={handleDirectoryForAllAttacksOnChange(
        storeManagementAttackTypeConfiguration
      )(props.directory.getPropertyAsNumber('id'))}
    />
  )

  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.IoA']
  })
  return (
    <Cell className={props.className} direction="column">
      <ContainerFlex
        name="CellDirectoryHeader"
        justifyContent="center"
        alignItems="center"
        items={[
          hasOneAttackDisabled ? (
            <div
              title={translate(
                'One or several IoAs are deactivated temporarily'
              )}
            >
              <IconSkullHead size={18} />
            </div>
          ) : (
            <IconCheckCircle />
          )
        ]}
      />

      <Div>
        <LabelSliced
          maxLength={15}
          value={props.directory.getPropertyAsString('name')}
        />
      </Div>

      {CheckboxElt}
    </Cell>
  )
}

export default observer(CellDirectory)
