import type { Maybe } from '@@types/helpers'
import { useAppRouter, useStores } from '@app/hooks'
import type { AppRouteName } from '@app/routes'
import type { IStoreMenuOptions } from '@app/stores/helpers/StoreMenu'
import StoreMenu from '@app/stores/helpers/StoreMenu'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { ensureArray } from '@libs/ensureArray'
import { observe } from 'mobx'
import * as React from 'react'
import { useHistoryChange } from '../Router/hooks'

/**
 * Initialize a new StoreMenu instance.
 */
export function useStoreMenuInit<T extends IMenuEntry<any>>(
  options: IStoreMenuOptions<T> & {
    // or pass an existing one
    storeMenu?: StoreMenu<T>
    dependencies?: any[]
  }
): StoreMenu<T> {
  const { storeRoot } = useStores()

  const storeMenu = React.useMemo(() => {
    return options.storeMenu || new StoreMenu<T>(storeRoot, options)
  }, [])

  return React.useMemo(() => {
    if (options.menuEntries) {
      storeMenu.setMenuEntries(options.menuEntries)
    }

    if (options.defaultSelectedMenuKey) {
      storeMenu.setDefaultSelectedMenuKey(options.defaultSelectedMenuKey)
    }

    return storeMenu
  }, ensureArray(options.dependencies))
}

/**
 * This hook allows to return selected entries from `menuEntries` according
 * to the current routeName.
 */
export function useMenuEntriesSelection(
  storeMenu: StoreMenu<IMenuEntry<any>>,
  allowNoCurrentSelection?: boolean
): { currentRouteName: Maybe<AppRouteName> } {
  const { currentRouteInfo } = useHistoryChange()

  const currentRouteName = currentRouteInfo?.routeName || null

  // on currentRouteName change, update the selected entry via the store
  React.useEffect(() => {
    if (!currentRouteName) {
      return
    }

    const selectedEntries = storeMenu.routeMatchingMenuEntries(currentRouteName)
    const selectedMenuEntry = selectedEntries.pop()

    if (selectedMenuEntry) {
      storeMenu.selectEntry(selectedMenuEntry.key)
      return
    }

    if (allowNoCurrentSelection) {
      storeMenu.selectEntry(null)
    }
  }, [currentRouteName])

  // bind menu selection with blades
  useMenuSelectionBladeBinding(storeMenu)

  return { currentRouteName }
}

/**
 * Update blade last url with menu selection.
 *
 * Since the menu/navigation will change the url of the current blade (which is the latest),
 * this hook observes the menu selection and updates the latest blade url.
 */
function useMenuSelectionBladeBinding(storeMenu: StoreMenu<IMenuEntry<any>>) {
  const appRouter = useAppRouter()

  const { storeBlades } = useStores()

  React.useEffect(() => {
    const dispose = observe(
      storeMenu.selectedObservedMenuKey,
      menuEntryKeyChange => {
        const routeDefinition = storeMenu.getMenuEntry(
          String(menuEntryKeyChange.newValue)
        )?.routeDefinition

        if (!routeDefinition) {
          return
        }

        const url = appRouter.makeRouteInfosPathname(routeDefinition)
        storeBlades.updateLastBladeUrl(url)
      }
    )

    return () => {
      dispose()
    }
  }, [])
}
