import { ContainerContent } from '@app/components/Container'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import ConfigurationActivityLogsForm from './ConfigurationActivityLogsForm'
import {
  onActivityLogsConfigurationLoad,
  onActivityLogsConfigurationUnload
} from './handlers'
import { canSubmitActivityLogsConfiguration } from './permissions'

interface IConfigurationLogsPageProps {}

const ConfigurationActivityLogsPage: React.FC<
  IConfigurationLogsPageProps
> = () => {
  const { storeManagementApplicationSettings } = useStores()

  return (
    <ContainerContent
      onLoad={onActivityLogsConfigurationLoad(
        storeManagementApplicationSettings
      )}
      onUnload={onActivityLogsConfigurationUnload(
        storeManagementApplicationSettings
      )}
      flags={
        storeManagementApplicationSettings.storeFlagsAppSettingsFetch.flags
      }
      rbacCapabilityCheck={canSubmitActivityLogsConfiguration}
    >
      <ConfigurationActivityLogsForm />
    </ContainerContent>
  )
}

export default observer(ConfigurationActivityLogsPage)
