import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconTrashProps extends IIconProps {}

const IconTrash: React.FC<IIconTrashProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.5 4.65L2.78 3.42C2.78 3.42 4.66 2.5 8 2.5C11.34 2.5 13.2 3.4 13.2 3.4L13.5 4.65C13.5 4.65 12.16 5.6 8 5.6C3.84 5.6 2.5 4.65 2.5 4.65Z"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M3.2 4.9209L3.85 13.4398C3.85 13.4398 4.5 14.4898 8 14.4898C11.5 14.4898 12.15 13.4398 12.15 13.4398L12.8003 4.9209"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 2.76614V0.999531C6.5 0.723648 6.72365 0.5 6.99953 0.5H9.00047C9.27635 0.5 9.5 0.723648 9.5 0.999531V2.7"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconTrash
