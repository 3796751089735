import type { MaybeUndef } from '@@types/helpers'
import type StoreForm from '@app/stores/helpers/StoreForm'
import {
  DEFAULT_COMBINATOR,
  DEFAULT_FIELD,
  DEFAULT_RULE_PARAMS,
  DEFAULT_RULEGROUP_PARAMS
} from './constants'
import type {
  DefinitionRule,
  DefinitionRuleGroup,
  DefinitionRuleOrRuleGroup,
  WizardRule,
  WizardRuleGroup,
  WizardRuleOrRuleGroup
} from './types'
import { WizardRuleGroupFieldName } from './types'

// --- Expression definition factories ---

export function createRuleDefinition(
  params: Partial<DefinitionRule> = DEFAULT_RULE_PARAMS
): DefinitionRule {
  return {
    field: params.field || DEFAULT_FIELD,
    value: params.value !== undefined ? params.value : ''
  }
}

export function createRuleGroupDefinition(
  params: Partial<DefinitionRuleGroup> = DEFAULT_RULEGROUP_PARAMS
): DefinitionRuleGroup {
  return {
    combinator: params.combinator || DEFAULT_COMBINATOR,
    rules: params.rules || []
  }
}

// --- Deep finders ---

/** Find among values a Rule or RuleGroup by his multi level path */
export function findRuleOrRuleGroup(params: {
  indexInRuleGroup: MaybeUndef<number>
  ruleGroupPath: number[]
  ruleGroup: WizardRuleGroup
}): WizardRuleOrRuleGroup {
  const selectedRuleOrRuleGroup: MaybeUndef<WizardRuleOrRuleGroup> =
    params.ruleGroupPath.reduce((ruleOrRuleGroup, indexPath) => {
      if (!ruleOrRuleGroup) {
        if (indexPath !== 0) {
          throw Error(
            `Unable to find first RuleGroup type: ${JSON.stringify(
              {
                ruleGroupPath: params.ruleGroupPath.join('.'),
                nbRules: params.ruleGroup.rules.length
              },
              null,
              2
            )}`
          )
        }

        return params.ruleGroup
      }

      if (!isWizardRuleGroup(ruleOrRuleGroup)) {
        throw Error(
          `Unable to find a RuleGroup type: ${JSON.stringify(
            {
              ruleGroupPath: params.ruleGroupPath.join('.'),
              nbRules: params.ruleGroup.rules.length
            },
            null,
            2
          )}`
        )
      }

      return ruleOrRuleGroup.rules[indexPath]
    }, undefined as MaybeUndef<WizardRuleOrRuleGroup>)

  if (!selectedRuleOrRuleGroup) {
    throw Error(
      `Unable to find a RuleOrRuleGroup type: ${JSON.stringify(
        {
          ruleGroupPath: params.ruleGroupPath.join('.'),
          nbRules: params.ruleGroup.rules.length
        },
        null,
        2
      )}`
    )
  }

  // In case ruleGroupPath contains selected index
  if (params.indexInRuleGroup === undefined) {
    return selectedRuleOrRuleGroup
  }

  // In case ruleGroupPath does not contains selected index
  if (isWizardRuleGroup(selectedRuleOrRuleGroup)) {
    return selectedRuleOrRuleGroup.rules[params.indexInRuleGroup]
  }

  throw Error(
    `Unable to find nothing: ${JSON.stringify(
      {
        ruleGroupPath: params.ruleGroupPath.join('.'),
        nbRules: params.ruleGroup.rules.length
      },
      null,
      2
    )}`
  )
}

/** Ensure to find a ruleGroup */
export function findRuleGroup(params: {
  ruleGroupPath: number[]
  ruleGroup: WizardRuleGroup
}): WizardRuleGroup {
  const ruleGroup = findRuleOrRuleGroup({
    ...params,
    indexInRuleGroup: undefined
  })

  if (!isWizardRuleGroup(ruleGroup)) {
    throw Error(
      `Unable to find a RuleGroup type: ${JSON.stringify(
        {
          ruleGroupPath: params.ruleGroupPath.join('.'),
          nbRules: params.ruleGroup.rules.length,
          isRule: isWizardRule(ruleGroup)
        },
        null,
        2
      )}`
    )
  }

  return ruleGroup
}

/** Ensure to find a Rule or RuleGroup among RuleGroup */
export function findRule(params: {
  indexInRuleGroup: MaybeUndef<number>
  ruleGroupPath: number[]
  ruleGroup: WizardRuleGroup
}): WizardRule {
  const rule = findRuleOrRuleGroup(params)

  if (!isWizardRule(rule)) {
    throw Error(
      `Unable to find a Rule type: ${JSON.stringify(
        {
          ruleGroupPath: params.ruleGroupPath.join('.'),
          nbRules: params.ruleGroup.rules.length,
          isRuleGroup: isWizardRuleGroup(rule)
        },
        null,
        2
      )}`
    )
  }

  return rule
}

// --- Type guards ---

/** Type predicate to ensure it's a StoreForm with RuleGroup fields */
function isRuleGroupStoreForm(storeForm: MaybeUndef<StoreForm<any>>): boolean {
  if (!storeForm) {
    return false
  }

  return storeForm
    .getFieldNames()
    .some(fieldName => fieldName === WizardRuleGroupFieldName.combinator)
}

/** Type predicate to ensure it's a StoreForm with Rule fields */
function isRuleStoreForm(storeForm: MaybeUndef<StoreForm<any>>): boolean {
  if (!storeForm) {
    return false
  }

  return storeForm
    .getFieldNames()
    .some(fieldName => fieldName !== WizardRuleGroupFieldName.combinator)
}

/** Type guard to ensure it contains a StoreForm with Rule fields */
export function isWizardRule(arg: WizardRuleOrRuleGroup): arg is WizardRule {
  return isRuleStoreForm(arg.storeForm)
}

/** Type guard to ensure it contains a StoreForm with RuleGroup fields */
export function isWizardRuleGroup(
  arg: WizardRuleOrRuleGroup
): arg is WizardRuleGroup {
  return isRuleGroupStoreForm(arg.storeForm)
}

/** Type guard to ensure it's a RuleGroup */
export function isDefinitionRuleGroup(
  arg: DefinitionRuleOrRuleGroup
): arg is DefinitionRuleGroup {
  return (arg as DefinitionRuleGroup).combinator !== undefined
}

/** Type guard to ensure it's a Rule */
export function isDefinitionRule(
  arg: DefinitionRuleOrRuleGroup
): arg is DefinitionRule {
  return (arg as DefinitionRuleGroup).combinator === undefined
}
