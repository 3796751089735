/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface UpdateTenantSetting
 */
export interface UpdateTenantSetting {
  /**
   *
   * @type {string}
   * @memberof UpdateTenantSetting
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof UpdateTenantSetting
   */
  credentialsId: string
  /**
   *
   * @type {boolean}
   * @memberof UpdateTenantSetting
   */
  enabled?: boolean
}

/**
 * Check if a given object implements the UpdateTenantSetting interface.
 */
export function instanceOfUpdateTenantSetting(value: object): boolean {
  if (!('name' in value)) return false
  if (!('credentialsId' in value)) return false
  return true
}

export function UpdateTenantSettingFromJSON(json: any): UpdateTenantSetting {
  return UpdateTenantSettingFromJSONTyped(json, false)
}

export function UpdateTenantSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateTenantSetting {
  if (json == null) {
    return json
  }
  return {
    name: json['name'],
    credentialsId: json['credentials_id'],
    enabled: json['enabled'] == null ? undefined : json['enabled']
  }
}

export function UpdateTenantSettingToJSON(
  value?: UpdateTenantSetting | null
): any {
  if (value == null) {
    return value
  }
  return {
    name: value['name'],
    credentials_id: value['credentialsId'],
    enabled: value['enabled']
  }
}
