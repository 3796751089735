import { ContainerFlex } from '@app/components-legacy/Container'
import type EntityAttack from '@app/entities/EntityAttack'
import { AttacksPageContext } from '@app/pages/IoA/IoAAttacksPage/context'
import { getCriticityColor } from '@libs/criticity'
import { Criticity } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import IoAAttacksTableBodyDataRowDateColoredDot from './IoAAttacksTableBodyDataRowDateColoredDot'
import IoAAttacksTableBodyDataRowDateText from './IoAAttacksTableBodyDataRowDateText'

interface IIoAAttacksTableBodyDateProps {
  attackEntity: EntityAttack
}

const IoAAttacksTableBodyDate: React.FC<
  IIoAAttacksTableBodyDateProps
> = props => {
  const attacksPageContext = React.useContext(AttacksPageContext)

  if (!attacksPageContext) {
    return null
  }

  return (
    <ContainerFlex
      name="Date"
      items={[
        <IoAAttacksTableBodyDataRowDateText
          date={props.attackEntity.getPropertyAsString('date')}
        />,

        <IoAAttacksTableBodyDataRowDateColoredDot
          color={getCriticityColor(
            props.attackEntity.attackTypeCriticity || Criticity.Unknown
          )}
        />
      ]}
    />
  )
}

export default observer(IoAAttacksTableBodyDate)
