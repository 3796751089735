import type { IDataRowAdObjectAsDeviantObject } from '@app/entities/EntityAdObject/types'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import { useStores } from '@app/hooks/useStores'
import type StoreIndicatorDeviantObjectDeviances from '@app/stores/IoE/StoreIndicator/StoreIndicatorDeviantObjectDeviances'
import * as React from 'react'

/**
 * Use a hook to instanciate a new StoreIndicatorDeviantObjectDeviances at mount.
 * It's useful to use a hook in this context because we need flags from the
 * created instance in the same component (see caller).
 *
 * @deprecated
 *
 * Do not use init like this. Prefer onLoad / unUnload handlers in Blade/ContainerContent
 * load/onLoad props.
 */
export function useDeviancesFactory(
  checkerCodename: GenericCheckerCodename,
  adObjectRow: IDataRowAdObjectAsDeviantObject
): StoreIndicatorDeviantObjectDeviances {
  const {
    storeRoot: { logger },
    storeIoE: { storeIndicator }
  } = useStores()

  // create only one store and retrieve the same instance by adObjectRow.id,
  // in order to keep the observability
  const storeIndicatorDeviantObjectDeviances = React.useMemo(() => {
    return storeIndicator.storeIndicatorDeviantObjects.createNewDeviantObjectDeviancesStore(
      adObjectRow
    )
  }, [adObjectRow.id])

  React.useEffect(() => {
    const args =
      storeIndicator.storeIndicatorDeviantObjects.computeFetchDeviantObjectsArgs(
        checkerCodename
      )

    if (!args) {
      logger.warn('Deviant objects query arguments are missing.')
      return
    }

    const deviancesArgs =
      storeIndicatorDeviantObjectDeviances.computeFetchDeviancesArgs(
        checkerCodename
      )

    if (!deviancesArgs) {
      logger.warn('Incriminating attributes query arguments are missing')
      return
    }

    storeIndicatorDeviantObjectDeviances.fetchDeviances(deviancesArgs)

    // delete the store when leaving
    return () => {
      storeIndicator.storeIndicatorDeviantObjects.deleteDeviantObjectDeviancesStore(
        adObjectRow
      )
    }
  }, [storeIndicatorDeviantObjectDeviances])

  return storeIndicatorDeviantObjectDeviances
}
