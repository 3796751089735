import { ContainerForm } from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import {
  InputCheckers,
  InputInfrastructures
} from '@app/components-legacy/Input'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreWidgetSerie from '@app/stores/Dashboard/StoreWidgetSerie'
import * as React from 'react'

export interface IWidgetInputsDataOptionsForComplianceScoreProps {
  storeSerie: StoreWidgetSerie
}

const WidgetInputsDataOptionsForComplianceScore: React.FC<
  IWidgetInputsDataOptionsForComplianceScoreProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Dashboard.WidgetCommonForm']
  })

  const fields: IField[] = [
    {
      name: 'indicators',
      label: translate('Indicators'),
      control: (
        <InputCheckers
          loadAtMount
          storeInputGenericCheckers={
            props.storeSerie.storeInputCheckersExposure
          }
        />
      )
    },
    {
      name: 'directories',
      label: translate('Directories'),
      labelAlignItem: 'center',
      control: (
        <InputInfrastructures
          loadAtMount
          storeInfrastructures={props.storeSerie.storeInfrastructures}
        />
      )
    }
  ]

  return <ContainerForm fields={fields} />
}

export default WidgetInputsDataOptionsForComplianceScore
