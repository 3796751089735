import { useAppTranslator } from '@app/hooks'
import type StoreForm from '@app/stores/helpers/StoreForm'
import {
  onInputBlur,
  onInputChange
} from '@app/stores/helpers/StoreForm/handlers'
import { consts } from '@app/styles'
import { FontColorV2 } from '@app/styles/consts'
import { Input, Tooltip } from 'antd'
import type { PasswordProps } from 'antd/lib/input'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '../Container'
import { formatErrorMessage } from '../Container/ContainerForm/functions'
import { IconEye, IconInfoTul } from '../Icon'

const StyledInputPassword = styled(Input.Password)`
  color: ${props => props.theme.tokens['input/color/text']};
  background-color: ${props =>
    props.theme.tokens['input/backgroundColor/default']};
  border: none;

  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    border: none;
    box-shadow: none;
  }

  .ant-input::placeholder {
    color: ${props => props.theme.tokens['input/color/placeholder']};
  }
`

const StyledSpan = styled.span`
  display: flex;
  cursor: pointer;
`

interface IInputPasswordProps extends PasswordProps {
  className?: string
  storeForm: StoreForm<any>
}

const InputPassword: React.FC<IInputPasswordProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.ContainerFormField']
  })

  const { name } = props

  if (!name) {
    return null
  }

  return (
    <StyledInputPassword
      name={name}
      placeholder={props.placeholder}
      onChange={onInputChange(props.storeForm)(name)}
      onBlur={onInputBlur(props.storeForm)(name)}
      value={props.value}
      autoComplete={name}
      iconRender={visible => (
        <ContainerFlex
          name="InputPasswordSuffixIcons"
          alignItems="center"
          items={[
            props.storeForm.field(name).errors.length && (
              <Tooltip
                title={formatErrorMessage(translate)(
                  props.storeForm.field(name).errors
                )}
                overlayInnerStyle={{
                  borderRadius: consts.borderRadiusRedesign
                }}
              >
                <StyledSpan>
                  <IconInfoTul size={16} color={FontColorV2.red} />
                </StyledSpan>
              </Tooltip>
            ),
            <StyledSpan>
              {visible ? (
                <IconEye />
              ) : (
                <IconEye color={FontColorV2.secondary} />
              )}
            </StyledSpan>
          ]}
          spaced
          spaceWidth="small"
        />
      )}
    />
  )
}

export default InputPassword
