import type { Maybe } from '@@types/helpers'
import type { IDataRowAdObjectAsAttribute } from './types'

// filter function on attributes row
export const filterAttributes =
  (filterRegexp: Maybe<RegExp>) =>
  (DataRow: IDataRowAdObjectAsAttribute): boolean => {
    if (!filterRegexp) {
      return true
    }

    return (
      filterRegexp.test(DataRow.attributeName) ||
      filterRegexp.test(DataRow.attributeValueAtEvent.value) ||
      filterRegexp.test(DataRow.attributeCurrentValue.value)
    )
  }
