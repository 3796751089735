import { ContainerFlex } from '@app/components-legacy/Container'
import * as React from 'react'

interface IWidgetListTableActionIconsProps {
  icons: React.ReactNodeArray
}

const WidgetListTableActionIcons: React.FC<
  IWidgetListTableActionIconsProps
> = props => {
  return (
    <ContainerFlex
      name="WidgetListTableActionIcons"
      items={props.icons}
      justifyContent="flex-end"
    />
  )
}

export default WidgetListTableActionIcons
