import type { MaybeUndef, Perhaps } from '@@types/helpers'
import type { ConfigClient } from '@libs/config/types'
import type { FetchClient } from '@libs/FetchClient'
import Translator from '@libs/i18n/Translator'
import { isDefined } from '@libs/isDefined'
import { getLogger } from '@libs/logger'
import type { Language, Maybe } from '@server/graphql/typeDefs/types'
import { LANGUAGE_LOCALSTORAGE_KEY } from './AppTranslator'
import type { AllTranslations } from './types'

/**
 * Return the first language of available languages.
 */
export function getFirstLanguageAvailable(
  languages: Array<Perhaps<Language>>,
  availableLanguages: Language[],
  fallbackLanguage: Language
): Language {
  let index = -1

  for (const language of languages.filter(isDefined)) {
    index = availableLanguages.indexOf(language)
    if (index > -1) {
      break
    }
  }

  if (index > -1) {
    return availableLanguages[index]
  }

  return fallbackLanguage
}

function hasLocalStorage(): boolean {
  return Boolean(typeof window !== 'undefined' && window && localStorage)
}

function extractLanguageFromUrl(): MaybeUndef<Language> {
  if (typeof window === 'undefined') {
    return undefined
  }

  const params: {
    [k: string]: MaybeUndef<string>
  } = window.document.location.search
    .slice(1)
    .split('&')
    .reduce((acc, param) => {
      const [key, value] = param.split('=')
      return { ...acc, [key]: value }
    }, {})

  return params.lng as Language
}

function extractBrowserLanguage(
  availableLanguages: Language[]
): MaybeUndef<Language> {
  if (typeof window === 'undefined') {
    return undefined
  }

  const browserLanguage = window.navigator.language.replace('-', '_')

  // If the language is in the format "en" instead of "en-US", search for a
  // corresponding language in the available languages.
  if (browserLanguage.length === 2) {
    return availableLanguages.find(language =>
      language.startsWith(browserLanguage)
    )
  }

  return browserLanguage as Language
}

function extractFromLocalStorage(): Maybe<Language> {
  if (!hasLocalStorage()) {
    return null
  }
  return localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) as Language
}

/**
 * Init an instance of Translator for the client.
 * Fetch translations provided by the i18n router,
 * and return a Translator instance.
 */
export function initTranslator(
  fetchClient: FetchClient,
  i18nConfig: ConfigClient['i18n'],
  _language?: Language
): Promise<Translator> {
  const logger = getLogger('i18n')

  const firstLanguage = (_language ||
    extractLanguageFromUrl() ||
    extractFromLocalStorage() ||
    extractBrowserLanguage(i18nConfig.locales.available) ||
    i18nConfig.locales.fallback) as Language

  const language = getFirstLanguageAvailable(
    [firstLanguage],
    i18nConfig.locales.available,
    i18nConfig.locales.fallback
  )

  const translator = new Translator({
    debug: i18nConfig.debug === true
  })
    .setLogger(logger)
    .setPreferredLanguages([language, i18nConfig.locales.fallback])
    .setPreferredNamespaces(['', 'Common'])

  const url = `/w/i18n/${language}`

  // Load translations and add them in the translator instance
  return fetchClient
    .get(url)
    .then(response => response.json() as Promise<AllTranslations>)
    .then(translations => {
      const languages = Object.keys(translations) as Language[]

      languages.forEach(l => {
        translator.addTranslations(l, translations[l])
      })

      return translator
    })
    .catch(err => {
      if (err) {
        logger.error(
          'An error has occurred during the translator initialization.',
          err
        )

        if (err.stack) {
          logger.debug(err.stack)
        }
      }

      return translator
    })
}
