import { BulletStatusKO, BulletStatusOK } from '@app/components-legacy/Bullet'
import {
  ContainerFlex,
  ContainerFooter,
  ContainerIcon,
  ContainerRbac
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import {
  IconDeleteOutlined,
  IconEditOutlined
} from '@app/components-legacy/Icon/IconAntd'
import IconBlank from '@app/components-legacy/Icon/IconBlank'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import {
  WidgetListActionButtons,
  WidgetListActionsHeader,
  WidgetListCountItems,
  WidgetListPagination,
  WidgetListTable,
  WidgetListTableActionIcons
} from '@app/components-legacy/Widgets/WidgetList'
import type { EntityUser } from '@app/entities'
import type { IDataRowUser } from '@app/entities/EntityUser'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { canReadLockoutPolicy } from '@app/pages/Management/SystemPage/ConfigurationPage/ConfigurationTenableAccountPage/permissions'
import { filterFalsies } from '@libs/filterFalsies'
import { isDefined } from '@libs/isDefined'
import { flatMap } from 'lodash'
import * as React from 'react'
import { canAccessToRoles } from '../../Roles/permissions'
import {
  canCreateUser,
  canDeleteUser,
  canEditUser
} from '../UsersCreatePage/permissions'
import {
  onUserCreateButtonClick,
  onUserIconDeletionClick,
  onUserIconEditClick,
  onUsersPageChange
} from './handlers'
import { UserLockedOutIcon, UserNotLockedOutIcon } from './UserLockedOutIcons'
import UserToneUserCreationModal from './UserToneUserCreationModal'

interface IUsersListProps {}

const UsersList: React.FC<IUsersListProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Login.ProviderTypes', 'Management.Accounts.Users']
  })

  const appRouter = useAppRouter()

  const { storeManagementUsers, storeRbac, storeLockoutPolicy, storeAbout } =
    useStores()

  const hiddenColumnsKeys: Array<keyof IDataRowUser> = filterFalsies([
    'id',
    !storeRbac.isUserGrantedTo(canAccessToRoles) ? 'rbacRoles' : false,
    // hide the lockedOut column if Lockout Policy is disabled
    !storeRbac.isUserGrantedTo(canReadLockoutPolicy) ||
    !storeLockoutPolicy.lockoutPolicy?.enabled
      ? 'lockedOut'
      : false
  ])

  return (
    <>
      <ContainerFlex
        name="UsersList"
        direction="column"
        items={[
          <WidgetListActionsHeader>
            <WidgetListCountItems
              storeWidgetList={storeManagementUsers.storeWidgetUsersList}
            />

            <WidgetListActionButtons>
              <ContainerRbac rbacCapabilityCheck={canCreateUser}>
                <FormWrapperButton
                  labelledBy="createUser"
                  buttonProps={{
                    type: 'primary',
                    onClick: onUserCreateButtonClick(
                      appRouter,
                      storeAbout,
                      storeRbac,
                      storeManagementUsers
                    )
                  }}
                >
                  {translate('Create a user')}
                </FormWrapperButton>
              </ContainerRbac>
            </WidgetListActionButtons>
          </WidgetListActionsHeader>,

          <WidgetListTable<EntityUser, IDataRowUser>
            translate={translate}
            hiddenColumnsKeys={hiddenColumnsKeys}
            storeWidgetList={storeManagementUsers.storeWidgetUsersList}
            cellsRenderFn={{
              provider: value => {
                return value.toUpperCase()
              },

              rbacRoles: rbacRoles => {
                const rbacRoleNames = flatMap(rbacRoles.map(role => role.node))
                  .filter(isDefined)
                  .sort((a, b) => (a.id > b.id ? 1 : -1))
                  .map(entity => entity.name)
                  .join(', ')

                return <div>{rbacRoleNames}</div>
              },

              active: value => {
                return value ? <BulletStatusOK /> : <BulletStatusKO />
              },

              lockedOut: value => {
                return value ? <UserLockedOutIcon /> : <UserNotLockedOutIcon />
              }
            }}
            actionsButtonsRenderFn={userId => {
              return (
                <WidgetListTableActionIcons
                  icons={[
                    <ContainerRbac
                      rbacCapabilityCheck={canEditUser(Number(userId))}
                      render={({ isGranted }) => {
                        return isGranted ? (
                          <ContainerIcon
                            labelledBy="editUser"
                            iconComponent={IconEditOutlined}
                            iconProps={{
                              type: 'edit',
                              onClick: onUserIconEditClick(appRouter)(
                                Number(userId)
                              )
                            }}
                          />
                        ) : (
                          <IconBlank />
                        )
                      }}
                    />,

                    <ContainerRbac
                      rbacCapabilityCheck={canDeleteUser(Number(userId))}
                      render={({ isGranted }) => {
                        return isGranted &&
                          !storeManagementUsers.isAdminUser(Number(userId)) ? (
                          <ContainerIcon
                            labelledBy="deleteUser"
                            iconComponent={IconDeleteOutlined}
                            iconProps={{
                              onClick: onUserIconDeletionClick(
                                storeManagementUsers
                              )(Number(userId))
                            }}
                          />
                        ) : (
                          <IconBlank />
                        )
                      }}
                    />
                  ]}
                />
              )
            }}
          />
        ]}
        spaced
      />

      <UserToneUserCreationModal />

      <Portal name={PlaceHolderName.bladeFooter}>
        <ContainerFooter>
          <ContainerFlex
            name="Pagination"
            justifyContent="flex-end"
            items={[
              <WidgetListPagination
                storeWidgetList={storeManagementUsers.storeWidgetUsersList}
                onChange={onUsersPageChange(storeManagementUsers)}
              />
            ]}
          />
        </ContainerFooter>
      </Portal>
    </>
  )
}

export default UsersList
