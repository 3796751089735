import type { TContainerFlexJustifyContent } from '@app/components/Container/ContainerFlex/types'
import { ContainerFlex } from '@app/components/Container/index'
import { consts } from '@app/styles'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import * as React from 'react'
import styled from 'styled-components'

interface IContainerFooterProps {
  className?: string
  variant?: 'normal' | 'popover' | 'modal'
  justify?: 'default' | 'left' | 'center' | 'right'
  children?: React.ReactNode
}

const ContainerFooter: React.FC<IContainerFooterProps> = props => {
  const childrens = React.Children.toArray(props.children)

  const getJustifyContent = (): TContainerFlexJustifyContent => {
    if (!props.justify) {
      return childrens.length === 1 ? 'flex-end' : 'space-between'
    }

    switch (props.justify) {
      case 'default':
        return 'space-between'

      case 'left':
        return 'flex-start'

      case 'center':
        return 'center'

      case 'right':
        return 'flex-end'

      default:
        assertUnreachableCase(props.justify)
    }
  }

  return (
    <ContainerFlex
      name="ContainerFooter"
      justifyContent={getJustifyContent()}
      alignItems="center"
      className={props.className}
      items={childrens}
      spaced
      spaceWidth="small"
    />
  )
}

function getPadding(props: IContainerFooterProps): string {
  if (!props.variant) {
    return `0 ${consts.paddingLarge} ${consts.paddingDefault}`
  }

  switch (props.variant) {
    case 'popover':
      return `${consts.paddingDefault} 0 0 0`

    case 'modal':
      return '0'

    case 'normal':
      return `${consts.paddingDefault} ${consts.paddingLarge}`
  }
}

export default styled(ContainerFooter)`
  width: 100%;
  padding: ${getPadding};
  min-height: ${consts.containerFooterHeightRedesign};
`
