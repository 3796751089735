import type { PropertiesNullable } from '@@types/helpers'
import type {
  Maybe,
  Pagination,
  PaginationHal
} from '@server/graphql/typeDefs/types'

export default class EntityPaginationHal
  implements PropertiesNullable<PaginationHal>
{
  nextLink: Maybe<string> = null

  constructor(data: Partial<Pagination>) {
    Object.assign(this, data)
  }
}
