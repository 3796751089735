import { AppRouteName } from '@app/routes'
import type StoreDashboards from '@app/stores/Dashboard/StoreDashboards'
import type { AppRouterClient } from '@libs/Router/types'
import type { CreateDashboardMutationArgs } from '@server/graphql/typeDefs/types'
import type { IDashboardTemplateCreation } from './types'
import { DashboardTemplate } from './types'

/**
 * Form init at loading.
 */
export const handleAddDashboardLoad =
  (storeDashboards: StoreDashboards) => () => {
    storeDashboards.storeDashboardsManagement.initDashboardTemplates()
  }

/**
 * Open the blade to add a dashboard.
 */
export const onAddDashboardButtonClick =
  (storeDashboards: StoreDashboards) =>
  (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    storeDashboards.storeDashboardsManagement.storeDrawerAddDashboard.openDrawer()
  }

/**
 * Create a new dashboard and redirect to the new-created-dashboard page.
 */
export const onAddDashboardValidate =
  (appRouter: AppRouterClient, storeDashboards: StoreDashboards) =>
  (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault()
    }

    const { storeDashboardsManagement } = storeDashboards
    const {
      storeFormAddDashboard,
      storeDrawerAddDashboard: storeAddDashboardDrawer
    } = storeDashboardsManagement

    if (!storeFormAddDashboard.validate()) {
      return
    }

    const args: CreateDashboardMutationArgs = {
      dashboard: {
        instanceName:
          storeFormAddDashboard.getFieldValueAsString('instanceName'),
        name: storeFormAddDashboard.getFieldValueAsString('name'),
        // FIXME
        order: 0
      }
    }

    storeDashboardsManagement
      .createDashboard(args)
      .then(() => storeDashboards.fetchDashboards())
      .then(() => {
        storeAddDashboardDrawer.closeDrawer()
        storeFormAddDashboard.reset()

        // redirect to the new created dashboard
        const lastDashboard = storeDashboards.getLastDashboardOfInstance(
          args.dashboard.instanceName
        )

        if (!lastDashboard) {
          return
        }

        const dashboardKey = lastDashboard.getDashboardKey()

        if (!dashboardKey) {
          return
        }

        storeDashboards.selectDashboard(dashboardKey)

        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Dashboard_Grid,
          parameters: {
            instanceName: lastDashboard.getPropertyAsString('instanceName'),
            dashboardId: lastDashboard.getPropertyAsNumber('id')
          }
        })

        appRouter.history.push(url)
      })
      .catch(() => {
        /* Already handled in the store */
      })
  }

/**
 * Create new dashboards and redirect to the last new-created-dashboard page.
 */
export const handleAddDashboardsSubmit =
  (appRouter: AppRouterClient, storeDashboards: StoreDashboards) =>
  (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault()
    }

    const { storeDashboardsManagement } = storeDashboards
    const {
      storeFormAddDashboard,
      storeDrawerAddDashboard: storeAddDashboardDrawer
    } = storeDashboardsManagement

    let order =
      storeDashboards
        .getLastDashboardOfInstance(
          storeFormAddDashboard.getFieldValueAsString('instanceName')
        )
        ?.getPropertyAsNumber('order') ?? 0

    const dashboardsTemplates: IDashboardTemplateCreation[] = []

    if (
      storeDashboardsManagement.dashboardTemplates.get(
        DashboardTemplate.customDashboard
      )
    ) {
      if (!storeFormAddDashboard.validate()) {
        return
      }

      order++
      const args: CreateDashboardMutationArgs = {
        dashboard: {
          instanceName:
            storeFormAddDashboard.getFieldValueAsString('instanceName'),
          name: storeFormAddDashboard.getFieldValueAsString('name'),
          order: order
        }
      }

      dashboardsTemplates.push({
        args: args,
        template: DashboardTemplate.customDashboard
      })
    }

    if (
      storeDashboardsManagement.dashboardTemplates.get(
        DashboardTemplate.adRisk360
      )
    ) {
      order++
      const args: CreateDashboardMutationArgs = {
        dashboard: {
          instanceName:
            storeFormAddDashboard.getFieldValueAsString('instanceName'),
          name: storeDashboards.translate('AD risk 360'),
          order: order
        }
      }

      dashboardsTemplates.push({
        args: args,
        template: DashboardTemplate.adRisk360
      })
    }

    if (
      storeDashboardsManagement.dashboardTemplates.get(
        DashboardTemplate.nativeAdminMonitoring
      )
    ) {
      order++
      const args: CreateDashboardMutationArgs = {
        dashboard: {
          instanceName:
            storeFormAddDashboard.getFieldValueAsString('instanceName'),
          name: storeDashboards.translate('Native Admin Monitoring'),
          order: order
        }
      }

      dashboardsTemplates.push({
        args: args,
        template: DashboardTemplate.nativeAdminMonitoring
      })
    }

    if (
      storeDashboardsManagement.dashboardTemplates.get(
        DashboardTemplate.adComplianceAndTopRisks
      )
    ) {
      order++
      const args: CreateDashboardMutationArgs = {
        dashboard: {
          instanceName:
            storeFormAddDashboard.getFieldValueAsString('instanceName'),
          name: storeDashboards.translate('AD Compliance and Top Risks'),
          order: order
        }
      }

      dashboardsTemplates.push({
        args: args,
        template: DashboardTemplate.adComplianceAndTopRisks
      })
    }

    if (
      storeDashboardsManagement.dashboardTemplates.get(
        DashboardTemplate.passwordManagementRisk
      )
    ) {
      order++
      const args: CreateDashboardMutationArgs = {
        dashboard: {
          instanceName:
            storeFormAddDashboard.getFieldValueAsString('instanceName'),
          name: storeDashboards.translate('Password Management Risk'),
          order: order
        }
      }

      dashboardsTemplates.push({
        args: args,
        template: DashboardTemplate.passwordManagementRisk
      })
    }

    if (
      storeDashboardsManagement.dashboardTemplates.get(
        DashboardTemplate.userMonitoring
      )
    ) {
      order++
      const args: CreateDashboardMutationArgs = {
        dashboard: {
          instanceName:
            storeFormAddDashboard.getFieldValueAsString('instanceName'),
          name: storeDashboards.translate('User Monitoring'),
          order: order
        }
      }

      dashboardsTemplates.push({
        args: args,
        template: DashboardTemplate.userMonitoring
      })
    }

    if (!dashboardsTemplates.length) {
      return
    }

    storeDashboards.storeRoot.stores.storeIoE.fetchCheckersExposure()

    storeDashboardsManagement
      .createDashboards(dashboardsTemplates)
      .then(() => storeDashboards.fetchDashboards())
      .then(() => {
        storeAddDashboardDrawer.closeDrawer()
        storeFormAddDashboard.reset()

        // redirect to the new created dashboard
        const lastDashboard = storeDashboards.getLastDashboardOfInstance(
          dashboardsTemplates[dashboardsTemplates.length - 1].args.dashboard
            .instanceName
        )

        if (!lastDashboard) {
          return
        }

        const dashboardKey = lastDashboard.getDashboardKey()

        if (!dashboardKey) {
          return
        }

        storeDashboards.selectDashboard(dashboardKey)

        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Dashboard_Grid,
          parameters: {
            instanceName: lastDashboard.getPropertyAsString('instanceName'),
            dashboardId: lastDashboard.getPropertyAsNumber('id')
          }
        })

        appRouter.history.push(url)
      })
      .catch(() => {
        /* Already handled in the store */
      })
  }

export const handleAddDashboardTemplate =
  (
    storeDashboards: StoreDashboards,
    dashboardTemplateType: DashboardTemplate
  ) =>
  (checked: boolean) => {
    const { storeDashboardsManagement } = storeDashboards

    storeDashboardsManagement.dashboardTemplates.set(
      dashboardTemplateType,
      checked
    )
  }
