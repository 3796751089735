import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'
import IconDirection, { Direction } from './IconDirection'

interface IIconLeftProps extends IIconProps {}

const IconLeft: React.FC<IIconLeftProps> = props => (
  <IconDirection direction={Direction.left} {...props} />
)

export default IconLeft
