import type { AvailableLanguage, Maybe } from '@server/graphql/typeDefs/types'
import Lookup from 'country-code-lookup'
import ISO6391 from 'iso-639-1'

export const extractLanguageSubtagFromLocale = (locale: string): string => {
  return locale.split('_')[0]
}
export const extractScriptOrRegionSubtagFromLocale = (
  locale: string
): Maybe<string> => {
  const splittedLocale = locale.split('_')

  if (splittedLocale.length < 2) {
    return null
  }

  return splittedLocale[1]
}

export const addBetaTag = (isBeta: boolean, label: string): string => {
  if (!isBeta) {
    return label
  }

  return label + ' (beta)'
}

export const getLabelFromLocale = (
  locale: AvailableLanguage,
  languageOccurences: Map<string, number>
) => {
  const languageSubtag = extractLanguageSubtagFromLocale(locale.language)
  const scriptOrRegionSubtag = extractScriptOrRegionSubtagFromLocale(
    locale.language
  )

  if (languageSubtag.toLowerCase() === 'zh') {
    switch (scriptOrRegionSubtag?.toLowerCase()) {
      case 'cn':
      case 'hans':
        return addBetaTag(locale.isBeta, '简体中文')
      case 'tw':
      case 'hant':
        return addBetaTag(locale.isBeta, '繁體中文')
      default:
      // Nothing to do, use default naming if not cn, tw, hans or hant.
    }
  }

  const languageName = ISO6391.getNativeName(languageSubtag) ?? locale

  // Add country name if there is multiple same language code
  const occurences = languageOccurences.get(languageSubtag)

  if (occurences && occurences < 2) {
    return addBetaTag(locale.isBeta, languageName)
  }

  if (!scriptOrRegionSubtag) {
    return addBetaTag(locale.isBeta, languageName)
  }

  return addBetaTag(
    locale.isBeta,
    `${languageName} (${
      Lookup.byIso(scriptOrRegionSubtag)?.country ?? scriptOrRegionSubtag
    }) `
  )
}
