import type { IDataRowReport } from '@app/entities/EntityReport'
import type { StoreManagementReportingCenter } from '@app/stores'
import { action } from 'mobx'

export const handleReportDeletionOnClick =
  (storeManagementReports: StoreManagementReportingCenter) =>
  (reportId: number) =>
  () => {
    const reportEntity = storeManagementReports.reports.get(reportId)

    if (reportEntity) {
      storeManagementReports.storeDrawerDeleteReport
        .setData({ reportDataRow: reportEntity.asDataRow() })
        .openDrawer()
    }
  }

/**
 * Delete the report, close the drawer and reload reports.
 */
export const handleReportDeleteOnSubmit =
  (storeManagementReports: StoreManagementReportingCenter) =>
  (dataRow: IDataRowReport) =>
    action((e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      storeManagementReports
        .deleteReport(dataRow.id)
        .then(() => {
          storeManagementReports.storeDrawerDeleteReport.closeDrawer()
          return storeManagementReports.fetchReports()
        })
        .catch(() => {
          // already catched in the store
        })
    })
