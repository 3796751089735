import type { StoreInputCrontab } from '@app/stores/helpers/StoreInputCrontab'
import { InputCrontabPeriod } from '@app/stores/helpers/StoreInputCrontab/types'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import ConfigurationByDay from './ConfigurationByDay'
import ConfigurationByMonth from './ConfigurationByMonth'
import ConfigurationByWeek from './ConfigurationByWeek'
import ConfigurationByYear from './ConfigurationByYear'

export interface IConfigurationProps {
  storeInputCrontab: StoreInputCrontab
}

function Configuration(props: IConfigurationProps) {
  switch (props.storeInputCrontab.period) {
    case InputCrontabPeriod.byYear: {
      return <ConfigurationByYear storeInputCrontab={props.storeInputCrontab} />
    }

    case InputCrontabPeriod.byMonth: {
      return (
        <ConfigurationByMonth storeInputCrontab={props.storeInputCrontab} />
      )
    }

    case InputCrontabPeriod.byWeek: {
      return <ConfigurationByWeek storeInputCrontab={props.storeInputCrontab} />
    }

    case InputCrontabPeriod.byDay: {
      return <ConfigurationByDay storeInputCrontab={props.storeInputCrontab} />
    }

    default: {
      assertUnreachableCase(props.storeInputCrontab.period)
    }
  }
}

export default observer(Configuration)
