import { ContainerIcon } from '@app/components-legacy/Container'
import { IconRightOutlined } from '@app/components-legacy/Icon/IconAntd'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import type { IDataRowGeneric } from '@app/stores/helpers/StoreWidgetList/types'
import { classes } from '@app/styles'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { onActionIconToggleClick } from './handlers'

interface IWidgetListTableActionIconsToggleProps {
  className?: string
  row: IDataRowGeneric
  storeWidgetList: StoreWidgetList<any, any>
}

/**
 * Toggle icon to open/close a toggleable row.
 */
const WidgetListTableActionIconsToggle: React.FC<
  IWidgetListTableActionIconsToggleProps
> = props => {
  return (
    <ContainerIcon
      labelledBy="toggleRow"
      className={classnames(classes.tableIconDontHide, props.className)}
      iconComponent={IconRightOutlined}
      iconProps={{
        onClick: onActionIconToggleClick(props.storeWidgetList)(props.row)
      }}
    />
  )
}

export default observer(styled(WidgetListTableActionIconsToggle)`
  transform: ${props =>
    props.storeWidgetList.isToggleableRowOpened(props.row)
      ? 'rotate(90deg)'
      : ''};
  transition:
    transform 0.1s ease-out,
    opacity 0.3s,
    background 0.3s !important;
`)
