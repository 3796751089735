import type { StoreManagementRelays } from '@app/stores'

export const handleRelaysRenewApiKeys =
  (storeManagementRelays: StoreManagementRelays) => () => {
    if (storeManagementRelays.storeFlagsRelaysRenewApiKey.isLoading) {
      return
    }

    storeManagementRelays.storeModalConfirmRenewApiKey.setData({ bulk: true })
    storeManagementRelays.storeModalConfirmRenewApiKey.show()
  }
