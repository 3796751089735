import { consts } from '@app/styles'
import { BackgroundColorV2, FontColorV2 } from '@app/styles/consts'
import * as React from 'react'
import SimpleBar from 'simplebar-react'
import styled from 'styled-components'

interface IInputCodeProps {
  className?: string
  children?: React.ReactNode
}

const StyledSimpleBar = styled(SimpleBar)`
  max-height: 200px;

  .simplebar-mask {
    border-radius: 3px;
  }
`

const Wrapper = styled.div`
  height: 200px;
`

const CustomDiv = styled.div`
  font-size: 11px;
  font-family: 'Source Code Pro';
  color: ${FontColorV2.secondary};
  background-color: ${BackgroundColorV2.lightGray};
  padding: ${consts.paddingDefault};
  height: 100%;
  line-height: 19.5px;
`

const InputCode: React.FC<IInputCodeProps> = props => {
  return (
    <StyledSimpleBar autoHide={false}>
      <Wrapper>
        <CustomDiv>{props.children}</CustomDiv>
      </Wrapper>
    </StyledSimpleBar>
  )
}

export default InputCode
