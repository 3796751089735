import type { EntityTopologyDirectory } from '@app/entities'
import { SCENE_TOOLIP_DEBOUNCE_DELAY } from '@app/stores/Topology/consts'
import * as React from 'react'
import styled from 'styled-components'
import type SceneObject from './SceneObject'

interface IDirectoryPolygonProps {
  className?: string
  color: string
  directorySceneObject: SceneObject<EntityTopologyDirectory>
  isTransparent?: boolean
  isKnown?: boolean
}

const DirectoryShape: React.FC<IDirectoryPolygonProps> = props => {
  return (
    <g
      transform={props.directorySceneObject.translateString}
      className={props.className}
    >
      <polygon points="10 5, 20 20, 0 20" />
    </g>
  )
}

export default styled(DirectoryShape)`
  fill: ${props => props.color};
  fill-opacity: ${props => (props.isTransparent ? '0.2' : '1')};
  transition: all ${SCENE_TOOLIP_DEBOUNCE_DELAY}ms linear;
  cursor: ${props => (props.isKnown ? 'pointer' : 'auto')};
`
