import type StoreSummaryCard from '@app/stores/IoA/StoreBoard/StoreSummaryCard'
import { AttacksSummaryChartCardType } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import CardAttacksByCriticityChart from './CardAttacksByCriticityChart'
import CardAttacksInTimeChart from './CardAttacksInTimeChart'

interface ICardChartProps {
  storeSummaryCard: StoreSummaryCard
}

const CardChart: React.FC<ICardChartProps> = props => {
  switch (props.storeSummaryCard.options.attacksSummaryCardEntity.chartType) {
    case AttacksSummaryChartCardType.Donut:
      return (
        <CardAttacksByCriticityChart
          storeSummaryCard={props.storeSummaryCard}
        />
      )

    case AttacksSummaryChartCardType.LineChart:
      return (
        <CardAttacksInTimeChart storeSummaryCard={props.storeSummaryCard} />
      )
  }
}

export default CardChart
