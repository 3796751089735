import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import type { IRbacRoute } from '@app/components-legacy/Router/types'
import { useStores } from '@app/hooks/useStores'
import AttackPathPage from '@app/pages/AttackPath'
import { canAccessToAttackPath } from '@app/pages/AttackPath/permissions'
import AuthPage from '@app/pages/Auth'
import Dashboard from '@app/pages/Dashboard'
import DashboardPdfPage from '@app/pages/Dashboard/GridPage/DrawerExportDashboardPdf/DashboardPdfPage'
import { canAccessToDashbards } from '@app/pages/Dashboard/permissions'
import APIInspector from '@app/pages/Debugg/APIInspector'
import HealthCheck from '@app/pages/HealthCheck'
import { canAccessToHealthCheck } from '@app/pages/HealthCheck/permissions'
import IdentitiesPage from '@app/pages/Identities/IdentitiesPage'
import KonsoleIdentitiesPage from '@app/pages/Identities/IdentitiesPage/KonsoleIdentityPage'
import { canAccessToIdentities } from '@app/pages/Identities/permissions'
import IdentityExplorerPage from '@app/pages/IdentityExplorer/IdentityExplorerPage'
import { canAccessToIdentityExplorer } from '@app/pages/IdentityExplorer/permissions'
import IoABoardPage from '@app/pages/IoA/IoABoardPage'
import {
  canAccessToIoABoard,
  canAccessToIoAExport
} from '@app/pages/IoA/IoABoardPage/permissions'
import IoABoardPagePDF from '@app/pages/IoA/IoABoardPDFPage'
import IoEBoardPage from '@app/pages/IoE/IoEBoardPage'
import { canAccessToIoE } from '@app/pages/IoE/IoEBoardPage/permissions'
import AccountsPage from '@app/pages/Management/AccountsPage'
import ActivityLogs from '@app/pages/Management/AccountsPage/ActivityLogs'
import { canAccessToActivityLogs } from '@app/pages/Management/AccountsPage/ActivityLogs/permissions'
import { canAccessToAccounts } from '@app/pages/Management/AccountsPage/permissions'
import SystemPage from '@app/pages/Management/SystemPage'
import { canAccessToSystem } from '@app/pages/Management/SystemPage/permissions'
import Preferences from '@app/pages/Preferences'
import { canAccessToPreferences } from '@app/pages/Preferences/permissions'
import ReportsPage from '@app/pages/Reports'
import { canAccessToReports } from '@app/pages/Reports/permissions'
import { canAccessToSecurityEngine } from '@app/pages/SecurityEngine/WeaknessInstancesPage/permissions'
import Topology from '@app/pages/Topology'
import { canAccessToTopology } from '@app/pages/Topology/permissions'
import TrailFlow from '@app/pages/TrailFlow'
import { canAccessToTrailFlow } from '@app/pages/TrailFlow/permissions'
import { canAccessToWeaknesses } from '@app/pages/Weaknesses/permissions'
import WeaknessesPage from '@app/pages/Weaknesses/WeaknessesPage'
import type { AppRouteDefinition } from '@app/routes'
import { AppRouteName } from '@app/routes'
import { filterFalsies } from '@libs/filterFalsies'
import { grant } from '@libs/rbac/permissions'
import WeaknessInstancesPage from 'app/pages/SecurityEngine/WeaknessInstancesPage'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IRoutesProps {}

/**
 * Declare all root routes of the app.
 */
const Routes: React.FC<IRoutesProps> = () => {
  const { storeDebug } = useStores()

  const routes = filterFalsies<IRbacRoute<AppRouteName, AppRouteDefinition>>([
    {
      routeDefinition: {
        routeName: AppRouteName.Dashboard,
        parameters: {}
      },
      component: Dashboard,
      rbacPermissionsCheck: () => canAccessToDashbards
    },
    {
      routeDefinition: {
        routeName: AppRouteName.Dashboard_PDF,
        parameters: {}
      },
      routeContext: {
        isPDFPage: true
      },
      component: DashboardPdfPage,
      rbacPermissionsCheck: () => canAccessToDashbards
    },
    {
      routeDefinition: {
        routeName: AppRouteName.IdentityExplorer,
        parameters: {}
      },
      component: IdentityExplorerPage,
      rbacPermissionsCheck: () => canAccessToIdentityExplorer
    },
    {
      routeDefinition: {
        routeName: AppRouteName.Gi_Identities,
        parameters: {}
      },
      component: IdentitiesPage,
      rbacPermissionsCheck: () => canAccessToIdentities
    },

    {
      routeDefinition: {
        routeName: AppRouteName.Identities,
        parameters: {}
      },
      component: KonsoleIdentitiesPage,
      rbacPermissionsCheck: () => canAccessToIdentities
    },
    {
      routeDefinition: {
        routeName: AppRouteName.Gi_Weaknesses,
        parameters: {}
      },
      component: WeaknessesPage,
      rbacPermissionsCheck: () => canAccessToWeaknesses
    },
    {
      routeDefinition: {
        routeName: AppRouteName.SecurityEngine,
        parameters: {}
      },
      component: WeaknessInstancesPage,
      rbacPermissionsCheck: () => canAccessToSecurityEngine
    },
    {
      routeDefinition: {
        routeName: AppRouteName.TrailFlow,
        parameters: {}
      },
      component: TrailFlow,
      rbacPermissionsCheck: () => canAccessToTrailFlow
    },
    {
      routeDefinition: {
        routeName: AppRouteName.IoE_Board,
        parameters: {}
      },
      component: IoEBoardPage,
      rbacPermissionsCheck: () => canAccessToIoE
    },
    {
      routeDefinition: {
        routeName: AppRouteName.IoA_Board,
        parameters: {}
      },
      component: IoABoardPage,
      rbacPermissionsCheck: () => canAccessToIoABoard
    },
    {
      routeDefinition: {
        routeName: AppRouteName.IoA_Board_PDF,
        parameters: {}
      },
      routeContext: {
        isPDFPage: true
      },
      component: IoABoardPagePDF,
      rbacPermissionsCheck: () => canAccessToIoAExport
    },
    {
      routeDefinition: {
        routeName: AppRouteName.Topology,
        parameters: {}
      },
      component: Topology,
      rbacPermissionsCheck: () => canAccessToTopology
    },
    {
      routeDefinition: {
        routeName: AppRouteName.AttackPath,
        parameters: {}
      },
      component: AttackPathPage,
      rbacPermissionsCheck: () => canAccessToAttackPath
    },
    {
      routeDefinition: {
        routeName: AppRouteName.Management_Accounts,
        parameters: {}
      },
      component: AccountsPage,
      rbacPermissionsCheck: () => canAccessToAccounts
    },
    {
      routeDefinition: {
        routeName: AppRouteName.Management_System,
        parameters: {}
      },
      component: SystemPage,
      rbacPermissionsCheck: () => canAccessToSystem
    },
    {
      routeDefinition: {
        routeName: AppRouteName.Preferences,
        parameters: {}
      },
      component: Preferences,
      rbacPermissionsCheck: () => canAccessToPreferences
    },
    {
      routeDefinition: {
        routeName: AppRouteName.ActivityLogs,
        parameters: {}
      },
      component: ActivityLogs,
      rbacPermissionsCheck: () => canAccessToActivityLogs
    },
    {
      routeDefinition: {
        routeName: AppRouteName.HealthCheck,
        parameters: {}
      },
      component: HealthCheck,
      rbacPermissionsCheck: () => canAccessToHealthCheck
    },
    storeDebug.isDevFlag() && {
      routeDefinition: {
        routeName: AppRouteName.Debug_APIInspector,
        parameters: {}
      },
      component: APIInspector,
      rbacPermissionsCheck: () => grant()
    },

    /**
     * /!\ Be careful, order of declared routes is important! /!\
     *
     * <RbacRoute /> browses all routes above and renders the first on which the user is granted.
     * Meaning that public routes (with no authentication) and finally Auth page need to be declared lastly.
     */

    {
      routeDefinition: {
        routeName: AppRouteName.Reports,
        parameters: {},
        queryStringParameters: {
          uuid: String()
        }
      },
      component: ReportsPage,
      rbacPermissionsCheck: () => canAccessToReports,
      isAnonymous: true,
      dontVerifyLicensesContext: true
    },
    {
      routeDefinition: {
        routeName: AppRouteName.Auth,
        parameters: {}
      },
      component: AuthPage,
      rbacPermissionsCheck: () => grant(),
      isAnonymous: true,
      dontVerifyLicensesContext: true
    }
  ])

  return <RbacRoutes routes={routes} redirect />
}

export default observer(Routes)
