import { configure } from 'mobx'
import { createRoot } from 'react-dom/client'
import { createApp } from './createApp'

// enable MobX strict mode
configure({
  enforceActions: 'observed',
  isolateGlobalState: true
})

const appContainer = document.getElementById('app')
const csrfToken = appContainer?.dataset.csrfToken ?? null

createApp({
  pathname: document.location.pathname,
  csrfToken
}).then(App => {
  const appContainer = document.getElementById('app')

  if (!appContainer) {
    return
  }

  // App can be null when a redirection is performed
  if (App) {
    const root = createRoot(appContainer)
    root.render(App)
  }
})
