import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import SyslogCommonForm from '../SyslogCommonForm'
import { onSyslogsEditSubmit } from './handlers'

interface ISyslogEditFormProps {
  syslogId: number
}

const SyslogEditForm: React.FC<ISyslogEditFormProps> = () => {
  const appRouter = useAppRouter()

  const { storeManagementSyslogs } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_System_Configuration_Syslogs_Edit,
    parameters: {
      syslogId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <SyslogCommonForm
      version="edition"
      onSubmit={onSyslogsEditSubmit(storeManagementSyslogs)(
        parameters.syslogId
      )}
    />
  )
}

export default observer(SyslogEditForm)
