import { useAppTranslator } from '@app/hooks'
import { getCriticityColor } from '@libs/criticity'
import type { Criticity } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import { BulletCircle } from '../Bullet'
import { ContainerFlex } from '../Container'

export interface ILabelCriticityProps {
  criticity: Criticity
}

const LabelCriticity: React.FC<ILabelCriticityProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.LabelChecker.ComplexityLevel']
  })

  return (
    <ContainerFlex
      name="LabelCriticity"
      alignItems="center"
      items={[
        <BulletCircle color={getCriticityColor(props.criticity)} />,
        <div>{translate(props.criticity)}</div>
      ]}
      spaced
      labelledBy={props.criticity}
      spaceWidth="verySmall"
    />
  )
}

export default LabelCriticity
