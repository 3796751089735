import { defaultTheme } from '@design-system/styles/themes/default'
import type { DSThemeDefinition } from '@design-system/styles/themes/types'
import { paladinVarsLight } from 'tenable-design-system-alt'

const tokens: Partial<DSThemeDefinition['tokens']> = {
  /**
   * Card
   */

  'card/borderRadius/default': paladinVarsLight.borderRadius.radius0,
  'card/borderRadius/large': paladinVarsLight.borderRadius.radiusL,
  'card/boxShadow/light': paladinVarsLight.boxShadow.boxShadowContainerSmall,
  'card/boxShadow/default': paladinVarsLight.boxShadow.shadowDepth1,
  'card/color/backgroundWhite': paladinVarsLight.color.colorBackgroundPrimary,
  'card/color/border/default': paladinVarsLight.border.borderContainerPrimary,
  'card/margin/mainContent': paladinVarsLight.spacing.space500,
  'card/padding/default': paladinVarsLight.spacing.spacingM,
  'card/padding/mainContent': paladinVarsLight.spacing.space800,

  /**
   * HeadBar
   */

  // We will probably use this color which is very close to the current one and the one on Figma
  // 'headBar/color/background': paladinVarsLight.color.colorsLightModeMidnight100,

  // Should be "paladinVarsLight.color.colorBackgroundSecondary" but CAM is not using the correct color
  'headBar/color/bodyBackgroundN': 'rgb(243, 244, 246)', // very close to colorBackgroundTertiary

  /**
   * HealthCheck
   */

  'healthCheck/color/failure': paladinVarsLight.color.colorForegroundStatusBad,
  'healthCheck/color/success': paladinVarsLight.color.colorForegroundStatusGood,
  'healthCheck/color/unknown':
    paladinVarsLight.color.colorBackgroundButtonDisabled,
  'healthCheck/color/warning':
    paladinVarsLight.color.colorForegroundStatusWarning,

  /**
   * Label
   */

  'label/color/text': paladinVarsLight.color.colorTextPrimary,

  /**
   * MainMenu
   */

  'mainMenu/padding/default': `${paladinVarsLight.spacing.spacingL} 10px`,
  'mainMenu/width/default': '56px',
  'mainMenu/group/border-radius/default':
    paladinVarsLight.borderRadius.radius250,
  'mainMenu/group/color/background':
    paladinVarsLight.color.colorBackgroundSecondary,
  'mainMenu/link/border-radius': paladinVarsLight.borderRadius.radius250,
  'mainMenu/link/color/background':
    paladinVarsLight.color.colorBackgroundSecondary,
  'mainMenu/link/color/backgroundHover':
    paladinVarsLight.color.colorBackgroundButtonPrimaryHover,
  'mainMenu/link/color/backgroundActive':
    paladinVarsLight.color.colorBackgroundButtonPrimaryIdle,
  'mainMenu/link/color/text': paladinVarsLight.color.colorForegroundTertiary,
  'mainMenu/link/color/textHover':
    paladinVarsLight.color.colorForegroundButtonPrimaryHover,
  'mainMenu/link/color/textActive':
    paladinVarsLight.color.colorForegroundButtonPrimaryHover,
  'mainMenu/link/padding/default': paladinVarsLight.spacing.spacingM,
  'mainMenu/menuEntry/width/default': '36px',
  'mainMenu/menuEntry/width/expanded': '201px',
  'mainMenu/menuEntry/width/expandedWithoutPadding': '185px'
}

export const paladinThemeTokens: DSThemeDefinition = {
  ...defaultTheme,
  tokens: { ...defaultTheme.tokens, ...tokens }
}
