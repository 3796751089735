import { ContainerFlex } from '@app/components-legacy/Container'
import type { StoreInfrastructures } from '@app/stores'
import { ensureArray } from '@libs/ensureArray'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import InfrastructuresTree from './InfrastructuresTree'

export interface IInputInfrastructuresPopoverProps {
  className?: string
  storeInfrastructures: StoreInfrastructures
}

function getInfrastructuresTreeItems(
  props: IInputInfrastructuresPopoverProps
): React.ReactNode {
  const { storeInfrastructures } = props

  const infrastructures = Array.from(
    props.storeInfrastructures.infrastructures.values()
  )
    .filter(infrastructureEntity => {
      const searchRegexp =
        storeInfrastructures.storeInputSearch.transformedSearchValueAsRegexp

      return (
        searchRegexp.test(infrastructureEntity.getPropertyAsString('name')) ||
        infrastructureEntity.getDirectories().some(entityDirectory => {
          return searchRegexp.test(entityDirectory.getPropertyAsString('name'))
        })
      )
    })
    .filter(infrastructureEntity => {
      const directories = ensureArray(infrastructureEntity.directories)
      // if the infra has no directories
      // (case when a new infra has just been created), do not display it
      return directories.length > 0
    })

  return (
    <InfrastructuresTree
      storeInfrastructures={props.storeInfrastructures}
      infrastructures={infrastructures}
    />
  )
}

const DrawerContent: React.FC<IInputInfrastructuresPopoverProps> = props => {
  return (
    <ContainerFlex
      name="InputInfrastructuresDrawerContent"
      className={props.className}
      items={getInfrastructuresTreeItems(props)}
      direction="column"
    />
  )
}

export default observer(DrawerContent)
