import type { EntityAttackType } from '@app/entities'
import type { IAttackChartData } from '@app/pages/IoA/IoABoardPage/IoABoardCards/CardChart/types'
import type StoreSummaryCard from '@app/stores/IoA/StoreBoard/StoreSummaryCard'
import { consts } from '@app/styles'
import { getCriticityColor } from '@libs/criticity'
import { formatDate } from '@libs/dates/formatDate'
import { convertTimestampToIsoString } from '@libs/dates/helpers'
import type { TranslateFn } from '@libs/i18n'
import {
  AttacksSummaryChartCardType,
  Criticity
} from '@server/graphql/typeDefs/types'
import type PptxGenJS from 'pptxgenjs'
import {
  GLOBAL_PADDING,
  PPTX_RIBBON_WIDTH,
  PPTX_SHIELD_SIZE
} from './constants'
import type { IPPTXCoords, IPPTXSize } from './types'
import {
  addBulletTextAndCount,
  addImage,
  addText,
  cleanColor,
  pixelToInches
} from './utils'

const PPTX_WHITE = cleanColor(consts.colorWhite)
const PPTX_BLUE_GREY_05 = cleanColor(consts.colorBlueGray015)
const PPTX_GREY = cleanColor(consts.colorGrey010)
const PPTX_SHADOW_GREY = cleanColor(consts.colorGrey005)

export class IoABoardCardPPTX {
  constructor(
    private slide: PptxGenJS.Slide,
    private translate: TranslateFn,
    private storeSummaryCard: StoreSummaryCard,
    private position: IPPTXCoords,
    private dimensions: IPPTXSize,
    private useUtc: boolean
  ) {}

  get forestName(): string {
    const directoryEntity = this.storeSummaryCard.directoryEntity

    if (!directoryEntity) {
      return ''
    }

    const infrastructureEntity =
      this.storeSummaryCard.storeRoot.stores.storeInfrastructures.getInfrastructureFromId(
        directoryEntity.getPropertyAsNumber('infrastructureId')
      )

    return infrastructureEntity?.getPropertyAsString('name') || ''
  }
  get domainName(): string {
    return this.storeSummaryCard.directoryEntity?.name || ''
  }

  get forestColor(): string {
    return cleanColor(this.storeSummaryCard.directoryEntity?.color) || 'FFFFFF'
  }

  private drawRibbon() {
    // Draw background
    this.slide.addShape('roundRect', {
      x: this.position.x,
      y: this.position.y,
      rectRadius: pixelToInches(5),
      fill: {
        color: this.forestColor
      },
      w: PPTX_RIBBON_WIDTH,
      h: this.dimensions.h
    })

    // Add forest name
    addText(this.slide, this.forestName, {
      x: this.position.x,
      y: this.position.y + GLOBAL_PADDING,
      vert: 'vert270',
      color: PPTX_WHITE,
      w: PPTX_RIBBON_WIDTH,
      h: this.dimensions.h - GLOBAL_PADDING,
      align: 'right'
    })
  }

  private drawShieldIcon(parentCoords: IPPTXCoords, parentSize: IPPTXSize) {
    const shieldSize: IPPTXSize = {
      w: pixelToInches(PPTX_SHIELD_SIZE.w),
      h: pixelToInches(PPTX_SHIELD_SIZE.h)
    }
    addImage(this.slide, '/w/assets/images/icons/shield.png', {
      ...shieldSize,
      x: parentCoords.x + (parentSize.w - shieldSize.w) / 2, // Center horizontally
      y: parentCoords.y + (parentSize.h - shieldSize.h) / 2 // Center vertically
    })
  }

  private drawLineChart(coords: IPPTXCoords, size: IPPTXSize) {
    const heightTitleZones = pixelToInches(40)

    addText(this.slide, this.translate('Number of events').toUpperCase(), {
      x: coords.x,
      y: coords.y,
      w: size.w,
      h: heightTitleZones,
      valign: 'middle',
      color: PPTX_BLUE_GREY_05
    })

    // Draw the chart
    const data = [
      {
        name: 'attacks',
        labels: this.storeSummaryCard.numberOfAttacksInTime.map(
          dataPointCount =>
            formatDate(convertTimestampToIsoString(dataPointCount.timestamp), {
              utc: this.useUtc,
              format:
                this.storeSummaryCard.storeRoot.stores.storeIoA.storeBoard
                  .storeTimeline.tickDateFormat
            })
        ),
        values: this.storeSummaryCard.numberOfAttacksInTime.map(
          dataPointCount => dataPointCount.count
        )
      }
    ]

    const chartPosition: IPPTXCoords = {
      x: coords.x + GLOBAL_PADDING,
      y: coords.y + heightTitleZones
    }
    const chartSize: IPPTXSize = {
      w: size.w - 2 * GLOBAL_PADDING,
      h: size.h - 2 * heightTitleZones
    }

    const labelsColor = this.storeSummaryCard.hasAttacksDetected
      ? cleanColor(consts.colorBlack001)
      : PPTX_GREY

    this.slide.addChart('bar', data, {
      ...chartPosition,
      ...chartSize,
      chartColors: [this.forestColor],
      layout: { x: 0, y: 0, w: 1, h: 1 },
      catAxisLabelFontSize: 6,
      catAxisLabelColor: labelsColor,
      valAxisLabelFontSize: 8,
      valAxisLabelColor: labelsColor,
      valGridLine: {
        style: 'dot',
        color: cleanColor(consts.colorGrey010)
      },
      color: labelsColor,
      barGapWidthPct: 10,
      catAxisLabelRotate: 0
    })

    // If no attack, draw the no-attack icon
    if (!this.storeSummaryCard.hasAttacksDetected) {
      this.drawShieldIcon(chartPosition, chartSize)
    }

    addText(this.slide, this.translate('Time').toUpperCase(), {
      x: coords.x,
      y: coords.y + size.h - heightTitleZones,
      w: size.w - GLOBAL_PADDING,
      h: heightTitleZones,
      valign: 'middle',
      align: 'right',
      color: PPTX_BLUE_GREY_05
    })
  }

  private drawDistributionAttack(
    coords: IPPTXCoords,
    size: IPPTXSize,
    data: IAttackChartData
  ) {
    /*
    Generating some consts for permormance reasons
    */
    const bulletSize = pixelToInches(10)
    const widthCountLabel = pixelToInches(65)
    const marginBulletAndLabel = pixelToInches(15)
    const withAvailableForBulletText = size.w - widthCountLabel

    addBulletTextAndCount(this.slide, {
      bullet: {
        color: this.storeSummaryCard.hasAttacksDetected
          ? data.criticityColor
          : PPTX_GREY,
        size: pixelToInches(10)
      },
      position: coords,
      text: {
        value: this.translate(data.criticity),
        options: {
          w: withAvailableForBulletText - bulletSize - marginBulletAndLabel,
          valign: 'middle',
          margin: marginBulletAndLabel
        }
      },
      countText: {
        value: `${data.rate} %`,
        width: widthCountLabel,
        options: {
          valign: 'middle',
          bold: true,
          align: 'right'
        }
      },
      size: {
        w: size.w,
        h: pixelToInches(15)
      }
    })
  }
  private drawDonutChart(coords: IPPTXCoords, size: IPPTXSize) {
    const heightTitleZones = pixelToInches(40)

    addText(this.slide, this.translate('Attack distribution'), {
      x: coords.x,
      y: coords.y,
      color: cleanColor(consts.colorBlack001),
      w: size.w,
      h: heightTitleZones,
      valign: 'middle'
    })

    const heightAvailableForCriticity = (size.h - heightTitleZones) / 4
    const colSize = size.w / 2

    this.storeSummaryCard.rateOfAttacksByCriticity.forEach(
      (attackChartData, index) => {
        this.drawDistributionAttack(
          {
            x: coords.x,
            y: coords.y + heightTitleZones + heightAvailableForCriticity * index
          },
          {
            w: colSize,
            h: heightAvailableForCriticity
          },
          attackChartData
        )
      }
    )

    // Draw the chart
    const chartPosition = {
      x: coords.x + colSize + 2 * GLOBAL_PADDING,
      y: coords.y + heightTitleZones
    }
    const chartSize = {
      w: colSize - 2 * GLOBAL_PADDING,
      h: size.h - heightTitleZones - 2 * GLOBAL_PADDING
    }

    const data = [
      {
        name: 'attacks',
        labels: this.storeSummaryCard.hasAttacksDetected
          ? this.storeSummaryCard.rateOfAttacksByCriticity.map(
              attackChartData => attackChartData.criticity
            )
          : [Criticity.Unknown],
        values: this.storeSummaryCard.hasAttacksDetected
          ? this.storeSummaryCard.rateOfAttacksByCriticity.map(
              attackChartData => attackChartData.rate
            )
          : [100]
      }
    ]

    const chartColors = this.storeSummaryCard.hasAttacksDetected
      ? this.storeSummaryCard.rateOfAttacksByCriticity.map(
          attackChartData =>
            cleanColor(attackChartData.criticityColor) || 'FFFFFF'
        )
      : [PPTX_GREY]

    this.slide.addChart('doughnut', data, {
      ...chartPosition,
      ...chartSize,
      chartColors,
      showLabel: false,
      showLegend: false,
      showPercent: false,
      showValue: false,
      layout: { x: 0, y: 0, w: 1, h: 1 },
      holeSize: 70
    })

    // If no attack, draw the no-attack icon
    if (!this.storeSummaryCard.hasAttacksDetected) {
      this.drawShieldIcon(chartPosition, chartSize)
    }
  }

  /**
   * Draw a Top 3 Attack. It includes the bullet, the attack type and the count
   */
  private drawTop3Attack(
    coords: IPPTXCoords,
    size: IPPTXSize,
    attackTypeCount: {
      attackType: EntityAttackType
      count: number
    }
  ) {
    const criticity = attackTypeCount.attackType.genericCriticity

    if (!criticity) {
      return
    }

    if (!attackTypeCount.attackType.name) {
      return
    }

    /**
     * Generating some consts for permormance reasons
     */
    const bulletSize = pixelToInches(10)
    const widthCountLabel = pixelToInches(30)
    const marginBulletAndLabel = pixelToInches(15)
    const withAvailableForBulletText = size.w - widthCountLabel

    addBulletTextAndCount(this.slide, {
      bullet: {
        color: getCriticityColor(criticity),
        size: pixelToInches(10)
      },
      position: coords,
      text: {
        value: attackTypeCount.attackType.name,
        options: {
          valign: 'middle',
          margin: marginBulletAndLabel,
          wrap: false,
          fit: 'none',
          isTextBox: false
        },
        width: withAvailableForBulletText - bulletSize - marginBulletAndLabel
      },
      countText: {
        value: `${attackTypeCount.count}`,
        width: widthCountLabel,
        options: {
          valign: 'middle',
          align: 'right'
        }
      },
      size: {
        w: size.w,
        h: pixelToInches(15)
      }
    })
  }

  /**
   * Draw the block for Top 3 Attacks. It includes the title and items
   */
  private drawTop3Attacks(coords: IPPTXCoords, size: IPPTXSize): void {
    const heightTitleZones = pixelToInches(30)
    const titleMarginBottom = pixelToInches(15)

    this.slide.addShape('rect', {
      fill: { color: cleanColor(consts.colorWhiteAlt) },
      h: heightTitleZones,
      x: coords.x,
      y: coords.y,
      w: size.w
    })
    addText(this.slide, this.translate('Top 3 attacks'), {
      x: coords.x + GLOBAL_PADDING,
      y: coords.y,
      color: cleanColor(consts.colorBlack001),
      w: size.w - GLOBAL_PADDING,
      h: heightTitleZones
    })

    const heightAvailableForAttacks =
      (size.h - heightTitleZones - titleMarginBottom) / 3

    if (!this.storeSummaryCard.hasAttacksDetected) {
      // No attack, display only a message
      addText(
        this.slide,
        this.translate('No attack detected during this period'),
        {
          x: coords.x + GLOBAL_PADDING,
          y: coords.y + heightTitleZones + titleMarginBottom,
          w: size.w - GLOBAL_PADDING,
          h: pixelToInches(15),
          color: PPTX_GREY
        }
      )

      return
    }

    this.storeSummaryCard.attackTypesTop3.forEach((attackType, index) => {
      this.drawTop3Attack(
        {
          x: coords.x + GLOBAL_PADDING,
          y:
            coords.y +
            heightTitleZones +
            titleMarginBottom +
            heightAvailableForAttacks * index
        },
        {
          w: size.w - 2 * GLOBAL_PADDING,
          h: heightAvailableForAttacks
        },
        attackType
      )
    })
  }

  draw() {
    // Draw the background
    this.slide.addShape('roundRect', {
      x: this.position.x,
      y: this.position.y,
      fill: { color: PPTX_WHITE },
      ...this.dimensions,
      rectRadius: pixelToInches(5),
      shadow: {
        type: 'outer',
        color: PPTX_SHADOW_GREY,
        angle: 1,
        offset: 1,
        blur: 10,
        opacity: 0.8
      }
    })

    this.drawRibbon()

    const mainSize: IPPTXSize = {
      w: this.dimensions.w - PPTX_RIBBON_WIDTH,
      h: this.dimensions.h - 2 * GLOBAL_PADDING
    }
    const mainPosition: IPPTXCoords = {
      x: this.position.x + PPTX_RIBBON_WIDTH,
      y: this.position.y + GLOBAL_PADDING
    }

    // Draw the domain name
    addText(this.slide, this.domainName.toUpperCase(), {
      x: mainPosition.x + GLOBAL_PADDING,
      y: mainPosition.y,
      w: mainSize.w,
      fontSize: 13,
      color: PPTX_BLUE_GREY_05
    })

    const heightCardTitle = 2 * GLOBAL_PADDING
    const heightForSection1 = (mainSize.h - heightCardTitle) * 0.55
    const heightForSection2 = (mainSize.h - heightCardTitle) * 0.45

    const part1Coords: IPPTXCoords = {
      x: mainPosition.x + GLOBAL_PADDING,
      y: mainPosition.y + heightCardTitle
    }
    const part1Size: IPPTXSize = {
      w: mainSize.w - 2 * GLOBAL_PADDING,
      h: heightForSection1
    }

    switch (this.storeSummaryCard.options.attacksSummaryCardEntity.chartType) {
      case AttacksSummaryChartCardType.Donut:
        this.drawDonutChart(part1Coords, part1Size)
        break
      case AttacksSummaryChartCardType.LineChart:
        this.drawLineChart(part1Coords, part1Size)
        break
    }

    this.drawTop3Attacks(
      {
        x: mainPosition.x,
        y: mainPosition.y + heightCardTitle + heightForSection1
      },
      {
        w: mainSize.w,
        h: heightForSection2
      }
    )

    // Draw the border
    this.slide.addShape('roundRect', {
      x: this.position.x,
      y: this.position.y,
      line: {
        color: PPTX_GREY
      },
      ...this.dimensions,
      rectRadius: pixelToInches(5)
    })
  }
}
