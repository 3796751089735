/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { ProviderType } from './ProviderType'
import {
  ProviderTypeFromJSON,
  ProviderTypeFromJSONTyped,
  ProviderTypeToJSON
} from './ProviderType'
import type { ScanInfo } from './ScanInfo'
import {
  ScanInfoFromJSON,
  ScanInfoFromJSONTyped,
  ScanInfoToJSON
} from './ScanInfo'

/**
 *
 * @export
 * @interface TenantSetting
 */
export interface TenantSetting {
  /**
   *
   * @type {string}
   * @memberof TenantSetting
   */
  uuid: string
  /**
   *
   * @type {string}
   * @memberof TenantSetting
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof TenantSetting
   */
  credentialsId: string
  /**
   *
   * @type {boolean}
   * @memberof TenantSetting
   */
  enabled: boolean
  /**
   *
   * @type {Date}
   * @memberof TenantSetting
   */
  lastSucceededScanAt?: Date
  /**
   *
   * @type {ProviderType}
   * @memberof TenantSetting
   */
  providerType: ProviderType
  /**
   *
   * @type {ScanInfo}
   * @memberof TenantSetting
   */
  lastScanInfo?: ScanInfo
}

/**
 * Check if a given object implements the TenantSetting interface.
 */
export function instanceOfTenantSetting(value: object): boolean {
  if (!('uuid' in value)) return false
  if (!('name' in value)) return false
  if (!('credentialsId' in value)) return false
  if (!('enabled' in value)) return false
  if (!('providerType' in value)) return false
  return true
}

export function TenantSettingFromJSON(json: any): TenantSetting {
  return TenantSettingFromJSONTyped(json, false)
}

export function TenantSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TenantSetting {
  if (json == null) {
    return json
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
    credentialsId: json['credentials_id'],
    enabled: json['enabled'],
    lastSucceededScanAt:
      json['last_succeeded_scan_at'] == null
        ? undefined
        : new Date(json['last_succeeded_scan_at']),
    providerType: ProviderTypeFromJSON(json['provider_type']),
    lastScanInfo:
      json['last_scan_info'] == null
        ? undefined
        : ScanInfoFromJSON(json['last_scan_info'])
  }
}

export function TenantSettingToJSON(value?: TenantSetting | null): any {
  if (value == null) {
    return value
  }
  return {
    uuid: value['uuid'],
    name: value['name'],
    credentials_id: value['credentialsId'],
    enabled: value['enabled'],
    last_succeeded_scan_at:
      value['lastSucceededScanAt'] == null
        ? undefined
        : value['lastSucceededScanAt'].toISOString(),
    provider_type: ProviderTypeToJSON(value['providerType']),
    last_scan_info: ScanInfoToJSON(value['lastScanInfo'])
  }
}
