import type { EntityDashboardWidgetOptionsSerieStatAttackCounts } from '@app/entities'
import { CriticityValuesOrdered, getCriticityValue } from '@libs/criticity'
import { DateFormat } from '@libs/dates/formatDate'
import { AttacksSummaryPeriod, Language } from '@server/graphql/typeDefs/types'
import * as moment from 'moment'
import type { IAttackTypeNumberOfAttacks } from '../types'

/**
 * Compute DataPointAttackCounts into sorted ioa array.
 */
export const getAttackTypesByNumberOfAttacks = (
  statsAttackCountsEntities: EntityDashboardWidgetOptionsSerieStatAttackCounts[],
  attackTypesNames: Map<number, string>
): IAttackTypeNumberOfAttacks[] => {
  const defaultArr: IAttackTypeNumberOfAttacks[] = []

  return statsAttackCountsEntities
    .reduce((acc, entity, index) => {
      const criticity = CriticityValuesOrdered[index]

      const dataWithCriticities = entity.dataEntities.map(dataPoint => {
        return {
          criticity,
          count: dataPoint.getPropertyAsNumber('count'),
          attackTypeId: dataPoint.getPropertyAsNumber('attackTypeId'),
          name:
            attackTypesNames.get(
              dataPoint.getPropertyAsNumber('attackTypeId')
            ) ?? ''
        }
      })

      return acc.concat(dataWithCriticities)
    }, defaultArr)
    .sort((a, b) => {
      // Sorting works like this:
      //  i. Reversed sort by number of attacks
      //  ii. Sort by criticity
      //  iii. Sort by alpha if same count and same criticity

      const diffCounts = b.count - a.count

      if (diffCounts) {
        return diffCounts
      }

      const diffCriticities =
        getCriticityValue(b.criticity) - getCriticityValue(a.criticity)

      if (diffCriticities) {
        return diffCriticities
      }

      return a.name > b.name ? 1 : -1
    })
}

/**
 * Compute DataPointAttackCounts into top 3 sorted ioa by higher count.
 */
export const getTop3AttackTypes = (
  statsAttackCountsEntities: EntityDashboardWidgetOptionsSerieStatAttackCounts[],
  attackTypesNames: Map<number, string>
): IAttackTypeNumberOfAttacks[] => {
  return getAttackTypesByNumberOfAttacks(
    statsAttackCountsEntities,
    attackTypesNames
  ).slice(0, 3)
}

/**
 * Return a formatted date depending on the selected period (hour, day, ...).
 */
export const barChartTooltipDateByAttacksSummaryPeriod = (
  dateStart: string,
  dateEnd: string,
  attacksSummaryPeriod: AttacksSummaryPeriod,
  language: Language
) => {
  const utcDateStart = moment(dateStart)

  const dayFormat =
    language === Language.FrFr
      ? DateFormat.frenchDateOfTheDay
      : DateFormat.englishDateOfTheDay

  switch (attacksSummaryPeriod) {
    case AttacksSummaryPeriod.Hour:
    case AttacksSummaryPeriod.Day:
      return `${utcDateStart
        .format(dayFormat)
        .toUpperCase()} (${utcDateStart.format(
        DateFormat.hoursMinutes
      )} - ${moment(dateEnd).format(DateFormat.hoursMinutes)})`

    case AttacksSummaryPeriod.Month:
      return utcDateStart.format(dayFormat).toUpperCase()

    case AttacksSummaryPeriod.Year: {
      const localizedMonthYearFormat =
        language === Language.FrFr
          ? DateFormat.frenchMonthYear
          : DateFormat.englishMonthYear

      return utcDateStart.format(localizedMonthYearFormat).toUpperCase()
    }
  }
}
