import type { EntityTopologyDirectory } from '@app/entities'
import { SCENE_TOOLIP_DEBOUNCE_DELAY } from '@app/stores/Topology/consts'
import * as React from 'react'
import styled from 'styled-components'
import type SceneObject from './SceneObject'

interface IDirectoryLabelProps {
  className?: string
  directorySceneObject: SceneObject<EntityTopologyDirectory>
  isHighlighted?: boolean
  isTransparent?: boolean
  isKnown?: boolean
}

const DirectoryLabel: React.FC<IDirectoryLabelProps> = props => {
  const { name } = props.directorySceneObject.object

  if (!name) {
    return null
  }

  return (
    <text className={props.className} {...props.directorySceneObject.xy}>
      {name}
    </text>
  )
}

export default styled(DirectoryLabel)`
  font-size: 12px;
  opacity: ${props => (props.isTransparent ? '0.2' : '1')};
  font-weight: ${props => (props.isHighlighted ? 600 : 100)};
  transition: all ${SCENE_TOOLIP_DEBOUNCE_DELAY}ms linear;
  cursor: ${props => (props.isKnown ? 'pointer' : 'auto')};
`
