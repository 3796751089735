import { Label } from '@app/components/Labels'
import type { EntityUserLog } from '@app/entities'
import { consts } from '@app/styles'
import React from 'react'
import styled from 'styled-components'
import { TraceTypeLabelMap } from '../types'

const StyledLabel = styled(Label)`
  margin-right: ${consts.marginSmall};
`

const UserLogAction = (props: { log: EntityUserLog }) => {
  return (
    <StyledLabel className="traceAction" labelledBy={String(props.log.logType)}>
      {props.log.logType && TraceTypeLabelMap.get(props.log.logType)}
    </StyledLabel>
  )
}

export default UserLogAction
