export enum DirectoryFormFieldName {
  infrastructureId = 'infrastructureId',
  relayId = 'relayId',

  type = 'type',

  name = 'name',
  dns = 'dns',
  sensitiveDataCollectionIsEnabled = 'sensitiveDataCollectionIsEnabled',
  tenableCloudSensitiveData = 'tenableCloudSensitiveData',

  ip = 'ip',
  ldapPort = 'ldapPort',
  globalCatalogPort = 'globalCatalogPort',
  smbPort = 'smbPort'
}
