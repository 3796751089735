import type { IGraphQLQuery } from '@libs/graphQL/types'
import type { Query } from '@server/graphql/typeDefs/types'

/**
 * Get current user and profiles.
 */

export type QueryInitUserData = IGraphQLQuery<
  null,
  Pick<
    Query,
    'applicationConfiguration' | 'preferences' | 'whoAmI' | 'rbacProfiles'
  >
>

export const queryInitUserData = `
  query queryStubInitUserData {
    applicationConfiguration {
      availableLanguages {
        language
        isBeta
      }
    }
    preferences {
      language
      preferredProfileId
    }
    whoAmI {
      id
      name
      surname
      email
      eulaVersion
      department
      provider
      roles {
        id
        name
        description
        permissions {
          entityName
          action
          entityIds
        }
      }
    }
    rbacProfiles {
      node {
        id
        name
        hasEverBeenCommitted
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
