import { Features } from '@alsid/common'
import { ContainerFlex } from '@app/components/Container'
import { useLoader } from '@app/hooks'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { handleMainMenuOnLoad } from './handlers'
import MenuEntries from './MenuEntries'

interface IMainMenuProps {
  className?: string
  style?: React.CSSProperties
}

const MainMenu: React.FC<IMainMenuProps> = props => {
  const { storeLayout } = useStores()

  const isNewNavigation =
    storeLayout.storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
      Features.KAPTEYN_LEFT_NAVIGATION_UI
    )

  useLoader({
    onLoad: handleMainMenuOnLoad(storeLayout)
  })

  const labelledBy = storeLayout.storeMenuMainNavigation.expanded
    ? 'mainMenuExpanded'
    : 'mainMenu'

  const getEntries = () => {
    return [
      <MenuEntries
        storeMenu={storeLayout.storeMenuMainNavigation}
        menuEntries={storeLayout.storeMenuMainNavigation.menuEntries}
        variant={isNewNavigation ? 'current' : 'legacy'}
      />
    ]
  }

  return (
    <ContainerFlex
      name="MainMenu"
      labelledBy={labelledBy}
      className={props.className}
      style={props.style}
      direction="column"
      flexGap={isNewNavigation ? 'small' : undefined}
      itemsFlexGrow={isNewNavigation ? undefined : [1, 0]}
      items={getEntries()}
    />
  )
}

const ObservedMainMenu = observer(MainMenu)

export default styled(ObservedMainMenu)`
  padding: ${props => props.theme.tokens['mainMenu/padding/default']};

  background: ${props => props.theme.tokens['mainMenu/color/background']};
  box-shadow: 2px 0 10px #d5d5e9;

  /* Allow the menu to be scrollable */
  overflow-y: auto;

  /* To be above Blades overlays */
  z-index: ${consts.zIndexNavigation};
`
