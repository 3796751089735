import type EntityDashboardWidgetOptions from '@app/entities/EntityDashboardWidgetOptions'
import type StoreDashboard from '@app/stores/Dashboard/StoreDashboard'
import type StoreWidget from '@app/stores/Dashboard/StoreWidget'
import type { WidgetKey } from '@libs/dashboards/types'

export interface IGridWidgetGenericProps {
  preLoad: boolean
  widgetKey: WidgetKey
  title: string
  options: EntityDashboardWidgetOptions
  storeWidget: StoreWidget
  storeDashboard: StoreDashboard
}

export enum ExportFormat {
  pdf = 'pdf'
}

export enum ExportFormFieldName {
  exportFormat = 'exportFormat'
}
