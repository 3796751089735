export const sizes = {
  smallest: '1px',
  veryVerySmall: '2px',
  verySmall: '3px',
  smaller: '4px',
  small: '5px',
  medium: '7px',
  default: '10px',
  large: '15px',
  veryLarge: '20px',
  veryVeryLarge: '25px',
  extraLarge: '30px',
  megaLarge: '60px'
}

export const colorTokens = {
  /**
   * Background colors
   */
  'color/background/white': '#FFFFFF',
  'color/background/lightGray': '#F7F9FC',
  'color/background/gray': '#d9dbe0',
  'color/background/tooltips': '#353A43',
  'color/background/green': '#498334',
  'color/background/greenLight': '#BDE8AF',
  'color/background/red': '#C74757',
  'color/background/redLight': '#FFC2CA',

  /**
   * Brand colors
   */
  'color/brand/blue1': '#5FA6EF',
  'color/brand/blue2': '#539FEC',
  'color/brand/blue3': '#4697EA',
  'color/brand/blue4': '#398FE7',
  'color/brand/blue5': '#2E88E5',
  'color/brand/blue6': '#2181E3',
  'color/brand/blue7': '#157AE1',
  'color/brand/blue8': '#0A73DF',
  'color/brand/blue9': '#0365CA',
  'color/brand/gradientStart': '#B9DCFF',
  'color/brand/gradientEnd': '#026EDD',
  'color/brand/lightGray': '#C5CFE9',
  'color/brand/decorativeGray': '#E3EAF4',

  /**
   * CTA colors
   */
  'color/cta/primary': '#026EDD',
  'color/cta/primaryHover': '#52A2EF',
  'color/cta/secondary': '#D7EBFF',
  'color/cta/secondaryHover': '#ECF7FF',
  'color/cta/tertiary': '#FFFFFF',
  'color/cta/delete': '#FF8181',
  'color/cta/disable': '#EAEBF2',

  /**
   * Font colors
   */
  'color/font/primary': '#545B92',
  'color/font/secondary': '#959ABC',
  'color/font/link': '#026EDD',
  'color/font/white': '#FFFFFF',
  'color/font/green': '#3D702B',
  'color/font/red': '#9E313E',
  'color/font/beta': '#457B00',

  /**
   * Overlay color
   */
  'color/overlay/default': 'rgba(41, 89, 166, 0.1)'
}

const paddingTokens = {
  /**
   * Paddings
   */
  'padding/veryVerySmall': '3px',
  'padding/verySmall': '5px',
  'padding/small': '7px',
  'padding/default': '10px',
  'padding/medium': '15px',
  'padding/large': '20px',
  'padding/extraLarge': '30px',
  'padding/XXL': '40px',
  'padding/XXXL': '60px'
}

const marginTokens = {
  /**
   * Margins
   */
  'margin/extraSmall': '2px',
  'margin/veryVerySmall': '3px',
  'margin/verySmall': '5px',
  'margin/small': '10px',
  'margin/smaller': '15px',
  'margin/default': '20px',
  'margin/medium': '30px',
  'margin/large': '40px',
  'margin/extraLarge': '60px'
}

const borderRadiusTokens = {
  /**
   * Border radius
   */
  'borderRadius/small': '3px',
  'borderRadius/medium': '5px',
  'borderRadius/large': '10px',
  'borderRadius/XL': '20px'
}

const boxShadowTokens = {
  /**
   * Box shadows
   */
  'boxShadow/light': '0 0 10px rgba(213, 213, 233, 0.5)',
  'boxShadow/default': '0 3px 7px rgba(207, 207, 235, 0.5)',
  'boxShadow/blade': '0 3px 7px 0 rgba(110,110,165,0.2)',
  'boxShadow/bladeTab': '-5px 0 5px -4px rgba(110,110,165,0.2)'
}

const fontSizeTokens = {
  /**
   * Font size
   */
  'fontSize/small': '11px',
  'fontSize/default': '13px'
}

const fontWeightTokens = {
  /**
   * Font Weight
   */
  'fontWeight/normal': '400',
  'fontWeight/bold': '600'
}

const tokens = {
  ...paddingTokens,
  ...marginTokens,
  ...borderRadiusTokens,
  ...boxShadowTokens,
  ...fontSizeTokens,

  /**
   * Blade
   */

  'blade/backgroundColor/default': colorTokens['color/background/lightGray'],
  'blade/backgroundColor/levelN': colorTokens['color/background/white'],
  'blade/boxShadow/default': boxShadowTokens['boxShadow/blade'],
  'blade/borderRadius/default': `${borderRadiusTokens['borderRadius/medium']} 0 0 ${borderRadiusTokens['borderRadius/medium']}`,

  /**
   * Button
   */

  'button/background/primary': colorTokens['color/cta/primary'],
  'button/background/secondary': colorTokens['color/cta/secondary'],
  'button/background/select': colorTokens['color/background/lightGray'],
  'button/background/tertiary': colorTokens['color/background/white'],
  'button/backgroundHover/tertiary': colorTokens['color/cta/secondary'],
  'button/borderRadius/default': borderRadiusTokens['borderRadius/small'],
  'button/color/primary': colorTokens['color/font/white'],
  'button/color/secondary': colorTokens['color/cta/primary'],
  'button/color/tertiary': colorTokens['color/cta/primary'],

  /**
   * Card
   */

  'card/borderRadius/default': borderRadiusTokens['borderRadius/medium'],
  'card/borderRadius/large': borderRadiusTokens['borderRadius/large'],
  'card/boxShadow/light': boxShadowTokens['boxShadow/light'],
  'card/boxShadow/default': boxShadowTokens['boxShadow/default'],
  'card/color/backgroundWhite': colorTokens['color/background/white'],
  'card/color/border/default': colorTokens['color/font/secondary'],
  'card/margin/default': '',
  'card/margin/mainContent': '',
  'card/padding/default': paddingTokens['padding/large'],
  'card/padding/mainContent': `${paddingTokens['padding/medium']} ${paddingTokens['padding/large']}}`,

  /**
   * CardInfo
   */
  'cardInfo/backgroundColor/default': colorTokens['color/background/lightGray'],
  'cardInfo/color/icon': colorTokens['color/cta/primary'],

  /**
   * Checkbox
   */

  'checkbox/borderRadius/default': borderRadiusTokens['borderRadius/small'],
  'checkbox/backgroundColor/default': colorTokens['color/background/white'],
  'checkbox/backgroundColor/disabled': colorTokens['color/cta/disable'],
  'checkbox/backgroundColor/checked': colorTokens['color/cta/primary'],
  'checkbox/backgroundColor/hover': colorTokens['color/cta/primaryHover'],
  'checkbox/borderColor/checked': colorTokens['color/cta/primary'],
  'checkbox/borderColor/default': '#D3D9E3',
  'checkbox/backgroundColor/indeterminate': colorTokens['color/font/secondary'],

  /**
   * Container
   */

  'container/background/default': colorTokens['color/background/white'],
  'container/background/primary': colorTokens['color/background/lightGray'],

  /**
   * ContainerDivider
   */

  'containerDivider/color/default': colorTokens['color/font/secondary'],

  /**
   * ContainerFooter
   */

  'containerFooter/color/separator': colorTokens['color/brand/decorativeGray'],

  /**
   * ContainerIcon
   */

  'containerIcon/color/default': colorTokens['color/font/primary'],
  'containerIcon/color/disabled': colorTokens['color/cta/disable'],
  'containerIcon/color/hover': colorTokens['color/cta/primary'],

  /**
   * ContainerHTMLContent
   */

  'containerHtmlContent/blockquote/borderLeft':
    colorTokens['color/font/secondary'],
  'containerHtmlContent/blockquote/color': colorTokens['color/font/secondary'],
  'containerHtmlContent/link/color': colorTokens['color/font/link'],
  'containerHtmlContent/link/color/hover': colorTokens['color/font/link'],

  /**
   * ContainerTimeline
   */

  'containerTimeline/borderLeftColor/default':
    colorTokens['color/brand/decorativeGray'],
  'containerTimeline/color/bulletCircle': colorTokens['color/font/primary'],

  /**
   * DashboardWidgets
   */

  'dashboardWidget/color/title': colorTokens['color/font/secondary'],
  'dashboardWidget/color/counter': colorTokens['color/font/primary'],
  'dashboardWidget/color/resizeArrow': colorTokens['color/font/link'],
  'dashboardWidget/boxShadow': boxShadowTokens['boxShadow/light'],
  'dashboardWidget/backgroundColor/widgetIllustration':
    colorTokens['color/background/lightGray'],
  'dashboardWidget/backgroundColorHover/widgetIllustration':
    colorTokens['color/background/lightGray'],
  'dashboardWidget/backgroundColorSelected/widgetIllustration':
    colorTokens['color/background/lightGray'],
  'dashboardWidget/borderColor/widgetIllustration': 'transparent',
  'dashboardWidget/borderColorHover/widgetIllustration':
    colorTokens['color/cta/primary'],
  'dashboardWidget/borderColorSelected/widgetIllustration':
    colorTokens['color/cta/primary'],
  'dashboardWidget/borderSize/widgetIllustration': '0',
  'dashboardWidget/borderSizeSelected/widgetIllustration': '1px',
  'dashboardWidget/boxShadow/widgetIllustration': 'none',
  'dashboardWidget/fontSize/widgetIllustration': '53px',
  'dashboardWidget/width/widgetIllustration': '130px',
  'dashboardWidget/fontWeight/fieldSetTitle': '400',

  /**
   * Drawer
   */

  'drawer/color/overlay': colorTokens['color/overlay/default'],
  'drawer/color/subtitle': colorTokens['color/font/secondary'],
  'drawer/borderRadius/default': borderRadiusTokens['borderRadius/large'],
  'drawer/boxShadow/horizontal': '-5px 0px 10px rgba(213, 213, 233, 0.5)',
  'drawer/color/selectionCounter': colorTokens['color/font/secondary'],

  /**
   * DrawerVerticalContainer
   */

  'drawerVerticalContainer/boxShadow/default':
    boxShadowTokens['boxShadow/light'],

  /**
   * HeadBar
   */

  'headBar/boxShadow/bladeTab': '-5px 0 5px -4px rgba(110,110,165,0.2)',
  'headBar/color/background': colorTokens['color/brand/decorativeGray'],
  'headBar/color/bodyBackground0': colorTokens['color/background/white'],
  'headBar/color/bodyBackgroundN': colorTokens['color/background/lightGray'],
  'headBar/height/default': '25px',
  'headBar/border/color': colorTokens['color/background/gray'],

  /**
   * HealthCheck
   */

  'healthCheck/color/failure': colorTokens['color/font/red'],
  'healthCheck/color/success': colorTokens['color/font/green'],
  'healthCheck/color/unknown': colorTokens['color/brand/lightGray'],
  'healthCheck/color/warning': colorTokens['color/font/secondary'],

  /**
   * InfrastructureDirectories
   */

  'infrastructureDirectories/color/border': colorTokens['color/font/secondary'],

  /**
   * Input
   */

  'input/borderRadius/default': borderRadiusTokens['borderRadius/small'],
  'input/backgroundColor/default': colorTokens['color/background/lightGray'],
  'input/backgroundColor/disabled': colorTokens['color/cta/disable'],
  'input/color/text': colorTokens['color/font/primary'],
  'input/color/textDisabled': colorTokens['color/font/secondary'],
  'input/color/textError': colorTokens['color/font/red'],
  'input/color/placeholder': colorTokens['color/font/secondary'],
  'input/height/default': '30px',
  'input/padding/default': `${paddingTokens['padding/veryVerySmall']} ${paddingTokens['padding/default']}`,

  /**
   * InputDatePickerRange
   */

  'inputDatePickerRange/fontSize/default': fontSizeTokens['fontSize/default'],
  'inputDatePickerRange/fontSize/reduced': fontSizeTokens['fontSize/small'],

  /**
   * InputExpression
   */

  'inputExpression/backgroundColor/default':
    colorTokens['color/background/lightGray'],
  'inputExpression/backgroundColor/widgetButton':
    colorTokens['color/cta/secondary'],
  'inputExpression/backgroundColor/widgetButtonDisabled':
    colorTokens['color/cta/disable'],
  'inputExpression/backgroundColor/widgetButtonHover':
    colorTokens['color/cta/secondaryHover'],
  'inputExpression/borderRadius/default':
    borderRadiusTokens['borderRadius/small'],
  'inputExpression/borderColor/default':
    colorTokens['color/brand/decorativeGray'],
  'inputExpression/borderColor/hover':
    colorTokens['color/brand/decorativeGray'],
  'inputExpression/color/textSecondary': colorTokens['color/font/secondary'],
  'inputExpression/color/widgetButtonIcon': colorTokens['color/cta/primary'],
  'inputExpression/color/widgetButtonIconHover':
    colorTokens['color/cta/primary'],

  /**
   * InputSearch
   */

  'inputSearch/backgroundColor/default':
    colorTokens['color/background/lightGray'],
  'inputSearch/color/closeIcon': colorTokens['color/font/secondary'],
  'inputSearch/padding/default': `${paddingTokens['padding/veryVerySmall']} ${paddingTokens['padding/default']}`,

  /**
   * Label
   */

  'label/color/text': colorTokens['color/font/primary'],
  'label/color/textError': colorTokens['color/font/red'],
  'label/color/textSuccess': colorTokens['color/font/green'],
  'label/fontWeight/p': fontWeightTokens['fontWeight/normal'],
  'label/fontSize/p': '13px',
  'label/lineHeight/p': '19.5px',

  /**
   * LabelChecker
   */

  'labelChecker/backgroundColor/default': colorTokens['color/background/white'],
  'labelChecker/backgroundColor/disabled': colorTokens['color/cta/disable'],
  'labelChecker/backgroundColor/hover': colorTokens['color/cta/secondaryHover'],
  'labelChecker/backgroundColor/selectable': colorTokens['color/cta/secondary'],
  'labelChecker/borderColor/default': colorTokens['color/brand/decorativeGray'],
  'labelChecker/color/default': colorTokens['color/font/primary'],
  'labelChecker/color/disabled': colorTokens['color/font/secondary'],

  /**
   * Layout
   */

  'layout/tul/height': 64,
  'layout/tul-v5/height': 78,
  'layout/header/background': colorTokens['color/background/white'],
  'layout/body/background': colorTokens['color/background/lightGray'],
  'layout/color': colorTokens['color/font/primary'],

  /**
   * MainMenu
   */

  'mainMenu/color/background': colorTokens['color/background/white'],
  'mainMenu/padding/default': `70px ${paddingTokens['padding/medium']} ${paddingTokens['padding/medium']}`,
  'mainMenu/width/default': '70px',
  'mainMenu/group/border-radius/default': '0',
  'mainMenu/group/color/background': colorTokens['color/background/white'],
  'mainMenu/link/border-radius': borderRadiusTokens['borderRadius/medium'],
  'mainMenu/link/color/background': colorTokens['color/background/lightGray'],
  'mainMenu/link/color/backgroundHover': colorTokens['color/brand/blue1'],
  'mainMenu/link/color/backgroundActive': colorTokens['color/brand/blue1'],
  'mainMenu/link/color/text': colorTokens['color/font/primary'],
  'mainMenu/link/color/textHover': colorTokens['color/font/white'],
  'mainMenu/link/color/textActive': colorTokens['color/font/white'],
  'mainMenu/link/padding/default': paddingTokens['padding/default'],
  'mainMenu/menuEntry/width/default': '40px',
  'mainMenu/menuEntry/width/expanded': '215px',
  'mainMenu/menuEntry/width/expandedWithoutPadding': '195px',

  /**
   * Menu
   */

  'menu/backgroundColor/default': 'transparent',
  'menu/color/border': colorTokens['color/brand/decorativeGray'],
  'menu/color/font': colorTokens['color/font/primary'],
  'menu/style/border': `2px solid ${colorTokens['color/brand/decorativeGray']}`,

  /**
   * Modal
   */

  'modal/color/overlay': colorTokens['color/overlay/default'],
  'modal/color/separator': colorTokens['color/brand/decorativeGray'],

  /**
   * Select
   */

  'select/backgroundColor/default': colorTokens['color/background/lightGray'],
  'select/backgroundColor/disabled': colorTokens['color/cta/disable'],
  'select/border/default': 'none',
  'select/borderRadius/default': borderRadiusTokens['borderRadius/small'],
  'select/boxShadow/focus': 'none',
  'select/color/icon': colorTokens['color/font/primary'],
  'select/color/iconDisabled': colorTokens['color/font/secondary'],
  'select/color/placeholder': colorTokens['color/font/secondary'],
  'select/color/text': colorTokens['color/font/secondary'],
  'select/color/textDisabled': colorTokens['color/font/secondary'],
  'select/color/textSelected': colorTokens['color/font/primary'],

  /**
   * Spinner
   */

  'spinner/color/default': colorTokens['color/cta/primary'],

  /**
   * Table
   */

  'table/backgroundColor/evenRow': colorTokens['color/background/white'],
  'table/backgroundColor/oddRow': colorTokens['color/background/lightGray'],
  'table/backgroundColor/rowHover': colorTokens['color/brand/decorativeGray'],
  'table/borderBottom/row': `1px solid ${colorTokens['color/brand/decorativeGray']}`,
  'table/padding/row': `0 ${paddingTokens['padding/large']}`,

  /**
   * Tag
   */

  'tag/color/background': colorTokens['color/brand/blue6'],
  'tag/color/backgroundHover': colorTokens['color/cta/primaryHover'],
  'tag/color/text': colorTokens['color/font/white'],
  'tag/color/beta': colorTokens['color/font/beta'],
  'tag/color/beta/borderRadius': colorTokens['color/font/beta'],
  'tag/color/beta/background': colorTokens['color/background/greenLight'],

  /**
   * TagExpression
   */

  'tagExpression/backgroundColor/default': colorTokens['color/cta/primary'],
  'tagExpression/backgroundColor/white': colorTokens['color/cta/secondary'],
  'tagExpression/color/default': colorTokens['color/font/white'],
  'tagExpression/color/transparent': colorTokens['color/font/primary'],
  'tagExpression/color/white': colorTokens['color/cta/primary'],

  /**
   * TrailFlowSearchEntry
   */

  'trailFlowSearchEntry/backgroundColor/default':
    colorTokens['color/background/lightGray'],
  'trailFlowSearchEntry/backgroundColor/hover':
    colorTokens['color/brand/decorativeGray'],
  'trailFlowSearchEntry/boxShadow/hover': 'none',

  /**
   * Tree
   */

  'tree/color/hover': colorTokens['color/background/lightGray'],

  /**
   * TooltipInfo
   */

  'tooltipInfo/color/icon': colorTokens['color/cta/primary']
}

export const defaultTheme = {
  tokens,
  sizes
}
