import { ContainerFlex } from '@app/components-legacy/Container'
import { IconPNG } from '@app/components-legacy/Icon'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import * as React from 'react'

export interface ILoginSAMLFormProps {
  redirectSAMLUrl: string
}

const LoginSAMLForm: React.FC<ILoginSAMLFormProps> = props => {
  const { testAttributeProps } = useTestAttribute('form')

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Login', 'Login.Labels', 'Login.Errors']
  })

  return (
    <a
      data-name="LoginSAMLForm"
      href={props.redirectSAMLUrl}
      {...testAttributeProps('link')('server')}
    >
      <ContainerFlex
        name="Link"
        items={[
          <div>
            {translate("Authenticate via your organization's SAML server")}
          </div>,

          <IconPNG iconName="icons/export_material@2x" labelledBy="samlLink" />
        ]}
        spaced
        spaceWidth="verySmall"
      />
    </a>
  )
}

export default LoginSAMLForm
