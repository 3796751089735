import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useAppTranslator } from '@app/hooks'
import { useStores } from '@app/hooks/useStores'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import TrailFlowSearchEntry from '../../TrailFlowSearchEntry'
import { onTrailFlowSearchHistorySelection } from '../DrawerHistory/handlers'

export interface IDrawerBookmarksProps {}

const DrawerBookmarks: React.FC<IDrawerBookmarksProps> = () => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const translate = useAppTranslator({
    namespaces: ['TrailFlow.SearchBookmarks']
  })

  const { storeTrailFlow } = useStores()
  const { storeSearchBookmarks } = storeTrailFlow

  const getBookmarksEntries = (): React.ReactNodeArray => {
    return storeSearchBookmarks.searchBookmarksEntriesFiltered.map(
      entitySearchBookmark => {
        if (
          !entitySearchBookmark.expression ||
          !entitySearchBookmark.expressionObject
        ) {
          return
        }

        return (
          <TrailFlowSearchEntry
            key={entitySearchBookmark.id}
            expressionObject={entitySearchBookmark.expressionObject}
            name={entitySearchBookmark.name}
            onClick={onTrailFlowSearchHistorySelection(storeTrailFlow)(
              entitySearchBookmark.expression
            )}
          />
        )
      }
    )
  }

  const getContent = (): React.ReactNode => {
    if (!storeSearchBookmarks.searchBookmarksEntriesFiltered.length) {
      return themeIsLegacy ? (
        <>{translate('No bookmark found')}</>
      ) : (
        <Label variant={LabelVariant.p}>{translate('No bookmark found')}</Label>
      )
    }

    return (
      <ContainerFlex
        name="DrawerBookmarks"
        direction="column"
        items={getBookmarksEntries()}
        spaced
        spaceWidth="veryVerySmall"
      />
    )
  }

  return (
    <ContainerContent
      flags={[
        storeSearchBookmarks.storeFlagsSearchBookmarks.flags,
        storeSearchBookmarks.storeFlagsCreateSearchBookmarksEntry.flags,
        storeSearchBookmarks.storeFlagsDeleteSearchBookmarksEntry.flags,
        storeSearchBookmarks.storeFlagsEditSearchBookmarksEntry.flags
      ]}
      spinner={<SpinnerInline />}
    >
      {getContent()}
    </ContainerContent>
  )
}

export default observer(DrawerBookmarks)
