import { Features } from '@alsid/common'
import {
  IconActivity,
  IconMegaphone,
  IconSignOut,
  IconUser
} from '@app/components/Icon'
import { ContainerRbac } from '@app/components-legacy/Container'
import { useAppRouter, useAppTranslator, useStores } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import type { IMainMenuEntry } from '@app/pages/MainMenu/types'
import { MainMenuKey } from '@app/pages/MainMenu/types'
import { canAccessToUserActivityLogs } from '@app/pages/Management/AccountsPage/ActivityLogs/permissions'
import { canAccessToPreferences } from '@app/pages/Preferences/permissions'
import { AppRouteName } from '@app/routes'
import { MenuEntryType } from '@app/stores/helpers/StoreMenu/types'
import { filterFalsies } from '@libs/filterFalsies'
import * as React from 'react'
import styled from 'styled-components'
import WidgetMenuLink from '../WidgetMenuLink'
import WidgetProfileSelector from '../WidgetProfileSelector'
import { canSelectProfiles } from '../WidgetProfileSelector/permissions'
import WidgetUserHeader from './WidgetUserHeader'

const MenuItem = styled.div``

const RowProfile = styled.div`
  padding: 15px 20px 0px;
`

interface IWidgetUserPopoverProps {
  className?: string
  hidePopover: () => void
}

const WidgetUserPopover: React.FC<IWidgetUserPopoverProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Layout.MainMenu'] })

  const { storeAbout, storeAuthentication, storeRbac } = useStores()

  const isTulEnabled = useFeatureFlag({
    featureFlagName: Features.TENABLE_UNIVERSAL_LAYOUT
  })

  const appRouter = useAppRouter()

  const deploymentType = storeAbout.isSaaSDeployment ? 'saas' : 'onprem'

  const currentYear = new Date().getFullYear()

  const whatsNewUrl = `https://docs.tenable.com/release-notes/Content/identity-exposure/${deploymentType}/${currentYear}.htm`

  const userSubNavigationEntries: IMainMenuEntry[] =
    filterFalsies<IMainMenuEntry>([
      {
        key: MainMenuKey.whatsNew,
        type: MenuEntryType.externalLink,
        label: translate('Whats new'),
        labelledBy: 'whatsNew',
        href: whatsNewUrl,
        icon: IconMegaphone
      },
      !isTulEnabled &&
        storeRbac.isUserGrantedTo(
          canAccessToUserActivityLogs(storeAuthentication)
        ) && {
          key: MainMenuKey.activityLogs,
          type: MenuEntryType.link,
          label: translate('Activity Logs'),
          labelledBy: 'ActivityLogs',
          routeDefinition: {
            routeName: AppRouteName.ActivityLogs,
            parameters: {}
          },
          icon: IconActivity
        },
      storeRbac.isUserGrantedTo(canAccessToPreferences) && {
        key: MainMenuKey.preferences,
        type: MenuEntryType.link,
        label: translate('My Account'),
        labelledBy: 'myAccount',
        routeDefinition: {
          routeName: AppRouteName.Preferences,
          parameters: {}
        },
        icon: IconUser
      },
      {
        key: MainMenuKey.logout,
        type: MenuEntryType.link,
        label: translate('Logout'),
        labelledBy: 'Logout',
        // use href to make a hard-redirection to the Kapteyn auth middleware
        // logout route, that will delete the session cookie
        href: appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.MiddlewareAuth_Logout,
          parameters: {}
        }),
        icon: IconSignOut
      }
    ])

  return (
    <div className={props.className}>
      <MenuItem>
        <WidgetUserHeader hidePopover={props.hidePopover} />
      </MenuItem>
      <MenuItem>
        <RowProfile>
          <ContainerRbac rbacCapabilityCheck={canSelectProfiles}>
            <WidgetProfileSelector />
          </ContainerRbac>
        </RowProfile>
      </MenuItem>
      {userSubNavigationEntries.map(navigationEntry => (
        <MenuItem key={navigationEntry.key}>
          <WidgetMenuLink
            hidePopover={props.hidePopover}
            navigationEntry={navigationEntry}
          />
        </MenuItem>
      ))}
    </div>
  )
}

export default styled(WidgetUserPopover)`
  width: 282px;
`
