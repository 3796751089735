import { RbacEntityName } from '@server/graphql/typeDefs/types'

/**
 * List RbacEntityName that are selfUser entities
 * (read permission can't be changed)
 */
export const selfUserRbacEntityNames: RbacEntityName[] = [
  RbacEntityName.Dashboard,
  RbacEntityName.Widget,
  RbacEntityName.Preference,
  RbacEntityName.ApiKey,
  RbacEntityName.SelfUser
]

/**
 * List RbacEntityName that are singleton entities
 * (create permission can't be changed)
 */
export const singletonRbacEntityNames: RbacEntityName[] = [
  RbacEntityName.Topology,
  RbacEntityName.ApplicationSetting,
  RbacEntityName.LockoutPolicy,
  RbacEntityName.LdapConfiguration,
  RbacEntityName.SamlConfiguration,
  RbacEntityName.Preference,
  RbacEntityName.ApiKey,
  RbacEntityName.Score,
  RbacEntityName.License,
  RbacEntityName.DirectoryRecrawl,
  RbacEntityName.SelfActivityLogs,
  RbacEntityName.DataCollectionConfiguration,
  RbacEntityName.HealthCheck
]

/**
 * Rbac entity names (Enum for Kapteyn) for which the API (/rbac/entities)
 * doesn't return entities.
 */
export const notApplicableRbacEntityNames: RbacEntityName[] = [
  RbacEntityName.AdObject,
  RbacEntityName.AdObjectDevianceHistory,
  RbacEntityName.Alert,
  RbacEntityName.Deviance,
  RbacEntityName.Event,
  RbacEntityName.CheckerOption,
  RbacEntityName.SelfUser,
  RbacEntityName.SelfActivityLogs,
  RbacEntityName.Attack,
  RbacEntityName.AttackAlert,
  RbacEntityName.AttackTypeOption,
  RbacEntityName.LicenseFeatureIoe,
  RbacEntityName.LicenseFeatureIoa,
  RbacEntityName.Unknown
]
