import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface ITypographyPDFTitleProps {
  className?: string
  children?: React.ReactNode
}

const StyledH3 = styled.h3`
  font-size: ${consts.textSizeExtraExtraLarge};
  font-weight: ${consts.fontWeightBold};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: ${consts.paddingXXL};
`

const TypographyPDFTitle: React.FC<ITypographyPDFTitleProps> = props => {
  return (
    <StyledH3 data-name="TypographyPDFTitle" className={props.className}>
      {props.children}
    </StyledH3>
  )
}

export default TypographyPDFTitle
