import type { IWidgetListBulkActionOption } from '@app/components-legacy/Widgets/WidgetList/types'
import type StoreAttacks from '@app/stores/IoA/StoreAttacks'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import {
  closeOrReopenAttacksBulk,
  closeOrReopenSelectedAttacks
} from './functions'
import { AttackBulkActionValue } from './types'

/**
 * Do the action according to the selection option.
 */
export const onAttacksBulkActionSelection =
  (storeAttacks: StoreAttacks) =>
  (option: IWidgetListBulkActionOption): void => {
    const value = option.value as AttackBulkActionValue

    switch (value) {
      case AttackBulkActionValue.close: {
        storeAttacks.storeModalConfirmCloseAction.show()
        return
      }

      case AttackBulkActionValue.reopen: {
        const { storeWidgetListAttacks } = storeAttacks

        storeWidgetListAttacks.isAllRowsSelected
          ? closeOrReopenAttacksBulk(storeAttacks)({ isClosed: false })
          : closeOrReopenSelectedAttacks(storeAttacks)({ isClosed: false })

        return
      }

      case AttackBulkActionValue.remove: {
        storeAttacks.storeDrawerAttacksFiltersAdd.openDrawer()
        return
      }

      default:
        assertUnreachableCase(value)
    }
  }

/**
 * Open the attacks remove filters drawer.
 */
export const onAttacksShowRemoveFiltersClick =
  (storeAttacks: StoreAttacks) => () => {
    storeAttacks.storeAttackRemoveFilters.storeDrawer.openDrawer()
  }
