import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppTranslator, useStores } from '@app/hooks'
import { grant } from '@libs/rbac/permissions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerContent from './DrawerContent'
import DrawerHeader from './DrawerHeader'
import { onTrailFlowSearchBookmarksUnload } from './handlers'

export interface ISearchBookmarksProps {
  className?: string
}

const SearchBookmarks: React.FC<ISearchBookmarksProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['TrailFlow.SearchBookmarks']
  })

  const { storeTrailFlow } = useStores()

  return (
    <DrawerHorizontalContainer
      variant="large"
      onUnload={onTrailFlowSearchBookmarksUnload(
        storeTrailFlow.storeSearchBookmarks
      )}
    >
      <BladeContentLayoutDefault
        layout={{
          name: 'default',
          title: <DrawerTitle>{translate('Bookmarks')}</DrawerTitle>,
          header: <DrawerHeader />,
          content: <DrawerContent />,
          footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
        }}
        rbacCapabilityCheck={grant()}
      />
    </DrawerHorizontalContainer>
  )
}

export default observer(SearchBookmarks)
