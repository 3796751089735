import { sliceString } from '@libs/sliceString'
import { Popover } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import CellJSONTooltipContent from './CellJSONTooltipContent'

export interface ICellBodyProps {
  className?: string
  payload?: any
}

const CellBody: React.FC<ICellBodyProps> = props => {
  const renderContent = () => {
    if (!props.payload) {
      return null
    }

    try {
      let jsonStr = JSON.stringify(props.payload, null, 2)

      // FIXME
      jsonStr = jsonStr.replace('\\n', '\n')

      return (
        <Popover
          placement="topLeft"
          content={<CellJSONTooltipContent>{jsonStr}</CellJSONTooltipContent>}
          arrowPointAtCenter
        >
          <code>{sliceString(jsonStr, { maxLength: 20 })}</code>
        </Popover>
      )
    } catch (err) {
      return <div>Unable to parse</div>
    }
  }

  return <div className={props.className}>{renderContent()}</div>
}

export default styled(CellBody)`
  width: 200px !important;
`
