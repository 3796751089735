import type { Perhaps } from '@@types/helpers'
import {
  createEntities,
  createEntity,
  EntityPagination,
  EntityReason
} from '@app/entities'
import EntityAdObjectAsDeviantObject from '@app/entities/EntityAdObject/EntityAdObjectAsDeviantObject'
import type {
  AdObjectId,
  IDataRowAdObjectAsDeviantObject
} from '@app/entities/EntityAdObject/types'
import type { StoreRoot } from '@app/stores'
import { StoreInfrastructures, StoreInputReasons } from '@app/stores'
import StoreDatePicker from '@app/stores/helpers/StoreDatePicker'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreForm from '@app/stores/helpers/StoreForm'
import StoreInputExpression from '@app/stores/helpers/StoreInputExpression'
import StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { DateFormat, formatDate } from '@libs/dates/formatDate'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import { checkRbac, isGrantedEntity } from '@libs/rbac/functions'
import type {
  MutationEditAdObjectDeviances,
  MutationEditCheckerDeviances,
  MutationEditDeviances
} from '@server/graphql/mutations/deviance'
import {
  mutationEditAdObjectDeviances,
  mutationEditCheckerDeviances,
  mutationEditDeviances
} from '@server/graphql/mutations/deviance'
import type { QueryCheckerReasons } from '@server/graphql/queries/checker'
import { queryCheckerReasons } from '@server/graphql/queries/checker'
import type { QueryCheckerDeviantObjects } from '@server/graphql/queries/deviance'
import { queryCheckerDeviantObjects } from '@server/graphql/queries/deviance'
import type {
  AdObject,
  EditAdObjectDeviancesMutationArgs,
  EditCheckerDeviancesMutationArgs,
  EditDeviancesMutationArgs,
  InputEditAdObjectDeviances,
  InputEditCheckerDeviances,
  InputEditDeviance,
  Maybe,
  Pagination,
  RbacCheckersQueryArgs,
  RbacDeviantAdObjectsQueryArgs,
  Reason
} from '@server/graphql/typeDefs/types'
import { flatMap } from 'lodash'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import StoreIndicatorDeviantObjectDeviances from './StoreIndicatorDeviantObjectDeviances'
import type { IDrawerIgnoreUntilData } from './types'
import { filterReasonsForChecker } from './utils'

export default class StoreIndicatorDeviantObjects extends StoreBase {
  public storeInputExpression = new StoreInputExpression(this.storeRoot, {
    enableIsDeviantSwitch: false
  })
  public storeInfrastructures = new StoreInfrastructures(this.storeRoot)
  public storeInputReasons = new StoreInputReasons(this.storeRoot, {
    // filter reasons of the one of the current checker
    filterReasonsFn: allReasons => {
      const { storeIndicatorDeviantObjects } =
        this.storeRoot.stores.storeIoE.storeIndicator

      return filterReasonsForChecker(
        storeIndicatorDeviantObjects.checkerReasons
      )(allReasons)
    }
  })

  public storeDrawerIgnoreObjets = new StoreDrawer<IDrawerIgnoreUntilData>(
    this.storeRoot
  )
  public storeDrawerUnignoreObjets = new StoreDrawer<IDrawerIgnoreUntilData>(
    this.storeRoot
  )
  public storeDrawerExportObjects = new StoreDrawer(this.storeRoot)

  public storeFormIgnoreObjects = new StoreForm(this.storeRoot)

  public storeFlagsLoadDeviantObjects = new StoreFlags(this.storeRoot)
  public storeFlagsLoadCheckerReasons = new StoreFlags(this.storeRoot)
  public storeFlagsReLoadDeviantObjects = new StoreFlags(this.storeRoot)
  public storeFlagsEditDeviances = new StoreFlags(this.storeRoot)

  public storeDatePicker = new StoreDatePicker(this.storeRoot, {
    defaultDateStart: null,
    defaultDateEnd: null
  })

  public storeWidgetListDeviantObjects = new StoreWidgetList<
    EntityAdObjectAsDeviantObject,
    IDataRowAdObjectAsDeviantObject
  >(this.storeRoot, {
    // build dataSets with IDataRowDeviantAdObject rows
    buildDataSetFn: entities => {
      if (!entities.length) {
        return {
          columns: [],
          rows: []
        }
      }

      const columns = entities[0].getColumns()
      const rows = entities.map(entity => entity.asDataRow()).filter(isDefined)

      return { columns, rows }
    }
  })

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['IoE.Details.DeviantObjects']
  })

  // reasons of the current checker, needed to filter reasons in the StoreInputReasons
  public checkerReasons: EntityReason[] = []

  /**
   * Observables
   */

  // one store by adObject 'opened' (toggleable rows on the interface)
  private $storesIndicatorDeviantObjectDeviances = observable.map<
    AdObjectId,
    StoreIndicatorDeviantObjectDeviances
  >()

  private $showIgnoredStatus = observable.box<boolean>(false)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch checker reasons and save them in the store.
   * It will be used to filter all reasons when opening the InputReasons drawer.
   */
  fetchCheckerReasons(args: RbacCheckersQueryArgs): Promise<any> {
    this.storeFlagsLoadCheckerReasons.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryCheckerReasons>(queryCheckerReasons, args)
      })
      .then(data => data.rbacCheckers)
      .then(rbacCheckers => {
        if (!checkRbac(this.storeRoot)(rbacCheckers)) {
          throw new ForbiddenAccessError()
        }

        const reasons = flatMap(
          rbacCheckers.node
            .map(checker => {
              return isGrantedEntity(checker.rbacReasons)
                ? checker.rbacReasons.node
                : null
            })
            .filter(isDefined)
        )

        this.checkerReasons = createEntities<Reason, EntityReason>(
          EntityReason,
          reasons
        )

        this.storeFlagsLoadCheckerReasons.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsLoadCheckerReasons, {
          forwardExceptionFn: () =>
            'An error has occurred when editing the deviances of the indicator'
        })
      )
  }

  /**
   * Fetch infras then deviant objects.
   */
  fetchDeviantObjects(args: RbacDeviantAdObjectsQueryArgs): Promise<any> {
    this.storeFlagsLoadDeviantObjects.loading()

    return Promise.resolve()
      .then(() => this._fetchDeviantObjects(args))
      .then(() => this.storeFlagsLoadDeviantObjects.success())
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsLoadDeviantObjects)
      )
  }

  /**
   * Reload deviant objects.
   */
  reloadDeviantObjects(args: RbacDeviantAdObjectsQueryArgs): Promise<any> {
    this.storeFlagsReLoadDeviantObjects.loading()

    return Promise.resolve()
      .then(() => this._fetchDeviantObjects(args))
      .then(() => this.storeFlagsReLoadDeviantObjects.success())
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsReLoadDeviantObjects)
      )
  }

  /**
   * Reset pagination and reload deviant objects.
   */
  searchDeviantObjects(args: RbacDeviantAdObjectsQueryArgs): Promise<void> {
    return this.reloadDeviantObjects({ ...args, adObjectsPage: 1 })
  }

  /**
   * Compute args to fetch deviant objects.
   */
  computeFetchDeviantObjectsArgs(
    checkerCodename: string,
    customArgs?: Partial<RbacDeviantAdObjectsQueryArgs>
  ): Maybe<RbacDeviantAdObjectsQueryArgs> {
    const { storeIoE, storeAuthentication } = this.storeRoot.stores

    // get current profile id
    const currentProfile = storeAuthentication.currentProfile
    const profileId = currentProfile && currentProfile.id

    if (!profileId) {
      return null
    }

    // get checker id
    const checker = storeIoE.checkers.get(checkerCodename)
    const checkerId = checker && checker.id

    if (!checkerId) {
      return null
    }

    let baseArgs: RbacDeviantAdObjectsQueryArgs = {
      profileId,
      checkerId,
      directoryIds: this.storeInfrastructures.getSelectedDirectoryIds(),
      reasonIds: this.storeInputReasons.selectedReasonIds,
      showIgnored: this.showIgnoredStatus,
      adObjectsExpression:
        this.storeInputExpression.expression.expressionAsStringyfiedObject,
      adObjectsPage: this.storeWidgetListDeviantObjects.paginationPage,
      adObjectsPerPage: this.storeWidgetListDeviantObjects.rowsPerPage
    }

    const dates = this.storeDatePicker.datePickerRangeValueAsMoments

    if (dates && dates.dateStart && dates.dateEnd) {
      const { dateStart, dateEnd } = dates

      baseArgs = {
        ...baseArgs,
        dateStart: dateStart.toISOString(),
        dateEnd: dateEnd.toISOString()
      }
    }

    return { ...baseArgs, ...customArgs }
  }

  /**
   * Edit all the deviances of a checker.
   */
  editCheckerDeviances(deviances: InputEditCheckerDeviances): Promise<any> {
    this.storeFlagsEditDeviances.loading()

    return Promise.resolve()
      .then(() => {
        const args: EditCheckerDeviancesMutationArgs = {
          deviances
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationEditCheckerDeviances>(
            mutationEditCheckerDeviances,
            args
          )
      })
      .then(results => {
        if (!results) {
          throw new Error('The query has failed')
        }

        this.storeRoot.stores.storeMessages.success(
          this.getIgnoreUntilSuccessMessage(deviances.ignoreUntil),
          {
            html: true,
            customIcon: 'success',
            labelledBy: 'deviancesIgnoreUntil'
          }
        )

        this.storeFlagsEditDeviances.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsEditDeviances, {
          forwardExceptionFn: () =>
            'An error has occurred when editing the deviances of the indicator'
        })
      )
  }

  /**
   * Edit all the deviances of an AdObject.
   */
  editAdObjectDeviances(deviances: InputEditAdObjectDeviances[]): Promise<any> {
    if (!deviances.length) {
      return Promise.resolve()
    }

    const ignoreUntil = deviances[0].ignoreUntil

    this.storeFlagsEditDeviances.loading()

    return Promise.resolve()
      .then(() => {
        const args: EditAdObjectDeviancesMutationArgs = {
          deviances
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationEditAdObjectDeviances>(
            mutationEditAdObjectDeviances,
            args
          )
      })
      .then(results => {
        const isAllFailed = results.editAdObjectDeviances.some(deviance =>
          isDefined(deviance.err)
        )

        if (isAllFailed) {
          throw new Error('All queries have failed')
        }

        const isAllSucceeded = results.editAdObjectDeviances.every(
          deviance => !isDefined(deviance.err)
        )

        if (isAllSucceeded) {
          this.storeRoot.stores.storeMessages.success(
            this.getIgnoreUntilSuccessMessage(ignoreUntil),
            {
              html: true,
              customIcon: 'success',
              labelledBy: 'deviancesIgnoreUntil'
            }
          )
        }

        const isSomeFailed = results.editAdObjectDeviances.some(deviance =>
          isDefined(deviance.err)
        )

        if (isSomeFailed) {
          this.storeRoot.stores.storeMessages.warning(
            this.translate(
              'Tenable.ad did not update certain deviances due to some errors'
            ),
            {
              labelledBy: 'deviancesNotUpdated'
            }
          )
        }

        this.storeFlagsEditDeviances.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsEditDeviances, {
          forwardExceptionFn: () =>
            'An error has occurred when editing the deviances of the object'
        })
      )
  }

  /**
   * Edit one or several deviances.
   */
  editDeviances(deviances: InputEditDeviance[]): Promise<any> {
    if (!deviances.length) {
      return Promise.resolve()
    }

    const ignoreUntil = deviances[0].ignoreUntil

    this.storeFlagsEditDeviances.loading()

    return Promise.resolve()
      .then(() => {
        const args: EditDeviancesMutationArgs = {
          deviances
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationEditDeviances>(mutationEditDeviances, args)
      })
      .then(results => {
        const isAllFailed = results.editDeviances.some(deviance =>
          isDefined(deviance.err)
        )

        if (isAllFailed) {
          throw new Error('All queries have failed')
        }

        const isAllSucceeded = results.editDeviances.every(
          deviance => !isDefined(deviance.err)
        )

        if (isAllSucceeded) {
          this.storeRoot.stores.storeMessages.success(
            this.getIgnoreUntilSuccessMessage(ignoreUntil),
            {
              html: true,
              customIcon: 'success',
              labelledBy: 'deviancesIgnoreUntil'
            }
          )
        }

        const hasFailures = results.editDeviances.some(deviance =>
          isDefined(deviance.err)
        )

        if (hasFailures) {
          this.storeRoot.stores.storeMessages.warning(
            this.translate(
              'Tenable.ad did not update certain deviances due to some errors'
            ),
            {
              labelledBy: 'deviancesNotUpdated'
            }
          )
        }

        this.storeFlagsEditDeviances.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsEditDeviances, {
          forwardExceptionFn: () =>
            'An error has occurred when editing the deviances of the object'
        })
      )
  }

  /**
   * Fetch deviant objects.
   */
  private _fetchDeviantObjects(
    args: RbacDeviantAdObjectsQueryArgs
  ): Promise<void> {
    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryCheckerDeviantObjects>(queryCheckerDeviantObjects, args)
      })
      .then(data => data.rbacDeviantAdObjects)
      .then(deviantObjects => {
        // explicit handle of 404s that is considered as an empty list of deviances
        if (!deviantObjects.rbacCapability.isFound) {
          this.storeWidgetListDeviantObjects.setEntities([])
          this.storeWidgetListDeviantObjects.resetPagination()
          return
        }

        if (!checkRbac(this.storeRoot)(deviantObjects)) {
          throw new ForbiddenAccessError()
        }

        const deviantObjectsEntities = createEntities<
          AdObject,
          EntityAdObjectAsDeviantObject
        >(EntityAdObjectAsDeviantObject, deviantObjects.node.node)

        const deviantObjectsPagination = createEntity<
          Pagination,
          EntityPagination
        >(EntityPagination, deviantObjects.node.pagination)

        this.storeWidgetListDeviantObjects.setEntities(deviantObjectsEntities)
        this.storeWidgetListDeviantObjects.setPagination(
          deviantObjectsPagination
        )
      })
  }

  /**
   * Return the success message according to the context.
   */
  private getIgnoreUntilSuccessMessage(ignoreUntil: Perhaps<string>): string {
    return isDefined(ignoreUntil)
      ? this.translate(
          'Tenable.ad will ignore the selected deviances until X',
          {
            interpolations: {
              date: formatDate(ignoreUntil, {
                utc: true,
                format: DateFormat.verbose
              })
            },
            transformMarkdown: true
          }
        )
      : this.translate('Tenable.ad stopped ignoring the selected deviances')
  }

  /* Actions */

  @action
  reset(): this {
    // reset objects list
    this.storeWidgetListDeviantObjects.reset()

    // reset all deviances lists
    this.$storesIndicatorDeviantObjectDeviances.forEach(store => {
      store.reset()
    })

    // reset filters
    this.storeInputReasons.reset()
    this.storeInputExpression.reset()
    this.storeInfrastructures.reset()
    this.storeDatePicker.reset()

    return this
  }

  @action
  setShowIgnoredStatus(status: boolean): this {
    this.$showIgnoredStatus.set(status)
    return this
  }

  /**
   * Create a new deviances store for an AdObjectId.
   * Used when opening the details of a deviant object.
   */
  @action
  createNewDeviantObjectDeviancesStore(
    adObjectRow: IDataRowAdObjectAsDeviantObject
  ): StoreIndicatorDeviantObjectDeviances {
    const store = new StoreIndicatorDeviantObjectDeviances(this.storeRoot, {
      adObjectRow
    })

    this.$storesIndicatorDeviantObjectDeviances.set(adObjectRow.id, store)

    return store
  }

  /**
   * Delete a deviances store.
   */
  @action
  deleteDeviantObjectDeviancesStore(
    adObjectRow: IDataRowAdObjectAsDeviantObject
  ): this {
    this.$storesIndicatorDeviantObjectDeviances.delete(adObjectRow.id)
    return this
  }

  /* Computed */

  @computed
  get showIgnoredStatus(): boolean {
    return this.$showIgnoredStatus.get()
  }

  @computed
  get storesIndicatorDeviantObjectDeviances(): Map<
    AdObjectId,
    StoreIndicatorDeviantObjectDeviances
  > {
    return toJS(this.$storesIndicatorDeviantObjectDeviances)
  }
}
