import type { StoreActivityLogs } from '@app/stores'

/**
 * Retrieve more user-traces
 */
export const handleSeeMoreLogsOnClick =
  (storeActivityLogs: StoreActivityLogs) => (): void => {
    const nextLink = storeActivityLogs.nextLink
    if (nextLink === null) {
      return
    }
    storeActivityLogs.fetchLogs({ replaceExistingLogs: false })
  }
