import type { StoreTopology } from '@app/stores'

/**
 * Load topology data and more on scene loading.
 */
export const onTopologySceneLoad =
  (storeTopology: StoreTopology) => (profileId: number) => (): void => {
    Promise.all([
      storeTopology.fetchTopology({ profileId }),
      storeTopology.storeInfrastructures.fetchInfrastructures()
    ])
  }
