import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import PortalPlaceHolder from '../Portal/PortalPlaceHolder'
import type { PlaceHolderName } from './types'

export interface IPlaceHolderDrawersVerticalProps {
  className?: string
  name: PlaceHolderName
}

const DivRelativeHook = styled.div`
  position: relative;
`

/**
 * PlaceHolder for vertical generic drawers.
 */
const PlaceHolderDrawersVertical: React.FC<
  IPlaceHolderDrawersVerticalProps
> = props => {
  return (
    <DivRelativeHook>
      <PortalPlaceHolder className={props.className} name={props.name} />
    </DivRelativeHook>
  )
}

export default styled(PlaceHolderDrawersVertical)`
  position: absolute;
  z-index: ${consts.zIndexDrawerVerticalContainer};
  width: 100%;
  top: 0;
  left: 0;
`
