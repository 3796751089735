import type { PropertiesNullable } from '@@types/helpers'
import type {
  Language,
  Maybe,
  Preferences
} from '@server/graphql/typeDefs/types'

export default class EntityPreferences
  implements PropertiesNullable<Preferences>
{
  language: Maybe<Language> = null
  preferredProfileId: Maybe<number> = null

  constructor(data: Partial<Preferences>) {
    Object.assign(this, data)
  }
}
