import { ContainerSimple } from '@app/components-legacy/Container'
import type { AlertProps } from 'antd'
import { Alert } from 'antd'
import * as React from 'react'
import type { IFormWrapperControl } from '../Form/Wrappers/types'

interface IWrapperAlertProps extends IFormWrapperControl {
  alertProps: AlertProps
}

const WrapperAlert: React.FC<IWrapperAlertProps> = props => {
  return (
    <ContainerSimple
      name={`${WrapperAlert.name}-${props.labelledBy}`}
      ariaRoles={props.ariaRoles || ['alert']}
      labelledBy={props.labelledBy}
    >
      <Alert {...props.alertProps} />
    </ContainerSimple>
  )
}

/**
 * @deprecated
 */
export default WrapperAlert
