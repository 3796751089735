import { ContainerFlex } from '@app/components/Container'
import { IconCount, IconLineChart } from '@app/components/Icon'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { HorizontalLine } from '@app/components/Separator'
import { CardSimple } from '@app/components-legacy/Card/CardSimple/CardSimple'
import { useAppTranslator } from '@app/hooks'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import InformationLine from './InformationLine'
import TemplateWrapper from './TemplateWrapper'
import { DashboardTemplate } from './types'

interface IAdRisk360Props {
  children?: React.ReactNode
}

const StyledHorizontalLine = styled(HorizontalLine)`
  margin-top: ${consts.paddingLarge};
  margin-bottom: ${consts.paddingMedium};
`

const AdRisk360: React.FC<IAdRisk360Props> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Dashboard.AddDashboardDrawer',
      'Dashboard.Common',
      'Components.LabelChecker.ComplexityLevel'
    ]
  })

  return (
    <TemplateWrapper
      title={translate('AD risk 360')}
      templateType={DashboardTemplate.adRisk360}
    >
      <ContainerFlex
        name="AdRisk360Template"
        flexGap="verySmall"
        direction="column"
        items={[
          <CardSimple>
            <ContainerFlex
              name="DevianceEvolution"
              flexGap="medium"
              items={[
                <IconLineChart />,
                <ContainerFlex
                  name="DevianceEvolutionContent"
                  flexGap="verySmall"
                  direction="column"
                  items={[
                    <Label variant={LabelVariant.p_bold}>
                      {translate('Deviance evolution')}
                    </Label>,
                    <Label variant={LabelVariant.description}>
                      {translate('Follow the risk evolution over time')}
                    </Label>
                  ]}
                />
              ]}
              itemsFlexShrink={[0, 1]}
            />
          </CardSimple>,

          <CardSimple>
            <ContainerFlex
              name="IssuesByIoESeverity"
              flexGap="medium"
              items={[
                <IconCount />,
                <ContainerFlex
                  name="IssuesByIoESeverityContent"
                  flexGap="verySmall"
                  direction="column"
                  items={[
                    <Label variant={LabelVariant.p_bold}>
                      {translate('Issues by IoE severity')}
                    </Label>,
                    <Label variant={LabelVariant.description}>
                      {translate(
                        'Gain insight into the primary risks that you can easily remediate to reduce your overall risk level'
                      )}
                    </Label>
                  ]}
                />
              ]}
              itemsFlexShrink={[0, 1]}
            />

            <StyledHorizontalLine color={consts.CTAColorV2.disable} />

            <ContainerFlex
              name="CountList"
              flexGap="verySmall"
              direction="column"
              items={[
                <InformationLine title={translate('critical')} />,
                <InformationLine title={translate('high')} />,
                <InformationLine title={translate('medium')} />,
                <InformationLine title={translate('low')} />
              ]}
            />
          </CardSimple>
        ]}
      />
    </TemplateWrapper>
  )
}

export default AdRisk360
