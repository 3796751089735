import { IconZoomReset } from '@app/components/Icon'
import { ContainerFlex } from '@app/components-legacy/Container'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import FormWrapperSlider from '@app/components-legacy/Form/Wrappers/Slider'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  handleAttackPathResetZoomOnClick,
  handleZoomSliderOnChange
} from '../handlers'

interface IZoomSliderProps {
  className?: string
}

const ZoomSlider: React.FC<IZoomSliderProps> = props => {
  const { storeAttackPath } = useStores()

  /**
   * Memoize all the zoomSlider component.
   * It avoids to re-render when chartZoomData change but not the k value.
   */
  const ZoomSliderMemoized = React.useMemo(() => {
    // Multiply by 1000 to have a finer granularity
    const sliderValue = Math.round(storeAttackPath.chartZoomData.k * 1000)
    const sliderMaxValue = Math.round(storeAttackPath.zoomSliderMaxValue * 1000)
    const sliderMinValue = Math.round(storeAttackPath.zoomSliderMinValue * 1000)

    return (
      <ContainerFlex
        name="ZoomSlider"
        className={props.className}
        labelledBy="AttackPathZoomSlider"
        direction="row"
        alignItems="center"
        itemsFlexShrink={[1, 0]}
        items={[
          <FormWrapperSlider
            data-name="slider"
            labelledBy="zoom-slider-button"
            className="sliderWrapper"
            sliderProps={{
              value: sliderValue,
              min: sliderMinValue,
              max: sliderMaxValue,
              tooltip: { open: false },
              onChange: handleZoomSliderOnChange(storeAttackPath)
            }}
          />,

          <FormWrapperButton
            labelledBy="resetZoom"
            variant={ButtonVariant.secondary}
            size={ButtonSize.small}
            icon={IconZoomReset}
            buttonProps={{
              onClick: handleAttackPathResetZoomOnClick(storeAttackPath)
            }}
          />
        ]}
        spaced
        spaceWidth="default"
      />
    )
  }, [
    storeAttackPath.chartZoomData.k,
    storeAttackPath.zoomSliderMaxValue,
    storeAttackPath.zoomSliderMinValue
  ])

  return ZoomSliderMemoized
}

const ObservedZoomSlider = observer(ZoomSlider)

export default styled(ObservedZoomSlider)`
  .ant-slider,
  .sliderWrapper {
    width: 100%;
  }

  .ant-slider .ant-slider-rail {
    background-color: #e1defb;
  }

  .ant-slider .ant-slider-track {
    background: linear-gradient(
      89.31deg,
      #b9dcff 23.79%,
      ${consts.CTAColorV2.primary} 81.15%
    );
  }

  .ant-slider .ant-slider-handle {
    border: 4px solid ${consts.CTAColorV2.primary};
    width: 16px;
    height: 16px;
  }

  .ant-slider:hover .ant-slider-handle {
    border-color: ${consts.CTAColorV2.primary};
  }
`
