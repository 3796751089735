import { Features } from '@alsid/common'
import { BladesContainer } from '@app/components-legacy/Blade'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LayoutDefault } from '@app/components-legacy/Layout'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import * as React from 'react'
import TrailFlowPage from './TrailFlowPage'

interface ITrailFlowProps {}

const TrailFlow: React.FC<ITrailFlowProps> = props => {
  const featureTrailflowNewDesign = useFeatureFlag({
    featureFlagName: Features.TRAILFLOW_NEW_DESIGN
  })

  return (
    <DSThemeProvider
      themeName={
        featureTrailflowNewDesign ? DSThemeName.default : DSThemeName.legacy
      }
    >
      <LayoutDefault
        content={
          <ContainerFlex
            name="TrailFlow"
            direction="row"
            items={[
              <BladesContainer>
                <TrailFlowPage />
              </BladesContainer>
            ]}
          />
        }
      />
    </DSThemeProvider>
  )
}

export default TrailFlow
