import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelDate } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import type { ILabelCheckerProps } from './types'

interface ILabelCheckerDateResolution<GC extends TGenericChecker>
  extends ILabelCheckerProps<GC> {}

function LabelCheckerDateResolution<GC extends TGenericChecker>(
  props: ILabelCheckerDateResolution<GC>
): Maybe<React.ReactElement> {
  const translate = useAppTranslator({
    namespaces: ['Components.LabelChecker']
  })

  if (!props.resolvedAt) {
    return null
  }

  return (
    <ContainerFlex
      name="LabelCheckerDateResolution"
      className={props.className}
      direction="column"
      justifyContent="flex-end"
      flexWrap="wrap"
      items={[
        <div>{translate('Fixed at')}</div>,
        <LabelDate date={props.resolvedAt} />
      ]}
    />
  )
}

export default styled(LabelCheckerDateResolution)`
  color: ${consts.colorGreen005};
  font-size: 0.9em;
  font-weight: normal;
  padding: ${consts.paddingSmall};

  > * {
    text-align: right;
  }
`
