export enum FieldName {
  chartType = 'chartType'
}

export enum ExportFormat {
  pdf = 'pdf',
  csv = 'csv',
  pptx = 'pptx'
}

export enum ExportFormFieldName {
  exportFormat = 'exportFormat',
  exportUtc = 'exportUtc'
}
