import { values } from 'lodash'
import { AttributeWithSDDL } from './types'

export const attributesWithSDDL = values(AttributeWithSDDL).map(s => {
  // attributes are saved in lowercase
  return s.toLowerCase()
})

/**
 * Return true if `attributeName` is in the `attributesWithSDDL` list.
 */
export function isAttributeIsSDDL(attributeName: string): boolean {
  return attributesWithSDDL.indexOf(attributeName.toLowerCase()) > -1
}
