import type { EntityReason } from '@app/entities'
import { indexEntitiesToMap } from '@libs/indexEntitiesToMap'

/**
 * Filter the reasons to keep the one present in checkerReasons.
 */
export function filterReasonsForChecker(checkerReasons: EntityReason[]) {
  return (allReasons: EntityReason[]): EntityReason[] => {
    const indexedCheckerReasons = indexEntitiesToMap<EntityReason, number>(
      checkerReasons,
      'id'
    )

    return allReasons.filter(reason => {
      return indexedCheckerReasons.has(reason.getPropertyAsNumber('id'))
    })
  }
}
