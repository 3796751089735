import { Features } from '@alsid/common'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  accessByFeatureFlag,
  deny,
  grant,
  readAssetOverview
} from '@libs/rbac/permissions'
import type { RbacCapabilityCheck } from '@libs/rbac/types'

/**
 * Main permission checking the Tenable platform is accessible.
 */
export const canAccessTenableCloud: RbacCapabilityCheck = (
  rbacPermissions,
  meta
) => {
  const { storeManagementAzureAD } = meta.storeRbac.storeRoot.stores

  const { isLicenseUpToDate } = meta.storeRbac.storeRoot.stores.storeLicense

  return rbacCapabilityCheckAllOf(
    // Is the license up-to-date?
    isLicenseUpToDate ? grant() : deny(),
    // Check that access and secret keys have been set and Kapteyn server is able to contact Tenable platform
    // TODO: Will need some adjustments for AirGap customers
    storeManagementAzureAD.isTenableCloudApiTokensWorking ? grant() : deny()
  )(rbacPermissions, meta)
}

/**
 * Allow access to GlobalInventory pages.
 */
export const canAccessToIdentities: RbacCapabilityCheck = (
  rbacPermissions,
  meta
) => {
  const isDebugEnabledForGlobalInventory =
    meta.storeRbac.storeRoot.environment.config.globalinventory?.debug
      ?.enabled === true

  // bypass all Rbac conditions if debug
  if (isDebugEnabledForGlobalInventory) {
    return grant()(rbacPermissions, meta)
  }

  return rbacCapabilityCheckAllOf(
    readAssetOverview(),
    accessByFeatureFlag(Features.GLOBAL_INVENTORY_IDENTITIES)()
  )(rbacPermissions, meta)
}

/**
 * Allow access to APA if APA is enabled and available in the workspace panel
 */
export const canAccessToApa: RbacCapabilityCheck = (rbacPermissions, meta) => {
  const apaApp =
    meta.storeRbac.storeRoot.tulApi?._storeRoot.stores.storeLayout.apps.get(
      'apa'
    )

  if (!apaApp) {
    return deny()(rbacPermissions, meta)
  }

  return rbacCapabilityCheckAllOf(
    apaApp.isEnabled() && !apaApp.isUnavailable() ? grant() : deny()
  )(rbacPermissions, meta)
}
