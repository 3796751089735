import * as React from 'react'
import { ContainerIcon } from '../Container'

interface IIconBlankProps {}

const IconBlank: React.FC<IIconBlankProps> = props => {
  return <ContainerIcon labelledBy="blank" />
}

export default IconBlank
