import type { PropertiesNullable } from '@@types/helpers'
import EntityAttackVector from '@app/entities/EntityAttackVector'
import EntityBase from '@app/entities/EntityBase'
import type {
  IEntityExportable,
  IEntityExportableAsCsvColumnsParameters,
  IEntityExportableAsCsvRowParameters,
  IEntityListable
} from '@app/entities/types'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import { sanitizeCsvValue } from '@libs/csv-protector'
import { indexEntitiesToMap } from '@libs/indexEntitiesToMap'
import type {
  Attack,
  AttackDestination,
  AttackSource,
  AttackVector,
  Criticity,
  Maybe,
  Resource
} from '@server/graphql/typeDefs/types'
import type { EntityAttackType } from '.'
import { createEntity } from '.'
import EntityAttackDestination from './EntityAttackDestination'
import EntityAttackSource from './EntityAttackSource'
import type { AttackTypeName } from './EntityGenericChecker/types'

export interface IDataRowAttack {
  id: Maybe<number>
  date: Maybe<string>
  source: Maybe<AttackSource>
  destination: Maybe<AttackDestination>
  vector: Maybe<AttackVector>
  isClosed: Maybe<boolean>

  attackTypeId: Maybe<number>
  attackTypeName: Maybe<AttackTypeName>
  attackTypeDescription: Maybe<string>
  attackTypeCriticity: Maybe<Criticity>
  attackTypeMitreAttackDescription: Maybe<string>
  attackTypeResources: Maybe<Resource[]>
  attackTypeYaraRules: Maybe<string>
  attackTypeVectorTemplate: Maybe<string>
}

/**
 * Used for attacks CSV exports.
 */
export interface IDataRowAttackExportable {
  id: Maybe<number>
  date: Maybe<string>
  vector: Maybe<string>
  sourceHostname: Maybe<AttackSource['hostname']>
  sourceIP: Maybe<AttackSource['ip']>
  sourceType: Maybe<AttackSource['type']>
  destinationHostname: Maybe<AttackDestination['hostname']>
  destinationIP: Maybe<AttackDestination['ip']>
  destinationType: Maybe<AttackDestination['type']>
}

export default class EntityAttack
  extends EntityBase
  implements
    PropertiesNullable<Attack>,
    IEntityListable<IDataRowAttack>,
    IEntityExportable<IDataRowAttackExportable>
{
  id: Maybe<number> = null
  date: Maybe<string> = null
  source: Maybe<AttackSource> = null
  destination: Maybe<AttackDestination> = null
  vector: Maybe<AttackVector> = null
  directoryId: Maybe<number> = null
  isClosed: Maybe<boolean> = null

  attackTypeId: Maybe<number> = null
  attackTypeName: Maybe<AttackTypeName> = null
  attackTypeDescription: Maybe<string> = null
  attackTypeCriticity: Maybe<Criticity> = null
  attackTypeMitreAttackDescription: Maybe<string> = null
  attackTypeResources: Maybe<Resource[]> = null
  attackTypeYaraRules: Maybe<string> = null
  attackTypeVectorTemplate: Maybe<string> = null

  constructor(data: Partial<Attack & { attackTypeName: string }>) {
    super()
    Object.assign(this, data)
  }

  getDestination(): Maybe<EntityAttackDestination> {
    if (!this.destination) {
      return null
    }

    return createEntity<AttackDestination, EntityAttackDestination>(
      EntityAttackDestination,
      this.destination
    )
  }

  getSource(): Maybe<EntityAttackSource> {
    if (!this.source) {
      return null
    }

    return createEntity<AttackSource, EntityAttackSource>(
      EntityAttackSource,
      this.source
    )
  }

  getVector(): Maybe<EntityAttackVector> {
    if (!this.vector) {
      return null
    }

    return createEntity<AttackVector, EntityAttackVector>(
      EntityAttackVector,
      this.vector
    )
  }

  /**
   * Return partial AttackType entity.
   */
  getAttackType(
    allAttackTypes: Map<number, EntityAttackType>
  ): Maybe<EntityAttackType> {
    return (this.attackTypeId && allAttackTypes.get(this.attackTypeId)) || null
  }

  /**
   * Extends the attack with attackType information.
   */
  extendsWithAttackType(attackTypes: EntityAttackType[]): void {
    if (!this.attackTypeId) {
      return
    }

    const indexedAttackTypes = indexEntitiesToMap<EntityAttackType, number>(
      attackTypes,
      'id'
    )

    const foundAttack = indexedAttackTypes.get(this.attackTypeId)

    if (!foundAttack) {
      return
    }

    this.attackTypeName = foundAttack.name
    this.attackTypeDescription = foundAttack.description
    this.attackTypeCriticity = foundAttack.genericCriticity
    this.attackTypeMitreAttackDescription = foundAttack.mitreAttackDescription
    this.attackTypeResources = foundAttack.resources
    this.attackTypeYaraRules = foundAttack.yaraRules
    this.attackTypeVectorTemplate = foundAttack.vectorTemplate
  }

  /** Implements IEntityListable */

  /**
   * Not used, columns are hardcoded in the view with a custom render.
   */
  getColumns() {
    return []
  }

  asDataRow(): Maybe<IDataRowAttack> {
    return {
      id: this.id,
      date: this.date,
      source: this.source,
      destination: this.destination,
      vector: this.vector,
      attackTypeId: this.attackTypeId,
      attackTypeName: this.attackTypeName,
      attackTypeDescription: this.attackTypeDescription,
      attackTypeCriticity: this.attackTypeCriticity,
      attackTypeMitreAttackDescription: this.attackTypeMitreAttackDescription,
      attackTypeResources: this.attackTypeResources,
      attackTypeYaraRules: this.attackTypeYaraRules,
      attackTypeVectorTemplate: this.attackTypeVectorTemplate,
      isClosed: this.isClosed
    }
  }

  /** Implements IEntityExportable */

  getCSVColumns(
    parameters: IEntityExportableAsCsvColumnsParameters<IDataRowAttackExportable>
  ): Array<IWidgetListColumns<IDataRowAttackExportable>> {
    const columns: Array<IWidgetListColumns<IDataRowAttackExportable>> = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Date',
        key: 'date'
      },
      {
        label: 'Attack Vector',
        key: 'vector'
      },
      {
        label: 'Source Hostname',
        key: 'sourceHostname'
      },
      {
        label: 'Source IP',
        key: 'sourceIP'
      },
      {
        label: 'Source Type',
        key: 'sourceType'
      },
      {
        label: 'Destination Hostname',
        key: 'destinationHostname'
      },
      {
        label: 'Destination IP',
        key: 'destinationIP'
      },
      {
        label: 'Destination Type',
        key: 'destinationType'
      }
    ]

    return columns.filter(column => !parameters.omitKeys.includes(column.key))
  }

  /**
   * Return the entity as a string for CSV exports.
   */
  asCSVRow(
    parameters: IEntityExportableAsCsvRowParameters<IDataRowAttackExportable>
  ): Maybe<string> {
    const dataRow = this.asDataRow()

    if (!dataRow) {
      return null
    }

    const csvRowValues: Record<keyof IDataRowAttackExportable, string> = {
      id: sanitizeCsvValue(this.id),
      date: sanitizeCsvValue(this.date),
      vector: sanitizeCsvValue(
        this.getVector()?.buildDescription(
          this.getPropertyAsString('attackTypeVectorTemplate')
        )
      ),
      sourceHostname: sanitizeCsvValue(this.source?.hostname),
      sourceIP: sanitizeCsvValue(this.source?.ip),
      sourceType: sanitizeCsvValue(this.source?.type),
      destinationHostname: sanitizeCsvValue(this.destination?.hostname),
      destinationIP: sanitizeCsvValue(this.destination?.ip),
      destinationType: sanitizeCsvValue(this.destination?.type)
    }

    const columnsKeys = this.getCSVColumns(parameters).map(column => column.key)

    return Object.entries(csvRowValues)
      .filter(([key]) =>
        columnsKeys.includes(key as keyof IDataRowAttackExportable)
      )
      .map(([_, value]) => value)
      .join(parameters.separator)
  }
}
