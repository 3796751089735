import type { EnsureNonNullable } from '@@types/helpers'
import { ensureArray } from '@libs/ensureArray'
import { filterNullOrUndefinedValues } from '@libs/helpers/objects/filterNullOrUndefinedValues'
import { omit, pick } from 'lodash'

/**
 * Remove undefined or nullable props and optionnaly pick or omit props.
 */
export function filterEmptyProps<T extends object>(
  props: T,
  options?: {
    pickProps?: Array<keyof T>
    omitProps?: Array<keyof T>
  }
): Partial<EnsureNonNullable<T>> {
  const pickKeys = ensureArray(options?.pickProps)
  const omitKeys = ensureArray(options?.omitProps)

  const finalProps = pickKeys.length
    ? pick(props, pickKeys)
    : omitKeys.length
      ? omit(props, omitKeys)
      : props

  return filterNullOrUndefinedValues<T>(finalProps as EnsureNonNullable<T>)
}
