import {
  ContainerContent,
  ContainerFooter,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import {
  FormWrapper,
  FormWrapperButton,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { TextAreaCertificate } from '@app/components-legacy/TextArea'
import { TypographyFieldsetTitle } from '@app/components-legacy/Typography'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import {
  onInputChange,
  onSwitchChange
} from '@app/stores/helpers/StoreForm/handlers'
import { SamlConfigurationFieldName } from '@app/stores/Management/StoreSAMLConfiguration/types'
import { AuthProviderName } from '@server/graphql/typeDefs/types'
import { Input, Switch } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import ConfigurationGroups from '../ConfigurationAuthCommon/ConfigurationGroups'
import {
  onSAMLConfigurationSubmit,
  onSAMLMetadataExportClick,
  onSAMLMetadataGenerateClick
} from './handlers'
import { canSubmitSAMLConfiguration } from './permissions'

interface ISamlConfigurationFormProps {}

const SamlConfigurationForm: React.FC<ISamlConfigurationFormProps> = () => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Configuration.Navigation',
      'Management.System.Configuration.SAML'
    ]
  })

  const appRouter = useAppRouter()

  const { storeRbac, storeManagementSAMLConfiguration } = useStores()

  const { storeForm } = storeManagementSAMLConfiguration

  const fieldsSAMLConfigurationEnableSwitch: IField[] = [
    {
      name: SamlConfigurationFieldName.enabled,
      label: translate('Enable SAML authentication'),
      errors: storeForm.field(SamlConfigurationFieldName.enabled).errors,
      description: translate(
        'Enable SAML authentication for your organization through an identity provider like Azure'
      ),
      control: (
        <Switch
          size="small"
          checked={storeForm.getFieldValueAsBoolean(
            SamlConfigurationFieldName.enabled
          )}
          onChange={onSwitchChange(storeForm)(
            SamlConfigurationFieldName.enabled
          )}
        />
      )
    }
  ]

  const fieldsSAMLConfiguration: IField[] = [
    {
      name: SamlConfigurationFieldName.providerLoginUrl,
      label: translate('URL of the SAML server'),
      errors: storeForm.field(SamlConfigurationFieldName.providerLoginUrl)
        .errors,
      required: true,
      control: (
        <Input
          type="text"
          name={SamlConfigurationFieldName.providerLoginUrl}
          placeholder="https://saml-server/adfs/ls/"
          onChange={onInputChange(storeForm)(
            SamlConfigurationFieldName.providerLoginUrl
          )}
          value={storeForm.getFieldValueAsString(
            SamlConfigurationFieldName.providerLoginUrl
          )}
          autoComplete="off"
        />
      )
    },
    {
      name: 'securityTitle',
      control: (
        <TypographyFieldsetTitle>
          {translate('Security')}
        </TypographyFieldsetTitle>
      )
    },
    {
      name: SamlConfigurationFieldName.signatureCertificate,
      label: translate('SAML server certificate'),
      errors: storeForm.field(SamlConfigurationFieldName.signatureCertificate)
        .errors,
      description: translate(
        'Copy paste the certificate provided by the SAML server'
      ),
      required: true,
      labelAlignItem: 'end',
      control: (
        <TextAreaCertificate
          textAreaProps={{
            name: SamlConfigurationFieldName.signatureCertificate,
            onChange: onInputChange(storeForm)(
              SamlConfigurationFieldName.signatureCertificate
            ),
            defaultValue: storeForm.getFieldValueAsString(
              SamlConfigurationFieldName.signatureCertificate
            )
          }}
        />
      )
    },
    {
      name: 'download',
      label: translate('Tenable.ad certificate'),
      description: translate(
        'Download and use this certificate in your SAML server'
      ),
      control: (
        <FormWrapperButton
          labelledBy="download"
          buttonProps={{
            type: 'primary',
            onClick: onSAMLMetadataGenerateClick(appRouter)
          }}
        >
          {translate('Download')}
        </FormWrapperButton>
      )
    },
    {
      name: SamlConfigurationFieldName.activateCreatedUsers,
      label: translate("Activate new user's account automatically"),
      errors: storeForm.field(SamlConfigurationFieldName.activateCreatedUsers)
        .errors,
      description: translate(
        'After the first SAML authentication, activate the created account automatically'
      ),
      control: (
        <Switch
          size="small"
          checked={storeForm.getFieldValueAsBoolean(
            SamlConfigurationFieldName.activateCreatedUsers
          )}
          onChange={onSwitchChange(storeForm)(
            SamlConfigurationFieldName.activateCreatedUsers
          )}
        />
      )
    },
    {
      name: 'tenableEndpointsTitle',
      control: (
        <TypographyFieldsetTitle>
          {translate('Tenable.ad endpoints')}
        </TypographyFieldsetTitle>
      )
    },
    {
      name: SamlConfigurationFieldName.serviceProviderUrl,
      label: translate('URL of the Tenable.ad service provider'),
      errors: storeForm.field(SamlConfigurationFieldName.serviceProviderUrl)
        .errors,
      control: (
        <Input
          type="text"
          name={SamlConfigurationFieldName.serviceProviderUrl}
          placeholder="https://tenable.app"
          value={storeForm.getFieldValueAsString(
            SamlConfigurationFieldName.serviceProviderUrl
          )}
          autoComplete="off"
          // no onChange, this field is not edidable but need to be copy-pastable
        />
      )
    },
    {
      name: SamlConfigurationFieldName.assertEndpoint,
      label: translate('Assert endpoint of the Tenable.ad service provider'),
      errors: storeForm.field(SamlConfigurationFieldName.assertEndpoint).errors,
      control: (
        <Input
          type="text"
          name={SamlConfigurationFieldName.assertEndpoint}
          placeholder="https://tenable.app/auth/providers/saml/assert"
          value={storeForm.getFieldValueAsString(
            SamlConfigurationFieldName.assertEndpoint
          )}
          autoComplete="off"
          // no onChange, this field is not edidable but need to be copy-pastable
        />
      )
    },
    {
      name: 'defaultProfileAndRolesTitle',
      control: (
        <TypographyFieldsetTitle>
          {translate('Default profile and roles')}
        </TypographyFieldsetTitle>
      )
    },
    {
      name: 'allowedGroups',
      label: translate('Allowed groups'),
      control: (
        <>
          <p>
            {translate(
              'You must configure the default profile and roles for each SAML group'
            )}
          </p>

          <ConfigurationGroups
            userProvider={AuthProviderName.Saml}
            storeFormGroups={
              storeManagementSAMLConfiguration.storeFormAllowedGroups
            }
            profiles={storeManagementSAMLConfiguration.profiles}
            rbacRoles={storeManagementSAMLConfiguration.rbacRoles}
            createGroupFn={storeManagementSAMLConfiguration.createAllowedGroups}
          />
        </>
      )
    }
  ]

  const displaySAMLConfig = storeForm.getFieldValueAsBoolean(
    SamlConfigurationFieldName.enabled
  )

  const canExportMetadata =
    // To avoid Error 500 from Enif with empty values
    !storeForm.getFieldValueAsString(
      SamlConfigurationFieldName.providerLoginUrl
    ) ||
    !storeForm.getFieldValueAsString(
      SamlConfigurationFieldName.signatureCertificate
    )

  return (
    <FormWrapper
      name="samlConfiguration"
      onSubmit={onSAMLConfigurationSubmit(storeManagementSAMLConfiguration)}
    >
      <ContainerContent title={translate('SAML configuration')}>
        <ContainerForm fields={fieldsSAMLConfigurationEnableSwitch} />

        {displaySAMLConfig && (
          <ContainerForm fields={fieldsSAMLConfiguration} />
        )}

        <Portal name={PlaceHolderName.bladeFooter}>
          <ContainerFooter justify="right">
            <FormWrapperButton
              labelledBy="exportSAMLData"
              buttonProps={{
                type: 'default',
                onClick: onSAMLMetadataExportClick(appRouter),
                disabled: canExportMetadata
              }}
            >
              {translate('Export SAML Metadata')}
            </FormWrapperButton>

            <FormWrapperButtonSubmit
              loading={
                storeManagementSAMLConfiguration.storeUpdateFlags.flags
                  .isLoading
              }
              disabled={!storeRbac.isUserGrantedTo(canSubmitSAMLConfiguration)}
            >
              {translate('Save')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </ContainerContent>
    </FormWrapper>
  )
}

export default observer(SamlConfigurationForm)
