import { ContainerContent, ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { FormWrapperSwitch } from '@app/components-legacy/Form/Wrappers'
import { useAppTranslator, useStores } from '@app/hooks'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToHealthCheckConfiguration } from '../../permissions'
import ConfigurationHealthCheckCard from './ConfigurationHeathCheckCard'
import { handleHealthCheckConfigurationGlobalStatusDisplayChange } from './handlers'

export interface IConfigurationHealthCheckProps {
  className?: string
}

function ConfigurationHealthCheck(props: IConfigurationHealthCheckProps) {
  const { storeManagementApplicationSettings } = useStores()

  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.HealthCheck']
  })

  return (
    <DSThemeProvider themeName={DSThemeName.default}>
      <ContainerContent
        name="ConfigurationHealthCheckPage"
        className={props.className}
        rbacCapabilityCheck={canAccessToHealthCheckConfiguration}
      >
        <ContainerFlex
          name="card"
          direction="column"
          flexGap="default"
          items={[
            <ContainerFlex
              name="form"
              alignItems="center"
              justifyContent="space-between"
              items={[
                <Label variant={LabelVariant.h2}>
                  {translate('Show the Global Health Check Status')}
                </Label>,

                <FormWrapperSwitch
                  labelledBy="healthCheckActivateGlobalStatus"
                  switchProps={{
                    loading:
                      storeManagementApplicationSettings
                        .storeFlagsAppSettingsFetch.isLoading,
                    checked:
                      storeManagementApplicationSettings.applicationSettings
                        ?.healthCheckGlobalStatusDisplayEnabled ?? false,
                    onChange:
                      handleHealthCheckConfigurationGlobalStatusDisplayChange(
                        storeManagementApplicationSettings
                      )
                  }}
                />
              ]}
              flexGap="small"
            />,

            <ConfigurationHealthCheckCard />
          ]}
        />
      </ContainerContent>
    </DSThemeProvider>
  )
}

export default observer(ConfigurationHealthCheck)
