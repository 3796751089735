import type EntityDashboardWidgetOptionsSerie from '@app/entities/EntityDashboardWidgetOptionsSerie'
import { WIDGET_DISPLAY_OPTIONS_LABEL_MAX_LENGTH } from '@app/stores/Dashboard/consts'
import { isDefined } from '@libs/isDefined'
import { sliceString } from '@libs/sliceString'
import type {
  DashboardWidgetOptionsSerieStat,
  DashboardWidgetOptionsSerieStatDataPoint
} from '@server/graphql/typeDefs/types'
import * as uuid from 'uuid'

export interface ILineChartData {
  labels: string[]
  rows: Array<{
    [k: string]: number
    timestamp: number
  }>
  uuids: string[]
}

export interface IPieChartData {
  labels: string[]
  rows: Array<{
    name: string
    value: number
  }>
  uuids: string[]
}

export interface IKeyMapping {
  labelKey: string
}

/**
 * Fast map-to-object conversion.
 */
const toObject = (map: Map<any, any>) => {
  const obj: { [k: string]: any } = {}
  map.forEach((v, k) => {
    obj[k] = v
  })
  return obj
}

/**
 * Use custom label from displayOptions if set.
 */
function getLabel(serie: EntityDashboardWidgetOptionsSerie): string {
  return sliceString(
    (serie.displayOptions && serie.displayOptions.label) ||
      (serie.stats && serie.stats.label) ||
      'Unknown label',
    { maxLength: WIDGET_DISPLAY_OPTIONS_LABEL_MAX_LENGTH }
  )
}

/**
 * Convert serie data to Recharts types.
 * See associated unit test for examples.
 */
export function convertToLineChartData(
  series: EntityDashboardWidgetOptionsSerie[]
): ILineChartData {
  const rows = new Map<number, Map<string, number>>()

  // extends stats with labels from displayOptions if any
  const stats = series
    .map(serie => {
      if (!serie.stats) {
        return
      }

      return {
        ...serie.stats,
        label: getLabel(serie)
      }
    })
    .filter(isDefined)

  stats.forEach(stat => {
    const data = stat.data as DashboardWidgetOptionsSerieStatDataPoint[]

    data.forEach(point => {
      // because DashboardWidgetOptionsSerieStatDataPointAttackCount (in the union)
      // has no timestamp
      if ('timestamp' in point) {
        const timestamp = Number(point.timestamp)
        const points = rows.get(timestamp) || new Map<string, number>()
        points.set(stat.label, 'count' in point ? point.count : point.value)
        rows.set(timestamp, points)
      }
    })
  })

  // inlined to avoid variables assignation
  return {
    labels: stats.map(stat => stat.label),
    rows: Array.from(rows.entries())
      .map(([timestamp, points]) => {
        return Object.assign({}, { timestamp }, toObject(points))
      })
      .sort((a, b) => a.timestamp - b.timestamp),
    uuids: stats.map(() => uuid.v4())
  }
}

/**
 * Convert serie data to Recharts types.
 * See associated unit test for examples.
 */
export function convertToPieChartData(
  stats: DashboardWidgetOptionsSerieStat[]
): IPieChartData {
  const rows = new Map<string, number>()

  stats.forEach(stat => {
    const data = stat.data as DashboardWidgetOptionsSerieStatDataPoint[]

    data.forEach(point => {
      rows.set(stat.label, 'count' in point ? point.count : point.value)
    })
  })

  // inlined to avoid variables assignation
  return {
    labels: stats.map(d => d.label),
    rows: Array.from(rows.entries()).map(([name, value]) => {
      return {
        name,
        value
      }
    }),
    uuids: stats.map(() => uuid.v4())
  }
}
