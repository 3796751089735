import type { IStoreEscapable } from '@app/stores/types'
import type { IKeyboardBindingsManagerHandler } from './types'

/**
 * Handler to handle Escape key in order to "escape" UI components in the
 * right order.
 *
 * For example, if a Blade, a Drawer, then a Modal is displayed, then the Escape
 * key will first escape/close the Modal, then the Drawer, then the Blade.
 */
export class EscapeHandler implements IKeyboardBindingsManagerHandler {
  private stores: Set<IStoreEscapable> = new Set()

  /**
   * Add a new store in the Set.
   */
  addStore(store: IStoreEscapable): this {
    this.stores.add(store)
    return this
  }

  /**
   * Delete a store from the Set.
   */
  deleteStore(store: IStoreEscapable): this {
    this.stores.delete(store)
    return this
  }

  /* Implement IKeyboardBindingsManagerHandler */

  /**
   * When pressing Escape, loop over all registered stores to find the first
   * one to escape.
   */
  onKeyDown(): void {
    const allStoresReverse = Array.from(this.stores.values()).reverse()

    // search the first "escapable" store, escape it, and stop the loop.
    for (const store of allStoresReverse) {
      if (store.isEscapable) {
        store.escape()
        break
      }
    }
  }
}
