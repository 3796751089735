import { Features } from '@alsid/common'
import {
  ContainerContent,
  ContainerFooter
} from '@app/components-legacy/Container'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import WidgetFormSelect from '@app/components-legacy/Widgets/WidgetForm/WidgetFormSelect'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { useStores } from '@app/hooks/useStores'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { DEFAULT_SMTP_PORT } from '@app/stores/Management/StoreApplicationSettings'
import {
  SmtpEncryption,
  SMTPServerFormFieldName
} from '@app/stores/Management/StoreApplicationSettings/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onSMTPConfigurationSubmit } from './handlers'
import { canSubmitSMTPConfiguration } from './permissions'

export interface IConfigurationSMTPFormProps {}

const ConfigurationSMTPForm: React.FC<IConfigurationSMTPFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Configuration.Navigation',
      'Management.System.Configuration.SMTP',
      'Management.System.Directories'
    ]
  })

  const {
    storeRbac,
    storeManagementApplicationSettings,
    storeManagementRelays
  } = useStores()

  const featureAlertingThroughRelay = useFeatureFlag({
    featureFlagName: Features.ALERTING_THROUGH_RELAY
  })

  const { storeRoot } = useStores()
  const featureSecuredRelayOnCustomerSide =
    storeRoot.environment.config.app.securerelay.customerside

  const displayedFields = [
    SMTPServerFormFieldName.smtpServerAddress,
    SMTPServerFormFieldName.smtpServerPort,
    SMTPServerFormFieldName.smtpAccount,
    SMTPServerFormFieldName.smtpAccountPassword,
    SMTPServerFormFieldName.smtpEncryption,
    SMTPServerFormFieldName.emailSender
  ]

  if (featureAlertingThroughRelay && featureSecuredRelayOnCustomerSide) {
    displayedFields.unshift(SMTPServerFormFieldName.smtpRelayId)
  }

  return (
    <FormWrapper
      name="smtpConfiguration"
      onSubmit={onSMTPConfigurationSubmit(
        storeManagementApplicationSettings,
        featureAlertingThroughRelay && featureSecuredRelayOnCustomerSide
      )}
    >
      <>
        <ContainerContent title={translate('SMTP server')}>
          <WidgetForm<SMTPServerFormFieldName>
            translate={translate}
            displayedFields={displayedFields}
            fieldsParams={{
              [SMTPServerFormFieldName.smtpServerPort]: {
                inputType: InputType.input,
                inputProps: {
                  placeholder: String(DEFAULT_SMTP_PORT)
                }
              },
              [SMTPServerFormFieldName.smtpEncryption]: {
                inputType: InputType.select,
                inputProps: {
                  labelledBy: 'smtpEncryption',
                  selectProps: {
                    value:
                      storeManagementApplicationSettings.storeFormSMTPConfiguration.getFieldValueAsString(
                        SMTPServerFormFieldName.smtpEncryption
                      )
                  },
                  selectOptions: [
                    {
                      value: SmtpEncryption.tls,
                      label: translate('TLS')
                    },
                    {
                      value: SmtpEncryption.startTls,
                      label: translate('StartTLS')
                    },
                    {
                      value: SmtpEncryption.none,
                      label: translate('None')
                    }
                  ]
                }
              }
            }}
            renderFields={{
              [SMTPServerFormFieldName.smtpRelayId]: (
                storeForm,
                field,
                inputId
              ) => {
                return (
                  <WidgetFormSelect
                    fieldName={field.name}
                    inputId={inputId}
                    inputProps={{
                      labelledBy: 'relay',
                      selectOptions: Array.from(
                        storeManagementRelays.relays.values()
                      ).map(relay => ({
                        value: relay.getPropertyAsString('id'),
                        label: relay.getPropertyAsString('name')
                      }))
                    }}
                    storeForm={storeForm}
                  />
                )
              }
            }}
            storeForm={
              storeManagementApplicationSettings.storeFormSMTPConfiguration
            }
          />
        </ContainerContent>

        <Portal name={PlaceHolderName.bladeFooter}>
          <ContainerFooter>
            <FormWrapperButtonSubmit
              loading={
                storeManagementApplicationSettings.storeFlagsAppSettingsSubmit
                  .flags.isLoading
              }
              disabled={!storeRbac.isUserGrantedTo(canSubmitSMTPConfiguration)}
            >
              {translate('Save')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </>
    </FormWrapper>
  )
}

export default observer(ConfigurationSMTPForm)
