import { ContainerFlex } from '@app/components-legacy/Container'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export interface IWidgetListFiltersHeaderProps {
  style?: React.CSSProperties
  items: React.ReactNodeArray
}

const WidgetListFiltersHeader: React.FC<
  IWidgetListFiltersHeaderProps
> = props => {
  return (
    <ContainerFlex
      name="WidgetListFiltersHeader"
      style={props.style}
      itemsFlexGrow={[1, 0]}
      alignItems="center"
      items={props.items}
      spaced
    />
  )
}

export default observer(WidgetListFiltersHeader)
