import type { StoreHealthCheck } from '@app/stores'

export const handleHealthCheckOnLoad =
  (storeHealthCheck: StoreHealthCheck) => () => {
    storeHealthCheck.storeInfrastructures.fetchInfrastructures()
    storeHealthCheck.storeInputHealthChecks.fetchHealthChecksTemplatesNames()
  }

export const handleHealthCheckOnUnLoad =
  (storeHealthCheck: StoreHealthCheck) => () => {
    storeHealthCheck.resetHealthCheckPage()
  }
