import type { StoreManagementRelays } from '@app/stores'

/**
 * Confirm the closing of selected attacks.
 */
export const onRelaysRenewApiKeyModalConfirmClick =
  (storeManagementRelays: StoreManagementRelays) => () => {
    const { storeModalConfirmRenewApiKey } = storeManagementRelays

    if (storeModalConfirmRenewApiKey.data?.bulk) {
      storeManagementRelays
        .renewSelectedRelaysApiKeys()
        .then(() => storeModalConfirmRenewApiKey.hide())
    }

    if (storeModalConfirmRenewApiKey.data?.relayId) {
      storeManagementRelays
        .renewSingleRelayApiKey(storeModalConfirmRenewApiKey.data.relayId)
        .then(() => storeModalConfirmRenewApiKey.hide())
    }
  }
