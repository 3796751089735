import { createEntities, EntityAttackType, EntityChecker } from '@app/entities'
import { canAccessToIoADetails } from '@app/pages/IoA/IoABoardPage/permissions'
import { canAccessToAdIoEDetails } from '@app/pages/IoE/IoEBoardPage/permissions'
import type { StoreRoot } from '@app/stores'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreBase from '@app/stores/StoreBase'
import type { WithOptionalInstanceName } from '@app/stores/types'
import { getCriticityValue } from '@libs/criticity'
import { ensureSet } from '@libs/ensureArray'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { If } from '@libs/fp-helpers/If'
import { isDefined } from '@libs/isDefined'
import { checkRbac } from '@libs/rbac/functions'
import { addSetValueToMap } from '@libs/setValueToMap'
import type { QueryRbacCheckersLight } from '@server/graphql/queries/checker'
import { queryRbacCheckersLight } from '@server/graphql/queries/checker'
import type { QueryAttacksTypes } from '@server/graphql/queries/ioa'
import { queryAttacksTypes } from '@server/graphql/queries/ioa'
import type {
  AttackType,
  Checker,
  Criticity,
  RbacAttackTypesQueryArgs,
  RbacCheckersQueryArgs
} from '@server/graphql/typeDefs/types'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { isNumber } from 'lodash'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import StoreFlags from '../StoreFlags'
import { StoreInputSearch } from '../StoreInputSearch'
import type {
  CheckersByCriticity,
  CheckersByRemediationCostLevel,
  IStoreInputGenericCheckersOptions,
  TGenericChecker
} from './types'

/**
 * Store used to store checkers selection.
 */
export default class StoreInputGenericCheckers<
  GC extends TGenericChecker
> extends StoreBase<IStoreInputGenericCheckersOptions<GC>> {
  public storeFlagsFetchCheckers = new StoreFlags(this.storeRoot)

  public storeInputSearch = new StoreInputSearch(this.storeRoot)
  public storeDrawer = new StoreDrawer(this.storeRoot)

  public defaultSelectedCheckerIds: number[] = []

  /* Private */

  private _checkers: Array<GC['entityType']> = []

  /* Observables */

  private $checkersByCodename = observable.map<
    GC['entityType']['genericCodename'],
    GC['entityType']
  >()
  private $checkersByCheckerId = observable.map<number, GC['entityType']>()
  private $checkersVisibility = observable.map<Criticity, boolean>()

  // codename <-> selection status
  private $selectedCheckers = observable.map<
    GC['entityType']['genericCodename'],
    boolean
  >()

  constructor(
    storeRoot: StoreRoot,
    options: WithOptionalInstanceName<IStoreInputGenericCheckersOptions<GC>>
  ) {
    super(storeRoot, {
      instanceName:
        options.instanceName ?? storeRoot.environment.getFirstInstanceName(),
      ...options
    })

    makeObservable(this)
  }

  /**
   * Fetch exposure checkers or attacks checkers.
   */
  fetchCheckers(
    args?: Partial<RbacCheckersQueryArgs> | Partial<RbacAttackTypesQueryArgs>
  ): Promise<void> {
    return If(!this.checkersById.size).fetch(() => {
      return this.options.checkerType === CheckerType.Exposure
        ? this.fetchExposureCheckers(args)
        : this.fetchAttackCheckers(args)
    })
  }

  /**
   * Fetch exposure checkers.
   *
   * Fetch the license at the same time to avoid to have to make the check
   * in the caller (or to forget to make the check).
   */
  fetchExposureCheckers(_args?: Partial<RbacCheckersQueryArgs>): Promise<void> {
    const { storeRbac } = this.storeRoot.stores

    if (!storeRbac.isUserGrantedTo(canAccessToAdIoEDetails(null))) {
      return Promise.resolve()
    }

    this.storeFlagsFetchCheckers.loading()

    return (
      Promise.resolve()
        .then(() => {
          const args: RbacCheckersQueryArgs = {
            profileId:
              this.storeRoot.stores.storeAuthentication.currentProfileId,
            //  fetch all options
            optionsCodenames: [],
            ..._args
          }

          return this.storeRoot
            .getGQLRequestor(this.options.instanceName)
            .query<QueryRbacCheckersLight>(queryRbacCheckersLight, args)
        })
        .then(data => data.rbacCheckers)
        .then(rbacCheckers => {
          if (
            !checkRbac(
              this.storeRoot,
              this.storeFlagsFetchCheckers
            )(rbacCheckers)
          ) {
            throw new ForbiddenAccessError()
          }

          const checkerEntities = createEntities<Checker, EntityChecker>(
            EntityChecker,
            rbacCheckers.node
          )

          this.setCheckers(checkerEntities)
        })
        // set default selection if any
        .then(() => {
          if (!this.defaultSelectedCheckerIds.length) {
            return
          }

          const codenames = this.defaultSelectedCheckerIds
            .map(checkerId => {
              const entityChecker = this.$checkersByCheckerId.get(checkerId)
              return entityChecker && entityChecker.genericCodename
            })
            .filter(isDefined)

          this.selectCheckers(codenames)
        })
        .then(() => {
          this.storeFlagsFetchCheckers.success()
        })
        .catch(
          handleStoreError(this.storeRoot, this.storeFlagsFetchCheckers, {
            errorMessageTranslationFn: () => false
          })
        )
    )
  }

  /**
   * Fetch attackTypes and options.
   *
   * Fetch the license at the same time to avoid to have to make the check
   * in the caller (or to forget to make the check).
   */
  fetchAttackCheckers(
    _args?: Partial<RbacAttackTypesQueryArgs>
  ): Promise<void> {
    const { storeRbac } = this.storeRoot.stores

    if (!storeRbac.isUserGrantedTo(canAccessToIoADetails(null))) {
      return Promise.resolve()
    }

    this.storeFlagsFetchCheckers.loading()

    return (
      Promise.resolve()
        .then(() => {
          const args: RbacAttackTypesQueryArgs = {
            profileId:
              this.storeRoot.stores.storeAuthentication.currentProfileId,
            //  fetch all options
            optionsCodenames: [],
            ..._args
          }

          return this.storeRoot
            .getGQLRequestor(this.options.instanceName)
            .query<QueryAttacksTypes>(queryAttacksTypes, args)
        })
        .then(data => data.rbacAttackTypes)
        .then(rbacAttackTypes => {
          if (
            !checkRbac(
              this.storeRoot,
              this.storeFlagsFetchCheckers
            )(rbacAttackTypes)
          ) {
            throw new ForbiddenAccessError()
          }

          const attackTypeEntities = createEntities<
            AttackType,
            EntityAttackType
          >(EntityAttackType, rbacAttackTypes.node)

          this.setCheckers(attackTypeEntities)
        })
        // set default selection if any
        .then(() => {
          if (!this.defaultSelectedCheckerIds.length) {
            return
          }

          const attackTypeNames = this.defaultSelectedCheckerIds
            .map(attackTypeId => {
              const entityAttackType =
                this.$checkersByCheckerId.get(attackTypeId)
              return entityAttackType && entityAttackType.genericCodename
            })
            .filter(isDefined)

          this.selectCheckers(attackTypeNames)
        })
        .then(() => {
          this.storeFlagsFetchCheckers.success()
        })
        .catch(
          handleStoreError(this.storeRoot, this.storeFlagsFetchCheckers, {
            errorMessageTranslationFn: () => false
          })
        )
    )
  }

  /**
   * Group checkers by criticity.
   */
  groupCheckersByCriticity(): CheckersByCriticity<GC> {
    return this.searchedCheckers.reduce((acc, checker) => {
      addSetValueToMap(acc, checker.genericCriticity, checker)
      return acc
    }, new Map() as CheckersByCriticity<GC>)
  }

  /**
   * Group checkers by remediation cost.
   */
  groupCheckersByRemediationCostLevel(): CheckersByRemediationCostLevel<GC> {
    if (this.options.checkerType === CheckerType.Attack) {
      throw new Error('No remediation cost for attacks (yet?)')
    }

    return this.searchedCheckers.reduce((acc, checker) => {
      addSetValueToMap(acc, checker.genericRemediationCostLevel, checker)
      return acc
    }, new Map() as CheckersByRemediationCostLevel<GC>)
  }

  /**
   * Get a list of available checkers by criticity.
   */
  checkersByCriticity(criticity: Criticity): Set<GC['entityType']> {
    return ensureSet(this.groupCheckersByCriticity().get(criticity))
  }

  /**
   * Check if the criticity has checkers.
   */
  hasCheckersForCriticity(criticity: Criticity): boolean {
    return Array.from(this.checkersByCriticity(criticity)).length > 0
  }

  /**
   * Return true if the codename is selected.
   */
  isCheckerSelected(codename: GC['entityType']['genericCodename']): boolean {
    return this.selectedCheckersCodename.indexOf(codename) !== -1
  }

  /**
   * Return true if all checkers of a defined criticity are selected.
   */
  isCheckersByCriticitySelected(criticity: Criticity): boolean {
    return Array.from(this.$checkersByCodename.entries())
      .filter(([, checkerEntity]) => {
        return checkerEntity.genericCriticity === criticity
      })
      .every(([codename]) => {
        return this.$selectedCheckers.get(codename) === true
      })
  }

  /**
   * Return true if some checkers of a defined criticity are selected.
   */
  isCheckersByCriticityPartiallySelected(criticity: Criticity): boolean {
    if (this.isCheckersByCriticitySelected(criticity)) {
      return false
    }

    return Array.from(this.$checkersByCodename.entries())
      .filter(([, checkerEntity]) => {
        return checkerEntity.genericCriticity === criticity
      })
      .some(([codename]) => {
        return this.$selectedCheckers.get(codename) === true
      })
  }

  /**
   * Return true if the checkers are visible for a defined criticity.
   */
  isCheckersVisible(criticity: Criticity): boolean {
    return this.$checkersVisibility.get(criticity) === true
  }

  /**
   * Set default selection of checkers after having fetched the checkers.
   */
  setDefaultSelectedCheckerIds(checkerIds: number[]): this {
    this.defaultSelectedCheckerIds = checkerIds
    return this
  }

  /**
   * Return fetched checkers.
   */
  get checkers(): Array<GC['entityType']> {
    return this._checkers
  }

  /* Actions */

  /**
   * Reset everything.
   */
  @action
  reset() {
    this.defaultSelectedCheckerIds = []
    this.storeInputSearch.reset()

    this.$checkersByCodename.clear()
    this.$checkersByCheckerId.clear()
    this.$checkersVisibility.clear()
    this.$selectedCheckers.clear()
  }

  @action
  setCheckers(checkerEntities: Array<GC['entityType']>): this {
    this._checkers = checkerEntities

    this.$checkersByCodename.clear()
    this.$checkersByCheckerId.clear()

    // filter checkers if a filter function has been defined in options
    const filterCheckersFn = this.options.filterCheckersFn

    const filteredEntityCheckers = filterCheckersFn
      ? filterCheckersFn(checkerEntities)
      : checkerEntities

    filteredEntityCheckers.forEach(checker => {
      if (checker.genericCodename) {
        this.$checkersByCodename.set(checker.genericCodename, checker)
      }

      // Don't support checker with ID as string for now (Identity checkers)
      if (checker.id && isNumber(checker.id)) {
        this.$checkersByCheckerId.set(checker.id, checker)
      }
    })

    return this
  }

  @action
  toggleSelectChecker(codename: GC['entityType']['genericCodename']): this {
    const isSelected = this.isCheckerSelected(codename)
    this.$selectedCheckers.set(codename, !isSelected)

    return this
  }

  @action
  selectChecker(codename: GC['entityType']['genericCodename']): this {
    this.$selectedCheckers.set(codename, true)

    return this
  }

  @action
  unselectChecker(codename: GC['entityType']['genericCodename']): this {
    this.$selectedCheckers.set(codename, false)

    return this
  }

  @action
  selectCheckers(codenames: Array<GC['entityType']['genericCodename']>): this {
    codenames.forEach(codename => {
      this.$selectedCheckers.set(codename, true)
    })

    return this
  }

  @action
  selectCheckersFromIds(checkerIds: number[]): this {
    Array.from(this.$checkersByCodename.entries()).forEach(
      ([checkerCodename, checker]) => {
        if (checkerIds.indexOf(checker.getPropertyAsNumber('id')) !== -1) {
          this.$selectedCheckers.set(checkerCodename, true)
        }
      }
    )

    return this
  }

  @action
  selectAllCheckers(): this {
    Array.from(this.$checkersByCodename.values()).forEach(checker => {
      this.$selectedCheckers.set(checker.genericCodename, true)
    })

    return this
  }

  @action
  selectAllSearchedCheckers(): this {
    Array.from(this.searchedCheckers).forEach(checker => {
      this.$selectedCheckers.set(checker.genericCodename, true)
    })

    return this
  }

  @action
  unselectCheckers(codenames: string[]): this {
    codenames.forEach(codename => {
      this.$selectedCheckers.set(codename, false)
    })

    return this
  }

  @action
  unselectAllCheckers(): this {
    this.$checkersByCodename.forEach((checkerEntity, codename) => {
      this.$selectedCheckers.set(codename, false)
    })

    return this
  }

  @action
  unselectCheckersByThreshold(criticityValue: number): this {
    const criticities = Array.from(this.$checkersByCheckerId.values()).map(
      checker => checker.genericCriticity
    )

    const lowerCriticities = criticities.filter(
      criticity => getCriticityValue(criticity) < criticityValue
    )

    lowerCriticities.forEach(criticity => {
      this.unselectCheckersByCriticity(criticity)
    })

    return this
  }

  @action
  selectCheckersByCriticity(criticity: Criticity): this {
    const codenames = Array.from(this.$checkersByCodename.entries())
      .filter(
        ([, checkerEntity]) => checkerEntity.genericCriticity === criticity
      )
      .map(([codename]) => codename)

    return this.selectCheckers(codenames)
  }

  @action
  unselectCheckersByCriticity(criticity: Criticity): this {
    const codenames = Array.from(this.$checkersByCodename.entries())
      .filter(
        ([, checkerEntity]) => checkerEntity.genericCriticity === criticity
      )
      .map(([codename]) => codename)

    return this.unselectCheckers(codenames)
  }

  @action
  showAllCheckers(): this {
    const criticities = Array.from(this.$checkersByCheckerId.values()).map(
      checker => checker.genericCriticity
    )

    criticities.forEach(criticity => {
      this.$checkersVisibility.set(criticity, true)
    })

    return this
  }

  @action
  showCheckers(criticity: Criticity): this {
    this.$checkersVisibility.set(criticity, true)

    return this
  }

  @action
  hideAllCheckers(): this {
    const criticities = Array.from(this.$checkersByCheckerId.values()).map(
      checker => {
        const g = checker.genericCriticity
        return g
      }
    )

    criticities.forEach(criticity => {
      this.$checkersVisibility.set(criticity, false)
    })

    return this
  }

  @action
  hideCheckers(criticity: Criticity): this {
    this.$checkersVisibility.set(criticity, false)

    return this
  }

  @action
  toggleCheckersVisibility(criticity: Criticity): this {
    this.$checkersVisibility.set(criticity, !this.isCheckersVisible(criticity))

    return this
  }

  @action
  replaceSelectedCheckers(selectedCheckers: Array<GC['entityType']>): this {
    const selectedCheckersCodename = selectedCheckers.map(
      checker => checker.genericCodename
    )

    this.$checkersByCodename.forEach(checkerEntity => {
      const codename = checkerEntity.genericCodename

      selectedCheckersCodename.includes(codename)
        ? this.$selectedCheckers.set(codename, true)
        : this.$selectedCheckers.set(codename, false)
    })

    return this
  }

  /* Computed */

  @computed
  get checkersByCodename(): Map<
    GC['entityType']['genericCodename'],
    GC['entityType']
  > {
    return toJS(this.$checkersByCodename)
  }

  @computed
  get checkersById(): Map<number, GC['entityType']> {
    return toJS(this.$checkersByCheckerId)
  }

  /**
   * Return the list of selected checkers.
   */
  @computed
  get selectedCheckers(): Array<GC['entityType']> {
    return Array.from(this.$selectedCheckers.entries())
      .filter(([, selected]) => selected === true)
      .map(([codename]) => this.$checkersByCodename.get(codename))
      .filter(isDefined)
  }

  /**
   * Return the list codenames of selected checkers.
   */
  @computed
  get selectedCheckersCodename(): Array<GC['entityType']['genericCodename']> {
    return this.selectedCheckers.map(checker => checker.genericCodename)
  }

  /**
   * Return the list of ids of selected checkers.
   */
  @computed
  get selectedCheckerIds(): number[] {
    return Array.from(this.$selectedCheckers.entries())
      .filter(([, selected]) => selected === true)
      .map(([codename]) => {
        const checker = this.$checkersByCodename.get(codename)
        return checker && checker.id
      })
      .filter(isNumber)
      .filter(isDefined)
  }

  /**
   * Return true if some checkers are selected.
   */
  @computed
  get hasSelectedCheckers(): boolean {
    return Array.from(this.$selectedCheckers.values()).some(
      selected => selected === true
    )
  }

  /**
   * Return all the checkers that match the current search value.
   */
  @computed
  get searchedCheckers(): Array<GC['entityType']> {
    const allCheckers = Array.from(this.checkersByCodename.entries()).map(
      ([, checker]) => checker
    )

    return allCheckers.filter(checker => {
      if (!checker.name) {
        return true
      }

      return this.storeInputSearch.transformedSearchValueAsRegexp.test(
        checker.name
      )
    })
  }

  /**
   * Return true if some but not all searched checkers are selected.
   */
  @computed
  get hasSomeSearchedCheckersSelected(): boolean {
    return !this.isAllSearchedCheckersSelected && this.hasSelectedCheckers
  }

  /**
   * Return true if all or searched checkers are selected.
   */
  @computed
  get isAllSearchedCheckersSelected(): boolean {
    if (this.storeInputSearch.hasSearchValue) {
      return this.searchedCheckers.every(checker => {
        return this.isCheckerSelected(checker.genericCodename)
      })
    }
    return Array.from(this.checkersByCodename).every(([codename]) =>
      this.isCheckerSelected(codename)
    )
  }
}
