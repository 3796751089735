import { ContainerFlex } from '@app/components/Container'
import { Label, LabelColorTag } from '@app/components/Labels'
import type { IPageVisited } from '@app/entities/EntityUserLog'
import { Tooltip } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { colorUserLogRoleNameRedesign } from '../consts'

const StyledParentsLabel = styled(LabelColorTag)`
  margin-right: 0px;
`

export const UserLogPageVisited = (props: IPageVisited) => {
  const { routeName, routePath, parents } = props

  const concatParents = parents?.join(' / ')

  const getItems = (): JSX.Element[] => {
    const items: JSX.Element[] = []

    if (concatParents) {
      items.push(
        <Tooltip title={concatParents}>
          <span>
            <StyledParentsLabel
              color={colorUserLogRoleNameRedesign.background}
              labelProperties={{
                color: colorUserLogRoleNameRedesign.text,
                labelledBy: concatParents
              }}
            >
              ...
            </StyledParentsLabel>
          </span>
        </Tooltip>
      )
      items.push(<Label label="/" />)
    }

    items.push(
      <Tooltip title={routePath}>
        <span>
          <LabelColorTag
            color={colorUserLogRoleNameRedesign.background}
            labelProperties={{
              color: colorUserLogRoleNameRedesign.text,
              labelledBy: routeName
            }}
          >
            {routeName}
          </LabelColorTag>
        </span>
      </Tooltip>
    )
    return items
  }

  return (
    <ContainerFlex
      name="PageVisited"
      items={getItems()}
      spaced
      spaceWidth="verySmall"
    />
  )
}
