import { Features } from '@alsid/common'
import type { IIconProps } from '@app/components/Icon/types'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import * as React from 'react'

interface IIconAlertTulProps extends IIconProps {}

const IconAlertTul: React.FC<IIconAlertTulProps> = props => {
  const { storeRoot } = useStores()

  const stroke = props.color ?? consts.colorBlue015
  const size = props.size ?? 24

  const isTulV6Enabled = storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
    Features.KAPTEYN_TUL_V6
  )

  if (isTulV6Enabled) {
    return (
      <tenable-floating-button
        id="notifications-menu-button"
        aria-label="Notifications"
        aria-haspopup="true"
      >
        <tenable-bell color="brand" size="medium" />
      </tenable-floating-button>
    )
  }

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
      <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
    </svg>
  )
}

export default IconAlertTul
