import type { PropertiesNullable } from '@@types/helpers'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import type { Maybe, Relay } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'
import type { IEntityListable, IEntityOmitKeysParameter } from './types'

export interface IDataRowRelay {
  id: number
  name: string
  directoryCount: number
  status: string
  alertCount: number
}

export default class EntityRelay
  extends EntityBase
  implements PropertiesNullable<Relay>, IEntityListable<IDataRowRelay>
{
  id: Maybe<number> = null
  name: Maybe<string> = null
  relayUuid: Maybe<string> = null
  status: Maybe<string> = null
  directoryIds: Maybe<number[]> = null
  apiKey: Maybe<string> = null
  newApiKey: Maybe<string> = null
  alertCount: Maybe<number> = null

  constructor(data: Partial<EntityRelay>) {
    super()
    Object.assign(this, data)
  }

  getDirectoryCount(): number {
    return this.directoryIds?.length ?? 0
  }

  /* Implements IEntityListable */

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowRelay> = []
  ): Array<IWidgetListColumns<IDataRowRelay>> {
    const columns: Array<IWidgetListColumns<IDataRowRelay>> = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Name',
        key: 'name'
      },
      {
        label: 'Linked Domains',
        key: 'directoryCount'
      },
      {
        label: 'Linked Alerting',
        key: 'alertCount'
      }
    ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  asDataRow(): IDataRowRelay {
    return {
      id: this.getPropertyAsNumber('id'),
      name: this.getPropertyAsString('name'),
      directoryCount: this.getDirectoryCount(),
      status: this.getPropertyAsString('status'),
      alertCount: this.getPropertyAsNumber('alertCount')
    }
  }
}
