import {
  createEntities,
  EntityAttackTypeOption,
  EntityCheckerOption
} from '@app/entities'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import type {
  CheckerOptionCodename,
  EntityGenericCheckerOption
} from '@app/entities/EntityGenericCheckerOption/types'
import type { StoreRoot } from '@app/stores'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type {
  IStoreInputGenericCheckersOptions,
  TGenericChecker
} from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import StoreMenu from '@app/stores/helpers/StoreMenu'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import StoreBase from '@app/stores/StoreBase'
import type { WithOptionalInstanceName } from '@app/stores/types'
import { ensureArray } from '@libs/ensureArray'
import { ApplicationError } from '@libs/errors'
import { isApplicationError } from '@libs/errors/functions'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { ApplicationErrorValue } from '@libs/errors/types'
import type { MutationSetProfileCheckerOptions } from '@server/graphql/mutations/profile'
import { mutationSetProfileCheckerOptions } from '@server/graphql/mutations/profile'
import type { QueryCheckerOptions } from '@server/graphql/queries/checker'
import { queryCheckerOptions } from '@server/graphql/queries/checker'
import type { QueryAttackTypeOptions } from '@server/graphql/queries/ioa'
import { queryAttackTypeOptions } from '@server/graphql/queries/ioa'
import type {
  AttackTypeOption,
  AttackTypeOptionsQueryArgs,
  CheckerOption,
  CheckerOptionsQueryArgs,
  Maybe,
  RbacAttackTypesQueryArgs,
  RbacCheckersQueryArgs,
  SetProfileCheckerOptionsMutationArgs
} from '@server/graphql/typeDefs/types'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { action, makeObservable, observable, runInAction } from 'mobx'
import {
  buildCheckerOptionsForDraft,
  filterCheckerOptionsAccordingProfileStatus,
  retrieveAllDirectoriesCheckerOptionsOfChecker
} from './helpers/buildCheckerOptionsForDraft'
import {
  getConfigurationMenuKey,
  getConfigurationMenuTranslation
} from './helpers/configurationMenu'
import { getDuplicatedCheckerOptionsForASameDirectory } from './helpers/getDuplicatedCheckerOptionsForASameDirectory'
import { groupCheckerOptionsByConfigurations } from './helpers/groupCheckerOptionsByConfigurations'
import StoreProfileCheckerSerie from './StoreProfileCheckerSerie'

export type TStoreProfileGenericCheckers = StoreProfileGenericCheckers<
  TGenericChecker,
  EntityGenericCheckerOption
>

/**
 * Store in charge to handle customization of checker of exposure/attack options.
 */
export default class StoreProfileGenericCheckers<
  GC extends TGenericChecker,
  E extends EntityGenericCheckerOption
> extends StoreBase<IStoreInputGenericCheckersOptions<GC>> {
  public storeFlagsFetchCheckers = new StoreFlags(this.storeRoot)
  public storeFlagsFetchCheckerOptions = new StoreFlags(this.storeRoot)
  public storeFlagsSaveOptions = new StoreFlags(this.storeRoot)

  // checkers / attackTypes selection
  public storeInputGenericCheckers = new StoreInputGenericCheckers<GC>(
    this.storeRoot,
    {
      checkerType: this.options.checkerType,
      selectable: false
    }
  )

  // search options
  public storeInputSearchOptions = new StoreInputSearch(this.storeRoot)

  // translator
  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Management.Accounts.Profiles.Configuration']
  })

  /* Observable */

  // StoreProfileCheckerSerie instances (one by configuration) for each checker
  private $storesProfileCheckerSerie = observable.map<
    GenericCheckerCodename,
    Array<StoreProfileCheckerSerie<GC, E>>
  >()

  // stores menu for each checker to navigate between configurations
  private $storesMenuCheckerSerie = observable.map<
    GenericCheckerCodename,
    // string is the index of the value of $storesProfileCheckerSerie (StoreProfileCheckerSerie[])
    StoreMenu<IMenuEntry<string>>
  >()

  /* Private */

  // mapping between generic checker and options
  private _genericCheckerOptions: Map<GenericCheckerCodename, E[]> = new Map()

  constructor(
    storeRoot: StoreRoot,
    options: WithOptionalInstanceName<IStoreInputGenericCheckersOptions<GC>>
  ) {
    super(storeRoot, {
      instanceName:
        options.instanceName ?? storeRoot.environment.getFirstInstanceName(),
      ...options
    })

    makeObservable(this)
  }

  /**
   * Fetch generic checkers and expand the list.
   */
  fetchGenericCheckers(profileId: number): Promise<any> {
    if (this.options.checkerType === CheckerType.Exposure) {
      const checkersQueryArgs: RbacCheckersQueryArgs = {
        profileId,
        // fetch all options
        optionsCodenames: []
      }

      this.storeFlagsFetchCheckers.loading()

      return this.storeInputGenericCheckers
        .fetchExposureCheckers(checkersQueryArgs)
        .then(() => this.storeInputGenericCheckers.showAllCheckers())
        .then(() => this.storeFlagsFetchCheckers.success())
        .catch(handleStoreError(this.storeRoot, this.storeFlagsFetchCheckers))
    }

    const attackTypesQueryArgs: RbacAttackTypesQueryArgs = {
      profileId,
      // fetch all options
      optionsCodenames: []
    }

    this.storeFlagsFetchCheckers.loading()

    return this.storeInputGenericCheckers
      .fetchAttackCheckers(attackTypesQueryArgs)
      .then(() => this.storeInputGenericCheckers.showAllCheckers())
      .then(() => this.storeFlagsFetchCheckers.success())
      .catch(handleStoreError(this.storeRoot, this.storeFlagsFetchCheckers))
  }

  /**
   * Fetch generic checkers options (staged or not).
   */
  fetchGenericCheckerOptions(
    genericCheckerId: number,
    checkerCodename: GenericCheckerCodename
  ): Promise<any> {
    const profileId =
      this.storeRoot.stores.storeManagementProfiles.currentProfileId

    if (!profileId) {
      this.storeRoot.logger.error('Current profile is not set.')
      return Promise.resolve()
    }

    this.storeFlagsFetchCheckerOptions.loading()

    if (this.options.checkerType === CheckerType.Exposure) {
      const checkerOptionsQueryArgs: CheckerOptionsQueryArgs = {
        profileId,
        checkerId: genericCheckerId
      }

      return this.storeRoot
        .getGQLRequestor()
        .query<QueryCheckerOptions>(
          queryCheckerOptions,
          checkerOptionsQueryArgs
        )
        .then(data => data.checkerOptions)
        .then(checkerOptions => {
          const checkerOptionsEntities = createEntities<CheckerOption, E>(
            EntityCheckerOption,
            checkerOptions
          )

          this._genericCheckerOptions.set(
            checkerCodename,
            checkerOptionsEntities
          )

          this.storeFlagsFetchCheckerOptions.success()
        })
        .catch(
          handleStoreError(this.storeRoot, this.storeFlagsFetchCheckerOptions)
        )
    }

    const attackTypeOptionsQueryArgs: AttackTypeOptionsQueryArgs = {
      profileId,
      attackTypeId: genericCheckerId
    }

    return this.storeRoot
      .getGQLRequestor()
      .query<QueryAttackTypeOptions>(
        queryAttackTypeOptions,
        attackTypeOptionsQueryArgs
      )
      .then(data => data.attackTypeOptions)
      .then(attackTypeOptions => {
        const attackTypeOptionsEntities = createEntities<AttackTypeOption, E>(
          EntityAttackTypeOption,
          attackTypeOptions
        )

        this._genericCheckerOptions.set(
          checkerCodename,
          attackTypeOptionsEntities
        )

        this.storeFlagsFetchCheckerOptions.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsFetchCheckerOptions)
      )
  }

  /**
   * Save checker-options as draft for the profile `checkerCodename`.
   */
  saveCheckerOptionsAsDraft(
    profileId: number,
    checkerId: number,
    checkerCodename: GenericCheckerCodename
  ): Promise<any> {
    this.storeFlagsSaveOptions.loading()

    return Promise.resolve()
      .then(() => {
        const storesProfileCheckerSerie =
          this.getStoresProfileCheckerSerie(checkerCodename)

        if (!storesProfileCheckerSerie) {
          throw new Error('No serie has been defined')
        }

        const checkerOptions = buildCheckerOptionsForDraft(
          profileId,
          storesProfileCheckerSerie
        )

        if (this.options.checkerType === 'identity') {
          throw new Error('No option available for this indicator')
        }

        const args: SetProfileCheckerOptionsMutationArgs = {
          profileId,
          checkerType: this.options.checkerType,
          checkerId,
          checkerOptions
        }

        // validate the options
        if (
          !this.validateCheckerOptions(checkerCodename, args.checkerOptions)
        ) {
          throw new ApplicationError({
            errorValue: ApplicationErrorValue.ValidationError,
            message: `Checker options validation has failed`
          })
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationSetProfileCheckerOptions>(
            mutationSetProfileCheckerOptions,
            args
          )
      })
      .then(response => {
        if (!response.setProfileCheckerOptions) {
          throw new Error('An error has occurred when saving checker options')
        }

        this.storeRoot.stores.storeMessages.success(
          this.translate('Options have been saved with success'),
          {
            labelledBy: 'optionsSaved'
          }
        )

        this.storeFlagsSaveOptions.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsSaveOptions, {
          errorMessageTranslationFn: err => {
            return isApplicationError(
              err,
              ApplicationErrorValue.ValidationError
            )
              ? // no message, let `this.validateCheckerOptions` prints the error
                false
              : // generic error
                this.translate(err.message)
          },
          // forward to the caller to avoid to close the drawer
          forwardExceptionFn: () =>
            'An error has occurred when saving the options of the profile'
        })
      )
  }

  /**
   * Validate checker options that will be send to the API.
   */
  validateCheckerOptions(
    checkerCodename: GenericCheckerCodename,
    checkerOptions: SetProfileCheckerOptionsMutationArgs['checkerOptions']
  ): boolean {
    const optionCodename =
      getDuplicatedCheckerOptionsForASameDirectory(checkerOptions)

    if (!optionCodename) {
      return true
    }

    const storeProfileCheckerSeries =
      this.getStoresProfileCheckerSerie(checkerCodename)

    if (!storeProfileCheckerSeries || !storeProfileCheckerSeries.length) {
      return true
    }

    const entityCheckerOptions =
      storeProfileCheckerSeries[0].options.serieCheckerOptions.get(
        optionCodename
      )

    if (!entityCheckerOptions) {
      return true
    }

    const errorMessage = this.translate(
      'The option "X" is duplicated with different values for a same domain',
      {
        interpolations: {
          checkerOptionCodename: entityCheckerOptions.getPropertyAsString(
            'name',
            '?'
          )
        },
        transformMarkdown: true
      }
    )

    this.storeRoot.stores.storeMessages.error(errorMessage, {
      duration: 10,
      customIcon: 'error',
      html: true,
      labelledBy: 'optionDuplicatedSameDirectoryError'
    })

    return false
  }

  /**
   * Return the stores series according to `checkerCodename`.
   */
  getStoresProfileCheckerSerie(
    checkerCodename: GenericCheckerCodename
  ): Array<StoreProfileCheckerSerie<GC, E>> {
    return ensureArray(this.$storesProfileCheckerSerie.get(checkerCodename))
  }

  /**
   * Return the stores menu according to `checkerCodename`.
   */
  getOrSetStoresMenuCheckerSerie(
    checkerCodename: GenericCheckerCodename
  ): StoreMenu<IMenuEntry<string>> {
    const storeMenu = this.$storesMenuCheckerSerie.get(checkerCodename)

    if (!storeMenu) {
      const newStoreMenu = new StoreMenu<IMenuEntry<string>>(this.storeRoot)
      this.$storesMenuCheckerSerie.set(checkerCodename, newStoreMenu)
      return newStoreMenu
    }

    return storeMenu
  }

  /**
   * Return the StoreProfileCheckerSerie according to the current menu selection.
   */
  getSelectedStoreProfileCheckerSerie(
    checkerCodename: GenericCheckerCodename
  ): Maybe<StoreProfileCheckerSerie<GC, E>> {
    const stores = this.getStoresProfileCheckerSerie(checkerCodename)

    const storeMenu = this.getOrSetStoresMenuCheckerSerie(checkerCodename)

    return stores[storeMenu.selectedEntryIndex] || null
  }

  /**
   * Create new checker options when adding a new refine configuration.
   */
  private _createNewCheckerOptionsForNewConfiguration(
    checkerCodename: GenericCheckerCodename
  ): Map<CheckerOptionCodename, E> {
    const isProfileDirty =
      this.storeRoot.stores.storeManagementProfiles.isCurrentProfileDirty

    const commitOrStagedCheckerOptions = ensureArray(
      this._genericCheckerOptions.get(checkerCodename)
    )
      // keep commit or staged options depending of the profile dirty state
      .filter(option => option.staged === isProfileDirty)

    const checkerOptionsByDirectories = groupCheckerOptionsByConfigurations<E>(
      this.options.checkerType,
      commitOrStagedCheckerOptions
    )

    // retrieve checkerOptions for "All directories" configuration
    const newCheckerOptions = checkerOptionsByDirectories[0].checkerOptions

    // remove the staged state
    for (const checkerOption of checkerOptionsByDirectories[0].checkerOptions.values()) {
      checkerOption.staged = false
    }

    return newCheckerOptions
  }

  /**
   * Add a new StoreProfileCheckerSerie instance to refine the configuration.
   */
  private _addNewStoresProfileCheckerSerie(
    entityChecker: GC['entityType'],
    isProfileDirty: boolean
  ) {
    const storesProfileCheckerSerie = this.getStoresProfileCheckerSerie(
      entityChecker.genericCodename
    )

    if (!storesProfileCheckerSerie.length) {
      this.createCheckerSerieStores(entityChecker.genericCodename)
      return
    }

    // TODO Compute directories that hasn't configuration yet in previous series
    const directoryIds = this.storeRoot.stores.storeInfrastructures.directoryIds

    const newCheckerOptions = this._createNewCheckerOptionsForNewConfiguration(
      entityChecker.genericCodename
    )

    const checkerOptions = ensureArray(
      this._genericCheckerOptions.get(entityChecker.genericCodename)
    )

    const storeProfileCheckerSerie = new StoreProfileCheckerSerie<GC, E>(
      this.storeRoot,
      {
        isProfileDirty,
        checker: entityChecker,
        directoryIds,
        checkerOptions,
        allDirectoriesCheckerOptions: newCheckerOptions,
        serieCheckerOptions: newCheckerOptions
      }
    ).init()

    storesProfileCheckerSerie.push(storeProfileCheckerSerie)

    this.$storesProfileCheckerSerie.set(
      entityChecker.genericCodename,
      storesProfileCheckerSerie
    )
  }

  /**
   * Add menu entries for each configuration.
   */
  private _setMenuEntries(
    checkerCodename: GenericCheckerCodename,
    series: Array<StoreProfileCheckerSerie<GC, E>>
  ): this {
    const storeMenu = this.getOrSetStoresMenuCheckerSerie(checkerCodename)

    storeMenu.reset()
    series.forEach(() => this._addMenuEntry(checkerCodename))
    storeMenu.selectFirstEntry()

    return this
  }

  /**
   * Add a new entry to the menu for a new configuration.
   */
  private _addMenuEntry(checkerCodename: GenericCheckerCodename): this {
    const storeMenu = this.getOrSetStoresMenuCheckerSerie(checkerCodename)

    const nextIndex =
      (storeMenu.menuEntries.length &&
        Math.max(...storeMenu.menuEntries.map(entry => Number(entry.key))) +
          1) ??
      0

    storeMenu.addMenuEntry({
      key: String(nextIndex),
      label: getConfigurationMenuTranslation(this.translate, nextIndex),
      labelledBy: getConfigurationMenuKey(nextIndex)
    })

    storeMenu.selectLastEntry()

    return this
  }

  /**
   * Delete an entry to the menu when removing a configuration.
   */
  private _deleteMenuEntry(
    checkerCodename: GenericCheckerCodename,
    configurationIndex: number
  ): this {
    const storeMenu = this.getOrSetStoresMenuCheckerSerie(checkerCodename)
    storeMenu.deleteMenuEntryIndex(configurationIndex)

    return this
  }

  /* Actions */

  /**
   * Reset everything.
   */
  @action
  reset(): void {
    this.storeInputGenericCheckers.reset()
    this.storeInputSearchOptions.reset()

    this.$storesProfileCheckerSerie.clear()
    this.$storesMenuCheckerSerie.clear()

    this._genericCheckerOptions.clear()
  }

  /**
   * Delete the stores in charge of the series configuration for a checker.
   */
  @action
  resetSeries(checkerCodename: GenericCheckerCodename): void {
    this.$storesProfileCheckerSerie.delete(checkerCodename)
    this.$storesMenuCheckerSerie.delete(checkerCodename)
  }

  /**
   * Create stores of the different configuration.
   */
  @action
  createCheckerSerieStores(checkerCodename: GenericCheckerCodename): void {
    const isProfileDirty =
      this.storeRoot.stores.storeManagementProfiles.isCurrentProfileDirty

    const genericChecker =
      this.storeInputGenericCheckers.checkersByCodename.get(checkerCodename)

    if (!genericChecker) {
      return
    }

    const genericCheckerId = genericChecker.getPropertyAsNumber('id')

    this.fetchGenericCheckerOptions(genericCheckerId, checkerCodename).then(
      () => {
        const checkerOptions = ensureArray(
          this._genericCheckerOptions.get(checkerCodename)
        )

        // keep commit or staged options depending of the profile dirty state
        const commitOrStagedCheckerOptions =
          filterCheckerOptionsAccordingProfileStatus(
            checkerOptions,
            isProfileDirty
          )

        const allDirectoriesCheckerOptions =
          retrieveAllDirectoriesCheckerOptionsOfChecker(
            commitOrStagedCheckerOptions
          )

        const checkerOptionsByDirectories =
          groupCheckerOptionsByConfigurations<E>(
            this.options.checkerType,
            commitOrStagedCheckerOptions
          )

        const series = checkerOptionsByDirectories.map(serie => {
          return new StoreProfileCheckerSerie(this.storeRoot, {
            isProfileDirty,
            checker: genericChecker,
            directoryIds: serie.directoryIds,
            checkerOptions,
            allDirectoriesCheckerOptions,
            serieCheckerOptions: serie.checkerOptions
          }).init()
        })

        this._setMenuEntries(checkerCodename, series)

        runInAction(() => {
          this.$storesProfileCheckerSerie.set(checkerCodename, series)
        })
      }
    )
  }

  /**
   * Create stores of checker of attack series.
   */
  @action
  createAttackTypeSerieStores(checkerCodename: GenericCheckerCodename): void {
    const isProfileDirty =
      this.storeRoot.stores.storeManagementProfiles.isCurrentProfileDirty

    const checker =
      this.storeInputGenericCheckers.checkersByCodename.get(checkerCodename)

    if (!checker) {
      return
    }

    const checkerId = checker.getPropertyAsNumber('id')

    this.fetchGenericCheckerOptions(checkerId, checkerCodename).then(() => {
      const checkerOptions = ensureArray(
        this._genericCheckerOptions.get(checkerCodename)
      )

      // keep commit or staged options depending of the profile dirty state
      const commitOrStagedCheckerOptions =
        filterCheckerOptionsAccordingProfileStatus(
          checkerOptions,
          isProfileDirty
        )

      const allDirectoriesCheckerOptions =
        retrieveAllDirectoriesCheckerOptionsOfChecker(
          commitOrStagedCheckerOptions
        )

      const checkerOptionsByDirectories =
        groupCheckerOptionsByConfigurations<E>(
          this.options.checkerType,
          commitOrStagedCheckerOptions
        )

      const series = checkerOptionsByDirectories.map(serie => {
        return new StoreProfileCheckerSerie(this.storeRoot, {
          isProfileDirty,
          checker,
          directoryIds: serie.directoryIds,
          checkerOptions,
          allDirectoriesCheckerOptions,
          serieCheckerOptions: serie.checkerOptions
        }).init()
      })

      this._setMenuEntries(checkerCodename, series)

      runInAction(() => {
        this.$storesProfileCheckerSerie.set(checkerCodename, series)
      })
    })
  }

  /**
   * Add a new serie (configuration) for the checker.
   */
  @action
  refineConfiguration(checkerCodename: GenericCheckerCodename): void {
    const isProfileDirty =
      this.storeRoot.stores.storeManagementProfiles.isCurrentProfileDirty

    if (!this.storeRoot.stores.storeInfrastructures.hasDirectories) {
      this.storeRoot.stores.storeMessages.info(
        this.translate(
          'You have to configure domains in order to be able to customize your profile'
        ),
        {
          labelledBy: 'configureDomainsToCustomizeProfile'
        }
      )
      return
    }

    const checker =
      this.storeInputGenericCheckers.checkersByCodename.get(checkerCodename)

    if (!checker) {
      return
    }

    this._addNewStoresProfileCheckerSerie(checker, isProfileDirty)
    this._addMenuEntry(checker.genericCodename)
  }

  /**
   * Flag a serie as deleted and override the serie in the store.
   */
  @action
  removeRefineConfiguration(
    checkerCodename: GenericCheckerCodename,
    serieNumber: number
  ): void {
    if (!this.$storesProfileCheckerSerie.has(checkerCodename)) {
      return
    }

    const storesProfileCheckerSerie =
      this.getStoresProfileCheckerSerie(checkerCodename)

    if (!storesProfileCheckerSerie) {
      return
    }

    const storeProfileCheckerSerie = storesProfileCheckerSerie[serieNumber]

    if (!storeProfileCheckerSerie) {
      return
    }

    // remove the store
    storesProfileCheckerSerie.splice(serieNumber, 1)

    this.$storesProfileCheckerSerie.set(
      checkerCodename,
      storesProfileCheckerSerie
    )

    this._deleteMenuEntry(checkerCodename, serieNumber)
  }
}
