import type {
  EntityGenericChecker,
  GenericCheckerCodename
} from '@app/entities/EntityGenericChecker/types'
import type { TStoreProfileGenericCheckers } from '@app/stores/Management/StoreProfiles/StoreProfileGenericCheckers'

/**
 * When closing the drawer of checker-options configuration for a checher,
 * reset the series for that checker.
 */
export const onOptionOnUnload =
  (storeProfileGenericChecker: TStoreProfileGenericCheckers) =>
  (checkerEntity: EntityGenericChecker) =>
  () => {
    storeProfileGenericChecker.storeInputSearchOptions.reset()
    storeProfileGenericChecker.resetSeries(checkerEntity.genericCodename)
  }

/**
 * Initialize series.
 */
export const onProfileCheckerConfigurationLoad =
  (storeProfileGenericCheckers: TStoreProfileGenericCheckers) =>
  (checkerCodename: GenericCheckerCodename) =>
  () => {
    storeProfileGenericCheckers.createCheckerSerieStores(checkerCodename)
  }

/**
 * Reset series.
 */
export const onProfileCheckerConfigurationUnload =
  (storeProfileGenericCheckers: TStoreProfileGenericCheckers) =>
  (checkerCodename: GenericCheckerCodename) =>
  () => {
    storeProfileGenericCheckers.resetSeries(checkerCodename)
  }

/**
 * Add a new serie of configuration.
 */
export const handleRefineConfigurationClick =
  (storeProfileGenericCheckers: TStoreProfileGenericCheckers) =>
  (checkerCodename: GenericCheckerCodename) =>
  () => {
    storeProfileGenericCheckers.refineConfiguration(checkerCodename)
  }

/**
 * Remove a serie of configuration.
 */
export const onRemoveRefineConfigurationClick =
  (storeProfileGenericCheckers: TStoreProfileGenericCheckers) =>
  (checkerCodename: GenericCheckerCodename, serieNumber: number) =>
  () => {
    storeProfileGenericCheckers.removeRefineConfiguration(
      checkerCodename,
      serieNumber
    )
  }

/**
 * Save checker-options as draft and refetch them to update the UI.
 */
export const onProfileSaveDraftClick =
  (storeProfileGenericCheckers: TStoreProfileGenericCheckers) =>
  (
    profileId: number,
    checkerId: number,
    checkerCodename: GenericCheckerCodename
  ) =>
  () => {
    const { storeManagementProfiles, storeBlades } =
      storeProfileGenericCheckers.storeRoot.stores

    const { storeProfileGenericCheckersSelected } = storeManagementProfiles

    storeProfileGenericCheckers
      .saveCheckerOptionsAsDraft(profileId, checkerId, checkerCodename)
      .then(() => storeBlades.closeLastBlade())
      .then(() => {
        return Promise.all([
          // reload profiles to display the new status (pending modifications or not)
          storeManagementProfiles.reloadProfiles(),
          // reload checkers to display their new status (disabled or not)
          storeProfileGenericCheckersSelected.storeInputGenericCheckers.fetchCheckers()
        ])
      })
      .catch(() => {
        // already catched in the store
      })
  }
