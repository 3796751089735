import type { MaybeUndef, PropertiesNullable } from '@@types/helpers'
import { getCriticityString } from '@libs/criticity'
import { sanitize } from '@libs/sanitize'
import type {
  AttackType,
  AttackTypeOption,
  AttackTypeVectorTemplateReplacements,
  Maybe,
  Resource
} from '@server/graphql/typeDefs/types'
import {
  CheckerRemediationCostLevel,
  CheckerType,
  Criticity
} from '@server/graphql/typeDefs/types'
import { createEntities, EntityAttackTypeOption } from '..'
import EntityBase from '../EntityBase'
import { CheckerOptionCodenameEnum } from '../EntityGenericCheckerOption/types'
import type { EntityProperties } from '../types'
import type {
  AttackTypeName,
  GenericCheckerCodename,
  IGenericChecker
} from './types'

export default class EntityAttackType
  extends EntityBase
  implements PropertiesNullable<AttackType>, IGenericChecker
{
  id: Maybe<number> = null
  name: Maybe<AttackTypeName> = null
  description: Maybe<string> = null
  mitreAttackDescription: Maybe<string> = null
  resources: Maybe<Resource[]> = null
  yaraRules: Maybe<string> = null
  vectorTemplate: Maybe<string> = null
  vectorTemplateReplacements: Maybe<AttackTypeVectorTemplateReplacements[]> =
    null
  options: Maybe<AttackTypeOption[]> = null

  /**
   * Used to differentiate checkerType that implements IGenericChecker.
   */
  type = CheckerType.Attack

  constructor(data: EntityProperties<AttackType>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return options entities.
   */
  getOptions(): EntityAttackTypeOption[] {
    return createEntities<AttackTypeOption, EntityAttackTypeOption>(
      EntityAttackTypeOption,
      this.options
    )
  }

  /**
   * Return the criticity option if found.
   */
  getCriticityOption(): MaybeUndef<EntityAttackTypeOption> {
    return this.getOptions()
      .filter(
        option => option.codename === CheckerOptionCodenameEnum.O_CRITICITY
      )
      .pop()
  }

  /** Implements IGenericChecker */

  get genericId() {
    return this.getPropertyAsString('id')
  }

  get genericName() {
    return this.getPropertyAsString('name')
  }

  get genericCodename(): GenericCheckerCodename {
    return sanitize(this.getPropertyAsString('name'))
  }

  /**
   * Return the criticity level from the criticity option value.
   */
  get genericCriticity(): Criticity {
    const criticity = this.getCriticityOption()

    if (!criticity) {
      return Criticity.Low
    }

    const criticityValue = Number(criticity.getValue())
    return getCriticityString(criticityValue)
  }

  /**
   * Return the remediation cost level.
   * No remediation cost on attacks (yet).
   */
  get genericRemediationCostLevel(): CheckerRemediationCostLevel {
    return CheckerRemediationCostLevel.Unknown
  }
}
