import { ErrorMessage, ErrorRbacDenyMessage } from '@app/components/Error'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import ContainerSpinner from '@app/components-legacy/Container/ContainerContent/ContainerSpinner'
import { SpinnerLogo } from '@app/components-legacy/Spinner'
import SpinnerLogoBlue from '@app/components-legacy/Spinner/SpinnerLogoBlue'
import type { IComponentLoaderProps } from '@app/components-legacy/types'
import { useLoader } from '@app/hooks/useLoader'
import { useStores } from '@app/hooks/useStores'
import { mergeFlags } from '@app/stores/helpers/StoreFlags/helpers'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { ensureArray } from '@libs/ensureArray'
import type { RbacCapabilityCheck } from '@libs/rbac/types'
import { computeComponentName } from '@libs/react-helpers/computeComponentName'
import type { Maybe } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex, ContainerFlexLegacy, ContainerOpacity } from '..'
import { TypographySubTitle } from '../../Typography'
import type { IContainerFlexProps } from '../ContainerFlex/types'

const StyledLabel = styled(Label)`
  display: flex;
`

interface IContainerContentProps extends IComponentLoaderProps {
  name?: string
  labelledBy?: string
  style?: React.CSSProperties
  className?: string
  title?: React.ReactNode
  fixedHeight?: number
  fullHeight?: boolean
  spinner?: React.ReactNode
  customErrorMessage?: Maybe<string>
  children: React.ReactNode
  rbacCapabilityCheck?: RbacCapabilityCheck
  spaceWidth?: IContainerFlexProps['spaceWidth']
  onError?: () => React.ReactNode
  onDeny?: () => React.ReactNode
}

/**
 * @deprecated
 * Container that allows to:
 * - display a spinner according to IFlags object,
 * - display an error
 * - display a title
 * - display children
 */
const ContainerContent: React.FC<IContainerContentProps> = props => {
  useLoader(props)

  const { storeRbac } = useStores()

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const flags = mergeFlags(...ensureArray(props.flags))

  const renderError = () => {
    if (props.onError) {
      return props.onError()
    }

    if (props.customErrorMessage) {
      return <ErrorMessage message={props.customErrorMessage} />
    }

    return <ErrorMessage />
  }

  const renderDeny = () => {
    if (props.onDeny) {
      return props.onDeny()
    }

    return <ErrorRbacDenyMessage />
  }

  const getPreloadItems = () => {
    if (flags.isLoading) {
      return (
        <ContainerSpinner>
          {props.spinner ||
            (themeIsLegacy ? <SpinnerLogo /> : <SpinnerLogoBlue />)}
        </ContainerSpinner>
      )
    }

    if (flags.isError) {
      return renderError()
    }

    if (flags.isForbidden) {
      return renderDeny()
    }

    const isDenied =
      props.rbacCapabilityCheck &&
      !storeRbac.isUserGrantedTo(props.rbacCapabilityCheck)

    if (isDenied) {
      return renderDeny()
    }

    return null
  }

  const fullHeight = props.fullHeight ?? true
  const preLoadItems = getPreloadItems()

  if (preLoadItems) {
    return (
      <ContainerFlex
        name={computeComponentName('ContainerContent', props.name)}
        style={props.style}
        className={props.className}
        // display error at the top of the page,
        alignItems={flags.isError ? 'flex-start' : 'center'}
        justifyContent={flags.isLoading ? 'center' : 'flex-start'}
        items={[preLoadItems]}
        fullHeight={fullHeight}
      />
    )
  }

  return (
    <ContainerOpacity
      name={computeComponentName('ContainerContent', props.name)}
      labelledBy={props.labelledBy}
      className={props.className}
      style={props.style}
      fullHeight={fullHeight}
      fade={props.fade ?? true}
    >
      <ContainerFlexLegacy
        name="ContainerFlexLegacy"
        items={[
          props.title &&
            (themeIsLegacy ? (
              <TypographySubTitle className="container-content-title">
                {props.title}
              </TypographySubTitle>
            ) : (
              <StyledLabel
                variant={LabelVariant.h2}
                className="container-content-title"
              >
                {props.title}
              </StyledLabel>
            )),
          ...React.Children.toArray(props.children)
        ]}
        spaced
        spaceWidth={props.spaceWidth || 'default'}
        fullHeight={fullHeight}
      />
    </ContainerOpacity>
  )
}

export default styled(ContainerContent)`
  ${props => (props.fixedHeight ? `min-height: ${props.fixedHeight}px` : '')};
  ${props => (props.fixedHeight ? `max-height: ${props.fixedHeight}px` : '')};
`
