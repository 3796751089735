import {
  ContainerFlex,
  ContainerFooter,
  ContainerRbac
} from '@app/components-legacy/Container'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import { ButtonVariant } from '@app/components-legacy/Form/Wrappers/types'
import {
  WidgetListCountItems,
  WidgetListSelectAllCheckbox
} from '@app/components-legacy/Widgets/WidgetList'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { canRenewRelaysApiKeys } from '@app/pages/Management/SystemPage/Relays/permissions'
import RelaysModalConfirmRenewApiKey from '@app/pages/Management/SystemPage/Relays/RelaysPage/RelaysModalConfirmRenewApiKey'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleRelaysRenewApiKeys } from './handlers'

interface IRelaysFooterProps {
  className?: string
}

const RelaysFooter: React.FC<IRelaysFooterProps> = () => {
  const { storeManagementRelays } = useStores()

  const translate = useAppTranslator({
    namespaces: ['Management.System.Relays']
  })

  const { storeWidgetList } = storeManagementRelays

  return (
    <ContainerRbac rbacCapabilityCheck={canRenewRelaysApiKeys()}>
      <ContainerFooter>
        <ContainerFlex
          name={RelaysFooter.name}
          direction="column"
          items={[
            <ContainerFlex
              name="RelaysFooterFirst"
              alignItems="center"
              justifyContent="space-between"
              items={[
                <ContainerFlex
                  name="RelaysFooterSelector"
                  alignItems="center"
                  flexWrap="wrap"
                  items={[
                    <WidgetListSelectAllCheckbox
                      disableSelectAllPages
                      storeWidgetList={storeWidgetList}
                    />,

                    <WidgetListCountItems storeWidgetList={storeWidgetList} />
                  ]}
                  spaced
                />,

                <FormWrapperButton
                  labelledBy="renewRelayApiKeys"
                  variant={ButtonVariant.primary}
                  buttonProps={{
                    loading:
                      storeManagementRelays.storeFlagsRelaysRenewApiKey.flags
                        .isLoading,
                    disabled:
                      !storeManagementRelays.storeWidgetList.isSomeRowsSelected,
                    onClick: handleRelaysRenewApiKeys(storeManagementRelays)
                  }}
                >
                  {translate('Renew API Keys')}
                </FormWrapperButton>
              ]}
              spaced
              spaceWidth="small"
            />
          ]}
          spaced
          spaceWidth="small"
          fullWidth
        />
      </ContainerFooter>

      <RelaysModalConfirmRenewApiKey />
    </ContainerRbac>
  )
}

export default observer(RelaysFooter)
