import type { defaultTheme } from './default'

export type DSThemeDefinition = typeof defaultTheme

export enum DSThemeName {
  // older Alsid for AD / T.AD theming
  legacy = 'legacy',
  // Legacy theme for secondary pages on grey backgrounds.
  grey = 'grey',
  // Paladin theme
  paladin = 'paladin',
  // new Exposure View theming (TODO rename "default" to "exposure")
  default = 'default'
}

export type DSTheme = {
  dsTheme: DSThemeName
} & DSThemeDefinition
