import * as React from 'react'

export interface IGaugeHighProps {}

export default (props: IGaugeHighProps) => (
  <svg width={24} height={24}>
    <defs>
      <path
        d="M10.055.062a9.846 9.846 0 0 0-8.153 15.366l1.452-1.145a8 8 0 1 1 13.403 0l1.452 1.145A9.846 9.846 0 0 0 10.055.062z"
        id="gauge-high-prefix__a"
      />
      <path
        d="M10.055.062a9.846 9.846 0 0 0-8.153 15.366l2.92-2.28A6.157 6.157 0 1 1 15.44 6.91l3.237-1.763A9.846 9.846 0 0 0 10.055.062zm5.376 6.87L9.449 8.778a1.23 1.23 0 1 0 1.173 2.154l4.809-4z"
        id="gauge-high-prefix__c"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(2 4)">
        <mask id="gauge-high-prefix__b" fill="#fff">
          <use xlinkHref="#gauge-high-prefix__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#gauge-high-prefix__a" />
        <g mask="url(#gauge-high-prefix__b)" fill="#9DA5B3">
          <path d="M-2-4h24v24H-2z" />
        </g>
      </g>
      <g transform="translate(2 4)">
        <mask id="gauge-high-prefix__d" fill="#fff">
          <use xlinkHref="#gauge-high-prefix__c" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#gauge-high-prefix__c" />
        <g mask="url(#gauge-high-prefix__d)" fill="#606B80">
          <path d="M-2-4h24v24H-2z" />
        </g>
      </g>
    </g>
  </svg>
)
