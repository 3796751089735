import {
  IconBackgroundCircle,
  IconBurger,
  IconBurgerClose
} from '@app/components/Icon'
import type { IContainerSimpleProps } from '@app/components-legacy/Container/ContainerSimple'
import FormWrapperButtonSimple from '@app/components-legacy/Form/Wrappers/ButtonSimple'
import { useStores } from '@app/hooks'
import { TenableButtonsColor } from '@app/styles/consts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { onBurgerMenuClick } from './handlers'

interface IHeaderLogoProps extends IContainerSimpleProps {}

const IconBackground = styled(IconBackgroundCircle)`
  display: none;
  z-index: 0;
`

const BurgerMenu: React.FC<IHeaderLogoProps> = props => {
  const { storeLayout } = useStores()

  const { expanded } = storeLayout.storeMenuMainNavigation

  return (
    <FormWrapperButtonSimple
      name={BurgerMenu.name}
      labelledBy="toggleMainMenu"
      onClick={onBurgerMenuClick(storeLayout.storeMenuMainNavigation)}
      {...props}
    >
      <IconBackground
        className={`iconBackground ${expanded ? 'activated' : ''}`}
      />

      <IconBurger
        labelledBy="iconBurger"
        className={`${!expanded ? 'active' : ''}`}
      />

      <IconBurgerClose
        labelledBy="iconBurgerClose"
        className={`${expanded ? 'active' : ''}`}
      />
    </FormWrapperButtonSimple>
  )
}

const ObservedBurgerMenu = observer(BurgerMenu)

export default styled(ObservedBurgerMenu)`
  height: 32px;
  width: 32px;
  position: relative;
  margin-left: 19px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg:not(.iconBackground) {
    width: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    &.active {
      width: 20px;
    }
  }

  .iconBackground {
    display: none;

    circle {
      fill: ${TenableButtonsColor.backgroundButtonFloatingHover};
    }

    &.activated {
      display: block;

      circle {
        fill: ${TenableButtonsColor.backgroundButtonFloatingSelected};
      }
    }
  }

  &:hover .iconBackground,
  &:active .iconBackground,
  &:focus:not([data-focus-method='mouse']) .iconBackground {
    display: block;
  }

  &:hover .iconBackground.activated circle,
  &:focus:not([data-focus-method='mouse']) .iconBackground.activated circle {
    fill: ${TenableButtonsColor.backgroundButtonFloatingHover};
  }

  &:active .iconBackground circle,
  &:active .iconBackground.activated circle {
    fill: ${TenableButtonsColor.backgroundButtonFloatingSelected};
  }
`
