import type { Maybe } from '@@types/helpers'
import { Features } from '@alsid/common'
import { ContainerFlex } from '@app/components/Container'
import ExposureScore from '@app/components/Visualization/ExposureScore'
import { IDENTIFIER_COLUMN_NAME } from '@app/components-legacy/TableNew/consts'
import { Table as TableNew } from '@app/components-legacy/TableNew/Table'
import type {
  ITableColumnSorter,
  TableColumnsProperties,
  TableRowData
} from '@app/components-legacy/TableNew/types'
import type EntityIdentity from '@app/entities/EntityIdentity'
import { useAppTranslator, useStores } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import type { StoreIdentityExplorer } from '@app/stores'
import type { IFlags } from '@app/stores/helpers/StoreFlags/types'
import type { Score, Tenant } from '@libs/openapi/service-identity-core'
import { GetIdentitiesSortByEnum } from '@libs/openapi/service-identity-core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canSeeIdentityExposureScore } from '../permissions'
import { getTenantsByProviderType } from '../utils'
import { handleIdentityExplorerOnColumnSort } from './handlers'
import Tenants from './Tenants'

export interface ITableIdentityExplorer {
  name: string
  tenants: Tenant[]
  exposureScore: Score
  openedRisksCount: number
  totalAccessibleResources: number
}

export interface ITableProps {
  className?: string
  style?: React.CSSProperties
  identitiesEntities: EntityIdentity[]
  flags?: IFlags | IFlags[]
}

const getColumnSorter = (
  storeIdentityExplorer: StoreIdentityExplorer,
  identityColumnOrder: GetIdentitiesSortByEnum,
  identityExplorerOrderingFeatureToggle: boolean
): Maybe<ITableColumnSorter> => {
  if (!identityExplorerOrderingFeatureToggle) {
    return null
  }
  if (
    storeIdentityExplorer.storeWidgetListIdentities.columnSorterState
      ?.columnId === identityColumnOrder
  ) {
    return {
      sortingOrder:
        storeIdentityExplorer.storeWidgetListIdentities.columnSorterState
          .sortingOrder,
      sorterFn: handleIdentityExplorerOnColumnSort(storeIdentityExplorer)(
        identityColumnOrder
      )
    }
  }
  return {
    sorterFn: handleIdentityExplorerOnColumnSort(storeIdentityExplorer)(
      identityColumnOrder
    )
  }
}

const Table: React.FC<ITableProps> = props => {
  const { storeRbac, storeAbout, storeIdentityExplorer } = useStores()

  const identityExplorerOrdering = useFeatureFlag({
    featureFlagName: Features.IDENTITY_EXPLORER_ORDERING
  })

  const displayBetaLabel = useFeatureFlag({
    featureFlagName: Features.BETA_ON_AES_SCORE
  })

  const translate = useAppTranslator({
    namespaces: ['IdentityExplorer.Table', 'IdentityExplorer.Providers']
  })

  const columns: TableColumnsProperties<ITableIdentityExplorer> = {
    name: {
      name: translate('Identity name'),
      relativeWidth: displayBetaLabel ? 2 : 2.5,
      sorter: getColumnSorter(
        storeIdentityExplorer,
        GetIdentitiesSortByEnum.Name,
        identityExplorerOrdering
      )
    },
    tenants: {
      name: translate('Account provider'),
      render: tenants => (
        <ContainerFlex
          name="AccountProviders"
          flexGap="small"
          flexWrap="nowrap"
          flexGrow="1"
          items={Array.from(getTenantsByProviderType(tenants).entries()).map(
            ([providerType, tenants]) => {
              return (
                <Tenants
                  key={providerType}
                  providerType={providerType}
                  tenants={tenants}
                />
              )
            }
          )}
        />
      )
    },
    exposureScore: {
      name: translate('Exposure score'),
      hidden: !storeRbac.isUserGrantedTo(
        canSeeIdentityExposureScore(storeAbout.productAssociation)
      ),
      isBeta: true,
      relativeWidth: displayBetaLabel ? 1.3 : undefined,
      sorter: getColumnSorter(
        storeIdentityExplorer,
        GetIdentitiesSortByEnum.ExposureScore,
        identityExplorerOrdering
      ),
      render: exposureScore => {
        return <ExposureScore {...exposureScore} />
      }
    },
    openedRisksCount: {
      name: translate('Open risks'),
      sorter: getColumnSorter(
        storeIdentityExplorer,
        GetIdentitiesSortByEnum.OpenedRisksCount,
        identityExplorerOrdering
      )
    },
    totalAccessibleResources: {
      name: translate('Total accessible resources'),
      sorter: getColumnSorter(
        storeIdentityExplorer,
        GetIdentitiesSortByEnum.TotalAccessibleResources,
        identityExplorerOrdering
      )
    }
  }

  const data: Array<TableRowData<ITableIdentityExplorer>> =
    props.identitiesEntities.map(identityEntity => {
      return {
        [IDENTIFIER_COLUMN_NAME]: identityEntity.getPropertyAsString('uuid'),
        name: identityEntity.getPropertyAsString('name'),
        tenants: identityEntity.getPropertyAsArrayOf<Tenant>('tenants'),
        exposureScore: identityEntity.getPropertyAsT<Score>('exposureScore'),
        openedRisksCount:
          identityEntity.getPropertyAsNumber('openedRisksCount'),
        totalAccessibleResources: identityEntity.getPropertyAsNumber(
          'totalAccessibleResources'
        )
      }
    })

  return (
    <TableNew
      labelledBy="identityExplorer"
      className={props.className}
      style={props.style}
      columns={columns}
      data={data}
      flags={props.flags}
    />
  )
}

export default observer(Table)
