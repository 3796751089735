import { ErrorMessage } from '@app/components/Error'
import type StoreWizard from '@app/stores/helpers/StoreWizard'
import type { WizardRuleGroup } from '@app/stores/helpers/StoreWizard/types'
import { WizardRuleGroupFieldName } from '@app/stores/helpers/StoreWizard/types'
import { isWizardRuleGroup } from '@app/stores/helpers/StoreWizard/utils'
import type { Combinator } from '@libs/Expression/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import ButtonGroup from './ButtonGroup'
import RuleGroup from './RuleGroup'
import RuleOrRuleGroup from './RuleOrRuleGroup'
import { isFirstRuleGroupLevel } from './utils'

interface IRuleGroupFieldsetProps {
  className?: string
  enableIsDeviantSwitch: boolean
  ruleGroupPath: number[]
  ruleGroup: WizardRuleGroup
  storeWizard: StoreWizard
}

const RuleGroupFieldset: React.FC<IRuleGroupFieldsetProps> = props => {
  const { storeForm, rules } = props.ruleGroup

  if (!isWizardRuleGroup(props.ruleGroup)) {
    return <ErrorMessage />
  }

  const parentCombinator = storeForm.getFieldValueAsString<Combinator>(
    WizardRuleGroupFieldName.combinator
  )

  const hasNoRules = !rules.length
  const isFirstLevel = isFirstRuleGroupLevel(props.ruleGroupPath)

  if (!isFirstLevel && hasNoRules) {
    return null
  }

  const renderFields = () => {
    return rules.map((ruleOrRuleGroup, index) => {
      const isFirst = index === 0
      const isLast = rules.length === index + 1

      const uniqueKey = props.ruleGroupPath.concat(index).join('-')

      return (
        <span key={uniqueKey}>
          {isFirst && (
            <RuleGroup
              index={index}
              ruleGroupPath={props.ruleGroupPath}
              enableIsDeviantSwitch={props.enableIsDeviantSwitch}
              ruleGroup={props.ruleGroup}
              storeWizard={props.storeWizard}
            />
          )}

          <RuleOrRuleGroup
            index={index}
            ruleGroupPath={props.ruleGroupPath}
            enableIsDeviantSwitch={props.enableIsDeviantSwitch}
            ruleOrRuleGroup={ruleOrRuleGroup}
            parentCombinator={parentCombinator}
            storeWizard={props.storeWizard}
          />

          {isLast && (
            <ButtonGroup
              ruleGroupPath={props.ruleGroupPath}
              ruleGroup={props.ruleGroup}
              storeWizard={props.storeWizard}
            />
          )}
        </span>
      )
    })
  }

  return (
    <div className={props.className} data-name="RuleGroupFieldset">
      {hasNoRules && (
        <RuleGroup
          index={0}
          ruleGroupPath={props.ruleGroupPath}
          enableIsDeviantSwitch={props.enableIsDeviantSwitch}
          ruleGroup={props.ruleGroup}
          storeWizard={props.storeWizard}
        />
      )}

      {renderFields()}

      {hasNoRules && (
        <ButtonGroup
          ruleGroupPath={props.ruleGroupPath}
          ruleGroup={props.ruleGroup}
          storeWizard={props.storeWizard}
        />
      )}
    </div>
  )
}

const ObservedRuleGroupFieldset = observer(RuleGroupFieldset)

export default styled(ObservedRuleGroupFieldset)`
  border: 1px solid
    ${props => props.theme.tokens['inputExpression/borderColor/default']};
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`
