import { ContainerFooter } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { AppRouteName } from '@app/routes'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import ProductLicenseUploadButton from './ProductLicenseUploadButton'

interface IProductLicenseUpdateProps {}

const ProductLicenseUpdate: React.FC<IProductLicenseUpdateProps> = () => {
  return (
    <Portal name={PlaceHolderName.bladeFooter}>
      <ContainerFooter>
        <DSThemeProvider themeName={DSThemeName.default}>
          <ProductLicenseUploadButton
            redirectRouteDefinition={{
              routeName: AppRouteName.Management_System_About,
              parameters: {}
            }}
          />
        </DSThemeProvider>
      </ContainerFooter>
    </Portal>
  )
}

export default observer(ProductLicenseUpdate)
