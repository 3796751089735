import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks/useStores'
import MenuSystem from '@app/pages/Management/SystemPage/MenuSystem'
import { AppRouteName } from '@app/routes'
import type { IManagementSystemInfrastructuresEditRouteDefinition } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import InfrastructuresCreatePage from '../InfrastructuresCreatePage'
import InfrastructuresEditPage from '../InfrastructuresEditPage'
import {
  canAccessToInfrastructures,
  canCreateInfrastructures,
  canEditInfrastructures
} from '../permissions'
import DrawerActionDeleteInfrastructure from './DrawerActionDeleteInfrastructure'
import { onInfrastructuresLoad, onInfrastructuresUnload } from './handlers'
import InfrastructuresList from './InfrastructuresList'

interface IInfrastructuresPageProps {}

const InfrastructuresPage: React.FC<IInfrastructuresPageProps> = props => {
  const { storeManagementInfrastructures } = useStores()

  return (
    <>
      <Blade
        root={true}
        routeDefinition={{
          routeName: AppRouteName.Management_System_Infrastructures,
          parameters: {
            infrastructureName: String()
          }
        }}
        onLoad={onInfrastructuresLoad(storeManagementInfrastructures)}
        onUnload={onInfrastructuresUnload(storeManagementInfrastructures)}
        flags={storeManagementInfrastructures.storeFlags.flags}
        layout={{
          name: 'default',
          menu: <MenuSystem />,
          content: (
            <ContainerContent>
              <InfrastructuresList />
            </ContainerContent>
          ),
          footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
        }}
        rbacCapabilityCheck={canAccessToInfrastructures}
      />

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerActionDeleteInfrastructure />
      </Portal>

      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName: AppRouteName.Management_System_Infrastructures_Create,
              parameters: {}
            },
            component: InfrastructuresCreatePage,
            rbacPermissionsCheck: () => canCreateInfrastructures
          },
          {
            routeDefinition: {
              routeName: AppRouteName.Management_System_Infrastructures_Edit,
              parameters: {
                infrastructureId: Number()
              }
            },
            component: InfrastructuresEditPage,
            rbacPermissionsCheck: (
              parameters: IManagementSystemInfrastructuresEditRouteDefinition['parameters']
            ) => canEditInfrastructures(parameters.infrastructureId)
          }
        ]}
      />
    </>
  )
}

export default observer(InfrastructuresPage)
