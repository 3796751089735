import * as React from 'react'
import { setApiInspectorTableColumnsStyled } from './styles'

export interface ITableRowProps {
  className?: string
  children?: React.ReactNode
}

const TableRow: React.FC<ITableRowProps> = props => {
  return (
    <div data-name="TableRow" className={props.className}>
      {props.children}
    </div>
  )
}

export default setApiInspectorTableColumnsStyled(TableRow)
