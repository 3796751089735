import { ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import type EntityHealthCheckDomainStatusReason from '@app/entities/EntityHealthCheckDomainStatusReason'
import { useAppTranslator } from '@app/hooks'
import HealthCheckReasonBodyLabel from '@app/pages/HealthCheck/Labels/HealthCheckReasonLabel'
import { HealthCheckReasonLocale } from '@app/pages/HealthCheck/Labels/types'
import { consts } from '@app/styles'
import { HealthCheckStatus } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'
import { formatHealthCheckAttributes } from '../helpers'

const StyledContainerFlex = styled(ContainerFlex)`
  margin: ${consts.marginVerySmall} 0;
`

interface IHealthCheckReasonBodyProps {
  healthCheckName: string
  reason: EntityHealthCheckDomainStatusReason
}

export function HealthCheckReasonBody(props: IHealthCheckReasonBodyProps) {
  const translate = useAppTranslator({
    namespaces: ['HealthCheck.Domains', 'HealthCheck.Details']
  })

  const reasonName = props.reason.getPropertyAsString('name')

  const interpolations = formatHealthCheckAttributes(
    props.reason.getAttributes()
  )

  return (
    <StyledContainerFlex
      name="HealthCheckReasonBody"
      direction="column"
      items={[
        <HealthCheckReasonBodyLabel
          locale={
            props.reason.status === HealthCheckStatus.Warning ||
            props.reason.status === HealthCheckStatus.Failure
              ? HealthCheckReasonLocale.details
              : HealthCheckReasonLocale.successDetails
          }
          healthCheckName={props.healthCheckName}
          reasonName={reasonName}
          transformMarkdown
          interpolations={interpolations}
        />,

        (props.reason.status === HealthCheckStatus.Warning ||
          props.reason.status === HealthCheckStatus.Failure) && (
          <ContainerFlex
            name="HealthCheckReasonRecommendations"
            direction="column"
            items={[
              <Label variant={LabelVariant.p_bold}>
                {translate('Recommendations')}
              </Label>,

              <HealthCheckReasonBodyLabel
                locale={HealthCheckReasonLocale.remediation}
                healthCheckName={props.healthCheckName}
                reasonName={reasonName}
                transformMarkdown
                interpolations={interpolations}
              />
            ]}
            flexGap="small"
          />
        )
      ]}
      flexGap="smaller"
    />
  )
}
