import type { IComponentLoaderProps } from '@app/components-legacy/types'
import * as React from 'react'
import { useStores } from './useStores'

/**
 * Trigger onLoad / onUnload on mount / unmount.
 */
export function useLoader(props: IComponentLoaderProps) {
  const { storeRbac } = useStores()

  React.useEffect(() => {
    if (
      props.rbacCapabilityCheck &&
      !storeRbac.isUserGrantedTo(props.rbacCapabilityCheck)
    ) {
      return
    }

    if (props.onLoad) {
      props.onLoad()
    }

    return () => {
      if (
        props.rbacCapabilityCheck &&
        !storeRbac.isUserGrantedTo(props.rbacCapabilityCheck)
      ) {
        return
      }

      if (props.onUnload) {
        props.onUnload()
      }
    }
  }, [])
}
