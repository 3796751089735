import { LabelAlert } from '@app/components-legacy/Label'
import { LabelAlertSize } from '@app/components-legacy/Label/LabelAlert/types'
import { EntityAlertIoE } from '@app/entities'
import { useAppTranslator, useStores } from '@app/hooks'
import type StoreGenericAlerts from '@app/stores/StoreAlerts/StoreGenericAlerts'
import { ensureArray } from '@libs/ensureArray'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  onAlertIoESeeDevianceClick,
  onAlertSelection,
  onAlertUnselection
} from './handlers'

interface IAlertsContentItemIoEProps {
  alert: EntityAlertIoE
  storeGenericAlerts: StoreGenericAlerts<any>
}

const AlertsContentItemIoE: React.FC<IAlertsContentItemIoEProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Generics', 'Drawers.Alerts']
  })

  const { storeCheckers, storeAlerts, storeInfrastructures } = useStores()

  if (!(props.alert instanceof EntityAlertIoE)) {
    return null
  }

  const checker = props.alert.getChecker(
    storeCheckers.storeInputCheckersExposure.checkersById
  )

  if (!checker) {
    return null
  }

  const directories = ensureArray(
    props.alert.getDirectory(storeInfrastructures.directories)
  )

  const isChecked = props.storeGenericAlerts.storeWidgetList.isSelected(
    props.alert.asDataRow()
  )

  const label = props.alert.getLabel() || translate('Unknown')

  return (
    <LabelAlert
      key={props.alert.getPropertyAsString('id')}
      checkerType={CheckerType.Exposure}
      id={props.alert.getPropertyAsNumber('id')}
      size={LabelAlertSize.fullWidth}
      label={label}
      date={props.alert.date}
      criticity={checker.genericCriticity}
      directories={directories}
      checked={isChecked}
      read={props.alert.read === true}
      archived={props.alert.archived === true}
      isCheckable
      actions={[
        {
          label: translate('See the deviance'),
          key: 'seeDeviance'
        }
      ]}
      onAction={onAlertIoESeeDevianceClick(storeAlerts)(props.alert)}
      onSelections={{
        onSelection: onAlertSelection(props.storeGenericAlerts)(
          props.alert.getPropertyAsNumber('id')
        ),
        onUnselection: onAlertUnselection(props.storeGenericAlerts)(
          props.alert.getPropertyAsNumber('id')
        )
      }}
      storeInfrastructures={storeInfrastructures}
      storeGenericAlerts={props.storeGenericAlerts}
    />
  )
}

export default observer(AlertsContentItemIoE)
