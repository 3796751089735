import type { DeepPartial } from '@@types/helpers'
import { useStores } from '@app/hooks'
import { buildVariants } from '@design-system/libs/buildVariants'
import { deepMerge } from '@productive-codebases/toolbox'
import React from 'react'
import styled from 'styled-components'
import { useMountGlobalInventoryPage } from 'tenable-cyber-asset-management'
import { type IGlobalInventoryAppConfiguration } from 'tenable-cyber-asset-management/dist/common/src/libs/IframeCommunicationBus/types'
import { getDefaultGlobalInventorySdkConfiguration } from './defaultConfiguration'

export interface IPageGlobalInventoryProps {
  configuration?: DeepPartial<IGlobalInventoryAppConfiguration>
}

const Div = styled.div(props => {
  return buildVariants(props)
    .css({
      height: '100%',
      width: '100%'
    })
    .end()
})

export default function PageGlobalInventory(props: IPageGlobalInventoryProps) {
  const { storeRoot } = useStores()

  const globalInventorySdkConfiguration = deepMerge([
    getDefaultGlobalInventorySdkConfiguration(storeRoot),
    props.configuration
  ])

  const { globalInventoryRef } = useMountGlobalInventoryPage(
    globalInventorySdkConfiguration
  )

  return <Div ref={globalInventoryRef} id="GlobalInventoryContainer" />
}
