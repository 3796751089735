import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { canAccessToEventDetails } from '@app/pages/TrailFlow/permissions'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DetailsDeviantObjectsAttributesPage from '../DetailsDeviantObjectsAttributesPage'
import DeviantObjects from './DeviantObjects'

interface IDetailsDeviantObjectsPageProps {}

const DetailsDeviantObjectsPage: React.FC<
  IDetailsDeviantObjectsPageProps
> = () => {
  const appRouter = useAppRouter()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.IoE_Details_DeviantObjects,
    parameters: {
      checkerId: Number(),
      checkerCodename: String(),
      infrastructureId: Number(),
      directoryId: Number(),
      eventId: String()
    }
  })

  return (
    <>
      <DeviantObjects />

      {parameters && (
        <RbacRoutes
          routes={[
            {
              routeDefinition: {
                routeName:
                  AppRouteName.IoE_Details_DeviantObjects_EventDetails_Attributes,
                parameters: {
                  checkerId: parameters.checkerId,
                  checkerCodename: parameters.checkerCodename,
                  infrastructureId: parameters.infrastructureId,
                  directoryId: parameters.directoryId,
                  eventId: parameters.eventId
                }
              },
              component: DetailsDeviantObjectsAttributesPage,
              rbacPermissionsCheck: () => canAccessToEventDetails
            }
          ]}
        />
      )}
    </>
  )
}

export default observer(DetailsDeviantObjectsPage)
