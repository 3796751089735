import { Drawer, DrawerContext } from '@app/components-legacy/Drawer'
import { useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import SearchHistoryTimeline from './SearchHistoryTimeline'

interface IDrawerSearchHistoryTimelineProps {}

const DrawerSearchHistoryTimeline: React.FC<
  IDrawerSearchHistoryTimelineProps
> = () => {
  const { storeTrailFlow } = useStores()

  return (
    <DrawerContext.Provider
      value={{
        storeDrawer:
          storeTrailFlow.storeSearchHistory.storeSearchHistoryTimelineDrawer
      }}
    >
      <Drawer>
        <SearchHistoryTimeline />
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerSearchHistoryTimeline)
