import type { Maybe } from '@@types/helpers'
import * as moment from 'moment'

/**
 * Return an iso string to a timestamp.
 */
export function convertIsoStringToTimestamp(date: string): number {
  return moment(date).unix() * 1000
}

/**
 * Return an iso string to a timestamp as string.
 */
export function convertIsoStringToTimestampStr(date: string): string {
  return String(moment(date).unix() * 1000)
}

/**
 * Convert a timestamp to an iso string.
 */
export function convertTimestampToIsoString(
  timestamp: number | string
): string {
  return moment.unix(Number(timestamp) / 1000).toISOString()
}

/**
 * Split an interval. Example:
 *
 * '1d' => [1, 'd']
 */
export function splitDurationValue(
  duration: string
): Maybe<[value: number, unit: string]> {
  const [, value, unit] = Array.from(duration.match(/(\d+)(\w+)/) || [])

  if (!value || !unit) {
    return null
  }

  return [Number(value), unit]
}

/**
 * Add a duration to a date.
 */
export function addDuration(
  dateStart: string,
  duration: string | { [unit: string]: number }
): Maybe<string> {
  let durationObj: { [unit: string]: number }

  if (typeof duration === 'string') {
    const parts = splitDurationValue(duration)

    if (!parts) {
      return null
    }

    const [value, unit] = parts

    durationObj = {
      [unit]: value
    }
  } else {
    durationObj = duration
  }

  return moment.utc(dateStart).add(durationObj).toISOString()
}
