import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import * as React from 'react'

interface IIconVectorProps extends IIconProps {}

const IconVector: React.FC<IIconVectorProps> = props => {
  const fill = props.color ?? consts.BrandColorV2.gradientEnd
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <path
        d="M8.22541 0.800781H9.29828V4.01938H8.22541V0.800781ZM8.22541 10.4566H9.29828V13.6752H8.22541V10.4566ZM2.32465 7.77441V6.70154H5.54325V7.77441H2.32465ZM11.9804 7.77441V6.70154H15.199V7.77441H11.9804ZM3.83372 3.06391L4.58794 2.30969L6.86778 4.58953L6.11356 5.34375L3.83372 3.06391ZM10.6562 9.88673L11.4104 9.1325L13.6903 11.4123L12.9361 12.1666L10.6562 9.88673ZM1.55403 15.2008L0.799805 14.4466L6.11386 9.1325L6.86809 9.88673L1.55403 15.2008ZM11.4109 5.34391L10.6567 4.58968L12.9365 2.30984L13.6907 3.06407L11.4109 5.34391Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconVector
