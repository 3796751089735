import type {
  IAttackPathCell,
  IAttackPathCoords
} from '@app/pages/AttackPath/types'
import type { Maybe } from '@server/graphql/typeDefs/types'
import type EntityAttackPathNode from './EntityAttackPathNode'
import EntityBase from './EntityBase'

export default abstract class EntityAttackPathBaseNode extends EntityBase {
  uid: string = ''

  cell?: IAttackPathCell

  linkedNodeEntity: Maybe<EntityAttackPathNode> = null

  x: number = 0
  y: number = 0
  depth: number = 0
  isSourceOfExpansion: boolean = false
  isPartOfExpansion: boolean = false
  isHiddenNode: boolean = false
  isImportantNode: boolean = false

  placed: boolean = false

  constructor() {
    super()
  }

  setCell(cell: IAttackPathCell): this {
    this.cell = cell

    return this
  }

  get coords(): IAttackPathCoords {
    return {
      x: this.x,
      y: this.y
    }
  }
}
