import type { DeepPartial } from '@@types/helpers'
import type { DSTheme } from '@design-system/styles/themes/types'
import { DSThemeName } from '@design-system/styles/themes/types'
import { deepMerge } from '@productive-codebases/toolbox'

/**
 * /!\ Don't use for now, this function may cause mutations in your objects.
 */
export function mergeThemes<T>(objects: [obj1: T, obj2: DeepPartial<T>]): T {
  return deepMerge(objects)
}

/**
 * Return true if the current theme is legacy.
 */
export function isThemeLegacy(theme: DSTheme): boolean {
  return theme.dsTheme === DSThemeName.legacy
}
