import { ContainerContent } from '@app/components-legacy/Container'
import * as React from 'react'
import { canAccessToPreferences } from '../permissions'
import PreferencesProfilesForm from './PreferencesProfilesForm'

interface IPreferencesProfilesProps {}

const PreferencesProfiles: React.FC<IPreferencesProfilesProps> = props => {
  return (
    <ContainerContent rbacCapabilityCheck={canAccessToPreferences}>
      <PreferencesProfilesForm />
    </ContainerContent>
  )
}

export default PreferencesProfiles
