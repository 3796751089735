import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import { BrandColorV2, FontColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

const Color1Path = styled.path`
  fill: ${FontColorV2.primary};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${consts.colorWhite};
  }
`

const Color2Path = styled.path`
  fill: ${BrandColorV2.decorativeGray};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${consts.colorFadedBlueNewDesign};
  }
`

interface IIconAccountsProps extends IIconProps {}

const IconAccountsLegacy: React.FC<IIconAccountsProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Color2Path d="M13.5 0C15.433 0 17 1.567 17 3.5C17 5.433 15.433 7 13.5 7C13.1555 7 12.8226 6.95023 12.5082 6.85748C12.2533 5.51847 11.4168 4.23305 10 3L10.0314 3.02908C10.2614 1.31878 11.7267 0 13.5 0Z" />
      <Color2Path d="M12.8842 8.00015C16.8657 7.97753 19.2264 10.5813 20 14.9717L15.8792 14.99L12.2052 11.4998C12.3592 10.9556 12.4809 9.89625 12.5704 8.32177L12.5869 8.00671C12.685 8.00291 12.784 8.00071 12.8842 8.00015Z" />
      <Color1Path d="M9.85172 11.1787C10.3732 10.3154 10.633 9.31852 10.5995 8.30967C10.6121 6.91413 10.0715 5.57071 9.09653 4.57498C8.12157 3.57925 6.79214 3.01279 5.40074 3.00022H5.29391C3.90251 2.98753 2.56308 3.52977 1.57031 4.50764C0.577535 5.48551 0.0127525 6.81889 0.000217438 8.21444V8.30967C-0.0124321 9.70522 0.528199 11.0486 1.50316 12.0444C2.47813 13.0401 3.80756 13.6066 5.19896 13.6191H5.29391C6.39053 13.6418 7.46633 13.3169 8.36806 12.6906L10.8131 15.0715L9.6262 16.262L11.1217 17.7738L12.3087 16.5834L13.0446 17.3334L11.8576 18.5238L13.3532 20L16 17.3453L9.85172 11.1787ZM5.29391 5.11924C6.14146 5.10626 6.95809 5.43822 7.55747 6.03938C8.15684 6.64054 8.48782 7.4596 8.47488 8.30967C8.5471 9.49385 7.95823 10.6204 6.9461 11.2344C5.93396 11.8484 4.66573 11.8484 3.6536 11.2344C2.64146 10.6204 2.0526 9.49385 2.12482 8.30967C2.1248 6.55228 3.54175 5.1258 5.29391 5.11924Z" />
    </svg>
  )
}

export default IconAccountsLegacy
