import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  font-weight: 600;
  color: ${consts.textDefaultColor};
`

interface IHeaderValueValue {
  className?: string
  rawType: string
  value: React.ReactNode
}

function BladeHeaderValueValue(props: IHeaderValueValue) {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  if (themeIsLegacy) {
    return <StyledDiv className={props.className}>{props.value}</StyledDiv>
  }

  return (
    <Label variant={LabelVariant.p_bold} labelledBy={`${props.rawType}-value`}>
      {props.value}
    </Label>
  )
}

export default BladeHeaderValueValue
