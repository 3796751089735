import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import { isDefined } from '@libs/isDefined'
import { get, last } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import WidgetFooterDirectories from '../Common/WidgetFooterDirectories'
import type { IWidgetProps } from './types'
import WidgetBigNumberValue from './WidgetBigNumberValue'
import WidgetContainer from './WidgetContainer'
import WidgetHeaderTitle from './WidgetHeaderTitle'

export interface IWidgetBigNumberProps extends IWidgetProps {}

const WidgetBigNumber: React.FC<IWidgetBigNumberProps> = props => {
  const renderHeader = () => {
    return (
      <WidgetHeaderTitle
        widgetKey={props.widgetKey}
        title={props.title}
        onEditIconClick={props.onEditIconClick}
        onDeleteIconClick={props.onDeleteIconClick}
        onRefreshIconClick={props.onRefreshIconClick}
      />
    )
  }

  const renderContent = (): Maybe<React.ReactElement> => {
    const lastSerie = last(props.storeWidget.series)

    if (!lastSerie) {
      return null
    }

    // value could be in count or value, depending of DashboardWidgetOptionsSerieStatDataPoint
    const count = get(
      lastSerie,
      'stats.data.0.count',
      get(lastSerie, 'stats.data.0.value')
    )

    if (count === undefined) {
      return null
    }

    const widgetType = lastSerie.dataOptions && lastSerie.dataOptions.type

    if (!widgetType) {
      return null
    }

    return (
      <ContainerFlex
        name="Count"
        direction="column"
        justifyContent="center"
        alignItems="center"
        items={[
          <WidgetBigNumberValue
            widgetType={widgetType}
            widgetLayout={props.layout}
            count={String(count)}
          />
        ]}
        fullHeight
      />
    )
  }

  const content = renderContent()

  return (
    <WidgetContainer
      data-name="WidgetBigNumber"
      labelledBy={`widget-id-${props.storeWidget.widgetEntity.id}`}
      className={props.className}
      flags={props.storeWidget.storeFlagsFetchWidgetData.flags}
      header={renderHeader()}
      footer={<WidgetFooterDirectories storeWidget={props.storeWidget} />}
      isMissingData={!isDefined(content)}
    >
      {/* TODO: Implement a custum legend */}

      {content}
    </WidgetContainer>
  )
}

export default observer(WidgetBigNumber)
