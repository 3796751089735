import type { StoreInputCrontab } from '@app/stores/helpers/StoreInputCrontab'
import { InputCrontabUnit } from '@app/stores/helpers/StoreInputCrontab/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ContainerFlex } from 'tenable-design-system-alt'
import SelectMonths from '../Controls/SelectMonths'
import { handleMonthsOnChange } from '../handlers'
import ConfigurationByMonth from './ConfigurationByMonth'

export interface IConfigurationByYearProps {
  storeInputCrontab: StoreInputCrontab
}

function ConfigurationByYear(props: IConfigurationByYearProps) {
  const months = JSON.parse(
    props.storeInputCrontab.storeForm.getFieldValueAsString(
      InputCrontabUnit.months
    )
  )

  return (
    <ContainerFlex
      name="ConfigurationByYear"
      flexDirection="column"
      flexGap="spacingM"
    >
      <SelectMonths
        months={months}
        onChange={handleMonthsOnChange(props.storeInputCrontab)}
      />

      <ConfigurationByMonth storeInputCrontab={props.storeInputCrontab} />
    </ContainerFlex>
  )
}

export default observer(ConfigurationByYear)
