import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks/useStores'
import MenuAccounts from '@app/pages/Management/AccountsPage/MenuAccounts'
import { AppRouteName } from '@app/routes'
import type { IManagementAccountsUsersEditRouteDefinition } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import UsersCreatePage from '../UsersCreatePage'
import UsersEditPage from '../UsersEditPage'
import DrawerActionDeleteUser from './DrawerActionDeleteUser'
import { onUsersLoad } from './handlers'
import {
  canAccessToAccountUsers,
  canCreateAccountUsers,
  canEditAccountUsers
} from './permissions'
import UsersList from './UsersList'

interface IUsersPageProps {}

const UsersPage: React.FC<IUsersPageProps> = () => {
  const { storeManagementUsers, storeLockoutPolicy, storeRbac, storeAbout } =
    useStores()

  return (
    <>
      <Blade
        root={true}
        routeDefinition={{
          routeName: AppRouteName.Management_Accounts_Users,
          parameters: {}
        }}
        onLoad={onUsersLoad(storeManagementUsers)}
        flags={[
          storeLockoutPolicy.storeFlagsFetchLockoutPolicy.flags,
          storeManagementUsers.storeFetchUsersFlags.flags
        ]}
        layout={{
          name: 'default',
          menu: <MenuAccounts />,
          content: (
            <>
              <ContainerContent>
                <UsersList />
              </ContainerContent>

              <Portal name={PlaceHolderName.genericDrawer}>
                <DrawerActionDeleteUser />
              </Portal>
            </>
          ),
          footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
        }}
        rbacCapabilityCheck={canAccessToAccountUsers(storeRbac)}
      />

      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName: AppRouteName.Management_Accounts_Users_Create,
              parameters: {}
            },
            component: UsersCreatePage,
            rbacPermissionsCheck: () => canCreateAccountUsers(storeRbac)
          },
          {
            routeDefinition: {
              routeName: AppRouteName.Management_Accounts_Users_Edit,
              parameters: {
                userId: Number()
              }
            },
            component: UsersEditPage,
            rbacPermissionsCheck: (
              parameters: IManagementAccountsUsersEditRouteDefinition['parameters']
            ) => canEditAccountUsers(storeRbac)(parameters.userId)
          }
        ]}
      />
    </>
  )
}
export default observer(UsersPage)
