import { ContainerHVCentered } from '@app/components-legacy/Container'
import { IconShield } from '@app/components-legacy/Icon'
import * as React from 'react'
import styled from 'styled-components'

interface ICardIconShieldProps {
  className?: string
}

const CardIconShield: React.FC<ICardIconShieldProps> = props => {
  return (
    <ContainerHVCentered className={props.className}>
      <IconShield size={30} />
    </ContainerHVCentered>
  )
}

export default styled(CardIconShield)`
  min-width: 100%;
  position: absolute;
  top: 0;
`
