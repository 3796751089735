import { createEntities, createEntity, EntityPagination } from '@app/entities'
import type { EventId } from '@app/entities/EntityEvent'
import EntityIncriminatingAttributeListable from '@app/entities/EntityIncriminatingAttribute/EntityIncriminatingAttributeListable'
import type { IDataRowIncriminatingAttributeListable } from '@app/entities/EntityIncriminatingAttribute/types'
import type { StoreRoot } from '@app/stores'
import { StoreInputReasons } from '@app/stores'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { ICheckerExposure } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { checkRbac } from '@libs/rbac/functions'
import type { QueryEventIncriminatingAttributes } from '@server/graphql/queries/event'
import { queryEventIncriminatingAttributes } from '@server/graphql/queries/event'
import type {
  IncriminatingAttribute,
  Maybe,
  Pagination,
  RbacEventIncriminatingAttributesQueryArgs
} from '@server/graphql/typeDefs/types'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { filterCheckersForEvent, filterReasonsForEvent } from './utils'

export default class StoreEventDetailsDeviances extends StoreBase {
  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  public storeInputReasons = new StoreInputReasons(this.storeRoot, {
    filterReasonsFn: allReasons =>
      filterReasonsForEvent(
        this.storeRoot.stores.storeTrailFlow.storeEventDetails.event
      )(allReasons)
  })

  public storeInputCheckersExposure =
    new StoreInputGenericCheckers<ICheckerExposure>(this.storeRoot, {
      checkerType: CheckerType.Exposure,
      selectable: true,
      filterCheckersFn: allCheckers =>
        filterCheckersForEvent(
          this.storeRoot.stores.storeTrailFlow.storeEventDetails.event
        )(allCheckers)
    })

  public storeWidgetList = new StoreWidgetList<
    EntityIncriminatingAttributeListable,
    IDataRowIncriminatingAttributeListable
  >(this.storeRoot, {
    selectable: false
  })

  public storeFetchIncriminatingAttributesFlags = new StoreFlags(this.storeRoot)
  public storeReloadIncriminatingAttributesFlags = new StoreFlags(
    this.storeRoot
  )

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['TrailFlow.EventDetails.Deviances']
  })

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
  }

  /**
   * Fetch incriminating attributes of a deviant AdObject.
   */
  fetchIncriminatingAttributes(
    args: RbacEventIncriminatingAttributesQueryArgs
  ): Promise<any> {
    this.storeFetchIncriminatingAttributesFlags.loading()

    return Promise.resolve()
      .then(() => this._fetchIncriminatingAttributes(args))
      .then(() => this.storeFetchIncriminatingAttributesFlags.success())
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFetchIncriminatingAttributesFlags
        )
      )
  }

  /**
   * Reload incriminating attributes.
   */
  reloadIncriminatingAttributes(
    args: RbacEventIncriminatingAttributesQueryArgs
  ): Promise<any> {
    this.storeReloadIncriminatingAttributesFlags.loading()

    return Promise.resolve()
      .then(() => this._fetchIncriminatingAttributes(args))
      .then(() => this.storeReloadIncriminatingAttributesFlags.success())
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeReloadIncriminatingAttributesFlags
        )
      )
  }

  /**
   * Reset pagination and reload incriminating attributes.
   */
  searchIncriminatingAttributes(
    args: RbacEventIncriminatingAttributesQueryArgs
  ): Promise<void> {
    return this.reloadIncriminatingAttributes({
      ...args,
      incriminatingAttributesPage: 1
    })
  }

  /**
   * Compute args to fetch incriminating attributes.
   */
  computeFetchIncriminatingAttributesArgs(
    infrastructureId: number,
    directoryId: number,
    eventId: EventId,
    customArgs?: Partial<RbacEventIncriminatingAttributesQueryArgs>
  ): Maybe<RbacEventIncriminatingAttributesQueryArgs> {
    const profileId = this.storeRoot.stores.storeAuthentication.currentProfileId

    const defaultArgs: RbacEventIncriminatingAttributesQueryArgs = {
      profileId,
      infrastructureId,
      directoryId,
      eventId,
      checkerIds: this.storeInputCheckersExposure.selectedCheckerIds,
      reasonIds: this.storeInputReasons.selectedReasonIds,
      incriminatingAttributesPage: this.storeWidgetList.paginationPage,
      incriminatingAttributesPerPage: this.storeWidgetList.rowsPerPage
    }

    return { ...defaultArgs, ...customArgs }
  }

  /**
   * Fetch incriminating attributes.
   */
  private _fetchIncriminatingAttributes(
    args: RbacEventIncriminatingAttributesQueryArgs
  ): Promise<any> {
    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryEventIncriminatingAttributes>(
            queryEventIncriminatingAttributes,
            args
          )
      })
      .then(data => data.rbacEventIncriminatingAttributes)
      .then(incriminatingAttributes => {
        if (!checkRbac(this.storeRoot)(incriminatingAttributes)) {
          throw new ForbiddenAccessError()
        }

        const incriminatingAttributesEntities = createEntities<
          IncriminatingAttribute,
          EntityIncriminatingAttributeListable
        >(
          EntityIncriminatingAttributeListable,
          incriminatingAttributes.node.node
        )

        const incriminatingAttributesPagination = createEntity<
          Pagination,
          EntityPagination
        >(EntityPagination, incriminatingAttributes.node.pagination)

        this.storeWidgetList.setEntities(incriminatingAttributesEntities)
        this.storeWidgetList.setPagination(incriminatingAttributesPagination)
      })
      .then(() => this.storeFetchIncriminatingAttributesFlags.success())
  }

  reset(): this {
    this.storeInputCheckersExposure.reset()
    this.storeInputReasons.reset()
    this.storeWidgetList.reset()

    return this
  }
}
