import type { TransientProps } from '@@types/helpers'
import type { IIconProps } from '@app/components/Icon/types'
import { Label } from '@app/components/Labels'
import { useDataFocusMethodAttribute } from '@app/hooks'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { consts } from '@app/styles'
import { CTAColorV2, FontColorV2 } from '@app/styles/consts'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import type { DSThemeDefinition } from '@design-system/styles/themes/types'
import { combineEventHandlers } from '@libs/combineEventHandlers'
import { Button } from 'antd'
import type { ButtonProps } from 'antd/lib/button'
import * as React from 'react'
import styled from 'styled-components'
import type { IFormWrapperControl } from './types'
import { ButtonSize, ButtonVariant } from './types'

const StyledButton = styled(Button)<
  TransientProps<
    Pick<
      IFormWrapperButtonProps,
      'customDisabled' | 'size' | 'square' | 'variant'
    >
  > & {
    $hasChildren: boolean
  }
>`
  display: flex;
  align-items: center;
  justify-content: ${props =>
    props.$variant === ButtonVariant.select ? 'space-between' : 'center'};
  height: ${props => getHeight(props.$size)};
  width: ${props =>
    props.$hasChildren && !props.$square
      ? undefined
      : props.$size === ButtonSize.large
        ? '40px'
        : '30px'};
  padding: 0
    ${props =>
      props.$hasChildren && !props.$square
        ? props.$size === ButtonSize.large
          ? consts.paddingLarge
          : consts.paddingDefault
        : 0};
  pointer-events: auto;
  border-radius: ${props => getBorderRadius(props.theme, props.$size)};
  border: 0;
  background-color: ${props =>
    getBackgroundColor(props.theme, props.$variant, {
      customDisabled: props.$customDisabled
    })};
  border: ${props =>
    props.$variant === ButtonVariant.tertiary
      ? `1px solid ${CTAColorV2.primary}`
      : ''};
  box-shadow: none;
  text-shadow: none;

  ::after {
    display: none;
  }

  &:focus {
    background-color: ${props =>
      getBackgroundColor(props.theme, props.$variant, {
        customDisabled: props.$customDisabled
      })};
  }

  &:hover,
  &:focus:not([data-focus-method='mouse']),
  &.active {
    background-color: ${props =>
      getBackgroundColor(props.theme, props.$variant, {
        isHover: true,
        customDisabled: props.$customDisabled
      })};

    &:active {
      box-shadow: none;
    }
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    border: ${props =>
      props.$variant === ButtonVariant.tertiary
        ? `1px solid ${CTAColorV2.primary}`
        : ''};
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    background-color: ${props => getDisabledBackgroundColor(props.$variant)};
  }

  ${props => props.$customDisabled && 'cursor: not-allowed;'}
`

const StyledLabel = styled(Label)<{
  fontSize: string
}>`
  font-size: ${props => props.fontSize};
`

interface IFormWrapperButtonProps<T = any> extends IFormWrapperControl {
  buttonProps?: ButtonProps
  size?: ButtonSize
  variant?: ButtonVariant
  icon?: React.FC<IIconProps>
  iconProps?: T
  iconSize?: number
  iconAfterLabel?: boolean
  /**
   * Force the button into a square shape, useful for page buttons in pagination.
   * Not necessary for icons, the button is already squared if it contains only an icon.
   */
  square?: boolean
  /**
   * Disable behaviour without having the disable html attribute.
   * Useful to trigger a popup on hover.
   */
  customDisabled?: boolean
  wrapWithLabel?: boolean
  children?: React.ReactNode
}

function FormWrapperButton<T = any>(props: IFormWrapperButtonProps<T>) {
  const { testAttributeProps } = useTestAttribute('form')
  const dataFocusMethodAttributeProps = useDataFocusMethodAttribute()
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const variant = getVariant(props)

  const extendedProps = { ...props, variant }

  // We retrieve the button properties that can be injected to the component by
  // a parent like Tooltip and combine the events to call both the injected one
  // and the one specified in the buttonProps.
  const onMouseEnter = combineEventHandlers(
    (props as ButtonProps).onMouseEnter,
    props.buttonProps?.onMouseEnter
  )
  const onMouseLeave = combineEventHandlers(
    (props as ButtonProps).onMouseLeave,
    props.buttonProps?.onMouseLeave
  )

  const Icon = props.icon
  const icon = Icon && (
    <Icon
      className="anticon"
      color={getLabelColor(extendedProps)}
      size={props.iconSize}
      {...props.iconProps}
    />
  )

  const buttonProps: ButtonProps = {
    type: props.variant === ButtonVariant.primary ? 'primary' : undefined,
    icon: props.iconAfterLabel ? null : icon,
    ...props.buttonProps,
    onMouseEnter,
    onMouseLeave
  }

  const name = `FormWrapperButton-${props.labelledBy}`

  if (themeIsLegacy) {
    return (
      <Button
        data-name={name}
        className={props.className}
        style={props.style}
        {...testAttributeProps('button')(props.labelledBy, props.pendoSuffix)}
        {...buttonProps}
      >
        {props.children}
      </Button>
    )
  }

  return (
    <StyledButton
      data-name={name}
      className={props.className}
      style={props.style}
      aria-disabled={props.customDisabled || props.buttonProps?.disabled}
      tabIndex={props.customDisabled ? -1 : undefined}
      $customDisabled={props.customDisabled}
      $size={props.size}
      $square={props.square}
      $variant={variant}
      $hasChildren={Boolean(props.children)}
      {...dataFocusMethodAttributeProps}
      {...testAttributeProps('button')(props.labelledBy)}
      {...buttonProps}
      onClick={props.customDisabled ? undefined : buttonProps.onClick}
    >
      {typeof props.children === 'string' ||
      typeof props.children === 'number' ||
      props.wrapWithLabel ? (
        <StyledLabel
          color={getLabelColor(extendedProps)}
          fontSize={getFontSize(props.size)}
        >
          {props.children}
        </StyledLabel>
      ) : (
        props.children
      )}
      {props.iconAfterLabel && icon}
    </StyledButton>
  )
}

export default FormWrapperButton

function getBackgroundColor(
  theme: DSThemeDefinition,
  variant?: ButtonVariant,
  params?: {
    isHover?: boolean
    customDisabled?: boolean
  }
): string {
  if (params?.customDisabled) {
    return getDisabledBackgroundColor(variant)
  }

  switch (variant) {
    case ButtonVariant.select:
      return theme.tokens['button/background/select']

    case ButtonVariant.tertiary:
      return params?.isHover
        ? theme.tokens['button/backgroundHover/tertiary']
        : theme.tokens['button/background/tertiary']

    case ButtonVariant.colored:
      return params?.isHover ? CTAColorV2.secondary : 'transparent'

    case ButtonVariant.secondary:
      return params?.isHover ? CTAColorV2.secondaryHover : CTAColorV2.secondary

    case ButtonVariant.transparent:
      return 'transparent'

    case ButtonVariant.delete:
      return CTAColorV2.delete
    case ButtonVariant.primary:
    default:
      return params?.isHover ? CTAColorV2.primaryHover : CTAColorV2.primary
  }
}

function getDisabledBackgroundColor(variant?: ButtonVariant): string {
  if (
    variant === ButtonVariant.colored ||
    variant === ButtonVariant.transparent
  ) {
    return 'transparent'
  }

  return CTAColorV2.disable
}

function getHeight(size?: ButtonSize) {
  switch (size) {
    case ButtonSize.large:
      return '40px'
    case ButtonSize.verySmall:
      return '24px'
    default:
      return '30px'
  }
}

function getFontSize(size?: ButtonSize) {
  switch (size) {
    case ButtonSize.verySmall:
      return '11px'
    default:
      return '13px'
  }
}

function getBorderRadius(theme: DSThemeDefinition, size?: ButtonSize) {
  switch (size) {
    case ButtonSize.verySmall:
      return '14px'
    default:
      return theme.tokens['button/borderRadius/default']
  }
}

function getLabelColor(props: IFormWrapperButtonProps) {
  if (props.buttonProps?.disabled || props.customDisabled) {
    return FontColorV2.secondary
  }
  switch (props.variant) {
    case ButtonVariant.secondary:
    case ButtonVariant.colored:
    case ButtonVariant.tertiary:
    case ButtonVariant.transparent:
      return CTAColorV2.primary
    case ButtonVariant.select:
      return FontColorV2.primary
    default:
      return FontColorV2.white
  }
}

function getVariant(props: IFormWrapperButtonProps) {
  if (props.variant) {
    return props.variant
  }

  if (props.buttonProps?.type === 'primary') {
    return ButtonVariant.primary
  }

  return ButtonVariant.secondary
}
