import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconActivityProps extends IIconProps {}

const IconActivity: React.FC<IIconActivityProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 20

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.875 8.125H15.625"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.875 11.875H15.625"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.875 15.625H15.625"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.875 4.375H15.625"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.3875 15.6125L4.375 15.625"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.3875 11.8625L4.375 11.875"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.3875 8.11252L4.375 8.12502"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.3875 4.36252L4.375 4.37502"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconActivity
