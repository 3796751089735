import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { useAppTranslator } from '@app/hooks'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'

export interface ILabelConfirmProps {}

const LabelConfirm: React.FC<ILabelConfirmProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const translate = useAppTranslator({
    namespaces: ['Components.LabelConfirm']
  })

  return themeIsLegacy ? (
    <div>{translate('Are you sure')}</div>
  ) : (
    <Label variant={LabelVariant.p}>{translate('Are you sure')}</Label>
  )
}

export default LabelConfirm
