import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '..'
import type { TContainerFlexSpaceWidth } from './types'

interface IContainerFlexVerticalProps {
  className?: string
  style?: React.CSSProperties
  items: React.ReactNode | React.ReactNodeArray
  // if true, add the default margin between items
  spaced?: boolean
  // width of the spacer
  spaceWidth?: TContainerFlexSpaceWidth
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const ContainerFlexVertical: React.FC<IContainerFlexVerticalProps> = props => {
  const extraProps = filterEmptyProps({
    onClick: props.onClick
  })

  return (
    <ContainerFlex
      className={props.className}
      style={props.style}
      name="ContainerFlexVertical"
      direction="column"
      justifyContent="center"
      items={props.items}
      spaced={props.spaced}
      spaceWidth={props.spaceWidth}
      {...extraProps}
    />
  )
}

export default styled(ContainerFlexVertical)`
  height: 100%;
`
