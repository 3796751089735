import type StoreProfileCheckerOptionOWCT from '@app/stores/Management/StoreProfiles/StoreProfileCheckerOptionOWCT'

export const onAddNewConfigurationClick =
  (storeProfileCheckerOptionOWCT: StoreProfileCheckerOptionOWCT) => () => {
    storeProfileCheckerOptionOWCT.addConfiguration()
  }

export const onRemoveConfigurationClick =
  (storeProfileCheckerOptionOWCT: StoreProfileCheckerOptionOWCT) =>
  (index: number) =>
  () =>
    storeProfileCheckerOptionOWCT.removeConfiguration(index)
