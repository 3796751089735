import type { IIconProps } from '@app/components/Icon/types'
import { TenableColor } from '@app/styles/consts'
import * as React from 'react'

interface IIconAlertProps extends IIconProps {}

const IconAlert: React.FC<IIconAlertProps> = props => {
  const stroke = props.color ?? TenableColor.brand_d_blue
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.19949 15.6432C3.95985 15.5369 1.3858 15.2392 1.875 14.75C3.125 13.5 4.375 11.25 4.375 8.125C4.375 5.65182 5.95238 3.5701 8.15034 2.80928C8.30029 1.92519 9.0713 1.25 10 1.25C10.9287 1.25 11.6997 1.92519 11.8489 2.81147C14.0476 3.5701 15.625 5.65182 15.625 8.125C15.625 11.25 16.875 13.5 18.125 14.75C18.8858 15.5108 12.2377 15.8085 7.19949 15.6432Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3954 18.1274C11.0521 18.5096 10.5541 18.75 9.99998 18.75C9.44542 18.75 8.9471 18.5093 8.60382 18.1266"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default IconAlert
