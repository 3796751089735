export enum ReportCommonFormFieldName {
  reportName = 'reportName',
  reportType = 'reportType',
  checkerIds = 'checkerIds',
  directoryIds = 'directoryIds',
  attackTypeIds = 'attackTypeIds',
  profileId = 'profileId',
  recipients = 'recipients',
  recurrence = 'recurrence',
  dataTimeframe = 'dataTimeframe',
  format = 'format',
  timeZone = 'timeZone'
}
