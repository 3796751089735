import {
  ContainerFooter,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { Modal, ModalCancelButton } from '@app/components-legacy/Modal'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleModalSaveConfigurationConfirmButton } from './handlers'

export interface IModalConfirmSaveConfigurationProps {}

const ModalConfirmSaveConfiguration: React.FC<
  IModalConfirmSaveConfigurationProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Titles', 'Buttons', 'Management.System.Configuration.IoA']
  })

  const { storeManagementAttackTypeConfiguration } = useStores()

  const {
    storeFlagsUpdateAttackTypeConfiguration,
    storeFlagsReloadAttackTypeConfiguration
  } = storeManagementAttackTypeConfiguration

  return (
    <Modal
      modalProps={{
        title: translate('Confirmation'),
        footer: (
          <ContainerFooter variant="modal">
            <ModalCancelButton
              storeModal={
                storeManagementAttackTypeConfiguration.storeModalSaveConfiguration
              }
            />

            <FormWrapperButton
              labelledBy="submit"
              buttonProps={{
                type: 'primary',
                onClick: handleModalSaveConfigurationConfirmButton(
                  storeManagementAttackTypeConfiguration
                ),
                loading:
                  storeFlagsUpdateAttackTypeConfiguration.isLoading ||
                  storeFlagsReloadAttackTypeConfiguration.isLoading
              }}
            >
              {translate('Confirm')}
            </FormWrapperButton>
          </ContainerFooter>
        )
      }}
      storeModal={
        storeManagementAttackTypeConfiguration.storeModalSaveConfiguration
      }
    >
      <ContainerHTMLContent padded>
        {translate('Note that activity statuses will be cleared', {
          transformMarkdown: true
        })}
      </ContainerHTMLContent>

      <p>{translate('Do you want to save the configuration')}</p>
    </Modal>
  )
}

export default observer(ModalConfirmSaveConfiguration)
