import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import ContainerStep from './ContainerStep'
import SliderSearchDelay from './SliderSearchDelay'

interface IConfigurationAttackTypeDomainsStep2Props {}

function ConfigurationAttackTypeDomainsStep2(
  props: IConfigurationAttackTypeDomainsStep2Props
) {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.IoA']
  })

  return (
    <ContainerStep
      stepNumber={2}
      title={translate('Search delay')}
      control={<SliderSearchDelay />}
      disabled={false}
    >
      <Label variant={LabelVariant.p}>
        {translate(
          'Duration of event collection before triggering a security analysis'
        )}
      </Label>
    </ContainerStep>
  )
}

export default observer(ConfigurationAttackTypeDomainsStep2)
