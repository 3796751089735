import type { MaybeUndef } from '@@types/helpers'
import type { IKeyboardBindingsManagerHandler, KeyboardKey } from './types'

/**
 * Class in charge of keyboard bindings, globally to the app.
 *
 * It allows to bind a key with an handler, with its specific logic, in order
 * to have a global management of the key through the whole app.
 */
export class KeyboardBindingsManager {
  private bindings: Map<KeyboardKey, IKeyboardBindingsManagerHandler> =
    new Map()

  constructor() {
    if (typeof document === 'undefined') {
      return
    }

    this.bindKeyboard()
  }

  /**
   * Add a new binding.
   */
  addBinding(key: KeyboardKey, handler: IKeyboardBindingsManagerHandler) {
    this.bindings.set(key, handler)
  }

  /**
   * Remove a binding.
   */
  removeBinding(key: KeyboardKey) {
    this.bindings.delete(key)
  }

  /**
   * Return the handler defined for a key.
   */
  getHandler<T extends IKeyboardBindingsManagerHandler>(
    key: KeyboardKey
  ): MaybeUndef<T> {
    return this.bindings.get(key) as T
  }

  /**
   * Bind a function to the keydown event.
   */
  private bindKeyboard() {
    document.onkeydown = this.handleKey.bind(this)
  }

  /**
   * Execute the handler defined for a key.
   */
  private handleKey(e: KeyboardEvent) {
    const handler = this.bindings.get(e.key as KeyboardKey)

    if (!handler) {
      return
    }

    handler.onKeyDown()
  }
}
