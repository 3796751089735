import type { EntitySearchBookmarkEntry } from '@app/entities'
import type StoreSearchBookmarks from '@app/stores/TrailFlow/StoreSearchBookmarks'
import { BookmarkEditFormFieldName } from '@app/stores/TrailFlow/StoreSearchBookmarks'

/**
 * Handle open drawer and init form data.
 */
export const handleEditBookmarkLoad =
  (storeSearchBookmarks: StoreSearchBookmarks) =>
  (searchBookmarkEntry: EntitySearchBookmarkEntry) =>
  () => {
    storeSearchBookmarks.storeDrawerEditBookmark
      .setData(searchBookmarkEntry)
      .openDrawer()

    storeSearchBookmarks.storeFormEditBookmark
      .setDefaultFieldsValues([
        {
          key: BookmarkEditFormFieldName.existingName,
          value: searchBookmarkEntry.getPropertyAsString('name')
        },
        {
          key: BookmarkEditFormFieldName.newName,
          value: searchBookmarkEntry.getPropertyAsString('name')
        }
      ])
      .reset()
  }

/**
 * Handle close drawer and reset form data.
 */
export const handleEditBookmarkUnload =
  (storeSearchBookmarks: StoreSearchBookmarks) => () => {
    storeSearchBookmarks.storeDrawerEditBookmark.closeDrawer()
  }

/**
 * Edit bookmark and close drawer.
 */
export const handleEditBookmarkValidate =
  (storeSearchBookmarks: StoreSearchBookmarks) =>
  (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault()
    }

    const { storeFormEditBookmark, storeDrawerEditBookmark } =
      storeSearchBookmarks

    if (!storeFormEditBookmark.validate()) {
      return
    }

    const newName =
      storeFormEditBookmark.getFieldValueAsString(
        BookmarkEditFormFieldName.newName
      ) || null

    const id = storeDrawerEditBookmark.data?.id
    const category = storeDrawerEditBookmark.data?.category

    if (!id) {
      return
    }

    return storeSearchBookmarks
      .editSearchBookmarksEntry({
        id,
        name: newName,
        category
      })
      .then(handleEditBookmarkUnload(storeSearchBookmarks))
  }
