import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'

interface IIconTrailflowProps extends IIconProps {}

const IconTrailflow: React.FC<IIconTrailflowProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.84027 6.80752C8.64514 6.60718 8.42962 6.42679 8.19708 6.26969C7.54441 5.82876 6.75761 5.57129 5.91067 5.57129C3.65217 5.57129 1.82129 7.40217 1.82129 9.66067C1.82129 11.9192 3.65217 13.7501 5.91067 13.7501C8.16917 13.7501 10.0001 11.9192 10.0001 9.66067C10.0001 7.40217 11.8309 5.57129 14.0894 5.57129C16.3479 5.57129 18.1788 7.40217 18.1788 9.66067C18.1788 11.9192 16.3479 13.7501 14.0894 13.7501C13.0073 13.7501 12.0233 13.3297 11.2919 12.6434"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default IconTrailflow
