import type { Perhaps } from '@@types/helpers'
import type { StoreInputTenants } from '@app/stores'
import { If } from '@libs/fp-helpers/If'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'

/**
 * Open the drawer of the tenants selection.
 */
export const handleInputTenantsOnLoad =
  (storeInputTenants: StoreInputTenants) =>
  (loadAtMount: Perhaps<boolean>) =>
  () => {
    if (!loadAtMount) {
      return
    }

    If(!storeInputTenants.tenants.size).fetch(() =>
      storeInputTenants.fetchTenants()
    )
  }

/**
 * Open the drawer of the tenants selection.
 */
export const handleInputTenantsOnClick =
  (storeInputTenants: StoreInputTenants) => () => {
    storeInputTenants.storeDrawer.openDrawer()
  }

/**
 * When searching a Tenant and unselect other tenants.
 *
 * The user have to select again once it has finished his search.
 */
export const handleTenantsSearch =
  (storeInputTenants: StoreInputTenants) => () => {
    storeInputTenants.unselectAllTenants()
  }

/**
 * Select or unselect all tenants.
 */
export const handleTenantsSelectAllOnClick =
  (storeInputTenants: StoreInputTenants) => (e: CheckboxChangeEvent) => {
    e.target.checked
      ? storeInputTenants.selectAllTenants()
      : storeInputTenants.unselectAllTenants()
  }

/**
 * Toggle selection of a Tenant.
 */
export const handleTenantSelection =
  (storeInputTenants: StoreInputTenants) => (tenantId: string) => () => {
    storeInputTenants.toggleSelectTenant(tenantId)
  }

/**
 * Validate the Tenant selection.
 */
export const handleTenantsDrawerOnSubmit =
  (
    storePendingTenants: StoreInputTenants,
    storeInputTenants: StoreInputTenants
  ) =>
  (onValidate?: () => void) =>
  () => {
    storeInputTenants.replaceSelectedTenants(
      storePendingTenants.selectedTenants
    )
    storeInputTenants.storeDrawer.closeDrawer()

    if (onValidate) {
      onValidate()
    }
  }
