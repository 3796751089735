import { Features } from '@alsid/common'
import { ContainerFlex } from '@app/components/Container'
import { HeadBar } from '@app/components-legacy/HeadBar'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import Header from '@app/pages/HeaderLegacy'
import MainMenu from '@app/pages/MainMenu'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import * as React from 'react'
import styled from 'styled-components'
import { GlobalInventoryAppProvider } from 'tenable-cyber-asset-management'

interface ILayoutDefaultProps {
  className?: string
  content: React.ReactNode
}

const LayoutDefault: React.FC<ILayoutDefaultProps> = props => {
  const isTulEnabled = useFeatureFlag({
    featureFlagName: Features.TENABLE_UNIVERSAL_LAYOUT
  })

  const featureKapteynLeftNavigationUi = useFeatureFlag({
    featureFlagName: Features.KAPTEYN_LEFT_NAVIGATION_UI
  })

  return (
    <GlobalInventoryAppProvider>
       <ContainerFlex
      name="LayoutDefaultFirst"
      className={props.className}
      direction="column"
      itemsFlexGrow={[0, 1]}
      items={[
        !isTulEnabled && <Header />,

        <ContainerFlex
          name="LayoutDefaultSecond"
          className="LayoutDefaultSecond"
          direction="row"
          itemsFlexGrow={[0, 1]}
          itemsFlexShrink={[0, 1]}
          items={[
            <DSThemeProvider
              themeName={
                featureKapteynLeftNavigationUi
                  ? DSThemeName.paladin
                  : DSThemeName.default
              }
            >
              <MainMenu />
            </DSThemeProvider>,
            <ContainerFlex
              name="LayoutDefaultThird"
              className="LayoutDefaultThird"
              direction="column"
              itemsFlexGrow={[0, 1]}
              items={[
                <HeadBar />,

                <ContainerFlex
                  name="LayoutDefaultFourth"
                  direction="row"
                  itemsFlexGrow={[1]}
                  items={[props.content]}
                />
              ]}
            />
          ]}
        />
      ]}
      fullHeight
    />
    </GlobalInventoryAppProvider>
   
  )
}

export default styled(LayoutDefault)`
  .LayoutDefaultSecond {
    /* Allow MainMenu to be scrollable */
    overflow: auto;
  }

  .LayoutDefaultThird {
    /* Avoid scrollbars on the main content container */
    overflow: hidden;
    position: relative;
  }
`
