import type { Maybe, MaybeUndef } from '@@types/helpers'
import type { Expression } from '@libs/Expression'
import type { NodeOrLeaf } from '@libs/Expression/types'
import * as React from 'react'
import * as WithSeparator from 'react-with-separator'
import styled from 'styled-components'
import { ContainerFlex } from '../Container'
import Tag from './Tag'
import TagParenthesis from './TagParenthesis'
import { TagFlags } from './types'

/**
 * Convert an Expression object to a React.ReactNode.
 */
export function convertExpressionToTags(
  expression: Expression,
  variant?: TagFlags
): Maybe<JSX.Element> {
  function parseNode(
    nodeOrLeaf: MaybeUndef<NodeOrLeaf>,
    recursive: boolean = false
  ) {
    if (!nodeOrLeaf) {
      return null
    }

    if (nodeOrLeaf.isNode()) {
      const elements = nodeOrLeaf.values.map(value => parseNode(value, true))

      const nodeAsJsx = (
        <WithSeparator
          separator={<Tag variant={variant} label={nodeOrLeaf.combinator} />}
        >
          {elements}
        </WithSeparator>
      )

      if (!recursive) {
        return nodeAsJsx
      }

      return <TagParenthesis>{nodeAsJsx}</TagParenthesis>
    }

    const valueAsString = nodeOrLeaf.value.toString()

    return (
      <Tag
        label={nodeOrLeaf.key}
        value={valueAsString}
        variant={variant}
        labelledBy={nodeOrLeaf.key}
      />
    )
  }

  return parseNode(expression.node)
}

export interface ITagExpressionProps {
  id: string
  className?: string
  style?: React.CSSProperties
  variant?: TagFlags
  expression: Expression
  onClick?: (expression: Expression) => void
}

const TagExpression: React.FC<ITagExpressionProps> = props => {
  const onClick = () => {
    if (props.onClick) {
      props.onClick(props.expression)
    }
  }

  return (
    <div
      data-name="TagExpression"
      className={props.className}
      style={props.style}
      onClick={onClick}
    >
      <ContainerFlex
        name="TagExpressionWrapper"
        items={[
          convertExpressionToTags(
            props.expression,
            props.variant || TagFlags.None
          )
        ]}
      />
    </div>
  )
}

export default styled(TagExpression)`
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};
`
