import type {
  IWSAPIInspectorMessage,
  IWSRegistration
} from '@server/routers/WSProxyRouter/types'
import { WSEntityName } from '@server/routers/WSProxyRouter/types'
import type { IObservableArray } from 'mobx'
import { action, computed, makeObservable, observable } from 'mobx'
import * as uuid from 'uuid'
import type { StoreRoot } from '..'
import StoreFlags from '../helpers/StoreFlags'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'

export const NB_MAX_ROWS = 150

export type MessagePayload = IWSAPIInspectorMessage['payload']

export interface IInspectorEvent extends MessagePayload {
  uuid: string
}

export default class StoreAPIInspector extends StoreBase {
  public storeFlags = new StoreFlags(this.storeRoot)

  // websocket registration
  private wsRegistration:
    | IWSRegistration<IWSAPIInspectorMessage['payload']>
    | undefined

  /* Observables */

  private eventsList = observable.array<IInspectorEvent>()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  registerWS() {
    // don't register several times
    if (this.wsRegistration) {
      return
    }

    this.wsRegistration = this.storeRoot.wsClient.addRegistration<
      IWSAPIInspectorMessage['payload']
    >(
      'APIInspector',
      {
        name: WSEntityName.apiInspector,
        payload: {}
      },
      this.pushNewEvent.bind(this)
    )
  }

  unregisterWS() {
    if (this.wsRegistration) {
      this.storeRoot.wsClient.removeRegistration(this.wsRegistration)
      this.wsRegistration = undefined
    }
  }

  /* Actions */

  @action
  pushNewEvent(payload: IWSAPIInspectorMessage['payload']): void {
    this.eventsList.unshift({
      uuid: uuid.v4(),
      ...payload
    })

    this.eventsList.length = NB_MAX_ROWS
  }

  @action
  resetList(): void {
    this.eventsList.clear()
  }

  /* Computed */

  @computed
  get events(): IObservableArray<IInspectorEvent> {
    return this.eventsList
  }
}
