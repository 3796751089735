/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { PingTheService200Response } from '../models/index'
import {
  PingTheService200ResponseFromJSON,
  PingTheService200ResponseToJSON
} from '../models/index'

/**
 *
 */
export class HealthApi extends runtime.BaseAPI {
  /**
   * Ping the core service
   */
  async pingTheServiceRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<PingTheService200Response>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/core/ad/ping`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      PingTheService200ResponseFromJSON(jsonValue)
    )
  }

  /**
   * Ping the core service
   */
  async pingTheService(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<PingTheService200Response> {
    const response = await this.pingTheServiceRaw(initOverrides)
    return await response.value()
  }
}
