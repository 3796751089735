import { IconBarChart, IconLineChart } from '@app/components/Icon'
import { ContainerFlex } from '@app/components-legacy/Container'
import {
  IconBarChartOutlined,
  IconLineChartOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { useAppTranslator } from '@app/hooks'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { DashboardWidgetType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { WidgetCommonFormFieldName } from '../types'
import WidgetInputIllustrationContainer from './WidgetInputIllustrationContainer'

export interface IWidgetInputIllustrationProps {
  storeForm: StoreForm<any>
}

const Div = styled.div`
  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      color: ${consts.CTAColorV2.primary};
    `}
`

const WidgetInputIllustration: React.FC<
  IWidgetInputIllustrationProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Dashboard.WidgetCommonForm']
  })

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  /**
   * Save widget type when clicking on an illustration.
   */
  const onChange = (widgetType: DashboardWidgetType) => {
    props.storeForm.setFieldValue(WidgetCommonFormFieldName.type, widgetType)
  }

  const selectedWidgetType = props.storeForm.getFieldValueAsString(
    WidgetCommonFormFieldName.type
  )

  return (
    <ContainerFlex
      name="WidgetInputIllustration"
      items={[
        <WidgetInputIllustrationContainer
          name={translate('Bar chart')}
          type={DashboardWidgetType.BarChart}
          selected={selectedWidgetType === DashboardWidgetType.BarChart}
          onChange={onChange}
          labelledBy="barChart"
        >
          {themeIsLegacy ? <IconBarChartOutlined /> : <IconBarChart />}
        </WidgetInputIllustrationContainer>,

        <WidgetInputIllustrationContainer
          name={translate('Line chart')}
          type={DashboardWidgetType.LineChart}
          selected={selectedWidgetType === DashboardWidgetType.LineChart}
          onChange={onChange}
          labelledBy="lineChart"
        >
          {themeIsLegacy ? <IconLineChartOutlined /> : <IconLineChart />}
        </WidgetInputIllustrationContainer>,

        <WidgetInputIllustrationContainer
          name={translate('Big number')}
          type={DashboardWidgetType.BigNumber}
          selected={selectedWidgetType === DashboardWidgetType.BigNumber}
          onChange={onChange}
          labelledBy="bigNumber"
        >
          <Div>{themeIsLegacy ? 1337 : 52}</Div>
        </WidgetInputIllustrationContainer>
      ]}
      spaced
    />
  )
}

export default observer(WidgetInputIllustration)
