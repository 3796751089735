import { Features } from '@alsid/common'
import {
  ContainerContent,
  ContainerFlex,
  ContainerFooter
} from '@app/components/Container'
import { WidgetListPagination } from '@app/components/Widgets/WidgetList'
import BladeContentLayoutDefault from '@app/components-legacy/Blade/BladeContent/BladeContentLayoutDefault'
import { DrawerContext } from '@app/components-legacy/Drawer/context'
import Drawer from '@app/components-legacy/Drawer/Drawer'
import DrawerHorizontalContainer from '@app/components-legacy/Drawer/DrawerHorizontalContainer'
import DrawerTitle from '@app/components-legacy/Drawer/DrawerTitle'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { MAX_DRAWER_NODE_SELECTION } from '@app/stores/AttackPath/consts'
import type { IDrawerAttackPathData } from '@app/stores/AttackPath/types'
import type StoreDrawer from '@app/stores/helpers/StoreDrawer'
import { grant } from '@libs/rbac/permissions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerNodeRelationshipsContent from './DrawerNodeRelationshipsContent'
import {
  handleDrawerNodeRelationshipSelectionSubmit,
  handleDrawerNodeRelationshipsPaginationChange
} from './handlers'

export interface IDrawerNodeRelationshipsProps {
  storeDrawer: StoreDrawer<IDrawerAttackPathData>
}

const DrawerNodeRelationships: React.FC<
  IDrawerNodeRelationshipsProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'AttackPath.Drawer']
  })

  const featureAttackPathDrawerSelection = useFeatureFlag({
    featureFlagName: Features.ATTACK_PATH_DRAWER_SELECTION
  })

  const { storeAttackPath } = useStores()

  const adObjectId = props.storeDrawer.getDataValue('adObjectId')
  const currentId = props.storeDrawer.getDataValue('currentId')

  React.useEffect(() => {
    if (!currentId) {
      return
    }

    const childrenNodesWithEdge =
      storeAttackPath.getChildrenNodesWithEdge(currentId)
    storeAttackPath.setDrawerChildrenNodesWithEdge(childrenNodesWithEdge)

    return () => {
      storeAttackPath.storeWidgetListDrawerNodeRelationships.reset()
      storeAttackPath.storeInputSearchDrawer.reset()
      storeAttackPath.setDrawerAdObjectTypeFilter([])
    }
  }, [currentId])

  const { countSelectedRows } =
    storeAttackPath.storeWidgetListDrawerNodeRelationships

  const buttonDisabled =
    !storeAttackPath.storeWidgetListDrawerNodeRelationships
      .isSomeRowsSelected || countSelectedRows > MAX_DRAWER_NODE_SELECTION

  return (
    <DrawerContext.Provider value={{ storeDrawer: props.storeDrawer }}>
      <Drawer>
        <DrawerHorizontalContainer variant="large">
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: (
                <DrawerTitle>{translate('Node relationships')}</DrawerTitle>
              ),
              content: (
                <ContainerFlex
                  name="DrawerAttackPath"
                  direction="column"
                  style={{ height: '100%' }}
                  items={[
                    <ContainerContent spaceWidth="none">
                      <DrawerNodeRelationshipsContent nodeUid={currentId} />
                    </ContainerContent>
                  ]}
                />
              ),
              footer: (
                <ContainerFooter justify="default">
                  <div>
                    <WidgetListPagination
                      storeWidgetList={
                        storeAttackPath.storeWidgetListDrawerNodeRelationships
                      }
                      onChange={handleDrawerNodeRelationshipsPaginationChange(
                        storeAttackPath
                      )}
                    />
                  </div>
                  {featureAttackPathDrawerSelection && (
                    <FormWrapperButton
                      labelledBy="DrawerNodeRelationshipsDisplay"
                      variant={ButtonVariant.primary}
                      size={ButtonSize.small}
                      buttonProps={{
                        disabled: buttonDisabled,
                        onClick:
                          handleDrawerNodeRelationshipSelectionSubmit(
                            storeAttackPath
                          )(currentId)
                      }}
                    >
                      {`${translate(
                        'Display the objects'
                      )} (${countSelectedRows}/${MAX_DRAWER_NODE_SELECTION})`}
                    </FormWrapperButton>
                  )}
                </ContainerFooter>
              )
            }}
            rbacCapabilityCheck={grant()}
            scrollChildrenContainerOverflow="hidden"
            paddingV="default"
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerNodeRelationships)
