import { AppRouteName } from '@app/routes'
import type { StoreManagementRelays } from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'

export const handleEditRelayIconOnClick =
  (appRouter: AppRouterClient) => (relayId: number) => () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Relays_Edit,
      parameters: {
        relayId
      }
    })

    appRouter.history.push(url)
  }

export const handleDeleteRelayIconOnClick =
  (storeManagementRelays: StoreManagementRelays) => (relayId: number) => () => {
    const relayEntity = storeManagementRelays.getRelayFromId(relayId)

    if (!relayEntity) {
      return
    }

    storeManagementRelays.storeDeleteDrawer
      .setData({ relayDataRow: relayEntity.asDataRow() })
      .openDrawer()
  }

export const handleRelayRenewApiKey =
  (storeManagementRelays: StoreManagementRelays, relayId: number) => () => {
    if (storeManagementRelays.storeFlagsRelaysRenewApiKey.isLoading) {
      return
    }

    storeManagementRelays.storeModalConfirmRenewApiKey.setData({
      bulk: false,
      relayId
    })
    storeManagementRelays.storeModalConfirmRenewApiKey.show()
  }
