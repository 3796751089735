import {
  createEntities,
  createEntity,
  EntityAlertIoA,
  EntityAlertIoE,
  EntityPagination
} from '@app/entities'
import type { IDataRowAlertIoA } from '@app/entities/EntityAlertIoA'
import type { IDataRowAlertIoE } from '@app/entities/EntityAlertIoE'
import {
  canReadAlertsIoA,
  canReadAlertsIoE
} from '@app/pages/HeaderLegacy/Widgets/WidgetAlerts/permissions'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import { haveReflectedPromisesFailed } from '@libs/promiseReflect'
import { checkRbac, isGrantedEntity } from '@libs/rbac/functions'
import type {
  MutationEditAlertsIoA,
  MutationEditAlertsIoE,
  MutationEditBulkAlertsIoA,
  MutationEditBulkAlertsIoE
} from '@server/graphql/mutations/alert'
import {
  mutationEditAlertsIoA,
  mutationEditAlertsIoE,
  mutationEditBulkAlertsIoA,
  mutationEditBulkAlertsIoE
} from '@server/graphql/mutations/alert'
import type {
  QueryRbacAlertsIoA,
  QueryRbacAlertsIoACount,
  QueryRbacAlertsIoE
} from '@server/graphql/queries/alerts'
import {
  queryRbacAlertsIoA,
  queryRbacAlertsIoACount,
  queryRbacAlertsIoE
} from '@server/graphql/queries/alerts'
import type {
  AlertIoA,
  AlertIoE,
  EditAlertsIoAMutationArgs,
  EditAlertsIoEMutationArgs,
  EditBulkAlertsIoAMutationArgs,
  EditBulkAlertsIoEMutationArgs,
  InputEditAlertIoA,
  InputEditAlertIoABulkPayload,
  InputEditAlertIoE,
  InputEditAlertIoEBulkPayload,
  Maybe,
  Pagination,
  RbacAlertsIoACountQueryArgs,
  RbacAlertsIoAQueryArgs,
  RbacAlertsIoEQueryArgs
} from '@server/graphql/typeDefs/types'
import { CheckerType } from '@server/graphql/typeDefs/types'
import type {
  IWSAlertIoAMessage,
  IWSAlertIoEMessage,
  IWSRegistration
} from '@server/routers/WSProxyRouter/types'
import { WSEntityName } from '@server/routers/WSProxyRouter/types'
import { action, computed, makeObservable, observable } from 'mobx'
import * as uuid from 'uuid'
import type { StoreRoot } from '..'
import StoreBase from '../StoreBase'
import type { GenericAlert, IStoreGenericAlertsOptions } from './types'

export interface IFetchAlertsOptions {
  updateGlobalFlags?: boolean
  updateActionsFlags?: boolean
  updateSwitchFlags?: boolean
}

export interface ICountAlertsOptions {
  forceCount?: boolean

  // Currently, the count of IoA alerts is not guarantee when several attacks are
  // detected at the same time, due to - I suppose - to concurrent queries and differents
  // counts in the output (1).
  // This option will allow to avoid the count query and just make a simple increment
  // of the counter value (until 99).
  justIncrement?: boolean
}

export default class StoreGenericAlerts<
  GA extends GenericAlert
> extends StoreBase<IStoreGenericAlertsOptions<GA>> {
  public storeFlags = new StoreFlags(this.storeRoot)
  public storeActionFlags = new StoreFlags(this.storeRoot)
  public storeSwitchFlags = new StoreFlags(this.storeRoot)

  public storeWidgetList = new StoreWidgetList<
    EntityAlertIoE | EntityAlertIoA,
    IDataRowAlertIoE | IDataRowAlertIoA
  >(this.storeRoot).setPagination(
    new EntityPagination({
      page: 1,
      perPage: 10,
      totalCount: -1
    })
  )

  // flag to avoid to fetch more that one alert at a time
  // (and because we display only one live alert at a time)
  private _pendingRbacQueryAlertQuery: boolean = false

  // websocket registration
  private _alertIoEWSRegistration: Maybe<
    IWSRegistration<IWSAlertIoEMessage['payload']>
  > = null
  private _alertIoAWSRegistration: Maybe<
    IWSRegistration<IWSAlertIoAMessage['payload']>
  > = null

  /* Observable */

  // keys are alertId
  private $alerts = observable.map<string, EntityAlertIoE | EntityAlertIoA>()

  // number of not read/archived alerts
  private $alertsCount = observable.box<Maybe<number>>(null)

  // live alert received via websocket
  private $liveAlert =
    observable.box<Maybe<EntityAlertIoE | EntityAlertIoA>>(null)

  // status of widgets of the sidebar
  private $showArchivedStatus = observable.box<boolean>(false)

  /* Private */

  // Map that defines current counts queries on IoA alerts to avoid "race conditions".
  // For example: When several attacks are received at "the same time", it allows to
  // "cancel" previous queries and set the count from the result of the "last one".
  // String is an uuid.
  private _alertsIoACountPendingQueries: Map<string, 'committed' | 'cancel'> =
    new Map()

  constructor(storeRoot: StoreRoot, options: IStoreGenericAlertsOptions<GA>) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Register websocket for alerts live notifications.
   */
  registerAlertWS(): void {
    if (this.options.checkerType === CheckerType.Exposure) {
      const profileId =
        this.storeRoot.stores.storeAuthentication.currentProfileId
      return this._registerAlertIoEWS(profileId)
    }

    if (this.options.checkerType === CheckerType.Attack) {
      return this._registerAlertIoAWS()
    }
  }

  /**
   * Fetch alerts, set the current page as read, update the counter.
   */
  fetchAlerts(
    args?: Partial<RbacAlertsIoEQueryArgs | RbacAlertsIoAQueryArgs>,
    countOptions?: ICountAlertsOptions
  ): Promise<void> {
    return this._fetchAlerts(args, { updateGlobalFlags: true })
      .then(() => this._setCurrentPageAsRead())
      .then(() => this.countAlerts(countOptions))
  }

  /**
   * Fetch archived alerts with default args.
   */
  fetchArchivedAlerts() {
    return this._fetchAlerts(
      { alertsPage: 1 },
      {
        updateSwitchFlags: true
      }
    )
  }

  /**
   * Fetch alerts after having done an action.
   */
  fetchAlertsAfterAction() {
    return this._fetchAlerts(
      { alertsPage: 1 },
      {
        updateActionsFlags: true
      }
    ).then(() => this.countAlerts({ forceCount: true }))
  }

  /**
   * Count the number of not read / not archived alerts.
   */
  countAlerts(_options?: ICountAlertsOptions): Promise<void> {
    const defaultOptions: ICountAlertsOptions = {
      forceCount: false,
      justIncrement: false
    }

    const options: ICountAlertsOptions = {
      ...defaultOptions,
      ..._options
    }

    // if there are more than 99 alerts, avoid to count
    // (the badge can't display more than 99 items)
    if (
      isDefined(this.alertsCount) &&
      this.alertsCount > 99 &&
      !options.forceCount
    ) {
      return Promise.resolve()
    }

    // just increment the counter (See (1) for explanations)
    if (options.justIncrement) {
      const newCount = (this.$alertsCount.get() ?? 0) + 1
      this._setAlertsCount(newCount)
      return Promise.resolve()
    }

    if (this.options.checkerType === CheckerType.Exposure) {
      const profileId =
        this.storeRoot.stores.storeAuthentication.currentProfileId
      return this._countAlertsIoE(profileId)
    }

    if (this.options.checkerType === CheckerType.Attack) {
      return this._countAlertsIoA()
    }

    return Promise.resolve()
  }

  /**
   * Edit some alerts.
   * (set archived status, ...)
   */
  editAlerts(
    alerts: Array<InputEditAlertIoE | InputEditAlertIoA>
  ): Promise<void> {
    const args: EditAlertsIoEMutationArgs | EditAlertsIoAMutationArgs = {
      alerts: alerts.map(a => {
        return {
          alertId: a.alertId,
          archived: a.archived,
          // set the alerts as read in all cases
          // (have been already read before being selected)
          read: true
        }
      })
    }

    if (this.options.checkerType === CheckerType.Exposure) {
      return this._editAlertsIoE(args)
    }

    if (this.options.checkerType === CheckerType.Attack) {
      return this._editAlertsIoA(args)
    }

    return Promise.resolve()
  }

  /**
   * Edit all alerts.
   * (set archived status, ...)
   */
  editBulkAlerts(
    alertBulkPayload:
      | InputEditAlertIoEBulkPayload
      | InputEditAlertIoABulkPayload
  ): Promise<any> {
    this.storeActionFlags.loading()

    if (this.options.checkerType === CheckerType.Exposure) {
      return this._editBulkAlertsIoE(
        alertBulkPayload as InputEditAlertIoEBulkPayload
      )
    }

    if (this.options.checkerType === CheckerType.Attack) {
      return this._editBulkAlertsIoA(
        alertBulkPayload as InputEditAlertIoABulkPayload
      )
    }

    return Promise.resolve()
  }

  /**
   * Register websocket for alerts IOE live notifications.
   */
  private _registerAlertIoEWS(profileId: number): void {
    // if not allowed to see IoE alerts, don't register the related WS channel
    if (!this._canReadAlertsIoE()) {
      return
    }

    // don't register several times
    if (this._alertIoEWSRegistration) {
      return
    }

    this._alertIoEWSRegistration = this.storeRoot.wsClient.addRegistration<
      IWSAlertIoEMessage['payload']
    >(
      'Alerts',
      {
        name: WSEntityName.alertIoE,
        payload: {
          profileId
        }
      },
      this._saveNewAlert.bind(this)
    )
  }

  /**
   * Private methods.
   */

  /**
   * Register websocket for alerts IOA live notifications.
   */
  private _registerAlertIoAWS(): void {
    // if not allowed to see IoA alerts, don't register the related WS channel
    if (!this._canReadAlertsIoA()) {
      return
    }

    // don't register several times
    if (this._alertIoAWSRegistration) {
      return
    }

    this._alertIoAWSRegistration = this.storeRoot.wsClient.addRegistration<
      IWSAlertIoAMessage['payload']
    >(
      'Alerts',
      {
        name: WSEntityName.alertIoA,
        payload: {
          profileId: this.storeRoot.stores.storeAuthentication.currentProfileId,
          language: this.storeRoot.appTranslator.language
        }
      },
      this._saveNewAlert.bind(this)
    )
  }

  /**
   * Set the current page of alerts as read.
   */
  private _setCurrentPageAsRead(): Promise<void> {
    const alerts: InputEditAlertIoE[] = this.storeWidgetList.entitiesAsArray
      .map(alert => {
        if (!alert.id) {
          return
        }

        // don't patch already read alerts
        if (alert.read === true) {
          return
        }

        return {
          alertId: alert.id,
          read: true
        }
      })
      .filter(isDefined)

    return this.editAlerts(alerts)
  }

  /**
   * When receiving a websocket for a new alert,
   * Fetch the alert context,
   * And save the alert as a liveAlert.
   */
  private _saveNewAlert(
    payload: IWSAlertIoEMessage['payload'] | IWSAlertIoAMessage['payload']
  ): void | Promise<any> {
    if (!payload) {
      this.storeRoot.logger.error(
        'Cant set new alert, the payload is undefined.'
      )
      return
    }

    // don't display the new alert is an alert is already displayed
    if (this.$liveAlert.get()) {
      return
    }

    // don't fetch the new alert is a query is still pending
    if (this._pendingRbacQueryAlertQuery) {
      return
    }

    if (this.options.checkerType === CheckerType.Exposure) {
      this._pendingRbacQueryAlertQuery = true

      const payloadIoE = payload as IWSAlertIoEMessage['payload']

      // extends the payload received via websocket
      return this._extendOneAlertIoE(payloadIoE).then(alertEntityExtended => {
        if (!alertEntityExtended) {
          return
        }

        this._pendingRbacQueryAlertQuery = false
        this._setLiveAlert(alertEntityExtended)

        // if there is less than 99 alerts, count
        if (isDefined(this.alertsCount) && this.alertsCount <= 99) {
          this.countAlerts()
        }
      })
    }

    if (this.options.checkerType === CheckerType.Attack) {
      const payloadIoA = payload as IWSAlertIoAMessage['payload']
      const alertIoAEntity = new EntityAlertIoA(payloadIoA)

      this._setLiveAlert(alertIoAEntity)

      // if there is less than 99 alerts, count
      if (isDefined(this.alertsCount) && this.alertsCount <= 99) {
        this.countAlerts({
          // just increment the counter (See (1) for explanations)
          justIncrement: true
        })
      }
    }
  }

  /**
   * Fetch the detail of one alert IoE.
   * Used to extend the payload received by websocket from live alerts.
   *
   * Note that only IoE alerts are extended, IoA alerts are coming with full payload.
   */
  private _extendOneAlertIoE(
    payload: IWSAlertIoEMessage['payload']
  ): Promise<Maybe<EntityAlertIoE> | void> {
    // if not granted to read IoE alerts, do nothing
    // (btw, WS channels should be not registered)
    if (!this._canReadAlertsIoE()) {
      return Promise.resolve()
    }

    const args: RbacAlertsIoEQueryArgs = {
      profileId: payload.profileId,
      alertsPage: 1,
      alertsPerPage: 1,
      showRead: true,
      showArchived: true,
      alertId: payload.id
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryRbacAlertsIoE>(queryRbacAlertsIoE, args)
      })
      .then(data => data.rbacAlertsIoE)
      .then(alerts => {
        if (!checkRbac(this.storeRoot)(alerts)) {
          throw new ForbiddenAccessError()
        }

        if (!alerts.node.node.length) {
          throw new Error('No alerts found when extending the alert')
        }

        const alert = alerts.node.node[0]

        return createEntity<AlertIoE, EntityAlertIoE>(EntityAlertIoE, alert)
      })
      .catch(handleStoreError(this.storeRoot, null))
  }

  /**
   * Fetch alerts.
   */
  private _fetchAlerts(
    args?: Partial<RbacAlertsIoEQueryArgs | RbacAlertsIoAQueryArgs>,
    options?: IFetchAlertsOptions
  ): Promise<void> {
    const { storeCheckers } = this.storeRoot.stores

    return storeCheckers.fetchCheckers().then(() => {
      return this.options.checkerType === CheckerType.Exposure
        ? this._fetchAlertsIoE(args, options)
        : this._fetchAlertsIoA(args, options)
    })
  }

  /**
   * Fetch alerts, update stores, update flags.
   * This is a private function, to not have to export options.
   */
  private _fetchAlertsIoE(
    args?: Partial<RbacAlertsIoEQueryArgs>,
    options?: IFetchAlertsOptions
  ): Promise<void> {
    // if not granted, don't fetch
    if (!this._canReadAlertsIoE()) {
      return Promise.resolve()
    }

    const profileId = this.storeRoot.stores.storeAuthentication.currentProfileId

    const finalArgs: RbacAlertsIoEQueryArgs = {
      profileId,
      alertsPage: this.storeWidgetList.paginationPage,
      alertsPerPage: this.storeWidgetList.rowsPerPage,
      showRead: true,
      showArchived: this.showArchivedStatus,
      ...args
    }

    const defaultOptions: IFetchAlertsOptions = {
      updateGlobalFlags: false,
      updateActionsFlags: false,
      updateSwitchFlags: false
    }

    const finalOptions = {
      ...defaultOptions,
      ...(options || {})
    }

    this._setLoadingFlags(finalOptions)

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryRbacAlertsIoE>(queryRbacAlertsIoE, finalArgs)
      })
      .then(data => data.rbacAlertsIoE)
      .then(alerts => {
        if (!checkRbac(this.storeRoot)(alerts)) {
          this._setForbiddenFlags(finalOptions)
          throw new ForbiddenAccessError()
        }

        // keep alerts for which there is an association deviance (granted)
        const alertsWithGrantedDeviance = alerts.node.node.filter(alert =>
          isGrantedEntity(alert.rbacDeviance)
        )

        const alertsEntities = createEntities<AlertIoE, EntityAlertIoE>(
          EntityAlertIoE,
          alertsWithGrantedDeviance
        )

        const alertsPagination = createEntity<Pagination, EntityPagination>(
          EntityAlertIoE,
          alerts.node.pagination
        )

        this._setAlerts(alertsEntities)

        this.storeWidgetList.setEntities(alertsEntities)
        this.storeWidgetList.setPagination(alertsPagination)

        this._setSuccessFlags(finalOptions)
      })
      .catch(err => {
        this._setFailFlags(finalOptions)
        handleStoreError(this.storeRoot, this.storeFlags)(err)
      })
  }

  /**
   * Fetch alerts, update stores, update flags.
   * This is a private function, to not have to export options.
   */
  private _fetchAlertsIoA(
    args?: Partial<RbacAlertsIoAQueryArgs>,
    options?: IFetchAlertsOptions
  ): Promise<void> {
    // if not granted, don't fetch
    if (!this._canReadAlertsIoA()) {
      return Promise.resolve()
    }

    const profileId = this.storeRoot.stores.storeAuthentication.currentProfileId

    const finalArgs: RbacAlertsIoAQueryArgs = {
      profileId,
      alertsPage: this.storeWidgetList.paginationPage,
      alertsPerPage: this.storeWidgetList.rowsPerPage,
      showArchived: this.showArchivedStatus,
      ...args
    }

    const defaultOptions: IFetchAlertsOptions = {
      updateGlobalFlags: false,
      updateActionsFlags: false,
      updateSwitchFlags: false
    }

    const finalOptions = {
      ...defaultOptions,
      ...(options || {})
    }

    this._setLoadingFlags(finalOptions)

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryRbacAlertsIoA>(queryRbacAlertsIoA, finalArgs)
      })
      .then(data => data.rbacAlertsIoA)
      .then(alerts => {
        if (!checkRbac(this.storeRoot)(alerts)) {
          this._setForbiddenFlags(finalOptions)
          throw new ForbiddenAccessError()
        }

        const alertsEntities = createEntities<AlertIoA, EntityAlertIoA>(
          EntityAlertIoA,
          alerts.node.node
        )

        const alertsPagination = createEntity<Pagination, EntityPagination>(
          EntityAlertIoA,
          alerts.node.pagination
        )

        this._setAlerts(alertsEntities)

        this.storeWidgetList.setEntities(alertsEntities)
        this.storeWidgetList.setPagination(alertsPagination)

        this._setSuccessFlags(finalOptions)
      })
      .catch(err => {
        this._setFailFlags(finalOptions)
        handleStoreError(this.storeRoot, this.storeFlags)(err)
      })
  }

  /**
   * Count the alerts for IoE.
   */
  private _countAlertsIoE(profileId: number): Promise<void> {
    // if not granted, don't count
    if (!this._canReadAlertsIoE()) {
      return Promise.resolve()
    }

    const ioeQueryArgs: RbacAlertsIoEQueryArgs = {
      profileId,
      alertsPage: 1,
      alertsPerPage: 1,
      showRead: false,
      showArchived: false
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryRbacAlertsIoE>(queryRbacAlertsIoE, ioeQueryArgs)
      })
      .then(data => data.rbacAlertsIoE)
      .then(alerts => {
        if (!checkRbac(this.storeRoot)(alerts)) {
          throw new ForbiddenAccessError()
        }

        if (isDefined(alerts.node.pagination.totalCount)) {
          this._setAlertsCount(alerts.node.pagination.totalCount)
        }
      })
      .catch(
        handleStoreError(this.storeRoot, null, {
          // avoid to show some errors about counting since it could occur at
          // any time on any interfaces. Exception logging will be enough for
          // debugging if needed.
          errorMessageTranslationFn: () => false
        })
      )
  }

  /**
   * Count the alerts for IoA.
   */
  private _countAlertsIoA(): Promise<void> {
    // if not granted, don't count
    if (!this._canReadAlertsIoA()) {
      return Promise.resolve()
    }

    // cancel previous queries
    Array.from(this._alertsIoACountPendingQueries.entries())
      .filter(([, status]) => status === 'committed')
      .forEach(([_uuid]) => this._alertsIoACountPendingQueries.delete(_uuid))

    const countQueryUuid = uuid.v4()

    // add a new pending count query
    this._alertsIoACountPendingQueries.set(countQueryUuid, 'committed')

    const profileId = this.storeRoot.stores.storeAuthentication.currentProfileId

    const ioaCountArgs: RbacAlertsIoACountQueryArgs = {
      profileId,
      showRead: false,
      showArchived: false
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryRbacAlertsIoACount>(queryRbacAlertsIoACount, ioaCountArgs)
      })
      .then(data => data.rbacAlertsIoACount)
      .then(rbacAlertsIoACount => {
        if (!checkRbac(this.storeRoot)(rbacAlertsIoACount)) {
          throw new ForbiddenAccessError()
        }

        if (
          isDefined(rbacAlertsIoACount.node.count) &&
          // check if the query is still committed
          this._alertsIoACountPendingQueries.get(countQueryUuid) === 'committed'
        ) {
          this._alertsIoACountPendingQueries.delete(countQueryUuid)
          this._setAlertsCount(rbacAlertsIoACount.node.count)
        }
      })
      .catch(
        handleStoreError(this.storeRoot, null, {
          // avoid to show some errors about counting since it could occurred at
          // any time on any interfaces. Exception logging will be enough for
          // debugging if needed.
          errorMessageTranslationFn: () => false
        })
      )
  }

  /**
   * Edit alerts IoE.
   */
  private _editAlertsIoE(args: EditAlertsIoEMutationArgs): Promise<void> {
    // if not granted, do nothing
    if (!this._canReadAlertsIoE()) {
      return Promise.resolve()
    }

    return this.storeRoot
      .getGQLRequestor()
      .query<MutationEditAlertsIoE>(mutationEditAlertsIoE, args)
      .then(results => {
        if (haveReflectedPromisesFailed(results.editAlertsIoE)) {
          this.storeRoot.stores.storeMessages.genericError()
        }
      })
      .catch(handleStoreError(this.storeRoot, null))
  }

  /**
   * Edit alerts IoA.
   */
  private _editAlertsIoA(args: EditAlertsIoAMutationArgs): Promise<void> {
    // if not granted, do nothing
    if (!this._canReadAlertsIoA()) {
      return Promise.resolve()
    }

    return this.storeRoot
      .getGQLRequestor()
      .query<MutationEditAlertsIoA>(mutationEditAlertsIoA, args)
      .then(results => {
        if (!results.editAlertsIoA) {
          this.storeRoot.stores.storeMessages.genericError()
        }
      })
      .catch(handleStoreError(this.storeRoot, null))
  }

  /**
   * Edit all alerts IoE.
   */
  private _editBulkAlertsIoE(
    alertBulkPayload: InputEditAlertIoEBulkPayload
  ): Promise<any> {
    // if not granted, do nothing
    if (!this._canReadAlertsIoE()) {
      return Promise.resolve()
    }

    const ioeMutationsArgs: EditBulkAlertsIoEMutationArgs = {
      alertBulkPayload: {
        profileId: alertBulkPayload.profileId,
        archived: alertBulkPayload.archived,
        // set the alerts as read in all cases
        // (have been already read before being selected)
        read: true
      }
    }

    return this.storeRoot
      .getGQLRequestor()
      .query<MutationEditBulkAlertsIoE>(
        mutationEditBulkAlertsIoE,
        ioeMutationsArgs
      )
      .then(() => this.storeActionFlags.success())
      .catch(handleStoreError(this.storeRoot, this.storeActionFlags))
  }

  /**
   * Edit all alerts IoA.
   */
  private _editBulkAlertsIoA(
    alertBulkPayload: InputEditAlertIoABulkPayload
  ): Promise<any> {
    // if not granted, do nothing
    if (!this._canReadAlertsIoA()) {
      return Promise.resolve()
    }

    const profileId = this.storeRoot.stores.storeAuthentication.currentProfileId

    const args: EditBulkAlertsIoAMutationArgs = {
      alertBulkPayload: {
        profileId,
        archived: alertBulkPayload.archived,
        // set the alerts as read in all cases
        // (have been already read before being selected)
        read: true
      }
    }

    return this.storeRoot
      .getGQLRequestor()
      .query<MutationEditBulkAlertsIoA>(mutationEditBulkAlertsIoA, args)
      .then(() => this.storeActionFlags.success())
      .catch(handleStoreError(this.storeRoot, this.storeActionFlags))
  }

  private _setLoadingFlags(options: IFetchAlertsOptions): void {
    if (options.updateGlobalFlags) {
      this.storeFlags.loading()
    }

    if (options.updateActionsFlags) {
      this.storeActionFlags.loading()
    }

    if (options.updateSwitchFlags) {
      this.storeSwitchFlags.loading()
    }
  }

  private _setSuccessFlags(options: IFetchAlertsOptions): void {
    if (options.updateGlobalFlags) {
      this.storeFlags.success()
    }

    if (options.updateActionsFlags) {
      this.storeActionFlags.success()
    }

    if (options.updateSwitchFlags) {
      this.storeSwitchFlags.success()
    }
  }

  private _setFailFlags(options: IFetchAlertsOptions): void {
    if (options.updateGlobalFlags) {
      this.storeFlags.fail()
    }

    if (options.updateActionsFlags) {
      this.storeActionFlags.fail()
    }

    if (options.updateSwitchFlags) {
      this.storeSwitchFlags.fail()
    }
  }

  private _setForbiddenFlags(options: IFetchAlertsOptions): void {
    if (options.updateGlobalFlags) {
      this.storeFlags.forbidden()
    }

    if (options.updateActionsFlags) {
      this.storeActionFlags.forbidden()
    }

    if (options.updateSwitchFlags) {
      this.storeSwitchFlags.forbidden()
    }
  }

  /**
   * Return true if the store is for IoE and if the user is granted to
   * access to IoE alerts.
   */
  private _canReadAlertsIoE(): boolean {
    return (
      this.options.checkerType === CheckerType.Exposure &&
      this.storeRoot.stores.storeRbac.isUserGrantedTo(canReadAlertsIoE)
    )
  }

  /**
   * Return true if the store is for IoA and if the user is granted to
   * access to IoA alerts.
   */
  private _canReadAlertsIoA(): boolean {
    return (
      this.options.checkerType === CheckerType.Attack &&
      this.storeRoot.stores.storeRbac.isUserGrantedTo(canReadAlertsIoA)
    )
  }

  /* Actions */

  @action
  reset(): this {
    this.storeFlags.reset()
    this.storeActionFlags.reset()
    this.storeSwitchFlags.reset()

    this.storeWidgetList.reset()

    return this
  }

  /**
   * Unregister the websocket channel.
   */
  @action
  unregisterAlertWS(): this {
    if (this.options.checkerType === CheckerType.Exposure) {
      if (!this._alertIoEWSRegistration) {
        return this
      }

      this.storeRoot.wsClient.removeRegistration(this._alertIoEWSRegistration)
      this._alertIoEWSRegistration = null

      return this
    }

    if (this.options.checkerType === CheckerType.Attack) {
      if (!this._alertIoAWSRegistration) {
        return this
      }

      this.storeRoot.wsClient.removeRegistration(this._alertIoAWSRegistration)
      this._alertIoAWSRegistration = null

      return this
    }

    return this
  }

  /**
   * Toggle the archived status and unselect all alerts.
   */
  @action
  setShowArchivedStatus(bool: boolean): this {
    this.$showArchivedStatus.set(bool)
    this.storeWidgetList.unselectAllRows()

    return this
  }

  @action
  removeLiveAlert(): this {
    this.$liveAlert.set(null)

    return this
  }

  @action
  private _setAlerts(alerts: Array<EntityAlertIoE | EntityAlertIoA>): this {
    this.$alerts.clear()

    alerts.forEach(entity => {
      if (entity.id) {
        this.$alerts.set(String(entity.id), entity)
      }
    })

    return this
  }

  /**
   * Return the number of not read/archived alerts (for the badge icon).
   */
  @action
  private _setAlertsCount(count: number): this {
    this.$alertsCount.set(count)
    this.storeRoot.tulApi?.setNotificationsCount(count)

    return this
  }

  @action
  private _setLiveAlert(alertEntity: EntityAlertIoE | EntityAlertIoA): this {
    this.$liveAlert.set(alertEntity)

    return this
  }

  /* Computed methods */

  /**
   * Return alerts that have deviances as an array.
   * (deviance can be null if not granted).
   */
  @computed
  get alerts(): Array<EntityAlertIoE | EntityAlertIoA> {
    const alerts = Array.from(this.$alerts.values())
      .filter(isDefined)
      .filter(alert => {
        if (alert instanceof EntityAlertIoE) {
          return isDefined(alert.getDeviance())
        }

        return true
      })

    if (this.options.checkerType === CheckerType.Exposure) {
      return alerts as EntityAlertIoE[]
    }

    if (this.options.checkerType === CheckerType.Attack) {
      return alerts as EntityAlertIoA[]
    }

    return []
  }

  @computed
  get alertsCount(): Maybe<number> {
    return this.$alertsCount.get()
  }

  @computed
  get liveAlert(): Maybe<EntityAlertIoE | EntityAlertIoA> {
    return this.$liveAlert.get()
  }

  @computed
  get showArchivedStatus(): boolean {
    return this.$showArchivedStatus.get()
  }
}
