import { ContainerFlex } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks/useStores'
import { SCENE_ZOOM_MAX_VALUE } from '@app/stores/Topology/consts'
import { Slider } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { onZoomSliderChange } from './handlers'
import { useSceneInitZoomSliderValue } from './hooks'
import ZoomLabel from './ZoomLabel'

interface IZoomSliderProps {
  className?: string
}

const ZoomSlider: React.FC<IZoomSliderProps> = props => {
  useSceneInitZoomSliderValue()
  const { storeTopology } = useStores()

  /**
   * Memoize all the zoomSlider component.
   * It avoids to re-render when chartZoomData change but not the k value.
   */
  const ZoomSliderMemoized = React.useMemo(() => {
    if (!storeTopology.chartZoomMin || !storeTopology.chartZoomData) {
      return null
    }

    // Multiply by 1000 to have a finer granularity
    const sliderValue = storeTopology.chartZoomData.k * 1000
    const sliderMaxValue = SCENE_ZOOM_MAX_VALUE * 1000
    const sliderMinValue = storeTopology.chartZoomMin * 1000

    return (
      <ContainerFlex
        name="ZoomSlider"
        className={props.className}
        direction="row"
        alignItems="center"
        items={[
          <ZoomLabel />,
          <Slider
            value={sliderValue}
            min={sliderMinValue}
            max={sliderMaxValue}
            tooltip={{ open: false }}
            onChange={onZoomSliderChange(storeTopology)}
          />
        ]}
        spaced
        spaceWidth="small"
      />
    )
  }, [storeTopology.chartZoomData.k, storeTopology.chartZoomMin])

  return ZoomSliderMemoized
}

const ObservedZoomSlider = observer(ZoomSlider)

export default styled(ObservedZoomSlider)`
  width: 200px;

  .ant-slider {
    width: 100%;
  }
`
