import { PlusOutlined } from '@ant-design/icons'
import { useAppTranslator } from '@app/hooks'
import { emailFormat } from '@app/stores/helpers/StoreForm/validators'
import type { StoreInputEmails } from '@app/stores/helpers/StoreInputEmails'
import { buildVariants } from '@design-system/libs/buildVariants'
import type { InputRef } from 'antd'
import { Input, Tag, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  handleEditInputChange,
  handleEditInputConfirm,
  handleEmailTagOnEdit,
  handleInputChange,
  handleInputConfirm,
  handleOnEmailTagClose,
  handleTagOnClick,
  handleTagOnKeydown
} from './handlers'

const MAX_EMAIL_LENGTH_NO_TRUNC = 36

interface IEmailInputPropsVariants {
  variant: 'add' | 'edit'
}

interface IInputEmailsProps {
  storeInputEmails: StoreInputEmails
  onValidate: () => void
}

interface IEmailTagPropsVariants {
  variant: 'valid' | 'syntaxError'
}

const EmailTag = styled(Tag)<IEmailTagPropsVariants>(props => {
  return buildVariants(props)
    .variant('variant', props.variant, {
      valid: {
        backgroundColor: props.theme.tokens['tag/color/background'],
        color: props.theme.tokens['tag/color/text'],
        padding: '1px 5px 2px 7px',
        border: 'none',
        '&:hover': {
          backgroundColor: props.theme.tokens['tag/color/backgroundHover']
        },
        '& .ant-tag-close-icon > svg': {
          color: props.theme.tokens['tag/color/text']
        }
      },
      syntaxError: {
        color: '#d4380d',
        background: '#fff2e8',
        borderColor: '#ffbb96',
        '&:hover': {
          backgroundColor: props.theme.tokens['input/backgroundColor/default']
        }
      }
    })
    .end()
})

const EmailInput = styled(Input)<IEmailInputPropsVariants>(props => {
  return buildVariants(props)
    .css({
      backgroundColor: props.theme.tokens['input/backgroundColor/default'],
      color: props.theme.tokens['input/color/text'],
      border: 'none'
    })
    .variant('variant', props.variant, {
      add: {
        backgroundColor: props.theme.tokens['input/backgroundColor/default']
      },
      edit: {}
    })
    .end()
})

const AddEmailTag = styled(Tag)(({ theme }) => {
  return buildVariants({ theme })
    .css({
      backgroundColor: theme.tokens['button/background/secondary'],
      color: theme.tokens['button/color/secondary'],
      borderColor: theme.tokens['button/color/secondary'],
      alignSelf: 'stretch',
      textAlign: 'center',
      marginRight: 0
    })
    .end()
})

const StyledInputEmails = styled.div(props => {
  return buildVariants(props)
    .css({
      display: 'flex',
      flexDirection: 'column',
      width: '300px',
      minHeight: '126px',
      maxHeight: '234px',
      alignItems: 'flex-start',
      background: props.theme.tokens['layout/body/background'],
      gap: props.theme.sizes.small,
      padding: props.theme.sizes.small,
      overflowY: 'auto'
    })
    .end()
})

const InputEmails: React.FC<IInputEmailsProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.ReportingCenter']
  })
  const storeInputEmails = props.storeInputEmails

  const addInputRef = React.useRef<InputRef>(null)
  const editInputRef = React.useRef<InputRef>(null)

  // instanciate email validator
  const validatorEmailHandler = emailFormat().handler(translate)

  return (
    <StyledInputEmails>
      {!storeInputEmails.inputVisible && (
        <AddEmailTag
          onClick={() => {
            handleTagOnClick(storeInputEmails)()

            // get the focus after the ref is set
            setTimeout(() => {
              addInputRef.current?.focus()
            })
          }}
          onKeyDown={handleTagOnKeydown(storeInputEmails)}
          tabIndex={0}
        >
          <PlusOutlined /> {translate('Add email')}
        </AddEmailTag>
      )}

      {storeInputEmails.inputVisible && (
        <EmailInput
          ref={addInputRef}
          variant="add"
          type="text"
          size="small"
          value={storeInputEmails.inputValue}
          onChange={handleInputChange(storeInputEmails)}
          onBlur={handleInputConfirm(storeInputEmails)(props.onValidate)}
          onPressEnter={handleInputConfirm(storeInputEmails)(props.onValidate)}
          placeholder={translate('Enter emails separated by comma')}
        />
      )}

      {storeInputEmails.emails.map((email, index) => {
        if (storeInputEmails.editInputIndex === index) {
          return (
            <EmailInput
              ref={editInputRef}
              variant="edit"
              key={email}
              size="small"
              value={storeInputEmails.editInputValue}
              onChange={handleEditInputChange(storeInputEmails)}
              onBlur={handleEditInputConfirm(storeInputEmails)(
                props.onValidate
              )}
              onPressEnter={handleEditInputConfirm(storeInputEmails)(
                props.onValidate
              )}
            />
          )
        }

        const isLongEmail = email.length > MAX_EMAIL_LENGTH_NO_TRUNC

        const tagElem = (
          <EmailTag
            tabIndex={0}
            variant={
              validatorEmailHandler.isValid(email) ? 'valid' : 'syntaxError'
            }
            key={email}
            closable={true}
            onKeyDown={handleEmailTagOnEdit(storeInputEmails)(editInputRef)(
              index,
              email
            )}
            onClose={handleOnEmailTagClose(storeInputEmails)(props.onValidate)(
              email
            )}
          >
            <span
              onClick={handleEmailTagOnEdit(storeInputEmails)(editInputRef)(
                index,
                email
              )}
            >
              {isLongEmail
                ? `${email.slice(0, MAX_EMAIL_LENGTH_NO_TRUNC)}...`
                : email}
            </span>
          </EmailTag>
        )

        return isLongEmail ? (
          <Tooltip title={email} key={email}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        )
      })}
    </StyledInputEmails>
  )
}

export default observer(InputEmails)
