import { DivP } from '@app/components/Labels/Label/DivP'
import { labelPCss } from '@app/components/Labels/Label/labelsCss'
import {
  ContainerFlex,
  ContainerFooter,
  ContainerPopoverContent
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { LabelDate } from '@app/components-legacy/Label'
import type { IAdObjetAttribute } from '@app/entities/EntityAdObject/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { isAttributeIsSDDL } from '@libs/sddl/isAttributeIsSDDL'
import { sliceString } from '@libs/sliceString'
import { ValueType } from '@libs/valueTypeParser/types'
import { Popover } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import EventDetailsAttributesPopoverSddl from './EventDetailsAttributesPopoverSddl'
import { onAttributeShowValueButtonClick } from './handlers'

const StyledLabelDate = styled(LabelDate)`
  ${labelPCss}
`

interface IEventDetailsAttributesPopoverProps {
  attribute: IAdObjetAttribute
}

const Monospaced = styled.div`
  ${consts.fontTextAreaDefault}
  color: ${props => props.theme.tokens['label/color/text']};
`

const MAX_LENGTH = 50
const POPOVER_MAX_LENGTH = 250

const EventDetailsAttributesPopover: React.FC<
  IEventDetailsAttributesPopoverProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['TrailFlow.EventDetails.DrawerAttributeValue']
  })

  const {
    storeTrailFlow: { storeEventDetails }
  } = useStores()

  const { storeEventDetailsAttributes } = storeEventDetails

  if (isAttributeIsSDDL(props.attribute.attributeName)) {
    return (
      <EventDetailsAttributesPopoverSddl
        maxLength={MAX_LENGTH}
        value={props.attribute.value}
      />
    )
  }

  switch (props.attribute.valueType) {
    case ValueType.date:
      return <StyledLabelDate date={props.attribute.value} />

    case ValueType.object:
    case ValueType.arrayObject:
      return (
        <Popover
          content={
            <ContainerFlex
              name="Popover"
              direction="column"
              justifyContent="space-between"
              items={[
                <ContainerPopoverContent>
                  <Monospaced>
                    {sliceString(props.attribute.value, {
                      maxLength: POPOVER_MAX_LENGTH
                    })}
                  </Monospaced>
                </ContainerPopoverContent>,

                <ContainerFooter variant="popover">
                  <FormWrapperButton
                    labelledBy="seeWholeValue"
                    buttonProps={{
                      type: 'primary',
                      onClick: onAttributeShowValueButtonClick(
                        storeEventDetailsAttributes
                      )(props.attribute)
                    }}
                  >
                    {translate('See whole value')}
                  </FormWrapperButton>
                </ContainerFooter>
              ]}
              spaced
            />
          }
        >
          <Monospaced>
            {sliceString(props.attribute.value, { maxLength: MAX_LENGTH })}
          </Monospaced>
        </Popover>
      )

    default: {
      if (props.attribute.value.length < MAX_LENGTH) {
        return <DivP>{props.attribute.value}</DivP>
      }

      return (
        <Popover
          content={
            <ContainerFlex
              name="Popover"
              direction="column"
              justifyContent="space-between"
              items={[
                <ContainerPopoverContent>
                  {sliceString(props.attribute.value, {
                    maxLength: POPOVER_MAX_LENGTH
                  })}
                </ContainerPopoverContent>,

                <ContainerFooter variant="popover">
                  <FormWrapperButton
                    labelledBy="seeWholeValue"
                    buttonProps={{
                      type: 'primary',
                      onClick: onAttributeShowValueButtonClick(
                        storeEventDetailsAttributes
                      )(props.attribute)
                    }}
                  >
                    {translate('See whole value')}
                  </FormWrapperButton>
                </ContainerFooter>
              ]}
              spaced
            />
          }
        >
          <DivP>
            {sliceString(props.attribute.value, { maxLength: MAX_LENGTH })}
          </DivP>
        </Popover>
      )
    }
  }
}

export default EventDetailsAttributesPopover
