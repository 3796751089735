import type { Maybe } from '@@types/helpers'
import { useAppRouter } from '@app/hooks'
import type { AppRouteName } from '@app/routes'
import type { IRouteInfo } from '@libs/Router/types'
import type { History } from 'history'
import * as React from 'react'

export interface IUseHistoryChangeOptions {
  onHistoryChange?: (
    currentRouteInfo: Maybe<IRouteInfo<AppRouteName, any>>,
    listener: History<any>['location']
  ) => void
}

export interface IUseHistoryChangeOutput {
  history: Maybe<History<any>>
  currentRouteInfo: Maybe<IRouteInfo<AppRouteName, any>>
}

/**
 * Retrieve history and bind history change to save the currentRouteName.
 */
export function useHistoryChange(
  options?: IUseHistoryChangeOptions
): IUseHistoryChangeOutput {
  const [historyState, setHistory] = React.useState<Maybe<History>>(null)

  const [currentRouteInfoState, setCurrentRouteInfo] =
    React.useState<Maybe<IRouteInfo<AppRouteName, any>>>(null)

  const appRouter = useAppRouter()

  // First, save the history object into the state
  React.useEffect(() => {
    if (historyState) {
      return
    }

    const historyObject = appRouter.historyObject()

    if (historyObject) {
      setHistory(historyObject)
    }
  }, [])

  // Second, set the currentRouteName and listen on history changes to update
  // the currentRouteName
  React.useEffect(() => {
    if (!historyState) {
      return
    }

    setCurrentRouteInfo(appRouter.getCurrentRouteInfo())

    const unregisterHandler = historyState.listen(listener => {
      const routeInfo = appRouter.getCurrentRouteInfo()

      setCurrentRouteInfo(routeInfo)

      if (options?.onHistoryChange) {
        options.onHistoryChange(routeInfo, listener)
      }
    })

    return () => {
      if (unregisterHandler) {
        unregisterHandler()
      }
    }
  }, [historyState, setCurrentRouteInfo])

  return { history: historyState, currentRouteInfo: currentRouteInfoState }
}
