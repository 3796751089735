import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { InputCheckersCards, InputSearch } from '@app/components-legacy/Input'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import { handleProfileGenericCheckerOnClick } from './handlers'

interface IProfileEditFormCheckersSelectionProps {}

const ProfileEditFormCheckersSelection: React.FC<
  IProfileEditFormCheckersSelectionProps
> = () => {
  const translate = useAppTranslator({
    namespaces: ['Titles', 'Buttons', 'Management.Accounts.Profiles.Edition']
  })

  const { storeManagementProfiles } = useStores()
  const { storeProfileCheckersExposure } = storeManagementProfiles

  return (
    <ContainerContent>
      <ContainerFlex
        name={ProfileEditFormCheckersSelection.name}
        direction="column"
        items={[
          <InputSearch
            placeholder={translate('Search for an indicator')}
            storeInputSearch={
              storeProfileCheckersExposure.storeInputGenericCheckers
                .storeInputSearch
            }
          />,

          <InputCheckersCards
            onSelection={handleProfileGenericCheckerOnClick(
              storeProfileCheckersExposure.storeInputGenericCheckers
            )}
            storeInputGenericCheckers={
              storeProfileCheckersExposure.storeInputGenericCheckers
            }
          />
        ]}
        spaced
      />
    </ContainerContent>
  )
}

export default ProfileEditFormCheckersSelection
