import { ErrorMessage } from '@app/components/Error'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import * as React from 'react'

interface IErrorMessageProps {}

const LoginErrorMessage: React.FC<IErrorMessageProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Login.Errors']
  })

  return (
    <ErrorMessage
      message={translate(
        'An error occurred during the initialization of the authentication process'
      )}
    />
  )
}

export default LoginErrorMessage
