import { isDefined } from '@libs/isDefined'
import { interpolateRainbow, interpolateTurbo, range, scaleLinear } from 'd3'
import { lighten } from 'polished'

/**
 * Generate a scheme (set of colors) of a defined length.
 */
export function getTurboColorScheme(length: number): string[] {
  const translate = scaleLinear().domain([0, length]).range([0, 1])

  return range(0, length)
    .map(i => {
      const value = translate(i)

      if (!isDefined(value)) {
        return
      }

      return interpolateTurbo(value)
    })
    .filter(isDefined)
}

/**
 * Generate a scheme (set of colors) of a defined length.
 */
export function getRainbowColorScheme(length: number): string[] {
  const translate = scaleLinear().domain([0, length]).range([0, 1])

  return range(0, length)
    .map(i => {
      const value = translate(i)

      if (!isDefined(value)) {
        return
      }

      return interpolateRainbow(value)
    })
    .filter(isDefined)
}

/**
 * Generate a set of colors from a color scheme for infrastructures and domains.
 */
export function getInfrastructuresColorScheme(length: number): string[] {
  return getRainbowColorScheme(length).map(color => {
    return lighten(0, color)
  })
}

/**
 * Generate a set of colors from a color scheme for reasons.
 */
export function getReasonsColorScheme(length: number): string[] {
  return getTurboColorScheme(length)
    .reverse()
    .map(color => {
      return lighten(0.2, color)
    })
}

/**
 * Generate a set of colors from a color scheme for tenants.
 */
export function getTenantsColorScheme(length: number): string[] {
  return getTurboColorScheme(length)
    .reverse()
    .map(color => {
      return lighten(0.2, color)
    })
}

export function getHealthCheckNamesColorScheme(length: number): string[] {
  return getTurboColorScheme(length)
    .reverse()
    .map(color => {
      return lighten(0.2, color)
    })
}
