export enum SamlConfigurationFieldName {
  enabled = 'enabled',
  providerLoginUrl = 'providerLoginUrl',
  signatureCertificate = 'signatureCertificate',
  activateCreatedUsers = 'activateCreatedUsers',
  serviceProviderUrl = 'serviceProviderUrl',
  assertEndpoint = 'assertEndpoint'
}

export enum SamlConfigurationGroupFieldName {
  groupName = 'groupName',
  defaultProfileId = 'defaultProfileId',
  defaultRoleIds = 'defaultRoleIds'
}
