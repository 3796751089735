import { ContainerFlex } from '@app/components-legacy/Container'
import type { IContainerFlexProps } from '@app/components-legacy/Container/ContainerFlex/types'
import { castArray, range } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export interface IWidgetListActionsHeaderProps {
  className?: string
  containerFlexProps?: Partial<IContainerFlexProps>
  children?: React.ReactNode
}

const WidgetListActionsHeader: React.FC<
  IWidgetListActionsHeaderProps
> = props => {
  const getItemsFlexGrow = () => {
    const length = castArray(props.children).length

    if (length === 1) {
      return [1]
    }

    return range(0, length - 1)
      .map(() => 0)
      .concat(1)
  }

  return (
    <ContainerFlex
      name="WidgetListActionsHeader"
      className={props.className}
      alignItems="center"
      itemsFlexGrow={getItemsFlexGrow()}
      items={props.children}
      spaced
      {...props.containerFlexProps}
    />
  )
}

export default observer(WidgetListActionsHeader)
