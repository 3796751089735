import { createEntity } from '@app/entities'
import EntityApplicationSettings from '@app/entities/EntityApplicationSettings'
import type { StoreRoot } from '@app/stores'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreForm from '@app/stores/helpers/StoreForm'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import {
  emailFormat,
  mandatory,
  minValue,
  portFormat
} from '@app/stores/helpers/StoreForm/validators'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { filterFalsies } from '@libs/filterFalsies'
import { checkRbac } from '@libs/rbac/functions'
import type { MutationUpdateApplicationSettings } from '@server/graphql/mutations/applicationSetting'
import { mutationUpdateApplicationSettings } from '@server/graphql/mutations/applicationSetting'
import type { QueryApplicationSettings } from '@server/graphql/queries/management'
import { queryApplicationSettings } from '@server/graphql/queries/management'
import type {
  ApplicationSettings,
  InputUpdateApplicationSettings,
  InputUpdateLockoutPolicy
} from '@server/graphql/typeDefs/types'
import type Maybe from 'graphql/tsutils/Maybe'
import { action, computed, makeObservable, observable } from 'mobx'
import {
  ActivityLogsConfigurationFormFieldName,
  HealthCheckFormFieldName,
  SecurityConfigurationFormFieldName,
  SmtpEncryption,
  SMTPServerFormFieldName,
  TelemetryFormFieldName,
  TenableAccountFormFieldName
} from './types'

export const DEFAULT_SMTP_PORT = 25

export default class StoreApplicationSettings extends StoreBase {
  public storeFlagsAppSettingsFetch = new StoreFlags(this.storeRoot)
  public storeFlagsAppSettingsSubmit = new StoreFlags(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: [
      'Buttons',
      'Management.System.Configuration.SMTP',
      'Management.System.Configuration.Logs',
      'Management.System.Configuration.Security',
      'Management.System.Configuration.ApplicationSettings',
      'Management.System.Configuration.DataCollection',
      'Management.System.Directories'
    ]
  })

  public storeFormSMTPConfiguration = new StoreForm<SMTPServerFormFieldName>(
    this.storeRoot,
    {
      setup: {
        fields: {
          [SMTPServerFormFieldName.smtpRelayId]: {
            label: 'Relay',
            description: 'Relay to use to connect to the SMTP server',
            inputType: InputType.select
          },
          [SMTPServerFormFieldName.smtpServerAddress]: {
            label: 'SMTP server address'
          },
          [SMTPServerFormFieldName.smtpServerPort]: {
            label: 'SMTP server port',
            validators: [portFormat()]
          },
          [SMTPServerFormFieldName.smtpAccount]: {
            label: 'SMTP account'
          },
          [SMTPServerFormFieldName.smtpAccountPassword]: {
            label: 'SMTP account password',
            inputType: InputType.inputPassword
          },
          [SMTPServerFormFieldName.smtpEncryption]: {
            label: 'SMTP encryption',
            inputType: InputType.select,
            description:
              'Encryption policy used for the connection to the SMTP server'
          },
          [SMTPServerFormFieldName.emailSender]: {
            label: 'Email address of the sender',
            validators: [mandatory(), emailFormat()]
          }
        }
      }
    }
  ).setDefaultFieldsValues([
    {
      key: SMTPServerFormFieldName.smtpEncryption,
      value: SmtpEncryption.none
    }
  ])

  public storeFormActivityLogsConfiguration =
    new StoreForm<ActivityLogsConfigurationFormFieldName>(this.storeRoot, {
      setup: {
        fields: {
          [ActivityLogsConfigurationFormFieldName.isActive]: {
            label: 'Activate the Activity logs feature',
            inputType: InputType.switch,
            labelAlignItem: 'center'
          },
          [ActivityLogsConfigurationFormFieldName.retentionDurationInMonth]: {
            label: 'Retention duration (in month)',
            inputType: InputType.select,
            validators: [mandatory(), minValue(0)()],
            labelAlignItem: 'center'
          }
        }
      }
    })

  public storeFormSecurityConfiguration =
    new StoreForm<SecurityConfigurationFormFieldName>(this.storeRoot, {
      setup: {
        fields: {
          [SecurityConfigurationFormFieldName.internalCertificate]: {
            label: 'Additional certificates',
            description:
              "Paste your company's PEM-encoded certificate chains here",
            inputType: InputType.textAreaCertificate
          }
        }
      }
    })

  public storeFormTenableAccountConfiguration =
    new StoreForm<TenableAccountFormFieldName>(this.storeRoot, {
      setup: {
        fields: {
          [TenableAccountFormFieldName.defaultProfileId]: {
            label: 'Default profile id',
            validators: [mandatory()]
          },
          [TenableAccountFormFieldName.defaultRoleIds]: {
            label: 'Default role ids',
            validators: [mandatory()]
          }
        }
      }
    })

  public storeFormTelemetryConfiguration =
    new StoreForm<TelemetryFormFieldName>(this.storeRoot, {
      setup: {
        fields: {
          [TelemetryFormFieldName.telemetryEnabled]: {
            label: 'Enable telemetry',
            inputType: InputType.switch
          }
        }
      }
    })

  public storeFormHealthCheckConfiguration =
    new StoreForm<HealthCheckFormFieldName>(this.storeRoot, {
      setup: {
        fields: {
          [HealthCheckFormFieldName.globalStatusDisplayEnabled]: {
            label: 'Show the Global Health Check Status',
            inputType: InputType.switch
          }
        }
      }
    })

  /* Observable */

  private $applicationSettings =
    observable.box<Maybe<EntityApplicationSettings>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch application settings and save results into an entity.
   */
  fetchApplicationSettings() {
    this.storeFlagsAppSettingsFetch.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryApplicationSettings>(queryApplicationSettings)
      })
      .then(data => data.rbacApplicationSettings)
      .then(applicationSettings => {
        if (
          !checkRbac(
            this.storeRoot,
            this.storeFlagsAppSettingsFetch
          )(applicationSettings)
        ) {
          throw new ForbiddenAccessError()
        }

        const applicationSettingsEntity = createEntity<
          ApplicationSettings,
          EntityApplicationSettings
        >(EntityApplicationSettings, applicationSettings.node)

        this.setApplicationsSettings(applicationSettingsEntity)

        this.storeFlagsAppSettingsFetch.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsAppSettingsFetch))
  }

  /**
   * Update application settings.
   */
  updateApplicationSettings(
    applicationSettings: InputUpdateApplicationSettings,
    options = {
      // if set to true, do not push a success message and forward the error exception
      forwardException: false
    }
  ) {
    this.storeFlagsAppSettingsSubmit.loading()

    return Promise.resolve()
      .then(() => {
        const args: MutationUpdateApplicationSettings['args'] = {
          applicationSettings
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationUpdateApplicationSettings>(
            mutationUpdateApplicationSettings,
            args
          )
      })
      .then(data => data.updateApplicationSettings)
      .then(updateApplicationSettings => {
        if (!options.forwardException) {
          this.storeRoot.stores.storeMessages.success(
            this.translate('Application settings updated'),
            {
              ariaRoles: ['alert'],
              labelledBy: 'applicationSettingsUpdated'
            }
          )
        }

        const applicationSettingsEntity = createEntity<
          ApplicationSettings,
          EntityApplicationSettings
        >(EntityApplicationSettings, updateApplicationSettings)

        this.setApplicationsSettings(applicationSettingsEntity)

        this.storeFlagsAppSettingsSubmit.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsAppSettingsSubmit, {
          forwardExceptionFn: () => options.forwardException
        })
      )
  }

  /**
   * Update application settings and Lockout Policy when being on the Tenable.ad
   * account configuration and "merge" sucesses/errors messages of both.
   */
  updateApplicationSettingsAndLockoutPolicy(
    applicationSettings: Maybe<InputUpdateApplicationSettings>,
    lockoutPolicy: Maybe<InputUpdateLockoutPolicy>
  ): Promise<any> {
    const { storeLockoutPolicy } = this.storeRoot.stores

    return Promise.all(
      filterFalsies([
        applicationSettings &&
          this.updateApplicationSettings(applicationSettings, {
            forwardException: true
          }),
        lockoutPolicy &&
          storeLockoutPolicy.editLockoutPolicy(lockoutPolicy, {
            forwardException: true
          })
      ])
    )
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Application settings updated'),
          {
            ariaRoles: ['alert'],
            labelledBy: 'applicationSettingsUpdated'
          }
        )
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsAppSettingsSubmit))
  }

  /* Action */

  /**
   * Save application settings.
   */
  @action
  setApplicationsSettings(
    applicationSettingsEntity: EntityApplicationSettings
  ): this {
    this.$applicationSettings.set(applicationSettingsEntity)
    return this
  }

  /* Computed */

  @computed
  get applicationSettings(): Maybe<EntityApplicationSettings> {
    return this.$applicationSettings.get()
  }
}
