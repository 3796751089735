import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import {
  ContainerContent,
  ContainerFlex,
  ContainerTimeline
} from '@app/components-legacy/Container'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useAppTranslator, useStores } from '@app/hooks'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import * as React from 'react'
import { onSearchHistoryEntryDelete } from '../../TrailFlowHistoryDrawer/DrawerHistory/handlers'
import TrailFlowSearchEntry from '../../TrailFlowSearchEntry'
import DrawerFooter from './DrawerFooter'
import { onTrailFlowSearchHistoryTimelineSelection } from './handlers'

export interface IDrawerContentProps {
  className?: string
}

const DrawerContent: React.FC<IDrawerContentProps> = () => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'TrailFlow.SearchHistory']
  })

  const { storeTrailFlow } = useStores()

  const getItemsSearchHistory = () =>
    Array.from(
      storeTrailFlow.storeSearchHistory.searchHistoryEntriesSortedByDate.values()
    ).map(searchHistoryEntries => {
      return (
        <ContainerFlex
          name="SearchHistoryPerDate"
          direction="column"
          items={Array.from(searchHistoryEntries).map(entitySearchHistory => {
            if (
              !entitySearchHistory.expressionObject ||
              !entitySearchHistory.expression
            ) {
              return
            }
            return (
              <TrailFlowSearchEntry
                key={entitySearchHistory.id}
                expressionObject={entitySearchHistory.expressionObject}
                onClick={onTrailFlowSearchHistoryTimelineSelection(
                  storeTrailFlow
                )(entitySearchHistory.expression)}
                onDelete={onSearchHistoryEntryDelete(
                  storeTrailFlow.storeSearchHistory
                )(entitySearchHistory)}
              />
            )
          })}
          spaced
          spaceWidth="veryVerySmall"
        />
      )
    })

  if (!storeTrailFlow.storeSearchHistory.searchHistoryEntries.size) {
    return (
      <>
        {themeIsLegacy ? (
          <>{translate('No history entry found')}</>
        ) : (
          <Label variant={LabelVariant.p}>
            {translate('No history entry found')}
          </Label>
        )}

        <DrawerFooter />
      </>
    )
  }

  return (
    <>
      <ContainerContent
        flags={[
          storeTrailFlow.storeSearchHistory.storeFlagsSearchHistory.flags,
          storeTrailFlow.storeSearchHistory.storeFlagsDeleteSearchHistoryEntry
            .flags
        ]}
        spinner={<SpinnerInline />}
      >
        <ContainerTimeline
          itemLabels={Array.from(
            storeTrailFlow.storeSearchHistory.searchHistoryEntriesSortedByDate.keys()
          ).map(date => moment.utc(date).calendar())}
          items={getItemsSearchHistory()}
        />
      </ContainerContent>

      <DrawerFooter />
    </>
  )
}

export default observer(DrawerContent)
