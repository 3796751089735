import type { IDataRowTenantSetting } from '@app/entities/EntityTenantSetting'
import type { StoreManagementTenants } from '@app/stores'

/**
 * Delete the tenant, close the drawer and reload tenants.
 */
export const handleTenantDeletionOnSubmit =
  (storeManagementTenants: StoreManagementTenants) =>
  (dataRow: IDataRowTenantSetting) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { storeRoot } = storeManagementTenants

    storeManagementTenants.deleteTenant(dataRow.id).then(() => {
      storeManagementTenants.fetchTenants()

      storeManagementTenants.storeDeleteDrawer.closeDrawer()

      storeRoot.stores.storeMessages.success(
        storeManagementTenants.translate('Tenant X deleted', {
          interpolations: {
            tenantName: dataRow.name
          }
        }),
        {
          labelledBy: 'tenantDeleted'
        }
      )
    })
  }
