import type { IDataRowDirectory } from '@app/entities/EntityDirectory'
import type { StoreManagementDirectories } from '@app/stores'

/**
 * Recrawl a directory and close the drawer.
 */
export const onDirectoryRecrawlValidate =
  (storeManagementDirectories: StoreManagementDirectories) =>
  (dataRow: IDataRowDirectory) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    storeManagementDirectories
      .recrawlDirectory(dataRow.id)
      .then(() => {
        storeManagementDirectories.storeRecrawlDrawer.closeDrawer()
      })
      .catch(() => {
        // already catched in the store
      })
  }
