import { handleDrawerOnClose } from '@app/components-legacy/Drawer/handlers'
import type StoreForm from '@app/stores/helpers/StoreForm'
import type StoreInputExpression from '@app/stores/helpers/StoreInputExpression'
import type StoreWizard from '@app/stores/helpers/StoreWizard'
import type { DefinitionDateValue } from '@app/stores/helpers/StoreWizard/types'
import { WizardRuleGroupFieldName } from '@app/stores/helpers/StoreWizard/types'
import { Expression } from '@libs/Expression'
import { Combinator } from '@libs/Expression/types'
import * as moment from 'moment'
import type { RangePickerSharedProps } from 'rc-picker/lib/RangePicker'
import type * as React from 'react'
import {
  getDateRuleFromExpression,
  getDefinedDateRule,
  isExpressionContainsIsDeviantTrue,
  isExpressionContainsOrDeviantCombinator
} from './utils'
import {
  convertToExpressionDefinition,
  convertToWizardDefinition
} from './wizardParser'

/**
 * Reset and initialize wizardStore state.
 * We need this hack because wizardStore currently wrap the Drawer and never reinitialize the form content.
 */
export function onFormContentLoad(
  storeInputExpression: StoreInputExpression
): void {
  const { storeWizard } = storeInputExpression

  storeWizard.resetValues()

  if (!storeInputExpression.expression.isDefined) {
    return
  }

  // Set values
  const ruleGroup = convertToWizardDefinition(
    storeInputExpression.expression.expressionAsObject
  )

  storeWizard.setValues(ruleGroup)

  const isDeviant = isExpressionContainsIsDeviantTrue(
    storeInputExpression.expression
  )

  if (isDeviant) {
    storeWizard.setDeviant(isDeviant)

    const isOrDeviantCombinator = isExpressionContainsOrDeviantCombinator(
      storeInputExpression.expression
    )

    storeWizard.setDeviantCombinator(
      isOrDeviantCombinator ? Combinator.OR : Combinator.AND
    )
  }

  // Set dateRule
  const dateRule = getDateRuleFromExpression(storeInputExpression.expression)

  if (dateRule) {
    storeWizard.setDateRule(dateRule)
  }
}

export function onDrawerWizardSubmit(
  storeInputExpression: StoreInputExpression,
  onValidate?: () => void
) {
  return (): void => {
    const { storeWizard } = storeInputExpression

    if (!storeWizard.values) {
      handleDrawerOnClose(storeInputExpression.storeDrawer)()
      return
    }

    storeWizard.setSubmitting(true)

    const expression = new Expression().fromExpressionDefinition(
      convertToExpressionDefinition(
        storeWizard.values,
        storeWizard.isDeviant,
        storeWizard.deviantCombinator,
        getDefinedDateRule(storeWizard.dateRule)
      )
    )

    const isValid = storeInputExpression
      .clearEntry()
      .setEntry(expression.expressionAsString)
      .validateEntry()

    if (isValid) {
      // Send expression to parent if method is defined
      if (onValidate) {
        onValidate()
      }

      storeWizard.resetValues()
      handleDrawerOnClose(storeInputExpression.storeDrawer)()

      return
    }

    storeWizard.setSubmitting(false)
  }
}

export function onDrawerWizardReset(storeWizard: StoreWizard) {
  return (e: React.MouseEvent<HTMLElement, MouseEvent>): StoreWizard => {
    e.preventDefault()

    return storeWizard.resetValues()
  }
}

export function onDeviantChange(storeWizard: StoreWizard) {
  return (): StoreWizard => {
    return storeWizard.setDeviant(!storeWizard.isDeviant)
  }
}

export function onDeviantCombinatorChange(
  combinator: Combinator,
  storeWizard: StoreWizard
) {
  return (): StoreWizard => {
    return storeWizard.setDeviantCombinator(
      combinator === Combinator.OR ? Combinator.AND : Combinator.OR
    )
  }
}

export function onCalendarChange(storeWizard: StoreWizard) {
  return (dates: RangePickerSharedProps<any>['value']): void => {
    const [[fromOperator], [toOperator]] = storeWizard.dateRule

    if (!dates) {
      return
    }

    const [from, to] = dates

    const fromStartOfDay = from
      ? moment.utc(from).startOf('day').toISOString()
      : ''
    const toEndOfDay = to ? moment.utc(to).endOf('day').toISOString() : ''

    const changedDates: DefinitionDateValue = [
      [fromOperator, fromStartOfDay],
      [toOperator, toEndOfDay]
    ]

    storeWizard.setDateRule(changedDates)
  }
}

export function onCombinatorChange(
  combinator: Combinator,
  storeForm: StoreForm<any>
) {
  return (): StoreForm<any> => {
    return storeForm.setFieldValue(
      WizardRuleGroupFieldName.combinator,
      combinator === Combinator.OR ? Combinator.AND : Combinator.OR
    )
  }
}

export function onPushRule(ruleGroupPath: number[], storeWizard: StoreWizard) {
  return (): StoreWizard => {
    return storeWizard.pushRule(ruleGroupPath)
  }
}

export function onPushRuleGroup(
  combinator: Combinator,
  ruleGroupPath: number[],
  storeWizard: StoreWizard
) {
  return (): StoreWizard => {
    return storeWizard.pushRuleGroup(ruleGroupPath, combinator)
  }
}

export function onRemoveRuleOrRuleGroup(
  index: number,
  ruleGroupPath: number[],
  storeWizard: StoreWizard
) {
  return (): StoreWizard => {
    return storeWizard.removeRuleOrRuleGroup(index, ruleGroupPath)
  }
}
