import { FormWrapperSelect } from '@app/components-legacy/Form'
import { useAppTranslator, useStores } from '@app/hooks'
import type StoreWidgetsManagement from '@app/stores/Dashboard/StoreWidgetsManagement'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { DashboardWidgetDataOptionType } from '@server/graphql/typeDefs/types'
import type { SelectValue } from 'antd/lib/select'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canSelectDashboardWidgetsRelatedToDeviances } from '../../permissions'
import { WidgetCommonFormFieldName } from '../types'
import { onWidgetInputDataOptionsTypeSelect } from './handlers'

interface IWidgetInputDataOptionsTypeProps {
  storeWidgetsManagement: StoreWidgetsManagement
}

const WidgetInputDataOptionsType: React.FC<
  IWidgetInputDataOptionsTypeProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Dashboard.WidgetCommonForm']
  })

  const { storeRbac } = useStores()

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const { storeForm } = props.storeWidgetsManagement

  // fallback to undefined when `getFieldValueAsString` returns an empty string
  // in order to display the placeholder
  const selectValue =
    storeForm.getFieldValueAsString(
      WidgetCommonFormFieldName.dataOptionsType
    ) || undefined

  const options = [
    {
      label: translate('Users count'),
      value: DashboardWidgetDataOptionType.User,
      labelledBy: 'usersCount'
    }
  ]

  if (storeRbac.isUserGrantedTo(canSelectDashboardWidgetsRelatedToDeviances)) {
    options.push(
      {
        label: translate('Deviances count'),
        value: DashboardWidgetDataOptionType.Deviance,
        labelledBy: 'deviancesCount'
      },
      {
        label: translate('Compliance score'),
        value: DashboardWidgetDataOptionType.ComplianceScore,
        labelledBy: 'complianceScore'
      }
    )
  }

  return (
    <FormWrapperSelect<SelectValue>
      labelledBy="chooseDataType"
      width={themeIsLegacy ? undefined : 'large'}
      selectProps={{
        placeholder: translate('Choose a data type'),
        value: selectValue,
        onChange: onWidgetInputDataOptionsTypeSelect(
          props.storeWidgetsManagement
        )
      }}
      selectOptions={options}
    />
  )
}

export default observer(WidgetInputDataOptionsType)
