import type { Maybe } from '@@types/helpers'
import { ContainerSimple } from '@app/components/Container'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import type { IContainerSimpleProps } from '../Container/ContainerSimple'

export interface IContainerHTMLContentProps extends IContainerSimpleProps {
  children: Maybe<string>
  padded?: boolean
}

const ContainerHTMLContent: React.FC<IContainerHTMLContentProps> = props => {
  if (!props.children) {
    return null
  }

  return (
    <ContainerSimple
      name={ContainerHTMLContent.name}
      className={props.className}
      ariaRoles={props.ariaRoles}
      labelledBy={props.labelledBy}
      containerProps={{
        dangerouslySetInnerHTML: {
          __html: props.children
        }
      }}
      inline={props.inline}
    />
  )
}

/**
 * Copy styles of some other components to format markdown textes like
 * other contents.
 * TODO Check how to leverage on existing styles instead of copy them here.
 */
export default styled(ContainerHTMLContent)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: ${consts.fontWeightBold};
    text-transform: uppercase;
    margin: ${consts.marginDefault} 0;
  }

  h1 {
    font-size: ${consts.textSizeVeryLarge};
    color: ${consts.colorBlueGray010};

    :first-child {
      margin-top: 0;
    }
  }

  h2 {
    font-size: ${consts.textSizeLarge};
    color: ${props => props.theme.tokens['label/color/text']};
  }

  h3 {
    font-size: ${consts.textSizeNormal};
    color: ${props => props.theme.tokens['label/color/text']};
  }

  h4 {
    font-size: ${consts.textSizeNormal};
    color: ${props => props.theme.tokens['label/color/text']};
  }

  h5 {
    font-size: ${consts.textSizeNormal};
    color: ${props => props.theme.tokens['label/color/text']};
  }

  a {
    color: ${props => props.theme.tokens['containerHtmlContent/link/color']};
  }

  a:hover {
    color: ${props =>
      props.theme.tokens['containerHtmlContent/link/color/hover']};
  }

  table {
    margin-bottom: ${props => (props.padded ? '1em' : 0)};
  }

  ul {
    margin: ${consts.marginSmall} 0;
  }

  pre {
    background-color: ${consts.colorBlueGray030};
    padding: 4px 8px;
    white-space: pre-wrap;

    code {
      padding: 0;
    }
  }

  em {
    font-size: ${consts.textSizeNormal};
    font-weight: ${consts.fontWeightBold};
  }

  code {
    background-color: ${consts.colorBlueGray030};
    padding: 1px 3px;
  }

  p {
    margin-bottom: ${props => (props.padded ? '1em' : 0)};
  }

  strong {
    font-weight: ${consts.fontWeightBold};
  }

  blockquote {
    margin: 0.5em 0 0.5em;
    padding: 0 1em;
    color: ${props =>
      props.theme.tokens['containerHtmlContent/blockquote/color']};
    border-left: 0.25em solid
      ${props =>
        props.theme.tokens['containerHtmlContent/blockquote/borderLeft']};
  }
`
