import { ErrorGenericMessage } from '@app/components/Error'
import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  ContainerContent,
  ContainerFooter,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import {
  Drawer,
  DrawerCancelButton,
  DrawerContext,
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { LabelConfirm } from '@app/components-legacy/Label'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useAppTranslator, useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canDeleteUser } from '../UsersCreatePage/permissions'
import { onUsersDeleteDrawerSubmit } from './handlers'

export interface IDrawerActionDeleteUserProps {}

const DrawerActionDeleteUser: React.FC<IDrawerActionDeleteUserProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.Accounts.Users']
  })

  const { storeManagementUsers } = useStores()

  const { storeDeleteDrawer } = storeManagementUsers

  const userDataRow = storeDeleteDrawer.getDataValue('userDataRow')
  const userDataId = userDataRow && userDataRow.id

  const renderContent = () => {
    if (!userDataRow) {
      return <ErrorGenericMessage />
    }

    const isLoading = storeManagementUsers.storeDeletionFlags.flags.isLoading

    return (
      <FormWrapper
        name="userDeletion"
        onSubmit={onUsersDeleteDrawerSubmit(storeManagementUsers)(userDataRow)}
      >
        <ContainerContent
          flags={storeManagementUsers.storeFetchUsersFlags.flags}
          spinner={<SpinnerInline />}
        >
          <ContainerHTMLContent>
            {translate('You will delete the user X', {
              transformMarkdown: true,
              interpolations: {
                name: userDataRow.name
              }
            })}
          </ContainerHTMLContent>

          <LabelConfirm />
        </ContainerContent>

        <Portal name={PlaceHolderName.drawerFooter}>
          <ContainerFooter>
            <DrawerCancelButton />

            <FormWrapperButtonSubmit loading={isLoading} danger>
              {translate('Delete')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </FormWrapper>
    )
  }

  return (
    <DrawerContext.Provider value={{ storeDrawer: storeDeleteDrawer }}>
      <Drawer>
        <DrawerHorizontalContainer>
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: <DrawerTitle>{translate('Delete a user')}</DrawerTitle>,
              content: renderContent(),
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canDeleteUser(userDataId)}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerActionDeleteUser)
