import { ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { ButtonCopyToClipboard } from '@app/components-legacy/Button'
import { IconDownloadOutlined } from '@app/components-legacy/Icon/IconAntd'
import type { EntityUserLog } from '@app/entities'
import type { IApiCall } from '@app/entities/EntityUserLog'
import { consts } from '@app/styles'
import { CTAColorV2 } from '@app/styles/consts'
import { DateFormat, formatDate } from '@libs/dates/formatDate'
import { filterFalsies } from '@libs/filterFalsies'
import * as React from 'react'
import styled from 'styled-components'
import { UserLogAPICallAction } from '../../UserActivityLogs/UserLogAPICallRow/UserLogAPICallAction'
import { UserLogIcon } from '../UserLogIcon'

const DrawerTraceTime: React.FC<{ logDate: Date }> = props => {
  const traceDate = formatDate(props.logDate, {
    format: 'MMM DD YYYY HH:MM'
  })
  return (
    <Label className="traceTime" labelledBy="time">
      {traceDate}
    </Label>
  )
}

export interface IDrawerActivityLogPayloadTitle {
  log: EntityUserLog
}

const StyledTitleSecondLine = styled(ContainerFlex)`
  margin: ${consts.marginDefault};
`

const DrawerActivityLogPayloadTitle: React.FC<
  IDrawerActivityLogPayloadTitle
> = props => {
  const { log: trace } = props
  const logDate = new Date(Date.parse(String(trace.createdAt)))
  const decodedAttributes = trace.decodedLogAttributes as IApiCall

  const titleFirstLine = (
    <ContainerFlex
      name="trace-payload-title"
      labelledBy="trace-payload-title"
      flexWrap="wrap"
      itemsFlexGrow={[1, 0]}
      items={filterFalsies([
        <div>
          <UserLogIcon trace={trace} />
        </div>,
        <DrawerTraceTime logDate={logDate} />
      ])}
    />
  )

  const titleSecondLine = (
    <StyledTitleSecondLine
      name="trace-payload-title"
      labelledBy="trace-payload-title"
      alignItems="center"
      itemsFlexGrow={[0, 1]}
      items={filterFalsies([
        <Label label={trace.userEmail ?? 'non available'} />,
        <UserLogAPICallAction log={trace} />
      ])}
    />
  )

  const payload = decodedAttributes.payload as string
  const fileDate = formatDate(logDate, {
    format: DateFormat.englishFullDate
  })
  const fileName = `log-${fileDate}.json`
  const downloadablePayloadFile = new Blob([payload], { type: 'text/json' })

  const thirdLine = (
    <ContainerFlex
      name="trace-payload-title"
      labelledBy="trace-payload-title"
      justifyContent="flex-end"
      items={filterFalsies([
        <ButtonCopyToClipboard
          textToCopy={decodedAttributes.payload}
          buttonProps={{
            style: {
              border: 'none',
              color: CTAColorV2.primary,
              padding: `${consts.paddingSmall}`
            }
          }}
        />,
        <a
          href={URL.createObjectURL(downloadablePayloadFile)}
          download={fileName}
          title="Download the log"
        >
          <IconDownloadOutlined
            style={{
              color: CTAColorV2.primary,
              cursor: 'pointer',
              fontSize: '15px',
              padding: consts.paddingSmall,
              paddingTop: consts.paddingDefault
            }}
          />
        </a>
      ])}
    />
  )

  return (
    <ContainerFlex
      style={{
        backgroundColor: consts.BackgroundColorV2.white,
        margin: consts.marginSmall,
        padding: consts.paddingVerySmall,
        borderRadius: consts.containerBorderRadius
      }}
      name="trace-payload"
      direction="column"
      spaced
      paddingV={'large'}
      items={[titleFirstLine, titleSecondLine, thirdLine]}
    />
  )
}

export default DrawerActivityLogPayloadTitle
