import { useStores } from '@app/hooks'
import * as React from 'react'
import { FormWrapperButtonCancel } from '../Form/Wrappers'

interface IButtonCloseLastBladeProps {}

/**
 * Add a Cancel button for blades.
 * No need to pass the storeBlades instance, it is retrieved automatically from
 * the context the store is aware of the previous blade url.
 *
 * Usage:
 *
 * <BladeCancelButton />
 */
const BladeCancelButton: React.FC<IButtonCloseLastBladeProps> = props => {
  const { storeBlades } = useStores()

  const handleOnClick = () => {
    storeBlades.closeLastBlade()
  }

  return <FormWrapperButtonCancel onClick={handleOnClick} />
}

export default BladeCancelButton
