import { Features } from '@alsid/common'
import { ContainerFlex } from '@app/components/Container'
import { IconDirection } from '@app/components/Icon'
import { Direction } from '@app/components/Icon/IconDirection'
import { Label, LabelSliced } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { VerticalLine } from '@app/components/Separator'
import { IDENTIFIER_COLUMN_NAME } from '@app/components-legacy/TableNew/consts'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { consts } from '@app/styles'
import { BrandColorV2 } from '@app/styles/consts'
import { isDefined } from '@libs/isDefined'
import * as React from 'react'
import styled from 'styled-components'
import { LabelBeta } from '../Label'
import TableCell from './TableCell'
import type { TableColumnsProperties, TableInterfaceType } from './types'
import { TableColumnOrder } from './types'

const StyledVerticalLine = styled(VerticalLine)`
  margin-left: ${consts.marginDefault};
`

const StyledContainerFlex = styled(ContainerFlex)`
  padding: ${props => props.theme.tokens['table/padding/row']};
`

interface ITableHeaderProps<T extends TableInterfaceType> {
  className?: string
  labelledBy: string
  columns: TableColumnsProperties<T>
  availableWidth: number
  totalRelativeWidth: number
}

export function TableHeader<T extends TableInterfaceType>(
  props: ITableHeaderProps<T>
) {
  const displayBetaLabel = useFeatureFlag({
    featureFlagName: Features.BETA_ON_AES_SCORE
  })
  const columnsArray = Object.keys(props.columns)

  return (
    <StyledContainerFlex
      className={props.className}
      name="TableHeader"
      direction="row"
      items={columnsArray.map((columnName, i) => {
        if (columnName === IDENTIFIER_COLUMN_NAME) {
          return null
        }

        const column = props.columns[columnName]

        if (column.hidden) {
          return null
        }

        const relativeWidth = !isDefined(column.fixedWidth)
          ? column.relativeWidth ?? 1
          : undefined

        const verticalLineWidth = 21

        const cursor = column.sorter ? 'pointer' : 'inherit'

        const cellAvailableWidth =
          (column.fixedWidth ??
            (props.availableWidth * (column.relativeWidth ?? 1)) /
              props.totalRelativeWidth) - verticalLineWidth

        return (
          <TableCell
            labelledBy={`${props.labelledBy}/header/${columnName}`}
            height={30}
            fixedWidth={column.fixedWidth}
            relativeWidth={
              relativeWidth && relativeWidth / props.totalRelativeWidth
            }
            key={columnName}
            justifyContent={'space-between'}
            cursor={cursor}
            handleOnClick={column.sorter?.sorterFn}
          >
            {column.name && (
              <>
                <Label
                  variant={
                    column.headerCentered
                      ? LabelVariant.h3Centered
                      : LabelVariant.h3
                  }
                >
                  <LabelSliced
                    value={column.name}
                    maxWidth={cellAvailableWidth}
                  />
                  {displayBetaLabel && column.isBeta && <LabelBeta />}
                </Label>
                {column.sorter?.sortingOrder && (
                  <IconDirection
                    style={{ marginRight: 'auto', marginLeft: '8px' }}
                    direction={
                      column.sorter.sortingOrder === TableColumnOrder.Asc
                        ? Direction.up
                        : Direction.down
                    }
                  />
                )}
              </>
            )}
            {i < columnsArray.length - 1 && column.name && (
              <StyledVerticalLine color={BrandColorV2.lightGray} height={9} />
            )}
          </TableCell>
        )
      })}
      spaced
    />
  )
}
