import { LayoutPDF } from '@app/components-legacy/Layout'
import * as React from 'react'

interface IDashboardPagePdfProps {}

function DashboardPdfPage(props: IDashboardPagePdfProps) {
  return <LayoutPDF content={<div>Hello World</div>} />
}

export default DashboardPdfPage
