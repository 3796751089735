import { BladesContainer } from '@app/components-legacy/Blade'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LayoutDefault } from '@app/components-legacy/Layout'
import * as React from 'react'
import IoEBoardBlade from './IoEBoardBlade'

interface IIoEBoardPageProps {}

const IoEBoardPage: React.FC<IIoEBoardPageProps> = props => {
  return (
    <LayoutDefault
      content={
        <ContainerFlex
          name="IoEBoardPage"
          items={[
            <BladesContainer>
              <IoEBoardBlade />
            </BladesContainer>
          ]}
        />
      }
    />
  )
}

export default IoEBoardPage
