import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import { LabelNonBreakable } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import type { SwitchProps } from 'antd/lib/switch'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '../Container'
import { FormWrapperSwitch } from '../Form/Wrappers'

export interface IInputSwitchProps {
  className?: string
  label?: string
  labelledBy: string
  useLabelInSwitch?: boolean
  switchProps: SwitchProps
}

const InputSwitch: React.FC<IInputSwitchProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const translate = useAppTranslator({ namespaces: ['Components.InputSwitch'] })

  const showLabel = Boolean(props.label) && props.useLabelInSwitch !== true

  const checkedChildren = themeIsLegacy
    ? props.useLabelInSwitch === true
      ? props.label
      : translate('Yes')
    : undefined

  const unCheckedChildren = themeIsLegacy
    ? props.useLabelInSwitch === true
      ? props.label
      : translate('No')
    : undefined

  const id = React.useId()

  return (
    <ContainerFlex
      name="InputSwitch"
      className={props.className}
      alignItems="center"
      items={[
        showLabel &&
          (themeIsLegacy ? (
            <LabelNonBreakable>{props.label}</LabelNonBreakable>
          ) : (
            <label htmlFor={id}>
              <Label variant={LabelVariant.p}>
                <LabelNonBreakable>{props.label}</LabelNonBreakable>
              </Label>
            </label>
          )),

        <FormWrapperSwitch
          labelledBy={props.labelledBy}
          switchProps={{
            id: id,
            size: 'small',
            checkedChildren,
            unCheckedChildren,
            ...props.switchProps
          }}
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default styled(InputSwitch)`
  min-width: 100px; /* IE11 fix */
`
