import { LabelNonBreakable } from '@app/components-legacy/Label'
import LabelAlt, {
  LabelAltVariant
} from '@app/components-legacy/Label/LabelAlt'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import { formatNumber } from '@libs/numbers/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export interface IWidgetListCountItemsWithFilterProps {
  className?: string
  style?: React.CSSProperties
  label?: string
  storeWidgetList: StoreWidgetList<any, any>
}

const WidgetListCountItemsWithFilter: React.FC<
  IWidgetListCountItemsWithFilterProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.WidgetList']
  })

  if (props.storeWidgetList.isEmpty) {
    return null
  }

  const count = props.storeWidgetList.options.selectable
    ? formatNumber(props.storeWidgetList.countFilteredSelectedRows)
    : formatNumber(props.storeWidgetList.paginationTotalCount)

  const totalCount = formatNumber(props.storeWidgetList.paginationTotalCount)

  const translation = props.storeWidgetList.options.selectable
    ? translate('Number of filtered selected objects', {
        interpolations: {
          count,
          totalCount
        }
      })
    : translate('Number of filtered objects', {
        interpolations: {
          count,
          totalCount
        }
      })

  return (
    <div
      data-name="WidgetListCountItemsWithFilter"
      className={props.className}
      style={props.style}
    >
      <LabelNonBreakable>
        <LabelAlt
          labelledBy={`listCounts-${count}-${totalCount}`}
          variant={LabelAltVariant.Disabled}
        >
          {translation}
        </LabelAlt>
      </LabelNonBreakable>
    </div>
  )
}

export default observer(WidgetListCountItemsWithFilter)
