/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { Finding } from './Finding'
import { FindingFromJSON, FindingFromJSONTyped, FindingToJSON } from './Finding'
import type { Pagination } from './Pagination'
import {
  PaginationFromJSON,
  PaginationFromJSONTyped,
  PaginationToJSON
} from './Pagination'

/**
 *
 * @export
 * @interface FindingsResponse
 */
export interface FindingsResponse {
  /**
   *
   * @type {Array<Finding>}
   * @memberof FindingsResponse
   */
  items: Array<Finding>
  /**
   *
   * @type {Pagination}
   * @memberof FindingsResponse
   */
  pagination: Pagination
}

/**
 * Check if a given object implements the FindingsResponse interface.
 */
export function instanceOfFindingsResponse(value: object): boolean {
  if (!('items' in value)) return false
  if (!('pagination' in value)) return false
  return true
}

export function FindingsResponseFromJSON(json: any): FindingsResponse {
  return FindingsResponseFromJSONTyped(json, false)
}

export function FindingsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FindingsResponse {
  if (json == null) {
    return json
  }
  return {
    items: (json['items'] as Array<any>).map(FindingFromJSON),
    pagination: PaginationFromJSON(json['pagination'])
  }
}

export function FindingsResponseToJSON(value?: FindingsResponse | null): any {
  if (value == null) {
    return value
  }
  return {
    items: (value['items'] as Array<any>).map(FindingToJSON),
    pagination: PaginationToJSON(value['pagination'])
  }
}
