import { IconOpenLink } from '@app/components/Icon'
import type EntityAttackPathNode from '@app/entities/EntityAttackPathNode'
import { useStores } from '@app/hooks'
import { handleAdObjectChildrenOnClick } from '@app/pages/AttackPath/SceneBlade/handlers'
import { isEntityAttackPathNode } from '@app/stores/AttackPath/utils'
import { consts } from '@app/styles'
import { formatNumberTo4CharactersMax } from '@libs/numbers/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

export interface IDrawerNodeProps extends React.SVGProps<SVGImageElement> {
  uid: string
  linkedEntity: EntityAttackPathNode
}

const TextWithNoFocus = styled.text`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.24px;
  line-height: 15px;
  user-select: none;
  fill: ${consts.colorWhite};
`

const DrawerNode: React.FC<IDrawerNodeProps> = props => {
  const { storeAttackPath } = useStores()

  const { childrenCount, nextDepthNodeCount } = props.linkedEntity

  const displayedNodes = !storeAttackPath.expandedNodeUids.includes(
    props.linkedEntity.uid
  )
    ? []
    : storeAttackPath.nodes.filter(
        node =>
          isEntityAttackPathNode(node) &&
          node.linkedNodeEntity?.uid === props.linkedEntity.uid
      )

  const formattedChildrenCount = formatNumberTo4CharactersMax(
    (childrenCount || nextDepthNodeCount || 0) - displayedNodes.length
  )

  const width = 28 + formattedChildrenCount.length * 8
  const height = 18

  return (
    <g>
      <g
        className={props.className}
        onClick={handleAdObjectChildrenOnClick(storeAttackPath)(
          props.linkedEntity.id,
          props.linkedEntity.uid,
          props.linkedEntity.depth,
          displayedNodes
        )}
        width={width}
        height={height}
        transform={`translate(${-width / 2}, ${-height / 2})`}
        cursor="pointer"
      >
        <rect
          width={width}
          height={height}
          rx={10}
          ry={10}
          fill={consts.colorAttackPathBackgroundGroupedNodeCount}
        />

        <IconOpenLink x={width - 19} y={2} />

        <TextWithNoFocus x={8} y={13}>
          {formattedChildrenCount}
        </TextWithNoFocus>
      </g>
    </g>
  )
}

export default observer(DrawerNode)
