import type { IGraphQLQuery } from '@libs/graphQL/types'
import type { Query, RbacUsersTracesQueryArgs } from '../typeDefs/types'

/**
 * Users Traces
 */

export type QueryRbacUsersTraces = IGraphQLQuery<
  RbacUsersTracesQueryArgs,
  Pick<Query, 'rbacUsersTraces'>
>

export const queryRbacUsersTraces = `
  query queryRbacUsersTraces (
    $dateStart: String
    $dateEnd: String
    $nextLink: String
    $ips: [String!]
    $userEmails: [String!]
    $logTypes: [String!]
  ) {
    rbacUsersTraces(
      dateStart: $dateStart
      dateEnd: $dateEnd
      nextLink: $nextLink
      ips: $ips
      userEmails: $userEmails
      logTypes: $logTypes
    ) {
      node {
        node {
          id
          createdAt
          userId
          userEmail
          userIp
          logTitle
          logSource
          logType
          logAttributes
        }
        pagination {
          nextLink
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
