import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'
import IconDirection, { Direction } from './IconDirection'

interface IIconUpProps extends IIconProps {}

const IconUp: React.FC<IIconUpProps> = props => (
  <IconDirection direction={Direction.up} {...props} />
)

export default IconUp
