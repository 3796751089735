import { ContainerSimple } from '@app/components-legacy/Container'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import type {
  CheckboxChangeEvent,
  CheckboxProps
} from 'antd/lib/checkbox/Checkbox'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import * as React from 'react'
import FormWrapperCheckboxSimple from './FormWrapperCheckboxSimple'
import type { IFormWrapperControl } from './types'

interface IFormWrapperCheckboxProps extends IFormWrapperControl {
  checkboxProps: CheckboxProps
}

const FormWrapperCheckbox: React.FC<IFormWrapperCheckboxProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  if (!themeIsLegacy) {
    return (
      <FormWrapperCheckboxSimple
        checked={props.checkboxProps.checked}
        defaultChecked={props.checkboxProps.defaultChecked}
        disabled={props.checkboxProps.disabled}
        indeterminate={props.checkboxProps.indeterminate}
        onChange={checked => {
          if (!props.checkboxProps.onChange) {
            return
          }
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const event: CheckboxChangeEvent = {
            target: { checked },
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            preventDefault: () => {},
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            stopPropagation: () => {}
          } as CheckboxChangeEvent
          props.checkboxProps.onChange(event)
        }}
        labelledBy={props.labelledBy}
      >
        {props.children}
      </FormWrapperCheckboxSimple>
    )
  }

  return (
    <ContainerSimple
      name={`${FormWrapperCheckbox.name}-${props.labelledBy}`}
      ariaRoles={props.ariaRoles || ['form', 'checkbox']}
      labelledBy={props.labelledBy}
    >
      <Checkbox {...props.checkboxProps}>{props.children}</Checkbox>
    </ContainerSimple>
  )
}

export default FormWrapperCheckbox
