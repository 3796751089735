import { CSSColors } from '@app/styles/colors/types'
import * as React from 'react'
import styled from 'styled-components'

/** @deprecated */
export enum BulletCircleSize {
  verySmall = 5,
  small = 7,
  medium = 10,
  large = 18
}

interface IBulletCircleProps {
  className?: string
  color: string
  size?: BulletCircleSize
  reversed?: boolean
}

/** @deprecated */
const BulletCircle = (props: IBulletCircleProps) => {
  return <div className={props.className} />
}

export default styled(BulletCircle)`
  height: ${props => `${props.size || BulletCircleSize.small}px`};
  width: ${props => `${props.size || BulletCircleSize.small}px`};
  background-color: ${props =>
    props.reversed ? 'white' : props.color || CSSColors.Black};
  border: 1px solid ${props => props.color || CSSColors.Black};
  border-radius: 50%;
`
