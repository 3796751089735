import Input from '@app/components-legacy/Form/Wrappers/Input'
import { useAppTranslator } from '@app/hooks'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { WidgetCommonFormFieldName } from '../types'

export interface IWidgetInputTitleProps {
  storeForm: StoreForm<any>
}

const WidgetInputTitle: React.FC<IWidgetInputTitleProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Dashboard.WidgetCommonForm']
  })

  return (
    <Input
      name={WidgetCommonFormFieldName.title}
      labelledBy="widgetInputTitle"
      inputProps={{
        value: props.storeForm.getFieldValueAsString(
          WidgetCommonFormFieldName.title
        ),
        placeholder: translate('Active users'),
        onChange: onInputChange(props.storeForm)(
          WidgetCommonFormFieldName.title
        ),
        autoComplete: 'off'
      }}
    />
  )
}

export default observer(WidgetInputTitle)
