import type { PropertiesNullable } from '@@types/helpers'
import type {
  DashboardWidget,
  DashboardWidgetOptions,
  DashboardWidgetOptionsSerie,
  DashboardWidgetType,
  Maybe
} from '@server/graphql/typeDefs/types'
import { createEntities } from '.'
import EntityBase from './EntityBase'
import EntityDashboardWidgetOptionsSerie from './EntityDashboardWidgetOptionsSerie'

export default class EntityDashboardWidgetOptions
  extends EntityBase
  implements PropertiesNullable<DashboardWidgetOptions>
{
  type: Maybe<DashboardWidgetType> = null

  series: Maybe<DashboardWidgetOptionsSerie[]> = null

  constructor(data: Partial<DashboardWidget>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return series.
   */
  getSeries(): EntityDashboardWidgetOptionsSerie[] {
    return createEntities<
      DashboardWidgetOptionsSerie,
      EntityDashboardWidgetOptionsSerie
    >(EntityDashboardWidgetOptionsSerie, this.series)
  }
}
