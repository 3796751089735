import {
  createEntities,
  createEntity,
  EntityProfile,
  EntityRbacRole
} from '@app/entities'
import EntityLDAPConfiguration from '@app/entities/EntityLDAPConfiguration'
import type { StoreRoot } from '@app/stores'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreForm from '@app/stores/helpers/StoreForm'
import type { IFieldValue } from '@app/stores/helpers/StoreForm/types'
import {
  ldapUrlFormat,
  mandatory
} from '@app/stores/helpers/StoreForm/validators'
import StoreFormGroups from '@app/stores/helpers/StoreFormGroups.ts'
import type { CreateGroupFn } from '@app/stores/helpers/StoreFormGroups.ts/types'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { ensureArray } from '@libs/ensureArray'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import { checkRbac } from '@libs/rbac/functions'
import { mutationUpdateLDAPConfiguration } from '@server/graphql/mutations/ldapConfiguration'
import type { QueryLDAPConfiguration } from '@server/graphql/queries/management'
import { queryLDAPConfiguration } from '@server/graphql/queries/management'
import type {
  InputLdapConfiguration,
  LdapConfiguration,
  Maybe,
  Profile,
  RbacRole,
  UpdateLdapConfigurationMutationArgs
} from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable } from 'mobx'
import {
  LdapConfigurationFieldName,
  LdapConfigurationGroupFieldName
} from './types'

export default class StoreLDAPConfiguration extends StoreBase {
  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Errors.Form', 'Management.System.Configuration.LDAP']
  })

  public storeForm = new StoreForm<LdapConfigurationFieldName>(this.storeRoot, {
    setup: {
      fields: {
        [LdapConfigurationFieldName.enabled]: {
          label: 'Enable LDAP authentication'
        },
        [LdapConfigurationFieldName.url]: {
          label: 'Address of the LDAP server',
          validators: [mandatory(), ldapUrlFormat()]
        },
        [LdapConfigurationFieldName.searchUserDN]: {
          label: 'Service account used to query the LDAP Server',
          validators: [mandatory()]
        },
        [LdapConfigurationFieldName.searchUserPassword]: {
          label: 'Service account password',
          validators: [mandatory()]
        },
        [LdapConfigurationFieldName.userSearchBase]: {
          label: 'LDAP search base',
          validators: [mandatory()]
        },
        [LdapConfigurationFieldName.userSearchFilter]: {
          label: 'LDAP search filter',
          validators: [mandatory()]
        },
        [LdapConfigurationFieldName.enableSaslBinding]: {
          label: 'Enable Sasl bindings'
        },
        [LdapConfigurationFieldName.relayId]: {
          label: 'Relay'
        }
      },
      translate: this.translate
    }
  })

  public storeFlags = new StoreFlags(this.storeRoot)
  public storeUpdateFlags = new StoreFlags(this.storeRoot)

  public storeFormAllowedGroups = new StoreFormGroups<
    StoreForm<LdapConfigurationGroupFieldName>
  >(this.storeRoot)

  /* Observable */

  private $ldapConfiguration =
    observable.box<Maybe<EntityLDAPConfiguration>>(null)
  private $rbacRoles = observable.box<Maybe<EntityRbacRole[]>>(null)
  private $profiles = observable.box<Maybe<EntityProfile[]>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  fetchLDAPConfiguration() {
    this.storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryLDAPConfiguration>(queryLDAPConfiguration)
      })
      .then(({ rbacLDAPConfiguration, rbacProfiles, rbacRoles }) => {
        if (
          !checkRbac(this.storeRoot, this.storeFlags)(rbacLDAPConfiguration)
        ) {
          throw new ForbiddenAccessError()
        }

        const ldapConfigurationEntity = createEntity<
          LdapConfiguration,
          EntityLDAPConfiguration
        >(EntityLDAPConfiguration, rbacLDAPConfiguration.node)

        const rbacProfilesEntities = createEntities<Profile, EntityProfile>(
          EntityProfile,
          ensureArray(rbacProfiles.node)
        )

        const rbacRolesEntities = createEntities<RbacRole, EntityRbacRole>(
          EntityRbacRole,
          ensureArray(rbacRoles.node)
        )

        this.setEntities(
          ldapConfigurationEntity,
          rbacProfilesEntities,
          rbacRolesEntities
        )

        this.setFormDefaultValues()
        this.setFormGroupsForAllowedGroups(ldapConfigurationEntity)

        this.storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlags))
  }

  /**
   * Update LDAP configuration.
   */
  updateLDAPConfiguration(ldapConfiguration: InputLdapConfiguration) {
    this.storeUpdateFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: UpdateLdapConfigurationMutationArgs = {
          ldapConfiguration
        }

        return this.storeRoot
          .getGQLRequestor()
          .query(mutationUpdateLDAPConfiguration, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('LDAP configuration updated'),
          {
            labelledBy: 'ldapConfigurationUpdated'
          }
        )

        this.storeUpdateFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeUpdateFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when editing the LDAP configuration'
        })
      )
  }

  /**
   * Set the allowed groups (groups of StoreForm in storeFormAllowedGroups).
   */
  setFormGroupsForAllowedGroups(
    ldapConfigurationEntity: EntityLDAPConfiguration
  ): void {
    if (!ldapConfigurationEntity.allowedGroups) {
      return
    }

    this.storeFormAllowedGroups.reset()

    ldapConfigurationEntity.allowedGroups.forEach(() => {
      this.storeFormAllowedGroups.addGroup(this.createAllowedGroups)
    })
  }

  /* Actions */

  /**
   * Reset everything.
   */
  @action
  reset(): this {
    this.storeFormAllowedGroups.reset()

    this.storeForm.hardReset()

    this.storeFlags.reset()
    this.storeUpdateFlags.reset()

    this.$ldapConfiguration.set(null)
    this.$rbacRoles.set(null)
    this.$profiles.set(null)

    return this
  }

  /**
   * Save LDAP configuration.
   */
  @action
  setEntities(
    ldapConfigurationEntity: EntityLDAPConfiguration,
    profilesEntities: Maybe<EntityProfile[]>,
    rbacRolesEntities: Maybe<EntityRbacRole[]>
  ): this {
    this.$ldapConfiguration.set(ldapConfigurationEntity)
    this.$profiles.set(profilesEntities)
    this.$rbacRoles.set(rbacRolesEntities)

    return this
  }

  /**
   * Reset the form first and set fields values.
   */
  @action
  setFormDefaultValues(): this {
    const ldapConfiguration = this.ldapConfiguration

    if (!ldapConfiguration) {
      return this
    }

    this.storeForm
      .setDefaultFieldsValuesFromObject(ldapConfiguration, {
        action: 'omit',
        keys: ['allowedGroups']
      })
      .reset()

    return this
  }

  /**
   * When adding a group fieldset configuration (for default profile and defaultRoles),
   * instanciate a new StoreForm instance and setup default fields according to
   * the current LDAP configuration.
   */
  @action
  createAllowedGroups: CreateGroupFn<
    StoreForm<LdapConfigurationGroupFieldName>
  > = index => {
    const storeForm = new StoreForm(this.storeRoot, {
      setup: {
        fields: {
          [LdapConfigurationGroupFieldName.groupName]: {
            label: 'LDAP group name',
            validators: [mandatory()]
          },
          [LdapConfigurationGroupFieldName.defaultProfileId]: {
            label: 'Default profile',
            validators: [mandatory()]
          },
          [LdapConfigurationGroupFieldName.defaultRoleIds]: {
            label: 'Default roles',
            validators: [mandatory()]
          }
        }
      }
    })

    if (!this.ldapConfiguration) {
      return storeForm
    }

    const defaultValues: IFieldValue[] = []

    const allowedGroups = ensureArray(this.ldapConfiguration.allowedGroups)[
      index
    ]

    const groupName = allowedGroups && allowedGroups.name

    if (groupName) {
      defaultValues.push({
        key: LdapConfigurationGroupFieldName.groupName,
        value: groupName
      })
    }

    const defaultProfileId = allowedGroups && allowedGroups.defaultProfileId

    if (defaultProfileId) {
      defaultValues.push({
        key: LdapConfigurationGroupFieldName.defaultProfileId,
        value: defaultProfileId
      })
    }

    const defaultRoleIds = allowedGroups && allowedGroups.defaultRoleIds

    if (defaultRoleIds) {
      defaultValues.push({
        key: LdapConfigurationGroupFieldName.defaultRoleIds,
        value: defaultRoleIds.join(',')
      })
    }

    storeForm.setDefaultFieldsValues(defaultValues).reset()

    return storeForm
  }

  /* Computed */

  @computed
  get ldapConfiguration(): Maybe<EntityLDAPConfiguration> {
    return this.$ldapConfiguration.get()
  }

  @computed
  get rbacRoles(): EntityRbacRole[] {
    return ensureArray(this.$rbacRoles.get())
  }

  @computed
  get rbacRoleNames(): string[] {
    return ensureArray(this.$rbacRoles.get())
      .map(rbacRole => rbacRole.name)
      .filter(isDefined)
  }

  @computed
  get profiles(): EntityProfile[] {
    return ensureArray(this.$profiles.get())
  }
}
