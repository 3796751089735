import { ContainerFlex } from '@app/components-legacy/Container'
import * as React from 'react'
import TableContentBody from './TableContentBody'
import TableContentHead from './TableContentHead'

export interface ITableProps {
  className?: string
}

const Table: React.FC<ITableProps> = props => {
  return (
    <ContainerFlex
      name="Table"
      className={props.className}
      direction="column"
      items={[<TableContentHead />, <TableContentBody />]}
      fullHeight
    />
  )
}

export default Table
