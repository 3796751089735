import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { TypographyFieldsetTitle } from '../Typography'

interface INavigationTitleProps {
  className?: string
  label: string
}

const NavigationTitle: React.FC<INavigationTitleProps> = props => {
  return (
    <TypographyFieldsetTitle className={props.className}>
      {props.label}
    </TypographyFieldsetTitle>
  )
}

export default styled(NavigationTitle)`
  margin-bottom: ${consts.marginSmall};

  :not(:first-child) {
    margin-top: ${consts.marginDefault};
  }
`
