import type { Maybe } from '@@types/helpers'
import { BladeContentLayoutDefault as BladeContentLayoutDefaultDeprecated } from '@app/components-legacy/Blade/BladeContent'
import BladeContentLayoutDefault from '@app/components-legacy/Blade/BladeContent/BladeContentLayoutDefault'
import {
  ContainerContent,
  ContainerFooter
} from '@app/components-legacy/Container'
import {
  Drawer as DrawerDeprecated,
  DrawerCancelButton,
  DrawerContext as DrawerContextDeprecated,
  DrawerHorizontalContainer as DrawerHorizontalContainerDeprecated,
  DrawerTitle as DrawerTitleDeprecated
} from '@app/components-legacy/Drawer'
import { DrawerContext } from '@app/components-legacy/Drawer/context'
import Drawer from '@app/components-legacy/Drawer/Drawer'
import DrawerHorizontalContainer from '@app/components-legacy/Drawer/DrawerHorizontalContainer'
import DrawerTitle from '@app/components-legacy/Drawer/DrawerTitle'
import { FormWrapper } from '@app/components-legacy/Form/Wrappers'
import FormWrapperButtonSubmit from '@app/components-legacy/Form/Wrappers/ButtonSubmit'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { explodeWidgetKey } from '@libs/dashboards/keys'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { canRemoveDashboardWidget } from '../../permissions'
import { onDashboardRemoveValidate } from './handlers'

interface IDrawerRemoveDashboardWidgetProps {}

const P = styled.p`
  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      color: ${consts.FontColorV2.primary};
    `}
`

const DrawerRemoveDashboardWidget: React.FC<
  IDrawerRemoveDashboardWidgetProps
> = () => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Components.LabelConfirm',
      'Dashboard.RemoveDashboardWidgetDrawer',
      'Dashboard.Common'
    ]
  })

  const { storeDashboards } = useStores()

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const storeDashboard = storeDashboards.currentStoreDashboard

  if (!storeDashboard) {
    return null
  }

  const getIds = (): ReturnType<typeof explodeWidgetKey> => {
    const widgetKey =
      storeDashboard.storeWidgetsManagement.storeDrawer.getDataValue(
        'widgetKey'
      )

    if (!widgetKey) {
      return null
    }

    const ids = explodeWidgetKey(widgetKey)

    return ids
  }

  const renderContent = (): Maybe<React.ReactElement> => {
    const ids = getIds()

    if (!ids) {
      return null
    }

    return (
      <FormWrapper
        name="dashboardDeletion"
        onSubmit={onDashboardRemoveValidate(storeDashboards)(ids)}
      >
        <ContainerContent>
          <P>{translate('You will remove this widget')}</P>
          <P>{translate('Are you sure')}</P>

          <Portal name={PlaceHolderName.drawerFooter}>
            <ContainerFooter>
              <DrawerCancelButton
                storeDrawer={storeDashboard.storeWidgetsManagement.storeDrawer}
              />

              {themeIsLegacy ? (
                <FormWrapperButtonSubmit
                  loading={
                    storeDashboard.storeWidgetsManagement.storeFlags.flags
                      .isLoading
                  }
                >
                  {translate('OK')}
                </FormWrapperButtonSubmit>
              ) : (
                <FormWrapperButtonSubmit
                  type="primary"
                  htmlType="submit"
                  loading={
                    storeDashboard.storeWidgetsManagement.storeFlags.flags
                      .isLoading
                  }
                >
                  {translate('Delete')}
                </FormWrapperButtonSubmit>
              )}
            </ContainerFooter>
          </Portal>
        </ContainerContent>
      </FormWrapper>
    )
  }

  const dashboardWidgetId = getIds()?.dashboardWidgetId || null

  return themeIsLegacy ? (
    <DrawerContextDeprecated.Provider
      value={{
        storeDrawer: storeDashboard.storeWidgetsManagement.storeDrawer
      }}
    >
      <DrawerDeprecated>
        <DrawerHorizontalContainerDeprecated labelledBy="deleteWidget">
          <BladeContentLayoutDefaultDeprecated
            layout={{
              name: 'default',
              title: (
                <DrawerTitleDeprecated>
                  {translate('Remove a widget')}
                </DrawerTitleDeprecated>
              ),
              content: renderContent(),
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canRemoveDashboardWidget(dashboardWidgetId)}
          />
        </DrawerHorizontalContainerDeprecated>
      </DrawerDeprecated>
    </DrawerContextDeprecated.Provider>
  ) : (
    <DrawerContext.Provider
      value={{
        storeDrawer: storeDashboard.storeWidgetsManagement.storeDrawer
      }}
    >
      <Drawer>
        <DrawerHorizontalContainer labelledBy="deleteWidget">
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: <DrawerTitle>{translate('Delete widget')}</DrawerTitle>,
              content: renderContent(),
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canRemoveDashboardWidget(dashboardWidgetId)}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerRemoveDashboardWidget)
