import type { IEntityListable } from '@app/entities/types'
import type { IDataRowGeneric } from '@app/stores/helpers/StoreWidgetList/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import type { IWidgetListTableCommonProps } from './types'
import WidgetTable from './WidgetTable'

interface IWidgetListTableProps<
  E extends IEntityListable<IDataRowGeneric>,
  D extends IDataRowGeneric
> extends IWidgetListTableCommonProps<E, D> {
  className?: string
  style?: React.CSSProperties
}

/**
 * @wip This component is currently migrating from component-legacy.
 */
export function WidgetListTable<E extends IEntityListable<IDataRowGeneric>, D>(
  props: IWidgetListTableProps<E, D>
): React.ReactElement {
  return (
    <div
      data-name="WidgetListTable"
      className={props.className}
      style={props.style}
    >
      <WidgetTable<E, D> {...props} />
    </div>
  )
}

export default observer(WidgetListTable)
