import type { EntityUserLog } from '@app/entities'
import type { StoreActivityLogs } from '@app/stores'

/**
 * Open the drawer to display the payload
 */
export const onShowPayloadButtonClick =
  (storeActivityLogs: StoreActivityLogs, log: EntityUserLog) =>
  (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    const { storeActivityLogPayload: storeTracePayload } = storeActivityLogs
    storeTracePayload.setTrace(log)
    storeActivityLogs.storeActivityLogPayload.storeDrawerPayload.openDrawer()
  }
