import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelChecker } from '@app/components-legacy/Label'
import { LabelCheckerSize } from '@app/components-legacy/Label/LabelChecker/types'
import type { EntityAttackType } from '@app/entities'
import * as React from 'react'

interface IIoAAttacksTableBodyDataRowAttackNameProps {
  className?: string
  attackTypeEntity: Maybe<EntityAttackType>
}

const IoAAttacksTableBodyDataRowAttackName: React.FC<
  IIoAAttacksTableBodyDataRowAttackNameProps
> = props => {
  if (!props.attackTypeEntity) {
    return null
  }

  if (!props.attackTypeEntity.genericName) {
    return null
  }

  return (
    <ContainerFlex
      name="IoAAttacksTableBodyDataRowAttackName"
      alignItems="center"
      justifyContent="center"
      items={[
        <LabelChecker
          name={props.attackTypeEntity.genericName}
          criticity={props.attackTypeEntity.genericCriticity}
          size={LabelCheckerSize.small}
          hoverable={false}
          small
        />
      ]}
    />
  )
}

export default IoAAttacksTableBodyDataRowAttackName
