import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface ICellWrapperProps {
  className?: string
  children: React.ReactNode
  isHeadCell?: boolean
}

const Div = styled.div<{ isHeadCell: boolean }>`
  background: ${consts.BackgroundColorV2.white};
  padding: ${props =>
    props.isHeadCell
      ? `${consts.paddingDefault} ${consts.paddingDefault} ${consts.paddingVerySmall} ${consts.paddingDefault}`
      : `0 ${consts.paddingDefault}`};
`

const CellWrapper: React.FC<ICellWrapperProps> = props => {
  return (
    <Div className={props.className} isHeadCell={props.isHeadCell || false}>
      {props.children}
    </Div>
  )
}

export default CellWrapper
