import type { MaybeUndef } from '@@types/helpers'
import { Features } from '@alsid/common'
import {
  ContainerContent,
  ContainerFlex,
  ContainerFooter,
  ContainerIcon,
  ContainerRbac
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { IconBee } from '@app/components-legacy/Icon'
import {
  IconDeleteOutlined,
  IconEditOutlined,
  IconSyncOutlined
} from '@app/components-legacy/Icon/IconAntd'
import IconBlank from '@app/components-legacy/Icon/IconBlank'
import { InputSearch } from '@app/components-legacy/Input'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import SpinnerInline, {
  SpinnerInlineSize
} from '@app/components-legacy/Spinner/SpinnerInline'
import {
  WidgetListActionButtons,
  WidgetListActionsHeader,
  WidgetListCountItems,
  WidgetListPagination,
  WidgetListTable,
  WidgetListTableActionIcons
} from '@app/components-legacy/Widgets/WidgetList'
import type { EntityDirectory } from '@app/entities'
import type { IDataRowDirectory } from '@app/entities/EntityDirectory'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { useStores } from '@app/hooks/useStores'
import type { TooltipProps } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  canAccessToHoneyAccount,
  canCreateDirectories,
  canDeleteDirectory,
  canEditDirectory,
  canEditHoneyAccount,
  canRecrawlDirectory
} from '../permissions'
import DirectoryIconCrawlingStatus from './DirectoryIconCrawlingStatus'
import DirectoryIconHoneyAccountStatus from './DirectoryIconHoneyAccountStatus'
import {
  handleCreateDirectoryButtonOnClick,
  handleDeleteDirectoryIconOnClick,
  handleDirectoryPageOnChange,
  handleEditDirectoryIconOnClick,
  handleEditHoneyAccountDirectoryIconOnClick,
  handleRecrawlDirectoryIconOnClick,
  handleSearchDirectoryOnChange,
  handleSearchDirectoryOnReset
} from './handlers'

const StyledHeadStatus = styled('div')`
  text-align: center;
`

export interface IDirectoriesListProps {}

const DirectoriesList: React.FC<IDirectoriesListProps> = props => {
  const featureSensitiveCollectionUserHashes = useFeatureFlag({
    featureFlagName: Features.SENSITIVE_COLLECTION_USER_HASHES
  })

  const featureSensitiveCollectionDpapiKey = useFeatureFlag({
    featureFlagName: Features.SENSITIVE_COLLECTION_DPAPI_KEY
  })

  const featureForestAndDomainSearch = useFeatureFlag({
    featureFlagName: Features.FOREST_AND_DOMAIN_SEARCH
  })

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.System.Directories']
  })

  const appRouter = useAppRouter()

  const { storeRbac, storeManagementDirectories } = useStores()

  const isPrivilegedAnalysisEnabled = Array.from(
    storeManagementDirectories.directories.values()
  ).some(directory => directory.sensitiveDataCollectionIsEnabled)

  const hiddenKeys: Array<keyof IDataRowDirectory> = ['id']

  if (!storeRbac.isUserGrantedTo(canAccessToHoneyAccount)) {
    hiddenKeys.push('honeyAccountConfigurationStatus')
  }

  if (
    !(
      featureSensitiveCollectionUserHashes || featureSensitiveCollectionDpapiKey
    ) ||
    !isPrivilegedAnalysisEnabled
  ) {
    hiddenKeys.push('sensitiveDataCollectionStatus')
  }

  return (
    <>
      <ContainerFlex
        name="DirectoriesList"
        direction="column"
        items={[
          featureForestAndDomainSearch ? (
            <InputSearch
              placeholder={translate('Search for a domain')}
              storeInputSearch={storeManagementDirectories.storeInputSearch}
              onReset={handleSearchDirectoryOnReset(storeManagementDirectories)}
              onChange={handleSearchDirectoryOnChange(
                storeManagementDirectories
              )}
            />
          ) : null,
          <WidgetListActionsHeader>
            <WidgetListCountItems
              storeWidgetList={storeManagementDirectories.storeWidgetList}
            />

            <WidgetListActionButtons>
              <ContainerRbac rbacCapabilityCheck={canCreateDirectories}>
                <FormWrapperButton
                  labelledBy="addDirectory"
                  buttonProps={{
                    type: 'primary',
                    onClick: handleCreateDirectoryButtonOnClick(appRouter)
                  }}
                >
                  {translate('Add a domain')}
                </FormWrapperButton>
              </ContainerRbac>
            </WidgetListActionButtons>
          </WidgetListActionsHeader>,

          <ContainerContent
            flags={storeManagementDirectories.storeSearchFlags.flags}
            spinner={<SpinnerInline size={SpinnerInlineSize.small} />}
          >
            <WidgetListTable<EntityDirectory, IDataRowDirectory>
              translate={translate}
              hiddenColumnsKeys={hiddenKeys}
              storeWidgetList={storeManagementDirectories.storeWidgetList}
              headsRenderFn={{
                ldapCrawlingStatus: str => (
                  <StyledHeadStatus>{translate(str)}</StyledHeadStatus>
                ),
                sysvolCrawlingStatus: str => (
                  <StyledHeadStatus>{translate(str)}</StyledHeadStatus>
                ),
                sensitiveDataCollectionStatus: str => (
                  <StyledHeadStatus>{translate(str)}</StyledHeadStatus>
                ),
                honeyAccountConfigurationStatus: str => (
                  <StyledHeadStatus>{translate(str)}</StyledHeadStatus>
                )
              }}
              cellsRenderFn={{
                ldapCrawlingStatus: status => (
                  <DirectoryIconCrawlingStatus status={status} source="LDAP" />
                ),
                sysvolCrawlingStatus: status => (
                  <DirectoryIconCrawlingStatus
                    status={status}
                    source="SYSVOL"
                  />
                ),
                sensitiveDataCollectionStatus: (status, row) => {
                  const currentDirectory =
                    storeManagementDirectories.directories.get(row.id)

                  if (!currentDirectory) {
                    return
                  }

                  if (!currentDirectory.sensitiveDataCollectionIsEnabled) {
                    return
                  }

                  return (
                    <DirectoryIconCrawlingStatus
                      status={status}
                      source="Sensitive data collection"
                    />
                  )
                },
                honeyAccountConfigurationStatus: (status, row) => {
                  // Required when the Honey Account will be in GA
                  if (!status) {
                    return
                  }

                  const isConfigurable =
                    storeManagementDirectories.isHoneyAccountConfigurable(
                      row.id
                    )

                  const labelledBy = isConfigurable
                    ? `directory-sync-honey-account-${status}`
                    : `directory-sync-honey-account-${status}-inactive`

                  return (
                    <DirectoryIconHoneyAccountStatus
                      labelledBy={labelledBy}
                      status={status}
                      isConfigurable={isConfigurable}
                      directoryId={row.id}
                    />
                  )
                }
              }}
              actionsButtonsRenderFn={directoryId => {
                const isReadyToRecrawl =
                  storeManagementDirectories.isDirectoryReadyToRecrawl(
                    Number(directoryId)
                  )

                const isConfigurable =
                  storeManagementDirectories.isHoneyAccountConfigurable(
                    Number(directoryId)
                  )

                const isEditable =
                  storeManagementDirectories.isHoneyAccountEditable(
                    Number(directoryId)
                  )

                return (
                  <WidgetListTableActionIcons
                    icons={[
                      <ContainerRbac
                        rbacCapabilityCheck={canRecrawlDirectory(
                          Number(directoryId)
                        )}
                        render={({ isGranted }) => {
                          return isGranted ? (
                            <ContainerIcon
                              labelledBy={`recrawlDirectory-${directoryId}`}
                              iconComponent={IconSyncOutlined}
                              iconProps={{
                                type: 'recrawl',
                                disabled: !isReadyToRecrawl,
                                onClick: isReadyToRecrawl
                                  ? handleRecrawlDirectoryIconOnClick(
                                      storeManagementDirectories
                                    )(Number(directoryId))
                                  : undefined
                              }}
                              tooltipProps={{
                                placement: 'rightBottom',
                                title: translate(
                                  isReadyToRecrawl
                                    ? 'Force data domain refreshment'
                                    : 'Force data domain refreshment not possible'
                                )
                              }}
                            />
                          ) : (
                            <IconBlank />
                          )
                        }}
                      />,

                      <ContainerRbac
                        rbacCapabilityCheck={canEditHoneyAccount(
                          Number(directoryId)
                        )}
                        render={({ isGranted }) => {
                          const tooltipHaNotAvailable: MaybeUndef<TooltipProps> =
                            !isConfigurable && !isEditable
                              ? {
                                  placement: 'rightBottom',
                                  title: translate(
                                    'Honey Account Not Yet Available'
                                  )
                                }
                              : undefined

                          const tooltipHaUnexist: MaybeUndef<TooltipProps> =
                            !isEditable
                              ? {
                                  placement: 'rightBottom',
                                  title: translate(
                                    'The Honey Account edition will only be available after it has been added'
                                  )
                                }
                              : undefined

                          return isGranted ? (
                            <ContainerIcon
                              labelledBy="editHoneyAccount"
                              iconComponent={IconBee}
                              iconProps={{
                                type: 'edit',
                                disabled: !isEditable,
                                onClick:
                                  handleEditHoneyAccountDirectoryIconOnClick(
                                    appRouter
                                  )(Number(directoryId))
                              }}
                              tooltipProps={
                                tooltipHaNotAvailable || tooltipHaUnexist
                              }
                            />
                          ) : null
                        }}
                      />,
                      <ContainerRbac
                        rbacCapabilityCheck={canEditDirectory(
                          Number(directoryId)
                        )}
                        render={({ isGranted }) => {
                          return isGranted ? (
                            <ContainerIcon
                              labelledBy={`editDirectory-${directoryId}`}
                              iconComponent={IconEditOutlined}
                              iconProps={{
                                type: 'edit',
                                onClick: handleEditDirectoryIconOnClick(
                                  appRouter
                                )(Number(directoryId))
                              }}
                            />
                          ) : (
                            <IconBlank />
                          )
                        }}
                      />,

                      <ContainerRbac
                        rbacCapabilityCheck={canDeleteDirectory(
                          Number(directoryId)
                        )}
                        render={({ isGranted }) => {
                          return isGranted ? (
                            <ContainerIcon
                              labelledBy={`deleteDirectory-${directoryId}`}
                              iconComponent={IconDeleteOutlined}
                              iconProps={{
                                onClick: handleDeleteDirectoryIconOnClick(
                                  storeManagementDirectories
                                )(Number(directoryId))
                              }}
                            />
                          ) : (
                            <IconBlank />
                          )
                        }}
                      />
                    ]}
                  />
                )
              }}
            />
          </ContainerContent>
        ]}
        spaced
      />

      <Portal name={PlaceHolderName.bladeFooter}>
        <ContainerFooter>
          <ContainerFlex
            name="Pagination"
            justifyContent="flex-end"
            items={[
              <WidgetListPagination
                storeWidgetList={storeManagementDirectories.storeWidgetList}
                onChange={handleDirectoryPageOnChange(
                  storeManagementDirectories
                )}
              />
            ]}
          />
        </ContainerFooter>
      </Portal>
    </>
  )
}

export default observer(DirectoriesList)
