import { AppRouteName } from '@app/routes'
import type { StoreManagementTenants } from '@app/stores'
import { TenantsFormFieldName } from '@app/stores/Management/StoreTenants'
import type { CreateTenantSetting } from '@libs/openapi/service-identity-core'

/**
 * Clear everything when loading the blade.
 */
export const handleTenantCreationPageLoad =
  (storeManagementTenants: StoreManagementTenants) => () => {
    storeManagementTenants.storeFormCreation.reset()

    storeManagementTenants.fetchCredentials()
  }

/**
 * Create the tenant,
 * Fetch tenants,
 * And go back to the list.
 */
export const handleTenantCreationOnSubmit =
  (storeManagementTenants: StoreManagementTenants) =>
  (e: React.FormEvent<any>) => {
    const { appRouter } = storeManagementTenants.storeRoot
    const { storeFormCreation, storeRoot } = storeManagementTenants

    e.preventDefault()

    if (!storeFormCreation.validate()) {
      return
    }

    const tenant: CreateTenantSetting = {
      name: storeFormCreation.getFieldValueAsString(TenantsFormFieldName.name),
      credentialsId: storeFormCreation.getFieldValueAsString(
        TenantsFormFieldName.credentialUUID
      )
    }

    storeManagementTenants
      .createTenant(tenant)
      .then(() => storeManagementTenants.fetchTenants())
      .then(() => {
        storeFormCreation.reset()

        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Management_System_Tenants,
          parameters: {}
        })

        appRouter.history.push(url)

        storeRoot.stores.storeMessages.success(
          storeManagementTenants.translate('Tenant X created', {
            interpolations: {
              tenantName: tenant.name
            }
          }),
          {
            labelledBy: 'tenantCreated'
          }
        )
      })
  }

export const handleTenantRefreshCredentialsOnClick =
  (storeManagementTenants: StoreManagementTenants) => () => {
    storeManagementTenants.reloadCredentials()
  }

/** Handle new credentials addition by opening T.io credentials view */
export const handleAddCredentialOnClick =
  (storeManagementTenants: StoreManagementTenants) => () => {
    window.open(
      storeManagementTenants.storeRoot.environment.config.app.settings
        .tiocredentials.url
    )
  }
