import type { IIconProps } from '@app/components/Icon/types'
import { BackgroundColorV2, FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconWarningCircleProps extends IIconProps {}

const IconWarningCircle: React.FC<IIconWarningCircleProps> = props => {
  const fill = props.color ?? FontColorV2.red
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="8" cy="8" r="8" fill={fill} />
      <rect
        x="7"
        y="3"
        width="2"
        height="7"
        fill={BackgroundColorV2.redLight}
      />
      <circle cx="8" cy="12" r="1" fill={BackgroundColorV2.redLight} />
    </svg>
  )
}

export default IconWarningCircle
