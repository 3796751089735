import { ContainerContent, ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { FormWrapperSwitch } from '@app/components-legacy/Form/Wrappers'
import { useAppTranslator, useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToDataCollectionConfiguration } from '../../permissions'
import { canEditDataCollectionConfiguration } from '../permissions'
import ConfigurationDataCollectionCard from './ConfigurationDataCollectionCard'
import { handleDataCollectionStatusOnUpdate } from './handlers'

export interface IConfigurationDataCollectionProps {
  className?: string
}

export function ConfigurationDataCollection(
  props: IConfigurationDataCollectionProps
) {
  const { storeManagementDataCollection, storeRbac } = useStores()

  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Configuration.Navigation',
      'Management.System.Configuration.DataCollection'
    ]
  })

  const isEnabled =
    storeManagementDataCollection.dataCollectionStatusEntity?.getPropertyAsBoolean(
      'isEnabled'
    )

  return (
    <ContainerContent
      name="ConfigurationDataCollectionPage"
      className={props.className}
      rbacCapabilityCheck={canAccessToDataCollectionConfiguration}
    >
      <ContainerFlex
        name="card"
        direction="column"
        flexGap="default"
        items={[
          <ContainerFlex
            name="form"
            alignItems="center"
            justifyContent="space-between"
            items={[
              <Label variant={LabelVariant.h2}>
                {translate(`Use the Tenable Cloud service`)}
              </Label>,

              <FormWrapperSwitch
                labelledBy="tenableCloud"
                switchProps={{
                  disabled: !storeRbac.isUserGrantedTo(
                    canEditDataCollectionConfiguration
                  ),
                  loading:
                    storeManagementDataCollection
                      .storeFlagsDataCollectionStatusFetch.isLoading,
                  checked: isEnabled,
                  onChange: handleDataCollectionStatusOnUpdate(
                    storeManagementDataCollection
                  )
                }}
              />
            ]}
            spaced
            spaceWidth="small"
          />,

          <ConfigurationDataCollectionCard />
        ]}
      />
    </ContainerContent>
  )
}

export default observer(ConfigurationDataCollection)
