import {
  ContainerContent,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { useAppTranslator } from '@app/hooks'
import type StoreWidgetsManagement from '@app/stores/Dashboard/StoreWidgetsManagement'
import type StoreRoot from '@app/stores/StoreRoot'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { filterFalsies } from '@libs/filterFalsies'
import * as React from 'react'
import WidgetInputDataOptionsType from '../WidgetInput/WidgetInputDataOptionsType'
import WidgetInputDuration from '../WidgetInput/WidgetInputDuration'
import WidgetInputsDataOptionsSeries from '../WidgetInputsDataOptions/WidgetInputsDataOptionsSeries'

export interface IWidgetInputsForLineChartProps {
  hasMultipleInstance: boolean
  storeWidgetsManagement: StoreWidgetsManagement
  storeRoot?: StoreRoot
}

const WidgetInputsForLineChart: React.FC<
  IWidgetInputsForLineChartProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Dashboard.WidgetCommonForm']
  })

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const fields: IField[] = filterFalsies([
    props.hasMultipleInstance && {
      name: 'instanceName',
      label: translate('Instance'),
      control: <div>{props.storeWidgetsManagement.instanceName}</div>
    },
    {
      name: 'dataType',
      label: translate('Type of data'),
      labelAlignItem: themeIsLegacy ? 'baseline' : 'center',
      control: (
        <WidgetInputDataOptionsType
          storeWidgetsManagement={props.storeWidgetsManagement}
        />
      )
    },
    {
      name: 'duration',
      label: translate('Duration'),
      labelAlignItem: themeIsLegacy ? 'baseline' : 'center',
      control: (
        <WidgetInputDuration
          storeWidgetsManagement={props.storeWidgetsManagement}
        />
      )
    }
  ])

  return (
    <ContainerContent name="WidgetInputsForLineChart">
      <ContainerForm fields={fields} />

      <WidgetInputsDataOptionsSeries
        storeWidgetsManagement={props.storeWidgetsManagement}
      />
    </ContainerContent>
  )
}

export default WidgetInputsForLineChart
