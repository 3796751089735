import type { PropertiesNullable } from '@@types/helpers'
import { LicenseStatus } from '@app/stores/StoreLicense/types'
import type {
  License,
  LicenseFeature,
  LicenseType,
  Maybe
} from '@server/graphql/typeDefs/types'
import * as moment from 'moment'
import EntityBase from './EntityBase'

export default class EntityLicense
  extends EntityBase
  implements PropertiesNullable<License>
{
  customerName: Maybe<string> = null
  type: Maybe<LicenseType> = null
  currentActiveUserCount: Maybe<number> = null
  maxActiveUserCount: Maybe<number> = null
  features: Maybe<LicenseFeature[]> = null
  expirationDateUTC: Maybe<string> = null
  inAppEula: Maybe<boolean> = null
  activationCode: Maybe<string> = null
  containerUuid: Maybe<string> = null
  productAssociation: Maybe<string> = null

  constructor(data: Partial<License>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return the status of the license.
   */
  getStatus(): LicenseStatus {
    if (!this.type) {
      return LicenseStatus.unset
    }

    /**
     * Check the expiration date.
     */

    const expirationDateUTC = this.getPropertyAsString('expirationDateUTC')

    if (!expirationDateUTC) {
      return LicenseStatus.expired
    }

    if (moment.utc().isAfter(moment.utc(expirationDateUTC))) {
      return LicenseStatus.expired
    }

    /**
     * Check the nb max of users allowed.
     */

    // License is "over violated" (more than 10% overusage)
    if (
      this.getPropertyAsNumber('currentActiveUserCount') >
      this.getPropertyAsNumber('maxActiveUserCount') * 1.1
    ) {
      return LicenseStatus.overViolated
    }

    // License is violated in an acceptable amount (less than 10% overusage)
    if (
      this.getPropertyAsNumber('currentActiveUserCount') >
      this.getPropertyAsNumber('maxActiveUserCount')
    ) {
      return LicenseStatus.violated
    }

    // Close to license violation
    if (
      this.getPropertyAsNumber('currentActiveUserCount') >
      this.getPropertyAsNumber('maxActiveUserCount') * 0.95
    ) {
      return LicenseStatus.nearViolated
    }

    return LicenseStatus.valid
  }
}
