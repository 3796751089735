import { ContainerFlex } from '@app/components-legacy/Container'
import {
  IconLineChartOutlined,
  IconPieChartOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { useAppTranslator, useStores } from '@app/hooks'
import { AttacksSummaryChartCardType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { FieldName } from '../types'
import { handlerEditCardFormChartTypeSelection } from './handlers'
import InputIllustrationContainer from './InputIllustrationContainer'

const InputIllustration: React.FC<{}> = () => {
  const translate = useAppTranslator({
    namespaces: ['IoA.Board']
  })

  const { storeIoA } = useStores()
  const { storeSummaryCards } = storeIoA.storeBoard
  const { storeFormEditCard } = storeSummaryCards

  const selectedType = storeFormEditCard.getFieldValueAsString(
    FieldName.chartType
  )

  return (
    <ContainerFlex
      name="InputIllustration"
      items={[
        <InputIllustrationContainer
          name={translate('Attack distribution')}
          type={AttacksSummaryChartCardType.Donut}
          selected={selectedType === AttacksSummaryChartCardType.Donut}
          onChange={handlerEditCardFormChartTypeSelection(storeSummaryCards)}
        >
          <IconPieChartOutlined />
        </InputIllustrationContainer>,

        <InputIllustrationContainer
          name={translate('Number of events')}
          type={AttacksSummaryChartCardType.LineChart}
          selected={selectedType === AttacksSummaryChartCardType.LineChart}
          onChange={handlerEditCardFormChartTypeSelection(storeSummaryCards)}
        >
          <IconLineChartOutlined />
        </InputIllustrationContainer>
      ]}
      spaced
    />
  )
}

export default observer(InputIllustration)
