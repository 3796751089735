import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import type { IDataRowGeneric } from '@app/stores/helpers/StoreWidgetList/types'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { WidgetListPageSelectorValue } from './types'

/**
 * Select rows, pages or all the list when selectionning an option
 * in the page selector.
 */
export const onPageSelectorChange =
  (storeWidgetList: StoreWidgetList<any, any>) =>
  (selectValue: WidgetListPageSelectorValue) => {
    switch (selectValue) {
      case WidgetListPageSelectorValue.selectCurrentPage:
        storeWidgetList.selectCurrentPageRows()
        break

      case WidgetListPageSelectorValue.selectAllPages:
        storeWidgetList.selectAllRows()
        break

      case WidgetListPageSelectorValue.unselectAllPages:
        storeWidgetList.unselectAllRows()
        break
    }
  }

/**
 * Select or unselect a row.
 */
export const onSelectPageCheckboxChange =
  (storeWidgetList: StoreWidgetList<any, any>) => (e: CheckboxChangeEvent) => {
    e.target.checked
      ? storeWidgetList.selectCurrentPageRows()
      : storeWidgetList.unselectAllRows()
  }

/**
 * Toggle the toggleable row.
 */
export const onActionIconToggleClick =
  (storeWidgetList: StoreWidgetList<any, any>) =>
  (row: IDataRowGeneric) =>
  (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    // since the row itself is clickable to open the toggleable row, stop propagation
    // to avoid to open/close at the same time.
    event.stopPropagation()

    storeWidgetList.toggleToggleableRow(row)
  }
