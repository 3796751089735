import {
  ContainerFlex,
  ContainerFlexVertical,
  ContainerRbac,
  ContainerTimeline
} from '@app/components-legacy/Container'
import { IconTadaaa } from '@app/components-legacy/Icon'
import { InputSwitch } from '@app/components-legacy/Input'
import { useAppTranslator } from '@app/hooks'
import type StoreGenericAlerts from '@app/stores/StoreAlerts/StoreGenericAlerts'
import * as React from 'react'
import { canReadArchivedAlerts } from '../../permissions'
import { onAlertShowArchivedChange } from './handlers'

interface IAlertContentTimelineProps {
  alertItems: React.ReactNodeArray
  storeGenericAlerts: StoreGenericAlerts<any>
}

const AlertContentTimeline: React.FC<IAlertContentTimelineProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Drawers.Alerts'] })

  const renderSwitch = () => {
    return (
      <ContainerRbac rbacCapabilityCheck={canReadArchivedAlerts}>
        <InputSwitch
          label={translate('Show archived')}
          labelledBy="showArchived"
          switchProps={{
            defaultChecked: props.storeGenericAlerts.showArchivedStatus,
            onChange: onAlertShowArchivedChange(props.storeGenericAlerts),
            loading: props.storeGenericAlerts.storeSwitchFlags.isLoading
          }}
        />
      </ContainerRbac>
    )
  }

  if (props.alertItems.length === 0) {
    return (
      <ContainerFlex
        name="NoAlerts"
        direction="column"
        items={[
          <ContainerFlex
            name="NoAlertsSwitch"
            justifyContent="flex-end"
            items={[renderSwitch()]}
          />,

          <ContainerFlexVertical
            items={[<IconTadaaa text={translate('No alerts')} />]}
          />
        ]}
        fullHeight
      />
    )
  }

  return (
    <ContainerTimeline
      itemLabels={[
        <ContainerFlex
          name="LatestAlerts"
          justifyContent="space-between"
          items={[<div>{translate('Latest alerts')}</div>, renderSwitch()]}
        />
      ]}
      items={[
        <ContainerFlex
          name="AlertsContentItems"
          direction="column"
          items={props.alertItems}
          spaced
          spaceWidth="small"
        />
      ]}
      timelineToBottom
    />
  )
}

export default AlertContentTimeline
