import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { canSeeInputInfrastructuresDrawer } from '@app/components-legacy/Input/InputInfrastructures/permissions'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreInfrastructures } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerContent from './DrawerContent'
import DrawerFooter from './DrawerFooter'
import DrawerHeader from './DrawerHeader'
import { useDrawerInputInfrastructuresInit } from './hooks'

interface IDrawerInputInfrastructuresProps {
  onSubmit?: () => void
  storeInfrastructures: StoreInfrastructures
}

const DrawerInputInfrastructures: React.FC<
  IDrawerInputInfrastructuresProps
> = props => {
  const storePendingInfrastructures = useDrawerInputInfrastructuresInit(
    props.storeInfrastructures
  )

  const translate = useAppTranslator({
    namespaces: ['Components.InputInfrastructures']
  })

  return (
    <DrawerHorizontalContainer
      labelledBy="inputInfrastructures"
      flags={storePendingInfrastructures.storeFlagsFetchInfrastructures.flags}
    >
      <BladeContentLayoutDefault
        layout={{
          name: 'default',
          title: <DrawerTitle>{translate('Forests and domains')}</DrawerTitle>,
          header: (
            <DrawerHeader storeInfrastructures={storePendingInfrastructures} />
          ),
          content: (
            <DrawerContent storeInfrastructures={storePendingInfrastructures} />
          ),
          footer: (
            <DrawerFooter
              onSubmit={props.onSubmit}
              storeInfrastructures={props.storeInfrastructures}
              storePendingInfrastructures={storePendingInfrastructures}
              storeDrawer={props.storeInfrastructures.storeDrawer}
            />
          )
        }}
        rbacCapabilityCheck={canSeeInputInfrastructuresDrawer}
      />
    </DrawerHorizontalContainer>
  )
}

export default observer(DrawerInputInfrastructures)
