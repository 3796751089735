import { ContainerSimple } from '@app/components/Container'
import type { IContainerSimpleProps } from '@app/components-legacy/Container/ContainerSimple'
import * as React from 'react'
import styled from 'styled-components'

interface ILabelNonBreakableProps extends IContainerSimpleProps {
  // display 3 dots when the oneline character is too long
  ellipsis?: boolean
}

const LabelNonBreakable: React.FC<ILabelNonBreakableProps> = props => {
  return (
    <ContainerSimple name="LabelNonBreakable" {...props} inline>
      {props.children}
    </ContainerSimple>
  )
}

export default styled(LabelNonBreakable)`
  white-space: nowrap;
  overflow: ${props => (props.ellipsis ? 'hidden' : 'initial')};
  text-overflow: ${props => (props.ellipsis ? 'ellipsis' : 'initial')};
`
