import { AppMenu } from '@app/components-legacy/Menu'
import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { filterFalsies } from '@libs/filterFalsies'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { EventDetailsPageContext } from '../contexts'
import { canSeeDeviances } from '../permissions'

interface IEventDetailsMenuProps {}

enum EventDetailsMenuKey {
  Attributes = 'Attributes',
  Deviances = 'Deviances'
}

const EventDetailsMenu: React.FC<IEventDetailsMenuProps> = () => {
  const translate = useAppTranslator({
    namespaces: [
      'TrailFlow.EventDetails.Menu',
      'TrailFlow.EventDetails.Attributes'
    ]
  })

  const parameters = React.useContext(EventDetailsPageContext)

  const { storeTrailFlow, storeRbac } = useStores()

  const storeMenu = useStoreMenuInit<IMenuEntry<EventDetailsMenuKey>>({
    menuEntries:
      parameters &&
      filterFalsies([
        {
          key: EventDetailsMenuKey.Attributes,
          label: translate('Attributes'),
          labelledBy: 'Attributes',
          routeDefinition: {
            routeName: AppRouteName.TrailFlow_EventDetails_Attributes,
            parameters
          }
        },

        // display only if deviances have been found for this event
        // and if granted to see deviances
        storeTrailFlow.storeEventDetails.hasDeviances &&
          storeRbac.isUserGrantedTo(canSeeDeviances) && {
            key: EventDetailsMenuKey.Deviances,
            label: translate('Deviances'),
            labelledBy: 'Deviances',
            routeDefinition: {
              routeName: AppRouteName.TrailFlow_EventDetails_Deviances,
              parameters
            }
          }
      ]),
    defaultSelectedMenuKey: EventDetailsMenuKey.Attributes
  })

  return <AppMenu storeMenu={storeMenu} />
}

export default observer(EventDetailsMenu)
