import { handleDrawerOnClose } from '@app/components-legacy/Drawer/handlers'
import { AppRouteName } from '@app/routes'
import type { StoreDashboards } from '@app/stores'
import { UserFormFieldName } from '@app/stores/Dashboard/types'
import type { AppRouterClient } from '@libs/Router/types'
import type { EditDashboardMutationArgs } from '@server/graphql/typeDefs/types'

/**
 * Form init at loading.
 */
export const onEditDashboardLoad = (storeDashboards: StoreDashboards) => () => {
  if (!storeDashboards.currentStoreDashboard) {
    return
  }

  const { dashboardEntity } = storeDashboards.currentStoreDashboard
  const { storeFormEditDashboard } = storeDashboards.storeDashboardsManagement

  storeFormEditDashboard
    .setDefaultFieldsValues([
      {
        key: UserFormFieldName.name,
        value: dashboardEntity.getPropertyAsString('name')
      },
      {
        key: UserFormFieldName.instanceName,
        value: dashboardEntity.getPropertyAsString('instanceName')
      }
    ])
    .reset()
}

/**
 * Update the dashboard.
 */
export const onEditDashboardValidate =
  (storeDashboards: StoreDashboards, appRouter: AppRouterClient) =>
  (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault()
    }

    const { storeDashboardsManagement } = storeDashboards
    const { storeFormEditDashboard } = storeDashboardsManagement

    if (!storeFormEditDashboard.validate()) {
      return
    }

    const parameters = appRouter.getRouteParameters({
      routeName: AppRouteName.Dashboard_Grid,
      parameters: {
        instanceName: String(),
        dashboardId: Number()
      }
    })

    if (!parameters) {
      return
    }

    const { instanceName, dashboardId } = parameters

    const name = storeFormEditDashboard.getFieldValueAsString('name')

    const args: EditDashboardMutationArgs = {
      dashboard: {
        instanceName,
        dashboardId,
        name
      }
    }

    return storeDashboardsManagement.editDashboard(args).then(() => {
      handleDrawerOnClose(
        storeDashboards.storeDashboardsManagement.storeDrawerEditDashboard
      )()

      storeFormEditDashboard.reset()

      return storeDashboards.fetchOneDashboard(instanceName, dashboardId)
    })
  }

/**
 * Open the drawer to edit the dashboard.
 */
export const onEditDashboardButtonClick =
  (storeDashboards: StoreDashboards) =>
  (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    storeDashboards.storeDashboardsManagement.storeDrawerEditDashboard.openDrawer()
  }
