import type { EntityProfile } from '@app/entities'
import { createEntity, EntityApiKey, EntityPreferences } from '@app/entities'
import {
  mandatory,
  userPasswordFormat
} from '@app/stores/helpers/StoreForm/validators'
import StoreModal from '@app/stores/helpers/StoreModal'
import { ForbiddenAccessError, UnauthorizedAccessError } from '@libs/errors'
import { isErrorOfType } from '@libs/errors/functions'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { ErrorName } from '@libs/errors/types'
import { checkRbac } from '@libs/rbac/functions'
import type { MutationRefreshApiKey } from '@server/graphql/mutations/apiKey'
import { mutationRefreshApiKey } from '@server/graphql/mutations/apiKey'
import type {
  MutationChangeUserPassword,
  MutationUpdatePreferences
} from '@server/graphql/mutations/preference'
import {
  mutationChangeUserPassword,
  mutationUpdatePreferences
} from '@server/graphql/mutations/preference'
import type { QueryApiKey } from '@server/graphql/queries/apiKey'
import { queryApiKey } from '@server/graphql/queries/apiKey'
import type { QueryPreferences } from '@server/graphql/queries/management'
import { queryPreferences } from '@server/graphql/queries/management'
import type {
  ApiKey,
  ChangeUserPasswordMutationArgs,
  InputChangeUserPassword,
  InputUpdatePreferences,
  Preferences,
  UpdatePreferencesMutationArgs
} from '@server/graphql/typeDefs/types'
import { AuthProviderName } from '@server/graphql/typeDefs/types'
import type Maybe from 'graphql/tsutils/Maybe'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '.'
import type { AuthenticationCredentials } from './Authentication/types'
import StoreFlags from './helpers/StoreFlags'
import StoreForm from './helpers/StoreForm'
import { equalTo } from './helpers/StoreForm/validators'
import StoreBase from './StoreBase'
import type { IStoreOptions } from './types'

export enum PreferenceFormFieldName {
  oldPassword = 'oldPassword',
  newPassword = 'newPassword',
  newPasswordConfirmation = 'newPasswordConfirmation'
}

export default class StorePreferences extends StoreBase {
  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: [
      'Errors.Form',
      'Preferences.Preferences',
      'Preferences.PersonalSettings.Security'
    ]
  })

  public storeFlags = new StoreFlags(this.storeRoot)
  public storeFlagsSecurity = new StoreFlags(this.storeRoot)
  public storeFlagsApiKey = new StoreFlags(this.storeRoot)

  public storeFormI18n = new StoreForm(this.storeRoot)
  public storeFormPreferredProfile = new StoreForm(this.storeRoot)
  public storeFormSecurity: StoreForm<PreferenceFormFieldName> =
    new StoreForm<PreferenceFormFieldName>(this.storeRoot, {
      setup: {
        fields: {
          oldPassword: {
            label: 'Old password',
            validators: [mandatory()]
          },
          newPassword: {
            label: 'New password',
            validators: [mandatory(), userPasswordFormat()]
          },
          newPasswordConfirmation: {
            label: 'Confirmation of the new password',
            validators: [
              mandatory(),
              equalTo(() =>
                this.storeFormSecurity.getFieldValueAsString(
                  PreferenceFormFieldName.newPassword
                )
              )({
                onError: () =>
                  this.translate('The password confirmation is incorrect')
              })
            ]
          }
        }
      }
    })

  public storeModalConfirmApiKeyRefresh = new StoreModal(this.storeRoot)

  /* Observables */

  private $preferences = observable.box<Maybe<EntityPreferences>>(null)
  private $apiKey = observable.box<Maybe<EntityApiKey>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch preferences and save results into an entity.
   */
  fetchPreferences(): Promise<any> {
    this.storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryPreferences>(queryPreferences)
      })
      .then(data => data.preferences)
      .then(preferences => {
        if (!preferences) {
          throw new Error('Preferences are not defined.')
        }

        const preferencesEntity = createEntity<Preferences, EntityPreferences>(
          EntityPreferences,
          preferences
        )

        this.setPreferences(preferencesEntity)

        this.storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlags))
  }

  /**
   * Update preferences.
   */
  updatePreferences(preferences: InputUpdatePreferences): Promise<any> {
    this.storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: UpdatePreferencesMutationArgs = {
          preferences
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationUpdatePreferences>(mutationUpdatePreferences, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Preferences updated'),
          {
            labelledBy: 'preferencesUpdated'
          }
        )

        this.storeFlags.success()
        this.storeFormSecurity.reset()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when editing the preferences'
        })
      )
  }

  /**
   * Change the password of the signed in user.
   */
  changeUserPassword(
    inputChangeUserPassword: InputChangeUserPassword
  ): Promise<any> {
    this.storeFlagsSecurity.loading()

    const whoAmI = this.storeRoot.stores.storeAuthentication.whoAmI
    const login = whoAmI && whoAmI.email

    const badPasswordMessage = this.translate('Your old password is incorrect')

    if (!login) {
      this.storeRoot.stores.storeMessages.error(badPasswordMessage, {
        labelledBy: 'badPassword'
      })
      return Promise.resolve()
    }

    const authCredentials: AuthenticationCredentials = {
      provider: AuthProviderName.Tenable,
      credentials: {
        login,
        password: inputChangeUserPassword.oldPassword
      }
    }

    return (
      Promise.resolve()
        // first, try to login with the filled old password to check if it is valid
        .then(() =>
          this.storeRoot.stores.storeAuthentication.tryToLogin(authCredentials)
        )
        .then(response => {
          if (response.status === 401) {
            throw new UnauthorizedAccessError(badPasswordMessage)
          }

          return response
        })
        // if login is successful, continue to change the password
        .then(() => {
          const args: ChangeUserPasswordMutationArgs = {
            password: inputChangeUserPassword
          }

          return this.storeRoot
            .getGQLRequestor()
            .query<MutationChangeUserPassword>(mutationChangeUserPassword, args)
        })
        .then(response => {
          if (response.changeUserPassword.error) {
            throw new UnauthorizedAccessError(
              this.translate('An error has occurred')
            )
          }

          this.storeRoot.stores.storeMessages.success(
            this.translate('Password changed'),
            {
              labelledBy: 'passwordChanged'
            }
          )

          this.storeFormSecurity.reset()
          this.storeFlagsSecurity.success()
        })
        .catch(err => {
          this.storeFlagsSecurity.stopLoading()

          if (
            isErrorOfType<UnauthorizedAccessError>(
              err,
              ErrorName.UnauthorizedAccessError
            )
          ) {
            this.storeRoot.stores.storeMessages.error(err.message, {
              labelledBy: 'changeUserPasswordError'
            })
            return
          }

          handleStoreError(this.storeRoot, null)(err)
        })
    )
  }

  /**
   * Fetch API key.
   */
  fetchApiKey() {
    this.storeFlagsApiKey.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot.getGQLRequestor().query<QueryApiKey>(queryApiKey)
      })
      .then(data => data.rbacApiKey)
      .then(apiKey => {
        if (!checkRbac(this.storeRoot, this.storeFlagsApiKey)(apiKey)) {
          throw new ForbiddenAccessError()
        }

        const apiKeyEntity = createEntity<ApiKey, EntityApiKey>(
          EntityApiKey,
          apiKey.node
        )

        this.setApiKey(apiKeyEntity)

        this.storeFlagsApiKey.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsApiKey))
  }

  /**
   * Refresh API key.
   */
  refreshApiKey() {
    this.storeFlagsApiKey.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<MutationRefreshApiKey>(mutationRefreshApiKey)
      })
      .then(data => data.refreshApiKey)
      .then(apiKey => {
        const apiKeyEntity = createEntity<ApiKey, EntityApiKey>(
          EntityApiKey,
          apiKey
        )

        this.setApiKey(apiKeyEntity)

        this.storeFlagsApiKey.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsApiKey))
  }

  /* Actions */

  /**
   * Save preferences.
   */
  @action
  setPreferences(preferencesEntity: EntityPreferences): void {
    this.$preferences.set(preferencesEntity)
  }

  @action
  setApiKey(apiKeyEntity: EntityApiKey): this {
    this.$apiKey.set(apiKeyEntity)
    return this
  }

  /* Computed */

  @computed
  get preferences(): Maybe<EntityPreferences> {
    return this.$preferences.get()
  }

  @computed
  get language() {
    const preferences = this.$preferences.get()

    if (!preferences) {
      return this.storeRoot.appTranslator.language
    }

    return preferences.language
  }

  @computed
  get preferredProfile(): Maybe<EntityProfile> {
    const preferences = this.$preferences.get()

    if (!preferences) {
      return this.storeRoot.stores.storeAuthentication.currentProfile
    }

    if (!preferences.preferredProfileId) {
      return
    }

    return this.storeRoot.stores.storeAuthentication.profiles.get(
      preferences.preferredProfileId
    )
  }

  @computed
  get apiKey(): Maybe<EntityApiKey> {
    return this.$apiKey.get()
  }
}
