import {
  ContainerFlex,
  ContainerHVCentered
} from '@app/components-legacy/Container'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { consts } from '@app/styles'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import type { DashboardWidgetType } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled, { css } from 'styled-components'

export interface IWidgetInputIllustrationContainerProps {
  className?: string
  type: DashboardWidgetType
  name: string
  selected: boolean
  onChange: (widgetType: DashboardWidgetType) => void
  labelledBy: string
  children?: React.ReactNode
}

const WidgetInputIllustrationContainer: React.FC<
  IWidgetInputIllustrationContainerProps
> = props => {
  const { testAttributeProps } = useTestAttribute('menu')

  const onChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    props.onChange(props.type)
  }

  return (
    <div
      className={props.className}
      onClick={onChange}
      {...testAttributeProps('menuitem')(props.labelledBy)}
    >
      <ContainerHVCentered>
        <ContainerFlex
          name="WidgetInputIllustrationContainer"
          direction="column"
          alignItems="center"
          justifyContent="center"
          items={[
            <div className="widgetIcon">{props.children}</div>,
            <div className="widgetName">{props.name}</div>
          ]}
        />
      </ContainerHVCentered>
    </div>
  )
}

export default styled(WidgetInputIllustrationContainer)`
  width: ${({ theme }) =>
    theme.tokens['dashboardWidget/width/widgetIllustration']};
  height: ${({ theme }) =>
    theme.tokens['dashboardWidget/width/widgetIllustration']};

  background-color: ${props =>
    props.selected
      ? props.theme.tokens[
          'dashboardWidget/backgroundColorSelected/widgetIllustration'
        ]
      : props.theme.tokens[
          'dashboardWidget/backgroundColor/widgetIllustration'
        ]};

  box-shadow: ${({ theme }) =>
    theme.tokens['dashboardWidget/boxShadow/widgetIllustration']};
  border: ${props =>
      props.selected
        ? props.theme.tokens[
            'dashboardWidget/borderSizeSelected/widgetIllustration'
          ]
        : props.theme.tokens['dashboardWidget/borderSize/widgetIllustration']}
    solid
    ${props =>
      props.selected
        ? props.theme.tokens[
            'dashboardWidget/borderColorSelected/widgetIllustration'
          ]
        : props.theme.tokens['dashboardWidget/borderColor/widgetIllustration']};
  border-radius: ${consts.containerBorderRadius};

  :hover {
    background-color: ${({ theme }) =>
      theme.tokens['dashboardWidget/backgroundColorHover/widgetIllustration']};
    border: 1px solid
      ${({ theme }) =>
        theme.tokens['dashboardWidget/borderColorHover/widgetIllustration']};
    cursor: pointer;
  }

  .widgetIcon {
    font-size: ${({ theme }) =>
      theme.tokens['dashboardWidget/fontSize/widgetIllustration']};
  }

  .widgetName {
    text-align: center;
    max-width: ${({ theme }) =>
      theme.tokens['dashboardWidget/width/widgetIllustration']};
    ${props =>
      !isThemeLegacy(props.theme) &&
      css`
        color: ${consts.FontColorV2.primary};
      `}
  }
`
