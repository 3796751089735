import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import { BrandColorV2, FontColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

const Color1Path = styled.path`
  stroke: ${FontColorV2.primary};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    stroke: ${consts.colorWhite};
  }
`

const Color2Path = styled.path`
  fill: ${BrandColorV2.decorativeGray};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${consts.colorFadedBlueNewDesign};
  }
`

interface IIconHealthCheckProps extends IIconProps {}

const IconHealthCheckLegacy: React.FC<IIconHealthCheckProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Color2Path d="M21.2996 2.8915C20.305 1.21924 18.7302 0.172282 16.9794 0.0193078C15.2894 -0.128317 13.6345 0.575861 12.451 1.95099L11.6373 2.89635L11.0465 2.21011C8.97599 -0.196284 5.71469 -0.644842 3.46396 1.16143C2.07246 2.27903 1.20913 3.95403 1.03256 5.87852C0.850315 7.85947 1.43908 9.80112 2.64882 11.2064L3.89801 12.6533L11.1467 19.7979C11.2842 19.9325 11.4602 20 11.6368 20C11.8133 20 11.9899 19.9325 12.1277 19.7969L19.3716 12.658C19.3855 12.6449 19.404 12.6256 19.4169 12.6103L20.4019 11.4658C22.4579 9.07786 22.835 5.47166 21.2993 2.89196L21.2996 2.8915Z" />
      <Color1Path
        d="M1 10.8333H6.32609L7.84783 5L10.8913 12.5L12.413 7.5L13.9348 10.8333H18.5"
        stroke-linecap="round"
      />
    </svg>
  )
}

export default IconHealthCheckLegacy
