import {
  IconCloseCircle,
  IconWarningTriangleFilled
} from '@app/components/Icon'
import type { IIconProps } from '@app/components/Icon/types'
import { useStores } from '@app/hooks'
import { HealthCheckStatusIcon } from '@app/pages/HealthCheck/HealthCheckStatusIcon'
import { HealthCheckStatus } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export interface IHealthCheckGlobalStatusIconProps extends IIconProps {}

function HealthCheckGlobalStatusIcon(props: IHealthCheckGlobalStatusIconProps) {
  const { storeHealthCheckGlobalStatus } = useStores()

  const { healthCheckGlobalStatus } = storeHealthCheckGlobalStatus

  if (!healthCheckGlobalStatus) {
    return (
      <StyledDiv className={props.className}>
        <HealthCheckStatusIcon status={HealthCheckStatus.Unknown} />
      </StyledDiv>
    )
  }

  if (healthCheckGlobalStatus.status === HealthCheckStatus.Failure) {
    return (
      <StyledDiv className={props.className}>
        <IconCloseCircle />
      </StyledDiv>
    )
  }

  if (healthCheckGlobalStatus.status === HealthCheckStatus.Warning) {
    return (
      <StyledDiv className={props.className}>
        <IconWarningTriangleFilled size={16} />
      </StyledDiv>
    )
  }

  return (
    <StyledDiv className={props.className}>
      <HealthCheckStatusIcon status={HealthCheckStatus.Success} />
    </StyledDiv>
  )
}

export default observer(HealthCheckGlobalStatusIcon)
