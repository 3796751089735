import { ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import FormWrapperCheckboxSimple from '@app/components-legacy/Form/Wrappers/FormWrapperCheckboxSimple'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { handleAddDashboardTemplate } from './handlers'
import type { DashboardTemplate } from './types'

const StyledContainerFlex = styled(ContainerFlex)`
  width: 100%;
  border-radius: ${consts.borderRadiusSmallRedesign};
  padding: ${consts.paddingLarge};
  background-color: ${consts.BackgroundColorV2.lightGray};
`

interface ITemplateWrapperProps {
  title: string
  className?: string
  style?: React.CSSProperties
  children: React.ReactNode
  templateType: DashboardTemplate
}

const TemplateWrapper: React.FC<ITemplateWrapperProps> = props => {
  const { storeDashboards } = useStores()

  return (
    <StyledContainerFlex
      name="AddDashboardTemplateWrapper"
      className={props.className}
      direction="column"
      items={[
        <ContainerFlex
          name="AddDashboardTemplateWrapperHeader"
          justifyContent="space-between"
          items={[
            <Label variant={LabelVariant.p_bold}>{props.title}</Label>,
            <FormWrapperCheckboxSimple
              labelledBy={`AddTemplate-${props.templateType}`}
              onChange={handleAddDashboardTemplate(
                storeDashboards,
                props.templateType
              )}
            />
          ]}
        />,

        <div>{props.children}</div>
      ]}
      flexGap="default"
    />
  )
}

export default TemplateWrapper
