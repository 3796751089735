import { ContainerContent } from '@app/components/Container'
import { CardSimple } from '@app/components-legacy/Card/CardSimple/CardSimple'
import { ContainerFooter } from '@app/components-legacy/Container'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { TelemetryFormFieldName } from '@app/stores/Management/StoreApplicationSettings/types'
import { consts } from '@app/styles'
import { buildVariants } from '@design-system/libs/buildVariants'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { onTelemetryConfigurationSubmit } from './handlers'
import { canSubmitTelemetryConfiguration } from './permissions'

const StyledFormWrapper = styled(FormWrapper)`
  background: none;
  padding: 0;
`

const StyledCardSimple = styled(CardSimple)(props => {
  return buildVariants(props)
    .css({
      maxWidth: consts.configurationCardsWidth
    })
    .end()
})

interface IConfigurationTelemetryFormProps {}

const ConfigurationLogsForm: React.FC<
  IConfigurationTelemetryFormProps
> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Configuration.Navigation',
      'Management.System.Configuration.Telemetry'
    ]
  })

  const { storeRbac, storeManagementApplicationSettings } = useStores()

  return (
    <StyledFormWrapper
      name="telemetryConfiguration"
      onSubmit={onTelemetryConfigurationSubmit(
        storeManagementApplicationSettings
      )}
    >
      <StyledCardSimple>
        <ContainerContent title={translate('Telemetry configuration')}>
          <WidgetForm<TelemetryFormFieldName>
            translate={translate}
            fieldsParams={{
              [TelemetryFormFieldName.telemetryEnabled]: {
                inputType: InputType.switch
              }
            }}
            storeForm={
              storeManagementApplicationSettings.storeFormTelemetryConfiguration
            }
          />
        </ContainerContent>

        <Portal name={PlaceHolderName.bladeFooter}>
          <ContainerFooter>
            <FormWrapperButtonSubmit
              loading={
                storeManagementApplicationSettings.storeFlagsAppSettingsSubmit
                  .flags.isLoading
              }
              disabled={
                !storeRbac.isUserGrantedTo(canSubmitTelemetryConfiguration)
              }
            >
              {translate('Save')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </StyledCardSimple>
    </StyledFormWrapper>
  )
}

export default observer(ConfigurationLogsForm)
