import { ContainerFlex } from '@app/components/Container'
import * as React from 'react'
import styled from 'styled-components'
import ConfigurationReportsActions from './ConfigurationReportsActions'
import ConfigurationReportsNoData from './ConfigurationReportsNoData'

export interface IConfigurationReportsFirstReportProps {}

const StyledContainerFlex = styled(ContainerFlex)`
  height: 100%;
`

export default function ConfigurationReportsFirstReport(
  props: IConfigurationReportsFirstReportProps
) {
  return (
    <StyledContainerFlex
      name="ConfigurationReportsPage"
      direction="column"
      itemsFlexGrow={[0, 1]}
      items={[<ConfigurationReportsActions />, <ConfigurationReportsNoData />]}
      flexGap="default"
    />
  )
}
