/**
 * Microsoft Entra ID Support Configuration
 */

import type { IGraphQLQuery } from '@libs/graphQL/types'
import type { Query } from '../typeDefs/types'

export type QueryAzureADSupportConfiguration = IGraphQLQuery<
  null,
  Pick<Query, 'azureADSupportConfiguration'>
>

export const queryAzureADSupportConfiguration = `
    query queryAzureADSupportConfiguration {
        azureADSupportConfiguration {
            azureADSupportEnabled
        }
    }
`
