import type { IGraphQLQuery } from '@libs/graphQL/types'
import type {
  DeleteRelayMutationArgs,
  EditRelayMutationArgs,
  Mutation,
  RenewRelayApiKeyMutationArgs
} from '../typeDefs/types'

export type MutationEditRelay = IGraphQLQuery<
  EditRelayMutationArgs,
  Pick<Mutation, 'editRelay'>
>

export const mutationEditRelay = `
  mutation mutationEditRelay($relay: InputEditRelay!) {
    editRelay(
      relay: $relay
    ) {
      id
      name
    }
  }
`

export type MutationDeleteRelay = IGraphQLQuery<
  DeleteRelayMutationArgs,
  Pick<Mutation, 'deleteRelay'>
>

export const mutationDeleteRelay = `
  mutation mutationDeleteRelay($relay: InputDeleteRelay!) {
    deleteRelay(
      relay: $relay
    )
  }
`

export type MutationRenewRelayApiKeys = IGraphQLQuery<
  RenewRelayApiKeyMutationArgs,
  Pick<Mutation, 'renewRelayApiKey'>
>

export const mutationRenewRelayApiKey = `
  mutation mutationRenewRelayApiKey($relay: InputRenewRelayApiKey!) {
    renewRelayApiKey(
      relay: $relay
    )
  }
`
