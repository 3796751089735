import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  readCheckers,
  readTopology,
  readUIIoEDeviances,
  readUITopology
} from '@libs/rbac/permissions'

export const canAccessToTopology = rbacCapabilityCheckAllOf(
  readUITopology(),
  readTopology()
)

export const canAccessDomainDetails = rbacCapabilityCheckAllOf(
  canAccessToTopology,
  readUIIoEDeviances(),
  readCheckers()
)
