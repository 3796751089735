import {
  ContainerFlex,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import Modal from '@app/components-legacy/Modal/Modal'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { SpinnerInlineSize } from '@app/components-legacy/Spinner/SpinnerInline'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

const SpinnerContainerFlex = styled(ContainerFlex)`
  padding: ${consts.paddingLarge};
`

interface IModalFeatureFlagRestartProps {}

const ModalFeatureFlagRestart: React.FC<
  IModalFeatureFlagRestartProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.ModalFeatureFlagRestart']
  })
  const { storeFeatureFlags } = useStores()

  return (
    <Modal
      modalProps={{
        title: translate('Your platform is restarting'),
        footer: null
      }}
      storeModal={storeFeatureFlags.storeModalFeatureFlagRestart}
    >
      <ContainerHTMLContent padded>
        {translate('Your environment is currently rebooting', {
          transformMarkdown: true
        })}
      </ContainerHTMLContent>
      <SpinnerContainerFlex
        name="ModalFeatureToggleRestartSpinner"
        justifyContent="center"
        items={[<SpinnerInline size={SpinnerInlineSize.small} />]}
      />
    </Modal>
  )
}

export default ModalFeatureFlagRestart
