import { ContainerFlex } from '@app/components/Container'
import type { IIconProps } from '@app/components/Icon/types'
import { Label, LabelSliced } from '@app/components/Labels'
import { useDataFocusMethodAttribute, useTestAttribute } from '@app/hooks'
import { buildVariants } from '@design-system/libs/buildVariants'
import { Tooltip } from 'antd'
import classnames from 'classnames'
import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { LabelVariant } from '../Labels/types'

const StyledContainerFlex = styled(ContainerFlex)`
  min-width: ${props =>
    props.theme.tokens['mainMenu/menuEntry/width/expandedWithoutPadding']};
`

const StyledLabel = styled(Label)`
  min-width: 0;
`

interface IMenuLinkProps {
  className?: string
  labelledBy?: string
  icon?: React.FC<IIconProps>
  icon2?: React.FC<IIconProps>
  label: string
  label2?: string | React.FC
  conditioningIcon2Component?: React.FC<{ children: React.ReactNode }>
  to: string
  onClick: () => void
  expanded?: boolean
  active?: boolean
  gradientColor: string
  variant?: 'legacy' | 'current' // To remove when the toggle kapteyn-left-navigation-ui is deleted
  externalHref?: string
}

const MenuLink: React.FC<IMenuLinkProps> = props => {
  const dataFocusMethodAttributeProps =
    useDataFocusMethodAttribute<HTMLAnchorElement>()

  const { testAttributeProps } = useTestAttribute('menu')

  const Icon = props.icon
  const Icon2 = props.icon2

  function getLabel2(label2: string | React.FC) {
    if (typeof label2 === 'string') {
      return label2
    }
    const Label = label2
    return <Label />
  }

  function renderSecondLine() {
    if (!props.icon2 || !props.label2) {
      return null
    }

    const component = (
      <ContainerFlex
        name="MenuLinkLine2"
        alignItems="center"
        itemsFlexShrink={[0, 1]}
        items={[
          Icon2 ? <Icon2 /> : null,
          <Label color="currentColor">{getLabel2(props.label2)}</Label>
        ]}
        flexGap="small"
      />
    )

    if (props.conditioningIcon2Component) {
      const ConditioningIcon2Component = props.conditioningIcon2Component

      return (
        <ConditioningIcon2Component>{component}</ConditioningIcon2Component>
      )
    }

    return component
  }

  function renderContent() {
    const content = (
      <StyledContainerFlex
        name="MenuLink"
        direction="column"
        items={[
          <ContainerFlex
            name="MenuLinkLine1"
            alignItems="center"
            itemsFlexShrink={[0, 1]}
            items={[
              Icon ? <Icon /> : null,
              <StyledLabel
                color="currentColor"
                variant={
                  props.variant === 'current'
                    ? LabelVariant.menuLink
                    : LabelVariant.legacyMenuLink
                }
              >
                <LabelSliced value={props.label} maxWidthAuto />
              </StyledLabel>
            ]}
            flexGap="small"
          />,
          renderSecondLine()
        ]}
        flexGap="small"
      />
    )

    if (props.externalHref) {
      return (
        <a
          className={classnames(props.className, { active: props.active })}
          href={props.externalHref}
          target="_blank"
          rel="noopener noreferrer"
          {...dataFocusMethodAttributeProps}
          {...testAttributeProps('menuitem')(props.labelledBy)}
        >
          {content}
        </a>
      )
    }

    return (
      <Link
        className={classnames(props.className, { active: props.active })}
        to={props.to}
        onClick={props.onClick}
        {...dataFocusMethodAttributeProps}
        {...testAttributeProps('menuitem')(props.labelledBy)}
      >
        {content}
      </Link>
    )
  }

  return (
    <Tooltip
      title={
        props.label2 ? (
          <>
            {props.label} {getLabel2(props.label2)}
          </>
        ) : (
          props.label
        )
      }
      placement="right"
      overlayClassName={props.expanded ? 'ant-tooltip-hidden' : ''}
    >
      {renderContent()}
    </Tooltip>
  )
}

export default styled(MenuLink)(props => {
  return buildVariants(props)
    .css({
      display: 'flex',
      overflow: 'hidden',
      transition: 'width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)',
      whiteSpace: 'nowrap',
      svg: {
        flexShrink: 0
      },
      padding: props.theme.tokens['mainMenu/link/padding/default'],
      width: props.expanded
        ? props.theme.tokens['mainMenu/menuEntry/width/expanded']
        : props.theme.tokens['mainMenu/menuEntry/width/default'],
      borderRadius: props.theme.tokens['mainMenu/link/border-radius'],
      backgroundColor: props.theme.tokens['mainMenu/link/color/background'],
      color: props.theme.tokens['mainMenu/link/color/text'],

      '&.active': {
        backgroundColor:
          props.theme.tokens['mainMenu/link/color/backgroundActive'],
        color: props.theme.tokens['mainMenu/link/color/textActive'],
        textDecoration: 'none'
      },
      "&:hover, :focus:not([data-focus-method='mouse'])": {
        backgroundColor:
          props.theme.tokens['mainMenu/link/color/backgroundHover'],
        color: props.theme.tokens['mainMenu/link/color/textHover'],
        textDecoration: 'none'
      },
      "&.active span, &:hover span, :focus:not([data-focus-method='mouse']) span":
        {
          color: props.theme.tokens['mainMenu/link/color/textHover']
        }
    })
    .variant('variant', props.variant ?? 'legacy', {
      legacy: {
        "&.active, :hover, :focus:not([data-focus-method='mouse'])": {
          backgroundColor: props.gradientColor
        }
      },
      current: {
        transition:
          'width 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0.1s ease'
      }
    })
    .end()
})
