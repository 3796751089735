import { CheckerType } from '@server/graphql/typeDefs/types'
import { EntityChecker } from '..'
import EntityCheckerIdentity from './EntityCheckerIdentity'
import type { CheckerTypeUnion } from './types'

/**
 * Typeguard function that asserts that checker is an EntityChecker instance
 * for an exposure checker.
 */
export function isEntityCheckerExposure(checker: {
  type: CheckerTypeUnion
}): checker is EntityChecker {
  return (
    checker instanceof EntityChecker && checker.type === CheckerType.Exposure
  )
}

/**
 * Typeguard function that asserts that checker is an EntityCheckerIdentity instance
 * from T.ID add-on.
 */
export function isEntityCheckerIdentity(checker: {
  type: CheckerTypeUnion
}): checker is EntityCheckerIdentity {
  return checker instanceof EntityCheckerIdentity && checker.type === 'identity'
}
