import ButtonCopyToClipboard from '@app/components-legacy/Button/ButtonCopyToClipboard'
import { ContainerFlex } from '@app/components-legacy/Container'
import { Input } from 'antd'
import type { InputProps } from 'antd/lib/input'
import * as React from 'react'
import styled from 'styled-components'

export interface IInputWithClipboardCopyProps extends InputProps {}

const InputWithClipboardCopy: React.FC<
  IInputWithClipboardCopyProps
> = props => {
  const inputRef = React.useRef(null)

  return (
    <ContainerFlex
      className={props.className}
      name="InputWithClipboardCopy"
      items={[
        <Input ref={inputRef} {...props} />,
        <ButtonCopyToClipboard refObject={inputRef} />
      ]}
    />
  )
}

export default styled(InputWithClipboardCopy)`
  & > :not(:last-child) {
    margin-right: -1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > :not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  & > :hover,
  & > :focus {
    z-index: 1;
  }
`
