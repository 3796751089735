import Environment from '@libs/Environment/Environment'
import type { ISessionParams } from '@libs/Environment/types'
import { FetchClient } from '@libs/FetchClient'
import { createGraphQLClient } from '@libs/graphQL'
import { IGraphQLClientHeaders } from '@libs/graphQL/types'
import { filterNullOrUndefinedValues } from '@libs/helpers/objects/filterNullOrUndefinedValues'
import { languageToAcceptLanguage } from '@libs/httpHeaders'
import { initTranslator } from '@libs/i18n/initTranslator'
import { setMomentLocale, setupMomentCalendarFormat } from '@libs/i18n/moment'
import { getLogger, setLogger } from '@libs/logger'
import { OpenApiClients } from '@libs/openapi-clients'
import { sanitize } from '@libs/sanitize'
import WSClient from '@libs/WSClient'
import type { Maybe } from '@productive-codebases/toolbox'
import type { GraphQLClient } from 'graphql-request'
import * as fetch from 'isomorphic-fetch'
import * as logger from 'loglevel'
import { getConfigFromDOM } from './getConfigFromDOM'

/**
 * Setup the environment.
 */
export function setupEnvironment(parameters?: {
  csrfToken: Maybe<string>
}): Promise<Environment> {
  const config = getConfigFromDOM()

  // save the new logger
  logger.setLevel(config.log.level)
  setLogger(logger)

  const sessionParams: ISessionParams = {
    csrfToken: parameters?.csrfToken ?? null
  }

  const fetchClient = new FetchClient(fetch, {})

  return initTranslator(fetchClient, config.i18n).then(translator => {
    const acceptLanguage = translator
      .getPreferredLanguages()
      .map(languageToAcceptLanguage)
      .join(',')

    const preferredLanguage = translator.getFirstPreferredLanguage()

    const acceptLanguageHeader = {
      'Accept-Language': acceptLanguage
    }

    // set moment locales for calendars
    setupMomentCalendarFormat(translator)

    // set the correct locale to Moment globally to the full client app
    setMomentLocale(preferredLanguage)

    // set the language header for all future calls
    fetchClient.setHeaders({
      'Accept-Language': acceptLanguage
    })

    // instanciate one graphQL client by Tenable.ad instance
    const graphQLClients: Map<string, GraphQLClient> = new Map()

    config.instances.forEach(instance => {
      graphQLClients.set(
        instance.name,
        createGraphQLClient({
          baseUrl: '',
          instanceName: sanitize(instance.name),
          clientRequestOptions: {
            headers: filterNullOrUndefinedValues({
              ...acceptLanguageHeader,
              [IGraphQLClientHeaders.csrfToken]:
                instance.csrfToken ?? sessionParams.csrfToken
            })
          }
        })
      )
    })

    const isSecure = window.location.protocol === 'https:' ? true : false
    const wsProtocol = isSecure ? 'wss://' : 'ws://'
    const wsClient = new WSClient(
      `${wsProtocol}${document.location.host}`,
      getLogger('WSClient')
    )

    const openApiClient = new OpenApiClients()
      .setHeaders(acceptLanguageHeader)
      .createClients()

    return new Environment(
      config,
      sessionParams,
      logger,
      fetchClient,
      graphQLClients,
      wsClient,
      translator,
      openApiClient,
      localStorage
    )
  })
}
