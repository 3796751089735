import { LabelColorTag } from '@app/components-legacy/Label'
import type { StoreInputReasons } from '@app/stores'
import * as React from 'react'
import styled from 'styled-components'

const StyledLabelColorTag = styled(LabelColorTag)`
  margin: 1px 2px;
`

interface IDeviancesReasonTagsProps {
  reasonIds: number[]
  storeInputReasons: StoreInputReasons
}

const DeviancesReasonTags: React.FC<IDeviancesReasonTagsProps> = props => {
  const devianceReasons = props.storeInputReasons.getDeviancesReasons(
    props.reasonIds
  )

  return (
    <div>
      {devianceReasons.map(entityReason => {
        return (
          <StyledLabelColorTag
            key={entityReason.getPropertyAsString('name')}
            color={entityReason.getPropertyAsString('color')}
            label={entityReason.getPropertyAsString('name')}
          />
        )
      })}
    </div>
  )
}

export default DeviancesReasonTags
