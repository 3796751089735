import type { EntityUserLog } from '@app/entities'
import type Maybe from 'graphql/tsutils/Maybe'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '..'
import StoreDrawer from '../helpers/StoreDrawer'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'

/**
 * Store in charge of getting trace drawer and payload data
 */

export default class StoreActivityLogPayload extends StoreBase {
  /* Flags */
  public storeDrawerPayload = new StoreDrawer(this.storeRoot)

  /* Observables */
  private $trace = observable.box<Maybe<EntityUserLog>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /* Actions */

  /**
   * Reset store
   */
  @action
  reset(): this {
    this.storeDrawerPayload.reset()

    return this
  }

  @action
  setTrace(log: EntityUserLog): this {
    this.$trace.set(log)
    return this
  }

  @computed
  get trace(): Maybe<EntityUserLog> {
    return this.$trace.get()
  }
}
