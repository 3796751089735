import { ensureArray } from '@libs/ensureArray'
import { isSDDLTree } from '@libs/sddl/isSDDLTree'
import type { ISddlTree } from '@libs/sddl/types'
import { ACETitle } from '@libs/sddl/types'
import { get, isString } from 'lodash'

export function getTypeDefinitions(sddlTree: ISddlTree): string[] {
  const tree = ensureArray(sddlTree.details).find(
    x => isSDDLTree(x) && x.title === ACETitle.Type
  )

  return (get(tree, 'details.0.details', []) as ISddlTree['details']).filter(
    isString
  )
}

export function getAccountSidDefinitions(sddlTree: ISddlTree): string[] {
  const tree = ensureArray(sddlTree.details).find(
    x => isSDDLTree(x) && x.title === ACETitle.AccountSid
  )

  return (get(tree, 'details', []) as ISddlTree['details']).filter(isString)
}

export function getRightsDefinitions(sddlTree: ISddlTree): string[] {
  const tree = ensureArray(sddlTree.details).find(
    x => isSDDLTree(x) && x.title === ACETitle.Rights
  )

  // FIXME - Typings are wrong here...
  return (get(tree, 'details', []) as ISddlTree['details'])
    .map(x => get(x, 'details.0', null))
    .filter(isString) as unknown as string[]
}

export function getGuidDefinitions(sddlTree: ISddlTree): string[] {
  const tree = ensureArray(sddlTree.details).find(
    x => isSDDLTree(x) && x.title === ACETitle.ObjectGUID
  )

  return (get(tree, 'details', []) as ISddlTree['details']).filter(isString)
}
