import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  authenticated,
  hasNotApprovedEula,
  readLicense,
  updateLicense,
  validLicense
} from '@libs/rbac/permissions'

export const canReadLicenseDuringLogin = rbacCapabilityCheckAllOf(readLicense())

export const canUpdateLicenseDuringLogin =
  rbacCapabilityCheckAllOf(updateLicense())

export const canAccessToLicensePage = rbacCapabilityCheckAllOf(authenticated())

export const canValidateEULA = rbacCapabilityCheckAllOf(
  validLicense(),
  hasNotApprovedEula()
)
