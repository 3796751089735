import { ContainerFlex, ContainerIcon } from '@app/components-legacy/Container'
import {
  IconMinusCircleOutlined,
  IconPlusCircleOutlined
} from '@app/components-legacy/Icon/IconAntd'
import type StoreProfileCheckerOptionArrayObject from '@app/stores/Management/StoreProfiles/StoreProfileCheckerOptionOMCGMAW'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import {
  onAddNewConfigurationClick,
  onRemoveConfigurationClick
} from './handlers'

interface IGroupConfigurationTitleProps {
  className?: string
  index: number
  storeProfileCheckerOptionArrayObject: StoreProfileCheckerOptionArrayObject
}

const GroupConfigurationTitle: React.FC<
  IGroupConfigurationTitleProps
> = props => {
  return (
    <ContainerFlex
      name={GroupConfigurationTitle.name}
      className={props.className}
      justifyContent="space-between"
      alignItems="center"
      items={[
        <div>#{props.index + 1}</div>,

        <ContainerFlex
          name={`${GroupConfigurationTitle.name}Buttons`}
          alignItems="center"
          items={[
            <ContainerIcon
              labelledBy="removeConfiguration"
              iconComponent={IconMinusCircleOutlined}
              iconProps={{
                onClick: onRemoveConfigurationClick(
                  props.storeProfileCheckerOptionArrayObject
                )(props.index)
              }}
            />,
            <ContainerIcon
              labelledBy="addNewConfiguration"
              iconComponent={IconPlusCircleOutlined}
              iconProps={{
                onClick: onAddNewConfigurationClick(
                  props.storeProfileCheckerOptionArrayObject
                )
              }}
            />
          ]}
        />
      ]}
    />
  )
}

export default styled(GroupConfigurationTitle)`
  background: ${consts.colorBlueGray040};
  padding: ${consts.paddingVerySmall} ${consts.paddingDefault};
`
