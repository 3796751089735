import type { IContainerSimpleProps } from '@app/components-legacy/Container/ContainerSimple'

export interface IFormWrapperControl extends IContainerSimpleProps {
  labelledBy: string
  pendoSuffix?: string
}

export enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  colored = 'colored',
  delete = 'delete',
  select = 'select',
  transparent = 'transparent'
}

export enum ButtonSize {
  verySmall = 'verySmall',
  small = 'small',
  large = 'large'
}
