import { ContainerFlex, ContainerRbac } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import {
  InputExpression,
  InputInfrastructures,
  InputReasons,
  InputSwitch
} from '@app/components-legacy/Input'
import InputDatePickerRange from '@app/components-legacy/Input/InputDatePickerRange'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { CheckerDetailsPageContext } from '../../context'
import { canSetIgnoreDateOnDeviantObjects } from '../../permissions'
import {
  onDeviantObjectsSearchSubmit,
  onDeviantObjectsShowIgnoredChange,
  refreshDeviantObjects
} from '../handlers'

interface IDeviantObjectsFiltersProps {}

const ContainerFlexCustom = styled(ContainerFlex)`
  & > div:first-child {
    width: 30%;
  }
`

const InputDatePickerRangeCustom = styled(InputDatePickerRange)`
  width: 230px;
`

const DeviantObjectsFilters: React.FC<IDeviantObjectsFiltersProps> = () => {
  const parameters = React.useContext(CheckerDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoE.Details.DeviantObjects']
  })

  const { storeIoE } = useStores()

  if (!parameters) {
    return null
  }

  const { storeIndicatorDeviantObjects } = storeIoE.storeIndicator

  return (
    <ContainerFlex
      name="DeviantObjectsTableFilters"
      direction="column"
      items={[
        <ContainerFlexCustom
          name="DeviantObjectsTableFilters1"
          alignItems="center"
          justifyContent="space-between"
          items={[
            <InputExpression
              onValidate={onDeviantObjectsSearchSubmit(storeIoE)(
                parameters.checkerCodename
              )}
              onClearIconClick={refreshDeviantObjects(storeIoE)(
                parameters.checkerCodename
              )}
              storeInputExpression={
                storeIndicatorDeviantObjects.storeInputExpression
              }
            />,

            <InputDatePickerRangeCustom
              storeDatePicker={storeIndicatorDeviantObjects.storeDatePicker}
            />,

            <InputInfrastructures
              storeInfrastructures={
                storeIoE.storeIndicator.storeIndicatorDeviantObjects
                  .storeInfrastructures
              }
              onValidate={refreshDeviantObjects(storeIoE)(
                parameters.checkerCodename
              )}
            />,

            <InputReasons
              storeInputReasons={
                storeIoE.storeIndicator.storeIndicatorDeviantObjects
                  .storeInputReasons
              }
              onValidate={refreshDeviantObjects(storeIoE)(
                parameters.checkerCodename
              )}
            />,

            <ContainerRbac
              rbacCapabilityCheck={canSetIgnoreDateOnDeviantObjects}
            >
              <InputSwitch
                label={translate('Show ignored')}
                labelledBy="showIgnored"
                switchProps={{
                  defaultChecked:
                    storeIndicatorDeviantObjects.showIgnoredStatus,
                  onChange: onDeviantObjectsShowIgnoredChange(storeIoE)(
                    parameters.checkerCodename
                  )
                }}
              />
            </ContainerRbac>,

            <FormWrapperButton
              labelledBy="search"
              buttonProps={{
                type: 'primary',
                onClick: refreshDeviantObjects(storeIoE)(
                  parameters.checkerCodename
                )
              }}
            >
              {translate('Search')}
            </FormWrapperButton>
          ]}
          spaced
          spaceWidth="small"
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(DeviantObjectsFilters)
