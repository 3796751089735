import type { Maybe } from '@@types/helpers'
import type { Features } from '@alsid/common'
import type { StoreRbac } from '@app/stores'
import type { IRbacCapability } from './types'

/**
 * Return a grant capability.
 */
export const grantCapability = (
  message: Maybe<string> = null
): IRbacCapability => {
  return {
    isGranted: true,
    message
  }
}

/**
 * Return a deny capability.
 */
export const denyCapability = (
  message: Maybe<string> = null
): IRbacCapability => {
  return {
    isGranted: false,
    message
  }
}

/**
 * Return a capability according to the toggle value.
 */
export const toggleCapability =
  (storeRbac: StoreRbac) =>
  (featureFlagName: Features) =>
  (message: Maybe<string> = null): IRbacCapability => {
    return storeRbac.isUserGrantedAccordingFeatureFlag(featureFlagName)
      ? grantCapability(message)
      : denyCapability(message)
  }
