import { ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import * as React from 'react'

interface IHealthCheckDetailsSectionProps {
  title: string
  content: React.ReactNode
}

const HealthCheckDetailsSection: React.FC<
  IHealthCheckDetailsSectionProps
> = props => {
  return (
    <ContainerFlex
      name="HealthCheckDetailsSection"
      direction="column"
      items={[
        <Label variant={LabelVariant.h2}>{props.title}</Label>,
        props.content
      ]}
      spaced
    />
  )
}

export default HealthCheckDetailsSection
