import { createEntities } from '@app/entities'
import EntityCredential from '@app/entities/EntityCredential'
import type { IDataRowTenantSetting } from '@app/entities/EntityTenantSetting'
import EntityTenantSetting from '@app/entities/EntityTenantSetting'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { mandatory } from '@app/stores/helpers/StoreForm/validators'
import { handleStoreError } from '@libs/errors/handleStoreError'
import type {
  CreateTenantSetting,
  CreateTenantSettingRequest,
  Credential,
  DeleteTenantSettingRequest,
  TenantSetting,
  UpdateTenantSetting,
  UpdateTenantSettingRequest
} from '@libs/openapi/service-identity-core'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '..'
import StoreDrawer from '../helpers/StoreDrawer'
import StoreFlags from '../helpers/StoreFlags'
import StoreForm from '../helpers/StoreForm'
import StoreWidgetList from '../helpers/StoreWidgetList'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'

export enum TenantsFormFieldName {
  name = 'name',
  credentialUUID = 'credentialUUID'
}

export default class StoreTenants extends StoreBase {
  public storeFlagsFetchTenants = new StoreFlags(this.storeRoot)

  public storeFlagsCreateTenant = new StoreFlags(this.storeRoot)
  public storeFlagsEditTenant = new StoreFlags(this.storeRoot)
  public storeFlagsToggleTenantStatus = new StoreFlags(this.storeRoot)
  public storeFlagsDeleteTenant = new StoreFlags(this.storeRoot)

  public storeFlagsFetchCredentials = new StoreFlags(this.storeRoot)
  public storeFlagsReloadCredentials = new StoreFlags(this.storeRoot)

  public storeWidgetList = new StoreWidgetList<
    EntityTenantSetting,
    IDataRowTenantSetting
  >(this.storeRoot, {
    selectable: false
  })

  public storeFormCreation = new StoreForm<TenantsFormFieldName>(
    this.storeRoot,
    {
      setup: {
        fields: {
          [TenantsFormFieldName.name]: {
            label: 'Name of the tenant',
            validators: [mandatory()]
          },
          [TenantsFormFieldName.credentialUUID]: {
            label: 'Credential',
            validators: [mandatory()],
            inputType: InputType.select
          }
        }
      }
    }
  )

  public storeFormEdition = new StoreForm<TenantsFormFieldName>(
    this.storeRoot,
    {
      setup: {
        fields: {
          [TenantsFormFieldName.name]: {
            label: 'Name',
            description: 'Name of the tenant',
            validators: [mandatory()]
          },
          [TenantsFormFieldName.credentialUUID]: {
            label: 'Credential',
            validators: [mandatory()],
            inputType: InputType.select
          }
        }
      }
    }
  )

  public storeDeleteDrawer = new StoreDrawer<{
    tenantDataRow: IDataRowTenantSetting
  }>(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Errors', 'Management.System.Tenants']
  })

  /* Observables */

  // keys are tenant id
  private $tenants = observable.map<string, EntityTenantSetting>()
  private $credentials = observable.map<string, EntityCredential>()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch tenants.
   */
  fetchTenants(storeFlags = this.storeFlagsFetchTenants): void {
    if (
      !this.storeRoot.stores.storeManagementAzureAD
        .isTenableCloudApiTokensWorking
    ) {
      return
    }

    storeFlags.loading()

    this.storeRoot.environment.openApiClients.serviceIdentityCore.settings
      .listTenantSettings()
      .then(tenants => {
        if (!tenants) {
          this.storeRoot.logger.error('Unable to retrieve tenants settings')
        }

        const identitiesEntities = createEntities<
          TenantSetting,
          EntityTenantSetting
        >(EntityTenantSetting, tenants)

        this.setTenants(identitiesEntities)
        this.storeWidgetList.setEntities(identitiesEntities)

        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Fetch credentials.
   */
  fetchCredentials(storeFlags = this.storeFlagsFetchCredentials) {
    storeFlags.loading()

    return this.storeRoot.environment.openApiClients.serviceIdentityCore.settings
      .listCredentials()
      .then(credentials => {
        if (!credentials) {
          this.storeRoot.logger.error('Unable to retrieve credentials')
        }

        const identitiesEntities = createEntities<Credential, EntityCredential>(
          EntityCredential,
          credentials
        )

        this.setCredentials(identitiesEntities)

        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Reload credentials.
   */
  reloadCredentials() {
    return this.fetchCredentials(this.storeFlagsReloadCredentials)
  }

  /**
   * Create a tenant.
   */
  createTenant(createTenantSetting: CreateTenantSetting) {
    this.storeFlagsCreateTenant.loading()

    const request: CreateTenantSettingRequest = {
      createTenantSetting: createTenantSetting
    }

    return this.storeRoot.environment.openApiClients.serviceIdentityCore.settings
      .createTenantSetting(request)
      .then(() => {
        this.storeFlagsCreateTenant.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsCreateTenant))
  }

  /**
   * Edit a tenant.
   */
  editTenant(
    tenantSettingId: string,
    updateTenantSetting: UpdateTenantSetting,
    storeFlags = this.storeFlagsEditTenant
  ) {
    storeFlags.loading()

    const request: UpdateTenantSettingRequest = {
      updateTenantSetting: updateTenantSetting,
      tenantSettingId
    }

    return this.storeRoot.environment.openApiClients.serviceIdentityCore.settings
      .updateTenantSetting(request)
      .then(() => {
        storeFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, storeFlags, {
          // forward err to catch errors related to credentials provider type
          forwardExceptionFn: () => true
        })
      )
  }

  /**
   * Enabled or disable a tenant.
   */
  toggleTenantStatus(
    tenantSettingId: string,
    updateTenantSetting: UpdateTenantSetting
  ) {
    return this.editTenant(
      tenantSettingId,
      updateTenantSetting,
      this.storeFlagsToggleTenantStatus
    ).catch(
      handleStoreError(this.storeRoot, this.storeFlagsToggleTenantStatus, {
        // forward err to avoid having the success message in the handler
        forwardExceptionFn: () => true
      })
    )
  }

  /**
   * Delete a tenant.
   */
  deleteTenant(tenantSettingId: string) {
    this.storeFlagsDeleteTenant.loading()

    const request: DeleteTenantSettingRequest = {
      tenantSettingId
    }

    return this.storeRoot.environment.openApiClients.serviceIdentityCore.settings
      .deleteTenantSetting(request)
      .then(() => {
        this.storeFlagsDeleteTenant.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsDeleteTenant))
  }

  /**
   * Actions
   */

  @action
  setTenants(tenants: EntityTenantSetting[]) {
    this.$tenants.clear()

    tenants.forEach(entity => {
      if (entity.uuid) {
        this.$tenants.set(entity.uuid, entity)
      }
    })
  }

  @action
  setCredentials(credentials: EntityCredential[]) {
    this.$credentials.clear()

    credentials.forEach(entity => {
      if (entity.uuid) {
        this.$credentials.set(entity.uuid, entity)
      }
    })
  }

  /* Computed */

  @computed
  get tenants() {
    return this.$tenants
  }

  @computed
  get credentials() {
    return this.$credentials
  }
}
