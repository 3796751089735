import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { LabelBreakable } from '..'

interface ILabelSlicedContainerPopoverContentProps {
  className?: string
  children?: React.ReactNode
}

/**
 * Container of Popover content that set a minimal width/height and wrap content
 * by default.
 */
const LabelSlicedContainerPopoverContent: React.FC<
  ILabelSlicedContainerPopoverContentProps
> = props => {
  return (
    <LabelBreakable className={props.className}>
      {props.children}
    </LabelBreakable>
  )
}

/** @deprecated */
export default styled(LabelSlicedContainerPopoverContent)`
  width: ${consts.tooltipDefaultWidth};
  min-height: 100px;
`
