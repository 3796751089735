import { IconRightArrow } from '@app/components/Icon'
import {
  ContainerFlex,
  ContainerFooter,
  ContainerRbac
} from '@app/components-legacy/Container'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { canAccessToIoASetupDownloadGpo } from '../permissions'
import {
  handleCancelEditsButtonClick,
  handleSaveIoAConfigurationButtonClick
} from './handlers'
import ModalConfirmSaveConfiguration from './ModalConfirmSaveConfiguration'

interface IConfigurationAttackTypeFooterProps {}

const StyledContainerFooter = styled(ContainerFooter)`
  @media only screen and (max-width: ${consts.breakpointMediumViewPort}px) {
    display: inline-block;
  }
`

const ConfigurationAttackTypeFooter: React.FC<
  IConfigurationAttackTypeFooterProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Errors', 'Management.System.Configuration.IoA']
  })

  const {
    storeManagementAttackTypeConfiguration,
    storeManagementApplicationSettings
  } = useStores()

  if (!storeManagementAttackTypeConfiguration.attackTypeConfiguration) {
    return null
  }

  const isIoAAutomaticConfiguration =
    storeManagementApplicationSettings.applicationSettings
      ?.ioaAutomaticConfiguration || false

  const {
    permissions: { canEdit }
  } = storeManagementAttackTypeConfiguration.attackTypeConfiguration

  const saveConfigurationTooltipTitle = canEdit
    ? ''
    : translate(
        'You do not have the required permissions to perform this action'
      )

  const hasSelectionChanged =
    storeManagementAttackTypeConfiguration.isConfigurationDirty &&
    !storeManagementAttackTypeConfiguration.hasSameSelectedEntries

  const hasSomeAttacksSelected =
    storeManagementAttackTypeConfiguration.hasSomeAttacksSelected()

  return (
    <>
      <StyledContainerFooter>
        <ContainerFlex
          name="ConfigurationAttackTypeFooterRight"
          alignItems="center"
          items={[
            <ContainerRbac rbacCapabilityCheck={canAccessToIoASetupDownloadGpo}>
              <FormWrapperButton
                labelledBy="cancelIoaConfigurationEdits"
                variant={ButtonVariant.secondary}
                size={ButtonSize.large}
                buttonProps={{
                  disabled: !hasSelectionChanged,
                  onClick: handleCancelEditsButtonClick(
                    storeManagementAttackTypeConfiguration
                  )
                }}
              >
                {translate('Cancel edits')}
              </FormWrapperButton>

              <Tooltip title={saveConfigurationTooltipTitle}>
                <FormWrapperButton
                  labelledBy="saveIoaConfiguration"
                  variant={ButtonVariant.primary}
                  size={ButtonSize.large}
                  customDisabled={!canEdit || !hasSomeAttacksSelected}
                  buttonProps={{
                    onClick: handleSaveIoAConfigurationButtonClick(
                      storeManagementAttackTypeConfiguration
                    )(
                      storeManagementAttackTypeConfiguration.customPastDelaySeconds,
                      isIoAAutomaticConfiguration
                    ),
                    loading:
                      storeManagementAttackTypeConfiguration
                        .storeFlagsReloadAttackTypeConfiguration.isLoading ||
                      storeManagementAttackTypeConfiguration
                        .storeFlagsUpdateAttackTypeConfiguration.isLoading
                  }}
                  icon={IconRightArrow}
                >
                  {translate('Save the configuration')}
                </FormWrapperButton>
              </Tooltip>
            </ContainerRbac>
          ]}
          justifyContent="flex-end"
          spaced
        />
      </StyledContainerFooter>

      <ModalConfirmSaveConfiguration />
    </>
  )
}

export default observer(ConfigurationAttackTypeFooter)
