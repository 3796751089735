import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconReloadProps extends IIconProps {}

const IconReload: React.FC<IIconReloadProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <path
        d="M2.07664 10.5947C0.954313 8.03572 1.61969 5.06629 3.63739 3.22622L4.4271 4.174L5.0125 1.18563L1.9633 1.21559L2.65561 2.04687C0.118286 4.32105 -0.726667 8.02259 0.670952 11.2108C1.96487 14.162 4.88534 16 8.00317 16C8.57694 16 9.15806 15.9378 9.73641 15.8086L9.40211 14.3114C6.3959 14.9822 3.31445 13.4195 2.07655 10.5951L2.07664 10.5947ZM15.3294 4.78883C13.7973 1.29477 9.98473 -0.639019 6.26323 0.191399L6.59753 1.68858C9.6048 1.01735 12.6852 2.58055 13.9235 5.40475C15.0493 7.97243 14.3754 10.9527 12.3413 12.792L11.6131 11.9176L11.0277 14.9057L14.0769 14.8759L13.3231 13.9711C15.8767 11.6984 16.7304 7.98556 15.3295 4.78872L15.3294 4.78883Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconReload
