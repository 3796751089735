// eslint-disable-next-line no-restricted-imports
import { consts } from '@app/styles'
import { colorTokens, defaultTheme } from '@design-system/styles/themes/default'
import type { DSThemeDefinition } from '@design-system/styles/themes/types'

const legacyColorTokens = {
  /**
   * Colors
   */
  'color/font/link': '#755fd3',
  'color/font/link/hover': '#9c89e0'
}

const legacyBoxShadowTokens = {
  /**
   * Box shadows
   */
  'boxShadow/bladeTab': `-5px 0px 5px -3px ${consts.colorGrey015}`
}

const tokens: Partial<DSThemeDefinition['tokens']> = {
  /**
   * Blade
   */

  'blade/backgroundColor/default': consts.colorBodyBackground,
  'blade/backgroundColor/levelN': consts.colorBodyBackground,
  'blade/boxShadow/default': consts.bladesBoxShadow,
  'blade/borderRadius/default': '0',

  /**
   * Card
   */

  'card/color/border/default': consts.colorBlueGray025,

  /**
   * ContainerDivider
   */

  'containerDivider/color/default': consts.colorBlueGray020,

  /**
   * ContainerFooter
   */

  'containerFooter/color/separator': consts.colorBlueGray030,

  /**
   * ContainerIcon
   */

  'containerIcon/color/default': consts.colorBlue015,
  'containerIcon/color/disabled': consts.colorBlueGray020,
  'containerIcon/color/hover': consts.colorCyan001,

  /**
   * ContainerHTMLContent
   */

  'containerHtmlContent/blockquote/borderLeft': consts.colorBlueGray010,
  'containerHtmlContent/blockquote/color': consts.colorBlueGray010,
  'containerHtmlContent/link/color': legacyColorTokens['color/font/link'],
  'containerHtmlContent/link/color/hover':
    legacyColorTokens['color/font/link/hover'],

  /**
   * ContainerTimeline
   */

  'containerTimeline/borderLeftColor/default': consts.colorBlueGray030,
  'containerTimeline/color/bulletCircle': consts.colorBlue005,

  /**
   * DashboardWidget
   */

  'dashboardWidget/color/title': consts.colorBlueGray010,
  'dashboardWidget/color/counter': consts.colorRed001,
  'dashboardWidget/boxShadow': consts.shadowThin,
  'dashboardWidget/backgroundColor/widgetIllustration':
    colorTokens['color/background/white'],
  'dashboardWidget/backgroundColorHover/widgetIllustration':
    consts.colorSelected01,
  'dashboardWidget/backgroundColorSelected/widgetIllustration':
    consts.colorSelected01,
  'dashboardWidget/borderColor/widgetIllustration': consts.colorBlueGray020,
  'dashboardWidget/borderColorHover/widgetIllustration':
    consts.colorBlueGray020,
  'dashboardWidget/borderColorSelected/widgetIllustration':
    consts.colorBlueGray015,
  'dashboardWidget/borderSize/widgetIllustration': '1px',
  'dashboardWidget/borderSizeSelected/widgetIllustration': '1px',
  'dashboardWidget/boxShadow/widgetIllustration': consts.shadowThin,
  'dashboardWidget/fontSize/widgetIllustration': '42px',
  'dashboardWidget/width/widgetIllustration': '120px',
  'dashboardWidget/fontWeight/fieldSetTitle': '600',

  /**
   * Drawer
   */

  'drawer/color/overlay': consts.colorBlack010,
  'drawer/borderRadius/default': '0',
  'drawer/boxShadow/horizontal': `-5px 0px 5px -3px ${consts.colorGrey030}`,

  /**
   * DrawerVerticalContainer
   */

  'drawerVerticalContainer/boxShadow/default': `0px 3px 5px 0px ${consts.colorGrey015}`,

  /**
   * HeadBar
   */

  'headBar/boxShadow/bladeTab': legacyBoxShadowTokens['boxShadow/bladeTab'],
  'headBar/color/bodyBackgroundN': colorTokens['color/background/white'],
  'headBar/height/default': '32px',

  /**
   * InfrastructureDirectories
   */

  'infrastructureDirectories/color/border': consts.colorBlueGray015,

  /**
   * Input
   */

  'input/borderRadius/default': '0',
  'input/backgroundColor/default': consts.colorBlueGray035,
  'input/backgroundColor/disabled': consts.colorInputDisabled,
  'input/color/text': consts.colorBlue015,
  'input/color/textDisabled': consts.colorBlueGray015,
  'input/color/textError': consts.colorRed001,
  'input/color/placeholder': '#757575',
  'input/height/default': 'unset',

  /**
   * InputExpression
   */

  'inputExpression/backgroundColor/default': consts.colorWhiteAlt,
  'inputExpression/backgroundColor/widgetButton': consts.colorBlueGray030,
  'inputExpression/backgroundColor/widgetButtonDisabled':
    consts.colorInputDisabled,
  'inputExpression/backgroundColor/widgetButtonHover': consts.colorBlue010,
  'inputExpression/borderRadius/default': '4px',
  'inputExpression/borderColor/default': consts.colorGrey010,
  'inputExpression/borderColor/hover': consts.colorBlue010,
  'inputExpression/color/textSecondary': consts.colorBlueGray015,
  'inputExpression/color/widgetButtonIcon': consts.colorBlue015,
  'inputExpression/color/widgetButtonIconHover': consts.colorWhite,

  /**
   * InputSearch
   */

  'inputSearch/backgroundColor/default': consts.colorBlueGray035,
  'inputSearch/padding/default': `4px 8px`,

  /**
   * Label
   */

  'label/color/text': consts.colorBlue015,
  'label/color/textError': consts.colorRed001,
  'label/color/textSuccess': consts.colorGreen020,

  /**
   * LabelChecker
   */
  'labelChecker/backgroundColor/disabled': consts.colorBlueGray035,
  'labelChecker/backgroundColor/hover': consts.colorSelected01,
  'labelChecker/backgroundColor/selectable': consts.colorSelected01,
  'labelChecker/borderColor/default': consts.colorBlueGray025,
  'labelChecker/color/default': consts.colorBlue015,
  'labelChecker/color/disabled': consts.colorBlueGray015,

  /**
   * Menu
   */

  'menu/backgroundColor/default': consts.colorBodyBackground,
  'menu/color/border': '#e8e8e8',
  'menu/color/font': consts.colorBlue015,
  'menu/style/border': `1px solid #e8e8e8`,

  /**
   * Select
   */

  'select/backgroundColor/default': consts.colorWhite,
  'select/border/default': `1px solid ${consts.colorGrey010}`,
  'select/borderRadius/default': '2px',
  'select/boxShadow/focus': '0 0 0 2px rgba(24, 144, 255, 0.2)',
  'select/color/textSelected': consts.colorBlue015,

  /**
   * Spinner
   */

  'spinner/color/default': consts.colorBlue005,

  /**
   * Table
   */

  'table/backgroundColor/evenRow': consts.colorWhiteAlt,
  'table/backgroundColor/oddRow': consts.colorWhite,
  'table/backgroundColor/rowHover': consts.colorBlueGray040,
  'table/borderBottom/row': `1px solid ${consts.colorBlueGray025}`,
  'table/padding/row': '0',

  /**
   * TagExpression
   */

  'tagExpression/backgroundColor/default': consts.colorGreen030,
  'tagExpression/backgroundColor/white': consts.colorWhiteAlt,
  'tagExpression/color/default': consts.colorBlue015,
  'tagExpression/color/transparent': consts.colorBlue015,
  'tagExpression/color/white': consts.colorBlue015,

  /**
   * TrailFlowSearchEntry
   */

  'trailFlowSearchEntry/backgroundColor/default': consts.colorBlueGray035,
  'trailFlowSearchEntry/backgroundColor/hover': consts.colorBlueGray035,
  'trailFlowSearchEntry/boxShadow/hover': consts.shadowToggleableRow
}

export const legacyThemeTokens: DSThemeDefinition = {
  ...defaultTheme,
  tokens: { ...defaultTheme.tokens, ...tokens }
}
