import type { IAttackChartData } from '@app/pages/IoA/IoABoardPage/IoABoardCards/CardChart/types'
import * as d3 from 'd3'
import * as React from 'react'

interface IDotnutChartProps {
  className?: string
  data: IAttackChartData[]
  width: number
  height: number
  innerRadius: number
  outerRadius: number
}

const DotnutChart: React.FC<IDotnutChartProps> = props => {
  const gRef = React.useRef(null)

  const createPie = d3
    .pie<any, IAttackChartData>()
    .value(d => d.rate)
    .sort(null)

  const createArc = d3
    .arc()
    .innerRadius(props.innerRadius)
    .outerRadius(props.outerRadius)

  React.useEffect(() => {
    const data = createPie(props.data)

    const g = d3.select(gRef.current)
    const gWithData = g.selectAll('g.arc').data(data)

    const gWithUpdate = gWithData.enter().append('g').attr('class', 'arc')

    const path = gWithUpdate.append('path').merge(gWithData.select('path.arc'))

    path
      .attr('class', 'arc')
      .attr('d', createArc as any)
      .attr('fill', d => d.data.criticityColor)
      .attr('stroke', '#fff')
      .attr('stroke-width', 2)
  }, [props.data])

  return (
    <svg width={props.width} height={props.height}>
      <g
        ref={gRef}
        transform={`translate(${props.outerRadius} ${props.outerRadius})`}
      />
    </svg>
  )
}

export default DotnutChart
