export enum EmailFormFieldName {
  id = 'id',
  address = 'address',
  description = 'description',
  shouldNotifyOnInitialFullSecurityCheck = 'shouldNotifyOnInitialFullSecurityCheck',

  profiles = 'profiles',

  inputType = 'inputType',
  criticityThreshold = 'criticityThreshold',
  checkers = 'checkers',
  attackTypes = 'attackTypes',

  directories = 'directories',
  healthCheckNames = 'healthCheckNames'
}
