import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import type { Criticity, Maybe } from '@server/graphql/typeDefs/types'

export enum LabelCheckerSize {
  small = '200px',
  normal = '380px',
  fullWidth = '100%'
}

export interface ILabelCheckerAppearance {
  size?: LabelCheckerSize
  small?: boolean
  checkable?: boolean
  selectable?: boolean
  hoverable?: boolean
  selected?: boolean
  maxLength?: number
  // if false, the background is kept "white" even if selected
  selectedBackground?: boolean
  indeterminate?: boolean
  enabled?: Maybe<boolean>
  // is true (default), force min-height of all cards
  fixedHeight?: boolean
}

export interface ILabelCheckerProps<GC extends TGenericChecker>
  extends ILabelCheckerAppearance {
  /* Styles */

  className?: string

  /* Appearance is inherited from ILabelCheckerAppearance */

  /* Business data */

  name: GC['entityType']['genericName']
  codename?: GC['entityType']['genericCodename']
  criticity: Maybe<Criticity>
  description?: string
  resolvedAt?: Maybe<Date | string>
  resolved?: boolean
  complexityValue?: number
  directoryIds?: number[]

  /* Behavior */

  onClick?: (codename: GenericCheckerCodename, selected?: boolean) => void
}
