import { LabelColorTag, LabelSliced } from '@app/components-legacy/Label'
import { computeLabelColor } from '@app/styles/colors/getLabelColor'
import { Tooltip } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import BulletTriangle from '../Bullet/BulletTriangle'

const directoryTagIconAndMarginsWidth = 5 + 8 + 5 + 5 + 8

const StyledBulletTriangle = styled(BulletTriangle)`
  margin-top: 0;
`

export interface ILabelDirectoryTagProps {
  className?: string
  style?: React.CSSProperties
  labelledBy?: string
  color: string
  label: string
  maxLength?: number
  maxWidth?: number
  tooltipLabel?: React.ReactNode | string
}

const LabelDirectoryTag: React.FC<ILabelDirectoryTagProps> = props => {
  const renderLabel = () => {
    const maxLength = !props.maxWidth && !props.maxLength ? 25 : props.maxLength

    if (props.tooltipLabel) {
      return (
        <Tooltip title={props.tooltipLabel}>
          <div>
            <LabelSliced
              labelledBy={props.labelledBy}
              maxLength={maxLength}
              maxWidth={
                props.maxWidth &&
                props.maxWidth - directoryTagIconAndMarginsWidth
              }
              value={props.label}
              tooltipProps={{ open: false }}
            />
          </div>
        </Tooltip>
      )
    }

    return (
      <LabelSliced
        labelledBy={props.labelledBy}
        maxLength={maxLength}
        value={props.label}
      />
    )
  }

  return (
    <LabelColorTag
      className={props.className}
      color={props.color}
      icon={<StyledBulletTriangle color={computeLabelColor(props.color)} />}
    >
      {renderLabel()}
    </LabelColorTag>
  )
}

export default LabelDirectoryTag
