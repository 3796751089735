import type { StoreManagementApplicationSettings } from '@app/stores'
import { TelemetryFormFieldName } from '@app/stores/Management/StoreApplicationSettings/types'
import type { InputUpdateApplicationSettings } from '@server/graphql/typeDefs/types'

export const onTelemetryConfigurationLoad =
  (storeManagementApplicationSettings: StoreManagementApplicationSettings) =>
  () => {
    storeManagementApplicationSettings.fetchApplicationSettings().then(() => {
      const applicationSettings =
        storeManagementApplicationSettings.applicationSettings

      if (!applicationSettings) {
        return
      }

      storeManagementApplicationSettings.storeFormTelemetryConfiguration
        .setDefaultFieldsValues([
          {
            key: TelemetryFormFieldName.telemetryEnabled,
            value: applicationSettings.getPropertyAsBoolean('telemetryEnabled')
          }
        ])
        .reset()
    })
  }

export const onTelemetryConfigurationUnload =
  (storeManagementApplicationSettings: StoreManagementApplicationSettings) =>
  () => {
    storeManagementApplicationSettings.storeFormTelemetryConfiguration.reset()
  }

/**
 * Save Logs configuration.
 */
export const onTelemetryConfigurationSubmit =
  (storeManagementApplicationSettings: StoreManagementApplicationSettings) =>
  (e: React.FormEvent<unknown>) => {
    e.preventDefault()

    const { storeFormTelemetryConfiguration } =
      storeManagementApplicationSettings

    if (!storeFormTelemetryConfiguration.validate()) {
      storeFormTelemetryConfiguration.storeRoot.stores.storeMessages.validationError()
      return
    }

    const currentApplicationSettings =
      storeManagementApplicationSettings.applicationSettings

    if (!currentApplicationSettings) {
      return
    }

    const applicationSettings: InputUpdateApplicationSettings = {
      telemetryEnabled: storeFormTelemetryConfiguration.getFieldValueAsBoolean(
        TelemetryFormFieldName.telemetryEnabled
      )
    }

    storeManagementApplicationSettings
      .updateApplicationSettings(applicationSettings)
      .catch(() => {
        // No need to catch the error for now as we do not intend to display it
        // to the user
      })
  }
