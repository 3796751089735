import type { Maybe } from '@@types/helpers'
import { marked } from 'marked'

/**
 * Transform markdown to HTML by keeping 'hard breaks' (all \n => </br>).
 */
export function mdToHTML(
  text: Maybe<string>,
  options: {
    markedOption?: marked.MarkedOptions
    customRenderers?: Partial<marked.Renderer>
  } = {}
): string {
  if (!text) {
    return ''
  }

  const markedOptions: marked.MarkedOptions = {
    gfm: true,
    breaks: true,
    ...options
  }

  marked.use({ renderer: defaultRenderers() })

  if (options.customRenderers) {
    marked.use({ renderer: options.customRenderers })
  }

  return marked.parse(text, markedOptions)
}

/**
 * Default markdown renderers.
 */
function defaultRenderers(): marked.Renderer {
  const renderer = new marked.Renderer()

  // We want to open a link in a new tab instead of the current page
  renderer.link = (href, title, text) => {
    return `<a target="_blank" rel="noopener noreferrer" href="${href}" ${
      title ? `title="${title}"` : ''
    }>${text}</a>`
  }

  return renderer
}

/**
 * Tokenize a text and return the list of tokens.
 * Useful when used with `sliceTokenizedMarkdownString`.
 */
export function tokenizeMarkdownString(
  text: string,
  options: {
    markedOption?: marked.MarkedOptions
    customRenderers?: Partial<marked.Renderer>
  } = {}
): marked.TokensList {
  if (options.customRenderers) {
    marked.use({ renderer: options.customRenderers as marked.Renderer })
  }

  return marked.lexer(text)
}
