import type { Maybe } from '@@types/helpers'
import { useBladeContext } from '@app/components-legacy/Blade/hooks'
import type { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { isDefined } from '@libs/isDefined'
import { addSetValueToMap, removeSetValueToMap } from '@libs/setValueToMap'
import * as React from 'react'
import { v4 as v4uuid } from 'uuid'
import type { IBladeContext } from '../Blade/types'
import type { PortalUid } from './types'

const placeHolderPortalIds: Map<
  PlaceHolderName | string,
  Set<string>
> = new Map()

/**
 * Return the PortalUid to be used in a PlaceHolder component.
 */
export function usePortalPlaceHolder(placeHolderName: PlaceHolderName): {
  portalUid: PortalUid
  placeHolderPortalIds: typeof placeHolderPortalIds
} {
  const bladeContext = useBladeContext()

  const portalUid = React.useMemo(() => {
    const _placeHolderName = getPlaceHolderName(placeHolderName, bladeContext)
    const _placeHolderUid = v4uuid()

    addSetValueToMap(placeHolderPortalIds, _placeHolderName, _placeHolderUid)

    return _placeHolderUid
  }, [])

  React.useEffect(() => {
    return () =>
      removeSetValueToMap(placeHolderPortalIds, placeHolderName, portalUid)
  }, [])

  return { portalUid, placeHolderPortalIds }
}

/**
 * Return the PortalUid to be used in a Portal component.
 */
export function usePortal(placeHolderName: PlaceHolderName): {
  portalUid: PortalUid
  placeHolderPortalIds: typeof placeHolderPortalIds
} {
  const bladeContext = useBladeContext()

  const portalUid = React.useMemo(() => {
    const _placeHolderName = getPlaceHolderName(placeHolderName, bladeContext)
    const idsSet = placeHolderPortalIds.get(_placeHolderName)

    return idsSet && Array.from(idsSet.values()).pop()
  }, [])

  if (!portalUid) {
    // if PlaceHolder are present and rendered before the Portal,
    // this should be never the case
    return { portalUid: 'portal-uid-not-found', placeHolderPortalIds }
  }

  return { portalUid, placeHolderPortalIds }
}

/**
 * Generate the portal name as a string.
 *
 * The trick here is to generate a placeholder name relative to a bladeUid if the
 * placeholder matchs /blade/ and if a blade context is provided.
 *
 * It allows to render the content in the correct placeholders when full-reloading
 * the app.
 */
function getPlaceHolderName(
  placeHolderName: PlaceHolderName,
  bladeContext: Maybe<IBladeContext>
): string {
  const isBladePlaceHolder = /blade/.test(placeHolderName)

  if (isBladePlaceHolder) {
    return [placeHolderName, bladeContext?.entityBlade.uuid]
      .filter(isDefined)
      .join('-')
  }

  return placeHolderName
}
