import { IconIdentity } from '@app/components/Icon'
import * as React from 'react'
import { IconPNG } from '../Icon'

interface IBladeHeaderIconProps {
  icon: string
}

const BladeHeaderIcon: React.FC<IBladeHeaderIconProps> = props => {
  if (props.icon === 'identity') {
    return <IconIdentity />
  }

  return (
    <IconPNG
      iconName={`icons/header/${props.icon}`}
      size={40}
      labelledBy="bladeHeader"
    />
  )
}

export default BladeHeaderIcon
