import type { MaybeUndef } from '@@types/helpers'
import type {
  RequestDocument,
  RequestOptions,
  Variables
} from 'graphql-request'
import { GraphQLClient } from 'graphql-request'
import { parseRequestArgs } from 'graphql-request/dist/parseArgs'
import type { RequestInit } from 'graphql-request/dist/types.dom'
import { extractGraphQLQueryName } from '../functions'

/**
 * Tiny wrapper around GraphQLClient to be able to pass the name of the query
 * in the url of the graphQL client for easier readability in network panels
 * of browsers.
 *
 * Unfortunately, it's not really possible to make it with only one instance
 * of GraphQLClient since the instance is targetting a static endpoint set
 * at the instanciation.
 *
 * This class extends the base class GraphQLClient to allow to use it as
 * as GraphQLClient instance.
 */
export default class GQLClientWrapper extends GraphQLClient {
  private _url: string
  private _options: MaybeUndef<RequestInit>

  // save all instances of graphQLClient to avoid new instanciation at each
  // new requests. Each instance is binded to a defined endpoint with the
  // name of the query in querystring for easier debugging.
  private allClients: Map<string, GraphQLClient> = new Map()

  constructor(url: string, options?: RequestInit) {
    super(url, options)

    // recopie url and options in private variables, since original ones are
    // private, hence no accessible here...
    this._url = url
    this._options = options
  }

  /**
   * Overload the request method to instantiate a GraphQLClient by query name.
   * It allows to set a different endpoint with the queryName as querystring.
   *
   * The function can either be called directly with a GraphQL query/mutation string (RequestDocument) and variables
   * or with a RequestOptions object.
   * RequestOptions allows to pass an AbortSignal to ba able to cancel an ongoing request.
   */
  request<T = any, V = Variables>(
    document: RequestDocument,
    variables?: V,
    requestHeaders?: RequestOptions['requestHeaders']
  ): Promise<T>
  request<T = any, V = Variables>(options: RequestOptions<V>): Promise<T>
  request<T = any, V = Variables>(
    documentOrOptions: RequestDocument | RequestOptions<V>,
    variables?: V,
    requestHeaders?: RequestOptions['requestHeaders']
  ): Promise<T> {
    const requestOptions = parseRequestArgs<V>(
      documentOrOptions,
      variables,
      requestHeaders
    )

    const query = String(requestOptions.document)
    const queryName = extractGraphQLQueryName(query)

    if (!queryName) {
      return Promise.reject('Query name not found')
    }

    const existingGraphQLClient = this.allClients.get(queryName)

    if (existingGraphQLClient) {
      return existingGraphQLClient.request({
        ...requestOptions,
        document: query
      })
    }

    const graphQLClientUrl = `${this._url}?q=${queryName}`
    const graphQLClient = new GraphQLClient(graphQLClientUrl, this._options)

    this.allClients.set(queryName, graphQLClient)

    return graphQLClient.request({ ...requestOptions, document: query })
  }
}
