import * as React from 'react'
import { animated, useTransition } from 'react-spring'
import styled from 'styled-components'
import type { IContainerSimpleProps } from './ContainerSimple'
import ContainerSimple from './ContainerSimple'

interface IContainerOpacityProps extends IContainerSimpleProps {
  name?: string
  fade?: boolean
  // if true, set height: 100%
  fullHeight?: boolean | number
}

/**
 * @deprecated
 */
const ContainerOpacity: React.FC<IContainerOpacityProps> = props => {
  const [show] = React.useState(false)

  const transitions = useTransition([show], 0, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  if (props.fade === false) {
    return (
      <ContainerSimple name={props.name ?? 'ContainerOpacity'} {...props}>
        {props.children}
      </ContainerSimple>
    )
  }

  return (
    <ContainerSimple name={props.name ?? 'ContainerOpacity'} {...props}>
      {transitions.map(t => {
        return (
          <animated.div key={t.key} style={t.props}>
            {props.children}
          </animated.div>
        )
      })}
    </ContainerSimple>
  )
}

export default styled(ContainerOpacity)`
  /* Set height */
  ${props => {
    if (!props.fullHeight) {
      return ''
    }

    const height = typeof props.fullHeight === 'number' ? props.fullHeight : 100

    return `
      height: ${height}%;

      > div {
        height: ${height}%;
      }
    `
  }}
`
