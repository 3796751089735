import { Features } from '@alsid/common'
import { useStores } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import * as React from 'react'

/**
 * Setup Telemetry framework when the user is logged in.
 */
export function useSetupTelemetry() {
  const featureTelemetry = useFeatureFlag({
    featureFlagName: Features.TELEMETRY
  })

  const { storeRoot, storeAuthentication, storeTelemetry } = useStores()

  // identify telemetry when logged in
  React.useEffect(() => {
    if (!featureTelemetry) {
      return
    }

    if (!storeAuthentication.whoAmI) {
      return
    }

    const { id } = storeAuthentication.whoAmI

    if (!id) {
      storeRoot.logger.warn(
        '[Telemetry] Cannot enable Telemetry - Missing customer name in Licence od user id'
      )
      return
    }

    storeTelemetry.identifyTelemetry({
      userUid: String(id)
    })
  }, [storeAuthentication.whoAmI])
}
