import { Label } from '@app/components/Labels'
import type { EntityUserLog } from '@app/entities'
import { DateFormat, formatDate } from '@libs/dates/formatDate'
import React from 'react'

const UserLogTime = (props: { log: EntityUserLog }) => {
  const logDate = new Date(Date.parse(String(props.log.createdAt)))

  const hoursMinutes = formatDate(logDate, {
    format: DateFormat.hoursMinutes
  })
  return (
    <Label className="traceTime" labelledBy="time">
      {hoursMinutes}
    </Label>
  )
}

export default UserLogTime
