import { ContainerFlex } from '@app/components/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { IconDeleteOutlined } from '@app/components-legacy/Icon/IconAntd'
import type { TextAreaProps } from 'antd/lib/input/TextArea'
import * as React from 'react'
import styled from 'styled-components'
import { ButtonCopyToClipboard } from '../Button'
import { InputCode } from '../Input'

export interface ITextAreaCertificateProps {
  className?: string
  textAreaProps?: TextAreaProps
  onClear?: () => void
}

/**
 * @deprecated
 */
const TextAreaCertificate: React.FC<ITextAreaCertificateProps> = props => {
  const textAreaFinalProps: TextAreaProps = {
    autoComplete: 'off',
    placeholder: `-----BEGIN CERTIFICATE-----
...
...
-----END CERTIFICATE-----`,
    style: {
      height: '300px'
    },
    ...props.textAreaProps
  }
  // const [textAreaValue, setTextAreaValue] = React.useState('')

  return (
    <ContainerFlex
      name="TextAreaCertificate"
      className={props.className}
      items={[
        <InputCode textAreaProps={textAreaFinalProps} />,

        <ContainerFlex
          name="TextAreaCertificateCopy"
          justifyContent="flex-start"
          direction="column"
          flexGap="verySmall"
          items={[
            <ButtonCopyToClipboard
              textToCopy={String(
                textAreaFinalProps.defaultValue || textAreaFinalProps.value
              )}
            />,
            props.onClear && (
              <FormWrapperButton
                labelledBy="TextAreaCertificateDelete"
                buttonProps={{
                  onClick: props.onClear
                }}
              >
                <IconDeleteOutlined />
              </FormWrapperButton>
            )
          ]}
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default styled(TextAreaCertificate)`
  position: relative;
  width: 500px;
`
