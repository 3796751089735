/**
 * Do stuff according to `condition`.
 *
 * Usage:
 *
 * If(!storeInfrastructures.infrastructures.size)
 *   .fetch(storeInfrastructures.fetchInfrastructures.bind(storeInfrastructures))
 *   .then(() =>
 */
export function If(condition: boolean) {
  return {
    fetch: (fetchFunction: () => Promise<void>) => {
      if (!condition) {
        return Promise.resolve()
      }
      return fetchFunction()
    }
  }
}
