import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconEditProps extends IIconProps {}

const IconEdit: React.FC<IIconEditProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.5 4.50015L5.65789 12.3423C5.5539 12.4463 5.42835 12.5261 5.2901 12.5763L2.53169 13.5771C2.46405 13.6016 2.39852 13.5361 2.42306 13.4685L3.42385 10.7101C3.474 10.5718 3.5539 10.4463 3.65789 10.3423L11.5 2.50015C12.0523 1.94787 12.9477 1.94787 13.5 2.50015L13.5 2.50015C14.0523 3.05244 14.0523 3.94787 13.5 4.50015Z"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.5 13.5H13.5"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default IconEdit
