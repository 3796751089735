import { Features } from '@alsid/common'
import { useHistoryChange } from '@app/components-legacy/Router/hooks'
import { useAppRouter, useStores } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import {
  isSafariBrowser,
  isTelemetryDisableOnRoute
} from '@libs/telemetry/utils'
import * as React from 'react'

/**
 * Handle Telemetry logging when leaving the page.
 */
export function usePageHideTelemetry() {
  const featureTelemetry = useFeatureFlag({
    featureFlagName: Features.TELEMETRY
  })

  const appRouter = useAppRouter()
  const { storeTelemetry } = useStores()
  const { currentRouteInfo } = useHistoryChange()

  const currentRouteName = currentRouteInfo?.routeName || null
  const isTelemetryDisableOnRouteFn = isTelemetryDisableOnRoute(appRouter)
  const isSafari = isSafariBrowser()

  // Handles telemetry for `pagehide` event
  const onHandlerPageHide = React.useCallback(() => {
    // This event is only needed for Safari.
    // Safari needs `pagehide` to cover for `visibilitychange`
    // that doesn't trigger in some cases.
    if (!isSafari) {
      return
    }

    if (!featureTelemetry) {
      return
    }

    if (!currentRouteName) {
      return
    }

    if (isTelemetryDisableOnRouteFn(currentRouteName)) {
      return
    }

    storeTelemetry.logPageLeft(currentRouteName)
  }, [currentRouteName])

  React.useEffect(() => {
    document.addEventListener('pagehide', onHandlerPageHide)

    return () => {
      document.removeEventListener('pagehide', onHandlerPageHide)
    }
  }, [currentRouteName])
}
