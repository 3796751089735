export enum SyslogFormFieldName {
  id = 'id',
  relayId = 'relayId',

  ip = 'ip',
  port = 'port',
  protocol = 'protocol',
  tls = 'tls',
  description = 'description',
  shouldNotifyOnInitialFullSecurityCheck = 'shouldNotifyOnInitialFullSecurityCheck',

  profiles = 'profiles',
  inputType = 'inputType',

  // on events changes
  filterExpression = 'filterExpression',

  // on each deviance
  criticityThreshold = 'criticityThreshold',
  checkers = 'checkers',

  // on each attacks
  attackTypes = 'attackTypes',

  healthCheckNames = 'healthCheckNames',

  directories = 'directories'
}

export enum ProtocolInputName {
  UDP = 'UDP',
  TCP = 'TCP'
}
