import { FormWrapperInputAutoComplete } from '@app/components-legacy/Form'
import type { IFormWrapperInputAutoCompleteProps } from '@app/components-legacy/Form/Wrappers/InputAutoComplete'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { onSelectChange } from '@app/stores/helpers/StoreForm/handlers'
import type { FieldName } from '@app/stores/helpers/StoreForm/types'
import { ensureArray } from '@libs/ensureArray'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IWidgetFormInputAutoCompleteProps {
  /** Field name */
  fieldName: FieldName
  /** Input id */
  inputId: string
  /** Props that will be passed to the rendered component */
  inputProps?: IFormWrapperInputAutoCompleteProps
  /** StoreForm to get data */
  storeForm: StoreForm<any>
}

/**
 * @deprecated
 */
const WidgetFormInputAutoComplete: React.FC<
  IWidgetFormInputAutoCompleteProps
> = props => {
  return (
    <FormWrapperInputAutoComplete
      labelledBy={props.inputProps?.labelledBy ?? props.fieldName}
      autoCompleteProps={{
        id: props.inputId,
        value: props.storeForm.getFieldValueAsString(props.fieldName),
        onChange: onSelectChange(props.storeForm)(props.fieldName),
        onSelect: onSelectChange(props.storeForm)(props.fieldName),
        ...props.inputProps?.autoCompleteProps
      }}
      autoCompleteOptions={ensureArray(props.inputProps?.autoCompleteOptions)}
      inputSearchProps={props.inputProps?.inputSearchProps}
    />
  )
}

export default observer(WidgetFormInputAutoComplete)
