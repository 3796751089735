import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import { useAppTranslator, useStores } from '@app/hooks'
import { AppRouteName } from '@app/routes'
import type { StoreRbac } from '@app/stores'
import type StoreMenu from '@app/stores/helpers/StoreMenu'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { MenuEntryType } from '@app/stores/helpers/StoreMenu/types'
import { filterFalsies } from '@libs/filterFalsies'
import type { TranslateFn } from '@libs/i18n'
import { rbacCapabilityCheckAnyOf } from '@libs/rbac/functions'
import {
  canAccessToActivityLogsConfiguration,
  canAccessToDataCollectionConfiguration,
  canAccessToEmailAlertsConfiguration,
  canAccessToHealthCheckConfiguration,
  canAccessToIoAConfiguration,
  canAccessToLDAPProviderConfiguration,
  canAccessToRelayConfiguration,
  canAccessToReportingCenter,
  canAccessToSAMLProviderConfiguration,
  canAccessToSecurityConfiguration,
  canAccessToSMTPConfiguration,
  canAccessToSyslogAlertsConfiguration,
  canAccessToTelemetryConfiguration,
  canAccessToTenableProviderConfiguration
} from '../permissions'
import { MenuSystemMenuKey } from '../types'

export function getSystemConfigurationMenuEntries(parameters: {
  storeRbac: StoreRbac
  translate: TranslateFn
}): Array<IMenuEntry<MenuSystemMenuKey>> {
  const { storeRbac, translate } = parameters

  const { storeAuthentication, storeLayout, storeAbout } =
    storeRbac.storeRoot.stores

  return filterFalsies<IMenuEntry<MenuSystemMenuKey>>([
    /* Application settings */

    storeRbac.isUserGrantedTo(
      rbacCapabilityCheckAnyOf(
        canAccessToSMTPConfiguration,
        canAccessToActivityLogsConfiguration,
        canAccessToSecurityConfiguration,
        canAccessToIoAConfiguration,
        canAccessToDataCollectionConfiguration,
        canAccessToRelayConfiguration,
        canAccessToTelemetryConfiguration,
        canAccessToHealthCheckConfiguration
      )
    ) && {
      key: MenuSystemMenuKey.ApplicationSettings,
      label: translate('Application settings'),
      labelledBy: 'Application settings',
      type: MenuEntryType.category
    },
    storeRbac.isUserGrantedTo(canAccessToSMTPConfiguration) && {
      key: MenuSystemMenuKey.SMTPServer,
      label: translate('SMTP server'),
      labelledBy: 'SMTP server',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Configuration_SMTP,
        parameters: {}
      },
      type: MenuEntryType.link
    },
    storeRbac.isUserGrantedTo(canAccessToActivityLogsConfiguration) && {
      key: MenuSystemMenuKey.ActivityLogs,
      label: translate(MenuSystemMenuKey.ActivityLogs),
      labelledBy: MenuSystemMenuKey.ActivityLogs,
      routeDefinition: {
        routeName: AppRouteName.Management_System_Configuration_ActivityLogs,
        parameters: {}
      },
      type: MenuEntryType.link
    },
    storeRbac.isUserGrantedTo(canAccessToSecurityConfiguration) && {
      key: MenuSystemMenuKey.Security,
      label: translate('Security'),
      labelledBy: 'Security',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Configuration_Security,
        parameters: {}
      },
      type: MenuEntryType.link
    },
    storeRbac.isUserGrantedTo(canAccessToIoAConfiguration) && {
      key: MenuSystemMenuKey.IndicatorsOfAAttack,
      label: translate('Indicators of Attack'),
      labelledBy: 'Indicators of Attack',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Configuration_IoA,
        parameters: {}
      },
      type: MenuEntryType.link
    },
    storeRbac.isUserGrantedTo(canAccessToDataCollectionConfiguration) && {
      key: MenuSystemMenuKey.CollectSensitiveData,
      label: translate('Tenable Cloud'),
      labelledBy: 'Tenable Cloud',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Configuration_TenableCloud,
        parameters: {}
      },
      type: MenuEntryType.link
    },
    storeRbac.isUserGrantedTo(canAccessToRelayConfiguration) && {
      key: MenuSystemMenuKey.Relay,
      label: translate('Relay'),
      labelledBy: 'Relay',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Configuration_Relay,
        parameters: {}
      },
      type: MenuEntryType.link
    },
    storeRbac.isUserGrantedTo(canAccessToTelemetryConfiguration) && {
      key: MenuSystemMenuKey.TelemetryConfiguration,
      label: translate('Telemetry configuration'),
      labelledBy: 'Telemetry',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Configuration_Telemetry,
        parameters: {}
      },
      type: MenuEntryType.link
    },
    storeRbac.isUserGrantedTo(canAccessToHealthCheckConfiguration) && {
      key: MenuSystemMenuKey.HealthCheckConfiguration,
      label: translate('Health check configuration'),
      labelledBy: 'HealthCheck',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Configuration_HealthCheck,
        parameters: {}
      },
      type: MenuEntryType.link
    },

    /* Alerting engine */

    storeRbac.isUserGrantedTo(
      rbacCapabilityCheckAnyOf(
        canAccessToSyslogAlertsConfiguration,
        canAccessToEmailAlertsConfiguration
      )
    ) && {
      key: MenuSystemMenuKey.AlertingEngine,
      label: translate('Alerting engine'),
      labelledBy: 'Alerting engine',
      type: MenuEntryType.category
    },
    storeRbac.isUserGrantedTo(canAccessToSyslogAlertsConfiguration) && {
      key: MenuSystemMenuKey.SYSLOGAlerts,
      label: translate('SYSLOG alerts'),
      labelledBy: 'SYSLOG alerts',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Configuration_Syslogs,
        parameters: {}
      },
      type: MenuEntryType.link
    },
    storeRbac.isUserGrantedTo(canAccessToEmailAlertsConfiguration) && {
      key: MenuSystemMenuKey.EmailAlerts,
      label: translate('Email alerts'),
      labelledBy: 'Email alerts',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Configuration_Emails,
        parameters: {}
      },
      type: MenuEntryType.link
    },

    /* Reporting center */

    storeRbac.isUserGrantedTo(
      rbacCapabilityCheckAnyOf(canAccessToReportingCenter)
    ) && {
      key: MenuSystemMenuKey.Reporting,
      label: translate('Reporting'),
      labelledBy: 'Reporting',
      type: MenuEntryType.category
    },
    storeRbac.isUserGrantedTo(canAccessToReportingCenter) && {
      key: MenuSystemMenuKey.ReportingCenter,
      label: translate('Reporting center'),
      labelledBy: 'Reporting center',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Configuration_ReportingCenter,
        parameters: {}
      },
      type: MenuEntryType.link
    },

    /* Authentication */

    storeRbac.isUserGrantedTo(
      rbacCapabilityCheckAnyOf(
        canAccessToTenableProviderConfiguration,
        canAccessToLDAPProviderConfiguration(storeAbout),
        canAccessToSAMLProviderConfiguration(storeAbout)
      )
    ) && {
      key: MenuSystemMenuKey.Authentication,
      label: translate('Authentication'),
      labelledBy: 'Authentication',
      type: MenuEntryType.category
    },
    storeRbac.isUserGrantedTo(canAccessToTenableProviderConfiguration) && {
      key: MenuSystemMenuKey.TenableProductName,
      label: storeLayout.getAuthenticationProductName(),
      labelledBy: storeLayout.getAuthenticationProductName(),
      routeDefinition: {
        routeName:
          AppRouteName.Management_System_Configuration_TenableAccountConfiguration,
        parameters: {}
      },
      type: MenuEntryType.link
    },
    storeRbac.isUserGrantedTo(
      canAccessToLDAPProviderConfiguration(storeAbout)
    ) && {
      key: MenuSystemMenuKey.LDAPConfiguration,
      label: translate('LDAP configuration'),
      labelledBy: 'LDAP configuration',
      routeDefinition: {
        routeName:
          AppRouteName.Management_System_Configuration_LDAPConfiguration,
        parameters: {}
      },
      type: MenuEntryType.link
    },
    storeRbac.isUserGrantedTo(
      canAccessToSAMLProviderConfiguration(storeAbout)
    ) && {
      key: MenuSystemMenuKey.SAMLConfiguration,
      label: translate('SAML configuration'),
      labelledBy: 'SAML configuration',
      routeDefinition: {
        routeName:
          AppRouteName.Management_System_Configuration_SAMLConfiguration,
        parameters: {}
      },
      type: MenuEntryType.link
    }
  ])
}

export function useSystemConfigurationStoreMenu(
  storeMenu?: StoreMenu<IMenuEntry<MenuSystemMenuKey>>
): StoreMenu<IMenuEntry<MenuSystemMenuKey>> {
  const { storeRbac } = useStores()

  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.Navigation']
  })

  return useStoreMenuInit<IMenuEntry<MenuSystemMenuKey>>({
    storeMenu,
    menuEntries: getSystemConfigurationMenuEntries({ storeRbac, translate })
  })
}
