import { consts } from '@app/styles'
import styled from 'styled-components'

export function setApiInspectorTableColumnsStyled(
  component: React.ComponentType<any>
) {
  return styled(component)`
    display: flex;
    justify-content: space-between;
    flex-flow: nowrap;
    border-bottom: 1px solid ${consts.colorBlueGray025};

    > * {
      flex: none;
      width: 50%;
      overflow: hidden;
      padding-left: ${consts.paddingVerySmall};
    }

    > div {
      margin-right: ${consts.marginVerySmall};
    }
  `
}
