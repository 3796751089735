import { BladeCancelButton } from '@app/components-legacy/Blade'
import {
  ContainerContent,
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import { useAppTranslator, useStores } from '@app/hooks'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { RelaysFormFieldName } from '@app/stores/Management/StoreRelays'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export interface IRelayCommonFormProps {
  version: 'edition'
  onSubmit: (e: React.FormEvent<any>) => void
  storeForm: StoreForm<any>
}

const RelayCommonForm: React.FC<IRelayCommonFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Titles', 'Management.System.Relays']
  })
  const { storeManagementRelays } = useStores()

  return (
    <FormWrapper name="relayConfiguration" onSubmit={props.onSubmit}>
      <ContainerFlex
        name="RelaysEditForm"
        direction="column"
        items={[
          <ContainerContent title={translate('Main information')}>
            <WidgetForm<RelaysFormFieldName>
              translate={translate}
              storeForm={props.storeForm}
              displayedFields={[RelaysFormFieldName.name]}
            />
          </ContainerContent>
        ]}
        spaced
      />

      <Portal name={PlaceHolderName.bladeFooter}>
        <ContainerFooter>
          <BladeCancelButton />

          <FormWrapperButtonSubmit
            type="primary"
            loading={storeManagementRelays.storeEditionFlags.isLoading}
          >
            {translate('Edit')}
          </FormWrapperButtonSubmit>
        </ContainerFooter>
      </Portal>
    </FormWrapper>
  )
}

export default observer(RelayCommonForm)
