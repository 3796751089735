import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import type { IEntityListable } from '@app/entities/types'
import type {
  IDataRowGeneric,
  IWidgetListColumns
} from '@app/stores/helpers/StoreWidgetList/types'
import type {
  HealthCheckPlatformStatus,
  HealthCheckStatus,
  Maybe
} from '@server/graphql/typeDefs/types'

export default class EntityHealthCheckPlatformStatus
  extends EntityBase
  implements
    PropertiesNullable<HealthCheckPlatformStatus>,
    IEntityListable<IDataRowGeneric>
{
  healthCheckName: Maybe<string> = null
  status: Maybe<HealthCheckStatus> = null
  lastCheckDate: Maybe<string> = null
  statusSince: Maybe<string> = null

  constructor(data: Partial<HealthCheckPlatformStatus>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Not used, columns are hardcoded in the view with a custom render.
   */
  getColumns(): Array<IWidgetListColumns<IDataRowGeneric>> {
    return []
  }
}
