/**
 * Module that save an instance of a logger.
 * Get retrieve the logger via `getLogger` when you need it, server or client side.
 */

import { wrap } from 'lodash'
import type { ILogger } from './types'

let logger: ILogger

/**
 * Return a new logger with a prefix if the namespace has been defined.
 */
export function getLogger(namespace?: string): ILogger {
  const currentLogger = logger || console

  if (namespace && currentLogger.child) {
    return currentLogger.child({ namespace })
  }

  if (namespace && currentLogger.getLogger) {
    const clogger = currentLogger.getLogger(namespace)

    // do not wrap again if the logger is already wrapper
    // (workaround after a Hot Reload)
    if (clogger.prefix) {
      return clogger
    }

    const ns = `[${namespace}]`

    clogger.debug = wrap(clogger.debug, (fn, ...args) => fn(ns, ...args))
    clogger.info = wrap(clogger.info, (fn, ...args) => fn(ns, ...args))
    clogger.warn = wrap(clogger.warn, (fn, ...args) => fn(ns, ...args))
    clogger.error = wrap(clogger.error, (fn, ...args) => fn(ns, ...args))

    clogger.prefix = true

    return clogger
  }

  return currentLogger
}

/**
 * Bind `logger` methods to console global object.
 */
export function setLogger(newLogger: ILogger): void {
  logger = newLogger

  /* eslint-disable no-console */
  console.log = newLogger.info.bind(newLogger)
  console.debug = newLogger.debug.bind(newLogger)
  console.info = newLogger.info.bind(newLogger)
  console.warn = newLogger.warn.bind(newLogger)
  console.error = newLogger.error.bind(newLogger)
}
