import type { Maybe } from '@@types/helpers'
import type StoreAttacks from '@app/stores/IoA/StoreAttacks'
import * as React from 'react'

const AttacksPageContext = React.createContext<
  Maybe<{
    storeAttacks: StoreAttacks
  }>
>(null)

export { AttacksPageContext }
