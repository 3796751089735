import type { PropertiesNullable } from '@@types/helpers'
import { interpolateTemplate } from '@alsid/common/helpers/template/interpolateTemplate'
import type {
  IEntityExportable,
  IEntityExportableAsCsvColumnsParameters,
  IEntityExportableAsCsvRowParameters,
  IEntityListable,
  IEntityOmitKeysParameter
} from '@app/entities/types'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import { sanitizeCsvValue } from '@libs/csv-protector'
import { formatAttributeValueToMarkdown } from '@libs/incriminatingAttributes/formatAttributeValueToMarkdown'
import type {
  DescriptionTemplate,
  Finding,
  Tenant,
  ProviderType,
  DescriptionTemplateReplacementsInner
} from '@libs/openapi/service-identity-core'
import type { Maybe } from '@server/graphql/typeDefs/types'
import EntityBase from '../EntityBase'

/**
 * Used for findings list.
 */
export interface IDataRowFinding {
  id: string
  type: string
  object: string
  provider: Maybe<ProviderType>
  tenant: Maybe<Tenant>
  description: string
  rawDescription: string
  date: string
}

export interface IDataRowFindingExportable {
  id: string
  object: string
  provider: Maybe<ProviderType>
  tenant: Maybe<Tenant>
  description: string
  date: string
}

export default class EntityFinding
  extends EntityBase
  implements
    PropertiesNullable<Finding>,
    IEntityListable<IDataRowFinding>,
    IEntityExportable<IDataRowFindingExportable>
{
  uuid: Maybe<string> = null
  checkerId: Maybe<string> = null
  type: Maybe<string> = null
  object: Maybe<string> = null
  detectionDate: Maybe<Date> = null
  description: Maybe<DescriptionTemplate> = null
  path: Maybe<string> = null
  tenant: Maybe<Tenant> = null

  constructor(data: Partial<Finding>) {
    super()
    Object.assign(this, data)
  }

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowFinding> = []
  ): Array<IWidgetListColumns<IDataRowFinding>> {
    const columns: Array<IWidgetListColumns<IDataRowFinding>> = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Type',
        key: 'type'
      },
      {
        label: 'Object',
        key: 'object'
      },
      {
        label: 'Provider',
        key: 'provider'
      },
      {
        label: 'Tenant',
        key: 'tenant'
      },
      {
        label: 'Description',
        key: 'description'
      },
      {
        label: 'Date',
        key: 'date'
      }
    ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  /**
   * Return the entity as a row for widget lists.
   */
  asDataRow(): IDataRowFinding {
    const descriptionTemplate =
      this.getPropertyAsT<DescriptionTemplate>('description')

    // Interpolate description template with variables
    const description = interpolateTemplate(
      descriptionTemplate.template,
      this._formatTemplateReplacements(descriptionTemplate.replacements, true)
    )

    const rawDescription = interpolateTemplate(
      descriptionTemplate.template,
      this._formatTemplateReplacements(descriptionTemplate.replacements)
    )

    return {
      id: this.getPropertyAsString('uuid'),
      type: this.getPropertyAsString('type'),
      object: this.getPropertyAsString('object'),
      provider: this.tenant?.providerType ?? null,
      tenant: this.tenant ?? null,
      description,
      rawDescription,
      date: this.getPropertyAsString('detectionDate')
    }
  }

  /** Implements IEntityExportable */

  getCSVColumns(
    parameters: IEntityExportableAsCsvColumnsParameters<IDataRowFindingExportable>
  ): Array<IWidgetListColumns<IDataRowFindingExportable>> {
    const columns: Array<IWidgetListColumns<IDataRowFindingExportable>> = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Object',
        key: 'object'
      },
      {
        label: 'Provider',
        key: 'provider'
      },
      {
        label: 'Tenant',
        key: 'tenant'
      },
      {
        label: 'Description',
        key: 'description'
      },
      {
        label: 'Date',
        key: 'date'
      }
    ]

    return columns.filter(column => !parameters.omitKeys.includes(column.key))
  }

  /**
   * Return the entity as a string for CSV exports.
   */
  asCSVRow(
    parameters: IEntityExportableAsCsvRowParameters<IDataRowFindingExportable>
  ): Maybe<string> {
    const descriptionTemplate =
      this.getPropertyAsT<DescriptionTemplate>('description')

    // Interpolate description template with variables
    const description = interpolateTemplate(
      descriptionTemplate.template,
      this._formatTemplateReplacements(descriptionTemplate.replacements)
    )

    const csvRowValues: Record<keyof IDataRowFindingExportable, string> = {
      id: sanitizeCsvValue(this.uuid),
      object: sanitizeCsvValue(this.object),
      provider: sanitizeCsvValue(
        parameters.translate(this.tenant?.providerType ?? '-', {
          namespaces: ['Components.IconIdentityProvider']
        })
      ),
      tenant: sanitizeCsvValue(this.tenant?.name),
      description: sanitizeCsvValue(description),
      date: sanitizeCsvValue(this.detectionDate?.toISOString())
    }

    const columnsKeys = this.getCSVColumns(parameters).map(column => column.key)

    return Object.entries(csvRowValues)
      .filter(([key]) =>
        columnsKeys.includes(key as keyof IDataRowFindingExportable)
      )
      .map(([_, value]) => value)
      .join(parameters.separator)
  }

  /**
   * Private
   */

  /**
   * Generates an object containing every replacement needed.
   * Replacements can adopt the Markdown format.
   */
  private _formatTemplateReplacements(
    replacements: DescriptionTemplateReplacementsInner[],
    useMarkdown?: boolean
  ): Record<string, string> {
    return Object.fromEntries(
      replacements
        .reduce((obj, replacement) => {
          const replacementValue = useMarkdown
            ? formatAttributeValueToMarkdown(
                replacement.value,
                replacement.valueType
              )
            : replacement.value

          obj.set(replacement.name, replacementValue)
          return obj
        }, new Map<string, string>())
        .entries()
    )
  }
}
