import type { Query } from '@server/graphql/typeDefs/types'

/**
 * Retrieve dashboards
 */
export type QueryRbacDashboards = Pick<Query, 'rbacDashboards'>

export const queryRbacDashboards = `
  query queryRbacDashboards(
    $profileId: Int!
    $dashboardId: Int
  ) {
    rbacDashboards(
      profileId: $profileId
      dashboardId: $dashboardId
    ) {
      node {
        instanceName
        id
        name
        order
        rbacWidgets {
          node {
            id
            posX
            posY
            width
            height
            title
            options {
              type
            }
          }
          rbacCapability {
            isGranted
            isFound
            message
            rbacEntityName
          }
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

export type QueryRbacDashboardsWidgets = Pick<Query, 'rbacDashboards'>

export const queryRbacDashboardsWidgets = `
  query queryRbacDashboardsWidgets(
    $profileId: Int!
    $dashboardId: Int
    $dashboardWidgetId: Int
  ) {
    rbacDashboards(
      profileId: $profileId
      dashboardId: $dashboardId
    ) {
      node {
        instanceName
        id
        name
        order
        rbacWidgets(
          dashboardWidgetId: $dashboardWidgetId
        ) {
          node {
            id
            posX
            posY
            width
            height
            title
            options {
              type
              series {
                dataOptions {
                  __typename
                  ... on DashboardWidgetOptionsSerieDataOptionsUser {
                    type
                    directoryIds
                    profileId
                    active
                    interval
                    duration
                  }
                  ... on DashboardWidgetOptionsSerieDataOptionsDeviance {
                    type
                    checkerIds
                    directoryIds
                    reasonIds
                    profileId
                    interval
                    duration
                  }
                  ... on DashboardWidgetOptionsSerieDataOptionsComplianceScore {
                    type
                    checkerIds
                    directoryIds
                    reasonIds
                    profileId
                    interval
                    duration
                  }
                }
                displayOptions {
                  __typename
                  ... on DashboardWidgetOptionsSerieDisplayOptionsLineChart {
                    type
                    label
                  }
                  ... on DashboardWidgetOptionsSerieDisplayOptionsBarChart {
                    type
                    label
                  }
                  ... on DashboardWidgetOptionsSerieDisplayOptionsBigNumber {
                    type
                    label
                  }
                }
              }
            }
          }
          rbacCapability {
            isGranted
            isFound
            message
            rbacEntityName
          }
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

export type QueryRbacDashboardsWidgetsStats = Pick<Query, 'rbacDashboards'>

export const queryRbacDashboardsWidgetsStats = `
  query queryDashboardsWidgetsStats(
    $profileId: Int!
    $dashboardId: Int
    $dashboardWidgetId: Int
  ) {
    rbacDashboards(
      profileId: $profileId
      dashboardId: $dashboardId
    ) {
      node {
        instanceName
        id
        name
        order
        rbacWidgets(
          dashboardWidgetId: $dashboardWidgetId
        ) {
          node {
            id
            posX
            posY
            width
            height
            title
            options {
              type
              series {
                dataOptions {
                  __typename
                  ... on DashboardWidgetOptionsSerieDataOptionsUser {
                    type
                    directoryIds
                    profileId
                    active
                    interval
                    duration
                  }
                  ... on DashboardWidgetOptionsSerieDataOptionsDeviance {
                    type
                    checkerIds
                    directoryIds
                    reasonIds
                    profileId
                    interval
                    duration
                  }
                  ... on DashboardWidgetOptionsSerieDataOptionsComplianceScore {
                    type
                    checkerIds
                    directoryIds
                    reasonIds
                    profileId
                    interval
                    duration
                  }
                }
                displayOptions {
                  __typename
                  ... on DashboardWidgetOptionsSerieDisplayOptionsLineChart {
                    type
                    label
                  }
                  ... on DashboardWidgetOptionsSerieDisplayOptionsBarChart {
                    type
                    label
                  }
                  ... on DashboardWidgetOptionsSerieDisplayOptionsBigNumber {
                    type
                    label
                  }
                }
                stats(
                  profileId: $profileId
                ) {
                  __typename
                  ... on DashboardWidgetOptionsSerieStatUser {
                    type
                    timestampStart
                    timestampEnd
                    directoryIds
                    profileId
                    active
                    interval
                    label
                    data {
                      timestamp
                      count
                    }
                  }
                  ... on DashboardWidgetOptionsSerieStatDeviance {
                    type
                    timestampStart
                    timestampEnd
                    checkerIds
                    directoryIds
                    reasonIds
                    profileId
                    interval
                    label
                    data {
                      timestamp
                      count
                    }
                  }
                  ... on DashboardWidgetOptionsSerieStatComplianceScore {
                    type
                    timestampStart
                    timestampEnd
                    checkerIds
                    directoryIds
                    reasonIds
                    profileId
                    interval
                    label
                    data {
                      timestamp
                      value
                    }
                  }
                }
              }
            }
          }
          rbacCapability {
            isGranted
            isFound
            message
            rbacEntityName
          }
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
