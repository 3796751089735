import type { IIconProps } from '@app/components/Icon/types'
import { IconDoubleLeftOutlined } from '@app/components-legacy/Icon/IconAntd'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

const StyledIconDoubleLeftOutlined = styled(IconDoubleLeftOutlined)`
  svg {
    fill: ${props => props.color ?? CTAColorV2.primary};
    width: ${props => props.size ?? 11}px;
    height: ${props => props.size ?? 11}px;
  }
`

interface IIconDoubleLeftProps extends IIconProps {}

const IconDoubleLeft: React.FC<IIconDoubleLeftProps> = props => (
  <StyledIconDoubleLeftOutlined {...props} />
)

export default IconDoubleLeft
