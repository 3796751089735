import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { BackgroundColorV2, CTAColorV2, FontColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'
import { AttackPathVariant } from '../../types'

interface IAttackPathTabProps {
  className?: string
  variant?: AttackPathVariant
  labelledBy?: string
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  children?: React.ReactNode
}

const AttackPathTab: React.FC<IAttackPathTabProps> = props => {
  const { testAttributeProps } = useTestAttribute('tab')

  return (
    <div
      className={props.className}
      {...testAttributeProps()(props.labelledBy)}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  )
}

export default styled(AttackPathTab)`
  color: ${props =>
    !props.variant || props.variant === AttackPathVariant.default
      ? FontColorV2.white
      : CTAColorV2.primary};
  font-size: 13px;
  padding: ${props =>
    !props.variant || props.variant === AttackPathVariant.default
      ? '5px 25px'
      : '5px 15px'};
  cursor: pointer;
  pointer-events: auto;
  text-align: center;

  &.active {
    background-color: ${props =>
      !props.variant || props.variant === AttackPathVariant.default
        ? BackgroundColorV2.white
        : CTAColorV2.primary};
    color: ${props =>
      !props.variant || props.variant === AttackPathVariant.default
        ? CTAColorV2.primary
        : FontColorV2.white};
    border-radius: 5px;
    user-select: none;
  }
`
