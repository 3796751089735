import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import * as React from 'react'

interface IIconCheckProps extends IIconProps {}

const IconCheck: React.FC<IIconCheckProps> = props => {
  const fill = props.color ?? consts.colorWhite
  const size = props.size ?? 13

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <g
        id="Page-2"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-412.000000, -2340.000000)">
          <g transform="translate(412.000000, 2340.000000)">
            <rect x="0" y="0" width="16" height="16" />
            <path
              d="M4.86647922,12.0779163 L5.7419684,13 L6.4378329,11.9362518 C6.91965878,11.2006386 7.44292939,10.4805363 7.99028359,9.79490968 C9.68606398,7.67777448 11.7030411,5.80431211 13.9858935,4.22577344 L14.8148807,3.65433566 L13.6717585,2 L12.8445907,2.57156113 C10.4011398,4.26049574 8.23910618,6.26704998 6.42065677,8.53787607 C6.09421781,8.94538512 5.77477882,9.3650439 5.46918557,9.79327532 C4.75259871,9.04896612 4.14465009,8.44283688 3.11547001,7.46376669 L2.38670259,6.76956739 L1,8.22876742 L1.72876742,8.92296673 C3.09992823,10.2267798 3.70775351,10.8553275 4.86641754,12.0778855 L4.86647922,12.0779163 Z"
              fill={fill}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconCheck
