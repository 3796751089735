import type { MaybeUndef } from '@@types/helpers'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import type { StoreIoE } from '@app/stores'

/**
 * Loaders/Reset.
 */

/**
 * On initialization, fetch the deviant objects.
 */
export const onDeviantObjectsLoad =
  (storeIoE: StoreIoE) => (checkerCodename: GenericCheckerCodename) => () => {
    const { storeIndicatorDeviantObjects } = storeIoE.storeIndicator

    return (
      Promise.resolve()
        .then(() => {
          // checkers are available when coming from IoE board,
          // not not when coming from Topology
          const hasCheckers = storeIoE.checkers.size > 0

          if (hasCheckers) {
            return Promise.resolve()
          }

          return storeIoE.fetchCheckersExposure()
        })
        // refresh infrastructures *first* to be sure to not pass deleted
        // infras/dirs as mutations args
        .then(() => {
          return storeIndicatorDeviantObjects.storeInfrastructures.fetchInfrastructures()
        })
        .then(() => {
          storeIndicatorDeviantObjects.storeInfrastructures.replaceSelectedDirectories(
            storeIoE.storeRoot.stores.storeInfrastructures
              .selectedDirectoriesMap
          )

          const args =
            storeIndicatorDeviantObjects.computeFetchDeviantObjectsArgs(
              checkerCodename
            )

          if (!args) {
            return
          }

          return Promise.all([
            storeIndicatorDeviantObjects.fetchDeviantObjects(args),
            // fetch checker reasons first
            storeIndicatorDeviantObjects.fetchCheckerReasons({
              ...args,
              optionsCodenames: []
            })
          ]).then(() => {
            // then all reasons, to be able to filter on checker reasons
            storeIndicatorDeviantObjects.storeInputReasons.fetchReasons()
          })
        })
    )
  }

/**
 * Reset everything when leaving the page.
 */
export const onDeviantObjectsUnload = (storeIoE: StoreIoE) => () => {
  const { storeIndicatorDeviantObjects } = storeIoE.storeIndicator
  storeIndicatorDeviantObjects.reset()
}

/**
 * Refresh the deviant objects.
 */
export const refreshDeviantObjects =
  (storeIoE: StoreIoE) => (checkerCodename: GenericCheckerCodename) => () => {
    const { storeIndicatorDeviantObjects } = storeIoE.storeIndicator

    if (!storeIndicatorDeviantObjects.storeInputExpression.validateEntry()) {
      return
    }

    storeIndicatorDeviantObjects.storeWidgetListDeviantObjects.resetPagination()

    const args =
      storeIndicatorDeviantObjects.computeFetchDeviantObjectsArgs(
        checkerCodename
      )

    if (!args) {
      return
    }

    storeIndicatorDeviantObjects.reloadDeviantObjects(args)
  }

/**
 * Pagination.
 */

/**
 * On deviant objects page change, fetch deviant objets of the new page.
 */
export const onDeviantObjectsPageChange =
  (storeIoE: StoreIoE) =>
  (checkerCodename: GenericCheckerCodename) =>
  (page: number, perPage: MaybeUndef<number>) => {
    const { storeIndicatorDeviantObjects } = storeIoE.storeIndicator

    const args = storeIndicatorDeviantObjects.computeFetchDeviantObjectsArgs(
      checkerCodename,
      {
        adObjectsPage: page,
        adObjectsPerPage: perPage
      }
    )

    if (!args) {
      return
    }

    storeIndicatorDeviantObjects.reloadDeviantObjects(args)
  }

/**
 * Filters.
 */

/**
 *
 * When ignoring deviant object, save the status and reload the table.
 */
export const onDeviantObjectsShowIgnoredChange =
  (storeIoE: StoreIoE) =>
  (checkerCodename: GenericCheckerCodename) =>
  (checked: boolean) => {
    const { storeIndicatorDeviantObjects } = storeIoE.storeIndicator

    storeIndicatorDeviantObjects.setShowIgnoredStatus(checked)
    refreshDeviantObjects(storeIoE)(checkerCodename)()
  }

/**
 * Start a new search of deviant objects.
 */
export const onDeviantObjectsSearchSubmit =
  (storeIoE: StoreIoE) => (checkerCodename: GenericCheckerCodename) => () => {
    const { storeIndicatorDeviantObjects } = storeIoE.storeIndicator

    const args =
      storeIndicatorDeviantObjects.computeFetchDeviantObjectsArgs(
        checkerCodename
      )

    if (!args) {
      return
    }

    storeIndicatorDeviantObjects.searchDeviantObjects(args)
  }
