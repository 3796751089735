import { BladeContentLayoutDefault as BladeContentLayoutDefaultDeprecated } from '@app/components-legacy/Blade/BladeContent'
import BladeContentLayoutDefault from '@app/components-legacy/Blade/BladeContent/BladeContentLayoutDefault'
import {
  Drawer as DrawerDeprecated,
  DrawerContext as DrawerContextDeprecated,
  DrawerHorizontalContainer as DrawerHorizontalContainerDeprecated,
  DrawerTitle as DrawerTitleDeprecated
} from '@app/components-legacy/Drawer'
import { DrawerContext } from '@app/components-legacy/Drawer/context'
import Drawer from '@app/components-legacy/Drawer/Drawer'
import DrawerHorizontalContainer from '@app/components-legacy/Drawer/DrawerHorizontalContainer'
import DrawerTitle from '@app/components-legacy/Drawer/DrawerTitle'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canDeleteDashbard } from '../../permissions'
import DrawerDeleteDashboardForm from './DrawerDeleteDashboardForm'

interface IDrawerDeleteDashboardProps {}

const DrawerDeleteDashboard: React.FC<IDrawerDeleteDashboardProps> = () => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Components.LabelConfirm',
      'Dashboard.DeleteDashboardDrawer',
      'Dashboard.Common'
    ]
  })

  const { storeDashboards } = useStores()

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const { storeDrawerDeleteDashboard } =
    storeDashboards.storeDashboardsManagement

  const dashboadId = storeDrawerDeleteDashboard.getDataValue('dashboardId')

  return themeIsLegacy ? (
    <DrawerContextDeprecated.Provider
      value={{ storeDrawer: storeDrawerDeleteDashboard }}
    >
      <DrawerDeprecated>
        <DrawerHorizontalContainerDeprecated labelledBy="deleteDashboard">
          <BladeContentLayoutDefaultDeprecated
            layout={{
              name: 'default',
              title: (
                <DrawerTitleDeprecated>
                  {translate('Delete the dashboard')}
                </DrawerTitleDeprecated>
              ),
              content: <DrawerDeleteDashboardForm />,
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canDeleteDashbard(dashboadId)}
          />
        </DrawerHorizontalContainerDeprecated>
      </DrawerDeprecated>
    </DrawerContextDeprecated.Provider>
  ) : (
    <DrawerContext.Provider value={{ storeDrawer: storeDrawerDeleteDashboard }}>
      <Drawer>
        <DrawerHorizontalContainer labelledBy="deleteDashboard">
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: (
                <DrawerTitle>{translate('Delete the dashboard')}</DrawerTitle>
              ),
              content: <DrawerDeleteDashboardForm />,
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canDeleteDashbard(dashboadId)}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerDeleteDashboard)
