import { deltaE, hexToRgb } from '.'
import { consts } from '..'

/**
 * Compute the color of the label according to the color of the background.
 */
export function computeLabelColor(backgroundColor: string): string {
  return deltaE(
    hexToRgb(backgroundColor) || [0, 0, 0],
    hexToRgb(consts.colorWhite) || [0, 0, 0]
  ) > 25
    ? consts.colorWhite
    : consts.colorBlue015
}
