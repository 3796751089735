import type { StoreInputCrontab } from '@app/stores/helpers/StoreInputCrontab'
import { InputCrontabUnit } from '@app/stores/helpers/StoreInputCrontab/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ContainerFlex } from 'tenable-design-system-alt'
import SelectDaysOfTheWeek from '../Controls/SelectDaysOfTheWeek'
import { handleDaysOfTheWeekOnChange } from '../handlers'
import ConfigurationByDay from './ConfigurationByDay'

export interface IConfigurationByWeekProps {
  storeInputCrontab: StoreInputCrontab
}

export function ConfigurationByWeek(props: IConfigurationByWeekProps) {
  const daysOfTheWeek = JSON.parse(
    props.storeInputCrontab.storeForm.getFieldValueAsString(
      InputCrontabUnit.daysOfTheWeek
    )
  )

  return (
    <ContainerFlex
      name="ConfigurationByWeek"
      flexDirection="column"
      flexGap="spacingM"
    >
      <SelectDaysOfTheWeek
        days={daysOfTheWeek}
        onChange={handleDaysOfTheWeekOnChange(props.storeInputCrontab)}
      />
      <ConfigurationByDay storeInputCrontab={props.storeInputCrontab} />
    </ContainerFlex>
  )
}

export default observer(ConfigurationByWeek)
