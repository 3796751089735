import StoreRoot from '@app/stores/StoreRoot'
import { isDefined } from '@libs/isDefined'
import { IGlobalInventoryAppConfiguration } from 'tenable-cyber-asset-management/dist/common/src/libs/IframeCommunicationBus/types'
import { canAccessTenableCloud } from '../Identities/permissions'
import { ensureGIValidLanguage, navigateToPageUrl } from './helpers'
import { DeepPartial } from '@alsid/common'
import { deepMerge } from '@productive-codebases/toolbox'

export const getDefaultGlobalInventorySdkConfiguration = (
  storeRoot: StoreRoot
): IGlobalInventoryAppConfiguration => {
  const production = storeRoot.environment.config.production ? 'true' : 'false'
  const { whoAmI } = storeRoot.stores.storeAuthentication

  const userIdentifierForGridPrefix =
    [whoAmI?.id, whoAmI?.email].filter(isDefined).join('.') || 'anyone'

  const tenablePlatformOrigin = storeRoot.environment.tenablePlatformOrigin()
  const globalInventorySdkConfiguration: IGlobalInventoryAppConfiguration = {
    endpoint:
      '/w/assets/tenable-cyber-asset-management/globalinventory/index.html',
    initialPage: '/assetsOverview',
    preferences: {
      authentication: {
        mode: storeRoot.stores.storeRbac.isUserGrantedTo(canAccessTenableCloud)
          ? 'externalToken'
          : 'noToken',
        session: {
          containerUuid: 'unused',
          // used to prefix the persistent information of the grid
          uuid: userIdentifierForGridPrefix
        }
      },
      language: ensureGIValidLanguage(storeRoot.appTranslator.language),
      layout: {
        backToAssetsButtonUrl: {
          type: 'internal',
          routeName: 'AssetsOverview'
        },
        noPadding: 'true'
      },
      environment: {
        production,
        origin: '',
        tenablePlatformOrigin,
        // for proxying to Kapteyn server middleware to Tenable services
        servicesPrefixPath: '/w/tenable-gi-services-proxy'
      },
      featureFlags: {
        isApaAttackPathEnabled: 'true',
        isIdentityEntitlementsAesScoreEnabled: 'true',
        isDevicesTabForAccountsEnabled: 'true',
        isApaWeaknessInCamEnabled: 'true',
        't-one-asset-details-device-account-view': 'true'
      }
    },
    iframeStyle: {
      height: '100%',
      width: '100%',
      border: 'none',
      overflow: 'hidden',
      margin: 0,
      padding: 0
    },
    iframeMessageHandlers: {
      onExternalLinkClick: (_, iframeMessage) => {
        if (iframeMessage.selectedApplication) {
          // retro compat with TVM (doesn't know exactly how it is used)
          localStorage.setItem(
            'selectedApplication',
            iframeMessage.selectedApplication
          )
        }

        switch (iframeMessage.target) {
          // use the app router to handle _parent links
          case '_parent': {
            storeRoot.appRouter.history.push(iframeMessage.url)
            break
          }

          default: {
            window.open(iframeMessage.url, iframeMessage.target)
            break
          }
        }
      },
      /** This function navigates to a target page.
       * If the destination page is not a kapteyn page, we redirect the browser to the new specified url.
       * Otherwise, we navigate to a Kapteyn page.
       */
      onNavigateToPageUrl: (_, iframeMessage) => {
        navigateToPageUrl(storeRoot, iframeMessage.pathname)
      }
    }
  }

  const isDebug =
    storeRoot.environment.config.globalinventory?.debug?.enabled === true

  /**
   * Update the configuration for debug mode.
   * The debug mode is:
   * - Kapteyn started in development with mocks - npm run start:dev + npm run watch:mocks
   * - GI started in dev on qa-staging (or other environment) - npm run start:qa-staging
   *
   * You just need to set the debug configuration in the Kapteyn configuration file.
   * Example:
   *
   * globalinventory:
   *   debug:
   *     enabled: true
   *     irontoken: <YOUR-IRON-TOKEN-FOR-YOUR-ENV>
   */
  if (isDebug) {
    localStorage.setItem(
      'Iron.token',
      storeRoot.environment.config.globalinventory?.debug?.irontoken ?? ''
    )

    return deepMerge<
      IGlobalInventoryAppConfiguration,
      DeepPartial<IGlobalInventoryAppConfiguration>
    >([
      globalInventorySdkConfiguration,
      {
        // URL of the Vite server that host the iframe app
        endpoint: 'http://localhost:4010',
        preferences: {
          environment: {
            // we don't want to use the Kapteyn proxy here, we leverage on the GI's Vite server
            servicesPrefixPath: ''
          },
          authentication: {
            mode: 'ironToken'
          }
        }
      }
    ])
  }

  // clear potiental irontoken used during the debug
  localStorage.removeItem('Iron.token')

  return globalInventorySdkConfiguration
}
