import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  editApplicationSetting,
  editActivityLogs
} from '@libs/rbac/permissions'
import { canAccessToActivityLogsConfiguration } from '../permissions'

export const canSubmitActivityLogsConfiguration = rbacCapabilityCheckAllOf(
  canAccessToActivityLogsConfiguration,
  editApplicationSetting()
)

export const canDeleteUserTraces = rbacCapabilityCheckAllOf(
  canSubmitActivityLogsConfiguration,
  editActivityLogs()
)
