import {
  ContainerContent,
  ContainerFooter
} from '@app/components-legacy/Container'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import type { SecurityConfigurationFormFieldName } from '@app/stores/Management/StoreApplicationSettings/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onSecurityConfigurationSubmit } from './handlers'
import { canSubmitSecurityConfiguration } from './permissions'

interface IConfigurationSecurityFormProps {}

const ConfigurationSecurityForm: React.FC<
  IConfigurationSecurityFormProps
> = () => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Configuration.Navigation',
      'Management.System.Configuration.Security'
    ]
  })

  const { storeRbac, storeManagementApplicationSettings } = useStores()

  return (
    <FormWrapper
      name="securityConfiguration"
      onSubmit={onSecurityConfigurationSubmit(
        storeManagementApplicationSettings
      )}
    >
      <>
        <ContainerContent title={translate('Security')}>
          <WidgetForm<SecurityConfigurationFormFieldName>
            translate={translate}
            storeForm={
              storeManagementApplicationSettings.storeFormSecurityConfiguration
            }
          />
        </ContainerContent>

        <Portal name={PlaceHolderName.bladeFooter}>
          <ContainerFooter>
            <FormWrapperButtonSubmit
              loading={
                storeManagementApplicationSettings.storeFlagsAppSettingsSubmit
                  .flags.isLoading
              }
              disabled={
                !storeRbac.isUserGrantedTo(canSubmitSecurityConfiguration)
              }
            >
              {translate('Save')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </>
    </FormWrapper>
  )
}

export default observer(ConfigurationSecurityForm)
