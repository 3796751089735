import { ContainerFlex } from '@app/components/Container'
import { Label, LabelColorTag } from '@app/components/Labels'
import type { EntityUserLog } from '@app/entities'
import type { IApiCall } from '@app/entities/EntityUserLog'
import { IApiCallRestVerb } from '@app/entities/EntityUserLog'
import { consts } from '@app/styles'
import { colorWhite } from '@app/styles/consts'
import React from 'react'
import styled from 'styled-components'
import {
  colorActivityLogsLogAlternateBackgroundRedesign,
  ColorLogUserHttpVerb,
  colorUserLogInternalServerError,
  httpStatusErrorLabels
} from '../../consts'

interface IUserLogAPICallActionProps {
  style?: React.CSSProperties
  log: EntityUserLog
}

const StyledAction = styled(ContainerFlex)`
  border-radius: ${consts.borderRadiusRedesign};
  background-color: ${colorActivityLogsLogAlternateBackgroundRedesign};
  padding: ${consts.paddingVerySmall} ${consts.paddingDefault};
`

const StyledErrorMessage = styled(LabelColorTag)`
  margin-right: 0px; // Remove default right margin
`

const getColorForVerb = (verb: IApiCallRestVerb): string => {
  switch (verb) {
    case IApiCallRestVerb.GET:
      return ColorLogUserHttpVerb.blue
    case IApiCallRestVerb.PUT:
    case IApiCallRestVerb.PATCH:
      return ColorLogUserHttpVerb.orange
    case IApiCallRestVerb.DELETE:
      return ColorLogUserHttpVerb.red
    default:
      return ColorLogUserHttpVerb.green
  }
}

const getErrorMessage = (status: number): string => {
  return `[${status}] ${httpStatusErrorLabels.get(status) ?? 'API error'}`
}

export const UserLogAPICallAction: React.FC<
  IUserLogAPICallActionProps
> = props => {
  const { log: trace } = props
  const logAttributes = trace.decodedLogAttributes as IApiCall
  const isInError = logAttributes.status >= 400

  return (
    <StyledAction
      name="traceAction"
      className="traceAction"
      flexWrap="wrap"
      style={props.style}
      itemsFlexGrow={[0, 1, 0]}
      items={[
        <LabelColorTag
          label={logAttributes.verb}
          color={getColorForVerb(logAttributes.verb)}
          labelProperties={{
            color: consts.colorWhite,
            labelledBy: logAttributes.verb
          }}
        />,
        <Label label={logAttributes.route} labelledBy="routeName" />,
        isInError ? (
          <StyledErrorMessage
            label={getErrorMessage(logAttributes.status)}
            color={colorUserLogInternalServerError}
            labelProperties={{
              color: colorWhite,
              labelledBy: 'errorCallStatus'
            }}
          />
        ) : null
      ]}
    />
  )
}
