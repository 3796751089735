import { ContainerContent } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleIoABoardLoad } from '../IoABoardPage/handlers'
import { canAccessToIoABoard } from '../IoABoardPage/permissions'
import IoABoardCardsPDF from './IoABoardCardsPDF'
import IoABoardHeaderPDF from './IoABoardHeaderPDF'
import IoABoardInfrastructuresPDF from './IoABoardInfrastructuresPDF'

interface IIoABoardPagesPDFProps {}

const IoABoardPagesPDF: React.FC<IIoABoardPagesPDFProps> = () => {
  const { storeIoA } = useStores()

  return (
    <ContainerContent
      onLoad={handleIoABoardLoad(storeIoA.storeBoard)}
      rbacCapabilityCheck={canAccessToIoABoard}
    >
      <IoABoardHeaderPDF />
      <IoABoardInfrastructuresPDF />
      <IoABoardCardsPDF />
    </ContainerContent>
  )
}

export default observer(IoABoardPagesPDF)
