import type { PropertiesNullable } from '@@types/helpers'
import type {
  AdObject,
  Deviance,
  Directory,
  Event,
  Maybe,
  Profile,
  Reason
} from '@server/graphql/typeDefs/types'
import * as moment from 'moment'
import {
  createEntity,
  EntityAdObject,
  EntityDirectory,
  EntityEvent,
  EntityReason
} from './'
import EntityBase from './EntityBase'

export default class EntityDeviance
  extends EntityBase
  implements PropertiesNullable<Deviance>
{
  id: Maybe<number> = null
  createdEventId: Maybe<string> = null
  resolvedEventId: Maybe<string> = null
  devianceProviderId: Maybe<string> = null
  resolvedAt: Maybe<string> = null
  eventDate: Maybe<string> = null
  ignoreUntil: Maybe<string> = null
  checkerId: Maybe<number> = null
  directoryId: Maybe<number> = null
  profileId: Maybe<number> = null
  adObjectId: Maybe<number> = null
  reasonId: Maybe<number> = null

  directory: Maybe<Directory> = null
  event: Maybe<Event> = null
  profile: Maybe<Profile> = null
  adObject: Maybe<AdObject> = null
  reason: Maybe<Reason> = null

  constructor(data: Partial<Deviance>) {
    super()
    Object.assign(this, data)
  }

  getDirectory(): Maybe<EntityDirectory> {
    if (!this.directory) {
      return null
    }

    return createEntity<Directory, EntityDirectory>(
      EntityDirectory,
      this.directory
    )
  }

  getEvent(): Maybe<EntityEvent> {
    if (!this.event) {
      return null
    }

    return createEntity<Event, EntityEvent>(EntityEvent, this.event)
  }

  /**
   * When we are in a context of events (Trailing flow), return the deviance
   * of the related event.
   *
   * If not, return the adObject related to the deviance itself.
   */
  getAdObject(): Maybe<EntityAdObject> {
    const event = this.getEvent()

    if (event) {
      return event.getAdObject()
    }

    if (this.adObject) {
      return createEntity<AdObject, EntityAdObject>(
        EntityAdObject,
        this.adObject
      )
    }

    return null
  }

  getReason(): Maybe<EntityReason> {
    if (!this.reason) {
      return null
    }

    return createEntity<Reason, EntityReason>(EntityReason, this.reason)
  }

  /**
   * Return true if the current date is before than the "ignoreUntil" property value.
   */
  isIgnored(): boolean {
    if (!this.ignoreUntil) {
      return false
    }

    return moment().utc().isBefore(moment.utc(this.ignoreUntil))
  }
}
