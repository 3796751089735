import type { Maybe } from '@@types/helpers'
import { LabelSliced } from '@app/components/Labels'
import { TooltipDate } from '@app/components-legacy/Tooltip'
import { useTestAttribute } from '@app/hooks'
import type { IFormatDateOptions } from '@libs/dates/formatDate'
import { formatDate } from '@libs/dates/formatDate'
import { upperFirst } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'

export interface ILabelDateProps {
  className?: string
  labelledBy?: string
  date: Maybe<Date | string>
  inline?: boolean
  formatDateOptions?: IFormatDateOptions
  noTooltip?: boolean
  maxWidth?: number
}

const LabelDate: React.FC<ILabelDateProps> = props => {
  const { testAttributeProps } = useTestAttribute('contentinfo')

  if (!props.date) {
    return null
  }

  const getDate = (): string => {
    if (!props.date) {
      return ''
    }

    return upperFirst(
      formatDate(props.date, {
        ...props.formatDateOptions
      })
    )
  }

  const renderDate = () => {
    if (props.maxWidth) {
      return (
        <span>
          <LabelSliced
            labelledBy={props.labelledBy}
            value={getDate()}
            maxWidth={props.maxWidth}
            popoverType="none"
          />
        </span>
      )
    }

    if (props.inline) {
      return (
        <span
          className={props.className}
          {...testAttributeProps()(props.labelledBy)}
        >
          {getDate()}
        </span>
      )
    }

    return (
      <div
        className={props.className}
        {...testAttributeProps()(props.labelledBy)}
      >
        {getDate()}
      </div>
    )
  }

  return !props.noTooltip ? (
    <TooltipDate date={props.date} formatDateOptions={props.formatDateOptions}>
      {renderDate()}
    </TooltipDate>
  ) : (
    renderDate()
  )
}

export default styled(LabelDate)`
  white-space: nowrap;
`
