import type { Maybe } from '@@types/helpers'
import type { StoreInfrastructures } from '@app/stores'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '../../Container'
import { buildInfrasDirsMapping } from './buildInfrasDirsMapping'
import InfrastructureDirectories from './InfrastructureDirectories'

export interface ILabelDirectoriesTreeProps {
  className?: string
  directoryIds: number[]
  direction?: 'row' | 'column'
  storeInfrastructures: StoreInfrastructures
  buildInfrastructureUrlFn?: (id: Maybe<number>) => string
  buildDirectoryUrlFn?: (id: Maybe<number>) => string
  onInfrastructureClick?: (
    id: Maybe<number>
  ) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  onDirectoryClick?: (
    id: Maybe<number>
  ) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const InfrastructureDirectoriesStyled = styled(InfrastructureDirectories)<{
  direction: ILabelDirectoriesTreeProps['direction']
}>`
  margin-bottom: ${props =>
    props.direction === 'row' ? consts.marginDefault : 0};
`

const LabelDirectoriesTree: React.FC<ILabelDirectoriesTreeProps> = props => {
  const infrasDirsMapping = React.useMemo(() => {
    return buildInfrasDirsMapping(props.storeInfrastructures)(
      props.directoryIds
    )
  }, [])

  const renderInfrastructuresDirectories = () => {
    return Object.keys(infrasDirsMapping).map(infrastructureName => {
      return (
        <InfrastructureDirectoriesStyled
          direction={props.direction}
          infrastructureId={
            infrasDirsMapping[infrastructureName].infrastructure.id
          }
          infrastructureName={infrastructureName}
          directories={infrasDirsMapping[infrastructureName].directories}
          buildInfrastructureUrlFn={props.buildInfrastructureUrlFn}
          buildDirectoryUrlFn={props.buildDirectoryUrlFn}
          onInfrastructureClick={props.onInfrastructureClick}
          onDirectoryClick={props.onDirectoryClick}
        />
      )
    })
  }

  if (props.direction === 'row') {
    return (
      <ContainerFlex
        name="LabelDirectoriesTree"
        flexWrap="wrap"
        items={renderInfrastructuresDirectories()}
        spaced
        spaceWidth="large"
      />
    )
  }

  return (
    <ContainerFlex
      name="LabelDirectoriesTree"
      direction="column"
      items={renderInfrastructuresDirectories()}
      spaced
      spaceWidth="small"
    />
  )
}

export default LabelDirectoriesTree
