import type { EntityTopologyDirectory } from '@app/entities'
import { useStores } from '@app/hooks/useStores'
import { isCSSSupports } from '@app/styles/helpers'
import { ensureArray } from '@libs/ensureArray'
import { isDefined } from '@libs/isDefined'
import { TrustHazardLevel } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import type SceneObject from './SceneObject'
import Trust from './Trust'
import type { TrustType } from './types'

interface ITrustsProps {
  directorySceneObjects: Array<SceneObject<EntityTopologyDirectory>>
  renderOnly: TrustType
}

const Trusts: React.FC<ITrustsProps> = props => {
  const { storeTopology } = useStores()

  if (!storeTopology.topologyEntity) {
    return null
  }

  const isSvgDominantBaselineSupported = isCSSSupports(
    'dominant-baseline',
    'central'
  )

  return (
    <g data-name="Trusts">
      {storeTopology.topologyEntity
        .getUniqTrusts()
        .map(trust => {
          const fromDirectory = props.directorySceneObjects.find(
            directory => trust.from === directory.object.uid
          )

          const toDirectory = props.directorySceneObjects.find(
            directory => trust.to === directory.object.uid
          )

          if (!fromDirectory || !toDirectory) {
            return null
          }

          if (!isDefined(trust.hazardLevel)) {
            return null
          }

          const isInternal =
            fromDirectory.object.uidInfrastructure ===
            toDirectory.object.uidInfrastructure

          if (isInternal && !storeTopology.isShowingInternalTrusts) {
            return null
          }

          if (props.renderOnly === 'external' && isInternal) {
            return null
          }

          if (props.renderOnly === 'internal' && !isInternal) {
            return null
          }

          // FIXME: I see this function a lot of time, you could extract it to a function and pass a to and from object
          const correlatedTrust = storeTopology.topologyEntity
            .getTrusts()
            .find(
              t =>
                t.from === toDirectory.object.uid &&
                t.to === fromDirectory.object.uid &&
                t.hazardLevel === trust.hazardLevel
            )

          // When there are two arrows in the same direction but with different
          // hazard levels, add an offset to the lower hazard.
          const applyOffset = !!storeTopology.topologyEntity
            .getTrusts()
            .find(
              t =>
                t.from === fromDirectory.object.uid &&
                t.to === toDirectory.object.uid &&
                t.hazardLevel !== trust.hazardLevel &&
                (trust.hazardLevel === TrustHazardLevel.Regular ||
                  (trust.hazardLevel === TrustHazardLevel.Unknown &&
                    t.hazardLevel === TrustHazardLevel.Dangerous))
            )

          return (
            <Trust
              key={`trust-key-${fromDirectory.object.uid}-${toDirectory.object.uid}-${trust.hazardLevel}`}
              uid={trust.computeUid(
                fromDirectory,
                toDirectory,
                trust.hazardLevel
              )}
              drawString={trust.computeDrawString(
                fromDirectory,
                toDirectory,
                isInternal,
                applyOffset
              )}
              hazardLevel={trust.hazardLevel}
              attributes={ensureArray(trust.attributes)}
              isInternal={isInternal}
              correlatedTrust={correlatedTrust || null}
              directorySceneObjects={{
                from: fromDirectory,
                to: toDirectory
              }}
              isSvgDominantBaselineSupported={isSvgDominantBaselineSupported}
            />
          )
        })
        .filter(isDefined)}
    </g>
  )
}

export default observer(Trusts)
