import ProductVersion from '@app/pages/Auth/ProductVersion'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '../Container'

const StyledContainerFlex = styled(ContainerFlex)`
  margin: auto;
  width: 100%; // to center content on IE11
`

interface ILayoutAuthProps {
  className?: string
  content: React.ReactNode
}

const LayoutAuth: React.FC<ILayoutAuthProps> = props => {
  return (
    <>
      <ContainerFlex
        name="LayoutAuth"
        className={props.className}
        justifyContent="center"
        items={[
          <StyledContainerFlex
            name="LayoutAuthVerticalAlign"
            direction="column"
            justifyContent="center"
            alignItems="center"
            items={[props.content]}
          />
        ]}
        fullHeight
      />

      <ProductVersion />
    </>
  )
}

export default styled(LayoutAuth)`
  background-color: ${consts.colorLoginLayoutBackground};
  overflow: auto;
`
