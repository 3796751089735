import { LabelColorTag } from '@app/components/Labels'
import type { IProfileCreationOrDeletionOrUnstaging } from '@app/entities/EntityUserLog'
import React from 'react'

const UserLogProfile = (props: IProfileCreationOrDeletionOrUnstaging) => {
  const { profileId, profileName } = props
  if (!profileId || !profileName) {
    return null
  }

  return (
    <LabelColorTag
      labelProperties={{
        labelledBy: 'profile-name'
      }}
    >
      {profileName}
    </LabelColorTag>
  )
}

export default UserLogProfile
