import type {
  StoreManagementApplicationSettings,
  StoreMessages,
  StoreActivityLogs
} from '@app/stores'
import { ActivityLogsConfigurationFormFieldName } from '@app/stores/Management/StoreApplicationSettings/types'
import type { TranslateFn } from '@libs/i18n'
import type { InputUpdateApplicationSettings } from '@server/graphql/typeDefs/types'

export const onActivityLogsConfigurationLoad =
  (storeManagementApplicationSettings: StoreManagementApplicationSettings) =>
  () => {
    storeManagementApplicationSettings.fetchApplicationSettings().then(() => {
      const applicationSettings =
        storeManagementApplicationSettings.applicationSettings

      if (!applicationSettings) {
        return
      }

      storeManagementApplicationSettings.storeFormActivityLogsConfiguration
        .setDefaultFieldsValues([
          {
            key: ActivityLogsConfigurationFormFieldName.retentionDurationInMonth,
            value: applicationSettings.getPropertyAsNumber(
              'activityLogsRetentionDurationInMonth'
            )
          },
          {
            key: ActivityLogsConfigurationFormFieldName.isActive,
            value: applicationSettings.getPropertyAsBoolean(
              'activityLogsIsActive'
            )
          }
        ])
        .reset()
    })
  }

export const onActivityLogsConfigurationUnload =
  (storeManagementApplicationSettings: StoreManagementApplicationSettings) =>
  () => {
    storeManagementApplicationSettings.storeFormActivityLogsConfiguration.reset()
  }

/**
 * Save ActivityLogs configuration.
 */
export const onActivityLogsConfigurationSubmit =
  (storeManagementApplicationSettings: StoreManagementApplicationSettings) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeFormActivityLogsConfiguration } =
      storeManagementApplicationSettings

    if (!storeFormActivityLogsConfiguration.validate()) {
      storeFormActivityLogsConfiguration.storeRoot.stores.storeMessages.validationError()
      return
    }

    const currentApplicationSettings =
      storeManagementApplicationSettings.applicationSettings

    if (!currentApplicationSettings) {
      return
    }

    const applicationSettings: InputUpdateApplicationSettings = {
      activityLogsIsActive:
        storeFormActivityLogsConfiguration.getFieldValueAsBoolean(
          ActivityLogsConfigurationFormFieldName.isActive
        ),
      activityLogsRetentionDurationInMonth:
        storeFormActivityLogsConfiguration.getFieldValueAsNumber(
          ActivityLogsConfigurationFormFieldName.retentionDurationInMonth
        )
    }

    storeManagementApplicationSettings
      .updateApplicationSettings(applicationSettings)
      .catch(() => {
        // already catched in the store
      })
  }

export const handleActivityLogsDeleteAllOnClick =
  (storeActivityLogs: StoreActivityLogs) => (): void => {
    storeActivityLogs.storeModalConfirmDeleteTraces.show()
  }

/**
 * Confirm the delete of search history.
 */
export const onDeleteModalConfirmClick =
  (storeActivityLogs: StoreActivityLogs, storeMessages: StoreMessages) =>
  (translate: TranslateFn) =>
  async () => {
    storeActivityLogs.storeModalConfirmDeleteTraces.hide()
    await storeActivityLogs.deleteAllUserTraces()

    // Display a success message to the user
    storeMessages.success(translate('Logs have been successfully cleared'), {
      labelledBy: 'deleteUserTracesSuccessMessage'
    })
  }

/**
 * Cancel the delete and close the modal.
 */
export const onCancelModalConfirmClick =
  (storeActivityLogs: StoreActivityLogs) => (): void => {
    storeActivityLogs.storeModalConfirmDeleteTraces.hide()
  }
