import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { isRoleReadOnly } from '@app/stores/Management/Rbac/functions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditRole, canReadRole } from '../permissions'
import { handleEditRoleOnLoad, handleEditRoleOnUnload } from './handlers'
import RoleEditForm from './RoleEditForm'
import RoleEditMenu from './RoleEditMenu'

interface IRolesEditBladeProps {}

const RolesEditBlade: React.FC<IRolesEditBladeProps> = () => {
  const appRouter = useAppRouter()

  const { storeManagementRbacRoles, storeRbac } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_Accounts_Roles_Edit,
    parameters: {
      roleId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  const isReadableOnly = isRoleReadOnly(parameters.roleId, storeRbac)
  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_Accounts_Roles_Edit,
        parameters: {
          roleId: parameters.roleId
        }
      }}
      onLoad={handleEditRoleOnLoad(storeManagementRbacRoles)(parameters.roleId)}
      onUnload={handleEditRoleOnUnload(storeManagementRbacRoles)}
      flags={[
        storeManagementRbacRoles.storeFetchRbacRolesFlags.flags,
        storeManagementRbacRoles.storeFetchRbacEntitiesFlags.flags
      ]}
      layout={{
        name: 'default',
        header: <PortalPlaceHolder name={PlaceHolderName.bladeHeader} />,
        menu: <RoleEditMenu />,
        content: <RoleEditForm />,
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={
        isReadableOnly
          ? canReadRole(parameters.roleId)
          : canEditRole(parameters.roleId)
      }
    />
  )
}

export default observer(RolesEditBlade)
