import { LabelDirectory } from '@app/components-legacy/Label'
import type { EntityEvent } from '@app/entities'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import CellEmpty from './CellEmpty'

export interface ICellDirectoryProps {
  event: EntityEvent
}

const CellDirectory: React.FC<ICellDirectoryProps> = props => {
  const { storeInfrastructures } = useStores()

  const getCellContent = () => {
    const directory = props.event.directory

    if (!directory) {
      return <CellEmpty />
    }

    return (
      <LabelDirectory
        label={directory.name}
        color={storeInfrastructures.getDirectoryColor(directory.id)}
      />
    )
  }

  return <div className="eventDirectory">{getCellContent()}</div>
}

export default CellDirectory
