import {
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { ButtonSize } from '@app/components-legacy/Form/Wrappers/types'
import { handleInputCheckersDrawerOnSubmit } from '@app/components-legacy/Input/InputCheckers/handlers'
import { LabelAlt } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreDrawer from '@app/stores/helpers/StoreDrawer'
import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import CheckersSelectionCounter from './CheckersSelectionCounter'

interface IDrawerFooterProps<GC extends TGenericChecker> {
  onValidate?: () => void
  storeDrawer: StoreDrawer<any>
  storePendingInputGenericCheckers: StoreInputGenericCheckers<GC>
}

function DrawerFooter<GC extends TGenericChecker>(
  props: IDrawerFooterProps<GC>
): React.ReactElement {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Components.InputCheckers']
  })

  const enableSubmit =
    props.storePendingInputGenericCheckers.hasSelectedCheckers

  return (
    <ContainerFooter>
      <DrawerCancelButton />

      <ContainerFlex
        name="DrawerFooterRight"
        alignItems="center"
        items={[
          <LabelAlt variant={LabelAltVariant.Minor}>
            <CheckersSelectionCounter
              storeInputGenericCheckers={props.storePendingInputGenericCheckers}
            />
          </LabelAlt>,

          <FormWrapperButton
            labelledBy="submit"
            pendoSuffix="drawer"
            size={ButtonSize.large}
            buttonProps={{
              type: 'primary',
              disabled: !enableSubmit,
              onClick: handleInputCheckersDrawerOnSubmit(props.storeDrawer)(
                props.onValidate
              )
            }}
          >
            {translate('Submit selection')}
          </FormWrapperButton>
        ]}
        spaced
        spaceWidth="small"
      />
    </ContainerFooter>
  )
}

export default observer(DrawerFooter)
