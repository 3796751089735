import { ContainerContent } from '@app/components/Container'
import { ErrorMessage } from '@app/components/Error'
import SpinnerInline, {
  SpinnerInlineSize
} from '@app/components-legacy/Spinner/SpinnerInline'
import type StoreInputHealthChecks from '@app/stores/StoreInputHealthChecks'
import type { BaseButtonProps } from 'antd/lib/button/button'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleInputHealthCheckNamesOnLoad } from './handlers'
import InputHealthChecksButton from './InputHealthChecksButton'

interface IHealthChecksProps {
  className?: string
  style?: React.CSSProperties
  size?: BaseButtonProps['size']
  loadAtMount?: boolean
  /**
   * Display the health checks grouped by health check types.
   */
  withExpand?: boolean
  onSubmit?: () => void
  storeInputHealthChecks: StoreInputHealthChecks
}

const InputHealthChecks: React.FC<IHealthChecksProps> = props => {
  if (
    props.storeInputHealthChecks.storeFlagsFetchHealthChecksTemplatesNames
      .isLoading
  ) {
    return <SpinnerInline size={SpinnerInlineSize.small} />
  }

  if (
    props.storeInputHealthChecks.storeFlagsFetchHealthChecksTemplatesNames
      .isError
  ) {
    return <ErrorMessage />
  }

  return (
    <ContainerContent
      name="InputHealthChecks"
      onLoad={handleInputHealthCheckNamesOnLoad(props.storeInputHealthChecks)(
        props.loadAtMount
      )}
    >
      <InputHealthChecksButton
        size={props.size}
        withExpand={props.withExpand}
        onSubmit={props.onSubmit}
        storeInputHealthChecks={props.storeInputHealthChecks}
      />
    </ContainerContent>
  )
}

export default observer(InputHealthChecks)
