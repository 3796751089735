import { ContainerFlex } from '@app/components/Container'
import { IconCloseSmall } from '@app/components/Icon'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useAppTranslator } from '@app/hooks'
import { TenableBordersColor } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

const StyledFormWrapperButton = styled(FormWrapperButton)`
  margin-right: -8px;
  margin-top: -2px;
`

const StyledContainerSettingsHeader = styled(ContainerFlex)`
  padding: 15px;
`

interface IWidgetSettingsHeaderProps {
  className?: string
  hidePopover: () => void
}

const WidgetSettingsHeader: React.FC<IWidgetSettingsHeaderProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Layout.SettingsMenu'] })

  return (
    <ContainerFlex
      name="WidgetSettingsHeader"
      className={props.className}
      direction="column"
      items={[
        <StyledContainerSettingsHeader
          name="WidgetSettingsHeaderTop"
          justifyContent="space-between"
          items={[
            <Label variant={LabelVariant.tenableH3} labelledBy="settings">
              {translate('Settings')}
            </Label>,

            <StyledFormWrapperButton
              labelledBy="close"
              variant={ButtonVariant.colored}
              size={ButtonSize.small}
              icon={IconCloseSmall}
              iconSize={16}
              buttonProps={{
                onClick: props.hidePopover
              }}
            />
          ]}
        />
      ]}
      spaced
      spaceWidth="smaller"
    />
  )
}

export default styled(WidgetSettingsHeader)`
  border-bottom: 1px solid ${TenableBordersColor.borderBase};
`
