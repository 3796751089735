import { ContainerFlex } from '@app/components/Container'
import { Label, LabelSliced } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { IDENTIFIER_COLUMN_NAME } from '@app/components-legacy/TableNew/consts'
import { useDataFocusMethodAttribute } from '@app/hooks'
import { BrandColorV2 } from '@app/styles/consts'
import { isDefined } from '@libs/isDefined'
import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import TableCell from './TableCell'
import type {
  TableColumnsProperties,
  TableInterfaceType,
  TableRowData
} from './types'

const StyledRowContainerFlex = styled(ContainerFlex)`
  padding: ${props => props.theme.tokens['table/padding/row']};
`

const StyledLink = styled(Link)`
  :hover,
  :focus:not([data-focus-method='mouse']) {
    text-decoration: none;
    background-color: ${BrandColorV2.decorativeGray} !important;
  }
`

interface ITableRowProps<T extends TableInterfaceType> {
  labelledBy: string
  columns: TableColumnsProperties<T>
  availableWidth: number
  totalRelativeWidth: number
  rowLinkTo?: (rowData: TableRowData<T>) => string
  row: TableRowData<T>
}

export function TableRow<T extends TableInterfaceType>(
  props: ITableRowProps<T>
) {
  const dataFocusMethodAttributeProps =
    useDataFocusMethodAttribute<HTMLAnchorElement>()

  const rowElement = (
    <StyledRowContainerFlex
      name="TableBodyRow"
      ariaRoles={['table', 'row']}
      labelledBy={`${props.labelledBy}/${props.row[IDENTIFIER_COLUMN_NAME]}`}
      direction="row"
      alignItems="center"
      items={Object.keys(props.columns).map(columnName => {
        if (columnName === IDENTIFIER_COLUMN_NAME) {
          return null
        }

        const column = props.columns[columnName]

        if (column.hidden) {
          return null
        }

        const relativeWidth = !isDefined(column.fixedWidth)
          ? column.relativeWidth ?? 1
          : undefined

        const cellAvailableWidth =
          column.fixedWidth ??
          (props.availableWidth * (column.relativeWidth ?? 1)) /
            props.totalRelativeWidth

        return (
          <TableCell
            labelledBy={`${props.labelledBy}/body/${columnName}/${props.row[IDENTIFIER_COLUMN_NAME]}`}
            height={40}
            fixedWidth={column.fixedWidth}
            relativeWidth={
              relativeWidth && relativeWidth / props.totalRelativeWidth
            }
            key={column.name}
            justifyContent={column.justifyContent}
          >
            {column.render ? (
              column.render(props.row[columnName], cellAvailableWidth)
            ) : (
              <Label variant={LabelVariant.p}>
                {typeof props.row[columnName] === 'string' ? (
                  <LabelSliced
                    value={props.row[columnName]}
                    maxWidth={cellAvailableWidth}
                  />
                ) : (
                  props.row[columnName]
                )}
              </Label>
            )}
          </TableCell>
        )
      })}
      spaced
    />
  )
  return props.rowLinkTo ? (
    <StyledLink
      to={props.rowLinkTo(props.row)}
      {...dataFocusMethodAttributeProps}
    >
      {rowElement}
    </StyledLink>
  ) : (
    rowElement
  )
}
