import { FormWrapperCheckbox } from '@app/components-legacy/Form'
import { LabelNonBreakable } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import type { RbacAction, RbacEntityName } from '@server/graphql/typeDefs/types'
import { Tooltip } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import { onSetAllPermissionsCheckboxClick } from './handlers'

interface ICheckboxByDefaultProps {
  className?: string
  rbacEntityName: RbacEntityName
  rbacAction: RbacAction
  checked?: boolean
  disabled: boolean
}

const CheckboxByDefault: React.FC<ICheckboxByDefaultProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Roles.Permissions']
  })

  const { storeManagementRbacRoles } = useStores()

  return (
    <LabelNonBreakable>
      <FormWrapperCheckbox
        labelledBy={`${props.rbacAction}-${props.rbacEntityName}`}
        checkboxProps={{
          className: props.className,
          disabled: props.disabled,
          onChange: onSetAllPermissionsCheckboxClick(storeManagementRbacRoles)(
            props.rbacEntityName,
            props.rbacAction
          ),
          checked: props.checked === true
        }}
      >
        <Tooltip
          title={translate('Grant automatically entities that will be created')}
        >
          <>{translate('By default')}</>
        </Tooltip>
      </FormWrapperCheckbox>
    </LabelNonBreakable>
  )
}

export default styled(CheckboxByDefault)`
  font-size: 11px;
  font-weight: normal;
`
