import Modal from '@app/components-legacy/Modal/Modal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import type { StoreFeatureFlags } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ContainerFooter, ContainerHTMLContent } from '../Container'
import { FormWrapperButton } from '../Form'

interface IModalFeatureFlagUpdateProps {}

const onConfirmClick = (storeFeatureToggles: StoreFeatureFlags) => () => {
  storeFeatureToggles.storeModalFeatureFlagUpdate.hide()
}

const ModalFeatureFlagUpdate: React.FC<
  IModalFeatureFlagUpdateProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Components.ModalFeatureToggleUpdate']
  })
  const { storeFeatureFlags, storeRoot } = useStores()

  return (
    <Modal
      modalProps={{
        title: translate('Your platform will restart shortly'),
        footer: (
          <ContainerFooter variant="modal">
            <FormWrapperButton
              labelledBy="confirm"
              buttonProps={{
                onClick: onConfirmClick(storeFeatureFlags)
              }}
            >
              {translate('OK')}
            </FormWrapperButton>
          </ContainerFooter>
        )
      }}
      storeModal={storeFeatureFlags.storeModalFeatureFlagUpdate}
    >
      <ContainerHTMLContent padded>
        {translate('Your environment will reboot', {
          transformMarkdown: true,
          interpolations: {
            timeout: storeFeatureFlags.timeoutInSeconds
          }
        })}
      </ContainerHTMLContent>
    </Modal>
  )
}

export default observer(ModalFeatureFlagUpdate)
