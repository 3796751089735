import { ContainerForm } from '@app/components/Container'
import type { IField } from '@app/components/Container/ContainerForm/types'
import { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import { FormWrapperSelect } from '@app/components-legacy/Form'
import type { EntityProfile, EntityRbacRole } from '@app/entities'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import type StoreForm from '@app/stores/helpers/StoreForm'
import {
  onInputChange,
  onSelectChange
} from '@app/stores/helpers/StoreForm/handlers'
import { LdapConfigurationGroupFieldName } from '@app/stores/Management/StoreLDAPConfiguration/types'
import { filterFalsies } from '@libs/filterFalsies'
import { isDefined } from '@libs/isDefined'
import type { Maybe } from '@server/graphql/typeDefs/types'
import { AuthProviderName } from '@server/graphql/typeDefs/types'
import { Input } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canSelectProfile } from './permissions'

interface IConfigurationGroupsFormProps {
  userProvider: AuthProviderName
  profiles: EntityProfile[]
  rbacRoles: EntityRbacRole[]
  storeForm: StoreForm<any>
}

const ConfigurationGroupsForm: React.FC<
  IConfigurationGroupsFormProps
> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Management.System.Configuration.LDAP',
      'Management.System.Configuration.SAML'
    ]
  })

  const { storeRbac } = useStores()

  /**
   * Return the profileId is granted to read it.
   * It avoid to have selection options like "42" because the user is not granted
   * to read the profile "42".
   */
  const getSelectedProfileId = (): Maybe<string> => {
    const selectedProfileId = props.storeForm.getFieldValueAsString(
      LdapConfigurationGroupFieldName.defaultProfileId
    )

    if (!selectedProfileId) {
      return null
    }

    return storeRbac.isUserGrantedTo(
      canSelectProfile(Number(selectedProfileId))
    )
      ? selectedProfileId
      : null
  }

  /**
   * Return the granted selected roles ids.
   * It avoid to have selection options like "42" because the user is not granted
   * to read the role "42".
   */
  const getSelectedRolesIds = (): string[] => {
    const grantedRolesIds = props.rbacRoles
      .map(rbacRole => rbacRole.id)
      .filter(isDefined)

    const selectedRolesIds = props.storeForm
      .getFieldValueForSelectMultiple(
        LdapConfigurationGroupFieldName.defaultRoleIds
      )
      .map(id => Number(id))
      // remove ids not present in grantedRolesIds
      .filter(id => grantedRolesIds.indexOf(id) !== -1)
      .map(id => String(id))

    return selectedRolesIds
  }

  const serieFields: IField[] = filterFalsies([
    props.userProvider !== AuthProviderName.Tenable && {
      name: LdapConfigurationGroupFieldName.groupName,
      label:
        props.userProvider === AuthProviderName.Ldap
          ? translate('LDAP group name')
          : translate('SAML group name'),
      errors: props.storeForm.field(LdapConfigurationGroupFieldName.groupName)
        .errors,
      required: true,
      control: (
        <Input
          data-name="string"
          placeholder={
            props.userProvider === AuthProviderName.Ldap
              ? 'DC=mydomain,DC=corp'
              : ''
          }
          name={LdapConfigurationGroupFieldName.groupName}
          value={props.storeForm.getFieldValueAsString(
            LdapConfigurationGroupFieldName.groupName
          )}
          onChange={onInputChange(props.storeForm)(
            LdapConfigurationGroupFieldName.groupName
          )}
        />
      )
    },
    {
      name: LdapConfigurationGroupFieldName.defaultProfileId,
      label: translate('Default profile'),
      errors: props.storeForm.field(
        LdapConfigurationGroupFieldName.defaultProfileId
      ).errors,
      required: true,
      control: (
        <FormWrapperSelect<any>
          labelledBy="defaultProfile"
          selectProps={{
            placeholder: translate('Default profile'),
            value: getSelectedProfileId() || '',
            onChange: onSelectChange(props.storeForm)(
              LdapConfigurationGroupFieldName.defaultProfileId
            )
          }}
          selectOptions={props.profiles.map(profile => {
            return {
              label: profile.getPropertyAsString('name'),
              value: profile.getPropertyAsString('id'),
              labelledBy: `${profile.getPropertyAsString(
                'name'
              )}-${profile.getPropertyAsString('id')}`
            }
          })}
        />
      )
    },
    {
      name: LdapConfigurationGroupFieldName.defaultRoleIds,
      label: translate('Default roles'),
      errors: props.storeForm.field(
        LdapConfigurationGroupFieldName.defaultRoleIds
      ).errors,
      required: true,
      control: (
        <FormWrapperSelect<any>
          labelledBy="defaultRoles"
          selectProps={{
            mode: 'multiple',
            value: getSelectedRolesIds(),
            onChange: onSelectChange(props.storeForm)(
              LdapConfigurationGroupFieldName.defaultRoleIds
            )
          }}
          selectOptions={props.rbacRoles.map(role => {
            return {
              label: role.getPropertyAsString('name'),
              value: role.getPropertyAsString('id'),
              labelledBy: `${role.getPropertyAsString(
                'name'
              )}-${role.getPropertyAsString('id')}`
            }
          })}
        />
      )
    }
  ])

  return (
    <ContainerForm fields={serieFields} variant={ContainerFormVariant.drawer} />
  )
}

export default observer(ConfigurationGroupsForm)
