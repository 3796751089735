import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditProfiles } from '../ProfilesPage/permissions'
import {
  handleProfileCheckersOnLoad,
  handleProfileCheckersOnUnload
} from './handlers'
import ProfileEditForm from './ProfileEditForm'
import ProfileMenu from './ProfileMenu'

interface IProfileEditProps {}

const ProfileEdit: React.FC<IProfileEditProps> = () => {
  const appRouter = useAppRouter()

  const { storeManagementProfiles } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_Accounts_Profiles_Edit,
    parameters: {
      profileId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  const profile = storeManagementProfiles.profiles.get(parameters.profileId)
  const isTenableProfile = Boolean(profile && profile.isTenableProfile())

  return (
    <>
      <Blade
        routeDefinition={{
          routeName: AppRouteName.Management_Accounts_Profiles_Edit,
          parameters: {
            profileId: parameters.profileId
          }
        }}
        onLoad={handleProfileCheckersOnLoad(
          storeManagementProfiles,
          parameters.profileId
        )}
        onUnload={handleProfileCheckersOnUnload(storeManagementProfiles)}
        flags={[
          // load profiles
          storeManagementProfiles.storeFlagsFetchProfiles.flags,
          // load generic checkers
          storeManagementProfiles.storeProfileCheckersExposure
            .storeFlagsFetchCheckers.flags,
          storeManagementProfiles.storeProfileCheckersAttack
            .storeFlagsFetchCheckers.flags
        ]}
        layout={{
          name: 'default',
          header: <PortalPlaceHolder name={PlaceHolderName.bladeHeader} />,
          menu: <ProfileMenu profileId={parameters.profileId} />,
          content: <ProfileEditForm />,
          footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
        }}
        rbacCapabilityCheck={canEditProfiles(
          isTenableProfile,
          parameters.profileId
        )}
      />
    </>
  )
}

export default observer(ProfileEdit)
