import type { StoreManagementAttackTypeConfiguration } from '@app/stores'

export const handleDirectoryForOneAttackTypeOnChange =
  (
    storeManagementAttackTypeConfiguration: StoreManagementAttackTypeConfiguration
  ) =>
  (directoryId: number, attackTypeId: number) =>
  () => {
    storeManagementAttackTypeConfiguration.toggleDirectoryForAnAttackSelection(
      directoryId,
      attackTypeId
    )
  }

export const handleDirectoryForAllAttacksOnChange =
  (
    storeManagementAttackTypeConfiguration: StoreManagementAttackTypeConfiguration
  ) =>
  (directoryId: number) =>
  () => {
    storeManagementAttackTypeConfiguration.toggleDirectoryForAllAttacksSelection(
      directoryId
    )
  }

export const handleInfrastructureForOneAttackOnChange =
  (
    storeManagementAttackTypeConfiguration: StoreManagementAttackTypeConfiguration
  ) =>
  (infrastructureId: number, attackTypeId: number) =>
  () => {
    storeManagementAttackTypeConfiguration.toggleInfrastructureForOneAttackSelection(
      infrastructureId,
      attackTypeId
    )
  }

export const handleInfrastructureForAllAttacksOnChange =
  (
    storeManagementAttackTypeConfiguration: StoreManagementAttackTypeConfiguration
  ) =>
  (infrastructureId: number) =>
  () => {
    storeManagementAttackTypeConfiguration.toggleInfrastructureForAllAttacksSelection(
      infrastructureId
    )
  }

export const handleAttackTypeForAllDirectoriesOnChange =
  (
    storeManagementAttackTypeConfiguration: StoreManagementAttackTypeConfiguration
  ) =>
  (attackTypeId: number) =>
  () => {
    storeManagementAttackTypeConfiguration.toggleAttackTypeForAllDirectoriesSelection(
      attackTypeId
    )
  }

export const handleInfrastructureCollapseOrExpand =
  (
    storeManagementAttackTypeConfiguration: StoreManagementAttackTypeConfiguration
  ) =>
  (infrastructureId: number) =>
  () => {
    const { infrastructuresCollapsed } = storeManagementAttackTypeConfiguration

    if (infrastructuresCollapsed.get(infrastructureId)) {
      infrastructuresCollapsed.set(
        infrastructureId,
        !infrastructuresCollapsed.get(infrastructureId)
      )

      storeManagementAttackTypeConfiguration.setInfrastructuresCollapsed(
        infrastructuresCollapsed
      )

      return
    }

    infrastructuresCollapsed.set(infrastructureId, true)
    storeManagementAttackTypeConfiguration.setInfrastructuresCollapsed(
      infrastructuresCollapsed
    )
  }
