import { ContainerFlex } from '@app/components/Container'
import IconDown from '@app/components/Icon/IconDown'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import SpinnerInline, {
  SpinnerInlineSize
} from '@app/components-legacy/Spinner/SpinnerInline'
import { useAppTranslator, useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleSeeMoreLogsOnClick } from './handler'

const SeeMoreLogs: React.FC = props => {
  const { storeActivityLogs } = useStores()

  const translate = useAppTranslator({
    namespaces: ['ActivityLogs.Table']
  })

  return (
    <ContainerFlex
      name="ContainerFlexSeeMoreTraces"
      justifyContent="center"
      items={[
        storeActivityLogs.hasMoreTracesAvailable && (
          <FormWrapperButton
            labelledBy="seeMore"
            variant={ButtonVariant.secondary}
            size={ButtonSize.small}
            icon={IconDown}
            iconAfterLabel
            wrapWithLabel
            buttonProps={{
              onClick: handleSeeMoreLogsOnClick(storeActivityLogs)
            }}
          >
            <ContainerFlex
              name="ContainerFlexSeeMoreTracesLabel"
              alignItems="center"
              flexGap="verySmall"
              items={[
                storeActivityLogs.storeFlagsFetchMoreLogs.isLoading && (
                  <SpinnerInline size={SpinnerInlineSize.small} />
                ),
                <span>{translate('See more')}</span>
              ]}
            />
          </FormWrapperButton>
        )
      ]}
      fullWidth
    ></ContainerFlex>
  )
}

export default observer(SeeMoreLogs)
