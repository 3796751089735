import { ContainerFlex } from '@app/components-legacy/Container'
import { TypographyFieldsetTitle } from '@app/components-legacy/Typography'
import { useAppTranslator, useStores } from '@app/hooks'
import { WidgetCommonFormFieldName } from '@app/pages/Dashboard/WidgetAddPage/types'
import type StoreWidgetsManagement from '@app/stores/Dashboard/StoreWidgetsManagement'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { DashboardWidgetType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import WidgetInputsForBarChart from './WidgetInputsForBarChart'
import WidgetInputsForBigNumber from './WidgetInputsForBigNumber'
import WidgetInputsForLineChart from './WidgetInputsForLineChart'

export interface IWidgetInputsWidgetTypeSwitchProps {
  version: string
  storeWidgetsManagement: StoreWidgetsManagement
}

const WidgetInputsWidgetTypeSwitch: React.FC<
  IWidgetInputsWidgetTypeSwitchProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Dashboard.WidgetCommonForm']
  })

  const { storeRoot } = useStores()

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const renderInputs = () => {
    const items = [
      <TypographyFieldsetTitle>
        {translate('Widget configuration')}
      </TypographyFieldsetTitle>
    ]

    const widgetType =
      props.storeWidgetsManagement.storeForm.getFieldValueAsString<DashboardWidgetType>(
        WidgetCommonFormFieldName.type
      )

    const hasMultipleInstance = storeRoot.environment.hasMultipleInstances()

    switch (widgetType) {
      case DashboardWidgetType.BarChart:
        items.push(
          <WidgetInputsForBarChart
            hasMultipleInstance={hasMultipleInstance}
            storeWidgetsManagement={props.storeWidgetsManagement}
          />
        )
        break

      case DashboardWidgetType.LineChart:
        items.push(
          <WidgetInputsForLineChart
            hasMultipleInstance={hasMultipleInstance}
            storeWidgetsManagement={props.storeWidgetsManagement}
          />
        )
        break

      case DashboardWidgetType.BigNumber:
        items.push(
          <WidgetInputsForBigNumber
            hasMultipleInstance={hasMultipleInstance}
            storeWidgetsManagement={props.storeWidgetsManagement}
          />
        )
        break

      case DashboardWidgetType.PieChart:
      case DashboardWidgetType.SecurityCompliance:
        items.push(<div>Not yet available</div>)
        break

      default:
        assertUnreachableCase(widgetType)
    }

    return items
  }

  return (
    <ContainerFlex
      name="WidgetInputsWidgetType"
      direction="column"
      items={renderInputs()}
      spaced
      spaceWidth={themeIsLegacy ? 'small' : 'default'}
    />
  )
}

export default observer(WidgetInputsWidgetTypeSwitch)
