import { Parser } from '@libs/Expression/parser/Parser'
import { ParseResult } from '@libs/Expression/parser/ParseResult'

/**
 * Returns a new parser that, once called with an input, will apply the parser
 * passed as parameter until failure or end of input.
 * On complete all results will be returned in an array.
 *
 * Example :
 *  const stringParser: Parser<string> = stringEqualsTo("123")
 *  const parser : Parser<string[]> = sequence0N(stringParser)
 *  parser.parse("12312345") will
 *   - Successfully returns ["123", "123"] as parsed value and "45" as remaining input
 *  parser.parse("456789") will
 *   - Successfully returns [] as parsed value and "456789" as remaining input
 *
 *
 * @param parser : Parser to be repeated
 */
export function sequence0N<T>(parser: Parser<T>): Parser<T[]> {
  const min = 0
  return sequence(parser, min).named(`sequence0N(${parser.name})`)
}

/**
 * Returns a new parser that, once called with an input, will apply the parser
 * passed as parameter expected optionally to match.
 *
 * Example :
 *  const stringParser: Parser<string> = stringEqualsTo("123")
 *  const parser : Parser<string | null> = optional(stringParser)
 *  parser.parse("12312345") will
 *   - Successfully returns "123" as parsed value and "12345" as remaining input
 *  parser.parse("456789") will
 *   - Successfully returns null as parsed value and "456789" as remaining input
 *
 *
 * @param parser : Parser to be repeated
 */
export function optional<T>(parser: Parser<T>): Parser<T | null> {
  const min = 0
  const max = 1
  return sequence(parser, min, max)
    .map(result => (result.length === 0 ? null : result[0]))
    .named(`optional(${parser.name})`)
}

/**
 * Returns a new parser that, once called with an input, will apply the parser
 * passed as parameter until failure or end of input expecting at least one result
 * On complete all results will be returned in an array.
 *
 * Example :
 *  const stringParser: Parser<string> = stringEqualsTo("123")
 *  const parser : Parser<string[]> = sequence1N(stringParser)
 *  parser.parse("12312345") will
 *   - Successfully returns ["123", "123"] as parsed value and "45" as remaining input
 *  parser.parse("456789") will
 *   - Fails and "456789" as remaining input
 *
 *
 * @param parser : Parser to be repeated
 */
export function sequence1N<T>(parser: Parser<T>): Parser<T[]> {
  const min = 1
  return sequence(parser, min).named(`sequence1N(${parser.name})`)
}

// TODO Change the default new error with more specific error

/**
 * Returns a new parser that, once called with an input, will apply the parser
 * passed as parameter with min and max occurrences expected
 * On complete all results will be returned in an array.
 *
 * Example :
 *  const stringParser: Parser<string> = stringEqualsTo("123")
 *  const parser : Parser<string[]> = sequenceN(stringParser, min:2, max:3)
 *  parser.parse("12312345") will
 *   - Successfully returns ["123", "123"] as parsed value and "45" as remaining input
 *  parser.parse("456789") will
 *   - Fails and "456789" as remaining input
 *  parser.parse("123123123123456789") will
 *   - Successfully returns ["123", "123", "123"] as parsed value and "123456789" as remaining input
 *
 *
 * @param parser : Parser to be repeated
 * @param min : min nb of occurrences to consider success. 0 if not specified
 * @param max : max nb of occurrences to consider success. PositiveInfinity if not specified
 */
export function sequence<T>(
  parser: Parser<T>,
  min?: number,
  max?: number
): Parser<T[]> {
  if (min && min < 0) {
    throw new Error(
      `To construct a Sequence parser, min(${min}) should be greater than 0`
    )
  }
  if (max && max < 0) {
    throw new Error(
      `To construct a Sequence parser, max(${max}) should be greater than 0`
    )
  }
  if (max && min && max < min) {
    throw new Error(
      `To construct a Sequence parser, max(${max}) should be greater than min(${min})`
    )
  }
  if (max && min && min === 0 && max === 0) {
    throw new Error(
      `To construct a Sequence parser, max(${max}) and min(${min}) should not be 0 at the same time`
    )
  }

  const realMin = min === undefined ? 0 : min
  const realMax = max === undefined ? Infinity : max

  return Parser.strict(
    `sequence(min:${realMin}, max:${realMax})`,
    ({ text, parserName }) => {
      const rep0NRec = (currentInput: string, acc: T[]): ParseResult<T[]> => {
        if (currentInput.length === 0) {
          return ParseResult.success<T[]>({
            parsersHistory: [],
            parsedValue: acc,
            parserName,
            remaining: ''
          })
        }

        const parseResult = parser.parse(currentInput)
        if (parseResult.isSuccess()) {
          acc.push(parseResult.parsedValue)
          if (acc.length < realMax) {
            return rep0NRec(parseResult.remaining, acc)
          }
        }

        const nextInput = parseResult.isSuccess()
          ? parseResult.remaining
          : currentInput
        return ParseResult.success<T[]>({
          parsersHistory: parseResult.parsersHistory,
          parsedValue: acc,
          parserName,
          remaining: nextInput
        })
      }

      return rep0NRec(text, []).flatMap((array, thisParserResult) => {
        if (realMin <= array.length && array.length <= realMax) {
          return thisParserResult
        }

        return ParseResult.failure<T[]>({
          parsersHistory: thisParserResult.parsersHistory,
          errorMsg: `Expected nb items recognized by '${parser.name}' to be between min(${realMin}) and max(${realMax}) but real nb items was: ${array.length}`,
          parserName: thisParserResult.parserName,
          remaining: thisParserResult.remaining
        })
      })
    }
  )
}
