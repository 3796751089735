import { ContainerRbac } from '@app/components-legacy/Container'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import IoEBoardMenuItems from './IoEBoardMenuItems'
import { canAccessToIdentityIoEDetails } from './permissions'

interface IIoEBoardMenuProps {}

const IoEBoardMenu: React.FC<IIoEBoardMenuProps> = props => {
  return (
    <ContainerRbac rbacCapabilityCheck={canAccessToIdentityIoEDetails}>
      <IoEBoardMenuItems />
    </ContainerRbac>
  )
}

export default observer(IoEBoardMenu)
