import ContainerFlex from '@app/components/Container/ContainerFlex/ContainerFlex'
import { IconRight } from '@app/components/Icon'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import FormWrapperButtonSimple from '@app/components-legacy/Form/Wrappers/ButtonSimple'
import { consts } from '@app/styles'
import {
  BackgroundColorV2,
  BrandColorV2,
  FontColorV2
} from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

const StyledContainerFlex = styled(ContainerFlex)`
  height: 100%;
`

const FakeButton = styled.div`
  height: 40px;
  width: 40px;
  border-radius: ${consts.borderRadiusSmallRedesign};
  background-color: ${BackgroundColorV2.white};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const SmallEllipse = styled.div`
  height: 90px;
  width: 90px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
`

const LargeEllipse = styled.div`
  height: 140px;
  width: 140px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
`

interface IFormWrapperButtonFancyProps {
  className?: string
  labelledBy: string
  label: string
  description?: string
}

const FormWrapperButtonFancy: React.FC<
  IFormWrapperButtonFancyProps
> = props => {
  return (
    <FormWrapperButtonSimple
      labelledBy={props.labelledBy}
      className={props.className}
    >
      <StyledContainerFlex
        name="FormWrapperButtonFancy"
        justifyContent="space-between"
        itemsFlexGrow={[1, 0]}
        items={[
          <ContainerFlex
            name="FormWrapperButtonFancyText"
            direction="column"
            justifyContent="center"
            items={[
              <Label variant={LabelVariant.h2} color={consts.colorWhite}>
                {props.label}
              </Label>,
              <Label variant={LabelVariant.p} color={consts.colorWhite}>
                {props.description}
              </Label>
            ]}
            spaced
          />,
          <ContainerFlex
            name="FormWrapperButtonFancyIcon"
            direction="column"
            justifyContent="center"
            items={[
              <FakeButton>
                <IconRight color={FontColorV2.link} size={16} />
                <SmallEllipse />
                <LargeEllipse />
              </FakeButton>
            ]}
            spaced
          />
        ]}
        spaced
        spaceWidth="large"
      />
    </FormWrapperButtonSimple>
  )
}

export default styled(FormWrapperButtonFancy)`
  width: 350px;
  padding: ${consts.paddingLarge};
  border-radius: ${consts.borderRadiusRedesign};
  overflow: hidden;

  background: ${BrandColorV2.gradientStart};
  background: -moz-linear-gradient(
    ${consts.gradientAngle},
    ${BrandColorV2.gradientStart} 0%,
    ${BrandColorV2.gradientEnd} 100%
  );
  background: -webkit-linear-gradient(
    ${consts.gradientAngle},
    ${BrandColorV2.gradientStart} 0%,
    ${BrandColorV2.gradientEnd} 100%
  );
  background: linear-gradient(
    ${consts.gradientAngle},
    ${BrandColorV2.gradientStart} 0%,
    ${BrandColorV2.gradientEnd} 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="${BrandColorV2.gradientStart}",endColorstr="${BrandColorV2.gradientEnd}",GradientType=1);
`
