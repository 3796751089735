import { Features } from '@alsid/common'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  accessByFeatureFlag,
  deny,
  grant,
  readTenableCloudApiToken
} from '@libs/rbac/permissions'
import type { RbacCapabilityCheck } from '@libs/rbac/types'

/**
 * Unique check to the AzureAD support FF.
 */
export const canAccessToAzureAdData = rbacCapabilityCheckAllOf(
  // Check FF
  accessByFeatureFlag(Features.AZURE_AD_ADDON)(),
  // Check that the user can read the Tenable Cloud API tokens (access keys + secret keys)
  readTenableCloudApiToken()
)

/**
 * Main permission allowing to show AzureAD data or not.
 */
export const canDisplayAzureAdBecauseEnabled: RbacCapabilityCheck = (
  rbacPermissions,
  meta
) => {
  const { azureADSupportConfiguration } =
    meta.storeRbac.storeRoot.stores.storeManagementAzureAD

  const { isLicenseUpToDate } = meta.storeRbac.storeRoot.stores.storeLicense

  return rbacCapabilityCheckAllOf(
    // FF
    canAccessToAzureAdData,
    // Is the license up-to-date?
    isLicenseUpToDate ? grant() : deny(),
    // Is the feature enabled?
    azureADSupportConfiguration?.azureADSupportEnabled ? grant() : deny()
  )(rbacPermissions, meta)
}
