import type { Perhaps } from '@@types/helpers'
import { isDefined } from '@libs/isDefined'

/**
 * Compute the component names from a list of strings.
 *
 * Usage:
 * <div data-name={computeComponentName('ContainerFoo', myCustomName)}>...</div>
 */
export function computeComponentName(...names: Array<Perhaps<string>>): string {
  return names.filter(isDefined).join('-')
}
