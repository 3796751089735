import type { ConfigClient } from '@libs/config/types'
import { getLogger } from '@libs/logger'

// Save the config in the module to be able to return it
// after an HRM reload.
let config: ConfigClient

/**
 * Retrieve the config from the DOM.
 */
export function getConfigFromDOM(): ConfigClient {
  const logger = getLogger()

  if (config) {
    return config
  }

  try {
    const configNode = document.getElementById('config')
    if (configNode) {
      config = JSON.parse(window.atob(configNode.innerHTML))
    }
  } catch (err) {
    logger.error('Cant parse the client configuration.')
    logger.debug(err.stack)
  }

  return config
}
