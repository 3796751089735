import { ContainerFlex } from '@app/components-legacy/Container'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { consts } from '@app/styles'
import { isDefined } from '@libs/isDefined'
import { omit } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import LabelDirectories from '../LabelDirectories'
import LabelCheckerGauge from './LabelCheckerGauge'
import type { ILabelCheckerProps } from './types'

interface ILabelCheckerFooterProps<GC extends TGenericChecker>
  extends ILabelCheckerProps<GC> {
  isOvered: boolean
}

function LabelCheckerFooter<GC extends TGenericChecker>(
  props: ILabelCheckerFooterProps<GC>
): React.ReactElement {
  const showDirectories = isDefined(props.directoryIds)
  const showGauge = isDefined(props.complexityValue)
  const hasDirectories = props.directoryIds && props.directoryIds.length > 0

  const renderDirectories = () => {
    if (!props.directoryIds) {
      return null
    }

    return <LabelDirectories directoryIds={props.directoryIds} />
  }

  return (
    <ContainerFlex
      name="LabelCheckerFooter"
      className={props.className}
      itemsFlexGrow={[1, 0]}
      alignItems="center"
      justifyContent={hasDirectories ? 'flex-start' : 'flex-end'}
      items={[
        showDirectories && renderDirectories(),
        showGauge && <LabelCheckerGauge {...omit(props, 'className')} />
      ]}
      wrapItems
    />
  )
}

export default styled(LabelCheckerFooter)`
  transition: ${consts.transitionOpacity};
  padding: ${consts.paddingVeryVerySmall} ${consts.paddingSmall};

  /* Allow to show the Tooltip over the &:hover::after element */
  z-index: 2;

  background-color: ${props =>
    props.isOvered ? consts.colorSelected02 : consts.colorWhiteAlt};
`
