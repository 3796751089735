import { ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import type {
  EntityHealthCheckDomainStatusDetails,
  EntityHealthCheckPlatformStatusDetails
} from '@app/entities'
import { useAppTranslator } from '@app/hooks'
import { HealthCheckReason } from '@app/pages/HealthCheck/HealthCheckDetails/Reason/HealthCheckReason'
import * as React from 'react'
import HealthCheckNameLabel from '../Labels/HealthCheckNameLabel'
import { HealthCheckNameLocale } from '../Labels/types'
import HealthCheckDetailsSection from './HealthCheckDetailsSection'

interface IHealthCheckDetailsInformationProps {
  healthCheckDetails:
    | EntityHealthCheckDomainStatusDetails
    | EntityHealthCheckPlatformStatusDetails
}

const HealthCheckDetailsInformation: React.FC<
  IHealthCheckDetailsInformationProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['HealthCheck.Details']
  })

  const healthCheckName =
    props.healthCheckDetails.getPropertyAsString('healthCheckName')

  const reasons = props.healthCheckDetails.getReasons()

  return (
    <ContainerFlex
      name="HealthCheckDetailsInformation"
      direction="column"
      items={[
        <HealthCheckDetailsSection
          title={translate('Executive summary')}
          content={
            <HealthCheckNameLabel
              locale={HealthCheckNameLocale.description}
              healthCheckName={healthCheckName}
              transformMarkdown
            />
          }
        />,

        reasons && reasons.length > 0 && (
          <Label variant={LabelVariant.h2}>{translate('Details')}</Label>
        ),

        reasons && (
          <ContainerFlex
            name="HealthCheckReasons"
            direction="column"
            items={reasons.map(reason => (
              <HealthCheckReason
                key={reason.name}
                healthCheckName={healthCheckName}
                reason={reason}
              />
            ))}
            flexGap="smaller"
          />
        )
      ]}
      spaced
      spaceWidth="default"
      borderPadding="default"
    />
  )
}

export default HealthCheckDetailsInformation
