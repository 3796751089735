import { ContainerFlex } from '@app/components/Container'
import { IconDown, IconLeft, IconPlus, IconRight } from '@app/components/Icon'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import FormWrapperButtonSelect from '@app/components-legacy/Form/Wrappers/ButtonSelect'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import type StoreMenu from '@app/stores/helpers/StoreMenu'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { CTAColorV2 } from '@app/styles/consts'
import { Tabs as TabsAntd, Tooltip } from 'antd'
import type { SelectValue } from 'antd/lib/select'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

const TabButtonWrapper = styled.div`
  position: relative;
  height: 48px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  ::after {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid #f0f0f0;
    content: '';
  }
`

const Line = styled.div`
  position: relative;
  height: 48px;
  flex-grow: 1;

  ::after {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid #f0f0f0;
    content: '';
  }
`

const StyledTabsAntd = styled(TabsAntd)`
  // Available width minus 4 buttons of 45px.
  max-width: calc(100% - (4 * 45px));
`

export interface ITabsProps {
  className?: string
  storeMenu: StoreMenu<IMenuEntry<string>>
  previousTabLabel?: string
  nextTabLabel?: string
  listAllTabsLabel?: string
  newTabLabel?: string
  onNewTab?: () => void
}

function Tabs<T = SelectValue>(props: ITabsProps): React.ReactElement {
  const { storeMenu } = props

  const previousButtonDisabled = storeMenu.selectedEntryIndex <= 0

  const previousButton = (
    <FormWrapperButton
      labelledBy="previousTab"
      variant={ButtonVariant.colored}
      size={ButtonSize.small}
      buttonProps={{
        disabled: previousButtonDisabled,
        icon: (
          <IconLeft
            color={
              previousButtonDisabled ? CTAColorV2.disable : CTAColorV2.primary
            }
            size={11}
          />
        ),
        onClick: () => storeMenu.selectPreviousEntry()
      }}
    />
  )

  const nextButtonDisabled =
    storeMenu.selectedEntryIndex >= storeMenu.menuEntries.length - 1

  const nextButton = (
    <FormWrapperButton
      labelledBy="nextTab"
      variant={ButtonVariant.colored}
      size={ButtonSize.small}
      buttonProps={{
        disabled: nextButtonDisabled,
        icon: (
          <IconRight
            color={nextButtonDisabled ? CTAColorV2.disable : CTAColorV2.primary}
            size={11}
          />
        ),
        onClick: () => storeMenu.selectNextEntry()
      }}
    />
  )

  return (
    <ContainerFlex
      name="Tabs"
      className={props.className}
      alignItems="center"
      itemsFlexShrink={[0, 1, 0, 0, 0]}
      items={[
        <TabButtonWrapper>
          {previousButtonDisabled ? (
            previousButton
          ) : (
            <Tooltip title={props.previousTabLabel}>
              <div>{previousButton}</div>
            </Tooltip>
          )}
        </TabButtonWrapper>,

        <StyledTabsAntd
          onChange={activeKey => storeMenu.selectEntry(activeKey)}
          activeKey={storeMenu.selectedMenuKey || ''}
        >
          {storeMenu.menuEntries.map(menuEntry => (
            <TabsAntd.TabPane tab={menuEntry.label} key={menuEntry.key} />
          ))}
        </StyledTabsAntd>,

        <TabButtonWrapper>
          {nextButtonDisabled ? (
            nextButton
          ) : (
            <Tooltip title={props.nextTabLabel}>
              <div>{nextButton}</div>
            </Tooltip>
          )}
        </TabButtonWrapper>,

        <TabButtonWrapper>
          <FormWrapperButtonSelect
            labelledBy="listAllTabs"
            icon={<IconDown color={CTAColorV2.primary} size={11} />}
            selectOptions={storeMenu.menuEntries.map(menuEntry => ({
              value: menuEntry.key,
              label: menuEntry.label
            }))}
            value={storeMenu.selectedMenuKey}
            placement="bottomRight"
            buttonTooltipLabel={props.listAllTabsLabel}
            buttonTooltipPlacement="topRight"
            onChange={(key: string) => {
              storeMenu.selectEntry(key)
            }}
          />
        </TabButtonWrapper>,

        <TabButtonWrapper>
          <Tooltip title={props.newTabLabel} placement="topRight">
            <div>
              <FormWrapperButton
                labelledBy="newTab"
                variant={ButtonVariant.colored}
                size={ButtonSize.small}
                buttonProps={{
                  icon: <IconPlus color={CTAColorV2.primary} size={14} />,
                  onClick: props.onNewTab
                }}
              />
            </div>
          </Tooltip>
        </TabButtonWrapper>,

        <Line />
      ]}
    />
  )
}

const ObservedTabs = observer(Tabs)

export default styled(ObservedTabs)`
  width: 100%;
`
