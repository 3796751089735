import { ContainerContent } from '@app/components/Container'
import { ErrorMessage } from '@app/components/Error'
import SpinnerInline, {
  SpinnerInlineSize
} from '@app/components-legacy/Spinner/SpinnerInline'
import type StoreInputTenants from '@app/stores/StoreInputTenants'
import type { BaseButtonProps } from 'antd/lib/button/button'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleInputTenantsOnLoad } from './handlers'
import InputTenantsButton from './InputTenantsButton'

interface IInputTenantsProps {
  className?: string
  style?: React.CSSProperties
  size?: BaseButtonProps['size']
  onSubmit?: () => void
  loadAtMount?: boolean
  storeInputTenants: StoreInputTenants
}

const InputTenants: React.FC<IInputTenantsProps> = props => {
  if (props.storeInputTenants.storeFlagsFetchTenants.isLoading) {
    return <SpinnerInline size={SpinnerInlineSize.small} />
  }

  if (props.storeInputTenants.storeFlagsFetchTenants.isError) {
    return <ErrorMessage />
  }

  return (
    <ContainerContent
      name="InputTenants"
      onLoad={handleInputTenantsOnLoad(props.storeInputTenants)(
        props.loadAtMount
      )}
    >
      <InputTenantsButton
        size={props.size}
        onSubmit={props.onSubmit}
        storeInputTenants={props.storeInputTenants}
      />
    </ContainerContent>
  )
}

export default observer(InputTenants)
