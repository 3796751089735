import type { Query } from '../typeDefs/types'

/**
 * Retrieve profiles
 */
export type QueryProfiles = Pick<Query, 'profiles'>

export const queryProfiles = `
  query queryProfiles(
    $profileId: Int
  ) {
    profiles(
      profileId: $profileId
    ) {
      id
      name
      dirty
      hasEverBeenCommitted
    }
  }
`
