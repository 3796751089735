import { FormWrapperSelect } from '@app/components-legacy/Form'
import { range } from 'lodash'
import * as React from 'react'
import { UnitPrefix } from '../types'
import CronfieldControlContainer from './CronfieldControlContainer'

export interface ISelectMinuteProps {
  minutes: string[]
  onChange: (minute: string) => void
}

export default function SelectMinute(props: ISelectMinuteProps) {
  const selectOptions = range(0, 60).map(minute => {
    return {
      label: minute.toString().padStart(2, '0'),
      value: String(minute)
    }
  })

  return (
    <CronfieldControlContainer
      name="SelectMinute"
      unitPrefix={UnitPrefix.HoursMinuteSeparator}
      hidePrefix={false}
    >
      <FormWrapperSelect<string>
        labelledBy="format"
        width="verySmall"
        selectProps={{
          placeholder: 'Minutes',
          value: props.minutes[0],
          allowClear: true,
          onChange: props.onChange
        }}
        selectOptions={selectOptions}
      />
    </CronfieldControlContainer>
  )
}
