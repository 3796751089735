import { AppRouteName } from '@app/routes'
import type { StoreActivityLogs } from '@app/stores'
import type { ISubmitFormParameters } from '@libs/submitForm'
import { submitForm } from '@libs/submitForm'
import type { IExportParametersActivityLogs } from '@server/routers/exportCSVRouter/types'

/**
 * Export to csv.
 */
export function exportToCSV(storeActivityLogs: StoreActivityLogs) {
  const { storeRoot } = storeActivityLogs
  const { appRouter, appTranslator } = storeRoot
  const { storeActivityLogsFilters } = storeActivityLogs

  const pathname = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.MiddlewareExportCSV,
    parameters: {}
  })

  const range =
    storeActivityLogs.storeDatePicker.datePickerRangeValueAsIsoStrings
  const dateStart = range?.dateStart
  const dateEnd = range?.dateEnd

  const parameters: ISubmitFormParameters<IExportParametersActivityLogs> = {
    parameters: {
      routine: 'ActivityLogs',
      contextData: {
        filters: {
          ips: storeActivityLogsFilters.selectedIPs,
          userEmails: storeActivityLogsFilters.selectedUserEmails,
          dateEnd: dateEnd ?? undefined,
          dateStart: dateStart ?? undefined
        }
      }
    },
    language: appTranslator.language,
    'csrf-token': storeRoot.environment.sessionParams.csrfToken
  }
  submitForm(pathname, parameters, 'POST')
}
