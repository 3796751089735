import { ContainerFlex } from '@app/components-legacy/Container'
import { useAppRouter, useStores } from '@app/hooks'
import type StoreSummaryCard from '@app/stores/IoA/StoreBoard/StoreSummaryCard'
import { consts } from '@app/styles'
import { AttackResourceType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import CardChart from './CardChart'
import { handleCardOnClick } from './handlers'
import IoABoardCardRibbon from './IoABoardCardRibbon'
import IoABoardCardTitle from './IoABoardCardTitle'

interface IIoABoardCardProps {
  className?: string
  storeSummaryCard: StoreSummaryCard
}

const IoABoardCard: React.FC<IIoABoardCardProps> = props => {
  const appRouter = useAppRouter()

  const { storeIoA } = useStores()

  const { directoryEntity } = props.storeSummaryCard

  if (!directoryEntity) {
    return null
  }

  return (
    <ContainerFlex
      name={IoABoardCard.name}
      className={props.className}
      // extends the horizontal right part of the card
      itemsFlexGrow={[0, 1]}
      items={[
        <IoABoardCardRibbon directoryEntity={directoryEntity} />,

        <ContainerFlex
          name="IoABoardCardContent"
          direction="column"
          items={[
            <IoABoardCardTitle storeSummaryCard={props.storeSummaryCard} />,
            <CardChart storeSummaryCard={props.storeSummaryCard} />
          ]}
          spaced
          spaceWidth="small"
        />
      ]}
      onClick={handleCardOnClick(
        appRouter,
        storeIoA
      )({
        resourceType: AttackResourceType.Directory,
        resourceValue: directoryEntity.getPropertyAsString('id')
      })}
    />
  )
}

const ObservedIoABoardCard = observer(IoABoardCard)

export default styled(ObservedIoABoardCard)`
  background-color: ${consts.colorWhite};
  color: ${consts.colorBlueGray001};
  border-radius: 4px;
  box-shadow: 0 0 4px 2px ${consts.colorGrey005};
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 370px;
  width: 320px;
  margin-bottom: ${consts.marginDefault};
  cursor: pointer;
`
