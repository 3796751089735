import { ContainerFlex } from '@app/components-legacy/Container'
import type { IContainerFlexProps } from '@app/components-legacy/Container/ContainerFlex/types'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface ICellProps {
  className?: string
  direction?: IContainerFlexProps['direction']
  isInfrastructure?: boolean
  isAttackType?: boolean
  detectedEvents?: boolean
  width?: number
  children?: React.ReactNode
}

const StyledContainerFlex = styled(ContainerFlex)<ICellProps>`
  ${getWidth}
  min-height: '40px';
  max-height: '40px';
  overflow: hidden;
  font-weight: ${consts.fontWeightBold};
  text-align: ${props => (props.isAttackType ? 'left' : 'center')};
  border: 'none';
  padding-left: ${props =>
    props.isAttackType ? consts.paddingLarge : consts.paddingSmall};
  ${getBackground};
`

const Cell: React.FC<ICellProps> = props => {
  return (
    <StyledContainerFlex
      name={Cell.name}
      className={props.className}
      justifyContent={props.isAttackType ? 'flex-start' : 'center'}
      alignItems="center"
      direction={props.direction ?? 'row'}
      items={React.Children.toArray(props.children)}
      wrapItems
      spaced
      spaceWidth="verySmall"
      paddingH="small"
      paddingV="small"
      {...props}
    />
  )
}

function getBackground(props: ICellProps): string {
  if (props.isAttackType) {
    return `background: ${consts.colorWhite};`
  }

  return `background: ${consts.BackgroundColorV2.lightGray};`
}

function getWidth(props: ICellProps) {
  const width = props.width ?? 130

  return `
    min-width: ${width}px;
    max-width: ${width}px;
  `
}

export default Cell
