import { Features } from '@alsid/common'
import { AssetImg } from '@app/components/Asset/AssetImg'
import { ErrorBoundary, ErrorMessage } from '@app/components/Error'
import {
  ContainerFlex,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import { IconHighFive } from '@app/components-legacy/Icon'
import { useAppTranslator } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { useStores } from '@app/hooks/useStores'
import { useWindowSize } from '@app/hooks/useWindowSize'
import { FontColorV2 } from '@app/styles/consts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { AttackPathSearchTabs } from '../../types'
import { useSceneDimensions } from './hooks'
import SceneChart from './SceneChart'

interface ISceneProps {
  className?: string
}

const MessageWrapper = styled.div`
  margin: 0;
  text-align: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${FontColorV2.primary};
`

const Text = styled.div`
  margin-top: 50px;
`

const Scene: React.FC<ISceneProps> = props => {
  const windowSize = useWindowSize()
  const sceneDivRef = useSceneDimensions(windowSize)

  const translate = useAppTranslator({
    namespaces: ['AttackPath.Chart']
  })

  const { storeAttackPath } = useStores()

  const featurePinHighlightedPath = useFeatureFlag({
    featureFlagName: Features.ATTACK_PATH_PIN_HIGHLIGHTED_PATH
  })

  const getScene = () => {
    if (!storeAttackPath.sceneIsReady) {
      return
    }

    if (storeAttackPath.noAttackPathTwoNodesResult) {
      return (
        <MessageWrapper>
          <ContainerFlex
            name="AttackPathNoResultMessage"
            inline
            alignItems="center"
            spaceWidth="small"
            items={[
              <IconHighFive />,
              <ContainerHTMLContent>
                {translate('There is no Attack Path', {
                  transformMarkdown: true
                })}
              </ContainerHTMLContent>
            ]}
            spaced
          />
        </MessageWrapper>
      )
    }

    if (
      storeAttackPath.targetNodeDisplay &&
      !storeAttackPath.noAttackPathTwoNodesResult &&
      !storeAttackPath.nodes.length
    ) {
      if (!storeAttackPath.selectedSearchedSourceNode) {
        return (
          <MessageWrapper>
            <AssetImg
              imageName="attackpath/attack-path-add-start-node"
              fileType="svg"
              width={362}
            />
            <Text>
              {translate(
                'Add a starting node to find all the paths between your two objects'
              )}
            </Text>
          </MessageWrapper>
        )
      }
      if (!storeAttackPath.selectedSearchedTargetNode) {
        return (
          <MessageWrapper>
            <AssetImg
              imageName="attackpath/attack-path-add-end-node"
              fileType="svg"
              width={362}
            />
            <Text>
              {translate(
                'Add an ending node to find all the paths between your two objects'
              )}
            </Text>
          </MessageWrapper>
        )
      }
    }

    if (
      !storeAttackPath.targetNodeDisplay &&
      !storeAttackPath.selectedSearchedSourceNode &&
      !storeAttackPath.nodes.length
    ) {
      return (
        <MessageWrapper>
          <AssetImg
            imageName={
              storeAttackPath.activeTab === AttackPathSearchTabs.blastRadius
                ? 'attackpath/blast-radius-add-object'
                : 'attackpath/asset-exposure-add-object'
            }
            fileType="svg"
            width={595}
          />
          <Text>{translate('Add an object to discover all its links')}</Text>
        </MessageWrapper>
      )
    }

    return <SceneChart featurePinHighlightedPath={featurePinHighlightedPath} />
  }

  return (
    <ErrorBoundary errorComponent={<ErrorMessage />}>
      <div
        data-name="Scene"
        data-test="contentinfo/attack-path-scene"
        ref={sceneDivRef}
        className={props.className}
      >
        {getScene()}
      </div>
    </ErrorBoundary>
  )
}

const ObservedScene = observer(Scene)

// force container to be full-sized to get correct size dimensions
export default styled(ObservedScene)`
  width: 100%;
  height: 100%;
`
