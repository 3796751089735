import type { IIconProps } from '@app/components/Icon/types'
import { BackgroundColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconCloseCircleProps extends IIconProps {}

const IconCloseCircle: React.FC<IIconCloseCircleProps> = props => {
  const fill = props.color ?? BackgroundColorV2.red
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="8" cy="8" r="8" fill={fill} />
      <path
        d="M12.5 4.52316L11.4768 3.5L8 6.97684L4.52316 3.5L3.5 4.52316L6.97684 8L3.5 11.4768L4.52316 12.5L8 9.02316L11.4768 12.5L12.5 11.4768L9.02316 8L12.5 4.52316Z"
        fill="white"
      />
    </svg>
  )
}

export default IconCloseCircle
