import type { Query } from '../typeDefs/types'

/**
 * Retrieve checkers details for the Indicators of Exposure view.
 */

export type QueryRbacCheckers = Pick<Query, 'rbacCheckers'>

export const queryRbacCheckers = `
  query queryRbacCheckers(
    $profileId: Int!
    $optionsCodenames: [String!]!
  ) {
    rbacCheckers(
      profileId: $profileId
      optionsCodenames: $optionsCodenames
    ) {
      node {
        id
        codename
        name
        description
        remediationCost
        execSummary
        isDeviant
        directoryIds
        vulnerabilityDetail {
          detail
        }
        resources {
          name
          url
          type
        }
        attackerKnownTools {
          name
          url
          author
        }
        recommendation {
          name
          description
          execSummary
          detail
          resources {
            name
            url
            type
          }
        }
        options {
          codename
          value
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Return checkers with not all joined data.
 */

export type QueryRbacCheckersLight = Pick<Query, 'rbacCheckers'>

export const queryRbacCheckersLight = `
  query queryRbacCheckersLight(
    $profileId: Int!
    $optionsCodenames: [String!]!
  ) {
    rbacCheckers(
      profileId: $profileId
      optionsCodenames: $optionsCodenames
    ) {
      node {
        id
        codename
        name
        description
        remediationCost
        execSummary
        options {
          id
          name
          description
          codename
          value
          valueType
          translations
          directoryId
          checkerId
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Return checkers compliance status.
 */

export type QueryCheckerDeviancesForCheckerCompliance = Pick<
  Query,
  'rbacCheckers'
>

export const queryCheckerDeviancesForCheckerCompliance = `
  query queryCheckerDeviancesForCheckerCompliance(
    $profileId: Int!
    $directoryIds: [Int!]!
    $checkerId: Int!
    $optionsCodenames: [String!]!
    $deviancesPerPage: Int
  ) {
    rbacCheckers(
      profileId: $profileId
      checkerId: $checkerId
      optionsCodenames: $optionsCodenames
    ) {
      node {
        id
        codename
        name
        isDeviant
        directoryIds
        options {
          codename
          value
        }
        rbacDeviances(
          directoryIds: $directoryIds
          deviancesPerPage: $deviancesPerPage
        ) {
          node {
            id
            eventDate
            checkerId
          }
          rbacCapability {
            isGranted
            isFound
            message
            rbacEntityName
          }
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Return checkers reasons.
 */

export type QueryCheckerReasons = Pick<Query, 'rbacCheckers'>

export const queryCheckerReasons = `
  query queryCheckerReasons(
    $profileId: Int!
    $checkerId: Int!
    $optionsCodenames: [String!]!
  ) {
    rbacCheckers(
      profileId: $profileId
      checkerId: $checkerId
      optionsCodenames: $optionsCodenames
    ) {
      node {
        id
        codename
        rbacReasons(checkerId: $checkerId) {
          node {
            id
            codename
            name
            description
          }
          rbacCapability {
            isGranted
            isFound
            message
            rbacEntityName
          }
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Retrieve checker-options for profiles customization.
 */

export type QueryCheckerOptions = Pick<Query, 'checkerOptions'>

export const queryCheckerOptions = `
  query queryCheckerOptions(
    $profileId: Int!
    $checkerId: Int!
  ) {
    checkerOptions(
      profileId: $profileId
      checkerId: $checkerId
    ) {
      id
      name
      description
      codename
      value
      valueType
      staged
      translations
      checkerId
      directoryId
    }
  }
`
