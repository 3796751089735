import { FormWrapperInput } from '@app/components-legacy/Form/Wrappers'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import type StoreWizard from '@app/stores/helpers/StoreWizard'
import { WizardRuleFieldName } from '@app/stores/helpers/StoreWizard/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

interface IInputFieldRule {
  className?: string
  index: number
  ruleGroupPath: number[]
  storeWizard: StoreWizard
  labelledBy: string
}

const InputFieldRule: React.FC<IInputFieldRule> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.Wizard']
  })

  const { storeForm } = props.storeWizard.getRuleOrRuleGroup(
    props.index,
    props.ruleGroupPath
  )

  return (
    <FormWrapperInput
      className={props.className}
      labelledBy={props.labelledBy}
      inputProps={{
        placeholder: translate('Enter a value'),
        value: storeForm.getFieldValueAsString(WizardRuleFieldName.value),
        onChange: onInputChange(storeForm)(WizardRuleFieldName.value)
      }}
    />
  )
}

const observedInputFieldRule = observer(InputFieldRule)

export default styled(observedInputFieldRule)`
  width: 250px;
`
