import type { EntityEvent } from '@app/entities'
import { createEntities, EntityDeviance } from '@app/entities'
import type { EventId } from '@app/entities/EntityEvent'
import { canSeeDeviances } from '@app/pages/TrailFlow/permissions'
import StoreBase from '@app/stores/StoreBase'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import { isGrantedEntity } from '@libs/rbac/functions'
import type { QueryEventsDeviances } from '@server/graphql/queries/event'
import { queryEventsDeviances } from '@server/graphql/queries/event'
import type {
  Deviance,
  InputDirectoryIdEventId,
  RbacDeviancesQueryArgs
} from '@server/graphql/typeDefs/types'
import type {
  IWSDevianceMessage,
  IWSRegistration
} from '@server/routers/WSProxyRouter/types'
import { WSEntityName } from '@server/routers/WSProxyRouter/types'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '..'
import type { IStoreOptions } from '../types'
import type { IEventDeviance } from './types'

export default class StoreDeviances extends StoreBase {
  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['TrailFlow.Table']
  })

  // deviances WS registrations
  private _devianceWSRegistrations: Array<
    IWSRegistration<IWSDevianceMessage['payload']>
  > = []

  // deviances related to an event
  private _deviances: Map<
    EventId,
    // key is devianceId
    Map<number, IEventDeviance>
  > = new Map()

  // When receiving deviances with isDeviant:true filter, events are then fetched.
  // But a lof ot deviances concern the same event and is emit at the "same time".
  // So to avoid to fetch again and again the same event and having 10, 20 queries
  // in parallel, this map allows to skip the query if the event is being queryied.
  private _eventBeingFetched: Map<EventId, boolean> = new Map()

  /* Observables */

  // toggle between 0 and 1
  private $deviancesActivity = observable.box<number>(0)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch deviances for events that not have its deviances fetched yet.
   */
  fetchDeviances(): Promise<void> {
    const { storeAuthentication } = this.storeRoot.stores

    const { storeTrailFlow, storeInfrastructures } = this.storeRoot.stores

    // if no directory selected (or not yet added), return
    if (!storeInfrastructures.selectedDirectoryIds.length) {
      return Promise.resolve()
    }

    const eventsInput = storeTrailFlow.storeEvents.events
      // keep events that don't have its deviances fetched yet
      .filter(event => {
        return event.id && !this._deviances.has(event.id)
      })
      .map(event => {
        if (!event.directory) {
          return
        }

        if (!event.directory.id) {
          return
        }

        if (!event.id) {
          return
        }

        const inputDirectoryEventId: InputDirectoryIdEventId = {
          directoryId: event.directory.id,
          eventId: event.id
        }

        return inputDirectoryEventId
      })
      .filter(isDefined)

    const profileIds = storeAuthentication.profileIds
    const tenableProfile = storeAuthentication.tenableProfile

    const args: RbacDeviancesQueryArgs = {
      events: eventsInput,
      profileIds
    }

    return this.storeRoot
      .getGQLRequestor()
      .query<QueryEventsDeviances>(queryEventsDeviances, args)
      .then(data => data.rbacDeviances)
      .then(deviances => {
        // keep granted deviances
        const allDeviances = deviances.reduce<Deviance[]>(
          (acc, eventDeviances) => {
            return isGrantedEntity(eventDeviances)
              ? acc.concat(eventDeviances.node)
              : acc
          },
          []
        )

        const entities = createEntities<Deviance, EntityDeviance>(
          EntityDeviance,
          allDeviances
        )

        entities.forEach(deviance => {
          if (!deviance.id) {
            return
          }

          if (!deviance.createdEventId) {
            return
          }

          if (!deviance.profileId) {
            return
          }

          if (!deviance.createdEventId) {
            return
          }

          const isTenableProfile = tenableProfile?.id === deviance.profileId

          this._addDeviance(deviance.createdEventId, {
            devianceId: deviance.id,
            isTenableProfile,
            profileId: deviance.profileId,
            isResolved: deviance.resolvedEventId !== null
          })
        })

        this.updateDeviancesActivity()
      })
      .catch(handleStoreError(this.storeRoot, null))
  }

  /**
   * Add a new deviance to the map of existing deviances for a defined event.
   */
  _addDeviance(eventId: EventId, deviance: IEventDeviance): void {
    const eventDeviances =
      this._deviances.get(eventId) || new Map<number, IEventDeviance>()

    eventDeviances.set(deviance.devianceId, deviance)

    this._deviances.set(eventId, eventDeviances)
  }

  /**
   * Function called when a deviance is received by WS.
   *
   * Two cases:
   *
   * - If isDeviant:true is set, a deviance is received, then its related event is fetched.
   * Note the usage of the `this._eventBeingFetched` map to avoid to DDOS the server.
   *
   * - If isDeviant:false (or not set), a deviance is received and added to the list
   * of deviances of events displayed in the TrailFlow.
   */
  updateEventDeviances(payload: IWSDevianceMessage['payload']): Promise<void> {
    if (!payload) {
      return Promise.resolve()
    }

    const { storeEvents } = this.storeRoot.stores.storeTrailFlow

    let createEventPromise = Promise.resolve()

    /**
     * Case of isDeviant:true
     */

    const createdEventIdStr = String(payload.createdEventId)

    const eventBeingFetched = this._eventBeingFetched.has(createdEventIdStr)

    if (
      this.storeRoot.stores.storeTrailFlow.isDeviantFilterEnabled &&
      !eventBeingFetched
    ) {
      this._eventBeingFetched.set(createdEventIdStr, true)

      createEventPromise = storeEvents
        .retrieveOneEvent(payload.directoryId, createdEventIdStr)
        .then(event => {
          if (!event) {
            this.storeRoot.logger.warn(`Event not found ${createdEventIdStr}`)
            return
          }

          this._eventBeingFetched.delete(createdEventIdStr)

          return storeEvents.addEvent(event)
        })
    }

    /**
     * Case of isDeviant:false (or not set)
     */

    return createEventPromise.then(() => {
      const eventIdStr = String(
        payload.resolvedEventId || payload.createdEventId
      )

      if (!eventIdStr || !payload.profileId) {
        this.storeRoot.logger.warn(
          'eventId or profileId is not set. Verify the payload of the websocket message.'
        )
        return
      }

      // if the eventId is not displayed anymore, drop the deviance
      if (storeEvents.eventIds.indexOf(eventIdStr) === -1) {
        return
      }

      const isTenableProfile = Boolean(
        this.storeRoot.stores.storeAuthentication.tenableProfile?.id ===
          payload.profileId
      )

      this._addDeviance(eventIdStr, {
        devianceId: payload.id,
        isTenableProfile,
        profileId: payload.profileId,
        isResolved: isDefined(payload.resolvedAt)
      })

      this.updateDeviancesActivity()
    })
  }

  /**
   * Unsubscribe deviances.
   */
  unregisterDevianceWS(): this {
    this._devianceWSRegistrations.forEach(registration => {
      this.storeRoot.wsClient.removeRegistration(registration)
    })

    this._devianceWSRegistrations = []

    return this
  }

  /**
   * Subscribe to deviances for the current profile.
   */
  registerDevianceWS(): void {
    // if not allowed to see deviances (according to the license),
    // don't register the WS to prevent deviances live messages.
    if (!this.storeRoot.stores.storeRbac.isUserGrantedTo(canSeeDeviances)) {
      return
    }

    const { storeInfrastructures, storeAuthentication } = this.storeRoot.stores

    // if registering again, drop existing registrations (to avoid duplication)
    this.unregisterDevianceWS()

    const { storeDeviances, storeInputExpression } =
      this.storeRoot.stores.storeTrailFlow

    const selectedDirectoryIds = storeInfrastructures.getSelectedDirectoryIds()

    // register one deviance channel by profile
    this._devianceWSRegistrations = storeAuthentication.profileIds.map(
      profileId => {
        return this.storeRoot.wsClient.addRegistration<
          IWSDevianceMessage['payload']
        >(
          'Deviance',
          {
            name: WSEntityName.deviance,
            payload: {
              profileId,
              directories: selectedDirectoryIds,
              expression: storeInputExpression.expression.expressionAsObject
            }
          },
          storeDeviances.updateEventDeviances.bind(this)
        )
      }
    )
  }

  /**
   * Purge deviances for deleted events.
   * This purge won't trigger a refresh since the map is not observed.
   */
  purgeDeviances(removedEventEntities: EntityEvent[]): this {
    removedEventEntities.forEach(eventEntity => {
      this._deviances.delete(eventEntity.getPropertyAsString('id'))
    })

    return this
  }

  /**
   * Reset deviances.
   */
  reset(): this {
    this._deviances.clear()
    this._eventBeingFetched.clear()

    return this.updateDeviancesActivity()
  }

  /**
   * Return the deviances map.
   */
  get deviances(): Map<EventId, Map<number, IEventDeviance>> {
    return this._deviances
  }

  /* Actions */

  /**
   * Toggle the observable to trigger a refresh since deviances map is not observed
   * for performances reasons.
   */
  @action
  updateDeviancesActivity(): this {
    this.$deviancesActivity.set(this.$deviancesActivity.get() ^ 1)
    return this
  }

  /* Computed */

  @computed
  get deviancesActivity(): number {
    return this.$deviancesActivity.get()
  }
}
