import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import type {
  HealthCheckAttribute,
  Maybe
} from '@server/graphql/typeDefs/types'

export default class EntityHealthCheckAttribute
  extends EntityBase
  implements PropertiesNullable<HealthCheckAttribute>
{
  name: Maybe<string> = null
  value: Maybe<string> = null
  valueType: Maybe<string> = null

  constructor(data: Partial<HealthCheckAttribute>) {
    super()
    Object.assign(this, data)
  }
}
