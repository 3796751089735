import type { Maybe } from '@@types/helpers'
import type { SceneCoordinates, SceneCoordinatesOffset } from './types'

/**
 * Wrap all objects of the scene with coordinates.
 */
export default class SceneObject<O> {
  private _coordinates: SceneCoordinates
  private _object: Maybe<O>

  constructor(coordinates: SceneCoordinates, object?: O) {
    this._coordinates = coordinates
    this._object = object || null
  }

  /**
   * Return coordinates.
   */
  get coordinates(): SceneCoordinates {
    return this._coordinates
  }

  /**
   * Return x, y.
   */
  get xy(): { x: number; y: number } {
    return {
      x: this._coordinates.x,
      y: this._coordinates.y
    }
  }

  /**
   * Return the wrapped object.
   */
  get object(): O {
    if (!this._object) {
      throw new Error('Object has not been defined!')
    }
    return this._object
  }

  /**
   * Return the transform translate attribute value.
   */
  get translateString(): string {
    return `translate(${this._coordinates.x}, ${this._coordinates.y})`
  }

  /**
   * Add offset on x, y.
   *
   * If options.clone is true, return a new SceneObject object.
   */
  addOffset(sceneOffset: SceneCoordinatesOffset, options = { clone: false }) {
    const newCoordinates: SceneCoordinates = {
      x: this._coordinates.x + sceneOffset.x,
      y: this._coordinates.y + sceneOffset.y,
      radius: this._coordinates.radius
    }

    if (options.clone) {
      return new SceneObject(newCoordinates, this.object)
    }

    this._coordinates = newCoordinates

    return this
  }
}
