import { consts } from '@app/styles'
import { computeLabelColor } from '@app/styles/colors/getLabelColor'
import { Tag } from 'antd'
import * as React from 'react'
import styled from 'styled-components'

const Label = styled.div<{ margin: boolean }>`
  ${props => props.margin && `margin-left: ${consts.marginVerySmall};`}
`

interface ILabelColorTagProps {
  className?: string
  color: string
  icon?: React.ReactNode
  label?: string
  children?: React.ReactNode
}

const LabelColorTag: React.FC<ILabelColorTagProps> = props => {
  return (
    <Tag className={props.className} color={props.color} icon={props.icon}>
      <Label margin={Boolean(props.icon)}>
        {props.label ?? props.children}
      </Label>
    </Tag>
  )
}

/**
 * @deprecated
 */
export default styled(LabelColorTag)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: ${consts.fontWeightBold};
  height: 20px;
  padding: 0 ${consts.paddingVerySmall};
  border: 0;
  border-radius: 3px;
  color: ${props => computeLabelColor(props.color)};
`
