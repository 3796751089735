import { ContainerFlex } from '@app/components-legacy/Container'
import type EntityAttackPathNode from '@app/entities/EntityAttackPathNode'
import * as React from 'react'
import NodeObjectIcon from '../Components/NodeObjectIcon'
import DrawerNodeRelationshipNameDirectory from './DrawerNodeRelationshipNameDirectory'

export interface IDrawerNodeRelationshipProps {
  className?: string
  style?: React.CSSProperties
  entityAttackPathNode: EntityAttackPathNode
  maxWidth: number
}

const DrawerNodeRelationship: React.FC<
  IDrawerNodeRelationshipProps
> = props => {
  return (
    <ContainerFlex
      name="DrawerNodeRelationship"
      className={props.className}
      style={props.style}
      alignItems="center"
      direction="row"
      spaceWidth="small"
      itemsFlexGrow={[0, 1]}
      items={[
        <NodeObjectIcon
          adObjectType={props.entityAttackPathNode.adObjectType}
          size={35}
        />,

        <DrawerNodeRelationshipNameDirectory
          entityAttackPathNode={props.entityAttackPathNode}
          maxWidth={props.maxWidth - 35 - 20}
        />
      ]}
      spaced
    />
  )
}

export default DrawerNodeRelationship
