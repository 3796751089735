import type { PropertiesNullable } from '@@types/helpers'
import type {
  AuthConfigurationAllowedGroups,
  LdapConfiguration,
  Maybe
} from '@server/graphql/typeDefs/types'

export default class EntityLDAPConfiguration
  implements PropertiesNullable<LdapConfiguration>
{
  enabled: Maybe<boolean> = null
  url: Maybe<string> = null
  searchUserDN: Maybe<string> = null
  searchUserPassword: Maybe<string> = null
  userSearchBase: Maybe<string> = null
  userSearchFilter: Maybe<string> = null
  allowedGroups: Maybe<AuthConfigurationAllowedGroups[]> = null
  enableSaslBinding: Maybe<boolean> = null
  relayId: Maybe<number> = null
  constructor(data: Partial<LdapConfiguration>) {
    Object.assign(this, data)
  }
}
