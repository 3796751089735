import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'

interface IIconLineChartProps extends IIconProps {}

const IconLineChart: React.FC<IIconLineChartProps> = props => {
  const size = props.size ?? 60

  return (
    <svg
      className={props.className}
      style={props.style}
      width={size}
      height={size}
      fill="white"
      viewBox="0 0 60 60"
    >
      <line
        x1="1.58083"
        y1="57.6493"
        x2="10.6493"
        y2="40.4192"
        stroke="#0A73DF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="13.1318"
        y1="35.5039"
        x2="21.1318"
        y2="21.5039"
        stroke="#0A73DF"
        strokeWidth="2"
      />
      <line x1="25" y1="20" x2="38" y2="20" stroke="#0A73DF" strokeWidth="2" />
      <line
        x1="41.2628"
        y1="17.3243"
        x2="52.2628"
        y2="5.32428"
        stroke="#0A73DF"
        strokeWidth="2"
      />
      <path
        d="M1.5 58L9.80683 56.241"
        stroke="#5FA6EF"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="14.1318"
        y1="53.5039"
        x2="22.1318"
        y2="39.5039"
        stroke="#5FA6EF"
        strokeWidth="2"
      />
      <line
        x1="26.3162"
        y1="37.0513"
        x2="38.3162"
        y2="41.0513"
        stroke="#5FA6EF"
        strokeWidth="2"
      />
      <line
        x1="41.2929"
        y1="40.2929"
        x2="52.2929"
        y2="29.2929"
        stroke="#5FA6EF"
        strokeWidth="2"
      />
      <circle cx="40" cy="42" r="3" stroke="#5FA6EF" strokeWidth="2" />
      <circle cx="54" cy="28" r="3" stroke="#5FA6EF" strokeWidth="2" />
      <circle cx="24" cy="38" r="3" stroke="#5FA6EF" strokeWidth="2" />
      <circle cx="13" cy="55" r="3" stroke="#5FA6EF" strokeWidth="2" />
      <circle cx="13" cy="38" r="3" stroke="#0A73DF" strokeWidth="2" />
      <circle cx="23" cy="20" r="3" stroke="#0A73DF" strokeWidth="2" />
      <circle cx="40" cy="20" r="3" stroke="#0A73DF" strokeWidth="2" />
      <circle cx="54" cy="4" r="3" stroke="#0A73DF" strokeWidth="2" />
    </svg>
  )
}

export default IconLineChart
