import { BulletCircle } from '@app/components-legacy/Bullet'
import {
  ContainerContent,
  ContainerFlex,
  ContainerSimple
} from '@app/components-legacy/Container'
import { LabelAlt } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { CardRank } from '@app/pages/IoA/IoABoardPage/IoABoardCards/CardChart/CardRank'
import type StoreSummaryCard from '@app/stores/IoA/StoreBoard/StoreSummaryCard'
import { consts } from '@app/styles'
import { Criticity } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import CardIconShield from './CardIconShield'
import DotnutChart from './DotnutChart'

const StyledDonutContainer = styled(ContainerSimple)`
  position: relative;
`

interface ICardAttacksByCriticityChartProps {
  style?: React.CSSProperties
  storeSummaryCard: StoreSummaryCard
}

const StyledCardIconShield = styled(CardIconShield)`
  height: 110px;
`

const CardAttacksByCriticityChart: React.FC<
  ICardAttacksByCriticityChartProps
> = props => {
  const translate = useAppTranslator({ namespaces: ['Buttons', 'IoA.Board'] })

  const legendData = props.storeSummaryCard.rateOfAttacksByCriticity
  const donutData = props.storeSummaryCard.hasAttacksDetected
    ? legendData
    : [
        {
          criticity: Criticity.Unknown,
          criticityColor: consts.colorGrey005,
          rate: 100
        }
      ]

  const getBulletColor = (criticityColor: string) => {
    return !props.storeSummaryCard.hasAttacksDetected
      ? consts.colorGrey005
      : criticityColor
  }

  const getContent = () => {
    const legendItems = legendData.map(data => {
      return (
        <ContainerFlex
          key={data.criticity}
          name={`CardChartDonutLayoutLegend${data.criticity}`}
          justifyContent="space-between"
          alignItems="center"
          items={[
            <ContainerFlex
              key={data.criticity}
              name={`CardChartDonutLayoutLegend${data.criticity}Name`}
              justifyContent="space-between"
              alignItems="center"
              items={[
                <BulletCircle color={getBulletColor(data.criticityColor)} />,

                <div>{translate(data.criticity)}</div>
              ]}
              spaced
              spaceWidth="small"
            />,

            <div>{data.rate} %</div>
          ]}
          spaced
        />
      )
    })

    return (
      <ContainerFlex
        name="ChartContent"
        direction="column"
        justifyContent="space-between"
        items={[
          <ContainerFlex
            name="ChartWithLabel"
            direction="column"
            items={[
              <LabelAlt variant={LabelAltVariant.Bolded}>
                {translate('Attack distribution')}
              </LabelAlt>,

              <ContainerFlex
                name="Chart"
                justifyContent="space-between"
                items={[
                  <ContainerFlex
                    name="ChardLegend"
                    direction="column"
                    items={legendItems}
                    spaced
                    spaceWidth="small"
                  />,

                  <StyledDonutContainer>
                    <DotnutChart
                      data={donutData}
                      width={110}
                      height={110}
                      innerRadius={37}
                      outerRadius={54}
                    />

                    {!props.storeSummaryCard.hasAttacksDetected && (
                      <StyledCardIconShield />
                    )}
                  </StyledDonutContainer>
                ]}
              />
            ]}
            paddingH="default"
            spaced
          />,

          <CardRank storeSummaryCard={props.storeSummaryCard} />
        ]}
        fullHeight
      />
    )
  }

  return (
    <ContainerContent
      name="CardAttacksByCriticityChart"
      style={props.style}
      flags={props.storeSummaryCard.storeFlagsFetchAttackStats.flags}
      spinner={<SpinnerInline />}
    >
      {getContent()}
    </ContainerContent>
  )
}

export default observer(CardAttacksByCriticityChart)
