import type { Maybe } from '@@types/helpers'
import { canDisplayAzureAdBecauseEnabled } from '@app/pages/permissions'
import {
  rbacCapabilityCheckAllOf,
  rbacCapabilityCheckAnyOf
} from '@libs/rbac/functions'
import {
  readCheckers,
  readDirectories,
  readInfrastructures,
  readLicenseFeatureIoE,
  readTenants,
  readUIIoE,
  readUIIoEIdentity
} from '@libs/rbac/permissions'

/** IoE for ActiveDirectory */

export const canAccessToAdIoE = rbacCapabilityCheckAllOf(
  readUIIoE(),
  readLicenseFeatureIoE(),
  readInfrastructures(),
  readDirectories(),
  readCheckers()
)

export const canAccessToAdIoEDetails = (checkerId: Maybe<number>) => {
  return rbacCapabilityCheckAllOf(canAccessToAdIoE, readCheckers(checkerId))
}

/* IoE for EntraID */

export const canAccessToIdentityIoE = rbacCapabilityCheckAllOf(
  canDisplayAzureAdBecauseEnabled,
  readUIIoEIdentity(),
  readTenants()
)

export const canAccessToIdentityIoEDetails = rbacCapabilityCheckAllOf(
  canAccessToIdentityIoE
)

/* For all kind of IoE */

export const canAccessToIoE = rbacCapabilityCheckAnyOf(
  canAccessToAdIoE,
  canAccessToIdentityIoE
)

export const canAccessToAdAndIdentityIoE = rbacCapabilityCheckAllOf(
  canAccessToAdIoE,
  canAccessToIdentityIoE
)
