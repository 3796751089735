import { ContainerFlex } from '@app/components/Container'
import { IconCheck, IconCloseCircle, IconDirection } from '@app/components/Icon'
import { Direction } from '@app/components/Icon/IconDirection'
import {
  ContainerHTMLContent,
  ContainerSimple
} from '@app/components-legacy/Container'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { consts } from '@app/styles'
import { BackgroundColor, CTAColorV2, FontColorV2 } from '@app/styles/consts'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { ensureArray } from '@libs/ensureArray'
import { isDefined } from '@libs/isDefined'
import { Select } from 'antd'
import type { SelectProps, SelectValue } from 'antd/lib/select'
import { kebabCase } from 'lodash'
import * as React from 'react'
import styled, { css } from 'styled-components'
import { CheckboxDiv } from './FormWrapperCheckboxSimple'
import type { IFormWrapperControl } from './types'
import { boldAndOrderSelectOptions, getSelectWidth } from './utils'

const Unchecked = styled(CheckboxDiv)``

const Checked = styled(CheckboxDiv)``

const Count = styled.span``

const StyledDropdownDiv = styled.div<{ isMultipleOrTags: boolean }>`
  .ant-select-item {
    color: ${({ isMultipleOrTags, theme }) =>
      isMultipleOrTags
        ? theme.tokens['select/color/textSelected']
        : theme.tokens['select/color/text']};
  }

  .ant-select-item-option-state {
    display: none;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: inherit;
    font-weight: normal;
    color: ${FontColorV2.primary};
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${BackgroundColor.primaryHover};
  }

  .rc-virtual-list-scrollbar {
    width: 5px !important;
  }

  ${Checked} {
    display: none;
  }

  .ant-select-item-option-selected ${Unchecked} {
    display: none;
  }

  .ant-select-item-option-selected ${Checked} {
    display: flex;
  }
`

export interface IFormWrapperSelectProps<T = SelectValue>
  extends IFormWrapperControl {
  width?:
    | 'verySmall'
    | 'small'
    | 'smallNormal'
    | 'normal'
    | 'medium'
    | 'large'
    | 'extraLarge'
    | 'auto'
  height?: 'fixed' | 'auto'
  selectProps?: SelectProps<T>
  selectOptions: Array<
    IFormWrapperSelectGroupOptionProps | IFormWrapperSelectOptionProps
  >
  renderOption?: (option: IFormWrapperSelectOptionProps) => React.ReactNode
}

export interface IFormWrapperSelectGroupOptionProps {
  groupLabel: string
  options: IFormWrapperSelectOptionProps[]
}

export interface IFormWrapperSelectOptionProps {
  labelledBy?: string
  className?: string
  style?: React.CSSProperties
  label: string
  value: string | number
  disabled?: boolean
  count?: number
}

export function FormWrapperSelect<T = SelectValue>(
  props: IFormWrapperSelectProps<T>
): React.ReactElement {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const { testAttributeProps } = useTestAttribute('form')

  const [searchValue, setSearchValue] = React.useState('')
  const [dropdownVisible, setDropdownVisible] = React.useState(false)

  const boldedOptions = boldAndOrderSelectOptions(
    props.selectOptions,
    searchValue
  )

  const isMultipleOrTags =
    props.selectProps?.mode === 'multiple' || props.selectProps?.mode === 'tags'

  const renderOption = (option: IFormWrapperSelectOptionProps) => {
    return (
      <Select.Option
        style={option.style}
        key={option.value}
        value={option.value}
        label={option.label}
        disabled={option.disabled}
      >
        {themeIsLegacy ? (
          <ContainerSimple
            name="FormWrapperSelectOption"
            ariaRoles={[
              'form',
              'listbox',
              'option',
              ...ensureArray(props.ariaRoles)
            ]}
            labelledBy={option.labelledBy || (option.value as string)}
          >
            {option.label}
          </ContainerSimple>
        ) : (
          <ContainerFlex
            name="FormWrapperSelectOption"
            ariaRoles={[
              'form',
              'listbox',
              'option',
              ...ensureArray(props.ariaRoles)
            ]}
            labelledBy={option.labelledBy || (option.value as string)}
            alignItems="center"
            itemsFlexGrow={[0, 0, 1]}
            items={[
              isMultipleOrTags && (
                <Checked checked>
                  <IconCheck />
                </Checked>
              ),
              isMultipleOrTags && <Unchecked />,
              <div>
                {props.renderOption ? (
                  props.renderOption(option)
                ) : (
                  <ContainerHTMLContent inline>
                    {option.label}
                  </ContainerHTMLContent>
                )}
                {isDefined(option.count) && <Count> ({option.count})</Count>}
              </div>
            ]}
            spaced
            spaceWidth="small"
          />
        )}
      </Select.Option>
    )
  }

  if (themeIsLegacy) {
    return (
      <Select<any>
        {...props.selectProps}
        {...testAttributeProps('listbox')(props.labelledBy)}
        // use inline style to work around the limitation of using styled + generic
        style={{
          width: getSelectWidth(props.width)
        }}
      >
        {props.selectOptions.map(option => {
          if ('groupLabel' in option) {
            return (
              <Select.OptGroup
                key={kebabCase(String(option.groupLabel))}
                label={option.groupLabel}
              >
                {option.options.map(renderOption)}
              </Select.OptGroup>
            )
          }

          return renderOption(option)
        })}
      </Select>
    )
  }

  return (
    <Select<any>
      className={props.className}
      clearIcon={<IconCloseCircle size={16} color={FontColorV2.secondary} />}
      {...props.selectProps}
      {...testAttributeProps('listbox')(props.labelledBy)}
      dropdownRender={menu => (
        <StyledDropdownDiv isMultipleOrTags={isMultipleOrTags}>
          {menu}
        </StyledDropdownDiv>
      )}
      dropdownStyle={{
        border: 0,
        padding: 0,
        boxShadow: consts.boxShadowRedesignLight
      }}
      // use inline style to work around the limitation of using styled + generic
      style={{
        width: getSelectWidth(props.width),
        maxWidth: props.width === 'auto' ? '100%' : undefined
      }}
      showArrow={true}
      suffixIcon={
        <IconDirection
          direction={Direction.down}
          color={
            props.selectProps?.disabled
              ? theme.tokens['select/color/iconDisabled']
              : theme.tokens['select/color/icon']
          }
        />
      }
      onBlur={() => {
        setSearchValue('')
      }}
      onChange={(value, option) => {
        setSearchValue('')
        if (props.selectProps?.onChange) {
          props.selectProps.onChange(value, option)
        }
      }}
      onDropdownVisibleChange={visible => setDropdownVisible(visible)}
      onSearch={
        isMultipleOrTags || props.selectProps?.showSearch
          ? value => {
              setSearchValue(value)
            }
          : undefined
      }
    >
      {boldedOptions.map(option => {
        if ('groupLabel' in option) {
          return (
            <Select.OptGroup
              key={kebabCase(String(option.groupLabel))}
              label={option.groupLabel}
            >
              {option.options.map(renderOption)}
            </Select.OptGroup>
          )
        }

        return renderOption(option)
      })}
    </Select>
  )
}

export default styled(FormWrapperSelect)`
  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      overflow: hidden;
      color: ${FontColorV2.primary};

      .ant-select-selector {
        background-color: ${props =>
          props.theme.tokens['select/backgroundColor/default']} !important;
        border: 0 !important;
        border-radius: ${props.theme.tokens[
          'select/borderRadius/default'
        ]} !important;
        width: 100%;
        ${props.height === 'auto' ? '' : 'height: 30px;'}
        box-shadow: none !important;
      }

      .ant-select-selection-overflow {
        ${props.height === 'auto' ? '' : 'flex-wrap: nowrap;'}
        ${props.height === 'auto' ? '' : 'overflow: hidden;'}
      }

      .ant-select-selection-placeholder {
        color: ${props.theme.tokens['select/color/placeholder']};
      }

      &.ant-select-single.ant-select-open .ant-select-selection-item {
        color: ${FontColorV2.primary};
      }

      &.ant-select-multiple .ant-select-selection-item {
        height: 20px;
        border: 0;
        background-color: ${CTAColorV2.primaryHover};
        color: ${consts.colorWhite};
      }

      &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        color: ${props.theme.tokens['select/color/textDisabled']};
        background: ${props.theme.tokens[
          'select/backgroundColor/disabled'
        ]} !important;
      }

      .ant-select-selection-item {
        display: flex;
        align-items: center;

        > * {
          flex-grow: 1;
        }

        ${Checked}, ${Unchecked}, ${Count} {
          display: none;
        }
      }

      .ant-select-selection-item-content b {
        font-weight: normal;
      }

      .ant-select-selection-item-remove svg {
        fill: ${consts.colorWhite};
      }

      .ant-select-clear {
        width: 16px;
        height: 16px;
        margin-top: -8px;
        right: 10px;
      }
    `}
` as typeof FormWrapperSelect // Force generic type
