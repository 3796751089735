import { useAppTranslator } from '@app/hooks'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { Radio } from 'antd'
import type { RadioChangeEvent } from 'antd/lib/radio'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { WidgetCommonFormFieldName, WidgetCommonFormUserStatus } from '../types'

export interface IWidgetInputUserStatusProps {
  storeForm: StoreForm<any>
}

const WidgetInputUserStatus: React.FC<IWidgetInputUserStatusProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Dashboard.WidgetCommonForm']
  })

  const onChange = (e: RadioChangeEvent) => {
    props.storeForm.setFieldValue(
      WidgetCommonFormFieldName.dataOptionsUserStatus,
      e.target.value
    )
  }

  return (
    <Radio.Group
      name={WidgetCommonFormFieldName.dataOptionsUserStatus}
      onChange={onChange}
      value={props.storeForm.getFieldValueAsString(
        WidgetCommonFormFieldName.dataOptionsUserStatus,
        WidgetCommonFormUserStatus.active
      )}
    >
      <Radio value={WidgetCommonFormUserStatus.active}>
        {translate('Active')}
      </Radio>

      <Radio value={WidgetCommonFormUserStatus.inactive}>
        {translate('Inactive')}
      </Radio>

      <Radio value={WidgetCommonFormUserStatus.all}>{translate('All')}</Radio>
    </Radio.Group>
  )
}

export default observer(WidgetInputUserStatus)
