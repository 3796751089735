import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  ContainerContent,
  ContainerFooter,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import {
  Drawer,
  DrawerCancelButton,
  DrawerContext,
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { FormWrapperSelect } from '@app/components-legacy/Form'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { AttacksPageContext } from '../context'
import { canAccessToAttacksExport } from '../permissions'
import { handleExportAttacksOnSubmit } from './handlers'

export interface IIoAAttacksDrawerExportAttacksProps {}

const IoAAttacksDrawerExportAttacks: React.FC<
  IIoAAttacksDrawerExportAttacksProps
> = () => {
  const attacksPageContext = React.useContext(AttacksPageContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Export', 'IoA.Attacks']
  })

  if (!attacksPageContext) {
    return null
  }

  const { storeAttacks } = attacksPageContext

  const renderContent = () => {
    const fields: IField[] = [
      {
        name: 'exportFormat',
        label: translate('Export format'),
        control: (
          <FormWrapperSelect
            labelledBy="exportFormat"
            selectProps={{
              defaultValue: 'csv'
            }}
            selectOptions={[
              {
                label: 'CSV',
                value: 'csv'
              }
            ]}
          />
        )
      }
    ]

    return (
      <FormWrapper
        name="attacksExport"
        onSubmit={handleExportAttacksOnSubmit(storeAttacks)}
      >
        <ContainerContent>
          <p>
            {translate(
              'You are exporting the incidents of the current context'
            )}
          </p>

          <ContainerContent>
            <ContainerForm
              variant={ContainerFormVariant.drawer}
              fields={fields}
            />
          </ContainerContent>
        </ContainerContent>

        <Portal name={PlaceHolderName.drawerFooter}>
          <ContainerFooter>
            <DrawerCancelButton />

            <FormWrapperButtonSubmit>
              {translate('Export all')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </FormWrapper>
    )
  }

  return (
    <DrawerContext.Provider
      value={{ storeDrawer: storeAttacks.storeDrawerExportAttacks }}
    >
      <Drawer>
        <DrawerHorizontalContainer>
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: <DrawerTitle>{translate('Export incidents')}</DrawerTitle>,
              content: renderContent(),
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canAccessToAttacksExport}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(IoAAttacksDrawerExportAttacks)
