import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import { ensureArray } from '@libs/ensureArray'
import type {
  DashboardWidgetOptionsSerieStatAttack,
  DashboardWidgetOptionsSerieStatDataPointCount,
  Maybe
} from '@server/graphql/typeDefs/types'
import { createEntities } from '.'
import EntityAttacksStatData from './EntityAttacksStatData'

export type AttacksStat = Pick<
  DashboardWidgetOptionsSerieStatAttack,
  'attackTypeIds' | 'timestampStart' | 'timestampEnd' | 'data'
>

export default class EntityAttacksStat
  extends EntityBase
  implements PropertiesNullable<AttacksStat>
{
  attackTypeIds: Maybe<AttacksStat['attackTypeIds']> = null
  timestampStart: Maybe<AttacksStat['timestampStart']> = null
  timestampEnd: Maybe<AttacksStat['timestampEnd']> = null
  data: Maybe<AttacksStat['data']> = null

  constructor(data: Partial<AttacksStat>) {
    super()
    Object.assign(this, data)
  }

  getAttackTypeIds(): number[] {
    return ensureArray(this.attackTypeIds)
  }

  get dataEntities(): EntityAttacksStatData[] {
    return createEntities<
      DashboardWidgetOptionsSerieStatDataPointCount,
      EntityAttacksStatData
    >(EntityAttacksStatData, ensureArray(this.data))
  }
}
