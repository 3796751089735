import type { PropertiesNullable } from '@@types/helpers'
import type {
  Maybe,
  RbacRole,
  AuthProviderName,
  WhoAmI
} from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityWhoAmI
  extends EntityBase
  implements PropertiesNullable<WhoAmI>
{
  id: Maybe<number> = null
  provider: Maybe<AuthProviderName> = null
  identifier: Maybe<string> = null
  surname: Maybe<string> = null
  name: Maybe<string> = null
  email: Maybe<string> = null
  eulaVersion: Maybe<number> = null
  department?: Maybe<string> = null
  active: Maybe<boolean> = null
  roles: Maybe<RbacRole[]> = null

  constructor(data: Partial<EntityWhoAmI>) {
    super()
    Object.assign(this, data)
  }
}
