import type { MaybeUndef } from '@@types/helpers'
import { containsStrings } from '@libs/containsStrings'
import {
  sddlSids,
  wellKnownFilteredTrusteeSids,
  wellKnownGuidsWithControlAccess,
  wellKnownGuidsWithSelfWrite,
  wellKnownGuidsWithWriteProperties,
  wellKnownSidsWithAce,
  wellKnownSidsWithAcePatterns
} from '@libs/sddl/consts'
import type {
  ISddlTree,
  SddlSidsEnum,
  WellKnownExtendedGuidsEnum,
  WellKnownGuidsEnum
} from '@libs/sddl/types'
import { ACETitle } from '@libs/sddl/types'
import {
  getAccountSidDefinitions,
  getGuidDefinitions,
  getRightsDefinitions,
  getTypeDefinitions
} from './definitions'
import { ACEColorEnum } from './types'
import { isAce, matchEnumOrLabel, matchEnumPatternOrLabel } from './util'

/**
 * Return a color for ACE sddl depending on their rights
 */
export const getAceColor = (sddlTree: ISddlTree): MaybeUndef<ACEColorEnum> => {
  if (sddlTree.title !== ACETitle.ACE) {
    return
  }

  if (
    hasAllowedTypes(sddlTree) &&
    !hasFilteredTrustee(sddlTree) &&
    hasRights(sddlTree)
  ) {
    if (!hasSidPrivileges(sddlTree)) {
      // An unprivileged trustee has a control ACE on the object
      return ACEColorEnum.RED
    }

    // A built-in privileged user/group has a control ACE on the object
    return ACEColorEnum.ORANGE
  }

  // There is no control ACE on the object
  return ACEColorEnum.GREEN
}

// --- SDDL CHECKERS ---

export function hasAllowedTypes(sddlTree: ISddlTree): boolean {
  const allowedRights = ['ALLOWED']

  return [sddlTree]
    .filter(isAce)
    .flatMap(getTypeDefinitions)
    .some(typeDef => containsStrings(allowedRights, typeDef))
}

export function hasFilteredTrustee(sddlTree: ISddlTree): boolean {
  /**
   * Find both in key and value to match with enums or bigrams
   */
  const matchWellKnownFilteredTrustee = (sidDef: string): boolean => {
    const sidLabel = sddlSids.get(sidDef as SddlSidsEnum)

    return Array.from(wellKnownFilteredTrusteeSids.entries()).some(x => {
      return matchEnumOrLabel(sidDef, sidLabel)(x)
    })
  }

  return [sddlTree]
    .filter(isAce)
    .flatMap(getAccountSidDefinitions)
    .some(matchWellKnownFilteredTrustee)
}

export function hasRights(sddlTree: ISddlTree): boolean {
  const rights = {
    generic: ['GENERIC_ALL', 'GENERIC_WRITE'],
    standard: ['WRITE_DAC', 'WRITE_OWNER', 'DELETE'],
    writeProperty: ['ADS_RIGHT_DS_WRITE_PROP'],
    writeProperty2: [
      'ADS_RIGHT_DS_CREATE_CHILD',
      'ADS_RIGHT_DS_DELETE_CHILD',
      'ADS_RIGHT_DS_DELETE_TREE'
    ],
    extended: ['ADS_RIGHT_DS_CONTROL_ACCESS'],
    validated: ['ADS_RIGHT_DS_SELF']
  }

  const guidDefinitions = [sddlTree].filter(isAce).flatMap(getGuidDefinitions)

  const hasNoGuids = !guidDefinitions.length

  return [sddlTree]
    .filter(isAce)
    .flatMap(getRightsDefinitions)
    .some(rightsDef => {
      const hasWritePropertiesGuids = () =>
        guidDefinitions.some(guid =>
          wellKnownGuidsWithWriteProperties.has(guid as WellKnownGuidsEnum)
        )

      const hasControlAccessGuids = () =>
        guidDefinitions.some(guid =>
          wellKnownGuidsWithControlAccess.has(
            guid as WellKnownExtendedGuidsEnum
          )
        )

      const hasSelfWriteGuids = () =>
        guidDefinitions.some(guid =>
          wellKnownGuidsWithSelfWrite.has(guid as WellKnownGuidsEnum)
        )

      return (
        containsStrings(rights.generic, rightsDef) ||
        containsStrings(rights.standard, rightsDef) ||
        (containsStrings(rights.writeProperty, rightsDef) &&
          (hasNoGuids || hasWritePropertiesGuids())) ||
        containsStrings(rights.writeProperty2, rightsDef) ||
        (containsStrings(rights.extended, rightsDef) &&
          (hasNoGuids || hasControlAccessGuids())) ||
        (containsStrings(rights.validated, rightsDef) &&
          (hasNoGuids || hasSelfWriteGuids()))
      )
    })
}

export function hasSidPrivileges(sddlTree: ISddlTree): boolean {
  /**
   * Find both in key and value to match with enums or bigrams
   */
  const matchWellKnownSidsWithAces = (sidDef: string): boolean => {
    const sidLabel = sddlSids.get(sidDef as SddlSidsEnum)

    const matchWellKnownEnum = Array.from(wellKnownSidsWithAce.entries()).some(
      matchEnumOrLabel(sidDef, sidLabel)
    )

    const matchWellKnownEnumPattern = Array.from(
      wellKnownSidsWithAcePatterns.entries()
    ).some(matchEnumPatternOrLabel(sidDef, sidLabel))

    return matchWellKnownEnum || matchWellKnownEnumPattern
  }

  return [sddlTree]
    .filter(isAce)
    .flatMap(getAccountSidDefinitions)
    .some(matchWellKnownSidsWithAces)
}
