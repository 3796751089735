import { AppRouteName } from '@app/routes'
import type { StoreIoA } from '@app/stores'
import type StoreBoard from '@app/stores/IoA/StoreBoard'
import type StoreSummaryCard from '@app/stores/IoA/StoreBoard/StoreSummaryCard'
import type StoreSummaryCards from '@app/stores/IoA/StoreBoard/StoreSummaryCards'
import type { AppRouterClient } from '@libs/Router/types'
import type { AttackResourceType } from '@server/graphql/typeDefs/types'
import { AttacksSummaryChartCardType } from '@server/graphql/typeDefs/types'
import { FieldName } from '../types'

/**
 * When clicking on a card, go to the attacks related to the resource.
 */
export const handleCardOnClick =
  (appRouter: AppRouterClient, storeIoA: StoreIoA) =>
  (parameters: { resourceType?: AttackResourceType; resourceValue?: string }) =>
  () => {
    const boardFilters = storeIoA.computeBoardQueryParameters()

    const attacksUrl = appRouter.makeRouteInfosPathname(
      {
        routeName: AppRouteName.IoA_Attacks,
        parameters: {},
        queryStringParameters: {
          boardFilters,
          attacksFilters: [
            {
              resourceType: parameters.resourceType,
              resourceValue: parameters.resourceValue
            }
          ]
        }
      },
      { arrayFormat: 'indices' }
    )

    appRouter.history.push(attacksUrl)
  }

/**
 * Open edit drawer and init store form
 */
export const handleCardEditionOnClick =
  (storeSummaryCards: StoreSummaryCards, storeSummaryCard: StoreSummaryCard) =>
  (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()

    const { storeFormEditCard, storeDrawerEditCard } = storeSummaryCards

    const { directoryId, chartType } =
      storeSummaryCard.options.attacksSummaryCardEntity

    if (!directoryId || !chartType) {
      return
    }

    // init form of the drawer
    storeFormEditCard
      .setDefaultFieldsValues([
        {
          key: FieldName.chartType,
          value: chartType
        }
      ])
      .reset()

    // open the drawer
    storeDrawerEditCard.setData({ directoryId }).openDrawer()
  }

/**
 * Refresh card
 */
export const handleCardRefreshOnClick =
  (storeBoard: StoreBoard, storeSummaryCard: StoreSummaryCard) =>
  (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()

    const { directoryId } = storeSummaryCard.options.attacksSummaryCardEntity

    if (!directoryId) {
      return
    }

    storeBoard
      .fetchAttackSummary(
        storeSummaryCard.storeFlagsFetchAttackStats,
        directoryId
      )
      .then(() => {
        const args = storeSummaryCard.computeArgs()

        if (!args) {
          return
        }

        const storeSummaryCardRefreshed =
          storeBoard.storeSummaryCards.storesSummaryCard.find(
            card =>
              card.directoryEntity && card.directoryEntity.id === directoryId
          )

        if (!storeSummaryCardRefreshed) {
          return
        }

        if (
          storeSummaryCard.options.attacksSummaryCardEntity.chartType ===
          AttacksSummaryChartCardType.LineChart
        ) {
          storeSummaryCardRefreshed.fetchAttacksStats(args)
        }
      })
  }
