import type { Maybe } from '@@types/helpers'
import type { EntityAttackType } from '@app/entities'
import {
  ExportFormat,
  ExportFormFieldName
} from '@app/pages/IoA/IoABoardPage/types'
import type { StoreRoot } from '@app/stores'
import { StoreInfrastructures } from '@app/stores'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreForm from '@app/stores/helpers/StoreForm'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { mandatory } from '@app/stores/helpers/StoreForm/validators'
import StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { ICheckerAttack } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { CriticityValuesOrdered } from '@libs/criticity'
import { ensureArray } from '@libs/ensureArray'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { checkRbac } from '@libs/rbac/functions'
import type { MutationEditAttacksSummaryLayout } from '@server/graphql/mutations/attack'
import { mutationEditAttacksSummaryLayout } from '@server/graphql/mutations/attack'
import type { QueryAttacksSummary } from '@server/graphql/queries/ioa'
import { queryAttacksSummary } from '@server/graphql/queries/ioa'
import type {
  Criticity,
  EditAttacksSummaryLayoutMutationArgs
} from '@server/graphql/typeDefs/types'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable } from 'mobx'
import { DEFAULT_SUMMARY_LAYOUT, DEFAULT_SUMMARY_PERIOD } from './consts'
import StoreSummaryCards from './StoreSummaryCards'
import StoreTimeline from './StoreTimeline'
import type { AttackSummaryArgs, IoABoardQueryStringParameters } from './types'

export default class StoreBoard extends StoreBase {
  public storeFlagsFetchAttacksSummary = new StoreFlags(this.storeRoot)
  public storeFlagsReloadAttacksSummary = new StoreFlags(this.storeRoot)
  public storeFlagsExport = new StoreFlags(this.storeRoot)

  public storeInfrastructures = new StoreInfrastructures(this.storeRoot)

  public storeTimeline = new StoreTimeline(this.storeRoot, {
    attacksSummaryPeriod: DEFAULT_SUMMARY_PERIOD
  })

  public storeSummaryCards = new StoreSummaryCards(this.storeRoot, {
    attacksSummaryLayout: DEFAULT_SUMMARY_LAYOUT
  })

  /* Drawer to export cards */

  public storeDrawerExportCards = new StoreDrawer(this.storeRoot)

  /* AttackTypes picker */

  public storeInputCheckersAttacks =
    new StoreInputGenericCheckers<ICheckerAttack>(this.storeRoot, {
      checkerType: CheckerType.Attack,
      selectable: true
    })

  public storeFormExport = new StoreForm<ExportFormFieldName>(this.storeRoot, {
    setup: {
      fields: {
        [ExportFormFieldName.exportFormat]: {
          label: 'Export format',
          labelAlignItem: 'center',
          inputType: InputType.select,
          validators: [mandatory()]
        },
        [ExportFormFieldName.exportUtc]: {
          label: 'Use UTC',
          labelAlignItem: 'center',
          inputType: InputType.checkbox
        }
      }
    }
  })
    .setDefaultFieldsValues([
      {
        key: ExportFormFieldName.exportFormat,
        value: ExportFormat.pdf
      },
      {
        key: ExportFormFieldName.exportUtc,
        value: false
      }
    ])
    .reset()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch attacks summary.
   */
  public fetchAttacksSummary(
    storeFlags = this.storeFlagsFetchAttacksSummary
  ): Promise<void> {
    storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args = this.getAttackSummaryArgs()

        if (!args) {
          return Promise.reject()
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<QueryAttacksSummary>(queryAttacksSummary, args)
      })
      .then(({ rbacAttacksSummary, attacksStats }) => {
        if (!checkRbac(this.storeRoot, storeFlags)(rbacAttacksSummary)) {
          throw new ForbiddenAccessError()
        }

        this.storeTimeline.setAttacksStats(attacksStats)

        this.storeSummaryCards.setSummaryLayout(rbacAttacksSummary.node.layout)
        this.storeSummaryCards.setSummaryCards(rbacAttacksSummary.node.cards)

        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Fetch attack summary.
   */
  public fetchAttackSummary(
    storeFlags = this.storeFlagsFetchAttacksSummary,
    directoryId: number
  ): Promise<void> {
    storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args = this.getAttackSummaryArgs()

        if (!args) {
          return Promise.reject()
        }

        args.directoryIds = [directoryId]

        return this.storeRoot
          .getGQLRequestor()
          .query<QueryAttacksSummary>(queryAttacksSummary, args)
      })
      .then(({ rbacAttacksSummary }) => {
        if (!checkRbac(this.storeRoot, storeFlags)(rbacAttacksSummary)) {
          throw new ForbiddenAccessError()
        }

        const filteredCard = rbacAttacksSummary.node.cards.find(
          card => card.directoryId === directoryId
        )

        if (!filteredCard) {
          return
        }

        this.storeSummaryCards.setSummaryCard(filteredCard)

        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Reload attacks summary after having cleaning existing data.
   */
  public reloadAttacksSummary = (): Promise<void> => {
    return this.fetchAttacksSummary(this.storeFlagsReloadAttacksSummary)
  }

  /**
   * Edit summary layout.
   */
  public editSummaryLayout(
    _args: EditAttacksSummaryLayoutMutationArgs
  ): Promise<void> {
    this.storeFlagsReloadAttacksSummary.loading()

    const args: EditAttacksSummaryLayoutMutationArgs = {
      ..._args,
      profileId: this.storeRoot.stores.storeAuthentication.currentProfileId
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<MutationEditAttacksSummaryLayout>(
            mutationEditAttacksSummaryLayout,
            args
          )
      })
      .then(() => {
        this.storeSummaryCards.setSummaryLayout(args.layout.layout)
        this.storeFlagsReloadAttacksSummary.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsReloadAttacksSummary)
      )
  }

  /**
   * Find attackType by its attackTypeId.
   */
  public getAttackTypeById(attackTypeId: number): Maybe<EntityAttackType> {
    return this.storeInputCheckersAttacks.checkersById.get(attackTypeId) || null
  }

  /**
   * Return stores parameters to fetch attacks summary.
   */
  public getAttackSummaryArgs(): Maybe<AttackSummaryArgs> {
    const { storeAuthentication } = this.storeRoot.stores

    const attacksSummaryPeriod = this.storeTimeline.attacksSummaryPeriod

    if (!attacksSummaryPeriod) {
      return null
    }

    // False if you want only open attacks.
    // True for closed attacks.
    // If not specified, won't filter anything.
    const isClosed = this.storeSummaryCards.displayOnlyAttackedDomainsSwitch
      ? false
      : undefined

    return {
      profileId: storeAuthentication.currentProfileId,
      dateStart: this.storeTimeline.dateStart,
      dateEnd: this.storeTimeline.dateEnd,
      interval: this.storeTimeline.interval,
      directoryIds: this.storeInfrastructures.getSelectedDirectoryIds(),
      ioaSeries: this.ioaSeriesBySelectedCriticity,
      layout: this.storeSummaryCards.summaryLayout,
      isClosed
    }
  }

  /* Actions */

  @action
  reset(): this {
    this.storeTimeline.reset()
    this.storeSummaryCards.reset()

    return this
  }

  /**
   * Save the filters of the querystring in the store.
   */
  @action
  updateFiltersStoresFromQueryString(
    ioaBoardQueryParams?: IoABoardQueryStringParameters
  ): this {
    if (!ioaBoardQueryParams) {
      return this
    }

    /** Update storeTimeline */
    if (ioaBoardQueryParams.period) {
      this.storeTimeline.setPeriod(ioaBoardQueryParams.period)
    }

    if (ioaBoardQueryParams.dateStart) {
      this.storeTimeline.setDateStart(ioaBoardQueryParams.dateStart)
    }

    /** Update storeInfrastructures */
    if (ioaBoardQueryParams.directoryIds) {
      const directoryIds = ensureArray(ioaBoardQueryParams.directoryIds).map(
        value => Number(value)
      )

      !directoryIds.length
        ? this.storeInfrastructures.selectAllDirectories()
        : this.storeInfrastructures.selectDirectories(directoryIds)
    }

    /** Update storeInputCheckersAttacks */
    if (ioaBoardQueryParams.checkersIds) {
      const checkersIds = ensureArray(ioaBoardQueryParams.checkersIds).map(
        value => Number(value)
      )

      if (!checkersIds.length) {
        this.storeInputCheckersAttacks.selectAllCheckers()
      } else {
        this.storeInputCheckersAttacks.unselectAllCheckers()
        this.storeInputCheckersAttacks.selectCheckersFromIds(checkersIds)
      }
    }

    /** Update storeSummaryCards */
    if (ioaBoardQueryParams.displayOnlyAttackedDomains) {
      this.storeSummaryCards.setDisplayOnlyAttackedDomainsSwitch(
        ioaBoardQueryParams.displayOnlyAttackedDomains === 'true'
      )
    }

    if (ioaBoardQueryParams.search) {
      this.storeSummaryCards.storeInputSearch.setSearchValue(
        ioaBoardQueryParams.search
      )
    }

    return this
  }

  /* Computed */

  /**
   * Return the list of attackType
   */
  @computed
  get attackTypeEntities(): EntityAttackType[] {
    return Array.from(this.storeInputCheckersAttacks.checkersById.values())
  }

  /**
   * Return ioaSeries of attackTypeIds by criticity.
   */
  @computed
  get ioaSeries(): number[][] {
    const getAttackTypesByCriticity = (criticity: Criticity) => {
      return this.attackTypeEntities.filter(attackTypeEntity => {
        return attackTypeEntity.genericCriticity === criticity
      })
    }

    return CriticityValuesOrdered.map(criticity => {
      return getAttackTypesByCriticity(criticity).map(attackTypeEntity =>
        attackTypeEntity.getPropertyAsNumber('id')
      )
    })
  }

  /**
   * Return ioaSeries of attackTypeIds by selected criticity.
   */
  @computed
  get ioaSeriesBySelectedCriticity(): number[][] {
    return this.ioaSeries.map(attackTypeIds => {
      const selectedCheckerIds =
        this.storeInputCheckersAttacks.selectedCheckerIds

      return attackTypeIds.filter(attackTypeId => {
        return selectedCheckerIds.some(
          selectedCheckerId => selectedCheckerId === attackTypeId
        )
      })
    })
  }

  /**
   * Return the querystring filters according to the current state of local
   * stores.
   */
  @computed
  get computedQueryStringFilters(): IoABoardQueryStringParameters {
    const displayOnlyAttackedDomains = this.storeSummaryCards
      .displayOnlyAttackedDomainsSwitch
      ? 'true'
      : 'false'

    return {
      dateStart: this.storeTimeline.dateStart,
      dateEnd: this.storeTimeline.dateEnd,
      directoryIds: this.storeInfrastructures.getSelectedDirectoryIds(),
      checkersIds: this.storeInputCheckersAttacks.selectedCheckerIds,
      period: this.storeTimeline.attacksSummaryPeriod,
      layout: this.storeSummaryCards.summaryLayout,
      search: this.storeSummaryCards.storeInputSearch.searchValue,
      displayOnlyAttackedDomains
    }
  }
}
