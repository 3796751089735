import { ContainerSimple } from '@app/components-legacy/Container'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import type { InputProps } from 'antd'
import { Input } from 'antd'
import * as React from 'react'
import styled, { css } from 'styled-components'
import type { IFormWrapperControl } from './types'

interface IFormWrapperInputProps extends IFormWrapperControl {
  inputProps: InputProps
}

const InputCustom = styled(Input)`
  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      border: 0;
      border-radius: ${consts.borderRadiusSmallRedesign};
      background-color: ${props.theme.tokens['input/backgroundColor/default']};
      color: ${props.theme.tokens['input/color/text']};
      height: ${props.theme.tokens['input/height/default']};
      padding: ${props.theme.tokens['input/padding/default']};

      &::placeholder {
        color: ${props.theme.tokens['input/color/placeholder']};
      }

      &.ant-input:focus,
      &.ant-input-focused,
      &.ant-input-affix-wrapper:focus,
      &.ant-input-affix-wrapper-focused {
        border: none;
        box-shadow: none;
      }
    `}
`

function FormWrapperInput(props: IFormWrapperInputProps) {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  return (
    <ContainerSimple
      name={`${FormWrapperInput.name}-${props.labelledBy}`}
      className={props.className}
      style={props.style}
      ariaRoles={props.ariaRoles || ['form', 'textbox']}
      labelledBy={props.labelledBy}
    >
      {themeIsLegacy ? (
        <Input {...props.inputProps}>{props.children}</Input>
      ) : (
        <InputCustom {...props.inputProps}>{props.children}</InputCustom>
      )}
    </ContainerSimple>
  )
}

export default FormWrapperInput
