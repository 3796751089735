import type { IGraphQLQuery } from '@libs/graphQL/types'
import type {
  Mutation,
  UpdateIoAConfigurationMutationArgs
} from '../typeDefs/types'

export type MutationUpdateAttackTypeConfiguration = Pick<
  Mutation,
  'updateAttackTypeConfiguration'
>

export const mutationUpdateAttackTypeConfiguration = `
  mutation mutationUpdateAttackTypeConfiguration(
    $attackTypeConfiguration: InputAttackTypeConfiguration!
  ) {
    updateAttackTypeConfiguration(
      attackTypeConfiguration: $attackTypeConfiguration
    )
  }
`

export type MutationUpdateIoAConfiguration = IGraphQLQuery<
  UpdateIoAConfigurationMutationArgs,
  Pick<Mutation, 'updateIoAConfiguration'>
>

export const mutationUpdateIoAConfiguration = `
  mutation mutationUpdateIoAConfiguration(
    $ioaConfiguration: InputIoaConfiguration!
  ) {
    updateIoAConfiguration(
      ioaConfiguration: $ioaConfiguration
    )
  }
`
