import { LabelSliced } from '@app/components/Labels'
import { CheckboxTree } from '@app/components-legacy/Checkbox'
import { onReasonSelection } from '@app/components-legacy/Input/InputReasons/handlers'
import StyledTree from '@app/components-legacy/Input/StyledTree'
import { LabelSliced as LabelSlicedDeprecated } from '@app/components-legacy/Label'
import type { EntityReason } from '@app/entities'
import type StoreInputReasons from '@app/stores/StoreInputReasons'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import type { TreeProps } from 'antd/lib/tree'
import Tree from 'antd/lib/tree'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export interface IReasonsTreeProps {
  className?: string
  style?: React.CSSProperties
  entitiesReason: EntityReason[]
  storeInputReasons: StoreInputReasons
}

const ReasonsTree: React.FC<IReasonsTreeProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const LocalLabelSliced = themeIsLegacy ? LabelSlicedDeprecated : LabelSliced

  const getTreeProps = (): TreeProps => {
    const treeProps: TreeProps = {}

    treeProps.treeData = props.entitiesReason.map(entityReason => ({
      title: (
        <span
          onClick={onReasonSelection(props.storeInputReasons)(
            entityReason.getPropertyAsNumber('id')
          )}
        >
          <LocalLabelSliced
            maxLength={50}
            value={entityReason.getPropertyAsString('name')}
          />
        </span>
      ),
      key: `${entityReason.getPropertyAsNumber('id')}`,
      icon: (
        <CheckboxTree
          onClick={onReasonSelection(props.storeInputReasons)(
            entityReason.getPropertyAsNumber('id')
          )}
          checked={
            props.storeInputReasons.isReasonSelected(
              entityReason.getPropertyAsNumber('id')
            ) || false
          }
          color={entityReason.color}
        />
      )
    }))

    return treeProps
  }

  const LocalTree = themeIsLegacy ? Tree : StyledTree

  return <LocalTree showIcon {...getTreeProps()} />
}

export default observer(ReasonsTree)
