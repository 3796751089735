import type Node from '@libs/Expression/Node'
import { escapeRegExp } from 'lodash'
import { keyValue } from './Grammar'
import type {
  IExpressionDefinition,
  ISerializable,
  StrOrNumbOrBool
} from './types'
import { Combinator } from './types'
import Value from './Value'

export default class Leaf implements ISerializable {
  private $key: string = ''
  private $value: Value = new Value()

  private constructor(key: string = '', value: StrOrNumbOrBool = '') {
    if (key) {
      this.setKey(key)
    }

    if (value) {
      this.setValue(value)
    }
  }

  get key() {
    return this.$key
  }

  get value() {
    return this.$value
  }

  /**
   * Use the key as the type.
   * eg: if key === 'date', the value will be transform to a valid UTC date
   */
  setKey(key: string): this {
    this.$key = key

    if (key === 'date') {
      this.$value.setType('date')
    }

    if (key === 'eventId') {
      this.$value.setType('eventId')
    }

    return this
  }

  /**
   * The value can be an array of strings when the leaf is created from a
   * expression object parsing
   * (case of ['>=', '2018-01-15T12:00:00.0000000Z'] for example)
   */
  setValue(value: StrOrNumbOrBool | StrOrNumbOrBool[]): this {
    if (Array.isArray(value)) {
      value = value.join('')
    }

    this.$value.setInitialValue(value)
    return this
  }

  setOperator(valueOperator: string | null): this {
    this.$value.setOperator(valueOperator)

    return this
  }

  isNode(): this is Node {
    return false
  }

  isLeaf(): this is Leaf {
    return true
  }

  /**
   * Convert the leaf to its displayed string representation.
   */
  toString(): string {
    return [this.$key, this.$value.toString()].join(':')
  }

  expressionInner(): IExpressionDefinition {
    return {
      [this.$key]: this.$value.toStringForExpression()
    }
  }

  /**
   * Convert the key/value pair to an valid JS expression.
   */
  toJs(): string {
    const key = `obj.${this.$key}`

    // if the value contains an operator
    if (this.$value.hasOperator()) {
      return [key, this.$value.toJs()].join(` ${this.$value.operator} `)
    }

    // if there are some wildcards, transform to a regexp test
    if (this.$value.hasWildcards()) {
      const regexpValue = String(this.$value.initialValue)
        .replace(/^%/, '')
        .replace(/%$/, '')

      return `new RegExp('${escapeRegExp(regexpValue)}').test(${key})`
    }

    // strict equality
    return [`obj.${this.$key}`, this.$value.toJs()].join(' === ')
  }

  /**
   * Convert the key/value part to a valid IExpressionDefinition object.
   * Note that this function is not usefull as it and is only there to implement
   * the interface.
   */
  toExpression(): IExpressionDefinition {
    return {
      [Combinator.AND]: [this.expressionInner()]
    }
  }

  /* Static methods */

  /**
   * Create a leaf from a key and a value with value parsing.
   */
  static create(key: string, value: StrOrNumbOrBool): Leaf {
    const finalValue = Array.isArray(value) ? value.join('') : value
    const valueToParse = `${key}:${finalValue}`

    const parseResults = keyValue().parse(valueToParse)

    if (!parseResults.isSuccess()) {
      throw new Error(`Cant create leaf with ${valueToParse}`)
    }

    return parseResults.parsedValue as Leaf
  }

  static unsafeCreate(key: string, value: StrOrNumbOrBool): Leaf {
    return new Leaf(key, value)
  }
}
