import type { EntityDirectory, EntityInfrastructure } from '@app/entities'
import type { StoreInfrastructures } from '@app/stores'
import { ensureArray } from '@libs/ensureArray'

interface IInfrasDirsMapping {
  [infrastructureName: string]: {
    infrastructure: EntityInfrastructure
    directories: EntityDirectory[]
  }
}

export const buildInfrasDirsMapping =
  (storeInfrastructures: StoreInfrastructures) =>
  (directoryIds: number[]): IInfrasDirsMapping => {
    const infrasDirsMapping = directoryIds.reduce<IInfrasDirsMapping>(
      (acc, directoryId) => {
        const infrastructure =
          storeInfrastructures.getInfrastructureFromDirectoryId(directoryId)

        if (!infrastructure) {
          return acc
        }

        const directory = storeInfrastructures.directories.get(directoryId)

        if (!directory) {
          return acc
        }

        if (infrastructure.name) {
          const directories = ensureArray(
            acc[infrastructure.name]?.directories
          ).concat(directory)
          // sort directories by name
          directories.sort((a, b) => ((a.name || '') < (b.name || '') ? -1 : 1))

          return {
            ...acc,
            [infrastructure.name]: { infrastructure, directories }
          }
        }

        return acc
      },
      {}
    )

    return infrasDirsMapping
  }
