import type { IIconProps } from '@app/components/Icon/types'
import { FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconDotsProps extends IIconProps {}

const IconAddWidget: React.FC<IIconDotsProps> = props => {
  const fill = props.color ?? FontColorV2.white
  const size = props.size ?? 19

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_227_2901)">
        <g clipPath="url(#clip1_227_2901)">
          <path
            d="M7.31348 3.09375V11.0487"
            stroke={fill}
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.336 7.07123H11.291"
            stroke={fill}
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <path
        d="M13 4H17C17.5523 4 18 4.44772 18 5V17C18 17.5523 17.5523 18 17 18H5C4.44772 18 4 17.5507 4 16.9984C4 15.3345 4 15.3397 4 13"
        stroke={fill}
        strokeLinecap="round"
      />
      <defs>
        <clipPath id="clip0_227_2901">
          <rect
            width="10"
            height="10"
            fill={fill}
            transform="translate(7.31348) rotate(45)"
          />
        </clipPath>
        <clipPath id="clip1_227_2901">
          <rect
            width="10"
            height="10"
            fill={fill}
            transform="translate(7.31348) rotate(45)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconAddWidget
