import { useStores } from '@app/hooks'
import StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { ICheckerAttack } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { isDefined } from '@libs/isDefined'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { last } from 'lodash'
import * as React from 'react'

/**
 * Initialize the attack types selection picked by instanciating a local instance of
 * StoreInputGenericCheckers.
 *
 * Attack types are selected according to the selected attacks in the latest attacks
 * blade.
 */
export function useStoreInputGenericCheckersSetup(): {
  storeInputGenericCheckers: StoreInputGenericCheckers<ICheckerAttack>
} {
  const { storeRoot, storeIoA } = useStores()

  // create a local StoreInputGenericCheckers instance for the attack types selection
  const storeInputGenericCheckers = React.useMemo(() => {
    const storeInputCheckersAttacks =
      new StoreInputGenericCheckers<ICheckerAttack>(storeRoot, {
        checkerType: CheckerType.Attack,
        selectable: true
      })

    // retrieve the context of attacks of the latest opened attacks blade
    const storeAttacks = last(storeIoA.storesAttacks)

    if (!storeAttacks) {
      return storeInputCheckersAttacks
    }

    storeInputCheckersAttacks.fetchAttackCheckers().then(() => {
      // select attackTypes of the selected attacks
      if (storeAttacks.storeWidgetListAttacks.isAllRowsSelected) {
        storeInputCheckersAttacks.selectAllCheckers()
        return
      }

      // select attackTypes according to the selected attacks
      const attackTypeIds =
        storeAttacks.storeWidgetListAttacks.selectedRowsAsArray
          .map(attack => attack.attackTypeId)
          .filter(isDefined)

      storeInputCheckersAttacks.selectCheckersFromIds(attackTypeIds)
    })

    return storeInputCheckersAttacks
  }, [])

  return { storeInputGenericCheckers }
}
