import { updateIoABoardUrlWithFilters } from '@app/pages/IoA/IoABoardPage/functions'
import type { StoreIoA } from '@app/stores'
import type {
  AttacksSummaryLayout,
  EditAttacksSummaryLayoutMutationArgs
} from '@server/graphql/typeDefs/types'
import type { SelectValue } from 'antd/lib/select'

/**
 * Set new layout and save layout to API.
 */
export const handleSummaryLayoutSelection = (storeIoA: StoreIoA) => {
  return (value: SelectValue): void => {
    const { storeBoard } = storeIoA

    const profileId =
      storeBoard.storeRoot.stores.storeAuthentication.currentProfileId

    const args: EditAttacksSummaryLayoutMutationArgs = {
      layout: {
        layout: value as AttacksSummaryLayout
      },
      profileId
    }

    storeBoard.editSummaryLayout(args).then(() => {
      updateIoABoardUrlWithFilters(storeIoA)
    })
  }
}

/**
 * Update the url with the new filter.
 */
export const handleSummaryCardsSearch = (storeIoA: StoreIoA) => {
  return (): void => {
    updateIoABoardUrlWithFilters(storeIoA)
  }
}

/**
 * Set new statement to display only attacked domains
 */
export const handleSummaryOnlyUnderAttackSwitch = (storeIoA: StoreIoA) => {
  return (checked: boolean) => {
    storeIoA.storeBoard.storeSummaryCards.setDisplayOnlyAttackedDomainsSwitch(
      checked
    )

    updateIoABoardUrlWithFilters(storeIoA)

    storeIoA.storeBoard.reloadAttacksSummary()
  }
}
