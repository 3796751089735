import {
  IconLockOutlined,
  IconUnlockOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { consts } from '@app/styles'
import React from 'react'

export const UserLockedOutIcon = () => (
  <IconLockOutlined style={{ color: consts.colorRed001 }} />
)

export const UserLockedOutRemoveIcon = () => (
  <IconUnlockOutlined style={{ color: consts.colorRed001 }} />
)

export const UserNotLockedOutIcon = () => <IconUnlockOutlined />
