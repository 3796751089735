import type { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import * as React from 'react'
import DrawerOverlay from './DrawerOverlay'

interface IDrawerProps {
  placeHolderName?: PlaceHolderName
  // true by default
  showOverlay?: boolean
  overlayBackgroundColor?: string
  isDrawerVertical?: boolean
  children?: React.ReactNode
}

const Drawer: React.FC<IDrawerProps> = props => {
  return (
    <div data-name="Drawer">
      {props.showOverlay === false ? null : (
        <DrawerOverlay
          placeHolderName={props.placeHolderName}
          overlayBackgroundColor={props.overlayBackgroundColor}
          isDrawerVertical={props.isDrawerVertical}
        />
      )}

      {props.children}
    </div>
  )
}

export default Drawer
