import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import {
  IconLeftOutlined,
  IconRightOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { TIMELINE_HEIGHT } from './constant'
import FiltersTimelineNavigation from './FiltersTimelineNavigation'
import FiltersTimelinePoint from './FiltersTimelinePoint'
import {
  handleTimelineNextClick,
  handleTimelinePreviousClick
} from './handlers'

const EMPTY_POINT = <div className="emptyPill" />

interface IFiltersTimelineProps {
  className?: string
  isLoading: boolean
}

const FiltersTimeline: React.FC<IFiltersTimelineProps> = props => {
  const { storeIoA } = useStores()
  const { storeTimeline } = storeIoA.storeBoard

  const pointItems = Array.from(storeTimeline.attackPoints.entries()).map(
    ([timestamp, points], index) => {
      return (
        <FiltersTimelinePoint
          key={timestamp}
          timestamp={timestamp}
          points={points}
          hideLabelWhenScreenTooSmall={index % 2 === 1}
        />
      )
    }
  )

  const pointsAsItems = props.isLoading ? [EMPTY_POINT] : [...pointItems]

  return (
    <ContainerContent
      data-name={FiltersTimeline.name}
      className={props.className}
    >
      <ContainerFlex
        name="FiltersTimeline"
        alignItems="center"
        itemsFlexGrow={[0, 1, 0]}
        className="filtersTimeline"
        items={[
          <FiltersTimelineNavigation
            onClick={handleTimelinePreviousClick(storeIoA)}
            isLoading={props.isLoading}
            icon={<IconLeftOutlined />}
          />,

          <ContainerFlex
            name="Timeline"
            className="timeline"
            alignItems="center"
            justifyContent="space-evenly"
            items={pointsAsItems}
            spaced
          />,

          <FiltersTimelineNavigation
            onClick={handleTimelineNextClick(storeIoA)}
            isLoading={props.isLoading}
            icon={<IconRightOutlined />}
          />
        ]}
      />
    </ContainerContent>
  )
}

const ObservedFiltersTimeline = observer(FiltersTimeline)

export default styled(ObservedFiltersTimeline)`
  height: ${TIMELINE_HEIGHT};
  overflow: hidden;
  border: 1px solid ${consts.colorGrey010};
  border-radius: 4px;

  .emptyPill {
    margin-right: 1px !important;
  }

  .filtersTimeline {
    min-width: 540px;
  }

  .timeline {
    position: relative;
    transition: ${consts.transitionOpacity};
    opacity: ${props => (props.isLoading ? '0.2' : '1')};
  }

  .timeline::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0px;
    width: 100%;
    border-top: 1px solid ${consts.colorGrey010};
  }
`
