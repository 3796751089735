import { Blade } from '@app/components-legacy/Blade'
import { ContainerRbac } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useStores } from '@app/hooks'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { canAccessToAttackActions } from '@app/pages/IoA/IoAAttacksPage/permissions'
import { canAccessToIoABoard } from '@app/pages/IoA/IoABoardPage/permissions'
import type { IIoAAttacksRouteDefinition } from '@app/routes'
import { AppRouteName } from '@app/routes'
import { ensureArray } from '@libs/ensureArray'
import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { AttacksPageContext } from './context'
import {
  handleAttacksBladeOnLoad,
  handleAttacksBladeOnUnload,
  handleAttacksOnScroll,
  handleAttacksRootBladeOnLoad
} from './handlers'
import IoAAttacksFooter from './IoAAttacksFooter'
import IoAAttacksHeader from './IoAAttacksHeader'
import IoAAttacksHeaderSub from './IoAAttacksHeaderSub'
import IoAAttacksTable from './IoAAttacksTable'
import IoADrawerAttackDetails from './IoADrawerAttackDetails'
import IoADrawerAttacksExport from './IoADrawerAttacksExport'
import IoADrawerAttacksFiltersAdd from './IoADrawerAttacksFiltersAdd'
import IoADrawerAttacksRemoveFiltersList from './IoADrawerAttacksRemoveFiltersList'

interface IIoAAttacksListPageProps {
  storeAttacksIndex?: number
}

const IoAAttacksPage: React.FC<IIoAAttacksListPageProps> = props => {
  const appRouter = useAppRouter()

  const { storeInfrastructures, storeIoA } = useStores()

  /**
   * Initialize StoreAttacks when reloading the page.
   */
  React.useEffect(() => {
    handleAttacksRootBladeOnLoad(storeIoA)(props.storeAttacksIndex)
  }, [])

  const storeAttacksIndex = props.storeAttacksIndex || 0
  const storeAttacks = storeIoA.storesAttacks[storeAttacksIndex]

  if (!storeAttacks) {
    return null
  }

  const allAttacksFilters =
    appRouter.getCurrentRouteQueryStringParameters<
      IIoAAttacksRouteDefinition['queryStringParameters']
    >()

  // In order to save the correct url (pathname + qs) into each blade,
  // retrieve filters from the beginning of the QS to the current store attacks index.
  const currentBladeAttacksFilters = ensureArray(
    allAttacksFilters?.attacksFilters
  ).slice(0, storeAttacksIndex + 1)

  const boardFilters = storeIoA.computeBoardQueryParameters()

  return (
    <AttacksPageContext.Provider value={{ storeAttacks }}>
      <Blade
        // build the uuid from the querystring to ensure that the same blade will be reused
        uuidFromString={`IoAAttacks-${storeAttacksIndex}`}
        routeDefinition={{
          routeName: AppRouteName.IoA_Attacks,
          parameters: {},
          queryStringParameters: {
            boardFilters,
            attacksFilters: currentBladeAttacksFilters
          }
        }}
        onLoad={handleAttacksBladeOnLoad(storeAttacks)}
        onUnload={handleAttacksBladeOnUnload(storeIoA)}
        flags={[
          // wait for needed data but not for attacks to not break
          // the blades init on hard-reloads
          storeInfrastructures.storeFlagsFetchInfrastructures.flags,
          storeIoA.storeFlagsFetchAttackTypes.flags
        ]}
        layout={{
          name: 'default',
          header: <IoAAttacksHeader />,
          menu: <IoAAttacksHeaderSub />,
          content: (
            <>
              <IoAAttacksTable />
              <IoAAttacksPage storeAttacksIndex={storeAttacksIndex + 1} />
            </>
          ),
          footer: (
            <ContainerRbac rbacCapabilityCheck={canAccessToAttackActions}>
              <IoAAttacksFooter />
            </ContainerRbac>
          )
        }}
        contentProps={{
          handleScroll: debounce(handleAttacksOnScroll(storeAttacks), 25)
        }}
        rbacCapabilityCheck={canAccessToIoABoard}
      />

      <Portal name={PlaceHolderName.genericDrawer}>
        <IoADrawerAttackDetails
          storeDrawer={storeAttacks.storeDrawerAttackDetails}
        />
      </Portal>

      <Portal name={PlaceHolderName.genericDrawer}>
        <IoADrawerAttacksFiltersAdd
          storeDrawer={storeAttacks.storeDrawerAttacksFiltersAdd}
        />
      </Portal>

      <Portal name={PlaceHolderName.genericDrawer}>
        <IoADrawerAttacksRemoveFiltersList
          storeDrawer={storeAttacks.storeAttackRemoveFilters.storeDrawer}
        />
      </Portal>

      <Portal name={PlaceHolderName.genericDrawer}>
        <IoADrawerAttacksExport />
      </Portal>
    </AttacksPageContext.Provider>
  )
}

export default observer(IoAAttacksPage)
