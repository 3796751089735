import { Blade } from '@app/components-legacy/Blade'
import {
  CheckerCodeNameEnum,
  CheckerCodeNameId
} from '@app/entities/EntityGenericChecker/types'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { CheckerDetailsPageContext } from '@app/pages/IoE/IoECheckerDetailsPage/context'
import DetailsBladeHeader from '@app/pages/IoE/IoECheckerDetailsPage/DetailsBladeHeader'
import DeviantObjectsFooter from '@app/pages/IoE/IoECheckerDetailsPage/DetailsDeviantObjectsPage/DeviantObjects/DeviantObjectsFooter'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  handleTrustDeviantObjectsOnLoad,
  handleTrustDeviantObjectsOnUnload
} from './handlers'
import { canSeeDangerousTrustRelationships } from './permissions'
import TableDeviantObjects from './TableDeviantObjects'

interface IBladeTrustsDeviantObjectsProps {}

const TrustsDeviantObjectsBlade: React.FC<
  IBladeTrustsDeviantObjectsProps
> = () => {
  const { storeIoE } = useStores()

  const appRouter = useAppRouter()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Topology_DeviantObjects,
    parameters: {
      checkerId: Number(),
      checkerCodename: String()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <CheckerDetailsPageContext.Provider
      value={{
        checkerId: parameters.checkerId,
        checkerCodename: parameters.checkerCodename
      }}
    >
      <Blade
        routeDefinition={{
          routeName: AppRouteName.Topology_DeviantObjects,
          parameters: {
            checkerId: CheckerCodeNameId.C_DANGEROUS_TRUST_RELATIONSHIP,
            checkerCodename: CheckerCodeNameEnum.C_DANGEROUS_TRUST_RELATIONSHIP
          }
        }}
        onLoad={handleTrustDeviantObjectsOnLoad(storeIoE)(parameters.checkerId)}
        onUnload={handleTrustDeviantObjectsOnUnload(storeIoE)}
        flags={[
          storeIoE.storeIndicator.storeFlagsFetchCheckerExposureComplianceStatus
            .flags
        ]}
        layout={{
          name: 'default',
          header: <DetailsBladeHeader />,
          content: <TableDeviantObjects />,
          footer: (
            <DeviantObjectsFooter
              checkerId={parameters.checkerId}
              checkerCodename={parameters.checkerCodename}
            />
          )
        }}
        rbacCapabilityCheck={canSeeDangerousTrustRelationships}
      />
    </CheckerDetailsPageContext.Provider>
  )
}

export default observer(TrustsDeviantObjectsBlade)
