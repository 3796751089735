import { ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  ContainerContent,
  ContainerFooter,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import {
  Drawer,
  DrawerCancelButton,
  DrawerContext,
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { FormWrapper } from '@app/components-legacy/Form/Wrappers'
import FormWrapperButtonSubmit from '@app/components-legacy/Form/Wrappers/ButtonSubmit'
import { LabelConfirm } from '@app/components-legacy/Label'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canDeleteReport } from '../permissions'
import { handleReportDeleteOnSubmit } from './handlers'

export interface IDrawerDeleteReportProps {}

function DrawerDeleteReport(props: IDrawerDeleteReportProps) {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.System.Configuration.ReportingCenter']
  })

  const { storeManagementReportingCenter } = useStores()
  const { storeDrawerDeleteReport: storeDeleteDrawer } =
    storeManagementReportingCenter

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const reportDataRow = storeDeleteDrawer.getDataValue('reportDataRow')

  const renderContent = () => {
    if (!reportDataRow) {
      return null
    }

    const isLoading =
      storeManagementReportingCenter.storeFlagsDeleteReport.flags.isLoading

    return (
      <FormWrapper
        name="reportDeletion"
        onSubmit={handleReportDeleteOnSubmit(storeManagementReportingCenter)(
          reportDataRow
        )}
      >
        <ContainerContent
          flags={storeManagementReportingCenter.storeFlagsDeleteReport.flags}
          spinner={<SpinnerInline />}
        >
          <ContainerFlex
            name="DrawerDeleteReportText"
            direction="column"
            items={[
              <Label variant={LabelVariant.p}>
                <ContainerHTMLContent>
                  {translate('You will delete the report', {
                    transformMarkdown: true,
                    interpolations: {
                      name: reportDataRow.name
                    }
                  })}
                </ContainerHTMLContent>
              </Label>,

              <LabelConfirm />
            ]}
            flexGap="default"
          />
        </ContainerContent>

        <Portal name={PlaceHolderName.drawerFooter}>
          <ContainerFooter>
            <DrawerCancelButton
              storeDrawer={
                storeManagementReportingCenter.storeDrawerDeleteReport
              }
            />
            <FormWrapperButtonSubmit loading={isLoading}>
              {translate('Delete')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </FormWrapper>
    )
  }

  return (
    <DrawerContext.Provider value={{ storeDrawer: storeDeleteDrawer }}>
      <Drawer>
        <DrawerHorizontalContainer>
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: <DrawerTitle>{translate('Delete report')}</DrawerTitle>,
              content: renderContent(),
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canDeleteReport(reportDataRow?.id || null)}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerDeleteReport)
