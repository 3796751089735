import { Features } from '@alsid/common'
import { AppRouteName } from '@app/routes'
import type {
  StoreAbout,
  StoreAuthentication,
  StoreEula,
  StoreLicense
} from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'

/**
 * Return true if everything is OK to be redirected to the HP.
 */
export function shouldRedirectToHomePage(
  storeAuthentication: StoreAuthentication,
  // pathname could be passed as parameter if appRouter doesn't have an history object yet
  pathname?: string
): boolean {
  const { appRouter } = storeAuthentication.storeRoot

  if (!storeAuthentication.isAuthenticated) {
    return false
  }

  const { currentSanitizedProfileName } = storeAuthentication

  if (!currentSanitizedProfileName) {
    return false
  }

  const finalPathname = pathname || appRouter.history.location()?.pathname

  if (!finalPathname) {
    return false
  }

  // redirect if *on* those pages
  const urls = [
    appRouter.getRoutePathname(AppRouteName.Root),
    appRouter.getRoutePathname(AppRouteName.Auth),
    appRouter.getRoutePathname(AppRouteName.Auth_Login),
    appRouter.getRoutePathname(AppRouteName.Auth_Eula)
  ]

  return urls.includes(finalPathname)
}

/**
 * Redirects to the different pages according to the context.
 * Once logged, redirect to the next page (Eula or HomePage)
 */
export function performRedirectsPostLogin(
  storeAuthentication: StoreAuthentication,
  storeEula: StoreEula,
  storeLicense: StoreLicense,
  storeAbout: StoreAbout,
  pathname: string
): void {
  const { logger, appRouter } = storeAuthentication.storeRoot

  const featureWorkspaceAppswitcherInTad =
    storeAuthentication.storeRoot.stores.storeRbac.isUserGrantedAccordingFeatureFlag(
      Features.WORKSPACE_APPSWITCHER_IN_TAD
    )

  if (
    !storeAuthentication.isAuthenticated &&
    storeAbout.productAssociation?.isAssociatedToTenableOne() &&
    featureWorkspaceAppswitcherInTad
  ) {
    logger.debug('[performRedirectsPostLogin] Redirect to T.ONE Login page')

    document.location.href =
      storeAuthentication.storeRoot.environment.config.app.settings.tonelogin.url

    return
  }

  if (!storeAuthentication.isAuthenticated) {
    return
  }

  if (!storeLicense.isLicenseStatusAuthorizeAccess) {
    logger.debug(
      '[performRedirectsPostLogin] Redirect to AppRouteName.Auth_License'
    )

    appRouter.history.push(
      appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.Auth_License,
        parameters: {}
      })
    )

    return
  }

  if (!storeEula.hasApprovedEula) {
    logger.debug(
      '[performRedirectsPostLogin] Redirect to AppRouteName.Auth_Eula'
    )

    appRouter.history.push(
      appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.Auth_Eula,
        parameters: {}
      })
    )

    return
  }

  if (shouldRedirectToHomePage(storeAuthentication, pathname)) {
    logger.debug(
      '[performRedirectsPostLogin] Redirect to AppRouteName.HomePage'
    )

    // FIXME
    // Do a hard redirect to fix TUL display that is not rendered correctly
    // with just an history push...

    appRouter.hardRedirect(
      appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.HomePage,
        parameters: {}
      })
    )

    return
  }
}

/**
 * Return true if not of some specific pages
 * (to avoid infinite loop or current-login pages).
 */
export function canRedirectToLogin(
  appRouter: AppRouterClient,
  // pathname could be passed as parameter if appRouter doesn't have an history object yet
  pathname?: string
) {
  const finalPathname = pathname || appRouter.history.location()?.pathname

  if (!finalPathname) {
    return false
  }

  // can redirect if *not* on those pages
  const urls = [
    appRouter.getRoutePathname(AppRouteName.Auth),
    appRouter.getRoutePathname(AppRouteName.Auth_Login),
    appRouter.getRoutePathname(AppRouteName.Auth_Eula),
    appRouter.getRoutePathname(AppRouteName.Auth_License),
    appRouter.getRoutePathname(AppRouteName.Auth_PendingActivation),
    appRouter.getRoutePathname(AppRouteName.Auth_FirstLoginPassword),
    appRouter.getRoutePathname(AppRouteName.Reports)
  ]

  return !urls.includes(finalPathname)
}
