import { ContainerFlex } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { AttackPathVariant } from '../../types'
import AttackPathInputSearchSource from './AttackPathInputSearchSource'
import AttackPathInputSearchTarget from './AttackPathInputSearchTarget'
import AttackPathSearchButton from './AttackPathSearchButton'
import AttackPathSwapButton from './AttackPathSwapButton'

interface IAttackPathInputsWrapperProps {
  className?: string
  variant?: AttackPathVariant
}

const SearchWrapper = styled(ContainerFlex)<{
  targetNodeDisplay: boolean
  variant?: AttackPathVariant
}>`
  position: relative;
  width: ${props =>
    !props.variant || props.variant === AttackPathVariant.default
      ? '60%'
      : props.targetNodeDisplay
        ? '59%'
        : '40%'};
  border-radius: ${consts.borderRadiusRedesign};
  background-color: ${props =>
    !props.variant || props.variant === AttackPathVariant.default
      ? consts.colorWhite
      : 'transparent'};
  box-shadow: ${props =>
    !props.variant || props.variant === AttackPathVariant.default
      ? consts.boxShadowRedesign
      : 'none'};
  padding: ${props =>
    !props.variant || props.variant === AttackPathVariant.default
      ? '20px'
      : '0'};
`

const InputsWrapper = styled(ContainerFlex)`
  width: 100%;
`

const AttackPathInputsWrapper: React.FC<
  IAttackPathInputsWrapperProps
> = props => {
  const { storeAttackPath } = useStores()

  const { targetNodeDisplay } = storeAttackPath

  return (
    <SearchWrapper
      name="AttackPathSceneSearch"
      className={props.className}
      labelledBy="AttackPathSceneSearch"
      variant={props.variant}
      targetNodeDisplay={targetNodeDisplay}
      alignItems="flex-start"
      direction="column"
      spaceWidth="small"
      items={[
        <InputsWrapper
          name="AttackPathInputsWrapper"
          spaceWidth="small"
          itemsFlexShrink={[1, 0, 1, 0]}
          items={[
            <AttackPathInputSearchSource />,
            targetNodeDisplay && <AttackPathSwapButton />,
            targetNodeDisplay && <AttackPathInputSearchTarget />,
            !storeAttackPath.searchIsDone && <AttackPathSearchButton />
          ]}
          spaced
        />
      ]}
      spaced
    />
  )
}

export default observer(AttackPathInputsWrapper)
