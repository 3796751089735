import CardCheckboxContainer from '@app/components-legacy/Card/CardCheckboxContainer'
import { FormWrapperCheckbox } from '@app/components-legacy/Form'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import * as React from 'react'

interface IAlertCheckboxProps {
  className?: string
  alertId: number
  checked: boolean
  onChange: (e: CheckboxChangeEvent) => void
}

const AlertCheckbox: React.FC<IAlertCheckboxProps> = props => {
  return (
    <CardCheckboxContainer checked={props.checked}>
      <FormWrapperCheckbox
        labelledBy={`selectAlert${props.alertId}`}
        checkboxProps={{
          checked: props.checked,
          onChange: props.onChange
        }}
      />
    </CardCheckboxContainer>
  )
}

export default AlertCheckbox
