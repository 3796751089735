import { AppRouteName } from '@app/routes'
import type {
  StoreAuthentication,
  StoreBlades,
  StoreDashboards
} from '@app/stores'
import type StoreDashboard from '@app/stores/Dashboard/StoreDashboard'
import type { InstanceName } from '@libs/Environment/types'
import type { AppRouterClient } from '@libs/Router/types'

/**
 * Initialize the form and other controls with data from options.
 *
 * Since the UI is generated according to types union, all cases have to be
 * handled. That results into a lot of code but it's relatively straightforward...
 */
export const onWidgetEditPageLoad =
  (storeAuthentication: StoreAuthentication, storeDashboard: StoreDashboard) =>
  (dashboardId: number, dashboardWidgetId: number) =>
  () => {
    const currentProfile = storeAuthentication.currentProfile
    const currentProfileId =
      currentProfile && currentProfile.getPropertyAsNumber('id')

    if (!storeDashboard || !currentProfileId) {
      return
    }

    storeDashboard.storeWidgetsManagement.initializeStoresForWidgetEdition({
      profileId: currentProfileId,
      dashboardId,
      dashboardWidgetId
    })
  }

/**
 * Reset data on unload
 */
export const onWidgetEditPageUnload =
  (storeDashboard: StoreDashboard) => () => {
    const { storeWidgetsManagement } = storeDashboard
    storeWidgetsManagement.reset()
  }

/**
 * Edit a widget.
 */
export const onWidgetEditionSubmit =
  (
    storeDashboards: StoreDashboards,
    storeDashboard: StoreDashboard,
    appRouter: AppRouterClient
  ) =>
  (dashboardId: number, dashboardWidgetId: number) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    if (!storeDashboard.storeWidgetsManagement.storeForm.validate()) {
      return
    }

    const dashboardEntity = storeDashboard.dashboardEntity

    const instanceName = dashboardEntity.getPropertyAsString('instanceName')

    storeDashboard.storeWidgetsManagement
      .editWidget(dashboardId, dashboardWidgetId)
      .then(() => {
        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Dashboard_Grid,
          parameters: {
            instanceName,
            dashboardId
          }
        })

        appRouter.history.push(url)

        return storeDashboards.fetchOneDashboard(
          instanceName,
          Number(dashboardId)
        )
      })
      .catch(() => {
        // Thrown in the store to avoid the redirection if something is wrong
        // when editing the widget.
      })
  }

export const onCancelButtonClick =
  (storeBlades: StoreBlades) =>
  (instanceName: InstanceName, dashboardId: number) =>
  () => {
    const url = storeBlades.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Dashboard_Grid,
      parameters: {
        instanceName,
        dashboardId
      }
    })

    storeBlades.goToBladeUrl(url)
  }
