import {
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import { useStores } from '@app/hooks/useStores'
import { isRbacAdminRole } from '@app/stores/Management/Rbac/functions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import RolesEditFooterSelection from './RolesEditFooterSelection'
import RolesEditFooterSubmit from './RolesEditFooterSubmit'
import RolesEditFooterToggles from './RolesEditFooterToggles'

interface IRolesEditBladeFooterProps {}

const RolesEditFooter: React.FC<IRolesEditBladeFooterProps> = () => {
  const { storeManagementRbacRoles } = useStores()

  const role = storeManagementRbacRoles.currentRbacRole
  const isAdminRole = isRbacAdminRole(role)

  return (
    <ContainerFooter>
      {!isAdminRole && (
        <ContainerFlex
          name="RolesEditFooter"
          labelledBy="RolePermissionsSelector"
          items={[<RolesEditFooterSelection />, <RolesEditFooterToggles />]}
        />
      )}

      <RolesEditFooterSubmit />
    </ContainerFooter>
  )
}

export default observer(RolesEditFooter)
