import type { StoreTrailFlow } from '@app/stores'

/**
 * Open drawer search history timeline when clicking on "see more" button on history tab.
 */
export const onTrailFlowHistorySeeMoreClick =
  (storeTrailFlow: StoreTrailFlow) => () => {
    storeTrailFlow.storeDrawerHistory.closeDrawer()
    storeTrailFlow.storeSearchHistory.storeSearchHistoryTimelineDrawer.openDrawer()
  }

/**
 * Open drawer search bookmarks when clicking on "see more" button on bookmark tab.
 */
export const onTrailFlowBookmarkSeeMoreClick =
  (storeTrailFlow: StoreTrailFlow) => () => {
    storeTrailFlow.storeDrawerHistory.closeDrawer()
    storeTrailFlow.storeSearchBookmarks.storeSearchBookmarksDrawer.openDrawer()
  }

/**
 * Close drawer preview history / bookmarks.
 */
export const onTrailFlowPreviewHistoryClose =
  (storeTrailFlow: StoreTrailFlow) => () => {
    storeTrailFlow.storeDrawerHistory.closeDrawer()
  }
