import { BulletCircle } from '@app/components-legacy/Bullet'
import { BulletCircleSize } from '@app/components-legacy/Bullet/BulletCircle'
import {
  ContainerFlex,
  ContainerPDFPage
} from '@app/components-legacy/Container'
import PdfFooterPage from '@app/components-legacy/SVG/Images/PdfFooterPage'
import PdfHeaderPage from '@app/components-legacy/SVG/Images/PdfHeaderPage'
import {
  TypographyPDFTitle,
  TypographySubTitle
} from '@app/components-legacy/Typography'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

interface IIoABoardInfrastructuresPDFProps {}

const StyledDomainList = styled('div')`
  background-color: ${consts.colorBlueGray035};
  border-radius: ${consts.containerBorderRadius};
  padding: ${consts.paddingLarge};
  margin-bottom: ${consts.marginDefault};
`

const StyledDomainName = styled<any>('div')`
  display: inline-block;
  font-weight: ${consts.fontWeightBold};
  width: 20%;
`

const IoABoardInfrastructuresPDF: React.FC<
  IIoABoardInfrastructuresPDFProps
> = () => {
  const { storeIoA } = useStores()
  const { storeInfrastructures } = storeIoA.storeBoard

  const translate = useAppTranslator({
    namespaces: ['IoA.BoardPDF']
  })

  const getInfrastructureItems = () => {
    const infrastructures = Array.from(
      storeInfrastructures
        .getDirectoriesByInfractructure({ onlySelected: true })
        .entries()
    )

    return infrastructures.map(([infrastructure, directories]) => {
      const directoryNames = Array.from(directories.values()).map(directory => {
        return (
          <StyledDomainName
            key={directory.getPropertyAsString('name')}
            color={infrastructure.color}
          >
            <ContainerFlex
              name="IoABoardInfrastructuresPDFDirectoryNames"
              direction="row"
              alignItems="center"
              items={[
                <BulletCircle color={infrastructure.color} />,

                <div>{directory.getPropertyAsString('name')}</div>
              ]}
              spaced
              paddingV="verySmall"
            />
          </StyledDomainName>
        )
      })

      return (
        <ContainerFlex
          name="IoABoardInfrastructuresPDFInfrastructureWrapper"
          direction="column"
          items={[
            <ContainerFlex
              name="IoABoardInfrastructuresPDFInfrastructure"
              direction="row"
              alignItems="center"
              items={[
                <BulletCircle
                  color={infrastructure.color}
                  size={BulletCircleSize.large}
                />,

                <TypographySubTitle>{infrastructure.name}</TypographySubTitle>
              ]}
              spaced
              paddingV="default"
            />,

            <StyledDomainList>{directoryNames}</StyledDomainList>
          ]}
          wrapItems
          paddingH="default"
        />
      )
    })
  }

  return (
    <ContainerPDFPage
      headerItem={<PdfHeaderPage />}
      footerItem={<PdfFooterPage />}
      bodyItems={[
        <TypographyPDFTitle>
          {translate('Perimeter selected')}
        </TypographyPDFTitle>,

        ...getInfrastructureItems()
      ]}
    />
  )
}

export default observer(IoABoardInfrastructuresPDF)
