import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { mergeFlags } from '@app/stores/helpers/StoreFlags/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canCreateEmailAlerts } from '../permissions'
import EmailCreateForm from './EmailCreateForm'
import { onEmailCreateLoad, onEmailCreateUnload } from './handlers'

interface IConfigurationEmailsCreatePageProps {}

const ConfigurationEmailsCreatePage: React.FC<
  IConfigurationEmailsCreatePageProps
> = () => {
  const { storeManagementEmails } = useStores()

  const flags = mergeFlags(
    // wait for infras
    storeManagementEmails.storeInfrastructures.storeFlagsFetchInfrastructures
      .flags,
    // wait IoE loaded in cards
    storeManagementEmails.storeInputCheckersExposure.storeFlagsFetchCheckers
      .flags,
    // wait IoA loaded in cards
    storeManagementEmails.storeInputCheckersAttacks.storeFlagsFetchCheckers
      .flags
  )

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_System_Configuration_Emails_Create,
        parameters: {}
      }}
      onLoad={onEmailCreateLoad(storeManagementEmails)}
      onUnload={onEmailCreateUnload(storeManagementEmails)}
      flags={flags}
      layout={{
        name: 'default',
        content: (
          <ContainerContent>
            <EmailCreateForm />
          </ContainerContent>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canCreateEmailAlerts}
    />
  )
}

export default observer(ConfigurationEmailsCreatePage)
