import type StoreSearchBookmarks from '@app/stores/TrailFlow/StoreSearchBookmarks'
import { BookmarkCategoryEditFormFieldName } from '@app/stores/TrailFlow/StoreSearchBookmarks'

/**
 * Handle open drawer and init form data.
 */
export const handleEditBookmarkCategoryLoad =
  (storeSearchBookmarks: StoreSearchBookmarks) =>
  (existingCategory: string) =>
  () => {
    storeSearchBookmarks.storeDrawerEditBookmarkCategory.openDrawer()

    storeSearchBookmarks.storeFormEditBookmarkCategory
      .setDefaultFieldsValues([
        {
          key: BookmarkCategoryEditFormFieldName.existingCategory,
          value: existingCategory
        },
        {
          key: BookmarkCategoryEditFormFieldName.newCategory,
          value: existingCategory
        }
      ])
      .reset()
  }

/**
 * Handle close drawer and reset form data.
 */
export const handleEditBookmarkCategoryUnload =
  (storeSearchBookmarks: StoreSearchBookmarks) => () => {
    storeSearchBookmarks.storeDrawerEditBookmarkCategory.closeDrawer()
  }

/**
 * Edit bookmark category and close drawer.
 */
export const handleEditBookmarkCategoryValidate =
  (storeSearchBookmarks: StoreSearchBookmarks) =>
  (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault()
    }

    const { storeFormEditBookmarkCategory } = storeSearchBookmarks

    if (!storeFormEditBookmarkCategory.validate()) {
      return
    }

    const existingCategory =
      storeFormEditBookmarkCategory.getFieldValueAsString(
        BookmarkCategoryEditFormFieldName.existingCategory
      )

    const newCategory =
      storeFormEditBookmarkCategory.getFieldValueAsString(
        BookmarkCategoryEditFormFieldName.newCategory
      ) || null

    return storeSearchBookmarks
      .editBookmarkCategory({
        existingCategory,
        newCategory
      })
      .then(handleEditBookmarkCategoryUnload(storeSearchBookmarks))
  }
