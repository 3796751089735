import {
  ContainerContent,
  ContainerFlex,
  ContainerIcon,
  ContainerTimeline
} from '@app/components-legacy/Container'
import {
  IconDownOutlined,
  IconUpOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { LabelAlt, LabelChecker } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import type { ILabelCheckerAppearance } from '@app/components-legacy/Label/LabelChecker/types'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { SpinnerInlineSize } from '@app/components-legacy/Spinner/SpinnerInline'
import { isEntityCheckerExposure } from '@app/entities/EntityGenericChecker/helpers'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { consts } from '@app/styles'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { getCriticityValue } from '@libs/criticity'
import { filterFalsies } from '@libs/filterFalsies'
import { isDefined } from '@libs/isDefined'
import { Criticity } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  onCheckerGroupTitleClick,
  onCheckerSelection,
  onCheckersGroupTitleChange
} from './handlers'
import LabelCheckerGroup from './LabelCheckerGroup'

export interface IInputCheckersCardsProps<GC extends TGenericChecker> {
  className?: string
  checkerAppearance?: ILabelCheckerAppearance
  criticityThreshold?: number
  onSelection?: (codename: GenericCheckerCodename) => void
  loadCheckersAtLoading?: boolean
  profileId?: number
  storeInputGenericCheckers: StoreInputGenericCheckers<GC>
}

const StyledLabelChecker = styled(LabelChecker)`
  margin-bottom: ${props =>
    isThemeLegacy(props.theme)
      ? consts.paddingMedium
      : consts.paddingVerySmall};
`

function InputCheckersCards<GC extends TGenericChecker>(
  props: IInputCheckersCardsProps<GC>
): React.ReactElement {
  const translate = useAppTranslator({
    namespaces: [
      'Components.LabelChecker',
      'Components.LabelChecker.ComplexityLevel'
    ]
  })

  /**
   * Return true if threshold (optional) is superior to criticity parameter
   */
  const filterByThreshold = (criticity: Criticity): boolean => {
    const { criticityThreshold } = props

    if (criticityThreshold) {
      return criticityThreshold > getCriticityValue(criticity)
    }

    return false
  }

  /**
   * Return the checkers of a defined criticity.
   */
  const getCheckersByCriticity = (criticity: Criticity) => {
    if (filterByThreshold(criticity)) {
      return null
    }

    const isCheckersVisible =
      props.storeInputGenericCheckers.isCheckersVisible(criticity)

    if (!isCheckersVisible) {
      return <div />
    }

    const noIndicator =
      !props.storeInputGenericCheckers.hasCheckersForCriticity(criticity)

    if (noIndicator) {
      return (
        <LabelAlt variant={LabelAltVariant.Disabled}>
          {translate('No indicator found for this criticity')}
        </LabelAlt>
      )
    }

    const checkers =
      props.storeInputGenericCheckers.checkersByCriticity(criticity)

    const checkable =
      props.storeInputGenericCheckers.options.selectable === true

    const items = Array.from(checkers.values())
      // Sort checkers in translated alphabetical order to ease brain filtering
      .sort((a, b) => String(a.name).localeCompare(String(b.name)))
      .map(checker => {
        if (!checker.genericCodename) {
          return
        }

        return (
          <StyledLabelChecker
            name={checker.getPropertyAsString('name')}
            codename={checker.genericCodename}
            criticity={criticity}
            // IOA can't be disabled
            enabled={isEntityCheckerExposure(checker) ? checker.enabled : true}
            onClick={onCheckerSelection(props.storeInputGenericCheckers)(
              checker.genericCodename,
              props.onSelection
            )}
            selected={props.storeInputGenericCheckers.isCheckerSelected(
              checker.genericCodename
            )}
            small
            checkable={checkable}
            {...props.checkerAppearance}
          />
        )
      })
      .filter(isDefined)

    return (
      <ContainerContent>
        <ContainerFlex
          name="Checkers"
          flexWrap="wrap"
          items={items}
          spaced
          spaceWidth="default"
          wrapItems
        />
      </ContainerContent>
    )
  }

  const renderCheckersGroup = (criticity: Criticity) => {
    const { storeInputGenericCheckers: storeInputCheckersExposure } = props

    if (filterByThreshold(criticity)) {
      return null
    }

    const isSelectable =
      props.storeInputGenericCheckers.options.selectable === true

    const isDisabled =
      !props.storeInputGenericCheckers.hasCheckersForCriticity(criticity)

    const isChecked = !isDisabled
      ? storeInputCheckersExposure.isCheckersByCriticitySelected(criticity)
      : false

    return (
      <ContainerFlex
        name="CheckersGroupTitle"
        alignItems="center"
        itemsFlexGrow={[1, 0]}
        items={[
          <LabelCheckerGroup
            criticity={criticity}
            checkable={isSelectable}
            disabled={isDisabled}
            checked={isChecked}
            indeterminate={storeInputCheckersExposure.isCheckersByCriticityPartiallySelected(
              criticity
            )}
            onLabelClick={onCheckerGroupTitleClick(storeInputCheckersExposure)(
              criticity
            )}
            onCheckboxClick={onCheckersGroupTitleChange(
              storeInputCheckersExposure
            )(criticity, isChecked)}
          />,

          <ContainerIcon
            labelledBy="toggleChecker"
            iconComponent={
              storeInputCheckersExposure.isCheckersVisible(criticity)
                ? IconUpOutlined
                : IconDownOutlined
            }
            iconProps={{
              onClick: onCheckerGroupTitleClick(storeInputCheckersExposure)(
                criticity
              )
            }}
          />
        ]}
        spaced
        spaceWidth="verySmall"
      />
    )
  }

  return (
    <ContainerContent
      name="InputCheckersCards"
      spinner={<SpinnerInline size={SpinnerInlineSize.small} />}
      flags={props.storeInputGenericCheckers.storeFlagsFetchCheckers.flags}
    >
      <ContainerTimeline
        itemLabels={filterFalsies([
          renderCheckersGroup(Criticity.Critical),
          renderCheckersGroup(Criticity.High),
          renderCheckersGroup(Criticity.Medium),
          renderCheckersGroup(Criticity.Low)
        ])}
        itemColors={[
          consts.colorRed001,
          consts.colorOrange001,
          consts.colorYellow001,
          consts.colorBlue005
        ]}
        items={filterFalsies([
          getCheckersByCriticity(Criticity.Critical),
          getCheckersByCriticity(Criticity.High),
          getCheckersByCriticity(Criticity.Medium),
          getCheckersByCriticity(Criticity.Low)
        ])}
        reversedBullets={false}
        timelineToBottom
      />
    </ContainerContent>
  )
}

export default observer(InputCheckersCards)
