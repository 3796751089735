import { Label } from '@app/components/Labels'
import { isDefined } from '@libs/isDefined'
import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'
import * as React from 'react'

export interface ITableCellProps {
  className?: string
  style?: React.CSSProperties
  colSpan?: number
  onClick?: () => void
  children?: React.ReactNode
}

const TableCell: React.FC<ITableCellProps> = props => {
  // TODO: enable logger (disabled because of storybook missing context wrapper)
  // const logger = useLogger()

  const allClasses = [
    props.className,
    isDefined(props.colSpan) ? 'toggleable' : ''
  ]
    .filter(isDefined)
    .join(' ')

  const tdProps = filterEmptyProps(
    {
      ...props,
      className: allClasses
    },
    {
      pickProps: ['className', 'style', 'colSpan', 'onClick']
    }
  )

  try {
    return <td {...tdProps}>{props.children}</td>
  } catch (err) {
    // logger.warn('Object received in TableCell. Missing renderFunction?')

    // TODO: handle labelledBy="genericError"
    return <Label>An error has occurred.</Label>
  }
}

export default TableCell
