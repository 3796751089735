import { LabelNonBreakable } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreInputHealthChecks from '@app/stores/StoreInputHealthChecks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IHealthChecksSelectionCounterProps {
  storeInputHealthChecks: StoreInputHealthChecks
}

const HealthChecksSelectionCounter: React.FC<
  IHealthChecksSelectionCounterProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.InputHealthChecks']
  })

  return (
    <LabelNonBreakable>
      {translate('Health checks selected', {
        interpolations: {
          count:
            props.storeInputHealthChecks.selectedHealthChecksTemplatesNamesIds
              .length,
          allCount:
            props.storeInputHealthChecks.healthChecksTemplatesNamesIds.length
        }
      })}
    </LabelNonBreakable>
  )
}

export default observer(HealthChecksSelectionCounter)
