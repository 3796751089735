import {
  ContainerContent,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { useAppTranslator } from '@app/hooks'
import type StoreWidgetsManagement from '@app/stores/Dashboard/StoreWidgetsManagement'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import WidgetInputDataOptionsType from '../WidgetInput/WidgetInputDataOptionsType'
import WidgetInputsDataOptionsSeries from '../WidgetInputsDataOptions/WidgetInputsDataOptionsSeries'

export interface IWidgetInputsForBigNumberProps {
  hasMultipleInstance: boolean
  storeWidgetsManagement: StoreWidgetsManagement
}

const WidgetInputsForBigNumber: React.FC<
  IWidgetInputsForBigNumberProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Dashboard.WidgetCommonForm']
  })

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const fields: IField[] = []

  if (props.hasMultipleInstance) {
    fields.push({
      name: 'instance',
      label: translate('Instance'),
      control: <div>{props.storeWidgetsManagement.instanceName}</div>
    })
  }

  fields.push({
    name: 'dataType',
    label: translate('Type of data'),
    labelAlignItem: themeIsLegacy ? 'baseline' : 'center',
    control: (
      <WidgetInputDataOptionsType
        storeWidgetsManagement={props.storeWidgetsManagement}
      />
    )
  })

  return (
    <ContainerContent name="WidgetInputsForBigNumber">
      <ContainerForm fields={fields} />

      <WidgetInputsDataOptionsSeries
        storeWidgetsManagement={props.storeWidgetsManagement}
      />
    </ContainerContent>
  )
}

export default WidgetInputsForBigNumber
