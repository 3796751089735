import { Features } from '@alsid/common'
import { ContainerHTMLContent } from '@app/components/Container'
import { BladeCancelButton } from '@app/components-legacy/Blade'
import {
  ContainerContent,
  ContainerFlex,
  ContainerFooter,
  ContainerIcon
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import {
  IconCheckCircleFilled,
  IconCloseCircleFilled,
  IconInfoCircleFilled
} from '@app/components-legacy/Icon/IconAntd'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import WidgetFormSelect from '@app/components-legacy/Widgets/WidgetForm/WidgetFormSelect'
import { useAppRouter } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { useStores } from '@app/hooks/useStores'
import { onDirectoryTestConnectivity } from '@app/pages/Management/SystemPage/Directories/DirectoriesCreatePage/handlers'
import { AppRouteName } from '@app/routes'
import type { StoreManagementDirectories } from '@app/stores'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { canAccessTenableCloudSensitiveData } from '../permissions'
import { DirectoryFormFieldName } from './types'

const StyledIconCheckCircleFilled = styled(IconCheckCircleFilled)`
  svg {
    width: 14px;
    height: 14px;
    transform: translateY(1px);
    color: ${consts.colorSuccessAntd};
  }
`

const StyledIconCloseCircleFilled = styled(IconCloseCircleFilled)`
  svg {
    width: 14px;
    height: 14px;
    transform: translateY(1px);
    color: ${consts.colorErrorAntd};
  }
`

const StyledDiv = styled.div`
  padding: 5px 0;
  color: ${consts.colorBlueGray015};

  p {
    display: inline;
  }
`

function getTestConnectivityButtonIcon(
  storeManagementDirectories: StoreManagementDirectories
): React.ReactNode {
  if (
    storeManagementDirectories.storeDirectoryConnectivityTestFlags.flags.isError
  ) {
    return <StyledIconCloseCircleFilled />
  }

  if (storeManagementDirectories.directoryConnectivityTestSuccess) {
    return <StyledIconCheckCircleFilled />
  }

  return null
}

export interface IDirectoryCommonFormProps {
  version: 'creation' | 'edition'
  onCancelButtonClick: () => void
  onSubmit: (e: React.FormEvent<any>) => void
}

const DirectoryCommonForm: React.FC<IDirectoryCommonFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Titles',
      'Management.System.Directories',
      'Management.System.Infrastructures'
    ]
  })

  const {
    storeInfrastructures,
    storeManagementRelays,
    storeManagementDirectories,
    storeManagementDataCollection,
    storeRbac
  } = useStores()
  const tenableCloudSensitiveDataEnabled =
    storeManagementDataCollection.dataCollectionStatusEntity?.getPropertyAsBoolean(
      'sensitiveDataEnabled'
    )
  const appRouter = useAppRouter()

  const featureSensitiveCollectionUserHashes = useFeatureFlag({
    featureFlagName: Features.SENSITIVE_COLLECTION_USER_HASHES
  })

  const { storeRoot } = useStores()
  const featureRelayOnCustomerSide =
    storeRoot.environment.config.app.securerelay.customerside

  const featureSensitiveCollectionDpapiKey = useFeatureFlag({
    featureFlagName: Features.SENSITIVE_COLLECTION_DPAPI_KEY
  })

  const currentInfrastructureId =
    storeManagementDirectories.storeFormDomain.getFieldValueAsNumber(
      DirectoryFormFieldName.infrastructureId
    )

  const currentInfrastructure = currentInfrastructureId
    ? storeInfrastructures.getInfrastructureFromId(currentInfrastructureId)
    : null

  const displayedFields = [
    DirectoryFormFieldName.name,
    DirectoryFormFieldName.dns,
    DirectoryFormFieldName.infrastructureId
  ]

  if (
    featureSensitiveCollectionUserHashes ||
    featureSensitiveCollectionDpapiKey
  ) {
    displayedFields.push(
      DirectoryFormFieldName.sensitiveDataCollectionIsEnabled
    )
  }

  if (featureRelayOnCustomerSide) {
    displayedFields.push(DirectoryFormFieldName.relayId)
  }

  if (storeRbac.isUserGrantedTo(canAccessTenableCloudSensitiveData)) {
    displayedFields.push(DirectoryFormFieldName.tenableCloudSensitiveData)
  }

  return (
    <FormWrapper name="directoryConfiguration" onSubmit={props.onSubmit}>
      <>
        <ContainerFlex
          name="DirectoriesCreateForm"
          direction="column"
          items={[
            <ContainerContent title={translate('Main information')}>
              <WidgetForm<DirectoryFormFieldName>
                translate={translate}
                displayedFields={displayedFields}
                renderFields={{
                  [DirectoryFormFieldName.infrastructureId]: (
                    storeForm,
                    field,
                    inputId
                  ) => {
                    if (props.version === 'creation') {
                      return (
                        <WidgetFormSelect
                          fieldName={field.name}
                          inputId={inputId}
                          inputProps={{
                            labelledBy: 'infrastructure',
                            selectOptions: Array.from(
                              storeInfrastructures.infrastructures.values()
                            ).map(infrastructure => ({
                              value: infrastructure.getPropertyAsString('id'),
                              label: infrastructure.getPropertyAsString('name')
                            }))
                          }}
                          storeForm={storeForm}
                        />
                      )
                    }

                    const infrastructureName = storeInfrastructures
                      .getInfrastructureFromId(Number(field.value))
                      ?.getPropertyAsString('name')

                    // when editing the directory, the infrastructure is not modifiable.
                    // (A directory can't change its forest once created)
                    return <div>{infrastructureName}</div>
                  },
                  [DirectoryFormFieldName.relayId]: (
                    storeForm,
                    field,
                    inputId
                  ) => {
                    return (
                      <WidgetFormSelect
                        fieldName={field.name}
                        inputId={inputId}
                        inputProps={{
                          labelledBy: 'relay',
                          selectOptions: Array.from(
                            storeManagementRelays.relays.values()
                          ).map(relay => ({
                            value: relay.getPropertyAsString('id'),
                            label: relay.getPropertyAsString('name')
                          }))
                        }}
                        storeForm={storeForm}
                      />
                    )
                  }
                }}
                fieldsParams={{
                  [DirectoryFormFieldName.sensitiveDataCollectionIsEnabled]: {
                    inputType: InputType.switch,
                    inputProps: {
                      size: 'default'
                    }
                  },
                  [DirectoryFormFieldName.tenableCloudSensitiveData]: {
                    inputType: InputType.switch,
                    inputProps: {
                      size: 'default',
                      disabled: true,
                      checked: tenableCloudSensitiveDataEnabled
                    }
                  }
                }}
                renderDescriptionFields={{
                  [DirectoryFormFieldName.sensitiveDataCollectionIsEnabled]: (
                    <ContainerFlex
                      name="ContainerIconDescriptionPrivilegedAnalysis"
                      items={[
                        <StyledDiv>
                          <ContainerHTMLContent inline>
                            {translate(
                              'By activating this feature, you indicate that the account set on this forest',
                              {
                                transformMarkdown: true,
                                interpolations: {
                                  infrastructureLogin: currentInfrastructure
                                    ? `\`${currentInfrastructure.login}\``
                                    : ''
                                }
                              }
                            )}
                          </ContainerHTMLContent>

                          <ContainerFlex
                            name="ContainerIconDescriptionPrivilegedAnalysisTooltip"
                            items={[
                              <ContainerIcon
                                labelledBy="privilegedAnalysisInfo"
                                iconComponent={IconInfoCircleFilled}
                                tooltipProps={{
                                  title: (
                                    <div
                                      style={{
                                        fontSize: '11px'
                                      }}
                                    >
                                      {translate(
                                        'The account needs the following extended rights at the domain root'
                                      )}
                                      <ul
                                        style={{
                                          paddingLeft: '15px'
                                        }}
                                      >
                                        <li>
                                          {translate(
                                            'DS-Replication-Get-Changes'
                                          )}
                                        </li>
                                        <li>
                                          {translate(
                                            'DS-Replication-Get-Changes-All'
                                          )}
                                        </li>
                                      </ul>
                                      <ContainerHTMLContent inline>
                                        {translate(
                                          'See Access for Privileged Analysis documentation'
                                        )}
                                      </ContainerHTMLContent>
                                    </div>
                                  ),
                                  placement: 'rightTop'
                                }}
                              />
                            ]}
                            inline
                          />
                        </StyledDiv>
                      ]}
                      spaced
                      spaceWidth="small"
                    />
                  ),
                  [DirectoryFormFieldName.tenableCloudSensitiveData]: (
                    <ContainerFlex
                      name="ContainerIconDescriptionPrivilegedAnalysisTransfer"
                      items={[
                        <StyledDiv>
                          <ContainerHTMLContent inline>
                            {translate(
                              tenableCloudSensitiveDataEnabled
                                ? 'You opted for transferring the privileged data to the Tenable Cloud Service'
                                : 'You opted for not sending the privileged data to the Tenable Cloud Service',
                              {
                                transformMarkdown: true,
                                interpolations: {
                                  uploadDataCollectionPath:
                                    appRouter.makeRouteInfosPathname({
                                      routeName:
                                        AppRouteName.Management_System_Configuration_TenableCloud,
                                      parameters: {}
                                    })
                                }
                              }
                            )}
                          </ContainerHTMLContent>
                        </StyledDiv>
                      ]}
                      spaced
                      spaceWidth="small"
                    />
                  )
                }}
                storeForm={storeManagementDirectories.storeFormDomain}
              />
            </ContainerContent>,

            <ContainerContent title={translate('Primary Domain Controller')}>
              <WidgetForm<DirectoryFormFieldName>
                translate={translate}
                displayedFields={[
                  DirectoryFormFieldName.ip,
                  DirectoryFormFieldName.ldapPort,
                  DirectoryFormFieldName.globalCatalogPort,
                  DirectoryFormFieldName.smbPort
                ]}
                fieldsParams={{
                  [DirectoryFormFieldName.ip]: {
                    inputType: InputType.input,
                    inputProps: {
                      placeholder: 'dc.company.corp'
                    }
                  },
                  [DirectoryFormFieldName.ldapPort]: {
                    inputType: InputType.input,
                    inputProps: {
                      placeholder: '389'
                    }
                  },
                  [DirectoryFormFieldName.globalCatalogPort]: {
                    inputType: InputType.input,
                    inputProps: {
                      placeholder: '3268'
                    }
                  },
                  [DirectoryFormFieldName.smbPort]: {
                    inputType: InputType.input,
                    inputProps: {
                      placeholder: '445'
                    }
                  }
                }}
                storeForm={storeManagementDirectories.storeFormDomain}
              />
            </ContainerContent>
          ]}
          spaced
        />

        <Portal name={PlaceHolderName.bladeFooter}>
          <ContainerFooter>
            <BladeCancelButton />

            <ContainerFlex
              name="DirectoriesCreateFormActionButtons"
              items={[
                <FormWrapperButton
                  labelledBy="directoryTestConnectivity"
                  buttonProps={{
                    icon: getTestConnectivityButtonIcon(
                      storeManagementDirectories
                    ),
                    loading:
                      storeManagementDirectories
                        .storeDirectoryConnectivityTestFlags.isLoading,
                    onClick: onDirectoryTestConnectivity(
                      storeManagementDirectories
                    )
                  }}
                >
                  {translate('Test connectivity')}
                </FormWrapperButton>,
                <FormWrapperButtonSubmit
                  type="primary"
                  loading={
                    storeManagementDirectories.storeSubmitFlags.isLoading
                  }
                >
                  {translate(props.version === 'creation' ? 'Add' : 'Edit')}
                </FormWrapperButtonSubmit>
              ]}
              spaced
            />
          </ContainerFooter>
        </Portal>
      </>
    </FormWrapper>
  )
}

export default observer(DirectoryCommonForm)
