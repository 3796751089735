import { ContainerFlex } from '@app/components-legacy/Container'
import { isDefined } from '@libs/isDefined'
import * as React from 'react'

interface IWidgetListActionButtonsProps {
  style?: React.CSSProperties
  children?: React.ReactNode
}

const WidgetListActionButtons: React.FC<
  IWidgetListActionButtonsProps
> = props => {
  return (
    <ContainerFlex
      name="WidgetListActionButtons"
      style={props.style}
      justifyContent="flex-end"
      items={React.Children.toArray(props.children).filter(isDefined)}
      spaced
      spaceWidth="verySmall"
    />
  )
}

export default WidgetListActionButtons
