import * as moment from 'moment-timezone'

export interface IFormatDateOptions {
  utc?: boolean
  format?: DateFormat | string
  timezone?: string
}

export enum DateFormat {
  iso = 'iso', // 2020-06-11T12:57:37.141Z

  short = 'LL', // June 11, 2020
  verbose = 'LLLL z', // Thursday, June 11, 2020 12:57 PM UTC (only work on UTC date)

  englishFullDate = 'HH:mm:ss, YYYY-MM-DD', // 14:56:38, 2020-06-11
  frenchFullDate = 'YYYY-MM-DD, HH:mm:ss', // 2020-06-11, 14:56:38

  frenchFullDateWithoutSeconds = 'DD/MM/YYYY HH:mm', // 27/01/2021 14:00

  frenchDateOfTheDay = 'DD/MM/YYYY', // 27/01/2021
  englishDateOfTheDay = 'YYYY-MM-DD', // 2020-06-11

  frenchMonthYear = 'MM/YYYY', // 01/2021
  englishMonthYear = 'YYYY-MM', // 2020-06

  fullMonth = 'MMMM YYYY', // January 2021

  englishShortDate = 'MM-DD', // 12-01
  frenchShortDate = 'DD-MM', // 01-12

  dayNumber = 'DD', // 31
  day = 'dddd', // Tuesday
  shortMonth = 'MMM', // Janv.
  month = 'MMMM', // Janvier
  year = 'YYYY', // 2020

  hoursMinutesSeconds = 'HH:mm:ss', // 13:37:42
  hoursMinutes = 'HH:mm' // 13:37
}

/**
 * Format a date according to the passed options.
 */
export function formatDate(
  date: Date | string,
  options?: IFormatDateOptions
): string {
  const isUTC = options?.utc === true
  const legitDate = new Date(date)
  const timezone = options?.timezone

  const getMomentDate = () => {
    if (isUTC) {
      return moment(legitDate).utc()
    }

    if (timezone) {
      return moment.tz(legitDate, timezone)
    }

    return moment(legitDate)
  }

  const getDate = () => {
    const m = getMomentDate()

    if (!options) {
      return m.format(DateFormat.englishFullDate)
    }

    if (!options.format) {
      return m.format(DateFormat.englishFullDate)
    }

    if (options.format === DateFormat.iso) {
      return m.toISOString()
    }

    return m.format(options.format)
  }

  return getDate().trim()
}

/**
 * Convert dates from a stringified timestamp or string.
 */
export function getDateObject(date: string | number): Date {
  // handle date if it's a stringified timestamp
  if (/^\d+$/.test(String(date))) {
    return moment.utc(Number(date)).toDate()
  }

  // handle date in a string format
  return moment.utc(date).toDate()
}
