import { consts } from '@app/styles'
import { buildVariants } from '@design-system/libs/buildVariants'
import { Tooltip } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from 'tenable-design-system-alt'
import ContainerStepTitle from './ContainerStepTitle'

export interface IContainerStepProps extends React.PropsWithChildren {
  stepNumber: number
  title: string
  control: React.ReactNode
  disabled: boolean
  tooltip?: string
}

const StyledContainerFlex = styled(ContainerFlex)`
  padding: ${consts.paddingLarge};
  box-shadow: ${consts.boxShadowRedesignLight};
  border-radius: ${consts.borderRadiusSmallRedesign};
  background: ${consts.BackgroundColorV2.white};
`

const ControlContainer = styled(ContainerFlex)<{ disabled: boolean }>(props => {
  return buildVariants(props)
    .css({
      alignSelf: 'flex-end'
    })

    .variant('disabled', props.disabled, {
      true: {
        cursor: 'not-allowed',
        pointerEvents: 'none'
      },

      false: {}
    })
    .end()
})

export default function ContainerStep(props: IContainerStepProps) {
  return (
    <StyledContainerFlex
      name="ContainerStep"
      flexJustifyContent="space-between"
      flexGrow={1}
    >
      <ContainerFlex
        name="ContainerStepInner"
        flexDirection="column"
        flexGap="spacingL"
      >
        <ContainerStepTitle stepNumber={props.stepNumber} title={props.title} />
        {props.children}
      </ContainerFlex>

      <ControlContainer name="ControlContainer" disabled={props.disabled}>
        {props.tooltip ? (
          <Tooltip title={props.tooltip} placement="left">
            <div>{props.control}</div>
          </Tooltip>
        ) : (
          props.control
        )}
      </ControlContainer>
    </StyledContainerFlex>
  )
}
