import type { EntityTopologyTrust } from '@app/entities'
import { trustAttributesNameValues } from '@app/entities/EntityTopologyTrust'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { TrustAttributesName } from '@app/stores/Topology/types'
import { consts } from '@app/styles'
import { isEqual, sortBy } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'

interface ITrustAttributesProps {
  className?: string
  attributes: string[]
  correlatedTrust: EntityTopologyTrust | null
  fromDirectoryName: string
  toDirectoryName: string
  hazardLevelColor: string
  isSvgDominantBaselineSupported: boolean
}

const TrustAttributes: React.FC<ITrustAttributesProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Topology.Tooltip']
  })

  const getTrustValue = (attribute: TrustAttributesName) => {
    return translate(trustAttributesNameValues[attribute])
  }

  const attributesRender = (attributes: string[]) =>
    attributes.map((attribute, index) => {
      return (
        <li key={index}>{getTrustValue(attribute as TrustAttributesName)}</li>
      )
    })

  const attributesDirectionRender = (fromName: string, toName: string) => (
    <div className="trustAttributesDirection">
      <span>{fromName}</span>
      <span className="arrow">{'> '}</span>
      <span>{toName}</span>
    </div>
  )

  const isAttributeDifferences =
    (props.correlatedTrust &&
      props.correlatedTrust.attributes &&
      !isEqual(
        sortBy(props.attributes),
        sortBy(props.correlatedTrust.attributes)
      )) ||
    false

  return (
    <div className={props.className}>
      <span className="title">
        {props.correlatedTrust
          ? translate('Bidirectional')
          : translate('Unidirectional')}
      </span>

      {!isAttributeDifferences && (
        <div className="trustAttributesBlock">
          <ul className="trustAttributes">
            {attributesRender(props.attributes)}
          </ul>
          {!props.isSvgDominantBaselineSupported &&
            !props.correlatedTrust &&
            attributesDirectionRender(
              props.fromDirectoryName,
              props.toDirectoryName
            )}
        </div>
      )}

      {isAttributeDifferences &&
        props.correlatedTrust &&
        props.correlatedTrust.attributes && (
          <section className="trustAttributesContainer">
            <div className="trustAttributesBlock">
              <ul className="trustAttributes">
                {attributesRender(props.attributes)}
              </ul>
              {attributesDirectionRender(
                props.fromDirectoryName,
                props.toDirectoryName
              )}
            </div>
            <div className="trustAttributesBlock">
              <ul className="trustAttributes">
                {attributesRender(props.correlatedTrust.attributes)}
              </ul>
              {attributesDirectionRender(
                props.toDirectoryName,
                props.fromDirectoryName
              )}
            </div>
          </section>
        )}
    </div>
  )
}

export default styled(TrustAttributes)`
  padding: 10px 30px 10px 10px;

  .title {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .trustAttributes {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 12px;
  }

  .trustAttributesContainer {
    display: flex;
  }

  .trustAttributesBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:first-child {
      padding-right: ${consts.paddingDefault};
      margin-right: ${consts.marginSmall};
      border-right: 1px solid ${consts.colorWhite};
    }

    .trustAttributesDirection {
      margin-top: ${consts.marginSmall};

      span:first-child {
        display: block;
      }
    }
  }

  .arrow {
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.hazardLevelColor};
  }
`
