import { Features } from '@alsid/common'
import { AssetImg } from '@app/components/Asset/AssetImg'
import { ContainerFlex } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks'
import * as React from 'react'
import styled from 'styled-components'
import { TenableProductLogo } from 'tenable-universal-layout'
import { TenableProductLogo as TenableProductLogoLegacy } from 'tenable-universal-layout-v5'

interface IAuthTenableLogoProps {
  className?: string
}

const AuthTenableLogo: React.FC<IAuthTenableLogoProps> = props => {
  const { storeFeatureFlags, storeLayout } = useStores()

  const useTenableIdentityExposureVisuals =
    storeFeatureFlags.getFeatureFlagValue(
      Features.TENABLE_IDENTITY_EXPOSURE_VISUALS
    )
  const useTenableUniversalLayoutLegacy =
    !storeFeatureFlags.getFeatureFlagValue(Features.KAPTEYN_TUL_V6)

  if (useTenableIdentityExposureVisuals) {
    return (
      <ContainerFlex
        name={AuthTenableLogo.name}
        className={props.className}
        alignItems="center"
        justifyContent="center"
        items={[
          <AssetImg
            imagePath="/w/assets/images/logos"
            imageName={
              useTenableUniversalLayoutLegacy
                ? TenableProductLogoLegacy['IE-stacked-FullColor-RGB-logo']
                : TenableProductLogo['IE-stacked-FullColor-RGB-logo']
            }
            fileType="svg"
            width={300}
            labelledBy={storeLayout.getProductName()}
          />
        ]}
      />
    )
  }

  return (
    <ContainerFlex
      name={AuthTenableLogo.name}
      className={props.className}
      alignItems="center"
      justifyContent="center"
      items={[
        <AssetImg
          imageName="logo-tenable-ad-color-vertical"
          width={300}
          fileType="png"
          labelledBy="tenableLogo"
        />
      ]}
    />
  )
}

export default styled(AuthTenableLogo)`
  height: 300px;
`
