import type { Maybe } from '@@types/helpers'
import { useStores } from '@app/hooks/useStores'
import type { IWindowSize } from '@app/hooks/useWindowSize'
import { SCENE_ZOOM_MAX_VALUE } from '@app/stores/Topology/consts'
import { consts } from '@app/styles'
import { multiply } from '@app/styles/helpers'
import { isDefined } from '@libs/isDefined'
import { select, zoom } from 'd3'
import * as React from 'react'

/**
 * When the div ref is set, set the scene dimensions into the store.
 * It will trigger a component render with the `sceneIsReady` flag set to true,
 * allowing to render the scene.
 */
export function useSceneDimensions(
  windowSize: Maybe<IWindowSize>
): React.RefObject<HTMLDivElement> {
  const sceneDivRef = React.useRef<HTMLDivElement>(null)

  const { storeTopology } = useStores()

  /**
   * When the div ref is set, set the scene dimensions into the store.
   * It will trigger a component render with the `sceneIsReady` flag set to true,
   * allowing to render the scene.
   */
  React.useEffect(() => {
    if (!sceneDivRef.current) {
      return
    }

    const widthPadding = Number(multiply(consts.paddingLarge, 2, false))
    const heightPadding = Number(multiply(consts.paddingMedium, 2, false))

    storeTopology.setSceneDimensions({
      width:
        sceneDivRef.current.offsetWidth +
        (isNaN(widthPadding) ? 0 : widthPadding),
      height:
        sceneDivRef.current.offsetHeight +
        (isNaN(heightPadding) ? 0 : heightPadding)
    })
  }, [sceneDivRef, windowSize])

  return sceneDivRef
}

/**
 * Save the SVG ref at mount.
 */
export function useSceneSvgRefInit(): React.RefObject<SVGSVGElement> {
  const chartSVGRef = React.useRef<SVGSVGElement>(null)

  const { storeTopology } = useStores()

  React.useEffect(() => {
    if (!chartSVGRef.current) {
      return
    }

    storeTopology.setChartSvgRef(chartSVGRef.current)
  }, [])

  return chartSVGRef
}

/**
 * Add a listener of the zoom event to update the ZoomSlider.
 */
export function useSceneZoomBinding(
  chartSVGRef: React.RefObject<SVGSVGElement>
): void {
  const { storeTopology } = useStores()

  React.useEffect(() => {
    if (!chartSVGRef.current || !isDefined(storeTopology.chartZoomMin)) {
      return
    }

    const selection = select(chartSVGRef.current)

    // on zoom event, save x, y, k
    const handleZoom = zoom<SVGSVGElement, any>()
      .on('zoom', event => {
        storeTopology.setChartZoomData(event.transform)
      })
      .scaleExtent([storeTopology.chartZoomMin, SCENE_ZOOM_MAX_VALUE])

    storeTopology.setHandleZoom(handleZoom)
    selection.call(handleZoom)

    if (storeTopology.zoomSliderValue) {
      handleZoom.scaleTo(selection, storeTopology.zoomSliderValue)
    }

    return () => {
      selection.on('zoom', null)
    }
  }, [chartSVGRef, storeTopology.chartZoomMin, storeTopology.zoomSliderValue])
}

/**
 * Add a zoom focus on researched element(s)
 */
export function useSceneZoomWithResearch(): void {
  const { storeTopology } = useStores()

  React.useEffect(() => {
    if (!storeTopology.handleZoom || !storeTopology.chartSvgRef) {
      return
    }

    const selection = select(storeTopology.chartSvgRef)

    const searchedInfraUidsLength =
      storeTopology.searchedInfrastructureUids.length

    if (!searchedInfraUidsLength) {
      return
    }

    // Only one result
    if (searchedInfraUidsLength === 1) {
      const infrastructureUid = storeTopology.searchedInfrastructureUids[0]
      if (!storeTopology.infrastructuresSceneObjects) {
        return
      }

      const infrastructureSceneObject =
        storeTopology.infrastructuresSceneObjects.find(
          infSceneObj => infSceneObj.object.uid === infrastructureUid
        )

      if (!infrastructureSceneObject) {
        return
      }

      storeTopology.handleZoom.scaleTo(selection, 0.9)
      storeTopology.handleZoom.translateTo(
        selection,
        infrastructureSceneObject.coordinates.x -
          (storeTopology.chartScaleWidth -
            storeTopology.sceneDimensions.width) /
            2,
        infrastructureSceneObject.coordinates.y -
          (storeTopology.chartScaleHeight -
            storeTopology.sceneDimensions.height) /
            2
      )
      return
    }

    // Multiple results
    if (
      !storeTopology.chartZoomMin ||
      !storeTopology.topologyEntity.packCoordinates
    ) {
      return
    }

    storeTopology.handleZoom.scaleTo(selection, storeTopology.chartZoomMin)
    storeTopology.handleZoom.translateTo(
      selection,
      storeTopology.topologyEntity.packCoordinates.x -
        (storeTopology.chartScaleWidth - storeTopology.sceneDimensions.width) /
          2,
      storeTopology.topologyEntity.packCoordinates.y -
        (storeTopology.chartScaleHeight -
          storeTopology.sceneDimensions.height) /
          2
    )
  }, [storeTopology.searchedInfrastructureUids])
}

/**
 * Add a micro level of zoom on window resize event in order to force a redraw
 * of trusts arrows that are not shifted when we resize the window.
 */
export function useArrayRedrawOnResize(windowSize: Maybe<IWindowSize>): void {
  const { storeTopology } = useStores()

  React.useEffect(() => {
    const actualZoomValue = storeTopology.zoomSliderValue

    if (!actualZoomValue) {
      return
    }

    setTimeout(() => {
      const newZoomValue = actualZoomValue + 0.001
      storeTopology.setZoomSliderValue(newZoomValue)
    }, 250)
  }, [windowSize])
}
