import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'

interface IIconSecurityEngineProps extends IIconProps {}

export default function IconSecurityEngine(props: IIconSecurityEngineProps) {
  const size = props.size ?? 20

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.625 16.875L15.625 6.875M11.875 16.875V14.375M8.125 16.875L8.125 10.625M4.375 16.875L4.375 6.875M15.625 3.125V4.375M11.875 3.125L11.875 11.875M8.125 3.125L8.125 8.125M4.375 3.125L4.375 4.375M16.875 6.875L14.375 6.875M13.125 14.375H10.625M9.375 10.625H6.875M5.625 6.875L3.125 6.875"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
