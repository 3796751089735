import {
  ContainerDividerFilter,
  ContainerFlex,
  ContainerRbac
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { InputInfrastructures } from '@app/components-legacy/Input'
import { InputCheckers } from '@app/components-legacy/Input/InputCheckers'
import { useAppTranslator, useStores } from '@app/hooks'
import { breakpointLargeViewPort } from '@app/styles/consts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { canAccessToIoATimeline } from '../permissions'
import FiltersDate from './FiltersDate'
import { handleTimelineRefreshButton } from './FiltersDate/handlers'
import FiltersTimeline from './FiltersTimeline'
import { handleAttackSearchSubmit } from './handlers'

interface IIoABoardFiltersProps {}

const StyledIoABoardFilters = styled(ContainerFlex)`
  @media only screen and (max-width: ${breakpointLargeViewPort}px) {
    margin-top: 10px;
  }
`

const StyledFilters = styled(ContainerFlex)`
  @media only screen and (max-width: ${breakpointLargeViewPort}px) {
    height: 200px;
  }
`

const IoABoardFilters: React.FC<IIoABoardFiltersProps> = () => {
  const translate = useAppTranslator({ namespaces: ['Buttons'] })

  const { storeIoA } = useStores()

  return (
    <StyledFilters
      name="IoABoardFilters"
      direction="column"
      items={[
        <ContainerFlex
          name="IoABoardFiltersTop"
          justifyContent="space-between"
          flexWrap="wrap"
          alignItems="center"
          items={[
            <FiltersDate />,

            <StyledIoABoardFilters
              name="IoABoardFilters"
              alignItems="center"
              items={[
                <InputInfrastructures
                  storeInfrastructures={
                    storeIoA.storeBoard.storeInfrastructures
                  }
                  onValidate={handleAttackSearchSubmit(storeIoA)}
                />,

                <InputCheckers
                  storeInputGenericCheckers={
                    storeIoA.storeBoard.storeInputCheckersAttacks
                  }
                  onValidate={handleAttackSearchSubmit(storeIoA)}
                />,

                <ContainerDividerFilter />,

                <FormWrapperButton
                  labelledBy="refresh"
                  buttonProps={{
                    type: 'primary',
                    disabled:
                      storeIoA.storeBoard.storeFlagsReloadAttacksSummary
                        .isLoading,
                    onClick: handleTimelineRefreshButton(storeIoA.storeBoard)
                  }}
                >
                  {translate('Refresh')}
                </FormWrapperButton>
              ]}
              spaced
              spaceWidth="small"
            />
          ]}
          spaced
        />,

        <ContainerRbac rbacCapabilityCheck={canAccessToIoATimeline}>
          <FiltersTimeline
            isLoading={
              storeIoA.storeBoard.storeFlagsReloadAttacksSummary.isLoading
            }
          />
        </ContainerRbac>
      ]}
      paddingV="small"
      spaced
    />
  )
}

export default observer(IoABoardFilters)
