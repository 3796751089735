import { updateIoABoardUrlWithFilters } from '@app/pages/IoA/IoABoardPage/functions'
import type { StoreIoA } from '@app/stores'
import type StoreBoard from '@app/stores/IoA/StoreBoard'
import { addDuration, convertTimestampToIsoString } from '@libs/dates/helpers'

/**
 * Fetch attacksStatsCounts for show the tooltip counts attacks informations.
 */
export const handleTimelinePillClick =
  (storeBoard: StoreBoard) =>
  (timestampStart: string) =>
  (visible: boolean) => {
    const { storeTimeline, storeInfrastructures } = storeBoard

    if (!visible) {
      return
    }

    const profileId =
      storeBoard.storeRoot.stores.storeAuthentication.currentProfileId

    const dateStart = convertTimestampToIsoString(timestampStart)
    const dateEnd = addDuration(dateStart, storeTimeline.interval) || dateStart
    const isClosed = storeBoard.storeSummaryCards
      .displayOnlyAttackedDomainsSwitch
      ? false
      : undefined

    storeTimeline.fetchAttacksCounts({
      profileId,
      dateStart,
      dateEnd,
      directoryIds: storeInfrastructures.getDirectoryIds(),
      ioaSeries: storeBoard.ioaSeriesBySelectedCriticity,
      isClosed
    })
  }

/**
 * Fetch next attackSummaries from timeline navigation.
 */
export const handleTimelineNextClick = (storeIoA: StoreIoA) => () => {
  storeIoA.storeBoard.storeTimeline.setDateStart(
    storeIoA.storeBoard.storeTimeline.nextDateStart
  )

  updateIoABoardUrlWithFilters(storeIoA)
  storeIoA.storeBoard.reloadAttacksSummary()
}

/**
 * Fetch previous attackSummaries from timeline navigation.
 */
export const handleTimelinePreviousClick = (storeIoA: StoreIoA) => () => {
  storeIoA.storeBoard.storeTimeline.setDateStart(
    storeIoA.storeBoard.storeTimeline.previousDateStart
  )

  updateIoABoardUrlWithFilters(storeIoA)
  storeIoA.storeBoard.reloadAttacksSummary()
}
