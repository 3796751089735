import { BulletStatusKO, BulletStatusOK } from '@app/components-legacy/Bullet'
import BulletStatusDisabled from '@app/components-legacy/Bullet/BulletStatusDisabled'
import {
  ContainerHTMLContent,
  ContainerIcon
} from '@app/components-legacy/Container'
import {
  IconClockCircleFilled,
  IconSyncOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { useAppTranslator } from '@app/hooks'
import { getDescription } from '@app/pages/Management/SystemPage/Tenants/TenantsPage/helpers'
import { consts } from '@app/styles'
import { DateFormat, formatDate } from '@libs/dates/formatDate'
import { caseNotHandled } from '@libs/helpers/objects/caseNotHandled'
import type { ScanInfo } from '@libs/openapi/service-identity-core'
import { ScanInfoScanStatusEnum } from '@libs/openapi/service-identity-core'
import * as React from 'react'

export interface ILabelTenantScanStatusProps {
  disabled?: boolean
  scanInfo?: ScanInfo
}

export default function LabelTenantScanStatus(
  props: ILabelTenantScanStatusProps
) {
  const translate = useAppTranslator({
    namespaces: ['Components.LabelTenantScanStatus']
  })

  const scanInfo = props.scanInfo ?? {
    description: translate('Scan will start soon'),
    scanStatus: ScanInfoScanStatusEnum.Queued
  }

  const TooltipTitle = () => {
    const description = props.scanInfo
      ? translate('Description', {
          interpolations: {
            description: getDescription(scanInfo, translate),
            scanRequestedAt: scanInfo.scanRequestedAt
              ? formatDate(scanInfo.scanRequestedAt, {
                  format: DateFormat.verbose
                })
              : '-',
            scanUpdatedAt: scanInfo.scanUpdatedAt
              ? formatDate(scanInfo.scanUpdatedAt, {
                  format: DateFormat.verbose
                })
              : '-'
          },
          transformMarkdown: true
        })
      : translate(scanInfo.description)

    return <ContainerHTMLContent padded>{description}</ContainerHTMLContent>
  }

  const DryRunTooltipTitle = () => {
    const description = translate('The scan has been requested')
    return <ContainerHTMLContent padded>{description}</ContainerHTMLContent>
  }

  if (props.disabled) {
    return (
      <ContainerIcon
        iconComponent={BulletStatusDisabled}
        labelledBy="disabled"
        tooltipProps={{
          title: 'The analysis is disabled'
        }}
      />
    )
  }

  if (props.scanInfo?.dryRun) {
    return (
      <ContainerIcon
        iconComponent={IconClockCircleFilled}
        iconProps={{
          color: consts.colorBlueGray015
        }}
        labelledBy={ScanInfoScanStatusEnum.Requested}
        tooltipProps={{
          title: <DryRunTooltipTitle />
        }}
      />
    )
  }

  const fallbackValue = (
    <ContainerIcon
      iconComponent={BulletStatusKO}
      labelledBy={scanInfo.scanStatus}
      tooltipProps={{
        title: <TooltipTitle />
      }}
    />
  )

  switch (scanInfo.scanStatus) {
    case ScanInfoScanStatusEnum.Queued:
    case ScanInfoScanStatusEnum.Requested: {
      return (
        <ContainerIcon
          iconComponent={IconClockCircleFilled}
          iconProps={{
            color: consts.colorBlueGray015
          }}
          labelledBy={scanInfo.scanStatus}
          tooltipProps={{
            title: <TooltipTitle />
          }}
        />
      )
    }

    case ScanInfoScanStatusEnum.Started:
    case ScanInfoScanStatusEnum.InProgress: {
      return (
        <ContainerIcon
          iconComponent={IconSyncOutlined}
          iconProps={{
            spin: true
          }}
          labelledBy={scanInfo.scanStatus}
          tooltipProps={{
            title: <TooltipTitle />
          }}
        />
      )
    }

    case ScanInfoScanStatusEnum.EndedSuccessfully: {
      return (
        <ContainerIcon
          iconComponent={BulletStatusOK}
          labelledBy={scanInfo.scanStatus}
          tooltipProps={{
            title: <TooltipTitle />
          }}
        />
      )
    }

    case ScanInfoScanStatusEnum.Dropped: {
      return (
        <ContainerIcon
          iconComponent={BulletStatusDisabled}
          labelledBy={scanInfo.scanStatus}
          tooltipProps={{
            title: <TooltipTitle />
          }}
        />
      )
    }

    case ScanInfoScanStatusEnum.EndedInError: {
      return fallbackValue
    }

    default:
      return caseNotHandled(scanInfo.scanStatus, fallbackValue)
  }
}
