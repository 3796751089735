import { ContainerFlex } from '@app/components/Container'
import { LabelBreakable } from '@app/components/Labels'
import { ButtonCopyToClipboard } from '@app/components-legacy/Button'
import ContainerHTMLContentExtended from '@app/components-legacy/Container/ContainerHTMLContentExtended'
import type { IDataRowFinding } from '@app/entities/EntityGenericChecker/EntityFinding'
import { BackgroundColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

interface IFindingDetailsProps {
  dataRowFinding: IDataRowFinding
}

const StyledContainerFlex = styled(ContainerFlex)`
  background-color: ${BackgroundColorV2.lightGray};
`

const StyledContainerFlexContent = styled(ContainerFlex)`
  background-color: ${BackgroundColorV2.white};
`

const FindingDetails: React.FC<IFindingDetailsProps> = props => {
  return (
    <StyledContainerFlex
      name="FindingDetails"
      paddingH="large"
      paddingV="medium"
      itemsFlexGrow={[1]}
      items={[
        <StyledContainerFlexContent
          name="FindingDetailsContent"
          paddingH="medium"
          paddingV="small"
          justifyContent="space-between"
          items={[
            <LabelBreakable>
              <ContainerHTMLContentExtended inline>
                {props.dataRowFinding.description}
              </ContainerHTMLContentExtended>
            </LabelBreakable>,

            <ButtonCopyToClipboard
              variant="small"
              textToCopy={props.dataRowFinding.rawDescription}
            />
          ]}
        />
      ]}
    />
  )
}

export default FindingDetails
