import type { Perhaps } from '@@types/helpers'
import type { ICheckerOptionArraySelectValue } from '@app/stores/Management/StoreProfiles/types'
import { DateFormat, formatDate } from '@libs/dates/formatDate'
import { ensureArray } from '@libs/ensureArray'
import { JSONBigIntParse } from '@libs/json/bigint'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { last } from 'lodash'
import type { IDecodeOptions } from './types'
import { ValueType } from './types'

/**
 * Decode the value according to its valueType.
 */
export function decodeValue(
  value: Perhaps<string>,
  valueType: ValueType,
  options?: IDecodeOptions
): string | string[] {
  const finalOptions: IDecodeOptions = {
    lastValueOnly: false,
    fallback: '-',
    ...options
  }

  let retValue: any

  if (value === null || value === undefined) {
    return String(finalOptions.fallback)
  }

  try {
    // use JSONbig to handle big integers
    retValue = JSONBigIntParse(value)
  } catch (err) {
    retValue = String(value || finalOptions.fallback)
  }

  try {
    switch (valueType) {
      case ValueType.integer:
      case ValueType.string:
      case ValueType.sddl:
      case ValueType.regex:
      case ValueType.boolean:
        retValue = String(retValue)
        break

      case ValueType.float:
        retValue = String(retValue).replace(/,/, '.')
        break

      case ValueType.date:
        retValue = formatDate(retValue, { format: DateFormat.iso })
        break

      case ValueType.arrayInteger:
      case ValueType.arrayString:
      case ValueType.arrayCron:
      case ValueType.arrayRegex:
      case ValueType.arrayBoolean:
        retValue = ensureArray(retValue).map(v => String(v))
        break

      case ValueType.arraySelect: {
        const selectChoices = retValue as ICheckerOptionArraySelectValue

        if (selectChoices.chosen === undefined) {
          throw new Error('Chosen value is undefined')
        }

        retValue = selectChoices.chosen
        break
      }
      case ValueType.object:
      case ValueType.arrayObject:
        retValue = JSON.stringify(retValue)
        break

      default:
        assertUnreachableCase(valueType)
    }
  } catch (err) {
    retValue = 'Unable to decode the value'
  }

  if (Array.isArray(retValue) && finalOptions.lastValueOnly) {
    retValue = last(retValue)
  }

  // slice the value to avoid to make diffs on very huge values
  // that leads some freezes in the browser.
  if (
    retValue &&
    typeof retValue === 'string' &&
    finalOptions.maxLength &&
    // if finalOptions.maxLength === -1, don't slice
    finalOptions.maxLength > 0 &&
    retValue.length > finalOptions.maxLength
  ) {
    retValue = retValue.slice(0, finalOptions.maxLength) + '…'
  }

  return retValue
}
