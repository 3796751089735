import {
  IconCheckCircle,
  IconCloseCircle,
  IconUnknownCircle,
  IconWarningTriangleFilled
} from '@app/components/Icon'
import { useTestAttribute } from '@app/hooks'
import { HealthCheckStatusTooltip } from '@app/pages/HealthCheck/HealthCheckStatusTooltip'
import { isDefined } from '@libs/isDefined'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type { Maybe } from '@server/graphql/typeDefs/types'
import { HealthCheckStatus } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  display: flex;
`

export interface IHealthCheckStatusIconProps {
  className?: string
  style?: React.CSSProperties
  labelledBy?: string
  status: Maybe<HealthCheckStatus>
  withTooltip?: boolean
}

export function HealthCheckStatusIcon(props: IHealthCheckStatusIconProps) {
  const { testAttributeProps } = useTestAttribute('img')

  if (!isDefined(props.status)) {
    return null
  }

  function getIcon() {
    switch (props.status) {
      case HealthCheckStatus.Success:
        return <IconCheckCircle style={props.style} />
      case HealthCheckStatus.Failure:
        return <IconCloseCircle style={props.style} />
      case HealthCheckStatus.Warning:
        return <IconWarningTriangleFilled size={16} style={props.style} />
      case HealthCheckStatus.Unknown:
        return <IconUnknownCircle style={props.style} />
      default:
        assertUnreachableCase(props.status)
    }
  }

  const icon = (
    <StyledDiv
      className={props.className}
      style={props.style}
      {...testAttributeProps()(
        `statusIcon/${props.labelledBy ?? ''}/${props.status}`
      )}
    >
      {getIcon()}
    </StyledDiv>
  )

  return props.withTooltip ? (
    <HealthCheckStatusTooltip status={props.status}>
      {icon}
    </HealthCheckStatusTooltip>
  ) : (
    icon
  )
}
