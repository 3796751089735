import { BladeCancelButton } from '@app/components-legacy/Blade'
import {
  ContainerContent,
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  handleHoneyAccountInputSearch,
  handleHoneyAccountInputSelect
} from './handlers'
import { HoneyAccountFormFieldName } from './types'

export interface IHoneyAccountCommonFormProps {
  version: 'creation' | 'edition'
  directoryId: number
  onCancelButtonClick: () => void
  onSubmit: (e: React.FormEvent<any>) => void
  onDelete?: (e: React.FormEvent<any>) => void
}

const HoneyAccountCommonForm: React.FC<
  IHoneyAccountCommonFormProps
> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Titles',
      'Management.System.Directories',
      'Management.System.Infrastructures'
    ]
  })

  const { storeManagementDirectories } = useStores()
  const { storeFormHoneyAccount } = storeManagementDirectories

  return (
    <FormWrapper name="honeyAccountConfiguration" onSubmit={props.onSubmit}>
      <>
        <ContainerFlex
          name="honeyAccountCreateForm"
          direction="column"
          items={[
            <ContainerContent>
              <WidgetForm<HoneyAccountFormFieldName>
                translate={translate}
                displayedFields={[HoneyAccountFormFieldName.name]}
                fieldsParams={{
                  [HoneyAccountFormFieldName.name]: {
                    inputType: InputType.inputAutoComplete,
                    inputProps: {
                      labelledBy: 'name',
                      inputSearchProps: {
                        loading:
                          storeManagementDirectories
                            .storeSearchHoneyAccountsFlags.isLoading || false
                      },
                      autoCompleteProps: {
                        autoFocus: true,
                        onSelect: handleHoneyAccountInputSelect(
                          storeManagementDirectories
                        ),
                        onSearch: handleHoneyAccountInputSearch(
                          storeManagementDirectories
                        )(props.directoryId)
                      },
                      autoCompleteOptions:
                        storeManagementDirectories.getHoneyAccountCandidatesAsOptions(
                          props.directoryId
                        )
                    }
                  }
                }}
                storeForm={storeFormHoneyAccount}
              />
            </ContainerContent>
          ]}
          spaced
        />

        <Portal name={PlaceHolderName.bladeFooter}>
          <ContainerFooter>
            <BladeCancelButton />

            <ContainerFlex
              name="honeyAccountButtonForm"
              labelledBy="honeyAccountButtonForm"
              direction="row"
              items={[
                props.version === 'edition' && (
                  <FormWrapperButtonSubmit
                    type="primary"
                    htmlType="button"
                    onClick={props.onDelete}
                    danger
                    loading={
                      storeManagementDirectories.storeSubmitFlags.isLoading
                    }
                  >
                    {translate('Delete')}
                  </FormWrapperButtonSubmit>
                ),

                <FormWrapperButtonSubmit
                  type="primary"
                  loading={
                    storeManagementDirectories.storeSubmitFlags.isLoading
                  }
                >
                  {translate(props.version === 'creation' ? 'Add' : 'Edit')}
                </FormWrapperButtonSubmit>
              ]}
              spaced
            />
          </ContainerFooter>
        </Portal>
      </>
    </FormWrapper>
  )
}

export default observer(HoneyAccountCommonForm)
