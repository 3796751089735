import { LabelSliced } from '@app/components/Labels'
import type { Maybe } from '@server/graphql/typeDefs/types'
import React from 'react'
import styled from 'styled-components'

const StyledLabel = styled(LabelSliced)`
  width: 180px;
  display: inline-block;
`
export const UserLogUser = (props: { user: Maybe<string> }) => {
  return (
    <StyledLabel
      className="traceUser"
      labelledBy="user"
      popoverType="tooltip"
      value={props.user ?? ''}
      maxLength={23}
    />
  )
}
