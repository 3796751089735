import { ErrorMessage } from '@app/components/Error'
import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { IconDeleteOutlined } from '@app/components-legacy/Icon/IconAntd'
import type StoreWizard from '@app/stores/helpers/StoreWizard'
import type { WizardRuleGroup } from '@app/stores/helpers/StoreWizard/types'
import { WizardRuleGroupFieldName } from '@app/stores/helpers/StoreWizard/types'
import { isWizardRuleGroup } from '@app/stores/helpers/StoreWizard/utils'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { Combinator } from '@libs/Expression/types'
import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'
import Button from 'antd/lib/button'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import IconTrash from '../../../../components/Icon/IconTrash'
import SwitchIsDeviant from './formElements/SwitchIsDeviant'
import {
  onCombinatorChange,
  onDeviantCombinatorChange,
  onRemoveRuleOrRuleGroup
} from './handlers'
import { getButtonType, isFirstRuleGroupLevel } from './utils'

interface IRuleGroupProps {
  className?: string
  index: number
  ruleGroupPath: number[]
  enableIsDeviantSwitch: boolean
  ruleGroup: WizardRuleGroup
  storeWizard: StoreWizard
}

const RuleGroup: React.FC<IRuleGroupProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const isFirstLevel = isFirstRuleGroupLevel(props.ruleGroupPath)

  if (!isWizardRuleGroup(props.ruleGroup)) {
    return <ErrorMessage />
  }

  const { storeForm } = props.ruleGroup

  const deviantCombinator = props.storeWizard.deviantCombinator

  const combinatorValue = storeForm.getFieldValueAsString<Combinator>(
    WizardRuleGroupFieldName.combinator
  )

  return (
    <ContainerFlex
      className={props.className}
      name="RuleGroup"
      alignItems="center"
      justifyContent="space-between"
      items={[
        <ContainerFlex
          name="RuleGroupInfo"
          alignItems="center"
          items={[
            <Button.Group>
              <FormWrapperButton
                labelledBy="and"
                buttonProps={{
                  type: getButtonType(Combinator.AND)(combinatorValue),
                  onClick: onCombinatorChange(combinatorValue, storeForm)
                }}
              >
                {Combinator.AND}
              </FormWrapperButton>

              <FormWrapperButton
                labelledBy="or"
                buttonProps={{
                  type: getButtonType(Combinator.OR)(combinatorValue),
                  onClick: onCombinatorChange(combinatorValue, storeForm)
                }}
              >
                {Combinator.OR}
              </FormWrapperButton>
            </Button.Group>
          ]}
          spaced
        />,

        isFirstLevel && (
          <ContainerFlex
            name="RuleGroupButton"
            alignItems="center"
            items={[
              props.enableIsDeviantSwitch && (
                <Button.Group>
                  <FormWrapperButton
                    labelledBy="and"
                    buttonProps={{
                      type: getButtonType(Combinator.AND)(deviantCombinator),
                      onClick: onDeviantCombinatorChange(
                        deviantCombinator,
                        props.storeWizard
                      )
                    }}
                  >
                    {Combinator.AND}
                  </FormWrapperButton>

                  <FormWrapperButton
                    labelledBy="and"
                    buttonProps={{
                      type: getButtonType(Combinator.OR)(deviantCombinator),
                      onClick: onDeviantCombinatorChange(
                        deviantCombinator,
                        props.storeWizard
                      )
                    }}
                  >
                    {Combinator.OR}
                  </FormWrapperButton>
                </Button.Group>
              ),

              props.enableIsDeviantSwitch && (
                <SwitchIsDeviant storeWizard={props.storeWizard} />
              )
            ]}
            spaced
          />
        ),

        !isFirstLevel && (
          <FormWrapperButton
            labelledBy="submit"
            icon={!themeIsLegacy ? IconTrash : undefined}
            buttonProps={filterEmptyProps({
              size: 'small',
              icon: themeIsLegacy ? <IconDeleteOutlined /> : undefined,
              onClick: onRemoveRuleOrRuleGroup(
                props.index,
                props.ruleGroupPath,
                props.storeWizard
              )
            })}
          />
        )
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

const ObservedRuleGroup = observer(RuleGroup)

export default styled(ObservedRuleGroup)`
  padding: 10px;
  margin: -10px -10px 10px -10px;
  background-color: ${props =>
    props.theme.tokens['inputExpression/backgroundColor/default']};
`
