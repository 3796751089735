import { consts } from '@app/styles'
import type { TContainerFlexSpaceWidth } from './types'

export function getWidthValue(width: TContainerFlexSpaceWidth) {
  switch (width) {
    case 'none':
      return '0px'
    case 'veryVerySmall':
      return consts.marginVeryVerySmall
    case 'verySmall':
      return consts.marginVerySmall
    case 'small':
      return consts.marginSmall
    case 'smaller':
      return consts.marginSmaller
    case 'default':
      return consts.marginDefault
    case 'medium':
      return consts.marginMedium
    case 'large':
      return consts.marginLarge
  }
}
