import {
  ContainerContent,
  ContainerFooter,
  ContainerHTMLContent,
  ContainerIcon
} from '@app/components-legacy/Container'
import {
  DrawerContext,
  useDrawerContext
} from '@app/components-legacy/Drawer/context'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { IconDeleteOutlined } from '@app/components-legacy/Icon/IconAntd'
import { InputSearch } from '@app/components-legacy/Input'
import { LabelChecker, LabelMissingData } from '@app/components-legacy/Label'
import { LabelCheckerSize } from '@app/components-legacy/Label/LabelChecker/types'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import {
  WidgetListTable,
  WidgetListTableActionIcons
} from '@app/components-legacy/Widgets/WidgetList'
import type { EntityAttackRemoveFilter } from '@app/entities'
import type { IDataRowAttackRemoveFilter } from '@app/entities/EntityAttackRemoveFilter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreAttacks from '@app/stores/IoA/StoreAttacks'
import { startCase } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  onAttacksRemoveAllFiltersButtonClick,
  onAttacksRemoveFiltersCancelIconClick as onAttacksRemoveFiltersCancelButtonClick,
  onAttacksRemoveFiltersDeleteIconClick
} from './handlers'

interface IIoADrawerAttacksRemoveFiltersListContentProps {
  className?: string
  storeAttacks: StoreAttacks
}

const LabelCheckerStyled = styled(LabelChecker)`
  margin: 5px 0;
`

const IoADrawerAttacksRemoveFiltersListContent: React.FC<
  IIoADrawerAttacksRemoveFiltersListContentProps
> = props => {
  const contextValue = useDrawerContext(DrawerContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoA.Attacks.DrawerRemoveFilters']
  })

  if (!contextValue) {
    return <LabelMissingData />
  }

  const { storeInputCheckersAttacks, storeInputSearch } =
    props.storeAttacks.storeAttackRemoveFilters

  return (
    <>
      <ContainerContent>
        <ContainerHTMLContent>
          {translate('List of attack suppression filters', {
            transformMarkdown: true,
            interpolations: {
              resourceType: props.storeAttacks.resourceTypeTranslation,
              resourceValue: props.storeAttacks.resourceValueTranslation
            }
          })}
        </ContainerHTMLContent>

        {props.storeAttacks.storeAttackRemoveFilters.storeWidgetList
          .allEntitiesAsArray.length && (
          <InputSearch
            className={props.className}
            placeholder={translate('Search for a filter')}
            storeInputSearch={storeInputSearch}
          />
        )}

        <WidgetListTable<EntityAttackRemoveFilter, IDataRowAttackRemoveFilter>
          translate={translate}
          hiddenColumnsKeys={['id']}
          storeWidgetList={
            props.storeAttacks.storeAttackRemoveFilters.storeWidgetList
          }
          cellsRenderFn={{
            resourceType: resourceType => {
              return translate(startCase(resourceType))
            },

            resourceValue: () => {
              return props.storeAttacks.resourceValueTranslation
            },

            attackTypeId: attackTypeId => {
              const attackType =
                storeInputCheckersAttacks.checkersById.get(attackTypeId)

              if (!attackType) {
                return '-'
              }

              return (
                <LabelCheckerStyled
                  name={attackType.getPropertyAsString('name')}
                  codename={attackType.genericCodename}
                  criticity={attackType.genericCriticity}
                  size={LabelCheckerSize.small}
                  small
                  hoverable={false}
                />
              )
            }
          }}
          actionsButtonsRenderFn={attackTypeId => {
            return (
              <WidgetListTableActionIcons
                icons={[
                  <ContainerIcon
                    labelledBy="removeAttackFilter"
                    iconComponent={IconDeleteOutlined}
                    iconProps={{
                      type: 'edit',
                      onClick: onAttacksRemoveFiltersDeleteIconClick(
                        props.storeAttacks
                      )(Number(attackTypeId))
                    }}
                  />
                ]}
              />
            )
          }}
        />
      </ContainerContent>

      <Portal name={PlaceHolderName.drawerFooter}>
        <ContainerFooter>
          <FormWrapperButton
            labelledBy="close"
            buttonProps={{
              type: 'default',
              onClick: onAttacksRemoveFiltersCancelButtonClick(
                props.storeAttacks
              )
            }}
          >
            {translate('Close')}
          </FormWrapperButton>

          <FormWrapperButton
            labelledBy="close"
            buttonProps={{
              type: 'primary',
              onClick: onAttacksRemoveAllFiltersButtonClick(props.storeAttacks),
              loading:
                props.storeAttacks.storeFlagsUnremoveAllAttacks.flags.isLoading
            }}
          >
            {translate('Remove all filters')}
          </FormWrapperButton>
        </ContainerFooter>
      </Portal>
    </>
  )
}

export default observer(IoADrawerAttacksRemoveFiltersListContent)
