import type { PropertiesNullable } from '@@types/helpers'
import type {
  Maybe,
  TenableCloudApiToken
} from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityTenableCloudApiToken
  extends EntityBase
  implements PropertiesNullable<TenableCloudApiToken>
{
  accessKey: Maybe<string> = null
  secretKey: Maybe<string> = null

  constructor(data: Partial<TenableCloudApiToken>) {
    super()
    Object.assign(this, data)
  }
}
