import { ContainerFlex } from '@app/components-legacy/Container'
import type { StoreInputHealthChecks } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import HealthCheckNamesTree from './HealthChecksTree'

export interface IInputHealthCheckNamesDrawerProps {
  className?: string
  withExpand?: boolean
  storeInputHealthChecks: StoreInputHealthChecks
}

function getHealthCheckNamesTreeItems(
  props: IInputHealthCheckNamesDrawerProps
): React.ReactNode {
  const { storeInputHealthChecks: storeInputHealthCheckNames } = props

  return (
    <HealthCheckNamesTree
      withExpand={props.withExpand}
      storeInputHealthCheckNames={storeInputHealthCheckNames}
    />
  )
}

const DrawerContent: React.FC<IInputHealthCheckNamesDrawerProps> = props => {
  return (
    <ContainerFlex
      name="InputHealthCheckNamesDrawerContent"
      className={props.className}
      items={getHealthCheckNamesTreeItems(props)}
      direction="column"
    />
  )
}

export default observer(DrawerContent)
