import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import InfrastructureCommonForm from '../InfrastructuresCreatePage/InfrastructureCommonForm'
import { onInfrastructuresEditSubmit } from './handlers'

interface IInfrastructureEditFormProps {
  infrastructureId: number
}

const InfrastructureEditForm: React.FC<
  IInfrastructureEditFormProps
> = props => {
  const appRouter = useAppRouter()

  const { storeManagementInfrastructures, storeBlades } = useStores()

  return (
    <InfrastructureCommonForm
      storeForm={storeManagementInfrastructures.storeFormEdition}
      version="edition"
      onSubmit={onInfrastructuresEditSubmit(
        appRouter,
        storeManagementInfrastructures
      )(props.infrastructureId)}
    />
  )
}

export default InfrastructureEditForm
