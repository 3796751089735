import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  Drawer,
  DrawerHorizontalContainer,
  DrawerContext,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreDrawer from '@app/stores/helpers/StoreDrawer'
import { grant } from '@libs/rbac/permissions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerAttackDetailsContent from './DrawerAttackDetailsContent'

export interface IDrawerAttackDetailsProps {
  storeDrawer: StoreDrawer<{ attackVectorDescription: string }>
}

const IoADrawerAttackDetails: React.FC<IDrawerAttackDetailsProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['IoA.Attacks']
  })

  return (
    <DrawerContext.Provider value={{ storeDrawer: props.storeDrawer }}>
      <Drawer>
        <DrawerHorizontalContainer variant="large">
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: <DrawerTitle>{translate('Incident details')}</DrawerTitle>,
              content: <DrawerAttackDetailsContent />,
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={grant()}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(IoADrawerAttackDetails)
