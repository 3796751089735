import { AppRouteName } from '@app/routes'
import type StoreAttacks from '@app/stores/IoA/StoreAttacks'

/**
 * Set the closed filter and reload attacks.
 */
export const onAttacksFilterCloseChange =
  (storeAttacks: StoreAttacks) => (isChecked: boolean) => {
    storeAttacks.setFilterIsClosed(isChecked)
    onAttacksSearchSubmit(storeAttacks)()
  }

/**
 * Update the url on search submit and fetch attacks.
 */
export const onAttacksSearchSubmit =
  (storeAttacks: StoreAttacks) => (): void => {
    const appRouter = storeAttacks.storeRoot.appRouter

    const { boardFilters, attacksFilters } =
      storeAttacks.storeIoA.computeAttacksAllQueryParameters()

    const url = appRouter.makeRouteInfosPathname(
      {
        routeName: AppRouteName.IoA_Attacks,
        parameters: {},
        queryStringParameters: {
          boardFilters,
          attacksFilters
        }
      },
      { arrayFormat: 'indices' }
    )

    appRouter.history.replace(url)

    storeAttacks.reloadAttacks()
  }

/**
 * When clicking on the start button, it pauses the live detection.
 */
export const onAttacksPauseClick = (storeAttacks: StoreAttacks) => () => {
  storeAttacks.pauseLiveAttacks()
}

/**
 * When clicking on the pause button, it restarts the live detection.
 */
export const onAttacksRestartClick = (storeAttacks: StoreAttacks) => () => {
  storeAttacks.restartLiveAttacks()

  // refetch attacks to update the list with the latest attacks

  // const args: RbacAttacksQueryArgs = {
  //   profileId,
  //   dateStart: new Date().toISOString(),
  //   attacksPerPage: ATTACKS_LIMIT,
  //   // TODO
  //   attacksPage: 1,
  //   orderBy: OrderBy.Desc
  // }

  // storeAttacks.fetchAttacks(args)
}
