import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperSwitch } from '@app/components-legacy/Form/Wrappers'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreAttackPath } from '@app/stores'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { handleShowAllTooltipsOnChange } from '../handlers'

interface IShowAllNodeTooltipSwitchProps {
  storeAttackPath: StoreAttackPath
  className?: string
}

const StyledLabel = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: ${consts.FontColorV2.secondary};
`

const ShowAllNodeTooltipSwitch: React.FC<
  IShowAllNodeTooltipSwitchProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.Chart']
  })

  return (
    <ContainerFlex
      name={ShowAllNodeTooltipSwitch.name}
      className={props.className}
      alignItems="center"
      justifyContent="space-between"
      items={[
        <StyledLabel>{translate('Show all node tooltips')}</StyledLabel>,

        <FormWrapperSwitch
          labelledBy="showAllNodeTooltips"
          switchProps={{
            checked: props.storeAttackPath.isShowingAllTooltips,
            onChange: handleShowAllTooltipsOnChange(props.storeAttackPath)
          }}
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(ShowAllNodeTooltipSwitch)
