import type { Maybe } from '@@types/helpers'

export enum ACETitle {
  ACE = 'ACE',
  ACEs = 'ACEs',
  Type = 'Type',
  Flags = 'Flags',
  AccountSid = 'AccountSid',
  Rights = 'Rights',
  ObjectGUID = 'ObjectGUID',
  InheritObjectGUID = 'InheritObjectGUID',
  ResourceAttribute = 'ResourceAttribute',
  Descriptor = 'Descriptor',
  SDDL = 'SDDL',
  Owner = 'Owner',
  Group = 'Group',
  DACL = 'DACL',
  SACL = 'SACL'
}

export enum AttributeWithSDDL {
  defaultsecuritydescriptor = 'defaultSecurityDescriptor',
  nTSecurityDescriptor = 'nTSecurityDescriptor',
  msDS_AllowedToActOnBehalfOfOtherIdentity = 'msDS-AllowedToActOnBehalfOfOtherIdentity',
  msDS_GroupMSAMembership = 'msDS-GroupMSAMembership',
  controlAccessRights = 'controlAccessRights',
  dacl_sddl = 'DACL-Sddl'
}

export enum SddlAceTypeEnum {
  A = 'A',
  D = 'D',
  OA = 'OA',
  OD = 'OD',
  AU = 'AU',
  AL = 'AL',
  OU = 'OU',
  OL = 'OL',
  ML = 'ML',
  XA = 'XA',
  XD = 'XD',
  RA = 'RA',
  SP = 'SP',
  XU = 'XU',
  ZA = 'ZA'
}

export enum SddlAceFlagsEnum {
  CI = 'CI',
  OI = 'OI',
  NP = 'NP',
  IO = 'IO',
  ID = 'ID',
  SA = 'SA',
  FA = 'FA'
}

export enum SddlAceRightsEnum {
  // Generic access rights
  GA = 'GA',
  GR = 'GR',
  GW = 'GW',
  GX = 'GX',

  // Standard access rights
  RC = 'RC',
  SD = 'SD',
  WD = 'WD',
  WO = 'WO',

  // Directory service object access rights
  RP = 'RP',
  WP = 'WP',
  CC = 'CC',
  DC = 'DC',
  LC = 'LC',
  SW = 'SW',
  LO = 'LO',
  DT = 'DT',
  CR = 'CR',

  // File access rights
  FA = 'FA',
  FR = 'FR',
  FW = 'FW',
  FX = 'FX',

  // Registry key access rights
  KA = 'KA',
  KR = 'KR',
  KW = 'KW',
  KX = 'KX',

  // Mandatory label rights
  NR = 'NR',
  NW = 'NW',
  NX = 'NX'
}

export enum SddlDaclFlagsEnum {
  P = 'P',
  AR = 'AR',
  AI = 'AI',
  NO_ACCESS_CONTROL = 'NO_ACCESS_CONTROL'
}

export enum SddlSidsEnum {
  AA = 'AA',
  AC = 'AC',
  AN = 'AN',
  AO = 'AO',
  AP = 'AP',
  AS = 'AS',
  AU = 'AU',
  BA = 'BA',
  BG = 'BG',
  BO = 'BO',
  BU = 'BU',
  CA = 'CA',
  CD = 'CD',
  CG = 'CG',
  CN = 'CN',
  CO = 'CO',
  CY = 'CY',
  DA = 'DA',
  DC = 'DC',
  DD = 'DD',
  DG = 'DG',
  DU = 'DU',
  EA = 'EA',
  ED = 'ED',
  EK = 'EK',
  ER = 'ER',
  ES = 'ES',
  HA = 'HA',
  HI = 'HI',
  IS = 'IS',
  IU = 'IU',
  KA = 'KA',
  LA = 'LA',
  LG = 'LG',
  LS = 'LS',
  LU = 'LU',
  LW = 'LW',
  ME = 'ME',
  MP = 'MP',
  MS = 'MS',
  MU = 'MU',
  NO = 'NO',
  NS = 'NS',
  NU = 'NU',
  OW = 'OW',
  PA = 'PA',
  PO = 'PO',
  PS = 'PS',
  PU = 'PU',
  RA = 'RA',
  RC = 'RC',
  RD = 'RD',
  RE = 'RE',
  RM = 'RM',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  SA = 'SA',
  SI = 'SI',
  SO = 'SO',
  SS = 'SS',
  SU = 'SU',
  SY = 'SY',
  UD = 'UD',
  WD = 'WD',
  WR = 'WR'
}

export enum WellKnownSidsEnum {
  'S-1-0-0' = 'S-1-0-0',
  'S-1-0' = 'S-1-0',
  'S-1-1-0' = 'S-1-1-0',
  'S-1-1' = 'S-1-1',
  'S-1-2-0' = 'S-1-2-0',
  'S-1-2-1' = 'S-1-2-1',
  'S-1-2' = 'S-1-2',
  'S-1-3-0' = 'S-1-3-0',
  'S-1-3-1' = 'S-1-3-1',
  'S-1-3-2' = 'S-1-3-2',
  'S-1-3-3' = 'S-1-3-3',
  'S-1-3-4' = 'S-1-3-4',
  'S-1-3' = 'S-1-3',
  'S-1-4' = 'S-1-4',
  'S-1-5-1' = 'S-1-5-1',
  'S-1-5-2' = 'S-1-5-2',
  'S-1-5-3' = 'S-1-5-3',
  'S-1-5-4' = 'S-1-5-4',
  'S-1-5-6' = 'S-1-5-6',
  'S-1-5-7' = 'S-1-5-7',
  'S-1-5-8' = 'S-1-5-8',
  'S-1-5-9' = 'S-1-5-9',
  'S-1-5-10' = 'S-1-5-10',
  'S-1-5-11' = 'S-1-5-11',
  'S-1-5-12' = 'S-1-5-12',
  'S-1-5-13' = 'S-1-5-13',
  'S-1-5-14' = 'S-1-5-14',
  'S-1-5-15' = 'S-1-5-15',
  'S-1-5-17' = 'S-1-5-17',
  'S-1-5-18' = 'S-1-5-18',
  'S-1-5-19' = 'S-1-5-19',
  'S-1-5-20' = 'S-1-5-20',
  'S-1-5-32-544' = 'S-1-5-32-544',
  'S-1-5-32-545' = 'S-1-5-32-545',
  'S-1-5-32-546' = 'S-1-5-32-546',
  'S-1-5-32-547' = 'S-1-5-32-547',
  'S-1-5-32-548' = 'S-1-5-32-548',
  'S-1-5-32-549' = 'S-1-5-32-549',
  'S-1-5-32-550' = 'S-1-5-32-550',
  'S-1-5-32-551' = 'S-1-5-32-551',
  'S-1-5-32-552' = 'S-1-5-32-552',
  'S-1-5-32-553' = 'S-1-5-32-553',
  'S-1-5-32-554' = 'S-1-5-32-554',
  'S-1-5-32-555' = 'S-1-5-32-555',
  'S-1-5-32-556' = 'S-1-5-32-556',
  'S-1-5-32-557' = 'S-1-5-32-557',
  'S-1-5-32-558' = 'S-1-5-32-558',
  'S-1-5-32-559' = 'S-1-5-32-559',
  'S-1-5-32-560' = 'S-1-5-32-560',
  'S-1-5-32-561' = 'S-1-5-32-561',
  'S-1-5-32-562' = 'S-1-5-32-562',
  'S-1-5-32-568' = 'S-1-5-32-568',
  'S-1-5-32-569' = 'S-1-5-32-569',
  'S-1-5-32-571' = 'S-1-5-32-571',
  'S-1-5-32-572' = 'S-1-5-32-572',
  'S-1-5-32-573' = 'S-1-5-32-573',
  'S-1-5-32-574' = 'S-1-5-32-574',
  'S-1-5-32-575' = 'S-1-5-32-575',
  'S-1-5-32-576' = 'S-1-5-32-576',
  'S-1-5-32-577' = 'S-1-5-32-577',
  'S-1-5-32-578' = 'S-1-5-32-578',
  'S-1-5-32-579' = 'S-1-5-32-579',
  'S-1-5-32-580' = 'S-1-5-32-580',
  'S-1-5-32-581' = 'S-1-5-32-581',
  'S-1-5-32-582' = 'S-1-5-32-582',
  'S-1-5-32-583' = 'S-1-5-32-583',
  'S-1-5-33' = 'S-1-5-33',
  'S-1-5-64-10' = 'S-1-5-64-10',
  'S-1-5-64-14' = 'S-1-5-64-14',
  'S-1-5-64-21' = 'S-1-5-64-21',
  'S-1-5-80-0' = 'S-1-5-80-0',
  'S-1-5-80-956008885-3418522649-1831038044-1853292631-2271478464' = 'S-1-5-80-956008885-3418522649-1831038044-1853292631-2271478464',
  'S-1-5-83-0' = 'S-1-5-83-0',
  'S-1-5-84-0-0-0-0-0' = 'S-1-5-84-0-0-0-0-0',
  'S-1-5-113' = 'S-1-5-113',
  'S-1-5-114' = 'S-1-5-114',
  'S-1-5-1000' = 'S-1-5-1000',
  'S-1-5' = 'S-1-5',
  'S-1-15-2-1' = 'S-1-15-2-1',
  'S-1-16-0' = 'S-1-16-0',
  'S-1-16-4096' = 'S-1-16-4096',
  'S-1-16-8192' = 'S-1-16-8192',
  'S-1-16-8448' = 'S-1-16-8448',
  'S-1-16-12288' = 'S-1-16-12288',
  'S-1-16-16384' = 'S-1-16-16384',
  'S-1-16-20480' = 'S-1-16-20480',
  'S-1-16-28672' = 'S-1-16-28672',
  'S-1-18-1' = 'S-1-18-1',
  'S-1-18-2' = 'S-1-18-2',
  'S-1-18-3' = 'S-1-18-3',
  'S-1-18-4' = 'S-1-18-4',
  'S-1-18-5' = 'S-1-18-5',
  'S-1-18-6' = 'S-1-18-6',

  // matchs
  'S-1-5-21-.*-498' = 'S-1-5-21-.*-498',
  'S-1-5-21-.*-500' = 'S-1-5-21-.*-500',
  'S-1-5-21-.*-501' = 'S-1-5-21-.*-501',
  'S-1-5-21-.*-502' = 'S-1-5-21-.*-502',
  'S-1-5-21-.*-512' = 'S-1-5-21-.*-512',
  'S-1-5-21-.*-513' = 'S-1-5-21-.*-513',
  'S-1-5-21-.*-514' = 'S-1-5-21-.*-514',
  'S-1-5-21-.*-515' = 'S-1-5-21-.*-515',
  'S-1-5-21-.*-516' = 'S-1-5-21-.*-516',
  'S-1-5-21-.*-517' = 'S-1-5-21-.*-517',
  'S-1-5-21-.*-518' = 'S-1-5-21-.*-518',
  'S-1-5-21-.*-519' = 'S-1-5-21-.*-519',
  'S-1-5-21-.*-520' = 'S-1-5-21-.*-520',
  'S-1-5-21-.*-521' = 'S-1-5-21-.*-521',
  'S-1-5-21-.*-522' = 'S-1-5-21-.*-522',
  'S-1-5-21-.*-525' = 'S-1-5-21-.*-525',
  'S-1-5-21-.*-526' = 'S-1-5-21-.*-526',
  'S-1-5-21-.*-527' = 'S-1-5-21-.*-527',
  'S-1-5-21-.*-553' = 'S-1-5-21-.*-553',
  'S-1-5-21-.*-571' = 'S-1-5-21-.*-571',
  'S-1-5-21-.*-572' = 'S-1-5-21-.*-572'
}

export enum WellKnownGuidsEnum {
  '00000000-0000-0000-0000-000000000000' = '00000000-0000-0000-0000-000000000000',
  'f30e3bbe-9ff0-11d1-b603-0000f80367c1' = 'f30e3bbe-9ff0-11d1-b603-0000f80367c1',
  'f30e3bc1-9ff0-11d1-b603-0000f80367c1' = 'f30e3bc1-9ff0-11d1-b603-0000f80367c1',
  'bf9679c0-0de6-11d0-a285-00aa003049e2' = 'bf9679c0-0de6-11d0-a285-00aa003049e2',
  'bc0ac240-79a9-11d0-9020-00c04fc2d4cf' = 'bc0ac240-79a9-11d0-9020-00c04fc2d4cf',
  'f3a64788-5306-11d1-a9c5-0000f80367c1' = 'f3a64788-5306-11d1-a9c5-0000f80367c1',
  '00fbf30c-91fe-11d1-aebc-0000f80367c1' = '00fbf30c-91fe-11d1-aebc-0000f80367c1',
  'e48d0154-bcf8-11d1-8702-00c04fb96050' = 'e48d0154-bcf8-11d1-8702-00c04fb96050',
  '5b47d60f-6090-40b2-9f37-2a4de88f3063' = '5b47d60f-6090-40b2-9f37-2a4de88f3063',
  '9b026da6-0d3c-465c-8bee-5199d7165cba' = '9b026da6-0d3c-465c-8bee-5199d7165cba',
  '72e39547-7b18-11d1-adef-00c04fd8d5cd' = '72e39547-7b18-11d1-adef-00c04fd8d5cd',
  '80863791-dbe9-4eb8-837e-7f0ab55d9ac7' = '80863791-dbe9-4eb8-837e-7f0ab55d9ac7',
  'd31a8757-2447-4545-8081-3bb610cacbf2' = 'd31a8757-2447-4545-8081-3bb610cacbf2',
  '3f78c3e5-f79a-46bd-a0b8-9d18116ddc79' = '3f78c3e5-f79a-46bd-a0b8-9d18116ddc79'
}

export enum WellKnownExtendedGuidsEnum {
  '00000000-0000-0000-0000-000000000000' = '00000000-0000-0000-0000-000000000000',
  'ee914b82-0a98-11d1-adbb-00c04fd8d5cd' = 'ee914b82-0a98-11d1-adbb-00c04fd8d5cd',
  '440820ad-65b4-11d1-a3da-0000f875ae0d' = '440820ad-65b4-11d1-a3da-0000f875ae0d',
  '1abd7cf8-0a99-11d1-adbb-00c04fd8d5cd' = '1abd7cf8-0a99-11d1-adbb-00c04fd8d5cd',
  '68b1d179-0d15-4d4f-ab71-46152e79a7bc' = '68b1d179-0d15-4d4f-ab71-46152e79a7bc',
  'edacfd8f-ffb3-11d1-b41d-00a0c968f939' = 'edacfd8f-ffb3-11d1-b41d-00a0c968f939',
  '0e10c968-78fb-11d2-90d4-00c04f79dc55' = '0e10c968-78fb-11d2-90d4-00c04f79dc55',
  'a05b8cc2-17bc-4802-a710-e7c15ab866a2' = 'a05b8cc2-17bc-4802-a710-e7c15ab866a2',
  '014bf69c-7b3b-11d1-85f6-08002be74fab' = '014bf69c-7b3b-11d1-85f6-08002be74fab',
  'cc17b1fb-33d9-11d2-97d4-00c04fd8d5cd' = 'cc17b1fb-33d9-11d2-97d4-00c04fd8d5cd',
  'bae50096-4752-11d1-9052-00c04fc2d4cf' = 'bae50096-4752-11d1-9052-00c04fc2d4cf',
  'd58d5f36-0a98-11d1-adbb-00c04fd8d5cd' = 'd58d5f36-0a98-11d1-adbb-00c04fd8d5cd',
  'e12b56b6-0a95-11d1-adbb-00c04fd8d5cd' = 'e12b56b6-0a95-11d1-adbb-00c04fd8d5cd',
  'e2a36dc9-ae17-47c3-b58b-be34c55ba633' = 'e2a36dc9-ae17-47c3-b58b-be34c55ba633',
  'fec364e0-0a98-11d1-adbb-00c04fd8d5cd' = 'fec364e0-0a98-11d1-adbb-00c04fd8d5cd',
  'ab721a52-1e2f-11d0-9819-00aa0040529b' = 'ab721a52-1e2f-11d0-9819-00aa0040529b',
  '69ae6200-7f46-11d2-b9ad-00c04f79f805' = '69ae6200-7f46-11d2-b9ad-00c04f79f805',
  '2f16c4a5-b98e-432c-952a-cb388ba33f2e' = '2f16c4a5-b98e-432c-952a-cb388ba33f2e',
  '9923a32a-3607-11d2-b9be-0000f87a36b2' = '9923a32a-3607-11d2-b9be-0000f87a36b2',
  '4ecc03fe-ffc0-4947-b630-eb672a8a9dbc' = '4ecc03fe-ffc0-4947-b630-eb672a8a9dbc',
  '1131f6aa-9c07-11d1-f79f-00c04fc2dcd2' = '1131f6aa-9c07-11d1-f79f-00c04fc2dcd2',
  '1131f6ad-9c07-11d1-f79f-00c04fc2dcd2' = '1131f6ad-9c07-11d1-f79f-00c04fc2dcd2',
  '89e95b76-444d-4c62-991a-0facbeda640c' = '89e95b76-444d-4c62-991a-0facbeda640c',
  '1131f6ac-9c07-11d1-f79f-00c04fc2dcd2' = '1131f6ac-9c07-11d1-f79f-00c04fc2dcd2',
  'f98340fb-7c5b-4cdb-a00b-2ebdfa115a96' = 'f98340fb-7c5b-4cdb-a00b-2ebdfa115a96',
  '1131f6ab-9c07-11d1-f79f-00c04fc2dcd2' = '1131f6ab-9c07-11d1-f79f-00c04fc2dcd2',
  '05c74c5e-4deb-43b4-bd9f-86664c2a7fd5' = '05c74c5e-4deb-43b4-bd9f-86664c2a7fd5',
  'b7b1b3de-ab09-4242-9e30-9980e5d322f7' = 'b7b1b3de-ab09-4242-9e30-9980e5d322f7',
  'b7b1b3dd-ab09-4242-9e30-9980e5d322f7' = 'b7b1b3dd-ab09-4242-9e30-9980e5d322f7',
  '7c0e2a7c-a419-48e4-a995-10180aad54dd' = '7c0e2a7c-a419-48e4-a995-10180aad54dd',
  'ba33815a-4f93-4c76-87f3-57574bff8109' = 'ba33815a-4f93-4c76-87f3-57574bff8109',
  'b4e60130-df3f-11d1-9c86-006008764d0e' = 'b4e60130-df3f-11d1-9c86-006008764d0e',
  '06bd3201-df3e-11d1-9c86-006008764d0e' = '06bd3201-df3e-11d1-9c86-006008764d0e',
  '4b6e08c3-df3c-11d1-9c86-006008764d0e' = '4b6e08c3-df3c-11d1-9c86-006008764d0e',
  '4b6e08c1-df3c-11d1-9c86-006008764d0e' = '4b6e08c1-df3c-11d1-9c86-006008764d0e',
  '06bd3200-df3e-11d1-9c86-006008764d0e' = '06bd3200-df3e-11d1-9c86-006008764d0e',
  '4b6e08c2-df3c-11d1-9c86-006008764d0e' = '4b6e08c2-df3c-11d1-9c86-006008764d0e',
  '4b6e08c0-df3c-11d1-9c86-006008764d0e' = '4b6e08c0-df3c-11d1-9c86-006008764d0e',
  '06bd3203-df3e-11d1-9c86-006008764d0e' = '06bd3203-df3e-11d1-9c86-006008764d0e',
  '06bd3202-df3e-11d1-9c86-006008764d0e' = '06bd3202-df3e-11d1-9c86-006008764d0e',
  'a1990816-4298-11d1-ade2-00c04fd8d5cd' = 'a1990816-4298-11d1-ade2-00c04fd8d5cd',
  '1131f6ae-9c07-11d1-f79f-00c04fc2dcd2' = '1131f6ae-9c07-11d1-f79f-00c04fc2dcd2',
  '45ec5156-db7e-47bb-b53f-dbeb2d03c40f' = '45ec5156-db7e-47bb-b53f-dbeb2d03c40f',
  '0bc1554e-0a99-11d1-adbb-00c04fd8d5cd' = '0bc1554e-0a99-11d1-adbb-00c04fd8d5cd',
  '62dd28a8-7f46-11d2-b9ad-00c04f79f805' = '62dd28a8-7f46-11d2-b9ad-00c04f79f805',
  'ab721a56-1e2f-11d0-9819-00aa0040529b' = 'ab721a56-1e2f-11d0-9819-00aa0040529b',
  '9432c620-033c-4db7-8b58-14ef6d0bf477' = '9432c620-033c-4db7-8b58-14ef6d0bf477',
  '1a60ea8d-58a6-4b20-bcdc-fb71eb8a9ff8' = '1a60ea8d-58a6-4b20-bcdc-fb71eb8a9ff8',
  '7726b9d5-a4b4-4288-a6b2-dce952e80a7f' = '7726b9d5-a4b4-4288-a6b2-dce952e80a7f',
  '91d67418-0135-4acc-8d79-c08e857cfbec' = '91d67418-0135-4acc-8d79-c08e857cfbec',
  'ab721a54-1e2f-11d0-9819-00aa0040529b' = 'ab721a54-1e2f-11d0-9819-00aa0040529b',
  'ab721a55-1e2f-11d0-9819-00aa0040529b' = 'ab721a55-1e2f-11d0-9819-00aa0040529b',
  'ccc2dc7d-a6ad-4a7a-8846-c04e3cc53501' = 'ccc2dc7d-a6ad-4a7a-8846-c04e3cc53501',
  '280f369c-67c7-438e-ae98-1d46f3c6f541' = '280f369c-67c7-438e-ae98-1d46f3c6f541',
  'be2bb760-7f46-11d2-b9ad-00c04f79f805' = 'be2bb760-7f46-11d2-b9ad-00c04f79f805',
  'ab721a53-1e2f-11d0-9819-00aa0040529b' = 'ab721a53-1e2f-11d0-9819-00aa0040529b',
  '00299570-246d-11d0-a768-00aa006e0529' = '00299570-246d-11d0-a768-00aa006e0529',
  '3e0f7e18-2c7a-4c10-ba82-4d926db99a3e' = '3e0f7e18-2c7a-4c10-ba82-4d926db99a3e',
  '084c93a2-620d-4879-a836-f0ae47de0e89' = '084c93a2-620d-4879-a836-f0ae47de0e89',
  '94825a8d-b171-4116-8146-1e34d8f54401' = '94825a8d-b171-4116-8146-1e34d8f54401',
  '4125c71f-7fac-4ff0-bcb7-f09a41325286' = '4125c71f-7fac-4ff0-bcb7-f09a41325286',
  '88a9933e-e5c8-4f2a-9dd7-2527416b8092' = '88a9933e-e5c8-4f2a-9dd7-2527416b8092',
  '9b026da6-0d3c-465c-8bee-5199d7165cba' = '9b026da6-0d3c-465c-8bee-5199d7165cba'
}

export type SddlAceTypes = Map<SddlAceTypeEnum, string>
export type SddlAceFlags = Map<SddlAceFlagsEnum, string>
export type SddlAceRights = Map<SddlAceRightsEnum, string>
export type SddlAceIntRights = Array<[number, string]>
export type SddlDaclFlags = Map<SddlDaclFlagsEnum, string>
export type SddlSids = Map<SddlSidsEnum, string>
export type WellKnownSids = Map<WellKnownSidsEnum, string>
export type WellKnownGuids = Map<WellKnownGuidsEnum, string>
export type WellKnownExtendedGuids = Map<WellKnownExtendedGuidsEnum, string>
export type PatternsMap = Map<RegExp, string>

export type SDDL = string
export type Detail = Maybe<ISddlTree | string>

export interface ISddlTree {
  value: string
  title: Maybe<string>
  details: Detail[]
}
