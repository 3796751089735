import type { Maybe } from '@@types/helpers'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import * as React from 'react'
import { ErrorMessage } from './index'

interface IErrorRbacDenyMessageProps {
  message?: Maybe<string>
}

const ErrorRbacDenyMessage: React.FC<IErrorRbacDenyMessageProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Rbac'] })

  const message = translate(
    props.message || 'You do not have access to this page'
  )

  return <ErrorMessage labelledBy="deny-error" message={message} />
}

export default ErrorRbacDenyMessage
