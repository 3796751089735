import { ButtonCopyToClipboard } from '@app/components-legacy/Button'
import Code from '@app/components-legacy/Code'
import { ContainerContent } from '@app/components-legacy/Container'
import type EntityAttack from '@app/entities/EntityAttack'
import * as React from 'react'
import styled from 'styled-components'

const lineHeight = 20.43
const linesNumber = 12
const paddingAndBorderHeight = 12
const height = Math.round(lineHeight * linesNumber + paddingAndBorderHeight)

const StyledCode = styled(Code)`
  max-height: ${height}px;
  flex: 100%;
`

interface IIoAAttacksDescriptionTabYaraRulesProps {
  className?: string
  style?: React.CSSProperties
  attackEntity: EntityAttack
}

const IoAAttacksYaraRulesTab: React.FC<
  IIoAAttacksDescriptionTabYaraRulesProps
> = props => {
  const attackTypeYaraRules = props.attackEntity.getPropertyAsString(
    'attackTypeYaraRules'
  )

  return (
    <ContainerContent
      name="IoAAttacksYaraRulesTab"
      className={props.className}
      style={props.style}
    >
      <StyledCode className={props.className}>{attackTypeYaraRules}</StyledCode>

      <ButtonCopyToClipboard
        textToCopy={attackTypeYaraRules}
        position={{
          top: 5,
          right: 20
        }}
      />
    </ContainerContent>
  )
}

export default styled(IoAAttacksYaraRulesTab)`
  position: relative;
`
