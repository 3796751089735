import type { CreateDashboardMutationArgs } from '@server/graphql/typeDefs/types'

export enum DashboardTemplate {
  customDashboard = 'customDashboard',
  adRisk360 = 'adRisk360',
  nativeAdminMonitoring = 'nativeAdminMonitoring',
  adComplianceAndTopRisks = 'adComplianceAndTopRisks',
  passwordManagementRisk = 'passwordManagementRisk',
  userMonitoring = 'userMonitoring'
}

export interface IDashboardTemplateCreation {
  args: CreateDashboardMutationArgs
  template: DashboardTemplate
}
