import type { StoreTrailFlow } from '@app/stores'
import type StoreSearchBookmarks from '@app/stores/TrailFlow/StoreSearchBookmarks'
import type { SelectValue } from 'antd/lib/select'

/**
 * When clicking on an expression or name from the bookmarks drawer, select this expression.
 */
export const onTrailFlowSearchBookmarksSelection =
  (storeTrailFlow: StoreTrailFlow) => (value: string) => () => {
    storeTrailFlow.storeInputExpression
      .clearEntry()
      .setEntry(value)
      .validateEntry()

    storeTrailFlow.storeSearchBookmarks.storeSearchBookmarksDrawer.closeDrawer()
    storeTrailFlow.reloadData()
  }

export const onCategorySelectorChange =
  (storeSearchBookmarks: StoreSearchBookmarks) =>
  (selectValue: SelectValue) => {
    storeSearchBookmarks.setCategorySelectorValue(String(selectValue))
  }

/**
 * Reset storeSearchBookmarks when leaving the drawer.
 */
export const onTrailFlowSearchBookmarksUnload =
  (storeSearchBookmarks: StoreSearchBookmarks) => () => {
    storeSearchBookmarks.reset()
  }

/**
 * Delete search bookmark category.
 */
export const onDeleteSearchBookmarkCategory =
  (storeSearchBookmarks: StoreSearchBookmarks) => (category: string) => () => {
    storeSearchBookmarks.deleteSearchBookmarksCategory(category)
  }
