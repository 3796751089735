import { useAppTranslator } from '@app/hooks'
import { isDefined } from '@libs/isDefined'
import type { HealthCheckStatus, Maybe } from '@server/graphql/typeDefs/types'
import { Tooltip } from 'antd'
import * as React from 'react'

export interface IHealthCheckStatusTooltipProps {
  style?: React.CSSProperties
  status: Maybe<HealthCheckStatus>
  children: React.ReactNode
}

export function HealthCheckStatusTooltip(
  props: IHealthCheckStatusTooltipProps
) {
  const translate = useAppTranslator({
    namespaces: ['HealthCheck.StatusMessages']
  })

  if (!isDefined(props.status)) {
    return <>{props.children}</>
  }

  return (
    <Tooltip style={props.style} title={translate(props.status)}>
      {props.children}
    </Tooltip>
  )
}
