import type { Maybe } from '@@types/helpers'
import type { EntityHealthCheckAttribute } from '@app/entities'
import type { ITranslateOptionsInterpolations } from '@libs/i18n'
import { isDefined } from '@libs/isDefined'
import { ensureArray } from '@productive-codebases/toolbox'

export function formatHealthCheckAttributes(
  attributes?: Maybe<EntityHealthCheckAttribute[]>
): ITranslateOptionsInterpolations {
  const interpolations: ITranslateOptionsInterpolations = {}

  ensureArray(attributes).forEach(attribute => {
    if (!attribute.name || !isDefined(attribute.value)) {
      return
    }

    if (attribute.value === '' || attribute.name.startsWith('DATA_')) {
      interpolations[attribute.name] = attribute.value
      return
    }

    interpolations[attribute.name] = `**${attribute.value}**`
  })

  return interpolations
}
