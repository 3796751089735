import type { Maybe } from '@@types/helpers'
import type { TContainerFlexAlignItems } from '@app/components-legacy/Container/ContainerFlex/types'
import { ensureArray } from '@libs/ensureArray'
import type { TranslateFn } from '@libs/i18n'
import { isDefined } from '@libs/isDefined'
import { action, computed, makeObservable, observable } from 'mobx'
import type {
  FieldError,
  FieldName,
  FieldSuccess,
  FieldValue,
  IFieldMeta,
  IFieldSanitizor,
  IFieldValidator
} from './types'
import { InputType } from './types'

export default class Field {
  public name: FieldName
  public translate: TranslateFn

  /**
   * Handle meta-data.
   * Useful to attach various data to the field in addition to its value.
   */
  meta = {
    /**
     * Override meta data.
     */
    override: (allMeta: Map<string, any>): this => {
      this.$meta = allMeta
      return this
    },

    set: <T>(meta: IFieldMeta<T>): this => {
      this.$meta.set(meta.key, meta.value)
      return this
    },

    /**
     * Return a meta data.
     */
    get: <T>(key: string): Maybe<T> => {
      return this.$meta.get(key)
    },

    /**
     * Delete a meta data.
     */
    delete: (key: string): this => {
      this.$meta.delete(key)
      return this
    },

    /**
     * Return all meta data.
     */
    getAll: (): Map<string, any> => {
      return this.$meta
    },

    /**
     * Copy meta of the field to an another field.
     */
    copyToField: (field: Field): this => {
      field.meta.override(this.$meta)
      return this
    }
  }

  // value is always a string (a stringified value in case of an object/array)
  private $defaultValue: Maybe<FieldValue> = null

  private $label: Maybe<string> = null
  private $labelAlignItem: Maybe<TContainerFlexAlignItems> = null
  private $description: Maybe<string> = null
  private $inputType: InputType = InputType.input
  private $validators: Map<string, IFieldValidator> = new Map()
  private $sanitizors: Map<string, IFieldSanitizor> = new Map()
  private $meta: Map<string, any> = new Map()

  /* Observable */

  private $value = observable.box<Maybe<FieldValue>>(null)
  private $errors = observable.set<FieldError>()
  private $successes = observable.set<FieldSuccess>()

  constructor(
    readonly fieldName: FieldName,
    readonly translateFn: TranslateFn
  ) {
    this.name = fieldName
    this.translate = translateFn

    makeObservable(this)
  }

  /**
   * Add a label.
   */
  addLabel(label: string): this {
    this.$label = label
    return this
  }

  /**
   * Add a labelAlignItem.
   */
  addLabelAlignItem(labelAlignItem: TContainerFlexAlignItems): this {
    this.$labelAlignItem = labelAlignItem
    return this
  }

  /**
   * Add a description.
   */
  addDescription(description: string): this {
    this.$description = description
    return this
  }

  /**
   * Add an input type.
   */
  addInputType(inputType: InputType): this {
    this.$inputType = inputType
    return this
  }

  /**
   * Add a validator.
   */
  addValidator(validator: IFieldValidator): this {
    this.$validators.set(validator.name, validator)
    return this
  }

  /**
   * Remove a validator.
   */
  removeValidator(name: string): this {
    this.$validators.delete(name)
    return this
  }

  /**
   * Add a sanitizor.
   */
  addSanitizor(sanitizor: IFieldSanitizor): this {
    this.$sanitizors.set(sanitizor.name, sanitizor)
    return this
  }

  /**
   * Remove a sanitizor.
   */
  removeSanitizor(sanitizor: IFieldSanitizor): this {
    this.$sanitizors.delete(sanitizor.name)
    return this
  }

  /**
   * Return true if the field has a defined validator.
   */
  is(validatorName: string): boolean {
    return (
      Array.from(this.$validators.entries())
        .map(([, validator]) => validator.name)
        .indexOf(validatorName) > -1
    )
  }

  /**
   * Return true if the field has been modified.
   */
  isDirty(): boolean {
    return this.$value.get() !== this.$defaultValue
  }

  /**
   * Set a default value for this field.
   */
  setDefaultValue(value: Maybe<FieldValue>): this {
    this.$defaultValue = value
    return this
  }

  /* Action */

  @action
  hardReset() {
    this.$defaultValue = null
    this.reset()
  }

  @action
  reset() {
    this.$value.set(this.$defaultValue)
    this.$errors.clear()
    this.$successes.clear()
  }

  /**
   * Set a value for this field.
   */
  @action
  setValue(value: Maybe<FieldValue>): this {
    this.$value.set(value)
    return this
  }

  /**
   * Set an error for this field.
   */
  @action
  setError(error: FieldError): this {
    this.$errors.add(String(error))
    return this
  }

  /**
   * Clear all errors for this field.
   */
  @action
  clearErrors(): this {
    this.$errors.clear()
    return this
  }

  /**
   * Sanitize the field value by overwriting the value with the sanitized value.
   */
  @action
  sanitize(): this {
    this.setValue(this.sanitizedValue)
    return this
  }

  /**
   * Validate the field value.
   * Return true if the form is valid (with no error).
   */
  @action
  validate(sanitize = true): boolean {
    this.$errors.clear()
    this.$successes.clear()

    if (sanitize) {
      this.sanitize()
    }

    this.$validators.forEach(validator => {
      const validatorHandler = validator.handler(this.translate)

      const isValid = validatorHandler.isValid(
        isDefined(this.value) ? String(this.value) : null
      )

      if (isValid && validatorHandler.onSuccess) {
        ensureArray(validatorHandler.onSuccess(this.valueAsString)).forEach(
          message => {
            this.$successes.add(message)
          }
        )
      }

      if (!isValid && validatorHandler.onError) {
        ensureArray(validatorHandler.onError(this.valueAsString)).forEach(
          message => {
            this.$errors.add(message)
          }
        )
      }
    })

    return this.$errors.size === 0
  }

  get label(): Maybe<string> {
    return this.$label
  }

  get labelAlignItem(): TContainerFlexAlignItems {
    return this.$labelAlignItem ?? 'baseline'
  }

  get description(): Maybe<string> {
    return this.$description
  }

  get inputType(): InputType {
    return this.$inputType
  }

  /* Computed */

  @computed
  get required(): boolean {
    return this.$validators.has('mandatory')
  }

  @computed
  get hasValue(): boolean {
    return isDefined(this.value) && this.value !== ''
  }

  /**
   * Return the value.
   */
  @computed
  get value(): Maybe<FieldValue> {
    return this.$value.get()
  }

  /**
   * Return the value as a string.
   */
  @computed
  get valueAsString(): Maybe<string> {
    const value = this.$value.get()
    return isDefined(value) ? String(value) : null
  }

  /**
   * Return the sanitized value.
   */
  @computed
  get sanitizedValue(): Maybe<FieldValue> {
    return Array.from(this.$sanitizors.entries()).reduce(
      (acc, [, sanitizor]) => {
        if (acc === undefined) {
          return acc
        }
        return sanitizor.sanitize(acc)
      },
      this.value
    )
  }

  /**
   * Return the value as a string.
   */
  @computed
  get asString(): string {
    if (!this.hasValue) {
      return ''
    }

    return String(this.value)
  }

  /**
   * Return the value as a boolean.
   */
  @computed
  get asBoolean(): boolean {
    if (!this.hasValue) {
      return false
    }

    if (typeof this.value === 'string') {
      return this.value === 'true'
    }

    return Boolean(this.value)
  }

  /**
   * Return the value as a number.
   */
  @computed
  get asNumber(): number {
    if (!this.hasValue) {
      return 0
    }

    return Number(this.value)
  }

  /**
   * Return errors according to validation rules and the current value of the field.
   */
  @computed
  get errors(): FieldError[] {
    return Array.from(this.$errors.values())
  }

  /**
   * Return successes according to validation rules and the current value of the field.
   */
  @computed
  get successes(): FieldSuccess[] {
    return Array.from(this.$successes.values())
  }
}
