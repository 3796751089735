import type {
  GetIdentitiesRequest as GetIdentitiesRequestOpenApi,
  IdentitiesResponse
} from '@libs/openapi/service-identity-core'
import {
  IdentitiesApi,
  IdentitiesResponseFromJSON
} from '@libs/openapi/service-identity-core'
import * as runtime from '@libs/openapi/service-identity-core/runtime'
import { assertUnreachableCase } from '@productive-codebases/toolbox'

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface GetIdentitiesRequestExtended
  extends GetIdentitiesRequestOpenApi {
  directoryUuids: string[]
}

/**
 * Expand the Identities API with new parameters that will be handled in Kapteyn Server.
 */
export class IdentitiesApiExtended extends IdentitiesApi {
  /**
   * Get all identities
   */
  async getIdentitiesRaw(
    requestParameters: GetIdentitiesRequestExtended,
    initOverrides?: RequestInit
  ) {
    const queryParameters: any = {}

    const requestParametersKeys = Object.keys(requestParameters) as Array<
      keyof GetIdentitiesRequestExtended
    >

    requestParametersKeys.map(key => {
      switch (key) {
        case 'textQuery':
          if (requestParameters.textQuery !== undefined) {
            queryParameters.text_query = requestParameters.textQuery
          }
          break
        case 'offset':
          if (requestParameters.offset !== undefined) {
            queryParameters.offset = requestParameters.offset
          }
          break
        case 'limit':
          if (requestParameters.limit !== undefined) {
            queryParameters.limit = requestParameters.limit
          }
          break
        case 'directoryUuids':
          if (requestParameters.directoryUuids !== undefined) {
            queryParameters['directory_uuids[]'] =
              requestParameters.directoryUuids.map(d => d.toLowerCase())
          }
          break
        case 'tenantIds':
          if (requestParameters.tenantIds !== undefined) {
            queryParameters['tenant_ids[]'] = requestParameters.tenantIds
          }
          break
        case 'sortBy':
          if (requestParameters.sortBy !== undefined) {
            queryParameters.sort_by = requestParameters.sortBy
          }
          break
        case 'order':
          if (requestParameters.order !== undefined) {
            queryParameters.order = requestParameters.order
          }
          break
        default:
          assertUnreachableCase(key)
      }
    })

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/core/ad/identities`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      IdentitiesResponseFromJSON(jsonValue)
    )
  }

  /**
   * Get all identities
   */
  async getIdentities(
    requestParameters: GetIdentitiesRequestExtended,
    initOverrides?: RequestInit
  ): Promise<IdentitiesResponse> {
    const response = await this.getIdentitiesRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }
}
