import InputSearch from '@app/components-legacy/Input/InputSearch'
import { LabelDirectoriesTree } from '@app/components-legacy/Label'
import LabelDirectoryTag from '@app/components-legacy/Label/LabelDirectoryTag'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useMeasure } from 'react-use'
import styled from 'styled-components'
import AttackPathAutoComplete from './AttackPathAutoComplete'
import AttackPathAutoCompleteOption from './AttackPathAutoCompleteOption'
import {
  getLeftIcon,
  handleNodeFinderTargetFocus,
  handleNodeFinderTargetSelect,
  handleTargetSearchInputChange,
  handleTargetSearchInputReset
} from './handlers'
import { getInputSearchAvailableWidth } from './utils'

const DivMarginLeft = styled.div`
  display: flex;
  margin-left: 8px;
`

interface IAttackPathInputSearchTargetProps {
  className?: string
  style?: React.CSSProperties
}

const AttackPathInputSearchTarget: React.FC<
  IAttackPathInputSearchTargetProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.NodeFinder']
  })

  const { storeAttackPath, storeInfrastructures } = useStores()

  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const availableWidth = getInputSearchAvailableWidth(width)

  const directoryId = storeAttackPath.selectedSearchedTargetNode?.directoryId
  const directory =
    directoryId && storeInfrastructures.getDirectoryFromId(directoryId)

  return (
    <div className={props.className} style={props.style} ref={ref}>
      <AttackPathAutoComplete
        popupClassName="attack-path-node-finder"
        listHeight={380}
        options={storeAttackPath.searchTargetSuggestions.map(
          (attackPathNode, i) => ({
            value: String(attackPathNode.id),
            label: (
              <AttackPathAutoCompleteOption
                key={attackPathNode.id}
                labelledBy={`attackPath/targetSuggestion/${i}`}
                attackPathNode={attackPathNode}
                storeInfrastructures={storeInfrastructures}
              />
            )
          })
        )}
        onSelect={handleNodeFinderTargetSelect(storeAttackPath)}
        onFocus={handleNodeFinderTargetFocus(storeAttackPath)}
      >
        <InputSearch
          name="node-finder-target-search"
          placeholder={translate('Set a target node')}
          storeInputSearch={storeAttackPath.storeInputSearchTarget}
          onChange={handleTargetSearchInputChange(storeAttackPath)}
          onReset={handleTargetSearchInputReset(storeAttackPath)}
          leftIcon={getLeftIcon(
            storeAttackPath.selectedSearchedTargetNode,
            storeAttackPath.storeFlagsSearchTargetNodes,
            storeAttackPath.activeTab,
            storeAttackPath.direction,
            false
          )}
          suffix={
            directory &&
            directory.name && (
              <DivMarginLeft>
                <LabelDirectoryTag
                  labelledBy="sourceDirectory"
                  color={directory.color}
                  label={directory.name}
                  tooltipLabel={
                    <LabelDirectoriesTree
                      // key used to force the recreation of the component on
                      // change to prevent to add props.directoryIds
                      // dependency to the useMemo as no directoryIds prop
                      // usage is memoized
                      key={directoryId}
                      storeInfrastructures={storeInfrastructures}
                      directoryIds={[directoryId]}
                    />
                  }
                  maxWidth={availableWidth * 0.3}
                />
              </DivMarginLeft>
            )
          }
          labelledByReset="resetSearchTarget"
        />
      </AttackPathAutoComplete>
    </div>
  )
}

const observedAttackPathInputSearchTarget = observer(
  AttackPathInputSearchTarget
)

export default styled(observedAttackPathInputSearchTarget)`
  width: 100%;
  pointer-events: auto;
`
