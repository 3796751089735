import { ContainerFlex } from '@app/components-legacy/Container'
import { useDeviceViewPort } from '@app/hooks/useDeviceViewPort'
import { DeviceViewPortWidth } from '@app/hooks/useDeviceViewPort/types'
import type { IRbacCapability } from '@libs/rbac/types'
import * as React from 'react'
import styled from 'styled-components'
import type { IBladeLayoutTwoColumns } from '../types'
import BladeContentLayoutDefaultContent from './BladeContentLayoutDefaultContent'
import BladeContentLayoutTwoColumnsLeftColumn from './BladeContentLayoutTwoColumnsLeftColumn'

interface IBladeContentLayoutNavigationBodyProps {
  className?: string
  style?: React.CSSProperties
  isGranted: boolean
  isForbidden: boolean
  isError: boolean
  isLoading: boolean
  rbacCapability: IRbacCapability
  layout: IBladeLayoutTwoColumns
}

const BladeContentLayoutTwoColumnsContent: React.FC<
  IBladeContentLayoutNavigationBodyProps
> = props => {
  const { deviceViewPort } = useDeviceViewPort()

  const hideLeftColumn =
    props.layout.responsive === true &&
    deviceViewPort.isLessThan(DeviceViewPortWidth.extraLarge)

  return (
    <ContainerFlex
      name="BladeContentLayoutTwoColumnsContent"
      className={props.className}
      style={props.style}
      itemsFlexGrow={[0, 1]}
      items={[
        !hideLeftColumn && (
          <BladeContentLayoutTwoColumnsLeftColumn layout={props.layout} />
        ),

        <BladeContentLayoutDefaultContent
          isGranted={props.isGranted}
          isForbidden={props.isForbidden}
          isError={props.isError}
          isLoading={props.isLoading}
          rbacCapability={props.rbacCapability}
          layout={props.layout}
        />
      ]}
      fullHeight
    />
  )
}

export default styled(BladeContentLayoutTwoColumnsContent)`
  overflow: auto;
`
