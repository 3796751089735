import { useStores } from '@app/hooks/useStores'
import { SCENE_TOOLIP_DEBOUNCE_DELAY } from '@app/stores/Topology/consts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import ChartContent from './ChartContent'
import {
  useSceneSvgRefInit,
  useSceneZoomBinding,
  useSceneZoomWithResearch
} from './hooks'
import { sceneChartBaseZIndex } from './zIndexes'

interface ISceneChartProps {
  className?: string
}

const SceneChart: React.FC<ISceneChartProps> = props => {
  const chartSVGRef = useSceneSvgRefInit()
  useSceneZoomBinding(chartSVGRef)
  useSceneZoomWithResearch()

  const { storeTopology } = useStores()

  const { x, y, k } = storeTopology.chartZoomData

  const zoomTransform = `translate(${x}, ${y}) scale(${k})`

  const scaledTransform = `translate(-${
    (storeTopology.chartScaleWidth - storeTopology.sceneDimensions.width) / 2
  }, -${
    (storeTopology.chartScaleHeight - storeTopology.sceneDimensions.height) / 2
  })`

  // FIXME: Maybe it's mandatory to test the height as well
  const isChartScaleBiggerThanScene =
    storeTopology.chartScaleWidth - storeTopology.sceneDimensions.width > 0

  const isActiveSearch = storeTopology.storeInputSearch.focus === true

  return (
    <div data-name="Chart" className={props.className}>
      <svg
        width={storeTopology.sceneDimensions.width}
        height={storeTopology.sceneDimensions.height}
        ref={chartSVGRef}
      >
        <g
          data-name="ChartTransformedContainer"
          className={`chartTransformedContainer ${
            isActiveSearch ? 'isActiveSearch' : ''
          }`}
          transform={zoomTransform}
        >
          <g
            data-name="ChartScaledContainer"
            transform={isChartScaleBiggerThanScene ? scaledTransform : ''}
          >
            <ChartContent />
          </g>
        </g>
      </svg>
    </div>
  )
}

const ObservedChart = observer(SceneChart)

export default styled(ObservedChart)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${sceneChartBaseZIndex};

  .chartTransformedContainer.isActiveSearch {
    transition: transform ${SCENE_TOOLIP_DEBOUNCE_DELAY}ms linear;
  }
`
