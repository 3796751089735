import type { IIconProps } from '@app/components/Icon/types'
import { IconEllipsisOutlined } from '@app/components-legacy/Icon/IconAntd'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

const StyledIconEllipsis = styled(IconEllipsisOutlined)`
  svg {
    fill: ${props => props.color ?? CTAColorV2.primary};
    width: ${props => props.size ?? 15}px;
    height: ${props => props.size ?? 15}px;
  }
`

interface IIconEllipsisProps extends IIconProps {}

const IconEllipsis: React.FC<IIconEllipsisProps> = props => (
  <StyledIconEllipsis {...props} />
)

export default IconEllipsis
