import type { PropertiesNullable } from '@@types/helpers'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import { ensureArray } from '@libs/ensureArray'
import { Expression } from '@libs/Expression'
import type {
  Directory,
  Maybe,
  Syslog,
  SyslogInputType
} from '@server/graphql/typeDefs/types'
import { createEntities, EntityDirectory } from './'
import EntityBase from './EntityBase'
import type { IEntityListable, IEntityOmitKeysParameter } from './types'

export interface IDataRowSyslog {
  id: number
  ip: string
  port: number
  protocol: string
  tls: boolean
  criticityThreshold: number
  description: string
  shouldNotifyOnInitialFullSecurityCheck: boolean
  directories: number[]
  inputType: SyslogInputType
  filterExpression: Expression
  profiles: number[]
  checkers: number[]
  attackTypes: number[]
  relayId: number
  healthCheckNames: string[]
}

export default class EntitySyslog
  extends EntityBase
  implements PropertiesNullable<Syslog>, IEntityListable<IDataRowSyslog>
{
  id: Maybe<number> = null
  ip: Maybe<string> = null
  port: Maybe<number> = null
  protocol: Maybe<string> = null
  tls: Maybe<boolean> = null
  criticityThreshold: Maybe<number> = null
  description: Maybe<string> = null
  shouldNotifyOnInitialFullSecurityCheck: Maybe<boolean> = null
  inputType: Maybe<SyslogInputType> = null
  filterExpression?: Maybe<string> = null
  directories: Maybe<Directory[]> = null
  healthCheckNames: Maybe<string[]> = null
  profiles: Maybe<number[]> = null
  checkers: Maybe<number[]> = null
  attackTypes: Maybe<number[]> = null
  relayId: Maybe<number> = null

  constructor(data: Partial<Syslog>) {
    super()
    Object.assign(this, data)
  }

  getDirectories(): EntityDirectory[] {
    return createEntities<Directory, EntityDirectory>(
      EntityDirectory,
      this.directories
    )
  }

  getExpression(): Expression {
    try {
      return this.filterExpression
        ? new Expression().fromExpressionDefinition(
            Expression.fromStringToJSON(this.filterExpression)
          )
        : new Expression()
    } catch (err) {
      return new Expression()
    }
  }

  /* Implements IEntityListable */

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowSyslog> = []
  ): Array<IWidgetListColumns<IDataRowSyslog>> {
    const columns: Array<IWidgetListColumns<IDataRowSyslog>> = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'IP address or hostname',
        key: 'ip'
      },
      {
        label: 'Port',
        key: 'port'
      },
      {
        label: 'Protocol',
        key: 'protocol'
      },
      {
        label: 'Criticity threshold',
        key: 'criticityThreshold'
      },
      {
        label: 'TLS',
        key: 'tls'
      },
      {
        label: 'Directories',
        key: 'directories'
      },
      {
        label: 'Description',
        key: 'description'
      }
    ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  asDataRow(): IDataRowSyslog {
    return {
      id: this.getPropertyAsNumber('id'),
      ip: this.getPropertyAsString('ip'),
      port: this.getPropertyAsNumber('port'),
      protocol: this.getPropertyAsString('protocol'),
      criticityThreshold: this.getPropertyAsNumber('criticityThreshold'),
      tls: this.getPropertyAsBoolean('tls'),
      directories: ensureArray(this.directories).map(d => d.id),
      healthCheckNames: ensureArray(this.healthCheckNames),
      shouldNotifyOnInitialFullSecurityCheck: this.getPropertyAsBoolean(
        'shouldNotifyOnInitialFullSecurityCheck'
      ),
      description: this.getPropertyAsString('description'),
      inputType: this.getPropertyAsT<SyslogInputType>('inputType'),
      profiles: ensureArray(this.profiles),
      checkers: ensureArray(this.checkers),
      attackTypes: ensureArray(this.attackTypes),
      filterExpression: this.getExpression(),
      relayId: this.getPropertyAsNumber('relayId')
    }
  }
}
