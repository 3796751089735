import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'

interface IIconAttackPathProps extends IIconProps {}

const IconAttackPath: React.FC<IIconAttackPathProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.88755 9.99988H1.86255M18.1376 9.99988H13.1125M18.1376 9.99988L16.8632 11.8698M18.1376 9.99988L16.8632 8.12456M10 18.1374V13.1124M10 6.88738V1.86238M11.8696 3.13463L10 1.86084L8.12435 3.13463M8.12435 16.863L9.99386 18.1368L11.8696 16.863M3.13694 8.12456L1.86315 9.99407L3.13695 11.8698M13.1164 9.99988C13.1164 11.721 11.7211 13.1162 10 13.1162C8.27895 13.1162 6.88373 11.721 6.88373 9.99988C6.88373 8.27879 8.27895 6.88356 10 6.88356C11.7211 6.88356 13.1164 8.27879 13.1164 9.99988Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconAttackPath
