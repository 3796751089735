import type { StoreInputReasons } from '@app/stores'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'

/**
 * Open the drawer of the reasons selection.
 */
export const onReasonsPickerButtonClick =
  (storeInputReasons: StoreInputReasons) => () => {
    storeInputReasons.storeDrawer.openDrawer()
  }

/**
 * When searching a reason and unselect other reasons.
 *
 * The user have to select again once it has finished his search.
 */
export const onReasonsSearch = (storeInputReasons: StoreInputReasons) => () => {
  storeInputReasons.unselectAllReasons()
}

/**
 * Select or unselect all reasons.
 */
export const onReasonsSelectAllClick =
  (storeInputReasons: StoreInputReasons) => (e: CheckboxChangeEvent) => {
    e.target.checked
      ? storeInputReasons.selectAllReasons()
      : storeInputReasons.unselectAllReasons()
  }

/**
 * Toggle selection of a reason.
 */
export const onReasonSelection =
  (storeInputReasons: StoreInputReasons) => (reasonId: number) => () => {
    storeInputReasons.toggleSelectReason(reasonId)
  }

/**
 * Validate the reason selection.
 */
export const onReasonsDrawerSubmit =
  (
    storePendingReasons: StoreInputReasons,
    storeInputReasons: StoreInputReasons
  ) =>
  (onValidate?: () => void) =>
  () => {
    storeInputReasons.replaceSelectedReasons(
      storePendingReasons.selectedReasons
    )
    storeInputReasons.storeDrawer.closeDrawer()

    if (onValidate) {
      onValidate()
    }
  }
