import type {
  IFormWrapperSelectGroupOptionProps,
  IFormWrapperSelectOptionProps,
  IFormWrapperSelectProps
} from '@app/components-legacy/Form/Wrappers/Select'

function boldString(str: string, substr: string) {
  // Catch regular expression crashes
  try {
    let boldedStr = str

    const escapedSubstr = substr.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

    const strRegExp = new RegExp(escapedSubstr, 'gi')

    const matches = [...str.matchAll(strRegExp)]

    // reverse to replace from the end
    matches.reverse().forEach(match => {
      const index = match.index || 0

      boldedStr = `${boldedStr.substring(0, index)}<b>${
        match[0]
      }</b>${boldedStr.substring(index + match[0].length)}`
    })

    return boldedStr
  } catch (err) {
    return str
  }
}

export function boldAndOrderSelectOptions<
  U extends IFormWrapperSelectGroupOptionProps | IFormWrapperSelectOptionProps
>(options: U[], searchValue: string): U[] {
  return options
    .map(option => {
      if ('groupLabel' in option) {
        const subOptions = boldAndOrderSelectOptions(
          option.options,
          searchValue
        )
        return {
          option: { ...option, options: subOptions },
          rank: subOptions?.[0].label.startsWith('<b>') ? 1 : 2
        }
      }

      const label = searchValue
        ? boldString(option.label, searchValue)
        : option.label

      return {
        option: {
          ...option,
          label
        },
        rank: label.startsWith('<b>') ? 1 : 2 // put the option where search matches the start of the label on top
      }
    })
    .sort(({ rank: rank1 }, { rank: rank2 }) => rank1 - rank2)
    .map(({ option }) => option)
}

export function getSelectWidth(
  width?: IFormWrapperSelectProps<any>['width']
): string {
  switch (width) {
    case 'verySmall':
      return '60px'

    case 'small':
      return '100px'

    case 'smallNormal':
      return '175px'

    case 'normal':
      return '200px'

    case 'medium':
      return '263px'

    case 'large':
      return '300px'

    case 'extraLarge':
      return '400px'

    case 'auto':
      return 'auto'

    default:
      return '100%'
  }
}
