import type { DomDocument, DomWindow } from '@@types/dom'

export type BrowserEnvironment = {
  domDocument: DomDocument
  domWindow: DomWindow
}

export function createBrowserEnvironment(
  domDocument: DomDocument,
  domWindow: DomWindow
): BrowserEnvironment {
  return {
    domDocument,
    domWindow
  }
}
