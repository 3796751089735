import { consts } from '@app/styles'
import classnames from 'classnames'
import * as React from 'react'
import styled from 'styled-components'

interface IIconBeeProps {
  className?: string
  disabled?: boolean
  onClick?: () => void
}

const IconBee: React.FC<IIconBeeProps> = props => {
  const onClick = !props.disabled
    ? () => props.onClick && props.onClick()
    : undefined

  const fillColor = props.disabled ? consts.colorBlueGray020 : 'currentColor'

  const classNames = classnames({
    anticon: true,
    [props.className || '']: true
  })

  return (
    // Add a wrapper with "anticon" className to apply hover effect on table
    <span className={classNames}>
      <svg width="13" height="12" onClick={onClick}>
        <g fill="none" fillRule="evenodd">
          <path d="M.5 0h12v12H.5z" />
          <path
            d="m7.54 1.118.223-.861c.115-.445.77-.264.655.18l-.256.988a3.216 3.216 0 0 1 1.403 2.053l2.282 3.59c.666 1.048-.21 2.464-1.446 2.2a1.39 1.39 0 0 1-.895-.637c-.324 1.127-1.233 1.993-2.351 2.229l-.38.927a.335.335 0 0 1-.633-.026l-.301-.92c-1.081-.26-1.952-1.114-2.268-2.21-.21.33-.515.556-.895.637-1.213.26-2.125-1.132-1.447-2.2l2.283-3.59c.177-.856.691-1.599 1.407-2.056L4.665.438c-.115-.445.54-.626.655-.181l.223.86a2.992 2.992 0 0 1 1.997.001ZM4.182 3.602h4.715C8.653 2.47 7.67 1.647 6.539 1.647c-1.131 0-2.113.822-2.357 1.955Zm4.772.701H4.125V5.55h4.829V4.303ZM3.446 7.56V4.856L1.791 7.46c-.337.547.128 1.256.752 1.123.425-.09.685-.682.903-1.024Zm.68-1.308v1.246h4.828V6.251H4.125ZM8.91 8.199H4.168c.189 1.019.982 1.82 1.975 1.991.252.035.289.27.356.477.08-.194.131-.432.37-.466 1.02-.146 1.848-.959 2.042-2.002Zm.722-3.343v2.702c.21.332.483.935.903 1.025.62.132 1.096-.584.747-1.132l-1.65-2.595Z"
            fill={fillColor}
            fillRule="nonzero"
          />
        </g>
      </svg>
    </span>
  )
}

export default styled(IconBee)`
  cursor: ${props => (props.disabled ? 'default !important' : 'pointer')};
`
