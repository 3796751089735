import type { IContainerSimpleProps } from '@app/components-legacy/Container/ContainerSimple'
import ContainerSimple from '@app/components-legacy/Container/ContainerSimple'
import { useAppRouter } from '@app/hooks'
import { AppRouteName } from '@app/routes'
import * as React from 'react'
import styled from 'styled-components'

const StyledContainerSimple = styled(ContainerSimple)<{ width?: number }>`
  > img {
    width: ${props => (props.width ? `${props.width}px` : 'auto')};
  }
`

export interface IAssetImgProps extends IContainerSimpleProps {
  labelledBy?: string
  imagePath?: string
  imageName: string
  width?: number
  fileType?: 'png' | 'svg'
}

export const AssetImg: React.FC<IAssetImgProps> = props => {
  const appRouter = useAppRouter()

  function getImagePath() {
    if (props.imagePath) {
      return [props.imagePath, props.imageName].join('/')
    }

    return appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareAssets_Images,
      parameters: {
        image: props.imageName
      }
    })
  }

  const imgSrc = `${getImagePath()}.${props.fileType || 'png'}`

  return (
    <StyledContainerSimple ariaRoles={['figure', 'img']} {...props}>
      <img src={imgSrc} />
    </StyledContainerSimple>
  )
}
