import { ContainerFlex } from '@app/components/Container'
import IconIdentityProvider from '@app/components/Icon/IconIdentityProvider'
import { LabelDate, LabelSliced } from '@app/components-legacy/Label'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import {
  WidgetListPagination,
  WidgetListTable,
  WidgetListTableActionIcons,
  WidgetListTableActionIconsToggle
} from '@app/components-legacy/Widgets/WidgetList'
import type { IDataRowFinding } from '@app/entities/EntityGenericChecker/EntityFinding'
import type EntityFinding from '@app/entities/EntityGenericChecker/EntityFinding'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckerIdentityDetailsPageContext } from '../../context'
import DrawerExportFindings from '../DrawerExportFindings'
import { handleFindingsOnPageChange } from '../handlers'
import FindingDetails from './FindingDetails'

interface IFindingsTableProps {}

const FindingsTable: React.FC<IFindingsTableProps> = () => {
  const parameters = React.useContext(CheckerIdentityDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Components.IconIdentityProvider',
      'IoE.Details.Findings'
    ]
  })

  const { storeIoE } = useStores()

  if (!parameters) {
    return null
  }

  return (
    <>
      <ContainerFlex
        name="Table"
        labelledBy="findingsObjects"
        direction="column"
        items={[
          <WidgetListTable<EntityFinding, IDataRowFinding>
            translate={translate}
            storeWidgetList={
              storeIoE.storeIndicator.storeIndicatorFindings
                .storeWidgetListFindings
            }
            columnWidths={{
              type: 6,
              object: 10,
              provider: 15,
              tenant: 10,
              date: 10
            }}
            hiddenColumnsKeys={['id']}
            cellsRenderFn={{
              object: value => {
                if (!value) {
                  return null
                }

                return <LabelSliced value={value} maxLength={30} />
              },

              provider: providerType => {
                if (!providerType) {
                  return null
                }

                return (
                  <ContainerFlex
                    name="FindingTenant"
                    alignItems="center"
                    flexGap="small"
                    items={[
                      <IconIdentityProvider providerType={providerType} />,

                      <LabelSliced
                        value={translate(providerType)}
                        maxLength={60}
                      />
                    ]}
                  />
                )
              },

              tenant: tenant => {
                if (!tenant) {
                  return null
                }

                return (
                  <ContainerFlex
                    name="FindingTenant"
                    alignItems="center"
                    flexGap="small"
                    items={[<LabelSliced value={tenant.name} maxLength={60} />]}
                  />
                )
              },

              description: value => {
                return (
                  <LabelSliced
                    value={value}
                    maxLength={60}
                    allowHTML
                    popoverType="none"
                  />
                )
              },

              date: value => {
                return <LabelDate date={value} />
              }
            }}
            actionsButtonsRenderFn={(_, row) => {
              return (
                <WidgetListTableActionIcons
                  icons={[
                    <WidgetListTableActionIconsToggle
                      storeWidgetList={
                        storeIoE.storeIndicator.storeIndicatorFindings
                          .storeWidgetListFindings
                      }
                      row={row}
                    />
                  ]}
                />
              )
            }}
            toggleableRowRenderFn={(_, row) => (
              <FindingDetails dataRowFinding={row} />
            )}
          />,

          <WidgetListPagination
            storeWidgetList={
              storeIoE.storeIndicator.storeIndicatorFindings
                .storeWidgetListFindings
            }
            onChange={handleFindingsOnPageChange(storeIoE)(
              parameters.checkerId
            )}
          />
        ]}
        spaced
      />

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerExportFindings />
      </Portal>
    </>
  )
}

export default observer(FindingsTable)
