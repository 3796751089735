/* eslint-disable max-classes-per-file */
import type { Maybe } from '@@types/helpers'
import type { IGraphQLServerErrorMessage } from '@libs/graphQL/GQLRequestor/types'
import type { ApplicationErrorValue } from './types'
import { ErrorName } from './types'

export class CustomError<T> extends Error {
  private isMessageString: boolean

  /**
   * Handle string or object as message.
   */
  constructor(message: T) {
    const finalMessage =
      typeof message === 'string' ? message : JSON.stringify(message)

    super(finalMessage)
    Object.setPrototypeOf(this, new.target.prototype)

    this.name = ErrorName.CustomError

    // save the type of message to know if it has to be parsed or not
    this.isMessageString = typeof message === 'string'
  }

  /**
   * Return the parsed message T from the message string.
   */
  getMessage(): T {
    if (this.isMessageString) {
      return this.message as unknown as T
    }

    try {
      return JSON.parse(this.message) as T
    } catch (err) {
      throw new Error('[CustomError] Cant parse the error payload')
    }
  }
}

/**
 * Used for any error in the application (related to stores, mainly).
 */
export class ApplicationError extends CustomError<{
  errorValue: ApplicationErrorValue
  message?: string
}> {
  constructor(message: {
    errorValue: ApplicationErrorValue
    message?: string
  }) {
    super(message)
    this.name = ErrorName.ApplicationError
  }
}

/**
 * Used for errors related to RequestError from the API (related to stores, mainly).
 */
export class ApplicationRequestError extends CustomError<{
  errorValue: ApplicationErrorValue
  message?: {
    message: string
    technicalError?: string
  }
}> {
  constructor(message: {
    errorValue: ApplicationErrorValue
    message?: {
      message: string
      technicalError?: string
    }
  }) {
    super(message)
    this.name = ErrorName.ApplicationRequestError
  }
}

/**
 * Used for any errors related that requires the reload of the App.
 */
export class HardReloadError extends CustomError<Maybe<string>> {
  /**
   * Message is the url to redirect.
   */
  constructor(message: Maybe<string>) {
    super(message)
    this.name = ErrorName.HardReloadError
  }
}

/**
 * Used for any errors related to a bad request (400).
 */
export class BadRequestError extends CustomError<string> {
  constructor(message?: string) {
    super(message || 'Bad request error')
    this.name = ErrorName.BadRequestError
  }
}

/**
 * Used for any errors related to an access unauthorized (401).
 */
export class UnauthorizedAccessError extends CustomError<string> {
  constructor(message?: string) {
    super(message || 'Unauthorized access error')
    this.name = ErrorName.UnauthorizedAccessError
  }
}

/**
 * Used for any errors related to a locked account (Account Policy).
 */
export class LockedOutAccountAccessError extends CustomError<string> {
  constructor(message?: string) {
    super(message || 'Lockout account access error')
    this.name = ErrorName.LockedOutAccountAccessError
  }
}

/**
 * Used for any errors related to an access forbidden (403).
 */
export class ForbiddenAccessError extends CustomError<string> {
  constructor(message?: string) {
    super(message || 'Fordidden access error')
    this.name = ErrorName.ForbiddenAccessError
  }
}

/**
 * Used for any error related to a resource not found (404).
 */
export class NotFoundError extends CustomError<string> {
  constructor(message?: string) {
    super(message || 'Not found')
    this.name = ErrorName.NotFoundError
  }
}

/**
 * Used for any errors related to an error in the server.
 */
export class ServerError extends CustomError<string> {
  constructor(message?: string) {
    super(message || 'Server error')
    this.name = ErrorName.ServerError
  }
}

/**
 * Used for any errors related to an error in the server.
 */
export class RbacNotSupportedError extends CustomError<string> {
  constructor(message?: string) {
    super(message || 'RBAC not supported error')
    this.name = ErrorName.RbacNotSupportedError
  }
}

/**
 * Used for any errors related to a GraphQL error server-side.
 * Used in to pass meta-data between the GraphQLRouter middleware and the
 * GQLRequestor client.
 */
export class GraphQLServerError extends CustomError<IGraphQLServerErrorMessage> {
  constructor(message: IGraphQLServerErrorMessage) {
    super(message)
    this.name = ErrorName.GraphQLServerError
  }
}

/**
 * Used for license downgrade error.
 * This is dispatched when the user wants to use an old format license when he
 * already have a higher version one.
 */
export class DowngradeLicenseError extends CustomError<Maybe<string>> {
  constructor(message: Maybe<string>) {
    super(message)
    this.name = ErrorName.LicenseDowngradeNotAllowed
  }
}

/**
 * Used for license already used error.
 * This is dispatched when the user wants to use a license that has already been used.
 */
export class AlreadyUsedLicenseError extends CustomError<Maybe<string>> {
  constructor(message: Maybe<string>) {
    super(message)
    this.name = ErrorName.InvalidLinkingKey
  }
}

/**
 * Used for invalid password error.
 * This is dispatched when the user needs to reset his password.
 */
export class InvalidPasswordError extends CustomError<Maybe<string>> {
  constructor(message: Maybe<string>) {
    super(message)
    this.name = ErrorName.InvalidPassword
  }
}

/**
 * Used when the user is redirected to first-login-password page after login.
 */
export class PasswordChangeNeededError extends CustomError<Maybe<string>> {
  constructor(message: Maybe<string>) {
    super(message)
    this.name = ErrorName.PasswordChangeNeededError
  }
}

export class AttackPathGraphError extends CustomError<string> {
  constructor(errorName: ErrorName) {
    super(errorName)
    this.name = errorName
  }
}
