import type { IGraphQLQuery } from '@libs/graphQL/types'
import type { UpdateAzureAdSupportConfigurationMutationArgs } from '@server/graphql/typeDefs/types'
import type { Mutation } from '../typeDefs/types'

/**
 * Microsoft Entra ID Support Configuration
 */

/* Edit Microsoft Entra ID Support Configuration */

export type MutationUpdateAzureADSupportConfiguration = IGraphQLQuery<
  UpdateAzureAdSupportConfigurationMutationArgs,
  Pick<Mutation, 'updateAzureADSupportConfiguration'>
>

export const mutationUpdateAzureADSupportConfiguration = `
  mutation mutationUpdateAzureADSupportConfiguration(
    $azureADSupportConfiguration: InputAzureADSupportConfiguration!
  ) {
    updateAzureADSupportConfiguration(
      azureADSupportConfiguration: $azureADSupportConfiguration
    ) {
      azureADSupportEnabled
    }
  }
`
