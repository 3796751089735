import type { Maybe, MaybeUndef } from '@@types/helpers'
import type { IWidgetListBulkActionOption } from '@app/components-legacy/Widgets/WidgetList/types'
import { getDataSet } from '@app/components-legacy/Widgets/WidgetList/WidgetListTable/functions'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import type EntityIncriminatingAttributeListable from '@app/entities/EntityIncriminatingAttribute/EntityIncriminatingAttributeListable'
import type { IDataRowIncriminatingAttributeListable } from '@app/entities/EntityIncriminatingAttribute/types'
import { AppRouteName } from '@app/routes'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import type StoreIndicatorDeviantObjectDeviances from '@app/stores/IoE/StoreIndicator/StoreIndicatorDeviantObjectDeviances'
import type StoreIndicatorDeviantObjects from '@app/stores/IoE/StoreIndicator/StoreIndicatorDeviantObjects'
import type { IDrawerIgnoreUntilData } from '@app/stores/IoE/StoreIndicator/types'
import type { IRouteDefinition } from '@libs/Router/types'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { DeviantObjectBulkActionValue } from '../types'

/**
 * On incriminating attributes page change,
 * fetch new incriminating attributes of the new page.
 */
export const onDeviancesPageChange =
  (
    storeIndicatorDeviantObjectDeviances: StoreIndicatorDeviantObjectDeviances
  ) =>
  (checkerCodename: GenericCheckerCodename) =>
  (page: number, perPage: MaybeUndef<number>) => {
    storeIndicatorDeviantObjectDeviances.computeFetchDeviancesArgs(
      checkerCodename
    )

    const args = storeIndicatorDeviantObjectDeviances.computeFetchDeviancesArgs(
      checkerCodename,
      {
        incriminatingAttributesPage: page,
        incriminatingAttributesPerPage: perPage
      }
    )

    if (!args) {
      return
    }

    storeIndicatorDeviantObjectDeviances.reloadDeviances(args)
  }

/**
 * On bulk action, open the drawer to ignore or stop ignoring selected deviances.
 */
export const onDeviancesBulkActionSelection =
  (storeIndicatorDeviantObjects: StoreIndicatorDeviantObjects) =>
  (drawerData: IDrawerIgnoreUntilData) =>
  (option: IWidgetListBulkActionOption) => {
    if (option.value === DeviantObjectBulkActionValue.ignore) {
      storeIndicatorDeviantObjects.storeFormIgnoreObjects.hardReset()

      storeIndicatorDeviantObjects.storeDrawerIgnoreObjets
        .setData(drawerData)
        .openDrawer()

      return
    }

    if (option.value === DeviantObjectBulkActionValue.unignore) {
      storeIndicatorDeviantObjects.storeDrawerUnignoreObjets
        .setData(drawerData)
        .openDrawer()

      return
    }
  }

export const onCheckIncriminatingAttribute =
  (
    storeWidgetList: StoreWidgetList<
      EntityIncriminatingAttributeListable,
      IDataRowIncriminatingAttributeListable
    >,
    incriminatingAttributeDataRow: IDataRowIncriminatingAttributeListable
  ) =>
  (e: CheckboxChangeEvent): void => {
    if (!storeWidgetList) {
      return
    }

    const dataSet = getDataSet(storeWidgetList)

    e.target.checked
      ? storeWidgetList.selectRow(incriminatingAttributeDataRow)
      : storeWidgetList.unselectRow(incriminatingAttributeDataRow, dataSet.rows)
  }

export function getDeviantObjectsEventDetailsRouteName(
  routeName: IRouteDefinition<AppRouteName>['routeName']
): Maybe<
  | AppRouteName.IoE_Details_DeviantObjects_EventDetails_Attributes
  | AppRouteName.Topology_DeviantObjects_EventDetails_Attributes
> {
  switch (routeName) {
    case AppRouteName.IoE_Details_DeviantObjects:
      return AppRouteName.IoE_Details_DeviantObjects_EventDetails_Attributes
    case AppRouteName.Topology_DeviantObjects:
      return AppRouteName.Topology_DeviantObjects_EventDetails_Attributes
    default:
      return null
  }
}
