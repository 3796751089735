import { Features } from '@alsid/common'
import { ContainerFlex } from '@app/components/Container/ContainerFlex'
import { IconClose } from '@app/components-legacy/Icon'
import { useAppRouter, useStores } from '@app/hooks'
import { useDeviceViewPort } from '@app/hooks/useDeviceViewPort'
import { DeviceViewPortWidth } from '@app/hooks/useDeviceViewPort/types'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { AppRouteName } from '@app/routes'
import { consts, helpers } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { buildVariants } from '@design-system/libs/buildVariants'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  handleAppRibbonTimer,
  handleWidgetRibbonVisibilityOnClick
} from './handlers'

export interface ILabelAppRibbonProps {
  labelledBy?: string
  isTulEnabled?: boolean
  isTulV6Enabled?: boolean
  variant: 'version' | 'license'
  closeNotAllowed?: boolean
  children: React.ReactNode
}

const hideRibbonOnRouteNames = [
  AppRouteName.Auth_Eula,
  AppRouteName.Auth_PendingActivation,
  AppRouteName.Auth_FirstLoginPassword,
  AppRouteName.IoA_Board_PDF,
  AppRouteName.Auth_License
]

const RIBBON_WIDTH = '600px'

const StyledContainerFlex = styled(ContainerFlex)<
  Omit<ILabelAppRibbonProps, 'children'>
>(props => {
  const theme = useDSTheme()

  const tulHeight =
    theme.tokens[`layout/tul${props.isTulV6Enabled ? '' : '-v5'}/height`]

  return buildVariants(props)
    .css({
      position: 'absolute',
      top: 0,
      left: '50%',
      zIndex: consts.zIndexLabelAppRibbon,
      marginLeft: `-${helpers.divide(RIBBON_WIDTH, 2)}`,
      width: RIBBON_WIDTH,
      padding: consts.paddingVeryVerySmall,
      color: 'white',
      textAlign: 'center',
      lineHeight: '16px',
      borderRadius: consts.borderRadiusRedesign,

      a: {
        color: 'white',
        textDecoration: 'underline'
      }
    })

    .variant('isTulEnabled', props.isTulEnabled, {
      true: {
        marginTop: 10,
        lineHeight: '16px',
        height: tulHeight - 20
      },

      false: {
        marginTop: 5,
        lineHeight: '13px',
        height: helpers.sub(consts.headerHeight, 10)
      }
    })

    .variant('variant', props.variant, {
      version: {
        backgroundColor: consts.colorPurple010,
        fontWeight: consts.fontWeightBold
      },

      license: {
        backgroundColor: consts.colorRed001,
        fontWeight: 'normal'
      }
    })

    .end()
})

const StyledIconClose = styled(IconClose)(props => {
  return buildVariants(props)
    .css({
      position: 'absolute',
      right: '15px'
    })
    .end()
})

const LabelAppRibbon: React.FC<ILabelAppRibbonProps> = props => {
  const appRouter = useAppRouter()
  const { deviceViewPort } = useDeviceViewPort()
  const { storeLicense } = useStores()
  const isTulEnabled = useFeatureFlag({
    featureFlagName: Features.TENABLE_UNIVERSAL_LAYOUT
  })
  const isTulV6Enabled = useFeatureFlag({
    featureFlagName: Features.KAPTEYN_TUL_V6
  })

  const closable =
    deviceViewPort.isLessThan(DeviceViewPortWidth.large) &&
    !props.closeNotAllowed

  React.useEffect(() => {
    if (!closable) {
      return
    }

    handleWidgetRibbonVisibilityOnClick(storeLicense, true)()
  }, [])

  React.useEffect(() => {
    if (!closable) {
      return
    }

    const { ribbonTimer } =
      storeLicense.storeRoot.environment.config.app.settings

    handleAppRibbonTimer(storeLicense, ribbonTimer)()
  }, [storeLicense.isRibbonVisible])

  const currentRouteName = appRouter.getCurrentRouteName()

  // Do not show the ribbon on the Auth-like page
  // (like login, license upload, eula, etc).
  if (currentRouteName && hideRibbonOnRouteNames.includes(currentRouteName)) {
    return null
  }

  if (!storeLicense.isRibbonVisible) {
    return null
  }

  if (!storeLicense.isRibbonDisplayed) {
    return null
  }

  return (
    <StyledContainerFlex
      name="LabelAppRibbon"
      alignItems="center"
      justifyContent="center"
      labelledBy={props.labelledBy}
      isTulEnabled={isTulEnabled}
      isTulV6Enabled={isTulV6Enabled}
      variant={props.variant}
      items={[
        <div>{props.children}</div>,

        closable && (
          <StyledIconClose
            onClick={handleWidgetRibbonVisibilityOnClick(
              storeLicense,
              !storeLicense.isRibbonVisible
            )}
            color={consts.colorWhite}
          />
        )
      ]}
    />
  )
}

export default observer(LabelAppRibbon)
