import FormWrapperCheckboxSimple from '@app/components-legacy/Form/Wrappers/FormWrapperCheckboxSimple'
import { LabelNonBreakable } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleSelectAllEntriesOnChange } from './handlers'

interface ISelectAllEntriesSelectorProps {
  className?: string
}

const SelectAllEntriesSelector: React.FC<
  ISelectAllEntriesSelectorProps
> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Management.System.Configuration.IoA',
      'Components.InputAttackTypes'
    ]
  })

  const { storeManagementAttackTypeConfiguration } = useStores()

  return (
    <LabelNonBreakable>
      <FormWrapperCheckboxSimple
        labelledBy="selectAll"
        className={props.className}
        checked={storeManagementAttackTypeConfiguration.isAllAttackTypesForAllDirectoriesSelected()}
        indeterminate={storeManagementAttackTypeConfiguration.isAllAttackTypesForAllDirectoriesPartiallySelected()}
        onChange={handleSelectAllEntriesOnChange(
          storeManagementAttackTypeConfiguration
        )}
        labelProps={{
          style: { fontWeight: consts.fontWeightBold }
        }}
      >
        {translate('Select all')}
      </FormWrapperCheckboxSimple>
    </LabelNonBreakable>
  )
}

export default observer(SelectAllEntriesSelector)
