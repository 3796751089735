import Blade from '@app/components-legacy/Blade/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks/useStores'
import WidgetAddPage from '@app/pages/Dashboard/WidgetAddPage'
import { AppRouteName } from '@app/routes'
import { grant } from '@libs/rbac/permissions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import GridPage from './GridPage'
import DrawerAddDashboard from './GridPage/DrawerAddDashboard'
import DrawerDeleteDashboard from './GridPage/DrawerDeleteDashboard'
import DrawerEditDashboard from './GridPage/DrawerEditDashboard'
import DrawerRemoveDashboardWidget from './GridPage/DrawerRemoveDashboardWidget'
import GridCreateFirstDashboard from './GridPage/GridCreateFirstDashboard'
import GridMenu from './GridPage/GridMenu'
import { onDashboardsLoad, onDashboardsUnload } from './handlers'
import { canAccessToDashbards } from './permissions'
import WidgetEditPage from './WidgetEditPage'

interface IDashboardPageProps {}

const DashboardPage: React.FC<IDashboardPageProps> = () => {
  const { storeDashboards } = useStores()

  return (
    <>
      <Blade
        root={true}
        routeDefinition={{
          routeName: AppRouteName.Dashboard,
          parameters: {}
        }}
        onLoad={onDashboardsLoad(storeDashboards)}
        onUnload={onDashboardsUnload(storeDashboards)}
        flags={[
          storeDashboards.storeFlagsAllDashboards.flags,
          storeDashboards.storeFlagsOneDashboard.flags
        ]}
        layout={{
          name: 'default',
          menu: <GridMenu />,
          content: (
            <RbacRoutes
              routes={[
                {
                  routeDefinition: {
                    routeName: AppRouteName.Dashboard_Grid,
                    parameters: {
                      instanceName: String(),
                      dashboardId: Number()
                    }
                  },
                  component: GridPage,
                  rbacPermissionsCheck: () => grant()
                },
                {
                  routeDefinition: {
                    routeName: AppRouteName.Dashboard,
                    parameters: {}
                  },
                  component: GridCreateFirstDashboard,
                  rbacPermissionsCheck: () => grant()
                }
              ]}
            />
          )
        }}
        rbacCapabilityCheck={canAccessToDashbards}
      />

      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName: AppRouteName.Dashboard_Grid_WidgetAdd,
              parameters: {
                instanceName: String(),
                dashboardId: Number()
              }
            },
            component: WidgetAddPage,
            rbacPermissionsCheck: () => grant()
          },
          {
            routeDefinition: {
              routeName: AppRouteName.Dashboard_Grid_WidgetEdit,
              parameters: {
                instanceName: String(),
                dashboardId: Number(),
                dashboardWidgetId: Number()
              }
            },
            component: WidgetEditPage,
            rbacPermissionsCheck: () => grant()
          }
        ]}
      />

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerAddDashboard />
      </Portal>

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerEditDashboard />
      </Portal>

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerDeleteDashboard />
      </Portal>

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerRemoveDashboardWidget />
      </Portal>
    </>
  )
}

export default observer(DashboardPage)
