import type { MaybeUndef } from '@@types/helpers'
import type Translator from '@libs/i18n/Translator'
import Keepsake from '@libs/Keepsake'
import { getLogger } from '@libs/logger'
import { mdToHTML } from '@libs/mdToHTML'
import type { Language } from '@server/graphql/typeDefs/types'
import type { ITranslateKey, ITranslateOptions, TranslateFn } from './types'

const logger = getLogger('AppTranslator')

export const LANGUAGE_LOCALSTORAGE_KEY = 'tad:language'

export const defaultTranslateFn: TranslateFn = (str: string) => str

export default class AppTranslator {
  private _translator: Translator
  private _localStorage: MaybeUndef<Storage>

  private _keeper = new Keepsake()

  constructor(translator: Translator, localStorage?: Storage) {
    this._translator = translator
    this._localStorage = localStorage

    const firstPreferredLanguage = this._translator.getFirstPreferredLanguage()

    // save the language in local storage
    this.saveLanguage(firstPreferredLanguage)
  }

  /**
   * Translate key with options.
   */
  translate(key: ITranslateKey, options?: ITranslateOptions): string {
    return this._translate(key, options)
  }

  /**
   * Return a memoized translate function binded to a namespace.
   */
  bindOptions(options?: ITranslateOptions): TranslateFn {
    // return a memoized translate function that will memoize results according
    // to arguments passed to the function
    const memoTranslate = this._keeper.memoFunction(this._translate.bind(this))

    return (key: ITranslateKey, localOptions?: ITranslateOptions) => {
      return memoTranslate.width(key, { ...options, ...localOptions })
    }
  }

  /**
   * Save language in localStorage.
   */
  saveLanguage(language: Language): void {
    if (this._localStorage) {
      this._localStorage.setItem(LANGUAGE_LOCALSTORAGE_KEY, language)
    }
  }

  /**
   * Return the language of the translator.
   */
  get language(): Language {
    return this._translator.getFirstPreferredLanguage()
  }

  /* Private */

  /**
   * Translate a text.
   */
  private _translate(key: ITranslateKey, options?: ITranslateOptions): string {
    if (IS_DEVELOPMENT) {
      if (/\.$/.test(key)) {
        logger.warn(`Translations keys should not finish with dots (${key}).`)
      }
    }

    const value = this._translator.translate(key, options)

    if (options && options.transformMarkdown) {
      return mdToHTML(value)
    }

    return value
  }
}
