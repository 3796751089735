import type { Maybe } from '@@types/helpers'
import * as React from 'react'

export interface IWindowSize {
  width: number
  height: number
}

function getWindowSize(): IWindowSize {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

export function useWindowSize(
  options = { debounceDelay: 150 }
): Maybe<IWindowSize> {
  const [windowSize, setWindowSize] =
    React.useState<IWindowSize>(getWindowSize())

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout

    const onResize = () => {
      clearTimeout(timeoutId)

      timeoutId = setTimeout(
        () => setWindowSize(getWindowSize()),
        options.debounceDelay
      )
    }

    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [])

  return windowSize
}
