import type { Maybe } from '@@types/helpers'
import { useAppTranslator } from '@app/hooks'
import type StoreRoot from '@app/stores/StoreRoot'
import { consts } from '@app/styles'
import { getRainbowColorScheme } from '@app/styles/colors/schemes'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { convertToLineChartData } from '@libs/dashboards/formatData'
import { formatYTicks } from '@libs/dashboards/formatYTicks'
import { DateFormat } from '@libs/dates/formatDate'
import { hasItems } from '@libs/hasItems'
import { isDefined } from '@libs/isDefined'
import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as moment from 'moment'
import * as React from 'react'
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, YAxis } from 'recharts'
import styled, { css } from 'styled-components'
import type { IWidgetProps } from './types'
import WidgetContainer from './WidgetContainer'
import WidgetFooterDirectories from './WidgetFooterDirectories'
import WidgetHeaderTitle from './WidgetHeaderTitle'

export interface IWidgetBarChartProps extends IWidgetProps {
  storeRoot?: StoreRoot
}

const CustomWidgetContainer = styled(WidgetContainer)`
  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      .recharts-cartesian-axis-line {
        stroke: ${consts.FontColorV2.secondary};
      }

      .recharts-cartesian-axis-tick-line {
        display: none;
      }

      .recharts-legend-item-text {
        color: ${consts.FontColorV2.primary} !important;
      }

      .recharts-cartesian-axis-tick {
        text {
          fill: ${consts.FontColorV2.secondary};
        }
      }

      .recharts-cartesian-grid-vertical {
        line:first-child,
        line:last-child {
          display: none;
        }
      }
    `}
`

const WidgetBarChart: React.FC<IWidgetBarChartProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Dashboard.Common'] })

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const renderHeader = () => {
    const warnings = []
    if (props.storeWidget.hasSerieWithUnknownDirectory) {
      warnings.push(translate('Some domains are no longer available'))
    }

    return (
      <WidgetHeaderTitle
        widgetKey={props.widgetKey}
        title={props.title}
        warnings={warnings}
        onEditIconClick={props.onEditIconClick}
        onDeleteIconClick={props.onDeleteIconClick}
        onRefreshIconClick={props.onRefreshIconClick}
      />
    )
  }

  const renderContent = (): Maybe<React.ReactElement> => {
    const { storeWidget } = props
    const { series } = storeWidget

    if (!hasItems(series)) {
      return null
    }

    const data = convertToLineChartData(series)

    if (!hasItems(data.rows)) {
      return null
    }

    const colors = getRainbowColorScheme(data.labels.length)
    const timestamp = get(series[0], 'stats.data.0.timestamp')

    return (
      <BarChart
        width={props.bounds.width}
        height={props.bounds.height}
        data={data.rows}
        margin={{
          top: 70,
          bottom: 30
        }}
        barGap={themeIsLegacy ? 4 : 10}
      >
        <CartesianGrid strokeDasharray="3 3" />

        <YAxis tickFormatter={formatYTicks} />

        <Tooltip
          labelFormatter={() => {
            return moment(Number(timestamp)).format(DateFormat.verbose)
          }}
        />

        <Legend
          wrapperStyle={{
            top: '10px'
          }}
        />

        {data.labels.map((label, i) => {
          return <Bar key={data.uuids[i]} dataKey={label} fill={colors[i]} />

          // FIXME Colors by domains should be the way to go but currently
          // barcharts are very darks. See how to improve it.

          // const color = getSerieColor(
          //   props.storeWidget.storeInfrastructures,
          //   series[i]
          // )

          // return <Bar key={label} dataKey={label} fill={color} />
        })}
      </BarChart>
    )
  }

  const content = renderContent()

  return (
    <CustomWidgetContainer
      data-name="WidgetBar"
      labelledBy={`widget-id-${props.storeWidget.widgetEntity.id}`}
      flags={props.storeWidget.storeFlagsFetchWidgetData.flags}
      header={renderHeader()}
      footer={<WidgetFooterDirectories storeWidget={props.storeWidget} />}
      isMissingData={!isDefined(content)}
    >
      {content}
    </CustomWidgetContainer>
  )
}

export default observer(WidgetBarChart)
