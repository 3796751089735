import { LabelNonBreakable } from '@app/components-legacy/Label'
import type { EntityDirectory } from '@app/entities'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface IIoABoardCardRibbonProps {
  className?: string
  directoryEntity: EntityDirectory
}

const StyledLabelNonBreakable = styled(LabelNonBreakable)`
  white-space: nowrap;
  transform: translate(0px, 0%) rotate(180deg);
  color: ${consts.colorWhite};
  writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  direction: rtl;
`

const IoABoardCardRibbon: React.FC<IIoABoardCardRibbonProps> = props => {
  const { storeIoA } = useStores()
  const { storeInfrastructures } = storeIoA.storeBoard

  const infrastructureEntity =
    storeInfrastructures.getInfrastructureFromDirectoryId(
      props.directoryEntity.getPropertyAsNumber('id')
    )

  return (
    <div data-name="IoABoardCardRibbon" className={props.className}>
      <StyledLabelNonBreakable>
        {infrastructureEntity?.getPropertyAsString('name')}
      </StyledLabelNonBreakable>
    </div>
  )
}

export default styled(IoABoardCardRibbon)`
  background-color: ${props => props.directoryEntity.color};
  border-radius: 4px 0 0 4px;
  position: relative;
  width: 20px;
  height: 100%;
  justify-content: right;
  align-items: right;
  padding-top: 14px;
`
