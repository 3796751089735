import {
  ContainerFlex,
  ContainerFooter,
  ContainerIcon,
  ContainerRbac
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { IconCheck } from '@app/components-legacy/Icon'
import {
  IconDeleteOutlined,
  IconEditOutlined,
  IconInfoCircleOutlined,
  IconMailOutlined
} from '@app/components-legacy/Icon/IconAntd'
import IconBlank from '@app/components-legacy/Icon/IconBlank'
import { LabelDirectories } from '@app/components-legacy/Label'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import {
  WidgetListActionButtons,
  WidgetListActionsHeader,
  WidgetListCountItems,
  WidgetListPagination,
  WidgetListTable,
  WidgetListTableActionIcons
} from '@app/components-legacy/Widgets/WidgetList'
import type { EntitySyslog } from '@app/entities'
import type { IDataRowSyslog } from '@app/entities/EntitySyslog'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { getCriticityString } from '@libs/criticity'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onSyslogDeleteIconClick } from './DrawerDeleteSyslog/handlers'
import {
  onSyslogCreateButtonClick,
  onSyslogEditIconClick,
  onSyslogsListPageChange,
  onSyslogTestIconClick
} from './handlers'
import {
  canCreateSyslogAlerts,
  canDeleteSyslogAlert,
  canEditSyslogAlert,
  canReadSyslogAlerts
} from './permissions'

interface ISyslogsListProps {}

const SyslogsList: React.FC<ISyslogsListProps> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Configuration.SyslogAlerts',
      'Components.LabelChecker.ComplexityLevel'
    ]
  })

  const appRouter = useAppRouter()

  const { storeManagementSyslogs } = useStores()

  return (
    <>
      <ContainerFlex
        name="SyslogsList"
        direction="column"
        items={[
          <WidgetListActionsHeader>
            <WidgetListCountItems
              storeWidgetList={storeManagementSyslogs.storeWidgetList}
            />

            <WidgetListActionButtons>
              <ContainerRbac rbacCapabilityCheck={canCreateSyslogAlerts}>
                <FormWrapperButton
                  labelledBy="addSyslogAlert"
                  buttonProps={{
                    type: 'primary',
                    onClick: onSyslogCreateButtonClick(appRouter)(
                      storeManagementSyslogs
                    )
                  }}
                >
                  {translate('Add a SYSLOG alert')}
                </FormWrapperButton>
              </ContainerRbac>
            </WidgetListActionButtons>
          </WidgetListActionsHeader>,

          <WidgetListTable<EntitySyslog, IDataRowSyslog>
            translate={translate}
            columnWidths={{
              id: 'ID'
            }}
            storeWidgetList={storeManagementSyslogs.storeWidgetList}
            cellsRenderFn={{
              tls: status => {
                return <IconCheck status={status} />
              },
              description: value => {
                // if no descriptions, no tooltip
                if (!value) {
                  return null
                }

                return (
                  <Tooltip title={value}>
                    <IconInfoCircleOutlined />
                  </Tooltip>
                )
              },
              criticityThreshold: value => {
                return <div>{translate(getCriticityString(value))}</div>
              },
              directories: directoryIds => {
                return <LabelDirectories directoryIds={directoryIds} />
              }
            }}
            actionsButtonsRenderFn={syslogId => {
              return (
                <WidgetListTableActionIcons
                  icons={[
                    <ContainerRbac
                      rbacCapabilityCheck={canReadSyslogAlerts(
                        Number(syslogId)
                      )}
                      render={({ isGranted }) => {
                        return isGranted ? (
                          <ContainerIcon
                            labelledBy="testSyslog"
                            iconComponent={IconMailOutlined}
                            iconProps={{
                              type: 'mail',
                              onClick: onSyslogTestIconClick(
                                storeManagementSyslogs
                              )(Number(syslogId))
                            }}
                          />
                        ) : (
                          <IconBlank />
                        )
                      }}
                    />,

                    <ContainerRbac
                      rbacCapabilityCheck={canEditSyslogAlert(Number(syslogId))}
                      render={({ isGranted }) => {
                        return isGranted ? (
                          <ContainerIcon
                            labelledBy="editSyslog"
                            iconComponent={IconEditOutlined}
                            iconProps={{
                              type: 'edit',
                              onClick: onSyslogEditIconClick(appRouter)(
                                Number(syslogId)
                              )
                            }}
                          />
                        ) : (
                          <IconBlank />
                        )
                      }}
                    />,

                    <ContainerRbac
                      rbacCapabilityCheck={canDeleteSyslogAlert(
                        Number(syslogId)
                      )}
                      render={({ isGranted }) => {
                        return isGranted ? (
                          <ContainerIcon
                            labelledBy="deleteSyslog"
                            iconComponent={IconDeleteOutlined}
                            iconProps={{
                              type: 'delete',
                              onClick: onSyslogDeleteIconClick(
                                storeManagementSyslogs
                              )(Number(syslogId))
                            }}
                          />
                        ) : (
                          <IconBlank />
                        )
                      }}
                    />
                  ]}
                />
              )
            }}
          />
        ]}
        spaced
      />

      <Portal name={PlaceHolderName.bladeFooter}>
        <ContainerFooter>
          <ContainerFlex
            name="Pagination"
            justifyContent="flex-end"
            items={[
              <WidgetListPagination
                storeWidgetList={storeManagementSyslogs.storeWidgetList}
                onChange={onSyslogsListPageChange(storeManagementSyslogs)}
              />
            ]}
          />
        </ContainerFooter>
      </Portal>
    </>
  )
}

export default observer(SyslogsList)
