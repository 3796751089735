import { ContainerFlex } from '@app/components/Container'
import { IconSearch } from '@app/components/Icon'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import InputSearch from '@app/components-legacy/Input/InputSearch'
import { useAppTranslator, useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { handleIdentitiesOnSearch } from './handlers'

export interface ISearchBarProps {
  className?: string
  style?: React.CSSProperties
}

const StyledInputSearch = styled(InputSearch)`
  min-width: 500px;
`

const SearchBar: React.FC<ISearchBarProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['IdentityExplorer.SearchBar']
  })

  const { storeIdentityExplorer } = useStores()

  if (storeIdentityExplorer.storeFlagsFetchIdentities.isError) {
    return null
  }

  return (
    <ContainerFlex
      name="SearchBar"
      flexGap="small"
      items={[
        <StyledInputSearch
          storeInputSearch={storeIdentityExplorer.storeInputSearch}
          placeholder={translate('Search for an identity')}
          onValidate={handleIdentitiesOnSearch(storeIdentityExplorer)}
          onReset={handleIdentitiesOnSearch(storeIdentityExplorer)}
        />,

        <FormWrapperButton
          className={props.className}
          style={props.style}
          labelledBy="searchNode"
          variant={ButtonVariant.primary}
          size={ButtonSize.small}
          icon={IconSearch}
          iconSize={20}
          buttonProps={{
            onClick: handleIdentitiesOnSearch(storeIdentityExplorer),
            disabled: !storeIdentityExplorer.storeInputSearch.hasSearchValue
          }}
        />
      ]}
    />
  )
}

export default observer(SearchBar)
