/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum ProviderType {
  Okta = 'OKTA',
  AzureActiveDirectory = 'AZURE_ACTIVE_DIRECTORY',
  ActiveDirectory = 'ACTIVE_DIRECTORY',
  GoogleCloudPlatform = 'GOOGLE_CLOUD_PLATFORM',
  Github = 'GITHUB'
}

export function ProviderTypeFromJSON(json: any): ProviderType {
  return ProviderTypeFromJSONTyped(json, false)
}

export function ProviderTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProviderType {
  return json as ProviderType
}

export function ProviderTypeToJSON(value?: ProviderType | null): any {
  return value as any
}
