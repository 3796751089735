import type { StoreBlades, StoreManagementRbacRoles } from '@app/stores'
import { If } from '@libs/fp-helpers/If'
import { isDefined } from '@libs/isDefined'
import {
  computeRbacPermissions,
  sanitizeRbacPermissions
} from '@libs/rbac/functions'
import type { RbacEntityItemType } from '@libs/rbac/types'
import { RbacRoleFormFieldName } from '@libs/rbac/types'
import type {
  InputEditRbacRole,
  RbacAction,
  RbacEntityName
} from '@server/graphql/typeDefs/types'
import type { MenuProps } from 'rc-menu/lib/Menu'

/**
 * Edit the permissions of a role.
 */
const editRole =
  (storeManagementRbacRoles: StoreManagementRbacRoles) =>
  (
    roleId: number,
    options?: {
      applyOnly: boolean
    }
  ): Promise<any> => {
    const { storeForm } = storeManagementRbacRoles

    if (!storeForm.validate()) {
      return Promise.reject()
    }

    const rbacPermissions = sanitizeRbacPermissions(
      storeManagementRbacRoles.pendingRbacPermissions
    )
      .map(entityRbacPermission => {
        const entityIds = entityRbacPermission.entityIds

        // empty entityIds means that we can remove the permission from the role
        if (Array.isArray(entityIds) && entityIds.length === 0) {
          return null
        }

        return {
          entityName:
            entityRbacPermission.getPropertyAsT<RbacEntityName>('entityName'),
          action: entityRbacPermission.getPropertyAsT<RbacAction>('action'),
          entityIds
        }
      })
      .filter(isDefined)

    const role: InputEditRbacRole = {
      id: roleId,
      name: storeForm.getFieldValueAsString(RbacRoleFormFieldName.name),
      description: storeForm.getFieldValueAsString(
        RbacRoleFormFieldName.description
      )
    }

    const isAdmin = roleId !== 1
    // cannot edit admin permissions
    if (isAdmin) {
      role.permissions = rbacPermissions
    }

    return storeManagementRbacRoles.editRole(role, options)
  }

/**
 * Fetch roles and permissions of the current roles and init
 * form and pending permissions.
 */
export const handleEditRoleOnLoad =
  (storeManagementRbacRoles: StoreManagementRbacRoles) =>
  (roleId: number) =>
  () => {
    storeManagementRbacRoles.setCurrentRole(roleId)

    If(!storeManagementRbacRoles.rbacRoles.size)
      .fetch(() => storeManagementRbacRoles.fetchRbacRoles())
      .then(() => storeManagementRbacRoles.fetchRbacEntities())
      .then(() => {
        const role = storeManagementRbacRoles.rbacRoles.get(roleId)

        if (!role) {
          return
        }

        storeManagementRbacRoles.storeForm
          .setDefaultFieldsValues([
            {
              key: RbacRoleFormFieldName.name,
              value: role.getPropertyAsString(RbacRoleFormFieldName.name)
            },
            {
              key: RbacRoleFormFieldName.description,
              value: role.getPropertyAsString(RbacRoleFormFieldName.description)
            }
          ])
          .reset()

        const rbacPermissions = computeRbacPermissions([role])

        storeManagementRbacRoles.initPendingRbacPermissions(rbacPermissions)
      })
  }

/**
 * Reset some properties of the store when leaving the role edition.
 */
export const handleEditRoleOnUnload =
  (storeManagementRbacRoles: StoreManagementRbacRoles) => () => {
    storeManagementRbacRoles.resetRoleEdition()
  }

/**
 * Select a different type of entity item.
 */
export const handleNavigationEntityItemTypeOnClick =
  (storeManagementRbacRoles: StoreManagementRbacRoles): MenuProps['onClick'] =>
  param => {
    storeManagementRbacRoles.storeMenu.selectEntry(
      param.key as RbacEntityItemType
    )
  }

/**
 * Edit a role by replacing all permissions for this role.
 */
export const handleEditRoleApplyOnSubmit =
  (storeManagementRbacRoles: StoreManagementRbacRoles) =>
  (roleId: number) =>
  (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    editRole(storeManagementRbacRoles)(roleId, { applyOnly: true }).catch(
      () => {
        // Already handed in the store
      }
    )
  }

export const handleEditRoleOnSubmit =
  (
    storeBlades: StoreBlades,
    storeManagementRbacRoles: StoreManagementRbacRoles
  ) =>
  (roleId: number) =>
  () => {
    editRole(storeManagementRbacRoles)(roleId)
      // Disable the "Apply and close" button to prevent the user from triggering it a second time juste before closing the panel
      .then(() => storeManagementRbacRoles.storeEditRbacRoleFlags.forbidden())
      .then(() => storeBlades.closeLastBlade())
      .catch(() => {
        // Already handed in the store
      })
  }

/**
 * Close the edition role blade.
 */
export const handleRoleEditionCancelOnClick =
  (storeBlades: StoreBlades) => () => {
    storeBlades.closeLastBlade()
  }
