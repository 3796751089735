import { BladeCancelButton } from '@app/components-legacy/Blade'
import {
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onProfileCreateSubmit } from './handlers'
import ProfileCreateFormMainFields from './ProfileCreateFormMainFields'

interface IProfileCreateFormProps {}

const ProfileCreateForm: React.FC<IProfileCreateFormProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.Accounts.Profiles.Creation']
  })

  const appRouter = useAppRouter()

  const { storeBlades, storeManagementProfiles } = useStores()

  return (
    <FormWrapper
      name="profileCreation"
      onSubmit={onProfileCreateSubmit(
        storeManagementProfiles,
        storeBlades,
        appRouter
      )}
    >
      <>
        <ContainerFlex
          name={ProfileCreateForm.name}
          direction="column"
          items={[<ProfileCreateFormMainFields />]}
          spaced
        />

        <Portal name={PlaceHolderName.bladeFooter}>
          <ContainerFooter>
            <BladeCancelButton />

            <FormWrapperButtonSubmit type="primary">
              {translate('Create')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </>
    </FormWrapper>
  )
}

export default observer(ProfileCreateForm)
