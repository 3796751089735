import type { Maybe } from '@@types/helpers'
import { LabelAlt, LabelValue } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '../Container'

const ToolIcon = styled.div`
  font-size: 2em;
  line-height: 45px;
  width: 50px;
  height: 50px;
  background: ${consts.colorBlueGray020};
  text-align: center;
  border: 1px solid ${consts.colorBlueGray015};
`

export interface ILabelAttackerKnownToolProps {
  name?: string
  url?: Maybe<string>
  author?: Maybe<string>
}

const LabelAttackerKnownTool: React.FC<
  ILabelAttackerKnownToolProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.LabelAttackerKnownTool']
  })

  const getName = () => {
    if (props.url) {
      return <a href={props.url}>{props.name}</a>
    }

    return <span>{props.name}</span>
  }

  const getItems = () => {
    const items = [
      <LabelValue fallbackValue={translate('Unnamed tool')}>
        {getName()}
      </LabelValue>
    ]

    if (props.author) {
      items.push(<LabelAlt>{props.author}</LabelAlt>)
    }

    return items
  }

  return (
    <ContainerFlex
      name="LabelAttackerKnownTool"
      alignItems="center"
      items={[
        <ToolIcon />,
        <ContainerFlex
          name="LabelAttackerKnownToolInfos"
          direction="column"
          items={getItems()}
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default LabelAttackerKnownTool
