import type { ReactComponent } from '@@types/helpers'
import type { IconComponentProps } from '@ant-design/icons/lib/components/AntdIcon'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import * as React from 'react'
import { ContainerFlex } from '../Container'

export interface ILinkButtonProps {
  iconComponent: ReactComponent
  iconProps?: Omit<IconComponentProps, 'icon'>
  label: string
  onClick?: () => void
  labelledBy: string
}

const LinkButton: React.FC<ILinkButtonProps> = props => {
  const { testAttributeProps } = useTestAttribute('form')

  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()

    if (props.onClick) {
      props.onClick()
    }
  }

  return (
    <a onClick={onClick} {...testAttributeProps('button')(props.labelledBy)}>
      <ContainerFlex
        name="ButtonLink"
        alignItems="center"
        items={[
          <props.iconComponent {...props.iconProps} />,
          <span>{props.label}</span>
        ]}
        spaceWidth="verySmall"
        spaced
      />
    </a>
  )
}

export default LinkButton
