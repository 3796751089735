import {
  WIDGET_DURATION_1_DAY,
  WIDGET_DURATION_1_HOUR
} from '@app/stores/Dashboard/consts'
import { DateFormat } from '@libs/dates/formatDate'
import { Language } from '@server/graphql/typeDefs/types'
import * as moment from 'moment'

/**
 * Format an UNIX date to a string according to the duration or a defined string format.
 *
 * Usage:
 *
 * const dateFormatter = getDateFormatter(30 * 24 * 3600)
 * const dateStr = dateFormatter(<unix_timestamp>)
 */
export function getDateFormatter(
  language: Language,
  duration: number,
  options?: {
    dateFormat?: DateFormat
    utc?: boolean
  }
) {
  let dateFormat = options?.dateFormat

  if (!dateFormat) {
    switch (duration) {
      case WIDGET_DURATION_1_HOUR:
        dateFormat = DateFormat.hoursMinutesSeconds
        break

      case WIDGET_DURATION_1_DAY:
        dateFormat = DateFormat.hoursMinutes
        break

      default:
        dateFormat =
          language === Language.EnUs
            ? DateFormat.englishShortDate
            : DateFormat.frenchShortDate
        break
    }
  }

  return (unixTimestamp: number | string): string => {
    moment.locale(language)

    const m = moment(Number(unixTimestamp))

    if (options?.utc === true) {
      m.utc()
    }

    const date =
      dateFormat === DateFormat.iso
        ? m.toDate().toISOString()
        : m.format(dateFormat)

    return date.trim()
  }
}
