import { useTestAttribute } from '@app/hooks/useTestAttribute'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import type { FieldName } from '@app/stores/helpers/StoreForm/types'
import { Input } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import type { UserFormVersion } from './UserCommonForm'

interface IUserCommonFormPasswordProps {
  fieldName: FieldName
  id?: string
  version: UserFormVersion
  storeForm: StoreForm<any>
}

const UserCommonFormPassword: React.FC<
  IUserCommonFormPasswordProps
> = props => {
  const { testAttributeProps } = useTestAttribute('form')

  return (
    <Input.Password
      id={props.id}
      name={props.fieldName}
      onChange={onInputChange(props.storeForm)(props.fieldName)}
      value={props.storeForm.getFieldValueAsString(props.fieldName)}
      autoComplete="new-password"
      visibilityToggle
      {...testAttributeProps('textbox')(props.fieldName)}
    />
  )
}

export default observer(UserCommonFormPassword)
