import { buildVariants } from '@design-system/libs/buildVariants'
import React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from 'tenable-design-system-alt'
import Header from './Header'

interface ILayoutPublicProps {
  content: React.ReactNode
}

const StyledContainerFlex = styled(ContainerFlex)(props => {
  return buildVariants(props)
    .css({
      backgroundColor: props.theme.tokens['layout/body/background'],
      color: props.theme.tokens['layout/color'],
      height: `calc(100% - ${props.theme.tokens['layout/tul/height']}px)`
    })
    .end()
})

export interface IAppProps {}

export default function LayoutPublic(props: ILayoutPublicProps) {
  return (
    <StyledContainerFlex
      name="LayoutPublic"
      flexDirection="column"
      flexAlignItems="center"
      flexGrow={1}
    >
      <Header />

      <ContainerFlex
        name="LayoutPublicVerticalAlign"
        flexDirection="column"
        flexJustifyContent="center"
        flexAlignItems="center"
        flexGrow={1}
      >
        {props.content}
      </ContainerFlex>
    </StyledContainerFlex>
  )
}
