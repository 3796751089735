import { ContainerFlex } from '@app/components-legacy/Container'
import { IconSVG } from '@app/components-legacy/Icon'
import { consts } from '@app/styles'
import { compareStringsAlphabetically } from '@libs/compareStringsAlphabetically'
import type { AttackPathRelationType } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'

const DefaultIconContainer = styled.div``

const HoverIconContainer = styled.div``

export interface IRelationTypesIconsProps {
  className?: string
  labelledBy?: string
  relationTypes: AttackPathRelationType[]
  size?: number
}

const RelationTypesIcons: React.FC<IRelationTypesIconsProps> = props => {
  if (!props.relationTypes) {
    return null
  }

  return (
    <ContainerFlex
      className={props.className}
      name="DrawerNodeRelationshipsNodeRelations"
      labelledBy={props.labelledBy}
      items={props.relationTypes
        .sort(compareStringsAlphabetically)
        .map(relationType => (
          <>
            <DefaultIconContainer>
              <IconSVG
                iconName={`attackpath/${relationType}`}
                size={props.size}
              />
            </DefaultIconContainer>
            <HoverIconContainer>
              <IconSVG
                iconName={`attackpath/${relationType}-hover`}
                size={props.size}
              />
            </HoverIconContainer>
          </>
        ))}
    />
  )
}

export default styled(RelationTypesIcons)`
  display: inline-flex;
  background-color: ${consts.colorAttackPathPrimary};
  border-radius: ${consts.borderRadiusLargeRedesign};
  line-height: normal;

  &:hover {
    background-color: ${consts.colorAttackPathSecondary};
  }

  ${HoverIconContainer} {
    display: none;
  }

  &:hover ${DefaultIconContainer} {
    display: none;
  }

  &:hover ${HoverIconContainer} {
    display: block;
  }
`
