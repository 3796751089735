import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  font-weight: 600;
  color: ${consts.colorBlueGray015};
`

interface IBladeHeaderValueTypeProps {
  className?: string
  rawType: string
  type: string
}

function BladeHeaderValueType(props: IBladeHeaderValueTypeProps) {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  if (themeIsLegacy) {
    return <StyledDiv className={props.className}>{props.type}</StyledDiv>
  }

  return (
    <Label variant={LabelVariant.h3} labelledBy={`${props.rawType}-title`}>
      {props.type}
    </Label>
  )
}

export default BladeHeaderValueType
