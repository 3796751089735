import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import type { AppRouterClient } from '@libs/Router/types'

/**
 * When clicking on a navigation entry, update the url of the current blade
 * to be able to go back on the correct blade when closing the sub blade,
 * and push the url into the history.
 */
export const onNavigationEntryClick =
  (appRouter: AppRouterClient) =>
  (navigationEntry: IMenuEntry<any>) =>
  (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!navigationEntry.routeDefinition) {
      return
    }

    // handle ctrl+click to open the link in a new window
    if (e.ctrlKey) {
      return
    }

    e.preventDefault()

    const entryUrl = appRouter.makeRouteInfosPathname(
      navigationEntry.routeDefinition
    )

    appRouter.history.push(entryUrl)
  }
