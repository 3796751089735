import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'

interface IIconAttackPathAnalysisProps extends IIconProps {}

const IconAttackPathAnalysis: React.FC<
  IIconAttackPathAnalysisProps
> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.125 9.375C3.125 6.81237 4.6673 4.60996 6.87431 3.64536M11.875 16.875C8.8547 16.875 6.33477 14.7326 5.75198 11.8846M17.5 5.625H7.5C7.15482 5.625 6.875 5.34518 6.875 5V2.5C6.875 2.15482 7.15482 1.875 7.5 1.875H17.5C17.8452 1.875 18.125 2.15482 18.125 2.5V5C18.125 5.34518 17.8452 5.625 17.5 5.625ZM10 11.875H1.25C0.904822 11.875 0.625 11.5952 0.625 11.25V10C0.625 9.65482 0.904822 9.375 1.25 9.375H10C10.3452 9.375 10.625 9.65482 10.625 10V11.25C10.625 11.5952 10.3452 11.875 10 11.875ZM17.5 18.125H12.5C12.1548 18.125 11.875 17.8452 11.875 17.5V16.25C11.875 15.9048 12.1548 15.625 12.5 15.625H17.5C17.8452 15.625 18.125 15.9048 18.125 16.25V17.5C18.125 17.8452 17.8452 18.125 17.5 18.125Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconAttackPathAnalysis
