import type { IDataRowRbacRole } from '@app/entities/EntityRbacRole'
import type { StoreManagementRbacRoles } from '@app/stores'

/**
 * Delete the role, close the drawer, reload roles.
 */
export const onDeleteRoleSubmit =
  (
    storeManagementRbacRoles: StoreManagementRbacRoles,
    dataRow: IDataRowRbacRole
  ) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    storeManagementRbacRoles
      .deleteRole(dataRow.id, dataRow.name)
      .then(() => {
        storeManagementRbacRoles.storeDrawerDeleteRole.closeDrawer()
        return storeManagementRbacRoles.fetchRbacRoles()
      })
      .catch(() => {
        // already catched in the store
      })
  }

/**
 * Close the delete role drawer.
 */
export const onDeleteDrawerCloseClick =
  (storeManagementRbacRoles: StoreManagementRbacRoles) => () => {
    storeManagementRbacRoles.storeDrawerDeleteRole.closeDrawer()
  }
