import { useStores } from '@app/hooks/useStores'
import * as React from 'react'

interface IRegisterProps {
  children: React.ReactElement
}

const Register: React.FC<IRegisterProps> = props => {
  const { storeAPIInspector } = useStores()

  React.useEffect(() => {
    storeAPIInspector.registerWS()

    return () => {
      storeAPIInspector.unregisterWS()
    }
  }, [])

  return props.children
}

export default Register
