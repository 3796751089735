import * as React from 'react'
import { ContainerFlex } from '.'
import type { IContainerFlexProps } from './ContainerFlex/types'

interface IContainerHVCenteredProps {
  className?: string
  style?: React.CSSProperties
  containerFlexProps?: IContainerFlexProps
  children?: React.ReactNode
}

const ContainerHVCentered: React.FC<IContainerHVCenteredProps> = props => {
  return (
    <ContainerFlex
      name="ContainerHVCenteredContainer"
      className={props.className}
      style={props.style}
      justifyContent="center"
      alignItems="center"
      items={[
        <ContainerFlex
          name="ContainerHVCenteredContainerItems"
          direction="column"
          alignItems="center"
          items={props.children}
          fullWidth
        />
      ]}
      fullHeight
      {...props.containerFlexProps}
    />
  )
}

export default ContainerHVCentered
