import { ContainerFlex } from '@app/components/Container'
import { LabelColorTag } from '@app/components/Labels'
import type { IContainerSimpleProps } from '@app/components-legacy/Container/ContainerSimple'
import { IconSVG } from '@app/components-legacy/Icon'
import type { IConfigurationChangedItem } from '@app/entities/EntityUserLog'
import { ConfigurationType } from '@app/entities/EntityUserLog'
import { consts } from '@app/styles'
import React from 'react'
import styled from 'styled-components'
import {
  colorActivityLogsLogAlternateBackgroundRedesign,
  colorUserLogIoAAttackTypeRedesign
} from '../../consts'
import { renderMultipleValues } from '../../utils'

interface IUserLogUserCreatedItem extends IContainerSimpleProps {
  changedItem: IConfigurationChangedItem
}

const StyledAction = styled(ContainerFlex)`
  border-radius: ${consts.borderRadiusRedesign};
  background-color: ${colorActivityLogsLogAlternateBackgroundRedesign};
  padding: ${consts.paddingVerySmall} ${consts.paddingDefault};
`

const StyledIcon = styled(IconSVG)`
  transform: rotateY(180deg);
  margin-right: ${consts.marginSmall};
`

const UserLogUserCreatedItem = (props: IUserLogUserCreatedItem) => {
  const { changedItem } = props

  const getItemLabel = (): string => {
    if (changedItem.fieldType === ConfigurationType.userRole) {
      return 'Role'
    }

    return String(changedItem.field)
  }

  return (
    <ContainerFlex
      name={UserLogUserCreatedItem.name}
      className={props.className}
      ariaRoles={['list', 'row']}
      labelledBy="createdUserSubLine"
      alignItems="center"
      items={[
        <StyledIcon iconName="activityLogs/backline" size={20} />,
        <StyledAction
          name="ChangedItemAction"
          flexWrap="wrap"
          items={[
            <LabelColorTag
              color={colorUserLogIoAAttackTypeRedesign.background}
              labelProperties={{
                color: colorUserLogIoAAttackTypeRedesign.text
              }}
            >
              {getItemLabel()}
            </LabelColorTag>,
            ...renderMultipleValues(
              'neutral',
              String(changedItem.field),
              changedItem.after,
              changedItem.fieldType
            )
          ]}
        />
      ]}
    />
  )
}

export default styled(UserLogUserCreatedItem)`
  margin-top: 1px;
  margin-bottom: 1px;
`
