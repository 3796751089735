import { IconUp } from '@app/components/Icon'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { formatNumberTo4CharactersMax } from '@libs/numbers/helpers'
import { AttackPathDirection } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

const VerticalLine = styled.hr`
  height: 100%;
  width: 1px;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 1px solid ${consts.colorAttackPathPrimary};
  margin: 0;
`

const RelationshipsCount = styled.div`
  position: absolute;
  left: 2px;
  top: 11px;
  min-width: 33px;
  height: 18px;
  padding: 0 6px;
  text-align: center;
  background-color: ${consts.colorAttackPathArrow};
  border-radius: ${consts.borderRadiusLargeRedesign};
  font-size: 11px;
  color: ${consts.colorWhite};
  transform: translateX(-50%);
`

const StyledIconUp = styled(IconUp)`
  position: absolute;
  top: -4px;
  left: -4.5px;
`

export interface IDrawerNodeRelationshipsVerticalLineProps {
  className?: string
}

const DrawerNodeRelationshipsVerticalLine: React.FC<
  IDrawerNodeRelationshipsVerticalLineProps
> = props => {
  const { storeAttackPath } = useStores()

  return (
    <div className={props.className}>
      {storeAttackPath.direction === AttackPathDirection.To && (
        <StyledIconUp color={consts.colorAttackPathArrow} />
      )}
      <VerticalLine />
      <RelationshipsCount>
        {formatNumberTo4CharactersMax(
          storeAttackPath.filteredDrawerChildrenNodesWithEdge.length
        )}
      </RelationshipsCount>
    </div>
  )
}

const ObservedDrawerNodeRelationshipsVerticalLine = observer(
  DrawerNodeRelationshipsVerticalLine
)

export default styled(ObservedDrawerNodeRelationshipsVerticalLine)`
  position: relative;
  margin-left: 23px;
`
