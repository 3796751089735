import type { Maybe } from '@@types/helpers'
import type { IContainerFlexProps } from '@app/components/Container/ContainerFlex/types'
import { BladeContext } from '@app/components-legacy/Blade/context'
import { generateHashFromString } from '@app/components-legacy/Blade/utils'
import { useAppRouter, useAppTranslator } from '@app/hooks'
import { useLoader } from '@app/hooks/useLoader'
import { useStores } from '@app/hooks/useStores'
import type { ITranslateOptionsInterpolations } from '@libs/i18n'
import type { RbacCapabilityCheck } from '@libs/rbac/types'
import type { IRouteDefinition } from '@libs/Router/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import * as uuid from 'uuid'
import type { IComponentLoaderProps } from '../types'
import { BladeContent } from './BladeContent'
import type { BladeLayout } from './types'
import { BladeContentVariant } from './types'

interface IBladeProps extends IComponentLoaderProps {
  className?: string
  root?: boolean
  uuidFromString?: string
  routeDefinition: IRouteDefinition<any>
  contentProps?: {
    variant?: BladeContentVariant
    handleScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void
  }
  layout: BladeLayout
  rbacCapabilityCheck: RbacCapabilityCheck
  titleInterpolations?: ITranslateOptionsInterpolations
  scrollChildrenContainerOverflow?: React.CSSProperties['overflow']
  scrollChildrenContainerBoxShadow?: React.CSSProperties['boxShadow']
  paddingH?: Maybe<IContainerFlexProps['paddingH']>
  paddingV?: Maybe<IContainerFlexProps['paddingV']>
  layoutContentGreyBackground?: boolean
}

/**
 * Blade container.
 *
 * Usage example:
 *
 * <Blade
 *    url={appRouter.makeRouteInfosPathname({
 *      routeName: AppRouteName.Management.Accounts.Roles.Edit,
 *      parameters: {
 *        roleId
 *      }
 *    })}
 *    title={translate('Edit a role')}
 *    onLoad={onEditRoleLoad(storeManagementRbacRoles)(Number(roleId))}
 *    onUnload={onEditRoleUnload(storeManagementRbacRoles)}
 *    flags={editRolesFlags}
 *    header={<MyBladeNavigation />}
 *    content={<MyBladeContent />}
 *    footer={<MyBladeFooter />}
 *    rbacCapabilityCheck={editRole(Number(roleId))}
 *  />
 */

const Blade: React.FC<IBladeProps> = props => {
  useLoader(props)

  const { storeBlades } = useStores()
  const appRouter = useAppRouter()
  const translate = useAppTranslator({
    namespaces: ['RouteNames.Pages']
  })

  const bladeUuid = React.useMemo<string>(() => {
    return props.uuidFromString
      ? generateHashFromString(props.uuidFromString)
      : uuid.v4()
  }, [props.uuidFromString])

  const title = translate(
    appRouter.getRouteSpecs(props.routeDefinition.routeName).routeName,
    props.titleInterpolations
  )

  /**
   * Create and remove blades at mount/unmount.
   */
  React.useEffect(() => {
    if (props.root) {
      storeBlades.reset()
    }

    storeBlades.addBlade(bladeUuid, {
      title,
      url: appRouter.makeRouteInfosPathname(props.routeDefinition)
    })

    return () => {
      storeBlades.removeBlade(bladeUuid)
    }
  }, [bladeUuid])

  const blade = storeBlades.getBlade(bladeUuid)

  if (!blade) {
    return null
  }

  return (
    <BladeContext.Provider
      value={{
        entityBlade: blade,
        bladeContentProps: {
          variant: props.contentProps?.variant ?? BladeContentVariant.normal,
          handleScroll: props.contentProps?.handleScroll,
          layoutContentGreyBackground: props.layoutContentGreyBackground
        }
      }}
    >
      <BladeContent
        data-name="Blade"
        labelledBy={title}
        className={props.className}
        uuid={blade.uuid}
        level={blade.level || 0}
        inPosition={storeBlades.isInPosition(bladeUuid)}
        flags={props.flags}
        layout={props.layout}
        rbacCapabilityCheck={props.rbacCapabilityCheck}
        scrollChildrenContainerOverflow={props.scrollChildrenContainerOverflow}
        scrollChildrenContainerBoxShadow={
          props.scrollChildrenContainerBoxShadow
        }
        paddingH={props.paddingH}
        paddingV={props.paddingV}
      />
    </BladeContext.Provider>
  )
}

export default observer(Blade)
