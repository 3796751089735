import type { PropertiesNullable } from '@@types/helpers'
import type {
  Credential,
  ProviderType,
  Tenant
} from '@libs/openapi/service-identity-core'
import type { Maybe } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityCredential
  extends EntityBase
  implements PropertiesNullable<Credential>
{
  uuid: Maybe<string> = null
  providerType: Maybe<ProviderType> = null
  name: Maybe<string> = null
  tenantUuid: Maybe<string> = null

  constructor(data: Partial<Tenant>) {
    super()
    Object.assign(this, data)
  }
}
