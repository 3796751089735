import type { DateFormat } from '@libs/dates/formatDate'
import { formatDate } from '@libs/dates/formatDate'

const isEven = (n: number) => n % 2 === 0

export const getDate = (date: Date | string, format: DateFormat): string => {
  return formatDate(date, {
    format
  })
}

const getSddl = (value: string) => {
  const parsedValues = JSON.parse(value)
  const items = parsedValues.map(
    (parsedValue: {
      Item1: string
      Item2: string
      Item3: string
      Item4: Array<{ Item1: string; Item2: string }>
    }) => {
      const md =
        `${parsedValue.Item2} (${parsedValue.Item3})\n` +
        parsedValue.Item4.map(
          ({ Item1, Item2 }) => `- ${Item1} ${Item2}\n`
        ).join('')

      return md
    }
  )
  return items.join('')
}

/**
 * Duplicate about 85% of the logic used on ContainerHTMLContentExtended, we need this method to render the description of a deviance
 * when the user click on copy to clipboard on a deviance card, without html and jsx, but just a string.
 * TODO : Let's rethink about a better solution.
 */
export const getElements = (description: string, format: DateFormat) => {
  const parts = description.split(/(\[\w+].*?\[\/\w+])/g)

  return parts
    .map((part, i) => {
      if (!isEven(i)) {
        const matchResults = new RegExp(/\[(\w+)](.*?)\[\/\w+]/).exec(part)

        if (!matchResults) {
          return null
        }

        const [_, type, value] = matchResults

        switch (type) {
          case 'date':
            return getDate(value, format)
          case 'sddl': {
            return getSddl(value)
          }
        }
      }

      return part
    })
    .join('')
}
