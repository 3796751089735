import { Parser } from '@libs/Expression/parser/Parser'
import { ParseResult } from '@libs/Expression/parser/ParseResult'
import { escapeRegExp, trimStart } from 'lodash'

/**
 * Returns a new parser that, once called with an input, will see if input starts with
 * the string passed as parameter.
 *
 * Example :
 *  const stringParser: Parser<string> = stringEqualsTo("123")
 *  parser.parse("12312345") will
 *   - Successfully returns "123" as parsed value and "12345" as remaining input
 *  parser.parse("456789") will
 *   - Fails and returns "456789" as remaining input
 *
 *
 * @param s : the string to be tested against the input when parse will be called
 * @param ignoringCase : true if the comparison should be case sensitive, false otherwise
 */
export function stringEqualsTo(
  s: string,
  ignoringCase?: boolean
): Parser<string> {
  const realIgnoringCase = ignoringCase === undefined ? false : ignoringCase

  return Parser.strict(
    `StringEqualsTo(${s}, ignoringCase:${realIgnoringCase})`,
    ({ text, parserName, parsersHistory }) => {
      function charsAreNotTheSameAt(pos: number) {
        return realIgnoringCase
          ? s.charAt(pos).toLowerCase() !== text.charAt(pos).toLowerCase()
          : s.charAt(pos) !== text.charAt(pos)
      }

      function endIndexFor(pos: number) {
        // No need to decrease the currentPosition index
        // if we have not been in the for loop
        return pos === 0 ? pos : pos - 1
      }

      let currentPos: number
      for (currentPos = 0; currentPos < s.length; currentPos++) {
        if (charsAreNotTheSameAt(currentPos)) {
          return ParseResult.failure({
            parsersHistory,
            errorMsg: `Expected string '${s.charAt(
              currentPos
            )}' at pos:${currentPos}  in:'${text}'`,
            parserName,
            remaining: text // (currentPos === 0 ? text : Parser.computeRemaining(text, endIndexFor(currentPos)))
          })
        }
      }
      return ParseResult.success({
        parsersHistory,
        parsedValue: s,
        parserName,
        remaining: Parser.computeRemaining(text, endIndexFor(currentPos))
      })
    }
  )
}

/**
 * Returns a new parser that, once called with an input, will see if input starts with
 * string matching the regexp passed as parameter.
 *
 * Example :
 *  const stringParser: Parser<string> = stringMatching(/[0-9]+/g)
 *  parser.parse("12312345") will
 *   - Successfully returns "12312345" as parsed value and "" as remaining input
 *  parser.parse("az456789") will
 *   - Fails and returns "az456789" as remaining input
 *
 *
 * @param r : The regexp to be tested when parse will be called
 * @param trimmingLeftBefore : true if trim before testing the regexp false otherwise
 */
export function stringMatching(
  regexp: RegExp,
  trimmingLeftBefore: boolean = true
): Parser<string> {
  return Parser.strict(
    `StringMatch(${regexp})`,
    ({ text, parserName, parsersHistory }) => {
      text = trimmingLeftBefore ? trimStart(text) : text

      const matchResult = text.match(regexp)

      if (matchResult !== null) {
        const parsedValue = matchResult[0]
        if (text.startsWith(parsedValue)) {
          return ParseResult.success({
            parsersHistory,
            parsedValue,
            parserName,
            remaining: Parser.computeRemaining(text, parsedValue.length - 1)
          })
        }
      }

      return ParseResult.failure({
        parsersHistory,
        errorMsg: `The input does not start with regExp:'${regexp}' in:'${text}'`,
        parserName,
        remaining: text
      })
    }
  )
}

/**
 * Returns a new parser that, once called with an input, will see if input starts with
 * string matching 0 or many whiteSpaces char.
 * A whitespace character can be
 *    A space character
 *    A tab character
 *    A carriage return character
 *    A new line character
 *    A vertical tab character
 *    A form feed character
 */
export function zeroOrMoreSpaces(): Parser<string> {
  return Parser.strict('zeroOrMoreSpaces', input => {
    return stringMatching(/\s*/g).apply(input)
  })
}

/**
 * Returns a new parser that, once called with an input, will see if input is
 * surrounded by left and right value passed as parameter
 *
 * Example :
 *  const stringParser: Parser<string> = anyStringSurroundedBy("123", "789")
 *  parser.parse("123456789") will
 *   - Successfully returns "456" as parsed value and "" as remaining input
 *  parser.parse("az456789") will
 *   - Fails and returns "az456789" as remaining input
 *
 *
 *  const stringParser: Parser<string> = anyStringSurroundedBy("'") // right parameter missing
 *  parser.parse("'123456789'") will
 *   - Successfully returns "123456789" as parsed value and "" as remaining input
 *  parser.parse("az456789") will
 *   - Fails and returns "az456789" as remaining input
 *
 *
 * @param left : The string to be on the left of searched value
 * @param right : The string to be on the right of searched value
 *                If missing, the string on hte left will be used as
 *                right value.
 */
export function anyStringSurroundedBy(
  left: string,
  right?: string
): Parser<string> {
  const textOnLeft = left
  const textOnRight = right === undefined ? textOnLeft : right

  return Parser.strict(
    `AnyStringSurroundedBy(${textOnLeft}, ${textOnRight})`,
    ({ text, parserName, parsersHistory }) => {
      for (let position = 0; position < textOnLeft.length; position++) {
        if (textOnLeft.charAt(position) !== text.charAt(position)) {
          return ParseResult.failure({
            parsersHistory,
            errorMsg:
              `Input does not start with see surrounding left value` +
              `'${textOnLeft}'. Expected string '${textOnLeft.charAt(
                position
              )}' at pos:${position}"`,
            parserName,
            remaining: Parser.computeRemaining(text, position)
          })
        }
      }

      // We have to match LEFT-VALUE-RIGHT
      const valueStartIndex = textOnLeft.length
      const valueEndIndex = text.indexOf(textOnRight, valueStartIndex)

      if (valueEndIndex > -1) {
        const parsedValue = text.substring(valueStartIndex, valueEndIndex)
        const position = valueEndIndex + textOnRight.length
        return ParseResult.success({
          parsersHistory,
          parsedValue,
          parserName,
          remaining: Parser.computeRemaining(text, position - 1)
        })
      }

      const pos = text.length
      return ParseResult.failure({
        parsersHistory,
        errorMsg: `End of input reached without see surrounding right value '${textOnRight}'`,
        parserName,
        remaining: Parser.computeRemaining(text, pos)
      })
    }
  )
}

/**
 * Returns a new parser that, once called with an input, will returned the
 * string before one of strings presents in array passed as parameters.
 *
 * Example :
 *  const stringParser: Parser<string> = anyStringUntilOneOf(["azerty", "789"])
 *  parser.parse("123456789") will
 *   - Successfully returns "123456" as parsed value and "789" as remaining input
 *  parser.parse("az456789") will
 *   - Fails and returns "az456789" as remaining input
 *
 *
 *  const stringParser: Parser<string> = anyStringUntilOneOf(["1", "z"])
 *  parser.parse("009azerty") will
 *   - Successfully returns "009a" as parsed value and "zerty" as remaining input
 *  parser.parse("az456789") will
 *   - Fails and returns "az456789" as remaining input
 *
 * @param xs
 * @param ignoringCase
 */
export function anyStringUntilOneOf(
  xs: string[],
  ignoringCase?: boolean
): Parser<string> {
  const realIgnoringCase = ignoringCase === undefined ? false : ignoringCase

  return Parser.strict(
    `AnyStringUntilOneOf(${xs}, ignoringCase:${realIgnoringCase})`,
    ({ text, parserName, parsersHistory }) => {
      const ascendingOrder = (a: number, b: number) => a - b

      const indexOfFirstOccurrence = realIgnoringCase
        ? (wordToSearch: string) =>
            text.search(new RegExp(escapeRegExp(wordToSearch), 'i'))
        : (wordToSearch: string) => text.indexOf(wordToSearch)

      const foundIndexes = xs
        .map(wordToSearch => indexOfFirstOccurrence(wordToSearch))
        .filter(index => index !== -1)
        .sort(ascendingOrder)

      if (foundIndexes.length === 0) {
        return ParseResult.success({
          parsersHistory,
          parsedValue: text,
          parserName,
          remaining: ''
        })
      }

      const endIndex = foundIndexes[0]

      return ParseResult.success({
        parsersHistory,
        parsedValue: text.substring(0, endIndex),
        parserName,
        remaining: Parser.computeRemaining(text, endIndex - 1)
      })
    }
  )
}

/**
 * Returns a new parser that, once called with an input, will returned the
 * string in the array passed as parameter that matched the input.
 *
 * Example :
 *  const stringParser: Parser<string> = anyStringAmong(["123", "789"])
 *  parser.parse("123456789") will
 *   - Successfully returns "123" as parsed value and "456789" as remaining input
 *  parser.parse("az456789") will
 *   - Fails and returns "az456789" as remaining input
 *
 * @param words lists of words to be searched in the input.
 */
export function anyStringAmong(words: string[]): Parser<string> {
  return Parser.strict(`AnyStringAmong(${words})`, input => {
    for (const word of words) {
      const parseResult = stringEqualsTo(word).apply(input)
      if (parseResult.isSuccess()) {
        return parseResult.withParserName(input.parserName)
      }
    }

    return ParseResult.failure({
      parsersHistory: input.parsersHistory,
      errorMsg: `Unable to find any string among ${words} in the input text`,
      parserName: input.parserName,
      remaining: input.text
    })
  })
}
