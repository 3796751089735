import { AppRouteName } from '@app/routes'
import type { StoreManagementTenants } from '@app/stores'
import type { UpdateTenantSetting } from '@libs/openapi/service-identity-core'
import type { AppRouterClient } from '@libs/Router/types'
import type { SwitchChangeEventHandler } from 'antd/lib/switch'

/**
 * Handle Tenants management page loading.
 */
export const handleTenantsOnLoad =
  (storeManagementTenants: StoreManagementTenants) => () => {
    storeManagementTenants.fetchTenants()
  }

/**
 * Handle tenant creation.
 */
export const handleAddTenantOnClick = (appRouter: AppRouterClient) => () => {
  const url = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.Management_System_Tenants_Create,
    parameters: {}
  })
  appRouter.history.push(url)
}

/**
 * Handle tenant edition.
 */
export const handleEditTenantIconOnClick =
  (appRouter: AppRouterClient) => (tenantId: string) => () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Tenants_Edit,
      parameters: {
        tenantId
      }
    })
    appRouter.history.push(url)
  }

/**
 * Handle tenant deletion.
 */
export const handleDeleteTenantIconOnClick =
  (storeManagementTenants: StoreManagementTenants) =>
  (tenantId: string) =>
  () => {
    const tenantEntity = storeManagementTenants.tenants.get(tenantId)

    if (!tenantEntity) {
      return
    }

    storeManagementTenants.storeDeleteDrawer
      .setData({ tenantDataRow: tenantEntity.asDataRow() })
      .openDrawer()
  }

/**
 * Handle tenant scan status update.
 */
export const handleTenantEnableSwitchOnChange =
  (storeManagementTenants: StoreManagementTenants) =>
  (tenantSettingId: string): SwitchChangeEventHandler =>
  (enabled): void => {
    const { storeRoot } = storeManagementTenants

    const tenant = storeManagementTenants.tenants
      .get(tenantSettingId)
      ?.asDataRow()

    if (!tenant) {
      return
    }

    const updateTenantSetting: UpdateTenantSetting = {
      ...tenant,
      enabled
    }

    storeManagementTenants
      .toggleTenantStatus(tenantSettingId, updateTenantSetting)
      .then(() => {
        storeManagementTenants.fetchTenants()

        storeRoot.stores.storeMessages.success(
          storeManagementTenants.translate('Tenant X updated', {
            interpolations: {
              tenantName: updateTenantSetting.name
            }
          }),
          {
            labelledBy: 'tenantUpdated'
          }
        )
      })
      .catch(() => {
        // Error already displayed in the store
      })
  }
