import type { Maybe } from '@@types/helpers'
import { EntityAttackTypeOption, EntityCheckerOption } from '@app/entities'
import type {
  CheckerOptionCodename,
  EntityGenericCheckerOption
} from '@app/entities/EntityGenericCheckerOption/types'
import { CheckerOptionCodenameEnum } from '@app/entities/EntityGenericCheckerOption/types'
import { StoreInfrastructures } from '@app/stores'
import StoreForm from '@app/stores/helpers/StoreForm'
import type {
  IFieldMultiValue,
  IFieldValue
} from '@app/stores/helpers/StoreForm/types'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import StoreProfileCheckerOptionOMCGMAW from '@app/stores/Management/StoreProfiles/StoreProfileCheckerOptionOMCGMAW'
import StoreProfileCheckerOptionOWCT from '@app/stores/Management/StoreProfiles/StoreProfileCheckerOptionOWCT'
import type {
  ICheckerOptionArraySelectValue,
  ICheckerOptionFieldMeta,
  ICheckerOptionOMCGMAWConfigurationValues,
  ICheckerOptionOWCTConfigurationValues,
  IStoreProfileCheckerSerieOptions
} from '@app/stores/Management/StoreProfiles/types'
import StoreBase from '@app/stores/StoreBase'
import { ensureArray } from '@libs/ensureArray'
import { indexEntitiesToMap } from '@libs/indexEntitiesToMap'
import { isDefined } from '@libs/isDefined'
import { decodeValue } from '@libs/valueTypeParser/decodeValue'
import { ValueType } from '@libs/valueTypeParser/types'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { encodeFieldValue } from './helpers/encodeFieldValues'
import { isOptionsValueEqual } from './helpers/isOptionsValueEqual'

export type TStoreProfileCheckerSerie = StoreProfileCheckerSerie<
  TGenericChecker,
  EntityGenericCheckerOption
>

/**
 * State of each checker options configuration serie.
 */
export default class StoreProfileCheckerSerie<
  GC extends TGenericChecker,
  E extends EntityGenericCheckerOption
> extends StoreBase<IStoreProfileCheckerSerieOptions<GC, E>> {
  public storeInfrastructures = new StoreInfrastructures(this.storeRoot)

  // storeForm dedicated to the whole serie configuration
  public storeForm = new StoreForm(this.storeRoot)

  // store dedicated for the option O-MAX-CUSTOM-GROUPS-MEMBERS-AND-WHITELIST
  public storeProfileCheckerOptionOMCGMAW =
    new StoreProfileCheckerOptionOMCGMAW(this.storeRoot)

  // store dedicated for the option O-WHITELIST-CERTIFICATE-TEMPLATES
  public storeProfileCheckerOptionOWCT = new StoreProfileCheckerOptionOWCT(
    this.storeRoot
  )

  // save field name (=CheckerOptionCodename) that are currently staged (=modified)
  // to highlight them on the interface
  private _stagedFields = new Set<CheckerOptionCodename>()

  /**
   * Initialize various things.
   */
  init(): this {
    // select directories
    const directoryIds = this.options.directoryIds.filter(isDefined)

    if (directoryIds.length) {
      this.storeInfrastructures.fetchInfrastructures().then(() => {
        this.storeInfrastructures.selectDirectories(directoryIds)
      })
    }

    this._retrieveStagedFields()

    // setup form fields
    this.initFormFields()

    return this
  }

  /**
   * Depending to the value type of the option:
   * - return the value as a string
   * - return the value as a defined structure (ie: array/select)
   * - update the field value from its dedicated store (ie: array/object)
   *
   * At the end, return a mapping between option codename and stringified values,
   * in order to be sent to the API.
   */
  encodeOptionValues(): Map<CheckerOptionCodename, Maybe<string>> {
    const optionCodenames =
      this.storeForm.getFieldNames() as CheckerOptionCodename[]

    const optionValues: Map<CheckerOptionCodename, Maybe<string>> = new Map()

    optionCodenames.forEach(optionCodename => {
      const value = encodeFieldValue(this.storeForm)(
        optionCodename,
        this.storeProfileCheckerOptionOMCGMAW.configurationValues,
        this.storeProfileCheckerOptionOWCT.configurationValues
      )

      optionValues.set(optionCodename, value)
    })

    return optionValues
  }

  /**
   * Return the staged fields.
   */
  get stagedFields(): Set<CheckerOptionCodename> {
    return this._stagedFields
  }

  /**
   * Create field (one for each option) for the form of the serie.
   */
  private initFormFields(): void {
    this.options.serieCheckerOptions.forEach(
      (defaultCheckerOption, checkerOptionCodename) => {
        const serieCheckerOption =
          this.options.serieCheckerOptions.get(checkerOptionCodename) ||
          defaultCheckerOption

        const fieldMeta: ICheckerOptionFieldMeta = {
          checkerId: this.options.checker.getPropertyAsNumber('id'),
          valueType: serieCheckerOption.getPropertyAsString(
            'valueType'
          ) as ValueType,
          staged: serieCheckerOption.getPropertyAsBoolean('staged')
        }

        try {
          switch (serieCheckerOption.valueType) {
            case ValueType.string:
            case ValueType.sddl:
            case ValueType.regex:
            case ValueType.float:
            case ValueType.integer:
            case ValueType.boolean: {
              const fieldValue: IFieldValue = {
                key: checkerOptionCodename,
                value: String(
                  decodeValue(
                    serieCheckerOption.getPropertyAsString('value'),
                    serieCheckerOption.valueType
                  )
                )
              }

              this.storeForm
                .field(fieldValue.key)
                .setDefaultValue(fieldValue.value)
                .meta.set<ICheckerOptionFieldMeta>({
                  key: 'meta',
                  value: fieldMeta
                })

              break
            }

            case ValueType.arrayString:
            case ValueType.arrayCron:
            case ValueType.arrayRegex:
            case ValueType.arrayInteger:
            case ValueType.arrayBoolean: {
              const fieldValue: IFieldMultiValue = {
                key: checkerOptionCodename,
                values: ensureArray(
                  decodeValue(
                    serieCheckerOption.getPropertyAsString('value'),
                    serieCheckerOption.valueType
                  )
                )
              }

              this.storeForm
                .setDefaultFieldsMultiValues([fieldValue])
                .field(fieldValue.key)
                .meta.set<ICheckerOptionFieldMeta>({
                  key: 'meta',
                  value: fieldMeta
                })

              break
            }

            case ValueType.arraySelect: {
              const fieldValue: IFieldValue = {
                key: checkerOptionCodename,
                // decodeValue returns here the chosen value
                value: String(
                  decodeValue(
                    serieCheckerOption.getPropertyAsString('value'),
                    serieCheckerOption.valueType
                  )
                )
              }

              const parsedArraySelect = JSON.parse(
                serieCheckerOption.getPropertyAsString('value')
              ) as ICheckerOptionArraySelectValue

              this.storeForm
                .field(fieldValue.key)
                .setDefaultValue(fieldValue.value)
                .meta.set<ICheckerOptionFieldMeta>({
                  key: 'meta',
                  value: {
                    ...fieldMeta,
                    arraySelectSelection: {
                      value: parsedArraySelect,
                      translations: ensureArray(serieCheckerOption.translations)
                    }
                  }
                })

              break
            }

            case ValueType.arrayObject: {
              switch (checkerOptionCodename) {
                case CheckerOptionCodenameEnum.O_MAX_CUSTOM_GROUPS_MEMBERS_AND_WHITELIST: {
                  const fieldValue: IFieldValue = {
                    key: checkerOptionCodename,
                    // final value returned by storeProfileCheckerOptionOMCGMAW.configurationValues
                    value: JSON.stringify(
                      [] as ICheckerOptionOMCGMAWConfigurationValues[]
                    )
                  }

                  this.storeForm
                    .field(fieldValue.key)
                    .setDefaultValue(fieldValue.value)
                    .meta.set<ICheckerOptionFieldMeta>({
                      key: 'meta',
                      value: fieldMeta
                    })

                  this.storeProfileCheckerOptionOMCGMAW.initConfigurations(
                    serieCheckerOption.getPropertyAsString('value')
                  )

                  break
                }

                case CheckerOptionCodenameEnum.O_WHITELIST_CERTIFICATE_TEMPLATES: {
                  const fieldValue: IFieldValue = {
                    key: checkerOptionCodename,
                    // final value returned by storeProfileCheckerOptionOWCT.configurationValues
                    value: JSON.stringify(
                      [] as ICheckerOptionOWCTConfigurationValues[]
                    )
                  }

                  this.storeForm
                    .field(fieldValue.key)
                    .setDefaultValue(fieldValue.value)
                    .meta.set<ICheckerOptionFieldMeta>({
                      key: 'meta',
                      value: fieldMeta
                    })

                  this.storeProfileCheckerOptionOWCT.initConfigurations(
                    serieCheckerOption.getPropertyAsString('value')
                  )

                  break
                }

                default: {
                  throw new Error(
                    `Can't init form for the "${checkerOptionCodename}" option that is not yet supported`
                  )
                }
              }

              break
            }

            case ValueType.date:
            case ValueType.object: {
              throw new Error(
                `Can't init form for the "${checkerOptionCodename}" option, the type ${serieCheckerOption.valueType} is not yet supported`
              )
            }

            default: {
              assertUnreachableCase(
                serieCheckerOption.valueType,
                'ValueType of the checkerOption is invalid'
              )
            }
          }
        } catch (err) {
          this.storeRoot.logException(err)
        }

        return
      }
    )

    // set default values in fieds
    this.storeForm.reset()
  }

  /**
   * Retrieve fields that are currently staged.
   * (if the value of the related commit option is different than the one of the staged option)
   */
  private _retrieveStagedFields(): void {
    // if the profile is not dirty, there is no staged fields
    if (!this.options.isProfileDirty) {
      return
    }

    const commitSerieCheckerOptions = this.options.checkerOptions.filter(
      option => {
        const isForThatGenericChecker =
          (option instanceof EntityCheckerOption &&
            option.checkerId ===
              this.options.checker.getPropertyAsNumber('id')) ||
          (option instanceof EntityAttackTypeOption &&
            option.attackTypeId ===
              this.options.checker.getPropertyAsNumber('id'))

        return (
          // if the option is for the current generic checker
          isForThatGenericChecker &&
          // if the option is for the current serie
          this.options.directoryIds.indexOf(option.directoryId) !== -1 &&
          // if the option is commit
          !option.staged
        )
      }
    )

    const indexedCommitSerieCheckerOptions = indexEntitiesToMap<
      E,
      CheckerOptionCodename
    >(commitSerieCheckerOptions, 'codename')

    Array.from(this.options.serieCheckerOptions.values()).forEach(
      serieOption => {
        // retrieve the related commit option
        const relatedCommitOption =
          serieOption.codename &&
          indexedCommitSerieCheckerOptions.get(serieOption.codename)

        const serieOptionCodename = serieOption.codename

        if (!serieOptionCodename) {
          return
        }

        // if the related commit option has not been found, it means that it's a
        // new staged option (the option has never been commit yet),
        // so we had the field as staged.
        if (!relatedCommitOption) {
          this._stagedFields.add(serieOptionCodename)
          return
        }

        // if the options are equal, return
        if (isOptionsValueEqual(relatedCommitOption, serieOption)) {
          return
        }

        // if the values are different, add the fied as staged
        this._stagedFields.add(serieOptionCodename)
      }
    )
  }
}
