import * as React from 'react'
import GridWidgetContent from './GridWidgetContent'
import type { IGridWidgetGenericProps } from './types'

const GridWidget: React.FC<IGridWidgetGenericProps> = props => {
  React.useEffect(() => {
    if (props.preLoad) {
      props.storeWidget.fetchData(props.widgetKey).then(() => {
        const series = props.storeWidget.series

        if (series && series.length) {
          props.storeDashboard.setWidgetRefreshTimeout(
            props.widgetKey,
            series[0]
          )
        }
      })
    }

    return () => {
      props.storeDashboard.clearWidgetRefreshTimeout(props.widgetKey)
    }
  }, [])

  return <GridWidgetContent {...props} />
}

export default GridWidget
