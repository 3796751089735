import {
  ContainerFlex,
  ContainerHVCentered
} from '@app/components-legacy/Container'
import { consts } from '@app/styles'
import type { AttacksSummaryChartCardType } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'

const StyledContainerFlex = styled(ContainerFlex)`
  width: 100%;
`

export interface IInputIllustrationContainerProps {
  className?: string
  type: AttacksSummaryChartCardType
  name: string
  selected: boolean
  onChange: (type: AttacksSummaryChartCardType) => void
  children?: React.ReactNode
}

const InputIllustrationContainer: React.FC<
  IInputIllustrationContainerProps
> = props => {
  const onChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    props.onChange(props.type)
  }

  return (
    <div className={props.className} onClick={onChange}>
      <ContainerHVCentered>
        <StyledContainerFlex
          name="InputIllustrationContainer"
          direction="column"
          alignItems="center"
          justifyContent="center"
          items={[
            <div className="inputIcon">{props.children}</div>,
            <div className="inputName">{props.name}</div>
          ]}
          fullWidth
        />
      </ContainerHVCentered>
    </div>
  )
}

export default styled(InputIllustrationContainer)`
  width: 150px;
  height: 120px;

  background-color: ${props =>
    props.selected ? consts.colorSelected01 : consts.colorWhite};

  box-shadow: ${consts.shadowThin};
  border: 1px solid
    ${props =>
      props.selected ? consts.colorBlueGray015 : consts.colorBlueGray020};
  border-radius: ${consts.containerBorderRadius};

  :hover {
    background-color: ${consts.colorSelected01};
    cursor: pointer;
  }

  .inputIcon {
    font-size: 42px;
  }

  .inputName {
    text-align: center;
    padding: 0 ${consts.marginDefault};
    width: 100%;
  }
`
