import { Features } from '@alsid/common'
import { canAccessToHealthCheck } from '@app/pages/HealthCheck/permissions'
import type { StoreAbout } from '@app/stores'
import {
  rbacCapabilityCheckAllOf,
  rbacCapabilityCheckAnyOf
} from '@libs/rbac/functions'
import {
  accessByFeatureFlag,
  readApplicationSetting,
  readEmailAlerts,
  readLicenseFeatureIoA,
  readLicenseFeatureIoE,
  readLockoutPolicy,
  readProfiles,
  readRoles,
  readSyslogAlerts,
  readActivityLogs,
  readToLdapConfiguration,
  readToSamlConfiguration,
  readUISystemConfiguration,
  readUISystemConfigurationAlertsEmail,
  readUISystemConfigurationAlertsSyslog,
  readUISystemConfigurationAttackType,
  readUISystemConfigurationLdap,
  readUISystemConfigurationSaml,
  readUISystemConfigurationSecurity,
  readUISystemConfigurationSMTPServer,
  readUISystemConfigurationTelemetry,
  readUISystemConfigurationTenableAccount,
  readDataCollection,
  deny,
  readUIRelayLinkingKey,
  editRelay,
  readUiSystemConfigurationReportingCenter,
  readReport
} from '@libs/rbac/permissions'
import { canAccessToSystem } from '../permissions'

export const canAccessToSystemConfiguration = rbacCapabilityCheckAllOf(
  canAccessToSystem,
  readUISystemConfiguration()
)

/* Application services */

export const canAccessToSMTPConfiguration = rbacCapabilityCheckAllOf(
  canAccessToSystemConfiguration,
  readUISystemConfigurationSMTPServer(),
  readApplicationSetting()
)

export const canAccessToActivityLogsConfiguration = rbacCapabilityCheckAllOf(
  accessByFeatureFlag(Features.TRACEABILITY_DASHBOARD)(),
  canAccessToSystemConfiguration,
  readActivityLogs(),
  readApplicationSetting()
)

export const canAccessToTelemetryConfiguration = rbacCapabilityCheckAllOf(
  canAccessToSystemConfiguration,
  accessByFeatureFlag(Features.TELEMETRY)(),
  readUISystemConfigurationTelemetry(),
  readApplicationSetting()
)

export const canAccessToHealthCheckConfiguration = rbacCapabilityCheckAllOf(
  canAccessToHealthCheck
)

export const canAccessToReportingCenter = rbacCapabilityCheckAllOf(
  canAccessToSystemConfiguration,
  accessByFeatureFlag(Features.REPORTING_CENTER)(),
  readUiSystemConfigurationReportingCenter(),
  readReport()
)

// PKI
export const canAccessToSecurityConfiguration = rbacCapabilityCheckAllOf(
  canAccessToSystemConfiguration,
  readUISystemConfigurationSecurity(),
  readApplicationSetting()
)

export const canAccessToIoAConfiguration = rbacCapabilityCheckAllOf(
  canAccessToSystemConfiguration,
  readUISystemConfigurationAttackType(),
  readLicenseFeatureIoA(),
  readApplicationSetting()
)

export const canAccessToDataCollectionConfiguration = rbacCapabilityCheckAllOf(
  canAccessToSystemConfiguration,
  readDataCollection()
)

/* Alerting engine */

export const canAccessToSyslogAlertsConfiguration = rbacCapabilityCheckAllOf(
  canAccessToSystemConfiguration,
  readUISystemConfigurationAlertsSyslog(),
  readSyslogAlerts()
)

export const canAccessToEmailAlertsConfiguration = rbacCapabilityCheckAllOf(
  canAccessToSystemConfiguration,
  readUISystemConfigurationAlertsEmail(),
  readEmailAlerts()
)

export const canSelectTriggerOnDeviances = rbacCapabilityCheckAllOf(
  readLicenseFeatureIoE()
)

export const canSelectTriggerOnAttacks = rbacCapabilityCheckAllOf(
  readLicenseFeatureIoA()
)

export const canSelectTriggerOnHealthCheckStatusChange =
  rbacCapabilityCheckAllOf(canAccessToHealthCheck)

/* Authentication */

export const canAccessToTenableProviderDefaultRolesAndProfiles =
  rbacCapabilityCheckAllOf(
    canAccessToSystemConfiguration,
    readUISystemConfigurationTenableAccount(),
    readApplicationSetting(),
    readRoles(),
    readProfiles()
  )

export const canAccessToTenableProviderLockoutPolicy =
  rbacCapabilityCheckAnyOf(readLockoutPolicy())

export const canAccessToTenableProviderConfiguration = rbacCapabilityCheckAnyOf(
  canAccessToTenableProviderDefaultRolesAndProfiles,
  canAccessToTenableProviderLockoutPolicy
)

export const canAccessToLDAPProviderConfiguration = (
  storeAbout?: StoreAbout
) => {
  if (storeAbout && storeAbout.productAssociation?.isAssociatedToTenableOne()) {
    const featureWorkspaceAppswitcherInTad =
      storeAbout.storeRoot.stores.storeRbac.isUserGrantedAccordingFeatureFlag(
        Features.WORKSPACE_APPSWITCHER_IN_TAD
      )

    if (featureWorkspaceAppswitcherInTad) {
      return deny()
    }
  }

  return rbacCapabilityCheckAllOf(
    canAccessToSystemConfiguration,
    readUISystemConfigurationLdap(),
    readToLdapConfiguration()
  )
}

export const canAccessToRelayConfiguration = rbacCapabilityCheckAllOf(
  canAccessToSystemConfiguration,
  readUIRelayLinkingKey(),
  editRelay()
)

export const canAccessToSAMLProviderConfiguration = (
  storeAbout?: StoreAbout
) => {
  if (storeAbout && storeAbout.productAssociation?.isAssociatedToTenableOne()) {
    const featureWorkspaceAppswitcherInTad =
      storeAbout.storeRoot.stores.storeRbac.isUserGrantedAccordingFeatureFlag(
        Features.WORKSPACE_APPSWITCHER_IN_TAD
      )

    if (featureWorkspaceAppswitcherInTad) {
      return deny()
    }
  }

  return rbacCapabilityCheckAllOf(
    canAccessToSystemConfiguration,
    readUISystemConfigurationSaml(),
    readToSamlConfiguration()
  )
}
