import type { Maybe } from '@@types/helpers'
import type { StoreRoot } from '@app/stores'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreEscapable } from '@app/stores/types'
import { isDefined } from '@libs/isDefined'
import type { EscapeHandler } from '@libs/KeyboardBindingsManager/EscapeHandler'
import { KeyboardKey } from '@libs/KeyboardBindingsManager/types'
import { action, computed, makeObservable, observable } from 'mobx'

export interface IStoreModalOptions {
  isUnclosable?: boolean
}

/**
 * Store to use with the Modal component.
 */
export default class StoreModal<TMetadata>
  extends StoreBase<IStoreModalOptions>
  implements IStoreEscapable
{
  /**
   * Observable
   */

  // show modal or not
  private $isVisible = observable.box<boolean>(false)

  // optional data for the modal dialog.
  private $data = observable.box<Maybe<TMetadata>>(null)

  // can close the modal
  private $isUnclosable = observable.box<boolean>(false)

  constructor(storeRoot: StoreRoot, options: IStoreModalOptions = {}) {
    super(storeRoot, options)

    if (options && isDefined(options.isUnclosable)) {
      this.$isUnclosable.set(options.isUnclosable)
    }

    makeObservable(this)
  }

  /* Actions */

  /**
   * Set data.
   */
  @action
  setData(data: TMetadata): this {
    this.$data.set(data)
    return this
  }

  /**
   * Show modal.
   */
  @action
  show(): this {
    if (!this.isUnclosable) {
      this.registerIntoKeyboardHandler()
    }
    this.$isVisible.set(true)
    return this
  }

  /**
   * Hide modal.
   */
  @action
  hide(): this {
    this.unregisterIntoKeyboardHandler()
    this.$isVisible.set(false)
    return this
  }

  /* Computed */

  @computed
  get isVisible(): boolean {
    return this.$isVisible.get()
  }

  /**
   * Retrieve data.
   */
  @computed
  get data(): Maybe<TMetadata> {
    return this.$data.get()
  }

  @computed
  get isUnclosable(): boolean {
    return this.$isUnclosable.get()
  }

  /* Implements IStoreEscapable */

  /**
   * Register the store instance into KeyboardBindingsManager when opening the modal.
   */
  registerIntoKeyboardHandler(): void {
    const handler =
      this.storeRoot.keyboardBindingsManager.getHandler<EscapeHandler>(
        KeyboardKey.Escape
      )

    if (!handler) {
      return
    }

    handler.addStore(this)
  }

  /**
   * Remove the store instance from KeyboardBindingsManager when closing the modal.
   */
  unregisterIntoKeyboardHandler(): void {
    const handler =
      this.storeRoot.keyboardBindingsManager.getHandler<EscapeHandler>(
        KeyboardKey.Escape
      )

    if (!handler) {
      return
    }

    handler.deleteStore(this)
  }

  /**
   * Return true if the drawer is opened.
   */
  get isEscapable(): boolean {
    return this.isVisible
  }

  /**
   * Close the drawer.
   */
  escape(): Promise<boolean> {
    this.hide()
    return Promise.resolve(true)
  }
}
