import { ContainerHTMLContent } from '@app/components-legacy/Container'
import { parseSDDL } from '@app/entities/EntityIncriminatingAttribute/helpers'
import { consts } from '@app/styles'
import { marked } from 'marked'
import * as React from 'react'
import styled from 'styled-components'

interface IContainerHTMLContentExtendedSddlProps {
  className?: string
  value: string
  key?: number
}

const ContainerHTMLContentExtendedSddl: React.FC<
  IContainerHTMLContentExtendedSddlProps
> = props => {
  const parsedValues = parseSDDL(props.value, true)
  const formattedItems = parsedValues.map(({ items, formattedString }) => {
    return (
      <ContainerHTMLContent
        key={`${props.key}${items.Item1}`}
        labelledBy="extendedSddl"
        inline
      >
        {marked.parse(formattedString)}
      </ContainerHTMLContent>
    )
  })
  return <div className={props.className}>{formattedItems}</div>
}

export default styled(ContainerHTMLContentExtendedSddl)`
  padding-top: ${consts.paddingDefault};
`
