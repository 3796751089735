import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconUserGroupProps extends IIconProps {}

const IconUserGroup: React.FC<IIconUserGroupProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 20

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 9.375C9.22589 9.375 10.625 7.97589 10.625 6.25C10.625 4.52411 9.22589 3.125 7.5 3.125C5.77411 3.125 4.375 4.52411 4.375 6.25C4.375 7.97589 5.77411 9.375 7.5 9.375Z"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M1.875 15.625V15.625C1.875 13.5539 3.55393 11.875 5.625 11.875H9.375C11.4461 11.875 13.125 13.5539 13.125 15.625V15.625"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1255 3.18762C14.5517 3.47737 15.625 4.73834 15.625 6.25C15.625 7.76015 14.5538 9.02012 13.1298 9.31151"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.375 11.875C16.4461 11.875 18.125 13.5539 18.125 15.625V15.625"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconUserGroup
