import type { StorePreferences } from '@app/stores'
import type StoreForm from '@app/stores/helpers/StoreForm'
import type { InputChangeUserPassword } from '@server/graphql/typeDefs/types'

export const onPreferencesSecurityLoad =
  (storePreferences: StorePreferences) => () => {
    storePreferences.storeFormSecurity.reset()
  }

export const onChangePasswordSubmit =
  (storeForm: StoreForm<any>, storePreferences: StorePreferences) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!storeForm.validate()) {
      return
    }

    const password: InputChangeUserPassword = {
      oldPassword: storeForm.getFieldValueAsString('oldPassword'),
      newPassword: storeForm.getFieldValueAsString('newPassword')
    }

    storePreferences.changeUserPassword(password)
  }

export const onApiKeyRefreshSubmit =
  (storePreferences: StorePreferences) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    storePreferences.storeModalConfirmApiKeyRefresh.show()
  }

export const onApiKeyRefreshModalCancelClick =
  (storePreferences: StorePreferences) => () => {
    storePreferences.storeModalConfirmApiKeyRefresh.hide()
  }

export const onApiKeyRefreshModalConfirmClick =
  (storePreferences: StorePreferences) => () => {
    storePreferences.refreshApiKey()
    storePreferences.storeModalConfirmApiKeyRefresh.hide()
  }
