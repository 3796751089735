import { AppRouteName } from '@app/routes'
import type StoreIndicator from '@app/stores/IoE/StoreIndicator'
import { filterNullOrUndefinedValues } from '@libs/helpers/objects/filterNullOrUndefinedValues'
import type { ISubmitFormParameters } from '@libs/submitForm'
import { submitForm } from '@libs/submitForm'
import type { IExportParametersFindings } from '@server/routers/exportCSVRouter/types'

/**
 * Export findings.
 */
export const handleExportFindingsOnSubmit =
  (storeIndicator: StoreIndicator) => (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeRoot } = storeIndicator
    const { storeMessages } = storeIndicator.storeRoot.stores
    const { appRouter } = storeIndicator.storeRoot

    const pathname = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareExportCSV,
      parameters: {}
    })

    const checkerId = storeIndicator.checkerIdentity?.genericId
    const checkerCodeName = storeIndicator.checkerIdentity?.genericCodename
    const datesRange =
      storeIndicator.storeIndicatorFindings.storeDatePicker
        .datePickerRangeValueAsMoments

    if (!checkerId || !checkerCodeName) {
      return
    }

    const parameters: ISubmitFormParameters<IExportParametersFindings> = {
      parameters: {
        routine: 'Findings',
        contextData: filterNullOrUndefinedValues({
          checkerId,
          checkerCodeName,
          tenantIds:
            storeIndicator.storeIndicatorFindings.storeInputTenants
              .selectedTenantIds,
          textQuery:
            storeIndicator.storeIndicatorFindings.storeInputSearchFinding
              .searchValue,
          startDetectionDate: datesRange?.dateStart?.toISOString(),
          endDetectionDate: datesRange?.dateEnd?.toISOString()
        })
      },
      language: storeMessages.storeRoot.appTranslator.language,
      'csrf-token': storeRoot.environment.sessionParams.csrfToken
    }

    submitForm(pathname, parameters, 'POST')
  }
