import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { IconArrow } from '@app/components-legacy/Icon'
import type EntityAttack from '@app/entities/EntityAttack'
import { useAppTranslator } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { AttacksPageContext } from '../../context'
import { onAttackExpandButtonClick } from './handlers'

interface IIoAAttacksTableBodyDataRowExpandButtonProps {
  attackEntity: EntityAttack
}

const IoAAttacksTableBodyDataRowExpandButton: React.FC<
  IIoAAttacksTableBodyDataRowExpandButtonProps
> = props => {
  const attacksPageContext = React.useContext(AttacksPageContext)

  const translate = useAppTranslator({
    namespaces: ['IoA.Attacks']
  })

  if (!attacksPageContext) {
    return null
  }

  const expanded =
    attacksPageContext.storeAttacks.storeWidgetListAttacks.isToggleableRowOpened(
      props.attackEntity
    )

  return (
    <ContainerFlex
      name="IoAAttacksTableBodyDataRowExpandButton"
      className="attackExpandButton"
      justifyContent="flex-end"
      items={[
        <FormWrapperButton
          labelledBy="createFirstDashboard"
          buttonProps={{
            icon: (
              <IconArrow
                labelledBy="extendAttack"
                direction="right"
                rotation={90}
                expanded={expanded}
              />
            ),
            onClick: onAttackExpandButtonClick(attacksPageContext.storeAttacks)(
              props.attackEntity
            )
          }}
        >
          {translate('Details')}
        </FormWrapperButton>
      ]}
    />
  )
}

export default observer(IoAAttacksTableBodyDataRowExpandButton)
