import { Features } from '@alsid/common'
import {
  ContainerContent,
  ContainerFooter,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { TypographyFieldsetTitle } from '@app/components-legacy/Typography'
import WidgetFormSelect from '@app/components-legacy/Widgets/WidgetForm/WidgetFormSelect'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { useStores } from '@app/hooks/useStores'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import {
  onInputChange,
  onSwitchChange
} from '@app/stores/helpers/StoreForm/handlers'
import { LdapConfigurationFieldName } from '@app/stores/Management/StoreLDAPConfiguration/types'
import { filterFalsies } from '@libs/filterFalsies'
import { AuthProviderName } from '@server/graphql/typeDefs/types'
import { Input, Switch } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import ConfigurationGroups from '../ConfigurationAuthCommon/ConfigurationGroups'
import { onLDAPConfigurationSubmit } from './handlers'
import { canSubmitLDAPConfiguration } from './permissions'

interface ILdapConfigurationFormProps {}

const LdapConfigurationForm: React.FC<ILdapConfigurationFormProps> = props => {
  const { testAttributeProps } = useTestAttribute('form')

  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Configuration.Navigation',
      'Management.System.Configuration.LDAP'
    ]
  })

  const { storeRbac, storeManagementLDAPConfiguration, storeManagementRelays } =
    useStores()

  const { storeForm } = storeManagementLDAPConfiguration

  const { storeRoot } = useStores()
  const featureSecuredRelayOnCustomerSide =
    storeRoot.environment.config.app.securerelay.customerside

  const featureLdapAuthentThroughRelay = useFeatureFlag({
    featureFlagName: Features.LDAP_AUTHENT_THROUGH_RELAY
  })

  const fieldsLDAPConfigurationEnableSwitch: IField[] = [
    {
      name: LdapConfigurationFieldName.enabled,
      label: translate('Enable LDAP authentication'),
      errors: storeForm.field(LdapConfigurationFieldName.enabled).errors,
      control: (
        <Switch
          size="small"
          checked={
            storeForm.getFieldValueAsBoolean(
              LdapConfigurationFieldName.enabled
            ) === true
          }
          onChange={onSwitchChange(storeForm)(
            LdapConfigurationFieldName.enabled
          )}
          {...testAttributeProps('switch')('enableLdapAuthentication')}
        />
      )
    }
  ]

  const fieldsLDAPConfiguration: IField[] = filterFalsies([
    featureSecuredRelayOnCustomerSide &&
      featureLdapAuthentThroughRelay && {
        name: LdapConfigurationFieldName.relayId,
        label: translate('Relay'),
        description: translate('Relay to use to connect to the LDAP server'),
        errors: storeForm.field(LdapConfigurationFieldName.relayId).errors,
        control: (
          <WidgetFormSelect
            inputProps={{
              labelledBy: 'relay',
              selectOptions: Array.from(
                storeManagementRelays.relays.values()
              ).map(relay => ({
                value: relay.getPropertyAsString('id'),
                label: relay.getPropertyAsString('name')
              }))
            }}
            storeForm={storeForm}
            fieldName={LdapConfigurationFieldName.relayId}
            inputId={'ldapRelayId'}
          />
        )
      },
    {
      name: LdapConfigurationFieldName.url,
      label: translate('Address of the LDAP server'),
      errors: storeForm.field(LdapConfigurationFieldName.url).errors,
      required: true,
      control: (
        <Input
          type="text"
          name={LdapConfigurationFieldName.url}
          placeholder="ldap://123.123.123.123:389"
          onChange={onInputChange(storeForm)(LdapConfigurationFieldName.url)}
          value={storeForm.getFieldValueAsString(
            LdapConfigurationFieldName.url
          )}
          autoComplete="off"
        />
      )
    },
    {
      name: LdapConfigurationFieldName.searchUserDN,
      label: translate('Service account used to query the LDAP Server'),
      errors: storeForm.field(LdapConfigurationFieldName.searchUserDN).errors,
      required: true,
      control: (
        <Input
          type="text"
          name={LdapConfigurationFieldName.searchUserDN}
          placeholder={translate('DN, SamAccountName or UserPrincipalName')}
          onChange={onInputChange(storeForm)(
            LdapConfigurationFieldName.searchUserDN
          )}
          value={storeForm.getFieldValueAsString(
            LdapConfigurationFieldName.searchUserDN
          )}
          autoComplete="off"
        />
      )
    },
    {
      name: LdapConfigurationFieldName.searchUserPassword,
      label: translate('Service account password'),
      errors: storeForm.field(LdapConfigurationFieldName.searchUserPassword)
        .errors,
      required: true,
      control: (
        <Input.Password
          name={LdapConfigurationFieldName.searchUserPassword}
          onChange={onInputChange(storeForm)(
            LdapConfigurationFieldName.searchUserPassword
          )}
          value={storeForm.getFieldValueAsString(
            LdapConfigurationFieldName.searchUserPassword
          )}
          autoComplete="new-password"
          visibilityToggle
        />
      )
    },
    {
      name: LdapConfigurationFieldName.userSearchBase,
      label: translate('LDAP search base'),
      errors: storeForm.field(LdapConfigurationFieldName.userSearchBase).errors,
      required: true,
      control: (
        <Input
          name={LdapConfigurationFieldName.userSearchBase}
          placeholder="DC=mydomain,DC=corp"
          onChange={onInputChange(storeForm)(
            LdapConfigurationFieldName.userSearchBase
          )}
          value={storeForm.getFieldValueAsString(
            LdapConfigurationFieldName.userSearchBase
          )}
          autoComplete="off"
        />
      )
    },
    {
      name: LdapConfigurationFieldName.userSearchFilter,
      label: translate('LDAP search filter'),
      errors: storeForm.field(LdapConfigurationFieldName.userSearchFilter)
        .errors,
      required: true,
      control: (
        <Input
          name={LdapConfigurationFieldName.userSearchFilter}
          placeholder="(sAMAccountname={{login}})"
          onChange={onInputChange(storeForm)(
            LdapConfigurationFieldName.userSearchFilter
          )}
          value={storeForm.getFieldValueAsString(
            LdapConfigurationFieldName.userSearchFilter
          )}
          autoComplete="off"
        />
      )
    },
    {
      name: LdapConfigurationFieldName.enableSaslBinding,
      label: translate('Enable SASL bindings'),
      errors: storeForm.field(LdapConfigurationFieldName.enableSaslBinding)
        .errors,
      control: (
        <Switch
          size="small"
          checked={
            storeForm.getFieldValueAsBoolean(
              LdapConfigurationFieldName.enableSaslBinding
            ) === true
          }
          onChange={onSwitchChange(storeForm)(
            LdapConfigurationFieldName.enableSaslBinding
          )}
          {...testAttributeProps('switch')('enableSaslBinding')}
        />
      )
    },
    {
      name: 'defaultProfileAndRolesTitle',
      control: (
        <TypographyFieldsetTitle>
          {translate('Default profile and roles')}
        </TypographyFieldsetTitle>
      )
    },
    {
      name: 'allowedGroups',
      label: translate('Allowed groups'),
      control: (
        <>
          <p>
            {translate(
              'You must configure the default profile and roles for each LDAP group'
            )}
          </p>

          <ConfigurationGroups
            userProvider={AuthProviderName.Ldap}
            storeFormGroups={
              storeManagementLDAPConfiguration.storeFormAllowedGroups
            }
            profiles={storeManagementLDAPConfiguration.profiles}
            rbacRoles={storeManagementLDAPConfiguration.rbacRoles}
            createGroupFn={storeManagementLDAPConfiguration.createAllowedGroups}
          />
        </>
      )
    }
  ])

  const displayLdapConfig = storeForm.getFieldValueAsBoolean(
    LdapConfigurationFieldName.enabled
  )

  return (
    <FormWrapper
      name="ldapConfiguration"
      onSubmit={onLDAPConfigurationSubmit(storeManagementLDAPConfiguration)}
    >
      <ContainerContent title={translate('LDAP configuration')}>
        <ContainerForm fields={fieldsLDAPConfigurationEnableSwitch} />

        {displayLdapConfig && (
          <ContainerForm fields={fieldsLDAPConfiguration} />
        )}

        <Portal name={PlaceHolderName.bladeFooter}>
          <ContainerFooter>
            <FormWrapperButtonSubmit
              loading={
                storeManagementLDAPConfiguration.storeUpdateFlags.flags
                  .isLoading
              }
              disabled={!storeRbac.isUserGrantedTo(canSubmitLDAPConfiguration)}
            >
              {translate('Save')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </ContainerContent>
    </FormWrapper>
  )
}

export default observer(LdapConfigurationForm)
