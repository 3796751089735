import { FormWrapperSelect } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks'
import * as React from 'react'
import { UnitPrefix } from '../types'
import CronfieldControlContainer from './CronfieldControlContainer'

export interface ISelectMonthsProps {
  months: string[]
  onChange: (months: string[]) => void
}

export default function SelectMonths(props: ISelectMonthsProps) {
  const translate = useAppTranslator({
    namespaces: ['Components.InputCrontab']
  })
  const selectOptions = [
    {
      label: 'January',
      value: '1'
    },
    {
      label: 'February',
      value: '2'
    },
    {
      label: 'March',
      value: '3'
    },
    {
      label: 'April',
      value: '4'
    },
    {
      label: 'May',
      value: '5'
    },
    {
      label: 'June',
      value: '6'
    },
    {
      label: 'July',
      value: '7'
    },
    {
      label: 'August',
      value: '8'
    },
    {
      label: 'September',
      value: '9'
    },
    {
      label: 'October',
      value: '10'
    },
    {
      label: 'November',
      value: '11'
    },
    {
      label: 'December',
      value: '12'
    }
  ]
  const emptySelection = props.months.length === 0
  return (
    <CronfieldControlContainer
      name="SelectMonths"
      unitPrefix={UnitPrefix.In}
      hidePrefix={emptySelection}
    >
      <FormWrapperSelect<string[]>
        labelledBy="format"
        width={emptySelection ? 'large' : 'medium'}
        selectProps={{
          placeholder: translate('Every month'),
          value: props.months,
          allowClear: true,
          onChange: props.onChange,
          mode: 'multiple'
        }}
        selectOptions={selectOptions.map(option => {
          return { ...option, label: translate(option.label) }
        })}
      />
    </CronfieldControlContainer>
  )
}
