import type { EntityAttackPathEdge, EntityAttackPathNode } from '@app/entities'
import {
  EntityAttackPathNodeDrawer,
  EntityAttackPathNodeExpand
} from '@app/entities'
import { useStores } from '@app/hooks/useStores'
import { MAIN_NODE_WIDTH, NODE_WIDTH } from '@app/stores/AttackPath/consts'
import type { EntityAttackPathNodeAny } from '@app/stores/AttackPath/types'
import { consts } from '@app/styles'
import { renderSvgLabelFilter } from '@libs/svg/helpers'
import { AttackPathAdObjectType } from '@server/graphql/typeDefs/types'
import { Graph } from '@visx/network'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import ArrowLink from '../Components/ArrowLink'
import DrawerNode from '../Components/DrawerNode'
import ExpandNode from '../Components/ExpandNode'
import ImageNode from '../Components/ImageNode'
import { useSceneSvgRefInit, useSceneZoomBinding } from './hooks'
import { sceneChartBaseZIndex } from './zIndexes'

interface ISceneChartProps {
  className?: string
  featurePinHighlightedPath: boolean
}

const SceneChart: React.FC<ISceneChartProps> = props => {
  const chartSVGRef = useSceneSvgRefInit()
  useSceneZoomBinding(chartSVGRef)

  const { storeAttackPath } = useStores()

  const { x, y, k } = storeAttackPath.chartZoomData

  const zoomTransform = `translate(${x}, ${y}) scale(${k})`

  const nodes = storeAttackPath.nodes
  const edges = storeAttackPath.edges

  const graph = React.useMemo(() => {
    const graphData = {
      nodes,
      links: edges
    }

    return (
      <Graph<EntityAttackPathEdge, EntityAttackPathNodeAny>
        graph={graphData}
        nodeComponent={({ node }) => {
          const nodeSize = node.getPropertyAsBoolean('isImportantNode')
            ? MAIN_NODE_WIDTH
            : NODE_WIDTH

          if (node instanceof EntityAttackPathNodeDrawer) {
            return (
              <DrawerNode
                uid={node.getPropertyAsString('uid')}
                linkedEntity={node.getPropertyAsT<EntityAttackPathNode>(
                  'linkedNodeEntity'
                )}
              />
            )
          }

          if (node instanceof EntityAttackPathNodeExpand) {
            return (
              <ExpandNode
                uid={node.getPropertyAsString('uid')}
                linkedEntity={node.getPropertyAsT<EntityAttackPathNode>(
                  'linkedNodeEntity'
                )}
              />
            )
          }

          return (
            <ImageNode
              nodetype={node.adObjectType ?? AttackPathAdObjectType.Other}
              width={nodeSize}
              height={nodeSize}
              adObjectId={node.getPropertyAsNumber('id')}
              name={node.getPropertyAsString('name')}
              nodeId={node.getPropertyAsString('uid')}
              isImportantNode={node.getPropertyAsBoolean('isImportantNode')}
              directoryId={node.getPropertyAsNumber('directoryId')}
              childrenCount={node.getPropertyAsNumber('childrenCount')}
              isHiddenNode={node.getPropertyAsBoolean('isHiddenNode')}
              depth={node.getPropertyAsNumber('depth')}
              featurePinHighlightedPath={props.featurePinHighlightedPath}
            />
          )
        }}
        linkComponent={({ link }) => {
          return (
            <ArrowLink {...link} source={link.source} target={link.target} />
          )
        }}
      />
    )
  }, [nodes, edges])

  return (
    <div data-name="Chart" className={props.className}>
      <svg
        ref={chartSVGRef}
        width={storeAttackPath.sceneDimensions.width}
        height={storeAttackPath.sceneDimensions.height}
      >
        <g transform={zoomTransform}>
          <defs>
            {renderSvgLabelFilter('highlight', consts.colorAttackPathSecondary)}
          </defs>
          {graph}
        </g>
      </svg>
    </div>
  )
}

const ObservedChart = observer(SceneChart)

export default styled(ObservedChart)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${sceneChartBaseZIndex};

  .zoomContainer {
    position: relative;
  }
`
