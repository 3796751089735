import { useAppTranslator } from '@app/hooks'
import type StoreRoot from '@app/stores/StoreRoot'
import { getRainbowColorScheme } from '@app/styles/colors/schemes'
import { convertToPieChartData } from '@libs/dashboards/formatData'
import { ensureArray } from '@libs/ensureArray'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Cell, Legend, Pie, PieChart } from 'recharts'
import type { IWidgetProps } from './types'
import WidgetContainer from './WidgetContainer'
import WidgetFooterDirectories from './WidgetFooterDirectories'
import WidgetHeaderTitle from './WidgetHeaderTitle'

export interface IWidgetPieChartProps extends IWidgetProps {
  storeRoot?: StoreRoot
}

const RADIAN = Math.PI / 180

function renderCustomizedLabel() {
  return ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent
  }: any): any => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }
}

/**
 * TODO: Rework everything in this component, it's not usable yet.
 */

const WidgetPieChart: React.FC<IWidgetPieChartProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Dashboard.Common'] })

  const renderHeader = () => {
    const warnings = []
    if (props.storeWidget.hasSerieWithUnknownDirectory) {
      warnings.push(translate('Some domains are no longer available'))
    }

    return (
      <WidgetHeaderTitle
        widgetKey={props.widgetKey}
        title={props.title}
        warnings={warnings}
        onEditIconClick={props.onEditIconClick}
        onDeleteIconClick={props.onDeleteIconClick}
        onRefreshIconClick={props.onRefreshIconClick}
      />
    )
  }

  const renderContent = () => {
    const { storeWidget } = props
    const { series } = storeWidget

    if (!series || (series && !series.length)) {
      return (
        <WidgetContainer
          data-name="WidgetBigNumber"
          className={props.className}
          header={renderHeader()}
          isMissingData
        />
      )
    }

    const data = convertToPieChartData(ensureArray(series[0].stats))
    const colors = getRainbowColorScheme(data.rows.length)

    // const firstData = data.labels[0]

    // TODO Add a scroll pane for each labels or somethink like that?
    // if (!firstData) {
    //   return <WidgetError />
    // }

    return (
      <PieChart width={props.bounds.width} height={props.bounds.height}>
        <Legend
          wrapperStyle={{
            top: '10px'
          }}
        />

        <Pie
          data={data.rows}
          cx={props.bounds.width / 2}
          cy={props.bounds.height / 2}
          outerRadius={80}
          dataKey="value"
          labelLine={false}
          // https://github.com/recharts/recharts/issues/907
          isAnimationActive={false}
          label={renderCustomizedLabel()}
        >
          {data.rows.map((row, i) => (
            <Cell key={data.uuids[i]} fill={colors[i]} />
          ))}
        </Pie>
      </PieChart>
    )
  }

  return (
    <WidgetContainer
      data-name="WidgetPie"
      labelledBy={`widget-id-${props.storeWidget.widgetEntity.id}`}
      flags={props.storeWidget.storeFlagsFetchWidgetData.flags}
      header={renderHeader()}
      footer={<WidgetFooterDirectories storeWidget={props.storeWidget} />}
    >
      {renderContent()}
    </WidgetContainer>
  )
}

export default observer(WidgetPieChart)
