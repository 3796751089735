import { ContainerFlex } from '@app/components-legacy/Container'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onTrailFlowFetchNewerEventsClick } from './handlers'
import TableControl from './TableControl'

export interface ITableHeaderProps {}

const TableHeader: React.FC<ITableHeaderProps> = props => {
  const translate = useAppTranslator({ namespaces: ['TrailFlow.Table'] })

  const { storeTrailFlow } = useStores()

  const isLoading =
    storeTrailFlow.storeEvents.storeFlagsFetchNewerEvents.isLoading

  return (
    <ContainerFlex
      name="TableHeader"
      justifyContent="center"
      items={[
        <TableControl
          labelledBy="load-next-events"
          direction="up"
          label={translate('Load next events')}
          isLoading={isLoading}
          onClick={onTrailFlowFetchNewerEventsClick(storeTrailFlow)}
        />
      ]}
    />
  )
}

export default observer(TableHeader)
