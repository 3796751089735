import type { Maybe } from '@@types/helpers'
import type { EntityChecker, EntityEvent, EntityReason } from '@app/entities'
import { ensureArray } from '@libs/ensureArray'
import { isGrantedEntity } from '@libs/rbac/functions'

/**
 * Filter reasons according to an event.
 */
export function filterReasonsForEvent(event: Maybe<EntityEvent>) {
  return (allReasons: EntityReason[]): EntityReason[] => {
    if (!event || !event.rbacReasons) {
      return allReasons
    }

    if (!isGrantedEntity(event.rbacReasons)) {
      return allReasons
    }

    const filteredReasonIds = event.rbacReasons.node.map(reason => reason.id)

    return allReasons.filter(reason => {
      return filteredReasonIds.indexOf(reason.getPropertyAsNumber('id')) !== -1
    })
  }
}

/**
 * Filter checkers according to an event.
 */
export function filterCheckersForEvent(event: Maybe<EntityEvent>) {
  return (allCheckers: EntityChecker[]): EntityChecker[] => {
    if (!event || !event.rbacDeviances) {
      return allCheckers
    }

    const allCheckerIds = ensureArray(
      event.getDeviances().map(deviance => deviance.checkerId)
    )

    return allCheckers.filter(checker => {
      return allCheckerIds.includes(checker.id)
    })
  }
}
