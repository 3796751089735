import type { EntityEvent } from '@app/entities'
import { AppRouteName } from '@app/routes'
import type { StoreInfrastructures, StoreTrailFlow } from '@app/stores'
import type StoreSearchBookmarks from '@app/stores/TrailFlow/StoreSearchBookmarks'
import type StoreSearchHistory from '@app/stores/TrailFlow/StoreSearchHistory'
import { HistoryMenuKey } from '@app/stores/TrailFlow/StoreSearchHistory'
import type { AppRouterClient } from '@libs/Router/types'
import { onPauseTrailFlow, onRestartTrailFlow } from '../handlers'
import { fetchNewerEvents, fetchOlderEvents } from './functions'

export const reloadData = (storeTrailFlow: StoreTrailFlow) => {
  storeTrailFlow.storeDrawerHistory.closeDrawer()
  storeTrailFlow.reloadData()
}

/**
 * When validating an expression in the input search.
 */
export const onTrailFlowExpressionSubmit =
  (storeTrailFlow: StoreTrailFlow) => () => {
    const { expressionAsString } =
      storeTrailFlow.storeInputExpression.expression

    if (expressionAsString) {
      storeTrailFlow.storeSearchHistory.createSearchHistoryEntry(
        expressionAsString
      )
    }

    reloadData(storeTrailFlow)
  }

/**
 * Search submitting a new search.
 */
export const onTrailFlowSearchSubmit =
  (storeTrailFlow: StoreTrailFlow) => () => {
    if (storeTrailFlow.storeInputExpression.validateEntry()) {
      const { expressionAsString } =
        storeTrailFlow.storeInputExpression.expression

      if (expressionAsString) {
        storeTrailFlow.storeSearchHistory.createSearchHistoryEntry(
          expressionAsString
        )
      }

      reloadData(storeTrailFlow)
    }
  }

/**
 * Clear the expression and reload.
 */
export const onTrailFlowExpressionCloseIconClick =
  (storeTrailFlow: StoreTrailFlow) => () => {
    storeTrailFlow.storeInputExpression.clearEntry()
    reloadData(storeTrailFlow)
  }

/**
 * When selecting an infra or a domain.
 */
export const onTrailFlowInputInfrastructuresChange =
  (storeTrailFlow: StoreTrailFlow) => () => {
    reloadData(storeTrailFlow)
  }

export const onTrailFlowFetchNewerEventsClick =
  (storeTrailFlow: StoreTrailFlow) => () => {
    fetchNewerEvents(storeTrailFlow)
  }

export const onTrailFlowFetchOlderEventsClick =
  (storeTrailFlow: StoreTrailFlow) => () => {
    fetchOlderEvents(storeTrailFlow)
  }

/**
 * When clicking on the start button, it pauses the TrailFlow.
 */
export const onTrailFlowStartButtonClick =
  (storeTrailFlow: StoreTrailFlow) => () => {
    onPauseTrailFlow(storeTrailFlow)()
  }

/**
 * When clicking on the pause button, it starts the TrailFlow.
 */
export const onTrailFlowPauseButtonClick =
  (storeTrailFlow: StoreTrailFlow) => () => {
    onRestartTrailFlow(storeTrailFlow)(null)()
  }

/**
 * When clicking on the event, open the event details blade.
 */
export const onTrailFlowEventRowClick =
  (appRouter: AppRouterClient, storeInfrastructures: StoreInfrastructures) =>
  (event: EntityEvent) =>
  () => {
    if (!event.directory) {
      return
    }

    const directoryId = event.directory.id
    const eventId = event.id

    if (!directoryId || !eventId) {
      return
    }

    const infrastructure =
      storeInfrastructures.getInfrastructureFromDirectoryId(directoryId)

    const infrastructureId = infrastructure && infrastructure.id

    if (!infrastructureId) {
      return
    }

    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.TrailFlow_EventDetails_Attributes,
      parameters: {
        infrastructureId,
        directoryId,
        eventId
      }
    })

    appRouter.history.push(url)
  }

/* TrailFlow Search History */

/**
 * Open drawer search history when trailflow search input is focused.
 */
export const onTrailFlowSearchFocus =
  (
    storeSearchHistory: StoreSearchHistory,
    storeSearchBookmarks: StoreSearchBookmarks
  ) =>
  () => {
    const { storeMenuHistory, storeDrawerHistory } =
      storeSearchHistory.storeRoot.stores.storeTrailFlow

    if (storeMenuHistory.selectedMenuKey === HistoryMenuKey.History) {
      storeSearchHistory.fetchSearchHistoryEntries()
    }

    // Always fetch bookmarks to display the existing bookmark icon
    storeSearchBookmarks.fetchSearchBookmarksEntries()

    storeDrawerHistory.openDrawer()
  }
