import {
  ContainerContent,
  ContainerRbac
} from '@app/components-legacy/Container'
import { FormWrapperSwitch } from '@app/components-legacy/Form/Wrappers'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import { useAppTranslator, useStores } from '@app/hooks'
import { LockoutPolicyFormFieldName } from '@app/stores/Management/StoreLockoutPolicy/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditLockoutPolicy, canReadLockoutPolicy } from '../permissions'
import {
  handleLockoutPolicyEnabledOnChange,
  handleLockoutPolicyOnLoad,
  handleLockoutPolicyOnUnLoad
} from './handlers'
import LockoutDurationField from './LockoutDurationField'
import LockoutFailedAttemptPeriod from './LockoutFailedAttemptPeriod'
import LockoutFailedAttemptThreshold from './LockoutFailedAttemptThreshold'

interface ILockoutPolicyFormProps {}

const LockoutPolicyForm: React.FC<ILockoutPolicyFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.LockoutPolicy']
  })

  const { storeRbac, storeLockoutPolicy } = useStores()

  const isLockoutPolicyEnabled =
    storeLockoutPolicy.storeFormLockoutPolicyConfiguration.getFieldValueAsBoolean(
      LockoutPolicyFormFieldName.enabled
    )

  // hide infinite checkboxes, handled manually in custom components
  const hiddenFields = isLockoutPolicyEnabled
    ? [
        LockoutPolicyFormFieldName.lockoutDurationInfinite,
        LockoutPolicyFormFieldName.failedAttemptPeriodInfinite
      ]
    : null

  const displayedFields = !isLockoutPolicyEnabled
    ? [LockoutPolicyFormFieldName.enabled]
    : null

  const canEditLockoutPolicyValue =
    storeRbac.isUserGrantedTo(canEditLockoutPolicy)

  return (
    <ContainerRbac rbacCapabilityCheck={canReadLockoutPolicy}>
      <ContainerContent
        name={LockoutPolicyForm.name}
        title={translate('Lockout policy')}
        onLoad={handleLockoutPolicyOnLoad(storeLockoutPolicy)}
        onUnload={handleLockoutPolicyOnUnLoad(storeLockoutPolicy)}
        flags={storeLockoutPolicy.storeFlagsFetchLockoutPolicy.flags}
      >
        <WidgetForm<LockoutPolicyFormFieldName>
          translate={translate}
          displayedFields={displayedFields}
          hiddenFields={hiddenFields}
          storeForm={storeLockoutPolicy.storeFormLockoutPolicyConfiguration}
          renderFields={{
            [LockoutPolicyFormFieldName.enabled]: () => (
              <FormWrapperSwitch
                labelledBy="enableLockoutPolicy"
                switchProps={{
                  size: 'small',
                  disabled: !canEditLockoutPolicyValue,
                  checked: isLockoutPolicyEnabled,
                  onChange: handleLockoutPolicyEnabledOnChange(
                    storeLockoutPolicy.storeFormLockoutPolicyConfiguration
                  )
                }}
              />
            ),

            [LockoutPolicyFormFieldName.lockoutDuration]: () => (
              <LockoutDurationField />
            ),

            [LockoutPolicyFormFieldName.failedAttemptThreshold]: () => (
              <LockoutFailedAttemptThreshold />
            ),

            [LockoutPolicyFormFieldName.failedAttemptPeriod]: () => (
              <LockoutFailedAttemptPeriod />
            )
          }}
        />
      </ContainerContent>
    </ContainerRbac>
  )
}

export default observer(LockoutPolicyForm)
