import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { AutoComplete, Input } from 'antd'
import type {
  AutoCompleteProps,
  DataSourceItemObject
} from 'antd/lib/auto-complete'
import type { SearchProps } from 'antd/lib/input'
import * as React from 'react'
import type { IFormWrapperControl } from './types'

/**
 * @deprecated
 */
export interface IFormWrapperInputAutoCompleteProps
  extends IFormWrapperControl {
  autoCompleteProps?: AutoCompleteProps
  /**
   * Force the object dataSource format.
   * https://ant.design/components/auto-complete/#Part-of-the-api-in-v3-are-not-available-in-v4
   */
  autoCompleteOptions: DataSourceItemObject[]
  inputSearchProps?: SearchProps
}

/**
 * @deprecated
 */
export const FormWrapperAutoComplete: React.FC<
  IFormWrapperInputAutoCompleteProps
> = props => {
  const { testAttributeProps } = useTestAttribute('form')

  const renderOption = (option: DataSourceItemObject) => {
    return {
      label: option.text,
      ...option
    }
  }

  return (
    <AutoComplete
      {...props.autoCompleteProps}
      {...testAttributeProps('listbox')(props.labelledBy)}
      popupClassName="honey-account-distinguished-name-finder"
      value={props.autoCompleteProps?.value}
      options={props.autoCompleteOptions.map(renderOption)}
    >
      <Input.Search {...props.inputSearchProps} />
    </AutoComplete>
  )
}

export default FormWrapperAutoComplete
