import type { Maybe } from '@@types/helpers'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import * as React from 'react'

const CheckerDetailsPageContext = React.createContext<
  Maybe<{
    checkerId: number
    checkerCodename: GenericCheckerCodename
  }>
>(null)

export { CheckerDetailsPageContext }
