import { LabelColorTag } from '@app/components/Labels'
import type { IReportConfigurationCreationOrDeletion } from '@app/entities/EntityUserLog'
import React from 'react'
import { colorUserLogRoleNameRedesign } from '../consts'

const UserLogReport = (props: IReportConfigurationCreationOrDeletion) => {
  const { reportName } = props

  return (
    <LabelColorTag
      color={colorUserLogRoleNameRedesign.background}
      labelProperties={{
        color: colorUserLogRoleNameRedesign.text,
        labelledBy: 'report-name'
      }}
    >
      {reportName}
    </LabelColorTag>
  )
}

export default UserLogReport
