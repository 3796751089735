import { UNIQ_CHECKER_OPTIONS_BY_CONFIGURATION } from '@alsid/common'
import type {
  CheckerOptionCodename,
  EntityGenericCheckerOption
} from '@app/entities/EntityGenericCheckerOption/types'
import { indexEntitiesToMap } from '@libs/indexEntitiesToMap'
import { isDefined } from '@libs/isDefined'
import { getLogger } from '@libs/logger'
import type { InputSetProfileCheckerOption } from '@server/graphql/typeDefs/types'
import { flatMapDeep } from 'lodash'
import type StoreProfileCheckerSerie from '../StoreProfileCheckerSerie'
import type {
  ICheckerOptionFieldMeta,
  IStoreProfileCheckerSerieOptions
} from '../types'
import { isSerieForAllDirectories } from './isSerieForAllDirectories'

/**
 * Return staged of commit options according to the profile status.
 */
export function filterCheckerOptionsAccordingProfileStatus<
  E extends EntityGenericCheckerOption
>(checkerOptions: E[], isCurrentProfileDirty: boolean): E[] {
  return (
    checkerOptions
      // keep commit or staged options depending of the profile dirty state
      .filter(option => option.staged === isCurrentProfileDirty)
  )
}

/**
 * Return a map of checker options entities of all options.
 * (the ones for all directories configuration)
 */
export function retrieveAllDirectoriesCheckerOptionsOfChecker<
  E extends EntityGenericCheckerOption
>(checkerOptions: E[]): Map<CheckerOptionCodename, E> {
  const allDirectoriesCheckerOptions = checkerOptions.filter(
    checkerOption => !isDefined(checkerOption.directoryId)
  )

  return indexEntitiesToMap<E, CheckerOptionCodename>(
    allDirectoriesCheckerOptions,
    'codename'
  )
}

/**
 * Return true if the option is valid for the current serie.
 * Some options can't be overriden into refined configuration.
 */
export function isValidOptionAccordingToConfiguration(
  storeSerieOptions: IStoreProfileCheckerSerieOptions<any, any>,
  checkerOptionCodename: CheckerOptionCodename
): boolean {
  if (
    !isSerieForAllDirectories(storeSerieOptions) &&
    UNIQ_CHECKER_OPTIONS_BY_CONFIGURATION.indexOf(checkerOptionCodename) !== -1
  ) {
    return false
  }

  return true
}

/**
 * Filter items that are empty strings
 * This has been mandatory for array/cron type that have a validation in Eridanis
 */
const sanitizeJSONArrayOfStrings = (json: string): string => {
  const data = JSON.parse(json)

  if (!Array.isArray(data)) {
    return json
  }

  return JSON.stringify(data.filter(item => item && String(item).length > 0))
}

/**
 * Build the checker-options from series (~ configurations) to sent to the API
 * for a new draft.
 */
export function buildCheckerOptionsForDraft(
  profileId: number,
  storesProfileCheckerSerie: Array<StoreProfileCheckerSerie<any, any>>
): InputSetProfileCheckerOption[] {
  const logger = getLogger()

  const deepCheckerOptions = storesProfileCheckerSerie.map(
    storeProfileCheckerSerie => {
      const optionValues = storeProfileCheckerSerie.encodeOptionValues()

      const directoryIds = isSerieForAllDirectories(
        storeProfileCheckerSerie.options
      )
        ? [-1] // use a number for "all directories" to keep type consistency
        : storeProfileCheckerSerie.storeInfrastructures.getSelectedDirectoryIds()

      return directoryIds.map(directoryId => {
        const checkerOptionCodenames =
          storeProfileCheckerSerie.storeForm.getFieldNames() as CheckerOptionCodename[]

        return checkerOptionCodenames
          .map(checkerOptionCodename => {
            const field = storeProfileCheckerSerie.storeForm.field(
              checkerOptionCodename
            )

            const fieldMeta = field.meta.get<ICheckerOptionFieldMeta>('meta')

            if (!fieldMeta) {
              logger.warn(
                'Field has not meta, skipping for option',
                checkerOptionCodename
              )

              return null
            }

            // do not same options that can't be override into refined configurations
            if (
              !isValidOptionAccordingToConfiguration(
                storeProfileCheckerSerie.options,
                checkerOptionCodename
              )
            ) {
              return
            }

            const value = sanitizeJSONArrayOfStrings(
              optionValues.get(checkerOptionCodename) || ''
            )

            const checkerOption: InputSetProfileCheckerOption = {
              checkerId: fieldMeta.checkerId,
              valueType: fieldMeta.valueType,
              codename: checkerOptionCodename,
              profileId,
              directoryId: directoryId === -1 ? null : directoryId,
              value
            }

            return checkerOption
          })
          .filter(isDefined)
      })
    }
  )

  return flatMapDeep<InputSetProfileCheckerOption>(deepCheckerOptions)
}
