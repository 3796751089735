import type { Maybe } from '@@types/helpers'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '.'
import type { IContainerFlexProps } from './ContainerFlex/types'

const StyledChildrenContainer = styled.div<{
  overflow?: React.CSSProperties['overflow']
  boxShadow?: React.CSSProperties['boxShadow']
}>`
  ${props => props.overflow && `overflow: ${props.overflow};`}
  ${props => props.boxShadow && `box-shadow: ${props.boxShadow};`}
`

export interface IContainerScrollProps {
  className?: string
  paddingH?: Maybe<IContainerFlexProps['paddingH']>
  paddingV?: Maybe<IContainerFlexProps['paddingV']>
  handleScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void
  childrenContainerOverflow?: React.CSSProperties['overflow']
  childrenContainerBoxShadow?: React.CSSProperties['boxShadow']
  children?: React.ReactNode
}

const ContainerScroll: React.FC<IContainerScrollProps> = props => {
  return (
    <ContainerFlex
      name="ContainerScroll"
      className={props.className}
      direction="column"
      itemsFlexGrow={[1, 0]}
      items={[
        <StyledChildrenContainer
          overflow={props.childrenContainerOverflow}
          boxShadow={props.childrenContainerBoxShadow}
        >
          {props.children}
        </StyledChildrenContainer>,
        /**
         * Uggly workaround for https://github.com/w3c/csswg-drafts/issues/129.
         * When having overflow-y, padding-bottom is ignored!
         * So the trick consists to set empty div at the end of a column flex container.
         */
        <div></div>
      ]}
      paddingH={props.paddingH}
      paddingV={props.paddingV ?? 'medium'}
      fullHeight
      onScroll={props.handleScroll}
    />
  )
}

export default styled(ContainerScroll)`
  overflow-y: auto;
`
