import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import {
  InputCheckersCards,
  InputCriticity
} from '@app/components-legacy/Input'
import InputHealthChecks from '@app/components-legacy/Input/InputHealthChecks'
import type { StoreManagementEmails } from '@app/stores'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type { Maybe } from '@server/graphql/typeDefs/types'
import { EmailInputType } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import {
  onEmailCriticityThresholdChanges,
  onHealthCheckNamesChange
} from './handlers'
import { EmailFormFieldName } from './types'

interface IFieldsForInputNameParams {
  storeManagementEmails: StoreManagementEmails
  translate: (s: string) => string
}

/**
 * Return fields according to the trigger selection.
 * (on changes, on deviances, on attacks)
 */
export default function getFieldsForInputType(
  params: IFieldsForInputNameParams
): IField[] {
  const inputTypeName =
    params.storeManagementEmails.storeForm.getFieldValueAsString<
      Maybe<EmailInputType>
    >(EmailFormFieldName.inputType)

  // could be null at loading
  if (!inputTypeName) {
    return []
  }

  switch (inputTypeName) {
    case EmailInputType.Deviances:
      return onEachDevianceFields(params)

    case EmailInputType.Attacks:
      return onEachAttackFields(params)

    case EmailInputType.HealthChecks:
      return onHealthCheckStatusChangeField(params)

    default:
      assertUnreachableCase(inputTypeName)
  }
}

function onEachDevianceFields(params: IFieldsForInputNameParams): IField[] {
  const { storeManagementEmails, translate } = params

  return [
    {
      name: 'criticityThreshold',
      label: translate('Criticity threshold'),
      description: translate(
        'Threshold of severity from which the alerts of the indicators will be sent'
      ),
      required: true,
      control: (
        <InputCriticity
          criticityValue={storeManagementEmails.storeForm.getFieldValueAsNumber(
            EmailFormFieldName.criticityThreshold
          )}
          onChange={onEmailCriticityThresholdChanges(storeManagementEmails)}
        />
      )
    },
    {
      name: 'indicatorsOfExposure',
      label: translate('Indicators of Exposure'),
      errors: storeManagementEmails.storeForm.field(EmailFormFieldName.checkers)
        .errors,
      control: (
        <InputCheckersCards
          criticityThreshold={storeManagementEmails.storeForm.getFieldValueAsNumber(
            EmailFormFieldName.criticityThreshold
          )}
          storeInputGenericCheckers={
            storeManagementEmails.storeInputCheckersExposure
          }
          loadCheckersAtLoading
        />
      )
    }
  ]
}

function onEachAttackFields(params: IFieldsForInputNameParams): IField[] {
  const { storeManagementEmails, translate } = params

  return [
    {
      name: 'criticityThreshold',
      label: translate('Criticity threshold'),
      description: translate(
        'Threshold of severity from which the alerts of the indicators will be sent'
      ),
      required: true,
      control: (
        <InputCriticity
          criticityValue={storeManagementEmails.storeForm.getFieldValueAsNumber(
            EmailFormFieldName.criticityThreshold
          )}
          onChange={onEmailCriticityThresholdChanges(storeManagementEmails)}
        />
      )
    },
    {
      name: 'indicatorsOfAttack',
      label: translate('Indicators of Attack'),
      errors: storeManagementEmails.storeForm.field(
        EmailFormFieldName.attackTypes
      ).errors,
      control: (
        <InputCheckersCards
          criticityThreshold={storeManagementEmails.storeForm.getFieldValueAsNumber(
            EmailFormFieldName.criticityThreshold
          )}
          storeInputGenericCheckers={
            storeManagementEmails.storeInputCheckersAttacks
          }
          loadCheckersAtLoading
        />
      )
    }
  ]
}

function onHealthCheckStatusChangeField(
  params: IFieldsForInputNameParams
): IField[] {
  return [
    {
      name: EmailFormFieldName.healthCheckNames,
      label: params.translate('Health checks'),
      labelAlignItem: 'center',
      required: true,
      errors: params.storeManagementEmails.storeForm.field(
        EmailFormFieldName.healthCheckNames
      ).errors,
      control: (
        <InputHealthChecks
          loadAtMount
          withExpand
          onSubmit={onHealthCheckNamesChange(params.storeManagementEmails)}
          storeInputHealthChecks={
            params.storeManagementEmails.storeInputHealthChecks
          }
        />
      )
    }
  ]
}
