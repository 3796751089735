import { Features } from '@alsid/common'
import { AppRouteName } from '@app/routes'
import type { StoreLayout, StoreRoot } from '@app/stores'
import type { GlobalInventoryPageConfigurator } from 'tenable-cyber-asset-management/dist/sdk/src/GlobalInventoryApp/GlobalInventoryPageConfigurator'
import type { IMainMenuEntry } from './types'

/**
 * Init entries of the main navigation.
 */
export const handleMainMenuOnLoad = (storeLayout: StoreLayout) => () => {
  const isLegacyMenu =
    !storeLayout.storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
      Features.KAPTEYN_LEFT_NAVIGATION_UI
    )

  if (isLegacyMenu) {
    storeLayout.initializeMainNavigationLegacy()
    return
  }

  storeLayout.initializeMainNavigation()
}

/**
 * Navigate to next page when clicking on a menu item.
 */
export const handleMenuItemOnClick = (storeRoot: StoreRoot) => {
  return (
    menuItem: IMainMenuEntry,
    globalInventoryPageConfigurator: GlobalInventoryPageConfigurator
  ) => {
    if (!menuItem.routeDefinition) {
      return
    }

    const isCurrentPage =
      menuItem.routeDefinition?.routeName ===
      storeRoot.appRouter.getCurrentRouteName()

    /**
     * if next navigation is the same as the current page and the page is a GI page, we want to load the iframe again.
     * Otherwise, if  the user is in identity details and we use react navigation, nothing will happen because the route is still the same as far as the the client app is concerned
     */
    if (
      isCurrentPage &&
      globalInventoryPageConfigurator.globalInventoryApp
        ?.isGlobalInventoryPageMounted
    ) {
      globalInventoryPageConfigurator.globalInventoryApp?.setPage(
        menuItem.routeDefinition.routeName
      )
      return
    }

    /** Security Engine reload */
    if (menuItem.routeDefinition.routeName === AppRouteName.SecurityEngine) {
      storeRoot.incrementSecurityEngineKey()
    }

    storeRoot.appRouter.history.push(
      storeRoot.appRouter.makeRouteInfosPathname(menuItem.routeDefinition)
    )
  }
}
