import { AppRouteName } from '@app/routes'
import type { StoreIdentityExplorer } from '@app/stores'
import type { ISubmitFormParameters } from '@libs/submitForm'
import { submitForm } from '@libs/submitForm'
import type { IExportParametersIdentities } from '@server/routers/exportCSVRouter/types'

/**
 * Export identities.
 */
export const handleExportIdentitiesOnSubmit =
  (storeIdentityExplorer: StoreIdentityExplorer) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeRoot } = storeIdentityExplorer
    const { storeMessages } = storeIdentityExplorer.storeRoot.stores
    const { appRouter } = storeIdentityExplorer.storeRoot

    const pathname = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareExportCSV,
      parameters: {}
    })

    // TODO - Maybe add sorting here? Keeping it null for the moment
    const { tenantIds, directoryUuids, textQuery } =
      storeIdentityExplorer.identitiesParametersFromFilters(null)

    const parameters: ISubmitFormParameters<IExportParametersIdentities> = {
      parameters: {
        routine: 'Identities',
        contextData: {
          tenantIds,
          directoryUuids,
          textQuery
        }
      },
      language: storeMessages.storeRoot.appTranslator.language,
      'csrf-token': storeRoot.environment.sessionParams.csrfToken
    }

    submitForm(pathname, parameters, 'POST')
  }
