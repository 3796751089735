import type { PropertiesNullable } from '@@types/helpers'
import { ValueType } from '@libs/valueTypeParser/types'
import type { Maybe, ObjectAttribute } from '@server/graphql/typeDefs/types'

export default class EntityObjectAttribute
  implements PropertiesNullable<ObjectAttribute>
{
  name: Maybe<string> = null
  value: Maybe<string> = null
  valueType: Maybe<ValueType> = null

  constructor(data: Partial<ObjectAttribute>) {
    Object.assign(this, data)
  }

  /**
   * Return the valueType of the change or fallback on string.
   */
  getValueType(): ValueType {
    return this.valueType || ValueType.string
  }
}
