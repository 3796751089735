import { EntityAttackPathEdge, EntityAttackPathNode } from '@app/entities'
import { hasControlRightRelationTypes } from '@app/entities/EntityAttackPathEdge'
import type {
  IAttackPathCell,
  IAttackPathCoords
} from '@app/pages/AttackPath/types'
import type { StoreInfrastructures } from '@app/stores'
import { filterFalsies } from '@libs/filterFalsies'
import { addSetValueToMap } from '@libs/setValueToMap'
import type {
  AttackPathEdge,
  AttackPathNode
} from '@server/graphql/typeDefs/types'
import {
  AttackPathDirection,
  AttackPathRelationType
} from '@server/graphql/typeDefs/types'
import { v4 as v4uuid } from 'uuid'
import { MAX_CHILDREN_BEFORE_HIDING_THEM } from './consts'
import type {
  EntityAttackPathNodeAny,
  IAttackPathEdgeExtended,
  IndexedEdgesByNode,
  INodeWithEdge
} from './types'

/**
 * Utility method for generating a boolean array with a value for each entry.
 */
export const generateEmptyBooleanArray = (
  nbValues: number,
  defaultValue: boolean
): boolean[] => {
  return new Array(nbValues).fill(defaultValue)
}

export const getMaxCellOnARing = (ringIndex: number) => {
  return Math.max(1, ringIndex * 8)
}

/**
 * Return spreadsheet cell (row, column) from an onion ring cell.
 */
export const getSpreadsheetCellFromOnionRingCell = (args: {
  maxOnionRingIndex: number
  onionRingIndex: number
  onionRingCellIndex: number
}): IAttackPathCell => {
  const { maxOnionRingIndex, onionRingIndex, onionRingCellIndex } = args

  const initialRowColumn = maxOnionRingIndex - onionRingIndex

  const maxCellsOnRing = getMaxCellOnARing(onionRingIndex)

  if (onionRingIndex > 0) {
    const topRightCellIndex = maxCellsOnRing / 4
    const bottomRightCellIndex = maxCellsOnRing / 2
    const bottomLeftCellIndex = (3 * maxCellsOnRing) / 4

    // For cells on top line
    if (onionRingCellIndex <= topRightCellIndex) {
      return {
        row: initialRowColumn,
        column: initialRowColumn + onionRingCellIndex,
        onionRingCellIndex,
        onionRingIndex
      }
    }

    // For cells on right line
    if (
      onionRingCellIndex > topRightCellIndex &&
      onionRingCellIndex <= bottomRightCellIndex
    ) {
      return {
        row: initialRowColumn + onionRingCellIndex - topRightCellIndex,
        column: initialRowColumn + topRightCellIndex,
        onionRingCellIndex,
        onionRingIndex
      }
    }

    // For cells on bottom line
    if (
      onionRingCellIndex > bottomRightCellIndex &&
      onionRingCellIndex <= bottomLeftCellIndex
    ) {
      return {
        row: initialRowColumn + bottomRightCellIndex - topRightCellIndex,
        column: initialRowColumn + bottomLeftCellIndex - onionRingCellIndex,
        onionRingCellIndex,
        onionRingIndex
      }
    }

    // For cells on left line
    return {
      row: initialRowColumn + maxCellsOnRing - onionRingCellIndex,
      column: initialRowColumn,
      onionRingCellIndex,
      onionRingIndex
    }
  }

  return {
    row: initialRowColumn,
    column: initialRowColumn,
    onionRingCellIndex,
    onionRingIndex
  }
}

/**
 * Sort nodes by depth.
 */
export const sortByDepth = (
  a: EntityAttackPathNode,
  b: EntityAttackPathNode
) => {
  if (a.depth === null || b.depth === null) {
    return 0
  }
  return a.depth < b.depth ? -1 : 1
}

/**
 * Return a rotated point around another one with an angle (in degree).
 */
export const rotatePointAroundPivot = (
  point: IAttackPathCoords,
  pivot: IAttackPathCoords,
  angle: number
) => {
  const radianAngle = (angle * Math.PI) / 180
  const xM = point.x - pivot.x
  const yM = point.y - pivot.y

  const x = xM * Math.cos(radianAngle) + yM * Math.sin(radianAngle) + pivot.x
  const y = -xM * Math.sin(radianAngle) + yM * Math.cos(radianAngle) + pivot.y
  return { x: Math.round(x), y: Math.round(y) }
}

/**
 * This method will search all connected nodes and edges related to the one
 * passed as an argument.
 * The approach is to find every nodes attached via edges, create an entity
 * with unique id, then recursively search for linked nodes via edges.
 */
export const findInDepthLinkedEntities = (
  maxDepth: number,
  direction: AttackPathDirection,
  baseNodeEntity: EntityAttackPathNode,
  rawNodes: AttackPathNode[],
  rawEdges: IAttackPathEdgeExtended[]
): {
  nodes: Map<string, EntityAttackPathNode>
  edges: EntityAttackPathEdge[]
} => {
  const nodes = new Map<string, EntityAttackPathNode>()
  const edges: EntityAttackPathEdge[] = []

  // in order to have the count and the drawer at the rootNode level, we need it into the nodes map.
  // for the expanded use case on the other hand, we dont want to have the node that has been clicked into the map.
  if (baseNodeEntity.depth === 0) {
    nodes.set(baseNodeEntity.uid, baseNodeEntity)
  }

  if (baseNodeEntity.depth >= maxDepth) {
    return { nodes, edges }
  }

  const directionFrom = direction === AttackPathDirection.From

  const indexedNodes: Map<number, AttackPathNode> = rawNodes.reduce(
    (map, node) => {
      map.set(node.id, node)

      return map
    },
    new Map()
  )

  const directionStartKey = directionFrom ? 'sourceId' : 'targetId'
  const directionEndKey = directionFrom ? 'targetId' : 'sourceId'

  function getChildrenEdges(id: number): IAttackPathEdgeExtended[] {
    return rawEdges.filter(edge => {
      // Select only nodes that are destination or source (depending on the direction chosen) of the parentNode
      return edge[directionStartKey] === id
    })
  }

  if (!baseNodeEntity.id) {
    return { nodes, edges: [] }
  }

  const nodesIdsPathsToVisit: number[][] = [[baseNodeEntity.id]]
  const nodesEntitiesPathsToVisit: EntityAttackPathNode[][] = [[baseNodeEntity]]
  const nodesEntitiesToSet: EntityAttackPathNode[] = []
  const edgesEntitiesToPush: EntityAttackPathEdge[] = []

  while (nodesIdsPathsToVisit.length > 0) {
    const nodeEntityToSet = nodesEntitiesToSet.pop()
    const edgeEntityToPush = edgesEntitiesToPush.pop()

    if (nodeEntityToSet) {
      nodes.set(nodeEntityToSet.uid, nodeEntityToSet)
    }

    if (edgeEntityToPush) {
      edges.push(edgeEntityToPush)
    }

    const currentNodePath = nodesIdsPathsToVisit.pop()
    const currentNodeEntitiesPath = nodesEntitiesPathsToVisit.pop()

    if (!currentNodePath || !currentNodeEntitiesPath) {
      break
    }

    const currentNodeId = currentNodePath[currentNodePath.length - 1]

    const currentNodeEntity =
      currentNodeEntitiesPath[currentNodeEntitiesPath.length - 1]

    if (currentNodeEntity.depth >= maxDepth) {
      continue
    }

    const childrenEdges = getChildrenEdges(currentNodeId)

    for (let i = childrenEdges.length - 1; i >= 0; i--) {
      const childEdge = childrenEdges[i]
      const childNodeId = childEdge[directionEndKey]
      const linkedNode = indexedNodes.get(childNodeId)

      const depth = currentNodeEntity.depth + 1
      const rootNodeHasTooManyChildren =
        currentNodeEntity.depth === 0 &&
        currentNodeEntity.nextDepthNodeCount &&
        currentNodeEntity.nextDepthNodeCount > MAX_CHILDREN_BEFORE_HIDING_THEM

      // Create the entity for the linked node
      const linkedNodeEntity = new EntityAttackPathNode({
        ...linkedNode,
        uid: v4uuid(),
        depth,
        linkedNodeEntity: currentNodeEntity,
        nextDepthNodeCount: rootNodeHasTooManyChildren
          ? linkedNode?.nextDepthNodeCount
          : depth === maxDepth
            ? linkedNode?.nextDepthNodeCount
            : 0
      })

      const sourceUid = directionFrom
        ? currentNodeEntity.uid
        : linkedNodeEntity.uid
      const targetUid = directionFrom
        ? linkedNodeEntity.uid
        : currentNodeEntity.uid

      // Create the edge entity with all needed parameters
      const edgeEntity = new EntityAttackPathEdge({
        ...childEdge,
        id: v4uuid(),
        sourceUid, // adding uid for easy finding later
        targetUid // adding uid for easy finding later
      })

      // Skip nodes already explored in this path
      if (currentNodePath.includes(childNodeId)) {
        nodes.set(linkedNodeEntity.uid, linkedNodeEntity)
        edges.push(edgeEntity)
        continue
      }

      nodesIdsPathsToVisit.push([...currentNodePath, childNodeId])
      nodesEntitiesPathsToVisit.push([
        ...currentNodeEntitiesPath,
        linkedNodeEntity
      ])

      nodesEntitiesToSet.push(linkedNodeEntity)
      edgesEntitiesToPush.push(edgeEntity)
    }
  }

  return { nodes, edges }
}

export const isEntityAttackPathNode = (
  node: EntityAttackPathNodeAny
): node is EntityAttackPathNode => {
  if ((node as EntityAttackPathNode).adObjectType) {
    return true
  }
  return false
}

export const combineRelationsTypesInEdges = (
  edges: AttackPathEdge[]
): IAttackPathEdgeExtended[] => {
  // Construct a map for all relations between two nodes
  const relationsByNodes = edges.reduce((acc, edge) => {
    addSetValueToMap(
      acc,
      `${edge.sourceId}_${edge.targetId}`, // do not use - as a separator, it would conflict with negative values
      edge.relationType
    )
    return acc
  }, new Map<string, Set<AttackPathRelationType>>())

  return Array.from(relationsByNodes.entries()).reduce(
    (acc: IAttackPathEdgeExtended[], relationsForSource) => {
      const [key, relationsSet] = relationsForSource

      const [sourceId, targetId] = key.split('_').map(item => Number(item))

      const relations = Array.from(relationsSet.values())

      if (relations.length === 0) {
        return acc
      }

      const edge: IAttackPathEdgeExtended = {
        sourceId,
        targetId,
        relationTypes: []
      }

      const controlRightRelations: AttackPathRelationType[] = []

      relations.forEach(relation => {
        if (hasControlRightRelationTypes.includes(relation)) {
          controlRightRelations.push(relation)
        } else {
          edge.relationTypes.push(relation)
        }
      })

      if (controlRightRelations.length > 0) {
        edge.controlRightRelations = controlRightRelations
        edge.relationTypes.push(AttackPathRelationType.HasControlRight)
      }

      acc.push(edge)

      return acc
    },
    []
  )
}

export const generateIndexedEdges = (
  edges: EntityAttackPathEdge[],
  direction: AttackPathDirection
): IndexedEdgesByNode => {
  return edges.reduce((indexedEdges, edge) => {
    const key =
      direction === AttackPathDirection.From ? edge.sourceUid : edge.targetUid
    const value =
      direction === AttackPathDirection.From ? edge.targetUid : edge.sourceUid

    addSetValueToMap(indexedEdges, key, value)

    return indexedEdges
  }, new Map())
}

/**
 * Function that will count children of a given node.
 */
export const getChildrenCount = (
  nodeUId: string,
  indexedEdges: IndexedEdgesByNode
): number => {
  return indexedEdges.get(nodeUId)?.size ?? 0
}

export const groupNodes = (
  linkedNodes: EntityAttackPathNode[],
  linkedEdges: EntityAttackPathEdge[],
  direction: AttackPathDirection
) => {
  const indexedEdges = generateIndexedEdges(linkedEdges, direction)

  // Assign count for every edge to determine which one should hide its children.
  linkedNodes.forEach(node => {
    const count = getChildrenCount(node.uid, indexedEdges)

    node.setChildrenCount(count)
  })

  // Find nodes that have their number of children exceeding the threshold
  const nodesThatHaveTooManyChildren = linkedNodes
    .filter(node => node.hasTooManyChildren)
    .map(node => node.uid)

  // Filter all children that are linked to a node that exceed the threshold
  const groupedNodes = linkedNodes.filter(node => {
    for (const heavyNodeUid of nodesThatHaveTooManyChildren) {
      const theHeavyNode = node.uid === heavyNodeUid

      if (!theHeavyNode && node.hasNodeInHierarchy(heavyNodeUid)) {
        return false
      }
    }

    return true
  })

  return groupedNodes
}

/**
 * Return a grouped list of nodes by parent.
 * The map has the parent Id as a key and children nodes as value.
 */
export const groupNodesByParent = (nodes: EntityAttackPathNodeAny[]) => {
  return nodes.reduce((nodesByParentAcc, node) => {
    if (node.linkedNodeEntity && node.linkedNodeEntity.uid) {
      const nodesByParent =
        nodesByParentAcc.get(node.linkedNodeEntity.uid) ?? []
      nodesByParent.push(node)
      nodesByParentAcc.set(node.linkedNodeEntity.uid, nodesByParent)
    }
    return nodesByParentAcc
  }, new Map<string, EntityAttackPathNodeAny[]>())
}

/**
 * Compute edges properties so that they are linked to a source and target entity
 * and svg properties are computed once.
 */
export const computeEdgesProperties = (
  nodes: EntityAttackPathNodeAny[],
  edges: EntityAttackPathEdge[]
): EntityAttackPathEdge[] => {
  // Index nodes by uid
  const nodesByUid: Map<string, EntityAttackPathNodeAny> = nodes.reduce(
    (acc, node) => {
      acc.set(node.uid, node)
      return acc
    },
    new Map()
  )

  // Attach source and target entities to edges
  const enhancedEdges = edges.map(edge => {
    if (!edge.sourceUid || !edge.targetUid) {
      return null
    }

    const source = nodesByUid.get(edge.sourceUid)
    const target = nodesByUid.get(edge.targetUid)

    if (!source || !target) {
      return edge
    }

    // Attach entities to the edge.
    // It's done here because we need to have fully qualified entities (with onion placement data)
    edge._setSource(source)
    edge._setTarget(target)

    if (!edge.source || !edge.target) {
      return null
    }

    // Launch computation of graphics properties
    edge.computeSvgProps()

    return edge
  })

  return filterFalsies(enhancedEdges)
}

/**
 * Sort an array of edges and associated node by node name then directory name
 */
export function sortNodesWithEdges(
  nodesWithEdge: INodeWithEdge[],
  storeInfrastructures: StoreInfrastructures
) {
  return nodesWithEdge.sort((nodeWithEdge1, nodeWithEdge2) => {
    const { name: nodeName1 } = nodeWithEdge1.node
    const { name: nodeName2 } = nodeWithEdge2.node

    if (!nodeName1 && nodeName2) {
      return -1
    }

    if (!nodeName2 && nodeName1) {
      return 1
    }

    const nameResult =
      nodeName1 && nodeName2 ? nodeName1.localeCompare(nodeName2) : 0

    if (nameResult !== 0) {
      return nameResult
    }

    const { directoryId: directoryId1 } = nodeWithEdge1.node
    const { directoryId: directoryId2 } = nodeWithEdge2.node

    if (!directoryId1 && directoryId2) {
      return -1
    }

    if (!directoryId2 && directoryId1) {
      return 1
    }

    const directoryName1 =
      directoryId1 &&
      storeInfrastructures.getDirectoryFromId(directoryId1)?.name
    const directoryName2 =
      directoryId2 &&
      storeInfrastructures.getDirectoryFromId(directoryId2)?.name

    if (!directoryName1 && directoryName2) {
      return -1
    }

    if (!directoryName2 && directoryName1) {
      return 1
    }

    return directoryName1 && directoryName2
      ? directoryName1.localeCompare(directoryName2)
      : 0
  })
}

/**
 * Rotate coordinates x, y from center cx, xy by a certain angle.
 */
export function rotateCoords(
  cx: number,
  cy: number,
  x: number,
  y: number,
  angle: number
) {
  const radians = (Math.PI / 180) * angle
  const cos = Math.cos(radians)
  const sin = Math.sin(radians)
  const nx = cos * (x - cx) + sin * (y - cy) + cx
  const ny = cos * (y - cy) - sin * (x - cx) + cy
  return [nx, ny]
}
