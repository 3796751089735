import { Features } from '@alsid/common'
import { useHistoryChange } from '@app/components-legacy/Router/hooks'
import { useAppRouter, useStores } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { isTelemetryDisableOnRoute } from '@libs/telemetry/utils'
import * as React from 'react'

/**
 * Handle telemetry when a page loads or unloads.
 */
export function usePageLifeCycleTelemetry() {
  const featureTelemetry = useFeatureFlag({
    featureFlagName: Features.TELEMETRY
  })

  const appRouter = useAppRouter()
  const { storeTelemetry } = useStores()
  const { currentRouteInfo } = useHistoryChange()

  const currentRouteName = currentRouteInfo?.routeName || null
  const isTelemetryDisableOnRouteFn = isTelemetryDisableOnRoute(appRouter)

  // Handles telemetry when the page loads for the first time of a session
  const onRouteEnterTelemetry = React.useCallback(() => {
    if (!featureTelemetry) {
      return
    }

    if (!currentRouteName) {
      return
    }

    if (isTelemetryDisableOnRouteFn(currentRouteName)) {
      return
    }

    storeTelemetry.logPageVisited(currentRouteName)
  }, [currentRouteName])

  // Handles telemetry when the page unloads
  const onRouterLeaveTelemetry = React.useCallback(() => {
    if (!featureTelemetry) {
      return
    }

    if (!currentRouteName) {
      return
    }

    if (isTelemetryDisableOnRouteFn(currentRouteName)) {
      return
    }

    storeTelemetry.logPageLeft(currentRouteName)
  }, [currentRouteName])

  /**
   * Log telemetry events when a page loads and unloads.
   */
  React.useEffect(() => {
    onRouteEnterTelemetry()

    return () => {
      onRouterLeaveTelemetry()
    }
  }, [currentRouteName])
}
