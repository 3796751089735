/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { ProviderType } from './ProviderType'
import {
  ProviderTypeFromJSON,
  ProviderTypeFromJSONTyped,
  ProviderTypeToJSON
} from './ProviderType'

/**
 *
 * @export
 * @interface Tenant
 */
export interface Tenant {
  /**
   *
   * @type {string}
   * @memberof Tenant
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Tenant
   */
  name: string
  /**
   *
   * @type {ProviderType}
   * @memberof Tenant
   */
  providerType?: ProviderType
}

/**
 * Check if a given object implements the Tenant interface.
 */
export function instanceOfTenant(value: object): boolean {
  if (!('id' in value)) return false
  if (!('name' in value)) return false
  return true
}

export function TenantFromJSON(json: any): Tenant {
  return TenantFromJSONTyped(json, false)
}

export function TenantFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Tenant {
  if (json == null) {
    return json
  }
  return {
    id: json['id'],
    name: json['name'],
    providerType:
      json['provider_type'] == null
        ? undefined
        : ProviderTypeFromJSON(json['provider_type'])
  }
}

export function TenantToJSON(value?: Tenant | null): any {
  if (value == null) {
    return value
  }
  return {
    id: value['id'],
    name: value['name'],
    provider_type: ProviderTypeToJSON(value['providerType'])
  }
}
