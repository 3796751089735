import type { Maybe } from '@@types/helpers'
import { ContainerFlex, ContainerHTMLContent } from '@app/components/Container'
import { IconInfo } from '@app/components/Icon'
import { useAppRouter, useAppTranslator } from '@app/hooks'
import { AppRouteName } from '@app/routes'
import { FontColorV2 } from '@app/styles/consts'
import { caseNotHandled } from '@libs/helpers/objects/caseNotHandled'
import { DataCollectionNotWorkingReason } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'

interface IConfigurationDataCollectionErrorMessageProps {
  reason: DataCollectionNotWorkingReason
}

const StyledInfoIcon = styled(IconInfo)`
  margin-top: 2px;
`

const ConfigurationDataCollectionErrorMessage: React.FC<
  IConfigurationDataCollectionErrorMessageProps
> = props => {
  const appRouter = useAppRouter()

  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.DataCollection']
  })

  function getErrorMessage(): Maybe<string> {
    switch (props.reason) {
      case DataCollectionNotWorkingReason.AuthenticationFailure: {
        return translate(DataCollectionNotWorkingReason.AuthenticationFailure, {
          transformMarkdown: true,
          interpolations: {
            uploadLicensePath: appRouter.makeRouteInfosPathname({
              routeName: AppRouteName.Management_System_About,
              parameters: {}
            })
          }
        })
      }

      case DataCollectionNotWorkingReason.DatacollectionapitokenRetrievalFailure: {
        return translate(
          DataCollectionNotWorkingReason.DatacollectionapitokenRetrievalFailure,
          {
            transformMarkdown: true,
            interpolations: {
              uploadLicensePath: appRouter.makeRouteInfosPathname({
                routeName: AppRouteName.Management_System_About,
                parameters: {}
              })
            }
          }
        )
      }

      case DataCollectionNotWorkingReason.ObsoleteLicense: {
        return translate(DataCollectionNotWorkingReason.ObsoleteLicense, {
          transformMarkdown: true,
          interpolations: {
            uploadLicensePath: appRouter.makeRouteInfosPathname({
              routeName: AppRouteName.Management_System_About,
              parameters: {}
            })
          }
        })
      }

      default: {
        return caseNotHandled(props.reason, '', 'Reason not handled')
      }
    }
  }

  return (
    <ContainerFlex
      name="ConfigurationDataCollectionErrorMessage"
      itemsFlexShrink={[0, 1]}
      items={[
        <StyledInfoIcon size={16} color={FontColorV2.secondary} />,

        <ContainerHTMLContent
          labelledBy={`tenable-cloud-error-${props.reason}`}
        >
          {getErrorMessage()}
        </ContainerHTMLContent>
      ]}
      spaced
      spaceWidth="smaller"
    />
  )
}

export default ConfigurationDataCollectionErrorMessage
