import type { Maybe } from '@@types/helpers'
import { ReflectedPromiseStatus } from '@server/graphql/typeDefs/types'

export interface IReflectedPromise<T> {
  node?: Maybe<T>
  err?: Maybe<string>
  status: ReflectedPromiseStatus
}

/**
 * Returns a promise that is always successful when this promise is settled.
 * Its fulfillment value is an object that reflects the resolution of this promise.
 *
 * Usage:
 *
 * Promise.all(allPromises.map(promiseReflect)).then((results) => {
 *   const successes = results.filter(r => r.status === ReflectedPromiseStatus.fulfilled)
 * });
 */
export function promiseReflect<T = any>(
  promise: Promise<T>
): Promise<IReflectedPromise<T>> {
  return promise
    .then(node => {
      return {
        node,
        err: null,
        status: ReflectedPromiseStatus.Fulfilled
      }
    })
    .catch(err => {
      return {
        node: null,
        err: String(err),
        status: ReflectedPromiseStatus.Rejected
      }
    })
}

/**
 * Return true if at least one promise status is rejected.
 */
export function haveReflectedPromisesFailed<T>(
  reflectedLikePromise: Array<IReflectedPromise<T>>
) {
  const failUpdates = reflectedLikePromise.filter(
    r => r.status === ReflectedPromiseStatus.Rejected
  )

  return failUpdates.length > 0
}
