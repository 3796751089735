import type { Maybe } from '@@types/helpers'
import {
  allSidsPatterns,
  sddlAceFlags,
  sddlAceRights,
  sddlAceTypes,
  sddlDaclFlags,
  sddlSids,
  wellKnownSids,
  wellKnownGuidsWithSelfWrite,
  wellKnownGuidsWithWriteProperties,
  wellKnownGuidsWithControlAccess,
  wellKnownExtendedGuids
} from './consts'
import { WellKnownExtendedGuidsEnum, ACETitle } from './types'

/**
 * Return a user-friendly name for key that could be anything part of the SDDL.
 */
export function getUserFriendlyDefinition(
  title: Maybe<string>,
  key: string
): string {
  if (typeof key !== 'string') {
    return 'N/A'
  }

  const maps = getMapsByTitle(title)

  for (const map of maps) {
    if (map.has(key)) {
      return map.get(key) || key
    }
  }

  // try to find a pattern that could match
  for (const regexp of allSidsPatterns.keys()) {
    if (regexp.test(key)) {
      const validGuid = regexp
        .toString()
        .replace('$/', '')
        .replace('/^', '') as WellKnownExtendedGuidsEnum

      // Add info char for extended GUIDs only
      if (WellKnownExtendedGuidsEnum[validGuid]) {
        return `${allSidsPatterns.get(regexp) || key} (extended right)`
      }

      return allSidsPatterns.get(regexp) || key
    }
  }

  return String(key)
}

function getMapsByTitle(title: Maybe<string>): Array<Map<any, any>> {
  switch (title) {
    case ACETitle.Type:
      return [sddlAceTypes]
    case ACETitle.Flags:
      return [sddlAceFlags, sddlDaclFlags]
    case ACETitle.Rights:
      return [sddlAceRights]
    case ACETitle.AccountSid:
      return [wellKnownSids, sddlSids]
    case ACETitle.ObjectGUID:
      return [
        wellKnownGuidsWithSelfWrite,
        wellKnownGuidsWithWriteProperties,
        wellKnownGuidsWithControlAccess,
        wellKnownExtendedGuids
      ]
    default:
      return [
        sddlAceTypes,
        sddlAceFlags,
        sddlDaclFlags,
        sddlAceRights,
        wellKnownSids,
        sddlSids,
        wellKnownGuidsWithSelfWrite,
        wellKnownGuidsWithWriteProperties,
        wellKnownGuidsWithControlAccess,
        wellKnownExtendedGuids
      ]
  }
}
