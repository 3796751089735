import { FormWrapper } from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import { onCreateRoleSubmit } from './handlers'
import RolesCreateBladeFooter from './RoleCreateBladeFooter'
import RoleFormMainInformation from './RoleFormMainInformation'

interface IRoleCreateFormProps {}

const RoleCreateForm: React.FC<IRoleCreateFormProps> = props => {
  const appRouter = useAppRouter()

  const { storeBlades, storeManagementRbacRoles } = useStores()

  return (
    <FormWrapper
      name="roleCreation"
      onSubmit={onCreateRoleSubmit(
        storeBlades,
        storeManagementRbacRoles,
        appRouter
      )('creation')}
    >
      <>
        <RoleFormMainInformation />

        <Portal name={PlaceHolderName.bladeFooter}>
          <RolesCreateBladeFooter />
        </Portal>
      </>
    </FormWrapper>
  )
}

export default RoleCreateForm
