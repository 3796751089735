import { createEntity, EntityChecker } from '@app/entities'
import type EntityCheckerIdentity from '@app/entities/EntityGenericChecker/EntityCheckerIdentity'
import { CheckerOptionCodenameEnum } from '@app/entities/EntityGenericCheckerOption/types'
import type { StoreRoot } from '@app/stores'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { checkRbac } from '@libs/rbac/functions'
import type { QueryCheckerDeviancesForCheckerCompliance } from '@server/graphql/queries/checker'
import { queryCheckerDeviancesForCheckerCompliance } from '@server/graphql/queries/checker'
import type {
  Checker,
  Maybe,
  RbacCheckersQueryArgs,
  RbacDeviancesCheckerArgs
} from '@server/graphql/typeDefs/types'
import { first } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import StoreIndicatorDeviantObjects from './StoreIndicatorDeviantObjects'
import StoreIndicatorFindings from './StoreIndicatorFindings'

export default class StoreIndicator extends StoreBase {
  public storeIndicatorDeviantObjects = new StoreIndicatorDeviantObjects(
    this.storeRoot
  )

  public storeIndicatorFindings = new StoreIndicatorFindings(this.storeRoot)

  public storeFlagsFetchCheckerExposureComplianceStatus = new StoreFlags(
    this.storeRoot
  )

  public storeFlagsFetchCheckerIdentityComplianceStatus = new StoreFlags(
    this.storeRoot
  )

  /* Observable */

  private $checkerExposure = observable.box<Maybe<EntityChecker>>(null)

  private $checkerIdentity = observable.box<Maybe<EntityCheckerIdentity>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Retrieve the checker and its deviances to check its compliance status.
   */
  fetchCheckerExposureComplianceStatus(
    profileId: number,
    checkerId: number,
    directoryIds: number[]
  ) {
    this.storeFlagsFetchCheckerExposureComplianceStatus.loading()

    const args: RbacDeviancesCheckerArgs & RbacCheckersQueryArgs = {
      profileId,
      checkerId,
      directoryIds,
      deviancesPerPage: 1,
      optionsCodenames: [CheckerOptionCodenameEnum.O_CRITICITY]
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryCheckerDeviancesForCheckerCompliance>(
            queryCheckerDeviancesForCheckerCompliance,
            args
          )
      })
      .then(data => data.rbacCheckers)
      .then(rbacCheckers => {
        if (
          !checkRbac(
            this.storeRoot,
            this.storeFlagsFetchCheckerExposureComplianceStatus
          )(rbacCheckers)
        ) {
          throw new ForbiddenAccessError()
        }

        const checker = first(rbacCheckers.node)

        this.setCheckerExposure(
          checker
            ? createEntity<Checker, EntityChecker>(EntityChecker, checker)
            : null
        )

        this.storeFlagsFetchCheckerExposureComplianceStatus.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsFetchCheckerExposureComplianceStatus
        )
      )
  }

  /**
   * Retrieve the identity checker compliance status.
   *
   * FIXME?
   * Having a deciated endpoint would be better instead of reloading all identity
   * checkers.
   */
  fetchCheckerIdentityComplianceStatus() {
    return this.storeRoot.stores.storeIoE.fetchCheckersIdentity(
      this.storeFlagsFetchCheckerIdentityComplianceStatus
    )
  }

  /* Actions */

  /**
   * Set current exposure checker after having checked its compliance.
   */
  @action
  setCheckerExposure(entityChecker: Maybe<EntityChecker>): this {
    if (!entityChecker) {
      return this
    }

    this.$checkerExposure.set(entityChecker)

    return this
  }

  /**
   * Set current identity checker after having checked its compliance.
   */
  @action
  setCheckerIdentity(entityChecker: Maybe<EntityCheckerIdentity>): this {
    if (!entityChecker) {
      return this
    }

    this.$checkerIdentity.set(entityChecker)

    return this
  }

  /* Computed */

  @computed
  get checkerExposure(): Maybe<EntityChecker> {
    return this.$checkerExposure.get()
  }

  @computed
  get checkerIdentity(): Maybe<EntityCheckerIdentity> {
    return this.$checkerIdentity.get()
  }
}
