import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'

interface IIconDashboardProps extends IIconProps {}

const IconDashboard: React.FC<IIconDashboardProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 8.125H2.5C2.15482 8.125 1.875 7.84518 1.875 7.5V2.5C1.875 2.15482 2.15482 1.875 2.5 1.875H7.5C7.84518 1.875 8.125 2.15482 8.125 2.5V7.5C8.125 7.84518 7.84518 8.125 7.5 8.125Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 8.125H12.5C12.1548 8.125 11.875 7.84518 11.875 7.5V2.5C11.875 2.15482 12.1548 1.875 12.5 1.875H17.5C17.8452 1.875 18.125 2.15482 18.125 2.5V7.5C18.125 7.84518 17.8452 8.125 17.5 8.125Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 18.125H2.5C2.15482 18.125 1.875 17.8452 1.875 17.5V12.5C1.875 12.1548 2.15482 11.875 2.5 11.875H7.5C7.84518 11.875 8.125 12.1548 8.125 12.5V17.5C8.125 17.8452 7.84518 18.125 7.5 18.125Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 18.125H12.5C12.1548 18.125 11.875 17.8452 11.875 17.5V12.5C11.875 12.1548 12.1548 11.875 12.5 11.875H17.5C17.8452 11.875 18.125 12.1548 18.125 12.5V17.5C18.125 17.8452 17.8452 18.125 17.5 18.125Z"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconDashboard
