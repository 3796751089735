import { ContainerFlex } from '@app/components/Container'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import AddFiltersMenuButton from './AdditionnalFilters'
import DownloadButton from './DownloadButton'
import FilterRangePicker from './FilterDate'

interface IActivityLogsFiltersProps {}

const StyledVerticalLine = styled('div')`
  width: 1px;
  background-color: ${consts.colorGrayAltRedesign};
  height: 20px;
`

const ActivityLogsMenu: React.FC<IActivityLogsFiltersProps> = () => {
  return (
    <ContainerFlex
      name="ActivityLogsFilters"
      direction="row"
      items={[
        <FilterRangePicker />,
        <AddFiltersMenuButton />,
        <StyledVerticalLine />,
        <DownloadButton />
      ]}
      paddingV="medium"
      paddingH="medium"
      spaced
      spaceWidth="small"
      labelledBy="filters"
      alignItems="center"
    />
  )
}

export default observer(ActivityLogsMenu)
