import { Features } from '@alsid/common'
import { canAccessTenableCloud } from '@app/pages/Identities/permissions'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import { accessByFeatureFlag } from '@libs/rbac/permissions'

export const canAccessToSecurityEngine = rbacCapabilityCheckAllOf(
  canAccessTenableCloud,
  accessByFeatureFlag(Features.KAPTEYN_SECURITY_ENGINE)()
)

export const canAccessToSecurityEngineMilestone2 = rbacCapabilityCheckAllOf(
  canAccessToSecurityEngine,
  accessByFeatureFlag(Features.KAPTEYN_SECURITY_ENGINE_MILESTONE_2)()
)

export const canAccessToSecurityEngineMilestone3 = rbacCapabilityCheckAllOf(
  canAccessToSecurityEngineMilestone2,
  accessByFeatureFlag(Features.KAPTEYN_SECURITY_ENGINE_MILESTONE_3)()
)

export const canAccessToSecurityEngineMilestone4 = rbacCapabilityCheckAllOf(
  canAccessToSecurityEngineMilestone3,
  accessByFeatureFlag(Features.KAPTEYN_SECURITY_ENGINE_MILESTONE_4)()
)

export const canAccessToSecurityEngineMilestone5 = rbacCapabilityCheckAllOf(
  canAccessToSecurityEngineMilestone4,
  accessByFeatureFlag(Features.KAPTEYN_SECURITY_ENGINE_MILESTONE_5)()
)

export const canAccessToSecurityEngineMilestone6 = rbacCapabilityCheckAllOf(
  canAccessToSecurityEngineMilestone5,
  accessByFeatureFlag(Features.KAPTEYN_SECURITY_ENGINE_MILESTONE_6)()
)
