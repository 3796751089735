import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface IAlertDescriptionProps {
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}

const AlertDescription: React.FC<IAlertDescriptionProps> = props => {
  return (
    <div
      data-name="AlertDescription"
      className={props.className}
      style={props.style}
    >
      {props.children}
    </div>
  )
}

const pS = consts.paddingSmall

export default styled(AlertDescription)`
  padding: ${pS} ${pS} ${consts.paddingLarge} ${pS};
`
