import { Tooltip } from 'antd'
import * as React from 'react'
import styled from 'styled-components'

export enum BulletTooltipSize {
  small = 7
}

interface IBulletTooltipProps {
  className?: string
  color?: string
  size?: BulletTooltipSize
  reversed?: boolean
  resolved?: boolean
  tooltip?: string
  children: React.ReactNode
}

const BulletTooltipRaw = (props: IBulletTooltipProps) => {
  return (
    <div className={props.className}>
      {props.children}

      {props.tooltip !== undefined && (
        <Tooltip title={props.tooltip}>
          <div className="hoverableZone" />
        </Tooltip>
      )}
    </div>
  )
}

export default styled(BulletTooltipRaw)`
  position: relative;

  /* Adjust an overlay to show the tooltip on the larger zone */
  .hoverableZone {
    position: absolute;
    top: -4px;
    left: -4px;
    width: ${props => `${(props.size || BulletTooltipSize.small) * 2}px`};
    height: ${props => `${(props.size || BulletTooltipSize.small) * 2}px`};
    cursor: help;
  }
`
