import type { Maybe, PropertiesNullable } from '@@types/helpers'
import type { HealthChecksTemplatesName } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityHealthChecksTemplatesName
  extends EntityBase
  implements PropertiesNullable<HealthChecksTemplatesName>
{
  codename: Maybe<string> = null
  alert: Maybe<boolean> = null
  healthCheckLocalizedName: Maybe<string> = null
  color: string = ''

  constructor(data: Partial<EntityHealthChecksTemplatesName>) {
    super()
    Object.assign(this, data)
  }

  setColor(arg0: string) {
    this.color = arg0
  }

  setLocalizedName(localizedName: string) {
    this.healthCheckLocalizedName = localizedName
  }

  // FIXME: not sure this goes here? (no ids from data source)
  setId(id: string) {
    this.id = id
  }
}
