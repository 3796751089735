import { createEntity, EntityDataCollectionStatus } from '@app/entities'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreBase from '@app/stores/StoreBase'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { checkRbac } from '@libs/rbac/functions'
import type { MutationUpdateDataCollectionStatus } from '@server/graphql/mutations/dataCollection'
import { mutationUpdateDataCollectionStatus } from '@server/graphql/mutations/dataCollection'
import type { QueryRbacDataCollectionStatus } from '@server/graphql/queries/dataCollection'
import { queryRbacDataCollectionStatus } from '@server/graphql/queries/dataCollection'
import type {
  DataCollectionStatus,
  InputUpdateDataCollectionStatus
} from '@server/graphql/typeDefs/types'
import type {
  IWSDataCollectionStatusMessage,
  IWSRegistration
} from '@server/routers/WSProxyRouter/types'
import { WSEntityName } from '@server/routers/WSProxyRouter/types'
import type Maybe from 'graphql/tsutils/Maybe'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '..'
import type { IStoreOptions } from '../types'

export default class StoreDataCollection extends StoreBase {
  public storeFlagsDataCollectionStatusFetch = new StoreFlags(this.storeRoot)
  public storeFlagsDataCollectionStatusSubmit = new StoreFlags(this.storeRoot)

  private dataCollectionStatusWSRegistration: Maybe<
    IWSRegistration<IWSDataCollectionStatusMessage['payload']>
  > = null
  private pageIsActive: boolean = false

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Management.System.Configuration.DataCollection']
  })

  /* Observable */

  private $dataCollectionStatus = observable.box<EntityDataCollectionStatus>()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Register data collection status websocket.
   */
  registerDataCollectionStatusWS() {
    // don't register if the page is not active
    if (!this.pageIsActive) {
      return
    }

    // don't register several times
    if (this.dataCollectionStatusWSRegistration) {
      return
    }

    this.dataCollectionStatusWSRegistration =
      this.storeRoot.wsClient.addRegistration<
        IWSDataCollectionStatusMessage['payload']
      >(
        'DataCollectionStatus',
        {
          name: WSEntityName.dataCollectionStatus,
          payload: {}
        },
        payload => this.handleDataCollectionStatusWSMessage(payload)
      )
  }

  /**
   * Unregister data collection status websocket.
   */
  unregisterDataCollectionStatusWS() {
    if (this.dataCollectionStatusWSRegistration) {
      this.storeRoot.wsClient.removeRegistration(
        this.dataCollectionStatusWSRegistration
      )
      this.dataCollectionStatusWSRegistration = null
    }
  }

  /**
   * Handle data collection status websocket message.
   */
  handleDataCollectionStatusWSMessage(
    payload: IWSDataCollectionStatusMessage['payload']
  ) {
    if (this.dataCollectionStatusEntity?.isEnabled) {
      this.setDataCollectionStatus({
        isEnabled: true,
        notWorkingBecauseOf: payload.notWorkingBecauseOf,
        sensitiveDataEnabled:
          this.dataCollectionStatusEntity.sensitiveDataEnabled ?? false
      })
    }
  }

  /**
   * Fetch data collection status and save results into an entity.
   */
  fetchDataCollectionStatus() {
    this.storeFlagsDataCollectionStatusFetch.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryRbacDataCollectionStatus>(
            queryRbacDataCollectionStatus
          )
      })
      .then(data => data.rbacDataCollectionStatus)
      .then(dataCollectionStatus => {
        if (
          !checkRbac(
            this.storeRoot,
            this.storeFlagsDataCollectionStatusFetch
          )(dataCollectionStatus)
        ) {
          throw new ForbiddenAccessError()
        }

        this.setDataCollectionStatus(dataCollectionStatus.node)

        this.storeFlagsDataCollectionStatusFetch.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsDataCollectionStatusFetch
        )
      )
  }

  /**
   * Fetch data collection status and save results into an entity.
   */
  updateDataCollectionStatus(
    dataCollectionStatus: InputUpdateDataCollectionStatus
  ) {
    this.storeFlagsDataCollectionStatusSubmit.loading()

    return Promise.resolve()
      .then(() => {
        const args: MutationUpdateDataCollectionStatus['args'] = {
          dataCollectionStatus
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationUpdateDataCollectionStatus>(
            mutationUpdateDataCollectionStatus,
            args
          )
      })
      .then(data => data.updateDataCollectionStatus)
      .then(updateDataCollectionStatus => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Information transfer configuration updated'),
          {
            ariaRoles: ['alert'],
            labelledBy: 'dataCollectionConfiguration'
          }
        )

        this.setDataCollectionStatus(updateDataCollectionStatus)

        this.storeFlagsDataCollectionStatusSubmit.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsDataCollectionStatusSubmit
        )
      )
  }

  setPageIsActive(active: boolean) {
    this.pageIsActive = active
  }

  /* Action */

  @action
  reset(): this {
    this.unregisterDataCollectionStatusWS()

    this.storeFlagsDataCollectionStatusFetch.reset()

    this.storeFlagsDataCollectionStatusSubmit.reset()

    this.pageIsActive = false

    return this
  }

  /**
   * Save data collection status.
   */
  @action
  setDataCollectionStatus(dataCollectionStatus: DataCollectionStatus): this {
    const dataCollectionStatusEntity = createEntity<
      DataCollectionStatus,
      EntityDataCollectionStatus
    >(EntityDataCollectionStatus, dataCollectionStatus)

    this.$dataCollectionStatus.set(dataCollectionStatusEntity)
    return this
  }

  /* Computed */

  @computed
  get dataCollectionStatusEntity(): Maybe<EntityDataCollectionStatus> {
    return this.$dataCollectionStatus.get()
  }
}
