import { AppRouteName } from '@app/routes'
import type { StoreRoot } from '@app/stores'
import type { ISubmitFormParameters } from '@libs/submitForm'
import { submitForm } from '@libs/submitForm'
import type { IRouterPDFParameters } from '@server/routers/exportPDFRouter/types'

/**
 * POST the url to the PDF middleware to render the PDF server side and initiates
 * the download.
 */
export function sendPageToPDFMiddleware(
  storeRoot: StoreRoot,
  pathnames: string[],
  filename?: string
) {
  const pathname = storeRoot.appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.MiddlewarePDF,
    parameters: {}
  })

  const parameters: ISubmitFormParameters<IRouterPDFParameters> = {
    parameters: { pathnames, filename },
    language: storeRoot.appTranslator.language,
    'csrf-token': storeRoot.environment.sessionParams.csrfToken
  }

  submitForm(pathname, parameters, 'POST')
}
