import type { Perhaps } from '@@types/helpers'
import type { StoreManagementRbacRoles } from '@app/stores'
import type { TranslateFn } from '@libs/i18n'
import { isDefined } from '@libs/isDefined'
import type { IRbacBulkPermissionAction } from '@libs/rbac/types'
import { RbacEntityItemType, RbacPermissionType } from '@libs/rbac/types'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type { Maybe } from '@server/graphql/typeDefs/types'
import { RbacAction } from '@server/graphql/typeDefs/types'
import type {
  IRbacBulkPermissionOption,
  RbacBulkPermissionValues
} from './types'
import { RbacBulkPermissionActionValues } from './types'

export function getBulkPermissionValues(): RbacBulkPermissionValues {
  return new Map([
    [
      RbacBulkPermissionActionValues.grantReadingAndEditing,
      {
        type: RbacPermissionType.grant,
        actions: [RbacAction.Read, RbacAction.Edit],
        entities: 'id'
      }
    ],
    [
      RbacBulkPermissionActionValues.grantAllByDefault,
      {
        type: RbacPermissionType.grant,
        actions: [RbacAction.Read, RbacAction.Edit, RbacAction.Create],
        entities: 'all'
      }
    ],
    [
      RbacBulkPermissionActionValues.grantReading,
      {
        type: RbacPermissionType.grant,
        actions: [RbacAction.Read],
        entities: 'id'
      }
    ],
    [
      RbacBulkPermissionActionValues.grantReadingByDefault,
      {
        type: RbacPermissionType.grant,
        actions: [RbacAction.Read],
        entities: 'all'
      }
    ],
    [
      RbacBulkPermissionActionValues.grantEditing,
      {
        type: RbacPermissionType.grant,
        actions: [RbacAction.Edit],
        entities: 'id'
      }
    ],
    [
      RbacBulkPermissionActionValues.grantEditingByDefault,
      {
        type: RbacPermissionType.grant,
        actions: [RbacAction.Read, RbacAction.Edit],
        entities: 'all'
      }
    ],
    [
      RbacBulkPermissionActionValues.grantCreatingByDefault,
      {
        type: RbacPermissionType.grant,
        actions: [RbacAction.Create],
        entities: 'all'
      }
    ],
    [
      RbacBulkPermissionActionValues.unauthorizeAll,
      {
        type: RbacPermissionType.unauthorize,
        actions: [RbacAction.Read, RbacAction.Edit, RbacAction.Create],
        entities: 'all'
      }
    ],
    [
      RbacBulkPermissionActionValues.unauthorizeReading,
      {
        type: RbacPermissionType.unauthorize,
        actions: [RbacAction.Read],
        entities: 'all'
      }
    ],
    [
      RbacBulkPermissionActionValues.unauthorizeEditing,
      {
        type: RbacPermissionType.unauthorize,
        actions: [RbacAction.Edit],
        entities: 'all'
      }
    ],
    [
      RbacBulkPermissionActionValues.unauthorizeCreating,
      {
        type: RbacPermissionType.unauthorize,
        actions: [RbacAction.Create],
        entities: 'all'
      }
    ]
  ])
}

/**
 * Return the available bulk options according to the type of entity.
 */
export const getBulkPermissionOptions = (
  storeManagementRbacRoles: StoreManagementRbacRoles,
  translate: TranslateFn
): IRbacBulkPermissionOption[] => {
  const grantColor = '#f1f8ff'
  const unauthorizeColor = '#ededed'

  const bulkPermissionValues = getBulkPermissionValues()

  switch (storeManagementRbacRoles.storeMenu.selectedMenuKey) {
    case RbacEntityItemType.data: {
      return [
        {
          backgroundColor: grantColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.grantAllByDefault
            )
          ),
          label: translate('Grant all by default')
        },
        {
          backgroundColor: grantColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.grantReadingAndEditing
            )
          ),
          label: translate('Grant reading and editing')
        },
        {
          backgroundColor: grantColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.grantReading
            )
          ),
          label: translate('Grant reading')
        },
        {
          backgroundColor: grantColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.grantReadingByDefault
            )
          ),
          label: translate('Grant reading by default')
        },
        {
          backgroundColor: grantColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.grantEditing
            )
          ),
          label: translate('Grant editing')
        },
        {
          backgroundColor: grantColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.grantEditingByDefault
            )
          ),
          label: translate('Grant editing by default')
        },
        {
          backgroundColor: grantColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.grantCreatingByDefault
            )
          ),
          label: translate('Grant creating by default')
        },
        {
          backgroundColor: unauthorizeColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.unauthorizeAll
            )
          ),
          label: translate('Unauthorize all')
        },
        {
          backgroundColor: unauthorizeColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.unauthorizeReading
            )
          ),
          label: translate('Unauthorize reading')
        },
        {
          backgroundColor: unauthorizeColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.unauthorizeEditing
            )
          ),
          label: translate('Unauthorize editing')
        },
        {
          backgroundColor: unauthorizeColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.unauthorizeCreating
            )
          ),
          label: translate('Unauthorize creating')
        }
      ]
    }

    case RbacEntityItemType.userPersonal: {
      return [
        {
          backgroundColor: grantColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.grantAllByDefault
            )
          ),
          label: translate('Grant all')
        },
        {
          backgroundColor: grantColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.grantEditingByDefault
            )
          ),
          label: translate('Grant editing')
        },
        {
          backgroundColor: unauthorizeColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.unauthorizeAll
            )
          ),
          label: translate('Unauthorize all')
        }
      ]
    }

    case RbacEntityItemType.singleton: {
      return [
        {
          backgroundColor: grantColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.grantEditingByDefault
            )
          ),
          label: translate('Grant all')
        },
        {
          backgroundColor: grantColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.grantReadingByDefault
            )
          ),
          label: translate('Grant reading')
        },
        {
          backgroundColor: unauthorizeColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.unauthorizeAll
            )
          ),
          label: translate('Unauthorize all')
        }
      ]
    }

    case RbacEntityItemType.ui: {
      return [
        {
          backgroundColor: grantColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.grantReadingAndEditing
            )
          ),
          label: translate('Grant')
        },
        {
          backgroundColor: unauthorizeColor,
          value: buildPermissionOptionValue(
            bulkPermissionValues.get(
              RbacBulkPermissionActionValues.unauthorizeAll
            )
          ),
          label: translate('Unauthorize')
        }
      ]
    }

    default:
      assertUnreachableCase(storeManagementRbacRoles.storeMenu.selectedMenuKey)
  }
}

/**
 * Build a permission id for a permissions bulk selector.
 */
export function buildPermissionOptionValue(
  permissionAction: Perhaps<IRbacBulkPermissionAction>
): string {
  if (!permissionAction) {
    return ''
  }

  return [
    permissionAction.type,
    permissionAction.actions.join(','),
    permissionAction.entities
  ].join('-')
}

/**
 * Reverse of buildPermissionOptionValue.
 */
export function explodeBulkPermissionOptionValue(
  permissionValue: string
): Maybe<IRbacBulkPermissionAction> {
  const [type, actions, entities] = permissionValue.split('-')

  if (!isDefined(type) || !isDefined(actions) || !isDefined(entities)) {
    return null
  }

  const bulkPermissionValue: IRbacBulkPermissionAction = {
    type: type as IRbacBulkPermissionAction['type'],
    actions: actions.split(',') as IRbacBulkPermissionAction['actions'],
    entities: entities as IRbacBulkPermissionAction['entities']
  }

  return bulkPermissionValue
}
