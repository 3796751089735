import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconCopyToClipBoardProps extends IIconProps {}

const IconCopyToClipBoard: React.FC<IIconCopyToClipBoardProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_791_3334)">
        <path
          d="M9.57183 4.75H2.69849C2.15279 4.75061 1.71057 5.19283 1.70996 5.73853V12.612C1.71057 13.1577 2.15279 13.5999 2.69849 13.6005H9.57183C10.1175 13.5999 10.5597 13.1577 10.5604 12.612V5.73853C10.5597 5.19283 10.1175 4.75061 9.57183 4.75Z"
          fill={fill}
        />
        <path
          d="M11.8153 2.40039H4.90552C4.35982 2.401 3.91761 2.84322 3.91699 3.38892V4.25561H9.57161C10.3901 4.25656 11.0534 4.91987 11.0544 5.7384V11.2872H11.8153C12.361 11.2866 12.8032 10.8444 12.8038 10.2987V3.38892C12.8032 2.84322 12.361 2.401 11.8153 2.40039Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_791_3334">
          <rect
            width="12"
            height="11.2"
            fill="white"
            transform="translate(1.6001 2.40039)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconCopyToClipBoard
