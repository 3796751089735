export enum MenuSystemMenuKey {
  RelayManagement = 'Relay management',
  ForestManagement = 'Forest management',
  DomainManagement = 'Domain management',
  TenantManagement = 'Tenant management',
  Configuration = 'Configuration',
  About = 'About',
  Legal = 'Legal',

  /* Configuration sub menu */

  ApplicationSettings = 'Application settings',
  SMTPServer = 'SMTP server',
  ActivityLogs = 'Activity Logs',
  Security = 'Security',
  IndicatorsOfAAttack = 'Indicators of Attack',
  CollectSensitiveData = 'Collect Sensitive Data',
  Reporting = 'Reporting',
  ReportingCenter = 'Reporting center',
  AlertingEngine = 'Alerting engine',
  SYSLOGAlerts = 'SYSLOG alerts',
  EmailAlerts = 'Email alerts',
  Plugins = 'Plugins',
  Authentication = 'Authentication',
  TenableProductName = 'TenableProductName',
  LDAPConfiguration = 'LDAP configuration',
  SAMLConfiguration = 'SAML configuration',
  TelemetryConfiguration = 'Telemetry configuration',
  HealthCheckConfiguration = 'Health check configuration',
  Relay = 'Relay'
}
