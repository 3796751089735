import { IconSettings } from '@app/components/Icon'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { ContainerFlex } from '@app/components-legacy/Container'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import { Popover } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { AttackPathVariant } from '../../types'
import AlertTruncated from '../Components/AlertTruncated'
import {
  handleAttackPathTabOnClick,
  onGraphToolsPopoverVisibleChange
} from '../handlers'
import AttackPathInputsWrapper from '../NodeFinder/AttackPathInputsWrapper'
import AttackPathTabs from '../NodeFinder/AttackPathTabs'
import ShowAllNodeTooltipSwitch from './ShowAllNodeTooltipSwitch'

interface IAttackPathSceneFiltersProps {}

const StyledFilters = styled(ContainerFlex)`
  position: absolute;
  top: ${consts.marginSmaller};
  left: ${consts.marginDefault};
  right: ${consts.marginDefault};
  bottom: ${consts.marginSmaller};
  padding: ${consts.paddingDefault};
  pointer-events: none;
  z-index: 1;
`

const StyledShowAllNodeTooltipSwitch = styled(ShowAllNodeTooltipSwitch)`
  pointer-events: auto;
`

const StyledLine = styled.div`
  height: 30px;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: ${consts.colorLighterVioletRedesign};
  margin-right: 9.5px !important;
`

const StyledLabel = styled(Label)`
  text-transform: uppercase;
  padding-bottom: 5px;
`

const AttackPathSceneFilters: React.FC<IAttackPathSceneFiltersProps> = () => {
  const { storeAttackPath } = useStores()

  const translate = useAppTranslator({
    namespaces: ['AttackPath.Chart']
  })

  return (
    <DSThemeProvider themeName={DSThemeName.grey}>
      <StyledFilters
        name="AttackPathSceneFilters"
        direction="column"
        justifyContent="space-between"
        items={[
          <ContainerFlex
            name="AttackPathFiltersTop"
            justifyContent="space-between"
            alignItems="flex-start"
            labelledBy="attackPathHeader"
            itemsFlexGrow={[2, 1]}
            items={[
              <ContainerFlex
                name="AttackPathFiltersSearch"
                alignItems="flex-start"
                justifyContent="flex-start"
                items={[
                  <AttackPathTabs
                    variant={AttackPathVariant.graph}
                    tabHandler={handleAttackPathTabOnClick(storeAttackPath)}
                  />,
                  <AttackPathInputsWrapper variant={AttackPathVariant.graph} />
                ]}
                spaced
                spaceWidth="small"
              />,

              <StyledLine />,
              <Popover
                content={
                  <ContainerFlex
                    name="AttackPathTools"
                    direction="column"
                    items={[
                      <StyledLabel variant={LabelVariant.h3}>
                        {translate('Display information')}
                      </StyledLabel>,
                      <StyledShowAllNodeTooltipSwitch
                        storeAttackPath={storeAttackPath}
                      />
                    ]}
                    spaced
                    spaceWidth="small"
                  />
                }
                trigger="click"
                placement="bottomRight"
                open={storeAttackPath.isPopoverVisible}
                onOpenChange={onGraphToolsPopoverVisibleChange(storeAttackPath)}
                overlayInnerStyle={{
                  borderRadius: '5px',
                  boxShadow: '0px 0px 10px rgba(213, 213, 233, 0.5)'
                }}
              >
                <div>
                  <FormWrapperButton
                    labelledBy="attackPathTools"
                    variant={ButtonVariant.secondary}
                    size={ButtonSize.small}
                    icon={IconSettings}
                  />
                </div>
              </Popover>
            ]}
            spaced
          />,
          <ContainerFlex
            name="AttackPathFiltersBottom"
            justifyContent="center"
            labelledBy="attackPathFooter"
            items={[<AlertTruncated />]}
          />
        ]}
        paddingV="small"
        spaced
      />
    </DSThemeProvider>
  )
}

export default observer(AttackPathSceneFilters)
