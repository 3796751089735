import type { IEntityListable } from '@app/entities/types'
import type { IDataRowGeneric } from '@app/stores/helpers/StoreWidgetList/types'
import * as React from 'react'
import type { IWidgetListTableCommonProps } from './types'

interface IWidgetTableTbodyCellProps<
  E extends IEntityListable<IDataRowGeneric>,
  D extends IDataRowGeneric
> extends IWidgetListTableCommonProps<E, D> {
  rowKey: keyof D
  rowValue: any
  row: D
}

/**
 * Render the a cell of a row of the Tbody.
 */
export function WidgetTableTbodyCell<
  E extends IEntityListable<IDataRowGeneric>,
  D
>(props: IWidgetTableTbodyCellProps<E, D>): React.ReactElement {
  if (!props.cellsRenderFn) {
    return <div>{props.rowValue}</div>
  }

  if (!(props.rowKey in props.cellsRenderFn)) {
    return <div>{props.rowValue}</div>
  }

  const renderFn = props.cellsRenderFn[props.rowKey]

  return <>{renderFn ? renderFn(props.rowValue, props.row) : null}</>
}

export default WidgetTableTbodyCell
