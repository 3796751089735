import type { PropertiesNullable } from '@@types/helpers'
import type { TopologyDirectory, Maybe } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityTopologyDirectory
  extends EntityBase
  implements PropertiesNullable<TopologyDirectory>
{
  id: Maybe<number> = null
  uid: Maybe<string> = null
  name: Maybe<string> = null
  known: Maybe<boolean> = null

  // more properties
  uidInfrastructure: Maybe<string> = null

  constructor(data: Partial<TopologyDirectory>) {
    super()
    Object.assign(this, data)
  }
}
