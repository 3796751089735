import { Features } from '@alsid/common'
import { ContainerContent, ContainerFlex } from '@app/components/Container'
import LabelWarning from '@app/components/Labels/LabelWarning'
import { ContainerRbac } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { buildVariants } from '@design-system/libs/buildVariants'
import { DSThemeName } from '@design-system/styles/themes/types'
import { DataCollectionNotWorkingReason } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import ConfigurationAzureADSupport from './ConfigurationAzureADSupport'
import { handleConfigurationAzureADSupportOnUnLoad } from './ConfigurationAzureADSupport/handlers'
import ConfigurationDataCollection from './ConfigurationDataCollection'
import ConfigurationDataCollectionErrorMessage from './ConfigurationDataCollection/ConfigurationDataCollectionErrorMessage'
import { handleDataCollectionStatusOnUnload } from './ConfigurationDataCollection/handlers'
import { ConfigurationPendo } from './ConfigurationPendo'
import { handleTenableCloudOnLoad } from './handlers'
import { canReadAzureAdSupport } from './permissions'

interface IConfigurationTenableCloudProps {}

const StyledContainerFlex = styled(ContainerFlex)(props => {
  return buildVariants(props)
    .css({
      maxWidth: consts.configurationCardsWidth
    })
    .end()
})

export function ConfigurationTenableCloudPage(
  props: IConfigurationTenableCloudProps
) {
  const {
    storeManagementAzureAD,
    storeManagementDataCollection,
    storeLicense,
    storeAuthentication,
    storeRbac
  } = useStores()

  const { cloudStatistics } = storeAuthentication

  function getContentItems() {
    if (!storeLicense.isLicenseUpToDate) {
      return [
        <LabelWarning variant="light">
          <ConfigurationDataCollectionErrorMessage
            reason={DataCollectionNotWorkingReason.ObsoleteLicense}
          />
        </LabelWarning>
      ]
    }

    return [
      <ContainerRbac rbacCapabilityCheck={canReadAzureAdSupport}>
        <ConfigurationAzureADSupport />
      </ContainerRbac>,

      <ConfigurationDataCollection />,

      storeRbac.isUserGrantedAccordingFeatureFlag(Features.PENDO_INTEGRATION) &&
        cloudStatistics && (
          <ConfigurationPendo cloudStatistics={cloudStatistics} />
        )
    ]
  }

  return (
    <DSThemeProvider themeName={DSThemeName.default}>
      <ContainerContent
        name="ConfigurationTenableCloudPage"
        onLoad={handleTenableCloudOnLoad(storeLicense)}
        onUnload={() => {
          handleConfigurationAzureADSupportOnUnLoad(storeManagementAzureAD)()
          handleDataCollectionStatusOnUnload(storeManagementDataCollection)
        }}
        flags={[
          storeManagementAzureAD.storeFlagsTenableCloudApiKeysStatusFetch.flags,
          storeManagementAzureAD.storeFlagsAzureADConfigurationFetch.flags,
          storeManagementDataCollection.storeFlagsDataCollectionStatusFetch
            .flags
        ]}
      >
        <StyledContainerFlex
          name="ConfigurationTenableCloudPage"
          direction="column"
          flexGap="large"
          items={getContentItems()}
        />
      </ContainerContent>
    </DSThemeProvider>
  )
}

export default observer(ConfigurationTenableCloudPage)
