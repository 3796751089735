import type { Maybe, MaybeUndef } from '@@types/helpers'
import React from 'react'

/**
 * Register an event listener on a dom element.
 */
export function useEventListener<
  T extends HTMLElement,
  U extends keyof HTMLElementEventMap
>(
  eventName: U,
  handler: MaybeUndef<(e: HTMLElementEventMap[U]) => void>
): {
  ref?: React.RefCallback<T>
} {
  const [element, setElement] = React.useState<Maybe<T>>(null)

  React.useEffect(() => {
    if (!element || !element.addEventListener || !handler) {
      return
    }

    element.addEventListener<U>(eventName, handler)

    return () => {
      element.removeEventListener<U>(eventName, handler)
    }
  }, [eventName, element, handler])

  return {
    ref: e => {
      setElement(e)
    }
  }
}
