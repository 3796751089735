import type { Maybe } from '@@types/helpers'
import { EntityAttackTypeOption, EntityCheckerOption } from '@app/entities'
import type { CheckerTypeUnion } from '@app/entities/EntityGenericChecker/types'
import type {
  CheckerOptionCodename,
  EntityGenericCheckerOption
} from '@app/entities/EntityGenericCheckerOption/types'
import { inspect } from '@libs/debug/inspect'
import { indexEntitiesToMap } from '@libs/indexEntitiesToMap'
import { isDefined } from '@libs/isDefined'
import { addSetValueToMap } from '@libs/setValueToMap'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { pick, remove, sortBy } from 'lodash'

// if null, it means "All directories"
type DirectoryId = Maybe<number>
type SerializedConfiguration = string

export interface ICheckerOptionsByConfigurations<
  E extends EntityGenericCheckerOption
> {
  // if null, the serie is for all directoriesv
  directoryIds: Array<Maybe<number>>
  checkerOptions: Map<CheckerOptionCodename, E>
}

/**
 * - Indexer toutes les options par directoryId (Map<DirectoryId, Set<Options>>)
 * - Indexer la configuration complète de chaque directory (Map<String(Set<Options>), Set<DirectoryId>>)
 * - Reverse la map pour retrouver Array<{directoryIds[], checkerOptions: Map<CheckerOptionCodename, EntityGenericCheckerOption>}>
 */

export function groupCheckerOptionsByConfigurations<
  E extends EntityGenericCheckerOption
>(
  checkerType: E['checkerType'],
  options: E[],
  debug = false
): Array<ICheckerOptionsByConfigurations<E>> {
  const optionsByDirectoryId = indexOptionsByDirectoryId<E>(options, debug)

  const directoryIdsBySerializedConfiguration =
    indexDirectoryIdsBySerializedConfiguration(optionsByDirectoryId, debug)

  return indexCheckerOptionsByDirectoryIds(
    checkerType,
    directoryIdsBySerializedConfiguration,
    debug
  )
}

function indexOptionsByDirectoryId<E extends EntityGenericCheckerOption>(
  options: E[],
  debug: boolean
): Map<DirectoryId, Set<EntityGenericCheckerOption>> {
  const optionsByDirectoryId = new Map<
    DirectoryId,
    Set<EntityGenericCheckerOption>
  >()

  options.forEach(option => {
    addSetValueToMap(optionsByDirectoryId, option.directoryId, option)
  })

  if (debug) {
    inspect('indexOptionsByDirectoryId', optionsByDirectoryId)
  }

  return optionsByDirectoryId
}

function indexDirectoryIdsBySerializedConfiguration<
  E extends EntityGenericCheckerOption
>(
  optionsByDirectoryId: Map<DirectoryId, Set<E>>,
  debug: boolean
): Map<SerializedConfiguration, Set<DirectoryId>> {
  const directoryIdsBySerializedConfiguration = new Map<
    SerializedConfiguration,
    Set<DirectoryId>
  >()

  Array.from(optionsByDirectoryId.entries()).forEach(
    ([directoryId, setEntityGenericCheckerOptions]) => {
      const serializedConfiguration = serializeConfiguration(
        Array.from(setEntityGenericCheckerOptions.values())
      )

      // don't add a same configuration if already added for "All directories"
      const isConfigurationForAllDirectories = Array.from(
        (
          directoryIdsBySerializedConfiguration.get(serializedConfiguration) ||
          new Set()
        ).values()
      ).some(directory => directory === null)

      if (isConfigurationForAllDirectories && directoryId !== null) {
        return
      }

      addSetValueToMap(
        directoryIdsBySerializedConfiguration,
        serializedConfiguration,
        directoryId
      )
    }
  )

  if (debug) {
    inspect(
      'indexDirectoryIdsBySerializedConfiguration',
      directoryIdsBySerializedConfiguration
    )
  }

  return directoryIdsBySerializedConfiguration
}

function indexCheckerOptionsByDirectoryIds<
  E extends EntityGenericCheckerOption
>(
  checkerType: E['checkerType'],
  directoryIdsBySerializedConfiguration: Map<
    SerializedConfiguration,
    Set<DirectoryId>
  >,
  debug: boolean
): Array<ICheckerOptionsByConfigurations<E>> {
  const remainingConfigurations = Array.from(
    directoryIdsBySerializedConfiguration.entries()
  )

  // retrieve the configuration of "All directories" first
  const allDirectoryConfiguration = remove(
    remainingConfigurations,
    ([, directories]) =>
      Array.from(directories.values()).filter(isDefined).length === 0
  ).pop()

  // sort refined configurations by the number of directories
  const allConfigurations = remainingConfigurations.sort(
    ([, a], [, b]) =>
      Array.from(b.values()).filter(isDefined).length -
      Array.from(a.values()).filter(isDefined).length
  )

  // add the "All directories" configuration at the beginning
  if (allDirectoryConfiguration) {
    allConfigurations.unshift(allDirectoryConfiguration)
  }

  const checkerOptionsByDirectoryIds: Array<
    ICheckerOptionsByConfigurations<E>
  > = []

  allConfigurations.forEach(([serializedConfiguration, directoryIds]) => {
    const checkerOptionsAsArray = unserializeConfiguration<E>(
      checkerType,
      serializedConfiguration
    )

    const checkerOptions = indexEntitiesToMap<E, CheckerOptionCodename>(
      checkerOptionsAsArray,
      'codename'
    )

    checkerOptionsByDirectoryIds.push({
      directoryIds: Array.from(directoryIds.values()),
      checkerOptions
    })
  })

  if (debug) {
    inspect('indexCheckerOptionsByDirectoryIds', checkerOptionsByDirectoryIds)
  }

  return checkerOptionsByDirectoryIds
}

function serializeConfiguration(
  checkerOptions: EntityGenericCheckerOption[]
): SerializedConfiguration {
  return JSON.stringify(
    sortBy(
      checkerOptions.map(option => {
        return pick(option, [
          'codename',
          'name',
          'description',
          'value',
          'valueType',
          'translations',
          'staged'
        ])
      }),
      'codename'
    )
  )
}

function unserializeConfiguration<E extends EntityGenericCheckerOption>(
  checkerType: CheckerTypeUnion,
  serializedConfiguration: SerializedConfiguration
): E[] {
  return (JSON.parse(serializedConfiguration) as object[]).map(obj => {
    const instance = (
      checkerType === CheckerType.Exposure
        ? new EntityCheckerOption(obj)
        : new EntityAttackTypeOption(obj)
    ) as E

    return instance
  })
}
