import ContextStores from '@app/contexts/ContextStores'
import { ApplicationError } from '@libs/errors'
import { ApplicationErrorValue } from '@libs/errors/types'
import type { IAppTranslateOptions, TranslateFn } from '@libs/i18n'
import * as React from 'react'
import { ensureArray } from '../../libs/ensureArray'

/**
 * Return a translator function binded to translatorOptions.
 */
export function useAppTranslator(
  appTranslatorOptions: IAppTranslateOptions
): TranslateFn {
  const value = React.useContext(ContextStores)

  const translator = value.storeRoot && value.storeRoot.appTranslator

  if (!translator) {
    throw new ApplicationError({
      errorValue: ApplicationErrorValue.TranslatorError,
      message: `Translator instance is not available`
    })
  }

  // merge translator namespaces, having store ones first to be able to override
  // translations according to the store options.
  const namespaces: IAppTranslateOptions['namespaces'] = [
    ...ensureArray(appTranslatorOptions.storeOptionsI18n?.translatorNamespaces),
    ...ensureArray(appTranslatorOptions.namespaces)
  ]

  return translator.bindOptions({
    ...appTranslatorOptions,
    namespaces
  })
}
