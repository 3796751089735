import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { mergeFlags } from '@app/stores/helpers/StoreFlags/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canListSyslogAlerts } from '../permissions'
import { onSyslogCreateLoad, onSyslogCreateUnload } from './handlers'
import SyslogCreateForm from './SyslogCreateForm'

interface IConfigurationSyslogsCreatePageProps {}

const ConfigurationSyslogsCreatePage: React.FC<
  IConfigurationSyslogsCreatePageProps
> = () => {
  const { storeManagementSyslogs } = useStores()

  const flags = mergeFlags(
    // wait for infras
    storeManagementSyslogs.storeInfrastructures.storeFlagsFetchInfrastructures
      .flags,
    // wait IoE loaded in cards
    storeManagementSyslogs.storeInputCheckersExposure.storeFlagsFetchCheckers
      .flags,
    // wait IoA loaded in cards
    storeManagementSyslogs.storeInputCheckersAttacks.storeFlagsFetchCheckers
      .flags
  )

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_System_Configuration_Syslogs_Create,
        parameters: {}
      }}
      onLoad={onSyslogCreateLoad(storeManagementSyslogs)}
      onUnload={onSyslogCreateUnload(storeManagementSyslogs)}
      flags={flags}
      layout={{
        name: 'default',
        content: (
          <ContainerContent>
            <SyslogCreateForm />
          </ContainerContent>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canListSyslogAlerts}
    />
  )
}

export default observer(ConfigurationSyslogsCreatePage)
