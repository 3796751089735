import {
  createEntity,
  EntityAzureADSupportConfiguration,
  EntityTenableCloudApiToken
} from '@app/entities'
import { AppRouteName } from '@app/routes'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { mandatory } from '@app/stores/helpers/StoreForm/validators'
import StoreModal from '@app/stores/helpers/StoreModal'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import type { ForbiddenAccessError } from '@libs/errors'
import { isErrorOfType } from '@libs/errors/functions'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { ErrorName } from '@libs/errors/types'
import { PingTheService200ResponseStatusEnum } from '@libs/openapi/service-identity-core'
import type { MutationUpdateAzureADSupportConfiguration } from '@server/graphql/mutations/azureADSupport'
import { mutationUpdateAzureADSupportConfiguration } from '@server/graphql/mutations/azureADSupport'
import type { MutationUpdateTenableCloudApiToken } from '@server/graphql/mutations/tenableCloudApiToken'
import { mutationUpdateTenableCloudApiToken } from '@server/graphql/mutations/tenableCloudApiToken'
import type { QueryAzureADSupportConfiguration } from '@server/graphql/queries/azureADSupportConfiguration'
import { queryAzureADSupportConfiguration } from '@server/graphql/queries/azureADSupportConfiguration'
import type {
  AzureAdSupportConfiguration,
  InputAzureAdSupportConfiguration,
  InputUpdateTenableCloudApiToken,
  TenableCloudApiToken,
  UpdateTenableCloudApiTokenMutationArgs
} from '@server/graphql/typeDefs/types'
import type Maybe from 'graphql/tsutils/Maybe'
import { get } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '../..'
import StoreForm from '../../helpers/StoreForm'
import {
  ApiTokensStatus,
  AzureADSupportConfigurationFormFieldName
} from './types'

export default class StoreAzureAD extends StoreBase {
  public storeFlagsTenableCloudApiKeysStatusFetch = new StoreFlags(
    this.storeRoot
  )
  public storeFlagsAzureADConfigurationFetch = new StoreFlags(this.storeRoot)
  public storeFlagsAzureADConfigurationUpdate = new StoreFlags(this.storeRoot)
  public storeFlagsAzureADConfigurationSubmit = new StoreFlags(this.storeRoot)
  public storeFlagsTenableCloudPingStatus = new StoreFlags(this.storeRoot)

  public storeModalPromptUseOfTenableCloud = new StoreModal(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Management.System.Configuration.AzureADSupport']
  })

  public storeFormAzureADSupportConfiguration =
    new StoreForm<AzureADSupportConfigurationFormFieldName>(this.storeRoot, {
      setup: {
        fields: {
          [AzureADSupportConfigurationFormFieldName.accessKey]: {
            label: 'Access Key',
            description: 'Access Key of tenable cloud',
            validators: [mandatory()],
            inputType: InputType.inputPassword
          },
          [AzureADSupportConfigurationFormFieldName.secretKey]: {
            label: 'Secret Key',
            description: 'Secret Key of tenable cloud',
            validators: [mandatory()],
            inputType: InputType.inputPassword
          }
        }
      }
    })

  /* Observable */

  private $azureADSupportConfiguration =
    observable.box<Maybe<EntityAzureADSupportConfiguration>>(null)

  private $tenableCloudApiStatus = observable.box<'set' | 'unset'>('unset')

  private $tenableCloudApiToken =
    observable.box<Maybe<EntityTenableCloudApiToken>>(null)

  // true if service-core-identity has answered a valid status for the tokens defined in the configuration
  private $tenableCloudApiTokensStatus = observable.box<ApiTokensStatus>(
    ApiTokensStatus.notWorking
  )

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch custom Kapteyn server custom middleware route that returns a "status"
   * if ApiKeys are saved or not.
   */
  fetchTenableCloudApiKeysStatus() {
    this.storeFlagsTenableCloudApiKeysStatusFetch.loading()

    const urlApiKeys = this.storeRoot.appRouter.getRoutePathname(
      AppRouteName.MiddlewareTenableServicesProxy_ApiKeys
    )

    return this.storeRoot.environment.fetchClient
      .get(urlApiKeys)
      .then(response => response.json())
      .then(json => {
        this.setApiKeysStatus(get(json, 'status') === 'set' ? 'set' : 'unset')
        this.storeFlagsTenableCloudApiKeysStatusFetch.success()
      })
      .catch(() => {
        this.setApiKeysStatus('unset')
        this.storeFlagsTenableCloudApiKeysStatusFetch.reset()
      })
  }

  /**
   * Fetch Microsoft Entra ID configuration and save results into an entity.
   */
  fetchAzureADSupportConfiguration() {
    this.storeFlagsAzureADConfigurationFetch.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryAzureADSupportConfiguration>(
            queryAzureADSupportConfiguration
          )
      })
      .then(data => {
        this.setAzureADSupportConfiguration(data.azureADSupportConfiguration)
        this.storeFlagsAzureADConfigurationFetch.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsAzureADConfigurationFetch
        )
      )
  }

  /**
   * Return a boolean if the ping to service-core-identity has been successful,
   * meaning that the user is correctly authenticated.
   */
  fetchTenableCloudApiKeysPingStatus(): Promise<any> {
    this.storeFlagsTenableCloudPingStatus.loading()

    return Promise.resolve()
      .then(() =>
        this.storeRoot.environment.openApiClients.serviceIdentityCore.health.pingTheService()
      )
      .then(data => {
        if (data.status !== PingTheService200ResponseStatusEnum.Ok) {
          throw new Error()
        }

        this.setTenableCloudApiKeysStatus(ApiTokensStatus.working)
        this.storeFlagsTenableCloudPingStatus.success()
      })
      .catch(e => {
        if (e.status === 401) {
          this.setTenableCloudApiKeysStatus(ApiTokensStatus.invalidCredentials)
        } else if (e.status === 403) {
          this.setTenableCloudApiKeysStatus(ApiTokensStatus.notAdmin)
        } else {
          this.setTenableCloudApiKeysStatus(ApiTokensStatus.notWorking)
        }
        this.storeFlagsTenableCloudPingStatus.fail()
      })
  }

  /**
   * Fetch data collection status and save results into an entity.
   */
  updateAzureADSupportConfiguration(
    azureADSupportConfiguration: InputAzureAdSupportConfiguration
  ) {
    this.storeFlagsAzureADConfigurationUpdate.loading()

    return Promise.resolve()
      .then(() => {
        const args: MutationUpdateAzureADSupportConfiguration['args'] = {
          azureADSupportConfiguration
        }
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationUpdateAzureADSupportConfiguration>(
            mutationUpdateAzureADSupportConfiguration,
            args
          )
      })
      .then(data => data.updateAzureADSupportConfiguration)
      .then(updateAzureADSupportConfiguration => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Azure AD Support configuration updated'),
          {
            ariaRoles: ['alert'],
            labelledBy: 'azureadsupportcofiguration'
          }
        )

        if (!updateAzureADSupportConfiguration.azureADSupportEnabled) {
          this.setTenableCloudApiToken(null)
          this.setTenableCloudApiKeysStatus(ApiTokensStatus.notWorking)

          this.storeFormAzureADSupportConfiguration.reset()
        }

        this.setAzureADSupportConfiguration(updateAzureADSupportConfiguration)

        this.storeFlagsAzureADConfigurationUpdate.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsAzureADConfigurationUpdate
        )
      )
  }

  updateTenableCloudApiToken(
    tenableCloudApiToken: InputUpdateTenableCloudApiToken
  ) {
    this.storeFlagsAzureADConfigurationSubmit.loading()

    return Promise.resolve()
      .then(() => {
        const args: UpdateTenableCloudApiTokenMutationArgs = {
          tenableCloudApiToken
        }
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationUpdateTenableCloudApiToken>(
            mutationUpdateTenableCloudApiToken,
            args
          )
      })
      .then(results => {
        if (!results) {
          throw new Error('The query has failed')
        }
        this.storeFlagsAzureADConfigurationSubmit.success()

        this.storeRoot.stores.storeMessages.success(
          this.translate('API keys have been updated'),
          {
            ariaRoles: ['alert'],
            labelledBy: 'azureadsupportcofiguration'
          }
        )
        this.setTenableCloudApiToken(tenableCloudApiToken)
      })
      .catch(err => {
        const isForbidden = isErrorOfType<ForbiddenAccessError>(
          err,
          ErrorName.ForbiddenAccessError
        )

        const messageOptions = {
          duration: 30,
          labelledBy: 'error'
        }

        // If the user is not granted (service-identity-core | Eridanis's 40x),
        // return an explicit error in the message
        return handleStoreError(
          this.storeRoot,
          this.storeFlagsAzureADConfigurationSubmit,
          {
            errorMessageTranslationFn: () => {
              if (isForbidden) {
                return this.translate('Missing permissions')
              }

              return null
            }
          },
          messageOptions
        )(err)
      })
  }

  /**
   * Actions
   */

  @action
  reset(): this {
    this.storeFormAzureADSupportConfiguration.reset()

    this.storeFlagsTenableCloudApiKeysStatusFetch.reset()
    this.storeFlagsAzureADConfigurationFetch.reset()
    this.storeFlagsAzureADConfigurationUpdate.reset()
    this.storeFlagsAzureADConfigurationSubmit.reset()
    this.storeFlagsTenableCloudPingStatus.reset()

    return this
  }

  /**
   * Save data collection status.
   */
  @action
  setAzureADSupportConfiguration(
    azureADSupportConfiguration: AzureAdSupportConfiguration
  ): this {
    const azureADSupportConfigurationEntity = createEntity<
      AzureAdSupportConfiguration,
      EntityAzureADSupportConfiguration
    >(EntityAzureADSupportConfiguration, azureADSupportConfiguration)

    this.$azureADSupportConfiguration.set(azureADSupportConfigurationEntity)
    return this
  }

  /**
   * Save ApiKeys status.
   */
  @action
  setApiKeysStatus(status: 'set' | 'unset'): this {
    this.$tenableCloudApiStatus.set(status)
    return this
  }

  /**
   * SaveTenable Cloud Api token.
   */
  @action
  setTenableCloudApiToken(
    tenableCloudApiToken: Maybe<TenableCloudApiToken>
  ): this {
    if (!tenableCloudApiToken) {
      this.$tenableCloudApiToken.set(null)
      this.$tenableCloudApiTokensStatus.set(ApiTokensStatus.notWorking)
      return this
    }

    const tenableCloudApiTokenEntity = createEntity<
      TenableCloudApiToken,
      EntityTenableCloudApiToken
    >(EntityTenableCloudApiToken, tenableCloudApiToken)

    this.$tenableCloudApiToken.set(tenableCloudApiTokenEntity)

    return this
  }

  /**
   * Save API keys status.
   */
  @action
  setTenableCloudApiKeysStatus(status: ApiTokensStatus): this {
    this.$tenableCloudApiTokensStatus.set(status)
    return this
  }

  /**
   * Computed
   */

  @computed
  get tenableCloudApiTokensStatus(): ApiTokensStatus {
    return this.$tenableCloudApiTokensStatus.get()
  }

  @computed
  get azureADSupportConfiguration(): Maybe<EntityAzureADSupportConfiguration> {
    return this.$azureADSupportConfiguration.get()
  }

  @computed
  get tenableCloudApiToken(): Maybe<EntityTenableCloudApiToken> {
    return this.$tenableCloudApiToken.get()
  }

  @computed
  get tenableCloudApiStatusSet(): boolean {
    return this.$tenableCloudApiStatus.get() === 'set'
  }

  @computed
  get isAzureAdSupportEnabled(): boolean {
    return this.azureADSupportConfiguration?.azureADSupportEnabled === true
  }

  @computed
  get isTenableCloudApiTokensWorking(): boolean {
    return (
      this.$tenableCloudApiTokensStatus.get() === ApiTokensStatus.working &&
      !this.storeFlagsTenableCloudPingStatus.isError
    )
  }

  @computed
  get showADSupportStatus(): boolean {
    if (!this.isAzureAdSupportEnabled) {
      return false
    }

    if (!this.tenableCloudApiStatusSet) {
      return false
    }

    return true
  }
}
