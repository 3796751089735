import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

const StyledPath = styled.path`
  fill: ${consts.colorBlue005};
  clip-rule: evenodd;
  fill-rule: evenodd;
`

interface IIconServerProps {
  className?: string
}

const IconServer: React.FC<IIconServerProps> = props => {
  return (
    <svg
      className={props.className}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPath
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4,2.858C3.448,2.858 3,3.305 3,3.858C3,4.41 3.448,4.858 4,4.858C4.552,4.858 5,4.41 5,3.858C5,3.305 4.552,2.858 4,2.858ZM7.5,3.358C7.224,3.358 7,3.582 7,3.858C7,4.134 7.224,4.358 7.5,4.358L13.5,4.358C13.776,4.358 14,4.134 14,3.858C14,3.582 13.776,3.358 13.5,3.358L7.5,3.358ZM16,3.358C16,3.082 16.224,2.858 16.5,2.858C16.776,2.858 17,3.082 17,3.358L17,4.358C17,4.634 16.776,4.858 16.5,4.858C16.224,4.858 16,4.634 16,4.358L16,3.358ZM18.5,2.858C18.224,2.858 18,3.082 18,3.358L18,4.358C18,4.634 18.224,4.858 18.5,4.858C18.776,4.858 19,4.634 19,4.358L19,3.358C19,3.082 18.776,2.858 18.5,2.858Z M0,1C0,0.448 0.448,0 1,0L21,0C21.552,0 22,0.448 22,1L22,21C22,21.552 21.552,22 21,22L1,22C0.448,22 0,21.552 0,21L0,1ZM1,0.963L1,7L21,7L21,1C21,1 1.552,0.963 1,0.963ZM21,8L1,8L1,14L21,14L21,8ZM21,15L1,15L1,21L21,21L21,15ZM4,10C3.448,10 3,10.448 3,11C3,11.552 3.448,12 4,12C4.552,12 5,11.552 5,11C5,10.448 4.552,10 4,10ZM7.5,10.5C7.224,10.5 7,10.724 7,11C7,11.276 7.224,11.5 7.5,11.5L13.5,11.5C13.776,11.5 14,11.276 14,11C14,10.724 13.776,10.5 13.5,10.5L7.5,10.5ZM16,10.5C16,10.224 16.224,10 16.5,10C16.776,10 17,10.224 17,10.5L17,11.5C17,11.776 16.776,12 16.5,12C16.224,12 16,11.776 16,11.5L16,10.5ZM18.5,10C18.224,10 18,10.224 18,10.5L18,11.5C18,11.776 18.224,12 18.5,12C18.776,12 19,11.776 19,11.5L19,10.5C19,10.224 18.776,10 18.5,10ZM4,17C3.448,17 3,17.448 3,18C3,18.552 3.448,19 4,19C4.552,19 5,18.552 5,18C5,17.448 4.552,17 4,17ZM7.5,17.5C7.224,17.5 7,17.724 7,18C7,18.276 7.224,18.5 7.5,18.5L13.5,18.5C13.776,18.5 14,18.276 14,18C14,17.724 13.776,17.5 13.5,17.5L7.5,17.5ZM16,17.5C16,17.224 16.224,17 16.5,17C16.776,17 17,17.224 17,17.5L17,18.5C17,18.776 16.776,19 16.5,19C16.224,19 16,18.776 16,18.5L16,17.5ZM18.5,17C18.224,17 18,17.224 18,17.5L18,18.5C18,18.776 18.224,19 18.5,19C18.776,19 19,18.776 19,18.5L19,17.5C19,17.224 18.776,17 18.5,17Z"
      />
    </svg>
  )
}

export default styled(IconServer)`
  width: 22px;
  height: 22px;
  fill: none;
`
