import { hexToRgb, toPercent, toPx } from '@app/styles/helpers'
import { buildVariants } from '@design-system/libs/buildVariants'
import { boundedBetween } from '@libs/numbers/helpers'
import { ScoreSeverityEnum } from '@libs/openapi/service-identity-core/models/Score'
import { darken } from 'polished'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '../Container'
import Label from '../Labels/Label'

export interface IExposureScoreProps {
  barWidth?: number
  value: number
  severity: ScoreSeverityEnum
}

export interface IBarProps {
  variant: 'background' | 'value'
  value: number
  severity: ScoreSeverityEnum
}

const colors = {
  background: '#eaeaea',
  unavailable: '#000000',
  low: '#2DF24D',
  medium: '#EFE600',
  high: '#FCB600',
  veryHigh: '#FF6F31',
  critical: '#FF014D'
}

type ColorObject = {
  hex: string
  rgb: [number, number, number]
}

function getBarColor(severity: ScoreSeverityEnum): ColorObject {
  const colorMap = new Map<ScoreSeverityEnum, string>([
    [ScoreSeverityEnum.Unavailable, colors.unavailable],
    [ScoreSeverityEnum.Low, colors.low],
    [ScoreSeverityEnum.Medium, colors.medium],
    [ScoreSeverityEnum.High, colors.high],
    [ScoreSeverityEnum.Critical, colors.veryHigh]
  ])

  const color = colorMap.get(severity) ?? colors.unavailable
  return {
    hex: color,
    rgb: hexToRgb(color)
  }
}

const BarsContainer = styled.div<{ barWidth: IExposureScoreProps['barWidth'] }>(
  props => {
    const barWidth = props.barWidth || 120

    return buildVariants(props)
      .css({
        position: 'relative',
        height: '10px',
        minWidth: toPx(barWidth)
      })
      .end()
  }
)

const Bar = styled.div<IBarProps>(props => {
  const barWidth = boundedBetween(0, 100)(Math.ceil(100 * props.value) / 1000)

  const fromColor = props.severity
    ? getBarColor(props.severity).rgb.join(',')
    : null
  const toColor = getBarColor(props.severity).rgb.join(',')

  return buildVariants(props)
    .css({
      position: 'absolute',
      left: 0,
      top: 0,
      height: '10px',
      borderRadius: '2px'
    })

    .variant('variant', props.variant, {
      background: {
        background: colors.background,
        width: '100%'
      },

      value: {
        background: `linear-gradient(90deg, rgba(${fromColor},0.5) 0%, rgba(${toColor},1) 100%)`,
        width: toPercent(barWidth)
      }
    })

    .end()
})

const Value = styled.div<{
  children: number
  severity: ScoreSeverityEnum
}>(props => {
  const color = getBarColor(props.severity)

  return buildVariants(props)
    .css({
      color: darken(0.15, color.hex),
      fontWeight: 500
    })
    .end()
})

/**
 * Display a horizontal bar of an Exposure Score, bounded between 0 and 1000.
 */
export default function ExposureScore(props: IExposureScoreProps) {
  const finalValue = boundedBetween(0, 1000)(props.value)
  const scoreIsAvailable = props.severity != ScoreSeverityEnum.Unavailable
  const valueOrNA = scoreIsAvailable ? (
    <Value severity={props.severity}>{finalValue}</Value>
  ) : (
    <Label label={'N/A'} />
  )

  return (
    <ContainerFlex
      name="ExposureScore"
      alignItems="center"
      flexGap="default"
      items={[
        <BarsContainer barWidth={props.barWidth}>
          <Bar variant="background" value={1000} severity={props.severity} />
          {scoreIsAvailable && (
            <Bar variant="value" value={finalValue} severity={props.severity} />
          )}
        </BarsContainer>,
        valueOrNA
      ]}
    />
  )
}
