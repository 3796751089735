import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import type {
  AttackDestination,
  AttackDestinationType,
  Maybe
} from '@server/graphql/typeDefs/types'

export default class EntityAttackDestination
  extends EntityBase
  implements PropertiesNullable<AttackDestination>
{
  type: Maybe<AttackDestinationType> = null
  hostname: Maybe<string> = null
  ip: Maybe<string> = null

  constructor(data: Partial<AttackDestination>) {
    super()
    Object.assign(this, data)
  }
}
