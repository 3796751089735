import { ContainerFlex } from '@app/components-legacy/Container'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import type { IAuthPageLayout } from './types'

interface IAuthPlaceHoldersProps {
  className?: string
  layout: IAuthPageLayout
}

const AuthPlaceHolders: React.FC<IAuthPlaceHoldersProps> = props => {
  return (
    <ContainerFlex
      name="AuthPlaceHolders"
      className={props.className}
      direction="column"
      itemsFlexGrow={[0, 1]}
      items={[
        props.layout.header && <div>{props.layout.header}</div>,

        <ContainerFlex
          name="AuthPlaceHoldersBottom"
          direction="column"
          itemsFlexGrow={[0, 1]}
          items={[
            props.layout.menu && <div>{props.layout.menu}</div>,

            <ContainerFlex
              name="AuthPlaceHoldersContentAndFooter"
              direction="column-reverse"
              itemsFlexGrow={[0, 1]}
              items={[
                props.layout.footer && <div>{props.layout.footer}</div>,

                <div>{props.layout.content}</div>
              ]}
              spaced
              fullHeight
            />
          ]}
          spaced
        />
      ]}
    />
  )
}

export default styled(AuthPlaceHolders)`
  background-color: white;
  box-shadow: 0px 0px 61px 0px rgba(0, 0, 0, 0.75);
  border-radius: ${consts.containerBorderRadius};
  min-height: 550px;
  width: 550px;
  margin: 10px 0;
`
