import type { StoreDashboards } from '@app/stores'
import type { InstanceName } from '@libs/Environment/types'

export const onDashboardRemoveValidate =
  (storeDashboards: StoreDashboards) =>
  (ids: {
    instanceName: InstanceName
    dashboardId: number
    dashboardWidgetId: number
  }) =>
  (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault()
    }

    const storeDashboard = storeDashboards.currentStoreDashboard

    if (!storeDashboard) {
      return
    }

    const { storeDrawer } = storeDashboard.storeWidgetsManagement

    storeDashboard.storeWidgetsManagement
      .deleteDashboardWidget(ids.dashboardId, ids.dashboardWidgetId)
      .then(() =>
        storeDashboards.fetchOneDashboard(ids.instanceName, ids.dashboardId)
      )
      .then(() => storeDrawer.closeDrawer())
      .catch(() => {
        /* Already handled in the store */
      })
  }
