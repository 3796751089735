import type { StoreRoot } from '@app/stores'
import StoreForm from '@app/stores/helpers/StoreForm'
import type { ICheckerOptionOWCTConfigurationValues } from '@app/stores/Management/StoreProfiles/types'
import { CheckerOptionOWCTConfigurationFieldName } from '@app/stores/Management/StoreProfiles/types'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { ensureArray } from '@libs/ensureArray'
import { isNotFalsy } from '@libs/isDefined'
import { action, computed, makeObservable, observable } from 'mobx'

/**
 * Store dedicated to the checker option O-WHITELIST-CERTIFICATE-TEMPLATES (OWCT).
 */
export default class StoreProfileCheckerOptionOWCT extends StoreBase {
  // for each configuration, use an instance of a StoreForm
  private _storeFormConfigurations = observable.map<
    number,
    StoreForm<CheckerOptionOWCTConfigurationFieldName>
  >()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Set the form default configurations fields.
   */
  initConfigurations(configurationValues: string): void {
    this.resetConfigurations()

    try {
      const decodedValues: ICheckerOptionOWCTConfigurationValues[] =
        ensureArray(JSON.parse(configurationValues))

      decodedValues.forEach(decodedValue => {
        this.addConfiguration(decodedValue)
      })
    } catch (err) {
      this.storeRoot.logger.error(
        `Error when parsing configuration for the O-WHITELIST-CERTIFICATE-TEMPLATES option`
      )
    }
  }

  /* Actions */

  @action
  resetConfigurations(): void {
    this._storeFormConfigurations.clear()
  }

  /**
   * Add a new configuration.
   */
  @action
  addConfiguration(decodedValue?: ICheckerOptionOWCTConfigurationValues): void {
    const storeForm = new StoreForm<CheckerOptionOWCTConfigurationFieldName>(
      this.storeRoot
    )

    storeForm.setDefaultFieldsValues([
      {
        key: CheckerOptionOWCTConfigurationFieldName.displayName,
        value: decodedValue ? decodedValue.displayName : ''
      },
      {
        key: CheckerOptionOWCTConfigurationFieldName.trusteeId,
        value: decodedValue ? decodedValue.trusteeId : ''
      }
    ])

    // apply default fields to values
    storeForm.reset()

    this._storeFormConfigurations.set(
      this._storeFormConfigurations.size,
      storeForm
    )
  }

  /**
   * Remove a configuration.
   */
  @action
  removeConfiguration(index: number): void {
    this._storeFormConfigurations.delete(index)
  }

  /* Computed */

  /**
   * Return the storeForms instances for all the configuration.
   */
  @computed
  get storeFormConfigurations(): Array<
    StoreForm<CheckerOptionOWCTConfigurationFieldName>
  > {
    return Array.from(this._storeFormConfigurations.values())
  }

  /**
   * Return the encoded values for the option.
   */
  @computed
  get configurationValues(): ICheckerOptionOWCTConfigurationValues[] {
    return (
      Array.from(this._storeFormConfigurations.values())
        .filter(storeForm => {
          return storeForm.getFieldValueAsString(
            CheckerOptionOWCTConfigurationFieldName.displayName
          )
        })
        // filter configuration where displayName is not empty
        .filter(isNotFalsy)
        .map(storeForm => {
          return {
            [CheckerOptionOWCTConfigurationFieldName.displayName]:
              storeForm.getFieldValueAsString(
                CheckerOptionOWCTConfigurationFieldName.displayName
              ),
            [CheckerOptionOWCTConfigurationFieldName.trusteeId]:
              storeForm.getFieldValueAsString(
                CheckerOptionOWCTConfigurationFieldName.trusteeId
              )
          }
        })
    )
  }
}
