import type { Query } from '../typeDefs/types'

/**
 * Fetch reasons.
 */
export type QueryReasons = Pick<Query, 'rbacReasons'>

export const queryReasons = `
  query queryReasons {
    rbacReasons {
      node {
        id
        codename
        name
        description
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
