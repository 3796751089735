import type { EntityAttacksStat, EntityAttacksSummaryCard } from '@app/entities'
import { CriticityValuesOrdered, getCriticityColor } from '@libs/criticity'
import type { DashboardWidgetOptionsSerieStatDataPointCount } from '@server/graphql/typeDefs/types'
import type { IAttackChartData, IAttackSortData } from './types'

function computeRate(
  dataCount: number,
  attacksCount: number
): IAttackChartData['rate'] {
  // avoid division by zero
  if (attacksCount === 0) {
    return 0
  }

  const result = (dataCount * 100) / attacksCount
  const roundedResult = result.toFixed(2)

  return Number(roundedResult)
}

/**
 * Compute statsCount into data for donut chart.
 */
export function getRateOfAttacksByCriticity(
  attacksSummaryCardEntity: EntityAttacksSummaryCard
): IAttackChartData[] {
  return attacksSummaryCardEntity.statsCountEntities.map(
    (statCounts, index) => {
      const criticity = CriticityValuesOrdered[index]
      const criticityColor = getCriticityColor(criticity)

      return {
        criticity,
        criticityColor,
        rate: computeRate(
          statCounts.dataCount,
          attacksSummaryCardEntity.attacksCount
        )
      }
    }
  )
}

/**
 * Compute statsCount into data for sorting
 */
export function getNumberOfAttacksByCriticity(
  attacksSummaryCardEntity: EntityAttacksSummaryCard
): IAttackSortData[] {
  return attacksSummaryCardEntity.statsCountEntities.map(
    (statCounts, index) => {
      const criticity = CriticityValuesOrdered[index]

      return {
        criticity,
        count: statCounts.dataCount
      }
    }
  )
}

/**
 * Compute attacksStats into data for line chart.
 */
export function getNumberOfAttacksInTime(
  attacksStatEntities: EntityAttacksStat[]
): DashboardWidgetOptionsSerieStatDataPointCount[] {
  const EMPTY_COMPUTED_DATA: DashboardWidgetOptionsSerieStatDataPointCount[] =
    []

  return attacksStatEntities.reduce((acc, curr) => {
    if (!acc.length) {
      return curr.dataEntities.map(dataEntity => {
        return {
          count: dataEntity.getPropertyAsNumber('count'),
          timestamp: dataEntity.getPropertyAsString('timestamp')
        }
      })
    }

    return acc.map(accumulatedStatDataCounts => {
      const attacksStatDataEntity = curr.dataEntities.find(
        dataEntity =>
          dataEntity.getPropertyAsString('timestamp') ===
          accumulatedStatDataCounts.timestamp
      )

      if (!attacksStatDataEntity) {
        return accumulatedStatDataCounts
      }

      return {
        count:
          accumulatedStatDataCounts.count +
          attacksStatDataEntity.getPropertyAsNumber('count'),
        timestamp: accumulatedStatDataCounts.timestamp
      }
    })
  }, EMPTY_COMPUTED_DATA)
}
