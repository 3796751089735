import { ContainerFlex, ContainerIcon } from '@app/components-legacy/Container'
import {
  IconMinusCircleOutlined,
  IconPlusCircleOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import type StoreForm from '@app/stores/helpers/StoreForm'
import type { InputMultiValuesOnChange } from '@app/stores/helpers/StoreForm/types'
import { consts } from '@app/styles'
import { Input } from 'antd'
import type { BaseButtonProps } from 'antd/lib/button/button'
import { range } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  onMultiInputAddIconClick,
  onMultiInputRemoveIconClick,
  onMultiInputChange
} from './handlers'
import { makeFieldName } from './tools'

export interface IInputMultiValuesProps {
  name: string
  placeholders?: string | string[]
  onChange: InputMultiValuesOnChange
  disabled?: boolean
  size?: BaseButtonProps['size']
  storeForm: StoreForm<any>
}

const InputMultiValues: React.FC<IInputMultiValuesProps> = props => {
  const [numberOfInputs, setNumberOfInputs] = React.useState<number>(
    Math.max(
      props.storeForm.getMultiValuesFields(props.name, {
        removeEmptyFields: false
      }).length,
      1
    )
  )

  const { testAttributeProps } = useTestAttribute('form')

  /**
   * Because `numberOfInputs` is stored in a local state, the component will not be
   * refreshed if the number of multi-values changes in the store.
   *
   * This hook observes the number of values saved in the store in order to update the
   * local state and force a refresh of the component.
   */
  React.useEffect(() => {
    // the number of fields rendered is the max between:
    const nbFields = Math.max(
      // keep at least 1 field
      1,
      // the number of fields added manually (after a click on the '+' icon)
      numberOfInputs,
      // the number of values into the field, stored in the store
      props.storeForm.getMultiValuesFields(props.name).length
    )
    setNumberOfInputs(nbFields)
  }, [props.storeForm.getMultiValuesFields(props.name).length])

  const renderInputs = () => {
    return range(0, numberOfInputs).map(i => {
      const inputName = makeFieldName(props.name, i)

      const placeholder = Array.isArray(props.placeholders)
        ? props.placeholders[i]
        : props.placeholders

      // create/get the field
      const field = props.storeForm.field(inputName)

      // keep meta of the "root" field
      props.storeForm.field(props.name).meta.copyToField(field)
      const hasError = field.errors.length > 0

      // the div below is needed for the style props to work correctly
      return (
        <ContainerFlex
          key={inputName}
          name="InputMultiValues"
          alignItems="center"
          items={[
            <div>
              <Input
                name={inputName}
                size={props.size}
                placeholder={placeholder}
                style={{
                  borderColor: hasError ? consts.colorRed001 : undefined
                }}
                onChange={onMultiInputChange(props.name, i)(props.onChange)}
                value={field.asString}
                disabled={props.disabled === true}
                autoComplete="off"
                {...testAttributeProps('textbox')(inputName ?? 'search')}
              />
            </div>,
            ...getIcons(inputName, i)
          ]}
          spaceWidth="verySmall"
          spaced
        />
      )
    })
  }

  const getIcons = (inputName: string, index: number) => {
    if (props.disabled === true) {
      return []
    }

    const items = []

    if (numberOfInputs > 1) {
      items.push(
        <ContainerIcon
          labelledBy={`${inputName}-removeValue-${index}`}
          iconComponent={IconMinusCircleOutlined}
          iconProps={{
            onClick: onMultiInputRemoveIconClick(props.storeForm)(
              props.name,
              index,
              numberOfInputs,
              setNumberOfInputs,
              props.onChange
            )
          }}
        />
      )
    }

    items.push(
      <ContainerIcon
        labelledBy={`${inputName}-addValue-${index}`}
        iconComponent={IconPlusCircleOutlined}
        iconProps={{
          onClick: onMultiInputAddIconClick(numberOfInputs, setNumberOfInputs)
        }}
      />
    )

    return items
  }

  return (
    <ContainerFlex
      name="InputMultiValues"
      direction="column"
      items={renderInputs()}
      spaced
      spaceWidth="verySmall"
    />
  )
}

export default observer(InputMultiValues)
