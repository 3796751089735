import type { PropertiesNullable } from '@@types/helpers'
import type SceneObject from '@app/pages/Topology/SceneBlade/Scene/SceneObject'
import {
  SCENE_DOMAIN_TRIANGLE_HEIGHT,
  SCENE_DOMAIN_TRIANGLE_LONG
} from '@app/stores/Topology/consts'
import { TrustAttributesName } from '@app/stores/Topology/types'
import type {
  Maybe,
  TopologyTrust,
  TrustHazardLevel
} from '@server/graphql/typeDefs/types'
import type { EntityTopologyDirectory } from '.'
import EntityBase from './EntityBase'

export const trustAttributesNameValues: Record<TrustAttributesName, string> = {
  [TrustAttributesName.NON_TRANSITIVE]: 'Non-transitive',
  [TrustAttributesName.UPLEVEL_ONLY]: 'Uplevel only',
  [TrustAttributesName.QUARANTINED_DOMAIN]: 'Quarantined',
  [TrustAttributesName.FOREST_TRANSITIVE]: 'Forest transitive',
  [TrustAttributesName.CROSS_ORGANIZATION_OR_SELECTIVE_AUTH]:
    'Cross-organization or selective authentication',
  [TrustAttributesName.WITHIN_FOREST]: 'Within forest',
  [TrustAttributesName.TREAT_AS_EXTERNAL]: 'Treat as external',
  [TrustAttributesName.RC4_ENCRYPTION]: 'RC4 encryption',
  [TrustAttributesName.AES_KEYS]: 'AES keys',
  [TrustAttributesName.CROSS_ORGANIZATION_NO_TGT_DELEGATION]:
    'Cross-organization without TGT delegation',
  [TrustAttributesName.PIM_TRUST]: 'PIM trust',
  [TrustAttributesName.CROSS_ORGANIZATION_ENABLE_TGT_DELEGATION]:
    'Cross-organization with TGT delegation',
  [TrustAttributesName.TREE_PARENT]: 'Tree parent',
  [TrustAttributesName.TREE_ROOT]: 'Tree root',
  [TrustAttributesName.NO_ATTRIBUTE]: 'No attribute'
}

export default class EntityTopologyTrust
  extends EntityBase
  implements PropertiesNullable<TopologyTrust>
{
  from: Maybe<string> = null
  to: Maybe<string> = null
  hazardLevel: Maybe<TrustHazardLevel> = null
  attributes: Maybe<string[]> = null

  constructor(data: Partial<TopologyTrust>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Compte the Uid of the trust.
   */
  computeUid(
    directorySceneObjectFrom: SceneObject<EntityTopologyDirectory>,
    directorySceneObjectTo: SceneObject<EntityTopologyDirectory>,
    hazardLevel: Maybe<TrustHazardLevel>
  ): string {
    return `${directorySceneObjectFrom.object.name}${directorySceneObjectTo.object.name}${hazardLevel}`
  }

  /**
   * Compute the string used in the path SVG tag.
   */
  computeDrawString(
    directorySceneObjectFrom: SceneObject<EntityTopologyDirectory>,
    directorySceneObjectTo: SceneObject<EntityTopologyDirectory>,
    isInternal: boolean,
    applyOffset?: boolean
  ): string {
    const fromCoordinates = `${
      directorySceneObjectFrom.coordinates.x + SCENE_DOMAIN_TRIANGLE_LONG / 2
    },${directorySceneObjectFrom.coordinates.y + SCENE_DOMAIN_TRIANGLE_HEIGHT}`

    const toCoordinates = `${
      directorySceneObjectTo.coordinates.x + SCENE_DOMAIN_TRIANGLE_LONG / 2
    },${directorySceneObjectTo.coordinates.y + SCENE_DOMAIN_TRIANGLE_HEIGHT}`

    if (isInternal) {
      const cpx =
        (directorySceneObjectFrom.coordinates.x +
          SCENE_DOMAIN_TRIANGLE_LONG / 2 +
          directorySceneObjectTo.coordinates.x +
          SCENE_DOMAIN_TRIANGLE_LONG / 2) /
        2
      const offsetY = 30
      const cpY =
        directorySceneObjectTo.coordinates.y +
        SCENE_DOMAIN_TRIANGLE_HEIGHT +
        offsetY
      const controlPoint = `${cpx},${cpY}`

      return `M${fromCoordinates} Q ${controlPoint} ${toCoordinates}`
    }

    // FIXME to remove if the new solution is really better
    /* const controlPoint = `${
      directorySceneObjectFrom.coordinates.x + SCENE_DOMAIN_TRIANGLE_LONG / 2
    },${directorySceneObjectTo.coordinates.y + SCENE_DOMAIN_TRIANGLE_HEIGHT}` */

    const p1x =
      directorySceneObjectFrom.coordinates.x + SCENE_DOMAIN_TRIANGLE_LONG / 2
    const p1y =
      directorySceneObjectFrom.coordinates.y + SCENE_DOMAIN_TRIANGLE_HEIGHT
    const p2x =
      directorySceneObjectTo.coordinates.x + SCENE_DOMAIN_TRIANGLE_LONG / 2
    const p2y =
      directorySceneObjectTo.coordinates.y + SCENE_DOMAIN_TRIANGLE_HEIGHT

    // mid-point of line:
    const mpx = (p2x + p1x) * 0.5
    const mpy = (p2y + p1y) * 0.5

    // angle of perpendicular to line:
    const theta = Math.atan2(p2y - p1y, p2x - p1x) - Math.PI / 2

    // distance of control point from mid-point of line:
    const offset = applyOffset ? -200 : -150

    // location of control point:
    const c1x = mpx + offset * Math.cos(theta)
    const c1y = mpy + offset * Math.sin(theta)

    return `M${fromCoordinates} Q${c1x},${c1y} ${toCoordinates}`
  }
}
