import type { StoreAuthentication } from '@app/stores'
import { getTimeZones } from '@vvo/tzdb'

export const getProfiles = (storeAuthentication: StoreAuthentication) => {
  return Array.from(storeAuthentication.profiles.values()).map(profile => {
    return {
      label: profile.getPropertyAsString('name'),
      value: profile.getPropertyAsString('id'),
      labelledBy: `${profile.getPropertyAsString(
        'name'
      )}-${profile.getPropertyAsString('id')}`
    }
  })
}

/**
 * Return the closest browser time zone value from the @vvo/tzdb time zones list, or Etc/UTC if not found or if the
 * method is not supported by the browser.
 */
export function getBrowserTimeZone(): string {
  try {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (!browserTimeZone) {
      return 'Etc/UTC'
    }

    const timeZones = getTimeZones()

    const matchingNameTimeZone = timeZones.find(timeZone => {
      return browserTimeZone === timeZone.name
    })

    if (matchingNameTimeZone) {
      return matchingNameTimeZone.name
    }

    const matchingGroupTimeZone = timeZones.find(timeZone =>
      timeZone.group.includes(browserTimeZone)
    )

    if (matchingGroupTimeZone) {
      return matchingGroupTimeZone.name
    }

    return 'Etc/UTC'
  } catch (err) {
    return 'Etc/UTC'
  }
}
