import type { MaybeUndef } from '@@types/helpers'
import type { IWidgetListBulkActionOption } from '@app/components-legacy/Widgets/WidgetList/types'
import type { EntityAlertIoA, EntityAlertIoE } from '@app/entities'
import { AppRouteName } from '@app/routes'
import type { StoreAlerts } from '@app/stores'
import type StoreGenericAlerts from '@app/stores/StoreAlerts/StoreGenericAlerts'
import type {
  InputEditAlertIoABulkPayload,
  InputEditAlertIoEBulkPayload
} from '@server/graphql/typeDefs/types'
import { AttackResourceType, CheckerType } from '@server/graphql/typeDefs/types'
import { canReadAlertsIoA, canReadAlertsIoE } from '../../permissions'
import { AlertsBulkActionValue } from '../types'
import { AlertActionKey } from './types'

/**
 * Fetch checkers information when opening the alerts drawer to retrieve
 * checkers options (criticities).
 */
export const onAlertsDrawerLoad = (storeAlerts: StoreAlerts) => () => {
  const { storeCheckers } = storeAlerts.storeRoot.stores

  storeCheckers.fetchCheckers()
}

export const onAlertsDrawerUnload = (storeAlerts: StoreAlerts) => () => {
  storeAlerts.reset()
}

/**
 * Load IoE or IoA alerts.
 */
export const onAlertsDrawerMenuLoad =
  (storeGenericAlerts: StoreGenericAlerts<any>) => () => {
    // set the default selected tab according to the license features
    const { storeRbac, storeAlerts } = storeGenericAlerts.storeRoot.stores

    if (
      storeAlerts.storeMenu.selectedMenuKey === CheckerType.Exposure &&
      storeRbac.isUserGrantedTo(canReadAlertsIoE)
    ) {
      storeAlerts.storeMenu.selectEntry(CheckerType.Exposure)
    }

    if (
      storeAlerts.storeMenu.selectedMenuKey === CheckerType.Attack &&
      storeRbac.isUserGrantedTo(canReadAlertsIoA)
    ) {
      storeAlerts.storeMenu.selectEntry(CheckerType.Attack)
    }

    storeGenericAlerts.fetchAlerts(undefined, { forceCount: true })
  }

/**
 * Redirect to the deviance of the alert.
 */
export const onAlertIoESeeDevianceClick =
  (storeAlerts: StoreAlerts) =>
  (alert: EntityAlertIoE) =>
  (actionKey: string) => {
    if (actionKey !== AlertActionKey.seeDeviance) {
      return
    }

    const { appRouter } = storeAlerts.storeRoot
    const { storeMessages } = storeAlerts.storeRoot.stores

    const deviance = alert.getDeviance()

    if (!deviance) {
      storeMessages.genericError()
      return
    }

    // build the url to the event details
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.TrailFlow_EventDetails_Attributes,
      parameters: {
        infrastructureId: alert.getPropertyAsNumber('infrastructureId'),
        directoryId: alert.getPropertyAsNumber('directoryId'),
        eventId: deviance.getPropertyAsString('createdEventId')
      }
    })

    // make a hard redirect for now, there are some issues with blades
    // when pushing an url on the "same level"...
    document.location.href = url
  }

/**
 * Redirect to attacks.
 */
export const onAlertSeeAttacksClick =
  (storeAlerts: StoreAlerts) =>
  (alert: EntityAlertIoA) =>
  (actionKey: string) => {
    if (actionKey !== AlertActionKey.seeAttacks) {
      return
    }

    const { appRouter } = storeAlerts.storeRoot

    // Hard redirect needed because investigation view is not fully url driven and
    // cannot handle filters changes through url
    appRouter.hardRedirect(
      {
        routeName: AppRouteName.IoA_Attacks,
        parameters: {},
        queryStringParameters: {
          attacksFilters: [
            {
              dateStart: alert.date,
              dateEnd: alert.date,
              resourceType: AttackResourceType.Directory,
              resourceValue: String(alert.directoryId),
              attackTypeIds: [String(alert.attackTypeId)],
              includeClosed: 'true'
            }
          ]
        }
      },
      { arrayFormat: 'indices' }
    )

    storeAlerts.closeDrawer()
  }

export const onAlertSelection =
  (storeGenericAlerts: StoreGenericAlerts<any>) => (alertId: number) => () => {
    storeGenericAlerts.storeWidgetList.selectRow({ id: alertId })
  }

export const onAlertUnselection =
  (storeGenericAlerts: StoreGenericAlerts<any>) => (alertId: number) => () => {
    storeGenericAlerts.storeWidgetList.unselectRow({ id: alertId }, [])
  }

/**
 * Fetch new page.
 */
export const onAlertPageChange =
  (storeGenericAlerts: StoreGenericAlerts<any>) =>
  (page: number, pageSize: MaybeUndef<number>) => {
    storeGenericAlerts.fetchAlerts(
      {
        alertsPage: page,
        alertsPerPage: pageSize,
        showArchived: storeGenericAlerts.showArchivedStatus
      },
      {
        forceCount: true
      }
    )
  }

/**
 * Update the archived status.
 */
export const onAlertShowArchivedChange =
  (storeGenericAlerts: StoreGenericAlerts<any>) => (checked: boolean) => {
    storeGenericAlerts.setShowArchivedStatus(checked)
    storeGenericAlerts.fetchArchivedAlerts()
  }

/**
 * Update the status of the selected alerts.
 */
export const onAlertActionSelection =
  (storeGenericAlerts: StoreGenericAlerts<any>) =>
  (option: IWidgetListBulkActionOption) => {
    const isBulk = storeGenericAlerts.storeWidgetList.isAllRowsSelected

    if (isBulk) {
      editAllAlerts(storeGenericAlerts)(option)
    } else {
      editAlerts(storeGenericAlerts)(option)
    }
  }

/**
 * Update the status of all alerts (all pages).
 */
export const editAllAlerts =
  (storeGenericAlerts: StoreGenericAlerts<any>) =>
  (option: IWidgetListBulkActionOption) => {
    const { storeAuthentication } = storeGenericAlerts.storeRoot.stores

    let alertBulkPayload:
      | InputEditAlertIoEBulkPayload
      | InputEditAlertIoABulkPayload

    // retrieve payload according to the checker type
    if (storeGenericAlerts.options.checkerType === CheckerType.Exposure) {
      // if the selection is archived, set the alert as read too
      alertBulkPayload = {
        profileId: storeAuthentication.currentProfileId,
        read: option.value === AlertsBulkActionValue.archived,
        archived: option.value === AlertsBulkActionValue.archived
      }
    } else {
      // if the selection is archived, set the alert as read too
      alertBulkPayload = {
        profileId: storeAuthentication.currentProfileId,
        read: option.value === AlertsBulkActionValue.archived,
        archived: option.value === AlertsBulkActionValue.archived
      }
    }

    return (
      storeGenericAlerts
        .editBulkAlerts(alertBulkPayload)
        // reload alerts
        .then(() => storeGenericAlerts.fetchAlertsAfterAction())
        // unselect alerts
        .then(() => storeGenericAlerts.storeWidgetList.unselectAllRows())
    )
  }

/**
 * Update the status of the selected alerts.
 */
export const editAlerts =
  (storeGenericAlerts: StoreGenericAlerts<any>) =>
  (option: IWidgetListBulkActionOption) => {
    const { selectedRows } = storeGenericAlerts.storeWidgetList

    if (!selectedRows) {
      return
    }

    const inputEditAlerts = Array.from(selectedRows.values()).map(row => {
      return {
        alertId: Number(row.id),
        archived: option.value === AlertsBulkActionValue.archived
      }
    })

    return (
      storeGenericAlerts
        .editAlerts(inputEditAlerts)
        // reload alerts
        .then(() => storeGenericAlerts.fetchAlertsAfterAction())
        // unselect alerts
        .then(() => storeGenericAlerts.storeWidgetList.unselectAllRows())
    )
  }
