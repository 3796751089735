import { Features } from '@alsid/common'
import { BladesContainer } from '@app/components-legacy/Blade'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LayoutDefault } from '@app/components-legacy/Layout'
import { useAppRouter, useStores } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { AppRouteName } from '@app/routes'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { DSThemeName } from '@design-system/styles/themes/types'
import * as React from 'react'
import styled, { css } from 'styled-components'
import DashboardPage from './DashboardPage'

const StyledLayoutDefault = styled(LayoutDefault)`
  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      .ant-input {
        width: 300px !important;
      }
    `}
`

interface IDashboardProps {}

const Dashboard: React.FC<IDashboardProps> = () => {
  const appRouter = useAppRouter()

  const { storeDashboards } = useStores()

  const featureDashboardQuickWin = useFeatureFlag({
    featureFlagName: Features.DASHBOARD_QUICK_WIN
  })

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Dashboard_Grid,
    parameters: {
      instanceName: String(),
      dashboardId: Number()
    }
  })

  // Redirect to the dashboards and initialize first dashboard when parameters
  // (of dashboardId) are not set yet or if set but no dashboards are fetched yet.
  // This effect can't be called into a onLoad props like usual because we need to
  // observe the parameters.
  React.useEffect(() => {
    const noDashboards = storeDashboards.sortedDashboards.length === 0

    if (noDashboards || !parameters) {
      storeDashboards.redirectToDashboards()
    }
  }, [parameters])

  return (
    <DSThemeProvider
      themeName={
        featureDashboardQuickWin ? DSThemeName.default : DSThemeName.legacy
      }
    >
      <StyledLayoutDefault
        content={
          <ContainerFlex
            name="Dashboard"
            items={[
              <BladesContainer>
                <DashboardPage />
              </BladesContainer>
            ]}
          />
        }
      />
    </DSThemeProvider>
  )
}

export default Dashboard
