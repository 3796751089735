import { ContainerFlex } from '@app/components/Container'
import { LabelAltError, LabelSliced } from '@app/components/Labels'
import {
  ContainerContent,
  ContainerIcon,
  ContainerRbac
} from '@app/components-legacy/Container'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import {
  IconDeleteOutlined,
  IconEditOutlined
} from '@app/components-legacy/Icon/IconAntd'
import IconBlank from '@app/components-legacy/Icon/IconBlank'
import HumanReadableCron from '@app/components-legacy/Input/InputCrontab/HumanReadableCron'
import { LabelDirectories } from '@app/components-legacy/Label'
import {
  WidgetListActionButtons,
  WidgetListActionsHeader,
  WidgetListTable,
  WidgetListTableActionIcons
} from '@app/components-legacy/Widgets/WidgetList'
import type { EntityReport } from '@app/entities'
import type { IDataRowReport } from '@app/entities/EntityReport'
import { useAppTranslator, useStores } from '@app/hooks'
import ConfigurationReportsReportAccessToken from '@app/pages/Management/SystemPage/ConfigurationPage/ConfigurationReportingCenterPage/ConfigurationReportsList/ConfigurationReportsReportAccessToken'
import { createReport, grant } from '@libs/rbac/permissions'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { ReportType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleReportDeletionOnClick } from '../DrawerDeleteReport/handlers'
import { handleCreateReportOnClick } from '../handlers'
import { canDeleteReport, canEditReport } from '../permissions'
import EmailsTags from './EmailsTags'
import { handleReportEditionOnClick } from './handlers'

export interface IConfigurationReportsListProps {}

function ConfigurationReportsList(props: IConfigurationReportsListProps) {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.ReportingCenter']
  })

  const { storeAuthentication, storeManagementReportingCenter, storeRbac } =
    useStores()

  const canCreateReport = storeRbac.isUserGrantedTo(createReport())

  return (
    <ContainerContent>
      <ContainerFlex
        name="ConfigurationReportsList"
        direction="column"
        flexGap="large"
        items={[
          <WidgetListActionsHeader
            containerFlexProps={{ alignItems: 'normal' }}
          >
            <ConfigurationReportsReportAccessToken />

            <WidgetListActionButtons>
              <ContainerRbac rbacCapabilityCheck={grant()}>
                <FormWrapperButton
                  labelledBy="addReport"
                  variant={ButtonVariant.primary}
                  size={ButtonSize.large}
                  buttonProps={{
                    disabled: !canCreateReport,
                    onClick: handleCreateReportOnClick(
                      storeManagementReportingCenter
                    )
                  }}
                >
                  {translate('Create report')}
                </FormWrapperButton>
              </ContainerRbac>
            </WidgetListActionButtons>
          </WidgetListActionsHeader>,

          <WidgetListTable<EntityReport, IDataRowReport>
            translate={translate}
            hiddenColumnsKeys={['id']}
            storeWidgetList={storeManagementReportingCenter.storeWidgetList}
            cellsRenderFn={{
              name: value => {
                return <LabelSliced maxWidth={200} value={value} />
              },
              type: type => {
                switch (type) {
                  case ReportType.Deviances: {
                    return translate('Deviances')
                  }

                  case ReportType.Attacks: {
                    return translate('Attacks')
                  }

                  default:
                    assertUnreachableCase(type)
                }
              },
              indicator: ({ type, ids }) => {
                switch (type) {
                  case ReportType.Deviances: {
                    return (
                      <div>
                        {ids.length}/
                        {
                          storeManagementReportingCenter
                            .storeInputCheckersExposure.checkers.length
                        }
                      </div>
                    )
                  }

                  case ReportType.Attacks: {
                    return (
                      <div>
                        {ids.length}/
                        {
                          storeManagementReportingCenter
                            .storeInputCheckersAttacks.checkers.length
                        }
                      </div>
                    )
                  }

                  default:
                    assertUnreachableCase(type)
                }
              },
              profileId: profileId => {
                const profileName =
                  storeAuthentication.profiles.get(profileId)?.name

                if (!profileName) {
                  return (
                    <LabelAltError labelledBy="noProfile">
                      No profile set
                    </LabelAltError>
                  )
                }

                return <LabelSliced maxWidth={150} value={profileName} />
              },
              directoryIds: directoryIds => {
                return <LabelDirectories directoryIds={directoryIds} />
              },
              recipientEmails: emails => {
                return <EmailsTags emails={emails} />
              },
              dataTimeframe: DataTimeframe => {
                return <div>{translate(DataTimeframe)}</div>
              },
              recurrence: recurrence => {
                return (
                  <HumanReadableCron
                    crontab={recurrence}
                    type={'tableLabel'}
                    maxWidth={250}
                  />
                )
              },
              timeZone: timeZone => {
                return <LabelSliced value={timeZone} maxWidth={120} />
              }
            }}
            actionsButtonsRenderFn={reportId => {
              return (
                <WidgetListTableActionIcons
                  icons={[
                    <ContainerRbac
                      rbacCapabilityCheck={canEditReport(Number(reportId))}
                      render={({ isGranted }) => {
                        return isGranted ? (
                          <ContainerIcon
                            labelledBy={`editReport-${reportId}`}
                            iconComponent={IconEditOutlined}
                            iconProps={{
                              type: 'edit',
                              onClick: handleReportEditionOnClick(
                                storeManagementReportingCenter
                              )(Number(reportId))
                            }}
                          />
                        ) : (
                          <IconBlank />
                        )
                      }}
                    />,

                    <ContainerRbac
                      rbacCapabilityCheck={canDeleteReport(Number(reportId))}
                      render={({ isGranted }) => {
                        return isGranted ? (
                          <ContainerIcon
                            labelledBy={`deleteReport-${reportId}`}
                            iconComponent={IconDeleteOutlined}
                            iconProps={{
                              onClick: handleReportDeletionOnClick(
                                storeManagementReportingCenter
                              )(Number(reportId))
                            }}
                          />
                        ) : (
                          <IconBlank />
                        )
                      }}
                    />
                  ]}
                />
              )
            }}
          />
        ]}
      />
    </ContainerContent>
  )
}

export default observer(ConfigurationReportsList)
