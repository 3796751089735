import * as React from 'react'
import type { IIconProps } from '../types'

export default function IconOkta(props: IIconProps) {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#5FA6EF" />
      <circle cx="8" cy="8" r="4" fill="white" />
    </svg>
  )
}
