import { ContainerFlex } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks'
import { LockoutPolicyFormFieldName } from '@app/stores/Management/StoreLockoutPolicy/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditLockoutPolicy } from '../permissions'
import {
  LOCKOUT_POLICY_FAILED_ATTEMPTS_COUNTS_MAX,
  LOCKOUT_POLICY_FAILED_ATTEMPTS_COUNTS_MIN,
  LOCKOUT_POLICY_FAILED_ATTEMPTS_COUNTS_STEPS
} from './consts'
import { handleLockoutPolicyFieldsOnChange } from './handlers'
import StyledFormWrapperInput from './StyledFormWrapperInput'
import StyledSlider from './StyledSlider'

interface ILockoutFailedAttemptThresholdProps {}

const LockoutFailedAttemptThreshold: React.FC<
  ILockoutFailedAttemptThresholdProps
> = props => {
  const { storeLockoutPolicy, storeRbac } = useStores()

  const { storeFormLockoutPolicyConfiguration } = storeLockoutPolicy

  const failedAttemptThreshold =
    storeFormLockoutPolicyConfiguration.getFieldValueAsNumber(
      LockoutPolicyFormFieldName.failedAttemptThreshold
    )

  const handleFailedAttemptsThresholdOnChangeFn =
    handleLockoutPolicyFieldsOnChange(storeFormLockoutPolicyConfiguration)(
      LockoutPolicyFormFieldName.failedAttemptThreshold
    )

  const canEditLockoutPolicyValue =
    storeRbac.isUserGrantedTo(canEditLockoutPolicy)

  const Slider = (
    <StyledSlider
      min={LOCKOUT_POLICY_FAILED_ATTEMPTS_COUNTS_MIN}
      max={LOCKOUT_POLICY_FAILED_ATTEMPTS_COUNTS_MAX}
      step={LOCKOUT_POLICY_FAILED_ATTEMPTS_COUNTS_STEPS}
      value={failedAttemptThreshold}
      onChange={handleFailedAttemptsThresholdOnChangeFn}
      tooltip={{ open: false }}
      disabled={!canEditLockoutPolicyValue}
    />
  )

  const InputValue = (
    <StyledFormWrapperInput
      labelledBy="failedAttemptThresholdValue"
      inputProps={{
        onChange: e => {
          handleFailedAttemptsThresholdOnChangeFn(Number(e.target.value))
        },
        value: failedAttemptThreshold,
        disabled: !canEditLockoutPolicyValue
      }}
    />
  )

  return (
    <ContainerFlex
      name={LockoutFailedAttemptThreshold.name}
      alignItems="center"
      items={[Slider, InputValue]}
      fullWidth
      spaced
    />
  )
}

export default observer(LockoutFailedAttemptThreshold)
