import { ContainerHTMLContent } from '@app/components/Container'
import { IconVector } from '@app/components/Icon'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { flatMap } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from 'tenable-design-system-alt'
import ContainerStep from './ContainerStep'
import { handleSeeProcedureOnClick } from './handlers'

interface IConfigurationAttackTypeDomainsStep1Props {}

const MessageContainerFlex = styled(ContainerFlex)<{
  isIoAInstalledAndAutomatic: boolean
}>`
  padding: ${props =>
    props.isIoAInstalledAndAutomatic ? '5px 10px' : 'initial'};
  border-radius: ${props =>
    props.isIoAInstalledAndAutomatic
      ? consts.borderRadiusSmallRedesign
      : 'initial'};
  background-color: ${props =>
    props.isIoAInstalledAndAutomatic
      ? consts.BackgroundColorV2.lightGray
      : 'initial'};
`

function ConfigurationAttackTypeDomainsStep1(
  props: IConfigurationAttackTypeDomainsStep1Props
) {
  const {
    storeManagementAttackTypeConfiguration,
    storeManagementApplicationSettings
  } = useStores()

  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.IoA']
  })

  const hasSelectionChanged =
    storeManagementAttackTypeConfiguration.isConfigurationDirty &&
    !storeManagementAttackTypeConfiguration.hasSameSelectedEntries

  const entityInfrastructures = Array.from(
    storeManagementAttackTypeConfiguration.storeInfrastructures.infrastructures.values()
  )

  const directories = flatMap(
    entityInfrastructures.map(
      entityInfrastructure => entityInfrastructure.directories
    )
  )

  const isNoDirectoriesConfigured = directories.length === 0

  const isIoAProcedureInstalled = directories.every(
    directory => directory?.ioaProcedureInstalled === true
  )

  const isIoAAutomaticConfiguration =
    storeManagementApplicationSettings.applicationSettings
      ?.ioaAutomaticConfiguration || false

  const isIoAInstalledAndAutomatic =
    isIoAAutomaticConfiguration && isIoAProcedureInstalled

  return (
    <ContainerStep
      stepNumber={1}
      title={translate('Domains configuration')}
      tooltip={
        isNoDirectoriesConfigured
          ? translate('There is no domain configured yet')
          : undefined
      }
      control={
        <FormWrapperButton
          labelledBy="seeProcedure"
          variant={ButtonVariant.primary}
          size={ButtonSize.large}
          buttonProps={{
            onClick: handleSeeProcedureOnClick(
              storeManagementAttackTypeConfiguration.storeModalProcedure
            ),
            disabled: hasSelectionChanged || isNoDirectoriesConfigured
          }}
        >
          {translate('See procedure')}
        </FormWrapperButton>
      }
      disabled={isNoDirectoriesConfigured}
    >
      <MessageContainerFlex
        data-test="domainsConfigurationMessage"
        name="DomainsConfigurationMessage"
        isIoAInstalledAndAutomatic={isIoAInstalledAndAutomatic}
        flexGap="spacingL"
      >
        {isIoAInstalledAndAutomatic && <IconVector />}

        <Label variant={LabelVariant.p}>
          <ContainerHTMLContent>
            {translate(
              isIoAInstalledAndAutomatic
                ? 'Automatic updates are activated'
                : 'You must configure each of your domains to use Indicators of Attack (IoA)',
              {
                transformMarkdown: true
              }
            )}
          </ContainerHTMLContent>
        </Label>
      </MessageContainerFlex>
    </ContainerStep>
  )
}

export default observer(ConfigurationAttackTypeDomainsStep1)
