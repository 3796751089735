import type { PropertiesNullable } from '@@types/helpers'
import type { Maybe, VulnerabilityDetail } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityVulnerabilityDetail
  extends EntityBase
  implements PropertiesNullable<VulnerabilityDetail>
{
  detail: Maybe<string> = null

  constructor(data: Partial<VulnerabilityDetail>) {
    super()
    Object.assign(this, data)
  }
}
