import { IconSearch } from '@app/components/Icon'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleAdObjectOnSearch } from '../handlers'

interface IAttackPathSearchButtonProps {
  className?: string
  style?: React.CSSProperties
}

const AttackPathSearchButton: React.FC<
  IAttackPathSearchButtonProps
> = props => {
  const { storeAttackPath } = useStores()
  const { canValidate } = storeAttackPath

  const disableSubmitButton = !canValidate

  return (
    <FormWrapperButton
      className={props.className}
      style={props.style}
      labelledBy="searchNode"
      variant={ButtonVariant.primary}
      size={ButtonSize.small}
      icon={IconSearch}
      iconSize={20}
      buttonProps={{
        onClick: handleAdObjectOnSearch(storeAttackPath),
        disabled: disableSubmitButton
      }}
    />
  )
}

export default observer(AttackPathSearchButton)
