import { Features } from '@alsid/common'
import MenuLink from '@app/components/MenuLink'
import MenuTitle from '@app/components/MenuTitle'
import { useAppRouter } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import type StoreMenu from '@app/stores/helpers/StoreMenu'
import { MenuEntryType } from '@app/stores/helpers/StoreMenu/types'
import { BrandColorV2 } from '@app/styles/consts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useGlobalInventoryAppContext } from 'tenable-cyber-asset-management'
import { handleMenuItemOnClick } from './handlers'
import type { IMainMenuEntry } from './types'

interface IMenuEntryProps {
  entry: IMainMenuEntry
  gradientColor?: BrandColorV2
  storeMenu: StoreMenu<IMainMenuEntry>
}

const MenuEntry: React.FC<IMenuEntryProps> = props => {
  const featureKapteynLeftNavigationUi = useFeatureFlag({
    featureFlagName: Features.KAPTEYN_LEFT_NAVIGATION_UI
  })
  const { globalInventoryPageConfigurator } = useGlobalInventoryAppContext()

  const appRouter = useAppRouter()

  const { entry } = props

  if (!entry.type) {
    return null
  }

  switch (entry.type) {
    case MenuEntryType.category:
      return (
        <MenuTitle
          key={entry.key}
          label={entry.label}
          expanded={props.storeMenu.expanded}
          variant={featureKapteynLeftNavigationUi ? 'current' : 'legacy'}
        />
      )

    case MenuEntryType.link:
    case MenuEntryType.externalLink: {
      const isSelected = props.storeMenu.selectedMenuKey === entry.key

      return (
        <MenuLink
          key={entry.key}
          to={
            entry.routeDefinition
              ? appRouter.makeRouteInfosPathname(entry.routeDefinition)
              : ''
          }
          onClick={() =>
            handleMenuItemOnClick(props.storeMenu.storeRoot)(
              entry,
              globalInventoryPageConfigurator
            )
          }
          active={isSelected}
          icon={entry.icon}
          icon2={entry.icon2}
          conditioningIcon2Component={entry.conditioningIcon2Component}
          label={entry.label}
          label2={entry.label2}
          labelledBy={entry.labelledBy}
          expanded={props.storeMenu.expanded}
          gradientColor={props.gradientColor || BrandColorV2.blue1}
          variant={featureKapteynLeftNavigationUi ? 'current' : 'legacy'}
          externalHref={entry.href}
        />
      )
    }
    case MenuEntryType.footer:
      return null
  }
}

export default observer(MenuEntry)
