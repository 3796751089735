import type { PropertiesNullable } from '@@types/helpers'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import type {
  ProviderType,
  ScanInfo,
  Tenant,
  TenantSetting
} from '@libs/openapi/service-identity-core'
import type { Maybe } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'
import type { IEntityListable, IEntityOmitKeysParameter } from './types'

export interface IDataRowTenantSetting {
  id: string
  name: string
  credentialsId: string
  lastScanInfo?: ScanInfo
  lastSucceededScanAt?: string
  providerType: ProviderType
  enabled: boolean
}

export default class EntityTenantSetting
  extends EntityBase
  implements
    PropertiesNullable<TenantSetting>,
    IEntityListable<IDataRowTenantSetting>
{
  uuid: Maybe<string> = null
  name: Maybe<string> = null
  credentialsId: Maybe<string> = null
  containerId: Maybe<string> = null
  enabled: Maybe<boolean> = null
  providerType: Maybe<ProviderType> = null
  lastScanInfo: Maybe<ScanInfo> = null
  lastSucceededScanAt: Maybe<Date> = null

  constructor(data: Partial<Tenant>) {
    super()
    Object.assign(this, data)
  }

  /* Implements IEntityListable */

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowTenantSetting> = []
  ): Array<IWidgetListColumns<IDataRowTenantSetting>> {
    const columns: Array<IWidgetListColumns<IDataRowTenantSetting>> = [
      {
        label: 'UUID',
        key: 'id'
      },
      {
        label: 'Name',
        key: 'name'
      },
      {
        label: 'Provider Type',
        key: 'providerType'
      },
      {
        label: 'Scan status',
        key: 'lastScanInfo'
      },
      {
        label: 'Last successful scan',
        key: 'lastSucceededScanAt'
      },
      {
        label: 'Enabled',
        key: 'enabled'
      }
    ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  asDataRow(): IDataRowTenantSetting {
    return {
      id: this.getPropertyAsString('uuid'),
      name: this.getPropertyAsString('name'),
      credentialsId: this.getPropertyAsString('credentialsId'),
      providerType: this.getPropertyAsT<ProviderType>('providerType'),
      lastScanInfo: this.getPropertyAsT<ScanInfo>('lastScanInfo'),
      lastSucceededScanAt: this.getPropertyAsString('lastSucceededScanAt'),
      enabled: this.getPropertyAsBoolean('enabled')
    }
  }
}
