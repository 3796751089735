import { ButtonSize } from '@app/components-legacy/Form/Wrappers/types'
import { useAppTranslator, useLogger } from '@app/hooks'
import type { ButtonProps } from 'antd/lib/button'
import * as React from 'react'
import { FormWrapperButton } from '..'
import { FormWrapperContext } from './Form/FormWrapperContext'

interface IFormWrapperSubmitProps extends ButtonProps {}

const FormWrapperButtonSubmit: React.FC<IFormWrapperSubmitProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Buttons'] })

  const formWrapperContext = React.useContext(FormWrapperContext)

  const logger = useLogger()

  if (!formWrapperContext) {
    logger.error(
      'FormWrapperButtonSubmit must be used inside a <FormWrapper> component.'
    )
    return null
  }

  const buttonProps: ButtonProps = {
    type: 'primary',
    htmlType: 'submit',
    ...props
  }

  return (
    <FormWrapperButton
      labelledBy="submit"
      size={ButtonSize.large}
      buttonProps={{
        ...buttonProps,
        form: formWrapperContext.uid || 'form-uid-not-found'
      }}
    >
      {props.children || translate('OK')}
    </FormWrapperButton>
  )
}

export default FormWrapperButtonSubmit
