import type { Maybe } from '@@types/helpers'
import { createEntities, EntityPagination } from '@app/entities'
import type { IDataRowAttack } from '@app/entities/EntityAttack'
import EntityAttack from '@app/entities/EntityAttack'
import type { StoreRoot } from '@app/stores'
import StoreDatePicker from '@app/stores/helpers/StoreDatePicker'
import StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { ICheckerAttack } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { ensureArray } from '@libs/ensureArray'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { haveReflectedPromisesFailed } from '@libs/promiseReflect'
import { checkRbac } from '@libs/rbac/functions'
import type {
  MutationCloseAttacks,
  MutationCloseAttacksBulk,
  MutationRemoveAttacks,
  MutationUnremoveAllAttacks,
  MutationUnremoveAttacks
} from '@server/graphql/mutations/attack'
import {
  mutationCloseAttacks,
  mutationCloseAttacksBulk,
  mutationRemoveAttacks,
  mutationUnremoveAllAttacks,
  mutationUnremoveAttacks
} from '@server/graphql/mutations/attack'
import type {
  QueryRbacAttacks,
  QueryRbacAttacksCount
} from '@server/graphql/queries/ioa'
import {
  queryRbacAttacks,
  queryRbacAttacksCount
} from '@server/graphql/queries/ioa'
import type {
  Attack,
  AttacksCount,
  CloseAttacksBulkMutationArgs,
  CloseAttacksMutationArgs,
  RbacAttacksQueryArgs,
  RemoveAttacksMutationArgs,
  UnremoveAllAttacksMutationArgs,
  UnremoveAttacksMutationArgs
} from '@server/graphql/typeDefs/types'
import {
  AttackResourceType,
  CheckerType,
  OrderBy
} from '@server/graphql/typeDefs/types'
import type {
  IWSAttackMessage,
  IWSRegistration
} from '@server/routers/WSProxyRouter/types'
import { WSEntityName } from '@server/routers/WSProxyRouter/types'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import * as moment from 'moment'
import StoreDrawer from '../../helpers/StoreDrawer'
import StoreFlags from '../../helpers/StoreFlags'
import StoreModal from '../../helpers/StoreModal'
import StoreWidgetList from '../../helpers/StoreWidgetList'
import { ATTACKS_LIMIT, ATTACKS_MAX_PAGES } from '../consts'
import type { SetAttacksDirection } from '../types'
import StoreAttackRemoveFilters from './StoreAttackRemoveFilters'
import type { AttacksQueryStringParameters } from './types'

export default class StoreAttacks extends StoreBase {
  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['IoA.Attacks', 'IoA.Attacks.DrawerRemoveFilters']
  })

  // keep a reference to the "parent" storeIoA
  public storeIoA = this.storeRoot.stores.storeIoA

  /* Flags */

  public storeFlagsFetchAttacks = new StoreFlags(this.storeRoot)
  public storeFlagsFetchAttacksCount = new StoreFlags(this.storeRoot)
  public storeFlagsReloadAttacks = new StoreFlags(this.storeRoot)
  public storeFlagsFetchNewerAttacks = new StoreFlags(this.storeRoot)
  public storeFlagsFetchOlderAttacks = new StoreFlags(this.storeRoot)

  public storeFlagsCloseAttacks = new StoreFlags(this.storeRoot)
  public storeFlagsRemoveAttacks = new StoreFlags(this.storeRoot)
  public storeFlagsUnremoveAttacks = new StoreFlags(this.storeRoot)
  public storeFlagsUnremoveAllAttacks = new StoreFlags(this.storeRoot)

  /* Modals */

  public storeModalConfirmCloseAction = new StoreModal(this.storeRoot)

  /* DatePickers */

  public storeDatePicker = new StoreDatePicker(this.storeRoot, {
    defaultDateStart: null,
    defaultDateEnd: null
  })

  /* AttackTypes picker */

  public storeInputCheckersAttacks =
    new StoreInputGenericCheckers<ICheckerAttack>(this.storeRoot, {
      checkerType: CheckerType.Attack,
      selectable: true
    })

  /* InputSearch */

  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  /* Drawers */

  // Drawer to see long values of attacks properties
  public storeDrawerAttackDetails = new StoreDrawer<{
    attackVectorDescription: string
  }>(this.storeRoot)

  // Attacks filters drawer that allows to add/remove attacks filters
  public storeDrawerAttacksFiltersAdd = new StoreDrawer(this.storeRoot)

  // Drawer to export attacks
  public storeDrawerExportAttacks = new StoreDrawer(this.storeRoot)

  /* Lists */

  // List of attacks
  public storeWidgetListAttacks = new StoreWidgetList<
    EntityAttack,
    IDataRowAttack
  >(this.storeRoot)

  /* Other stores */

  // Store in charge to handle the attacks remove-filters.
  public storeAttackRemoveFilters = new StoreAttackRemoveFilters(this.storeRoot)

  /* Private */

  private _attackWSRegistration: Maybe<
    IWSRegistration<IWSAttackMessage['payload']>
  > = null

  /* Observables */

  private $queryStringParameters = observable.box<AttacksQueryStringParameters>(
    {}
  )
  private $filterIsClosed = observable.box(false)
  private $isLive = observable.box<boolean>(false)
  private $hasOlderResults = observable.box<boolean>(true)
  private $attacksCount = observable.box<Maybe<number>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch attacks.
   */
  fetchAttacks(
    _args: RbacAttacksQueryArgs,
    storeFlags = this.storeFlagsFetchAttacks
  ): Promise<void> {
    storeFlags.loading()

    const args: RbacAttacksQueryArgs = {
      ..._args,
      attacksFilters: {
        ..._args.attacksFilters,
        profileId: this.storeRoot.stores.storeAuthentication.currentProfileId
      }
    }

    return Promise.resolve()
      .then(() => {
        return Promise.all([
          this.storeRoot
            .getGQLRequestor()
            .query<QueryRbacAttacks>(queryRbacAttacks, args),
          this.storeRoot
            .getGQLRequestor()
            .query<QueryRbacAttacksCount>(queryRbacAttacksCount, args)
        ])
      })
      .then(([{ rbacAttacks }, { rbacAttacksCount }]) => {
        if (
          !checkRbac(this.storeRoot, storeFlags)(rbacAttacks) ||
          !checkRbac(this.storeRoot, storeFlags)(rbacAttacksCount)
        ) {
          throw new ForbiddenAccessError()
        }

        this.setAttacks(rbacAttacks.node)
        this.setAttacksCount(rbacAttacksCount.node)
        this.setHasOlderResults(rbacAttacks.node.length === ATTACKS_LIMIT)

        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Fetch attacks.
   */
  fetchAttacksCount(
    _args: RbacAttacksQueryArgs,
    storeFlags = this.storeFlagsFetchAttacksCount
  ): Promise<void> {
    storeFlags.loading()

    const args: RbacAttacksQueryArgs = {
      ..._args,
      attacksFilters: {
        ..._args.attacksFilters,
        profileId: this.storeRoot.stores.storeAuthentication.currentProfileId
      }
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryRbacAttacksCount>(queryRbacAttacksCount, args)
      })
      .then(({ rbacAttacksCount }) => {
        if (!checkRbac(this.storeRoot, storeFlags)(rbacAttacksCount)) {
          throw new ForbiddenAccessError()
        }

        this.$attacksCount.set(rbacAttacksCount.node.count)

        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Reload the attacks.
   */
  reloadAttacks(): Promise<void> {
    const args = this.computedArgsFromFilters

    if (!args) {
      return Promise.resolve()
    }

    return this.fetchAttacks(args, this.storeFlagsReloadAttacks).then(() => {
      this.storeWidgetListAttacks.unselectAllRows()
    })
  }

  /**
   * Fetch newer attacks.
   */
  fetchNewerAttacks(): Promise<void> {
    const args = this.computedArgsFromFilters

    if (!args) {
      return Promise.resolve()
    }

    this.storeFlagsFetchNewerAttacks.loading()

    // Set the start date filter to the first fetched incident date to fetch the newer incidents
    // We add a small increment to the date to not have the first incident in the new request because the filter is inclusive
    const dateStart = moment(this.attacks[0].date).add(1, 'ms').toISOString()

    const queryArgs: RbacAttacksQueryArgs = {
      ...args,
      attacksFilters: {
        ...args.attacksFilters,
        dateStart,
        profileId: this.storeRoot.stores.storeAuthentication.currentProfileId
      }
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryRbacAttacks>(queryRbacAttacks, queryArgs)
      })
      .then(({ rbacAttacks }) => {
        if (
          !checkRbac(
            this.storeRoot,
            this.storeFlagsFetchNewerAttacks
          )(rbacAttacks)
        ) {
          throw new ForbiddenAccessError()
        }

        this.pauseLiveAttacks()
        this.setNewerAttacks(rbacAttacks.node)

        this.storeFlagsFetchNewerAttacks.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsFetchNewerAttacks))
  }

  /**
   * Fetch older attacks.
   */
  fetchOlderAttacks(): Promise<void> {
    const args = this.computedArgsFromFilters

    if (!args) {
      return Promise.resolve()
    }

    this.storeFlagsFetchOlderAttacks.loading()

    // Set the end date filter to the last fetched incident date to fetch the older incidents
    // We add a small increment to the date to not have the last incident in the new request because the filter is inclusive
    const dateEnd = moment(this.attacks[this.attacks.length - 1].date)
      .subtract(1, 'ms')
      .toISOString()

    const queryArgs: RbacAttacksQueryArgs = {
      ...args,
      attacksFilters: {
        ...args.attacksFilters,
        dateEnd,
        profileId: this.storeRoot.stores.storeAuthentication.currentProfileId
      }
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryRbacAttacks>(queryRbacAttacks, queryArgs)
      })
      .then(({ rbacAttacks }) => {
        if (
          !checkRbac(
            this.storeRoot,
            this.storeFlagsFetchOlderAttacks
          )(rbacAttacks)
        ) {
          throw new ForbiddenAccessError()
        }

        this.pauseLiveAttacks()
        this.setOlderAttacks(rbacAttacks.node)

        this.setHasOlderResults(rbacAttacks.node.length === ATTACKS_LIMIT)

        this.storeFlagsFetchOlderAttacks.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsFetchOlderAttacks))
  }

  /**
   * Add an attack from a payload received via websocket.
   * New attacks are added to the map in the natural order, thus displayed first.
   */
  addLiveAttack(payload: IWSAttackMessage['payload']): void {
    if (!payload) {
      return
    }

    switch (payload.action) {
      case 'add':
        this.addNewLiveAttack(payload.attack)
        break

      case 'update':
        this.updateLiveAttack(payload.attack)
        break
    }
  }

  /**
   * Close or reopen some attacks.
   */
  closeOrReopenAttacks(
    _args: CloseAttacksMutationArgs,
    parameters: { isClosed: boolean }
  ): Promise<void> {
    this.storeFlagsCloseAttacks.loading()

    const args: CloseAttacksMutationArgs = {
      ..._args,
      profileId: this.storeRoot.stores.storeAuthentication.currentProfileId
    }

    return this.storeRoot
      .getGQLRequestor()
      .query<MutationCloseAttacks>(mutationCloseAttacks, args)
      .then(results => {
        if (haveReflectedPromisesFailed(results.closeAttacks)) {
          this.storeRoot.stores.storeMessages.warning(
            this.translate(
              parameters.isClosed
                ? 'Some incidents have not been closed due to some errors'
                : 'Some incidents have not been reopened due to some errors'
            ),
            {
              labelledBy: parameters.isClosed
                ? 'attacksNotClosed'
                : 'attacksNotReopen'
            }
          )

          this.storeFlagsCloseAttacks.fail()

          return
        }

        this.storeRoot.stores.storeMessages.success(
          this.translate(
            parameters.isClosed
              ? 'The selected incidents have been closed'
              : 'The selected incidents have been reopened'
          ),
          {
            labelledBy: parameters.isClosed ? 'attacksClosed' : 'attacksReopen'
          }
        )

        this.storeFlagsCloseAttacks.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsCloseAttacks))
  }

  /**
   * Close or reopen all attacks in bulk.
   */
  closeOrReopenAttacksBulk(
    _args: CloseAttacksBulkMutationArgs,
    parameters: { isClosed: boolean }
  ): Promise<void> {
    this.storeFlagsCloseAttacks.loading()

    const args: CloseAttacksBulkMutationArgs = {
      ..._args,
      profileId: this.storeRoot.stores.storeAuthentication.currentProfileId
    }

    return this.storeRoot
      .getGQLRequestor()
      .query<MutationCloseAttacksBulk>(mutationCloseAttacksBulk, args)
      .then(results => {
        if (!results.closeAttacksBulk) {
          this.storeRoot.stores.storeMessages.warning(
            this.translate(
              parameters.isClosed
                ? 'Some incidents have not been closed due to some errors'
                : 'Some incidents have not been reopened due to some errors'
            ),
            {
              labelledBy: parameters.isClosed
                ? 'attacksNotClosed'
                : 'attacksNotReopen'
            }
          )

          this.storeFlagsCloseAttacks.fail()

          return
        }

        this.storeRoot.stores.storeMessages.success(
          this.translate(
            parameters.isClosed
              ? 'The selected incidents have been closed'
              : 'The selected incidents have been reopened'
          ),
          {
            labelledBy: parameters.isClosed ? 'attacksClosed' : 'attacksReopen'
          }
        )

        this.storeFlagsCloseAttacks.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsCloseAttacks))
  }

  /**
   * Remove some attacks by adding some attack remove-filters.
   */
  removeAttacks(_args: RemoveAttacksMutationArgs): Promise<void> {
    this.storeFlagsRemoveAttacks.loading()

    const args: RemoveAttacksMutationArgs = {
      ..._args,
      profileId: this.storeRoot.stores.storeAuthentication.currentProfileId
    }

    return this.storeRoot
      .getGQLRequestor()
      .query<MutationRemoveAttacks>(mutationRemoveAttacks, args)
      .then(results => {
        if (!results.removeAttacks) {
          this.storeRoot.stores.storeMessages.warning(
            this.translate(
              'Some incidents have not been removed due to some errors'
            ),
            {
              labelledBy: 'attacksNotRemoved'
            }
          )

          this.storeFlagsRemoveAttacks.fail()

          return
        }

        this.storeRoot.stores.storeMessages.success(
          this.translate('The selected incidents have been removed'),
          { labelledBy: 'attacksRemoved' }
        )

        this.storeFlagsRemoveAttacks.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsRemoveAttacks))
  }

  /**
   * Unremove some attacks by removing some attack remove-filters.
   */
  unremoveAttacks(_args: UnremoveAttacksMutationArgs): Promise<void> {
    this.storeFlagsUnremoveAttacks.loading()

    const args: UnremoveAttacksMutationArgs = {
      ..._args,
      profileId: this.storeRoot.stores.storeAuthentication.currentProfileId
    }

    return this.storeRoot
      .getGQLRequestor()
      .query<MutationUnremoveAttacks>(mutationUnremoveAttacks, args)
      .then(results => {
        if (!results.unremoveAttacks) {
          this.storeRoot.stores.storeMessages.genericError()
          this.storeFlagsUnremoveAttacks.fail()
          return
        }
        this.storeRoot.stores.storeMessages.success(
          this.translate('The incident deletion filter has been removed'),
          {
            labelledBy: 'attackDeletionFilterRemoved'
          }
        )
        this.storeFlagsUnremoveAttacks.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsUnremoveAttacks))
  }

  /**
   * Unremove all attacks by removing all attack remove-filters.
   */
  unremoveAllAttacks(_args: UnremoveAllAttacksMutationArgs): Promise<void> {
    this.storeFlagsUnremoveAllAttacks.loading()

    const args: UnremoveAllAttacksMutationArgs = {
      ..._args,
      profileId: this.storeRoot.stores.storeAuthentication.currentProfileId
    }

    return this.storeRoot
      .getGQLRequestor()
      .query<MutationUnremoveAllAttacks>(mutationUnremoveAllAttacks, args)
      .then(results => {
        if (!results.unremoveAllAttacks) {
          this.storeRoot.stores.storeMessages.genericError()
          this.storeFlagsUnremoveAllAttacks.fail()
          return
        }

        this.storeRoot.stores.storeMessages.success(
          this.translate('All the incident deletion filters have been removed'),
          {
            labelledBy: 'allAttackDeletionFiltersRemoved'
          }
        )

        this.storeFlagsUnremoveAllAttacks.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsUnremoveAllAttacks)
      )
  }

  /**
   * Extends attack with attackTypes informations.
   * Return attack entities.
   */
  private _extendsAttacks(attacks: Attack[]): EntityAttack[] {
    const attackEntities = createEntities<Attack, EntityAttack>(
      EntityAttack,
      attacks
    )

    attackEntities.forEach(attackEntity => {
      attackEntity.extendsWithAttackType(this.storeIoA.attackTypesAsArray)
    })

    return attackEntities
  }

  /* Actions */

  /**
   * Reset stores.
   */
  @action
  reset(): this {
    this.storeFlagsFetchAttacks.reset()

    this.storeWidgetListAttacks.reset()

    return this
  }

  /**
   * Pause the live mode.
   */
  @action
  pauseLiveAttacks(): this {
    if (this.isLive) {
      this.storeRoot.stores.storeMessages.info(
        this.translate('The real-time incidents detection is paused'),
        {
          labelledBy: 'attacksDetectionPaused'
        }
      )
    }

    // this.$isLive.set(false)

    // this.unregisterAttackWS()

    return this
  }

  /**
   * Restart the live mode.
   */
  @action
  restartLiveAttacks(): this {
    this.storeRoot.stores.storeMessages.info(
      this.translate('The real-time incidents detection is restarting'),
      {
        labelledBy: 'attacksDetectionStarting'
      }
    )

    // this.storeRoot.stores.storeMessages.info(
    //   this.translate('The real-time incidents detection is restarting')
    // )

    // this.$isLive.set(true)

    // this.registerAttacksWS()

    return this
  }

  /**
   * Set the query string parameters.
   */
  @action
  setQueryStringParameters(
    queryStringParameters: AttacksQueryStringParameters
  ): this {
    this.$queryStringParameters.set(queryStringParameters)
    return this
  }

  /**
   * Save attack entities.
   */
  @action
  setAttacks(attacks: Attack[]): this {
    const attackEntities = this._extendsAttacks(attacks)

    this._pushOrUnshiftAttacksIntoWidgetList(attackEntities, {
      appendDirection: 'newer',
      clearAttacks: true
    })

    return this
  }

  /**
   * Save attack entities.
   */
  @action
  setAttacksCount(attacksCount: AttacksCount): this {
    this.$attacksCount.set(attacksCount.count)

    this.storeWidgetListAttacks.setPagination(
      new EntityPagination({
        totalCount: attacksCount.count
      })
    )

    return this
  }

  /**
   * Add a new attacks to the list received via WS.
   */
  @action
  addNewLiveAttack(attack: Attack): this {
    const attackEntities = this._extendsAttacks([attack])

    this._pushOrUnshiftAttacksIntoWidgetList(attackEntities, {
      appendDirection: 'newer',
      clearAttacks: false
    })

    return this
  }

  /**
   * Update an attack of the list with a new one received via WS.
   */
  @action
  updateLiveAttack(attack: Attack): this {
    const attackEntities = this._extendsAttacks([attack])

    attackEntities.forEach(attackEntity => {
      this.storeWidgetListAttacks.updateOneEntity(
        attackEntity,
        _attack => _attack.id === attack.id
      )
    })

    return this
  }

  /**
   * Save newer attack entities.
   */
  @action
  setNewerAttacks(attacks: Attack[]): this {
    const attackEntities = this._extendsAttacks(attacks)

    this._pushOrUnshiftAttacksIntoWidgetList(attackEntities, {
      appendDirection: 'newer',
      clearAttacks: false
    })

    return this
  }

  /**
   * Save older attack entities.
   */
  @action
  setOlderAttacks(attacks: Attack[]): this {
    const attackEntities = this._extendsAttacks(attacks)

    this._pushOrUnshiftAttacksIntoWidgetList(attackEntities, {
      appendDirection: 'older',
      clearAttacks: false
    })

    return this
  }

  /**
   * Set has older results
   */
  @action
  setHasOlderResults(hasMore: boolean): this {
    this.$hasOlderResults.set(hasMore)
    return this
  }

  /**
   * Subscribe to attacks.
   */
  @action
  registerAttacksWS(): void {
    // don't register several times
    if (this._attackWSRegistration) {
      return
    }

    this._attackWSRegistration = this.storeRoot.wsClient.addRegistration<
      IWSAttackMessage['payload']
    >(
      'Attack',
      {
        name: WSEntityName.attack,
        payload: {
          language: this.storeRoot.appTranslator.language
        }
      },
      this.addLiveAttack.bind(this)
    )
  }

  /**
   * Unsubscribe attacks.
   */
  @action
  unregisterAttackWS(): void {
    if (this._attackWSRegistration) {
      this.storeRoot.wsClient.removeRegistration(this._attackWSRegistration)
      this._attackWSRegistration = null
    }
  }

  /**
   * Save the state of the "see closed incidents" switch.
   */
  @action
  setFilterIsClosed(isClosed: boolean): this {
    this.$filterIsClosed.set(isClosed)
    return this
  }

  /**
   * Save the filters of the querystring in the store and initialize stores.
   */
  @action
  initFiltersStoresFromQueryStringOrPreviousBladeState(
    _attacksQueryParams?: AttacksQueryStringParameters
  ): this {
    if (_attacksQueryParams) {
      this.setQueryStringParameters(_attacksQueryParams)
    }

    const attacksQueryParams = this.queryStringParameters

    if (!attacksQueryParams) {
      return this
    }

    /** Init storeInputSearch */

    this.storeInputSearch.setSearchValue(attacksQueryParams.search || '')

    /** Init storeDatePicker */

    const datesRange = {
      dateStart: attacksQueryParams.dateStart
        ? moment(attacksQueryParams.dateStart)
        : null,
      dateEnd: attacksQueryParams.dateEnd
        ? moment(attacksQueryParams.dateEnd)
        : null
    }

    this.storeDatePicker.setDatePickerRangeValue([
      datesRange.dateStart,
      datesRange.dateEnd
    ])

    /** Init storeInputCheckersAttacks */

    const attackTypeIds = ensureArray(attacksQueryParams.attackTypeIds).map(
      id => Number(id)
    )

    // select attackTypes
    !attackTypeIds.length &&
    !this.storeIoA.storeBoard.storeInputCheckersAttacks.selectedCheckerIds
      .length
      ? this.storeInputCheckersAttacks.selectAllCheckers()
      : this.storeInputCheckersAttacks.selectCheckersFromIds(
          attackTypeIds.length
            ? attackTypeIds
            : this.storeIoA.storeBoard.storeInputCheckersAttacks
                .selectedCheckerIds
        )

    /** Init include closed switch */

    this.$filterIsClosed.set(attacksQueryParams.includeClosed === 'true')

    return this
  }

  /**
   * Push or unshift attacks into the attacks observable and splice the array to
   * `limit` attacks.
   *
   * Return the removed attacks.
   */
  @action
  private _pushOrUnshiftAttacksIntoWidgetList(
    newAttacks: EntityAttack[],
    options: {
      appendDirection: SetAttacksDirection
      clearAttacks: boolean
    }
  ): EntityAttack[] {
    if (options.clearAttacks) {
      this.storeWidgetListAttacks.resetEntities()
    }

    const attacks = this.storeWidgetListAttacks.entitiesAsArray

    const nbPages = this.isLive ? 1 : ATTACKS_MAX_PAGES
    const nbAttacksMax = ATTACKS_LIMIT * nbPages

    // if direction => newer, add attacks at the beginning of the array of attacks
    // and limit the number of attacks to `limit`.
    if (options.appendDirection === 'newer') {
      attacks.unshift(...newAttacks)

      // TODO: Improve the memory saving behaviour, it's not usable because the scroll position doesn't change when
      // we remove and add the same amount of attacks in the same time. We could consider a "display:none" with
      // a fixed parent height strategy instead of deleting the attacks.
      // const removerOlderAttacks = attacks.splice(Math.min(attacks.length, nbAttacksMax))

      this.storeWidgetListAttacks.setEntities(attacks)

      return [] // removerOlderAttacks
    }

    // if direction => older, add attacks at the end of the array of attacks
    // and limit the number of attacks to `limit`.
    attacks.push(...newAttacks)

    /*
    const removedNewerAttacks = attacks.splice(
      0,
      Math.max(0, attacks.length - nbAttacksMax)
    )
    */

    this.storeWidgetListAttacks.setEntities(attacks)

    return [] // removedNewerAttacks
  }

  /* Computed */

  @computed
  get filterIsClosed(): boolean {
    return this.$filterIsClosed.get()
  }

  /**
   * Return the querystring parameters.
   */
  @computed
  get queryStringParameters(): AttacksQueryStringParameters {
    const queryStringParameters = toJS(this.$queryStringParameters.get())

    return {
      ...queryStringParameters,
      attackTypeIds: queryStringParameters.attackTypeIds
    }
  }

  /**
   * Return true if the table is live
   * (and if the websocket connection is alive).
   */
  @computed
  get isLive(): boolean {
    return Boolean(
      this.storeRoot.stores.storeWSIndicator.isConnected &&
        this.$isLive.get() === true
    )
  }

  /**
   * Return true if we do not have fetched all the attacks yet
   */
  @computed
  get hasOlderResults(): boolean {
    return this.$hasOlderResults.get()
  }

  /**
   * Return attacks as an array for the list.
   */
  @computed
  get attacks(): EntityAttack[] {
    return this.storeWidgetListAttacks.allEntitiesAsArray
  }

  /**
   * Return the querystring filters according to the current state of local
   * stores.
   */
  @computed
  get computedQueryStringFilters(): Maybe<AttacksQueryStringParameters> {
    const queryStringFilters = this.queryStringParameters

    if (!queryStringFilters) {
      return null
    }

    const datesRange = this.storeDatePicker.datePickerRangeValueAsIsoStrings

    return {
      resourceType: queryStringFilters.resourceType,
      resourceValue: queryStringFilters.resourceValue,
      search: this.storeInputSearch.searchValue,
      includeClosed: this.$filterIsClosed.get() ? 'true' : 'false',
      dateStart: datesRange?.dateStart,
      dateEnd: datesRange?.dateEnd,
      attackTypeIds: this.storeInputCheckersAttacks.selectedCheckerIds.map(id =>
        String(id)
      )
    }
  }

  /**
   * Return args for the GraphQL query in order to reload the list of attacks
   * according to parameters retrieved from the querystring.
   */
  @computed
  get computedArgsFromFilters(): Maybe<RbacAttacksQueryArgs> {
    const queryStringFilters = this.queryStringParameters

    if (!queryStringFilters.resourceType || !queryStringFilters.resourceValue) {
      return null
    }

    const dateRange = this.storeDatePicker?.datePickerRangeValueAsIsoStrings

    return {
      attacksFilters: {
        profileId: this.storeRoot.stores.storeAuthentication.currentProfileId,
        dateStart: dateRange?.dateStart,
        dateEnd: dateRange?.dateEnd,
        resourceType: queryStringFilters?.resourceType,
        resourceValue: queryStringFilters?.resourceValue,
        includeClosed: this.filterIsClosed,
        attackTypeIds: this.storeInputCheckersAttacks.selectedCheckerIds,
        search: this.storeInputSearch.searchValue
      },
      limit: ATTACKS_LIMIT,
      orderBy: OrderBy.Desc
    }
  }

  /**
   * Return the resource type of the current list of attacks from the
   * querystring parameters.
   */
  @computed
  get resourceTypeTranslation(): string {
    const { resourceType } = this.queryStringParameters

    if (!resourceType) {
      return '-'
    }

    switch (resourceType) {
      case AttackResourceType.Infrastructure:
        return this.translate('Incidents related to the forest')

      case AttackResourceType.Directory:
        return this.translate('Incidents related to the domain')

      case AttackResourceType.Hostname:
        return this.translate('Incidents related to the hostname')

      case AttackResourceType.Ip:
        return this.translate('Incidents related to the IP')
    }
  }

  /**
   * Return the resource value of the current list of attacks from the
   * querystring parameters.
   */
  @computed
  get resourceValueTranslation(): string {
    if (
      !this.queryStringParameters.resourceValue ||
      !this.queryStringParameters.resourceType
    ) {
      return '-'
    }

    const { storeInfrastructures } = this.storeRoot.stores

    switch (this.queryStringParameters.resourceType) {
      case AttackResourceType.Infrastructure: {
        const infrastructure = storeInfrastructures.getInfrastructureFromId(
          Number(this.queryStringParameters.resourceValue)
        )
        return infrastructure?.getPropertyAsString('name') || '-'
      }
      case AttackResourceType.Directory: {
        const directory = storeInfrastructures.getDirectoryFromId(
          Number(this.queryStringParameters.resourceValue)
        )
        return directory?.getPropertyAsString('name') || '-'
      }
      case AttackResourceType.Hostname:
      case AttackResourceType.Ip:
        return this.queryStringParameters.resourceValue
    }
  }
}
