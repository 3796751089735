import type { IDashboardTemplateCreation } from '@app/pages/Dashboard/GridPage/DrawerAddDashboard/types'
import { DashboardTemplate } from '@app/pages/Dashboard/GridPage/DrawerAddDashboard/types'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreForm from '@app/stores/helpers/StoreForm'
import { mandatory, maxLength } from '@app/stores/helpers/StoreForm/validators'
import { createDashboardKey } from '@libs/dashboards/keys'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefinedAndNotEmptyString } from '@libs/isDefined'
import type {
  MutationCreateDashboard,
  MutationDeleteDashboard,
  MutationEditDashboard
} from '@server/graphql/mutations/dashboard'
import {
  mutationCreateDashboard,
  mutationDeleteDashboard,
  mutationEditDashboard
} from '@server/graphql/mutations/dashboard'
import type {
  CreateDashboardMutationArgs,
  DeleteDashboardMutationArgs,
  EditDashboardMutationArgs
} from '@server/graphql/typeDefs/types'
import StoreBase from '../StoreBase'
import type { IStoreMultiInstanceOptions } from '../types'
import { DASHBOARD_NAME_MAX_LENGTH } from './consts'
import type { IDashboardDrawerParameters } from './types'
import { UserFormFieldName } from './types'

export interface IStoreDashboardsManagementOptions
  extends IStoreMultiInstanceOptions {}

/**
 * Store in charge of the create/edition/delete of a dashboard.
 */
export default class StoreDashboardsManagement extends StoreBase<IStoreDashboardsManagementOptions> {
  public storeFormAddDashboard = new StoreForm<UserFormFieldName>(
    this.storeRoot,
    {
      setup: {
        fields: {
          [UserFormFieldName.name]: {
            label: 'Name',
            validators: [mandatory(), maxLength(DASHBOARD_NAME_MAX_LENGTH)()]
          },
          [UserFormFieldName.instanceName]: {
            label: 'Instance',
            validators: [
              mandatory({
                isValid: value => {
                  const instances = this.storeRoot.environment.config.instances
                  // if more than 2 instances, the input will not be displayed,
                  // so it is valid
                  if (instances.length < 2) {
                    return true
                  }

                  return isDefinedAndNotEmptyString(value)
                }
              })
            ]
          }
        }
      }
    }
  )

  public storeFlagsAddDashboard = new StoreFlags(this.storeRoot)
  public storeDrawerAddDashboard = new StoreDrawer(this.storeRoot)

  public storeFormEditDashboard = new StoreForm(this.storeRoot, {
    setup: {
      fields: {
        [UserFormFieldName.name]: {
          label: 'Name',
          validators: [mandatory(), maxLength(DASHBOARD_NAME_MAX_LENGTH)()]
        },
        [UserFormFieldName.instanceName]: {
          label: 'Instance',
          validators: [mandatory()]
        }
      }
    }
  })

  public storeFlagsEditDashboard = new StoreFlags(this.storeRoot)
  public storeDrawerEditDashboard = new StoreDrawer(this.storeRoot)

  public storeFlagsDeleteDashboard = new StoreFlags(this.storeRoot)
  public storeDrawerDeleteDashboard =
    new StoreDrawer<IDashboardDrawerParameters>(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Dashboard.Common']
  })

  public dashboardTemplates = new Map<DashboardTemplate, boolean>()

  /**
   * Create a new dashboard.
   */
  createDashboard(args: CreateDashboardMutationArgs): Promise<void> {
    this.storeFlagsAddDashboard.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor(args.dashboard.instanceName)
          .query<MutationCreateDashboard>(mutationCreateDashboard, args)
      })
      .then(data => data.createDashboard)
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Dashboard created'),
          {
            labelledBy: 'dashboardCreated'
          }
        )

        this.storeFlagsAddDashboard.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsAddDashboard, {
          forwardExceptionFn: () =>
            'An error has occurred when creating a new dashboard'
        })
      )
  }

  /**
   * Create a new dashboard with predefined widgets.
   */
  createDashboardWithPredefinedWidgets(
    args: CreateDashboardMutationArgs,
    template: DashboardTemplate
  ): Promise<void> {
    this.storeFlagsAddDashboard.loading()

    // if it's a custom dashboard then no predefined widgets are needed
    if (template === DashboardTemplate.customDashboard) {
      return Promise.resolve()
        .then(() => {
          return this.storeRoot
            .getGQLRequestor(args.dashboard.instanceName)
            .query<MutationCreateDashboard>(mutationCreateDashboard, args)
        })
        .then(data => data.createDashboard)
        .then(() => {
          this.storeRoot.stores.storeMessages.success(
            this.translate('Dashboard created'),
            {
              labelledBy: 'dashboardCreated'
            }
          )

          this.storeFlagsAddDashboard.success()
        })
        .catch(
          handleStoreError(this.storeRoot, this.storeFlagsAddDashboard, {
            forwardExceptionFn: () =>
              'An error has occurred when creating a new dashboard'
          })
        )
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor(args.dashboard.instanceName)
          .query<MutationCreateDashboard>(mutationCreateDashboard, args)
      })
      .then(data => data.createDashboard)
      .then(async data => {
        const { id: dashboardId } = data

        await this.storeRoot.stores.storeDashboards.fetchOneDashboard(
          args.dashboard.instanceName,
          dashboardId
        )

        return {
          instanceName: args.dashboard.instanceName,
          dashboardId: dashboardId
        }
      })
      .then(async data => {
        const dashboardKey = createDashboardKey(
          data.instanceName,
          data.dashboardId
        )

        this.storeRoot.stores.storeDashboards.selectDashboard(dashboardKey)

        const currentStoreDashboard =
          this.storeRoot.stores.storeDashboards.currentStoreDashboard

        if (!currentStoreDashboard) {
          return
        }

        await currentStoreDashboard.storeWidgetsManagement.createPredefinedWidgets(
          currentStoreDashboard.dashboardEntity.getPropertyAsNumber('id'),
          template,
          args.dashboard
        )

        return data
      })
      .then(data => {
        if (!data) {
          return
        }

        this.storeRoot.stores.storeDashboards.fetchOneDashboard(
          data.instanceName,
          data.dashboardId
        )
      })
      .then(data => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Dashboard created'),
          {
            labelledBy: 'dashboardCreated'
          }
        )

        this.storeFlagsAddDashboard.success()

        return data
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsAddDashboard, {
          forwardExceptionFn: () =>
            'An error has occurred when creating a new dashboard'
        })
      )
  }

  /**
   * Create new dashboards from predefined templates.
   */
  createDashboards(
    dashboardTemplates: IDashboardTemplateCreation[]
  ): Promise<void[]> {
    this.storeFlagsAddDashboard.loading()

    return Promise.all(
      dashboardTemplates.map(dashboard =>
        this.createDashboardWithPredefinedWidgets(
          dashboard.args,
          dashboard.template
        )
      )
    )
  }

  /**
   * Edit a dashboard.
   */
  editDashboard(args: EditDashboardMutationArgs) {
    this.storeFlagsEditDashboard.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor(args.dashboard.instanceName)
          .query<MutationEditDashboard>(mutationEditDashboard, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Dashboard updated'),
          {
            labelledBy: 'dashboardUpdated'
          }
        )

        this.storeFlagsEditDashboard.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsEditDashboard, {
          errorMessageTranslationFn: () => {
            return 'An error has occurred when editing the dashboard'
          }
        })
      )
  }

  /**
   * Delete a dashboard.
   */
  deleteDashboard(args: DeleteDashboardMutationArgs) {
    this.storeFlagsDeleteDashboard.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor(args.dashboard.instanceName)
          .query<MutationDeleteDashboard>(mutationDeleteDashboard, args)
      })
      .then(() => {
        const dashboardKey = createDashboardKey(
          args.dashboard.instanceName,
          args.dashboard.dashboardId
        )
        this.storeRoot.stores.storeDashboards.deleteDashboardStore(dashboardKey)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Dashboard deleted'),
          {
            labelledBy: 'dashboardDeleted'
          }
        )

        this.storeFlagsDeleteDashboard.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsDeleteDashboard, {
          errorMessageTranslationFn: () => {
            return 'An error has occurred when deleting the dashboard'
          }
        })
      )
  }

  /**
   * Init Dashboard Templates.
   */
  initDashboardTemplates() {
    this.dashboardTemplates.clear()

    this.dashboardTemplates.set(DashboardTemplate.adRisk360, false)
    this.dashboardTemplates.set(DashboardTemplate.customDashboard, false)
  }
}
