import type { IAttackPathCoords } from '@app/pages/AttackPath/types'

/**
 * Retuns the coords of a point along the line at a certain percentage
 */
export default function getPercentPositionAlongTheLine(
  point1: IAttackPathCoords,
  point2: IAttackPathCoords,
  percentage: number
) {
  return {
    x: point1.x * (1.0 - percentage) + point2.x * percentage,
    y: point1.y * (1.0 - percentage) + point2.y * percentage
  }
}
