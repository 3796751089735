import { ContainerHTMLContent } from '@app/components-legacy/Container'
import { formatIncriminatingAttributeDescription } from '@app/entities/EntityIncriminatingAttribute/helpers'
import { marked } from 'marked'
import * as React from 'react'
import ContainerHTMLContentExtendedDate from './ContainerHTMLContentExtendedDate'
import ContainerHTMLContentExtendedSddl from './ContainerHTMLContentExtendedSddl'

export interface IContainerHTMLContentExtendedProps {
  className?: string
  children: string
  padded?: boolean
  inline?: boolean
}

/**
 * ContainerHMTLContent able to handle extended display for some type of values.
 */
const ContainerHTMLContentExtended: React.FC<
  IContainerHTMLContentExtendedProps
> = props => {
  const formatText = (value: string, i: number) => (
    <ContainerHTMLContent
      className={props.className}
      key={i}
      inline={props.inline}
      padded={props.padded}
      labelledBy="htmlContent"
    >
      {marked.parse(value).replace(/<\/?p>/g, '')}
    </ContainerHTMLContent>
  )
  const formatDate = (value: string, i: number) => (
    <ContainerHTMLContentExtendedDate key={i} date={value} />
  )
  const formatSDDL = (value: string, i: number) => (
    <ContainerHTMLContentExtendedSddl key={i} value={value} />
  )

  const elements = formatIncriminatingAttributeDescription({
    description: props.children,
    formatText,
    formatDate,
    formatSDDL
  })

  return <>{elements}</>
}

export default ContainerHTMLContentExtended
