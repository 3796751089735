import {
  ContainerContent,
  ContainerFooter,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { FormWrapperSelect } from '@app/components-legacy/Form'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { onSelectChange } from '@app/stores/helpers/StoreForm/handlers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canSetDefaultLangage } from '../permissions'
import {
  onPreferencesInternationalizationLoad,
  onPreferencesInternationalizationSubmit
} from './handlers'

interface IPreferencesInternationalizationFormProps {}

const PreferencesInternationalizationForm: React.FC<
  IPreferencesInternationalizationFormProps
> = () => {
  const {
    storeRoot,
    storeRbac,
    storePreferences,
    storeApplicationConfiguration
  } = useStores()

  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Preferences.PersonalSettings.Navigation',
      'Preferences.PersonalSettings.Internationalization'
    ]
  })

  const languages = storeApplicationConfiguration.availableLanguages

  const fields: IField[] = [
    {
      name: 'language',
      label: translate('Language'),
      control: (
        <FormWrapperSelect
          labelledBy="language"
          selectProps={{
            value:
              storePreferences.storeFormI18n.getFieldValueAsString('language'),
            onChange: onSelectChange(storePreferences.storeFormI18n)('language')
          }}
          selectOptions={languages.map(language => {
            return {
              label: language.label,
              value: language.locale
            }
          })}
        />
      )
    }
  ]

  return (
    <FormWrapper
      name="internationalizationConfiguration"
      onSubmit={onPreferencesInternationalizationSubmit(
        storeRoot,
        storePreferences
      )}
    >
      <>
        <ContainerContent
          onLoad={onPreferencesInternationalizationLoad(storePreferences)}
          title={translate('Internationalization')}
        >
          <ContainerForm fields={fields} />
        </ContainerContent>

        <Portal name={PlaceHolderName.bladeFooter}>
          <ContainerFooter>
            <FormWrapperButtonSubmit
              disabled={!storeRbac.isUserGrantedTo(canSetDefaultLangage)}
            >
              {translate('Save')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </>
    </FormWrapper>
  )
}

export default observer(PreferencesInternationalizationForm)
