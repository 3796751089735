import type { EntitySearchBookmarkEntry } from '@app/entities'
import type StoreSearchBookmarks from '@app/stores/TrailFlow/StoreSearchBookmarks'
import { BookmarkFormFieldName } from '@app/stores/TrailFlow/StoreSearchBookmarks'
import type StoreTrailFlow from '@app/stores/TrailFlow/StoreTrailFlow'
import type {
  InputCreateSearchBookmarkEntry,
  InputEditSearchBookmarkEntry
} from '@server/graphql/typeDefs/types'

/**
 * Delete search bookmark entry.
 */
export const onSearchBookmarkEntryDelete =
  (storeSearchBookmarks: StoreSearchBookmarks) =>
  (searchBookmarkEntry: EntitySearchBookmarkEntry) =>
  (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // We need to stop propagation to prevent the history entry from being selected, we only want to delete it here.
    event.stopPropagation()

    if (!searchBookmarkEntry.id) {
      return
    }

    storeSearchBookmarks.deleteSearchBookmarksEntry(searchBookmarkEntry.id)
  }

/**
 * Handle popover visible changes.
 */
export const onBookmarkPopoverVisibleChange =
  (storeSearchBookmarks: StoreSearchBookmarks) =>
  (bookmark?: EntitySearchBookmarkEntry) =>
  (visible: boolean) => {
    if (!visible) {
      storeSearchBookmarks.storeFormCreateBookmark.hardReset()
    }

    if (bookmark && visible) {
      storeSearchBookmarks.storeFormCreateBookmark
        .setDefaultFieldsValues([
          {
            key: BookmarkFormFieldName.name,
            value: bookmark.getPropertyAsString('name')
          },
          {
            key: BookmarkFormFieldName.existingCategory,
            value: bookmark.getPropertyAsString('category')
          }
        ])
        .reset()
    }

    storeSearchBookmarks.setPopoverVisible(visible)
  }

/**
 * Display field existingCategories or field newCategory.
 */
export const onCreateFolderForBookmarksToggle =
  (storeSearchBookmarks: StoreSearchBookmarks) => () => {
    storeSearchBookmarks.setCreatingNewFolderForBookmarks(
      !storeSearchBookmarks.isCreatingNewFolderForBookmarks
    )
  }

/**
 * Create a bookmark.
 */
export const onBookmarkCreate = (storeTrailFlow: StoreTrailFlow) => () => {
  const { expressionAsString } = storeTrailFlow.storeInputExpression.expression

  if (!expressionAsString) {
    return
  }

  const { storeSearchBookmarks } = storeTrailFlow
  const { storeFormCreateBookmark } = storeSearchBookmarks

  const bookmark: InputCreateSearchBookmarkEntry = {
    expression: expressionAsString,
    name: storeFormCreateBookmark.getFieldValueAsString('name'),
    category: storeSearchBookmarks.isCreatingNewFolderForBookmarks
      ? storeFormCreateBookmark.getFieldValueAsString('newCategory')
      : storeFormCreateBookmark.getFieldValueAsString('existingCategory')
  }

  storeSearchBookmarks
    .setPopoverVisible(false)
    .setCreatingNewFolderForBookmarks(false)
    .createSearchBookmarksEntry(bookmark)

  storeSearchBookmarks.storeFormCreateBookmark.hardReset()
}

/**
 * Edit a bookmark.
 */
export const onBookmarkEdit =
  (storeSearchBookmarks: StoreSearchBookmarks) =>
  (bookMarkId: number) =>
  () => {
    const { storeFormCreateBookmark } = storeSearchBookmarks

    const bookmark: InputEditSearchBookmarkEntry = {
      id: bookMarkId,
      name: storeFormCreateBookmark.getFieldValueAsString('name'),
      category: storeSearchBookmarks.isCreatingNewFolderForBookmarks
        ? storeFormCreateBookmark.getFieldValueAsString('newCategory')
        : storeFormCreateBookmark.getFieldValueAsString('existingCategory')
    }

    storeSearchBookmarks
      .setPopoverVisible(false)
      .setCreatingNewFolderForBookmarks(false)
      .editSearchBookmarksEntry(bookmark)

    storeSearchBookmarks.storeFormCreateBookmark.hardReset()
  }

/**
 * Delete search bookmark entry from Edit popover view.
 */
export const onSearchBookmarkEntryFromEditDelete =
  (storeSearchBookmarks: StoreSearchBookmarks) =>
  (bookMarkId: number) =>
  () => {
    storeSearchBookmarks
      .setPopoverVisible(false)
      .deleteSearchBookmarksEntry(bookMarkId)

    storeSearchBookmarks.storeFormCreateBookmark.hardReset()
  }
