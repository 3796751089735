import Label from '@app/components/Labels/Label'
import LabelTenantScanStatus from '@app/components/Labels/LabelTenantScanStatus'
import LabelTidAddOnError from '@app/components/Labels/LabelTidAddOnError'
import {
  ContainerFlex,
  ContainerIcon,
  ContainerRbac
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { FormWrapperSwitch } from '@app/components-legacy/Form/Wrappers'
import {
  IconDeleteOutlined,
  IconEditOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { LabelDate } from '@app/components-legacy/Label'
import {
  WidgetListActionButtons,
  WidgetListActionsHeader,
  WidgetListCountItems,
  WidgetListTable,
  WidgetListTableActionIcons
} from '@app/components-legacy/Widgets/WidgetList'
import type { IDataRowTenantSetting } from '@app/entities/EntityTenantSetting'
import type EntityTenantSetting from '@app/entities/EntityTenantSetting'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { buildVariants } from '@design-system/libs/buildVariants'
import { DateFormat } from '@libs/dates/formatDate'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  canCreateTenants,
  canDeleteTenants,
  canEditTenant
} from '../permissions'
import {
  handleAddTenantOnClick,
  handleDeleteTenantIconOnClick,
  handleEditTenantIconOnClick,
  handleTenantEnableSwitchOnChange
} from './handlers'

export interface ITenantsListProps {}

const LabelTenantScanStatusContainer = styled.div(props => {
  return (
    buildVariants(props)
      // custom CSS to align different icons sizes
      .css({
        display: 'flex',
        justifyContent: 'center',
        width: 25
      })
      .end()
  )
})

const TenantsList: React.FC<ITenantsListProps> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Tenants',
      'Components.IconIdentityProvider'
    ]
  })

  const appRouter = useAppRouter()

  const { storeRbac, storeManagementTenants, storeManagementAzureAD } =
    useStores()

  if (!storeManagementAzureAD.isTenableCloudApiTokensWorking) {
    return <LabelTidAddOnError />
  }

  return (
    <>
      <ContainerFlex
        name="TenantsList"
        direction="column"
        items={[
          <WidgetListActionsHeader>
            <WidgetListCountItems
              storeWidgetList={storeManagementTenants.storeWidgetList}
            />

            <WidgetListActionButtons>
              <ContainerRbac rbacCapabilityCheck={canCreateTenants}>
                <FormWrapperButton
                  labelledBy="addTenant"
                  buttonProps={{
                    type: 'primary',
                    onClick: handleAddTenantOnClick(appRouter)
                  }}
                >
                  {translate('Add a tenant')}
                </FormWrapperButton>
              </ContainerRbac>
            </WidgetListActionButtons>
          </WidgetListActionsHeader>,

          <WidgetListTable<EntityTenantSetting, IDataRowTenantSetting>
            translate={translate}
            hiddenColumnsKeys={['id']}
            storeWidgetList={storeManagementTenants.storeWidgetList}
            cellsRenderFn={{
              enabled: (enabled, row) => {
                return (
                  <FormWrapperSwitch
                    labelledBy={`enable-checkbox-${row.id}`}
                    switchProps={{
                      id: row.id,
                      size: 'small',
                      checked: enabled,
                      onChange: handleTenantEnableSwitchOnChange(
                        storeManagementTenants
                      )(row.id),
                      disabled: !storeRbac.isUserGrantedTo(
                        canEditTenant(row.id)
                      )
                    }}
                  />
                )
              },

              providerType: providerType => {
                return <span>{translate(providerType)}</span>
              },

              lastScanInfo: (lastScan, tenant) => {
                if (!tenant.enabled) {
                  return (
                    <LabelTenantScanStatusContainer>
                      <LabelTenantScanStatus disabled />
                    </LabelTenantScanStatusContainer>
                  )
                }

                return (
                  <LabelTenantScanStatusContainer>
                    <LabelTenantScanStatus scanInfo={lastScan} />
                  </LabelTenantScanStatusContainer>
                )
              },
              lastSucceededScanAt: lastSucceededScanAtDate => {
                if (!lastSucceededScanAtDate) {
                  return <Label label={translate('None')} />
                }
                return (
                  <LabelDate
                    date={lastSucceededScanAtDate}
                    formatDateOptions={{ format: DateFormat.verbose }}
                  />
                )
              }
            }}
            actionsButtonsRenderFn={tenantId => {
              return (
                <ContainerRbac rbacCapabilityCheck={canEditTenant(tenantId)}>
                  <WidgetListTableActionIcons
                    icons={[
                      <ContainerIcon
                        labelledBy={`editTenant-${tenantId}`}
                        iconComponent={IconEditOutlined}
                        iconProps={{
                          type: 'edit',
                          onClick:
                            handleEditTenantIconOnClick(appRouter)(tenantId)
                        }}
                      />,

                      <ContainerRbac rbacCapabilityCheck={canDeleteTenants}>
                        <ContainerIcon
                          labelledBy={`deleteTenant-${tenantId}`}
                          iconComponent={IconDeleteOutlined}
                          iconProps={{
                            onClick: handleDeleteTenantIconOnClick(
                              storeManagementTenants
                            )(tenantId)
                          }}
                        />
                      </ContainerRbac>
                    ]}
                  />
                </ContainerRbac>
              )
            }}
          />
        ]}
        spaced
      />
    </>
  )
}

export default observer(TenantsList)
