import { Features } from '@alsid/common'
import {
  createEntities,
  createEntity,
  EntityPagination,
  EntitySyslog
} from '@app/entities'
import type { IDataRowSyslog } from '@app/entities/EntitySyslog'
import {
  ProtocolInputName,
  SyslogFormFieldName
} from '@app/pages/Management/SystemPage/ConfigurationPage/ConfigurationSyslogsPage/types'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { CRITICITY_LEVEL_LOW } from '@libs/criticity'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefinedAndNotEmptyString } from '@libs/isDefined'
import { checkRbac } from '@libs/rbac/functions'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type {
  MutationCreateSyslog,
  MutationDeleteSyslog,
  MutationEditSyslog,
  MutationTestExistingSyslog,
  MutationTestNewSyslog
} from '@server/graphql/mutations/syslog'
import {
  mutationCreateSyslog,
  mutationDeleteSyslog,
  mutationEditSyslog,
  mutationTestExistingSyslog,
  mutationTestNewSyslog
} from '@server/graphql/mutations/syslog'
import type { QuerySyslogs } from '@server/graphql/queries/notification'
import { querySyslogs } from '@server/graphql/queries/notification'
import type {
  CreateSyslogMutationArgs,
  DeleteSyslogMutationArgs,
  EditSyslogMutationArgs,
  InputCreateSyslog,
  InputEditSyslog,
  InputTestNewSyslog,
  Pagination,
  RbacSyslogsQueryArgs,
  Syslog,
  TestExistingSyslogMutationArgs,
  TestNewSyslogMutationArgs
} from '@server/graphql/typeDefs/types'
import { CheckerType, SyslogInputType } from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import type { StoreRoot } from '..'
import StoreDrawer from '../helpers/StoreDrawer'
import StoreFlags from '../helpers/StoreFlags'
import StoreForm from '../helpers/StoreForm'
import {
  anyOfValidators,
  hostnameFormat,
  ipFormat,
  mandatory,
  portFormat
} from '../helpers/StoreForm/validators'
import StoreInputExpression from '../helpers/StoreInputExpression'
import StoreInputGenericCheckers from '../helpers/StoreInputGenericCheckers'
import type { ICheckerExposure } from '../helpers/StoreInputGenericCheckers/types'
import StoreWidgetList from '../helpers/StoreWidgetList'
import StoreBase from '../StoreBase'
import StoreInfrastructures from '../StoreInfrastructures'
import StoreInputHealthChecks from '../StoreInputHealthChecks'
import type { IStoreOptions } from '../types'
import type { ICheckerAttack } from './../helpers/StoreInputGenericCheckers/types'

export default class StoreSyslogs extends StoreBase {
  public storeFlags = new StoreFlags(this.storeRoot)
  public storeSubmitFlags = new StoreFlags(this.storeRoot)
  public storeTestConnectivityFlags = new StoreFlags(this.storeRoot)
  public storeDeletionFlags = new StoreFlags(this.storeRoot)

  public storeDeleteDrawer = new StoreDrawer<{ syslogDataRow: IDataRowSyslog }>(
    this.storeRoot
  )

  public storeForm = new StoreForm<SyslogFormFieldName>(this.storeRoot, {
    setup: {
      fields: {
        [SyslogFormFieldName.relayId]: {
          label: 'Relay',
          description: 'Relay to use to connect to the Syslog collector',
          inputType: InputType.select,
          validators: [
            mandatory({
              isValid: value => {
                const featureAlertingThroughRelay =
                  this.storeRoot.stores.storeRbac.isUserGrantedAccordingFeatureFlag(
                    Features.ALERTING_THROUGH_RELAY
                  )

                // don't check if KAPTEYN_SHARED_APP_SECURERELAY_CUSTOMERSIDE env var is not enabled
                if (
                  !this.storeRoot.environment.config.app.securerelay
                    .customerside
                ) {
                  return true
                }

                // don't check if feature flag for alerting through relay is not enabled
                if (!featureAlertingThroughRelay) {
                  return true
                }

                return isDefinedAndNotEmptyString(value)
              }
            })
          ]
        },
        [SyslogFormFieldName.ip]: {
          label: 'Collector IP address or hostname',
          validators: [
            mandatory(),
            anyOfValidators(ipFormat(), hostnameFormat())
          ]
        },
        [SyslogFormFieldName.port]: {
          label: 'Collector port',
          validators: [mandatory(), portFormat()]
        },
        // TODO: Add all validators (but need some implem to handle multi-field values)
        [SyslogFormFieldName.id]: {
          label: 'ID'
        },
        [SyslogFormFieldName.protocol]: {
          label: 'Protocol',
          description: 'Protocol used by the collector',
          inputType: InputType.select,
          validators: [mandatory()]
        },
        [SyslogFormFieldName.tls]: {
          label: 'TLS',
          description: 'Activate TLS to encrypt logs',
          inputType: InputType.checkbox
        },
        [SyslogFormFieldName.description]: {
          label: 'Description'
        },
        [SyslogFormFieldName.shouldNotifyOnInitialFullSecurityCheck]: {
          label: 'Full security check'
        },
        [SyslogFormFieldName.profiles]: {
          label: 'Profile',
          validators: [
            mandatory({
              isValid: value => {
                const inputType =
                  this.storeForm.getFieldValueAsString<SyslogInputType>(
                    SyslogFormFieldName.inputType
                  )

                // don't check for adObject changes
                if (inputType === SyslogInputType.AdObjectChanges) {
                  return true
                }

                // don't check for health checks
                if (inputType === SyslogInputType.HealthChecks) {
                  return true
                }

                return isDefinedAndNotEmptyString(value)
              }
            })
          ]
        },
        [SyslogFormFieldName.inputType]: {
          label: 'Filter trigger'
        },
        [SyslogFormFieldName.criticityThreshold]: {
          label: 'Criticity threshold',
          description:
            'Threshold of severity from which the alerts of the indicators will be sent'
        },
        [SyslogFormFieldName.checkers]: {
          label: 'Indicators of Exposure',
          validators: [
            mandatory({
              isValid: () => {
                const inputType =
                  this.storeForm.getFieldValueAsString<SyslogInputType>(
                    SyslogFormFieldName.inputType
                  )

                // don't check for adObject changes
                if (inputType === SyslogInputType.AdObjectChanges) {
                  return true
                }

                // don't check for attacks
                if (inputType === SyslogInputType.Attacks) {
                  return true
                }

                // don't check for health checks
                if (inputType === SyslogInputType.HealthChecks) {
                  return true
                }

                // check this field if it's for deviances
                return this.storeInputCheckersExposure.hasSelectedCheckers
              },
              onError: () =>
                this.translate('You have to select at least one indicator')
            })
          ]
        },
        [SyslogFormFieldName.attackTypes]: {
          label: 'Indicators of Attack',
          validators: [
            mandatory({
              isValid: () => {
                const inputType =
                  this.storeForm.getFieldValueAsString<SyslogInputType>(
                    SyslogFormFieldName.inputType
                  )

                // check this field if it's for attacks
                if (inputType === SyslogInputType.Attacks) {
                  return this.storeInputCheckersAttacks.hasSelectedCheckers
                }

                return true
              },
              onError: () =>
                this.translate('You have to select at least one indicator')
            })
          ]
        },
        [SyslogFormFieldName.filterExpression]: {
          label: 'Filter expression',
          validators: [
            mandatory({
              isValid: () => {
                const inputType =
                  this.storeForm.getFieldValueAsString<SyslogInputType>(
                    SyslogFormFieldName.inputType
                  )

                // check expression only for changes
                if (inputType === SyslogInputType.AdObjectChanges) {
                  return this.storeInputExpression.validateEntry()
                }

                return true
              },
              onError: () => this.translate('Invalid expression')
            })
          ]
        },
        [SyslogFormFieldName.healthCheckNames]: {
          label: 'Health checks',
          description: 'Health Checks affected by this alert',
          validators: [
            mandatory({
              isValid: value => {
                const inputType =
                  this.storeForm.getFieldValueAsString<SyslogInputType>(
                    SyslogFormFieldName.inputType
                  )

                // don't check this field if it's for attacks
                if (inputType === SyslogInputType.Attacks) {
                  return true
                }

                // don't check for deviances
                if (inputType === SyslogInputType.Deviances) {
                  return true
                }

                // don't check for ad object changes
                if (inputType === SyslogInputType.AdObjectChanges) {
                  return true
                }

                return isDefinedAndNotEmptyString(value)
              },
              onError: () => {
                return this.translate(
                  'You must select at least one health check'
                )
              }
            })
          ]
        },
        [SyslogFormFieldName.directories]: {
          label: 'Directories',
          description: 'Directories affected by this alert'
        }
      },
      assertFieldExists: false
    }
  })

  public storeWidgetList = new StoreWidgetList<EntitySyslog, IDataRowSyslog>(
    this.storeRoot,
    {
      selectable: false
    }
  )

  public storeInputCheckersExposure =
    new StoreInputGenericCheckers<ICheckerExposure>(this.storeRoot, {
      checkerType: CheckerType.Exposure,
      selectable: true
    })

  public storeInputCheckersAttacks =
    new StoreInputGenericCheckers<ICheckerAttack>(this.storeRoot, {
      checkerType: CheckerType.Attack,
      selectable: true
    })

  public storeInputExpression = new StoreInputExpression(this.storeRoot)

  public storeInfrastructures = new StoreInfrastructures(this.storeRoot)
  public storeInputHealthChecks = new StoreInputHealthChecks(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: [
      'Errors',
      'Components.InputExpression',
      'Components.InputHealthChecks',
      'Management.System.Configuration.SyslogAlerts',
      'Management.System.Directories'
    ]
  })

  /* Observable */

  // keys are syslog id
  private $syslogs = observable.map<number, EntitySyslog>()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  fetchSyslogs(_args?: RbacSyslogsQueryArgs): Promise<void> {
    const args: RbacSyslogsQueryArgs = {
      syslogsPage: this.storeWidgetList.paginationPage,
      syslogsPerPage: this.storeWidgetList.rowsPerPage,
      ..._args
    }

    this.storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QuerySyslogs>(querySyslogs, args)
      })
      .then(data => data.rbacSyslogs)
      .then(syslogs => {
        if (!checkRbac(this.storeRoot, this.storeFlags)(syslogs)) {
          throw new ForbiddenAccessError()
        }

        const syslogsEntities = createEntities<Syslog, EntitySyslog>(
          EntitySyslog,
          syslogs.node.node
        )

        const syslogsPagination = createEntity<Pagination, EntityPagination>(
          EntityPagination,
          syslogs.node.pagination
        )

        this.setSyslogs(syslogsEntities)

        this.storeWidgetList.setEntities(syslogsEntities)
        this.storeWidgetList.setPagination(syslogsPagination)

        this.storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlags))
  }

  /**
   * Create a syslog configuration,
   */
  createSyslog(syslog: InputCreateSyslog) {
    this.storeSubmitFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args = this.filterCreateSyslogByInputType({ syslog })

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationCreateSyslog>(mutationCreateSyslog, args)
      })
      .then(() => this.fetchSyslogs())
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('SYSLOG alert created'),
          {
            labelledBy: 'syslogAlertCreated'
          }
        )

        this.storeSubmitFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeSubmitFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when creating the syslog configuration'
        })
      )
  }

  /**
   * Edit a syslog.
   */
  editSyslog(syslog: InputEditSyslog) {
    this.storeSubmitFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args = this.filterEditSyslogByInputType({ syslog })

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationEditSyslog>(mutationEditSyslog, args)
      })
      .then(() => {
        // reload syslogs (and remake the mapping between infras and dirs)
        return this.fetchSyslogs()
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('SYSLOG alert updated'),
          {
            labelledBy: 'syslogAlertUpdated'
          }
        )

        this.storeSubmitFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeSubmitFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when editing the syslog configuration'
        })
      )
  }

  /**
   * Delete a syslog.
   */
  deleteSyslog(syslogId: number) {
    this.storeDeletionFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: DeleteSyslogMutationArgs = {
          syslog: {
            id: syslogId
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationDeleteSyslog>(mutationDeleteSyslog, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('SYSLOG alert deleted'),
          {
            labelledBy: 'syslogAlertDeleted'
          }
        )

        this.storeDeletionFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeDeletionFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when deleting the syslog'
        })
      )
  }

  /**
   * Test the connectivity of a new Syslog alert.
   */
  testNewSyslog(syslog: InputTestNewSyslog): Promise<void> {
    this.storeTestConnectivityFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args = this.filterTestSyslogByInputType({ syslog })

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationTestNewSyslog>(mutationTestNewSyslog, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('A SYSLOG alert has been sent to the server'),
          {
            labelledBy: 'newSyslogAlertSent'
          }
        )

        this.storeTestConnectivityFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeTestConnectivityFlags))
  }

  /**
   * Test the connectivity of an existing Syslog alert.
   */
  testExistingSyslog(syslogId: number): Promise<void> {
    this.storeTestConnectivityFlags.loading()

    const args: TestExistingSyslogMutationArgs = {
      syslog: {
        id: syslogId
      }
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<MutationTestExistingSyslog>(mutationTestExistingSyslog, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('A SYSLOG alert has been sent to the server'),
          {
            labelledBy: 'existingSyslogAlertSent'
          }
        )

        this.storeTestConnectivityFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeTestConnectivityFlags))
  }

  getArgsFromStores(): InputTestNewSyslog {
    const featureRelayOnCustomerSide =
      this.storeRoot.stores.storeRbac.isUserGrantedAccordingFeatureFlag(
        Features.ALERTING_THROUGH_RELAY
      )

    const inputType = this.storeForm.getFieldValueAsString<SyslogInputType>(
      SyslogFormFieldName.inputType
    )

    const args: InputTestNewSyslog = {
      ip: this.storeForm.getFieldValueAsString(SyslogFormFieldName.ip),

      port: this.storeForm.getFieldValueAsNumber(SyslogFormFieldName.port),

      protocol: this.storeForm.getFieldValueAsString<ProtocolInputName>(
        SyslogFormFieldName.protocol
      ),

      tls:
        this.storeForm.getFieldValueAsString<ProtocolInputName>(
          SyslogFormFieldName.protocol
        ) === ProtocolInputName.UDP
          ? false
          : this.storeForm.getFieldValueAsBoolean(SyslogFormFieldName.tls),

      description: this.storeForm.getFieldValueAsString(
        SyslogFormFieldName.description
      ),

      // false if Health check context
      shouldNotifyOnInitialFullSecurityCheck:
        inputType === SyslogInputType.HealthChecks
          ? false
          : this.storeForm.getFieldValueAsBoolean(
              SyslogFormFieldName.shouldNotifyOnInitialFullSecurityCheck
            ),

      // no profile if Health check context
      profiles:
        inputType === SyslogInputType.HealthChecks
          ? null
          : this.storeForm
              .getFieldValueAsString(SyslogFormFieldName.profiles)
              .split(',')
              .map(id => Number(id)),

      inputType,

      // on each deviance / attack, low if Health check context
      criticityThreshold:
        inputType === SyslogInputType.HealthChecks
          ? CRITICITY_LEVEL_LOW
          : this.storeForm.getFieldValueAsNumber(
              SyslogFormFieldName.criticityThreshold,
              CRITICITY_LEVEL_LOW
            ),

      // no checker if IoA or Health check context
      checkers:
        inputType === SyslogInputType.Attacks ||
        inputType === SyslogInputType.HealthChecks
          ? null
          : this.storeInputCheckersExposure.selectedCheckerIds,

      // no attackTypes if IoE or Health check context
      attackTypes:
        inputType === SyslogInputType.Attacks ||
        inputType === SyslogInputType.HealthChecks
          ? this.storeInputCheckersAttacks.selectedCheckerIds
          : null,

      // on each changes
      filterExpression:
        this.storeInputExpression.expression.expressionAsStringyfiedObject,

      healthCheckNames: this.storeForm
        .getFieldValueAsString(SyslogFormFieldName.healthCheckNames)
        .split(',')
        .map(id => String(id)),

      directories: this.storeForm
        .getFieldValueAsString(SyslogFormFieldName.directories)
        .split(',')
        .map(id => Number(id)),

      relayId: featureRelayOnCustomerSide
        ? this.storeForm.getFieldValueAsNumber(SyslogFormFieldName.relayId)
        : null
    }

    return args
  }

  /**
   * Filter syslog args depending of inputType
   * - if 'deviances', then omit filterExpression
   * - if 'adObjectChanges', then reset criticityThreshold to zero
   * - if 'healthChecks', then omit some fields
   */
  private filterCreateSyslogByInputType(
    args: CreateSyslogMutationArgs
  ): CreateSyslogMutationArgs {
    switch (args.syslog.inputType) {
      case SyslogInputType.AdObjectChanges: {
        const { criticityThreshold, ...syslogWithoutCriticityThreshold } =
          args.syslog

        const syslog = {
          ...syslogWithoutCriticityThreshold,
          criticityThreshold: CRITICITY_LEVEL_LOW
        }

        return { syslog }
      }

      case SyslogInputType.Deviances:
      case SyslogInputType.Attacks: {
        const { filterExpression, ...syslogWithoutExpression } = args.syslog
        return { syslog: syslogWithoutExpression }
      }

      case SyslogInputType.HealthChecks: {
        const {
          filterExpression,
          attackTypes,
          profiles,
          checkers,
          ...syslogWithoutExpression
        } = args.syslog

        return {
          syslog: syslogWithoutExpression
        }
      }

      default:
        assertUnreachableCase(args.syslog.inputType)
    }
  }

  /**
   * Filter syslog rags depending of inputType
   * - if 'deviances', then omit filterExpression
   * - if 'adObjectChanges', then reset criticityThreshold to zero
   * - if 'healthChecks', then omit some fields
   */
  private filterTestSyslogByInputType(
    args: TestNewSyslogMutationArgs
  ): TestNewSyslogMutationArgs {
    switch (args.syslog.inputType) {
      case SyslogInputType.AdObjectChanges: {
        const { criticityThreshold, ...syslogWithoutCriticityThreshold } =
          args.syslog

        const syslog = {
          ...syslogWithoutCriticityThreshold,
          criticityThreshold: CRITICITY_LEVEL_LOW
        }

        return { syslog }
      }

      case SyslogInputType.Deviances:
      case SyslogInputType.Attacks: {
        const { filterExpression, ...syslogWithoutExpression } = args.syslog
        return { syslog: syslogWithoutExpression }
      }

      case SyslogInputType.HealthChecks: {
        const {
          filterExpression,
          attackTypes,
          profiles,
          checkers,
          ...syslogWithoutExpression
        } = args.syslog

        return {
          syslog: syslogWithoutExpression
        }
      }

      default:
        assertUnreachableCase(args.syslog.inputType)
    }
  }

  /**
   * Filter syslog args depending of inputType
   * - if 'deviances', then omit filterExpression
   * - if 'adObjectChanges', then reset criticityThreshold to zero
   * - if 'healthChecks', then omit some fields
   */
  private filterEditSyslogByInputType(
    args: EditSyslogMutationArgs
  ): EditSyslogMutationArgs {
    switch (args.syslog.inputType) {
      case SyslogInputType.AdObjectChanges: {
        const { criticityThreshold, ...syslogWithoutCriticityThreshold } =
          args.syslog

        const syslog = {
          ...syslogWithoutCriticityThreshold,
          criticityThreshold: CRITICITY_LEVEL_LOW
        }

        return { syslog }
      }

      case SyslogInputType.Deviances:
      case SyslogInputType.Attacks: {
        const { filterExpression, ...syslogWithoutExpression } = args.syslog
        return { syslog: syslogWithoutExpression }
      }

      case SyslogInputType.HealthChecks: {
        const {
          filterExpression,
          attackTypes,
          profiles,
          checkers,
          ...syslogWithoutExpression
        } = args.syslog

        return {
          syslog: syslogWithoutExpression
        }
      }

      default:
        assertUnreachableCase(args.syslog.inputType)
    }
  }

  /* Actions */

  @action
  reset(): this {
    this.storeForm.reset()
    this.storeInputExpression.reset()

    this.storeInputCheckersExposure.reset()
    this.storeInputCheckersAttacks.reset()

    this.storeInputHealthChecks.reset()
    this.storeInfrastructures.reset()

    return this
  }

  @action
  setSyslogs(syslogs: EntitySyslog[]) {
    this.$syslogs.clear()

    syslogs.forEach(entity => {
      if (entity.id) {
        this.$syslogs.set(entity.id, entity)
      }
    })
  }

  /* Computed */

  @computed
  get syslogs(): Map<number, EntitySyslog> {
    return toJS(this.$syslogs)
  }
}
