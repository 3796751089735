import {
  ContainerFooter,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { Modal, ModalCancelButton } from '@app/components-legacy/Modal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { AttacksPageContext } from '../context'
import { onAttackCloseActionModalConfirmClick } from './handlers'

export interface IIoAAttacksConfirmCloseModalProps {}

const IoAAttacksModalConfirmClose: React.FC<
  IIoAAttacksConfirmCloseModalProps
> = props => {
  const attacksPageContext = React.useContext(AttacksPageContext)

  const translate = useAppTranslator({
    namespaces: ['Titles', 'Buttons', 'IoA.Attacks']
  })

  if (!attacksPageContext) {
    return null
  }

  return (
    <Modal
      modalProps={{
        title: translate('Confirmation'),
        footer: (
          <ContainerFooter variant="modal">
            <ModalCancelButton
              storeModal={
                attacksPageContext.storeAttacks.storeModalConfirmCloseAction
              }
            />

            <FormWrapperButton
              labelledBy="submit"
              buttonProps={{
                type: 'primary',
                onClick: onAttackCloseActionModalConfirmClick(
                  attacksPageContext.storeAttacks
                ),
                loading:
                  attacksPageContext.storeAttacks.storeFlagsCloseAttacks.flags
                    .isLoading
              }}
            >
              {translate('Confirm')}
            </FormWrapperButton>
          </ContainerFooter>
        )
      }}
      storeModal={attacksPageContext.storeAttacks.storeModalConfirmCloseAction}
    >
      <p>{translate('When closing an incident')}</p>
      <p>
        <ContainerHTMLContent>
          {translate(
            'You can display closed incidents by clicking on the closed incidents toggle button',
            { transformMarkdown: true }
          )}
        </ContainerHTMLContent>
      </p>
      <p>
        {translate('Do you want to close the incident', {
          interpolations: {
            count:
              attacksPageContext.storeAttacks.storeWidgetListAttacks
                .countSelectedRows
          }
        })}
      </p>
    </Modal>
  )
}

export default observer(IoAAttacksModalConfirmClose)
