import type {
  StoreAuthentication,
  StoreManagementApplicationSettings
} from '@app/stores'
import type { InputUpdateApplicationSettings } from '@server/graphql/typeDefs/types'

/**
 * update cloudStatisticsIsActive
 */
export const updateCloudStatisticsIsActive =
  (
    storeManagementApplicationSettings: StoreManagementApplicationSettings,
    storeAuthentication: StoreAuthentication
  ) =>
  async (isEnabled: boolean) => {
    const applicationSettings: InputUpdateApplicationSettings = {
      cloudStatisticsIsActive: isEnabled
    }

    await storeManagementApplicationSettings
      .updateApplicationSettings(applicationSettings)
      .then(() => {
        storeAuthentication.fetchCloudStatistics()
      })
  }
