import * as React from 'react'
import styled from 'styled-components'

interface IIoAAttacksDescriptionTabContentProps {
  className?: string
  showDescriptionTab?: boolean
  children?: React.ReactNode
}

const IoAAttacksDescriptionTabContent: React.FC<
  IIoAAttacksDescriptionTabContentProps
> = props => {
  return <div className={props.className}>{props.children}</div>
}

export default styled(IoAAttacksDescriptionTabContent)`
  margin-bottom: ${props => (props.showDescriptionTab ? '20px' : 0)};
`
