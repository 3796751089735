const dangerousChars = new Set(['=', '-', '"', '@', '+', '	'])

export function sanitizeCsvValue(str: unknown): string {
  if (typeof str === 'number' || typeof str === 'bigint') {
    return String(str)
  }

  if (!str) {
    return ''
  }

  let csvValue = String(str)

  /**
   * @see https://wiki.mozilla.org/images/6/6f/Phpmyadmin-report.pdf
   *
   * When performing a CSV Export, for any cell that starts with an =, -, ", @, or +, add a space to
   * the beginning and remove any tab characters (0x09) in the cell
   */
  if (dangerousChars.has(String(csvValue.at(0)))) {
    csvValue = ` ${csvValue.replaceAll(/\t/g, ' ')}`
  }

  /**
   * @see https://www.ietf.org/rfc/rfc4180.txt
   *
   * Fields containing line breaks (CRLF), double quotes, and commas
   * should be enclosed in double-quotes.
   *
   * If double-quotes are used to enclose fields, then a double-quote
   * appearing inside a field must be escaped by preceding it with
   * another double quote.
   */
  return `"${csvValue.replaceAll('"', '""')}"`
}
