import type { MaybeUndef } from '@@types/helpers'
import { deepMerge } from '@libs/deepMerge'
import { JSONBigIntParse, JSONBigIntStringify } from '@libs/json/bigint'
import ExpressionParser from './ExpressionParser'
import type { IExpressionDefinition, NodeOrLeaf } from './types'

export default class Expression {
  private _nodeOrLeaf: MaybeUndef<NodeOrLeaf> = undefined

  /**
   * Convert the expression to its displayed string representation.
   */
  get expressionAsString(): MaybeUndef<string> {
    if (!this._nodeOrLeaf) {
      return undefined
    }

    return this._nodeOrLeaf.toString()
  }

  /**
   * Return the expression as a JS expression.
   * /!\ Warning, this method exposes a string that could leads to security issues
   * since it's a user entry and it has to be `eval`ed!
   * Used only by mocks to be able to filter results.
   */
  get expressionAsJS(): string {
    if (!this.isDefined) {
      return 'true'
    }

    if (!this._nodeOrLeaf) {
      return 'false'
    }

    return this._nodeOrLeaf.toJs()
  }

  /**
   * Return the expression as an object.
   */
  get expressionAsObject(): IExpressionDefinition {
    if (!this._nodeOrLeaf) {
      return {}
    }

    return this._nodeOrLeaf.toExpression()
  }

  /**
   * Return the expression as an stringyfied object.
   */
  get expressionAsStringyfiedObject(): string {
    return Expression.fromJSONToString(this.expressionAsObject)
  }

  get node() {
    return this._nodeOrLeaf
  }

  get isDefined(): boolean {
    const defined =
      this.expressionAsString === undefined || this.expressionAsString === ''

    return !defined
  }

  fromString(str: string): this {
    this._nodeOrLeaf = new ExpressionParser().parse(str)
    return this
  }

  fromExpressionDefinition(expressionDefinition: IExpressionDefinition): this {
    this._nodeOrLeaf = new ExpressionParser().parseObject(expressionDefinition)
    return this
  }

  /**
   * Merge an expression to the current one.
   */
  merge(expression: Expression): Expression {
    // merge objects
    const mergedExpObj = deepMerge<
      IExpressionDefinition,
      IExpressionDefinition
    >(this.expressionAsObject, expression.expressionAsObject)

    // return a new expression
    return new Expression().fromExpressionDefinition(mergedExpObj)
  }

  setNode(nodeOrLeaf: MaybeUndef<NodeOrLeaf>): this {
    this._nodeOrLeaf = nodeOrLeaf
    return this
  }

  /**
   * Parse an expression with BigInt support.
   */
  static fromStringToJSON(expressionString: string): IExpressionDefinition {
    return JSONBigIntParse<IExpressionDefinition>(expressionString)
  }

  /**
   * Stringify an expression object with BigInt support.
   */
  static fromJSONToString(expressionJSON: IExpressionDefinition): string {
    return JSONBigIntStringify(expressionJSON)
  }
}
