import * as React from 'react'
import styled from 'styled-components'

/*
 * CheckboxWrapper
 * Used to overwrite background and color of the checkbox.
 */

interface ICheckboxWrapperProps {
  className?: string
  color: string
  children: React.ReactNode
}

const CheckboxWrapper: React.FC<ICheckboxWrapperProps> = props => {
  return (
    <div data-name="CheckboxWrapper" className={props.className}>
      {props.children}
    </div>
  )
}

export default styled(CheckboxWrapper)`
  .ant-checkbox-checked::after {
    border: 1px solid ${props => props.color};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.color};
    border-color: ${props => props.color};
  }

  .ant-checkbox-checked::after {
    border: 1px solid ${props => props.color};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.color};
    border-color: ${props => props.color};
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${props => props.color};
  }

  /* Force the checkbox + labels on Antd to not be wrapped */
  > .ant-checkbox-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
`
