import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import {
  IconVerticalAlignBottomOutlined,
  IconVerticalAlignMiddleOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { useAppTranslator, useStores } from '@app/hooks'
import { Divider, Tooltip } from 'antd'
import * as React from 'react'
import { onCollapsedItemListsButtonClick } from './ListEntityItems/handlers'

interface IRolesEditBladeFooterTogglesProps {}

const RolesEditFooterToggles: React.FC<
  IRolesEditBladeFooterTogglesProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Roles.Permissions']
  })

  const { storeManagementRbacRoles } = useStores()

  return (
    <ContainerFlex
      name="RolesEditFooterToggles"
      alignItems="center"
      items={[
        <Divider type="vertical" />,

        <ContainerFlex
          name="RolesEditBladeFooterEntitiesVisibilityButtons"
          alignItems="center"
          items={[
            <Tooltip title={translate('Collapse all objects')}>
              <FormWrapperButton
                labelledBy="collapseAllObjects"
                buttonProps={{
                  icon: <IconVerticalAlignMiddleOutlined />,
                  onClick: onCollapsedItemListsButtonClick(
                    storeManagementRbacRoles
                  )(false)
                }}
              />
            </Tooltip>,

            <Tooltip title={translate('Expand all objects')}>
              <FormWrapperButton
                labelledBy="expandAllObjects"
                buttonProps={{
                  icon: <IconVerticalAlignBottomOutlined />,
                  onClick: onCollapsedItemListsButtonClick(
                    storeManagementRbacRoles
                  )(true)
                }}
              />
            </Tooltip>
          ]}
          spaced
          spaceWidth="small"
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default RolesEditFooterToggles
