import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import { handleTenantCreationOnSubmit } from './handlers'
import TenantCommonForm from './TenantCommonForm'

interface ITenantCreateFormProps {}

const TenantCreateForm: React.FC<ITenantCreateFormProps> = props => {
  const { storeManagementTenants } = useStores()

  return (
    <TenantCommonForm
      storeForm={storeManagementTenants.storeFormCreation}
      version="creation"
      onSubmit={handleTenantCreationOnSubmit(storeManagementTenants)}
    />
  )
}

export default TenantCreateForm
