import * as React from 'react'
import { ContainerFlex } from '../Container'
import BladeHeaderValueType from './BladeHeaderValueType'
import BladeHeaderValueValue from './BladeHeaderValueValue'

interface IBladeHeaderValueProps {
  rawType: string
  type: string
  showValueOnly?: boolean
  value: React.ReactNode
}

const BladeHeaderValue: React.FC<IBladeHeaderValueProps> = props => {
  return (
    <ContainerFlex
      name="BladeHeaderValue"
      labelledBy={props.rawType}
      ariaRoles={['banner', 'cell']}
      direction="column"
      items={[
        !props.showValueOnly && (
          <BladeHeaderValueType rawType={props.rawType} type={props.type} />
        ),
        <BladeHeaderValueValue rawType={props.rawType} value={props.value} />
      ]}
      spaced
      spaceWidth="verySmall"
    />
  )
}

export default BladeHeaderValue
