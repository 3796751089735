import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { IconSVG } from '@app/components-legacy/Icon'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import styled from 'styled-components'
import { handleNodeFinderOnSwap } from './handlers'

const StyledIconSVG = styled(IconSVG)`
  display: flex; // center the icon
`

interface IAttackPathSwapButtonProps {
  className?: string
  style?: React.CSSProperties
}

const AttackPathSwapButton: React.FC<IAttackPathSwapButtonProps> = props => {
  const { storeAttackPath } = useStores()

  return (
    <FormWrapperButton
      className={props.className}
      style={props.style}
      labelledBy="AttackPathSwapButton"
      variant={ButtonVariant.secondary}
      size={ButtonSize.small}
      buttonProps={{
        icon: <StyledIconSVG size={16} iconName="attackpath/reverse-path" />,
        onClick: handleNodeFinderOnSwap(storeAttackPath)
      }}
    />
  )
}

export default AttackPathSwapButton
