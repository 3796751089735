import InputSearch from '@app/components-legacy/Input/InputSearch'
import { LabelDirectoriesTree } from '@app/components-legacy/Label'
import LabelDirectoryTag from '@app/components-legacy/Label/LabelDirectoryTag'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useMeasure } from 'react-use'
import styled from 'styled-components'
import AttackPathAutoComplete from './AttackPathAutoComplete'
import AttackPathAutoCompleteOption from './AttackPathAutoCompleteOption'
import {
  getLeftIcon,
  handleNodeFinderSourceFocus,
  handleNodeFinderSourceSelect,
  handleSourceSearchInputChange,
  handleSourceSearchInputReset
} from './handlers'
import { getInputSearchAvailableWidth } from './utils'

const DivMarginLeft = styled.div`
  display: flex;
  margin-left: 8px;
`

interface IAttackPathInputSearchSourceProps {
  className?: string
  style?: React.CSSProperties
}

const AttackPathInputSearchSource: React.FC<
  IAttackPathInputSearchSourceProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.NodeFinder']
  })

  const { storeAttackPath, storeInfrastructures } = useStores()

  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const availableWidth = getInputSearchAvailableWidth(width)

  const { targetNodeDisplay } = storeAttackPath

  const directoryId = storeAttackPath.selectedSearchedSourceNode?.directoryId
  const directory =
    directoryId && storeInfrastructures.getDirectoryFromId(directoryId)

  return (
    <div className={props.className} style={props.style} ref={ref}>
      <AttackPathAutoComplete
        popupClassName="attack-path-node-finder"
        listHeight={380}
        options={storeAttackPath.searchSourceSuggestions.map(
          (attackPathNode, i) => ({
            value: String(attackPathNode.id),
            label: (
              <AttackPathAutoCompleteOption
                key={attackPathNode.id}
                labelledBy={`attackPath/sourceSuggestion/${i}`}
                attackPathNode={attackPathNode}
                storeInfrastructures={storeInfrastructures}
              />
            )
          })
        )}
        onSelect={handleNodeFinderSourceSelect(storeAttackPath)}
        onFocus={handleNodeFinderSourceFocus(storeAttackPath)}
      >
        <InputSearch
          name="node-finder-source-search"
          placeholder={
            !targetNodeDisplay
              ? translate(
                  'Search for a node to get all the information about it'
                )
              : translate('Set a starting node')
          }
          storeInputSearch={storeAttackPath.storeInputSearchSource}
          onChange={handleSourceSearchInputChange(storeAttackPath)}
          onReset={handleSourceSearchInputReset(storeAttackPath)}
          leftIcon={getLeftIcon(
            storeAttackPath.selectedSearchedSourceNode,
            storeAttackPath.storeFlagsSearchSourceNodes,
            storeAttackPath.activeTab,
            storeAttackPath.direction,
            true
          )}
          suffix={
            directory &&
            directory.name && (
              <DivMarginLeft>
                <LabelDirectoryTag
                  labelledBy="sourceDirectory"
                  color={directory.color}
                  label={directory.name}
                  tooltipLabel={
                    <LabelDirectoriesTree
                      // key used to force the recreation of the component on
                      // change to prevent to add props.directoryIds
                      // dependency to the useMemo as no directoryIds prop
                      // usage is memoized
                      key={directoryId}
                      storeInfrastructures={storeInfrastructures}
                      directoryIds={[directoryId]}
                    />
                  }
                  maxWidth={availableWidth * 0.3}
                />
              </DivMarginLeft>
            )
          }
          labelledByReset="resetSearchSource"
        />
      </AttackPathAutoComplete>
    </div>
  )
}

const observedAttackPathInputSearchSource = observer(
  AttackPathInputSearchSource
)

export default styled(observedAttackPathInputSearchSource)`
  width: 100%;
  pointer-events: auto;
`
