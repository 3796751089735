import { useTestAttribute } from '@app/hooks/useTestAttribute'
import * as React from 'react'
import { FormWrapperContext } from './FormWrapperContext'
import { useFormWrapperUid } from './hooks'

interface IFormWrapperProps
  extends React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  name: string
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const FormWrapper: React.FC<IFormWrapperProps> = props => {
  const { testAttributeProps } = useTestAttribute('form')

  const formUid = useFormWrapperUid()

  return (
    <FormWrapperContext.Provider
      value={{ uid: formUid, name: props.name, onSubmit: props.onSubmit }}
    >
      <form
        id={formUid}
        {...props}
        onSubmit={props.onSubmit}
        {...testAttributeProps('main')(props.name)}
      >
        {props.children}
      </form>
    </FormWrapperContext.Provider>
  )
}

export default FormWrapper
