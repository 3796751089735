import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { ButtonSize } from '@app/components-legacy/Form/Wrappers/types'
import { InputSearch } from '@app/components-legacy/Input'
import InputDatePickerRange from '@app/components-legacy/Input/InputDatePickerRange'
import { useAppTranslator, useStores } from '@app/hooks'
import { DateFormat } from '@libs/dates/formatDate'
import { Language } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  onInputDatePickerChange,
  onInputSearchReset,
  onInputSearchSubmit
} from './handlers'

export interface IDrawerHeaderProps {
  className?: string
}

const DrawerHeader: React.FC<IDrawerHeaderProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['TrailFlow.SearchHistory']
  })

  const { storeTrailFlow, storePreferences } = useStores()

  return (
    <ContainerFlex
      name="SearchHistoryTimelineHeader"
      itemsFlexGrow={[2, 1, 0]}
      alignItems="center"
      items={[
        <InputSearch
          placeholder={translate('Search a history entry')}
          storeInputSearch={storeTrailFlow.storeSearchHistory.storeInputSearch}
          onValidate={onInputSearchSubmit(storeTrailFlow.storeSearchHistory)}
          onReset={onInputSearchReset(storeTrailFlow.storeSearchHistory)}
        />,

        <InputDatePickerRange
          onChange={onInputDatePickerChange(storeTrailFlow.storeSearchHistory)}
          storeDatePicker={storeTrailFlow.storeSearchHistory.storeDatePicker}
          labelledBy="history-datePicker"
          format={
            storePreferences.language === Language.FrFr
              ? DateFormat.frenchDateOfTheDay
              : DateFormat.englishDateOfTheDay
          }
        />,

        <FormWrapperButton
          labelledBy="search"
          size={ButtonSize.small}
          buttonProps={{
            type: 'primary',
            onClick: onInputSearchSubmit(storeTrailFlow.storeSearchHistory)
          }}
        >
          {translate('Search')}
        </FormWrapperButton>
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(DrawerHeader)
