import {
  ContainerContent,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import { LabelMissingData } from '@app/components-legacy/Label'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import { CheckerDetailsPageContext } from '../context'

export interface IExecutiveSummaryProps {}

const ExecutiveSummary: React.FC<IExecutiveSummaryProps> = props => {
  const parameters = React.useContext(CheckerDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoE.Details.Recommendations']
  })

  const appRouter = useAppRouter()

  const { storeIoE } = useStores()

  if (!parameters) {
    return null
  }

  const renderContent = () => {
    const checker = storeIoE.checkers.get(parameters.checkerCodename)

    if (!checker) {
      return <LabelMissingData />
    }

    if (!checker.execSummary) {
      return <LabelMissingData />
    }

    return (
      <ContainerHTMLContent labelledBy="deviance execSummary">
        {checker.execSummary}
      </ContainerHTMLContent>
    )
  }

  return (
    <ContainerContent title={translate('Executive summary')}>
      {renderContent()}
    </ContainerContent>
  )
}

export default ExecutiveSummary
