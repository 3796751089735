import { BladeCancelButton } from '@app/components-legacy/Blade'
import { ContainerFooter } from '@app/components-legacy/Container'
import { FormWrapperButtonSubmit } from '@app/components-legacy/Form/Wrappers'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import * as React from 'react'

interface IRolesCreateBladeFooterProps {}

const RolesCreateBladeFooter: React.FC<
  IRolesCreateBladeFooterProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Titles', 'Buttons', 'Management.Accounts.Roles']
  })

  const { storeManagementRbacRoles } = useStores()

  return (
    <ContainerFooter>
      <BladeCancelButton />

      <FormWrapperButtonSubmit
        loading={storeManagementRbacRoles.storeCreateRbacRoleFlags.isLoading}
      >
        {translate('Add')}
      </FormWrapperButtonSubmit>
    </ContainerFooter>
  )
}

export default RolesCreateBladeFooter
