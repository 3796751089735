import { ButtonExportAll } from '@app/components-legacy/Button'
import {
  ContainerFlex,
  ContainerFooter,
  ContainerRbac
} from '@app/components-legacy/Container'
import { handleDrawerOnOpen } from '@app/components-legacy/Drawer/handlers'
import {
  WidgetListBulkActions,
  WidgetListCountItems,
  WidgetListSelectAllCheckbox
} from '@app/components-legacy/Widgets/WidgetList'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import type { IDrawerIgnoreUntilData } from '@app/stores/IoE/StoreIndicator/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  canExportDeviantObjects,
  canSetIgnoreDateOnDeviantObjects
} from '../../permissions'
import { onDeviancesBulkActionSelection } from '../DeviancesList/handlers'
import { DeviantObjectBulkActionValue } from '../types'

interface IObservedDeviantObjectsFooterProps {
  className?: string
  checkerId: number
  checkerCodename: GenericCheckerCodename
}

const DeviantObjectsFooter: React.FC<
  IObservedDeviantObjectsFooterProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoE.Details.DeviantObjects']
  })

  const {
    storeIoE: {
      storeIndicator: { storeIndicatorDeviantObjects }
    }
  } = useStores()

  const { storeWidgetListDeviantObjects } = storeIndicatorDeviantObjects

  const drawerData: IDrawerIgnoreUntilData = {
    objectType: 'object',
    checkerId: props.checkerId,
    checkerCodename: props.checkerCodename,
    adObjectId: null
  }

  return (
    <ContainerFooter>
      <ContainerFlex
        name="ActionsLeft"
        alignItems="center"
        items={[
          <WidgetListSelectAllCheckbox
            storeWidgetList={storeWidgetListDeviantObjects}
          />,

          <WidgetListCountItems
            storeWidgetList={storeWidgetListDeviantObjects}
          />,

          <ContainerRbac rbacCapabilityCheck={canSetIgnoreDateOnDeviantObjects}>
            <WidgetListBulkActions
              options={[
                {
                  label: translate('Ignore selected objects'),
                  value: DeviantObjectBulkActionValue.ignore
                },
                {
                  label: translate('Stop ignoring selected objects'),
                  value: DeviantObjectBulkActionValue.unignore
                }
              ]}
              onChange={onDeviancesBulkActionSelection(
                storeIndicatorDeviantObjects
              )(drawerData)}
              width="large"
              storeWidgetList={storeWidgetListDeviantObjects}
            />
          </ContainerRbac>
        ]}
        spaced
      />

      <ContainerRbac rbacCapabilityCheck={canExportDeviantObjects}>
        <ButtonExportAll
          onClick={handleDrawerOnOpen(
            storeIndicatorDeviantObjects.storeDrawerExportObjects
          )}
        />
      </ContainerRbac>
    </ContainerFooter>
  )
}

export default observer(DeviantObjectsFooter)
