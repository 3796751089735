import type { Maybe } from '@@types/helpers'
import {
  BladeContentHeader,
  BladeContentMenu
} from '@app/components-legacy/Blade/BladeContent'
import BladeContentLayoutDefaultContent from '@app/components-legacy/Blade/BladeContent/BladeContentLayoutDefaultContent'
import BladeContentTitle from '@app/components-legacy/Blade/BladeContent/BladeContentTitle'
import type { IBladeLayoutDefault } from '@app/components-legacy/Blade/types'
import { ContainerFlex } from '@app/components-legacy/Container'
import type { IContainerFlexProps } from '@app/components-legacy/Container/ContainerFlex/types'
import type { IComponentLoaderProps } from '@app/components-legacy/types'
import { useStores } from '@app/hooks'
import { mergeFlags } from '@app/stores/helpers/StoreFlags/helpers'
import { ensureArray } from '@libs/ensureArray'
import { isDefined } from '@libs/isDefined'
import type { RbacCapabilityCheck } from '@libs/rbac/types'
import * as React from 'react'

interface IBladeContentLayoutFullProps {
  flags?: IComponentLoaderProps['flags']
  layout: IBladeLayoutDefault
  rbacCapabilityCheck: RbacCapabilityCheck
  scrollChildrenContainerOverflow?: React.CSSProperties['overflow']
  scrollChildrenContainerBoxShadow?: React.CSSProperties['boxShadow']
  paddingH?: Maybe<IContainerFlexProps['paddingH']>
  paddingV?: Maybe<IContainerFlexProps['paddingV']>
}

const BladeContentLayoutDefault: React.FC<
  IBladeContentLayoutFullProps
> = props => {
  const { storeRbac } = useStores()

  const rbacCapability = storeRbac.checkIfUserIsAbleTo(
    props.rbacCapabilityCheck
  )

  const flags = mergeFlags(...ensureArray(props.flags))

  const isGranted = rbacCapability.isGranted === true
  const isForbidden = flags.isForbidden === true
  const isError = flags.isError === true
  const isLoading = flags.isLoading === true

  return (
    <ContainerFlex
      name="BladeContentLayoutDefault"
      direction="column"
      items={[
        <BladeContentTitle isLoading={isLoading} layout={props.layout} />,

        <BladeContentHeader
          isLoading={isLoading}
          isGranted={isGranted}
          isError={isError}
          layout={props.layout}
        />,

        <BladeContentMenu
          // Keep the menu displayed even when the content is being loaded
          isLoading={false}
          isGranted={isGranted}
          isError={isError}
          layout={props.layout}
        />,

        <BladeContentLayoutDefaultContent
          isGranted={isGranted}
          isForbidden={isForbidden}
          isError={isError}
          isLoading={isLoading}
          rbacCapability={rbacCapability}
          layout={props.layout}
          scrollChildrenContainerOverflow={
            props.scrollChildrenContainerOverflow
          }
          scrollChildrenContainerBoxShadow={
            props.scrollChildrenContainerBoxShadow
          }
          paddingH={props.paddingH}
          paddingV={props.paddingV}
        />
      ].filter(isDefined)}
      fullHeight
    />
  )
}

export default BladeContentLayoutDefault
