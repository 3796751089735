import type { StoreTopology } from '@app/stores'
import type { SliderSingleProps } from 'antd/lib/slider'

/**
 * Hide or not internal trusts of forests.
 */
export const onHidingInternalTrustsChange =
  (storeTopology: StoreTopology) => (): StoreTopology => {
    return storeTopology.setShowingInternalTrusts(
      !storeTopology.isShowingInternalTrusts
    )
  }

/**
 * When ZoomSlider value change, save this value to the store for synchronization with d3 zoom.
 */
export const onZoomSliderChange =
  (storeTopology: StoreTopology): SliderSingleProps['onChange'] =>
  value => {
    if (!value) {
      return
    }
    const k = (value as number) / 1000
    storeTopology.setZoomSliderValue(k)
  }
