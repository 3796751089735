import { StoreInfrastructures } from '@app/stores'
import * as React from 'react'

/**
 * Initialize the drawer infrastructures.
 */
export function useDrawerInputInfrastructuresInit(
  storeInfrastructures: StoreInfrastructures
) {
  // create a new memoized storeInfrastructure for pending selection
  const storePendingInfrastructures = React.useMemo(() => {
    return new StoreInfrastructures(storeInfrastructures.storeRoot, {
      instanceName: storeInfrastructures.options.instanceName,
      emptySelectionAllowed: false
    })
  }, [])

  // when the drawer is opened, fetch the infrastructures
  // and select the same infras that the storeInfrastructures passed as param.
  React.useEffect(() => {
    if (!storeInfrastructures.storeDrawer.isDrawerOpened) {
      return
    }

    // save into the pending store, the fetchers of the passed store
    storePendingInfrastructures.setInfrastructures(
      Array.from(storeInfrastructures.infrastructures.values())
    )

    Promise.resolve()
      .then(() => {
        // fetch only if no infrastructure
        if (storePendingInfrastructures.infrastructures.size) {
          return Promise.resolve()
        }
        return storePendingInfrastructures.fetchInfrastructures()
      })
      .then(() => {
        storePendingInfrastructures.selectDirectories(
          storeInfrastructures.getSelectedDirectoryIds()
        )
      })
  }, [storeInfrastructures.storeDrawer.isDrawerOpened])

  return storePendingInfrastructures
}
