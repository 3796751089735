import { useStores } from '@app/hooks/useStores'
import { RbacEntityItemType } from '@libs/rbac/types'
import * as React from 'react'
import ListEntityItemsTypeSingleton from './ListEntityItems/ListEntityItemsTypeSingleton'

export interface IRoleEditEntitiesListsSingletonProps {
  disabled: boolean
}

const RoleEditEntitiesListsSingleton: React.FC<
  IRoleEditEntitiesListsSingletonProps
> = props => {
  const { storeManagementRbacRoles } = useStores()

  const storeWidgetList =
    storeManagementRbacRoles.storesWidgetListEntityItems.get(
      RbacEntityItemType.singleton
    )

  if (!storeWidgetList) {
    return null
  }

  return (
    <ListEntityItemsTypeSingleton
      rbacEntityItemType={RbacEntityItemType.singleton}
      disabled={props.disabled}
      storeWidgetList={storeWidgetList}
    />
  )
}

export default RoleEditEntitiesListsSingleton
