import type { MaybeUndef } from '@@types/helpers'
import type { EventId } from '@app/entities/EntityEvent'
import type { StoreTrailFlow } from '@app/stores'

/**
 * When loading the deviances for an event,
 *
 * Load checkers for the list,
 * Load reasons,
 * Once done, load related deviances.
 */
export const onEventDetailsDeviancesLoad =
  (storeTrailFlow: StoreTrailFlow) =>
  (infrastructureId: number, directoryId: number, eventId: EventId) =>
  () => {
    const { storeEventDetailsDeviances } = storeTrailFlow.storeEventDetails

    const { storeInputCheckersExposure, storeInputReasons } =
      storeEventDetailsDeviances

    return Promise.all([
      storeInputCheckersExposure.fetchExposureCheckers(),
      storeInputReasons.fetchReasons()
    ])
      .then(() => {
        storeInputCheckersExposure.showAllCheckers().selectAllCheckers()
      })
      .then(() => {
        const args =
          storeEventDetailsDeviances.computeFetchIncriminatingAttributesArgs(
            infrastructureId,
            directoryId,
            eventId
          )

        if (!args) {
          return
        }

        storeEventDetailsDeviances.fetchIncriminatingAttributes(args)
      })
  }

export const onEventDetailsDeviancesUnload =
  (storeTrailFlow: StoreTrailFlow) => () => {
    storeTrailFlow.storeEventDetails.storeEventDetailsDeviances.reset()
  }

export const onDeviancePageChange =
  (storeTrailFlow: StoreTrailFlow) =>
  (infrastructureId: number, directoryId: number, eventId: EventId) =>
  (page: number, perPage: MaybeUndef<number>) => {
    const { storeEventDetailsDeviances } = storeTrailFlow.storeEventDetails

    const args =
      storeEventDetailsDeviances.computeFetchIncriminatingAttributesArgs(
        infrastructureId,
        directoryId,
        eventId,
        {
          incriminatingAttributesPage: page,
          incriminatingAttributesPerPage: perPage
        }
      )

    if (!args) {
      return
    }

    storeEventDetailsDeviances.reloadIncriminatingAttributes(args)
  }

/**
 * When checkers are validated, reload the incriminated attributes.
 */
export const onCheckerValidation =
  (storeTrailFlow: StoreTrailFlow) =>
  (infrastructureId: number, directoryId: number, eventId: EventId) =>
  () => {
    const { storeEventDetailsDeviances } = storeTrailFlow.storeEventDetails

    const args =
      storeEventDetailsDeviances.computeFetchIncriminatingAttributesArgs(
        infrastructureId,
        directoryId,
        eventId
      )

    if (!args) {
      return
    }

    storeEventDetailsDeviances.reloadIncriminatingAttributes(args)
  }

/**
 * When reasons are validated, reload the incriminated attributes.
 */
export const onReasonsValidation =
  (storeTrailFlow: StoreTrailFlow) =>
  (infrastructureId: number, directoryId: number, eventId: EventId) =>
  () => {
    const { storeEventDetailsDeviances } = storeTrailFlow.storeEventDetails

    const args =
      storeEventDetailsDeviances.computeFetchIncriminatingAttributesArgs(
        infrastructureId,
        directoryId,
        eventId
      )

    if (!args) {
      return
    }

    storeEventDetailsDeviances.fetchIncriminatingAttributes(args)
  }

/**
 * Reload incriminated attributes.
 */
export const reloadIncriminatedAttributes =
  (storeTrailFlow: StoreTrailFlow) =>
  (infrastructureId: number, directoryId: number, eventId: EventId) =>
  () => {
    const { storeEventDetailsDeviances } = storeTrailFlow.storeEventDetails

    const args =
      storeEventDetailsDeviances.computeFetchIncriminatingAttributesArgs(
        infrastructureId,
        directoryId,
        eventId
      )

    if (!args) {
      return
    }

    storeEventDetailsDeviances.reloadIncriminatingAttributes(args)
  }
