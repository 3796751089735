import type { MaybeUndef, Maybe } from '@@types/helpers'
import type { StoreRbac } from '@app/stores'
import { explodeWidgetKey } from '@libs/dashboards/keys'
import type { WidgetKey } from '@libs/dashboards/types'
import {
  rbacCapabilityCheckAllOf,
  rbacCapabilityCheckAnyOf
} from '@libs/rbac/functions'
import {
  createDashboard,
  createDashboardWidget,
  deleteDashboard,
  deleteDashboardWidget,
  editDashboard,
  editDashboardWidget,
  readDashboards,
  readLicenseFeatureIoA,
  readLicenseFeatureIoE,
  readUIDashboard
} from '@libs/rbac/permissions'

/* Dashboards */

export const canAccessToDashbards = rbacCapabilityCheckAllOf(
  readUIDashboard(),
  rbacCapabilityCheckAnyOf(readLicenseFeatureIoE(), readLicenseFeatureIoA()),
  readDashboards()
)

export const canCreateDashbard = rbacCapabilityCheckAllOf(
  canAccessToDashbards,
  createDashboard()
)

export const canEditDashbard = (dashboardId: Maybe<number>) =>
  rbacCapabilityCheckAllOf(canAccessToDashbards, editDashboard(dashboardId))

export const canConfigureDashboard = (dashboardId: number) =>
  rbacCapabilityCheckAllOf(
    canAccessToDashbards,
    rbacCapabilityCheckAnyOf(
      editDashboard(dashboardId),
      deleteDashboard(dashboardId)
    )
  )

export const canDeleteDashbard = (dashboardId: Maybe<number>) =>
  rbacCapabilityCheckAllOf(canAccessToDashbards, deleteDashboard(dashboardId))

/* Dashboards Widgets */

export const canAddDashboardOrWidget = rbacCapabilityCheckAllOf(
  canAccessToDashbards,
  rbacCapabilityCheckAnyOf(createDashboard(), createDashboardWidget())
)

export const canCreateDashboardWidget = rbacCapabilityCheckAllOf(
  canAccessToDashbards,
  createDashboardWidget()
)

export const canEditDashboardWidget = rbacCapabilityCheckAllOf(
  canAccessToDashbards,
  editDashboardWidget()
)

export const canEditDashboardOrWidget = (dashboardWidgetId: number) =>
  rbacCapabilityCheckAllOf(
    canAccessToDashbards,
    rbacCapabilityCheckAnyOf(
      createDashboard(),
      editDashboardWidget(dashboardWidgetId)
    )
  )

export const canRemoveDashboardWidget = (dashboardWidgetId: Maybe<number>) =>
  rbacCapabilityCheckAllOf(
    canAccessToDashbards,
    deleteDashboardWidget(dashboardWidgetId)
  )

export const canSelectDashboardWidgetsRelatedToDeviances =
  rbacCapabilityCheckAllOf(readLicenseFeatureIoE())

/* Check RBAC capabilities according to the current storeRbac configuration. */

/**
 * Return true if the user is allowed to refresh the widget.
 */
export const canRefreshWidget =
  (storeRbac: StoreRbac) =>
  (widgetKey: MaybeUndef<WidgetKey>): boolean => {
    if (!widgetKey) {
      return false
    }

    const widgetKeyDetails = explodeWidgetKey(widgetKey)

    if (!widgetKeyDetails) {
      return false
    }

    const { dashboardWidgetId } = widgetKeyDetails

    return storeRbac.isUserGrantedTo(editDashboardWidget(dashboardWidgetId))
  }

/**
 * Return true if the user is allowed to edit the widget.
 */
export const canEditWidget =
  (storeRbac: StoreRbac) =>
  (widgetKey: MaybeUndef<WidgetKey>): boolean => {
    if (!widgetKey) {
      return false
    }

    const widgetKeyDetails = explodeWidgetKey(widgetKey)

    if (!widgetKeyDetails) {
      return false
    }

    const { dashboardWidgetId } = widgetKeyDetails

    return storeRbac.isUserGrantedTo(editDashboardWidget(dashboardWidgetId))
  }

/**
 * Return true if the user is allowed to delete the widget.
 */
export const canDeleteWidget =
  (storeRbac: StoreRbac) =>
  (widgetKey: MaybeUndef<WidgetKey>): boolean => {
    if (!widgetKey) {
      return false
    }

    const widgetKeyDetails = explodeWidgetKey(widgetKey)

    if (!widgetKeyDetails) {
      return false
    }

    const { dashboardWidgetId } = widgetKeyDetails

    return storeRbac.isUserGrantedTo(deleteDashboardWidget(dashboardWidgetId))
  }
