import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'

interface IIconBarChartProps extends IIconProps {}

const IconBarChart: React.FC<IIconBarChartProps> = props => {
  const size = props.size ?? 60

  return (
    <svg
      className={props.className}
      style={props.style}
      width={size}
      height={size}
      viewBox="0 0 60 60"
    >
      <path
        d="M0 41C0 39.8954 0.895431 39 2 39H13C14.1046 39 15 39.8954 15 41V60H0V41Z"
        fill="#8BC1F9"
      />
      <path
        d="M15 17C15 15.8954 15.8954 15 17 15H28C29.1046 15 30 15.8992 30 17.0037C30 33.3467 30 43.1055 30 60H15V17Z"
        fill="#58A8FA"
      />
      <path
        d="M30 2C30 0.895432 30.8954 0 32 0H43C44.1046 0 45 0.895431 45 2V60H30V2Z"
        fill="#2182E4"
      />
      <path
        d="M45 26C45 24.8954 45.8954 24 47 24H58C59.1046 24 60 24.8954 60 26V60H45V26Z"
        fill="#055CB7"
      />
    </svg>
  )
}

export default IconBarChart
