import type {
  StoreManagementProfiles,
  StoreManagementReportingCenter
} from '@app/stores'
import type { MaybeUndef } from '@productive-codebases/toolbox'
import { action } from 'mobx'
import { ReportAction } from './types'

/**
 * Load reports at loading.
 */
export const handleConfigurationReportingOnLoad =
  (storeManagementReportingCenter: StoreManagementReportingCenter) =>
  (storeManagementProfiles: StoreManagementProfiles) =>
    action(() => {
      storeManagementReportingCenter.storeInputCheckersExposure.fetchExposureCheckers()
      storeManagementReportingCenter.storeInputCheckersAttacks.fetchAttackCheckers()

      storeManagementReportingCenter.storeInfrastructures.fetchInfrastructures()

      storeManagementProfiles.fetchProfiles()

      storeManagementReportingCenter.fetchReports({
        reportsPage: 1,
        reportsPerPage:
          storeManagementReportingCenter.storeWidgetList.rowsPerPage
      })

      storeManagementReportingCenter.fetchReportAccessToken()
    })

/**
 * Open drawer to create a report.
 */
export const handleCreateReportOnClick = (
  storeManagementReportingCenter: StoreManagementReportingCenter
) =>
  action(() => {
    storeManagementReportingCenter.setCurrentReportAction(ReportAction.Creation)
    storeManagementReportingCenter.storeDrawerReportAction.openDrawer()
  })

/**
 * Handle reports page change.
 */
export const handleReportsPageOnChange =
  (storeManagementReports: StoreManagementReportingCenter) =>
  (page: number, pageSize: MaybeUndef<number>) => {
    storeManagementReports.fetchReports({
      reportsPage: page,
      reportsPerPage: pageSize
    })
  }
