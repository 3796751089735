import { ContainerContent } from '@app/components-legacy/Container'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { EventDetailsPageContext } from '../contexts'
import { canSeeDeviances } from '../permissions'
import EventDetailDeviancesTable from './EventDetailDeviancesTable'
import {
  onEventDetailsDeviancesLoad,
  onEventDetailsDeviancesUnload
} from './handlers'

interface IEventDetailsDeviancesPageProps {
  className?: string
}

const EventDetailsDeviancesPage: React.FC<
  IEventDetailsDeviancesPageProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['TrailFlow.EventDetails.Deviances']
  })

  const { storeTrailFlow } = useStores()

  const { storeEventDetailsDeviances } = storeTrailFlow.storeEventDetails

  const parameters = React.useContext(EventDetailsPageContext)

  if (!parameters) {
    return null
  }

  return (
    <ContainerContent
      title={translate('Deviances')}
      className={props.className}
      onLoad={onEventDetailsDeviancesLoad(storeTrailFlow)(
        parameters.infrastructureId,
        parameters.directoryId,
        parameters.eventId
      )}
      onUnload={onEventDetailsDeviancesUnload(storeTrailFlow)}
      flags={[
        storeEventDetailsDeviances.storeFetchIncriminatingAttributesFlags.flags,
        storeEventDetailsDeviances.storeInputCheckersExposure
          .storeFlagsFetchCheckers.flags,
        storeEventDetailsDeviances.storeInputReasons.storeFlagsFetchReasons
          .flags
      ]}
      rbacCapabilityCheck={canSeeDeviances}
    >
      <EventDetailDeviancesTable />
    </ContainerContent>
  )
}

const ObservedEventDetailsDeviancesPage = observer(EventDetailsDeviancesPage)

/*
 * Hack the margin-bottom of the title display in the blade, to mitigate the blank space.
 * See https://github.com/AlsidOfficial/AlsidForAD-Cassiopeia/issues/4606
 */
export default styled(ObservedEventDetailsDeviancesPage)`
  & .container-content-title {
    margin-bottom: 0;
  }
`
