import { ContainerContent } from '@app/components-legacy/Container'
import { handleInputInfrastructuresOnLoad } from '@app/components-legacy/Input/InputInfrastructures/handlers'
import SpinnerInline, {
  SpinnerInlineSize
} from '@app/components-legacy/Spinner/SpinnerInline'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { canCreateDirectories } from '@app/pages/Management/SystemPage/Directories/permissions'
import { canCreateInfrastructures } from '@app/pages/Management/SystemPage/Infrastructures/permissions'
import { AppRouteName } from '@app/routes'
import type StoreInfrastructures from '@app/stores/StoreInfrastructures'
import type { BaseButtonProps } from 'antd/lib/button/button'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Link } from 'react-router-dom'
import InputInfrastructuresButton from './InputInfrastructuresButton'

interface IInputInfrastructuresProps {
  className?: string
  style?: React.CSSProperties
  size?: BaseButtonProps['size']
  onValidate?: () => void
  loadAtMount?: boolean
  storeInfrastructures: StoreInfrastructures
}

const InputInfrastructures: React.FC<IInputInfrastructuresProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Components.InputInfrastructures']
  })

  const appRouter = useAppRouter()

  const { storeRbac } = useStores()

  const renderContent = () => {
    if (
      !props.storeInfrastructures.infrastructures.size &&
      storeRbac.isUserGrantedTo(canCreateInfrastructures)
    ) {
      const url = appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.Management_System_Infrastructures_Create,
        parameters: {}
      })

      return (
        <div>
          {translate('No forest found')}{' '}
          <Link to={url}>{translate('Add a forest')}</Link>
        </div>
      )
    }

    if (
      !props.storeInfrastructures.hasDirectories &&
      storeRbac.isUserGrantedTo(canCreateDirectories)
    ) {
      const url = appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.Management_System_Directories_Create,
        parameters: {}
      })

      return (
        <div>
          {translate('No domain found')}{' '}
          <Link to={url}>{translate('Add a domain')}</Link>
        </div>
      )
    }

    return (
      <InputInfrastructuresButton
        size={props.size}
        onSubmit={props.onValidate}
        storeInfrastructures={props.storeInfrastructures}
      />
    )
  }

  return (
    <ContainerContent
      name={InputInfrastructures.name}
      onLoad={handleInputInfrastructuresOnLoad(props.storeInfrastructures)(
        props.loadAtMount
      )}
      flags={props.storeInfrastructures.storeFlagsFetchInfrastructures.flags}
      spinner={<SpinnerInline size={SpinnerInlineSize.small} />}
    >
      {/* Wrap with a div to avoid to have a full width button */}
      <div>{renderContent()}</div>
    </ContainerContent>
  )
}

export default observer(InputInfrastructures)
