import { ContainerContent } from '@app/components-legacy/Container'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled, { css } from 'styled-components'
import Grid from './Grid'
import GridAddFirstWidget from './GridAddFirstWidget'
import { onAddWidgetButtonClick } from './handlers'

export interface IGridPageProps {}

const ContainerContentCustom = styled(ContainerContent)<{ size: number }>`
  ${props =>
    !isThemeLegacy(props.theme) &&
    props.size > 0 &&
    css`
      padding: 0 40px;
    `}
`

const GridPage: React.FC<IGridPageProps> = () => {
  const appRouter = useAppRouter()

  const { storeDashboards } = useStores()

  const storeDashboard = storeDashboards.currentStoreDashboard

  if (!storeDashboard) {
    return null
  }

  const renderContent = () => {
    if (!storeDashboard.storeWidgets.size) {
      return (
        <GridAddFirstWidget
          onAddFirstWidgetButton={onAddWidgetButtonClick(appRouter)}
        />
      )
    }

    return <Grid storeDashboard={storeDashboard} />
  }

  return (
    <ContainerContentCustom
      data-name="Grid"
      flags={storeDashboard.storeFlags.flags}
      size={storeDashboard.storeWidgets.size}
    >
      {renderContent()}
    </ContainerContentCustom>
  )
}

export default observer(GridPage)
