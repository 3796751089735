import type { ApplicationError } from '.'
import type { ApplicationErrorValue } from './types'
import { ErrorName } from './types'

/**
 * Typeguard to validate than err is of type T.
 *
 * Usage:
 * if (isErrorOfType<HardReloadError>(err, ErrorName.HardReloadError)) {
 *   err is considered as HardReloadError
 * }
 */
export function isErrorOfType<T>(err: any, errorName: ErrorName): err is T {
  if (!(err instanceof Error)) {
    return false
  }

  return err.name === errorName
}

/**
 * Typeguard to validate that err is an ApplicationError with the errorValue ApplicationErrorValue.
 *
 * Usage:
 * if (isApplicationError(err, ApplicationErrorValue.devianceNotFound)) {
 *   err is considered as an ApplicationError for devianceNotFound error value
 * }
 */
export function isApplicationError(
  err: any,
  errorValue: ApplicationErrorValue
): err is ApplicationError {
  return (
    isErrorOfType<ApplicationError>(err, ErrorName.ApplicationError) &&
    err.getMessage().errorValue === errorValue
  )
}
