import type { IBladeHeaderInfo } from '@app/components-legacy/Blade/BladeHeader'
import BladeHeader from '@app/components-legacy/Blade/BladeHeader'
import {
  LabelCompliance,
  LabelCriticity,
  LabelDate
} from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { filterFalsies } from '@libs/filterFalsies'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckerIdentityDetailsPageContext } from './context'
import { canReadIoEIdentityComplianceStatus } from './permissions'

export interface IDetailsBladeHeaderProps {}

const DetailsBladeHeader: React.FC<IDetailsBladeHeaderProps> = () => {
  const parameters = React.useContext(CheckerIdentityDetailsPageContext)

  const translate = useAppTranslator({ namespaces: ['Buttons', 'IoE.Details'] })

  const { storeRbac, storeIoE } = useStores()

  if (!parameters) {
    return null
  }

  const getInfos = (): IBladeHeaderInfo[] => {
    const checker = storeIoE.storeIndicator.checkerIdentity

    if (!checker) {
      return []
    }

    const latestDetectionDate = checker.latestDetectionDate ? (
      <LabelDate date={checker.latestDetectionDate} />
    ) : (
      translate('No finding')
    )

    return filterFalsies([
      {
        rawType: 'Name',
        type: translate('Name'),
        value: checker.name
      },
      {
        rawType: 'Criticity',
        type: translate('Criticity'),
        value: <LabelCriticity criticity={checker.genericCriticity} />
      },
      storeRbac.isUserGrantedTo(canReadIoEIdentityComplianceStatus) && {
        rawType: 'Status',
        type: translate('Status'),
        value: <LabelCompliance enabled compliant={!checker.hasFindings} />
      },
      {
        rawType: 'Latest detection',
        type: translate('Latest detection'),
        value: latestDetectionDate
      }
    ])
  }

  return <BladeHeader icon="identity" infos={getInfos()} />
}

export default observer(DetailsBladeHeader)
