/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface DescriptionTemplateReplacementsInner
 */
export interface DescriptionTemplateReplacementsInner {
  /**
   * Name of the finding incriminating attribute
   * @type {string}
   * @memberof DescriptionTemplateReplacementsInner
   */
  name: string
  /**
   * JSON-stringified value of the finding incriminating attribute
   * @type {string}
   * @memberof DescriptionTemplateReplacementsInner
   */
  value: string
  /**
   * Type of the finding incriminating attribute
   * @type {string}
   * @memberof DescriptionTemplateReplacementsInner
   */
  valueType: DescriptionTemplateReplacementsInnerValueTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum DescriptionTemplateReplacementsInnerValueTypeEnum {
  String = 'STRING',
  Regex = 'REGEX',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Object = 'OBJECT',
  Sddl = 'SDDL',
  Arraystring = 'ARRAYSTRING',
  Arrayregex = 'ARRAYREGEX',
  Arrayinteger = 'ARRAYINTEGER',
  Arrayboolean = 'ARRAYBOOLEAN',
  Arrayselect = 'ARRAYSELECT',
  Arrayobject = 'ARRAYOBJECT',
  Arraycron = 'ARRAYCRON'
}

/**
 * Check if a given object implements the DescriptionTemplateReplacementsInner interface.
 */
export function instanceOfDescriptionTemplateReplacementsInner(
  value: object
): boolean {
  if (!('name' in value)) return false
  if (!('value' in value)) return false
  if (!('valueType' in value)) return false
  return true
}

export function DescriptionTemplateReplacementsInnerFromJSON(
  json: any
): DescriptionTemplateReplacementsInner {
  return DescriptionTemplateReplacementsInnerFromJSONTyped(json, false)
}

export function DescriptionTemplateReplacementsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DescriptionTemplateReplacementsInner {
  if (json == null) {
    return json
  }
  return {
    name: json['name'],
    value: json['value'],
    valueType: json['value_type']
  }
}

export function DescriptionTemplateReplacementsInnerToJSON(
  value?: DescriptionTemplateReplacementsInner | null
): any {
  if (value == null) {
    return value
  }
  return {
    name: value['name'],
    value: value['value'],
    value_type: value['valueType']
  }
}
