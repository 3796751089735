import type { MaybeUndef, PropertiesNullable } from '@@types/helpers'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import type {
  CrawlingStatus,
  Directory,
  Event,
  FailureReason,
  HoneyAccountStatus,
  Infrastructure,
  Maybe,
  Relay
} from '@server/graphql/typeDefs/types'
import { createEntity, EntityInfrastructure } from '.'
import EntityBase from './EntityBase'
import type { IEntityListable, IEntityOmitKeysParameter } from './types'

export const DIRECTORY_DEFAULT_LDAP_PORT = 389
export const DIRECTORY_DEFAULT_GLOBAL_CATALOG_PORT = 3268
export const DIRECTORY_DEFAULT_SMB_PORT = 445
export const DIRECTORY_DEFAULT_TYPE = 'ADDS'

export interface IDataRowDirectory {
  id: number
  name: string
  forest: string
  ip: string
  ldapCrawlingStatus: CrawlingStatus
  sysvolCrawlingStatus: CrawlingStatus
  honeyAccountConfigurationStatus: Maybe<HoneyAccountStatus>
  sensitiveDataCollectionStatus: CrawlingStatus
}

export default class EntityDirectory
  extends EntityBase
  implements PropertiesNullable<Directory>, IEntityListable<IDataRowDirectory>
{
  id: Maybe<number> = null
  name: Maybe<string> = null
  ip: Maybe<string> = null
  type: Maybe<string> = null
  ldapPort: Maybe<number> = null
  globalCatalogPort: Maybe<number> = null
  smbPort: Maybe<number> = null
  dns: Maybe<string> = null
  ldapCrawlingStatus: Maybe<CrawlingStatus> = null
  sysvolCrawlingStatus: Maybe<CrawlingStatus> = null
  honeyAccountAdObjectId: Maybe<number> = null
  honeyAccountDistinguishedName: Maybe<string> = null
  honeyAccountConfigurationStatus: Maybe<HoneyAccountStatus> = null
  infrastructureId: Maybe<number> = null
  relayId: Maybe<number> = null
  sensitiveDataCollectionIsEnabled: boolean = false
  sensitiveDataCollectionStatus: Maybe<CrawlingStatus> = null

  infrastructure: Maybe<Infrastructure> = null
  relay: Maybe<Relay> = null
  ioaProcedureInstalled: Maybe<boolean> = null
  ioaProcedureFailureReason: Maybe<FailureReason> = null
  ioaProcedureFailureDescription: Maybe<string> = null
  directoryUuid: Maybe<string> = null
  // singular because the GraphQL allows to fetch only one event
  event: Maybe<Event> = null

  // save one color per directory (derived from the infrastructure color)
  color: string = '#000000'

  constructor(data: Partial<Directory>) {
    super()
    Object.assign(this, data)
  }

  setColor(color: string): void {
    this.color = color
  }

  setLdapCrawlingStatus(status: CrawlingStatus): this {
    this.ldapCrawlingStatus = status
    return this
  }

  setSysvolCrawlingStatus(status: CrawlingStatus): this {
    this.sysvolCrawlingStatus = status
    return this
  }

  setHoneyAccountConfigurationStatus(status: HoneyAccountStatus): this {
    this.honeyAccountConfigurationStatus = status
    return this
  }

  setSensitiveDataCollectionStatus(status: CrawlingStatus): this {
    this.sensitiveDataCollectionStatus = status
    return this
  }

  getInfrastructure(): MaybeUndef<EntityInfrastructure> {
    if (!this.infrastructure) {
      return
    }

    return createEntity<Infrastructure, EntityInfrastructure>(
      EntityInfrastructure,
      this.infrastructure
    )
  }

  /* Implements IEntityListable */

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowDirectory> = []
  ): Array<IWidgetListColumns<IDataRowDirectory>> {
    const columns: Array<IWidgetListColumns<IDataRowDirectory>> = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Name',
        key: 'name'
      },
      {
        label: 'Forest',
        key: 'forest'
      },
      {
        label: 'IP address or hostname',
        key: 'ip'
      },
      {
        label: 'LDAP initialization status',
        key: 'ldapCrawlingStatus'
      },
      {
        label: 'SYSVOL initialization status',
        key: 'sysvolCrawlingStatus'
      },
      {
        label: 'Privileged analysis',
        key: 'sensitiveDataCollectionStatus'
      },
      {
        label: 'Honey account configuration status',
        key: 'honeyAccountConfigurationStatus'
      }
    ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  asDataRow(): IDataRowDirectory {
    const infrastructure = this.getInfrastructure()
    const forest = infrastructure && infrastructure.getPropertyAsString('name')

    return {
      id: this.getPropertyAsNumber('id'),
      name: this.getPropertyAsString('name'),
      forest: forest || '-',
      ip: this.getPropertyAsString('ip'),
      ldapCrawlingStatus: this.getPropertyAsT('ldapCrawlingStatus'),
      sysvolCrawlingStatus: this.getPropertyAsT('sysvolCrawlingStatus'),
      sensitiveDataCollectionStatus: this.getPropertyAsT(
        'sensitiveDataCollectionStatus'
      ),
      honeyAccountConfigurationStatus: this.honeyAccountConfigurationStatus
    }
  }
}
