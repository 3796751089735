import { ContainerContent, ContainerFlex } from '@app/components/Container'
import { IconLeft } from '@app/components/Icon'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useAppRouter, useAppTranslator, useStores } from '@app/hooks'
import {
  handleAttackPathTier0Load,
  handleAttackPathTier0Unload
} from '@app/pages/AttackPath/SceneBlade/NodeFinder/Tier0/handlers'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import AttackPathTier0Content from './AttackPathTier0Content'

const StyledLink = styled(Link)`
  display: inline-flex;

  :hover {
    text-decoration: none;
  }
`

interface IAttackPathTier0PageProps {}

const AttackPathTier0Page: React.FC<IAttackPathTier0PageProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.Tier0']
  })

  const appRouter = useAppRouter()

  const { storeAttackPath } = useStores()

  const attackPathUrl = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.AttackPath,
    parameters: {}
  })

  return (
    <ContainerContent
      onLoad={handleAttackPathTier0Load(storeAttackPath.storeAttackPathTier0)}
      onUnload={handleAttackPathTier0Unload(
        storeAttackPath.storeAttackPathTier0
      )}
    >
      <ContainerFlex
        name="AttackPathTier0Page"
        direction="column"
        items={[
          <div>
            <StyledLink to={attackPathUrl}>
              <FormWrapperButton
                labelledBy="back-to-search"
                variant={ButtonVariant.secondary}
                size={ButtonSize.small}
                icon={IconLeft}
              >
                {translate('Back to Attack Path search')}
              </FormWrapperButton>
            </StyledLink>
          </div>,

          <AttackPathTier0Content />
        ]}
        spaced
      />
    </ContainerContent>
  )
}

export default observer(AttackPathTier0Page)
