import { FormWrapperButton } from '@app/components-legacy/Form'
import {
  WidgetListActionButtons,
  WidgetListActionsHeader
} from '@app/components-legacy/Widgets/WidgetList'
import { useStores } from '@app/hooks'
import * as React from 'react'

export interface IHeaderProps {}

const Header: React.FC<IHeaderProps> = props => {
  const { storeAPIInspector } = useStores()

  const onResetClick = () => {
    storeAPIInspector.resetList()
  }

  return (
    <WidgetListActionsHeader>
      <WidgetListActionButtons>
        <FormWrapperButton
          labelledBy="reset"
          buttonProps={{
            type: 'primary',
            onClick: onResetClick
          }}
        >
          Reset
        </FormWrapperButton>
      </WidgetListActionButtons>
    </WidgetListActionsHeader>
  )
}

export default Header
