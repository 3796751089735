import type StoreAttacks from '@app/stores/IoA/StoreAttacks'
import {
  closeOrReopenAttacksBulk,
  closeOrReopenSelectedAttacks
} from '../IoAAttacksFooter/functions'

/**
 * Confirm the closing of selected attacks.
 */
export const onAttackCloseActionModalConfirmClick =
  (storeAttacks: StoreAttacks) => () => {
    const { storeWidgetListAttacks } = storeAttacks

    storeWidgetListAttacks.isAllRowsSelected
      ? closeOrReopenAttacksBulk(storeAttacks)({ isClosed: true })
      : closeOrReopenSelectedAttacks(storeAttacks)({ isClosed: true })
  }

/**
 * Cancel the closing from the modal.
 */
export const onAttackCloseActionModalCancelClick =
  (storeAttacks: StoreAttacks) => (): void => {
    storeAttacks.storeModalConfirmCloseAction.hide()
  }
