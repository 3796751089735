import { AppRouteName } from '@app/routes'
import type { StoreBlades, StoreManagementDirectories } from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'
import type { InputUpdateHoneyAccount } from '@server/graphql/typeDefs/types'
import { debounce } from 'lodash'
import { HoneyAccountFormFieldName } from './types'
import { isMinLengthReached } from './utils'

/**
 * Reset the form at loading.
 */
export const onHoneyAccountCreatePageLoad =
  (storeManagementDirectories: StoreManagementDirectories) =>
  (directoryId: number) =>
  () => {
    Promise.resolve()
      .then(() => storeManagementDirectories.storeInputSearch.reset())
      .then(() => storeManagementDirectories.storeFormHoneyAccount.hardReset())
      .then(() => {
        return !storeManagementDirectories.directories.size
          ? storeManagementDirectories.fetchDirectories()
          : null
      })
      .then(() => {
        return !storeManagementDirectories.honeyAccountConfigurationScript.get(
          directoryId
        )
          ? storeManagementDirectories.getHoneyAccountConfigurationScript({
              directoryId
            })
          : null
      })
  }

/**
 * Create the Honey Account,
 * And go back to the list.
 */
export const onHoneyAccountCreateSubmit =
  (
    appRouter: AppRouterClient,
    storeManagementDirectories: StoreManagementDirectories
  ) =>
  (directoryId: number) =>
  (e: React.FormEvent<any>) => {
    const { storeFormHoneyAccount } = storeManagementDirectories

    e.preventDefault()

    if (!storeFormHoneyAccount.validate()) {
      storeFormHoneyAccount.storeRoot.stores.storeMessages.validationError()
      return
    }

    const honeyAccount: InputUpdateHoneyAccount = {
      directoryId,
      honeyAccountDistinguishedName:
        storeFormHoneyAccount.getFieldValueAsString(
          HoneyAccountFormFieldName.name
        )
    }

    storeManagementDirectories.updateHoneyAccount(honeyAccount).then(() => {
      const url = appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.Management_System_Directories,
        parameters: {}
      })
      appRouter.history.push(url)
    })
  }

/**
 * Redirect to the previous view.
 */
export const onHoneyAccountCreateCancelClick =
  (storeBlades: StoreBlades) => () => {
    const url = storeBlades.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Directories,
      parameters: {}
    })

    storeBlades.goToBladeUrl(url)
  }

/**
 * Search for a Honey Account.
 */
const handleHoneyAccountSearch = (
  storeManagementDirectories: StoreManagementDirectories,
  directoryId: number,
  distinguishedName: string
) => {
  storeManagementDirectories.searchHoneyAccountCandidates({
    directoryId,
    name: distinguishedName
  })
}

/**
 * Search for a Honey Account with debounce.
 */
export const handleHoneyAccountSearchDebounced = debounce(
  handleHoneyAccountSearch,
  500
)

/**
 * Search for a Honey Account when input changes.
 */
export const handleHoneyAccountInputSearch =
  (storeManagementDirectories: StoreManagementDirectories) =>
  (directoryId: number) =>
  (distinguishedName: string) => {
    storeManagementDirectories.storeInputSearch.setSearchValue(
      distinguishedName
    )

    if (!isMinLengthReached(distinguishedName)) {
      // Clear the list before a new search to display the loading message.
      storeManagementDirectories.removeHoneyAccountCandidates(directoryId)
      return
    }

    handleHoneyAccountSearchDebounced(
      storeManagementDirectories,
      directoryId,
      distinguishedName
    )
  }

export const handleHoneyAccountInputSelect =
  (storeManagementDirectories: StoreManagementDirectories) =>
  (distinguishedName: string) => {
    storeManagementDirectories.storeInputSearch.setSearchValue(
      distinguishedName
    )
  }
