import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  ContainerContent,
  ContainerFooter
} from '@app/components-legacy/Container'
import { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import {
  Drawer,
  DrawerCancelButton,
  DrawerContext,
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { IoABoardPageContext } from '../context'
import { canAccessToIoAExport } from '../permissions'
import { ExportFormat, ExportFormFieldName } from '../types'
import { handleExportIoABoardOnSubmit } from './handlers'

export interface IIoABoardDrawerExportProps {}

const IoABoardDrawerExport: React.FC<IIoABoardDrawerExportProps> = () => {
  const ioaBoardPageContext = React.useContext(IoABoardPageContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Export', 'IoA.Board', 'IoA.BoardPDF']
  })

  if (!ioaBoardPageContext) {
    return null
  }

  const { storeBoard } = ioaBoardPageContext

  const renderContent = () => {
    return (
      <FormWrapper
        name="IoABoardExport"
        onSubmit={handleExportIoABoardOnSubmit(translate)(storeBoard)}
      >
        <ContainerContent>
          <p>
            {translate('You are exporting the cards for the current context')}
          </p>

          <ContainerContent
            flags={storeBoard.storeFlagsExport.flags}
            spinner={<SpinnerInline />}
          >
            <WidgetForm<ExportFormFieldName>
              translate={translate}
              storeForm={storeBoard.storeFormExport}
              fieldsParams={{
                [ExportFormFieldName.exportFormat]: {
                  inputType: InputType.select,
                  inputProps: {
                    labelledBy: 'export',
                    selectProps: {
                      value: storeBoard.storeFormExport.getFieldValueAsString(
                        ExportFormFieldName.exportFormat
                      )
                    },
                    selectOptions: [
                      {
                        value: ExportFormat.pdf,
                        label: 'PDF'
                      },
                      {
                        value: ExportFormat.csv,
                        label: 'CSV'
                      },
                      {
                        value: ExportFormat.pptx,
                        label: 'PPTX'
                      }
                    ]
                  }
                },
                [ExportFormFieldName.exportUtc]: {
                  inputType: InputType.checkbox,
                  inputProps: {
                    checked:
                      storeBoard.storeFormExport.getFieldValueAsBoolean(
                        ExportFormFieldName.exportUtc
                      ) ||
                      storeBoard.storeFormExport.getFieldValueAsString<ExportFormat>(
                        ExportFormFieldName.exportFormat
                      ) === ExportFormat.csv,
                    disabled:
                      storeBoard.storeFormExport.getFieldValueAsString<ExportFormat>(
                        ExportFormFieldName.exportFormat
                      ) === ExportFormat.csv
                  }
                }
              }}
              variant={ContainerFormVariant.drawer}
            />
          </ContainerContent>
        </ContainerContent>

        <Portal name={PlaceHolderName.drawerFooter}>
          <ContainerFooter>
            <DrawerCancelButton />

            <FormWrapperButtonSubmit>
              {translate('Export')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </FormWrapper>
    )
  }

  return (
    <DrawerContext.Provider
      value={{ storeDrawer: storeBoard.storeDrawerExportCards }}
    >
      <Drawer>
        <DrawerHorizontalContainer>
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: <DrawerTitle>{translate('Export cards')}</DrawerTitle>,
              content: renderContent(),
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canAccessToIoAExport}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(IoABoardDrawerExport)
