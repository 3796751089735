import { consts } from '@app/styles'
import * as React from 'react'
import { BulletCircle } from '.'

interface IBulletStatusWarningProps {}

const BulletStatusWarning: React.FC<IBulletStatusWarningProps> = props => {
  return <BulletCircle color={consts.colorOrange001} />
}

export default BulletStatusWarning
