import type Leaf from './Leaf'
import type Node from './Node'

export enum Combinator {
  AND = 'AND',
  OR = 'OR'
}

export interface ISerializable {
  toString: (recursive: boolean) => string
  toJs: (recursive: boolean) => string
  toExpression: (
    parentExpression?: IExpressionDefinition,
    prefixPath?: Array<string | number>
  ) => IExpressionDefinition
}

export enum ExpressionParserError {
  InvalidValue = 'InvalidValue'
}

export type ExpressionKey = Combinator | string
export type ValueOpOrNull = string | null

// having some issues with this recursive type, any as fallback but it shoud not...
export type IExpressionDefinition = {
  [K in ExpressionKey]: IExpressionDefinition[] | any
}

export type NodeOrLeaf = Node | Leaf
export type ValueType = 'generic' | 'date' | 'eventId'
export type StrOrNumbOrBool = string | number | boolean
