import type { IGraphQLQuery } from '@libs/graphQL/types'
import type {
  Mutation,
  UpdateLockoutPolicyMutationArgs
} from '../typeDefs/types'

export type MutationUpdateLockoutPolicy = IGraphQLQuery<
  UpdateLockoutPolicyMutationArgs,
  Pick<Mutation, 'updateLockoutPolicy'>
>

export const mutationUpdateLockoutPolicy = `
  mutation mutationUpdateLockoutPolicy(
    $lockoutPolicy: InputUpdateLockoutPolicy!
  ) {
    updateLockoutPolicy(
      lockoutPolicy: $lockoutPolicy
    )
  }
`
