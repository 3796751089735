import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'
import IconDirection, { Direction } from './IconDirection'

interface IIconDownProps extends IIconProps {}

const IconDown: React.FC<IIconDownProps> = props => (
  <IconDirection direction={Direction.down} {...props} />
)

export default IconDown
