import { ContainerContent } from '@app/components-legacy/Container'
import { LabelDirectoriesTree } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'

export interface IImpactedDomainsProps {
  directoryIds: number[]
}

const ImpactedDomains: React.FC<IImpactedDomainsProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoE.Details.Information']
  })

  const { storeInfrastructures } = useStores()

  return (
    <ContainerContent title={translate('Impacted Domains')}>
      <LabelDirectoriesTree
        direction="row"
        directoryIds={props.directoryIds}
        storeInfrastructures={storeInfrastructures}
      />
    </ContainerContent>
  )
}

export default ImpactedDomains
