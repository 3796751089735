import type { Maybe } from '@@types/helpers'
import type StoreBoard from '@app/stores/IoA/StoreBoard'
import * as React from 'react'

const IoABoardPageContext = React.createContext<
  Maybe<{
    storeBoard: StoreBoard
  }>
>(null)

export { IoABoardPageContext }
