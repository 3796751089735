import { useLogger } from '@app/hooks'
import * as React from 'react'
import type { PlaceHolderName } from '../PlaceHolder/types'
import { usePortalPlaceHolder } from './usePortal'

interface IPortalPlaceHolderProps {
  className?: string
  name: PlaceHolderName
  debug?: boolean
}

const PortalPlaceHolder: React.FC<IPortalPlaceHolderProps> = props => {
  const { portalUid } = usePortalPlaceHolder(props.name)
  const logger = useLogger()

  if (props.debug) {
    logger.debug(
      `[PortalPlaceHolder] Render a new placeholder with id "${portalUid}"`
    )
  }

  return (
    <div
      data-name="PortalPlaceHolder"
      id={portalUid}
      className={props.className}
    />
  )
}

export default PortalPlaceHolder
