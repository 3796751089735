import type StoreAttacks from '@app/stores/IoA/StoreAttacks'
import type {
  UnremoveAllAttacksMutationArgs,
  UnremoveAttacksMutationArgs
} from '@server/graphql/typeDefs/types'

/**
 * Initialize the drawer.
 */
export const onDrawerAttacksFiltersLoad =
  (storeAttacks: StoreAttacks) => () => {
    const profileId =
      storeAttacks.storeRoot.stores.storeAuthentication.currentProfileId

    storeAttacks.storeAttackRemoveFilters.fetchAttacksRemoveFilters({
      profileId
    })
    storeAttacks.storeAttackRemoveFilters.storeInputCheckersAttacks.fetchAttackCheckers()
  }

/**
 * Unremove attacks by removing a remove-filter.
 */
export const onAttacksRemoveFiltersDeleteIconClick =
  (storeAttacks: StoreAttacks) => (attackTypeId: number) => () => {
    const profileId =
      storeAttacks.storeRoot.stores.storeAuthentication.currentProfileId

    const args: UnremoveAttacksMutationArgs = {
      filterId: attackTypeId,
      profileId
    }

    storeAttacks.unremoveAttacks(args).then(() => {
      // reload the list
      storeAttacks.storeAttackRemoveFilters.fetchAttacksRemoveFilters({
        profileId
      })
    })
  }

/**
 * Close the drawer and reload attacks to take into accounts the removed remove-filters.
 */
export const onAttacksRemoveFiltersCancelIconClick =
  (storeAttacks: StoreAttacks) => () => {
    storeAttacks.storeAttackRemoveFilters.storeDrawer.closeDrawer()
    storeAttacks.reloadAttacks()
  }

/**
 * Remove all filters, close the drawer and reload attacks.
 */
export const onAttacksRemoveAllFiltersButtonClick =
  (storeAttacks: StoreAttacks) => () => {
    const profileId =
      storeAttacks.storeRoot.stores.storeAuthentication.currentProfileId

    const args: UnremoveAllAttacksMutationArgs = {
      profileId
    }

    storeAttacks.unremoveAllAttacks(args).then(() => {
      storeAttacks.reloadAttacks()
      storeAttacks.storeAttackRemoveFilters.storeDrawer.closeDrawer()
    })
  }
