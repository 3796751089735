import type { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'

export const onInputSearchValueChange =
  (storeInputSearch: StoreInputSearch) =>
  (e: React.ChangeEvent<HTMLInputElement>) => {
    storeInputSearch.setSearchValue(e.target.value)
  }

export const onInputSearchValueReset =
  (storeInputSearch: StoreInputSearch) => (onReset?: () => void) => () => {
    storeInputSearch.reset()

    if (onReset) {
      onReset()
    }
  }

export const onInputSearchFocus =
  (storeInputSearch: StoreInputSearch) => () => {
    storeInputSearch.setFocus(true)
  }

export const onInputSearchBlur = (storeInputSearch: StoreInputSearch) => () => {
  storeInputSearch.setFocus(false)
}

export const onInputKeyPress =
  (storeInputSearch: StoreInputSearch) =>
  (onValidate?: (searchValue: string) => void) =>
  (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onValidate && e.key === 'Enter') {
      onValidate(storeInputSearch.searchValue)
    }
  }
