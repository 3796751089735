import type { Maybe } from '@@types/helpers'
import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import { ButtonCopyToClipboard } from '@app/components-legacy/Button'
import { CardTitle } from '@app/components-legacy/Card/CardTitle'
import {
  ContainerDividerFilter,
  ContainerFlex
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { IconArrow } from '@app/components-legacy/Icon'
import { LabelAlt, LabelDate } from '@app/components-legacy/Label'
import LabelDevianceIgnoreUntil from '@app/components-legacy/Label/LabelDeviance/LabelDevianceIgnoreUntil'
import {
  formatIncriminatingAttributeDescription,
  parseSDDL
} from '@app/entities/EntityIncriminatingAttribute/helpers'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { DateFormat } from '@libs/dates/formatDate'
import { Language } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import { Link } from 'react-router-dom'
import IconDirection, {
  Direction
} from '../../../components/Icon/IconDirection'
import { LabelAltVariant } from '../LabelAlt'
import { getDate, getElements } from './helper'
import LabelDevianceResolvedAt from './LabelDevianceResolvedAt'

interface ILabelDevianceTitleProps {
  className?: string
  description: Maybe<string>
  eventDate: string
  eventUrl?: Maybe<string>
  expanded: boolean
  reasonName: string
  ignoreUntil: Maybe<string>
  resolvedAt: Maybe<string>
  toggleExpand: () => void
}

const LabelDevianceTitle: React.FC<ILabelDevianceTitleProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const { storeRoot } = useStores()

  const translate = useAppTranslator({
    namespaces: ['IoE.Details.DeviantObjects']
  })

  const format =
    storeRoot.appTranslator.language === Language.FrFr
      ? DateFormat.frenchFullDate
      : DateFormat.englishFullDate

  const getTextToCopy = () => {
    const description = (
      props.description ||
      translate('No description is available for this deviance')
    )
      // remove markdown backticks for copied text
      .replace(/`/g, '')

    const formatText = (value: string) => value
    const formatDate = (value: string) => value
    const formatSDDL = (value: string) => {
      const formattedValue = parseSDDL(value)
        .map(({ formattedString }) => formattedString)
        .join('\n')

      return `\n\n${formattedValue}`
    }

    const formattedDescription = formatIncriminatingAttributeDescription({
      description,
      formatText,
      formatDate,
      formatSDDL
    }).join('')

    return `${props.reasonName}\n${getDate(
      props.eventDate,
      format
    )}\n${getElements(formattedDescription, format)}`
  }

  return (
    <ContainerFlex
      className={props.className}
      name="LabelDevianceTitle"
      justifyContent="space-between"
      alignItems="center"
      items={[
        <ContainerFlex
          name="LabelDevianceTitleLeft"
          items={[<CardTitle>{props.reasonName}</CardTitle>]}
        />,

        <ContainerFlex
          name="LabelDevianceTitleRight"
          alignItems="center"
          items={[
            props.resolvedAt && (
              <LabelDevianceResolvedAt resolvedAt={props.resolvedAt} />
            ),

            <LabelAlt
              variant={
                props.resolvedAt
                  ? LabelAltVariant.LineThrough
                  : LabelAltVariant.Normal
              }
            >
              {props.eventUrl ? (
                <Link to={props.eventUrl}>
                  {themeIsLegacy ? (
                    <LabelDate date={props.eventDate} />
                  ) : (
                    <Label variant={LabelVariant.p}>
                      <LabelDate date={props.eventDate} />
                    </Label>
                  )}
                </Link>
              ) : themeIsLegacy ? (
                <LabelDate date={props.eventDate} />
              ) : (
                <Label variant={LabelVariant.p}>
                  <LabelDate date={props.eventDate} />
                </Label>
              )}
            </LabelAlt>,

            <ContainerDividerFilter />,

            <ContainerFlex
              name="LabelDevianceTitleRightIcons"
              alignItems="center"
              items={[
                <ButtonCopyToClipboard
                  textToCopy={getTextToCopy()}
                  buttonVariant={ButtonVariant.colored}
                  buttonProps={{
                    type: 'link'
                  }}
                />,

                props.ignoreUntil && (
                  <LabelDevianceIgnoreUntil ignoreUntil={props.ignoreUntil} />
                ),

                themeIsLegacy ? (
                  <IconArrow
                    labelledBy="extendDeviance"
                    direction="right"
                    rotation={90}
                    expanded={props.expanded}
                    onClick={props.toggleExpand}
                  />
                ) : (
                  <FormWrapperButton
                    labelledBy="extendDeviance"
                    variant={ButtonVariant.colored}
                    size={ButtonSize.small}
                    icon={IconDirection}
                    iconProps={{
                      direction: props.expanded
                        ? Direction.down
                        : Direction.right
                    }}
                    buttonProps={{ onClick: props.toggleExpand }}
                  />
                )
              ]}
              spaced
              spaceWidth="small"
            />
          ]}
          spaced
          spaceWidth="small"
        />
      ]}
    />
  )
}

export default LabelDevianceTitle
