import { ContainerFlex } from '@app/components-legacy/Container'
import type EntityAttack from '@app/entities/EntityAttack'
import * as React from 'react'
import IoAAttacksTableBodyRowCardContainer from './IoAAttacksTableBodyRowCardContainer'
import IoAAttacksTableBodyDate from './IoAAttacksTableRow/IoAAttacksTableBodyDate'

interface IIoAAttacksTableBodyRowProps {
  className?: string
  style?: React.CSSProperties
  index: number
  attackEntity: EntityAttack
}

const IoAAttacksTableBodyRow: React.FC<
  IIoAAttacksTableBodyRowProps
> = props => {
  return (
    <div
      className={props.className}
      data-attack-id={`attackId-${props.attackEntity.id}`}
    >
      <ContainerFlex
        name="IoAAttacksTableBodyRow"
        itemsFlexGrow={[0, 1]}
        items={[
          <IoAAttacksTableBodyDate attackEntity={props.attackEntity} />,

          <IoAAttacksTableBodyRowCardContainer
            attackEntity={props.attackEntity}
          />
        ]}
        spaced
        spaceWidth="small"
      />
    </div>
  )
}

export default IoAAttacksTableBodyRow
