import type { IIconProps } from '@app/components/Icon/types'
import { FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconPinProps extends IIconProps {}

const IconPin: React.FC<IIconPinProps> = props => {
  const fill = props.color ?? FontColorV2.secondary
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.46771 11.0362L8.84408 14.4126C10.3473 12.9093 10.7228 10.7046 9.9699 8.85108L12.1086 5.50799C13.3796 6.11377 14.9473 5.89048 16 4.83772L11.1622 0C10.111 1.0512 9.88723 2.61638 10.4899 3.8863L7.14178 6.02703C5.28932 5.27822 3.08865 5.65466 1.5874 7.15591L4.96378 10.5323L0.038465 15.4576L0 16L0.542399 15.9615L5.46771 11.0362Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconPin
