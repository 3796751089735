import type { MaybeUndef, PropertiesNullable } from '@@types/helpers'
import { createDashboardKey } from '@libs/dashboards/keys'
import type { DashboardKey } from '@libs/dashboards/types'
import { ensureArray } from '@libs/ensureArray'
import { isDefined } from '@libs/isDefined'
import type {
  Dashboard,
  DashboardWidget,
  Maybe,
  MaybeGrantedWidgets
} from '@server/graphql/typeDefs/types'
import { createEntities } from '.'
import EntityBase from './EntityBase'
import EntityDashboardWidget from './EntityDashboardWidget'

export default class EntityDashboard
  extends EntityBase
  implements PropertiesNullable<Dashboard>
{
  id: Maybe<number> = null
  name: Maybe<string> = null
  order: Maybe<number> = null
  instanceName: Maybe<string> = null
  rbacWidgets: Maybe<MaybeGrantedWidgets> = null

  constructor(data: Partial<Dashboard>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return a unique key for a dashbaord.
   */
  getDashboardKey(): MaybeUndef<DashboardKey> {
    if (!isDefined(this.id) || !isDefined(this.instanceName)) {
      return
    }

    return createDashboardKey(this.instanceName, this.id)
  }

  /**
   * Return widgets.
   */
  getWidgets(): EntityDashboardWidget[] {
    return createEntities<DashboardWidget, EntityDashboardWidget>(
      EntityDashboardWidget,
      ensureArray(this.rbacWidgets?.node),
      // pass the parent to be able to retrieve dashboard info in the entity
      this
    )
  }
}
