import {
  ContainerContent,
  ContainerFlex,
  ContainerFooter
} from '@app/components/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { filterFalsies } from '@libs/filterFalsies'
import { isDefined } from '@libs/isDefined'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import UserLog from '../../UserActivityLogs/UserLog'
import ActivityLogsLogPayload from './ActivityLogsLogPayload'
import DrawerActivityLogPayloadTitle from './DrawerActivityLogPayloadTitle'

export interface IDrawerTracePayload {}

const DrawerActivityLogPayloadContent: React.FC<
  IDrawerTracePayload
> = props => {
  const { storeActivityLogs } = useStores()
  const { storeActivityLogPayload: storeTracePayload } = storeActivityLogs
  const { trace } = storeTracePayload

  let content = null

  if (isDefined(trace)) {
    content = <UserLog log={trace} />
  }

  const fieldsItems = filterFalsies([
    isDefined(trace) && (
      <ContainerFlex
        fullHeight
        name="trace-payload"
        direction="column"
        items={[
          <DrawerActivityLogPayloadTitle log={trace} />,
          <ActivityLogsLogPayload log={trace} />
        ]}
      />
    )
  ])

  return (
    <ContainerContent
      style={{
        backgroundColor: consts.BackgroundColor.secondary,
        borderRadius: consts.containerBorderRadius
      }}
    >
      <ContainerFlex
        name="DrawerTracePayload"
        labelledBy="payloadDrawer"
        direction="column"
        items={fieldsItems}
      />

      <Portal name={PlaceHolderName.drawerFooter}>
        <ContainerFooter />
      </Portal>
    </ContainerContent>
  )
}

export default observer(DrawerActivityLogPayloadContent)
