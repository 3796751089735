import { InputSelectNative } from '@app/components-legacy/Input'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { onNativeSelectChange } from '@app/stores/helpers/StoreForm/handlers'
import type StoreWizard from '@app/stores/helpers/StoreWizard'
import { WizardRuleFieldName } from '@app/stores/helpers/StoreWizard/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { getOptionAttributes } from '../attributes'

interface ISelectFieldRule {
  className?: string
  index: number
  ruleGroupPath: number[]
  storeWizard: StoreWizard
}

const getOptions = (field: string): React.ReactNode => {
  return [
    // Set the rule field dynamically at first position of select
    <option key={field} value={field}>
      {field}
    </option>,
    ...getOptionAttributes()
  ]
}

const SelectFieldRule: React.FC<ISelectFieldRule> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.Wizard']
  })

  const { storeForm } = props.storeWizard.getRuleOrRuleGroup(
    props.index,
    props.ruleGroupPath
  )

  const fieldValue = storeForm.getFieldValueAsString(WizardRuleFieldName.field)

  return (
    <InputSelectNative
      labelledBy="fieldName"
      className={props.className}
      onChange={onNativeSelectChange(storeForm)(WizardRuleFieldName.field)}
      value={fieldValue}
    >
      {getOptions(fieldValue)}
    </InputSelectNative>
  )
}

const observedSelectFieldRule = observer(SelectFieldRule)

export default styled(observedSelectFieldRule)`
  width: 220px;
`
