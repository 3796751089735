import type { Maybe } from '@@types/helpers'
import { IconSVG } from '@app/components-legacy/Icon'
import type { AttackPathAdObjectType } from '@server/graphql/typeDefs/types'
import { AttackPathDirection } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import { AttackPathSearchTabs } from '../types'

export interface INodeSearchIconProps {
  labelledBy?: string
  adObjectType?: Maybe<AttackPathAdObjectType>
  size?: number
  direction?: AttackPathDirection
  source?: boolean
  activeTab?: AttackPathSearchTabs
}

const getSearchIcon = (
  direction?: AttackPathDirection,
  activeTab?: AttackPathSearchTabs,
  source?: boolean
) => {
  if (activeTab === AttackPathSearchTabs.attackPath) {
    if (!source) {
      return 'arrival-point'
    }

    return 'starting-point'
  }

  if (direction === AttackPathDirection.From) {
    return 'blast-radius'
  }

  return 'asset-exposure'
}

const NodeSearchIcon: React.FC<INodeSearchIconProps> = props => {
  if (!props.adObjectType) {
    return (
      <IconSVG
        labelledBy={props.labelledBy}
        iconName={`attackpath/search-${getSearchIcon(
          props.direction,
          props.activeTab,
          props.source
        )}`}
        size={props.size}
      />
    )
  }

  return (
    <IconSVG
      labelledBy={props.labelledBy}
      iconName={`attackpath/search-${props.adObjectType}`}
      size={props.size}
    />
  )
}

export default NodeSearchIcon
