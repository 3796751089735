import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import EmailCommonForm from '../EmailCommonForm'
import { onEmailsEditSubmit } from './handlers'

interface IEmailEditFormProps {
  emailId: number
}

const EmailEditForm: React.FC<IEmailEditFormProps> = () => {
  const appRouter = useAppRouter()

  const { storeManagementEmails } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_System_Configuration_Emails_Edit,
    parameters: {
      emailId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <EmailCommonForm
      version="edition"
      onSubmit={onEmailsEditSubmit(storeManagementEmails)(parameters.emailId)}
    />
  )
}

export default observer(EmailEditForm)
