import type { PropertiesNullable } from '@@types/helpers'
import { isDefined } from '@libs/isDefined'
import type {
  AttackTypeConfigurationConfigurationEntry,
  Maybe
} from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

// string that represents a selection in the IOA setup configuration
// example: "directoryId:42-attackTypeId:96"
export type ConfigurationEntrySelectionString = string

export default class EntityAttackTypeConfigurationEntry
  extends EntityBase
  implements PropertiesNullable<AttackTypeConfigurationConfigurationEntry>
{
  attackTypeId: Maybe<number> = null
  directoryId: Maybe<number> = null
  detectedEvents: Maybe<boolean> = null
  customPastDelaySeconds: Maybe<number> = null
  autoDisabledForPerfAt: Maybe<string> = null

  constructor(data: Partial<AttackTypeConfigurationConfigurationEntry>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return a stringified representation of the entity to add selection in the store.
   * Return an always defined string for convenient usage with the Set<string> that
   * doesn't support nullable values.
   */
  toSelectionString(): ConfigurationEntrySelectionString {
    return this.directoryId && this.attackTypeId
      ? `directoryId:${this.directoryId}-attackTypeId:${this.attackTypeId}`
      : ''
  }

  /**
   * Create a EntityAttackTypeConfigurationEntry object since a selection string.
   */
  static fromSelectionString(
    selectionString: ConfigurationEntrySelectionString
  ): Maybe<EntityAttackTypeConfigurationEntry> {
    const ids = selectionString
      .split('-')
      .map(part => part.split(':')[1])
      .filter(isDefined)

    if (ids.length !== 2) {
      return null
    }

    return new EntityAttackTypeConfigurationEntry({
      directoryId: Number(ids[0]),
      attackTypeId: Number(ids[1])
    })
  }
}
