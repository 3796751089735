import type { Features } from '@alsid/common'
import { useStores } from './useStores'

interface IUseFeatureFlagParameters {
  featureFlagName: Features
}

/**
 * Return the value of a feature flag (declared in LaunchDarkly).
 */
export function useFeatureFlag(params: IUseFeatureFlagParameters): boolean {
  const { storeRoot } = useStores()
  return storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
    params.featureFlagName
  )
}
