import type { Maybe } from '@@types/helpers'
import IconSkullHead from '@app/components/Icon/IconSkullHead'
import { ContainerFlex } from '@app/components-legacy/Container'
import FormWrapperCheckboxSimple from '@app/components-legacy/Form/Wrappers/FormWrapperCheckboxSimple'
import type { EntityAttackType, EntityInfrastructure } from '@app/entities'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { DateFormat } from '@libs/dates/formatDate'
import { Language } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import * as React from 'react'
import SimpleBar from 'simplebar-react'
import styled from 'styled-components'
import Cell from './Cell'
import CellWrapper from './CellWrapper'
import { SETUP_TABLE_HEIGHT } from './consts'
import {
  handleDirectoryForOneAttackTypeOnChange,
  handleInfrastructureForOneAttackOnChange
} from './handlers'

interface IEntryCellsProps {
  refs: {
    thead: React.RefObject<HTMLDivElement>
    columns: React.RefObject<HTMLDivElement>
  }
  isCheckable: boolean
  infrastructures: EntityInfrastructure[]
  attackTypes: EntityAttackType[]
}

const ScrollableDiv = styled(SimpleBar)`
  max-height: ${() => `${SETUP_TABLE_HEIGHT}px`};
  width: 100%;
`

const CellWrapperDirectory = styled(CellWrapper)`
  padding: 0 ${consts.paddingVerySmall} 0 0;
`

const CellWrapperInfrastructure = styled(CellWrapper)<{
  isInfraCollapsed: boolean
}>`
  padding-right: ${props =>
    props.isInfraCollapsed ? consts.paddingDefault : consts.paddingVerySmall};
`

const CellRedesign = styled(Cell)``

const ContainerInfraAndDirectories = styled(ContainerFlex)`
  margin-right: ${consts.marginVerySmall};

  &:first-child {
    // every Cell components that are just under the header of the table
    &
      > ${CellWrapperInfrastructure}
      > ${CellRedesign},
      &
      > ${CellWrapperDirectory}
      > ${CellRedesign} {
      padding-top: 17px;
      max-height: 50px;
      min-height: 50px;
    }
  }

  &:last-child {
    // every Cell components that are just above the end of the table
    & > div {
      padding-bottom: ${consts.paddingDefault};

      // only the first Cell components that is just above the end of the table should have border left radius
      &:first-child {
        border-bottom-left-radius: ${consts.borderRadiusRedesign};
      }

      // only the last Cell components that is just above the end of the table should have border right radius
      &:last-child {
        border-bottom-right-radius: ${consts.borderRadiusRedesign};
      }
    }
  }

  ${CellWrapperDirectory}:last-child {
    padding-right: ${consts.paddingDefault};
  }

  // we need to remove the margin right on the last CellsEntries block
  div[data-name='CellsEntries'] > div[data-name='CellsEntries']:last-child & {
    margin-right: 0;
  }
`

const EMPTY_CELL = <></>

const StyledContainerFlexCellHeader = styled(ContainerFlex)`
  margin-left: 42px;
`

const CellsEntries: React.FC<IEntryCellsProps> = props => {
  const tableCellsEntriesRef = React.useRef<Maybe<HTMLDivElement>>(null)

  const { storeManagementAttackTypeConfiguration } = useStores()

  // bind the scroll event to manipulate left columns or thead scroll
  // when scrolling the cell entries (content)
  React.useEffect(() => {
    const tableCellsEntriesRefCurrent = tableCellsEntriesRef.current
    const tableTheadRefCurrent = props.refs.thead.current
    const tableColumnsRefCurrent = props.refs.columns.current

    if (
      !tableCellsEntriesRefCurrent ||
      !tableTheadRefCurrent ||
      !tableColumnsRefCurrent
    ) {
      return
    }

    tableCellsEntriesRefCurrent.onscroll = () => {
      tableTheadRefCurrent.scrollLeft = tableCellsEntriesRefCurrent.scrollLeft
      tableColumnsRefCurrent.scrollTop = tableCellsEntriesRefCurrent.scrollTop
    }

    return () => {
      tableCellsEntriesRefCurrent.onscroll = null
    }
  }, [tableCellsEntriesRef])

  const { storeInfrastructures } = storeManagementAttackTypeConfiguration
  const { storeRoot } = useStores()
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.IoA']
  })
  const getInfrastructureCell = (
    infrastructure: EntityInfrastructure,
    attackType: EntityAttackType,
    isInfraCollapsed: boolean
  ) => {
    const checkboxLabelledBy = [
      infrastructure.getPropertyAsString('name'),
      attackType.getPropertyAsString('name')
    ].join('-')

    const directories = infrastructure
      .getDirectories()
      .filter(
        directory =>
          directory.id &&
          storeInfrastructures.selectedDirectoryIds.includes(directory.id)
      )

    if (!directories.length) {
      return
    }

    const isChecked =
      storeManagementAttackTypeConfiguration.isInfrastructureForOneAttackSelected(
        infrastructure.getPropertyAsNumber('id'),
        attackType.getPropertyAsNumber('id')
      )

    const isCheckableAndChecked = isChecked && !props.isCheckable
    const CheckboxElt = (isCheckableAndChecked || props.isCheckable) && (
      <FormWrapperCheckboxSimple
        labelledBy={checkboxLabelledBy}
        disabled={!props.isCheckable}
        checked={isChecked}
        indeterminate={storeManagementAttackTypeConfiguration.isInfrastructureForOneAttackPartiallySelected(
          infrastructure.getPropertyAsNumber('id'),
          attackType.getPropertyAsNumber('id')
        )}
        onChange={handleInfrastructureForOneAttackOnChange(
          storeManagementAttackTypeConfiguration
        )(
          infrastructure.getPropertyAsNumber('id'),
          attackType.getPropertyAsNumber('id')
        )}
      />
    )

    return (
      <CellWrapperInfrastructure
        key={checkboxLabelledBy}
        isInfraCollapsed={isInfraCollapsed}
      >
        <CellRedesign isInfrastructure>
          {CheckboxElt || EMPTY_CELL}
        </CellRedesign>
      </CellWrapperInfrastructure>
    )
  }

  const getInfrastructureDirectoriesCells = (
    infrastructure: EntityInfrastructure,
    attackType: EntityAttackType
  ) => {
    const format =
      storeRoot.appTranslator.language === Language.FrFr
        ? DateFormat.frenchFullDate
        : DateFormat.englishFullDate

    const directories = infrastructure
      .getDirectories()
      .filter(
        directory =>
          directory.id &&
          storeInfrastructures.selectedDirectoryIds.includes(directory.id)
      )

    return directories.map(directory => {
      const checkboxLabelledBy = [
        directory.getPropertyAsString('name'),
        attackType.getPropertyAsString('name')
      ].join('-')

      const entry =
        storeManagementAttackTypeConfiguration.getConfigurationEntry(
          directory.getPropertyAsNumber('id'),
          attackType.getPropertyAsNumber('id')
        )

      const detectedEvents = entry?.detectedEvents === true

      const autoDisabledForPerfAt = entry?.autoDisabledForPerfAt
      const disabledDate = autoDisabledForPerfAt
        ? moment(autoDisabledForPerfAt).format(format)
        : null

      const isChecked =
        storeManagementAttackTypeConfiguration.isDirectoryForOneAttackSelected(
          directory.getPropertyAsNumber('id'),
          attackType.getPropertyAsNumber('id')
        )

      const isCheckableAndChecked = isChecked && !props.isCheckable
      const CheckboxElt = (isCheckableAndChecked || props.isCheckable) && (
        <FormWrapperCheckboxSimple
          labelledBy={checkboxLabelledBy}
          disabled={!props.isCheckable}
          checked={isChecked}
          onChange={handleDirectoryForOneAttackTypeOnChange(
            storeManagementAttackTypeConfiguration
          )(
            directory.getPropertyAsNumber('id'),
            attackType.getPropertyAsNumber('id')
          )}
        />
      )

      const errorMsg: string = translate(
        'IoA temporarily deactivated (since X) to maintain performance',
        {
          interpolations: {
            date: disabledDate as string
          }
        }
      )
      return (
        <CellWrapperDirectory>
          <CellRedesign
            key={`attackType-${attackType.id}`}
            detectedEvents={detectedEvents}
          >
            {autoDisabledForPerfAt ? (
              <StyledContainerFlexCellHeader
                name="CellIoaDomainStatus"
                justifyContent="flex-end"
                alignItems="center"
                spaced
                spaceWidth="default"
                items={[
                  CheckboxElt,
                  autoDisabledForPerfAt && (
                    <div title={errorMsg}>
                      <IconSkullHead />
                    </div>
                  )
                ]}
              />
            ) : (
              CheckboxElt || EMPTY_CELL
            )}
          </CellRedesign>
        </CellWrapperDirectory>
      )
    })
  }

  return (
    <ScrollableDiv
      scrollableNodeProps={{ ref: tableCellsEntriesRef }}
      autoHide={false}
    >
      <ContainerFlex
        name={CellsEntries.name}
        items={props.infrastructures.map(infrastructure => {
          const isInfrastructureCollapsed =
            storeManagementAttackTypeConfiguration.infrastructuresCollapsed.get(
              infrastructure.getPropertyAsNumber('id')
            ) || false

          return (
            <ContainerFlex
              name="CellsEntries"
              direction="column"
              items={props.attackTypes.map(attackType => {
                return (
                  <ContainerInfraAndDirectories
                    name="CellsEntries"
                    items={
                      isInfrastructureCollapsed
                        ? [
                            getInfrastructureCell(
                              infrastructure,
                              attackType,
                              isInfrastructureCollapsed
                            )
                          ]
                        : [
                            getInfrastructureCell(
                              infrastructure,
                              attackType,
                              isInfrastructureCollapsed
                            ),
                            ...getInfrastructureDirectoriesCells(
                              infrastructure,
                              attackType
                            )
                          ]
                    }
                  />
                )
              })}
            />
          )
        })}
      />
    </ScrollableDiv>
  )
}

export default observer(CellsEntries)
