import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { InputSearch } from '@app/components-legacy/Input'
import InputDatePickerRange from '@app/components-legacy/Input/InputDatePickerRange'
import InputTenants from '@app/components-legacy/Input/InputTenants'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { handleFindingsOnInputSearchSubmit } from '@app/pages/IoE/IoECheckerIdentityDetailsPage/DetailsFindingsPage/handlers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckerIdentityDetailsPageContext } from '../context'

interface IFindingsFiltersProps {}

const FindingsFilters: React.FC<IFindingsFiltersProps> = () => {
  const parameters = React.useContext(CheckerIdentityDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoE.Details.Findings']
  })

  const { storeIoE } = useStores()

  if (!parameters) {
    return null
  }

  const { storeIndicatorFindings } = storeIoE.storeIndicator

  return (
    <ContainerFlex
      name="FindingsTableFilters"
      direction="column"
      items={[
        <ContainerFlex
          name="FindingsTableFilters1"
          alignItems="center"
          justifyContent="space-between"
          itemsFlexGrow={[1]}
          items={[
            <InputSearch
              storeInputSearch={storeIndicatorFindings.storeInputSearchFinding}
              onValidate={handleFindingsOnInputSearchSubmit(storeIoE)(
                parameters.checkerId
              )}
              onReset={handleFindingsOnInputSearchSubmit(storeIoE)(
                parameters.checkerId
              )}
              placeholder={translate('Search for an object')}
            />,

            <InputDatePickerRange
              storeDatePicker={storeIndicatorFindings.storeDatePicker}
            />,

            <InputTenants
              storeInputTenants={storeIndicatorFindings.storeInputTenants}
              onSubmit={handleFindingsOnInputSearchSubmit(storeIoE)(
                parameters.checkerId
              )}
            />,

            <FormWrapperButton
              labelledBy="search"
              buttonProps={{
                type: 'primary',
                onClick: handleFindingsOnInputSearchSubmit(storeIoE)(
                  parameters.checkerId
                )
              }}
            >
              {translate('Search')}
            </FormWrapperButton>
          ]}
          spaced
          spaceWidth="small"
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(FindingsFilters)
