/**
 * Return true if `collection` contains at least one `targets`.
 * Note that unlike `includes` from lodash, this function supports searchs
 * on strings directly.
 */
export function containsStrings(
  collection: string[],
  targets: string | string[]
): boolean {
  const search = (target: string): boolean =>
    collection.some(value => value !== '' && target.indexOf(value) !== -1)

  return Array.isArray(targets) ? targets.some(search) : search(targets)
}
