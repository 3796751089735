import {
  createEntities,
  createEntity,
  EntityHealthCheckDomainStatus,
  EntityHealthCheckDomainStatusDetails,
  EntityHealthCheckPlatformStatus,
  EntityHealthCheckPlatformStatusDetails,
  EntityPagination
} from '@app/entities'
import { StoreInputHealthChecks } from '@app/stores'
import StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import type { IDataRowGeneric } from '@app/stores/helpers/StoreWidgetList/types'
import { handleStoreError } from '@libs/errors/handleStoreError'
import type {
  QueryHealthChecksDomainStatus,
  QueryHealthChecksDomainStatusDetails,
  QueryHealthChecksPlatformStatus,
  QueryHealthChecksPlatformStatusDetails
} from '@server/graphql/queries/healthCheck'
import {
  queryHealthChecksDomainStatus,
  queryHealthChecksDomainStatusDetails,
  queryHealthChecksPlatformStatus,
  queryHealthChecksPlatformStatusDetails
} from '@server/graphql/queries/healthCheck'
import type {
  HealthCheckDomainStatus,
  HealthCheckDomainStatusDetails,
  HealthCheckPlatformStatus,
  HealthCheckPlatformStatusDetails,
  HealthChecksDomainStatusDetailsQueryArgs,
  HealthChecksDomainStatusQueryArgs,
  HealthChecksPlatformStatusDetailsQueryArgs,
  HealthChecksPlatformStatusQueryArgs,
  Maybe
} from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '..'
import { StoreInfrastructures } from '..'
import StoreFlags from '../helpers/StoreFlags'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'

export default class StoreHealthCheck extends StoreBase {
  public storeInfrastructures = new StoreInfrastructures(this.storeRoot)
  public storeInputHealthChecks = new StoreInputHealthChecks(this.storeRoot)

  /* Flags */

  public storeFlagsFetchDomainStatus = new StoreFlags(this.storeRoot)
  public storeFlagsFetchDomainStatusDetails = new StoreFlags(this.storeRoot)

  public storeFlagsFetchPlatformStatus = new StoreFlags(this.storeRoot)
  public storeFlagsFetchPlatformStatusDetails = new StoreFlags(this.storeRoot)

  /* Lists */

  public storeWidgetListHealthChecksDomainStatus = new StoreWidgetList<
    EntityHealthCheckDomainStatus,
    IDataRowGeneric
  >(this.storeRoot)

  public storeWidgetListHealthChecksPlatformStatus = new StoreWidgetList<
    EntityHealthCheckPlatformStatus,
    IDataRowGeneric
  >(this.storeRoot)

  /* Observables */

  private $healthChecksDomainStatus =
    observable.box<Maybe<EntityHealthCheckDomainStatus[]>>(null)
  private $healthCheckDomainStatusDetails =
    observable.box<Maybe<EntityHealthCheckDomainStatusDetails>>(null)

  private $healthChecksPlatformStatus =
    observable.box<Maybe<EntityHealthCheckPlatformStatus[]>>(null)
  private $healthCheckPlatformStatusDetails =
    observable.box<Maybe<EntityHealthCheckPlatformStatusDetails>>(null)

  private $showHealthy = observable.box<boolean>(false)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch health checks domain status
   */
  fetchHealthChecksDomainStatus(
    args: HealthChecksDomainStatusQueryArgs,
    storeFlags = this.storeFlagsFetchDomainStatus
  ) {
    storeFlags.loading()
    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryHealthChecksDomainStatus>(
            queryHealthChecksDomainStatus,
            args
          )
      })
      .then(healthChecksDomainStatus => {
        if (!healthChecksDomainStatus) {
          this.storeRoot.logger.error(
            'Unable to retrieve health checks domain status'
          )
        }

        const { node, pagination } =
          healthChecksDomainStatus.healthChecksDomainStatus

        const healthCheckEntities = createEntities<
          HealthCheckDomainStatus,
          EntityHealthCheckDomainStatus
        >(EntityHealthCheckDomainStatus, node)
        this.setHealthChecksDomainStatus(healthCheckEntities)

        this.storeWidgetListHealthChecksDomainStatus.setPagination(
          new EntityPagination({
            page: pagination.page || null,
            perPage: pagination.perPage || null,
            totalCount: pagination.totalCount || null
          })
        )

        this.storeWidgetListHealthChecksDomainStatus.setEntities(
          healthCheckEntities
        )

        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Fetch health checks domain status details
   */
  fetchHealthChecksDomainsStatusDetails(
    args: HealthChecksDomainStatusDetailsQueryArgs
  ) {
    this.storeFlagsFetchDomainStatusDetails.loading()
    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryHealthChecksDomainStatusDetails>(
            queryHealthChecksDomainStatusDetails,
            args
          )
      })
      .then(healthCheck => {
        if (!healthCheck) {
          this.storeRoot.logger.error(
            'Unable to retrieve health checks domain status details'
          )
        }
        const healthCheckDomainStatusDetailsEntity = createEntity<
          HealthCheckDomainStatusDetails,
          EntityHealthCheckDomainStatusDetails
        >(
          EntityHealthCheckDomainStatusDetails,
          healthCheck.healthChecksDomainStatusDetails
        )
        this.setHealthCheckDomainStatusDetails(
          healthCheckDomainStatusDetailsEntity
        )
        this.storeFlagsFetchDomainStatusDetails.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsFetchDomainStatusDetails
        )
      )
  }

  /**
   * Fetch health checks platform status
   */
  fetchHealthChecksPlatformStatus(
    args: HealthChecksPlatformStatusQueryArgs,
    storeFlags = this.storeFlagsFetchPlatformStatus
  ) {
    storeFlags.loading()
    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryHealthChecksPlatformStatus>(
            queryHealthChecksPlatformStatus,
            args
          )
      })
      .then(healthChecksPlatformStatus => {
        if (!healthChecksPlatformStatus) {
          this.storeRoot.logger.error(
            'Unable to retrieve health checks platform status'
          )
        }

        const { node, pagination } =
          healthChecksPlatformStatus.healthChecksPlatformStatus

        const healthCheckEntities = createEntities<
          HealthCheckPlatformStatus,
          EntityHealthCheckPlatformStatus
        >(EntityHealthCheckPlatformStatus, node)
        this.setHealthChecksPlatformStatus(healthCheckEntities)

        this.storeWidgetListHealthChecksPlatformStatus.setPagination(
          new EntityPagination({
            page: pagination.page || null,
            perPage: pagination.perPage || null,
            totalCount: pagination.totalCount || null
          })
        )

        this.storeWidgetListHealthChecksPlatformStatus.setEntities(
          healthCheckEntities
        )

        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Fetch health checks platform status details
   */
  fetchHealthChecksPlatformStatusDetails(
    args: HealthChecksPlatformStatusDetailsQueryArgs
  ) {
    this.storeFlagsFetchPlatformStatusDetails.loading()
    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryHealthChecksPlatformStatusDetails>(
            queryHealthChecksPlatformStatusDetails,
            args
          )
      })
      .then(healthCheck => {
        if (!healthCheck) {
          this.storeRoot.logger.error(
            'Unable to retrieve health checks platform status details'
          )
        }
        const healthCheckPlatformStatusDetailsEntity = createEntity<
          HealthCheckPlatformStatusDetails,
          EntityHealthCheckPlatformStatusDetails
        >(
          EntityHealthCheckPlatformStatusDetails,
          healthCheck.healthChecksPlatformStatusDetails
        )
        this.setHealthCheckPlatformStatusDetails(
          healthCheckPlatformStatusDetailsEntity
        )
        this.storeFlagsFetchPlatformStatusDetails.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsFetchPlatformStatusDetails
        )
      )
  }

  /* Actions */

  @action
  reset(): this {
    this.resetHealthCheckPage()
    this.resetHealthCheckDomainStatus()
    this.resetHealthCheckPlatformStatus()

    return this
  }

  @action
  resetHealthCheckPage(): this {
    this.storeInfrastructures.reset()
    this.storeWidgetListHealthChecksDomainStatus.reset()
    this.storeInputHealthChecks.reset()

    return this
  }

  @action
  resetHealthCheckDomainStatus(): this {
    this.storeFlagsFetchDomainStatus.reset()
    this.$healthChecksDomainStatus.set(null)
    this.$showHealthy.set(false)

    this.resetDomainStatusDetails()

    return this
  }

  @action
  resetHealthCheckPlatformStatus(): this {
    this.storeFlagsFetchPlatformStatus.reset()
    this.$healthChecksPlatformStatus.set(null)
    this.$showHealthy.set(false)

    this.resetPlatformStatusDetails()

    return this
  }

  @action
  resetDomainStatusDetails(): this {
    this.storeFlagsFetchDomainStatusDetails.reset()
    this.$healthCheckDomainStatusDetails.set(null)

    return this
  }

  @action
  resetPlatformStatusDetails(): this {
    this.storeFlagsFetchPlatformStatusDetails.reset()
    this.$healthCheckPlatformStatusDetails.set(null)

    return this
  }

  @action
  setHealthChecksDomainStatus(
    healthCheckDomainStatusEntities: EntityHealthCheckDomainStatus[]
  ): this {
    this.$healthChecksDomainStatus.set(healthCheckDomainStatusEntities)
    return this
  }

  @action
  setHealthCheckDomainStatusDetails(
    healthCheckDomainStatusDetailsEntity: EntityHealthCheckDomainStatusDetails
  ): this {
    this.$healthCheckDomainStatusDetails.set(
      healthCheckDomainStatusDetailsEntity
    )
    return this
  }

  @action
  setHealthChecksPlatformStatus(
    healthCheckPlatformStatusEntities: EntityHealthCheckPlatformStatus[]
  ): this {
    this.$healthChecksPlatformStatus.set(healthCheckPlatformStatusEntities)
    return this
  }

  @action
  setHealthCheckPlatformStatusDetails(
    healthCheckPlatformStatusDetailsEntity: EntityHealthCheckPlatformStatusDetails
  ): this {
    this.$healthCheckPlatformStatusDetails.set(
      healthCheckPlatformStatusDetailsEntity
    )
    return this
  }

  @action
  setShowHealthy(showHealthy: boolean): this {
    this.$showHealthy.set(showHealthy)
    return this
  }

  /* Computed */

  @computed
  get healthChecksDomainStatus(): Maybe<EntityHealthCheckDomainStatus[]> {
    return this.$healthChecksDomainStatus.get()
  }

  @computed
  get healthCheckDomainStatusDetails(): Maybe<EntityHealthCheckDomainStatusDetails> {
    return this.$healthCheckDomainStatusDetails.get()
  }

  @computed
  get healthCheckPlatformStatusDetails(): Maybe<EntityHealthCheckPlatformStatusDetails> {
    return this.$healthCheckPlatformStatusDetails.get()
  }

  @computed
  get healthChecksPlatformStatus(): Maybe<EntityHealthCheckPlatformStatus[]> {
    return this.$healthChecksPlatformStatus.get()
  }

  @computed
  get showHealthy(): boolean {
    return this.$showHealthy.get()
  }
}
