import type { Maybe } from '@@types/helpers'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import type { EntityDirectory } from '@app/entities'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { LabelDirectory, LabelSliced } from '..'
import LabelAlt, { LabelAltVariant } from '../LabelAlt'

interface IInfrastructureDirectoriesProps {
  className?: string
  infrastructureId: Maybe<number>
  infrastructureName: string
  directories: EntityDirectory[]
  buildInfrastructureUrlFn?: (id: Maybe<number>) => string
  buildDirectoryUrlFn?: (id: Maybe<number>) => string
  onInfrastructureClick?: (
    id: Maybe<number>
  ) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  onDirectoryClick?: (
    id: Maybe<number>
  ) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const InfrastructureDirectories: React.FC<
  IInfrastructureDirectoriesProps
> = props => {
  const theme = useDSTheme()

  function renderLabelDirectorys() {
    return props.directories.map((directory, i) => {
      if (!directory.name) {
        return null
      }

      const key = [directory.name, i].join('-')
      return props.buildDirectoryUrlFn ? (
        <Link
          key={key}
          to={props.buildDirectoryUrlFn(directory.id)}
          onClick={props.onDirectoryClick?.(directory.id)}
        >
          <LabelDirectory
            className="directoriesTreeDirLabel"
            color={directory.color}
            label={directory.name}
          />
        </Link>
      ) : (
        <LabelDirectory
          key={key}
          className="directoriesTreeDirLabel"
          color={directory.color}
          label={directory.name}
        />
      )
    })
  }

  const label = isThemeLegacy(theme) ? (
    <LabelSliced maxLength={25} value={props.infrastructureName} />
  ) : (
    <Label variant={LabelVariant.p_bold}>
      <LabelSliced maxLength={25} value={props.infrastructureName} />
    </Label>
  )

  return (
    <div className={props.className}>
      <div className="directoriesTreeInfraName">
        <LabelAlt variant={LabelAltVariant.Bolded}>
          {props.buildInfrastructureUrlFn ? (
            <Link
              to={props.buildInfrastructureUrlFn(props.infrastructureId)}
              onClick={props.onInfrastructureClick?.(props.infrastructureId)}
            >
              {label}
            </Link>
          ) : (
            label
          )}
        </LabelAlt>
      </div>

      <div>{renderLabelDirectorys()}</div>
    </div>
  )
}

export default styled(InfrastructureDirectories)`
  min-width: 220px;
  margin-bottom: ${consts.marginDefault};

  .directoriesTreeInfraName {
    padding-left: 5px;
  }

  .directoriesTreeDirLabel {
    position: relative;
    padding-left: 12px;

    &:before {
      content: '';
      position: absolute;
      top: -9px;
      left: -2px;
      width: 8px;
      height: 20px;
      border-left: 1px solid
        ${props => props.theme.tokens['infrastructureDirectories/color/border']};
      border-bottom: 1px solid
        ${props => props.theme.tokens['infrastructureDirectories/color/border']};
    }
  }
`
