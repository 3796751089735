import { BladesContainer } from '@app/components-legacy/Blade'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LayoutDefault } from '@app/components-legacy/Layout'
import * as React from 'react'
import PreferencesPage from './PreferencesPage'

interface IPreferencesProps {}

const Preferences: React.FC<IPreferencesProps> = props => {
  return (
    <LayoutDefault
      content={
        <ContainerFlex
          name="Preferences"
          items={[
            <BladesContainer>
              <PreferencesPage />
            </BladesContainer>
          ]}
        />
      }
    />
  )
}

export default Preferences
