import type { IGraphQLQuery } from '@libs/graphQL/types'
import type { Mutation } from '../typeDefs/types'

export type MutationRefreshReportAccessToken = IGraphQLQuery<
  null,
  Pick<Mutation, 'refreshReportAccessToken'>
>

export const mutationRefreshReportAccessToken = `
  mutation mutationRefreshReportAccessToken {
    refreshReportAccessToken {
      reportAccessToken
    }
  }
`
