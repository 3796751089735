import { createEntities, createEntity, EntityPagination } from '@app/entities'
import type { IDataRowAdObjectAsDeviantObject } from '@app/entities/EntityAdObject/types'
import EntityIncriminatingAttributeListable from '@app/entities/EntityIncriminatingAttribute/EntityIncriminatingAttributeListable'
import type { IDataRowIncriminatingAttributeListable } from '@app/entities/EntityIncriminatingAttribute/types'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { checkRbac } from '@libs/rbac/functions'
import type { QueryAdObjectIncriminatingAttributes } from '@server/graphql/queries/deviance'
import { queryAdObjectIncriminatingAttributes } from '@server/graphql/queries/deviance'
import type {
  IncriminatingAttribute,
  Maybe,
  Pagination,
  RbacAdObjectIncriminatingAttributesQueryArgs
} from '@server/graphql/typeDefs/types'

export interface IStoreIoEIncriminatingAttributesOptions extends IStoreOptions {
  adObjectRow: IDataRowAdObjectAsDeviantObject
}

export default class StoreIndicatorDeviantObjectDeviances extends StoreBase<IStoreIoEIncriminatingAttributesOptions> {
  public storeWidgetListDeviances = new StoreWidgetList<
    EntityIncriminatingAttributeListable,
    IDataRowIncriminatingAttributeListable
  >(this.storeRoot)

  public storeFetchDeviancesFlags = new StoreFlags(this.storeRoot)
  public storeFetchEditDeviances = new StoreFlags(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['IoE.Details.DeviantObjects']
  })

  /**
   * Fetch deviances of a deviant AdObject.
   */
  fetchDeviances(
    args: RbacAdObjectIncriminatingAttributesQueryArgs
  ): Promise<any> {
    this.storeFetchDeviancesFlags.loading()

    return Promise.resolve()
      .then(() => this._fetchDeviances(args))
      .then(() => this.storeFetchDeviancesFlags.success())
      .catch(handleStoreError(this.storeRoot, this.storeFetchDeviancesFlags))
  }

  /**
   * Reload deviances of a deviant AdObject.
   */
  reloadDeviances(
    args: RbacAdObjectIncriminatingAttributesQueryArgs
  ): Promise<any> {
    this.storeFetchDeviancesFlags.loading()

    return Promise.resolve()
      .then(() => this._fetchDeviances(args))
      .then(() => this.storeFetchDeviancesFlags.success())
      .catch(handleStoreError(this.storeRoot, this.storeFetchDeviancesFlags))
  }

  /**
   * Reset pagination and reload deviances.
   */
  searchDeviances(
    args: RbacAdObjectIncriminatingAttributesQueryArgs
  ): Promise<void> {
    return this.reloadDeviances({
      ...args,
      incriminatingAttributesPage: 1
    })
  }

  /**
   * Compute args to fetch deviances.
   */
  computeFetchDeviancesArgs(
    checkerCodename: string,
    customArgs?: Partial<RbacAdObjectIncriminatingAttributesQueryArgs>
  ): Maybe<RbacAdObjectIncriminatingAttributesQueryArgs> {
    const { storeIoE } = this.storeRoot.stores

    const args =
      storeIoE.storeIndicator.storeIndicatorDeviantObjects.computeFetchDeviantObjectsArgs(
        checkerCodename
      )

    if (!args || !this.options) {
      return null
    }

    let baseArgs: RbacAdObjectIncriminatingAttributesQueryArgs = {
      profileId: args.profileId,
      checkerId: args.checkerId,
      adObjectId: this.options.adObjectRow.id,
      showIgnored: args.showIgnored,
      incriminatingAttributesPage: this.storeWidgetListDeviances.paginationPage,
      incriminatingAttributesPerPage: this.storeWidgetListDeviances.rowsPerPage
    }

    const dates =
      storeIoE.storeIndicator.storeIndicatorDeviantObjects.storeDatePicker
        .datePickerRangeValueAsIsoStrings

    if (dates) {
      baseArgs = {
        ...baseArgs,
        dateStart: dates.dateStart,
        dateEnd: dates.dateEnd
      }
    }

    return { ...baseArgs, ...customArgs }
  }

  /**
   * Fetch deviances attributes.
   */
  private _fetchDeviances(
    args: RbacAdObjectIncriminatingAttributesQueryArgs
  ): Promise<any> {
    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryAdObjectIncriminatingAttributes>(
            queryAdObjectIncriminatingAttributes,
            args
          )
      })
      .then(data => data.rbacAdObjectIncriminatingAttributes)
      .then(incriminatingAttributes => {
        if (!checkRbac(this.storeRoot)(incriminatingAttributes)) {
          throw new ForbiddenAccessError()
        }

        const incriminatingAttributesEntities = createEntities<
          IncriminatingAttribute,
          EntityIncriminatingAttributeListable
        >(
          EntityIncriminatingAttributeListable,
          incriminatingAttributes.node.node
        )

        const incriminatingAttributesPagination = createEntity<
          Pagination,
          EntityPagination
        >(EntityPagination, incriminatingAttributes.node.pagination)

        this.storeWidgetListDeviances.setEntities(
          incriminatingAttributesEntities
        )
        this.storeWidgetListDeviances.setPagination(
          incriminatingAttributesPagination
        )
      })
      .then(() => this.storeFetchDeviancesFlags.success())
  }

  reset(): this {
    this.storeWidgetListDeviances.reset()
    return this
  }
}
