import * as React from 'react'
import type { IIconProps } from '../types'

export default function IconGCP(props: IIconProps) {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.43181 15.7537H4.77272V7.64009L0 4.06055V14.3219C0 15.113 0.640737 15.7537 1.43181 15.7537Z"
        fill="#4285F4"
      />
      <path
        d="M16.2273 15.7537H19.5682C20.3593 15.7537 21 15.113 21 14.3219V4.06055L16.2273 7.64009V15.7537Z"
        fill="#34A853"
      />
      <path
        d="M16.2273 1.43567V7.6402L21 4.06066V2.15158C21 0.382096 18.98 -0.628526 17.5637 0.433402L16.2273 1.43567Z"
        fill="#FBBC04"
      />
      <path
        d="M4.77258 7.64007V1.43555L10.4998 5.73099L16.2271 1.43555V7.64007L10.4998 11.9355L4.77258 7.64007Z"
        fill="#EA4335"
      />
      <path
        d="M0 2.15158V4.06066L4.77272 7.6402V1.43567L3.43636 0.433402C2.02005 -0.628526 0 0.382096 0 2.15158Z"
        fill="#C5221F"
      />
    </svg>
  )
}
