import { ContainerFlex } from '@app/components/Container'
import IconVector from '@app/components/Icon/IconVector'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { CardInfo } from '@app/components-legacy/Card/CardInfo/CardInfo'
import { ContainerFooter } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { Modal, ModalCancelButton } from '@app/components-legacy/Modal'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onRelaysRenewApiKeyModalConfirmClick } from './handlers'

export interface IIoAAttacksConfirmCloseModalProps {}

const RelaysModalConfirmRenewApiKey: React.FC<
  IIoAAttacksConfirmCloseModalProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.System.Relays']
  })

  const { storeManagementRelays } = useStores()

  return (
    <Modal
      modalProps={{
        title: translate('Are you sure'),
        footer: (
          <ContainerFooter variant="modal">
            <ModalCancelButton
              storeModal={storeManagementRelays.storeModalConfirmRenewApiKey}
            />

            <FormWrapperButton
              labelledBy="submit"
              buttonProps={{
                type: 'primary',
                onClick: onRelaysRenewApiKeyModalConfirmClick(
                  storeManagementRelays
                ),
                loading:
                  storeManagementRelays.storeFlagsRelaysRenewApiKey.flags
                    .isLoading
              }}
            >
              {translate('Continue')}
            </FormWrapperButton>
          </ContainerFooter>
        )
      }}
      storeModal={storeManagementRelays.storeModalConfirmRenewApiKey}
    >
      <p>
        <Label>
          {translate('You are about to renew one or several Relay API keys')}
        </Label>
      </p>
      <p>
        <Label>{translate('In general we do not recommend this action')}</Label>
      </p>
      <p>
        <Label variant={LabelVariant.p_bold}>
          {translate(
            'Take this action only if you detected a secret compromise'
          )}
        </Label>
      </p>
      <CardInfo
        title={translate('Expect the following impacts')}
        icon={IconVector}
      >
        <ContainerFlex
          name="RelaysModalConfirmRenewApiKeyInfo"
          direction="column"
          items={[
            <Label>
              -{' '}
              {translate(
                'The security analysis for the domain stops until the data refresh completes'
              )}
            </Label>,
            <Label>
              - {translate('The computing load of the platform may increase')}
            </Label>,
            <Label>
              -{' '}
              {translate(
                'Tenable Identity Exposure may generate multiple deviances and resolutions'
              )}
            </Label>
          ]}
        />
      </CardInfo>
    </Modal>
  )
}

export default observer(RelaysModalConfirmRenewApiKey)
