import { consts } from '@app/styles'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '../Container'
import HeaderBreadcrumb from './HeaderBreadcrumb'

interface IHeadBarContentProps {
  style?: React.CSSProperties
  className?: string
  hasBlades: boolean
}

const HeadBarContent: React.FC<IHeadBarContentProps> = props => {
  return (
    <ContainerFlex
      name="HeadBarContent"
      className={props.className}
      direction="column"
      justifyContent="center"
      items={[
        <ContainerFlex
          name="HeaderBreadcrumbs"
          items={[<HeaderBreadcrumb />]}
        />
      ]}
    />
  )
}

export default styled(HeadBarContent)`
  background-color: ${props =>
    props.hasBlades
      ? isThemeLegacy(props.theme)
        ? consts.bladesOverlayBackground(true)
        : props.theme.tokens['headBar/color/background']
      : 'transparent'};
  height: ${({ theme }) => theme.tokens['headBar/height/default']};
  line-height: ${({ theme }) => theme.tokens['headBar/height/default']};
`
