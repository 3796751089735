import * as React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  border-radius: ${({ theme }) => theme.tokens['card/borderRadius/large']};
  padding: ${({ theme }) => theme.tokens['card/padding/default']};
  background-color: ${({ theme }) =>
    theme.tokens['card/color/backgroundWhite']};
  box-shadow: ${({ theme }) => theme.tokens['card/boxShadow/light']};
`

interface ICardSimpleProps {
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}

export function CardSimple(props: ICardSimpleProps) {
  return (
    <StyledDiv className={props.className} style={props.style}>
      {props.children}
    </StyledDiv>
  )
}
