import { LayoutPDF } from '@app/components-legacy/Layout'
import * as React from 'react'
import IoABoardPagesPDF from './IoABoardPagesPDF'

interface IIoABoardPagePDFProps {}

const IoABoardPagePDF: React.FC<IIoABoardPagePDFProps> = () => {
  return <LayoutPDF content={<IoABoardPagesPDF />} />
}

export default IoABoardPagePDF
