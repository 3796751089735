import type { IAttackPathCoords } from '@app/pages/AttackPath/types'

/**
 * Retuns the coords of a point just before the end
 */
export default function getPositionBeforeTheEnd(
  point1: IAttackPathCoords,
  point2: IAttackPathCoords,
  distanceToTheEnd: number
) {
  const d = Math.sqrt(
    Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
  )

  return {
    x: point2.x - (distanceToTheEnd * (point2.x - point1.x)) / d,
    y: point2.y - (distanceToTheEnd * (point2.y - point1.y)) / d
  }
}
