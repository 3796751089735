import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canCreateHoneyAccount } from '../permissions'
import { onHoneyAccountCreatePageLoad } from './handlers'
import HoneyAccountCommonLayout from './HoneyAccountCommonLayout'
import HoneyAccountCreateForm from './HoneyAccountCreateForm'

interface IHoneyAccountCreatePageProps {}

const HoneyAccountCreatePage: React.FC<
  IHoneyAccountCreatePageProps
> = props => {
  const appRouter = useAppRouter()
  const { storeManagementDirectories } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_System_Directories_Honey_Account_Create,
    parameters: {
      directoryId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <Blade
      routeDefinition={{
        routeName:
          AppRouteName.Management_System_Directories_Honey_Account_Create,
        parameters: { directoryId: parameters.directoryId }
      }}
      onLoad={onHoneyAccountCreatePageLoad(storeManagementDirectories)(
        parameters.directoryId
      )}
      flags={[
        storeManagementDirectories.storeFlags.flags,
        storeManagementDirectories.storeHoneyAccountConfigurationScriptFlags
          .flags
      ]}
      layout={{
        name: 'default',
        content: (
          <HoneyAccountCommonLayout directoryId={parameters.directoryId}>
            <HoneyAccountCreateForm directoryId={parameters.directoryId} />
          </HoneyAccountCommonLayout>
        ),

        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canCreateHoneyAccount(parameters.directoryId)}
    />
  )
}

export default observer(HoneyAccountCreatePage)
