import { ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { FormWrapperSwitch } from '@app/components-legacy/Form/Wrappers'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { BackgroundColorV2, FontColorV2 } from '@app/styles/consts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { canEditDataCollectionConfiguration } from '../permissions'
import { handleDataCollectionSensitiveDataStatusOnUpdate } from './handlers'

interface IConfigurationDataCollectionSensitiveDataProps {
  className?: string
}

const ConfigurationDataCollectionSensitiveData: React.FC<
  IConfigurationDataCollectionSensitiveDataProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.DataCollection']
  })

  const { storeManagementDataCollection, storeRbac } = useStores()

  const isEnabled =
    storeManagementDataCollection.dataCollectionStatusEntity?.getPropertyAsBoolean(
      'isEnabled'
    )

  const sensitiveDataEnabled =
    storeManagementDataCollection.dataCollectionStatusEntity?.getPropertyAsBoolean(
      'sensitiveDataEnabled'
    )

  const disabled =
    !storeRbac.isUserGrantedTo(canEditDataCollectionConfiguration) || !isEnabled

  return (
    <ContainerFlex
      name="ConfigurationDataCollectionSensitiveData"
      className={props.className}
      itemsFlexShrink={[0, 1]}
      items={[
        <FormWrapperSwitch
          labelledBy="tenableCloudSensitiveData"
          switchProps={{
            disabled,
            loading:
              storeManagementDataCollection.storeFlagsDataCollectionStatusFetch
                .isLoading,
            checked: sensitiveDataEnabled,
            onChange: handleDataCollectionSensitiveDataStatusOnUpdate(
              storeManagementDataCollection
            )
          }}
        />,
        <Label
          variant={LabelVariant.p}
          color={disabled ? FontColorV2.secondary : FontColorV2.primary}
        >
          {translate(
            'By activating this option you indicate that the data collected via the Privileged analysis'
          )}
        </Label>
      ]}
      spaced
      spaceWidth="smaller"
    />
  )
}

const ObservedConfigurationDataCollectionSensitiveData = observer(
  ConfigurationDataCollectionSensitiveData
)

export default styled(ObservedConfigurationDataCollectionSensitiveData)`
  background-color: ${BackgroundColorV2.white};
  padding: ${consts.paddingDefault} ${consts.paddingMedium};
`
