import {
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import {
  WidgetListBulkActions,
  WidgetListCountItems,
  WidgetListPagination,
  WidgetListSelectAllCheckbox
} from '@app/components-legacy/Widgets/WidgetList'
import type { AdObjectId } from '@app/entities/EntityAdObject/types'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { DeviantObjectBulkActionValue } from '@app/pages/IoE/IoECheckerDetailsPage/DetailsDeviantObjectsPage/types'
import type StoreIndicatorDeviantObjectDeviances from '@app/stores/IoE/StoreIndicator/StoreIndicatorDeviantObjectDeviances'
import type { IDrawerIgnoreUntilData } from '@app/stores/IoE/StoreIndicator/types'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  onDeviancesBulkActionSelection,
  onDeviancesPageChange
} from './handlers'

interface IDeviancesCardsFooterProps {
  className?: string
  checkerId: number
  checkerCodename: GenericCheckerCodename
  adObjectId: AdObjectId
  storeIndicatorDeviantObjectDeviances: StoreIndicatorDeviantObjectDeviances
}

const DeviancesCardsFooter: React.FC<IDeviancesCardsFooterProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['IoE.Details.DeviantObjects']
  })

  const {
    storeIoE: {
      storeIndicator: { storeIndicatorDeviantObjects }
    }
  } = useStores()

  const { storeWidgetListDeviances } =
    props.storeIndicatorDeviantObjectDeviances

  const drawerData: IDrawerIgnoreUntilData = {
    objectType: 'deviance',
    checkerId: props.checkerId,
    checkerCodename: props.checkerCodename,
    adObjectId: props.adObjectId
  }

  return (
    <ContainerFooter
      data-name="DeviancesCardsFooter"
      className={props.className}
    >
      <ContainerFlex
        name="DeviancesCardsFooterContent"
        justifyContent="space-between"
        items={[
          <ContainerFlex
            name="DeviancesCardsFooterContentLeft"
            alignItems="center"
            items={[
              <WidgetListSelectAllCheckbox
                storeWidgetList={storeWidgetListDeviances}
              />,

              <WidgetListCountItems
                label={translate('Number of selected deviances', {
                  interpolations: {
                    count: storeWidgetListDeviances.countSelectedRows,
                    totalCount: storeWidgetListDeviances.paginationTotalCount
                  }
                })}
                storeWidgetList={storeWidgetListDeviances}
              />,

              <WidgetListBulkActions
                options={[
                  {
                    label: translate('Ignore selected deviances'),
                    value: DeviantObjectBulkActionValue.ignore
                  },
                  {
                    label: translate('Stop ignoring selected deviances'),
                    value: DeviantObjectBulkActionValue.unignore
                  }
                ]}
                onChange={onDeviancesBulkActionSelection(
                  storeIndicatorDeviantObjects
                )(drawerData)}
                width="large"
                storeWidgetList={storeWidgetListDeviances}
              />
            ]}
            spaced
          />,

          <WidgetListPagination
            storeWidgetList={storeWidgetListDeviances}
            onChange={onDeviancesPageChange(
              props.storeIndicatorDeviantObjectDeviances
            )(props.checkerCodename)}
          />
        ]}
      />
    </ContainerFooter>
  )
}

const ObservedDeviancesCardsFooter = observer(DeviancesCardsFooter)

export default styled(ObservedDeviancesCardsFooter)`
  padding: ${consts.paddingDefault};
`
