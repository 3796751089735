import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex, ContainerIcon } from '../Container'
import {
  IconMinusCircleOutlined,
  IconPlusCircleOutlined
} from '../Icon/IconAntd'

interface IFormGroupTitleProps {
  className?: string
  count: number
  onRemoveClick: () => void
  onAddClick: () => void
}

const FormGroupTitle: React.FC<IFormGroupTitleProps> = props => {
  return (
    <ContainerFlex
      name="FormGroupTitle"
      className={props.className}
      alignItems="center"
      justifyContent="space-between"
      items={[
        <div>#{props.count + 1}</div>,

        <ContainerFlex
          name="FormGroupTitleButtons"
          items={[
            <ContainerIcon
              labelledBy="removeValue"
              iconComponent={IconMinusCircleOutlined}
              iconProps={{
                onClick: props.onRemoveClick
              }}
            />,

            <ContainerIcon
              labelledBy="addValue"
              iconComponent={IconPlusCircleOutlined}
              iconProps={{
                onClick: props.onAddClick
              }}
            />
          ]}
        />
      ]}
    />
  )
}

export default styled(FormGroupTitle)`
  background: ${consts.colorBlueGray040};
  padding: ${consts.paddingVerySmall} ${consts.paddingDefault};
`
