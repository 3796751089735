import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks/useStores'
import MenuAccounts from '@app/pages/Management/AccountsPage/MenuAccounts'
import type { IManagementAccountsRolesEditRouteDefinition } from '@app/routes'
import { AppRouteName } from '@app/routes'
import { isRoleReadOnly } from '@app/stores/Management/Rbac/functions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  canAccessToRoles,
  canCreateRoles,
  canEditRole,
  canReadRole
} from '../permissions'
import RolesCreatePage from '../RolesCreatePage'
import RolesEditPage from '../RolesEditPage'
import DrawerRoleDelete from '../RolesEditPage/DrawerRoleDelete'
import { handleRolesPageOnLoad, handleRolesPageOnUnload } from './handlers'
import RolesList from './RolesList'

interface IRolesPageProps {}

const RolesPage: React.FC<IRolesPageProps> = () => {
  const { storeManagementRbacRoles, storeRbac } = useStores()

  return (
    <>
      <Blade
        root={true}
        routeDefinition={{
          routeName: AppRouteName.Management_Accounts_Roles,
          parameters: {}
        }}
        onLoad={handleRolesPageOnLoad(storeManagementRbacRoles)}
        onUnload={handleRolesPageOnUnload(storeManagementRbacRoles)}
        flags={storeManagementRbacRoles.storeFetchRbacRolesFlags.flags}
        layout={{
          name: 'default',
          menu: <MenuAccounts />,
          content: (
            <ContainerContent>
              <RolesList />
            </ContainerContent>
          ),
          footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
        }}
        rbacCapabilityCheck={canAccessToRoles}
      />

      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName: AppRouteName.Management_Accounts_Roles_Create,
              parameters: {}
            },
            component: RolesCreatePage,
            rbacPermissionsCheck: () => canCreateRoles
          },
          {
            routeDefinition: {
              routeName: AppRouteName.Management_Accounts_Roles_Edit,
              parameters: {
                roleId: Number()
              }
            },
            component: RolesEditPage,
            rbacPermissionsCheck: (
              parameters: IManagementAccountsRolesEditRouteDefinition['parameters']
            ) => {
              const isReadableOnly = isRoleReadOnly(
                parameters.roleId,
                storeRbac
              )

              return isReadableOnly
                ? canReadRole(parameters.roleId)
                : canEditRole(parameters.roleId)
            }
          }
        ]}
      />

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerRoleDelete />
      </Portal>
    </>
  )
}

export default observer(RolesPage)
