import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface ITypographySubTitleProps {
  className?: string
  children?: React.ReactNode
}

const TypographySubTitle: React.FC<ITypographySubTitleProps> = props => {
  return (
    <h3 data-name="TypographySubTitle" className={props.className}>
      {props.children}
    </h3>
  )
}

export default styled(TypographySubTitle)`
  text-transform: uppercase;
  font-weight: ${consts.fontWeightBold};
  font-size: ${consts.textSizeVeryLarge};
  color: ${consts.colorBlueGray010};

  /* Override margin set by Antd */
  margin-top: 0;
  margin-bottom: 0;
`
