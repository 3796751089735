import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import { BrandColorV2, FontColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

const Color1Path = styled.path`
  fill: ${FontColorV2.primary};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${consts.colorWhite};
  }
`

const Color2Path = styled.path`
  fill: ${BrandColorV2.decorativeGray};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${consts.colorFadedBlueNewDesign};
  }
`

interface IIconIoEProps extends IIconProps {}

const IconIoELegacy: React.FC<IIconIoEProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Color2Path d="M0.893551 9.23668C0.985988 9.14352 1.04761 9.08141 1.14005 8.98825C2.95796 7.37348 5.39212 6.59715 7.70303 6.75241C8.78145 6.84557 9.79825 7.12505 10.7534 7.5598C11.5854 6.90768 12.5713 6.19345 13.7114 5.32396C11.8627 4.36131 9.73663 3.89551 7.70303 4.01972C4.71425 4.17499 1.87954 5.57239 0 7.71506C0 7.71506 0 7.71506 0 7.74612L0.893551 9.23668Z" />
      <Color2Path d="M16.6695 7.52875C15.7451 8.77088 14.944 9.82669 14.2661 10.7272C14.9132 11.783 15.2829 12.9631 15.4062 14.1742C15.437 14.3915 15.6218 14.5778 15.8683 14.5778H19.535C19.8123 14.5778 20.028 14.3294 19.9972 14.0189C19.535 11.5657 18.3641 9.26773 16.6695 7.52875Z" />
      <Color1Path d="M17.1764 4.85188C17.3613 4.60345 17.0532 4.26187 16.8067 4.47924C14.8039 6.00085 11.3529 8.64038 9.35014 10.193C9.13445 10.1309 8.91877 10.0999 8.67227 10.0999C7.07004 10.0999 5.74512 11.4041 5.74512 13.0499C5.74512 14.6647 7.03923 16 8.67227 16C10.3053 16 11.5994 14.6958 11.5994 13.0499C11.5994 12.8326 11.5686 12.5841 11.507 12.3668C13.0784 10.3483 15.6666 6.87034 17.1764 4.85188Z" />
    </svg>
  )
}

export default IconIoELegacy
