import { InputSearch } from '@app/components-legacy/Input'
import {
  WidgetListActionsHeader,
  WidgetListSelectAllCheckbox,
  WidgetListCountItems,
  WidgetListTable
} from '@app/components-legacy/Widgets/WidgetList'
import type { EntityRbacRole } from '@app/entities'
import type { IDataRowRbacRole } from '@app/entities/EntityRbacRole'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'

interface IUserRolesListProps {}

const UserRolesList: React.FC<IUserRolesListProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Users']
  })

  const { storeManagementUsers } = useStores()

  return (
    <>
      <InputSearch
        placeholder={translate('Search a role')}
        storeInputSearch={storeManagementUsers.storeInputSearchRbacRoles}
      />

      <WidgetListActionsHeader>
        <WidgetListSelectAllCheckbox
          storeWidgetList={storeManagementUsers.storeWidgetRbacRolesList}
        />

        <WidgetListCountItems
          storeWidgetList={storeManagementUsers.storeWidgetRbacRolesList}
        />
      </WidgetListActionsHeader>

      <WidgetListTable<EntityRbacRole, IDataRowRbacRole>
        translate={translate}
        hiddenColumnsKeys={['id']}
        storeWidgetList={storeManagementUsers.storeWidgetRbacRolesList}
      />
    </>
  )
}

export default UserRolesList
