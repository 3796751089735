import type { Perhaps } from '@@types/helpers'
import type StoreDrawer from '@app/stores/helpers/StoreDrawer'
import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { If } from '@libs/fp-helpers/If'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'

/**
 * Load checkers at mount or not.
 */
export function handleInputCheckersOnLoad<GC extends TGenericChecker>(
  storeInputGenericCheckers: StoreInputGenericCheckers<GC>
) {
  return (loadAtMount: Perhaps<boolean>) => () => {
    if (!loadAtMount) {
      return
    }

    If(!storeInputGenericCheckers.checkersByCodename.size).fetch(() =>
      storeInputGenericCheckers.fetchCheckers()
    )
  }
}

/**
 * When searching a checker and unselect other checkers.
 *
 * The user have to select again once it has finished his search.
 */
export function handleInputCheckersOnSearch<GC extends TGenericChecker>(
  storeInputGenericCheckers: StoreInputGenericCheckers<GC>
) {
  return () => storeInputGenericCheckers.unselectAllCheckers()
}

/**
 * Select or unselect all checkers.
 */
export function handleInputCheckersSelectAllOnClick<GC extends TGenericChecker>(
  storeInputGenericCheckers: StoreInputGenericCheckers<GC>
) {
  return (e: CheckboxChangeEvent) => {
    e.target.checked
      ? storeInputGenericCheckers.selectAllSearchedCheckers()
      : storeInputGenericCheckers.unselectAllCheckers()
  }
}

/**
 * Validate the checkers selection.
 */
export function handleInputCheckersDrawerOnSubmit(
  storeDrawer: StoreDrawer<any>
) {
  return (onValidate?: () => void) => () => {
    storeDrawer.closeDrawer()

    if (onValidate) {
      onValidate()
    }
  }
}
