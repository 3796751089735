import BladeHeader from '@app/components-legacy/Blade/BladeHeader'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DomainDetailsGauge from './DomainDetailsGauge'

export interface IDomainDetailsBladeHeaderProps {}

const DomainDetailsBladeHeader: React.FC<
  IDomainDetailsBladeHeaderProps
> = () => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoE.Details']
  })

  const appRouter = useAppRouter()

  const { storeTopology } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Topology_DomainDetails,
    parameters: {
      directoryId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  const getInfos = () => {
    const directory =
      storeTopology.storeTopologyDomainDetails.getDirectoryFromId(
        parameters.directoryId
      )

    if (!directory) {
      return []
    }

    const domainScore = storeTopology.storeTopologyDomainDetails.domainScore

    return [
      {
        rawType: 'Name',
        type: translate('Name'),
        value: directory.name
      },
      {
        rawType: 'Score',
        type: 'Score',
        showValueOnly: true,
        value: (
          <DomainDetailsGauge score={domainScore} width={100} height={50} />
        )
      }
    ]
  }

  return <BladeHeader icon="box" infos={getInfos()} />
}

export default observer(DomainDetailsBladeHeader)
