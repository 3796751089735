import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { LabelDocuments, LabelMissingData } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import { CheckerDetailsPageContext } from '../context'

export interface IDocumentsProps {}

const Documents: React.FC<IDocumentsProps> = props => {
  const parameters = React.useContext(CheckerDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoE.Details.Information']
  })

  const { storeIoE } = useStores()

  if (!parameters) {
    return null
  }

  const renderContent = () => {
    const checker = storeIoE.checkers.get(parameters.checkerCodename)

    if (!checker) {
      return <LabelMissingData />
    }

    const resources = checker.getResources()

    if (!resources.length) {
      return <div>{translate('No document available')}</div>
    }

    const items = resources.map(resource => {
      return (
        <LabelDocuments
          url={resource.url}
          name={resource.name || translate('Unnamed document')}
          type={resource.type}
        />
      )
    })

    if (!items.length) {
      return <div>{translate('No documents listed for this indicator')}</div>
    }

    return (
      <ContainerFlex
        name="Documents"
        direction="column"
        items={items}
        spaced
        spaceWidth="verySmall"
      />
    )
  }

  return (
    <ContainerContent title={translate('Documents')}>
      {renderContent()}
    </ContainerContent>
  )
}

export default Documents
