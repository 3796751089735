import { ContainerFlex } from '@app/components/Container'
import { CardMainContent } from '@app/components-legacy/Card/CardMainContent/CardMainContent'
import { consts } from '@app/styles'
import { BackgroundColorV2 } from '@app/styles/consts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import HealthCheckContentPage from './HealthCheckContentPage'

const StyledContainerFlex = styled(ContainerFlex)`
  background-color: ${BackgroundColorV2.white};
  border-radius: ${consts.borderRadiusSmallRedesign};
  height: 100%;
`

export interface IHealthCheckMainPageProps {}

const HealthCheckMainPage: React.FC<IHealthCheckMainPageProps> = props => {
  return (
    <CardMainContent fullHeight>
      <StyledContainerFlex
        name="HealthCheckMainPage"
        direction="column"
        items={[<HealthCheckContentPage />]}
      />
    </CardMainContent>
  )
}

export default observer(HealthCheckMainPage)
