import { ContainerIcon } from '@app/components-legacy/Container'
import { IconCloseCircleOutlined } from '@app/components-legacy/Icon/IconAntd'
import * as React from 'react'

interface IIconClose {
  onClick: () => void
}

const IconClose: React.FC<IIconClose> = props => {
  return (
    <ContainerIcon
      labelledBy="close"
      iconComponent={IconCloseCircleOutlined}
      iconProps={{
        onClick: props.onClick
      }}
    />
  )
}

export default IconClose
