import {
  rbacCapabilityCheckAllOf,
  rbacCapabilityCheckAnyOf
} from '@libs/rbac/functions'
import {
  readLicense,
  readUISystemAbout,
  updateLicense
} from '@libs/rbac/permissions'
import { canAccessToSystem } from '../permissions'

export const canAccessToAbout = rbacCapabilityCheckAllOf(
  canAccessToSystem,
  readUISystemAbout()
)

export const canReadLicense = rbacCapabilityCheckAllOf(
  canAccessToAbout,
  readLicense()
)

export const canUpdateLicense = rbacCapabilityCheckAllOf(
  canAccessToAbout,
  updateLicense()
)

export const canReadOrUpdateLicense = rbacCapabilityCheckAnyOf(
  canReadLicense,
  canUpdateLicense
)
