import type { Maybe } from '@@types/helpers'
import { IconSVG } from '@app/components-legacy/Icon'
import type { AttackPathAdObjectType } from '@server/graphql/typeDefs/types'
import * as React from 'react'

export interface INodeObjectIconProps {
  adObjectType?: Maybe<AttackPathAdObjectType>
  size?: number
}

const NodeObjectIcon: React.FC<INodeObjectIconProps> = props => {
  if (!props.adObjectType) {
    return null
  }

  return (
    <IconSVG
      iconName={`attackpath/object-${props.adObjectType}`}
      size={props.size}
    />
  )
}

export default NodeObjectIcon
