import { addSetValueToMap } from './setValueToMap'

/**
 * Index entities to a map with the id as key.
 */
export function indexEntitiesToMap<T, K>(
  entities: T[],
  key: keyof T
): Map<K, T> {
  return entities.reduce((acc, entity) => {
    const id = entity[key] as unknown as K

    if (!id) {
      return acc
    }

    acc.set(id, entity)
    return acc
  }, new Map<K, T>())
}

/**
 * Append entities to a map with the id as key.
 */
export function appendEntitiesToMap<T, K>(
  entities: T[],
  key: keyof T
): Map<K, Set<T>> {
  const map: Map<K, Set<T>> = new Map()

  entities.forEach(entity => {
    const id = entity[key] as unknown as K

    if (!id) {
      return
    }

    addSetValueToMap(map, id, entity)
  })

  return map
}
