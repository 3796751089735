import {
  ContainerFooter,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import * as React from 'react'
import { handlerEditCardFormSubmit } from './handlers'
import InputIllustration from './InputIllustration'

export interface IIoABoardCardFormProps {}

const IoABoardCardForm: React.FC<IIoABoardCardFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoA.Board']
  })

  const { storeIoA } = useStores()
  const { storeSummaryCards } = storeIoA.storeBoard

  const fields: IField[] = [
    {
      name: 'chartType',
      label: translate('Chart type'),
      labelAlignItem: 'flex-start',
      control: <InputIllustration />
    }
  ]

  return (
    <FormWrapper
      name="cardConfiguration"
      onSubmit={handlerEditCardFormSubmit(storeSummaryCards)}
    >
      <ContainerForm fields={fields} variant={ContainerFormVariant.drawer} />

      <Portal name={PlaceHolderName.drawerFooter}>
        <ContainerFooter>
          <DrawerCancelButton />

          <FormWrapperButtonSubmit
            loading={storeSummaryCards.storeFlagsEditCard.isLoading}
          >
            {translate('Save')}
          </FormWrapperButtonSubmit>
        </ContainerFooter>
      </Portal>
    </FormWrapper>
  )
}

export default IoABoardCardForm
