import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconFootPrintProps extends IIconProps {}

const IconFootPrint: React.FC<IIconFootPrintProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 20

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.07281 3.87284C4.27736 2.67482 5.87504 1.74796 7.73203 1.28048C8.07199 1.1949 8.41157 1.127 8.74953 1.07614"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.5367 10.5957C15.1705 7.94188 12.8932 5.89813 10.1386 5.89813C7.12897 5.89813 4.68915 8.33796 4.68915 11.3476C4.68915 13.2079 5.62124 14.8504 7.04386 15.8337C7.66339 16.2819 8.07734 16.4727 8.92421 16.5729C10.5877 16.7698 11.4743 15.4032 11.4743 13.9603C11.4743 12.8781 10.9414 11.8246 10.0037 11.4279"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.8733 13.9621C7.87167 13.4415 7.06252 12.5194 7.06252 11.3125C7.06252 9.5866 8.46162 8.18749 10.1875 8.18749C11.7396 8.18749 13.0275 9.31904 13.2553 10.8027C13.3789 12.5 14.4868 13.6965 15.717 13.8229C17.0719 13.9621 18.0491 12.6483 18.0589 11.195C18.0643 10.5739 17.9916 9.94281 17.8332 9.31364C16.8126 5.25928 12.4461 2.61518 8.28648 3.61343C4.38711 4.54922 1.88239 8.11106 2.17071 11.7403"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.8575 18.7956C9.05718 19.5005 6.23771 18.6176 4.38708 16.7222"
        stroke={fill}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default IconFootPrint
