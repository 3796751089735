/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PingTheService200Response
 */
export interface PingTheService200Response {
  /**
   *
   * @type {string}
   * @memberof PingTheService200Response
   */
  status: PingTheService200ResponseStatusEnum
}

/**
 * @export
 * @enum {string}
 */
export enum PingTheService200ResponseStatusEnum {
  Ok = 'ok',
  Ko = 'ko'
}

/**
 * Check if a given object implements the PingTheService200Response interface.
 */
export function instanceOfPingTheService200Response(value: object): boolean {
  if (!('status' in value)) return false
  return true
}

export function PingTheService200ResponseFromJSON(
  json: any
): PingTheService200Response {
  return PingTheService200ResponseFromJSONTyped(json, false)
}

export function PingTheService200ResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PingTheService200Response {
  if (json == null) {
    return json
  }
  return {
    status: json['status']
  }
}

export function PingTheService200ResponseToJSON(
  value?: PingTheService200Response | null
): any {
  if (value == null) {
    return value
  }
  return {
    status: value['status']
  }
}
