import { EntityChecker } from '@app/entities'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { CheckerRemediationCostLevel } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import GaugeHigh from './GaugeHigh'
import GaugeLow from './GaugeLow'
import GaugeMedium from './GaugeMedium'

export interface ILabelGaugeProps {
  className?: string
  remediationCost: number
}

const LabelGauge: React.FC<ILabelGaugeProps> = props => {
  const remediationCostLevel = EntityChecker.getRemediationCostLevel(
    props.remediationCost
  )

  switch (remediationCostLevel) {
    case CheckerRemediationCostLevel.Low:
      return <GaugeLow />

    case CheckerRemediationCostLevel.Medium:
    case CheckerRemediationCostLevel.Unknown:
      return <GaugeMedium />

    case CheckerRemediationCostLevel.High:
      return <GaugeHigh />

    default:
      assertUnreachableCase(remediationCostLevel)
  }
}

export default LabelGauge
