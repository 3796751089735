import { useStores } from '@app/hooks/useStores'
import { flatMap } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import Infrastructures from './Infrastructures'
import Trusts from './Trusts'

interface IChartContentProps {}

const ChartContent: React.FC<IChartContentProps> = () => {
  const { storeTopology } = useStores()

  const infrastructureSceneObjects =
    storeTopology.topologyEntity.computeInfrastructureSceneObjects(
      storeTopology.chartScaleWidth,
      storeTopology.chartScaleHeight
    )

  if (infrastructureSceneObjects.length > 0) {
    storeTopology.setinfrastructuresSceneObjects(infrastructureSceneObjects)
  }

  const directorySceneObjects =
    storeTopology.topologyEntity.computeDirectorySceneObjects(
      infrastructureSceneObjects
    )

  return (
    <>
      <Trusts
        directorySceneObjects={flatMap(
          Array.from(directorySceneObjects.values())
        )}
        renderOnly="external"
      />

      <Infrastructures
        infrastructureSceneObjects={infrastructureSceneObjects}
        directorySceneObjects={directorySceneObjects}
      />

      <Trusts
        directorySceneObjects={flatMap(
          Array.from(directorySceneObjects.values())
        )}
        renderOnly="internal"
      />
    </>
  )
}

export default observer(ChartContent)
