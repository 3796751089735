import { ContainerFlex } from '@app/components/Container'
import LabelTidAddOnError from '@app/components/Labels/LabelTidAddOnError'
import { useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import IoEBoardCards from './IoEBoardCards'
import IoEBoardFilters from './IoEBoardFilters'

export interface IIoEBoardContentProps {}

function IoEBoardContent(props: IIoEBoardContentProps) {
  const { storeInfrastructures, storeIoE } = useStores()

  return (
    <ContainerFlex
      name="IoEBoardContent"
      direction="column"
      flexGap="default"
      fullHeight
      items={[
        <IoEBoardFilters />,

        storeIoE.showIoEIdentitiesConnectionError && <LabelTidAddOnError />,

        <IoEBoardCards
          storeIoE={storeIoE}
          directoryIds={storeInfrastructures.selectedDirectoryIds}
          tenantIds={storeIoE.storeInputTenants.selectedTenantIds}
        />
      ]}
    />
  )
}

export default observer(IoEBoardContent)
