import {
  ContainerDividerFilter,
  ContainerFlex,
  ContainerSimple
} from '@app/components-legacy/Container'
import { IconClockCircleOutlined } from '@app/components-legacy/Icon/IconAntd'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { AttacksSummaryPeriod } from '@server/graphql/typeDefs/types'
import { Radio, Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import FiltersDatePicker from './FiltersDatePicker'
import {
  handleTimelineNowButton,
  handleTimelinePeriodSelection
} from './handlers'

interface IFiltersDateProps {
  className?: string
}

const RadioGroupStyled = styled(Radio.Group)`
  min-width: 255px;
`

const FiltersDate: React.FC<IFiltersDateProps> = props => {
  const translate = useAppTranslator({ namespaces: ['IoA.Board'] })

  const { storeIoA } = useStores()
  const { storeTimeline } = storeIoA.storeBoard

  if (!storeTimeline.attacksSummaryPeriod) {
    return null
  }

  const radioButtons = Object.values(AttacksSummaryPeriod).map(
    dateFormatType => {
      const lowerCaseDateFormatType = dateFormatType.toLowerCase()

      return (
        <Radio.Button
          key={lowerCaseDateFormatType}
          value={lowerCaseDateFormatType}
        >
          {translate(lowerCaseDateFormatType)}
        </Radio.Button>
      )
    }
  )

  return (
    <ContainerFlex
      name={FiltersDate.name}
      alignItems="center"
      items={[
        <RadioGroupStyled
          onChange={handleTimelinePeriodSelection(storeIoA)}
          defaultValue={storeTimeline.attacksSummaryPeriod}
        >
          {radioButtons}
        </RadioGroupStyled>,

        <ContainerDividerFilter />,

        <FiltersDatePicker />,

        <Tooltip title={translate('Reload at the current date')}>
          <ContainerSimple
            ariaRoles={['menubar', 'img']}
            labelledBy="setDateStartToNow"
            containerProps={{
              onClick: handleTimelineNowButton(storeIoA)
            }}
          >
            <IconClockCircleOutlined />
          </ContainerSimple>
        </Tooltip>
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(FiltersDate)
