import type { StoreAuthentication } from '@app/stores'
import type StoreForm from '@app/stores/helpers/StoreForm'
import type StoreIndicatorDeviantObjects from '@app/stores/IoE/StoreIndicator/StoreIndicatorDeviantObjects'
import type { IDrawerIgnoreUntilData } from '@app/stores/IoE/StoreIndicator/types'
import type {
  InputEditAdObjectDeviances,
  InputEditCheckerDeviances,
  InputEditDeviance,
  Maybe
} from '@server/graphql/typeDefs/types'
import type * as moment from 'moment'

/**
 * On date selection, save it in the store.
 */
export const onIgnoreUntilDateChange =
  (storeForm: StoreForm<any>) => (value: Maybe<moment.Moment>) => {
    if (!value) {
      return
    }

    storeForm.setFieldValue(
      'ignoreUntil',
      value.utc().endOf('day').toISOString()
    )
  }

/**
 * Set ignoreUntil date to deviances according to the selection context.
 */
export const onDeviantObjectsSetIgnoreDateSubmit =
  (
    storeAuthentication: StoreAuthentication,
    storeIndicatorDeviantObjects: StoreIndicatorDeviantObjects
  ) =>
  (ignoreUntil: string) =>
  () => {
    const { logger } = storeIndicatorDeviantObjects.storeRoot

    const { storeDrawerIgnoreObjets } = storeIndicatorDeviantObjects

    const drawerData = storeDrawerIgnoreObjets.data

    if (!drawerData) {
      logger.warn('Missing drawer data')
      return
    }

    editDeviances(
      storeAuthentication,
      storeIndicatorDeviantObjects
    )(drawerData)(ignoreUntil)
      .then(() => {
        const checkerCodename =
          storeDrawerIgnoreObjets.getDataValue('checkerCodename')

        if (!checkerCodename) {
          logger.warn('Missing checkerCodename')
          return
        }

        const args =
          storeIndicatorDeviantObjects.computeFetchDeviantObjectsArgs(
            checkerCodename
          )

        if (!args) {
          logger.warn('Missing mutation args')
          return
        }

        storeIndicatorDeviantObjects.reloadDeviantObjects(args)

        storeDrawerIgnoreObjets.closeDrawer()
      })
      .catch(() => {
        // should be already logged
      })
  }

/**
 * Remove ignoreUntil date to deviances according to the selection context.
 */
export const onDeviantObjectsUnsetIgnoreDateSubmit =
  (
    storeAuthentication: StoreAuthentication,
    storeIndicatorDeviantObjects: StoreIndicatorDeviantObjects
  ) =>
  () => {
    const { logger } = storeIndicatorDeviantObjects.storeRoot

    const { storeDrawerUnignoreObjets } = storeIndicatorDeviantObjects

    const drawerData = storeDrawerUnignoreObjets.data

    if (!drawerData) {
      logger.warn('Missing drawer data')
      return
    }

    editDeviances(
      storeAuthentication,
      storeIndicatorDeviantObjects
    )(drawerData)(null)
      .then(() => {
        const checkerCodename =
          storeDrawerUnignoreObjets.getDataValue('checkerCodename')

        if (!checkerCodename) {
          logger.warn('Missing checkerCodename')
          return
        }

        const args =
          storeIndicatorDeviantObjects.computeFetchDeviantObjectsArgs(
            checkerCodename
          )

        if (!args) {
          logger.warn('Missing mutation args')
          return
        }

        storeIndicatorDeviantObjects.reloadDeviantObjects(args)

        storeDrawerUnignoreObjets.closeDrawer()
      })
      .catch(err => {
        logger.warn(
          'An error has occurred when updating deviances',
          err.message
        )
      })
  }

/**
 * Set an ignore date to selected deviant objects.
 * If `ignoreUntil` is null, unset the ignoreUntil date.
 */
export const editDeviances =
  (
    storeAuthentication: StoreAuthentication,
    storeIndicatorDeviantObjects: StoreIndicatorDeviantObjects
  ) =>
  (drawerData: Partial<IDrawerIgnoreUntilData>) =>
  (ignoreUntil: Maybe<string>): Promise<any> => {
    const { logger } = storeIndicatorDeviantObjects.storeRoot

    const profileId = storeAuthentication.currentProfileId

    const { storeInputExpression } = storeIndicatorDeviantObjects
    const { storeWidgetListDeviantObjects } = storeIndicatorDeviantObjects

    const objectType = drawerData.objectType
    const checkerId = drawerData.checkerId
    const adObjectId = drawerData.adObjectId

    if (!checkerId) {
      logger.warn('Missing checkerId')
      return Promise.reject()
    }

    /**
     * If the drawer is coming from some selected objects.
     */

    if (objectType === 'object') {
      /**
       * If all objects are selected, update all deviances of the checker.
       */

      if (storeWidgetListDeviantObjects.isAllRowsSelected) {
        const editCheckerDeviancesVariables: InputEditCheckerDeviances = {
          profileId,
          checkerId,
          reasonIds: storeIndicatorDeviantObjects.storeInputReasons.reasonIds,
          adObjectExpression:
            storeInputExpression.expression.expressionAsString,
          ...storeIndicatorDeviantObjects.storeDatePicker
            .datePickerRangeValueAsIsoStrings,
          ignoreUntil
        }

        return storeIndicatorDeviantObjects.editCheckerDeviances(
          editCheckerDeviancesVariables
        )
      }

      /**
       * Otherwise, update all deviances of one or several adObjects.
       */

      const editAdObjectDeviancesVariables: InputEditAdObjectDeviances[] =
        storeWidgetListDeviantObjects.selectedRowsAsArray.map(adObject => {
          return {
            profileId,
            checkerId,
            adObjectId: adObject.id,
            ignoreUntil
          }
        })

      return storeIndicatorDeviantObjects.editAdObjectDeviances(
        editAdObjectDeviancesVariables
      )
    }

    /**
     * If the drawer is coming from some selected deviances.
     */

    /**
     * If all deviances of an adObject are selected,
     * update all the deviance of that object.
     */

    if (!adObjectId) {
      logger.warn('Missing adObjectId')
      return Promise.reject()
    }

    const storeIndicatorDeviantObjectDeviances =
      storeIndicatorDeviantObjects.storesIndicatorDeviantObjectDeviances.get(
        adObjectId
      )

    if (!storeIndicatorDeviantObjectDeviances) {
      logger.warn('Missing storeIndicatorDeviantObjectDeviances')
      return Promise.reject()
    }

    if (
      storeIndicatorDeviantObjectDeviances.storeWidgetListDeviances
        .isAllRowsSelected
    ) {
      const editCheckerDeviancesVariables: InputEditAdObjectDeviances[] = [
        {
          profileId,
          checkerId,
          adObjectId,
          reasonIds: storeIndicatorDeviantObjects.storeInputReasons.reasonIds,
          ...storeIndicatorDeviantObjects.storeDatePicker
            .datePickerRangeValueAsIsoStrings,
          ignoreUntil
        }
      ]

      return storeIndicatorDeviantObjects.editAdObjectDeviances(
        editCheckerDeviancesVariables
      )
    }

    /**
     * Otherwise, update one ore more ad-hoc deviances.
     */

    const editDeviancesVariables: InputEditDeviance[] =
      storeIndicatorDeviantObjectDeviances.storeWidgetListDeviances.selectedRowsAsArray.map(
        deviance => {
          return {
            directoryId: deviance.directoryId,
            devianceId: deviance.id,
            ignoreUntil
          }
        }
      )

    return storeIndicatorDeviantObjects.editDeviances(editDeviancesVariables)
  }
