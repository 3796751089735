import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import { getLogger } from '@libs/logger'
import { rbacCapability } from '@libs/rbac/functions'
import type { RbacEntityItemType, RbacPermissions } from '@libs/rbac/types'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type { Maybe, RbacEntityAction } from '@server/graphql/typeDefs/types'
import { RbacAction, RbacEntityName } from '@server/graphql/typeDefs/types'
import type { EntityRbacEntity } from '.'
import EntityBase from './EntityBase'
import type { IEntityListable, IEntityOmitKeysParameter } from './types'

export interface IDataRowRbacEntityItem {
  id: string
  name: string
  rbacEntityItemType: Maybe<RbacEntityItemType>
  rbacEntityName: RbacEntityName
  rbacReadIdAction: boolean
  rbacReadAllAction: boolean
  rbacEditIdAction: boolean
  rbacEditAllAction: boolean
  rbacCreateAction: boolean
}

const logger = getLogger('EntityRbacEntityItem')

/**
 * Since RbacEntityItem is an union of type, this Entity does not extends one
 * specific type of object but any types of the union.
 *
 * Unfortunately, this class doesn't have a base class to stipulate this behavior,
 * but have only some properties declared and that are used for some objects of
 * the union.
 *
 * The DataRow on this entity is a IDataRowRbacEntityItem object composed of
 * the different properties according to the Rbac entity name.
 */
export default class EntityRbacEntityItem
  extends EntityBase
  implements IEntityListable<IDataRowRbacEntityItem>
{
  type: Maybe<RbacEntityItemType> = null

  // almost all entities (including UI and Singleton entities)
  name: Maybe<string> = null

  // dashboardWidget
  title: Maybe<string> = null

  // adObject
  attributeName: Maybe<string> = null

  // email
  address: Maybe<string> = null

  // syslog
  ip: Maybe<string> = null

  // tenant
  uuid: Maybe<string> = null

  constructor(
    data: Partial<EntityRbacEntityItem>,
    public readonly parent: EntityRbacEntity
  ) {
    super()

    Object.assign(this, data)

    if (!parent) {
      throw new Error('Missing EntityRbacEntityItem parent')
    }
  }

  /**
   * Return true if the current entity grants the access to the requested rbacAction
   * with the set of permissions passed as parameters.
   */
  isGranted = (permissions: RbacPermissions) => (rbacAction: RbacAction) => {
    const dataRow = this.asDataRow()

    if (!dataRow) {
      return false
    }

    return rbacCapability(permissions)(
      dataRow.rbacEntityName,
      rbacAction,
      dataRow.id
    ).isGranted
  }

  /**
   * Return the available Rbac actions according to the parent entity actions flags.
   */
  getAvailableRbacAction() {
    const dataRow = this.asDataRow()

    if (!dataRow) {
      return []
    }

    const rbacActions: RbacAction[] = []

    if (dataRow.rbacReadIdAction || dataRow.rbacReadAllAction) {
      rbacActions.push(RbacAction.Read)
    }

    if (dataRow.rbacEditIdAction || dataRow.rbacEditAllAction) {
      rbacActions.push(RbacAction.Edit)
    }

    if (dataRow.rbacCreateAction) {
      rbacActions.push(RbacAction.Create)
    }

    return rbacActions
  }

  /* Implements IEntityListable */

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowRbacEntityItem> = []
  ): Array<IWidgetListColumns<IDataRowRbacEntityItem>> {
    const columns: Array<IWidgetListColumns<IDataRowRbacEntityItem>> = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Entity name',
        key: 'name'
      },
      {
        label: 'Entity type',
        key: 'rbacEntityItemType'
      },
      {
        label: 'Read',
        key: 'rbacReadIdAction'
      },
      {
        label: 'Read',
        key: 'rbacReadAllAction'
      },
      {
        label: 'Edit',
        key: 'rbacEditIdAction'
      },
      {
        label: 'Edit',
        key: 'rbacEditAllAction'
      },
      {
        label: 'Create',
        key: 'rbacCreateAction'
      }
    ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  asDataRow(): Maybe<IDataRowRbacEntityItem> {
    const rbacEntityName =
      this.parent.getPropertyAsT<RbacEntityName>('entityName')
    const entityAction = this.parent.getPropertyAsT<RbacEntityAction>('actions')

    if (!entityAction) {
      return null
    }

    const actions = {
      rbacEntityName,
      rbacReadIdAction: entityAction.read.id,
      rbacReadAllAction: entityAction.read.all,
      rbacCreateAction: entityAction.create,
      rbacEditIdAction: entityAction.edit.id,
      rbacEditAllAction: entityAction.edit.all
    }

    try {
      switch (rbacEntityName) {
        /**
         * Data entities
         */

        case RbacEntityName.Infrastructure:
        case RbacEntityName.Directory:
        case RbacEntityName.Profile:
        case RbacEntityName.User:
        case RbacEntityName.Role: {
          return {
            id: this.getPropertyAsString('id'),
            name: this.getPropertyAsString('name'),
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        case RbacEntityName.Checker: {
          return {
            id: this.getPropertyAsString('id'),
            name: this.getPropertyAsString('name'),
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        case RbacEntityName.AttackType: {
          return {
            id: this.getPropertyAsString('id'),
            name: this.getPropertyAsString('name'),
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        case RbacEntityName.EmailNotifier: {
          return {
            id: this.getPropertyAsString('id'),
            name: this.getPropertyAsString('address'),
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        case RbacEntityName.Syslog: {
          return {
            id: this.getPropertyAsString('id'),
            name: this.getPropertyAsString('ip'),
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        case RbacEntityName.Plugin:
        case RbacEntityName.PluginConfiguration: {
          return {
            id: this.getPropertyAsString('id'),
            name: this.getPropertyAsString('name'),
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        case RbacEntityName.Report: {
          return {
            id: this.getPropertyAsString('id'),
            name: this.getPropertyAsString('name'),
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        case RbacEntityName.Relay: {
          return {
            id: this.getPropertyAsString('id'),
            name: this.getPropertyAsString('name'),
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        case RbacEntityName.Tenant: {
          return {
            id: this.getPropertyAsString('uuid'),
            name: this.getPropertyAsString('name'),
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        /**
         * UI entities => no data row
         */

        case RbacEntityName.UiDashboard:
        case RbacEntityName.UiTrailFlow:
        case RbacEntityName.UiIoe:
        case RbacEntityName.UiIoeInformations:
        case RbacEntityName.UiIoeVulnerabilityDetails:
        case RbacEntityName.UiIoeDeviantElements:
        case RbacEntityName.UiIoeDeviantElementsExport:
        case RbacEntityName.UiIoeDeviantElementsSetIgnoreDate:
        case RbacEntityName.UiIoeRecommandations:
        case RbacEntityName.UiIoeIdentity:
        case RbacEntityName.UiIoa:
        case RbacEntityName.UiIoaAttackDescription:
        case RbacEntityName.UiIoaAttackYaraRules:
        case RbacEntityName.UiIoaCloseAttack:
        case RbacEntityName.UiIoaEditCard:
        case RbacEntityName.UiIoaExport:
        case RbacEntityName.UiIoaRemoveAttack:
        case RbacEntityName.UiIoaTimeline:
        case RbacEntityName.UiAccounts:
        case RbacEntityName.UiAccountsUsers:
        case RbacEntityName.UiAccountsRoles:
        case RbacEntityName.UiAccountsProfiles:
        case RbacEntityName.UiSystem:
        case RbacEntityName.UiSystemInfrastructures:
        case RbacEntityName.UiSystemDirectories:
        case RbacEntityName.UiSystemConfiguration:
        case RbacEntityName.UiSystemConfigurationSmtpServer:
        case RbacEntityName.UiSystemConfigurationLogs:
        case RbacEntityName.UiSystemConfigurationSecurity:
        case RbacEntityName.UiSystemConfigurationIoa:
        case RbacEntityName.UiSystemConfigurationIoaDownloadGpo:
        case RbacEntityName.UiSystemConfigurationAlertsSyslog:
        case RbacEntityName.UiSystemConfigurationAlertsEmail:
        case RbacEntityName.UiSystemConfigurationPlugin:
        case RbacEntityName.UiSystemConfigurationPluginConfiguration:
        case RbacEntityName.UiSystemConfigurationReportingCenter:
        case RbacEntityName.UiSystemConfigurationTenableAccount:
        case RbacEntityName.UiSystemConfigurationLdap:
        case RbacEntityName.UiSystemConfigurationSaml:
        case RbacEntityName.UiSystemAbout:
        case RbacEntityName.UiPreferences:
        case RbacEntityName.UiAlerts:
        case RbacEntityName.UiAlertsArchiveAlert:
        case RbacEntityName.UiTopology:
        case RbacEntityName.UiAttackPath:
        case RbacEntityName.UiSystemRelay:
        case RbacEntityName.UiSystemConfigurationAppServicesLinkingKey:
        case RbacEntityName.UiTelemetry:
        case RbacEntityName.UiIdentityExplorer:
        case RbacEntityName.UiAssetOverview:
        case RbacEntityName.UiWeaknessOverview: {
          return {
            // id is not used
            id: '-1',
            name: this.getPropertyAsString('name'),
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        /**
         * "Self-user" entities => a data row by type of entity
         */

        case RbacEntityName.Dashboard: {
          return {
            // id is not used
            id: '-1',
            name: RbacEntityName.Dashboard,
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        case RbacEntityName.Widget: {
          return {
            // id is not used
            id: '-1',
            name: RbacEntityName.Widget,
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        case RbacEntityName.Preference: {
          return {
            // id is not used
            id: '-1',
            name: RbacEntityName.Preference,
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        case RbacEntityName.ApiKey: {
          return {
            // id is not used
            id: '-1',
            name: RbacEntityName.ApiKey,
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        case RbacEntityName.SelfUser: {
          return {
            // id is not used
            id: '-1',
            name: RbacEntityName.SelfUser,
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        /**
         * "Singleton" entities => no data row
         */

        case RbacEntityName.Score:
        case RbacEntityName.Topology:
        case RbacEntityName.ApplicationSetting:
        case RbacEntityName.LockoutPolicy:
        case RbacEntityName.LdapConfiguration:
        case RbacEntityName.SamlConfiguration:
        case RbacEntityName.License:
        case RbacEntityName.DirectoryRecrawl:
        case RbacEntityName.ActivityLogs:
        case RbacEntityName.SelfActivityLogs:
        case RbacEntityName.DataCollectionConfiguration:
        case RbacEntityName.HealthCheck:
        case RbacEntityName.TenableCloudApiToken: {
          return {
            // id is not used
            id: '-1',
            name: this.getPropertyAsString('name'),
            rbacEntityItemType: this.type,
            ...actions
          }
        }

        // Rbac not implemented in API
        case RbacEntityName.AdObject:
        case RbacEntityName.AdObjectDevianceHistory:
        case RbacEntityName.Event:
        case RbacEntityName.Deviance:
        case RbacEntityName.CheckerOption:
        case RbacEntityName.Attack:
        case RbacEntityName.AttackAlert:
        case RbacEntityName.AttackTypeOption:
        case RbacEntityName.AttackPath:
        case RbacEntityName.Alert: {
          return null
        }

        // "Fake" Rbac entity name, matching the license features

        case RbacEntityName.LicenseFeatureIoe:
        case RbacEntityName.LicenseFeatureIoa:
        case RbacEntityName.Unknown: {
          return null
        }

        default:
          assertUnreachableCase(rbacEntityName)
      }
    } catch (err) {
      logger.warn(`EntityName value is invalid: "${rbacEntityName}"`)
      return null
    }
  }
}
