import type { Maybe } from '@@types/helpers'
import { useStores } from '@app/hooks/useStores'
import type { IRbacCapability, RbacCapabilityCheck } from '@libs/rbac/types'
import * as React from 'react'

interface IContainerRbacProps {
  rbacCapabilityCheck: RbacCapabilityCheck
  render?: (rbacCapability: IRbacCapability) => Maybe<React.ReactElement>
  children?: React.ReactNode
}

/**
 * Container to wrap a component that will be displayed if the user has the
 * correct permission.
 *
 * It's also possible to pass a function in the render props to have more
 * flexibility with the Rbac results.
 *
 * Usage:
 *
 * <ContainerRbac rbacCapabilityCheck={readDashboards()}>
 *   <Button>Create</Button>
 * </ContainerRbac>
 *
 * <ContainerRbac
 *   rbacCapabilityCheck={readDashboards()}
 *   render={({ isGranted, message }) => {
 *     return (
 *       <Tooltip content={message}>
 *         <Button disabled={!isGranted}>Edit</Button>
 *       </Tooltip>
 *     )
 *   })}
 */
const ContainerRbac: React.FC<IContainerRbacProps> = props => {
  const { storeRbac } = useStores()

  const rbacCapability = storeRbac.checkIfUserIsAbleTo(
    props.rbacCapabilityCheck
  )

  if (props.render) {
    return props.render(rbacCapability)
  }

  return storeRbac.isUserGrantedTo(props.rbacCapabilityCheck) ? (
    <>{props.children}</>
  ) : null
}

export default ContainerRbac
