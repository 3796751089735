import { Features } from '@alsid/common'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { filterNullOrUndefinedValues } from '@libs/helpers/objects/filterNullOrUndefinedValues'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerContent, ContainerFlex } from '../Container'
import { extractContainerLoaderProps } from '../Container/ContainerContent/helpers'
import { SpinnerInline } from '../Spinner'
import type { IComponentLoaderProps } from '../types'
import { DrawerContext, useDrawerContext } from './context'

export interface IDrawerContainerProps
  extends Omit<IComponentLoaderProps, 'rbacCapabilityCheck'> {
  className?: string
  labelledBy?: string
  variant?: 'normal' | 'large' | 'veryLarge'
  children?: React.ReactNode
}

const DrawerHorizontalContainer: React.FC<IDrawerContainerProps> = props => {
  const theme = useDSTheme()

  const contextValue = useDrawerContext(DrawerContext)

  const isTulEnabled = useFeatureFlag({
    featureFlagName: Features.TENABLE_UNIVERSAL_LAYOUT
  })

  if (!contextValue) {
    return null
  }

  const getContent = () => {
    // Keep an empty div for the drawer animation
    if (!contextValue.storeDrawer.isDrawerOpened) {
      return [<div></div>]
    }

    const containerLoaderProps = extractContainerLoaderProps(props)

    return (
      <ContainerContent spinner={<SpinnerInline />} {...containerLoaderProps}>
        {props.children}
      </ContainerContent>
    )
  }

  const isOpened = contextValue.storeDrawer.isDrawerOpened

  // use inline style because styled is not reactive on contextValue.storeDrawer changes
  const style: React.CSSProperties = filterNullOrUndefinedValues({
    right: isOpened ? 0 : `-${getDrawerWidth(props.variant)}`,
    boxShadow: isOpened ? theme.tokens['drawer/boxShadow/horizontal'] : null,
    top: isTulEnabled ? 0 : consts.headerHeight,
    height: isTulEnabled ? '100%' : `calc(100% - ${consts.headerHeight})`
  })

  return (
    <ContainerFlex
      name="DrawerHorizontalContainer"
      labelledBy={
        props.labelledBy
          ? `drawerHorizontalContainer/${props.labelledBy}`
          : 'drawerHorizontalContainer'
      }
      className={props.className}
      style={style}
      itemsFlexGrow={[1]}
      direction="column"
      items={getContent()}
    />
  )
}

function getDrawerWidth(variant?: IDrawerContainerProps['variant']): string {
  if (!variant) {
    return '500px'
  }

  switch (variant) {
    case 'veryLarge':
      return '1100px'

    case 'large':
      return '850px'

    case 'normal':
      return '500px'

    default:
      assertUnreachableCase(variant)
  }
}

const ObservedDrawerContainer = observer(DrawerHorizontalContainer)

export default styled(ObservedDrawerContainer)`
  position: absolute;
  top: 0;
  z-index: ${consts.zIndexDrawerContainer};

  background-color: white;

  width: ${props => getDrawerWidth(props.variant)};
  height: 100%;

  transition: right 0.3s ${consts.smoothAnimation};

  border-top-left-radius: ${props =>
    props.theme.tokens['drawer/borderRadius/default']};
  border-bottom-left-radius: ${props =>
    props.theme.tokens['drawer/borderRadius/default']};
`
