import {
  ContainerContent,
  ContainerFooter,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { InputSelectProfiles } from '@app/components-legacy/Input'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canSetDefaultProfile } from '../permissions'
import { onProfileChange, onProfileSubmit } from './handlers'
import { FieldName } from './types'

interface IPreferencesProfilesFormProps {}

const PreferencesProfilesForm: React.FC<IPreferencesProfilesFormProps> = () => {
  const { storePreferences, storeRbac, storeAuthentication } = useStores()

  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Preferences.PersonalSettings.Navigation',
      'Preferences.PersonalSettings.Profiles'
    ]
  })

  const preferredProfile = storePreferences.preferredProfile

  React.useEffect(() => {
    if (preferredProfile) {
      storePreferences.storeFormPreferredProfile
        .setDefaultFieldsValues([
          {
            key: FieldName.preferredProfileName,
            value: preferredProfile.getPropertyAsString('name')
          }
        ])
        .reset()
    }
  }, [])

  const defaultProfileName =
    storePreferences.storeFormPreferredProfile.getFieldValueAsString(
      FieldName.preferredProfileName
    )

  const fields: IField[] = [
    {
      name: 'preferredProfile',
      label: translate('Preferred profile'),
      description: translate('Select your default profile'),
      control: (
        <InputSelectProfiles
          labelledBy="preferences-profiles"
          onProfileChange={onProfileChange(storePreferences)}
          defaultProfileName={defaultProfileName}
          profiles={storeAuthentication.selectionnableProfiles}
          disabled={!storeRbac.isUserGrantedTo(canSetDefaultProfile)}
        />
      )
    }
  ]

  return (
    <FormWrapper
      name="preferencesConfiguration"
      onSubmit={onProfileSubmit(storePreferences, storeAuthentication)}
    >
      <>
        <ContainerContent title={translate('Profiles')}>
          <ContainerForm fields={fields} />
        </ContainerContent>

        <Portal name={PlaceHolderName.bladeFooter}>
          <ContainerFooter>
            <FormWrapperButtonSubmit
              disabled={!storeRbac.isUserGrantedTo(canSetDefaultProfile)}
            >
              {translate('Save')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </>
    </FormWrapper>
  )
}

export default observer(PreferencesProfilesForm)
