import type { IGraphQLQuery } from '@libs/graphQL/types'
import type {
  CreateDirectoryMutationArgs,
  DeleteDirectoryMutationArgs,
  EditDirectoryMutationArgs,
  Mutation,
  RecrawlDirectoryMutationArgs,
  RequestDirectoryConnectivityMutationArgs,
  UpdateHoneyAccountMutationArgs
} from '../typeDefs/types'

export type MutationCreateDirectory = IGraphQLQuery<
  CreateDirectoryMutationArgs,
  Pick<Mutation, 'createDirectory'>
>

export const mutationCreateDirectory = `
  mutation mutationCreateDirectory($directory: InputCreateDirectory!) {
    createDirectory(
      directory: $directory
    ) {
      name
      ip
      type
      ldapPort
      globalCatalogPort
      smbPort
      dns
      relayId
      sensitiveDataCollectionIsEnabled
    }
  }
`

export type MutationEditDirectory = IGraphQLQuery<
  EditDirectoryMutationArgs,
  Pick<Mutation, 'editDirectory'>
>

export const mutationEditDirectory = `
  mutation mutationEditDirectory($directory: InputEditDirectory!) {
    editDirectory(
      directory: $directory
    ) {
      name
      ip
      type
      ldapPort
      globalCatalogPort
      smbPort
      dns
      relayId
      sensitiveDataCollectionIsEnabled
    }
  }
`

export type MutationDeleteDirectory = IGraphQLQuery<
  DeleteDirectoryMutationArgs,
  Pick<Mutation, 'deleteDirectory'>
>

export const mutationDeleteDirectory = `
  mutation mutationDeleteDirectory($directory: InputDeleteDirectory!) {
    deleteDirectory(
      directory: $directory
    )
  }
`

export type MutationRecrawlDirectory = IGraphQLQuery<
  RecrawlDirectoryMutationArgs,
  Pick<Mutation, 'recrawlDirectory'>
>

export const mutationRecrawlDirectory = `
  mutation mutationRecrawlDirectory($directory: InputRecrawlDirectory!) {
    recrawlDirectory(
      directory: $directory
    ) {
      ldapCrawlingStatus
      sysvolCrawlingStatus
      honeyAccountConfigurationStatus
      sensitiveDataCollectionStatus
    }
  }
`

export type MutationRequestDirectoryConnectivity = IGraphQLQuery<
  RequestDirectoryConnectivityMutationArgs,
  Pick<Mutation, 'requestDirectoryConnectivity'>
>

export const mutationRequestDirectoryConnectivity = `
  mutation mutationRequestDirectoryConnectivity(
    $id: String!
    $directory: InputRequestDirectoryConnectivity!
    ) {
      requestDirectoryConnectivity(
        id: $id
        directory: $directory
      )
  }
`

export type MutationUpdateHoneyAccount = IGraphQLQuery<
  UpdateHoneyAccountMutationArgs,
  Pick<Mutation, 'updateHoneyAccount'>
>

export const mutationUpdateHoneyAccount = `
  mutation mutationUpdateHoneyAccount($honeyAccount: InputUpdateHoneyAccount!) {
    updateHoneyAccount(
      honeyAccount: $honeyAccount
    ) {
      ldapCrawlingStatus
      sysvolCrawlingStatus
      honeyAccountConfigurationStatus
    }
  }
`
