import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useStores } from '@app/hooks'
import AuthMenu from '@app/pages/Auth/AuthMenu'
import AuthPlaceHolders from '@app/pages/Auth/AuthPlaceHolders'
import AuthTenableLogo from '@app/pages/Auth/AuthTenableLogo'
import { AuthProviderName } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import AuthContainerPadded from '../Common/AuthContainerPadded'
import { onAuthLoginLoad } from './handlers'
import LoginErrorMessage from './LoginErrorMessage'
import LoginLDAPForm from './LoginLDAPForm'
import LoginSAMLForm from './LoginSAMLForm'
import LoginTenableForm from './LoginTenableForm'

interface ILoginPageProps {}

/**
 * Override controls widths to avoid to split labels.
 */
const ContainerContentAuthForm = styled(ContainerContent)`
  .controlContainer,
  .fieldSubLabel {
    width: 350px;
  }
`

const LoginPage: React.FC<ILoginPageProps> = () => {
  const { storeAuthentication } = useStores()

  const renderForm = () => {
    const currentMenuEntry = storeAuthentication.storeMenu.selectedMenuKey

    if (!currentMenuEntry) {
      return null
    }

    const storeForm = storeAuthentication.storeForms[currentMenuEntry]
    const provider = storeAuthentication.providers.get(currentMenuEntry)

    if (!storeForm || !provider) {
      return <LoginErrorMessage />
    }

    switch (currentMenuEntry) {
      case AuthProviderName.Tenable:
        return <LoginTenableForm />

      case AuthProviderName.Ldap:
        return <LoginLDAPForm />

      case AuthProviderName.Tone:
      case AuthProviderName.Saml:
        return (
          <LoginSAMLForm
            redirectSAMLUrl={provider.getPropertyAsString('url')}
          />
        )
    }
  }

  return (
    <AuthPlaceHolders
      layout={{
        header: <AuthTenableLogo />,
        menu: <AuthMenu />,
        content: (
          <ContainerContentAuthForm
            onLoad={onAuthLoginLoad(storeAuthentication)}
          >
            <AuthContainerPadded>
              <ContainerContent
                flags={storeAuthentication.storeFlagsFetchProviders.flags}
                spinner={<SpinnerInline />}
                onError={() => <LoginErrorMessage />}
              >
                {renderForm()}
              </ContainerContent>
            </AuthContainerPadded>
          </ContainerContentAuthForm>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.authFooter} />
      }}
    />
  )
}

export default observer(LoginPage)
