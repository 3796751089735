import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelDirectoriesTree } from '@app/components-legacy/Label'
import type EntityAttack from '@app/entities/EntityAttack'
import { useAppRouter, useStores } from '@app/hooks'
import { AttacksPageContext } from '@app/pages/IoA/IoAAttacksPage/context'
import { isDefined } from '@libs/isDefined'
import { AttackResourceType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { addAttacksFiltersInUrl } from './functions'
import { onAttackLinkClick } from './handlers'
import IoAIncidentLabelValue from './IoAIncidentLabelValue'

interface IIoAAttacksTableBodyDataRowAttackDirectoryProps {
  className?: string
  attackEntity: EntityAttack
}

const IoAAttacksTableBodyDataRowAttackDirectory: React.FC<
  IIoAAttacksTableBodyDataRowAttackDirectoryProps
> = props => {
  const { storeInfrastructures, storeIoA } = useStores()

  const appRouter = useAppRouter()

  const attacksPageContext = React.useContext(AttacksPageContext)

  if (!attacksPageContext) {
    return null
  }

  const { storeAttacks } = attacksPageContext

  const currentQueryStringParameters = storeAttacks.queryStringParameters

  const directoryIds = [props.attackEntity.directoryId].filter(isDefined)

  const buildFilterUrlWithInfrastructure = (id: Maybe<number>) =>
    addAttacksFiltersInUrl(
      appRouter,
      storeIoA
    )({
      ...currentQueryStringParameters,
      resourceType: AttackResourceType.Infrastructure,
      resourceValue: String(id || '')
    })

  const buildFilterUrlWithDirectory = (id: Maybe<number>) =>
    addAttacksFiltersInUrl(
      appRouter,
      storeIoA
    )({
      ...currentQueryStringParameters,
      resourceType: AttackResourceType.Directory,
      resourceValue: String(id || '')
    })

  const getItems = () => {
    if (!directoryIds.length) {
      return [<IoAIncidentLabelValue>{null}</IoAIncidentLabelValue>]
    }

    return [
      <LabelDirectoriesTree
        storeInfrastructures={storeInfrastructures}
        directoryIds={directoryIds}
        buildInfrastructureUrlFn={buildFilterUrlWithInfrastructure}
        buildDirectoryUrlFn={buildFilterUrlWithDirectory}
        onInfrastructureClick={() => onAttackLinkClick}
        onDirectoryClick={() => onAttackLinkClick}
      />
    ]
  }

  return (
    <ContainerFlex
      name="IoAAttacksTableBodyDataRowAttackDirectory"
      justifyContent={!directoryIds.length ? 'center' : 'flex-start'}
      className={props.className}
      items={getItems()}
    />
  )
}

const ObservedIoAAttacksTableBodyDataRowAttackDirectory = observer(
  IoAAttacksTableBodyDataRowAttackDirectory
)

export default styled(ObservedIoAAttacksTableBodyDataRowAttackDirectory)`
  padding: 0 3px;
`
