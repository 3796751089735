import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelDirectoriesTree, LabelSliced } from '@app/components-legacy/Label'
import LabelDirectoryTag from '@app/components-legacy/Label/LabelDirectoryTag'
import type EntityAttackPathNode from '@app/entities/EntityAttackPathNode'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { isDefined } from '@libs/isDefined'
import * as React from 'react'
import styled from 'styled-components'
import NodeObjectIcon from '../Components/NodeObjectIcon'

export interface IDrawerNodeCurrentProps {
  className?: string
  entityAttackPathNode: EntityAttackPathNode
}

const DrawerNodeCurrent: React.FC<IDrawerNodeCurrentProps> = props => {
  const { storeInfrastructures } = useStores()

  const directory =
    props.entityAttackPathNode.directoryId &&
    storeInfrastructures.getDirectoryFromId(
      props.entityAttackPathNode.directoryId
    )

  return (
    <ContainerFlex
      name="DrawerNodeCurrent"
      className={props.className}
      alignItems="center"
      direction="row"
      labelledBy={`attackPathGroupDrawer/currentNode/id-${props.entityAttackPathNode.id}/uid-${props.entityAttackPathNode.uid}`}
      items={[
        <NodeObjectIcon
          adObjectType={props.entityAttackPathNode.adObjectType}
          size={48}
        />,

        <ContainerFlex
          name="DrawerNodeRelationshipContent"
          direction="column"
          spaceWidth="verySmall"
          items={[
            <Label variant={LabelVariant.subtitle1}>
              <LabelSliced
                labelledBy={`attackPathGroupDrawer/currentNodeName/id-${props.entityAttackPathNode.id}/uid-${props.entityAttackPathNode.uid}`}
                value={props.entityAttackPathNode.name || ''}
                maxWidth={700}
              />
            </Label>,

            directory && isDefined(directory.id) && directory.name && (
              <div>
                <LabelDirectoryTag
                  labelledBy={`attackPathGroupDrawer/currentNodeDirectory/id-${props.entityAttackPathNode.id}/uid-${props.entityAttackPathNode.uid}`}
                  color={directory.color}
                  label={directory.name}
                  tooltipLabel={
                    <LabelDirectoriesTree
                      storeInfrastructures={storeInfrastructures}
                      directoryIds={[directory.id]}
                    />
                  }
                  maxWidth={705}
                />
              </div>
            )
          ]}
          spaced
        />
      ]}
      spaced
    />
  )
}

export default styled(DrawerNodeCurrent)`
  margin-left: ${consts.marginDefault};
`
