import type { MaybeUndef } from '@@types/helpers'
import { keyValue, logicalExpression } from '@libs/Expression/Grammar'
import { flatten } from '@libs/Expression/NodeLeafUtils'
import type { ParseResult } from '@libs/Expression/parser/ParseResult'
import { isEmpty } from 'lodash'
import Node from './Node'
import type { IExpressionDefinition, NodeOrLeaf, Combinator } from './types'
import { ExpressionParserError } from './types'

export const VALUE_MIN_LENGTH = 3

/**
 * Try recursively to retrieve errors from a node or a leaf.
 */
function getErrorIn(nodeOrLeaf: NodeOrLeaf): MaybeUndef<ExpressionParserError> {
  function getErrorRecIn(
    nodeOrLeafs: NodeOrLeaf[]
  ): MaybeUndef<ExpressionParserError> {
    if (!nodeOrLeafs.length) {
      return
    }

    const [current, ...rest] = nodeOrLeafs
    const newNodeOrLeafs: NodeOrLeaf[] = rest && rest.length > 0 ? rest : []

    if (!current.isLeaf()) {
      newNodeOrLeafs.unshift(...current.values)
      return getErrorRecIn(newNodeOrLeafs)
    }

    if (
      current.value.isString() &&
      String(current.value.initialValue).length < VALUE_MIN_LENGTH
    ) {
      return ExpressionParserError.InvalidValue
    }

    return getErrorRecIn(newNodeOrLeafs)
  }

  return getErrorRecIn([nodeOrLeaf])
}

export default class ExpressionParser {
  private $parseError: MaybeUndef<ExpressionParserError>

  getError(): MaybeUndef<ExpressionParserError> {
    return this.$parseError
  }

  /**
   * Parse a string to build a Node that could be serialized/outputed
   * as an expression object.
   */
  parse(str: MaybeUndef<string>): MaybeUndef<NodeOrLeaf> {
    if (!str) {
      return
    }

    const parseResult: ParseResult<NodeOrLeaf> = logicalExpression().parse(str)

    if (!parseResult.isSuccess()) {
      return
    }

    const nodeOrLeaf = parseResult.parsedValue
    this.$parseError = getErrorIn(nodeOrLeaf)

    return flatten(nodeOrLeaf)
  }

  /**
   * Parse an IExpressionDefinition object.
   * Reverse of the parse function just before.
   */
  parseObject(
    expressionDefinition: IExpressionDefinition
  ): MaybeUndef<NodeOrLeaf> {
    const parse = (
      nodeParent: Node,
      expressionValuePart: IExpressionDefinition
    ): void => {
      expressionValuePart.forEach((value: any) => {
        const keys = Object.keys(value)

        keys.forEach(key => {
          if (this.isCombinator(key)) {
            const node = new Node().setCombinator(key)
            nodeParent.add(node)
            parse(node, value[key])
          } else {
            const finalValue = Array.isArray(value[key])
              ? value[key].join('')
              : value[key]
            const parseResults = keyValue().parse(`${key}:${finalValue}`)

            if (parseResults.isSuccess()) {
              const leaf = parseResults.parsedValue
              nodeParent.add(leaf)
            }
          }
        })
      })
    }

    if (isEmpty(expressionDefinition)) {
      return
    }

    // wrap by an uniq 'AND' combinator if there are several combinators at the root
    // (it's often the case when merging expressions)
    const hasManyCombinators =
      typeof expressionDefinition === 'object' &&
      Object.keys(expressionDefinition).length > 1

    expressionDefinition = hasManyCombinators
      ? { AND: [expressionDefinition] }
      : expressionDefinition

    const rootNode = new Node()

    try {
      const combinator = Object.keys(expressionDefinition)[0] as Combinator
      rootNode.setCombinator(combinator)
      parse(rootNode, expressionDefinition[combinator])
    } catch (err) {
      /* Error during the parsing, the expression definition is certainly invalid */
    }

    return rootNode
  }

  /**
   * Typeguard function that confirms that str is a Combinator.
   */
  private isCombinator(str: string): str is Combinator {
    return ['AND', 'OR'].indexOf(str) !== -1
  }
}
