import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { useAppTranslator } from '@app/hooks'
import { consts } from '@app/styles'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import * as React from 'react'
import styled from 'styled-components'
import TemplateWrapper from './TemplateWrapper'
import { DashboardTemplate } from './types'

interface ICustomDashboardProps {
  children?: React.ReactNode
}

const Div = styled.div`
  margin-top: ${consts.marginSmall};

  label {
    display: none;
  }
`

const CustomDashboard: React.FC<ICustomDashboardProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Dashboard.AddDashboardDrawer', 'Dashboard.Common']
  })

  return (
    <TemplateWrapper
      title={translate('Custom Dashboard')}
      templateType={DashboardTemplate.customDashboard}
    >
      <Label variant={LabelVariant.description}>
        {translate(
          'Configure a fresh dashboard according to your specific requirements'
        )}
      </Label>
      <DSThemeProvider themeName={DSThemeName.grey}>
        <Div>{props.children}</Div>
      </DSThemeProvider>
    </TemplateWrapper>
  )
}

export default CustomDashboard
