import { EntityAttackTypeConfigurationEntry } from '@app/entities'
import type { ConfigurationEntrySelectionString } from '@app/entities/EntityAttackTypeConfigurationEntry'
import type { StoreRoot } from '@app/stores'
import { StoreInfrastructures } from '@app/stores'
import StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { ICheckerAttack } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import StoreModal from '@app/stores/helpers/StoreModal'
import type { IStoreOptions } from '@app/stores/types'
import { ensureArray } from '@libs/ensureArray'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import type {
  MutationUpdateAttackTypeConfiguration,
  MutationUpdateIoAConfiguration
} from '@server/graphql/mutations/ioaConfiguration'
import {
  mutationUpdateAttackTypeConfiguration,
  mutationUpdateIoAConfiguration
} from '@server/graphql/mutations/ioaConfiguration'
import type {
  QueryAttackTypeConfiguration,
  QueryIoaConfiguration
} from '@server/graphql/queries/ioa'
import {
  queryAttackTypeConfiguration,
  queryIoaConfiguration
} from '@server/graphql/queries/ioa'
import type {
  AttackTypeConfiguration,
  InputAttackTypeConfiguration,
  InputIoaConfiguration,
  Maybe,
  UpdateAttackTypeConfigurationMutationArgs,
  UpdateIoAConfigurationMutationArgs
} from '@server/graphql/typeDefs/types'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { flatMap, isEqual } from 'lodash'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import * as moment from 'moment'
import StoreFlags from '../../helpers/StoreFlags'
import StoreBase from '../../StoreBase'

type SetupViewRefsName = 'tableContent' | 'leftColumn'

export default class StoreAttackTypeConfiguration extends StoreBase {
  public storeFlagsFetchAttackTypeConfiguration = new StoreFlags(this.storeRoot)
  public storeFlagsReloadAttackTypeConfiguration = new StoreFlags(
    this.storeRoot
  )
  public storeFlagsUpdateIoAConfiguration = new StoreFlags(this.storeRoot)
  public storeFlagsUpdateAttackTypeConfiguration = new StoreFlags(
    this.storeRoot
  )

  public storeInputCheckersAttacks =
    new StoreInputGenericCheckers<ICheckerAttack>(this.storeRoot, {
      checkerType: CheckerType.Attack,
      selectable: true
    })

  public storeInfrastructures = new StoreInfrastructures(this.storeRoot)

  // modals
  public storeModalSaveConfiguration = new StoreModal(this.storeRoot)
  public storeModalEditManualQuotaLimit = new StoreModal(this.storeRoot)
  public storeModalEditAutoQuotaLimit = new StoreModal(this.storeRoot)
  public storeModalProcedure = new StoreModal(this.storeRoot)
  public storeModalIoAErrors = new StoreModal(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Management.System.Configuration.IoA']
  })

  public $infrastructuresCollapsed = observable.map<number, boolean>([])

  /* Private */

  // refs to DOM elements
  private _tableRefs: Record<SetupViewRefsName, Maybe<HTMLDivElement>> = {
    tableContent: null,
    leftColumn: null
  }

  // key are stringified AttackTypeConfigurationConfigurationEntry
  private _indexedConfigurationEntries: Map<
    ConfigurationEntrySelectionString,
    EntityAttackTypeConfigurationEntry
  > = new Map()

  /* Observable */

  // attack-type-configuration output
  private $attackTypeConfiguration =
    observable.box<Maybe<AttackTypeConfiguration>>(null)

  // current selections (checkboxes status) - Values are stringified AttackTypeConfigurationConfigurationEntry
  private $entrySelectionStrings =
    observable.set<ConfigurationEntrySelectionString>()

  private $customPastDelaySeconds = observable.box<number>(30)

  // true if the user has started to edit the configuration
  private $configurationDirty = observable.box<boolean>(false)

  private $scriptDownloadDate = observable.box<Maybe<moment.Moment>>(null)

  private $forcedManualIoaConfiguration = observable.box<boolean>(false)

  public isConfigurationSavedOnManualMode: boolean = false

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch IOA configuration.
   */
  fetchIoaConfiguration(
    storeFlags = this.storeFlagsFetchAttackTypeConfiguration
  ): Promise<void> {
    storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryIoaConfiguration>(queryIoaConfiguration, {})
      })
      .then(({ ioaConfiguration }) => {
        if (ioaConfiguration.scriptDownloadDate) {
          this.setScriptDownloadDate(
            moment(ioaConfiguration.scriptDownloadDate)
          )
        }

        if (isDefined(ioaConfiguration.forcedManualIoaConfiguration)) {
          this.setForcedManualIoaConfiguration(
            ioaConfiguration.forcedManualIoaConfiguration
          )
        }

        if (isDefined(ioaConfiguration.customPastDelaySeconds)) {
          this.setCustomPastDelaySeconds(
            ioaConfiguration.customPastDelaySeconds
          )
        }

        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Fetch attacks settings informations.
   */
  fetchAttackTypeConfiguration(
    storeFlags = this.storeFlagsFetchAttackTypeConfiguration
  ): Promise<void> {
    storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryAttackTypeConfiguration>(queryAttackTypeConfiguration, {})
      })
      .then(({ attackTypeConfiguration }) => {
        this.setAttackTypeConfiguration(attackTypeConfiguration)
        this.initSelectedEntries()

        storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /**
   * Reload the configuration after modification (actualize the status).
   */
  reloadAttackTypeConfiguration() {
    this.fetchAttackTypeConfiguration(
      this.storeFlagsReloadAttackTypeConfiguration
    )
    this.fetchIoaConfiguration()
  }

  /**
   * Update IoA configuration
   */
  updateIoAConfiguration(
    ioaConfiguration: InputIoaConfiguration
  ): Promise<any> {
    this.storeFlagsUpdateIoAConfiguration.loading()

    return Promise.resolve()
      .then(() => {
        const args: UpdateIoAConfigurationMutationArgs = {
          ioaConfiguration: ioaConfiguration
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationUpdateIoAConfiguration>(
            mutationUpdateIoAConfiguration,
            args
          )
      })
      .then(() => {
        this.storeFlagsUpdateIoAConfiguration.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsUpdateIoAConfiguration,
          {
            forwardExceptionFn: () => true
          }
        )
      )
  }

  /**
   * Update attacks settings.
   */
  updateAttackTypeConfiguration(
    attackTypeConfiguration: Pick<
      InputAttackTypeConfiguration,
      'configuration'
    >,
    options = {
      // if set to true, do not push a success message and forward the error exception
      forwardException: false
    }
  ): Promise<any> {
    this.storeFlagsUpdateAttackTypeConfiguration.loading()

    return Promise.resolve()
      .then(() => {
        const args: UpdateAttackTypeConfigurationMutationArgs = {
          attackTypeConfiguration
        }

        return this.storeRoot
          .getGQLRequestor()
          .query<MutationUpdateAttackTypeConfiguration>(
            mutationUpdateAttackTypeConfiguration,
            args
          )
      })
      .then(() => {
        if (!options.forwardException) {
          this.storeRoot.stores.storeMessages.success(
            this.translate(
              'Configuration saved Tenable.ad is configuring your domains automatically'
            ),
            {
              labelledBy: 'ioaConfigurationUpdated'
            }
          )
        }

        this.setConfigurationDirty(false)

        // clear the current selection
        // (the new selection will occur just after having reloading the table)
        this.$entrySelectionStrings.clear()

        this.storeFlagsUpdateAttackTypeConfiguration.success()
      })
      .catch(
        handleStoreError(
          this.storeRoot,
          this.storeFlagsUpdateAttackTypeConfiguration,
          {
            forwardExceptionFn: () => options.forwardException
          }
        )
      )
  }

  /**
   * Save ref (HTML Element) of the setup view table.
   */
  setRefs(place: SetupViewRefsName, ref: HTMLDivElement): this {
    this._tableRefs[place] = ref
    return this
  }

  /**
   * If all refs have been filled, then it return all refs, otherwise it return null because we need all those refs to process.
   */
  getRefs(): Maybe<Record<SetupViewRefsName, HTMLDivElement>> {
    const areAllRefsDefined = (
      refs: Record<SetupViewRefsName, Maybe<HTMLDivElement>>
    ): refs is Record<SetupViewRefsName, HTMLDivElement> => {
      return Object.values(refs).every(isDefined)
    }

    return areAllRefsDefined(this._tableRefs) ? this._tableRefs : null
  }

  /**
   * Return the configuration entry entity from a directory and an attackType pair.
   */
  getConfigurationEntry(
    directoryId: number,
    attackTypeId: number
  ): Maybe<EntityAttackTypeConfigurationEntry> {
    const entry = this.getEntries({ directoryId, attackTypeId }).pop()

    if (!entry) {
      return null
    }

    return (
      this._indexedConfigurationEntries.get(entry.toSelectionString()) || null
    )
  }

  /**
   * Return configuration entries according to the passed parameters and selected
   * states of pickers.
   */
  getEntries(parameters: {
    infrastructureId?: number
    directoryId?: number
    attackTypeId?: number
  }): EntityAttackTypeConfigurationEntry[] {
    return computed(() => {
      const getDirectoryIds = () => {
        if (parameters.directoryId) {
          return [parameters.directoryId]
        }

        if (parameters.infrastructureId) {
          return this.storeInfrastructures
            .selectedDirectories(parameters.infrastructureId)
            .map(directory => directory.id)
            .filter(isDefined)
        }

        return this.storeInfrastructures.selectedDirectoryIds
      }

      const getAttackTypesIds = () => {
        if (parameters.attackTypeId) {
          return [parameters.attackTypeId]
        }

        return this.storeInputCheckersAttacks.selectedCheckerIds
      }

      return flatMap(
        getDirectoryIds().map(directoryId => {
          return getAttackTypesIds().map(attackTypeId => {
            return new EntityAttackTypeConfigurationEntry({
              directoryId,
              attackTypeId
            })
          })
        })
      )
    }).get()
  }

  /**
   * Return true if the entry is selected.
   */
  isDirectoryForOneAttackSelected(
    directoryId: number,
    attackTypeId: number
  ): boolean {
    const entry = this.getEntries({
      directoryId,
      attackTypeId
    }).pop()

    if (!entry) {
      return false
    }

    return this.selectedEntries.has(entry.toSelectionString())
  }

  /**
   * Return true if a directory is selected for all attacks.
   */
  isDirectoryForAllAttacksSelected(directoryId: number): boolean {
    return this.getEntries({ directoryId }).every(entry => {
      return this.$entrySelectionStrings.has(entry.toSelectionString())
    })
  }

  /**
   * Return true if a directory is selected for at least one attack.
   */
  isDirectoryForAllAttacksPartiallySelected(directoryId: number): boolean {
    if (this.isDirectoryForAllAttacksSelected(directoryId)) {
      return false
    }

    return this.getEntries({ directoryId }).some(entry => {
      return this.$entrySelectionStrings.has(entry.toSelectionString())
    })
  }

  /**
   * Return true if a directory is automaticaly disabled for at least one attack (for perf) for a specified directory.
   */
  isDirectoryForAllAttacksPartialyDisabledForPerf(
    directoryId: number
  ): boolean {
    return this.storeInputCheckersAttacks.selectedCheckerIds.some(
      attackTypeId => {
        const conf = this.getConfigurationEntry(directoryId, attackTypeId)
        return (
          conf?.autoDisabledForPerfAt && conf.autoDisabledForPerfAt !== null
        )
      }
    )
  }

  /**
   * Return true if a directory is automaticaly disabled for at least one attack (for perf) for any directory.
   */
  isSomeIoaDeactivated(): boolean {
    return this.getEntries({}).some(entry => {
      if (entry.directoryId === null) {
        handleStoreError(this.storeRoot, null, {
          errorMessageTranslationFn: () => {
            return 'Cannot get the config of a null directoryId'
          }
        })
        return false
      }
      if (entry.attackTypeId === null) {
        handleStoreError(this.storeRoot, null, {
          errorMessageTranslationFn: () => {
            return 'Cannot get the config of a null attackID'
          }
        })
        return false
      }
      const conf = this.getConfigurationEntry(
        entry.directoryId,
        entry.attackTypeId
      )
      return conf?.autoDisabledForPerfAt && conf.autoDisabledForPerfAt !== null
    })
  }
  /**
   * Return true if an infrastructure has some directories selected for one attack.
   */
  isInfrastructureForOneAttackPartiallySelected(
    infrastructureId: number,
    attackTypeId: number
  ): boolean {
    if (
      this.isInfrastructureForOneAttackSelected(infrastructureId, attackTypeId)
    ) {
      return false
    }

    return this.getEntries({ infrastructureId, attackTypeId }).some(entry => {
      return this.$entrySelectionStrings.has(entry.toSelectionString())
    })
  }

  /**
   * Return true if an infrastructureId (all domains) is selected for one attack.
   */
  isInfrastructureForOneAttackSelected(
    infrastructureId: number,
    attackTypeId: number
  ): boolean {
    return this.getEntries({ infrastructureId, attackTypeId }).every(entry => {
      return this.$entrySelectionStrings.has(entry.toSelectionString())
    })
  }

  /**
   * Return true if an infrastructureId (all domains) is selected for all attacks.
   */
  isInfrastructureForAllAttacksSelected(infrastructureId: number): boolean {
    return this.getEntries({ infrastructureId }).every(entry => {
      return this.$entrySelectionStrings.has(entry.toSelectionString())
    })
  }

  /**
   * Return true if an infrastructureId (all domains) is selected for at least one attack.
   */
  isInfrastructureForAllAttacksPartiallySelected(
    infrastructureId: number
  ): boolean {
    if (this.isInfrastructureForAllAttacksSelected(infrastructureId)) {
      return false
    }

    return this.getEntries({ infrastructureId }).some(entry => {
      return this.$entrySelectionStrings.has(entry.toSelectionString())
    })
  }

  /**
   * Return true if all directories are selected for an attack.
   */
  isAttackTypeForAllDirectoriesSelected(attackTypeId: number): boolean {
    return this.getEntries({ attackTypeId }).every(entry => {
      return this.$entrySelectionStrings.has(entry.toSelectionString())
    })
  }

  /**
   * Return true if at least one directory is selected for an attack.
   */
  isAttackTypeForAllDirectoriesPartiallySelected(
    attackTypeId: number
  ): boolean {
    if (this.isAttackTypeForAllDirectoriesSelected(attackTypeId)) {
      return false
    }

    return this.getEntries({ attackTypeId }).some(entry => {
      return this.$entrySelectionStrings.has(entry.toSelectionString())
    })
  }

  /**
   * Return true if all attacks types for all directies are selected.
   */
  isAllAttackTypesForAllDirectoriesSelected(): boolean {
    return this.getEntries({}).every(entry => {
      return this.$entrySelectionStrings.has(entry.toSelectionString())
    })
  }

  /**
   * Return true if some attacks types for some directies are selected.
   */
  isAllAttackTypesForAllDirectoriesPartiallySelected(): boolean {
    if (this.isAllAttackTypesForAllDirectoriesSelected()) {
      return false
    }

    return this.getEntries({}).some(entry => {
      return this.$entrySelectionStrings.has(entry.toSelectionString())
    })
  }

  /**
   * Return true if some or all attacks types are selected.
   */
  hasSomeAttacksSelected(): boolean {
    return this.getEntries({}).some(entry => {
      return this.$entrySelectionStrings.has(entry.toSelectionString())
    })
  }

  /**
   * Required to display a message for manual users only, after saving configuration, on modal procedure
   */
  setIsConfigurationSavedOnManualMode(isManual: boolean): this {
    this.isConfigurationSavedOnManualMode = isManual

    return this
  }

  /* Actions */

  @action
  reset(): this {
    this.storeInfrastructures.reset()
    this.storeInputCheckersAttacks.reset()

    this.$attackTypeConfiguration.set(null)

    this.$entrySelectionStrings.clear()
    this._indexedConfigurationEntries.clear()

    this.isConfigurationSavedOnManualMode = false

    return this
  }

  @action
  setAttackTypeConfiguration(
    attackTypeConfiguration: AttackTypeConfiguration
  ): this {
    this.$attackTypeConfiguration.set(attackTypeConfiguration)
    return this
  }

  @action
  setCustomPastDelaySeconds(value: number): this {
    this.$customPastDelaySeconds.set(value)
    return this
  }

  @action
  setConfigurationDirty(isDirty: boolean): this {
    this.$configurationDirty.set(isDirty)
    return this
  }

  /**
   * Initialize the table configuration with attackTypeConfiguration.
   */
  @action
  initSelectedEntries(): this {
    this._indexedConfigurationEntries.clear()

    ensureArray(this.$attackTypeConfiguration.get()?.configuration).forEach(
      entry => {
        const entryEntity = new EntityAttackTypeConfigurationEntry(entry)
        const strEntry = entryEntity.toSelectionString()

        this.$entrySelectionStrings.add(strEntry)
        this._indexedConfigurationEntries.set(strEntry, entryEntity)
      }
    )

    return this
  }

  /**
   * Toggle selection for a directory for an attackTypeId.
   */
  @action
  toggleDirectoryForAnAttackSelection(
    directoryId: number,
    attackTypeId: number
  ): this {
    this.setConfigurationDirty(true)

    const entry = new EntityAttackTypeConfigurationEntry({
      directoryId,
      attackTypeId
    })

    this.$entrySelectionStrings.has(entry.toSelectionString())
      ? this.$entrySelectionStrings.delete(entry.toSelectionString())
      : this.$entrySelectionStrings.add(entry.toSelectionString())

    return this
  }

  /**
   * Toggle selection for a directory for all attackTypeIds.
   */
  @action
  toggleDirectoryForAllAttacksSelection(directoryId: number): this {
    this.setConfigurationDirty(true)

    const entries = this.getEntries({ directoryId })

    this.isDirectoryForAllAttacksSelected(directoryId)
      ? entries.forEach(entry =>
          this.$entrySelectionStrings.delete(entry.toSelectionString())
        )
      : entries.forEach(entry =>
          this.$entrySelectionStrings.add(entry.toSelectionString())
        )

    return this
  }

  /**
   * Toggle selection for an infrastructure for one attackTypeId.
   */
  @action
  toggleInfrastructureForOneAttackSelection(
    infrastructureId: number,
    attackTypeId: number
  ): this {
    this.setConfigurationDirty(true)

    const entries = this.getEntries({ infrastructureId, attackTypeId })

    this.isInfrastructureForOneAttackSelected(infrastructureId, attackTypeId)
      ? entries.forEach(entry =>
          this.$entrySelectionStrings.delete(entry.toSelectionString())
        )
      : entries.forEach(entry =>
          this.$entrySelectionStrings.add(entry.toSelectionString())
        )

    return this
  }

  /**
   * Toggle selection for an infrastructure for all attacks.
   */
  @action
  toggleInfrastructureForAllAttacksSelection(infrastructureId: number): this {
    this.setConfigurationDirty(true)

    const entries = this.getEntries({ infrastructureId })

    this.isInfrastructureForAllAttacksSelected(infrastructureId)
      ? entries.forEach(entry =>
          this.$entrySelectionStrings.delete(entry.toSelectionString())
        )
      : entries.forEach(entry =>
          this.$entrySelectionStrings.add(entry.toSelectionString())
        )

    return this
  }

  /**
   * Toggle selection for an attack for all directories.
   */
  @action
  toggleAttackTypeForAllDirectoriesSelection(attackTypeId: number): this {
    this.setConfigurationDirty(true)

    const entries = this.getEntries({ attackTypeId })

    this.isAttackTypeForAllDirectoriesSelected(attackTypeId)
      ? entries.forEach(entry =>
          this.$entrySelectionStrings.delete(entry.toSelectionString())
        )
      : entries.forEach(entry =>
          this.$entrySelectionStrings.add(entry.toSelectionString())
        )

    return this
  }

  /**
   * Toggle selection for all configuration entries.
   */
  @action
  toggleAllAttackTypesForAllDirectoriesSelection(): this {
    this.setConfigurationDirty(true)

    const entries = this.getEntries({})

    this.isAllAttackTypesForAllDirectoriesSelected()
      ? entries.forEach(entry =>
          this.$entrySelectionStrings.delete(entry.toSelectionString())
        )
      : entries.forEach(entry =>
          this.$entrySelectionStrings.add(entry.toSelectionString())
        )

    return this
  }

  /**
   * Cancel all the changes made in the configuration.
   */
  @action
  cancelEdits(): void {
    this.$entrySelectionStrings.clear()
    this.initSelectedEntries()
  }

  @action
  setScriptDownloadDate(scriptDownloadDate: Maybe<moment.Moment>): this {
    this.$scriptDownloadDate.set(scriptDownloadDate)
    return this
  }

  @action
  setForcedManualIoaConfiguration(forcedManualIoaConfiguration: boolean): this {
    this.$forcedManualIoaConfiguration.set(forcedManualIoaConfiguration)
    return this
  }

  /**
   * Save collapsed infrastructures.
   */
  @action
  setInfrastructuresCollapsed(
    infrastructuresCollapsed: Map<number, boolean>
  ): this {
    this.$infrastructuresCollapsed.replace(infrastructuresCollapsed)

    return this
  }

  /* Computed */

  /**
   * Return attacks settings.
   */
  @computed
  get attackTypeConfiguration(): Maybe<AttackTypeConfiguration> {
    return this.$attackTypeConfiguration.get()
  }

  @computed
  get selectedEntries(): Set<string> {
    return toJS(this.$entrySelectionStrings)
  }

  @computed
  get customPastDelaySeconds(): number {
    return this.$customPastDelaySeconds.get()
  }

  @computed
  private get _initialSelectedEntries(): string[] {
    return ensureArray(this.attackTypeConfiguration?.configuration).map(
      config => {
        return new EntityAttackTypeConfigurationEntry({
          directoryId: config.directoryId,
          attackTypeId: config.attackTypeId
        }).toSelectionString()
      }
    )
  }

  @computed
  get hasSameSelectedEntries(): boolean {
    return isEqual(
      this._initialSelectedEntries.sort(),
      Array.from(this.selectedEntries).sort()
    )
  }

  /**
   * Return new attacks settings configuration to save by keeping only
   * attackTypes and directories selected in the pickers.
   */
  @computed
  get inputAttackTypeConfiguration(): InputAttackTypeConfiguration {
    const configuration = Array.from(this.$entrySelectionStrings.values())
      .map(selectionString => {
        const entryEntity =
          EntityAttackTypeConfigurationEntry.fromSelectionString(
            selectionString
          )

        if (!entryEntity) {
          return null
        }

        const { directoryId, attackTypeId } = entryEntity

        if (!directoryId || !attackTypeId) {
          return
        }

        // keep the entry if its directory is selected
        if (
          !this.storeInfrastructures.selectedDirectoryIds.includes(directoryId)
        ) {
          return null
        }

        // keep the entry if its attackType is selected
        if (
          !this.storeInputCheckersAttacks.selectedCheckerIds.includes(
            attackTypeId
          )
        ) {
          return null
        }

        return {
          directoryId,
          attackTypeId
        }
      })
      .filter(isDefined)

    return { configuration }
  }

  /**
   * Return true if the configuration is dirty (modified).
   */
  @computed
  get isConfigurationDirty(): boolean {
    return this.$configurationDirty.get()
  }

  /**
   * Shortcut to return the canEdit permission.
   */
  @computed
  get canEditConfiguration(): boolean {
    return this.$attackTypeConfiguration.get()?.permissions.canEdit === true
  }

  /**
   * Return the script download date.
   */
  @computed
  get scriptDownloadDate(): Maybe<moment.Moment> {
    return this.$scriptDownloadDate.get()
  }

  /**
   * Return if the ioa configuration is forced to be manual
   */
  @computed
  get forcedManualIoaConfiguration(): boolean {
    return this.$forcedManualIoaConfiguration.get()
  }

  /**
   * Return infrastructures collapsed.
   */
  @computed
  get infrastructuresCollapsed(): Map<number, boolean> {
    return toJS(this.$infrastructuresCollapsed)
  }
}
