import {
  createEntities,
  createEntity,
  EntityAttacksSummaryCard
} from '@app/entities'
import type { StoreRoot } from '@app/stores'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreForm from '@app/stores/helpers/StoreForm'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import StoreBase from '@app/stores/StoreBase'
import {
  CriticityValuesOrdered,
  getOrderedCriticityValuesStartingAt
} from '@libs/criticity'
import { ensureArray } from '@libs/ensureArray'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined, isDefinedAndNotEmptyString } from '@libs/isDefined'
import type { MutationEditAttacksSummaryDirectory } from '@server/graphql/mutations/attack'
import { mutationEditAttacksSummaryDirectory } from '@server/graphql/mutations/attack'
import type {
  AttacksSummaryCard,
  AttacksSummaryChartCardType,
  EditAttacksSummaryDirectoryMutationArgs,
  Maybe
} from '@server/graphql/typeDefs/types'
import { AttacksSummaryLayout } from '@server/graphql/typeDefs/types'
import { isNull, orderBy, uniq } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import {
  ATTACKS_DEFAULT_CARD_CHART_TYPE,
  ATTACKS_DEFAULT_SUMMARY_LAYOUT
} from '../../consts'
import StoreSummaryCard from '../StoreSummaryCard'
import type { IStoreSummaryCardsOptions, SummaryCardsBlock } from '../types'

export default class StoreSummaryCards extends StoreBase<IStoreSummaryCardsOptions> {
  public storeFlagsEditCard = new StoreFlags(this.storeRoot)

  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  public storeFormEditCard = new StoreForm<AttacksSummaryChartCardType>(
    this.storeRoot
  )

  public storeDrawerEditCard = new StoreDrawer<{ directoryId: number }>(
    this.storeRoot
  )

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['IoA.Board']
  })

  /**
   * Observable
   */

  private $displayOnlyAttackedDomainsSwitch = observable.box<boolean>(true)

  private $attacksSummaryLayout = observable.box<Maybe<AttacksSummaryLayout>>(
    this.options.attacksSummaryLayout ?? null
  )

  // one store for each card (key are directoryId)
  private $storesSummaryCard = observable.map<number, StoreSummaryCard>(
    new Map()
  )

  constructor(storeRoot: StoreRoot, options: IStoreSummaryCardsOptions) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Edit chart card type.
   */
  public editAttacksSummaryDirectory = (
    directoryId: number,
    chartType: AttacksSummaryChartCardType
  ): Promise<void> => {
    this.storeFlagsEditCard.loading()

    const profileId = this.storeRoot.stores.storeAuthentication.currentProfileId

    const args: EditAttacksSummaryDirectoryMutationArgs = {
      profileId,
      directory: {
        chartType,
        directoryId
      }
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<MutationEditAttacksSummaryDirectory>(
            mutationEditAttacksSummaryDirectory,
            args
          )
      })
      .then(data => {
        if (!data.editAttacksSummaryDirectory) {
          this.storeFlagsEditCard.fail()
          return
        }

        const storeSummaryCard = this.$storesSummaryCard.get(directoryId)

        if (!storeSummaryCard) {
          return
        }

        // If same charType, no need to change the store
        if (
          storeSummaryCard.options.attacksSummaryCardEntity.chartType ===
          chartType
        ) {
          return
        }

        const statsCounts = ensureArray(
          storeSummaryCard.options.attacksSummaryCardEntity.statsCounts
        )

        this.setSummaryCards(
          [
            {
              directoryId,
              chartType,
              statsCounts
            }
          ],
          true // Asking for a merge so that other cards are not replaced
        )
      })
      .then(() => {
        const directoryEntity =
          this.$storesSummaryCard.get(directoryId)?.directoryEntity

        if (!directoryEntity) {
          return
        }

        this.storeRoot.stores.storeMessages.success(
          this.translate('The chart for the domain X has been updated', {
            interpolations: {
              directoryName: directoryEntity.getPropertyAsString('name')
            }
          }),
          {
            labelledBy: 'chartUpdated'
          }
        )

        this.storeFlagsEditCard.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsEditCard))
  }

  /* Actions */

  @action
  reset(): this {
    this.storeFlagsEditCard.reset()
    this.storeInputSearch.reset()
    this.storeFormEditCard.reset()
    this.storeDrawerEditCard.reset()

    this.$storesSummaryCard.clear()

    return this
  }

  /**
   * Set layout
   */
  @action
  setSummaryLayout(attacksSummaryLayout: Maybe<AttacksSummaryLayout>): this {
    this.$attacksSummaryLayout.set(
      attacksSummaryLayout ?? ATTACKS_DEFAULT_SUMMARY_LAYOUT
    )

    return this
  }

  /**
   * Set summary cards and instanciate a StoreSummaryCard store for each.
   */
  @action
  setSummaryCards(
    attacksSummaryCards: AttacksSummaryCard[],
    merge?: boolean
  ): this {
    // Define a default chartType if not defined (could be nullable if never set)
    const cards = attacksSummaryCards.map(card => {
      return {
        ...card,
        chartType: card.chartType ?? ATTACKS_DEFAULT_CARD_CHART_TYPE
      }
    })

    const attacksSummaryCardEntities = createEntities<
      AttacksSummaryCard,
      EntityAttacksSummaryCard
    >(EntityAttacksSummaryCard, cards)

    // create stores
    const stores = attacksSummaryCardEntities.reduce(
      (acc, attacksSummaryCardEntity) => {
        const directoryId = attacksSummaryCardEntity.directoryId

        if (!directoryId) {
          return acc
        }

        const storeSummaryCard = new StoreSummaryCard(this.storeRoot, {
          attacksSummaryCardEntity
        })

        if (
          this.displayOnlyAttackedDomainsSwitch &&
          !attacksSummaryCardEntity.attacksCount
        ) {
          return acc
        }

        acc.set(directoryId, storeSummaryCard)
        return acc
      },
      new Map<number, StoreSummaryCard>()
    )

    // merge or replace all stores one shot (don't make a loop to avoid excessive renders)
    if (merge) {
      this.$storesSummaryCard.merge(stores)
    } else {
      this.$storesSummaryCard.replace(stores)
    }

    return this
  }

  /**
   * Replace a summary card by directoryId and instanciate a new StoreSummaryCard instead.
   */
  @action
  setSummaryCard(attacksSummaryCard: AttacksSummaryCard): this {
    // Define a default chartType if not defined (could be nullable if never set)
    const card: AttacksSummaryCard = {
      ...attacksSummaryCard,
      chartType: attacksSummaryCard.chartType ?? ATTACKS_DEFAULT_CARD_CHART_TYPE
    }

    const attacksSummaryCardEntity = createEntity<
      AttacksSummaryCard,
      EntityAttacksSummaryCard
    >(EntityAttacksSummaryCard, card)

    const directoryId = attacksSummaryCardEntity.directoryId

    if (!directoryId) {
      return this
    }

    const storeSummaryCard = new StoreSummaryCard(this.storeRoot, {
      attacksSummaryCardEntity
    })

    this.$storesSummaryCard.set(directoryId, storeSummaryCard)

    return this
  }

  /**
   * Set the Show only attacked domains switch value.
   */
  @action
  setDisplayOnlyAttackedDomainsSwitch(
    displayOnlyAttackedDomains: boolean
  ): this {
    this.$displayOnlyAttackedDomainsSwitch.set(displayOnlyAttackedDomains)

    return this
  }

  /* Computed */

  /**
   * Return the current layout.
   */
  @computed
  get summaryLayout(): AttacksSummaryLayout {
    return this.$attacksSummaryLayout.get() || AttacksSummaryLayout.Alphabetical
  }

  /**
   * Return the ont attacked domains switch value.
   */
  @computed
  get displayOnlyAttackedDomainsSwitch(): boolean {
    return this.$displayOnlyAttackedDomainsSwitch.get()
  }

  /**
   * Return stores for all cards.
   */
  @computed
  get storesSummaryCard(): StoreSummaryCard[] {
    return Array.from(this.$storesSummaryCard.values())
  }

  /**
   * Return stores that match the current filters.
   */
  @computed
  get filteredStoresSummaryCard(): StoreSummaryCard[] {
    const { storeInfrastructures } = this.storeRoot.stores.storeIoA.storeBoard

    return this.storesSummaryCard.filter(storeSummaryCard => {
      const { directoryEntity } = storeSummaryCard

      if (!directoryEntity) {
        return false
      }

      const infrastructureEntity =
        storeInfrastructures.getInfrastructureFromDirectoryId(
          directoryEntity.getPropertyAsNumber('id')
        )

      if (!infrastructureEntity) {
        return false
      }

      // Only selected directory ids
      const isDirectorySelected =
        storeInfrastructures.selectedDirectoryIds.some(
          id => id === directoryEntity.getPropertyAsNumber('id')
        )

      if (!isDirectorySelected) {
        return false
      }

      const attackNames = storeSummaryCard.attackTypesTop3
        .map(({ attackType, count }) => {
          if (count === 0) {
            return
          }
          return attackType.getPropertyAsString('name').toLowerCase()
        })
        .filter(isDefined)

      const directoryName = directoryEntity
        .getPropertyAsString('name')
        .toLowerCase()

      const infrastructureName = infrastructureEntity
        .getPropertyAsString('name')
        .toLowerCase()

      const sources = [...attackNames, directoryName, infrastructureName]

      return sources.some(name =>
        this.storeInputSearch.transformedSearchValueAsRegexp.test(name)
      )
    })
  }

  /**
   * Return "blocks", that contains stores for each card.
   */
  @computed
  get sortedFilteredStoresSummaryCardBlocks(): SummaryCardsBlock[] {
    switch (this.summaryLayout) {
      case AttacksSummaryLayout.Alphabetical: {
        return this._sortBlocksAlphabetically()
      }

      case AttacksSummaryLayout.Criticity: {
        return this._sortBlocksByCriticity()
      }

      case AttacksSummaryLayout.Infrastructures: {
        return this._sortBlocksByInfrastructure()
      }
    }
  }

  /**
   * Group "n" storeSummaryCards in a row.
   */
  getGroupedStoreSummaryCardByRows = (
    nbCardsByRow: number,
    storeSummaryCards: StoreSummaryCard[]
  ): StoreSummaryCard[][] => {
    return storeSummaryCards.reduce((acc, storeSummaryCard, index) => {
      const shouldCreateAGroup = index % nbCardsByRow === 0

      if (shouldCreateAGroup) {
        return acc.concat([[storeSummaryCard]])
      }

      const lastStore = acc.pop()

      if (!lastStore) {
        return acc
      }

      return acc.concat([lastStore.concat(storeSummaryCard)])
    }, [] as StoreSummaryCard[][])
  }

  /* Private */

  private _sortByDirectoryName = (a: StoreSummaryCard, b: StoreSummaryCard) => {
    const directoryNameA = a.directoryEntity?.name?.toLowerCase()
    const directoryNameB = b.directoryEntity?.name?.toLowerCase()

    if (!directoryNameA || !directoryNameB) {
      return 0
    }

    const value = directoryNameA > directoryNameB

    return value ? 1 : -1
  }

  private _sortBlocksAlphabetically(): SummaryCardsBlock[] {
    const directoriesNameFirstLetter = orderBy(
      uniq(
        this.filteredStoresSummaryCard
          .map(storeSummaryCard => {
            return storeSummaryCard.directoryEntity?.getPropertyAsString('name')
          })
          .filter(isDefinedAndNotEmptyString)
          .map(directoryName => directoryName[0].toLowerCase())
      )
    )

    return directoriesNameFirstLetter.map(directorNameFirstLetter => {
      const storesSummaryCard = this.filteredStoresSummaryCard
        .filter(storeSummaryCard => {
          const directoryName = storeSummaryCard.directoryEntity
            ?.getPropertyAsString('name', '-')[0]
            .toLowerCase()

          return directoryName === directorNameFirstLetter
        })
        .sort(this._sortByDirectoryName)

      return {
        blockName: directorNameFirstLetter.toUpperCase(),
        storesSummaryCard
      }
    })
  }

  private _sortBlocksByCriticity(): SummaryCardsBlock[] {
    const summaryCardsBlocks = CriticityValuesOrdered.map(criticity => {
      const orderedCriticities = getOrderedCriticityValuesStartingAt(criticity)

      const storesSummaryCard = this.filteredStoresSummaryCard
        .filter(storeSummaryCard => {
          const attacksByCricity = storeSummaryCard.numberOfAttacksByCriticity
          if (!attacksByCricity.length) {
            return false
          }

          const firstCriticityPositive = attacksByCricity.find(
            attack => attack.count > 0
          )

          if (!firstCriticityPositive) {
            return false
          }

          return firstCriticityPositive.criticity === criticity
        })
        .sort((a, b) => {
          const value = orderedCriticities.reduce((val, crit) => {
            // A distinguish sort value has been found, returning it
            if (val !== 0) {
              return val
            }

            const countA = a.numberOfAttacksByCriticity.find(numCrit => {
              return numCrit.criticity === crit
            })?.count

            const countB = b.numberOfAttacksByCriticity.find(numCrit => {
              return numCrit.criticity === crit
            })?.count

            if (isNull(countA) || isNull(countB) || countA === countB) {
              return val
            }

            return Number(countA) > Number(countB) ? -1 : 1
          }, 0)

          if (value !== 0) {
            return value
          }

          // If still equal, order by increasing alphabetical order
          const directoryNameA = a.directoryEntity
            ?.getPropertyAsString('name', '-')
            .toLowerCase()
          const directoryNameB = b.directoryEntity
            ?.getPropertyAsString('name', '-')
            .toLowerCase()

          if (!directoryNameA || !directoryNameB) {
            return 0
          }

          return directoryNameA < directoryNameB ? -1 : 1
        })

      if (!storesSummaryCard.length) {
        return
      }

      return {
        blockName: this.translate(criticity),
        storesSummaryCard
      }
    }).filter(isDefined)

    // Find all cards without attacks
    const noAttackStoresSummaryCard = this.filteredStoresSummaryCard
      .filter(
        storeSummaryCard =>
          !storeSummaryCard.numberOfAttacksByCriticity.some(
            byCriticty => byCriticty.count > 0
          )
      )
      .sort(this._sortByDirectoryName)

    if (!noAttackStoresSummaryCard.length) {
      return summaryCardsBlocks
    }

    const noAttacksBlock: SummaryCardsBlock = {
      blockName: this.translate('Without attack'),
      storesSummaryCard: noAttackStoresSummaryCard
    }

    return summaryCardsBlocks.concat(noAttacksBlock)
  }

  private _sortBlocksByInfrastructure(): SummaryCardsBlock[] {
    const { storeInfrastructures } = this.storeRoot.stores.storeIoA.storeBoard

    const infrastructureIds = uniq(
      this.filteredStoresSummaryCard
        .map(storeSummaryCard => {
          return storeSummaryCard.directoryEntity?.getPropertyAsNumber('id')
        })
        .filter(isDefined)
        .map(directoryId => {
          return storeInfrastructures.getInfrastructureFromDirectoryId(
            directoryId
          )
        })
        .map(infrastructureEntity => infrastructureEntity?.id)
        .filter(isDefined)
    )

    return infrastructureIds
      .map(infrastructureId => {
        const storesSummaryCard = this.filteredStoresSummaryCard
          .filter(storeSummaryCard => {
            const directoryId =
              storeSummaryCard.directoryEntity?.getPropertyAsNumber('id')

            if (!directoryId) {
              return false
            }

            const infrastructureEntity =
              storeInfrastructures.getInfrastructureFromDirectoryId(directoryId)

            return infrastructureEntity?.id === infrastructureId
          })
          .sort(this._sortByDirectoryName)

        const infrastructureName =
          storeInfrastructures
            .getInfrastructureFromId(infrastructureId)
            ?.getPropertyAsString('name') || '-'

        return {
          blockName: infrastructureName.toLowerCase(),
          storesSummaryCard,
          infrastructureId
        }
      })
      .sort((a, b) => {
        if (a.blockName === b.blockName) {
          return 0
        }

        return a.blockName > b.blockName ? 1 : -1
      })
  }
}
