import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperSelect } from '@app/components-legacy/Form'
import type { IFormWrapperSelectOptionProps } from '@app/components-legacy/Form/Wrappers/Select'
import InputSearch from '@app/components-legacy/Input/InputSearch'
import { useAppTranslator, useStores } from '@app/hooks'
import { compareStringsAlphabetically } from '@libs/compareStringsAlphabetically'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  handleDrawerNodeRelationshipAdObjectTypeFilterChange,
  handleDrawerNodeRelationshipNameFilterChange
} from './handlers'

export interface IDrawerNodeRelationshipsFiltersProps {
  className?: string
}

const DrawerNodeRelationshipsFilters: React.FC<
  IDrawerNodeRelationshipsFiltersProps
> = props => {
  const { storeAttackPath } = useStores()

  const translate = useAppTranslator({
    namespaces: ['AttackPath.Drawer']
  })

  const adObjectTypes = new Set<string>()

  storeAttackPath.filteredByNameDrawerChildrenNodesWithEdge.forEach(
    nodeWithEdge => {
      if (!nodeWithEdge.node.adObjectType) {
        return
      }
      adObjectTypes.add(nodeWithEdge.node.adObjectType)
    }
  )

  const adObjectTypeOptions: IFormWrapperSelectOptionProps[] = [
    ...adObjectTypes
  ]
    .sort(compareStringsAlphabetically)
    .map(adObjectType => {
      const count =
        storeAttackPath.filteredByNameDrawerChildrenNodesWithEdge.filter(
          ({ node }) => node.adObjectType === adObjectType
        ).length

      return {
        label: adObjectType,
        value: adObjectType,
        count
      }
    })

  return (
    <ContainerFlex
      name="DrawerNodeRelationshipsFilters"
      className={props.className}
      items={[
        <InputSearch
          placeholder={translate('Search for an object')}
          autoComplete="off"
          onChange={handleDrawerNodeRelationshipNameFilterChange(
            storeAttackPath
          )}
          onReset={handleDrawerNodeRelationshipNameFilterChange(
            storeAttackPath
          )}
          storeInputSearch={storeAttackPath.storeInputSearchDrawer}
        />,
        <FormWrapperSelect<any>
          selectProps={{
            placeholder: translate('All objects types'),
            mode: 'multiple',
            value: storeAttackPath.drawerAdObjectTypeFilter,
            onChange:
              handleDrawerNodeRelationshipAdObjectTypeFilterChange(
                storeAttackPath
              )
          }}
          labelledBy="attackPathFilterAdObjectType"
          selectOptions={adObjectTypeOptions}
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

const ObservedDrawerNodeRelationshipsFilters = observer(
  DrawerNodeRelationshipsFilters
)

export default styled(ObservedDrawerNodeRelationshipsFilters)`
  padding-left: 48px;
`
