import { ContainerHTMLContent } from '@app/components-legacy/Container'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { mdToHTML } from '@libs/mdToHTML'
import { sliceMarkdownString, sliceString } from '@libs/sliceString'
import * as React from 'react'
import styled from 'styled-components'
import { LabelNonBreakable } from '..'
import type { ILabelSlicedProps } from './types'

function isEllipsisActive(element: HTMLElement): boolean {
  return element.offsetWidth < element.scrollWidth
}

interface ILabelSlicedContainerProps extends ILabelSlicedProps {
  onEllipsisActiveChange?: (ellipsisActive: boolean) => void
}

const EllipsisContainer = styled.div<{ maxWidth: number }>`
  display: inline-flex;
  max-width: ${props => props.maxWidth}px;
`

/** @deprecated */
const LabelSlicedContainer: React.FC<ILabelSlicedContainerProps> = props => {
  const { testAttributeProps } = useTestAttribute('contentinfo')

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (props.onEllipsisActiveChange) {
      props.onEllipsisActiveChange(isEllipsisActive(event.currentTarget))
    }
  }

  if (props.maxWidth) {
    return (
      <EllipsisContainer maxWidth={props.maxWidth}>
        <LabelNonBreakable
          className={props.className}
          labelledBy={props.labelledBy}
          ellipsis
          containerProps={{ onMouseEnter: handleMouseEnter }}
        >
          {props.value}
        </LabelNonBreakable>
      </EllipsisContainer>
    )
  }

  const renderHtmlContainer = () => {
    if (!props.maxLength) {
      return (
        <ContainerHTMLContent
          className={props.className}
          labelledBy={props.labelledBy}
          {...props.containerHMTLContentProps}
        >
          {mdToHTML(props.value)}
        </ContainerHTMLContent>
      )
    }

    const slicedText = sliceMarkdownString(props.value, {
      maxLength: props.maxLength
    })

    return (
      <ContainerHTMLContent
        className={props.className}
        labelledBy={props.labelledBy}
        {...props.containerHMTLContentProps}
      >
        {mdToHTML(slicedText)}
      </ContainerHTMLContent>
    )
  }

  const renderSimpleContainer = () => {
    const slicedText = props.maxLength
      ? sliceString(props.value, { maxLength: props.maxLength })
      : props.value

    if (props.breakString === true) {
      return (
        <div className={props.className} {...testAttributeProps}>
          {slicedText}
        </div>
      )
    }

    return (
      <LabelNonBreakable
        className={props.className}
        labelledBy={props.labelledBy}
      >
        {slicedText}
      </LabelNonBreakable>
    )
  }

  const Container = props.allowHTML
    ? renderHtmlContainer()
    : renderSimpleContainer()

  if (props.valueWrapper) {
    return <props.valueWrapper>{Container}</props.valueWrapper>
  }

  return <>{Container}</>
}

export default LabelSlicedContainer
