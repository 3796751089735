import { AppRouteName } from '@app/routes'
import type {
  StoreBlades,
  StoreManagementUsers,
  StoreMessages,
  StoreRbac
} from '@app/stores'
import { UserFormFieldName } from '@app/stores/Management/StoreUsers'
import type { TranslateFn } from '@libs/i18n'
import { editRole } from '@libs/rbac/permissions'
import type { AppRouterClient } from '@libs/Router/types'
import type {
  EditUserRolesMutationArgs,
  InputCreateUser
} from '@server/graphql/typeDefs/types'
import { AuthProviderName } from '@server/graphql/typeDefs/types'

/**
 * On load, reset the form.
 */
export const handleUserCreationOnLoad =
  (storeManagementUsers: StoreManagementUsers) => () => {
    storeManagementUsers.reset()

    storeManagementUsers.storeForm
      .setDefaultFieldsValues([
        {
          key: UserFormFieldName.formVersion,
          value: 'creation'
        },
        {
          key: UserFormFieldName.provider,
          value: AuthProviderName.Tenable
        },
        {
          key: UserFormFieldName.active,
          value: true
        }
      ])
      .reset()

    // select default roles
    const rbacRoleRows = storeManagementUsers.rbacUserCreationDefaultRoles.map(
      rbacRoleEntity => rbacRoleEntity.asDataRow()
    )

    storeManagementUsers.storeWidgetRbacRolesList
      .unselectAllRows()
      .resetPagination()
      .selectRows(rbacRoleRows)
  }

/**
 * Create the user.
 */
export const handleUserCreationOnSubmit =
  (
    storeMessages: StoreMessages,
    storeRbac: StoreRbac,
    storeManagementUsers: StoreManagementUsers
  ) =>
  (translate: TranslateFn, appRouter: AppRouterClient) =>
  (e: React.FormEvent<any>) => {
    const { storeForm } = storeManagementUsers

    e.preventDefault()

    if (!storeForm.validate()) {
      return
    }

    if (
      storeForm.getFieldValueAsString('password') !==
      storeForm.getFieldValueAsString('passwordConfirmation')
    ) {
      storeMessages.error(
        translate('Password and confirmation password do not match'),
        {
          labelledBy: 'passwordNotMatch'
        }
      )
      return
    }

    const user: InputCreateUser = {
      surname: storeForm.getFieldValueAsString(UserFormFieldName.surname),
      name: storeForm.getFieldValueAsString(UserFormFieldName.name),
      email: storeForm.getFieldValueAsString(UserFormFieldName.email),
      password: storeForm.getFieldValueAsString(UserFormFieldName.password),
      department: storeForm.getFieldValueAsString(UserFormFieldName.department),
      biography: storeForm.getFieldValueAsString(UserFormFieldName.biography),
      active: storeForm.getFieldValueAsBoolean(UserFormFieldName.active)
    }

    storeManagementUsers
      .createUser(user)
      .then(userId => {
        if (!userId) {
          storeMessages.error('Cant apply roles on the create user', {
            labelledBy: 'cantApplyRolesCreateUser'
          })
          return
        }

        const { selectedRows } = storeManagementUsers.storeWidgetRbacRolesList

        if (!selectedRows) {
          return
        }

        const selectedRoleIds = Array.from(selectedRows.keys()).map(id =>
          Number(id)
        )

        const roles: EditUserRolesMutationArgs = {
          roles: {
            userId,
            roles: selectedRoleIds
          }
        }

        // set roles only if granted
        const canSetRoles = storeRbac.isUserGrantedTo(editRole())

        if (!canSetRoles) {
          return Promise.resolve()
        }

        return storeManagementUsers.setUserRoles(roles)
      })
      .then(() => {
        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Management_Accounts_Users,
          parameters: {}
        })
        appRouter.history.push(url)
      })
      .catch(() => {
        // already catched in the store
      })
  }

/**
 * Close the blade when cancelling the user edition.
 */
export const handleUserCreationCancelOnClick =
  (storeBlades: StoreBlades) => () => {
    const url = storeBlades.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_Accounts_Users,
      parameters: {}
    })

    storeBlades.goToBladeUrl(url)
  }
