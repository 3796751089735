import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface IIconCloseRawProps {
  className?: string
  color?: string
  onClick: () => void
}

const IconCloseRaw = (props: IIconCloseRawProps) => {
  const onClick = () => props.onClick()

  const fill = props.color || consts.colorBlueGray010

  return (
    <svg className={props.className} width={24} height={24} onClick={onClick}>
      <defs>
        <path
          id="icon-close-prefix__a"
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="icon-close-prefix__b" fill="#fff">
          <use xlinkHref="#icon-close-prefix__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#icon-close-prefix__a" />
        <g mask="url(#icon-close-prefix__b)" fill={fill}>
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  )
}

/**
 * @deprecated
 */
export default styled(IconCloseRaw)`
  cursor: pointer;
`
