import type { Maybe } from '@@types/helpers'
import * as React from 'react'

const HealthCheckDetailsContext = React.createContext<
  Maybe<{
    healthCheckName: string
    directoryId?: number
  }>
>(null)

export { HealthCheckDetailsContext }
