import { BladeCancelButton } from '@app/components-legacy/Blade'
import {
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { IconCheckOutlined } from '@app/components-legacy/Icon/IconAntd'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  handleProfileCommitOnClick,
  handleProfileUnstageOnClick
} from '../ProfilesPage/handlers'

interface IProfileEditFooterProps {}

const ProfileEditFooter: React.FC<IProfileEditFooterProps> = () => {
  const translate = useAppTranslator({
    namespaces: [
      'Management.Accounts.Profiles.List',
      'Management.Accounts.Profiles.Edition'
    ]
  })

  const { storeManagementProfiles } = useStores()

  const currentProfileEntity = storeManagementProfiles.currentProfileEntity

  if (!currentProfileEntity) {
    return null
  }

  const isCurrentProfileDirty =
    currentProfileEntity.getPropertyAsBoolean('dirty')

  if (currentProfileEntity && isCurrentProfileDirty) {
    return (
      <ContainerFooter>
        <FormWrapperButton
          labelledBy="revertChanges"
          buttonProps={{
            disabled: !isCurrentProfileDirty,
            onClick: handleProfileUnstageOnClick(storeManagementProfiles)(
              currentProfileEntity
            )
          }}
        >
          {translate('Revert changes')}
        </FormWrapperButton>

        <FormWrapperButton
          labelledBy="commitChanges"
          buttonProps={{
            disabled: !isCurrentProfileDirty,
            onClick: handleProfileCommitOnClick(storeManagementProfiles)(
              currentProfileEntity.getPropertyAsNumber('id'),
              currentProfileEntity.getPropertyAsString('name')
            )
          }}
        >
          {translate('Commit changes')}
        </FormWrapperButton>
      </ContainerFooter>
    )
  }

  return (
    <ContainerFooter>
      <BladeCancelButton />

      <ContainerFlex
        name={ProfileEditFooter.name}
        alignItems="center"
        items={[
          <IconCheckOutlined />,
          <div>{translate('This profile has no pending modification')}</div>
        ]}
        spaced
        spaceWidth="verySmall"
      />
    </ContainerFooter>
  )
}

export default observer(ProfileEditFooter)
