import {
  EntityAttackPathNode,
  EntityAttackPathNodeDrawer,
  EntityAttackPathNodeExpand
} from '@app/entities'
import type {
  ILineProps,
  IRectangleProps
} from '@app/entities/EntityAttackPathEdge'
import { useStores } from '@app/hooks'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import type { EntityAttackPathNodeAny } from '@app/stores/AttackPath/types'
import { consts } from '@app/styles'
import { isDefined } from '@libs/isDefined'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type { Maybe } from '@server/graphql/typeDefs/types'
import {
  AttackPathDirection,
  AttackPathRelationType
} from '@server/graphql/typeDefs/types'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { handleEdgeOnMouseOut, handleEdgeOnMouseOver } from '../handlers'
import RelationsTooltipGraph from './RelationsTooltipGraph'

export interface IArrowLinkProps {
  className?: string
  lineProps: Maybe<ILineProps>
  arrowProps: React.SVGProps<SVGPathElement>
  iconProps: Array<React.SVGProps<SVGImageElement>>
  rectangleProps: Maybe<IRectangleProps>
  rectangleAngle: number
  relationTypes: Maybe<AttackPathRelationType[]>
  id: Maybe<string>
  uuidLinked: Maybe<string>
  isFake: boolean
  controlRightRelations?: Maybe<AttackPathRelationType[]>
  source?: EntityAttackPathNodeAny
  target?: EntityAttackPathNodeAny
}

const ArrowLink: React.FC<IArrowLinkProps> = props => {
  const { storeAttackPath } = useStores()

  const { testAttributeProps } = useTestAttribute('contentinfo')

  const isHoveringNode = isDefined(storeAttackPath.currentNodeId)

  const isHoveringEdge = isDefined(storeAttackPath.currentEdgeId)

  const isPinnedNode = isDefined(storeAttackPath.currentPinnedNodeUid)

  const isPartOfCurrentPath = () => {
    if (!props.target || !props.source) {
      return false
    }

    if (!isHoveringNode && !isPinnedNode) {
      return false
    }

    if (
      storeAttackPath.direction === AttackPathDirection.From ||
      storeAttackPath.targetNode
    ) {
      // if edge's target is the target node
      if (storeAttackPath.targetNode?.id === props.target.id) {
        // if node uid hovered or pinned is the same as the edge's source
        if (
          storeAttackPath.currentNodeId === props.source.uid ||
          storeAttackPath.currentPinnedNodeUid === props.source.uid
        ) {
          return true
        }

        // if edge is fake we need to find out if related real edge is highlighted
        if (props.isFake && props.uuidLinked) {
          const edgeRelated = storeAttackPath.getEdgeByUid(props.uuidLinked)

          if (
            edgeRelated &&
            (storeAttackPath.currentNodeId === edgeRelated.source?.uid ||
              storeAttackPath.currentPinnedNodeUid === edgeRelated.source?.uid)
          ) {
            return true
          }
        }
      }

      return (
        storeAttackPath.currentPath.has(
          props.target.getPropertyAsString('uid')
        ) ||
        storeAttackPath.currentPinnedPath.has(
          props.target.getPropertyAsString('uid')
        )
      )
    }

    return (
      storeAttackPath.currentPath.has(
        props.source.getPropertyAsString('uid')
      ) ||
      storeAttackPath.currentPinnedPath.has(
        props.source.getPropertyAsString('uid')
      )
    )
  }

  const isConnectedToCurrentEdge = () => {
    if (!isHoveringEdge) {
      return false
    }

    if (props.uuidLinked === storeAttackPath.currentEdgeId) {
      return true
    }

    if (props.id === storeAttackPath.currentEdgeId) {
      return true
    }

    return false
  }

  const isHighlighted =
    isPartOfCurrentPath() ||
    storeAttackPath.isAllGraphHighlighted ||
    isConnectedToCurrentEdge()

  const hasLinkToOrFromAnExpandableNode = () => {
    if (!props.target || !props.source) {
      return false
    }

    // There is at least one item that is an expansion node (button +)
    const oneItemIsAnExpandNode =
      props.source instanceof EntityAttackPathNodeExpand ||
      props.target instanceof EntityAttackPathNodeExpand

    // One of the source / target is the expanded node
    return (
      (storeAttackPath.expandedNodeUids.includes(
        props.target.getPropertyAsString('uid')
      ) ||
        storeAttackPath.expandedNodeUids.includes(
          props.source.getPropertyAsString('uid')
        )) &&
      oneItemIsAnExpandNode
    )
  }

  const isFromLastExpandedNode = () => {
    if (!props.target || !props.source) {
      return false
    }

    return (
      storeAttackPath.expandedNodeUids.indexOf(
        props.target?.getPropertyAsString('uid')
      ) ===
        storeAttackPath.expandedNodeUids.length - 1 ||
      storeAttackPath.expandedNodeUids.indexOf(
        props.source.getPropertyAsString('uid')
      ) ===
        storeAttackPath.expandedNodeUids.length - 1
    )
  }

  const canBeDisplayed =
    !hasLinkToOrFromAnExpandableNode() ||
    (storeAttackPath.storeFlagsFetchExpandedNodes.flags.isLoading &&
      isFromLastExpandedNode())

  const classNames = classnames({
    [props.className || '']: true,
    isHighlighted
  })

  const arrowColor = isHighlighted
    ? consts.colorAttackPathSecondary
    : consts.colorAttackPathArrow

  const rectColor = isHighlighted
    ? consts.colorAttackPathSecondary
    : consts.colorAttackPathPrimary

  const attrProps = () => {
    if (!props.source || !props.target || props.isFake) {
      return
    }

    function getTestValue(node: EntityAttackPathNodeAny): string {
      if (node instanceof EntityAttackPathNode) {
        return node.name || String(node.id)
      }
      if (node instanceof EntityAttackPathNodeDrawer) {
        return 'drawer'
      }
      if (node instanceof EntityAttackPathNodeExpand) {
        return 'expand'
      }
      assertUnreachableCase(node)
    }

    return testAttributeProps()(
      `edge-source-${getTestValue(props.source)}-target-${getTestValue(
        props.target
      )}`
    )
  }

  const halfWidthRect = props.rectangleProps
    ? props.rectangleProps.width / 2
    : 0
  const rotateX = props.rectangleProps
    ? props.rectangleProps.x + halfWidthRect
    : 0

  const halfHeightRect = props.rectangleProps
    ? props.rectangleProps.height / 2
    : 0
  const rotateY = props.rectangleProps
    ? props.rectangleProps.y + halfHeightRect
    : 0

  const isOnlyFakeRelation =
    props.relationTypes &&
    props.relationTypes.includes(AttackPathRelationType.Fake) &&
    props.relationTypes.length === 1

  return (
    <g {...attrProps()}>
      {canBeDisplayed && (
        <g
          className={classNames}
          onMouseOver={handleEdgeOnMouseOver(storeAttackPath)(
            props.isFake ? props.uuidLinked : props.id
          )}
          onMouseOut={handleEdgeOnMouseOut(storeAttackPath)}
        >
          <line
            {...props.lineProps}
            strokeWidth={1}
            stroke={consts.colorAttackPathPrimary}
            strokeOpacity={0.6}
          />

          {props.relationTypes &&
            props.rectangleProps &&
            !isOnlyFakeRelation && (
              <RelationsTooltipGraph
                relationTypes={props.relationTypes}
                controlRightRelations={props.controlRightRelations}
                sourceId={props.source?.id}
                targetId={props.target?.id}
              >
                <g
                  transform={`rotate(${props.rectangleAngle} ${rotateX} ${rotateY})`}
                >
                  <rect
                    x={props.rectangleProps.x}
                    y={props.rectangleProps.y}
                    width={props.rectangleProps.width}
                    height={props.rectangleProps.height}
                    fill={rectColor}
                    rx="10"
                  />
                  {props.relationTypes.map((relation, index) => {
                    if (relation === AttackPathRelationType.Fake) {
                      return
                    }

                    const iconProps = props.iconProps[index]

                    if (!iconProps || !props.rectangleProps) {
                      return
                    }

                    const href = iconProps.href as string
                    const hrefHover = href
                      .substring(0, href.length - 4)
                      .concat('-hover.svg')

                    const posY = props.rectangleProps.y
                    const iconWidth = iconProps.width as number

                    const rotateIconX = props.rectangleProps.x + iconWidth / 2
                    const rotateIconY = posY + iconWidth * index + iconWidth / 2

                    return (
                      <g
                        key={relation}
                        transform={`rotate(${-props.rectangleAngle} ${rotateIconX} ${rotateIconY})`}
                      >
                        <image
                          {...props.iconProps[index]}
                          x={props.rectangleProps.x}
                          y={posY + iconWidth * index}
                        />

                        <image
                          {...props.iconProps[index]}
                          x={props.rectangleProps.x}
                          y={posY + iconWidth * index}
                          href={hrefHover}
                          className="hover"
                        />
                      </g>
                    )
                  })}
                </g>
              </RelationsTooltipGraph>
            )}

          {!props.target?.isHiddenNode && (
            <path
              fill="none"
              {...props.arrowProps}
              strokeWidth={2}
              stroke={arrowColor}
              strokeLinecap="round"
            />
          )}
        </g>
      )}
    </g>
  )
}

const ObservedArrowLink = observer(ArrowLink)

export default styled(ObservedArrowLink)`
  &:hover,
  &.isHighlighted {
    line,
    path {
      stroke: ${consts.colorAttackPathSecondary};
      stroke-opacity: 1;
    }

    image {
      display: none;
    }

    image.hover {
      display: block;
    }
  }

  image.hover {
    display: none;
  }
`
