import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { useAppTranslator } from '@app/hooks'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import * as React from 'react'
import { Link } from 'react-router-dom'
import DirectoryCommonForm from '../DirectoriesCreatePage/DirectoryCommonForm'
import { onDirectoriesEditCancelClick } from '../DirectoriesEditPage/handlers'
import { onDirectoriesCreateSubmit } from './handlers'

interface IDirectoryCreateFormProps {}

const DirectoryCreateForm: React.FC<IDirectoryCreateFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Titles',
      'Management.System.Directories',
      'Management.System.Infrastructures'
    ]
  })

  const appRouter = useAppRouter()

  const { storeBlades, storeInfrastructures, storeManagementDirectories } =
    useStores()

  if (!storeInfrastructures.infrastructures.size) {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Infrastructures_Create,
      parameters: {}
    })

    return (
      <ContainerContent title={translate('Main information')}>
        <ContainerFlex
          name="CreateInfrastructure"
          items={[
            <div>{translate('No forest created yet')}</div>,
            <Link to={url}>{translate('Add a forest')}</Link>
          ]}
          spaced
          spaceWidth="verySmall"
        />
      </ContainerContent>
    )
  }

  return (
    <DirectoryCommonForm
      version="creation"
      onCancelButtonClick={onDirectoriesEditCancelClick(storeBlades)}
      onSubmit={onDirectoriesCreateSubmit(
        appRouter,
        storeManagementDirectories
      )}
    />
  )
}

export default DirectoryCreateForm
