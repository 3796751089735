import type { IIconProps } from '@app/components/Icon/types'
import { FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconSearchProps extends IIconProps {}

const IconSearch: React.FC<IIconSearchProps> = props => {
  const fill = props.color ?? FontColorV2.primary
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      style={props.style}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.6 12.8352L10.6044 9.83956C11.2226 9.05636 11.595 8.07061 11.595 6.9975C11.595 4.4624 9.53261 2.40002 6.9975 2.40002C4.4624 2.40002 2.40002 4.4624 2.40002 6.9975C2.40002 9.53261 4.4624 11.595 6.9975 11.595C8.07061 11.595 9.05636 11.2226 9.83956 10.6044L12.8352 13.6L13.6 12.8352ZM6.9975 10.5132C5.05899 10.5132 3.48178 8.93602 3.48178 6.9975C3.48178 5.05899 5.05899 3.48178 6.9975 3.48178C8.93629 3.48178 10.5132 5.05899 10.5132 6.9975C10.5132 8.93602 8.93629 10.5132 6.9975 10.5132Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconSearch
