import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { ContainerHTMLContent } from '@app/components-legacy/Container'
import { Modal } from '@app/components-legacy/Modal'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onRestartTrailFlow } from './handlers'

export interface ITrailFlowPageAutomaticPauseModalProps {}

const TrailFlowPageAutomaticPauseModal: React.FC<
  ITrailFlowPageAutomaticPauseModalProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Titles', 'Buttons', 'TrailFlow.Table']
  })

  const { storeTrailFlow } = useStores()

  return (
    <Modal
      modalProps={{
        title: translate('The Trail Flow has paused'),
        okText: translate('Restart the Trail Flow'),
        closable: true,
        maskClosable: true,
        onOk: () => {
          onRestartTrailFlow(storeTrailFlow)(null)()
          storeTrailFlow.storeModalTrailFlowAutomaticPause.hide()
        }
      }}
      storeModal={storeTrailFlow.storeModalTrailFlowAutomaticPause}
    >
      <Label variant={LabelVariant.p}>
        <ContainerHTMLContent padded>
          {translate('The Trail Flow has paused due to inactivity')}
        </ContainerHTMLContent>
      </Label>
    </Modal>
  )
}

export default observer(TrailFlowPageAutomaticPauseModal)
