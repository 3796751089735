import { ContainerFlex } from '@app/components/Container'
import { IconRightArrow, IconWarningCircle } from '@app/components/Icon'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { WrapperAlert } from '@app/components/Wrapper'
import { AlertSize, AlertVariant } from '@app/components/Wrapper/types'
import FormWrapperButtonSimple from '@app/components-legacy/Form/Wrappers/ButtonSimple'
import { Modal } from '@app/components-legacy/Modal'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { BackgroundColorV2, FontColorV2 } from '@app/styles/consts'
import { isDefined } from '@libs/isDefined'
import { flatMap } from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { handleArrowErrorsModalOnClick } from './handlers'

const StyledAlert = styled(WrapperAlert)`
  width: 100%;

  .ant-alert {
    text-align: left;
  }
`

const DivError = styled.div`
  background-color: ${BackgroundColorV2.redLight};
  border-radius: ${consts.borderRadiusSmallRedesign};
  margin-bottom: ${consts.marginVerySmall};
  padding: ${consts.paddingDefault} ${consts.paddingLarge};
`

interface IErrorMessageProps {}

const ErrorMessage: React.FC<IErrorMessageProps> = () => {
  const { storeManagementAttackTypeConfiguration, storeManagementDirectories } =
    useStores()

  const { scriptDownloadDate } = storeManagementAttackTypeConfiguration

  const directoriesNotInstalledScript =
    storeManagementDirectories.getNotInstalledScriptDirectories()

  const translate = useAppTranslator({
    namespaces: [
      'Management.System.Configuration.IoA',
      'Management.System.Configuration.IoA.FailureReasons'
    ]
  })

  const durationInHoursSinceScriptDownload = moment
    .duration(moment(moment.now()).diff(scriptDownloadDate))
    .asHours()

  const isInstallationNotCompletedYet =
    durationInHoursSinceScriptDownload > 24 &&
    Array.from(directoriesNotInstalledScript).length

  const entityInfrastructures = Array.from(
    storeManagementAttackTypeConfiguration.storeInfrastructures.infrastructures.values()
  )

  const directories = flatMap(
    entityInfrastructures.map(entityInfrastructure =>
      entityInfrastructure.getDirectories()
    )
  ).filter(isDefined)

  const directoriesWithFailureReasons = directories.filter(
    directory => directory.ioaProcedureFailureReason !== null
  )

  if (!isInstallationNotCompletedYet && !directoriesWithFailureReasons.length) {
    return null
  }

  return (
    <>
      <StyledAlert
        name="IoaErrorMessage"
        labelledBy="ioaErrorMessage"
        onClick={
          directoriesWithFailureReasons.length > 1
            ? handleArrowErrorsModalOnClick(
                storeManagementAttackTypeConfiguration.storeModalIoAErrors
              )
            : undefined
        }
        variant={AlertVariant.error}
        alertProps={{
          showIcon: true,
          icon: <IconWarningCircle />,
          message: (
            <>
              {isInstallationNotCompletedYet &&
                translate(
                  'You downloaded the installation script but Tenable.ad detected that the installation procedure did not complete.'
                )}

              {directoriesWithFailureReasons.length > 0 && (
                <ContainerFlex
                  name="IoAErrors"
                  alignItems="center"
                  justifyContent="space-between"
                  items={[
                    directoriesWithFailureReasons.length === 1 ? (
                      <Label variant={LabelVariant.p} color={FontColorV2.red}>
                        <div>
                          <b>
                            {translate(
                              directoriesWithFailureReasons[0].getPropertyAsString(
                                'ioaProcedureFailureReason'
                              )
                            )}
                            .
                          </b>
                        </div>
                        {
                          directoriesWithFailureReasons[0]
                            .ioaProcedureFailureDescription
                        }
                      </Label>
                    ) : (
                      <Label variant={LabelVariant.p} color={FontColorV2.red}>
                        {translate(
                          'The Indicator of Attack deployment encountered several errors'
                        )}
                      </Label>
                    ),
                    directoriesWithFailureReasons.length > 1 && (
                      <FormWrapperButtonSimple
                        labelledBy="openErrorsModal"
                        onClick={handleArrowErrorsModalOnClick(
                          storeManagementAttackTypeConfiguration.storeModalIoAErrors
                        )}
                      >
                        <IconRightArrow color={FontColorV2.red} size={16} />
                      </FormWrapperButtonSimple>
                    )
                  ]}
                  spaced
                />
              )}
            </>
          )
        }}
        size={AlertSize.large}
      />

      {directoriesWithFailureReasons.length > 1 && (
        <Modal
          modalProps={{
            title: translate('Errors'),
            footer: null,
            width: 800,
            closable: true
          }}
          storeModal={
            storeManagementAttackTypeConfiguration.storeModalIoAErrors
          }
        >
          {directoriesWithFailureReasons.map(directory => (
            <DivError key={directory.id}>
              <Label variant={LabelVariant.p} color={FontColorV2.red}>
                <div>
                  <b>
                    {translate(
                      directory.getPropertyAsString('ioaProcedureFailureReason')
                    )}
                    .
                  </b>
                </div>
                {directory.ioaProcedureFailureDescription}
              </Label>
            </DivError>
          ))}
        </Modal>
      )}
    </>
  )
}
export default observer(ErrorMessage)
