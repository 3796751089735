import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'
import IconDirection, { Direction } from './IconDirection'

interface IIconRightProps extends IIconProps {}

const IconRight: React.FC<IIconRightProps> = props => (
  <IconDirection direction={Direction.right} {...props} />
)

export default IconRight
