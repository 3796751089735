import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canCreateDirectories } from '../permissions'
import DirectoryCreateForm from './DirectoryCreateForm'
import {
  onDirectoriesCreatePageLoad,
  onDirectoriesCreatePageUnload
} from './handlers'

interface IDirectoriesCreatePageProps {}

const DirectoriesCreatePage: React.FC<IDirectoriesCreatePageProps> = props => {
  const {
    storeManagementDirectories,
    storeManagementDataCollection,
    storeManagementRelays,
    storeRbac
  } = useStores()

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_System_Directories_Create,
        parameters: {}
      }}
      onLoad={onDirectoriesCreatePageLoad(
        storeManagementDirectories,
        storeManagementDataCollection,
        storeManagementRelays,
        storeRbac
      )}
      onUnload={onDirectoriesCreatePageUnload(
        storeManagementDirectories,
        storeManagementRelays
      )}
      flags={storeManagementDirectories.storeFlags.flags}
      layout={{
        name: 'default',
        content: (
          <ContainerContent>
            <DirectoryCreateForm />
          </ContainerContent>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canCreateDirectories}
    />
  )
}

export default observer(DirectoriesCreatePage)
