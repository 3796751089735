/**
 * Namespaces used in different loggers.
 */
export const loggerMapping = {
  client: {
    /** Layers */
    init: 'init',
    react: 'react',
    stores: 'stores',
    queries: 'queries',

    /** libs */
    FetchClient: 'FetchClient',
    UrlBuilder: 'UrlBuilder',

    /** Domains */

    internal: 'internal',
    authentication: 'authentication',

    /** Components */

    ListTable: 'ListTable',
    MyComponent: 'MyComponent'
  },

  sdk: {
    SecurityEngineApp: 'SecurityEngineApp'
  },

  securityEngine: {
    /* Domain specifics */

    init: 'init',

    /* Helpers */

    urlBuilder: 'urlBuilder',

    /* React Pages */

    Page: 'Page',

    /* Stores */

    StoreRoot: 'StoreRoot',

    // Stores for other components
    StoreButtonCustomizeColumns: 'StoreButtonCustomizeColumns',

    // Components
    ButtonColumnsSelector: 'ButtonColumnsSelector',

    // Pages
    StorePageWeaknessInstances: 'StorePageWeaknessInstances'
  },

  common: {
    i18n: 'i18n',
    iframeCommunicationBus: 'iframeCommunicationBus',
    stubs: 'stubs',
    helpers: 'helpers',
    validators: 'validators'
  },

  server: {
    init: 'init',
    config: 'config'
  },

  mocks: {
    generator: 'generator'
  }
}
