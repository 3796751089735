import {
  ContainerContent,
  ContainerFooter
} from '@app/components-legacy/Container'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import Button from '@app/components-legacy/Form/Wrappers/Button'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { ActivityLogsConfigurationFormFieldName } from '@app/stores/Management/StoreApplicationSettings/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  handleActivityLogsDeleteAllOnClick,
  onActivityLogsConfigurationSubmit
} from './handlers'
import {
  canDeleteUserTraces,
  canSubmitActivityLogsConfiguration
} from './permissions'
import UserTracesConfirmDeleteModal from './UserTracesConfirmDeleteModal'

interface IConfigurationLogsFormProps {}

const ConfigurationActivityLogsForm: React.FC<
  IConfigurationLogsFormProps
> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Configuration.Navigation',
      'Management.System.Configuration.ActivityLogs'
    ]
  })

  const { storeRbac, storeManagementApplicationSettings, storeActivityLogs } =
    useStores()

  return (
    <FormWrapper
      name="activityLogsConfiguration"
      onSubmit={onActivityLogsConfigurationSubmit(
        storeManagementApplicationSettings
      )}
    >
      <ContainerContent title={translate('Activity Logs management')}>
        <WidgetForm<ActivityLogsConfigurationFormFieldName>
          translate={translate}
          fieldsParams={{
            [ActivityLogsConfigurationFormFieldName.retentionDurationInMonth]: {
              inputType: InputType.select,
              inputProps: {
                labelledBy: 'retentionDurationInMonth',
                selectProps: {},
                selectOptions: [
                  {
                    value: 1,
                    label: '1'
                  },
                  {
                    value: 2,
                    label: '2'
                  },
                  {
                    value: 3,
                    label: '3'
                  },
                  {
                    value: 4,
                    label: '4'
                  },
                  {
                    value: 5,
                    label: '5'
                  },
                  {
                    value: 6,
                    label: '6'
                  }
                ]
              }
            }
          }}
          storeForm={
            storeManagementApplicationSettings.storeFormActivityLogsConfiguration
          }
        />
      </ContainerContent>

      <Portal name={PlaceHolderName.bladeFooter}>
        <ContainerFooter justify="right">
          <Button
            labelledBy="deleteAllTracesButton"
            buttonProps={{
              onClick: handleActivityLogsDeleteAllOnClick(storeActivityLogs),
              danger: true
            }}
          >
            {translate('Clear all the activity logs data')}
          </Button>

          <FormWrapperButtonSubmit
            loading={
              storeManagementApplicationSettings.storeFlagsAppSettingsSubmit
                .flags.isLoading
            }
            disabled={
              !storeRbac.isUserGrantedTo(canSubmitActivityLogsConfiguration)
            }
          >
            {translate('Save')}
          </FormWrapperButtonSubmit>
        </ContainerFooter>
      </Portal>

      {storeRbac.isUserGrantedTo(canDeleteUserTraces) && (
        <UserTracesConfirmDeleteModal />
      )}
    </FormWrapper>
  )
}

export default observer(ConfigurationActivityLogsForm)
