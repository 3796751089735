import { ContainerFlex } from '@app/components-legacy/Container'
import { InputSwitch } from '@app/components-legacy/Input'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { onHidingInternalTrustsChange } from './handlers'
import TrustsSwitchLabel from './TrustsSwitchLabel'

interface ITrustsSwitchProps {
  className?: string
}

const TrustsSwitch: React.FC<ITrustsSwitchProps> = props => {
  const { storeTopology } = useStores()

  if (!storeTopology.topologyEntity) {
    return null
  }

  return (
    <ContainerFlex
      name="TrutsSwitch"
      className={props.className}
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      items={[
        <TrustsSwitchLabel />,
        <InputSwitch
          labelledBy="showInternalTrusts"
          switchProps={{
            onChange: onHidingInternalTrustsChange(storeTopology),
            defaultChecked: storeTopology.isShowingInternalTrusts
          }}
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

const ObservedTrustsSwitch = observer(TrustsSwitch)

export default styled(ObservedTrustsSwitch)`
  width: 235px;
`
