import { ContainerContent } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToLDAPProviderConfiguration } from '../permissions'
import { onLoadLdapConfiguration, onUnLoadLdapConfiguration } from './handlers'
import LdapConfigurationForm from './LdapConfigurationForm'

interface IConfigurationLDAPPageProps {}

const ConfigurationLDAPPage: React.FC<IConfigurationLDAPPageProps> = () => {
  const {
    storeManagementLDAPConfiguration,
    storeAbout,
    storeManagementRelays,
    storeRbac
  } = useStores()

  return (
    <ContainerContent
      onLoad={onLoadLdapConfiguration(
        storeManagementLDAPConfiguration,
        storeManagementRelays,
        storeRbac
      )}
      onUnload={onUnLoadLdapConfiguration(storeManagementLDAPConfiguration)}
      flags={storeManagementLDAPConfiguration.storeFlags.flags}
      rbacCapabilityCheck={canAccessToLDAPProviderConfiguration(storeAbout)}
    >
      <LdapConfigurationForm />
    </ContainerContent>
  )
}

export default observer(ConfigurationLDAPPage)
