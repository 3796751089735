/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ErrorResponse, ProviderType, Tenant } from '../models/index'
import {
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  ProviderTypeFromJSON,
  ProviderTypeToJSON,
  TenantFromJSON,
  TenantToJSON
} from '../models/index'

export interface ListTenantsRequest {
  providers?: Array<ProviderType>
}

/**
 *
 */
export class TenantsApi extends runtime.BaseAPI {
  /**
   * Retrieve all tenants
   */
  async listTenantsRaw(
    requestParameters: ListTenantsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<Tenant>>> {
    const queryParameters: any = {}

    if (requestParameters['providers'] != null) {
      queryParameters['providers[]'] = requestParameters['providers']
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/core/ad/tenants`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TenantFromJSON)
    )
  }

  /**
   * Retrieve all tenants
   */
  async listTenants(
    requestParameters: ListTenantsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<Tenant>> {
    const response = await this.listTenantsRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
