import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  readLicenseFeatureIoE,
  readUIIoEDeviances
} from '@libs/rbac/permissions'

export const canSeeInputReasonsDrawer = rbacCapabilityCheckAllOf(
  readLicenseFeatureIoE(),
  readUIIoEDeviances()
)
