import { useTestAttribute } from '@app/hooks/useTestAttribute'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import type { FieldName } from '@app/stores/helpers/StoreForm/types'
import { Input } from 'antd'
import type { PasswordProps } from 'antd/lib/input/Password'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IWidgetFormInputPasswordProps {
  /** Field name */
  fieldName: FieldName
  /** Input id */
  inputId: string
  /** Props that will be passed to the rendered component */
  inputProps?: PasswordProps
  /** StoreForm to get data */
  storeForm: StoreForm<any>
}

/**
 * @deprecated
 */
const WidgetFormInputPassword: React.FC<
  IWidgetFormInputPasswordProps
> = props => {
  const { testAttributeProps } = useTestAttribute('form')

  return (
    <Input.Password
      type="text"
      id={props.inputId}
      name={props.fieldName}
      value={props.storeForm.getFieldValueAsString(props.fieldName)}
      autoComplete="new-password"
      visibilityToggle
      onChange={onInputChange(props.storeForm)(props.fieldName)}
      {...props.inputProps}
      {...testAttributeProps('textbox')(props.fieldName)}
    />
  )
}

export default observer(WidgetFormInputPassword)
