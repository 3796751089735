import { AppRouteName } from '@app/routes'
import type { StoreAuthentication } from '@app/stores'
import { FirstLoginPasswordFormFieldName } from '@app/stores/Authentication/StoreAuthentication'

/**
 * Reset password with Tenable provider.
 */
export const handleResetPasswordOnSubmit =
  (storeAuthentication: StoreAuthentication) =>
  (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) {
      e.preventDefault()
    }

    const storeForm = storeAuthentication.storeFormFirstLoginPassword

    const isValid = storeForm.validate()

    if (!isValid) {
      return
    }

    if (!storeAuthentication.resetPasswordNonce) {
      return
    }

    const newPassword = storeForm.field(
      FirstLoginPasswordFormFieldName.newPassword
    ).asString

    storeAuthentication.tryToResetPassword(newPassword)
  }

/**
 * Redirect to the login page if the resetPasswordNonce is null.
 */
export const handleResetPasswordOnLoad =
  (storeAuthentication: StoreAuthentication) => () => {
    if (!storeAuthentication.resetPasswordNonce) {
      const url =
        storeAuthentication.storeRoot.appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.HomePage,
          parameters: {}
        })

      storeAuthentication.storeRoot.appRouter.history.push(url)
    }
  }

/**
 * Redirect to the login page if the cancel button is clicked.
 */
export const handleResetPasswordOnCancel =
  (storeAuthentication: StoreAuthentication) => () => {
    const url = storeAuthentication.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.HomePage,
      parameters: {}
    })

    storeAuthentication.storeRoot.appRouter.history.push(url)
  }
