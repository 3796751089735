import { AppRouteName } from '@app/routes'
import type { StoreBlades, StoreDashboards } from '@app/stores'
import type StoreDashboard from '@app/stores/Dashboard/StoreDashboard'
import type StoreWidget from '@app/stores/Dashboard/StoreWidget'
import { explodeDashboardKey, explodeWidgetKey } from '@libs/dashboards/keys'
import type { WidgetKey } from '@libs/dashboards/types'
import { isDefined } from '@libs/isDefined'
import type { AppRouterClient } from '@libs/Router/types'
import type { EditDashboardWidgetMutationArgs } from '@server/graphql/typeDefs/types'
import type { MenuProps } from 'rc-menu/lib/Menu'
import type * as GridLayout from 'react-grid-layout'

/**
 * When moving, resizing widgets, retrieve coordinates and update the widget
 * to the API.
 */
const updateWidgetsPosition =
  (storeDashboard: StoreDashboard) => (allItems: GridLayout.Layout[]) => {
    if (!storeDashboard) {
      return
    }

    const allWidgetsLayout = storeDashboard.widgetLayouts

    allItems
      .map(item => {
        if (!item.i) {
          return
        }

        const itemIds = explodeWidgetKey(item.i)

        if (!itemIds) {
          return
        }

        return {
          i: item.i,
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          ...itemIds
        }
      })
      .filter(isDefined)
      .filter(itemRaw => {
        const widgetLayout = allWidgetsLayout.get(itemRaw.i)

        if (!widgetLayout) {
          return true
        }

        // keep items whose position has really changed
        return (
          itemRaw.x !== widgetLayout.x ||
          itemRaw.y !== widgetLayout.y ||
          itemRaw.w !== widgetLayout.w ||
          itemRaw.h !== widgetLayout.h
        )
      })
      .forEach(itemRaw => {
        const args: EditDashboardWidgetMutationArgs = {
          dashboardWidget: {
            instanceName: itemRaw.instanceName,
            dashboardId: itemRaw.dashboardId,
            id: itemRaw.dashboardWidgetId,
            posX: itemRaw.x,
            posY: itemRaw.y,
            width: itemRaw.w,
            height: itemRaw.h
          }
        }

        storeDashboard.editWidgetLayout(args)
      })
  }

/**
 * After resizing a widget, update widgets position.
 */
export const onDashboardGridResizeStop =
  (storeDashboard: StoreDashboard) => (allItems: GridLayout.Layout[]) => {
    updateWidgetsPosition(storeDashboard)(allItems)
  }

/**
 * After dragging a widget, update widgets position.
 */
export const onDashboardGridDragStop =
  (storeDashboard: StoreDashboard) => (allItems: GridLayout.Layout[]) => {
    updateWidgetsPosition(storeDashboard)(allItems)
  }

/**
 * On each layout changes, save the new layout of each widget.
 */
export const onDashboardGridLayoutChange =
  (storeDashboards: StoreDashboards) =>
  (widgetLayouts: GridLayout.Layout[]) => {
    widgetLayouts.forEach(widgetLayout => {
      const storeDashboard = storeDashboards.currentStoreDashboard

      if (!storeDashboard) {
        return
      }

      const widgetKey = widgetLayout.i
      if (!widgetKey) {
        return
      }

      storeDashboard.setWidgetLayout(widgetKey, widgetLayout)
    })
  }

/**
 * Open the blade to add a widget.
 */
export const onAddWidgetButtonClick =
  (appRouter: AppRouterClient) =>
  (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (e) {
      e.preventDefault()
    }

    const parameters = appRouter.getRouteParameters({
      routeName: AppRouteName.Dashboard_Grid,
      parameters: {
        instanceName: String(),
        dashboardId: Number()
      }
    })

    if (!parameters) {
      return
    }

    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Dashboard_Grid_WidgetAdd,
      parameters
    })

    appRouter.history.push(url)
  }

/**
 * Select a dashboard.
 */
export const onMenuEntryClick =
  (
    storeDashboards: StoreDashboards,
    storeBlades: StoreBlades,
    appRouter: AppRouterClient
  ): MenuProps['onClick'] =>
  value => {
    const dashboardKey = value.key

    const attributes = explodeDashboardKey(String(dashboardKey))

    if (!attributes) {
      return
    }

    const { instanceName, dashboardId } = attributes

    // select the dashboard
    storeDashboards
      .fetchOneDashboard(instanceName, dashboardId)
      .then(() => storeDashboards.selectDashboard(String(dashboardKey)))

    const dashboardGridUrl = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Dashboard_Grid,
      parameters: {
        instanceName,
        dashboardId
      }
    })

    storeBlades.updateLastBladeUrl(dashboardGridUrl)

    appRouter.history.push(dashboardGridUrl)
  }

/**
 * Refresh the widget.
 */
export const onRefreshIconClick =
  (storeWidget: StoreWidget) => (widgetKey: WidgetKey) => () => {
    storeWidget.fetchData(widgetKey)
  }

/**
 * Open the blade of edit the widget.
 */
export const onEditIconClick =
  (appRouter: AppRouterClient) => (widgetKey: WidgetKey) => () => {
    const widgetIds = explodeWidgetKey(widgetKey)

    if (!widgetIds) {
      return
    }

    if (
      !widgetIds.instanceName ||
      !widgetIds.dashboardId ||
      !widgetIds.dashboardWidgetId
    ) {
      return
    }

    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Dashboard_Grid_WidgetEdit,
      parameters: {
        instanceName: String(widgetIds.instanceName),
        dashboardId: widgetIds.dashboardId,
        dashboardWidgetId: widgetIds.dashboardWidgetId
      }
    })

    appRouter.history.push(url)
  }

/**
 * Open the drawer to delete a widget.
 */
export const onDeleteIconClick =
  (storeDashboard: StoreDashboard) => (widgetKey: WidgetKey) => () => {
    storeDashboard.storeWidgetsManagement.storeDrawer
      .setData({ widgetKey })
      .openDrawer()
  }
