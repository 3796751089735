import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'
import { castArray } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import { getWidthValue } from './functions'

export type TContainerFlexLegacySpaceWidth =
  | 'none'
  | 'veryVerySmall'
  | 'verySmall'
  | 'small'
  | 'smaller'
  | 'default'
  | 'medium'
  | 'large'
  | 'extraLarge'
  // Paladin
  | 'spacing-md'

export type TContainerFlexLegacySpaced = boolean | 'allChildren'

export type TContainerFlexLegacyOverflow =
  | 'visible'
  | 'hidden'
  | 'scroll'
  | 'inherit'
  | 'initial'
  | 'auto'

/* ContainerFlexLegacy */

export interface IContainerFlexLegacyProps {
  // data-name for easier readability when looking at DOM
  name: string
  // className needed for styled-component
  className?: string
  // custom styles (needed for a ContainerFlexLegacy parent that will set flex-grow in styles)
  style?: React.CSSProperties
  // if true, add the default margin between items
  spaced?: TContainerFlexLegacySpaced
  // width of the spacer
  spaceWidth?: TContainerFlexLegacySpaceWidth
  // if set, specify the padding between the content and of the bottom border
  borderPadding?: TContainerFlexLegacySpaceWidth
  // flex items (children)
  items: React.ReactNode | React.ReactNodeArray
  // if true, set height: 100%
  fullHeight?: boolean
  // if true, set min-width: 100%
  fullWidth?: boolean
  // optional onClick handler
  onClick?: () => void
}

/**
 * This component is a copy paste of ContainerFlex but for IE11... without Flex directives!
 * See https://github.com/philipwalton/flexbugs/issues/216#issuecomment-359042902
 *
 * In a few words, with a flex container, IE11 is not able to compute correctly
 * the nested flex-containers.
 *
 * So this component is only usable for "direction: column" (in fact no direction at all here)
 * in order to mimic the behavior of the ContainerFlex direction="column" but without
 * the usage of flex properties...
 *
 * It's a workaround awaiting the day where we'll stop the support of IE11.
 */
const ContainerFlexLegacy: React.FC<IContainerFlexLegacyProps> = props => {
  const renderItem = (item: React.ReactNode, i: number) => {
    if (typeof item === 'boolean') {
      return null
    }

    if (!item) {
      return null
    }

    const key = `item-${i}`
    // FIXME: use item props style to avoid overriding
    // const props = (item as JSX.Element).props
    // const style = props ? props.style : {}

    const newProps: Partial<IContainerFlexLegacyProps & { key: string }> = {
      key,
      style: {}
    }

    return React.cloneElement(item as JSX.Element, newProps)
  }

  if (!props.items) {
    return null
  }

  if (Array.isArray(props.items) && !props.items.length) {
    return null
  }

  const finalProps = filterEmptyProps({
    'data-name': name,
    style: props.style,
    className: props.className,
    onClick: props.onClick
  })

  return <div {...finalProps}>{castArray(props.items).map(renderItem)}</div>
}

export default styled(ContainerFlexLegacy)`
  /*
   * Because className can't be injected in the renderItem methods,
   * (classNames injected by others styled HoC would be overwritten)
   * the solution is to inject all styles of spaced items in a single function.
  */
  ${props => {
    if (!props.spaced) {
      return ''
    }

    return `
      > ${props.spaced === 'allChildren' ? '*' : `*:not(:last-child)`} {
        margin-bottom: ${getWidthValue(props.spaceWidth || 'default')};
      };
    `
  }}

  /* Set full height */
  ${props => {
    if (!props.fullHeight) {
      return ''
    }

    return 'height: 100%;'
  }}

  /* Set full width */
  ${props => {
    if (!props.fullWidth) {
      return ''
    }

    return 'min-width: 100%;'
  }}
`
