import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconZoomResetProps extends IIconProps {}

const IconZoomReset: React.FC<IIconZoomResetProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.24 0V1.81894H12.901L9.16211 5.54103L10.459 6.8379L14.1811 3.09896V5.76001H16V3.00872e-05L10.24 0Z"
        fill={fill}
      />
      <path
        d="M5.54103 9.16211L1.81894 12.901V10.24H0V16H5.75998V14.181H3.09893L6.83787 10.4589L5.54103 9.16211Z"
        fill={fill}
      />
      <path
        d="M14.1811 12.901L10.459 9.16211L9.16211 10.459L12.901 14.1811H10.24V16H16V10.24H14.181L14.1811 12.901Z"
        fill={fill}
      />
      <path
        d="M5.75998 1.81894V0H0V5.75998H1.81894V3.09893L5.54103 6.83787L6.8379 5.541L3.09896 1.81891L5.75998 1.81894Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconZoomReset
