import { useTestAttribute } from '@app/hooks/useTestAttribute'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { onSwitchChange } from '@app/stores/helpers/StoreForm/handlers'
import type { FieldName } from '@app/stores/helpers/StoreForm/types'
import { Switch } from 'antd'
import type { SwitchProps } from 'antd/es/switch'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IWidgetFormSwitchProps {
  /** Field name */
  fieldName: FieldName
  /** Input id */
  inputId: string
  /** Props that will be passed to the rendered component */
  inputProps?: SwitchProps
  /** StoreForm to get data */
  storeForm: StoreForm<any>
}

/**
 * @deprecated
 */
const WidgetFormSwitch: React.FC<IWidgetFormSwitchProps> = props => {
  const inputRef = React.useRef<HTMLElement>(null)
  const { testAttributeProps } = useTestAttribute('form')

  // The antd Switch cannot take an id, we set it manually
  React.useEffect(
    function setInputId() {
      if (!inputRef.current) {
        return
      }
      inputRef.current.id = props.inputId
    },
    [inputRef.current]
  )

  return (
    <Switch
      ref={inputRef}
      size="small"
      checked={
        props.storeForm.getFieldValueAsString(props.fieldName) === 'true'
      }
      onChange={onSwitchChange(props.storeForm)(props.fieldName)}
      {...props.inputProps}
      {...testAttributeProps('switch')(props.fieldName)}
    />
  )
}

export default observer(WidgetFormSwitch)
