import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditTenant } from '../permissions'
import { handleTenantEditionPageOnLoad } from './handlers'
import TenantEditForm from './TenantEditForm'

interface ITenantsEditPageProps {}

const TenantsEditPage: React.FC<ITenantsEditPageProps> = () => {
  const appRouter = useAppRouter()

  const { storeManagementTenants } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_System_Tenants_Edit,
    parameters: {
      tenantId: String()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_System_Tenants_Edit,
        parameters: {
          tenantId: parameters.tenantId
        }
      }}
      onLoad={handleTenantEditionPageOnLoad(storeManagementTenants)(
        parameters.tenantId
      )}
      flags={[
        storeManagementTenants.storeFlagsFetchTenants.flags,
        storeManagementTenants.storeFlagsFetchCredentials.flags
      ]}
      layout={{
        name: 'default',
        content: (
          <ContainerContent>
            <TenantEditForm tenantId={parameters.tenantId} />
          </ContainerContent>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={canEditTenant(parameters.tenantId)}
    />
  )
}

export default observer(TenantsEditPage)
