export interface IAttackPathCoords {
  x: number
  y: number
}

export interface IAttackPathCell {
  row: number
  column: number
  onionRingIndex?: number
  onionRingCellIndex?: number
}

export enum AttackPathSearchTabs {
  attackPath = 'attackPath',
  blastRadius = 'blastRadius',
  assetExposure = 'assetExposure'
}

export enum AttackPathVariant {
  // default
  default = 'default',
  // graph view
  graph = 'graph'
}
