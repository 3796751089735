import CardDecorationTriangle from '@app/components-legacy/Card/CardDecorationTriangle'
import * as React from 'react'

interface ILabelDevianceReasonInitialsProps {
  reasonColor: string
  reasonName: string
}

const LabelDevianceReasonInitials: React.FC<
  ILabelDevianceReasonInitialsProps
> = props => {
  const reasonInitials = props.reasonName
    .split(' ')
    .map(s => s.charAt(0))
    .join('')
    .substring(0, 2)

  return (
    <CardDecorationTriangle
      name="LabelDevianceReasonInitials"
      backgroundColor={props.reasonColor}
    >
      {reasonInitials}
    </CardDecorationTriangle>
  )
}

export default LabelDevianceReasonInitials
