import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { canAccessToTenantsInReadOnly } from '@app/pages/Management/SystemPage/Tenants/permissions'
import type { StoreInputTenants } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerContent from './DrawerContent'
import DrawerFooter from './DrawerFooter'
import DrawerHeader from './DrawerHeader'
import { useDrawerInputTenantsInit } from './hooks'

interface IDrawerInputTenantsProps {
  onSubmit?: () => void
  storeInputTenants: StoreInputTenants
}

const DrawerInputTenants: React.FC<IDrawerInputTenantsProps> = props => {
  const storePendingInputTenants = useDrawerInputTenantsInit(
    props.storeInputTenants
  )

  const translate = useAppTranslator({
    namespaces: ['Components.InputTenants']
  })

  return (
    <DrawerHorizontalContainer
      flags={storePendingInputTenants.storeFlagsFetchTenants.flags}
    >
      <BladeContentLayoutDefault
        layout={{
          name: 'default',
          title: <DrawerTitle>{translate('Tenants')}</DrawerTitle>,
          header: <DrawerHeader storeInputTenants={storePendingInputTenants} />,
          content: (
            <DrawerContent storeInputTenants={storePendingInputTenants} />
          ),
          footer: (
            <DrawerFooter
              onSubmit={props.onSubmit}
              storeInputTenants={props.storeInputTenants}
              storePendingTenants={storePendingInputTenants}
            />
          )
        }}
        rbacCapabilityCheck={canAccessToTenantsInReadOnly}
      />
    </DrawerHorizontalContainer>
  )
}

export default observer(DrawerInputTenants)
