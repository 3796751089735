import type { IIconProps } from '@app/components/Icon/types'
import { FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconEyeProps extends IIconProps {}

const IconEye: React.FC<IIconEyeProps> = props => {
  const fill = props.color ?? FontColorV2.primary
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.8891 10C7.8891 11.1385 8.812 12.0613 9.95045 12.0613C11.0889 12.0613 12.0118 11.1385 12.0118 10C12.0118 8.86155 11.0889 7.93865 9.95045 7.93865C8.812 7.93865 7.8891 8.86155 7.8891 10ZM17.9419 9.52515C16.1971 5.84969 13.5597 4 10.0241 4C6.48665 4 3.85106 5.84969 2.10628 9.52699C1.96457 9.82706 1.96457 10.1748 2.10628 10.4748C3.85106 14.1503 6.48849 16 10.0241 16C13.5615 16 16.1971 14.1503 17.9419 10.473C18.0836 10.1748 18.0836 9.82883 17.9419 9.52515ZM9.95045 13.2393C8.16149 13.2393 6.71119 11.789 6.71119 10C6.71119 8.21104 8.16149 6.76074 9.95045 6.76074C11.7394 6.76074 13.1897 8.21104 13.1897 10C13.1897 11.789 11.7394 13.2393 9.95045 13.2393Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconEye
