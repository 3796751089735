import Input from '@app/components-legacy/Form/Wrappers/Input'
import { useAppTranslator } from '@app/hooks'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ReportCommonFormFieldName } from './types'

export interface IReportInputNameProps {
  storeForm: StoreForm<any>
}

const ReportInputName: React.FC<IReportInputNameProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.ReportingCenter']
  })

  return (
    <Input
      name={ReportCommonFormFieldName.reportName}
      labelledBy="ReportInputName"
      inputProps={{
        value: props.storeForm.getFieldValueAsString(
          ReportCommonFormFieldName.reportName
        ),
        placeholder: translate('Name'),
        onChange: onInputChange(props.storeForm)(
          ReportCommonFormFieldName.reportName
        ),
        autoComplete: 'off'
      }}
    />
  )
}

export default observer(ReportInputName)
