import type { StoreInputCrontab } from '@app/stores/helpers/StoreInputCrontab'
import { InputCrontabUnit } from '@app/stores/helpers/StoreInputCrontab/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ContainerFlex } from 'tenable-design-system-alt'
import SelectHours from '../Controls/SelectHours'
import SelectMinute from '../Controls/SelectMinute'
import { handleHoursOnChange, handleMinuteOnChange } from '../handlers'

export interface IConfigurationByDayProps {
  storeInputCrontab: StoreInputCrontab
}

function ConfigurationByDay(props: IConfigurationByDayProps) {
  const hours = JSON.parse(
    props.storeInputCrontab.storeForm.getFieldValueAsString(
      InputCrontabUnit.hours
    )
  )

  const minute = JSON.parse(
    props.storeInputCrontab.storeForm.getFieldValueAsString(
      InputCrontabUnit.minute
    )
  )

  return (
    <ContainerFlex
      name="ConfigurationByDay"
      flexJustifyContent="flex-end"
      flexGap="spacingM"
    >
      <SelectHours
        hours={hours}
        onChange={handleHoursOnChange(props.storeInputCrontab)}
      />

      <SelectMinute
        minutes={minute}
        onChange={handleMinuteOnChange(props.storeInputCrontab)}
      />
    </ContainerFlex>
  )
}

export default observer(ConfigurationByDay)
