import type { IGraphQLQuery } from '@libs/graphQL/types'
import type {
  CreateReportMutationArgs,
  DeleteReportMutationArgs,
  EditReportMutationArgs,
  Mutation
} from '../typeDefs/types'

export type MutationCreateReport = IGraphQLQuery<
  CreateReportMutationArgs,
  Pick<Mutation, 'createReport'>
>

export const mutationCreateReport = `
  mutation mutationCreateReport($report: InputCreateReport!) {
    createReport(
      report: $report
    ) {
      name
      type
      checkerIds
      directoryIds
      attackTypeIds
      profileId
      recipientEmails
      format
      dataTimeframe
      recurrence
      timeZone
    }
  }
`

export type MutationEditReport = IGraphQLQuery<
  EditReportMutationArgs,
  Pick<Mutation, 'editReport'>
>

export const mutationEditReport = `
  mutation mutationEditReport($report: InputEditReport!) {
    editReport(
      report: $report
    ) {
      id
      name
      type
      checkerIds
      directoryIds
      attackTypeIds
      profileId
      recipientEmails
      format
      dataTimeframe
      recurrence
      timeZone
    }
  }
`

export type MutationDeleteReport = IGraphQLQuery<
  DeleteReportMutationArgs,
  Pick<Mutation, 'deleteReport'>
>

export const mutationDeleteReport = `
  mutation mutationDeleteReport($report: InputDeleteReport!) {
    deleteReport(
      report: $report
    )
  }
`
