import { ContainerFlex } from '@app/components/Container'
import { WidgetListPagination } from '@app/components/Widgets/WidgetList'
import ContainerContent from '@app/components-legacy/Container/ContainerContent'
import ContainerSections from '@app/components-legacy/Container/ContainerSections'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  handleHealthChecksPlatformStatusOnLoad,
  handleHealthChecksPlatformStatusOnUnload,
  handleHealthChecksPlatformStatusPaginationChange
} from './handlers'
import HealthCheckPlatformStatusFilters from './HealthCheckPlatformStatusFilters'
import HealthCheckPlatformStatusTable from './HealthCheckPlatformStatusTable'

const StyledContainerFlex = styled(ContainerFlex)`
  height: 100%;
`

const StyledHealthCheckPlatformStatusTable = styled(
  HealthCheckPlatformStatusTable
)`
  height: 100%;
`

export interface IHealthCheckPlatformStatusProps {}

function HealthCheckPlatformStatus(props: IHealthCheckPlatformStatusProps) {
  const { storeHealthCheck } = useStores()

  const { storeWidgetListHealthChecksPlatformStatus } = storeHealthCheck

  const healthChecksPlatformStatusEntities =
    storeWidgetListHealthChecksPlatformStatus.allEntitiesAsArray

  return (
    <ContainerContent
      onLoad={handleHealthChecksPlatformStatusOnLoad(storeHealthCheck)}
      onUnload={handleHealthChecksPlatformStatusOnUnload(storeHealthCheck)}
    >
      <StyledContainerFlex
        name="HealthCheckContent"
        itemsFlexGrow={[1]}
        items={[
          <ContainerSections
            contentContainerProps={{
              style: { margin: `0 -${consts.marginDefault}` }
            }}
            header={<HealthCheckPlatformStatusFilters />}
            content={
              <StyledHealthCheckPlatformStatusTable
                healthCheckEntities={healthChecksPlatformStatusEntities}
                flags={[
                  storeHealthCheck.storeInfrastructures
                    .storeFlagsFetchInfrastructures.flags,
                  storeHealthCheck.storeFlagsFetchPlatformStatus.flags
                ]}
              />
            }
            footer={
              <WidgetListPagination
                onChange={handleHealthChecksPlatformStatusPaginationChange(
                  storeHealthCheck
                )}
                storeWidgetList={
                  storeHealthCheck.storeWidgetListHealthChecksPlatformStatus
                }
              />
            }
          />
        ]}
      />
    </ContainerContent>
  )
}

export default observer(HealthCheckPlatformStatus)
