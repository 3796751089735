import { Drawer, DrawerContext } from '@app/components-legacy/Drawer'
import { useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerEditBookmark from './DrawerEditBookmark'
import DrawerEditBookmarkCategory from './DrawerEditBookmarkCategory'
import SearchBookmarks from './SearchBookmarks'

interface IDrawerSearchHistoryTimelineProps {}

const DrawerSearchBookmarks: React.FC<
  IDrawerSearchHistoryTimelineProps
> = () => {
  const { storeTrailFlow } = useStores()

  return (
    <DrawerContext.Provider
      value={{
        storeDrawer:
          storeTrailFlow.storeSearchBookmarks.storeSearchBookmarksDrawer
      }}
    >
      <Drawer>
        <SearchBookmarks />

        <DrawerEditBookmarkCategory />
        <DrawerEditBookmark />
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerSearchBookmarks)
