import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerHTMLContent } from '../Container'

export interface ILabelReasonProps {
  codename: string
  className?: string
  resolved?: boolean
  description: string
}

const LabelReason: React.FC<ILabelReasonProps> = props => {
  return (
    <div className={props.className}>
      <ContainerHTMLContent labelledBy={props.codename}>
        {props.description}
      </ContainerHTMLContent>
    </div>
  )
}

const pM = consts.paddingMedium

export default styled(LabelReason)`
  margin-left: ${consts.marginDefault};
  padding: ${pM} 0 ${pM} ${pM};
  opacity: ${props => (props.resolved === true ? 0.5 : 1)};
  border-left: 1px solid ${consts.colorBlueGray020};
  /* #2080 & #2434 Quick fix IE11 issue: full width text */
  max-width: 380px;
`
