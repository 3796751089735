/* eslint-disable max-classes-per-file */

interface ISuccessParams<T> {
  parsersHistory: string[]
  parsedValue: T
  parserName: string
  remaining: string
  result?: string
}

interface IFailureParams {
  parsersHistory: string[]
  errorMsg: string
  parserName: string
  remaining: string
  result?: string
}

export abstract class ParseResult<T> {
  abstract isSuccess(): this is ParseResultSuccess<T>

  map<U>(fn: (t: T, self: ParseResultSuccess<T>) => U): ParseResult<U> {
    if (this.isSuccess()) {
      return new ParseResultSuccess<U>({
        parsersHistory: this.parsersHistory,
        parsedValue: fn(this.parsedValue, this),
        parserName: this.parserName,
        remaining: this.remaining
      })
    }

    return this as any as ParseResultFailure<U>
  }

  flatMap<U>(
    fn: (t: T, self: ParseResultSuccess<T>) => ParseResult<U>
  ): ParseResult<U> {
    if (this.isSuccess()) {
      return fn(this.parsedValue, this)
    }

    return this as any as ParseResultFailure<U>
  }

  abstract get remaining(): string

  abstract get parsersHistory(): string[]

  abstract get parserName(): string

  abstract toJS(): object

  abstract withParserName(parserName: string): ParseResult<T>

  static success<T>(params: ISuccessParams<T>): ParseResultSuccess<T> {
    return new ParseResultSuccess<T>(params)
  }

  static failure<T>(params: IFailureParams): ParseResultFailure<T> {
    return new ParseResultFailure<T>(params)
  }
}

export class ParseResultSuccess<T> extends ParseResult<T> {
  constructor(private $params: ISuccessParams<T>) {
    super()
    $params.result = 'Success'
  }

  get parsedValue(): T {
    return this.$params.parsedValue
  }

  get parserName(): string {
    return this.$params.parserName
  }

  get parsersHistory(): string[] {
    return this.$params.parsersHistory
  }

  get remaining(): string {
    return this.$params.remaining
  }

  isSuccess(): this is ParseResultSuccess<T> {
    return true
  }

  withParserName(parserName: string): ParseResult<T> {
    return new ParseResultSuccess({ ...this.$params, parserName })
  }

  toJS() {
    return this.$params
  }
}

export class ParseResultFailure<T> extends ParseResult<any> {
  constructor(private $params: IFailureParams) {
    super()
    $params.result = 'Failure'
  }

  get parserName(): string {
    return this.$params.parserName
  }

  get parsersHistory(): string[] {
    return this.$params.parsersHistory
  }

  get remaining(): string {
    return this.$params.remaining
  }

  isSuccess(): this is ParseResultSuccess<any> {
    return false
  }

  withParserName(parserName: string): ParseResult<T> {
    return new ParseResultFailure({ ...this.$params, parserName })
  }

  toJS() {
    return this.$params
  }
}
