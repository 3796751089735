import { useStores } from '@app/hooks/useStores'
import { RbacEntityItemType } from '@libs/rbac/types'
import * as React from 'react'
import ListEntityItemsTypeUI from './ListEntityItems/ListEntityItemsTypeUI'

export interface IRoleEditEntitiesListsUIProps {
  disabled: boolean
}

const RoleEditEntitiesListsUI: React.FC<
  IRoleEditEntitiesListsUIProps
> = props => {
  const { storeManagementRbacRoles } = useStores()

  const storeWidgetList =
    storeManagementRbacRoles.storesWidgetListEntityItems.get(
      RbacEntityItemType.ui
    )

  if (!storeWidgetList) {
    return null
  }

  return (
    <ListEntityItemsTypeUI
      disabled={props.disabled}
      storeWidgetList={storeWidgetList}
    />
  )
}

export default RoleEditEntitiesListsUI
