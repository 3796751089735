import type { Maybe, MaybeUndef } from '@@types/helpers'
import { ensureArray } from '@libs/ensureArray'
import { isDefined, isNotFalsy } from '@libs/isDefined'
import { getLogger } from '@libs/logger'
import type {
  IRouteDefinition,
  IRouteInfo,
  IRouteReversed,
  IRouteSpecs,
  Routes,
  RouteUrlParameters
} from '@libs/Router/types'
import type {
  History,
  Location,
  LocationListener,
  LocationState,
  Path
} from 'history'
import { get } from 'lodash'
import type QueryString from 'qs'
import { parse as parseQs, stringify as stringifyQs } from 'qs'
import { matchPath } from 'react-router-dom'

export default class AppRouter<
  R extends string,
  RD extends IRouteDefinition<R>
> {
  public logger = getLogger('AppRouter')

  /**
   * Proxy functions to the history object.
   */
  history = {
    location: (): Maybe<Location<any>> => {
      if (!this._isHistoryDefined(this.$historyObject)) {
        return null
      }

      return this.$historyObject.location
    },

    push: (path: Path, state?: LocationState): void => {
      if (!this._isHistoryDefined(this.$historyObject)) {
        return
      }

      this.$historyObject.push(path, state)
    },

    replace: (path: Path, state?: LocationState): void => {
      if (!this._isHistoryDefined(this.$historyObject)) {
        return
      }

      this.$historyObject.replace(path, state)
    }
  }

  private $routes: Routes<RD['routeName']>
  private $reversedRoutes: Array<IRouteReversed<RD['routeName']>> = []

  private $defaultRouteParameters: Maybe<RouteUrlParameters> = null

  private $historyObject: Maybe<History> = null
  private $onHistoryChangeHandlers: Array<LocationListener<LocationState>> = []

  /**
   * Instanciate a new AppRouter from a Record type of routes.
   */
  constructor(routes: Routes<R>) {
    this.$routes = routes

    // compute reversed route to be able to retrieve the route name from a pathname
    this.$reversedRoutes = (Object.keys(routes) as Array<RD['routeName']>)
      .map(routeName => {
        const pathname = routes[routeName].pathname
        const regexpStr = pathname.replace(/:(\w+)/g, '[^/]+')
        const regexp = new RegExp(regexpStr)

        return {
          pathname,
          regexp,
          routeName
        }
      })
      // sort by endpoint length to match the longest route first
      .sort((a, b) => (a.pathname.length < b.pathname.length ? 1 : -1))
  }

  /**
   * Set the history object and binds history changes.
   */
  setHistory(history: History): this {
    this.$historyObject = history

    // each time that the history changes, save the current route name
    this.$historyObject.listen((location, action) => {
      this.$onHistoryChangeHandlers.forEach(handler => {
        handler(location, action)
      })
    })

    return this
  }

  /**
   * Bind handler to an history change event.
   */
  onHistoryChange(handler: LocationListener<LocationState>): this {
    this.$onHistoryChangeHandlers.push(handler)
    return this
  }

  /**
   * Set default route parameters used when building routes.
   */
  setRouterDefaultRouteParameters(
    defaultRouteParameters: RouteUrlParameters
  ): this {
    this.$defaultRouteParameters = defaultRouteParameters
    return this
  }

  /**
   * Return the specifications of the routeName retrieved from the
   * current history pathname.
   */
  getCurrentRouteSpecs(): Maybe<IRouteSpecs<RD['routeName']>> {
    const routeName = this.getCurrentRouteName()

    if (!routeName) {
      return null
    }

    return this.$routes[routeName]
  }

  /**
   * Return the specifications of a routeName.
   */
  getRouteSpecs(routeName: RD['routeName']): IRouteSpecs<RD['routeName']> {
    return this.$routes[routeName]
  }

  /**
   * Return the pathname of a routeName.
   */
  getRoutePathname(routeName: RD['routeName']): string {
    const routeInfo = this.$routes[routeName]

    if (!routeInfo) {
      this.logger.warn(`Route ${routeName} has not been found.`)
    }

    return routeInfo.pathname
  }

  /**
   * Return the routeName of the current pathname of the history object.
   */
  getCurrentRouteName(): Maybe<RD['routeName']> {
    if (!this._isHistoryDefined(this.$historyObject)) {
      return null
    }

    const pathname = this.$historyObject.location.pathname

    if (!pathname) {
      return null
    }

    return this.getRouteName(pathname)
  }

  /**
   * Return the routeName of the passed pathname.
   */
  getRouteName(pathname: string): Maybe<RD['routeName']> {
    for (let i = 0; i <= this.$reversedRoutes.length - 1; i++) {
      if (this.$reversedRoutes[i].regexp.test(pathname)) {
        return this.$reversedRoutes[i].routeName
      }
    }

    return null
  }

  /**
   * Return parameters of a route.
   *
   * Useful to retrieve url parameters outside the render life cycle
   * that limits `this.props.match.params` to the parameters available relative
   * to the <Route /> component.
   */
  getRouteParameters<T extends RD>(
    route: T | T[],
    historyObject?: History
  ): Maybe<T['parameters']> {
    const wantedHistoryObject = historyObject || this.$historyObject

    if (!this._isHistoryDefined(wantedHistoryObject)) {
      return null
    }

    for (const _route of ensureArray(route)) {
      const path = this.getRoutePathname(_route.routeName)
      const match = matchPath(wantedHistoryObject.location.pathname, { path })

      if (match) {
        return this._castParameters(
          _route,
          match.params as { [key: string]: string }
        )
      }
    }

    return null
  }

  /**
   * Return the query parameters of the current location.
   */
  getCurrentRouteQueryStringParameters<Q>(
    historyObject?: History
  ): MaybeUndef<Q> {
    const wantedHistoryObject = historyObject || this.$historyObject

    if (!this._isHistoryDefined(wantedHistoryObject)) {
      return
    }

    const queryString = wantedHistoryObject.location.search

    return parseQs(queryString, {
      allowDots: true,
      ignoreQueryPrefix: true
    }) as unknown as Q
  }

  /**
   * Return a IRouteInfo object from the current route.
   */
  getCurrentRouteInfo<Q>(): IRouteInfo<R, Q> {
    return {
      routeName: this.getCurrentRouteName(),
      routeParameters: null,
      queryStringParameters: this.getCurrentRouteQueryStringParameters<Q>()
    }
  }

  /**
   * Split a pathname to pathnames, from the shorter to the longuest.
   * Example:
   *
   * /profile/:profileName/management/accounts/users/create
   *
   * =>
   *
   * /profile
   * /profile/:profileName
   * /profile/:profileName/management
   * /profile/:profileName/management/accounts
   * /profile/:profileName/management/accounts/users
   * /profile/:profileName/management/accounts/users/create
   */
  splitPathname(pathname: string): string[] {
    const allPathnames = []

    let newIndex: number
    // add one more slash to catch the last one
    let subPathname = pathname + '/'

    while ((newIndex = subPathname.lastIndexOf('/'))) {
      subPathname = subPathname.slice(0, newIndex)
      allPathnames.push(subPathname)
    }

    return allPathnames.filter(isNotFalsy).reverse()
  }

  /**
   * Return the pathname from routeInfos.
   */
  makeRouteInfosPathname(
    routeDefinition: RD,
    stringifyOptions?: QueryString.IStringifyOptions
  ): string {
    const { routeName, parameters, queryStringParameters } = routeDefinition

    const pathname = this.getRoutePathname(routeName)
    const allParameters = this._getAllRouteParameters(parameters)

    // replace parameters values
    const url = this._buildUrlParams(pathname, allParameters)

    if (!queryStringParameters) {
      return url
    }

    // add querystring parameters
    const queryString = this.makeRouteQueryString(
      queryStringParameters,
      stringifyOptions
    )

    if (!queryString) {
      return url
    }

    return `${url}?${queryString}`
  }

  /**
   * Compute the querystring from the route querystring parameters.
   */
  makeRouteQueryString(
    queryStringParameters: RD['queryStringParameters'],
    stringifyOptions?: QueryString.IStringifyOptions
  ): string {
    return stringifyQs(queryStringParameters, {
      allowDots: true,
      skipNulls: true,
      arrayFormat: 'brackets',
      ...stringifyOptions
    })
  }

  /**
   * Return true if the current route name if one of `routeNames`.
   */
  isCurrentRouteNameIsOneOf(routeNames: Array<RD['routeName']>): boolean {
    const currentRouteName = this.getCurrentRouteName()

    if (!currentRouteName) {
      return false
    }

    return routeNames.includes(currentRouteName)
  }

  /**
   * Return true if `document.location.pathname` is starting by one of the passed
   * pathnames.
   *
   * Use this methods when history is not yet available.
   */
  isCurrentPathnameIsStartingByOneOf(pathnames: string[]): boolean {
    return pathnames.some(pathname =>
      document.location.pathname.startsWith(pathname)
    )
  }

  /**
   * Perform a "hard redirect" to url.
   */
  hardRedirect(
    routeDefinition: Maybe<RD | string>,
    stringifyOptions?: QueryString.IStringifyOptions
  ): void {
    if (!routeDefinition) {
      window.document.location.reload()
      return
    }

    window.document.location.href =
      typeof routeDefinition === 'string'
        ? routeDefinition
        : this.makeRouteInfosPathname(routeDefinition, stringifyOptions)
  }

  /**
   * Return the history object.
   */
  historyObject(): Maybe<History> {
    return this.$historyObject
  }

  /**
   * Log registered routes.
   */
  dumpRoutes() {
    const allRouteNames = Object.keys(this.$routes) as Array<RD['routeName']>

    allRouteNames.forEach(routeName => {
      const routeSpecs = this.$routes[routeName]
      this.logger.info(`- ${routeName}: ${routeSpecs.pathname}`)
    })
  }

  /**
   * Private
   */

  private _isHistoryDefined<T>(history: Maybe<T>): history is T {
    if (!history) {
      this.logger.error('History object is not defined')
      return false
    }

    return true
  }

  private _getAllRouteParameters(
    routeParameters?: Maybe<RouteUrlParameters>
  ): RouteUrlParameters {
    const allParameters: RouteUrlParameters = {
      ...this.$defaultRouteParameters,
      ...routeParameters
    }
    return allParameters
  }

  /**
   * Cast parameters to numbers if declared as a number and if it looks like
   * a number.
   */
  private _castParameters<T extends RD>(
    route: T,
    parameters: {
      [key: string]: string
    }
  ): T['parameters'] {
    const paramTypes = Object.entries(route.parameters).reduce(
      (acc, [param, type]) => {
        acc.set(param, typeof type === 'number' ? 'number' : 'string')
        return acc
      },
      new Map<string, 'string' | 'number'>()
    )

    const finalParams = Object.entries(parameters).reduce<T['parameters']>(
      (acc, [key, value]) => {
        if (!isDefined(value)) {
          return acc
        }

        const isDigit =
          paramTypes.get(key) === 'number' && /^[0-9]+$/.test(value)

        return {
          ...acc,
          [key]: isDigit ? Number(value) : value
        }
      },
      {}
    )

    return finalParams
  }

  /**
   * Return a pathname with parameters replaced in placeholders.
   */
  private _buildUrlParams(
    pathname: string,
    allParameters: RouteUrlParameters
  ): string {
    let url = pathname.replace(/:(\w+)/g, parameterName => {
      // remove ':' at beginning
      const replacement = get(allParameters, parameterName.slice(1))

      if (replacement === undefined) {
        this.logger.warn(
          `Missing replacement for the parameter "${parameterName}" in the endpoint "${pathname}".`
        )
        return parameterName
      }

      return String(replacement)
    })

    // trim trailing '/'
    if (url !== '/') {
      url = url.replace(/\/*$/, '')
    }

    return encodeURI(url)
  }
}
