import type { PropertiesNullable } from '@@types/helpers'
import type { ICloudStatistics, IPendoParameters } from '@libs/Pendo/types'
import type { Maybe } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityCloudStatistics
  extends EntityBase
  implements PropertiesNullable<ICloudStatistics>
{
  containerUuid: Maybe<string> = null
  userUuid: Maybe<string> = null
  siteId: Maybe<string> = null
  role: Maybe<'ADMIN' | 'BASIC'> = null
  isEnabled: Maybe<boolean> = null
  environmentType: Maybe<string> = null
  customerName: Maybe<string> = null

  constructor(data: Partial<ICloudStatistics>) {
    super()
    Object.assign(this, data)
  }

  getPendoParameters(): Maybe<IPendoParameters> {
    if (
      !this.isEnabled ||
      !this.containerUuid ||
      !this.userUuid ||
      !this.role
    ) {
      return null
    }

    return {
      isEnabled: this.isEnabled,
      tulPendoData: {
        containerUuid: this.containerUuid,
        uuid: this.userUuid,
        siteId: 't.ad',
        permissions: this.getPermissionsValue()
      }
    }
  }

  getPermissionsValue(): number {
    return this.role === 'ADMIN' ? 64 : 16
  }
}
