import { FormWrapperSelect } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks'
import type { StoreInputCrontab } from '@app/stores/helpers/StoreInputCrontab'
import { InputCrontabPeriod } from '@app/stores/helpers/StoreInputCrontab/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handlePeriodOnChange } from './handlers'

export interface IPeriodSelectorProps {
  storeInputCrontab: StoreInputCrontab
}

function PeriodSelector(props: IPeriodSelectorProps) {
  const translate = useAppTranslator({
    namespaces: ['Components.InputCrontab']
  })

  return (
    <FormWrapperSelect
      labelledBy="format"
      width="large"
      selectProps={{
        value: props.storeInputCrontab.period,
        onChange: handlePeriodOnChange(props.storeInputCrontab)
      }}
      selectOptions={[
        {
          label: translate('byYear'),
          value: InputCrontabPeriod.byYear
        },
        {
          label: translate('byMonth'),
          value: InputCrontabPeriod.byMonth
        },
        {
          label: translate('byWeek'),
          value: InputCrontabPeriod.byWeek
        },
        {
          label: translate('byDay'),
          value: InputCrontabPeriod.byDay
        }
      ]}
    />
  )
}

export default observer(PeriodSelector)
