import { Features } from '@alsid/common'
import type { StoreManagementDataCollection } from '@app/stores'

export const handleDataCollectionStatusOnLoad =
  (storeManagementDataCollection: StoreManagementDataCollection) =>
  async () => {
    storeManagementDataCollection.setPageIsActive(true)

    await storeManagementDataCollection.fetchDataCollectionStatus()
    const { dataCollectionStatusEntity } = storeManagementDataCollection

    if (dataCollectionStatusEntity?.isEnabled) {
      storeManagementDataCollection.registerDataCollectionStatusWS()
    }
  }

export const handleDataCollectionStatusOnUnload =
  (storeManagementDataCollection: StoreManagementDataCollection) => () => {
    storeManagementDataCollection.reset()
  }

export const handleDataCollectionStatusOnUpdate =
  (storeManagementDataCollection: StoreManagementDataCollection) =>
  async (isEnabled: boolean) => {
    if (!storeManagementDataCollection.dataCollectionStatusEntity) {
      return
    }

    const { storeRbac } = storeManagementDataCollection.storeRoot.stores
    const featureTenableCloudSensitiveData =
      storeRbac.isUserGrantedAccordingFeatureFlag(
        Features.TENABLE_CLOUD_SENSITIVE_DATA
      )

    const sensitiveDataEnabled: boolean = !featureTenableCloudSensitiveData
      ? false
      : isEnabled
        ? storeManagementDataCollection.dataCollectionStatusEntity.getPropertyAsBoolean(
            'sensitiveDataEnabled'
          )
        : false

    await storeManagementDataCollection.updateDataCollectionStatus({
      isEnabled,
      sensitiveDataEnabled
    })

    const { dataCollectionStatusEntity } = storeManagementDataCollection

    if (dataCollectionStatusEntity?.isEnabled) {
      storeManagementDataCollection.registerDataCollectionStatusWS()
      return
    }
    storeManagementDataCollection.unregisterDataCollectionStatusWS()
  }

export const handleDataCollectionSensitiveDataStatusOnUpdate =
  (storeManagementDataCollection: StoreManagementDataCollection) =>
  async (sensitiveDataEnabled: boolean) => {
    if (!storeManagementDataCollection.dataCollectionStatusEntity) {
      return
    }

    const { storeRbac } = storeManagementDataCollection.storeRoot.stores
    const featureTenableCloudSensitiveData =
      storeRbac.isUserGrantedAccordingFeatureFlag(
        Features.TENABLE_CLOUD_SENSITIVE_DATA
      )

    if (!featureTenableCloudSensitiveData) {
      return
    }

    const isEnabled =
      storeManagementDataCollection.dataCollectionStatusEntity.getPropertyAsBoolean(
        'isEnabled'
      )

    if (!isEnabled) {
      return
    }

    await storeManagementDataCollection.updateDataCollectionStatus({
      isEnabled,
      sensitiveDataEnabled
    })
  }
