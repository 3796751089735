import { JSONBigIntStringify } from '@libs/json/bigint'
import * as moment from 'moment'
import { thisStringRepresentsANumber, toNumber } from './functions'
import { valueParser } from './Grammar'
import type { StrOrNumbOrBool, ValueType } from './types'

export default class Value {
  static quote: string = '"'
  static allDateOperators = ['<=', '=<', '>=', '=>', '=', '<', '>']

  private $initialValue: StrOrNumbOrBool = ''

  // value computed for the final expression
  private $value: StrOrNumbOrBool = ''

  // format the value according to the type
  private $type: ValueType = 'generic'

  // optional operator like '>=', '<=', '>', '<' extract from the initial value
  private $operator: string | null = null

  /**
   * Return the operator.
   */
  get operator(): string {
    switch (this.$type) {
      case 'date':
      case 'eventId':
        return this.$operator !== null ? this.$operator : this.defaultOperator

      // no transformation
      default:
        return ''
    }
  }

  /**
   * Return the value after transformation according to the type.
   */
  get value(): StrOrNumbOrBool {
    switch (this.$type) {
      // convert the date to UTC
      case 'date':
        return this.transformForDate()

      // no transformation
      default:
        return this.$value
    }
  }

  get initialValue(): StrOrNumbOrBool {
    return this.$initialValue
  }

  get defaultOperator(): string {
    return '>='
  }

  /**
   * Set the initial value.
   */
  setInitialValue(initialValue: StrOrNumbOrBool): this {
    this.$initialValue = initialValue

    const initialValueAsString = String(initialValue)

    if (initialValueAsString === 'true') {
      this.$value = true
      return this
    }

    if (initialValueAsString === 'false') {
      this.$value = false
      return this
    }

    const parseResult = valueParser().parse(initialValueAsString)

    if (!parseResult.isSuccess()) {
      return this
    }

    const [valueOperator, value] = parseResult.parsedValue

    if (valueOperator !== undefined) {
      this.setOperator(valueOperator)
    }

    if (thisStringRepresentsANumber(value)) {
      this.$value = toNumber(value) as number
      return this
    }

    // If the value is surrounded by quote, then we strip the quotes
    if (
      value.length > 1 &&
      value.charAt(0) === Value.quote &&
      value.charAt(value.length - 1) === Value.quote
    ) {
      this.$value = value.substring(1, value.length - 1)
      return this
    }

    this.$value = value

    return this
  }

  setType(type: ValueType): this {
    this.$type = type
    return this
  }

  setOperator(valueOperator: string | null) {
    let correctOperator

    if (valueOperator === '=>') {
      correctOperator = '>='
    }

    if (valueOperator === '=<') {
      correctOperator = '<='
    }

    this.$operator = correctOperator || valueOperator || this.defaultOperator

    return this
  }

  isBoolean(): boolean {
    return typeof this.$value === 'boolean'
  }

  isString(): boolean {
    return typeof this.$value === 'string'
  }

  isNumber(): boolean {
    return typeof this.$value === 'number'
  }

  /**
   * Convert the pair key/value to its displayed string representation.
   */
  toString(): string {
    if (typeof this.$value === 'boolean') {
      return this.$value.toString()
    }

    if (typeof this.$value === 'number') {
      return this.$value.toString()
    }

    const val = this.$value // this.$type === 'date' ? this.transformForDate() : this.$value

    if (this.operator !== null) {
      return this.operator + `"${val}"`
    }

    return `"${val}"`
  }

  /**
   * Return the value transformed for an expression object.
   */
  toStringForExpression(): StrOrNumbOrBool | StrOrNumbOrBool[] {
    if (typeof this.$value === 'boolean') {
      return this.$value
    }

    if (typeof this.$value === 'number') {
      return this.$value
    }

    const val = this.$type === 'date' ? this.transformForDate() : this.$value

    if (this.hasOperator()) {
      return [this.operator, val]
    }

    return val
  }

  /**
   * Return the transformed value for a JS evaluation.
   */
  toJs(): string {
    switch (this.$type) {
      // convert the date to UTC
      case 'date':
        return JSON.stringify(this.transformForDate())

      // no transformation
      default:
        return JSONBigIntStringify(this.$value)
    }
  }

  /**
   * Return true if the value as an expression.
   */
  hasOperator(): boolean {
    return this.operator.length > 0
  }

  /**
   * Return true if the initial value has some wildcards (%).
   */
  hasWildcards(): boolean {
    if (typeof this.$initialValue !== 'string') {
      return false
    }

    return this.$initialValue.indexOf('%') !== -1
  }

  /* Private methods */

  /**
   * Format the output of the date in a UTC date format.
   */
  private transformForDate(): StrOrNumbOrBool {
    if (typeof this.$value !== 'string') {
      return this.$value
    }

    return moment(this.$value).utc().format('YYYY-MM-DDTHH:mm:ss.SSS0000[Z]')
  }
}
