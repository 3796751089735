import { InputSwitch } from '@app/components-legacy/Input'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onOnlyGrantedCheck } from './handlers'

interface ICheckboxOnlyGrantedPermissionsProps {}

const CheckboxOnlyGrantedPermissions: React.FC<
  ICheckboxOnlyGrantedPermissionsProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Roles.Permissions']
  })

  const { storeManagementRbacRoles } = useStores()

  return (
    <InputSwitch
      label={translate('Show only granted permissions')}
      labelledBy="onlyGranted"
      switchProps={{
        onChange: onOnlyGrantedCheck(storeManagementRbacRoles),
        checked: storeManagementRbacRoles.onlyGrantedCheckboxValue
      }}
    />
  )
}

export default observer(CheckboxOnlyGrantedPermissions)
