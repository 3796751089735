import { ContainerContent } from '@app/components-legacy/Container'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import InfrastructureCommonForm from '../InfrastructuresCreatePage/InfrastructureCommonForm'
import { onCreateInfrastructuresSubmit } from './handlers'

interface IInfrastructureCreateFormProps {}

const InfrastructureCreateForm: React.FC<
  IInfrastructureCreateFormProps
> = props => {
  const appRouter = useAppRouter()

  const { storeBlades, storeManagementInfrastructures } = useStores()

  return (
    <ContainerContent>
      <InfrastructureCommonForm
        storeForm={storeManagementInfrastructures.storeFormCreation}
        version="creation"
        onSubmit={onCreateInfrastructuresSubmit(
          appRouter,
          storeManagementInfrastructures
        )}
      />
    </ContainerContent>
  )
}

export default InfrastructureCreateForm
