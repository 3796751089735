import { IconDownload } from '@app/components/Icon'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { exportToCSV } from './handlers'

interface IDownloadButtonProps {
  className?: string
}

const DownloadButton: React.FC<IDownloadButtonProps> = props => {
  const { storeActivityLogs } = useStores()
  return (
    <FormWrapperButton
      className={props.className}
      labelledBy="downloadActivityLogs"
      variant={ButtonVariant.secondary}
      icon={IconDownload}
      buttonProps={{
        onClick: () => exportToCSV(storeActivityLogs),
        style: {
          justifyItems: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }}
      size={ButtonSize.small}
    />
  )
}

export default observer(DownloadButton)
