import * as React from 'react'
import SimpleBar from 'simplebar-react'
import styled from 'styled-components'

const RelativeContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const AbsoluteContainer = styled.div<{ gap?: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: ${props => props.gap ?? props.theme.tokens['margin/default']};
`

const SimpleBarContainer = styled.div`
  height: 100%;
  overflow: hidden;
`

const StyledSimpleBar = styled(SimpleBar)`
  height: 100%;
`

const NormalScrollableContainer = styled.div`
  height: 100%;
  overflow: auto;
`

interface IContainerSectionsProps {
  className?: string
  header?: React.ReactNode
  content?: React.ReactNode
  footer?: React.ReactNode
  gap?: string
  withSimpleBar?: boolean
  contentContainerProps?: React.HTMLAttributes<HTMLDivElement>
}

function ContainerSections(props: IContainerSectionsProps) {
  return (
    <RelativeContainer className={props.className}>
      <AbsoluteContainer gap={props.gap}>
        {props.header}
        {props.content &&
          (props.withSimpleBar ? (
            <SimpleBarContainer {...props.contentContainerProps}>
              <StyledSimpleBar autoHide={false}>
                {props.content}
              </StyledSimpleBar>
            </SimpleBarContainer>
          ) : (
            <NormalScrollableContainer {...props.contentContainerProps}>
              {props.content}
            </NormalScrollableContainer>
          ))}
        {props.footer}
      </AbsoluteContainer>
    </RelativeContainer>
  )
}

export default ContainerSections
