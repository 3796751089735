import { LabelSliced } from '@app/components/Labels'
import { forwardProps } from '@design-system/libs/forwardProps'
import type {
  IForwardedProps,
  ReactHTMLAttributes
} from '@design-system/libs/forwardProps/types'
import { Tag } from 'antd'
import * as React from 'react'

export interface ILabelEmailProps
  extends IForwardedProps<ReactHTMLAttributes<HTMLDivElement>> {
  children: string
}

export default function LabelEmail(props: ILabelEmailProps) {
  return (
    <Tag color="blue" {...forwardProps(props)}>
      <LabelSliced value={props.children} maxWidth={120} />
    </Tag>
  )
}
