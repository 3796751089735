import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  readCheckers,
  readDirectories,
  readLicenseFeatureIoE,
  readUIIoE
} from '@libs/rbac/permissions'

export const canAccessToDomainDetails = (directoryId: number) =>
  rbacCapabilityCheckAllOf(
    readCheckers(),
    readUIIoE(),
    readLicenseFeatureIoE(),
    readDirectories(directoryId)
  )
