import { InputInfrastructures } from '@app/components-legacy/Input'
import { LabelAlt } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { isSerieForAllDirectories } from '@app/stores/Management/StoreProfiles/helpers/isSerieForAllDirectories'
import type { TStoreProfileCheckerSerie } from '@app/stores/Management/StoreProfiles/StoreProfileCheckerSerie'
import { Tooltip } from 'antd'
import * as React from 'react'

interface IConfigurationSerieDirectoriesProps {
  storeProfileCheckerSerie: TStoreProfileCheckerSerie
}

const ConfigurationSerieDirectories: React.FC<
  IConfigurationSerieDirectoriesProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Profiles.Configuration']
  })

  if (isSerieForAllDirectories(props.storeProfileCheckerSerie.options)) {
    return (
      <LabelAlt variant={LabelAltVariant.Bolded}>
        <Tooltip
          title={translate(
            'The first configuration has to apply on all domains'
          )}
        >
          <>{translate('On all directories')}</>
        </Tooltip>
      </LabelAlt>
    )
  }

  return (
    <InputInfrastructures
      storeInfrastructures={props.storeProfileCheckerSerie.storeInfrastructures}
    />
  )
}

export default ConfigurationSerieDirectories
