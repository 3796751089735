import type { Perhaps } from '@@types/helpers'
import { ensureArray } from '@libs/ensureArray'

/**
 * Format numbers with thousands separator.
 */
export function formatNumber(n: number | string): string {
  return String(n).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

/**
 * Return rounded number to `digitNumber`.
 */
export function roundNumber(num: number, digitNumber = 2): number {
  return Number(num.toFixed(digitNumber))
}

/**
 * Cast an array of string to an array of numbers.
 */
export function toNumbers(
  arr: Perhaps<string | number | string[] | number[]>
): number[] {
  return ensureArray(arr).map(str => Number(str))
}

/**
 * Format a number to a 4 characters maximum string, using k for 1000 and
 * M for 1,000,000.
 * Ex: 1234, 12k, 12.3k, 123k, 1.2M
 */
export function formatNumberTo4CharactersMax(n: number): string {
  if (n < 10000) {
    return String(n)
  }
  if (n < 1000000) {
    return `${Math.trunc(n / 1000)}k`
  }
  if (n < 10000000) {
    return `${Number((n / 1000000).toFixed(1))}M`
  }
  return `${Math.trunc(n / 1000000)}M`
}

/**
 * Bound a value between min and max.
 */
export function boundedBetween(min: number, max: number) {
  return (value: number): number => {
    return Math.max(min, Math.min(max, value))
  }
}
