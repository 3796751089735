import { sendPageToPDFMiddleware } from '@app/components-legacy/HeadBar/helpers'
import { AppRouteName } from '@app/routes'
import type StoreDashboard from '@app/stores/Dashboard/StoreDashboard'
import { filterFalsies } from '@libs/filterFalsies'
import { sanitizeFilename } from '@libs/sanitize'
import { ExportFormat } from '../types'

export const handleExportDashboardOnSubmit = (storeDashboard: any) => () => {
  if (ExportFormat.pdf) {
    return exportDashboardToPDF(storeDashboard)
  }
}

function exportDashboardToPDF(storeDashboard: StoreDashboard) {
  const { storeRoot } = storeDashboard
  const { appRouter } = storeDashboard.storeRoot

  const exportPathnames = filterFalsies([
    appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Dashboard_PDF,
      parameters: {
        instanceName: 'default',
        dashboardId: 1600001
      }
    })
  ])

  const filename = sanitizeFilename('dashboard-sample.pdf')

  sendPageToPDFMiddleware(storeRoot, exportPathnames, filename)
}
