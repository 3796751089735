import type { IUserChanged } from '@app/entities/EntityUserLog'
import type EntityUserLog from '@app/entities/EntityUserLog'
import { ConfigurationType } from '@app/entities/EntityUserLog'
import { UserTraceLogType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import UserTraceAPICallRow from './UserLogAPICallRow'
import UserTraceConfigurationChangeRow from './UserLogConfigurationChangeRow'
import UserTraceSimpleRow from './UserLogSimpleRow'
import UserTraceUserCreatedOrUpdateRow from './UserLogUserCreatedOrUpdateRow'

interface IUserLogProps {
  log: EntityUserLog
}

const UserLog: React.FC<IUserLogProps> = props => {
  const { log: trace } = props

  switch (trace.logType) {
    case UserTraceLogType.ConfigurationChanged:
    case UserTraceLogType.InfrastructureModified: {
      const changes = trace.decodedLogAttributes as IUserChanged

      if (
        changes &&
        (changes.configurationType === ConfigurationType.userRole ||
          changes.configurationType === ConfigurationType.user)
      ) {
        return <UserTraceUserCreatedOrUpdateRow log={trace} />
      }
      return <UserTraceConfigurationChangeRow log={trace} />
    }

    case UserTraceLogType.UserCreated:
      return <UserTraceUserCreatedOrUpdateRow log={trace} />

    case UserTraceLogType.UnauthenticatedApiCall:
    case UserTraceLogType.ApiCall:
      return <UserTraceAPICallRow log={trace} />

    default:
      return <UserTraceSimpleRow log={trace} />
  }
}

export default observer(UserLog)
