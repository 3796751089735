import type { ILogger, LogLevel } from '@libs/logger'

/**
 * Utility function to print errors easily.
 */
export function logException(
  logger: ILogger,
  options?: { showTraceback?: boolean; severity?: LogLevel }
) {
  const finalOptions = {
    showTraceback: true,
    ...options
  }

  return (err: Error, customMessage: string = 'An exception has occurred') => {
    const message = `↓ ${customMessage} ↓`

    switch (finalOptions.severity) {
      case 'debug':
        logger.debug(message)
        break

      case 'info':
        logger.info(message)
        break
      case 'warn':
        logger.warn(message)
        break
      default:
        logger.error(message)
    }

    if (finalOptions.showTraceback && err instanceof Error) {
      logger.debug(err.stack)
    }
  }
}
