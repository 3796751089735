import type { IFormWrapperSelectOptionProps } from '@app/components-legacy/Form/Wrappers/Select'
import {
  createEntities,
  EntityAttackPathAccountWithTier0AttackPath,
  EntityAttackPathTier0Object
} from '@app/entities'
import EntityPagination, {
  PAGINATION_PERPAGE_DEFAULT
} from '@app/entities/EntityPagination'
import { StoreInfrastructures } from '@app/stores'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import type { IDataRowGeneric } from '@app/stores/helpers/StoreWidgetList/types'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { checkRbac } from '@libs/rbac/functions'
import type {
  QueryRbacAttackPathAccountsWithTier0AttackPath,
  QueryRbacAttackPathAllTier0Objects,
  QueryRbacAttackPathTier0Objects
} from '@server/graphql/queries/attack-path'
import {
  queryRbacAttackPathAccountsWithTier0AttackPath,
  queryRbacAttackPathAllTier0Objects,
  queryRbacAttackPathTier0Objects
} from '@server/graphql/queries/attack-path'
import type {
  AttackPathAccountWithTier0AttackPath,
  AttackPathTier0Object,
  Maybe,
  RbacAttackPathAccountsWithTier0AttackPathQueryArgs,
  RbacAttackPathAllTier0ObjectsQueryArgs,
  RbacAttackPathTier0ObjectsQueryArgs
} from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '..'
import StoreFlags from '../helpers/StoreFlags'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'

export default class StoreAttackPathTier0 extends StoreBase {
  public storeInfrastructures = new StoreInfrastructures(this.storeRoot)
  public storeInputSearchAccountsWithTier0AttackPath = new StoreInputSearch(
    this.storeRoot
  )

  /* Flags */

  public storeFlagsFetchTier0Objects = new StoreFlags(this.storeRoot)
  public storeFlagsFetchAllTier0Objects = new StoreFlags(this.storeRoot)
  public storeFlagsFetchAccountsWithTier0AttackPath = new StoreFlags(
    this.storeRoot
  )

  /* Lists */

  public storeWidgetListTier0Assets = new StoreWidgetList<
    EntityAttackPathTier0Object,
    IDataRowGeneric
  >(this.storeRoot)

  public storeWidgetListAccountsWithTier0AttackPath = new StoreWidgetList<
    EntityAttackPathAccountWithTier0AttackPath,
    IDataRowGeneric
  >(this.storeRoot)

  /* Observables */

  private $attackPathTier0Objects =
    observable.box<Maybe<EntityAttackPathTier0Object[]>>(null)

  private $attackPathAllTier0Objects =
    observable.box<Maybe<EntityAttackPathTier0Object[]>>(null)

  private $accountsWithTier0AttackPath =
    observable.box<Maybe<EntityAttackPathAccountWithTier0AttackPath[]>>(null)

  private $accountsWithTier0AttackPathAssetFilter =
    observable.box<Maybe<number>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  fetchTier0Objects(
    directoryIds: number[],
    page: number,
    perPage = PAGINATION_PERPAGE_DEFAULT,
    storeFlags = this.storeFlagsFetchTier0Objects
  ): Promise<void> {
    storeFlags.loading()
    return Promise.resolve()
      .then(() => {
        const args: RbacAttackPathTier0ObjectsQueryArgs = {
          directoryIds,
          page,
          perPage
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryRbacAttackPathTier0Objects>(
            queryRbacAttackPathTier0Objects,
            args
          )
      })
      .then(({ rbacAttackPathTier0Objects }) => {
        if (
          !checkRbac(this.storeRoot, storeFlags)(rbacAttackPathTier0Objects)
        ) {
          throw new ForbiddenAccessError()
        }

        if (!rbacAttackPathTier0Objects.node) {
          throw new Error('No data while getting tier 0 objects')
        }

        return rbacAttackPathTier0Objects.node
      })
      .then(attackPathTier0Objects => {
        storeFlags.success()

        const entities = createEntities<
          AttackPathTier0Object,
          EntityAttackPathTier0Object
        >(EntityAttackPathTier0Object, attackPathTier0Objects.node)

        this.setAttackPathTier0Objects(entities)

        const { pagination } = attackPathTier0Objects

        this.storeWidgetListTier0Assets.setPagination(
          new EntityPagination({
            page: pagination.page || null,
            perPage: pagination.perPage || null,
            totalCount: pagination.totalCount || null
          })
        )

        this.storeWidgetListTier0Assets.setEntities(entities)
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  fetchAllTier0Objects(
    directoryIds: number[],
    storeFlags = this.storeFlagsFetchAllTier0Objects
  ): Promise<void> {
    storeFlags.loading()
    return Promise.resolve()
      .then(() => {
        const args: RbacAttackPathAllTier0ObjectsQueryArgs = {
          directoryIds
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryRbacAttackPathAllTier0Objects>(
            queryRbacAttackPathAllTier0Objects,
            args
          )
      })
      .then(({ rbacAttackPathAllTier0Objects }) => {
        if (
          !checkRbac(this.storeRoot, storeFlags)(rbacAttackPathAllTier0Objects)
        ) {
          throw new ForbiddenAccessError()
        }

        if (!rbacAttackPathAllTier0Objects.node) {
          throw new Error('No data while getting all tier 0 objects')
        }

        return rbacAttackPathAllTier0Objects.node
      })
      .then(attackPathTier0Objects => {
        storeFlags.success()

        const entities = createEntities<
          AttackPathTier0Object,
          EntityAttackPathTier0Object
        >(EntityAttackPathTier0Object, attackPathTier0Objects)

        this.setAttackPathAllTier0Objects(entities)
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  fetchAccountsWithTier0AttackPath(
    accountSearchText: Maybe<string>,
    directoryIds: number[],
    tier0AssetId: Maybe<number>,
    page: number,
    perPage = PAGINATION_PERPAGE_DEFAULT,
    storeFlags = this.storeFlagsFetchAccountsWithTier0AttackPath
  ): Promise<void> {
    storeFlags.loading()
    return Promise.resolve()
      .then(() => {
        const args: RbacAttackPathAccountsWithTier0AttackPathQueryArgs = {
          accountSearchText,
          directoryIds,
          tier0AssetId,
          page,
          perPage
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryRbacAttackPathAccountsWithTier0AttackPath>(
            queryRbacAttackPathAccountsWithTier0AttackPath,
            args
          )
      })
      .then(({ rbacAttackPathAccountsWithTier0AttackPath }) => {
        if (
          !checkRbac(
            this.storeRoot,
            storeFlags
          )(rbacAttackPathAccountsWithTier0AttackPath)
        ) {
          throw new ForbiddenAccessError()
        }

        if (!rbacAttackPathAccountsWithTier0AttackPath.node) {
          throw new Error(
            'No data while getting accounts with tier 0 attack path'
          )
        }

        return rbacAttackPathAccountsWithTier0AttackPath.node
      })
      .then(accountsWithTier0AttackPath => {
        storeFlags.success()

        const entities = createEntities<
          AttackPathAccountWithTier0AttackPath,
          EntityAttackPathAccountWithTier0AttackPath
        >(
          EntityAttackPathAccountWithTier0AttackPath,
          accountsWithTier0AttackPath.node
        )

        this.setAccountsWithTier0AttackPath(entities)

        const { pagination } = accountsWithTier0AttackPath

        this.storeWidgetListAccountsWithTier0AttackPath.setPagination(
          new EntityPagination({
            page: pagination.page || null,
            perPage: pagination.perPage || null,
            totalCount: pagination.totalCount || null
          })
        )

        this.storeWidgetListAccountsWithTier0AttackPath.setEntities(entities)
      })
      .catch(handleStoreError(this.storeRoot, storeFlags))
  }

  /* Actions */

  /**
   * Reset stores.
   */
  @action
  reset() {
    this.storeInfrastructures.reset()
    this.storeFlagsFetchTier0Objects.reset()
    this.storeFlagsFetchAccountsWithTier0AttackPath.reset()
    this.$attackPathTier0Objects.set(null)
    this.$attackPathAllTier0Objects.set(null)
    this.$accountsWithTier0AttackPath.set(null)
    this.$accountsWithTier0AttackPathAssetFilter.set(null)
    this.storeInputSearchAccountsWithTier0AttackPath.reset()
  }

  /**
   * Reset stores for tier 0 assets.
   */
  @action
  resetTier0Assets() {
    this.storeInfrastructures.selectAllDirectories()
    this.storeFlagsFetchTier0Objects.reset()
    this.$attackPathTier0Objects.set(null)
  }

  /**
   * Reset stores for accounts with tier 0 attack path.
   */
  @action
  resetAccountsWithTier0AttackPath() {
    this.storeInfrastructures.selectAllDirectories()
    this.storeFlagsFetchAccountsWithTier0AttackPath.reset()
    this.$attackPathAllTier0Objects.set(null)
    this.$accountsWithTier0AttackPath.set(null)
    this.$accountsWithTier0AttackPathAssetFilter.set(null)
    this.storeInputSearchAccountsWithTier0AttackPath.reset()
  }

  @action
  setAttackPathTier0Objects(
    attackPathTier0Objects: EntityAttackPathTier0Object[]
  ): this {
    this.$attackPathTier0Objects.set(attackPathTier0Objects)
    return this
  }

  @action
  setAttackPathAllTier0Objects(
    attackPathAllTier0Objects: EntityAttackPathTier0Object[]
  ): this {
    this.$attackPathAllTier0Objects.set(attackPathAllTier0Objects)
    return this
  }

  @action
  setAccountsWithTier0AttackPath(
    accountsWithTier0AttackPath: EntityAttackPathAccountWithTier0AttackPath[]
  ): this {
    this.$accountsWithTier0AttackPath.set(accountsWithTier0AttackPath)
    return this
  }

  @action
  setAccountsWithTier0AttackPathAssetFilter(
    accountsWithTier0AttackPathAssetFilter: Maybe<number>
  ): this {
    this.$accountsWithTier0AttackPathAssetFilter.set(
      accountsWithTier0AttackPathAssetFilter
    )
    return this
  }

  /* Computed */

  @computed
  get attackPathTier0Objects(): Maybe<EntityAttackPathTier0Object[]> {
    return this.$attackPathTier0Objects.get()
  }

  @computed
  get attackPathAllTier0Objects(): Maybe<EntityAttackPathTier0Object[]> {
    return this.$attackPathAllTier0Objects.get()
  }

  @computed
  get attackPathAllTier0ObjectsSelectOptions(): IFormWrapperSelectOptionProps[] {
    if (!this.attackPathAllTier0Objects) {
      return []
    }
    return this.attackPathAllTier0Objects.map(object => ({
      labelledBy: `${object.getPropertyAsString(
        'id'
      )}${object.getPropertyAsString('name')}`,
      value: object.getPropertyAsNumber('id'),
      label: object.getPropertyAsString('name')
    }))
  }

  @computed
  get accountsWithTier0AttackPath(): Maybe<
    EntityAttackPathAccountWithTier0AttackPath[]
  > {
    return this.$accountsWithTier0AttackPath.get()
  }

  @computed
  get accountsWithTier0AttackPathAssetFilter(): Maybe<number> {
    return this.$accountsWithTier0AttackPathAssetFilter.get()
  }
}
