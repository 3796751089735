/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { Resource } from './Resource'
import {
  ResourceFromJSON,
  ResourceFromJSONTyped,
  ResourceToJSON
} from './Resource'

/**
 *
 * @export
 * @interface Recommendation
 */
export interface Recommendation {
  /**
   *
   * @type {string}
   * @memberof Recommendation
   */
  cost: RecommendationCostEnum
  /**
   *
   * @type {string}
   * @memberof Recommendation
   */
  detail: string
  /**
   *
   * @type {Array<Resource>}
   * @memberof Recommendation
   */
  resources?: Array<Resource>
}

/**
 * @export
 * @enum {string}
 */
export enum RecommendationCostEnum {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH'
}

/**
 * Check if a given object implements the Recommendation interface.
 */
export function instanceOfRecommendation(value: object): boolean {
  if (!('cost' in value)) return false
  if (!('detail' in value)) return false
  return true
}

export function RecommendationFromJSON(json: any): Recommendation {
  return RecommendationFromJSONTyped(json, false)
}

export function RecommendationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Recommendation {
  if (json == null) {
    return json
  }
  return {
    cost: json['cost'],
    detail: json['detail'],
    resources:
      json['resources'] == null
        ? undefined
        : (json['resources'] as Array<any>).map(ResourceFromJSON)
  }
}

export function RecommendationToJSON(value?: Recommendation | null): any {
  if (value == null) {
    return value
  }
  return {
    cost: value['cost'],
    detail: value['detail'],
    resources:
      value['resources'] == null
        ? undefined
        : (value['resources'] as Array<any>).map(ResourceToJSON)
  }
}
