import type { Maybe } from '@@types/helpers'
import type { DashboardKey, WidgetKey } from '@libs/dashboards/types'
import type { InstanceName } from '@libs/Environment/types'

/**
 * Create the DashboardKey.
 */
export function createDashboardKey(
  instanceName: InstanceName,
  dashboardId: number
): DashboardKey {
  return [instanceName, String(dashboardId)].join(':')
}

/**
 * Create a WidgetKey from ids.
 */
export function createWidgetKey(
  dashboardKey: DashboardKey,
  dashboadWidgetId: number
): WidgetKey {
  return [dashboardKey, String(dashboadWidgetId)].join(':')
}

/**
 * Explode a DashboardKey to retrieve attributes.
 */
export function explodeDashboardKey(
  key: DashboardKey
): { instanceName: InstanceName; dashboardId: number } | null {
  const [instanceName, dashboardId] = key.split(':')

  if (!instanceName || !dashboardId) {
    return null
  }

  return {
    instanceName,
    dashboardId: Number(dashboardId)
  }
}

/**
 * Explode a widget key to retrieve ids.
 */
export function explodeWidgetKey(key: WidgetKey): Maybe<{
  instanceName: InstanceName
  dashboardId: number
  dashboardWidgetId: number
}> {
  const [instanceName, dashboardId, dashboardWidgetId] = key.split(':')

  if (!instanceName || !dashboardId || !dashboardWidgetId) {
    return null
  }

  return {
    instanceName,
    dashboardId: Number(dashboardId),
    dashboardWidgetId: Number(dashboardWidgetId)
  }
}
