import type { Maybe } from '@@types/helpers'
import { Label, LabelBreakable } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import ContainerHTMLContentExtended from '@app/components-legacy/Container/ContainerHTMLContentExtended'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled from 'styled-components'

interface ILabelDevianceDescriptionProps {
  className?: string
  description: Maybe<string>
}

const LabelDevianceDescription: React.FC<
  ILabelDevianceDescriptionProps
> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const translate = useAppTranslator({
    namespaces: ['IoE.Details.DeviantObjects']
  })

  const getDescription = (): string | React.ReactElement => {
    if (!props.description) {
      return translate('No description is available for this deviance')
    }

    return (
      // since this issue: https://jira.eng.tenable.com/browse/AD-5539
      // we have to apply break word style to prevent huge AD object attributes to exceed
      // the width when displayed
      <LabelBreakable>
        <ContainerHTMLContentExtended inline>
          {props.description}
        </ContainerHTMLContentExtended>
      </LabelBreakable>
    )
  }

  return (
    <div data-name="LabelDevianceDescription" className={props.className}>
      {themeIsLegacy ? (
        getDescription()
      ) : (
        <Label variant={LabelVariant.p}>{getDescription()}</Label>
      )}
    </div>
  )
}

export default styled(LabelDevianceDescription)`
  // force sub blocks to be inlined to avoid new lines when having some interpolations
  // like dates blocks.
  > div {
    display: inline;
  }
`
