import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelDirectoriesTree, LabelSliced } from '@app/components-legacy/Label'
import LabelDirectoryTag from '@app/components-legacy/Label/LabelDirectoryTag'
import type { StoreInfrastructures } from '@app/stores'
import { isDefined } from '@libs/isDefined'
import type { AttackPathNode } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import { useMeasure } from 'react-use'
import NodeSearchIcon from '../../Components/NodeSearchIcon'

interface IAttackPathAutoCompleteOptionProps {
  labelledBy?: string
  attackPathNode: AttackPathNode
  storeInfrastructures: StoreInfrastructures
}

const AttackPathAutoCompleteOption: React.FC<
  IAttackPathAutoCompleteOptionProps
> = props => {
  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const { directoryId } = props.attackPathNode

  const directory =
    directoryId && props.storeInfrastructures.getDirectoryFromId(directoryId)

  const iconSize = 32

  const iconAndMarginWidth = iconSize + 10
  const marginBetweenLabelAndDirectory = 10
  const availableWidth = Math.max(
    width - iconAndMarginWidth - marginBetweenLabelAndDirectory,
    0
  )

  return (
    <div ref={ref}>
      <ContainerFlex
        name="AttackPathAutoCompleteOption"
        labelledBy={props.labelledBy}
        alignItems="center"
        direction="row"
        spaceWidth="small"
        itemsFlexGrow={[0, 1]}
        items={[
          <NodeSearchIcon
            labelledBy={`${props.labelledBy}/icon-${props.attackPathNode.adObjectType}`}
            adObjectType={props.attackPathNode.adObjectType}
            size={iconSize}
          />,

          <ContainerFlex
            name="AttackPathAutoCompleteOptionLabelTag"
            justifyContent="space-between"
            items={[
              <LabelSliced
                labelledBy={`${props.labelledBy}/name`}
                value={props.attackPathNode.name || ''}
                maxWidth={availableWidth * 0.7}
              />,

              directory && isDefined(directoryId) && directory.name && (
                <LabelDirectoryTag
                  labelledBy={`${props.labelledBy}/directory`}
                  color={directory.color}
                  label={directory.name}
                  tooltipLabel={
                    <LabelDirectoriesTree
                      storeInfrastructures={props.storeInfrastructures}
                      directoryIds={[directoryId]}
                    />
                  }
                  maxWidth={availableWidth * 0.3}
                />
              )
            ]}
          />
        ]}
        spaced
      />
    </div>
  )
}

export default AttackPathAutoCompleteOption
