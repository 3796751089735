import type { Perhaps } from '@@types/helpers'
import { isDefined } from './isDefined'

/**
 * Return true if l is a defined array with at least one item.
 */
export function hasItems<T>(l: Perhaps<T[]>): l is T[] {
  if (!isDefined(l)) {
    return false
  }

  // slice to handle array-like structure like MobX observables
  if (!Array.isArray(l.slice(0))) {
    return false
  }

  return l.length > 0
}
