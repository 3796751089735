import {
  ContainerContent,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { InputWithClipboardCopy } from '@app/components-legacy/Input'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'

export interface ILinkingKeyFormProps {}

const LinkingKeyForm: React.FC<ILinkingKeyFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.Relay']
  })

  const { storeManagementRelays } = useStores()

  const fields: IField[] = [
    {
      name: 'linkingKey',
      label: translate('Single-use linking key'),
      control: (
        <InputWithClipboardCopy
          name="linkingKey"
          value={storeManagementRelays.relayLinkingKey?.getPropertyAsString(
            'linkingKey'
          )}
          autoComplete="off"
        />
      ),
      description: translate(
        'The linking key will be asked during a Relay setup. The key will be renewed after each completed setup'
      )
    }
  ]

  return (
    <ContainerContent title={translate('Linking key')}>
      <ContainerForm fields={fields} />
    </ContainerContent>
  )
}

export default LinkingKeyForm
