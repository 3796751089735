import { ContainerContent, ContainerFlex } from '@app/components/Container'
import { IconNoData } from '@app/components-legacy/Icon'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { canAccessToIoAConfiguration } from '../permissions'
import AlertAutomaticUpdateDisabled from './AlertAutomaticUpdateDisabled'
import ConfigurationAttackTypeDomains from './ConfigurationAttackTypeDomains'
import ConfigurationAttackTypeFooter from './ConfigurationAttackTypeFooter'
import ConfigurationAttackTypeSetupTable from './ConfigurationAttackTypeSetupTable'
import ErrorMsg from './ErrorMsg'
import {
  handleAttackTypeConfigurationLoad,
  handleAttackTypeConfigurationUnload
} from './handlers'
import ModalProcedure from './ModalProcedure'

interface IConfigurationAttackTypePageProps {}

const CustomContainerFlex = styled(ContainerFlex)`
  border-bottom: 1px solid ${consts.CTAColorV2.disable};
  padding-bottom: ${consts.paddingLarge};
`

const ConfigurationAttackTypePage: React.FC<
  IConfigurationAttackTypePageProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.IoA']
  })

  const {
    storeManagementAttackTypeConfiguration,
    storeManagementApplicationSettings,
    storeManagementDirectories
  } = useStores()

  const hasDirectories =
    storeManagementAttackTypeConfiguration.storeInfrastructures.hasDirectories

  const getContent = () => {
    if (!hasDirectories) {
      return (
        <ContainerContent
          title={translate('Definition of attack scenarios')}
          fullHeight={false}
        >
          <IconNoData text={<div>{translate('No domain found')}</div>} />
        </ContainerContent>
      )
    }

    return (
      <>
        <ConfigurationAttackTypeSetupTable />

        <Portal name={PlaceHolderName.bladeFooter}>
          <ConfigurationAttackTypeFooter />
        </Portal>

        <ModalProcedure
          storeModal={
            storeManagementAttackTypeConfiguration.storeModalProcedure
          }
        />
      </>
    )
  }

  return (
    <DSThemeProvider themeName={DSThemeName.grey}>
      <ContainerContent
        onLoad={handleAttackTypeConfigurationLoad(
          storeManagementAttackTypeConfiguration,
          storeManagementApplicationSettings,
          storeManagementDirectories
        )}
        onUnload={handleAttackTypeConfigurationUnload(
          storeManagementAttackTypeConfiguration
        )}
        flags={[
          // pickers
          storeManagementAttackTypeConfiguration.storeInfrastructures
            .storeFlagsFetchInfrastructures.flags,
          storeManagementAttackTypeConfiguration.storeInputCheckersAttacks
            .storeFlagsFetchCheckers.flags,
          // settings
          storeManagementAttackTypeConfiguration
            .storeFlagsFetchAttackTypeConfiguration.flags
        ]}
        rbacCapabilityCheck={canAccessToIoAConfiguration}
      >
        <CustomContainerFlex
          name="DomainsConfigurationSetup"
          items={[
            <AlertAutomaticUpdateDisabled />,
            <ContainerFlex
              name="DomainsConfigurationSetup"
              paddingV={'small'}
              items={[<ConfigurationAttackTypeDomains />]}
              spaced
              spaceWidth="smaller"
            />,
            <ErrorMsg />
          ]}
          direction="column"
        />

        {getContent()}
      </ContainerContent>
    </DSThemeProvider>
  )
}

export default observer(ConfigurationAttackTypePage)
