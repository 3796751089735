import { Features } from '@alsid/common'
import { ContainerContent, ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { ContainerRbac } from '@app/components-legacy/Container'
import { FormWrapperSwitch } from '@app/components-legacy/Form/Wrappers'
import { useAppTranslator, useStores } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditAzureAdSupport } from '../permissions'
import ConfigurationAzureADSupportCard from './ConfigurationAzureADSupportCard'
import { handleConfigurationAzureADSupportOnChange } from './handlers'
import ModalPromptUseOfTenableCloud from './ModalPromptUseOfTenableCloud'

export interface IConfigurationAzureADSupportProps {
  className?: string
}

export function ConfigurationAzureADSupport(
  props: IConfigurationAzureADSupportProps
) {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Configuration.Navigation',
      'Management.System.Configuration.AzureADSupport'
    ]
  })

  const { storeManagementAzureAD } = useStores()

  const tenableCloudIncitationPopup = useFeatureFlag({
    featureFlagName: Features.TENABLE_CLOUD_INCITATION_POPUP
  })

  return (
    <>
      <ContainerContent
        name="ConfigurationTenableCloudPage"
        className={props.className}
      >
        <ContainerFlex
          name="card"
          direction="column"
          flexGap="default"
          items={[
            <ContainerFlex
              name="form"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              items={[
                <Label variant={LabelVariant.h2}>
                  {translate(`Activate Azure AD Support`)}
                </Label>,

                <ContainerRbac rbacCapabilityCheck={canEditAzureAdSupport}>
                  <FormWrapperSwitch
                    labelledBy="tenableCloud"
                    switchProps={{
                      checked: storeManagementAzureAD.isAzureAdSupportEnabled,
                      loading:
                        storeManagementAzureAD
                          .storeFlagsAzureADConfigurationUpdate.isLoading,
                      onChange: handleConfigurationAzureADSupportOnChange(
                        storeManagementAzureAD,
                        tenableCloudIncitationPopup
                      )
                    }}
                  />
                </ContainerRbac>
              ]}
              spaced
              spaceWidth="small"
            />,

            <ConfigurationAzureADSupportCard />
          ]}
        />
      </ContainerContent>
      <ModalPromptUseOfTenableCloud />
    </>
  )
}

export default observer(ConfigurationAzureADSupport)
