import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperCheckbox } from '@app/components-legacy/Form'
import { useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onToggleSelectAllEntitiesClick } from './ListEntityItems/handlers'
import ListEntityItemsBulkActionsSelector from './ListEntityItems/ListEntityItemsBulkActionsSelector'

interface IRolesEditBladeFooterSelectionProps {}

const RolesEditFooterSelection: React.FC<
  IRolesEditBladeFooterSelectionProps
> = props => {
  const { storeManagementRbacRoles } = useStores()

  return (
    <ContainerFlex
      name="RolesEditFooterSelection"
      alignItems="center"
      items={[
        <FormWrapperCheckbox
          labelledBy="selectAllPermissions"
          checkboxProps={{
            checked: storeManagementRbacRoles.isAllRbacEntityItemsSelected(
              storeManagementRbacRoles.storeMenu.selectedMenuKey
            ),
            indeterminate:
              storeManagementRbacRoles.isSomeRbacEntityItemsSelected(
                storeManagementRbacRoles.storeMenu.selectedMenuKey
              ) &&
              !storeManagementRbacRoles.isAllRbacEntityItemsSelected(
                storeManagementRbacRoles.storeMenu.selectedMenuKey
              ),
            onClick: onToggleSelectAllEntitiesClick(storeManagementRbacRoles)(
              storeManagementRbacRoles.storeMenu.selectedMenuKey
            )
          }}
        />,

        <ListEntityItemsBulkActionsSelector />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(RolesEditFooterSelection)
