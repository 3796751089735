import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { getWidthValue } from './functions'
import type {
  TContainerFlexSpaced,
  TContainerFlexSpaceWidth,
  TFlexWrap
} from './types'

interface IItemWrapperRawProps {
  className?: string
  style?: React.CSSProperties
  spaced?: TContainerFlexSpaced
  flexWrap?: TFlexWrap
  borderPadding?: TContainerFlexSpaceWidth
  spaceWidth?: TContainerFlexSpaceWidth
  children?: React.ReactNode
}

const ItemWrapper: React.FC<IItemWrapperRawProps> = props => {
  return (
    <div className={props.className} style={props.style}>
      {props.children}
    </div>
  )
}

export default styled(ItemWrapper)`
  margin-bottom: ${props => {
    return props.spaced && props.flexWrap === 'wrap'
      ? `${getWidthValue(props.spaceWidth || 'default')}`
      : '0px'
  }};

  ${props => {
    return props.borderPadding
      ? `
        :not(:last-child) {
          border-bottom: 1px solid ${consts.colorBlueGray025};
          padding-bottom: ${getWidthValue(props.borderPadding || 'default')};
        }
      `
      : ''
  }};
`
