import { ContainerFlex } from '@app/components-legacy/Container'
import RelationsTooltipRelation from '@app/pages/AttackPath/SceneBlade/Components/RelationsTooltipRelation'
import { consts } from '@app/styles'
import { paddingDefault, paddingSmall } from '@app/styles/consts'
import { compareStringsAlphabetically } from '@libs/compareStringsAlphabetically'
import type { Maybe } from '@server/graphql/typeDefs/types'
import { AttackPathRelationType } from '@server/graphql/typeDefs/types'
import { Tooltip } from 'antd'
import * as React from 'react'

export interface IRelationsTooltipProps {
  className?: string
  relationTypes: AttackPathRelationType[]
  controlRightRelations?: Maybe<AttackPathRelationType[]>
  children: React.ReactNode
}

const RelationsTooltip: React.FC<IRelationsTooltipProps> = props => {
  return (
    <ContainerFlex
      name="RelationsTooltip"
      items={[
        <Tooltip
          title={
            <ContainerFlex
              name="RelationsTooltip"
              direction="column"
              spaceWidth="small"
              items={props.relationTypes
                .sort(compareStringsAlphabetically)
                .map(relationType => (
                  <RelationsTooltipRelation
                    relationType={relationType}
                    controlRightRelations={
                      relationType === AttackPathRelationType.HasControlRight
                        ? props.controlRightRelations
                        : null
                    }
                  />
                ))}
              spaced
            />
          }
          color={consts.colorAttackPathSecondary}
          placement="bottomLeft"
          overlayClassName="noArrowTooltip smallPaddingTopTooltip"
          overlayInnerStyle={{
            borderRadius: consts.borderRadiusRedesign,
            userSelect: 'none',
            boxShadow: 'none',
            padding: `${paddingSmall} ${paddingDefault} ${paddingSmall} ${paddingSmall}`
          }}
          children={props.children}
        />
      ]}
    />
  )
}

export default RelationsTooltip
