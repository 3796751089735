import { AssetImg } from '@app/components/Asset/AssetImg'
import { LabelAlt } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '../Container'
import { LabelAltVariant } from '../Label/LabelAlt'

interface IIconTadaaaProps {
  className?: string
  text?: string
}

const StyledAssetImg = styled(AssetImg)`
  opacity: 0.5;
`

const IconTadaaa: React.FC<IIconTadaaaProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Components.IconTada'] })

  return (
    <ContainerFlex
      name={IconTadaaa.name}
      className={props.className}
      direction="column"
      alignItems="center"
      items={[
        <StyledAssetImg
          imageName="placeholder-tadaaa"
          fileType="svg"
          width={130}
        />,

        <LabelAlt variant={LabelAltVariant.Minor}>
          {props.text ?? translate('Well done!')}
        </LabelAlt>
      ]}
      spaced
    />
  )
}

export default styled(IconTadaaa)`
  margin-bottom: 100px;
`
