import type { PropertiesNullable } from '@@types/helpers'
import type {
  AzureAdSupportConfiguration,
  Maybe
} from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityAzureADSupportConfiguration
  extends EntityBase
  implements PropertiesNullable<AzureAdSupportConfiguration>
{
  azureADSupportEnabled: Maybe<boolean> = null

  constructor(data: Partial<AzureAdSupportConfiguration>) {
    super()
    Object.assign(this, data)
  }
}
