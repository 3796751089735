import { greyThemeTokens } from '@design-system/styles/themes/grey'
import { legacyThemeTokens } from '@design-system/styles/themes/legacy'
import { paladinThemeTokens } from '@design-system/styles/themes/paladin'
import type { DSThemeDefinition } from '@design-system/styles/themes/types'
import { DSThemeName } from '@design-system/styles/themes/types'
import { defaultTheme as defaultTokens } from './default'

/**
 * All themes definition.
 *
 * Note that those themes are already legacy as well.
 * The latest initiative about themes is to use tenable-design-system-alt library
 * and provide themes tokens.
 */
export const dsThemes: Record<DSThemeName, DSThemeDefinition> = {
  // older Alsid for AD / T.AD theming
  [DSThemeName.legacy]: legacyThemeTokens,
  // Legacy theme for secondary pages on grey backgrounds.
  [DSThemeName.grey]: greyThemeTokens,
  // Paladin theme
  [DSThemeName.paladin]: paladinThemeTokens,
  // new Exposure View theming (TODO rename "default" to "exposure")
  [DSThemeName.default]: defaultTokens
}
