import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconAssetExposureProps extends IIconProps {}

const IconAssetExposure: React.FC<IIconAssetExposureProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6184 9.07937H10.1867V11.619L6.37714 7.80952L10.1867 4L10.1867 6.53968L14.6184 6.53968C15.266 6.53968 15.266 6.53968 15.266 7.1873V8.43174C15.266 9.07936 15.266 9.07937 14.6184 9.07937ZM0 7.80952C0 6.3619 1.07936 5.26984 2.51429 5.26984C4.0254 5.26984 5.10476 6.3619 5.10476 7.80952C5.10476 9.25714 4.0127 10.3492 2.51429 10.3492C1.09206 10.3492 0 9.25714 0 7.80952Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconAssetExposure
