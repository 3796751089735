import type { Maybe } from '@@types/helpers'
import { Popover } from '@app/components/Popover'
import type { IPopoverRef } from '@app/components/Popover/Popover'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import { consts } from '@app/styles'
import { BackgroundColorV2, FontColorV2 } from '@app/styles/consts'
import { Tooltip } from 'antd'
import type { TooltipPlacement } from 'antd/lib/tooltip'
import * as React from 'react'
import SimpleBar from 'simplebar-react'
import styled from 'styled-components'
import type { IFormWrapperControl } from './types'
import { ButtonSize, ButtonVariant } from './types'

const PopoverContent = styled.div`
  width: 250px;
`

const StyledSimpleBar = styled(SimpleBar)`
  max-height: 256px;

  .simplebar-mask {
    border-radius: 3px;
  }
`

const SelectOption = styled.div<{
  selected: boolean
}>`
  height: 32px;
  background-color: ${BackgroundColorV2.white};
  color: ${props =>
    props.selected ? FontColorV2.primary : FontColorV2.secondary};
  font-weight: ${props =>
    props.selected ? consts.fontWeightBold : consts.fontWeightNormal};
  cursor: pointer;
  display: flex;
  align-items: center;

  :hover {
    color: ${FontColorV2.primary};
  }
`

const SelectOptionContent = styled.div`
  padding: 5px 12px;
`

export interface IFormWrapperButtonSelectProps extends IFormWrapperControl {
  value?: Maybe<string>
  selectOptions: Array<{
    label: string
    value: string
  }>
  icon: React.ReactNode
  placement?: TooltipPlacement
  buttonTooltipLabel?: string
  buttonTooltipPlacement?: TooltipPlacement
  onChange: (value: string) => void
}

const FormWrapperButtonSelect: React.FC<
  IFormWrapperButtonSelectProps
> = props => {
  const [popoverVisible, setPopoverVisible] = React.useState<boolean>(false)

  const popoverRef = React.useRef<IPopoverRef>(null)
  const triggerRef = React.useRef<HTMLDivElement>(null)

  const button = (
    <FormWrapperButton
      className={popoverVisible ? 'active' : ''}
      labelledBy={props.labelledBy}
      variant={ButtonVariant.colored}
      size={ButtonSize.small}
      buttonProps={{
        icon: props.icon
      }}
    />
  )

  return (
    <Popover
      ref={popoverRef}
      triggerRef={triggerRef}
      popoverProps={{
        placement: props.placement,
        trigger: 'click',
        content: (
          <PopoverContent>
            <StyledSimpleBar autoHide={false}>
              {props.selectOptions.map(option => (
                <SelectOption
                  key={option.value}
                  selected={option.value === props.value}
                  onClick={() => {
                    popoverRef.current?.hidePopover()
                    props.onChange(option.value)
                  }}
                >
                  <SelectOptionContent>{option.label}</SelectOptionContent>
                </SelectOption>
              ))}
            </StyledSimpleBar>
          </PopoverContent>
        ),
        onOpenChange: setPopoverVisible
      }}
    >
      <div ref={triggerRef}>
        {props.buttonTooltipLabel ? (
          <Tooltip
            title={props.buttonTooltipLabel}
            placement={props.buttonTooltipPlacement}
          >
            <div>{button}</div>
          </Tooltip>
        ) : (
          button
        )}
      </div>
    </Popover>
  )
}

export default FormWrapperButtonSelect
