import { LabelSliced } from '@app/components-legacy/Label'
import type { EntityEvent } from '@app/entities'
import * as React from 'react'
import TableContentBodyCell from '../TableContentBodyCell'
import CellEmpty from './CellEmpty'

export interface ICellDNProps {
  event: EntityEvent
}

const CellDN: React.FC<ICellDNProps> = props => {
  const getCellContent = () => {
    if (!props.event) {
      return <CellEmpty />
    }

    const adObject = props.event.getAdObject()

    if (!adObject) {
      return <CellEmpty />
    }

    return (
      <LabelSliced
        popoverType="none"
        value={adObject.getDNOrGlobalPath()}
        labelledBy="DN"
        maxLength={75}
      />
    )
  }

  return (
    <div className="eventDN">
      <TableContentBodyCell>{getCellContent()}</TableContentBodyCell>
    </div>
  )
}

export default CellDN
