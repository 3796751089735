import type { Maybe } from '@@types/helpers'
import { ensureArray } from '@libs/ensureArray'
export { default as EntityAbout } from './EntityAbout'
export { default as EntityAdObject } from './EntityAdObject'
export { default as EntityAlertIoA } from './EntityAlertIoA'
export { default as EntityAlertIoE } from './EntityAlertIoE'
export { default as EntityApiKey } from './EntityApiKey'
export { default as EntityApplicationConfiguration } from './EntityApplicationConfiguration'
export { default as EntityApplicationSettings } from './EntityApplicationSettings'
export { default as EntityAttackPathBaseNode } from './EntityAttackPathBaseNode'
export { default as EntityAttackPathEdge } from './EntityAttackPathEdge'
export { default as EntityAttackPathNode } from './EntityAttackPathNode'
export { default as EntityAttackPathNodeDrawer } from './EntityAttackPathNodeDrawer'
export { default as EntityAttackPathNodeExpand } from './EntityAttackPathNodeExpand'
export { default as EntityAttackPathTier0Object } from './EntityAttackPathTier0Object'
export { default as EntityAttackPathAccountWithTier0AttackPath } from './EntityAttackPathAccountWithTier0AttackPath'
export { default as EntityAttackRemoveFilter } from './EntityAttackRemoveFilter'
export { default as EntityAttacksStat } from './EntityAttacksStat'
export { default as EntityAttacksSummary } from './EntityAttacksSummary'
export { default as EntityAttacksSummaryCard } from './EntityAttacksSummaryCard'
export { default as EntityAttackTypeConfigurationEntry } from './EntityAttackTypeConfigurationEntry'
export { default as EntityAuthProvider } from './EntityAuthProvider'
export { default as EntityAzureADSupportConfiguration } from './EntityAzureADSupportConfiguration'
export { default as EntityBlade } from './EntityBlade'
export { default as EntityCategory } from './EntityCategory'
export { default as EntityChange } from './EntityChange'
export { default as EntityChangeValues } from './EntityChangeValues'
export { default as EntityCloudStatistics } from './EntityCloudStatistics'
export { default as EntityDashboardWidgetOptionsSerieStatAttackCounts } from './EntityDashboardWidgetOptionsSerieStatAttackCounts'
export { default as EntityDashboardWidgetOptionsSerieStatDataPointAttackCount } from './EntityDashboardWidgetOptionsSerieStatDataPointAttackCount'
export { default as EntityDataCollectionStatus } from './EntityDataCollectionStatus'
export { default as EntityDeviance } from './EntityDeviance'
export { default as EntityDirectory } from './EntityDirectory'
export { default as EntityEmail } from './EntityEmail'
export { default as EntityEula } from './EntityEula'
export { default as EntityEvent } from './EntityEvent'
export { default as EntityAttackType } from './EntityGenericChecker/EntityAttackType'
export { default as EntityChecker } from './EntityGenericChecker/EntityChecker'
export { default as EntityAttackTypeOption } from './EntityGenericCheckerOption/EntityAttackTypeOption'
export { default as EntityCheckerOption } from './EntityGenericCheckerOption/EntityCheckerOption'
export { default as EntityHealthCheckAttribute } from './EntityHealthCheckAttribute'
export { default as EntityHealthCheckDomainStatus } from './EntityHealthCheckDomainStatus'
export { default as EntityHealthCheckDomainStatusDetails } from './EntityHealthCheckDomainStatusDetails'
export { default as EntityHealthCheckPlatformStatus } from './EntityHealthCheckPlatformStatus'
export { default as EntityHealthCheckPlatformStatusDetails } from './EntityHealthCheckPlatformStatusDetails'
export { default as EntityIncriminatingAttribute } from './EntityIncriminatingAttribute'
export { default as EntityInfrastructure } from './EntityInfrastructure'
export { default as EntityLDAPConfiguration } from './EntityLDAPConfiguration'
export { default as EntityLicense } from './EntityLicense'
export { default as EntityLockoutPolicy } from './EntityLockoutPolicy'
export { default as EntityNotification } from './EntityNotification'
export { default as EntityObjectAttribute } from './EntityObjectAttribute'
export { default as EntityPagination } from './EntityPagination'
export { default as EntityPlugin } from './EntityPlugin'
export { default as EntityPreferences } from './EntityPreferences'
export { default as EntityProductAssociation } from './EntityProductAssociation'
export { default as EntityProfile } from './EntityProfile'
export { default as EntityRbacEntity } from './EntityRbacEntity'
export { default as EntityRbacEntityItem } from './EntityRbacEntityItem'
export { default as EntityRbacPermission } from './EntityRbacPermission'
export { default as EntityRbacRole } from './EntityRbacRole'
export { default as EntityReason } from './EntityReason'
export { default as EntityRecommendation } from './EntityRecommendation'
export { default as EntityReport } from './EntityReport'
export { default as EntityResource } from './EntityResource'
export { default as EntitySAMLConfiguration } from './EntitySAMLConfiguration'
export { default as EntitySearchBookmarkEntry } from './EntitySearchBookmarkEntry'
export { default as EntitySearchHistoryEntry } from './EntitySearchHistoryEntry'
export { default as EntitySyslog } from './EntitySyslog'
export { default as EntityTenableCloudApiToken } from './EntityTenableCloudApiToken'
export { default as EntityTopology } from './EntityTopology'
export { default as EntityTopologyDirectory } from './EntityTopologyDirectory'
export { default as EntityTopologyInfrastructure } from './EntityTopologyInfrastructure'
export { default as EntityTopologyTrust } from './EntityTopologyTrust'
export { default as EntityUser } from './EntityUser'
export { default as EntityUserLog } from './EntityUserLog'
export { default as EntityWhoAmI } from './EntityWhoAmI'
export { default as EntityHealthCheckName } from './EntityHealthChecksTemplatesName'

/**
 * Instanciate 'real entities' from litteral objects
 * having the same object shape.
 */
export function createEntities<TRaw, TEntity, TParentEntity = any>(
  EntityClass: any,
  rawObjects: Maybe<TRaw[]>,
  parentEntity?: TParentEntity
): TEntity[] {
  return ensureArray(rawObjects).map(rawObject => {
    return new EntityClass(rawObject, parentEntity)
  })
}

export function createEntity<TRaw, TEntity, TParentEntity = any>(
  EntityClass: any,
  rawObject: TRaw,
  parentEntity?: TParentEntity
): TEntity {
  return new EntityClass(rawObject, parentEntity)
}
