import { LabelAlert } from '@app/components-legacy/Label'
import { LabelAlertSize } from '@app/components-legacy/Label/LabelAlert/types'
import { EntityAlertIoA } from '@app/entities'
import { useAppTranslator, useStores } from '@app/hooks'
import type StoreGenericAlerts from '@app/stores/StoreAlerts/StoreGenericAlerts'
import { ensureArray } from '@libs/ensureArray'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  onAlertSeeAttacksClick,
  onAlertSelection,
  onAlertUnselection
} from './handlers'

interface IAlertsContentItemIoAProps {
  alert: EntityAlertIoA
  storeGenericAlerts: StoreGenericAlerts<any>
}

const AlertsContentItemIoA: React.FC<IAlertsContentItemIoAProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Generics', 'Drawers.Alerts']
  })

  const { storeCheckers, storeAlerts, storeInfrastructures } = useStores()

  if (!(props.alert instanceof EntityAlertIoA)) {
    return null
  }

  if (!props.alert.attackTypeId) {
    return null
  }

  const attackType = props.alert.getAttackType(
    storeCheckers.storeInputCheckersAttacks.checkersById
  )

  if (!attackType) {
    return null
  }

  const directories = ensureArray(
    props.alert.getDirectory(storeInfrastructures.directories)
  )

  const isChecked = props.storeGenericAlerts.storeWidgetList.isSelected(
    props.alert.asDataRow()
  )

  return (
    <LabelAlert
      key={props.alert.getPropertyAsString('id')}
      checkerType={CheckerType.Attack}
      id={props.alert.getPropertyAsNumber('id')}
      size={LabelAlertSize.fullWidth}
      label={attackType.getPropertyAsString('name') || translate('Unknown')}
      date={props.alert.date}
      criticity={attackType.genericCriticity}
      directories={directories}
      checked={isChecked}
      read={props.alert.read === true}
      archived={props.alert.archived === true}
      isCheckable
      actions={[
        {
          label: translate('Get more information'),
          key: 'seeAttacks'
        }
      ]}
      onAction={onAlertSeeAttacksClick(storeAlerts)(props.alert)}
      onSelections={{
        onSelection: onAlertSelection(props.storeGenericAlerts)(
          props.alert.getPropertyAsNumber('id')
        ),
        onUnselection: onAlertUnselection(props.storeGenericAlerts)(
          props.alert.getPropertyAsNumber('id')
        )
      }}
      storeInfrastructures={storeInfrastructures}
      storeGenericAlerts={props.storeGenericAlerts}
    />
  )
}

export default observer(AlertsContentItemIoA)
