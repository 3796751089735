import { EntityAttackPathNodeDrawer, EntityPagination } from '@app/entities'
import type EntityAttackPathNode from '@app/entities/EntityAttackPathNode'
import type { StoreAttackPath } from '@app/stores'
import type { Maybe } from '@server/graphql/typeDefs/types'
import { AttackPathDirection } from '@server/graphql/typeDefs/types'

export const handleDrawerNodeRelationshipsPaginationChange =
  (storeAttackPath: StoreAttackPath) =>
  (page: number): void => {
    const { rowsPerPage: perPage, paginationTotalCount: totalCount } =
      storeAttackPath.storeWidgetListDrawerNodeRelationships

    storeAttackPath.storeWidgetListDrawerNodeRelationships.setPagination(
      new EntityPagination({
        page,
        perPage,
        totalCount
      })
    )
  }

export const handleDrawerNodeRelationshipNameFilterChange =
  (storeAttackPath: StoreAttackPath) => () => {
    const { rowsPerPage: perPage } =
      storeAttackPath.storeWidgetListDrawerNodeRelationships

    storeAttackPath.storeWidgetListDrawerNodeRelationships.setPagination(
      new EntityPagination({
        page: 0,
        perPage,
        totalCount: storeAttackPath.filteredDrawerChildrenNodesWithEdge.length
      })
    )
  }

export const handleDrawerNodeRelationshipAdObjectTypeFilterChange =
  (storeAttackPath: StoreAttackPath) => (values: string[]) => {
    const { rowsPerPage: perPage } =
      storeAttackPath.storeWidgetListDrawerNodeRelationships

    storeAttackPath.setDrawerAdObjectTypeFilter(values)

    storeAttackPath.storeWidgetListDrawerNodeRelationships.setPagination(
      new EntityPagination({
        page: 0,
        perPage,
        totalCount: storeAttackPath.filteredDrawerChildrenNodesWithEdge.length
      })
    )
  }

export const handleDrawerNodeRelationshipSelectionChange =
  (storeAttackPath: StoreAttackPath, node: EntityAttackPathNode) =>
  (checked: boolean) => {
    if (checked) {
      storeAttackPath.storeWidgetListDrawerNodeRelationships.selectRow(node)
      return
    }
    storeAttackPath.storeWidgetListDrawerNodeRelationships.unselectRow(node, [])
  }

export const handleDrawerNodeRelationshipSelectionSubmit =
  (storeAttackPath: StoreAttackPath) => (currentId: Maybe<string>) => () => {
    if (!currentId) {
      return
    }

    const currentEntityAttackPathNode =
      storeAttackPath.linkedNodes.get(currentId)

    if (!currentEntityAttackPathNode) {
      return
    }

    const isDirectionFrom =
      storeAttackPath.direction === AttackPathDirection.From

    const selectedNodes =
      storeAttackPath.storeWidgetListDrawerNodeRelationships.selectedRowsAsArray

    const edgesRelated = storeAttackPath.linkedEdges.filter(edge =>
      isDirectionFrom
        ? edge.sourceUid === currentId
        : edge.targetUid === currentId
    )

    // Start
    if (storeAttackPath.expandedNodeUids.length === 0) {
      storeAttackPath.setExpandedNodeUids([currentId])
    } else if (!currentEntityAttackPathNode.isPartOfExpansion) {
      const nodes = storeAttackPath.nodes
        .filter(node => !node.isPartOfExpansion)
        .map(node => {
          node.isSourceOfExpansion = false

          if (node instanceof EntityAttackPathNodeDrawer && node.isExpanded) {
            storeAttackPath.moveBackDrawerNode(node)
          }

          return node
        })

      storeAttackPath.setNodes(nodes)

      storeAttackPath.setEdges(
        storeAttackPath.edges.filter(edge => !edge.isPartOfExpansion)
      )

      storeAttackPath.setExpandedNodeUids([currentId])
    }

    // same tree but different branch
    if (
      currentEntityAttackPathNode.linkedNodeEntity &&
      storeAttackPath.expandedNodeUids.includes(
        currentEntityAttackPathNode.linkedNodeEntity?.uid
      ) &&
      storeAttackPath.expandedNodeUids.indexOf(
        currentEntityAttackPathNode.linkedNodeEntity?.uid
      ) !==
        storeAttackPath.expandedNodeUids.length - 1
    ) {
      const indexFound = storeAttackPath.expandedNodeUids.indexOf(
        currentEntityAttackPathNode.linkedNodeEntity?.uid
      )

      storeAttackPath.removeExpandedNodeUids(
        storeAttackPath.expandedNodeUids[indexFound + 1]
      )
    }

    // same tree, same branch
    if (
      currentEntityAttackPathNode.linkedNodeEntity &&
      storeAttackPath.expandedNodeUids.includes(
        currentEntityAttackPathNode.linkedNodeEntity?.uid
      ) &&
      storeAttackPath.expandedNodeUids.indexOf(
        currentEntityAttackPathNode.linkedNodeEntity?.uid
      ) ===
        storeAttackPath.expandedNodeUids.length - 1
    ) {
      storeAttackPath.setExpandedNodeUids(
        storeAttackPath.expandedNodeUids.concat(currentId)
      )
    }

    storeAttackPath.displayExpandNodesFromDrawer(
      currentEntityAttackPathNode,
      selectedNodes,
      edgesRelated
    )

    storeAttackPath.storeDrawer.closeDrawer()
  }
