import { Features } from '@alsid/common'
import { ContainerContent } from '@app/components-legacy/Container'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import WidgetFormSelect from '@app/components-legacy/Widgets/WidgetForm/WidgetFormSelect'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { useStores } from '@app/hooks/useStores'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ProtocolInputName, SyslogFormFieldName } from './types'

export interface ISyslogCommonFormMainInformationProps {}

const SyslogCommonFormMainFields: React.FC<
  ISyslogCommonFormMainInformationProps
> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Titles',
      'Management.System.Configuration.SyslogAlerts',
      'Management.System.Directories'
    ]
  })

  const { storeManagementSyslogs, storeManagementRelays } = useStores()

  const featureAlertingThroughRelay = useFeatureFlag({
    featureFlagName: Features.ALERTING_THROUGH_RELAY
  })

  const { storeRoot } = useStores()
  const featureSecuredRelayOnCustomerSide =
    storeRoot.environment.config.app.securerelay.customerside

  // TLS is not available for UDP
  const selectedProtocol =
    storeManagementSyslogs.storeForm.getFieldValueAsString<ProtocolInputName>(
      SyslogFormFieldName.protocol
    )

  const hiddenFields =
    selectedProtocol === ProtocolInputName.UDP ? [SyslogFormFieldName.tls] : []

  const displayedFields = [
    SyslogFormFieldName.ip,
    SyslogFormFieldName.port,
    SyslogFormFieldName.protocol,
    SyslogFormFieldName.tls,
    SyslogFormFieldName.description
  ]

  if (featureAlertingThroughRelay && featureSecuredRelayOnCustomerSide) {
    displayedFields.unshift(SyslogFormFieldName.relayId)
  }

  return (
    <ContainerContent title={translate('Main information')}>
      <WidgetForm<SyslogFormFieldName>
        translate={translate}
        storeForm={storeManagementSyslogs.storeForm}
        displayedFields={displayedFields}
        hiddenFields={hiddenFields}
        fieldsParams={{
          [SyslogFormFieldName.ip]: {
            inputType: InputType.input,
            inputProps: {
              placeholder: 'syslog-server.com'
            }
          },
          [SyslogFormFieldName.port]: {
            inputType: InputType.input,
            inputProps: {
              placeholder: '514'
            }
          },
          [SyslogFormFieldName.protocol]: {
            inputType: InputType.select,
            inputProps: {
              labelledBy: 'protocol',
              selectProps: {},
              selectOptions: [
                {
                  value: ProtocolInputName.UDP,
                  label: ProtocolInputName.UDP
                },
                {
                  value: ProtocolInputName.TCP,
                  label: ProtocolInputName.TCP
                }
              ]
            }
          }
        }}
        renderFields={{
          [SyslogFormFieldName.relayId]: (storeForm, field, inputId) => {
            return (
              <WidgetFormSelect
                fieldName={field.name}
                inputId={inputId}
                inputProps={{
                  labelledBy: 'relay',
                  selectOptions: Array.from(
                    storeManagementRelays.relays.values()
                  ).map(relay => ({
                    value: relay.getPropertyAsString('id'),
                    label: relay.getPropertyAsString('name')
                  }))
                }}
                storeForm={storeForm}
              />
            )
          }
        }}
      />
    </ContainerContent>
  )
}

export default observer(SyslogCommonFormMainFields)
