import type { IGraphQLQuery } from '@libs/graphQL/types'
import type { Query } from '../typeDefs/types'

/**
 * Retrieve the ReportAccessToken.
 */

export type QueryReportAccessToken = IGraphQLQuery<
  null,
  Pick<Query, 'rbacReportAccessToken'>
>

export const queryRbacReportAccessToken = `
 query queryRbacReportAccessToken {
    rbacReportAccessToken {
      node {
        reportAccessToken
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
   }
 }
`
