import type { Maybe } from '@@types/helpers'
import { createEntities, createEntity } from '@app/entities'
import type { IDataRowRelay } from '@app/entities/EntityRelay'
import EntityRelay from '@app/entities/EntityRelay'
import EntityRelayLinkingKey from '@app/entities/EntityRelayLinkingKey'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreBase from '@app/stores/StoreBase'
import type { BadRequestError } from '@libs/errors'
import { ForbiddenAccessError } from '@libs/errors'
import { isErrorOfType } from '@libs/errors/functions'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { ErrorName } from '@libs/errors/types'
import { checkRbac } from '@libs/rbac/functions'
import { isDefined } from '@productive-codebases/toolbox'
import type {
  MutationDeleteRelay,
  MutationEditRelay,
  MutationRenewRelayApiKeys
} from '@server/graphql/mutations/relay'
import {
  mutationDeleteRelay,
  mutationEditRelay,
  mutationRenewRelayApiKey
} from '@server/graphql/mutations/relay'
import type {
  QueryRbacRelayLinkingKey,
  QueryRbacRelays
} from '@server/graphql/queries/relay'
import {
  queryRbacRelayLinkingKey,
  queryRbacRelays
} from '@server/graphql/queries/relay'
import type {
  DeleteRelayMutationArgs,
  EditRelayMutationArgs,
  InputEditRelay,
  Relay,
  RelayLinkingKey,
  RenewRelayApiKeyMutationArgs
} from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '..'
import StoreDrawer from '../helpers/StoreDrawer'
import StoreForm from '../helpers/StoreForm'
import { mandatory } from '../helpers/StoreForm/validators'
import { StoreInputSearch } from '../helpers/StoreInputSearch'
import StoreModal from '../helpers/StoreModal'
import StoreWidgetList from '../helpers/StoreWidgetList'
import type { IStoreOptions } from '../types'

export enum RelaysFormFieldName {
  name = 'name'
}

export default class StoreRelays extends StoreBase {
  [x: string]: any

  public storeWidgetList = new StoreWidgetList<EntityRelay, IDataRowRelay>(
    this.storeRoot,
    {
      selectable: false,
      buildDataSetFn: relays => {
        if (!relays.length) {
          return {
            columns: [],
            rows: []
          }
        }

        const columns = relays[0].getColumns()
        const rows = relays
          .filter(relay => {
            return this.storeInputSearch.transformedSearchValueAsRegexp.test(
              relay.name ?? ''
            )
          })
          .map(entity => entity.asDataRow())
          .filter(isDefined)

        return { columns, rows }
      }
    }
  )

  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Errors', 'Management.System.Relays']
  })

  public storeDeleteDrawer = new StoreDrawer<{
    relayDataRow: IDataRowRelay
  }>(this.storeRoot)

  public storeFlagsRelayLinkingKeyFetch = new StoreFlags(this.storeRoot)
  public storeFlagsRelaysFetch = new StoreFlags(this.storeRoot)
  public storeFlagsRelaysRenewApiKey = new StoreFlags(this.storeRoot)

  public storeEditionFlags = new StoreFlags(this.storeRoot)
  public storeDeletionFlags = new StoreFlags(this.storeRoot)

  public storeFormEdition = new StoreForm<RelaysFormFieldName>(this.storeRoot, {
    setup: {
      fields: {
        [RelaysFormFieldName.name]: {
          label: 'Name',
          description: 'Name of the relay',
          validators: [mandatory()]
        }
      }
    }
  })

  /* Modals */

  public storeModalConfirmRenewApiKey = new StoreModal<{
    bulk: boolean
    relayId?: number
  }>(this.storeRoot)

  private $relayLinkingKey = observable.box<Maybe<EntityRelayLinkingKey>>(null)
  private $relays = observable.map<number, EntityRelay>()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch relay's linking key and save results into an entity.
   */
  fetchRelayLinkingKey() {
    this.storeFlagsRelayLinkingKeyFetch.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryRbacRelayLinkingKey>(queryRbacRelayLinkingKey)
      })
      .then(data => data.rbacRelayLinkingKey)
      .then(linkingKey => {
        if (
          !checkRbac(
            this.storeRoot,
            this.storeFlagsRelayLinkingKeyFetch
          )(linkingKey)
        ) {
          throw new ForbiddenAccessError()
        }

        const relayLinkingKey = createEntity<
          RelayLinkingKey,
          EntityRelayLinkingKey
        >(EntityRelayLinkingKey, linkingKey.node)

        this.setRelayLinkingKey(relayLinkingKey)

        this.storeFlagsRelayLinkingKeyFetch.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlagsRelayLinkingKeyFetch)
      )
  }

  /**
   * Fetch relays save results into an entity.
   */
  fetchRelays() {
    this.storeFlagsRelaysFetch.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryRbacRelays>(queryRbacRelays)
      })
      .then(data => data.rbacRelays)
      .then(rbacRelays => {
        if (
          !checkRbac(this.storeRoot, this.storeFlagsRelaysFetch)(rbacRelays)
        ) {
          throw new ForbiddenAccessError()
        }

        const relayEntities = createEntities<Relay, EntityRelay>(
          EntityRelay,
          rbacRelays.node
        )

        this.setRelays(relayEntities)
        this.storeWidgetList.setEntities(relayEntities)

        this.storeFlagsRelaysFetch.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsRelaysFetch))
  }

  /**
   * Edit a relay.
   */
  editRelay(relay: InputEditRelay) {
    this.storeEditionFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: EditRelayMutationArgs = {
          relay
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationEditRelay>(mutationEditRelay, args)
      })
      .then(() => {
        // reload relays
        return this.fetchRelays()
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Relay X updated', {
            interpolations: {
              relayName: relay.name
            }
          }),
          {
            labelledBy: 'relayUpdated'
          }
        )

        this.storeEditionFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeEditionFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when editing the relay'
        })
      )
  }

  /**
   * Delete a relay.
   */
  deleteRelay(relayId: number, relayName: string) {
    this.storeDeletionFlags.loading()

    return Promise.resolve()
      .then(() => {
        const args: DeleteRelayMutationArgs = {
          relay: {
            id: relayId
          }
        }

        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<MutationDeleteRelay>(mutationDeleteRelay, args)
      })
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Relay X deleted', {
            interpolations: {
              relayName
            }
          }),
          {
            labelledBy: 'relayDeleted'
          }
        )

        this.storeDeletionFlags.success()
      })
      .catch(error => {
        if (isErrorOfType<BadRequestError>(error, ErrorName.BadRequestError)) {
          return handleStoreError(this.storeRoot, this.storeDeletionFlags, {
            errorMessageTranslationFn: _ =>
              this.translate(
                'Could not delete relay because an alerting or a directory is linked to it'
              )
          })
        }

        return handleStoreError(this.storeRoot, this.storeDeletionFlags, {
          forwardExceptionFn: () =>
            'An error has occurred when deleting the relay'
        })(error)
      })
  }

  /**
   * Renew all the select relays api keys.
   */
  renewSelectedRelaysApiKeys(): Promise<any> {
    this.storeFlagsRelaysRenewApiKey.loading()

    return Promise.all(
      this.storeWidgetList.selectedRowsAsArray.map(relay => {
        return this.renewRelayApiKey(relay.id)
      })
    )
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Renewed API key for all selected Relays'),
          {
            labelledBy: 'selectedRelaysApiKeysRenewed'
          }
        )

        this.storeFlagsRelaysRenewApiKey.success()
      })
      .catch(error => {
        return handleStoreError(
          this.storeRoot,
          this.storeFlagsRelaysRenewApiKey,
          {
            forwardExceptionFn: () =>
              'An error has occurred when renewing the relay API token'
          }
        )(error)
      })
  }

  /**
   * Renew a single relay api key.
   */
  renewSingleRelayApiKey(relayId: number): Promise<any> {
    this.storeFlagsRelaysRenewApiKey.loading()

    return this.renewRelayApiKey(relayId)
      .then(() => {
        this.storeRoot.stores.storeMessages.success(
          this.translate('Renewed API key'),
          {
            labelledBy: 'relaysApiKeyRenewed'
          }
        )

        this.storeFlagsRelaysRenewApiKey.success()
      })
      .catch(error => {
        return handleStoreError(
          this.storeRoot,
          this.storeFlagsRelaysRenewApiKey,
          {
            forwardExceptionFn: () =>
              'An error has occurred when renewing a relay API token'
          }
        )(error)
      })
  }

  /**
   * Renew a relay api key.
   */
  renewRelayApiKey(relayId: number): Promise<any> {
    return Promise.resolve().then(() => {
      const args: RenewRelayApiKeyMutationArgs = {
        relay: {
          id: relayId
        }
      }

      return this.storeRoot
        .getGQLRequestor()
        .makeQuery<MutationRenewRelayApiKeys>(mutationRenewRelayApiKey, args)
    })
  }

  getRelaysFromSearch(name: number): Maybe<EntityRelay> {
    return this.$relays.get(name) ?? null
  }

  getRelayFromId(id: number): Maybe<EntityRelay> {
    return this.$relays.get(id) ?? null
  }

  isDeletable(relayId: number): boolean {
    const relay = this.getRelayFromId(relayId)
    const directoryCount = relay?.directoryIds?.length ?? 0

    return Boolean(relay && directoryCount === 0 && relay.alertCount === 0)
  }

  /* Action */

  @action
  reset(): this {
    this.storeFlagsRelayLinkingKeyFetch.reset()

    this.storeFlagsRelaysFetch.reset()

    this.storeWidgetList.reset()

    this.storeFlagsRelaysRenewApiKey.reset()

    return this
  }

  @action
  setRelayLinkingKey(relayLinkingKeyEntity: EntityRelayLinkingKey): this {
    this.$relayLinkingKey.set(relayLinkingKeyEntity)
    return this
  }

  @action
  setRelays(relaysEntities: EntityRelay[]): this {
    this.$relays.clear()

    relaysEntities.forEach(entity => {
      if (entity.id) {
        this.$relays.set(entity.id, entity)
      }
    })

    return this
  }

  @computed
  get relayLinkingKey(): Maybe<EntityRelayLinkingKey> {
    return this.$relayLinkingKey.get()
  }

  @computed
  get relays(): Map<number, EntityRelay> {
    return this.$relays
  }
}
