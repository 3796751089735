import BladeContentLayoutDefault from '@app/components-legacy/Blade/BladeContent/BladeContentLayoutDefault'
import { DrawerContext } from '@app/components-legacy/Drawer/context'
import Drawer from '@app/components-legacy/Drawer/Drawer'
import DrawerHorizontalContainer from '@app/components-legacy/Drawer/DrawerHorizontalContainer'
import DrawerTitle from '@app/components-legacy/Drawer/DrawerTitle'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useStores } from '@app/hooks/useStores'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToActivityLogs } from '../../permissions'
import DrawerActivityLogPayloadContent from './DrawerActivityLogPayload'

interface IDrawerPayloadProps {}

const DrawerPayload: React.FC<IDrawerPayloadProps> = props => {
  const { storeActivityLogs } = useStores()

  const { storeActivityLogPayload } = storeActivityLogs

  return (
    <DSThemeProvider themeName={DSThemeName.default}>
      <DrawerContext.Provider
        value={{
          storeDrawer: storeActivityLogPayload.storeDrawerPayload
        }}
      >
        <Drawer>
          <DrawerHorizontalContainer>
            <BladeContentLayoutDefault
              layout={{
                name: 'default',
                title: <DrawerTitle>{'Log details'}</DrawerTitle>,
                content: <DrawerActivityLogPayloadContent />,
                footer: (
                  <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
                )
              }}
              rbacCapabilityCheck={canAccessToActivityLogs}
              paddingV="default"
            />
          </DrawerHorizontalContainer>
        </Drawer>
      </DrawerContext.Provider>
    </DSThemeProvider>
  )
}

export default observer(DrawerPayload)
