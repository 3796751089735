import type { PropertiesNullable } from '@@types/helpers'
import type {
  AuthConfigurationAllowedGroups,
  Maybe,
  SamlConfiguration
} from '@server/graphql/typeDefs/types'

export default class EntitySAMLConfiguration
  implements PropertiesNullable<SamlConfiguration>
{
  enabled: Maybe<boolean> = null
  providerLoginUrl: Maybe<string> = null
  encryptionCertificate: Maybe<string> = null
  signatureCertificate: Maybe<string> = null
  serviceProviderUrl: Maybe<string> = null
  assertEndpoint: Maybe<string> = null
  activateCreatedUsers: Maybe<boolean> = null
  allowedGroups: Maybe<AuthConfigurationAllowedGroups[]> = null

  constructor(data: Partial<SamlConfiguration>) {
    Object.assign(this, data)
  }
}
