import type { Mutation } from '../typeDefs/types'

/**
 * Dashboards.
 */

/* Create dashboard */

export type MutationCreateDashboard = Pick<Mutation, 'createDashboard'>

export const mutationCreateDashboard = `
  mutation mutationCreateDashboard($dashboard: InputCreateDashboard!) {
    createDashboard(dashboard: $dashboard) {
      id
      name
      order
    }
  }
`

/* Edit dashboard */

export type MutationEditDashboard = Pick<Mutation, 'editDashboard'>

export const mutationEditDashboard = `
  mutation mutationEditDashboard($dashboard: InputEditDashboard!) {
    editDashboard(dashboard: $dashboard) {
      name
      order
    }
  }
`

/* Delete dashboard */

export type MutationDeleteDashboard = Pick<Mutation, 'deleteDashboard'>

export const mutationDeleteDashboard = `
  mutation mutationDeleteDashboard($dashboard: InputDeleteDashboard!) {
    deleteDashboard(dashboard: $dashboard)
  }
`

/**
 * Widgets.
 */

/* Create dashboard widget */

export type MutationCreateDashboardWidget = Pick<
  Mutation,
  'createDashboardWidget'
>

export const mutationCreateDashboardWidget = `
  mutation mutationCreateDashboardWidget(
    $dashboardWidget: InputCreateDashboardWidget!
  ) {
    createDashboardWidget(dashboardWidget: $dashboardWidget) {
      id
      posX
      posY
      width
      height
      title
    }
  }
`

/* Edit dashboard widget */

export type MutationEditDashboardWidget = Pick<Mutation, 'editDashboardWidget'>

export const mutationEditDashboardWidget = `
  mutation mutationEditDashboardWidget(
    $dashboardWidget: InputEditDashboardWidget!
  ) {
    editDashboardWidget(dashboardWidget: $dashboardWidget) {
      id
      posX
      posY
      width
      height
      title
    }
  }
`

/* Delete dashboard widget */

export type MutationDeleteDashboardWidget = Pick<
  Mutation,
  'deleteDashboardWidget'
>

export const mutationDeleteDashboardWidget = `
  mutation mutationDeleteDashboardWidget($dashboardWidget: InputDeleteDashboardWidget!) {
    deleteDashboardWidget(dashboardWidget: $dashboardWidget)
  }
`

/* Set dashboard widget options */

export type MutationSetDashboardWidgetOptions = Pick<
  Mutation,
  'setDashboardWidgetOptions'
>

export const mutationSetDashboardWidgetOptions = `
  mutation mutationSetDashboardWidgetOptions($dashboardWidgetOptions: InputSetDashboardWidgetOptions!) {
    setDashboardWidgetOptions(dashboardWidgetOptions: $dashboardWidgetOptions)
  }
`
