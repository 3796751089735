import {
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { ButtonSize } from '@app/components-legacy/Form/Wrappers/types'
import { LabelAlt } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreInputHealthChecks } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleHealthChecksDrawerOnSubmit } from '../handlers'
import HealthChecksSelectionCounter from './HealthChecksSelectionCounter'

interface IDrawerFooterProps {
  onSubmit?: () => void
  storeInputHealthChecks: StoreInputHealthChecks
  storePendingHealthChecks: StoreInputHealthChecks
}

const DrawerFooter: React.FC<IDrawerFooterProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Components.InputHealthChecks']
  })

  const enableSubmit =
    props.storePendingHealthChecks.isDrawerSelectionSubmitEnabled

  return (
    <ContainerFooter>
      <DrawerCancelButton
        storeDrawer={props.storeInputHealthChecks.storeDrawer}
      />

      <ContainerFlex
        name="DrawerFooterRight"
        alignItems="center"
        items={[
          <LabelAlt variant={LabelAltVariant.Minor}>
            <HealthChecksSelectionCounter
              storeInputHealthChecks={props.storePendingHealthChecks}
            />
          </LabelAlt>,

          <FormWrapperButton
            labelledBy="submit"
            pendoSuffix="drawer"
            size={ButtonSize.large}
            buttonProps={{
              type: 'primary',
              disabled: !enableSubmit,
              onClick: handleHealthChecksDrawerOnSubmit(
                props.storePendingHealthChecks,
                props.storeInputHealthChecks
              )(props.onSubmit)
            }}
          >
            {translate('Submit selection')}
          </FormWrapperButton>
        ]}
        spaced
        spaceWidth="small"
      />
    </ContainerFooter>
  )
}

export default observer(DrawerFooter)
