import { InputSelectProfiles } from '@app/components-legacy/Input'
import { LabelMissingData } from '@app/components-legacy/Label'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { onProfileSelection } from './handlers'

interface IWidgetProfileSelectorProps {
  style?: React.CSSProperties
  className?: string
}

const WidgetProfileSelector: React.FC<IWidgetProfileSelectorProps> = props => {
  const { storeAuthentication } = useStores()
  const appRouter = useAppRouter()

  const currentProfile = storeAuthentication.currentProfile

  const currentProfileName =
    currentProfile && currentProfile.getPropertyAsString('name')

  if (!currentProfileName) {
    return <LabelMissingData />
  }

  return (
    <InputSelectProfiles
      className={props.className}
      labelledBy="widgets-profiles"
      onProfileChange={onProfileSelection(appRouter, storeAuthentication)}
      defaultProfileName={currentProfileName}
      profiles={storeAuthentication.selectionnableProfiles}
    />
  )
}

const ObservedWidgetProfileSelector = observer(WidgetProfileSelector)

export default styled(ObservedWidgetProfileSelector)`
  min-width: 150px;
  max-width: 150px;
`
