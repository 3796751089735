/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { Recommendation } from './Recommendation'
import {
  RecommendationFromJSON,
  RecommendationFromJSONTyped,
  RecommendationToJSON
} from './Recommendation'
import type { Resource } from './Resource'
import {
  ResourceFromJSON,
  ResourceFromJSONTyped,
  ResourceToJSON
} from './Resource'
import type { Tenant } from './Tenant'
import { TenantFromJSON, TenantFromJSONTyped, TenantToJSON } from './Tenant'

/**
 *
 * @export
 * @interface Checker
 */
export interface Checker {
  /**
   *
   * @type {string}
   * @memberof Checker
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Checker
   */
  codename: string
  /**
   *
   * @type {string}
   * @memberof Checker
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof Checker
   */
  hasFindings: boolean
  /**
   *
   * @type {Date}
   * @memberof Checker
   */
  latestDetectionDate?: Date
  /**
   *
   * @type {string}
   * @memberof Checker
   */
  summary: string
  /**
   *
   * @type {string}
   * @memberof Checker
   */
  description: string
  /**
   *
   * @type {Array<Tenant>}
   * @memberof Checker
   */
  tenants: Array<Tenant>
  /**
   *
   * @type {string}
   * @memberof Checker
   */
  severity: CheckerSeverityEnum
  /**
   *
   * @type {string}
   * @memberof Checker
   */
  vulnerabilityDetail: string
  /**
   *
   * @type {Recommendation}
   * @memberof Checker
   */
  recommendation: Recommendation
  /**
   *
   * @type {Array<Resource>}
   * @memberof Checker
   */
  resources?: Array<Resource>
}

/**
 * @export
 * @enum {string}
 */
export enum CheckerSeverityEnum {
  Critical = 'CRITICAL',
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW'
}

/**
 * Check if a given object implements the Checker interface.
 */
export function instanceOfChecker(value: object): boolean {
  if (!('id' in value)) return false
  if (!('codename' in value)) return false
  if (!('name' in value)) return false
  if (!('hasFindings' in value)) return false
  if (!('summary' in value)) return false
  if (!('description' in value)) return false
  if (!('tenants' in value)) return false
  if (!('severity' in value)) return false
  if (!('vulnerabilityDetail' in value)) return false
  if (!('recommendation' in value)) return false
  return true
}

export function CheckerFromJSON(json: any): Checker {
  return CheckerFromJSONTyped(json, false)
}

export function CheckerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Checker {
  if (json == null) {
    return json
  }
  return {
    id: json['id'],
    codename: json['codename'],
    name: json['name'],
    hasFindings: json['has_findings'],
    latestDetectionDate:
      json['latest_detection_date'] == null
        ? undefined
        : new Date(json['latest_detection_date']),
    summary: json['summary'],
    description: json['description'],
    tenants: (json['tenants'] as Array<any>).map(TenantFromJSON),
    severity: json['severity'],
    vulnerabilityDetail: json['vulnerability_detail'],
    recommendation: RecommendationFromJSON(json['recommendation']),
    resources:
      json['resources'] == null
        ? undefined
        : (json['resources'] as Array<any>).map(ResourceFromJSON)
  }
}

export function CheckerToJSON(value?: Checker | null): any {
  if (value == null) {
    return value
  }
  return {
    id: value['id'],
    codename: value['codename'],
    name: value['name'],
    has_findings: value['hasFindings'],
    latest_detection_date:
      value['latestDetectionDate'] == null
        ? undefined
        : value['latestDetectionDate'].toISOString(),
    summary: value['summary'],
    description: value['description'],
    tenants: (value['tenants'] as Array<any>).map(TenantToJSON),
    severity: value['severity'],
    vulnerability_detail: value['vulnerabilityDetail'],
    recommendation: RecommendationToJSON(value['recommendation']),
    resources:
      value['resources'] == null
        ? undefined
        : (value['resources'] as Array<any>).map(ResourceToJSON)
  }
}
