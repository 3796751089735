import { LabelDirectories } from '@app/components-legacy/Label'
import type StoreWidget from '@app/stores/Dashboard/StoreWidget'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'

interface IWidgetFooterDirectoriesProps {
  storeWidget: StoreWidget
}

const WidgetFooterDirectories: React.FC<
  IWidgetFooterDirectoriesProps
> = props => {
  const theme = useDSTheme()

  if (
    props.storeWidget.storeFlagsFetchWidgetData.flags.isLoading ||
    !isThemeLegacy(theme)
  ) {
    return null
  }

  return <LabelDirectories directoryIds={props.storeWidget.directoryIds} />
}

export default WidgetFooterDirectories
