import type { PropertiesNullable } from '@@types/helpers'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import type {
  IncriminatingAttribute,
  Maybe
} from '@server/graphql/typeDefs/types'
import EntityIncriminatingAttribute from '.'
import type { IEntityListable, IEntityOmitKeysParameter } from '../types'
import type { IDataRowIncriminatingAttributeListable } from './types'

export default class EntityIncriminatingAttributeListable
  extends EntityIncriminatingAttribute
  implements
    PropertiesNullable<IncriminatingAttribute>,
    IEntityListable<IDataRowIncriminatingAttributeListable>
{
  /* Implements IEntityListable */

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowIncriminatingAttributeListable> = []
  ): Array<IWidgetListColumns<IDataRowIncriminatingAttributeListable>> {
    const columns: Array<
      IWidgetListColumns<IDataRowIncriminatingAttributeListable>
    > = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Description',
        key: 'description'
      },
      {
        label: 'Reason',
        key: 'reasonId'
      },
      {
        label: 'Checker',
        key: 'checkerId'
      },
      {
        label: 'Ignore until',
        key: 'ignoreUntil'
      },
      {
        label: 'Resolved at',
        key: 'resolvedAt'
      }
    ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  /**
   * Return the entity as a row for widget lists.
   */
  asDataRow(): Maybe<IDataRowIncriminatingAttributeListable> {
    return {
      id: this.getPropertyAsNumber('id'),
      description: this.buildDescriptionMarkdown(),
      directoryId: this.getPropertyAsNumber('directoryId'),
      reasonId: this.getPropertyAsNumber('reasonId'),
      checkerId: this.getPropertyAsNumber('checkerId'),
      ignoreUntil: this.getPropertyAsString('ignoreUntil'),
      eventCreatedId: this.getPropertyAsString('createdEventId'),
      eventDate: this.getPropertyAsString('eventDate'),
      resolvedAt: this.getPropertyAsString('resolvedAt')
    }
  }
}
