import type { DeepPartial } from '@@types/helpers'
import { createAttributeString } from '@alsid/common/libs/styles/createAttributeString'
import { createStyleString } from '@alsid/common/libs/styles/createStyleString'
import type { ISecurityEngineAppConfiguration } from '@libs/IframeCommunicationBus/types'
import { newLogger } from '@libs/new-logger'
import { deepMerge, type Maybe } from '@productive-codebases/toolbox'
import { IframeCommunicationBus } from 'libs/IframeCommunicationBus'
import { buildIframeBusHandlers } from './libs/buildIframeBusHandlers'
import { buildIframeUrl } from './libs/buildIframeUrl'
import {
  createBrowserEnvironment,
  type BrowserEnvironment
} from './libs/environment'
import { SecurityEngineError } from './libs/Errors'

const logger = newLogger('sdk')('SecurityEngineApp')

/**
 * Usage example:
 *
 * new SecurityEngineApp({
 *   iframe: {
 *     style: {
 *       padding: '30px'
 *     }
 *   },
 *   development: true,
 *   endpoint: '/path/to/cam/dist/files'
 * }).mountInto('#securityEngine')
 */
export class SecurityEngineApp {
  private _iframeCommunicationBus: Maybe<IframeCommunicationBus> = null

  constructor(
    private _configuration: ISecurityEngineAppConfiguration,
    private _browserEnvironment: BrowserEnvironment = createBrowserEnvironment(
      document,
      window
    )
  ) {
    const defaultStyle: React.CSSProperties = {
      display: 'block',
      height: '100%',
      width: '100%',
      border: 'none',
      margin: 0,
      padding: 0,
      overflowY: 'scroll'
    }

    const defaultConfiguration: DeepPartial<ISecurityEngineAppConfiguration> = {
      iframeStyle: defaultStyle
    }

    this._configuration = deepMerge([defaultConfiguration, this._configuration])
  }

  /**
   * "Mount" the CAM iframe in the node matching `hostNodeHtmlSelector`.
   */
  mountInto(hostNodeHtmlSelector: string): this {
    this._insertIframe(hostNodeHtmlSelector)
    this._configureIframeBus()

    return this
  }

  /**
   * "Clean" SecurityEngine context.
   */
  unmount() {
    this._replaceHistoryWithoutQuerystringParameter('securityEngine')
    this._iframeCommunicationBus?.unbind()
  }

  /**
   * Private
   */

  /**
   * Insert iframe that loads SecurityEngine application.
   */
  private _insertIframe(hostNodeHtmlSelector: string): this {
    logger('debug')('Insert iframe')

    const hostNode =
      this._browserEnvironment.domDocument.querySelector(hostNodeHtmlSelector)

    if (!hostNode) {
      throw new SecurityEngineError(
        `The node matching "${hostNodeHtmlSelector}" selector has not been found.`
      )
    }

    const src = this._configuration.endpoint

    // avoid inception loading
    if (!src) {
      return this
    }

    const iframeUrl = buildIframeUrl(
      this._browserEnvironment,
      this._configuration
    )

    const attributes = {
      style: createStyleString(this._configuration.iframeStyle),
      src: iframeUrl,
      allow: ['clipboard-write'].join('; ')
    }

    hostNode.innerHTML = `<iframe width="100%" height="100%" frameborder="0" ${createAttributeString(
      attributes
    )} />`

    return this
  }

  /**
   * Configure the iframe bus to communicate with SecurityEngine's iframe.
   */
  private _configureIframeBus(): this {
    logger('debug')('Configure IframeCommunicationBus: %o', this._configuration)

    const targetOrigin = this._configuration.endpoint.startsWith('http')
      ? // origin of the iframe's endpoint if starting by http (dev only)
        new URL(this._configuration.endpoint).origin
      : // origin of the current document when served on the same origin (production)
        this._browserEnvironment.domDocument.location.origin

    this._iframeCommunicationBus = new IframeCommunicationBus(
      {
        targetOrigin,
        handlers: buildIframeBusHandlers(
          this._browserEnvironment,
          this._configuration.iframeMessageHandlers
        ),
        windowObject: this._browserEnvironment.domWindow
      },
      logger
    )

    return this
  }

  /**
   * Replace the history by removing a specific querystring parameter.
   */
  private _replaceHistoryWithoutQuerystringParameter(
    parameterName: string
  ): void {
    const { domWindow, domDocument } = this._browserEnvironment

    const searchParams = new URLSearchParams(domDocument.location.search)

    searchParams.delete(parameterName)

    // construct the new URL without the specified parameter
    const newRelativePathQuery = `${
      domDocument.location.pathname
    }?${searchParams.toString()}${domDocument.location.hash}`

    domWindow.history.replaceState(null, '', newRelativePathQuery)
  }
}
