import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import type {
  DashboardWidgetOptionsSerieStatDataPointCount,
  Maybe
} from '@server/graphql/typeDefs/types'

export default class EntityAttacksStatData
  extends EntityBase
  implements PropertiesNullable<DashboardWidgetOptionsSerieStatDataPointCount>
{
  timestamp: Maybe<string> = null
  count: Maybe<number> = null

  constructor(data: Partial<DashboardWidgetOptionsSerieStatDataPointCount>) {
    super()
    Object.assign(this, data)
  }
}
