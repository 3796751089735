import { ContainerFlex } from '@app/components-legacy/Container'
import { consts } from '@app/styles'
import { Tooltip } from 'antd'
import type { TooltipProps } from 'antd/lib/tooltip'
import * as React from 'react'
import LabelSlicedContainer from './LabelSlicedContainer'
import type { ILabelSlicedProps } from './types'

const LabelSlicedTooltipContent: React.FC<ILabelSlicedProps> = props => {
  const [isValueSliced, setIsValueSliced] = React.useState(
    !(props.maxWidth || props.maxWidthAuto)
  )

  React.useEffect(() => {
    setIsValueSliced(!props.maxWidth)
  }, [props.value, props.maxWidth])

  const getTooltipContent = (): React.ReactNode => {
    const { maxWidth, maxWidthAuto, ...labelProps } = props

    return (
      <ContainerFlex
        name="TooltipContent"
        direction="column"
        items={[
          <LabelSlicedContainer
            {...labelProps}
            maxLength={props.popoverMaxLength}
            breakString
            labelProperties={{ color: consts.colorWhite }}
          />,
          props.decoration
        ]}
        spaced
      />
    )
  }

  const tooltipProps: TooltipProps = {
    title: isValueSliced ? getTooltipContent() : null,
    open: isValueSliced ? undefined : false,
    ...props.tooltipProps
  }

  return (
    <Tooltip {...tooltipProps}>
      <span>
        <LabelSlicedContainer
          {...props}
          onEllipsisActiveChange={setIsValueSliced}
        />
      </span>
    </Tooltip>
  )
}

export default LabelSlicedTooltipContent
