import type { Maybe } from '@@types/helpers'
import {
  breakpointExtraLargeViewPort,
  breakpointLargeViewPort,
  breakpointMediumViewPort,
  breakpointSmallViewPort,
  breakpointVerySmallViewPort
} from '@app/styles/consts'
import * as React from 'react'
import { useWindowSize } from '..'
import type { IWindowSize } from '../useWindowSize'
import { DeviceViewPort } from './DeviceViewPort'
import type { IUseDeviceViewPort } from './types'
import { DeviceViewPortWidth } from './types'

function getDeviceViewWidth(
  windowSize?: Maybe<IWindowSize>
): DeviceViewPortWidth {
  if (!windowSize) {
    return DeviceViewPortWidth.small
  }

  if (windowSize.width > breakpointExtraLargeViewPort) {
    return DeviceViewPortWidth.extraLarge
  }

  if (windowSize.width > breakpointLargeViewPort) {
    return DeviceViewPortWidth.large
  }

  if (windowSize.width > breakpointMediumViewPort) {
    return DeviceViewPortWidth.medium
  }

  if (windowSize.width > breakpointSmallViewPort) {
    return DeviceViewPortWidth.small
  }

  if (windowSize.width > breakpointVerySmallViewPort) {
    return DeviceViewPortWidth.verySmall
  }

  return DeviceViewPortWidth.small
}

/**
 * Return a DeviceViewPort object according to the width of the window.
 */
export function useDeviceViewPort(): IUseDeviceViewPort {
  const windowSize = useWindowSize()

  const deviceViewPort = React.useMemo(() => {
    return {
      deviceViewPort: new DeviceViewPort(getDeviceViewWidth(windowSize))
    }
  }, [windowSize])

  // consider a full screen size if the size is not computable (yet?)
  if (!windowSize) {
    return {
      deviceViewPort: new DeviceViewPort(DeviceViewPortWidth.extraLarge)
    }
  }

  return deviceViewPort
}
