import type { PropertiesNullable } from '@@types/helpers'
import type { Expression } from '@libs/Expression'
import type { Maybe, SearchBookmarkEntry } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntitySearchBookmarkEntry
  extends EntityBase
  implements PropertiesNullable<SearchBookmarkEntry>
{
  id: Maybe<number> = null
  expression: Maybe<string> = null
  expressionObject: Maybe<Expression> = null
  name: Maybe<string> = null
  category: Maybe<string> = null

  constructor(data: Partial<SearchBookmarkEntry>) {
    super()
    Object.assign(this, data)
  }
}
