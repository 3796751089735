import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components/Container'
import { LabelSliced } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import BladeHeader from '@app/components-legacy/Blade/BladeHeader'
import { LabelDate, LabelDirectoriesTree } from '@app/components-legacy/Label'
import type {
  EntityHealthCheckDomainStatusDetails,
  EntityHealthCheckPlatformStatusDetails
} from '@app/entities'
import { useAppTranslator, useStores } from '@app/hooks'
import { HealthCheckStatusTooltip } from '@app/pages/HealthCheck/HealthCheckStatusTooltip'
import { filterFalsies } from '@libs/filterFalsies'
import * as React from 'react'
import { useMeasure } from 'react-use'
import { HealthCheckStatusIcon } from '../HealthCheckStatusIcon'
import HealthCheckNameLabel from '../Labels/HealthCheckNameLabel'
import { HealthCheckNameLocale } from '../Labels/types'
import { HealthCheckDetailsContext } from './context'

interface IHealthCheckDetailsHeaderProps {
  healthCheckDetails: Maybe<
    | EntityHealthCheckDomainStatusDetails
    | EntityHealthCheckPlatformStatusDetails
  >
}

const HealthCheckDetailsHeader: React.FC<
  IHealthCheckDetailsHeaderProps
> = props => {
  const [ref, { width: headerWidth }] = useMeasure<HTMLDivElement>()

  const parameters = React.useContext(HealthCheckDetailsContext)

  const { storeInfrastructures } = useStores()

  const { healthCheckDetails } = props

  const translate = useAppTranslator({
    namespaces: [
      'HealthCheck.Domains',
      'HealthCheck.Details',
      'HealthCheck.Status'
    ]
  })

  if (!parameters || !healthCheckDetails) {
    return null
  }

  const hasDirectory =
    parameters.directoryId !== undefined && 'directoryId' in healthCheckDetails

  const healthCheckName =
    healthCheckDetails?.getPropertyAsString('healthCheckName')

  const columnCount = hasDirectory ? 5 : 4
  const marginBetweenColumns = 20
  const availableWidth =
    (headerWidth - (columnCount - 1) * marginBetweenColumns) / columnCount

  return (
    <div ref={ref}>
      <BladeHeader
        infos={filterFalsies([
          {
            rawType: 'Name',
            type: translate('Health check'),
            value: (
              <HealthCheckNameLabel
                healthCheckName={healthCheckName}
                locale={HealthCheckNameLocale.name}
                labelProps={{
                  variant: LabelVariant.p_bold
                }}
                maxWidth={availableWidth}
              />
            )
          },
          {
            rawType: 'Status',
            type: translate('Status'),
            value: (
              <HealthCheckStatusTooltip status={healthCheckDetails.status}>
                <div>
                  <ContainerFlex
                    name="HealthCheckDetailsHeaderStatus"
                    alignItems="center"
                    items={[
                      <HealthCheckStatusIcon
                        status={healthCheckDetails.status}
                      />,
                      <LabelSliced
                        value={translate(
                          healthCheckDetails.getPropertyAsString('status')
                        )}
                        maxWidth={availableWidth - 21}
                      />
                    ]}
                    spaced
                    spaceWidth="verySmall"
                  />
                </div>
              </HealthCheckStatusTooltip>
            )
          },
          hasDirectory && {
            rawType: 'Impacted domain',
            type: translate('Impacted domain'),
            value: (
              <LabelDirectoriesTree
                direction="column"
                directoryIds={[
                  healthCheckDetails.getPropertyAsNumber('directoryId')
                ]}
                storeInfrastructures={storeInfrastructures}
              />
            )
          },
          {
            rawType: 'Last check',
            type: translate('Last check'),
            value: (
              <LabelDate
                date={healthCheckDetails.lastCheckDate}
                maxWidth={availableWidth}
              />
            )
          },
          {
            rawType: 'Status since',
            type: translate('Status since'),
            value: (
              <LabelDate
                date={healthCheckDetails.statusSince}
                maxWidth={availableWidth}
              />
            )
          }
        ])}
      />
    </div>
  )
}

export default HealthCheckDetailsHeader
