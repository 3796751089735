import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconUnPinProps extends IIconProps {}

const IconUnPin: React.FC<IIconUnPinProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.84408 14.4126L5.46771 11.0362L0.542399 15.9615L0 16L0.038465 15.4576L4.96378 10.5323L1.5874 7.15591C2.54146 6.20185 3.77799 5.70207 5.02812 5.65669L10.3464 10.8408C10.3323 12.1352 9.83146 13.4252 8.84408 14.4126ZM10.4482 8.10348L12.1086 5.50799C13.3796 6.11377 14.9473 5.89048 16 4.83772L11.1622 0C10.111 1.0512 9.88723 2.61638 10.4899 3.8863L7.85211 5.57287L10.4482 8.10348Z"
        fill={fill}
      />
      <path d="M1.5 0.5L15 14" stroke={fill} strokeLinecap="round" />
    </svg>
  )
}

export default IconUnPin
