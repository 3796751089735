import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import { FontColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

export enum Direction {
  up = 'up',
  right = 'right',
  down = 'down',
  left = 'left'
}

const StyledSvg = styled.svg<{ rotation: number }>`
  transform: rotate(${({ rotation }) => rotation}deg);
  transition: transform 0.3s ${consts.smoothAnimation} !important;
`

function getRotation(direction?: Direction): number {
  switch (direction) {
    case 'right':
      return 90
    case 'down':
      return 180
    case 'left':
      return 270
    default:
      return 0
  }
}

export interface IIconDirectionProps extends IIconProps {
  direction?: Direction
}

const IconDirection: React.FC<IIconDirectionProps> = props => {
  const stroke = props.color ?? FontColorV2.primary
  const size = props.size ?? 12

  return (
    <StyledSvg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 30 30"
      rotation={getRotation(props.direction)}
      style={props.style}
    >
      <g>
        <path
          fill="none"
          d="M5 20 L15 10 L25 20"
          strokeWidth="4"
          stroke={stroke}
          strokeLinecap="round"
        />
      </g>
    </StyledSvg>
  )
}

export default IconDirection
