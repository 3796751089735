import type { Maybe } from '@@types/helpers'
import type { InstanceName } from '@libs/Environment/types'
import type { FetchClient } from '@libs/FetchClient'
import type AppRouter from '@libs/Router/AppRouter'
import type { IRouteDefinition } from '@libs/Router/types'
import type {
  ApiServiceRouteDefinition,
  ApiServiceRouteName
} from '@server/routes/api'
import type {
  AuthServiceRouteDefinition,
  AuthServiceRouteName
} from '@server/routes/auth'
import type {
  HealthCheckServiceRouteDefinition,
  HealthCheckServiceRouteName
} from '@server/routes/healthcheck'
import type { Variables } from 'graphql-request'
import type { RequestInit } from 'graphql-request/dist/types.dom'

export enum BackendServiceName {
  api = 'api',
  auth = 'auth',
  healthcheck = 'healthcheck',
  live = 'live'
}

export interface IGraphQLService<
  R extends string,
  RD extends IRouteDefinition<R>
> {
  fetchClient: FetchClient
  router: AppRouter<R, RD>
}

export interface IGraphQLServices {
  [BackendServiceName.api]: IGraphQLService<
    ApiServiceRouteName,
    ApiServiceRouteDefinition
  >
  [BackendServiceName.auth]: IGraphQLService<
    AuthServiceRouteName,
    AuthServiceRouteDefinition
  >
  [BackendServiceName.healthcheck]: IGraphQLService<
    HealthCheckServiceRouteName,
    HealthCheckServiceRouteDefinition
  >
}

export interface IGraphQLClientOption {
  baseUrl: string
  // Tenable.ad instance name
  instanceName: InstanceName
  clientRequestOptions?: RequestInit
}

export enum IGraphQLClientHeaders {
  csrfToken = 'csrf-token'
}

/**
 * Define the different types of a GraphQL query.
 */
export interface IGraphQLQuery<A extends Maybe<Variables>, O, E = null> {
  args: A
  success: O
  err: E
}

export type AnyGraphQLQuery = IGraphQLQuery<any, any, any>
