import { ServerError } from '@libs/errors'

export function assertResponseStatus(
  response: Response,
  expectedResponseStatus = 200
) {
  if (response.status !== expectedResponseStatus) {
    throw new ServerError(
      `The response status is invalid. Expected to be ${expectedResponseStatus}, received ${response.status} (${response.url}).`
    )
  }
}
