import type { Maybe } from '@@types/helpers'
import { LabelDeviance } from '@app/components-legacy/Label'
import type EntityAdObjectAsAttributes from '@app/entities/EntityAdObject/EntityAdObjectAsAttribute'
import type EntityIncriminatingAttributeListable from '@app/entities/EntityIncriminatingAttribute/EntityIncriminatingAttributeListable'
import type { IDataRowIncriminatingAttributeListable } from '@app/entities/EntityIncriminatingAttribute/types'
import { useAppRouter, useStores } from '@app/hooks'
import { AppRouteName } from '@app/routes'
import type { StoreInfrastructures, StoreInputReasons } from '@app/stores'
import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { ICheckerExposure } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import { isDefined } from '@libs/isDefined'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  getDeviantObjectsEventDetailsRouteName,
  onCheckIncriminatingAttribute
} from './handlers'

interface IDevianceCardProps {
  adObject?: Maybe<EntityAdObjectAsAttributes>
  selectable?: boolean
  incriminatingAttributeDataRow: IDataRowIncriminatingAttributeListable
  storeInfrastructures: StoreInfrastructures
  storeInputCheckersExposure?: StoreInputGenericCheckers<ICheckerExposure>
  storeInputReasons: StoreInputReasons
  storeWidgetList: StoreWidgetList<
    EntityIncriminatingAttributeListable,
    IDataRowIncriminatingAttributeListable
  >
}

const DevianceCard: React.FC<IDevianceCardProps> = props => {
  const appRouter = useAppRouter()

  const { storeInfrastructures } = useStores()

  const routeName = appRouter.getCurrentRouteName()

  if (
    routeName !== AppRouteName.IoE_Details_DeviantObjects &&
    routeName !== AppRouteName.Topology_DeviantObjects
  ) {
    return null
  }

  const parameters = appRouter.getRouteParameters({
    routeName,
    parameters: {
      checkerId: Number(),
      checkerCodename: String()
    }
  })

  if (!parameters) {
    return null
  }

  const {
    directoryId,
    checkerId,
    reasonId,
    eventCreatedId,
    description,
    eventDate,
    ignoreUntil,
    resolvedAt
  } = props.incriminatingAttributeDataRow

  const devianceReasons = props.storeInputReasons.getDeviancesReasons(
    [reasonId].filter(isDefined)
  )

  const devianceReason = devianceReasons[0]

  const infrastructureId =
    storeInfrastructures.getInfrastructureIdFromDirectoryId(directoryId)

  if (!infrastructureId || !eventCreatedId || !eventDate || !devianceReason) {
    return null
  }

  const checked = props.storeWidgetList.isSelected(
    props.incriminatingAttributeDataRow
  )

  const entityChecker =
    checkerId && props.storeInputCheckersExposure
      ? props.storeInputCheckersExposure.checkersById.get(checkerId) || null
      : null

  const eventDetailsRouteName =
    getDeviantObjectsEventDetailsRouteName(routeName)

  const eventUrl = eventDetailsRouteName
    ? appRouter.makeRouteInfosPathname({
        routeName: eventDetailsRouteName,
        parameters: {
          checkerId: parameters.checkerId,
          checkerCodename: parameters.checkerCodename,
          infrastructureId,
          directoryId,
          eventId: eventCreatedId
        }
      })
    : null

  return (
    <LabelDeviance
      adObject={props.adObject}
      checked={checked}
      eventDate={eventDate}
      description={description}
      entityChecker={entityChecker}
      eventUrl={eventUrl}
      onCheck={onCheckIncriminatingAttribute(
        props.storeWidgetList,
        props.incriminatingAttributeDataRow
      )}
      reasonColor={devianceReason.getPropertyAsString('color')}
      reasonName={devianceReason.getPropertyAsString('name')}
      ignoreUntil={ignoreUntil}
      resolvedAt={resolvedAt}
      selectable={props.selectable}
    />
  )
}

export default observer(DevianceCard)
