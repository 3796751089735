import type { MaybeUndef } from '@@types/helpers'
import { AppRouteName } from '@app/routes'
import type { StoreManagementEmails } from '@app/stores'
import { onSelectChange } from '@app/stores/helpers/StoreForm/handlers'
import type { AppRouterClient } from '@libs/Router/types'
import type { SelectValue } from 'antd/lib/select'
import { EmailFormFieldName } from './types'

export const onEmailsListLoad =
  (storeManagementEmails: StoreManagementEmails) => () => {
    storeManagementEmails.fetchEmails({
      emailsPage: 1,
      emailsPerPage: storeManagementEmails.storeWidgetList.rowsPerPage
    })
  }

export const onEmailCreateButtonClick = (appRouter: AppRouterClient) => () => {
  const url = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.Management_System_Configuration_Emails_Create,
    parameters: {}
  })
  appRouter.history.push(url)
}

export const onEmailTestIconClick =
  (storeManagementEmails: StoreManagementEmails) => (emailId: number) => () => {
    storeManagementEmails.testExistingEmail(emailId)
  }

export const onEmailEditIconClick =
  (appRouter: AppRouterClient) => (emailId: number) => () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Configuration_Emails_Edit,
      parameters: { emailId }
    })
    appRouter.history.push(url)
  }

export const onEmailDeleteIconClick =
  (storeManagementEmails: StoreManagementEmails) => (emailId: number) => () => {
    const emailEntity = storeManagementEmails.emails.get(emailId)

    if (emailEntity) {
      storeManagementEmails.storeDeleteDrawer
        .setData({ emailDataRow: emailEntity.asDataRow() })
        .openDrawer()
    }
  }

export const onEmailsListPageChange =
  (storeManagementEmails: StoreManagementEmails) =>
  (page: number, pageSize: MaybeUndef<number>) => {
    storeManagementEmails.fetchEmails({
      emailsPage: page,
      emailsPerPage: pageSize
    })
  }

export const onDirectoriesChange =
  (storeManagementEmails: StoreManagementEmails) => () => {
    storeManagementEmails.storeForm.setFieldValue(
      EmailFormFieldName.directories,
      storeManagementEmails.storeInfrastructures
        .getSelectedDirectoryIds()
        .join(',')
    )
  }

export const onProfilesChanges =
  (storeManagementEmails: StoreManagementEmails) =>
  (values: string[]): void => {
    storeManagementEmails.storeForm.setFieldValue(
      EmailFormFieldName.profiles,
      values.join(',')
    )
  }

export const onHealthCheckNamesChange =
  (storeManagementEmails: StoreManagementEmails) => () => {
    const { storeForm, storeInputHealthChecks } = storeManagementEmails

    storeForm.setFieldValue(
      EmailFormFieldName.healthCheckNames,
      storeInputHealthChecks.selectedHealthChecksTemplatesNamesIds.join(',')
    )
  }

/**
 * Auto unselect checkers on increase criticity threshold
 */
export const onEmailCriticityThresholdChanges =
  (storeManagementEmails: StoreManagementEmails) => (value: SelectValue) => {
    const { storeInputCheckersExposure } = storeManagementEmails

    storeInputCheckersExposure.unselectCheckersByThreshold(Number(value))

    onSelectChange(storeManagementEmails.storeForm)(
      EmailFormFieldName.criticityThreshold
    )(value)
  }

export const onEmailTriggerChanges =
  (storeManagementEmails: StoreManagementEmails) => (value: SelectValue) => {
    const { storeForm } = storeManagementEmails

    onSelectChange(storeForm)(EmailFormFieldName.inputType)(value)
  }
