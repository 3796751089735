import { IconCheck } from '@app/components/Icon'
import { ContainerSimple } from '@app/components-legacy/Container'
import { consts } from '@app/styles'
import { BrandColorV2, CTAColorV2 } from '@app/styles/consts'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { Switch } from 'antd'
import type { SwitchProps } from 'antd/lib/switch'
import * as React from 'react'
import styled from 'styled-components'
import type { IFormWrapperControl } from './types'

interface IFormWrapperSwitchProps extends IFormWrapperControl {
  switchProps: SwitchProps
  inputId?: string
}

const StyledSwitch = styled(Switch)`
  background-color: ${props =>
    props.checked ? CTAColorV2.primary : BrandColorV2.decorativeGray};
  width: 48px;
  height: 25px;

  .ant-switch-handle {
    top: 1px;
    left: 1px;
    width: 23px;
    height: 23px;
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 23px - 1px);
  }

  .ant-switch-handle::before {
    border-radius: 12px;
    box-shadow: none;
  }

  &.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
    left: 0;
    right: -20%;
  }

  &.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked
    .ant-switch-handle::before {
    left: -20%;
    right: 0;
  }

  &.ant-switch-loading,
  &.ant-switch-disabled {
    opacity: 1;
    background-color: ${consts.colorClearGreyNewDesign};

    .ant-switch-handle::before {
      background-color: ${consts.colorGreyNewDesign};
    }
  }
`

const StyledIconCheck = styled(IconCheck)`
  position: absolute;
  top: 5px;
  left: 5px;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  opacity: 0;
`

const ContainerSimpleWithIcon = styled(ContainerSimple)`
  position: relative;
  width: 48px;
  height: 25px;

  .ant-switch-checked + ${StyledIconCheck} {
    left: calc(100% - 21px);
    opacity: 1;

    path {
      fill: ${CTAColorV2.primary};
      transition: all 0.2s ease-in-out;
    }
  }

  .ant-switch-loading
    + ${StyledIconCheck},
    .ant-switch-disabled
    + ${StyledIconCheck} {
    path {
      fill: ${consts.colorClearGreyNewDesign};
    }
  }
`

const FormWrapperSwitch: React.FC<IFormWrapperSwitchProps> = props => {
  const inputRef = React.useRef<HTMLElement>(null)

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  // The antd Switch cannot take an id, we set it manually
  React.useEffect(
    function setInputId() {
      if (!inputRef.current || !props.inputId || themeIsLegacy) {
        return
      }
      inputRef.current.id = props.inputId
    },
    [inputRef.current]
  )

  if (themeIsLegacy) {
    return (
      <ContainerSimple
        name={`${FormWrapperSwitch.name}-${props.labelledBy}`}
        ariaRoles={props.ariaRoles || ['form', 'switch']}
        {...props}
      >
        <Switch {...props.switchProps}>{props.children}</Switch>
      </ContainerSimple>
    )
  }

  return (
    <ContainerSimpleWithIcon
      name={`${FormWrapperSwitch.name}-${props.labelledBy}`}
      ariaRoles={props.ariaRoles || ['form', 'switch']}
      {...props}
    >
      <StyledSwitch {...props.switchProps} ref={inputRef} />
      <StyledIconCheck color={BrandColorV2.decorativeGray} size={16} />
    </ContainerSimpleWithIcon>
  )
}

export default FormWrapperSwitch
