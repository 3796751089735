import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components-legacy/Container'
import type { TContainerFlexJustifyContent } from '@app/components-legacy/Container/ContainerFlex/types'
import { LabelSliced } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { useAppRouter, useStores } from '@app/hooks'
import { AttacksPageContext } from '@app/pages/IoA/IoAAttacksPage/context'
import { AttackResourceType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { addAttacksFiltersInUrl } from './functions'
import IoAIncidentLabelValue from './IoAIncidentLabelValue'

const IconContainerFlex = styled(ContainerFlex)`
  width: 22px;
  height: 22px;
`

interface IIoAAttacksTableBodyDataRowSourceDestValueProps {
  className?: string
  justifyContent: TContainerFlexJustifyContent
  type: 'source' | 'destination'
  hostname: Maybe<string>
  ip: Maybe<string>
  icon: React.ReactNode
}

const IoAAttacksTableBodyDataRowSourceDestValue: React.FC<
  IIoAAttacksTableBodyDataRowSourceDestValueProps
> = props => {
  const appRouter = useAppRouter()

  const { storeIoA } = useStores()

  const attacksPageContext = React.useContext(AttacksPageContext)

  if (!attacksPageContext) {
    return null
  }

  const { storeAttacks } = attacksPageContext

  const currentQueryStringParameters = storeAttacks.queryStringParameters

  const filterUrlWithHostname = props.hostname
    ? addAttacksFiltersInUrl(
        appRouter,
        storeIoA
      )({
        ...currentQueryStringParameters,
        resourceType: AttackResourceType.Hostname,
        resourceValue: props.hostname
      })
    : null

  const filterUrlWithIp = props.ip
    ? addAttacksFiltersInUrl(
        appRouter,
        storeIoA
      )({
        ...currentQueryStringParameters,
        resourceType: AttackResourceType.Ip,
        resourceValue: props.ip
      })
    : null

  return (
    <ContainerFlex
      name="IoAAttacksTableBodyDataRowSourceDestValue"
      alignItems="center"
      justifyContent={props.justifyContent}
      items={[
        <IconContainerFlex
          name="SourceIcon"
          alignItems="center"
          justifyContent="center"
          items={[props.icon]}
        />,

        <ContainerFlex
          name="SourceText"
          direction="column"
          justifyContent="center"
          items={[
            <IoAIncidentLabelValue url={filterUrlWithHostname}>
              <LabelSliced maxLength={17} value={props.hostname || ''} />
            </IoAIncidentLabelValue>,

            props.ip && (
              <IoAIncidentLabelValue
                variant={LabelAltVariant.Small}
                url={filterUrlWithIp}
              >
                <LabelSliced maxLength={25} value={props.ip} />
              </IoAIncidentLabelValue>
            )
          ]}
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(IoAAttacksTableBodyDataRowSourceDestValue)
