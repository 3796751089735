import { Features } from '@alsid/common'
import { ContainerFlex } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { consts } from '@app/styles'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import { AttackPathDirection } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import DrawerNodeRelationshipsFilters from './DrawerNodeRelationshipsFilters'
import DrawerNodeRelationshipsInfo from './DrawerNodeRelationshipsInfo'
import DrawerNodeRelationshipsNodes from './DrawerNodeRelationshipsNodes'
import DrawerNodeRelationshipsVerticalLine from './DrawerNodeRelationshipsVerticalLine'

const StyledContainerFlex = styled(ContainerFlex)`
  overflow: hidden;
`

export interface IDrawerNodeRelationshipsContentBodyProps {
  className?: string
}

const DrawerNodeRelationshipsContentBody: React.FC<
  IDrawerNodeRelationshipsContentBodyProps
> = props => {
  const { storeAttackPath } = useStores()

  const featureAttackPathDrawerSelection = useFeatureFlag({
    featureFlagName: Features.ATTACK_PATH_DRAWER_SELECTION
  })

  const paginatedChildrenNodesWithEdge =
    storeAttackPath.paginatedDrawerChildrenNodesWithEdge

  return (
    <DSThemeProvider themeName={DSThemeName.grey}>
      <ContainerFlex
        name="DrawerNodeRelationshipsContentBody"
        className={props.className}
        itemsFlexGrow={[0, 1]}
        direction="column"
        items={[
          featureAttackPathDrawerSelection && <DrawerNodeRelationshipsInfo />,
          <StyledContainerFlex
            name="DrawerNodeRelationshipsContentBody2"
            itemsFlexGrow={[0, 1]}
            items={[
              <DrawerNodeRelationshipsVerticalLine />,

              <StyledContainerFlex
                name="DrawerNodeRelationshipsContentBody3"
                direction="column"
                itemsFlexGrow={[0, 1]}
                items={[
                  <DrawerNodeRelationshipsFilters />,
                  <DrawerNodeRelationshipsNodes
                    childrenNodesWithEdge={paginatedChildrenNodesWithEdge}
                    hasArrow={
                      storeAttackPath.direction === AttackPathDirection.From
                    }
                  />
                ]}
              />
            ]}
          />
        ]}
        spaced
        spaceWidth="smaller"
      />
    </DSThemeProvider>
  )
}

const ObservedDrawerNodeRelationshipsContentBody = observer(
  DrawerNodeRelationshipsContentBody
)

export default styled(ObservedDrawerNodeRelationshipsContentBody)`
  height: calc(100% - 48px);
  padding: ${consts.paddingMedium} ${consts.paddingLarge};
  background-color: ${consts.colorWhiteAltRedesign};
  border-radius: ${consts.borderRadiusLargeRedesign};
  overflow: hidden;
`
