import type { IIconProps } from '@app/components/Icon/types'
import { FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconPlusProps extends IIconProps {}

const IconPlus: React.FC<IIconPlusProps> = props => {
  const fill = props.color ?? FontColorV2.white
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.2672 8.87494L14.2672 7.2672L8.80387 7.2672V1.80387L7.19613 1.80387L7.19613 7.2672H1.7328L1.7328 8.87494L7.19613 8.87494L7.19613 14.3383L8.80387 14.3383V8.87494L14.2672 8.87494Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconPlus
