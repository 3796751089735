import { FormGroups } from '@app/components-legacy/Form'
import type { EntityProfile, EntityRbacRole } from '@app/entities'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreForm from '@app/stores/helpers/StoreForm'
import type StoreFormGroups from '@app/stores/helpers/StoreFormGroups.ts'
import type { CreateGroupFn } from '@app/stores/helpers/StoreFormGroups.ts/types'
import { AuthProviderName } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import ConfigurationGroupsForm from './ConfigurationGroupsForm'

interface IConfigurationGroupsProps {
  userProvider: AuthProviderName
  storeFormGroups: StoreFormGroups<StoreForm<any>>
  profiles: EntityProfile[]
  rbacRoles: EntityRbacRole[]
  createGroupFn: CreateGroupFn<StoreForm<any>>
}

const ConfigurationGroups: React.FC<IConfigurationGroupsProps> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Management.System.Configuration.LDAP',
      'Management.System.Configuration.SAML'
    ]
  })

  return (
    <FormGroups<StoreForm<any>>
      addFirstGroupButtonLabel={
        props.userProvider === AuthProviderName.Ldap
          ? translate('Add an LDAP group')
          : translate('Add a SAML group')
      }
      store={props.storeFormGroups}
      createGroupFn={props.createGroupFn}
    >
      {storeFormGroup => {
        return (
          <ConfigurationGroupsForm
            userProvider={props.userProvider}
            profiles={props.profiles}
            rbacRoles={props.rbacRoles}
            storeForm={storeFormGroup}
          />
        )
      }}
    </FormGroups>
  )
}

export default ConfigurationGroups
