// eslint-disable-next-line @typescript-eslint/no-var-requires
const JSONBig = require('@toniq-labs/json-bigint')

const JSONBigConfigured = JSONBig({
  alwaysParseAsBig: false,
  useNativeBigInt: true
})

/**
 * Shortcut to parse a string to an object with Bigint support.
 */
export function JSONBigIntParse<T = any>(str: string, ...args: any[]): T {
  return JSONBigConfigured.parse(str, ...args)
}

/**
 * Shortcut to stringify an object with Bigint support.
 */
export function JSONBigIntStringify<T>(obj: T, ...args: any[]): string {
  return JSONBigConfigured.stringify(obj, ...args)
}
