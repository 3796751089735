import { FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconHighFiveProps {
  className?: string
  color?: string
  size?: number
}

const IconHighFive: React.FC<IIconHighFiveProps> = props => {
  const color = props.color || FontColorV2.primary
  const size = `${props.size || 35}px`

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 35 35"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <polygon points="0 0 12.7150929 0 12.7150929 18.5931836 0 18.5931836" />
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-723.000000, -568.000000)">
          <g transform="translate(723.000000, 568.000000)">
            <g transform="translate(4.000000, 4.000000)">
              <path
                d="M7.96282528,11.0743494 C7.96282528,12.8112825 6.55477695,14.2193309 4.81784387,14.2193309 C3.08091078,14.2193309 1.67286245,12.8112825 1.67286245,11.0743494 C1.67286245,9.33741636 3.08091078,7.92936803 4.81784387,7.92936803 C6.55477695,7.92936803 7.96282528,9.33741636 7.96282528,11.0743494"
                fill={color}
              />
              <path
                d="M25.2936803,11.0743494 C25.2936803,12.8112825 23.885632,14.2193309 22.1486989,14.2193309 C20.4117658,14.2193309 19.0037175,12.8112825 19.0037175,11.0743494 C19.0037175,9.33741636 20.4117658,7.92936803 22.1486989,7.92936803 C23.885632,7.92936803 25.2936803,9.33741636 25.2936803,11.0743494"
                fill={color}
              />
              <path
                d="M14.3531599,6.25650558 L12.8475836,13.0148699 C12.8475836,13.0148699 12.1784387,16.9695167 7.96282528,17.3843866 L7.96282528,24.6322305 C7.96282528,24.9604461 7.69650558,25.2267658 7.36828996,25.2267658 L0.594535316,25.2267658 C0.266319703,25.2267658 0,24.9604461 0,24.6322305 L0,17.3763569 C0,15.6328996 1.41356877,14.2193309 3.15702602,14.2193309 L7.96282528,14.2193309 C7.96282528,14.2193309 9.73371747,13.8847584 10.2533086,11.0743494 C10.7732342,8.26394052 11.2081784,6.25650558 11.2081784,6.25650558 L14.3531599,6.25650558 Z"
                fill={color}
              />
              <g transform="translate(14.284907, 6.633582)">
                <path
                  d="M12.7150929,17.9986617 C12.7150929,18.3268773 12.4487732,18.593197 12.1205576,18.593197 L5.34680297,18.593197 C5.01858736,18.593197 4.75226766,18.3268773 4.75226766,17.9986617 L4.75226766,10.7507844 C1.47914498,10.4285911 0.34394052,7.97252788 0,6.89048699 L1.58687732,0 C1.73609665,0.707286245 2.07535316,2.35237918 2.46178439,4.44078067 C2.98137546,7.25118959 4.75226766,7.58576208 4.75226766,7.58576208 L9.55806691,7.58576208 C11.3015242,7.58576208 12.7150929,8.99933086 12.7150929,10.7427881 L12.7150929,17.9986617 Z"
                  fill={color}
                />
              </g>
              <polygon
                fill={color}
                points="12.6468401 3.21189591 14.3531599 3.21189591 14.3531599 0 12.6468401 0"
              />
              <polygon
                fill={color}
                points="16.1126699 4.78345651 14.9061011 3.57688773 17.1772796 1.30577621 18.3838483 2.51231152"
              />
              <polygon
                fill={color}
                points="10.8873134 4.78345651 12.0938822 3.57688773 9.82273717 1.30577621 8.6161684 2.51231152"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconHighFive
