import { Features } from '@alsid/common'
import {
  IconAttackPath,
  IconDashboard,
  IconIdentities,
  IconIoA,
  IconIoE,
  IconSecurityEngine,
  IconTopology,
  IconTrailflow
} from '@app/components/Icon/MainMenu'
import IconAttackPathAnalysis from '@app/components/Icon/MainMenu/IconAttackPathAnalysis'
import IconWeaknesses from '@app/components/Icon/MainMenu/IconWeaknesses'
import {
  IconAccountsLegacy,
  IconAttackPathLegacy,
  IconDashboardLegacy,
  IconHealthCheckLegacy,
  IconIdentitiesLegacy,
  IconIdentityExplorerLegacy,
  IconIoALegacy,
  IconIoELegacy,
  IconSystemLegacy,
  IconTopologyLegacy,
  IconTrailflowLegacy
} from '@app/components/Icon/MainMenuLegacy'
import { canAccessToAttackPath } from '@app/pages/AttackPath/permissions'
import { canAccessToDashbards } from '@app/pages/Dashboard/permissions'
import HealthCheckGlobalStatusConditioning from '@app/pages/HealthCheck/HealthCheckGlobalStatus/HealthCheckGlobalStatusConditioning'
import HealthCheckGlobalStatusIcon from '@app/pages/HealthCheck/HealthCheckGlobalStatus/HealthCheckGlobalStatusIcon'
import HealthCheckGlobalStatusLabel from '@app/pages/HealthCheck/HealthCheckGlobalStatus/HealthCheckGlobalStatusLabel'
import { canAccessToHealthCheck } from '@app/pages/HealthCheck/permissions'
import {
  canAccessToApa,
  canAccessToIdentities
} from '@app/pages/Identities/permissions'
import { canAccessToIdentityExplorer } from '@app/pages/IdentityExplorer/permissions'
import { canAccessToIoABoard } from '@app/pages/IoA/IoABoardPage/permissions'
import {
  canAccessToAdIoE,
  canAccessToIdentityIoE
} from '@app/pages/IoE/IoEBoardPage/permissions'
import { IoEBoardMenuItemKey } from '@app/pages/IoE/IoEBoardPage/types'
import type { IMainMenuEntry } from '@app/pages/MainMenu/types'
import { MainMenuKey } from '@app/pages/MainMenu/types'
import { canAccessToAccounts } from '@app/pages/Management/AccountsPage/permissions'
import { canAccessToSystem } from '@app/pages/Management/SystemPage/permissions'
import { canAccessToSecurityEngine } from '@app/pages/SecurityEngine/WeaknessInstancesPage/permissions'
import { canAccessToTopology } from '@app/pages/Topology/permissions'
import { canAccessToTrailFlow } from '@app/pages/TrailFlow/permissions'
import { canAccessToWeaknesses } from '@app/pages/Weaknesses/permissions'
import { AppRouteName } from '@app/routes'
import { filterFalsies } from '@libs/filterFalsies'
import { flatMap } from 'lodash'
import StoreMenu from './helpers/StoreMenu'
import { MenuEntryType } from './helpers/StoreMenu/types'
import StoreModal from './helpers/StoreModal'
import StoreBase from './StoreBase'
import type StoreRoot from './StoreRoot'
import type { IStoreOptions } from './types'

export default class StoreLayout extends StoreBase {
  public storeModalChangesNotSaved = new StoreModal(this.storeRoot)

  public storeMenuMainNavigation = new StoreMenu<IMainMenuEntry>(
    this.storeRoot,
    {}
  )

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Layout.MainMenu']
  })

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
  }

  /**
   * Initialize entries of the main navigation.
   */
  initializeMainNavigation(): this {
    const { storeDebug, storeRbac } = this.storeRoot.stores

    const apaAnalysisUrl =
      this.storeRoot.environment.tenablePlatformOrigin() + '/apa'

    const isDevFlag = storeDebug.isDevFlag()

    const mainMenuEntries: Map<IMainMenuEntry, IMainMenuEntry[]> = new Map([
      [
        {
          key: MainMenuKey.identity360Title,
          type: MenuEntryType.category,
          label: this.translate('Identity 360'),
          labelledBy: 'Identity360'
        },
        filterFalsies<IMainMenuEntry>([
          storeRbac.isUserGrantedTo(canAccessToIdentityExplorer) && {
            key: MainMenuKey.identityExplorer,
            type: MenuEntryType.link,
            label: this.translate('Identity Explorer'),
            labelledBy: 'Identity Explorer',
            routeDefinition: {
              routeName: AppRouteName.IdentityExplorer,
              parameters: {}
            },
            icon: IconIdentityExplorerLegacy
          },
          storeRbac.isUserGrantedTo(canAccessToIdentities) && {
            key: MainMenuKey.identities,
            type: MenuEntryType.link,
            label: this.translate('Identities'),
            labelledBy: 'Identities',
            routeDefinition: {
              routeName: AppRouteName.Gi_Identities,
              parameters: {}
            },
            icon: IconIdentities
          },
          storeRbac.isUserGrantedTo(canAccessToWeaknesses) && {
            key: MainMenuKey.weaknesses,
            type: MenuEntryType.link,
            label: this.translate('Weaknesses'),
            labelledBy: 'Weaknesses',
            routeDefinition: {
              routeName: AppRouteName.Gi_Weaknesses,
              parameters: {}
            },
            icon: IconWeaknesses
          },
          storeRbac.isUserGrantedTo(canAccessToApa) && {
            key: MainMenuKey.attackPathAnalysis,
            type: MenuEntryType.externalLink,
            label: this.translate('APA Analysis'),
            labelledBy: 'Attack path analysis',
            href: apaAnalysisUrl,
            icon: IconAttackPathAnalysis
          },
          storeRbac.isUserGrantedTo(canAccessToSecurityEngine) && {
            key: MainMenuKey.securityEngine,
            type: MenuEntryType.link,
            label: this.translate('Security Engine'),
            labelledBy: 'SecurityEngine',
            routeDefinition: {
              routeName: AppRouteName.SecurityEngine,
              parameters: {}
            },
            icon: IconSecurityEngine
          }
        ])
      ],
      [
        {
          key: MainMenuKey.activeDirectoryTitle,
          type: MenuEntryType.category,
          label: this.translate('Active Directory'),
          labelledBy: 'ActiveDirectory'
        },
        filterFalsies<IMainMenuEntry>([
          storeRbac.isUserGrantedTo(canAccessToDashbards) && {
            key: MainMenuKey.dashboard,
            type: MenuEntryType.link,
            label: this.translate('Dashboards'),
            labelledBy: 'Dashboards',
            routeDefinition: {
              routeName: AppRouteName.Dashboard,
              parameters: {}
            },
            icon: IconDashboard
          },
          storeRbac.isUserGrantedTo(canAccessToTrailFlow) && {
            key: MainMenuKey.trailflow,
            type: MenuEntryType.link,
            label: this.translate('Trail Flow'),
            labelledBy: 'Trail Flow',
            routeDefinition: {
              routeName: AppRouteName.TrailFlow,
              parameters: {}
            },
            icon: IconTrailflow
          },
          storeRbac.isUserGrantedTo(canAccessToAdIoE) && {
            key: IoEBoardMenuItemKey.ActiveDirectory,
            type: MenuEntryType.link,
            label: this.translate('Indicators of Exposure'),
            labelledBy: 'Indicators of Exposure',
            routeDefinition: {
              routeName: AppRouteName.IoE_Board_AD,
              parameters: {}
            },
            icon: IconIoE
          },
          storeRbac.isUserGrantedTo(canAccessToIoABoard) && {
            key: MainMenuKey.ioa,
            type: MenuEntryType.link,
            label: this.translate('Indicators of Attack'),
            labelledBy: 'Indicators of Attack',
            routeDefinition: {
              routeName: AppRouteName.IoA_Board,
              parameters: {}
            },
            icon: IconIoA
          },
          storeRbac.isUserGrantedTo(canAccessToTopology) && {
            key: MainMenuKey.topology,
            type: MenuEntryType.link,
            label: this.translate('Topology'),
            labelledBy: 'Topology',
            routeDefinition: {
              routeName: AppRouteName.Topology,
              parameters: {}
            },
            icon: IconTopology
          },
          storeRbac.isUserGrantedTo(canAccessToAttackPath) && {
            key: MainMenuKey.attackPath,
            type: MenuEntryType.link,
            label: this.translate('Attack path'),
            labelledBy: 'Attack path',
            routeDefinition: {
              routeName: AppRouteName.AttackPath,
              parameters: {}
            },
            icon: IconAttackPath
          }
        ])
      ],
      [
        {
          key: MainMenuKey.entraIdTitle,
          type: MenuEntryType.category,
          label: this.translate('Entra ID'),
          labelledBy: 'EntraId'
        },
        filterFalsies<IMainMenuEntry>([
          storeRbac.isUserGrantedTo(canAccessToIdentityIoE) && {
            key: IoEBoardMenuItemKey.AzureAD,
            type: MenuEntryType.link,
            label: this.translate('Indicators of Exposure'),
            labelledBy: 'Indicators of Exposure',
            routeDefinition: {
              routeName: AppRouteName.IoE_Board_MEID,
              parameters: {}
            },
            icon: IconIoE
          }
        ])
      ]
    ])

    if (isDevFlag) {
      mainMenuEntries.set(
        {
          key: MainMenuKey.debugTitle,
          type: MenuEntryType.category,
          label: this.translate('Debug'),
          labelledBy: 'Debug',
          icon: IconDashboard
        },
        [
          {
            key: MainMenuKey.apiInspector,
            type: MenuEntryType.link,
            label: 'API inspector',
            labelledBy: 'API Inspector',
            routeDefinition: {
              routeName: AppRouteName.Debug_APIInspector,
              parameters: {}
            },
            icon: IconDashboard
          }
        ]
      )
    }

    const mainMenuEntriesAsArray = flatMap(
      Array.from(mainMenuEntries.entries()).map(([titleEntry, menuEntries]) => {
        // don't display title if there is no menu entry
        if (!menuEntries.length) {
          return menuEntries
        }

        return [titleEntry, ...menuEntries]
      })
    )

    this.storeMenuMainNavigation.setMenuEntries(mainMenuEntriesAsArray)

    return this
  }

  /**
   * @deprecated To remove when the toggle kapteyn-left-navigation-ui is deleted
   * Initialize legacy entries of the main navigation.
   */
  initializeMainNavigationLegacy(): this {
    const { storeDebug, storeRbac } = this.storeRoot.stores

    const isDevFlag = storeDebug.isDevFlag()

    const mainMenuEntries: Map<IMainMenuEntry, IMainMenuEntry[]> = new Map([
      [
        {
          key: MainMenuKey.generalTitle,
          type: MenuEntryType.category,
          label: this.translate('General'),
          labelledBy: 'General'
        },
        filterFalsies<IMainMenuEntry>([
          storeRbac.isUserGrantedTo(canAccessToDashbards) && {
            key: MainMenuKey.dashboard,
            type: MenuEntryType.link,
            label: this.translate('Dashboards'),
            labelledBy: 'Dashboards',
            routeDefinition: {
              routeName: AppRouteName.Dashboard,
              parameters: {}
            },
            icon: IconDashboardLegacy
          },
          storeRbac.isUserGrantedTo(canAccessToIdentityExplorer) && {
            key: MainMenuKey.identityExplorer,
            type: MenuEntryType.link,
            label: this.translate('Identity Explorer'),
            labelledBy: 'Identity Explorer',
            routeDefinition: {
              routeName: AppRouteName.IdentityExplorer,
              parameters: {}
            },
            icon: IconIdentityExplorerLegacy
          },
          storeRbac.isUserGrantedTo(canAccessToIdentities) && {
            key: MainMenuKey.identities,
            type: MenuEntryType.link,
            label: this.translate('Identities'),
            labelledBy: 'Identities',
            routeDefinition: {
              routeName: AppRouteName.Gi_Identities,
              parameters: {}
            },
            icon: IconIdentitiesLegacy
          },
          storeRbac.isUserGrantedTo(canAccessToWeaknesses) && {
            key: MainMenuKey.weaknesses,
            type: MenuEntryType.link,
            label: this.translate('Weaknesses'),
            labelledBy: 'Weaknesses',
            routeDefinition: {
              routeName: AppRouteName.Gi_Weaknesses,
              parameters: {}
            },
            icon: IconIdentitiesLegacy
          }
        ])
      ],
      [
        {
          key: MainMenuKey.securityTitle,
          type: MenuEntryType.category,
          label: this.translate('Security Analytics'),
          labelledBy: 'Security Analytics'
        },
        filterFalsies([
          storeRbac.isUserGrantedTo(canAccessToTrailFlow) && {
            key: MainMenuKey.trailflow,
            type: MenuEntryType.link,
            label: this.translate('Trail Flow'),
            labelledBy: 'Trail Flow',
            routeDefinition: {
              routeName: AppRouteName.TrailFlow,
              parameters: {}
            },
            icon: IconTrailflowLegacy
          },
          storeRbac.isUserGrantedTo(canAccessToAdIoE) && {
            key: MainMenuKey.ioe,
            type: MenuEntryType.link,
            label: this.translate('Indicators of Exposure'),
            labelledBy: 'Indicators of Exposure',
            routeDefinition: {
              routeName: AppRouteName.IoE_Board,
              parameters: {}
            },
            icon: IconIoELegacy
          },
          storeRbac.isUserGrantedTo(canAccessToIoABoard) && {
            key: MainMenuKey.ioa,
            type: MenuEntryType.link,
            label: this.translate('Indicators of Attack'),
            labelledBy: 'Indicators of Attack',
            routeDefinition: {
              routeName: AppRouteName.IoA_Board,
              parameters: {}
            },
            icon: IconIoALegacy
          },
          storeRbac.isUserGrantedTo(canAccessToTopology) && {
            key: MainMenuKey.topology,
            type: MenuEntryType.link,
            label: this.translate('Topology'),
            labelledBy: 'Topology',
            routeDefinition: {
              routeName: AppRouteName.Topology,
              parameters: {}
            },
            icon: IconTopologyLegacy
          },
          storeRbac.isUserGrantedTo(canAccessToAttackPath) && {
            key: MainMenuKey.attackPath,
            type: MenuEntryType.link,
            label: this.translate('Attack path'),
            labelledBy: 'Attack path',
            routeDefinition: {
              routeName: AppRouteName.AttackPath,
              parameters: {}
            },
            icon: IconAttackPathLegacy
          }
        ])
      ],
      [
        {
          key: MainMenuKey.managementTitle,
          type: MenuEntryType.category,
          label: this.translate('Management'),
          labelledBy: 'Management'
        },
        filterFalsies([
          storeRbac.isUserGrantedTo(canAccessToAccounts) && {
            key: MainMenuKey.accounts,
            type: MenuEntryType.link,
            label: this.translate('Accounts'),
            labelledBy: 'Accounts',
            routeDefinition: {
              routeName: AppRouteName.Management_Accounts,
              parameters: {}
            },
            icon: IconAccountsLegacy
          },
          storeRbac.isUserGrantedTo(canAccessToSystem) && {
            key: MainMenuKey.system,
            type: MenuEntryType.link,
            label: this.translate('System'),
            labelledBy: 'System',
            routeDefinition: {
              routeName: AppRouteName.Management_System,
              parameters: {}
            },
            icon: IconSystemLegacy
          },
          storeRbac.isUserGrantedTo(canAccessToHealthCheck) && {
            key: MainMenuKey.healthCheck,
            type: MenuEntryType.link,
            label: this.translate('Health check'),
            label2: HealthCheckGlobalStatusLabel,
            conditioningIcon2Component: HealthCheckGlobalStatusConditioning,
            labelledBy: 'HealthCheck',
            routeDefinition: {
              routeName: AppRouteName.HealthCheck,
              parameters: {}
            },
            icon: IconHealthCheckLegacy,
            icon2: HealthCheckGlobalStatusIcon,
            bottom: true
          }
        ])
      ]
    ])

    if (isDevFlag) {
      mainMenuEntries.set(
        {
          key: MainMenuKey.debugTitle,
          type: MenuEntryType.category,
          label: this.translate('Debug'),
          labelledBy: 'Debug',
          icon: IconDashboardLegacy
        },
        filterFalsies([
          {
            key: MainMenuKey.apiInspector,
            type: MenuEntryType.link,
            label: 'API inspector',
            labelledBy: 'API Inspector',
            routeDefinition: {
              routeName: AppRouteName.Debug_APIInspector,
              parameters: {}
            },
            icon: IconDashboardLegacy
          }
        ])
      )
    }

    const mainMenuEntriesAsArray = flatMap(
      Array.from(mainMenuEntries.entries()).map(([titleEntry, menuEntries]) => {
        // don't display title if there is no menu entry
        if (!menuEntries.length) {
          return menuEntries
        }

        return [titleEntry, ...menuEntries]
      })
    )

    this.storeMenuMainNavigation.setMenuEntries(mainMenuEntriesAsArray)

    return this
  }

  /**
   * Compute TUL display status according various parameters.
   */
  isTulDisplayed(): boolean {
    const { storeAuthentication, storeFeatureFlags } = this.storeRoot.stores
    const { appRouter } = this.storeRoot

    const isTulEnabled = storeFeatureFlags.getFeatureFlagValue(
      Features.TENABLE_UNIVERSAL_LAYOUT
    )

    return (
      isTulEnabled &&
      storeAuthentication.isAuthenticated &&
      !document.location.href.includes('/pdf/') &&
      !appRouter.isCurrentPathnameIsStartingByOneOf([
        '/pdf',
        '/reports',
        '/auth/license'
      ])
    )
  }

  /**
   * Return the product name according various parameters.
   */
  getProductName(): string {
    const { storeFeatureFlags } = this.storeRoot.stores

    const useTenableIdentityExposureVisuals =
      storeFeatureFlags.getFeatureFlagValue(
        Features.TENABLE_IDENTITY_EXPOSURE_VISUALS
      )

    return useTenableIdentityExposureVisuals
      ? 'Tenable Identity Exposure' // (also named Tenable.IE sometimes)
      : 'Tenable.ad'
  }

  /**
   * Return the product name related to the Authentication.
   */
  getAuthenticationProductName(): string {
    const { storeAuthentication } = this.storeRoot.stores

    return storeAuthentication.isToneUser()
      ? 'Tenable.one'
      : this.getProductName()
  }
}
