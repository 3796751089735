import { ContainerFlex, ContainerFooter } from '@app/components/Container'
import { IconClose } from '@app/components/Icon'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useAppTranslator } from '@app/hooks'
import type StoreModal from '@app/stores/helpers/StoreModal'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { isDefined } from '@libs/isDefined'
import { Modal as AntdModal } from 'antd'
import type { ModalProps } from 'antd/lib/modal'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { onModalCloseIconClick } from './handlers'

const HeaderContainerFlex = styled(ContainerFlex)`
  padding-bottom: ${consts.paddingLarge};
`

const AntdModalCustom = styled(AntdModal)`
  .ant-modal-content {
    box-shadow: 0 10px 15px 0 rgba(104, 104, 156, 0.2);
    border-radius: ${consts.borderRadiusRedesign};
    padding: ${consts.paddingLarge} ${consts.paddingLarge} 0;
  }

  .ant-modal-body {
    padding: 0 0 ${consts.paddingXXL};
  }

  .ant-modal-footer {
    padding: ${consts.paddingLarge} 0;
    border-top: 1px solid
      ${({ theme }) => theme.tokens['modal/color/separator']};
  }

  .ant-modal-close {
    display: none;
  }
`

export interface IModalProps {
  className?: string
  style?: React.CSSProperties
  onOk?: () => void
  onCancel?: () => void
  modalProps: ModalProps
  storeModal: StoreModal<any>
  children?: React.ReactNode
}

const onOk = (storeModal: StoreModal<any>) => () => storeModal.hide()
const onCancel = (storeModal: StoreModal<any>) => () => storeModal.hide()

const Modal: React.FC<IModalProps> = props => {
  const theme = useDSTheme()
  const translate = useAppTranslator({ namespaces: ['Common.Buttons'] })

  const themeIsLegacy = isThemeLegacy(theme)

  if (themeIsLegacy) {
    return (
      <AntdModal
        className={props.className}
        style={props.style}
        open={props.storeModal.isVisible}
        onOk={onOk(props.storeModal)}
        onCancel={onCancel(props.storeModal)}
        closable={!props.storeModal.isUnclosable}
        maskClosable={!props.storeModal.isUnclosable}
        keyboard={!props.storeModal.isUnclosable}
        {...props.modalProps}
      >
        {props.children}
      </AntdModal>
    )
  }

  const maskStyle: React.CSSProperties = {
    backgroundColor: theme.tokens['modal/color/overlay']
  }

  const { title, ...modalProps } = props.modalProps

  let footer = modalProps.footer

  if (!isDefined(modalProps.footer) && !props.storeModal.isUnclosable) {
    footer = (
      <ContainerFooter variant="modal">
        <FormWrapperButton
          labelledBy="cancel"
          variant={ButtonVariant.secondary}
          size={ButtonSize.large}
          buttonProps={{
            onClick: modalProps.onCancel ?? onCancel(props.storeModal)
          }}
        >
          {modalProps.cancelText ?? translate('Cancel')}
        </FormWrapperButton>
        <FormWrapperButton
          labelledBy="submit"
          variant={ButtonVariant.primary}
          size={ButtonSize.large}
          buttonProps={{
            onClick: modalProps.onOk ?? onOk(props.storeModal)
          }}
        >
          {modalProps.okText ?? translate('OK')}
        </FormWrapperButton>
      </ContainerFooter>
    )
  }

  return (
    <AntdModalCustom
      className={props.className}
      style={props.style}
      open={props.storeModal.isVisible}
      onOk={onOk(props.storeModal)}
      onCancel={onCancel(props.storeModal)}
      closable={false}
      maskClosable={!props.storeModal.isUnclosable}
      keyboard={!props.storeModal.isUnclosable}
      {...modalProps}
      footer={footer}
      maskStyle={{ ...maskStyle, ...modalProps.maskStyle }}
    >
      {title && (
        <HeaderContainerFlex
          name="ModalHeader"
          justifyContent="space-between"
          alignItems="center"
          items={[
            <Label variant={LabelVariant.h2}>{title}</Label>,
            props.modalProps.closable && (
              <FormWrapperButton
                labelledBy="modal/close"
                buttonProps={{
                  onClick: onModalCloseIconClick(props.storeModal)
                }}
                icon={IconClose}
                iconSize={12}
                variant={ButtonVariant.colored}
                size={ButtonSize.small}
              />
            )
          ]}
        />
      )}
      {props.children}
    </AntdModalCustom>
  )
}

export default observer(Modal)
