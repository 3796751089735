import {
  ContainerFlex,
  ContainerHTMLContent,
  ContainerIcon
} from '@app/components-legacy/Container'
import { IconInfoCircleOutlined } from '@app/components-legacy/Icon/IconAntd'
import { LabelAlt } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { AuthProviderName } from '@server/graphql/typeDefs/types'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IUserCommonFormAuthenticationTypeProps {
  storeForm: StoreForm<any>
}

const UserCommonFormAuthenticationType: React.FC<
  IUserCommonFormAuthenticationTypeProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Login.ProviderTypes', 'Management.Accounts.Users']
  })

  const { storeAuthentication } = useStores()

  const authTooltipContent = translate(
    storeAuthentication.isToneUser()
      ? 'Tenable.ad operates as part of the Tenable.One platform. Click HERE to access user account settings'
      : 'A user can use various providers to authenticate on Tenable.ad',
    {
      transformMarkdown: true
    }
  )

  /**
   * Return the translation of the Provider if defined.
   */
  const getAuthenticationType = (): string => {
    const provider =
      props.storeForm.getFieldValueAsString<AuthProviderName>('provider')

    // could be undefined at page loading
    if (!provider) {
      return ''
    }

    if (storeAuthentication.isToneUser()) {
      return 'TENABLE ONE'
    }

    switch (provider) {
      case AuthProviderName.Tone:
      case AuthProviderName.Tenable:
        return AuthProviderName.Tenable.toUpperCase()
      case AuthProviderName.Ldap:
        return translate(AuthProviderName.Ldap.toUpperCase())
      case AuthProviderName.Saml:
        return translate(AuthProviderName.Saml.toUpperCase())
      default:
        assertUnreachableCase(provider)
    }
  }

  return (
    <ContainerFlex
      name="AuthenticationTypeLabel"
      alignItems="baseline"
      items={[
        <LabelAlt variant={LabelAltVariant.Bolded}>
          <Tooltip
            title={
              <ContainerHTMLContent>{authTooltipContent}</ContainerHTMLContent>
            }
          >
            <>{getAuthenticationType()}</>
          </Tooltip>
        </LabelAlt>,

        <ContainerIcon
          labelledBy="userInfo"
          iconComponent={IconInfoCircleOutlined}
          tooltipProps={{
            title: (
              <ContainerHTMLContent>{authTooltipContent}</ContainerHTMLContent>
            )
          }}
        />
      ]}
      spaced
      spaceWidth="verySmall"
    />
  )
}

export default observer(UserCommonFormAuthenticationType)
