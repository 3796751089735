import {
  ContainerHTMLContent,
  ContainerHVCentered
} from '@app/components-legacy/Container'
import { IconNoData } from '@app/components-legacy/Icon'
import { useAppTranslator } from '@app/hooks'
import { buildVariants } from '@design-system/libs/buildVariants'
import * as React from 'react'
import styled from 'styled-components'
import CreateReportButton from './CreateReportButton'

interface IConfigurationReportsNoDataProps {}

const StyledContainerHTMLContent = styled(ContainerHTMLContent)(props => {
  return buildVariants(props)
    .css({
      textAlign: 'center'
    })
    .end()
})

const ConfigurationReportsNoData: React.FC<
  IConfigurationReportsNoDataProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.ReportingCenter']
  })

  return (
    <ContainerHVCentered>
      <IconNoData
        text={
          <StyledContainerHTMLContent>
            {translate('Configure your first report', {
              transformMarkdown: true
            })}
          </StyledContainerHTMLContent>
        }
        imageName="reports-placeholder"
      />
      <CreateReportButton />
    </ContainerHVCentered>
  )
}

export default ConfigurationReportsNoData
