import { ErrorMessage } from '@app/components/Error'
import type StoreWizard from '@app/stores/helpers/StoreWizard'
import type { WizardRuleOrRuleGroup } from '@app/stores/helpers/StoreWizard/types'
import {
  isWizardRule,
  isWizardRuleGroup
} from '@app/stores/helpers/StoreWizard/utils'
import type { Combinator } from '@libs/Expression/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import RuleFieldset from './RuleFieldset'
import RuleGroupFieldset from './RuleGroupFieldset'

interface IRuleOrRuleGroupProps {
  index: number
  ruleGroupPath: number[]
  enableIsDeviantSwitch: boolean
  parentCombinator: Combinator
  ruleOrRuleGroup: WizardRuleOrRuleGroup
  storeWizard: StoreWizard
}

const RuleOrRuleGroup: React.FC<IRuleOrRuleGroupProps> = props => {
  if (isWizardRule(props.ruleOrRuleGroup)) {
    return (
      <RuleFieldset
        index={props.index}
        ruleGroupPath={props.ruleGroupPath}
        rule={props.ruleOrRuleGroup}
        parentCombinator={props.parentCombinator}
        storeWizard={props.storeWizard}
      />
    )
  }

  if (isWizardRuleGroup(props.ruleOrRuleGroup)) {
    // This is where the rulePath & ruleGroupPath are built
    const newRuleGroupPath = props.ruleGroupPath.concat(props.index)

    return (
      <RuleGroupFieldset
        enableIsDeviantSwitch={props.enableIsDeviantSwitch}
        ruleGroupPath={newRuleGroupPath}
        ruleGroup={props.ruleOrRuleGroup}
        storeWizard={props.storeWizard}
      />
    )
  }

  return <ErrorMessage />
}

export default observer(RuleOrRuleGroup)
