import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  ContainerContent,
  ContainerFooter,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import {
  Drawer,
  DrawerCancelButton,
  DrawerContext,
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canRecrawlDirectory } from '../../permissions'
import { onDirectoryRecrawlValidate } from './handlers'

export interface IDrawerActionRecrawlDirectoryProps {}

const DrawerActionRecrawlDirectory: React.FC<
  IDrawerActionRecrawlDirectoryProps
> = () => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.System.Directories']
  })

  const { storeManagementDirectories } = useStores()
  const { storeRecrawlDrawer } = storeManagementDirectories

  const directoryDataRow = storeRecrawlDrawer.getDataValue('directoryDataRow')

  const renderContent = () => {
    if (!directoryDataRow) {
      return null
    }

    const isLoading =
      storeManagementDirectories.storeRecrawlFlags.flags.isLoading

    return (
      <FormWrapper
        name="directoryRecrawl"
        onSubmit={onDirectoryRecrawlValidate(storeManagementDirectories)(
          directoryDataRow
        )}
      >
        <ContainerContent
          flags={storeManagementDirectories.storeFlags.flags}
          spinner={<SpinnerInline />}
        >
          <ContainerHTMLContent>
            {translate('You will recrawl the domain X', {
              transformMarkdown: true,
              interpolations: {
                name: directoryDataRow.name
              }
            })}
          </ContainerHTMLContent>

          <ContainerHTMLContent>
            {translate('Expected recrawl impacts', {
              transformMarkdown: true
            })}
          </ContainerHTMLContent>
        </ContainerContent>

        <Portal name={PlaceHolderName.drawerFooter}>
          <ContainerFooter>
            <DrawerCancelButton />

            <FormWrapperButtonSubmit loading={isLoading}>
              {translate('Confirm')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </FormWrapper>
    )
  }

  return (
    <DrawerContext.Provider value={{ storeDrawer: storeRecrawlDrawer }}>
      <Drawer>
        <DrawerHorizontalContainer>
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: <DrawerTitle>{translate('Recrawl a domain')}</DrawerTitle>,
              content: <ContainerContent>{renderContent()}</ContainerContent>,
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canRecrawlDirectory(
              directoryDataRow?.id || null
            )}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerActionRecrawlDirectory)
