import type { EntityChecker } from '@app/entities'
import { DashboardTemplate } from '@app/pages/Dashboard/GridPage/DrawerAddDashboard/types'
import { WidgetCommonFormFieldName } from '@app/pages/Dashboard/WidgetAddPage/types'
import { filterFalsies } from '@libs/filterFalsies'
import type {
  MutationCreateDashboardWidget,
  MutationSetDashboardWidgetOptions
} from '@server/graphql/mutations/dashboard'
import {
  mutationCreateDashboardWidget,
  mutationSetDashboardWidgetOptions
} from '@server/graphql/mutations/dashboard'
import type {
  CreateDashboardWidgetMutationArgs,
  InputCreateDashboard,
  InputSetDashboardWidgetOptions,
  SetDashboardWidgetOptionsMutationArgs
} from '@server/graphql/typeDefs/types'
import {
  Criticity,
  DashboardWidgetDataOptionType,
  DashboardWidgetType
} from '@server/graphql/typeDefs/types'
import type { StoreRoot } from '..'
import type StoreWidgetsManagement from './StoreWidgetsManagement'
import type { IWidgetAttributes, IWidgetCoordinates } from './types'

export const getComplianceBarChartWidget = (
  storeRoot: StoreRoot,
  storeWidgetsManagement: StoreWidgetsManagement,
  args: CreateDashboardWidgetMutationArgs,
  dashboardId: number
): Promise<void> => {
  return storeRoot
    .getGQLRequestor(storeWidgetsManagement.options.instanceName)
    .query<MutationCreateDashboardWidget>(mutationCreateDashboardWidget, args) // set options for the created widget
    .then(dataDashboardWidget => {
      const { id: dashboardWidgetId } =
        dataDashboardWidget.createDashboardWidget

      const { storeIoE } = storeWidgetsManagement.storeRoot.stores

      const dashboardWidgetOptions: InputSetDashboardWidgetOptions = {
        instanceName: storeWidgetsManagement.instanceName,
        dashboardId,
        dashboardWidgetId,
        type: DashboardWidgetType.BarChart,
        series: [
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: Array.from(storeIoE.checkers.values()).map(checker =>
                checker.getPropertyAsNumber('id')
              ),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              duration: 0
            },
            displayOptions: {
              type: DashboardWidgetType.BarChart,
              label: 'Total'
            }
          },
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: storeIoE
                .getCheckersPerCriticity(storeIoE.checkers, Criticity.Critical)
                .map(checker => checker.getPropertyAsNumber('id')),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              duration: 0
            },
            displayOptions: {
              type: DashboardWidgetType.BarChart,
              label: 'Critical'
            }
          },
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: storeIoE
                .getCheckersPerCriticity(storeIoE.checkers, Criticity.High)
                .map(checker => checker.getPropertyAsNumber('id')),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              duration: 0
            },
            displayOptions: {
              type: DashboardWidgetType.BarChart,
              label: 'High'
            }
          },
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: storeIoE
                .getCheckersPerCriticity(storeIoE.checkers, Criticity.Medium)
                .map(checker => checker.getPropertyAsNumber('id')),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              duration: 0
            },
            displayOptions: {
              type: DashboardWidgetType.BarChart,
              label: 'Medium'
            }
          },
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: storeIoE
                .getCheckersPerCriticity(storeIoE.checkers, Criticity.Low)
                .map(checker => checker.getPropertyAsNumber('id')),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              duration: 0
            },
            displayOptions: {
              type: DashboardWidgetType.BarChart,
              label: 'Low'
            }
          }
        ]
      }

      if (!dashboardWidgetOptions) {
        throw new Error('Missing widget options')
      }

      const args: SetDashboardWidgetOptionsMutationArgs = {
        dashboardWidgetOptions
      }

      return storeRoot
        .getGQLRequestor(storeWidgetsManagement.options.instanceName)
        .query<MutationSetDashboardWidgetOptions>(
          mutationSetDashboardWidgetOptions,
          args
        )
    })
    .then(results => {
      if (!results.setDashboardWidgetOptions) {
        throw new Error('An error has occurred when creating the widget')
      }

      storeWidgetsManagement.reset()

      storeRoot.stores.storeMessages.success(
        storeWidgetsManagement.translate('Widget added'),
        {
          labelledBy: 'widgetAdded'
        }
      )

      storeWidgetsManagement.storeFlags.success()
    })
}

export const getComplianceLineChartWidget = (
  storeRoot: StoreRoot,
  storeWidgetsManagement: StoreWidgetsManagement,
  args: CreateDashboardWidgetMutationArgs,
  dashboardId: number
): Promise<void> => {
  return storeRoot
    .getGQLRequestor(storeWidgetsManagement.options.instanceName)
    .query<MutationCreateDashboardWidget>(mutationCreateDashboardWidget, args) // set options for the created widget
    .then(dataDashboardWidget => {
      const { id: dashboardWidgetId } =
        dataDashboardWidget.createDashboardWidget

      const { storeIoE } = storeRoot.stores

      const dashboardWidgetOptions: InputSetDashboardWidgetOptions = {
        instanceName: storeWidgetsManagement.instanceName,
        dashboardId,
        dashboardWidgetId,
        type: DashboardWidgetType.LineChart,
        series: [
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: Array.from(storeIoE.checkers.values()).map(checker =>
                checker.getPropertyAsNumber('id')
              ),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              // 1 month duration
              duration: 2592000
            },
            displayOptions: {
              type: DashboardWidgetType.LineChart,
              label: 'Total'
            }
          },
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: storeIoE
                .getCheckersPerCriticity(storeIoE.checkers, Criticity.Critical)
                .map(checker => checker.getPropertyAsNumber('id')),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              // 1 month duration
              duration: 2592000
            },
            displayOptions: {
              type: DashboardWidgetType.LineChart,
              label: 'Critical'
            }
          },
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: storeIoE
                .getCheckersPerCriticity(storeIoE.checkers, Criticity.High)
                .map(checker => checker.getPropertyAsNumber('id')),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              // 1 month duration
              duration: 2592000
            },
            displayOptions: {
              type: DashboardWidgetType.LineChart,
              label: 'High'
            }
          },
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: storeIoE
                .getCheckersPerCriticity(storeIoE.checkers, Criticity.Medium)
                .map(checker => checker.getPropertyAsNumber('id')),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              // 1 month duration
              duration: 2592000
            },
            displayOptions: {
              type: DashboardWidgetType.LineChart,
              label: 'Medium'
            }
          },
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: storeIoE
                .getCheckersPerCriticity(storeIoE.checkers, Criticity.Low)
                .map(checker => checker.getPropertyAsNumber('id')),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              // 1 month duration
              duration: 2592000
            },
            displayOptions: {
              type: DashboardWidgetType.LineChart,
              label: 'Low'
            }
          }
        ]
      }

      if (!dashboardWidgetOptions) {
        throw new Error('Missing widget options')
      }

      const args: SetDashboardWidgetOptionsMutationArgs = {
        dashboardWidgetOptions
      }

      return storeRoot
        .getGQLRequestor(storeWidgetsManagement.options.instanceName)
        .query<MutationSetDashboardWidgetOptions>(
          mutationSetDashboardWidgetOptions,
          args
        )
    })
    .then(results => {
      if (!results.setDashboardWidgetOptions) {
        throw new Error('An error has occurred when creating the widget')
      }

      storeWidgetsManagement.reset()

      storeRoot.stores.storeMessages.success(
        storeWidgetsManagement.translate('Widget added'),
        {
          labelledBy: 'widgetAdded'
        }
      )

      storeWidgetsManagement.storeFlags.success()
    })
}

export const getComplianceTotalScoreNumberWidget = (
  storeRoot: StoreRoot,
  storeWidgetsManagement: StoreWidgetsManagement,
  args: CreateDashboardWidgetMutationArgs,
  dashboardId: number
): Promise<void> => {
  return storeRoot
    .getGQLRequestor(storeWidgetsManagement.options.instanceName)
    .query<MutationCreateDashboardWidget>(mutationCreateDashboardWidget, args) // set options for the created widget
    .then(dataDashboardWidget => {
      const { id: dashboardWidgetId } =
        dataDashboardWidget.createDashboardWidget

      const { storeIoE } = storeRoot.stores

      const dashboardWidgetOptions: InputSetDashboardWidgetOptions = {
        instanceName: storeWidgetsManagement.instanceName,
        dashboardId,
        dashboardWidgetId,
        type: DashboardWidgetType.BigNumber,
        series: [
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: Array.from(storeIoE.checkers.values()).map(checker =>
                checker.getPropertyAsNumber('id')
              ),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              duration: 0
            },
            displayOptions: {
              type: DashboardWidgetType.BigNumber
            }
          }
        ]
      }

      if (!dashboardWidgetOptions) {
        throw new Error('Missing widget options')
      }

      const args: SetDashboardWidgetOptionsMutationArgs = {
        dashboardWidgetOptions
      }

      return storeRoot
        .getGQLRequestor(storeWidgetsManagement.options.instanceName)
        .query<MutationSetDashboardWidgetOptions>(
          mutationSetDashboardWidgetOptions,
          args
        )
    })
    .then(results => {
      if (!results.setDashboardWidgetOptions) {
        throw new Error('An error has occurred when creating the widget')
      }

      storeWidgetsManagement.reset()

      storeRoot.stores.storeMessages.success(
        storeWidgetsManagement.translate('Widget added'),
        {
          labelledBy: 'widgetAdded'
        }
      )

      storeWidgetsManagement.storeFlags.success()
    })
}

export const getDeviancesCounterPerCriticity = (
  storeRoot: StoreRoot,
  storeWidgetsManagement: StoreWidgetsManagement,
  dashboardId: number,
  widgetCoordinates: IWidgetCoordinates,
  dashboard: InputCreateDashboard
): Array<Promise<void>> => {
  const criticitiesCounter = [
    {
      criticity: Criticity.Critical,
      title: 'Critical deviance count'
    },
    {
      criticity: Criticity.High,
      title: 'High deviance count'
    },
    {
      criticity: Criticity.Medium,
      title: 'Medium deviance count'
    },
    {
      criticity: Criticity.Low,
      title: 'Low deviance count'
    }
  ]

  const positionsX = [0, 2, 4, 6]
  const positionY = 4
  let counterX = 0

  const widgetsCounterCriticities = criticitiesCounter.map(criticityCounter => {
    widgetCoordinates.posX = positionsX[counterX]
    widgetCoordinates.posY = positionY

    counterX++

    const dashboardWidget =
      storeWidgetsManagement.getDashboardWidgetVariablesForPredefinedCreation(
        widgetCoordinates,
        storeWidgetsManagement.translate(criticityCounter.title),
        dashboard.instanceName,
        dashboardId
      )

    const args: CreateDashboardWidgetMutationArgs = {
      dashboardWidget
    }

    const { storeIoE } = storeRoot.stores

    return storeRoot
      .getGQLRequestor(storeWidgetsManagement.options.instanceName)
      .query<MutationCreateDashboardWidget>(mutationCreateDashboardWidget, args) // set options for the created widget
      .then(dataDashboardWidget => {
        const { id: dashboardWidgetId } =
          dataDashboardWidget.createDashboardWidget

        const dashboardWidgetOptions: InputSetDashboardWidgetOptions = {
          instanceName: storeWidgetsManagement.instanceName,
          dashboardId,
          dashboardWidgetId,
          type: DashboardWidgetType.BigNumber,
          series: [
            {
              dataOptionsDeviance: {
                type: DashboardWidgetDataOptionType.Deviance,
                checkerIds: storeIoE
                  .getCheckersPerCriticity(
                    storeIoE.checkers,
                    criticityCounter.criticity
                  )
                  .map(checker => checker.getPropertyAsNumber('id')),
                directoryIds: [],
                // not yet implemented in API
                reasonIds: [],
                // FIXME Use real profileId when supported in API
                profileId: 1,
                duration: 0
              },
              displayOptions: {
                type: DashboardWidgetType.BigNumber
              }
            }
          ]
        }

        if (!dashboardWidgetOptions) {
          throw new Error('Missing widget options')
        }

        const args: SetDashboardWidgetOptionsMutationArgs = {
          dashboardWidgetOptions
        }

        return storeRoot
          .getGQLRequestor(storeWidgetsManagement.options.instanceName)
          .query<MutationSetDashboardWidgetOptions>(
            mutationSetDashboardWidgetOptions,
            args
          )
      })
      .then(results => {
        if (!results.setDashboardWidgetOptions) {
          throw new Error('An error has occurred when creating the widget')
        }

        storeWidgetsManagement.reset()

        storeRoot.stores.storeMessages.success(
          storeWidgetsManagement.translate('Widget added'),
          {
            labelledBy: 'widgetAdded'
          }
        )

        storeWidgetsManagement.storeFlags.success()
      })
  })

  return widgetsCounterCriticities
}

export const getDeviancesCounterPerRisk = (
  storeRoot: StoreRoot,
  storeWidgetsManagement: StoreWidgetsManagement,
  dashboardId: number,
  widgetCoordinates: IWidgetCoordinates,
  dashboard: InputCreateDashboard,
  template: DashboardTemplate
): Array<Promise<void>> => {
  const risksCodeNames =
    template === DashboardTemplate.adRisk360
      ? [
          'C-UNCONST-DELEG',
          'C-DC-ACCESS-CONSISTENCY',
          'C-PASSWORD-POLICY',
          'C-PASSWORD-NOT-REQUIRED',
          'C-DANG-PRIMGROUPID',
          'C-CLEARTEXT-PASSWORD',
          'C-SLEEPING-ACCOUNTS',
          'C-ADMINCOUNT-ACCOUNT-PROPS',
          'C-USERS-CAN-JOIN-COMPUTERS',
          'C-ROOTOBJECTS-SD-CONSISTENCY',
          'C-REVER-PWD-GPO'
        ]
      : [
          'C-NATIVE-ADM-GROUP-MEMBERS',
          'C-PROTECTED-USERS-GROUP-UNUSED',
          'C-ADMINCOUNT-ACCOUNT-PROPS',
          'C-ADM-ACC-USAGE',
          'C-LAPS-UNSECURE-CONFIG'
        ]

  const risks = filterFalsies(
    risksCodeNames.map(codeName => {
      const checker = storeRoot.stores.storeIoE.checkers.get(codeName)

      if (!checker) {
        return null
      }

      return {
        title: checker.getPropertyAsString('name'),
        checkerId: checker.getPropertyAsNumber('id')
      }
    })
  )

  const positionsX = [0, 2, 4, 6]
  const positionsY = [0, 2, 4]
  let counterX = 0
  let counterY = 0

  const widgetsCounterRisks = risks.map(risk => {
    widgetCoordinates.posX = positionsX[counterX]
    widgetCoordinates.posY = positionsY[counterY]

    // we want 4 items per line
    if (counterX === 3) {
      counterX = -1
      counterY++
    }

    counterX++

    const dashboardWidget =
      storeWidgetsManagement.getDashboardWidgetVariablesForPredefinedCreation(
        widgetCoordinates,
        risk.title || '',
        dashboard.instanceName,
        dashboardId
      )

    const args: CreateDashboardWidgetMutationArgs = {
      dashboardWidget
    }

    return storeRoot
      .getGQLRequestor(storeWidgetsManagement.options.instanceName)
      .query<MutationCreateDashboardWidget>(mutationCreateDashboardWidget, args) // set options for the created widget
      .then(dataDashboardWidget => {
        const { id: dashboardWidgetId } =
          dataDashboardWidget.createDashboardWidget

        const dashboardWidgetOptions: InputSetDashboardWidgetOptions = {
          instanceName: storeWidgetsManagement.instanceName,
          dashboardId,
          dashboardWidgetId,
          type: DashboardWidgetType.BigNumber,
          series: [
            {
              dataOptionsDeviance: {
                type: DashboardWidgetDataOptionType.Deviance,
                checkerIds: [risk.checkerId],
                directoryIds:
                  storeWidgetsManagement.storeInfrastructures.getSelectedDirectoryIds(),
                // not yet implemented in API
                reasonIds: [],
                // FIXME Use real profileId when supported in API
                // https://github.com/AlsidOfficial/DSC-Cassiopeia/issues/2337
                profileId: 1,
                // same duration for all series
                duration:
                  storeWidgetsManagement.storeForm.getFieldValueAsNumber(
                    WidgetCommonFormFieldName.dataOptionsDuration
                  )
              },
              displayOptions: {
                type: DashboardWidgetType.BigNumber
              }
            }
          ]
        }

        if (!dashboardWidgetOptions) {
          throw new Error('Missing widget options')
        }

        const args: SetDashboardWidgetOptionsMutationArgs = {
          dashboardWidgetOptions
        }

        return storeRoot
          .getGQLRequestor(storeWidgetsManagement.options.instanceName)
          .query<MutationSetDashboardWidgetOptions>(
            mutationSetDashboardWidgetOptions,
            args
          )
      })
      .then(results => {
        if (!results.setDashboardWidgetOptions) {
          throw new Error('An error has occurred when creating the widget')
        }

        storeWidgetsManagement.reset()

        storeRoot.stores.storeMessages.success(
          storeWidgetsManagement.translate('Widget added'),
          {
            labelledBy: 'widgetAdded'
          }
        )

        storeWidgetsManagement.storeFlags.success()
      })
  })

  return widgetsCounterRisks
}

export const getPasswordDeviancesOnUserAccounts = (
  storeRoot: StoreRoot,
  storeWidgetsManagement: StoreWidgetsManagement,
  dashboardId: number,
  args: CreateDashboardWidgetMutationArgs
): Promise<void> => {
  const lineChartCheckerCodenames = [
    'C-PASSWORD-NOT-REQUIRED', // Account with Possible Empty Password
    'C-USERS-REVER-PWDS', // Reversible Passwords
    'C-REVER-PWD-GPO', // Reversible Passwords in GPO
    'C-PASSWORD-DONT-EXPIRE', // Accounts With Never Expiring Passwords
    'C-USER-PASSWORD', // User Account Using Old Password
    'C-PASSWORD-POLICY', // Application of Weak Password Policies on Users
    'C-KRBTGT-PASSWORD', // Last Password Change on KRBTGT account
    'C-AAD-SSO-PASSWORD', // Last Change of the Microsoft Entra SSO Account Password
    'C-CLEARTEXT-PASSWORD' // Potential Clear-Text Password
  ]
  const lineChartCheckersMap = new Map<string, EntityChecker>()
  lineChartCheckerCodenames.forEach(codeName => {
    const checker = storeRoot.stores.storeIoE.checkers.get(codeName)
    if (checker) {
      lineChartCheckersMap.set(codeName, checker)
    }
  })
  return storeRoot
    .getGQLRequestor(storeWidgetsManagement.options.instanceName)
    .query<MutationCreateDashboardWidget>(mutationCreateDashboardWidget, args) // set options for the created widget
    .then(dataDashboardWidget => {
      const { id: dashboardWidgetId } =
        dataDashboardWidget.createDashboardWidget

      const { storeIoE } = storeRoot.stores

      const dashboardWidgetOptions: InputSetDashboardWidgetOptions = {
        instanceName: storeWidgetsManagement.options.instanceName,
        dashboardId,
        dashboardWidgetId,
        type: DashboardWidgetType.LineChart,
        series: [
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: Array.from(lineChartCheckersMap.values()).map(
                checker => checker.getPropertyAsNumber('id')
              ),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              // 1 month duration
              duration: 2592000
            },
            displayOptions: {
              type: DashboardWidgetType.LineChart,
              label: storeWidgetsManagement.translate('Total')
            }
          },
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: storeIoE
                .getCheckersPerCriticity(
                  lineChartCheckersMap,
                  Criticity.Critical
                )
                .map(checker => checker.getPropertyAsNumber('id')),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              // 1 month duration
              duration: 2592000
            },
            displayOptions: {
              type: DashboardWidgetType.LineChart,
              label: storeWidgetsManagement.translate('Critical')
            }
          },
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: storeIoE
                .getCheckersPerCriticity(lineChartCheckersMap, Criticity.High)
                .map(checker => checker.getPropertyAsNumber('id')),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              // 1 month duration
              duration: 2592000
            },
            displayOptions: {
              type: DashboardWidgetType.LineChart,
              label: storeWidgetsManagement.translate('High')
            }
          },
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: storeIoE
                .getCheckersPerCriticity(lineChartCheckersMap, Criticity.Medium)
                .map(checker => checker.getPropertyAsNumber('id')),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              // 1 month duration
              duration: 2592000
            },
            displayOptions: {
              type: DashboardWidgetType.LineChart,
              label: storeWidgetsManagement.translate('Medium')
            }
          },
          {
            dataOptionsComplianceScore: {
              type: DashboardWidgetDataOptionType.ComplianceScore,
              checkerIds: storeIoE
                .getCheckersPerCriticity(lineChartCheckersMap, Criticity.Low)
                .map(checker => checker.getPropertyAsNumber('id')),
              directoryIds: [],
              // not yet implemented in API
              reasonIds: [],
              // FIXME Use real profileId when supported in API
              profileId: 1,
              // 1 month duration
              duration: 2592000
            },
            displayOptions: {
              type: DashboardWidgetType.LineChart,
              label: storeWidgetsManagement.translate('Low')
            }
          }
        ]
      }

      if (!dashboardWidgetOptions) {
        throw new Error('Missing widget options')
      }

      const args: SetDashboardWidgetOptionsMutationArgs = {
        dashboardWidgetOptions
      }

      return storeRoot
        .getGQLRequestor(storeWidgetsManagement.options.instanceName)
        .query<MutationSetDashboardWidgetOptions>(
          mutationSetDashboardWidgetOptions,
          args
        )
    })
    .then(results => {
      if (!results.setDashboardWidgetOptions) {
        throw new Error('An error has occurred when creating the widget')
      }

      storeWidgetsManagement.reset()

      storeRoot.stores.storeMessages.success(
        storeWidgetsManagement.translate('Widget added'),
        {
          labelledBy: 'widgetAdded'
        }
      )

      storeWidgetsManagement.storeFlags.success()
    })
}

export const getPasswordCounterWidgets = (
  storeRoot: StoreRoot,
  storeWidgetsManagement: StoreWidgetsManagement,
  dashboardId: number,
  widgetCoordinates: IWidgetCoordinates,
  dashboard: InputCreateDashboard
): Array<Promise<void>> => {
  const counterWidgets: IWidgetAttributes[] = [
    {
      codeNames: ['C-PASSWORD-NOT-REQUIRED'],
      widgetName: storeWidgetsManagement.translate('Potential Empty Passwords')
    },
    {
      codeNames: ['C-REVER-PWD-GPO', 'C-USERS-REVER-PWDS'],
      widgetName: storeWidgetsManagement.translate('Reversible passwords')
    },
    {
      codeNames: ['C-PASSWORD-DONT-EXPIRE'],
      widgetName: storeWidgetsManagement.translate(
        'Accounts with non-expiring passwords'
      )
    },
    {
      codeNames: ['C-USER-PASSWORD'],
      widgetName: storeWidgetsManagement.translate(
        'User accounts with an old password'
      )
    }
  ]
  let posX = 0
  const widgetRisks = counterWidgets.map(widgetAttributes => {
    widgetCoordinates.posX = posX
    widgetCoordinates.posY = 2
    posX += 2

    const dashboardWidget =
      storeWidgetsManagement.getDashboardWidgetVariablesForPredefinedCreation(
        widgetCoordinates,
        widgetAttributes.widgetName,
        dashboard.instanceName,
        dashboardId
      )
    const args: CreateDashboardWidgetMutationArgs = {
      dashboardWidget
    }
    const checkerIds = filterFalsies(
      widgetAttributes.codeNames.map(codeName => {
        const checker = storeRoot.stores.storeIoE.checkers.get(codeName)
        if (!checker) {
          return
        }
        return checker.getPropertyAsNumber('id')
      })
    )
    return storeWidgetsManagement.storeRoot
      .getGQLRequestor(storeWidgetsManagement.options.instanceName)
      .query<MutationCreateDashboardWidget>(mutationCreateDashboardWidget, args) // set options for the created widget
      .then(dataDashboardWidget => {
        const { id: dashboardWidgetId } =
          dataDashboardWidget.createDashboardWidget

        const dashboardWidgetOptions: InputSetDashboardWidgetOptions = {
          instanceName: storeWidgetsManagement.instanceName,
          dashboardId,
          dashboardWidgetId,
          type: DashboardWidgetType.BigNumber,
          series: [
            {
              dataOptionsComplianceScore: {
                type: DashboardWidgetDataOptionType.Deviance,
                checkerIds: checkerIds,
                directoryIds: [],
                // not yet implemented in API
                reasonIds: [],
                // FIXME Use real profileId when supported in API
                profileId: 1,
                // 1 month duration
                duration: 2592000
              },
              displayOptions: {
                type: DashboardWidgetType.BigNumber,
                label: storeWidgetsManagement.translate(
                  widgetAttributes.widgetName
                )
              }
            }
          ]
        }

        if (!dashboardWidgetOptions) {
          throw new Error('Missing widget options')
        }

        const args: SetDashboardWidgetOptionsMutationArgs = {
          dashboardWidgetOptions
        }

        return storeRoot
          .getGQLRequestor(storeWidgetsManagement.options.instanceName)
          .query<MutationSetDashboardWidgetOptions>(
            mutationSetDashboardWidgetOptions,
            args
          )
      })
      .then(results => {
        if (!results.setDashboardWidgetOptions) {
          throw new Error('An error has occurred when creating the widget')
        }

        storeWidgetsManagement.reset()

        storeRoot.stores.storeMessages.success(
          storeWidgetsManagement.translate('Widget added'),
          {
            labelledBy: 'widgetAdded'
          }
        )

        storeWidgetsManagement.storeFlags.success()
      })
  })

  return widgetRisks
}

export const getUsersScoreNumberWidget = (
  storeRoot: StoreRoot,
  storeWidgetsManagement: StoreWidgetsManagement,
  args: CreateDashboardWidgetMutationArgs,
  dashboardId: number,
  allUsers: boolean,
  active?: boolean
): Promise<void> => {
  return storeRoot
    .getGQLRequestor(storeWidgetsManagement.options.instanceName)
    .query<MutationCreateDashboardWidget>(mutationCreateDashboardWidget, args) // set options for the created widget
    .then(dataDashboardWidget => {
      const { id: dashboardWidgetId } =
        dataDashboardWidget.createDashboardWidget

      const dashboardWidgetOptions: InputSetDashboardWidgetOptions = {
        instanceName: storeWidgetsManagement.instanceName,
        dashboardId,
        dashboardWidgetId,
        type: DashboardWidgetType.BigNumber,
        series: [
          allUsers
            ? {
                dataOptionsUser: {
                  type: DashboardWidgetDataOptionType.User,
                  directoryIds: [],
                  duration: 0
                },
                displayOptions: {
                  type: DashboardWidgetType.BigNumber
                }
              }
            : {
                dataOptionsUser: {
                  type: DashboardWidgetDataOptionType.User,
                  directoryIds: [],
                  duration: 0,
                  active: active
                },
                displayOptions: {
                  type: DashboardWidgetType.BigNumber
                }
              }
        ]
      }

      if (!dashboardWidgetOptions) {
        throw new Error('Missing widget options')
      }

      const args: SetDashboardWidgetOptionsMutationArgs = {
        dashboardWidgetOptions
      }

      return storeRoot
        .getGQLRequestor(storeWidgetsManagement.options.instanceName)
        .query<MutationSetDashboardWidgetOptions>(
          mutationSetDashboardWidgetOptions,
          args
        )
    })
    .then(results => {
      if (!results.setDashboardWidgetOptions) {
        throw new Error('An error has occurred when creating the widget')
      }

      storeWidgetsManagement.reset()

      storeRoot.stores.storeMessages.success(
        storeWidgetsManagement.translate('Widget added'),
        {
          labelledBy: 'widgetAdded'
        }
      )

      storeWidgetsManagement.storeFlags.success()
    })
}

export const getDevianceNumberWidgetFromChecker = (
  storeRoot: StoreRoot,
  storeWidgetsManagement: StoreWidgetsManagement,
  args: CreateDashboardWidgetMutationArgs,
  dashboardId: number,
  checkerCodeName: string
): Promise<void> => {
  const checker = storeRoot.stores.storeIoE.checkers.get(checkerCodeName)

  if (!checker) {
    return Promise.resolve()
  }

  return storeRoot
    .getGQLRequestor(storeWidgetsManagement.options.instanceName)
    .query<MutationCreateDashboardWidget>(mutationCreateDashboardWidget, args) // set options for the created widget
    .then(dataDashboardWidget => {
      const { id: dashboardWidgetId } =
        dataDashboardWidget.createDashboardWidget

      const dashboardWidgetOptions: InputSetDashboardWidgetOptions = {
        instanceName: storeWidgetsManagement.instanceName,
        dashboardId,
        dashboardWidgetId,
        type: DashboardWidgetType.BigNumber,
        series: [
          {
            dataOptionsDeviance: {
              type: DashboardWidgetDataOptionType.Deviance,
              checkerIds: [checker.getPropertyAsNumber('id')],
              directoryIds: [],
              reasonIds: [],
              profileId: 1,
              duration: 0
            },
            displayOptions: {
              type: DashboardWidgetType.BigNumber
            }
          }
        ]
      }

      if (!dashboardWidgetOptions) {
        throw new Error('Missing widget options')
      }

      const args: SetDashboardWidgetOptionsMutationArgs = {
        dashboardWidgetOptions
      }

      return storeRoot
        .getGQLRequestor(storeWidgetsManagement.options.instanceName)
        .query<MutationSetDashboardWidgetOptions>(
          mutationSetDashboardWidgetOptions,
          args
        )
    })
    .then(results => {
      if (!results.setDashboardWidgetOptions) {
        throw new Error('An error has occurred when creating the widget')
      }

      storeWidgetsManagement.reset()

      storeRoot.stores.storeMessages.success(
        storeWidgetsManagement.translate('Widget added'),
        {
          labelledBy: 'widgetAdded'
        }
      )

      storeWidgetsManagement.storeFlags.success()
    })
}

export const getUserEvolutionLineChartWidget = (
  storeRoot: StoreRoot,
  storeWidgetsManagement: StoreWidgetsManagement,
  args: CreateDashboardWidgetMutationArgs,
  dashboardId: number,
  sleepingAccounts?: boolean
): Promise<void> => {
  return storeRoot
    .getGQLRequestor(storeWidgetsManagement.options.instanceName)
    .query<MutationCreateDashboardWidget>(mutationCreateDashboardWidget, args) // set options for the created widget
    .then(dataDashboardWidget => {
      const { id: dashboardWidgetId } =
        dataDashboardWidget.createDashboardWidget

      const dashboardWidgetOptions: InputSetDashboardWidgetOptions = {
        instanceName: storeWidgetsManagement.instanceName,
        dashboardId,
        dashboardWidgetId,
        type: DashboardWidgetType.LineChart,
        series: [
          sleepingAccounts
            ? {
                dataOptionsDeviance: {
                  type: DashboardWidgetDataOptionType.Deviance,
                  checkerIds: [
                    storeRoot.stores.storeIoE.checkers
                      .get('C-SLEEPING-ACCOUNTS')
                      ?.getPropertyAsNumber('id') ?? 0
                  ],
                  directoryIds: [],
                  reasonIds: [],
                  // 1 month duration
                  duration: 2592000,
                  profileId: 1
                },
                displayOptions: {
                  type: DashboardWidgetType.LineChart
                }
              }
            : {
                dataOptionsUser: {
                  type: DashboardWidgetDataOptionType.User,
                  directoryIds: [],
                  // 1 month duration
                  duration: 2592000
                },
                displayOptions: {
                  type: DashboardWidgetType.LineChart
                }
              }
        ]
      }

      if (!dashboardWidgetOptions) {
        throw new Error('Missing widget options')
      }

      const args: SetDashboardWidgetOptionsMutationArgs = {
        dashboardWidgetOptions
      }

      return storeRoot
        .getGQLRequestor(storeWidgetsManagement.options.instanceName)
        .query<MutationSetDashboardWidgetOptions>(
          mutationSetDashboardWidgetOptions,
          args
        )
    })
    .then(results => {
      if (!results.setDashboardWidgetOptions) {
        throw new Error('An error has occurred when creating the widget')
      }

      storeWidgetsManagement.reset()

      storeRoot.stores.storeMessages.success(
        storeWidgetsManagement.translate('Widget added'),
        {
          labelledBy: 'widgetAdded'
        }
      )

      storeWidgetsManagement.storeFlags.success()
    })
}
