import { IconSetting } from '@app/components/Icon'
import WidgetContainer from '@app/pages/HeaderLegacy/Widgets/WidgetContainer'
import * as React from 'react'

interface IPopoverContainerProps {
  activated: boolean
}

const PopoverContainer = React.forwardRef<
  HTMLDivElement,
  IPopoverContainerProps
>((props, ref) => {
  return (
    <WidgetContainer
      ref={ref}
      name="WidgetSettings"
      ariaRoles={['menu', 'menuitem']}
      labelledBy="widgetSettings"
      isBackgroundIcon={true}
      {...props}
    >
      <IconSetting />
    </WidgetContainer>
  )
})

export default PopoverContainer
