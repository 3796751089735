import { LabelColorTag } from '@app/components/Labels'
import type { IRoleCreationOrDeletion } from '@app/entities/EntityUserLog'
import { isDefined } from '@libs/isDefined'
import React from 'react'
import {
  colorUserLogRoleDescriptionRedesign,
  colorUserLogRoleNameRedesign
} from '../consts'

const UserLogRole = (props: IRoleCreationOrDeletion) => {
  const { roleName, roleDescription } = props

  return (
    <>
      <LabelColorTag
        color={colorUserLogRoleNameRedesign.background}
        labelProperties={{
          color: colorUserLogRoleNameRedesign.text,
          labelledBy: 'role-name'
        }}
      >
        {roleName}
      </LabelColorTag>

      {isDefined(roleDescription) && (
        <LabelColorTag
          color={colorUserLogRoleDescriptionRedesign.background}
          labelProperties={{
            color: colorUserLogRoleDescriptionRedesign.text,
            labelledBy: 'role-description'
          }}
        >
          {` : ${roleDescription}`}
        </LabelColorTag>
      )}
    </>
  )
}

export default UserLogRole
