import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import HealthCheckDomainStatus from '@app/pages/HealthCheck/HealthCheckDomainStatus/HealthCheckDomainStatus'
import HealthCheckPlatformStatus from '@app/pages/HealthCheck/HealthCheckPlatformStatus/HealthCheckPlatformStatus'
import {
  canAccessToHealthCheckDomainStatus,
  canAccessToHealthCheckPlatformStatus
} from '@app/pages/HealthCheck/permissions'
import { AppRouteName } from '@app/routes'
import * as React from 'react'

interface IHealthCheckContentPageProps {}

function HealthCheckContentPage(props: IHealthCheckContentPageProps) {
  return (
    <RbacRoutes
      routes={[
        {
          routeDefinition: {
            routeName: AppRouteName.HealthCheck_DomainStatus,
            parameters: {}
          },
          component: HealthCheckDomainStatus,
          rbacPermissionsCheck: () => canAccessToHealthCheckDomainStatus
        },
        {
          routeDefinition: {
            routeName: AppRouteName.HealthCheck_PlatformStatus,
            parameters: {}
          },
          component: HealthCheckPlatformStatus,
          rbacPermissionsCheck: () => canAccessToHealthCheckPlatformStatus
        }
      ]}
      redirect
    />
  )
}

export default HealthCheckContentPage
