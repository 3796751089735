// in seconds
export const LOCKOUT_POLICY_DURATION_MIN = 10
export const LOCKOUT_POLICY_DURATION_MAX = 3600
export const LOCKOUT_POLICY_DURATION_STEP = 10

// failed attemps period (in seconds)
export const LOCKOUT_POLICY_FAILED_ATTEMPT_PERIOD_MIN = 10
export const LOCKOUT_POLICY_FAILED_ATTEMPT_PERIOD_MAX = 3600
export const LOCKOUT_POLICY_FAILED_ATTEMPT_PERIOD_STEP = 10

// number of attempts
export const LOCKOUT_POLICY_FAILED_ATTEMPTS_COUNTS_MIN = 1
export const LOCKOUT_POLICY_FAILED_ATTEMPTS_COUNTS_MAX = 50
export const LOCKOUT_POLICY_FAILED_ATTEMPTS_COUNTS_STEPS = 1
