export const mutationUpdateSAMLConfiguration = `
  mutation mutationUpdateSAMLConfiguration($samlConfiguration: InputSAMLConfiguration!) {
    updateSAMLConfiguration(
      samlConfiguration: $samlConfiguration
    ) {
      enabled
      providerLoginUrl
      signatureCertificate
      serviceProviderUrl
      assertEndpoint
    }
  }
`
