import type StoreProfileCheckerOptionOMCGMAW from '@app/stores/Management/StoreProfiles/StoreProfileCheckerOptionOMCGMAW'

export const onAddNewConfigurationClick =
  (storeProfileCheckerOptionOMCGMAW: StoreProfileCheckerOptionOMCGMAW) =>
  () => {
    storeProfileCheckerOptionOMCGMAW.addConfiguration()
  }

export const onRemoveConfigurationClick =
  (storeProfileCheckerOptionOMCGMAW: StoreProfileCheckerOptionOMCGMAW) =>
  (index: number) =>
  () =>
    storeProfileCheckerOptionOMCGMAW.removeConfiguration(index)
