import { buildVariants } from '@design-system/libs/buildVariants'
import * as React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div<{
  fullHeight?: boolean
}>(props => {
  const theme = props.theme

  return buildVariants(props)
    .css({
      borderRadius: theme.tokens['card/borderRadius/default'],
      padding: theme.tokens['card/padding/mainContent'],
      margin: theme.tokens['card/margin/mainContent'],
      backgroundColor: theme.tokens['card/color/backgroundWhite'],
      boxShadow: theme.tokens['card/boxShadow/default']
    })
    .variant('fullHeight', props.fullHeight, {
      true: {
        height: '100%'
      },
      false: {}
    })
    .end()
})

interface ICardMainContentProps {
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
  fullHeight?: boolean
}

export function CardMainContent(props: ICardMainContentProps) {
  return (
    <StyledDiv
      className={props.className}
      style={props.style}
      fullHeight={props.fullHeight}
    >
      {props.children}
    </StyledDiv>
  )
}
