/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { Checker, ErrorResponse, FindingsResponse } from '../models/index'
import {
  CheckerFromJSON,
  CheckerToJSON,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  FindingsResponseFromJSON,
  FindingsResponseToJSON
} from '../models/index'

export interface GetFindingsByCheckerIdRequest {
  checkerId: string
  tenantIds?: Array<string>
  textQuery?: string
  startDetectionDate?: Date
  endDetectionDate?: Date
  offset?: number
  limit?: number
}

/**
 *
 */
export class CheckersApi extends runtime.BaseAPI {
  /**
   * Retrieve all checkers
   */
  async getCheckersRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<Array<Checker>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/core/ad/checkers`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(CheckerFromJSON)
    )
  }

  /**
   * Retrieve all checkers
   */
  async getCheckers(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<Array<Checker>> {
    const response = await this.getCheckersRaw(initOverrides)
    return await response.value()
  }

  /**
   * Get all findings related to a single checker
   */
  async getFindingsByCheckerIdRaw(
    requestParameters: GetFindingsByCheckerIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<FindingsResponse>> {
    if (requestParameters['checkerId'] == null) {
      throw new runtime.RequiredError(
        'checkerId',
        'Required parameter "checkerId" was null or undefined when calling getFindingsByCheckerId().'
      )
    }

    const queryParameters: any = {}

    if (requestParameters['tenantIds'] != null) {
      queryParameters['tenant_ids[]'] = requestParameters['tenantIds']
    }

    if (requestParameters['textQuery'] != null) {
      queryParameters['text_query'] = requestParameters['textQuery']
    }

    if (requestParameters['startDetectionDate'] != null) {
      queryParameters['start_detection_date'] = (
        requestParameters['startDetectionDate'] as any
      ).toISOString()
    }

    if (requestParameters['endDetectionDate'] != null) {
      queryParameters['end_detection_date'] = (
        requestParameters['endDetectionDate'] as any
      ).toISOString()
    }

    if (requestParameters['offset'] != null) {
      queryParameters['offset'] = requestParameters['offset']
    }

    if (requestParameters['limit'] != null) {
      queryParameters['limit'] = requestParameters['limit']
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/core/ad/checkers/{checkerId}/findings`.replace(
          `{${'checkerId'}}`,
          encodeURIComponent(String(requestParameters['checkerId']))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    )

    return new runtime.JSONApiResponse(response, jsonValue =>
      FindingsResponseFromJSON(jsonValue)
    )
  }

  /**
   * Get all findings related to a single checker
   */
  async getFindingsByCheckerId(
    requestParameters: GetFindingsByCheckerIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<FindingsResponse> {
    const response = await this.getFindingsByCheckerIdRaw(
      requestParameters,
      initOverrides
    )
    return await response.value()
  }
}
