import type { Maybe } from '@@types/helpers'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled from 'styled-components'
import type { WidgetListColumnSize } from '../Widgets/WidgetList/WidgetListTable/types'

export interface ITableHeadCellProps {
  className?: string
  style?: React.CSSProperties
  width?: Maybe<number | string | WidgetListColumnSize>
  children?: React.ReactNode
}

const TableHeadCell: React.FC<ITableHeadCellProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  return (
    <th className={props.className} style={props.style}>
      {themeIsLegacy ? (
        props.children
      ) : (
        <Label variant={LabelVariant.h3}>{props.children}</Label>
      )}
    </th>
  )
}

function getWidth(width: ITableHeadCellProps['width']): string {
  if (!width) {
    return 'auto'
  }

  switch (width) {
    case 'ID':
      return `70px`
  }

  if (typeof width === 'number') {
    return `${width}%`
  }

  return width
}

export default styled(TableHeadCell)`
  font-weight: 600;
  padding-right: ${consts.paddingDefault};
  width: ${props => getWidth(props.width)};
  text-align: left;
`
