import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import PortalPlaceHolder from '../Portal/PortalPlaceHolder'
import type { PlaceHolderName } from './types'

export interface IPlaceHolderDrawersProps {
  className?: string
  name: PlaceHolderName
}

/**
 * PlaceHolder for generic drawers.
 */
const PlaceHolderDrawers: React.FC<IPlaceHolderDrawersProps> = props => {
  return <PortalPlaceHolder className={props.className} name={props.name} />
}

export default styled(PlaceHolderDrawers)`
  position: absolute;
  z-index: ${consts.zIndexDrawer};
  height: 100%;
  top: 0;
  right: 0;
`
