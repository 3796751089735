import { Drawer, DrawerContext } from '@app/components-legacy/Drawer'
import { useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import Alerts from './Alerts'

interface IDrawerAlertsProps {}

const DrawerAlerts: React.FC<IDrawerAlertsProps> = () => {
  const { storeAlerts } = useStores()

  return (
    <DrawerContext.Provider value={{ storeDrawer: storeAlerts.storeDrawer }}>
      <Drawer>
        <Alerts />
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerAlerts)
