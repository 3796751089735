export enum LocalStorageKey {
  // Debug level
  debug = 'debug',

  // Token used for authentication
  IronToken = 'Iron.token',

  // User's current locale set in T.io
  TioLocale = 'tioLocale',

  // Used to set selected application in T.io so we are not redirected
  selectedApplication = 'selectedApplication'
}

// Used for the T.io selectedApplication values
export enum LocalStorageSelectedApplication {
  tenable_io = 'tenable_io',
  was = 'was',
  lumin = 'lumin'
}
