import { ContainerFlex, ContainerHTMLContent } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { useAppTranslator } from '@app/hooks'
import { consts } from '@app/styles'
import { BackgroundColorV2, FontColorV2 } from '@app/styles/consts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'

interface IConfigurationPendoCardProps {
  className?: string
}

const StyledDiv = styled.div`
  font-weight: ${consts.fontWeightBold};
  cursor: pointer;
`

const ConfigurationPendoCard: React.FC<
  IConfigurationPendoCardProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.Pendo']
  })

  const [showLearnMore, setShowLearnMore] = useState(true)

  return (
    <ContainerFlex
      name="formGroup"
      className={props.className}
      direction="column"
      items={[
        <Label>
          <ContainerHTMLContent padded>
            {translate(`After you enable or disable this option`, {
              transformMarkdown: true
            })}
          </ContainerHTMLContent>

          <div
            onClick={() => {
              setShowLearnMore(false)
            }}
          >
            {' '}
            {showLearnMore ? (
              <StyledDiv>{translate(`Learn more`)}</StyledDiv>
            ) : (
              <ContainerHTMLContent padded>
                {translate(`The Enable Usage Statistics option specifies`)}
              </ContainerHTMLContent>
            )}
          </div>
        </Label>
      ]}
      spaced
      spaceWidth="smaller"
      wrapItems
    />
  )
}

const ObservedConfigurationPendoCard = observer(ConfigurationPendoCard)

export default styled(ObservedConfigurationPendoCard)`
  border-radius: ${consts.borderRadiusLargeRedesign};
  padding: ${consts.paddingLarge};
  background-color: ${BackgroundColorV2.lightGray};

  a {
    color: ${FontColorV2.link};
    font-weight: ${consts.fontWeightBold};
    text-decoration: underline;
  }
`
