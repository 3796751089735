import { ContainerFlex } from '@app/components/Container'
import { BladesContainer } from '@app/components-legacy/Blade'
import { LayoutDefault } from '@app/components-legacy/Layout'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import * as React from 'react'
import WeaknessesBlade from './WeaknessesBlade'

interface IWeaknessesPageProps {}

const WeaknessesPage: React.FC<IWeaknessesPageProps> = props => {
  return (
    <DSThemeProvider themeName={DSThemeName.paladin}>
      <LayoutDefault
        content={
          <ContainerFlex
            name="WeaknessesPage"
            items={[
              <BladesContainer>
                <WeaknessesBlade />
              </BladesContainer>
            ]}
          />
        }
      />
    </DSThemeProvider>
  )
}

export default WeaknessesPage
