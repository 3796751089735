import { ContainerFlex } from '@app/components-legacy/Container'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { LabelAlt, LabelAltError } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import {
  TypographyFieldsetTitle,
  TypographySubTitle
} from '@app/components-legacy/Typography'
import { useAppTranslator } from '@app/hooks'
import type StoreWidgetsManagement from '@app/stores/Dashboard/StoreWidgetsManagement'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { isDefined } from '@libs/isDefined'
import { range } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import WidgetInputsDisplayOptionsSwitch from '../WidgetInputsDisplayOptions/WidgetInputsDisplayOptionsSwitch'
import WidgetInputsDataOptionsSwitch from './WidgetInputsDataOptionsSwitch'

export interface IWidgetInputsDataOptionsSeriesProps {
  storeWidgetsManagement: StoreWidgetsManagement
}

const MAX_NUMBER_OF_SERIES = 50

const WidgetInputsDataOptionsSeries: React.FC<
  IWidgetInputsDataOptionsSeriesProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Dashboard.WidgetCommonForm']
  })

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  /**
   * Return the limit of the number of series (1 max for BigNumber).
   */
  const getMaxNumberOfSeries = () => {
    return props.storeWidgetsManagement.isBigNumber ? 1 : MAX_NUMBER_OF_SERIES
  }

  const onAddSerieClick = () => {
    props.storeWidgetsManagement.addNewSerie()
  }

  const onRemoveSerieClick = (serieNumber: number) => () => {
    props.storeWidgetsManagement.removeSerie(serieNumber)
  }

  const renderSeries = () => {
    const limitSerieUp = Math.min(
      props.storeWidgetsManagement.nbSeries + 1,
      getMaxNumberOfSeries() + 1
    )

    return range(1, limitSerieUp)
      .map(i => {
        const storeSerie = props.storeWidgetsManagement.getStoreSerie(i)

        if (!storeSerie) {
          return
        }

        const displayRemoveSerieBtn =
          props.storeWidgetsManagement.nbSeries > 1 &&
          !props.storeWidgetsManagement.isBigNumber

        const key = `serie-${i}`

        return (
          <ContainerFlex
            name="WidgetInputsDataOptionsSerie"
            direction="column"
            items={[
              <ContainerFlex
                key={key}
                name="WidgetInputsDataOptionsSerieFields"
                direction="column"
                items={[
                  <TypographyFieldsetTitle>
                    {translate(
                      themeIsLegacy
                        ? 'Dataset number X'
                        : 'Configuration dataset number X',
                      {
                        interpolations: {
                          count: i
                        }
                      }
                    )}
                  </TypographyFieldsetTitle>,

                  <WidgetInputsDataOptionsSwitch
                    storeForm={props.storeWidgetsManagement.storeForm}
                    storeSerie={storeSerie}
                  />,

                  <WidgetInputsDisplayOptionsSwitch storeSerie={storeSerie} />,

                  displayRemoveSerieBtn && (
                    <div>
                      {themeIsLegacy ? (
                        <FormWrapperButton
                          labelledBy={`removeSerie-${i}`}
                          buttonProps={{
                            size: 'small',
                            onClick: onRemoveSerieClick(i)
                          }}
                        >
                          {translate('Remove the dataset number X', {
                            interpolations: {
                              count: i
                            }
                          })}
                        </FormWrapperButton>
                      ) : (
                        <FormWrapperButton
                          labelledBy={`removeSerie-${i}`}
                          variant={ButtonVariant.tertiary}
                          size={ButtonSize.small}
                          buttonProps={{
                            onClick: onRemoveSerieClick(i)
                          }}
                        >
                          {translate('Remove dataset', {
                            interpolations: {
                              count: i
                            }
                          })}
                        </FormWrapperButton>
                      )}
                    </div>
                  )
                ]}
                spaced
                spaceWidth={themeIsLegacy ? 'small' : 'default'}
              />
            ]}
            spaced
            spaceWidth="large"
          />
        )
      })
      .filter(isDefined)
  }

  const renderAddSerieBtn = () => {
    const maxNumberOfSeries = getMaxNumberOfSeries()

    const showAddNewSerieBtn =
      props.storeWidgetsManagement.nbSeries < maxNumberOfSeries

    if (showAddNewSerieBtn) {
      return (
        <div>
          {themeIsLegacy ? (
            <FormWrapperButton
              labelledBy="addNewSerie"
              buttonProps={{
                onClick: onAddSerieClick
              }}
            >
              {translate('Add a new dataset')}
            </FormWrapperButton>
          ) : (
            <FormWrapperButton
              labelledBy="addNewSerie"
              variant={ButtonVariant.secondary}
              size={ButtonSize.small}
              buttonProps={{
                onClick: onAddSerieClick
              }}
            >
              {translate('Add a new dataset')}
            </FormWrapperButton>
          )}
        </div>
      )
    }

    if (props.storeWidgetsManagement.isBigNumber) {
      return (
        <LabelAlt variant={LabelAltVariant.Disabled}>
          {translate(
            'You cannot add more than one dataset to a BigNumber widget'
          )}
        </LabelAlt>
      )
    }

    return (
      <LabelAltError labelledBy="cantAddSerie">
        {translate('You cannot add more than X dataset', {
          interpolations: {
            count: maxNumberOfSeries
          }
        })}
      </LabelAltError>
    )
  }

  if (!props.storeWidgetsManagement.isWidgetDataOptionsTypeSelected) {
    return null
  }

  return (
    <ContainerFlex
      name="WidgetInputsDataOptionsSeries"
      direction="column"
      items={[
        themeIsLegacy && (
          <TypographySubTitle>
            {translate('Datasets configuration')}
          </TypographySubTitle>
        ),

        ...renderSeries(),

        renderAddSerieBtn()
      ]}
      spaced
    />
  )
}

export default observer(WidgetInputsDataOptionsSeries)
