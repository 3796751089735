import type { StoreRoot } from '@app/stores'
import StoreForm from '@app/stores/helpers/StoreForm'
import type { ICheckerOptionOMCGMAWConfigurationValues } from '@app/stores/Management/StoreProfiles/types'
import { CheckerOptionOMCGMAWConfigurationFieldName } from '@app/stores/Management/StoreProfiles/types'
import StoreBase from '@app/stores/StoreBase'
import type { IStoreOptions } from '@app/stores/types'
import { ensureArray } from '@libs/ensureArray'
import { isNotFalsy } from '@libs/isDefined'
import { action, computed, makeObservable, observable } from 'mobx'

/**
 * Store dedicated to the checker option O-MAX-CUSTOM-GROUPS-MEMBERS-AND-WHITELIST (OMCGMAW).
 */
export default class StoreProfileCheckerOptionOMCGMAW extends StoreBase {
  // for each configuration, use an instance of a StoreForm
  private _storeFormConfigurations = observable.map<
    number,
    StoreForm<CheckerOptionOMCGMAWConfigurationFieldName>
  >()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Set the form default configurations fields.
   */
  initConfigurations(configurationValues: string): void {
    this.resetConfigurations()

    try {
      const decodedValues: ICheckerOptionOMCGMAWConfigurationValues[] =
        ensureArray(JSON.parse(configurationValues))

      decodedValues.forEach(decodedValue => {
        this.addConfiguration(decodedValue)
      })
    } catch (err) {
      this.storeRoot.logger.error(
        `Error when parsing configuration for the O-MAX-CUSTOM-GROUPS-MEMBERS-AND-WHITELIST option`
      )
    }
  }

  /* Actions */

  @action
  resetConfigurations(): void {
    this._storeFormConfigurations.clear()
  }

  /**
   * Add a new configuration.
   */
  @action
  addConfiguration(
    decodedValue?: ICheckerOptionOMCGMAWConfigurationValues
  ): void {
    const storeForm = new StoreForm<CheckerOptionOMCGMAWConfigurationFieldName>(
      this.storeRoot
    )

    storeForm
      .setDefaultFieldsValues([
        {
          key: CheckerOptionOMCGMAWConfigurationFieldName.distinguishedName,
          value: decodedValue ? decodedValue.distinguishedName : ''
        },
        {
          key: CheckerOptionOMCGMAWConfigurationFieldName.nbUsersMax,
          value: decodedValue ? decodedValue.nbUsersMax : 0
        }
      ])
      .setDefaultFieldsMultiValues([
        {
          key: CheckerOptionOMCGMAWConfigurationFieldName.usersWhiteList,
          values: decodedValue ? decodedValue.usersWhiteList : ['']
        }
      ])

    // apply default fields to values
    storeForm.reset()

    this._storeFormConfigurations.set(
      this._storeFormConfigurations.size,
      storeForm
    )
  }

  /**
   * Remove a configuration.
   */
  @action
  removeConfiguration(index: number): void {
    this._storeFormConfigurations.delete(index)
  }

  /* Computed */

  /**
   * Return the storeForms instances for all the configuration.
   */
  @computed
  get storeFormConfigurations(): Array<
    StoreForm<CheckerOptionOMCGMAWConfigurationFieldName>
  > {
    return Array.from(this._storeFormConfigurations.values())
  }

  /**
   * Return the encoded values for the option.
   */
  @computed
  get configurationValues(): ICheckerOptionOMCGMAWConfigurationValues[] {
    return (
      Array.from(this._storeFormConfigurations.values())
        .filter(storeForm => {
          return storeForm.getFieldValueAsString(
            CheckerOptionOMCGMAWConfigurationFieldName.distinguishedName
          )
        })
        // filter configuration where distinguishedName is not empty
        .filter(isNotFalsy)
        .map(storeForm => {
          return {
            [CheckerOptionOMCGMAWConfigurationFieldName.distinguishedName]:
              storeForm.getFieldValueAsString(
                CheckerOptionOMCGMAWConfigurationFieldName.distinguishedName
              ),
            [CheckerOptionOMCGMAWConfigurationFieldName.nbUsersMax]:
              storeForm.getFieldValueAsNumber(
                CheckerOptionOMCGMAWConfigurationFieldName.nbUsersMax
              ),
            [CheckerOptionOMCGMAWConfigurationFieldName.usersWhiteList]:
              storeForm
                .getMultiValuesFieldValuesAsArray(
                  CheckerOptionOMCGMAWConfigurationFieldName.usersWhiteList
                )
                .map(fieldValue => String(fieldValue))
          }
        })
    )
  }
}
