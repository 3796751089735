import {
  colorGrey015,
  colorWhiteAltRedesign,
  CTAColorV2,
  FontColorV2
} from '@app/styles/consts'

export const colorTraceTypeFont = '#886089'
export const colorTraceTypeBackground = '#F4DFF5'

export enum ColorTraceConfValueFont {
  newValue = '#79B266',
  formerValue = '#F26D55'
}

export enum ColorTraceConfValueBackground {
  newValue = '#F0FFEA',
  formerValue = '#FFE9E5'
}

export enum ColorTraceConfNameFont {
  hasNewValue = '#6C5DCA',
  doesNotHaveNewValue = '#5277E6'
}

export enum ColorTraceConfNameBackground {
  hasNewValue = 'none',
  doesNotHaveNewValue = '#E9EFFF'
}

export const colorActivityLogsLogAlternateBackgroundRedesign = '#F7F7FD'
export const colorUserLogActivatedRedesign = {
  text: '#79B266',
  background: '#F0FFEA'
}
export const colorUserLogDeactivatedRedesign = {
  text: '#F26D55',
  background: '#FFE9E5'
}
export const colorUserLogIoAAttackTypeRedesign = {
  text: CTAColorV2.primary,
  background: CTAColorV2.secondary
}

export const colorUserLogPreviousValueRedesign = {
  text: colorWhiteAltRedesign,
  background: colorGrey015
}

export const colorUserLogRoleNameRedesign = {
  text: CTAColorV2.primary,
  background: CTAColorV2.secondary
}

export const colorUserLogRoleDescriptionRedesign = {
  text: FontColorV2.primary,
  background: '#F7F7FD'
}

export const colorLogUserCreationDeletionRedesign = {
  text: '#8f5d8c',
  background: '#f8def7'
}

export const ColorLogUserHttpVerb = {
  blue: '#7F9EF9',
  green: '#93D461',
  orange: '#F39C6E',
  red: '#EF627E'
}

export const colorMenuRedesign = {
  text: '#6A5BED',
  background: '#DCD7FC'
}

export const colorUserLogInternalServerError = '#F26D55'

export const colorUserDisplayPayloadButton = {
  text: '#FFFFFF',
  background: '#6C5DCA'
}

/**
 * List of error http status
 */
export const httpStatusErrorLabels = new Map<number, string>(
  [
    {
      code: '400',
      phrase: 'Bad Request'
    },
    {
      code: '401',
      phrase: 'Unauthorized'
    },
    {
      code: '402',
      phrase: 'Payment Required'
    },
    {
      code: '403',
      phrase: 'Forbidden'
    },
    {
      code: '404',
      phrase: 'Not Found'
    },
    {
      code: '405',
      phrase: 'Method Not Allowed'
    },
    {
      code: '406',
      phrase: 'Not Acceptable'
    },
    {
      code: '407',
      phrase: 'Proxy Authentication Required'
    },
    {
      code: '408',
      phrase: 'Request Timeout'
    },
    {
      code: '409',
      phrase: 'Conflict'
    },
    {
      code: '410',
      phrase: 'Gone'
    },
    {
      code: '411',
      phrase: 'Length Required'
    },
    {
      code: '412',
      phrase: 'Precondition Failed'
    },
    {
      code: '413',
      phrase: 'Payload Too Large'
    },
    {
      code: '414',
      phrase: 'URI Too Long'
    },
    {
      code: '415',
      phrase: 'Unsupported Media Type'
    },
    {
      code: '416',
      phrase: 'Range Not Satisfiable'
    },
    {
      code: '417',
      phrase: 'Expectation Failed'
    },
    {
      code: '418',
      phrase: "I'm a teapot"
    },
    {
      code: '426',
      phrase: 'Upgrade Required'
    },
    {
      code: '500',
      phrase: 'Internal Server Error'
    },
    {
      code: '501',
      phrase: 'Not Implemented'
    },
    {
      code: '502',
      phrase: 'Bad Gateway'
    },
    {
      code: '503',
      phrase: 'Service Unavailable'
    },
    {
      code: '504',
      phrase: 'Gateway Time-out'
    },
    {
      code: '505',
      phrase: 'HTTP Version Not Supported'
    },
    {
      code: '308',
      phrase: 'Permanent Redirect'
    },
    {
      code: '422',
      phrase: 'Unprocessable Entity'
    },
    {
      code: '423',
      phrase: 'Locked'
    },
    {
      code: '424',
      phrase: 'Failed Dependency'
    },
    {
      code: '428',
      phrase: 'Precondition Required'
    },
    {
      code: '429',
      phrase: 'Too Many Requests'
    },
    {
      code: '431',
      phrase: 'Request Header Fields Too Large'
    },
    {
      code: '451',
      phrase: 'Unavailable For Legal Reasons'
    },
    {
      code: '506',
      phrase: 'Variant Also Negotiates'
    },
    {
      code: '507',
      phrase: 'Insufficient Storage'
    },
    {
      code: '511',
      phrase: 'Network Authentication Required'
    }
  ].map(status => [Number(status.code), status.phrase])
)

export const INDENT_LENGTH_JSON_FORMAT = 2

export const PAYLOAD_MAX_LENGTH = 300
