import {
  DIRECTORY_DEFAULT_GLOBAL_CATALOG_PORT,
  DIRECTORY_DEFAULT_LDAP_PORT,
  DIRECTORY_DEFAULT_SMB_PORT,
  DIRECTORY_DEFAULT_TYPE
} from '@app/entities/EntityDirectory'
import { AppRouteName } from '@app/routes'
import type {
  StoreBlades,
  StoreManagementDirectories,
  StoreManagementRelays,
  StoreRbac
} from '@app/stores'
import type StoreForm from '@app/stores/helpers/StoreForm'
import type StoreDataCollection from '@app/stores/Management/StoreDataCollection'
import type { AppRouterClient } from '@libs/Router/types'
import type { InputCreateDirectory } from '@server/graphql/typeDefs/types'
import { canAccessToRelays } from '../../Relays/permissions'
import { canAccessTenableCloudSensitiveData } from '../permissions'
import { DirectoryFormFieldName } from './types'

function validateDirectoryForm(
  storeFormDomain: StoreForm<DirectoryFormFieldName>
): boolean {
  if (!storeFormDomain.validate()) {
    storeFormDomain.storeRoot.stores.storeMessages.validationError()
    return false
  }
  return true
}

export function getDirectoryFromForm(
  storeFormDomain: StoreForm<DirectoryFormFieldName>
): InputCreateDirectory {
  return {
    name: storeFormDomain.getFieldValueAsString(DirectoryFormFieldName.name),
    ip: storeFormDomain.getFieldValueAsString(DirectoryFormFieldName.ip),
    infrastructureId: storeFormDomain.getFieldValueAsNumber(
      DirectoryFormFieldName.infrastructureId
    ),
    relayId: storeFormDomain.getFieldValueAsNumber(
      DirectoryFormFieldName.relayId
    ),
    sensitiveDataCollectionIsEnabled: storeFormDomain.getFieldValueAsBoolean(
      DirectoryFormFieldName.sensitiveDataCollectionIsEnabled
    ),

    dns: storeFormDomain.getFieldValueAsString(DirectoryFormFieldName.dns),
    ldapPort: storeFormDomain.getFieldValueAsNumber(
      DirectoryFormFieldName.ldapPort,
      DIRECTORY_DEFAULT_LDAP_PORT
    ),
    globalCatalogPort: storeFormDomain.getFieldValueAsNumber(
      DirectoryFormFieldName.globalCatalogPort,
      DIRECTORY_DEFAULT_GLOBAL_CATALOG_PORT
    ),
    smbPort: storeFormDomain.getFieldValueAsNumber(
      DirectoryFormFieldName.smbPort,
      DIRECTORY_DEFAULT_SMB_PORT
    ),

    type: storeFormDomain.getFieldValueAsString(
      DirectoryFormFieldName.type,
      DIRECTORY_DEFAULT_TYPE
    )
  }
}

/**
 * Reset the form at loading.
 */
export const onDirectoriesCreatePageLoad =
  (
    storeManagementDirectories: StoreManagementDirectories,
    storeManagementDataCollection: StoreDataCollection,
    storeManagementRelays: StoreManagementRelays,
    storeRbac: StoreRbac
  ) =>
  () => {
    storeManagementDirectories.storeFormDomain.hardReset()

    if (storeRbac.isUserGrantedTo(canAccessToRelays)) {
      storeManagementRelays.fetchRelays()
    }

    if (storeRbac.isUserGrantedTo(canAccessTenableCloudSensitiveData)) {
      storeManagementDataCollection.fetchDataCollectionStatus()
    }
  }

/**
 * Reset at unloading.
 */
export const onDirectoriesCreatePageUnload =
  (
    storeManagementDirectories: StoreManagementDirectories,
    storeManagementRelays: StoreManagementRelays
  ) =>
  () => {
    storeManagementDirectories.resetCreationPage()
    storeManagementRelays.reset()
  }

/**
 * Create the directory,
 * Fetch directories,
 * And go back to the list.
 */
export const onDirectoriesCreateSubmit =
  (
    appRouter: AppRouterClient,
    storeManagementDirectories: StoreManagementDirectories
  ) =>
  (e: React.FormEvent<any>) => {
    const { storeFormDomain } = storeManagementDirectories

    e.preventDefault()

    if (!validateDirectoryForm(storeFormDomain)) {
      return
    }

    const directory = getDirectoryFromForm(storeFormDomain)

    storeManagementDirectories
      .createDirectory(directory)
      .then(() => {
        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Management_System_Directories,
          parameters: {}
        })
        appRouter.history.push(url)
      })
      .catch(() => {
        // already catched in the store
      })
  }

export const onDirectoriesCreateCancelClick = (storeBlades: StoreBlades) => {
  const url = storeBlades.storeRoot.appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.Management_System_Directories,
    parameters: {}
  })

  storeBlades.goToBladeUrl(url)
}

export const onDirectoryTestConnectivity =
  (storeManagementDirectories: StoreManagementDirectories) => () => {
    const { storeFormDomain } = storeManagementDirectories

    if (!validateDirectoryForm(storeFormDomain)) {
      return
    }

    const directory = getDirectoryFromForm(storeFormDomain)

    storeManagementDirectories.testDirectoryConnectivity(directory)
  }
