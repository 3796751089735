import { LabelAppRibbon } from '@app/components-legacy/Label'
import { ProfileName } from '@app/entities/EntityProfile'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { LicenseStatus } from '@app/stores/StoreLicense/types'
import { sanitize } from '@libs/sanitize'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface IAppRibonProps {}

const CapitalizedString = styled.div`
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 600;
`

const AppRibon: React.FC<IAppRibonProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.About.License']
  })

  const appRouter = useAppRouter()

  const { storeRoot, storeAuthentication, storeLicense, storePendo } =
    useStores()

  // if not authenticated, no ribbon
  if (!storeAuthentication.isAuthenticated) {
    return null
  }

  // if pendo is enabled, no ribbon
  if (storePendo.isPendoEnabled) {
    return null
  }

  switch (storeLicense.licenseStatus) {
    // if the license if not set, the user can't loggin,
    // so no ribbon needs to be displayed
    case LicenseStatus.unset: {
      return null
    }

    case LicenseStatus.expired: {
      const licenseManagementUrl = appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.Management_System_About,
        parameters: {
          profileName:
            storeAuthentication.currentSanitizedProfileName ||
            sanitize(ProfileName.Tenable)
        }
      })

      return (
        <LabelAppRibbon
          labelledBy="licenseExpired"
          variant="license"
          closeNotAllowed={true}
        >
          <CapitalizedString>
            {translate('The license has expired')}
          </CapitalizedString>

          <div>
            <Link to={licenseManagementUrl}>
              {translate('Please update the license')}
            </Link>
          </div>
        </LabelAppRibbon>
      )
    }

    case LicenseStatus.nearViolated: {
      return (
        <LabelAppRibbon labelledBy="licenseViolated" variant="license">
          <CapitalizedString>
            {translate(
              'The number of active users is near of the number of users allowed by the license'
            )}
          </CapitalizedString>
        </LabelAppRibbon>
      )
    }

    case LicenseStatus.overViolated:
    case LicenseStatus.violated: {
      const licenseManagementUrl = appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.Management_System_About,
        parameters: {
          profileName:
            storeAuthentication.currentSanitizedProfileName ||
            sanitize(ProfileName.Tenable)
        }
      })

      return (
        <LabelAppRibbon labelledBy="licenseViolated" variant="license">
          <CapitalizedString>
            {translate(
              'The number of active users is superior of the number of users allowed by the license'
            )}
          </CapitalizedString>

          <div>
            <Link to={licenseManagementUrl}>
              {translate('Please update the license')}
            </Link>
          </div>
        </LabelAppRibbon>
      )
    }

    // Display ribbon text (if defined) of the Kapteyn configuration
    case LicenseStatus.valid: {
      if (!storeRoot.environment.config.app.settings.ribbon) {
        return null
      }

      return (
        <LabelAppRibbon variant="version">
          {storeRoot.environment.config.app.settings.ribbon}
        </LabelAppRibbon>
      )
    }

    default:
      assertUnreachableCase(storeLicense.licenseStatus)
  }
}

export default observer(AppRibon)
