export enum LdapConfigurationFieldName {
  relayId = 'relayId',
  enabled = 'enabled',
  url = 'url',
  searchUserDN = 'searchUserDN',
  searchUserPassword = 'searchUserPassword',
  userSearchBase = 'userSearchBase',
  userSearchFilter = 'userSearchFilter',
  enableSaslBinding = 'enableSaslBinding'
}

export enum LdapConfigurationGroupFieldName {
  groupName = 'groupName',
  defaultProfileId = 'defaultProfileId',
  defaultRoleIds = 'defaultRoleIds'
}
