import { AppRouteName } from '@app/routes'
import type { StoreRoot } from '@app/stores'
import type { Language } from '@server/graphql/typeDefs/types'
import { parse } from 'qs'
import type { IGlobalInventoryIframePreferences } from 'tenable-cyber-asset-management/dist/common/src/libs/IframeCommunicationBus/types'

const pathToRouteNameMapper: Record<string, AppRouteName> = {
  '/weaknesses': AppRouteName.Gi_Weaknesses,
  '/external/tie/cloud-settings':
    AppRouteName.Management_System_Configuration_TenableCloud
}

/**
 * Ensure that the language is valid for GI.
 * Should be more included in GI itself.
 */
export function ensureGIValidLanguage(
  language: Language
): IGlobalInventoryIframePreferences['language'] {
  if (language.match(/fr/)) {
    return 'fr'
  }

  return 'en'
}

function redirectBrowserTo(url: string) {
  const { origin, pathname } = window.location
  const newUrl = `${origin}${pathname}?globalinventory=${url}`

  window.location.href = newUrl
  return
}

function navigateToKapteynPage(
  storeRoot: StoreRoot,
  routeName: AppRouteName,
  search: string
) {
  // Case 2: The destination page is a Kapteyn page.
  const params = parse(search, {
    allowDots: true,
    ignoreQueryPrefix: true
  })

  /**
   * The Route name type is made up of union types.
   * See the type declaration in the file: Kapteyn/src/app/routes.ts
   * We don't know in advance which type it's going to be as we're building the routeDefinition dynamically.
   */
  const routeDefinition: any = {
    routeName: routeName,
    parameters: {},
    queryStringParameters: params
  }

  const finalUrl = storeRoot.appRouter.makeRouteInfosPathname(routeDefinition, {
    arrayFormat: 'indices'
  })

  storeRoot.appRouter.history.push(finalUrl)
}

export function navigateToPageUrl(storeRoot: StoreRoot, uri: string) {
  // The prefix Url has no added value except to form a valid Url in order to pass it to new URL()
  const prefixUrl = 'http://example.com'
  const url = `${prefixUrl}${uri}`

  const urlObject = new URL(url)

  const kapteynRouteName = pathToRouteNameMapper[urlObject.pathname]

  /* Case 1 : The destination page is not a Kapteyn page. 
   Example: Asset details page has not a route defined in kapteyn app routes */
  if (!kapteynRouteName) {
    redirectBrowserTo(uri)
  } else {
    // Case 2: The destination page is a Kapteyn page
    navigateToKapteynPage(storeRoot, kapteynRouteName, urlObject.search)
  }
}
