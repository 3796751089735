import type { EntityAttackTypeOption, EntityCheckerOption } from '..'
import type EntityCheckerIdentityOption from './EntityCheckerIdentityOption'

export type EntityGenericCheckerOption =
  | EntityCheckerOption
  | EntityAttackTypeOption
  | EntityCheckerIdentityOption

/**
 * Options of generic checkers.
 * Declare only options where specific logic is applyed.
 */
export enum CheckerOptionCodenameEnum {
  // Common
  O_DUMMY_OPTION = 'O-DUMMY-OPTION',
  O_CRITICITY = 'O-CRITICITY',

  // O_ENABLED will exist only for IoE for now
  O_ENABLED = 'O-ENABLED',

  // Checkers
  O_MAX_CUSTOM_GROUPS_MEMBERS_AND_WHITELIST = 'O-MAX-CUSTOM-GROUPS-MEMBERS-AND-WHITELIST',
  O_WHITELIST_CERTIFICATE_TEMPLATES = 'O-WHITELIST-CERTIFICATE-TEMPLATES'

  // AttackTypes
  // None for now
}

export type CheckerOptionCodename = CheckerOptionCodenameEnum | string
