import * as React from 'react'
import styled from 'styled-components'
import type { BladeLayout } from '../types'

const StyledDiv = styled.div`
  .ant-menu {
    background-color: ${({ theme }) =>
      theme.tokens['menu/backgroundColor/default']};
  }

  .ant-menu-horizontal {
    border-bottom: ${({ theme }) => theme.tokens['menu/style/border']};
  }

  .ant-menu,
  .ant-menu-item,
  .ant-menu-item a,
  .ant-menu-item:hover,
  .ant-menu-item:hover a,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item a,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover a {
    color: ${({ theme }) => theme.tokens['menu/color/font']};
  }
`

interface IBladeContentMenuProps {
  style?: React.CSSProperties
  isLoading: boolean
  isGranted: boolean
  isError: boolean
  layout: BladeLayout
}

const BladeContentMenu: React.FC<IBladeContentMenuProps> = props => {
  if (
    !props.layout.menu ||
    props.isLoading ||
    !props.isGranted ||
    props.isError
  ) {
    return null
  }

  return (
    <StyledDiv data-name="BladeContentMenu" style={props.style}>
      {props.layout.menu}
    </StyledDiv>
  )
}

export default BladeContentMenu
