import type { Query } from '../typeDefs/types'

/**
 * Retrieve roles
 */
export type QueryRbacRoles = Pick<Query, 'rbacRoles'>

export const queryRbacRoles = `
  query queryRbacRoles(
    $roleId: Int
  ) {
    rbacRoles(
      roleId: $roleId
    ) {
      node {
        id
        name
        description
        permissions {
          entityName
          action
          entityIds
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Retrieve Rbac entities
 */
export type QueryEntitiesRbac = Pick<Query, 'entitiesRbac'>

export const queryRbacEntities = `
  query queryRbacPermissions {
    entitiesRbac {
      entityName
      getAllUrl
      actions {
        read {
          all
          id
        }
        edit {
          all
          id
        }
        create
      }
      items {
        __typename
        ...on Infrastructure {
          id
          name
        }
        ...on Directory {
          id
          name
        }
        ...on Checker {
          id
          name
        }
        ...on AttackType {
          id
          name
        }
        ...on Profile {
          id
          name
        }
        ...on User {
          id
          name
        }
        ...on Report {
          id
          name
        }
        ...on Email {
          id
          address
        }
        ...on Syslog {
          id
          ip
        }
        ...on RbacRole {
          id
          name
        }
        ...on Relay {
          id
          name
        }
        ...on Tenant {
          uuid
          name
        }
      }
    }
  }
`
