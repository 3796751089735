import { Features } from '@alsid/common'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import { accessByFeatureFlag, readUIAttackPath } from '@libs/rbac/permissions'

export const canAccessToAttackPath =
  rbacCapabilityCheckAllOf(readUIAttackPath())

export const canAccessToAttackPathTier0 = rbacCapabilityCheckAllOf(
  readUIAttackPath(),
  accessByFeatureFlag(Features.ATTACK_PATH_TIER0)()
)
