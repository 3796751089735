import type { Maybe } from '@productive-codebases/toolbox'

const logger = console

/**
 * Allow to implement all cases of a switch statement
 * and fallback gracefully by printing a warning message if
 * unknown value at runtime
 */
export function caseNotHandled<TValue extends never, TFallBackValue>(
  value: TValue,
  fallbackValue?: TFallBackValue,
  logMessage?: string
): Maybe<TFallBackValue> {
  const warningMessage =
    logMessage ||
    "Didn't expect to get here! Please handle the following value properly:"

  logger.warn(`${warningMessage} (${value}) of type ${typeof value}`)

  return fallbackValue ?? null
}
