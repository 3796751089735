import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import type StoreDashboard from '@app/stores/Dashboard/StoreDashboard'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import WidgetCommonForm from '../WidgetAddPage/WidgetCommonForm'
import { onCancelButtonClick, onWidgetEditionSubmit } from './handlers'

interface IWidgetEditFormProps {
  storeDashboard: StoreDashboard
}

const WidgetEditForm: React.FC<IWidgetEditFormProps> = props => {
  const appRouter = useAppRouter()

  const { storeBlades, storeDashboards } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Dashboard_Grid_WidgetEdit,
    parameters: {
      instanceName: String(),
      dashboardId: Number(),
      dashboardWidgetId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <WidgetCommonForm
      version="edition"
      storeDashboard={props.storeDashboard}
      onCancelButtonClick={onCancelButtonClick(storeBlades)(
        parameters.instanceName,
        parameters.dashboardId
      )}
      onSubmit={onWidgetEditionSubmit(
        storeDashboards,
        props.storeDashboard,
        appRouter
      )(parameters.dashboardId, parameters.dashboardWidgetId)}
    />
  )
}

export default observer(WidgetEditForm)
