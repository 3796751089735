import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import { createEntities, EntityHealthCheckAttribute } from '@app/entities/index'
import type {
  HealthCheckAttribute,
  HealthCheckDomainStatus,
  HealthCheckDomainStatusReason,
  HealthCheckStatus,
  Maybe
} from '@server/graphql/typeDefs/types'

export default class EntityHealthCheckDomainStatusReason
  extends EntityBase
  implements PropertiesNullable<HealthCheckDomainStatusReason>
{
  name: Maybe<string> = null
  status: Maybe<HealthCheckStatus> = null
  statusSince: Maybe<string> = null
  lastCheckDate: Maybe<string> = null
  attributes: Maybe<HealthCheckAttribute[]> = null

  constructor(data: Partial<HealthCheckDomainStatus>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return the attributes entities.
   */
  getAttributes(): Maybe<EntityHealthCheckAttribute[]> {
    if (!this.attributes) {
      return null
    }

    return createEntities<HealthCheckAttribute, EntityHealthCheckAttribute>(
      EntityHealthCheckAttribute,
      this.attributes
    )
  }
}
