import { useStores } from '@app/hooks'
import { canSeeDeviances } from '@app/pages/TrailFlow/permissions'
import type StoreInputExpression from '@app/stores/helpers/StoreInputExpression'
import { DEFAULT_RULEGROUP_PATH } from '@app/stores/helpers/StoreWizard/constants'
import * as React from 'react'
import styled from 'styled-components'
import { onFormContentLoad } from './handlers'
import RuleGroupFieldset from './RuleGroupFieldset'

interface IFormContentProps {
  className?: string
  storeInputExpression: StoreInputExpression
}

const FormContent: React.FC<IFormContentProps> = props => {
  const { storeRbac } = useStores()

  React.useEffect(() => {
    onFormContentLoad(props.storeInputExpression)
  }, [])

  const ruleGroup = props.storeInputExpression.storeWizard.getRuleGroup(
    DEFAULT_RULEGROUP_PATH
  )

  const enableIsDeviantSwitch =
    // show is deviant switch only if granted
    storeRbac.isUserGrantedTo(canSeeDeviances) &&
    props.storeInputExpression.options.enableIsDeviantSwitch === true

  return (
    <RuleGroupFieldset
      enableIsDeviantSwitch={enableIsDeviantSwitch}
      ruleGroupPath={DEFAULT_RULEGROUP_PATH}
      ruleGroup={ruleGroup}
      storeWizard={props.storeInputExpression.storeWizard}
    />
  )
}

export default styled(FormContent)`
  position: relative;
`
