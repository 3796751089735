import { SCENE_TOOLIP_DEBOUNCE_DELAY } from '@app/stores/Topology/consts'
import { consts } from '@app/styles'
import { TrustHazardLevel } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'

interface ITrustPathProps {
  className?: string
  uid: string
  drawString: string
  hazardLevel: TrustHazardLevel
  isHovered: boolean
  isHighlighted: boolean
  isZoomed: boolean
}

const TrustPath: React.FC<ITrustPathProps> = props => {
  return (
    <g className={props.className}>
      <path id={props.uid} d={props.drawString} />

      {/* Use to increase the tooltip surface area */}
      <path className="external" d={props.drawString} />
    </g>
  )
}

export default styled(TrustPath)`
  > path {
    stroke: ${props =>
      props.hazardLevel === TrustHazardLevel.Dangerous
        ? consts.colorRed001
        : props.hazardLevel === TrustHazardLevel.Unknown
          ? consts.colorOrange001
          : consts.colorBlue010};
    stroke-width: ${props => (props.isHovered ? '4' : '1')};
    stroke-opacity: ${props =>
      props.isHighlighted || props.isHovered ? 1 : 0.2};
    fill: none;
    transition: all ${SCENE_TOOLIP_DEBOUNCE_DELAY}ms linear;
    cursor: ${props =>
      props.hazardLevel === TrustHazardLevel.Dangerous ? 'pointer' : 'default'};

    /* Used to increase the tooltip surface area */
    &.external {
      stroke: transparent;
      stroke-width: ${props => (props.isZoomed ? '10' : '16')};
    }
  }
`
