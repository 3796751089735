import { ContainerFlex } from '@app/components/Container'
import { IconInfo } from '@app/components/Icon'
import { Label } from '@app/components/Labels'
import { WrapperAlert } from '@app/components/Wrapper'
import { useAppTranslator } from '@app/hooks'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

export interface IDrawerNodeRelationshipsInfoProps {
  className?: string
}

const DrawerNodeRelationshipsInfo: React.FC<
  IDrawerNodeRelationshipsInfoProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.Drawer']
  })

  return (
    <WrapperAlert
      className={props.className}
      labelledBy="DrawerNodeRelationshipsInfo"
      alertProps={{
        message: (
          <ContainerFlex
            name="DrawerNodeRelationshipsInfo"
            alignItems="center"
            justifyContent="center"
            items={[
              <IconInfo />,
              <Label color={consts.colorWhite}>
                {translate(
                  'Select one or more objects to display them on the graph'
                )}
              </Label>
            ]}
            flexGap="small"
          />
        )
      }}
    />
  )
}

export default styled(DrawerNodeRelationshipsInfo)`
  width: 100%;
`
