import { ContainerContent } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToSAMLProviderConfiguration } from '../permissions'
import { onLoadSAMLConfiguration, onUnLoadSAMLConfiguration } from './handlers'
import SAMLConfigurationForm from './SAMLConfigurationForm'

interface IConfigurationSAMLPageProps {}

const ConfigurationSAMLPage: React.FC<IConfigurationSAMLPageProps> = () => {
  const { storeManagementSAMLConfiguration, storeAbout } = useStores()

  return (
    <ContainerContent
      onLoad={onLoadSAMLConfiguration(storeManagementSAMLConfiguration)}
      onUnload={onUnLoadSAMLConfiguration(storeManagementSAMLConfiguration)}
      flags={storeManagementSAMLConfiguration.storeFlags.flags}
      rbacCapabilityCheck={canAccessToSAMLProviderConfiguration(storeAbout)}
    >
      <SAMLConfigurationForm />
    </ContainerContent>
  )
}

export default observer(ConfigurationSAMLPage)
