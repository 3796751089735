import type { MaybeUndef } from '@@types/helpers'
import { AppRouteName } from '@app/routes'
import type { StoreManagementInfrastructures } from '@app/stores'
import type StoreInfrastructures from '@app/stores/Management/StoreInfrastructures'
import type { AppRouterClient } from '@libs/Router/types'
import { debounce } from 'lodash'

export const onInfrastructuresLoad =
  (storeManagementInfrastructures: StoreInfrastructures) => () => {
    storeManagementInfrastructures.fetchInfrastructures()
  }

export const onInfrastructuresUnload =
  (storeManagementInfrastructures: StoreInfrastructures) => () => {
    storeManagementInfrastructures.reset()
  }

export const handleSearchInfrastructureOnChange = (
  storeManagementInfrastructures: StoreInfrastructures
) =>
  debounce(() => {
    storeManagementInfrastructures.fetchInfrastructuresWithName()
  }, 500)

export const handleSearchInfrastructureOnReset =
  (storeManagementInfrastructures: StoreInfrastructures) => () => {
    storeManagementInfrastructures.storeInputSearch.reset()
    storeManagementInfrastructures.fetchInfrastructures(
      { infrastructuresPage: 1 },
      storeManagementInfrastructures.storeSearchFlags
    )
  }

export const onAddInfrastructureClick = (appRouter: AppRouterClient) => () => {
  const url = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.Management_System_Infrastructures_Create,
    parameters: {}
  })
  appRouter.history.push(url)
}

export const onAddDirectoryClick = (appRouter: AppRouterClient) => () => {
  const url = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.Management_System_Directories_Create,
    parameters: {}
  })
  appRouter.history.push(url)
}

export const onEditInfrastructureIconClick =
  (appRouter: AppRouterClient) => (infrastructureId: number) => () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Infrastructures_Edit,
      parameters: {
        infrastructureId
      }
    })
    appRouter.history.push(url)
  }

export const onDeleteInfrastructureIconClick =
  (storeManagementInfrastructures: StoreManagementInfrastructures) =>
  (infrastructureId: number) =>
  () => {
    const infrastructureEntity =
      storeManagementInfrastructures.infrastructures.get(infrastructureId)

    if (infrastructureEntity) {
      storeManagementInfrastructures.storeDeleteDrawer
        .setData({ infrastructureDataRow: infrastructureEntity.asDataRow() })
        .openDrawer()
    }
  }

export const onPageChange =
  (storeManagementInfrastructures: StoreManagementInfrastructures) =>
  (page: number, pageSize: MaybeUndef<number>) => {
    storeManagementInfrastructures.fetchInfrastructures({
      infrastructuresPage: page,
      infrastructuresPerPage: pageSize
    })
  }
