import type { Maybe } from '@@types/helpers'
import { ContainerContent, ContainerFlex } from '@app/components/Container'
import { IconAssetExposure, IconSearch } from '@app/components/Icon'
import { Label, LabelSliced } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { WidgetListPagination } from '@app/components/Widgets/WidgetList'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import InputInfrastructures from '@app/components-legacy/Input/InputInfrastructures'
import { LabelDirectoriesTree } from '@app/components-legacy/Label'
import LabelDirectoryTag from '@app/components-legacy/Label/LabelDirectoryTag'
import { IDENTIFIER_COLUMN_NAME } from '@app/components-legacy/TableNew/consts'
import { Table } from '@app/components-legacy/TableNew/Table'
import type {
  TableColumnsProperties,
  TableRowData
} from '@app/components-legacy/TableNew/types'
import { useAppRouter, useAppTranslator, useStores } from '@app/hooks'
import NodeSearchIcon from '@app/pages/AttackPath/Components/NodeSearchIcon'
import { AppRouteName } from '@app/routes'
import { consts } from '@app/styles'
import { isDefined } from '@libs/isDefined'
import type { AttackPathAdObjectType } from '@server/graphql/typeDefs/types'
import { AttackPathDirection } from '@server/graphql/typeDefs/types'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  handleAttackPathTier0AssetsDirectoryChange,
  handleAttackPathTier0AssetsLoad,
  handleAttackPathTier0AssetsPaginationChange,
  handleAttackPathTier0AssetsUnload
} from './handlers'

const StyledLabel = styled(Label)`
  margin: 0 ${consts.marginDefault};
`

const StyledContainerFlex = styled(ContainerFlex)`
  margin: 0 ${consts.marginDefault};
`

const StyledWidgetListPagination = styled(WidgetListPagination)`
  margin: 0 ${consts.marginDefault};
`

interface IAttackPathTier0AssetsColumns {
  name: {
    name: string
    adObjectType: Maybe<AttackPathAdObjectType>
  }
  directoryId: Maybe<number>
  accountWithTier0Access: string
  exposure: string
  accountsLink: Maybe<number>
  assetExposureLink: Maybe<number>
}

interface IAttackPathTier0AssetsProps {
  className?: string
}

const AttackPathTier0Assets: React.FC<IAttackPathTier0AssetsProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.Tier0', 'AttackPath.Tier0.TableColumns']
  })

  const { storeAttackPath, storeInfrastructures } = useStores()

  const { storeAttackPathTier0 } = storeAttackPath

  const appRouter = useAppRouter()

  const columns: TableColumnsProperties<IAttackPathTier0AssetsColumns> = {
    name: {
      name: translate('Name'),
      relativeWidth: 35,
      render: ({ name, adObjectType }, availableWidth) => {
        return (
          <ContainerFlex
            name="AttackPathTier0AssetName"
            alignItems="center"
            items={[
              <NodeSearchIcon adObjectType={adObjectType} size={30} />,
              <Label variant={LabelVariant.p}>
                <LabelSliced
                  value={name}
                  maxWidth={availableWidth - (30 + 10)}
                />
              </Label>
            ]}
            spaced
            spaceWidth="small"
          />
        )
      }
    },
    directoryId: {
      name: translate('Domain'),
      relativeWidth: 25,
      render: (directoryId, availableWidth) => {
        const directory =
          directoryId && storeInfrastructures.getDirectoryFromId(directoryId)

        return (
          directory &&
          isDefined(directory.id) &&
          directory.name && (
            <LabelDirectoryTag
              color={directory.color}
              label={directory.name}
              tooltipLabel={
                <LabelDirectoriesTree
                  storeInfrastructures={storeInfrastructures}
                  directoryIds={[directory.id]}
                />
              }
              maxWidth={availableWidth}
            />
          )
        )
      }
    },
    accountWithTier0Access: {
      name: translate('Accounts with attack path'),
      relativeWidth: 20
    },
    exposure: {
      name: translate('Exposure'),
      relativeWidth: 20
    },
    accountsLink: {
      fixedWidth: 30,
      render: id => {
        if (!isDefined(id) || id < 0) {
          return null
        }

        const link = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.AttackPath_Tier0_Accounts,
          parameters: {},
          queryStringParameters: {
            tier0AssetId: String(id)
          }
        })

        return (
          <Tooltip title={translate('List accounts with attack path')}>
            <Link to={link}>
              <FormWrapperButton
                labelledBy={`tier-0-accounts-link-${id}}`}
                variant={ButtonVariant.tertiary}
                size={ButtonSize.small}
                icon={IconSearch}
                iconSize={20}
              />
            </Link>
          </Tooltip>
        )
      }
    },
    assetExposureLink: {
      fixedWidth: 30,
      render: id => {
        if (!isDefined(id) || id < 0) {
          return null
        }

        const link = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.AttackPath,
          parameters: {},
          queryStringParameters: {
            direction: AttackPathDirection.To,
            sourceNodeId: String(id)
          }
        })

        return (
          <Tooltip title={translate('Explore asset exposure')}>
            <a href={link}>
              <FormWrapperButton
                labelledBy={`asset-exposure-link-${id}}`}
                variant={ButtonVariant.tertiary}
                size={ButtonSize.small}
                icon={IconAssetExposure}
              />
            </a>
          </Tooltip>
        )
      }
    }
  }

  const data: Array<TableRowData<IAttackPathTier0AssetsColumns>> =
    storeAttackPathTier0.attackPathTier0Objects?.map(object => ({
      [IDENTIFIER_COLUMN_NAME]: object.getPropertyAsNumber('id'),
      name: {
        name: object.getPropertyAsString('name'),
        adObjectType: object.adObjectType
      },
      directoryId: object.directoryId,
      accountWithTier0Access: String(object.accountWithTier0Access),
      exposure:
        (isDefined(object.accountWithTier0Access) &&
          isDefined(object.accountTotal) &&
          `${(
            (object.accountWithTier0Access / object.accountTotal) *
            100
          ).toFixed(2)}%`) ||
        '',
      assetExposureLink: object.id,
      accountsLink: object.id
    })) || []

  return (
    <ContainerContent
      onLoad={handleAttackPathTier0AssetsLoad(storeAttackPathTier0)}
      onUnload={handleAttackPathTier0AssetsUnload(storeAttackPathTier0)}
    >
      <ContainerFlex
        className={props.className}
        name="AttackPathTier0Assets"
        direction="column"
        items={[
          <StyledLabel variant={LabelVariant.p}>
            {translate('Displays the natively privileged assets')}
          </StyledLabel>,
          <StyledContainerFlex
            name="AttackPathTier0AssetsFilters"
            justifyContent="flex-end"
            items={[
              <InputInfrastructures
                storeInfrastructures={storeAttackPathTier0.storeInfrastructures}
                onValidate={handleAttackPathTier0AssetsDirectoryChange(
                  storeAttackPathTier0
                )}
              />
            ]}
          />,
          <ContainerContent
            flags={storeAttackPathTier0.storeFlagsFetchTier0Objects.flags}
          >
            <Table
              labelledBy="attackPathTier0Assets"
              columns={columns}
              data={data}
            />
            <StyledWidgetListPagination
              onChange={handleAttackPathTier0AssetsPaginationChange(
                storeAttackPathTier0
              )}
              storeWidgetList={storeAttackPathTier0.storeWidgetListTier0Assets}
            />
          </ContainerContent>
        ]}
        spaced
      />
    </ContainerContent>
  )
}

export default observer(AttackPathTier0Assets)
