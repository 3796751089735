import { Features } from '@alsid/common'
import ContextStores from '@app/contexts/ContextStores'
import WidgetAlerts from '@app/pages/HeaderLegacy/Widgets/WidgetAlerts'
import WidgetHelp from '@app/pages/HeaderLegacy/Widgets/WidgetHelp'
import WidgetSettings from '@app/pages/HeaderLegacy/Widgets/WidgetSettings'
import WidgetUser from '@app/pages/HeaderLegacy/Widgets/WidgetUser'
import type { StoreRoot } from '@app/stores'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import { isDefined } from '@libs/isDefined'
import React from 'react'
import type { ITulConfig } from 'tenable-universal-layout-v5'
import {
  init as initTul,
  TenableProductLogo
} from 'tenable-universal-layout-v5'
import { emptyServerProperties, emptySession } from './emptyResponses'

export async function initializeTulLegacy(storeRoot: StoreRoot) {
  const { storePendo } = storeRoot.stores

  const featureWorkspaceAppswitcherInTad =
    storeRoot.stores.storeRbac.isUserGrantedAccordingFeatureFlag(
      Features.WORKSPACE_APPSWITCHER_IN_TAD
    )

  const featureTenableIdentityExposureVisuals =
    storeRoot.stores.storeRbac.isUserGrantedAccordingFeatureFlag(
      Features.TENABLE_IDENTITY_EXPOSURE_VISUALS
    )

  /**
   * Retrieve server properties from Kapteyn server middleware
   * (which queries Eridanis directly).
   */
  const serverProperties =
    featureWorkspaceAppswitcherInTad &&
    storeRoot.stores.storeAbout.productAssociation?.isAssociatedToTenableOne()
      ? await storeRoot.stores.storeAuthentication.fetchServerPropertiesLegacy()
      : null

  const cloudStatistics =
    await storeRoot.stores.storeAuthentication.fetchCloudStatistics()

  const pendoParameters = cloudStatistics?.getPendoParameters()

  const tulConfig: ITulConfig = {
    /**
     * FIXME.
     *
     * Feature-flags route is not (yet?) proxified by Kapteyn server and no mechanism are put in place in TUL
     * to target an another service (OpenApi prefix).
     * So the new BI app will never be displayed as TUL's FF are not fetched.
     *
     * The fix is maybe to fetch Tenable's FF in Kapteyn server first and to inject them here explicitely
     * instead of fetching FFs in TUL.
     */
    featureFlags: {
      enabled: false
    },

    services: {
      session: {
        enabled: true,

        fetchData: () => {
          return Promise.resolve(emptySession)
        },

        renderComponents: {
          accountPanel: () => {
            return (
              <DSThemeProvider themeName={DSThemeName.default}>
                <ContextStores.Provider value={{ storeRoot }}>
                  <WidgetUser />
                </ContextStores.Provider>
              </DSThemeProvider>
            )
          }
        },

        parameters: {
          // don't use Irontoken, only used by apps living on the Tenable workspace,
          // which is not the case of T.AD
          disableIronToken: true
        }
      },

      serverProperties: {
        enabled: featureWorkspaceAppswitcherInTad,

        fetchData: () => {
          return Promise.resolve(serverProperties ?? emptyServerProperties)
        },

        renderComponents: {
          // use T.AD navigation
          mainNavigationDrawer: false,
          // if no serverProperties, don't render appsPanel
          appsPanel: isDefined(serverProperties)
        },

        parameters: {
          workspaceDomain: storeRoot.environment.config.tul?.workspacedomain
        }
      },

      settings: {
        enabled: false
      },

      alerts: {
        renderComponents: {
          /**
           * Render custom Alerts panel.
           */
          alertsPanel: () => {
            return (
              <DSThemeProvider themeName={DSThemeName.default}>
                <ContextStores.Provider value={{ storeRoot }}>
                  {!storePendo.isPendoEnabled && <WidgetHelp />}

                  <WidgetSettings />

                  <div
                    style={{
                      paddingLeft: '8px'
                    }}
                  >
                    <WidgetAlerts />
                  </div>
                </ContextStores.Provider>
              </DSThemeProvider>
            )
          }
        }
      },

      telemetry: {
        enabled: false
      },

      pendo: {
        enabled: storePendo.isPendoEnabled,

        fetchData: () => {
          const tulPendoData = pendoParameters?.tulPendoData

          if (!tulPendoData) {
            throw new Error(
              'Cant initialize Pendo, check CloudStatistics parameters.'
            )
          }

          return Promise.resolve(tulPendoData)
        },

        parameters: {
          apikey:
            storeRoot.environment.config.tul?.services?.pendo?.parameters
              ?.apikey ?? ''
        },

        metadata: {
          visitor: [
            {
              name: 'adUiLanguage',
              value: storeRoot.appTranslator.language
            },
            {
              name: 'username',
              value:
                storeRoot.stores.storeAuthentication.cloudStatistics
                  ?.userUuid ?? ''
            },
            {
              name: 'email',
              value: storeRoot.stores.storeAuthentication.whoAmI?.email ?? ''
            }
          ],
          account: [
            {
              name: 'adSite',
              value:
                storeRoot.stores.storeAuthentication.cloudStatistics?.siteId ??
                ''
            },
            {
              name: 'adEnvironmentType',
              value:
                storeRoot.stores.storeAuthentication.cloudStatistics
                  ?.environmentType ?? ''
            },
            {
              name: 'adCustomerId',
              value:
                storeRoot.stores.storeAuthentication.cloudStatistics
                  ?.customerName ?? ''
            }
          ]
        }
      },

      streamio: {
        enabled: false
      }
    },

    app: {
      production: storeRoot.environment.config.production,

      useDefaultCSS: false,

      logo: featureTenableIdentityExposureVisuals
        ? {
            name: TenableProductLogo['IE-FullColor-RGB-logo'],
            path: '/w/assets/images/logos'
          }
        : undefined,

      handlers: {
        onNavigate: ({ url }) => {
          // Handle redirection of the AppsSwitcher
          document.location.href = url
        },

        /**
         * Set the expanded flag when clicking on a TUL's humburger icon.
         */
        onHamburgerClick: () => {
          const { storeMenuMainNavigation } = storeRoot.stores.storeLayout
          storeMenuMainNavigation.setExpanded(!storeMenuMainNavigation.expanded)
        }
      }
    }
  }

  return initTul('tenable-universal-layout', tulConfig)
}
