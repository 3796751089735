import * as React from 'react'
import styled from 'styled-components'

interface IDrawerAttributeValueContentPreProps {
  className?: string
  children?: React.ReactNode
}

const DrawerAttributeValueContentPre: React.FC<
  IDrawerAttributeValueContentPreProps
> = props => {
  return <pre className={props.className}>{props.children}</pre>
}

export default styled(DrawerAttributeValueContentPre)`
  /* Override default CSS */
  margin-bottom: 0;
`
