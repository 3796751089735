import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import type StoreDashboard from '@app/stores/Dashboard/StoreDashboard'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onCancelButtonClick, onWidgetAddSubmit } from './handlers'
import WidgetCommonForm from './WidgetCommonForm'

interface IWidgetAddFormProps {
  storeDashboard: StoreDashboard
}

const WidgetAddForm: React.FC<IWidgetAddFormProps> = props => {
  const { storeBlades, storeDashboards } = useStores()

  const appRouter = useAppRouter()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Dashboard_Grid,
    parameters: {
      instanceName: String(),
      dashboardId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <WidgetCommonForm
      version="creation"
      storeDashboard={props.storeDashboard}
      onCancelButtonClick={onCancelButtonClick(storeBlades)(
        parameters.instanceName,
        parameters.dashboardId
      )}
      onSubmit={onWidgetAddSubmit(
        appRouter,
        storeDashboards,
        props.storeDashboard
      )(parameters.dashboardId)}
    />
  )
}

export default observer(WidgetAddForm)
