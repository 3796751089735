import { LabelAlert } from '@app/components-legacy/Label'
import { EntityAlertIoA } from '@app/entities'
import { useLogger } from '@app/hooks'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { ensureArray } from '@libs/ensureArray'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { animated, useTransition } from 'react-spring'
import styled from 'styled-components'

export interface IPlaceHolderAlertsProps {
  className?: string
  alertsLifeTime: 5
}

const LabelContainer = styled(animated.div)`
  background-color: white;
  box-shadow: ${consts.shadowLarge};
`

const PlaceHolderAlertsIoA: React.FC<IPlaceHolderAlertsProps> = props => {
  const { storeCheckers, storeAlerts, storeInfrastructures } = useStores()

  const logger = useLogger()

  const alertEntities = [storeAlerts.storeAlertsIoA.liveAlert]

  const transitions = useTransition(alertEntities, 0, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return (
    <div data-name="PlaceHolderAlertsIoA" className={props.className}>
      {transitions.map(transitionProps => {
        const { item: alertEntity, key, props: styleProps } = transitionProps

        if (!(alertEntity instanceof EntityAlertIoA)) {
          return null
        }

        if (!alertEntity.attackTypeId) {
          logger.warn('Alert cant be displayed, the attack type is undefined.')

          // remove the liveAlert to be able to display the next one
          storeAlerts.storeAlertsIoE.removeLiveAlert()

          return null
        }

        const alertId = alertEntity.getPropertyAsNumber('id')

        const attackType =
          storeCheckers.storeInputCheckersAttacks.checkersById.get(
            alertEntity.attackTypeId
          )

        if (!attackType) {
          return null
        }

        return (
          <LabelContainer key={key} style={styleProps}>
            <LabelAlert
              id={alertId}
              checkerType={CheckerType.Attack}
              label={attackType.getPropertyAsString('name')}
              criticity={attackType.genericCriticity}
              directories={ensureArray(
                alertEntity.getDirectory(storeInfrastructures.directories)
              )}
              date={alertEntity.date}
              isClosable
              alertLifeTime={props.alertsLifeTime}
              storeInfrastructures={storeInfrastructures}
              storeGenericAlerts={storeAlerts.storeAlertsIoA}
            />
          </LabelContainer>
        )
      })}
    </div>
  )
}

const ObservedPlaceHolderAlerts = observer(PlaceHolderAlertsIoA)

export default styled(ObservedPlaceHolderAlerts)`
  position: absolute;
  z-index: ${consts.zIndexAlertsPlaceholder};
  bottom: 200px;
  left: 30px;
`
