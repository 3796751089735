import { ContainerFlex } from '@app/components/Container'
import { Blade, BladesContainer } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import InputInfrastructures from '@app/components-legacy/Input/InputInfrastructures'
import InputTenants from '@app/components-legacy/Input/InputTenants'
import { LayoutDefault } from '@app/components-legacy/Layout'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppTranslator, useStores } from '@app/hooks'
import { AppRouteName } from '@app/routes'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToIdentityExplorer } from '../permissions'
import IdentityExplorerList from './IdentityExplorerList'
import {
  handleIdentitiesFilteringOnSubmit,
  handleIdentityExplorerPageOnLoad,
  handleIdentityExplorerPageOnUnload
} from './IdentityExplorerList/handlers'
import SearchBar from './IdentityExplorerList/SearchBar'

interface IIdentityExplorerPageProps {}

const IdentityExplorerPage: React.FC<IIdentityExplorerPageProps> = props => {
  const {
    storeIdentityExplorer,
    storeInfrastructures,
    storeManagementAzureAD
  } = useStores()

  const translate = useAppTranslator({ namespaces: ['Common.Buttons'] })

  return (
    <LayoutDefault
      content={
        <ContainerFlex
          name="IdentityExplorerPage"
          items={[
            <BladesContainer>
              <Blade
                root={true}
                routeDefinition={{
                  routeName: AppRouteName.IdentityExplorer,
                  parameters: {}
                }}
                onLoad={handleIdentityExplorerPageOnLoad(storeIdentityExplorer)}
                onUnload={handleIdentityExplorerPageOnUnload(
                  storeIdentityExplorer
                )}
                flags={[
                  storeIdentityExplorer.storeFlagsFetchIdentities.flags,
                  storeIdentityExplorer.storeInfrastructures
                    .storeFlagsFetchInfrastructures.flags,
                  storeIdentityExplorer.storeInputTenants.storeFlagsFetchTenants
                    .flags
                ]}
                layout={{
                  name: 'default',
                  // return undefined here to avoid empty space and border if there is an error
                  header:
                    storeManagementAzureAD.isTenableCloudApiTokensWorking ? (
                      <ContainerFlex
                        name="IdentityExplorerMenu"
                        flexGap="default"
                        justifyContent="space-between"
                        items={[
                          <DSThemeProvider themeName={DSThemeName.default}>
                            <SearchBar />
                          </DSThemeProvider>,

                          <ContainerFlex
                            name="Inputs"
                            flexGap="default"
                            items={[
                              <InputTenants
                                storeInputTenants={
                                  storeIdentityExplorer.storeInputTenants
                                }
                                onSubmit={handleIdentitiesFilteringOnSubmit(
                                  storeIdentityExplorer
                                )}
                              />,

                              <InputInfrastructures
                                storeInfrastructures={storeInfrastructures}
                                onValidate={handleIdentitiesFilteringOnSubmit(
                                  storeIdentityExplorer
                                )}
                              />,

                              <FormWrapperButton
                                labelledBy="search-identities"
                                buttonProps={{
                                  type: 'primary',
                                  onClick: handleIdentitiesFilteringOnSubmit(
                                    storeIdentityExplorer
                                  )
                                }}
                              >
                                {translate('Refresh')}
                              </FormWrapperButton>
                            ]}
                          ></ContainerFlex>
                        ]}
                      />
                    ) : undefined,
                  content: (
                    <ContainerContent
                      flags={
                        storeIdentityExplorer.storeFlagsReloadIdentities.flags
                      }
                    >
                      <IdentityExplorerList />
                    </ContainerContent>
                  ),
                  footer: (
                    <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
                  )
                }}
                rbacCapabilityCheck={canAccessToIdentityExplorer}
              />
            </BladesContainer>
          ]}
        />
      }
    />
  )
}

export default observer(IdentityExplorerPage)
