import type { Mutation } from '@server/graphql/typeDefs/types'

// Edit one or more deviances

export type MutationEditDeviances = Pick<Mutation, 'editDeviances'>

export const mutationEditDeviances = `
  mutation mutationEditDeviances($deviances: [InputEditDeviance!]!) {
    editDeviances(
      deviances: $deviances
    ) {
      err
      status
    }
  }
`

// Edit deviances of one or more adObject.

export type MutationEditAdObjectDeviances = Pick<
  Mutation,
  'editAdObjectDeviances'
>

export const mutationEditAdObjectDeviances = `
  mutation mutationEditAdObjectDeviances(
    $deviances: [InputEditAdObjectDeviances!]!
  ) {
    editAdObjectDeviances(
      deviances: $deviances
    ) {
      err
      status
    }
  }
`

// Edit all deviances of a checker.

export type MutationEditCheckerDeviances = Pick<
  Mutation,
  'editCheckerDeviances'
>

export const mutationEditCheckerDeviances = `
  mutation mutationEditCheckerDeviances($deviances: InputEditCheckerDeviances!) {
    editCheckerDeviances(
      deviances: $deviances
    )
  }
`
