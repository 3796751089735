import type { Query } from '../typeDefs/types'

/**
 * Retrieve the list of attack types.
 */
export type QueryAttacksTypes = Pick<Query, 'rbacAttackTypes'>

export const queryAttacksTypes = `
  query queryRbacAttacksTypes(
    $profileId: Int!
    $optionsCodenames: [String!]!
  ) {
    rbacAttackTypes(
      profileId: $profileId
      optionsCodenames: $optionsCodenames
    ) {
      node {
        id
        name
        description
        mitreAttackDescription
        resources {
          name
          url
          type
        }
        yaraRules
        vectorTemplate
        vectorTemplateReplacements {
          name,
          valueType
        }
        options {
          id
          name
          description
          codename
          value
          valueType
          translations
          directoryId
          attackTypeId
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Retrieve all stats and cards data for the consolidated view.
 *
 * Example of parameters:
 *
 * {
 *   "profileId": 1,
 *   "period": "day",
 *   "dateStart": "2020-08-04T16:27:54.817Z",
 *   "directoryIds": [
 *     1
 *   ],
 *   "ioaSeries": [
 *     # [1, 2] are the critical IoAs
 *     [
 *       1,
 *       2
 *     ],
 *     # [3, 4] are the high IoAs
 *     [
 *       3,
 *       4
 *     ],
 *     ...
 *   ],
 *   "layout": "infrastructures"
 * }
 */

export type QueryAttacksSummary = Pick<
  Query,
  'rbacAttacksSummary' | 'attacksStats'
>

export const queryAttacksSummary = `
  query queryAttacksSummary(
    $profileId: Int!
    $dateStart: String!
    $dateEnd: String!
    $interval: String!
    $directoryIds: [Int!]!
    $ioaSeries: [[Int!]!]!
    $layout: AttacksSummaryLayout!
    $isClosed: Boolean
  ) {
    rbacAttacksSummary(
      profileId: $profileId
      dateStart: $dateStart
      dateEnd: $dateEnd
      interval: $interval
      directoryIds: $directoryIds
      layout: $layout
    ) {
      node {
        layout
        cards {
          directoryId
          chartType

          statsCounts(
            profileId: $profileId
            dateStart: $dateStart
            dateEnd: $dateEnd
            directoryIds: $directoryIds
            ioaSeries: $ioaSeries
            isClosed: $isClosed
          ) {
            timestampStart
            timestampEnd
            data {
              attackTypeId
              count
            }
            isClosed
          }
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }

    attacksStats(
      profileId: $profileId
      dateStart: $dateStart
      dateEnd: $dateEnd
      interval: $interval
      directoryIds: $directoryIds
      ioaSeries: $ioaSeries
      isClosed: $isClosed
    ) {
      attackTypeIds
      timestampStart
      timestampEnd
      data {
        timestamp
        count
      }
      isClosed
    }
  }
`

/**
 * Retrieve attacks stats.
 * (Use on lineChart component mount)
 */

export type QueryAttacksStats = Pick<Query, 'attacksStats'>

export const queryAttacksStats = `
  query queryAttacksStats(
    $profileId: Int!
    $dateStart: String!
    $dateEnd: String!
    $interval: String!
    $directoryIds: [Int!]!
    $ioaSeries: [[Int!]!]!
    $isClosed: Boolean
  ) {
    attacksStats(
      profileId: $profileId
      dateStart: $dateStart
      dateEnd: $dateEnd
      interval: $interval
      directoryIds: $directoryIds
      ioaSeries: $ioaSeries
      isClosed: $isClosed
    ) {
      attackTypeIds
      timestampStart
      timestampEnd
      data {
        timestamp
        count
      }
      isClosed
    }
  }
`

/**
 * Retrieve attacks stats counts.
 * (Used on the timeline when hovering a pill)
 */

export type QueryAttacksStatsCounts = Pick<Query, 'attacksStatsCounts'>

export const queryAttacksStatsCounts = `
  query queryAttacksStatsCounts(
    $profileId: Int!
    $dateStart: String!
    $dateEnd: String!
    $directoryIds: [Int!]!
    $ioaSeries: [[Int!]!]!
    $isClosed: Boolean
  ) {
    attacksStatsCounts(
      profileId: $profileId
      dateStart: $dateStart
      dateEnd: $dateEnd
      directoryIds: $directoryIds
      ioaSeries: $ioaSeries
      isClosed: $isClosed
    ) {
      timestampStart
      timestampEnd
      data {
        attackTypeId
        count
      }
      isClosed
    }
  }
`

/**
 * List attacks for the list of incidents.
 */

export type QueryRbacAttacks = Pick<Query, 'rbacAttacks'>

export const queryRbacAttacks = `
  query queryRbacAttacks(
    $attacksFilters: InputAttacksFilters!
    $limit: Int
    $orderBy: OrderBy!
  ) {
    rbacAttacks(
      attacksFilters: $attacksFilters
      limit: $limit
      orderBy: $orderBy
    ) {
      node {
        id
        date
        source {
          hostname
          ip
          type
        }
        destination {
          hostname
          ip
          type
        }
        vector {
          attributes {
            name
            value
            valueType
          }
        }
        attackTypeId
        directoryId
        isClosed
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Total number of attacks for the list of incidents.
 */

export type QueryRbacAttacksCount = Pick<Query, 'rbacAttacksCount'>

export const queryRbacAttacksCount = `
  query queryRbacAttacksCount(
    $attacksFilters: InputAttacksFilters!
  ) {
    rbacAttacksCount(
      attacksFilters: $attacksFilters
    ) {
      node {
        count
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * List attacks filters (to remove attacks).
 */

export type QueryAttacksRemoveFilters = Pick<Query, 'attacksRemoveFilters'>

export const queryAttacksRemoveFilters = `
  query queryAttacksRemoveFilters(
    $profileId: Int!
  ) {
    attacksRemoveFilters(
      profileId: $profileId
    ) {
      id
      resourceType
      resourceValue
      attackTypeId
    }
  }
`

/**
 * List attacks settings and related entities for the IoA configuration.
 */

export type QueryAttackTypeConfiguration = Pick<
  Query,
  'attackTypeConfiguration'
>

export const queryAttackTypeConfiguration = `
  query queryAttackTypeConfiguration {
    attackTypeConfiguration {
      configuration {
        attackTypeId
        directoryId
        detectedEvents
        autoDisabledForPerfAt
      }
      permissions {
        canEdit
      }
    }
  }
`

/**
 * IOA configuration.
 */

export type QueryIoaConfiguration = Pick<Query, 'ioaConfiguration'>

export const queryIoaConfiguration = `
   query queryIoaConfiguration {
    ioaConfiguration {
      scriptDownloadDate
      forcedManualIoaConfiguration
      customPastDelaySeconds
     }
   }
 `

/**
 * Retrieve attack types options for profiles customization.
 */

export type QueryAttackTypeOptions = Pick<Query, 'attackTypeOptions'>

export const queryAttackTypeOptions = `
  query queryAttackTypeOptions(
    $profileId: Int!
    $attackTypeId: Int!
  ) {
    attackTypeOptions(
      profileId: $profileId
      attackTypeId: $attackTypeId
    ) {
      id
      name
      description
      codename
      value
      valueType
      staged
      translations
      attackTypeId
      directoryId
    }
  }
`
