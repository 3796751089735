import { ContainerFlex } from '@app/components-legacy/Container'
import {
  TypographyFieldsetTitle,
  TypographySubTitle
} from '@app/components-legacy/Typography'
import { useAppRouter, useAppTranslator, useStores } from '@app/hooks'
import type { IIoABoardPDFRouteDefinition } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import FiltersTimeline from '../IoABoardPage/IoABoardFilters/FiltersTimeline'

interface IIoABoardFiltersPDFProps {}

const IoABoardFiltersPDF: React.FC<IIoABoardFiltersPDFProps> = () => {
  const { storeIoA } = useStores()

  const { storeTimeline } = storeIoA.storeBoard

  const translate = useAppTranslator({
    namespaces: ['IoA.BoardPDF']
  })
  const appRouter = useAppRouter()

  const queryStringParameters =
    appRouter.getCurrentRouteQueryStringParameters<
      IIoABoardPDFRouteDefinition['queryStringParameters']
    >()

  const useUtc = queryStringParameters?.useUtc === 'true'
  const utcText = useUtc ? ' UTC' : ''

  return (
    <ContainerFlex
      name={IoABoardFiltersPDF.name}
      direction="column"
      justifyContent="center"
      items={[
        <div>
          <TypographySubTitle>
            {translate('Period of time')} (
            {translate(storeTimeline.attacksSummaryPeriod)}
            {utcText})
          </TypographySubTitle>

          <TypographyFieldsetTitle>
            {storeTimeline.formatAttacksSummaryPeriodWithTimezone(
              useUtc,
              queryStringParameters?.timezone
            )}
          </TypographyFieldsetTitle>
        </div>,

        <ContainerFlex
          name="IoABoardFiltersPDFTimeline"
          direction="column"
          justifyContent="center"
          items={[
            <TypographySubTitle>
              {translate('Timeline of attacks for the period')}
            </TypographySubTitle>,

            <FiltersTimeline
              isLoading={
                storeIoA.storeBoard.storeFlagsReloadAttacksSummary.isLoading
              }
            />
          ]}
          spaced
          wrapItems
        />
      ]}
      spaced
      paddingH="default"
      paddingV="default"
    />
  )
}

export default observer(IoABoardFiltersPDF)
