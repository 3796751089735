import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import { BrandColorV2, FontColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

const Color1Path = styled.path`
  fill: ${FontColorV2.primary};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${consts.colorWhite};
  }
`

const Color2Path = styled.path`
  fill: ${BrandColorV2.decorativeGray};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${consts.colorFadedBlueNewDesign};
  }
`

interface IIconIdentityExplorerProps extends IIconProps {}

const IconIdentityExplorerLegacy: React.FC<
  IIconIdentityExplorerProps
> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Color2Path d="M8.33313 9.1665V7.50008C8.33313 7.03954 7.96063 6.6665 7.49991 6.6665C7.03919 6.6665 6.6667 7.03952 6.6667 7.50008V9.1665C6.6667 12.3888 4.05438 15 0.833214 15H0V16.6664H0.833214C4.97558 16.6664 8.33321 13.309 8.33321 9.16641L8.33313 9.1665Z" />
      <Color2Path d="M0.833036 18.3334C5.89598 18.3334 10 14.2294 10 9.16648V7.50005C10 6.11978 8.88027 5.00005 7.5 5.00005C6.11973 5.00005 5 6.11978 5 7.50005V9.16648C5 11.4682 3.13478 13.3334 0.833214 13.3334L0 13.3336V11.6667H0.833214C2.21348 11.6667 3.33321 10.5469 3.33321 9.16665V7.50005C3.33321 5.19884 5.19844 3.33362 7.5 3.33362C9.80049 3.33362 11.6668 5.19884 11.6668 7.50005V9.16647C11.6668 15.15 6.81679 20 0.833304 20H8.92367e-05V18.3335L0.833036 18.3334Z" />
      <Color2Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9743 9.95714C14.991 9.69571 15 9.43217 15 9.16643V7.5C15 3.35799 11.6413 0 7.5 0C3.35781 0 0 3.35799 0 7.5V9.16643C0 9.6275 0.372666 10 0.833215 10C1.29393 10 1.66679 9.62751 1.66679 9.16643V7.5C1.66679 4.27875 4.27911 1.66696 7.49982 1.66696C10.7209 1.66696 13.3329 4.27893 13.3329 7.5V9.16643C13.3329 12.9452 11.6519 16.3265 9.00068 18.6162C10.7083 17.5852 12.5731 16.3535 13.368 15.5037L13.3292 15.4649L13.6776 15.1165C14.2623 14.2569 14.6003 12.9537 14.7805 11.8013C14.7647 11.8054 14.7489 11.8096 14.7332 11.814C14.8157 11.3777 14.8832 10.9359 14.9296 10.488C14.9434 10.2904 14.9517 10.1109 14.9554 9.95536L14.9743 9.95714Z"
      />
      <Color1Path d="M9.92027 10.7703C11.2949 12.1449 13.5235 12.1449 14.8981 10.7703C16.2727 9.39573 16.2727 7.16709 14.8981 5.7925C13.5235 4.41791 11.2949 4.41791 9.92027 5.7925C8.54568 7.16709 8.54568 9.39573 9.92027 10.7703Z" />
      <Color1Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.40981 20C5.40981 16.1576 8.52466 13.0428 12.367 13.0428C16.2094 13.0428 19.3242 16.1576 19.3242 20H5.40981Z"
      />
    </svg>
  )
}

export default IconIdentityExplorerLegacy
