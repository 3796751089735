import { TagExpression } from '@app/components-legacy/Tag'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreWizard from '@app/stores/helpers/StoreWizard'
import { Expression } from '@libs/Expression'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { getDefinedDateRule } from './utils'
import { convertToExpressionDefinition } from './wizardParser'

const StyledTitle = styled.div`
  color: ${props => props.theme.tokens['inputExpression/color/textSecondary']};
  font-size: 11px;
  position: absolute;
  top: 5px;
`

const StyledTitlePlaceholder = styled.div`
  color: ${props => props.theme.tokens['inputExpression/color/textSecondary']};
  font-size: 11px;
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;
`

interface IFormPreviewerProps {
  className?: string
  storeWizard: StoreWizard
}

const FormPreviewer: React.FC<IFormPreviewerProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.Wizard']
  })

  const expression = new Expression().fromExpressionDefinition(
    convertToExpressionDefinition(
      props.storeWizard.values,
      props.storeWizard.isDeviant,
      props.storeWizard.deviantCombinator,
      getDefinedDateRule(props.storeWizard.dateRule)
    )
  )

  /** Parsed twice to remove first level parenthesis like "isDeviant: true OR (cn: toto)" */
  const displayableExpression = new Expression().fromString(
    expression.expressionAsString || ''
  )

  return (
    <div className={props.className}>
      <StyledTitle>{translate('Preview')}</StyledTitle>
      {!displayableExpression.isDefined ? (
        <StyledTitlePlaceholder>
          ({translate('Preview placeholder')})
        </StyledTitlePlaceholder>
      ) : (
        <TagExpression id="expression" expression={displayableExpression} />
      )}
    </div>
  )
}

const ObservedFormPreviewer = observer(FormPreviewer)

export default styled(ObservedFormPreviewer)`
  border: 1px solid
    ${props => props.theme.tokens['inputExpression/borderColor/default']};
  padding: 30px 10px;
  background-color: ${props =>
    props.theme.tokens['inputExpression/backgroundColor/default']};
  border-radius: 4px;
  text-align: left;
  margin-bottom: 5px;
  overflow-x: auto;
  overflow-y: hidden;
  height: 90px;
  position: relative;
`
