import type { Mutation } from '../typeDefs/types'

/**
 * Create a profile.
 */

export type MutationCreateProfile = Pick<Mutation, 'createProfile'>

export const mutationCreateProfile = `
  mutation mutationCreateProfile($profile: InputCreateProfile!) {
    createProfile(
      profile: $profile
    ) {
      id
      name
      deleted
    }
  }
`

/**
 * Copy a profile.
 */

export type MutationCopyProfile = Pick<Mutation, 'copyProfile'>

export const mutationCopyProfile = `
  mutation mutationCopyProfile($profile: InputCopyProfile!) {
    copyProfile(
      profile: $profile
    ) {
      id
      name
      deleted
    }
  }
`

/**
 * Edit a profile.
 */

export type MutationEditProfile = Pick<Mutation, 'editProfile'>

export const mutationEditProfile = `
  mutation mutationEditProfile($profile: InputEditProfile!) {
    editProfile(
      profile: $profile
    ) {
      id
      name
      deleted
    }
  }
`

/**
 * Unstage a profile.
 */

export type MutationUnstageProfile = Pick<Mutation, 'unstageProfile'>

export const mutationUnstageProfile = `
  mutation mutationUnstageProfile($profile: InputUnstageProfile!) {
    unstageProfile(
      profile: $profile
    )
  }
`

/**
 * Commit a profile.
 */

export type MutationCommitProfile = Pick<Mutation, 'commitProfile'>

export const mutationCommitProfile = `
  mutation mutationCommitProfile($profile: InputCommitProfile!) {
    commitProfile(
      profile: $profile
    )
  }
`

/**
 * Delete a profile.
 */

export type MutationDeleteProfile = Pick<Mutation, 'deleteProfile'>

export const mutationDeleteProfile = `
  mutation mutationDeleteProfile($profile: InputDeleteProfile!) {
    deleteProfile(
      profile: $profile
    )
  }
`

/**
 * Set checker-options on a profile.
 */

export type MutationSetProfileCheckerOptions = Pick<
  Mutation,
  'setProfileCheckerOptions'
>

export const mutationSetProfileCheckerOptions = `
  mutation mutationSetProfileCheckerOptions(
    $profileId: Int!
    $checkerType: CheckerType!
    $checkerId: Int!
    $checkerOptions: [InputSetProfileCheckerOption!]!
  ) {
    setProfileCheckerOptions(
      profileId: $profileId
      checkerType: $checkerType
      checkerId: $checkerId
      checkerOptions: $checkerOptions
    )
  }
`
