import type { EntityRbacRole } from '@app/entities'
import { EntityRbacPermission } from '@app/entities'
import type { StoreRoot } from '@app/stores'
import type StoreFlags from '@app/stores/helpers/StoreFlags'
import { isListConsideredAsEmpty } from '@app/stores/helpers/StoreWidgetList/utils'
import { isDefined, isNotFalsy } from '@libs/isDefined'
import { getLogger } from '@libs/logger'
import type {
  IGrantedEntity,
  IMaybeGrantedEntity,
  IRbacCapability,
  IRbacCapabilityCheckMeta,
  IRbacPermissionOption,
  RbacCapabilityCheck,
  RbacDynamicId,
  RbacEntityId,
  RbacEntityIds,
  RbacPermissionKey,
  RbacPermissions,
  RbacPermissionsWithEntityNumber,
  RbacSideEffectPermissionKey,
  RbacSideEffectPermissions
} from '@libs/rbac/types'
import { RbacEntityItemType, RbacPermissionType } from '@libs/rbac/types'
import { isTruthy } from '@productive-codebases/toolbox'
import type { Maybe, RbacEntity } from '@server/graphql/typeDefs/types'
import { RbacAction, RbacEntityName } from '@server/graphql/typeDefs/types'
import { flatMap, values } from 'lodash'
import { selfUserRbacEntityNames, singletonRbacEntityNames } from './consts'

const logger = getLogger('rbacCapability')

/**
 * Check that access if granted according to passed permissions.
 */
export function rbacCapability(
  rbacPermissions: RbacPermissionsWithEntityNumber,
  meta?: IRbacCapabilityCheckMeta
) {
  return (
    rbacEntityName: RbacEntityName,
    rbacAction: RbacAction,
    rbacEntityId?: Maybe<RbacEntityId | number>,
    rbacPermissionOption?: Maybe<IRbacPermissionOption>
  ): IRbacCapability => {
    const rbacPermissionKey = buildRbacPermissionKey(
      rbacEntityName,
      rbacAction,
      isDefined(rbacEntityId) ? [String(rbacEntityId)] : null
    )

    const isGrantedForAskPermission = rbacPermissions.has(rbacPermissionKey)
    const message = rbacPermissionOption?.message || null

    if (isGrantedForAskPermission) {
      return {
        isGranted: true,
        message
      }
    }

    // if not allowed, check if allowed for all entities
    const rbacPermissionKeyForAllEntities = buildRbacPermissionKey(
      rbacEntityName,
      rbacAction,
      null
    )

    const isGrantedForAll = rbacPermissions.has(rbacPermissionKeyForAllEntities)

    if (isGrantedForAll) {
      return {
        isGranted: true,
        message
      }
    }

    if (rbacPermissionOption?.strict === true) {
      return {
        isGranted: false,
        message
      }
    }

    // if not allowed, check if allowed for a match
    if (!isDefined(rbacEntityId)) {
      const isGrantedForOneMatch = rbacCapabilityMatch(rbacPermissions)(
        rbacEntityName,
        rbacAction
      ).isGranted

      if (isGrantedForOneMatch) {
        return {
          isGranted: true,
          message
        }
      }
    }

    const logMessage = `Access denied (Missing permission ${rbacPermissionKey})`

    return {
      isGranted: false,
      message,
      logMessage
    }
  }
}

/**
 * Check that access if granted if, according to passed permissions,
 * the entityName/action match an existing permission.
 *
 * It's different that the `check` function in the way that `checkMatch` will
 * return a grant check if a permission matchs the asked permission,
 * for an entity or not.
 */
export function rbacCapabilityMatch(
  rbacPermissions: RbacPermissionsWithEntityNumber
) {
  return (
    rbacEntityName: RbacEntityName,
    rbacAction: RbacAction,
    rbacEntityId?: Maybe<RbacEntityId>,
    rbacPermissionOption?: Maybe<IRbacPermissionOption>
  ): IRbacCapability => {
    const regexps = [
      new RegExp(`^${rbacAction}:${rbacEntityName}$`),
      new RegExp(
        `^${rbacAction}:${rbacEntityName}:(?:[\\d\\*]+|[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$`
      )
    ]

    const isGrantedForAskPermission = Array.from(rbacPermissions.keys()).some(
      permissionKey => {
        return regexps.some(regexp => regexp.test(permissionKey))
      }
    )

    const message =
      (rbacPermissionOption && rbacPermissionOption.message) || null

    if (isGrantedForAskPermission) {
      return {
        isGranted: true,
        message
      }
    }

    return {
      isGranted: false,
      message
    }
  }
}

/**
 * Check that all Rbac checks are granted or not.
 */
export function rbacCapabilityAllOf(
  ...rbacChecks: IRbacCapability[]
): IRbacCapability {
  const grantedMessages: Array<Maybe<string>> = []
  const deniedMessages: Array<Maybe<string>> = []

  const isAllGranted = rbacChecks.reduce((isGrantedForPermission, check) => {
    check.isGranted
      ? grantedMessages.push(check.message)
      : deniedMessages.push(check.message)

    return isGrantedForPermission && check.isGranted
  }, true)

  if (isAllGranted) {
    return {
      isGranted: true,
      message: grantedMessages.filter(isDefined).pop() || null
    }
  }

  return {
    isGranted: false,
    message: deniedMessages.filter(isDefined).pop() || null
  }
}

/**
 * Check that at least one Rbac check is granted.
 */
export function rbacCapabilityAnyOf(
  ...rbacChecks: IRbacCapability[]
): IRbacCapability {
  const grantedMessages: Array<Maybe<string>> = []
  const deniedMessages: Array<Maybe<string>> = []

  const isSomeGranted = rbacChecks.reduce((isGrantedForPermission, check) => {
    check.isGranted
      ? grantedMessages.push(check.message)
      : deniedMessages.push(check.message)

    return isGrantedForPermission || check.isGranted
  }, false)

  if (isSomeGranted) {
    return {
      isGranted: true,
      message: grantedMessages.filter(isDefined).pop() || null
    }
  }

  return {
    isGranted: false,
    message: deniedMessages.filter(isDefined).pop() || null
  }
}

/**
 * Check that at least one Rbac check function is granted.
 *
 * Usage:
 *
 * import { editUser, readUsers } from '@libs/rbac/permissions'
 *
 * <Blade
 *   ...
 *   rbacCapabilityCheck={rbacCapabilityCheckAnyOf(editUser(), readUsers())}
 * />
 */
export function rbacCapabilityCheckAnyOf(
  ...rbacCapabilityChecks: RbacCapabilityCheck[]
): RbacCapabilityCheck {
  const defaultRbacCheck: RbacCapabilityCheck = (/* rbacPermissions */) => {
    return {
      isGranted: false,
      message: null,
      logMessage: null
    }
  }

  return rbacCapabilityChecks.reduce<RbacCapabilityCheck>(
    (acc, _rbacCapabilityCheck) => {
      return (rbacPermissions, meta) => {
        const previousCapability = acc(rbacPermissions, meta)
        const currentCapability = _rbacCapabilityCheck(rbacPermissions, meta)
        const logMessage = isDefined(previousCapability.logMessage)
          ? [previousCapability.logMessage, currentCapability.logMessage]
              .filter(isTruthy)
              .join('; ')
          : null

        return {
          isGranted:
            previousCapability.isGranted || currentCapability.isGranted,
          message: null,
          logMessage
        }
      }
    },
    defaultRbacCheck
  )
}

/**
 * Check that all Rbac check functions are granted.
 *
 * Usage:
 *
 * import { editUser, readUsers } from '@libs/rbac/permissions'
 *
 * <Blade
 *   ...
 *   rbacCapabilityCheck={rbacCapabilityCheckAllOf(readUsers(), editUser())}
 * />
 */
export function rbacCapabilityCheckAllOf(
  ...rbacCapabilityChecks: RbacCapabilityCheck[]
): RbacCapabilityCheck {
  const defaultRbacCheck: RbacCapabilityCheck = (/* rbacPermissions */) => {
    return {
      isGranted: true,
      message: null,
      logMessage: null
    }
  }

  return rbacCapabilityChecks.reduce<RbacCapabilityCheck>(
    (acc, _rbacCapabilityCheck) => {
      return (rbacPermissions, meta) => {
        const previousCapability = acc(rbacPermissions, meta)
        const currentCapability = _rbacCapabilityCheck(rbacPermissions, meta)
        const logMessage = isDefined(previousCapability.logMessage)
          ? [previousCapability.logMessage, currentCapability.logMessage]
              .filter(isTruthy)
              .join('; ')
          : null

        return {
          isGranted:
            previousCapability.isGranted && currentCapability.isGranted,
          message: null,
          logMessage
        }
      }
    },
    defaultRbacCheck
  )
}

/**
 * Return true if the entity name is for UI.
 */
export function isRbacUIEntity(rbacEntityName: RbacEntityName): boolean {
  return /^ui/i.test(rbacEntityName)
}

/**
 * Return a function that returns true if the entity is a self-user one.
 */
export function isRbacSelfUserEntity(rbacEntityName: RbacEntityName): boolean {
  return selfUserRbacEntityNames.indexOf(rbacEntityName) !== -1
}

/**
 * Return a function that returns true if the entity is a singleton one.
 */
export function isSingletonEntity(rbacEntityName: RbacEntityName): boolean {
  return singletonRbacEntityNames.indexOf(rbacEntityName) > -1
}

/**
 * Return a function that returns true if the entity is a singleton one
 * (where permissions can't be set on a specific ID).
 */
export function isRbacSingletonEntity(allRbacEntities: RbacEntity[]) {
  return (rbacEntityName: RbacEntityName): boolean => {
    if (isRbacSelfUserEntity(rbacEntityName)) {
      return false
    }

    const rbacEntities = allRbacEntities.filter(
      entity => entity.entityName === rbacEntityName
    )

    if (!rbacEntities.length) {
      return false
    }

    const actions = rbacEntities[0].actions

    if (!actions) {
      return false
    }

    return (
      // allows only to modify all entities
      actions.read.id === false && actions.edit.id === false
    )
  }
}

/**
 * Compute the list of permissions from a set of roles.
 */
export function computeRbacPermissions(
  rbacRoleEntities: EntityRbacRole[]
): RbacPermissions {
  const rbacPermissionsEntities = flatMap(
    rbacRoleEntities.map(role => role.getPermissions())
  )

  const rbacPermissions: RbacPermissions = new Map()

  rbacPermissionsEntities.forEach(permission => {
    // if entityIds is null, set a permission for all entities
    if (!permission.entityIds) {
      const rbacPermissionKey = buildRbacPermissionKey(
        permission.getPropertyAsT<RbacEntityName>('entityName'),
        permission.getPropertyAsT<RbacAction>('action')
      )
      rbacPermissions.set(rbacPermissionKey, permission)

      return
    }

    // if entityIds are defined, set as many permissions as entityId
    permission.entityIds.forEach(entityId => {
      const rbacPermissionKey = buildRbacPermissionKey(
        permission.getPropertyAsT<RbacEntityName>('entityName'),
        permission.getPropertyAsT<RbacAction>('action'),
        [entityId]
      )

      rbacPermissions.set(rbacPermissionKey, permission)
    })
  })

  return rbacPermissions
}

/**
 * Build a Rbac permission key.
 */
export function buildRbacPermissionKey(
  rbacEntityName: RbacEntityName | RbacEntityItemType,
  rbacAction: RbacAction,
  rbacEntityIds?: RbacEntityIds | number[]
): RbacPermissionKey {
  const entityIds = !isDefined(rbacEntityIds) ? '*' : rbacEntityIds.join(',')
  return [rbacAction, rbacEntityName, entityIds].filter(isNotFalsy).join(':')
}

/**
 * Build a Rbac side-effect permission key.
 */
export function buildRbacSideEffectPermissionKey(
  permissionType: RbacPermissionType,
  rbacEntityName: RbacEntityName | RbacEntityItemType,
  rbacAction: RbacAction,
  rbacEntityIds?: RbacEntityIds
): RbacPermissionKey {
  return [
    permissionType,
    buildRbacPermissionKey(rbacEntityName, rbacAction, rbacEntityIds)
  ].join(':')
}

/**
 * Retrieve data from a permission key.
 */
export function explodeRbacPermissionKey(
  rbacPermissionKey: RbacPermissionKey
): {
  rbacEntityName: RbacEntityName
  rbacAction: RbacAction
  rbacEntityIds: RbacEntityIds
  rbacDynamicId?: RbacDynamicId
} {
  const [rbacAction, rbacEntityName, maybeRbacEntityIds, maybeRbacDynamicId] =
    rbacPermissionKey.split(':')

  const rbacEntityIds = isNotFalsy(maybeRbacEntityIds)
    ? maybeRbacEntityIds.split(',').map(id => String(id))
    : null

  return {
    rbacAction: rbacAction as RbacAction,
    rbacEntityName: rbacEntityName as RbacEntityName,
    rbacEntityIds,
    rbacDynamicId: maybeRbacDynamicId as RbacDynamicId
  }
}

/**
 * When adding / removing some permissions, other permissions might be
 * added / removed as well.
 *
 * Return a function that returns an array of side-effect permissions
 * according to the type of permissions passed as parameters.
 */
export function getRbacSideEffectPermissions() {
  const rbacSideEffectPermissions = new Map<
    RbacSideEffectPermissionKey,
    RbacSideEffectPermissions
  >()

  values(RbacEntityName).forEach(localRbacEntityName => {
    if (isRbacUIEntity(localRbacEntityName)) {
      return
    }

    // if grant "create" => grant "edit", "read"
    rbacSideEffectPermissions.set(
      buildRbacSideEffectPermissionKey(
        RbacPermissionType.grant,
        localRbacEntityName,
        RbacAction.Create
      ),
      new Map([
        [
          RbacPermissionType.grant,
          [
            {
              rbacEntityName: localRbacEntityName,
              rbacAction: RbacAction.Edit
            },
            {
              rbacEntityName: localRbacEntityName,
              rbacAction: RbacAction.Read
            }
          ]
        ]
      ])
    )

    // if grant "edit" => grant "read"
    rbacSideEffectPermissions.set(
      buildRbacSideEffectPermissionKey(
        RbacPermissionType.grant,
        localRbacEntityName,
        RbacAction.Edit
      ),
      new Map([
        [
          RbacPermissionType.grant,
          [
            {
              rbacEntityName: localRbacEntityName,
              rbacAction: RbacAction.Read
            }
          ]
        ]
      ])
    )

    // if unauthorize "read" => unauthorize "edit", "create"
    rbacSideEffectPermissions.set(
      buildRbacSideEffectPermissionKey(
        RbacPermissionType.unauthorize,
        localRbacEntityName,
        RbacAction.Read
      ),
      new Map([
        [
          RbacPermissionType.unauthorize,
          [
            {
              rbacEntityName: localRbacEntityName,
              rbacAction: RbacAction.Edit
            },
            {
              rbacEntityName: localRbacEntityName,
              rbacAction: RbacAction.Create
            }
          ]
        ]
      ])
    )

    // if unauthorize "edit" => unauthorize "create"
    rbacSideEffectPermissions.set(
      buildRbacSideEffectPermissionKey(
        RbacPermissionType.unauthorize,
        localRbacEntityName,
        RbacAction.Edit
      ),
      new Map([
        [
          RbacPermissionType.unauthorize,
          [
            {
              rbacEntityName: localRbacEntityName,
              rbacAction: RbacAction.Create
            }
          ]
        ]
      ])
    )
  })

  /* UI side-effect permissions */

  // When granted Account Profile => Grant Account
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiAccountsProfiles,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiAccounts,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted Account Roles => Grant Account
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiAccountsRoles,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiAccounts,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted Account Users => Grant Account
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiAccountsUsers,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiAccounts,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted IoeDeviantElements => Grant Ioe
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiIoeDeviantElements,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiIoe,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted IoeDeviantElementsExport => Grant Ioe, IoEDeviantElements
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiIoeDeviantElementsExport,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiIoeDeviantElements,
            rbacAction: RbacAction.Read
          },
          {
            rbacEntityName: RbacEntityName.UiIoe,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted IoeDeviantElementsSetIgnoreDate => Grant Ioe, IoEDeviantElements
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiIoeDeviantElementsSetIgnoreDate,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiIoeDeviantElements,
            rbacAction: RbacAction.Read
          },
          {
            rbacEntityName: RbacEntityName.UiIoe,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted IoeInformations => Grant Ioe
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiIoeInformations,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiIoe,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted IoeRecommandations => Grant Ioe
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiIoeRecommandations,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiIoe,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted IoeVulnerabilityDetails => Grant Ioe
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiIoeVulnerabilityDetails,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiIoe,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted SystemAbout => Grant System
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiSystemAbout,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiSystem,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted SystemConfiguration => Grant System
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiSystemConfiguration,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiSystem,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted SystemConfigurationSmtpServer => Grant SystemConfiguration, System
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiSystemConfigurationSmtpServer,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiSystemConfiguration,
            rbacAction: RbacAction.Read
          },
          {
            rbacEntityName: RbacEntityName.UiSystem,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted SystemConfigurationLogs => Grant SystemConfiguration, System
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiSystemConfigurationLogs,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiSystemConfiguration,
            rbacAction: RbacAction.Read
          },
          {
            rbacEntityName: RbacEntityName.UiSystem,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted SystemConfigurationAlertsEmail => Grant SystemConfiguration, System
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiSystemConfigurationAlertsEmail,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiSystemConfiguration,
            rbacAction: RbacAction.Read
          },
          {
            rbacEntityName: RbacEntityName.UiSystem,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted SystemConfigurationAlertsSyslog => Grant SystemConfiguration, System
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiSystemConfigurationAlertsSyslog,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiSystemConfiguration,
            rbacAction: RbacAction.Read
          },
          {
            rbacEntityName: RbacEntityName.UiSystem,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted SystemConfigurationTenableAccount => Grant SystemConfiguration, System
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiSystemConfigurationAlertsSyslog,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiSystemConfiguration,
            rbacAction: RbacAction.Read
          },
          {
            rbacEntityName: RbacEntityName.UiSystem,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted SystemConfigurationLdap => Grant SystemConfiguration, System
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiSystemConfigurationTenableAccount,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiSystemConfiguration,
            rbacAction: RbacAction.Read
          },
          {
            rbacEntityName: RbacEntityName.UiSystem,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted SystemConfigurationLdap => Grant SystemConfiguration, System
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiSystemConfigurationLdap,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiSystemConfiguration,
            rbacAction: RbacAction.Read
          },
          {
            rbacEntityName: RbacEntityName.UiSystem,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted SystemConfigurationSaml => Grant SystemConfiguration, System
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiSystemConfigurationSaml,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiSystemConfiguration,
            rbacAction: RbacAction.Read
          },
          {
            rbacEntityName: RbacEntityName.UiSystem,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted SystemDirectories => Grant System
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiSystemDirectories,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiSystem,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  // When granted SystemInfrastructures => Grant System
  rbacSideEffectPermissions.set(
    buildRbacSideEffectPermissionKey(
      RbacPermissionType.grant,
      RbacEntityName.UiSystemInfrastructures,
      RbacAction.Read
    ),
    new Map([
      [
        RbacPermissionType.grant,
        [
          {
            rbacEntityName: RbacEntityName.UiSystem,
            rbacAction: RbacAction.Read
          }
        ]
      ]
    ])
  )

  const isRbacPermissionInvalidFn = isRbacPermissionInvalid()

  return (
    permissionType: RbacPermissionType,
    rbacEntityName: RbacEntityName,
    rbacAction: RbacAction
  ): RbacSideEffectPermissions => {
    const rbacSideEffectPermissionKey = buildRbacSideEffectPermissionKey(
      permissionType,
      rbacEntityName,
      rbacAction
    )

    const matchedSideEffectRbacPermissions = rbacSideEffectPermissions.get(
      rbacSideEffectPermissionKey
    )

    if (!matchedSideEffectRbacPermissions) {
      return new Map()
    }

    // keep only valid permissions
    Array.from(matchedSideEffectRbacPermissions.entries()).forEach(
      ([localPermissionType, localPermissions]) => {
        matchedSideEffectRbacPermissions.set(
          localPermissionType,
          localPermissions.filter(p => {
            return !isRbacPermissionInvalidFn(p.rbacEntityName, p.rbacAction)
          })
        )
      }
    )

    return matchedSideEffectRbacPermissions
  }
}

/**
 * Mutate pendingRbacPermissions to add/remove permissions on
 * checkerOption / attackTypeOption entities,
 * according to the current permissions set on profile entity.
 *
 * See why: https://github.com/AlsidOfficial/AlsidForAD-Cassiopeia/issues/3739
 */
export function mergeRbacCheckerOptionsPermissions(
  rbacPermissions: RbacPermissions
): void {
  const isGrantedToEditOneProfile = Array.from(rbacPermissions.keys()).some(
    rbacPermissionKey => {
      const { rbacEntityName, rbacAction } =
        explodeRbacPermissionKey(rbacPermissionKey)
      return (
        rbacEntityName === RbacEntityName.Profile &&
        rbacAction === RbacAction.Edit
      )
    }
  )

  const entities = [
    RbacEntityName.CheckerOption,
    RbacEntityName.AttackTypeOption
  ]

  entities.forEach(entityName => {
    const rbacPermissionOnCheckerOption = new EntityRbacPermission({
      entityName,
      action: RbacAction.Create,
      entityIds: null
    })

    // if at least one profile is granted, add create:checkerOption:* / create:attackTypeOption:*
    if (isGrantedToEditOneProfile) {
      rbacPermissions.set(
        rbacPermissionOnCheckerOption.buildRbacPermissionKey(),
        rbacPermissionOnCheckerOption
      )
      return
    }

    // if not, remove create:checkerOption:* / create:attackTypeOption:*
    rbacPermissions.delete(
      rbacPermissionOnCheckerOption.buildRbacPermissionKey()
    )
  })
}

/**
 * Return a function that checks the invalidity of a permission key.
 */
export function isRbacPermissionInvalid() {
  const invalidPermissionsKeys = new Map<RbacPermissionKey, boolean>([
    /**
     * Data entities
     */

    // can't edit or create a checker
    [buildRbacPermissionKey(RbacEntityName.Checker, RbacAction.Edit), false],
    [buildRbacPermissionKey(RbacEntityName.Checker, RbacAction.Create), false],

    // can't edit or create an attackType
    [buildRbacPermissionKey(RbacEntityName.AttackType, RbacAction.Edit), false],
    [
      buildRbacPermissionKey(RbacEntityName.AttackType, RbacAction.Create),
      false
    ],

    /**
     * Self-user entities
     */

    // can't modify the read on preferences, it's granted by default
    [buildRbacPermissionKey(RbacEntityName.Preference, RbacAction.Read), false],
    // can't create preferences
    [
      buildRbacPermissionKey(RbacEntityName.Preference, RbacAction.Create),
      false
    ],
    // can't create API key
    [buildRbacPermissionKey(RbacEntityName.ApiKey, RbacAction.Create), false],

    // can't modify the read on personal informations, it's granted by default
    [buildRbacPermissionKey(RbacEntityName.SelfUser, RbacAction.Read), false],
    // can't create personal informations
    [buildRbacPermissionKey(RbacEntityName.SelfUser, RbacAction.Create), false],

    // can't modify the read on dashboards / widget, it's granted by default (by user)
    [buildRbacPermissionKey(RbacEntityName.Dashboard, RbacAction.Read), false],
    [buildRbacPermissionKey(RbacEntityName.Widget, RbacAction.Read), false],

    /**
     * Singleton entities
     */

    // can't modify the edition and creation of Score singleton entity, it's a readonly permission
    [buildRbacPermissionKey(RbacEntityName.Score, RbacAction.Edit), false],
    [buildRbacPermissionKey(RbacEntityName.Score, RbacAction.Create), false],

    // can't modify the edition and creation of Topology singleton entity, it's a readonly permission
    [buildRbacPermissionKey(RbacEntityName.Topology, RbacAction.Edit), false],
    [buildRbacPermissionKey(RbacEntityName.Topology, RbacAction.Create), false],

    // can't modify the creation of DataCollectionConfiguration singleton entity
    [
      buildRbacPermissionKey(
        RbacEntityName.DataCollectionConfiguration,
        RbacAction.Create
      ),
      false
    ],

    // can't modify the creation of Relay singleton entity
    [buildRbacPermissionKey(RbacEntityName.Relay, RbacAction.Create), false],

    // can't modify the edition and creation of SelfActivityLogs singleton entity, it's a readonly permission
    [
      buildRbacPermissionKey(RbacEntityName.SelfActivityLogs, RbacAction.Edit),
      false
    ],
    [
      buildRbacPermissionKey(
        RbacEntityName.SelfActivityLogs,
        RbacAction.Create
      ),
      false
    ],

    // can't create ApplicationSetting singleton entity
    [
      buildRbacPermissionKey(
        RbacEntityName.ApplicationSetting,
        RbacAction.Create
      ),
      false
    ],

    // can't create LdapConfiguration singleton entity
    [
      buildRbacPermissionKey(
        RbacEntityName.LdapConfiguration,
        RbacAction.Create
      ),
      false
    ],

    // can't create SamlConfiguration singleton entity
    [
      buildRbacPermissionKey(
        RbacEntityName.SamlConfiguration,
        RbacAction.Create
      ),
      false
    ],

    // can't create License singleton entity
    [buildRbacPermissionKey(RbacEntityName.License, RbacAction.Create), false],

    [
      buildRbacPermissionKey(
        RbacEntityName.DirectoryRecrawl,
        RbacAction.Create
      ),
      false
    ],
    [
      buildRbacPermissionKey(RbacEntityName.DirectoryRecrawl, RbacAction.Edit),
      false
    ],

    // can't modify the edition and creation of HealthCheck singleton entity, it's a readonly permission
    [
      buildRbacPermissionKey(RbacEntityName.HealthCheck, RbacAction.Edit),
      false
    ],
    [
      buildRbacPermissionKey(RbacEntityName.HealthCheck, RbacAction.Create),
      false
    ],

    /**
     * UI entities
     */

    // can't create or modify UI entities
    [buildRbacPermissionKey(RbacEntityItemType.ui, RbacAction.Create), false],
    [buildRbacPermissionKey(RbacEntityItemType.ui, RbacAction.Edit), false]
  ])

  return (
    rbacEntityName: RbacEntityName | RbacEntityItemType,
    rbacAction: RbacAction,
    rbacEntityIds?: RbacEntityIds
  ): boolean => {
    // "Empty items" are invalid and should be not send to API
    if (isListConsideredAsEmpty(rbacEntityIds)) {
      return true
    }

    const permissionKey = buildRbacPermissionKey(rbacEntityName, rbacAction)
    return invalidPermissionsKeys.has(permissionKey)
  }
}

/**
 * Ensure that permissions are valid, remove some of them if needed
 * and return an array of valid permissions to be sent to the API.
 *
 * This functions mutates pendingRbacPermissions.
 */
export function sanitizeRbacPermissions(
  pendingRbacPermissions: RbacPermissions
): EntityRbacPermission[] {
  const userEditionSanitizor = () => {
    const hasSelfUserEditPermission = pendingRbacPermissions.has(
      buildRbacPermissionKey(RbacEntityName.SelfUser, RbacAction.Edit)
    )

    const hasUserEditAllPermission = pendingRbacPermissions.has(
      buildRbacPermissionKey(RbacEntityName.User, RbacAction.Edit)
    )

    if (hasSelfUserEditPermission && hasUserEditAllPermission) {
      pendingRbacPermissions.delete(
        buildRbacPermissionKey(RbacEntityName.SelfUser, RbacAction.Edit)
      )
    }
  }

  userEditionSanitizor()

  // We cannot have self user tracing permission and full user tracing one, so
  // if the full is activated, remove the selfed one.
  const userTracesReadSanitizor = () => {
    const hasSelfActivityLogsReadPermission = pendingRbacPermissions.has(
      buildRbacPermissionKey(RbacEntityName.SelfActivityLogs, RbacAction.Read)
    )

    const hasActivityLogsReadAllPermission = pendingRbacPermissions.has(
      buildRbacPermissionKey(RbacEntityName.ActivityLogs, RbacAction.Read)
    )

    if (hasSelfActivityLogsReadPermission && hasActivityLogsReadAllPermission) {
      pendingRbacPermissions.delete(
        buildRbacPermissionKey(RbacEntityName.SelfActivityLogs, RbacAction.Read)
      )
    }
  }

  userTracesReadSanitizor()

  // more to come ?

  return Array.from(pendingRbacPermissions.values())
}

/**
 * Check the access to a MaybeGranted* entity fetched from GraphQL and acts as a
 * typeguard function that set `node` property as defined.
 */
export function isGrantedEntity<T>(
  maybeGrantedEntity: Maybe<IMaybeGrantedEntity<T>>
): maybeGrantedEntity is IGrantedEntity<T> {
  const isGranted = maybeGrantedEntity?.rbacCapability?.isGranted
  const isFound = maybeGrantedEntity?.rbacCapability?.isFound

  if (!isGranted || !isFound || !maybeGrantedEntity?.node) {
    return false
  }

  return true
}

/**
 * Check the access to a MaybeGranted* entity fetched from GraphQL and acts as a
 * typeguard function that set `node` property as defined.
 *
 * Usage:
 *
 * .then(alerts => {
 *   if (!checkRbac(this.storeRoot, storeFlags)(alerts)) {
 *     throw new ForbiddenAccessError()
 *   }
 * })
 */
export function checkRbac(storeRoot: StoreRoot, storeFlags?: StoreFlags) {
  return <T>(
    maybeGrantedEntity: Maybe<IMaybeGrantedEntity<T>>
  ): maybeGrantedEntity is IGrantedEntity<T> => {
    const isEntityGranted = maybeGrantedEntity?.rbacCapability?.isGranted
    const isEntityFound = maybeGrantedEntity?.rbacCapability?.isFound
    const rbacEntityName =
      maybeGrantedEntity?.rbacCapability?.rbacEntityName || 'unknown'

    const isNotGranted =
      !isEntityGranted || !isEntityFound || !maybeGrantedEntity?.node

    if (!isNotGranted) {
      return true
    }

    if (maybeGrantedEntity && !isEntityFound) {
      storeRoot.logger.info(
        `[Rbac] Unauthorized access to ${rbacEntityName} (404)`
      )
    }

    if (maybeGrantedEntity && !isEntityGranted) {
      storeRoot.logger.info(
        `[Rbac] Unauthorized access to ${rbacEntityName} (403)`
      )
    }

    if (storeFlags) {
      storeFlags.forbidden()
    }

    return false
  }
}
