import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import type {
  AttackSource,
  AttackSourceType,
  Maybe
} from '@server/graphql/typeDefs/types'

export default class EntityAttackSource
  extends EntityBase
  implements PropertiesNullable<AttackSource>
{
  type: Maybe<AttackSourceType> = null
  hostname: Maybe<string> = null
  ip: Maybe<string> = null

  constructor(data: Partial<AttackSource>) {
    super()
    Object.assign(this, data)
  }
}
