import { ContainerFlex, ContainerRbac } from '@app/components-legacy/Container'
import { RouteContext } from '@app/components-legacy/Router/context'
import { TypographyFieldsetTitle } from '@app/components-legacy/Typography'
import { canAccessToIoAEditCard } from '@app/pages/IoA/IoABoardPage/permissions'
import type StoreSummaryCard from '@app/stores/IoA/StoreBoard/StoreSummaryCard'
import * as React from 'react'
import styled from 'styled-components'
import IoABoardCardActions from './IoABoardCardActions'

interface IIoABoardCardTitleProps {
  className?: string
  storeSummaryCard: StoreSummaryCard
}

const IoABoardCardTitle: React.FC<IIoABoardCardTitleProps> = props => {
  const directoryName =
    props.storeSummaryCard.directoryEntity?.getPropertyAsString('name')

  const routeContext = React.useContext(RouteContext)

  const items = [
    <TypographyFieldsetTitle padded={false}>
      {directoryName || '-'}
    </TypographyFieldsetTitle>
  ]

  if (!routeContext?.isPDFPage) {
    items.push(
      <ContainerRbac rbacCapabilityCheck={canAccessToIoAEditCard}>
        <IoABoardCardActions storeSummaryCard={props.storeSummaryCard} />
      </ContainerRbac>
    )
  }

  return (
    <ContainerFlex
      name={IoABoardCardTitle.name}
      className={props.className}
      justifyContent="space-between"
      alignItems="center"
      items={items}
      paddingH="default"
    />
  )
}

export default styled(IoABoardCardTitle)`
  height: 40px;
`
