export enum WidgetListPageSelectorValue {
  selectCurrentPage = 'selectCurrentPage',
  selectAllPages = 'selectAllPages',
  unselectAllPages = 'unselectAllPages'
}

export interface IWidgetListBulkActionOption {
  label: string
  value: string
}
