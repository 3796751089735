import { BladeCancelButton } from '@app/components-legacy/Blade'
import { ContainerFlex } from '@app/components-legacy/Container'
import {
  FormWrapperButton,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleEditRoleOnSubmit } from './handlers'

interface IRolesEditBladeFooterSubmitProps {}

const RolesEditFooterSubmit: React.FC<
  IRolesEditBladeFooterSubmitProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.Accounts.Roles.Permissions']
  })

  const { storeBlades, storeManagementRbacRoles } = useStores()

  const roleId = storeManagementRbacRoles.currentRbacRoleId

  return (
    <ContainerFlex
      name="RolesEditFooterSubmit"
      justifyContent="flex-end"
      items={[
        <BladeCancelButton />,

        <FormWrapperButtonSubmit
          loading={
            storeManagementRbacRoles.storeApplyRbacRoleFlags.flags.isLoading
          }
        >
          {translate('Apply')}
        </FormWrapperButtonSubmit>,

        <FormWrapperButton
          labelledBy="ApplyAndClose"
          buttonProps={{
            type: 'primary',
            loading:
              storeManagementRbacRoles.storeEditRbacRoleFlags.flags.isLoading,
            disabled:
              storeManagementRbacRoles.storeEditRbacRoleFlags.flags.isForbidden,
            onClick: handleEditRoleOnSubmit(
              storeBlades,
              storeManagementRbacRoles
            )(Number(roleId))
          }}
        >
          {translate('Apply and close')}
        </FormWrapperButton>
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(RolesEditFooterSubmit)
