import type { PropertiesNullable } from '@@types/helpers'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import type { AlertIoA, Maybe } from '@server/graphql/typeDefs/types'
import type { EntityAttackType } from '.'
import EntityBase from './EntityBase'
import type EntityDirectory from './EntityDirectory'
import type { IEntityListable } from './types'

export interface IDataRowAlertIoA {
  id: number
  label?: string
}

export default class EntityAlertIoA
  extends EntityBase
  implements PropertiesNullable<AlertIoA>, IEntityListable<IDataRowAlertIoA>
{
  id: Maybe<number> = null
  date: Maybe<string> = null
  directoryId: Maybe<number> = null
  attackTypeId: Maybe<number> = null
  archived: Maybe<boolean> = null
  read: Maybe<boolean> = null

  constructor(data: Partial<AlertIoA>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return the attackType at the origin of the alert.
   */
  getAttackType(
    allAttackTypes: Map<number, EntityAttackType>
  ): Maybe<EntityAttackType> {
    return (this.attackTypeId && allAttackTypes.get(this.attackTypeId)) || null
  }

  /**
   * Return the directory impacted by the alert.
   */
  getDirectory(
    allDirectories: Map<number, EntityDirectory>
  ): Maybe<EntityDirectory> {
    return (this.directoryId && allDirectories.get(this.directoryId)) || null
  }

  /* Implements IEntityListable */

  getColumns(): Array<IWidgetListColumns<IDataRowAlertIoA>> {
    // Not used as a list for now
    return []
  }

  asDataRow(): IDataRowAlertIoA {
    return {
      id: this.getPropertyAsNumber('id')
    }
  }
}
