import type { PropertiesNullable } from '@@types/helpers'
import type {
  DataCollectionNotWorkingReason,
  DataCollectionStatus,
  Maybe
} from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityDataCollectionStatus
  extends EntityBase
  implements PropertiesNullable<DataCollectionStatus>
{
  isEnabled: Maybe<boolean> = null
  notWorkingBecauseOf: Maybe<DataCollectionNotWorkingReason[]> = null
  sensitiveDataEnabled: Maybe<boolean> = null

  constructor(data: Partial<DataCollectionStatus>) {
    super()
    Object.assign(this, data)
  }
}
