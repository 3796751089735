import {
  BackgroundColorV2,
  BrandColorV2,
  FontColorV2
} from '@app/styles/consts'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import type { Combinator } from '@libs/Expression/types'
import Tag from 'antd/lib/tag'
import * as React from 'react'
import styled, { css } from 'styled-components'

interface ICombinatorFieldRule {
  className?: string
  parentCombinator: Combinator
}

const CombinatorFieldRule: React.FC<ICombinatorFieldRule> = props => {
  return <Tag className={props.className}>{props.parentCombinator}</Tag>
}

export default styled(CombinatorFieldRule)`
  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      color: ${FontColorV2.primary};
      border-color: ${BrandColorV2.decorativeGray};
      background-color: ${BackgroundColorV2.lightGray};
    `}
`
