import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

export interface ICellMethodProps {
  className?: string
  children: string
}

const CellMethod: React.FC<ICellMethodProps> = props => {
  return <div className={props.className}>{props.children}</div>
}

function getColor(method: string): string {
  switch (method) {
    case 'GET':
      return consts.colorGreen005

    case 'POST':
      return consts.colorBlue005

    case 'PUT':
      return consts.colorBlue015

    case 'PATCH':
      return consts.colorPurple010

    default:
      return consts.colorBlueGray015
  }
}

export default styled(CellMethod)`
  width: 150px !important;
  font-weight: ${consts.fontWeightBold};
  color: ${props => getColor(props.children)};
`
