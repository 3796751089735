import { ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { CardSimple } from '@app/components-legacy/Card/CardSimple/CardSimple'
import { useAppTranslator } from '@app/hooks'
import * as React from 'react'

interface IConfigurationHealthCheckCardProps {
  className?: string
}

function ConfigurationHeathCheckCard(
  props: IConfigurationHealthCheckCardProps
) {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.HealthCheck']
  })

  return (
    <ContainerFlex
      name="ConfigurationHeathCheckCard"
      className={props.className}
      direction="column"
      items={[
        <CardSimple>
          <Label variant={LabelVariant.p}>
            {translate(
              'Enabling this feature provides you with the overall status of your Tenable.ad platform in real-time'
            )}
          </Label>
        </CardSimple>
      ]}
      flexGap="smaller"
      wrapItems
    />
  )
}

export default ConfigurationHeathCheckCard
