import { handleContainerInfiniteScroll } from '@app/components-legacy/Container/ContainerInfiniteScroll/handlers'
import type { IContainerInfiniteScrollHandlerOptions } from '@app/components-legacy/Container/ContainerInfiniteScroll/types'
import type { IUseHistoryChangeOptions } from '@app/components-legacy/Router/hooks'
import { AppRouteName } from '@app/routes'
import type { IIoAAttacksRouteDefinition } from '@app/routes'
import type { StoreIoA } from '@app/stores'
import type StoreAttacks from '@app/stores/IoA/StoreAttacks'
import { If } from '@libs/fp-helpers/If'
import { isDefined } from '@libs/isDefined'
import { createStoreAttacksAccordingFilters } from './functions'

/**
 * Handler that listens the browser history and create StoreAttacks instances
 * according to the current attacks investigation filters.
 *
 * Once the first StoreAttacks instance is created, the first root blade is renderer,
 * then recursively as many as instances.
 */
export const handleAttacksHistoryOnChange =
  (storeIoA: StoreIoA): IUseHistoryChangeOptions['onHistoryChange'] =>
  (currentRouteInfo): void => {
    if (!currentRouteInfo) {
      return
    }

    const queryStringParameters =
      currentRouteInfo.queryStringParameters as IIoAAttacksRouteDefinition['queryStringParameters']

    if (!queryStringParameters || !queryStringParameters.attacksFilters) {
      return
    }

    createStoreAttacksAccordingFilters(
      storeIoA,
      queryStringParameters.attacksFilters
    )
  }

/**
 * When hard-reloading the attacks list, create all StoreAttacks from the current
 * filters.
 */
export const handleAttacksRootBladeOnLoad =
  (storeIoA: StoreIoA) => (storeAttacksIndex?: number) => {
    // Fetch initial data only if needed and before instanciating StoreAttacks stores
    const initData = Promise.all([
      // infra/dirs are needed to display which directory is impacted
      If(
        !storeIoA.storeRoot.stores.storeInfrastructures.infrastructures.size
      ).fetch(() =>
        storeIoA.storeRoot.stores.storeInfrastructures.fetchInfrastructures()
      ),
      // attacks types are needed to display IOA cards
      If(!storeIoA.attackTypes.size).fetch(() => storeIoA.fetchAttackTypes())
    ])

    // new, create StoreAttacks stores according to the current filters
    initData.then(() => {
      if (isDefined(storeAttacksIndex)) {
        return
      }

      const appRouter = storeIoA.storeRoot.appRouter

      const qs =
        appRouter.getCurrentRouteQueryStringParameters<
          IIoAAttacksRouteDefinition['queryStringParameters']
        >()

      if (!qs || !qs.attacksFilters) {
        return
      }

      createStoreAttacksAccordingFilters(storeIoA, qs.attacksFilters)
    })
  }

/**
 * Fetch data and initialize pickers at blade initialization.
 */
export const handleAttacksBladeOnLoad = (storeAttacks: StoreAttacks) => () => {
  return storeAttacks.storeInputCheckersAttacks
    .fetchAttackCheckers()
    .then(() => {
      storeAttacks.initFiltersStoresFromQueryStringOrPreviousBladeState()

      const args = storeAttacks.computedArgsFromFilters

      // fetch attacks
      if (args) {
        storeAttacks.fetchAttacks(args)
      }
    })

  // register WS
  // TODO see which payload to register for each new attack investigation view
  // storeAttacks.registerAttacksWS()
}

/**
 * Clear all StoreAttacks and unsubscribe to attacks when leaving the page.
 */
export const handleAttacksBladeOnUnload = (storeIoA: StoreIoA) => () => {
  storeIoA.reset()
  // storeAttacks.unregisterAttackWS()

  // When closing a blade, if the page is not the attacks page, remove all
  // StoreAttacks stores
  if (
    storeIoA.storeRoot.appRouter.getCurrentRouteName() !==
    AppRouteName.IoA_Attacks
  ) {
    storeIoA.clearStoresAttack()
  }
}

/**
 * When scrolling, fetch older attacks.
 */
export const handleAttacksOnScroll =
  (storeAttacks: StoreAttacks) =>
  (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const options: IContainerInfiniteScrollHandlerOptions = {
      onScrollFn: () => {
        storeAttacks.fetchOlderAttacks()
      },
      preventFn: () => {
        const fetchNextPages =
          !storeAttacks.storeFlagsFetchOlderAttacks.flags.isLoading &&
          storeAttacks.hasOlderResults

        return !fetchNextPages
      }
    }

    return handleContainerInfiniteScroll(options)(e)
  }
