import {
  ContainerFlex,
  ContainerIcon,
  ContainerRbac
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import {
  IconDeleteOutlined,
  IconEditOutlined,
  IconEyeOutlined
} from '@app/components-legacy/Icon/IconAntd'
import IconBlank from '@app/components-legacy/Icon/IconBlank'
import { InputSearch } from '@app/components-legacy/Input'
import {
  WidgetListActionButtons,
  WidgetListActionsHeader,
  WidgetListCountItems,
  WidgetListTable,
  WidgetListTableActionIcons
} from '@app/components-legacy/Widgets/WidgetList'
import type { EntityRbacRole } from '@app/entities'
import type { IDataRowRbacRole } from '@app/entities/EntityRbacRole'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import {
  isRbacAdminRole,
  isRoleReadOnly
} from '@app/stores/Management/Rbac/functions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  canCreateRoles,
  canDeleteRole,
  canEditRole,
  canReadRole
} from '../permissions'
import {
  handleCreateRoleButtonOnClick,
  handleDeleteRoleIconOnClick,
  handleEditRoleIconOnClick
} from './handlers'

interface IRolesListProps {}

const RolesList: React.FC<IRolesListProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Roles']
  })

  const appRouter = useAppRouter()

  const { storeManagementRbacRoles, storeRbac } = useStores()

  return (
    <ContainerFlex
      name="RolesList"
      direction="column"
      items={[
        <InputSearch
          placeholder={translate('Search for a Role')}
          storeInputSearch={storeManagementRbacRoles.storeInputSearch}
        />,
        <WidgetListActionsHeader>
          <WidgetListCountItems
            storeWidgetList={storeManagementRbacRoles.storeWidgetListRbacRoles}
          />

          <WidgetListActionButtons>
            <ContainerRbac rbacCapabilityCheck={canCreateRoles}>
              <FormWrapperButton
                labelledBy="createRole"
                buttonProps={{
                  type: 'primary',
                  onClick: handleCreateRoleButtonOnClick(appRouter)
                }}
              >
                {translate('Create a role')}
              </FormWrapperButton>
            </ContainerRbac>
          </WidgetListActionButtons>
        </WidgetListActionsHeader>,

        <WidgetListTable<EntityRbacRole, IDataRowRbacRole>
          translate={translate}
          hiddenColumnsKeys={['id']}
          storeWidgetList={storeManagementRbacRoles.storeWidgetListRbacRoles}
          actionsButtonsRenderFn={(roleId, role) => {
            const isAdminRole = isRbacAdminRole(role)
            const isReadableOnly = isRoleReadOnly(role.id, storeRbac)

            const nodes = []

            if (isAdminRole || isReadableOnly) {
              nodes.push(
                <ContainerRbac
                  rbacCapabilityCheck={canReadRole(role.id)}
                  render={({ isGranted }) => {
                    return isGranted ? (
                      <ContainerIcon
                        labelledBy="seeRole"
                        iconComponent={IconEyeOutlined}
                        iconProps={{
                          type: 'eye',
                          onClick: handleEditRoleIconOnClick(appRouter)(
                            Number(roleId)
                          )
                        }}
                        tooltipProps={{
                          title: translate('See role')
                        }}
                      />
                    ) : (
                      <IconBlank />
                    )
                  }}
                />
              )
            } else {
              nodes.push(
                ...[
                  <ContainerRbac
                    rbacCapabilityCheck={canEditRole(role.id)}
                    render={({ isGranted }) => {
                      return isGranted ? (
                        <ContainerIcon
                          labelledBy="editRole"
                          iconComponent={IconEditOutlined}
                          iconProps={{
                            type: 'edit',
                            onClick: handleEditRoleIconOnClick(appRouter)(
                              Number(roleId)
                            )
                          }}
                        />
                      ) : (
                        <IconBlank />
                      )
                    }}
                  />,

                  // prevent the deletion of the Admin role
                  !isAdminRole ? (
                    <ContainerRbac
                      rbacCapabilityCheck={canDeleteRole(role.id)}
                      render={({ isGranted }) => {
                        return isGranted ? (
                          <ContainerIcon
                            labelledBy="deleteRole"
                            iconComponent={IconDeleteOutlined}
                            iconProps={{
                              onClick: handleDeleteRoleIconOnClick(
                                storeManagementRbacRoles
                              )(Number(roleId))
                            }}
                          />
                        ) : (
                          <IconBlank />
                        )
                      }}
                    />
                  ) : (
                    <IconBlank />
                  )
                ]
              )
            }

            return <WidgetListTableActionIcons icons={nodes} />
          }}
        />
      ]}
      spaced
    />
  )
}

export default observer(RolesList)
