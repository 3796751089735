import { updateIoABoardFiltersFromQueryParams } from '@app/pages/IoA/IoABoardPage/functions'
import type { IIoABoardRouteDefinition } from '@app/routes'
import type StoreBoard from '@app/stores/IoA/StoreBoard'

/**
 * Fetch data to initialize the IOA Board view.
 */
export const handleIoABoardLoad = (storeBoard: StoreBoard) => () => {
  return Promise.all([
    storeBoard.storeInfrastructures.fetchInfrastructures(),
    storeBoard.storeInputCheckersAttacks.fetchAttackCheckers().then(() => {
      storeBoard.storeInputCheckersAttacks.showAllCheckers().selectAllCheckers()
    })
  ])
    .then(() => {
      const appRouter = storeBoard.storeRoot.appRouter

      const qs =
        appRouter.getCurrentRouteQueryStringParameters<
          IIoABoardRouteDefinition['queryStringParameters']
        >()

      if (!qs) {
        return
      }

      return updateIoABoardFiltersFromQueryParams(storeBoard, qs.boardFilters)
    })
    .then(() => {
      storeBoard.fetchAttacksSummary()
    })
}

/**
 * Reset things when leaving the page.
 */
export const handleIoABoardUnload = (storeBoard: StoreBoard) => () => {
  storeBoard.reset()
}
