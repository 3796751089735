import { AssetImg } from '@app/components/Asset/AssetImg'
import { ContainerFlex, ContainerOpacity } from '@app/components/Container'
import * as React from 'react'
import styled from 'styled-components'

interface ISpinnerLogoProps {
  className?: string
  width?: number
}

const StyledAssetImg = styled(AssetImg)`
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  animation: rotating 2s cubic-bezier(0.58, 0, 0.38, 0.99) infinite;
  opacity: 0.5;
`

const SpinnerLogoBlue: React.FC<ISpinnerLogoProps> = props => {
  return (
    <ContainerOpacity fullHeight>
      <ContainerFlex
        className={props.className}
        name={SpinnerLogoBlue.name}
        justifyContent="center"
        items={[
          <ContainerFlex
            name="SpinnerLogoBlueInner"
            direction="column"
            justifyContent="center"
            items={[
              <StyledAssetImg
                imageName="logo-tenable-ad-color-shape-blue"
                fileType="png"
                width={props.width ?? 82}
              />
            ]}
          />
        ]}
        fullHeight
        fullWidth
      />
    </ContainerOpacity>
  )
}

export default SpinnerLogoBlue
