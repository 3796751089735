import type { Maybe } from '@@types/helpers'
import { IconSVG } from '@app/components-legacy/Icon'
import { useAppTranslator, useStores } from '@app/hooks'
import { isDefined } from '@libs/isDefined'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ContainerFlex } from '../Container'
import LabelTenant from './LabelTenant'

export interface ILabelTenantsProps {
  // is null, it means that it's for all tenants
  tenantIds: Maybe<string[]>
}

const LabelTenants: React.FC<ILabelTenantsProps> = props => {
  const { storeIoE } = useStores()

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Components.LabelTenants']
  })

  /**
   * If there is only one tenant, return the name and the color of the tenant.
   * Otherwise, return the number of tenants.
   */
  const getLabel = (): string => {
    if (props.tenantIds === null) {
      return translate('All tenants')
    }

    if (props.tenantIds.length === 1) {
      const tenantId = props.tenantIds[0]
      const tenant = storeIoE.storeInputTenants.tenants.get(String(tenantId))

      const fallbackLabel = translate('Not available')

      if (!tenant) {
        return fallbackLabel
      }

      return tenant.name || fallbackLabel
    }

    return translate('nbTenants', {
      interpolations: {
        count: props.tenantIds.length
      }
    })
  }

  /**
   * Return the title of the tooltip.
   *
   * In some cases, some directory could be set as props but could not be
   * present in the store (case of Widgets on the dashboards).
   *
   * In this case, we add a custom message to explain why.
   */
  const renderTooltipTitle = (): Maybe<React.ReactElement> => {
    if (props.tenantIds === null) {
      return null
    }

    if (!props.tenantIds.length) {
      return null
    }

    return (
      <ContainerFlex
        name="tenants"
        direction="column"
        items={props.tenantIds
          .map(tenantId => {
            const tenant = storeIoE.storeInputTenants.tenants.get(tenantId)

            if (!tenant) {
              return null
            }

            return (
              <ContainerFlex
                key={tenantId}
                name="tenant"
                alignItems="center"
                flexGap="verySmall"
                items={[
                  <IconSVG iconName="icons/azure-active-directory" size={16} />,
                  <div>{tenant.getPropertyAsString('name')}</div>
                ]}
              />
            )
          })
          .filter(isDefined)}
      />
    )
  }

  return (
    <div data-name="LabelTenants">
      <LabelTenant label={getLabel()} tooltipLabel={renderTooltipTitle()} />
    </div>
  )
}

export default observer(LabelTenants)
