import type EntityAttackDestination from '@app/entities/EntityAttackDestination'
import type {
  AttackDestinationType,
  Maybe
} from '@server/graphql/typeDefs/types'
import * as React from 'react'
import IoAAttacksTableBodyDataRowSourceDestValue from './IoAAttacksTableBodyDataRowSourceDestValue'
import IoAAttacksTargetIcon from './IoAAttacksTargetIcon'

interface IIoAAttacksTableBodyDataRowDestinationProps {
  className?: string
  attackDestinationEntity: Maybe<EntityAttackDestination>
}

const IoAAttacksTableBodyDataRowDestination: React.FC<
  IIoAAttacksTableBodyDataRowDestinationProps
> = props => {
  if (!props.attackDestinationEntity) {
    return null
  }

  const destinationType =
    props.attackDestinationEntity.getPropertyAsT<Maybe<AttackDestinationType>>(
      'type'
    )

  const destinationHostname =
    props.attackDestinationEntity.getPropertyAsString('hostname')

  const destinationIp = props.attackDestinationEntity.getPropertyAsString('ip')

  return (
    <IoAAttacksTableBodyDataRowSourceDestValue
      className="attackDestination"
      justifyContent="flex-start"
      type="destination"
      hostname={destinationHostname}
      ip={destinationIp}
      icon={<IoAAttacksTargetIcon destination={destinationType} />}
    />
  )
}

export default IoAAttacksTableBodyDataRowDestination
