import { ContainerContent, ContainerFlex } from '@app/components/Container'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { buildVariants } from '@design-system/libs/buildVariants'
import { DSThemeName } from '@design-system/styles/themes/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import ConfigurationHealthCheck from './ConfigurationHealthCheck/ConfigurationHealthCheck'
import {
  handleHealthCheckConfigurationLoad,
  handleHealthCheckConfigurationUnload
} from './handlers'

interface IConfigurationHealthCheckProps {}

const StyledContainerFlex = styled(ContainerFlex)(props => {
  return buildVariants(props)
    .css({
      maxWidth: consts.configurationCardsWidth
    })
    .end()
})

function ConfigurationHealthCheckPage(props: IConfigurationHealthCheckProps) {
  const { storeManagementApplicationSettings } = useStores()

  return (
    <DSThemeProvider themeName={DSThemeName.default}>
      <ContainerContent
        name="ConfigurationHealthCheckPage"
        onLoad={handleHealthCheckConfigurationLoad(
          storeManagementApplicationSettings
        )}
        onUnload={handleHealthCheckConfigurationUnload(
          storeManagementApplicationSettings
        )}
        flags={[
          storeManagementApplicationSettings.storeFlagsAppSettingsFetch.flags
        ]}
      >
        <StyledContainerFlex
          name="ConfigurationHealthCheckPage"
          direction="column"
          flexGap="large"
          items={[<ConfigurationHealthCheck />]}
        />
      </ContainerContent>
    </DSThemeProvider>
  )
}

export default observer(ConfigurationHealthCheckPage)
