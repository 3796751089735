import { AppMenu } from '@app/components-legacy/Menu'
import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import { useAppRouter, useAppTranslator, useStores } from '@app/hooks'
import { AppRouteName } from '@app/routes'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { filterFalsies } from '@libs/filterFalsies'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canEditProfileIoA, canEditProfileIoE } from '../permissions'

interface IProfileMenuProps {
  profileId: number
}

const ProfileMenu: React.FC<IProfileMenuProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Profiles.Edition']
  })

  const { storeRbac, storeCheckers, storeManagementProfiles } = useStores()

  const appRouter = useAppRouter()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_Accounts_Profiles_Edit_Checker,
    parameters: {
      profileId: Number(),
      checkerId: Number(),
      checkerCodename: String()
    }
  })

  // check is the current edited checker is an IoE or an IOA to select the correct menu item
  const isCheckedCurrentlyEditedIsAttackType =
    parameters?.checkerId &&
    storeCheckers.storeInputCheckersAttacks.checkersById.has(
      parameters?.checkerId
    )

  const getDefaultSelectedMenuKey = () => {
    if (
      isCheckedCurrentlyEditedIsAttackType &&
      storeRbac.isUserGrantedTo(canEditProfileIoA(props.profileId))
    ) {
      return CheckerType.Attack
    }

    if (storeRbac.isUserGrantedTo(canEditProfileIoE(props.profileId))) {
      return CheckerType.Exposure
    }

    if (storeRbac.isUserGrantedTo(canEditProfileIoA(props.profileId))) {
      return CheckerType.Attack
    }

    return undefined
  }

  const storeMenu = useStoreMenuInit<IMenuEntry<CheckerType>>({
    storeMenu: storeManagementProfiles.storeMenu,
    menuEntries: filterFalsies([
      storeRbac.isUserGrantedTo(canEditProfileIoE(props.profileId)) && {
        key: CheckerType.Exposure,
        label: translate('Indicators of Exposure'),
        labelledBy: 'Exposure alerts'
      },
      storeRbac.isUserGrantedTo(canEditProfileIoA(props.profileId)) && {
        key: CheckerType.Attack,
        label: translate('Indicators of Attack'),
        labelledBy: 'Exposure alerts'
      }
    ]),
    defaultSelectedMenuKey: getDefaultSelectedMenuKey()
  })

  return <AppMenu storeMenu={storeMenu} />
}

export default observer(ProfileMenu)
