import { ContainerFlex } from '@app/components-legacy/Container'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import * as React from 'react'
import { Card } from '.'
import { FormWrapperCheckbox } from '../Form'
import CardCheckboxContainer from './CardCheckboxContainer'

interface ICardSelectableProps {
  className?: string
  style?: React.CSSProperties
  checked?: boolean
  selectable?: boolean
  onCheck?: (value: CheckboxChangeEvent) => void
  children?: React.ReactNode
}

const CardSelectable: React.FC<ICardSelectableProps> = props => {
  if (!props.selectable) {
    return (
      <Card
        data-name="CardSelectable"
        className={props.className}
        style={props.style}
      >
        {props.children}
      </Card>
    )
  }

  return (
    <Card
      data-name="CardSelectable"
      active={props.checked}
      className={props.className}
      style={props.style}
    >
      <ContainerFlex
        name="CardSelectable"
        justifyContent="space-between"
        items={[
          <>{props.children}</>,

          <CardCheckboxContainer checked={props.checked}>
            <FormWrapperCheckbox
              labelledBy="selectCard"
              checkboxProps={{
                checked: props.checked,
                onChange: props.onCheck
              }}
            />
          </CardCheckboxContainer>
        ]}
        fullHeight
      />
    </Card>
  )
}

export default CardSelectable
