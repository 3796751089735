import type { Maybe, PropertiesNullable } from '@@types/helpers'
import {
  LINK_ARROW_SIZE,
  LINK_ICON_WIDTH,
  MAIN_NODE_WIDTH,
  NODE_WIDTH
} from '@app/stores/AttackPath/consts'
import type { EntityAttackPathNodeAny } from '@app/stores/AttackPath/types'
import { compareStringsUnalphabetically } from '@libs/compareStringsAlphabetically'
import { getArrowCoordsForAngle } from '@libs/geometry'
import getPercentPositionAlongTheLine from '@libs/geometry/getPercentPositionAlongTheLine'
import getVectorPositionWithMargins from '@libs/geometry/getVectorPositionWithMargins'
import { isDefined } from '@libs/isDefined'
import type {
  AttackPathEdge,
  AttackPathRelationType
} from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export const hasControlRightRelationTypes = [
  'AddAuthCA',
  'AddKeyCredentials',
  'AddMember',
  'AddRCM',
  'AddUserScript',
  'AddAltSecurityIdentities',
  'CanLinkGPO',
  'DCSync',
  'EditCertTemplate',
  'GrantAllowedToAct',
  'GrantAllowedToDelegate',
  'GrantReadGMSAPassword',
  'GrantRODCManage',
  'ImplicitTakeover',
  'ReadGMSAPassword',
  'ReadLAPSPassword',
  'ResetPassword',
  'WriteDACL',
  'WriteOwner'
]

export interface ILineProps {
  x1: number
  x2: number
  y1: number
  y2: number
}

export interface IRectangleProps {
  x: number
  y: number
  width: number
  height: number
}

export default class EntityAttackPathEdge
  extends EntityBase
  implements PropertiesNullable<Omit<AttackPathEdge, 'relationType'>>
{
  id: Maybe<string> = null
  sourceId: Maybe<number> = null
  targetId: Maybe<number> = null
  relationTypes: Maybe<AttackPathRelationType[]> = null
  controlRightRelations?: AttackPathRelationType[]

  sourceUid: Maybe<string> = null
  targetUid: Maybe<string> = null
  private _source?: EntityAttackPathNodeAny
  private _target?: EntityAttackPathNodeAny
  arrowProps: React.SVGProps<SVGPathElement> = {}
  lineProps: Maybe<ILineProps> = null
  rectangleProps: Maybe<IRectangleProps> = null
  rectangleAngle: number = 0
  iconProps: Array<React.SVGProps<SVGImageElement>> = []

  isPartOfExpansion: boolean = false

  uuidLinked: Maybe<string> = null
  isFake: boolean = false

  constructor(data: Partial<EntityAttackPathEdge>) {
    super()
    Object.assign(this, data)
  }

  computeSvgProps() {
    this._setLineProps()
    this._setArrowProps()
    this._setRectangleProps()
    this._setIconProps()
  }

  _setLineProps() {
    if (!this.source || !this.target) {
      return
    }

    const targetMargin = this.target.isImportantNode
      ? (MAIN_NODE_WIDTH / 2) * 1.3
      : (NODE_WIDTH / 2) * 1.6

    this.lineProps = getVectorPositionWithMargins(
      this.source.coords,
      this.target.coords,
      this.source.isHiddenNode ? 0 : NODE_WIDTH / 2,
      this.target.isHiddenNode ? 0 : targetMargin
    )

    if (
      !this.lineProps.x1 ||
      !this.lineProps.x2 ||
      !this.lineProps.y1 ||
      !this.lineProps.y2
    ) {
      return
    }
  }

  _setArrowProps() {
    if (!this.lineProps) {
      return
    }

    const lineAngle = Math.atan2(
      this.lineProps.y2 - this.lineProps.y1,
      this.lineProps.x2 - this.lineProps.x1
    )

    const coordsArrowPart1 = getArrowCoordsForAngle(
      {
        x: this.lineProps.x2,
        y: this.lineProps.y2
      },
      lineAngle - Math.PI / 4,
      lineAngle,
      LINK_ARROW_SIZE
    )

    const coordsArrowPart2 = getArrowCoordsForAngle(
      {
        x: this.lineProps.x2,
        y: this.lineProps.y2
      },
      lineAngle + Math.PI / 4,
      lineAngle,
      LINK_ARROW_SIZE
    )

    const arrowPath = [
      `M${coordsArrowPart1.x} ${coordsArrowPart1.y}`,
      `L${this.lineProps.x2} ${this.lineProps.y2}`,
      `L${coordsArrowPart2.x} ${coordsArrowPart2.y}`
    ].join(' ')

    this.arrowProps = {
      d: arrowPath
    }
  }

  _setRectangleProps() {
    if (!this.lineProps) {
      return
    }

    const x1 = this.lineProps.x1
    const x2 = this.lineProps.x2
    const y1 = this.lineProps.y1
    const y2 = this.lineProps.y2

    const rectangleWidth = this.relationTypes
      ? this.relationTypes.length * LINK_ICON_WIDTH
      : LINK_ICON_WIDTH

    const rectanglePosition = getPercentPositionAlongTheLine(
      {
        x: x1,
        y: y1
      },
      {
        x: x2,
        y: y2
      },
      0.5
    )

    this.rectangleProps = {
      x: rectanglePosition.x - LINK_ICON_WIDTH / 2,
      y: rectanglePosition.y - rectangleWidth / 2,
      width: LINK_ICON_WIDTH,
      height: rectangleWidth
    }

    const deltaX = x2 - x1
    const deltaY = y1 - y2
    const thetaRadians = Math.atan2(deltaX, deltaY)

    this.rectangleAngle = (thetaRadians * 180) / Math.PI
  }

  _setIconProps() {
    const iconSize = LINK_ICON_WIDTH

    if (!this.relationTypes) {
      return
    }

    this.iconProps = this.relationTypes
      .sort(compareStringsUnalphabetically)
      .map((relation, index) => {
        if (index >= 5) {
          return
        }

        return {
          href: `/w/assets/images/attackpath/${relation}.svg`,
          width: iconSize,
          height: iconSize
        }
      })
      .filter(isDefined)
  }

  _setSource(source: EntityAttackPathNodeAny) {
    this._source = source
  }

  _setTarget(target: EntityAttackPathNodeAny) {
    this._target = target
  }

  get source() {
    return this._source
  }

  get target() {
    return this._target
  }
}
