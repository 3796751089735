import {
  createEntity,
  EntityAbout,
  EntityProductAssociation
} from '@app/entities'
import { AppRouteName } from '@app/routes'
import { handleStoreError } from '@libs/errors/handleStoreError'
import type {
  About,
  Maybe,
  ProductAssociation
} from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable } from 'mobx'
import type { StoreRoot } from '.'
import StoreFlags from './helpers/StoreFlags'
import StoreBase from './StoreBase'
import type { IStoreOptions } from './types'

export default class StoreAbout extends StoreBase {
  public storeFlags = new StoreFlags(this.storeRoot)
  public storeProductAssociationFlags = new StoreFlags(this.storeRoot)

  /* Observables */

  private $about = observable.box<Maybe<EntityAbout>>(null)
  private $productAssociation =
    observable.box<Maybe<EntityProductAssociation>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch product information
   */
  fetchAbout() {
    this.storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        const url = this.storeRoot.appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.MiddlewareAPI_About,
          parameters: {}
        })
        return this.storeRoot.fetchClient.get(url)
      })
      .then(response => response.json() as Promise<About>)
      .then(about => {
        if (!about) {
          this.storeRoot.logger.error(
            'Unable to retrieve the version of the product'
          )

          about = about || {
            version: 'unknown'
          }
        }

        const aboutEntity = createEntity<About, EntityAbout>(EntityAbout, about)

        this.setAbout(aboutEntity)

        this.storeFlags.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlags))
  }

  /**
   * Fetch product association
   */
  fetchProductAssociation() {
    this.storeProductAssociationFlags.loading()

    const url = this.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareProductAssociation,
      parameters: {}
    })

    return Promise.resolve()
      .then(() => {
        return this.storeRoot.fetchClient.get(url)
      })
      .then(response => response.json() as Promise<ProductAssociation>)
      .then(productAssociation => {
        if (!productAssociation) {
          this.storeRoot.logger.error(
            'Unable to retrieve the product association'
          )
        }

        const productAssociationEntity = createEntity<
          ProductAssociation,
          EntityProductAssociation
        >(EntityProductAssociation, productAssociation)

        this.setProductAssociation(productAssociationEntity)

        this.storeProductAssociationFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeProductAssociationFlags)
      )
  }

  /* Actions */

  /**
   * Save about information.
   */
  @action
  setAbout(aboutEntity: EntityAbout): this {
    this.$about.set(aboutEntity)
    return this
  }

  /**
   * Save productAssociation information.
   */
  @action
  setProductAssociation(productAssociation: EntityProductAssociation): this {
    this.$productAssociation.set(productAssociation)
    return this
  }

  /* Computed */

  @computed
  get about(): Maybe<EntityAbout> {
    return this.$about.get()
  }

  @computed
  get productAssociation(): Maybe<EntityProductAssociation> {
    return this.$productAssociation.get()
  }

  @computed
  get productVersion(): Maybe<string> {
    if (!this.about) {
      return null
    }

    return this.about.version
  }

  @computed
  get isSaaSDeployment(): Maybe<boolean> {
    if (!this.about) {
      return null
    }

    return this.about.isSaaSDeployment
  }
}
