import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelNonBreakable } from '@app/components-legacy/Label'
import { formatNumber } from '@libs/numbers/helpers'
import { DashboardWidgetDataOptionType } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'

export interface IWidgetBigNumberValueProps {
  className?: string
  widgetType: DashboardWidgetDataOptionType
  widgetLayout: ReactGridLayout.Layout
  count: string
}

interface IPercentProps {
  className?: string
}

const FONT_SIZE_LARGE = '100px'
const FONT_SIZE_MEDIUM = '75px'
const FONT_SIZE_SMALL = '50px'
const FONT_SIZE_VERY_SMALL = '40px'

const PercentRaw: React.FC<IPercentProps> = props => {
  return <span className={props.className}>%</span>
}

const Percent = styled(PercentRaw)`
  font-size: ${FONT_SIZE_VERY_SMALL};
`

const WidgetBigNumberValue: React.FC<IWidgetBigNumberValueProps> = props => {
  const renderCount = () => {
    switch (props.widgetType) {
      case DashboardWidgetDataOptionType.ComplianceScore:
        return (
          <ContainerFlex
            name="PercentCount"
            alignItems="baseline"
            items={[<span>{formatNumber(props.count)}</span>, <Percent />]}
          />
        )

      default:
        return <span>{formatNumber(props.count)}</span>
    }
  }

  return (
    <LabelNonBreakable className={props.className}>
      {renderCount()}
    </LabelNonBreakable>
  )
}

/**
 * Compute the font-size according to the widget width and count length.
 */
function computeFontSize(layout: ReactGridLayout.Layout, count: string) {
  // small size

  if (layout.w <= 3) {
    if (count.length <= 4) {
      return FONT_SIZE_LARGE
    }

    if (count.length <= 7) {
      return FONT_SIZE_MEDIUM
    }

    return FONT_SIZE_SMALL
  }

  // large size

  if (count.length <= 7) {
    return FONT_SIZE_LARGE
  }

  if (count.length <= 9) {
    return FONT_SIZE_MEDIUM
  }

  return FONT_SIZE_SMALL
}

export default styled(WidgetBigNumberValue)`
  text-align: center;
  color: ${({ theme }) => theme.tokens['dashboardWidget/color/counter']};
  font-size: ${props => computeFontSize(props.widgetLayout, props.count)};
`
