export interface IDecodeOptions {
  // if -1, don't slice
  maxLength?: number
  lastValueOnly?: boolean
  fallback?: string
}

export enum ValueType {
  string = 'string',
  regex = 'regex',
  float = 'float',
  integer = 'integer',
  boolean = 'boolean',
  date = 'date',
  object = 'object',
  sddl = 'sddl',
  arrayString = 'array/string',
  arrayRegex = 'array/regex',
  arrayInteger = 'array/integer',
  arrayBoolean = 'array/boolean',
  arraySelect = 'array/select',
  arrayObject = 'array/object',
  arrayCron = 'array/cron'
}

export enum ObjectAttributeClassNames {
  'account' = 'account',
  'ACS-Policy' = 'ACS-Policy',
  'ACS-Resource-Limits' = 'ACS-Resource-Limits',
  'ACS-Subnet' = 'ACS-Subnet',
  'Address-Book-Container' = 'Address-Book-Container',
  'Address-Template' = 'Address-Template',
  'Application-Entity' = 'Application-Entity',
  'Application-Process' = 'Application-Process',
  'Application-Settings' = 'Application-Settings',
  'Application-Site-Settings' = 'Application-Site-Settings',
  'Application-Version' = 'Application-Version',
  'Attribute-Schema' = 'Attribute-Schema',
  'bootableDevice' = 'bootableDevice',
  'Builtin-Domain' = 'Builtin-Domain',
  'Category-Registration' = 'Category-Registration',
  'Certification-Authority' = 'Certification-Authority',
  'Class-Registration' = 'Class-Registration',
  'Class-Schema' = 'Class-Schema',
  'Class-Store' = 'Class-Store',
  'Com-Connection-Point' = 'Com-Connection-Point',
  'Computer' = 'Computer',
  'Configuration' = 'Configuration',
  'Connection-Point' = 'Connection-Point',
  'Contact' = 'Contact',
  'Container' = 'Container',
  'FormControl-Access-Right' = 'FormControl-Access-Right',
  'Country' = 'Country',
  'CRL-Distribution-Point' = 'CRL-Distribution-Point',
  'Cross-Ref' = 'Cross-Ref',
  'Cross-Ref-Container' = 'Cross-Ref-Container',
  'Device' = 'Device',
  'Dfs-Configuration' = 'Dfs-Configuration',
  'DHCP-Class' = 'DHCP-Class',
  'Display-Specifier' = 'Display-Specifier',
  'Display-Template' = 'Display-Template',
  'DMD' = 'DMD',
  'Dns-Node' = 'Dns-Node',
  'Dns-Zone' = 'Dns-Zone',
  'document' = 'document',
  'documentSeries' = 'documentSeries',
  'Domain' = 'Domain',
  'Domain-DNS' = 'Domain-DNS',
  'Domain-Policy' = 'Domain-Policy',
  'domainRelatedObject' = 'domainRelatedObject',
  'DSA' = 'DSA',
  'DS-UI-Settings' = 'DS-UI-Settings',
  'Dynamic-Object' = 'Dynamic-Object',
  'File-Link-Tracking' = 'File-Link-Tracking',
  'File-Link-Tracking-Entry' = 'File-Link-Tracking-Entry',
  'Foreign-Security-Principal' = 'Foreign-Security-Principal',
  'friendlyCountry' = 'friendlyCountry',
  'FT-Dfs' = 'FT-Dfs',
  'Group' = 'Group',
  'Group-Of-Names' = 'Group-Of-Names',
  'groupOfUniqueNames' = 'groupOfUniqueNames',
  'Group-Policy-Container' = 'Group-Policy-Container',
  'ieee802Device' = 'ieee802Device',
  'Index-Server-Catalog' = 'Index-Server-Catalog',
  'inetOrgPerson' = 'inetOrgPerson',
  'Infrastructure-Update' = 'Infrastructure-Update',
  'Intellimirror-Group' = 'Intellimirror-Group',
  'Intellimirror-SCP' = 'Intellimirror-SCP',
  'Inter-Site-Transport' = 'Inter-Site-Transport',
  'Inter-Site-Transport-Container' = 'Inter-Site-Transport-Container',
  'ipHost' = 'ipHost',
  'ipNetwork' = 'ipNetwork',
  'ipProtocol' = 'ipProtocol',
  'Ipsec-Base' = 'Ipsec-Base',
  'Ipsec-Filter' = 'Ipsec-Filter',
  'Ipsec-ISAKMP-Policy' = 'Ipsec-ISAKMP-Policy',
  'Ipsec-Negotiation-Policy' = 'Ipsec-Negotiation-Policy',
  'Ipsec-NFA' = 'Ipsec-NFA',
  'Ipsec-Policy' = 'Ipsec-Policy',
  'ipService' = 'ipService',
  'Leaf' = 'Leaf',
  'Licensing-Site-Settings' = 'Licensing-Site-Settings',
  'Link-Track-Object-Move-Table' = 'Link-Track-Object-Move-Table',
  'Link-Track-OMT-Entry' = 'Link-Track-OMT-Entry',
  'Link-Track-Vol-Entry' = 'Link-Track-Vol-Entry',
  'Link-Track-Volume-Table' = 'Link-Track-Volume-Table',
  'Locality' = 'Locality',
  'Lost-And-Found' = 'Lost-And-Found',
  'Mail-Recipient' = 'Mail-Recipient',
  'Meeting' = 'Meeting',
  'ms-Authz-Central-Access-Policies' = 'ms-Authz-Central-Access-Policies',
  'ms-Authz-Central-Access-Policy' = 'ms-Authz-Central-Access-Policy',
  'ms-Authz-Central-Access-Rule' = 'ms-Authz-Central-Access-Rule',
  'ms-Authz-Central-Access-Rules' = 'ms-Authz-Central-Access-Rules',
  'ms-COM-Partition' = 'ms-COM-Partition',
  'ms-COM-PartitionSet' = 'ms-COM-PartitionSet',
  'ms-DFS-Deleted-Link-v2' = 'ms-DFS-Deleted-Link-v2',
  'ms-DFS-Link-v2' = 'ms-DFS-Link-v2',
  'ms-DFS-Namespace-Anchor' = 'ms-DFS-Namespace-Anchor',
  'ms-DFS-Namespace-v2' = 'ms-DFS-Namespace-v2',
  'ms-DFSR-Connection' = 'ms-DFSR-Connection',
  'ms-DFSR-Content' = 'ms-DFSR-Content',
  'ms-DFSR-ContentSet' = 'ms-DFSR-ContentSet',
  'ms-DFSR-GlobalSettings' = 'ms-DFSR-GlobalSettings',
  'ms-DFSR-LocalSettings' = 'ms-DFSR-LocalSettings',
  'ms-DFSR-Member' = 'ms-DFSR-Member',
  'ms-DFSR-ReplicationGroup' = 'ms-DFSR-ReplicationGroup',
  'ms-DFSR-Subscriber' = 'ms-DFSR-Subscriber',
  'ms-DFSR-Subscription' = 'ms-DFSR-Subscription',
  'ms-DFSR-Topology' = 'ms-DFSR-Topology',
  'ms-DNS-Server-Settings' = 'ms-DNS-Server-Settings',
  'ms-DS-App-Configuration' = 'ms-DS-App-Configuration',
  'ms-DS-App-Data' = 'ms-DS-App-Data',
  'ms-DS-Az-Admin-Manager' = 'ms-DS-Az-Admin-Manager',
  'ms-DS-Az-Application' = 'ms-DS-Az-Application',
  'ms-DS-Az-Operation' = 'ms-DS-Az-Operation',
  'ms-DS-Az-Role' = 'ms-DS-Az-Role',
  'ms-DS-Az-Scope' = 'ms-DS-Az-Scope',
  'ms-DS-Az-Task' = 'ms-DS-Az-Task',
  'ms-DS-Bindable-Object' = 'ms-DS-Bindable-Object',
  'ms-DS-Bind-Proxy' = 'ms-DS-Bind-Proxy',
  'ms-DS-Claims-Transformation-Policies' = 'ms-DS-Claims-Transformation-Policies',
  'ms-DS-Claims-Transformation-Policy-Type' = 'ms-DS-Claims-Transformation-Policy-Type',
  'ms-DS-Claim-Type' = 'ms-DS-Claim-Type',
  'ms-DS-Claim-Type-Property-Base' = 'ms-DS-Claim-Type-Property-Base',
  'ms-DS-Claim-Types' = 'ms-DS-Claim-Types',
  'ms-DS-Group-Managed-Service-Account' = 'ms-DS-Group-Managed-Service-Account',
  'ms-DS-Managed-Service-Account' = 'ms-DS-Managed-Service-Account',
  'ms-DS-Optional-Feature' = 'ms-DS-Optional-Feature',
  'ms-DS-Password-Settings' = 'ms-DS-Password-Settings',
  'ms-DS-Password-Settings-Container' = 'ms-DS-Password-Settings-Container',
  'ms-DS-Quota-Container' = 'ms-DS-Quota-Container',
  'ms-DS-Quota-FormControl' = 'ms-DS-Quota-FormControl',
  'ms-DS-Resource-Properties' = 'ms-DS-Resource-Properties',
  'ms-DS-Resource-Property' = 'ms-DS-Resource-Property',
  'ms-DS-Resource-Property-List' = 'ms-DS-Resource-Property-List',
  'ms-DS-Service-Connection-Point-Publication-Service' = 'ms-DS-Service-Connection-Point-Publication-Service',
  'ms-DS-Value-Type' = 'ms-DS-Value-Type',
  'ms-Exch-Configuration-Container' = 'ms-Exch-Configuration-Container',
  'ms-FVE-RecoveryInformation' = 'ms-FVE-RecoveryInformation',
  'ms-ieee-80211-Policy' = 'ms-ieee-80211-Policy',
  'ms-Imaging-PostScanProcess' = 'ms-Imaging-PostScanProcess',
  'ms-Imaging-PSPs' = 'ms-Imaging-PSPs',
  'ms-Kds-Prov-RootKey' = 'ms-Kds-Prov-RootKey',
  'ms-Kds-Prov-ServerConfiguration' = 'ms-Kds-Prov-ServerConfiguration',
  'MSMQ-Configuration' = 'MSMQ-Configuration',
  'MSMQ-Custom-Recipient' = 'MSMQ-Custom-Recipient',
  'MSMQ-Enterprise-Settings' = 'MSMQ-Enterprise-Settings',
  'MSMQ-Group' = 'MSMQ-Group',
  'MSMQ-Migrated-User' = 'MSMQ-Migrated-User',
  'MSMQ-Queue' = 'MSMQ-Queue',
  'MSMQ-Settings' = 'MSMQ-Settings',
  'MSMQ-Site-Link' = 'MSMQ-Site-Link',
  'ms-net-ieee-80211-GroupPolicy' = 'ms-net-ieee-80211-GroupPolicy',
  'ms-net-ieee-8023-GroupPolicy' = 'ms-net-ieee-8023-GroupPolicy',
  'ms-PKI-Enterprise-Oid' = 'ms-PKI-Enterprise-Oid',
  'ms-PKI-Key-Recovery-Agent' = 'ms-PKI-Key-Recovery-Agent',
  'ms-PKI-Private-Key-Recovery-Agent' = 'ms-PKI-Private-Key-Recovery-Agent',
  'ms-Print-ConnectionPolicy' = 'ms-Print-ConnectionPolicy',
  'msSFU-30-Domain-Info' = 'msSFU-30-Domain-Info',
  'msSFU-30-Mail-Aliases' = 'msSFU-30-Mail-Aliases',
  'msSFU-30-Net-Id' = 'msSFU-30-Net-Id',
  'msSFU-30-Network-User' = 'msSFU-30-Network-User',
  'msSFU-30-NIS-Map-Config' = 'msSFU-30-NIS-Map-Config',
  'ms-SPP-Activation-Object' = 'ms-SPP-Activation-Object',
  'ms-SPP-Activation-Objects-Container' = 'ms-SPP-Activation-Objects-Container',
  'MS-SQL-OLAPCube' = 'MS-SQL-OLAPCube',
  'MS-SQL-OLAPDatabase' = 'MS-SQL-OLAPDatabase',
  'MS-SQL-OLAPServer' = 'MS-SQL-OLAPServer',
  'MS-SQL-SQLDatabase' = 'MS-SQL-SQLDatabase',
  'MS-SQL-SQLPublication' = 'MS-SQL-SQLPublication',
  'MS-SQL-SQLRepository' = 'MS-SQL-SQLRepository',
  'MS-SQL-SQLServer' = 'MS-SQL-SQLServer',
  'ms-TAPI-Rt-Conference' = 'ms-TAPI-Rt-Conference',
  'ms-TAPI-Rt-Person' = 'ms-TAPI-Rt-Person',
  'ms-TPM-Information-Object' = 'ms-TPM-Information-Object',
  'ms-TPM-Information-Objects-Container' = 'ms-TPM-Information-Objects-Container',
  'ms-WMI-IntRangeParam' = 'ms-WMI-IntRangeParam',
  'ms-WMI-IntSetParam' = 'ms-WMI-IntSetParam',
  'ms-WMI-MergeablePolicyTemplate' = 'ms-WMI-MergeablePolicyTemplate',
  'ms-WMI-ObjectEncoding' = 'ms-WMI-ObjectEncoding',
  'ms-WMI-PolicyTemplate' = 'ms-WMI-PolicyTemplate',
  'ms-WMI-PolicyType' = 'ms-WMI-PolicyType',
  'ms-WMI-RangeParam' = 'ms-WMI-RangeParam',
  'ms-WMI-RealRangeParam' = 'ms-WMI-RealRangeParam',
  'ms-WMI-Rule' = 'ms-WMI-Rule',
  'ms-WMI-ShadowObject' = 'ms-WMI-ShadowObject',
  'ms-WMI-SimplePolicyTemplate' = 'ms-WMI-SimplePolicyTemplate',
  'ms-WMI-Som' = 'ms-WMI-Som',
  'ms-WMI-StringSetParam' = 'ms-WMI-StringSetParam',
  'ms-WMI-UintRangeParam' = 'ms-WMI-UintRangeParam',
  'ms-WMI-UintSetParam' = 'ms-WMI-UintSetParam',
  'ms-WMI-UnknownRangeParam' = 'ms-WMI-UnknownRangeParam',
  'ms-WMI-WMIGPO' = 'ms-WMI-WMIGPO',
  'nisMap' = 'nisMap',
  'nisNetgroup' = 'nisNetgroup',
  'nisObject' = 'nisObject',
  'NTDS-Connection' = 'NTDS-Connection',
  'NTDS-DSA' = 'NTDS-DSA',
  'NTDS-DSA-RO' = 'NTDS-DSA-RO',
  'NTDS-Service' = 'NTDS-Service',
  'NTDS-Site-Settings' = 'NTDS-Site-Settings',
  'NTFRS-Member' = 'NTFRS-Member',
  'NTFRS-Replica-Set' = 'NTFRS-Replica-Set',
  'NTFRS-Settings' = 'NTFRS-Settings',
  'NTFRS-Subscriber' = 'NTFRS-Subscriber',
  'NTFRS-Subscriptions' = 'NTFRS-Subscriptions',
  'oncRpc' = 'oncRpc',
  'Organization' = 'Organization',
  'Organizational-Person' = 'Organizational-Person',
  'Organizational-Role' = 'Organizational-Role',
  'Organizational-Unit' = 'Organizational-Unit',
  'Package-Registration' = 'Package-Registration',
  'Person' = 'Person',
  'Physical-Location' = 'Physical-Location',
  'PKI-Certificate-Template' = 'PKI-Certificate-Template',
  'PKI-Enrollment-Service' = 'PKI-Enrollment-Service',
  'posixAccount' = 'posixAccount',
  'posixGroup' = 'posixGroup',
  'Print-Queue' = 'Print-Queue',
  'Query-Policy' = 'Query-Policy',
  'Remote-Mail-Recipient' = 'Remote-Mail-Recipient',
  'Remote-Storage-Service-Point' = 'Remote-Storage-Service-Point',
  'Residential-Person' = 'Residential-Person',
  'rFC822LocalPart' = 'rFC822LocalPart',
  'RID-Manager' = 'RID-Manager',
  'RID-Set' = 'RID-Set',
  'room' = 'room',
  'Rpc-Container' = 'Rpc-Container',
  'rpc-Entry' = 'rpc-Entry',
  'rpc-Group' = 'rpc-Group',
  'rpc-Profile' = 'rpc-Profile',
  'rpc-Profile-Element' = 'rpc-Profile-Element',
  'rpc-Server' = 'rpc-Server',
  'rpc-Server-Element' = 'rpc-Server-Element',
  'RRAS-Administration-Connection-Point' = 'RRAS-Administration-Connection-Point',
  'RRAS-Administration-Dictionary' = 'RRAS-Administration-Dictionary',
  'Sam-Domain' = 'Sam-Domain',
  'Sam-Domain-Base' = 'Sam-Domain-Base',
  'Sam-Server' = 'Sam-Server',
  'Secret' = 'Secret',
  'Security-Object' = 'Security-Object',
  'Security-Principal' = 'Security-Principal',
  'Server' = 'Server',
  'Servers-Container' = 'Servers-Container',
  'Service-Administration-Point' = 'Service-Administration-Point',
  'Service-Class' = 'Service-Class',
  'Service-Connection-Point' = 'Service-Connection-Point',
  'Service-Instance' = 'Service-Instance',
  'shadowAccount' = 'shadowAccount',
  'simpleSecurityObject' = 'simpleSecurityObject',
  'Site' = 'Site',
  'Site-Link' = 'Site-Link',
  'Site-Link-Bridge' = 'Site-Link-Bridge',
  'Sites-Container' = 'Sites-Container',
  'Storage' = 'Storage',
  'Subnet' = 'Subnet',
  'Subnet-Container' = 'Subnet-Container',
  'SubSchema' = 'SubSchema',
  'Top' = 'Top',
  'Trusted-Domain' = 'Trusted-Domain',
  'Type-Library' = 'Type-Library',
  'User' = 'User',
  'Volum' = 'Volum'
}

export enum ObjectAttributeDNType {
  'DC' = 'domainComponent',
  'CN' = 'commonName',
  'OU' = 'organizationalUnitName',
  'O' = 'organizationName',
  'STREET' = 'streetAddress',
  'L' = 'localityName',
  'ST' = 'stateOrProvinceName',
  'C' = 'countryName',
  'UID' = 'userid'
}
