import { CSSColors } from '@app/styles/colors/types'
import { Tooltip } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerSimple } from '../Container'
import type { IContainerSimpleProps } from '../Container/ContainerSimple'

export enum BulletDiamondSize {
  small = 7
}

interface IBulletDiamondProps extends IContainerSimpleProps {
  color?: string
  size?: BulletDiamondSize
  reversed?: boolean
  resolved?: boolean
  tooltip?: string
  labelledBy: string
}

const BulletDiamondRaw = (props: IBulletDiamondProps) => {
  return (
    <ContainerSimple ariaRoles={['list', 'row', 'img']} {...props}>
      <div className="icon" />

      {props.resolved === true && <div className="strike" />}

      {props.tooltip && (
        <Tooltip title={props.tooltip}>
          <div className="hoverableZone" />
        </Tooltip>
      )}
    </ContainerSimple>
  )
}

export default styled(BulletDiamondRaw)`
  position: relative;

  .icon {
    width: ${props => `${props.size || BulletDiamondSize.small}px`};
    height: ${props => `${props.size || BulletDiamondSize.small}px`};
    border: 1px solid ${props => props.color || CSSColors.Red};
    background-color: ${props =>
      props.reversed ? 'white' : props.color || CSSColors.Red};
    transform: rotate(45deg);
    transform-origin: center center;
  }

  .strike {
    position: absolute;
    top: -2px;
    height: 0px;
    width: 13px;
    left: 0px;
    border-bottom: 1px solid ${props => props.color || CSSColors.Red};
    border-top: 1px solid white;
    transform: rotate(45deg);
    transform-origin: top left;
  }

  /* Adjust an overlay to show the tooltip on the larger zone */
  .hoverableZone {
    position: absolute;
    top: -4px;
    left: -4px;
    width: ${props => `${(props.size || BulletDiamondSize.small) * 2}px`};
    height: ${props => `${(props.size || BulletDiamondSize.small) * 2}px`};
    cursor: help;
  }
`
