import { BrandColorV2 } from '@app/styles/consts'
import { isDefined } from '@libs/isDefined'
import styled from 'styled-components'

interface IVerticalLineProps {
  color?: string
  height?: number
  width?: number
}

const VerticalLine = styled.hr<IVerticalLineProps>`
  height: ${({ height }) => (isDefined(height) ? `${height}px` : '100%')};
  width: 0;
  border-top: 0;
  border-bottom: 0;
  border-left: ${({ width }) => width ?? 1}px solid
    ${({ color }) => color ?? BrandColorV2.decorativeGray};
  border-right: 0;
  margin: 0;
`

export default VerticalLine
