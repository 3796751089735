import type { Mutation } from '@server/graphql/typeDefs/types'

export type MutationCreateSearchHistoryEntry = Pick<
  Mutation,
  'createSearchHistoryEntry'
>

export const mutationCreateSearchHistoryEntry = `
  mutation mutationCreateSearchHistoryEntry($searchHistoryEntry: InputCreateSearchHistoryEntry!) {
    createSearchHistoryEntry(
      searchHistoryEntry: $searchHistoryEntry
    ) {
      id
      expression
      date
    }
  }
`

export type MutationDeleteSearchHistoryEntry = Pick<
  Mutation,
  'deleteSearchHistoryEntry'
>

export const mutationDeleteSearchHistoryEntry = `
  mutation mutationDeleteSearchHistoryEntry($searchHistoryEntry: InputDeleteSearchHistoryEntry!) {
    deleteSearchHistoryEntry(
      searchHistoryEntry: $searchHistoryEntry
    )
  }
`

export type MutationDeleteSearchHistoryEntriesByQuery = Pick<
  Mutation,
  'deleteSearchHistoryEntriesByQuery'
>

export const mutationDeleteSearchHistoryEntriesByQuery = `
  mutation mutationDeleteSearchHistoryEntriesByQuery($searchHistoryQuery: InputDeleteSearchHistoryEntriesByQuery!) {
    deleteSearchHistoryEntriesByQuery(
      searchHistoryQuery: $searchHistoryQuery
    )
  }
`

export type MutationDeleteSearchHistoryEntries = Pick<
  Mutation,
  'deleteSearchHistoryEntries'
>

export const mutationDeleteSearchHistoryEntries = `
  mutation mutationDeleteSearchHistoryEntries {
    deleteSearchHistoryEntries
  }
`
