import type { EntityChecker } from '@app/entities'
import type EntityCheckerIdentity from '@app/entities/EntityGenericChecker/EntityCheckerIdentity'
import { AppRouteName } from '@app/routes'
import type { StoreIoE } from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'

/**
 * Load data according to which navigation is selected (All, AD or EntraID).
 */
export const handleIoEBoardPageOnLoad =
  (storeIoE: StoreIoE) => (currentRouteName: AppRouteName) => () => {
    // If "All" or "ActiveDirectory" navigation entry selected
    if (
      [AppRouteName.IoE_Board, AppRouteName.IoE_Board_AD].includes(
        currentRouteName
      )
    ) {
      // checkers needs infrastructures, so fetch them sequentially
      storeIoE.storeRoot.stores.storeInfrastructures
        .fetchInfrastructures()
        .then(() => storeIoE.fetchCheckersExposure())
    }

    // If "All" or "EntraID" navigation entry selected
    if (
      [AppRouteName.IoE_Board, AppRouteName.IoE_Board_MEID].includes(
        currentRouteName
      )
    ) {
      storeIoE.storeInputTenants.fetchTenants()
      storeIoE.fetchCheckersIdentity()
    }
  }

/**
 * Handle click on an indicator and redirect to the indicator details view.
 */
export const handleCheckerOnClick =
  (appRouter: AppRouterClient) => (checker: EntityChecker) => () => {
    const checkerId = checker.id
    const checkerCodename = checker.codename

    if (checkerId && checkerCodename) {
      const url = appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.IoE_Details_Information,
        parameters: {
          checkerId,
          checkerCodename
        }
      })

      appRouter.history.push(url)
    }
  }

/**
 * Handle click on an identity indicator and redirect to the identity indicator details view.
 */
export const handleCheckerIdentityOnClick =
  (appRouter: AppRouterClient) => (checker: EntityCheckerIdentity) => () => {
    const checkerId = checker.id
    const checkerCodename = checker.codename

    if (checkerId && checkerCodename) {
      const url = appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.IoE_Identity_Details_Information,
        parameters: {
          checkerId,
          checkerCodename
        }
      })

      appRouter.history.push(url)
    }
  }

/**
 * Show/Hide indicators with deviances / findings.
 */
export const handleShowAllIndicatorsOnClick =
  (storeIoE: StoreIoE) => (value: boolean) => {
    storeIoE.setShowAllIndicators(value)
  }

/**
 * Reload indicators when selecting infrastructures.
 */
export const handleInfrastructureOnChange = (storeIoE: StoreIoE) => () => {
  storeIoE.reloadCheckersExposure()
}

/**
 * Reload identity indicators when selecting tenants.
 */
export const handleTenantOnChange = (storeIoE: StoreIoE) => () => {
  storeIoE.reloadCheckersIdentity()
}
