import { useAppTranslator, useStores } from '@app/hooks'
import { Input, Slider } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ContainerFlex } from 'tenable-design-system-alt'

export interface ISliderDelayProps {}

function SliderSearchDelay(props: ISliderDelayProps) {
  const { storeManagementAttackTypeConfiguration } = useStores()

  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.IoA']
  })

  return (
    <ContainerFlex
      name="SliderSearchDelay"
      flexAlignItems="center"
      flexWrap="nowrap"
      flexGap="spacingXs"
    >
      <Slider
        style={{ width: '200px' }}
        min={30}
        max={540}
        value={storeManagementAttackTypeConfiguration.customPastDelaySeconds}
        tooltip={{
          open: false
        }}
        onChange={value => {
          storeManagementAttackTypeConfiguration.setCustomPastDelaySeconds(
            value
          )
        }}
      />

      <Input
        style={{ width: 50 }}
        value={storeManagementAttackTypeConfiguration.customPastDelaySeconds}
      />

      {translate('seconds')}
    </ContainerFlex>
  )
}

export default observer(SliderSearchDelay)
