import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import { canAccessToIdentityIoEDetails } from '../IoEBoardPage/permissions'

export const canReadIoEIdentityInformation = rbacCapabilityCheckAllOf(
  canAccessToIdentityIoEDetails
)

export const canAccessToIoEIdentityFindings = rbacCapabilityCheckAllOf(
  canAccessToIdentityIoEDetails
)

export const canReadIoEIdentityRecommandations = rbacCapabilityCheckAllOf(
  canAccessToIdentityIoEDetails
)

export const canReadIoEIdentityComplianceStatus = rbacCapabilityCheckAllOf(
  canAccessToIdentityIoEDetails
)

export const canExportIoEIdentityFindings = rbacCapabilityCheckAllOf(
  canAccessToIdentityIoEDetails
)
