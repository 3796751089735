import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  ContainerContent,
  ContainerFooter,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import {
  Drawer,
  DrawerCancelButton,
  DrawerContext,
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { FormWrapperSelect } from '@app/components-legacy/Form'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckerDetailsPageContext } from '../../context'
import { canExportDeviantObjects } from '../../permissions'
import { handleExportDeviantObjectsOnSubmit } from './handlers'

export interface IDrawerExportDeviantObjectsProps {}

const DrawerExportDeviantObjects: React.FC<
  IDrawerExportDeviantObjectsProps
> = () => {
  const parameters = React.useContext(CheckerDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Export', 'IoE.Details.DeviantObjects']
  })

  const { storeMessages, storeIoE } = useStores()

  const appRouter = useAppRouter()

  if (!parameters) {
    return null
  }

  const { storeDrawerExportObjects } =
    storeIoE.storeIndicator.storeIndicatorDeviantObjects

  const renderContent = () => {
    const fields: IField[] = [
      {
        name: 'exportFormat',
        label: translate('Export format'),
        control: (
          <FormWrapperSelect
            labelledBy="exportFormat"
            selectProps={{
              defaultValue: 'csv'
            }}
            selectOptions={[
              {
                label: 'CSV',
                value: 'csv'
              }
            ]}
          />
        )
      }
    ]

    return (
      <FormWrapper
        name="deviantObjectsExport"
        onSubmit={handleExportDeviantObjectsOnSubmit(
          storeMessages,
          storeIoE
        )(appRouter)(parameters.checkerId, parameters.checkerCodename)}
      >
        <ContainerContent>
          <p>
            {translate(
              'You are exporting all the deviant objects related to this indicator'
            )}
          </p>

          <ContainerContent>
            <ContainerForm
              variant={ContainerFormVariant.drawer}
              fields={fields}
            />
          </ContainerContent>
        </ContainerContent>

        <Portal name={PlaceHolderName.drawerFooter}>
          <ContainerFooter>
            <DrawerCancelButton />

            <FormWrapperButtonSubmit>
              {translate('Export all')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </FormWrapper>
    )
  }

  return (
    <DrawerContext.Provider value={{ storeDrawer: storeDrawerExportObjects }}>
      <Drawer>
        <DrawerHorizontalContainer>
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: (
                <DrawerTitle>{translate('Export deviant objects')}</DrawerTitle>
              ),
              content: renderContent(),
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canExportDeviantObjects}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerExportDeviantObjects)
