import { AssetImg } from '@app/components/Asset/AssetImg'
import * as React from 'react'

interface IIconShieldProps {
  text?: React.ReactNode | string
  size?: number
}

const IconShield: React.FC<IIconShieldProps> = props => {
  return (
    <AssetImg
      imageName="icons/shield"
      fileType="svg"
      width={props.size ?? 350}
    />
  )
}

export default IconShield
