import type { StoreManagementApplicationSettings } from '@app/stores'
import { HealthCheckFormFieldName } from '@app/stores/Management/StoreApplicationSettings/types'

export const handleHealthCheckConfigurationLoad =
  (storeManagementApplicationSettings: StoreManagementApplicationSettings) =>
  () => {
    storeManagementApplicationSettings.fetchApplicationSettings().then(() => {
      const applicationSettings =
        storeManagementApplicationSettings.applicationSettings

      if (!applicationSettings) {
        return
      }

      storeManagementApplicationSettings.storeFormHealthCheckConfiguration
        .setDefaultFieldsValues([
          {
            key: HealthCheckFormFieldName.globalStatusDisplayEnabled,
            value: applicationSettings.getPropertyAsBoolean(
              'healthCheckGlobalStatusDisplayEnabled'
            )
          }
        ])
        .reset()
    })
  }

export const handleHealthCheckConfigurationUnload =
  (storeManagementApplicationSettings: StoreManagementApplicationSettings) =>
  () => {
    storeManagementApplicationSettings.storeFormHealthCheckConfiguration.reset()
  }
