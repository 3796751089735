import { ContainerContent, ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { useAppTranslator, useStores } from '@app/hooks'
import {
  handleAccountsWithTier0AttackPathLoad,
  handleAccountsWithTier0AttackPathUnload
} from '@app/pages/AttackPath/SceneBlade/NodeFinder/Tier0/handlers'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import AttackPathTier0AccountsFilters from './AttackPathTier0AccountsFilters'
import AttackPathTier0AccountsTable from './AttackPathTier0AccountsTable'

const StyledLabel = styled(Label)`
  margin: 0 ${consts.marginDefault};
`

interface IAttackPathTier0AccountsProps {
  className?: string
}

const AttackPathTier0Accounts: React.FC<
  IAttackPathTier0AccountsProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.Tier0']
  })

  const { storeAttackPath } = useStores()

  const { storeAttackPathTier0 } = storeAttackPath

  return (
    <ContainerContent
      onLoad={handleAccountsWithTier0AttackPathLoad(storeAttackPathTier0)}
      onUnload={handleAccountsWithTier0AttackPathUnload(storeAttackPathTier0)}
    >
      <ContainerFlex
        className={props.className}
        name="AttackPathTier0Accounts"
        direction="column"
        items={[
          <StyledLabel variant={LabelVariant.p}>
            {translate('Displays all user and computer accounts')}
          </StyledLabel>,
          <AttackPathTier0AccountsFilters />,
          <AttackPathTier0AccountsTable />
        ]}
        spaced
      />
    </ContainerContent>
  )
}

export default observer(AttackPathTier0Accounts)
