import {
  EntityAttackPathEdge,
  EntityAttackPathNode,
  EntityAttackPathNodeDrawer,
  EntityAttackPathNodeExpand
} from '@app/entities'
import { isDefined } from '@libs/isDefined'
import { AttackPathDirection } from '@server/graphql/typeDefs/types'
import { flow, isNull } from 'lodash'
import { v4 as v4uuid } from 'uuid'
import { CELL_SIZE, MAX_CHILDREN_BEFORE_HIDING_THEM } from '../consts'
import type {
  EntityAttackPathNodeAny,
  OnionGenerationData,
  PlacementAlgorithmData
} from '../types'
import {
  computeEdgesProperties,
  generateEmptyBooleanArray,
  getMaxCellOnARing,
  getSpreadsheetCellFromOnionRingCell,
  groupNodes,
  groupNodesByParent,
  rotatePointAroundPivot,
  sortByDepth
} from '../utils'

export type Onion = Map<number, EntityAttackPathNodeAny[]> // Map<onionRingIndex, node[]>
export type DepthNodes = Map<number, EntityAttackPathNode[]> // Map<depth, node[]>

/**
 * Sorts nodes by increasing depth
 */
export const orderByIncreasingDepth = (
  nodes: EntityAttackPathNode[]
): EntityAttackPathNode[] => {
  return nodes.sort(sortByDepth)
}

/**
 * Return a map with the depth as a key and a nodes as value
 */
export const groupNodesByDepth = (
  nodes: EntityAttackPathNode[]
): DepthNodes => {
  return nodes.reduce((acc, node) => {
    if (isNull(node.depth)) {
      return acc
    }

    const byDepthNodes = acc.get(node.depth) ?? []
    byDepthNodes.push(node)
    acc.set(node.depth, byDepthNodes)
    return acc
  }, new Map<number, EntityAttackPathNode[]>())
}

/**
 * Return a map of expands nodes and drawer nodes
 */
export const getFurtherEdgesNodes = (
  nodes: DepthNodes
): Array<EntityAttackPathNodeExpand | EntityAttackPathNodeDrawer> => {
  const depths = Array.from(nodes.keys())

  const lastDepth = depths.pop()

  if (!lastDepth || lastDepth === 0) {
    return []
  }

  const lastDepthNodes = nodes.get(lastDepth)

  if (!lastDepthNodes) {
    return []
  }

  const nodesWithFurtherEdges = lastDepthNodes.filter(
    node => node.nextDepthNodeCount && node.nextDepthNodeCount > 0
  )

  if (!nodesWithFurtherEdges.length) {
    return []
  }

  return nodesWithFurtherEdges.map(node => {
    if (
      node.nextDepthNodeCount &&
      node.nextDepthNodeCount > MAX_CHILDREN_BEFORE_HIDING_THEM
    ) {
      return new EntityAttackPathNodeDrawer({
        uid: v4uuid(),
        linkedNodeEntity: node,
        depth: lastDepth + 1
      })
    }
    return new EntityAttackPathNodeExpand({
      uid: v4uuid(),
      linkedNodeEntity: node,
      depth: lastDepth + 1
    })
  })
}

/**
 * Return a map of drawer nodes
 */
export const getDrawerNodes = (
  nodes: DepthNodes
): EntityAttackPathNodeDrawer[] => {
  const drawerNodes: EntityAttackPathNodeDrawer[] = []

  nodes.forEach((depthNode, depth) => {
    depthNode.forEach(node => {
      if (node.hasTooManyChildren) {
        const drawerNode = new EntityAttackPathNodeDrawer({
          uid: v4uuid(),
          linkedNodeEntity: node,
          depth: depth + 1
        })

        drawerNodes.push(drawerNode)
      }
    })
  })

  return drawerNodes
}

/**
 * Return the maximum value for a ring index
 */
export const getMaxOnionRingIndex = (onion: Onion) => {
  return Array.from(onion.keys()).reduce((max, index) => {
    return max > index ? max : index
  }, 0)
}

/**
 * Generate an onion by defining which ring is affected to which depth
 * Returning a map with ring index as key and array of nodes as value
 *
 * This is the step 1 of the onion placement algorithm
 */
export const generateOnion = (
  nodesByDepth: Map<number, EntityAttackPathNodeAny[]>
): Map<number, EntityAttackPathNodeAny[]> => {
  let lastOnionRingIndex = -1

  // We want to get a unique onion ring for each depth that can contain every node
  return Array.from(nodesByDepth.values()).reduce(
    (acc, nodes, currentIndex) => {
      lastOnionRingIndex++
      if (currentIndex === 0) {
        // First node is always on the first ring as there is only one node
        acc.set(0, nodes)
        return acc
      }

      const nodesByParent = new Map<string, EntityAttackPathNodeAny[]>()

      nodes.forEach(node => {
        const uid = node.linkedNodeEntity?.uid ?? 'orphan'
        const parentNodes = nodesByParent.get(uid)

        if (parentNodes) {
          parentNodes.push(node)
          return
        }
        nodesByParent.set(uid, [node])
      })

      const maxNodesByBranch = Array.from(nodesByParent.values()).reduce(
        (maxValue, parentNodes) =>
          parentNodes.length > maxValue ? parentNodes.length : maxValue,
        0
      )

      const totalIndexes = maxNodesByBranch * nodesByParent.size

      // Search for a ring where there are enough cells to assign nodes
      // We add an extra ring on the last depth to avoid having crossed lines
      // because of the grouping
      while (lastOnionRingIndex * 8 < totalIndexes) {
        lastOnionRingIndex++
      }
      acc.set(lastOnionRingIndex, nodes)

      return acc
    },
    new Map<number, EntityAttackPathNodeAny[]>() // Return a Map<onionRingIndex, Nodes> object
  )
}

/**
 * Generate an onion by defining which ring is affected to which depth
 * Returning a map with ring index as key and array of nodes as value
 *
 * This is the step 1 of the onion placement algorithm
 */
export const generateOnionForExpand = (
  nodesByDepth: Map<number, EntityAttackPathNode[]>,
  lastOnionRingIndex: number
): Map<number, EntityAttackPathNodeAny[]> => {
  // We want to get a unique onion ring for each depth that can contain every node
  return Array.from(nodesByDepth.values()).reduce(
    (acc, nodes) => {
      lastOnionRingIndex++

      // Search for a ring where there are enough cells to assign nodes
      while (lastOnionRingIndex * 2 + 1 < nodes.length) {
        lastOnionRingIndex++
      }
      acc.set(lastOnionRingIndex, nodes)

      return acc
    },
    new Map<number, EntityAttackPathNodeAny[]>() // Return a Map<onionRingIndex, Nodes> object
  )
}

export const reverseOnion = (onion: Onion): Onion => {
  return new Map(
    Array.from(onion.entries()).sort(([onionRingIndexA], [onionRingIndexB]) => {
      return onionRingIndexA > onionRingIndexB ? -1 : 1
    })
  )
}

/**
 * Returns a list of nodes well placed.
 *
 * This is the step 2 of the onion placement algorithm.
 *
 * @param rawNodes nodes to place
 * @param rotateGraph rotates the graph by 180° if true to display the first nodes to the right
 */
export const generateOnionPlacement = (
  rawNodes: EntityAttackPathNode[],
  rotateGraph?: boolean
): OnionGenerationData => {
  const nodesByDepth = flow(orderByIncreasingDepth, groupNodesByDepth)(rawNodes)

  const depth2nodes = nodesByDepth.get(2)

  const temporaryDepth1Nodes: EntityAttackPathNode[] = []
  const depth2NodesMoved: EntityAttackPathNode[] = []

  if (depth2nodes) {
    // Move depth 1 nodes with drawer to depth 2 to prevent nodes collisions on expand
    const filteredDepth1Nodes = nodesByDepth.get(1)?.filter(node => {
      if (node.hasTooManyChildren) {
        // Create a temporary node in depth 1 which is linked to the moved node
        // to make sure the place in depth 1 is reserved
        const temporaryNode = new EntityAttackPathNode({
          uid: v4uuid(),
          linkedNodeEntity: node.linkedNodeEntity
        })

        node.linkedNodeEntity = temporaryNode

        temporaryDepth1Nodes.push(temporaryNode)

        depth2NodesMoved.push(node)

        return false
      }
      return true
    })

    depth2nodes.unshift(...depth2NodesMoved)

    nodesByDepth.set(1, [
      ...temporaryDepth1Nodes,
      ...(filteredDepth1Nodes || [])
    ])
  }

  const expandsNodes = getFurtherEdgesNodes(nodesByDepth)
  const drawerNodes = getDrawerNodes(nodesByDepth)

  const allExpands = [...expandsNodes, ...drawerNodes]

  const onion = generateOnion(nodesByDepth)

  const reversedOnion = reverseOnion(onion)

  const maxOnionRingIndex = getMaxOnionRingIndex(onion)

  const placedNodes: Map<string, EntityAttackPathNodeAny[]> = new Map()
  let previousOnionRingIndex = 0

  reversedOnion.forEach((nodes, onionRingIndex) => {
    if (maxOnionRingIndex > 0 && onionRingIndex === maxOnionRingIndex) {
      placeExternalOnionRing(
        onionRingIndex,
        nodes,
        maxOnionRingIndex,
        placedNodes
      )

      // Save the index as the next previous one.
      previousOnionRingIndex = onionRingIndex

      return
    }

    if (maxOnionRingIndex === 0 || onionRingIndex < maxOnionRingIndex) {
      placeInternalOnionRing(
        onionRingIndex,
        nodes,
        maxOnionRingIndex,
        previousOnionRingIndex,
        placedNodes
      )
    }

    // Save the index as the next previous one.
    previousOnionRingIndex = onionRingIndex
  })

  // Place expands
  if (allExpands.length) {
    placeExpands(allExpands, maxOnionRingIndex)
  }

  const centeredNode = nodesByDepth.get(0)?.[0]

  if (!centeredNode) {
    // Typescript checking
    return { maxOnionRingIndex, nodes: [] }
  }

  // Remove temporary depth 1 nodes moved to depth 2
  const placedDepth1 = placedNodes.get(centeredNode.uid)

  const depth1NodesUuidToRemove = temporaryDepth1Nodes.map(node => node.uid)

  const filteredPlacedDepth1 = placedDepth1?.filter(node => {
    return !depth1NodesUuidToRemove.includes(node.uid)
  })

  if (filteredPlacedDepth1) {
    placedNodes.set(centeredNode.uid, filteredPlacedDepth1)
  }

  // Link moved nodes to the central node
  depth2NodesMoved.forEach(node => {
    node.linkedNodeEntity = centeredNode
  })

  const flatNodes = Array.from(placedNodes.values()).reduce((acc, nodes) => {
    acc.push(...nodes)
    return acc
  }, allExpands)

  // Compute cell coords
  centeredNode.x = Number(centeredNode.cell?.column) * CELL_SIZE
  centeredNode.y = Number(centeredNode.cell?.row) * CELL_SIZE

  centeredNode.placed = true

  const centeredNodeCoords = centeredNode.coords

  flatNodes.forEach(node => {
    if (node.uid === centeredNode.uid) {
      return
    }

    const nodeCoords = {
      x: Number(node.cell?.column) * CELL_SIZE,
      y: Number(node.cell?.row) * CELL_SIZE
    }

    const rotatedCoords = rotatePointAroundPivot(
      nodeCoords,
      centeredNodeCoords,
      rotateGraph ? -135 : 45
    )

    // Affect rotated coords
    node.x = rotatedCoords.x
    node.y = rotatedCoords.y

    node.placed = true
  })

  // Remove nodes that have not been placed in the graph
  const placedFlatNodes = flatNodes.filter(node => node.placed)

  return { maxOnionRingIndex, nodes: placedFlatNodes }
}

/**
 * Proceed the external onion ring
 * This method place every "grouped by parent" nodes with some space between
 */
export const placeExternalOnionRing = (
  onionRingIndex: number,
  nodes: EntityAttackPathNodeAny[],
  maxOnionRingIndex: number,
  placedNodesRef: Map<string, EntityAttackPathNodeAny[]>
): void => {
  const nodesByParent = groupNodesByParent(nodes)

  const totalBoxes = maxOnionRingIndex * 8

  const boxesAllocatedByParent = totalBoxes / nodesByParent.size

  // Proceed assignments
  let parentIndex = 0
  nodesByParent.forEach(byParentNodes => {
    let nextCellIndex =
      (totalBoxes +
        parentIndex * boxesAllocatedByParent -
        Math.floor(byParentNodes.length / 2)) %
      totalBoxes

    // For each node, we will display them one after the other
    byParentNodes.forEach(entity => {
      if (
        !entity.uid ||
        !entity.linkedNodeEntity ||
        !entity.linkedNodeEntity.uid
      ) {
        return
      }
      // Get translation from onion ring cell to spreadsheet one
      const calculatedCell = getSpreadsheetCellFromOnionRingCell({
        maxOnionRingIndex,
        onionRingIndex,
        onionRingCellIndex: nextCellIndex
      })

      // Affect values to the node
      entity.setCell(calculatedCell)

      const entitiesGroupedByParentId =
        placedNodesRef.get(entity.linkedNodeEntity.uid) ?? []
      entitiesGroupedByParentId.push(entity)
      placedNodesRef.set(entity.linkedNodeEntity.uid, entitiesGroupedByParentId)

      // Move to next cell
      nextCellIndex = (totalBoxes + nextCellIndex + 1) % totalBoxes
    })

    parentIndex++
  })
}

/**
 * Proceed the external onion ring for expanded items
 * This method place every "grouped by parent" nodes with some space between
 */
export const placeExternalExpandedOnionRing = (
  onionRingIndex: number,
  nodes: EntityAttackPathNodeAny[],
  rootOnionRingIndex: number,
  placedNodesRef: Map<string, EntityAttackPathNodeAny[]>,
  minCellIndex: number,
  maxOnionRingCellIndex: number
): void => {
  const nodesByParent = groupNodesByParent(nodes)

  // Proceed assignments
  let lastCellIndex = minCellIndex
  nodesByParent.forEach(byParentNodes => {
    // Pour chaque noeud, on va faire en sorte de les afficher les uns après les autres
    byParentNodes.forEach(entity => {
      if (
        !entity.uid ||
        !entity.linkedNodeEntity ||
        !entity.linkedNodeEntity.uid
      ) {
        return
      }
      // Get translation from onion ring cell to spreadsheet one
      const calculatedCell = getSpreadsheetCellFromOnionRingCell({
        maxOnionRingIndex: rootOnionRingIndex,
        onionRingIndex,
        onionRingCellIndex: lastCellIndex
      })

      // Affect values to the node
      entity.setCell(calculatedCell)

      const entitiesGroupedByParentId =
        placedNodesRef.get(entity.linkedNodeEntity.uid) ?? []
      entitiesGroupedByParentId.push(entity)
      placedNodesRef.set(entity.linkedNodeEntity.uid, entitiesGroupedByParentId)

      // Move to next cell
      lastCellIndex++

      if (lastCellIndex > maxOnionRingCellIndex) {
        lastCellIndex = 0
      }
    })
  })
}

/**
 * Find the median cell index of a group of nodes
 */
const getCenteredCellIndexFromNodes = (
  nodes: EntityAttackPathNodeAny[]
): number => {
  if (nodes.length === 1) {
    return nodes[0].cell?.onionRingCellIndex ?? 0
  }

  const { length, [0]: firstNode, [length - 1]: lastNode } = nodes

  const firstCellIndex = firstNode.cell?.onionRingCellIndex ?? 0
  const lastCellIndex = lastNode.cell?.onionRingCellIndex

  if (isDefined(lastCellIndex)) {
    if (firstCellIndex > lastCellIndex) {
      return nodes[Math.floor(length / 2)].cell?.onionRingCellIndex ?? 0
    }
    return firstCellIndex + (lastCellIndex - firstCellIndex) / 2
  }

  return firstCellIndex
}

/**
 * Proceed one internal onion ring
 * This method loop over nodes, search for children that have been placed,
 * take the one at the middle then place it accordingly
 * If no children for this node, affect a random unused cell.
 */
export const placeInternalOnionRing = (
  onionRingIndex: number,
  nodes: EntityAttackPathNodeAny[],
  maxOnionRingIndex: number,
  parentOnionRingIndex: number,
  placedNodesRef: Map<string, EntityAttackPathNodeAny[]>
): void => {
  const unplacedNodes: EntityAttackPathNodeAny[] = []

  const cellCountForThisOnionRing = getMaxCellOnARing(onionRingIndex)
  const cellIndexes = new Map<number, boolean>(
    generateEmptyBooleanArray(cellCountForThisOnionRing, false).map(
      (val, index) => [index, val]
    )
  )

  nodes.forEach(entity => {
    const groupedChildren = placedNodesRef.get(entity.uid)
    if (!groupedChildren) {
      unplacedNodes.push(entity)
      return
    }

    // Get centered index
    const centeredIndex = getCenteredCellIndexFromNodes(groupedChildren)

    // Determine the ring cell index as a projection of N-1 to N onion ring
    const percentDiffOnionRingSize = onionRingIndex / parentOnionRingIndex
    let onionRingCellIndex = Math.floor(
      centeredIndex * percentDiffOnionRingSize
    )

    // Check if this cell is not already used
    // If so, move to the next available cell
    while (cellIndexes.get(onionRingCellIndex) === true) {
      onionRingCellIndex++

      if (onionRingCellIndex > cellCountForThisOnionRing) {
        onionRingCellIndex = 0
      }
    }

    const calculatedCell = getSpreadsheetCellFromOnionRingCell({
      maxOnionRingIndex,
      onionRingIndex,
      onionRingCellIndex
    })

    // Affect values to the node
    entity.setCell(calculatedCell)

    if (onionRingIndex === 0) {
      // Do special treatment for root node as there is no parent
      // Parent id will not be needed because it's the last iteration.
      // But adding this node is needed to return its position
      placedNodesRef.set('', [entity])
      return
    }

    if (!entity.linkedNodeEntity) {
      return
    }
    // Save reference of entity in a group of nodes with same parent for next iteration
    const entitiesGroupedByParentId =
      placedNodesRef.get(entity.linkedNodeEntity.uid) ?? []

    entitiesGroupedByParentId.push(entity)

    cellIndexes.set(onionRingCellIndex, true)

    placedNodesRef.set(entity.linkedNodeEntity.uid, entitiesGroupedByParentId)
  })

  // Proceed unplaced nodes
  // This has to be done after inital placement because we don
  unplacedNodes.forEach(entity => {
    // Find first unused cell index
    const unusedCellIndexes = Array.from(cellIndexes.entries()).filter(
      value => value[1] === false
    )

    // to remove "|| !unusedCellIndexes.length", only here for debug purpose
    if (!unusedCellIndexes.length) {
      return
    }

    // Select the first available cell
    const onionRingCellIndex = unusedCellIndexes[0][0]

    // Calculate the right cell
    const calculatedCell = getSpreadsheetCellFromOnionRingCell({
      maxOnionRingIndex,
      onionRingIndex,
      onionRingCellIndex
    })

    // Affect values to the node
    entity.setCell(calculatedCell)

    // When we only have 1 node in the graph
    if (!entity.linkedNodeEntity) {
      placedNodesRef.set('', [entity])

      return
    }

    const entitiesGroupedByParentId =
      placedNodesRef.get(entity.linkedNodeEntity.uid) ?? []

    entitiesGroupedByParentId.push(entity)

    // Update the map to not affect two time the same index
    cellIndexes.set(onionRingCellIndex, true)

    placedNodesRef.set(entity.linkedNodeEntity.uid, entitiesGroupedByParentId)
  })
}

/**
 * Proceed expands onion ring
 * This method loop over nodes, search for the parent cell,
 * calculate the proportional placement on next onion ring and place it
 */
export const placeExpands = (
  nodes: EntityAttackPathNodeAny[],
  rootOnionRingIndex: number
): void => {
  nodes.forEach(entity => {
    const parentOnionRingIndex =
      entity.linkedNodeEntity?.cell?.onionRingIndex || 0
    // We place the onionRingIndex to one half step forward the parent
    const onionRingIndex = parentOnionRingIndex + 0.4

    const parentOnionRingCellIndex =
      entity.linkedNodeEntity?.cell?.onionRingCellIndex

    if (!isDefined(parentOnionRingCellIndex)) {
      return
    }

    // Calculate the extrapolated position from parent
    const percentDiffOnionRingSize =
      parentOnionRingIndex === 0 ? 0 : onionRingIndex / parentOnionRingIndex

    const onionRingCellIndex = Math.round(
      parentOnionRingCellIndex * percentDiffOnionRingSize
    )

    const calculatedCell = getSpreadsheetCellFromOnionRingCell({
      maxOnionRingIndex: rootOnionRingIndex,
      onionRingIndex,
      onionRingCellIndex
    })

    // Affect values to the node
    entity.setCell(calculatedCell)
  })
}

/**
 * Returns a list of well placed extended nodes
 *
 * This is the step 2 of the onion placement algorithm for expanded nodes
 *
 * /!\ It should be used only for expanded nodes, as it uses a different method as the former one. /!\
 * Differences are :
 *  - We already know the central node (expandedNode)
 *  - Available cells are limited to a range around the central node (to avoid edges all around the central node)
 *  - This range is calculated by extrapolating the position of parent on the next available ring and placing nodes evenly around this index
 *
 * centeredNode is the global central one and is used for rotation only
 */
export const generateExpandNodesPlacement = (
  rawNodes: EntityAttackPathNode[],
  expandedNode: EntityAttackPathNode,
  centeredNode: EntityAttackPathNodeAny,
  rootOnionRingIndex: number
): OnionGenerationData => {
  const expandedNodeOnionRingIndex = expandedNode.cell?.onionRingIndex ?? 0

  // Group nodes by depth
  const nodesByDepth = flow(orderByIncreasingDepth, groupNodesByDepth)(rawNodes)

  const expandsNodes = getFurtherEdgesNodes(nodesByDepth)

  // Add 1 to include the expand button emplacement
  const lastOnionRingIndex = expandedNodeOnionRingIndex + 1

  // Generate the onion
  const onion = generateOnionForExpand(nodesByDepth, lastOnionRingIndex)

  // Reverse onion
  const reversedOnion = reverseOnion(onion)

  const maxOnionRingIndex = getMaxOnionRingIndex(onion)

  const placedNodes: Map<string, EntityAttackPathNodeAny[]> = new Map()

  reversedOnion.forEach((nodes, onionRingIndex) => {
    const maxOnionRingCellIndex = onionRingIndex * 8 - 1

    const percentDiffOnionRingSize =
      expandedNodeOnionRingIndex === 0
        ? 0
        : onionRingIndex / expandedNodeOnionRingIndex

    let minCellIndex = Math.round(
      (expandedNode.cell?.onionRingCellIndex ?? 1) * percentDiffOnionRingSize -
        Math.floor(nodes.length / 2)
    )

    if (minCellIndex < 0) {
      minCellIndex += maxOnionRingCellIndex
    }

    placeExternalExpandedOnionRing(
      onionRingIndex,
      nodes,
      rootOnionRingIndex,
      placedNodes,
      minCellIndex,
      maxOnionRingCellIndex
    )
  })

  // Place expands
  if (expandsNodes.length) {
    placeExpands(expandsNodes, rootOnionRingIndex)
  }

  const flatNodes = Array.from(placedNodes.values()).reduce((acc, nodes) => {
    acc.push(...nodes)
    return acc
  }, expandsNodes)

  const centeredNodeCoords = centeredNode.coords
  flatNodes.forEach(node => {
    if (node.uid === centeredNode.uid) {
      return
    }

    const nodeCoords = {
      x: Number(node.cell?.column) * CELL_SIZE,
      y: Number(node.cell?.row) * CELL_SIZE
    }

    const rotatedCoords = rotatePointAroundPivot(
      nodeCoords,
      centeredNodeCoords,
      45
    )

    // Affect rotated coords
    node.x = rotatedCoords.x
    node.y = rotatedCoords.y

    node.placed = true
  })

  const placedFlatNodes = flatNodes.filter(node => node.placed)

  return { maxOnionRingIndex, nodes: placedFlatNodes }
}

// Place all nodes for the search from or to a searched node
export const placeNodesForOneNodeSearch = (
  linkedNodes: EntityAttackPathNode[],
  linkedEdges: EntityAttackPathEdge[],
  rootNode: EntityAttackPathNode,
  direction: AttackPathDirection
): PlacementAlgorithmData => {
  const directionFrom = direction === AttackPathDirection.From

  // Filter all children that are linked to a node that exceed the threshold
  // Do that only when we are not in 2 nodes search
  const groupedNodes = groupNodes(linkedNodes, linkedEdges, direction)

  // Add entities placed with the onion method
  const onionGeneration = generateOnionPlacement(groupedNodes, false)

  const nodesWithFurtherEdges = onionGeneration.nodes.filter(node => !node.id)

  const edgesFromFurtherEdges = nodesWithFurtherEdges
    .map(expand => {
      if (!expand.linkedNodeEntity) {
        return
      }
      const edgeEntity = new EntityAttackPathEdge({
        id: v4uuid(),
        sourceUid: directionFrom ? expand.linkedNodeEntity.uid : expand.uid, // adding uid for easy finding later
        targetUid: directionFrom ? expand.uid : expand.linkedNodeEntity.uid // adding uid for easy finding later
      })
      return edgeEntity
    })
    .filter(isDefined)

  const remainingNodeUids = new Set(onionGeneration.nodes.map(node => node.uid))

  const cleanedEdges = linkedEdges.filter(
    edge =>
      edge.sourceUid &&
      edge.targetUid &&
      remainingNodeUids.has(edge.sourceUid) &&
      remainingNodeUids.has(edge.targetUid)
  )

  // Compute properties for all edges
  const edges = computeEdgesProperties(
    onionGeneration.nodes,
    cleanedEdges.concat(edgesFromFurtherEdges)
  )

  return {
    nodes: onionGeneration.nodes.length ? onionGeneration.nodes : [rootNode],
    maxIndex: onionGeneration.maxOnionRingIndex,
    edges
  }
}
