import { ContainerFlex } from '@app/components-legacy/Container'
import { InputSearch } from '@app/components-legacy/Input'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { sceneHeaderZIndex } from '../zIndexes'
import TrustsSwitch from './TrustsSwitch'
import ZoomSlider from './ZoomSlider'

interface ISceneHeaderProps {
  className?: string
}

const SceneHeader: React.FC<ISceneHeaderProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Topology.Chart']
  })

  const { storeTopology } = useStores()

  return (
    <ContainerFlex
      name="SceneHeader"
      className={props.className}
      direction="row"
      alignItems="center"
      itemsFlexGrow={[1, 0, 0]}
      items={[
        <InputSearch
          placeholder={translate('Search')}
          storeInputSearch={storeTopology.storeInputSearch}
        />,
        <ZoomSlider />,
        <TrustsSwitch />
      ]}
      spaced
    />
  )
}

export default styled(SceneHeader)`
  position: absolute;
  top: 10px;
  left: 0px;
  z-index: ${sceneHeaderZIndex};
  width: 100%;
  background: ${consts.colorWhite};
  padding: 0 20px;
`
