export enum InputCrontabPeriod {
  'byYear' = 'byYear',
  'byMonth' = 'byMonth',
  'byWeek' = 'byWeek',
  'byDay' = 'byDay'
}

export enum InputCrontabUnit {
  // keep same order as cron expression
  minute = 'minute',
  hours = 'hours',
  daysOfTheMonth = 'daysOfTheMonth',
  months = 'months',
  daysOfTheWeek = 'daysOfTheWeek'
}
