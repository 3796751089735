import { ContainerFlex } from '@app/components-legacy/Container'
import { PlaceHolderDrawersVertical } from '@app/components-legacy/PlaceHolder'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import * as React from 'react'
import TableFilters from './Table/TableFilters'
import TableHeader from './Table/TableHeader'

interface ITrailFlowPageHeaderProps {}

const TrailFlowPageHeader: React.FC<ITrailFlowPageHeaderProps> = () => {
  return (
    <ContainerFlex
      name="TrailFlowPageHeader"
      direction="column"
      items={[
        <TableFilters />,

        <PlaceHolderDrawersVertical name={PlaceHolderName.verticalDrawer} />,

        <TableHeader />
      ]}
      spaced
      spaceWidth="verySmall"
    />
  )
}

export default TrailFlowPageHeader
