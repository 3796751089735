import {
  ContainerContent,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import { Modal, ModalCancelButton } from '@app/components-legacy/Modal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { RbacEntityItemType } from '@libs/rbac/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import RoleEditEntitiesListsData from './RoleEditEntitiesListsData'
import RoleEditEntitiesListsSelfUser from './RoleEditEntitiesListsSelfUser'
import RoleEditEntitiesListsSingleton from './RoleEditEntitiesListsSingleton'
import RoleEditEntitiesListsUI from './RoleEditEntitiesListsUI'
import RolesEditHeader from './RolesEditHeader'

export interface IRoleEditEntitiesListssProps {
  disabled: boolean
}

const RoleEditEntitiesLists: React.FC<IRoleEditEntitiesListssProps> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.Accounts.Roles',
      'Management.Accounts.Roles.Permissions',
      'Management.Accounts.Roles.Permissions.Modal'
    ]
  })

  const { storeManagementRbacRoles } = useStores()

  return (
    <>
      <ContainerContent title={translate('Permissions management')}>
        <p>{translate('List of permissions applyed on this role')}</p>

        <RolesEditHeader />

        {storeManagementRbacRoles.storeMenu.selectedMenuKey ===
          RbacEntityItemType.data && (
          <RoleEditEntitiesListsData disabled={props.disabled} />
        )}

        {storeManagementRbacRoles.storeMenu.selectedMenuKey ===
          RbacEntityItemType.userPersonal && (
          <RoleEditEntitiesListsSelfUser disabled={props.disabled} />
        )}

        {storeManagementRbacRoles.storeMenu.selectedMenuKey ===
          RbacEntityItemType.singleton && (
          <RoleEditEntitiesListsSingleton disabled={props.disabled} />
        )}

        {storeManagementRbacRoles.storeMenu.selectedMenuKey ===
          RbacEntityItemType.ui && (
          <RoleEditEntitiesListsUI disabled={props.disabled} />
        )}
      </ContainerContent>

      <Modal
        modalProps={{
          title: translate('Information'),
          footer: (
            <ModalCancelButton
              storeModal={storeManagementRbacRoles.storeModalEditSelfUser}
            />
          )
        }}
        storeModal={storeManagementRbacRoles.storeModalEditSelfUser}
      >
        <ContainerHTMLContent>
          {translate(
            'Permission to modify personal information cannot be revoked',
            { transformMarkdown: true }
          )}
        </ContainerHTMLContent>
      </Modal>
    </>
  )
}

export default observer(RoleEditEntitiesLists)
