import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useAppTranslator } from '@app/hooks'
import type StoreDrawer from '@app/stores/helpers/StoreDrawer'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import { DrawerContext, useDrawerContext } from '.'
import { FormWrapperButton as FormWrapperButtonDeprecated } from '../Form'

interface IButtonCloseLastBladeProps {
  storeDrawer?: StoreDrawer<any>
}

/**
 * Add a Cancel button for Drawer.
 * Retrieve automatically the current Drawer store via the context or use
 * the instance passed as parameter.
 *
 * Usage:
 *
 * <DrawerCancelButton />
 */
const DrawerCancelButton: React.FC<IButtonCloseLastBladeProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Buttons'] })

  const theme = useDSTheme()

  const context = useDrawerContext(DrawerContext)

  const themeIsLegacy = isThemeLegacy(theme)

  const storeDrawer = context?.storeDrawer || props.storeDrawer

  if (!storeDrawer) {
    return null
  }

  const handleOnClick = () => {
    storeDrawer.closeDrawer()
  }

  return themeIsLegacy ? (
    <FormWrapperButtonDeprecated
      labelledBy="cancel"
      pendoSuffix="drawer"
      buttonProps={{
        onClick: handleOnClick
      }}
    >
      {translate('Cancel')}
    </FormWrapperButtonDeprecated>
  ) : (
    <FormWrapperButton
      labelledBy="cancel"
      pendoSuffix="drawer"
      variant={ButtonVariant.secondary}
      size={ButtonSize.large}
      buttonProps={{
        onClick: handleOnClick
      }}
    >
      {translate('Cancel')}
    </FormWrapperButton>
  )
}

export default DrawerCancelButton
