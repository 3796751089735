import IconClose from '@app/components/Icon/IconClose'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { CTAColorV2 } from '@app/styles/consts'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import { ContainerFlex } from '../Container'
import { IconClose as IconCloseLegacy } from '../Icon'
import { DrawerContext, useDrawerContext } from './context'
import { handleDrawerOnClose } from './handlers'

interface IDrawerTitleProps {
  className?: string
  children?: React.ReactNode
}

function DrawerTitle(props: IDrawerTitleProps) {
  const contextValue = useDrawerContext(DrawerContext)
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  if (!contextValue) {
    return null
  }

  return (
    <ContainerFlex
      name="DrawerTitle"
      className={props.className}
      justifyContent="space-between"
      alignItems={themeIsLegacy ? undefined : 'center'}
      items={[
        themeIsLegacy ? (
          <div>{props.children}</div>
        ) : (
          <Label variant={LabelVariant.h2}>{props.children}</Label>
        ),
        themeIsLegacy ? (
          <div data-test="close-drawer">
            <IconCloseLegacy
              onClick={handleDrawerOnClose(contextValue.storeDrawer)}
            />
          </div>
        ) : (
          <FormWrapperButton
            labelledBy="closeDrawer"
            variant={ButtonVariant.colored}
            size={ButtonSize.small}
            buttonProps={{
              onClick: handleDrawerOnClose(contextValue.storeDrawer),
              icon: <IconClose color={CTAColorV2.primary} size={13} />
            }}
          />
        )
      ]}
    />
  )
}

export default DrawerTitle
