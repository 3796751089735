import type { PropertiesNullable } from '@@types/helpers'
import type { ProviderType, Tenant } from '@libs/openapi/service-identity-core'
import type { Maybe } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityTenant
  extends EntityBase
  implements PropertiesNullable<Tenant>
{
  id: Maybe<string> = null
  name: Maybe<string> = null
  providerType: Maybe<ProviderType> = null

  // save one color per tenant
  color: string = '#000000'

  constructor(data: Partial<Tenant>) {
    super()
    Object.assign(this, data)
  }

  setColor(color: string): this {
    this.color = color
    return this
  }
}
