import type { IContainerSimpleProps } from '@app/components-legacy/Container/ContainerSimple'
import ContainerSimple from '@app/components-legacy/Container/ContainerSimple'
import type { EntityEvent } from '@app/entities'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { onTrailFlowEventRowClick } from './handlers'
import { setTableColumnsStyled } from './styles'

interface ITableContentBodyRowProps extends IContainerSimpleProps {
  className?: string
  event: EntityEvent
  index: string
  isLive: boolean
}

const TableContentBodyRow: React.FC<ITableContentBodyRowProps> = props => {
  const appRouter = useAppRouter()

  const { storeInfrastructures } = useStores()

  const eventId = props.event.getPropertyAsString('id')
  const adObjectId = props.event.getPropertyAsString('adObjectId')

  const index = `event-${eventId}-adObjectId-${adObjectId}`

  return (
    <ContainerSimple
      name={TableContentBodyRow.name}
      className={props.className}
      ariaRoles={['list', 'row']}
      labelledBy={index}
      containerProps={{
        onClick: onTrailFlowEventRowClick(
          appRouter,
          storeInfrastructures
        )(props.event)
      }}
    >
      {props.children}
    </ContainerSimple>
  )
}

export default setTableColumnsStyled(styled(TableContentBodyRow)`
  cursor: pointer;
  transition: ${consts.transitionOpacity};
  padding: ${props => props.theme.tokens['table/padding/row']};

  :nth-child(odd) {
    background-color: ${props =>
      props.theme.tokens['table/backgroundColor/oddRow']};
  }

  :nth-child(even) {
    background-color: ${props =>
      props.theme.tokens['table/backgroundColor/evenRow']};
  }

  :hover {
    background-color: ${props =>
      props.theme.tokens['table/backgroundColor/rowHover']};
  }
`)
