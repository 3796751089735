import { ErrorMessage } from '@app/components/Error'
import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreWizard from '@app/stores/helpers/StoreWizard'
import type { WizardRuleGroup } from '@app/stores/helpers/StoreWizard/types'
import { isWizardRuleGroup } from '@app/stores/helpers/StoreWizard/utils'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { Combinator } from '@libs/Expression/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { onPushRule, onPushRuleGroup } from './handlers'

interface IButtonGroupProps {
  className?: string
  ruleGroupPath: number[]
  ruleGroup: WizardRuleGroup
  storeWizard: StoreWizard
}

const ButtonGroup: React.FC<IButtonGroupProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const translate = useAppTranslator({
    namespaces: ['Components.Wizard']
  })

  if (!isWizardRuleGroup(props.ruleGroup)) {
    return <ErrorMessage />
  }

  const renderButtonGroupItems = (): JSX.Element[] => {
    return [
      <FormWrapperButton
        labelledBy="addNewRule"
        buttonProps={{
          onClick: onPushRule(props.ruleGroupPath, props.storeWizard)
        }}
        wrapWithLabel
      >
        + {translate('Add a new rule')}
      </FormWrapperButton>,

      themeIsLegacy ? <div>/</div> : <Label variant={LabelVariant.p}>/</Label>,

      <ContainerFlex
        name="ButtonGroupCondition"
        alignItems="center"
        items={[
          themeIsLegacy ? (
            <div>{translate('Add a new condition')}</div>
          ) : (
            <Label variant={LabelVariant.p}>
              {translate('Add a new condition')}
            </Label>
          ),

          <FormWrapperButton
            labelledBy="and"
            buttonProps={{
              onClick: onPushRuleGroup(
                Combinator.AND,
                props.ruleGroupPath,
                props.storeWizard
              )
            }}
          >
            + AND
          </FormWrapperButton>,

          <FormWrapperButton
            labelledBy="or"
            buttonProps={{
              onClick: onPushRuleGroup(
                Combinator.OR,
                props.ruleGroupPath,
                props.storeWizard
              )
            }}
          >
            + OR
          </FormWrapperButton>
        ]}
        spaced
        spaceWidth="small"
      />
    ]
  }

  return (
    <ContainerFlex
      className={props.className}
      name="ButtonGroup"
      alignItems="center"
      items={renderButtonGroupItems()}
      spaced
    />
  )
}

const ObservedButtonGroup = observer(ButtonGroup)

export default styled(ObservedButtonGroup)`
  border-top: 1px solid
    ${props => props.theme.tokens['inputExpression/borderColor/default']};
  padding-top: 10px;
`
