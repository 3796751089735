import type { Maybe } from '@@types/helpers'
import { getDataSet } from '@app/components-legacy/Widgets/WidgetList/WidgetListTable/functions'
import type EntityAdObjectAsAttributes from '@app/entities/EntityAdObject/EntityAdObjectAsAttribute'
import type EntityIncriminatingAttributeListable from '@app/entities/EntityIncriminatingAttribute/EntityIncriminatingAttributeListable'
import type { IDataRowIncriminatingAttributeListable } from '@app/entities/EntityIncriminatingAttribute/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreInfrastructures, StoreInputReasons } from '@app/stores'
import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { ICheckerExposure } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import type StoreWidgetList from '@app/stores/helpers/StoreWidgetList'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import EventDetailDevianceCard from './EventDetailDevianceCard'

interface IEventDetailDeviancesCardsContentProps {
  adObject?: Maybe<EntityAdObjectAsAttributes>
  selectable?: boolean
  storeInfrastructures: StoreInfrastructures
  storeInputCheckersExposure?: StoreInputGenericCheckers<ICheckerExposure>
  storeInputReasons: StoreInputReasons
  storeWidgetList: StoreWidgetList<
    EntityIncriminatingAttributeListable,
    IDataRowIncriminatingAttributeListable
  >
}

const EventDetailDeviancesCardsContent: React.FC<
  IEventDetailDeviancesCardsContentProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.WidgetList']
  })

  const dataSet = getDataSet(props.storeWidgetList)

  if (!dataSet.rows.length) {
    return <div>{translate('No result')}</div>
  }

  return (
    <>
      {dataSet.rows.map(incriminatingAttributeDataRow => (
        <EventDetailDevianceCard
          key={incriminatingAttributeDataRow.id}
          adObject={props.adObject}
          incriminatingAttributeDataRow={incriminatingAttributeDataRow}
          selectable={props.selectable}
          storeWidgetList={props.storeWidgetList}
          storeInfrastructures={props.storeInfrastructures}
          storeInputCheckersExposure={props.storeInputCheckersExposure}
          storeInputReasons={props.storeInputReasons}
        />
      ))}
    </>
  )
}

export default observer(EventDetailDeviancesCardsContent)
