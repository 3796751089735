import { Popover as AntdPopover } from 'antd'
import type { PopoverProps } from 'antd/lib/popover'
import * as React from 'react'

export interface IPopoverRef {
  hidePopover: () => void
}

export interface IPopoverProps {
  children: React.ReactNode
  // needed to trigger a click to close the popover when hidePopover is called
  triggerRef?: React.RefObject<HTMLElement>
  popoverProps: PopoverProps
  tenableTheme?: boolean
}

const Popover = React.forwardRef<IPopoverRef, IPopoverProps>((props, ref) => {
  React.useImperativeHandle(ref, () => ({
    hidePopover() {
      if (props.popoverProps.trigger === 'click') {
        props.triggerRef?.current?.click?.()
      }
    }
  }))

  return (
    <AntdPopover
      overlayClassName={
        props.tenableTheme ? 'tenable-ant-popover' : 'custom-ant-popover'
      }
      {...props.popoverProps}
    >
      {props.children}
    </AntdPopover>
  )
})

export default Popover
