import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { IconNoData } from '@app/components-legacy/Icon'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { TypographyFieldsetTitle } from '@app/components-legacy/Typography'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import IoABoardCard from './IoABoardCard'

interface IIoABoardCardsProps {}

const IoABoardCards: React.FC<IIoABoardCardsProps> = () => {
  const { storeIoA } = useStores()
  const { storeBoard } = storeIoA

  const sortedCardItems =
    storeBoard.storeSummaryCards.sortedFilteredStoresSummaryCardBlocks.map(
      block => {
        const cardsItems = block.storesSummaryCard.map(storeSummaryCard => {
          return (
            <IoABoardCard
              key={storeSummaryCard.directoryEntity?.getPropertyAsNumber('id')}
              storeSummaryCard={storeSummaryCard}
            />
          )
        })

        return (
          <ContainerFlex
            name="IoABoardCardsBlock"
            direction="column"
            items={[
              <TypographyFieldsetTitle>
                {block.blockName}
              </TypographyFieldsetTitle>,

              <ContainerFlex
                name="IoABoardCardsBlockCards"
                items={cardsItems}
                flexWrap="wrap"
                spaced
                spaceWidth="default"
              />
            ]}
            spaced
            spaceWidth="small"
          />
        )
      }
    )

  const getContent = () => {
    if (!sortedCardItems.length) {
      return <IconNoData />
    }

    return (
      <ContainerFlex
        name={IoABoardCards.name}
        direction="column"
        items={sortedCardItems}
        spaced
        spaceWidth="default"
      />
    )
  }

  return (
    <ContainerContent
      spinner={<SpinnerInline />}
      flags={storeBoard.storeFlagsReloadAttacksSummary.flags}
    >
      {getContent()}
    </ContainerContent>
  )
}

export default observer(IoABoardCards)
