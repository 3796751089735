import { ContainerFlex } from '@app/components/Container'
import {
  ContainerContent,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import { FormWrapperSelect } from '@app/components-legacy/Form'
import { FormWrapper } from '@app/components-legacy/Form/Wrappers'
import { InputEmails, InputInfrastructures } from '@app/components-legacy/Input'
import InputCheckers from '@app/components-legacy/Input/InputCheckers'
import InputCrontab from '@app/components-legacy/Input/InputCrontab'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { TypographyFieldsetTitle } from '@app/components-legacy/Typography'
import { useAppTranslator, useStores } from '@app/hooks'
import type {
  StoreAuthentication,
  StoreManagementReportingCenter
} from '@app/stores'
import { buildVariants } from '@design-system/libs/buildVariants'
import { filterFalsies } from '@libs/filterFalsies'
import { DataTimeframe, ReportType } from '@server/graphql/typeDefs/types'
import { getTimeZones } from '@vvo/tzdb'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import type { ReportAction } from '../types'
import {
  handleAttackTypesOnSelection,
  handleCheckersOnSelection,
  handleDataTimeframeOnChange,
  handleDirectoriesOnSelection,
  handleProfileIdOnChange,
  handleRecipientsOnAddEmails,
  handleReportTypeOnChange,
  handleTimeZoneOnChange
} from './handlers'
import { getProfiles } from './helpers'
import ReportCommonFormButtons from './ReportCommonFormButtons'
import ReportInputName from './ReportInputName'
import { ReportCommonFormFieldName } from './types'

export interface IReportCommonFormProps {
  version: ReportAction
  onSubmit: (e: React.FormEvent<any>) => void
  storeManagementReportingCenter: StoreManagementReportingCenter
}

const StyledContainerFlex = styled(ContainerFlex)(props => {
  return buildVariants(props)
    .css({
      '[data-test="form/row/profile-id"] > div, [data-test="form/row/data-timeframe"] > div, [data-test="form/row/report-type"] > div, [data-test="form/row/format"] > div, [data-test="form/row/time-zone"] > div':
        {
          alignItems: 'center',
          '& .controlContainer': {
            height: '30px'
          }
        },
      '[data-test="contentinfo/description-recipients"],[data-test="alert/error-recipients"],[data-test="form/textbox/report-input-name"], [data-test="form/button/show-picker-checkers"], [data-test="form/button/show-picker-infrastructures"]':
        {
          width: '300px'
        },
      'div[data-name="TypographyFieldsetTitle"]': { width: '600px' }
    })
    .end()
})

const ReportCommonForm: React.FC<IReportCommonFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Titles',
      'Buttons',
      'Management.System.Configuration.ReportingCenter'
    ]
  })
  const { storeAuthentication } = useStores()

  const timeZonesWithUtc = getTimeZones({ includeUtc: true })

  /**
   * Render widgets type illustrations.
   */
  const renderReportFields = (storeAuthentication: StoreAuthentication) => {
    const {
      storeForm,
      storeInputCheckersExposure,
      storeInputCheckersAttacks,
      storeInfrastructures,
      storeInputEmails
    } = props.storeManagementReportingCenter

    const reportType = storeForm.getFieldValueAsString(
      ReportCommonFormFieldName.reportType
    )

    const fields: IField[] = filterFalsies([
      {
        name: 'typeSection',
        control: (
          <TypographyFieldsetTitle>
            {translate('Report type')}
          </TypographyFieldsetTitle>
        )
      },
      {
        name: ReportCommonFormFieldName.reportType,
        label: translate('Report type'),
        required: true,
        errors: storeForm.field(ReportCommonFormFieldName.reportType).errors,
        control: (
          <FormWrapperSelect
            labelledBy="reportType"
            width="large"
            selectProps={{
              value: storeForm.getFieldValueAsString(
                ReportCommonFormFieldName.reportType
              ),
              onChange: handleReportTypeOnChange(
                props.storeManagementReportingCenter
              )
            }}
            selectOptions={[
              {
                label: translate('Deviances'),
                value: ReportType.Deviances
              },
              {
                label: translate('Attacks'),
                value: ReportType.Attacks
              }
            ]}
          />
        )
      },
      reportType === ReportType.Deviances && {
        name: ReportCommonFormFieldName.checkerIds,
        label: translate('Indicators'),
        required: true,
        errors: storeForm.field(ReportCommonFormFieldName.checkerIds).errors,
        control: (
          <InputCheckers
            storeInputGenericCheckers={storeInputCheckersExposure}
            onValidate={handleCheckersOnSelection(
              props.storeManagementReportingCenter
            )}
          />
        )
      },
      reportType === ReportType.Attacks && {
        name: ReportCommonFormFieldName.attackTypeIds,
        label: translate('Indicators'),
        required: true,
        errors: storeForm.field(ReportCommonFormFieldName.attackTypeIds).errors,
        control: (
          <InputCheckers
            storeInputGenericCheckers={storeInputCheckersAttacks}
            onValidate={handleAttackTypesOnSelection(
              props.storeManagementReportingCenter
            )}
          />
        )
      },
      {
        name: ReportCommonFormFieldName.directoryIds,
        label: translate('Domains'),
        required: true,
        errors: storeForm.field(ReportCommonFormFieldName.directoryIds).errors,
        control: (
          <InputInfrastructures
            storeInfrastructures={storeInfrastructures}
            onValidate={handleDirectoriesOnSelection(
              props.storeManagementReportingCenter
            )}
          />
        )
      },
      {
        name: ReportCommonFormFieldName.profileId,
        label: translate('Profile'),
        required: true,
        errors: storeForm.field(ReportCommonFormFieldName.profileId).errors,
        control: (
          <FormWrapperSelect
            width="large"
            labelledBy={ReportCommonFormFieldName.profileId}
            selectProps={{
              value: storeForm.getFieldValueAsString(
                ReportCommonFormFieldName.profileId
              ),
              onChange: handleProfileIdOnChange(
                props.storeManagementReportingCenter
              )
            }}
            selectOptions={getProfiles(storeAuthentication)}
          />
        )
      },
      {
        name: 'nameSection',
        control: (
          <TypographyFieldsetTitle>{translate('Name')}</TypographyFieldsetTitle>
        )
      },
      {
        name: 'reportName',
        label: translate('Report name'),
        required: true,
        errors: storeForm.field(ReportCommonFormFieldName.reportName).errors,
        control: <ReportInputName storeForm={storeForm} />
      },
      {
        name: 'generationParametersSection',
        control: (
          <TypographyFieldsetTitle>
            {translate('Report generation parameters')}
          </TypographyFieldsetTitle>
        )
      },
      {
        name: ReportCommonFormFieldName.dataTimeframe,
        label: translate('Data timeframe'),
        required: true,
        errors: storeForm.field(ReportCommonFormFieldName.reportType).errors,
        control: (
          <FormWrapperSelect
            labelledBy="dataTimeframe"
            width="large"
            selectProps={{
              placeholder: translate('Select a timeframe'),
              value: storeForm.getFieldValueAsString(
                ReportCommonFormFieldName.dataTimeframe
              ),
              onChange: handleDataTimeframeOnChange(
                props.storeManagementReportingCenter
              )
            }}
            selectOptions={[
              {
                label: translate('day'),
                value: DataTimeframe.Day
              },
              {
                label: translate('week'),
                value: DataTimeframe.Week
              },
              {
                label: translate('month'),
                value: DataTimeframe.Month
              },
              {
                label: translate('quarter'),
                value: DataTimeframe.Quarter
              }
            ]}
          />
        )
      },
      {
        name: ReportCommonFormFieldName.format,
        label: translate('Format'),
        required: true,
        errors: storeForm.field(ReportCommonFormFieldName.format).errors,
        control: (
          <FormWrapperSelect
            labelledBy="format"
            width="large"
            selectProps={{
              disabled: true,
              placeholder: translate('Select a timeframe'),
              value: storeForm.getFieldValueAsString(
                ReportCommonFormFieldName.format
              ),
              onChange: handleDataTimeframeOnChange(
                props.storeManagementReportingCenter
              )
            }}
            selectOptions={[
              {
                label: 'csv',
                value: 'csv'
              }
            ]}
          />
        )
      },
      {
        name: 'recurrence',
        label: translate('Recurrence'),
        required: true,
        control: (
          <InputCrontab
            storeInputCrontab={
              props.storeManagementReportingCenter.storeInputCrontab
            }
          />
        )
      },
      {
        name: ReportCommonFormFieldName.timeZone,
        label: translate('Time zone'),
        required: true,
        errors: storeForm.field(ReportCommonFormFieldName.timeZone).errors,
        control: (
          <FormWrapperSelect
            labelledBy="format"
            width="large"
            selectProps={{
              placeholder: translate('Select a time zone'),
              value: storeForm.getFieldValueAsString(
                ReportCommonFormFieldName.timeZone
              ),
              showSearch: true,
              filterOption: (input, option) =>
                (option?.label ? String(option.label) : '')
                  .toLowerCase()
                  .includes(input.toLowerCase().replace(' ', '_')),
              onChange: handleTimeZoneOnChange(
                props.storeManagementReportingCenter
              )
            }}
            selectOptions={timeZonesWithUtc
              .map(timeZone => ({
                label: `${timeZone.name} (${timeZone.abbreviation})`,
                value: timeZone.name
              }))
              .sort((option1, option2) =>
                option1.label.localeCompare(option2.label)
              )}
          />
        )
      },
      {
        name: 'recipientsSection',
        control: (
          <TypographyFieldsetTitle>
            {translate('Recipients')}
          </TypographyFieldsetTitle>
        )
      },
      {
        name: ReportCommonFormFieldName.recipients,
        required: true,
        errors: storeForm.field(ReportCommonFormFieldName.recipients).errors,
        description: translate('Add emails description'),
        label: translate('Emails'),
        control: (
          <InputEmails
            storeInputEmails={storeInputEmails}
            onValidate={handleRecipientsOnAddEmails(
              props.storeManagementReportingCenter
            )}
          />
        )
      }
    ])

    return (
      <ContainerContent>
        <ContainerForm fields={fields} variant={ContainerFormVariant.natural} />
      </ContainerContent>
    )
  }

  return (
    <FormWrapper
      name="widgetConfiguration"
      className="waiting-for-alt"
      onSubmit={props.onSubmit}
    >
      <StyledContainerFlex
        name="ReportCommonForm"
        direction="column"
        items={[renderReportFields(storeAuthentication)]}
      />

      <Portal name={PlaceHolderName.bladeFooter}>
        <ReportCommonFormButtons version={props.version} />
      </Portal>
    </FormWrapper>
  )
}

export default observer(ReportCommonForm)
