import ButtonCopyToClipboard from '@app/components/Button/ButtonCopyToClipboard'
import { ContainerFlex } from '@app/components/Container'
import IconRecast from '@app/components/Icon/IconRecast'
import { FormWrapperInput } from '@app/components-legacy/Form/Wrappers'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import TooltipInfo from '@app/components-legacy/Tooltip/TooltipInfo'
import { useAppTranslator, useStores } from '@app/hooks'
import { handleReportAccessTokenRefreshOnClick } from '@app/pages/Management/SystemPage/ConfigurationPage/ConfigurationReportingCenterPage/ConfigurationReportsList/handlers'
import { createReport } from '@libs/rbac/permissions'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

const StyledFormWrapperInput = styled(FormWrapperInput)`
  width: 385px;
`

export interface IConfigurationReportsReportAccessTokenProps {}

function ConfigurationReportsReportAccessToken(
  props: IConfigurationReportsReportAccessTokenProps
) {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.ReportingCenter']
  })

  const { storeManagementReportingCenter, storeRbac } = useStores()

  const canCreateReport = storeRbac.isUserGrantedTo(createReport())

  return (
    <ContainerFlex
      name="ConfigurationReportsReportAccessToken"
      direction="column"
      flexGap="small"
      items={[
        <label htmlFor="reportAccessTokenInput">
          {translate('Reports access key')}
        </label>,
        <ContainerFlex
          name="ConfigurationReportsReportAccessTokenInput"
          flexGap="small"
          alignItems="center"
          items={[
            <StyledFormWrapperInput
              labelledBy="reportAccessToken"
              inputProps={{
                id: 'reportAccessTokenInput',
                readOnly: true,
                value:
                  storeManagementReportingCenter.reportAccessToken
                    ?.reportAccessToken ?? '',
                suffix: (
                  <ContainerFlex
                    name="ConfigurationReportsReportAccessTokenInputButtons"
                    items={[
                      <ButtonCopyToClipboard
                        variant={ButtonVariant.transparent}
                        size={ButtonSize.small}
                        textToCopy={
                          storeManagementReportingCenter.reportAccessToken
                            ?.reportAccessToken
                        }
                      />,
                      <Tooltip title={translate('Generate a new access key')}>
                        <FormWrapperButton
                          labelledBy="refreshToken"
                          variant={ButtonVariant.transparent}
                          size={ButtonSize.small}
                          icon={IconRecast}
                          customDisabled={!canCreateReport}
                          buttonProps={{
                            'aria-label': translate(
                              'Generate a new access key'
                            ),
                            onClick: handleReportAccessTokenRefreshOnClick(
                              storeManagementReportingCenter
                            )
                          }}
                        />
                      </Tooltip>
                    ]}
                  />
                )
              }}
            />,
            <TooltipInfo
              title={translate(
                'This access key is required to access the report'
              )}
            />
          ]}
        />
      ]}
    />
  )
}

export default observer(ConfigurationReportsReportAccessToken)
