import type { InstanceName } from '@libs/Environment/types'
import type { Layout } from 'react-grid-layout'

export interface IDashboardDrawerParameters {
  instanceName: InstanceName
  dashboardId: number
}

export enum DashboardWidgetSerieInterval {
  hour = '1h',
  day = '1d',
  week = '1w',
  bimensual = '2w',
  month = '1m'
}

export interface IWidgetBounds {
  width: number
  height: number
}

export interface IPartialLayout extends Partial<Layout> {
  posX: number
  posY: number
  minW: number
  minH: number
  maxW: number
  maxH: number
}

export interface IWidgetCoordinates {
  posX: number
  posY: number
}

export enum UserFormFieldName {
  name = 'name',
  instanceName = 'instanceName'
}

export interface IWidgetAttributes {
  codeNames: string[]
  widgetName: string
}
