import { ContainerFlex } from '@app/components/Container'
import IconIdentityProvider from '@app/components/Icon/IconIdentityProvider'
import { useAppTranslator, useStores } from '@app/hooks'
import type { Tenant } from '@libs/openapi/service-identity-core'
import { ProviderType } from '@libs/openapi/service-identity-core'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export interface ITenantsProps {
  providerType: ProviderType
  tenants: Set<Tenant>
}

function Tenants(props: ITenantsProps) {
  const translate = useAppTranslator({
    namespaces: ['IdentityExplorer.Providers']
  })

  const { storeInfrastructures } = useStores()

  function getTenantName(tenant: Tenant): string {
    if (tenant.providerType !== ProviderType.ActiveDirectory) {
      return tenant.name
    }

    // FIXME uuid are uppercased when coming back from Core
    const upperCaseId = tenant.id.toLocaleUpperCase()

    return (
      storeInfrastructures.directoriesByUuid
        .get(upperCaseId)
        ?.getPropertyAsString('name') ?? ''
    )
  }

  function renderTitle() {
    return (
      <ContainerFlex
        direction="column"
        name="TenantsTitle"
        items={[
          <strong>{translate(props.providerType)}</strong>,

          <div>
            {Array.from(props.tenants.values()).map(tenant => (
              <div key={tenant.id}>{getTenantName(tenant)}</div>
            ))}
          </div>
        ]}
      ></ContainerFlex>
    )
  }

  return (
    <Tooltip title={renderTitle()}>
      <span>
        <IconIdentityProvider providerType={props.providerType} size={20} />
      </span>
    </Tooltip>
  )
}

export default observer(Tenants)
