import { LabelSliced } from '@app/components-legacy/Label'
import { useStores } from '@app/hooks'
import { buildVariants } from '@design-system/libs/buildVariants'
import { Language } from '@server/graphql/typeDefs/types'
import cronstrue from 'cronstrue/i18n'
import React from 'react'
import styled from 'styled-components'
import { CronstrueLocale } from './types'

interface IHumanReadableCronProps {
  crontab: string
  type: 'tableLabel' | 'cronSummary'
  maxWidth?: number
}

const Div = styled.div<Pick<IHumanReadableCronProps, 'type'>>(props => {
  return buildVariants(props)
    .variant('type', props.type, {
      tableLabel: {},

      cronSummary: {
        width: '300px',
        marginTop: props.theme.tokens['margin/default'],
        padding: props.theme.tokens['padding/default'],
        backgroundColor: props.theme.tokens['button/background/select'],
        borderRadius: props.theme.tokens['borderRadius/small']
      }
    })
    .end()
})

const getLanguageForCronstrue = (language: Language) => {
  switch (language) {
    case Language.DeDe:
      return CronstrueLocale.De

    case Language.EnUs:
      return CronstrueLocale.En

    case Language.FrFr:
      return CronstrueLocale.Fr

    case Language.JaJp:
      return CronstrueLocale.Ja

    case Language.KoKr:
      return CronstrueLocale.Ko

    case Language.ZhCn:
      return CronstrueLocale.ZhCn

    case Language.ZhTw:
      return CronstrueLocale.ZhTw

    default:
      return CronstrueLocale.En
  }
}

function HumanReadableCron(props: IHumanReadableCronProps) {
  const { storeRoot } = useStores()

  const language = getLanguageForCronstrue(storeRoot.appTranslator.language)

  const value = cronstrue.toString(props.crontab, { locale: language })

  return (
    <Div type={props.type}>
      {props.maxWidth ? (
        <LabelSliced maxWidth={props.maxWidth} value={value} />
      ) : (
        <>{value}</>
      )}
    </Div>
  )
}

export default HumanReadableCron
