import { ContainerFlex } from '@app/components-legacy/Container'
import type { PopoverProps } from 'antd/lib/popover'
import Popover from 'antd/lib/popover'
import * as React from 'react'
import LabelSlicedContainer from './LabelSlicedContainer'
import LabelSlicedContainerPopoverContent from './LabelSlicedContainerPopoverContent'
import type { ILabelSlicedProps } from './types'

/** @deprecated */
const LabelSlicedPopoverContent: React.FC<ILabelSlicedProps> = props => {
  const [isValueSliced, setIsValueSliced] = React.useState(!props.maxWidth)

  React.useEffect(() => {
    setIsValueSliced(!props.maxWidth)
  }, [props.value, props.maxWidth])

  const getPopOverContent = (): React.ReactNode => {
    const { maxWidth, ...labelProps } = props

    return (
      <ContainerFlex
        name="PopOverContent"
        direction="column"
        items={[
          <LabelSlicedContainer
            {...labelProps}
            maxLength={props.popoverMaxLength}
            breakString
          />,
          props.decoration
        ]}
        spaced
      />
    )
  }

  const popoverProps: PopoverProps = {
    content: isValueSliced ? (
      <LabelSlicedContainerPopoverContent>
        {getPopOverContent()}
      </LabelSlicedContainerPopoverContent>
    ) : null,
    open: isValueSliced ? undefined : false,
    ...props.popoverProps
  }

  return (
    <Popover {...popoverProps}>
      <span>
        <LabelSlicedContainer
          {...props}
          onEllipsisActiveChange={setIsValueSliced}
        />
      </span>
    </Popover>
  )
}

export default LabelSlicedPopoverContent
