import type { Maybe } from '@@types/helpers'
import { IconRightArrow } from '@app/components/Icon'
import { Label, LabelSliced } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { LabelDate, LabelDirectoriesTree } from '@app/components-legacy/Label'
import LabelDirectoryTag from '@app/components-legacy/Label/LabelDirectoryTag'
import { IDENTIFIER_COLUMN_NAME } from '@app/components-legacy/TableNew/consts'
import { Table } from '@app/components-legacy/TableNew/Table'
import type {
  TableColumnsProperties,
  TableRowData
} from '@app/components-legacy/TableNew/types'
import { TooltipDate } from '@app/components-legacy/Tooltip'
import type { EntityHealthCheckDomainStatus } from '@app/entities'
import { useAppRouter, useAppTranslator, useStores } from '@app/hooks'
import HealthCheckMainPageNoResult from '@app/pages/HealthCheck/HealthCheckMainPageNoResult'
import { AppRouteName } from '@app/routes'
import type { IFlags } from '@app/stores/helpers/StoreFlags/types'
import { CTAColorV2 } from '@app/styles/consts'
import { isDefined } from '@libs/isDefined'
import type { HealthCheckStatus } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import * as React from 'react'
import { HealthCheckStatusIcon } from '../HealthCheckStatusIcon'
import HealthCheckNameLabel from '../Labels/HealthCheckNameLabel'
import { HealthCheckNameLocale } from '../Labels/types'

export interface ITableDomainStatusHealthCheck {
  name: string
  status: { value: Maybe<HealthCheckStatus>; labelledBy: string }
  lastCheckDate: string
  infrastructure: string
  directory: number
  statusSince: string
  arrow: undefined
}

export interface IHealthCheckDomainStatusTableProps {
  className?: string
  style?: React.CSSProperties
  healthCheckEntities: EntityHealthCheckDomainStatus[]
  flags?: IFlags | IFlags[]
}

const HealthCheckDomainStatusTable: React.FC<
  IHealthCheckDomainStatusTableProps
> = props => {
  const { storeHealthCheck } = useStores()
  const { storeInfrastructures } = storeHealthCheck

  const translate = useAppTranslator({
    namespaces: ['HealthCheck.Domains']
  })

  const appRouter = useAppRouter()

  const columns: TableColumnsProperties<ITableDomainStatusHealthCheck> = {
    name: {
      name: translate('Health check'),
      relativeWidth: 2.5,
      render: name => (
        <HealthCheckNameLabel
          healthCheckName={name}
          maxWidthAuto
          locale={HealthCheckNameLocale.name}
        />
      )
    },
    status: {
      name: translate('Status'),
      relativeWidth: 0.7,
      render: status => (
        <HealthCheckStatusIcon
          labelledBy={status.labelledBy}
          status={status.value}
          withTooltip
        />
      )
    },
    lastCheckDate: {
      name: translate('Last check'),
      render: (lastCheckDate, availableWidth) => (
        <Label variant={LabelVariant.p}>
          <LabelDate date={lastCheckDate} maxWidth={availableWidth} />
        </Label>
      )
    },
    infrastructure: {
      name: translate('Forest')
    },
    directory: {
      name: translate('Domain'),
      render: (directoryId, availableWidth) => {
        const directory =
          directoryId && storeInfrastructures.getDirectoryFromId(directoryId)

        return (
          directory &&
          isDefined(directory.id) &&
          directory.name && (
            <LabelDirectoryTag
              color={directory.color}
              label={directory.name}
              tooltipLabel={
                <LabelDirectoriesTree
                  storeInfrastructures={storeInfrastructures}
                  directoryIds={[directory.id]}
                />
              }
              maxWidth={availableWidth}
            />
          )
        )
      }
    },
    statusSince: {
      name: translate('Status since'),
      render: (statusSince, availableWidth) => (
        <TooltipDate date={statusSince}>
          <span>
            <Label variant={LabelVariant.p}>
              <LabelSliced
                value={moment(statusSince).fromNow(true)}
                maxWidth={availableWidth}
                popoverType="none"
              />
            </Label>
          </span>
        </TooltipDate>
      )
    },
    arrow: {
      fixedWidth: 40,
      justifyContent: 'center',
      render: () => <IconRightArrow size={16} color={CTAColorV2.primary} />
    }
  }

  const data: Array<TableRowData<ITableDomainStatusHealthCheck>> =
    props.healthCheckEntities.map(healthCheckEntity => ({
      [IDENTIFIER_COLUMN_NAME]: `${healthCheckEntity.getPropertyAsString(
        'healthCheckName'
      )}-${healthCheckEntity.getPropertyAsNumber('directoryId')}`,
      name: healthCheckEntity.getPropertyAsString('healthCheckName'),
      status: {
        value: healthCheckEntity.status,
        labelledBy: `${healthCheckEntity.getPropertyAsString(
          'healthCheckName'
        )}-${healthCheckEntity.getPropertyAsNumber('directoryId')}`
      },
      lastCheckDate: healthCheckEntity.getPropertyAsString('lastCheckDate'),
      infrastructure: healthCheckEntity.directoryId
        ? storeInfrastructures.getInfrastructureFromDirectoryId(
            healthCheckEntity.directoryId
          )?.name || ''
        : '',
      directory: healthCheckEntity.getPropertyAsNumber('directoryId'),
      statusSince: healthCheckEntity.getPropertyAsString('statusSince'),
      arrow: undefined
    }))

  return (
    <Table
      labelledBy="healthCheck"
      className={props.className}
      style={props.style}
      columns={columns}
      data={data}
      rowLinkTo={rowData => {
        return appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.HealthCheck_DomainStatus_Details_Information,
          parameters: {
            healthCheckName: rowData.name,
            directoryId: rowData.directory
          }
        })
      }}
      flags={props.flags}
      renderNoData={() => <HealthCheckMainPageNoResult />}
      hideHeaderIfNoData
    />
  )
}

export default observer(HealthCheckDomainStatusTable)
