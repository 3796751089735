import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  editApplicationSetting,
  editLockoutPolicy,
  readLockoutPolicy
} from '@libs/rbac/permissions'
import { canAccessToTenableProviderConfiguration } from '../permissions'

export const canSubmitTenableAccountForm = rbacCapabilityCheckAllOf(
  canAccessToTenableProviderConfiguration,
  editApplicationSetting()
)

export const canReadLockoutPolicy =
  rbacCapabilityCheckAllOf(readLockoutPolicy())

export const canEditLockoutPolicy =
  rbacCapabilityCheckAllOf(editLockoutPolicy())
