import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'

interface ITableContentBodyCellProps {
  children?: React.ReactNode
}

const TableContentBodyCell: React.FC<ITableContentBodyCellProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  if (themeIsLegacy) {
    return <>{props.children}</>
  }

  return <Label variant={LabelVariant.p}>{props.children}</Label>
}

export default TableContentBodyCell
