import type { MaybeUndef } from '@@types/helpers'
import {
  ContainerContent,
  ContainerFlex,
  ContainerSimple
} from '@app/components-legacy/Container'
import { LabelAlt } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useAppTranslator, useStores } from '@app/hooks'
import { CardRank } from '@app/pages/IoA/IoABoardPage/IoABoardCards/CardChart/CardRank'
import type StoreSummaryCard from '@app/stores/IoA/StoreBoard/StoreSummaryCard'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import type { AxisDomain } from 'recharts/types/util/types'
import styled from 'styled-components'
import CardIconShield from '../CardAttacksByCriticityChart/CardIconShield'
import CardBarChart from './CardBarChart'
import { handleCardOnLoad } from './handlers'

const AxisLabelY = styled(LabelAlt)`
  margin-left: 15px;
  text-transform: uppercase;
  font-size: 0.8em;
`

const AxisLabelX = styled(LabelAlt)`
  margin-right: 15px;
  text-transform: uppercase;
  font-size: 0.8em;
`

const StyledBarChartContainer = styled(ContainerSimple)`
  position: relative;
`

interface ICardChartAreaLayoutProps {
  storeSummaryCard: StoreSummaryCard
}

const CardAttacksInTimeChart: React.FC<ICardChartAreaLayoutProps> = props => {
  const translate = useAppTranslator({ namespaces: ['IoA.Board'] })

  const { storeRoot, storeIoA } = useStores()

  const { directoryEntity, numberOfAttacksInTime } = props.storeSummaryCard

  if (!directoryEntity) {
    return null
  }

  /**
   * Get a domain between 0 and 1 value to have a nice graph
   * when there is no attack
   */
  const getYDomain = (): MaybeUndef<AxisDomain> => {
    return !props.storeSummaryCard.hasAttacksDetected ? [0, 1] : undefined
  }

  const getContent = () => {
    return (
      <ContainerFlex
        name="ChartContent"
        direction="column"
        justifyContent="space-between"
        items={[
          <ContainerFlex
            name="ChartWithLabel"
            items={[
              <ContainerFlex
                name="Chart"
                direction="column"
                items={[
                  <AxisLabelY variant={LabelAltVariant.Minor}>
                    {translate('Number of events')}
                  </AxisLabelY>,

                  <StyledBarChartContainer>
                    <CardBarChart
                      width={300}
                      height={145}
                      margin={{
                        top: 15,
                        right: 40,
                        left: -15,
                        bottom: 0
                      }}
                      data={numberOfAttacksInTime}
                      language={storeRoot.appTranslator.language}
                      attacksSummaryPeriod={
                        storeIoA.storeBoard.storeTimeline.attacksSummaryPeriod
                      }
                      directoryColor={directoryEntity.getPropertyAsString(
                        'color'
                      )}
                      yDomain={getYDomain()}
                    />

                    {!props.storeSummaryCard.hasAttacksDetected && (
                      <CardIconShield />
                    )}
                  </StyledBarChartContainer>,

                  <ContainerFlex
                    name="TimeLabel"
                    justifyContent="flex-end"
                    items={[
                      <AxisLabelX variant={LabelAltVariant.Minor}>
                        {translate('Time')}
                      </AxisLabelX>
                    ]}
                  />
                ]}
              />
            ]}
          />,

          <CardRank storeSummaryCard={props.storeSummaryCard} />
        ]}
        fullHeight
      />
    )
  }

  return (
    <ContainerContent
      name="CardAttacksInTimeChart"
      onLoad={handleCardOnLoad(props.storeSummaryCard)}
      flags={props.storeSummaryCard.storeFlagsFetchAttackStats.flags}
      spinner={<SpinnerInline />}
    >
      {getContent()}
    </ContainerContent>
  )
}

export default observer(CardAttacksInTimeChart)
