import { AppRouteName } from '@app/routes'
import type { StoreAuthentication } from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'
import { sanitize } from '@libs/sanitize'
import type { SelectValue } from 'antd/lib/select'

/**
 * When selecting a profile, reload the app with the new profile in the URL.
 */
export const onProfileSelection =
  (appRouter: AppRouterClient, storeAuthentication: StoreAuthentication) =>
  (profileName: SelectValue): void => {
    const { storeMessages } = storeAuthentication.storeRoot.stores

    const profileNameStr = String(profileName)

    if (!storeAuthentication.setCurrentProfile(profileNameStr)) {
      storeMessages.error('An error has occurred when selecting this profile', {
        labelledBy: 'errorSelectingProfile'
      })
      return
    }

    const sanitizedProfileName = sanitize(profileNameStr)

    // reload the app with the selected profile
    appRouter.hardRedirect({
      routeName: AppRouteName.HomePage,
      parameters: {
        profileName: sanitizedProfileName
      }
    })
  }
