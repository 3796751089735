import { ErrorMessage } from '@app/components/Error'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { grant } from '@libs/rbac/permissions'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import ProfilesCheckerConfiguration from '../ProfilesCheckerConfiguration'
import ProfileEditFooter from './ProfileEditFooter'
import ProfileEditFormAttackTypesSelection from './ProfileEditFormAttackTypesSelection'
import ProfileEditFormCheckersSelection from './ProfileEditFormCheckersSelection'
import ProfileEditFormMainFields from './ProfileEditFormMainFields'

interface IProfileEditFormProps {}

const ProfileEditForm: React.FC<IProfileEditFormProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['Titles', 'Management.Accounts.Profiles.Edition']
  })

  const appRouter = useAppRouter()

  const { storeManagementProfiles } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_Accounts_Profiles_Edit,
    parameters: {
      profileId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  const profileFound = storeManagementProfiles.profiles.get(
    parameters.profileId
  )

  if (!profileFound) {
    return (
      <ContainerContent title={translate('Profile configuration')}>
        <ErrorMessage message={translate(`This profile doesn't exist`)} />
      </ContainerContent>
    )
  }

  const isExposureSelected =
    storeManagementProfiles.storeMenu.selectedMenuKey === CheckerType.Exposure

  return (
    <>
      <ProfileEditFormMainFields />

      {isExposureSelected ? (
        <ProfileEditFormCheckersSelection />
      ) : (
        <ProfileEditFormAttackTypesSelection />
      )}

      <RbacRoutes
        routes={[
          {
            routeDefinition: {
              routeName: AppRouteName.Management_Accounts_Profiles_Edit_Checker,
              parameters: {
                profileId: Number(),
                checkerId: Number(),
                checkerCodename: String()
              }
            },
            component: ProfilesCheckerConfiguration,
            rbacPermissionsCheck: () => grant()
          }
        ]}
      />

      <Portal name={PlaceHolderName.bladeFooter}>
        {!storeManagementProfiles.isCurrentProfileReadOnly && (
          <ProfileEditFooter />
        )}
      </Portal>
    </>
  )
}

export default observer(ProfileEditForm)
