import { ErrorGenericMessage } from '@app/components/Error'
import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  ContainerContent,
  ContainerFooter,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import {
  Drawer,
  DrawerCancelButton,
  DrawerContext,
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { LabelConfirm } from '@app/components-legacy/Label'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal, PortalPlaceHolder } from '@app/components-legacy/Portal'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canDeleteRole } from '../../permissions'
import { onDeleteRoleSubmit } from './handlers'

interface IDrawerRoleDeleteProps {}

const DrawerRoleDelete: React.FC<IDrawerRoleDeleteProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.Accounts.Roles']
  })

  const { storeManagementRbacRoles } = useStores()

  const roleDataRow =
    storeManagementRbacRoles.storeDrawerDeleteRole.getDataValue(
      'rbacRoleDataRow'
    )

  function renderContent() {
    if (!roleDataRow) {
      return <ErrorGenericMessage />
    }

    const isLoading =
      storeManagementRbacRoles.storeDeletionRbacRoleFlags.flags.isLoading

    return (
      <FormWrapper
        name="roleDeletion"
        onSubmit={onDeleteRoleSubmit(storeManagementRbacRoles, roleDataRow)}
      >
        <ContainerContent
          flags={storeManagementRbacRoles.storeDeletionRbacRoleFlags.flags}
          spinner={<SpinnerInline />}
        >
          <ContainerHTMLContent>
            {translate('You are about to delete the role X', {
              transformMarkdown: true,
              interpolations: {
                roleName: roleDataRow.name
              }
            })}
          </ContainerHTMLContent>

          <LabelConfirm />
        </ContainerContent>

        <Portal name={PlaceHolderName.drawerFooter}>
          <ContainerFooter>
            <DrawerCancelButton />

            <FormWrapperButtonSubmit loading={isLoading} danger>
              {translate('Delete')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </FormWrapper>
    )
  }

  const roleId = roleDataRow && roleDataRow.id

  return (
    <DrawerContext.Provider
      value={{ storeDrawer: storeManagementRbacRoles.storeDrawerDeleteRole }}
    >
      <Drawer>
        <DrawerHorizontalContainer>
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: <DrawerTitle>{translate('Delete a role')}</DrawerTitle>,
              content: renderContent(),
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={canDeleteRole(roleId)}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerRoleDelete)
