import type { Maybe } from '@@types/helpers'
import { BulletStatusKO, BulletStatusOK } from '@app/components-legacy/Bullet'
import BulletStatusWarning from '@app/components-legacy/Bullet/BulletStatusWarning'
import { ContainerFlex, ContainerIcon } from '@app/components-legacy/Container'
import {
  IconPlusOutlined,
  IconSyncOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { useAppRouter, useAppTranslator } from '@app/hooks'
import { consts } from '@app/styles'
import type { TranslateFn } from '@libs/i18n'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { HoneyAccountStatus } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'
import { handleCreateHoneyAccountDirectoryIconOnClick } from './handlers'

interface IDirectoryIconHoneyAccountStatus {
  labelledBy: string
  // Required when the Honey Account will be in GA
  status: HoneyAccountStatus
  isConfigurable: boolean
  directoryId: number
}

const DirectoryIconHoneyAccountStatus: React.FC<
  IDirectoryIconHoneyAccountStatus
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.System.Directories']
  })

  const appRouter = useAppRouter()

  if (props.status === HoneyAccountStatus.None) {
    return (
      <ContainerFlex
        name={props.labelledBy}
        direction="column"
        alignItems="center"
        items={[
          <StyledIconPlusOutlined isActive={props.isConfigurable}>
            <ContainerIcon
              labelledBy={props.labelledBy}
              iconComponent={IconPlusOutlined}
              iconProps={{
                className: 'icon-plus-outlined',
                type: props.isConfigurable ? 'create' : undefined,
                onClick: props.isConfigurable
                  ? handleCreateHoneyAccountDirectoryIconOnClick(appRouter)(
                      props.directoryId
                    )
                  : undefined
              }}
              tooltipProps={
                !props.isConfigurable
                  ? {
                      title: translate('Honey Account Not Yet Available')
                    }
                  : undefined
              }
            />
          </StyledIconPlusOutlined>
        ]}
        wrapItems
      />
    )
  }

  const tooltipProps = getHoneyAccountStatusTooltip(props.status, translate)

  return (
    <ContainerFlex
      name={props.labelledBy}
      direction="column"
      alignItems="center"
      items={[
        <ContainerIcon
          labelledBy={props.labelledBy}
          tooltipProps={tooltipProps}
          iconComponent={getIcon(props)}
          iconProps={{
            spin: props.status === HoneyAccountStatus.Pending
          }}
        />
      ]}
      wrapItems
    />
  )
}

export default DirectoryIconHoneyAccountStatus

const StyledIconPlusOutlined = styled<any>('div')`
  .icon-plus-outlined {
    display: block;
    background-color: ${props =>
      props.isActive ? consts.colorBlueGray020 : consts.colorBlueGray030};
    ${props => (!props.isActive ? `color: ${consts.colorBlueGray015}` : '')};
    width: 73px;
    padding: 5px;
    font-size: 8px;
    border-radius: 3px;

    ${props => {
      if (!props.isActive) {
        return
      }

      return `
        &:hover {
          color: ${consts.colorWhite};
          background-color: ${consts.colorBlue010};
        }
      `
    }}
  }
`

function getIcon(props: IDirectoryIconHoneyAccountStatus) {
  switch (props.status) {
    case HoneyAccountStatus.None:
    case HoneyAccountStatus.Pending:
      return IconSyncOutlined

    case HoneyAccountStatus.Configured:
      return BulletStatusOK

    case HoneyAccountStatus.Missing:
      return BulletStatusKO

    case HoneyAccountStatus.Misconfigured:
      return BulletStatusWarning

    default:
      assertUnreachableCase(props.status)
  }
}

function getHoneyAccountStatusTooltip(
  status: HoneyAccountStatus,
  translate: TranslateFn
) {
  const honeyAccountTooltip = getHoneyAccountStatusLocale(status)

  if (!honeyAccountTooltip) {
    return
  }

  return {
    title: translate(honeyAccountTooltip)
  }
}

function getHoneyAccountStatusLocale(
  status: HoneyAccountStatus
): Maybe<string> {
  switch (status) {
    case HoneyAccountStatus.Configured:
      return 'Honey account found and configured'

    case HoneyAccountStatus.Missing:
      return 'No user found for this domain'

    case HoneyAccountStatus.Misconfigured:
      return 'Honey account not configured'

    default:
      // No tooltip displayed for Pending and None enum
      return null
  }
}
