import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import * as React from 'react'

interface IIconOpenLinkProps extends IIconProps {}

const IconOpenLink: React.FC<IIconOpenLinkProps> = props => {
  const fill = props.color ?? consts.colorWhite
  const size = props.size ?? 13

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      x={props.x}
      y={props.y}
      viewBox="0 0 15 15"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1263.000000, -452.000000)">
          <g transform="translate(1221.000000, 450.000000)">
            <g transform="translate(22.000000, 0.000000)">
              <g transform="translate(27.121101, 9.121101) rotate(-179.000000) translate(-27.121101, -9.121101) translate(20.121101, 2.121101)">
                <rect x="0" y="0" width="14" height="14"></rect>
                <g
                  transform="translate(7.280304, 6.840960) rotate(179.000000) translate(-7.280304, -6.840960) translate(2.280281, 1.840960)"
                  fill={fill}
                  fillRule="nonzero"
                >
                  <path d="M5.46904271,-1.8189894e-12 L5.46904271,0.908843971 L0.908814163,0.908843971 L0.908814163,9.09106236 L9.09118584,9.09106236 L9.09118584,4.53091924 L10.0000468,4.53091924 L10.0000468,9.54557801 C9.98943612,9.78953186 9.79011802,9.98939229 9.54561634,10 L0.454430498,10 C0.210472087,9.98938925 0.0106079056,9.79007512 0,9.54557801 L0,0.454562485 C0.0106109498,0.210608644 0.209928812,0.0107482063 0.454430498,0.000140499427 L5.46904271,-1.8189894e-12 Z M6.50767141,0.454421985 C6.50767141,0.203419756 6.71113947,-1.8189894e-12 6.96213469,-1.8189894e-12 L9.54549925,-1.8189894e-12 C9.55876288,0.000548814183 9.57202604,0.00118911691 9.58510678,0.00164646597 C9.81387926,0.0317414436 9.98977613,0.222731684 9.99993912,0.454335625 L9.99993912,3.03765179 C9.99993912,3.15811834 9.95218949,3.27364399 9.8668462,3.3589974 C9.78168556,3.44415644 9.6661554,3.49208782 9.54549457,3.49208782 C9.42502576,3.49208782 9.30949794,3.44415644 9.22414294,3.3589974 C9.1389823,3.2736557 9.09105002,3.1581277 9.09105002,3.03765179 L9.09105002,0.90875761 L6.96211596,0.90875761 C6.84164715,0.90875761 6.72611933,0.86082623 6.64076433,0.775667185 C6.55560369,0.69032549 6.50767141,0.574797493 6.50767141,0.454321575 L6.50767141,0.454421985 Z" />
                  <path d="M9.72862687,0.913948783 C9.72862687,0.913948783 6.38511615,4.25713269 5.32104721,5.32118169 C5.14368209,5.49854349 4.85626639,5.49854349 4.67890596,5.32118169 C4.67881449,5.32118169 4.67881449,5.32109023 4.67863156,5.32109023 C4.50117511,5.14363711 4.50117511,4.8558732 4.67863156,4.67842243 C5.74291125,3.61416267 9.08594703,0.271189515 9.08594703,0.271189515 L9.72862687,0.913857318 L9.72862687,0.913948783 Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconOpenLink
