import {
  ContainerContent,
  ContainerFooter,
  ContainerForm
} from '@app/components/Container'
import type { IField } from '@app/components/Container/ContainerForm/types'
import { InputPassword } from '@app/components/Input'
import { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import {
  handleResetPasswordOnCancel,
  handleResetPasswordOnLoad,
  handleResetPasswordOnSubmit
} from './handlers'

const StyledContainerFooter = styled(ContainerFooter)`
  padding: 0 50px 30px;
`

export interface IFirstLoginPasswordFormProps {
  className?: string
}

const FirstLoginPasswordForm: React.FC<
  IFirstLoginPasswordFormProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Login.Labels', 'Login.FirstLogin', 'Components.Wizard']
  })

  const { storeAuthentication } = useStores()

  const { storeFormFirstLoginPassword } = storeAuthentication

  const fields: IField[] = [
    {
      name: 'newPassword',
      required: true,
      errors: storeFormFirstLoginPassword.field('newPassword').errors,
      hideError: true,
      control: (
        <InputPassword
          name="newPassword"
          placeholder={translate('Enter your password')}
          storeForm={storeFormFirstLoginPassword}
          value={storeFormFirstLoginPassword.getFieldValueAsString(
            'newPassword'
          )}
        />
      )
    },
    {
      name: 'newPasswordConfirmation',
      required: true,
      errors: storeFormFirstLoginPassword.field('newPasswordConfirmation')
        .errors,
      hideError: true,
      control: (
        <InputPassword
          name="newPasswordConfirmation"
          placeholder={translate('Confirm your password')}
          storeForm={storeFormFirstLoginPassword}
          value={storeFormFirstLoginPassword.getFieldValueAsString(
            'newPasswordConfirmation'
          )}
        />
      )
    }
  ]

  return (
    <ContainerContent onLoad={handleResetPasswordOnLoad(storeAuthentication)}>
      <FormWrapper
        className={props.className}
        name="passwordChange"
        onSubmit={handleResetPasswordOnSubmit(storeAuthentication)}
      >
        <ContainerForm fields={fields} variant={ContainerFormVariant.fill} />

        <Portal name={PlaceHolderName.firstLoginPasswordChangeFooter}>
          <StyledContainerFooter>
            <FormWrapperButton
              labelledBy="cancelFirstLoginForm"
              variant={ButtonVariant.secondary}
              size={ButtonSize.large}
              buttonProps={{
                onClick: handleResetPasswordOnCancel(storeAuthentication)
              }}
            >
              {translate('Cancel')}
            </FormWrapperButton>

            <FormWrapperButtonSubmit
              type="primary"
              htmlType="submit"
              loading={
                storeAuthentication.storeFlagsFirstLoginPassword.isLoading
              }
            >
              {translate('Continue')}
            </FormWrapperButtonSubmit>
          </StyledContainerFooter>
        </Portal>
      </FormWrapper>
    </ContainerContent>
  )
}

export default observer(FirstLoginPasswordForm)
