import type { Maybe } from '@@types/helpers'
import type { StoreLockoutPolicy } from '@app/stores'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { LockoutPolicyFormFieldName } from '@app/stores/Management/StoreLockoutPolicy/types'
import { isDefined } from '@libs/isDefined'
import { LOCKOUT_POLICY_DURATION_MIN } from './consts'

/**
 * Initialize the form.
 */
export const handleLockoutPolicyOnLoad =
  (storeLockoutPolicy: StoreLockoutPolicy) => () => {
    const { storeFormLockoutPolicyConfiguration } = storeLockoutPolicy

    storeLockoutPolicy.fetchLockoutPolicy().then(() => {
      if (!storeLockoutPolicy.lockoutPolicy) {
        return
      }

      // initialize the lockout policity form
      storeFormLockoutPolicyConfiguration
        .setDefaultFieldsValues([
          {
            key: LockoutPolicyFormFieldName.enabled,
            value:
              storeLockoutPolicy.lockoutPolicy.getPropertyAsBoolean('enabled')
          },
          {
            key: LockoutPolicyFormFieldName.lockoutDuration,
            value:
              storeLockoutPolicy.lockoutPolicy.getPropertyAsNumber(
                'lockoutDuration'
              ) || LOCKOUT_POLICY_DURATION_MIN
          },
          {
            key: LockoutPolicyFormFieldName.lockoutDurationInfinite,
            value:
              storeLockoutPolicy.lockoutPolicy.getPropertyAsNumber(
                'lockoutDuration'
              ) === 0
          },
          {
            key: LockoutPolicyFormFieldName.failedAttemptThreshold,
            value: storeLockoutPolicy.lockoutPolicy.getPropertyAsNumber(
              'failedAttemptThreshold'
            )
          },
          {
            key: LockoutPolicyFormFieldName.failedAttemptPeriod,
            value: storeLockoutPolicy.lockoutPolicy.getPropertyAsNumber(
              'failedAttemptPeriod'
            )
          },
          {
            key: LockoutPolicyFormFieldName.failedAttemptPeriodInfinite,
            value:
              storeLockoutPolicy.lockoutPolicy.getPropertyAsNumber(
                'failedAttemptPeriod'
              ) === 0
          }
        ])
        .reset()
    })
  }

/**
 * Reset the form when leaving the page.
 */
export const handleLockoutPolicyOnUnLoad =
  (storeLockoutPolicy: StoreLockoutPolicy) => () => {
    storeLockoutPolicy.storeFormLockoutPolicyConfiguration.hardReset()
  }

/**
 * Handle Lockout Policy enabled status change.
 */
export const handleLockoutPolicyEnabledOnChange =
  (storeFormLockoutPolicyConfiguration: StoreForm<any>) =>
  (status: boolean) => {
    storeFormLockoutPolicyConfiguration.setFieldValue(
      LockoutPolicyFormFieldName.enabled,
      status
    )
  }

/**
 * Handle Lockout Policy fields changes.
 */
export const handleLockoutPolicyFieldsOnChange =
  (storeFormLockoutPolicyConfiguration: StoreForm<any>) =>
  (
    fieldName: LockoutPolicyFormFieldName,
    fieldInfiniteName?: LockoutPolicyFormFieldName
  ) =>
  (value: Maybe<number>) => {
    if (fieldInfiniteName && !isDefined(value)) {
      const isInfinite =
        storeFormLockoutPolicyConfiguration.getFieldValueAsBoolean(
          fieldInfiniteName
        )

      storeFormLockoutPolicyConfiguration.setFieldValue(
        fieldInfiniteName,
        // toggle infinite checkbox
        !isInfinite
      )

      // return now to keep the original value in `fieldName` to retrieve it
      // if removing the infinite value
      return
    }

    // don't update the field if the value filled is not a number
    if (isDefined(value)) {
      storeFormLockoutPolicyConfiguration.setFieldValue(fieldName, value)
    }
  }
