import type { TranslateFn } from '@libs/i18n'
import type { ScanInfo } from '@libs/openapi/service-identity-core'
import { ScanInfoScanStatusEnum } from '@libs/openapi/service-identity-core'

const errorMessages = [
  'AADSTS7000215: Invalid client secret provided',
  'Error code: Authorization_RequestDenied',
  'Provided AAD credential is incomplete'
]

export const getDescription = (
  scanInfo: ScanInfo,
  translateFn: TranslateFn
) => {
  const description = scanInfo.description
  // check if description contains known error code
  if (
    ScanInfoScanStatusEnum.EndedInError === scanInfo.scanStatus &&
    containsAnyErrorMessage(description, errorMessages)
  ) {
    // prepend with permissions issue message
    return `${translateFn(
      'CustomDescriptionPermissionsIssue'
    )} <br> ${translateFn(description)}`
  }

  return translateFn(description)
}

export const containsAnyErrorMessage = (
  description: string,
  errorMessages: string[]
) => {
  return errorMessages.some(errorMessage => description.includes(errorMessage))
}
