import type { Maybe } from '@@types/helpers'
import type { IEntityListable } from '@app/entities/types'
import { isDefined } from '@libs/isDefined'

/**
 * As listable entities can implement either `asDataRow` or `asCustomDataRow`
 * to return data rows, this helper is useful to avoid to make the check.
 */
export function listDataRow<D>(listableEntity: IEntityListable<D>): Maybe<D> {
  if (listableEntity.asCustomDataRow) {
    return listableEntity.asCustomDataRow()
  }

  if (listableEntity.asDataRow) {
    return listableEntity.asDataRow()
  }

  return null
}

/**
 * Same, but for entities arrays.
 */
export function listDataRows<D>(
  listableEntities: Array<IEntityListable<D>>
): D[] {
  return listableEntities.map(listDataRow).filter(isDefined)
}
