import { FormWrapperInput } from '@app/components-legacy/Form/Wrappers'
import styled from 'styled-components'

export default styled(FormWrapperInput)`
  input {
    // override default width set for forms
    width: 70px !important;
    text-align: center;
  }
`
