/**
 * Compare two strings alphabetically using String.prototype.localeCompare.
 */
export function compareStringsAlphabetically(a: string, b: string): number {
  return a.localeCompare(b)
}

/**
 * Compare two strings alphabetically in descending order using String.prototype.localeCompare.
 */
export function compareStringsUnalphabetically(a: string, b: string): number {
  return -a.localeCompare(b)
}
