import { ContainerFlex } from '@app/components-legacy/Container'
import { InputCheckers, InputReasons } from '@app/components-legacy/Input'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import { EventDetailsPageContext } from '../contexts'
import { onCheckerValidation, onReasonsValidation } from './handlers'

interface IEventDetailDeviancesTableHeaderProps {}

const EventDetailDeviancesTableHeader: React.FC<
  IEventDetailDeviancesTableHeaderProps
> = () => {
  const { storeTrailFlow } = useStores()
  const { storeEventDetailsDeviances } = storeTrailFlow.storeEventDetails

  const parameters = React.useContext(EventDetailsPageContext)

  if (!parameters) {
    return null
  }

  return (
    <ContainerFlex
      name="EventDetailDeviancesTableHeader"
      justifyContent="flex-end"
      items={[
        <InputCheckers
          storeInputGenericCheckers={
            storeEventDetailsDeviances.storeInputCheckersExposure
          }
          onValidate={onCheckerValidation(storeTrailFlow)(
            parameters.infrastructureId,
            parameters.directoryId,
            parameters.eventId
          )}
        />,

        <InputReasons
          storeInputReasons={storeEventDetailsDeviances.storeInputReasons}
          onValidate={onReasonsValidation(storeTrailFlow)(
            parameters.infrastructureId,
            parameters.directoryId,
            parameters.eventId
          )}
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default EventDetailDeviancesTableHeader
