import {
  ContainerContent,
  ContainerFlex,
  ContainerPDFPage
} from '@app/components-legacy/Container'
import PdfFooterPage from '@app/components-legacy/SVG/Images/PdfFooterPage'
import PdfHeaderPage from '@app/components-legacy/SVG/Images/PdfHeaderPage'
import {
  TypographyPDFSubTitle,
  TypographyPDFTitle
} from '@app/components-legacy/Typography'
import { useAppTranslator } from '@app/hooks'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import IoABoardCard from '../IoABoardPage/IoABoardCards/IoABoardCard'
import { NB_CARDS_BY_ROW } from './constants'

interface IIoABoardCardsPDFProps {}

// Reduce size to fit multiple cards in one line
const StyledCard = styled.div`
  transform: scale(0.9);
`

const IoABoardCardsPDF: React.FC<IIoABoardCardsPDFProps> = () => {
  const { storeIoA } = useStores()
  const { storeBoard } = storeIoA
  const { storeSummaryCards } = storeBoard

  const translate = useAppTranslator({
    namespaces: ['IoA.BoardPDF']
  })

  const sortedCardItems =
    storeSummaryCards.sortedFilteredStoresSummaryCardBlocks.map(
      (block, blockIndex) => {
        const storeSummaryCardByRows =
          storeSummaryCards.getGroupedStoreSummaryCardByRows(
            NB_CARDS_BY_ROW,
            block.storesSummaryCard
          )

        const cardsItemsByRows = storeSummaryCardByRows.map(
          (storeSummaryCardByCols, rowIndex) => {
            const items = storeSummaryCardByCols.map(storeSummaryCard => {
              return (
                <StyledCard>
                  <IoABoardCard storeSummaryCard={storeSummaryCard} />
                </StyledCard>
              )
            })

            return (
              <ContainerFlex
                key={rowIndex}
                name="IoABoardCardsPDFRow"
                direction="row"
                justifyContent="center"
                items={items}
                spaced
              />
            )
          }
        )

        return (
          <ContainerPDFPage
            key={blockIndex}
            headerItem={<PdfHeaderPage />}
            footerItem={<PdfFooterPage />}
            bodyItems={[
              blockIndex === 0 && (
                <>
                  <TypographyPDFTitle>
                    {translate('Domains impacted')}
                    {', '}
                    {translate('Sorted by')}{' '}
                    {translate(
                      storeIoA.storeBoard.storeSummaryCards.summaryLayout
                    )}
                  </TypographyPDFTitle>
                </>
              ),

              <TypographyPDFSubTitle>{block.blockName}</TypographyPDFSubTitle>,

              ...cardsItemsByRows
            ]}
          />
        )
      }
    )

  return <ContainerContent>{sortedCardItems}</ContainerContent>
}

export default observer(IoABoardCardsPDF)
