import type { Maybe } from '@@types/helpers'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

export interface ISDDLPanelHeaderDefinitionProps {
  className?: string
  definition: Maybe<string>
}

const SDDLPanelHeaderDefinition: React.FC<
  ISDDLPanelHeaderDefinitionProps
> = props => {
  if (!props.definition) {
    return null
  }

  if (typeof props.definition !== 'string') {
    return null
  }

  return <div className={props.className}>{props.definition}</div>
}

export default styled(SDDLPanelHeaderDefinition)`
  background-color: ${consts.colorBlue015};
  color: white;
  padding: 0 ${consts.paddingVerySmall};
  border-radius: ${consts.containerSmallBorderRadius};
`
