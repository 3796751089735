import { And, Constraint, Or } from '@libs/Expression/NodeLeafUtils'
import { Parser } from '@libs/Expression/parser/Parser'
import {
  optional,
  sequence0N
} from '@libs/Expression/parser/utils/SequenceParsers'
import {
  anyStringAmong,
  anyStringSurroundedBy,
  anyStringUntilOneOf,
  stringEqualsTo,
  stringMatching,
  zeroOrMoreSpaces
} from '@libs/Expression/parser/utils/StringParsers'
import type { NodeOrLeaf, ValueOpOrNull } from '@libs/Expression/types'
import { Combinator } from '@libs/Expression/types'
import Value from '@libs/Expression/Value'
import type Leaf from './Leaf'

const ignoringCase = true
const and = Combinator.AND
const or = Combinator.OR
const leftBracket = '('
const rightBracket = ')'
const semiColon = ':'
const equal = '='
const singleQuote = "'"
const doubleQuote = '"'

function term(): Parser<NodeOrLeaf> {
  return Parser.lazy<any>('Term', () =>
    factor()
      .followedBy(
        sequence0N(
          zeroOrMoreSpaces()
            .followedByKeepRight(stringEqualsTo(and, ignoringCase))
            .followedByKeepRight(factor())
        )
      )
      .map(([firstFactor, repeatedOtherFactor]) =>
        And(firstFactor, repeatedOtherFactor)
      )
  )
}

function factor(): Parser<NodeOrLeaf> {
  return Parser.lazy<any>('Factor', () =>
    keyValue().orElse(parenthesisedExpression())
  )
}

function parenthesisedExpression(): Parser<NodeOrLeaf> {
  return Parser.lazy<any>('ParenthesisedExpression', () =>
    stringEqualsTo(leftBracket)
      .followedByKeepRight(logicalExpression())
      .followedByKeepLeft(stringEqualsTo(rightBracket))
  )
}

/**
 *  Definitions :
 *    ~    :  Followed by
 *    |    :  Or Else
 *    ::=  :  Is Defined As
 *
 *  Grammar definition for Logical expressions
 *
 *    logicalExpression         ::=  term   ~ sequence0N( "OR" ~ term  )
 *      term                    ::=  factor ~ sequence0N( "AND" ~ factor )
 *      factor                  ::=  keyValue | parenthesisedExpression
 *      parenthesisedExpression ::=  "(" ~ logicalExpression ~ ")"
 *      keyValue                ::=  key ~ (":" | "=") ~ value
 *      key                     ::=  [-/\\_a-zA-Z][-/\\_a-zA-Z0-9]*
 *      value                   ::=  number | date | string
 *
 *  @Notes :
 *  - The entry point of the parser is the production 'logicalExpression' ie
 *    Every expressions to be recognized will flow thru 'logicalExpression' rule
 *    first and then follow down to other rules defined bellow.
 *
 *  - Because it is defined on top, the 'OR' combinator has lower
 *    priority than the 'AND' combinator. More generally, all rules defined
 *    first have low priority than rules defined in another production
 *    defined after them. this is not true for '|' (Or Else) where symbols
 *    defined first has greater priority than other symbols on the same line.
 */
export function logicalExpression(): Parser<NodeOrLeaf> {
  return Parser.lazy<any>('LogicalExpression', () =>
    zeroOrMoreSpaces()
      .followedByKeepRight(term())
      .followedBy(
        sequence0N(
          zeroOrMoreSpaces()
            .followedByKeepRight(stringEqualsTo(or, ignoringCase))
            .followedByKeepRight(term())
        )
      )
      .map(([firstTerm, repeatedTerms]) => Or(firstTerm, repeatedTerms))
  )
}

export function valueParser(): Parser<[ValueOpOrNull, string]> {
  const dateOperator: Parser<string> = anyStringAmong(
    Value.allDateOperators
  ).named('DateOperator')

  const quotedValue: Parser<string> = anyStringSurroundedBy(singleQuote)
    .orElse(anyStringSurroundedBy(doubleQuote))
    .map(
      unquotedMatchedString =>
        `${Value.quote}${unquotedMatchedString}${Value.quote}`
    )
    .named('Quoted')

  const unQuotedValue: Parser<string> = anyStringUntilOneOf(
    // avoid to match an operator in the value (cf issue #2168)
    [` ${and} `, ` ${or} `, leftBracket, rightBracket],
    ignoringCase
  ).named('UnQuoted')

  return optional(dateOperator)
    .followedBy(quotedValue.orElse(unQuotedValue))
    .flatMap<[ValueOpOrNull, string]>(([dateOp, val]) =>
      val.length > 0
        ? Parser.success<[ValueOpOrNull, string]>([dateOp, val.trim()])
        : Parser.failure<[ValueOpOrNull, string]>('Non empty value expected')
    )
    .named('Value')
}

export function keyValue(): Parser<Leaf> {
  const key: Parser<string> = stringMatching(/[-/\\_a-zA-Z][-/\\_a-zA-Z0-9]*/g)
    .followedByKeepLeft(anyStringAmong([semiColon, equal]))
    .named('Key')

  return key
    .followedBy(valueParser())
    .named('KeyValue')
    .map(([k, v]) => Constraint(k, v))
}
