import ContextStores from '@app/contexts/ContextStores'
import type StoreRoot from '@app/stores/StoreRoot'
import type { IStores } from '@app/stores/types'
import { ApplicationError } from '@libs/errors'
import { ApplicationErrorValue } from '@libs/errors/types'
import * as React from 'react'

/**
 * Retrieve MobX stores from the context.
 */
export function useStores(): IStores & { storeRoot: StoreRoot } {
  const value = React.useContext(ContextStores)

  if (!value.storeRoot) {
    throw new ApplicationError({
      errorValue: ApplicationErrorValue.HookError,
      message: `Root store has not been found`
    })
  }

  const allStores = {
    ...value.storeRoot.stores,
    storeRoot: value.storeRoot
  }

  return allStores
}
