import type {
  StoreManagementApplicationSettings,
  StoreManagementRbacRoles
} from '@app/stores'
import { TenableAccountFormFieldName } from '@app/stores/Management/StoreApplicationSettings/types'
import type StoreLockoutPolicy from '@app/stores/Management/StoreLockoutPolicy'
import { LockoutPolicyFormFieldName } from '@app/stores/Management/StoreLockoutPolicy/types'
import { ensureArray } from '@libs/ensureArray'
import type {
  InputUpdateApplicationSettings,
  InputUpdateLockoutPolicy,
  Maybe
} from '@server/graphql/typeDefs/types'
import { canAccessToTenableProviderDefaultRolesAndProfiles } from '../permissions'
import { canEditLockoutPolicy } from './permissions'

/**
 * Fetch data when coming on the page and setup forms.
 */
export const handleTenableAccountConfigurationOnLoad =
  (
    storeManagementRbacRoles: StoreManagementRbacRoles,
    storeManagementApplicationSettings: StoreManagementApplicationSettings
  ) =>
  () => {
    const { storeRbac } = storeManagementRbacRoles.storeRoot.stores

    if (
      storeRbac.isUserGrantedTo(
        canAccessToTenableProviderDefaultRolesAndProfiles
      )
    ) {
      Promise.all([
        // populate roles selector
        storeManagementRbacRoles.fetchRbacRoles(),
        storeManagementApplicationSettings.fetchApplicationSettings()
      ]).then(() => {
        const applicationSettings =
          storeManagementApplicationSettings.applicationSettings

        if (!applicationSettings) {
          return
        }

        // initialize the Tenable.ad form
        storeManagementApplicationSettings.storeFormTenableAccountConfiguration
          .setDefaultFieldsValues([
            {
              key: TenableAccountFormFieldName.defaultProfileId,
              value: applicationSettings.getPropertyAsNumber('defaultProfileId')
            },
            {
              key: TenableAccountFormFieldName.defaultRoleIds,
              value: ensureArray(applicationSettings.defaultRoleIds).join(',')
            }
          ])
          .reset()
      })
    }
  }

/**
 * Reset forms when leaving the page.
 */
export const handleTenableAccountConfigurationOnUnLoad =
  (storeManagementApplicationSettings: StoreManagementApplicationSettings) =>
  () => {
    storeManagementApplicationSettings.storeFormTenableAccountConfiguration.hardReset()
  }

/**
 * Save Tenable.ad account configuration
 * (application settings + Lockout Policy)
 */
export const handleTenableAccountConfigurationOnSubmit =
  (
    storeManagementApplicationSettings: StoreManagementApplicationSettings,
    storeLockoutPolicy: StoreLockoutPolicy
  ) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    return storeManagementApplicationSettings.updateApplicationSettingsAndLockoutPolicy(
      getApplicationSettingsArgs(storeManagementApplicationSettings),
      getLockoutPolicyArgs(storeLockoutPolicy)
    )
  }

/**
 * Retrive Application Settings configuration args.
 */
const getApplicationSettingsArgs = (
  storeManagementApplicationSettings: StoreManagementApplicationSettings
): Maybe<InputUpdateApplicationSettings> => {
  const { storeRbac } = storeManagementApplicationSettings.storeRoot.stores

  if (
    !storeRbac.isUserGrantedTo(
      canAccessToTenableProviderDefaultRolesAndProfiles
    )
  ) {
    return null
  }

  const { storeFormTenableAccountConfiguration } =
    storeManagementApplicationSettings

  const { storeMessages } =
    storeFormTenableAccountConfiguration.storeRoot.stores

  if (!storeFormTenableAccountConfiguration.validate()) {
    storeMessages.validationError()
    return null
  }

  const currentApplicationSettings =
    storeManagementApplicationSettings.applicationSettings

  if (!currentApplicationSettings) {
    return null
  }

  const applicationSettings: InputUpdateApplicationSettings = {
    defaultProfileId:
      storeFormTenableAccountConfiguration.getFieldValueAsNumber(
        TenableAccountFormFieldName.defaultProfileId
      ),
    defaultRoleIds: storeFormTenableAccountConfiguration
      .getFieldValueAsString(TenableAccountFormFieldName.defaultRoleIds)
      .split(',')
      .map(id => Number(id))
  }

  return applicationSettings
}

/**
 * Retrieve Lockout Policy configuration args.
 */
const getLockoutPolicyArgs = (
  storeLockoutPolicy: StoreLockoutPolicy
): Maybe<InputUpdateLockoutPolicy> => {
  const { storeRbac } = storeLockoutPolicy.storeRoot.stores
  const { storeFormLockoutPolicyConfiguration } = storeLockoutPolicy

  if (!storeRbac.isUserGrantedTo(canEditLockoutPolicy)) {
    return null
  }

  const infiniteLockoutDuration =
    storeFormLockoutPolicyConfiguration.getFieldValueAsBoolean(
      LockoutPolicyFormFieldName.lockoutDurationInfinite
    )

  const infiniteFailedAttemptPeriod =
    storeFormLockoutPolicyConfiguration.getFieldValueAsBoolean(
      LockoutPolicyFormFieldName.failedAttemptPeriodInfinite
    )

  const lockoutPolicyArgs: InputUpdateLockoutPolicy = {
    enabled: storeFormLockoutPolicyConfiguration.getFieldValueAsBoolean(
      LockoutPolicyFormFieldName.enabled
    ),
    lockoutDuration: infiniteLockoutDuration
      ? 0
      : storeFormLockoutPolicyConfiguration.getFieldValueAsNumber(
          LockoutPolicyFormFieldName.lockoutDuration
        ),
    failedAttemptThreshold:
      storeFormLockoutPolicyConfiguration.getFieldValueAsNumber(
        LockoutPolicyFormFieldName.failedAttemptThreshold
      ),
    failedAttemptPeriod: infiniteFailedAttemptPeriod
      ? 0
      : storeFormLockoutPolicyConfiguration.getFieldValueAsNumber(
          LockoutPolicyFormFieldName.failedAttemptPeriod
        )
  }

  return lockoutPolicyArgs
}
