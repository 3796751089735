import type { Mutation } from '../typeDefs/types'

export type MutationCreateSyslog = Pick<Mutation, 'createSyslog'>

export const mutationCreateSyslog = `
  mutation mutationCreateSyslog($syslog: InputCreateSyslog!) {
    createSyslog(
      syslog: $syslog
    ) {
      ip
      port
      protocol
      tls
      criticityThreshold
      directories {
        id
      }
      healthCheckNames
      description
      shouldNotifyOnInitialFullSecurityCheck
      filterExpression
      inputType
      profiles
      checkers
      relayId
    }
  }
`

export type MutationEditSyslog = Pick<Mutation, 'editSyslog'>

export const mutationEditSyslog = `
  mutation mutationEditSyslog($syslog: InputEditSyslog!) {
    editSyslog(
      syslog: $syslog
    ) {
      ip
      port
      protocol
      tls
      criticityThreshold
      directories {
        id
      }
      healthCheckNames
      description
      shouldNotifyOnInitialFullSecurityCheck
      filterExpression
      inputType
      profiles
      checkers
      relayId
    }
  }
`

export type MutationDeleteSyslog = Pick<Mutation, 'deleteSyslog'>

export const mutationDeleteSyslog = `
  mutation mutationDeleteSyslog($syslog: InputDeleteSyslog!) {
    deleteSyslog(
      syslog: $syslog
    )
  }
`

export type MutationTestNewSyslog = Pick<Mutation, 'testNewSyslog'>

export const mutationTestNewSyslog = `
  mutation mutationTestNewSyslog($syslog: InputTestNewSyslog!) {
    testNewSyslog(
      syslog: $syslog
    )
  }
`

export type MutationTestExistingSyslog = Pick<Mutation, 'testExistingSyslog'>

export const mutationTestExistingSyslog = `
  mutation mutationTestExistingSyslog($syslog: InputTestExistingSyslog!) {
    testExistingSyslog(
      syslog: $syslog
    )
  }
`
