import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { TagValue } from '.'
import { ContainerFlex } from '../Container'
import { TagFlags } from './types'

interface ITagParenthesisProps {
  className?: string
  children: React.ReactNode
}

const TagParenthesisRaw = (props: ITagParenthesisProps) => {
  return (
    <ContainerFlex
      className={props.className}
      name="TagParenthesis"
      alignItems="center"
      items={[
        <TagValue variant={TagFlags.Transparent | TagFlags.NoMargin}>
          (
        </TagValue>,
        <>{props.children}</>,
        <TagValue variant={TagFlags.Transparent | TagFlags.NoMargin}>
          )
        </TagValue>
      ]}
    />
  )
}

export default styled(TagParenthesisRaw)`
  font-weight: ${consts.fontWeightBold};
  ${consts.fontMonospaceDefault};
`
