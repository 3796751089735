import { interpolateTemplate } from '@alsid/common/helpers/template/interpolateTemplate'
import ButtonCopyToClipboard from '@app/components/Button/ButtonCopyToClipboard'
import InputCode from '@app/components/Input/InputCode'
import { ContainerFlex } from '@app/components-legacy/Container'
import type { EntityDirectory, EntityInfrastructure } from '@app/entities'
import { useAppTranslator, useStores } from '@app/hooks'
import {
  IOA_CONFIGURATION_FILENAME,
  IOA_INSTALL_SCRIPT_FILENAME
} from '@libs/common/consts'
import { isDefined } from '@libs/isDefined'
import type { TextAreaProps } from 'antd/lib/input'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

interface IContainerScriptCommandsProps {
  className?: string
}

const ContainerScriptCommands: React.FC<
  IContainerScriptCommandsProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.IoA']
  })

  const { storeManagementAttackTypeConfiguration } = useStores()

  const scriptCommand = `./${IOA_INSTALL_SCRIPT_FILENAME} -DomainControllerAddress <%= ip %> -TenableServiceAccount <%= accountService %> -ConfigurationFileLocation ./${IOA_CONFIGURATION_FILENAME}`

  const infraAndDirectories = Array.from(
    storeManagementAttackTypeConfiguration.storeInfrastructures.directories.values()
  ).map(directory => {
    if (!directory || !directory.id) {
      return
    }

    const infrastructure =
      storeManagementAttackTypeConfiguration.storeInfrastructures.getInfrastructureFromDirectoryId(
        directory.id
      )

    if (!infrastructure) {
      return
    }

    const tuple: [EntityInfrastructure, EntityDirectory] = [
      infrastructure,
      directory
    ]
    return tuple
  })

  const scriptCommands = infraAndDirectories
    .filter(isDefined)
    .map(([infrastructure, directory]) => {
      if (!infrastructure || !directory) {
        return
      }

      try {
        return interpolateTemplate(scriptCommand, {
          ip: directory.getPropertyAsString('ip', '?'),
          accountService: infrastructure.getPropertyAsString('login', '?')
        })
      } catch (e) {
        return null
      }
    })
    .filter(isDefined)

  const uniqScriptCommands = new Set([...scriptCommands])
  const hasNoScriptCommand = !uniqScriptCommands.size

  const textAreaFinalProps: TextAreaProps = {
    autoComplete: 'off',
    value: Array.from(uniqScriptCommands.values()).join('\n'),
    placeholder: hasNoScriptCommand
      ? translate('No command to execute')
      : undefined,
    readOnly: hasNoScriptCommand,
    style: {
      height: '200px'
    }
  }

  return (
    <ContainerFlex
      name="TextAreaCertificate"
      className={props.className}
      direction="column"
      items={[
        <InputCode>
          {hasNoScriptCommand
            ? translate('No command to execute')
            : Array.from(uniqScriptCommands.values()).map((value, index) => (
                <div key={`command-${index}`}>{value}</div>
              ))}
        </InputCode>,

        <ContainerFlex
          name="TextAreaCertificateCopy"
          justifyContent="flex-end"
          items={[
            <ButtonCopyToClipboard
              textToCopy={String(
                textAreaFinalProps.defaultValue || textAreaFinalProps.value
              )}
            />
          ]}
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

const ObservedContainerScriptCommands = observer(ContainerScriptCommands)

export default styled(ObservedContainerScriptCommands)`
  position: relative;
`
