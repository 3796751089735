import type { Maybe } from '@@types/helpers'
import { Features } from '@alsid/common'
import type { AppRouteName } from '@app/routes'
import { TelemetryFormFieldName } from '@app/stores/Management/StoreApplicationSettings/types'
import { getLogger } from '@libs/logger'
import StoreBase from '../StoreBase'
import type StoreRoot from '../StoreRoot'
import type { IStoreOptions } from '../types'
import type {
  ITelemetryEnableMetadata,
  ITelemetryExternalScriptAPI,
  ITelemetryIdentifyMetadata,
  ParamsButtonClick,
  ParamsFormSubmitted,
  ParamsPageLeft,
  ParamsPageVisited
} from './types'
import { EventType } from './types'

export default class StoreTelemetry extends StoreBase {
  /* Private */

  private _logger = getLogger('Telemetry')
  private _appRouter = this.storeRoot.appRouter
  private _containerId: string | null = null

  private _lastDatePageVisited = new Date()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
  }

  /**
   *
   * Enable Telemetry.
   */
  enableTelemetry(telemetryMetaData: ITelemetryEnableMetadata): this {
    if (!this._telemetry) {
      return this
    }

    const { dataSourceKey, development, debug, containerId } = telemetryMetaData

    this._containerId = containerId

    this._telemetry.enable(dataSourceKey, {
      development,
      autotrack: false, // Disabled as the lib can't catch events the way this lib do
      debug
    })

    return this
  }

  /**
   * Identify Telemetry with the logged used.
   */
  identifyTelemetry(telemetryMetaData: ITelemetryIdentifyMetadata): this {
    if (!this._telemetry) {
      return this
    }

    const { userUid } = telemetryMetaData

    if (!this._containerId) {
      this._logger.error(
        "Could not send 'identity' to telemetry, containerId is not set yet. Did you run 'enableTelemetry'?"
      )
      return this
    }

    this._telemetry.identify(userUid, {
      container_id: this._containerId
    })

    return this
  }

  /**
   * Log the page the user visited.
   */
  logPageVisited(routeName: AppRouteName): this {
    if (!this._telemetry) {
      return this
    }

    const href = this._appRouter.getRoutePathname(routeName)

    const params: ParamsPageVisited = {
      name: routeName,
      href
    }

    // We use the "page" method of the lib because using "event" with an event named
    // "page visited" is forbidden
    this._telemetry.page(params)

    this._lastDatePageVisited = new Date()

    this._logger.debug('🚀 Log page visited:', params)

    return this
  }

  /**
   * Log the page the user left.
   */
  logPageLeft(routeName: AppRouteName): this {
    if (!this._telemetry) {
      return this
    }

    const timeSpent = this._getTimeSpent()
    const href = this._appRouter.getRoutePathname(routeName)

    const params: ParamsPageLeft = {
      pageName: routeName,
      href,
      timeSpent
    }

    this._telemetry.event(EventType.PageClosed, params)

    this._logger.debug('⌛ Log page left:', params)

    return this
  }

  /**
   * Log the submit event when the user validates a form.
   */
  logFormSubmitted(formName: string): this {
    if (!this._telemetry) {
      return this
    }

    const params: ParamsFormSubmitted = {
      formName
    }

    this._telemetry.event(EventType.FormSent, params)

    this._logger.debug(EventType.FormSent, params)

    return this
  }

  /**
   * Log the click on a button
   */
  logClick(buttonName: string): this {
    if (!this._telemetry) {
      return this
    }

    const routeInfo = this.storeRoot.appRouter.getCurrentRouteInfo()

    if (!routeInfo.routeName) {
      return this
    }

    const params: ParamsButtonClick = {
      name: buttonName,
      href: this._appRouter.getRoutePathname(routeInfo.routeName)
    }

    this._telemetry.event(EventType.ButtonClicked, params)
    this._logger.debug(EventType.ButtonClicked, params)

    return this
  }

  /* Private */

  /**
   * Return the global amplitude object registered by the browser script.
   */
  private get _telemetry(): Maybe<ITelemetryExternalScriptAPI> {
    if (!(window.telemetry && window.telemetry.enable)) {
      this.storeRoot.logger.warn(
        '[Telemetry] Cannot enable telemetry - Missing framework'
      )

      return null
    }

    // If the toggle is off, returning no telemetry
    if (
      !this.storeRoot.stores.storeRbac.isUserGrantedAccordingFeatureFlag(
        Features.TELEMETRY
      )
    ) {
      return null
    }

    // If the telemetry is activated in configuration
    if (
      !this.storeRoot.stores.storeManagementApplicationSettings.storeFormTelemetryConfiguration.getFieldValueAsBoolean(
        TelemetryFormFieldName.telemetryEnabled
      )
    ) {
      return null
    }

    return window.telemetry
  }

  /**
   * Compute the time stayed on a page.
   */
  private _getTimeSpent(): number {
    const startDate = this._lastDatePageVisited
    const endDate = new Date()

    const diffInSeconds = Math.round(
      (endDate.getTime() - startDate.getTime()) / 1000
    )

    return diffInSeconds
  }
}
