import FormWrapperRangePicker from '@app/components-legacy/Input/InputDatePickerRange/index'
import { useStores } from '@app/hooks'
import { DateFormat } from '@libs/dates/formatDate'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleActivityLogsRangePickerOnChange } from './handlers'

interface IFilterRangePickerProps {
  className?: string
}

const FilterRangePicker: React.FC<IFilterRangePickerProps> = props => {
  const { storeActivityLogs } = useStores()

  return (
    <div data-name="FilterRangePicker">
      <FormWrapperRangePicker
        className={props.className}
        labelledBy="activityLogsRangePicker"
        onChange={handleActivityLogsRangePickerOnChange(storeActivityLogs)}
        unitOfTime="minutes"
        rangePickerProps={{
          showTime: {
            showSecond: false
          },
          format: DateFormat.frenchFullDateWithoutSeconds,
          allowClear: true,
          allowEmpty: [true, true]
        }}
        storeDatePicker={storeActivityLogs.storeDatePicker}
      />
    </div>
  )
}

export default observer(FilterRangePicker)
