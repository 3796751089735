import type { IDataRowReport } from '@app/entities/EntityReport'
import type { StoreManagementReportingCenter } from '@app/stores'
import { onSelectChange } from '@app/stores/helpers/StoreForm/handlers'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type { InputEditReport } from '@server/graphql/typeDefs/types'
import { DataTimeframe, ReportType } from '@server/graphql/typeDefs/types'
import type { SelectValue } from 'antd/lib/select'
import { action } from 'mobx'
import { getBrowserTimeZone, getProfiles } from './helpers'
import { ReportCommonFormFieldName } from './types'

/**
 * Load inputs on drawer loading for creation
 */
export const handleReportCreationOnLoad = (
  storeManagementReportingCenter: StoreManagementReportingCenter
) =>
  action(() => {
    const {
      storeInfrastructures,
      storeInputCheckersExposure,
      storeInputCrontab,
      storeInputCheckersAttacks
    } = storeManagementReportingCenter

    // remove any current data filled in form fields
    storeManagementReportingCenter.storeForm.hardReset()

    // data is already feched when loading the reports table
    // FIXME checkers are not loaded and no error message is displayed on submit when selection is empty (mandatory)
    storeInputCheckersExposure.selectAllCheckers()
    storeInputCheckersAttacks.selectAllCheckers()
    storeInfrastructures.selectAllDirectories()
    storeInputCrontab.setDefaultCrontab()

    // set default values with the report properties
    storeManagementReportingCenter.storeForm
      .setDefaultFieldsValues([
        {
          key: ReportCommonFormFieldName.reportType,
          value: ReportType.Deviances
        },
        {
          key: ReportCommonFormFieldName.checkerIds,
          value: JSON.stringify(storeInputCheckersExposure.selectedCheckerIds)
        },
        {
          key: ReportCommonFormFieldName.attackTypeIds,
          value: JSON.stringify(storeInputCheckersAttacks.selectedCheckerIds)
        },
        {
          key: ReportCommonFormFieldName.directoryIds,
          value: JSON.stringify(storeInfrastructures.selectedDirectoryIds)
        },
        {
          key: ReportCommonFormFieldName.profileId,
          value: getProfiles(
            storeManagementReportingCenter.storeRoot.stores.storeAuthentication
          )[0].value
        },
        {
          key: ReportCommonFormFieldName.format,
          value: 'csv'
        },
        {
          key: ReportCommonFormFieldName.dataTimeframe,
          value: DataTimeframe.Week
        },
        {
          key: ReportCommonFormFieldName.timeZone,
          value: getBrowserTimeZone()
        }
      ])
      // set values in the form
      .reset()
  })

/**
 * Reset inputs on drawer unloading for creation
 */
export const handleReportCreationOnUnload = (
  storeManagementReportingCenter: StoreManagementReportingCenter
) =>
  action(() => {
    storeManagementReportingCenter.resetForm()
  })

/**
 * Load inputs on drawer loading for edition
 */
export const handleReportEditionOnLoad =
  (storeManagementReportingCenter: StoreManagementReportingCenter) =>
  (reportDataRow: IDataRowReport) =>
    action(() => {
      const {
        storeInfrastructures,
        storeInputCheckersExposure,
        storeInputCheckersAttacks,
        storeInputEmails,
        storeInputCrontab
      } = storeManagementReportingCenter

      switch (reportDataRow.type) {
        case ReportType.Deviances: {
          storeInputCheckersExposure.unselectAllCheckers()
          storeInputCheckersExposure.selectCheckersFromIds(
            reportDataRow.indicator.ids
          )
          storeInputCheckersAttacks.selectAllCheckers()
          break
        }

        case ReportType.Attacks: {
          storeInputCheckersExposure.selectAllCheckers()
          storeInputCheckersAttacks.unselectAllCheckers()
          storeInputCheckersAttacks.selectCheckersFromIds(
            reportDataRow.indicator.ids
          )
          break
        }

        default:
          assertUnreachableCase(reportDataRow.type)
      }

      storeInfrastructures.selectDirectories(reportDataRow.directoryIds)

      storeInputEmails.setEmails(reportDataRow.recipientEmails)
      storeInputCrontab.setCrontab(reportDataRow.recurrence)

      storeManagementReportingCenter.storeForm
        .setDefaultFieldsValues([
          {
            key: ReportCommonFormFieldName.reportType,
            value: reportDataRow.type
          },
          {
            key: ReportCommonFormFieldName.checkerIds,
            value: JSON.stringify(reportDataRow.indicator)
          },
          {
            key: ReportCommonFormFieldName.directoryIds,
            value: JSON.stringify(reportDataRow.directoryIds)
          },
          {
            key: ReportCommonFormFieldName.profileId,
            value: reportDataRow.profileId
          },
          {
            key: ReportCommonFormFieldName.reportName,
            value: reportDataRow.name
          },
          {
            key: ReportCommonFormFieldName.format,
            value: reportDataRow.format
          },
          {
            key: ReportCommonFormFieldName.dataTimeframe,
            value: reportDataRow.dataTimeframe
          },
          {
            key: ReportCommonFormFieldName.timeZone,
            value: reportDataRow.timeZone
          },
          {
            key: ReportCommonFormFieldName.recipients,
            value: JSON.stringify(reportDataRow.recipientEmails)
          }
        ])
        .reset()
    })

/**
 * Reset inputs on drawer unloading for edition
 */
export const handleReportEditionOnUnload = (
  storeManagementReportingCenter: StoreManagementReportingCenter
) =>
  action(() => {
    storeManagementReportingCenter.resetForm()
  })

/**
 * Set the report type.
 */
export const handleReportTypeOnChange =
  (storeManagementReportingCenter: StoreManagementReportingCenter) =>
  (value: SelectValue) => {
    const { storeForm } = storeManagementReportingCenter

    onSelectChange(storeForm)(ReportCommonFormFieldName.reportType)(value)
  }

/**
 * Update form values when selecting directories.
 */
export const handleDirectoriesOnSelection =
  (storeManagementReportingCenter: StoreManagementReportingCenter) => () => {
    storeManagementReportingCenter.storeForm.setFieldValue(
      ReportCommonFormFieldName.directoryIds,
      JSON.stringify(
        storeManagementReportingCenter.storeInfrastructures.selectedDirectoryIds
      )
    )
  }

/**
 * Update form values when selecting indicators.
 */
export const handleCheckersOnSelection =
  (storeManagementReportingCenter: StoreManagementReportingCenter) => () => {
    storeManagementReportingCenter.storeForm.setFieldValue(
      ReportCommonFormFieldName.checkerIds,
      JSON.stringify(
        storeManagementReportingCenter.storeInputCheckersExposure
          .selectedCheckerIds
      )
    )
  }

/**
 * Update form values when selecting attack type indicators.
 */
export const handleAttackTypesOnSelection =
  (storeManagementReportingCenter: StoreManagementReportingCenter) => () => {
    storeManagementReportingCenter.storeForm.setFieldValue(
      ReportCommonFormFieldName.attackTypeIds,
      JSON.stringify(
        storeManagementReportingCenter.storeInputCheckersAttacks
          .selectedCheckerIds
      )
    )
  }

/**
 * Set the profile on profile selection.
 */
export const handleProfileIdOnChange =
  (storeManagementReportingCenter: StoreManagementReportingCenter) =>
  (value: SelectValue) => {
    const { storeForm } = storeManagementReportingCenter

    onSelectChange(storeForm)(ReportCommonFormFieldName.profileId)(value)
  }

/**
 * Set the data timeframe on selection.
 */
export const handleDataTimeframeOnChange =
  (storeManagementReportingCenter: StoreManagementReportingCenter) =>
  (value: SelectValue) => {
    const { storeForm } = storeManagementReportingCenter

    onSelectChange(storeForm)(ReportCommonFormFieldName.dataTimeframe)(value)
  }

/**
 * Set the time zone on selection.
 */
export const handleTimeZoneOnChange =
  (storeManagementReportingCenter: StoreManagementReportingCenter) =>
  (value: SelectValue) => {
    const { storeForm } = storeManagementReportingCenter

    onSelectChange(storeForm)(ReportCommonFormFieldName.timeZone)(value)
  }

export const handleRecipientsOnAddEmails =
  (storeManagementReportingCenter: StoreManagementReportingCenter) =>
  (error?: string) => {
    storeManagementReportingCenter.storeForm.resetFieldErrors(
      ReportCommonFormFieldName.recipients
    )
    const value =
      storeManagementReportingCenter.storeInputEmails.emails.length > 0
        ? JSON.stringify(storeManagementReportingCenter.storeInputEmails.emails)
        : ''
    storeManagementReportingCenter.storeForm.setFieldValue(
      ReportCommonFormFieldName.recipients,
      value
    )
    if (error) {
      storeManagementReportingCenter.storeForm.setFieldError(
        ReportCommonFormFieldName.recipients,
        error
      )
    }
  }

/**
 * Create the report when submitting the form.
 */
export const handleReportCreationOnSubmit =
  (storeManagementReportingCenter: StoreManagementReportingCenter) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeForm } = storeManagementReportingCenter

    if (!storeForm.validate()) {
      return
    }

    storeManagementReportingCenter
      .createReport(storeManagementReportingCenter.getArgsFromStores())
      .then(() => {
        storeManagementReportingCenter.storeDrawerReportAction.closeDrawer()
        storeManagementReportingCenter.fetchReports()
      })
      .catch(() => {
        // already catched in the store
      })
  }

/**
 * Create the report when submitting the form.
 */
export const handleReportEditionOnSubmit =
  (storeManagementReportingCenter: StoreManagementReportingCenter) =>
  (reportId: number) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeForm } = storeManagementReportingCenter

    if (!storeForm.validate()) {
      return
    }

    const report: InputEditReport = {
      id: reportId,
      ...storeManagementReportingCenter.getArgsFromStores()
    }

    storeManagementReportingCenter
      .editReport(report)
      .then(() => {
        storeManagementReportingCenter.storeDrawerReportAction.closeDrawer()
        storeManagementReportingCenter.fetchReports()
      })
      .catch(() => {
        // already catched in the store
      })
  }
