/**
 * Convert object-notation styles to inline styles.
 */
export function createStyleString(styles?: object): string {
  if (!styles) {
    return ''
  }

  return Object.entries(styles)
    .map(([prop, value]) => {
      const kebabCaseProp = prop
        .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2')
        .toLowerCase()

      return `${kebabCaseProp}: ${value}`
    })
    .join('; ')
}
