import type { MaybeUndef } from '@@types/helpers'
import { DEFAULT_RULEGROUP_PARAMS } from '@app/stores/helpers/StoreWizard/constants'
import type {
  DefinitionDateValue,
  DefinitionRuleGroup,
  DefinitionRuleOrRuleGroup
} from '@app/stores/helpers/StoreWizard/types'
import { WizardFieldName } from '@app/stores/helpers/StoreWizard/types'
import {
  createRuleDefinition,
  createRuleGroupDefinition,
  isDefinitionRuleGroup
} from '@app/stores/helpers/StoreWizard/utils'
import type { IExpressionDefinition } from '@libs/Expression/types'
import { Combinator } from '@libs/Expression/types'
import { isDefined } from '@libs/isDefined'
import { compact, isBoolean, isEmpty } from 'lodash'

const getInputField = <T = string>(
  input: IExpressionDefinition
): MaybeUndef<T> => {
  const [inputField] = Object.keys(input)
  return inputField as unknown as T
}

export const isDateInput = (input: IExpressionDefinition): boolean => {
  const inputField = getInputField(input)
  return inputField === 'date'
}

export const isGroupInput = (input: IExpressionDefinition): boolean => {
  const inputField = getInputField(input)

  if (!isDefined(inputField)) {
    return false
  }

  const isFieldCombinator = inputField === 'AND' || inputField === 'OR'
  return isFieldCombinator && input[inputField] instanceof Array
}

export const getDefinitionRules = (
  input: IExpressionDefinition
): IExpressionDefinition[] => {
  const combinator = getInputField<Combinator>(input)

  if (!isDefined(combinator)) {
    return []
  }

  const inputRules = input[combinator] as IExpressionDefinition[]
  return inputRules
}

/**
 * Convert expression definition into a wizard definition
 */
export function convertToWizardDefinition(
  initialInput: IExpressionDefinition
): DefinitionRuleGroup {
  const repeatOnGroup = (input: IExpressionDefinition): DefinitionRuleGroup => {
    const combinator = getInputField<Combinator>(input)

    const rules = getDefinitionRules(input).map(ruleOrRuleGroup => {
      const field = getInputField(ruleOrRuleGroup)

      if (!isDefined(field)) {
        return
      }

      const value = ruleOrRuleGroup[field]

      if (isGroupInput(ruleOrRuleGroup)) {
        const hasDateKey = getDefinitionRules(ruleOrRuleGroup).some(
          def => Object.keys(def)[0] === WizardFieldName.date
        )

        if (hasDateKey) {
          return
        }

        return repeatOnGroup(ruleOrRuleGroup)
      }

      // Avoid to create "isDeviant" rule - it's handled at root of wizardStore state
      if (field === WizardFieldName.isDeviant) {
        return
      }

      // Avoid to create "date" rule - it's handled at root of wizardStore state
      if (field === WizardFieldName.date) {
        return
      }

      return createRuleDefinition({
        field,
        value
      })
    })

    return createRuleGroupDefinition({
      combinator,
      rules: compact(rules)
    })
  }

  if (isGroupInput(initialInput)) {
    const convertedDefinition = repeatOnGroup(initialInput)

    // Detect a single rule child and move it at first level (omit first combinator)
    if (convertedDefinition.rules.length === 1) {
      const [singleRule] = convertedDefinition.rules

      // Ensure to return a ruleGroup
      if (isDefinitionRuleGroup(singleRule)) {
        return singleRule
      }

      return convertedDefinition
    }

    return convertedDefinition
  }

  // Expression parser should avoid this case
  return createRuleGroupDefinition(DEFAULT_RULEGROUP_PARAMS)
}

/**
 * Convert wizard definition into an expression definition
 * The following arguments are used at first level of the expression
 * - isDeviant
 * - deviantcombinator
 * - dateRule
 */
export function convertToExpressionDefinition(
  initialInput: DefinitionRuleOrRuleGroup,
  isDeviant: MaybeUndef<boolean>,
  deviantCombinator: MaybeUndef<Combinator>,
  dateRule: MaybeUndef<DefinitionDateValue>
): IExpressionDefinition {
  const repeatOnGroup = (input: DefinitionRuleGroup): IExpressionDefinition => {
    const newRules = input.rules
      .map(ruleOrRuleGroup => {
        if (isDefinitionRuleGroup(ruleOrRuleGroup)) {
          return repeatOnGroup(ruleOrRuleGroup)
        }

        // Avoid to ignore false boolean value
        if (!ruleOrRuleGroup.value && !isBoolean(ruleOrRuleGroup.value)) {
          return
        }

        return {
          [ruleOrRuleGroup.field]: ruleOrRuleGroup.value
        }
      })
      .filter(isDefined)

    if (!newRules.length) {
      return {}
    }

    return { [input.combinator]: newRules }
  }

  const hasDateRule = isDefined(dateRule)
  const hasIsDeviantRule = isDeviant === true

  if (
    isDefinitionRuleGroup(initialInput) &&
    (hasDateRule || hasIsDeviantRule)
  ) {
    /** First level combinator */
    const combinator = deviantCombinator || Combinator.AND
    const output: IExpressionDefinition = { [combinator]: [] }

    if (hasIsDeviantRule) {
      output[combinator].push({ isDeviant })
    }

    if (hasDateRule && dateRule) {
      const [from, to] = dateRule

      if (combinator === Combinator.AND) {
        output[combinator].push({ date: from })
        output[combinator].push({ date: to })
      }

      if (combinator === Combinator.OR) {
        output[combinator].push({ AND: [{ date: from }, { date: to }] })
      }
    }

    const expressionDefinition = repeatOnGroup(initialInput)

    if (!isEmpty(expressionDefinition)) {
      output[combinator].push(expressionDefinition)
    }

    return output
  }

  if (isDefinitionRuleGroup(initialInput)) {
    return repeatOnGroup(initialInput)
  }

  // Expression parser should avoid this case
  return initialInput
}
