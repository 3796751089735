import StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { If } from '@libs/fp-helpers/If'
import * as React from 'react'

/**
 * Initialize the drawer of checkers.
 *
 * Return a new store that will be used during the selection of checkers in the
 * drawer, without impacting the underlying interface.
 */
export function useDrawerInputCheckersInit<GC extends TGenericChecker>(
  storeInputGenericCheckers: StoreInputGenericCheckers<GC>
) {
  // create a new memoized storeInputCheckersExposure for pending selection
  const storePendingInputGenericCheckers = React.useMemo(() => {
    return new StoreInputGenericCheckers<any>(
      storeInputGenericCheckers.storeRoot,
      storeInputGenericCheckers.options
    )
  }, [])

  // when the drawer is opened, fetch the checkers
  // and select the same checkers that the storeInputCheckersExposure passed as param.
  React.useEffect(() => {
    if (!storeInputGenericCheckers.storeDrawer.isDrawerOpened) {
      return
    }

    // save into the pending store, the fetchers of the passed store
    storePendingInputGenericCheckers.setCheckers(
      storeInputGenericCheckers.checkers
    )

    // fetch only if no checkers
    If(!storePendingInputGenericCheckers.checkers.length)
      .fetch(() => storePendingInputGenericCheckers.fetchCheckers())
      .then(() => {
        storePendingInputGenericCheckers
          .selectCheckersFromIds(storeInputGenericCheckers.selectedCheckerIds)
          .showAllCheckers()
      })

    // unselect checkers when closing the drawer to not keep not submitted selection
    return () => {
      storePendingInputGenericCheckers.unselectAllCheckers()
    }
  }, [storeInputGenericCheckers.storeDrawer.isDrawerOpened])

  return storePendingInputGenericCheckers
}
