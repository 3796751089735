import type { Query } from '../typeDefs/types'

/**
 * Retrieve IoE alerts.
 */
export type QueryRbacAlertsIoE = Pick<Query, 'rbacAlertsIoE'>

export const queryRbacAlertsIoE = `
  query queryRbacAlertsIoE(
    $profileId: Int!
    $alertsPage: Int
    $alertsPerPage: Int
    $alertId: Int
    $showRead: Boolean!
    $showArchived: Boolean!
  ) {
    rbacAlertsIoE(
      profileId: $profileId
      alertsPage: $alertsPage
      alertsPerPage: $alertsPerPage
      alertId: $alertId
      showRead: $showRead
      showArchived: $showArchived
    ) {
      node {
        node {
          id
          infrastructureId
          directoryId
          devianceId
          archived
          read
          date
          rbacDeviance {
            node {
              createdEventId
              directoryId
              checkerId
              reason {
                name
              }
            }
            rbacCapability {
              isGranted
              isFound
              message
              rbacEntityName
            }
          }
        }
        pagination {
          page
          perPage
          totalCount
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Retrieve IoA alerts.
 */
export type QueryRbacAlertsIoA = Pick<Query, 'rbacAlertsIoA'>

export const queryRbacAlertsIoA = `
  query queryRbacAlertsIoA(
    $profileId: Int!
    $showArchived: Boolean!
    $alertsPage: Int
    $alertsPerPage: Int
  ) {
    rbacAlertsIoA(
      profileId: $profileId
      showArchived: $showArchived
      alertsPage: $alertsPage
      alertsPerPage: $alertsPerPage
    ) {
      node {
        node {
          id
          date
          directoryId
          attackTypeId
          archived
          read
        }
        pagination {
          page
          perPage
          totalCount
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Counts IoA Alerts
 */
export type QueryRbacAlertsIoACount = Pick<Query, 'rbacAlertsIoACount'>

export const queryRbacAlertsIoACount = `
  query queryRbacAlertsIoACount(
    $profileId: Int!
    $showArchived: Boolean!
    $showRead: Boolean!
  ) {
    rbacAlertsIoACount(
      profileId: $profileId
      showArchived: $showArchived
      showRead: $showRead
    ) {
      node {
        count
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
