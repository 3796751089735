import { AppRouteName } from '@app/routes'
import type { StoreBlades, StoreManagementProfiles } from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'
import type {
  InputCopyProfile,
  InputCreateProfile
} from '@server/graphql/typeDefs/types'
import type { SelectValue } from 'antd/lib/select'
import { ProfileCreateFieldValue, ProfileFieldName } from './types'

/**
 * Init the profile creation interface.
 */
export const onProfileCreateLoad =
  (
    appRouter: AppRouterClient,
    storeManagementProfiles: StoreManagementProfiles
  ) =>
  () => {
    storeManagementProfiles.storeFormCreation
      .setDefaultFieldsValues([
        // set create profile by default
        {
          key: ProfileFieldName.fromProfileSelection,
          value: ProfileCreateFieldValue.createProfile
        },
        // no action selected yet
        {
          key: ProfileFieldName.fromProfileSubmit,
          value: ''
        },
        // enabled by default
        {
          key: ProfileFieldName.enabled,
          value: true
        }
      ])
      .reset()

    storeManagementProfiles.fetchProfiles().then(() => {
      if (storeManagementProfiles.hasReachNumberOfMaximalProfiles()) {
        // redirect to the profile list
        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Management_Accounts_Profiles,
          parameters: {}
        })
        appRouter.history.push(url)

        // show the modalbox saying that there is no slot available for new profile
        storeManagementProfiles.storeModalProfileMax.show()
      }
    })
  }

/**
 * Redirect to the profiles list.
 */
export const onProfileCreateCancelClick =
  (storeBlades: StoreBlades) => (): void => {
    const url = storeBlades.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_Accounts_Profiles,
      parameters: {}
    })

    storeBlades.goToBladeUrl(url)
  }

/**
 * Set the profile on selection.
 */
export const onProfileCreateCopyFromSelection =
  (storeManagementProfiles: StoreManagementProfiles) =>
  (profileSelection: SelectValue): void => {
    storeManagementProfiles.storeFormCreation
      .reset()
      .setFieldValue(
        ProfileFieldName.fromProfileSelection,
        profileSelection as string
      )
  }

/**
 * Submit the profile creation.
 * Depending of the Action select value, create or copy a profile.
 */
export const onProfileCreateSubmit =
  (
    storeManagementProfiles: StoreManagementProfiles,
    storeBlades: StoreBlades,
    appRouter: AppRouterClient
  ) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const isValid = storeManagementProfiles.storeFormCreation.validate()

    if (!isValid) {
      return
    }

    const fieldCreateValue =
      storeManagementProfiles.storeFormCreation.getFieldValueAsString(
        ProfileFieldName.fromProfileSelection
      )

    if (fieldCreateValue === ProfileCreateFieldValue.createProfile) {
      createProfileSubmit(storeManagementProfiles, storeBlades, appRouter)
    } else {
      copyProfileSubmit(storeManagementProfiles, storeBlades, appRouter)
    }
  }

/**
 * Create a profile and redirect to the url to edit its options.
 */
export const createProfileSubmit = (
  storeManagementProfiles: StoreManagementProfiles,
  storeBlades: StoreBlades,
  appRouter: AppRouterClient
) => {
  const profile: InputCreateProfile = {
    name: storeManagementProfiles.storeFormCreation.getFieldValueAsString(
      ProfileFieldName.name
    ),
    // Directories for a profile are not used for now,
    // so passing an empty array (= all directories)
    directories: []
  }

  storeManagementProfiles
    .createProfile(profile)
    .then(() => storeBlades.removeLastBlade())
    .then(() => {
      const profileId = storeManagementProfiles.currentProfileId

      if (!profileId) {
        return
      }

      const url = appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.Management_Accounts_Profiles_Edit,
        parameters: {
          profileId
        }
      })

      appRouter.history.push(url)
    })
}

/**
 * Copy a profile and redirect to the url to edit its options.
 */
export const copyProfileSubmit = (
  storeManagementProfiles: StoreManagementProfiles,
  storeBlades: StoreBlades,
  appRouter: AppRouterClient
) => {
  const { storeFormCreation } = storeManagementProfiles

  const profileId = storeFormCreation.getFieldValueAsNumber(
    ProfileFieldName.fromProfileSelection
  )

  const profileName = storeFormCreation.getFieldValueAsString(
    ProfileFieldName.name
  )

  const profile: InputCopyProfile = {
    id: profileId,
    name: profileName,
    directories: [] // not used for now
  }

  storeManagementProfiles
    .copyProfile(profile)
    .then(() => storeBlades.removeLastBlade())
    .then(() => {
      const currentProfileId = storeManagementProfiles.currentProfileId

      if (!currentProfileId) {
        return
      }

      const url = appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.Management_Accounts_Profiles_Edit,
        parameters: {
          profileId: currentProfileId
        }
      })

      appRouter.history.push(url)
    })
}
