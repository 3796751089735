import { useAppTranslator } from '@app/hooks'
import type StoreRoot from '@app/stores/StoreRoot'
import { Tooltip, Typography } from 'antd'
import * as React from 'react'

export interface ILabelMissingDataProps {
  defaultMessageType?: 'default' | 'verbose'
  storeRoot?: StoreRoot
}

const LabelMissingData: React.FC<ILabelMissingDataProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.LabelMissingData']
  })

  const getContent = () => {
    if (!props.defaultMessageType || props.defaultMessageType === 'default') {
      return <Typography.Text strong>-</Typography.Text>
    }

    return (
      <Typography.Text strong>
        {translate('Information is not available')}
      </Typography.Text>
    )
  }

  return (
    <Tooltip title={translate('Information is not available')}>
      {getContent()}
    </Tooltip>
  )
}

export default LabelMissingData
