import * as React from 'react'

export interface IIconMagicWandProps {}

export default function IconMagicWand() {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 400 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="59.8658"
        y1="348.708"
        x2="194.866"
        y2="214.708"
        stroke="currentColor"
        strokeWidth="60"
      />
      <line
        x1="214.787"
        y1="194.787"
        x2="250.787"
        y2="158.787"
        stroke="currentColor"
        strokeWidth="60"
      />
      <line
        x1="122.576"
        y1="71.415"
        x2="177.585"
        y2="126.424"
        stroke="currentColor"
        strokeWidth="20"
      />
      <line
        x1="122.929"
        y1="125.938"
        x2="177.938"
        y2="70.9289"
        stroke="currentColor"
        strokeWidth="20"
      />
      <line
        x1="272.576"
        y1="81.415"
        x2="327.585"
        y2="136.424"
        stroke="currentColor"
        strokeWidth="20"
      />
      <line
        x1="272.929"
        y1="135.938"
        x2="327.938"
        y2="80.9289"
        stroke="currentColor"
        strokeWidth="20"
      />
      <line
        x1="281.576"
        y1="233.415"
        x2="336.585"
        y2="288.424"
        stroke="currentColor"
        strokeWidth="20"
      />
      <line
        x1="281.929"
        y1="287.938"
        x2="336.938"
        y2="232.929"
        stroke="currentColor"
        strokeWidth="20"
      />
    </svg>
  )
}
