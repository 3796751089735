import { ContainerContent } from '@app/components-legacy/Container'
import * as React from 'react'
import { canAccessToPreferences } from '../permissions'
import PreferencesInternationalizationForm from './PreferencesInternationalizationForm'

interface IPreferencesInternationalizationProps {}

const PreferencesInternationalization: React.FC<
  IPreferencesInternationalizationProps
> = props => {
  return (
    <ContainerContent rbacCapabilityCheck={canAccessToPreferences}>
      <PreferencesInternationalizationForm />
    </ContainerContent>
  )
}

export default PreferencesInternationalization
