import * as React from 'react'

export interface IGaugeLowProps {}

export default (props: IGaugeLowProps) => (
  <svg width={24} height={24}>
    <defs>
      <path
        d="M10.055.062a9.846 9.846 0 0 0-8.153 15.366l1.452-1.145a8 8 0 1 1 13.403 0l1.452 1.145A9.846 9.846 0 0 0 10.055.062z"
        id="gauge-low-prefix__a"
      />
      <path
        d="M.21 5.908a9.803 9.803 0 0 0 1.692 5.52l2.92-2.28a6.117 6.117 0 0 1-.044-6.413L1.68.73A9.794 9.794 0 0 0 .21 5.908zM4.731 2.68l4.634 4.548a1.282 1.282 0 1 0 1.39-2.154L4.733 2.68z"
        id="gauge-low-prefix__c"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(2 4)">
        <mask id="gauge-low-prefix__b" fill="#fff">
          <use xlinkHref="#gauge-low-prefix__a" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#gauge-low-prefix__a" />
        <g mask="url(#gauge-low-prefix__b)" fill="#9DA5B3">
          <path d="M-2-4h24v24H-2z" />
        </g>
      </g>
      <g transform="translate(2 8)">
        <mask id="gauge-low-prefix__d" fill="#fff">
          <use xlinkHref="#gauge-low-prefix__c" />
        </mask>
        <use fill="#000" fillRule="nonzero" xlinkHref="#gauge-low-prefix__c" />
        <g mask="url(#gauge-low-prefix__d)" fill="#606B80">
          <path d="M-2-8h24v24H-2z" />
        </g>
      </g>
    </g>
  </svg>
)
