import * as React from 'react'
import type { IIconProps } from '../types'

export default function IconAd(props: IIconProps) {
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="7.11114" height="7.11114" fill="#91C949" />
      <rect y="8.8888" width="7.11114" height="7.11114" fill="#F2BE42" />
      <rect x="8.88965" width="7.11114" height="7.11114" fill="#DF5C31" />
      <rect
        x="8.88965"
        y="8.8888"
        width="7.11114"
        height="7.11114"
        fill="#4AABE9"
      />
    </svg>
  )
}
