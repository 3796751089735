import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { ICheckerAttack } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import type StoreAttacks from '@app/stores/IoA/StoreAttacks'
import type { RemoveAttacksMutationArgs } from '@server/graphql/typeDefs/types'

/**
 * Save new filters to remove attacks and close the drawer.
 */
export const onDrawerAttacksRemoveFiltersAddValidate =
  (
    storeAttacks: StoreAttacks,
    storeInputGenericCheckers: StoreInputGenericCheckers<ICheckerAttack>
  ) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { resourceType, resourceValue } = storeAttacks.queryStringParameters

    if (!resourceType || !resourceValue) {
      return
    }

    const { storeAuthentication } = storeAttacks.storeRoot.stores
    const profileId = storeAuthentication.currentProfileId

    const attackFilters = storeInputGenericCheckers.selectedCheckerIds.map(
      attackTypeId => {
        return {
          resourceType,
          resourceValue,
          attackTypeId
        }
      }
    )

    const args: RemoveAttacksMutationArgs = {
      attackFilters,
      profileId
    }

    storeAttacks.removeAttacks(args).then(() => {
      storeAttacks.storeDrawerAttacksFiltersAdd.closeDrawer()
      storeAttacks.reloadAttacks()
    })
  }
