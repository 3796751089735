import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import { BrandColorV2, FontColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

const Color1Path = styled.path`
  fill: ${FontColorV2.primary};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${consts.colorWhite};
  }
`

const Color2Path = styled.path`
  fill: ${BrandColorV2.decorativeGray};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${consts.colorFadedBlueNewDesign};
  }
`

interface IIconTrailflowProps extends IIconProps {}

const IconTrailflowLegacy: React.FC<IIconTrailflowProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Color2Path d="M1.25 9.03809C0.559644 9.03809 0 9.59773 0 10.2881C0 13.1591 2.25114 15.5762 5.07812 15.5762C6.53324 15.5762 7.7859 14.8829 8.76465 13.9893C9.74339 13.0957 10.5046 11.9805 11.0986 10.8838C11.4275 10.277 11.2023 9.51855 10.5957 9.18945C9.98893 8.86061 9.23046 9.08574 8.90137 9.69238C8.40161 10.6151 7.76028 11.5201 7.08008 12.1411C6.39988 12.7621 5.73723 13.0762 5.07812 13.0762C3.67668 13.0762 2.5 11.8785 2.5 10.2881C2.5 9.59773 1.94036 9.03809 1.25 9.03809Z" />
      <Color2Path d="M14.9218 5C13.4667 5 12.214 5.69331 11.2352 6.58691C10.2565 7.48052 9.49525 8.59563 8.90126 9.69238C8.57241 10.2992 8.79755 11.0576 9.40419 11.3867C10.011 11.7156 10.7694 11.4904 11.0985 10.8838C11.5983 9.96103 12.2396 9.05609 12.9198 8.43506C13.6 7.81403 14.2627 7.5 14.9218 7.5C16.3232 7.5 17.4999 8.69767 17.4999 10.2881C17.4999 10.9784 18.0595 11.5381 18.7499 11.5381C19.4402 11.5381 19.9999 10.9784 19.9999 10.2881C19.9999 7.41712 17.7487 5 14.9218 5Z" />
      <Color1Path d="M5.07812 5C6.53324 5 7.7859 5.69331 8.76465 6.58691C9.74339 7.48052 10.5046 8.59563 11.0986 9.69238L11.095 9.687C11.5429 10.5126 12.099 11.3188 12.6948 11.924L12.9199 12.1411C13.6001 12.7621 14.2628 13.0762 14.9219 13.0762C16.3233 13.0762 17.5 11.8785 17.5 10.2881C17.5 9.59773 18.0596 9.03809 18.75 9.03809C19.4404 9.03809 20 9.59773 20 10.2881C20 13.1591 17.7489 15.5762 14.9219 15.5762C13.4668 15.5762 12.2141 14.8829 11.2354 13.9893C10.2566 13.0957 9.49536 11.9805 8.90137 10.8838L8.72968 10.5772C8.25861 9.76343 7.6847 8.98709 7.08008 8.43506C6.39988 7.81403 5.73723 7.5 5.07812 7.5C3.67668 7.5 2.5 8.69767 2.5 10.2881C2.5 10.9784 1.94036 11.5381 1.25 11.5381C0.559644 11.5381 0 10.9784 0 10.2881C0 7.41712 2.25114 5 5.07812 5Z" />
    </svg>
  )
}

export default IconTrailflowLegacy
