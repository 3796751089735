import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import { AppRouteName } from '@app/routes'
import type { StoreManagementProfiles } from '@app/stores'
import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import { filterFalsies } from '@libs/filterFalsies'
import { If } from '@libs/fp-helpers/If'
import type { InputEditProfile } from '@server/graphql/typeDefs/types'
import { canEditProfileIoA, canEditProfileIoE } from '../permissions'
import { ProfileFieldName } from '../ProfilesCreatePage/types'

/**
 * Fetch data and reset forms.
 */
export const handleProfileCheckersOnLoad =
  (storeManagementProfiles: StoreManagementProfiles, profileId: number) =>
  () => {
    const { storeRbac } = storeManagementProfiles.storeRoot.stores

    const canEditIoE = storeRbac.isUserGrantedTo(canEditProfileIoE(profileId))
    const canEditIoA = storeRbac.isUserGrantedTo(canEditProfileIoA(profileId))

    If(!storeManagementProfiles.profiles.size)
      .fetch(() => storeManagementProfiles.fetchProfiles())
      .then(() => {
        storeManagementProfiles.setCurrentProfile(profileId)

        const profileEntity = storeManagementProfiles.currentProfileEntity

        if (!profileEntity) {
          return
        }

        storeManagementProfiles.storeFormEdition
          .setDefaultFieldsValues([
            {
              key: ProfileFieldName.name,
              value: profileEntity.getPropertyAsString('name')
            }
          ])
          .reset()

        // load the generic checkers of the selected profileId
        Promise.all(
          filterFalsies([
            canEditIoE &&
              storeManagementProfiles.storeProfileCheckersExposure.fetchGenericCheckers(
                profileId
              ),

            canEditIoA &&
              storeManagementProfiles.storeProfileCheckersAttack.fetchGenericCheckers(
                profileId
              )
          ])
        )
      })
  }

/**
 * Reset the store when unloading the blade.
 */
export const handleProfileCheckersOnUnload =
  (storeManagementProfiles: StoreManagementProfiles) => (): void => {
    storeManagementProfiles.reset()
  }

/**
 * When leaving the field, save the new profile name.
 */
export const handleProfileNameFieldOnBlur =
  (storeManagementProfiles: StoreManagementProfiles) => (): void => {
    const { storeFormEdition } = storeManagementProfiles

    if (!storeFormEdition.field(ProfileFieldName.name).isDirty()) {
      return
    }

    const isValid = storeFormEdition.validate()

    if (!isValid) {
      return
    }

    const currentProfileId = storeManagementProfiles.currentProfileId

    if (!currentProfileId) {
      return
    }

    const profile: InputEditProfile = {
      id: currentProfileId,
      name: storeFormEdition.getFieldValueAsString(ProfileFieldName.name)
    }

    storeManagementProfiles
      .editProfile(profile)
      .then(() => storeFormEdition.setCurrentFieldsValuesAsDefault())
      .catch(() => {
        /* Already catched in the store */
      })
  }

/**
 * When clicking on a checker of exposure or attack,
 * Instanciate StoreProfileCheckerSerie stores, setup forms and redirect to the url
 * of checker configuration that will open a blade.
 */
export const handleProfileGenericCheckerOnClick =
  (storeInputGenericCheckers: StoreInputGenericCheckers<any>) =>
  (checkerCodename: GenericCheckerCodename): void => {
    const { storeManagementProfiles } =
      storeInputGenericCheckers.storeRoot.stores

    const checkerEntity =
      storeInputGenericCheckers.checkersByCodename.get(checkerCodename)

    const profileId = storeManagementProfiles.currentProfileId
    const checkerId = checkerEntity && checkerEntity.getPropertyAsNumber('id')

    if (!profileId || !checkerId) {
      return
    }

    const { appRouter } = storeManagementProfiles.storeRoot

    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_Accounts_Profiles_Edit_Checker,
      parameters: {
        profileId,
        checkerId,
        checkerCodename
      }
    })

    appRouter.history.push(url)
  }
