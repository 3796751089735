import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconSignOutProps extends IIconProps {}

const IconSignOut: React.FC<IIconSignOutProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 20

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3965 5.39654C11.2012 5.59184 11.2012 5.90824 11.3965 6.10353L14.043 8.75003H5.25C4.97363 8.75003 4.75 8.97366 4.75 9.25003C4.75 9.5264 4.97363 9.75003 5.25 9.75003H14.043L11.3965 12.3965C11.2012 12.5918 11.2012 12.9082 11.3965 13.1035C11.5918 13.2989 11.9082 13.2989 12.1035 13.1035L15.6029 9.60416C15.6493 9.55789 15.6861 9.50247 15.7115 9.44095C15.7368 9.37985 15.75 9.31509 15.75 9.25003C15.75 9.18497 15.7368 9.12021 15.7115 9.05911C15.6861 8.99759 15.6493 8.94217 15.6029 8.8959L12.1035 5.39654C11.9082 5.20124 11.5918 5.20124 11.3965 5.39654Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconSignOut
