import WrapperAlert from '@app/components-legacy/Wrapper/WrapperAlert'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'

interface IProfileAlertReadOnlyProfileProps {}

const ProfileAlertReadOnlyProfile: React.FC<
  IProfileAlertReadOnlyProfileProps
> = props => {
  const { storeManagementProfiles } = useStores()

  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Profiles.Edition']
  })

  if (!storeManagementProfiles.isCurrentProfileReadOnly) {
    return null
  }

  const currentProfile = storeManagementProfiles.currentProfileEntity

  if (!currentProfile) {
    return null
  }

  return (
    <WrapperAlert
      alertProps={{
        type: 'info',
        message: currentProfile.isTenableProfile()
          ? translate('This is the Tenable profile and cannot be customizable')
          : translate('This profile is read only and cannot be customizable'),
        showIcon: true
      }}
      labelledBy="read-only-profile"
    />
  )
}

export default ProfileAlertReadOnlyProfile
