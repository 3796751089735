import type { StoreManagementApplicationSettings } from '@app/stores'
import type { InputUpdateApplicationSettings } from '@server/graphql/typeDefs/types'

/**
 * Save health check configuration.
 */
export const handleHealthCheckConfigurationGlobalStatusDisplayChange =
  (storeManagementApplicationSettings: StoreManagementApplicationSettings) =>
  (value: boolean) => {
    const { storeFormHealthCheckConfiguration } =
      storeManagementApplicationSettings

    if (!storeFormHealthCheckConfiguration.validate()) {
      storeFormHealthCheckConfiguration.storeRoot.stores.storeMessages.validationError()
      return
    }

    const currentApplicationSettings =
      storeManagementApplicationSettings.applicationSettings

    if (!currentApplicationSettings) {
      return
    }

    const applicationSettings: InputUpdateApplicationSettings = {
      healthCheckGlobalStatusDisplayEnabled: value
    }

    storeManagementApplicationSettings
      .updateApplicationSettings(applicationSettings)
      .catch(() => {
        // No need to catch the error for now as we do not intend to display it
        // to the user
      })
  }
