import { AppRouteName } from '@app/routes'
import type {
  StoreBlades,
  StoreManagementUsers,
  StoreMessages,
  StoreRbac
} from '@app/stores'
import { UserFormFieldName } from '@app/stores/Management/StoreUsers'
import type { TranslateFn } from '@libs/i18n'
import { editRole } from '@libs/rbac/permissions'
import type { AppRouterClient } from '@libs/Router/types'
import type {
  EditUserRolesMutationArgs,
  InputEditUser
} from '@server/graphql/typeDefs/types'
import { AuthProviderName } from '@server/graphql/typeDefs/types'

/**
 * Init the form and role selection.
 */
export const onUserEditLoad =
  (storeManagementUsers: StoreManagementUsers) =>
  (userId: number) =>
  (): Promise<any> => {
    storeManagementUsers.reset()

    return (
      Promise.resolve()
        // load users if not already done
        .then(() => {
          if (!storeManagementUsers.users.size) {
            return storeManagementUsers.fetchUsers()
          }
          return Promise.resolve()
        })
        .then(() => {
          const userEntity = storeManagementUsers.users.get(userId)

          if (!userEntity) {
            return
          }

          // init form
          storeManagementUsers.storeForm
            .setDefaultFieldsValues([
              {
                key: UserFormFieldName.formVersion,
                value: 'edition'
              }
            ])
            .setDefaultFieldsValuesFromObject(userEntity, {
              action: 'omit',
              keys: ['id', 'identifier', 'rbacRoles', 'eulaVersion']
            })
            .reset()

          // select roles
          const rbacRoleRows = userEntity
            .getRoles()
            .map(rbacRoleEntity => rbacRoleEntity.asDataRow())

          storeManagementUsers.storeWidgetRbacRolesList
            .unselectAllRows()
            .resetPagination()
            .selectRows(rbacRoleRows)
        })
    )
  }

/**
 * Do a API call to remove the locked out status on the user.
 */
export const handleUserRemoveLockedOutSubmit =
  (storeManagementUsers: StoreManagementUsers) => (userId: number) => () => {
    storeManagementUsers.removeLockedOutStatus(userId).then(() => {
      storeManagementUsers.storeForm
        .setDefaultFieldsValues([
          {
            key: UserFormFieldName.lockedOut,
            value: false
          }
        ])
        .reset()
    })
  }

/**
 * Reset store when leaving the edition.
 */
export const onUserEditUnLoad =
  (storeManagementUsers: StoreManagementUsers) => () =>
    storeManagementUsers.reset()

/**
 * Edit the user,
 * Fetch directories,
 * And go back to the list.
 * Hard reload if the edited user is the current one.
 */
export const onUserEditSubmit =
  (
    storeMessages: StoreMessages,
    storeRbac: StoreRbac,
    storeManagementUsers: StoreManagementUsers
  ) =>
  (translate: TranslateFn, appRouter: AppRouterClient) =>
  (userId: number) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeForm } = storeManagementUsers

    if (!storeForm.validate()) {
      return
    }

    const user: InputEditUser = {
      id: userId,
      surname: storeForm.getFieldValueAsString(UserFormFieldName.surname),
      name: storeForm.getFieldValueAsString(UserFormFieldName.name),
      email: storeForm.getFieldValueAsString(UserFormFieldName.email),
      department: storeForm.getFieldValueAsString(UserFormFieldName.department),
      biography: storeForm.getFieldValueAsString(UserFormFieldName.biography),
      active: storeForm.getFieldValueAsBoolean(UserFormFieldName.active),
      lockedOut: storeForm.getFieldValueAsBoolean(UserFormFieldName.lockedOut)
    }

    const isTenableProvider =
      storeForm.getFieldValueAsString(UserFormFieldName.provider) ===
      AuthProviderName.Tenable

    // Temp hack to allow any user to modify the password of any account.
    // See https://github.com/AlsidOfficial/DSC-Cassiopeia/issues/1241
    const isNewPassword =
      storeForm.getFieldValueAsString(UserFormFieldName.password) !== ''

    if (isTenableProvider && isNewPassword) {
      if (
        storeForm.getFieldValueAsString(UserFormFieldName.password) !==
        storeForm.getFieldValueAsString(UserFormFieldName.passwordConfirmation)
      ) {
        storeMessages.error(
          translate('Password and confirmation password do not match'),
          {
            labelledBy: 'passwordNotMatch'
          }
        )
        return
      }

      user.password = storeForm.getFieldValueAsString(
        UserFormFieldName.password
      )
    }

    const { selectedRows } = storeManagementUsers.storeWidgetRbacRolesList

    if (!selectedRows) {
      return
    }

    storeManagementUsers
      .editUser(user)
      .then(() => {
        const selectedRoleIds = Array.from(selectedRows.keys()).map(id =>
          Number(id)
        )

        const roles: EditUserRolesMutationArgs = {
          roles: {
            userId,
            roles: selectedRoleIds
          }
        }

        // don't edit roles if not granted or if not Tenable.ad user (LDAP or SAML)
        if (!storeRbac.isUserGrantedTo(editRole())) {
          return Promise.resolve()
        }

        return storeManagementUsers.setUserRoles(roles)
      })
      .then(() => {
        const isCurrentUserLoggedIn =
          storeManagementUsers.isCurrentUserLoggedIn(userId)

        // If the edited user is not the current one, just redirect
        if (!isCurrentUserLoggedIn) {
          const url = appRouter.makeRouteInfosPathname({
            routeName: AppRouteName.Management_Accounts_Users,
            parameters: {}
          })

          appRouter.history.push(url)
          return
        }

        // Otherwise, hard reload to the user's page to reload full privileges.
        // Add some delay so that toast messages can be read by the user
        setTimeout(() => {
          appRouter.hardRedirect({
            routeName: AppRouteName.Management_Accounts_Users,
            parameters: {}
          })
        }, 1500)
      })
      .catch(() => {
        // already catched in the store
      })
  }

export const onCancelButtonClick = (storeBlades: StoreBlades) => () => {
  const url = storeBlades.storeRoot.appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.Management_Accounts_Users,
    parameters: {}
  })

  storeBlades.goToBladeUrl(url)
}
