/* tslint:disable */
/* eslint-disable */
/**
 * service-identity-core
 * Identity core service endpoints for AD addon
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime'
import type { DescriptionTemplateReplacementsInner } from './DescriptionTemplateReplacementsInner'
import {
  DescriptionTemplateReplacementsInnerFromJSON,
  DescriptionTemplateReplacementsInnerFromJSONTyped,
  DescriptionTemplateReplacementsInnerToJSON
} from './DescriptionTemplateReplacementsInner'

/**
 *
 * @export
 * @interface DescriptionTemplate
 */
export interface DescriptionTemplate {
  /**
   * Localized description template of the finding
   * @type {string}
   * @memberof DescriptionTemplate
   */
  template: string
  /**
   * Metadata about the placeholders in the template
   * @type {Array<DescriptionTemplateReplacementsInner>}
   * @memberof DescriptionTemplate
   */
  replacements: Array<DescriptionTemplateReplacementsInner>
}

/**
 * Check if a given object implements the DescriptionTemplate interface.
 */
export function instanceOfDescriptionTemplate(value: object): boolean {
  if (!('template' in value)) return false
  if (!('replacements' in value)) return false
  return true
}

export function DescriptionTemplateFromJSON(json: any): DescriptionTemplate {
  return DescriptionTemplateFromJSONTyped(json, false)
}

export function DescriptionTemplateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DescriptionTemplate {
  if (json == null) {
    return json
  }
  return {
    template: json['template'],
    replacements: (json['replacements'] as Array<any>).map(
      DescriptionTemplateReplacementsInnerFromJSON
    )
  }
}

export function DescriptionTemplateToJSON(
  value?: DescriptionTemplate | null
): any {
  if (value == null) {
    return value
  }
  return {
    template: value['template'],
    replacements: (value['replacements'] as Array<any>).map(
      DescriptionTemplateReplacementsInnerToJSON
    )
  }
}
