import { ContainerContent } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import SpinnerInline from '@app/components-legacy/Spinner/SpinnerInline'
import { useStores } from '@app/hooks'
import { BackgroundColorV2, CTAColorV2 } from '@app/styles/consts'
import { Collapse } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import UserLog from './UserLog'

interface IUserTracesProps {
  className?: string
}

const StyledCollapse = styled(Collapse)`
  width: 100%;

  > .ant-collapse-item > .ant-collapse-header {
    width: auto;
    display: inline-block;
    right: inherit;
  }

  .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    color: ${CTAColorV2.primary};
  }
`

const UserActivityLogs: React.FC<IUserTracesProps> = props => {
  const { storeActivityLogs } = useStores()

  const logsByDay = storeActivityLogs.logsByDay

  const activeKeys = Array.from(logsByDay.keys())

  return (
    <ContainerContent
      spinner={<SpinnerInline />}
      flags={[storeActivityLogs.storeFlagsFetchLogs.flags]}
      fullWidth
    >
      <StyledCollapse
        bordered={false}
        expandIconPosition="end"
        defaultActiveKey={activeKeys}
      >
        {Array.from(logsByDay, ([dateString, tracesOfTheDay]) => (
          <Collapse.Panel
            header={<Label>{dateString}</Label>}
            key={dateString}
            style={{
              backgroundColor: BackgroundColorV2.lightGray,
              border: 'none'
            }}
          >
            {Array.from(tracesOfTheDay).map(trace => {
              return <UserLog log={trace} key={trace.id} />
            })}
          </Collapse.Panel>
        ))}
      </StyledCollapse>
    </ContainerContent>
  )
}

export default observer(UserActivityLogs)
