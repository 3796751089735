import type { Maybe } from '@@types/helpers'
import { createEntities } from '@app/entities'
import EntityHealthChecksTemplatesName from '@app/entities/EntityHealthChecksTemplatesName'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import {
  HealthCheckCodenameKey,
  InputHealthChecksMode
} from '@app/stores/StoreInputHealthChecks/types'
import { getHealthCheckNamesColorScheme } from '@app/styles/colors/schemes'
import { CSSColors } from '@app/styles/colors/types'
import { isDefined } from '@libs/isDefined'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type { QueryHealthChecksTemplatesNames } from '@server/graphql/queries/healthCheck'
import { queryHealthChecksTemplatesNames } from '@server/graphql/queries/healthCheck'
import type { HealthChecksTemplatesName } from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import type { StoreRoot } from '../index'
import StoreBase from '../StoreBase'

export interface IStoreInputHealthChecksOptions {
  emptySelectionAllowed?: boolean
}

// Treeview internal ID representation (healthchecknameID)
export type TreeId = string

export default class StoreInputHealthChecks extends StoreBase<IStoreInputHealthChecksOptions> {
  public storeFlagsFetchHealthChecksTemplatesNames = new StoreFlags(
    this.storeRoot
  )
  public storeDrawer = new StoreDrawer(this.storeRoot)
  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Components.InputHealthChecks', 'HealthCheck.HealthChecks']
  })

  /* Observable */

  private $allHealthChecksTemplatesNames =
    observable.box<Maybe<EntityHealthChecksTemplatesName[]>>(null)
  private $healthChecksTemplatesNames = observable.map<
    string,
    EntityHealthChecksTemplatesName
  >()
  private $selectedHealthChecksTemplatesNames = observable.map<
    TreeId,
    boolean
  >()
  private $mode = observable.box<InputHealthChecksMode>(
    InputHealthChecksMode.alerts
  )

  // ID of expanded health check types (Tree)
  private $expandedHealthCheckTypes = observable.set<HealthCheckCodenameKey>()

  constructor(
    storeRoot: StoreRoot,
    options: IStoreInputHealthChecksOptions = {}
  ) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch health checks codenames.
   */
  fetchHealthChecksTemplatesNames(
    storeFlags = this.storeFlagsFetchHealthChecksTemplatesNames
  ) {
    storeFlags.loading()
    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryHealthChecksTemplatesNames>(
            queryHealthChecksTemplatesNames
          )
      })
      .then(healthChecksTemplatesNames => {
        if (!healthChecksTemplatesNames) {
          throw new Error('Unable to retrieve health checks templates names')
        }

        const healthChecksTemplatesNamesEntities = createEntities<
          HealthChecksTemplatesName,
          EntityHealthChecksTemplatesName
        >(
          EntityHealthChecksTemplatesName,
          healthChecksTemplatesNames.healthChecksTemplatesNames
        )
        this.setAllHealthChecksTemplatesNames(
          healthChecksTemplatesNamesEntities
        )
        this.setHealthChecksTemplatesNames(
          this.filteredHealthChecksTemplatesNames
        )

        storeFlags.success()
      })
      .catch(err => {
        this.$allHealthChecksTemplatesNames.set(null)
        this.setHealthChecksTemplatesNames([])

        storeFlags.fail()

        this.storeRoot.logException(err)
      })
  }

  /**
   * Return the color of a healthcheckname.
   */
  getHealthCheckNameColor(healthCheckNameId: string): string {
    const entityHealthCheckName =
      this.$healthChecksTemplatesNames.get(healthCheckNameId)

    if (!entityHealthCheckName) {
      return CSSColors.Black
    }

    return entityHealthCheckName.color
  }

  /**
   * Return the checked status of an infrastructure.
   */
  isHealthCheckNameSelected(healthCheckNameId: string): Maybe<boolean> {
    return this.selectedHealthChecksTemplatesNamesIds.some(
      selectedId => selectedId === healthCheckNameId
    )
  }

  /**
   * Return the checked status of an infrastructure.
   */
  isHealthCheckTypeSelected(
    healthCheckCodenameKey: HealthCheckCodenameKey
  ): Maybe<boolean> {
    const healthChecksTemplatesNamesIds = Array.from(
      this.selectedHealthChecksTemplatesNames.entries()
    ).filter(([treeId]) =>
      this.isHealthCheckTemplateNameIdInType(treeId, healthCheckCodenameKey)
    )

    const allSelected = healthChecksTemplatesNamesIds.every(
      ([, selected]) => selected === true
    )

    if (allSelected === true) {
      return true
    }

    const noneSelected = healthChecksTemplatesNamesIds.every(
      ([, selected]) => selected === false
    )

    if (noneSelected === true) {
      return false
    }

    // indeterminate status
    return null
  }

  /**
   * Return true if the health check template name id is part of the health check type with the provided codename key.
   */
  isHealthCheckTemplateNameIdInType(
    healthChecksTemplatesNameId: string,
    healthCheckCodenameKey: HealthCheckCodenameKey
  ) {
    return healthChecksTemplatesNameId.startsWith(healthCheckCodenameKey)
  }

  /**
   * Build the internal key used in the tree structure.
   */
  buildTreeKey(
    healthCheckCodenameKey: HealthCheckCodenameKey,
    healthChecksTemplatesNamesId: string
  ): string {
    return [String(healthCheckCodenameKey), healthChecksTemplatesNamesId].join(
      '|'
    )
  }

  /* Actions */

  @action
  reset(): this {
    this.$allHealthChecksTemplatesNames.set(null)
    this.$healthChecksTemplatesNames.clear()
    this.$selectedHealthChecksTemplatesNames.clear()
    this.$mode.set(InputHealthChecksMode.alerts)

    return this
  }

  /**
   * Select or unselect the health check names of a health check type.
   */
  @action
  selectHealthCheckType(
    healthCheckCodenameKey: HealthCheckCodenameKey,
    selected: boolean
  ): this {
    if (!this.healthCheckCodenameKeys.includes(healthCheckCodenameKey)) {
      return this
    }

    this.healthChecksTemplatesNamesIdsByType
      .get(healthCheckCodenameKey)
      ?.forEach(healthChecksTemplatesName =>
        this.$selectedHealthChecksTemplatesNames.set(
          healthChecksTemplatesName,
          selected
        )
      )

    return this
  }

  /**
   * Reverse the selection of the health check names of an health check type.
   */
  @action
  toggleSelectHealthCheckType(
    healthCheckCodenameKey: HealthCheckCodenameKey
  ): this {
    const selectedHealthChecksTemplatesNamesIds =
      this.selectedHealthChecksTemplatesNamesIds.filter(
        healthChecksTemplatesName =>
          this.isHealthCheckTemplateNameIdInType(
            healthChecksTemplatesName,
            healthCheckCodenameKey
          )
      )

    const typeHealthChecksTemplatesNamesIds =
      this.healthChecksTemplatesNamesIdsByType.get(healthCheckCodenameKey) ?? []

    // unselect if the infrastructure is already selected
    const select =
      selectedHealthChecksTemplatesNamesIds.length !==
      typeHealthChecksTemplatesNamesIds.length

    return this.selectHealthCheckType(healthCheckCodenameKey, select)
  }

  /**
   * Set healthchecknames entities.
   */
  @action
  setHealthChecksTemplatesNames(
    healthChecksTemplatesNamesEntities: EntityHealthChecksTemplatesName[]
  ): this {
    this.$healthChecksTemplatesNames.clear()

    // generate a color scheme for all healthchecknames
    const colors = getHealthCheckNamesColorScheme(
      healthChecksTemplatesNamesEntities.length
    )

    // set colors
    healthChecksTemplatesNamesEntities.forEach(
      (entityHealthChecksTemplatesName, i) => {
        const codename =
          entityHealthChecksTemplatesName.getPropertyAsString('codename')
        entityHealthChecksTemplatesName.setId(codename)
        entityHealthChecksTemplatesName.setColor(colors[i])
        entityHealthChecksTemplatesName.setLocalizedName(
          this.translate(`${codename}.name`)
        )
      }
    )

    const allTreeIds: TreeId[] = []

    healthChecksTemplatesNamesEntities.forEach(entityHealthCheckName => {
      const healthCheckNameId = entityHealthCheckName.getPropertyAsString('id')

      this.$healthChecksTemplatesNames.set(
        healthCheckNameId,
        entityHealthCheckName
      )

      const treeId = String(healthCheckNameId)
      allTreeIds.push(treeId)

      // retrieve the current selected status or unselect it by default
      const isSelectedValue =
        this.$selectedHealthChecksTemplatesNames.get(treeId)

      const isSelectedBoolean = isDefined(isSelectedValue)
        ? isSelectedValue
        : false

      this.$selectedHealthChecksTemplatesNames.set(treeId, isSelectedBoolean)
    })

    // remove all selection that can be "deprecated" after a healthcheckname deletion
    Array.from(this.selectedHealthChecksTemplatesNames.keys()).forEach(
      treeId => {
        if (allTreeIds.indexOf(treeId) === -1) {
          this.$selectedHealthChecksTemplatesNames.delete(treeId)
        }
      }
    )

    return this
  }

  /**
   * Select or unselect the healthchecknames.
   */
  @action
  selectHealthChecksTemplatesName(
    healthChecksTemplatesNameId: string,
    selected: boolean
  ): this {
    const entityHealthCheckName = this.$healthChecksTemplatesNames.get(
      healthChecksTemplatesNameId
    )

    if (!entityHealthCheckName) {
      return this
    }

    const treeId = entityHealthCheckName.getPropertyAsString('id')

    this.$selectedHealthChecksTemplatesNames.set(treeId, selected)

    return this
  }

  /**
   * Replace all the healthchecknames selection.
   */
  @action
  replaceSelectedHealthChecksTemplatesNames(
    selectedHealthChecksTemplatesNames: Map<TreeId, boolean>
  ): this {
    Array.from(selectedHealthChecksTemplatesNames.keys()).forEach(treeId => {
      const selected = selectedHealthChecksTemplatesNames.get(treeId)

      // if undefined, keep the existing value
      if (!isDefined(selected)) {
        return
      }

      this.$selectedHealthChecksTemplatesNames.set(treeId, selected)
    })

    return this
  }

  /**
   * Select all healthchecknames or only the healthchecknames that
   * match if the search value is defined.
   */
  @action
  selectAllHealthChecksTemplatesNames(withExpand?: boolean): this {
    if (this.storeInputSearch.hasSearchValue) {
      this.selectHealthChecksTemplatesNames(
        withExpand
          ? this.searchedHealthChecksTemplatesNamesIdsWithExpand
          : this.searchedHealthChecksTemplatesNamesIds
      )
      return this
    }

    return this.selectHealthChecksTemplatesNames(
      this.healthChecksTemplatesNamesIds
    )
  }

  /**
   * Unselect all healthchecknames.
   */
  @action
  unselectAllHealthChecksTemplatesNames(): this {
    Array.from(this.selectedHealthChecksTemplatesNames.keys()).forEach(
      treeId => {
        this.$selectedHealthChecksTemplatesNames.set(treeId, false)
      }
    )

    return this
  }

  /**
   * Select or unselect some healthchecknames.
   */
  @action
  selectHealthChecksTemplatesNames(
    healthChecksTemplatesNamesIds: string[]
  ): this {
    Array.from(this.selectedHealthChecksTemplatesNames.keys())
      .map(treeId => String(treeId))
      .forEach(healthCheckNameId => {
        const isSelected =
          healthChecksTemplatesNamesIds.indexOf(healthCheckNameId) !== -1
        const treeId = String(healthCheckNameId)

        this.$selectedHealthChecksTemplatesNames.set(treeId, isSelected)
      })

    return this
  }

  /**
   * Toggle the selection of one healthcheckname.
   */
  @action
  toggleSelectHealthChecksTemplatesName(
    healthChecksTemplatesNameId: string
  ): this {
    const entityHealthCheckName = this.healthChecksTemplatesNamesIds.find(
      id => id === healthChecksTemplatesNameId
    )

    if (!entityHealthCheckName) {
      return this
    }

    const treeId = String(healthChecksTemplatesNameId)
    const selected = this.$selectedHealthChecksTemplatesNames.get(treeId)

    this.$selectedHealthChecksTemplatesNames.set(treeId, !selected)

    return this
  }

  /**
   * Set all health checks templates names.
   */
  @action
  setAllHealthChecksTemplatesNames(
    allHealthChecksTemplatesNames: EntityHealthChecksTemplatesName[]
  ): this {
    this.$allHealthChecksTemplatesNames.set(allHealthChecksTemplatesNames)

    return this
  }

  /**
   * Set mode.
   */
  @action
  setMode(mode: InputHealthChecksMode): this {
    this.$mode.set(mode)
    this.setHealthChecksTemplatesNames(this.filteredHealthChecksTemplatesNames)

    return this
  }

  /**
   * Expand or collapsed health check type.
   */
  @action
  toggleExpandHealthCheckType(
    healthCheckCodenameKeys: HealthCheckCodenameKey[]
  ): this {
    this.$expandedHealthCheckTypes.replace(healthCheckCodenameKeys)

    return this
  }

  /**
   * Expand or collapsed all health check types.
   */
  @action
  expandAllHealthCheckTypes(): this {
    this.$expandedHealthCheckTypes.replace(this.healthCheckCodenameKeys)

    return this
  }

  /**
   * Collapse all health check types.
   */
  @action
  collapseAllHealthCheckTypes(): this {
    this.$expandedHealthCheckTypes.clear()

    return this
  }

  /* Computed */

  /**
   * Return true if submit is allowed on empty selection
   */
  @computed
  get isDrawerSelectionSubmitEnabled(): boolean {
    return (
      this.selectedHealthChecksTemplatesNamesIds.length > 0 ||
      (this.options.emptySelectionAllowed ?? false)
    )
  }

  /**
   * Return filtered healthchecknames as a map.
   */
  @computed
  get healthChecksTemplatesNames(): Map<
    string,
    EntityHealthChecksTemplatesName
  > {
    return toJS(this.$healthChecksTemplatesNames)
  }

  /**
   * Return selected healthchecknames as map.
   */
  @computed
  get selectedHealthChecksTemplatesNames(): Map<TreeId, boolean> {
    return toJS(this.$selectedHealthChecksTemplatesNames)
  }

  /**
   * Return selected healthchecknames as an array of number.
   */
  @computed
  get selectedHealthChecksTemplatesNamesIds(): string[] {
    return Array.from(this.selectedHealthChecksTemplatesNames.entries())
      .filter(([, selected]) => selected === true)
      .map(([key]) => String(key))
  }

  /**
   * Return all the healthcheckname ids.
   */
  @computed
  get healthChecksTemplatesNamesIds(): string[] {
    return Array.from(this.healthChecksTemplatesNames.keys())
  }

  /**
   * Return all the healthcheckname that match the current search value.
   */
  @computed
  get searchedHealthChecksTemplatesNames(): EntityHealthChecksTemplatesName[] {
    return Array.from(this.healthChecksTemplatesNames.values()).filter(
      entityHealthCheckName => {
        const regexp = this.storeInputSearch.transformedSearchValueAsRegexp

        return regexp.test(
          entityHealthCheckName.getPropertyAsString('healthCheckLocalizedName')
        )
      }
    )
  }

  /**
   * Return all the healthcheckname ids that match the current search value.
   */
  @computed
  get searchedHealthChecksTemplatesNamesIds(): string[] {
    return this.searchedHealthChecksTemplatesNames.map(
      healthChecksTemplatesName =>
        healthChecksTemplatesName.getPropertyAsString('id')
    )
  }

  /**
   * Return all the healthcheckname that match the current search value when types are displayed.
   */
  @computed
  get searchedHealthChecksTemplatesNamesWithExpand(): EntityHealthChecksTemplatesName[] {
    const translate = this.storeRoot.appTranslator.bindOptions({
      namespaces: ['HealthCheck.Filter']
    })

    return Array.from(this.healthChecksTemplatesNamesByType)
      .map(([healthCheckCodenameKey, healthChecksTemplatesNames]) => {
        return healthChecksTemplatesNames.filter(entityHealthCheckName => {
          const regexp = this.storeInputSearch.transformedSearchValueAsRegexp

          return (
            regexp.test(translate(healthCheckCodenameKey)) ||
            regexp.test(
              entityHealthCheckName.getPropertyAsString(
                'healthCheckLocalizedName'
              )
            )
          )
        })
      })
      .reduce((a, b) => a.concat(b), [])
  }

  /**
   * Return all the healthcheckname ids that match the current search value when types are displayed.
   */
  @computed
  get searchedHealthChecksTemplatesNamesIdsWithExpand(): string[] {
    return this.searchedHealthChecksTemplatesNamesWithExpand.map(
      healthChecksTemplatesName =>
        healthChecksTemplatesName.getPropertyAsString('id')
    )
  }

  /**
   * Return the expanded health check types (of the tree).
   */
  @computed
  get expandedHealthCheckTypes(): Set<string> {
    return toJS(this.$expandedHealthCheckTypes)
  }

  /**
   * Return true if some health check types are expanded.
   */
  @computed
  get isSomeHealthCheckTypesExpanded(): boolean {
    if (this.isAllHealthCheckTypesExpanded) {
      return false
    }

    return this.$expandedHealthCheckTypes.size > 0
  }

  /**
   * Return true if all health check types are expanded.
   */
  @computed
  get isAllHealthCheckTypesExpanded(): boolean {
    return (
      this.healthCheckCodenameKeys.length ===
      this.$expandedHealthCheckTypes.size
    )
  }

  /**
   * Return true if some healthchecknames are selected.
   */
  @computed
  get isSomeHealthChecksTemplatesNamesSelected(): boolean {
    if (this.isAllHealthChecksTemplatesNamesSelected) {
      return false
    }

    return Array.from(this.selectedHealthChecksTemplatesNames.values()).some(
      selected => selected === true
    )
  }

  /**
   * Return true if all or searches healthchecknames are selected.
   */
  @computed
  get isAllHealthChecksTemplatesNamesSelected(): boolean {
    if (this.storeInputSearch.hasSearchValue) {
      return Array.from(this.selectedHealthChecksTemplatesNames.entries())
        .filter(([treeId]) => {
          return (
            this.searchedHealthChecksTemplatesNamesIds.indexOf(
              String(treeId)
            ) !== -1
          )
        })
        .every(([, selected]) => selected === true)
    }

    return Array.from(this.selectedHealthChecksTemplatesNames.values()).every(
      selected => selected === true
    )
  }

  /**
   * Return true if all or searches healthchecknames are selected when types are displayed.
   */
  @computed
  get isAllHealthChecksTemplatesNamesSelectedWithExpand(): boolean {
    if (this.storeInputSearch.hasSearchValue) {
      return Array.from(this.selectedHealthChecksTemplatesNames.entries())
        .filter(([treeId]) => {
          return (
            this.searchedHealthChecksTemplatesNamesIdsWithExpand.indexOf(
              String(treeId)
            ) !== -1
          )
        })
        .every(([, selected]) => selected === true)
    }

    return Array.from(this.selectedHealthChecksTemplatesNames.values()).every(
      selected => selected === true
    )
  }

  /**
   * Return mode.
   */
  @computed
  get mode(): InputHealthChecksMode {
    return this.$mode.get()
  }

  /**
   * Return filtered health check codename keys.
   */
  @computed
  get healthCheckCodenameKeys(): HealthCheckCodenameKey[] {
    return Object.values(HealthCheckCodenameKey).filter(
      healthCheckCodenameKey =>
        this.healthChecksTemplatesNamesIds.find(healthChecksTemplatesName =>
          this.isHealthCheckTemplateNameIdInType(
            healthChecksTemplatesName,
            healthCheckCodenameKey
          )
        )
    )
  }

  /**
   * Return a map of health check template names by type.
   */
  @computed
  get healthChecksTemplatesNamesByType(): Map<
    HealthCheckCodenameKey,
    EntityHealthChecksTemplatesName[]
  > {
    const map = new Map<
      HealthCheckCodenameKey,
      EntityHealthChecksTemplatesName[]
    >()

    this.healthCheckCodenameKeys.forEach(healthCheckCodenameKey =>
      map.set(
        healthCheckCodenameKey,
        Array.from(this.healthChecksTemplatesNames.values()).filter(
          healthChecksTemplatesName =>
            this.isHealthCheckTemplateNameIdInType(
              healthChecksTemplatesName.getPropertyAsString('id'),
              healthCheckCodenameKey
            )
        )
      )
    )

    return map
  }

  /**
   * Return a map of health check template names ids by type.
   */
  @computed
  get healthChecksTemplatesNamesIdsByType(): Map<
    HealthCheckCodenameKey,
    string[]
  > {
    const map = new Map<HealthCheckCodenameKey, string[]>()

    this.healthChecksTemplatesNamesByType.forEach(
      (healthChecksTemplatesNames, healthCheckCodenameKey) => {
        const ids = healthChecksTemplatesNames.map(healthChecksTemplatesNames =>
          healthChecksTemplatesNames.getPropertyAsString('id')
        )

        map.set(healthCheckCodenameKey, ids)
      }
    )

    return map
  }

  /**
   * Return a map of colors by health check type.
   */
  @computed
  get healthCheckTypesColorScheme(): Map<HealthCheckCodenameKey, string> {
    const colorScheme = getHealthCheckNamesColorScheme(
      this.healthCheckCodenameKeys.length
    )

    const map = new Map<HealthCheckCodenameKey, string>()

    this.healthCheckCodenameKeys.forEach((healthCheckCodenameKey, i) =>
      map.set(healthCheckCodenameKey, colorScheme[i])
    )

    return map
  }

  /**
   * Return all health checks templates names.
   */
  @computed
  private get allHealthChecksTemplatesNames(): Maybe<
    EntityHealthChecksTemplatesName[]
  > {
    return this.$allHealthChecksTemplatesNames.get()
  }

  /**
   * Return filtered health checks templates names.
   */
  @computed
  private get filteredHealthChecksTemplatesNames(): EntityHealthChecksTemplatesName[] {
    if (!this.allHealthChecksTemplatesNames) {
      return []
    }

    switch (this.mode) {
      case InputHealthChecksMode.domainStatus:
        return this.allHealthChecksTemplatesNames.filter(name =>
          this.isHealthCheckTemplateNameIdInType(
            name.getPropertyAsString('codename'),
            HealthCheckCodenameKey.domainStatus
          )
        )

      case InputHealthChecksMode.platformStatus:
        return this.allHealthChecksTemplatesNames.filter(name =>
          this.isHealthCheckTemplateNameIdInType(
            name.getPropertyAsString('codename'),
            HealthCheckCodenameKey.platformStatus
          )
        )

      case InputHealthChecksMode.alerts:
        return this.allHealthChecksTemplatesNames.filter(name =>
          name.getPropertyAsBoolean('alert')
        )

      default:
        assertUnreachableCase(this.mode)
    }
  }
}
