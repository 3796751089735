import type { IGraphQLQuery } from '@libs/graphQL/types'
import type {
  Mutation,
  UpdateTenableCloudApiTokenMutationArgs
} from '../typeDefs/types'

export type MutationUpdateTenableCloudApiToken = IGraphQLQuery<
  UpdateTenableCloudApiTokenMutationArgs,
  Pick<Mutation, 'updateTenableCloudApiToken'>
>
export const mutationUpdateTenableCloudApiToken = `
  mutation mutationUpdateTenableCloudApiToken(
    $tenableCloudApiToken: InputUpdateTenableCloudApiToken!
  ) {
    updateTenableCloudApiToken(
      tenableCloudApiToken: $tenableCloudApiToken
    )
  }
`
