import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import type { StoreIoE } from '@app/stores'

/**
 * When entering, load the checker compliance status.
 */
export const handleCheckerIdentityDetailsOnLoad =
  (storeIoE: StoreIoE) => (checkerCodename: GenericCheckerCodename) => () => {
    return Promise.resolve()
      .then(() => {
        return storeIoE.storeIndicator.fetchCheckerIdentityComplianceStatus()
      })
      .then(() => {
        const checker = storeIoE.checkersIdentity.get(checkerCodename)
        storeIoE.storeIndicator.setCheckerIdentity(checker ?? null)
      })
  }

/**
 * When leaving, reset findings.
 */
export const handleCheckerIdentityDetailsOnUnLoad =
  (storeIoE: StoreIoE) => () => {
    const { storeIndicatorFindings } = storeIoE.storeIndicator
    storeIndicatorFindings.reset()
  }
