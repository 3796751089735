import type { Maybe } from '@@types/helpers'
import { LabelChecker } from '@app/components-legacy/Label'
import { LabelCheckerSize } from '@app/components-legacy/Label/LabelChecker/types'
import type { EntityChecker } from '@app/entities'
import type EntityAdObjectAsAttributes from '@app/entities/EntityAdObject/EntityAdObjectAsAttribute'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { isDefined } from '@libs/isDefined'
import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface ILabelDevianceLabelCheckerProps {
  adObject?: Maybe<EntityAdObjectAsAttributes>
  className?: string
  entityChecker: EntityChecker
}

const StyledLabelChecker = styled(LabelChecker)`
  /* Override margin-bottom of LabelChecker */
  margin-bottom: 0;
`

const LabelDevianceLabelChecker: React.FC<
  ILabelDevianceLabelCheckerProps
> = props => {
  const appRouter = useAppRouter()

  const { storeIoE } = useStores()

  const { storeInputExpression } =
    storeIoE.storeIndicator.storeIndicatorDeviantObjects

  const criticity = props.entityChecker.genericCriticity
  const codename = props.entityChecker.getPropertyAsString('codename')
  const label = props.entityChecker.getPropertyAsString('name')

  const checkerId = props.entityChecker.id
  const checkerCodename = props.entityChecker.codename

  const onCheckerClick = () => {
    const id = props.adObject?.getId()

    if (!id) {
      return
    }

    storeInputExpression.setEntry(`id:"${id}"`)
    storeInputExpression.validateEntry()
  }

  const getContent = () => {
    const url =
      checkerId &&
      checkerCodename &&
      appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.IoE_Details_DeviantObjects,
        parameters: {
          checkerId,
          checkerCodename
        }
      })

    const content = (
      <StyledLabelChecker
        criticity={criticity}
        codename={codename}
        name={label}
        size={LabelCheckerSize.fullWidth}
        hoverable={isDefined(url)}
        small
      />
    )

    if (url) {
      return (
        <Link to={url} onClick={onCheckerClick}>
          {content}
        </Link>
      )
    }

    return content
  }

  return (
    <div data-name="LabelDevianceLabelChecker" className={props.className}>
      {getContent()}
    </div>
  )
}

export default styled(LabelDevianceLabelChecker)`
  /* Set same width that computed width when used into a ContainerFormField container */
  width: 375px;
`
