import { AssetImg } from '@app/components/Asset/AssetImg'
import {
  LabelReportSubTitle,
  LabelReportTitle
} from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks'
import * as React from 'react'
import styled from 'styled-components'

interface IPdfHeaderTitleProps {
  className?: string
}

const StyledLabelReportSubTitle = styled(LabelReportSubTitle)`
  margin: 25px 0;
`

const StyledLabelReportTitle = styled(LabelReportTitle)`
  background: rgb(255, 255, 255);
  /* Add a radial background to improve title lisibility */
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
`

const PdfHeaderTitle: React.FC<IPdfHeaderTitleProps> = props => {
  const translate = useAppTranslator({ namespaces: ['IoA.BoardPDF'] })

  return (
    <div className={props.className}>
      <>
        <AssetImg
          imageName="logo-tenable-ad-color"
          width={200}
          fileType="png"
        />

        <StyledLabelReportSubTitle size="title">
          {translate('Consolidated report')}
        </StyledLabelReportSubTitle>

        <div>
          <StyledLabelReportTitle size="title" outline>
            {translate('Indicators of')}
          </StyledLabelReportTitle>

          <StyledLabelReportTitle size="title">
            {translate('Attack')}
          </StyledLabelReportTitle>
        </div>
      </>
    </div>
  )
}

export default styled(PdfHeaderTitle)`
  text-align: center;
  line-height: 80px;
  margin-bottom: 100px;
  padding-top: 50px;
  background: white url('/w/assets/images/pdf-header.svg') no-repeat left top;
  background-position: center center;
  width: 100%;
  height: 618px;
`
