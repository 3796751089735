// We are customizing the style of Pendo icon and bubble for specific T.ie needs
export function usePendoCustomStyles() {
  const interval = setInterval(() => {
    if (
      !window.pendo ||
      !window.pendo.Sizzle ||
      !window.pendo.location ||
      !window.pendo.location.setUrl
    ) {
      return
    }

    /* Here we need to tell Pendo to stop detecting the browser URL. This is to avoid Pendo overriding again and again our custom style
       when Kapteyn url changes. See https://agent.pendo.io/advanced/location/#:~:text=The%20Pendo%20agent%20stops%20detecting,function%20with%20the%20desired%20URL
    */
    window.pendo.location.setUrl(window.location.origin)

    const bubbleElements = window.pendo.Sizzle(
      'tenable-global-header::shadow tenable-universal-header-help-action::shadow #help-menu-button::shadow .tenable-floating-button .pendo-resource-center-badge-notification-bubble'
    )

    const bubbleTextElements = window.pendo.Sizzle(
      'tenable-global-header::shadow tenable-universal-header-help-action::shadow #help-menu-button::shadow .tenable-floating-button .pendo-resource-center-badge-notification-bubble .pendo-notification-bubble-unread-count'
    )

    if (
      !Array.isArray(bubbleElements) ||
      bubbleElements.length === 0 ||
      !Array.isArray(bubbleTextElements) ||
      bubbleTextElements.length === 0
    ) {
      return
    }

    bubbleElements[0].style =
      'position: absolute; top: -2px; left: -15px; width: 25px; height: 15.7px; opacity: 1; transform: scale(1); transition: all 0.3s ease-in-out 0s; background-color: rgb(69, 117, 249); font-size: 10px !important; font-weight: 500; text-align: center; border-radius: 5px; line-height: 0px; user-select: none;'

    bubbleTextElements[0].style =
      'position: absolute; top: 100px; left: 10px; color: #ffffff; height: 20px; top: -3px; font-size: 10px !important; font-family: "Encode Sans Semi Expanded"; display: grid; align-items: center;'

    clearInterval(interval)
  }, 10)
}
