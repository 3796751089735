import { LabelDirectoriesTree } from '@app/components-legacy/Label'
import LabelDirectoryTag from '@app/components-legacy/Label/LabelDirectoryTag'
import type { IDirectoryConfigurationChanged } from '@app/entities/EntityUserLog'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import React from 'react'

const UserLogDirectory = (props: IDirectoryConfigurationChanged) => {
  const { storeActivityLogs, storeInfrastructures } = useStores()
  const { directoryId, directoryName } = props

  if (!directoryId || !directoryName) {
    return null
  }

  const color = storeActivityLogs.directoryColors.get(directoryId)

  return (
    <LabelDirectoryTag
      label={directoryName}
      color={color ?? consts.colorBlack001}
      maxWidth={200}
      tooltipLabel={
        <LabelDirectoriesTree
          storeInfrastructures={storeInfrastructures}
          directoryIds={[directoryId]}
        />
      }
    />
  )
}

export default UserLogDirectory
