import {
  ContainerContent,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { ProfileFieldName } from '@app/pages/Management/AccountsPage/Profiles/ProfilesCreatePage/types'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import { Input } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import ProfileCreateFormSelect from './ProfileCreateFormSelect'

interface IProfileCreateFormMainFieldsProps {}

const ProfileCreateFormMainFields: React.FC<
  IProfileCreateFormMainFieldsProps
> = () => {
  const translate = useAppTranslator({
    namespaces: ['Titles', 'Buttons', 'Management.Accounts.Profiles.Creation']
  })
  const { storeManagementProfiles } = useStores()

  function getFields(): IField[] {
    const fields: IField[] = [
      {
        name: 'action',
        label: translate('Action'),
        control: <ProfileCreateFormSelect />
      },
      {
        name: 'profileName',
        label: translate('Name of the profile'),
        errors: storeManagementProfiles.storeFormCreation.field(
          ProfileFieldName.name
        ).errors,
        description: translate(
          'The profile name may only contain alphanumeric characters or underscores'
        ),
        control: (
          <Input
            type="text"
            name="name"
            onChange={onInputChange(storeManagementProfiles.storeFormCreation)(
              ProfileFieldName.name
            )}
            value={storeManagementProfiles.storeFormCreation.getFieldValueAsString(
              ProfileFieldName.name
            )}
            autoComplete="off"
          />
        )
      }
    ]

    return fields
  }

  return (
    <ContainerContent title={translate('Main information')}>
      <ContainerForm fields={getFields()} />
    </ContainerContent>
  )
}

export default observer(ProfileCreateFormMainFields)
