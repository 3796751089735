import {
  ContainerContent,
  ContainerFlex,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import { LabelMissingData } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import { CheckerDetailsPageContext } from '../context'
import { canReadIoERecommandations } from '../permissions'
import Documents from './Documents'

interface IDetailsRecommendationsPageProps {}

const DetailsRecommendationsPage: React.FC<
  IDetailsRecommendationsPageProps
> = props => {
  const parameters = React.useContext(CheckerDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: ['IoE.Details.Recommendations']
  })

  const { storeIoE } = useStores()

  if (!parameters) {
    return null
  }

  const checker = storeIoE.checkers.get(parameters.checkerCodename)

  if (!checker) {
    return <LabelMissingData />
  }

  const recommandation = checker.getRecommendation()

  if (!recommandation) {
    return <LabelMissingData />
  }

  return (
    <ContainerContent
      rbacCapabilityCheck={canReadIoERecommandations(parameters.checkerId)}
    >
      <ContainerFlex
        name="DetailsVulnerabilityDetailsPage"
        direction="column"
        items={[
          <ContainerContent title={translate('Executive summary')}>
            <ContainerHTMLContent labelledBy="executiveSummary">
              {recommandation.getPropertyAsString(
                'execSummary',
                'Information not available'
              )}
            </ContainerHTMLContent>
          </ContainerContent>,

          <ContainerContent title={translate('Details')}>
            <ContainerHTMLContent labelledBy="details">
              {recommandation.getPropertyAsString(
                'detail',
                'Information not available'
              )}
            </ContainerHTMLContent>
          </ContainerContent>,

          <Documents />
        ]}
        spaced
        wrapItems
        borderPadding="default"
      />
    </ContainerContent>
  )
}

export default DetailsRecommendationsPage
