import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import { BrandColorV2, FontColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

const Color1Path = styled.path`
  fill: ${FontColorV2.primary};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${consts.colorWhite};
  }
`

const Color2Path = styled.path`
  stroke: ${BrandColorV2.decorativeGray};
  stroke-width: 1.5;

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    stroke: ${consts.colorFadedBlueNewDesign};
  }
`

interface IIconTopologyProps extends IIconProps {}

const IconTopologyLegacy: React.FC<IIconTopologyProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Color2Path d="M9.52379 19.0476C13.907 19.0476 17.4603 15.4943 17.4603 11.1111C17.4603 6.7279 13.907 3.17461 9.52379 3.17461C5.14058 3.17461 1.58728 6.7279 1.58728 11.1111C1.58728 15.4943 5.14058 19.0476 9.52379 19.0476Z" />
      <Color1Path
        d="M9.52385 6.34921C11.2771 6.34921 12.6984 4.92789 12.6984 3.1746C12.6984 1.42132 11.2771 0 9.52385 0C7.77056 0 6.34924 1.42132 6.34924 3.1746C6.34924 4.92789 7.77056 6.34921 9.52385 6.34921Z"
        fill="#676EA1"
      />
      <Color1Path
        d="M3.1746 19.0476C4.92789 19.0476 6.34921 17.6263 6.34921 15.873C6.34921 14.1197 4.92789 12.6984 3.1746 12.6984C1.42132 12.6984 0 14.1197 0 15.873C0 17.6263 1.42132 19.0476 3.1746 19.0476Z"
        fill="#676EA1"
      />
      <Color1Path
        d="M15.873 19.0476C17.6263 19.0476 19.0476 17.6263 19.0476 15.873C19.0476 14.1197 17.6263 12.6984 15.873 12.6984C14.1197 12.6984 12.6984 14.1197 12.6984 15.873C12.6984 17.6263 14.1197 19.0476 15.873 19.0476Z"
        fill="#676EA1"
      />
    </svg>
  )
}

export default IconTopologyLegacy
