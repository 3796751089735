import {
  createEntities,
  EntityAttacksStat,
  EntityDashboardWidgetOptionsSerieStatAttackCounts
} from '@app/entities'
import type { IIoABoardPDFRouteDefinition } from '@app/routes'
import { AppRouteName } from '@app/routes'
import type { StoreRoot } from '@app/stores'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreBase from '@app/stores/StoreBase'
import { CriticityValuesOrdered } from '@libs/criticity'
import { DateFormat } from '@libs/dates/formatDate'
import { addDuration } from '@libs/dates/helpers'
import { ensureArray } from '@libs/ensureArray'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { addSetValueToMap } from '@libs/setValueToMap'
import type { QueryAttacksStatsCounts } from '@server/graphql/queries/ioa'
import { queryAttacksStatsCounts } from '@server/graphql/queries/ioa'
import type {
  AttacksStatsCountsQueryArgs,
  DashboardWidgetOptionsSerieStatAttack,
  DashboardWidgetOptionsSerieStatAttackCounts,
  Maybe
} from '@server/graphql/typeDefs/types'
import {
  AttacksSummaryPeriod,
  Criticity,
  Language
} from '@server/graphql/typeDefs/types'
import { last, sortBy, values } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import * as moment from 'moment-timezone'
import type { PickerPanelProps } from 'rc-picker/lib/PickerPanel'
import { DEFAULT_DATE_START } from '../consts'
import type {
  IStoreTimelineOptions,
  ITimelineAttackPoint,
  TimelineAttackPoints
} from './types'
import { TimelineInterval } from './types'

export default class StoreTimeline extends StoreBase<IStoreTimelineOptions> {
  public storeFlagsTooltip = new StoreFlags(this.storeRoot)

  /* Observables */

  // period of the timeline (dateStart to dateEnd)
  private $period = observable.box<AttacksSummaryPeriod>(
    this.options.attacksSummaryPeriod
  )

  // selected date (via the DatePicker)
  private $dateStart = observable.box<string>(DEFAULT_DATE_START)

  // stats for each points of the timeline
  private $attacksStats = observable.box<Maybe<EntityAttacksStat[]>>(null)

  // counts for the tooltip (the counts of attacks during an interval of the selected period)
  private $attacksCounts =
    observable.box<Maybe<EntityDashboardWidgetOptionsSerieStatAttackCounts[]>>(
      null
    )

  constructor(storeRoot: StoreRoot, options: IStoreTimelineOptions) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch attacksStats for the tooltip when clicking on a pill of the timeline.
   */
  public fetchAttacksCounts(_args: AttacksStatsCountsQueryArgs): Promise<void> {
    this.storeFlagsTooltip.loading()

    const args: AttacksStatsCountsQueryArgs = {
      ..._args,
      profileId: this.storeRoot.stores.storeAuthentication.currentProfileId
    }

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryAttacksStatsCounts>(queryAttacksStatsCounts, args)
      })
      .then(({ attacksStatsCounts }) => {
        this.setAttacksCounts(attacksStatsCounts)
        this.storeFlagsTooltip.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsTooltip))
  }

  /* Actions */

  @action
  reset(): this {
    this.storeFlagsTooltip.reset()

    this.$dateStart.set(DEFAULT_DATE_START)

    this.$attacksStats.set(null)
    this.$attacksCounts.set(null)

    return this
  }

  /**
   * Set the selected period.
   */
  @action
  setPeriod(period: AttacksSummaryPeriod): this {
    this.$period.set(period)
    return this
  }

  /**
   * Set dateStart.
   */
  @action
  setDateStart(dateStart: string): this {
    this.$dateStart.set(dateStart)
    return this
  }

  /**
   * Set attacksStats.
   */
  @action
  setAttacksStats(attacksStats: DashboardWidgetOptionsSerieStatAttack[]): this {
    const attacksStatEntities = createEntities<
      DashboardWidgetOptionsSerieStatAttack,
      EntityAttacksStat
    >(EntityAttacksStat, attacksStats)

    this.$attacksStats.set(attacksStatEntities)
    return this
  }

  /**
   * Set attacks counts for the tooltip.
   */
  @action
  setAttacksCounts(
    attacksCounts: DashboardWidgetOptionsSerieStatAttackCounts[]
  ): this {
    const attacksStatCountsEntities = createEntities<
      DashboardWidgetOptionsSerieStatAttackCounts,
      EntityDashboardWidgetOptionsSerieStatAttackCounts
    >(EntityDashboardWidgetOptionsSerieStatAttackCounts, attacksCounts)

    this.$attacksCounts.set(attacksStatCountsEntities)

    return this
  }

  /* Computed */

  /**
   * Return the current timeline period.
   */
  @computed
  get attacksSummaryPeriod(): AttacksSummaryPeriod {
    return this.$period.get()
  }

  /**
   * Return the current dateStart of the timeline.
   */
  @computed
  get dateStart(): string {
    if (
      this.storeRoot.appRouter.getCurrentRouteName() ===
      AppRouteName.IoA_Board_PDF
    ) {
      const queryStringParameters =
        this.storeRoot.appRouter.getCurrentRouteQueryStringParameters<
          IIoABoardPDFRouteDefinition['queryStringParameters']
        >()

      if (queryStringParameters?.boardFilters?.dateStart) {
        return queryStringParameters.boardFilters.dateStart
      }
    }

    return moment
      .utc(this.$dateStart.get())
      .local()
      .startOf(this.$period.get())
      .toISOString()
  }

  /**
   * Return the computed dateEnd of the timeline according to the interval.
   */
  @computed
  get dateEnd(): string {
    if (
      this.storeRoot.appRouter.getCurrentRouteName() ===
      AppRouteName.IoA_Board_PDF
    ) {
      const queryStringParameters =
        this.storeRoot.appRouter.getCurrentRouteQueryStringParameters<
          IIoABoardPDFRouteDefinition['queryStringParameters']
        >()
      if (queryStringParameters?.boardFilters?.dateEnd) {
        return queryStringParameters.boardFilters.dateEnd
      }
    }

    return moment
      .utc(this.$dateStart.get())
      .local()
      .endOf(this.$period.get())
      .add(1, 'second') // Move to next moment (hour, day, year)
      .startOf(this.$period.get()) // Return to start of period
      .toISOString()
  }

  /**
   * Return the interval according to the period.
   */
  @computed
  get interval(): TimelineInterval {
    switch (this.attacksSummaryPeriod) {
      case AttacksSummaryPeriod.Hour:
        return TimelineInterval.Every5Minutes

      case AttacksSummaryPeriod.Day:
        return TimelineInterval.EveryHour

      case AttacksSummaryPeriod.Month:
        return TimelineInterval.EveryDay

      case AttacksSummaryPeriod.Year:
        return TimelineInterval.EveryMonth
    }
  }

  /**
   * Return the Antd's DatePicker picker props value according to the period.
   */
  @computed
  get datePickerValue(): PickerPanelProps<any>['picker'] {
    switch (this.attacksSummaryPeriod) {
      case AttacksSummaryPeriod.Hour:
        return 'date'

      case AttacksSummaryPeriod.Day:
        return 'date'

      case AttacksSummaryPeriod.Month:
        return 'month'

      case AttacksSummaryPeriod.Year:
        return 'year'
    }
  }

  /**
   * Return the date format for the date picker according to the period.
   */
  @computed
  get datePickerFormat(): string {
    switch (this.attacksSummaryPeriod) {
      case AttacksSummaryPeriod.Hour:
        return `${DateFormat.short}, ${DateFormat.hoursMinutes}`

      case AttacksSummaryPeriod.Day:
        return DateFormat.short

      case AttacksSummaryPeriod.Month: {
        if (this.storeRoot.appTranslator.language === Language.JaJp) {
          return `${DateFormat.year}年${DateFormat.month}`
        }
        return `${DateFormat.month} ${DateFormat.year}`
      }

      case AttacksSummaryPeriod.Year: {
        if (this.storeRoot.appTranslator.language === Language.JaJp) {
          return `${DateFormat.year}年`
        }
        return DateFormat.year
      }
    }
  }

  /**
   * Return the date format for ticks according to the period.
   */
  @computed
  get tickDateFormat(): DateFormat {
    switch (this.attacksSummaryPeriod) {
      case AttacksSummaryPeriod.Hour:
        return DateFormat.hoursMinutes

      case AttacksSummaryPeriod.Day:
        return DateFormat.hoursMinutes

      case AttacksSummaryPeriod.Month:
        return DateFormat.dayNumber

      case AttacksSummaryPeriod.Year:
        return DateFormat.shortMonth
    }
  }

  /**
   * Return the date format for the tooltip according to the period.
   */
  @computed
  get tooltipDateFormat(): DateFormat {
    const isFrench = this.storeRoot.appTranslator.language === Language.FrFr

    switch (this.attacksSummaryPeriod) {
      case AttacksSummaryPeriod.Hour:
      case AttacksSummaryPeriod.Day:
        return isFrench ? DateFormat.frenchFullDate : DateFormat.englishFullDate

      case AttacksSummaryPeriod.Month:
        return isFrench
          ? DateFormat.frenchDateOfTheDay
          : DateFormat.englishDateOfTheDay

      case AttacksSummaryPeriod.Year:
        return isFrench
          ? DateFormat.frenchMonthYear
          : DateFormat.englishMonthYear
    }
  }

  /**
   * Return a formatted attacks summary period mainly used for the export
   * Date is returned in timezone time if provided
   */
  formatAttacksSummaryPeriodWithTimezone(
    useUtc: boolean,
    timezone: string = moment.tz.guess()
  ): string {
    const isFrench = this.storeRoot.appTranslator.language === Language.FrFr
    const dateStart = useUtc
      ? moment.utc(this.dateStart)
      : moment.tz(this.dateStart, timezone)
    const dateEnd = useUtc
      ? moment.utc(this.dateEnd)
      : moment.tz(this.dateEnd, timezone)

    return `${dateStart
      .format(isFrench ? DateFormat.frenchFullDate : DateFormat.englishFullDate)
      .toUpperCase()} => ${dateEnd.format(
      isFrench ? DateFormat.frenchFullDate : DateFormat.englishFullDate
    )}`
  }

  /**
   * Return the dateStart when going back in the Timeline.
   */
  @computed
  get previousDateStart(): string {
    return moment(this.dateStart)
      .subtract(1, this.attacksSummaryPeriod)
      .toISOString()
  }

  /**
   * Return the dateStart when going forward in the Timeline.
   */
  @computed
  get nextDateStart(): string {
    return this.dateEnd
  }

  /**
   * Return entities that represent the current attacks stats.
   */
  @computed
  get attacksStats(): EntityAttacksStat[] {
    return ensureArray(this.$attacksStats.get())
  }

  /**
   * Return entities that represent the counts of a serie.
   */
  @computed
  get attacksStatsCountsForTooltip(): EntityDashboardWidgetOptionsSerieStatAttackCounts[] {
    return ensureArray(this.$attacksCounts.get())
  }

  /**
   * Return true if there is some attacks detected on the period.
   */
  @computed
  get hasAttacksDetected(): boolean {
    return ensureArray(this.$attacksStats.get()).some(stat => {
      return ensureArray(stat.data).some(point => point.count > 0)
    })
  }

  /**
   * Compute timeline points from a list of attacksStats.
   */
  @computed
  get attackPoints(): TimelineAttackPoints {
    const timelinePoints = new Map<string, Set<ITimelineAttackPoint>>()

    const criticities = values(CriticityValuesOrdered)

    this.attacksStats.forEach((stats, index) => {
      const currentCriticity = criticities[index]

      const points = ensureArray(stats.data)

      points.forEach(point => {
        const pointAttack: ITimelineAttackPoint = {
          criticity: currentCriticity,
          count: point.count
        }

        addSetValueToMap(timelinePoints, point.timestamp, pointAttack)
      })
    })

    // When the Influx's continuous query has not populated the data,
    // Equu is returning an empty array, leaving the timeline blank.
    // As a workaround, populate points manually here with count at 0 for each
    // "fake" point. (1)
    if (timelinePoints.size === 0) {
      this.getTickTimestamps.forEach(timestamp => {
        const pointAttack: ITimelineAttackPoint = {
          criticity: Criticity.Unknown,
          count: 0
        }

        addSetValueToMap(timelinePoints, String(timestamp), pointAttack)
      })
    }

    return timelinePoints
  }

  /**
   * Return timestamps (is ms) for the wanted period and according to the selected
   * interval when no point are returned from Equuleus (see (1)).
   */
  @computed
  get getTickTimestamps(): number[] {
    const dateTicks: number[] = []

    let dateTick = this.dateStart

    while (moment.utc(dateTick).isBefore(moment.utc(this.dateEnd))) {
      dateTicks.push(moment.utc(dateTick).unix() * 1000)

      const nextDateTick = addDuration(dateTick, this.interval)

      if (!nextDateTick) {
        break
      }

      dateTick = nextDateTick
    }

    return dateTicks
  }

  /**
   * Return the biggest attack count sum.
   */
  @computed
  get pillBiggestAttackCountSum(): number {
    const counts = Array.from(this.attackPoints.values()).map(points => {
      return Array.from(points.values()).reduce((acc, point) => {
        return acc + point.count
      }, 0)
    })

    return last(sortBy(counts)) ?? 0
  }
}
