import type { MaybeUndef } from '@@types/helpers'
import { ContainerFlex, ContainerForm } from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import type { CheckerOptionCodename } from '@app/entities/EntityGenericCheckerOption/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import type Field from '@app/stores/helpers/StoreForm/Field'
import { sortCheckerOptions } from '@app/stores/Management/StoreProfiles/helpers/sortCheckerOptions'
import type { TStoreProfileCheckerSerie } from '@app/stores/Management/StoreProfiles/StoreProfileCheckerSerie'
import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import ConfigurationSerieDirectories from './ConfigurationSerieDirectories'
import { getConfigurationFields } from './getConfigurationFields'

interface IConfigurationSerieFormProps {
  checkerCodename: GenericCheckerCodename
  storeProfileCheckerSerie: TStoreProfileCheckerSerie
}

const ConfigurationSerieForm: React.FC<
  IConfigurationSerieFormProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Profiles.Configuration']
  })

  const { storeManagementProfiles } = useStores()

  const getItems = () => {
    const configurationFields = getConfigurationFields(
      props.storeProfileCheckerSerie,
      storeManagementProfiles.storeProfileGenericCheckersSelected
        .storeInputSearchOptions,
      translate
    )(storeManagementProfiles.isCurrentProfileReadOnly)

    // Hackish loop to add the codename to the fields, that will allow to sort options
    const sortedSerieFields = configurationFields.map(field => {
      const formField = get(field, 'meta.field') as MaybeUndef<Field>

      return {
        ...field,
        codename: ((formField && formField.name) ||
          'Unknown option') as CheckerOptionCodename
      }
    })

    sortCheckerOptions(sortedSerieFields)

    const fields: IField[] = [
      {
        name: 'applyOn',
        label: translate('Apply on'),
        labelAlignItem: 'center',
        control: (
          <ConfigurationSerieDirectories
            storeProfileCheckerSerie={props.storeProfileCheckerSerie}
          />
        )
      },
      ...sortedSerieFields
    ]

    const items: React.ReactNode[] = []

    items.push(<ContainerForm fields={fields} />)

    return items
  }

  return (
    <ContainerFlex
      name={ConfigurationSerieForm.name}
      direction="column"
      items={getItems()}
      spaced
    />
  )
}

export default observer(ConfigurationSerieForm)
