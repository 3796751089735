import { IconSVG } from '@app/components-legacy/Icon'
import { LabelAlt, LabelSliced } from '@app/components-legacy/Label'
import { Tooltip } from 'antd'
import * as React from 'react'
import { ContainerFlex } from '../Container'

export interface ILabelTenantProps {
  className?: string
  style?: React.CSSProperties
  label: string
  maxLength?: number
  tooltipLabel?: React.ReactNode | string
  labelledBy?: string
}

const LabelTenant: React.FC<ILabelTenantProps> = props => {
  const renderLabel = () => {
    const maxLength = props.maxLength ?? 25

    if (props.tooltipLabel) {
      return (
        <Tooltip title={props.tooltipLabel}>
          <div>
            <LabelSliced maxLength={maxLength} value={props.label} />
          </div>
        </Tooltip>
      )
    }

    return <LabelSliced maxLength={maxLength} value={props.label} />
  }

  const Label = <LabelAlt>{renderLabel()}</LabelAlt>

  return (
    <ContainerFlex
      name="LabelTenant"
      labelledBy="tenant"
      className={props.className}
      style={props.style}
      items={[
        <IconSVG iconName="icons/azure-active-directory" size={16} />,
        Label
      ]}
      spaced
      spaceWidth="verySmall"
    />
  )
}

export default LabelTenant
