import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { FormWrapperSelect } from '@app/components-legacy/Form'
import {
  InputCriticity,
  InputMultiValues,
  InputSwitch
} from '@app/components-legacy/Input'
import { LabelAltError } from '@app/components-legacy/Label'
import { CheckerOptionCodenameEnum } from '@app/entities/EntityGenericCheckerOption/types'
import {
  onInputChange,
  onInputNumberChange,
  onMultiValuesChange,
  onMultiValuesCronInputChange,
  onSelectChange,
  onSwitchChange
} from '@app/stores/helpers/StoreForm/handlers'
import type { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import { isValidOptionAccordingToConfiguration } from '@app/stores/Management/StoreProfiles/helpers/buildCheckerOptionsForDraft'
import type { TStoreProfileCheckerSerie } from '@app/stores/Management/StoreProfiles/StoreProfileCheckerSerie'
import type { ICheckerOptionFieldMeta } from '@app/stores/Management/StoreProfiles/types'
import type { TranslateFn } from '@libs/i18n'
import { isDefined } from '@libs/isDefined'
import { ValueType } from '@libs/valueTypeParser/types'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { Input, InputNumber } from 'antd'
import React from 'react'
import ConfigurationSerieFormOMCGMAW from './ConfigurationSerieFormOMCGMAW'
import ConfigurationSerieFormOWCT from './ConfigurationSerieFormOWCT'

/**
 * Return the fields of a configuration of a checker.
 */
export const getConfigurationFields =
  (
    storeProfileCheckerSerie: TStoreProfileCheckerSerie,
    storeInputSearchOptions: StoreInputSearch,
    translate: TranslateFn
  ) =>
  (isReadonly: boolean): IField[] => {
    const {
      storeForm,
      storeProfileCheckerOptionOMCGMAW,
      storeProfileCheckerOptionOWCT,
      options,
      stagedFields
    } = storeProfileCheckerSerie

    return Array.from(options.allDirectoriesCheckerOptions.entries())
      .map(([checkerOptionCodename, defaultCheckerOption]) => {
        const serieCheckerOption =
          options.serieCheckerOptions.get(checkerOptionCodename) ||
          defaultCheckerOption

        const name = serieCheckerOption.getPropertyAsString('name')
        const description =
          serieCheckerOption.getPropertyAsString('description')
        const valueType = serieCheckerOption.valueType
        const isStaged = stagedFields.has(
          serieCheckerOption.getPropertyAsString('codename')
        )

        // do not display options that can't be override into refined configurations
        if (
          !isValidOptionAccordingToConfiguration(options, checkerOptionCodename)
        ) {
          return
        }

        // set some data in meta used to build mutations args
        const field = storeForm.field(checkerOptionCodename)

        switch (valueType) {
          case ValueType.string:
          case ValueType.sddl:
          case ValueType.regex:
          case ValueType.float: {
            return {
              name,
              label: name,
              description,
              errors: storeForm.field(checkerOptionCodename).errors,
              highlight: isStaged,
              control: (
                <Input
                  data-name={valueType}
                  value={storeForm.getFieldValueAsString(checkerOptionCodename)}
                  disabled={isReadonly}
                  onChange={onInputChange(storeForm)(checkerOptionCodename)}
                />
              ),
              meta: {
                field
              }
            }
          }

          case ValueType.integer: {
            // exception for the criticity selector
            const control =
              checkerOptionCodename ===
              CheckerOptionCodenameEnum.O_CRITICITY ? (
                <InputCriticity
                  criticityValue={storeForm.getFieldValueAsNumber(
                    checkerOptionCodename
                  )}
                  onChange={onSelectChange(storeForm)(checkerOptionCodename)}
                  selectProps={{
                    disabled: isReadonly
                  }}
                />
              ) : (
                <InputNumber
                  data-name={valueType}
                  min={0}
                  value={storeForm.getFieldValueAsNumber(checkerOptionCodename)}
                  disabled={isReadonly}
                  onChange={onInputNumberChange(storeForm)(
                    checkerOptionCodename
                  )}
                />
              )

            return {
              name,
              label: name,
              description,
              errors: storeForm.field(checkerOptionCodename).errors,
              highlight: isStaged,
              control,
              meta: {
                field
              }
            }
          }

          case ValueType.boolean: {
            return {
              name,
              label: name,
              description,
              errors: storeForm.field(checkerOptionCodename).errors,
              highlight: isStaged,
              control: (
                <InputSwitch
                  data-name={valueType}
                  labelledBy={checkerOptionCodename}
                  switchProps={{
                    checked:
                      storeForm.getFieldValueAsString(checkerOptionCodename) ===
                      'true',
                    disabled: isReadonly,
                    onChange: onSwitchChange(storeForm)(checkerOptionCodename)
                  }}
                />
              ),
              meta: {
                field
              }
            }
          }

          case ValueType.arrayCron:
            return {
              name,
              label: name,
              description,
              errors: storeForm.field(checkerOptionCodename).errors,
              highlight: isStaged,
              control: (
                <InputMultiValues
                  data-name={valueType}
                  name={checkerOptionCodename}
                  disabled={isReadonly}
                  onChange={onMultiValuesCronInputChange(storeForm)}
                  storeForm={storeForm}
                />
              ),
              meta: {
                field
              }
            }

          case ValueType.arrayString:
          case ValueType.arrayRegex:
          case ValueType.arrayInteger:
          case ValueType.arrayBoolean: {
            return {
              name,
              label: name,
              description,
              errors: storeForm.field(checkerOptionCodename).errors,
              highlight: isStaged,
              control: (
                <InputMultiValues
                  data-name={valueType}
                  name={checkerOptionCodename}
                  disabled={isReadonly}
                  onChange={onMultiValuesChange(storeForm)}
                  storeForm={storeForm}
                />
              ),
              meta: {
                field
              }
            }
          }

          case ValueType.arraySelect: {
            const fieldMeta = field.meta.get<ICheckerOptionFieldMeta>('meta')

            if (!fieldMeta) {
              return
            }

            if (!fieldMeta.arraySelectSelection) {
              return
            }

            const {
              value: { choices },
              translations
            } = fieldMeta.arraySelectSelection

            return {
              name,
              label: name,
              description,
              errors: storeForm.field(checkerOptionCodename).errors,
              highlight: isStaged,
              control: (
                <FormWrapperSelect
                  labelledBy={name}
                  selectProps={{
                    value: field.asString,
                    onChange: onSelectChange(storeForm)(checkerOptionCodename),
                    disabled: isReadonly
                  }}
                  selectOptions={choices.map((choice, i) => {
                    const translation =
                      (translations && translations[i]) || translate(choice)
                    return {
                      label: translation,
                      value: choice
                    }
                  })}
                />
              ),
              meta: {
                field
              }
            }
          }

          case ValueType.arrayObject: {
            switch (checkerOptionCodename) {
              case CheckerOptionCodenameEnum.O_MAX_CUSTOM_GROUPS_MEMBERS_AND_WHITELIST: {
                return {
                  name,
                  label: name,
                  description,
                  errors: storeForm.field(checkerOptionCodename).errors,
                  highlight: isStaged,
                  control: (
                    <ConfigurationSerieFormOMCGMAW
                      storeForm={storeForm}
                      highlight={isStaged}
                      readonly={isReadonly}
                      storeProfileCheckerOptionArrayObject={
                        storeProfileCheckerOptionOMCGMAW
                      }
                    />
                  ),
                  meta: {
                    field
                  }
                }
              }

              case CheckerOptionCodenameEnum.O_WHITELIST_CERTIFICATE_TEMPLATES: {
                return {
                  name,
                  label: name,
                  description,
                  errors: storeForm.field(checkerOptionCodename).errors,
                  highlight: isStaged,
                  control: (
                    <ConfigurationSerieFormOWCT
                      storeForm={storeForm}
                      highlight={isStaged}
                      readonly={isReadonly}
                      storeProfileCheckerOptionArrayObject={
                        storeProfileCheckerOptionOWCT
                      }
                    />
                  ),
                  meta: {
                    field
                  }
                }
              }

              default: {
                return {
                  name,
                  label: name,
                  description,
                  errors: storeForm.field(checkerOptionCodename).errors,
                  highlight: isStaged,
                  control: (
                    <LabelAltError labelledBy="valueNonConfiguration">
                      {translate(`${valueType} is not configurable`)}
                    </LabelAltError>
                  ),
                  meta: {
                    field
                  }
                }
              }
            }
          }

          // Date is not implemented in the API for checker options.
          // Just there to satisfy the final assertion.
          case ValueType.object:
          case ValueType.date: {
            return {
              name,
              label: name,
              description,
              errors: storeForm.field(checkerOptionCodename).errors,
              highlight: isStaged,
              control: (
                <LabelAltError labelledBy="valueNonConfigurable">
                  {translate(`${valueType} is not configurable`)}
                </LabelAltError>
              ),
              meta: {
                field
              }
            }
          }
        }

        assertUnreachableCase(valueType)
      })
      .filter(isDefined)
      .filter(field => {
        return storeInputSearchOptions.transformedSearchValueAsRegexp.test(
          field.label
        )
      })
  }
