import type { EventId } from '@app/entities/EntityEvent'
import type { StoreTrailFlow } from '@app/stores'

export const onEventDetailsLoad =
  (storeTrailFlow: StoreTrailFlow) =>
  (infrastructureId: number, directoryId: number, eventId: EventId) =>
  () => {
    storeTrailFlow.storeEventDetails.fetchEventDetails(
      infrastructureId,
      directoryId,
      eventId
    )
  }

export const onEventDetailsUnload = (storeTrailFlow: StoreTrailFlow) => () => {
  storeTrailFlow.storeEventDetails.reset()
}
