import type { PropertiesNullable } from '@@types/helpers'
import { SmtpEncryption } from '@app/stores/Management/StoreApplicationSettings/types'
import type { ApplicationSettings, Maybe } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityApplicationSettings
  extends EntityBase
  implements PropertiesNullable<ApplicationSettings>
{
  userRegistration: Maybe<boolean> = null
  activityLogsIsActive: Maybe<boolean> = null
  activityLogsRetentionDurationInMonth: Maybe<number> = null
  smtpServerAddress: Maybe<string> = null
  smtpServerPort: Maybe<number> = null
  smtpAccount: Maybe<string> = null
  smtpAccountPassword: Maybe<string> = null
  tls: Maybe<boolean> = null
  smtpUseStartTLS: Maybe<boolean> = null
  emailSender: Maybe<string> = null
  defaultProfileId: Maybe<number> = null
  defaultRoleIds: Maybe<number[]> = null
  internalCertificate: Maybe<string> = null
  telemetryEnabled: Maybe<boolean> = null
  ioaAutomaticConfiguration: Maybe<boolean> = null
  smtpRelayId: Maybe<number> = null
  cloudStatisticsIsActive: Maybe<boolean> = null
  healthCheckGlobalStatusDisplayEnabled: Maybe<boolean> = null

  constructor(data: Partial<ApplicationSettings>) {
    super()
    Object.assign(this, data)
  }

  get smtpEncryption(): SmtpEncryption {
    if (this.tls) {
      return SmtpEncryption.tls
    }

    if (this.smtpUseStartTLS) {
      return SmtpEncryption.startTls
    }

    return SmtpEncryption.none
  }
}
