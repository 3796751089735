import type { MaybeUndef } from '@@types/helpers'
import type { ILogger } from '@libs/logger'
import type {
  IWSCommand,
  IWSMessage,
  IWSMessageData,
  IWSMessageError,
  IWSMessageStatus,
  IWSRegistration,
  WSEntityRegistration,
  WSRegistrationName
} from '@server/routers/WSProxyRouter/types'
import { WSCommandName } from '@server/routers/WSProxyRouter/types'
import * as WebSocket from 'isomorphic-ws'
import * as uuid from 'uuid'
import type { ExtendedWindows, RegistrationFn, TWSClient } from './types'

export const extendedWindow =
  typeof window !== 'undefined' ? (window as ExtendedWindows) : undefined

export default class WSClient implements TWSClient {
  private logger: ILogger
  private url: string
  private ws: MaybeUndef<WebSocket> = undefined
  private registrations: Map<string, IWSRegistration<any>> = new Map()
  private pendingRegistrations: Map<string, IWSRegistration<any>> = new Map()

  private reconnectionTimer: MaybeUndef<NodeJS.Timeout>
  private reconnectionLoopDelay = 10000
  private reconnectionAttempts: number = 0

  private backEndConnectionAvailable = false

  constructor(url: string, logger: ILogger) {
    this.logger = logger
    this.url = url
  }

  /**
   * Add a new registration and send a 'register' WS message if connected.
   * If disconnected, add the registration in a pending stack.
   */
  addRegistration<P>(
    name: WSRegistrationName,
    entity: WSEntityRegistration,
    registrationHandler: RegistrationFn<P>
  ): IWSRegistration<P> {
    const channelUuid = uuid.v4()

    const registration: IWSRegistration<P> = {
      channelUuid,
      name,
      entity,
      handler: registrationHandler
    }

    if (!this.isConnected()) {
      this.logger.debug(
        `Add a new pending handler for registration: ${channelUuid} (${name}).`
      )

      this.pendingRegistrations.set(channelUuid, registration)
    } else {
      this.register(registration)
    }

    return registration
  }

  /**
   * Remove a registration from the current or pending registration
   * and send a WS 'unregister' message.
   */
  removeRegistration(registration: IWSRegistration<any>): void {
    this.logger.debug(
      `Unregister the handler ${registration.channelUuid} (${registration.name}).`
    )

    this.registrations.delete(registration.channelUuid)
    this.pendingRegistrations.delete(registration.channelUuid)

    this.send({
      command: WSCommandName.unregister,
      ...registration
    })
  }

  /**
   * Connect to Kapteyn server.
   */
  connect(): void {
    if (this.isConnected()) {
      return
    }

    this.ws = new WebSocket(this.url)
    this.ws.onopen = this.onOpen.bind(this)
    this.ws.onmessage = this.onMessage.bind(this)
    this.ws.onclose = this.onClose.bind(this)
    this.ws.onerror = this.onError.bind(this)
  }

  /**
   * Send a message to the WS server.
   */
  send(registrationCommand: IWSCommand): void {
    if (!this.ws || !this.isConnected()) {
      this.logger.debug('Cant send message, not connected to the server.')
      return
    }

    this.logger.debug(
      [
        'Send message',
        `Command: ${registrationCommand.command}`,
        `Channel: ${registrationCommand.channelUuid}`,
        `Entity: ${JSON.stringify(registrationCommand.entity)}`
      ].join(' - ')
    )

    const wsMessage: IWSMessage = {
      command: registrationCommand.command,
      channelUuid: registrationCommand.channelUuid,
      entity: registrationCommand.entity.name,
      payload: registrationCommand.entity.payload
    }

    this.ws.send(JSON.stringify(wsMessage))
  }

  close(): void {
    if (this.ws) {
      this.ws.close()
    }
  }

  getPendingRegistrations(): Map<string, IWSRegistration<any>> {
    return this.pendingRegistrations
  }

  getRegistrations(): Map<string, IWSRegistration<any>> {
    return this.registrations
  }

  setReconnectionLoopDelay(delay: number): void {
    this.reconnectionLoopDelay = delay
  }

  /**
   * Return true if the client is connected to the WS server.
   */
  isConnected(): boolean {
    return Boolean(
      this.ws &&
        this.ws.readyState === 1 &&
        this.backEndConnectionAvailable === true
    )
  }

  /**
   * Private
   */

  /**
   * When the connection is established,
   * register pending registrations.
   */
  private onOpen() {
    this.logger.info('Connected.')

    this.addPendingRegistrations()
    this.stopReconnectionLoop()
  }

  /**
   * When a message is received,
   * call handlers of registrations.
   */
  private onMessage(event: {
    data: WebSocket.Data
    type: string
    target: WebSocket
  }) {
    const messageData: IWSMessageData | IWSMessageStatus | IWSMessageError =
      JSON.parse(String(event.data))

    this.logger.debug('Receive message data:', messageData)

    if ('error' in messageData) {
      this.logger.error(String(messageData.error))

      if (messageData.error === 'Backend connection is not available') {
        this.backEndConnectionAvailable = false
      }

      return
    }

    if ('message' in messageData) {
      this.logger.debug(messageData.message)

      if (messageData.message === 'Backend connection is available') {
        this.backEndConnectionAvailable = true
      }

      return
    }

    this.callHandlers(messageData)
  }

  /**
   * Log only if not being trying to reconnect.
   */
  private onError() {
    if (this.reconnectionTimer) {
      return
    }

    this.logger.info('Error during the connection.')
  }

  /**
   * If the connection is closed, start the reconnection loop.
   */
  private onClose() {
    if (this.reconnectionTimer) {
      return
    }

    this.logger.info('Connection closed.')
    this.startReconnectionLoop()
  }

  /**
   * Register a new registration,
   * and send a 'register' message to the WS server.
   */
  private register(registration: IWSRegistration<any>) {
    this.logger.debug(
      `Register a new handler: ${registration.channelUuid} (${registration.name}).`
    )

    this.registrations.set(registration.channelUuid, registration)

    this.send({
      command: WSCommandName.register,
      ...registration
    })
  }

  /**
   * Register pending registrations and send 'register' messages to the WS server.
   */
  private addPendingRegistrations() {
    // Hack: It seems that the connection between Kapteyn server <-> Browser
    // is done before that the connection is established between Kapteyn server <-> Eridanis.
    // Maybe it's an issue relative to http-proxy-middleware...
    // For now, just delayed the sending of message.
    setTimeout(() => {
      this.pendingRegistrations.forEach(registration => {
        this.register(registration)
      })
    }, 1000)
  }

  /**
   * Call all handlers of registrations with the extended payload message (if any),
   * or with the payload received from the Websocket.
   */
  private callHandlers(messageData: IWSMessageData) {
    this.registrations.forEach(registration => {
      if (registration.channelUuid === messageData.channelUuid) {
        registration.handler(messageData.extendedPayload || messageData.payload)
      }
    })
  }

  /**
   * Start a reconnection loop to reestablish the connection when the WS server
   * will be back.
   */
  private startReconnectionLoop() {
    this.stopReconnectionLoop()

    this.reconnectionTimer = setInterval(() => {
      this.reconnectionAttempts++
      this.logger.info(`Try to reconnect (${this.reconnectionAttempts}).`)
      this.connect()
    }, this.reconnectionLoopDelay)

    this.logger.info('Start the reconnection loop.')

    // in development, save the timer in global to be able to delete it
    // after a HmR
    if (process.env.NODE_ENV === 'development' && extendedWindow) {
      extendedWindow.wsClientReconnectTimer = this.reconnectionTimer
    }
  }

  /**
   * Stop the reconnection loop.
   */
  private stopReconnectionLoop() {
    this.logger.info('Stop the reconnection loop.')

    this.reconnectionAttempts = 0

    if (this.reconnectionTimer) {
      clearInterval(this.reconnectionTimer)
      this.reconnectionTimer = undefined
    }

    // in development, remove also the timer saved in global
    // (workaround to be able to clear the previous timer after a HmR)
    if (
      process.env.NODE_ENV === 'development' &&
      extendedWindow &&
      extendedWindow.wsClientReconnectTimer
    ) {
      clearInterval(extendedWindow.wsClientReconnectTimer)
      extendedWindow.wsClientReconnectTimer = undefined
    }
  }
}
