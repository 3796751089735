import { Label, LabelColorTag } from '@app/components/Labels'
import { ContainerFlex } from '@app/components-legacy/Container'
import type { IContainerSimpleProps } from '@app/components-legacy/Container/ContainerSimple'
import type { EntityUserLog } from '@app/entities'
import type { IUserChanged } from '@app/entities/EntityUserLog'
import { ConfigurationType } from '@app/entities/EntityUserLog'
import { consts } from '@app/styles'
import { BackgroundColor, marginSmall, marginSmaller } from '@app/styles/consts'
import { isDefined } from '@libs/isDefined'
import { UserTraceLogType } from '@server/graphql/typeDefs/types'
import React from 'react'
import styled from 'styled-components'
import UserLogCollapseRow from '../../Components/UserLogCollapseRow'
import { UserLogIcon } from '../../Components/UserLogIcon'
import { UserLogIpAddress } from '../../Components/UserLogIpAddress'
import UserLogTime from '../../Components/UserLogTime'
import { UserLogUser } from '../../Components/UserLogUser'
import { UserLogUsername } from '../../Components/UserLogUserName'
import UserTraceUserCreatedItem from './UserLogUserCreatedItem'
import UserTraceUserUpdatedItem from './UserLogUserUpdatedItem'

interface IUserLogRowProps extends IContainerSimpleProps {
  log: EntityUserLog
}

const StyledLabel = styled(Label)`
  margin-right: ${consts.marginVeryVerySmall};
`

const StyledAmountOfModification = styled(LabelColorTag)`
  margin-right: ${consts.marginVeryVerySmall};
`

const UserLogUserCreatedOrUpdateRow: React.FC<IUserLogRowProps> = ({
  log,
  className
}) => {
  if (!log.decodedLogAttributes) {
    return null
  }

  const userAttributes = log.decodedLogAttributes as IUserChanged

  const getAction = (): JSX.Element[] => {
    const action = []

    switch (log.logType) {
      case UserTraceLogType.ConfigurationChanged: {
        const { items } = userAttributes

        if (!items) {
          break
        }

        const hasAtLeastOneRoleInItems = isDefined(
          items.find(item => item.fieldType === ConfigurationType.userRole)
        )
        action.push(<StyledLabel>Has edited</StyledLabel>)
        action.push(
          <StyledAmountOfModification
            color={BackgroundColor.secondary}
            labelProperties={{
              labelledBy: 'amountOfModification'
            }}
          >
            {items.length}
          </StyledAmountOfModification>
        )
        action.push(
          <StyledLabel>
            {hasAtLeastOneRoleInItems ? 'role' : 'value'}
            {items.length > 1 ? 's' : ''} of the user
          </StyledLabel>
        )
        break
      }
      default: {
        action.push(<StyledLabel>Has created a new user</StyledLabel>)
      }
    }

    action.push(
      <UserLogUsername
        userName={userAttributes.userName}
        userSurname={userAttributes.userSurname}
      />
    )

    return action
  }

  const rowItems = [
    <UserLogIcon trace={log} />,
    <UserLogUser user={log.userEmail} />,
    <UserLogIpAddress ipAddress={log.userIp} />,
    ...getAction(),

    <UserLogTime log={log} />
  ]

  const title = (
    <ContainerFlex
      name={UserLogUserCreatedOrUpdateRow.name}
      className={className}
      ariaRoles={['list', 'row']}
      flexWrap="wrap"
      labelledBy={UserLogUserCreatedOrUpdateRow.name}
      alignItems="center"
      items={rowItems}
    />
  )

  return (
    <UserLogCollapseRow title={title} log={log}>
      {userAttributes.items?.map(item => (
        <div key={`${log.id}${item.field}${item.before}${item.after}`}>
          {log.logType === UserTraceLogType.UserCreated ? (
            <UserTraceUserCreatedItem changedItem={item} />
          ) : (
            <UserTraceUserUpdatedItem changedItem={item} />
          )}
        </div>
      ))}
    </UserLogCollapseRow>
  )
}
export default styled(UserLogUserCreatedOrUpdateRow)`
  border-radius: ${consts.borderRadiusRedesign};
  background-color: ${consts.colorWhite};
  padding-right: ${consts.paddingExtraLarge};
  padding-left: ${consts.paddingLarge};
  padding-top: ${consts.paddingSmall};
  padding-bottom: ${consts.paddingSmall};

  .traceUser {
    text-align: left;
    margin-right: ${marginSmaller};
  }

  .traceIpAddress {
    text-align: left;
    margin-right: ${marginSmall};
  }

  .traceAction {
    text-align: left;
  }

  .traceTime {
    text-align: right;
    flex: 1;
    width: 100%;
  }
`
