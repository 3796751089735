export enum ProfileFieldName {
  fromProfileSelection = 'fromProfileSelection',
  fromProfileSubmit = 'fromProfileSubmit',
  name = 'name',
  enabled = 'enabled'
}

export enum ProfileCreateFieldValue {
  createProfile = 'createProfile'
}
