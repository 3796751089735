import * as React from 'react'
import styled from 'styled-components'

interface ICheckboxesTreeProps {
  className?: string
  color: string
  children?: React.ReactNode
}

/**
 * Render a checkbox inside an Antd Tree component.
 * Background color of the checkbox is overridden by the color passed in props.
 */
const CheckboxesTree: React.FC<ICheckboxesTreeProps> = props => {
  return (
    <div data-name="InfrastructuresTreeTree" className={props.className}>
      {props.children}
    </div>
  )
}

export default styled(CheckboxesTree)`
  .ant-tree.ant-tree-directory
    > li.ant-tree-treenode-selected
    > span.ant-tree-checkbox
    .ant-tree-checkbox-inner,
  .ant-tree.ant-tree-directory
    .ant-tree-child-tree
    > li.ant-tree-treenode-selected
    > span.ant-tree-checkbox
    .ant-tree-checkbox-inner {
    border-color: ${props => props.color};
  }

  .ant-tree.ant-tree-directory
    > li.ant-tree-treenode-selected
    > span.ant-tree-checkbox.ant-tree-checkbox-checked
    .ant-tree-checkbox-inner::after,
  .ant-tree.ant-tree-directory
    .ant-tree-child-tree
    > li.ant-tree-treenode-selected
    > span.ant-tree-checkbox.ant-tree-checkbox-checked
    .ant-tree-checkbox-inner::after {
    border-color: ${props => props.color};
  }

  .ant-tree.ant-tree-directory
    > li.ant-tree-treenode-selected
    > span.ant-tree-node-content-wrapper::before,
  .ant-tree.ant-tree-directory
    .ant-tree-child-tree
    > li.ant-tree-treenode-selected
    > span.ant-tree-node-content-wrapper::before {
    background: ${props => props.color};
  }

  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
    border-color: ${props => props.color};
  }

  .ant-tree-checkbox-checked::after {
    border: 1px solid ${props => props.color};
  }

  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: ${props => props.color};
    border-color: ${props => props.color};
  }

  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: ${props => props.color};
  }
`
