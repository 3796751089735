import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import DrawerSDDLDescription from '@app/pages/TrailFlow/EventDetailsAttributesPage/DrawerSDDLDescription'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckerDetailsPageContext } from '../../context'
import { canAccessToIoEDeviantObjects } from '../../permissions'
import DrawerExportDeviantObjects from '../DrawerExportDeviantObjects'
import DrawerIgnoreDeviantObjects from '../DrawerIgnoreDeviantObjects'
import DrawerUnignoreDeviantObjects from '../DrawerUnignoreDeviantObjects'
import { onDeviantObjectsLoad, onDeviantObjectsUnload } from '../handlers'
import DeviantObjectsFooter from './DeviantObjectsFooter'
import DeviantObjectsTab from './DeviantObjectsTab'

interface IDeviantObjectsProps {}

const DeviantObjects: React.FC<IDeviantObjectsProps> = () => {
  const parameters = React.useContext(CheckerDetailsPageContext)

  const translate = useAppTranslator({ namespaces: ['IoE.Details.Menu'] })

  const { storeIoE } = useStores()

  const { storeIndicatorDeviantObjects } = storeIoE.storeIndicator

  if (!parameters) {
    return null
  }

  return (
    <>
      <ContainerContent
        title={translate('Deviant objects')}
        flags={[
          storeIoE.storeFlagsFetchCheckersExposure.flags,
          storeIndicatorDeviantObjects.storeFlagsLoadDeviantObjects.flags,
          storeIndicatorDeviantObjects.storeInputReasons.storeFlagsFetchReasons
            .flags,
          storeIndicatorDeviantObjects.storeFlagsLoadCheckerReasons.flags,
          storeIndicatorDeviantObjects.storeInfrastructures
            .storeFlagsFetchInfrastructures.flags
        ]}
        onLoad={onDeviantObjectsLoad(storeIoE)(parameters.checkerCodename)}
        onUnload={onDeviantObjectsUnload(storeIoE)}
        rbacCapabilityCheck={canAccessToIoEDeviantObjects(parameters.checkerId)}
      >
        <>
          <DeviantObjectsTab />

          <Portal name={PlaceHolderName.bladeFooter}>
            <DeviantObjectsFooter
              checkerId={parameters.checkerId}
              checkerCodename={parameters.checkerCodename}
            />
          </Portal>
        </>
      </ContainerContent>

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerIgnoreDeviantObjects />
      </Portal>

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerUnignoreDeviantObjects />
      </Portal>

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerExportDeviantObjects />
      </Portal>

      <Portal name={PlaceHolderName.genericDrawer}>
        <DrawerSDDLDescription
          storeDrawer={
            storeIoE.storeRoot.stores.storeTrailFlow.storeEventDetails
              .storeEventDetailsAttributes.storeDrawerSDDLDescription
          }
        />
      </Portal>
    </>
  )
}

export default observer(DeviantObjects)
