import { useAppTranslator } from '@app/hooks/useAppTranslator'
import * as React from 'react'
import styled from 'styled-components'

interface IZoomLabelProps {
  className?: string
}

const ZoomLabel: React.FC<IZoomLabelProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Topology.Chart']
  })

  return (
    <span data-name="ZoomLabel" className={props.className}>
      {translate('Zoom')}
    </span>
  )
}

// Memoize in order to avoid useless re-render
const MemoizedZoomLabel = React.memo(ZoomLabel)

export default styled(MemoizedZoomLabel)`
  text-transform: uppercase;
`
