import { ContainerSimple } from '@app/components/Container'
import { ErrorWrapper } from '@app/components/Error'
import {
  IconCheckCircle,
  IconClose,
  IconCloseCircle
} from '@app/components/Icon'
import { FormWrapperButton } from '@app/components-legacy/Form'
import {
  IconCheckCircleFilled,
  IconCloseCircleFilled
} from '@app/components-legacy/Icon/IconAntd'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import type { IMessage } from '@app/stores/StoreMessages'
import {
  MESSAGES_DEFAULT_DURATION,
  MessageType
} from '@app/stores/StoreMessages'
import { consts } from '@app/styles'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { message } from 'antd'
import type { ArgsProps } from 'antd/lib/message'
import { observe } from 'mobx'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex, ContainerHTMLContent } from '../Container'

const StyledContainerHTMLContent = styled(ContainerHTMLContent)`
  text-align: left;
`

const StyledIconCheckCircleFilled = styled(IconCheckCircleFilled)`
  &.anticon {
    margin: 0;
  }

  svg {
    width: 15px;
    height: 15px;
    color: ${consts.colorSuccessAntd};
  }
`

const StyledIconCloseCircleFilled = styled(IconCloseCircleFilled)`
  &.anticon {
    margin: 0;
  }

  svg {
    width: 15px;
    height: 15px;
    color: ${consts.colorErrorAntd};
  }
`

/**
 * Observe alerts and render them by using the `message` function from Antd.
 */
export function useAlertsObserver(): void {
  const { storeMessages } = useStores()

  React.useEffect(() => {
    const getIcon = (
      customIcon: NonNullable<IMessage['options']['customIcon']>
    ) => {
      switch (customIcon) {
        case 'success':
          return (
            <ContainerSimple labelledBy="success">
              <StyledIconCheckCircleFilled />
            </ContainerSimple>
          )

        case 'error':
          return (
            <ContainerSimple labelledBy="error">
              <StyledIconCloseCircleFilled />
            </ContainerSimple>
          )

        default:
          assertUnreachableCase(customIcon)
      }
    }

    const disposer = observe(storeMessages.messages, change => {
      if (change.type === 'add') {
        const messagePayload = change.newValue

        const content = messagePayload.options.html ? (
          <DSThemeProvider themeName={DSThemeName.legacy}>
            <ContainerFlex
              name="Message"
              items={[
                messagePayload.options.customIcon &&
                  getIcon(messagePayload.options.customIcon),

                <StyledContainerHTMLContent
                  ariaRoles={messagePayload.options.ariaRoles}
                  labelledBy={messagePayload.options.labelledBy}
                >
                  {messagePayload.message}
                </StyledContainerHTMLContent>,

                messagePayload.options.closable && (
                  <FormWrapperButton
                    labelledBy={`buttonCloseMessage-${messagePayload.key}`}
                    buttonProps={{
                      type: 'link',
                      size: 'small',
                      icon: (
                        <IconClose color={consts.colorBlueGray005} size={12} />
                      ),
                      onClick: () => {
                        message.destroy(messagePayload.key)
                      }
                    }}
                  />
                )
              ]}
              spaced
              spaceWidth="small"
              alignItems={messagePayload.options.alignItems}
            />
          </DSThemeProvider>
        ) : (
          <ErrorWrapper
            ariaRoles={messagePayload.options.ariaRoles}
            labelledBy={messagePayload.options.labelledBy}
          >
            {messagePayload.message}
          </ErrorWrapper>
        )

        const messageConfig: ArgsProps = {
          key: messagePayload.key,
          content,
          duration:
            messagePayload.options.duration ?? MESSAGES_DEFAULT_DURATION,
          type: messagePayload.options.type || MessageType.info
        }

        if (messageConfig.type === 'success') {
          messageConfig.icon = (
            <span
              role="img"
              aria-label="check-circle"
              className="anticon anticon-check-circle"
            >
              <IconCheckCircle size={16} />
            </span>
          )
        }

        if (messageConfig.type === 'error') {
          messageConfig.icon = (
            <span
              role="img"
              aria-label="close-circle"
              className="anticon anticon-close-circle"
            >
              <IconCloseCircle size={16} />
            </span>
          )
        }

        // if a custom icon is set, remove the default icon of the message
        if (messagePayload.options.customIcon) {
          messageConfig.icon = <div />
        }

        message.open(messageConfig)
      }
    })

    return () => {
      disposer()
    }
  }, [])
}

/**
 * When the app loads, if an ?error=blabla querystring parameter is present,
 * retrieve its value and push an error.
 * Then, remove the querystring in the browser history.
 */
export function useAlertsQueryStringNotifier() {
  const { storeMessages } = useStores()

  const appRouter = useAppRouter()

  React.useEffect(() => {
    const url = new URL(document.location.href)
    const errorString = url.searchParams.get('error')

    if (errorString) {
      storeMessages.error(errorString, {
        labelledBy: 'queryStringError'
      })

      url.searchParams.delete('error')

      appRouter.history.replace(url.pathname)
    }
  }, [])
}
