import type { Maybe } from '@@types/helpers'
import { IconSVG } from '@app/components-legacy/Icon'
import type { EntityUserLog } from '@app/entities'
import type { IUserChanged } from '@app/entities/EntityUserLog'
import { ConfigurationType } from '@app/entities/EntityUserLog'
import { consts } from '@app/styles'
import { UserTraceLogType } from '@server/graphql/typeDefs/types'
import React from 'react'
import styled from 'styled-components'

export interface IUserLogIconProps {
  labelledBy?: string
  size?: number
  trace: Maybe<EntityUserLog>
}

const StyledIcon = styled(IconSVG)`
  margin-right: ${consts.marginSmaller};
`
export const UserLogIcon = (props: IUserLogIconProps) => {
  const getIconFromLogType = (): string => {
    if (!props.trace || !props.trace.logType) {
      return 'configuration'
    }

    switch (props.trace.logType) {
      case UserTraceLogType.LoginSucceeded:
      case UserTraceLogType.LoginFailed:
        return 'log-in'
      case UserTraceLogType.Logout:
        return 'log-out'
      case UserTraceLogType.UnauthenticatedApiCall:
      case UserTraceLogType.ApiCall:
        return 'api-calls'
      case UserTraceLogType.UserCreated:
        return 'user-create'
      case UserTraceLogType.UserDeleted:
        return 'user-delete'
      case UserTraceLogType.PageVisited:
        return 'page-visited'
      case UserTraceLogType.ConfigurationChanged: {
        const changes = props.trace.decodedLogAttributes as IUserChanged

        if (
          changes &&
          (changes.configurationType === ConfigurationType.userRole ||
            changes.configurationType === ConfigurationType.user)
        ) {
          return 'user-edit'
        }

        return 'configuration'
      }
      default:
        return 'configuration'
    }
  }

  return (
    <StyledIcon
      labelledBy={getIconFromLogType()}
      iconName={`activityLogs/${getIconFromLogType()}`}
      size={props.size ?? 20}
    />
  )
}
