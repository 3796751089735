import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'

interface IIconTopologyProps extends IIconProps {}

const IconTopology: React.FC<IIconTopologyProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0787 4.0152C13.033 4.35257 12.9146 4.67097 12.7375 4.95863C15.0253 6.00781 16.6143 8.31834 16.6143 11C16.6143 11.61 16.5321 12.2009 16.3781 12.762C16.715 12.8355 17.0284 12.9719 17.3053 13.1583C17.5064 12.4738 17.6143 11.7495 17.6143 11C17.6143 7.88536 15.7512 5.20588 13.0787 4.0152ZM9.97147 17.6429C11.2347 17.6429 12.4156 17.2903 13.421 16.6781C13.5822 16.9775 13.7983 17.243 14.0558 17.4611C12.8744 18.2096 11.4735 18.6429 9.97147 18.6429C8.45895 18.6429 7.04906 18.2035 5.86244 17.4454C6.1175 17.2255 6.33097 16.9586 6.48947 16.6582C7.50193 17.2826 8.69463 17.6429 9.97147 17.6429ZM3.56249 12.7535C3.41001 12.1949 3.32861 11.6069 3.32861 11C3.32861 8.33142 4.90215 6.03037 7.172 4.97408C6.99275 4.68754 6.87209 4.37005 6.82372 4.03338C4.1729 5.233 2.32861 7.90112 2.32861 11C2.32861 11.7408 2.43401 12.457 2.63064 13.1344C2.90987 12.9529 3.22476 12.8217 3.56249 12.7535Z"
        fill="currentColor"
      />
      <path
        d="M9.97116 6.71429C11.5491 6.71429 12.8283 5.4351 12.8283 3.85714C12.8283 2.27919 11.5491 1 9.97116 1C8.3932 1 7.11401 2.27919 7.11401 3.85714C7.11401 5.4351 8.3932 6.71429 9.97116 6.71429Z"
        stroke="currentColor"
      />
      <path
        d="M4.25705 18.1429C5.835 18.1429 7.11419 16.8637 7.11419 15.2857C7.11419 13.7078 5.835 12.4286 4.25705 12.4286C2.67909 12.4286 1.3999 13.7078 1.3999 15.2857C1.3999 16.8637 2.67909 18.1429 4.25705 18.1429Z"
        stroke="currentColor"
      />
      <path
        d="M15.6855 18.1429C17.2635 18.1429 18.5427 16.8637 18.5427 15.2857C18.5427 13.7078 17.2635 12.4286 15.6855 12.4286C14.1076 12.4286 12.8284 13.7078 12.8284 15.2857C12.8284 16.8637 14.1076 18.1429 15.6855 18.1429Z"
        stroke="currentColor"
      />
    </svg>
  )
}
export default IconTopology
