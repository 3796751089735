import type { PropertiesNullable } from '@@types/helpers'
import {
  createEntities,
  EntityDashboardWidgetOptionsSerieStatDataPointAttackCount
} from '@app/entities'
import { ensureArray } from '@libs/ensureArray'
import type {
  DashboardWidgetDataOptionType,
  DashboardWidgetOptionsSerieStatAttackCounts,
  DashboardWidgetOptionsSerieStatDataPointAttackCount,
  Maybe
} from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityDashboardWidgetOptionsSerieStatAttackCounts
  extends EntityBase
  implements PropertiesNullable<DashboardWidgetOptionsSerieStatAttackCounts>
{
  type: Maybe<DashboardWidgetDataOptionType> = null
  timestampStart: Maybe<string> = null
  timestampEnd: Maybe<string> = null
  interval: Maybe<string> = null
  directoryIds: Maybe<number[]> = null
  attackTypeIds: Maybe<number[]> = null
  profileId: Maybe<number> = null
  label: Maybe<string> = null
  data: Maybe<DashboardWidgetOptionsSerieStatDataPointAttackCount[]> = null

  constructor(data: Partial<DashboardWidgetOptionsSerieStatAttackCounts>) {
    super()
    Object.assign(this, data)
  }

  public getAttackTypeIds(): number[] {
    return ensureArray(this.data).map(data => data.attackTypeId)
  }

  get dataEntities(): EntityDashboardWidgetOptionsSerieStatDataPointAttackCount[] {
    return createEntities<
      DashboardWidgetOptionsSerieStatDataPointAttackCount,
      EntityDashboardWidgetOptionsSerieStatDataPointAttackCount
    >(
      EntityDashboardWidgetOptionsSerieStatDataPointAttackCount,
      ensureArray(this.data)
    )
  }

  get dataCount(): number {
    return this.dataEntities.reduce(
      (acc, curr) => acc + curr.getPropertyAsNumber('count'),
      0
    )
  }
}
