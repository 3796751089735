import { createEntities, EntityReason } from '@app/entities'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import { getReasonsColorScheme } from '@app/styles/colors/schemes'
import { CSSColors } from '@app/styles/colors/types'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import { checkRbac } from '@libs/rbac/functions'
import type { QueryReasons } from '@server/graphql/queries/reasons'
import { queryReasons } from '@server/graphql/queries/reasons'
import type { Maybe, Reason } from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import type { StoreRoot } from '.'
import StoreBase from './StoreBase'

// Treeview internal ID representation (reasonID)
export type TreeId = string

export interface IStoreInputReasonsOptions {
  instanceName?: string
  /**
   * Filter function to optionally filter reasons (by checker/event for example)
   */
  filterReasonsFn?: (reasons: EntityReason[]) => EntityReason[]
}

export default class StoreInputReasons extends StoreBase<IStoreInputReasonsOptions> {
  public storeFlagsFetchReasons = new StoreFlags(this.storeRoot)
  public storeDrawer = new StoreDrawer(this.storeRoot)
  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Components.InputReasons']
  })

  /* Observable */

  private $reasons = observable.map<number, EntityReason>()
  private $selectedReasons = observable.map<TreeId, boolean>()

  constructor(storeRoot: StoreRoot, options: IStoreInputReasonsOptions) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch reasons.
   */
  fetchReasons(): Promise<void> {
    this.storeFlagsFetchReasons.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryReasons>(queryReasons)
      })
      .then(data => data.rbacReasons)
      .then(reasons => {
        if (!checkRbac(this.storeRoot, this.storeFlagsFetchReasons)(reasons)) {
          throw new ForbiddenAccessError()
        }

        const reasonEntities = createEntities<Reason, EntityReason>(
          EntityReason,
          reasons.node
        )

        this.setReasons(reasonEntities)

        this.storeFlagsFetchReasons.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsFetchReasons))
  }

  /**
   * Return the color of a reason.
   */
  getReasonColor(reasonId: number): string {
    const entityReason = this.$reasons.get(reasonId)

    if (!entityReason) {
      return CSSColors.Black
    }

    return entityReason.color
  }

  /**
   * Return reasons from deviances.
   */
  getDeviancesReasons(reasonIds: number[]): EntityReason[] {
    return reasonIds
      .filter(isDefined)
      .map(id => this.reasons.get(id))
      .filter(isDefined)
  }

  /**
   * Return the checked status of an infrastructure.
   */
  isReasonSelected(reasonId: number): Maybe<boolean> {
    return this.selectedReasonIds.some(selectedId => selectedId === reasonId)
  }

  /* Actions */

  @action
  reset(): this {
    this.$reasons.clear()
    this.$selectedReasons.clear()

    return this
  }

  /**
   * Set reasons entities.
   */
  @action
  private setReasons(entityReasons: EntityReason[]): this {
    this.$reasons.clear()

    // generate a color scheme for all reasons
    const colors = getReasonsColorScheme(entityReasons.length)

    // set colors
    entityReasons.forEach((entityReason, i) => {
      entityReason.setColor(colors[i])
    })

    // filter reasons if a filter function has been defined in options
    const filterReasonsFn = this.options.filterReasonsFn

    const filteredEntityReasons = filterReasonsFn
      ? filterReasonsFn(entityReasons)
      : entityReasons

    const allTreeIds: TreeId[] = []

    filteredEntityReasons.forEach(entityReason => {
      const reasonId = entityReason.getPropertyAsNumber('id')

      this.$reasons.set(reasonId, entityReason)

      const treeId = String(reasonId)
      allTreeIds.push(treeId)

      // retrieve the current selected status or select it by default
      const isSelectedValue = this.$selectedReasons.get(treeId)

      const isSelectedBoolean = isDefined(isSelectedValue)
        ? isSelectedValue
        : true

      this.$selectedReasons.set(treeId, isSelectedBoolean)
    })

    // remove all selection that can be "deprecated" after a reason deletion
    Array.from(this.selectedReasons.keys()).forEach(treeId => {
      if (allTreeIds.indexOf(treeId) === -1) {
        this.$selectedReasons.delete(treeId)
      }
    })

    return this
  }

  /**
   * Select or unselect the reasons.
   */
  @action
  selectReason(reasonId: number, selected: boolean): this {
    const entityReason = this.$reasons.get(reasonId)

    if (!entityReason) {
      return this
    }

    const treeId = entityReason.getPropertyAsString('id')

    this.$selectedReasons.set(treeId, selected)

    return this
  }

  /**
   * Replace all the reasons selection.
   */
  @action
  replaceSelectedReasons(selectedReasons: Map<TreeId, boolean>): this {
    Array.from(this.selectedReasons.keys()).forEach(treeId => {
      const selected = selectedReasons.get(treeId)

      // if undefined, keep the existing value
      if (!isDefined(selected)) {
        return
      }

      this.$selectedReasons.set(treeId, selected)
    })

    return this
  }

  /**
   * Select all reasons or only the reasons that
   * match if the search value is defined.
   */
  @action
  selectAllReasons(): this {
    if (this.storeInputSearch.hasSearchValue) {
      this.selectReasons(this.searchedReasonIds)
      return this
    }

    return this.selectReasons(this.reasonIds)
  }

  /**
   * Unselect all reasons.
   */
  @action
  unselectAllReasons(): this {
    Array.from(this.selectedReasons.keys()).forEach(treeId => {
      this.$selectedReasons.set(treeId, false)
    })

    return this
  }

  /**
   * Select or unselect some reasons.
   */
  @action
  selectReasons(reasonIds: number[]): this {
    Array.from(this.selectedReasons.keys())
      .map(treeId => Number(treeId))
      .forEach(reasonId => {
        const isSelected = reasonIds.indexOf(reasonId) !== -1
        const treeId = String(reasonId)

        this.$selectedReasons.set(treeId, isSelected)
      })

    return this
  }

  /**
   * Toggle the selection of one reason.
   */
  @action
  toggleSelectReason(reasonId: number): this {
    const entityReason = this.reasonIds.find(id => id === reasonId)

    if (!entityReason) {
      return this
    }

    const treeId = String(reasonId)
    const selected = this.$selectedReasons.get(treeId)

    this.$selectedReasons.set(treeId, !selected)

    return this
  }

  /* Computed */

  /**
   * Return filtered reasons as a map.
   */
  @computed
  get reasons(): Map<number, EntityReason> {
    return toJS(this.$reasons)
  }

  /**
   * Return selected reasons as map.
   */
  @computed
  get selectedReasons(): Map<TreeId, boolean> {
    return toJS(this.$selectedReasons)
  }

  /**
   * Return selected reasons as an array of number.
   */
  @computed
  get selectedReasonIds(): number[] {
    return Array.from(this.selectedReasons.entries())
      .filter(([, selected]) => selected === true)
      .map(([key]) => Number(key))
  }

  /**
   * Return all the reason ids.
   */
  @computed
  get reasonIds(): number[] {
    return Array.from(this.reasons.keys())
  }

  /**
   * Return all the reason ids that match the current search value.
   */
  @computed
  get searchedReasonIds(): number[] {
    return Array.from(this.reasons.values())
      .filter(entityReason => {
        const regexp = this.storeInputSearch.transformedSearchValueAsRegexp

        return regexp.test(entityReason.getPropertyAsString('name'))
      })
      .map(entityReason => entityReason.getPropertyAsNumber('id'))
  }

  /**
   * Return true if some reasons are selected.
   */
  @computed
  get isSomeReasonsSelected(): boolean {
    if (this.isAllReasonsSelected) {
      return false
    }

    return Array.from(this.selectedReasons.values()).some(
      selected => selected === true
    )
  }

  /**
   * Return true if all or searches reasons are selected.
   */
  @computed
  get isAllReasonsSelected(): boolean {
    if (this.storeInputSearch.hasSearchValue) {
      return Array.from(this.selectedReasons.entries())
        .filter(([treeId]) => {
          return this.searchedReasonIds.indexOf(Number(treeId)) !== -1
        })
        .every(([, selected]) => selected === true)
    }

    return Array.from(this.selectedReasons.values()).every(
      selected => selected === true
    )
  }
}
