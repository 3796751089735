import { consts } from '@app/styles'
import styled from 'styled-components'

export function setTableColumnsStyled(component: React.ComponentType<any>) {
  return styled(component)`
    display: flex;
    justify-content: space-between;
    flex-flow: nowrap;
    border-bottom: ${props => props.theme.tokens['table/borderBottom/row']};

    > * {
      flex: none;
      flex: 20%;
      overflow: hidden;
      padding-left: ${consts.paddingVerySmall};
    }

    .eventDeviance {
      flex: 30px;
    }

    .eventSource {
      flex: 100px;
    }

    .eventType {
      flex: 200px;
    }

    .eventDN {
      flex: 50%;
    }

    .eventDirectory {
      flex: 150px;
    }

    .eventDate {
      flex: 290px;
    }
  `
}
