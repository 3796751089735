import { colorPPTXDefaultText } from '@app/styles/consts'
import type PptxGenJS from 'pptxgenjs'
import { LAYOUT_HEIGHT, LAYOUT_WIDTH, PPTX_MAIN_TOP_BG_SIZE } from './constants'
import type {
  IPPTXBulletTextAndCountProps,
  IPPTXBulletTextProps
} from './types'

export const percentToInches = (value: number, baseDim: number): number => {
  return (value / 100) * baseDim
}

/**
 * Return the inches value from a percent of the layout width or argument width
 * @param value 0 to 100 value
 * @returns An inch value
 */
export const percentWidthToInches = (value: number): number => {
  return percentToInches(value, LAYOUT_WIDTH)
}
/**
 * Return the inches value from a percent of the layout height
 * @param value 0 to 100 value
 * @returns An inch value
 */
export const percentHeightToInches = (value: number): number => {
  return percentToInches(value, LAYOUT_HEIGHT)
}

export const pixelToInches = (value: number): number => {
  return value * 0.0104166667
}
export const cmToInches = (value: number): number => {
  return value / 2.54
}

export const cleanColor = (value?: string): string => {
  if (!value) {
    return ''
  }
  return value.replace('#', '')
}

/**
 * Calculated constants
 */
export const heightTopBackgroundImage =
  (LAYOUT_WIDTH * PPTX_MAIN_TOP_BG_SIZE.h) / PPTX_MAIN_TOP_BG_SIZE.w

/**
 * Reusable properties
 */
export const defaultTextOptions: PptxGenJS.TextPropsOptions = {
  fontSize: 10,
  color: cleanColor(colorPPTXDefaultText),
  paraSpaceAfter: 5,
  fit: 'resize',
  h: pixelToInches(11),
  margin: 0
}

export const titleTextOptions: PptxGenJS.TextPropsOptions = {
  w: '100%',
  bold: true,
  align: 'center',
  valign: 'middle',
  fontSize: 14,
  h: heightTopBackgroundImage * 0.8,
  margin: 0
}

export const subTitleTextOptions: PptxGenJS.TextPropsOptions = {
  ...titleTextOptions,
  bold: false,
  fontSize: 12,
  y: cmToInches(3)
}

/**
 * Add an image to the slide or a bunch of texts
 *
 */
// TODO: An idea could be to return the position after the current insertion
export const addImage = (
  slide: PptxGenJS.Slide,
  path: string,
  options?: PptxGenJS.ImageProps
): void => {
  slide.addImage({
    path,
    ...options
  })
}

/**
 * Add a text or a bunch of texts
 *
 */
// TODO: An idea could be to return the position after the current insertion
export const addText = (
  slide: PptxGenJS.Slide,
  text: string | PptxGenJS.TextProps[],
  options?: PptxGenJS.TextPropsOptions
): void => {
  slide.addText(text, {
    ...defaultTextOptions,
    ...options
  })
}

/**
 * Add a line with a bullet then a text.
 */
export const addBulletText = (
  slide: PptxGenJS.Slide,
  props: IPPTXBulletTextProps
): void => {
  slide.addShape('roundRect', {
    fill: { color: props.bullet.color },
    x: props.position.x,
    y: props.position.y,
    w: props.bullet.size,
    h: props.bullet.size,
    rectRadius: props.bullet.size
  })

  const textLeftMargin = props.text.margin ?? pixelToInches(10)
  const textWidth =
    (props.text.width ?? props.size.w - props.bullet.size) - textLeftMargin
  addText(slide, props.text.value, {
    ...props.text.options,
    w: textWidth,
    x: props.position.x + props.bullet.size + textLeftMargin,
    y: props.position.y,
    h: pixelToInches(props.text.options?.fontSize || 11)
  })
}

/**
 * Add a line with a bullet, then a text, then another text pushed on the right
 */
export const addBulletTextAndCount = (
  slide: PptxGenJS.Slide,
  props: IPPTXBulletTextAndCountProps
): void => {
  const widthCountLabel = props.countText.width
  const withAvailableForBulletText = props.size.w - widthCountLabel

  // const
  addBulletText(slide, props)

  // Add the count value
  addText(slide, props.countText.value, {
    x: props.position.x + withAvailableForBulletText,
    y: props.position.y,
    w: widthCountLabel,
    valign: 'middle',
    bold: true,
    align: 'right'
  })
}
