import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperSwitch } from '@app/components-legacy/Form/Wrappers'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreWizard from '@app/stores/helpers/StoreWizard'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onDeviantChange } from '../handlers'

interface ISwitchIsDeviantProps {
  storeWizard: StoreWizard
}

const SwitchIsDeviant: React.FC<ISwitchIsDeviantProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const translate = useAppTranslator({
    namespaces: ['Components.Wizard']
  })

  return (
    <ContainerFlex
      name={SwitchIsDeviant.name}
      alignItems="center"
      items={[
        <label htmlFor="inputExpressionDeviantOnly">
          {themeIsLegacy ? (
            translate('Deviant only')
          ) : (
            <Label variant={LabelVariant.p}>{translate('Deviant only')}</Label>
          )}
        </label>,

        <FormWrapperSwitch
          labelledBy="inputExpressionDeviantOnly"
          switchProps={{
            id: 'inputExpressionDeviantOnly',
            onChange: onDeviantChange(props.storeWizard),
            checked: props.storeWizard.isDeviant,
            size: 'small'
          }}
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(SwitchIsDeviant)
