import { useStores } from '@app/hooks/useStores'
import * as React from 'react'
import EmailCommonForm from '../EmailCommonForm'
import { onEmailCreateSubmit } from './handlers'

interface IEmailCreateFormProps {}

const EmailCreateForm: React.FC<IEmailCreateFormProps> = () => {
  const { storeManagementEmails } = useStores()

  return (
    <EmailCommonForm
      version="creation"
      onSubmit={onEmailCreateSubmit(storeManagementEmails)}
    />
  )
}

export default EmailCreateForm
