import type { IGraphQLQuery } from '@libs/graphQL/types'
import type {
  CreateUserMutationArgs,
  DeleteUserMutationArgs,
  EditUserMutationArgs,
  EditUserRolesMutationArgs,
  Mutation,
  UpdateUserEulaMutationArgs
} from '../typeDefs/types'

export type MutationCreateUser = IGraphQLQuery<
  CreateUserMutationArgs,
  Pick<Mutation, 'createUser'>
>

export const mutationCreateUser = `
  mutation mutationCreateUser($user: InputCreateUser!) {
    createUser(
      user: $user
    ) {
      id
    }
  }
`

export type MutationUpdateUser = IGraphQLQuery<
  EditUserMutationArgs,
  Pick<Mutation, 'editUser'>
>

export const mutationEditUser = `
  mutation mutationEditUser($user: InputEditUser!) {
    editUser(
      user: $user
    ) {
      id
    }
  }
`

/* Update EULA */

export type MutationUpdateUserEula = IGraphQLQuery<
  UpdateUserEulaMutationArgs,
  Pick<Mutation, 'updateUserEula'>
>

export const mutationUpdateUserEula = `
  mutation mutationUpdateUserEula($userEula: InputUserEula!) {
    updateUserEula(
      userEula: $userEula
    ) {
      id
      eulaVersion
    }
  }
`

/* Delete user */

export type MutationDeleteUser = IGraphQLQuery<
  DeleteUserMutationArgs,
  Pick<Mutation, 'deleteUser'>
>

export const mutationDeleteUser = `
  mutation mutationDeleteUser($user: InputDeleteUser!) {
    deleteUser(
      user: $user
    )
  }
`

/* Edit roles */

export type MutationEditUserRoles = IGraphQLQuery<
  EditUserRolesMutationArgs,
  Pick<Mutation, 'editUserRoles'>
>

export const mutationEditUserRoles = `
  mutation mutationEditUserRoles($roles: InputEditUserRoles!) {
    editUserRoles(
      roles: $roles
    )
  }
`
