import { ContainerFlex } from '@app/components/Container'
import type { IIconProps } from '@app/components/Icon/types'
import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${consts.marginSmall};
`

interface IWidgetMenuLinkContentProps {
  icon?: React.FC<IIconProps>
  label: string
}

const WidgetMenuLinkContent: React.FC<IWidgetMenuLinkContentProps> = props => {
  const Icon = props.icon

  return (
    <ContainerFlex
      name="WidgetMenuLinkContent"
      alignItems="center"
      items={[
        Icon ? (
          <IconWrapper>
            <Icon />
          </IconWrapper>
        ) : null,
        <Label variant={LabelVariant.tenableLargeMenu}>{props.label}</Label>
      ]}
    ></ContainerFlex>
  )
}

export default WidgetMenuLinkContent
