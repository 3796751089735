import { useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DashboardCreateFirst from '../DashboardCreateFirst'
import { onDashboardCreateFirstButtonClick } from '../handlers'

interface IGridCreateFirstDashboardProps {}

const GridCreateFirstDashboard: React.FC<
  IGridCreateFirstDashboardProps
> = () => {
  const { storeDashboards } = useStores()

  if (storeDashboards.sortedDashboards.length) {
    return null
  }

  return (
    <DashboardCreateFirst
      onButtonClick={onDashboardCreateFirstButtonClick(storeDashboards)}
    />
  )
}

export default observer(GridCreateFirstDashboard)
