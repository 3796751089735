import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface IContainerHeaderProps {
  className?: string
  style?: React.CSSProperties
  children: React.ReactNode
}

const ContainerHeader: React.FC<IContainerHeaderProps> = props => {
  return (
    <div style={props.style} className={props.className}>
      {props.children}
    </div>
  )
}

export default styled(ContainerHeader)`
  width: 100%;
  padding: ${consts.marginSmall} ${consts.marginDefault};
  border-bottom: 1px solid ${consts.colorBlueGray030};
`
