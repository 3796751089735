import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { canAccessToHealthCheck } from '@app/pages/HealthCheck/permissions'
import type { StoreInputHealthChecks } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerContent from './DrawerContent'
import DrawerFooter from './DrawerFooter'
import DrawerHeader from './DrawerHeader'
import { useDrawerInputHealthChecksInit as useDrawerInputHealthChecksInit } from './hooks'

interface IDrawerInputstoreInputHealthChecksProps {
  onSubmit?: () => void
  withExpand?: boolean
  storeInputHealthChecks: StoreInputHealthChecks
}

const DrawerInputHealthChecks: React.FC<
  IDrawerInputstoreInputHealthChecksProps
> = props => {
  const storePendingInputHealthChecks = useDrawerInputHealthChecksInit(
    props.storeInputHealthChecks
  )

  const translate = useAppTranslator({
    namespaces: ['Components.InputHealthChecks']
  })

  return (
    <DrawerHorizontalContainer
      flags={
        storePendingInputHealthChecks.storeFlagsFetchHealthChecksTemplatesNames
          .flags
      }
    >
      <BladeContentLayoutDefault
        layout={{
          name: 'default',
          title: <DrawerTitle>{translate('Health checks')}</DrawerTitle>,
          header: (
            <DrawerHeader
              withExpand={props.withExpand}
              storeInputHealthChecks={storePendingInputHealthChecks}
            />
          ),
          content: (
            <DrawerContent
              withExpand={props.withExpand}
              storeInputHealthChecks={storePendingInputHealthChecks}
            />
          ),
          footer: (
            <DrawerFooter
              onSubmit={props.onSubmit}
              storeInputHealthChecks={props.storeInputHealthChecks}
              storePendingHealthChecks={storePendingInputHealthChecks}
            />
          )
        }}
        rbacCapabilityCheck={canAccessToHealthCheck}
      />
    </DrawerHorizontalContainer>
  )
}

export default observer(DrawerInputHealthChecks)
