import { Popover } from '@app/components/Popover'
import type { IPopoverRef } from '@app/components/Popover/Popover'
import * as React from 'react'
import WidgetUserPopover from './WidgetUserPopover'
import WidgetUserPopoverContainer from './WidgetUserPopoverContainer'

interface IWidgetUserProps {
  className?: string
}

const WidgetUser: React.FC<IWidgetUserProps> = props => {
  const popoverRef = React.useRef<IPopoverRef>(null)
  const triggerRef = React.useRef<HTMLDivElement>(null)

  function hidePopover(): void {
    popoverRef.current?.hidePopover()
  }

  return (
    <Popover
      ref={popoverRef}
      triggerRef={triggerRef}
      popoverProps={{
        content: <WidgetUserPopover hidePopover={hidePopover} />,
        placement: 'bottomRight',
        trigger: 'click'
      }}
    >
      <WidgetUserPopoverContainer ref={triggerRef} />
    </Popover>
  )
}

export default WidgetUser
