import type { IGraphQLQuery } from '@libs/graphQL/types'
import type { Query, UsersQueryArgs } from '../typeDefs/types'

/**
 * Retrieve users
 */
export type QueryUsers = IGraphQLQuery<
  UsersQueryArgs,
  Pick<Query, 'users' | 'rbacRoles' | 'rbacUserCreationDefaultsRbac'>
>

export const queryUsers = `
  query queryUsers(
    $usersPage: Int!
    $usersPerPage: Int!
  ) {
    users(
      usersPage: $usersPage
      usersPerPage: $usersPerPage
    ) {
      node {
        id
        surname
        name
        email
        department
        biography
        active
        lockedOut
        provider
        rbacRoles {
          node {
            id
            name
            description
            permissions {
              entityName
              action
              entityIds
            }
          }
          rbacCapability {
            isGranted
            isFound
            message
            rbacEntityName
          }
        }
      }
      pagination {
        page
        perPage
        totalCount
      }
    }
    rbacRoles {
      node {
        id
        name
        description
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
    rbacUserCreationDefaultsRbac {
      node {
        id
        name
        description
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Retrieve application settings
 */
export type QueryApplicationSettings = IGraphQLQuery<
  null,
  Pick<Query, 'rbacApplicationSettings'>
>

export const queryApplicationSettings = `
  query queryApplicationSettings {
    rbacApplicationSettings {
      node {
        userRegistration
        activityLogsIsActive
        activityLogsRetentionDurationInMonth
        smtpServerAddress
        smtpServerPort
        smtpAccount
        smtpAccountPassword
        smtpUseStartTLS
        tls
        emailSender
        defaultProfileId
        defaultRoleIds
        internalCertificate
        telemetryEnabled
        ioaAutomaticConfiguration
        smtpRelayId
        cloudStatisticsIsActive
        healthCheckGlobalStatusDisplayEnabled
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Retrieve preferences
 */
export type QueryPreferences = Pick<Query, 'preferences'>

export const queryPreferences = `
  query queryPreferences {
    preferences {
      language
      preferredProfileId
    }
  }
`

/**
 * Retrieve LDAP configuration
 */
export type QueryLDAPConfiguration = Pick<
  Query,
  'rbacLDAPConfiguration' | 'rbacProfiles' | 'rbacRoles'
>

export const queryLDAPConfiguration = `
  query queryLDAPConfiguration {
    rbacLDAPConfiguration {
      node {
        enabled
        url
        searchUserDN
        searchUserPassword
        userSearchBase
        userSearchFilter
        allowedGroups {
          name
          defaultProfileId
          defaultRoleIds
        }
        enableSaslBinding
        relayId
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
    rbacProfiles {
      node {
        id
        name
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
    rbacRoles {
      node {
        id
        name
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Retrieve SAML configuration
 */
export type QuerySAMLConfiguration = Pick<
  Query,
  'rbacSAMLConfiguration' | 'rbacProfiles' | 'rbacRoles'
>

export const querySAMLConfiguration = `
  query querySAMLConfiguration {
    rbacSAMLConfiguration {
      node {
        enabled
        providerLoginUrl
        signatureCertificate
        serviceProviderUrl
        assertEndpoint
        activateCreatedUsers
        allowedGroups {
          name
          defaultProfileId
          defaultRoleIds
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
    rbacProfiles {
      node {
        id
        name
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
    rbacRoles {
      node {
        id
        name
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
