import { Features } from '@alsid/common'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import { accessByFeatureFlag, readHealthCheck } from '@libs/rbac/permissions'

export const canAccessToHealthCheck = rbacCapabilityCheckAllOf(
  accessByFeatureFlag(Features.HEALTH_CHECK)(),
  readHealthCheck()
)

export const canAccessToHealthCheckDomainStatus = rbacCapabilityCheckAllOf(
  canAccessToHealthCheck
)

export const canAccessToHealthCheckPlatformStatus = rbacCapabilityCheckAllOf(
  canAccessToHealthCheck,
  accessByFeatureFlag(Features.PLATFORM_STATUS_HEALTH_CHECK)()
)
