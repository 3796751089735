import { ErrorBoundary } from '@app/components/Error'
import ContextStores from '@app/contexts/ContextStores'
import { StaticError } from '@app/pages/Error'
import type StoreRoot from '@app/stores/StoreRoot'
import type { IStores } from '@app/stores/types'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { buildVariants } from '@design-system/libs/buildVariants'
import { DSThemeName } from '@design-system/styles/themes/types'
import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { createGlobalStyle, StyleSheetManager } from 'styled-components'
import { createConfiguration, Provider } from 'tenable-design-system-alt'
import AntdConfigProvider from './AntdConfigProvider'
import App from './App'
import AppRibon from './AppRibon'

interface IRootProps {
  stores: IStores & { storeRoot: StoreRoot }
}

interface IGlobalStyleProps {
  isAutomationFlag: boolean
  isTulDisplayed: boolean
}

const GlobalStyle = createGlobalStyle<IGlobalStyleProps>(props => {
  return buildVariants(props)
    .css({
      // Add everything outside the body
      body: {
        overflow: 'hidden'
      }
    })

    .variant('isAutomationFlag', props.isAutomationFlag ?? false, {
      true: {
        // Add a border on tags with data-test if `isAutomationFlag` is up
        '[data-test]': {
          outline: '1px solid pink !important'
        }
      },

      false: {}
    })

    .variant('isTulDisplayed', props.isTulDisplayed ?? false, {
      true: {},

      false: {
        '#tenable-universal-layout': {
          display: 'none'
        }
      }
    })

    .end()
})

const Root: React.FC<IRootProps> = props => {
  const { storeRoot, storeLayout } = props.stores

  // can't use the useAppTranslator hook since the context is not set yet!
  const translate = storeRoot.appTranslator.bindOptions({
    namespaces: ['Errors']
  })

  const configuration = createConfiguration({
    paladinTheme: {
      // Use custom Paladin overrides here if you really need customizations
    }
  })

  return (
    <ContextStores.Provider value={{ storeRoot }}>
      <DSThemeProvider themeName={DSThemeName.legacy}>
        <StyleSheetManager
          disableVendorPrefixes={
            storeRoot.environment.config.production !== true
          }
        >
          <Provider configuration={configuration}>
            <AntdConfigProvider>
              <BrowserRouter>
                <ErrorBoundary
                  errorComponent={
                    <StaticError
                      reason={translate(
                        'An error has occurred during the loading of this page'
                      )}
                    >
                      <a href="/">{translate('Back to the home page')}</a>
                    </StaticError>
                  }
                >
                  <GlobalStyle
                    isAutomationFlag={props.stores.storeDebug.isAutomationFlag()}
                    isTulDisplayed={storeLayout.isTulDisplayed()}
                  />

                  <App />
                  <AppRibon />
                </ErrorBoundary>
              </BrowserRouter>
            </AntdConfigProvider>
          </Provider>
        </StyleSheetManager>
      </DSThemeProvider>
    </ContextStores.Provider>
  )
}

export default Root
