import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { ContainerHTMLContent } from '@app/components-legacy/Container'
import { Modal } from '@app/components-legacy/Modal'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  promptUseOfTenableCloudOnCancel,
  promptUseOfTenableCloudOnOk
} from './handlers'

export interface IModalPromptUseOfTenableCloudProps {}

const ModalPromptUseOfTenableCloud: React.FC<
  IModalPromptUseOfTenableCloudProps
> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Titles',
      'Buttons',
      'Management.System.Configuration.DataCollection'
    ]
  })

  const { storeManagementAzureAD } = useStores()

  return (
    <Modal
      modalProps={{
        title: translate('Enable tenable cloud service'),
        getContainer: '.LayoutDefaultThird',
        okText: translate('Enable full identity experience'),
        cancelText: translate('Keep tenable cloud services disabled'),
        closable: true,
        maskClosable: true,
        width: '680px',
        onOk: promptUseOfTenableCloudOnOk(storeManagementAzureAD),
        onCancel: promptUseOfTenableCloudOnCancel(storeManagementAzureAD)
      }}
      storeModal={storeManagementAzureAD.storeModalPromptUseOfTenableCloud}
    >
      <Label variant={LabelVariant.p}>
        <ContainerHTMLContent padded>
          {translate('Enable full identity experience body')}
        </ContainerHTMLContent>
      </Label>
    </Modal>
  )
}

export default observer(ModalPromptUseOfTenableCloud)
