import { AppRouteName } from '@app/routes'
import type { ReportMetadata } from '@server/graphql/typeDefs/types'
import StoreFlags from '../helpers/StoreFlags'
import StoreForm from '../helpers/StoreForm'
import { mandatory } from '../helpers/StoreForm/validators'
import StoreBase from '../StoreBase'
import type { ReportsFieldName } from './types'

export default class StoreReports extends StoreBase {
  public storeFlagsReportMetadata = new StoreFlags(this.storeRoot, {})

  public storeForm: StoreForm<ReportsFieldName> =
    new StoreForm<ReportsFieldName>(this.storeRoot, {
      setup: {
        fields: {
          uuid: {
            label: 'uuid',
            validators: [mandatory()]
          },
          accesstoken: {
            label: 'Access token',
            validators: [mandatory()]
          }
        }
      }
    })

  /**
   * Fetch report metadata.
   */
  fetchReportMetadata(parameters: {
    uuid: string
    accesstoken: string
  }): Promise<void | ReportMetadata> {
    function isReportMetadata(data: object): data is ReportMetadata {
      return 'type' in data && 'startDate' in data && 'endDate' in data
    }

    this.storeFlagsReportMetadata.loading()

    const url = this.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareReportingCenterReports_Metadata,
      parameters: {},
      queryStringParameters: parameters
    })

    return Promise.resolve()
      .then(() => {
        return this.storeRoot.fetchClient.get(url, {})
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Report not found')
        }
        return response.json()
      })
      .then(reportMetadata => {
        if (!isReportMetadata(reportMetadata)) {
          throw new Error('Invalid report metadata')
        }

        this.storeFlagsReportMetadata.success()
        return reportMetadata
      })
      .catch(err => {
        this.storeFlagsReportMetadata.fail()
        throw err
      })
  }
}
