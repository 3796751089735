import { BladeCancelButton } from '@app/components-legacy/Blade'
import {
  ContainerContent,
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import { useAppTranslator, useStores } from '@app/hooks'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { InfrastructuresFormFieldName } from '@app/stores/Management/StoreInfrastructures'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export interface IInfrastructureCommonFormProps {
  version: 'creation' | 'edition'
  onSubmit: (e: React.FormEvent<any>) => void
  storeForm: StoreForm<any>
}

const InfrastructureCommonForm: React.FC<
  IInfrastructureCommonFormProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Titles', 'Management.System.Infrastructures']
  })
  const { storeManagementInfrastructures } = useStores()

  return (
    <FormWrapper name="infrastructureConfiguration" onSubmit={props.onSubmit}>
      <>
        <ContainerFlex
          name="InfrastructuresCreateForm"
          direction="column"
          items={[
            <ContainerContent title={translate('Main information')}>
              <WidgetForm<InfrastructuresFormFieldName>
                translate={translate}
                storeForm={props.storeForm}
                displayedFields={[InfrastructuresFormFieldName.name]}
              />
            </ContainerContent>,

            <ContainerContent title={translate('Account')}>
              <WidgetForm<InfrastructuresFormFieldName>
                translate={translate}
                storeForm={props.storeForm}
                displayedFields={[
                  InfrastructuresFormFieldName.login,
                  InfrastructuresFormFieldName.password
                ]}
              />
            </ContainerContent>
          ]}
          spaced
        />

        <Portal name={PlaceHolderName.bladeFooter}>
          <ContainerFooter>
            <BladeCancelButton />

            <FormWrapperButtonSubmit
              type="primary"
              loading={
                storeManagementInfrastructures.storeCreationFlags.isLoading
              }
            >
              {translate(props.version === 'creation' ? 'Add' : 'Edit')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </>
    </FormWrapper>
  )
}

export default observer(InfrastructureCommonForm)
