import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

const StyledPath = styled.path`
  fill: ${consts.colorBlue005};
  clip-rule: evenodd;
  fill-rule: evenodd;
`

interface IIconSmartphoneProps {
  className?: string
}

const IconSmartphone: React.FC<IIconSmartphoneProps> = props => {
  return (
    <svg
      className={props.className}
      viewBox="0 0 13 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPath
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 2C13 0.89543 12.1046 0 11 0H2C0.89543 0 0 0.89543 0 2V20C0 21.1046 0.89543 22 2 22H11C12.1046 22 13 21.1046 13 20V2ZM2 1H11L11.1166 1.00673C11.614 1.06449 12 1.48716 12 2V20L11.9933 20.1166C11.9355 20.614 11.5128 21 11 21H2L1.88338 20.9933C1.38604 20.9355 1 20.5128 1 20V2L1.00673 1.88338C1.06449 1.38604 1.48716 1 2 1ZM5 2.5C5 2.22386 5.22386 2 5.5 2H7.5C7.77614 2 8 2.22386 8 2.5C8 2.77614 7.77614 3 7.5 3H5.5C5.22386 3 5 2.77614 5 2.5ZM6.5 18C5.94772 18 5.5 18.4477 5.5 19C5.5 19.5523 5.94772 20 6.5 20C7.05228 20 7.5 19.5523 7.5 19C7.5 18.4477 7.05228 18 6.5 18Z"
      />
    </svg>
  )
}

export default styled(IconSmartphone)`
  width: 13px;
  height: 22px;
  fill: none;
`
