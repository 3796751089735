import { ContainerFlex, ContainerForm } from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import { InputMultiValues } from '@app/components-legacy/Input'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreForm from '@app/stores/helpers/StoreForm'
import {
  onInputChange,
  onInputNumberChange,
  onMultiValuesChange
} from '@app/stores/helpers/StoreForm/handlers'
import type StoreProfileCheckerOptionArrayObject from '@app/stores/Management/StoreProfiles/StoreProfileCheckerOptionOMCGMAW'
import { CheckerOptionOMCGMAWConfigurationFieldName as FieldName } from '@app/stores/Management/StoreProfiles/types'
import { Input, InputNumber } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import GroupConfigurationTitle from './GroupConfigurationTitle'

interface IGroupConfigurationProps {
  index: number
  highlight: boolean
  readonly: boolean
  storeFormConfiguration: StoreForm<FieldName>
  storeProfileCheckerOptionArrayObject: StoreProfileCheckerOptionArrayObject
}

const GroupConfiguration: React.FC<IGroupConfigurationProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Profiles.Configuration']
  })

  /**
   * Return the fields of the option configuration according to the StoreForm
   * instance passed in props.
   */
  const serieFields: IField[] = [
    {
      name: FieldName.distinguishedName,
      label: translate(FieldName.distinguishedName),
      errors: props.storeFormConfiguration.field(FieldName.distinguishedName)
        .errors,
      highlight: props.highlight,
      control: (
        <Input
          data-name="string"
          name={FieldName.distinguishedName}
          value={props.storeFormConfiguration.getFieldValueAsString(
            FieldName.distinguishedName
          )}
          disabled={props.readonly}
          onChange={onInputChange(props.storeFormConfiguration)(
            FieldName.distinguishedName
          )}
        />
      )
    },
    {
      name: FieldName.nbUsersMax,
      label: translate(FieldName.nbUsersMax),
      errors: props.storeFormConfiguration.field(FieldName.nbUsersMax).errors,
      highlight: props.highlight,
      control: (
        <InputNumber
          data-name="number"
          name={FieldName.nbUsersMax}
          value={props.storeFormConfiguration.getFieldValueAsNumber(
            FieldName.nbUsersMax
          )}
          min={0}
          disabled={props.readonly}
          onChange={onInputNumberChange(props.storeFormConfiguration)(
            FieldName.nbUsersMax
          )}
        />
      )
    },
    {
      name: FieldName.usersWhiteList,
      label: translate(FieldName.usersWhiteList),
      errors: props.storeFormConfiguration.field(FieldName.usersWhiteList)
        .errors,
      highlight: props.highlight,
      control: (
        <InputMultiValues
          data-name="array/string"
          name={FieldName.usersWhiteList}
          disabled={props.readonly}
          onChange={onMultiValuesChange(props.storeFormConfiguration)}
          storeForm={props.storeFormConfiguration}
        />
      )
    }
  ]

  return (
    <ContainerFlex
      name={GroupConfiguration.name}
      direction="column"
      items={[
        <GroupConfigurationTitle
          index={props.index}
          storeProfileCheckerOptionArrayObject={
            props.storeProfileCheckerOptionArrayObject
          }
        />,

        <ContainerForm
          fields={serieFields}
          variant={ContainerFormVariant.fill}
        />
      ]}
      spaced
      spaceWidth="small"
    />
  )
}

export default observer(GroupConfiguration)
