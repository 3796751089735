import { CheckerType } from '@server/graphql/typeDefs/types'
import { action, makeObservable } from 'mobx'
import type { StoreRoot } from '..'
import StoreDrawer from '../helpers/StoreDrawer'
import StoreMenu from '../helpers/StoreMenu'
import type { IMenuEntry } from '../helpers/StoreMenu/types'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'
import StoreGenericAlerts from './StoreGenericAlerts'
import type { IAlertAttack, IAlertExposure } from './types'

export default class StoreAlerts extends StoreBase {
  public storeAlertsIoE = new StoreGenericAlerts<IAlertExposure>(
    this.storeRoot,
    { checkerType: CheckerType.Exposure }
  )

  public storeAlertsIoA = new StoreGenericAlerts<IAlertAttack>(this.storeRoot, {
    checkerType: CheckerType.Attack
  })

  public storeDrawer = new StoreDrawer(this.storeRoot)

  public storeMenu = new StoreMenu<IMenuEntry<CheckerType>>(this.storeRoot)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /* Actions */

  @action
  reset(): this {
    this.storeAlertsIoE.reset()
    this.storeAlertsIoA.reset()

    return this
  }

  /**
   * Close the drawer and unselect all alerts.
   */
  @action
  closeDrawer(): void {
    this.storeDrawer.closeDrawer()

    this.storeAlertsIoA.storeWidgetList.unselectAllRows()
    this.storeAlertsIoE.storeWidgetList.unselectAllRows()
  }
}
