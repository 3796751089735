import { consts } from '@app/styles'
import * as React from 'react'
import { BulletCircle } from '.'

interface IBulletStatusKOProps {}

const BulletStatusKO: React.FC<IBulletStatusKOProps> = props => {
  return <BulletCircle color={consts.colorRed001} />
}

export default BulletStatusKO
