import { ContainerFlex } from '@app/components-legacy/Container'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { consts } from '@app/styles'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { TrustHazardLevel } from '@server/graphql/typeDefs/types'
import { values } from 'lodash'
import * as React from 'react'
import styled from 'styled-components'
import { sceneLegendZIndex } from './zIndexes'

interface ISceneLegendProps {
  className?: string
}

const SceneLegend: React.FC<ISceneLegendProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Topology.Legend']
  })

  return (
    <div data-name="SceneLegend" className={props.className}>
      <ContainerFlex
        name="legend"
        direction="column"
        items={[
          <section className="part">
            <span className="title">{translate('Type')}</span>
            <ul>
              <li className="forest">{translate('Forest')}</li>
              <li className="domain">{translate('Domain')}</li>
              <li className="domain unknown">{translate('Unknown domain')}</li>
            </ul>
          </section>,

          <section className="part">
            <span className="title">{translate('Threat level')}</span>
            <ul>
              {values(TrustHazardLevel).map((levelType: TrustHazardLevel) => {
                switch (levelType) {
                  case TrustHazardLevel.Dangerous:
                    return (
                      <li
                        key={`level-${TrustHazardLevel.Dangerous}`}
                        className="level dangerous"
                      >
                        {translate('Dangerous')}
                      </li>
                    )
                  case TrustHazardLevel.Unknown:
                    return (
                      <li
                        key={`level-${TrustHazardLevel.Unknown}`}
                        className="level unknown"
                      >
                        {translate('Unknown')}
                      </li>
                    )
                  case TrustHazardLevel.Regular:
                    return (
                      <li
                        key={`level-${TrustHazardLevel.Regular}`}
                        className="level regular"
                      >
                        {translate('Regular')}
                      </li>
                    )
                  default:
                    assertUnreachableCase(levelType)
                }
              })}
            </ul>
          </section>
        ]}
      />
    </div>
  )
}

export default styled(SceneLegend)`
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 240px;
  padding: ${consts.paddingLarge};
  background: ${consts.colorWhiteAlt};
  z-index: ${sceneLegendZIndex};
  border-radius: 8px;

  .title {
    text-transform: uppercase;
    font-weight: 600;
  }

  .part:first-child {
    margin-bottom: ${consts.paddingLarge};
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 10px 0 0;

    li {
      display: flex;
      align-items: center;
      margin: 10px 0;

      &:before {
        content: '';
        margin-right: ${consts.paddingMedium};
      }

      &.forest:before {
        border-radius: 50%;
        width: 15px;
        height: 15px;
        border: 1px solid ${consts.colorBlueGray010};
      }

      &.domain {
        &:before {
          width: 15px;
          height: 15px;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 14px solid black;
        }
        &.unknown:before {
          border-bottom: 14px solid ${consts.colorOrange001};
        }
      }

      &.level {
        &:before {
          width: 15px;
          height: 2px;
        }

        &.dangerous:before {
          background-color: ${consts.colorRed001};
        }

        &.unknown:before {
          background-color: ${consts.colorOrange001};
        }

        &.regular:before {
          background-color: ${consts.colorBlue010};
        }
      }
    }
  }
`
