import { FormWrapperSelect } from '@app/components-legacy/Form'
import { useAppTranslator } from '@app/hooks'
import { range } from 'lodash'
import * as React from 'react'
import { UnitPrefix } from '../types'
import CronfieldControlContainer from './CronfieldControlContainer'

export interface ISelectDaysOfTheMonthProps {
  days: string[]
  onChange: (days: string[]) => void
}

export default function SelectDaysOfTheMonth(
  props: ISelectDaysOfTheMonthProps
) {
  const translate = useAppTranslator({
    namespaces: ['Components.InputCrontab']
  })

  const selectOptions = range(1, 32).map(day => {
    return {
      label: String(day),
      value: String(day)
    }
  })

  const emptySelection = props.days.length === 0

  return (
    <CronfieldControlContainer
      name="SelectDaysOfTheMonth"
      unitPrefix={UnitPrefix.On}
      hidePrefix={emptySelection}
    >
      <FormWrapperSelect<string[]>
        labelledBy="format"
        width={emptySelection ? 'large' : 'medium'}
        selectProps={{
          placeholder: translate('Every day of the month'),
          allowClear: true,
          value: props.days,
          onChange: props.onChange,
          mode: 'multiple'
        }}
        selectOptions={selectOptions}
      />
    </CronfieldControlContainer>
  )
}
