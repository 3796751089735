import { LabelCheckerSize } from '@app/components-legacy/Label/LabelChecker/types'
import styled from 'styled-components'

export function setTableColumnsStyled(
  component: React.ComponentType<any>,
  options: {
    header: boolean
  }
) {
  return styled(component)`
    .attackDate {
      width: 70px;
      margin: 0 10px 0 0;

      /* border: 1px solid red; */
    }

    .attackColumns {
      /* border: 1px solid lime; */

      > div {
        overflow: hidden;
        flex-shrink: 0;
        margin: 0 10px 0 0;

        /* border: 1px solid blue; */
      }

      .attackSource {
        min-width: 175px;
        flex: 12.5%;
        padding-left: 10px;
      }

      .attackVector {
        min-width: 200px;
        flex: 15%;
      }

      .attackDestination {
        min-width: 175px;
        flex: 12.5%;
      }

      .attackName {
        min-width: ${LabelCheckerSize.small};
        flex: 15%;
      }

      .attackDirectory {
        min-width: 200px;
        flex: 15%;
      }

      .attackExpandButton {
        // hack to compensate the lack of right margin in the header
        flex: ${options.header ? '100px' : '80px'};
      }

      .attackCheckbox {
        width: 55px;
      }
    }
  `
}
