import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import type {
  IEntityExportable,
  IEntityExportableAsCsvColumnsParameters,
  IEntityExportableAsCsvRowParameters,
  IEntityListable
} from '@app/entities/types'
import type {
  IDataRowGeneric,
  IWidgetListColumns
} from '@app/stores/helpers/StoreWidgetList/types'
import { sanitizeCsvValue } from '@libs/csv-protector'
import { isDefined } from '@libs/isDefined'
import type {
  Identity,
  Score,
  Tenant
} from '@libs/openapi/service-identity-core'
import { ensureArray } from '@productive-codebases/toolbox'
import type { Maybe } from '@server/graphql/typeDefs/types'

/**
 * Used for identities CSV exports.
 */
export interface IDataRowIdentityExportable {
  id: Maybe<number>
  name: Maybe<number>
  tenants: Maybe<string[]>
  exposureScore: Maybe<number>
  openedRisksCount: Maybe<number>
  totalAccessibleResources: Maybe<number>
}

export default class EntityIdentity
  extends EntityBase
  implements
    PropertiesNullable<Identity>,
    IEntityListable<IDataRowGeneric>,
    IEntityExportable<IDataRowIdentityExportable>
{
  uuid: Maybe<string> = null
  name: Maybe<string> = null
  tenants: Maybe<Tenant[]> = null
  exposureScore: Maybe<Score> = null
  openedRisksCount: Maybe<number> = null
  totalAccessibleResources: Maybe<number> = null

  constructor(data: Partial<Identity>) {
    super()
    Object.assign(this, data)
  }

  /** Implements IEntityListable */

  /**
   * Not used, columns are hardcoded in the view with a custom render.
   */
  getColumns(): Array<IWidgetListColumns<IDataRowIdentityExportable>> {
    return []
  }

  /** Implements IEntityExportable */

  getCSVColumns(
    parameters: IEntityExportableAsCsvColumnsParameters<IDataRowIdentityExportable>
  ): Array<IWidgetListColumns<IDataRowIdentityExportable>> {
    const columns: Array<IWidgetListColumns<IDataRowIdentityExportable>> = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Name',
        key: 'name'
      },
      {
        label: 'Tenants',
        key: 'tenants'
      },
      {
        label: 'Exposure score',
        key: 'exposureScore'
      },
      {
        label: 'Opened Risks Count',
        key: 'openedRisksCount'
      },
      {
        label: 'Total Accessible Resources',
        key: 'totalAccessibleResources'
      }
    ]

    return columns.filter(column => !parameters.omitKeys.includes(column.key))
  }

  /**
   * Return the entity as a string for CSV exports.
   */
  asCSVRow(
    parameters: IEntityExportableAsCsvRowParameters<IDataRowIdentityExportable>
  ): Maybe<string> {
    const csvRowValues: Record<keyof IDataRowIdentityExportable, string> = {
      id: sanitizeCsvValue(this.uuid),
      name: sanitizeCsvValue(this.name),
      tenants: sanitizeCsvValue(
        ensureArray(this.tenants)
          .map(tenant => tenant.name)
          .filter(isDefined)
          .join(', ')
      ),
      exposureScore: sanitizeCsvValue(this.exposureScore?.value),
      openedRisksCount: sanitizeCsvValue(this.openedRisksCount),
      totalAccessibleResources: sanitizeCsvValue(this.totalAccessibleResources)
    }

    const columnsKeys = this.getCSVColumns(parameters).map(column => column.key)

    return Object.entries(csvRowValues)
      .filter(([key]) =>
        columnsKeys.includes(key as keyof IDataRowIdentityExportable)
      )
      .map(([_, value]) => value)
      .join(parameters.separator)
  }
}
