import type { StoreManagementRelays } from '@app/stores'

export const handleRelayOnLoad =
  (storeManagementRelays: StoreManagementRelays) => () => {
    storeManagementRelays.fetchRelayLinkingKey()
    storeManagementRelays.fetchRelays()
  }

export const handleRelayOnUnload =
  (storeManagementRelays: StoreManagementRelays) => () => {
    storeManagementRelays.reset()
  }
