import { ContainerFlex } from '@app/components-legacy/Container'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface IIoAAttacksTableBodyDataRowDateColoredDotProps {
  className?: string
  style?: React.CSSProperties
  color: string
}

const VerticalLine = styled.hr`
  height: 100%;
  width: 1px;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  border-left: 1px solid ${consts.colorBlueGray025};
  margin: 0;
`

const Circle = styled.div<{ color: string }>`
  position: absolute;
  height: 7px;
  width: 7px;
  background-color: ${props => props.color};
  border-radius: 50%;
`

const CircleContainerFlex = styled(ContainerFlex)`
  position: absolute;
  top: 0;
  height: 72px;
`

const IoAAttacksTableBodyDataRowDateColoredDot: React.FC<
  IIoAAttacksTableBodyDataRowDateColoredDotProps
> = props => {
  return (
    <ContainerFlex
      name="IoAAttacksTableBodyDataRowDateColoredDot"
      className={props.className}
      style={props.style}
      justifyContent="center"
      alignItems="center"
      items={[
        <VerticalLine />,

        <CircleContainerFlex
          name="IoAAttacksTableBodyDataRowDateColoredDotCircle"
          items={<Circle color={props.color} />}
          justifyContent="center"
          alignItems="center"
        />
      ]}
    />
  )
}

export default styled(IoAAttacksTableBodyDataRowDateColoredDot)`
  position: relative;
  width: 11px;
`
