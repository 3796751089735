import type { StoreInputCrontab } from '@app/stores/helpers/StoreInputCrontab'
import type { InputCrontabPeriod } from '@app/stores/helpers/StoreInputCrontab/types'
import { InputCrontabUnit } from '@app/stores/helpers/StoreInputCrontab/types'

/**
 * Change form layout according to the period.
 */
export const handlePeriodOnChange =
  (storeInputCrontab: StoreInputCrontab) => (value: string) => {
    storeInputCrontab.setPeriod(value as InputCrontabPeriod)
  }

/**
 * Handle months selection.
 */
export const handleMonthsOnChange =
  (storeInputCrontab: StoreInputCrontab) => (months: string[]) => {
    storeInputCrontab.storeForm.setFieldValue(
      InputCrontabUnit.months,
      JSON.stringify(months)
    )
  }

/**
 * Handle days of the month selection.
 */
export const handleDaysOfTheMonthOnChange =
  (storeInputCrontab: StoreInputCrontab) => (daysOfTheMonth: string[]) => {
    storeInputCrontab.storeForm.setFieldValue(
      InputCrontabUnit.daysOfTheMonth,
      JSON.stringify(daysOfTheMonth)
    )
  }

/**
 * Handle day of the week selection.
 */
export const handleDaysOfTheWeekOnChange =
  (storeInputCrontab: StoreInputCrontab) => (daysOfTheWeek: string[]) => {
    storeInputCrontab.storeForm.setFieldValue(
      InputCrontabUnit.daysOfTheWeek,
      JSON.stringify(daysOfTheWeek)
    )
  }

/**
 * Handle day of the week selection.
 */
export const handleHoursOnChange =
  (storeInputCrontab: StoreInputCrontab) => (hours: string[]) => {
    storeInputCrontab.storeForm.setFieldValue(
      InputCrontabUnit.hours,
      JSON.stringify(hours)
    )
  }

/**
 * Handle day of the week selection.
 */
export const handleMinuteOnChange =
  (storeInputCrontab: StoreInputCrontab) => (minute: string) => {
    storeInputCrontab.storeForm.setFieldValue(
      InputCrontabUnit.minute,
      minute ? JSON.stringify([minute]) : JSON.stringify(['0'])
    )
  }
