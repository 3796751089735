import { ContainerFlex } from '@app/components/Container'
import { HorizontalLine } from '@app/components/Separator'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled from 'styled-components'

interface ITypographyFieldsetTitleProps {
  className?: string
  // default padding of h3 by default
  padded?: boolean
  children?: React.ReactNode
}

const H3 = styled.h3`
  margin: 0;
`

const TypographyFieldsetTitle: React.FC<
  ITypographyFieldsetTitleProps
> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  return themeIsLegacy ? (
    <h3 data-name="TypographyFieldsetTitle" className={props.className}>
      {props.children}
    </h3>
  ) : (
    <ContainerFlex
      name="TypographyFieldsetTitle"
      flexGap="smaller"
      alignItems="center"
      items={[
        <H3 data-name="TypographyFieldsetTitle" className={props.className}>
          {props.children}
        </H3>,
        <HorizontalLine color={consts.CTAColorV2.disable} />
      ]}
      itemsFlexShrink={[0, 1]}
    />
  )
}

export default styled(TypographyFieldsetTitle)`
  text-transform: uppercase;
  font-weight: ${({ theme }) =>
    theme.tokens['dashboardWidget/fontWeight/fieldSetTitle']};
  font-size: ${consts.textSizeNormal};
  color: ${consts.colorBlueGray015};
  ${props => (props.padded === false ? 'margin: 0px' : '')};
`
