import * as React from 'react'
import styled from 'styled-components'

export enum BulletTriangleSize {
  small = 4
}

interface IBulletTriangleProps {
  className?: string
  color: string
  size?: BulletTriangleSize
}

/** @deprecated */
const BulletTriangleRaw = (props: IBulletTriangleProps) => {
  return <div className={props.className} />
}

export default styled(BulletTriangleRaw)`
  width: 0;
  height: 0;
  margin-top: 6px;
  border-left: ${props =>
    `${props.size || BulletTriangleSize.small}px solid transparent`};
  border-right: ${props =>
    `${props.size || BulletTriangleSize.small}px solid transparent`};
  border-bottom: ${props =>
    `${props.size || BulletTriangleSize.small * 2}px solid ${props.color}`};
`
