import { AppMenu } from '@app/components-legacy/Menu'
import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { AppRouteName } from '@app/routes'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { filterFalsies } from '@libs/filterFalsies'
import * as React from 'react'
import {
  canAccessToAdAndIdentityIoE,
  canAccessToAdIoE,
  canAccessToIdentityIoE
} from './permissions'
import { IoEBoardMenuItemKey } from './types'

export interface IIoEBoardMenuFilterProps {}

const IoEBoardMenuItems: React.FC<IIoEBoardMenuFilterProps> = () => {
  const { storeRbac, storeIoE } = useStores()

  const translate = useAppTranslator({
    namespaces: ['IoE.MenuFilter']
  })

  const storeMenu = useStoreMenuInit<IMenuEntry<IoEBoardMenuItemKey>>({
    storeMenu: storeIoE.storeMenuIndicatorsFilter,
    defaultSelectedMenuKey: IoEBoardMenuItemKey.All,
    menuEntries: filterFalsies([
      storeRbac.isUserGrantedTo(canAccessToAdAndIdentityIoE) && {
        key: IoEBoardMenuItemKey.All,
        label: translate('All'),
        labelledBy: 'All',
        routeDefinition: {
          routeName: AppRouteName.IoE_Board,
          parameters: {}
        }
      },
      storeRbac.isUserGrantedTo(canAccessToAdIoE) && {
        key: IoEBoardMenuItemKey.ActiveDirectory,
        label: translate('Active Directory'),
        labelledBy: 'ActiveDirectory',
        routeDefinition: {
          routeName: AppRouteName.IoE_Board_AD,
          parameters: {}
        }
      },
      storeRbac.isUserGrantedTo(canAccessToIdentityIoE) && {
        key: IoEBoardMenuItemKey.AzureAD,
        label: translate('Azure AD'),
        labelledBy: 'AzureAD',
        routeDefinition: {
          routeName: AppRouteName.IoE_Board_MEID,
          parameters: {}
        }
      }
    ])
  })

  return <AppMenu storeMenu={storeMenu} />
}

export default IoEBoardMenuItems
