import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconDirectionPreviousActionProps extends IIconProps {}

const IconDirectionPreviousAction: React.FC<
  IIconDirectionPreviousActionProps
> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.28377 1.43249V0L3.08348 2.37935L7.25948 4.83158L7.28381 3.35055C10.2458 3.35055 12.6495 5.75424 12.6495 8.71627C12.6252 11.6783 10.2215 14.082 7.28381 14.082C4.32181 14.082 1.91809 11.6784 1.91809 8.71627H0C0 12.7223 3.25343 16 7.28373 16C11.2897 16 14.5675 12.7466 14.5675 8.71627C14.5431 4.71027 11.2897 1.45683 7.28373 1.43255L7.28377 1.43249Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconDirectionPreviousAction
