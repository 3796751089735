import type { TextAreaProps } from 'antd/lib/input/TextArea'
import TextArea from 'antd/lib/input/TextArea'
import * as React from 'react'
import styled from 'styled-components'

interface IInputCodeProps {
  className?: string
  textAreaProps?: TextAreaProps
}

/**
 * @deprecated
 */
const InputCode: React.FC<IInputCodeProps> = props => {
  return <TextArea className={props.className} {...props.textAreaProps} />
}

export default styled(InputCode)`
  font-family: 'Source Code Pro';
  font-size: 10px;
  white-space: pre-wrap;
`
