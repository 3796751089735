import { LabelBreakable } from '@app/components-legacy/Label'
import { consts } from '@app/styles'
import type { TranslateFn } from '@libs/i18n'
import type * as jsDiff from 'diff'
import * as React from 'react'
import styled from 'styled-components'
import { ContainerFlex } from '../Container'

/* Addition component */

interface IAdditionRawProps {
  className?: string
  children: React.ReactNode
  labelledBy?: string
}

const AdditionRaw: React.FC<IAdditionRawProps> = props => {
  return (
    <LabelBreakable className={props.className} labelledBy={props.labelledBy}>
      {props.children}
    </LabelBreakable>
  )
}

const Addition = styled(AdditionRaw)`
  color: ${consts.colorGreen005};
`

/* Deletion component */

interface IDeletionRawProps {
  className?: string
  children: React.ReactNode
  labelledBy?: string
}

const DeletionRaw: React.FC<IDeletionRawProps> = props => {
  return (
    <LabelBreakable className={props.className} labelledBy={props.labelledBy}>
      {props.children}
    </LabelBreakable>
  )
}

const Deletion = styled(DeletionRaw)`
  color: red;
  text-decoration: line-through;
`

/* Unchanged component */

interface IUnchangedRawProps {
  className?: string
  children: React.ReactNode
  labelledBy?: string
}

const UnchangedRaw: React.FC<IUnchangedRawProps> = props => {
  return (
    <LabelBreakable className={props.className} labelledBy={props.labelledBy}>
      {props.children}
    </LabelBreakable>
  )
}

const Unchanged = styled(UnchangedRaw)`
  color: silver;
`

/* LabelDiffLegend component */

interface ILabelDiffLegendProps {
  className?: string
  translate: TranslateFn
  labelledBy?: string
}

export const LabelDiffLegend: React.FC<ILabelDiffLegendProps> = props => {
  return (
    <ContainerFlex
      name="LabelDiffLegend"
      className={props.className}
      direction="column"
      items={[
        <Addition
          labelledBy={`${
            props.labelledBy ? `${props.labelledBy}/` : ''
          }addition`}
        >
          {props.translate('Addition')}
        </Addition>,
        <Deletion
          labelledBy={`${
            props.labelledBy ? `${props.labelledBy}/` : ''
          }deletion`}
        >
          {props.translate('Deletion')}
        </Deletion>,
        <Unchanged
          labelledBy={`${
            props.labelledBy ? `${props.labelledBy}/` : ''
          }unchanged`}
        >
          {props.translate('Unchanged')}
        </Unchanged>
      ]}
    />
  )
}

/* LabelDiff component */

export interface ILabelDiffProps {
  className?: string
  diffs: jsDiff.Change[]
  labelledBy?: string
}

const LabelDiff: React.FC<ILabelDiffProps> = props => {
  const wrappers = props.diffs.map(diff => {
    const Wrapper = diff.added ? Addition : diff.removed ? Deletion : Unchanged

    return <Wrapper labelledBy={props.labelledBy}>{diff.value}</Wrapper>
  })

  return <ContainerFlex name="LabelDiff" flexWrap="wrap" items={wrappers} />
}

export default LabelDiff
