import { AppRouteName } from '@app/routes'
import type { StoreManagementEmails } from '@app/stores'
import { getCriticityString, getCriticityValue } from '@libs/criticity'
import { If } from '@libs/fp-helpers/If'
import type { InputEditEmail } from '@server/graphql/typeDefs/types'
import { EmailInputType } from '@server/graphql/typeDefs/types'
import { canSelectTriggerOnDeviances } from '../../permissions'
import { EmailFormFieldName } from '../types'

export const onEmailEditLoad =
  (storeManagementEmails: StoreManagementEmails) => (emailId: number) => () => {
    const { storeRbac } = storeManagementEmails.storeRoot.stores

    const {
      storeInfrastructures,
      storeForm,
      storeInputCheckersAttacks,
      storeInputCheckersExposure,
      storeInputHealthChecks
    } = storeManagementEmails

    return Promise.all([
      If(!storeManagementEmails.emails.size).fetch(() =>
        storeManagementEmails.fetchEmails()
      ),
      // fetch everything before being able to select default values in the form
      storeInfrastructures.fetchInfrastructures(),
      storeInputCheckersAttacks.fetchAttackCheckers(),
      storeInputCheckersExposure.fetchExposureCheckers(),
      storeInputHealthChecks.fetchHealthChecksTemplatesNames()
    ])
      .then(() => {
        const emailEntity = storeManagementEmails.emails.get(emailId)
        const emailRow = emailEntity && emailEntity.asDataRow()

        if (!emailRow) {
          return
        }

        // retrieve the inputType according to the type of license
        const inputType =
          // if "on deviances" selected and the user is not allowed to see deviances, select "on attacks"
          emailRow.inputType === EmailInputType.Deviances &&
          !storeRbac.isUserGrantedTo(canSelectTriggerOnDeviances)
            ? EmailInputType.Attacks
            : // otherwise, keep the selection
              emailRow.inputType

        storeForm
          .hardReset()
          .setDefaultFieldsValuesFromObject({
            [EmailFormFieldName.address]: emailRow.address,
            [EmailFormFieldName.description]: emailRow.description,
            [EmailFormFieldName.shouldNotifyOnInitialFullSecurityCheck]:
              emailRow.shouldNotifyOnInitialFullSecurityCheck,
            // convert the list of profiles to a string
            [EmailFormFieldName.profiles]: emailRow.profiles.join(','),
            [EmailFormFieldName.inputType]: inputType,
            // get a valid CriticityThreshold value from a number between 0 and 100
            [EmailFormFieldName.criticityThreshold]: getCriticityValue(
              getCriticityString(emailRow.criticityThreshold)
            ),
            [EmailFormFieldName.checkers]: emailRow.checkers,
            [EmailFormFieldName.attackTypes]: emailRow.attackTypes,
            // convert the list of directories to a string
            [EmailFormFieldName.directories]: emailRow.directories.join(','),
            [EmailFormFieldName.healthCheckNames]:
              emailRow.healthCheckNames.join(',')
          })
          .reset()

        storeInfrastructures.selectDirectories(emailRow.directories)
        storeInputHealthChecks.selectHealthChecksTemplatesNames(
          emailRow.healthCheckNames
        )
        storeInputCheckersExposure.selectCheckersFromIds(emailRow.checkers)
        storeInputCheckersAttacks.selectCheckersFromIds(emailRow.attackTypes)
      })
      .catch(() => {
        // already catched in the store
      })
  }

export const onEmailEditUnload =
  (storeManagementEmails: StoreManagementEmails) => () => {
    storeManagementEmails.reset()
  }

/**
 * Edit the entity.
 */
export const onEmailsEditSubmit =
  (storeManagementEmails: StoreManagementEmails) =>
  (emailId: number) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeForm } = storeManagementEmails

    if (!storeForm.validate()) {
      return
    }

    const email: InputEditEmail = {
      id: emailId,
      ...storeManagementEmails.getArgsFromStores()
    }

    const appRouter = storeManagementEmails.storeRoot.appRouter

    storeManagementEmails
      .editEmail(email)
      .then(() => {
        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Management_System_Configuration_Emails,
          parameters: {}
        })
        appRouter.history.push(url)
      })
      .catch(() => {
        // already catched in the store
      })
  }
