import type { StoreAuthentication } from '@app/stores'
import type { AuthenticationCredentials } from '@app/stores/Authentication/types'
import { AuthProviderName } from '@server/graphql/typeDefs/types'
import { TenableInputName, LDAPInputName } from './types'

/**
 * Fetch providers (third services) available in the auth interface.
 */
export const onAuthLoginLoad =
  (storeAuthentication: StoreAuthentication) => () => {
    storeAuthentication.fetchProviders()
  }

/**
 * Authentication via Tenable credentials.
 */
export const onLoginViaTenableProviderSubmit =
  (storeAuthentication: StoreAuthentication) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const storeForm = storeAuthentication.storeForms[AuthProviderName.Tenable]

    const isValid = storeForm.validate()

    if (!isValid) {
      return
    }

    const login = storeForm.field(TenableInputName.email).asString
    const password = storeForm.field(TenableInputName.password).asString

    const authenticationCredentials: AuthenticationCredentials = {
      provider: AuthProviderName.Tenable,
      credentials: {
        login,
        password
      }
    }

    storeAuthentication.login(authenticationCredentials)
  }

/**
 * Authentication via LDAP.
 */
export const onLoginLDAPSubmit =
  (storeAuthentication: StoreAuthentication) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const storeForm = storeAuthentication.storeForms[AuthProviderName.Ldap]

    const isValid = storeForm.validate()

    if (!isValid) {
      return
    }

    const login = storeForm.field(LDAPInputName.account).asString
    const password = storeForm.field(LDAPInputName.password).asString

    const authenticationCredentials: AuthenticationCredentials = {
      provider: AuthProviderName.Ldap,
      credentials: {
        login,
        password
      }
    }

    storeAuthentication.login(authenticationCredentials)
  }
