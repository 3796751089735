import { useAppTranslator } from '@app/hooks'
import * as React from 'react'
import HealthCheckLabel from './HealthCheckLabel'
import type { HealthCheckNameLocale, IHealthCheckBaseLabelProps } from './types'

export interface IHealthCheckNameLabelProps extends IHealthCheckBaseLabelProps {
  locale: HealthCheckNameLocale
  healthCheckName: string
}

const HealthCheckNameLabel: React.FC<IHealthCheckNameLabelProps> = props => {
  const translate = useAppTranslator({
    namespaces: [`HealthCheck.HealthChecks.${props.healthCheckName}`],
    fallback: '**?**'
  })

  return (
    <HealthCheckLabel
      locale={props.locale}
      maxWidth={props.maxWidth}
      maxWidthAuto={props.maxWidthAuto}
      labelProps={props.labelProps}
      translate={translate}
      transformMarkdown={props.transformMarkdown}
      pureText={props.pureText}
      interpolations={props.interpolations}
    />
  )
}

export default HealthCheckNameLabel
