import { AppMenu } from '@app/components-legacy/Menu'
import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useDeviceViewPort } from '@app/hooks/useDeviceViewPort'
import { DeviceViewPortWidth } from '@app/hooks/useDeviceViewPort/types'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { filterFalsies } from '@libs/filterFalsies'
import * as React from 'react'
import { canAccessToAbout } from './AboutPage/permissions'
import { getSystemConfigurationMenuEntries } from './ConfigurationPage/hooks/useSystemConfigurationStoreMenu'
import { canAccessToSystemConfiguration } from './ConfigurationPage/permissions'
import { MenuSystemMenuKey } from './ConfigurationPage/types'
import { canAccessToDirectories } from './Directories/permissions'
import { canAccessToInfrastructures } from './Infrastructures/permissions'
import { canAccessToRelays } from './Relays/permissions'
import { canAccessToTenantsManagement } from './Tenants/permissions'

export interface IMenuSystemProps {}

const MenuSystem: React.FC<IMenuSystemProps> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Management.System.Navigation',
      'Management.System.Configuration.Navigation'
    ]
  })

  const { storeRbac } = useStores()

  const { deviceViewPort } = useDeviceViewPort()

  const menuEntries = filterFalsies<IMenuEntry<MenuSystemMenuKey>>([
    storeRbac.isUserGrantedTo(canAccessToRelays) && {
      key: MenuSystemMenuKey.RelayManagement,
      label: translate('Relay management'),
      labelledBy: 'Relay management',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Relays,
        parameters: {}
      }
    },
    storeRbac.isUserGrantedTo(canAccessToInfrastructures) && {
      key: MenuSystemMenuKey.ForestManagement,
      label: translate('Forest management'),
      labelledBy: 'Forest management',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Infrastructures,
        parameters: {}
      }
    },
    storeRbac.isUserGrantedTo(canAccessToDirectories) && {
      key: MenuSystemMenuKey.DomainManagement,
      label: translate('Domain management'),
      labelledBy: 'Domain management',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Directories,
        parameters: {}
      }
    },
    storeRbac.isUserGrantedTo(canAccessToTenantsManagement) && {
      key: MenuSystemMenuKey.TenantManagement,
      label: translate('Tenant management'),
      labelledBy: 'Tenant management',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Tenants,
        parameters: {}
      }
    },
    storeRbac.isUserGrantedTo(canAccessToSystemConfiguration) && {
      key: MenuSystemMenuKey.Configuration,
      label: translate('Configuration'),
      labelledBy: 'Configuration',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Configuration,
        parameters: {}
      },
      menuEntries: deviceViewPort.isLessThan(DeviceViewPortWidth.extraLarge)
        ? getSystemConfigurationMenuEntries({ storeRbac, translate })
        : null
    },
    storeRbac.isUserGrantedTo(canAccessToAbout) && {
      key: MenuSystemMenuKey.About,
      label: translate('About'),
      labelledBy: 'About',
      routeDefinition: {
        routeName: AppRouteName.Management_System_About,
        parameters: {}
      }
    },
    storeRbac.isUserGrantedTo(canAccessToAbout) && {
      key: MenuSystemMenuKey.Legal,
      label: translate('Legal'),
      labelledBy: 'Legal',
      routeDefinition: {
        routeName: AppRouteName.Management_System_Legal,
        parameters: {}
      }
    }
  ])

  const storeMenuSystem = useStoreMenuInit<IMenuEntry<MenuSystemMenuKey>>({
    // pass the deviceViewPort object as as dependency to reinstanciate a new StoreMenu
    // when the viewport has changed (to render a responsive menu or not)
    dependencies: [deviceViewPort],
    menuEntries
  })

  return <AppMenu storeMenu={storeMenuSystem} />
}

export default MenuSystem
