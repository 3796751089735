import { ContainerContent } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks/useStores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { canAccessToRelayConfiguration } from '../permissions'
import { handleRelayOnLoad } from './handlers'
import LinkingKeyForm from './LinkingKeyForm'

interface IConfigurationRelayPageProps {}

const ConfigurationRelayPage: React.FC<IConfigurationRelayPageProps> = () => {
  const { storeManagementRelays } = useStores()

  return (
    <ContainerContent
      flags={storeManagementRelays.storeFlagsRelayLinkingKeyFetch.flags}
      onLoad={handleRelayOnLoad(storeManagementRelays)}
      rbacCapabilityCheck={canAccessToRelayConfiguration}
    >
      <LinkingKeyForm />
    </ContainerContent>
  )
}

export default styled(observer(ConfigurationRelayPage))`
  max-width: 800px;
`
