import IconNoData from '@app/components-legacy/Icon/IconNoData'
import { useAppTranslator } from '@app/hooks'
import * as React from 'react'

export interface IHealthCheckMainPageNoResultProps {}

export default function HealthCheckMainPageNoResult(
  props: IHealthCheckMainPageNoResultProps
) {
  const translate = useAppTranslator({
    namespaces: ['HealthCheck.Domains']
  })

  return (
    <IconNoData
      text={translate('All health checks passed')}
      imageName="healthCheck/all-passed"
      size={375}
    />
  )
}
