import { BulletCircle } from '@app/components-legacy/Bullet'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LabelAlt, LabelSliced } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { colorWhite } from '@app/styles/consts'
import * as React from 'react'

interface ICardRankAttackProps {
  name?: string
  levelColor?: string
  nbAttacks?: number
}

const CardRankAttack: React.FC<ICardRankAttackProps> = props => {
  return (
    <ContainerFlex
      name={CardRankAttack.name}
      justifyContent="space-between"
      alignItems="center"
      items={[
        <ContainerFlex
          name="IoABoardCardAttackName"
          alignItems="center"
          items={[
            <BulletCircle color={props.levelColor || colorWhite} />,

            props.name ? (
              <LabelSliced maxLength={30} value={props.name} />
            ) : (
              <LabelAlt variant={LabelAltVariant.Disabled}>-</LabelAlt>
            )
          ]}
          spaced
          spaceWidth="small"
        />,

        props.nbAttacks !== undefined && (
          <LabelAlt variant={LabelAltVariant.Bolded}>
            {props.nbAttacks}
          </LabelAlt>
        )
      ]}
      paddingH="default"
    />
  )
}

export default CardRankAttack
