import { Features } from '@alsid/common'
import type { IIconProps } from '@app/components/Icon/types'
import { useAppTranslator, useStores } from '@app/hooks'
import { HealthCheckStatus } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

export interface IHealthCheckGlobalStatusLabelProps extends IIconProps {}

function HealthCheckGlobalStatusLabel(
  props: IHealthCheckGlobalStatusLabelProps
) {
  const translate = useAppTranslator({
    namespaces: ['HealthCheck.GlobalStatus']
  })

  const { storeHealthCheckGlobalStatus, storeRoot } = useStores()

  const { healthCheckGlobalStatus } = storeHealthCheckGlobalStatus

  const isPlatformStatusEnabled =
    storeRoot.stores.storeFeatureFlags.getFeatureFlagValue(
      Features.PLATFORM_STATUS_HEALTH_CHECK
    )

  if (!healthCheckGlobalStatus) {
    return <>{translate('Service unavailable')}</>
  }

  if (healthCheckGlobalStatus.status === HealthCheckStatus.Success) {
    return <>{translate('Normal')}</>
  }

  let numberOfIssues = healthCheckGlobalStatus.domainStats?.numberOfIssues ?? 0
  let numberOfWarnings =
    healthCheckGlobalStatus.domainStats?.numberOfWarnings ?? 0
  if (isPlatformStatusEnabled) {
    numberOfIssues += healthCheckGlobalStatus.platformStats?.numberOfIssues ?? 0
    numberOfWarnings +=
      healthCheckGlobalStatus.platformStats?.numberOfWarnings ?? 0
  }

  return (
    <>
      {`(${translate('issues', {
        interpolations: {
          count: numberOfIssues
        }
      })}, ${translate('warnings', {
        interpolations: {
          count: numberOfWarnings
        }
      })})`}
    </>
  )
}

export default observer(HealthCheckGlobalStatusLabel)
