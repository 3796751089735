import type { IRbacBulkPermissionAction } from '@libs/rbac/types'

export enum RbacBulkPermissionActionValues {
  grantAllByDefault = 'grantAllByDefault',
  grantReadingAndEditing = 'grantReadingAndEditing',
  grantReading = 'grantReading',
  grantReadingByDefault = 'grantReadingByDefault',
  grantEditing = 'grantEditing',
  grantEditingByDefault = 'grantEditingByDefault',
  grantCreatingByDefault = 'grantcreatingByDefault',
  unauthorizeAll = 'unauthorizeAll',
  unauthorizeReading = 'unauthorizeReading',
  unauthorizeEditing = 'unauthorizeEditing',
  unauthorizeCreating = 'unauthorizeCreating'
}

export type RbacBulkPermissionValues = Map<
  RbacBulkPermissionActionValues,
  IRbacBulkPermissionAction
>

export interface IRbacBulkPermissionOption {
  backgroundColor: string
  value: string
  label: string
}
