import { IconFilter } from '@app/components/Icon'
import { LabelColorTag } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { CTAColorV2 } from '@app/styles/consts'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { onAddFiltersButtonClick } from './DrawerAdditionnalFilters/handlers'

const StyledDiv = styled('div')`
  position: relative;
`

const StyledBulletCount = styled(LabelColorTag)`
  position: absolute;
  border-radius: ${consts.borderRadiusXLRedesign};
  top: -10px;
  right: -15px;
  width: 20px;
  height: 20px;
`

interface IAddFiltersMenuButtonProps {
  className?: string
}

const AddFiltersMenuButton: React.FC<IAddFiltersMenuButtonProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['ActivityLogs.Filters']
  })

  const { storeActivityLogs } = useStores()

  const { selectedFiltersCount } = storeActivityLogs.storeActivityLogsFilters

  return (
    <StyledDiv>
      <FormWrapperButton
        labelledBy="filtersButton"
        variant={ButtonVariant.secondary}
        size={ButtonSize.small}
        icon={IconFilter}
        iconAfterLabel={true}
        buttonProps={{
          onClick: onAddFiltersButtonClick(storeActivityLogs)
        }}
      >
        {translate('Filters')}
      </FormWrapperButton>
      {selectedFiltersCount > 0 && (
        <StyledBulletCount
          label={`${selectedFiltersCount}`}
          color={CTAColorV2.primary}
          labelProperties={{
            color: consts.colorWhite,
            variant: LabelVariant.caption,
            labelledBy: 'filtersCount'
          }}
        />
      )}
    </StyledDiv>
  )
}

export default observer(AddFiltersMenuButton)
