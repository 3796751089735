import { Features } from '@alsid/common'
import { canDisplayAzureAdBecauseEnabled } from '@app/pages/permissions'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  accessByFeatureFlagDisabled,
  readIdentityExplorer
} from '@libs/rbac/permissions'

export const canAccessToIdentityExplorer = rbacCapabilityCheckAllOf(
  canDisplayAzureAdBecauseEnabled,
  readIdentityExplorer(),
  accessByFeatureFlagDisabled(Features.GLOBAL_INVENTORY_IDENTITIES)()
)
