import { ContainerFlex } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { sortGenericCheckersByCriticityAndByName } from '@libs/criticity'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import CellsAttackTypes from './Cells/CellsAttackTypes'
import CellsEntries from './Cells/CellsEntries'
import CellsThead from './Cells/CellsThead'
import {
  SETUP_TABLE_ATTACK_TYPES_WIDTH_REDESIGN,
  SETUP_TABLE_HEIGHT
} from './Cells/consts'

interface ISetupTableProps {
  className?: string
  isLoading?: boolean
  isCheckable: boolean
}

const ScrollableDiv = styled.div`
  max-height: ${() => `${SETUP_TABLE_HEIGHT}px`};

  min-width: ${`${SETUP_TABLE_ATTACK_TYPES_WIDTH_REDESIGN + 20}px`};
  max-width: ${`${SETUP_TABLE_ATTACK_TYPES_WIDTH_REDESIGN}px`};

  overflow: hidden;
  margin-right: ${consts.marginVerySmall};
`

const StyledContainerFlex = styled(ContainerFlex)<ISetupTableProps>`
  opacity: ${props => (props.isLoading ? 0.5 : 1)};
  border-radius: ${consts.borderRadiusLargeRedesign};
  border: 0;
  border-right: 0;
  border-bottom: 0;
  background-color: transparent;
`

const SetupTable: React.FC<ISetupTableProps> = props => {
  const tableTheadRef = React.useRef<HTMLDivElement>(null)
  const tableColumnsRef = React.useRef<HTMLDivElement>(null)

  const { storeManagementAttackTypeConfiguration } = useStores()

  const entityAttackTypes =
    storeManagementAttackTypeConfiguration.storeInputCheckersAttacks
      .selectedCheckers

  sortGenericCheckersByCriticityAndByName(entityAttackTypes)

  const entityInfrastructures = Array.from(
    storeManagementAttackTypeConfiguration.storeInfrastructures.infrastructures.values()
  )

  return (
    <StyledContainerFlex
      name={SetupTable.name}
      direction="column"
      className={props.className}
      isLoading={props.isLoading}
      isCheckable={props.isCheckable}
      items={[
        <CellsThead
          refs={{ thead: tableTheadRef }}
          isCheckable={props.isCheckable}
          infrastructures={entityInfrastructures}
        />,

        <ContainerFlex
          name="Cells"
          items={[
            <ScrollableDiv ref={tableColumnsRef}>
              <ContainerFlex
                name="Cells"
                items={[
                  <CellsAttackTypes
                    isCheckable={props.isCheckable}
                    attackTypes={entityAttackTypes}
                  />
                ]}
              />
            </ScrollableDiv>,

            <CellsEntries
              refs={{
                thead: tableTheadRef,
                columns: tableColumnsRef
              }}
              isCheckable={props.isCheckable}
              infrastructures={entityInfrastructures}
              attackTypes={entityAttackTypes}
            />
          ]}
        />
      ]}
    />
  )
}

const ObservedSetupTable = observer(SetupTable)

export default ObservedSetupTable
