import type { IEntityListable } from '@app/entities/types'
import type { IDataRowGeneric } from '@app/stores/helpers/StoreWidgetList/types'
import * as React from 'react'
import type { IWidgetListTableCommonProps } from './types'

interface IWidgetTableTBodyRowToggleableContentProps<
  E extends IEntityListable<IDataRowGeneric>,
  D extends IDataRowGeneric
> extends IWidgetListTableCommonProps<E, D> {
  id: string
  row: D
}

export function WidgetTableTBodyRowToggleableContent<
  E extends IEntityListable<IDataRowGeneric>,
  D
>(props: IWidgetTableTBodyRowToggleableContentProps<E, D>): React.ReactElement {
  if (!props.toggleableRowRenderFn) {
    return <>{null}</>
  }

  return <div>{props.toggleableRowRenderFn(props.id, props.row)}</div>
}

export default WidgetTableTBodyRowToggleableContent
