import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import {
  IconSyncOutlined,
  IconEditOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { useStores } from '@app/hooks'
import type StoreSummaryCard from '@app/stores/IoA/StoreBoard/StoreSummaryCard'
import * as React from 'react'
import styled from 'styled-components'
import { handleCardEditionOnClick, handleCardRefreshOnClick } from './handlers'

interface IIoABoardCardActionsProps {
  storeSummaryCard: StoreSummaryCard
}

const IoABoardCardActions: React.FC<IIoABoardCardActionsProps> = props => {
  const { storeIoA } = useStores()

  return (
    <ContainerFlex
      name="IoABoardCardActions"
      items={[
        <FormWrapperButton
          labelledBy="refresh"
          buttonProps={{
            size: 'small',
            shape: 'circle',
            icon: <IconSyncOutlined />,
            onClick: handleCardRefreshOnClick(
              storeIoA.storeBoard,
              props.storeSummaryCard
            )
          }}
        />,
        <FormWrapperButton
          labelledBy="submit"
          buttonProps={{
            size: 'small',
            shape: 'circle',
            icon: <IconEditOutlined />,
            onClick: handleCardEditionOnClick(
              storeIoA.storeBoard.storeSummaryCards,
              props.storeSummaryCard
            )
          }}
        />
      ]}
      spaced
      spaceWidth="verySmall"
    />
  )
}

export default styled(IoABoardCardActions)`
  @media print {
    display: none;
  }
`
