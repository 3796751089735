import { ContainerHTMLContent } from '@app/components/Container'
import { IconBell, IconSkullHead } from '@app/components/Icon'
import { WrapperAlert } from '@app/components/Wrapper'
import { AlertSize, AlertVariant } from '@app/components/Wrapper/types'
import { useAppTranslator } from '@app/hooks'
import { consts } from '@app/styles'
import React from 'react'
import { renderToString } from 'react-dom/server'
import styled from 'styled-components'

const StyledWrapperAlert = styled(WrapperAlert)`
  width: 100%;
  margin-bottom: 20px;

  border-radius: ${consts.borderRadiusRedesign};
`

const StyledContainerHTMLContent = styled(ContainerHTMLContent)`
  padding-left: ${consts.paddingDefault};
`
interface IErrorMessageProps {}

const IoaDeactivatedInformationPanel: React.FC<IErrorMessageProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Configuration.IoA']
  })

  return (
    <StyledWrapperAlert
      name="IoaDeactivatedInformationPanel"
      labelledBy="IoaDeactivatedInformationPanel"
      variant={AlertVariant.dark}
      textAlign="left"
      alertProps={{
        icon: <IconBell color={consts.BackgroundColorV2.white} />,
        showIcon: true,
        message: (
          <StyledContainerHTMLContent>
            {translate('To maintain performance', {
              interpolations: {
                icon: renderToString(
                  <IconSkullHead
                    size={18}
                    color={consts.BackgroundColorV2.white}
                  />
                )
              }
            })}
          </StyledContainerHTMLContent>
        )
      }}
      size={AlertSize.large}
    />
  )
}
export default IoaDeactivatedInformationPanel
