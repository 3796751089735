import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { Checkbox } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import FormWrapperCheckboxSimple from '../Form/Wrappers/FormWrapperCheckboxSimple'

interface ICheckboxTreeProps {
  color: string
  onClick: () => void
  checked: boolean
  indeterminate?: boolean
}

const CheckboxCustom = styled(Checkbox)<{
  color: string
}>`
  margin-right: 10px;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.color};
    border-color: ${props => props.color};
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: ${props => props.color};
    border-color: ${props => props.color};
  }

  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-checked:hover .ant-checkbox-inner {
    border-color: ${props => props.color};
  }
`

const StyledCheckboxContainerDiv = styled.div`
  height: 24px;
  width: 30px;
  display: inline-flex;
  align-items: center;
`

/**
 * Render a checkbox inside an Antd Tree component.
 * Background color of the checkbox is overridden by the color passed in props.
 */
const CheckboxTree: React.FC<ICheckboxTreeProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  if (themeIsLegacy) {
    return (
      <CheckboxCustom
        onClick={props.onClick}
        checked={props.checked}
        indeterminate={props.indeterminate || false}
        color={props.color}
      />
    )
  }

  return (
    <StyledCheckboxContainerDiv>
      <FormWrapperCheckboxSimple
        labelledBy="checkboxTree"
        checked={props.checked}
        indeterminate={props.indeterminate || false}
        onChange={props.onClick}
      />
    </StyledCheckboxContainerDiv>
  )
}

export default CheckboxTree
