import type { ServerProperties } from 'tenable-universal-layout-v5'
import type { Session } from 'tenable-universal-layout-v5/dist/libs/openapi/session/sessionResource'
import { UserPermissions } from 'tenable-universal-layout-v5/dist/libs/openapi/session/sessionResource'
import type { LicenseApps } from 'tenable-universal-layout-v5/dist/libs/openapi/wug/serverConfigResource'
import { LicenseType } from 'tenable-universal-layout-v5/dist/libs/openapi/wug/serverConfigResource'

export const emptyServerProperties: ServerProperties = {
  license: {
    type: LicenseType.Local,
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    apps: {} as LicenseApps,
    expirationDate: 0,
    agents: 0,
    evaluation: true,
    ips: 0,
    scanners: 0,
    users: 0
  },
  analytics: {
    enabled: false,
    key: '',
    siteId: ''
  },
  nessusType: '',
  enterprise: false,
  msp: false,
  forceUiReload: false,
  nessusUiBuild: '',
  nessusUiVersion: '',
  serverBuild: '',
  serverVersion: '',
  scannerBoottime: 0,
  containerDbVersion: '',
  evaluation: {
    limitEnabled: false,
    scans: 0,
    targets: 0
  }
}

export const emptySession: Session = {
  name: '',
  userName: '',
  username: '',
  containerName: '',
  email: '',
  permissions: 0,
  userPermissions: [
    /**
     * As we don't have user's permissions (yet?) in TAD, we hardcode all permissions by default
     * to have all apps displayed in TUL apps switcher.
     */
    UserPermissions.TioBackendScanRead,
    UserPermissions.TioBackendApikeyCreate,
    UserPermissions.TioBackendMyProfileRead,
    UserPermissions.TioBackendMyProfileUpdate,
    UserPermissions.TioBackendAccountSettingUpdate,
    UserPermissions.TioBackendAccountSettingRead,
    UserPermissions.TioBackendAccessControlUpdate,
    UserPermissions.TioBackendScanDelete,
    UserPermissions.TioBackendHealthAndStatusRead,
    UserPermissions.TioBackendAccessControlDelete,
    UserPermissions.TioBackendAccessControlRead,
    UserPermissions.TioBackendScanCreate,
    UserPermissions.TioBackendActivityLogRead,
    UserPermissions.TioBackendAccessControlCreate,
    UserPermissions.ToneUiAttackPathAnalysisRead,
    UserPermissions.ToneBackendToneDataCategoryCloudResourceRead,
    UserPermissions.ToneBackendToneDataCategoryWebApplicationRead,
    UserPermissions.ToneUiToneExposureCardRead,
    UserPermissions.ToneBackendToneDataCategoryIdentitiesRead,
    UserPermissions.ToneUiToneTagCreate,
    UserPermissions.ToneUiToneTagDelete,
    UserPermissions.ToneUiToneExposureCardCreate,
    UserPermissions.ToneUiToneAssetsRead,
    UserPermissions.ToneBackendToneDataCategoryComputingResourceRead,
    UserPermissions.AssetInventoryExportManageOwn,
    UserPermissions.AssetInventoryTagEdit,
    UserPermissions.AssetInventoryTagCreate,
    UserPermissions.AssetInventoryAccessToAssetTypeCloudResource,
    UserPermissions.AssetInventoryAccessToAssetTypeComputingResource,
    UserPermissions.AssetInventoryAccessToAssetTypeIdentity,
    UserPermissions.AssetInventoryAccessToAssetTypeWebApplication,
    UserPermissions.AssetInventoryCyberAssetManagementRead,
    UserPermissions.AssetInventoryAssetInventoryRead,
    UserPermissions.LuminExposureViewExposureCardRead,
    UserPermissions.LuminExposureViewExposureCardCreate,
    UserPermissions.LuminExposureViewExposureCardShare,
    UserPermissions.LuminExposureViewSettingsRead,
    UserPermissions.LuminExposureViewSettingsManage,
    UserPermissions.LuminExposureViewExportManageOwn,
    UserPermissions.AttackPathAnalysisFindingRead,
    UserPermissions.AttackPathAnalysisFindingManage,
    UserPermissions.AttackPathAnalysisQuerySearch,
    UserPermissions.AttackPathAnalysisExportManageOw
  ],
  containerUuid: '',
  uuid: '',
  id: 0,
  uuidId: '',
  type: '',
  preferences: {
    enableExplore: ''
  },
  enabled: false,
  lastLoginAttempt: 0,
  loginFailCount: 0,
  loginFailTotal: 0,
  containerId: 0,
  containerType: '',
  features: {
    accessGroups: false,
    accessGroupsMigration: false,
    advancedSearchV2: false,
    agentTriageM2: false,
    agentUpdates: false,
    analytics: false,
    analyticsV2: false,
    assetDeletingUi: false,
    assetManagement: false,
    auditsWorkbench: false,
    awsConnectorV1: false,
    cflCoreSsor: false,
    connectorsGen2: false,
    containerSecurity: false,
    containerSecurityGen2: false,
    credentialsMgmt: false,
    credentialsMgmtV2: false,
    dashboardsGen2: false,
    dashboardsGen2BlankCanvas: false,
    dashboardsGen2Export: false,
    dashboardsGen2ExportPng: false,
    dashboardsGen2LuminEnabled: false,
    dashboardsGen2Schedule: false,
    dashboardsGen2TagFilter: false,
    dashboardsGen2WidgetFilters: false,
    dashboardsGen2WidgetLibrary: false,
    dynamicTagging: false,
    environmentManagement: false,
    exportDashboard: false,
    exportDashboardPdf: false,
    generalDataProtectionCompliance: false,
    importData: false,
    indexingV2: false,
    luminBetaAllowed: false,
    luminBetaEnabled: false,
    modifyVulnerability: false,
    pciMultiscan: false,
    qualysConnector: false,
    qualysVulnConnector: false,
    rbac: false,
    recastRules: false,
    reporting: false,
    scanService: false,
    scansGen2: false,
    state: false,
    suggestFeature: false,
    tagging: false,
    vmServiceQuery: false,
    vulnerabilityManagementGen2: false,
    wasDiscovery: false,
    wasMultiScanning: false,
    wasPluginSelection: false,
    wasScanProgress: false,
    webappScanning: false,
    webappScanningGen2: false,
    system: false
  },
  apps: {},
  groupUuids: [],
  groups: [],
  lastlogin: new Date()
}
