import * as React from 'react'
import styled from 'styled-components'

interface ILabelReportSubTitleProps {
  className?: string
  size: 'default' | 'title'
  children?: React.ReactNode
}

const LabelReportSubTitle: React.FC<ILabelReportSubTitleProps> = props => {
  return <div className={props.className}>{props.children}</div>
}

function getFontSize(props: ILabelReportSubTitleProps): string {
  return props.size === 'default' ? 'inherits' : '28px'
}

export default styled(LabelReportSubTitle)`
  font-family: 'Arial';
  font-size: ${getFontSize};
  text-transform: uppercase;
  letter-spacing: 10px;
`
