import { zIndexTul } from '../../styles/consts'

export function tulOverrides() {
  // update tiles font
  const globalHeader = document?.querySelector(
    'tenable-global-header'
  )?.shadowRoot
  const universalHeader = globalHeader?.querySelector(
    'tenable-universal-header'
  )
  const workspacePanel = universalHeader?.querySelector(
    'tenable-universal-header-workspace-panel'
  )?.shadowRoot
  const actionPanel = workspacePanel?.querySelector(
    'tenable-universal-header-action-panel'
  )
  const workspaceCards = actionPanel?.querySelectorAll(
    'tenable-universal-header-action-panel-body #workspaces-view tenable-workspace-card'
  )

  workspaceCards?.forEach(card => {
    const label = card.shadowRoot?.querySelector('label')
    if (label) {
      label.style.fontFamily = 'Barlow'
      label.style.fontWeight = 'normal'
      label.style.letterSpacing = '0.36px'
      label.style.fontStretch = 'condensed'
      label.style.letterSpacing = '0.02em'
      label.style.setProperty('-webkit-font-smoothing', 'unset')
      label.style.zIndex = String(zIndexTul)
    }
  })

  // update width
  const tenbuUniversalHeaderActionPanelRoot = workspacePanel?.querySelector(
    '.tenb-universal-header-action-panel__root'
  )
  const tenablePanelRoot =
    tenbuUniversalHeaderActionPanelRoot?.shadowRoot?.querySelector(
      '.tenable-panel-root'
    ) as HTMLElement
  if (tenablePanelRoot) {
    tenablePanelRoot.style.cssText = 'width: 550px !important;'
  }

  // update header typography
  const headerElement = actionPanel
    ?.querySelector('tenable-universal-header-action-panel-header')
    ?.querySelector('tenable-heading3')
    ?.shadowRoot?.querySelector('h3')
  if (headerElement) {
    headerElement.style.fontFamily = 'Barlow'
    headerElement.style.fontWeight = '600'
    headerElement.style.fontStretch = 'condensed'
    headerElement.style.letterSpacing = '0.02em'
  }
}
