import type { IIconProps } from '@app/components/Icon/types'
import { consts } from '@app/styles'
import { BrandColorV2, FontColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

const Color1Rect = styled.rect`
  fill: ${FontColorV2.primary};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${consts.colorWhite};
  }
`

const Color2Rect = styled.rect`
  fill: ${BrandColorV2.decorativeGray};

  a.active &,
  a:hover &,
  a:focus:not([data-focus-method='mouse']) & {
    fill: ${consts.colorFadedBlueNewDesign};
  }
`

interface IIconDashboardProps extends IIconProps {}

const IconDashboardLegacy: React.FC<IIconDashboardProps> = props => {
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Color1Rect width="9" height="9" rx="1" />
      <Color1Rect x="11" width="9" height="9" rx="1" />
      <Color2Rect x="11" y="11" width="9" height="9" rx="1" />
      <Color1Rect y="11" width="9" height="9" rx="1" />
    </svg>
  )
}

export default IconDashboardLegacy
