import { ForbiddenAccessError } from '@libs/errors'
import { checkRbac } from '@libs/rbac/functions'
import type {
  DashboardWidget,
  MaybeGrantedDashboards
} from '@server/graphql/typeDefs/types'
import { first } from 'lodash'
import type { StoreRoot } from '..'
import type StoreFlags from '../helpers/StoreFlags'

/**
 * Helper to retrieve the first widget of a MaybeGrantedDashboards object.
 * If not granted, raise a ForbiddenAccessError exception.
 */
export function getFirstDashboardWidget(
  storeRoot: StoreRoot,
  storeFlags?: StoreFlags
) {
  return (
    maybeGrantedDashboards: MaybeGrantedDashboards
  ): Promise<DashboardWidget> => {
    return Promise.resolve(maybeGrantedDashboards)
      .then(rbacDashboards => {
        if (!checkRbac(storeRoot, storeFlags)(rbacDashboards)) {
          throw new ForbiddenAccessError()
        }

        return first(rbacDashboards.node)
      })
      .then(dashboard => {
        if (!dashboard) {
          throw new Error('Dashboard has not been found')
        }

        if (!checkRbac(storeRoot, storeFlags)(dashboard.rbacWidgets)) {
          throw new ForbiddenAccessError()
        }

        return first(dashboard.rbacWidgets.node) || null
      })
      .then(widget => {
        if (!widget) {
          throw new Error('Widget is not defined')
        }

        return widget
      })
  }
}
