import { ErrorBoundary } from '@app/components/Error'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { WIDGET_DEFAULT_BOUNDS } from '@app/stores/Dashboard/consts'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import { DashboardWidgetType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import WidgetBarChart from '../Common/WidgetBarChart'
import WidgetBigNumber from '../Common/WidgetBigNumber'
import WidgetError from '../Common/WidgetError'
import WidgetLineChart from '../Common/WidgetLineChart'
import WidgetPieChart from '../Common/WidgetPieChart'
import {
  onDeleteIconClick,
  onEditIconClick,
  onRefreshIconClick
} from './handlers'
import type { IGridWidgetGenericProps } from './types'

interface IGridWidgetContentProps extends IGridWidgetGenericProps {}

const GridWidgetContent: React.FC<IGridWidgetContentProps> = props => {
  const appRouter = useAppRouter()

  const widgetBounds = props.storeDashboard.widgetBounds
  const bounds = widgetBounds.get(props.widgetKey) || WIDGET_DEFAULT_BOUNDS

  const Component = React.useMemo(() => {
    const renderWidgets = () => {
      const widgetLayout = props.storeDashboard.widgetLayouts.get(
        props.widgetKey
      )

      if (!widgetLayout) {
        return null
      }

      switch (props.options.type) {
        case DashboardWidgetType.LineChart:
          return (
            <WidgetLineChart
              widgetKey={props.widgetKey}
              title={props.title}
              storeWidget={props.storeWidget}
              bounds={bounds}
              layout={widgetLayout}
              onEditIconClick={onEditIconClick(appRouter)(props.widgetKey)}
              onDeleteIconClick={onDeleteIconClick(props.storeDashboard)(
                props.widgetKey
              )}
              onRefreshIconClick={onRefreshIconClick(props.storeWidget)(
                props.widgetKey
              )}
            />
          )

        case DashboardWidgetType.BarChart:
          return (
            <WidgetBarChart
              widgetKey={props.widgetKey}
              title={props.title}
              storeWidget={props.storeWidget}
              bounds={bounds}
              layout={widgetLayout}
              onEditIconClick={onEditIconClick(appRouter)(props.widgetKey)}
              onDeleteIconClick={onDeleteIconClick(props.storeDashboard)(
                props.widgetKey
              )}
              onRefreshIconClick={onRefreshIconClick(props.storeWidget)(
                props.widgetKey
              )}
            />
          )

        case DashboardWidgetType.PieChart:
          return (
            <WidgetPieChart
              widgetKey={props.widgetKey}
              title={props.title}
              storeWidget={props.storeWidget}
              bounds={bounds}
              layout={widgetLayout}
              onEditIconClick={onEditIconClick(appRouter)(props.widgetKey)}
              onDeleteIconClick={onDeleteIconClick(props.storeDashboard)(
                props.widgetKey
              )}
              onRefreshIconClick={onRefreshIconClick(props.storeWidget)(
                props.widgetKey
              )}
            />
          )

        case DashboardWidgetType.BigNumber:
          return (
            <WidgetBigNumber
              widgetKey={props.widgetKey}
              title={props.title}
              storeWidget={props.storeWidget}
              bounds={bounds}
              layout={widgetLayout}
              onEditIconClick={onEditIconClick(appRouter)(props.widgetKey)}
              onDeleteIconClick={onDeleteIconClick(props.storeDashboard)(
                props.widgetKey
              )}
              onRefreshIconClick={onRefreshIconClick(props.storeWidget)(
                props.widgetKey
              )}
            />
          )

        case DashboardWidgetType.SecurityCompliance:
          return <div>Not yet implemented</div>

        default:
          assertUnreachableCase(props.options.type)
      }
    }

    if (!props.options.type) {
      return <WidgetError title={props.title} />
    }

    return (
      <ErrorBoundary errorComponent={<WidgetError title={props.title} />}>
        {renderWidgets()}
      </ErrorBoundary>
    )
    // force refresh if the bounds of the widget change (when resizing)
  }, [bounds.width, bounds.height])

  return Component
}

export default observer(GridWidgetContent)
