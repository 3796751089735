import type { Maybe } from '@@types/helpers'
import { ContainerFlex } from '@app/components/Container'
import { useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import DrawerNodeCurrent from './DrawerNodeCurrent'
import DrawerNodeRelationshipsContentBody from './DrawerNodeRelationshipsContentBody'

export interface IDrawerNodeRelationshipsContentProps {
  className?: string
  nodeUid?: Maybe<string>
}

const DrawerNodeRelationshipsContent: React.FC<
  IDrawerNodeRelationshipsContentProps
> = props => {
  const { storeAttackPath } = useStores()

  if (!props.nodeUid) {
    return null
  }

  const entityAttackPathNode = storeAttackPath.getNode(props.nodeUid)

  if (!entityAttackPathNode) {
    return null
  }

  return (
    <ContainerFlex
      name="DrawerNodeRelationshipsContent"
      className={props.className}
      direction="column"
      spaceWidth="medium"
      items={[
        <DrawerNodeCurrent entityAttackPathNode={entityAttackPathNode} />,
        <DrawerNodeRelationshipsContentBody />
      ]}
      spaced
    />
  )
}

const ObservedDrawerNodeRelationshipsContent = observer(
  DrawerNodeRelationshipsContent
)

export default styled(ObservedDrawerNodeRelationshipsContent)`
  height: 100%;
`
