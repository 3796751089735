import { AppRouteName } from '@app/routes'
import type { StoreManagementInfrastructures } from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'
import type { InputCreateInfrastructure } from '@server/graphql/typeDefs/types'

/**
 * Clear everything when loading the blade.
 */
export const onInfrastructuresCreatePageLoad =
  (storeManagementInfrastructures: StoreManagementInfrastructures) => () => {
    storeManagementInfrastructures.storeFormCreation.reset()
  }

/**
 * Create the infrastructure,
 * Fetch infrastructures,
 * And go back to the list.
 */
export const onCreateInfrastructuresSubmit =
  (
    appRouter: AppRouterClient,
    storeManagementInfrastructures: StoreManagementInfrastructures
  ) =>
  (e: React.FormEvent<any>) => {
    const { storeFormCreation } = storeManagementInfrastructures

    e.preventDefault()

    if (!storeFormCreation.validate()) {
      return
    }

    const infrastructure: InputCreateInfrastructure = {
      name: storeFormCreation.getFieldValueAsString('name'),
      login: storeFormCreation.getFieldValueAsString('login'),
      password: storeFormCreation.getFieldValueAsString('password')
    }

    storeManagementInfrastructures
      .createInfrastructure(infrastructure)
      .then(() => {
        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Management_System_Infrastructures,
          parameters: {}
        })
        appRouter.history.push(url)
      })
      .catch(() => {
        // already catched in the store
      })
  }
