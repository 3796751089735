import type { Maybe } from '@@types/helpers'

export type SDDLItems = {
  Item1: string
  Item2: string
  Item3: string
  Item4: Array<{ Item1: string; Item2: string }>
}

export function parseSDDL(
  sddl: string,
  escapeSpecialCharacters = false
): Array<{ items: SDDLItems; formattedString: string }> {
  const parsedValues: SDDLItems[] = JSON.parse(sddl)

  return parsedValues.map((items, i) => {
    const formattedString =
      `${escapeSpecialCharacters ? escapeSpeChar(items.Item2) : items.Item2} (${
        escapeSpecialCharacters ? escapeSpeChar(items.Item3) : items.Item3
      })\n` +
      items.Item4.map(
        ({ Item1, Item2 }, j) =>
          `- ${escapeSpecialCharacters ? escapeSpeChar(Item1) : Item1} ${
            escapeSpecialCharacters ? escapeSpeChar(Item2) : Item2
          }${j < items.Item4.length - 1 ? '\n' : ''}`
      ).join('') +
      (i < parsedValues.length - 1 ? '\n' : '')

    return { items, formattedString }
  })
}

export function formatIncriminatingAttributeDescription<T, U, V>({
  description,
  formatText,
  formatDate,
  formatSDDL
}: {
  description: string
  formatText: (value: string, index: number) => T
  formatDate: (value: string, index: number) => U
  formatSDDL: (value: string, index: number) => V
}): Array<Maybe<string | T | U | V>> {
  const parts = description.split(
    /(\[tenable_ad_date].*?\[\/tenable_ad_date]|\[tenable_ad_sddl].*?\[\/tenable_ad_sddl])/g
  )

  const isEven = (n: number) => n % 2 === 0

  return parts.map((part, i) => {
    if (!isEven(i)) {
      const matchResults = new RegExp(
        /\[(tenable_ad_date|tenable_ad_sddl)](.*?)\[\/\1]/
      ).exec(part)

      if (!matchResults) {
        return null
      }

      const [_, type, value] = matchResults

      switch (type) {
        case 'tenable_ad_date':
          return formatDate(value, i)

        case 'tenable_ad_sddl': {
          return formatSDDL(value, i)
        }
      }
    }

    return formatText(part, i)
  })
}

export function formatIncriminatingAttributeDescriptionForDataRow(
  description: string
): string {
  const desc = description
    // remove markdown backticks
    .replace(/`/g, '')

  const formatText = (value: string) => value
  const formatDate = (value: string) => value
  const formatSDDL = (value: string) => {
    const formattedValue = parseSDDL(value)
      .map(({ formattedString }) => formattedString)
      .join('\n')

    return `\n\n${formattedValue}`
  }

  return formatIncriminatingAttributeDescription({
    description: desc,
    formatText,
    formatDate,
    formatSDDL
  }).join('')
}

/**
 * Escape every escapable character in markdown to prevent display bugs when a
 * special character is preceded by a backslash. Ex: galaxy.com\$Joe Biden
 *
 * The list of escapable special characters is taken from here :
 * https://github.com/markedjs/marked/blob/6435ac98896212b4f117b024cccd4c7e186a8b21/src/rules.js#L163
 */
function escapeSpeChar(str: string): string {
  return str.replace(/([!"#$%&'()*+,\-./:;<=>?@[\]\\^_`{|}~])/g, '\\$1')
}
