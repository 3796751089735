import { LocalStorageKey } from '@alsid/common/types/environment'
import {
  IframeActionEnum,
  type IIframeCommunicationBusHandlers
} from '@libs/IframeCommunicationBus/types'
import { newLogger } from '@libs/new-logger'
import { deepMerge } from '@productive-codebases/toolbox'
import type { BrowserEnvironment } from './environment'

const logger = newLogger('sdk')('SecurityEngineApp')

/**
 * Declare default handlers for the iframe bus.
 * Each of them is overridable if passed in customHandlers parameter.
 */
export function buildIframeBusHandlers(
  browserEnvironment: BrowserEnvironment,
  customHandlers?: Partial<IIframeCommunicationBusHandlers>
): IIframeCommunicationBusHandlers {
  const handlers: IIframeCommunicationBusHandlers = {
    /** Not used in the SDK */
    dummyAction: (_, iframeMessage) => {
      logger('debug')('Receive message: %o', iframeMessage)
    },

    /** Return the Irontoken to the requester (iframe) */
    getAppIronToken: ({ postMessage }, iframeMessage) => {
      logger('debug')('Receive message: %o', iframeMessage)

      postMessage({
        action: IframeActionEnum.returnIronTokenValue,
        ironToken:
          browserEnvironment.domWindow.localStorage.getItem(
            LocalStorageKey.IronToken
          ) ?? ''
      })
    },

    /** Not used in the SDK */
    returnIronTokenValue: (_, iframeMessage) => {
      logger('debug')('Receive message: %o', iframeMessage)
    },

    /** On iframe's location change, encode the value in a querystring parameter */
    onLocationChange: (_, iframeMessage) => {
      logger('debug')('Receive message: %o', iframeMessage.pathname)

      if (!iframeMessage.pathname) {
        return
      }

      const url = new URL(browserEnvironment.domDocument.location.href)

      // Using "_" as a replacement for "/" in the querystring, so we don't have to base64 encode the value
      url.searchParams.set(
        'securityEngine',
        encodeURI(iframeMessage.pathname.replace(/\//g, '_'))
      )

      if (iframeMessage.options?.replace) {
        browserEnvironment.domWindow.history.replaceState(
          {},
          '',
          url.toString()
        )
        return
      }

      browserEnvironment.domWindow.history.pushState({}, '', url.toString())
    },

    /** Open the link received from the requester */
    onExternalLinkClick: (_, iframeMessage) => {
      logger('debug')('Receive message: %o', iframeMessage)
      browserEnvironment.domWindow.open(iframeMessage.url, iframeMessage.target)
    },

    /** Track user's actions for telemetry */
    trackTelemetry: (_, iframeMessage) => {
      logger('debug')('Receive message: %o', iframeMessage)
    },

    /** Return the Pathname of the app to the requester (iframe) */
    getAppPathName: ({ postMessage }, iframeMessage) => {
      logger('debug')('Receive message: %o', iframeMessage)

      postMessage({
        action: IframeActionEnum.returnAppPathName,
        pathname: window.location.pathname
      })
    },

    /** Not used in the SDK */
    returnAppPathName: (_, iframeMessage) => {
      logger('debug')('Receive message: %o', iframeMessage)
    }
  }

  return deepMerge([handlers, customHandlers])
}
