import { consts } from '@app/styles'
import * as React from 'react'
import { BulletCircle } from '.'

interface IBulletStatusOKProps {}

const BulletStatusOK: React.FC<IBulletStatusOKProps> = props => {
  return <BulletCircle color={consts.colorGreen020} />
}

export default BulletStatusOK
