import type { IIoAAttacksRouteDefinition } from '@app/routes'
import { AppRouteName } from '@app/routes'
import type { StoreIoA } from '@app/stores'
import { ATTACKS_MAX_BLADES } from '@app/stores/IoA/consts'
import type { AttacksQueryStringParameters } from '@app/stores/IoA/StoreAttacks/types'
import { ensureArray } from '@libs/ensureArray'
import type { AppRouterClient } from '@libs/Router/types'

/**
 * Return the url after having added a new resourceType/resourceValue filter
 * to the current ones.
 * It will open a new blade of attacks.
 */
export const addAttacksFiltersInUrl =
  (appRouter: AppRouterClient, storeIoA: StoreIoA) =>
  (
    attackFilters: Pick<
      AttacksQueryStringParameters,
      'resourceType' | 'resourceValue'
    >
  ): string => {
    const qsParameters =
      appRouter.getCurrentRouteQueryStringParameters<
        IIoAAttacksRouteDefinition['queryStringParameters']
      >()

    // Keep only the 4 first blades to have 5 blades at max
    const urlFilters = ensureArray(qsParameters?.attacksFilters).slice(
      0,
      ATTACKS_MAX_BLADES - 1
    )

    if (attackFilters.resourceValue) {
      urlFilters.push(attackFilters)
    }

    const boardFilters = storeIoA.computeBoardQueryParameters()

    return appRouter.makeRouteInfosPathname(
      {
        routeName: AppRouteName.IoA_Attacks,
        parameters: {},
        queryStringParameters: {
          boardFilters,
          attacksFilters: urlFilters
        }
      },
      { arrayFormat: 'indices' }
    )
  }
