import type { Maybe } from '@@types/helpers'
import type { Features } from '@alsid/common'
import { AppRouteName } from '@app/routes'
import type { StoreRoot } from '@app/stores'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import StoreModal from '@app/stores/helpers/StoreModal'
import StoreBase from '@app/stores/StoreBase'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type { IWSRegistration } from '@server/routers/WSProxyRouter/types'
import { WSEntityName } from '@server/routers/WSProxyRouter/types'
import type { IWSFeatureFlagMessage } from '@server/routers/WSToggleRouter/types'
import { WSFeatureFlagPayloadMessages } from '@server/routers/WSToggleRouter/types'
import type { IObservableValue } from 'mobx'
import { action, computed, makeObservable, observable } from 'mobx'
import type { IStoreOptions } from '../types'
import type { FeatureFlags } from './types'

export default class StoreFeatureFlags extends StoreBase {
  public storeFlags = new StoreFlags(this.storeRoot)

  public storeModalFeatureFlagUpdate = new StoreModal(this.storeRoot)
  public storeModalFeatureFlagRestart = new StoreModal(this.storeRoot, {
    isUnclosable: true
  })

  private _featureFlagsWSRegistration: Maybe<
    IWSRegistration<IWSFeatureFlagMessage['payload']>
  > = null

  /* Observables */

  public $timeoutInSeconds: IObservableValue<number>

  // list of feature toggles
  private $indexedFeatureFlags = observable.map<Features, boolean>()

  private $featureFlagsHaveChanged = observable.box<boolean>(false)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)

    this.$timeoutInSeconds = observable.box<number>(
      this.storeRoot.environment.config.app.featuretoggler
        .timeoutinsecondsbeforeupdateconsidered
    )

    makeObservable(this)
  }

  /* Actions */

  /**
   * Fetch feature flags.
   */
  @action
  fetchFeatureFlags(): Promise<void> {
    this.storeFlags.loading()

    const url = this.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareFeatureFlags,
      parameters: {}
    })

    return this.storeRoot.fetchClient
      .get(url)
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Unable to get the feature flags')
        }

        return response.json() as Promise<FeatureFlags>
      })
      .then(data => {
        this.storeFlags.success()

        if (data) {
          this.setFeatureFlags(data)
        }
      })
      .then(() => this.registerEventWS())
      .catch(handleStoreError(this.storeRoot, this.storeFlags))
  }

  /**
   * Return the value of the flag.
   */
  getFeatureFlagValue(featureFlagName: Features): boolean {
    return this.$indexedFeatureFlags.get(featureFlagName) || false
  }

  /**
   * Perform the matching action when a websocket message is received.
   */
  onWSMessage(payload: IWSFeatureFlagMessage['payload']) {
    switch (payload.message) {
      // When a restart has already been triggered before the app launch
      case WSFeatureFlagPayloadMessages.update_received:
        this.triggerUpdateReceived()
        return

      case WSFeatureFlagPayloadMessages.restarting_soon:
        if (payload.timeoutValue) {
          this.setTimeoutInSeconds(payload.timeoutValue)
        }

        this.triggerUpdateReceived()
        return

      case WSFeatureFlagPayloadMessages.server_up:
        this.restartIfFeatureTogglesHaveChanged()
        return

      case WSFeatureFlagPayloadMessages.restart:
        location.reload()
        return

      default:
        assertUnreachableCase(payload.message)
    }
  }

  triggerUpdateReceived() {
    this.setFeatureFlagsHaveChanged(true)

    this.storeModalFeatureFlagUpdate.show()

    setTimeout(() => {
      location.reload()
    }, this.timeoutInSeconds * 1000)
  }

  triggerRestartingSoon(restartTimeout?: number) {
    this.setFeatureFlagsHaveChanged(true)

    this.storeModalFeatureFlagUpdate.hide()
    this.storeModalFeatureFlagRestart.show()

    // FIXME: If we need to wait for a server_up message, send a WS message to
    //  ask periodically for the server status.

    if (isDefined(restartTimeout)) {
      setTimeout(() => {
        location.reload()
      }, restartTimeout * 1000)
    }
  }

  restartIfFeatureTogglesHaveChanged() {
    if (this.$featureFlagsHaveChanged.get()) {
      location.reload()
    }
  }

  /**
   * Set feature flags.
   */
  @action
  private setFeatureFlags(featureFlags: FeatureFlags): this {
    this.$indexedFeatureFlags.clear()

    Object.entries(featureFlags).forEach(
      ([featureFlagName, featureFlagValue]) => {
        this.$indexedFeatureFlags.set(
          featureFlagName as Features,
          featureFlagValue
        )
      }
    )

    return this
  }

  /**
   * Set feature flags have changed.
   */
  @action
  setFeatureFlagsHaveChanged(value: boolean): this {
    this.$featureFlagsHaveChanged.set(value)

    return this
  }

  /**
   * Set timeout in seconds.
   */
  @action
  setTimeoutInSeconds(value: number): this {
    this.$timeoutInSeconds.set(value)

    return this
  }

  /**
   * Subscribe to feature toggles.
   */
  @action
  registerEventWS() {
    // don't register several times
    if (this._featureFlagsWSRegistration) {
      return
    }

    this._featureFlagsWSRegistration = this.storeRoot.wsClient.addRegistration<
      IWSFeatureFlagMessage['payload']
    >(
      'FeatureFlags',
      {
        name: WSEntityName.featureFlags,
        payload: {}
      },
      this.onWSMessage.bind(this)
    )
  }

  /**
   * Unsubscribe events.
   */
  @action
  unregisterEventWS() {
    if (this._featureFlagsWSRegistration) {
      this.storeRoot.wsClient.removeRegistration(
        this._featureFlagsWSRegistration
      )
      this._featureFlagsWSRegistration = null
    }
  }

  /* Computed */

  /**
   * Return the timeout in seconds.
   */
  @computed
  get timeoutInSeconds(): number {
    return this.$timeoutInSeconds.get()
  }
}
