import { ContainerFlex } from '@app/components-legacy/Container'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import type { DSTheme } from '@design-system/styles/themes/types'
import { getCriticityColor } from '@libs/criticity'
import { ensureArray } from '@libs/ensureArray'
import { isDefined } from '@libs/isDefined'
import { Criticity } from '@server/graphql/typeDefs/types'
import * as React from 'react'
import styled from 'styled-components'
import { LabelCheckerHeight } from '../LabelCheckerIdentity/types'
import { onLabelCheckerClick } from './handlers'
import LabelCheckerDescription from './LabelCheckerDescription'
import LabelCheckerFooter from './LabelCheckerFooter'
import LabelCheckerTitle from './LabelCheckerTitle'
import type { ILabelCheckerProps } from './types'
import { LabelCheckerSize } from './types'

function LabelChecker<GC extends TGenericChecker>(
  props: ILabelCheckerProps<GC>
): React.ReactElement {
  const [isOvered, setOverStatus] = React.useState<boolean>(false)

  /**
   * Needed to pass an over status to the footer, in order to change
   * the background color.
   */
  const onMouseEnterHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation()
    setOverStatus(true)
  }

  const onMouseOverLeave = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation()
    setOverStatus(false)
  }

  const hasComplexity = isDefined(props.complexityValue)
  const hasDirectories = ensureArray(props.directoryIds).length > 0
  const hasDescription = isDefined(props.description)
  const showFooter = hasComplexity || hasDirectories

  const { className, ...restProps } = props

  return (
    <div
      data-name="LabelChecker"
      onClick={onLabelCheckerClick(props)}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseOverLeave}
    >
      <ContainerFlex
        name="LabelCheckerContent"
        labelledBy={restProps.name}
        className={className}
        direction="column"
        justifyContent="space-between"
        items={[
          <ContainerFlex
            name="LabelCheckerContentPart"
            direction="column"
            items={[
              <LabelCheckerTitle {...restProps} />,

              hasDescription && <LabelCheckerDescription {...restProps} />
            ]}
          />,

          showFooter && (
            <LabelCheckerFooter {...restProps} isOvered={isOvered} />
          )
        ]}
      />
    </div>
  )
}

function showBackgroundColor(
  theme: DSTheme,
  props: ILabelCheckerProps<any>
): string {
  if (props.enabled === false) {
    return theme.tokens['labelChecker/backgroundColor/disabled']
  }

  if (props.selectedBackground === false) {
    return theme.tokens['labelChecker/backgroundColor/default']
  }

  if (
    (props.selected || props.indeterminate) &&
    (props.checkable || props.selectable)
  ) {
    return theme.tokens['labelChecker/backgroundColor/selectable']
  }

  return theme.tokens['labelChecker/backgroundColor/default']
}

export default styled(LabelChecker)`
  position: relative;

  min-width: ${props => (props.size ? props.size : LabelCheckerSize.normal)};
  max-width: ${props => (props.size ? props.size : LabelCheckerSize.normal)};
  height: ${props => (props.small ? 'auto' : LabelCheckerHeight.normal)};

  color: ${props =>
    props.enabled === false
      ? props.theme.tokens['labelChecker/color/disabled']
      : props.theme.tokens['labelChecker/color/default']};
  background-color: ${props => showBackgroundColor(props.theme, props)};
  opacity: ${props => (props.resolved ? 0.5 : 1)};

  border: 1px solid
    ${props => props.theme.tokens['labelChecker/borderColor/default']};
  border-radius: 4px;
  border-left: 4px solid
    ${props => getCriticityColor(props.criticity || Criticity.Unknown)};

  cursor: ${props => (props.onClick ? 'pointer' : 'normal')};

  :hover {
    background-color: ${props =>
      !isDefined(props.hoverable) || props.hoverable === true
        ? props.theme.tokens['labelChecker/backgroundColor/hover']
        : props.theme.tokens['labelChecker/backgroundColor/default']};
  }
`
