import { ContainerSimple } from '@app/components-legacy/Container'
import { Slider } from 'antd'
import type { SliderRangeProps, SliderSingleProps } from 'antd/lib/slider'
import * as React from 'react'
import type { IFormWrapperControl } from './types'

interface IFormWrapperSliderProps extends IFormWrapperControl {
  sliderProps: SliderSingleProps | SliderRangeProps
}

const FormWrapperSlider: React.FC<IFormWrapperSliderProps> = props => {
  return (
    <ContainerSimple
      name={`${FormWrapperSlider.name}-${props.labelledBy}`}
      ariaRoles={props.ariaRoles || ['form', 'slider']}
      {...props}
    >
      <Slider {...props.sliderProps}>{props.children}</Slider>
    </ContainerSimple>
  )
}

export default FormWrapperSlider
