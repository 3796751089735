import { useAppTranslator } from '@app/hooks'
import * as React from 'react'
import { FormWrapperButton } from '../Form'
import { IconDownloadOutlined } from '../Icon/IconAntd'

interface IButtonExportAllProps {
  onClick?: () => void
}

const ButtonExportAll: React.FC<IButtonExportAllProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Buttons'] })

  return (
    <FormWrapperButton
      labelledBy="exportAll"
      buttonProps={{
        type: 'primary',
        icon: <IconDownloadOutlined />,
        onClick: props.onClick
      }}
    >
      {translate('Export all')}
    </FormWrapperButton>
  )
}

export default ButtonExportAll
