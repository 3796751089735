import { ContainerContent } from '@app/components-legacy/Container'
import { useStores } from '@app/hooks/useStores'
import ApiKeyForm from '@app/pages/Preferences/PreferencesApiKey/ApiKeyForm'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToPreferences } from '../permissions'
import { onPreferencesApiKeyLoad } from './handlers'

interface IPreferencesApiKeyProps {}

const PreferencesApiKey: React.FC<IPreferencesApiKeyProps> = () => {
  const { storePreferences } = useStores()

  return (
    <ContainerContent
      flags={storePreferences.storeFlagsApiKey.flags}
      onLoad={onPreferencesApiKeyLoad(storePreferences)}
      rbacCapabilityCheck={canAccessToPreferences}
    >
      <ApiKeyForm />
    </ContainerContent>
  )
}

export default observer(PreferencesApiKey)
