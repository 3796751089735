import { Features } from '@alsid/common'
import { IconRight } from '@app/components/Icon'
import { ContainerFlex } from '@app/components-legacy/Container'
import FormWrapperCheckboxSimple from '@app/components-legacy/Form/Wrappers/FormWrapperCheckboxSimple'
import type { EntityAttackPathEdge, EntityAttackPathNode } from '@app/entities'
import { useStores } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { handleDrawerNodeRelationshipSelectionChange } from '@app/pages/AttackPath/DrawerNodeRelationships/handlers'
import { MAX_DRAWER_NODE_SELECTION } from '@app/stores/AttackPath/consts'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import RelationTypesIcons from '../Components/RelationTypesIcons'
import RelationsTooltip from '../SceneBlade/Components/RelationsTooltip'
import DrawerNodeRelationship from './DrawerNodeRelationship'

const HorizontalLine = styled.hr`
  height: 1px;
  width: 10px;
  border-top: 1px solid ${consts.colorAttackPathPrimary};
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  margin: 0;
`

const RelationTypeIconsContainer = styled.div<{
  maxIcons: number
  hasArrow: boolean
}>`
  width: ${props => props.maxIcons * 20}px;
  margin-right: ${consts.marginSmall};
  margin-left: ${props => (props.hasArrow ? consts.marginSmall : 0)};
`

const NodeContainerFlex = styled(ContainerFlex)`
  position: relative;
`

const StyledIconRight = styled(IconRight)`
  position: absolute;
  top: 11px;
  left: 2px;
`

const StyledFormWrapperCheckbox = styled(FormWrapperCheckboxSimple)`
  margin-left: 12px;
`

export interface IDrawerNodeRelationshipsNodeProps {
  className?: string
  style?: React.CSSProperties
  edge: EntityAttackPathEdge
  node: EntityAttackPathNode
  hasArrow: boolean
  maxRelationTypes: number
}

const DrawerNodeRelationshipsNode: React.FC<
  IDrawerNodeRelationshipsNodeProps
> = props => {
  const { storeAttackPath } = useStores()

  const { storeWidgetListDrawerNodeRelationships } = storeAttackPath

  const featureAttackPathDrawerSelection = useFeatureFlag({
    featureFlagName: Features.ATTACK_PATH_DRAWER_SELECTION
  })

  const { node, edge } = props

  if (!edge.relationTypes) {
    return null
  }

  const checked = storeWidgetListDrawerNodeRelationships.isSelected(node)

  const selectionDisabled =
    !checked &&
    storeWidgetListDrawerNodeRelationships.countSelectedRows >=
      MAX_DRAWER_NODE_SELECTION

  return (
    <NodeContainerFlex
      name="DrawerNodeRelationshipsNode"
      labelledBy={`attackPathGroupDrawer/node/id-${node.id}/uid-${node.uid}`}
      alignItems="center"
      itemsFlexGrow={[0, 0, 0, 1]}
      items={[
        props.hasArrow && (
          <StyledIconRight color={consts.colorAttackPathArrow} />
        ),

        <HorizontalLine />,

        <RelationTypeIconsContainer
          maxIcons={props.maxRelationTypes}
          hasArrow={props.hasArrow}
        >
          <RelationsTooltip
            relationTypes={edge.relationTypes}
            controlRightRelations={edge.controlRightRelations}
          >
            <div>
              <RelationTypesIcons
                labelledBy={`attackPathGroupDrawer/node/id-${node.id}/uid-${node.uid}`}
                relationTypes={edge.relationTypes}
                size={20}
              />
            </div>
          </RelationsTooltip>
        </RelationTypeIconsContainer>,

        <DrawerNodeRelationship
          entityAttackPathNode={node}
          maxWidth={
            730 -
            10 -
            20 * props.maxRelationTypes -
            10 -
            (props.hasArrow ? 10 : 0)
          }
        />,

        featureAttackPathDrawerSelection && (
          <StyledFormWrapperCheckbox
            labelledBy={`DrawerNodeRelationshipsNodesCheckbox/${node.id}`}
            checked={checked}
            disabled={selectionDisabled}
            onChange={handleDrawerNodeRelationshipSelectionChange(
              storeAttackPath,
              node
            )}
          />
        )
      ]}
    />
  )
}

export default observer(DrawerNodeRelationshipsNode)
