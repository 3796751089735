import { BladesContainer } from '@app/components-legacy/Blade'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LayoutDefault } from '@app/components-legacy/Layout'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { AppRouteName } from '@app/routes'
import { grant } from '@libs/rbac/permissions'
import * as React from 'react'
import AboutPage from './AboutPage'
import { canAccessToAbout } from './AboutPage/permissions'
import ConfigurationPage from './ConfigurationPage'
import { canAccessToSystemConfiguration } from './ConfigurationPage/permissions'
import DirectoriesPage from './Directories/DirectoriesPage'
import { canAccessToDirectories } from './Directories/permissions'
import InfrastructuresPage from './Infrastructures/InfrastructuresPage'
import { canAccessToInfrastructures } from './Infrastructures/permissions'
import LegalPage from './LegalPage'
import { canAccessToRelays } from './Relays/permissions'
import RelaysPage from './Relays/RelaysPage'
import { canAccessToTenantsManagement } from './Tenants/permissions'
import TenantsPage from './Tenants/TenantsPage'

interface ISystemPageProps {}

const SystemPage: React.FC<ISystemPageProps> = props => {
  return (
    <LayoutDefault
      content={
        <ContainerFlex
          name="ManagementSystem"
          direction="column"
          items={[
            <BladesContainer>
              <RbacRoutes
                routes={[
                  {
                    routeDefinition: {
                      routeName: AppRouteName.Management_System_Relays,
                      parameters: {}
                    },
                    component: RelaysPage,
                    rbacPermissionsCheck: () => canAccessToRelays
                  },
                  {
                    routeDefinition: {
                      routeName: AppRouteName.Management_System_Infrastructures,
                      parameters: {}
                    },
                    component: InfrastructuresPage,
                    rbacPermissionsCheck: () => canAccessToInfrastructures
                  },
                  {
                    routeDefinition: {
                      routeName: AppRouteName.Management_System_Directories,
                      parameters: {}
                    },
                    component: DirectoriesPage,
                    rbacPermissionsCheck: () => canAccessToDirectories
                  },
                  {
                    routeDefinition: {
                      routeName: AppRouteName.Management_System_Tenants,
                      parameters: {}
                    },
                    component: TenantsPage,
                    rbacPermissionsCheck: () => canAccessToTenantsManagement
                  },
                  {
                    routeDefinition: {
                      routeName: AppRouteName.Management_System_Configuration,
                      parameters: {}
                    },
                    component: ConfigurationPage,
                    rbacPermissionsCheck: () => canAccessToSystemConfiguration
                  },
                  {
                    routeDefinition: {
                      routeName: AppRouteName.Management_System_About,
                      parameters: {}
                    },
                    component: AboutPage,
                    rbacPermissionsCheck: () => canAccessToAbout
                  },
                  {
                    routeDefinition: {
                      routeName: AppRouteName.Management_System_Legal,
                      parameters: {}
                    },
                    component: LegalPage,
                    rbacPermissionsCheck: () => grant()
                  }
                ]}
                redirect
              />
            </BladesContainer>
          ]}
        />
      }
    />
  )
}

export default SystemPage
