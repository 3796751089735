import { ContainerFlex } from '@app/components/Container'
import IconDirectionPreviousAction from '@app/components/Icon/IconDirectionPreviousAction'
import { ContainerIcon, ContainerRbac } from '@app/components-legacy/Container'
import ButtonSimple from '@app/components-legacy/Form/Wrappers/ButtonSimple'
import {
  IconDeleteOutlined,
  IconEditOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { InputSearch } from '@app/components-legacy/Input'
import {
  WidgetListTable,
  WidgetListTableActionIcons
} from '@app/components-legacy/Widgets/WidgetList'
import type { IDataRowRelay } from '@app/entities/EntityRelay'
import type EntityRelay from '@app/entities/EntityRelay'
import { useAppRouter } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { canEditRelays, canRenewRelaysApiKeys } from '../../permissions'
import {
  handleDeleteRelayIconOnClick,
  handleEditRelayIconOnClick,
  handleRelayRenewApiKey
} from './handlers'

const StyledButtonSimple = styled(ButtonSimple)`
  display: flex;
  align-items: center;
  margin-right: 6px;

  :hover {
    svg path {
      fill: ${props => props.theme.tokens['containerIcon/color/hover']};
    }
  }

  &[aria-disabled='true'] {
    svg path {
      fill: ${props => props.theme.tokens['containerIcon/color/disabled']};
    }
  }
`

export interface IRelaysListProps {}

const RelaysList: React.FC<IRelaysListProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.System.Relays']
  })

  const appRouter = useAppRouter()

  const { storeManagementRelays, storeRbac } = useStores()

  const theme = useDSTheme()

  return (
    <ContainerFlex
      name="RelaysList"
      direction="column"
      items={[
        <InputSearch
          placeholder={translate('Search for a Relay')}
          storeInputSearch={storeManagementRelays.storeInputSearch}
        />,

        <WidgetListTable<EntityRelay, IDataRowRelay>
          translate={translate}
          hiddenColumnsKeys={['id']}
          storeWidgetList={storeManagementRelays.storeWidgetList}
          actionsButtonsRenderFn={relayId => {
            return (
              <ContainerRbac
                rbacCapabilityCheck={canEditRelays(Number(relayId))}
              >
                <WidgetListTableActionIcons
                  icons={[
                    storeRbac.isUserGrantedTo(canRenewRelaysApiKeys()) && (
                      <Tooltip title={translate('Renew API Key')}>
                        <StyledButtonSimple
                          labelledBy={`renewRelayApiKey-${relayId}`}
                          className="iconDisplayedOnHover"
                          disabled={
                            storeManagementRelays.storeFlagsRelaysRenewApiKey
                              .isLoading
                          }
                          onClick={handleRelayRenewApiKey(
                            storeManagementRelays,
                            Number(relayId)
                          )}
                        >
                          <IconDirectionPreviousAction
                            color={theme.tokens['containerIcon/color/default']}
                            size={14}
                          />
                        </StyledButtonSimple>
                      </Tooltip>
                    ),

                    <ContainerIcon
                      labelledBy={`editRelay-${relayId}`}
                      iconComponent={IconEditOutlined}
                      iconProps={{
                        type: 'edit',
                        onClick: handleEditRelayIconOnClick(appRouter)(
                          Number(relayId)
                        )
                      }}
                    />,

                    // display the delete button if the relay is not linked to any directory
                    storeManagementRelays.isDeletable(Number(relayId)) && (
                      <ContainerIcon
                        labelledBy={`deleteRelay-${relayId}`}
                        iconComponent={IconDeleteOutlined}
                        iconProps={{
                          onClick: handleDeleteRelayIconOnClick(
                            storeManagementRelays
                          )(Number(relayId))
                        }}
                      />
                    )
                  ]}
                />
              </ContainerRbac>
            )
          }}
        />
      ]}
      spaced
    />
  )
}

export default observer(RelaysList)
