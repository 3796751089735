import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled from 'styled-components'
import { onBladeOverlayClick } from './handlers'

interface IBladeOverlayProps {
  className?: string
  uuid: string
  level: number
  inPosition: boolean
}

const BladeOverlay: React.FC<IBladeOverlayProps> = props => {
  const { storeBlades } = useStores()

  return (
    <div
      data-name="BladeOverlay"
      className={props.className}
      onClick={onBladeOverlayClick(storeBlades)(props.uuid)}
    />
  )
}

export default styled(BladeOverlay)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  // Set a z-index to display the overlay above the content of the previous blade
  z-index: ${consts.zIndexBlade};

  background-color: ${props =>
    isThemeLegacy(props.theme)
      ? consts.bladesOverlayBackground(props.inPosition)
      : consts.bladesOverlayBackgroundRedesign(props.inPosition)};
  transition: ${consts.transitionOpacity};
  cursor: pointer;
`
