import { ContainerFlex } from '@app/components-legacy/Container'
import { useAppTranslator, useStores } from '@app/hooks'
import { CTAColorV2 } from '@app/styles/consts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { AttackPathSearchTabs, AttackPathVariant } from '../../types'
import AttackPathTab from './AttackPathTab'
import { handleNodeFinderTabClick } from './handlers'

interface IAttackPathTabsProps {
  className?: string
  variant?: AttackPathVariant
  tabHandler?: () => void
}

const StyledContainer = styled(ContainerFlex)<{
  variant?: AttackPathVariant
}>`
  border-radius: 5px;
  background-color: ${props =>
    !props.variant || props.variant === AttackPathVariant.default
      ? 'rgba(255, 255, 255, 0.2)'
      : CTAColorV2.secondary};
  pointer-events: auto;
  z-index: 5;
`

const AttackPathTabs: React.FC<IAttackPathTabsProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.NodeFinder']
  })

  const { storeAttackPath } = useStores()

  const { activeTab } = storeAttackPath

  return (
    <StyledContainer
      name="AttackPathTabs"
      className={props.className}
      variant={props.variant}
      alignItems="center"
      items={[
        <AttackPathTab
          labelledBy="attack-path"
          className={`${
            activeTab === AttackPathSearchTabs.attackPath ? 'active' : ''
          }`}
          variant={props.variant}
          onClick={handleNodeFinderTabClick(storeAttackPath)(
            AttackPathSearchTabs.attackPath
          )(props.tabHandler)}
        >
          {translate('AttackPath')}
        </AttackPathTab>,
        <AttackPathTab
          labelledBy="blast-radius"
          className={`${
            activeTab === AttackPathSearchTabs.blastRadius ? 'active' : ''
          }`}
          variant={props.variant}
          onClick={handleNodeFinderTabClick(storeAttackPath)(
            AttackPathSearchTabs.blastRadius
          )(props.tabHandler)}
        >
          {translate('Blast radius')}
        </AttackPathTab>,
        <AttackPathTab
          labelledBy="asset-exposure"
          className={`${
            activeTab === AttackPathSearchTabs.assetExposure ? 'active' : ''
          }`}
          variant={props.variant}
          onClick={handleNodeFinderTabClick(storeAttackPath)(
            AttackPathSearchTabs.assetExposure
          )(props.tabHandler)}
        >
          {translate('Asset Exposure')}
        </AttackPathTab>
      ]}
    />
  )
}

export default observer(AttackPathTabs)
