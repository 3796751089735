import { createEntity } from '@app/entities'
import EntityHealthCheckDetailedGlobalStatus from '@app/entities/EntityHealthCheckDetailedGlobalStatus'
import { HEALTH_CHECK_GLOBAL_STATUS_REFRESH_TIMEOUT } from '@app/stores/HealthCheck/consts'
import type { QueryHealthChecksDetailedGlobalStatus } from '@server/graphql/queries/healthCheck'
import { queryHealthChecksDetailedGlobalStatus } from '@server/graphql/queries/healthCheck'
import type {
  HealthCheckDetailedGlobalStatus,
  Maybe
} from '@server/graphql/typeDefs/types'
import { action, computed, makeObservable, observable } from 'mobx'
import * as uuid from 'uuid'
import type { StoreRoot } from '..'
import StoreFlags from '../helpers/StoreFlags'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'

export default class StoreHealthCheckGlobalStatus extends StoreBase {
  private globalStatusPollingTimeout: Maybe<NodeJS.Timeout> = null

  /**
   * Ensure that only one timeout is registered at a time.
   * Replace by AbortController when graphql-request is updated to v4.
   */
  private currentRequestUuid: Maybe<string> = null

  /* Flags */

  public storeFlagsFetchGlobalStatus = new StoreFlags(this.storeRoot)

  /* Observables */

  private $healthCheckGlobalStatus =
    observable.box<Maybe<EntityHealthCheckDetailedGlobalStatus>>(null)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Start to refresh periodically the global status.
   */
  startPollingGlobalStatus() {
    this.globalStatusPollingTimeout = setTimeout(async () => {
      const localUuid = uuid.v4()
      this.currentRequestUuid = localUuid

      await this.fetchHealthChecksGlobalStatus()

      if (localUuid !== this.currentRequestUuid) {
        return
      }

      this.startPollingGlobalStatus()
    }, HEALTH_CHECK_GLOBAL_STATUS_REFRESH_TIMEOUT * 1000)
  }

  /**
   * Stop the periodic refresh of the global status.
   */
  stopPollingGlobalStatus() {
    if (this.globalStatusPollingTimeout) {
      clearTimeout(this.globalStatusPollingTimeout)
    }
    this.globalStatusPollingTimeout = null
    this.currentRequestUuid = null
  }

  /**
   * Fetch health checks global status
   */
  fetchHealthChecksGlobalStatus(storeFlags = this.storeFlagsFetchGlobalStatus) {
    storeFlags.loading()
    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .makeQuery<QueryHealthChecksDetailedGlobalStatus>(
            queryHealthChecksDetailedGlobalStatus
          )
      })
      .then(healthChecksDetailedGlobalStatus => {
        if (!healthChecksDetailedGlobalStatus) {
          throw new Error('Unable to retrieve health checks global status')
        }

        const healthCheckEntities = createEntity<
          HealthCheckDetailedGlobalStatus,
          EntityHealthCheckDetailedGlobalStatus
        >(
          EntityHealthCheckDetailedGlobalStatus,
          healthChecksDetailedGlobalStatus.healthChecksDetailedGlobalStatus
        )
        this.setHealthCheckGlobalStatus(healthCheckEntities)

        storeFlags.success()
      })
      .catch(err => {
        this.setHealthCheckGlobalStatus(null)

        storeFlags.fail()

        this.storeRoot.logException(err)
      })
  }

  /**
   * Force the refresh of the global status and reset the timer.
   */
  async refreshGlobalStatusManually(): Promise<void> {
    this.stopPollingGlobalStatus()

    const localUuid = uuid.v4()
    this.currentRequestUuid = localUuid

    await this.fetchHealthChecksGlobalStatus()

    if (localUuid !== this.currentRequestUuid) {
      return
    }

    this.startPollingGlobalStatus()
  }

  /* Actions */

  @action
  reset(): this {
    this.$healthCheckGlobalStatus.set(null)

    this.storeFlagsFetchGlobalStatus.reset()

    this.stopPollingGlobalStatus()

    return this
  }

  @action
  setHealthCheckGlobalStatus(
    healthCheckGlobalStatusEntity: Maybe<EntityHealthCheckDetailedGlobalStatus>
  ): this {
    this.$healthCheckGlobalStatus.set(healthCheckGlobalStatusEntity)
    return this
  }

  /* Computed */

  @computed
  get healthCheckGlobalStatus(): Maybe<EntityHealthCheckDetailedGlobalStatus> {
    return this.$healthCheckGlobalStatus.get()
  }
}
