import { Features } from '@alsid/common'
import type { StoreAbout } from '@app/stores'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  deny,
  editApiKey,
  editPreferences,
  readProfiles,
  readUIPreferences
} from '@libs/rbac/permissions'

export const canAccessToPreferences =
  rbacCapabilityCheckAllOf(readUIPreferences())

export const canSetDefaultLangage = rbacCapabilityCheckAllOf(
  canAccessToPreferences,
  editPreferences()
)

export const canSetDefaultProfile = rbacCapabilityCheckAllOf(
  canAccessToPreferences,
  readProfiles(),
  editPreferences()
)

export const canEditCredentials = (storeAbout: StoreAbout) => {
  if (storeAbout.productAssociation?.isAssociatedToTenableOne()) {
    const featureWorkspaceAppswitcherInTad =
      storeAbout.storeRoot.stores.storeRbac.isUserGrantedAccordingFeatureFlag(
        Features.WORKSPACE_APPSWITCHER_IN_TAD
      )

    if (featureWorkspaceAppswitcherInTad) {
      return deny()
    }
  }

  return rbacCapabilityCheckAllOf(canAccessToPreferences, editPreferences())
}

export const canConfigureApiKey = rbacCapabilityCheckAllOf(
  canAccessToPreferences,
  editApiKey()
)
