import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canAccessToAdIoEDetails } from '../IoEBoardPage/permissions'
import { CheckerDetailsPageContext } from './context'
import DetailsBladeHeader from './DetailsBladeHeader'
import DetailsBladeMenu from './DetailsBladeMenu'
import DetailsDeviantObjectsPage from './DetailsDeviantObjectsPage'
import DetailsInformationsPage from './DetailsInformationsPage'
import DetailsRecommendationsPage from './DetailsRecommendationsPage'
import DetailsVulnerabilityDetailsPage from './DetailsVulnerabilityDetailsPage'
import { handleCheckerDetailsOnLoad } from './handlers'
import {
  canAccessToIoEDeviantObjects,
  canReadIoEInformation,
  canReadIoERecommandations,
  canReadIoEVulnerabilityDetails
} from './permissions'

interface IIoECheckerDetailsPageProps {}

const IoECheckerDetailsPage: React.FC<IIoECheckerDetailsPageProps> = () => {
  const appRouter = useAppRouter()

  const { storeAuthentication, storeInfrastructures, storeIoE } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.IoE_Details,
    parameters: {
      checkerId: Number(),
      checkerCodename: String()
    }
  })

  if (!parameters) {
    return null
  }

  return (
    <CheckerDetailsPageContext.Provider
      value={{
        checkerId: parameters.checkerId,
        checkerCodename: parameters.checkerCodename
      }}
    >
      <Blade
        routeDefinition={{
          routeName: AppRouteName.IoE_Details,
          parameters: {
            checkerId: parameters.checkerId,
            checkerCodename: parameters.checkerCodename
          }
        }}
        onLoad={handleCheckerDetailsOnLoad(
          storeAuthentication,
          storeInfrastructures,
          storeIoE.storeIndicator
        )(parameters.checkerId)}
        flags={[
          storeInfrastructures.storeFlagsFetchInfrastructures.flags,
          storeIoE.storeFlagsFetchCheckersExposure.flags,
          storeIoE.storeIndicator.storeFlagsFetchCheckerExposureComplianceStatus
            .flags
        ]}
        layout={{
          name: 'default',
          header: <DetailsBladeHeader />,
          menu: <DetailsBladeMenu />,
          content: (
            <RbacRoutes
              routes={[
                {
                  routeDefinition: {
                    routeName: AppRouteName.IoE_Details_Information,
                    parameters: {
                      checkerId: parameters.checkerId,
                      checkerCodename: parameters.checkerCodename
                    }
                  },
                  component: DetailsInformationsPage,
                  rbacPermissionsCheck: () =>
                    canReadIoEInformation(parameters.checkerId)
                },
                {
                  routeDefinition: {
                    routeName: AppRouteName.IoE_Details_VulnerabilityDetails,
                    parameters: {
                      checkerId: parameters.checkerId,
                      checkerCodename: parameters.checkerCodename
                    }
                  },
                  component: DetailsVulnerabilityDetailsPage,
                  rbacPermissionsCheck: () =>
                    canReadIoEVulnerabilityDetails(parameters.checkerId)
                },
                {
                  routeDefinition: {
                    routeName: AppRouteName.IoE_Details_DeviantObjects,
                    parameters: {
                      checkerId: parameters.checkerId,
                      checkerCodename: parameters.checkerCodename
                    }
                  },
                  component: DetailsDeviantObjectsPage,
                  rbacPermissionsCheck: () =>
                    canAccessToIoEDeviantObjects(parameters.checkerId)
                },
                {
                  routeDefinition: {
                    routeName: AppRouteName.IoE_Details_Recommendations,
                    parameters: {
                      checkerId: parameters.checkerId,
                      checkerCodename: parameters.checkerCodename
                    }
                  },
                  component: DetailsRecommendationsPage,
                  rbacPermissionsCheck: () =>
                    canReadIoERecommandations(parameters.checkerId)
                }
              ]}
              redirect
            />
          ),
          footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
        }}
        rbacCapabilityCheck={canAccessToAdIoEDetails(parameters.checkerId)}
      />
    </CheckerDetailsPageContext.Provider>
  )
}

export default observer(IoECheckerDetailsPage)
