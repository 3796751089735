import type { IDataRowInfrastructure } from '@app/entities/EntityInfrastructure'
import type { StoreManagementInfrastructures } from '@app/stores'

/**
 * Delete the infrastructure, close the drawer and reload infrastructures.
 */
export const onInfrastructureDeleteValidate =
  (storeManagementInfrastructures: StoreManagementInfrastructures) =>
  (dataRow: IDataRowInfrastructure) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    storeManagementInfrastructures
      .deleteInfrastructure(dataRow.id, dataRow.name)
      .then(() => {
        storeManagementInfrastructures.storeDeleteDrawer.closeDrawer()

        // reload infrastructures (and remake the mapping between infras and dirs)
        return storeManagementInfrastructures.fetchInfrastructures()
      })
      .catch(() => {
        // already catched in the store
      })
  }
