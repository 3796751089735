import type { MaybeUndef, Maybe } from '@@types/helpers'
import { ContainerForm } from '@app/components-legacy/Container'
import type {
  ContainerFormVariant,
  IField
} from '@app/components-legacy/Container/ContainerForm/types'
import WidgetFormCheckbox from '@app/components-legacy/Widgets/WidgetForm/WidgetFormCheckbox'
import type StoreForm from '@app/stores/helpers/StoreForm'
import type Field from '@app/stores/helpers/StoreForm/Field'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import type { TranslateFn } from '@libs/i18n'
import { isDefined } from '@libs/isDefined'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import * as uuid from 'uuid'
import type {
  FieldParams,
  WidgetFormFieldRenderer,
  WidgetFormFieldsParams,
  WidgetFormRenderDescription,
  WidgetFormRenderFields
} from './types'
import WidgetFormInput from './WidgetFormInput'
import WidgetFormInputAutoComplete from './WidgetFormInputAutoComplete'
import WidgetFormInputPassword from './WidgetFormInputPassword'
import WidgetFormSelect from './WidgetFormSelect'
import WidgetFormSwitch from './WidgetFormSwitch'
import WidgetFormTextareaCertificate from './WidgetFormTextareaCertificate'

interface IWidgetFormProps<F extends string> {
  /** Optional fields additional params to specify the rendered component or to pass props */
  fieldsParams?: WidgetFormFieldsParams<F>
  /** Set this value to render only the mentioned fields, if unset all the fields will be rendered */
  displayedFields?: Maybe<F[]>
  /** Optional fields to hide */
  hiddenFields?: Maybe<F[]>
  /**
   *  Optional custom render for any fields, the component returned by the function passed
   *  should use the MobX observer to be updated on input change
   */
  renderFields?: WidgetFormRenderFields<F>
  /** Custom description fields */
  renderDescriptionFields?: WidgetFormRenderDescription<F>
  /** Translator used to translate labels */
  translate: TranslateFn
  /** StoreForm to get data */
  storeForm: StoreForm<F>
  /** Change form size */
  variant?: ContainerFormVariant
}

/**
 * @deprecated
 */
function WidgetForm<F extends string>(
  props: IWidgetFormProps<F>
): React.ReactElement {
  const formUuid = React.useRef<string>(uuid.v4())

  /**
   * Return component in charge of the render of the field.
   */
  function getFieldControl(
    field: Field,
    inputId: string
  ): Maybe<React.ReactElement> {
    const fieldName = field.name as F

    const renderFunction: MaybeUndef<WidgetFormFieldRenderer<F>> =
      props.renderFields?.[fieldName]

    if (renderFunction) {
      return renderFunction(props.storeForm, field, inputId)
    }

    const customDescription = props.renderDescriptionFields?.[fieldName]

    const customFieldParams: MaybeUndef<FieldParams> =
      props.fieldsParams?.[fieldName]

    const fieldParams: FieldParams = customFieldParams ?? {
      inputType: props.storeForm.getFieldInputType(fieldName)
    }

    let inputComponent

    switch (fieldParams.inputType) {
      case InputType.checkbox:
        inputComponent = (
          <WidgetFormCheckbox
            fieldName={fieldName}
            inputId={inputId}
            inputProps={fieldParams.inputProps}
            storeForm={props.storeForm}
          />
        )
        break

      case InputType.input:
        inputComponent = (
          <WidgetFormInput
            fieldName={fieldName}
            inputId={inputId}
            inputProps={fieldParams.inputProps}
            storeForm={props.storeForm}
          />
        )
        break

      case InputType.inputAutoComplete:
        inputComponent = (
          <WidgetFormInputAutoComplete
            fieldName={fieldName}
            inputId={inputId}
            inputProps={fieldParams.inputProps}
            storeForm={props.storeForm}
          />
        )
        break

      case InputType.inputPassword:
        inputComponent = (
          <WidgetFormInputPassword
            fieldName={fieldName}
            inputId={inputId}
            inputProps={fieldParams.inputProps}
            storeForm={props.storeForm}
          />
        )
        break

      case InputType.textAreaCertificate:
        inputComponent = (
          <WidgetFormTextareaCertificate
            fieldName={fieldName}
            inputId={inputId}
            inputProps={fieldParams.inputProps}
            storeForm={props.storeForm}
          />
        )
        break

      case InputType.select:
        inputComponent = (
          <WidgetFormSelect
            fieldName={fieldName}
            inputId={inputId}
            inputProps={fieldParams.inputProps}
            storeForm={props.storeForm}
          />
        )
        break

      case InputType.switch:
        inputComponent = (
          <WidgetFormSwitch
            fieldName={fieldName}
            inputId={inputId}
            inputProps={fieldParams.inputProps}
            storeForm={props.storeForm}
          />
        )
        break

      case InputType.hidden:
        inputComponent = null
    }

    if (customDescription) {
      return (
        <>
          {inputComponent}

          {customDescription}
        </>
      )
    }

    return inputComponent
  }

  /**
   * Return extended field params.
   */
  function getExtendedFieldParams(fieldName: F): Maybe<Partial<IField>> {
    const customFieldParams = props.fieldsParams?.[fieldName]?.extendedParams

    if (!customFieldParams) {
      return null
    }

    return customFieldParams
  }

  /**
   * Return true if the field is hidden and should not be displayed.
   */
  function isFieldHidden(field: Field): boolean {
    // hidden fields are hidden by default
    if (field.inputType === InputType.hidden) {
      return true
    }

    const fieldName = field.name as F
    return props.hiddenFields?.includes(fieldName) === true
  }

  const fieldsToDisplay = props.storeForm.getFields().filter(field => {
    if (props.displayedFields) {
      return props.displayedFields.includes(field.name as F)
    }
    return true
  })

  const fields = fieldsToDisplay
    .map(field => {
      if (isFieldHidden(field)) {
        return
      }

      const fieldName = field.name as F
      const fieldInputId = `${formUuid.current}-${fieldName}`
      const fieldLabel = props.storeForm.field(fieldName).label
      const fieldLabelAlignItem =
        props.storeForm.field(fieldName).labelAlignItem
      const fieldDescription = props.storeForm.field(fieldName).description
      const fieldControl = getFieldControl(field, fieldInputId)

      if (!fieldControl) {
        return null
      }

      const finalField: IField = {
        name: fieldName,
        label: fieldLabel ? props.translate(fieldLabel) : fieldName,
        labelAlignItem: fieldLabelAlignItem,
        description: fieldDescription
          ? props.translate(fieldDescription)
          : undefined,
        errors: props.storeForm.field(fieldName).errors,
        required: props.storeForm.isFieldRequired(fieldName),
        control: fieldControl,
        inputId: fieldInputId,
        ...getExtendedFieldParams(fieldName as F)
      }

      return finalField
    })
    .filter(isDefined)

  return <ContainerForm fields={fields} variant={props.variant} />
}

export default observer(WidgetForm)
