import type { MaybeUndef } from '@@types/helpers'
import { Features } from '@alsid/common'
import type { IDataRowUser } from '@app/entities/EntityUser'
import { canReadLockoutPolicy } from '@app/pages/Management/SystemPage/ConfigurationPage/ConfigurationTenableAccountPage/permissions'
import { AppRouteName } from '@app/routes'
import type { StoreAbout, StoreManagementUsers, StoreRbac } from '@app/stores'
import type StoreUsers from '@app/stores/Management/StoreUsers'
import type { AppRouterClient } from '@libs/Router/types'

/**
 * Fetch users and lockout policy data to show/hide lockout status for each user.
 * (If lockout policy is disabled, the lockout status is hidden)
 */
export const onUsersLoad =
  (storeManagementUsers: StoreManagementUsers) => () => {
    const { storeLockoutPolicy, storeRbac } =
      storeManagementUsers.storeRoot.stores

    if (storeRbac.isUserGrantedTo(canReadLockoutPolicy)) {
      storeLockoutPolicy.fetchLockoutPolicy()
    }

    storeManagementUsers.fetchUsers()
  }

export const onUserCreateButtonClick =
  (
    appRouter: AppRouterClient,
    storeAbout: StoreAbout,
    storeRbac: StoreRbac,
    storeManagementUsers: StoreManagementUsers
  ) =>
  () => {
    if (
      storeAbout &&
      storeAbout.productAssociation?.isAssociatedToTenableOne()
    ) {
      const featureWorkspaceAppswitcherInTad =
        storeRbac.isUserGrantedAccordingFeatureFlag(
          Features.WORKSPACE_APPSWITCHER_IN_TAD
        )

      if (featureWorkspaceAppswitcherInTad) {
        storeManagementUsers.storeModalToneUserCreation.show()
        return
      }
    }

    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_Accounts_Users_Create,
      parameters: {}
    })

    appRouter.history.push(url)
  }

export const onUserIconEditClick =
  (appRouter: AppRouterClient) => (userId: number) => () => {
    const url = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_Accounts_Users_Edit,
      parameters: { userId }
    })
    appRouter.history.push(url)
  }

export const onUserIconDeletionClick =
  (storeManagementUsers: StoreManagementUsers) => (userId: number) => () => {
    const userEntity = storeManagementUsers.users.get(userId)

    if (!userEntity) {
      return
    }

    storeManagementUsers.storeDeleteDrawer
      .setData({ userDataRow: userEntity.asDataRow() })
      .openDrawer()
  }

export const onUsersPageChange =
  (storeManagementUsers: StoreManagementUsers) =>
  (page: number, pageSize: MaybeUndef<number>) => {
    storeManagementUsers.fetchUsers({
      usersPage: page,
      usersPerPage: pageSize
    })
  }

/**
 * Delete the user, close the drawer and reload users.
 */
export const onUsersDeleteDrawerSubmit =
  (storeManagementUsers: StoreUsers) =>
  (dataRow: IDataRowUser) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    storeManagementUsers
      .deleteUser(dataRow.id, dataRow.name)
      .then(() => {
        storeManagementUsers.storeDeleteDrawer.closeDrawer()
        return storeManagementUsers.fetchUsers()
      })
      .catch(() => {
        // already catched in the store
      })
  }
