import { MIN_SEARCH_LENGTH, DISTINGUISHED_NAME } from './constants'

/**
 * Check if the minimum string length is reached.
 */
export const isMinLengthReached = (value: string): boolean => {
  return value.length >= MIN_SEARCH_LENGTH
}

/**
 * Replace distinguished name in configuration script
 */
export const replaceDistinguishedName = (
  configurationScript: string,
  name: string
): string => {
  return configurationScript.replace(DISTINGUISHED_NAME, name)
}
