import type { IIconProps } from '@app/components/Icon/types'
import * as React from 'react'

interface IIconWarningTriangleProps extends IIconProps {}

const IconWarningTriangleFilled: React.FC<
  IIconWarningTriangleProps
> = props => {
  const fill = props.color ?? '#DD9845'
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="1.5 -0.5 20 20"
      {...props}
    >
      <path
        d="M11.134 1.5C11.5189 0.833334 12.4811 0.833333 12.866 1.5L21.5263 16.5C21.9112 17.1667 21.4301 18 20.6603 18H3.33975C2.56995 18 2.08882 17.1667 2.47372 16.5L11.134 1.5Z"
        fill={fill}
      />
      <path
        d="M11.6641 13.569L11.4301 10.033V6.38H12.5611V10.033L12.3401 13.569H11.6641ZM11.9956 16.0845C11.766 16.0845 11.5818 16.0217 11.4431 15.896C11.3045 15.766 11.2351 15.5927 11.2351 15.376C11.2351 15.1593 11.3045 14.986 11.4431 14.856C11.5818 14.726 11.766 14.661 11.9956 14.661C12.2296 14.661 12.416 14.7282 12.5546 14.8625C12.6933 14.9925 12.7626 15.1637 12.7626 15.376C12.7626 15.5883 12.6911 15.7595 12.5481 15.8895C12.4095 16.0195 12.2253 16.0845 11.9956 16.0845Z"
        fill="white"
      />
    </svg>
  )
}

export default IconWarningTriangleFilled
