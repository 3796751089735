import type { Perhaps } from '@@types/helpers'

/**
 * Very basic way to know if value seems to be a SDDL.
 */
export function isMaybeAnSDDL(value: Perhaps<string>): boolean {
  if (!value) {
    return false
  }

  return !/^\w+$/.test(value)
}
