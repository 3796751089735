import { Features } from '@alsid/common'
import {
  ContainerContent,
  ContainerFlex,
  ContainerFooter
} from '@app/components/Container'
import { FormWrapper } from '@app/components-legacy/Form/Wrappers'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import FormWrapperButtonSubmit from '@app/components-legacy/Form/Wrappers/ButtonSubmit'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import FilterIPField from './FilterIPField'
import FilterTypeField from './FilterTypeField'
import FilterUserField from './FilterUserField'
import { onAddFiltersResetButtonClick, onFiltersFormValidate } from './handlers'

export interface IDrawerAddFilterForm {}

const StyledFormWrapper = styled(FormWrapper)`
  padding: 0;
`

const StyledHR = styled('hr')`
  width: calc(100% + ${consts.marginDefault} + ${consts.marginDefault});
  background-color: ${consts.colorWhiteAltRedesign};
  border: 0 none;
  color: ${consts.colorWhiteAltRedesign};
  height: 1px;
  left: -${consts.marginDefault};
  position: relative;
`

const DrawerAdditionnalFiltersForm: React.FC<IDrawerAddFilterForm> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'ActivityLogs.Filters']
  })

  const appRouter = useAppRouter()
  const { storeActivityLogs } = useStores()
  const { storeActivityLogsFilters } = storeActivityLogs

  const isActivityLogsUsersFilteringEnabled = useFeatureFlag({
    featureFlagName: Features.ACTIVITY_LOGS_USERS_FILTERING
  })

  const fieldsItems = [
    <FilterIPField storeActivityLogsFilters={storeActivityLogsFilters} />,
    <StyledHR />,
    storeActivityLogsFilters.canShowByUserFilter && (
      <FilterUserField storeActivityLogsFilters={storeActivityLogsFilters} />
    ),
    storeActivityLogsFilters.canShowByUserFilter && <StyledHR />,
    isActivityLogsUsersFilteringEnabled && (
      <FilterTypeField storeActivityLogsFilters={storeActivityLogsFilters} />
    ),
    isActivityLogsUsersFilteringEnabled && <StyledHR />
  ]

  return (
    <StyledFormWrapper
      name="ActivityLogsFilters"
      onSubmit={onFiltersFormValidate(appRouter, storeActivityLogs)}
    >
      <ContainerContent>
        <ContainerFlex
          name="DrawerFiltersFields"
          direction="column"
          items={fieldsItems}
          spaced
          spaceWidth="default"
        />

        <Portal name={PlaceHolderName.drawerFooter}>
          <ContainerFooter>
            <FormWrapperButton
              labelledBy="eraseFilters"
              variant={ButtonVariant.secondary}
              size={ButtonSize.large}
              buttonProps={{
                onClick: onAddFiltersResetButtonClick(storeActivityLogs)
              }}
            >
              {translate('Erase filters')}
            </FormWrapperButton>

            <FormWrapperButtonSubmit
              loading={storeActivityLogsFilters.storeFlagsFetchIPs.isLoading}
            >
              {translate('Validate')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </ContainerContent>
    </StyledFormWrapper>
  )
}

export default observer(DrawerAdditionnalFiltersForm)
