import { AppRouteName } from '@app/routes'
import type { StoreBlades, StoreManagementInfrastructures } from '@app/stores'
import { InfrastructuresFormFieldName } from '@app/stores/Management/StoreInfrastructures'
import type { AppRouterClient } from '@libs/Router/types'
import type { InputEditInfrastructure } from '@server/graphql/typeDefs/types'

/**
 * Initialize the form according to the selected infrastructure.
 */
export const onInfrastructuresEditPageLoad =
  (storeManagementInfrastructures: StoreManagementInfrastructures) =>
  (infrastructureId: number) =>
  () => {
    Promise.resolve()
      .then(() => {
        return !storeManagementInfrastructures.infrastructures.size
          ? storeManagementInfrastructures.fetchInfrastructures()
          : null
      })
      .then(() => {
        const entity =
          storeManagementInfrastructures.infrastructures.get(infrastructureId)

        if (!entity) {
          return
        }

        storeManagementInfrastructures.storeFormEdition
          .setDefaultFieldsValuesFromObject({
            [InfrastructuresFormFieldName.name]:
              entity.getPropertyAsString('name'),
            [InfrastructuresFormFieldName.login]:
              entity.getPropertyAsString('login')
          })
          .reset()
      })
  }

export const onInfrastructuresEditCancelClick =
  (storeBlades: StoreBlades) => () => {
    const url = storeBlades.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Infrastructures,
      parameters: {}
    })

    storeBlades.goToBladeUrl(url)
  }

export const onInfrastructuresEditSubmit =
  (
    appRouter: AppRouterClient,
    storeManagementInfrastructures: StoreManagementInfrastructures
  ) =>
  (infrastructureId: number) =>
  (e: React.FormEvent<any>) => {
    const { storeFormEdition } = storeManagementInfrastructures

    e.preventDefault()

    if (!storeFormEdition.validate()) {
      return
    }

    const infrastructure: InputEditInfrastructure = {
      id: infrastructureId,
      name: storeFormEdition.getFieldValueAsString('name'),
      login: storeFormEdition.getFieldValueAsString('login')
    }

    const password = storeFormEdition.getFieldValueAsString('password')

    // change the password only if a new one has been filled
    if (password) {
      infrastructure.password = password
    }

    storeManagementInfrastructures
      .editInfrastructure(infrastructure)
      .then(() => {
        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Management_System_Infrastructures,
          parameters: {}
        })
        appRouter.history.push(url)
      })
      .catch(() => {
        // already catched in the store
      })
  }
