import { LabelAlert } from '@app/components-legacy/Label'
import { EntityAlertIoE } from '@app/entities'
import { useLogger } from '@app/hooks'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { ensureArray } from '@libs/ensureArray'
import { isDefined } from '@libs/isDefined'
import { CheckerType } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { animated, useTransition } from 'react-spring'
import styled from 'styled-components'

export interface IPlaceHolderAlertsProps {
  className?: string
  alertsLifeTime: 5
}

const LabelContainer = styled(animated.div)`
  background-color: white;
  box-shadow: ${consts.shadowLarge};
`

const PlaceHolderAlertsIoE: React.FC<IPlaceHolderAlertsProps> = props => {
  const { storeCheckers, storeAlerts, storeInfrastructures } = useStores()

  const logger = useLogger()

  const alertEntities = [storeAlerts.storeAlertsIoE.liveAlert].filter(isDefined)

  const transitions = useTransition(alertEntities, 0, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return (
    <div data-name="PlaceHolderAlertsIoE" className={props.className}>
      {transitions.map(transitionProps => {
        const { item: alertEntity, key, props: styleProps } = transitionProps

        if (!(alertEntity instanceof EntityAlertIoE)) {
          return null
        }

        const label = alertEntity.getLabel()

        // the label is the reason name and could be null if not granted to get it.
        if (!label) {
          logger.warn(
            'Alert cant be displayed, the label is undefined. Maybe the reason is missing?'
          )

          // remove the liveAlert to be able to display the next one
          storeAlerts.storeAlertsIoE.removeLiveAlert()

          return null
        }

        const checker = alertEntity.getChecker(
          storeCheckers.storeInputCheckersExposure.checkersById
        )

        if (!checker) {
          return null
        }

        const directories = ensureArray(
          alertEntity.getDirectory(storeInfrastructures.directories)
        )

        return (
          <LabelContainer key={key} style={styleProps}>
            <LabelAlert
              id={alertEntity.getPropertyAsNumber('id')}
              checkerType={CheckerType.Exposure}
              label={label}
              criticity={checker.genericCriticity}
              directories={directories}
              date={alertEntity.date}
              isClosable
              alertLifeTime={props.alertsLifeTime}
              storeInfrastructures={storeInfrastructures}
              storeGenericAlerts={storeAlerts.storeAlertsIoE}
            />
          </LabelContainer>
        )
      })}
    </div>
  )
}

const ObservedPlaceHolderAlerts = observer(PlaceHolderAlertsIoE)

export default styled(ObservedPlaceHolderAlerts)`
  position: absolute;
  z-index: ${consts.zIndexAlertsPlaceholder};
  bottom: 100px;
  left: 30px;
`
