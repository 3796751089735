import type { StoreAlerts } from '@app/stores'

/**
 * On drawer alerts load, count IoE and IoA alerts to initialize badges
 * and register channels WS to listen new alerts.
 *
 * Each new alert will display a notification.
 */
export const onDrawerAlertsLoad = (storeAlerts: StoreAlerts) => () => {
  storeAlerts.storeAlertsIoE.countAlerts()
  storeAlerts.storeAlertsIoE.registerAlertWS()

  storeAlerts.storeAlertsIoA.countAlerts()
  storeAlerts.storeAlertsIoA.registerAlertWS()
}

/**
 * On unload, unregister the alerts websocket.
 * Note that it's theoretical since the Drawer will never be unmounted!
 */
export const onDrawerAlertsUnload = (storeAlerts: StoreAlerts) => () => {
  storeAlerts.storeAlertsIoE.unregisterAlertWS()
  storeAlerts.storeAlertsIoA.unregisterAlertWS()
}

/**
 * Toggle the alert drawer.
 */
export const onDrawerAlertsClick = (storeAlerts: StoreAlerts) => () => {
  storeAlerts.storeDrawer.isDrawerOpened
    ? storeAlerts.closeDrawer()
    : storeAlerts.storeDrawer.openDrawer()
}
