import type { StoreRoot } from '@app/stores'
import { action, computed, makeObservable, observable } from 'mobx'
import StoreBase from './StoreBase'
import type { IStoreOptions } from './types'

export default class StoreWSIndicator extends StoreBase {
  private $isConnected = observable.box<boolean>(false)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)

    this.pollWSConnectionStatus()

    makeObservable(this)
  }

  pollWSConnectionStatus() {
    setTimeout(() => {
      this.setWSConnectionStatus()
      this.pollWSConnectionStatus()
    }, 3000)
  }

  /* Actions */

  @action
  setWSConnectionStatus(): void {
    this.$isConnected.set(this.storeRoot.wsClient.isConnected())
  }

  /* Computed */

  @computed
  get isConnected(): boolean {
    return this.$isConnected.get()
  }
}
