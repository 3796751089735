import type { Maybe } from '@@types/helpers'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  createSyslogAlert,
  deleteSyslogAlert,
  editSyslogAlert,
  readLicenseFeatureIoA,
  readSyslogAlerts,
  readUISystemConfigurationAlertsSyslog
} from '@libs/rbac/permissions'

export const canListSyslogAlerts = rbacCapabilityCheckAllOf(
  readUISystemConfigurationAlertsSyslog(),
  readSyslogAlerts()
)

export const canCreateSyslogAlerts = rbacCapabilityCheckAllOf(
  canListSyslogAlerts,
  createSyslogAlert()
)

export const canReadSyslogAlerts = (syslogId: number) =>
  rbacCapabilityCheckAllOf(canListSyslogAlerts, readSyslogAlerts(syslogId))

export const canEditSyslogAlert = (syslogId: number) =>
  rbacCapabilityCheckAllOf(canListSyslogAlerts, editSyslogAlert(syslogId))

export const canDeleteSyslogAlert = (syslogId: Maybe<number>) =>
  rbacCapabilityCheckAllOf(canListSyslogAlerts, deleteSyslogAlert(syslogId))

export const canAccessToIoASecurityCheck = rbacCapabilityCheckAllOf(
  readLicenseFeatureIoA()
)
