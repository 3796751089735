import { Features } from '@alsid/common'
import {
  IconAddWidget,
  IconDots,
  IconEdit,
  IconPlus
} from '@app/components/Icon'
import { ContainerFlex, ContainerRbac } from '@app/components-legacy/Container'
import FormWrapperButton from '@app/components-legacy/Form/Wrappers/Button'
import {
  ButtonSize,
  ButtonVariant
} from '@app/components-legacy/Form/Wrappers/types'
import {
  IconBarChartOutlined,
  IconCloseOutlined,
  IconDownloadOutlined,
  IconPlusCircleOutlined,
  IconSettingOutlined,
  IconTableOutlined
} from '@app/components-legacy/Icon/IconAntd'
import { LabelIcon } from '@app/components-legacy/Label'
import { LinkButton } from '@app/components-legacy/Link'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { useStores } from '@app/hooks/useStores'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { consts } from '@app/styles'
import { BackgroundColorV2, FontColorV2 } from '@app/styles/consts'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { filterFalsies } from '@libs/filterFalsies'
import { Dropdown, Menu } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import {
  canAccessToDashbards,
  canAddDashboardOrWidget,
  canConfigureDashboard,
  canCreateDashbard,
  canCreateDashboardWidget,
  canDeleteDashbard,
  canEditDashbard
} from '../permissions'
import { onAddDashboardButtonClick } from './DrawerAddDashboard/handlers'
import { onDeleteDashboardButtonClick } from './DrawerDeleteDashboard/handlers'
import { onEditDashboardButtonClick } from './DrawerEditDashboard/handlers'
import { handleExportDashboardOnSubmit } from './DrawerExportDashboardPdf/handlers'
import { onAddWidgetButtonClick } from './handlers'

interface IGridMenuButtonsProps {
  className?: string
}

interface IGridMenuButtonsProps {}

const StyledMenu = styled(Menu)`
  background: ${BackgroundColorV2.tooltips};
  padding: ${consts.paddingVerySmall};
  margin: 0;
  border-radius: ${consts.borderRadiusRedesign};
  margin-top: ${consts.marginVerySmall};

  .ant-dropdown-menu-item {
    color: ${FontColorV2.white};
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    background: ${BackgroundColorV2.tooltips};
    padding-bottom: ${consts.paddingDefault};
    padding-top: ${consts.paddingDefault};
  }

  .ant-dropdown-menu-item:last-child {
    border-bottom: 0;
  }
`
const GridMenuButtons: React.FC<IGridMenuButtonsProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const featureDashboardExport = useFeatureFlag({
    featureFlagName: Features.DASHBOARD_EXPORT
  })

  const { testAttributeProps } = useTestAttribute('menu')

  const translate = useAppTranslator({
    namespaces: [
      'Dashboard.Common',
      'Dashboard.AddDashboardDrawer',
      'Dashboard.DeleteDashboardDrawer'
    ]
  })

  const appRouter = useAppRouter()

  const { storeDashboards, storeRbac } = useStores()

  const dashboardId = storeDashboards.currentDashboardId

  if (!dashboardId) {
    return null
  }

  const renderAddMenu = (): JSX.Element => {
    return themeIsLegacy ? (
      <Menu
        items={filterFalsies([
          storeRbac.isUserGrantedTo(canCreateDashbard) && {
            key: 'addDashboard',
            label: (
              <a
                href="#"
                onClick={onAddDashboardButtonClick(storeDashboards)}
                {...testAttributeProps('menuitem')('addDashboard')}
              >
                <LabelIcon iconComponent={IconTableOutlined}>
                  {translate('Add a dashboard')}
                </LabelIcon>
              </a>
            )
          },
          storeRbac.isUserGrantedTo(canCreateDashboardWidget) && {
            key: 'addWidget',
            label: (
              <a
                href="#"
                onClick={onAddWidgetButtonClick(appRouter)}
                {...testAttributeProps('menuitem')('addWidget')}
              >
                <LabelIcon iconComponent={IconBarChartOutlined}>
                  {translate('Add a new widget on this dashboard')}
                </LabelIcon>
              </a>
            )
          }
        ])}
      />
    ) : (
      <StyledMenu
        items={filterFalsies([
          storeRbac.isUserGrantedTo(canCreateDashbard) && {
            key: 'addDashboard',
            label: (
              <a
                href="#"
                onClick={onAddDashboardButtonClick(storeDashboards)}
                {...testAttributeProps('menuitem')('addDashboard')}
              >
                <LabelIcon
                  iconComponent={IconPlus}
                  iconProps={{ color: FontColorV2.white }}
                >
                  {translate('Add new dashboard')}
                </LabelIcon>
              </a>
            )
          },
          storeRbac.isUserGrantedTo(canCreateDashboardWidget) && {
            key: 'addWidget',
            label: (
              <a
                href="#"
                onClick={onAddWidgetButtonClick(appRouter)}
                {...testAttributeProps('menuitem')('addWidget')}
              >
                <LabelIcon
                  iconComponent={IconAddWidget}
                  iconProps={{ color: FontColorV2.white }}
                >
                  {translate('Add widget to current dashboard')}
                </LabelIcon>
              </a>
            )
          }
        ])}
      />
    )
  }

  const renderConfigurationMenu = (): JSX.Element => {
    return themeIsLegacy ? (
      <Menu
        items={filterFalsies([
          storeRbac.isUserGrantedTo(canEditDashbard(dashboardId)) && {
            key: 'configureDashboard',
            label: (
              <a
                href="#"
                onClick={onEditDashboardButtonClick(storeDashboards)}
                {...testAttributeProps('menuitem')('configureDashboard')}
              >
                <LabelIcon iconComponent={IconSettingOutlined}>
                  {translate('Configure the dashboard')}
                </LabelIcon>
              </a>
            )
          },
          storeRbac.isUserGrantedTo(canDeleteDashbard(dashboardId)) && {
            key: 'deleteDashboard',
            label: (
              <a
                href="#"
                onClick={onDeleteDashboardButtonClick(
                  appRouter,
                  storeDashboards
                )}
                {...testAttributeProps('menuitem')('deleteDashboard')}
              >
                <LabelIcon iconComponent={IconCloseOutlined}>
                  {translate('Delete the dashboard')}
                </LabelIcon>
              </a>
            )
          }
        ])}
      />
    ) : (
      <StyledMenu
        items={filterFalsies([
          featureDashboardExport
            ? storeRbac.isUserGrantedTo(canAccessToDashbards) && {
                key: 'exportDashboardPdf',
                label: (
                  <a
                    key="exportDashboardPdf"
                    href="#"
                    onClick={handleExportDashboardOnSubmit(storeDashboards)}
                    {...testAttributeProps('menuitem')('exportDashboardPdf')}
                  >
                    <LabelIcon
                      iconComponent={IconDownloadOutlined}
                      iconProps={{ color: FontColorV2.white }}
                    >
                      {translate('Export dashboard')}
                    </LabelIcon>
                  </a>
                )
              }
            : null,
          storeRbac.isUserGrantedTo(canEditDashbard(dashboardId)) && {
            key: 'configureDashboard',
            label: (
              <a
                key="configureDashboard"
                href="#"
                onClick={onEditDashboardButtonClick(storeDashboards)}
                {...testAttributeProps('menuitem')('configureDashboard')}
              >
                <LabelIcon
                  iconComponent={IconEdit}
                  iconProps={{ color: FontColorV2.white }}
                >
                  {translate('Edit name')}
                </LabelIcon>
              </a>
            )
          },
          storeRbac.isUserGrantedTo(canDeleteDashbard(dashboardId)) && {
            key: 'deleteDashboard',
            label: (
              <a
                href="#"
                onClick={onDeleteDashboardButtonClick(
                  appRouter,
                  storeDashboards
                )}
                {...testAttributeProps('menuitem')('deleteDashboard')}
              >
                <LabelIcon iconComponent={IconCloseOutlined}>
                  {translate('Delete dashboard')}
                </LabelIcon>
              </a>
            )
          }
        ])}
      />
    )
  }

  const hasDashboards = storeDashboards.sortedDashboards.length > 0

  if (!hasDashboards) {
    return null
  }

  return themeIsLegacy ? (
    <ContainerFlex
      name="GridMenuButtons"
      className={props.className}
      alignItems="center"
      items={filterFalsies([
        <ContainerRbac rbacCapabilityCheck={canConfigureDashboard(dashboardId)}>
          <Dropdown overlay={renderConfigurationMenu()} trigger={['click']}>
            <LinkButton
              iconComponent={IconSettingOutlined}
              label={translate('Configure')}
              labelledBy={'dashboardsConfigure'}
            />
          </Dropdown>
        </ContainerRbac>,

        <ContainerRbac rbacCapabilityCheck={canAddDashboardOrWidget}>
          <Dropdown overlay={renderAddMenu()} trigger={['click']}>
            <LinkButton
              iconComponent={IconPlusCircleOutlined}
              label={translate('Add')}
              labelledBy={'dashboardsAdd'}
            />
          </Dropdown>
        </ContainerRbac>
      ])}
      spaced
    />
  ) : (
    <ContainerFlex
      name="GridMenuButtons"
      className={props.className}
      alignItems="center"
      items={filterFalsies([
        <ContainerRbac rbacCapabilityCheck={canAddDashboardOrWidget}>
          <Dropdown overlay={renderAddMenu()} trigger={['click']}>
            <div>
              <FormWrapperButton
                icon={IconPlus}
                variant={ButtonVariant.secondary}
                size={ButtonSize.small}
                labelledBy={'dashboardsAdd'}
              />
            </div>
          </Dropdown>
        </ContainerRbac>,

        <ContainerRbac rbacCapabilityCheck={canConfigureDashboard(dashboardId)}>
          <Dropdown overlay={renderConfigurationMenu()} trigger={['click']}>
            <div>
              <FormWrapperButton
                icon={IconDots}
                variant={ButtonVariant.secondary}
                size={ButtonSize.small}
                labelledBy={'dashboardsConfigure'}
              />
            </div>
          </Dropdown>
        </ContainerRbac>
      ])}
      spaced
      spaceWidth="small"
    />
  )
}

export default styled(GridMenuButtons)`
  margin-right: ${consts.marginDefault};
`
