import type { PropertiesNullable } from '@@types/helpers'
import type {
  Maybe,
  RbacEntity,
  RbacEntityAction,
  RbacEntityItem,
  RbacEntityName
} from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityRbacEntity
  extends EntityBase
  implements PropertiesNullable<RbacEntity>
{
  entityName: Maybe<RbacEntityName> = null
  url: Maybe<string> = null
  actions: Maybe<RbacEntityAction> = null
  items: RbacEntityItem[] = []

  constructor(data: Partial<EntityRbacEntity>) {
    super()
    Object.assign(this, data)
  }
}
