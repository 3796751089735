import { ContainerPDFPage } from '@app/components-legacy/Container'
import PdfFooterPage from '@app/components-legacy/SVG/Images/PdfFooterPage'
import PdfHeaderTitle from '@app/components-legacy/SVG/Images/PdfHeaderTitle'
import * as React from 'react'
import IoABoardAuthorPDF from './IoABoardAuthorPDF'
import IoABoardFiltersPDF from './IoABoardFiltersPDF'

interface IIoABoardHeaderPDFProps {}

const IoABoardHeaderPDF: React.FC<IIoABoardHeaderPDFProps> = () => {
  return (
    <ContainerPDFPage
      headerItem={<PdfHeaderTitle />}
      footerItem={<PdfFooterPage />}
      bodyItems={[
        <>
          <IoABoardAuthorPDF />
          <IoABoardFiltersPDF />
        </>
      ]}
    />
  )
}

export default IoABoardHeaderPDF
