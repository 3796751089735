import { ContainerFlex } from '@app/components-legacy/Container'
import type { IComponentLoaderProps } from '@app/components-legacy/types'
import { useStores } from '@app/hooks'
import { mergeFlags } from '@app/stores/helpers/StoreFlags/helpers'
import { ensureArray } from '@libs/ensureArray'
import { isDefined } from '@libs/isDefined'
import type { RbacCapabilityCheck } from '@libs/rbac/types'
import * as React from 'react'
import type { IBladeLayoutTwoColumns } from '../types'
import BladeContentHeader from './BladeContentHeader'
import BladeContentLayoutTwoColumnsContent from './BladeContentLayoutTwoColumnsContent'
import BladeContentMenu from './BladeContentMenu'
import BladeContentTitle from './BladeContentTitle'

interface IBladeContentLayoutNavigationProps {
  flags?: IComponentLoaderProps['flags']
  layout: IBladeLayoutTwoColumns
  rbacCapabilityCheck: RbacCapabilityCheck
}

const BladeContentLayoutTwoColumns: React.FC<
  IBladeContentLayoutNavigationProps
> = props => {
  const { storeRbac } = useStores()

  const rbacCapability = storeRbac.checkIfUserIsAbleTo(
    props.rbacCapabilityCheck
  )

  const flags = mergeFlags(...ensureArray(props.flags))

  const isGranted = rbacCapability.isGranted === true
  const isForbidden = flags.isForbidden === true
  const isError = flags.isError === true
  const isLoading = flags.isLoading === true

  return (
    <ContainerFlex
      name="BladeContentLayoutTwoColumns"
      direction="column"
      itemsFlexGrow={[0, 0, 1]}
      items={[
        <BladeContentTitle isLoading={isLoading} layout={props.layout} />,

        <BladeContentHeader
          isLoading={isLoading}
          isGranted={isGranted}
          isError={isError}
          layout={props.layout}
        />,

        <BladeContentMenu
          // Don't hide the menu when loading
          isLoading={false}
          isGranted={isGranted}
          isError={isError}
          layout={props.layout}
        />,

        <BladeContentLayoutTwoColumnsContent
          isGranted={isGranted}
          isForbidden={isForbidden}
          isError={isError}
          isLoading={isLoading}
          rbacCapability={rbacCapability}
          layout={props.layout}
        />
      ].filter(isDefined)}
      fullHeight
    />
  )
}

export default BladeContentLayoutTwoColumns
