import type {
  EntityTopologyDirectory,
  EntityTopologyInfrastructure
} from '@app/entities'
import { createEntity, EntityTopology } from '@app/entities'
import type SceneObject from '@app/pages/Topology/SceneBlade/Scene/SceneObject'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import { ForbiddenAccessError } from '@libs/errors'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import { checkRbac } from '@libs/rbac/functions'
import type { QueryRbacTopology } from '@server/graphql/queries/topology'
import { queryRbacTopology } from '@server/graphql/queries/topology'
import type {
  Maybe,
  RbacTopologyQueryArgs,
  Topology
} from '@server/graphql/typeDefs/types'
import { RbacEntityName } from '@server/graphql/typeDefs/types'
import type { ZoomBehavior } from 'd3'
import { flatMap } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { StoreInfrastructures } from '..'
import type { StoreRoot } from '..'
import StoreFlags from '../helpers/StoreFlags'
import StoreBase from '../StoreBase'
import type { IStoreOptions } from '../types'
import { SCENE_SCALE_DIVIDER } from './consts'
import StoreTopologyDomainDetails from './StoreTopologyDomainDetails'
import type { IChartZoomData, ISceneDimensions, ITooltip } from './types'

export default class StoreTopology extends StoreBase {
  /* Scene stores */

  public storeInfrastructures = new StoreInfrastructures(this.storeRoot)
  public storeFlags = new StoreFlags(this.storeRoot)
  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  /* Domain details */

  public storeTopologyDomainDetails = new StoreTopologyDomainDetails(
    this.storeRoot
  )

  // ref to the DOM element of the chart
  private $chartSvgRef: Maybe<SVGSVGElement> = null
  private $chartScaleSize: Maybe<number> = null
  private $infrastructuresSceneObjects: Maybe<
    Array<SceneObject<EntityTopologyInfrastructure>>
  > = null
  private $handleZoom: Maybe<ZoomBehavior<SVGSVGElement, any>> = null

  /* Observables */

  private $sceneDimensions = observable.box<Maybe<ISceneDimensions>>(null)
  private $chartZoomData = observable.box<Maybe<IChartZoomData>>(null)
  private $chartZoomMin = observable.box<Maybe<number>>(null)
  private $topologyEntity = observable.box<Maybe<EntityTopology>>(null)
  private $isShowingInternalTrusts = observable.box<boolean>(false)
  private $zoomSliderValue = observable.box<Maybe<number>>(null)

  private $tooltip = observable.box<Maybe<SceneObject<ITooltip>>>(null)

  // current hovered infrastructure
  private $infrastructureUid = observable.box<Maybe<string>>(null)

  private $sceneIsReady = observable.box<boolean>(true)

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch topology information
   */
  fetchTopology(args: RbacTopologyQueryArgs): Promise<void> {
    this.storeFlags.loading()

    return Promise.resolve()
      .then(() => {
        return this.storeRoot
          .getGQLRequestor()
          .query<QueryRbacTopology>(queryRbacTopology, args)
      })
      .then(data => data.rbacTopology)
      .then(rbacTopology => {
        if (!checkRbac(this.storeRoot)(rbacTopology)) {
          throw new ForbiddenAccessError(RbacEntityName.Topology)
        }

        const topologyEntity = createEntity<Topology, EntityTopology>(
          EntityTopology,
          rbacTopology.node
        )

        // set topology entity
        this.setTopology(topologyEntity)

        // set scene scale size according to the number of infrastructures
        const nbAllInfrastructures = topologyEntity.getInfrastructures().length
        this.$chartScaleSize = this.computeChartScaleSize(nbAllInfrastructures)

        // set chart zoom min according to the number of infrastructures
        this.setChartZoomMin(this.computeChartZoomMin(nbAllInfrastructures))

        // set the scene as ready to be rendered
        this.setSceneReady()

        this.storeFlags.success()
      })
      .catch(
        handleStoreError(this.storeRoot, this.storeFlags, {
          errorMessageTranslationFn: () => false
        })
      )
  }

  computeChartScaleSize(nbAllInfrastructures: number): number {
    if (nbAllInfrastructures <= 10) {
      return 0.5 + nbAllInfrastructures / SCENE_SCALE_DIVIDER
    }
    // we probably have to follow the formula of the first if, and begin with 1.5 for the next 10 items, etc.
    return nbAllInfrastructures / SCENE_SCALE_DIVIDER
  }

  computeChartZoomMin(nbAllInfrastructures: number): number {
    if (nbAllInfrastructures < 10) {
      return 0.5
    }
    if (nbAllInfrastructures < 20) {
      return 0.35
    }
    if (nbAllInfrastructures < 30) {
      return 0.3
    }
    if (nbAllInfrastructures < 60) {
      return 0.25
    }
    if (nbAllInfrastructures < 80) {
      return 0.2
    }
    if (nbAllInfrastructures < 200) {
      return 0.15
    }
    return 0.1
  }

  /**
   * Return true if from or to directory parent infrastructure is part of the user research.
   */
  isTrustHighlightedFromInfra(
    fromDirectoryObject: EntityTopologyDirectory,
    toDirectoryObject: EntityTopologyDirectory
  ): boolean {
    const searchedInfrastructureUids = this.searchedInfrastructureUids
    if (
      !fromDirectoryObject.uidInfrastructure ||
      !toDirectoryObject.uidInfrastructure
    ) {
      return false
    }
    return (
      searchedInfrastructureUids.indexOf(
        fromDirectoryObject.uidInfrastructure
      ) !== -1 ||
      searchedInfrastructureUids.indexOf(
        toDirectoryObject.uidInfrastructure
      ) !== -1
    )
  }

  /**
   * Return true if from or to directory is part of the user research.
   */
  isTrustHighlightedFromDirectory(
    fromDirectoryObject: EntityTopologyDirectory,
    toDirectoryObject: EntityTopologyDirectory
  ): boolean {
    const searchedDirectoriesUids = this.searchedDirectoriesUids
    if (!fromDirectoryObject.uid || !toDirectoryObject.uid) {
      return false
    }
    return (
      searchedDirectoriesUids.indexOf(fromDirectoryObject.uid) !== -1 ||
      searchedDirectoriesUids.indexOf(toDirectoryObject.uid) !== -1
    )
  }

  /**
   * Return the chart scale computed from the number of infrastructures.
   */
  get chartScaleSize(): number {
    if (!this.$chartScaleSize) {
      throw new Error('Chart scale size is not defined')
    }

    return this.$chartScaleSize
  }

  /* Actions */

  /**
   * Set the scene as ready (to be rendered).
   * @private
   */
  @action
  private setSceneReady(): this {
    this.$sceneIsReady.set(true)
    return this
  }

  /**
   * Set the scene are unready (when leading Topology view).
   */
  @action
  setSceneUnready(): this {
    this.$sceneIsReady.set(false)
    return this
  }

  /**
   * Save scene dimensions.
   */
  @action
  setSceneDimensions(sceneDimensions: ISceneDimensions): this {
    this.$sceneDimensions.set(sceneDimensions)
    return this
  }

  /**
   * Save topology information.
   */
  @action
  setChartSvgRef(ref: SVGSVGElement): this {
    this.$chartSvgRef = ref
    return this
  }

  /**
   * Save infrastructures scene objects information.
   */
  @action
  setinfrastructuresSceneObjects(
    infrastructuresSceneObjects: Array<
      SceneObject<EntityTopologyInfrastructure>
    >
  ): this {
    this.$infrastructuresSceneObjects = infrastructuresSceneObjects
    return this
  }

  /**
   * Save handleZoom behavior information.
   */
  @action
  setHandleZoom(handleZoom: ZoomBehavior<SVGSVGElement, any>): this {
    this.$handleZoom = handleZoom
    return this
  }

  /**
   * Save chart zoom data (on zoom event).
   */
  @action
  setChartZoomData(chartZoomData: IChartZoomData): this {
    this.$chartZoomData.set(chartZoomData)
    return this
  }

  /**
   * Save zoomSlider value (on zoomSlider event).
   */
  @action
  setZoomSliderValue(k: number): this {
    this.$zoomSliderValue.set(k)
    return this
  }

  /**
   * Save topology information.
   */
  @action
  setTopology(topologyEntity: EntityTopology): this {
    this.$topologyEntity.set(topologyEntity)
    return this
  }

  @action
  setShowingInternalTrusts(isHidingInternalTrusts: boolean): this {
    this.$isShowingInternalTrusts.set(isHidingInternalTrusts)
    return this
  }

  @action
  setTooltip(tooltip: SceneObject<ITooltip>): this {
    this.$tooltip.set(tooltip)
    return this
  }

  @action
  removeTooltip(): this {
    this.$tooltip.set(null)
    return this
  }

  @action
  setInfrastructureUid(infrastructureUid: string): this {
    this.$infrastructureUid.set(infrastructureUid)
    return this
  }

  @action
  removeInfrastructureUid(): this {
    this.$infrastructureUid.set(null)
    return this
  }

  @action
  setChartZoomMin(chartZoomMin: number): this {
    this.$chartZoomMin.set(chartZoomMin)
    return this
  }

  /* Computed */

  /**
   * Return the dimension (width, height) of the scene (the main div container).
   * If not defined, throw an exception to avoid to handle nullable values.
   */
  @computed
  get sceneDimensions(): ISceneDimensions {
    const sceneDimensions = this.$sceneDimensions.get()

    if (!sceneDimensions) {
      throw new Error('Scene dimensions are not defined')
    }

    return sceneDimensions
  }

  /**
   * Return the chart width scale according to the scene dimension and the
   * chart scale.
   */
  @computed
  get chartScaleWidth(): number {
    return this.sceneDimensions.width * this.chartScaleSize
  }

  /**
   * Return the chart height scale according to the scene dimension and the
   * chart scale.
   */
  @computed
  get chartScaleHeight(): number {
    return this.sceneDimensions.height * this.chartScaleSize
  }

  /**
   * Return the chart SVG DOM node.
   */
  @computed
  get chartSvgRef(): Maybe<SVGSVGElement> {
    return this.$chartSvgRef
  }

  /**
   * Return the SVG node bounding rectangles.
   */
  @computed
  get chartSvgDOMBounds(): Maybe<DOMRect> {
    if (!this.$chartSvgRef) {
      return null
    }

    return this.$chartSvgRef.getClientRects()[0] || null
  }

  /**
   * Return infrastructures scene objects.
   */
  @computed
  get infrastructuresSceneObjects(): Maybe<
    Array<SceneObject<EntityTopologyInfrastructure>>
  > {
    return this.$infrastructuresSceneObjects
  }

  /**
   * Return the handleZoom behavior.
   */
  @computed
  get handleZoom(): Maybe<ZoomBehavior<SVGSVGElement, any>> {
    return this.$handleZoom
  }

  /**
   * Return the zoom and position of the chart.
   */
  @computed
  get chartZoomData(): IChartZoomData {
    return this.$chartZoomData.get() || { x: 0, y: 0, k: 0 }
  }

  /**
   * Return the zoom min value of the chart.
   */
  @computed
  get chartZoomMin(): Maybe<number> {
    return this.$chartZoomMin.get()
  }

  /**
   * Return the zoom slider value.
   */
  @computed
  get zoomSliderValue(): Maybe<number> {
    return this.$zoomSliderValue.get()
  }

  /**
   * Return the topology entity.
   */
  @computed
  get topologyEntity(): EntityTopology {
    const topologyEntity = this.$topologyEntity.get()

    if (!topologyEntity) {
      throw new Error('Scene topology entity is not defined')
    }

    return topologyEntity
  }

  @computed
  get isShowingInternalTrusts(): boolean {
    return this.$isShowingInternalTrusts.get()
  }

  /**
   * Return true if the scene is ready to be rendered.
   */
  @computed
  get sceneIsReady(): boolean {
    const topologyEntity = this.$topologyEntity.get()
    const sceneDimensions = this.$sceneDimensions.get()

    if (!topologyEntity || !sceneDimensions) {
      this.storeRoot.logger.debug(
        'The scene as ready, missing some initialization data'
      )
      return false
    }

    return this.$sceneIsReady.get()
  }

  /**
   * Return the tooltip scene object.
   */
  @computed
  get tooltip(): Maybe<SceneObject<ITooltip>> {
    return this.$tooltip.get()
  }

  /**
   * Return the current hovered infrastructure uid.
   */
  @computed
  get infrastructureUid(): Maybe<string> {
    return this.$infrastructureUid.get()
  }

  /**
   * Return infrastructures that matches the current search.
   */
  @computed
  get searchedInfrastructureUids(): string[] {
    const topologyEntity = this.$topologyEntity.get()

    if (!topologyEntity) {
      return []
    }

    const { searchValue, transformedSearchValueAsRegexp } =
      this.storeInputSearch

    return topologyEntity
      .getInfrastructures()
      .filter(infrastructure => {
        return (
          searchValue.length >= 3 &&
          transformedSearchValueAsRegexp.test(
            infrastructure.getPropertyAsString('name')
          )
        )
      })
      .map(infrastructure => infrastructure.getPropertyAsString('uid'))
  }

  /**
   * Return Directories that matches the current search.
   */
  @computed
  get searchedDirectoriesUids(): string[] {
    const topologyEntity = this.$topologyEntity.get()

    if (!topologyEntity) {
      return []
    }

    const { searchValue, transformedSearchValueAsRegexp } =
      this.storeInputSearch

    const allDirectories = topologyEntity.getAllDirectories()

    return flatMap(Array.from(allDirectories.values()))
      .filter(directory => {
        return (
          searchValue.length >= 3 &&
          transformedSearchValueAsRegexp.test(
            directory.getPropertyAsString('name')
          )
        )
      })
      .map(directory => directory.getPropertyAsString('uid'))
  }

  /**
   * Return Directories uids that are connected to active trusts from search in order to highlight them.
   */
  @computed
  get searchedActiveDirectoriesUids(): string[] {
    const topologyEntity = this.$topologyEntity.get()

    if (!topologyEntity) {
      return []
    }

    // get trusts
    const trusts = topologyEntity.getTrusts()
    const searchedInfrastructureUids = this.searchedInfrastructureUids
    const allDirectories = topologyEntity.getAllDirectories()
    const allDirectoriesFlat = flatMap(Array.from(allDirectories.values()))

    // if trust from(directory uid) or to(directory uid) uidInfra is in searchedInfrastructureUids
    // then this directory must be active
    const activeDirectoryUids = trusts
      .reduce<string[]>((acc, trust) => {
        const fromDirectory = allDirectoriesFlat.find(
          directory => directory.uid === trust.from
        )

        const toDirectory = allDirectoriesFlat.find(
          directory => directory.uid === trust.to
        )

        if (!fromDirectory) {
          return acc
        }

        if (!toDirectory) {
          return acc
        }

        const isFromDirectoryInSearchInfra =
          fromDirectory.uidInfrastructure &&
          searchedInfrastructureUids.includes(fromDirectory.uidInfrastructure)

        const isToDirectoryInSearchInfra =
          toDirectory.uidInfrastructure &&
          searchedInfrastructureUids.includes(toDirectory.uidInfrastructure)

        const isFound =
          isFromDirectoryInSearchInfra || isToDirectoryInSearchInfra

        if (!isFound) {
          return acc
        }

        if (fromDirectory.uid && !acc.includes(fromDirectory.uid)) {
          acc.push(fromDirectory.uid)
        }
        if (toDirectory.uid && !acc.includes(toDirectory.uid)) {
          acc.push(toDirectory.uid)
        }

        return acc
      }, [])
      .filter(isDefined)

    return activeDirectoryUids
  }

  /**
   * Return tooltip current object uid.
   */
  @computed
  get tooltipUuid(): Maybe<string> {
    const tooltip = this.$tooltip.get()
    if (!tooltip) {
      return null
    }
    return tooltip.object.uid
  }

  /**
   * Take a param trust uid and return true if it matches current tooltip object uid.
   * That way, only the trust concerned is updated when hovering a trust.
   */
  isSameUuidAsTooltip(uuid: string): boolean {
    return computed(() => uuid === this.tooltipUuid).get()
  }
}
