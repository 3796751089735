import {
  ContainerContent,
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { BookmarkEditFormFieldName } from '@app/stores/TrailFlow/StoreSearchBookmarks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleEditBookmarkValidate } from './handlers'

interface IEditBookmarkFormProps {}

const EditBookmarkForm: React.FC<IEditBookmarkFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'TrailFlow.SearchBookmarks']
  })

  const { storeTrailFlow } = useStores()
  const { storeSearchBookmarks } = storeTrailFlow
  const { storeFormEditBookmark } = storeSearchBookmarks

  return (
    <FormWrapper
      name="bookmarkEdition"
      onSubmit={handleEditBookmarkValidate(storeSearchBookmarks)}
    >
      <ContainerContent>
        <ContainerFlex
          name="DrawerEditBookmarkField"
          direction="column"
          items={[
            <WidgetForm<BookmarkEditFormFieldName>
              variant={ContainerFormVariant.drawer}
              translate={translate}
              storeForm={storeFormEditBookmark}
              hiddenFields={[BookmarkEditFormFieldName.existingName]}
            />
          ]}
          spaced
        />

        <Portal name={PlaceHolderName.drawerFooter}>
          <ContainerFooter>
            <DrawerCancelButton />

            <FormWrapperButtonSubmit
              loading={storeSearchBookmarks.storeFlagsEditBookmark.isLoading}
            >
              {translate('Edit')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </ContainerContent>
    </FormWrapper>
  )
}

export default observer(EditBookmarkForm)
