import type { Maybe } from '@@types/helpers'
import { ContainerFooter } from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import type EntityAttackVector from '@app/entities/EntityAttackVector'
import { useAppTranslator } from '@app/hooks'
import * as React from 'react'
import { AttacksPageContext } from '../../context'
import { onAttackShowAttackDetailsClick } from './handlers'

interface IIoAAttacksTableBodyShowAttackDetailsDecorationProps {
  popoverMaxLength: number
  attackVector: Maybe<EntityAttackVector>
  attackVectorDescription: Maybe<string>
}

const IoAAttacksTableBodyShowAttackDetailsDecoration: React.FC<
  IIoAAttacksTableBodyShowAttackDetailsDecorationProps
> = props => {
  const attacksPageContext = React.useContext(AttacksPageContext)

  const translate = useAppTranslator({ namespaces: ['IoA.Attacks'] })

  if (!attacksPageContext || !props.attackVector) {
    return null
  }

  const attackVectorDescription = props.attackVectorDescription

  if (!attackVectorDescription) {
    return null
  }

  // no need to display the button + open the drawer if the description has less
  // chars that the limit
  if (attackVectorDescription.length <= props.popoverMaxLength) {
    return null
  }

  return (
    <ContainerFooter variant="popover">
      <FormWrapperButton
        labelledBy="viewFullDescription"
        buttonProps={{
          type: 'primary',
          onClick: onAttackShowAttackDetailsClick(
            attacksPageContext.storeAttacks
          )(attackVectorDescription)
        }}
      >
        {translate('View full description')}
      </FormWrapperButton>
    </ContainerFooter>
  )
}

export default IoAAttacksTableBodyShowAttackDetailsDecoration
