import * as React from 'react'
import LabelMissingData from './LabelMissingData'

export interface ILabelValueProps {
  fallbackValue?: React.ReactNode
  children?: React.ReactNode
}

const LabelValue: React.FC<ILabelValueProps> = props => {
  const getFallbackValue = () => {
    if (props.fallbackValue) {
      return props.fallbackValue
    }

    return <LabelMissingData />
  }

  return <div>{props.children || getFallbackValue()}</div>
}

export default LabelValue
