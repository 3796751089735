import { ContainerHTMLContent } from '@app/components/Container'
import { LabelColorTag } from '@app/components/Labels'
import { useAppTranslator, useStores } from '@app/hooks'
import { BackgroundColorV2, FontColorV2 } from '@app/styles/consts'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IConfigurationAzureADSupportStatusProps {}

const ConfigurationAzureADSupportStatus: React.FC<
  IConfigurationAzureADSupportStatusProps
> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Management.System.Configuration.AzureADSupport',
      'Management.Accounts.Roles.Permissions'
    ]
  })

  const { storeManagementAzureAD } = useStores()

  if (!storeManagementAzureAD.showADSupportStatus) {
    return null
  }

  function renderTag() {
    if (storeManagementAzureAD.isTenableCloudApiTokensWorking) {
      return (
        <LabelColorTag
          color={BackgroundColorV2.greenLight}
          labelProperties={{
            color: FontColorV2.green,
            labelledBy: 'tenable-cloud-working'
          }}
        >
          {translate('Working')}
        </LabelColorTag>
      )
    }

    return (
      <LabelColorTag
        color={BackgroundColorV2.redLight}
        labelProperties={{
          color: FontColorV2.red,
          labelledBy: 'tenable-cloud-not-working'
        }}
      >
        <ContainerHTMLContent>
          {translate(storeManagementAzureAD.tenableCloudApiTokensStatus, {
            interpolations: {
              tenableCloudApiTokenEntity: translate(
                'tenableCloudApiToken entity'
              )
            },
            transformMarkdown: true
          })}
        </ContainerHTMLContent>
      </LabelColorTag>
    )
  }

  // surround by a div to "disabled" the flex grow property
  return <div>{renderTag()}</div>
}

export default observer(ConfigurationAzureADSupportStatus)
