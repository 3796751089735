import { FormWrapperCheckbox } from '@app/components-legacy/Form'
import { TableCell, TableRow } from '@app/components-legacy/Table'
import type { ITableRowProps } from '@app/components-legacy/Table/TableRow'
import { ITableRowVariantFlags } from '@app/components-legacy/Table/types'
import type { IEntityListable } from '@app/entities/types'
import type { IDataRowGeneric } from '@app/stores/helpers/StoreWidgetList/types'
import { filterColumn } from '@app/stores/helpers/StoreWidgetList/utils'
import { isDefined } from '@libs/isDefined'
import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { getDataSet } from './functions'
import { onRowSelection, onToggleableRowClick } from './handlers'
import type { IWidgetListTableCommonProps } from './types'
import WidgetTableTbodyCell from './WidgetTableTbodyCell'
import WidgetTableTBodyRowToggleableContent from './WidgetTableTBodyRowToggleableContent'

interface IWidgetTableTBodyProps<
  E extends IEntityListable<IDataRowGeneric>,
  D extends IDataRowGeneric
> extends IWidgetListTableCommonProps<E, D> {}

/**
 * Render the rows of the TBody.
 */
export function WidgetTableTBody<E extends IEntityListable<IDataRowGeneric>, D>(
  props: IWidgetTableTBodyProps<E, D>
): React.ReactElement {
  const dataSet = getDataSet(props.storeWidgetList)

  const columnKeys = dataSet.columns
    .map(c => c.key)
    .filter(rowKey => filterColumn<D>(rowKey, props.hiddenColumnsKeys))

  const getCells =
    (isToggleable: boolean) =>
    (id: string, row: D): React.ReactNodeArray => {
      const cells = []

      if (props.storeWidgetList && props.storeWidgetList.options.selectable) {
        const SelectionCell = props.storeWidgetList.isEmpty ? (
          <TableCell key="checkbox" />
        ) : (
          <TableCell key="checkbox">
            <FormWrapperCheckbox
              labelledBy={`id-${id}`}
              ariaRoles={['table', 'row', 'checkbox']}
              checkboxProps={{
                checked: props.storeWidgetList.isSelected(row),
                onChange: onRowSelection(props.storeWidgetList)(row)
              }}
            />
          </TableCell>
        )

        cells.push(SelectionCell)
      }

      // set the onClick binding on each cell, it's not super-optimized but in order
      // to avoid to toggle the row when clicking on the selector checkbox, I don't
      // see how to do differently
      const cellOnClick = filterEmptyProps({
        onClick: isToggleable
          ? onToggleableRowClick(props.storeWidgetList)(row)
          : undefined
      })

      cells.push(
        columnKeys.map((attribute, j) => {
          const key = `row-${String(attribute)}-${j}`
          const value = row[attribute]

          return (
            <TableCell key={key} {...cellOnClick}>
              <WidgetTableTbodyCell<E, D>
                labelledBy={`${String(attribute)}`}
                rowKey={attribute}
                rowValue={value}
                row={row}
                {...props}
              />
            </TableCell>
          )
        })
      )

      if (props.actionsButtonsRenderFn) {
        cells.push(
          <TableCell key="actionButton">
            {props.actionsButtonsRenderFn(id, row)}
          </TableCell>
        )
      }

      return cells
    }

  const getColspanValue = (): number => {
    let colspan = columnKeys.length

    if (props.storeWidgetList.options.selectable) {
      colspan++
    }

    if (isDefined(props.actionsButtonsRenderFn)) {
      colspan++
    }

    return colspan
  }

  const getRowVariant = (
    isToggleable: boolean,
    isExpanded: boolean
  ): ITableRowVariantFlags => {
    if (isExpanded) {
      return ITableRowVariantFlags.Expanded
    }

    if (isToggleable) {
      return ITableRowVariantFlags.Clickable
    }

    return ITableRowVariantFlags.Normal
  }

  return (
    <>
      {dataSet.rows.map((row, i) => {
        const id = props.storeWidgetList.getId(row)
        const isOdd = i % 2 === 0

        const isToggleable = isDefined(props.toggleableRowRenderFn)
        const isExpanded =
          isToggleable && props.storeWidgetList.isToggleableRowOpened(row)

        const rowProps: Omit<ITableRowProps, 'children'> = {
          id,
          isOdd,
          hasActionIcons: isDefined(props.actionsButtonsRenderFn),
          variant: getRowVariant(isToggleable, isExpanded)
        }

        return (
          <React.Fragment key={`row-${id}`}>
            <TableRow key={id} {...rowProps}>
              {getCells(isToggleable)(id, row)}
            </TableRow>

            {isExpanded && (
              <TableRow
                variant={ITableRowVariantFlags.Toggleable}
                isOdd={rowProps.isOdd}
              >
                <TableCell colSpan={getColspanValue()}>
                  <WidgetTableTBodyRowToggleableContent<E, D>
                    id={id}
                    row={row}
                    {...props}
                  />
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}

export default observer(WidgetTableTBody)
