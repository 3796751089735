import { Features } from '@alsid/common'
import { ContainerFlex, ContainerHTMLContent } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { HorizontalLine } from '@app/components/Separator'
import { useAppTranslator } from '@app/hooks'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import {
  BackgroundColorV2,
  BrandColorV2,
  FontColorV2
} from '@app/styles/consts'
import type { DataCollectionNotWorkingReason } from '@server/graphql/typeDefs/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import ConfigurationDataCollectionErrorMessage from './ConfigurationDataCollectionErrorMessage'
import ConfigurationDataCollectionSensitiveData from './ConfigurationDataCollectionSensitiveData'
import ConfigurationDataCollectionSuccessLabel from './ConfigurationDataCollectionSuccessLabel'

interface IConfigurationDataCollectionCardProps {
  className?: string
}

const StyledHorizontalLine = styled(HorizontalLine)`
  margin: ${consts.marginVerySmall} 0;
`

const ConfigurationDataCollectionCard: React.FC<
  IConfigurationDataCollectionCardProps
> = props => {
  const featureTenableCloudSensitiveData = useFeatureFlag({
    featureFlagName: Features.TENABLE_CLOUD_SENSITIVE_DATA
  })

  const { storeManagementDataCollection } = useStores()

  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'Management.System.Configuration.Navigation',
      'Management.System.Configuration.DataCollection'
    ]
  })

  if (!storeManagementDataCollection.dataCollectionStatusEntity) {
    return null
  }

  const isEnabled =
    storeManagementDataCollection.dataCollectionStatusEntity?.getPropertyAsBoolean(
      'isEnabled'
    )

  const notWorkingBecauseOf =
    storeManagementDataCollection.dataCollectionStatusEntity.getPropertyAsArrayOf<DataCollectionNotWorkingReason>(
      'notWorkingBecauseOf'
    )

  const isWorking = notWorkingBecauseOf.length === 0

  return (
    <ContainerFlex
      name="formGroup"
      className={props.className}
      direction="column"
      items={[
        <Label>
          <ContainerHTMLContent padded>
            {translate(
              `Tenable.ad information Tenable’s private cloud transfer`,
              {
                transformMarkdown: true
              }
            )}
          </ContainerHTMLContent>

          <ContainerHTMLContent padded>
            {translate(`Trust and Assurance statement reference`, {
              transformMarkdown: true
            })}
          </ContainerHTMLContent>
        </Label>,

        featureTenableCloudSensitiveData && (
          <ConfigurationDataCollectionSensitiveData />
        ),

        isEnabled && !isWorking && (
          <StyledHorizontalLine color={BrandColorV2.lightGray} />
        ),

        isEnabled && (
          <ConfigurationDataCollectionSuccessLabel isWorking={isWorking} />
        ),

        ...((isEnabled &&
          !isWorking &&
          notWorkingBecauseOf.map(reason => (
            <ConfigurationDataCollectionErrorMessage reason={reason} />
          ))) ||
          [])
      ]}
      spaced
      spaceWidth="smaller"
      wrapItems
    />
  )
}

const ObservedConfigurationDataCollectionCard = observer(
  ConfigurationDataCollectionCard
)

export default styled(ObservedConfigurationDataCollectionCard)`
  border-radius: ${consts.borderRadiusLargeRedesign};
  padding: ${consts.paddingLarge};
  background-color: ${BackgroundColorV2.lightGray};

  a {
    color: ${FontColorV2.link};
    font-weight: ${consts.fontWeightBold};
    text-decoration: underline;
  }
`
