import { AppRouteName } from '@app/routes'
import type { StoreManagementRelays } from '@app/stores'
import { RelaysFormFieldName } from '@app/stores/Management/StoreRelays'
import type { AppRouterClient } from '@libs/Router/types'
import type { InputEditRelay } from '@server/graphql/typeDefs/types'

/**
 * Initialize the form according to the selected relay.
 */
export const handleRelaysEditPageOnLoad =
  (storeManagementRelays: StoreManagementRelays) => (relayId: number) => () => {
    Promise.resolve()
      .then(() => {
        return !storeManagementRelays.relays.size
          ? storeManagementRelays.fetchRelays()
          : null
      })
      .then(() => {
        const entity = storeManagementRelays.relays.get(relayId)
        if (!entity) {
          return
        }
        storeManagementRelays.storeFormEdition
          .setDefaultFieldsValuesFromObject({
            [RelaysFormFieldName.name]: entity.getPropertyAsString('name')
          })
          .reset()
      })
  }

export const handleRelaysEditOnSubmit =
  (appRouter: AppRouterClient, storeManagementRelays: StoreManagementRelays) =>
  (relayId: number) =>
  (e: React.FormEvent<any>) => {
    const { storeFormEdition } = storeManagementRelays

    e.preventDefault()

    if (!storeFormEdition.validate()) {
      return
    }

    const relay: InputEditRelay = {
      id: relayId,
      name: storeFormEdition.getFieldValueAsString('name')
    }

    storeManagementRelays
      .editRelay(relay)
      .then(() => {
        const url = appRouter.makeRouteInfosPathname({
          routeName: AppRouteName.Management_System_Relays,
          parameters: {
            relayId
          }
        })

        appRouter.history.push(url)
      })
      .catch(() => {
        // already catched in the store
      })
  }
