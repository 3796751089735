import { labelPCss } from '@app/components/Labels/Label/labelsCss'
import { BulletCircle, BulletTooltip } from '@app/components-legacy/Bullet'
import { BulletCircleSize } from '@app/components-legacy/Bullet/BulletCircle'
import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import { InputSearch } from '@app/components-legacy/Input'
import { LabelSliced } from '@app/components-legacy/Label'
import { WidgetListTable } from '@app/components-legacy/Widgets/WidgetList'
import type EntityAdObjectAsAttribute from '@app/entities/EntityAdObject/EntityAdObjectAsAttribute'
import type { IDataRowAdObjectAsAttribute } from '@app/entities/EntityAdObject/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { Popover } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import EventDetailsAttributesPopover from './EventDetailsAttributesPopover'
import EventDetailsAttributesTableChanges from './EventDetailsAttributesTableChanges'

const StyledLabelSliced = styled(LabelSliced)`
  ${labelPCss}
`

interface IEventDetailsAttributesTableProps {
  style?: React.CSSProperties
  className?: string
}

const EventDetailsAttributesTable: React.FC<
  IEventDetailsAttributesTableProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['TrailFlow.EventDetails.Attributes']
  })

  const {
    storeTrailFlow: { storeEventDetails }
  } = useStores()

  const { storeEventDetailsAttributes } = storeEventDetails

  function renderContent() {
    const event = storeEventDetails.event

    if (!event) {
      return null
    }

    const adObject = event.getAdObject()

    if (!event || !adObject) {
      return null
    }

    return (
      <ContainerFlex
        name="Attributes"
        direction="column"
        items={[
          <InputSearch
            placeholder={translate('Search for an attribute')}
            storeInputSearch={storeEventDetailsAttributes.storeInputSearch}
          />,

          <WidgetListTable<
            EntityAdObjectAsAttribute,
            IDataRowAdObjectAsAttribute
          >
            translate={translate}
            storeWidgetList={
              storeEventDetailsAttributes.storeWidgetListAttributes
            }
            hiddenColumnsKeys={['id', 'isChange']}
            headsRenderFn={{
              attributeDiffsBetweenBeforeAndAtEvent: () => ''
            }}
            columnWidths={{
              attributeDiffsBetweenBeforeAndAtEvent: '20px'
            }}
            cellsRenderFn={{
              attributeDiffsBetweenBeforeAndAtEvent: value => {
                if (!value) {
                  return null
                }

                return (
                  <Popover
                    placement="left"
                    content={
                      <EventDetailsAttributesTableChanges
                        diffs={value.diffs}
                        attributeBeforeValue={value.attributeBeforeValue.value}
                      />
                    }
                  >
                    <div className="diffIcon">
                      {/* Use an empty tooltip to increase the surface of the hovered area */}
                      <BulletTooltip tooltip="">
                        <BulletCircle
                          size={BulletCircleSize.verySmall}
                          color={consts.colorBlue005}
                        />
                      </BulletTooltip>
                    </div>
                  </Popover>
                )
              },

              attributeName: value => (
                <StyledLabelSliced
                  popoverType="popover"
                  value={value}
                  maxLength={20}
                  labelledBy={value}
                />
              ),

              attributeValueAtEvent: attribute => {
                return <EventDetailsAttributesPopover attribute={attribute} />
              },

              attributeCurrentValue: attribute => {
                return <EventDetailsAttributesPopover attribute={attribute} />
              }
            }}
          />
        ]}
        spaced
      />
    )
  }

  return (
    <ContainerContent
      style={props.style}
      className={props.className}
      title={translate('Attributes')}
    >
      {renderContent()}
    </ContainerContent>
  )
}

export default styled(EventDetailsAttributesTable)`
  .diffIcon {
    padding-right: ${consts.paddingSmall};
  }
`
