import ContainerTitle from '@app/components-legacy/Container/ContainerTitle'
import { consts } from '@app/styles'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled from 'styled-components'
import type { BladeLayout } from '../types'

interface IBladeContentTitleProps {
  className?: string
  isLoading: boolean
  layout: BladeLayout
}

const BladeContentTitle: React.FC<IBladeContentTitleProps> = props => {
  if (!props.layout.title || props.isLoading) {
    return null
  }

  return (
    <div data-name="BladeContentTitle" className={props.className}>
      <ContainerTitle>{props.layout.title}</ContainerTitle>
    </div>
  )
}

export default styled(BladeContentTitle)`
  padding: ${props =>
      isThemeLegacy(props.theme) ? consts.paddingDefault : consts.paddingMedium}
    ${consts.paddingLarge};
`
