import { ContainerFooter } from '@app/components-legacy/Container'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import { FormWrapperButton } from '@app/components-legacy/Form'
import { ButtonSize } from '@app/components-legacy/Form/Wrappers/types'
import { useAppTranslator, useStores } from '@app/hooks'
import { HistoryMenuKey } from '@app/stores/TrailFlow/StoreSearchHistory'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  onTrailFlowBookmarkSeeMoreClick,
  onTrailFlowHistorySeeMoreClick
} from './handler'

interface IHistoryDrawerFooterProps {}

const HistoryDrawerFooter: React.FC<IHistoryDrawerFooterProps> = props => {
  const translate = useAppTranslator({
    namespaces: [
      'Buttons',
      'TrailFlow.SearchHistory',
      'TrailFlow.SearchBookmarks'
    ]
  })

  const { storeTrailFlow } = useStores()

  return (
    <ContainerFooter>
      <DrawerCancelButton />

      <FormWrapperButton
        labelledBy={
          storeTrailFlow.storeMenuHistory.selectedMenuKey ===
          HistoryMenuKey.History
            ? 'seeMore'
            : 'manageBookmarks'
        }
        size={ButtonSize.large}
        buttonProps={{
          type: 'primary',
          onClick:
            storeTrailFlow.storeMenuHistory.selectedMenuKey ===
            HistoryMenuKey.History
              ? onTrailFlowHistorySeeMoreClick(storeTrailFlow)
              : onTrailFlowBookmarkSeeMoreClick(storeTrailFlow)
        }}
      >
        {storeTrailFlow.storeMenuHistory.selectedMenuKey ===
        HistoryMenuKey.History
          ? translate('Manage your history')
          : translate('Manage your bookmarks')}
      </FormWrapperButton>
    </ContainerFooter>
  )
}

export default observer(HistoryDrawerFooter)
