import { ensureArray } from '@libs/ensureArray'
import { StatusCodeDefinition } from '@libs/FetchClient/statusCodes'
import type { RequestError } from '@server/graphql/typeDefs/types'
import type { ServerContext } from '@server/ServerContext'
import type { GraphQLError } from 'graphql'
import GQLClientWrapper from './GQLClientWrapper'
import type { IGraphQLServerErrorMessage } from './GQLRequestor/types'
import type { IGraphQLClientOption } from './types'

/**
 * Create a instance of GraphQLClient for a Tenable.ad instance.
 */
export function createGraphQLClient(options: IGraphQLClientOption) {
  const graphQLEndpoint = `/w/graphql/${options.instanceName}/graphqlapi`
  const url = options.baseUrl + graphQLEndpoint

  return new GQLClientWrapper(url, {
    credentials: 'same-origin',
    ...options.clientRequestOptions,
    headers: options.clientRequestOptions?.headers
  })
}

/**
 * Parse the stringified message of GraphQLError exception.
 */
export function parseGraphQLErrorMessage(
  graphQLError: GraphQLError,
  serverContext: ServerContext
): IGraphQLServerErrorMessage {
  const queryPath = ensureArray(graphQLError.path?.slice(0)).join('.')

  try {
    // try to parse an error from a IGraphQLServerErrorMessage exception
    const errorMessage: IGraphQLServerErrorMessage = JSON.parse(
      graphQLError.message
    )

    return { ...errorMessage, queryPath }
  } catch (err) {
    const isSuggestionError = graphQLError.message.includes('Did you mean')

    if (isSuggestionError && serverContext.serverConfig.production === true) {
      const graphQLServerErrorMessage: IGraphQLServerErrorMessage = {
        message: 'Invalid request',
        statusCodeDefinition: StatusCodeDefinition.Server_Error
      }

      return graphQLServerErrorMessage
    }

    const graphQLServerErrorMessage: IGraphQLServerErrorMessage = {
      message: graphQLError.message,
      statusCodeDefinition: StatusCodeDefinition.Server_Error
    }

    return graphQLServerErrorMessage
  }
}

/**
 * Format a string for a contextualized (API error) GraphQL error.
 */
export function formatGraphQLServerErrorLogMessage(
  graphQLServerErrorMessage: IGraphQLServerErrorMessage
): string {
  const logMessage = JSON.stringify(graphQLServerErrorMessage)
  return `An error has occurred during the GraphQL query: ${logMessage}`
}

/**
 * Typeguard to validate than err is of type RequestError.
 */
export function isGraphQLRequestError(err: any): err is RequestError {
  return typeof err === 'object' && 'error' in err && 'message' in err
}

/**
 * Capture the name of the query/mutation.
 */
export function extractGraphQLQueryName(query: string): string {
  const [, queryName] =
    // query or mutation followed by everything except space, ( or {
    ensureArray(query.match(/(?:query|mutation) ([^\s|({]+)/)) || ''

  return queryName
}

/**
 * Typeguard to validate than err is of type IGraphQLServerErrorMessage
 */
export function isGraphQLServerError(
  err: any
): err is IGraphQLServerErrorMessage {
  return typeof err === 'object' && 'statusCodeDefinition' in err
}
