import { LabelColorTag } from '@app/components/Labels'
import { LabelSliced } from '@app/components-legacy/Label'
import { FontColorV2 } from '@app/styles/consts'
import type { Maybe } from '@server/graphql/typeDefs/types'
import React from 'react'
import { colorActivityLogsLogAlternateBackgroundRedesign } from '../consts'

export const UserLogIpAddress = (props: { ipAddress: Maybe<string> }) => {
  return (
    <LabelColorTag
      className="traceIpAddress"
      color={colorActivityLogsLogAlternateBackgroundRedesign}
      labelProperties={{ color: FontColorV2.primary, labelledBy: 'ip' }}
      style={{ width: '110px' }}
    >
      <LabelSliced value={props.ipAddress ?? ''} maxWidth={110} />
    </LabelColorTag>
  )
}
