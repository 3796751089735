import { ContainerFlex } from '@app/components-legacy/Container'
import { FormWrapperCheckbox } from '@app/components-legacy/Form'
import { InputSearch } from '@app/components-legacy/Input'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type { StoreInputHealthChecks } from '@app/stores'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  handleExpandAllHealthCheckTypesOnClick,
  handleHealthChecksSearch,
  handleHealthChecksSelectAllOnClick
} from '../handlers'

interface IDrawerHeaderProps {
  className?: string
  withExpand?: boolean
  storeInputHealthChecks: StoreInputHealthChecks
}

const DrawerHeader: React.FC<IDrawerHeaderProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Components.InputHealthChecks']
  })

  return (
    <ContainerFlex
      name="DrawerHeader"
      direction="column"
      items={[
        <InputSearch
          placeholder={translate('Search a health check')}
          onChange={handleHealthChecksSearch(props.storeInputHealthChecks)}
          storeInputSearch={props.storeInputHealthChecks.storeInputSearch}
        />,

        <ContainerFlex
          name="HealthCheckNameFilters"
          className={props.className}
          items={[
            props.withExpand && (
              <FormWrapperCheckbox
                labelledBy="expandAll"
                checkboxProps={{
                  name: 'LabelExpandChecker',
                  onChange: handleExpandAllHealthCheckTypesOnClick(
                    props.storeInputHealthChecks
                  ),
                  indeterminate:
                    props.storeInputHealthChecks.isSomeHealthCheckTypesExpanded,
                  checked:
                    props.storeInputHealthChecks.isAllHealthCheckTypesExpanded
                }}
              >
                {translate('Expand all')}
              </FormWrapperCheckbox>
            ),
            <FormWrapperCheckbox
              labelledBy="selectHealthCheckNames"
              checkboxProps={{
                name: 'LabelHealthCheckNamesChecker',
                onChange: handleHealthChecksSelectAllOnClick(
                  props.storeInputHealthChecks,
                  props.withExpand
                ),
                indeterminate:
                  props.storeInputHealthChecks
                    .isSomeHealthChecksTemplatesNamesSelected,
                checked: props.withExpand
                  ? props.storeInputHealthChecks
                      .isAllHealthChecksTemplatesNamesSelectedWithExpand
                  : props.storeInputHealthChecks
                      .isAllHealthChecksTemplatesNamesSelected
              }}
            >
              {props.storeInputHealthChecks.storeInputSearch.hasSearchValue
                ? translate('Select found health checks')
                : translate('Select all health checks')}
            </FormWrapperCheckbox>
          ]}
          spaced
        />
      ]}
      spaced
    />
  )
}

export default observer(DrawerHeader)
