import { ContainerFlex } from '@app/components/Container'
import {
  ContainerContent,
  ContainerFooter
} from '@app/components-legacy/Container'
import { ContainerFormVariant } from '@app/components-legacy/Container/ContainerForm/types'
import { DrawerCancelButton } from '@app/components-legacy/Drawer'
import { FormWrapperSelect } from '@app/components-legacy/Form'
import {
  FormWrapper,
  FormWrapperButtonSubmit
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { TypographyFieldsetTitle } from '@app/components-legacy/Typography'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { UserFormFieldName } from '@app/stores/Dashboard/types'
import { mergeFlags } from '@app/stores/helpers/StoreFlags/helpers'
import { onSelectChange } from '@app/stores/helpers/StoreForm/handlers'
import { InputType } from '@app/stores/helpers/StoreForm/types'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import AdComplianceAndTopRisks from './AdComplianceAndTopRisks'
import AdRisk360 from './AdRisk360'
import CustomDashboard from './CustomDashboard'
import {
  handleAddDashboardLoad,
  handleAddDashboardsSubmit,
  onAddDashboardValidate
} from './handlers'
import NativeAdminMonitoring from './NativeAdminMonitoring'
import PasswordManagementRisk from './PasswordManagementRisk'
import UserMonitoring from './UserMonitoring'

export interface IDrawerAddDashboardFormProps {}

const StyledContainerFlex = styled(ContainerFlex)`
  width: 50%;
`

const DrawerAddDashboardForm: React.FC<
  IDrawerAddDashboardFormProps
> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Dashboard.AddDashboardDrawer', 'Dashboard.Common']
  })

  const appRouter = useAppRouter()

  const { storeRoot, storeDashboards } = useStores()

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const { storeDashboardsManagement } = storeDashboards
  const { storeFormAddDashboard, storeFlagsAddDashboard } =
    storeDashboardsManagement

  React.useEffect(() => {
    storeFormAddDashboard
      .setDefaultFieldsValues([
        {
          key: 'name',
          value: ''
        },
        {
          key: 'instanceName',
          value: storeDashboardsManagement.options.instanceName
        }
      ])
      .reset()
  }, [])

  const instances = storeRoot.environment.config.instances
  const hasMultiInstances = instances.length > 1

  // merge flags for addition + fetch dashboards
  const flags = mergeFlags(
    storeFlagsAddDashboard.flags,
    storeDashboards.storeFlagsAllDashboards.flags
  )

  const fieldsItems = [
    <WidgetForm<UserFormFieldName>
      variant={ContainerFormVariant.drawer}
      translate={translate}
      storeForm={storeFormAddDashboard}
      displayedFields={[UserFormFieldName.name]}
      fieldsParams={
        !themeIsLegacy
          ? {
              [UserFormFieldName.name]: {
                inputType: InputType.input,
                inputProps: {
                  placeholder: translate('Dashboard Name')
                }
              }
            }
          : undefined
      }
    />
  ]

  const getInstanceNameController = () => {
    return (
      <FormWrapperSelect
        labelledBy="addDashboard"
        selectProps={{
          value: storeFormAddDashboard.getFieldValueAsString(
            UserFormFieldName.instanceName,
            storeDashboardsManagement.options.instanceName
          ),
          onChange: onSelectChange(storeFormAddDashboard)(
            UserFormFieldName.instanceName
          )
        }}
        selectOptions={instances.map(instance => {
          const instanceName = String(instance.name)
          return {
            label: translate(instance.name),
            value: instanceName
          }
        })}
      />
    )
  }

  if (hasMultiInstances) {
    fieldsItems.push(
      ...[
        <TypographyFieldsetTitle>
          {translate('Consolidation platform')}
        </TypographyFieldsetTitle>,

        <WidgetForm<UserFormFieldName>
          variant={ContainerFormVariant.drawer}
          translate={translate}
          storeForm={storeFormAddDashboard}
          displayedFields={[UserFormFieldName.instanceName]}
          renderFields={{
            [UserFormFieldName.instanceName]: getInstanceNameController
          }}
        />
      ]
    )
  }

  return (
    <FormWrapper
      name="dashboardCreation"
      onSubmit={
        themeIsLegacy
          ? onAddDashboardValidate(appRouter, storeDashboards)
          : handleAddDashboardsSubmit(appRouter, storeDashboards)
      }
    >
      <ContainerContent onLoad={handleAddDashboardLoad(storeDashboards)}>
        {themeIsLegacy ? (
          <ContainerFlex
            name="DrawerAddDashboardFields"
            direction="column"
            items={fieldsItems}
            spaced
          />
        ) : (
          <ContainerFlex
            name="DashboardTemplates"
            flexGap="small"
            items={[
              <StyledContainerFlex
                name="DashboardTemplatesLeftColumn"
                flexGap="small"
                direction="column"
                items={[
                  <AdComplianceAndTopRisks />,
                  <PasswordManagementRisk />,
                  <NativeAdminMonitoring />
                ]}
              />,
              <StyledContainerFlex
                name="DashboardTemplatesRightColumn"
                flexGap="smaller"
                direction="column"
                items={[
                  <AdRisk360 />,
                  <UserMonitoring />,
                  <CustomDashboard>
                    <ContainerFlex
                      name="AddCustomDashboardField"
                      direction="column"
                      items={fieldsItems}
                      spaced
                    />
                  </CustomDashboard>
                ]}
              />
            ]}
          />
        )}

        <Portal name={PlaceHolderName.drawerFooter}>
          <ContainerFooter>
            {themeIsLegacy && (
              <DrawerCancelButton
                storeDrawer={
                  storeDashboards.storeDashboardsManagement
                    .storeDrawerAddDashboard
                }
              />
            )}

            <FormWrapperButtonSubmit loading={flags.isLoading}>
              {themeIsLegacy ? translate('OK') : translate('Add dashboards')}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </ContainerContent>
    </FormWrapper>
  )
}

export default observer(DrawerAddDashboardForm)
