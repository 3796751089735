import { ContainerSimple } from '@app/components/Container'
import { IconClose } from '@app/components/Icon'
import type { IFormWrapperControl } from '@app/components-legacy/Form/Wrappers/types'
import { consts } from '@app/styles'
import { BackgroundColorV2, FontColorV2 } from '@app/styles/consts'
import { combineEventHandlers } from '@libs/combineEventHandlers'
import { isDefined } from '@libs/isDefined'
import { assertUnreachableCase } from '@productive-codebases/toolbox'
import type { AlertProps } from 'antd'
import { Alert } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import { AlertSize, AlertVariant } from './types'

function getTextColor(variant: AlertVariant = AlertVariant.default) {
  switch (variant) {
    case AlertVariant.default:
    case AlertVariant.dark:
      return FontColorV2.white
    case AlertVariant.success:
      return FontColorV2.green
    case AlertVariant.error:
      return FontColorV2.red

    default:
      assertUnreachableCase(variant)
  }
}

function getBackgroundColor(variant: AlertVariant = AlertVariant.default) {
  switch (variant) {
    case AlertVariant.default:
      return consts.colorBlackTransparentRedesign
    case AlertVariant.success:
      return BackgroundColorV2.greenLight
    case AlertVariant.error:
      return BackgroundColorV2.redLight
    case AlertVariant.dark:
      return BackgroundColorV2.tooltips
    default:
      assertUnreachableCase(variant)
  }
}

const StyledAlert = styled(Alert)<{
  size?: AlertSize
  variant?: AlertVariant
  isIcon: boolean
  textAlign?: 'left' | 'right' | 'center'
}>`
  &.ant-alert {
    min-height: 32px;
    font-size: ${consts.textSizeGlobal};
    border-radius: 5px;
    text-align: ${props => props.textAlign ?? 'center'};
    padding: ${props =>
      props.size === AlertSize.large && props.isIcon
        ? `${consts.paddingDefault} ${consts.paddingLarge} ${consts.paddingDefault} ${consts.paddingDefault}`
        : props.size === AlertSize.large
          ? `${consts.paddingDefault} ${consts.paddingLarge}`
          : `${consts.paddingVerySmall} ${consts.paddingDefault}`};
  }

  &.ant-alert-info {
    background-color: ${props => getBackgroundColor(props.variant)};
    border: 0;
  }

  &.ant-alert.ant-alert-motion-leave {
    transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }

  &.ant-alert-motion-leave-active {
    max-height: unset;
    padding-top: ${consts.paddingDefault};
    padding-bottom: ${consts.paddingDefault};
  }

  .ant-alert-message {
    color: ${props => getTextColor(props.variant)};
    ${props => (props.closable ? 'margin-left: 24px;' : '')}
  }

  .ant-alert-icon {
    align-self: flex-start;
    margin-top: 1px;
    margin-right: ${consts.marginSmall};
  }
`

interface IWrapperAlertProps extends IFormWrapperControl {
  className?: string
  style?: React.CSSProperties
  alertProps: AlertProps
  size?: AlertSize
  variant?: AlertVariant
  textAlign?: 'left' | 'right' | 'center'
  onClick?: () => void
}

const WrapperAlert: React.FC<IWrapperAlertProps> = props => {
  const [closed, setClosed] = React.useState<boolean>(false)

  if (closed) {
    return null
  }

  return (
    <ContainerSimple
      name={`${WrapperAlert.name}-${props.labelledBy}`}
      className={props.className}
      style={props.style}
      ariaRoles={props.ariaRoles || ['alert']}
      labelledBy={props.labelledBy}
    >
      <StyledAlert
        size={props.size}
        variant={props.variant}
        isIcon={
          (props.alertProps.icon && props.alertProps.showIcon && true) || false
        }
        textAlign={props.textAlign}
        closeText={
          props.alertProps.closable ? (
            <IconClose color={consts.colorWhite} size={16} />
          ) : undefined
        }
        {...props.alertProps}
        afterClose={combineEventHandlers<void>(
          () => setClosed(true),
          props.alertProps.afterClose
        )}
        onClick={props.onClick}
      />
    </ContainerSimple>
  )
}

export default styled(WrapperAlert)`
  width: 509px;
  ${props => isDefined(props.onClick) && 'cursor: pointer;'}
`
