import type { StoreIdentityExplorer } from '@app/stores'
import type { GetIdentitiesSortByEnum } from '@libs/openapi/service-identity-core'

/**
 * Load identities.
 *
 * Load drawers infrastructures & tenants first, select all,
 * then load identities.
 */
export const handleIdentityExplorerPageOnLoad =
  (storeIdentityExplorer: StoreIdentityExplorer) => (): void => {
    Promise.all([
      // fetch drawer infrastructures
      storeIdentityExplorer.storeInfrastructures
        .fetchInfrastructures()
        .then(() => {
          storeIdentityExplorer.storeInfrastructures.selectAllDirectories()
        }),
      // fetch drawer tenants
      storeIdentityExplorer.storeInputTenants.fetchTenants().then(() => {
        storeIdentityExplorer.storeInputTenants.selectAllTenants()
      })
    ]).then(() =>
      storeIdentityExplorer.fetchIdentities({
        page: 1
      })
    )
  }

export const handleIdentityExplorerPageOnUnload =
  (storeIdentityExplorer: StoreIdentityExplorer) => (): void => {
    storeIdentityExplorer.reset()
  }

export const handleIdentityExplorerPaginationChange =
  (storeIdentityExplorer: StoreIdentityExplorer) => (page: number) => {
    storeIdentityExplorer.fetchIdentities(
      { page },
      storeIdentityExplorer.storeFlagsReloadIdentities
    )
  }

export const handleIdentitiesFilteringOnSubmit =
  (storeIdentityExplorer: StoreIdentityExplorer) => (): void => {
    storeIdentityExplorer.reloadIdentities({ page: 1 })
  }

export const handleIdentitiesOnSearch = handleIdentitiesFilteringOnSubmit

export const handleIdentityExplorerOnColumnSort =
  (storeIdentityExplorer: StoreIdentityExplorer) =>
  (columnId: GetIdentitiesSortByEnum) =>
  () => {
    storeIdentityExplorer.reloadIdentities({ page: 1 }, columnId)
  }
