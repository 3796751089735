import type { RGBColor } from './types'

/**
 * Delta E Perception - Compute difference between 2 colors.
 * https://github.com/antimatter15/rgb-lab
 *
 *  <= 1.0:	  Not perceptible by human eyes.
 *  1 - 2:	  Perceptible through close observation.
 *  2 - 10:	  Perceptible at a glance.
 *  11 - 49:	Colors are more similar than opposite
 *  100:    	Colors are exact opposite
 */
export function deltaE(rgbA: RGBColor, rgbB: RGBColor): number {
  const labA = rgb2lab(rgbA)
  const labB = rgb2lab(rgbB)

  const deltaL = labA[0] - labB[0]
  const deltaA = labA[1] - labB[1]
  const deltaB = labA[2] - labB[2]

  const c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2])
  const c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2])

  const deltaC = c1 - c2
  const tempDeltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC
  const deltaH = tempDeltaH < 0 ? 0 : Math.sqrt(tempDeltaH)

  const sc = 1.0 + 0.045 * c1
  const sh = 1.0 + 0.015 * c1

  const deltaLKlsl = deltaL / 1.0
  const deltaCkcsc = deltaC / sc
  const deltaHkhsh = deltaH / sh

  const i =
    deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh

  return i < 0 ? 0 : Math.sqrt(i)
}

function rgb2lab(rgb: RGBColor): number[] {
  let r = rgb[0] / 255
  let g = rgb[1] / 255
  let b = rgb[2] / 255

  r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92
  g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92
  b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92

  let x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047
  let y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.0
  let z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883

  x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116
  y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116
  z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116

  return [116 * y - 16, 500 * (x - y), 200 * (y - z)]
}
