import type { Maybe, MaybeUndef } from '@@types/helpers'
import { EntityPagination } from '@app/entities'
import { PAGINATION_PERPAGE_DEFAULT } from '@app/entities/EntityPagination'
import { listDataRow, listDataRows } from '@app/entities/helpers'
import type { IEntityListable } from '@app/entities/types'
import type { StoreRoot } from '@app/stores'
import type { IdentityColumnSorterState } from '@app/stores/Identity/types'
import { isDefined } from '@libs/isDefined'
import type { GetIdentitiesSortByEnum } from '@libs/openapi/service-identity-core'
import { GetIdentitiesOrderEnum } from '@libs/openapi/service-identity-core'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import StoreBase from '../../StoreBase'
import type { IStoreI18nOptions } from '../../types'
import type {
  IDataRowGeneric,
  IStoreWidgetListMeta,
  IWidgetListDataSet,
  WidgetListGetterId
} from './types'
import {
  buildDataSetDefaultFn,
  defaultFilterEntitiesFn,
  defaultGetIdFn,
  isListConsideredAsEmpty
} from './utils'

export interface IStoreWidgetListOptions<E, D> extends IStoreI18nOptions {
  selectable: boolean
  offline: boolean
  getIdFn: (row: D) => string
  filterEntitiesFn: (entities: E[]) => E[]
  buildDataSetFn: (entities: E[]) => IWidgetListDataSet<D>
}

const baseOptions: IStoreWidgetListOptions<any, any> = {
  selectable: true,
  offline: false,
  getIdFn: defaultGetIdFn,
  filterEntitiesFn: defaultFilterEntitiesFn,
  buildDataSetFn: buildDataSetDefaultFn
}

/**
 * Store in charge of widget lists.
 */
export default class StoreWidgetList<
  E extends IEntityListable<IDataRowGeneric>,
  D extends IDataRowGeneric
> extends StoreBase<IStoreWidgetListOptions<E, D>> {
  /**
   * Handle meta-data.
   * Useful to attach various data to the store.
   */
  meta = {
    /**
     * Override meta data.
     */
    override: (
      allMeta: Map<
        IStoreWidgetListMeta<any>['key'],
        IStoreWidgetListMeta<any>['value']
      >
    ): this => {
      this.$meta = allMeta
      return this
    },

    /**
     * Set a meta data.
     */
    set: <T>(meta: IStoreWidgetListMeta<T>): this => {
      this.$meta.set(meta.key, meta.value)
      return this
    },

    /**
     * Return a meta data.
     */
    get: <T>(key: IStoreWidgetListMeta<any>['key']): MaybeUndef<T> => {
      return this.$meta.get(key)
    },

    /**
     * Delete a meta data.
     */
    delete: (key: IStoreWidgetListMeta<any>['key']): this => {
      this.$meta.delete(key)
      return this
    },

    /**
     * Return all meta data.
     */
    getAll: (): Map<
      IStoreWidgetListMeta<any>['key'],
      IStoreWidgetListMeta<any>['value']
    > => {
      return this.$meta
    }
  }

  private $meta: Map<
    IStoreWidgetListMeta<any>['key'],
    IStoreWidgetListMeta<any>['value']
  > = new Map()

  /* Observables */

  private $entities = observable.array<E>([])
  private $selectedRows = observable.map<WidgetListGetterId, D>()
  private $toggleableOpenedRows = observable.map<WidgetListGetterId, D>()

  private $pagination = observable.box<EntityPagination>(
    EntityPagination.fromDefaultAttributes()
  )

  private $columnSorterState =
    observable.box<Maybe<IdentityColumnSorterState>>(null)

  // all pages selection (that supposes that a pagination object has been defined)
  private $allRowsSelectionStatus = observable.box(false)

  constructor(
    storeRoot: StoreRoot,
    options: Partial<IStoreWidgetListOptions<E, D>> = {}
  ) {
    super(storeRoot, {
      ...baseOptions,
      ...options
    })

    makeObservable(this)
  }

  /**
   * Return true if the row matching `rowId` is selected.
   */
  isSelected(row: D): boolean {
    return (
      this.isAllRowsSelectionStatus || this.$selectedRows.has(this.getId(row))
    )
  }

  /**
   * Return true if the related toggleable row is opened.
   */
  isToggleableRowOpened(row: D): boolean {
    return this.$toggleableOpenedRows.has(this.getId(row))
  }

  /**
   * Return the ID of the row.
   */
  getId(row: D): WidgetListGetterId {
    return this.options.getIdFn(row)
  }

  /**
   * Actions
   */
  @action
  setColumnSorterState(
    columnSorterState: Maybe<IdentityColumnSorterState>
  ): this {
    this.$columnSorterState.set(columnSorterState)
    return this
  }

  @action
  reset(): this {
    this.resetPagination()
    this.resetSelection()
    this.resetEntities()

    return this
  }

  /**
   * Reset pagination.
   */
  @action
  resetPagination(): this {
    return this.setPagination(EntityPagination.fromDefaultAttributes())
  }

  /**
   * Reset entities and pagination.
   * To be called on lists unmounts.
   */
  @action
  resetSelection(): this {
    this.unselectAllRows()
    this.resetPagination()
    return this
  }

  /**
   * Reset entities.
   */
  @action
  resetEntities(): this {
    this.$entities.clear()
    this.$toggleableOpenedRows.clear()

    return this
  }

  /**
   * Replace all entities of the list.
   */
  @action
  setEntities(entities: E[]): this {
    this.$entities.replace(entities)
    return this
  }

  /**
   * Update one entity of the list.
   */
  @action
  updateOneEntity(entity: E, predicate: (entity: E) => boolean): this {
    const entityIndex = this.$entities.findIndex(predicate)

    if (entityIndex < 0) {
      this.storeRoot.logger.debug(
        '[StoreWidgetList] Cant update the entity: entity has not been found.'
      )
      return this
    }

    this.$entities.splice(entityIndex, 1, entity)

    return this
  }

  @action
  setPagination(pagination: EntityPagination): this {
    this.$pagination.set(pagination)
    return this
  }

  @action
  updatePagination(pagination: Partial<EntityPagination>): this {
    const newPagination = new EntityPagination({
      ...this.$pagination.get(),
      ...pagination
    })

    this.$pagination.set(newPagination)

    return this
  }

  @action
  selectCurrentPageRows(): this {
    this.unselectAllRows()
    this.entitiesAsDataSet.rows.forEach(row => this.selectRow(row))
    return this
  }

  @action
  selectAllRows(): this {
    if (this.hasPagination) {
      this.$selectedRows.clear()
      this.$allRowsSelectionStatus.set(true)
      return this
    }

    this.$selectedRows.replace(this.entitiesAsDataRowsMap)

    return this
  }

  @action
  unselectAllRows(): this {
    if (this.hasPagination) {
      this.$allRowsSelectionStatus.set(false)
    }

    this.$selectedRows.clear()

    return this
  }

  @action
  selectRow(row: D): this {
    this.$selectedRows.set(this.getId(row), row)
    return this
  }

  @action
  selectRows(rows: D[]): this {
    rows.forEach(row => {
      this.$selectedRows.set(this.getId(row), row)
    })
    return this
  }

  /**
   * Uncheck one item.
   *
   * If all items were checked (select all items), unchecking one item will:
   *  - check all other of the same page only
   *  - uncheck that item
   *  - remove the all selection status
   */
  @action
  unselectRow(row: D, dataSet: D[]): this {
    if (this.isAllRowsSelectionStatus) {
      dataSet.forEach(dataRow => {
        const dataRowId = this.getId(dataRow)

        if (dataRowId) {
          this.$selectedRows.set(dataRowId, dataRow)
        }
      })

      this.$allRowsSelectionStatus.set(false)
    }

    this.$selectedRows.delete(this.getId(row))

    return this
  }

  /**
   * Select or unselect the row.
   * Do not confuse with `openToggleableRow` that opens the optional toggleable row.
   */
  @action
  toggleRowSelection(row: D): this {
    this.isSelected(row) ? this.unselectRow(row, []) : this.selectRow(row)

    return this
  }

  @action
  openToggleableRow(row: D): this {
    this.$toggleableOpenedRows.set(this.getId(row), row)
    return this
  }

  @action
  closeToggleableRow(row: D): this {
    this.$toggleableOpenedRows.delete(this.getId(row))
    return this
  }

  @action
  toggleToggleableRow(row: D): this {
    return this.isToggleableRowOpened(row)
      ? this.closeToggleableRow(row)
      : this.openToggleableRow(row)
  }

  /* Computed methods */

  @computed
  get columnSorterState() {
    return this.$columnSorterState.get()
  }

  /**
   * Return all entities (without filtering).
   */
  @computed
  get allEntitiesAsArray(): E[] {
    return toJS(this.$entities)
  }

  /**
   * Return entities.
   */
  @computed
  get entitiesAsArray(): E[] {
    return toJS(this.options.filterEntitiesFn(toJS(this.$entities)))
  }

  /**
   * Return entities as a dataSet.
   */
  @computed
  get entitiesAsDataSet(): IWidgetListDataSet<D> {
    return this.options.buildDataSetFn(this.entitiesAsArray)
  }

  /**
   * Return entities as an array of D.
   */
  @computed
  get entitiesAsDataRows(): D[] {
    return listDataRows(this.entitiesAsArray)
  }

  /**
   * Return entities as a map of D.
   */
  @computed
  get entitiesAsDataRowsMap(): Map<WidgetListGetterId, D> {
    return this.entitiesAsArray.reduce<Map<WidgetListGetterId, D>>((acc, e) => {
      const dataRow = listDataRow<D>(e)

      if (!dataRow) {
        return acc
      }

      acc.set(this.options.getIdFn(dataRow), dataRow)
      return acc
    }, new Map())
  }

  /**
   * Return the selected rows.
   */
  @computed
  get selectedRows(): Maybe<Map<WidgetListGetterId, D>> {
    // If the list is paginated and if all rows are selected (flag),
    // returns null since it's not possible to return all the selected rows
    // (other pages are not fetched)
    // except if we have already fetched all the pages, indicated by the offline option,
    // in this case it returns all the selected rows.
    if (this.hasPagination && this.isAllRowsSelected && !this.options.offline) {
      this.storeRoot.logger.warn(
        `[StoreWidgetList] Can't return the selected rows since the list is paginated and all items are selected`
      )
      return null
    }

    // If there is no pagination and if all rows are selected, return all rows
    if (!this.hasPagination && this.isAllRowsSelected) {
      return this.entitiesAsDataRowsMap
    }

    return toJS(this.$selectedRows)
  }

  /**
   * Return the selected rows as an array.
   */
  @computed
  get selectedRowsAsArray(): D[] {
    return Array.from((this.selectedRows || new Map()).values())
  }

  /**
   * Return the count of selected rows.
   */
  @computed
  get countSelectedRows(): number {
    if (this.isAllRowsSelectionStatus) {
      return this.paginationTotalCount
    }

    return this.$selectedRows.size
  }

  /**
   * Return the count of filtered selected rows.
   */
  @computed
  get countFilteredSelectedRows(): number {
    if (this.isAllRowsSelectionStatus) {
      return this.paginationTotalCount
    }

    const selectedRowIds = Array.from((this.selectedRows || new Map()).keys())

    const filteredSelectedRows = selectedRowIds.filter(selectedRowId => {
      return this.entitiesAsArray.some(
        entity => String(entity.id) === String(selectedRowId)
      )
    })

    return filteredSelectedRows.length
  }

  /**
   * Return true if the allRowsSelectionStatus is enable.
   * That supposes that a pagination object is available.
   */
  @computed
  get isAllRowsSelectionStatus(): boolean {
    return this.hasPagination && this.$allRowsSelectionStatus.get()
  }

  /**
   * Return true if all rows of the current page are selected.
   */
  @computed
  get isPageRowsSelected(): boolean {
    if (this.isAllRowsSelectionStatus) {
      return true
    }

    if (this.entitiesAsDataSet.rows.length === 0) {
      return false
    }

    return this.$selectedRows.size === this.entitiesAsDataSet.rows.length
  }

  /**
   * Return the 'indeterminate' status of the checkbox of the current page.
   */
  @computed
  get isPageRowsPartiallySelected(): boolean {
    if (this.$selectedRows.size === 0) {
      return false
    }

    if (this.isAllRowsSelected) {
      return false
    }

    return true
  }

  /**
   * Return true if all rows are selected.
   */
  @computed
  get isAllRowsSelected(): boolean {
    if (this.isAllRowsSelectionStatus) {
      return true
    }

    if (this.paginationTotalCount === 0 || this.$selectedRows.size === 0) {
      return false
    }

    return this.$selectedRows.size === this.paginationTotalCount
  }

  /**
   * Return the 'indeterminate' status of the checkbox for all pages.
   */
  @computed
  get isAllRowsPartiallySelected(): boolean {
    if (this.$selectedRows.size === 0) {
      return false
    }

    if (this.isAllRowsSelected) {
      return false
    }

    if (this.$selectedRows.size === this.paginationTotalCount) {
      return false
    }

    return true
  }

  /**
   * Return true if some rows are selected.
   */
  @computed
  get isSomeRowsSelected(): boolean {
    if (this.isAllRowsSelectionStatus) {
      return true
    }

    return this.$selectedRows.size > 0
  }

  /**
   * Return the map of toggleable rows.
   */
  @computed
  get getToggleableRows(): Map<WidgetListGetterId, D> {
    return toJS(this.$toggleableOpenedRows)
  }

  /**
   * Return true if the list is empty (has no entities) or if all rows are
   * "empty entities" (entities with id equals to -1).
   */
  @computed
  get isEmpty(): boolean {
    return (
      !this.entitiesAsArray.length ||
      isListConsideredAsEmpty(this.entitiesAsArray.map(row => row.id))
    )
  }

  /**
   * Return the pagination entity.
   */
  @computed
  get pagination(): EntityPagination {
    return this.$pagination.get()
  }

  /**
   * Return true if an entity pagination has been defined.
   */
  @computed
  get hasPagination(): boolean {
    const totalCount = this.$pagination.get().totalCount
    return isDefined(totalCount) && totalCount !== -1
  }

  /**
   * Return the number of the current page.
   */
  @computed
  get paginationPage(): number {
    if (this.hasPagination) {
      return this.$pagination.get().page || 1
    }

    return 1
  }

  /**
   * Return the number of the next page.
   */
  @computed
  get paginationNextPage(): number {
    if (!this.hasMorePages) {
      return this.paginationPage
    }

    return this.paginationPage + 1
  }

  /**
   * Return the number of rows per page.
   */
  @computed
  get rowsPerPage(): number {
    if (this.hasPagination) {
      return this.$pagination.get().perPage || PAGINATION_PERPAGE_DEFAULT
    }

    return PAGINATION_PERPAGE_DEFAULT
  }

  /**
   * Return the count of all rows.
   */
  @computed
  get paginationTotalCount(): number {
    if (this.hasPagination) {
      return this.$pagination.get().totalCount || 0
    }

    return this.entitiesAsArray.length
  }

  /**
   * Return true if the current page is not the latest.
   */
  @computed
  get hasMorePages(): boolean {
    const pagination = this.$pagination.get()

    if (!pagination) {
      return false
    }

    if (
      !isDefined(pagination.totalCount) ||
      !isDefined(pagination.perPage) ||
      !isDefined(pagination.page)
    ) {
      return false
    }

    return pagination.totalCount > pagination.perPage * pagination.page
  }

  /* methods */

  nextColumnSorterState(columnId: GetIdentitiesSortByEnum) {
    if (
      this.$columnSorterState.get() === null ||
      this.$columnSorterState.get()?.columnId !== columnId
    ) {
      return { columnId, sortingOrder: GetIdentitiesOrderEnum.Asc }
    }
    const nextSortingOrder =
      this.$columnSorterState.get()?.sortingOrder === GetIdentitiesOrderEnum.Asc
        ? GetIdentitiesOrderEnum.Desc
        : GetIdentitiesOrderEnum.Asc
    return { columnId, sortingOrder: nextSortingOrder }
  }
}
