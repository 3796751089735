import type { PropertiesNullable } from '@@types/helpers'
import EntityAdObject from '@app/entities/EntityAdObject'
import type {
  IDataRowAdObject,
  IDataRowAdObjectAsAttribute
} from '@app/entities/EntityAdObject/types'
import { filterAttributes } from '@app/entities/EntityAdObject/utils'
import type {
  IEntityListable,
  IEntityOmitKeysParameter
} from '@app/entities/types'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import { isDefined } from '@libs/isDefined'
import type { IDecodeOptions } from '@libs/valueTypeParser/types'
import type { AdObject, Maybe } from '@server/graphql/typeDefs/types'

export default class EntityAdObjectAsAttribute
  extends EntityAdObject
  implements
    PropertiesNullable<AdObject>,
    IEntityListable<IDataRowAdObjectAsAttribute>
{
  /* Implements IEntityListable */

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowAdObjectAsAttribute> = []
  ): Array<IWidgetListColumns<IDataRowAdObjectAsAttribute>> {
    const columns: Array<IWidgetListColumns<IDataRowAdObjectAsAttribute>> = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Is change',
        key: 'isChange'
      },
      {
        label: 'Attributes between before and at event',
        key: 'attributeDiffsBetweenBeforeAndAtEvent'
      },
      {
        label: 'Attribute',
        key: 'attributeName'
      },
      {
        label: 'Value at event',
        key: 'attributeValueAtEvent'
      },
      {
        label: 'Current value',
        key: 'attributeCurrentValue'
      }
    ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  /**
   * Return attributes and changes as a dataSet row structure to be able
   * to use this in a WidgetListTable.
   */
  asCustomDataRow(
    filterRegexp: Maybe<RegExp>,
    decodeOptions: IDecodeOptions = {}
  ): IDataRowAdObject {
    const filterAttributeFn = filterAttributes(filterRegexp)

    const changes: IDataRowAdObjectAsAttribute[] = this.getChanges()
      .map(change => {
        if (!change.attributeName) {
          return
        }

        return {
          attributeName: change.attributeName,
          valueType: change.getValueType()
        }
      })
      .filter(isDefined)
      .sort()
      .map(({ attributeName, valueType }, id) => {
        const row: IDataRowAdObjectAsAttribute = {
          // start at 1000 to avoid clashs with id changes
          id: 1000 + id + 1,
          isChange: true,
          attributeName,
          attributeValueAtEvent: {
            attributeName,
            value: String(
              this.getAttributeValueAtEvent(attributeName, decodeOptions)
            ),
            valueType
          },
          attributeCurrentValue: {
            attributeName,
            value: String(
              this.getAttributeCurrentValue(attributeName, decodeOptions)
            ),
            valueType
          },
          attributeDiffsBetweenBeforeAndAtEvent: {
            // limit the values to avoid to make some diffs on huge attributes
            // like ntSecurityDescriptor.
            diffs: this.getDiffBetweenBeforeAndAtEvent(attributeName, {
              ...decodeOptions,
              maxLength: 1000
            }),
            attributeBeforeValue: {
              attributeName,
              value: String(
                this.getAttributeBeforeValue(attributeName, {
                  ...decodeOptions,
                  maxLength: 1000
                })
              ),
              valueType
            }
          }
        }

        return row
      })
      .filter(filterAttributeFn)

    const changeAttributeNames = changes.map(change => change.attributeName)

    const attributes: IDataRowAdObjectAsAttribute[] = this.getAttributes()
      .map(attribute => {
        if (!attribute.name) {
          return
        }

        return {
          attributeName: attribute.name,
          valueType: attribute.getValueType()
        }
      })
      .filter(isDefined)
      // remove attributes already in changes
      .filter(
        ({ attributeName }) =>
          changeAttributeNames.indexOf(attributeName) === -1
      )
      .sort()
      .map(({ attributeName, valueType }, id) => {
        const row: IDataRowAdObjectAsAttribute = {
          id: id + 1,
          isChange: false,
          attributeName,
          attributeValueAtEvent: {
            attributeName,
            value: String(
              this.getAttributeValueAtEvent(attributeName, decodeOptions)
            ),
            valueType
          },
          attributeCurrentValue: {
            attributeName,
            value: String(
              this.getAttributeCurrentValue(attributeName, decodeOptions)
            ),
            valueType
          }
        }

        return row
      })
      .filter(filterAttributeFn)

    const results: IDataRowAdObject = {
      id: this.getPropertyAsNumber('id'),
      changes,
      attributes
    }

    return results
  }
}
