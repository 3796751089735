import { Features } from '@alsid/common'
import { ButtonShowPicker } from '@app/components-legacy/Button'
import { Drawer, DrawerContext } from '@app/components-legacy/Drawer'
import { handleDrawerOnOpen } from '@app/components-legacy/Drawer/handlers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { DSThemeName } from '@design-system/styles/themes/types'
import type { BaseButtonProps } from 'antd/lib/button/button'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerInputCheckers from './DrawerInputCheckers'
import CheckersSelectionCounter from './DrawerInputCheckers/CheckersSelectionCounter'

interface IInputCheckersButtonProps<GC extends TGenericChecker> {
  size?: BaseButtonProps['size']
  onValidate?: () => void
  storeInputGenericCheckers: StoreInputGenericCheckers<GC>
}

function InputCheckersButton<GC extends TGenericChecker>(
  props: IInputCheckersButtonProps<GC>
): React.ReactElement {
  const featureDrawerNewDesign = useFeatureFlag({
    featureFlagName: Features.DRAWER_NEW_DESIGN
  })

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  return (
    <>
      <ButtonShowPicker
        labelledBy="showPickerCheckers"
        buttonsProps={{
          size: props.size,
          onClick: handleDrawerOnOpen(
            props.storeInputGenericCheckers.storeDrawer
          )
        }}
        storeDrawer={props.storeInputGenericCheckers.storeDrawer}
      >
        <CheckersSelectionCounter
          storeInputGenericCheckers={props.storeInputGenericCheckers}
        />
      </ButtonShowPicker>

      <Portal name={PlaceHolderName.selectorDrawer}>
        <DSThemeProvider
          themeName={
            featureDrawerNewDesign && !themeIsLegacy
              ? DSThemeName.default
              : DSThemeName.legacy
          }
        >
          <DrawerContext.Provider
            value={{ storeDrawer: props.storeInputGenericCheckers.storeDrawer }}
          >
            <Drawer>
              <DrawerInputCheckers
                onValidate={props.onValidate}
                storeInputGenericCheckers={props.storeInputGenericCheckers}
              />
            </Drawer>
          </DrawerContext.Provider>
        </DSThemeProvider>
      </Portal>
    </>
  )
}

export default observer(InputCheckersButton)
