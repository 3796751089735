import {
  ContainerContent,
  ContainerFlex
} from '@app/components-legacy/Container'
import LabelResource from '@app/components-legacy/Label/LabelResource'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { ensureArray } from '@libs/ensureArray'
import type { Resource } from '@libs/openapi/service-identity-core'
import * as React from 'react'
import { CheckerIdentityDetailsPageContext } from '../context'

interface IResourcesProps {
  resources: Resource[]
}

const Resources: React.FC<IResourcesProps> = props => {
  const parameters = React.useContext(CheckerIdentityDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoE.Details.Information']
  })

  if (!parameters) {
    return null
  }

  const renderContent = () => {
    const items = ensureArray(props.resources).map(resource => {
      if (!resource.name) {
        return null
      }

      return <LabelResource name={resource.name} url={resource.url} />
    })

    if (!items.length) {
      return <div>{translate('No documents listed for this indicator')}</div>
    }

    return (
      <ContainerFlex
        name="Resources"
        direction="column"
        items={items}
        spaced
        spaceWidth="small"
      />
    )
  }

  return (
    <ContainerContent labelledBy="resources" title={translate('Resources')}>
      {renderContent()}
    </ContainerContent>
  )
}

export default Resources
