import { Label } from '@app/components/Labels/index'
import type { ILabelProps } from '@app/components/Labels/Label'
import { consts } from '@app/styles'
import { ensureArray } from '@libs/ensureArray'
import * as React from 'react'

interface ILabelAltErrorProps extends ILabelProps {
  labelledBy: string
}

const LabelAltError: React.FC<ILabelAltErrorProps> = props => {
  const ariaRoles = ensureArray(props.ariaRoles || 'alert')

  return (
    <Label color={consts.colorRed001} ariaRoles={ariaRoles} {...props}>
      {props.children}
    </Label>
  )
}

export default LabelAltError
