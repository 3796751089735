import { Features } from '@alsid/common'
import { ButtonShowPicker } from '@app/components-legacy/Button'
import { Drawer, DrawerContext } from '@app/components-legacy/Drawer'
import DrawerInputReasons from '@app/components-legacy/Input/InputReasons/DrawerInputReasons'
import ReasonsSelectionCounter from '@app/components-legacy/Input/InputReasons/DrawerInputReasons/ReasonsSelectionCounter'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import type { StoreInputReasons } from '@app/stores'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { DSThemeName } from '@design-system/styles/themes/types'
import type { BaseButtonProps } from 'antd/lib/button/button'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { onReasonsPickerButtonClick } from './handlers'

interface IInputReasonsButtonProps {
  size?: BaseButtonProps['size']
  onValidate?: () => void
  storeInputReasons: StoreInputReasons
}

const InputReasonsButton: React.FC<IInputReasonsButtonProps> = props => {
  const featureDrawerNewDesign = useFeatureFlag({
    featureFlagName: Features.DRAWER_NEW_DESIGN
  })

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  return (
    <>
      <ButtonShowPicker
        labelledBy="showPickerReasons"
        buttonsProps={{
          size: props.size,
          onClick: onReasonsPickerButtonClick(props.storeInputReasons)
        }}
        storeDrawer={props.storeInputReasons.storeDrawer}
      >
        <ReasonsSelectionCounter storeInputReasons={props.storeInputReasons} />
      </ButtonShowPicker>

      <Portal name={PlaceHolderName.selectorDrawer}>
        <DSThemeProvider
          themeName={
            featureDrawerNewDesign && !themeIsLegacy
              ? DSThemeName.default
              : DSThemeName.legacy
          }
        >
          <DrawerContext.Provider
            value={{ storeDrawer: props.storeInputReasons.storeDrawer }}
          >
            <Drawer>
              <DrawerInputReasons
                onValidate={props.onValidate}
                storeInputReasons={props.storeInputReasons}
              />
            </Drawer>
          </DrawerContext.Provider>
        </DSThemeProvider>
      </Portal>
    </>
  )
}

export default observer(InputReasonsButton)
