import { useStores } from '@app/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { HealthCheckDetailsContext } from './context'
import HealthCheckDetailsInformation from './HealthCheckDetailsInformation'

interface IHealthCheckPlatformStatusDetailsInformationPageProps {}

const HealthCheckPlatformStatusDetailsInformationPage: React.FC<
  IHealthCheckPlatformStatusDetailsInformationPageProps
> = props => {
  const { storeHealthCheck } = useStores()

  const parameters = React.useContext(HealthCheckDetailsContext)

  if (!parameters || !storeHealthCheck.healthCheckPlatformStatusDetails) {
    return null
  }

  return (
    <HealthCheckDetailsInformation
      healthCheckDetails={storeHealthCheck.healthCheckPlatformStatusDetails}
    />
  )
}

export default observer(HealthCheckPlatformStatusDetailsInformationPage)
