import { isDefined } from '@libs/isDefined'
import * as React from 'react'
import { LabelDirectories } from '..'
import type { ILabelAlertProps } from './types'

interface IAlertDirectoriesProps {
  directories: ILabelAlertProps['directories']
}

const AlertDirectories: React.FC<IAlertDirectoriesProps> = props => {
  if (!props.directories) {
    return null
  }

  const directoryIds = props.directories.map(d => d.id).filter(isDefined)

  return (
    <div>
      <LabelDirectories directoryIds={directoryIds} maxWidth={190} />
    </div>
  )
}

export default AlertDirectories
