import WidgetContainer from '@app/pages/HeaderLegacy/Widgets/WidgetContainer'
import * as React from 'react'
import WidgetUserIcon from './WidgetUserIcon'

interface IPopoverContainerProps {}

const PopoverContainer = React.forwardRef<
  HTMLDivElement,
  IPopoverContainerProps
>((props, ref) => {
  return (
    <WidgetContainer
      ref={ref}
      name="WidgetUser"
      ariaRoles={['menu', 'menuitem']}
      labelledBy="username"
      {...props}
    >
      <WidgetUserIcon />
    </WidgetContainer>
  )
})

export default PopoverContainer
