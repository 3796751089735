import { ContainerContent } from '@app/components-legacy/Container'
import { InputCheckersCards } from '@app/components-legacy/Input'
import type { ILabelCheckerAppearance } from '@app/components-legacy/Label/LabelChecker/types'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerFooter from './DrawerFooter'

export interface IInputCheckersDrawerProps<GC extends TGenericChecker> {
  className?: string
  checkersAppearance?: ILabelCheckerAppearance
  onValidate?: () => void
  storeInputGenericCheckers: StoreInputGenericCheckers<GC>
  storePendingInputGenericCheckers: StoreInputGenericCheckers<GC>
}

function DrawerContent<GC extends TGenericChecker>(
  props: IInputCheckersDrawerProps<GC>
): React.ReactElement {
  return (
    <ContainerContent
      flags={
        props.storePendingInputGenericCheckers.storeFlagsFetchCheckers.flags
      }
      spinner={<SpinnerInline />}
    >
      <InputCheckersCards
        storeInputGenericCheckers={props.storePendingInputGenericCheckers}
        checkerAppearance={props.checkersAppearance}
        loadCheckersAtLoading
      />

      <Portal name={PlaceHolderName.drawerFooter}>
        <DrawerFooter
          onValidate={props.onValidate}
          storeDrawer={props.storeInputGenericCheckers.storeDrawer}
          storePendingInputGenericCheckers={
            props.storePendingInputGenericCheckers
          }
        />
      </Portal>
    </ContainerContent>
  )
}

export default observer(DrawerContent)
