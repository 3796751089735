import type { MaybeUndef } from '@@types/helpers'
import type StoreForm from '@app/stores/helpers/StoreForm'
import type { Combinator } from '@libs/Expression/types'
import type { IObservableArray } from 'mobx'

export enum WizardRuleFieldName {
  field = 'field',
  value = 'value'
}

export enum WizardRuleGroupFieldName {
  combinator = 'combinator'
}

export enum WizardFieldName {
  isDeviant = 'isDeviant',
  date = 'date'
}

// --- Store types ---

export type WizardRule = {
  storeForm: StoreForm<any>
  rules?: IObservableArray<WizardRuleOrRuleGroup>
}

export type WizardRuleGroup = {
  storeForm: StoreForm<any>
  rules: IObservableArray<WizardRuleOrRuleGroup>
}

export type WizardRuleOrRuleGroup = WizardRule | WizardRuleGroup

// --- Output types ---

export type DefinitionOperator = '=' | '>=' | '>' | '<=' | '<'

export type DefinitionDate = [DefinitionOperator, MaybeUndef<string>]
export type DefinitionDateValue = [DefinitionDate, DefinitionDate]

export type DefinitionRule = {
  field: string
  value: string | number | boolean
}

export type DefinitionDateRule = {
  field: WizardFieldName.date
  value: DefinitionDateValue
}

export type DefinitionRuleGroup = {
  combinator: Combinator
  rules: DefinitionRuleOrRuleGroup[]
}

export type DefinitionRuleOrRuleGroup = DefinitionRuleGroup | DefinitionRule
