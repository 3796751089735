import type { PropertiesNullable } from '@@types/helpers'
import type { Maybe, Reason } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityReason
  extends EntityBase
  implements PropertiesNullable<Reason>
{
  id: Maybe<number> = null
  codename: Maybe<string> = null
  name: Maybe<string> = null
  description: Maybe<string> = null

  // save one color per reason
  color: string = '#000000'

  constructor(data: Partial<Reason>) {
    super()
    Object.assign(this, data)
  }

  setColor(color: string): this {
    this.color = color
    return this
  }
}
