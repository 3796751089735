import { Blade } from '@app/components-legacy/Blade'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import RbacRoutes from '@app/components-legacy/Router/RbacRoutes'
import { useStores } from '@app/hooks/useStores'
import MenuSystem from '@app/pages/Management/SystemPage/MenuSystem'
import { AppRouteName } from '@app/routes'
import { grant } from '@libs/rbac/permissions'
import * as React from 'react'
import LegalEnvoy from './LegalEnvoy'
import LegalEula from './LegalEula'
import LegalMitreAttack from './LegalMitreAttack'
import LegalNavigation from './LegalNavigation'

interface ILegalPageProps {}

const LegalPage: React.FC<ILegalPageProps> = () => {
  const { storeAbout } = useStores()

  return (
    <Blade
      root={true}
      routeDefinition={{
        routeName: AppRouteName.Management_System_Legal,
        parameters: {}
      }}
      flags={storeAbout.storeFlags.flags}
      layout={{
        name: 'twoColumns',
        menu: <MenuSystem />,
        leftColumn: <LegalNavigation />,
        content: (
          <RbacRoutes
            routes={[
              {
                routeDefinition: {
                  routeName: AppRouteName.Management_System_Legal_Eula,
                  parameters: {}
                },
                component: LegalEula,
                rbacPermissionsCheck: () => grant()
              },
              {
                routeDefinition: {
                  routeName: AppRouteName.Management_System_Legal_MitreAttack,
                  parameters: {}
                },
                component: LegalMitreAttack,
                rbacPermissionsCheck: () => grant()
              },
              {
                routeDefinition: {
                  routeName: AppRouteName.Management_System_Legal_Envoy,
                  parameters: {}
                },
                component: LegalEnvoy,
                rbacPermissionsCheck: () => grant()
              }
            ]}
            redirect
          />
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      rbacCapabilityCheck={grant()}
    />
  )
}

export default LegalPage
