import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import type { IBladeLayoutTwoColumns } from '../types'

interface IBladeContentNavigationProps {
  className?: string
  style?: React.CSSProperties
  layout: IBladeLayoutTwoColumns
}

const BladeContentLayoutTwoColumnsLeftColumn: React.FC<
  IBladeContentNavigationProps
> = props => {
  if (!props.layout.leftColumn) {
    return null
  }

  return (
    <div
      data-name="BladeContentLayoutTwoColumnsLeftColumn"
      className={props.className}
      style={props.style}
    >
      {props.layout.leftColumn}
    </div>
  )
}

export default styled(BladeContentLayoutTwoColumnsLeftColumn)`
  min-width: 300px;
  padding: ${consts.paddingMedium} ${consts.paddingLarge};
`
