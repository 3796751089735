import { Features } from '@alsid/common'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  accessByFeatureFlag,
  editRelay,
  readRelay,
  readUISystemRelay
} from '@libs/rbac/permissions'

export const canAccessToRelays = rbacCapabilityCheckAllOf(
  readUISystemRelay(),
  readRelay()
)

export const canEditRelays = (relayId?: number) =>
  rbacCapabilityCheckAllOf(canAccessToRelays, editRelay(relayId))

export const canRenewRelaysApiKeys = (relayId?: number) =>
  rbacCapabilityCheckAllOf(
    canAccessToRelays,
    editRelay(relayId),
    accessByFeatureFlag(Features.SRLY_APIKEY_RENEWAL)()
  )
