import type { PublicPart } from '@@types/helpers'
import type { BladeUuid } from '@app/stores/StoreBlades/types'

export type IBladeRaw = Pick<PublicPart<EntityBlade>, 'title' | 'url'>

export default class EntityBlade {
  uuid: BladeUuid
  title: string
  url: string
  level: number
  inPosition: boolean

  constructor(data: PublicPart<EntityBlade>) {
    this.uuid = data.uuid
    this.title = data.title
    this.url = data.url
    this.level = data.level
    this.inPosition = data.inPosition
  }
}
