import { AppMenu } from '@app/components-legacy/Menu'
import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { filterFalsies } from '@libs/filterFalsies'
import * as React from 'react'
import { CheckerIdentityDetailsPageContext } from './context'
import {
  canAccessToIoEIdentityFindings,
  canReadIoEIdentityInformation,
  canReadIoEIdentityRecommandations
} from './permissions'

enum DetailsBladeMenuKey {
  Information = 'Information',
  Findings = 'Findings',
  Recommendations = 'Recommendations'
}

interface IDetailsBladeMenuProps {}

const DetailsBladeMenu: React.FC<IDetailsBladeMenuProps> = () => {
  const parameters = React.useContext(CheckerIdentityDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: ['IoE.IdentityDetails.Menu']
  })

  const { storeRbac } = useStores()

  const storeMenu = useStoreMenuInit<IMenuEntry<DetailsBladeMenuKey>>({
    menuEntries:
      parameters &&
      filterFalsies([
        storeRbac.isUserGrantedTo(canReadIoEIdentityInformation) && {
          key: DetailsBladeMenuKey.Information,
          label: translate('Information'),
          labelledBy: 'Information',
          routeDefinition: {
            routeName: AppRouteName.IoE_Identity_Details_Information,
            parameters
          }
        },
        storeRbac.isUserGrantedTo(canAccessToIoEIdentityFindings) && {
          key: DetailsBladeMenuKey.Findings,
          label: translate('Findings'),
          labelledBy: 'Findings',
          routeDefinition: {
            routeName: AppRouteName.IoE_Identity_Details_Findings,
            parameters
          }
        },
        storeRbac.isUserGrantedTo(canReadIoEIdentityRecommandations) && {
          key: DetailsBladeMenuKey.Recommendations,
          label: translate('Recommendations'),
          labelledBy: 'Recommendations',
          routeDefinition: {
            routeName: AppRouteName.IoE_Identity_Details_Recommendations,
            parameters
          }
        }
      ]),
    defaultSelectedMenuKey: DetailsBladeMenuKey.Information
  })

  return <AppMenu storeMenu={storeMenu} />
}

export default DetailsBladeMenu
