import { LabelAbout } from '@app/components-legacy/Label'
import { useStores } from '@app/hooks/useStores'
import { consts } from '@app/styles'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

interface IProductVersionProps {
  className?: string
}

interface IProductVersionProps {}

const ProductVersion: React.FC<IProductVersionProps> = props => {
  const { storeAbout } = useStores()

  if (storeAbout.storeFlags.isLoading) {
    return null
  }

  return (
    <LabelAbout
      className={props.className}
      version={storeAbout.productVersion}
      showPrefix
    />
  )
}

const ObservedProductVersion = observer(ProductVersion)

export default styled(ObservedProductVersion)`
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  padding: ${consts.paddingDefault};
`
