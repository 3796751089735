import Navigation from '@app/components-legacy/Navigation'
import * as React from 'react'
import { useSystemConfigurationStoreMenu } from './hooks/useSystemConfigurationStoreMenu'

interface IConfigurationNavigationProps {}

const ConfigurationNavigation: React.FC<IConfigurationNavigationProps> = () => {
  const storeConfigurationNavigation = useSystemConfigurationStoreMenu()

  return <Navigation storeMenu={storeConfigurationNavigation} />
}

export default ConfigurationNavigation
