import type { IIconProps } from '@app/components/Icon/types'
import { CTAColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconAttackPathProps extends IIconProps {}

const IconAttackPath: React.FC<IIconAttackPathProps> = props => {
  const fill = props.color ?? CTAColorV2.primary
  const size = props.size ?? 20

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.622 4.8L10.5631 5.85893L12.0651 7.36098H3.66871L5.17076 5.85893L4.11178 4.8L1.85873 7.05305L0.799805 8.112L1.85873 9.17093L4.11178 11.424L5.17076 10.3651L3.66871 8.86303H12.0651L10.5631 10.3651L11.622 11.424L13.875 9.17093L14.934 8.112L13.875 7.05305L11.622 4.8Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconAttackPath
