import { BladesContainer } from '@app/components-legacy/Blade'
import { ContainerFlex } from '@app/components-legacy/Container'
import { LayoutDefault } from '@app/components-legacy/Layout'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { DSThemeName } from '@design-system/styles/themes/types'
import * as React from 'react'
import ActivityLogsPage from './ActivityLogsPage'

function ActivityLogs() {
  return (
    <DSThemeProvider themeName={DSThemeName.grey}>
      <LayoutDefault
        content={
          <ContainerFlex
            name="ActivityLogs"
            direction="row"
            items={[
              <BladesContainer>
                <ActivityLogsPage />
              </BladesContainer>
            ]}
          />
        }
      />
    </DSThemeProvider>
  )
}

export default ActivityLogs
