import * as React from 'react'
import styled from 'styled-components'

interface IBladesContainerProps {
  className?: string
  style?: React.CSSProperties
  children: JSX.Element
}

const BladesContainer: React.FC<IBladesContainerProps> = props => {
  return (
    <div
      data-name="BladesContainer"
      style={props.style}
      className={props.className}
    >
      {/* Suppose that the first child is a Blade and passing the prop `true`
      to avoid left to right transition. See Blade component styles. */}
      {React.cloneElement(props.children, {
        root: true
      })}
    </div>
  )
}

export default styled(BladesContainer)`
  position: relative;
  height: 100%;
  width: 100%;
`
