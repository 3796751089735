import type { Maybe } from '@@types/helpers'
import { rbacCapabilityCheckAllOf } from '@libs/rbac/functions'
import {
  createEmailAlert,
  deleteEmailAlert,
  editEmailAlert,
  readEmailAlerts,
  readLicenseFeatureIoA
} from '@libs/rbac/permissions'
import { canAccessToEmailAlertsConfiguration } from '../permissions'

export const canCreateEmailAlerts = rbacCapabilityCheckAllOf(
  canAccessToEmailAlertsConfiguration,
  createEmailAlert()
)

export const canReadEmailAlert = (emailId: number) =>
  rbacCapabilityCheckAllOf(
    canAccessToEmailAlertsConfiguration,
    readEmailAlerts(emailId)
  )

export const canEditEmailAlert = (emailId: number) =>
  rbacCapabilityCheckAllOf(
    canAccessToEmailAlertsConfiguration,
    editEmailAlert(emailId)
  )

export const canDeleteEmailAlert = (emailId: Maybe<number>) =>
  rbacCapabilityCheckAllOf(
    canAccessToEmailAlertsConfiguration,
    deleteEmailAlert(emailId)
  )

export const canAccessToIoASecurityCheck = rbacCapabilityCheckAllOf(
  readLicenseFeatureIoA()
)
