import type { Query } from '../typeDefs/types'

/**
 * Fetch deviant objects for a checker.
 * Used for IoE > Deviant Objects list.
 */
export type QueryCheckerDeviantObjects = Pick<Query, 'rbacDeviantAdObjects'>

export const queryCheckerDeviantObjects = `
  query queryCheckerDeviantObjects (
    $profileId: Int!
    $checkerId: Int!
    $directoryIds: [Int!]!
    $reasonIds: [Int!]!
    $dateStart: String
    $dateEnd: String
    $showIgnored: Boolean!
    $adObjectsExpression: String!
    $adObjectsPage: Int
    $adObjectsPerPage: Int
  ) {
    rbacDeviantAdObjects(
      profileId: $profileId
      checkerId: $checkerId
      directoryIds: $directoryIds
      reasonIds: $reasonIds
      dateStart: $dateStart
      dateEnd: $dateEnd
      showIgnored: $showIgnored
      adObjectsExpression: $adObjectsExpression
      adObjectsPage: $adObjectsPage
      adObjectsPerPage: $adObjectsPerPage
    ) {
      node {
        node {
          id
          type
          directoryId
          objectId
          reasons
          objectAttributes {
            name
            value
            valueType
          }
          directoryId
        }
        pagination {
          page
          perPage
          totalCount
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Fetch incriminating attributes for an AdObject.
 * Used for IoE > Deviant Objects list.
 */
export type QueryAdObjectIncriminatingAttributes = Pick<
  Query,
  'rbacAdObjectIncriminatingAttributes'
>

export const queryAdObjectIncriminatingAttributes = `
  query queryAdObjectIncriminatingAttributes (
    $profileId: Int!
    $checkerId: Int!
    $adObjectId: Int!
    $showIgnored: Boolean!
    $dateStart: String
    $dateEnd: String
    $incriminatingAttributesPage: Int
    $incriminatingAttributesPerPage: Int
  ) {
    rbacAdObjectIncriminatingAttributes(
      profileId: $profileId
      checkerId: $checkerId
      adObjectId: $adObjectId
      showIgnored: $showIgnored
      dateStart: $dateStart
      dateEnd: $dateEnd
      incriminatingAttributesPage: $incriminatingAttributesPage
      incriminatingAttributesPerPage: $incriminatingAttributesPerPage
    ) {
      node {
        node {
          id
          eventDate
          createdEventId
          resolvedEventId
          devianceProviderId
          ignoreUntil
          resolvedAt
          attributes {
            name
            value
          }
          description {
            template
            replacements {
              name
              valueType
            }
          }
          checkerId
          directoryId
          profileId
          adObjectId
          reasonId
        }
        pagination {
          page
          perPage
          totalCount
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`

/**
 * Used in deviant objects export to retrieve contextual data of ids.
 */
export type QueryDeviantObjectsExportRelatedEntities = Pick<
  Query,
  'rbacDirectories' | 'rbacReasons'
>

export const queryDeviantObjectsExportRelatedEntities = `
  query queryDeviantObjectsExportRelatedEntities {
    rbacDirectories {
      node {
        node {
          id
          name
        }
        pagination {
          page
          perPage
          totalCount
        }
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
    rbacReasons {
      node {
        id
        name
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
