import {
  ContainerContent,
  ContainerForm
} from '@app/components-legacy/Container'
import { LabelChecker } from '@app/components-legacy/Label'
import type { EntityGenericChecker } from '@app/entities/EntityGenericChecker/types'
import { useAppTranslator, useStores } from '@app/hooks'
import * as React from 'react'
import { onOptionOnUnload } from './handlers'

interface IConfigurationHeaderProps {
  checkerEntity: EntityGenericChecker
}

const ConfigurationHeader: React.FC<IConfigurationHeaderProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Profiles.Configuration']
  })

  const { storeManagementProfiles } = useStores()

  return (
    <ContainerContent
      title={translate('Indicator configuration')}
      onUnload={onOptionOnUnload(
        storeManagementProfiles.storeProfileGenericCheckersSelected
      )(props.checkerEntity)}
    >
      <ContainerForm
        fields={[
          {
            name: props.checkerEntity.genericName,
            label: translate('Name of the indicator'),
            control: (
              <LabelChecker
                criticity={props.checkerEntity.genericCriticity}
                codename={props.checkerEntity.genericCodename}
                name={props.checkerEntity.genericName}
                small
              />
            )
          }
        ]}
      />
    </ContainerContent>
  )
}

export default ConfigurationHeader
