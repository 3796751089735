import { appendEntitiesToMap } from '@libs/indexEntitiesToMap'
import type { Tenant, ProviderType } from '@libs/openapi/service-identity-core'

export type TenantsByProvider = Map<ProviderType, Set<Tenant>>

/**
 * From a list of tenants, return a list of unique provider types with a list
 * of tenants.
 */
export const getTenantsByProviderType = (
  tenants: Tenant[]
): TenantsByProvider => {
  return appendEntitiesToMap<Tenant, ProviderType>(tenants, 'providerType')
}
