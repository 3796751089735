import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import { TagFlags } from './types'

interface ITagValue {
  className?: string
  variant?: TagFlags
  children: React.ReactNode
}

const TagValueRaw = (props: ITagValue) => {
  return (
    <div data-name="TagValue" className={props.className}>
      {props.children}
    </div>
  )
}

export default styled(TagValueRaw)`
  color: ${props => {
    const variant = props.variant || TagFlags.None

    if (variant & TagFlags.White) {
      return props.theme.tokens['tagExpression/color/white']
    }

    if (variant & TagFlags.Transparent) {
      return props.theme.tokens['tagExpression/color/transparent']
    }

    return props.theme.tokens['tagExpression/color/default']
  }};

  background-color: ${props => {
    const variant = props.variant || TagFlags.None

    if (variant & TagFlags.White) {
      return props.theme.tokens['tagExpression/backgroundColor/white']
    }

    if (variant & TagFlags.Grey) {
      return consts.colorBlueGray020
    }

    if (variant & TagFlags.Transparent) {
      return 'transparent'
    }

    return props.theme.tokens['tagExpression/backgroundColor/default']
  }};

  margin: 2px
    ${props => {
      const variant = props.variant || TagFlags.None

      if (variant & TagFlags.NoMargin) {
        return '0'
      }

      return '2px'
    }};

  padding: 1px;

  ${consts.fontMonospaceDefault};
  font-weight: ${consts.fontWeightBold};
  white-space: nowrap;

  border-radius: 2px;
`
