export function getInputSearchAvailableWidth(width: number): number {
  const border = 2
  const padding = 14
  const iconSize = 32
  const marginText = 5
  const paddingText = 4
  const closeIcon = 20

  const marginBetweenLabelAndDirectory = 8

  const iconsAndMarginWidth =
    border + padding + iconSize + marginText + paddingText + closeIcon

  return Math.max(
    width - iconsAndMarginWidth - marginBetweenLabelAndDirectory,
    0
  )
}
