import { ContainerFlex } from '@app/components-legacy/Container'
import { InputSearch } from '@app/components-legacy/Input'
import { useAppTranslator, useStores } from '@app/hooks'
import * as React from 'react'
import CheckboxOnlyGrantedPermissions from './ListEntityItems/CheckboxOnlyGrantedPermissions'

interface IRolesEditBladeHeaderProps {}

const RolesEditHeader: React.FC<IRolesEditBladeHeaderProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Management.Accounts.Roles.Permissions']
  })

  const { storeManagementRbacRoles } = useStores()

  return (
    <ContainerFlex
      name="RolesEditHeader"
      itemsFlexGrow={[1]}
      items={[
        <InputSearch
          placeholder={translate('Search an entity')}
          storeInputSearch={
            storeManagementRbacRoles.storeInputSearchRbacEntityItems
          }
        />,

        <CheckboxOnlyGrantedPermissions />
      ]}
      spaced
    />
  )
}

export default RolesEditHeader
