import type { EntityTopologyInfrastructure } from '@app/entities'
import { SCENE_TOOLIP_DEBOUNCE_DELAY } from '@app/stores/Topology/consts'
import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'
import type SceneObject from './SceneObject'

interface IInfrastructureShapeProps {
  infrastructureSceneObject: SceneObject<EntityTopologyInfrastructure>
  className?: string
  isHighlighted?: boolean
  isTransparent?: boolean
}

const InfrastructureShape: React.FC<IInfrastructureShapeProps> = props => {
  const { x, y, radius } = props.infrastructureSceneObject.coordinates

  return <circle className={props.className} cx={x} cy={y} r={radius} />
}

export default styled(InfrastructureShape)`
  stroke: ${props =>
    props.infrastructureSceneObject.object.known
      ? consts.colorBlue005
      : consts.colorBlueGray015};
  stroke-width: 1;
  stroke-opacity: ${props => (props.isTransparent ? '0.2' : '1')};
  fill: ${props =>
    props.isHighlighted ? consts.colorBlue005 : consts.colorWhite};
  fill-opacity: 0.8;
  transition: all ${SCENE_TOOLIP_DEBOUNCE_DELAY}ms linear;

  &:hover {
    stroke-width: 2;
    stroke-opacity: 1;
  }
`
