import type { PropertiesNullable } from '@@types/helpers'
import EntityBase from '@app/entities/EntityBase'
import type {
  IEntityListable,
  IEntityOmitKeysParameter
} from '@app/entities/types'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import type {
  Maybe,
  RbacPermission,
  RbacRole
} from '@server/graphql/typeDefs/types'
import { createEntities, EntityRbacPermission } from '.'

export interface IDataRowRbacRole {
  id: number
  name: string
  description: string
}

export default class EntityRbacRole
  extends EntityBase
  implements PropertiesNullable<RbacRole>, IEntityListable<IDataRowRbacRole>
{
  id: Maybe<number> = null
  name: Maybe<string> = null
  description: Maybe<string> = null
  permissions: RbacPermission[] = []

  constructor(data: Partial<RbacRole>) {
    super()
    Object.assign(this, data)
  }

  /**
   * Return permissions as entities.
   */
  getPermissions(): EntityRbacPermission[] {
    return createEntities<RbacPermission, EntityRbacPermission>(
      EntityRbacPermission,
      this.permissions
    )
  }

  /* Implements IEntityListable */

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowRbacRole> = []
  ): Array<IWidgetListColumns<IDataRowRbacRole>> {
    const columns: Array<IWidgetListColumns<IDataRowRbacRole>> = [
      {
        label: 'Role name',
        key: 'name'
      },
      {
        label: 'Role description',
        key: 'description'
      },
      {
        label: 'ID',
        key: 'id'
      }
    ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  asDataRow(): IDataRowRbacRole {
    return {
      id: this.getPropertyAsNumber('id'),
      name: this.getPropertyAsString('name'),
      description: this.getPropertyAsString('description')
    }
  }
}
