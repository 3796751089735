import { Features } from '@alsid/common'
import { ContainerFlex, ContainerSimple } from '@app/components/Container'
import { IconBackgroundCircle } from '@app/components/Icon'
import type { IContainerSimpleProps } from '@app/components-legacy/Container/ContainerSimple'
import FormWrapperButtonSimple from '@app/components-legacy/Form/Wrappers/ButtonSimple'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import { consts } from '@app/styles'
import { TenableButtonsColor } from '@app/styles/consts'
import { filterEmptyProps } from '@libs/react-helpers/filterEmptyProps'
import * as React from 'react'
import styled from 'styled-components'

const StyledContainerFlex = styled(ContainerFlex)<{
  isTulEnabled: boolean
  isTulV6Enabled: boolean
}>`
  // for badges
  position: relative;

  height: ${consts.headerHeight};

  padding: 0
    ${props => {
      if (props.isTulV6Enabled) {
        return 0
      }

      if (props.isTulEnabled) {
        return '8px'
      }

      return consts.paddingDefault
    }};
`

const IconBackground = styled(IconBackgroundCircle)`
  display: none;
  position: absolute;
  z-index: 0;
  left: 0px;
  top: 8px;
`

interface IWidgetContainerProps extends IContainerSimpleProps {
  labelledBy: string
  title?: string
  activated?: boolean
  onClick?: () => void
  children?: React.ReactNode
  isBackgroundIcon?: boolean
}

const WidgetContainer = React.forwardRef<HTMLDivElement, IWidgetContainerProps>(
  (props, ref) => {
    const {
      ariaRoles,
      className,
      labelledBy,
      title,
      activated,
      onClick,
      children,
      isBackgroundIcon,
      ...rest
    } = props

    const isTulEnabled = useFeatureFlag({
      featureFlagName: Features.TENABLE_UNIVERSAL_LAYOUT
    })
    const isTulV6Enabled = useFeatureFlag({
      featureFlagName: Features.KAPTEYN_TUL_V6
    })

    const getItems = () => {
      if (!isBackgroundIcon || isTulV6Enabled) {
        return React.Children.toArray(children)
      }

      return (
        <>
          <IconBackground className="iconBackground" />
          {React.Children.toArray(children)}
        </>
      )
    }
    return (
      <FormWrapperButtonSimple
        ref={ref}
        className={className}
        ariaRoles={ariaRoles}
        labelledBy={labelledBy}
        onClick={onClick}
        {...rest}
      >
        <ContainerSimple
          containerProps={filterEmptyProps({
            title
          })}
        >
          <StyledContainerFlex
            name={WidgetContainer.name}
            alignItems="center"
            items={getItems()}
            isTulEnabled={isTulEnabled}
            isTulV6Enabled={isTulV6Enabled}
          />
        </ContainerSimple>
      </FormWrapperButtonSimple>
    )
  }
)

export default styled(WidgetContainer)`
  height: ${consts.headerHeight};

  color: white;
  font-size: ${consts.iconDefaultSize};

  .iconBackground {
    display: ${props => (props.activated === true ? 'block' : 'none')};
  }

  .iconBackground circle {
    fill: ${props =>
      props.activated === true
        ? TenableButtonsColor.backgroundButtonFloatingSelected
        : TenableButtonsColor.backgroundButtonFloatingHover};
  }

  ${props => {
    if (props.onClick) {
      return `
        &:hover .iconBackground,
        &:active .iconBackground,
        &:focus:not([data-focus-method='mouse']) .iconBackground {
          display: block;
        }

        &:hover .iconBackground circle,
        &:focus:not([data-focus-method='mouse']) .iconBackground circle {
          fill: ${TenableButtonsColor.backgroundButtonFloatingHover};
        }

        &:active .iconBackground circle {
          fill: ${TenableButtonsColor.backgroundButtonFloatingSelected};
        }

        .iconBackground + svg {
          z-index: 1;
        }

        cursor: pointer;
      `
    }

    return ''
  }};
`
