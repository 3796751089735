import { createEntities } from '@app/entities'
import type EntityDashboardWidget from '@app/entities/EntityDashboardWidget'
import EntityDashboardWidgetOptionsSerie from '@app/entities/EntityDashboardWidgetOptionsSerie'
import type { StoreRoot } from '@app/stores'
import { StoreInfrastructures } from '@app/stores'
import { explodeWidgetKey } from '@libs/dashboards/keys'
import type { WidgetKey } from '@libs/dashboards/types'
import type { InstanceName } from '@libs/Environment/types'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import type { QueryRbacDashboardsWidgetsStats } from '@server/graphql/queries/dashboard'
import { queryRbacDashboardsWidgetsStats } from '@server/graphql/queries/dashboard'
import type {
  DashboardWidgetOptionsSerie,
  Maybe,
  RbacDashboardsQueryArgs
} from '@server/graphql/typeDefs/types'
import { difference, flatMap } from 'lodash'
import type { IObservableValue } from 'mobx'
import { action, computed, makeObservable, observable } from 'mobx'
import StoreFlags from '../helpers/StoreFlags'
import StoreBase from '../StoreBase'
import type { IStoreMultiInstanceOptions } from '../types'
import { getFirstDashboardWidget } from './functions'

export interface IStoreWidgetOptions extends IStoreMultiInstanceOptions {
  widgetEntity: EntityDashboardWidget
}

export default class StoreWidget extends StoreBase<IStoreWidgetOptions> {
  public storeInfrastructures = new StoreInfrastructures(
    this.storeRoot,
    this.options
  )

  public storeFlagsFetchWidgetData = new StoreFlags(this.storeRoot)

  /* Observables */

  // entity of this widget
  private $entityWidget: IObservableValue<EntityDashboardWidget>

  private $series = observable.box<EntityDashboardWidgetOptionsSerie[]>([])

  constructor(storeRoot: StoreRoot, options: IStoreWidgetOptions) {
    super(storeRoot, options)

    this.$entityWidget = observable.box(this.options.widgetEntity)

    makeObservable(this)
  }

  /**
   * Set the instanceName used for this store.
   */
  setInstanceName(instanceName: InstanceName): void {
    this.setOptions({
      instanceName
    })

    this.storeInfrastructures.setOptions({
      instanceName
    })
  }

  /**
   * Fetch data of the widget.
   */
  fetchData(widgetKey: WidgetKey): Promise<void> {
    this.storeFlagsFetchWidgetData.loading()

    return Promise.resolve()
      .then(() => this.storeInfrastructures.fetchInfrastructures())
      .then(() => {
        const ids = explodeWidgetKey(widgetKey)

        if (!ids) {
          throw new Error('Unable to find the widget')
        }

        const profileId =
          this.storeRoot.stores.storeAuthentication.currentProfileId

        const args: RbacDashboardsQueryArgs = {
          profileId,
          dashboardId: ids.dashboardId,
          dashboardWidgetId: ids.dashboardWidgetId
        }

        return this.storeRoot
          .getGQLRequestor(this.instanceName)
          .query<QueryRbacDashboardsWidgetsStats>(
            queryRbacDashboardsWidgetsStats,
            args
          )
      })
      .then(data => data.rbacDashboards)
      .then(
        getFirstDashboardWidget(this.storeRoot, this.storeFlagsFetchWidgetData)
      )
      .then(widget => widget.options.series)
      .then(series => {
        if (!series) {
          throw new Error('Series are not defined')
        }

        const serieEntities = createEntities<
          DashboardWidgetOptionsSerie,
          EntityDashboardWidgetOptionsSerie
        >(EntityDashboardWidgetOptionsSerie, series)

        this.setSeries(serieEntities)

        this.storeFlagsFetchWidgetData.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsFetchWidgetData))
  }

  get instanceName(): InstanceName {
    return (
      this.options.instanceName ??
      this.storeRoot.environment.getFirstInstanceName()
    )
  }

  /* Actions */

  /**
   * Clean everything.
   */
  @action
  reset() {
    this.$series.set([])
  }

  @action
  setSeries(series: EntityDashboardWidgetOptionsSerie[]): void {
    this.$series.set(series)
  }

  /**
   * Set the widget entity.
   */
  @action
  setWidgetEntity(widgetEntity: EntityDashboardWidget): void {
    this.$entityWidget.set(widgetEntity)
  }

  /* Computed */

  /**
   * Return the series.
   */
  @computed
  get series(): Maybe<EntityDashboardWidgetOptionsSerie[]> {
    return this.$series.get().slice(0)
  }

  /**
   * Return all directories of the series.
   * If the result is an empty array, return null meaning that
   * the configuration is for all directories.
   */
  @computed
  get directoryIds(): Maybe<number[]> {
    if (!this.series) {
      return null
    }

    const allDirectoryIds = flatMap<number>(
      this.series
        .map(serie => serie.stats && serie.stats.directoryIds)
        .filter(isDefined)
    )

    if (!allDirectoryIds.length) {
      return null
    }

    // unify the list
    return [...new Set(allDirectoryIds)]
  }

  /**
   * Return the widget entity.
   */
  @computed
  get widgetEntity(): EntityDashboardWidget {
    return this.$entityWidget.get()
  }

  /**
   * Return true if some series have a directory that is not in the list
   * of known directories.
   * It could be the case if the directory has been removed.
   */
  @computed
  get hasSerieWithUnknownDirectory(): boolean {
    return (
      difference(this.directoryIds, this.storeInfrastructures.getDirectoryIds())
        .length !== 0
    )
  }
}
