import { ContainerFlex } from '@app/components/Container'
import { CardTitle } from '@app/components-legacy/Card/CardTitle'
import { ContainerDividerFilter } from '@app/components-legacy/Container'
import * as React from 'react'

interface ICardTitleWithIconsProps {
  style?: React.CSSProperties
  className?: string
  title?: React.ReactNode
  preIconsElements?: React.ReactNode[]
  icons?: React.ReactNode[]
}

export function CardTitleWithIcons(props: ICardTitleWithIconsProps) {
  return (
    <ContainerFlex
      className={props.className}
      style={props.style}
      name="CardTitleWithIcons"
      justifyContent="space-between"
      alignItems="center"
      items={[
        <ContainerFlex
          name="CardTitleWithIconsLeft"
          items={[<CardTitle>{props.title}</CardTitle>]}
        />,

        <ContainerFlex
          name="CardTitleWithIconsRight"
          alignItems="center"
          items={[
            <ContainerFlex
              name="CardTitleWithIconsRightPreIcons"
              alignItems="center"
              items={props.preIconsElements}
              flexGap="smaller"
            />,

            props.icons && <ContainerDividerFilter />,

            props.icons && (
              <ContainerFlex
                name="CardTitleWithIconsRightIcons"
                alignItems="center"
                items={props.icons}
                flexGap="small"
              />
            )
          ]}
          flexGap="smaller"
        />
      ]}
      flexGap="smaller"
    />
  )
}
