import { FormWrapperSelect } from '@app/components-legacy/Form'
import type { IFormWrapperSelectProps } from '@app/components-legacy/Form/Wrappers/Select'
import type StoreForm from '@app/stores/helpers/StoreForm'
import { onSelectChange } from '@app/stores/helpers/StoreForm/handlers'
import type { FieldName } from '@app/stores/helpers/StoreForm/types'
import { ensureArray } from '@libs/ensureArray'
import type { SelectValue } from 'antd/lib/select'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface IWidgetFormSelectProps<T = SelectValue> {
  /** Field name */
  fieldName: FieldName
  /** Input id */
  inputId: string
  /** Props that will be passed to the rendered component */
  inputProps?: IFormWrapperSelectProps<T>
  /** StoreForm to get data */
  storeForm: StoreForm<any>
}

/**
 * @deprecated
 */
export function WidgetFormSelect<T = SelectValue>(
  props: IWidgetFormSelectProps<T>
): React.ReactElement {
  return (
    <FormWrapperSelect<any>
      labelledBy={props.inputProps?.labelledBy ?? props.fieldName}
      selectProps={{
        id: props.inputId,
        value: props.storeForm.getFieldValueAsString(props.fieldName),
        onChange: onSelectChange(props.storeForm)(props.fieldName),
        ...props.inputProps?.selectProps
      }}
      selectOptions={ensureArray(props.inputProps?.selectOptions)}
    />
  )
}

export default observer(WidgetFormSelect)
