import { useStoreMenuInit } from '@app/components-legacy/Menu/hooks'
import Navigation from '@app/components-legacy/Navigation'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { AppRouteName } from '@app/routes'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { MenuEntryType } from '@app/stores/helpers/StoreMenu/types'
import * as React from 'react'

enum LegalNavigationMenuKey {
  'End User' = 'End User',
  'MITRE ATT&CK' = 'MITRE ATT&CK',
  'Envoy' = 'Envoy'
}

interface ILegalNavigationProps {}

const LegalNavigation: React.FC<ILegalNavigationProps> = () => {
  const translate = useAppTranslator({
    namespaces: ['Management.System.Legal']
  })

  const storeMenu = useStoreMenuInit<IMenuEntry<LegalNavigationMenuKey>>({
    menuEntries: [
      {
        key: LegalNavigationMenuKey['End User'],
        label: translate('End User License Agreement'),
        labelledBy: 'End User License Agreement',
        routeDefinition: {
          routeName: AppRouteName.Management_System_Legal_Eula,
          parameters: {}
        },
        type: MenuEntryType.link
      },
      {
        key: LegalNavigationMenuKey['MITRE ATT&CK'],
        label: translate('MITRE ATT&CK® license'),
        labelledBy: 'MITRE ATT&CK® license',
        routeDefinition: {
          routeName: AppRouteName.Management_System_Legal_MitreAttack,
          parameters: {}
        },
        type: MenuEntryType.link
      },
      {
        key: LegalNavigationMenuKey.Envoy,
        label: translate('Envoy license'),
        labelledBy: 'Envoy license',
        routeDefinition: {
          routeName: AppRouteName.Management_System_Legal_Envoy,
          parameters: {}
        },
        type: MenuEntryType.link
      }
    ]
  })

  return <Navigation storeMenu={storeMenu} />
}

export default LegalNavigation
