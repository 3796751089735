import { ContainerFlex } from '@app/components/Container'
import {
  ContainerContent,
  ContainerRbac
} from '@app/components-legacy/Container'
import { TFlexValuePreset } from '@app/components-legacy/Container/ContainerFlex/types'
import { LabelMissingData } from '@app/components-legacy/Label'
import { useStores } from '@app/hooks'
import { ensureArray } from '@productive-codebases/toolbox'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CheckerIdentityDetailsPageContext } from '../context'
import {
  canAccessToIoEIdentityFindings,
  canReadIoEIdentityInformation
} from '../permissions'
import ImpactedTenants from './ImpactedTenants'
import Information from './Information'
import Resources from './Resources'

interface IDetailsInformationsPageProps {}

const DetailsInformationsPage: React.FC<
  IDetailsInformationsPageProps
> = props => {
  const { storeIoE } = useStores()

  const parameters = React.useContext(CheckerIdentityDetailsPageContext)

  if (!parameters) {
    return <LabelMissingData />
  }

  const checker = storeIoE.checkersIdentity.get(parameters.checkerCodename)

  if (!checker) {
    return <LabelMissingData />
  }

  return (
    <ContainerContent rbacCapabilityCheck={canReadIoEIdentityInformation}>
      <ContainerFlex
        name="DetailsInformationsPage"
        flexGap="extraLarge"
        itemsFlexGrow={[1, 1]}
        items={[
          <ContainerFlex
            name="DetailsInformationPageLeftColumn"
            direction="column"
            flexValuePreset={TFlexValuePreset.twoColumns}
            items={[
              <Information />,
              <Resources resources={ensureArray(checker.resources)} />
            ]}
            wrapItems
            spaced
            spaceWidth="default"
            borderPadding="default"
          />,

          <ContainerFlex
            name="DetailsInformationPageRightColumn"
            items={[
              <ContainerRbac
                rbacCapabilityCheck={canAccessToIoEIdentityFindings}
              >
                <ImpactedTenants />
              </ContainerRbac>
            ]}
            wrapItems
            spaced
            spaceWidth="default"
            borderPadding="default"
          />
        ]}
      />
    </ContainerContent>
  )
}

export default observer(DetailsInformationsPage)
