import {
  ContainerContent,
  ContainerHTMLContent
} from '@app/components-legacy/Container'
import { LabelMissingData } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { mdToHTML } from '@libs/mdToHTML'
import * as React from 'react'
import { CheckerIdentityDetailsPageContext } from '../context'

export interface IInformationProps {}

const Information: React.FC<IInformationProps> = props => {
  const parameters = React.useContext(CheckerIdentityDetailsPageContext)

  const translate = useAppTranslator({
    namespaces: ['Buttons', 'IoE.Details.Recommendations']
  })

  const { storeIoE } = useStores()

  if (!parameters) {
    return null
  }

  const renderContent = () => {
    const checker = storeIoE.checkersIdentity.get(parameters.checkerCodename)

    if (!checker) {
      return <LabelMissingData />
    }

    if (!checker.vulnerabilityDetail) {
      return <LabelMissingData />
    }

    return (
      <ContainerHTMLContent labelledBy="devianceVulnerabilityDetail" padded>
        {mdToHTML(checker.vulnerabilityDetail)}
      </ContainerHTMLContent>
    )
  }

  return (
    <ContainerContent title={translate('Executive summary')}>
      {renderContent()}
    </ContainerContent>
  )
}

export default Information
