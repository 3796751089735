import { LabelNonBreakable } from '@app/components-legacy/Label'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreInputGenericCheckers from '@app/stores/helpers/StoreInputGenericCheckers'
import type { TGenericChecker } from '@app/stores/helpers/StoreInputGenericCheckers/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

interface ICheckersSelectionCounterProps<GC extends TGenericChecker> {
  storeInputGenericCheckers: StoreInputGenericCheckers<GC>
}

/**
 * @deprecated
 */
function CheckersSelectionCounter<GC extends TGenericChecker>(
  props: ICheckersSelectionCounterProps<GC>
): React.ReactElement {
  const translate = useAppTranslator({
    namespaces: ['Components.InputCheckers']
  })

  return (
    <LabelNonBreakable>
      {translate('Checkers selected', {
        interpolations: {
          count: props.storeInputGenericCheckers.selectedCheckers.length,
          allCount: props.storeInputGenericCheckers.checkersById.size
        }
      })}
    </LabelNonBreakable>
  )
}

export default observer(CheckersSelectionCounter)
