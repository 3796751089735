import Label from '@app/components/Labels/Label'
import { LabelVariant } from '@app/components/Labels/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import React from 'react'
import styled from 'styled-components'

interface ITableBodyNoResultProps {
  className?: string
}

const TableBodyNoResult: React.FC<ITableBodyNoResultProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const translate = useAppTranslator({ namespaces: ['Components.WidgetList'] })

  return (
    <div className={props.className}>
      {themeIsLegacy ? (
        translate('No result')
      ) : (
        <Label variant={LabelVariant.p}>{translate('No result')}</Label>
      )}
    </div>
  )
}

export default styled(TableBodyNoResult)`
  margin: ${consts.marginSmall};
`
