import type { IDataRowDirectory } from '@app/entities/EntityDirectory'
import type { StoreManagementDirectories } from '@app/stores'

/**
 * Delete a directory, close the drawer and reload directories.
 */
export const onDirectoryDeleteValidate =
  (storeManagementDirectories: StoreManagementDirectories) =>
  (dataRow: IDataRowDirectory) =>
  (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    storeManagementDirectories
      .deleteDirectory(dataRow.id, dataRow.name)
      .then(() => {
        storeManagementDirectories.storeDeleteDrawer.closeDrawer()
        return storeManagementDirectories.fetchDirectories()
      })
      .catch(() => {
        // already catched in the store
      })
  }
