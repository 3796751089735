import { ContainerFlex } from '@app/components/Container'
import { Label } from '@app/components/Labels'
import { BladeCancelButton } from '@app/components-legacy/Blade'
import {
  ContainerContent,
  ContainerFooter,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { FormWrapper } from '@app/components-legacy/Form/Wrappers'
import FormWrapperButtonSubmit from '@app/components-legacy/Form/Wrappers/ButtonSubmit'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks'
import type StoreDashboard from '@app/stores/Dashboard/StoreDashboard'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import { WidgetCommonFormFieldName } from './types'
import WidgetInputIllustration from './WidgetInput/WidgetInputIllustration'
import WidgetInputTitle from './WidgetInput/WidgetInputTitle'
import WidgetInputsWidgetTypeSwitch from './WidgetInputsWidgetType/WidgetInputsWidgetTypeSwitch'

export interface IWidgetCommonFormProps {
  version: 'creation' | 'edition'
  onCancelButtonClick: () => void
  onSubmit: (e: React.FormEvent<any>) => void
  storeDashboard: StoreDashboard
}

const Div = styled.div`
  margin-top: ${consts.marginSmall};
`

const LabelCustom = styled(Label)`
  color: ${consts.FontColorV2.secondary};
`

const WidgetCommonForm: React.FC<IWidgetCommonFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Titles', 'Buttons', 'Dashboard.WidgetCommonForm']
  })

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const { storeWidgetsManagement } = props.storeDashboard

  // Place this condition to a const to force mobx to re-render the view
  // Related to https://jira.eng.tenable.com/browse/AD-6783
  const canSubmit = storeWidgetsManagement.isAdditionOrEditionFormSubmittable

  /**
   * Render widgets type illustrations.
   */
  const renderWidgetTypeFields = () => {
    const { storeForm } = storeWidgetsManagement

    const fields: IField[] = [
      {
        name: 'widgetType',
        label: themeIsLegacy ? translate('Type of the widget') : undefined,
        labelAlignItem: 'end',
        control: <WidgetInputIllustration storeForm={storeForm} />
      }
    ]

    if (storeWidgetsManagement.isWidgetTypeSelected) {
      fields.push({
        name: 'widgetName',
        label: translate('Name of the widget'),
        errors: storeForm.field(WidgetCommonFormFieldName.title).errors,
        control: <WidgetInputTitle storeForm={storeForm} />
      })
    }

    return (
      <ContainerContent
        title={translate(themeIsLegacy ? 'Main information' : 'Add widget')}
      >
        {!themeIsLegacy && (
          <Div>
            <LabelCustom>{translate('Select the type of widget')}</LabelCustom>
          </Div>
        )}
        <ContainerForm
          version={props.version}
          fields={fields}
          spaceWidth={themeIsLegacy ? 'small' : 'medium'}
        />
      </ContainerContent>
    )
  }

  return (
    <FormWrapper name="widgetConfiguration" onSubmit={props.onSubmit}>
      <>
        <ContainerFlex
          name="WidgetCommonForm"
          direction="column"
          items={[
            renderWidgetTypeFields(),

            storeWidgetsManagement.isWidgetTypeSelected && (
              <WidgetInputsWidgetTypeSwitch
                version={props.version}
                storeWidgetsManagement={storeWidgetsManagement}
              />
            )
          ]}
          flexGap={themeIsLegacy ? 'default' : 'medium'}
        />

        <Portal name={PlaceHolderName.bladeFooter}>
          <ContainerFooter>
            {themeIsLegacy && <BladeCancelButton />}

            <FormWrapperButtonSubmit
              type="primary"
              disabled={!canSubmit}
              loading={storeWidgetsManagement.storeFlags.isLoading}
            >
              {themeIsLegacy
                ? translate(props.version === 'creation' ? 'Add' : 'Edit')
                : translate(
                    props.version === 'creation' ? 'Add widget' : 'Edit'
                  )}
            </FormWrapperButtonSubmit>
          </ContainerFooter>
        </Portal>
      </>
    </FormWrapper>
  )
}

export default observer(WidgetCommonForm)
