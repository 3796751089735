import type { IGraphQLQuery } from '@libs/graphQL/types'
import type { Query } from '../typeDefs/types'

/**
 * Lockout Policy
 */

export type QueryLockoutPolicy = IGraphQLQuery<
  null,
  Pick<Query, 'rbacLockoutPolicy'>
>

export const queryLockoutPolicy = `
  query queryLockoutPolicy {
    rbacLockoutPolicy {
      node {
        enabled
        lockoutDuration
        failedAttemptThreshold
        failedAttemptPeriod
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
