import { AppRouteName } from '@app/routes'
import type { StoreBlades, StoreManagementDirectories } from '@app/stores'
import type { AppRouterClient } from '@libs/Router/types'
import type { InputUpdateHoneyAccount } from '@server/graphql/typeDefs/types'
import { HoneyAccountFormFieldName } from '../HoneyAccountCreatePage/types'

/**
 * Initialize the form of the edition of a Honey Account.
 */
export const onHoneyAccountEditLoad =
  (storeManagementDirectories: StoreManagementDirectories) =>
  (directoryId: number) =>
  () => {
    Promise.resolve()
      .then(() => storeManagementDirectories.storeInputSearch.reset())
      .then(() => storeManagementDirectories.clearHoneyAccountCandidates())
      // Ensure to edit an updated directory.
      .then(() => storeManagementDirectories.fetchDirectories())
      .then(() => {
        return !storeManagementDirectories.honeyAccountConfigurationScript.get(
          directoryId
        )
          ? storeManagementDirectories.getHoneyAccountConfigurationScript({
              directoryId
            })
          : null
      })
      .then(() => {
        const entity = storeManagementDirectories.directories.get(directoryId)

        if (!entity) {
          return
        }

        const name = entity.getPropertyAsString('honeyAccountDistinguishedName')

        storeManagementDirectories.storeFormHoneyAccount
          .setDefaultFieldsValuesFromObject({
            [HoneyAccountFormFieldName.name]: name
          })
          .reset()

        storeManagementDirectories.storeInputSearch.setSearchValue(name)
      })
  }

/**
 * Edit the Honey Account,
 * And go back to the list.
 */
export const onHoneyAccountEditSubmit =
  (
    appRouter: AppRouterClient,
    storeManagementDirectories: StoreManagementDirectories
  ) =>
  (directoryId: number) =>
  (e: React.FormEvent<any>) => {
    const { storeFormHoneyAccount } = storeManagementDirectories!

    e.preventDefault()

    if (!storeFormHoneyAccount.validate()) {
      storeFormHoneyAccount.storeRoot.stores.storeMessages.validationError()
      return
    }

    const honeyAccount: InputUpdateHoneyAccount = {
      directoryId,
      honeyAccountDistinguishedName:
        storeFormHoneyAccount.getFieldValueAsString(
          HoneyAccountFormFieldName.name
        )
    }

    storeManagementDirectories.updateHoneyAccount(honeyAccount).then(() => {
      const url = appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.Management_System_Directories,
        parameters: {}
      })
      appRouter.history.push(url)
    })
  }

/**
 * Delete the Honey Account,
 * And go back to the list.
 */
export const onHoneyAccountDeleteSubmit =
  (
    appRouter: AppRouterClient,
    storeManagementDirectories: StoreManagementDirectories
  ) =>
  (directoryId: number) =>
  () => {
    storeManagementDirectories.deleteHoneyAccount(directoryId).then(() => {
      const url = appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.Management_System_Directories,
        parameters: {}
      })
      appRouter.history.push(url)
    })
  }

/**
 * Redirect to the previous view.
 */
export const onHoneyAccountEditCancelClick =
  (storeBlades: StoreBlades) => () => {
    const url = storeBlades.storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Management_System_Directories,
      parameters: {}
    })

    storeBlades.goToBladeUrl(url)
  }
