import { ContainerFlex } from '@app/components-legacy/Container'
import type StoreMenu from '@app/stores/helpers/StoreMenu'
import type { IMenuEntry } from '@app/stores/helpers/StoreMenu/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useMenuEntriesSelection } from '../Menu/hooks'
import type { INavigationMenuProps } from '../Menu/Menu'
import NavigationEntry from './NavigationEntry'

export interface INavigationProps extends INavigationMenuProps<any> {
  className?: string
  storeMenu: StoreMenu<IMenuEntry<any>>
}

const Navigation: React.FC<INavigationProps> = props => {
  useMenuEntriesSelection(props.storeMenu)

  return (
    <ContainerFlex
      name="Navigation"
      className={props.className}
      direction="column"
      items={props.storeMenu.menuEntries.map(entry => {
        return (
          <NavigationEntry
            key={entry.key}
            isSelected={entry.key === props.storeMenu.selectedMenuKey}
            menuEntry={entry}
          />
        )
      })}
    />
  )
}

export default observer(Navigation)
