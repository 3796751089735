import { FormWrapper } from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppRouter } from '@app/hooks/useAppRouter'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import {
  isRbacAdminRole,
  isRoleReadOnly
} from '@app/stores/Management/Rbac/functions'
import * as React from 'react'
import RoleFormMainInformation from '../RolesCreatePage/RoleFormMainInformation'
import { handleEditRoleApplyOnSubmit } from './handlers'
import RoleEditEntitiesLists from './RoleEditEntitiesLists'
import RolesEditFooter from './RolesEditFooter'

interface IRoleEditFormProps {}

const RoleEditForm: React.FC<IRoleEditFormProps> = props => {
  const appRouter = useAppRouter()

  const { storeManagementRbacRoles, storeRbac } = useStores()

  const parameters = appRouter.getRouteParameters({
    routeName: AppRouteName.Management_Accounts_Roles_Edit,
    parameters: {
      roleId: Number()
    }
  })

  if (!parameters) {
    return null
  }

  const role = storeManagementRbacRoles.currentRbacRole

  const isReadableOnly =
    isRbacAdminRole(role) || isRoleReadOnly(parameters.roleId, storeRbac)

  return (
    <FormWrapper
      name="rolesEdition"
      onSubmit={handleEditRoleApplyOnSubmit(storeManagementRbacRoles)(
        parameters.roleId
      )}
    >
      <>
        <Portal name={PlaceHolderName.bladeHeader}>
          <RoleFormMainInformation />
        </Portal>

        <RoleEditEntitiesLists disabled={isReadableOnly} />

        <Portal name={PlaceHolderName.bladeFooter}>
          {!isReadableOnly && <RolesEditFooter />}
        </Portal>
      </>
    </FormWrapper>
  )
}

export default RoleEditForm
