import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

const StyledPath = styled.path`
  fill: ${consts.colorBlue005};
  clip-rule: evenodd;
  fill-rule: evenodd;
  transform: matrix(0.968988, 0, 0, 0.968988, 0.841981, 0.87704);
`

interface IIconDcTriangleProps {
  className?: string
}

const IconDcTriangle: React.FC<IIconDcTriangleProps> = props => {
  return (
    <svg
      className={props.className}
      viewBox="0 0 23 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPath
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.867,19.666L11,-0.832L-0.867,19.666L22.867,19.666ZM11,1.163L21.133,18.666L0.866,18.666L11,1.163ZM13.655,14.213C13.975,14.213 14.269,14.174 14.538,14.096L14.538,13.406C14.264,13.481 13.991,13.518 13.717,13.518C13.296,13.518 12.983,13.423 12.78,13.232C12.577,13.041 12.475,12.735 12.475,12.313C12.475,11.898 12.578,11.595 12.785,11.402C12.992,11.21 13.315,11.113 13.755,11.113C14.025,11.113 14.286,11.143 14.538,11.201L14.538,10.508C14.268,10.448 13.985,10.418 13.69,10.418C13.237,10.418 12.853,10.491 12.539,10.636C12.225,10.781 11.987,10.996 11.825,11.28C11.663,11.564 11.583,11.912 11.583,12.323C11.583,13.583 12.273,14.213 13.655,14.213ZM10.086,14.005C9.74,14.136 9.301,14.201 8.767,14.201C8.396,14.201 8.033,14.179 7.68,14.136L7.68,10.558C7.86,10.518 8.065,10.487 8.295,10.463C8.525,10.44 8.745,10.428 8.955,10.428C9.675,10.428 10.216,10.583 10.579,10.893C10.941,11.203 11.123,11.678 11.123,12.316C11.123,12.758 11.036,13.117 10.864,13.393C10.691,13.67 10.432,13.874 10.086,14.005ZM8.535,13.501C8.645,13.513 8.797,13.518 8.99,13.518C9.28,13.518 9.517,13.477 9.702,13.395C9.887,13.312 10.025,13.183 10.115,13.006C10.205,12.829 10.25,12.598 10.25,12.311C10.25,11.893 10.148,11.587 9.944,11.395C9.74,11.202 9.424,11.106 8.997,11.106C8.861,11.106 8.707,11.119 8.535,11.146L8.535,13.501Z"
      />
    </svg>
  )
}

export default styled(IconDcTriangle)`
  width: 23px;
  height: 20px;
  fill: none;
`
