import { Blade } from '@app/components-legacy/Blade'
import { ContainerContent } from '@app/components-legacy/Container'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import { useStores } from '@app/hooks/useStores'
import { AppRouteName } from '@app/routes'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { canCreateInfrastructures } from '../permissions'
import { onInfrastructuresCreatePageLoad } from './handlers'
import InfrastructureCreateForm from './InfrastructureCreateForm'

interface IInfrastructuresCreatePageProps {}

const InfrastructuresCreatePage: React.FC<
  IInfrastructuresCreatePageProps
> = () => {
  const { storeManagementInfrastructures } = useStores()

  return (
    <Blade
      routeDefinition={{
        routeName: AppRouteName.Management_System_Infrastructures_Create,
        parameters: {}
      }}
      flags={storeManagementInfrastructures.storeFlags.flags}
      layout={{
        name: 'default',
        content: (
          <ContainerContent>
            <InfrastructureCreateForm />
          </ContainerContent>
        ),
        footer: <PortalPlaceHolder name={PlaceHolderName.bladeFooter} />
      }}
      onLoad={onInfrastructuresCreatePageLoad(storeManagementInfrastructures)}
      rbacCapabilityCheck={canCreateInfrastructures}
    />
  )
}

export default observer(InfrastructuresCreatePage)
