import type { IGraphQLQuery } from '@libs/graphQL/types'
import type { Query } from '../typeDefs/types'

/**
 * License
 */

export type QueryLicenseData = IGraphQLQuery<null, Pick<Query, 'rbacLicense'>>

export const queryLicense = `
  query queryLicense {
    rbacLicense {
      node {
        customerName
        maxActiveUserCount
        currentActiveUserCount
        type
        features
        expirationDateUTC
        inAppEula
        activationCode
        containerUuid
        productAssociation
      }
      rbacCapability {
        isGranted
        isFound
        message
        rbacEntityName
      }
    }
  }
`
