import type { PropertiesNullable } from '@@types/helpers'
import type {
  DashboardWidgetOptionsSerie,
  DashboardWidgetOptionsSerieDataOptions,
  DashboardWidgetOptionsSerieDisplayOptions,
  DashboardWidgetOptionsSerieStat,
  Maybe
} from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'

export default class EntityDashboardWidgetOptionsSerie
  extends EntityBase
  implements PropertiesNullable<DashboardWidgetOptionsSerie>
{
  dataOptions: Maybe<DashboardWidgetOptionsSerieDataOptions> = null
  displayOptions: Maybe<DashboardWidgetOptionsSerieDisplayOptions> = null
  stats: Maybe<DashboardWidgetOptionsSerieStat> = null

  constructor(data: Partial<DashboardWidgetOptionsSerie>) {
    super()
    Object.assign(this, data)
  }
}
