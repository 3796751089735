import {
  ContainerContent,
  ContainerFlex,
  ContainerForm
} from '@app/components-legacy/Container'
import type { IField } from '@app/components-legacy/Container/ContainerForm/types'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { ProfileFieldName } from '@app/pages/Management/AccountsPage/Profiles/ProfilesCreatePage/types'
import { onInputChange } from '@app/stores/helpers/StoreForm/handlers'
import { Alert, Input } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleProfileNameFieldOnBlur } from './handlers'
import ProfileAlertReadOnlyProfile from './ProfileAlertReadOnlyProfile'

interface IProfileEditFormMainFieldsProps {}

const ProfileEditFormMainFields: React.FC<
  IProfileEditFormMainFieldsProps
> = () => {
  const translate = useAppTranslator({
    namespaces: [
      'Titles',
      'Buttons',
      'Management.Accounts.Profiles.Edition',
      'Management.Accounts.Profiles.Creation'
    ]
  })
  const { storeAuthentication, storeManagementProfiles } = useStores()

  function getFields(): IField[] {
    const { storeFormEdition } = storeManagementProfiles

    const isCurrentProfile =
      storeAuthentication.currentProfileId ===
      storeManagementProfiles.currentProfileId

    const disabled =
      storeManagementProfiles.isCurrentProfileReadOnly || isCurrentProfile

    const description = isCurrentProfile
      ? translate('You cant edit the name of the current profile')
      : translate(
          'The profile name may only contain alphanumeric characters or underscores'
        )

    const fields: IField[] = [
      {
        name: ProfileFieldName.name,
        label: translate('Name of the profile'),
        errors: storeManagementProfiles.storeFormEdition.field(
          ProfileFieldName.name
        ).errors,
        description,
        control: (
          <Input
            type="text"
            name="name"
            onChange={onInputChange(storeManagementProfiles.storeFormEdition)(
              ProfileFieldName.name
            )}
            onBlur={handleProfileNameFieldOnBlur(storeManagementProfiles)}
            value={storeFormEdition.getFieldValueAsString('name')}
            disabled={disabled}
            autoComplete="off"
          />
        )
      }
    ]

    return fields
  }

  return (
    <Portal name={PlaceHolderName.bladeHeader}>
      <ContainerFlex
        name={ProfileEditFormMainFields.name}
        direction="column"
        items={[
          storeManagementProfiles.isCurrentProfileDirty && (
            <Alert
              type="warning"
              message={translate(
                `This profile has changed, don't forget to commit them to apply the new configuration`
              )}
              showIcon
            />
          ),

          <ProfileAlertReadOnlyProfile />,

          <ContainerContent title={translate('Main information')}>
            <ContainerForm fields={getFields()} />
          </ContainerContent>
        ]}
        spaced
        spaceWidth="small"
      />
    </Portal>
  )
}

export default observer(ProfileEditFormMainFields)
