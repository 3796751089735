import type { Maybe } from '@@types/helpers'
import { EntityBlade } from '@app/entities'
import type { IBladeRaw } from '@app/entities/EntityBlade'
import type { EscapeHandler } from '@libs/KeyboardBindingsManager/EscapeHandler'
import { KeyboardKey } from '@libs/KeyboardBindingsManager/types'
import { range } from 'lodash'
import type { ObservableMap } from 'mobx'
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS
} from 'mobx'
import type { StoreRoot } from '..'
import StoreBase from '../StoreBase'
import type { IStoreEscapable, IStoreOptions } from '../types'
import type { BladeUuid } from './types'

const TRANSITION_DELAY = 300

export default class StoreBlades extends StoreBase implements IStoreEscapable {
  private $blades = observable.map<BladeUuid, EntityBlade>()

  constructor(storeRoot: StoreRoot, options: IStoreOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Return the blade matching the url.
   */
  getBlade(uuid: BladeUuid): Maybe<EntityBlade> {
    return this.$blades.get(uuid) || null
  }

  /**
   * Return the previous blade relative to the passed uuid.
   */
  getPreviousBladeFrom(uuid: BladeUuid): Maybe<EntityBlade> {
    const [index] = this._searchBladeByUuid(uuid)
    return this.bladesAsArray[index - 1]
  }

  /**
   * Return true if the blade matching url is in position
   * (when the animation is done).
   */
  isInPosition(uuid: BladeUuid): boolean {
    const blade = this.$blades.get(uuid)

    if (!blade) {
      return false
    }

    return Boolean(blade.inPosition)
  }

  /**
   * Make a redirection to a blade by keeping its animation.
   * (A push into the history will rewrite the DOM 'one shot').
   */
  goToBladeUrl(url: string): void {
    const appRouter = this.storeRoot.appRouter

    const [, blade] = this._searchBladeByUrl(url)

    if (!blade) {
      return
    }

    this.storeRoot.stores.storeBlades._spliceBlades(blade.uuid, 1).then(() => {
      appRouter.history.push(blade.url)
    })
  }

  /**
   * Remove some blades sequentially to have an animation per blade.
   */
  private async _spliceBlades(
    uuid: BladeUuid,
    offsetIndex: number = 0
  ): Promise<void> {
    const [index] = this._searchBladeByUuid(uuid)

    if (index === -1) {
      this.storeRoot.logger.warn(
        `[Blade] The blade with the uuid "${uuid}" has not been found.`
      )
      return Promise.resolve()
    }

    const uuids = range(index + offsetIndex, this.bladesAsArray.length)
      .map(i => this.bladesAsArray[i].uuid)
      .reverse()

    for (const _uuid of uuids) {
      if (_uuid) {
        await this.removeBlade(_uuid, TRANSITION_DELAY / 2)
      }
    }
  }

  /**
   * Search a blade by uuid.
   */
  private _searchBladeByUuid(uuid: BladeUuid): [number, Maybe<EntityBlade>] {
    const foundIndex = this.bladesAsArray.findIndex(
      _blade => _blade.uuid === uuid
    )

    if (foundIndex === -1) {
      this.storeRoot.logger.warn(
        `[Blade] The blade with the uuid "${uuid}" has not been found.`
      )

      return [-1, null]
    }

    return [foundIndex, this.bladesAsArray[foundIndex]]
  }

  /**
   * Search a blade by url.
   */
  private _searchBladeByUrl(url: string): [number, Maybe<EntityBlade>] {
    const foundIndex = this.bladesAsArray.findIndex(
      _blade => _blade.url === url
    )

    if (foundIndex === -1) {
      this.storeRoot.logger.warn(
        `[Blade] The blade with the url "${url}" has not been found.`
      )

      return [-1, null]
    }

    return [foundIndex, this.bladesAsArray[foundIndex]]
  }

  /* Actions */

  /**
   * Empty the blades map.
   */
  @action
  reset(): void {
    this.$blades.clear()
  }

  /**
   * Add a blade.
   *
   * The `inPosition` flag in initialized to false, then to true during the next
   * tick to animate the blade.
   *
   * /!\ Do not call this method yourself. Use the <Blade /> component
   * that will add/remove blade at mount/unmount.
   */
  @action
  addBlade(uuid: BladeUuid, blade: IBladeRaw): Promise<EntityBlade> {
    const bladeEntity = new EntityBlade({
      uuid,
      title: blade.title,
      url: blade.url,
      level: this.blades.size,
      inPosition: false
    })

    this.$blades.set(uuid, bladeEntity)

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.updateBladePosition(uuid, true)

        // register the store into KeyboardBindingsManager
        this.registerIntoKeyboardHandler()

        resolve(bladeEntity)
      })
    })
  }

  /**
   * Remove a blade.
   * If the blade level is > 0, the blade is removed from the map after a delay
   * to let the blade animation.
   */
  @action
  removeBlade(
    uuid: BladeUuid,
    transitionDelay = TRANSITION_DELAY
  ): Promise<void> {
    const blade = this.$blades.get(uuid)

    if (blade === undefined) {
      return Promise.resolve()
    }

    this.updateBladePosition(uuid, false)

    if (blade.level === 0) {
      this.$blades.delete(uuid)
      return Promise.resolve()
    }

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        runInAction(() => {
          this.$blades.delete(uuid)
        })

        resolve()
      }, transitionDelay)
    })
  }

  /**
   * Remove the latest blade.
   */
  @action
  removeLastBlade(): Promise<void> {
    const lastBlade = this.lastBlade

    if (!lastBlade) {
      return Promise.resolve()
    }

    return this.removeBlade(String(lastBlade.uuid))
  }

  /**
   * Update the blade position flag.
   */
  @action
  private updateBladePosition(uuid: BladeUuid, inPosition: boolean): void {
    const blade = this.$blades.get(uuid)

    if (!blade) {
      return
    }

    this.$blades.merge({
      [uuid]: {
        ...blade,
        inPosition
      }
    })
  }

  /**
   * Update the url of the last blade.
   *
   * A blade saves the url for which it has been created but if a navigation changes
   * the url of the page, the current opened blade (so the latest) needs to be updated
   * as well in order to ensure correct redirections when using `goToBlade()`
   * for example.
   */
  @action
  updateLastBladeUrl(url: string): void {
    const lastBlade = this.lastBlade

    if (!lastBlade) {
      return
    }

    this.$blades.merge({
      [lastBlade.uuid]: {
        ...lastBlade,
        url
      }
    })
  }

  /**
   * Close the last blade.
   */
  @action
  closeLastBlade(): Promise<boolean> {
    const uuid = this.lastBlade && this.lastBlade.uuid

    if (!uuid) {
      return Promise.resolve(false)
    }

    return this._spliceBlades(uuid).then(() => {
      if (this.lastBlade && this.lastBlade.uuid) {
        this.storeRoot.appRouter.history.push(this.lastBlade.url)
        return true
      }
      return false
    })
  }

  /* Computed */

  /**
   * Return an array of blades ordered by level.
   */
  @computed
  get bladesAsArray(): EntityBlade[] {
    return toJS(
      Array.from(this.$blades.values()).sort((a, b) =>
        (a.level || 0) > (b.level || 0) ? 1 : -1
      )
    )
  }

  /**
   * Return the latest blade.
   */
  @computed
  get lastBlade(): Maybe<EntityBlade> {
    return this.bladesAsArray[this.bladesAsArray.length - 1]
  }

  /**
   * Return blades.
   */
  @computed
  get blades(): ObservableMap<string, EntityBlade> {
    return this.$blades
  }

  /**
   * Implement IStoreEscapable
   */

  /**
   * Register the store into KeyboardBindingsManager.
   */
  registerIntoKeyboardHandler(): void {
    const handler =
      this.storeRoot.keyboardBindingsManager.getHandler<EscapeHandler>(
        KeyboardKey.Escape
      )

    if (!handler) {
      return
    }

    handler.addStore(this)
  }

  /**
   * Never unregister the store from KeyboardBindingsManager since there is only
   * one instance of the store.
   */
  unregisterIntoKeyboardHandler(): void {
    // never unregister
  }

  /**
   * Return true if the current level is superior to 0.
   * (meaning that a blade is opened)
   */
  get isEscapable(): boolean {
    const level = (this.lastBlade && this.lastBlade.level) || 0

    // don't close the root blade
    if (level <= 0) {
      return false
    }

    return true
  }

  /**
   * Close the latest blade.
   */
  escape(): Promise<boolean> {
    return this.closeLastBlade()
  }
}
