import type { Maybe } from '@@types/helpers'
import type { IContainerFlexProps } from '@app/components/Container/ContainerFlex/types'
import type { IComponentLoaderProps } from '@app/components-legacy/types'
import { useStores } from '@app/hooks'
import { useTestAttribute } from '@app/hooks/useTestAttribute'
import { consts } from '@app/styles'
import { filterNullOrUndefinedValues } from '@libs/helpers/objects/filterNullOrUndefinedValues'
import type { RbacCapabilityCheck } from '@libs/rbac/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'
import BladeOverlay from '../BladeOverlay'
import type { BladeLayout } from '../types'
import BladeContentLayoutDefault from './BladeContentLayoutDefault'
import BladeContentLayoutTwoColumns from './BladeContentLayoutTwoColumns'

interface IBladeContentProps {
  className?: string
  uuid: string
  level: number
  inPosition: boolean
  flags?: IComponentLoaderProps['flags']
  layout: BladeLayout
  labelledBy: string
  scrollChildrenContainerOverflow?: React.CSSProperties['overflow']
  scrollChildrenContainerBoxShadow?: React.CSSProperties['boxShadow']
  paddingH?: Maybe<IContainerFlexProps['paddingH']>
  paddingV?: Maybe<IContainerFlexProps['paddingV']>
  rbacCapabilityCheck: RbacCapabilityCheck
}

const BladeContent: React.FC<IBladeContentProps> = props => {
  const { storeBlades } = useStores()

  const { testAttributeProps } = useTestAttribute('document')

  const renderBladeContent = () => {
    switch (props.layout.name) {
      case 'twoColumns':
        return (
          <BladeContentLayoutTwoColumns
            flags={props.flags}
            layout={props.layout}
            rbacCapabilityCheck={props.rbacCapabilityCheck}
          />
        )

      default:
        return (
          <BladeContentLayoutDefault
            flags={props.flags}
            layout={props.layout}
            scrollChildrenContainerOverflow={
              props.scrollChildrenContainerOverflow
            }
            scrollChildrenContainerBoxShadow={
              props.scrollChildrenContainerBoxShadow
            }
            paddingH={props.paddingH}
            paddingV={props.paddingV}
            rbacCapabilityCheck={props.rbacCapabilityCheck}
          />
        )
    }
  }

  /**
   * Apply or not a zIndex according to the number of stacked blades:
   *
   * - If there is only one (root) blade, remove the zIndex to be able to render the
   * horizontal drawer overlay behind horizontal drawer content
   * (which is rendered into the blade).
   *
   * - If there is at least two blades (the root and another one), set a zIndex
   * in order to have the overlay of the previous blade below the current (latest)
   * displayed blade.
   */
  const styles: React.CSSProperties = filterNullOrUndefinedValues({
    zIndex: storeBlades.blades.size > 1 ? consts.zIndexBlade : null
  })

  return (
    <>
      {props.level > 0 && (
        <BladeOverlay
          uuid={props.uuid}
          level={props.level}
          inPosition={props.inPosition}
        />
      )}

      <div
        data-name="BladeContent"
        {...testAttributeProps()(props.labelledBy)}
        className={props.className}
        style={styles}
      >
        {renderBladeContent()}
      </div>
    </>
  )
}

const ObservedBladeContent = observer(BladeContent)

export default styled(ObservedBladeContent)`
  background-color: ${props =>
    props.level > 0
      ? props.theme.tokens['blade/backgroundColor/levelN']
      : props.theme.tokens['blade/backgroundColor/default']};
  box-shadow: ${props => props.theme.tokens['blade/boxShadow/default']};
  opacity: ${props => (props.inPosition ? '1' : '0')};
  transition:
    left 0.3s ${consts.smoothAnimation},
    ${consts.transitionOpacity};
  height: 100%;
  border-radius: ${props => props.theme.tokens['blade/borderRadius/default']};

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: ${props => {
    // no slide effect on 'root' blades
    if (!props.level) {
      return '0%'
    }

    // display the blade with an offset on the right
    if (!props.inPosition) {
      return '50%'
    }

    return consts.bladesLeftOffset
  }};

  .blade-uuid {
    position: absolute;
    top: 0;
    right: 0;
    font-size: ${consts.textSizeVerySmall};
    color: ${consts.colorBlueGray001};
  }
`
