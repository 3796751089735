import type { Maybe } from '@@types/helpers'
import type { IContainerFlexProps } from '@app/components/Container/ContainerFlex/types'
import { ErrorMessage, ErrorRbacDenyMessage } from '@app/components/Error'
import { useBladeContext } from '@app/components-legacy/Blade/hooks'
import {
  ContainerFlex,
  ContainerScroll
} from '@app/components-legacy/Container'
import type { IContainerScrollProps } from '@app/components-legacy/Container/ContainerScroll'
import { SpinnerLogo } from '@app/components-legacy/Spinner'
import SpinnerLogoBlue from '@app/components-legacy/Spinner/SpinnerLogoBlue'
import { useAppRouter } from '@app/hooks'
import { AppRouteName } from '@app/routes'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { filterNullOrUndefinedValues } from '@libs/helpers/objects/filterNullOrUndefinedValues'
import type { IRbacCapability } from '@libs/rbac/types'
import * as React from 'react'
import styled from 'styled-components'
import type { BladeLayout } from '../types'
import BladeContentFooter from './BladeContentFooter'

const StyledContainerFlex = styled(ContainerFlex)<{
  greyBackground?: boolean
}>`
  overflow: auto;
  background: ${props =>
    props.greyBackground ? consts.BackgroundColorV2.lightGray : 'inherit'};

  @media print {
    overflow: inherit;
  }
`

interface IBladeContentLayoutFullBodyProps {
  className?: string
  style?: React.CSSProperties
  isGranted: boolean
  isForbidden: boolean
  isError: boolean
  isLoading: boolean
  rbacCapability: IRbacCapability
  layout: BladeLayout
  scrollChildrenContainerOverflow?: React.CSSProperties['overflow']
  scrollChildrenContainerBoxShadow?: React.CSSProperties['overflow']
  paddingH?: Maybe<IContainerFlexProps['paddingH']>
  paddingV?: Maybe<IContainerFlexProps['paddingV']>
}

/**
 * Render the blade content.
 */
const BladeContentLayoutDefaultContent: React.FC<
  IBladeContentLayoutFullBodyProps
> = props => {
  const bladeContext = useBladeContext()

  const appRouter = useAppRouter()

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const currentPage = appRouter.getCurrentRouteName()

  const getContent = () => {
    if (!props.isGranted) {
      return <ErrorRbacDenyMessage message={props.rbacCapability.message} />
    }

    if (props.isForbidden) {
      return <ErrorRbacDenyMessage />
    }

    if (props.isError) {
      return <ErrorMessage />
    }

    if (props.isLoading) {
      return themeIsLegacy ? <SpinnerLogo /> : <SpinnerLogoBlue />
    }

    return props.layout.content
  }

  const getContentContainer = () => {
    const containerScrollProps: IContainerScrollProps =
      filterNullOrUndefinedValues<IContainerScrollProps>({
        handleScroll: bladeContext?.bladeContentProps?.handleScroll,
        paddingH: props.paddingH ?? 'large',
        paddingV: props.paddingV ?? 'medium',
        childrenContainerOverflow: props.scrollChildrenContainerOverflow,
        childrenContainerBoxShadow:
          props.isGranted &&
          !props.isForbidden &&
          !props.isError &&
          !props.isLoading
            ? props.scrollChildrenContainerBoxShadow
            : undefined
      })

    return (
      <ContainerScroll {...containerScrollProps}>
        {getContent()}
      </ContainerScroll>
    )
  }

  const hasGreyBackground =
    bladeContext?.bladeContentProps?.layoutContentGreyBackground &&
    (currentPage === AppRouteName.Management_System_Configuration_HealthCheck ||
      currentPage === AppRouteName.Management_System_Configuration_IoA ||
      currentPage === AppRouteName.Management_System_Configuration_Telemetry)

  // Trick: Render the footer before the content in order to setup the PlaceHolder
  // container before to render the Portal.
  // Then by using column-reverse direction, it allows to reverse the display.

  return (
    <StyledContainerFlex
      name="BladeContentLayoutDefaultContent"
      className={props.className}
      style={props.style}
      direction="column-reverse"
      itemsFlexGrow={[0, 1]}
      greyBackground={hasGreyBackground}
      items={[
        <BladeContentFooter
          isLoading={props.isLoading}
          isGranted={props.isGranted}
          isError={props.isError}
          layout={props.layout}
        />,
        getContentContainer()
      ]}
      fullHeight
    />
  )
}

export default BladeContentLayoutDefaultContent
