import type { PropertiesNullable } from '@@types/helpers'
import type { StoreRbac } from '@app/stores'
import type { IWidgetListColumns } from '@app/stores/helpers/StoreWidgetList/types'
import { consts } from '@app/styles'
import { readProfiles, editProfile } from '@libs/rbac/permissions'
import type { Maybe, Profile } from '@server/graphql/typeDefs/types'
import EntityBase from './EntityBase'
import type { IEntityListable, IEntityOmitKeysParameter } from './types'

export interface IDataRowProfile {
  id: number
  name: string
  dirty: boolean
  isSecurityAnalysisStarted: boolean
}

export enum ProfileName {
  Tenable = 'Tenable'
}

export default class EntityProfile
  extends EntityBase
  implements PropertiesNullable<Profile>, IEntityListable<IDataRowProfile>
{
  id: Maybe<number> = null
  name: Maybe<string> = null
  directories: Maybe<number[]> = null
  dirty: Maybe<boolean> = null
  hasEverBeenCommitted: Maybe<boolean> = null
  deleted: Maybe<boolean> = null

  // custom property
  color: Maybe<string> = null

  constructor(data: Partial<PropertiesNullable<Profile>>) {
    super()
    Object.assign(this, data)
  }

  setColor(color: string): void {
    // set the profile Tenable red
    if (this.isTenableProfile()) {
      this.color = consts.colorRed001
      return
    }

    this.color = color
  }

  isTenableProfile(): boolean {
    return String(this.name) === ProfileName.Tenable || this.id === 1
  }

  /**
   * Return true if the analysis has already started to this profile.
   */
  isSecurityAnalysisStarted() {
    return (
      this.isTenableProfile() ||
      this.getPropertyAsBoolean('hasEverBeenCommitted')
    )
  }

  /**
   * Return true if the profile is readable only.
   * Conditions are not the same if the profile is the Tenable one or not.
   */
  isReadableOnly(storeRbac: StoreRbac): boolean {
    const isTenableProfile = this.isTenableProfile()
    const profileId = this.getPropertyAsNumber('id')

    if (!profileId) {
      return false
    }

    return (
      // if Tenable profile and granted to read it (Tenable profile is not editable)
      (isTenableProfile &&
        storeRbac.isUserGrantedTo(readProfiles(profileId))) ||
      // if not AlTenablesid profile and granted to read it but not to edit it
      (!isTenableProfile &&
        storeRbac.isUserGrantedTo(readProfiles(profileId)) &&
        !storeRbac.isUserGrantedTo(editProfile(profileId)))
    )
  }

  /* Implements IEntityListable */

  getColumns(
    omitKeys: IEntityOmitKeysParameter<IDataRowProfile> = []
  ): Array<IWidgetListColumns<IDataRowProfile>> {
    const columns: Array<IWidgetListColumns<IDataRowProfile>> = [
      {
        label: 'ID',
        key: 'id'
      },
      {
        label: 'Name of the profile',
        key: 'name'
      },
      {
        label: 'Customization status',
        key: 'dirty'
      },
      {
        label: 'Analysis status',
        key: 'isSecurityAnalysisStarted'
      }
    ]

    return columns.filter(c => omitKeys.indexOf(c.key) === -1)
  }

  asDataRow(): IDataRowProfile {
    return {
      id: this.getPropertyAsNumber('id'),
      name: this.getPropertyAsString('name'),
      dirty: this.getPropertyAsBoolean('dirty'),
      isSecurityAnalysisStarted: this.isSecurityAnalysisStarted()
    }
  }
}
