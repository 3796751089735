import type {
  ISecurityEngineAppConfiguration,
  ISecurityEngineParameters
} from '@libs/IframeCommunicationBus/types'
import { isDefined, type Maybe } from '@productive-codebases/toolbox'
import { stringify } from 'qs'
import type { BrowserEnvironment } from './environment'

/**
 * Build the iframe url according to iframe configuration and parent-app url values.
 */
export function buildIframeUrl(
  browserEnvironment: BrowserEnvironment,
  configuration: ISecurityEngineAppConfiguration
): string {
  const iframeConfiguration: ISecurityEngineParameters = {
    targetOrigin: browserEnvironment.domDocument.location.origin,
    preferences: configuration.preferences
  }

  // url should start by http
  const endpoint = configuration.endpoint.startsWith('http')
    ? configuration.endpoint
    : [
        browserEnvironment.domDocument.location.origin,
        configuration.endpoint
      ].join('')

  const queryStringParameters = stringify(iframeConfiguration)

  const fragmentParameter = getSecurityEngineRoutePathname(
    configuration,
    browserEnvironment
  )

  const iframeUrlParts = [
    // path to SecurityEngine's index.html
    endpoint,
    // iframeConfiguration encoded in querystring parameters
    queryStringParameters ? `?${queryStringParameters}` : null,
    // route of the SecurityEngine app
    fragmentParameter ? `#${fragmentParameter}` : null
  ]

  return iframeUrlParts.filter(isDefined).join('')
}

/**
 * Return the value of the security engine querystring parameters that defined
 * the current route of SecurityEngine app.
 */
export function getSecurityEngineRoutePathname(
  configuration: ISecurityEngineAppConfiguration,
  browserEnvironment: BrowserEnvironment
): Maybe<string> {
  // otherwise, look after the ?securityEngine if defined
  const searchIterator = new URLSearchParams(
    browserEnvironment.domDocument.location.search
  ).entries()

  for (const [key, value] of searchIterator) {
    if (key !== 'securityEngine') {
      continue
    }

    // Using "_" as a replacement for "/" in the querystring so we don't have to base64 decode the value
    return decodeURI(value.replace(/_/g, '/'))
  }

  // if a page has been defined in the configuration, used it for the initial load
  if (configuration.initialPage) {
    return configuration.initialPage.replace(/^#*/g, '')
  }

  return null
}
