import { BladeCancelButton } from '@app/components-legacy/Blade'
import {
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import { FormWrapperButton } from '@app/components-legacy/Form'
import type { GenericCheckerCodename } from '@app/entities/EntityGenericChecker/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import { isSerieForAllDirectories } from '@app/stores/Management/StoreProfiles/helpers/isSerieForAllDirectories'
import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  onProfileSaveDraftClick,
  onRemoveRefineConfigurationClick
} from './handlers'

interface IConfigurationFooterProps {
  profileId: number
  checkerId: number
  checkerCodename: GenericCheckerCodename
}

const ConfigurationFooter: React.FC<IConfigurationFooterProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.Accounts.Profiles.Configuration']
  })

  const { storeManagementProfiles } = useStores()
  const { storeProfileGenericCheckersSelected } = storeManagementProfiles

  const storeProfileCheckerSerie =
    storeProfileGenericCheckersSelected.getSelectedStoreProfileCheckerSerie(
      props.checkerCodename
    )

  const storesProfileCheckerSerie =
    storeProfileGenericCheckersSelected.getStoresProfileCheckerSerie(
      props.checkerCodename
    )

  const storeMenu =
    storeProfileGenericCheckersSelected.getOrSetStoresMenuCheckerSerie(
      props.checkerCodename
    )

  const getRightControls = () => {
    if (!storeProfileCheckerSerie) {
      return null
    }

    const canRemoveSerie = !isSerieForAllDirectories(
      storeProfileCheckerSerie?.options
    )

    if (storeManagementProfiles.isCurrentProfileReadOnly) {
      return (
        <Tooltip title={translate('The Tenable profile is not editable')}>
          <FormWrapperButton
            labelledBy="saveAsDraft"
            buttonProps={{
              type: 'primary',
              disabled: true
            }}
          >
            {translate('Save as draft')}
          </FormWrapperButton>
        </Tooltip>
      )
    }

    return (
      <ContainerFlex
        name={ConfigurationFooter.name}
        alignItems="center"
        items={[
          <div>
            {translate('X configurations set', {
              interpolations: {
                count: storesProfileCheckerSerie.length
              }
            })}
          </div>,

          <FormWrapperButton
            labelledBy="removeConfiguration"
            buttonProps={{
              disabled: !canRemoveSerie,
              onClick: onRemoveRefineConfigurationClick(
                storeManagementProfiles.storeProfileGenericCheckersSelected
              )(props.checkerCodename, storeMenu.selectedEntryIndex)
            }}
          >
            {translate('Remove this configuration')}
          </FormWrapperButton>,

          <FormWrapperButton
            labelledBy="saveAsDraft"
            buttonProps={{
              type: 'primary',
              onClick: onProfileSaveDraftClick(
                storeProfileGenericCheckersSelected
              )(props.profileId, props.checkerId, props.checkerCodename),
              loading:
                storeProfileGenericCheckersSelected.storeFlagsSaveOptions.flags
                  .isLoading
            }}
          >
            {translate('Save as draft')}
          </FormWrapperButton>
        ]}
        spaced
      />
    )
  }

  const rightControls = getRightControls()

  return (
    <ContainerFooter justify={rightControls ? 'default' : 'left'}>
      <BladeCancelButton />
      {getRightControls()}
    </ContainerFooter>
  )
}

export default observer(ConfigurationFooter)
