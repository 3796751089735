import type { IIconProps } from '@app/components/Icon/types'
import { FontColorV2 } from '@app/styles/consts'
import * as React from 'react'

interface IIconUnknownCircleProps extends IIconProps {}

const IconUnknownCircle: React.FC<IIconUnknownCircleProps> = props => {
  const fill = props.color ?? FontColorV2.primary
  const size = props.size ?? 16

  return (
    <svg
      className={props.className}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <circle cx="8" cy="8" r="8" fill={fill} />
      <path
        d="M7.2945 8.0275C7.91417 7.84983 8.40167 7.657 8.757 7.449C9.11233 7.241 9.36583 7.00483 9.5175 6.7405C9.66917 6.47183 9.745 6.1555 9.745 5.7915C9.745 5.26283 9.5565 4.85767 9.1795 4.576C8.8025 4.29 8.25217 4.147 7.5285 4.147C6.7355 4.147 5.9815 4.26617 5.2665 4.5045V3.614C5.61317 3.50133 5.99017 3.41467 6.3975 3.354C6.80917 3.289 7.21867 3.2565 7.626 3.2565C8.30633 3.2565 8.88267 3.354 9.355 3.549C9.82733 3.744 10.1848 4.02567 10.4275 4.394C10.6745 4.76233 10.798 5.20433 10.798 5.72C10.798 6.19667 10.7113 6.6105 10.538 6.9615C10.369 7.30817 10.0982 7.61367 9.7255 7.878C9.35717 8.14233 8.8675 8.38067 8.2565 8.593L8.159 10.647H7.483L7.2945 8.0275ZM7.821 13.0845C7.59567 13.0845 7.4115 13.0195 7.2685 12.8895C7.12983 12.7595 7.0605 12.5883 7.0605 12.376C7.0605 12.1593 7.12983 11.986 7.2685 11.856C7.40717 11.726 7.59133 11.661 7.821 11.661C8.05067 11.661 8.23483 11.7282 8.3735 11.8625C8.51217 11.9925 8.5815 12.1637 8.5815 12.376C8.5815 12.5883 8.51217 12.7595 8.3735 12.8895C8.23483 13.0195 8.05067 13.0845 7.821 13.0845Z"
        fill="white"
      />
    </svg>
  )
}

export default IconUnknownCircle
