import { consts } from '@app/styles'
import { BrandColorV2, FontColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'

interface IBulletPointProps {
  className?: string
  children: React.ReactNode
}

const Circle = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  background-color: ${BrandColorV2.blue1};
  color: ${FontColorV2.white};
  font-size: ${consts.textSizeGlobal};
  font-weight: ${consts.fontWeightBold};
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`

const BulletPoint: React.FC<IBulletPointProps> = props => (
  <Circle>{props.children}</Circle>
)

export default BulletPoint
