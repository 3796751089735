import { ErrorGenericMessage } from '@app/components/Error'
import {
  ContainerFlex,
  ContainerOpacity
} from '@app/components-legacy/Container'
import { LabelAlt } from '@app/components-legacy/Label'
import { LabelAltVariant } from '@app/components-legacy/Label/LabelAlt'
import { SpinnerInline } from '@app/components-legacy/Spinner'
import type { IComponentLoaderProps } from '@app/components-legacy/types'
import { useAppTranslator } from '@app/hooks'
import { mergeFlags } from '@app/stores/helpers/StoreFlags/helpers'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { ensureArray } from '@libs/ensureArray'
import * as React from 'react'
import styled, { css } from 'styled-components'

export interface IWidgetContainerProps {
  className?: string
  // custom prop when missing data
  isMissingData?: boolean
  // custom props when something is wrong
  isError?: boolean
  // flags from the store
  flags?: IComponentLoaderProps['flags']
  header: React.ReactNode
  children?: React.ReactNode
  footer?: React.ReactNode
  labelledBy?: string
}

const WidgetContainer: React.FC<IWidgetContainerProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Dashboard.Common'] })

  const flags = mergeFlags(...ensureArray(props.flags))

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const renderContent = () => {
    if (flags.isLoading) {
      return (
        <ContainerFlex
          name="Spinner"
          labelledBy={props.labelledBy}
          alignItems="center"
          justifyContent="center"
          items={[<SpinnerInline />]}
        />
      )
    }

    if (props.isMissingData) {
      return (
        <ContainerFlex
          name="Spinner"
          labelledBy={props.labelledBy}
          alignItems="center"
          justifyContent="center"
          items={[
            <LabelAlt
              variant={LabelAltVariant.Disabled | LabelAltVariant.Bolded}
            >
              {translate('No data')}
            </LabelAlt>
          ]}
        />
      )
    }

    if (flags.isError) {
      return (
        <ContainerFlex
          name="Spinner"
          labelledBy={props.labelledBy}
          alignItems="center"
          justifyContent="center"
          items={[<ErrorGenericMessage />]}
        />
      )
    }

    if (!props.children) {
      return null
    }

    return (
      <ContainerOpacity fullHeight={themeIsLegacy ? 80 : 90}>
        {props.children}
      </ContainerOpacity>
    )
  }

  return (
    <ContainerFlex
      name="WidgetContainer"
      labelledBy={props.labelledBy}
      className={props.className}
      direction="column"
      itemsFlexGrow={[0, 1, 0]}
      items={[props.header, renderContent(), !flags.isError && props.footer]}
      fullHeight
    />
  )
}

export default styled(WidgetContainer)`
  box-shadow: ${({ theme }) => theme.tokens['dashboardWidget/boxShadow']};
  padding: ${consts.paddingVerySmall} ${consts.paddingDefault};
  overflow: hidden;
  cursor: default !important;
  border: ${props =>
    isThemeLegacy(props.theme) ? `1px solid ${consts.colorBlueGray020}` : '0'};
  border-radius: ${consts.containerBorderRadius};

  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      background-color: ${consts.BackgroundColorV2.white};
    `}
`
