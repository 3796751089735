import { AppRouteName } from '@app/routes'
import type { StoreRoot } from '@app/stores'
import type { UnauthorizedAccessError } from '@libs/errors'
import { ApplicationError, HardReloadError } from '@libs/errors'
import { isErrorOfType } from '@libs/errors/functions'
import { ApplicationErrorValue, ErrorName } from '@libs/errors/types'
import { logException } from '@libs/logExceptions'
import { getLogger } from '@libs/logger'
import type { QueryInitUserData } from '@server/graphql/queries/init'
import type { Maybe } from '@server/graphql/typeDefs/types'
import {
  fetchAnonymousData,
  checkPostLoginData,
  fetchUserData,
  fetchPostLoginData
} from '../utils/fetchData'
import { canRedirectToLogin } from '../utils/redirections'
import { initializeTelemetry } from './initializeTelemetry'

const logger = getLogger()

/**
 * Initialize the application.
 */
export function initializeApp(storeRoot: StoreRoot) {
  return (parameters: {
    pathname: string
    profileName: Maybe<string>
  }): Promise<void | QueryInitUserData> => {
    const { appRouter } = storeRoot

    const logoutUrl = storeRoot.appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.MiddlewareAuth_Logout,
      parameters: {}
    })

    return (
      Promise.resolve()
        /**
         * Fetch anonymous data:
         * - about (production version)
         * - eula
         */
        .then(() => {
          return fetchAnonymousData(storeRoot)
        })

        /**
         * Fetch user related data:
         * - applicationConfiguration (available languages)
         * - user's preferences
         * - WhoAmI
         * - User's roles
         * - Profiles
         */
        .then(() => {
          return fetchUserData(storeRoot)({
            profileName: parameters.profileName,
            user: null
          })
        })

        /**
         * Check post login data.
         */
        .then(() => {
          return checkPostLoginData(storeRoot)(parameters.profileName)
        })

        /**
         * Initialize tracking telemetry
         */
        .then(() => {
          return initializeTelemetry(storeRoot)
        })

        /**
         * Fetch post login data.
         * - infrastructures
         * - checkers...
         */
        .then(() => {
          return fetchPostLoginData(storeRoot)
        })

        /**
         * Handle UnauthorizedAccessError (401) when being logout.
         */
        .catch(err => {
          // if the error is a redirection, forward the exception
          if (isErrorOfType<HardReloadError>(err, ErrorName.HardReloadError)) {
            throw err
          }

          // handle unauthorized access
          if (
            isErrorOfType<UnauthorizedAccessError>(
              err,
              ErrorName.UnauthorizedAccessError
            )
          ) {
            // avoid infinite redirection if already in the login page
            if (!canRedirectToLogin(appRouter, parameters.pathname)) {
              return
            }

            // redirect to the logout middleware that will redirect to the login page
            throw new HardReloadError(logoutUrl)
          }

          logException(logger)(
            err,
            'An error has occurred during the app initialization'
          )

          // handle generic and unknown errors (often due to a problem with the API)
          throw new ApplicationError({
            errorValue: ApplicationErrorValue.ApiError,
            message: 'Error during initializeApp'
          })
        })
    )
  }
}
