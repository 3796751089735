import * as React from 'react'
import { ContainerFlex } from '../Container'
import BladeHeaderIcon from './BladeHeaderIcon'
import BladeHeaderValue from './BladeHeaderValue'

export interface IBladeHeaderInfo {
  rawType: string
  type: string
  showValueOnly?: boolean
  value: React.ReactNode
}

export interface IBladeHeaderProps {
  className?: string
  icon?: 'box' | 'ioe' | 'ioa' | 'identity'
  infos: IBladeHeaderInfo[]
}

const BladeHeader: React.FC<IBladeHeaderProps> = props => {
  const renderItems = () => {
    return props.infos.map(info => {
      return (
        <BladeHeaderValue
          rawType={info.rawType}
          type={info.type ?? info.rawType}
          showValueOnly={info.showValueOnly}
          value={info.value}
        />
      )
    })
  }

  return (
    <ContainerFlex
      name="BladeHeader"
      alignItems="center"
      className={props.className}
      itemsFlexGrow={[0, 1]}
      items={[
        props.icon && <BladeHeaderIcon icon={props.icon} />,

        <ContainerFlex
          name="BladeHeaderContainer"
          justifyContent="space-between"
          items={renderItems()}
        />
      ]}
      spaced
    />
  )
}

export default BladeHeader
