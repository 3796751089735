import type { Maybe } from '@@types/helpers'
import { SpinnerLogo } from '@app/components-legacy/Spinner'
import type { StoreAttackPath } from '@app/stores'
import type StoreFlags from '@app/stores/helpers/StoreFlags'
import type { AttackPathNode } from '@server/graphql/typeDefs/types'
import { AttackPathDirection } from '@server/graphql/typeDefs/types'
import { debounce } from 'lodash'
import * as React from 'react'
import NodeSearchIcon from '../../Components/NodeSearchIcon'
import { AttackPathSearchTabs } from '../../types'
import { handleAdObjectOnSearch } from '../handlers'

export const handleNodeFinderOnSwap =
  (storeAttackPath: StoreAttackPath) => () => {
    storeAttackPath.swapSourceAndTargetInputValues()

    if (storeAttackPath.searchIsDone) {
      handleAdObjectOnSearch(storeAttackPath)()
    }
  }

export const handleNodeFinderSourceSearch = (
  storeAttackPath: StoreAttackPath
) => {
  if (storeAttackPath.storeInputSearchSource.searchValue.length < 3) {
    storeAttackPath.removeSearchSourceSuggestions()
  }

  storeAttackPath.searchSourceNodesAndSetSuggestions()
}

export const handleNodeFinderTargetSearch = (
  storeAttackPath: StoreAttackPath
) => {
  if (storeAttackPath.storeInputSearchTarget.searchValue.length < 3) {
    storeAttackPath.removeSearchTargetSuggestions()
  }

  storeAttackPath.searchTargetNodesAndSetSuggestions()
}

export const handleNodeFinderSourceSelect =
  (storeAttackPath: StoreAttackPath) => (selectValue: unknown) => {
    const attackPathNode = storeAttackPath.searchSourceSuggestions.find(
      searchSourceSuggestion =>
        String(searchSourceSuggestion.id) === String(selectValue)
    )

    if (!attackPathNode) {
      return
    }

    storeAttackPath.storeInputSearchSource.setSearchValue(
      attackPathNode.name ?? String(attackPathNode.id)
    )

    storeAttackPath.setSelectedSearchedSourceNode(attackPathNode)

    handleNodeFinderSourceSearch(storeAttackPath)

    if (storeAttackPath.searchIsDone) {
      handleAdObjectOnSearch(storeAttackPath)()
    }
  }

export const handleNodeFinderTargetSelect =
  (storeAttackPath: StoreAttackPath) => (selectValue: unknown) => {
    const attackPathNode = storeAttackPath.searchTargetSuggestions.find(
      searchTargetSuggestion =>
        String(searchTargetSuggestion.id) === String(selectValue)
    )

    if (!attackPathNode) {
      return
    }

    storeAttackPath.storeInputSearchTarget.setSearchValue(
      attackPathNode.name ?? String(attackPathNode.id)
    )

    storeAttackPath.setSelectedSearchedTargetNode(attackPathNode)

    handleNodeFinderTargetSearch(storeAttackPath)

    if (storeAttackPath.searchIsDone) {
      handleAdObjectOnSearch(storeAttackPath)()
    }
  }

export const handleNodeFinderSourceFocus =
  (storeAttackPath: StoreAttackPath) => () => {
    if (
      storeAttackPath.selectedSearchedSourceNode &&
      !(storeAttackPath.searchSourceSuggestions?.length > 0)
    ) {
      handleNodeFinderSourceSearch(storeAttackPath)
    }
  }

export const handleNodeFinderTargetFocus =
  (storeAttackPath: StoreAttackPath) => () => {
    if (
      storeAttackPath.selectedSearchedTargetNode &&
      !(storeAttackPath.searchTargetSuggestions?.length > 0)
    ) {
      handleNodeFinderTargetSearch(storeAttackPath)
    }
  }

export const handleSourceSearchInputReset =
  (storeAttackPath: StoreAttackPath) => () => {
    storeAttackPath.removeSelectedSearchedSourceNode()
    storeAttackPath.removeSearchSourceSuggestions()
  }

export const handleNodeFinderSourceSearchDebounced = debounce(
  handleNodeFinderSourceSearch,
  500
)

export const handleSourceSearchInputChange =
  (storeAttackPath: StoreAttackPath) => () => {
    storeAttackPath.removeSelectedSearchedSourceNode()
    handleNodeFinderSourceSearchDebounced(storeAttackPath)
  }

export const handleTargetSearchInputReset =
  (storeAttackPath: StoreAttackPath) => () => {
    storeAttackPath.removeSelectedSearchedTargetNode()
    storeAttackPath.removeSearchTargetSuggestions()
  }

export const handleNodeFinderTargetSearchDebounced = debounce(
  handleNodeFinderTargetSearch,
  500
)

export const handleTargetSearchInputChange =
  (storeAttackPath: StoreAttackPath) => () => {
    storeAttackPath.removeSelectedSearchedTargetNode()
    handleNodeFinderTargetSearchDebounced(storeAttackPath)
  }

export const getLeftIcon = (
  attackPathNode: Maybe<AttackPathNode>,
  storeFlags: StoreFlags,
  activeTab: AttackPathSearchTabs,
  direction?: AttackPathDirection,
  source?: boolean
) => {
  if (attackPathNode) {
    return (
      <NodeSearchIcon adObjectType={attackPathNode.adObjectType} size={32} />
    )
  }

  if (storeFlags.isLoading) {
    return <SpinnerLogo width={20} />
  }

  return (
    <NodeSearchIcon
      size={16}
      direction={direction}
      source={source}
      activeTab={activeTab}
    />
  )
}

export const handleNodeFinderTabClick =
  (storeAttackPath: StoreAttackPath) =>
  (tab: AttackPathSearchTabs) =>
  (handler?: () => void) =>
  () => {
    if (tab === AttackPathSearchTabs.attackPath) {
      storeAttackPath.setTargetNodeDisplay(true)
    }

    if (tab === AttackPathSearchTabs.blastRadius) {
      storeAttackPath.setTargetNodeDisplay(false)
      storeAttackPath.setDirection(AttackPathDirection.From)
    }

    if (tab === AttackPathSearchTabs.assetExposure) {
      storeAttackPath.setTargetNodeDisplay(false)
      storeAttackPath.setDirection(AttackPathDirection.To)
    }

    storeAttackPath.setActiveTab(tab)

    if (handler) {
      handler()
    }
  }
