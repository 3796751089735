import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { buildVariants } from '@design-system/libs/buildVariants'
import { Tag } from 'antd'
import * as React from 'react'
import styled from 'styled-components'

interface ILabelBetaProps {}

const StyledTag = styled(Tag)(props => {
  return buildVariants(props)
    .css({
      fontFamily: 'Barlow SemiBold',
      fontSize: '9px',
      paddingLeft: props.theme.sizes.small,
      paddingRight: props.theme.sizes.small,
      marginLeft: props.theme.sizes.small,
      color: props.theme.tokens['tag/color/beta'],
      borderRadius: props.theme.sizes.smallest
    })
    .end()
})

const LabelBeta: React.FC<ILabelBetaProps> = props => {
  const theme = useDSTheme()
  return (
    <StyledTag color={theme.tokens['tag/color/beta/background']}>
      BETA
    </StyledTag>
  )
}

export default LabelBeta
