import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

export interface ITableBodyProps {
  className?: string
  children?: React.ReactNode
}

const TableBody: React.FC<ITableBodyProps> = props => {
  return <tbody className={props.className}>{props.children}</tbody>
}

export default styled(TableBody)`
  background-color: ${consts.BackgroundColor.secondary};

  tr:first-child {
    border-top: 11px solid ${consts.BackgroundColor.secondary};
  }

  tr:last-child {
    border-bottom: 11px solid ${consts.BackgroundColor.secondary};
  }
`
