import { Label } from '@app/components/Labels'
import Modal from '@app/components-legacy/Modal/Modal'
import { useAppTranslator } from '@app/hooks'
import type StoreModal from '@app/stores/helpers/StoreModal'
import * as React from 'react'

interface IModalCrawlingProps {
  storeModal: StoreModal<any>
}

const ModalCrawling: React.FC<IModalCrawlingProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['AttackPath.ModalCrawling']
  })

  return (
    <Modal
      modalProps={{
        title: translate('Attack path graph build in progress'),
        getContainer: '.LayoutDefaultThird',
        footer: null
      }}
      storeModal={props.storeModal}
    >
      <Label>{translate('Tenable.ad is currently crawling')}</Label>
      <br />
      <br />
      <Label>{translate('The loading takes place in the background')}</Label>
    </Modal>
  )
}

export default ModalCrawling
