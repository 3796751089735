import { consts } from '@app/styles'
import * as React from 'react'
import styled from 'styled-components'

interface ITypographyPDFSubTitleProps {
  className?: string
  children?: React.ReactNode
}

const StyledH4 = styled.h4`
  font-size: ${consts.textSizeExtraLarge};
  font-weight: ${consts.fontWeightBold};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: ${consts.paddingXXL};

  position: relative;
  z-index: 3;
  background-color: ${consts.colorWhite};
  padding: 0 100px;
  display: inline-block;
  letter-spacing: 10px;
`

// Display a line behind the typo
const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  ::after {
    content: '';
    border-bottom: 1px solid ${consts.colorBlue002};
    position: absolute;
    left: 100px;
    right: 100px;
    top: 18px;
    z-index: 1;
  }
`

const TypographyPDFSubTitle: React.FC<ITypographyPDFSubTitleProps> = props => {
  return (
    <StyledWrapper data-name="TypographyPDFSubTitle">
      <StyledH4 className={props.className}>{props.children}</StyledH4>
    </StyledWrapper>
  )
}

export default TypographyPDFSubTitle
