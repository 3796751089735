import { LabelAlt } from '@app/components-legacy/Label'
import { ensureArray } from '@libs/ensureArray'
import * as React from 'react'
import type { IContainerSimpleProps } from '../Container/ContainerSimple'
import { LabelAltVariant } from './LabelAlt'

interface ILabelAltErrorProps extends IContainerSimpleProps {
  labelledBy: string
}

const LabelAltError: React.FC<ILabelAltErrorProps> = props => {
  const ariaRoles = ensureArray(props.ariaRoles || 'alert')

  return (
    <LabelAlt variant={LabelAltVariant.Error} ariaRoles={ariaRoles} {...props}>
      {props.children}
    </LabelAlt>
  )
}

/** @deprecated */
export default LabelAltError
