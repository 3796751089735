import {
  ContainerContent,
  ContainerFlex,
  ContainerIcon,
  ContainerRbac
} from '@app/components-legacy/Container'
import {
  FormWrapper,
  FormWrapperButton
} from '@app/components-legacy/Form/Wrappers'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import WidgetForm from '@app/components-legacy/Widgets/WidgetForm'
import type { WidgetFormFieldRenderer } from '@app/components-legacy/Widgets/WidgetForm/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import type StoreForm from '@app/stores/helpers/StoreForm'
import type { FieldName } from '@app/stores/helpers/StoreForm/types'
import { UserFormFieldName } from '@app/stores/Management/StoreUsers'
import type { Maybe } from '@server/graphql/typeDefs/types'
import { AuthProviderName } from '@server/graphql/typeDefs/types'
import { Alert } from 'antd'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { handleUserRemoveLockedOutSubmit } from '../UsersEditPage/handlers'
import { UserLockedOutRemoveIcon } from '../UsersPage/UserLockedOutIcons'
import { handleUserCreationCancelOnClick } from './handlers'
import { canSelectUserRoles } from './permissions'
import UserCommonFormAuthenticationType from './UserCommonFormAuthenticationType'
import UserCommonFormPassword from './UserCommonFormPassword'
import UserCreateEditBladeFooter from './UserCreateEditBladeFooter'
import UserRolesList from './UserRolesList'

export type UserFormVersion = 'creation' | 'edition'

interface IUserCommonFormProps {
  className?: string
  version: UserFormVersion
  userId?: Maybe<number>
  onCancelButtonClick: () => void
  onSubmit: (e: React.FormEvent<any>) => void
  storeForm: StoreForm<UserFormFieldName>
}

const UserCommonForm: React.FC<IUserCommonFormProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Titles', 'Buttons', 'Management.Accounts.Users']
  })

  const { storeBlades, storeRbac, storeManagementUsers, storeAuthentication } =
    useStores()

  const isTenableProvider =
    props.storeForm.getFieldValueAsString('provider') ===
    AuthProviderName.Tenable

  const isCurrentUserLoggedIn =
    props.userId && storeManagementUsers.isCurrentUserLoggedIn(props.userId)

  const isLockedOut = props.storeForm.getFieldValueAsBoolean(
    UserFormFieldName.lockedOut
  )

  const renderAuthenticationType: WidgetFormFieldRenderer<
    UserFormFieldName
  > = storeForm => <UserCommonFormAuthenticationType storeForm={storeForm} />

  const renderPassword =
    (fieldName: FieldName): WidgetFormFieldRenderer<UserFormFieldName> =>
    (storeForm, field, inputId) => (
      <UserCommonFormPassword
        fieldName={fieldName}
        id={inputId}
        version={props.version}
        storeForm={storeForm}
      />
    )

  const renderLockedOut: WidgetFormFieldRenderer<UserFormFieldName> = () => {
    if (!props.userId) {
      return null
    }

    // if the user is not lockedOut, do not show the field
    if (!isLockedOut) {
      return null
    }

    return (
      <FormWrapperButton
        labelledBy="removeLockedOut"
        buttonProps={{
          danger: true,
          onClick: handleUserRemoveLockedOutSubmit(storeManagementUsers)(
            props.userId
          )
        }}
      >
        <ContainerIcon
          labelledBy="removeLockedOutIcon"
          label={translate('Unlock the account')}
          iconComponent={UserLockedOutRemoveIcon}
        />
      </FormWrapperButton>
    )
  }

  /**
   * Allow validation if there is not the role management
   * and if there is at least one role.
   */
  const canValidateRoles = () => {
    const isAllowedToSeeRoles = storeRbac.isUserGrantedTo(canSelectUserRoles)

    if (!isAllowedToSeeRoles) {
      return true
    }

    return (
      storeManagementUsers.storeWidgetRbacRolesList.selectedRowsAsArray.length >
      0
    )
  }

  const hiddenFields: Set<UserFormFieldName> = new Set()

  // can't create lockedOut accounts
  if (props.version === 'creation') {
    hiddenFields.add(UserFormFieldName.lockedOut)
  }

  // no passwords fields for LDAP/SAML providers or T.one users
  if (!isTenableProvider || storeAuthentication.isToneUser()) {
    hiddenFields
      .add(UserFormFieldName.password)
      .add(UserFormFieldName.passwordConfirmation)
  }

  return (
    <FormWrapper name="userConfiguration" onSubmit={props.onSubmit}>
      <>
        <ContainerFlex
          name="UserCommonForm"
          direction="column"
          items={[
            isCurrentUserLoggedIn && (
              <Alert
                type="warning"
                message={translate(
                  `This user is currently logged in, your changes could affect your current session`
                )}
                showIcon
              />
            ),

            <ContainerContent title={translate('Main information')}>
              <WidgetForm<UserFormFieldName>
                hiddenFields={Array.from(hiddenFields)}
                renderFields={{
                  [UserFormFieldName.provider]: renderAuthenticationType,
                  [UserFormFieldName.password]: renderPassword(
                    UserFormFieldName.password
                  ),
                  [UserFormFieldName.passwordConfirmation]: renderPassword(
                    UserFormFieldName.passwordConfirmation
                  ),
                  [UserFormFieldName.lockedOut]: renderLockedOut
                }}
                storeForm={props.storeForm}
                translate={translate}
              />
            </ContainerContent>,

            <ContainerRbac rbacCapabilityCheck={canSelectUserRoles}>
              <ContainerContent title={translate('Roles management')}>
                <UserRolesList />
              </ContainerContent>
            </ContainerRbac>
          ]}
          spaced
        />

        <Portal name={PlaceHolderName.bladeFooter}>
          <UserCreateEditBladeFooter
            version={props.version}
            storeFlags={storeManagementUsers.storeCreateUserFlags}
            onCancelClick={handleUserCreationCancelOnClick(storeBlades)}
            canValidate={canValidateRoles()}
          />
        </Portal>
      </>
    </FormWrapper>
  )
}

export default observer(UserCommonForm)
