import { Label } from '@app/components/Labels'
import { LabelVariant } from '@app/components/Labels/types'
import { LabelSliced } from '@app/components-legacy/Label'
import { useStores } from '@app/hooks'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import TableContentHeadCell from '@app/pages/TrailFlow/TrailFlowPage/Table/TableContentHeadCell'
import { consts } from '@app/styles'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import * as React from 'react'
import styled from 'styled-components'
import { canSeeDeviances } from '../../permissions'
import { setTableColumnsStyled } from './styles'

export interface ITableContentHeadProps {
  className?: string
}

const TableContentHead: React.FC<ITableContentHeadProps> = props => {
  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  const translate = useAppTranslator({ namespaces: ['TrailFlow.Table'] })

  const { storeRbac } = useStores()

  return (
    <div data-name="TableContentHead" className={props.className}>
      {storeRbac.isUserGrantedTo(canSeeDeviances) && (
        <div className="eventDeviance" />
      )}

      <TableContentHeadCell className="eventSource">
        {translate('Source')}
      </TableContentHeadCell>

      <TableContentHeadCell className="eventType">
        {translate('Type')}
      </TableContentHeadCell>

      <TableContentHeadCell className="eventClassFile">
        {translate('Class / File')}
      </TableContentHeadCell>

      <TableContentHeadCell className="eventDN">
        {translate('DN / Globalpath')}
      </TableContentHeadCell>

      <TableContentHeadCell className="eventDirectory">
        {translate('Domain')}
      </TableContentHeadCell>

      <span className="eventDate">
        {themeIsLegacy ? (
          <LabelSliced
            value={`${translate('Date')} (HH:MM:SS, YYYY-MM-DD)`}
            popoverType="tooltip"
          />
        ) : (
          <Label variant={LabelVariant.h3}>
            <LabelSliced
              value={`${translate('Date')} (HH:MM:SS, YYYY-MM-DD)`}
              popoverType="tooltip"
            />
          </Label>
        )}
      </span>
    </div>
  )
}

const TableStyledContentHead = setTableColumnsStyled(TableContentHead)

export default styled(TableStyledContentHead)`
  font-weight: ${consts.fontWeightBold};
  align-items: baseline;
  padding: ${props => props.theme.tokens['table/padding/row']};
`
