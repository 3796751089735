import {
  CheckerCodeNameEnum,
  CheckerCodeNameId
} from '@app/entities/EntityGenericChecker/types'
import { AppRouteName } from '@app/routes'
import type { StoreRbac, StoreTopology } from '@app/stores'
import { SCENE_TOOLIP_DEBOUNCE_DELAY } from '@app/stores/Topology/consts'
import type { ITooltip } from '@app/stores/Topology/types'
import { isDefined } from '@libs/isDefined'
import type { AppRouterClient } from '@libs/Router/types'
import type { Maybe } from '@server/graphql/typeDefs/types'
import { TrustHazardLevel } from '@server/graphql/typeDefs/types'
import type { ReactNode } from 'react'
import { canAccessToDomainDetails } from '../../DomainDetailsBlade/permissions'
import { canSeeDangerousTrustRelationships } from '../../TrustsDeviantObjectsBlade/permissions'
import SceneObject from './SceneObject'

// debounce the tooltip to avoid to set/unset too much and make React refreshs.
let tooltipTimer: NodeJS.Timeout

/**
 * Redirect to the forest creation form.
 */
export const onAddAForestButtonClick = (appRouter: AppRouterClient) => () => {
  const url = appRouter.makeRouteInfosPathname({
    routeName: AppRouteName.Management_System_Infrastructures_Create,
    parameters: {}
  })

  appRouter.history.push(url)
}

/**
 * When hovering a Trust, show the tooltip with trust specs.
 */
export const onTrustMouseOver =
  (storeTopology: StoreTopology) =>
  (tooltipContent: ReactNode, uid: string) =>
  (e: React.MouseEvent<SVGPathElement, MouseEvent>) => {
    clearTimeout(tooltipTimer)

    const svgDOMBounds = storeTopology.chartSvgDOMBounds

    const { clientX, clientY } = e

    tooltipTimer = setTimeout(() => {
      clearTimeout(tooltipTimer)

      const tooltip = new SceneObject<ITooltip>(
        {
          x: clientX,
          y: clientY
        },
        { content: tooltipContent, uid }
      )

      // add offsets according to the position of the main SVG container
      if (!svgDOMBounds) {
        return
      }

      tooltip.addOffset({
        x: -svgDOMBounds.x || -svgDOMBounds.left,
        y: -svgDOMBounds.y || -svgDOMBounds.top
      })

      storeTopology.setTooltip(tooltip)
    }, SCENE_TOOLIP_DEBOUNCE_DELAY)
  }

/**
 * When leaving a Trust, remove the tooltip.
 */
export const onTrustMouseOut = (storeTopology: StoreTopology) => () => {
  clearTimeout(tooltipTimer)
  storeTopology.removeTooltip()
}

/**
 * When hovering an Infrastructure, set its uid in the store to highlight the related trusts.
 */
export const onInfrastructureMouseOver =
  (storeTopology: StoreTopology) =>
  (infrastructureUid: Maybe<string>) =>
  () => {
    if (!infrastructureUid) {
      return
    }

    storeTopology.setInfrastructureUid(infrastructureUid)
  }

/**
 * When leaving an Infrastructure, unset infrastructure to hide Trusts.
 */
export const onInfrastructureMouseOut =
  (storeTopology: StoreTopology) => () => {
    storeTopology.removeInfrastructureUid()
  }

/**
 * When clicking on a red trust, show deviant object from trust checker on a new blade.
 */
export const onTrustClick =
  (storeRbac: StoreRbac, appRouter: AppRouterClient) =>
  (hazardLevel: TrustHazardLevel, isInternal: boolean) =>
  () => {
    // if not granted to see the deviances of the checker, return
    if (!storeRbac.isUserGrantedTo(canSeeDangerousTrustRelationships)) {
      return
    }

    if (hazardLevel !== TrustHazardLevel.Dangerous || isInternal === true) {
      return
    }

    appRouter.history.push(
      appRouter.makeRouteInfosPathname({
        routeName: AppRouteName.Topology_DeviantObjects,
        parameters: {
          checkerId: CheckerCodeNameId.C_DANGEROUS_TRUST_RELATIONSHIP,
          checkerCodename: CheckerCodeNameEnum.C_DANGEROUS_TRUST_RELATIONSHIP
        }
      })
    )
  }

/**
 * When clicking on a domain, show its score and list of IOE not compliant with it, ordered by criticity on a new blade.
 */
export const onDomainClick =
  (storeRbac: StoreRbac, appRouter: AppRouterClient) =>
  (directoryId: Maybe<number>) =>
  () => {
    if (!isDefined(directoryId)) {
      return
    }

    if (!storeRbac.isUserGrantedTo(canAccessToDomainDetails(directoryId))) {
      return
    }

    const pathname = appRouter.makeRouteInfosPathname({
      routeName: AppRouteName.Topology_DomainDetails,
      parameters: {
        directoryId
      }
    })

    appRouter.history.push(pathname)
  }
