import type { Query } from '../typeDefs/types'

/**
 * Retrieve the list of history entries.
 */
export type QuerySearchHistoryEntries = Pick<Query, 'searchHistory'>

export const querySearchHistoryEntries = `
  query querySearchHistoryEntries(
    $searchHistoryEntriesPage: Int
    $searchHistoryEntriesPerPage: Int
    $dateStart: String
    $dateEnd: String
    $expressionSearch: String
  ) {
    searchHistory(
      searchHistoryEntriesPage: $searchHistoryEntriesPage
      searchHistoryEntriesPerPage: $searchHistoryEntriesPerPage
      dateStart: $dateStart
      dateEnd: $dateEnd
      expressionSearch: $expressionSearch
    ) {
      node {
        id
        date
        expression
      }
      pagination {
        page
        perPage
        totalCount
      }
    }
  }
`
