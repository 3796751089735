import type { Maybe } from '@@types/helpers'
import { DatePicker } from 'antd'
import type { PickerProps } from 'antd/lib/date-picker/generatePicker'
import moment from 'moment'
import * as React from 'react'
import styled from 'styled-components'

interface IIgnoreUntilDatePickerProps {
  className?: string
  selectedIgnoreUntilDate: string
  onChange: (date: Maybe<moment.Moment>) => void
}

const IgnoreUntilDatePicker: React.FC<IIgnoreUntilDatePickerProps> = props => {
  const startOfTheDay = moment.utc().startOf('day')

  // disable dates that are before the end of the day
  const disableStartDate = (current: Maybe<moment.Moment>): boolean => {
    if (!current) {
      return false
    }
    return current.isBefore(startOfTheDay)
  }

  const datePickerProps: PickerProps<moment.Moment> = {
    disabledDate: disableStartDate,
    onChange: props.onChange
  }

  if (props.selectedIgnoreUntilDate !== '') {
    datePickerProps.value = moment(props.selectedIgnoreUntilDate)
  }

  return <DatePicker className={props.className} {...datePickerProps} />
}

export default styled(IgnoreUntilDatePicker)`
  width: 100%;
`
