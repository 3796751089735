import type StoreDatePicker from '@app/stores/helpers/StoreDatePicker'
import type { RangePickerSharedProps } from 'rc-picker/lib/RangePicker'

/**
 * On date changes, update the date in the store.
 */
export const onInputDatePickerRangeChange =
  (storeDatePicker: StoreDatePicker, unitOfTime?: moment.unitOfTime.StartOf) =>
  (onDatePickerChange?: () => void) =>
  (dates: RangePickerSharedProps<any>['value']) => {
    // when the dates are cleared
    if (!Array.isArray(dates) || !dates.length) {
      storeDatePicker.reset()

      if (onDatePickerChange) {
        onDatePickerChange()
      }

      return
    }

    const [dateStart, dateEnd] = dates

    if (dateStart || dateEnd) {
      storeDatePicker.setDatePickerRangeValue([dateStart, dateEnd], unitOfTime)

      // Do not call `onDatePickerChange` here, it is already called via the
      // InputDatePickerRange's onOpenChange props.
    }
  }
