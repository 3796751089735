import type { Maybe } from '@@types/helpers'
import { BladeContext } from '@app/components-legacy/Blade/context'
import type { IBladeContext } from '@app/components-legacy/Blade/types'
import { RouteContext } from '@app/components-legacy/Router/context'
import type { IRouteContext } from '@app/components-legacy/Router/types'
import type { EntityBlade } from '@app/entities'
import { useAppRouter } from '@app/hooks'
import * as React from 'react'

export function useBladeContext(): Maybe<IBladeContext> {
  return React.useContext(BladeContext)
}

/**
 * Return the optional context of the current blade.
 *
 * Pass an entity when using this hook outside the Blade context Provider,
 * or use it without parameter to retrieve the current blade entity via the
 * BladeContext.
 */
export function useBladeRouteContext(
  blade?: EntityBlade
): Maybe<IRouteContext> {
  const bladeContext = useBladeContext()
  const routeContext = React.useContext(RouteContext)

  const appRouter = useAppRouter()

  const bladeUrl = blade?.url || bladeContext?.entityBlade?.url

  // Memo the returned context according to the blade url
  return React.useMemo(() => {
    if (!bladeUrl) {
      return null
    }

    if (routeContext?.routeName !== appRouter.getRouteName(bladeUrl)) {
      return null
    }

    return routeContext
  }, [bladeUrl])
}
