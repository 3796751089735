import { ContainerFlex } from '@app/components-legacy/Container'
import type StoreFormGroups from '@app/stores/helpers/StoreFormGroups.ts'
import type { CreateGroupFn } from '@app/stores/helpers/StoreFormGroups.ts/types'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { FormWrapperButton } from '.'
import FormGroupTitle from './FormGroupTitle'
import { onFormGroupsAddClick, onFormGroupsRemoveClick } from './handlers'

interface IFormGroupsProps<G> {
  addFirstGroupButtonLabel: string
  store: StoreFormGroups<G>
  createGroupFn: CreateGroupFn<G>
}

export function FormGroups<G>(
  props: IFormGroupsProps<G> & { children: (group: G) => React.ReactNode }
): React.ReactElement {
  const getItems = (): React.ReactElement[] => {
    if (!props.store.groups.size) {
      return [
        <FormWrapperButton
          labelledBy="addGroup"
          buttonProps={{
            onClick: onFormGroupsAddClick(props.store)(props.createGroupFn)
          }}
        >
          {props.addFirstGroupButtonLabel}
        </FormWrapperButton>
      ]
    }

    return Array.from(props.store.groups.entries()).map(
      ([index, group], count) => {
        return (
          <ContainerFlex
            labelledBy={`allowedGroup-${index + 1}`}
            key={count}
            name="FormGroupsFieldset"
            direction="column"
            items={[
              <FormGroupTitle
                count={count}
                onRemoveClick={onFormGroupsRemoveClick(props.store)(index)}
                onAddClick={onFormGroupsAddClick(props.store)(
                  props.createGroupFn
                )}
              />,

              <div>{props.children(group)}</div>
            ]}
            spaced
            spaceWidth="small"
          />
        )
      }
    )
  }

  return (
    <ContainerFlex
      direction="column"
      name="FormGroups"
      items={getItems()}
      spaced
    />
  )
}

export default observer(FormGroups)
