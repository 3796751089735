import { BladeContentLayoutDefault } from '@app/components-legacy/Blade/BladeContent'
import {
  Drawer,
  DrawerContext,
  DrawerHorizontalContainer,
  DrawerTitle
} from '@app/components-legacy/Drawer'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { PortalPlaceHolder } from '@app/components-legacy/Portal'
import type { IAdObjetAttribute } from '@app/entities/EntityAdObject/types'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import type StoreDrawer from '@app/stores/helpers/StoreDrawer'
import { grant } from '@libs/rbac/permissions'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerAttributeValueContent from './DrawerAttributeValueContent'

export interface IDrawerAttributeValueProps {
  storeDrawer: StoreDrawer<{ attribute: IAdObjetAttribute }>
}

const DrawerAttributeValue: React.FC<IDrawerAttributeValueProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'TrailFlow.EventDetails.DrawerAttributeValue']
  })

  return (
    <DrawerContext.Provider value={{ storeDrawer: props.storeDrawer }}>
      <Drawer>
        <DrawerHorizontalContainer variant="large">
          <BladeContentLayoutDefault
            layout={{
              name: 'default',
              title: <DrawerTitle>{translate('Attribute value')}</DrawerTitle>,
              content: (
                <DrawerAttributeValueContent
                  attribute={props.storeDrawer.getDataValue('attribute')}
                />
              ),
              footer: <PortalPlaceHolder name={PlaceHolderName.drawerFooter} />
            }}
            rbacCapabilityCheck={grant()}
          />
        </DrawerHorizontalContainer>
      </Drawer>
    </DrawerContext.Provider>
  )
}

export default observer(DrawerAttributeValue)
