import { ContainerFooter } from '@app/components-legacy/Container'
import { ModalCancelButton } from '@app/components-legacy/Modal'
import Modal from '@app/components-legacy/Modal/Modal'
import { useAppTranslator } from '@app/hooks/useAppTranslator'
import { useStores } from '@app/hooks/useStores'
import * as React from 'react'

interface IModalMaxProfilesProps {}

const ModalMaxProfiles: React.FC<IModalMaxProfilesProps> = props => {
  const translate = useAppTranslator({
    namespaces: ['Buttons', 'Management.Accounts.Profiles.Modal']
  })
  const { storeManagementProfiles } = useStores()

  const { nbmaxprofiles } =
    storeManagementProfiles.storeRoot.environment.config.app.settings

  return (
    <Modal
      modalProps={{
        title: translate('Information'),
        footer: (
          <ContainerFooter variant="modal">
            <ModalCancelButton
              storeModal={storeManagementProfiles.storeModalProfileMax}
            />
          </ContainerFooter>
        )
      }}
      storeModal={storeManagementProfiles.storeModalProfileMax}
    >
      <div>
        {translate('You have reached the maximum number X of profiles', {
          interpolations: {
            nbMaxProfiles: nbmaxprofiles
          }
        })}
      </div>
    </Modal>
  )
}

export default ModalMaxProfiles
