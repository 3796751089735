import { Features } from '@alsid/common'
import { ButtonShowPicker } from '@app/components-legacy/Button'
import { Drawer, DrawerContext } from '@app/components-legacy/Drawer'
import { PlaceHolderName } from '@app/components-legacy/PlaceHolder/types'
import { Portal } from '@app/components-legacy/Portal'
import { useFeatureFlag } from '@app/hooks/useFeatureFlag'
import type StoreInputHealthChecks from '@app/stores/StoreInputHealthChecks'
import { DSThemeProvider } from '@design-system/contexts/DSThemeProvider'
import { useDSTheme } from '@design-system/hooks/useDSTheme'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { DSThemeName } from '@design-system/styles/themes/types'
import type { BaseButtonProps } from 'antd/lib/button/button'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DrawerInputHealthChecks from './DrawerInputHealthChecks'
import HealthChecksSelectionCounter from './DrawerInputHealthChecks/HealthChecksSelectionCounter'
import { handleInputHealthChecksOnClick } from './handlers'

interface IInputHealthChecksButtonProps {
  size?: BaseButtonProps['size']
  withExpand?: boolean
  onSubmit?: () => void
  storeInputHealthChecks: StoreInputHealthChecks
}

const InputHealthChecksButton: React.FC<
  IInputHealthChecksButtonProps
> = props => {
  const featureDrawerNewDesign = useFeatureFlag({
    featureFlagName: Features.DRAWER_NEW_DESIGN
  })

  const theme = useDSTheme()

  const themeIsLegacy = isThemeLegacy(theme)

  return (
    <>
      <ButtonShowPicker
        labelledBy="showPickerHealthChecks"
        buttonsProps={{
          size: props.size,
          onClick: handleInputHealthChecksOnClick(props.storeInputHealthChecks)
        }}
        storeDrawer={props.storeInputHealthChecks.storeDrawer}
      >
        <HealthChecksSelectionCounter
          storeInputHealthChecks={props.storeInputHealthChecks}
        />
      </ButtonShowPicker>

      <Portal name={PlaceHolderName.selectorDrawer}>
        <DSThemeProvider
          themeName={
            featureDrawerNewDesign && !themeIsLegacy
              ? DSThemeName.default
              : DSThemeName.legacy
          }
        >
          <DrawerContext.Provider
            value={{ storeDrawer: props.storeInputHealthChecks.storeDrawer }}
          >
            <Drawer>
              <DrawerInputHealthChecks
                withExpand={props.withExpand}
                onSubmit={props.onSubmit}
                storeInputHealthChecks={props.storeInputHealthChecks}
              />
            </Drawer>
          </DrawerContext.Provider>
        </DSThemeProvider>
      </Portal>
    </>
  )
}

export default observer(InputHealthChecksButton)
