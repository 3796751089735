import {
  ContainerFlex,
  ContainerFooter
} from '@app/components-legacy/Container'
import {
  WidgetListBulkActions,
  WidgetListCountItems,
  WidgetListPagination,
  WidgetListSelectAllCheckbox
} from '@app/components-legacy/Widgets/WidgetList'
import { useAppTranslator } from '@app/hooks'
import type StoreGenericAlerts from '@app/stores/StoreAlerts/StoreGenericAlerts'
import * as React from 'react'
import { AlertsBulkActionValue } from '../types'
import { onAlertActionSelection, onAlertPageChange } from './handlers'

interface IAlertsFooterProps {
  storeGenericAlerts: StoreGenericAlerts<any>
}

const AlertsFooter: React.FC<IAlertsFooterProps> = props => {
  const translate = useAppTranslator({ namespaces: ['Drawers.Alerts'] })

  return (
    <>
      <ContainerFooter>
        <WidgetListPagination
          storeWidgetList={props.storeGenericAlerts.storeWidgetList}
          onChange={onAlertPageChange(props.storeGenericAlerts)}
        />
      </ContainerFooter>

      <ContainerFooter>
        <ContainerFlex
          name="AlertsFooterActionsLeft"
          alignItems="center"
          items={[
            <WidgetListSelectAllCheckbox
              hideLabel
              storeWidgetList={props.storeGenericAlerts.storeWidgetList}
            />,

            <WidgetListCountItems
              storeWidgetList={props.storeGenericAlerts.storeWidgetList}
            />
          ]}
          spaced
          spaceWidth="small"
        />

        <WidgetListBulkActions
          options={[
            {
              label: translate('Archived'),
              value: AlertsBulkActionValue.archived
            },
            {
              label: translate('Unarchived'),
              value: AlertsBulkActionValue.unarchived
            }
          ]}
          onChange={onAlertActionSelection(props.storeGenericAlerts)}
          flags={props.storeGenericAlerts.storeActionFlags.flags}
          storeWidgetList={props.storeGenericAlerts.storeWidgetList}
        />
      </ContainerFooter>
    </>
  )
}

export default AlertsFooter
