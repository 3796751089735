import type { StoreManagementApplicationSettings } from '@app/stores'
import { DEFAULT_SMTP_PORT } from '@app/stores/Management/StoreApplicationSettings'
import { SmtpEncryption } from '@app/stores/Management/StoreApplicationSettings/types'
import type { InputUpdateApplicationSettings } from '@server/graphql/typeDefs/types'
import { canAccessToRelays } from '../../Relays/permissions'

export const onSMTPConfigurationLoad =
  (storeManagementApplicationSettings: StoreManagementApplicationSettings) =>
  () => {
    const { storeManagementRelays, storeRbac } =
      storeManagementApplicationSettings.storeRoot.stores

    if (storeRbac.isUserGrantedTo(canAccessToRelays)) {
      storeManagementRelays.fetchRelays()
    }

    storeManagementApplicationSettings.fetchApplicationSettings().then(() => {
      const applicationSettings =
        storeManagementApplicationSettings.applicationSettings

      if (!applicationSettings) {
        return
      }

      storeManagementApplicationSettings.storeFormSMTPConfiguration
        .setDefaultFieldsValuesFromObject(applicationSettings, {
          action: 'pick',
          keys: [
            'smtpServerAddress',
            'smtpServerPort',
            'smtpAccount',
            'smtpAccountPassword',
            'emailSender',
            'smtpRelayId'
          ]
        })
        .setDefaultFieldsValues([
          {
            key: 'smtpEncryption',
            value: applicationSettings.smtpEncryption
          }
        ])
        .reset()
    })
  }

export const onSMTPConfigurationUnload =
  (storeManagementApplicationSettings: StoreManagementApplicationSettings) =>
  () => {
    storeManagementApplicationSettings.storeFormSMTPConfiguration.reset()
  }

/**
 * Save the SMTP configuration.
 */
export const onSMTPConfigurationSubmit =
  (
    storeManagementApplicationSettings: StoreManagementApplicationSettings,
    shouldSendSmtpRelayId: boolean
  ) =>
  (e: React.FormEvent<any>) => {
    e.preventDefault()

    const { storeFormSMTPConfiguration } = storeManagementApplicationSettings

    if (!storeFormSMTPConfiguration.validate()) {
      storeFormSMTPConfiguration.storeRoot.stores.storeMessages.validationError()
      return
    }

    const currentApplicationSettings =
      storeManagementApplicationSettings.applicationSettings

    if (!currentApplicationSettings) {
      return
    }

    const applicationSettings: InputUpdateApplicationSettings = {
      smtpServerAddress:
        storeFormSMTPConfiguration.getFieldValueAsString('smtpServerAddress'),
      smtpServerPort: storeFormSMTPConfiguration.getFieldValueAsNumber(
        'smtpServerPort',
        DEFAULT_SMTP_PORT
      ),
      smtpAccount:
        storeFormSMTPConfiguration.getFieldValueAsString('smtpAccount'),
      smtpAccountPassword: storeFormSMTPConfiguration.getFieldValueAsString(
        'smtpAccountPassword'
      ),
      smtpUseStartTLS:
        storeFormSMTPConfiguration.getFieldValueAsString('smtpEncryption') ===
        SmtpEncryption.startTls,
      tls:
        storeFormSMTPConfiguration.getFieldValueAsString('smtpEncryption') ===
        SmtpEncryption.tls,
      emailSender:
        storeFormSMTPConfiguration.getFieldValueAsString('emailSender'),
      smtpRelayId: shouldSendSmtpRelayId
        ? storeFormSMTPConfiguration.getFieldValueAsNumber('smtpRelayId')
        : null
    }

    storeManagementApplicationSettings
      .updateApplicationSettings(applicationSettings)
      .catch(() => {
        // already catched in the store
      })
  }
