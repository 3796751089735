import { ContainerFlex } from '@app/components/Container'
import { IDENTIFIER_COLUMN_NAME } from '@app/components-legacy/TableNew/consts'
import { BackgroundColorV2 } from '@app/styles/consts'
import * as React from 'react'
import styled from 'styled-components'
import { TableRow } from './TableRow'
import type {
  TableColumnsProperties,
  TableInterfaceType,
  TableRowData
} from './types'

const StyledContainerFlex = styled(ContainerFlex)`
  height: 100%;

  > :nth-child(odd) {
    background-color: ${BackgroundColorV2.lightGray};
  }
`

type ITableBodyProps<T extends TableInterfaceType> = {
  className?: string
  labelledBy: string
  columns: TableColumnsProperties<T>
  data: Array<TableRowData<T>>
  availableWidth: number
  totalRelativeWidth: number
  rowLinkTo?: (rowData: TableRowData<T>) => string
}

export function TableBody<T extends TableInterfaceType>(
  props: ITableBodyProps<T>
) {
  return (
    <StyledContainerFlex
      className={props.className}
      name="TableBody"
      direction="column"
      items={props.data.map((row, rowIndex) => (
        <TableRow
          key={row[IDENTIFIER_COLUMN_NAME]}
          labelledBy={props.labelledBy}
          columns={props.columns}
          availableWidth={props.availableWidth}
          totalRelativeWidth={props.totalRelativeWidth}
          rowLinkTo={props.rowLinkTo}
          row={row}
        />
      ))}
    />
  )
}
