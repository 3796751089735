import type { Maybe } from '@@types/helpers'
import { useAppTranslator, useStores } from '@app/hooks'
import { consts } from '@app/styles'
import { getRainbowColorScheme } from '@app/styles/colors/schemes'
import { isThemeLegacy } from '@design-system/styles/themes/helpers'
import { convertToLineChartData } from '@libs/dashboards/formatData'
import { formatYTicks } from '@libs/dashboards/formatYTicks'
import { getDateFormatter } from '@libs/dashboards/getDateFormatter'
import { DateFormat } from '@libs/dates/formatDate'
import { hasItems } from '@libs/hasItems'
import { isDefined } from '@libs/isDefined'
import { get } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import styled, { css } from 'styled-components'
import type { IWidgetProps } from './types'
import WidgetContainer from './WidgetContainer'
import WidgetFooterDirectories from './WidgetFooterDirectories'
import WidgetHeaderTitle from './WidgetHeaderTitle'

export interface IWidgetLineChartProps extends IWidgetProps {}

const CustomWidgetContainer = styled(WidgetContainer)`
  ${props =>
    !isThemeLegacy(props.theme) &&
    css`
      .recharts-cartesian-axis-line {
        display: none;
      }

      .recharts-cartesian-axis-tick-line {
        display: none;
      }

      .recharts-legend-item-text {
        color: ${consts.FontColorV2.primary} !important;
      }

      .recharts-cartesian-axis-tick {
        text {
          fill: ${consts.FontColorV2.secondary};
        }
      }
    `}
`

const WidgetLineChart: React.FC<IWidgetLineChartProps> = props => {
  const { storeRoot } = useStores()

  const translate = useAppTranslator({ namespaces: ['Dashboard.Common'] })

  const renderHeader = () => {
    const warnings = []
    if (props.storeWidget.hasSerieWithUnknownDirectory) {
      warnings.push(translate('Some domains are no longer available'))
    }

    return (
      <WidgetHeaderTitle
        widgetKey={props.widgetKey}
        title={props.title}
        warnings={warnings}
        onEditIconClick={props.onEditIconClick}
        onDeleteIconClick={props.onDeleteIconClick}
        onRefreshIconClick={props.onRefreshIconClick}
      />
    )
  }

  const renderContent = (): Maybe<React.ReactElement> => {
    const { storeWidget } = props
    const { series } = storeWidget

    if (!hasItems(series)) {
      return null
    }

    const data = convertToLineChartData(series)

    if (!hasItems(data.rows)) {
      return null
    }

    const colors = getRainbowColorScheme(data.labels.length)

    const language = storeRoot.appTranslator.language
    const duration = get(series[0], 'dataOptions.duration')

    const isLegendAvailable = () => {
      if (!props.storeWidget.series) {
        return false
      }

      return props.storeWidget.series.some(serie => serie.displayOptions?.label)
    }

    return (
      <LineChart
        width={props.bounds.width}
        height={props.bounds.height}
        data={data.rows}
        margin={{
          top: 50,
          right: 20
        }}
      >
        <XAxis
          dataKey="timestamp"
          tickFormatter={getDateFormatter(language, Number(duration))}
          minTickGap={10}
        />

        <YAxis
          tickFormatter={formatYTicks}
          label={{
            angle: 270
          }}
        />

        <CartesianGrid stroke={consts.colorBlueGray025} strokeDasharray="5 5" />

        <Tooltip
          labelFormatter={getDateFormatter(language, Number(duration), {
            dateFormat: DateFormat.verbose
          })}
        />

        {isLegendAvailable() && (
          <Legend
            wrapperStyle={{
              top: '10px'
            }}
          />
        )}

        {data.labels.map((label, i) => {
          // const color = getSerieColor(
          //   props.storeWidget.storeInfrastructures,
          //   series[i]
          // )

          return (
            <Line
              key={data.uuids[i]}
              type="monotone"
              dataKey={label}
              stroke={colors[i]}
              dot={false}
            />
          )
        })}
      </LineChart>
    )
  }

  const content = renderContent()

  return (
    <CustomWidgetContainer
      data-name="WidgetLine"
      labelledBy={`widget-id-${props.storeWidget.widgetEntity.id}`}
      flags={props.storeWidget.storeFlagsFetchWidgetData.flags}
      header={renderHeader()}
      footer={<WidgetFooterDirectories storeWidget={props.storeWidget} />}
      isMissingData={!isDefined(content)}
    >
      {content}
    </CustomWidgetContainer>
  )
}

export default observer(WidgetLineChart)
