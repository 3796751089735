import type { Maybe } from '@@types/helpers'
import { createEntities } from '@app/entities'
import EntityTenant from '@app/entities/EntityTenant'
import StoreDrawer from '@app/stores/helpers/StoreDrawer'
import StoreFlags from '@app/stores/helpers/StoreFlags'
import { StoreInputSearch } from '@app/stores/helpers/StoreInputSearch'
import { getTenantsColorScheme } from '@app/styles/colors/schemes'
import { CSSColors } from '@app/styles/colors/types'
import { handleStoreError } from '@libs/errors/handleStoreError'
import { isDefined } from '@libs/isDefined'
import type { Tenant } from '@libs/openapi/service-identity-core'
import { ProviderType } from '@libs/openapi/service-identity-core'
import { action, computed, makeObservable, observable, toJS } from 'mobx'
import type { StoreRoot } from '.'
import StoreBase from './StoreBase'

export interface IStoreInputTenantsOptions {
  emptySelectionAllowed?: boolean
}

// Treeview internal ID representation (tenantID)
export type TreeId = string

export default class StoreInputTenants extends StoreBase<IStoreInputTenantsOptions> {
  public storeFlagsFetchTenants = new StoreFlags(this.storeRoot)
  public storeDrawer = new StoreDrawer(this.storeRoot)
  public storeInputSearch = new StoreInputSearch(this.storeRoot)

  public translate = this.storeRoot.appTranslator.bindOptions({
    namespaces: ['Components.InputTenants']
  })

  /* Observable */

  private $tenants = observable.map<string, EntityTenant>()
  private $selectedTenants = observable.map<TreeId, boolean>()

  constructor(storeRoot: StoreRoot, options: IStoreInputTenantsOptions = {}) {
    super(storeRoot, options)
    makeObservable(this)
  }

  /**
   * Fetch tenants.
   */
  fetchTenants(): Promise<void> {
    if (
      !this.storeRoot.stores.storeManagementAzureAD
        .isTenableCloudApiTokensWorking
    ) {
      return Promise.resolve()
    }

    this.storeFlagsFetchTenants.loading()

    return this.storeRoot.environment.openApiClients.serviceIdentityCore.tenants
      .listTenants({
        providers: [ProviderType.AzureActiveDirectory]
      })
      .then(tenants => {
        const entities = createEntities<Tenant, EntityTenant>(
          EntityTenant,
          tenants
        )

        this.setTenants(entities)

        this.storeFlagsFetchTenants.success()
      })
      .catch(handleStoreError(this.storeRoot, this.storeFlagsFetchTenants))
  }

  /**
   * Return the color of a tenant.
   */
  getTenantColor(tenantId: string): string {
    const entityTenant = this.$tenants.get(tenantId)

    if (!entityTenant) {
      return CSSColors.Black
    }

    return entityTenant.color
  }

  /**
   * Return the checked status of an infrastructure.
   */
  isTenantSelected(tenantId: string): Maybe<boolean> {
    return this.selectedTenantIds.some(selectedId => selectedId === tenantId)
  }

  /* Actions */

  @action
  reset(): this {
    this.$tenants.clear()
    this.$selectedTenants.clear()

    return this
  }

  /**
   * Set tenants entities.
   */
  @action
  setTenants(entityTenants: EntityTenant[]): this {
    this.$tenants.clear()

    // generate a color scheme for all tenants
    const colors = getTenantsColorScheme(entityTenants.length)

    // set colors
    entityTenants.forEach((entityTenant, i) => {
      entityTenant.setColor(colors[i])
    })

    const allTreeIds: TreeId[] = []

    entityTenants.forEach(entityTenant => {
      const tenantId = entityTenant.getPropertyAsString('id')

      this.$tenants.set(tenantId, entityTenant)

      const treeId = String(tenantId)
      allTreeIds.push(treeId)

      // retrieve the current selected status or select it by default
      const isSelectedValue = this.$selectedTenants.get(treeId)

      const isSelectedBoolean = isDefined(isSelectedValue)
        ? isSelectedValue
        : true

      this.$selectedTenants.set(treeId, isSelectedBoolean)
    })

    // remove all selection that can be "deprecated" after a tenant deletion
    Array.from(this.selectedTenants.keys()).forEach(treeId => {
      if (allTreeIds.indexOf(treeId) === -1) {
        this.$selectedTenants.delete(treeId)
      }
    })

    return this
  }

  /**
   * Select or unselect the tenants.
   */
  @action
  selectTenant(tenantId: string, selected: boolean): this {
    const entityTenant = this.$tenants.get(tenantId)

    if (!entityTenant) {
      return this
    }

    const treeId = entityTenant.getPropertyAsString('id')

    this.$selectedTenants.set(treeId, selected)

    return this
  }

  /**
   * Replace all the tenants selection.
   */
  @action
  replaceSelectedTenants(selectedTenants: Map<TreeId, boolean>): this {
    Array.from(this.selectedTenants.keys()).forEach(treeId => {
      const selected = selectedTenants.get(treeId)

      // if undefined, keep the existing value
      if (!isDefined(selected)) {
        return
      }

      this.$selectedTenants.set(treeId, selected)
    })

    return this
  }

  /**
   * Select all tenants or only the tenants that
   * match if the search value is defined.
   */
  @action
  selectAllTenants(): this {
    if (this.storeInputSearch.hasSearchValue) {
      this.selectTenants(this.searchedTenantIds)
      return this
    }

    return this.selectTenants(this.tenantIds)
  }

  /**
   * Unselect all tenants.
   */
  @action
  unselectAllTenants(): this {
    Array.from(this.selectedTenants.keys()).forEach(treeId => {
      this.$selectedTenants.set(treeId, false)
    })

    return this
  }

  /**
   * Select or unselect some tenants.
   */
  @action
  selectTenants(tenantIds: string[]): this {
    Array.from(this.selectedTenants.keys())
      .map(treeId => String(treeId))
      .forEach(tenantId => {
        const isSelected = tenantIds.indexOf(tenantId) !== -1
        const treeId = String(tenantId)

        this.$selectedTenants.set(treeId, isSelected)
      })

    return this
  }

  /**
   * Toggle the selection of one tenant.
   */
  @action
  toggleSelectTenant(tenantId: string): this {
    const entityTenant = this.tenantIds.find(id => id === tenantId)

    if (!entityTenant) {
      return this
    }

    const treeId = String(tenantId)
    const selected = this.$selectedTenants.get(treeId)

    this.$selectedTenants.set(treeId, !selected)

    return this
  }

  /* Computed */

  /**
   * Return true if submit is allowed on empty selection
   */
  @computed
  get isDrawerSelectionSubmitEnabled(): boolean {
    return (
      this.selectedTenantIds.length > 0 ||
      (this.options.emptySelectionAllowed ?? false)
    )
  }

  /**
   * Return filtered tenants as a map.
   */
  @computed
  get tenants(): Map<string, EntityTenant> {
    return toJS(this.$tenants)
  }

  /**
   * Return selected tenants as map.
   */
  @computed
  get selectedTenants(): Map<TreeId, boolean> {
    return toJS(this.$selectedTenants)
  }

  /**
   * Return selected tenants as an array of number.
   */
  @computed
  get selectedTenantIds(): string[] {
    return Array.from(this.selectedTenants.entries())
      .filter(([, selected]) => selected === true)
      .map(([key]) => String(key))
  }

  /**
   * Return all the tenant ids.
   */
  @computed
  get tenantIds(): string[] {
    return Array.from(this.tenants.keys())
  }

  /**
   * Return all the tenant ids that match the current search value.
   */
  @computed
  get searchedTenantIds(): string[] {
    return Array.from(this.tenants.values())
      .filter(entityTenant => {
        const regexp = this.storeInputSearch.transformedSearchValueAsRegexp

        return regexp.test(entityTenant.getPropertyAsString('name'))
      })
      .map(entityTenant => entityTenant.getPropertyAsString('id'))
  }

  /**
   * Return true if some tenants are selected.
   */
  @computed
  get isSomeTenantsSelected(): boolean {
    if (this.isAllTenantsSelected) {
      return false
    }

    return Array.from(this.selectedTenants.values()).some(
      selected => selected === true
    )
  }

  /**
   * Return true if all or searches tenants are selected.
   */
  @computed
  get isAllTenantsSelected(): boolean {
    if (this.storeInputSearch.hasSearchValue) {
      return Array.from(this.selectedTenants.entries())
        .filter(([treeId]) => {
          return this.searchedTenantIds.indexOf(String(treeId)) !== -1
        })
        .every(([, selected]) => selected === true)
    }

    return Array.from(this.selectedTenants.values()).every(
      selected => selected === true
    )
  }
}
